import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PolicyBillingData } from 'app/modules/policy-management/data/policy-billing.data';
import { BillingService } from 'app/core/services/billing/billing.service';
import { takeUntil } from 'rxjs/operators';
import { EncryptParameterDTO } from 'app/shared/models/data/dto/billing/encrypt-parameter.dto';

@Component({
  selector: 'app-test-make-a-payment',
  templateUrl: './test-make-a-payment.component.html',
  styleUrls: ['./test-make-a-payment.component.scss']
})
export class TestMakeAPaymentComponent implements OnInit {

  constructor(
    private _router: Router,
    public billingData: PolicyBillingData,
    private activatedRoute: ActivatedRoute,
    protected billingService: BillingService
  ) { }

  ngOnInit() {
    this.billingData.isMakeAPaymentRedirect = true;

    this.activatedRoute.paramMap.subscribe(params => {

      // call API to encrypt parameters
      const payloadForEncrypt: EncryptParameterDTO = {
        policyNumber: params.get('policynumber'),
        insuredLastName: params.get('insuredlastname')
      };
      
      let policyNumber: string;
      let lastName: string;
      this.billingService.encryptParameter(payloadForEncrypt).subscribe(result => {
        policyNumber = result.policyNumber;
        lastName = result.insuredLastName
      
        this._router.navigate(['makeapayment', policyNumber, lastName]);
      });
    });
  }
}
