import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { BsDropdownModule, ButtonsModule, ModalModule, PaginationModule } from 'ngx-bootstrap';
import { AppBreadcrumbModule } from '@coreui/angular';
import { SubmissionSectionComponent } from './components/dashboard/submission-section/submission-section.component';
import { PolicySectionComponent } from './components/dashboard/policy-section/policy-section.component';
import { QuestionNotifSectionComponent } from './components/dashboard/question-notif-section/question-notif-section.component';
import { NewsInfoSectionComponent } from './components/dashboard/news-info-section/news-info-section.component';
import { DownloadMaterialsSectionComponent } from './components/dashboard/download-materials-section/download-materials-section.component';
import { StatusCounterComponent } from './components/status-counter/status-counter.component';
import { BillingSectionComponent } from './components/dashboard/billing-section/billing-section.component';
import { ReportsSectionComponent } from './components/dashboard/reports-section/reports-section.component';
import { TaskSectionComponent } from './components/dashboard/task-section/task-section.component';
import { NotesSectionComponent } from './components/dashboard/notes-section/notes-section.component';
import { ManagementSectionComponent } from './components/dashboard/management-section/management-section.component';
import { QuoteSectionComponent } from './components/dashboard/quote-section/quote-section.component';
import { AnnouncementsSectionComponent } from './components/dashboard/announcements-section/announcements-section.component';
import { QuickSearchSectionComponent } from './components/dashboard/quick-search-section/quick-search.component';
import { QuickReferenceSectionComponent } from './components/dashboard/quick-reference-section/quick-reference.component';
import { QuoteModalComponent } from './components/dashboard/quote-modal/quote-modal.component';
import { HelpSectionComponent } from './components/dashboard/help-section/help-section.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '../../shared/shared.module';
import { MakePaymentModalComponent } from './components/dashboard/make-payment/make-payment-modal/make-payment-modal.component';
import { SearchPolicyComponent } from './components/dashboard/make-payment/search-policy-component/search-policy.component';
import { DashboardPaginationComponent } from 'app/shared/components/dashboard-pagination/dashboard-pagination.component';
import { AgentDashboardComponent } from './pages/agent-dashboard/agent-dashboard.component';
import { UwDashboardComponent } from './pages/uw-dashboard/uw-dashboard.component';
import { UwTaskFilterSectionComponent } from './components/dashboard/uw-task-filter-section/uw-task-filter-section.component';
import { UwButtonsComponent } from './components/dashboard/uw-buttons/uw-buttons.component';
import { UwTaskSectionComponent } from './components/dashboard/uw-task-section/uw-task-section.component';
import { ProcessEndorsementModalComponent } from './components/dashboard/process-endorsement-modal/process-endorsement-modal.component';
import { CustomPipesModule } from '../../shared/custom pipes/custom-pipe.module';
// import { OutstandingReinstatementRequirementsModalComponent } from './components/dashboard/make-payment/outstanding-reinstatement-requirements-modal/outstanding-reinstatement-requirements-modal.component';
//import { ReinstatementRequirementsMetModalComponent } from './components/dashboard/make-payment/reinstatement-requirements-met-modal/reinstatement-requirements-met-modal.component';
import { TaskModalComponent } from './components/dashboard/task-modal/task-modal.component';
import { TaskData } from './data/task.data';
import { TaskSearchModalComponent } from './components/dashboard/task-modal/task-search-modal/task-search-modal.component';
import { TaskNotesSectionComponent } from './components/dashboard/task-modal/task-notes-section/task-notes-section.component';
import { TaskHistorySectionComponent } from './components/dashboard/task-modal/task-history-section/task-history-section.component';
import { TaskNotesModalComponent } from './components/dashboard/task-modal/task-notes-modal/task-notes-modal.component';
import { TaskDocumentsModalComponent } from './components/dashboard/task-modal/task-documents-modal/task-documents-modal.component';
import { TaskDocumentSectionComponent } from './components/dashboard/task-modal/task-document-section/task-document-section.component';
import { TaskPaginationComponent } from '../../shared/components/task-pagination/task-pagination.component';
import { DataTableModule } from 'angular2-datatable';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CommissionModalComponent } from '../../layout/components/commission-modal/commission-modal.component';
import { FileSaverModule } from 'ngx-filesaver';
import { CommissionPaymentModalComponent } from '../../layout/components/commission-payment-modal/commission-payment-modal.component';
import { CommissionPaymenInfoModalComponent } from '../../layout/components/commission-payment-modal/commission-paymen-info-modal/commission-paymen-info-modal.component';
import { MakePaymentAgreementComponent } from './components/dashboard/make-payment/make-payment-agreement/make-payment-agreement.component';
@NgModule({
  declarations: [
    DashboardComponent,
    SubmissionSectionComponent,
    PolicySectionComponent,
    QuestionNotifSectionComponent,
    NewsInfoSectionComponent,
    DownloadMaterialsSectionComponent,
    StatusCounterComponent,
    BillingSectionComponent,
    ReportsSectionComponent,
    TaskSectionComponent,
    NotesSectionComponent,
    ManagementSectionComponent,
    QuoteSectionComponent,
    AnnouncementsSectionComponent,
    QuickSearchSectionComponent,
    QuickReferenceSectionComponent,
    QuoteModalComponent,
    HelpSectionComponent,
    MakePaymentModalComponent,
    SearchPolicyComponent,
    AgentDashboardComponent,
    DashboardPaginationComponent,
    TaskPaginationComponent,
    UwDashboardComponent,
    UwTaskFilterSectionComponent,
    UwButtonsComponent,
    UwTaskSectionComponent,
    ProcessEndorsementModalComponent,
    // OutstandingReinstatementRequirementsModalComponent,
    //ReinstatementRequirementsMetModalComponent,
    TaskModalComponent,
    TaskSearchModalComponent,
    TaskNotesSectionComponent,
    TaskHistorySectionComponent,
    TaskNotesModalComponent,
    TaskDocumentsModalComponent,
    TaskDocumentSectionComponent,
    CommissionModalComponent,
    CommissionPaymentModalComponent,
    CommissionPaymenInfoModalComponent,
    MakePaymentAgreementComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
    DashboardRoutingModule,
    ChartsModule,
    BsDropdownModule,
    AppBreadcrumbModule,
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    ReactiveFormsModule,
    TooltipModule,
    SharedModule,
    CustomPipesModule,
    DataTableModule,
    TypeaheadModule.forRoot(),
    FileSaverModule,
    PaginationModule.forRoot()
  ],
  exports: [
    QuoteModalComponent,
  ],
  entryComponents: [
    QuoteModalComponent,
    MakePaymentModalComponent,
    SearchPolicyComponent,
    ProcessEndorsementModalComponent,
    // OutstandingReinstatementRequirementsModalComponent,
    //ReinstatementRequirementsMetModalComponent,
    TaskModalComponent,
    TaskSectionComponent,
    TaskNotesSectionComponent,
    TaskHistorySectionComponent,
    TaskSearchModalComponent,
    TaskNotesModalComponent,
    TaskDocumentSectionComponent,
    TaskDocumentsModalComponent,
    CommissionModalComponent,
    CommissionPaymentModalComponent,
    CommissionPaymenInfoModalComponent
  ],
  providers: [
    TaskData
  ],
})
export class DashboardModule { }
