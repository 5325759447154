import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EntityRiskDTO } from '../../../shared/models/data/dto/quick-quote/entity-risk.dto';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { CommonService } from '../common.service';
import { map, catchError, shareReplay } from 'rxjs/operators';
import { RiskBindDTO2 } from '../../../shared/models/data/dto/quick-quote/risk-bind-dto';
import { PolicyNotesSaveDTO } from '../../../shared/models/data/dto/policy-management/policy-notes-save.dto';
import { PolicySearchResultDTO } from '../../../../app/shared/models/data/dto/suspended-payment/policy-list-dto';
import { PolicyDocumentsDTO } from '../../../shared/models/data/dto/policy-management/policy-documents.dto';
import { AllRiskBindNoteDTO } from '../../../shared/models/data/dto/policy-management/all-risk-bind-note.dto';
import { RiskClaimDTO } from '../../../shared/models/data/dto/third-party/risk-claims.dto';
import { RiskInterestDetailDTO } from '../../../shared/models/data/dto/quick-quote/risk-interest-detail.dto';
import { RiskSearchFilterDTO } from '../../../../app/shared/models/data/dto/policy-management/risk-search-filter.dto';
import { RiskSearchResultDTO } from '../../../../app/shared/models/data/dto/policy-management/risk-search-result.dto';
import { PolicyIssueDTO } from '../../../shared/models/data/dto/policy-management/policy-issue.dto';
import { RiskDTO2 } from 'app/shared/models/data/dto/quick-quote/risk-dto2';
import { BehaviorSubject } from 'rxjs';
import { ICancellationDTO, ICancellationResultDTO, PolicyCancellationDTO } from 'app/shared/models/data/dto/policy-management/policy-cancellation.dto';
import { IRewriteResponseDTO, RewriteResponseDTO } from '../../../shared/models/data/dto/rewrite/rewrite.response.dto';
import { ReinstatementRequestDTO, ReinstatementResponseDTO } from '../../../shared/models/data/dto/policy-management/policy-reinstatement.dto';
import { BackoutEndorsementDTO } from '../../../../app/shared/models/data/dto/policy-management/backout-endorsement.dto';
import { BackoutResponseDTO } from '../../../../app/shared/models/data/dto/policy-management/backout-response.dto';
import { RiskUWApprovalDTO2 } from '../../../shared/models/data/dto/quick-quote/uw-approval.dto';
import { StatusOnBillingTransaction } from '../../../shared/models/data/dto/policy-management/status-on-billing-transaction.dto';
import { RiskOutOfSequenceRequestDTO } from 'app/shared/models/data/dto/policy-management/risk-outofsequence.request.dto';
import { RiskOutOfSequenceResponseDTO } from 'app/shared/models/data/dto/policy-management/risk-outofsequence.response.dto';
import { NoticeHistoryDTO } from '../../../shared/models/data/dto/billing/notice-history.dto';
import { ManualRescindDTO } from '../../../shared/models/data/dto/form-document/manual-rescind.dto';
import { RiskBillingDTO2 } from '../../../shared/models/data/dto/quick-quote/risk-billing-dto2';
import { GenericConstants } from '../../../shared/constants/generic.constants';

type PolicyBackupRemark = 'Renewal Pending' | 'Renewal Offer';

@Injectable({
  providedIn: 'root'
})

export class PolicyService {
  reset: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isNotesSaving: boolean = false;
  public genericConstants = GenericConstants;

  constructor(
    public http: HttpClient,
    protected commonService: CommonService
  ) { }

  getData(riskId: string = '', riskDetailId: string): Observable<EntityRiskDTO> {
    const url = `${environment.ApiUrl}/Entity?riskId=${riskId}&riskDetailId=${riskDetailId}`;
    return this.http.get(url)
      .pipe(
        map(data => {
          return data as EntityRiskDTO;
        }),
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  postPolicyDocument(model: any): Observable<RiskBindDTO2> {
    return this.http.post(`${environment.ApiUrl}/Policy/binddocument`, model)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  deletePolicyDocument(model: any): Observable<any> {
    return this.http.delete(`${environment.ApiUrl}/Policy/binddocument?riskId=${model.riskId}&riskDetailId=${model.riskDetailId}&riskBindDocumentId=${model.riskBindDocumentId}`, model)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  postPolicyNotes(model: PolicyNotesSaveDTO): Observable<any> {
    return this.http.post(`${environment.ApiUrl}/Policy/bindnotes`, model)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getPolicyNotes(riskId: string): Observable<AllRiskBindNoteDTO[]> {
    return this.http.get(`${environment.ApiUrl}/Risk/bindnotes/all?riskId=${riskId}`)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getPolicyChanges(riskId: string, riskDetailId: string, remarks?: string): Observable<any> {
    return this.http.get(remarks ?
      `${environment.ApiUrl}/Policy/changes?riskId=${riskId}&riskDetailId=${riskDetailId}&remarks=${remarks}` :
      `${environment.ApiUrl}/Policy/changes?riskId=${riskId}&riskDetailId=${riskDetailId}`)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getPolicyDocuments(riskId: string): Observable<PolicyDocumentsDTO[]> {
    return this.http.get(`${environment.ApiUrl}/Risk/document/all?riskId=${riskId}`)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  generateSASURL(url: string): Observable<string> {
    url = this.encodeHashtag(url);
    return this.http.post(`${environment.ApiUrl}/SASURLGenerator?url=${url}`, {})
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  encodeHashtag(url: string): any {
    for (var counter in this.genericConstants.unacceptedFileNameSpecialCharacter){
      url = url.replace(this.genericConstants.unacceptedFileNameSpecialCharacter[counter].regex,
        this.genericConstants.unacceptedFileNameSpecialCharacter[counter].encode);
    }
    return url;
  }

  searchPolicies(keyword: string): Observable<PolicySearchResultDTO[]> {
    return this.http.get(`${environment.ApiUrl}/Policy/searchByKeyword?keyword=${keyword}`)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  postResetPolicyChanges(riskId: string, riskDetailId: string): Observable<string> {
    return this.http.post(`${environment.ApiUrl}/Policy/reset?riskId=${riskId}&riskDetailId=${riskDetailId}`, {})
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getClaimsData(riskId: string, riskDetailId: string): Observable<RiskClaimDTO[]> {
    const url = `${environment.ApiUrl}/Risk/claims/all?riskId=${riskId}&riskDetailId=${riskDetailId}`;

    return this.http.get(url)
      .pipe(catchError(this.commonService.handleObservableHttpError)
      );
  }

  getClaims(riskId: string, riskDetailId: string): Observable<RiskClaimDTO[]> {
    const url = `${environment.ApiUrl}/Risk/claims?riskId=${riskId}&riskDetailId=${riskDetailId}`;

    return this.http.get(url)
      .pipe(catchError(this.commonService.handleObservableHttpError)
      );
  }

  searchRiskByFilter(searchFilter: RiskSearchFilterDTO): Observable<RiskSearchResultDTO[]> {
    return this.http.post(`${environment.ApiUrl}/Risk/risks`, searchFilter)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getPaymentNotAllowedReasons(policyNumber: string): Observable<string[]> {
    return this.http.get(`${environment.ApiUrl}/Risk/GetPaymentNotAllowedReasons/${policyNumber}`)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getRiskById(riskId: string): Observable<RiskDTO2> {
    return this.http.get(`${environment.ApiUrl}/Risk/risk/${riskId}`)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getBillingRiskById(riskId: string): Observable<RiskBillingDTO2> {
    return this.http.get(`${environment.BillingServiceUrl}/api/Risk/risk/${riskId}`)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  postPolicyIssue(data?: any): Observable<PolicyIssueDTO> {
    return this.http.post(`${environment.ApiUrl}/Policy/issue`, data)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getBackUpData(riskDetailId: string, remarks?: PolicyBackupRemark): Observable<RiskDTO2> {
    const url = remarks ? `${environment.ApiUrl}/Policy/backup?riskDetailId=${riskDetailId}&remarks=${remarks}` :
      `${environment.ApiUrl}/Policy/backup?riskDetailId=${riskDetailId}`;

    return this.http.get(url)
      .pipe(
        map(data => {
          return data as RiskDTO2;
        }),
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getHistoryChangedBy(riskId: string, riskDetailId: string): Observable<number> {
    return this.http.get(`${environment.ApiUrl}/Risk/history/changeby?riskId=${riskId}&riskDetailId=${riskDetailId}`)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getHistoryApproveddBy(riskId: string, riskDetailId: string): Observable<number> {
    return this.http.get(`${environment.ApiUrl}/Risk/history/approvedby?riskId=${riskId}&riskDetailId=${riskDetailId}`)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getHistoryDeclinedBy(riskId: string, riskDetailId: string): Observable<number> {
    return this.http.get(`${environment.ApiUrl}/Risk/history/declinedby?riskId=${riskId}&riskDetailId=${riskDetailId}`)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  policyUpdateSubStatusCode(riskId: string, status: string) {
    return this.http.post(`${environment.ApiUrl}/risk/update-substatus?riskDetailId=${riskId}&status=${status}`, {})
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  setApproveForReinstatement(riskId: string, currentRiskDetailId: string): Observable<any> {
    return this.http.put(`${environment.ApiUrl}/risk/status/approvedForReinstatement?riskId=${riskId}&riskDetailId=${currentRiskDetailId}`, {})
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  revertApproveForReinstatement(riskId: string, currentRiskDetailId: string): Observable<any> {
    return this.http.put(`${environment.ApiUrl}/risk/status/approvedForReinstatement/revert?riskId=${riskId}&riskDetailId=${currentRiskDetailId}`, {})
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getStateTiming(riskId: string, currentRiskDetailId: string): Observable<any> {
    return this.http.get(`${environment.ApiUrl}/risk/manualcancellation/statetiming?riskId=${riskId}&riskDetailId=${currentRiskDetailId}`, {})
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  postCancellationPolicy(payload: ICancellationDTO): Observable<ICancellationResultDTO> {
    return this.http.post(`${environment.ApiUrl}/risk/manualcancellation`, payload)
      .pipe(
        map(result => {
          return result as ICancellationDTO;
        }),
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  postRewritePolicy(payload?): Observable<RewriteResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/risk/rewrite`, payload)
      .pipe(
        map(result => {
          return result as IRewriteResponseDTO;
        }),
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  postReinstatePolicy(payload: ReinstatementRequestDTO): Observable<ReinstatementResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/risk/manualreinstate`, payload)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getInterestsDetails(riskId: string, riskDetailId: string): Observable<RiskInterestDetailDTO[]> {
    const url = `${environment.ApiUrl}/Risk/interests/all?riskId=${riskId}&riskDetailId=${riskDetailId}`;

    return this.http.get(url)
      .pipe(catchError(this.commonService.handleObservableHttpError)
      );
  }

  getPreviousEndorsementInterestsDetails(riskId: string, riskDetailId: string): Observable<RiskInterestDetailDTO[]> {
    const url = `${environment.ApiUrl}/Risk/interests/allpreviousendorsement?riskId=${riskId}&riskDetailId=${riskDetailId}`;

    return this.http.get(url)
      .pipe(catchError(this.commonService.handleObservableHttpError)
      );
  }

  getSubStatusCode(riskId: string) {
    return this.http.get(`${environment.ApiUrl}/risk/substatus?riskDetailId=${riskId}`)
      .pipe(
        shareReplay(1),
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  putNonRenewal(payload: any): Observable<any> {
    const url = `${environment.ApiUrl}/risk/renewal/nonrenewal/nonrenew`;

    return this.http.put(url, payload)
      .pipe(catchError(this.commonService.handleObservableHttpError)
      );
  }

  putRescindNonRenewal(riskId: string, riskDetailId: string): Observable<any> {
    const url = `${environment.ApiUrl}/risk/renewal/nonrenewal/rescind?riskId=${riskId}&riskDetailId=${riskDetailId}`;

    return this.http.put(url, {})
      .pipe(catchError(this.commonService.handleObservableHttpError)
      );
  }

  putReverseNonRenewal(riskId: string, riskDetailId: string): Observable<any> {
    const url = `${environment.ApiUrl}/risk/renewal/nonrenewal/reverse?riskId=${riskId}&riskDetailId=${riskDetailId}`;

    return this.http.put(url, {})
      .pipe(catchError(this.commonService.handleObservableHttpError)
      );
  }

  postBackoutEndorsement(data?: BackoutEndorsementDTO): Observable<BackoutResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/Risk/backout`, data)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

    putRenewalReinstatement(riskId: string, riskDetailId: string): Observable<any> {
        const url = `${environment.ApiUrl}/risk/renewal/approvedrenewalreinstatement?riskId=${riskId}&riskDetailId=${riskDetailId}`;

    return this.http.put(url, {})
      .pipe(catchError(this.commonService.handleObservableHttpError)
      );
  }

  getRenewalReferral(id: string): Observable<RiskUWApprovalDTO2[]> {
    return this.http.get(`${environment.ApiUrl}/Policy/RenewalReferral?riskId=${id}`)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  postEffectiveDate(riskId: string, riskDetailId: string, effectiveDate: string): Observable<string> {
    const url = `${environment.ApiUrl}/Policy/effectivedate?riskId=${riskId}&riskDetailId=${riskDetailId}&effectiveDate=${effectiveDate}`;

    return this.http.post(url, null)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getDraftRiskDetailId(riskId: string): Observable<any> {
    return this.http.get(`${environment.ApiUrl}/Submission/draft?riskId=${riskId}`)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  postRescindPendingCancellation(request: NoticeHistoryDTO): Observable<ManualRescindDTO> {
    return this.http.post(`${environment.ApiUrl}/risk/rescindpendingcancellation`, request)
      .pipe(catchError(this.commonService.handleObservableHttpError));
  }

  getUpdatedStatusOnBillingTransaction(riskId: string): Observable<StatusOnBillingTransaction> {
    return this.http.get(`${environment.ApiUrl}/Risk/updatedStatusOnBillingTransaction?riskId=${riskId}`)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getPolicyUwApprovals(riskId: string, riskDetailId: string): Observable<RiskUWApprovalDTO2[]> {
    return this.http.get(`${environment.ApiUrl}/Risk/uwapprovals?riskId=${riskId}&riskDetailId=${riskDetailId}`)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
    }

    postOutOfSequence(data?: RiskOutOfSequenceRequestDTO): Observable<RiskOutOfSequenceResponseDTO> {
        return this.http.post(`${environment.ApiUrl}/Risk/outofsequence`, data)
            .pipe(
                catchError(this.commonService.handleObservableHttpError)
            );
    }

    getIVANSPolicies(agencyId: string, isInitialDownload: boolean): Observable<any> {
      let url_ = environment.ApiUrl + '/IVANS/Agency/Policies?agencyId={agencyId}&initialDownload={isInitialDownload}';
      url_ = url_.replace('{agencyId}', encodeURIComponent('' + agencyId));
      url_ = url_.replace('{isInitialDownload}', encodeURIComponent('' + isInitialDownload));
      return this.http.get(url_).pipe(
          map(result => {

          })
      );
  }

  getIVANSHistory(agencyId: string): Observable<any> {
    let url_ = environment.ApiUrl + '/IVANS/History?agencyId={agencyId}';
    url_ = url_.replace('{agencyId}', encodeURIComponent('' + agencyId));
    return this.http.get(url_).pipe(
        map(result => {
          return result;
        })
    );
  }
}
