import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { EarnedPremiumComponent } from './earned-premium.component';



@NgModule({
  declarations: [EarnedPremiumComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class EarnedPremiumModule { }
