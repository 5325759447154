import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { BaseClass } from '../../../../../shared/base-class';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, Validators } from '@angular/forms';
import { QuickQuotesLabelConstans } from '../../../../../shared/constants/quick-quotes.labels.constants';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { SubmissionPageData } from '../../../../../modules/submission-management/data/submission-page.data';
import { QuickQuoteData } from '../../../../../modules/submission-management/data/quick-quote.data';
import { PropertyData } from '../../../../../modules/submission-management/data/property.data';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { PropertyLabelConstants } from '../../../../../shared/constants/property.labels.constants';
import { AuthService } from '../../../../../core/services/auth.service';
import { PropertyValidators } from '../../../../../modules/submission-management/validators/property.validators';
import { PropertyValidatorErrorConstants } from '../../../../../modules/submission-management/validators/validator-error-constants/property-validator-error.constants';
import { ThirdPartyData } from '../../../../../modules/submission-management/data/third-party.data';
import { GenericConstants } from '../../../../../shared/constants/generic.constants';
import { Pages } from '../../../../../shared/constants/pages-and-sections.constants';
import FormUtils from 'app/shared/utilities/form.utils';
import { Iso360ValueMappingData } from 'app/modules/submission-management/data/iso360value-mapping.data';
import { QuoteService } from 'app/core/services/quote.service';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';
import { EntityRiskData } from '../../../../../modules/submission-management/data/entity-risk.data';
import { ApplicantData } from 'app/modules/submission-management/data/applicant.data';
import { EndorsementsData } from 'app/modules/submission-management/data/endorsements.data';
import { ThirdPartyDataService } from '../../../../../core/services/submission/third-party/third-party-data.service';
import { IAddressForCounty } from '../../../../../../app/shared/models/data/dto/third-party/address-for-county.dto';
import { BCEGSService } from '../../../../../core/services/submission/third-party/ISO-Location/BCEGSService';
import NotifUtils from '../../../../../shared/utilities/notif-utils';
import Utils from '../../../../../shared/utilities/utils';
import { CoveragesData } from 'app/modules/submission-management/data/coverages.data';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { PropertyBusinessRules } from 'app/modules/submission-management/business-rules/property.business-rules';
import { EndorsementsLabelsConstants } from 'app/shared/constants/endorsements.labels.constants';

@Component({
  selector: 'app-quick-quote-property',
  templateUrl: './quick-quote-property.component.html',
  styleUrls: ['./quick-quote-property.component.scss']
})
export class QuickQuotePropertyComponent extends BaseClass implements OnInit, OnDestroy {
  public ErrorMessageConstant = ErrorMessageConstant;
  public PropertyLabel = QuickQuotesLabelConstans.propertyPageLabel;
  public PropertyLabelConstants = PropertyLabelConstants;
  public PropertyValidatorErrorConstants = PropertyValidatorErrorConstants;
  public EndorsementsLabelsConstants = EndorsementsLabelsConstants;
  public Pages = Pages;
  public FormUtils = FormUtils;
  GenericLabel = GenericLabel;
  propertyGroup: FormGroup;
  isPropertyOpen: boolean = true;
  showFlatTile: boolean = false;
  isExternal: boolean = true; // default in authservice is external
  public formTypeConstants = FormTypeConstants;
  yearBuilt: number;
  isYearRoofValidatorsSet: boolean = false;
  formType: string;

  constructor(
      public quickQuoteData: QuickQuoteData,
      private submissionData: SubmissionPageData,
      private propertyData: PropertyData,
      private renderer: Renderer2,
      public authService: AuthService,
      private thirdPartyData: ThirdPartyData,
      private iso360ValueMapping: Iso360ValueMappingData,
      public quoteService: QuoteService,
      private entityRiskData: EntityRiskData,
      private applicantData: ApplicantData,
      private endorsementsData: EndorsementsData,
      private thirdPartyDataService: ThirdPartyDataService,
      private bCEGSService: BCEGSService,
      private coveragesData: CoveragesData,
      private policySummaryData: PolicySummaryData,
      private propertyBusinessRules: PropertyBusinessRules
    ) {
    super();
  }

  get propertyDetailsForms() {
    return this.propertyData.propertyDetails;
  }

  get propertyLocationDetailsForms() {
    return this.propertyData.locationDetails;
  }

  ngOnInit() {
    this.subscribeToYearBuilt();
    this.quoteService.formTypeObs$.pipe(takeUntil(this.stop$)).subscribe((formType) => this.formType = formType);

    this.entityRiskData.isApiCallCompleted$.subscribe(() => {
      this.subscribeToYearBuilt();
    });

    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.isExternal = this.isExternalUser(userType);
    });
  }

  subscribeToYearBuilt() {
    if (!this.isYearRoofValidatorsSet) {
      this.propertyDetailsForms.get('yearBuilt').valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
        this.yearBuilt = value;
        if (!this.isYearRoofValidatorsSet && value !== '') {
          if (this.quoteService.formType !== this.formTypeConstants.HO4) {
            this.setYearRoofCustomValidators();
          } else {
            this.propertyDetailsForms.get('yearRoof').setValidators([]);
            this.isYearRoofValidatorsSet = true;
          }
        }
      });
      this.updateYearBuiltAndYearRoofValidity();
    }
  }

  onInputYearBuilt(yearBuilt: number): void {
    this.propertyDetailsForms.get('yearRoof').setValue(this.quoteService.formType !== this.formTypeConstants.HO4 ? yearBuilt : null);
    this.yearBuilt = yearBuilt;
    this.propertyData.isYearBuiltQQChange = true;
    this.propertyData.isYearAirconChange = false;
    if (this.formType !== this.formTypeConstants.HO4) {
      this.updateYearRoofCustomValidators();
    }
    if (this.submissionData.quoteStatus.value !== GenericConstants.quoteStatus.status) {
      this.propertyData.setWindMitigationDefault();
    }

    this.setWaterDamageExclusion(Number(yearBuilt));
    this.BCEGSBasedOnYearBuiltChanged(yearBuilt);
    this.coveragesData.validateHurricaneDeductible(yearBuilt, this.applicantData.mainApplicant?.get('effectiveDate').value);
    if (Number(yearBuilt) > 999) {
      this.propertyBusinessRules.triggerActiveNew125(false, false, false, false, true);
    }
  }


  setWaterDamageExclusion(yearBuilt: number): void {
    const effectiveDateYear = this.applicantData.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate?.getFullYear();
    this.endorsementsData.waterDamageDefaultState = false;
    const validYearBuilt = yearBuilt > 1800;
    this.endorsementsData.disableWaterDamage(false);
    const WDEYearInclusion = this.endorsementsData.getWDEYearInclusion();

    if (validYearBuilt && (effectiveDateYear - yearBuilt) > WDEYearInclusion) {
      this.endorsementsData.endorsementsGroup.get('waterDamage').setValue(true);
      this.endorsementsData.disableWaterDamage(false);
      if (this.endorsementsData.IsPolicyOrRenewal) {
        this.endorsementsData.disableWaterDamage(this.isExternal);
      }
      this.endorsementsData.setLimitedWaterDamage();
      this.endorsementsData.setBRNew110InfoMessageStatus();
    }
  }

  collapseProperty() {
    this.isPropertyOpen = !this.isPropertyOpen;
    this.submissionData.isQQPropertyOpen = this.isPropertyOpen;
  }

  leaveYearRoof(yearRoof: any) {
    if (!yearRoof) {
      this.renderer.selectRootElement('#yearRoof').focus();
    }
  }

  isExternalUser(userType: string): boolean {
    return userType.toLocaleLowerCase() === GenericConstants.userType.external;
  }

  isProtectionClassDisabled(): string {
    if (!this.isExternal ||
      (this.thirdPartyData?.dataFromAPIResponse?.iso360Value?.isNoHitAddress && this.isExternal) ||
      (Boolean(this.thirdPartyData?.dataFromAPIResponse?.protectionClassLocationLowerNumber === '') ||
        Boolean(this.thirdPartyData?.dataFromAPIResponse?.protectionClassLocationLowerNumber === null))){
      return null;
    } else {
      return '';
    }
  }

  onChangeExteriorWallFinish(): void {
    if (this.thirdPartyData.dataFromAPIResponse !== undefined ||
      this.thirdPartyData.dataFromAPIResponse !== null) {
      if (this.propertyData.propertyDetails.get('exteriorWallFinish').value !== this.thirdPartyData.dataFromAPIResponse.iso360Value?.exteriorWallFinish?.code) {
        this.thirdPartyData.dataFromAPIResponse.iso360Value.exteriorWallFinish.iso360ValueCode = this.iso360ValueMapping.defaultExteriorWallFinishMapping.find(m => m.uiCode === this.propertyData.propertyDetails.get('exteriorWallFinish').value).iso360Code;
        this.thirdPartyData.dataFromAPIResponse.iso360Value.exteriorWallFinish.code = this.propertyData.propertyDetails.get('exteriorWallFinish').value;
      }
    }
  }

  onChangeExteriorWallConstructionType(): void {
    if (this.thirdPartyData.dataFromAPIResponse !== undefined ||
      this.thirdPartyData.dataFromAPIResponse !== null) {
      if (this.propertyData.propertyDetails.get('constructionType').value !== this.thirdPartyData.dataFromAPIResponse.iso360Value?.construction?.code) {
        this.thirdPartyData.dataFromAPIResponse.iso360Value.construction.iso360ValueCode = this.iso360ValueMapping.defaultConstructionTypeMapping.find(m => m.uiCode === this.propertyData.propertyDetails.get('constructionType').value).iso360Code;
        this.thirdPartyData.dataFromAPIResponse.iso360Value.construction.code = this.propertyData.propertyDetails.get('constructionType').value;
      }
    }
  }

  onChangeRoofMaterialType(): void {
    if (this.thirdPartyData.dataFromAPIResponse !== undefined ||
      this.thirdPartyData.dataFromAPIResponse !== null) {
      if (this.propertyData.propertyDetails.get('roofMaterial').value !== this.thirdPartyData.dataFromAPIResponse.iso360Value?.roofMaterial?.code) {
        this.thirdPartyData.dataFromAPIResponse.iso360Value.roofMaterial.iso360ValueCode = this.iso360ValueMapping.defaultRoofMaterialMapping.find(m => m.uiCode === this.propertyData.propertyDetails.get('roofMaterial').value).iso360Code;
        this.thirdPartyData.dataFromAPIResponse.iso360Value.roofMaterial.code = this.propertyData.propertyDetails.get('roofMaterial').value;
      }
    }

    this.propertyBusinessRules.triggerActiveNew125(false, false, false, false, false, true);
  }

  setYearRoofCustomValidators(): void {
    this.propertyDetailsForms.get('yearRoof').setValidators([Validators.required, Validators.min(1800),
    PropertyValidators.yearRoofShouldNotBeOlderThanYearBuiltValidator(this.yearBuilt),
    PropertyValidators.yearRoofInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);
    this.isYearRoofValidatorsSet = true;
    this.updateYearBuiltAndYearRoofValidity();
  }

  updateYearRoofCustomValidators(): void {
    this.propertyDetailsForms.get('yearRoof').setValidators([Validators.required, Validators.min(1800),
    PropertyValidators.yearRoofShouldNotBeOlderThanYearBuiltValidator(this.yearBuilt),
    PropertyValidators.yearRoofInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);
    this.updateYearBuiltAndYearRoofValidity();
  }

  updateYearBuiltAndYearRoofValidity(): void {
    this.propertyDetailsForms.get('yearRoof').updateValueAndValidity();
    this.propertyDetailsForms.get('yearBuilt').updateValueAndValidity();
  }

  ngOnDestroy() {
    this.isYearRoofValidatorsSet = false;
  }

  private BCEGSBasedOnYearBuiltChanged(yearBuilt: number): void {

    if (yearBuilt?.toString()?.length === 4 && yearBuilt >= 1800) {
    const address: IAddressForCounty = {};
    address.stateCode = this?.applicantData?.mainApplicant?.get('qqState')?.value;
    address.city = this?.applicantData?.mainApplicant?.get('qqCity')?.value;
    address.street1 = this?.applicantData?.mainApplicant?.get('addressLine1')?.value;
    address.street2 = this?.applicantData?.mainApplicant?.get('addressLine2')?.value;
    address.zip = this?.applicantData?.mainApplicant?.get('qqZipCode')?.value;
    Utils.blockUI();
    this.thirdPartyDataService.getBCEGSRecords(address).pipe(takeUntil(this.stop$)).subscribe(result => {
        this.bCEGSService.BCEGSMapperUsingYearBuilt(yearBuilt,result,this?.propertyData?.locationDetails);
        Utils.unblockUI();
      }, error => {
        NotifUtils.showError('Something Went Wrong.');
      });
    }

  }

  onInputYearRoof(yearRoof: number): void {
    const isFromRenewal = this.entityRiskData.getRisk()?.isFromRenewal;
    if (isFromRenewal) {
      if (Number(yearRoof) > 999) {
        this.propertyBusinessRules.triggerActiveNew125(false, false, false, false, true);
      }
    }
  }

}
