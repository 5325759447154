import { Injectable } from '@angular/core';
import { BaseClass } from '../../../shared/base-class';

interface IZip {
  address?: IAddress;
  propertyDetails?: IPropertyDetails;
  lexisNexis?: ILexisNexis;
}

interface IAddress {
  street?: string;
  state?: string;
  city?: string;
  zip?: string;
  county?: string;
  territoryCode?: string;
}

interface IPropertyDetails {
  yearBuilt?: string;
  protectionClass?: string;
  exteriorWallFinish?: string;
  constructionType?: string;
  squareFootage?: string;
  roofMaterial?: string;
  roofAge?: string;
}

interface ILexisNexis {
  creditScore?: number;
}

@Injectable()
export class ZipData extends BaseClass {
  zipList: IZip[] = [
    {
      address: {
        street: '80 Border Drive',
        state: 'FL',
        city: 'Hialeah',
        zip: '33018',
        county: 'Miami-Dade',
        territoryCode: '305'
      },
      propertyDetails: {
        yearBuilt: '2001',
        protectionClass: '2',
        // Temporary Value
        exteriorWallFinish: 'Cement Fiber',
        // exteriorWallFinish: 'Concrete Block',
        constructionType: 'Masonry',
        squareFootage: '1800',
        roofMaterial: 'WSS',
        roofAge: '2001'
      },
      lexisNexis: {
        creditScore: 888
      }
    },
    {
      address: {
        street: '22 West Pine Drive',
        state: 'FL',
        city: 'Middleburg',
        zip: '32068',
        county: 'Clay',
        territoryCode: '904'
      },
      propertyDetails: {
        yearBuilt: '2008',
        protectionClass: '6',
        exteriorWallFinish: 'Cement Fiber',
        constructionType: 'Masonry',
        squareFootage: '2400',
        roofMaterial: 'TICO',
        roofAge: '2011'
      },
      lexisNexis: {
        creditScore: 790
      }
    },
    {
      address: {
        street: '955 4th St.',
        state: 'FL',
        city: 'Fort Lauderdale',
        zip: '33351',
        county: 'Broward',
        territoryCode: '754'
      },
      propertyDetails: {
        yearBuilt: '2005',
        protectionClass: '4',
        exteriorWallFinish: 'Solid Brick or Stone',
        constructionType: 'Superior',
        squareFootage: '4000',
        roofMaterial: 'METC',
        roofAge: '2010'
      },
      lexisNexis: {
        creditScore: 715
      }
    },
    {
      address: {
        street: '56 Harvey Street',
        state: 'FL',
        city: 'Orange Park',
        zip: '32073',
        county: 'Clay',
        territoryCode: '904'
      },
      propertyDetails: {
        yearBuilt: '2014',
        protectionClass: '1',
        exteriorWallFinish: 'Aluminum or Metal',
        constructionType: 'Frame/Masonry Veneer',
        squareFootage: '1000',
        roofMaterial: 'SL',
        roofAge: '2024'
      },
      lexisNexis: {
        creditScore: 645
      }
    },
    {
      address: {
        street: '34 E. Albany Dr.',
        state: 'FL',
        city: 'Pensacola',
        zip: '32526',
        county: 'Escambia ',
        territoryCode: '850'
      },
      propertyDetails: {
        yearBuilt: '1999',
        protectionClass: '8',
        exteriorWallFinish: 'Cedar, Redwood or Pine',
        constructionType: 'Frame/Masonry Veneer',
        squareFootage: '3400',
        roofMaterial: 'COMPR',
        roofAge: '2006'
      },
      lexisNexis: {
        creditScore: 550
      }
    },
    {
      address: {
        street: '84 Poco Mas Drive',
        state: 'FL',
        city: 'Palm Beach',
        zip: '33480',
        county: 'Palm Beach',
        territoryCode: '561'
      },
      propertyDetails: {
        yearBuilt: '2014',
        protectionClass: '1',
        exteriorWallFinish: 'Aluminum or Metal',
        constructionType: 'Frame/Masonry Veneer',
        squareFootage: '1000',
        roofMaterial: 'SL',
        roofAge: '2024'
      }
    },
    {
      address: {
        street: '468 Michael Street',
        state: 'FL',
        city: 'Fort Pierce',
        zip: '34945',
        county: 'St Lucie',
        territoryCode: '772'
      },
      propertyDetails: {
        yearBuilt: '2008',
        protectionClass: '6',
        exteriorWallFinish: 'Cement Fiber',
        constructionType: 'Masonry',
        squareFootage: '2400',
        roofMaterial: 'TICO',
        roofAge: '2011'
      }
    },
    {
      address: {
        street: '18400 NE 5th Ave Miami',
        state: 'FL',
        city: 'Miami',
        zip: '33179',
        county: 'MiamiMiami-Dade County',
        territoryCode: '786'
      },
      propertyDetails: {
        yearBuilt: '2001',
        protectionClass: '2',
        // Temporary Value
        exteriorWallFinish: 'Cement Fiber',
        // exteriorWallFinish: 'Concrete Block',
        constructionType: 'Masonry',
        squareFootage: '1800',
        roofMaterial: 'WSS',
        roofAge: '2001'
      }
    },
    // Excluded zip code list for test purposes BRUL
    // (V5) Line 83: 'Do not show Flood Coverage Endorsement for zipcodes in the excluded list provided separately'
    {
      address: {
        street: '468 Michael Street',
        state: 'FL',
        city: 'Fort Pierce',
        zip: '32080',
        county: 'St Lucie',
        territoryCode: '772'
      },
      propertyDetails: {
        yearBuilt: '2008',
        protectionClass: '6',
        exteriorWallFinish: 'Cement Fiber',
        constructionType: 'Masonry',
        squareFootage: '2400',
        roofMaterial: 'TICO',
        roofAge: '2011'
      }
    },
    {
      address: {
        street: '468 Michael Street',
        state: 'FL',
        city: 'Fort Pierce',
        zip: '36561',
        county: 'St Lucie',
        territoryCode: '772'
      },
      propertyDetails: {
        yearBuilt: '2008',
        protectionClass: '6',
        exteriorWallFinish: 'Cement Fiber',
        constructionType: 'Masonry',
        squareFootage: '2400',
        roofMaterial: 'TICO',
        roofAge: '2011'
      }
    },
    {
      address: {
        street: '84 Poco Mas Drive',
        state: 'FL',
        city: 'Palm Beach',
        zip: '33908',
        county: 'Palm Beach',
        territoryCode: '561'
      },
      propertyDetails: {
        yearBuilt: '2014',
        protectionClass: '1',
        exteriorWallFinish: 'Aluminum or Metal',
        constructionType: 'Frame/Masonry Veneer',
        squareFootage: '1000',
        roofMaterial: 'SL',
        roofAge: '2024'
      }
    },
    // Added to test protection class 9
    {
      address: {
        street: '84 Poco Mas Drive',
        state: 'FL',
        city: 'Palm Beach',
        zip: '12345',
        county: 'Palm Beach',
        territoryCode: '561'
      },
      propertyDetails: {
        yearBuilt: '2014',
        protectionClass: '9',
        exteriorWallFinish: 'Aluminum or Metal',
        constructionType: 'Frame/Masonry Veneer',
        squareFootage: '1000',
        roofMaterial: 'SL',
        roofAge: '2024'
      }
    },
    // Added to test protection class 10
    {
      address: {
        street: '84 Poco Mas Drive',
        state: 'FL',
        city: 'Palm Beach',
        zip: '23456',
        county: 'Palm Beach',
        territoryCode: '561'
      },
      propertyDetails: {
        yearBuilt: '2014',
        protectionClass: '10',
        exteriorWallFinish: 'Aluminum or Metal',
        constructionType: 'Frame/Masonry Veneer',
        squareFootage: '1000',
        roofMaterial: 'SL',
        roofAge: '2024'
      }
    },
    // A-Plus Test Addresses
    {
      address: {
        street: '18400 NE 5th Ave',
        state: 'FL',
        city: 'Miami',
        zip: '33179',
        county: 'Miami-dade',
        territoryCode: '305'
      },
      propertyDetails: null
    },
    {
      address: {
        street: '21 Treasure Cir',
        state: 'FL',
        city: 'Sebastian',
        zip: '32958',
        county: 'Indian River',
        territoryCode: '772'
      },
      propertyDetails: null
    },
    {
      address: {
        street: '2271 Mallard Creek Cir',
        state: 'FL',
        city: 'Kissimmee',
        zip: '34743',
        county: 'Osceola',
        territoryCode: '407'
      },
      propertyDetails: null
    },
    {
      address: {
        street: '1158 SW 11th Ave',
        state: 'FL',
        city: 'Miami',
        zip: '33129',
        county: 'Miami-dade',
        territoryCode: '305'
      },
      propertyDetails: null
    },
    {
      address: {
        street: '1241 N Saint Johns St',
        state: 'FL',
        city: 'Saint Augustine',
        zip: '32084',
        county: 'Saint Johns',
        territoryCode: '904'
      },
      propertyDetails: null
    },
    {
      address: {
        street: '6717 Hillside Ln',
        state: 'FL',
        city: 'Lake Worth',
        zip: '33462',
        county: 'Palm Beach',
        territoryCode: '561'
      },
      propertyDetails: null
    },
    {
      address: {
        street: '240 25th SW St',
        state: 'FL',
        city: 'Naples',
        zip: '34117',
        county: 'Collier',
        territoryCode: '239'
      },
      propertyDetails: null
    },
    {
      address: {
        street: '5616 Eartha Dr Dr',
        state: 'FL',
        city: 'Jacksonville',
        zip: '32209',
        county: 'Duval',
        territoryCode: '904'
      },
      propertyDetails: null
    },
    {
      address: {
        street: '373 E Cannery Row Cir',
        state: 'FL',
        city: 'Delray Beach',
        zip: '33444',
        county: 'Palm Beach',
        territoryCode: '561'
      },
      propertyDetails: null
    },
    {
      address: {
        street: '13434 Balboa Dr',
        state: 'FL',
        city: 'Largo',
        zip: '33774',
        county: 'Pinellas',
        territoryCode: '727'
      },
      propertyDetails: null
    },
    {
      address: {
        street: '9981 C Tom Trl',
        state: 'FL',
        city: 'Tallahassee',
        zip: '32317',
        county: 'Leon',
        territoryCode: '850'
      },
      propertyDetails: null
    },




    // 3rd Party Location Addresses
    {
      address: {
        street: '10899 N LAKEWOOD AVE',
        state: 'FL',
        city: 'APOPKA',
        zip: '32703',
        county: 'ORANGE',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: '1399 NE 696TH ST',
        state: 'FL',
        city: 'BRANFORD',
        zip: '32008',
        county: 'DIXIE',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: '584 N BUENA VISTA BLVD',
        state: 'FL',
        city: 'THE VILLAGES',
        zip: '32162',
        county: 'SUMTER',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: '2899 S STATE ST',
        state: 'FL',
        city: 'BUNNELL',
        zip: '32110',
        county: 'FLAGLER',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: '359 BRIGHT LN',
        state: 'MS',
        city: 'ASHLAND',
        zip: '38603',
        county: 'BENTON',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: 'HENDRICKS PL',
        state: 'FL',
        city: 'CALLAHAN',
        zip: '32011',
        county: 'NASSAU',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: '4899 HALLANDALE BEACH BLVD',
        state: 'FL',
        city: 'HOLLYWOOD',
        zip: '33023',
        county: 'BROWARD',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: '7599 HARTLEE FIELD RD',
        state: 'TX',
        city: 'DENTON',
        zip: '76208',
        county: 'DENTON',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: '198 CAMBRIDGE ST',
        state: 'SC',
        city: 'CARLISLE',
        zip: '29031',
        county: 'UNION',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: '4750 AMELIA ISLAND PKWY',
        state: 'FL',
        city: 'FERNANDINA BEACH',
        zip: '32034',
        county: 'NASSAU',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: '799 TROPICAL AVE',
        state: 'FL',
        city: 'AVON PARK',
        zip: '33825',
        county: 'HIGHLANDS',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: '1999 NAUTILUS DR',
        state: 'FL',
        city: 'EASTPOINT',
        zip: '32328',
        county: 'FRANKLIN',
        territoryCode: '999',
      },
      propertyDetails: null,
    },

    {
      address: {
        street: '14740 TAMIAMI TRL',
        state: 'FL',
        city: 'NORTH PORT',
        zip: '34287',
        county: 'SARASOTA',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: '1199 N HARRIS AVE',
        state: 'FL',
        city: 'PANAMA CITY',
        zip: '32401',
        county: 'BAY',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: '473 ATLANTIC AVE',
        state: 'MA',
        city: 'SWAMPSCOTT',
        zip: '01907',
        county: 'ESSEX',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: 'EVERETTE ST',
        state: 'MS',
        city: 'BATESVILLE',
        zip: '38606',
        county: 'PANOLA',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: '113 DONNA DR',
        state: 'LA',
        city: 'DERIDDER',
        zip: '70634',
        county: 'VERNON',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: '23113 BANDERA RD',
        state: 'TX',
        city: 'HELOTES',
        zip: '78023',
        county: 'MEDINA',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: '11299 FORD RD',
        state: 'FL',
        city: 'BRYCEVILLE',
        zip: '32009',
        county: 'MEDINA',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: 'INVALID STREET',
        state: 'FL',
        city: 'INVALID CITY',
        zip: '00000',
        county: 'INVALID COUNTY',
        territoryCode: '999',
      },
      propertyDetails: null,
    },
    {
      address: {
        street: '7298 GRAYSON DR',
        state: 'TX',
        city: 'DENISON',
        zip: '75020',
        county: 'DENISON',
        territoryCode: '999',
      },
      propertyDetails: null,
    },

  ];
}
