import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-transfer-policy-list',
  templateUrl: './transfer-policy-list.component.html',
  styleUrls: ['./transfer-policy-list.component.scss']
})
export class TransferPolicyListComponent {
  @Input() policiesConstants: any;
  @Input() clearToTransferSearchBox: boolean = false;
  @Input() clearForTransferSearchBox: boolean = false;
  @Input() enableDebounceTime: boolean = true;
  @Input() isToTransferSearch: boolean = false;
  @Input() isForTransferSearch: boolean = false;
  @Input() forTransferSearchKeyword: string = '';
  @Input() forTransferPolicies: any;
  @Input() removeAllSelected: any;
  @Input() toTransferPolicies: any;
  @Input() transferAllSelected: any;
  @Input() isBatchStatusCompleted: any;

  @Output() policyToTransferClicked = new EventEmitter<string>();
  @Output() searchToTransferPolicies = new EventEmitter<string>();
  @Output() transferSelectedClicked = new EventEmitter<boolean>();
  @Output() policyForTransferClicked = new EventEmitter<string>();
  @Output() removeSelectedClicked = new EventEmitter<boolean>();
  @Output() searchForTransferPolicies = new EventEmitter<string>();
  @Output() transferAllClicked = new EventEmitter<boolean>();
  @Output() removeAllClicked = new EventEmitter<boolean>();

  constructor() { }

  policyToTransferClickedEvent(policyId: string): void {
    this.policyToTransferClicked.emit(policyId);
  }

  policyForTransferClickedEvent(policyId: string): void {
    this.policyForTransferClicked.emit(policyId);
  }

  transferAllClickedEvent(): void {
    this.transferAllClicked.emit(true);
  }

  removeAllClickedEvent(): void {
    this.removeAllClicked.emit(true);
  }

  transferSelectedClickedEvent(): void {
    this.transferSelectedClicked.emit(true);
  }

  removeSelectedClickedEvent(): void {
    this.removeSelectedClicked.emit(true);
  }

  searchToTransferPoliciesEvent(keyword: string): void {
    this.searchToTransferPolicies.emit(keyword);
  }

  searchForTransferPoliciesEvent(keyword: string): void {
    this.searchForTransferPolicies.emit(keyword);
  }
}