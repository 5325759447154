import { Injectable } from '@angular/core';
import swal, { SweetAlertOptions } from 'sweetalert2';
import { GenericConstants } from '../constants/generic.constants';
import { AddressDTO3 } from '../models/data/dto/quick-quote/entity-address.dto';
import { round } from 'reliable-round';

@Injectable()
export default class Utils {

  static hasIso360Loaded: boolean = false;
  static isGeneratingQuoteProposal: boolean = false;
  static isExpiredToken: boolean = false;
  static isCheckingStarPackage: boolean = false;
  static isCheckingCommissionRate: boolean = false;
  static isCheckingMoratorium: boolean = false;

  static blockUI(): void {
    if (this.isCheckingStarPackage || this.isCheckingCommissionRate) {
      return;
    }

    if (!this.isGeneratingQuoteProposal) {
      swal.fire({
        title: 'Please wait',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false
      });
      swal.showLoading();
    }
  }

  static unblockUI(): void {
    if (!this.isGeneratingQuoteProposal && !this.isExpiredToken && swal.isVisible() && swal.isLoading() && !this.isCheckingMoratorium) {
      swal.close();
    }
  }

  static generatingQP(): void {
    swal.fire({
      title: 'Generating Quote Proposal',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false
    });
    swal.showLoading();
  }

  static generatingACHFile(): void {
    swal.fire({
      title: 'Generating ACH File',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false
    });
    swal.showLoading();
  }

  static customLoaderMessage(title: string): void {
    swal.fire({
      title: title,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false
    });
    swal.showLoading();
  }

  static validateKeyPressAndAcceptAlphanumericOnly(event): Boolean {

    const keyCode = String.fromCharCode(event.keyCode);
    const alphanumericRegex: RegExp = new RegExp('[a-zA-ZÑñ ]');

    // Allow alpahbets, Ñ and ñ
    if (alphanumericRegex.test(keyCode)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }

  }

  static validateKeyPressForAlphaNumericFields(event): Boolean {

    const keyCode = String.fromCharCode(event.keyCode);
    const alphanumericRegex: RegExp = new RegExp('[a-zA-ZÑñ0-9]');

    // Allow numbers, alphabets, Ñ and ñ
    if (alphanumericRegex.test(keyCode)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }

  }

  public static getYesterdayDate(): Date {
    const today: Date = new Date();
    today.setDate(today.getDate() - 1);
    return today;
  }

  public static getAcceptedFileTypes(): string {
    let acceptedFileTypes: string = '';

    GenericConstants.acceptedFileTypes.forEach(item => {
      acceptedFileTypes += `.${item}, `;
    });

    return acceptedFileTypes;
  }

  public static mapDateToString(date?): string {
    const month = new Date(date).getMonth() + 1;
    const day = new Date(date).getDate();
    const year = new Date(date).getFullYear();

    return `${month}/${day}/${year}`;
  }

  public static getFullAddress(data?: AddressDTO3, withCountryCode: boolean = false): string {
    let address = '';
    if (data) {
      if (data.streetAddress1) {
        address += `${data.streetAddress1},`;
      }
      if (data.streetAddress2) {
        address += ` ${data.streetAddress2},`;
      }
      if (data.city && data.city !== 'NA') {
        address += ` ${data.city},`;
      }
      if (data.state && data.state !== 'NA') {
        address += ` ${data.state}`;
      }
      if (data.zipCode && data.zipCode !== '0') {
        address += ` ${data.zipCode}`;
      }
      if (data.zipCodeExt && data.zipCodeExt !== '0') {
        address += ` ${data.zipCodeExt}`;
      }
      if (withCountryCode) {
        if (data.countryCode.toLocaleLowerCase() !== 'usa') {
          address += ` ${data.countryCode}`;
        }
      }
    }
    return address;
  }

  public static updateNextBackPosition(): void {
    const cardBody = document.getElementById('card-body');
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 8) {
      cardBody.style.background = 'white';
      cardBody.style.bottom = '65px';
    } else {
      cardBody.style.bottom = '0';
    }
  }

  public static generateUniquerCreatedDate(items: any[], currentDate: Date): any[] {
    const seconds = 1;

    return this.checkCreatedDate(items, seconds, currentDate);
  }

  public static checkCreatedDate(items: any[], seconds: number, currentDate: Date) {
    let isSame = false;

    items.forEach((item, idx) => {
      if (item?.createdDate === null) {
        seconds += 1;
        const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), seconds, currentDate.getMilliseconds());
        item.createdDate = newDate;
        isSame = true;
      }

      // TODO: For future enhancement
      // else {
      //   const match = items.find((a) => a.createdDate === item.createdDate);
      //   if (items.indexOf(match) !== idx) {
      //     seconds+=1;
      //     currentDate.setSeconds(seconds);
      //     item.createdDate = currentDate;
      //     isSame = true;
      //   }
      // }
    });
    // TODO: For future enhancement
    // if (!isSame) {
    //   return items;
    // }
    // this.checkCreatedDate(items, seconds, currentDate);

    return items;
  }

  public static URLEncoder(url: string, fileName: any): string {

    if (typeof fileName === 'string' && url?.includes(fileName?.trim()) && (fileName?.trim()?.includes('#') || fileName?.trim()?.includes('%'))) {
      url = url?.replace(fileName?.trim(), encodeURIComponent(fileName?.trim()));
    }
    //Note: below code is the fix for unable to view the manually added document + filename is an object.
    // else if (typeof fileName === 'object' && url?.includes(fileName?.name?.trim()) && (fileName?.name?.trim()?.includes('#') || fileName?.name?.trim()?.includes('%'))) {
    //   url = url?.replace(fileName?.name?.trim(), encodeURIComponent(fileName?.name?.trim()));
    // }
      return url;
  }

  public static getCurrentDateInShortDateFormat(): string {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth()+1;
    const day = currentDate.getDate();
    return `${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day}/${year}`;
  }

  // Converts date to Short Date Format MM/DD/YYYY
  public static getShortDateFormat(input: string): string {
    const date = new Date(input);
    const year = date.getFullYear();
    const month = date.getMonth()+1;
    const day = date.getDate();
    return `${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day}/${year}`;
  }

  public static round(number: number, decimalPlaces?: number): number {
    let rounded: number;

    const text: string = number.toString();
    const index: number = text.indexOf('.');
    const decimalPlacesCount: number = text.length - index - 1;

    if (decimalPlaces) {
      rounded = round(number, decimalPlaces);
    } else {
      for (let counter = 0; counter < decimalPlacesCount; counter++) {
        rounded = round(number, counter);
      }

      rounded = round(rounded);
    }

    return rounded;
  }

  public static isValidGUID(str: string): boolean {
    // Regex to check valid
    // GUID
    const regex = new RegExp(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/);
    // if str
    // is empty return false
    if (str === null) {
        return false;
    }
    // Return true if the str
    // matched the ReGex
    if (regex.test(str) === true) {
        return true;
    } else {
        return false;
    }
  }
}
