import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../../core/services/layout/layout.service';
import { RefundData } from '../data/refund.data';
import { createRefundMenuItems } from './refund-navitems';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss']
})
export class RefundComponent implements OnInit {

  constructor(private layoutService: LayoutService, private refundData: RefundData) { }

  ngOnInit() {
    this.layoutService.clearMenu();
    this.refundData.initFormGroup();
    this.refundData.getRefundRequestList();
  }

}
