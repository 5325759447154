import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../common.service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { EntityDTO } from '../../../shared/models/management/agency-management/entityDto';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  constructor(public http: HttpClient,
    private commonService: CommonService) { }

  getEntityByUsername(username: string){
    return this.http.get(`${environment.ClientManagementUrl}/api/Entity/${environment.ApplicationId}/${username}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  postEntity(entity: EntityDTO) {
    return this.http.post(`${environment.ClientManagementUrl}/api/Entity/programstate`, entity, { responseType: 'text' })
      .catch(this.commonService.handleObservableHttpError);
  }

  putEntity(entity: EntityDTO){
    return this.http.put(`${environment.ClientManagementUrl}/api/Entity/update/${entity.id}`, entity, { responseType: 'text' })
      .catch(this.commonService.handleObservableHttpError);
  }
}
