import { createSelector } from '@ngrx/store';
import { BaseState } from '../base.state';

export const selectInterestsState = (state: BaseState) => state.interests;

export const selectInterestsIsLoading = createSelector(
  selectInterestsState,
  (state) => state.isLoading
)

export const selectInterestsList = createSelector(
  selectInterestsState,
  (state) => state.list
)