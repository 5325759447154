import { Pipe, PipeTransform } from '@angular/core';

interface IHurricaneDeductibleMinimumRequiredInfoArgs {
  formType: string;
  hurricaneDeductibleIsValid: boolean;
  hurricaneDeductible: string;
  homeAge: number;
  isInternal: boolean;
  isPolicyOrRenewal: boolean;
  hurricaneDeductibleBackup: number;
  isValidForNewMinimumDeductibleStateWide: boolean;
}

@Pipe({
  name: 'showHurricaneDeductibleMinimumPercentRequiredInfo'
})
export class HurricaneDeductibleMinimumPercentRequiredPipe implements PipeTransform {
  transform(hurricaneDeductibleMinimumRequiredInfoArgs: IHurricaneDeductibleMinimumRequiredInfoArgs): boolean {
    const {
      formType,
      hurricaneDeductibleIsValid,
      hurricaneDeductible,
      homeAge,
      isInternal,
      isPolicyOrRenewal,
      hurricaneDeductibleBackup,
      isValidForNewMinimumDeductibleStateWide
    } = hurricaneDeductibleMinimumRequiredInfoArgs;

    const validFormTypes: string[] = ['HO3', 'DP3'];
    const homeAgeMinimum: number = isValidForNewMinimumDeductibleStateWide ? 0 : 10;
    const isValidFormType: boolean = validFormTypes.includes(formType);
    const excluded: string = 'E';
    const hurricaneDeductibleMinimum: number  = isValidForNewMinimumDeductibleStateWide ? 2 : 5;

    let showMessage: boolean = false;
    let status: boolean = false;

    if (hurricaneDeductibleIsValid && isValidFormType && hurricaneDeductible !== excluded) {
      if (Number(hurricaneDeductible) === 500 && homeAge >= homeAgeMinimum) {
        showMessage = true;
      } else if (Number(hurricaneDeductible) < hurricaneDeductibleMinimum && homeAge >= homeAgeMinimum) {
        showMessage = true;
      }
    }

    if (isInternal) {
      status = showMessage;
    } else if (isPolicyOrRenewal && hurricaneDeductibleBackup < hurricaneDeductibleMinimum) {
      status = showMessage;
    }

    return status;
  }
}
