export const PolicyNonRenewalLabelsConstants = {
  PolicyNonRenewalModal: {
    TitleHeader: 'Policy Non-Renew',
    NonRenewalType: 'Non-Renewal Type',
    NonRenewalReasonType: 'Non-Renewal Reason Type',
    Reason: 'Reason',
    NonRenewalTypeRequired: 'Non-Renewal Type is required.',
    NonRenewalReasonTypeRequired: 'Non-Renewal Reason Type is required.',
    ReasonRequired: 'Reason is required.',
    NonRenewButton: 'Non-Renew Policy',
    CloseButton: 'Close',
    InsuredRequest: 'IR',
    BillingReasons: 'BR',
    UnderwriterReasons: 'UR'
  }
};
