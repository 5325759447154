import { EntitySubmissionListDTO } from 'app/shared/models/data/dto/submission-list/entity-submission-list.dto';
import { createAction, props } from '@ngrx/store';

export const updateRelatedRisksIsLoadingFromSubmissionListData = createAction(
  '[SubmissionListData] Update Related Risks Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateRelatedRisksListFromSubmissionListData = createAction(
  '[SubmissionListData] Update Related Risks List',
  props<{ list: EntitySubmissionListDTO[] }>()
);