
export class RenewalReinstatementApprovalDetailsRequestDTO implements IRenewalReinstatementApprovalDetailsRequestDTO {
  renewalRiskId?: string;
  expiringRiskId?: string;
  paymentPlan?: string;


  constructor(data?: IRenewalReinstatementApprovalDetailsRequestDTO) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }
}

export interface IRenewalReinstatementApprovalDetailsRequestDTO {
  renewalRiskId?: string;
  expiringRiskId?: string;
  paymentPlan?: string;
}