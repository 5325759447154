import { Injectable } from '@angular/core';
import { BaseClass } from 'app/shared/base-class';
import { ICommissionEmailReportDTO } from 'app/shared/models/commission-email-report.model';
import { CheckCommissionFileResponseDTO, INachaBankDTO } from 'app/shared/models/data/dto/nacha/nacha.dto';

@Injectable({
  providedIn: 'root',
})

export class CommissionData extends BaseClass {
  emailReportData: ICommissionEmailReportDTO[] = [];
  emailReportDataPaginationDetails: any = {
    totalRowCount: 200,
    itemPerPage: 10,
    currentPage: 1,
  };

  commissionACHData: CheckCommissionFileResponseDTO;
  nachaBank: INachaBankDTO[] = [];

  constructor(
  ) {
    super();
  }


  initEmailReportData(): void {
    this.emailReportData = [];
    for(let x = 1; x <= 10; x++) {
      let agencyCode = 'FL000' + x;
      if (x > 5){
        agencyCode = 'LA000' + x;
      }
      const data: ICommissionEmailReportDTO = {
        agencyCode: agencyCode,
        agencyCommissionEmail: 'recipient_email' + x + '@centauri.com',
        agencyName: 'AGENCY' + x,
        month: 10,
        year: 2023,
        notes: 'Successfully emailed.',
        remarks: 'N/A',
        status: ''
      };
      this.emailReportData.push(data);
    }
  }

  get activeNachaBank(): any {
    return this.nachaBank.filter(a => a.isActive);
  }
}