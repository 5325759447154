import { Component, OnDestroy, OnInit } from '@angular/core';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { LayoutService } from '../../../../core/services/layout/layout.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { QuoteModalComponent } from '../../components/dashboard/quote-modal/quote-modal.component';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { DashBoardService } from 'app/core/services/dashboard.service';
import { IBanner } from 'app/shared/models/dashboard.banner.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseClass } from 'app/shared/base-class';
import { takeUntil } from 'rxjs/operators';
import { SearchPolicyComponent } from '../../components/dashboard/make-payment/search-policy-component/search-policy.component';
import { ProcessEndorsementModalComponent } from '../../components/dashboard/process-endorsement-modal/process-endorsement-modal.component';
import { AuthService } from 'app/core/services/auth.service';
import { DashboardData } from '../../data/dashboard.data';
import { AgentDashboardDTO, IAgentDashboardDTO } from 'app/shared/models/data/dto/dashboard/agentDashboardDTO';
import Utils from 'app/shared/utilities/utils';
import { ThirdPartyDataService } from 'app/core/services/submission/third-party/third-party-data.service';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { PolicyService } from 'app/core/services/submission/policy.service';

@Component({
  selector: 'app-agent-dashboard',
  templateUrl: './agent-dashboard.component.html',
  styleUrls: ['./agent-dashboard.component.scss']
})
export class AgentDashboardComponent extends BaseClass implements OnInit, OnDestroy {

  public agentDashboardConstants = AgentDashboardConstants;
  public banners: IBanner[];

  modalRef: BsModalRef | null;
  searchInput: string;

  constructor(
    private layoutService: LayoutService,
    private storage: LocalStorageService,
    private modalService: BsModalService,
    private dashBoardService: DashBoardService,
    private router: Router,
    public authService: AuthService,
    public dashboardData: DashboardData,
    private thirdPartyDataService: ThirdPartyDataService,
    private policyService: PolicyService) {
    super();
    const enableRaterAudit = this.storage.retrieve('enableRaterAudit') ?? false;
    if (!enableRaterAudit) {
      this.storage.store('enableRaterAudit', false);
    }
  }

  ngOnInit() {
    this.dashBoardService.getBanners().pipe(takeUntil(this.stop$)).subscribe(response => {
       this.banners  = response as IBanner[];
    });
    this.layoutService.clearMenu();
    this.authService.isAgentSecCalling = false;

    this.authService.isAgentIdLoad$.subscribe(() => {
      const agentId = this.authService.agentUserInfo?.id;
      if (agentId && !this.authService.isAgentSecCalling) {
        this.authService.isAgentSecCalling = true;
        this.dashBoardService.getAgentDashBoardPolicySection(agentId).pipe(takeUntil(this.stop$)).subscribe(result => {
          this.dashboardData.agentDashBoard = result as AgentDashboardDTO;
          this.dashboardData.isAgentDashboardPolicySectionDoneLoading = true;
        });
      }
    });
  }

  showModal(): void {
    this.authService.checkUserBindingAuthority(this.authService.isExternalUser, this.authService.getCustomDate());
    if (this.authService.isUserAllowedToBind) {
      const initialState = {
        title: this.agentDashboardConstants.startNewQuoteModalTitle,
      };
      this.modalRef = this.modalService.show(QuoteModalComponent, {
        initialState,
        backdrop: true,
        ignoreBackdropClick: true,
      });
    } else {
      NotifUtils.showInformationWithConfirmationFromUser(this.agentDashboardConstants.bindingAuthorityNotAvailable, () => { });
    }
  }

  showProcessEndorsementModal(): void {
    const initialState = {
      title: this.agentDashboardConstants.processEndorsement
    };

    this.modalRef = this.modalService.show(ProcessEndorsementModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });

  }

  onSubmit(value: string): void {
    this.router.navigate(['/submissions/list'], { queryParams: { q: value?.toString()?.trim() } });
  }

  onMakePaymentClick(): void {
    const initialState = {
      title: this.agentDashboardConstants.makeAPaymentModalTitle,
    };
    this.modalRef = this.modalService.show(SearchPolicyComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  ngOnDestroy() {
    this.dashboardData.agentDashBoard = new AgentDashboardDTO();
    this.authService.isAgentSecCalling = false;
    this.dashboardData.isAgentDashboardPolicySectionDoneLoading = false;
  }

  onFloodClick(): void {
    Utils.blockUI();
    this.thirdPartyDataService.torrentPassthroughAccessRequest().pipe(takeUntil(this.stop$)).subscribe(result => {
      this.checkIfBridgeToTorrent(result.passthroughUrl);
      Utils.unblockUI();
    },
    error => {
      Utils.unblockUI();
      NotifUtils.showError(ErrorMessageConstant.TorrentFlood.torrentFailedMessage);
    });
  }

  checkIfBridgeToTorrent(token): void {
    switch (token) {
      case ErrorMessageConstant.TorrentFlood.unauthorizedLoginResponse:
      case ErrorMessageConstant.TorrentFlood.noUsernameTorrentResponse:
        window.open(token, '_blank');
        break;
      default:
        window.open(token, '_blank');
        break;
    }
  }
}
