import { createAction, props } from '@ngrx/store';
import { RiskStatus } from 'app/shared/models/data/dto/quick-quote/risk.dto';

export const updateSubmissionIsLoadingFromSubmissionManagementComponent = createAction(
  '[SubmissionManagementComponent] Update Submission Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateSubmissionStatusFromCoveragesData = createAction(
  '[CoveragesData] Update Submission Status',
  props<{ status: RiskStatus }>()
);

export const updateSubmissionStatusFromEndorsementsSavingData = createAction(
  '[EndorsementsSavingData] Update Submission Status',
  props<{ status: RiskStatus }>()
);

export const updateSubmissionStatusFromSubmissionPageData = createAction(
  '[SubmissionPageData] Update Submission Status',
  props<{ status: RiskStatus }>()
);

export const updateSubmissionStatusFromSummaryData = createAction(
  '[SummaryData] Update Submission Status',
  props<{ status: RiskStatus }>()
);

export const updateSubmissionStatusFromUwApprovalData = createAction(
  '[UWApprovalData] Update Submission Status',
  props<{ status: RiskStatus }>()
);

export const updateSubmissionStatusFromApplicantPage = createAction(
  '[ApplicantPageComponent] Update Submission Status',
  props<{ status: RiskStatus }>()
);

export const updateSubmissionIsPopulatingFormsFromSubmissionManagementComponent = createAction(
  '[SubmissionManagementComponent] Update Submission Is Populating Forms',
  props<{ isPopulatingForms: boolean }>()
);