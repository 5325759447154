export class FilterSubmissionListDTO implements IFilterSubmissionListDTO {
  searchText?: string | undefined;
  quoteNumber?: string | undefined;
  policyNumber?: string | undefined;
  insuredName?: string | undefined;
  broker?: string | undefined;
  inceptionDateFrom?: Date | undefined;
  inceptionDateTo?: Date | undefined;
  status?: string | undefined;
  subStatus?: string | undefined;
  formType?: string | undefined;
  riskId?: string[] | string | undefined;
  agentId?: string | undefined;
  agencyId?: string | undefined;
  formTypes?: Array<string> | undefined;
  renewalCode?: string;
  renewalStatus?: string;
  address?: string | undefined;
  pageNumber?: number;
  pageSize?: number;
  sortField?: string;
  isAscendingOrder?: boolean;
  stateCode?: string | undefined;
}

export interface IFilterSubmissionListDTO {
  searchText?: string | undefined;
  quoteNumber?: string | undefined;
  policyNumber?: string | undefined;
  insuredName?: string | undefined;
  broker?: string | undefined;
  inceptionDateFrom?: Date | undefined;
  inceptionDateTo?: Date | undefined;
  status?: string | undefined;
  subStatus?: string | undefined;
  formType?: string | undefined;
  riskId?: string[] | string | undefined;
  agentId?: string | undefined;
  agencyId?: string | undefined;
  formTypes?: Array<string> | undefined;
  renewalCode?: string;
  renewalStatus?: string;
  address?: string | undefined;
  pageNumber?: number;
  pageSize?: number;
  sortField?: string;
  isAscendingOrder?: boolean;
  stateCode?: string | undefined;
}
