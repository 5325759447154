import { Action, createReducer, on } from "@ngrx/store";
import * as fromBackupActions from "app/store/data-backup/data-backup.actions";
import { initialState, BackupState } from "./data-backup.state";

const _backupReducer = createReducer(
  initialState,
  on(
    fromBackupActions.updateBackupIsLoadingFormSubmissionManagementComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromBackupActions.updateBackupDataFormSubmissionManagementComponent,
    (state, { data }) => ({ ...state, data: data })
  ),
  on(
    fromBackupActions.updateBackupIsLoadingFormPolicyManagementComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromBackupActions.updateBackupDataFormPolicyManagementComponent,
    (state, { data }) => ({ ...state, data: data })
  )
);

export function backupReducer(
  state: BackupState | null,
  action: Action
) {
  return _backupReducer(state, action);
}