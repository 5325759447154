import { Component, Input, OnInit } from '@angular/core';
import { BindAndIssueValidatorErrorConstants } from '../../../../../../../../app/modules/submission-management/validators/validator-error-constants/bind-and-issue-error.constants';
import { BillingLabelConstants, CreditCardTypeConstants } from '../../../../../../../../app/shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from '../../../../../../../../app/shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../../../../app/shared/constants/generic.labels.constants';
import FormUtils from '../../../../../../../../app/shared/utilities/form.utils';
import { BindAndIssueData } from '../../../../../../../../app/modules/submission-management/data/bind-and-issue.data';
import { ZipCodeData } from '../../../../../../../../app/modules/submission-management/data/zipcode.data';
import { FormGroup } from '@angular/forms';
import { PaymentMethod } from '../../../../../../../../app/shared/enum/payment-method.enum';
import Utils from '../../../../../../../../app/shared/utilities/utils';
import { PageSections } from '../../../../../../../../app/shared/enum/page-sections.enum';

@Component({
  selector: 'app-billing-form-account-details',
  templateUrl: './billing-form-account-details.component.html',
  styleUrls: ['./billing-form-account-details.component.scss']
})
export class BillingFormAccountDetailsComponent implements OnInit {
  @Input() parentForm: FormGroup;

  public FormUtils = FormUtils;
  public BillingLabelConstants = BillingLabelConstants;
  public ErrorMessageConstant = ErrorMessageConstant;
  public CreditCardTypeConstants = CreditCardTypeConstants;
  GenericLabel = GenericLabel;
  isOpen: boolean = true;
  creditCardExpiredErrorMessage = BindAndIssueValidatorErrorConstants.creditCardExpired.value;

  PaymentMethod = PaymentMethod;

  constructor(public bindAndIssueData: BindAndIssueData,
    public zipCodeData: ZipCodeData) { }

  ngOnInit() {
  }

  get billingForm(): FormGroup {
    return this.bindAndIssueData.billingForm;
  }

  setZipCode(): void {
    this.billingForm.get('city').enable();
    const zipCode = this.billingForm.get('zipCode').value;
    const formControlNames = ['state', 'address', 'city', 'zipCode'];
    Utils.blockUI();
    if (zipCode !== '') {
      this.zipCodeData.getGenericZipCode(zipCode, this.billingForm, formControlNames, PageSections.Billing);
    } else {
      this.FormUtils.resetFields(this.billingForm, formControlNames);
      this.zipCodeData.billingCityList = [];
      Utils.unblockUI();
    }
  }
}
