import { Pipe, PipeTransform } from '@angular/core';
import { TransferStatusConstants } from 'app/shared/constants/book-transfer.constants';

@Pipe({
  name: 'disableDeleteButton'
})
export class DisableDeleteButtonPipe implements PipeTransform {
  transferStatusConstants = TransferStatusConstants;

  transform(transferStatus: string): boolean {
    return transferStatus !== this.transferStatusConstants.queued;
  }
}
