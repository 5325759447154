import { Component, EventEmitter, OnInit } from '@angular/core';
import { SuspendedPaymentsData } from '../../../../data/suspended-payments.data';
import { ErrorMessageConstant } from '../../../../../../shared/constants/error-message.constants';
import { SuspendedPaymentsLabel } from '../../../../../../shared/constants/suspended-payments.labels.constants';
import { SuspendedPaymentDetailsDTO } from '../../../../../../shared/models/data/dto/suspended-payment/suspended-payment-detail.dto';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { PolicyService } from '../../../../../../core/services/submission/policy.service';
import { PolicySearchResultDTO } from '../../../../../../shared/models/data/dto/suspended-payment/policy-list-dto';
import Utils from '../../../../../../shared/utilities/utils';
import { BillingService } from '../../../../../../core/services/billing/billing.service';
import { PostSuspendedPaymentDTO } from '../../../../../../shared/models/data/dto/suspended-payment/post-suspended-payment.dto';
import NotifUtils from '../../../../../../shared/utilities/notif-utils';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../../../../shared/base-class';
import { PolicyBillingConstants } from '../../../../../../shared/constants/policy-billing.constants';
import { SearchResultModalComponent } from '../post-suspended-payment-modal/search-result-modal/search-result-modal.component';
import { SuspendedSplitPayment } from '../../../../../../shared/models/data/dto/suspended-payment/suspended-split-payment.dto';
import { SuspendedPaymentRequestDTO } from '../../../../../../shared/models/data/dto/billing/suspended-payment-request.dto';
import FormUtils from '../../../../../../shared/utilities/form.utils';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { PayerDTO } from '../../../../../../shared/models/data/dto/billing/payer.dto';
import { Validators } from '@angular/forms';
import { SuspendedCheckDTO } from '../../../../../../shared/models/data/dto/billing/suspended-check.dto';

@Component({
    selector: 'app-add-split-payment-modal',
    templateUrl: './add-split-payment-modal.component.html',
    styleUrls: ['./add-split-payment-modal.component.scss']
})
export class AddSplitPaymentModalComponent extends BaseClass implements OnInit {
    public event: EventEmitter<any> = new EventEmitter<any>();
    public ErrorMessageConstant = ErrorMessageConstant;
    public LabelConstants = SuspendedPaymentsLabel;

    paymentDetail: SuspendedPaymentDetailsDTO;
    policySearchResult: PolicySearchResultDTO;

    searchString: string;

    noMatchFound: boolean = false;

    searchModalRef: BsModalRef | null;

    public FormUtils = FormUtils;
    public CurrencyMaskInputMode = CurrencyMaskInputMode;

    constructor(public bsModalRef: BsModalRef,
        public suspendedPaymentsData: SuspendedPaymentsData,
        private modalService: BsModalService,
        private policyService: PolicyService,
        private billingService: BillingService) {

        super();
    }

    ngOnInit() {
        this.resetAmountField();
    }

    hideModal(): void {
        this.bsModalRef.hide();
    }

    onAdd(): void {
        const request: SuspendedSplitPayment = {
            suspendedPaymentId: this.paymentDetail.id,
            policyOrQuoteNumber: this.policySearchResult?.policyNumber ? this.policySearchResult?.policyNumber : '',
            policyPaymentAmount: this.suspendedPaymentsData.addSuspendedSplitPaymentFromGroup.get('amount').value,
        };

        this.setTemporaryTableView(request);
        this.pushSplitPaymentsToSusPaymentsBatch();
        this.bsModalRef.hide();
    }

    onSearch(): void {
        Utils.blockUI();
        this.policyService.searchPolicies(this.searchString).pipe(takeUntil(this.stop$)).subscribe(result => {
            const isSiebelDataWithDuplicatePolicyNumber = result.length > 1 && result[0].isSiebel;
            const actOrPexpSiebelDataWithDuplicatePolicyNumber = result.filter(x => x.riskStatusCode === 'ACT' || x.riskStatusCode === 'PEXP');
            const latestActOrPexpSiebelDataWithDuplicatePolicyNumber = actOrPexpSiebelDataWithDuplicatePolicyNumber.sort((a, b) => new Date(b.firstIssueDate).getTime() - new Date(a.firstIssueDate).getTime())[0];
            Utils.unblockUI();

            if (result.length === 0) {
                this.noMatchFound = true;
                this.policySearchResult = null;
            } else if (result.length === 1 && result[0].isExactSearch) {
                this.noMatchFound = false;
                this.policySearchResult = result[0];
            } else if (isSiebelDataWithDuplicatePolicyNumber) {
                this.noMatchFound = false;
                this.policySearchResult = latestActOrPexpSiebelDataWithDuplicatePolicyNumber;
            } else {
                this.noMatchFound = false;
                this.showSearchResultModal(result);
            }
        },
            err => {
                Utils.unblockUI();
                NotifUtils.showError(err.error?.message);
            });
    }

    private showSearchResultModal(result: PolicySearchResultDTO[]): void {
        const initialState = {
            isReversal: true,
            resultList: result
        };

        this.searchModalRef = this.modalService.show(SearchResultModalComponent, {
            initialState,
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl modal-dialog-centered',
        });

        this.searchModalRef.content.event.subscribe((res) => {
            if (res) {
                this.noMatchFound = false;
                this.policySearchResult = res;
            }
            this.searchModalRef.hide();
        });
    }


    setTemporaryTableView(data: SuspendedSplitPayment): void {
        const newEntry = {
            policyOrQuoteNumber: data.policyOrQuoteNumber ?? '',
            policyPaymentAmount: data.policyPaymentAmount ?? 0,
        };

        this.suspendedPaymentsData.suspendedSplitPayment.push(newEntry);
        this.availableToAllocateComputation();
    }

    mapPayerForSuspendedSplitPayment(): PayerDTO {
        const payer: PayerDTO = {
            firstName: this.policySearchResult?.firstName,
            middleName: this.policySearchResult?.middleInitial,
            lastName: this.policySearchResult?.lastName,

            address1: this.policySearchResult?.propertyStreetAddress,
            address2: this.policySearchResult?.propertyStreetAddress2,

            city: this.policySearchResult?.propertyCity,
            state: this.policySearchResult?.propertyState,
            zip: this.policySearchResult?.propertyZipCode,
        };

        return payer;
    }

    pushSplitPaymentsToSusPaymentsBatch(): void {
        const receiptDate = new Date(this.paymentDetail.receiptDate);
        receiptDate.setDate(receiptDate.getDate() + 1);

        const suspendedCheck: SuspendedCheckDTO = {
            checkNum: this.paymentDetail.checkNum
        };

        const splitPayment: SuspendedPaymentRequestDTO = {
            receiptDate: receiptDate,
            policyNumber: this.policySearchResult?.policyNumber ? this.policySearchResult?.policyNumber : '',
            sourceId: 'IN',
            amount: this.suspendedPaymentsData.addSuspendedSplitPaymentFromGroup.get('amount').value,
            comment: '',
            suspendedPayer: this.mapPayerForSuspendedSplitPayment(),
            originalPolicyPaymentAmount: this.paymentDetail.amount,
            suspendedCheck: suspendedCheck,
            directBillCarrierId: this.paymentDetail.directBillCarrierId
        };

        this.suspendedPaymentsData.suspendedPaymentBatch.push(splitPayment);
    }

    availableToAllocateComputation(): void {
        let splitPaymentPolicyAmounts = [];

        splitPaymentPolicyAmounts = this.suspendedPaymentsData.suspendedSplitPayment.map(x => x.policyPaymentAmount);
        this.suspendedPaymentsData.sumOfSplitPaymentsPolicyPaymentAmounts = splitPaymentPolicyAmounts.reduce((acc, cur) => acc + cur, 0);
        this.suspendedPaymentsData.availableToAllocate = this.paymentDetail.amount - this.suspendedPaymentsData.sumOfSplitPaymentsPolicyPaymentAmounts;
    }

    resetAmountField(): void {
        this.suspendedPaymentsData.addSuspendedSplitPaymentFromGroup.get('amount').setValue(0);
        this.suspendedPaymentsData.addSuspendedSplitPaymentFromGroup.get('amount').setValidators([Validators.required, Validators.min(1), Validators.max(this.suspendedPaymentsData.availableToAllocate)]);
    }

    get disableAdd(): boolean {
        return this.suspendedPaymentsData.addSuspendedSplitPaymentFromGroup.get('amount').invalid;

    }
}
