import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginLabelsConstants } from '../../shared/constants/login.labels.constants';
import { PathConstants } from '../../shared/constants/path.constants';
import { AuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _authService: AuthService, private _router: Router) { }

  canActivate() {
    const authKey = localStorage.getItem(environment.AuthKey);
    const decodedJWT = authKey ? JSON.parse(window?.atob(authKey?.split('.')[1])) : null;
    const isInsured = decodedJWT?.is_insured;
    if (!this._authService.isLoggedIn()) {
      this._router.navigate([PathConstants.Login.Index]);
      return false;
    } else if (isInsured) {
      localStorage.removeItem('auth');
      window.open(environment.centauriHomePageURL, '_self');
      return false;

    }

    return true;
  }
}
