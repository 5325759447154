import Utils from '../utilities/utils';

export const BatchPaymentsConstants = {
  Labels: {
    Pending: {
      Header: 'Pending Batch Payments',
      Table: {
        BatchNumber: 'Batch Number',
        BatchDate: 'Batch Date',
        ControlTotal: 'Control Total',
        Count: 'Count',
        Upload: 'Upload Batch Payment File',
        Add: 'Add',
        Details: 'Details',
        Process: 'Process'
      }
    },

    Processed: {
      Header: 'Processed Batch Payments',
      Table: {
        BatchNumber: 'Batch Number',
        BatchDate: 'Batch Date',
        ControlTotal: 'Control Total',
        AmountPosted: 'Amount Posted',
        Difference: 'Difference',
        Count: 'Count',
        Details: 'Details'
      },
      Details: {
        header: 'Batch Payment Details',
      },
    },

    Details: {
      createHeader: 'Create Batch Payment',
      pendingHeader: 'Pending Batch Payment Details',
      processedHeader: 'Processed Batch Payment Details',
      addOrUpdateHeader: 'Payment Details',
      BatchNumber: 'Batch Number',
      BatchDate: 'Batch Date',
      ControlTotal: 'Control Total',
      AmountPosted: 'Amount Posted',
      Difference: 'Difference',
      Count: 'Count',
      PolicyNumber: 'Policy Number',
      InsuredName: 'Insured Name',
      Amount: 'Amount',
      CheckNumber: 'Check Number',
      PaymentOrigination: 'Payment Origination',
      ProcessCode: 'Process Code',
      PaidBy: 'Paid By',
      Company: 'Company',
      UserId: 'User Id',
      Actions: 'Actions',
      NoData: 'No Data To Display',
      BatchNumberPlaceholder: 'Generated upon Save',
      Add: 'Add',
      Edit: 'Edit',
      Update: 'Update',
      Remove: 'Remove',
      Save: 'Save',
      Cancel: 'Cancel'
    },

    Upload: {
      header: 'Upload Batch Payment',
      Upload: 'Upload',
      Cancel: 'Cancel'
    },
  },

  FieldNames: {
    batchNumber: 'batchNumber',
    batchDate: 'batchDate',
    controlTotal: 'controlTotal',
    amountPosted: 'amountPosted',
    difference: 'difference',
    count: 'count',
    policyNumber: 'policyNumber',
    insuredName: 'insuredName',
    amount: 'amount',
    checkNumber: 'checkNumber',
    paymentOrigination: 'paymentOrigination',
    processCode: 'processCode',
    paidBy: 'paidBy',
    company: 'company',
    userId: 'userId',
  },

  DefaultBatchPaymentDetails: {
    batchDate: Utils.getCurrentDateInShortDateFormat(),
    controlTotal: 0,
    amountPosted: 0,
    difference: 0,
    suspensePayments: []
  },

  AlertMessage: {
    saveBatchPaymentSuccessMsg: 'Batch Payment Successfully Saved',
    updateBatchPaymentSuccessMsg: 'Batch Payment Successfully Updated',
    uploadBatchPaymentSuccessMsg: 'Batch payment file successfully uploaded',
    removeBatchPaymentDetailConfirmMsg: 'Are you sure you want to delete this batch payment detail?',
    manualPostMsg: 'Batch Payments Successfully Processed',
    processBatchPaymentConfirmMsg: 'Are you sure you want to process this batch payment?',
    tooltipMsg: 'Amount Posted is not equal to the Control Total',
  }
};
