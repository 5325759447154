import { PathConstants } from '../../shared/constants/path.constants';
import { NavData } from '../../_nav';

export const createBookTransferMenuItems = (userType: string, isEditMode: boolean = false): NavData[] => {
  const path = '/' + PathConstants.BookTransfer.Index;

  const submissionMenu = [
    {
      name: 'Batch Dashboard',
      icon: 'fa fa-list',
      url: `${path}/${PathConstants.BookTransfer.BatchDashboard}`,
    },
    {
      name: isEditMode ? 'Edit Transfer' : 'New Transfer',
      icon: 'fa fa-exchange',
      url: `${path}/${PathConstants.BookTransfer.New}`,
      attributes: {
        disabled : isEditMode
      }
    }
  ];

  return submissionMenu;
};

