import { Component, OnInit } from '@angular/core';
import { ApplicantData } from 'app/modules/submission-management/data/applicant.data';
import { ClaimsData } from 'app/modules/submission-management/data/claims.data';
import { CoveragesData } from 'app/modules/submission-management/data/coverages.data';
import { EndorsementsData } from 'app/modules/submission-management/data/endorsements.data';
import { InterestData } from 'app/modules/submission-management/data/interest.data';
import { PropertyData } from 'app/modules/submission-management/data/property.data';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { PolicyNavValidationService } from 'app/core/services/navigation/policy-nav-validation.service';
import { PathConstants } from 'app/shared/constants/path.constants';
import { LayoutService } from 'app/core/services/layout/layout.service';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { createPolicyDetailsMenuItems } from 'app/modules/policy-management/policy-management-navitems';
import Utils from 'app/shared/utilities/utils';
import { PolicyService } from 'app/core/services/submission/policy.service';
import { BaseComponent } from 'app/shared/base-component';
import { takeUntil } from 'rxjs/operators';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import * as _ from 'lodash';
import { NavigationService } from 'app/core/services/navigation/navigation.service';
import { PolicyEditConstants } from 'app/shared/constants/policy-edit.constants';
import { SubmissionPageData } from 'app/modules/submission-management/data/submission-page.data';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { AuthService } from 'app/core/services/auth.service';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { PolicyIssueData } from 'app/modules/policy-management/data/policy-issue.data';
import { policyStatus } from 'app/shared/constants/policy-status.constants';
import { RiskStatus } from 'app/shared/models/data/dto/quick-quote/risk.dto';
import { UwQuestionsData } from 'app/modules/submission-management/data/uw-questions.data';
import { quoteStatus } from '../../../../../app/shared/constants/quote-status.constants';
import { InfoMessageConstant } from '../../../../../app/shared/constants/info-message.constants';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { ThirdPartyData } from 'app/modules/submission-management/data/third-party.data';
import { UwApprovalData } from 'app/modules/submission-management/data/uw-approval.data';
import { QuickQuoteData } from 'app/modules/submission-management/data/quick-quote.data';
import { Store } from '@ngrx/store';
import { PropertyBusinessRules } from 'app/modules/submission-management/business-rules/property.business-rules';

@Component({
  selector: 'app-policy-edit-cancel-button',
  templateUrl: './policy-edit-cancel-button.component.html',
  styleUrls: ['./policy-edit-cancel-button.component.scss']
})
export class PolicyEditCancelButtonComponent extends BaseComponent implements OnInit {
  isExternal: boolean = false;
  genericConstants = GenericConstants;
  constructor(public policySummaryData: PolicySummaryData,
    public coveragesData: CoveragesData,
    public endorsementsData: EndorsementsData,
    public claimsData: ClaimsData,
    public propertyData: PropertyData,
    public applicantData: ApplicantData,
    public interestData: InterestData,
    public policyNavValidationService: PolicyNavValidationService,
    protected layoutService: LayoutService,
    protected storage: LocalStorageService,
    public navigationService: NavigationService,
    protected policyService: PolicyService,
    public submissionPageData: SubmissionPageData,
    public authService: AuthService,
    public entityRiskData: EntityRiskData,
    public policyIssueData: PolicyIssueData,
    public uwQuestionsData: UwQuestionsData,
    protected summaryData: SummaryData,
    protected thirdPartyData: ThirdPartyData,
    protected uwApprovalData: UwApprovalData,
    protected quickQuoteData: QuickQuoteData,
    protected store: Store,
    protected propertyBusinessRules: PropertyBusinessRules
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeToUserType();
  }

  get riskId(): string {
    return this.entityRiskData.getRiskId();
  }

  get riskDetailId(): string {
    return this.entityRiskData.getRiskDetailId();
  }

  subscribeToUserType(): void {
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(result => {
      this.isExternal = result === this.genericConstants.userType.external;
    });
  }

  toggleEditPolicy(): void {
    if (this.entityRiskData.getPolicyStatus() !== 'CAN') {
      this.uwApprovalData.hurricaneDeductibleChangesDescription = this.policySummaryData.policyChanges?.find(x => x.includes('Changed Hurricane Deductible From:'));
      this.policyIssueData.isPolicyChangesLoading = true;
      this.policyIssueData.resetPolicyChanges();

      if (this.authService.isExternalUser && this.entityRiskData.moratoriumResponse) {
        let messageBuilder = '';
        this.entityRiskData.moratoriumResponse?.filter(x => x.type !== GenericConstants.closure)?.forEach(x => {
          messageBuilder += ` <li> ${x.popUpMessage} </li> `;
        });
        if (messageBuilder !== '') {
          NotifUtils.showInformationWithConfirmationFromUser(messageBuilder, () => {
            this.checkPolicyChangesAndNavigateToIssuePage();
          });
        } else {
          this.checkPolicyChangesAndNavigateToIssuePage();
        }
        return;
      }
      this.checkPolicyChangesAndNavigateToIssuePage();
    }
  }

  enableFields() {
    this.policySummaryData.isEditPolicy = !this.policySummaryData.isEditPolicy;
    localStorage.setItem('isEditPolicy', `${this.policySummaryData.isEditPolicy}`);
    this.coveragesData.setCoveragePageFormStatus(this.policySummaryData.isEditPolicy);
    this.endorsementsData.setEndorsementPageFormStatus(this.policySummaryData.isEditPolicy);
    this.propertyData.setPropertyPageFormStatus(this.policySummaryData.isEditPolicy);
    this.applicantData.setApplicantPageFormStatus(this.policySummaryData.isEditPolicy);
    this.thirdPartyData.checkThirdPartyBRUL();
    // UW Question is not editable
    // this.uwQuestionsData.setUWQuestionPageFormStatus(this.policySummaryData.isEditPolicy);
    this.interestData.setInterestPageFormStatus(this.policySummaryData.isEditPolicy);
    if (this.policySummaryData.isEditPolicy) {
      this.policyNavValidationService.validateCurrentCategory(PathConstants.Policy.Policies.CoveragesAndDeduction);
      this.policyNavValidationService.validateCurrentCategory(PathConstants.Policy.Policies.Property);
      this.policyNavValidationService.validateCurrentCategory(PathConstants.Policy.Policies.ApplicantPage);
      this.policyNavValidationService.validateCurrentCategory(PathConstants.Policy.Policies.Interests);
      this.policyNavValidationService.validateCurrentCategory(PathConstants.Policy.Policies.Endorsements);

      this.layoutService.updateMenu(createPolicyDetailsMenuItems({
        riskId: this.policySummaryData.clonePolicyId,
        riskDetailId: this.policySummaryData.riskDetailId,
        storage: this.storage,
        validation : this.policyNavValidationService.validationList,
        isEditPolicy: this.policySummaryData.isEditPolicy
    }));

      this.coveragesData.markCoveragesFormPageAsTouch();
      this.propertyData.markApplicantFormPageAsTouch();
      this.applicantData.markApplicantPageFormAsTouch();
      this.interestData.markInterestPageAsTouch();
      this.endorsementsData.markEndorsementPageAsTouch();

      this.uwQuestionsData.setDisabledEndorsementFields();
      this.coveragesData.setCoveragesPageDisabledFields();
      this.applicantData.setApplicantPageDisabledDOBandRetired();
      this.applicantData.setCoApplicantPageDisabledDOBandRetired();
    }
  }

  isRefferedtoUWStatus(): boolean {
    const status = this.submissionPageData.policyStatus?.value === AgentDashboardConstants.referredToUW &&
      this.isExternal;
    return status;
  }

  isDeclinedUWStatus(): boolean {
    const status = this.submissionPageData.policyStatus?.value === AgentDashboardConstants.declineByUW &&
      this.isExternal;
    return status;
  }

  isDeclineByUWStatusEdit(): void {
    Utils.blockUI();

    if (this.submissionPageData.policyStatus?.value === AgentDashboardConstants.declineByUW) {
      this.policyService.policyUpdateSubStatusCode(this.riskDetailId, 'Draft').pipe(takeUntil(this.stop$)).subscribe();

      this.policyService.postResetPolicyChanges(this.riskId, this.riskDetailId).pipe(takeUntil(this.stop$)).subscribe(result => {
        const data = result;
        this.policySummaryData.isFromDeclinedEdit = true;

        if (!this.policySummaryData.isFromDeclinedEdit) {
          Utils.unblockUI();
        }

        const callBackReloadPage = () => {
          this.initData();
          this.policyService.reset.next(true);
        };

        this.enableFields();
        callBackReloadPage();
      }, error => {
        Utils.unblockUI();
        NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
      });
    }
  }

  isCancelledStatus(): boolean {
    return this.submissionPageData.quoteStatus?.value === quoteStatus.can;
  }

  initData(): void {
    if (!this.entityRiskData.isDonePopulatingForms) {
      this.entityRiskData.isApiCallCompleted$.subscribe(() => {
        this.policyIssueData.triggerPolicyChanges();
        this.policyIssueData.setChangeEffectiveDateOption();
      });
    } else if (!this.entityRiskData.isDoneSavingForms) {
      this.entityRiskData.isDoneSavingForms$.pipe(takeUntil(this.stop$)).subscribe(() => {
        this.policyIssueData.triggerPolicyChanges();
      });
    } else {
      this.policyIssueData.triggerPolicyChanges();
    }
  }

  getEndorsementNotAllowedTooltip(): string {
    return this.isCancelledStatus() ? InfoMessageConstant.endorsementNotAllowedMessage : '';
  }

  checkPolicyChangesAndNavigateToIssuePage(): void {
    Utils.blockUI();
    this.policyService.getPolicyChanges(this.policySummaryData.clonePolicyId, this.policySummaryData.riskDetailId).pipe(takeUntil(this.stop$)).subscribe(policyChanges => {
      if (policyChanges?.length > 0 &&
        this.submissionPageData.policyStatus?.value !== AgentDashboardConstants.declineByUW) {
        NotifUtils.showInfo(PolicyEditConstants.pendingEndorsementChangesConfirmationMessage, () => {
          this.policySummaryData.navigatedToPolicyIssue = true;
          this.navigationService.navigatePolicyRoute(PathConstants.Policy.Policies.Issue);
          this.enableFields();
          this.policySummaryData.isFromDeclinedEdit = false;
          this.summaryData.showPendingEndorsementStatus = true;
          Utils.unblockUI();
        }, false);
      } else {
        this.isDeclineByUWStatusEdit();
        this.enableFields();
        this.policySummaryData.enablePolicyNavSaving = true;
      }
      this.summaryData.setSubStatusCode();

      if(policyChanges?.find(list => list.includes('Retired?') || list.includes('Birth Date'))?.length > 0) {
        this.quickQuoteData.setSeniorRetireeValue(true);
      }

      this.policyIssueData.isPolicyChangesLoading = false;
      this.entityRiskData.isSwitchedRisk = false;
      this.entityRiskData.isFromRewrite = false;
    }, error => {
      Utils.unblockUI();
      this.policyIssueData.isPolicyChangesLoading = false;
      NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
    }, () => {
      Utils.unblockUI();
    });
  }
}
