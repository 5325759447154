import { Component, OnInit } from '@angular/core';
import { BatchPaymentsData } from '../../../../../modules/payments/data/batch-payments.data';
import { BatchPaymentsConstants } from '../../../../../shared/constants/batch-payments.constants';
import { BsModalRef } from 'ngx-bootstrap';
import { BaseClass } from '../../../../../shared/base-class';
import { FileUploadConstants } from '../../../../../shared/constants/file-upload.constants';
import { FileUploadService } from '../../../../../shared/services/file-upload.service';

@Component({
  selector: 'app-upload-batch-payment-modal',
  templateUrl: './upload-batch-payment-modal.component.html',
  styleUrls: ['./upload-batch-payment-modal.component.scss']
})
export class UploadBatchPaymentModalComponent extends BaseClass implements OnInit {
  public UpdateLabels = BatchPaymentsConstants.Labels.Upload;
  title: string;
  fileUploadConstants = FileUploadConstants;

  constructor(
    private batchPaymentsData: BatchPaymentsData,
    public bsModalRef: BsModalRef,
    public fileUploadService: FileUploadService
  ) {
    super();
  }

  fileLabel: string =  this.fileUploadConstants.noFileChosen;

  get isInValidFileType(): boolean {
    return this.fileUploadService.isUploadedCSVExcelFileTypeInvalid(this.fileLabel);
  }

  ngOnInit() {
  }

  onChoosingFile(event) {
    this.batchPaymentsData.batchPaymentFile = event.item(0);
    this.fileLabel = this.batchPaymentsData.batchPaymentFile?.name;
  }

  onUploadBatchPayment() {
    this.bsModalRef.hide();
    this.batchPaymentsData.uploadBatchPayment();
  }

  hideModal() {
    this.bsModalRef.hide();
  }
}
