export const FloodZipCodesWatchList = [
    32754, 32780, 32796, 32901, 32905, 32922, 32926, 32927, 32935, 32940,
    32949, 32950, 32955, 32976, 33019, 33304, 33308, 33316, 33947, 33948,
    33950, 33952, 33953, 33980, 33981, 33955, 34223, 34224, 34428, 34429,
    34448, 34103, 34108, 34110, 34112, 32258, 32226, 32136, 34614, 33534,
    33570, 33572, 33602, 33605, 33607, 33609, 33611, 33615, 33619, 33629,
    33634, 33635, 32958, 32960, 32962, 32967, 32336, 33901, 33903, 33904,
    33914, 33919, 33928, 33991, 33993, 34449, 34498, 32626, 34207, 34210,
    34221, 34243, 33455, 34996, 34997, 33469, 34957, 33131, 33133, 33138,
    33157, 33160, 33181, 33190, 32034, 33401, 33435, 33462, 33487, 34652,
    34667, 34668, 34691, 33701, 33702, 33703, 33704, 33705, 33707, 33709,
    33710, 33711, 33712, 33716, 33755, 33756, 33759, 33760, 33762, 33764,
    33770, 33772, 33774, 33776, 33777, 34677, 34683, 34689, 34695, 34698,
    34946, 34950, 34229, 34231, 34234, 34239, 34275, 34285, 34293, 32084,
    32086, 32095, 32168, 32759, 33404
];
