import { Injectable, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IAngularMyDpOptions, IMyDate } from 'angular-mydatepicker';
import { BaseClass } from '../../../shared/base-class';
import Utils from '../../../shared/utilities/utils';
import { RewriteResponseDTO } from '../../../shared/models/data/dto/rewrite/rewrite.response.dto';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { PolicyService } from '../../../core/services/submission/policy.service';
import { Router } from '@angular/router';
import { EntityRiskData } from '../../../modules/submission-management/data/entity-risk.data';
import { SaveBindAndIssueDTO } from '../../../../app/shared/models/data/dto/quick-quote/save-bind-and-issue.dto';
import { CalculateRaterData } from '../../../core/services/submission/rater-premium/calculate-rater-data.service';
import { AuthService } from '../../../core/services/auth.service';
import { BillingService } from '../../../../app/core/services/billing/billing.service';
import { UpdateRiskRequestDTO } from '../../../shared/models/data/dto/billing/update-risk-request.dto';
import NotifUtils from '../../../../app/shared/utilities/notif-utils';
import { PolicyCancellationLabelsConstants } from '../../../../app/shared/constants/policy-cancellation.labels.constants';
import { Rater } from '../../../../app/shared/constants/rater.constant';
import { LocalStorageService } from '../../../../app/core/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PolicyRewriteData extends BaseClass {
  rewriteForm: FormGroup;

  newPolicyEffectiveDateOption: IAngularMyDpOptions;
  isInternal: boolean;

  PolicyRewriteLabelConstants = PolicyCancellationLabelsConstants.PolicyRewrite;

  constructor(protected policyService: PolicyService
    , protected router: Router
    , protected entityRiskData: EntityRiskData
    , protected authService: AuthService
    , protected storage: LocalStorageService
  ) {
    super();
  }

  initForms(): void {
    this.rewriteForm = this.rewriteSection();
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.isInternal = userType.toLocaleLowerCase() === 'internal';
    });
    this.dateOptions();
  }

  rewriteSection(): FormGroup {
    return new FormGroup({
      newPolicyEffectiveDate: new FormControl('', Validators.required)
    });
  }

  dateOptions(): void {
    const policyEffectiveDate = { isRange: false, singleDate: { jsDate: new Date(this.entityRiskData?.EntityRisk?.risks[0]?.riskDetails[0]?.effectiveDate) } };
    const policyExpirationDate = { isRange: false, singleDate: { jsDate: new Date(this.entityRiskData?.EntityRisk?.risks[0]?.riskDetails[0]?.expirationDate) } };

    this.newPolicyEffectiveDateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy',
      disableUntil: this.defineBackdateLimit(policyEffectiveDate),
      disableSince: {
        year: policyExpirationDate?.singleDate.jsDate.getFullYear(),
        month: policyExpirationDate?.singleDate.jsDate.getMonth() + 1,
        day: policyExpirationDate?.singleDate.jsDate.getDate() + 1
      },
      disableDates: this.authService.getLeapYearDateToDisable(this.authService.getCustomDate())
    };
  }

  postRewritePolicy(payload?): void {
    Utils.blockUI();
    this.policyService.postRewritePolicy(payload).pipe(takeUntil(this.stop$)).subscribe(result => {
      const RewriteResponse = <RewriteResponseDTO>result;

      const newPolicy: UpdateRiskRequestDTO = {
        riskId: RewriteResponse.newRiskId,
        policyNumber: RewriteResponse.newRiskPolicyNumber,
        quoteNumber: this.entityRiskData.getQuoteNumber()
      };

      this.entityRiskData.setPolicyNumberOnBind(newPolicy);

      NotifUtils.showSuccess(this.PolicyRewriteLabelConstants.RewriteSuccess, () => {
        this.entityRiskData.isFromRewrite = true;
        this.removeRaterStorage(this.entityRiskData.getRiskDetailId());
        this.router.navigateByUrl(`/policies/${RewriteResponse.newRiskId}/${RewriteResponse.newRiskDetailDraftId}/summary`);
      });
    }, (error) => {
      Utils.unblockUI();
    });
  }

  get invalidForm(): boolean {
    return this.rewriteForm.invalid;
  }

  private defineBackdateLimit(effectiveDate: any): IMyDate {
    if (this.isInternal) {
      return {
        year: 0,
        month: 0,
        day: 0
      };
    } else {
      const loginDate = this.authService.getCustomDate();
      const loginDateLess30 = this.authService.getCustomDate();
      loginDateLess30.setDate(loginDate.getDate() - 31);

      if (loginDateLess30 >= effectiveDate?.singleDate.jsDate) {
        return {
          year: loginDateLess30.getFullYear(),
          month: loginDateLess30.getMonth() + 1,
          day: loginDateLess30.getDate()
        };
      } else {
        let dateMinusOneDay: Date;

        if (effectiveDate?.singleDate.jsDate > loginDate) {
          dateMinusOneDay = loginDate;
        } else {
          dateMinusOneDay = new Date(effectiveDate?.singleDate.jsDate);
        }

        dateMinusOneDay.setDate(dateMinusOneDay.getDate() - 1);

        return {
          year: dateMinusOneDay.getFullYear(),
          month: dateMinusOneDay.getMonth() + 1,
          day: dateMinusOneDay.getDate()
        };
      }
    }
  }

  protected removeRaterStorage(riskDetailId: string) {
    this.storage.remove(`${Rater.raterRequest}_${riskDetailId}`);
    this.storage.remove(`${Rater.raterResult}_${riskDetailId}`);
  }
}
