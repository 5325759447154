import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StatusCounter } from '../../status-counter/status-counter';
import { QuoteModalComponent } from '../quote-modal/quote-modal.component';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { SubmissionService } from 'app/core/services/submission/submission.service';
import { FilterSubmissionListDTO } from 'app/shared/models/data/dto/submission-list/filter-submission-list.dto';
import { BaseClass } from 'app/shared/base-class';
import { takeUntil } from 'rxjs/operators';
import { DashboardData } from 'app/modules/dashboard/data/dashboard.data';

@Component({
  selector: 'app-quote-section',
  templateUrl: './quote-section.component.html',
  styleUrls: ['./quote-section.component.scss'],
})
export class QuoteSectionComponent extends BaseClass implements OnInit {
  submissionCounterItems: StatusCounter[];
  modalRef: BsModalRef | null;
  public agentDashboardConstants = AgentDashboardConstants;
  public additionalInformationRequiredCount: number = 0;
  public status = {
    quotedApplication:'QUO_APP',
    pending: 'PEN',
    referredToUW: 'RUW',
    approvedByUW: 'AUW',
    declineByUW: 'DUW',
    additionalInformationRequired: 'AIR'
  };

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
    private router: Router,
    private submissionService: SubmissionService,
    public dashboardData: DashboardData
  ) {
    super();
  }

  ngOnInit(): void {
    this.submissionCounterItems = [
      {
        description: 'Endorsement',
        icon: 'cil-library',
        url: '/dashboard',
      },
      {
        description: 'Payment',
        icon: 'cil-pen-alt',
        url: '/submissions/list?status=underwriting',
      },
      {
        description: 'Update Mortgagee',
        icon: 'cil-double-quote-sans-left',
        url: '/submissions/list?status=quoted',
      },
    ];
  }

  showModal(): void {
    const initialState = {
      title: this.agentDashboardConstants.startNewQuoteModalTitle,
    };
    this.modalRef = this.modalService.show(QuoteModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }
}
