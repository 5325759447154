import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { takeUntil, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectAppIsLoading, selectAppIsConfirmation } from 'app/store/app/app.selectors';
import { BaseClass } from './shared/base-class';
import Utils from './shared/utilities/utils';
import { Subject } from 'rxjs';

import { AuthService } from './core/services/auth.service';
import { DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { DOCUMENT } from '@angular/common';
import { PathConstants } from './shared/constants/path.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent extends BaseClass implements OnInit {
  private selectAppIsLoading$: Observable<boolean>;
  private selectAppIsConfirmation$: Observable<boolean>;
  private selectAppIsConfirmation: boolean | null;
  subscription: Subscription;
  protected maintenanceModeInformation: any;
  constructor(
    private router: Router,
    private store: Store,
      private authService: AuthService,
      private modalRef: BsModalRef,
      private modalService: BsModalService,
      @Inject(DOCUMENT) private document: Document
  ) {
    super();
    this.selectAppIsLoading$ = this.store.pipe(select(selectAppIsLoading));
    this.selectAppIsConfirmation$ = this.store.pipe(select(selectAppIsConfirmation));

    let userLoggedInData;
    this.authService.getUserLoggedIn().subscribe(userLoggedIn => {
      userLoggedInData = userLoggedIn;
      if(userLoggedIn && this.authService.isLoggedIn()){
        this.authService.idle.watch();
      }
    });

    this.authService.getUserLoggedIn().subscribe(userLoggedIn => {
      userLoggedInData = userLoggedIn;
    });

    if(userLoggedInData === undefined){
      if(localStorage.getItem('isUserLoggedIn') === 'true' && this.authService.isLoggedIn()){
        this.authService.idle.watch();
      }
    }

    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if(localStorage.getItem('isUserLoggedIn') === 'true'){
          this.authService.setUserLoggedIn(true);
        }
      }

      if (event instanceof NavigationEnd) {
        this.checkIPAddressInMaintenanceMode();
      }
    });

    window.onload = (e)=> {
      let tabId=sessionStorage.getItem("tabId");
      if (!tabId||(tabId!==sessionStorage.getItem("tabClosed"))){
          tabId=Math.random().toString(36).substr(2)+Date.now().toString(36).substr(5);
          sessionStorage.setItem("tabId",tabId);
      }

      if(localStorage.getItem("tabList")){
        let currentTabList = JSON.parse(localStorage.getItem("tabList"));
        let tabData = sessionStorage.getItem("tabId");
        let checkcurrentTabList = currentTabList.includes(tabData);
        if(!checkcurrentTabList){
          currentTabList.push(tabData);
          localStorage.removeItem('tabList');
          localStorage.setItem('tabList',JSON.stringify(currentTabList));
        }

      }else{
        let tabId=sessionStorage.getItem("tabId");
          let tabList = [];
          tabList.push(tabId)
          let newTabList = JSON.stringify(tabList);
          localStorage.setItem("tabList",newTabList);
      }
    }

    window.onbeforeunload = (e)=>{
      let tabIdDetails = sessionStorage.getItem('tabId');
      let tabListDetails = JSON.parse(localStorage.getItem('tabList'));

      tabListDetails = tabListDetails.filter(item => item != tabIdDetails);
      localStorage.removeItem('tabList');
      localStorage.setItem('tabList',JSON.stringify(tabListDetails));
    }

    let tabId=sessionStorage.getItem("tabId");
    sessionStorage.removeItem("tabClosed");
    window.addEventListener("unload",()=>{
        sessionStorage.setItem("tabClosed",tabId);
    });

  }

  @HostListener('document:click', ['$event'])
  handlerFunction(e: MouseEvent) {
    const url = this.router.url;
    const targetElement = document.querySelector('.my-modal');
    if(localStorage.getItem('isSessionTimeoutOpen') === 'false' || !localStorage.getItem('isSessionTimeoutOpen') ){
        if(!localStorage.getItem('auth')){
          if(!targetElement){
            if(
              url !== '/login' &&
              !url.includes('/404') &&
              url !==  '/account/forgot-password' &&
              url !== '/account/change-password-new-user' &&
              !url.includes('/account/reset-password') &&
              !url.includes('/redirectmakeapayment') &&
              !url.includes('/makeapayment') &&
              !url.includes('/compRater') &&
              !url.includes('/maintenance-mode')
            ) {
                this.document.location.href = '/dashboard';
            }
          }
        }else if(localStorage.getItem('auth')){
          if(url === '/login'){
            this.document.location.href = '/dashboard';
          }
        }
    }

  }

  ngOnInit() {
    if (window.location.href.indexOf("access") == -1){
      if(!sessionStorage.getItem('isLoggedIn') && !sessionStorage.getItem('tabClosed')){
        let tabList = JSON.parse(localStorage.getItem('tabList'));
        if( tabList.length < 1){
          localStorage.clear();
        }
      }
    }

    this.authService.idelTime();

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.subscribeToSelectAppIsLoading$();
    this.subscribeToSelectAppIsConfirmation$();
  }

  subscribeToSelectAppIsLoading$(): void {
    this.selectAppIsLoading$.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(isLoading => {
      if (!this.selectAppIsConfirmation) {
        if (isLoading === true) {
          Utils.blockUI();
          return;
        }

        setTimeout(() => {
          Utils.unblockUI();
        }, 1000);
      }
    });
  }

  subscribeToSelectAppIsConfirmation$(): void {
    this.selectAppIsConfirmation$.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(isConfirmation => {
      this.selectAppIsConfirmation = isConfirmation;
    });
  }

  checkIPAddressInMaintenanceMode(): void {
    const _ipAddress = localStorage.getItem('ipAddress');
    this.authService.getMaintenanceModeInformation(_ipAddress)
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.stop$),
        map((res: any) => {
        this.maintenanceModeInformation = <any>res;
        this.maintenanceModeLogic();
      })).subscribe();
  }

  maintenanceModeLogic(): void {
    if (this.maintenanceModeInformation) {
      if (this.maintenanceModeInformation.isMaintenanceMode) {
        if (!this.maintenanceModeInformation.isIpAddressWhiteList) {
          this.authService.setAuth(null);
          const tabArrDetails = localStorage.getItem('tabList');
          localStorage.clear();
          localStorage.setItem('tabList',tabArrDetails);
          this.authService.LoginDateFormGroup.get('loginDate').setValue(undefined);
          this.router.navigate([PathConstants.MaintenanceMode.Index]);
        }
      }
    }
  }

}

