import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-task-pagination',
  templateUrl: './task-pagination.component.html',
  styleUrls: ['./task-pagination.component.scss']
})
export class TaskPaginationComponent implements OnInit {
  @Input() pager: any = {};
  @Input() loading: boolean;

  @Output() setPage: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  setPageNumber(page: number) {
    this.setPage.emit(page);
  }
}
