import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { SubmissionListData } from 'app/modules/submission-management/data/submission-list.data';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from 'app/shared/base-class';
import { GenericLabel } from 'app/shared/constants/generic.labels.constants';
import { FormTypeConstants } from '../../../../../../shared/constants/form-types.constants';
import { StateConstants } from 'app/shared/constants/state-constants';

@Component({
  selector: 'app-submission-search-filter',
  templateUrl: './submission-search-filter.component.html',
  styleUrls: ['./submission-search-filter.component.scss']
})
export class SubmissionSearchFilterComponent extends BaseClass implements OnInit {

  public genericLabel = GenericLabel;
  private searchQuery: string = '';
  private searchStatus: string = '';
  private searchSubStatus: string = '';
  public act: string = 'ACT';
  public formTypeConstants = FormTypeConstants;
  public availableStates: string[] = null;

  hasMarginRight: boolean = true;

  @Output() submitSearch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  constructor(
    public submissionListData: SubmissionListData,
    public location: Location,
    public router: Router,
    public route: ActivatedRoute) {
      super();
  }

  ngOnInit(): void {

    this.submissionListData.initForms();

    this.submissionListData.searchForm.get('searchText').valueChanges.pipe(takeUntil(this.stop$)).subscribe(a => {
      this.searchQuery = a;
    });

    this.route.queryParamMap.pipe(takeUntil(this.stop$)).subscribe(queryParams => {
      this.searchQuery = queryParams.get('q')?.toString()?.trim();
      this.searchStatus = queryParams.get('status')?.toString()?.trim();
      this.searchSubStatus = queryParams.get('substatus')?.toString()?.trim();
    });

    if (this.searchQuery !== '' && this.searchQuery !== null && this.searchQuery !== undefined) {
      this.submissionListData.searchFilterForm = this.submissionListData.constructFormGroupSearchText(this.searchQuery.trim());
    } else if (this.searchStatus && !this.searchSubStatus) {
      this.submissionListData.searchFilterForm = this.submissionListData.constructFormGroupBasedOnPendingQuotes(this.searchStatus.trim());
    } else if (this.searchStatus && this.searchSubStatus) {
      this.submissionListData.searchFilterForm = this.submissionListData.constructFormGroupBasedOnStatusAndSubStatus(this.searchStatus.trim(),this.searchSubStatus.trim());
    } else {
      if (!this.submissionListData.isForPaymentDue) {
        this.submissionListData.searchFilterForm = this.submissionListData.constructFormGroupBasedOnStatusAndSubStatus(this.searchStatus?.trim(),this.searchSubStatus?.trim());
      }
    }

    const currentStates = ['FL', 'LA'];
    this.availableStates = StateConstants.filter(a => currentStates.includes(a.stateCode));

    this.submissionListData.buildSubmissionListRequest(this.submissionListData.searchFilterForm);
  }

  onSubmit(): void {
    this.submissionListData.showLoader = true;
    this.submissionListData.searchFilterForm.get('searchText').setValue(this?.searchQuery?.toString()?.trim());
    this.submitSearch.emit(this.submissionListData.searchFilterForm);
  }

  onSelectStatus(value: string): void {
    if (value.toUpperCase() !== this.act) {
      this.submissionListData.searchFilterForm.get('subStatus').setValue('');
    }
  }
}
