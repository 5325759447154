import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BillingLabelConstants } from '../../../../../../../app/shared/constants/bind-and-issue.labels.constants';
import { PaymentMethod } from '../../../../../../../app/shared/enum/payment-method.enum';
import { BaseClass } from 'app/shared/base-class';
import { AuthService } from 'app/core/services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { PolicyBillingData } from 'app/modules/policy-management/data/policy-billing.data';

@Component({
  selector: 'app-add-recurring-payment-agreement',
  templateUrl: './add-recurring-payment-agreement.component.html',
  styleUrls: ['./add-recurring-payment-agreement.component.scss']
})
export class AddRecurringPaymentAgreementComponent extends BaseClass implements OnInit {

  @Input() paymentForm: FormGroup;

  public BillingLabelConstants = BillingLabelConstants;
  isInternal: boolean;


  constructor(protected authService: AuthService, public billingData: PolicyBillingData) {
    super();
  }


  ngOnInit() {
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.isInternal = this.isInternalUser(userType);
    });
  }

  get isPaymentMethodRecurring(): boolean {
    return this.paymentForm.get('paymentMethod').value === PaymentMethod.RecurringCreditCard ||
      this.paymentForm.get('paymentMethod').value === PaymentMethod.RecurringECheck;
  }

  get isPaymentMethodOneTimeAuth(): boolean {
    return this.paymentForm.get('paymentMethod').value === PaymentMethod.CreditCard ||
      this.paymentForm.get('paymentMethod').value === PaymentMethod.EFT ||
      this.paymentForm.get('paymentMethod').value === PaymentMethod.ECheck;
  }

  get isPaymentMethodCreditCard(): boolean {
    return this.paymentForm.get('paymentMethod').value === PaymentMethod.CreditCard ||
      this.paymentForm.get('paymentMethod').value === PaymentMethod.RecurringCreditCard;
  }

  public isInternalUser(userType: string): boolean {
    return userType.toLocaleLowerCase() === 'internal';
  }

  toggleIAgreeInsuredCheckbox(isChecked: boolean): void {
    this.billingData.checkIfOneAgreeCheckbox(isChecked, this.isPaymentMethodRecurring ? this.paymentForm.get('agreeEnrollAutoPay').value : this.paymentForm.get('agreeOneTimePayment').value);
  }
  
  toggleIAgreeOneTimeAuthCheckbox(isChecked: boolean): void {
    this.billingData.checkIfOneAgreeCheckbox(this.paymentForm.controls.agreeInsured.value,isChecked);
  }
}
