import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicantRoutingModule } from './applicant-routing.module';
import { ApplicantComponent } from './applicant.component';
import { SharedModule } from '../../../shared/shared.module';
import { ApplicantContactComponent } from './contact/applicant-contact.component';
import { ApplicantCoApplicantComponent } from './coapplicant/applicant-coapplicant.component';
import { ApplicantMainApplicantComponent } from './mainapplicant/applicant-main-applicant.component';
import { NgxMaskModule } from 'ngx-mask';
import { FairCreditReportingActComponent } from './fcra/fair-credit-reporting-act.component';
import { CustomPipesModule } from '../../../shared/custom pipes/custom-pipe.module';
import { ApplicantInspectionComponent } from './inspection/applicant-inspection.component';


@NgModule({
  declarations: [ApplicantComponent, ApplicantContactComponent,
    ApplicantCoApplicantComponent , ApplicantMainApplicantComponent,
    FairCreditReportingActComponent,
    ApplicantInspectionComponent],
  imports: [
    CommonModule,
    ApplicantRoutingModule,
    SharedModule,
    NgxMaskModule.forRoot(),
    CustomPipesModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class ApplicantModule { }
