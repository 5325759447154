import { Injectable } from '@angular/core';
import { ITableFormControl, ITableTd, ITableTh, ITableTr } from 'app/shared/models/dynamic/table.interface';
import { BaseClass } from 'app/shared/base-class';
import { NotesLabelConstants } from 'app/shared/constants/bind-and-issue.labels.constants';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { NotesCategories } from 'app/shared/constants/notes.options.constants';
import { PolicyService } from 'app/core/services/submission/policy.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { ToastrService } from 'ngx-toastr';
import { PolicySummaryData } from './policy-summary.data';
import { PolicyNotesSaveDTO, PolicyRiskBindNoteDTO, RiskBindDTO } from 'app/shared/models/data/dto/policy-management/policy-notes-save.dto';
import * as _ from 'lodash';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { AllRiskBindNoteDTO } from 'app/shared/models/data/dto/policy-management/all-risk-bind-note.dto';
import { Observable } from 'rxjs';
import Utils from 'app/shared/utilities/utils';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { AuthService } from 'app/core/services/auth.service';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { PagerService } from 'app/core/services/pagerService';
import { RiskBindNoteDTO } from 'app/shared/models/data/dto/quick-quote/risk-bind-note.dto';

@Injectable({
  providedIn: 'root'
})
export class PolicyNotesData extends BaseClass {

  notesTableRows: ITableTr[] = [];
  public notesList: any[] = [];
  public currentRiskBindId: string;
  notesRiskDetailIds: string[] = [];

  notesTableHeaders: ITableTh[] = [
    { value: NotesLabelConstants.category },
    { value: NotesLabelConstants.description },
    { value: NotesLabelConstants.dateAdded },
    { value: NotesLabelConstants.addedBy }
  ];

  notesTableFormControls: ITableFormControl[] = [
    {
      name: 'field_1',
      type: 'string',
    },
    {
      name: 'field_2',
      type: 'string',
    },
    {
      name: 'field_3',
      type: 'string',
    },
    {
      name: 'field_4',
      type: 'string',
    },
  ];

  dateAddedOption: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'mm/dd/yyyy'
  };
  policyNotePager: any = {};
  policyPagedItems: ITableTr[] = [];
  currentPageNumber: number = 1;
  onInitPageNumber: number = 1;

  genericConstants = GenericConstants;

  constructor(
    protected policyService: PolicyService,
    protected policySummaryData: PolicySummaryData,
    protected toast: ToastrService,
    protected entityData: EntityRiskData,
    public authService: AuthService,
    protected pagerService: PagerService
  ) {
    super();
  }

  mapDate(date?): string {
    const month = new Date(date).getMonth() + 1 > 9 ? new Date(date).getMonth() + 1 : '0' + (new Date(date).getMonth() + 1);
    const day = new Date(date).getDate() > 9 ? new Date(date).getDate() : '0' + new Date(date).getDate();
    const year = new Date(date).getFullYear();

    return `${month}/${day}/${year}`;
  }

  saveNotes() {
    try {
      this.entityData.startSavingForms();
      this.policyService.isNotesSaving = true;

      const currentRiskDetailId = this.policySummaryData.riskDetailId;
      this.notesRiskDetailIds = this.notesList?.map(x => x.riskDetailId)
        .filter((value, index, self) => self.indexOf(value) === index && !!value);
      const currentRiskIdIndex = _.findIndex(this.notesRiskDetailIds, id => id === currentRiskDetailId);

      if (currentRiskIdIndex === -1) {
        this.notesRiskDetailIds.push(currentRiskDetailId);
      }

      const apiNotesSave$ = [];

      const payload = new PolicyNotesSaveDTO({
        riskId: this.policySummaryData.clonePolicyId,
        riskDetailId: currentRiskDetailId,
        riskBind: new RiskBindDTO({
          riskDetailId: currentRiskDetailId,
          riskBindNotes: []
        })
      });

      const filteredList = this.filterSystemGeneratedInNoteList();

      filteredList.forEach(x => {
        const riskBindNote: PolicyRiskBindNoteDTO = {
          id: x.id,
          riskBindId: x.riskBindId,
          category: x.category,
          description: x.description,
          createdBy: x.createdBy,
          createdDate: x.createdDate?.singleDate?.jsDate  ?? x.createdDate,
          riskDetailId: x.riskDetailId,
          isAdd: x.isAdd
        };
        payload.riskBind.riskBindNotes.push(riskBindNote);
      });

      apiNotesSave$.push(this.callApiSaveNotes(payload));

      if (apiNotesSave$.length !== 0) {
        Observable.forkJoin(apiNotesSave$)
          .subscribe(() => {
            this.policyService.isNotesSaving = false;
            this.entityData.finishSavingForms();
          },
            error => {
              this.policyService.isNotesSaving = false;
              this.entityData.finishSavingForms();
              console.log(error);
            });
      }

    } catch (error) {
      this.policyService.isNotesSaving = false;
      this.entityData.finishSavingForms();
    }
  }

  callApiSaveNotes(payload: PolicyNotesSaveDTO): Observable<any> {
    return this.policyService.postPolicyNotes(payload).pipe(takeUntil(this.stop$)).map(data => {
      this.updateCollectionID(data);
    }, error => {
      console.log(error);
    });
  }

  populateNotes(data: AllRiskBindNoteDTO[]) {
    this.notesList = [];
    this.notesTableRows = [];

    this.notesList = data.map(note => ({
      id: note?.id,
      riskBindId: note?.riskBindId,
      notesId: note?.id,
      description: note?.description,
      category: note?.category,
      createdDate: note?.createdDate,
      createdBy: note?.createdBy,
      isFromParent: note?.isFromParent,
      riskId: note?.riskId,
      detail: note?.detail,
      riskDetailId: note.riskDetailId,
      createdByFullName: note?.createdByFullName
    }));

    this.setInternalNotesList();
    this.initData(this.notesList);
  }

  initData(notesList: any) {
    if (this.authService.isExternalUser) {
      notesList = notesList.filter(item => {
        return !item.description.includes('OFAC');
      });
    }
  
    notesList = this.hideRenewalChangeNoteForExternalUser(notesList);
  
    notesList = notesList.map(item => {
      return {
        id: item.id,
        description: item?.description,
        category: item?.category,
        dateAdded: item?.createdDate,
        riskBindId: item?.riskBindId,
        notesId: item?.notesId,
        riskId: item?.riskId,
        createdBy: item?.createdBy,
        isFromParent: item?.isFromParent,
        createdByFullName: item?.createdByFullName,
        isAdd: false
      };
    });
  
    notesList.forEach(item => {
      this.addTableItem(item, item.id);
    });
  
    this.setPolicyNotesPage(this.onInitPageNumber);
  }

  hideRenewalChangeNoteForExternalUser(notesList: any): any {
    return notesList.filter(item => {
      return !(
        this.authService.isExternalUser &&
        item.category === 'RC' &&
        Number(item.createdBy) !== this.authService.getUserId()
      );
    });
  }

  addTableItem(newItem: any, id: any): void {
    const tr: ITableTd[] = [];
    const fields: string[] = [
      'category',
      'description',
      'dateAdded',
      'addedBy'
    ];
  
    fields.forEach((item, index) => {
      const display: string = this.updateDisplay(item, newItem);
  
      tr.push({
        id: id,
        value: Boolean(newItem[item]) ? newItem[item] : '',
        display: display,
      });
    });
  
    // Add the new item at the top of the notesTableRows array
    this.notesTableRows.unshift({
      id: id,
      tr: tr,
      createdBy: newItem.createdBy,
      isFromParent: newItem.isFromParent,
      createdDate: newItem.createdDate,
      isAdd: true
    });
  
    // Sort the notesTableRows array based on the dateAdded property in descending order
    this.notesTableRows.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
  }

  updateDisplay(item: any, newItem: any): string {
    switch (item) {
      case 'dateAdded':
        return newItem[item]?.singleDate?.formatted ? newItem[item]?.singleDate?.formatted : this.mapDate(newItem[item]);
      case 'category':
        return Boolean(newItem[item]) ? _.find(NotesCategories, ['code', newItem[item]])?.description : '';
      case 'addedBy' :
        return Boolean(newItem?.createdByFullName) ? newItem.createdByFullName : '';
      default:
        return Boolean(newItem[item]) ? String(newItem[item]) : '';
    }
  }

  updateCollectionID(response: any): void {
    // const riskNotes = response?.riskNotes;
    // if (riskNotes) {
    //   riskNotes.forEach(note => {
    //     const noteIndex = _.findIndex(this.notesList, item => item?.description === note?.description
    //       && item?.category === note?.category
    //       && Utils.mapDateToString(item?.createdDate?.singleDate?.formatted) === Utils.mapDateToString(note.createdDate)
    //       && !!!item.notesId);
    //     if (noteIndex !== -1) {
    //       this.notesList[noteIndex].notesId = note.id;
    //       this.notesList[noteIndex].isAdd = false;
    //     }
    //   });
    // }
    _.forEach(this.notesList, (item) => {
      item.isAdd = false;
    });
  }

  filterSystemGeneratedInNoteList(): any[] {
    return this.notesList.filter(a => a.detail !== 'System generated');
  }

  setPolicyNotesPage(page: number): void {
    if (page < 1) {
      return;
    }
    this.notesTableRows = _.orderBy(this.notesTableRows, ['createdDate'], ['asc']);
    this.policyNotePager = this.pagerService.getPager(this.notesTableRows.length, page);
    this.policyPagedItems = this.notesTableRows.slice(this.policyNotePager.startIndex, this.policyNotePager.endIndex + 1);
    this.currentPageNumber = page;
  }

  setInternalNotesList(): void {
    if (this.authService.isExternalUser) {
      const internal: string = 'INTR';
      const notesList: RiskBindNoteDTO[] = [...this.notesList];

      this.notesList = notesList.filter((item: RiskBindNoteDTO) => {
        return item.category !== internal;
      });
    }
  }
}
