import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { BaseClass } from '../../../../../shared/base-class';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { BatchPaymentsData } from '../../../../../modules/payments/data/batch-payments.data';
import { BatchPaymentDetailsDTO } from '../../../../../shared/models/data/dto/batch-payments/batch-payment-details.dto';
import { BatchPaymentsConstants } from '../../../../../shared/constants/batch-payments.constants';
import { LvCompany, LvPaidBy } from '../../../../../shared/constants/batch-payments.options.constants';
import { AuthService } from '../../../../../core/services/auth.service';
import { BillingService } from '../../../../../core/services/billing/billing.service';
import FormUtils from '../../../../../shared/utilities/form.utils';
import Utils from '../../../../../shared/utilities/utils';
import { CurrencyMaskInputMode } from 'ngx-currency';

@Component({
  selector: 'app-batch-suspense-payment-details-modal',
  templateUrl: './batch-suspense-payment-details-modal.component.html',
  styleUrls: ['./batch-suspense-payment-details-modal.component.scss']
})
export class BatchSuspensePaymentDetailsModalComponent extends BaseClass implements OnInit, OnDestroy {
  public CurrencyMaskInputMode = CurrencyMaskInputMode;
  public GenericLabel = GenericLabel;
  public FormUtils = FormUtils;
  public ErrorMessageConstant = ErrorMessageConstant;
  public BatchPaymentsLabel = BatchPaymentsConstants.Labels;
  public FieldNames = BatchPaymentsConstants.FieldNames;
  public LvPaidBy = LvPaidBy;
  public LvCompany = LvCompany;
  withBatch: boolean;
  isEditRecord: boolean;
  batchPaymentDetails: BatchPaymentDetailsDTO;
  batchPaymentDetailIndex: number;


  constructor(
    public batchPaymentsdata: BatchPaymentsData,
    private bsModalRef: BsModalRef,
    private billingService: BillingService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    this.batchPaymentsdata.isAddOrEditPaymentModalOpen = true;
    this.setBatchNumberValidator();
    this.populateFields();
    if (!this.batchPaymentDetails?.id) {
      this.batchPaymentsdata.resetDocumentVariables();
    }
  }

  get paymentForm(): FormGroup {
    return this.batchPaymentsdata.batchSuspensePaymentForm;
  }

  setBatchNumberValidator(): void {
    if (this.withBatch) {
      this.paymentForm.get(this.FieldNames.batchNumber).clearValidators();
      this.paymentForm.get(this.FieldNames.batchNumber).updateValueAndValidity();
    } else {
      this.paymentForm.get(this.FieldNames.batchNumber).clearValidators();
      this.paymentForm.get(this.FieldNames.batchNumber).setValidators([Validators.required]);
      this.paymentForm.get(this.FieldNames.batchNumber).updateValueAndValidity();
    }
  }

  populateFields(): void {
    if (!this.withBatch) {
      if (this.isEditRecord) {
        this.paymentForm.get(this.FieldNames.batchNumber).setValue(this.batchPaymentDetails?.batchNumber);
        this.paymentForm.get(this.FieldNames.batchDate).setValue(Utils.getShortDateFormat(this.batchPaymentDetails?.batchDate?.toString()));
        this.paymentForm.get(this.FieldNames.policyNumber).setValue(this.batchPaymentDetails?.policyNumber);
        this.paymentForm.get(this.FieldNames.amount).setValue(this.batchPaymentDetails?.amount);
        this.paymentForm.get(this.FieldNames.insuredName).setValue(this.batchPaymentDetails?.insuredName);
        this.paymentForm.get(this.FieldNames.paymentOrigination).setValue(this.batchPaymentDetails?.paymentOrigination);
        this.paymentForm.get(this.FieldNames.paidBy).setValue(this.batchPaymentDetails?.paidBy);
        this.paymentForm.get(this.FieldNames.company).setValue(this.batchPaymentDetails?.company);
        this.paymentForm.get(this.FieldNames.userId).setValue(this.batchPaymentDetails?.createdById ?? this.authService.getUserName());
        this.paymentForm.get(this.FieldNames.checkNumber).setValue(this.batchPaymentDetails?.checkNumber);
        Utils.unblockUI();
      } else {

        this.paymentForm.get(this.FieldNames.batchDate).setValue(
          this.batchPaymentDetails?.batchDate ? Utils.getShortDateFormat(this.batchPaymentDetails?.batchDate.toString()) : Utils.getCurrentDateInShortDateFormat()
        );
        this.paymentForm.get(this.FieldNames.userId).setValue(this.authService.getUserName());
        Utils.unblockUI();
      }

    } else {
      if (this.isEditRecord) {
        this.paymentForm.get(this.FieldNames.batchNumber).setValue(this.batchPaymentDetails?.batchNumber);
        this.paymentForm.get(this.FieldNames.batchDate).setValue(Utils.getShortDateFormat(this.batchPaymentDetails?.batchDate?.toString()));
        this.paymentForm.get(this.FieldNames.policyNumber).setValue(this.batchPaymentDetails?.policyNumber);
        this.paymentForm.get(this.FieldNames.amount).setValue(this.batchPaymentDetails?.amount);
        this.paymentForm.get(this.FieldNames.insuredName).setValue(this.batchPaymentDetails?.insuredName);
        this.paymentForm.get(this.FieldNames.paymentOrigination).setValue(this.batchPaymentDetails?.paymentOrigination);
        this.paymentForm.get(this.FieldNames.paidBy).setValue(this.batchPaymentDetails?.paidBy);
        this.paymentForm.get(this.FieldNames.company).setValue(this.batchPaymentDetails?.company);
        this.paymentForm.get(this.FieldNames.userId).setValue(this.batchPaymentDetails?.createdById);
        this.paymentForm.get(this.FieldNames.checkNumber).setValue(this.batchPaymentDetails?.checkNumber);
        Utils.unblockUI();
      } else {
        this.paymentForm.get(this.FieldNames.batchNumber).setValue(this.batchPaymentDetails?.batchNumber);
        this.paymentForm.get(this.FieldNames.batchDate).setValue(Utils.getShortDateFormat(this.batchPaymentDetails?.batchDate?.toString()));
        this.paymentForm.get(this.FieldNames.userId).setValue(this.batchPaymentDetails?.createdById ?? this.authService.getUserName());
        Utils.unblockUI();
      }
    }
  }

  hideModal(): void {
    this.batchPaymentsdata.isAddOrEditPaymentModalOpen = false;
    this.batchPaymentsdata.batchSuspensePaymentForm.reset();
    this.bsModalRef.hide();
  }

  setTemporaryTableView(data: BatchPaymentDetailsDTO): void {
    const newEntry = {
      batchDate: data.batchDate,
      batchNumber: data.batchNumber ?? '',
      policyNumber: data.policyNumber ?? '',
      insuredName: data.insuredName ?? '',
      amount: data.amount ?? 0,
      checkNumber: data.checkNumber ?? '',
      paymentOrigination: data.paymentOrigination ?? '',
      processCode: data.processCode ?? '',
      paidBy: data.paidBy ?? '',
      company: data.company ?? '',
      createdById: data.createdById ?? '',
      isActive: data.isActive,
      isBatchInProcessed: data.isBatchInProcessed,
      batchPaymentRelatedDoc: data.batchPaymentRelatedDoc
    };

    this.batchPaymentsdata.pendingBatchPaymentDetails?.push(newEntry);

    if (!this.withBatch) {
      this.batchPaymentsdata.batchPaymentSaveButtonValidation();
    }
  }

  onSave(): void {

    if (!this.isEditRecord) {
      const request: BatchPaymentDetailsDTO = {
        batchDate: this.withBatch ? this.batchPaymentDetails?.batchDate : new Date(),
        batchNumber: this.withBatch ? this.batchPaymentDetails?.batchNumber : '',
        policyNumber: this.paymentForm.get(this.FieldNames.policyNumber).value,
        insuredName: this.paymentForm.get(this.FieldNames.insuredName).value,
        amount: this.paymentForm.get(this.FieldNames.amount).value,
        checkNumber: this.paymentForm.get(this.FieldNames.checkNumber).value,
        paymentOrigination: this.paymentForm.get(this.FieldNames.paymentOrigination).value,
        processCode: 'N',
        paidBy: this.paymentForm.get(this.FieldNames.paidBy).value,
        company: this.paymentForm.get(this.FieldNames.company).value,
        createdById: this.paymentForm.get(this.FieldNames.userId).value,
        isActive: true,
        isBatchInProcessed: false,
        batchPaymentRelatedDoc: this.batchPaymentsdata.documentRequest
      };

      if (this.withBatch) {
        const record: BatchPaymentDetailsDTO[] = [];
        record.push(request);
        this.batchPaymentsdata.postBatchPayment(record);
      }

      this.batchPaymentsdata.editPaymentDocumentTableRows.push(this.batchPaymentsdata.paymentDocumentTableRows);
      this.setTemporaryTableView(request);
      this.hideModal();

    } else {
      const data: BatchPaymentDetailsDTO = {
        id: this.batchPaymentDetails.id,
        batchDate: this.withBatch ? this.batchPaymentDetails?.batchDate : new Date(),
        batchNumber: this.batchPaymentDetails.batchNumber,
        policyNumber: this.paymentForm.get(this.FieldNames.policyNumber).value,
        insuredName: this.paymentForm.get(this.FieldNames.insuredName).value,
        amount: this.paymentForm.get(this.FieldNames.amount).value,
        checkNumber: this.paymentForm.get(this.FieldNames.checkNumber).value,
        paymentOrigination: this.paymentForm.get(this.FieldNames.paymentOrigination).value,
        processCode: 'N',
        paidBy: this.paymentForm.get(this.FieldNames.paidBy).value,
        company: this.paymentForm.get(this.FieldNames.company).value,
        createdById: this.paymentForm.get(this.FieldNames.userId).value,
        isActive: true,
        isBatchInProcessed: false,
        batchPaymentRelatedDoc: this.batchPaymentsdata.documentRequest
      };

      if (this.withBatch) {
        this.batchPaymentsdata.putUpdateBatchPaymentDetail(data);
      }

      this.hideModal();
    }

  }

  ngOnDestroy(): void {
    this.batchPaymentsdata.batchPayment = [];
    this.batchPaymentsdata.isAddOrEditPaymentModalOpen = false;
  }
}
