export const LvRaterRoofCover = [{
  tableName: 'LvRaterRoofCover',
  tableDescription: 'Roof Cover',
  code: 'B',
  description: 'Non-FBC Equivalent',
  isActive: true,
  id: 1
},
{
  tableName: 'LvRaterRoofCover',
  tableDescription: 'Roof Cover',
  code: 'F',
  description: 'FBC Equivalent',
  isActive: true,
  id: 2
}];

export const LvRaterRoofDeckAttachment = [{
  tableName: 'LvRaterRoofDeckAttachment',
  tableDescription: 'Roof Deck Attachment',
  code: 'A',
  description: '6d @ 6/12',
  isActive: true,
  id: 1
},
{
  tableName: 'LvRaterRoofDeckAttachment',
  tableDescription: 'Roof Deck Attachment',
  code: 'B',
  description: '8d @ 6/12',
  isActive: true,
  id: 2
},
{
  tableName: 'LvRaterRoofDeckAttachment',
  tableDescription: 'Roof Deck Attachment',
  code: 'C',
  description: '8d @ 6/6',
  isActive: true,
  id: 3
},
{
  tableName: 'LvRaterRoofDeckAttachment',
  tableDescription: 'Roof Deck Attachment',
  code: 'O',
  description: 'Other',
  isActive: true,
  id: 4
},
{
  tableName: 'LvRaterRoofDeckAttachment',
  tableDescription: 'Roof Deck Attachment',
  code: 'R',
  description: 'Reinforced Concrete Roof Deck',
  isActive: false,
  id: 5
}];

export const LvRaterRoofToWallAttachment = [{
  tableName: 'LvRaterRoofToWallAttachment',
  tableDescription: 'Roof To Wall',
  code: 'T',
  description: 'Toe Nails',
  isActive: true,
  id: 1
},
{
  tableName: 'LvRaterRoofToWallAttachment',
  tableDescription: 'Roof To Wall',
  code: 'C',
  description: 'Clips',
  isActive: true,
  id: 2
},
{
  tableName: 'LvRaterRoofToWallAttachment',
  tableDescription: 'Roof To Wall',
  code: 'S',
  description: 'Single Wraps',
  isActive: true,
  id: 3
},
{
  tableName: 'LvRaterRoofToWallAttachment',
  tableDescription: 'Roof To Wall',
  code: 'D',
  description: 'Double Wraps',
  isActive: true,
  id: 4
},
{
  tableName: 'LvRaterRoofToWallAttachment',
  tableDescription: 'Roof To Wall',
  code: 'U',
  description: 'Unknown or Unidentified',
  isActive: true,
  id: 5
}];

export const LvRaterRoofGeometry = [{
  tableName: 'LvRaterRoofGeometry',
  tableDescription: 'Roof Geometry',
  code: 'F',
  description: 'Flat',
  isActive: true,
  id: 1
},
{
  tableName: 'LvRaterRoofGeometry',
  tableDescription: 'Roof Geometry',
  code: 'G',
  description: 'Gable',
  isActive: true,
  id: 2
},
{
  tableName: 'LvRaterRoofGeometry',
  tableDescription: 'Roof Geometry',
  code: 'H',
  description: 'Hip',
  isActive: true,
  id: 3
},
{
  tableName: 'LvRaterRoofGeometry',
  tableDescription: 'Roof Geometry',
  code: 'N',
  description: 'Non-Hip, Other',
  isActive: true,
  id: 4
},
{
  tableName: 'LvRaterRoofGeometry',
  tableDescription: 'Roof Geometry',
  code: 'O',
  description: 'Other',
  isActive: true,
  id: 5
}];

export const LvRaterRoofShape = [{
  tableName: 'LvRaterRoofShape',
  tableDescription: 'Roof Shape',
  code: 'F',
  description: 'Flat',
  isActive: true
},
{
  tableName: 'LvRaterRoofShape',
  tableDescription: 'Roof Shape',
  code: 'G',
  description: 'Gable',
  isActive: true
},
{
  tableName: 'LvRaterRoofShape',
  tableDescription: 'Roof Shape',
  code: 'H',
  description: 'Hip',
  isActive: true
},
{
  tableName: 'LvRaterRoofShape',
  tableDescription: 'Roof Shape',
  code: 'GM',
  description: 'Gambrel',
  isActive: true
},
{
  tableName: 'LvRaterRoofShape',
  tableDescription: 'Roof Shape',
  code: 'M',
  description: 'Mansard',
  isActive: true
},
{
  tableName: 'LvRaterRoofShape',
  tableDescription: 'Roof Shape',
  code: 'S',
  description: 'Shed',
  isActive: true
},
{
  tableName: 'LvRaterRoofShape',
  tableDescription: 'Roof Shape',
  code: 'C',
  description: 'Complex/Custom',
  isActive: true
}];

export const LvRaterOpeningProtection = [{
  tableName: 'LvRaterOpeningProtection',
  tableDescription: 'Opening Protection',
  code: 'A',
  description: 'Class A - Hurricane Impact',
  isActive: true,
  id: 1
},
{
  tableName: 'LvRaterOpeningProtection',
  tableDescription: 'Opening Protection',
  code: 'B',
  description: 'Class B - Basic Impact',
  isActive: true,
  id: 2
},
{
  tableName: 'LvRaterOpeningProtection',
  tableDescription: 'Opening Protection',
  code: 'N',
  description: 'None',
  isActive: true,
  id: 3
}];

export const LvRaterTerrain = [{
  tableName: 'LvRaterTerrain',
  tableDescription: 'Terrain',
  code: 'B',
  description: 'Terrain B',
  isActive: true
},
{
  tableName: 'LvRaterTerrain',
  tableDescription: 'Terrain',
  code: 'C',
  description: 'Terrain C',
  isActive: true
},
{
  tableName: 'LvRaterTerrain',
  tableDescription: 'Terrain',
  code: 'H',
  description: 'High Velocity Hurricane Zone',
  isActive: true
}];

export const LvRaterWindDesignSpeed = [{
  tableName: 'LvRaterWindSpeed',
  tableDescription: 'Wind Speed',
  code: '100',
  description: '=> 100 mph',
  isActive: true
},
{
  tableName: 'LvRaterWindSpeed',
  tableDescription: 'Wind Speed',
  code: '110',
  description: '=> 110 mph',
  isActive: true
},
{
  tableName: 'LvRaterWindSpeed',
  tableDescription: 'Wind Speed',
  code: '120',
  description: '=> 120 mph',
  isActive: true
}];

export const LvConstructionType = [{
  tableName: 'LvTrtstConType',
  tableDescription: 'Construction Type',
  code: 'FMV',
  description: 'Frame',
  isActive: true
},
{
  tableName: 'LvTrtstConType',
  tableDescription: 'Construction Type',
  code: 'MAS',
  description: 'Masonry',
  isActive: true
},
{
  tableName: 'LvTrtstConType',
  tableDescription: 'Construction Type',
  code: 'SUP',
  description: 'Superior',
  isActive: true
}];

export const LvRaterExteriorWallFinish = [{
  tableName: 'LvRaterExteriorWallFinish',
  tableDescription: 'Exterior Wall Finish',
  code: 'AOM',
  description: 'Aluminum or Metal',
  isActive: true
},
{
  tableName: 'LvRaterExteriorWallFinish',
  tableDescription: 'Exterior Wall Finish',
  code: 'VVS',
  description: 'Vinyl/Vinyl Shingles',
  isActive: true
},
{
  tableName: 'LvRaterExteriorWallFinish',
  tableDescription: 'Exterior Wall Finish',
  code: 'HHP',
  description: 'Hardboard/Masonite, Hardiplank',
  isActive: true
},
{
  tableName: 'LvRaterExteriorWallFinish',
  tableDescription: 'Exterior Wall Finish',
  code: 'SFI',
  description: 'Cement Fiber',
  isActive: true
},
{
  tableName: 'LvRaterExteriorWallFinish',
  tableDescription: 'Exterior Wall Finish',
  code: 'WSS',
  description: 'Wood Shingle/Shake',
  isActive: true
},
{
  tableName: 'LvRaterExteriorWallFinish',
  tableDescription: 'Exterior Wall Finish',
  code: 'SST',
  description: 'Synthetic Stucco (EIFS)',
  isActive: true
},
{
  tableName: 'LvRaterExteriorWallFinish',
  tableDescription: 'Exterior Wall Finish',
  code: 'STR',
  description: 'Stucco - Traditional',
  isActive: true
},
{
  tableName: 'LvRaterExteriorWallFinish',
  tableDescription: 'Exterior Wall Finish',
  code: 'BSV',
  description: 'Brick or Stone Veneer',
  isActive: true
},
{
  tableName: 'LvRaterExteriorWallFinish',
  tableDescription: 'Exterior Wall Finish',
  code: 'SBS',
  description: 'Solid Brick or Stone',
  isActive: true
},
{
  tableName: 'LvRaterExteriorWallFinish',
  tableDescription: 'Exterior Wall Finish',
  code: 'OTH',
  description: 'None',
  isActive: true
}];

export const LvMilesToStation = [{
  tableName: 'LvMilesToStation',
  tableDescription: 'Miles to Fire Station',
  code: 'MS0',
  description: 'Five road miles or less',
  isActive: true
},
{
  tableName: 'LvMilesToStation',
  tableDescription: 'Miles to Fire Station',
  code: 'MS1',
  description: 'Greater than 5 road miles',
  isActive: true
}
];

export const LvRaterFoundationType = [{
  tableName: 'LvRaterFoundationType',
  tableDescription: 'Foundation Type',
  code: 'BM',
  description: 'Basement',
  isActive: true
},
{
  tableName: 'LvRaterFoundationType',
  tableDescription: 'Foundation Type',
  code: 'CS',
  description: 'Concrete Slab',
  isActive: true
},
{
  tableName: 'LvRaterFoundationType',
  tableDescription: 'Foundation Type',
  code: 'CR',
  description: 'Crawlspace',
  isActive: true
},
{
  tableName: 'LvRaterFoundationType',
  tableDescription: 'Foundation Type',
  code: 'PB',
  description: 'Pier & Grade Beam',
  isActive: true
},
{
  tableName: 'LvRaterFoundationType',
  tableDescription: 'Foundation Type',
  code: 'EP',
  description: 'Elevated Post/Pier & Beam (Stilts)',
  isActive: true
},
{
  tableName: 'LvRaterFoundationType',
  tableDescription: 'Foundation Type',
  code: 'SW',
  description: 'Stilts with Sweep Away Walls',
  isActive: true
},
{
  tableName: 'LvRaterFoundationType',
  tableDescription: 'Foundation Type',
  code: 'DP',
  description: 'Deep Pilings',
  isActive: true
}];

export const LvRoofMaterial = [
  {
    tableName: 'LvRoofMaterial ',
    tableDescription: 'Roof Materials',
    code: 'COMP3',
    description: 'Composition - 3 Tab Shingle',
    isActive: true,
    isHO3: true,
    isHO4: true,
    isHO6: true,
    isDP3: true
  },
  {
    tableName: 'LvRoofMaterial ',
    tableDescription: 'Roof Materials',
    code: 'COMPA',
    description: 'Composition - Architectural Shingle',
    isActive: true,
    isHO3: true,
    isHO4: true,
    isHO6: true,
    isDP3: true
  },
  {
    tableName: 'LvRoofMaterial ',
    tableDescription: 'Roof Materials',
    code: 'COMPI',
    description: 'Composition - Impact Resistant Shingle',
    isActive: true,
    isHO3: true,
    isHO4: true,
    isHO6: true,
    isDP3: true
  },
  {
    tableName: 'LvRoofMaterial ',
    tableDescription: 'Roof Materials',
    code: 'COMPR',
    description: 'Composition - Roll Roofing',
    isActive: true,
    isHO3: true,
    isHO4: true,
    isHO6: true,
    isDP3: true
  },
  {
    tableName: 'LvRoofMaterial ',
    tableDescription: 'Roof Materials',
    code: 'BU',
    description: 'Built-up (Hot Mopped)',
    isActive: true,
    isHO3: true,
    isHO4: true,
    isHO6: true,
    isDP3: true
  },
  {
    tableName: 'LvRoofMaterial ',
    tableDescription: 'Roof Materials',
    code: 'METC',
    description: 'Metal - Corrugated Galvanized',
    isActive: true,
    isHO3: true,
    isHO4: true,
    isHO6: true,
    isDP3: true
  },
  {
    tableName: 'LvRoofMaterial ',
    tableDescription: 'Roof Materials',
    code: 'META',
    description: 'Metal - All Other ',
    isActive: true,
    isHO3: true,
    isHO4: true,
    isHO6: true,
    isDP3: true
  },
  {
    tableName: 'LvRoofMaterial ',
    tableDescription: 'Roof Materials',
    code: 'TICL',
    description: 'Tile - Clay',
    isActive: true,
    isHO3: true,
    isHO4: true,
    isHO6: true,
    isDP3: true
  },
  {
    tableName: 'LvRoofMaterial ',
    tableDescription: 'Roof Materials',
    code: 'TICO',
    description: 'Tile - Concrete',
    isActive: true,
    isHO3: true,
    isHO4: true,
    isHO6: true,
    isDP3: true
  },
  {
    tableName: 'LvRoofMaterial ',
    tableDescription: 'Roof Materials',
    code: 'TICE',
    description: 'Tile - Cement Fiber',
    isActive: true,
    isHO3: true,
    isHO4: true,
    isHO6: true,
    isDP3: true
  },
  {
    tableName: 'LvRoofMaterial ',
    tableDescription: 'Roof Materials',
    code: 'MEMEPDM',
    description: 'Membrane - EPDM or PVC',
    isActive: true,
    isHO3: true,
    isHO4: true,
    isHO6: true,
    isDP3: true
  },
  {
    tableName: 'LvRoofMaterial ',
    tableDescription: 'Roof Materials',
    code: 'WSS',
    description: 'Wood Shingles or Shakes',
    isActive: true,
    isHO3: true,
    isHO4: true,
    isHO6: true,
    isDP3: true
  },
  {
    tableName: 'LvRoofMaterial ',
    tableDescription: 'Roof Materials',
    code: 'SL',
    description: 'Slate',
    isActive: true,
    isHO3: true,
    isHO4: true,
    isHO6: true,
    isDP3: true
  },
  {
    tableName: 'LvRoofMaterial ',
    tableDescription: 'Roof Materials',
    code: 'SPF',
    description: 'Sprayed Polyurethane Foam (SPF)',
    isActive: true,
    isHO3: true,
    isHO4: true,
    isHO6: true,
    isDP3: true
  },
  {
    tableName: 'LvRoofMaterial ',
    tableDescription: 'Roof Materials',
    code: 'U',
    description: 'Unknown',
    isActive: false,
    isHO3: true,
    isHO4: true,
    isHO6: true,
    isDP3: true
  },
  {
    tableName: 'LvRoofMaterial ',
    tableDescription: 'Roof Materials',
    code: 'PC',
    description: 'Poured Concrete',  // not in iso360
    isActive: true,
    isHO3: true,
    isHO4: true,
    isHO6: true,
    isDP3: true
  }
];

export const LvPrimaryHeatType = [
  { description: 'Central Electric Heat', code: 'CEH', id: 1 },
  { description: 'Central Gas Heat', code: 'CGH', id: 2 },
  { description: 'Kerosene Heater', code: 'KH', id: 3 },
  { description: 'Portable electric space heater', code: 'PESH', id: 4 },
  { description: 'Portable gas space heater', code: 'PGSH', id: 5 },
  { description: 'Solar Heat', code: 'SH', id: 6 },
  { description: 'Wood Burning Stove', code: 'WBS', id: 7 },
  { description: 'Other', code: 'OTH', id: 8 }
];

export const StructureTypeTownhouseInteriorUnit = 'I';
export const StructureTypeTownhouseEndUnit = 'E';
export const LvStructureType = [
  {
    tableName: 'LvStructureType',
    tableDescription: 'Structure Type',
    code: '1',
    description: 'One family',
    isActive: true,
    isHO3: true,
    isHO4: false,
    isHO6: true,
    raterCode: 1
  },
  {
    tableName: 'LvStructureType',
    tableDescription: 'Structure Type',
    code: 'E',
    description: 'Townhouse End Unit',
    isActive: true,
    isHO3: true,
    isHO4: false,
    isHO6: true,
    raterCode: 1
  },
  {
    tableName: 'LvStructureType',
    tableDescription: 'Structure Type',
    code: 'I',
    description: 'Townhouse Interior Unit',
    isActive: true,
    isHO3: true,
    isHO4: false,
    isHO6: true,
    raterCode: 1
  },
  {
    tableName: 'LvStructureType',
    tableDescription: 'Structure Type',
    code: '2',
    description: 'Two family (Duplex)',
    isActive: true,
    isHO3: true,
    isHO4: false,
    isHO6: true,
    raterCode: 2
  },
  {
    tableName: 'LvStructureType',
    tableDescription: 'Structure Type',
    code: '3',
    description: 'Three family (Triplex)',
    isActive: true,
    isHO3: true,
    isHO4: false,
    isHO6: true,
    raterCode: 3
  },
  {
    tableName: 'LvStructureType',
    tableDescription: 'Structure Type',
    code: '4',
    description: 'Four Family (Four-plex)',
    isActive: true,
    isHO3: true,
    isHO4: false,
    isHO6: true,
    raterCode: 4
  },
  {
    tableName: 'LvStructureType',
    tableDescription: 'Structure Type',
    code: 'MU',
    description: 'Multi-family (5+ units)',
    isActive: true,
    isHO3: true,
    isHO4: false,
    isHO6: true,
    raterCode: 4
  },
  {
    tableName: 'LvStructureType',
    tableDescription: 'Structure Type',
    code: 'RE',
    description: 'Residential',
    isActive: true,
    isHO3: false,
    isHO4: true,
    isHO6: false,
    raterCode: 1
  },
  {
    tableName: 'LvStructureType',
    tableDescription: 'Structure Type',
    code: 'CR',
    description: 'Commercial Residential',
    isActive: true,
    isHO3: false,
    isHO4: true,
    isHO6: false,
    raterCode: 1
  }
];

export const LvNumberOfStories = [
  {
    tableName: 'LvNumberOfStories',
    tableDescription: 'Number of Stories',
    code: '1',
    description: '1 Story',
    isActive: true
  },
  {
    tableName: 'LvNumberOfStories',
    tableDescription: 'Number of Stories',
    code: '1.5',
    description: '1 1/2 Stories',
    isActive: true
  },
  {
    tableName: 'LvNumberOfStories',
    tableDescription: 'Number of Stories',
    code: '2',
    description: '2 Stories',
    isActive: true
  },
  {
    tableName: 'LvNumberOfStories',
    tableDescription: 'Number of Stories',
    code: '2.5',
    description: '2 1/2 Stories',
    isActive: true
  },
  {
    tableName: 'LvNumberOfStories',
    tableDescription: 'Number of Stories',
    code: '3',
    description: '3 Stories',
    isActive: true
  },
  {
    tableName: 'LvNumberOfStories',
    tableDescription: 'Number of Stories',
    code: '3.5',
    description: '3 1/2 Stories',
    isActive: true
  },
  {
    tableName: 'LvNumberOfStories',
    tableDescription: 'Number of Stories',
    code: '4',
    description: '4 Stories',
    isActive: true
  },
  {
    tableName: 'LvNumberOfStories',
    tableDescription: 'Number of Stories',
    code: 'BI',
    description: 'Bi-level (raised ranch)',
    isActive: true
  },
  {
    tableName: 'LvNumberOfStories',
    tableDescription: 'Number of Stories',
    code: 'SPLIT',
    description: 'Split level',
    isActive: true
  }
];

export const LvRaterPropertyUsage = [
  {
    tableName: 'LvRaterPropertyUsage',
    tableDescription: 'Property Usage List',
    code: 'OOP',
    description: 'Owner occupied primary',
    isActive: true
  },
  {
    tableName: 'LvRaterPropertyUsage',
    tableDescription: 'Property Usage List',
    code: 'OOS',
    description: 'Owner occupied secondary',
    isActive: true
  },
  {
    tableName: 'LvRaterPropertyUsage',
    tableDescription: 'Property Usage List',
    code: 'SS',
    description: 'Seasonal',
    isActive: true
  },
  {
    tableName: 'LvRaterPropertyUsage',
    tableDescription: 'Property Usage List',
    code: 'TO',
    description: 'Tenant Occupied',
    isActive: true
  },
  {
    tableName: 'LvRaterPropertyUsage',
    tableDescription: 'Property Usage List',
    code: 'STR',
    description: 'Short Term Rental',
    isActive: true
  },
  {
    tableName: 'LvRaterPropertyUsage',
    tableDescription: 'Property Usage List',
    code: 'UOCC',
    description: 'Unoccupied',
    isActive: true
  },
  {
    tableName: 'LvRaterPropertyUsage',
    tableDescription: 'Property Usage List',
    code: 'VCN',
    description: 'Vacant',
    isActive: true
  }
];

export const LvRaterPrimaryHeatType = [
  {
    tableName: 'LvRaterPrimaryHeatType',
    tableDescription: 'Primary Heat Type',
    code: 'CEH',
    description: 'Central Electric Heat',
    isActive: true
  },
  {
    tableName: 'LvRaterPrimaryHeatType',
    tableDescription: 'Primary Heat Type',
    code: 'CGH',
    description: 'Central Gas Heat',
    isActive: true
  },
  {
    tableName: 'LvRaterPrimaryHeatType',
    tableDescription: 'Primary Heat Type',
    code: 'KH',
    description: 'Kerosene Heater',
    isActive: true
  },
  {
    tableName: 'LvRaterPrimaryHeatType',
    tableDescription: 'Primary Heat Type',
    code: 'PESH',
    description: 'Portable Electric Space Heater',
    isActive: true
  },
  {
    tableName: 'LvRaterPrimaryHeatType',
    tableDescription: 'Primary Heat Type',
    code: 'PGSH',
    description: 'Portable Gas Space Heater',
    isActive: true
  },
  {
    tableName: 'LvRaterPrimaryHeatType',
    tableDescription: 'Primary Heat Type',
    code: 'SH',
    description: 'Solar Heat',
    isActive: true
  },
  {
    tableName: 'LvRaterPrimaryHeatType',
    tableDescription: 'Primary Heat Type',
    code: 'WBS',
    description: 'Wood Burning Stove',
    isActive: true
  },
  {
    tableName: 'LvRaterPrimaryHeatType',
    tableDescription: 'Primary Heat Type',
    code: 'OTH',
    description: 'Other',
    isActive: true
  }
];

export const LvFeetToHydrant = [
  {
    tableName: 'LvFeetToHydrant',
    tableDescription: 'Feet to Hydrant',
    code: 'FH0',
    description: '1,000 ft or less',
    isActive: true
  },
  {
    tableName: 'LvFeetToHydrant',
    tableDescription: 'Feet to Hydrant',
    code: 'FH1',
    description: 'more than 1,000 ft',
    isActive: true
  }
];

export const LvNumberOfUnitsBetweenFireWalls = [
  {
    tableName: 'LvNumberOfUnitsBetweenFireWalls',
    tableDescription: 'Number of Units between Fire Walls',
    code: 'NU0',
    description: 'None',
    isActive: true,
    raterCode: 0
  },
  {
    tableName: 'LvNumberOfUnitsBetweenFireWalls',
    tableDescription: 'Number of Units between Fire Walls',
    code: 'NU1',
    description: '1',
    isActive: true,
    raterCode: 1
  },
  {
    tableName: 'LvNumberOfUnitsBetweenFireWalls',
    tableDescription: 'Number of Units between Fire Walls',
    code: 'NU2',
    description: '2',
    isActive: true,
    raterCode: 2
  },
  {
    tableName: 'LvNumberOfUnitsBetweenFireWalls',
    tableDescription: 'Number of Units between Fire Walls',
    code: 'NU3',
    description: '3',
    isActive: true,
    raterCode: 3
  },
  {
    tableName: 'LvNumberOfUnitsBetweenFireWalls',
    tableDescription: 'Number of Units between Fire Walls',
    code: 'NU4',
    description: '4',
    isActive: true,
    raterCode: 4
  }
];

export const LvFoundationElevation = [
  {
    tableName: 'LvFoundationElevation',
    tableDescription: 'Foundation Elevation',
    code: 'FE0',
    description: 'Less than 6 feet',
    isActive: true
  },
  {
    tableName: 'LvFoundationElevation',
    tableDescription: 'Foundation Elevation',
    code: 'FE1',
    description: '6-10 feet',
    isActive: true
  },
  {
    tableName: 'LvFoundationElevation',
    tableDescription: 'Foundation Elevation',
    code: 'FE2',
    description: 'Greater than 10 feet ',
    isActive: true
  }
];

export const LvElectrical = [
  {
    tableName: 'LvElectrical',
    tableDescription: 'Electrical List',
    code: 'EO0',
    description: 'Circuit Breakers - no aluminum ',
    isActive: true
  },
  {
    tableName: 'LvElectrical',
    tableDescription: 'Electrical List',
    code: 'EO1',
    description: 'Aluminum Wiring (all or partial)',
    isActive: true
  },
  {
    tableName: 'LvElectrical',
    tableDescription: 'Electrical List',
    code: 'EO2',
    description: 'Knob and Tube',
    isActive: true
  },
  {
    tableName: 'LvElectrical',
    tableDescription: 'Electrical List',
    code: 'EO3',
    description: 'Fuses',
    isActive: true
  }
];

export const LvMitigationForm = [
  {
    tableName: 'LvMitigationForm',
    tableDescription: 'Mitigation Form',
    code: 'NF',
    description: 'No Form',
    isActive: true
  },
  {
    tableName: 'LvMitigationForm',
    tableDescription: 'Mitigation Form',
    code: 'OR',
    description: 'OIR-B1-1802 Rev 01/12',
    isActive: true
  },
  {
    tableName: 'LvMitigationForm',
    tableDescription: 'Mitigation Form',
    code: 'NFC',
    description: 'No Form - Converted',
    isActive: true
  },
  {
    tableName: 'LvMitigationForm',
    tableDescription: 'Mitigation Form',
    code: 'HO',
    description: 'HO A 001 09 09',
    isActive: true
  },
  {
    tableName: 'LvMitigationForm',
    tableDescription: 'Mitigation Form',
    code: 'IBHS',
    description: 'IBHS Certification (Fortified Safer Homes)',
    isActive: true
  },
];

export const LvGableEndBracing = [
  {
    tableName: 'LvGableEndBracing',
    tableDescription: 'Gable End Bracing',
    code: 'GEB2',
    description: 'Does not apply - no gable or gambrel roof shapes',
    isActive: true
  },
  {
    tableName: 'LvGableEndBracing',
    tableDescription: 'Gable End Bracing',
    code: 'GEB0',
    description: 'No',
    isActive: true
  },
  {
    tableName: 'LvGableEndBracing',
    tableDescription: 'Gable End Bracing',
    code: 'GEB3',
    description: 'Unknown or Unidentified',
    isActive: true
  },
  {
    tableName: 'LvGableEndBracing',
    tableDescription: 'Gable End Bracing',
    code: 'GEB1',
    description: 'Yes',
    isActive: true
  }
];

export const LvQualifiedInspectorType = [
  {
    tableName: 'LvQualifiedInspectorType',
    tableDescription: 'Qualified Inspector Type',
    code: 'QIT0',
    description: 'Building Code Inspector',
    isActive: true
  },
  {
    tableName: 'LvQualifiedInspectorType',
    tableDescription: 'Qualified Inspector Type',
    code: 'QIT1',
    description: 'General Building or Residential Contractor',
    isActive: true
  },
  {
    tableName: 'LvQualifiedInspectorType',
    tableDescription: 'Qualified Inspector Type',
    code: 'QIT2',
    description: 'Home Inspector',
    isActive: true
  },
  {
    tableName: 'LvQualifiedInspectorType',
    tableDescription: 'Qualified Inspector Type',
    code: 'QIT3',
    description: 'Hurricane Mitigation Inspector',
    isActive: true
  },
  {
    tableName: 'LvQualifiedInspectorType',
    tableDescription: 'Qualified Inspector Type',
    code: 'QIT4',
    description: 'Other',
    isActive: true
  },
  {
    tableName: 'LvQualifiedInspectorType',
    tableDescription: 'Qualified Inspector Type',
    code: 'QIT5',
    description: 'Professional Architect',
    isActive: true
  },
  {
    tableName: 'LvQualifiedInspectorType',
    tableDescription: 'Qualified Inspector Type',
    code: 'QIT6',
    description: 'Professional Engineer',
    isActive: true
  }
];

export const LvInternalPressureDesign = [
  {
    tableName: 'LvInternalPressureDesign',
    tableDescription: 'Internal Pressure Design',
    code: 'IPD0',
    description: 'Does Not apply - Existing Construction',
    isActive: true
  },
  {
    tableName: 'LvInternalPressureDesign',
    tableDescription: 'Internal Pressure Design',
    code: 'IPD1',
    description: 'Enclosed',
    isActive: true
  },
  {
    tableName: 'LvInternalPressureDesign',
    tableDescription: 'Internal Pressure Design',
    code: 'IPD2',
    description: 'Partially Enclosed',
    isActive: true
  }
];

export const LvProtectionClass = [
  {
    tableName: 'LvProtectionClass',
    tableDescription: 'Protection Class',
    code: '1',
    description: '1',
    isActive: true
  },
  {
    tableName: 'LvProtectionClass',
    tableDescription: 'Protection Class',
    code: '2',
    description: '2',
    isActive: true
  },
  {
    tableName: 'LvProtectionClass',
    tableDescription: 'Protection Class',
    code: '3',
    description: '3',
    isActive: true
  },
  {
    tableName: 'LvProtectionClass',
    tableDescription: 'Protection Class',
    code: '4',
    description: '4',
    isActive: true
  },
  {
    tableName: 'LvProtectionClass',
    tableDescription: 'Protection Class',
    code: '5',
    description: '5',
    isActive: true
  },
  {
    tableName: 'LvProtectionClass',
    tableDescription: 'Protection Class',
    code: '6',
    description: '6',
    isActive: true
  },
  {
    tableName: 'LvProtectionClass',
    tableDescription: 'Protection Class',
    code: '7',
    description: '7',
    isActive: true
  },
  {
    tableName: 'LvProtectionClass',
    tableDescription: 'Protection Class',
    code: '8',
    description: '8',
    isActive: true
  },
  {
    tableName: 'LvProtectionClass',
    tableDescription: 'Protection Class',
    code: '9',
    description: '9',
    isActive: true
  },
  {
    tableName: 'LvProtectionClass',
    tableDescription: 'Protection Class',
    code: '10',
    description: '10',
    isActive: true
  }
];

export const LvWindSpeed = [
  {
    tableName: 'LvWindSpeed',
    tableDescription: 'Wind Speed',
    code: 'WS0',
    description: '100mph',
    isActive: true
  },
  {
    tableName: 'LvWindSpeed',
    tableDescription: 'Wind Speed',
    code: 'WS1',
    description: '110mph',
    isActive: true
  },
  {
    tableName: 'LvWindSpeed',
    tableDescription: 'Wind Speed',
    code: 'WS2',
    description: '=>120mph',
    isActive: true
  }
];

export const LvAddressType = [
  {
    tableName: 'LvAddressType',
    tableDescription: 'Address Type',
    code: 'BL',
    description: 'Billing',
    isActive: true
  },
  {
    tableName: 'LvAddressType',
    tableDescription: 'Address Type',
    code: 'BS',
    description: 'Business',
    isActive: true
  },
  {
    tableName: 'LvAddressType',
    tableDescription: 'Address Type',
    code: 'GA',
    description: 'Garaging',
    isActive: true
  },
  {
    tableName: 'LvAddressType',
    tableDescription: 'Address Type',
    code: 'MA',
    description: 'Mailing',
    isActive: true
  },
  {
    tableName: 'LvAddressType',
    tableDescription: 'Address Type',
    code: 'PR',
    description: 'Property',
    isActive: true
  },
  {
    tableName: 'LvAddressType',
    tableDescription: 'Address Type',
    code: 'SH',
    description: 'Shipping',
    isActive: true
  }
];

export const LvMonthsOccupied = [
  {
    tableName: 'LvMonthsOccupied',
    tableDescription: 'Months Occupied',
    code: '1',
    description: '1',
    isActive: true
  },
  {
    tableName: 'LvMonthsOccupied',
    tableDescription: 'Months Occupied',
    code: '2',
    description: '2',
    isActive: true
  },
  {
    tableName: 'LvMonthsOccupied',
    tableDescription: 'Months Occupied',
    code: '3',
    description: '3',
    isActive: true
  },
  {
    tableName: 'LvMonthsOccupied',
    tableDescription: 'Months Occupied',
    code: '4',
    description: '4',
    isActive: true
  },
  {
    tableName: 'LvMonthsOccupied',
    tableDescription: 'Months Occupied',
    code: '5',
    description: '5',
    isActive: true
  },
  {
    tableName: 'LvMonthsOccupied',
    tableDescription: 'Months Occupied',
    code: '6',
    description: '6',
    isActive: true
  },
  {
    tableName: 'LvMonthsOccupied',
    tableDescription: 'Months Occupied',
    code: '7',
    description: '7',
    isActive: true
  },
  {
    tableName: 'LvMonthsOccupied',
    tableDescription: 'Months Occupied',
    code: '8',
    description: '8',
    isActive: true
  },
  {
    tableName: 'LvMonthsOccupied',
    tableDescription: 'Months Occupied',
    code: '9',
    description: '9',
    isActive: true
  },
  {
    tableName: 'LvMonthsOccupied',
    tableDescription: 'Months Occupied',
    code: '10',
    description: '10',
    isActive: true
  },
  {
    tableName: 'LvMonthsOccupied',
    tableDescription: 'Months Occupied',
    code: '11',
    description: '11',
    isActive: true
  },
  {
    tableName: 'LvMonthsOccupied',
    tableDescription: 'Months Occupied',
    code: '12',
    description: '12',
    isActive: true
  }
];

export const LvBceg = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '98', '99'];

export const LvNumberOfUnitsInsured = [
  {
    tableName: 'LvNumberOfUnitsInsured',
    tableDescription: 'Number of Units Insured',
    code: 'UI1',
    description: '1 Family Unit',
    isActive: true,
    raterCode: 1
  },
  {
    tableName: 'LvNumberOfUnitsInsured',
    tableDescription: 'Number of Units Insured',
    code: 'UI2',
    description: '2 Family Units',
    isActive: true,
    raterCode: 2
  },
  {
    tableName: 'LvNumberOfUnitsInsured',
    tableDescription: 'Number of Units Insured',
    code: 'UI3',
    description: '3 Family Units',
    isActive: true,
    raterCode: 3
  },
  {
    tableName: 'LvNumberOfUnitsInsured',
    tableDescription: 'Number of Units Insured',
    code: 'UI4',
    description: '4 Family Units',
    isActive: true,
    raterCode: 4
  },
  {
    tableName: 'LvNumberOfUnitsInsured',
    tableDescription: 'Number of Units Insured',
    code: 'UI5',
    description: '5+ Family Units',
    isActive: true,
    raterCode: 4
  }
];

export const LvLocationOfWaterHeater = [
  {
    tableName: 'LvLocationOfWaterHeater',
    tableDescription: 'Number of Units Insured',
    code: 'GAR',
    description: 'Garage',
    isActive: true,
    raterCode: 1
  },
  {
    tableName: 'LvLocationOfWaterHeater',
    tableDescription: 'Location Of Water Heater',
    code: '1FL',
    description: 'First Floor',
    isActive: true,
    raterCode: 2
  },
  {
    tableName: 'LvLocationOfWaterHeater',
    tableDescription: 'Number of Units Insured',
    code: '2HFL',
    description: 'Second or higher floor',
    isActive: true,
    raterCode: 3
  },
  {
    tableName: 'LvLocationOfWaterHeater',
    tableDescription: 'Number of Units Insured',
    code: 'ATTC',
    description: 'Attic',
    isActive: true,
    raterCode: 4
  },
  {
    tableName: 'LvLocationOfWaterHeater',
    tableDescription: 'Number of Units Insured',
    code: 'OUTD',
    description: 'Outdoors',
    isActive: true,
    raterCode: 4
  }
];