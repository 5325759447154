import { Component, OnInit } from '@angular/core';
import { BillingService } from '../../../../../core/services/billing/billing.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BaseClass } from '../../../../../shared/base-class';
import { BatchPaymentsData } from '../../../../../modules/payments/data/batch-payments.data';
import { BatchPaymentsConstants } from '../../../../../shared/constants/batch-payments.constants';
import { BatchPaymentDetailsDTO } from '../../../../../shared/models/data/dto/batch-payments/batch-payment-details.dto';
import { BatchSuspensePaymentDetailsModalComponent } from '../batch-suspense-payment-details-modal/batch-suspense-payment-details-modal.component';
import Utils from '../../../../../shared/utilities/utils';
import NotifUtils from '../../../../../shared/utilities/notif-utils';
import { UpdateBatchPaymentDetailIsActiveDTO } from '../../../../../shared/models/data/dto/batch-payments/update-batch-payment-detail-isactive.dto';
import FormUtils from '../../../../../shared/utilities/form.utils';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { SaveControlTotalRequestDTO } from '../../../../../shared/models/data/dto/batch-payments/save-control-total-request.dto';

@Component({
  selector: 'app-batch-payments-details-modal',
  templateUrl: './batch-payments-details-modal.component.html',
  styleUrls: ['./batch-payments-details-modal.component.scss']
})
export class BatchPaymentsDetailsModalComponent extends BaseClass implements OnInit {
  public BatchPaymentsLabel = BatchPaymentsConstants.Labels;
  public AlertMessage = BatchPaymentsConstants.AlertMessage;
  public ErrorMessageConstant = ErrorMessageConstant;
  public FormUtils = FormUtils;
  public CurrencyMaskInputMode = CurrencyMaskInputMode;
  batchPaymentDetails;
  isAdd: boolean;
  isForPendingPayments: boolean;
  userId: string;
  dateViewForCreateBatchPayment: any;
  defaultBatchPaymentDetails: BatchPaymentDetailsDTO[];

  constructor(
    public batchPaymentsdata: BatchPaymentsData,
    private bsModalRef: BsModalRef,
    private billingService: BillingService,
    private modalService: BsModalService
  ) {
    super();
  }

  ngOnInit() {
    if (!this.isAdd) {
      this.defaultBatchPaymentDetails = [
        {
          batchNumber: this.batchPaymentDetails[0]?.batchNumber,
          batchDate: this.batchPaymentDetails[0]?.batchDate
        }
      ];
    }
    this.batchPaymentsdata.pendingBatchPaymentDetails = this.batchPaymentDetails;
    this.dateViewForCreateBatchPayment = Utils.getCurrentDateInShortDateFormat();

    if (!this.isAdd) {
      this.batchPaymentsdata.batchPaymentForm.get('controlTotal').setValue(this.batchPaymentDetails[0]?.controlTotal);
    }
  }

  onAdd(): void {
    Utils.blockUI();
    if (this.batchPaymentsdata.pendingBatchPaymentDetails == null) {
      this.batchPaymentsdata.pendingBatchPaymentDetails = [];
    };

    if (this.batchPaymentsdata.pendingBatchPaymentDetails.length === 0) {
      this.batchPaymentDetails = this.defaultBatchPaymentDetails;
    }

    const initialState = {
      batchPaymentDetails: this.isAdd ? this.batchPaymentDetails : this.batchPaymentDetails[0],
      isEditRecord: false,
      withBatch: this.batchPaymentDetails ? true : false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    };
    Utils.unblockUI();
    this.showCreateOrEditBatchPaymentsModal(BatchSuspensePaymentDetailsModalComponent, initialState);
  }

  onEdit(item: BatchPaymentDetailsDTO, index: number): void {
    Utils.blockUI();
    const initialState = {
      batchPaymentDetails: item,
      isEditRecord: true,
      withBatch: item?.batchNumber ? true : false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
      batchPaymentDetailIndex: index
    };
    this.showCreateOrEditBatchPaymentsModal(BatchSuspensePaymentDetailsModalComponent, initialState);
  }

  onRemove(item: BatchPaymentDetailsDTO, index: number): void {
    if (!this.isAdd) {
      const payload: UpdateBatchPaymentDetailIsActiveDTO = {
        batchPaymentDetailid: item.id
      };
      NotifUtils.showConfirmMessage(this.AlertMessage.removeBatchPaymentDetailConfirmMsg, () => {
        this.batchPaymentsdata.pendingBatchPaymentDetails.splice(index, 1);
        this.billingService.putUpdateIsActive(payload).subscribe(() => { });
        this.batchPaymentsdata.getPendingBatchPaymentsList();
      });
    } else {
      NotifUtils.showConfirmMessage(this.AlertMessage.removeBatchPaymentDetailConfirmMsg, () => {
        this.batchPaymentsdata.pendingBatchPaymentDetails.splice(index, 1);
        this.batchPaymentsdata.batchPaymentSaveButtonValidation();
      });
    }

  }

  onSave(batchNumber?: string): void {
    if (this.isAdd) {
      this.batchPaymentsdata.postBatchPayment(this.batchPaymentsdata.pendingBatchPaymentDetails, this.batchPaymentsdata.batchPaymentForm.get('controlTotal').value);
      this.hideModal();
    } else {
      const data: SaveControlTotalRequestDTO = {
        batchNumber: batchNumber,
        controlTotal: this.batchPaymentsdata.batchPaymentForm.get('controlTotal').value
      };
      this.batchPaymentsdata.postControlTotal(data, true);
    }
  }

  showCreateOrEditBatchPaymentsModal(component?, initialState?): BsModalRef {
    return this.modalService.show(component,{initialState});
  }

  hideModal(): void {
    this.batchPaymentsdata.batchPaymentForm.reset();
    this.batchPaymentsdata.updateBatchPaymentsList(this.isForPendingPayments);
    this.bsModalRef.hide();
  }

  get amountPosted(): number {
    const amounts = this.batchPaymentsdata.pendingBatchPaymentDetails?.map(x => x.amount);

    if (amounts?.length === 0 || !amounts) return 0;

    return amounts?.reduce((total, currentVal) => {
      return total + currentVal;
    });
  }

  get difference(): number {
    const amounts = this.batchPaymentsdata.pendingBatchPaymentDetails?.filter(x => x.processCode === 'N').map(x => x.amount);
    const controlTotal = this.batchPaymentsdata.batchPaymentForm.get('controlTotal').value;

    if (amounts?.length === 0 || !amounts) return 0;

    return controlTotal - this.amountPosted;
  }

  get count(): number {
    return this.batchPaymentsdata.pendingBatchPaymentDetails?.length ?? 0;
  }
}