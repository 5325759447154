import { Subject } from 'rxjs';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import NotifUtils from '../../../../shared/utilities/notif-utils';
import { UwQuestionsComponent } from './uw-questions.component';
import { SummaryData } from '../../data/summary.data';
import { PageSectionsValidations } from '../../../../shared/enum/page-sections.enum';
import { PathConstants } from '../../../../shared/constants/path.constants';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';

@Injectable()
export class CanDeactivateUWQuestionsComponentGuard implements CanDeactivate<UwQuestionsComponent> {
    genericConstants = GenericConstants;

    constructor(protected qqData: SummaryData,
        protected policySummaryData: PolicySummaryData) {}

    canDeactivate(uwQuestions: UwQuestionsComponent, route: ActivatedRouteSnapshot, state: RouterStateSnapshot, nextState: RouterStateSnapshot) {
        const subject = new Subject<boolean>();
        const displayPopup = sessionStorage.getItem(PageSectionsValidations.CurrentPage) !== PathConstants.PageDestination.Quickquote && this.qqData.SummaryForm.get('riskId').value ? true : false;
        const showPopUp = !this.policySummaryData.isPolicyPage(this.qqData.SummaryForm.get('riskId').value);
        if (nextState.url === this.genericConstants.submissionsListUrl && displayPopup && showPopUp) {
            NotifUtils.showConfirmMessage(PageSectionsValidations.ConfirmMessageForSubmission,
                () => {
                    subject.next(true);
                }, () => {
                    subject.next(false);
                });
            return subject.asObservable();
        } else {
            uwQuestions.uwQuestionsValidationService.checkUWQuestionsPage();
            if (!uwQuestions.formValidation.uwQuestionsValidStatus && displayPopup) {
                NotifUtils.showConfirmMessage(PageSectionsValidations.ConfirmMessage,
                    () => {
                        subject.next(true);
                    }, () => {
                        subject.next(false);
                    });
                return subject.asObservable();
            }
        }
        return true;
    }
}