import { Action, createReducer, on } from '@ngrx/store';
import * as fromAppActions from 'app/store/app/app.actions';
import { initialState, AppState } from './app.state';

const _appReducer = createReducer(
  initialState,
  on(
    fromAppActions.updateAppIsLoadingFromAuthService,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromAppActions.updateAppIsLoadingFromPolicyNavSavingService,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromAppActions.updateAppIsLoadingFromSubmissionNavSavingService,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromAppActions.updateAppIsLoadingFromComparativeRaterData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromAppActions.updateAppIsLoadingFromLoginComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromAppActions.updateAppIsLoadingFromGroupDialogComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromAppActions.updateAppIsLoadingFromPolicyManagementComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromAppActions.updateAppIsLoadingFromPolicyIssueComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromAppActions.updateAppIsLoadingFromSubmissionManagementComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromAppActions.updateAppIsLoadingFromSubmissionListData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromAppActions.updateAppIsLoadingFromUwApprovalSavingData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromAppActions.updateAppIsLoadingFromZipCodeData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromAppActions.updateAppIsLoadingFromApplicantPageComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromAppActions.updateAppIsLoadingFromBindAndIssueComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromAppActions.updateAppIsLoadingFromIssuePolicyComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromAppActions.updateAppIsLoadingFromPolicyIssueData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromAppActions.updateAppIsConfirmationFromPolicyManagementComponent,
    (state, { isConfirmation }) => ({ ...state, isConfirmation: isConfirmation })
  ),
  on(
    fromAppActions.updateAppIsConfirmationFromPolicyRewriteComponent,
    (state, { isConfirmation }) => ({ ...state, isConfirmation: isConfirmation })
  ),
  on(
    fromAppActions.updateAppIsConfirmationFromZipCodeData,
    (state, { isConfirmation }) => ({ ...state, isConfirmation: isConfirmation })
  ),
  on(
    fromAppActions.updateAppIsConfirmationFromLocationDetailsComponent,
    (state, { isConfirmation }) => ({ ...state, isConfirmation: isConfirmation })
  ),
  on(
    fromAppActions.updateAppIsConfirmationFromPolicyIssueData,
    (state, { isConfirmation }) => ({ ...state, isConfirmation: isConfirmation })
  ),
  on(
    fromAppActions.updateAppRuwIsLoadingFromUwApprovalSavingData,
    (state, { ruwIsLoading }) => ({ ...state, ruwIsLoading: ruwIsLoading })
  ),
  on(
    fromAppActions.updateAppBrulDatesFromLogin,
    (state, { brulDates }) => ({ ...state, brulDates: brulDates })
  ),
  on(
    fromAppActions.updateAppBrulDatesFromDashboard,
    (state, { brulDates }) => ({ ...state, brulDates: brulDates })
  ),
  on(
    fromAppActions.updateAppBrulDatesFromComparativeRaterData,
    (state, { brulDates }) => ({ ...state, brulDates: brulDates })
  ),
  on(
    fromAppActions.updateAppBrulDatesFromEndorsementComponent,
    (state, { brulDates }) => ({ ...state, brulDates: brulDates })
  ),
);

export function appReducer(
  state: AppState | null,
  action: Action
) {
  return _appReducer(state, action);
}
