import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPolicyIssueValidationMessage'
})
export class GetPolicyIssueValidationMessagePipe implements PipeTransform {
  transform(premiumChangeList: any, summary: any, isOutOfSequenceTransaction: boolean, finalPremiumFeesNoOOS: number): string {
    const finalPremiumFees = premiumChangeList?.find(x => x.stepAlias === 'FPrePlusFee')?.premiumDiffChange;
    let msg: string = '';

    if (summary?.paid <= 0) {
      msg = 'Payment of deposit is required prior to issuance.';
    }

    if (finalPremiumFees === 0) {
      if (isOutOfSequenceTransaction) {
        if (finalPremiumFeesNoOOS === 0) {
          msg = '';
        }
      } else {
        msg = '';
      }
    }

    return msg;
  }
}
