import { Component, OnInit } from '@angular/core';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { StatusCounter } from '../../status-counter/status-counter';
import { BaseClass } from 'app/shared/base-class';
import { DashboardData } from 'app/modules/dashboard/data/dashboard.data';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-policy-section',
  templateUrl: './policy-section.component.html',
  styleUrls: ['./policy-section.component.scss']
})
export class PolicySectionComponent extends BaseClass implements OnInit {

  public agentDashboardConstants = AgentDashboardConstants;

  policyCounterItems: StatusCounter[];
  additionalInformationRequiredCount: number;
  pendingCancellationCount: number;
  pendingNonRenewalCount: number;

  constructor(public dashboardData: DashboardData) { super(); }

  ngOnInit(): void {
    this.policyCounterItems = [
      { description: 'In Force', icon: 'cil-library', url: '/policies/list?status=in-force' },
      { description: 'Pending Cancel', icon: 'cil-pen-alt', url: '/policies/list?status=pending-cancellation' },
      // { description: "Quote Referral", icon: "cil-ban", url: '/policies/list?status=pending-cancellation' },
      // { description: "Cancelled", count: 49, icon: "cil-double-quote-sans-left", url: '/policies/list?status=cancelled' },
      // { description: "Expired", count: 0, icon: "cil-double-quote-sans-left", url: '/policies/list?status=expired' },
    ];
  }
}
