export const RefundLabelsConstants = {
  RefundRequest: {
    Title: 'Refund Request',
    FilterTitle: 'FILTERS',
    Table: {
      Refund: 'Refund',
      RefundAmount: 'Refund Amount',
      Overpayment: 'Overpayment',
      PolicyNumber: 'Policy Number',
      TotalBookedPremium: 'Total Booked Premium',
      TotalPaid: 'Total Paid',
      PaymentPlan: 'Payment Plan',
      RefundTo: 'Refund To',
      PayeeName: 'Payee Name',
      RefundAddress: 'Refund Address',
      CheckRegister: 'Check Register',
      DeliveryType: 'Delivery Type'
    },
    Filters: {
      RequestStatus: 'Request Status:',
      PolicyNumber: 'Policy Number:',
      Overpayment: 'Overpayment:',
      Minimum: 'Minimum Overpayment:',
      Maximum: 'Maximum Overpayment:',
      PolicyOrigin: 'Policy Origin:'
    },
    RequestStatus: {
      All: 'A',
      Selected: 'S',
      NotSelected: 'NS'
    },
    RefundToType: {
      Mortgagee: 'Mortgagee',
      Insured: 'Insured',
      Other: 'Other',
      PremCo: 'Premium Finance Co.',
      MortgageeCode: 'M',
      InsuredCode: 'I',
      OtherCode: 'O',
      PremCoCode: 'P'
    },
    HttpType: {
      Post: 'post',
      Put: 'put'
    },
    DeliveryType: {
      Normal: 'Normal',
      Overnight: 'Overnight',
      Restricted: 'Restricted',
      Certified: 'Certified',
      Omaha: 'Omaha',
      NormalCode: 'N',
      OvernightCode: 'OV',
      RestrictedCode: 'R',
      CertifiedCode: 'C',
      OmahaCode: 'OM',
    },
    Action: {
      Remove: 'Remove',
      RemoveQuestion: 'Are you sure you want to remove?',
      Edit: 'Edit',
      EditRefundModalTitle: 'Edit Refund'
    },
    PolicyOrigin: {
      All: 'A',
      Star: 'S',
      IPX: 'I',
      Unspecified: 'U'
    }
  }
};

export const RefundRequestValueConstants = {
  MortgageeBill: 'Mortgagee Bill'
};

export const RefundRequestNameAndAddressLimits = {
  name: 64,
  street1: 32,
  street2: 32,
  city: 32,
  state: 10,
  zip: 10,
};

export const RefundPayeeFieldNames = {
  isIndividual: 'isIndividual',
  name: 'name',
  fullName: 'fullName',
  firstName: 'firstName',
  lastName: 'lastName',
  suffix: 'suffix',
  street1: 'street1',
  street2: 'street2',
  address: 'address',
  aptOrUnit: 'aptOrUnit',
  city: 'city',
  state: 'state',
  zip: 'zip',
};
