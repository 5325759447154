import { RiskStatus } from 'app/shared/models/data/dto/quick-quote/risk.dto';

export interface SubmissionState {
  isLoading: boolean | null;
  isPopulatingForms: boolean | null;
  status: RiskStatus | null;
}

export const initialState: SubmissionState = {
  isLoading: null,
  isPopulatingForms: null,
  status: null
};