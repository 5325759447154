import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  private storage: Storage;

  constructor() {
    this.storage = localStorage;
  }

  public retrieve(key: string): any {
    if (!key) {
      return;
    }
    const item = this.storage.getItem(key);

    if (!item || item === 'undefined') {
      return;
    }
    return JSON.parse(this.storage.getItem(key));
  }

  public get(key: string) {
    return this.storage.getItem(key);
  }

  public store(key: string, value: any) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  public remove(key: string) {
    this.storage.removeItem(key);
  }

  public removeAll(key: string) {
    for (const storageKey in localStorage) {
      if(storageKey.includes(key)) {
        this.storage.removeItem(storageKey);
      }
   }
  }
}
