import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseClass } from 'app/shared/base-class';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PolicyNonRenewalLabelsConstants } from 'app/shared/constants/policy-non-renewal.labels.constants';
import { LvNonRenewalType, LvNonRenewalReasonType } from 'app/shared/constants/policy-non-renewal.options.constants';
import { NonRenewalReasonType } from 'app/shared/models/policy/policy-non-renewal.model';
import { takeUntil } from 'rxjs/operators';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { SummaryData } from '../../../modules/submission-management/data/summary.data';
import Utils from '../../../../app/shared/utilities/utils';
import { PolicyService } from 'app/core/services/submission/policy.service';
import { PolicyNonRenewalDTO } from 'app/shared/models/data/dto/policy-management/policy-non-renew.dto';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';

@Component({
  selector: 'app-policy-non-renewal',
  templateUrl: './policy-non-renewal.component.html',
  styleUrls: ['./policy-non-renewal.component.scss']
})
export class PolicyNonRenewalComponent extends BaseClass implements OnInit, OnDestroy {
  protected title: string;
  public policyNonRenewForm: FormGroup;
  protected policyNonRenewalLabelsConstants = PolicyNonRenewalLabelsConstants;
  protected lvNonRenewalType = LvNonRenewalType;
  protected lvNonRenewalReasonType = LvNonRenewalReasonType;
  protected nonRenewalReasons: NonRenewalReasonType[] = [];

  constructor(
    protected fb: FormBuilder,
    public bsModalRef: BsModalRef,
    public summaryData: SummaryData,
    protected policyService: PolicyService,
    public policySummaryData: PolicySummaryData,
  ) {
    super();
  }

  ngOnInit() {
    this.initForm();
    this.subscribeToNonRenewalType();
  }

  initForm(): void {
    this.policyNonRenewForm = this.fb.group({
      nonRenewalType: new FormControl('', [Validators.required]),
      nonRenewalReasonType: new FormControl('', [Validators.required]),
      nonRenewalReason: new FormControl('', [Validators.required])
    });
  }

  subscribeToNonRenewalType(): void {
    this.policyNonRenewForm.get('nonRenewalType').valueChanges.pipe(takeUntil(this.stop$)).subscribe(result => {
      this.nonRenewalReasons = this.lvNonRenewalReasonType.filter(item => {
        return item.nonRenewalTypeCode === result;
      });
    })
  }

  okMyModal(): void {
    Utils.blockUI();

    this.policyService.putNonRenewal(this.getNonRenewalPayload()).pipe(takeUntil(this.stop$)).subscribe(() => {
      this.policySummaryData.renewalSubStatusCode.next('SFNRW');
      Utils.unblockUI();
      this.bsModalRef.hide();
    }, (error) => {
      Utils.unblockUI();
      NotifUtils.showError(error);
    });
  }

  getNonRenewalPayload(): PolicyNonRenewalDTO {
    return {
      riskId: this.summaryData.SummaryForm.get('riskId').value,
      riskDetailId: this.summaryData.SummaryForm.get('riskDetailId').value,
      nonRenewalTypeCode: this.policyNonRenewForm.get('nonRenewalType').value,
      nonRenewalReasonTypeCode: this.policyNonRenewForm.get('nonRenewalReasonType').value,
      nonRenewalReason: this.policyNonRenewForm.get('nonRenewalReason').value
    }
  }

  hideMyModal(): void {
    this.bsModalRef.hide();
  }

  ngOnDestroy() { }
}
