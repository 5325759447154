import { Component, HostListener, OnInit } from '@angular/core';
import { switchMap, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/base-component';
import { environment } from '../../../../environments/environment';
import { QuoteService } from 'app/core/services/quote.service';
import { QuickQuoteData } from '../../../modules/submission-management/data/quick-quote.data';
import { SummaryData } from '../../../modules/submission-management/data/summary.data';
import { PageHeaderSummaryConstants } from '../../../shared/constants/page-header-summary.constants';
import { PathConstants } from '../../../shared/constants/path.constants';
import { SubmissionPageData } from 'app/modules/submission-management/data/submission-page.data';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';
import { UwApprovalData } from '../../../../app/modules/submission-management/data/uw-approval.data';
import Utils from '../../../../app/shared/utilities/utils';
import { StatusConstants } from '../../../../app/shared/constants/risk-status';
import { ApplicantData } from '../../../../app/modules/submission-management/data/applicant.data';
import { EntityRiskData } from '../../../../app/modules/submission-management/data/entity-risk.data';
import { AddressType } from '../../../../app/shared/enum/address-type.enum';
import { Router } from '@angular/router';
import { AuthService } from '../../../../app/core/services/auth.service';
import { GenericConstants } from '../../../../app/shared/constants/generic.constants';
import { PolicyCancellationLabelsConstants } from 'app/shared/constants/policy-cancellation.labels.constants';
import { PolicyCancellationComponent } from 'app/modules/policy-management/policy-cancellation/policy-cancellation.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { PolicyService } from 'app/core/services/submission/policy.service';
import { RiskStatus } from '../../../../app/shared/models/data/dto/quick-quote/risk.dto';
import { PolicyRewriteComponent } from '../../../modules/policy-management/policy-actions/policy-rewrite/policy-rewrite.component';
import { PolicyCancellationData } from '../../../../app/modules/policy-management/data/policy-cancellation.data';
import { forkJoin, Observable } from 'rxjs';
import { selectSubmissionStatus } from 'app/store/submission/submission.selectors';
import { select, Store } from '@ngrx/store';
import { PolicyNonRenewalComponent } from 'app/modules/policy-management/policy-non-renewal/policy-non-renewal.component';
import { PolicyNotesData } from 'app/modules/policy-management/data/policy-notes.data';
import { BillingService } from '../../../core/services/billing/billing.service';
import { PostPaymentModalComponent } from 'app/modules/policy-management/policy-billing/payments/post-payments/post-payment-modal/post-payment-modal.component';
import { PaymentProcedure } from '../../../shared/enum/payment-procedure.enum';
import { PolicyReinstateConstants } from '../../../shared/constants/policy-reinstate.constants';
import { PolicyBillingData } from '../../../modules/policy-management/data/policy-billing.data';
import { SubmissionListData } from 'app/modules/submission-management/data/submission-list.data';
import { PolicyDocumentsData } from '../../../modules/policy-management/data/policy-documents.data';
import { ManualRescindDTO } from '../../models/data/dto/form-document/manual-rescind.dto';
import * as moment from 'moment';
import { AutoReinstatementConstants } from '../../constants/auto-reinstatement.constants';
import { AutoReinstatementData } from '../../../modules/policy-management/data/auto-reinstatement.data';
import { PolicyHistoryData } from 'app/modules/policy-management/data/policy-history-data';
import { selectPolicyStatus } from 'app/store/policy/policy.selectors';
import { selectSubmissionIsLoading } from 'app/store/submission/submission.selectors';
import { selectPolicyIsLoading } from 'app/store/policy/policy.selectors';
import { selectSubmissionUWRsIsLoading } from 'app/store/submission-uwrs/submission-uwrs.selectors';
import { selectPolicyUWRsIsLoading } from 'app/store/policy-uwrs/policy-uwrs.selectors';
import { AllowedPaymentRangeDTO } from 'app/shared/models/data/dto/billing/allowed-payment-range.dto';
import { RenewalTermData } from '../../../modules/submission-management/data/renewal-term.data';

@Component({
  selector: 'app-page-header-summary',
  templateUrl: './page-header-summary.component.html',
  styleUrls: ['./page-header-summary.component.scss'],
})
export class PageHeaderSummaryComponent extends BaseComponent implements OnInit {
  formType: string = 'HO3';
  isShow: boolean = false;
  isCalcPremium: boolean = false;
  public pageHeaderConstants = PageHeaderSummaryConstants;
  public policyCancellationLabelsConstants = PolicyCancellationLabelsConstants;
  public pathConstants = PathConstants;
  public formTypeConstants = FormTypeConstants;
  public statusConstants = StatusConstants;
  public modalRef: BsModalRef | null;
  public policyReinstateConstants = PolicyReinstateConstants;

  isInternal: boolean;

  orangeStatusData: string[] = ['Referred to UW', 'Approved by UW', 'Pending', 'Declined by UW', 'Additional Information Required'];
  redStatusData: string[] = ['Pending Cancellation', 'Cancelled'];

  genericConstants = GenericConstants;
  protected selectSubmissionIsLoading$: Observable<boolean>;
  protected selectSubmissionStatus$: Observable<RiskStatus>;
  protected selectPolicyIsLoading$: Observable<boolean>;
  protected selectPolicyStatus$: Observable<RiskStatus>;
  protected selectSubmissionUWRsIsLoading$: Observable<boolean>;
  protected selectPolicyUWRsIsLoading$: Observable<boolean>;
  protected riskStatus: RiskStatus = null;

  isRescindedRenewal: boolean = false;

  constructor(
    public quoteService: QuoteService,
    public quickQuoteData: QuickQuoteData,
    public summaryData: SummaryData,
    public submissionPageData: SubmissionPageData,
    public policySummaryData: PolicySummaryData,
    protected uwApprovalData: UwApprovalData,
    protected applicantData: ApplicantData,
    protected entityRiskData: EntityRiskData,
    protected router: Router,
    protected authService: AuthService,
    protected modalService: BsModalService,
    protected policyCancellationData: PolicyCancellationData,
    bsModalRef: BsModalRef,
    protected store: Store,
    protected policyService: PolicyService,
    protected policyNotesData: PolicyNotesData,
    protected billingService: BillingService,
    protected policyBillingData: PolicyBillingData,
    protected submissionListData: SubmissionListData,
    protected policyDocumentsData: PolicyDocumentsData,
    protected autoReinstatementData: AutoReinstatementData,
    protected policyHistoryData: PolicyHistoryData,
    protected renewalTermData: RenewalTermData
  ) {
    super();
    this.selectSubmissionIsLoading$ = this.store.pipe(select(selectSubmissionIsLoading));
    this.selectSubmissionStatus$ = this.store.pipe(select(selectSubmissionStatus));
    this.selectPolicyIsLoading$ = this.store.pipe(select(selectPolicyIsLoading));
    this.selectPolicyStatus$ = this.store.pipe(select(selectPolicyStatus));
    this.selectSubmissionUWRsIsLoading$ = this.store.pipe(select(selectSubmissionUWRsIsLoading));
    this.selectPolicyUWRsIsLoading$ = this.store.pipe(select(selectPolicyUWRsIsLoading));
  }


  ngOnInit() {
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.isInternal = userType.toLocaleLowerCase() === GenericConstants.userType.internal;
    });

    this.summaryData.isCalcPremium.pipe(takeUntil(this.stop$)).subscribe((res) => {
      if (!this.policySummaryData.isPolicy) {
        this.isCalcPremium = res;
      }

    });

    this.entityRiskData.isDonePopulatingForms$.pipe(takeUntil(this.stop$)).subscribe((res) => {
      if (this.entityRiskData.getRiskId()) {
        this.submissionListData.callRelatedSubmissionList(this.entityRiskData.getRiskId(), this.entityRiskData.getRiskDetailId(), this.isInternal, this.entityRiskData.getRisk());
      }
    });

    this.summaryData.SummaryForm.get('formType').setValue(
      JSON.parse(sessionStorage.getItem(environment.QuoteFormTypeAndState))?.formType ??
      this.quoteService?.formType ??
      this.pageHeaderConstants?.formType
    );
    this.summaryData.pageType = localStorage.getItem('pageType');
    this.summaryData.isFormTypeLoaded.next(true);
    this.subscribeToMainStatus();
    // Commented due to effectiveDate issue changing - [JOC]05/10/2022
    // this.subscribeToHistory(); 
  }

  subscribeToMainStatus(): void {
    this.selectSubmissionStatus$.pipe().subscribe(status => {
      this.riskStatus = status;
    });

    this.selectPolicyStatus$.pipe().subscribe(status => {
      this.riskStatus = status;
    });
  }
  // Commented due to effectiveDate issue changing - [JOC]05/10/2022
  // subscribeToHistory(): void { 
  //   this.policyHistoryData.historyItems.subscribe(endorsementResult => {
  //     if (this.summaryData.isSiebel) {
  //       const endorsementZeroData = endorsementResult?.find(x => Number(x.endorsementNumber) === 0);
  //       this.summaryData.SummaryForm.get('effectiveDate').setValue(endorsementZeroData?.effectiveDate ?? this.entityRiskData.getRisk().firstIssueDate);
  //     }
  //   });
  // }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: any) {
    const scrollPosition = window.scrollY;
    if (scrollPosition >= 94) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  isOrangeFontColor(): boolean {
    return this.orangeStatusData.includes(`${this.submissionPageData.policyStatus?.value}`);
  }

  isPendingCanUW(): boolean {
    return this.redStatusData.includes(`${this.submissionPageData.policyStatus?.value}`);
  }

  get isCancelledStatus(): boolean {
    return this.submissionPageData.quoteStatus?.value?.toLowerCase() === 'cancelled';
  }

  get isStatusExpired(): boolean {
    return this.submissionPageData.quoteStatus.value === this.genericConstants.quoteStatus.pexp || this.submissionPageData.quoteStatus.value === this.genericConstants.quoteStatus.qexp;
  }

  get showReinstateButton(): boolean {
    return this.isCancelledStatus &&
      (!this.isCancelledDueToBillingReason ||
        (this.isCancelledDueToBillingReason && !this.autoReinstatementData.isApprovalRequired$.value) ||
          (this.isCancelledDueToBillingReason && this.autoReinstatementData.isApprovalRequired$.value && this.submissionPageData.isApprovedForReinstatement.value));
  }

  get isCancelledDueToBillingReason(): boolean {
    return this.entityRiskData.getRisk().cancellationTypeCode === AutoReinstatementConstants.BillingReasonCode;
  }

  get showCancelButton(): boolean {
    return ((this.isInternal && this.submissionPageData.quoteStatus.value === this.genericConstants.quoteStatus.pexp) ||
      this.submissionPageData.quoteStatus.value === this.genericConstants.quoteStatus.act);
  }

  copyPolicyToNewSubmission() {

    NotifUtils.showConfirmMessage(this.pageHeaderConstants.CopyConfirmation, () => {
      const propertyAddress = this.entityRiskData.EntityRisk?.entityAddresses.find(a => a.addressTypeCode === AddressType.Property).address;
      const mailingAddress = this.entityRiskData.EntityRisk?.entityAddresses.length === 1 ?
        this.entityRiskData.EntityRisk?.entityAddresses[0]?.address : this.entityRiskData.EntityRisk?.entityAddresses.find(x => x.addressTypeCode === AddressType.Mailing)?.address;
      const hasMailingAddressSameInsured = !this.entityRiskData.EntityRisk?.isMailingAddressSameInsured && this.entityRiskData.EntityRisk?.entityAddresses.length > 1;

      this.applicantData.copiedInformation = {
        firstName: this.entityRiskData.EntityRisk.firstName,
        lastName: this.entityRiskData.EntityRisk.lastName,
        middleName: this.entityRiskData.EntityRisk.middleName,
        suffix: this.entityRiskData.EntityRisk.nameSuffix,

        propertyAddressLine1: propertyAddress.streetAddress1,
        propertyAddressLine2: propertyAddress.streetAddress2,
        propertyCity: propertyAddress.city,
        propertyState: propertyAddress.state,
        propertyZipCode: propertyAddress.zipCode,
        county: propertyAddress.county,

        isMailingAddressSameInsured: this.entityRiskData.EntityRisk?.isMailingAddressSameInsured,

        mailingAddressLine1: hasMailingAddressSameInsured ? mailingAddress.streetAddress1 : '',
        mailingAddressLine2: hasMailingAddressSameInsured ? mailingAddress.streetAddress2 : '',
        mailingCity: hasMailingAddressSameInsured && mailingAddress.city !== 'NA' ? mailingAddress.city : '',
        mailingState: hasMailingAddressSameInsured && mailingAddress.state !== 'NA' ? mailingAddress.state : '',
        mailingZipCode: hasMailingAddressSameInsured ? mailingAddress.zipCode : '',
        mailingCountry: hasMailingAddressSameInsured ? mailingAddress.countryCode : '',

        emailAddress: this.entityRiskData.EntityRisk.personalEmailAddress,
        mobilePhoneNumber: this.entityRiskData.EntityRisk.mobilePhone,
        homePhoneNumber: this.entityRiskData.EntityRisk.homePhone,

        agentId: this.entityRiskData.EntityRisk.risks[0].agentId,
        agencyId: this.entityRiskData.EntityRisk.risks[0].agencyId,
        subAgencyId: this.entityRiskData.EntityRisk.risks[0].subAgencyId,
        insuranceName: this.entityRiskData.EntityRisk.risks[0].insuranceName,
      };

      this.applicantData.copiedAgencyInformation = {
        agentId: this.entityRiskData.EntityRisk.risks[0].agentId,
        agencyId: this.entityRiskData.EntityRisk.risks[0].agencyId,
        subAgencyId: this.entityRiskData.EntityRisk.risks[0].subAgencyId,
        insuranceName: this.entityRiskData.EntityRisk.risks[0].insuranceName,
      };

      localStorage.setItem('fromCopy', 'true');
      this.applicantData.isQQSavedForCopy = false;
      this.applicantData.isCopiedInformation = true;
      this.router.navigate(['/submissions/new/quickquote/applicant-page']);
    });
  }


  showManualCancellationModal(): void {
    const initialState = {
      title: this.policyCancellationLabelsConstants.PolicyCancellation.PolicyCancellationTitleHeader
    };

    this.modalRef = this.modalService.show(PolicyCancellationComponent, {
      initialState,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  reinstatePolicy(): void {
    NotifUtils.showConfirmMessage(this.pageHeaderConstants.ReinstateConfirmation, () => {
      Utils.blockUI();
      forkJoin([
        this.billingService.getAllowedPaymentRange(this.entityRiskData.getRiskId()),
        this.autoReinstatementData.getOtherReinstatementRequirements(false, this.entityRiskData.getRiskId())])
        .pipe(takeUntil(this.stop$))
        .subscribe(result => {
          const paymentRangeResult = result[0];
          this.autoReinstatementData.allowedPaymentRangeFromPostPayment = result[0];
          this.autoReinstatementData.requirementsMetDetails = result[1];

          if (paymentRangeResult) {
            if (this.isCancelledDueToBillingReason) {
              this.processBillingReasonManualReinstatement(paymentRangeResult);
            } else {
              this.uwApprovalData.reinstatePolicy().subscribe(result2 => {
                this.policyCancellationData.reinstatePolicyCallback(result2);
                Utils.unblockUI();
              }, err => {
                Utils.unblockUI();
                NotifUtils.showMultiLineError(err.error?.message);
              });
            }
          };
        },
          err => {
            Utils.unblockUI();
            NotifUtils.showMultiLineError(err.error?.message);
          });
    }, () => { });
  }

  protected processBillingReasonManualReinstatement(paymentRangeResult: AllowedPaymentRangeDTO) {
    if (this.autoReinstatementData.requirementsMetDetails?.requiredAmount === 0) { // CNTSUP-1960
      this.uwApprovalData.reinstatePolicy().subscribe(result2 => {
        this.policyCancellationData.reinstatePolicyCallback(result2);
        Utils.unblockUI();
      }, err => {
        Utils.unblockUI();
        NotifUtils.showMultiLineError(err.error?.message);
      });
    } else {
      this.showMakeAPaymentModal(paymentRangeResult);
      Utils.unblockUI();
    }
  }

  protected showMakeAPaymentModal(paymentRangeResult: AllowedPaymentRangeDTO) {
    const initialState = {
      allowedPaymentRange: paymentRangeResult,
      paymentProcedure: PaymentProcedure.PayToReinstate
    };

    this.modalRef = this.modalService.show(PostPaymentModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  reinstatePolicyForCorrection(): void {
    NotifUtils.showConfirmMessage(this.pageHeaderConstants.ReinstateConfirmation, () => {
      Utils.blockUI();
      forkJoin([
        this.billingService.getAllowedPaymentRange(this.entityRiskData.getRiskId()),
        this.autoReinstatementData.getOtherReinstatementRequirements(false, this.entityRiskData.getRiskId())])
        .pipe(takeUntil(this.stop$))
        .subscribe(result => {
          const paymentRangeResult = result[0];
          this.autoReinstatementData.allowedPaymentRangeFromPostPayment = result[0];
          this.autoReinstatementData.requirementsMetDetails = result[1];

          // if (paymentRangeResult) {
          //   if (this.submissionPageData.isApprovedForReinstatement.value) {
          //     const initialState = {
          //       allowedPaymentRange: paymentRangeResult,
          //       paymentProcedure: PaymentProcedure.PayToReinstate
          //     };

          //     this.modalRef = this.modalService.show(PostPaymentModalComponent, {
          //       initialState,
          //       backdrop: true,
          //       ignoreBackdropClick: true,
          //     });
          //     Utils.unblockUI();
          //   } else {
              this.uwApprovalData.reinstatePolicyForCorrection().subscribe(result2 => {
                this.policyCancellationData.reinstatePolicyCallback(result2);
                Utils.unblockUI();
              }, err => {
                Utils.unblockUI();
                NotifUtils.showMultiLineError(err.error?.message);
              });
          //   }
          // };
        },
          err => {
            Utils.unblockUI();
            NotifUtils.showMultiLineError(err.error?.message);
          });
    }, () => { });
  }

  showRewriteModal(): void {
    const initialState = {
      title: this.policyCancellationLabelsConstants.PolicyRewrite.Title
    };

    this.modalRef = this.modalService.show(PolicyRewriteComponent, {
      initialState,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  showPendingEndorsementStatus(): boolean {
    const showPending = this.submissionPageData.policyStatus.value === this.pageHeaderConstants.policyStatusPending ?
      (this.summaryData.showPendingEndorsementStatus || this.uwApprovalData.uwApprovalList?.length !== 0) :
      false;
    return showPending;
  }

  nonRenew(): void {
    if (this.policySummaryData.withinNonRenewalPeriod) {
      const defaults: any = {
        title: this.pageHeaderConstants.policyRenewal.nonRenewalPeriodPassedAlertTitle,
        icon: this.pageHeaderConstants.policyRenewal.nonRenewalPeriodPassedAlertIcon,
        allowOutsideClick: false
      };

      NotifUtils.showNotice(this.pageHeaderConstants.policyRenewal.nonRenewalPeriodPassedAlertMessasge, defaults, () => {
        this.showNonRenewalModal();
      });

      return;
    }

    this.showNonRenewalModal();
  }

  showNonRenewalModal(): void {
    const initialState = {};

    this.modalRef = this.modalService.show(PolicyNonRenewalComponent, {
      initialState,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  rescindNonRenewal(): void {
    NotifUtils.showConfirmMessage(this.pageHeaderConstants.policyRenewal.rescindNonRenewalConfirmation, () => {
      const riskId = this.summaryData.SummaryForm.get('riskId').value;
      const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;

      Utils.blockUI();

      this.policyService.putRescindNonRenewal(riskId, riskDetailId).pipe(takeUntil(this.stop$)).subscribe(() => {
        this.policySummaryData.renewalSubStatusCode.next(null);
        Utils.unblockUI();
      }, (error) => {
        Utils.unblockUI();
        NotifUtils.showError(error);
      });
    });
  }

  reverseNonRenewal(): void {
    NotifUtils.showConfirmMessage(this.pageHeaderConstants.policyRenewal.reverseNonRenewalConfirmation, () => {
      const riskId = this.summaryData.SummaryForm.get('riskId').value;
      const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;

      Utils.blockUI();

      this.policyService.putReverseNonRenewal(riskId, riskDetailId).pipe(takeUntil(this.stop$)).subscribe(() => {
        this.policySummaryData.renewalSubStatusCode.next(null);
        Utils.unblockUI();
      }, (error) => {
        Utils.unblockUI();
        NotifUtils.showError(error);
      });
    });
  }

  flagRenewal(): void {
    this.policySummaryData.callFlagRenewal(this.entityRiskData.getRiskId(), this.entityRiskData.getRiskDetailId()).subscribe(() => {
      this.policySummaryData.renewalSubStatusCode.next('CRW');
      this.subscribeToPolicyNotes(this.entityRiskData.getRiskId());
      Utils.unblockUI();

      NotifUtils.showInformationWithConfirmationFromUser(this.pageHeaderConstants.policyRenewal.flagChangesInformationMessage, () => {
        //to do, on what will happen next upon click OK
      });

    }, (error) => {
      Utils.unblockUI();
      NotifUtils.showError(error);
    });;
  }

  subscribeToPolicyNotes(riskId?: string): void {
    this.policyService.getPolicyNotes(riskId).pipe(takeUntil(this.stop$)).subscribe(riskBindNotes => {
      this.policyNotesData.populateNotes(riskBindNotes);
    });
  }

  unflagRenewal(): void {
    this.policySummaryData.callUnFlagRenewal(this.entityRiskData.getRiskId(), this.entityRiskData.getRiskDetailId());
  }

  get isInternalUser(): boolean {
    return this.authService.userType.value === this.genericConstants.userType.internal;
  }

  get showNonRenewAction(): boolean {
    return (
      this.policySummaryData.renewalSubStatusCode.value === null ||
      this.policySummaryData.renewalSubStatusCode.value === this.pageHeaderConstants.policyRenewal.subStatusCode.RNRW ||
      this.policySummaryData.renewalSubStatusCode.value === this.pageHeaderConstants.policyRenewal.subStatusCode.RVNRW ||
      this.policySummaryData.renewalSubStatusCode.value === 'CRW'
    ) &&
      this.isActive &&
      this.isInternalUser &&
      ((!this.isRenewalPending && !this.isRenewalOffered && !this.isRenewed) ||
        (this.isRenewalPending && this.summaryData.isSiebel)); // show non-renewal actions if it's from siebel even there's a renewal pending risk. scenario for issiebel only. if expiring risk got non renewed and renewal pending risk got renewal not taken
  }

  get showRescindNonRenewalAction(): boolean {
    return this.policySummaryData.renewalSubStatusCode.value === 'SFNRW' &&
      this.isActive &&
      this.isInternalUser;
  }

  get showReverseNonRenewalAction(): boolean {
    return this.policySummaryData.renewalSubStatusCode.value === 'NRWD' &&
      (this.isActive || this.isPolicyExpired) &&
      this.isInternalUser;
  }

  get showRenewalFlagAction(): boolean {
    const isChangesAtRenewal = this.policySummaryData.renewalSubStatusCode.value === 'CRW';
    const currentDate = moment(this.authService.getCustomDate()).format('MM/DD/YYYY');
    const policyEffectiveDate = moment(this.entityRiskData.EntityRisk.risks[0].riskDetails[0].effectiveDate).format('MM/DD/YYYY');
    const policyEffectiveDateIsFutureDate = moment(policyEffectiveDate).diff(currentDate, 'days') > 0;

    const hasRenewedPolicy = this.submissionListData.relatedSubmissionList?.filter(relatedRisk => {
      return this.entityRiskData.riskId === relatedRisk.renewalFromRiskId && relatedRisk.status === 'ACT';
    })?.length;

    if (isChangesAtRenewal || hasRenewedPolicy) {
      return false;
    }
    if (policyEffectiveDateIsFutureDate && !this.isInternal) {
      return false;
    }

    return this.isInternal ?
      (this.policySummaryData.renewalSubStatusCode.value === null || this.policySummaryData.renewalSubStatusCode.value === this.pageHeaderConstants.policyRenewal.subStatusCode.RNRW) :
      (this.policySummaryData.renewalCode === null || this.policySummaryData.renewalCode === this.pageHeaderConstants.policyRenewal.subStatusCode.RNRW);
  }

  get showRenewalUnFlagAction(): boolean {
    return this.policySummaryData.renewalSubStatusCode.value === 'CRW' && this.isInternalUser &&
      (this.policySummaryData.renewalSubStatusCode.value !== this.pageHeaderConstants.policyRenewal.subStatusCode.RNRW ||
        this.policySummaryData.renewalSubStatusCode.value !== this.pageHeaderConstants.policyRenewal.subStatusCode.RVNRW);
  }

  get checkFlagRenewal(): boolean {
    return this.policySummaryData.isPolicy && this.policySummaryData.withinRenewalPeriod && this.policySummaryData.renewalSubStatusCode !== null;
  }

  get SummaryForms() {
    const ep = this.summaryData.SummaryForm.get('estimatedPremium');
    ep.setValue(ep.value === 0 ? ep.value.toString() : ep.value);

    return this.summaryData.SummaryForm;
  }

  get premiumFieldName(): string {
    return this.policySummaryData.isPolicy ? 'billingPremium' : 'estimatedPremium';
  }

  get taxesFieldName(): string {
    return this.policySummaryData.isPolicy ? 'billingTaxes' : 'taxes';
  }

  get feesFieldName(): string {
    return this.policySummaryData.isPolicy ? 'billingFees' : 'fees';
  }

  get isCancelled(): boolean {
    return this.redStatusData.includes(`${this.submissionPageData.quoteStatus?.value}`);
  }

  get renewalSubStatusWord(): string {
    switch (this.policySummaryData.renewalSubStatusCode.value) {
      case this.pageHeaderConstants.policyRenewal.subStatusCode.CRW:
        return this.pageHeaderConstants.policyRenewal.subStatus.CRW;
      case this.pageHeaderConstants.policyRenewal.subStatusCode.SFNRW:
        return this.pageHeaderConstants.policyRenewal.subStatus.SFNRW;
      case this.pageHeaderConstants.policyRenewal.subStatusCode.NRWD:
        return this.pageHeaderConstants.policyRenewal.subStatus.NRWD;
    }
  }

  get isRenewalPending(): boolean {
    return (this.policySummaryData.renewalStatusCode === this.pageHeaderConstants.policyRenewal.statusCode.RNPEN ||
      this.policySummaryData.renewalCode === this.pageHeaderConstants.policyRenewal.statusCode.RNPEN) ||
      (this.policySummaryData.renewalStatusCode === this.pageHeaderConstants.policyRenewal.statusCode.RRNPEN ||
        this.policySummaryData.renewalCode === this.pageHeaderConstants.policyRenewal.statusCode.RRNPEN);
  }

  get isRenewalOffered(): boolean {
    return (this.policySummaryData.renewalStatusCode === this.pageHeaderConstants.policyRenewal.statusCode.RWO ||
      this.policySummaryData.renewalCode === this.pageHeaderConstants.policyRenewal.statusCode.RWO) ||
      (this.policySummaryData.renewalStatusCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO ||
        this.policySummaryData.renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO);
  }

  get isRenewed(): boolean {
    return this.policySummaryData.renewalStatusCode === this.pageHeaderConstants.policyRenewal.statusCode.RWD ||
      this.policySummaryData.renewalCode === this.pageHeaderConstants.policyRenewal.statusCode.RWD;
  }

  get renewalStatusWord(): string {
    if (this.policySummaryData.isPolicy) {
      return null;
    }

    this.isRescindedRenewal = false;

    switch (this.policySummaryData.renewalStatusCode) {
      case this.pageHeaderConstants.policyRenewal.statusCode.RNPEN:
        return this.pageHeaderConstants.policyRenewal.status.RNPEN;
      case this.pageHeaderConstants.policyRenewal.statusCode.RWO:
        return this.pageHeaderConstants.policyRenewal.status.RWO;
      case this.pageHeaderConstants.policyRenewal.statusCode.RRW:
        this.isRescindedRenewal = true;
        return this.pageHeaderConstants.policyRenewal.status.RRW;
      case this.pageHeaderConstants.policyRenewal.statusCode.RWNT:
        return this.pageHeaderConstants.policyRenewal.status.RWNT;
      case this.pageHeaderConstants.policyRenewal.statusCode.RRNPEN:
        return this.pageHeaderConstants.policyRenewal.status.RRNPEN;
      case this.pageHeaderConstants.policyRenewal.statusCode.RRWO:
        return this.pageHeaderConstants.policyRenewal.status.RRWO;
      case this.pageHeaderConstants.policyRenewal.statusCode.PRRW:
        this.isRescindedRenewal = true;
        return this.pageHeaderConstants.policyRenewal.status.PRRW;
    }
  }

  get showPolicyStatusNotActive(): boolean {
    return this.policySummaryData.isPolicy &&
      this.submissionPageData.policyStatus.value !== this.pageHeaderConstants.policyStatusActive &&
      this.submissionPageData.policyStatus?.value && !this.submissionPageData.isPendingCancellation.value;
  }

  get showPolicyStatusActiveOrPendingCancellation(): boolean {
    return this.policySummaryData.isPolicy &&
      this.submissionPageData.policyStatus.value !== this.pageHeaderConstants.policyStatusActive &&
      this.submissionPageData.quoteStatus.value !== this.pageHeaderConstants.policyStatusCancelled;
  }

  get showPolicyStatusNonPendingEndorsement(): boolean {
    return this.submissionPageData.policyStatus.value !== this.pageHeaderConstants.policyStatusPending &&
      this.submissionPageData.quoteStatus.value !== this.pageHeaderConstants.policyStatusCancelled;
  }

  get NotDefaultStatusValue(): boolean {
    return this.submissionPageData.policyStatus.value !== 'Status';
  }

  get showPolicyStatusPendingEndorsement(): boolean {
    return (this.submissionPageData.policyStatus.value === this.pageHeaderConstants.policyStatusPending) &&
      (this.submissionPageData.quoteStatus.value !== this.pageHeaderConstants.policyStatusCancelled);
  }

  get showActionTypesInFirstRow(): boolean {
    return (this.policySummaryData.isPolicy || this.isShowActionForNonPolicy) &&
      (
        this.policySummaryData.renewalSubStatusCode.value === null ||
        this.policySummaryData.renewalSubStatusCode.value === this.pageHeaderConstants.policyRenewal.subStatusCode.RNRW ||
        this.policySummaryData.renewalSubStatusCode.value === this.pageHeaderConstants.policyRenewal.subStatusCode.RVNRW
      ) &&
      !this.showPolicyStatusNotActive;
  }

  get isActive(): boolean {
    return this.riskStatus === this.pageHeaderConstants.policyStatusActive;
  }

  get isPolicyExpired(): boolean {
    return this.riskStatus === this.pageHeaderConstants.policyStatusPolicyExpired;
  }

  get renewalPendingSubStatus(): string {
    const validMainStatus = ['Referred to UW', 'Approved by UW', 'Declined by UW', 'Additional Information Required'];
    const showRenewalPendingSubStatus = validMainStatus.includes(this.riskStatus);
    return showRenewalPendingSubStatus ? this.riskStatus : '';
  }

  get showRenewalPolicyActionTypes(): boolean {
    return this.policySummaryData.isPolicy &&
      this.policySummaryData.withinRenewalPeriod &&
      this.isActive;
  }

  get isRenewalNotTaken(): boolean {
    return this.policySummaryData.renewalStatusCode === this.pageHeaderConstants.policyRenewal.statusCode.RWNT && !this.policySummaryData.isPolicy;
  }

  get isFromRewrite(): boolean {
    return this.entityRiskData?.EntityRisk?.risks[0]?.isFromRewrite;
  }

  get isRewriteFrom(): boolean {
    return this.entityRiskData?.EntityRisk?.risks[0]?.rewriteFromPolicyNumber !== null && this.entityRiskData?.EntityRisk?.risks[0]?.rewriteFromRiskId !== null && this.entityRiskData?.EntityRisk?.risks[0]?.rewriteFromDraftRiskDetailId !== null;
  }

  get isRewriteTo(): boolean {
    return this.entityRiskData?.EntityRisk?.risks[0]?.rewriteToPolicyNumber !== null && this.entityRiskData?.EntityRisk?.risks[0]?.rewriteToRiskId !== null && this.entityRiskData?.EntityRisk?.risks[0]?.rewriteToDraftRiskDetailId !== null;
  }

  approveRenewalReinstatement(): void {
    NotifUtils.showConfirmMessage(this.pageHeaderConstants.policyRenewal.approveRenewalReinstatement, () => {
      const riskId = this.summaryData.SummaryForm.get('riskId').value;
      const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;

      Utils.blockUI();

      this.policyService.putRenewalReinstatement(riskId, riskDetailId).pipe(takeUntil(this.stop$)).subscribe(result => {
        this.policySummaryData.renewalStatusCode = result;
        Utils.unblockUI();
      }, (error) => {
        Utils.unblockUI();
        NotifUtils.showError(error);
      });
    });
  }

  onChangePolicyNumber(event, isRewrite?: boolean): void {
    const selected = event?.target?.value;

    this.entityRiskData.isSwitchedRisk = true;

    if (isRewrite) {
      const policyObj = this.summaryData.policyNumbers.find(x => x.riskId === selected);

      if (policyObj.isEndorsement) {
        this.router.navigateByUrl(`/policies/${policyObj.riskId}/${policyObj.riskDetailId}/summary`);
      } else {
        this.router.navigateByUrl(`/submissions/${policyObj.riskId}/${policyObj.riskDetailId}/quickquote/applicant-page`);
      }
    } else {
      const policyObj = this.submissionListData.relatedSubmissionList.find(x => x.riskId === selected);

      if (policyObj.isEndorsement) {
        this.router.navigateByUrl(`/policies/${policyObj.riskId}/${policyObj.draftRiskDetailId}/summary`);
      } else {
        this.router.navigateByUrl(`/submissions/${policyObj.riskId}/${policyObj.submissionRiskDetailId}/quickquote/applicant-page`);
      }

    }

  }

  rescindPendingCancellation(): void {
    NotifUtils.showConfirmMessage(this.pageHeaderConstants.rescindPendingCancellationConfirmation, () => {
      Utils.blockUI();

      this.billingService.getNoticeHistoryDetails(this.summaryData.SummaryForm.get('riskId').value)
        .pipe(
          switchMap(noticeHistory => this.policyService.postRescindPendingCancellation(noticeHistory))
        ).subscribe((result: ManualRescindDTO) => {
          this.submissionPageData.isPendingCancellation.next(Boolean(result.subStatus));
          this.refreshDocumentLists();
          Utils.unblockUI();
        }, (error) => {
          Utils.unblockUI();
          NotifUtils.showError(error.message);
        });
    });
  }

  protected refreshDocumentLists(): void {
    this.policyService.getPolicyDocuments(this.summaryData.SummaryForm.get('riskId').value)
      .pipe(takeUntil(this.stop$)).subscribe(documents => {
        this.policyDocumentsData.mapPolicyDocuments(documents);
        this.policyDocumentsData.getFTPDocuments(this.summaryData.SummaryForm.get('riskId').value);
      });
  }
  get nonPolicyActionLabel(): string {
    /*eslint curly: ["error", "multi-or-nest"]*/
    if (this.isRenewalPending)
      return this.pageHeaderConstants.Header.RescindRenewalPending;
    else if (this.isRenewalOffered)
      return this.pageHeaderConstants.Header.RescindRenewalOffer;
    else if  (this.isRescindedRenewal && this.policySummaryData.renewalStatusCode === this.pageHeaderConstants.policyRenewal.statusCode.PRRW)
      return this.pageHeaderConstants.Header.ReverseRescindRenewalPending;
    else if  (this.isRescindedRenewal && this.policySummaryData.renewalStatusCode === this.pageHeaderConstants.policyRenewal.statusCode.RRW)
      return this.pageHeaderConstants.Header.ReverseRescindRenewalOffer;
    else
      return '';
  }

  get isShowActionForNonPolicy(): boolean {
    return !this.policySummaryData.isPolicy &&
      this.isInternalUser &&
      this.renewalTermData.isForRescindConvertedRenewalProcess(this.isRenewalPending, this.isRenewalOffered, this.isRescindedRenewal);
  }
}
