import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SubmissionService } from 'app/core/services/submission/submission.service';
import { PagerService } from 'app/core/services/pagerService';
import { BaseClass } from 'app/shared/base-class';
import { FilterSubmissionListDTO } from 'app/shared/models/data/dto/submission-list/filter-submission-list.dto';
import { EntitySubmissionListDTO } from 'app/shared/models/data/dto/submission-list/entity-submission-list.dto';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { NgxSpinnerService } from 'ngx-spinner';
import Utils from 'app/shared/utilities/utils';
import { LvRiskStatus } from 'app/shared/constants/risk-status';
import { GenericLabel } from 'app/shared/constants/generic.labels.constants';
import { AuthService } from '../../../core/services/auth.service';
import { Subscription } from 'rxjs';
import { AgentDashboardConstants } from '../../../shared/constants/agent-dashboard.constants';
import { ProgramStateDTO } from '../../../shared/models/management/program-state.dto';
import { AgentService } from '../../../core/services/management/agent-service';
import { DatePipe } from '@angular/common';
import { SummaryData } from './summary.data';
import { updateAppIsLoadingFromSubmissionListData } from 'app/store/app/app.actions';
import { Store } from '@ngrx/store';
import {
  updateRelatedRisksIsLoadingFromSubmissionListData,
  updateRelatedRisksListFromSubmissionListData
} from 'app/store/related-risks/related-risks.actions';

@Injectable({
  providedIn: 'root'
})
export class SubmissionListData extends BaseClass {
  public LvRiskStatus = LvRiskStatus;
  public GenericLabel = GenericLabel;
  public pager: any = {};
  public loading: boolean;
  public submissionsData: EntitySubmissionListDTO[];
  public rawSubmissionList: EntitySubmissionListDTO[];
  public pagedItems: EntitySubmissionListDTO[];
  public datepickerOptions: IAngularMyDpOptions;
  public formTypes: Array<string> = [];
  public isLoading: boolean = false;
  public noResult: boolean = false;
  public showLoader: boolean = true;

  datePipe: DatePipe;

  public statusData: any[] = [
    { name: 'Quoted', value: 'QUO' },
    { name: 'Application', value: 'APP'},
    { name: 'Active', value: 'ACT'},
    { name: 'Referred to UW', value: 'RUW'},
    { name: 'Approved by UW', value: 'AUW'},
    { name: 'Declined by UW', value: 'DUW'},
    { name: 'Additional Info Required', value: 'AIR'},
    { name: 'Quote Expired', value: 'QEXP'},
    { name: 'Policy Expired', value: 'PEXP'},
    { name: 'Cancelled', value: 'CAN'},
    { name: 'Renewal Pending', value: 'RNPEN_ALL'},
    { name: 'Renewal Offered', value: 'RWO_ALL'},
    { name: 'Renewal Not Taken', value: 'RWNT'},
    { name: 'Rescinded Renewal Offer ', value: 'RRW'},
    { name: 'Rescinded Renewal Pending ', value: 'PRRW'},
    // { name: 'Revised Renewal Pending', value: 'RRNPEN'},
    // { name: 'Revised Renewal Offered', value: 'RRWO'}
  ];

  public subStatusData: any[] = [
    { name: 'Referred to UW', value: 'RUW'},
    { name: 'Approved by UW', value: 'AUW'},
    { name: 'Declined by UW', value: 'DUW'},
    { name: 'Additional Info Required', value: 'AIR'},
    { name: 'Pending Cancellation', value: 'PEC'},
    // { name: 'Pending Non-Renewal', value: 'PNR'},
    // { name: 'Pending Renewal', value: 'RNPEN_ALL'},
    { name: 'Non-Renewed', value: 'NRWD'},
    { name: 'Scheduled for Non-Renewal', value: 'SFNRW'}
  ];

  public sortBy: any[] = [
    {name: 'Policy Number (Ascending)', value: 'Policy Number (Ascending)', isAsc: true},
    {name: 'Policy Number (Descending)', value: 'Policy Number (Descending)', isAsc: false},
    {name: 'Quote Number (Ascending)', value: 'Quote Number (Ascending)', isAsc: true},
    {name: 'Quote Number (Descending)', value: 'Quote Number (Descending)', isAsc: false},
    {name: 'Insured Name (A-Z)', value: 'Insured Name (A-Z)', isAsc: true},
    {name: 'Insured Name (Z-A)', value: 'Insured Name (Z-A)', isAsc: false},
    {name: 'Effective Date (Oldest First)', value: 'Effective Date (Oldest First)', isAsc: true},
    {name: 'Effective Date (Newest First)', value: 'Effective Date (Newest First)', isAsc: false},
    {name: 'Expiration Date (Oldest First)', value: 'Expiration Date (Oldest First)', isAsc: true},
    {name: 'Expiration Date (Newest First)', value: 'Expiration Date (Newest First)', isAsc: false},
    {name: 'Status (A-Z)', value: 'Status (A-Z)', isAsc: true},
    {name: 'Status (Z-A)', value: 'Status (Z-A)', isAsc: false}
  ];

  public searchForm: FormGroup;
  public searchFilterForm: FormGroup;
  public agentDashboardConstants = AgentDashboardConstants;
  public submissionListRequest: FilterSubmissionListDTO = new FilterSubmissionListDTO();
  public isSubmissionListPage: boolean = false;
  protected subscription: Subscription;
  public isAscendingOrder: boolean = true;

  itemPerPage: number = 10;
  currentPage: number = 1;
  totalRowCount: number = 500;
  isAscending: boolean = true;

  isRelatedSubmissionListLoading: boolean = false;
  relatedSubmissionList: EntitySubmissionListDTO[] = [];

  relatedSubmissionListSubscription: Subscription;

  isForPaymentDue: boolean = false;

  constructor(
    protected formBuilder: FormBuilder,
    protected pagerService: PagerService,
    protected submissionService: SubmissionService,
    protected authService: AuthService,
    protected agentService: AgentService,
    protected summaryData: SummaryData,
    protected store: Store
  ) {
    super();
  }

  initForms(): void {
    this.initRiskData();

    this.datepickerOptions = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy',
      disableSince: {year: 0, month: 0, day: 0},
      disableUntil: {year: 0, month: 0, day: 0}
    };

    this.searchForm = this.formBuilder.group({
      searchText: ['',[]]
    });


  }

  setDateFromSubmissionListRequest(dateFrom: Date): void {
    this.submissionListRequest.inceptionDateFrom = dateFrom;
  }

  setDateToSubmissionListRequest(dateTo: Date): void {
    this.submissionListRequest.inceptionDateTo = dateTo;
  }

  setSearchTextSubmissionListRequest(searchText: string): void {
    this.submissionListRequest.searchText  = searchText;
  }

  setStateSubmissionListRequest(stateCode: string): void {
    this.submissionListRequest.stateCode = stateCode;
  }

  setFormTypeSubmissionListRequest(formType: string): void {
    this.submissionListRequest.formType = formType;
  }

  setBrokerSubmissionListRequest(broker: string): void {
    this.submissionListRequest.broker = broker;
  }

  setSortFieldSubmissionListRequest(sortField: string): void {
    this.submissionListRequest.sortField = sortField;
    this.isAscending = this.sortBy.find(a => a.name === sortField)?.isAsc;
    this.submissionListRequest.isAscendingOrder = this.isAscending;
  }

  setStatusSubmissionListRequest(status: string): void {
    const renewalStatus = ['RWNT', 'RRW', 'PRRW'];

    if (renewalStatus.includes(status)) {
      this.submissionListRequest.status = '';
      this.setRenewalStatusSubmissionListRequest(status);
      return;
    }

    this.submissionListRequest.status = status;
    this.setRenewalStatusSubmissionListRequest('');
  }

  setRenewalStatusSubmissionListRequest(status: string): void {
    this.submissionListRequest.renewalCode = status;
    this.submissionListRequest.renewalStatus = status;
  }

  setSubStatusSubmissionListRequest(subStatus: string): void {
    this.submissionListRequest.subStatus = subStatus;
  }

  setRiskIdSubmissionListRequest(riskId: string[]): void {
    this.submissionListRequest.riskId = riskId;
  }

  setAddressSubmissionListRequest(address: string): void {
    this.submissionListRequest.address = address;
  }

  buildSubmissionListRequest(searchFilterForm: FormGroup): void {
    const dateFrom = searchFilterForm?.value.effectiveDateFrom?.singleDate?.formatted || '';
    const dateTo = searchFilterForm?.value.effectiveDateTo?.singleDate?.formatted || '';
    this.setSearchTextSubmissionListRequest(searchFilterForm?.value?.searchText?.toString()?.trim());
    this.setStateSubmissionListRequest(searchFilterForm?.value?.stateCode?.toString()?.trim());
    this.setFormTypeSubmissionListRequest(searchFilterForm?.value.formType);
    this.setSortFieldSubmissionListRequest(searchFilterForm?.value.sortField);
    this.setBrokerSubmissionListRequest(searchFilterForm?.value?.broker?.toString()?.trim());
    this.setDateFromSubmissionListRequest(dateFrom);
    this.setDateToSubmissionListRequest(dateTo);
    this.setStatusSubmissionListRequest(searchFilterForm?.value.status);
    this.setSubStatusSubmissionListRequest(searchFilterForm?.value.subStatus);
    this.setRiskIdSubmissionListRequest(searchFilterForm?.value.riskId);
    this.setAddressSubmissionListRequest(searchFilterForm?.value?.address?.toString()?.trim());
    this.submissionListRequest.pageSize = this.itemPerPage;
    this.submissionListRequest.pageNumber = this.currentPage;
    this.buildSubmissionListRequestWithAgencyDetails();
  }

  buildSubmissionListRequestWithAgencyDetails(): void {
    let isExternal = true;

    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(x => {
      isExternal = (x === 'external');
    });

    this.submissionListRequest.agencyId = isExternal ? this.authService.agenciesInfo?.id : null;
    this.submissionListRequest.agentId = isExternal ? this.authService.agentUserInfo?.id : null;
    this.submissionListRequest.formTypes = isExternal ? !!this.formTypes ? this.formTypes.filter((el, i, a) => i === a.indexOf(el)) : [] : null ;
    Utils.blockUI();
    const subscriptionList = this.authService.isAgentUserInfoCompleted.pipe(takeUntil(this.stop$)).subscribe((result) => {
      if (result) {
        this.submissionListRequest.agencyId = isExternal ? this.authService.agenciesInfo?.id : null;
        this.submissionListRequest.agentId = isExternal ? this.authService.agentUserInfo?.id : null;

        this.submissionListRequest.formTypes = isExternal ? this.getFormType(this.authService.agentProgramStateDetails) : null;

        if (!this.authService.isAgentDashboardListDone && this.isSubmissionListPage) {
          this.callSubmissionList();
        }

      }
    }, error => {
    }, () => {
      this.authService.isAgentUserInfoCompleted.next(false);
    });
  }

  constructFormGroup(): FormGroup {
    return this.formBuilder.group({
      searchText: new FormControl(''),
      broker: new FormControl(''),
      effectiveDateFrom: new FormControl(''),
      effectiveDateTo: new FormControl(''),
      status: new FormControl(''),
      subStatus: new FormControl(''),
      formType: new FormControl(''),
      address: new FormControl(''),
      sortField: new FormControl('Insured Name (A-Z)'),
      stateCode: new FormControl('')
    });
  }

  constructFormGroupBasedOnStatusAndSubStatus(searchQuery: string = '', subStatus: string = ''): FormGroup {
    return this.formBuilder.group({
      searchText: new FormControl(''),
      formType: new FormControl(''),
      broker: new FormControl(''),
      effectiveDateFrom: new FormControl(''),
      effectiveDateTo: new FormControl(''),
      status: new FormControl(searchQuery),
      subStatus: new FormControl(subStatus),
      address: new FormControl(''),
      sortField: new FormControl('InsuredName'),
      stateCode: new FormControl('')
    });
  }

  constructFormGroupBasedOnPendingQuotes(searchQuery: string = ''): FormGroup {
    return this.formBuilder.group({
      searchText: new FormControl(''),
      broker: new FormControl(''),
      effectiveDateFrom: new FormControl(''),
      effectiveDateTo: new FormControl(''),
      status: new FormControl(searchQuery),
      subStatus: new FormControl(''),
      formType: new FormControl(''),
      address: new FormControl(''),
      sortField: new FormControl('InsuredName'),
      stateCode: new FormControl('')
    });
  }

  constructFormGroupSearchText(searchQuery: string = ''): FormGroup {
    return this.formBuilder.group({
      searchText: new FormControl(searchQuery),
      broker: new FormControl(''),
      effectiveDateFrom: new FormControl(''),
      effectiveDateTo: new FormControl(''),
      status: new FormControl(''),
      subStatus: new FormControl(''),
      formType: new FormControl(''),
      address: new FormControl(''),
      sortField: new FormControl('InsuredName'),
      stateCode: new FormControl('')
    });
  }

  constructFormGroupForPaymentDue(riskData: string[] = []): FormGroup {
    return this.formBuilder.group({
      searchText: new FormControl(''),
      broker: new FormControl(''),
      effectiveDateFrom: new FormControl(''),
      effectiveDateTo: new FormControl(''),
      status: new FormControl(this.agentDashboardConstants.actParam),
      subStatus: new FormControl(''),
      formType: new FormControl(''),
      riskId: new FormControl(riskData),
      address: new FormControl(''),
      sortField: new FormControl('InsuredName'),
      stateCode: new FormControl('')
    });
  }

  initRiskData(): void {
    // Fetch get all data in API backend
    this.submissionsData = new Array();
    this.rawSubmissionList = new Array();
    this.showLoader = true;
  }

  callSubmissionList(): void {

    if(this.isLoading) { return ;};

    const request = this.submissionListRequest;

    if(this.subscription){
      this.subscription.unsubscribe();
    }

    if (request.agencyId && request.agentId && request.formTypes.length > 0) {
      this.authService.isAgentDashboardListDone = true;
    }

    this.submissionsData = new Array();

    Utils.blockUI();
    this.isLoading = true;

    this.subscription = this.submissionService.getSubmissionListAsync(request).pipe(takeUntil(this.stop$)).subscribe(result => {
        this.totalRowCount = result.paginationDetails.totalCount;
        this.currentPage = result.paginationDetails.currentPage;
        this.noResult = this.totalRowCount === 0;
        this.initSubmissionListData(result.resultList, request);
      }, error => {
        Utils.unblockUI();
        NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
      });
  }

  callRelatedSubmissionList(riskId: string, riskDetailId?: string, isInternal?: boolean, risk?: any): void {
    if (this.relatedSubmissionListSubscription) {
      this.relatedSubmissionListSubscription.unsubscribe();
    }

    this.store.dispatch(updateAppIsLoadingFromSubmissionListData({ isLoading: true }));
    this.store.dispatch(updateRelatedRisksIsLoadingFromSubmissionListData({ isLoading: true }));
    this.isRelatedSubmissionListLoading = true;

    this.relatedSubmissionListSubscription = this.submissionService.getRelatedSubmissionListAsync(riskId).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.relatedSubmissionList = result.map(submissionList => {
        if (submissionList.isEndorsement) {
          submissionList.policyQuoteAndEffectiveDate = `${submissionList.policyNumber} (${new Date(submissionList.firstIssueDate).toLocaleDateString('en-US')})`;
        } else {
          submissionList.policyQuoteAndEffectiveDate = `${submissionList.quoteNumber} (${new Date(submissionList.firstIssueDate).toLocaleDateString('en-US')})`;
        }
        return submissionList;
      }).filter(data => data.policyNumber !== null).sort((a, b) => {
        const aQuoteAndYear = a.policyQuoteAndEffectiveDate;
        const bQuoteAndYear = b.policyQuoteAndEffectiveDate;
        const aYear = Number(aQuoteAndYear.substring(aQuoteAndYear.length - 5, aQuoteAndYear.length - 1));
        const bYear = Number(bQuoteAndYear.substring(bQuoteAndYear.length - 5, bQuoteAndYear.length - 1));
        return bYear-aYear;
      });

      if (!isInternal) {
        this.relatedSubmissionList = this.relatedSubmissionList.filter(data => {
          return data.renewalStatus !== 'RNPEN';
        });
      }

      this.summaryData.setDropdownPolicyNumber(risk, this.relatedSubmissionList);

      this.noResult = result.length === 0;
      this.showLoader = false;
      this.isRelatedSubmissionListLoading = false;

      this.store.dispatch(updateRelatedRisksListFromSubmissionListData({ list: result }));
      this.store.dispatch(updateAppIsLoadingFromSubmissionListData({ isLoading: false }));
      this.store.dispatch(updateRelatedRisksIsLoadingFromSubmissionListData({ isLoading: false }));
    }, error => {
      this.store.dispatch(updateAppIsLoadingFromSubmissionListData({ isLoading: false }));
      this.store.dispatch(updateRelatedRisksIsLoadingFromSubmissionListData({ isLoading: false }));
      NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
    });

  }

  initSubmissionListData(result: any, request: FilterSubmissionListDTO): void {
    this.rawSubmissionList = result;
    if (this.rawSubmissionList) {
      let agentId: string[] = [];
      let agencyId: string[] = [];

      this.rawSubmissionList.forEach(data => {
        if (data.agentId !== null) {
          agentId.push(data.agentId);
        }

        if (data.agencyId !== null) {
          agencyId.push(data.agencyId);
        }
      });
      agentId = agentId.filter((n, i) => agentId.indexOf(n) === i);
      agencyId = agencyId.filter((n, i) => agencyId.indexOf(n) === i);

      if (agentId.length > 0) {
        this.authService.getAllAgenciesSubagencies(agentId);
      }

      if (agencyId.length > 0) {
        this.authService.getAllAgenciesSubagenciesByAgencyId(agencyId);
      }

      this.agentService.getAllAgenciesSubagencies(agentId).pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe((response: any) => {
        this.authService.getAllAgenciesSubAgencies = response;
        this.rawSubmissionList = this.rawSubmissionList.map(subData => {
          const renewalSubmissionValidCodes = ['RNPEN', 'RWO', 'RWNT', 'RRNPEN', 'RRWO', 'RRW', 'PRRW'];
          if (subData.agentId !== null) {
            const AgenciesSubAgenciesInformation = this.authService.getAllAgenciesSubAgencies?.find(x => x.agentId === subData.agentId);
            subData.broker = AgenciesSubAgenciesInformation?.agencies.find(x => x.id === subData.agencyId)?.name ?? '';
          } else if (subData.agencyId !== null) {
            const AgenciesSubAgenciesInformation = this.authService.getAllAgenciesSubAgenciesByAgencyId?.find(x => x.id === subData.agencyId);
            subData.broker = AgenciesSubAgenciesInformation?.entity?.companyName ?? '';
          }
          if (!subData.isEndorsement && renewalSubmissionValidCodes.includes(subData.renewalCode)) {
            subData.status = subData.renewalCode;
          }
          return subData;
        });
      }, error => {
        this.submissionsData = this.rawSubmissionList;
        this.showLoader = false;
        this.isLoading = false;
        Utils.unblockUI();
      }, () => {
          this.submissionsData = this.rawSubmissionList;
          this.showLoader = false;
          this.isLoading = false;
          Utils.unblockUI();
      });
    }
    if (request.agencyId && request.agentId && request.formTypes.length > 0) {
      this.authService.isAgentDashboardListDone = false;
    }
  }

  setPage(page: number): void {
    if (page < 1) {
        return;
    }

    this.pager = this.pagerService.getPager(this.submissionsData.length, page);
    this.pagedItems = this.submissionsData.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  getFormType(programStateDetails: ProgramStateDTO[]): Array<string> {
    this.formTypes = [];
    programStateDetails.forEach(res => {
        this.formTypes.push(res.program.formTypeCode);
    });

    return this.formTypes.filter((el, i, a) => i === a.indexOf(el));
  }
}
