import { Injectable, Injector } from '@angular/core';
import { HttpHandler, HttpEvent, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { EMPTY, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { DatePipe } from '@angular/common';
import { catchError } from 'rxjs/operators';
import { TokenResponse } from 'app/shared/models/tokenResponse';
import Utils from 'app/shared/utilities/utils';
import { PathConstants } from 'app/shared/constants/path.constants';
import { Router } from '@angular/router';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  auth: AuthService;
  currentRequest: HttpRequest<any>;

  constructor(private injector: Injector,
    private router: Router,
    private datePipe: DatePipe,
    private toastr: ToastrService
    ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    this.currentRequest = request;
    const authService = this.injector.get(AuthService);
    const notRequiredForTokenURLs = ['https://api.ipify.org/?format=json'];

    let token = null;
    if (authService.isLoggedIn()) {
      const auth = authService.getAuth();
      token = auth ? auth.token : null;
    } else if (authService.hasToken()) {
      const auth = authService.getTokenResponse();
      token = auth.token;
    }

    if (token) {
      if (!notRequiredForTokenURLs.includes(request.url)) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          }
        });
      }
    }

    const localStorageDate = localStorage.getItem('loginDate');
    const UTCDate = localStorage.getItem('UTCServerDate');
    const ESTDate = localStorage.getItem('ESTServerDate');
    if ((localStorageDate !== 'undefined' && localStorageDate !== null) &&
        (UTCDate !== 'undefined' && UTCDate !== null) &&
        (ESTDate !== 'undefined' && ESTDate !== null)) {
      request = request.clone({
        setHeaders: {
          userdate: this.datePipe.transform(authService.getCustomDate(), 'yyyy-MM-ddTHH:mm:ss.SSSZ')
        }
      });
    }

    return next.handle(request).pipe(
      catchError(error => {
        console.log('error', error);
          return this.handleError(error);
      }));;
  }

  handleError(error: any, token?: TokenResponse) {
    Utils.isExpiredToken = error.status === 401;
    const isInsured = token ? JSON.parse(atob(JSON.stringify(token)?.split('.')[1]))?.is_insured : true;
    if (error.status === 401) {
        if (window.location.pathname !== `/${PathConstants.Auth.Index}`) {
            if (this.router.url.includes('/redirectmakeapayment') || this.router.url.includes('/makeapayment')) {
                this.showUnauthorizedError(isInsured);
            } else {
                const isSessionOpen = localStorage.getItem('isSessionOpen');
                if(isSessionOpen !== 'true'){
                    this.showUnauthorizedError(false);
                }
            }
        }
    } else if (error.status === 429) {
        if (this.currentRequest.url.includes('rater')) {
          this.toastr.error(ErrorMessageConstant.raterTooManyRequestMessage);
        }
        this.showTooManyRequestsError();
        return EMPTY;
    } else if (error.status === 500) {
        // error.error = error.error ? error.error : 'Internal Server Error';
        console.log('error 500', error);
        return throwError(error);
    } else {
        console.log('other error', error);
        return throwError(error);
    }
  }

  showUnauthorizedError(isInsured?: boolean): void {
    window.localStorage.setItem('isError404Open', 'true');
    const defaults: any = {
        title: 'Oops...',
        icon: ErrorMessageConstant.unAuthorizedError.error,
        allowOutsideClick: false
    };

    if (isInsured) {
        NotifUtils.showNotice(ErrorMessageConstant.unAuthorizedError.sessionExpiredMessage, defaults, () => {
            this.auth.closeAllOpenModals();
            this.auth.logout();
            localStorage.removeItem('auth');
            window.open(environment.centauriHomePageURL, '_self');
        });
    } else {
        NotifUtils.showNotice(ErrorMessageConstant.unAuthorizedError.message, defaults, () => {
            this.auth.closeAllOpenModals();
            this.auth.logout();
            this.router.navigate([`/${PathConstants.Login.Index}`]);
        });
    }
  }

  showTooManyRequestsError(): void {
    Utils.unblockUI();
    const defaults: any = {
        icon: 'error',
        allowOutsideClick: false
    };

    NotifUtils.showNotice(ErrorMessageConstant.error429TooManyRequestMessage, defaults, () => {
    });
  }
}
