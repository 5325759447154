import { Pipe, PipeTransform } from '@angular/core';

interface IHurricaneDeductibleMinimumOf5000RequiredInfoArgs {
  formType: string;
  hurricaneDeductibleIsValid: boolean;
  hurricaneDeductible: string;
  homeAge: number;
  isActiveIPXBrul: boolean;
  isInternal: boolean;
  isPolicyOrRenewal: boolean;
  hurricaneDeductibleBackup: number;
}

@Pipe({
  name: 'showHurricaneDeductibleMinimumOf5PercentRequiredInfo'
})
export class HurricaneDeductibleMinimumOf5PercentRequiredPipe implements PipeTransform {
  transform(hurricaneDeductibleMinimumOf5000RequiredInfoArgs: IHurricaneDeductibleMinimumOf5000RequiredInfoArgs): boolean {
    const {
      formType,
      hurricaneDeductibleIsValid,
      hurricaneDeductible,
      homeAge,
      isActiveIPXBrul,
      isInternal,
      isPolicyOrRenewal,
      hurricaneDeductibleBackup
    } = hurricaneDeductibleMinimumOf5000RequiredInfoArgs;

    const validFormTypes: string[] = ['HO3', 'DP3'];
    const homeAgeMinimum: number = 10;
    const isValidFormType: boolean = validFormTypes.includes(formType);
    const excluded: string = 'E';
    const hurricaneDeductibleMinimum: number  = 5;

    let showMessage: boolean = false;
    let status: boolean = false;

    if (hurricaneDeductibleIsValid && isValidFormType && hurricaneDeductible !== excluded) {
      if (Number(hurricaneDeductible) === 500 && homeAge >= homeAgeMinimum && isActiveIPXBrul) {
        showMessage = true;
      } else if (Number(hurricaneDeductible) < hurricaneDeductibleMinimum && homeAge >= homeAgeMinimum && isActiveIPXBrul) {
        showMessage = true;
      }
    }

    if (isInternal) {
      status = showMessage;
    } else if (isPolicyOrRenewal && hurricaneDeductibleBackup < hurricaneDeductibleMinimum) {
      status = showMessage;
    }

    return status;
  }
}
