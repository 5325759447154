import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { PathConstants } from '../../shared/constants/path.constants';
import { AuthService } from '../services/auth.service';

@Injectable()
export class RaterMigrationGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _router: Router
  ) { }

  canActivate() {

    if (!this._authService.isLoggedIn()) {
      this._router.navigate([PathConstants.Login.Index]);
      return false;
    }

    const isInternal = localStorage.getItem('userType') === 'internal';
    if (!isInternal) {
      this._router.navigate(['404']);
      return false;
    }

    return true;
  }
}