import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { LeadingIndicatorComponent } from './leading-indicator.component';



@NgModule({
  declarations: [LeadingIndicatorComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class LeadingIndicatorModule { }
