
import { ITableTr } from "app/shared/models/dynamic/table.interface";
import { RiskUWApprovalDTO } from "app/shared/models/data/dto/quick-quote/uw-approval.dto";

export interface SubmissionUWRsState {
  isLoading: boolean | null;
  list: RiskUWApprovalDTO[] | null;
  tableRows: ITableTr[] | null;
}
export const initialState = {
  isLoading: null,
  list: null,
  tableRows: null
}