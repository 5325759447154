export const CommissionPaymentValidatorErrorConstant = {
  NegativeAmountIsNotAllowErrorMessage: {
    key: 'NegativeAmountIsNotAllow',
    value: 'Negative amount for Payment is not allow.'
  },
  CannotBeZeroOrNull: {
    key: 'CannotBeZeroOrNull',
    value: 'Amount is required.'
  }
};
