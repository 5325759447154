import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ErrorMessageConstant } from '../../../../../../app/shared/constants/error-message.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { PolicyBillingLabelsConstants } from '../../../../../../app/shared/constants/policy-billing.labels.constants';
import { PolicyBillingData } from '../../../../../../app/modules/policy-management/data/policy-billing.data';
import { PaymentMethod } from '../../../../../../app/shared/enum/payment-method.enum';
import { PaymentConfirmationModalComponent } from '../payment-confirmation-modal/payment-confirmation-modal.component';
import { TransferPaymentModalComponent } from '../transfer-payment-modal/transfer-payment-modal.component';
import { AuthService } from '../../../../../../app/core/services/auth.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { BaseClass } from '../../../../../../app/shared/base-class';
import { EscheatConfirmationModalComponent } from '../escheat-confirmation-modal/escheat-confirmation-modal.component';
import { PaymentDetailsViewDTO } from '../../../../../../app/shared/models/data/dto/billing/payment-details-view.dto';
import { ReversePaymentDTO } from '../../../../../../app/shared/models/data/dto/billing/reverse-payment.dto';
import { ReversalType } from '../../../../../../app/shared/enum/reversal-type.enum';
import { BillingService } from '../../../../../../app/core/services/billing/billing.service';
import Utils from '../../../../../../app/shared/utilities/utils';
import NotifUtils from '../../../../../../app/shared/utilities/notif-utils';
import { TransferPaymentDTO } from '../../../../../../app/shared/models/data/dto/billing/transfer-payment.dto';
import { PolicyService } from '../../../../../../app/core/services/submission/policy.service';
import { forkJoin, of } from 'rxjs';
import { CurrencyPipe } from '@angular/common';
import { CopyPaymentRelatedDocDTO } from '../../../../../../app/shared/models/data/dto/billing/copy-payment-related-doc.dto';
import { EntityRiskData } from '../../../../../../app/modules/submission-management/data/entity-risk.data';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { RefundSetClearDateDTO } from '../../../../../../app/shared/models/data/dto/billing/refund-set-clear-date.dto';
import { ReversalTypeBillingValue } from '../../../../../../app/shared/enum/reversal-type-billing-value.enum';
import { RefundSetEscheatDateDTO } from '../../../../../../app/shared/models/data/dto/billing/refund-set-escheat-date.dto';
import { PolicyNotesData } from 'app/modules/policy-management/data/policy-notes.data';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { PolicyDocumentsData } from '../../../../../../app/modules/policy-management/data/policy-documents.data';
import { SubmissionPageData } from '../../../../submission-management/data/submission-page.data';
import { RiskStatus } from '../../../../../shared/models/data/dto/quick-quote/risk.dto';
import { LvRiskStatus } from '../../../../../shared/constants/risk-status';
import { SummaryData } from '../../../../submission-management/data/summary.data';
import { Store } from '@ngrx/store';
import { StatusOnBillingTransaction } from '../../../../../shared/models/data/dto/policy-management/status-on-billing-transaction.dto';
import { AutoReinstatementData } from '../../../data/auto-reinstatement.data';
import { updatePolicyStatusFromPolicyCancellationComponent } from 'app/store/policy/policy.actions';
import * as moment from 'moment';
import { TransferRenewalPaymentModalComponent } from '../transfer-renewal-payment-modal/transfer-renewal-payment-modal.component';
import { RenewalReinstatementApprovalDetailsRequestDTO } from '../../../../../shared/models/data/dto/billing/renewal-reinstatement-approval-details-request.dto';
import { SubmissionListData } from '../../../../../modules/submission-management/data/submission-list.data';
import { RiskDTO2 } from '../../../../../shared/models/data/dto/quick-quote/risk-dto2';
import { TransferPaymentToSuspenseModalComponent } from '../transfer-payment-to-suspense-modal/transfer-payment-to-suspense-modal.component';
import { SuspendedPaymentRequestDTO } from '../../../../../shared/models/data/dto/billing/suspended-payment-request.dto';
import { CheckTypePaymentMethods } from '../../../../../shared/constants/transfer-to-suspense.options.constants';
import { SuspendedPaymentResponseDTO } from '../../../../../shared/models/data/dto/billing/suspended-payment-response.dto';
import { TransferPaymentToSuspenseRequestDTO } from '../../../../../shared/models/data/dto/billing/transfer-payment-to-suspense-request.dto';
import { VoidSuspendedPaymentDTO } from '../../../../../shared/models/data/dto/suspended-payment/void-suspended-payment.dto';
import { PaymentSuspendedDocDTO } from '../../../../../shared/models/data/dto/billing/payment-suspended-doc.dto';
import { RelatedDocumentsComponent } from '../related-documents/related-documents.component';
import { SuspendedPaymentsData } from 'app/modules/payments/data/suspended-payments.data';
import { UndoPaymentNsfRequestDTO } from 'app/shared/models/data/dto/billing/undo-payment-nsf-request.dto';
import { UndoPaymentNsfProcessResponseDTO } from 'app/shared/models/data/dto/billing/undo-payment-nsf-response.dto';
import { PolicyCancellationData } from 'app/modules/policy-management/data/policy-cancellation.data';

@Component({
  selector: 'app-payment-details-modal',
  templateUrl: './payment-details-modal.component.html',
  styleUrls: ['./payment-details-modal.component.scss']
})
export class PaymentDetailsModalComponent extends BaseClass implements OnInit {
  public event: EventEmitter<any> = new EventEmitter<any>();
  public ErrorMessageConstant = ErrorMessageConstant;
  public PaymentDetailConstants = PolicyBillingLabelsConstants.paymentDetails;
  PaymentMethod = PaymentMethod;
  isInternal: boolean;

  modalRef: BsModalRef | null;
  title: string;

  paymentData: PaymentDetailsViewDTO;

  isPaymentReversal: boolean = false;
  isRefund: boolean = false;
  isEscheat: boolean = false;
  isFromSuspended: boolean = false;

  isNSF: boolean = false;

  paymentConfirmationModalRef: BsModalRef | null;

  methodLabel: string;

  datePickerDateOption: IAngularMyDpOptions;
  createdSuspense: SuspendedPaymentResponseDTO;

  renewalRisk: RiskDTO2;
  isApproved: boolean;
  requiredAmountToBind: any;

  @ViewChild(RelatedDocumentsComponent)
  protected relatedDocsComponent!: RelatedDocumentsComponent;

  constructor(
    public bsModalRef: BsModalRef,
    public billingData: PolicyBillingData,
    protected modalService: BsModalService,
    protected authService: AuthService,
    protected billingService: BillingService,
    protected policyService: PolicyService,
    protected currencyPipe: CurrencyPipe,
    protected entityRiskData: EntityRiskData,
    protected policyNotesData: PolicyNotesData,
    protected policySummaryData: PolicySummaryData,
    protected policyDocumentsData: PolicyDocumentsData,
    public submissionPageData: SubmissionPageData,
    protected summaryData: SummaryData,
    protected store: Store,
    protected autoReinstatementData: AutoReinstatementData,
    protected submissionListData: SubmissionListData,
    protected entityData: EntityRiskData,
    protected suspendedPaymentData: SuspendedPaymentsData,
    protected policyCancellationData: PolicyCancellationData
  ) {
    super();
  }

  ngOnInit() {
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.isInternal = this.isInternalUser(userType);
    });

    this.initForm();
    this.checkRwntApprovalForRenewalTransfer();
    this.checkRenewalOfferForRenewalTransfer();
  }

  initForm(): void {
    this.datePickerDateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy',
      selectorWidth: '230px',
      selectorHeight: '250px'
    };

    this.populateFields();
  }

  populateFields(): void {
    this.billingData.paymentDetailsForm.reset();

    if (this.paymentData.suspendedPaymentDetail === null) {
      this.paymentData.suspendedPaymentDetail = {};
    } else {
      if (this.paymentData.suspendedPaymentDetail.payer === null) {
        this.paymentData.suspendedPaymentDetail.payer = {};
      }
    }

    this.billingData.paymentDetailsForm.patchValue(this.paymentData);
    this.billingData.paymentDetailsForm.get('paymentMethod').enable();
    this.billingData.paymentDetailsForm.get('paymentMethod').setValue(this.paymentData.paymentMethod);

    if (this.billingData.paymentDetailsForm.value.reversalType) {
      this.isPaymentReversal = true;
    }

    if (this.billingData.paymentDetailsForm.value.escheatDate) {
      this.isEscheat = true;
    }

    if (this.isRefund) {
      this.methodLabel = this.PaymentDetailConstants.refundMethod;
    } else {
      this.methodLabel = this.PaymentDetailConstants.method;
    }

    if (this.billingData.paymentDetailsForm.get('reversalType').value === ReversalTypeBillingValue.NSF) {
      this.isNSF = true;
    }

    const clearDate: Date = this.billingData.paymentDetailsForm.get('clearDate').value;
    this.billingData.paymentDetailsForm.get('clearDate').setValue(clearDate ? { isRange: false, singleDate: { jsDate: new Date(clearDate) } } : null);
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  onVoid(): void {
    this.showActionConfirmation(this.PaymentDetailConstants.voidConfirmation, ReversalType.Void);
  }

  onNSF(): void {
    this.showActionConfirmation(this.PaymentDetailConstants.nsfConfirmation, ReversalType.NSF);
  }

  onStopPay(): void {
    this.showActionConfirmation(this.PaymentDetailConstants.stopPayConfirmation, ReversalType.StopPay);
  }

  onEscheat(): void {
    this.modalRef = this.paymentConfirmationModalRef = this.modalService.show(EscheatConfirmationModalComponent, {
      initialState: { message: this.PaymentDetailConstants.escheatConfirmation },
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.content.event.subscribe((result) => {
      if (result.data) {

        const refundEscheatDateDto: RefundSetEscheatDateDTO = result.data;
        refundEscheatDateDto.riskId = this.entityRiskData.getRiskId();
        refundEscheatDateDto.paymentId = this.paymentData.paymentId;

        Utils.blockUI();
        this.billingService.refundSetEscheatDate(refundEscheatDateDto).pipe(takeUntil(this.stop$)).subscribe(_ => {
          this.bsModalRef.hide();
          Utils.unblockUI();
          this.billingData.listPaymentsByRiskId(this.entityRiskData.getRiskId());
          NotifUtils.showSuccess(this.PaymentDetailConstants.escheatSuccess);
        }, err => {
          Utils.unblockUI();
          NotifUtils.showMultiLineError(err.error?.message);
        });
      }

      this.modalRef.hide();
    });
  }

  onTransferPayment(): void {
    this.modalRef = this.paymentConfirmationModalRef = this.modalService.show(TransferPaymentModalComponent, {
      initialState: { transferAmount: this.billingData.paymentDetailsForm.value.transferableAmount },
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.content.event.subscribe((result) => {
      if (result.data.continue) {

        Utils.blockUI();

        let destinationRiskId: string;

        this.policyService.searchRiskByFilter({ policyNumber: result.data.policyNumber, isEndorsement: false })
          .pipe(
            takeUntil(this.stop$),
            tap(risks => destinationRiskId = risks[0]?.id),
            switchMap((risks) => {
              if (risks.length > 0) {
                const isSiebelDataWithDuplicatePolicyNumber = risks.length > 1 && this.entityRiskData.getRiskIsSiebel();
                const request: TransferPaymentDTO = {
                  fromRiskId: this.entityRiskData.getRiskId(),
                  toRiskId: !isSiebelDataWithDuplicatePolicyNumber ? risks[0].id : result.data.selectedRiskId,
                  paymentId: this.paymentData.paymentId,
                  comments: result.data.comment,
                  amount: result?.data?.amount
                };
                return this.billingService.transferPayment(request);
              } else {
                return of(false);
              }
            }))
          .subscribe(res => {
            if (!res) {
              Utils.unblockUI();

              NotifUtils.showError(this.PaymentDetailConstants.transferPayment.invalidPolicyNumberError);
            } else {

              const copyRelatedDocDto: CopyPaymentRelatedDocDTO = {
                sourcePaymentId: this.paymentData.paymentId,
                destinationPaymentId: res.transferToPaymentId,
                destinationRiskId: destinationRiskId
              };

              forkJoin([this.billingService.copyPaymentRelatedDoc(copyRelatedDocDto).takeUntil(this.stop$), this.policyService.getPolicyNotes(this.entityRiskData?.getRiskId())])
                .subscribe(data => {
                  const response2 = data[1];

                  Utils.unblockUI();

                  this.billingData.populateBillingSections(this.entityRiskData.getRiskId());
                  this.policyNotesData.populateNotes(response2);
                  this.autoReinstatementData.populateAutoReinstateData();
                  NotifUtils.showSuccess(`${this.PaymentDetailConstants.transferPayment.success} ${this.currencyPipe.transform(Math.abs(res.amount))}`);
                  this.modalRef.hide();
                  this.bsModalRef.hide();
                },
                  err => {
                    Utils.unblockUI();
                    NotifUtils.showMultiLineError(err.error?.message);
                  });
            }
          },
            err => {
              Utils.unblockUI();
              NotifUtils.showMultiLineError(err.error?.message);
            });

      } else {
        this.modalRef.hide();
      }
    });
  }

  showActionConfirmation(msg: string, _actionType: string): void {

    let reverasalTypeLabel: string;
    switch (_actionType) {
      case ReversalType.Void:
        reverasalTypeLabel = this.PaymentDetailConstants.voidType;
        break;
      case ReversalType.NSF:
        reverasalTypeLabel = this.PaymentDetailConstants.nsfType;
        break;
      case ReversalType.StopPay:
        reverasalTypeLabel = this.PaymentDetailConstants.stopPayType;
        break;
    }

    this.modalRef = this.paymentConfirmationModalRef = this.modalService.show(PaymentConfirmationModalComponent, {
      initialState: { message: msg, actionTypeLabel: this.PaymentDetailConstants.save },
      class: 'modal-sm modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.content.event.subscribe((result) => {
      if (result.data.continue) {
        const request: ReversePaymentDTO = {
          riskId: this.entityRiskData.getRiskId(),
          paymentId: this.paymentData.paymentId,
          reversalTypeId: _actionType,
          comment: result.data.comment,
          amount: this.billingData.paymentDetailsForm.get('amount').value
        };

        Utils.blockUI();
        this.billingService.reversePayment(request).pipe(
          takeUntil(this.stop$),
          switchMap(() => forkJoin([
            this.policyService.getPolicyNotes(this.entityRiskData?.getRiskId()),
            this.policyService.getPolicyDocuments(this.entityRiskData?.getRiskId()),
            this.policyService.getUpdatedStatusOnBillingTransaction(this.entityRiskData?.getRiskId())
          ]))
        ).subscribe(policyResponse => {
          Utils.unblockUI();

          this.billingData.populateBillingSections(this.entityRiskData.getRiskId());
          this.billingData.listTransactionFeesByRiskId(this.entityRiskData.getRiskId());

          this.policyNotesData.populateNotes(policyResponse[0]);
          this.policyDocumentsData.mapPolicyDocuments(policyResponse[1]);
          this.updateRiskStatusOnBillingTransaction(policyResponse[2]);
          this.autoReinstatementData.populateAutoReinstateData();
          NotifUtils.showSuccess(`${reverasalTypeLabel} ${this.PaymentDetailConstants.successMessage}`);

          this.modalRef.hide();
          this.bsModalRef.hide();
        },
          err => {
            Utils.unblockUI();
            NotifUtils.showMultiLineError(err.error?.message);
          });
      } else {
        this.modalRef.hide();
      }
    });
  }

  public isInternalUser(userType: string): boolean {
    return userType.toLocaleLowerCase() === 'internal';
  }

  public clearDateChanged($data): void {
    const request: RefundSetClearDateDTO = {
      riskId: this.entityRiskData.getRiskId(),
      paymentId: this.paymentData.paymentId,
      // clearDate: $data.jsDate ? new Date(new Date($data.jsDate).toLocaleDateString('en-US') + 'UTC') : null
      clearDate: $data.jsDate ? moment($data.jsDate).format('YYYY-MM-DDT00:00:00.000') : null
    };

    Utils.blockUI();
    this.billingService.refundSetClearDate(request).pipe(takeUntil(this.stop$)).subscribe(_ => {
      this.billingData.listPaymentsByRiskId(this.entityRiskData.getRiskId());

      Utils.unblockUI();
    },
      err => {
        Utils.unblockUI();
        NotifUtils.showMultiLineError(err.error?.message);
      });
  }

  public isInternalMethod(): boolean {
    const internalCode: string = 'internal';
    return this.billingData.paymentDetailsForm.get('paymentMethod').value.toLocaleLowerCase() === internalCode;
  }

  protected updateRiskStatusOnBillingTransaction(data: StatusOnBillingTransaction): void {

    const riskStatusDescription = LvRiskStatus.find(a => a.code === data.riskStatus)?.description;
    this.summaryData.SummaryForm.get('quoteStatus').setValue(riskStatusDescription);
    this.submissionPageData.policyStatus.next(riskStatusDescription as RiskStatus);
    this.submissionPageData.quoteStatus.next(riskStatusDescription as RiskStatus);
    this.store.dispatch(updatePolicyStatusFromPolicyCancellationComponent({ status: riskStatusDescription as RiskStatus }));
    this.entityRiskData.EntityRisk.risks[0].riskStatusCode = data.riskStatus;
    this.entityRiskData.EntityRisk.risks[0].cancellationTypeCode = data.cancellationTypeCode;
    this.entityRiskData.EntityRisk.risks[0].approvedForReinstatementDate = data.approvedForReinstatementDate;
  }

  onRewnewalTransferPayment(): void {
    const payoffAmountAbsVal = Math.abs(this.billingData.summary.payoffAmount);
    const initialState = {
      payOffAmount: payoffAmountAbsVal,
      approvalAmountToBind: this.requiredAmountToBind,
      renewalRisk: this.renewalRisk
    };

    this.modalRef = this.paymentConfirmationModalRef = this.modalService.show(TransferRenewalPaymentModalComponent, {
      initialState,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.content.event.subscribe((result) => {
      if (result.data.continue) {
        this.billingData.getCommissionRate();
        this.summaryData.authService.isDoneCommissionRateChecking$.pipe(takeUntil(this.stop$)).subscribe(() => {
          if (!this.authService.isCommissionRateError) {
            Utils.blockUI();
            const request: TransferPaymentDTO = {
              fromRiskId: this.entityRiskData.getRiskId(),
              toRiskId: this.renewalRisk.id,
              paymentId: this.paymentData.paymentId,
              comments: result.data.comment,
              amount: result.data.amount,
              isManualRenewalTransfer: true,
              commissionRate:  Number(this.authService.commissionRateResponse.commissionRate)
            };
            this.billingService.manualRenwalTransfer(request).subscribe(() => {
              this.submissionListData.callRelatedSubmissionList(this.entityRiskData.getRiskId(), this.entityRiskData.getRiskDetailId(), this.isInternal);
              this.billingData.populateBillingSections(this.entityRiskData.getRiskId());
              Utils.unblockUI();
              NotifUtils.showSuccess(`Renewal transfer has been completed`, () => {
                this.modalRef.hide();
                this.bsModalRef.hide();
              });
            });
          } else {
            this.modalRef.hide();
            this.bsModalRef.hide();
          }
         });

      } else {
        this.modalRef.hide();
      }
    });
  }

  checkRwntApprovalForRenewalTransfer() {
    this.billingService.getRenewalRiskDetails(this.entityRiskData.getRiskId()).subscribe(res => {
      if (res) {
        Utils.blockUI();
        this.renewalRisk = res;

        if (this.renewalRisk?.statusProper === 'Renewal Not Taken') {
          const payload: RenewalReinstatementApprovalDetailsRequestDTO = {
            renewalRiskId: this.renewalRisk.id,
            expiringRiskId: this.entityRiskData.getRiskId(),
            paymentPlan: res.riskDetails[0].riskBinds[0].riskBindBilling.payPlanOption
          };

          this.billingService.getIsOtherRenewalRequirementsMet(payload).subscribe(res1 => {
            if (res1) {
              this.requiredAmountToBind = res1.requiredAmount;
              this.isApproved = res1.areRequirementsMetExceptPayment;
              Utils.unblockUI();
            }
          });
        } else {
          Utils.unblockUI();
        }
      }
    });
  }

  checkRenewalOfferForRenewalTransfer() {
    if (this.renewalRisk?.statusProper === 'Renewal Offered' || 'Revised Renewal Offered' || 'Revised Renewal Offer') {
      this.billingData.checkRenewalDetails(this.entityData.getRiskId(), this.entityData.getRiskDetailId(), false);
    }
  }

  get isValidForRenewalTransfer(): boolean {
    switch (this.renewalRisk?.statusProper) {
      case 'Renewal Not Taken':
        return this.isApproved && this.billingData.summary.payoffAmount < 0 && this.isInternal && Math.abs(this.billingData.summary.payoffAmount) >= this.requiredAmountToBind;
      case 'Renewal Offered':
      case 'Revised Renewal Offered':
      case 'Revised Renewal Offer':
        return Math.abs(this.billingData.summary.payoffAmount) >= this.billingData.renewalAmount && this.isInternal;
      default:
        return false;
        break;
    }
  }

  onTransferPaymentToSuspense(): void {
    const initialState = {
      postDate: this.billingData.paymentDetailsForm.get('postDate').value,
      postedAmount: this.billingData.paymentDetailsForm.get('amount').value,
      transferableAmount: this.billingData.paymentDetailsForm.get('transferableAmount').value,
      suspendedPaymentDetail: this.paymentData?.suspendedPaymentDetail,
      paymentMethod: this.paymentData.paymentMethod,
      referenceNum: this.paymentData.reference
    };

    this.modalRef = this.paymentConfirmationModalRef = this.modalService.show(TransferPaymentToSuspenseModalComponent, {
      initialState,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.content.event.subscribe((res) => {
      if (res.data.continue) {
        Utils.blockUI();
        const request: SuspendedPaymentRequestDTO = this.mapSuspendedPaymentPayload(res.data);

        this.billingService.postCreateSuspendedPayment(request).pipe(
          takeUntil(this.stop$),
          switchMap((suspendedPayment: SuspendedPaymentResponseDTO) => {
            this.createdSuspense = suspendedPayment;
            const transferToSuspenseRequest: TransferPaymentToSuspenseRequestDTO = {
              fromRiskId: this.entityRiskData.getRiskId(),
              suspendedPaymentId: suspendedPayment.id,
              amount: suspendedPayment.amount,
              paymentId: this.paymentData.paymentId,
              comment: suspendedPayment.comment,
              policyNumber: suspendedPayment.policyNumber
            };

            // Add Suspended Payment Related Doc
            if (this.relatedDocsComponent.paymentDocumentTableRows?.length > 0) {
              const paymentRelatedDocRequest: PaymentSuspendedDocDTO[] = [];

              this.relatedDocsComponent.paymentDocumentTableRows.forEach(doc => {
                paymentRelatedDocRequest.push({
                  suspendedPaymentId: this.createdSuspense.id,
                  fileName: doc.fileName,
                  filePath: doc.filePath,
                  description: doc.description,
                  isActive: doc.isActive,
                  createdBy: doc.createdBy
                });
              });

              // Copy Related Docs
              return this.billingService.copySuspendedPaymentDoc(paymentRelatedDocRequest)
                .pipe(
                  switchMap(() => {
                    // Create Negative Payment Amount
                    return this.billingService.transferPaymentToSuspense(transferToSuspenseRequest).map(x => x);
                  })
                );
            } else {
              // Create Negative Payment Amount
              return this.billingService.transferPaymentToSuspense(transferToSuspenseRequest).map(x => x);
            }
          })
        ).subscribe(response => {
          if (response) {
            Utils.unblockUI();
            this.billingData.populateBillingSections(this.entityRiskData.getRiskId());
            this.autoReinstatementData.populateAutoReinstateData();
            NotifUtils.showSuccess(`${this.PaymentDetailConstants.transferPayment.success} ${this.currencyPipe.transform(Math.abs(response.amount))} to Suspense`);
            this.modalRef.hide();
            this.bsModalRef.hide();
          }
        }, err => {
          const voidSuspenseRequest: VoidSuspendedPaymentDTO = {
            suspendedPaymentId: this.createdSuspense.id,
            comment: PolicyBillingLabelsConstants.transferPaymentToSuspense.failed
          };

          Utils.blockUI();
          this.billingService.voidSuspendedPayment(voidSuspenseRequest).subscribe(() => {
            NotifUtils.showError(`${PolicyBillingLabelsConstants.transferPaymentToSuspense.failed}. ${err.error.message}`);
            this.createdSuspense = null;
            Utils.unblockUI();
          }, _ => {
            this.createdSuspense = null;
            Utils.unblockUI();
            NotifUtils.showError(JSON.stringify(err.error.message));
          });
        });
      } else {
        this.modalRef.hide();
      }
    });
  }

  mapSuspendedPaymentPayload(details: SuspendedPaymentRequestDTO): SuspendedPaymentRequestDTO {
    const suspendedPaymentRequest: SuspendedPaymentRequestDTO = {
      receiptDate: details.receiptDate,
      suspendedDetail: details.suspendedDetail,
      policyNumber: details?.policyNumber,
      amount: details.amount,
      reasonId: details.reasonId,
      suspendedCheck: details.suspendedCheck,
      originalPolicyPaymentAmount: details.originalPolicyPaymentAmount,
      comment: details.comment,
      sourceId: details.sourceId,
      directBillCarrierId: this.suspendedPaymentData.getDirectBillCarrierIdByRiskStateCode(this.entityRiskData.getRisk().riskStateCode)
    };

    return suspendedPaymentRequest;
  }

  undoVoidOnPayment(msg: string): void {
    NotifUtils.showConfirmMessage(msg, () => {
      const request: UndoPaymentNsfRequestDTO = {
        paymentId: this.paymentData.paymentId,
        riskId: this.entityData.getRiskId()
      };

      let undoPaymentNsfResponse: UndoPaymentNsfProcessResponseDTO;

      Utils.blockUI();
      this.billingService.putUndoPaymentNsf(request).pipe(
        takeUntil(this.stop$),
        tap(response => undoPaymentNsfResponse = response),
        switchMap(() => forkJoin([
          this.policyService.getPolicyNotes(this.entityRiskData?.getRiskId()),
          this.policyService.getPolicyDocuments(this.entityRiskData?.getRiskId()),
          this.policyService.getUpdatedStatusOnBillingTransaction(this.entityRiskData?.getRiskId())
        ]))
      ).subscribe(policyResponse => {
        Utils.unblockUI();
        if (undoPaymentNsfResponse.isReinstated) {
          NotifUtils.showSuccess(undoPaymentNsfResponse.responseMessage,
            () => {
              this.policyCancellationData.reinstatePolicyCallback(undoPaymentNsfResponse.reinstatementResult);
              this.bsModalRef?.hide();
            });
        } else {
          NotifUtils.showSuccess(undoPaymentNsfResponse.responseMessage,
            () => {
              this.populateNeededDataOnUndoPaymentNsfSuccess(policyResponse);
              this.bsModalRef?.hide();
            });
        }
      },
      err => {
        Utils.unblockUI();
        NotifUtils.showMultiLineError(err.error?.message);
      });
    });
  }

  populateNeededDataOnUndoPaymentNsfSuccess(policyResponse: any[]) {
    this.billingData.populateBillingSections(this.entityRiskData.getRiskId());
    this.billingData.listTransactionFeesByRiskId(this.entityRiskData.getRiskId());

    this.policyNotesData.populateNotes(policyResponse[0]);
    this.policyDocumentsData.mapPolicyDocuments(policyResponse[1]);
    this.updateRiskStatusOnBillingTransaction(policyResponse[2]);
    this.autoReinstatementData.populateAutoReinstateData();
  }
}
