import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements OnInit {
  group: FormGroup;
  field = { label: '', required: false, name: '', placeholder: ''};
  constructor() { }

  ngOnInit() {
  }

}
