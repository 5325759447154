
import { ResponseObjectMoratorium } from "app/shared/models/submission/quick-quote/moratoriums.model";

export interface MoratoriumState {
  isLoading: boolean | null;
  list: ResponseObjectMoratorium[] | null;
}
export const initialState = {
  isLoading: null,
  list: null
}