import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuard } from './guards/auth.guard';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { RoleGuard } from './guards/role.guard';
import { ErrorResponseInterceptor } from './interceptors/error-response-interceptor';
import { RaterPremiumService } from './services/submission/rater-premium/rater-premium.service';
import { LocalStorageService } from './services/local-storage.service';
import { RaterPremiumResolverService } from './services/submission/rater-premium/rater-premium-resolver.service';
import { ValidationService } from './services/validation.service';
import { HORaterRequestData } from './services/submission/rater-premium/ho-rater-request-data.service';
import { DP3RaterRequestData } from './services/submission/rater-premium/dp3rater-request-data.service';
import { CalculateRaterData } from './services/submission/rater-premium/calculate-rater-data.service';
import { ManagementGuard } from './guards/management.guard';
import { QuoteGuard } from './guards/new-quote.guard';
import { NightlyJobGuard } from './guards/nightlyjob.guard';
import { ClientDetailService } from './services/client-details/client-detail.service';
import { ReportsGuard } from 'app/modules/reports/reports-navigation-guard.service';
import { MakeAPaymentGuard } from './guards/makeapayment.guard';
import { BookTransferGuard } from './guards/book-transfer.guard';
import { RefundApprovalGuard } from './guards/refund-approval.guard';
import { RaterMigrationGuard } from './guards/rater-migration.guard';
import { AccountService } from './services/account.service';
import { LoginGuard } from './guards/login.guard';
import { MaintenanceModeGuard } from './guards/maintenace-mode.guard';
import { RateLimitingResponseInterceptor } from './interceptors/rate-limiting-response-interceptor';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthService,
    CookieService,
    AuthGuard,
    RoleGuard,
    LocalStorageService,
    RaterPremiumResolverService,
    RaterPremiumService,
    HORaterRequestData,
    DP3RaterRequestData,
    CalculateRaterData,
    ValidationService,
    ManagementGuard,
    QuoteGuard,
    NightlyJobGuard,
    ClientDetailService,
    ReportsGuard,
    MakeAPaymentGuard,
    ClientDetailService,
    BookTransferGuard,
    RefundApprovalGuard,
    RaterMigrationGuard,
    AccountService,
    LoginGuard,
    MaintenanceModeGuard
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class CoreModule { }
