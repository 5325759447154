import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subscription, timer } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { NavigationService } from '../../../../core/services/navigation/navigation.service';
import { ValidationService } from '../../../../core/services/validation.service';
import { ReferenceCodeConstants } from '../../../../shared/constants/login.labels.constants';
import { TimerPipe } from '../../../../shared/custom pipes/timer-pipe';
import { UserTwoFactorAuthResponse } from '../../../../shared/models/user-validation/user-two-factor-auth.response';
import Utils from '../../../../shared/utilities/utils';
import { ComparativeRaterData } from '../../comparative-rater.data';
import { AuthService } from '../../../../core/services/auth.service';

@Component({
  selector: 'app-comparative-rater-reference-code-modal',
  templateUrl: './comparative-rater-reference-code-modal.component.html',
  styleUrls: ['./comparative-rater-reference-code-modal.component.scss']
})
export class ComparativeRaterReferenceCodeModalComponent implements OnInit, OnDestroy {

  title: string;
  description1: string;
  description2: string;

  referenceNumber: string;
  username: string;
  emailAddress: string;

  errorMessage: string;
  successMessage: string;
  isValid: boolean = true;

  inputtedCode: string;

  public ReferenceCodeConstants = ReferenceCodeConstants;

  isResendCodeClicked: boolean = false;

  // Resend Code Timer
  countDown: Subscription;
  counter: number = ReferenceCodeConstants.countdown;
  tick: number = ReferenceCodeConstants.tick;

  constructor(private bsModalRef: BsModalRef
              , private validationService: ValidationService
              , private navService: NavigationService
              , private timerPipe: TimerPipe
              , private comparativeRaterData: ComparativeRaterData
              , private authService: AuthService) { }

  ngOnInit() {
    const auth = localStorage.getItem(environment.AuthKey);
    const authResponse = this.authService.getTokenResponse();

    if (auth || authResponse.token) { // to do expiration token if available

      if(auth){
        const userInfo: string = atob(JSON.stringify(auth).split('.')[1]);
        this.emailAddress = JSON.parse(userInfo).email;
      }else{
        const userInfo: string = atob(JSON.stringify(authResponse.token).split('.')[1]);
        this.emailAddress = JSON.parse(userInfo).email;
      }

    }
  }

  cancel(): void {
    this.bsModalRef.hide();
    this.comparativeRaterData.showLoginField = true;
    this.comparativeRaterData.loginForm.get('username').setValue(this.username);
    this.comparativeRaterData.resetLoginCredentials(false);
  }

   // this called every time when user changed the code
  onCodeChanged(code: string): void {
    this.inputtedCode = code;
  }

  // this called only if user entered full code
  onCodeCompleted(code?: string): void {
    this.inputtedCode = code;
  }

  onContinue(): void {
    this.processTwoAuthFactor();
  }

  onResendCode(): void {
    this.isResendCodeClicked = true;
    this.counter = this.ReferenceCodeConstants.countdown;
    this.tick = this.ReferenceCodeConstants.tick;
    Utils.blockUI();
    this.validationService.getUserTwoFactorAuth(this.username).subscribe(result => {
      this.isResendCodeClicked = true;
      this.referenceNumber = result;
      this.isValid = true;
      this.successMessage = this.ReferenceCodeConstants.CodeSuccessSent;
      this.resendCodeTimer();
      Utils.unblockUI();

    }, err => {
      console.log(err);
      Utils.unblockUI();
    });
  }

  resendCodeTimer(): void {
    if (this.countDown) {
      this.countDown.unsubscribe();
    }
    this.countDown = timer(0, this.tick).subscribe(() => {
      --this.counter;
      this.counter = +this.timerPipe.transform(this.counter);
      if (+this.counter === 0) {
        this.isResendCodeClicked = false;
        this.countDown.unsubscribe();
      }
    });
  }

  processTwoAuthFactor(): void {
    if (this.inputtedCode && this.inputtedCode !== '') {
      const payload = {
        userName: this.username,
        referenceCode: this.inputtedCode,
        referenceNumber: this.referenceNumber,
        isActive: true,
        appCode: environment.ApplicationId
      };

      this.validationService.postUserTwoFactorAuth(payload).subscribe((result: UserTwoFactorAuthResponse) => {
      if (result.isValid) {
        this.authService.setAuthData();
        this.isValid = result.isValid;
        this.errorMessage = result.error;

        this.navService.initializeMenus().subscribe(() => {
          Utils.unblockUI();
          this.bsModalRef.hide();
          this.comparativeRaterData.getComparativeRate(this.comparativeRaterData.transactionId, this.username);
        }, err => {
          Utils.unblockUI();
        });
      } else {
        this.isValid = result.isValid;
        this.errorMessage = this.ReferenceCodeConstants.invalidCode;
      }
        },
          err => {
            this.isValid = err?.error?.isValid;
            this.errorMessage = this.ReferenceCodeConstants.invalidCode;
        });
    } else {
      this.isValid = false;
      this.errorMessage = this.ReferenceCodeConstants.CodeisEmpty;
    }
  }

  ngOnDestroy() {
    if (this.countDown) {
      this.countDown.unsubscribe();
    }
  }
}
