import { ResponseObjectMoratorium } from 'app/shared/models/submission/quick-quote/moratoriums.model';
import { createAction, props } from '@ngrx/store';

export const updateMoratoriumIsLoadingFromZipCodeData = createAction(
  '[ZipCodeData] Update Moratorium Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateMoratoriumListFromZipCodeData = createAction(
  '[ZipCodeData] Update Moratorium List',
  props<{ list: ResponseObjectMoratorium[] }>()
);

export const updateMoratoriumIsLoadingFromPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update Moratorium Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateMoratoriumListFromPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update Moratorium List',
  props<{ list: ResponseObjectMoratorium[] }>()
);

export const updateMoratoriumIsLoadingFromSubmissionManagementComponent = createAction(
  '[SubmissionManagementComponent] Update Moratorium Is Loading',
  props<{ isLoading: boolean }>()
);