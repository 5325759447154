import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../common.service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { AuditLogDTO } from '../../../shared/models/management/auditLog.dto';

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {

  constructor(public http: HttpClient,
    private commonService: CommonService) { }

  insertToAuditLog(payload: AuditLogDTO): Observable<AuditLogDTO> {
    return this.http.post(`${environment.ApiUrl}/AuditLog`, payload)
      .catch(this.commonService.handleObservableHttpError);
  }

  failedLoginAuditLog(payload: AuditLogDTO): Observable<AuditLogDTO> {
    return this.http.post(`${environment.ApiUrl}/AuditLog/failed-login`, payload)
      .catch(this.commonService.handleObservableHttpError);
  }
}
