import { ValidationListModel } from '../../shared/models/validation-list.model';
import { PathConstants } from '../../shared/constants/path.constants';
import { IPolicyNavDetails, NavData } from '../../_nav';
import { GenericConstants, UserType } from '../../shared/constants/generic.constants';
import { RiskStatusConstants } from 'app/shared/constants/risk-status';

const setBadge = (validation?) => {
  let badge = {
    text: null,
    variant: null
  };
  if(validation !== undefined) {
    if(!validation) {
      badge = {
        text: '!',
        variant: 'danger'
      };
        return badge;
    } else {
        return badge;
    }
  } else {
    return badge;
  }
};

export const createPolicyDetailsMenuItems = (navDetails: IPolicyNavDetails ): NavData[] => {
  const {
    riskId,
    riskDetailId,
    storage,
    validation,
    quoteStatus,
    hasAir,
    hasDecline,
    allUwrApproved,
    uwrListEmpty,
    hasUwrChanges,
    hasPostBindUwrItem,
    uwrListAllPostBind,
    postBindUwrItemsApproved,
    nonPostBindUwrItemsChecked,
    nonPostBindUwrItemsApproved,
    uwApprovalListAddedAllPostBind,
    isEditPolicy,
    isSubmissionReferApprovedAirDeclined
  } = navDetails;

  const userType: UserType = localStorage.getItem('userType') as UserType;
  let paramId = '/new';
  let raterMenuItem = [];
  // If id has value, concat id to submission details url path.
  if (riskId != null && riskDetailId != null) {
    paramId = `/${riskId}/${riskDetailId}`;
  }

  // Url path of submission details. '/submission/details'
  const path = '/' + PathConstants.Policy.Index + paramId;

  const showErrorBadgeForExternalUser: boolean = (
    userType === GenericConstants.userType.external &&
    quoteStatus === RiskStatusConstants.pending &&
    isEditPolicy &&
    !uwrListEmpty &&
    !uwrListAllPostBind
  );

  let submissionMenu = [
    {
      name: 'SUMMARY',
      icon: 'fa fa-list',
      url: `${path}/${PathConstants.Policy.Policies.Summary}`
    },
    {
      name: 'HISTORY',
      icon: 'fa fa-history',
      url: `${path}/${PathConstants.Policy.Policies.History}`
    },
    {
      name: 'BILLING',
      icon: 'fa fa-calculator',
      url: `${path}/${PathConstants.Policy.Policies.Billing}`,
    },
    {
      name: 'DOCUMENTS',
      icon: 'icon-docs',
      url: `${path}/${PathConstants.Policy.Policies.Documents}`
    },
    {
      name: 'COVERAGES',
      icon: 'icon-calculator',
      badge: setBadge(validation?.coverages),
      url: `${path}/${PathConstants.Policy.Policies.CoveragesAndDeduction}`
    },
    {
      name: 'ENDORSEMENTS',
      icon: 'icon-target',
      badge: setBadge(validation?.endorsements),
      url: `${path}/${PathConstants.Policy.Policies.Endorsements}`
    },
    {
      name: 'CLAIMS',
      icon: 'icon-notebook',
      url: `${path}/${PathConstants.Policy.Policies.Claims}`
    },
    {
      name: 'PROPERTY',
      icon: 'icon-info',
      badge: setBadge(validation?.property),
      url: `${path}/${PathConstants.Policy.Policies.Property}`
    },
    {
      name: 'APPLICANT',
      icon: 'icon-user',
      badge: setBadge(validation?.applicant),
      url: `${path}/${PathConstants.Policy.Policies.Applicant}`
    },
    {
      name: 'UW QUESTIONS',
      icon: 'icon-question',
      url: `${path}/${PathConstants.Policy.Policies.UWQuestions}`
    },
    {
      name: 'INTERESTS',
      icon: 'icon-support',
      badge: setBadge(validation?.interest),
      url: `${path}/${PathConstants.Policy.Policies.Interests}`
    },
    {
      name: 'UW APPROVAL',
      icon: 'icon-docs',
      badge: userType === 'internal' || showErrorBadgeForExternalUser ? setBadge(validation?.uwApproval) : {},
      url: `${path}/${PathConstants.Policy.Policies.UWApproval}`,
    },
    {
      name: 'ISSUE',
      icon: 'fa fa-calendar-check-o',
      url: `${path}/${PathConstants.Policy.Policies.Issue}`,
    },
    {
      name: 'NOTES',
      icon: 'fa fa-sticky-note-o',
      url: `${path}/${PathConstants.Policy.Policies.Notes}`,
    },
    {
      name: 'TASKS',
      icon: 'fa fa-tasks',
      url: `${path}/${PathConstants.Policy.Policies.Tasks}`,
    },
  ];

  let newsubmissionMenu =[];
    if(
      isSubmissionReferApprovedAirDeclined
      ){
      submissionMenu.find(res=> {
        if(res.name !='UW QUESTIONS'){
          newsubmissionMenu.push(res)
        }
        submissionMenu = newsubmissionMenu;
      })
    }else{
      submissionMenu.find(res=> {
        newsubmissionMenu.push(res)
      })
      submissionMenu = newsubmissionMenu;
    }

  switch (userType) {
    case 'internal':
      raterMenuItem = [{
        name: 'RATER MATRIX',
        icon: 'icon-plus',
        url: `${path}/${PathConstants.Policy.Policies.RaterPremiumTable}`
      }];
      break;
  }

  return [...submissionMenu, ...raterMenuItem];
};