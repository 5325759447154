import { Component, Input, OnInit } from '@angular/core';
import { DocumentsLabelConstants } from '../../../../../shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../../../shared/base-class';
import { QuickQuoteService } from '../../../../../../app/core/services/submission/quick-quote.service';
import { PolicyBillingData } from '../../../../../../app/modules/policy-management/data/policy-billing.data';
import { PolicyBillingLabelsConstants } from '../../../../../../app/shared/constants/policy-billing.labels.constants';
import { BillingService } from '../../../../../../app/core/services/billing/billing.service';
import { SuspendedPaymentsLabel } from '../../../../../../app/shared/constants/suspended-payments.labels.constants';
import { DocumentUploadModalComponent } from '../../../../../../app/modules/policy-management/policy-billing/payments/related-documents/document-upload-modal/document-upload-modal.component';
import Utils from '../../../../../../app/shared/utilities/utils';
import NotifUtils from '../../../../../../app/shared/utilities/notif-utils';
import { PaymentRelatedDocDTO } from '../../../../../../app/shared/models/data/dto/billing/payment-related-doc.dto';
import { AuthService } from '../../../../../../app/core/services/auth.service';
import { EntityRiskData } from '../../../../../../app/modules/submission-management/data/entity-risk.data';
import { SuspendedPaymentsData } from '../../../../../modules/payments/data/suspended-payments.data';
import { PolicyService } from '../../../../../../app/core/services/submission/policy.service';
import { UserService } from 'app/core/services/management/user.service';
import { SuspendedPaymentRelatedDocComponent } from 'app/modules/payments/suspended-payments/shared/suspended-payment-related-doc/suspended-payment-related-doc.component';
import { PaymentSuspendedDocDTO } from 'app/shared/models/data/dto/billing/payment-suspended-doc.dto';

@Component({
  selector: 'app-related-documents',
  templateUrl: './related-documents.component.html',
  styleUrls: ['./related-documents.component.scss']
})
export class RelatedDocumentsComponent extends BaseClass implements OnInit {

  public SuspendedPaymentsLabel = SuspendedPaymentsLabel;
  public ErrorMessageConstant = ErrorMessageConstant;

  @Input() paymentId: string;
  @Input() isSuspended: boolean = false;

  public paymentDocumentTableRows = [];

  PolicyBillingLabelsConstants = PolicyBillingLabelsConstants;
  DocumentLabelConstants = DocumentsLabelConstants;

  GenericLabel = GenericLabel;
  isOpen: boolean = true;
  modalRef: BsModalRef | null;

  isInternal: boolean;

  constructor(public policyBillingData: PolicyBillingData,
    protected modalService: BsModalService, public quickQuoteService: QuickQuoteService,
    protected billingService: BillingService,
    protected authService: AuthService,
    protected entityRiskData: EntityRiskData,
    protected suspendedData: SuspendedPaymentsData,
    protected policyService: PolicyService,
    protected userService: UserService
    ) {
    super();
  }

  ngOnInit() {
    this.loadTableItems();

    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.isInternal = this.isInternalUser(userType);
    });
  }

  loadTableItems(): void {
    Utils.blockUI();
    this.billingService.listPaymentRelatedDoc(this.paymentId).pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res) {
        this.paymentDocumentTableRows = res;
      }
      this.billingService.getPaymentDetails( this.entityRiskData.getRiskId(), this.paymentId).pipe(takeUntil(this.stop$)).subscribe(res1 => {
        if (res1) {
          if (res1.suspendedPaymentDetail?.invoiceImgLink) {
            this.paymentDocumentTableRows.push({description: 'Invoice Image',filePath: res1.suspendedPaymentDetail.invoiceImgLink, imgUploadDate: res1.suspendedPaymentDetail.imgUploadDate });
          }

          if (res1.suspendedPaymentDetail?.envelopeImgLink) {
            this.paymentDocumentTableRows.push({description: 'Envelope Image',filePath: res1.suspendedPaymentDetail.envelopeImgLink, imgUploadDate: res1.suspendedPaymentDetail.imgUploadDate });
          }

          if (res1.suspendedPaymentDetail?.checkImgLink) {
            this.paymentDocumentTableRows.push({description: 'Check Image',filePath: res1.suspendedPaymentDetail.checkImgLink, imgUploadDate: res1.suspendedPaymentDetail.imgUploadDate });
          }

          if (res1?.suspendedPaymentDetail) {
            this.billingService.listSuspendedPaymentDoc(res1.suspendedPaymentDetail?.id).pipe(takeUntil(this.stop$)).subscribe(res2 => {
              if (res2) {
                res2.forEach(data => {
                  this.paymentDocumentTableRows.push(data);
                });
              }
            });
          }

          if (res1.suspendedPaymentDetail?.suspendedMiscImage?.length > 0) {
            for (const miscImg of res1.suspendedPaymentDetail.suspendedMiscImage) {
              this.paymentDocumentTableRows.push({description: miscImg.imgDesc,filePath: miscImg.imgLink, imgUploadDate: res1.suspendedPaymentDetail.imgUploadDate });
            }
          }
        }
      });
      Utils.unblockUI();
    });
  }

  onUploadClick(): void {
    const initialState = {
      title: this.DocumentLabelConstants.addTitle,
      hideCategoryField: true,
    };
    this.modalRef = this.modalService.show(DocumentUploadModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.content.event.pipe(takeUntil(this.stop$)).subscribe((res) => {
      const request: PaymentRelatedDocDTO = {
        paymentId: this.paymentId,
        riskId: this.entityRiskData.getRiskId(),
        fileName: res.data.documentFile.name,
        filePath: res.data.documentFile.name,
        description: res.data.description,
        isActive: true,
        file: res.data.documentFile
      };

      Utils.blockUI();
      this.billingService.addPaymentRelatedDoc(request).pipe(takeUntil(this.stop$)).subscribe(_ => {
        Utils.unblockUI();
        this.loadTableItems();
      },
        err => {
          Utils.unblockUI();
          NotifUtils.showMultiLineError(err.error);
        }
      );
    });
  }

  onViewClick(filePath: string, fileName: string): void {
    this.policyService.generateSASURL(Utils.URLEncoder(filePath, fileName)).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
      window.open(resultSASURL, '_blank');
    });
  }

  onDeleteClick(item: PaymentSuspendedDocDTO): void {
    NotifUtils.showConfirmMessage(this.SuspendedPaymentsLabel.relatedDocuments.deleteConfirmation, () => {
      Utils.blockUI();

      if(this.isSuspended && item.suspendedPaymentId){
        this.billingService.deleteSuspendedPaymentDoc(item.id).pipe(takeUntil(this.stop$)).subscribe(_ => {
          Utils.unblockUI();
          this.loadTableItems();
        },
          err => {
            Utils.unblockUI();
            NotifUtils.showMultiLineError(err.error?.message);
          }
        );
      } else {
        this.billingService.deletePaymentRelatedDoc(item.id).pipe(takeUntil(this.stop$)).subscribe(_ => {
          Utils.unblockUI();
          this.loadTableItems();
        },
          err => {
            Utils.unblockUI();
            NotifUtils.showMultiLineError(err.error?.message);
          }
        );
      }
    });
  }

  public isInternalUser(userType: string): boolean {
    return userType.toLocaleLowerCase() === 'internal';
  }
}
