/* eslint-disable curly */
import { Injectable } from '@angular/core';
import { BaseClass } from '../../../shared/base-class';
import { EntityRiskData } from './entity-risk.data';
import Utils from '../../../shared/utilities/utils';
import { RenewalService } from '../../../core/services/submission/renewal/renewal.service';
import { takeUntil } from 'rxjs/operators';
import { RenewalStatusCode } from '../../../shared/models/data/dto/submission-list/entity-submission-list.dto';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { PolicySummaryData } from '../../policy-management/data/policy-summary.data';

@Injectable({
  providedIn: 'root',
})
export class RenewalTermData extends BaseClass {
  constructor(
    public entityRiskData: EntityRiskData,
    public renewalService: RenewalService,
    public policySummaryData: PolicySummaryData
  ) {
    super();
  }

  isForRescindConvertedRenewalProcess(
    isPending: boolean,
    isOffered: boolean,
    isRescinded: boolean
  ): boolean {
    return (
      this.isForConvertedRenewalTerm() &&
      (isPending || isOffered || isRescinded)
    );
  }

  isForConvertedRenewalTerm(): boolean {
    return false; //TODO: Insert indicator/flagging for converted renewal term, related to https://rivpartners.atlassian.net/browse/CNTLA-2201
  }

  rescindConvertedRenewal(): void {
    //TODO: Uncomment for LA-rollover, related to https://rivpartners.atlassian.net/browse/CNTLA-2201
    // Utils.blockUI();
    // this.renewalService
    //   .putRescindRenewal(this.entityRiskData?.getRiskId())
    //   .pipe(takeUntil(this.stop$))
    //   .subscribe(
    //     (renewalStatusCode: RenewalStatusCode) => {
    //       this.setStatusForDisplay(renewalStatusCode);
    //       Utils.unblockUI();
    //     },
    //     (error) => {
    //       Utils.unblockUI();
    //       NotifUtils.showError(error.message);
    //     }
    //   );
  }

  reverseRescindConvertedRenewal(): void {
    //TODO: Uncomment for LA-rollover, related to https://rivpartners.atlassian.net/browse/CNTLA-2201
    // Utils.blockUI();
    // this.renewalService
    //   .putReverseRescindRenewal(this.entityRiskData?.getRiskId())
    //   .pipe(takeUntil(this.stop$))
    //   .subscribe(
    //     (renewalStatusCode: RenewalStatusCode) => {
    //       this.setStatusForDisplay(renewalStatusCode);
    //       Utils.unblockUI();
    //     },
    //     (error) => {
    //       Utils.unblockUI();
    //       NotifUtils.showError(error.message);
    //     }
    //   );
  }

  private setStatusForDisplay(renewalStatus: RenewalStatusCode): void {
    this.policySummaryData.renewalStatusCode = renewalStatus;
    this.policySummaryData.renewalCode = renewalStatus;
  }
}
