import { ValidationListModel } from 'app/shared/models/validation-list.model';
import { PathConstants } from 'app/shared/constants/path.constants';
import { NavData } from 'app/_nav';

export const createSuspendedPaymentsMenuItems = (id?: string, storage?, validation?: ValidationListModel): NavData[] => {

  const path = `/${PathConstants.Payments.Index}/${PathConstants.Payments.SuspendedPayments.Index}`;

  const suspendedPaymentsMenu = [
    {
      name: 'PENDING',
      icon: 'fa fa-clock-o',
      url: `${path}/${PathConstants.Payments.SuspendedPayments.Pending}`
    },
    {
      name: 'RETURNED',
      icon: 'fa fa-undo',
      url: `${path}/${PathConstants.Payments.SuspendedPayments.Returned}`
    },
    {
      name: 'SENT TO IPX',
      icon: 'fa fa-paper-plane-o',
      url: `${path}/${PathConstants.Payments.SuspendedPayments.SentToIpx}`
    }
  ];

  return suspendedPaymentsMenu;
};


