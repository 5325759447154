import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ThirdPartyData } from 'app/modules/submission-management/data/third-party.data';
import { BaseClass } from 'app/shared/base-class';
import { CommonService } from 'app/core/services/common.service';
import { ThirdPartyDataService } from '../third-party-data.service';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HazardYearRangeChangedService extends BaseClass {
  constructor(public http: HttpClient,
    protected commonService: CommonService,
    protected thirdPartyData: ThirdPartyData,
    protected thirdPartyDataService: ThirdPartyDataService,
    protected toastr: ToastrService) {
    super();
  }

  checkIfYearRangeChanged(): Observable<void> {
    if (this.thirdPartyData.isNoHitIso360ValueFirstCall &&
      this.thirdPartyData.checkIfYearBuiltRangeChanged()) {
      return this.thirdPartyDataService.hazardRequest(this.thirdPartyData.createAirHazardRequest())
        .map(result => {
          this.thirdPartyData.mapAirHazardToSubmission(result);
          this.toastr.warning(`Year built range for Air Hazard was changed. Hazard result was retrieved. There might be changes on the following fields:
            <ul><li>Terrain</li><li>Wind Speed</li><li>Wind Design Speed</li><li>Wind Borne Debris Region</li></ul>`, null, { enableHtml: true });
        });
    } else {
      return of(null);
    }
  }
}