import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { PaymentMethod } from '../../../../../app/shared/enum/payment-method.enum';
import { CreditCardTypeConstants, PaymentPlanListConstants } from '../../../../../app/shared/constants/bind-and-issue.labels.constants';
import { GeneralValidationService } from '../../../../core/services/submission/validations/general-validation.service';
import { Pages } from '../../../../shared/constants/pages-and-sections.constants';
import { CustomValidators } from '../../../../shared/validators/custom.validator';
import { BindAndIssueData } from '../../data/bind-and-issue.data';
import { BindAndIssueValidators } from '../../validators/bind-and-issue-validator';
import { CreditCardType } from '../../../../../app/shared/enum/credit-card-type-enum';
import { RegexConstants } from '../../../../../app/shared/constants/regex.constants';
import { AuthService } from '../../../../core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BindAndIssueService {

  constructor(protected bindAndIssueData: BindAndIssueData, protected generalValidationService: GeneralValidationService, protected auth: AuthService) { }
  // #region =====> Bind and Issue Page
  billingSection: boolean;
  billingSectionInvalidControls = [];
  paperlessSection: boolean;
  paperlessSectionInvalidControls = [];
  signatureSection: boolean;
  signatureSectionInvalidControls = [];
  isConfirmedEmailAddress = false;

  get BindAndIssueBillingForm() {
    return this.bindAndIssueData.billingForm;
  }

  get BindAndIssuePaperlessForm() {
    return this.bindAndIssueData.paperlessDocumentForm;
  }

  get BindAndIssueSignatureForm() {
    return this.bindAndIssueData.signatureForm;
  }
  // #endregion

  checkBindAndIssuePage(): void {
    this.billingSection = this.checkBillingSection();
    this.paperlessSection = this.checkPaperlessSection();
    this.signatureSection = this.checkSignatureSection();

    this.billingSectionInvalidControls = this.generalValidationService.findInvalidControls(this.BindAndIssueBillingForm);
    this.paperlessSectionInvalidControls = this.generalValidationService.findInvalidControls(this.BindAndIssuePaperlessForm);
    this.signatureSectionInvalidControls = this.generalValidationService.findInvalidControls(this.BindAndIssueSignatureForm);

    const isEnrolledInPaperless = this.BindAndIssuePaperlessForm.get('enrollInPaperless').value ?? false;
    if (isEnrolledInPaperless && !this.isConfirmedEmailAddress && this.paperlessSectionInvalidControls.length === 0) {
      this.paperlessSectionInvalidControls.push('paperlessConfirmEmailAddress');
    }

    this.generalValidationService.mapToInvalidFormControlList(this.billingSectionInvalidControls, Pages.BindAndIssue.pageName, Pages.BindAndIssue.sections.Billing);
    this.generalValidationService.mapToInvalidFormControlList(this.paperlessSectionInvalidControls, Pages.BindAndIssue.pageName, Pages.BindAndIssue.sections.PaperlessDocuments);
    this.generalValidationService.mapToInvalidFormControlList(this.signatureSectionInvalidControls, Pages.BindAndIssue.pageName, Pages.BindAndIssue.sections.Signature);

  }

  // #region ==========> Bind and Issue Page Validations

  checkBillingSection(): boolean {
    const creditPaymentMethod = [PaymentMethod.CreditCard, PaymentMethod.RecurringCreditCard];
    const oneTimeAuthPayment = [PaymentMethod.CreditCard, PaymentMethod.EFT, PaymentMethod.ECheck];

    const isCreditPayment = creditPaymentMethod.includes(this.bindAndIssueData.billingForm.get('paymentMethod')?.value) ? true : false;
    const isFullPay = this.bindAndIssueData.billingForm.get('paymentPlan').value === PaymentPlanListConstants.fullPayCode;
    const isCardAMEX = this.bindAndIssueData.billingForm.get('ccType').value === CreditCardTypeConstants.AMEX;
    const creditCardType = this.bindAndIssueData.billingForm.get('ccType').value;
    const isRecurringCreditCard = this.bindAndIssueData.billingForm.get('paymentMethod')?.value === PaymentMethod.RecurringCreditCard;
    const isRecurringECheck = this.bindAndIssueData.billingForm.get('paymentMethod')?.value === PaymentMethod.RecurringECheck;
    const isRecurringPayment = isRecurringCreditCard || isRecurringECheck;
    const isOneTimeAuthPayment = oneTimeAuthPayment.includes(this.bindAndIssueData.billingForm.get('paymentMethod')?.value) ? true : false;
    const isPaymentPlanMortgagee = this.bindAndIssueData.billingForm.get('paymentPlan').value === PaymentPlanListConstants.mortgageePayCode;

    if (!isPaymentPlanMortgagee) {
      this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'paymentMethod', [Validators.required]);
      this.checkPaymentMethod();
    }

    this.checkPaymentPlanMortgagee(isPaymentPlanMortgagee);
    this.checkFullPay(isFullPay);
    this.checkCreditPayment(isCreditPayment, isCardAMEX, creditCardType);
    this.checkRecurringPayment(isRecurringPayment);
    this.checkOneTimeAuthPayment(isOneTimeAuthPayment);

    return this.BindAndIssueBillingForm.valid;
  }

  checkPaymentPlanMortgagee(isPaymentPlanMortgagee: boolean): boolean {
    if (isPaymentPlanMortgagee) {
      this.clearBillingFormValidators();
      this.generalValidationService.clearValidatorFormControl(this.bindAndIssueData.billingForm, 'paymentMethod');
      this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'downpayment');
      this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'bankAccountTotalChargeAmt', [Validators.required]);

      return true;
    }

    return false;
  }

  checkPaymentMethod(): void {
    switch (this.bindAndIssueData.billingForm.get('paymentMethod')?.value) {
      case PaymentMethod.CreditCard:
      case PaymentMethod.RecurringCreditCard:
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'firstName', [Validators.required, CustomValidators.spaceValidator, Validators.maxLength(32)]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'lastName', [Validators.required, CustomValidators.spaceValidator, Validators.maxLength(31)]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'address', [Validators.required, CustomValidators.spaceValidator]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'zipCode', [Validators.required, CustomValidators.spaceValidator]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'city', [Validators.required]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'state', [Validators.required]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'ccType', [Validators.required]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'ccExpirationMonth', [Validators.required, BindAndIssueValidators.checkCreditCardIsExpired(this.auth.getCustomDate())]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'ccExpirationYear', [Validators.required, BindAndIssueValidators.checkCreditCardIsExpired(this.auth.getCustomDate())]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'totalChargeAmt');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'accountType');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'routingNumber');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'accountNumber');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'nameOnAccount');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'bankAccountTotalChargeAmt');
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'agreeCreditCardFee', [Validators.requiredTrue]);
        break;
      case PaymentMethod.CashCheck:
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'accountType');
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'routingNumber');
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'accountNumber');
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'bankAccountTotalChargeAmt');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'nameOnAccount');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'firstName');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'lastName');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'address');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'zipCode');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'city');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'state');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'emailAddress');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccType');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccNumber');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccExpirationMonth');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccExpirationYear');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccCVV');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccCID');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'totalChargeAmt');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'agreeCreditCardFee');
        break;
      case PaymentMethod.ECheck:
      case PaymentMethod.RecurringECheck:
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'accountType', [Validators.required]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'routingNumber', [Validators.required, CustomValidators.spaceValidator]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'accountNumber', [Validators.required, CustomValidators.spaceValidator]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'nameOnAccount', [Validators.required, CustomValidators.spaceValidator, Validators.maxLength(64)]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'bankAccountTotalChargeAmt', [Validators.required]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'firstName', [Validators.required, CustomValidators.spaceValidator, Validators.maxLength(32)]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'lastName', [Validators.required, CustomValidators.spaceValidator, Validators.maxLength(31)]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'address', [Validators.required, CustomValidators.spaceValidator]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'zipCode', [Validators.required, CustomValidators.spaceValidator]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'city', [Validators.required]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'state', [Validators.required]);
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'emailAddress');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccType');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccNumber');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccExpirationMonth');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccExpirationYear');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccCVV');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccCID');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'totalChargeAmt');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'agreeCreditCardFee');
        break;
    }

    if (this.bindAndIssueData.billingForm.get('paymentMethod')?.value === '') {
      this.clearBillingFormValidators();
    }
  }

  checkFullPay(isFullPay: boolean): void {
    switch (isFullPay) {
      case true:
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'downpayment');
        break;
      case false:
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'downpayment', [Validators.required]);
        break;
    }
  }

  checkCreditPayment(isCreditPayment: boolean, isCardAMEX: boolean, creditCardType: string): void {
    if (isCreditPayment) {
      switch (isCardAMEX) {
        case true:
          this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'ccCID', [Validators.required, CustomValidators.spaceValidator]);
          this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccCVV');
          break;
        case false:
          this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'ccCVV', [Validators.required, CustomValidators.spaceValidator, Validators.minLength(3)]);
          this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccCID');
          break;
      }

      switch (creditCardType) {
        case CreditCardType.Visa:
          this.generalValidationService.resetValidatorFormControl(this.bindAndIssueData.billingForm, 'ccNumber', [Validators.required, Validators.pattern(RegexConstants.CreditCardFormat.visa)]);
          break;
        case CreditCardType.Mastercard:
          this.generalValidationService.resetValidatorFormControl(this.bindAndIssueData.billingForm, 'ccNumber', [Validators.required, Validators.pattern(RegexConstants.CreditCardFormat.masterCard)]);
          break;
        default:
          this.generalValidationService.resetValidatorFormControl(this.bindAndIssueData.billingForm, 'ccNumber', [Validators.required]);
          break;
      }
    }
  }

  checkRecurringPayment(isRecurringPayment: boolean): void {
    if (isRecurringPayment) {
      this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'agreeEnrollAutoPay', [Validators.requiredTrue]);
      this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'emailAddress', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,12}$')]);
      return;
    }

    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'agreeEnrollAutoPay');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'emailAddress');
  }

  checkOneTimeAuthPayment(isOneTimeAuthPayment: boolean): void {
    if (isOneTimeAuthPayment) {
      this.generalValidationService.resetValidatorFormControl(this.BindAndIssueBillingForm, 'agreeOneTimePayment', [Validators.requiredTrue]);
      return;
    }

    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'agreeOneTimePayment');
  }

  checkPaperlessSection(): boolean {
    const isEnrolledInPaperless = this.BindAndIssuePaperlessForm.get('enrollInPaperless').value ?? false;
    const emailAddress = this.BindAndIssuePaperlessForm.get('paperlessEmailAddress').value;
    const confirmEmailAddress = this.BindAndIssuePaperlessForm.get('paperlessConfirmEmailAddress').value;

    switch (isEnrolledInPaperless) {
      case true:
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssuePaperlessForm, 'enrollInPaperless', [Validators.required]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssuePaperlessForm, 'agreement', [Validators.required]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssuePaperlessForm, 'paperlessEmailAddress', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,12}$')]);
        this.generalValidationService.resetValidatorFormControl(this.BindAndIssuePaperlessForm, 'paperlessConfirmEmailAddress', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,12}$')]);
        break;
      case false:
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssuePaperlessForm, 'paperlessEmailAddress');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssuePaperlessForm, 'paperlessConfirmEmailAddress');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssuePaperlessForm, 'agreement');
        this.generalValidationService.clearValidatorFormControl(this.BindAndIssuePaperlessForm, 'enrollInPaperless');
        break;
    }

    if (emailAddress && confirmEmailAddress) {
      if (emailAddress === confirmEmailAddress) {
        this.isConfirmedEmailAddress = true;
      } else {
        this.isConfirmedEmailAddress = false;
      }
    }

    return isEnrolledInPaperless ? this.BindAndIssuePaperlessForm.valid && this.isConfirmedEmailAddress : this.BindAndIssuePaperlessForm.valid;
  }

  checkSignatureSection(): boolean {
    return this.BindAndIssueSignatureForm.valid;
  }

  clearBillingFormValidators(): void {
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'firstName');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'lastName');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'address');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'zipCode');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'city');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'state');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'emailAddress');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccType');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccNumber');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccExpirationMonth');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccExpirationYear');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccCVV');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'ccCID');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'totalChargeAmt');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'accountType');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'routingNumber');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'accountNumber');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'nameOnAccount');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'bankAccountTotalChargeAmt');
    this.generalValidationService.clearValidatorFormControl(this.BindAndIssueBillingForm, 'agreeCreditCardFee');
  }
  //#endregion


  checkCreditCardPaymentForUpdateRecurring(): void {
    if (this.bindAndIssueData.billingForm.get('updateRecurringPayment').value) {
      this.bindAndIssueData.billingForm.get('ccType').valueChanges.subscribe(x => {
        if (x) {
          const isCardAMEX = this.bindAndIssueData.billingForm.get('ccType').value === CreditCardTypeConstants.AMEX;
          this.checkCreditPayment(true, isCardAMEX, x);
        }
      });
    }
  }
}
