import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { BaseClass } from '../../../shared/base-class';
import { Injectable } from '@angular/core';
import { CoveragesValidators } from '../validators/coverages.validators';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import {
  LvCreditOrderStatus,
  LvCreditOrderStatusIndex,
  LvCreditScoreBrakcets,
  LvElevationCertificate, LvEstimatedCredit, LvFinancialResponsibility, LvFloodDeductible,
  LvFloodPolicyDuringPolicyPeriod, LvParticipationStatus, LvRaterBurglarProtection,
  LvRaterDeductibleHUR, LvRaterFireProtection, LvRaterOtherPerilDeductibleNHR,
  LvRaterSecuredCommunity, LvRaterSprinkler, ParticipationStatusConstants
} from '../../../shared/constants/quick-quote.options.constants';
import {
  ITableTh,
  ITableTr,
  ITableFormControl,
  ITableTd
} from '../../../shared/models/dynamic/table.interface';
import { RiskScheduleDTO, RiskScheduleDTO2 } from 'app/shared/models/data/dto/quick-quote/risk-schedule.dto';
import { EntityRiskDTO } from 'app/shared/models/data/dto/quick-quote/entity-risk.dto';
import { CoveragesLabelsConstants } from 'app/shared/constants/coverages.labels.constants';
import { CurrencyPipe } from '@angular/common';
import { AuthService } from 'app/core/services/auth.service';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { SummaryData } from './summary.data';
import Utils from '../../../shared/utilities/utils';
import { QuickQuoteService } from '../../../core/services/submission/quick-quote.service';
import { SaveCoveragesDTO } from '../../../shared/models/data/dto/quick-quote/save-coverages.dto';
import { RiskFloodDTO } from '../../../shared/models/data/dto/quick-quote/risk-flood.dto';
import { RiskDiscountDTO } from '../../../shared/models/data/dto/quick-quote/risk-discount.dto';
import { RiskCoverageDTO2, RiskCoverageDTO3 } from '../../../shared/models/data/dto/quick-quote/risk-coverage.dto';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessageConstant } from '../../../shared/constants/error-message.constants';
import FormUtils from '../../../shared/utilities/form.utils';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { PathConstants } from '../../../shared/constants/path.constants';
import { InfoMessageConstant } from '../../../shared/constants/info-message.constants';
import * as _ from 'lodash';
import { SubmissionPageData } from './submission-page.data';
import { CoveragesResultDTO } from '../../../shared/models/data/dto/quick-quote/coverages-result.dto';
import { EntityRiskData } from './entity-risk.data';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { RiskDTO2 } from 'app/shared/models/data/dto/quick-quote/risk-dto2';
import { Observable, Subject } from 'rxjs';
import { TorrentResponseDTO } from 'app/shared/models/data/dto/third-party/torrent-flood-quote.dto';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';
import { QuoteService } from 'app/core/services/quote.service';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { RiskDetailsDTO } from 'app/shared/models/data/dto/quick-quote/risk-details.dto';
import { CoveragesValidatorErrorConstants } from '../validators/validator-error-constants/coverages-validator-error.constants';
import { LocationLabelConstants } from '../../../shared/constants/property.labels.constants';
import { CalculateRaterData } from '../../../core/services/submission/rater-premium/calculate-rater-data.service';
import { QuickQuoteFormConstant } from 'app/shared/constants/quick-quotes.labels.constants';
import { PolicyIssueData } from '../../policy-management/data/policy-issue.data';
import { RiskStatusCode } from 'app/shared/models/data/dto/quick-quote/risk.dto';
import { select, Store } from '@ngrx/store';
import { updateSubmissionStatusFromCoveragesData } from 'app/store/submission/submission.actions';
import { SavingFlagConstants } from 'app/shared/constants/saving-flag.constants';
import { exit } from 'process';
import * as moment from 'moment';
import { SubmissionListData } from './submission-list.data';
import { Rater } from '../../../shared/constants/rater.constant';
import { updatePolicyStatusFromCoveragesData, updatePolicySubStatusFromCoveragesData } from 'app/store/policy/policy.actions';
import { GetRiskStatusPipe } from 'app/shared/pipes/get-risk-status.pipe';
import { updateCoveragesIsLoadingFromCoveragesData, updateCoveragesSchedulesFromCoveragesData } from 'app/store/coverages/coverages.actions';
import { EntitySubmissionListDTO } from 'app/shared/models/data/dto/submission-list/entity-submission-list.dto';
import { StatusConstants } from 'app/shared/constants/risk-status';
import { ApplicantData } from './applicant.data';

export const MAXCOVA = 10000000;
export const MINCOVA = 75000;
export const MINCOVCDP3DEF = 5000;
export const MAXCOVCDP3DEF = 250000;
export const MINCOVCDP3TENANT = 10000;
export const MAXCOVCDP3TENANT = 100000;
export const FLOODCOVERAGEV = 'v';
export const FIREIDNONE = '1';
export const BRUL358COUNTY = ['BROWARD', 'MIAMI-DADE', 'ST LUCIE', 'PALM BEACH'];

export interface ICheckIsActiveIPXBrulArgs {
  yearBuilt: number;
  firstIssueDate: any;
  effectiveDate: any;
}

export interface IGetFinalEffectiveDate {
  relatedRisks: EntitySubmissionListDTO[];
  firstIssueDate: any;
  effectiveDate: any;
}

export interface IHurricaneDeductibleMinimumOf5PercentRequiredArgs {
  homeAge: number;
  isActiveIPXBrul: boolean;
  isExternal: boolean;
}
export interface IHurricaneDeductibleMinimumPercentRequiredArgs {
  homeAge: number;
  isActiveIPXBrul: boolean;
  isExternal: boolean;
  isValidForNewMinimumDeductibleStateWide: boolean;
}

export interface IAopDeductibleMinimumInfoArgs {
  homeAge: number;
  isActiveIPXBrul: boolean;
  isExternal: boolean;
  isValidForBrul358: boolean;
  isValidForNewMinimumDeductibleStateWide: boolean;
}

export interface IAopDeductibleMinimumOf5000RequiredArgs {
  homeAge: number;
  isActiveIPXBrul: boolean;
  isExternal: boolean;
}
export interface IAopDeductibleMinimumOf2500RequiredArgs {
  homeAge: number;
  isActiveIPXBrul: boolean;
  isExternal: boolean;
}

@Injectable({
  providedIn: 'root',
})

export class CoveragesData extends BaseClass {
  showFireErrorOnInit = true;
  showPrivateFlood = true;
  infoMessageA: boolean = false;
  infoMessageC: boolean = false;
  hurricaneDeductibleExcludedInfoMessage: boolean;
  hurricaneDeductibleExcludedValue: string = '';
  hurricaneDeductible5InfoMessage: boolean;
  hurricaneDeductible2InfoMessage: boolean;
  hurrDedExcludedDueToWaterInfoMessage: boolean;
  hurrDed5DueToWaterInfoMessage: boolean;
  hurrDed2DueToWaterInfoMessage: boolean;
  userSelectedCovCId: number;
  preferredRisk = ['B', 'C', 'X'];
  scheduleTableNameId = CoveragesLabelsConstants.scheduleTableName;
  validCovA: number = null;
  validCovC: number = null;
  invalidCovA: number = null;
  invalidCovC: number = null;
  covAInfoMessage: boolean = false;
  covCInfoMessage: boolean = false;
  covAInfoViewed: boolean = false;
  covCInfoViewed: boolean = false;
  floodZoneVMessage: boolean = false;

  floodCovAInfoMessage: boolean = false;
  validCoverageALimit: number = null;

  floodCovCInfoMessage: boolean = false;
  floodCovCInfoMessageExcluded: boolean = false;
  validCoverageCLimit: number = null;

  floodCovCLimitInputValue: string;

  isFromPolicy: boolean = false;
  isFromCoverages: boolean = false;

  floodLabels = CoveragesLabelsConstants.flood;
  public isExternal: boolean = false;
  public creditScore?: number;
  public creditOrderStatusId?: number;
  public creditOrderStatusCode?: string;
  public creditScoreDateOrdered?: Date;
  public lexisReferenceNumber?: string;
  public hasSuccessOrderCredit?: boolean = false;
  creditPriorClaimTier?: number;
  statusCodeObservable = new Subject<string>();
  formTypeConstants = FormTypeConstants;
  genericContants = GenericConstants;
  locationLabelConstants = LocationLabelConstants;

  isBrul53: boolean = false;
  isBrul54: boolean = false;

  public QuickQuoteFormConstant = QuickQuoteFormConstant;
  county: string;

  isPrivateFloodCoverageUserInput: boolean = false;
  public isRetiredGreaterOrEqualTo60BRULCOV20: boolean = false;
  public isSeniorOrRetired: boolean = false;

  // FormGroups
  coveragesAndDeductiblesForm: FormGroup;
  discountsForm: FormGroup;
  schedulesForm: FormGroup;
  floodForm: FormGroup;
  floodAssistRetrievalForm: FormGroup;

  //Excluded Zipcode for Flood Coverage endorsement
  floodCoverageExcludedZipCode: any[];

  // Dropdown Values
  allOtherPerilsList: any[];
  hurricaneLimitList: any[];
  estimatedCreditList: any[];
  securedCommunityBuildingList: any[];
  fireList: any[];
  burglarList: any[];
  sprinklerList: any[];
  floodDeductibleList: any[];
  elevationCertificateList: any[];
  participationStatusList: any[];
  yesNoList: any[];
  IsValidHurricaneDeductible: boolean = true;
  applicantFloodPolicy: any[];
  defaultTier: string = '5 (score of 900)';
  creditOrderStatusList: any[];
  financialResponsibilityList: any[];

  firmDateOption: IAngularMyDpOptions;

  // Schedules Table Total Limit
  antiquesTotalLimit: number = 0;
  bicyclesTotalLimit: number = 0;
  cameraAndProjectionEquipmentTotalLimit: number = 0;
  coinCollectionsTotalLimit: number = 0;
  fineArtsAndAntiquesNoBreakageTotalLimit: number = 0;
  fineArtsAndAntiquesWithBreakageTotalLimit: number = 0;
  fursTotalLimit: number = 0;
  golfEquipmentTotalLimit: number = 0;
  gunsCollectibleTotalLimit: number = 0;
  gunsFiredTotalLimit: number = 0;
  personalJewelryTotalLimit: number = 0;
  miscellaneousTotalLimit: number = 0;
  musicalInstrumentsPersonalTotalLimit: number = 0;
  otherSportsEquipmentTotalLimit: number = 0;
  silverwareTotalLimit: number = 0;
  stampCollectionsTotalLimit: number = 0;

  schedulesTable: any[];

  isFloodCovPremiumZeroPerilValue: boolean = false;


  // Schedules Table Data
  tableHeaders: ITableTh[] = [{ value: 'Description' }, { value: 'Limit' }];
  tableRows: ITableTr[] = [];
  tableFormControls: ITableFormControl[] = [
    {
      name: 'field_1',
      type: 'string'
    },
    {
      name: 'field_2',
      type: 'number'
    }
  ];

  personalJewelryTableRows: ITableTr[] = [];
  bicyclesTableRows: ITableTr[] = [];
  cameraAndProjectionEquipmentTableRows: ITableTr[] = [];
  coinCollectionsTableRows: ITableTr[] = [];
  antiquesTableRows: ITableTr[] = [];
  fineArtsAndAntiquesNoBreakageTableRows: ITableTr[] = [];
  fineArtsAndAntiquesWithBreakageTableRows: ITableTr[] = [];
  fursTableRows: ITableTr[] = [];
  golfEquipmentTableRows: ITableTr[] = [];
  gunsCollectibleTableRows: ITableTr[] = [];
  gunsFiredTableRows: ITableTr[] = [];
  musicalInstrumentsPersonalTableRows: ITableTr[] = [];
  miscellaneousTableRows: ITableTr[] = [];
  otherSportsEquipmentTableRows: ITableTr[] = [];
  silverwareTableRows: ITableTr[] = [];
  stampCollectionsTableRows: ITableTr[] = [];

  scheduleTableNames = [
    'personalJewelry',
    'bicycles',
    'cameraAndProjectionEquipment',
    'coinCollections',
    'antiques',
    'fineArtsAndAntiquesNoBreakage',
    'fineArtsAndAntiquesWithBreakage',
    'furs',
    'golfEquipment',
    'gunsCollectible',
    'gunsFired',
    'musicalInstrumentsPersonal',
    'miscellaneous',
    'otherSportsEquipment',
    'silverware',
    'stampCollections'
  ];

  riskSchedules: RiskScheduleDTO[] = [];
  riskCoveragesResponse: any;
  coverageField = { coverageC: 'Coverage C', deductibleHUR: 'Hurricane Deductible' };
  estimatedCode: string = '';
  torrentResponse: TorrentResponseDTO;
  public isTorrentPurchased: boolean = false;

  enablePrivateFloodStatus: boolean = true;
  floodLossLocation: boolean = false;
  isComparativeCoverageMapped: boolean = false;
  isActiveCentauriHOPolicyFromComparativeRater: boolean = false;
  activeCentauriHOPolicyValueFromComparativeRater: boolean = false;

  previousHurricaneDeductibleValue: string;
  isUWR128Triggered: boolean = false;
  public currentHurricaneDecuctible: string;

  hasFloodElevationValuesChanged: boolean = false;
  initialLowestFloodElevationValue: number;
  initialBaseFloodElevationValue: number;
  initialElevationDifferenceValue: number;

  floodZoneAList: string[] = ['A', 'AE', 'A1-A30', 'AO', 'AH'];

  coveragesRetrievalData: RiskCoverageDTO3;
  hurricaneDeductibleidChanged: boolean = false;

  homeAge: number;
  isActiveIPXBrul: boolean;
  relatedRisks: EntitySubmissionListDTO[];
  statusConstants = StatusConstants;

  selectRelatedRiskstList$: Observable<EntitySubmissionListDTO[]>;

  get isFcraAcknowledgementBeReset(): boolean {
    return this.creditOrderStatusCode === LvCreditOrderStatus[LvCreditOrderStatusIndex.NotOrdered].code ? true : false;
  }

  get isFcraAcknowledgementBeResetLegacy(): boolean {
    return this.creditOrderStatusId === LvCreditOrderStatus[LvCreditOrderStatusIndex.NotOrdered].id ? true : false;
  }

  get isOrderCreditSuccessful(): boolean {
    const isOrdered = this.creditOrderStatusCode !== LvCreditOrderStatus[LvCreditOrderStatusIndex.NotOrdered].code ? true : false;
    return isOrdered;
  }

  get isOrderCreditSuccessfulLegacy(): boolean {
    const isOrdered = this.creditOrderStatusId > LvCreditOrderStatus[LvCreditOrderStatusIndex.NotOrdered].id ? true : false;
    return isOrdered;
  }

  get ipxDate(): any {
    return (this.authService.LoginDateFormGroup.get('IPXDate').value !== '' ||
      this.authService.LoginDateFormGroup.get('IPXDate').value !== undefined ||
      this.authService.LoginDateFormGroup.get('IPXDate').value !== null) ? localStorage.getItem('IPXDate')
        : this.authService.LoginDateFormGroup.get('IPXDate').value;
  }

  get isPolicy(): boolean {
    return this.policySummaryData.isPolicy;
  }

  get isRewrite(): boolean {
    return this.entityRiskData.getRisk()?.isFromRewrite;
  }

  get isRenewal(): boolean {
    return this.policySummaryData.isRenewal();
  }

  constructor(
    protected fb: FormBuilder,
    protected currency: CurrencyPipe,
    public authService: AuthService,
    public summaryData: SummaryData,
    public quickQuoteService: QuickQuoteService,
    public raterData: CalculateRaterData,
    protected toast: ToastrService,
    public submissionData: SubmissionPageData,
    public entityRiskData: EntityRiskData,
    public policySummaryData: PolicySummaryData,
    public quoteService: QuoteService,
    protected policyIssueData: PolicyIssueData,
    protected store: Store,
    protected submissionListData: SubmissionListData,
    protected getRiskStatus: GetRiskStatusPipe
  ) {
    super();

    this.raterData.creditClaimTiers.subscribe(tier => {
      const hasCreditScore = !isNaN(tier.creditScore) ? tier.creditScore !== null ? true : false : false;
      if (hasCreditScore && tier.creditClaimTier) {
        this.creditPriorClaimTier = tier.creditClaimTier;
      }
    });
    this.subscribeUserType();
  }

  initializeForms() {
    this.coveragesAndDeductiblesForm = this.fb.group({
      coverageA: this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3 ? new FormControl(0, [Validators.required, Validators.min(MINCOVA), Validators.max(MAXCOVA)]) : new FormControl(35000, [Validators.required, Validators.min(0), Validators.max(MAXCOVA)]),
      coverageB: new FormControl(0, [Validators.required]),
      coverageBLimitId: new FormControl('2', [Validators.required]),
      coverageC: this.quoteService.formType === this.formTypeConstants.HO3 ? new FormControl(0, [Validators.required]) : this.quoteService.formType === this.formTypeConstants.DP3 ? new FormControl(10000, [Validators.required]) : this.quoteService.formType === this.formTypeConstants.HO4 ? new FormControl(25000, [Validators.required, Validators.min(0), Validators.max(MAXCOVA)]) : new FormControl(25000, [Validators.required]),
      coverageCLimitId: new FormControl('50', [Validators.required]),
      coverageD: new FormControl(0, [Validators.required]),
      coverageEId: new FormControl('300000', [Validators.required]),
      coverageFId: new FormControl('5000', [Validators.required]),
      coverageDorE: new FormControl(0, []),
      coverageL: this.quoteService.formType === this.formTypeConstants.DP3 ? new FormControl(100000, []) : new FormControl(0, []),
      coverageM: this.quoteService.formType === this.formTypeConstants.DP3 ? new FormControl(1000, []) : new FormControl(0, []),
      isCoverageLEnabled: new FormControl(false, []),
      isCoverageMEnabled: new FormControl(false, []),
      allOtherPerilsId: new FormControl('1000', [Validators.required]),
      hurricaneDeductibleid: this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3 ? new FormControl('2', [Validators.required]) : new FormControl('1000', [Validators.required]),
      estimatedCreditId: new FormControl('EC5', [Validators.required]),
      creditOrdered: new FormControl(null, [Validators.required])
    });

    this.discountsForm = this.fb.group({
      accreditedBuilder: new FormControl(false, [Validators.required]),
      securedCommunityBuildingId: new FormControl('N', [Validators.required]),
      companion: new FormControl(false, [Validators.required]),
      seniorRetiree: new FormControl(false, [Validators.required]),
      fireId: new FormControl(this.policySummaryData.isPolicy ? null : '4', [Validators.required]),
      burglarId: new FormControl('1', [Validators.required]),
      sprinklerId: new FormControl('NO', [Validators.required]),
      financialResponsibilityId: new FormControl('', []),
      isCompanionHOPolicy: new FormControl(false, []),
      companionHOPolicy: new FormControl('', [])
    });

    this.floodForm = this.fb.group({
      floodZone: new FormControl('NA', [Validators.required]),
      applicantFloodPolicyId: this.quoteService.formType !== this.formTypeConstants.HO4 ?
        new FormControl('', [Validators.required]) : new FormControl(''),
      nFIPPremium: new FormControl(null),
      privateFloodCoverage: new FormControl(false, [Validators.required]),
      floodLossAtThisLocation: new FormControl(false, [Validators.required]),
      floodCoverageALimit: new FormControl(null),
      floodCoverageCLimit: new FormControl(null),
      floodDeductibleId: new FormControl('FD2'),
      participationStatusId: new FormControl(),
      firmDate: new FormControl(null),
      elevationCertificateId: new FormControl('N'),
      baseFloodElevation: new FormControl(null),
      lowestFloodElevation: new FormControl(null),
      elevationDifference: new FormControl(null),
    });

    this.schedulesForm = this.fb.group({
      addSchedules: new FormControl(false, [Validators.required]),
      itemsDamages: new FormControl(false, [Validators.required]),
      personalJewelry: new FormControl(false, [Validators.required]),
      bicycles: new FormControl(false, [Validators.required]),
      cameraAndProjectionEquipment: new FormControl(false, [Validators.required]),
      coinCollections: new FormControl(false, [Validators.required]),
      antiques: new FormControl(false, [Validators.required]),
      fineArtsAndAntiquesNoBreakage: new FormControl(false, [Validators.required]),
      fineArtsAndAntiquesWithBreakage: new FormControl(false, [Validators.required]),
      furs: new FormControl(false, [Validators.required]),
      golfEquipment: new FormControl(false, [Validators.required]),
      gunsCollectible: new FormControl(false, [Validators.required]),
      gunsFired: new FormControl(false, [Validators.required]),
      musicalInstrumentsPersonal: new FormControl(false, [Validators.required]),
      miscellaneous: new FormControl(false, [Validators.required]),
      otherSportsEquipment: new FormControl(false, [Validators.required]),
      silverware: new FormControl(false, [Validators.required]),
      stampCollections: new FormControl(false, [Validators.required]),
    });

    this.floodAssistRetrievalForm = this.fb.group({
      isFloodAssistRetrieved: new FormControl(false),
      isFloodAssistRetrievedSuccessful: new FormControl(false),
      floodAssistStatusCode: new FormControl(),
      isFloodZoneFromFloodAssist: new FormControl(false),
      isFIRMDateFromFloodAssist: new FormControl(false),
    });

    this.infoMessageA = false;
    this.infoMessageC = false;

    this.schedulesTable = [];

    this.populateDropdownValues();
    this.dateFormatOptions();
  }

  populateDropdownValues() {
    this.allOtherPerilsList = LvRaterOtherPerilDeductibleNHR;
    this.hurricaneLimitList = LvRaterDeductibleHUR.filter(item => {
      if (this.quoteService.formType === FormTypeConstants.HO3 || this.quoteService.formType === FormTypeConstants.DP3) {
        return item.isHO3 || item.isDP3;
      }
      if (this.quoteService.formType === FormTypeConstants.HO4 || this.quoteService.formType === FormTypeConstants.HO6) {
        return item.isHO4 || item.isHO6;
      }
    });
    this.estimatedCreditList = LvEstimatedCredit;
    this.creditOrderStatusList = LvCreditOrderStatus;
    this.securedCommunityBuildingList = LvRaterSecuredCommunity;
    this.fireList = LvRaterFireProtection;
    this.burglarList = LvRaterBurglarProtection;
    this.sprinklerList = LvRaterSprinkler;
    this.yesNoList = [
      { value: true, name: 'Yes' },
      { value: false, name: 'No' },
    ];
    this.floodDeductibleList = LvFloodDeductible;
    this.elevationCertificateList = LvElevationCertificate;
    this.participationStatusList = LvParticipationStatus;
    this.applicantFloodPolicy = LvFloodPolicyDuringPolicyPeriod;
    this.financialResponsibilityList = LvFinancialResponsibility;
  }

  dateFormatOptions() {
    this.firmDateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy'
    };
  }

  validateHurricaneDeductible(yearBuilt?: number, effectiveDate?: any) {
    this.homeAge = 0;
    this.isActiveIPXBrul = false;

    const coverageAStatus = this.coveragesAndDeductiblesForm.get('coverageA').status;
    const coverageA = Number(this.coveragesAndDeductiblesForm.get('coverageA').value);
    const AOP = Number(this.coveragesAndDeductiblesForm.get('allOtherPerilsId').value);
    const deductibleID = Number(this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').value === 'E' ? 0 : this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').value);
    const xCounty = this.county ?? this.entityRiskData.getRisk()?.riskDetails[0].riskProperties[0].county;
    const firstIssueDate = { isRange: false, singleDate: { jsDate: new Date(this.entityRiskData.getRisk()?.firstIssueDate) } };
    const processDate = { isRange: false, singleDate: { jsDate: new Date(this.entityRiskData.getRisk()?.processDate) } };

    const checkIsActiveIPXBrulArgs: ICheckIsActiveIPXBrulArgs = {
      yearBuilt: yearBuilt,
      firstIssueDate: firstIssueDate,
      effectiveDate: effectiveDate
    };

    this.checkIsActiveIPXBrul(checkIsActiveIPXBrulArgs);

    const hurricaneDeductibleMinimumPercentRequiredArgs: IHurricaneDeductibleMinimumPercentRequiredArgs = {
      homeAge: this.homeAge,
      isActiveIPXBrul: this.isActiveIPXBrul,
      isExternal: this.isExternal,
      isValidForNewMinimumDeductibleStateWide: this.isValidForNewMinimumDeductibleStateWide()
    };

    const aopDeductibleMinimumInfoArgs: IAopDeductibleMinimumInfoArgs = {
      homeAge: this.homeAge,
      isActiveIPXBrul: this.isActiveIPXBrul,
      isExternal: this.isExternal,
      isValidForBrul358: this.isValidForBRUL358(),
      isValidForNewMinimumDeductibleStateWide: this.isValidForNewMinimumDeductibleStateWide()
    };

    const aopBackup: number = Number(this.isPolicy ?
      this.entityRiskData.ParentBackUpRiskData?.riskCoverages[0].allOtherPerilsDeductible :
      this.entityRiskData.renewalBackup?.riskCoverages[0].allOtherPerilsDeductible);
    const aopMinimum: number = this.isValidForBRUL358() || this.isValidForNewMinimumDeductibleStateWide() ? 2500 : 5000;
    const skipAopDeductibleMinimumRequired: boolean =
      (this.isPolicy || this.isRenewal) &&
      aopBackup < aopMinimum &&
      Number(this.coveragesAndDeductiblesForm.get('allOtherPerilsId').value) === aopBackup;

    if (this.quoteService.formType === this.formTypeConstants.HO3 ||
      this.quoteService.formType === this.formTypeConstants.DP3) {
      if (skipAopDeductibleMinimumRequired) {
        this.coveragesAndDeductiblesForm.get('allOtherPerilsId').setValidators([Validators.required]);
      } else if ((this.isValidForBRUL358() || this.isValidForNewMinimumDeductibleStateWide()) && this.isExternal) {
        this.coveragesAndDeductiblesForm.get('allOtherPerilsId').setValidators([
          Validators.required,
          CoveragesValidators.aopDeductibleMinimumOf2500Required()
        ]);
      } else {
        this.coveragesAndDeductiblesForm.get('allOtherPerilsId').setValidators([
          Validators.required, CoveragesValidators.aopDeductibleMinimumOf5000Required(aopDeductibleMinimumInfoArgs)
        ]);
      }
    } else {
      this.coveragesAndDeductiblesForm.get('allOtherPerilsId').setValidators([Validators.required]);
      if (this.isValidForBRUL358() &&
          this.isExternal &&
          !skipAopDeductibleMinimumRequired) {
        this.coveragesAndDeductiblesForm.get('allOtherPerilsId').setValidators([
          Validators.required,
          CoveragesValidators.aopDeductibleMinimumOf2500Required()
        ]);
      }
    }
    this.coveragesAndDeductiblesForm.get('allOtherPerilsId').updateValueAndValidity();

    if ((coverageAStatus === 'VALID' || coverageAStatus === 'DISABLED') && deductibleID > 0 && (this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3)) {
      let hurricaneDeductible: number;

      if (this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').value !== 'E') {
        if (deductibleID === 500) {
          hurricaneDeductible = deductibleID;
          this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid')
          .setValidators([Validators.required,
            CoveragesValidators.hurricaneDedCannotBeLessThanAOP(hurricaneDeductible, AOP),
            CoveragesValidators.hurricaneDeductibleMustBeExcludedWhenCountyMonroe(hurricaneDeductible, xCounty),
            CoveragesValidators.hurricaneDeductibleMinimumOfPercentRequired(hurricaneDeductibleMinimumPercentRequiredArgs)
          ]);
          this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').updateValueAndValidity();
          this.IsValidHurricaneDeductible = deductibleID >= AOP;
        } else {
          const hurricaneDeductibleBackup: number = Number(this.isPolicy ?
            this.entityRiskData.ParentBackUpRiskData?.riskCoverages[0].hurricaneDeductible :
            this.entityRiskData.renewalBackup?.riskCoverages[0].hurricaneDeductible);
          const hurricaneDeductibleMinimum: number = 5;
          const skipHurricaneDeductibleMinimumOf5PercentRequired: boolean = (this.isPolicy || this.isRenewal) && hurricaneDeductibleBackup < hurricaneDeductibleMinimum;

          hurricaneDeductible = coverageA * (deductibleID / 100);

          if (skipHurricaneDeductibleMinimumOf5PercentRequired) {
            this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValidators([Validators.required,
              CoveragesValidators.hurricaneDedCannotBeLessThanAOP(hurricaneDeductible, AOP),
              CoveragesValidators.hurricaneDeductibleMustBeExcludedWhenCountyMonroe(hurricaneDeductible, xCounty)
            ]);
          } else {
            this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValidators([Validators.required,
              CoveragesValidators.hurricaneDedCannotBeLessThanAOP(hurricaneDeductible, AOP),
              CoveragesValidators.hurricaneDeductibleMustBeExcludedWhenCountyMonroe(hurricaneDeductible, xCounty),
              CoveragesValidators.hurricaneDeductibleMinimumOfPercentRequired(hurricaneDeductibleMinimumPercentRequiredArgs)
            ]);
          }

          this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').updateValueAndValidity();
          this.IsValidHurricaneDeductible = hurricaneDeductible >= AOP;
        }
      } else {
        this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValidators([Validators.required,
          CoveragesValidators.hurricaneDeductibleMinimumOfPercentRequired(hurricaneDeductibleMinimumPercentRequiredArgs)
        ]);
        this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').updateValueAndValidity();
        this.IsValidHurricaneDeductible = true;
      }
    } else if ((coverageAStatus === 'VALID' || coverageAStatus === 'DISABLED') && deductibleID > 0 && (this.quoteService.formType === this.formTypeConstants.HO4 || this.quoteService.formType === this.formTypeConstants.HO6)) {
      const hurricaneDeductible = Number(this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').value === 'E' ? 0 : this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').value);

      if (this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').value !== 'E') {
        this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid')
        .setValidators([Validators.required,
          CoveragesValidators.hurricaneDedCannotBeLessThanAOP(hurricaneDeductible, AOP),
          CoveragesValidators.hurricaneDeductibleMustBeExcludedWhenCountyMonroe(hurricaneDeductible, xCounty)]);
        this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').updateValueAndValidity();
        this.IsValidHurricaneDeductible = hurricaneDeductible >= AOP;
      } else {
        this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValidators([Validators.required]);
        this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').updateValueAndValidity();
        this.IsValidHurricaneDeductible = true;
      }
    } else {
      if (this.quoteService.formType === this.formTypeConstants.HO6 && deductibleID !== 0) {
        const hurricaneDeductible = Number(this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').value === 'E' ? 0 : this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').value);

        if (this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').value !== 'E') {
          this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValidators([Validators.required, CoveragesValidators.hurricaneDedCannotBeLessThanAOP(hurricaneDeductible, AOP)]);
          this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').updateValueAndValidity();
          this.IsValidHurricaneDeductible = hurricaneDeductible >= AOP;
        } else {
          this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValidators([Validators.required]);
          this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').updateValueAndValidity();
          this.IsValidHurricaneDeductible = true;
        }
      } else {
        if (this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3) {
          this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValidators([
            Validators.required,
            CoveragesValidators.hurricaneDeductibleMinimumOfPercentRequired(hurricaneDeductibleMinimumPercentRequiredArgs)
          ]);
          this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').updateValueAndValidity();
        } else {
          this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValidators([Validators.required]);
          this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').updateValueAndValidity();
        }
        this.IsValidHurricaneDeductible = deductibleID === 0 ? true : false;
      }
    }
  }

  setRiskType(): void {
    // FOR QA Test purposes
    // BRUL : 'Flood - The definition of a 'preferred risk' for Flood Coverage = in Flood Zones 'B', 'C', or 'X' and both Flood Coverage A C are required.   Anything that does not meet that defintion is a 'standard risk'.'
    const privateFlood = this.floodForm.get('privateFloodCoverage')?.value;
    const floodZone = this.floodForm.get('floodZone')?.value;
    const floodCovA = this.floodForm.get('floodCoverageALimit')?.value;
    const floodCovC = this.floodForm.get('floodCoverageCLimit')?.value;
    if (this.preferredRisk.includes(floodZone) && floodCovA && floodCovC && privateFlood) {
      console.log('For Test Purposes: Preferred risk');
    } else {
      console.log('For Test Purposes: Standard risk');
    }
  }

  public showFireErrorMessageOnInit() {
    //Utils.unblockUI();
    let isLNErrorMessageDisplayed = false;
    const fireId = this.raterData.fireProtection.toString();
    const displayFireErrorMessage = fireId === FIREIDNONE && this.showFireErrorOnInit && this.floodForm.status !== 'DISABLED' ? true : false;

    this.summaryData.errorMessage$.subscribe(isOrderingCredit => {
      if (this.summaryData.errorMessages.length > 0) {
        if (displayFireErrorMessage) {
          const errorMessage = {
            text: ErrorMessageConstant.coveragesErrorMessage.fireDiscountErrorMessage
          };
          this.summaryData.errorMessages.push(errorMessage);
        }
        NotifUtils.showErrorViaChain(this.summaryData.errorMessages);
        isLNErrorMessageDisplayed = true;
        this.summaryData.errorMessages = [];
      }
    });

    if (!isLNErrorMessageDisplayed) {
      if (displayFireErrorMessage) {
        if (fireId === FIREIDNONE) {
          NotifUtils.showError(ErrorMessageConstant.coveragesErrorMessage.fireDiscountErrorMessage);
          this.showFireErrorOnInit = false;
        }
      }
    } else {
      this.showFireErrorOnInit = false;
    }
  }

  public populateCoveragesPage(data: EntityRiskDTO, isPolicy: boolean = false) {
    this.coveragesRetrievalData = data.risks[0].riskDetails[0].riskCoverages[0];

    //Coverages Section
    this.userSelectedCovCId = undefined;
    this.validCovA = data?.risks[0]?.riskDetails[0]?.riskCoverages[0].covA;
    this.validCovC = data?.risks[0]?.riskDetails[0]?.riskCoverages[0].covC;
    const coverages = data?.risks[0]?.riskDetails[0]?.riskCoverages[0];
    const riskFloods = data?.risks[0]?.riskDetails[0]?.riskFloods;
    const riskDiscounts = data?.risks[0]?.riskDetails[0]?.riskDiscounts;

    if (coverages) {
      this.estimatedCode = data.estimatedCredit;
      this.creditScore = data.creditScore ?? this.creditScore;
      this.lexisReferenceNumber = data.lexisReferenceNumber;
      this.creditOrderStatusCode = data?.creditScoreStatusCode && data?.creditScoreStatusCode !== '' ?
        LvCreditOrderStatus.find(s => s.code === data?.creditScoreStatusCode).code : LvCreditOrderStatus[0].code;
      this.statusCodeObservable.next(this.creditOrderStatusCode);
      this.creditScoreDateOrdered = data.creditScoreDateOrdered;
      this.coveragesAndDeductiblesForm.get('coverageA').setValue(this.validCovA);

      if (coverages.covB) {
        this.coveragesAndDeductiblesForm.get('coverageB').setValue(coverages.covB);
      }
      const covBPercentage = Number(coverages.covBPercentageId) === 0;
      this.coveragesAndDeductiblesForm.get('coverageBLimitId').setValue(covBPercentage ? '2' : coverages.covBPercentageId);
      if (coverages.covC) {
        this.coveragesAndDeductiblesForm.get('coverageC').setValue(this.quoteService.formType === this.formTypeConstants.DP3 ? this.validCovC : coverages.covC);
      } else {
        if (this.quoteService.formType === this.formTypeConstants.DP3 && coverages.covC === 0) {
          this.coveragesAndDeductiblesForm.get('coverageC').setValue(this.validCovC);
        }
      }

      this.coveragesAndDeductiblesForm.get('coverageCLimitId').setValue(this.defaultCoverageSetter(this.coverageField.coverageC, coverages));

      if (this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId())) {
        this.userSelectedCovCId = this.coveragesAndDeductiblesForm.get('coverageCLimitId').value; // for Endorsement star package resetting
      }

      if (coverages.covD) {
        this.coveragesAndDeductiblesForm.get('coverageD').setValue(coverages.covD);
      }

      if (coverages.coverageL !== 0) {
        this.coveragesAndDeductiblesForm.get('coverageL').setValue(coverages.coverageL);
        this.coveragesAndDeductiblesForm.get('isCoverageLEnabled').setValue(true);
        this.coveragesAndDeductiblesForm.get('isCoverageMEnabled').setValue(true);
      } else {
        this.coveragesAndDeductiblesForm.get('coverageL').setValue('100000');
        this.coveragesAndDeductiblesForm.get('isCoverageLEnabled').setValue(false);
        this.coveragesAndDeductiblesForm.get('isCoverageMEnabled').setValue(false);
      }

      if (coverages.coverageM !== 0) {
        this.coveragesAndDeductiblesForm.get('coverageM').setValue(coverages.coverageM);
        this.coveragesAndDeductiblesForm.get('isCoverageMEnabled').setValue(true);
        this.coveragesAndDeductiblesForm.get('isCoverageLEnabled').setValue(true);
      } else {
        this.coveragesAndDeductiblesForm.get('coverageM').setValue('1000');
        this.coveragesAndDeductiblesForm.get('isCoverageMEnabled').setValue(false);
        this.coveragesAndDeductiblesForm.get('isCoverageLEnabled').setValue(false);
      }

      if (coverages.fairRentalAddlLiving !== 0) {
        this.coveragesAndDeductiblesForm.get('coverageDorE').setValue(Utils.round(coverages.fairRentalAddlLiving));
      } else {
        this.coveragesAndDeductiblesForm.get('coverageDorE').setValue(Utils.round(this.validCovA * 0.1));
      }

      if (this.quoteService.formType !== this.formTypeConstants.DP3) {
        this.coveragesAndDeductiblesForm.get('coverageEId').setValue(Number(coverages.covE) === 0 ? '300000' : coverages.covE);
        this.coveragesAndDeductiblesForm.get('coverageFId').setValue(Number(coverages.covF) === 0 ? '5000' : coverages.covF);
      } else {
        this.coveragesAndDeductiblesForm.get('coverageEId').setValue(0);
        this.coveragesAndDeductiblesForm.get('coverageFId').setValue(0);
      }

      this.coveragesAndDeductiblesForm.get('allOtherPerilsId').setValue(Number(coverages.allOtherPerilsDeductible) === 0 ? '1000' : coverages.allOtherPerilsDeductible);
      this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValue(this.defaultCoverageSetter(this.coverageField.deductibleHUR, coverages));
      this.currentHurricaneDecuctible = coverages.hurricaneDeductible;
      this.hurricaneDeductibleExcludedValue = this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').value;
      this.previousHurricaneDeductibleValue = this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').value;
      this.coveragesAndDeductiblesForm.get('estimatedCreditId').setValue(Number(data.estimatedCredit) === 0 ? 'EC5' : data.estimatedCredit);
      if (data.creditScoreStatusCode && data.creditScoreStatusCode !== '') {
        this.coveragesAndDeductiblesForm.get('creditOrdered').setValue(data.creditScoreStatusCode);
      }

      this.populateFinancialResponsibility(this.creditScore);

      this.floodAssistRetrievalForm.get('isFloodAssistRetrieved').setValue(coverages.isFloodAssistRetrieved);
      this.floodAssistRetrievalForm.get('isFloodAssistRetrievedSuccessful').setValue(coverages.isFloodAssistRetrievedSuccessful);

      this.coveragesAndDeductiblesForm.get('isCoverageLEnabled').valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(isCovLEnabled => {
        if (isCovLEnabled) {
          this.coveragesAndDeductiblesForm.get('isCoverageMEnabled').setValue(true);
        } else {
          this.coveragesAndDeductiblesForm.get('isCoverageMEnabled').setValue(false);
        }
      });

      this.coveragesAndDeductiblesForm.get('isCoverageMEnabled').valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(isCovMEnabled => {
        if (isCovMEnabled) {
          this.coveragesAndDeductiblesForm.get('isCoverageLEnabled').setValue(true);
        } else {
          this.coveragesAndDeductiblesForm.get('isCoverageLEnabled').setValue(false);
        }
      });

      this.coveragesAndDeductiblesForm.get('coverageL').valueChanges.pipe(takeUntil(this.stop$)).subscribe(covLValue => {
        this.updateCoverageM(Number(covLValue));
      });

      this.store.dispatch(updateCoveragesSchedulesFromCoveragesData({ schedules: data.risks[0].riskDetails[0].riskSchedules }));
    }

    const floodCovA = riskFloods[0]?.floodCovA;
    const floodCovC = riskFloods[0]?.floodCovC;
    if (riskFloods[0]) {
      const privateFlood = riskFloods[0]?.isPrivateFloodCov;
      this.floodForm.get('privateFloodCoverage').setValue(riskFloods[0]?.isPrivateFloodCov);
      this.floodForm.get('nFIPPremium').setValue(riskFloods[0]?.nfipPremium);
      this.floodForm.get('floodLossAtThisLocation').setValue(riskFloods[0]?.hasFloodLoss);
      this.floodForm.get('applicantFloodPolicyId').setValue(riskFloods[0]?.applicantFloodPolicyCode || '');
      this.floodForm.get('floodCoverageALimit').setValue(floodCovA || this.coveragesAndDeductiblesForm.get('coverageA').value);
      this.floodForm.get('floodCoverageCLimit').setValue(this.quoteService.formType !== this.formTypeConstants.HO3 ? floodCovC || this.coveragesAndDeductiblesForm.get('coverageC').value : floodCovC);
      this.validCoverageALimit = this.floodForm.get('floodCoverageALimit').value > CoveragesLabelsConstants.flood.floodCovAMaxValue ? CoveragesLabelsConstants.flood.floodCovAMaxValue : this.floodForm.get('floodCoverageALimit').value;
      this.validCoverageCLimit = this.quoteService.formType !== this.formTypeConstants.HO3
                                    ? this.floodForm.get('floodCoverageCLimit').value > CoveragesLabelsConstants.flood.floodCovCMaxValue ? CoveragesLabelsConstants.flood.floodCovCMaxValue : this.floodForm.get('floodCoverageCLimit').value
                                    : this.floodForm.get('floodCoverageCLimit').value;
      this.floodForm.get('floodDeductibleId').setValue(riskFloods[0]?.floodDeductibleCode || 'FD2');
      this.floodForm.get('elevationCertificateId').setValue(riskFloods[0]?.elevationCertificateCode || 'N');
      this.floodForm.get('baseFloodElevation').setValue(riskFloods[0]?.baseFloodElevation);
      this.floodForm.get('lowestFloodElevation').setValue(riskFloods[0]?.lowestFloorElevation);
      this.floodForm.get('elevationDifference').setValue(riskFloods[0]?.elevationDifference);
      const fZone = riskFloods[0]?.floodZone;
      this.floodForm.get('floodZone').setValue(fZone || 'NA');
      this.floodForm.get('participationStatusId').setValue(riskFloods[0]?.participationStatusCode);
      const fDate = riskFloods[0]?.firmDate || null;
      const date = new Date(fDate);
      this.floodForm.get('firmDate').setValue(fDate ? date.getFullYear() : null);
      this.floodAssistRetrievalForm.get('floodAssistStatusCode').setValue(riskFloods[0]?.floodAssistStatusCode);
      this.isTorrentPurchased = riskFloods[0]?.isTorrentPurchased;
      this.floodAssistRetrievalForm.get('isFloodZoneFromFloodAssist').setValue(riskFloods[0]?.isFloodZoneFromFloodAssist);
      this.floodAssistRetrievalForm.get('isFIRMDateFromFloodAssist').setValue(riskFloods[0]?.isFIRMDateFromFloodAssist);
    }

    if (this.policySummaryData.isPolicy) {
      this.discountsForm.get('seniorRetiree').setValue(riskDiscounts[0]?.isSrRetiree);
    } else {
      this.discountsForm.get('seniorRetiree').setValue(data?.isRetired || data?.coEntity?.isRetired);
    }



    const age = this.calculateInsuredAge(data?.birthDate, data?.risks[0]?.riskDetails[0]?.effectiveDate);
    const isRetiredGreaterOrEqualTo60BRULCOV20 = this.isInAgeOfRetirementBRULCOV20(age);
    const isInAgeOfRetirement = this.isInAgeOfRetirement(age);

    const coAppAge = this.calculateInsuredAge(data?.coEntity?.birthDate, data?.risks[0]?.riskDetails[0]?.effectiveDate);
    const isRetiredGreaterOrEqualTo60BRULCOV20_CoApp = this.isInAgeOfRetirementBRULCOV20(coAppAge);
    const isInAgeOfRetirement_CoApp = this.isInAgeOfRetirement(coAppAge);

    this.isRetiredGreaterOrEqualTo60BRULCOV20 = (isRetiredGreaterOrEqualTo60BRULCOV20 || isInAgeOfRetirement) || (isRetiredGreaterOrEqualTo60BRULCOV20_CoApp || isInAgeOfRetirement_CoApp);


    if (riskDiscounts[0]) {
      this.discountsForm.get('accreditedBuilder').setValue(riskDiscounts[0]?.isAccreditedBuilder);
      this.discountsForm.get('securedCommunityBuildingId').setValue(riskDiscounts[0]?.securedCommBldgCode);
      this.discountsForm.get('companion').setValue(riskDiscounts[0]?.isCompanion);
      this.discountsForm.get('fireId').setValue(riskDiscounts[0]?.fireCode);
      this.discountsForm.get('burglarId').setValue(riskDiscounts[0]?.buglarCode);
      this.discountsForm.get('sprinklerId').setValue(riskDiscounts[0]?.sprinklerCode);
      if (this.quoteService.formType === this.formTypeConstants.DP3) {
        this.discountsForm.get('companionHOPolicy').setValue(riskDiscounts[0]?.companionHOPolicy);
        this.discountsForm.get('isCompanionHOPolicy').setValue(riskDiscounts[0]?.isCompanionHOPolicy);
      }
    }

    // Schedules Section
    const risk = data.risks[0];
    this.schedulesForm.get('addSchedules').setValue(risk.riskDetails[0]?.isAddSchedule);
    this.schedulesForm.get('itemsDamages').setValue(risk.riskDetails[0]?.isItemDamageFromSchedule);

    const riskSchedules = risk.riskDetails[0]?.riskSchedules;
    this.riskSchedules = riskSchedules;
    this.resetTableRowItem();
    for (const propsName in this.scheduleTableNameId) {
      this.populateScheduleTable(propsName, riskSchedules);
    }

    FormUtils.markFormControlAsTouched(this.coveragesAndDeductiblesForm);
    FormUtils.markFormControlAsTouched(this.floodForm);
    FormUtils.markFormControlAsTouched(this.schedulesForm);
  }

  isInAgeOfRetirement(age: number): boolean {
    return age >= 55 && age <= 59;
  }

  isInAgeOfRetirementBRULCOV20(age: number): boolean {
    return age >= 60;
  }

  calculateInsuredAge(dateOfBirth: Date, effDate: Date): number {
    if (!!!dateOfBirth) {
      return 0;
    }

    const today = this.authService.getCustomDate();
    const birthDate = new Date(dateOfBirth);
    const effectiveDate = { isRange: false, singleDate: { jsDate: new Date(effDate) } };
    const dateRef = !!!effectiveDate ? today : new Date(effectiveDate?.singleDate?.jsDate);

    let age: number = dateRef.getFullYear() - birthDate.getFullYear();
    const m: number = dateRef.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && dateRef.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  setCreditScore() {
    const noHit = LvCreditOrderStatus[LvCreditOrderStatusIndex.NoHit].id;
    const noScore = LvCreditOrderStatus[LvCreditOrderStatusIndex.NoScore].id;
    const notOrdered = LvCreditOrderStatus[LvCreditOrderStatusIndex.NotOrdered].id;

    const noHitCode = LvCreditOrderStatus[LvCreditOrderStatusIndex.NoHit].code;
    const noScoreCode = LvCreditOrderStatus[LvCreditOrderStatusIndex.NoScore].code;
    const notOrderedCode = LvCreditOrderStatus[LvCreditOrderStatusIndex.NotOrdered].code;

    // Hot Fix:
    // Ordered - Score Recieved must be handled properly through
    // mapping logic
    switch (this.creditOrderStatusCode) {
      case noHitCode:
      case noScoreCode:
        this.creditScore = -1;
        break;
      case notOrderedCode:
        this.creditScore = 900;
        break;
      default:
        // Not Ordered = Excellent, estimated credit
        this.creditScore = 900;
        break;
    }
  }

  populateScheduleTable(tableId: string, riskSchedules: RiskScheduleDTO[]) {
    const tableData = riskSchedules.filter(x => x.tableId === tableId);
    if (tableData.length !== 0) {
      this.schedulesForm.get(tableId).setValue(true);
      // Map table data here
      this.addTableItem(this.getTableRow(tableId), tableId, tableData);
    }
  }

  addTableItem(table: ITableTr[], tableId: string, tableData: RiskScheduleDTO[]): void {
    tableData.forEach(item => {
      const td: ITableTd[] = [];
      td.push({
        id: item.id,
        value: item.description,
        display: item.description
      });
      td.push({
        id: item.id,
        value: item.limit,
        display: String(this.currency.transform(item.limit, 'USD', 'symbol', '1.0'))
      });
      table.push({
        id: item.id,
        scheduleId: item.scheduleId,
        tr: td,
        createdDate: item.createdDate
      });
    });
    this.calculateTotalLimit(table, tableId);
  }

  calculateTotalLimit(table: ITableTr[], tableRow: string): void {
    let totalLimit = 0;

    table.forEach((row) => {
      row.tr.forEach((td, tdIndex) => {
        if (tdIndex === 1) {
          totalLimit += Number(td.value);
        }
      });
    });

    switch (tableRow) {
      case this.scheduleTableNameId.antiques:
        this.antiquesTotalLimit = totalLimit;
        break;
      case this.scheduleTableNameId.bicycles:
        this.bicyclesTotalLimit = totalLimit;
        break;
      case this.scheduleTableNameId.cameraAndProjectionEquipment:
        this.cameraAndProjectionEquipmentTotalLimit = totalLimit;
        break;
      case this.scheduleTableNameId.coinCollections:
        this.coinCollectionsTotalLimit = totalLimit;
        break;
      case this.scheduleTableNameId.fineArtsAndAntiquesNoBreakage:
        this.fineArtsAndAntiquesNoBreakageTotalLimit = totalLimit;
        break;
      case this.scheduleTableNameId.fineArtsAndAntiquesWithBreakage:
        this.fineArtsAndAntiquesWithBreakageTotalLimit = totalLimit;
        break;
      case this.scheduleTableNameId.furs:
        this.fursTotalLimit = totalLimit;
        break;
      case this.scheduleTableNameId.golfEquipment:
        this.golfEquipmentTotalLimit = totalLimit;
        break;
      case this.scheduleTableNameId.gunsCollectible:
        this.gunsCollectibleTotalLimit = totalLimit;
        break;
      case this.scheduleTableNameId.gunsFired:
        this.gunsFiredTotalLimit = totalLimit;
        break;
      case this.scheduleTableNameId.personalJewelry:
        this.personalJewelryTotalLimit = totalLimit;
        break;
      case this.scheduleTableNameId.miscellaneous:
        this.miscellaneousTotalLimit = totalLimit;
        break;
      case this.scheduleTableNameId.musicalInstrumentsPersonal:
        this.musicalInstrumentsPersonalTotalLimit = totalLimit;
        break;
      case this.scheduleTableNameId.otherSportsEquipment:
        this.otherSportsEquipmentTotalLimit = totalLimit;
        break;
      case this.scheduleTableNameId.silverware:
        this.silverwareTotalLimit = totalLimit;
        break;
      case this.scheduleTableNameId.stampCollections:
        this.stampCollectionsTotalLimit = totalLimit;
        break;
      default: break;
    }
  }

  getTableRow(tableNameId: string): ITableTr[] {
    switch (tableNameId) {
      case this.scheduleTableNameId.antiques:
        return this.antiquesTableRows;
      case this.scheduleTableNameId.bicycles:
        return this.bicyclesTableRows;
      case this.scheduleTableNameId.cameraAndProjectionEquipment:
        return this.cameraAndProjectionEquipmentTableRows;
      case this.scheduleTableNameId.coinCollections:
        return this.coinCollectionsTableRows;
      case this.scheduleTableNameId.fineArtsAndAntiquesNoBreakage:
        return this.fineArtsAndAntiquesNoBreakageTableRows;
      case this.scheduleTableNameId.fineArtsAndAntiquesWithBreakage:
        return this.fineArtsAndAntiquesWithBreakageTableRows;
      case this.scheduleTableNameId.furs:
        return this.fursTableRows;
      case this.scheduleTableNameId.golfEquipment:
        return this.golfEquipmentTableRows;
      case this.scheduleTableNameId.gunsCollectible:
        return this.gunsCollectibleTableRows;
      case this.scheduleTableNameId.gunsFired:
        return this.gunsFiredTableRows;
      case this.scheduleTableNameId.personalJewelry:
        return this.personalJewelryTableRows;
      case this.scheduleTableNameId.miscellaneous:
        return this.miscellaneousTableRows;
      case this.scheduleTableNameId.musicalInstrumentsPersonal:
        return this.musicalInstrumentsPersonalTableRows;
      case this.scheduleTableNameId.otherSportsEquipment:
        return this.otherSportsEquipmentTableRows;
      case this.scheduleTableNameId.silverware:
        return this.silverwareTableRows;
      case this.scheduleTableNameId.stampCollections:
        return this.stampCollectionsTableRows;
      default: break;
    }
  }

  resetTableRowItem(): void {
    this.antiquesTableRows = [];
    this.bicyclesTableRows = [];
    this.cameraAndProjectionEquipmentTableRows = [];
    this.coinCollectionsTableRows = [];
    this.fineArtsAndAntiquesNoBreakageTableRows = [];
    this.fineArtsAndAntiquesWithBreakageTableRows = [];
    this.fursTableRows = [];
    this.golfEquipmentTableRows = [];
    this.gunsCollectibleTableRows = [];
    this.gunsFiredTableRows = [];
    this.personalJewelryTableRows = [];
    this.miscellaneousTableRows = [];
    this.musicalInstrumentsPersonalTableRows = [];
    this.otherSportsEquipmentTableRows = [];
    this.silverwareTableRows = [];
    this.stampCollectionsTableRows = [];
  }

  saveCoverages(isFromBindAndIssue: boolean = false, isFromEndorsement: boolean = false, updateInfoShowing: boolean = true, propertyUsage?: string) {
    try {
      this.entityRiskData.startSavingForms();
      this.quickQuoteService.setCategoryToSavingState(SavingFlagConstants.coverages);
      const riskId = this.entityRiskData.getRiskId() ?? this.summaryData.SummaryForm.get('riskId').value;
      const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;
      const addScheduleStatus = this.schedulesForm.get('addSchedules').value;

      this.collectRiskSchedules(riskDetailId);
      this.checkIsFromBindAndIssue(addScheduleStatus, isFromBindAndIssue);
      this.checkAddScheduleStatus(addScheduleStatus);
      this.setHeaderDetails(this.coveragesAndDeductiblesForm.get('coverageA').valid);
      this.setHeaderDetails(this.coveragesAndDeductiblesForm.get('coverageC').valid);
      if (this.quoteService.formType !== this.formTypeConstants.DP3) {
        this.initPostCoverages(isFromEndorsement, riskId, riskDetailId, addScheduleStatus, updateInfoShowing, isFromBindAndIssue);
      } else {
        this.initPostCoverages(isFromEndorsement, riskId, riskDetailId, addScheduleStatus, updateInfoShowing, isFromBindAndIssue, propertyUsage);
      }

      if (this.isFromCoverages) {
        if (this.covAInfoMessage && !this.covAInfoViewed) {
          this.covAInfoViewed = true;
        } else if (this.covAInfoMessage && this.covAInfoViewed) {
          if (this.invalidCovA === null) {
            this.covAInfoMessage = false;
            this.covAInfoViewed = false;
          }
        }

        if (this.covCInfoMessage && !this.covCInfoViewed) {
          this.covCInfoViewed = true;
        } else if (this.covCInfoMessage && this.covCInfoViewed) {
          if (this.invalidCovC === null) {
            this.covCInfoMessage = false;
            this.covCInfoViewed = false;
          }
        }

        if (this.quoteService.formType === this.formTypeConstants.DP3 && this.validCovC === 0) {
          this.coveragesAndDeductiblesForm.get('coverageC').setValidators([Validators.required]);
          this.coveragesAndDeductiblesForm.get('coverageC').updateValueAndValidity();
        }
      }
      if (this.quoteService.formType === this.formTypeConstants.DP3) {
        this.coveragesAndDeductiblesForm.get('coverageDorE').setValue(Utils.round(this.validCovA * .1));
      }
    } catch (error) {
      this.entityRiskData.finishSavingForms();
      this.quickQuoteService.setCategoryToNotSavingState(SavingFlagConstants.coverages);
      NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
    }
  }

  checkIsFromBindAndIssue(addScheduleStatus: boolean, isFromBindAndIssue: boolean): void {
    if (!addScheduleStatus && isFromBindAndIssue) {
      this.schedulesForm.get('itemsDamages').setValue(false);
      this.resetTableRowItem();
      this.scheduleTableNames.forEach((sheduleTableName) => {
        this.schedulesForm.get(sheduleTableName).setValue(false);
      });
      this.riskSchedules = [];
    }
  }

  checkAddScheduleStatus(addScheduleStatus: boolean): void {
    if (!addScheduleStatus) {
      this.riskSchedules = [];
    }
  }

  getCoverageRequest(riskId: any, riskDetailId: any, addScheduleStatus: boolean): SaveCoveragesDTO {
    const isAddSched = this.schedulesForm.get('addSchedules').value;
    const coverageRequest: SaveCoveragesDTO = {
      riskId: riskId,
      riskDetailId: riskDetailId,
      isAddSchedule: this.schedulesForm.get('addSchedules').value,
      isItemDamageFromSchedule: addScheduleStatus ? this.schedulesForm.get('itemsDamages').value : false,
      riskCoverage: this.getRiskCoverage(riskDetailId),
      riskDiscount: this.getRiskDiscount(riskDetailId),
      riskFlood: this.getRiskFlood(riskDetailId),
      riskSchedules: isAddSched ? this.riskSchedules : [],
      estimatedCredit: this.estimatedCode ?? '',
      creditScore: this.creditScore,
      creditScoreStatusCode: this.creditOrderStatusCode,
      creditScoreDateOrdered: this.creditScoreDateOrdered,
      isValidForApplication: this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId()) ? false : this.submissionData.isValidForApplication ?? false
    };

    return coverageRequest;
  }

  initPostCoverages(isFromEndorsement: boolean, riskId: any, riskDetailId: any, addScheduleStatus: boolean, updateInfoShowing: boolean = true, isFromBindAndIssue: boolean = false, propertyUsage?: string): void {
    if (this.quoteService.formType !== this.formTypeConstants.DP3) {
      this.checkIsFromEndorsement(isFromEndorsement, this.getCoverageRequest(riskId, riskDetailId, addScheduleStatus));
    } else {
      this.checkIsFromEndorsement(isFromEndorsement, this.getCoverageRequest(riskId, riskDetailId, addScheduleStatus), propertyUsage);
    }
    if (this.quoteService.formType !== FormTypeConstants.HO4) {
      this.checkFloodCoverageAValidation(updateInfoShowing); // update flood cov A info message if navigate saving only and not on other background saving
    }
    this.checkFloodCoverageCValidation(updateInfoShowing); // update flood cov C info message if navigate saving only and not on other background saving
    this.postCoverages(riskId, riskDetailId, this.getCoverageRequest(riskId, riskDetailId, addScheduleStatus), isFromBindAndIssue);
  }

  checkFloodCoverageAValidation(updateDefaultingInfoMessage: boolean = true): void {
    if (!updateDefaultingInfoMessage)  {return; }

    const coverageA = Number(this.coveragesAndDeductiblesForm.get('coverageA').value);
    const coverageALimitValue = this.floodForm.get('floodCoverageALimit');

    if (coverageALimitValue.errors?.max) {
      coverageALimitValue.setValue(this.floodLabels.floodCovAMaxValue);
      this.showFloodCoverageAInfoMessage(updateDefaultingInfoMessage);
      this.hidefloodCoverageALimitMessage(updateDefaultingInfoMessage);
    } else if (coverageALimitValue.hasError('floodCovAGreaterThanBase')) {
      this.showfloodCoverageALimitMessage(updateDefaultingInfoMessage);
      this.hideFloodCoverageAInfoMessage(updateDefaultingInfoMessage);
      const covLimitValue = this.validCoverageALimit > coverageA ? coverageA : this.validCoverageALimit;
      coverageALimitValue.setValue(covLimitValue ?? coverageA);
    } else if (coverageALimitValue.hasError('floodCovABaseFloodZone')) {
      this.showfloodCoverageALimitMessage(updateDefaultingInfoMessage);
      this.hideFloodCoverageAInfoMessage(updateDefaultingInfoMessage);
      const covLimitValue = this.validCoverageALimit > coverageA ? coverageA : this.validCoverageALimit;
      coverageALimitValue.setValue(covLimitValue ?? coverageA);
    } else if (coverageALimitValue.hasError(CoveragesValidatorErrorConstants.floodCovABaseFloodZoneBCXErrorMessage.key)) {
      this.showfloodCoverageALimitMessage(updateDefaultingInfoMessage);
      this.hideFloodCoverageAInfoMessage(updateDefaultingInfoMessage);
      const covLimitValue = this.validCoverageALimit > coverageA ? coverageA : this.validCoverageALimit;
      coverageALimitValue.setValue(covLimitValue ?? coverageA);
    } else {
      if (!this.raterData.isViewQuoteProposalClicked) {
        this.hideFloodCoverageAInfoMessage(updateDefaultingInfoMessage);
        this.hidefloodCoverageALimitMessage(updateDefaultingInfoMessage);
        this.validCoverageALimit = coverageALimitValue.value;
        if (this.validCoverageALimit > coverageA) {
          this.floodCovAInfoMessage = true;
          this.validCoverageALimit = coverageA;
        }
        coverageALimitValue.setValue(this.validCoverageALimit);
      }
    }
  }

  showFloodCoverageAInfoMessage(updateValue: boolean = true): void {
    if (updateValue) { this.infoMessageA = true; }
  }

  showfloodCoverageALimitMessage(updateValue: boolean = true): void {
    if (updateValue) { this.floodCovAInfoMessage = true; }
  }

  hideFloodCoverageAInfoMessage(updateValue: boolean = true): void {
    if (updateValue) { this.infoMessageA = false; }
  }

  hidefloodCoverageALimitMessage(updateValue: boolean = true): void {
    if (updateValue) { this.floodCovAInfoMessage = false; }
  }

  checkFloodCoverageCValidation(updateDefaultingInfoMessage: boolean = true): void {
    if (!updateDefaultingInfoMessage)  {return; }

    const coverageC = Number(this.coveragesAndDeductiblesForm.get('coverageC').value);
    const coverageCLimitValue = this.floodForm.get('floodCoverageCLimit');

    if (coverageC === 0 && coverageCLimitValue.value !== 0) {
      this.floodCovCInfoMessageExcluded = true;
      this.hidefloodCoverageCLimitMessage(updateDefaultingInfoMessage);
      this.hideFloodCoverageCInfoMessage(updateDefaultingInfoMessage);
      coverageCLimitValue.setValue(0);
    } else if (coverageC === 0 && coverageCLimitValue.value === 0) {
      this.floodCovCInfoMessageExcluded = false;
      this.hidefloodCoverageCLimitMessage(updateDefaultingInfoMessage);
      this.hideFloodCoverageCInfoMessage(updateDefaultingInfoMessage);
      coverageCLimitValue.setValue(0);
    } else if (coverageCLimitValue.errors?.max) {
      coverageCLimitValue.setValue(this.floodLabels.floodCovCMaxValue);
      this.showFloodCoverageCInfoMessage(updateDefaultingInfoMessage);
      this.hidefloodCoverageCLimitMessage(updateDefaultingInfoMessage);
      this.floodCovCInfoMessageExcluded = false;
    } else if (coverageCLimitValue.hasError('floodCovCGreaterThanBase')) {
      this.showfloodCoverageCLimitMessage(updateDefaultingInfoMessage);
      this.floodCovCInfoMessageExcluded = false;
      this.hideFloodCoverageCInfoMessage(updateDefaultingInfoMessage);
      const covLimitValue = this.validCoverageCLimit > coverageC ? coverageC : this.validCoverageCLimit;
      coverageCLimitValue.setValue(covLimitValue ?? coverageC);
    } else if (coverageCLimitValue.hasError('floodCovCBaseFloodZone')) {
      this.showfloodCoverageCLimitMessage(updateDefaultingInfoMessage);
      this.floodCovCInfoMessageExcluded = false;
      this.hideFloodCoverageCInfoMessage(updateDefaultingInfoMessage);
      const covLimitValue = this.validCoverageCLimit > coverageC ? coverageC : this.validCoverageCLimit;
      coverageCLimitValue.setValue(covLimitValue ?? coverageC);
    } else if (coverageCLimitValue.hasError(CoveragesValidatorErrorConstants.floodCovCBaseFloodZoneBCXErrorMessage.key)) {
      this.showfloodCoverageCLimitMessage(updateDefaultingInfoMessage);
      this.floodCovCInfoMessageExcluded = false;
      this.hideFloodCoverageCInfoMessage(updateDefaultingInfoMessage);
      const covLimitValue = this.validCoverageCLimit > coverageC ? coverageC : this.validCoverageCLimit;
      coverageCLimitValue.setValue(covLimitValue ?? coverageC);
    } else {
      if (!this.raterData.isViewQuoteProposalClicked) {
        this.hideFloodCoverageCInfoMessage(updateDefaultingInfoMessage);
        this.hidefloodCoverageCLimitMessage(updateDefaultingInfoMessage);
        this.floodCovCInfoMessageExcluded = false;
        this.validCoverageCLimit = coverageCLimitValue.value;
        if (this.validCoverageCLimit > coverageC) {
          this.showfloodCoverageCLimitMessage(updateDefaultingInfoMessage);
          this.validCoverageCLimit = coverageC;
        }
        coverageCLimitValue.setValue(this.validCoverageCLimit);
      }
    }
  }

  showFloodCoverageCInfoMessage(updateValue: boolean = true): void {
    if (updateValue) { this.infoMessageC = true; }
  }

  showfloodCoverageCLimitMessage(updateValue: boolean = true): void {
    if (updateValue) { this.floodCovCInfoMessage = true; }
  }

  hideFloodCoverageCInfoMessage(updateValue: boolean = true): void {
    if (updateValue) { this.infoMessageC = false; }
  }

  hidefloodCoverageCLimitMessage(updateValue: boolean = true): void {
    if (updateValue) { this.floodCovCInfoMessage = false; }
  }

  checkIsFromEndorsement(isFromEndorsement: boolean, coverageRequest: SaveCoveragesDTO, propertyUsage?: string): void {
    if (!isFromEndorsement) {
      const coverageA = this.coveragesAndDeductiblesForm.get('coverageA').value;
      const coverageC = this.coveragesAndDeductiblesForm.get('coverageC').value;

      switch(this.quoteService.formType) {
        case this.formTypeConstants.HO3:
          if (coverageA >= MINCOVA && coverageA <= MAXCOVA) {
            coverageRequest.riskCoverage.covA = +coverageA;
            this.validCovA = +coverageA;
            if (this.invalidCovA !== null) {
              this.invalidCovA = null;
            }
          } else {
            this.coveragesAndDeductiblesForm.get('coverageA').setValue(coverageRequest.riskCoverage.covA);
            if (this.coveragesAndDeductiblesForm.get('coverageA').errors?.required) {
              coverageRequest.riskCoverage.covA = 0;
              this.validCovA = 0;
            }
            coverageRequest.riskCoverage.covA = this.validCovA;
            this.covAInfoMessage = true;
            this.invalidCovA = coverageA === null ? -1 : coverageA;
          }

          if (coverageC >= 0 && coverageC <= MAXCOVA) {
            coverageRequest.riskCoverage.covC = +coverageC;
            this.validCovC = +coverageC;
            if (this.invalidCovC !== null) {
              this.invalidCovC = null;
            }
          } else {
            this.coveragesAndDeductiblesForm.get('coverageC').setValue(coverageRequest.riskCoverage.covC);
            if (this.coveragesAndDeductiblesForm.get('coverageC').errors?.required) {
              coverageRequest.riskCoverage.covC = 0;
              this.validCovC = 0;
            }
            coverageRequest.riskCoverage.covC = this.validCovC;
            this.covCInfoMessage = true;
            this.invalidCovC = coverageC === null ? -1 : coverageC;
          }
          break;
        case this.formTypeConstants.HO4:
          if (coverageC >= 0 && coverageC <= MAXCOVA) {
            coverageRequest.riskCoverage.covC = +coverageC;
            this.validCovC = +coverageC;
            if (this.invalidCovC !== null) {
              this.invalidCovC = null;
            }
          } else {
            if (this.coveragesAndDeductiblesForm.get('coverageC').errors?.required) {
              coverageRequest.riskCoverage.covC = 0;
              this.validCovC = 0;
            }
            coverageRequest.riskCoverage.covC = this.validCovC;
            this.covCInfoMessage = true;
            this.invalidCovC = coverageC === null ? -1 : coverageC;
            this.coveragesAndDeductiblesForm.get('coverageC').setValue(coverageRequest.riskCoverage.covC);
          }

          break;
        case this.formTypeConstants.HO6:
          if (coverageA === null) {
            coverageRequest.riskCoverage.covA = +this.validCovA;
          }
          if (coverageA >= 0 && coverageA <= MAXCOVA) {
            coverageRequest.riskCoverage.covA = +coverageA;
            this.validCovA = +coverageA;
            if (this.invalidCovA !== null) {
              this.invalidCovA = null;
            }
          } else {
            this.coveragesAndDeductiblesForm.get('coverageA').setValue(coverageRequest.riskCoverage.covA);
            if (this.coveragesAndDeductiblesForm.get('coverageA').errors?.required) {
              coverageRequest.riskCoverage.covA = this.validCovA;
            }
            coverageRequest.riskCoverage.covA = this.validCovA;
            this.covAInfoMessage = true;
            this.invalidCovA = coverageA === null ? -1 : coverageA;
          }

          if (coverageC >= 0 && coverageC <= MAXCOVA) {
            coverageRequest.riskCoverage.covC = +coverageC;
            this.validCovC = +coverageC;
            if (this.invalidCovC !== null) {
              this.invalidCovC = null;
            }
          } else {
            if (this.coveragesAndDeductiblesForm.get('coverageC').errors?.required) {
              coverageRequest.riskCoverage.covC = 0;
              this.validCovC = 0;
            }
            coverageRequest.riskCoverage.covC = this.validCovC;
            this.covCInfoMessage = true;
            this.invalidCovC = coverageC === null ? -1 : coverageC;
            this.coveragesAndDeductiblesForm.get('coverageC').setValue(coverageRequest.riskCoverage.covC);
          }
          break;
        case this.formTypeConstants.DP3:
          if (propertyUsage !== undefined) {
            if (coverageA >= MINCOVA && coverageA <= MAXCOVA) {
              coverageRequest.riskCoverage.covA = +coverageA;
              this.validCovA = +coverageA;
              if (this.invalidCovA !== null) {
                this.invalidCovA = null;
              }
            } else {
              this.coveragesAndDeductiblesForm.get('coverageA').setValue(coverageRequest.riskCoverage.covA);
              if (this.coveragesAndDeductiblesForm.get('coverageA').errors?.required) {
                coverageRequest.riskCoverage.covA = 0;
                this.validCovA = 0;
              }
              coverageRequest.riskCoverage.covA = this.validCovA;
              this.covAInfoMessage = true;
              this.invalidCovA = coverageA === null ? -1 : coverageA;
            }

            const covCMin = propertyUsage === this.locationLabelConstants.tenantOccupied ? MINCOVCDP3TENANT : MINCOVCDP3DEF;
            const covCMax = propertyUsage === this.locationLabelConstants.tenantOccupied ? MAXCOVCDP3TENANT : MAXCOVCDP3DEF;

            if (coverageC === 0) {
              coverageRequest.riskCoverage.covC = +coverageC;
              this.validCovC= +coverageC;
              if (this.invalidCovC !== null) {
                this.invalidCovC = null;
              }
            } else if (coverageC >= covCMin && coverageC <= covCMax) {
              coverageRequest.riskCoverage.covC = +coverageC;
              this.validCovC= +coverageC;
              if (this.invalidCovC !== null) {
                this.invalidCovC = null;
              }
            } else {
              this.coveragesAndDeductiblesForm.get('coverageC').setValue(coverageRequest.riskCoverage.covC);
              if (this.coveragesAndDeductiblesForm.get('coverageC').errors?.required) {
                coverageRequest.riskCoverage.covC = this.validCovC;
              }
              coverageRequest.riskCoverage.covC = this.validCovC;
              this.covCInfoMessage = this.isValidCovCValidDP3(this.validCovC, covCMin, covCMax);
              this.invalidCovC = coverageC === null ? -1 : coverageC;
              if (this.validCovC === 0) {
                this.coveragesAndDeductiblesForm.get('coverageC').setValidators([Validators.required]);
              }
            }
          }
          break;
      }
    }
  }

  postCoverages(riskId: any, riskDetailId: any, coverageRequest: SaveCoveragesDTO, isFromBindAndIssue: boolean = false): void {
    if (riskId && riskDetailId) {
      this.entityRiskData.isApiCallCompleted.next(false);
      this.policySummaryData.saveCoveragesComplete$.next(false);
      this.submissionData.coveragesSavingComplete.next(false);
      this.store.dispatch(updateCoveragesIsLoadingFromCoveragesData({ isLoading: true }));

      this.quickQuoteService.postCoverages(coverageRequest).pipe(takeUntil(this.stop$)).subscribe(res => {
        this.quickQuoteService.setCategoryToNotSavingState(SavingFlagConstants.coverages);
        const result = <CoveragesResultDTO>res;
        this.entityRiskData.finishSavingForms();
        this.entityRiskData.updateCurrentRisksRiskSchedulesData(result.riskSchedules);
        this.entityRiskData.updateCurrentRisksRiskFloodData(result.riskFloods);

        this.riskCoveragesResponse = res;
        this.updateRiskSchedulesIds(res);
        FormUtils.markFormControlAsTouched(this.coveragesAndDeductiblesForm);
        FormUtils.markFormControlAsTouched(this.floodForm);
        FormUtils.markFormControlAsTouched(this.schedulesForm);
        this.summaryData.isCalcPremium.next(true);
        this.updateRaterRequest(isFromBindAndIssue);
        NotifUtils.showConsoleSuccess(`${PathConstants.Submission.Submission.CoveragesAndDeduction} ${InfoMessageConstant.savedSuccessfullyMessage}`);
        FormUtils.markFormsAsPristine(this.getCoveragesForms()); // used for nav out logic
        this.setQuoteStatus(result.riskStatusCode, result.riskSubStatusCode as RiskStatusCode);
        if (this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId())) {
          this.entityRiskData.updateEntityRiskDataCoverage(coverageRequest.riskCoverage);
          this.policySummaryData.subStatusCode$.next(res.riskSubStatusCode as RiskStatusCode);

          if (!this.isExternal && (this.previousHurricaneDeductibleValue !== this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').value)) {
            this.isUWR128Triggered = false;
          }
        }

        this.entityRiskData.isApiCallCompleted.next(true);
        this.policySummaryData.saveCoveragesComplete$.next(true);
        this.submissionData.coveragesSavingComplete.next(true);
        this.store.dispatch(updateCoveragesSchedulesFromCoveragesData({ schedules: res?.riskSchedules }));
        this.store.dispatch(updateCoveragesIsLoadingFromCoveragesData({ isLoading: false }));
      },
        err => {
          Utils.unblockUI();
          this.entityRiskData.finishSavingForms();
          this.quickQuoteService.setCategoryToNotSavingState(SavingFlagConstants.coverages);
          this.summaryData.isCalcPremium.next(false);
          this.toast.error(ErrorMessageConstant.savingErrorMessage);
          this.entityRiskData.isApiCallCompleted.next(false);
          this.store.dispatch(updateCoveragesSchedulesFromCoveragesData({ schedules: [] }));
          this.store.dispatch(updateCoveragesIsLoadingFromCoveragesData({ isLoading: false }));
        },
      );
    }
  }

  // Prevent Application status reverting back to Quoted
  setQuoteStatus(riskStatusCode: RiskStatusCode, riskSubStatusCode: RiskStatusCode): void {
    if (riskStatusCode !== this.genericContants.quoteStatusCode.quo) {
      this.submissionData.quoteStatus.next(this.submissionData.getQuoteStatus(riskStatusCode));
      this.store.dispatch(updateSubmissionStatusFromCoveragesData({ status: this.submissionData.getQuoteStatus(riskStatusCode) }));

      if (this.policySummaryData.isPolicy) {
        this.store.dispatch(updatePolicyStatusFromCoveragesData({ status: this.getRiskStatus.transform(riskStatusCode) }));
        this.store.dispatch(updatePolicySubStatusFromCoveragesData({ subStatus: this.getRiskStatus.transform(riskSubStatusCode) }));
      }
    }
  }

  setHeaderDetails(value: boolean): void {
    if (value) {
      const covA = this.coveragesAndDeductiblesForm.get('coverageA').value;
      const covC = this.coveragesAndDeductiblesForm.get('coverageC').value;
      const riskId = this.summaryData.SummaryForm.get('riskId').value;
      const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;

      if (covA >= MINCOVA && covA <= MAXCOVA && (riskId && riskDetailId)) {
        this.summaryData.SummaryForm.get('coverageA').setValue(this.coveragesAndDeductiblesForm.get('coverageA').value);
      }

      if (this.quoteService.formType === this.formTypeConstants.HO6 && covA <= MAXCOVA && riskId) {
        this.summaryData.SummaryForm.get('coverageA').setValue(this.coveragesAndDeductiblesForm.get('coverageA').value);
      }

      if (this.quoteService.formType === this.formTypeConstants.HO4 && covC <= MAXCOVA && riskId) {
        this.summaryData.SummaryForm.get('coverageC').setValue(this.coveragesAndDeductiblesForm.get('coverageC').value);
      }
    }
  }

  getRiskCoverage(riskDetailId: string): RiskCoverageDTO2 {
    const covB = this.covBPayload;
    const covC = this.covCPayload;
    const covD = this.covDPayload;
    const covDorE = this.covDorEPayload;
    const covL = this.covLPayload;
    const covM = this.covMPayload;

    return {
      riskDetailId: riskDetailId,
      covA: this.coveragesAndDeductiblesForm.controls['coverageA'].value && this.quoteService.formType === this.formTypeConstants.HO4 ? 0 : this.validCovA,
      covB: this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3 ? covB : 0,
      covBPercentageId: this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3 ? this.coveragesAndDeductiblesForm.controls['coverageBLimitId'].value : '',
      covC: this.validCovC ? Math.round(Number(this.validCovC)) : 0,
      covCPercentageId: this.quoteService.formType === this.formTypeConstants.HO3 ? this.coveragesAndDeductiblesForm.controls['coverageCLimitId'].value : '0',
      covD: this.quoteService.formType !== this.formTypeConstants.DP3 ? covD : 0,
      covE: this.quoteService.formType !== this.formTypeConstants.DP3 ? this.coveragesAndDeductiblesForm.controls['coverageEId'].value : 0,
      covF: this.quoteService.formType !== this.formTypeConstants.DP3 ? this.coveragesAndDeductiblesForm.controls['coverageFId'].value : 0,
      coverageL: this.quoteService.formType === this.formTypeConstants.DP3 ? this.coveragesAndDeductiblesForm.controls['isCoverageLEnabled'].value ? covL : 0 : 0,
      coverageM: this.quoteService.formType === this.formTypeConstants.DP3 ? this.coveragesAndDeductiblesForm.controls['isCoverageMEnabled'].value ? covM : 0 : 0,
      fairRentalAddlLiving: this.quoteService.formType === this.formTypeConstants.DP3 ? covDorE : 0,
      allOtherPerilsDeductible: this.coveragesAndDeductiblesForm.controls['allOtherPerilsId'].value,
      hurricaneDeductible: this.coveragesAndDeductiblesForm.controls['hurricaneDeductibleid'].value,
      estimatedCredit: this.coveragesAndDeductiblesForm.controls['estimatedCreditId'].value,
      endorsementNumber: 1,
      creditOrdered: this.coveragesAndDeductiblesForm.controls['creditOrdered'].value,
      isFloodAssistRetrieved: this.floodAssistRetrievalForm.controls['isFloodAssistRetrieved'].value,
      isFloodAssistRetrievedSuccessful: this.floodAssistRetrievalForm.controls['isFloodAssistRetrievedSuccessful'].value
    };
  }

  getRiskDiscount(riskDetailId: string): RiskDiscountDTO {
    return {
      riskDetailId: riskDetailId,
      isAccreditedBuilder: (this.discountsForm.controls['accreditedBuilder'].value)?.toString() === 'true',
      isCompanion: this.discountsForm.controls['companion'].value,
      isSrRetiree: this.discountsForm?.get('seniorRetiree')?.value ?? false,
      securedCommBldgCode: this.discountsForm.controls['securedCommunityBuildingId'].value,
      fireCode: this.discountsForm.controls['fireId'].value,
      buglarCode: this.discountsForm.controls['burglarId'].value,
      sprinklerCode: this.discountsForm.controls['sprinklerId'].value,
      financialResponsibility: this.discountsForm.controls['financialResponsibilityId'].value,
      companionHOPolicy: this.discountsForm.controls['companionHOPolicy'].value,
      isCompanionHOPolicy: this.discountsForm.controls['isCompanionHOPolicy'].value
    };
  }

  getRiskFlood(riskDetailId: string): RiskFloodDTO {
    let firmDate: any;
    const elevationCertifcateCode = this.floodForm.controls['elevationCertificateId'].value === 'Y';
    const isOrderFloodDataClicked = this.floodAssistRetrievalForm.get('floodAssistStatusCode').value !== null;
    const fDate = this.floodForm.controls['firmDate'].value;
    const cntrFirmDate = fDate ? new Date(`01/01/01`) : null;

    if (fDate) {
      cntrFirmDate.setFullYear(fDate);
      cntrFirmDate.setDate(cntrFirmDate.getDate() + 1);
      firmDate = cntrFirmDate;
    }

    return {
      riskDetailId: riskDetailId,
      floodZone: this.floodForm.controls['floodZone'].value,
      applicantFloodPolicyCode: this.floodForm.controls['applicantFloodPolicyId'].value,
      nfipPremium: this.floodForm.controls['nFIPPremium'].value,
      isPrivateFloodCov: this.floodForm.controls['privateFloodCoverage'].value,
      hasFloodLoss: this.floodForm.controls['floodLossAtThisLocation'].value,
      floodCovA: this.checkIfFloodAssistRetrieved() && isOrderFloodDataClicked ? this.floodForm.controls['floodCoverageALimit'].value : 0,
      floodCovC: this.checkIfFloodAssistRetrieved() && isOrderFloodDataClicked ? this.floodForm.controls['floodCoverageCLimit'].value : 0,
      floodDeductibleCode: this.checkIfFloodAssistRetrieved() && isOrderFloodDataClicked ? this.floodForm.controls['floodDeductibleId'].value : null,
      participationStatusCode: this.floodForm.controls['participationStatusId'].value ?? null, // removed dependency from privateFlood for BR-COV-43
      firmDate: this.checkIfFloodAssistRetrieved() ? firmDate.toLocaleDateString('en-US') : null,
      elevationCertificateCode: this.checkIfFloodAssistRetrieved() && isOrderFloodDataClicked ? this.floodForm.controls['elevationCertificateId'].value : null,
      baseFloodElevation: this.checkIfFloodAssistRetrieved() && elevationCertifcateCode ? this.floodForm.controls['baseFloodElevation'].value : null,
      lowestFloorElevation: this.checkIfFloodAssistRetrieved() && elevationCertifcateCode ? this.floodForm.controls['lowestFloodElevation'].value : null,
      elevationDifference: this.checkIfFloodAssistRetrieved() && elevationCertifcateCode ? this.floodForm.controls['elevationDifference'].value : null,
      torrentTrackingNum: this?.torrentResponse?.torrentTrackingNum,
      torrentRequestTransactionId: this?.torrentResponse?.torrentRequestTransactionId,
      isFloodZoneAvailableFromTorrent: this?.torrentResponse?.isFloodZoneAvailableFromTorrent,
      floodAssistStatusCode: this?.floodForm?.get('privateFloodCoverage')?.value ? this.floodAssistRetrievalForm.get('floodAssistStatusCode').value : null,
      isTorrentPurchased: this?.isTorrentPurchased,
      isFloodZoneFromFloodAssist: this?.floodAssistRetrievalForm?.get('isFloodZoneFromFloodAssist')?.value,
      isFIRMDateFromFloodAssist: this?.floodAssistRetrievalForm?.get('isFIRMDateFromFloodAssist')?.value,
      isTorrentAddressValidationFailed: this?.torrentResponse?.isTorrentAddressValidationFailed
    };
  }

  checkIfFloodAssistRetrieved(): boolean {
    return  this.floodForm.controls['privateFloodCoverage'].value && this.floodAssistRetrievalForm.get('isFloodAssistRetrieved').value;
  }

  collectRiskSchedules(riskDetailId: string): void {
    this.scheduleTableNames.forEach((sheduleTableName) => {
      const sheduleTableRowsName = sheduleTableName + 'TableRows';
      if (this.schedulesForm.get(sheduleTableName).value) {
        if (this[sheduleTableRowsName].length !== 0) {
          this[sheduleTableRowsName].forEach((item, index) => {

            const fromDB = this.riskSchedules.filter(riskSchedule => {
              return (riskSchedule.tableId === sheduleTableName) && (riskSchedule.id === item.id);
            });

            if (fromDB.length === 0) {
              this.riskSchedules.push({
                riskDetailId: riskDetailId,
                id: item.id,
                scheduleId: item.scheduleId,
                tableId: sheduleTableName,
                description: item.tr[0].value,
                limit: item.tr[1].value,
                total: this[sheduleTableName + 'TotalLimit'],
                createdDate: item.createdDate,
                isAdd: item.isAdd ?? false
              });
            } else {
              this.riskSchedules.forEach(riskSchedule => {
                if (riskSchedule.id === fromDB[0].id) {
                  riskSchedule.description = item.tr[0].value;
                  riskSchedule.limit = item.tr[1].value;
                  riskSchedule.total = this[sheduleTableName + 'TotalLimit'];
                  riskSchedule.createdDate = item.createdDate;
                  riskSchedule.scheduleId = item.scheduleId;
                  riskSchedule.isAdd = false;
                }
              });
            }
          });
        }
      } else {
        this.riskSchedules = _.filter(this.riskSchedules, a => a.tableId !== sheduleTableName);
      }
    });
  }

  updateRiskSchedulesIds(coverageRequest): void {
    if (this.schedulesForm.get('addSchedules')?.value) {
      const { riskSchedules } = coverageRequest;
      riskSchedules.forEach((riskSchedule, index) => {
        this.riskSchedules[index].isAdd = false;
      });
      this.scheduleTableNames.forEach((scheduleTableName) => {
        if(this.schedulesForm.get(scheduleTableName)?.value) {
          const sheduleTableRowsName = scheduleTableName + 'TableRows';
          this[sheduleTableRowsName].forEach((item) => {
            item.isAdd = false;
          });
        }
      });
    }
  }

  getCoveragesForms() {
    return {
      coveragesAndDeductiblesForm: this.coveragesAndDeductiblesForm,
      discountsForm: this.discountsForm,
      floodForm: this.floodForm,
      schedulesForm: this.schedulesForm
    };
  }

  defaultCoverageSetter(field: string, coverages: RiskCoverageDTO3) {
    switch (field) {
      case this.coverageField.coverageC:
        if (coverages.covBPercentageId.toString() === '0') {
          return coverages.covC === 0 && coverages.covD === 0 ? '50' : coverages.covCPercentageId;
        }
        return coverages.covCPercentageId;
      case this.coverageField.deductibleHUR:
        if (this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3) {
          return coverages.hurricaneDeductible.toString() === '0' ? '2' : coverages.hurricaneDeductible;
        } else {
          return coverages.hurricaneDeductible.toString() === '0' ? '1000' : coverages.hurricaneDeductible;
        }
      default:
        break;
    }
  }

  setCoveragePageFormStatus(status: boolean): void {
    FormUtils.setFormGroupStatus(this.coveragesAndDeductiblesForm, status);
    FormUtils.setFormGroupStatus(this.discountsForm, status);
    FormUtils.setFormGroupStatus(this.floodForm, status);
    FormUtils.setFormGroupStatus(this.schedulesForm, status);
    if (!status) {
      this.coveragesAndDeductiblesForm.get('coverageCLimitId').disable();
    }
    this.disableControlForExternalUser();
    this.disableControl();
    this.coveragesAndDeductiblesForm.get('creditOrdered').disable();
    if (this.torrentResponse?.isFloodZoneAvailableFromTorrent || this?.floodAssistRetrievalForm.get('isFloodZoneFromFloodAssist')?.value) {
      this.floodForm.get('floodZone').disable();
    }
    if (this.isNotRegularProgram()) { this.floodForm.get('privateFloodCoverage').disable(); }
    this.coveragesAndDeductiblesForm.get('isCoverageMEnabled').disable();
    if (this.isExternal && this.county?.toString().toLocaleLowerCase() === this.QuickQuoteFormConstant.Coverages.Monroe) {
      this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').disable();
    }

    this.setPrivateFloodCoverageStatus();

  }

  checkBooleanStorage(data: string): boolean {
    return data === 'true' ? true : false;
  }

  isNotRegularProgram(): boolean {
    return this.floodForm.get('participationStatusId').value !== ParticipationStatusConstants.regularProgram
      && this.floodForm.get('participationStatusId').value;
  }

  disableControlForExternalUser(): void {
    const isEditPolicy = this.checkBooleanStorage(localStorage.getItem('isEditPolicy'));
    if (this.isExternal || !isEditPolicy || this.disablePrivateFloodCoverage()) {
      this.floodForm.get('privateFloodCoverage').disable();
    }
  }

  disableControl(): void {
    if (this.isExternal) {
      this.floodForm.get('floodCoverageALimit').disable();
      this.floodForm.get('floodCoverageCLimit').disable();
      this.floodForm.get('floodDeductibleId').disable();
      this.floodForm.get('elevationCertificateId').disable();
      this.floodForm.get('participationStatusId').disable();
      if (this.policySummaryData.isPolicy) {
        this.floodForm.get('baseFloodElevation').disable();
        this.floodForm.get('lowestFloodElevation').disable();
      }
    } else {
      if (!this.floodZoneAList.includes(this.floodForm.get('floodZone')?.value) || this.disablePrivateFloodCoverage() === 'disabled') {
        this.floodForm.get('elevationCertificateId').disable();
      }
    }
  }

  public calculateCoverages(coverageA, yearBuilt?: number, effectiveDate?: any): void {
    if (this.quoteService.formType === this.formTypeConstants.HO3 && !this.isComparativeCoverageMapped) {
      if (!!!coverageA) {
        coverageA = 0;
      }
      const coveragesAndDeductiblesForm = this.coveragesAndDeductiblesForm;
      const floodCoverageCLimitValue = this.floodForm.get('floodCoverageCLimit');
      const floodCoverageALimitValue = this.floodForm.get('floodCoverageALimit');
      const coverageBLimitValue = Number(coveragesAndDeductiblesForm.get('coverageBLimitId').value / 100);
      const coverageCLimitValue = Number(coveragesAndDeductiblesForm.get('coverageCLimitId').value / 100);

      this.floodCovCLimitInputValue = floodCoverageCLimitValue.value;

      if (coverageA >= MINCOVA && coverageA <= MAXCOVA) {
        const coverageB: number = Utils.round(coverageA * coverageBLimitValue);
        const coverageC: number = Utils.round(coverageA * coverageCLimitValue);
        const coverageD: number = Utils.round(coverageA * 0.1);

        coveragesAndDeductiblesForm.get('coverageB').setValue(coverageA ? coverageB : 0);
        coveragesAndDeductiblesForm.get('coverageC').setValue(coverageA ? coverageC : 0);
        coveragesAndDeductiblesForm.get('coverageD').setValue(coverageA ? coverageD : 0);
      } else {
        const coverageB: number = Utils.round(this.validCovA * coverageBLimitValue);
        const coverageC: number = Utils.round(this.validCovA * coverageCLimitValue);
        const coverageD: number = Utils.round(this.validCovA * 0.1);

        coveragesAndDeductiblesForm.get('coverageB').setValue(this.validCovA ? coverageB : 0);
        coveragesAndDeductiblesForm.get('coverageC').setValue(this.validCovA ? coverageC : 0);
        coveragesAndDeductiblesForm.get('coverageD').setValue(this.validCovA ? coverageD : 0);
      }

      if (!this.riskCoveragesResponse) {
        this.checkAndValidateFloodCoverageC(Number(coveragesAndDeductiblesForm.get('coverageC').value), floodCoverageCLimitValue);
        this.checkAndValidateFloodCoverageA(Number(coverageA), floodCoverageALimitValue);
      }

      this.floodForm.get('floodCoverageALimit').updateValueAndValidity();
      this.floodForm.get('floodCoverageALimit').markAsTouched();
      this.floodForm.get('floodCoverageCLimit').markAsTouched();
      this.updateFloodCoverageCValidity();
      this.validateHurricaneDeductible(yearBuilt, effectiveDate);
      this.setRiskType();
    } else if (this.quoteService.formType === this.formTypeConstants.DP3  && !this.isComparativeCoverageMapped) {
      if (!!!coverageA) {
        coverageA = 0;
      }
      const coveragesAndDeductiblesForm = this.coveragesAndDeductiblesForm;
      const coverageBLimitValue = Number(coveragesAndDeductiblesForm.get('coverageBLimitId').value / 100);

      if (coverageA >= MINCOVA && coverageA <= MAXCOVA) {
        const coverageB: number = Utils.round(coverageA * coverageBLimitValue);
        const coverageDorE: number = Utils.round(coverageA * 0.1);

        coveragesAndDeductiblesForm.get('coverageB').setValue(coverageA ? coverageB : 0);
        coveragesAndDeductiblesForm.get('coverageDorE').setValue(coverageA ? coverageDorE : 0);
      } else {
        const coverageB: number = Utils.round(this.validCovA * coverageBLimitValue);
        const coverageDorE: number = Utils.round(coverageA * 0.1);

        coveragesAndDeductiblesForm.get('coverageB').setValue(this.validCovA ? coverageB : 0);
        coveragesAndDeductiblesForm.get('coverageDorE').setValue(this.validCovA ? coverageDorE : 0);
      }
    } else {
      this.calculateHO4HO6Coverages(Number(this.coveragesAndDeductiblesForm.get('coverageC').value));
    }
  }

  public checkAndValidateFloodCoverageC(coverageC, floodCoverageCLimitValue?) {
    if (coverageC && floodCoverageCLimitValue?.value) {
      if (coverageC > CoveragesLabelsConstants.flood.floodCovCMaxValue && floodCoverageCLimitValue?.value > CoveragesLabelsConstants.flood.floodCovCMaxValue) {
        this.floodForm.get('floodCoverageCLimit').setValue(CoveragesLabelsConstants.flood.floodCovCMaxValue);
      } else {
        if (floodCoverageCLimitValue?.value) {
          this.floodForm.get('floodCoverageCLimit').setValue(floodCoverageCLimitValue?.value);
        } else {
          this.floodForm.get('floodCoverageCLimit').setValue(coverageC);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      exit;
    }

    if (floodCoverageCLimitValue?.value === 0 && this.quoteService.formType !== this.formTypeConstants.HO3) {
      this.floodForm.get('floodCoverageCLimit').setValue(coverageC);
    }
  }

  public updateFloodCoverageCValidity() {
    this.floodForm.get('floodCoverageCLimit').updateValueAndValidity();
    this.floodForm.get('floodCoverageCLimit').markAsTouched();
  }

  public checkAndValidateFloodCoverageA(coverageA, floodCoverageALimitValue?) {
    if (coverageA && floodCoverageALimitValue?.value) {
      if (coverageA > CoveragesLabelsConstants.flood.floodCovAMaxValue && floodCoverageALimitValue?.value > CoveragesLabelsConstants.flood.floodCovAMaxValue) {
        this.floodForm.get('floodCoverageALimit').setValue(CoveragesLabelsConstants.flood.floodCovAMaxValue);
      } else {
        if (floodCoverageALimitValue?.value) {
          this.floodForm.get('floodCoverageALimit').setValue(floodCoverageALimitValue?.value);
        } else {
          this.floodForm.get('floodCoverageALimit').setValue(coverageA);
        }
      }
    }
  }

  updateCoverageB(coverageBLimitId) {
    const coverageAValue = this.coveragesAndDeductiblesForm.get('coverageA').value;
    const coverageBLimitValue = Number(this.coveragesAndDeductiblesForm.get('coverageBLimitId').value / 100);
    this.coveragesAndDeductiblesForm.get('coverageB').setValue(coverageAValue ? coverageAValue * coverageBLimitValue : 0);
  }

  updateCoverageC(coverageCLimitId) {
    const coverageAValue = Number(this.coveragesAndDeductiblesForm.get('coverageA').value);
    const coverageCLimitValue = Number(this.coveragesAndDeductiblesForm.get('coverageCLimitId').value / 100);
    this.userSelectedCovCId = coverageCLimitId;
    if (coverageCLimitId === '0') {
      this.coveragesAndDeductiblesForm.get('coverageC').setValue(0);
      this.floodForm.get('floodCoverageCLimit').setValue(0);
      this.hideFloodCoverageCInfoMessage(true);
    } else {
      this.coveragesAndDeductiblesForm.get('coverageC').setValue(coverageAValue ? coverageAValue * coverageCLimitValue : 0);
      this.checkAndValidateFloodCoverageC(this.coveragesAndDeductiblesForm.get('coverageC')?.value, this.floodForm.get('floodCoverageCLimit'));
    }
    this.updateFloodCoverageCValidity();
  }

  assignEstimatedCredit(creditScore, creditClaimTier) {
    const estimatedCreditCode: string = this.evaluateEstimatedCredit(
      {
        score: creditScore,
        tier: creditClaimTier
      }
    );

    //TODO: To refactor code below once Centauri 172 has been completed in Test envi
    this.coveragesAndDeductiblesForm.get('estimatedCreditId').setValue(estimatedCreditCode !== '' ? estimatedCreditCode : this.estimatedCode ?? 'EC5');
  }

  evaluateEstimatedCredit(model): string {
    let estimatedCreditCode: string = '';
    _.forEach(LvCreditScoreBrakcets, tier => {
      if (Number(tier) === Number(model.tier)) {
        _.forEach(tier.ranges, range => {
          // TODO: Correct the assignment. Create
          // a property with corresponding est. credit code
          // at LvCreditScoreBrakcets.
          if (model.score >= range.min && model.score <= range.max) {
            estimatedCreditCode = LvEstimatedCredit[0].code;
          } else {
            estimatedCreditCode = LvEstimatedCredit[0].code;
          }
        });
      }
    });
    return estimatedCreditCode;
  }

  showFloodZoneInfoMessage(floodZone: string): void {
    this.floodZoneVMessage = floodZone.toLocaleLowerCase().includes(FLOODCOVERAGEV);
  }

  public markCoveragesFormPageAsTouch(): void {
    FormUtils.markFormgroupAsTouch(this.coveragesAndDeductiblesForm);
    FormUtils.markFormgroupAsTouch(this.discountsForm);
    FormUtils.markFormgroupAsTouch(this.floodForm);
  }

  public policyCompareRiskScheduleTableData(parentRiskDetailData?: RiskDetailsDTO): boolean {
    let status = false;
    _.forEach(this.scheduleTableNames, (tableName) => {
      let parentScheduleRowData = parentRiskDetailData?.riskSchedules;
      if (parentScheduleRowData) {
        const scheduleStatus = this.schedulesForm.get(tableName).value;
        parentScheduleRowData = parentScheduleRowData.filter(a => a.tableId === tableName);
        if (scheduleStatus && parentScheduleRowData.length >= 0) {
          status = this.policyCompareParentScheduleTableRowData(tableName, parentScheduleRowData);
          if (status) {
            return false;
          }
        } else {
          if (parentScheduleRowData.length !== 0) {
            status = true;
            return false;
          }
        }
      }
    });
    return status;
  }

  public policyCompareParentScheduleTableRowData(tableName: string, parentData: RiskScheduleDTO[], is10kLimit: boolean = false): boolean {
    let status = false;
    let scheduleTableData: any = [];
    if (is10kLimit) {
      scheduleTableData = this[tableName + 'TableRows'].filter(a => a.tr[1].value >= Number(10000));
    } else {
      scheduleTableData = this[tableName + 'TableRows'];
    }

    if (scheduleTableData && parentData && scheduleTableData.length < parentData.length && !is10kLimit) {
      status = true;
    } else {
      _.forEach(scheduleTableData, (data, index) => {
        const scheduleId = data.id;
        const parentTableRow = parentData.find(a => a.tableId === tableName && a.id === scheduleId);

        if (parentTableRow) {
          status = data.tr[0]?.value !== parentTableRow.description || Number(data.tr[1]?.value) !== Number(parentTableRow.limit);
        } else {
          status = true;
        }

        if (status) {
          return false;
        }
      });
    }
    return status;
  }

  public calculateHO4HO6Coverages(coverageC?): void {
    switch (this.quoteService.formType) {
      case this.formTypeConstants.HO4:
        this.coveragesAndDeductiblesForm.get('coverageD').setValue(coverageC * this.genericContants.covDPercentage.covDHO4);
        break;
      case this.formTypeConstants.HO6:
        this.coveragesAndDeductiblesForm.get('coverageD').setValue(coverageC * this.genericContants.covDPercentage.covDHO6);
        break;
    }
  }

  updateRaterRequest(isFromBindAndIssue: boolean = false): void {
    const policyNumber = this.entityRiskData?.EntityRisk?.risks[0]?.policyNumber ?? '';
    if (!isFromBindAndIssue) {
      this.raterData.coverageRaterRequest(this, policyNumber);
    }
  }

  resetInfoMessageDefaults(): void {
    this.floodCovCInfoMessage = false;
    this.floodCovCInfoMessageExcluded = false;
    this.floodCovAInfoMessage = false;
    this.isTorrentPurchased = false;
  }

  populateFinancialResponsibility(creditScore: number): void {
    if (this.quoteService.formType === this.formTypeConstants.HO4) {
      if (this.policySummaryData.isPolicy) {
        const financialResValue = this.entityRiskData?.EntityRisk?.risks[0]?.riskDetails[0]?.riskDiscounts[0]?.financialResponsibility;
        this.discountsForm.get('financialResponsibilityId').setValue(financialResValue);
      } else {
        if (!this.isExternal) {
          if (!isNaN(creditScore)) {
            if (creditScore === -1) {
              this.discountsForm.get('financialResponsibilityId').setValue(LvFinancialResponsibility[1].code);
            } else if (creditScore < 700) {
              this.discountsForm.get('financialResponsibilityId').setValue(LvFinancialResponsibility[0].code);
            } else if (creditScore < 750) {
              this.discountsForm.get('financialResponsibilityId').setValue(LvFinancialResponsibility[1].code);
            } else {
              this.discountsForm.get('financialResponsibilityId').setValue(LvFinancialResponsibility[2].code);
            }
          }
        }
      }
    }
  }

  subscribeUserType(): void {
    this.authService.userType.subscribe((userType) => {
      this.isExternal = this.isExternalUser(userType);
    });
  }

  private isExternalUser(userType: string): boolean {
    return userType?.toLocaleLowerCase() === 'external';
  }

  clearCoverageLValidator(): void {
    const isCoverageLEnabled = this.coveragesAndDeductiblesForm.get('isCoverageLEnabled')?.value;
    if ((!this.isBrul53 && !this.isBrul54) || !isCoverageLEnabled) {
      this.coveragesAndDeductiblesForm.get('coverageL').clearValidators();
      this.coveragesAndDeductiblesForm.get('coverageL').updateValueAndValidity();
    }
  }

  applyCoverageLValidator(): void {
    const isCoverageLEnabled = this.coveragesAndDeductiblesForm.get('isCoverageLEnabled')?.value;
    if (isCoverageLEnabled) {
      if (this.isBrul53 || this.isBrul54) {
        const coverageL = this.coveragesAndDeductiblesForm.get('coverageL');
        if (coverageL.validator) {
          coverageL.setValidators([CoveragesValidators.coverageL100kMaxValue(), coverageL.validator]);
        } else {
          coverageL.setValidators([CoveragesValidators.coverageL100kMaxValue()]);
        }
        this.coveragesAndDeductiblesForm.get('coverageL').updateValueAndValidity();
        return;
      }
    }
    this.clearCoverageLValidator();
  }

  updateCoverageM(covL): void {
    if (covL !== 0) {
      this.coveragesAndDeductiblesForm.get('coverageM').setValue(covL === 100000 ? 1000 : 5000);
    }
  }

  intCoveragesHurricaneDeductibleInfoMessage(): void {
    this.hurricaneDeductibleExcludedInfoMessage = false;
    this.hurricaneDeductible5InfoMessage = false;
    this.hurricaneDeductible2InfoMessage = false;
    this.hurrDedExcludedDueToWaterInfoMessage = false;
    this.hurrDed5DueToWaterInfoMessage = false;
    this.hurrDed2DueToWaterInfoMessage = false;
    }

    get isCoverageLorMEnabled(): boolean {
        return this.coveragesAndDeductiblesForm?.get('isCoverageLEnabled')?.value;
    }

  isValidCovCValidDP3(covC: number, covCMin: number, covCMax: number): boolean {
    return covC >= covCMin && covC <= covCMax;
  }

  setPrivateFloodChildValidator(status: boolean): void {
    if (!status) {
      this.resetValidators();
      return;
    }

    const covCBaseValue = this.coveragesAndDeductiblesForm.get('coverageC');
    const covABaseValue = this.coveragesAndDeductiblesForm.get('coverageA');
    const centauriPrivateFlood = this.floodForm.get('privateFloodCoverage');
    const floodZone = this.floodForm.get('floodZone');
    const participationStatusId = this.floodForm.get('participationStatusId');
    const coverageCLimitId = this.coveragesAndDeductiblesForm.get('coverageCLimitId');

    this.setCoverageAlimitValidator(covABaseValue, centauriPrivateFlood, floodZone);
    this.setCoverageClimitValidator(covCBaseValue, centauriPrivateFlood, floodZone, coverageCLimitId);
    this.setParticipationStatusValidator(participationStatusId);
    this.setFirmDateValidator();
    this.setElevationCertValidator();
  }

  setCoverageAlimitValidator(covABaseValue, centauriPrivateFlood, floodZone): void {
    if (this.quoteService.formType !== FormTypeConstants.HO4) {
      this.floodForm.get('floodCoverageALimit')
      .setValidators([Validators.required,
      CoveragesValidators.floodCovAShouldNotBeGreaterThanBaseValueValidator(covABaseValue, centauriPrivateFlood),
      CoveragesValidators.floodCovAValidatorBaseOnFloodZone(floodZone),
      CoveragesValidators.floodCovAInfoMessage(),
      Validators.max(2000000)]);
    } else {
      this.floodForm.get('floodCoverageALimit')
      .setValidators([]);
    }
    this.floodForm.get('floodCoverageALimit').updateValueAndValidity();
  }

  setCoverageClimitValidator(covCBaseValue, centauriPrivateFlood, floodZone, coverageCLimitId): void {
    this.floodForm.get('floodCoverageCLimit')
      .setValidators([Validators.required,
      CoveragesValidators.floodCovCShouldNotBeGreaterThanBaseValueValidator(covCBaseValue, centauriPrivateFlood),
      CoveragesValidators.floodCovCValidatorBaseOnFloodZone(floodZone, coverageCLimitId, this.quoteService.formType),
      CoveragesValidators.floodCovCInfoMessage(),
      Validators.max(500000)]);
  }

  setParticipationStatusValidator(participationStatusId): void {
    this.floodForm.get('participationStatusId')
      .setValidators([Validators.required,
      CoveragesValidators.participationStatusIdShouldBeRegularProgram(participationStatusId)]);
    this.floodForm.get('participationStatusId').updateValueAndValidity();
  }

  setFirmDateValidator(): void {
    this.floodForm.get('firmDate').setValidators([Validators.required, CoveragesValidators.firmDateValidator(this.authService.getCustomDate())]);
    this.floodForm.get('firmDate').updateValueAndValidity();
  }

  setElevationCertValidator(): void {
    this.floodForm.get('elevationCertificateId').setValidators([Validators.required]);
    this.floodForm.get('elevationCertificateId').updateValueAndValidity();
  }

  resetValidators(): void {
    this.floodForm.get('elevationCertificateId').setValue('N');
    this.floodForm.get('floodCoverageALimit').clearValidators();
    this.floodForm.get('floodCoverageCLimit').clearValidators();
    this.floodForm.get('floodDeductibleId').clearValidators();
    this.floodForm.get('participationStatusId').clearValidators();
    this.floodForm.get('firmDate').setValue(null);
    this.floodForm.get('firmDate').clearValidators();
    this.floodForm.get('elevationCertificateId').clearValidators();
    this.floodForm.get('baseFloodElevation').clearValidators();
    this.floodForm.get('lowestFloodElevation').clearValidators();
    this.floodForm.get('elevationDifference').clearValidators();

    this.floodForm.get('floodCoverageALimit').updateValueAndValidity();
    this.floodForm.get('floodCoverageCLimit').updateValueAndValidity();
    this.floodForm.get('floodDeductibleId').updateValueAndValidity();
    this.floodForm.get('participationStatusId').updateValueAndValidity();
    this.floodForm.get('firmDate').updateValueAndValidity();
    this.floodForm.get('elevationCertificateId').updateValueAndValidity();
    this.floodForm.get('baseFloodElevation').updateValueAndValidity();
    this.floodForm.get('lowestFloodElevation').updateValueAndValidity();
    this.floodForm.get('elevationDifference').updateValueAndValidity();
  }

  subscribeToFloodValueChanges(): void {
    this.subscribeToPrivateFloodCoverage();
    this.subscribeToElevationCertificate();
    this.subscribeToFloodZone();
  }

  subscribeToPrivateFloodCoverage(): void {
    this.floodForm.get('privateFloodCoverage').valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
      if (!this.policySummaryData.isPolicy) {
        this.triggerPrivateFloodCovBR(value);
      } else {
        if (this.policySummaryData.isEditPolicy) {
          this.triggerPrivateFloodCovBR(value, true);
        }
      }
    });
  }

  triggerPrivateFloodCovBR(value, isForPolicy: boolean = false): void {
    if (!isForPolicy) {
      this.discountsForm.get('companion').setValue(value || this.isTorrentPurchased);
    } else {
      if (!this.policySummaryData.isPrivateFloodUpdated && this.policySummaryData.isEditPolicy) {
        this.policySummaryData.isPrivateFloodUpdated = true;
      } else {
        this.discountsForm.get('companion').setValue(value || this.isTorrentPurchased);
      }
    }

    this.floodForm.get('floodCoverageCLimit').updateValueAndValidity();
    this.floodForm.get('floodCoverageALimit').updateValueAndValidity();
    this.setRiskType();
    this.setPrivateFloodChildValidator(value);
  }

  subscribeToElevationCertificate(): void {
    this.floodForm.get('elevationCertificateId').valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
      const privateFlood = this.floodForm.get('privateFloodCoverage').value;

      if (value.toLocaleLowerCase() === 'y' && privateFlood) {

        this.floodForm.get('baseFloodElevation').setValidators([Validators.required]);
        this.floodForm.get('lowestFloodElevation').setValidators([Validators.required]);
        this.floodForm.get('elevationDifference').setValidators([Validators.required]);
      } else {
        this.floodForm.get('baseFloodElevation').markAsUntouched();
        this.floodForm.get('lowestFloodElevation').markAsUntouched();
        this.floodForm.get('elevationDifference').markAsUntouched();

        this.floodForm.get('baseFloodElevation').clearValidators();
        this.floodForm.get('lowestFloodElevation').clearValidators();
        this.floodForm.get('elevationDifference').clearValidators();
      }

      this.floodForm.get('baseFloodElevation').updateValueAndValidity();
      this.floodForm.get('lowestFloodElevation').updateValueAndValidity();
      this.floodForm.get('elevationDifference').updateValueAndValidity();
    });
  }

  subscribeToFloodZone(): void {
    this.floodForm.get('floodZone').valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
      const floodA = this.floodForm.get('floodCoverageALimit')?.value;
      const floodC = this.floodForm.get('floodCoverageCLimit')?.value;
      this.setRiskType();
    });

    this.onSelectFloodZone(this.floodForm.get('floodZone')?.value);
  }

  onSelectFloodZone(floodZone: string): void {
    this.showFloodZoneInfoMessage(floodZone);

    if (floodZone.toLocaleLowerCase() === FLOODCOVERAGEV) {
      this.resetFloodCoverageEndorsement();
      this.enablePrivateFloodStatus = false;
    } else {
      if (this.policySummaryData.isPolicy) {
        this.enablePrivateFloodStatus = !this.floodLossLocation && !this.isExternal ? true : false;
      } else {
        this.enablePrivateFloodStatus = !this.floodLossLocation ? true : false;
      }
    }

    this.floodForm.get('floodCoverageCLimit').updateValueAndValidity();
    this.floodForm.get('floodCoverageALimit').updateValueAndValidity();
  }

  isPolicyPageNotEditable(): boolean {
    return this.policySummaryData.isPolicy && !this.policySummaryData.isEditPolicy;
  }

  resetFloodCoverageEndorsement(): void {
    const floodZone = this.floodForm.get('floodZone').value;

    if (floodZone === 'V') {
      this.floodForm.get('privateFloodCoverage').setValue(false);
    }
  }

  setCoveragesPageDisabledFields(): void {
    if (this.isExternal && this.hurricaneDeductibleExcludedInfoMessage && !this.policySummaryData.isRenewal()) {
      this.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').disable();
    }
  }

  isExternalPolicyMode(): boolean {
    return this.policySummaryData.isPolicy && this.isExternal;
  }

  disablePrivateFloodCoverage(): string | null {
    return !this.enablePrivateFloodStatus
      || this.isNotRegularProgram()
      || this.isExternalPolicyMode()
      || this.isPolicyPageNotEditable()
      || this.removePrivateFloodCoverage()
      ? 'disabled' : null;
  }

  setPrivateFloodCoverageStatus(): void {
    const riskFlood = this.entityRiskData.EntityRisk?.risks[0]?.riskDetails[0]?.riskFloods[0];
    const floodLoss = this.floodForm.get('floodLossAtThisLocation').value;
    this.updateEnablePrivateFloodStatusValue();

    if (!this.enablePrivateFloodStatus) {
      const privateFloodStatus = riskFlood && !floodLoss ? riskFlood.isPrivateFloodCov : false;
      this.floodForm.get('privateFloodCoverage').setValue(privateFloodStatus);
    }

    this.floodForm.get('participationStatusId').updateValueAndValidity();
    this.disablePrivateFloodCoverage();
  }

  updateEnablePrivateFloodStatusValue(): void {
    const floodLoss = this.floodForm.get('floodLossAtThisLocation').value;
    const isFloodZoneV = this.floodForm.get('floodZone').value.toLocaleLowerCase() === FLOODCOVERAGEV ? true : false;
    this.enablePrivateFloodStatus = !isFloodZoneV ? !floodLoss : false;
  }

  checkIsActiveIPXBrul(checkIsActiveIPXBrulArgs: ICheckIsActiveIPXBrulArgs): void {
    const {
      yearBuilt: yearBuilt,
      firstIssueDate: firstIssueDate,
      effectiveDate: effectiveDate
    } = checkIsActiveIPXBrulArgs;

    if (yearBuilt) {
      const getFinalEffectiveDateArgs: IGetFinalEffectiveDate = {
        relatedRisks: this.relatedRisks,
        firstIssueDate: firstIssueDate,
        effectiveDate: effectiveDate
      };

      const finalEffectiveDate: any = this.getFinalEffectiveDate(getFinalEffectiveDateArgs);
      const ipxDateDiff: number = moment(this.ipxDate).diff(finalEffectiveDate, 'days');

      this.homeAge = finalEffectiveDate?.getFullYear() - yearBuilt;
      this.isActiveIPXBrul = ipxDateDiff <= 0;
    }
  }

  getFinalEffectiveDate(getFinalEffectiveDateArgs: IGetFinalEffectiveDate): any {
    const {
      relatedRisks,
      firstIssueDate,
      effectiveDate
    } = getFinalEffectiveDateArgs;

    let finalEffectiveDate: any;
    const relatedSubmissionFiltered: EntitySubmissionListDTO[] = relatedRisks?.filter(item => {
      return item.status !== this.statusConstants.cancelled;
    });
    const relatedRisksSorted: EntitySubmissionListDTO[] = _.orderBy(relatedSubmissionFiltered, 'firstIssueDate', 'asc');

    if (relatedRisks?.length) {
      finalEffectiveDate = this.entityRiskData.removeDateTime(relatedRisksSorted[0]?.firstIssueDate);
    } else {
      if (this.isRenewal) {
        if (this.isPolicy) {
          finalEffectiveDate = this.entityRiskData.removeDateTime(firstIssueDate?.singleDate?.jsDate);
        } else {
          finalEffectiveDate = this.entityRiskData.removeDateTime(this.entityRiskData.expiringPolicyEntityRisk?.risks[0]?.firstIssueDate);
        }
      } else if (this.isPolicy && !this.isRewrite) {
        finalEffectiveDate = this.entityRiskData.removeDateTime(firstIssueDate?.singleDate?.jsDate);
      } else if (this.isPolicy && this.isRewrite) {
        finalEffectiveDate = firstIssueDate?.singleDate?.jsDate;
      } else {
        finalEffectiveDate = effectiveDate?.jsDate ? effectiveDate.jsDate : effectiveDate?.singleDate?.jsDate;
      }
    }

    return finalEffectiveDate;
  }

  toggleSchedules(): void {
    const isAddSchedule = this.schedulesForm.get('addSchedules')?.value;
    if (!this.policySummaryData.isPolicy && isAddSchedule) {
      _.forEach(this.scheduleTableNames, scheduleTableName => {
        if(this.schedulesForm.get(scheduleTableName).value) {
          const sheduleTableRowsName = scheduleTableName + 'TableRows';
          _.forEach(this[sheduleTableRowsName], item => {
            item.isAdd = true;
          });
        }
      });
    }
  }

  toggleSchedulesChildTable(scheduleTableName: string): void {
    if (this.scheduleTableNames.includes(scheduleTableName)) {
      if(!this.policySummaryData.isPolicy && this.schedulesForm.get(scheduleTableName)?.value) {
        const sheduleTableRowsName = scheduleTableName + 'TableRows';
        _.forEach(this[sheduleTableRowsName], item => {
          item.isAdd = true;
        });
      }
    }
  }

  isValidForBRUL358(): boolean {
    let status = false;
    const t = sessionStorage.getItem('selectedEffectiveDate');
    const effectiveDate = this.policySummaryData.isPolicy ?
                  this.entityRiskData.getPolicyFirstIssueDate() :
                  new Date(sessionStorage.getItem('selectedEffectiveDate'));
    if (BRUL358COUNTY.includes(this.county)){
      if(new Date(effectiveDate) >= new Date(this.authService.brul358EffectiveDate)) {
        status = true;
      }
    }
    return status;
  }

  get isFL(): boolean {
    const flStateCode: string = 'FL';

    return this.entityRiskData?.getRisk()?.riskStateCode === flStateCode;
  }

  get covA(): number {
    const covACondition: boolean = this.coveragesAndDeductiblesForm.controls['coverageA'].value &&
      this.quoteService.formType === this.formTypeConstants.HO4;

    if (this.isPolicy && this.isFL) {
      return covACondition ? 0 :
        Number(this.validCovA);
    }

    return covACondition ? 0 :
      Utils.round(Number(this.validCovA));
  }

  get covBPayload(): number {
    if (this.isPolicy && this.isFL) {
      const covBPercentage: number = Number(this.coveragesAndDeductiblesForm.get('coverageBLimitId').value / 100);

      return Utils.round(Number(this.validCovA * covBPercentage), 2);
    }

    return Utils.round(Number(this.coveragesAndDeductiblesForm.controls['coverageB'].value));
  }

  get covCPayload(): number {
    if (this.isPolicy && this.isFL) {
      return this.validCovC ? Number(this.validCovC) : 0;
    }

    return this.validCovC ? Utils.round(Number(this.validCovC)) : 0;
  }

  get covDPayload(): number {
    if (this.isPolicy && this.isFL) {
      return Utils.round(Number(this.coveragesAndDeductiblesForm.controls['coverageD'].value), 2);
    }

    return Utils.round(Number(this.coveragesAndDeductiblesForm.controls['coverageD'].value));
  }

  get covLPayload(): number {
    if (this.isPolicy && this.isFL) {
      return Utils.round(Number(this.coveragesAndDeductiblesForm.controls['coverageL'].value), 2);
    }

    return Utils.round(Number(this.coveragesAndDeductiblesForm.controls['coverageL'].value));
  }

  get covMPayload(): number {
    if (this.isPolicy && this.isFL) {
      return Utils.round(Number(this.coveragesAndDeductiblesForm.controls['coverageM'].value), 2);
    }

    return Utils.round(Number(this.coveragesAndDeductiblesForm.controls['coverageM'].value));
  }

  get covDorEPayload(): number {
    if (this.isPolicy && this.isFL) {
      return Utils.round(Number(this.validCovA * 0.1), 2);
    }

    return Utils.round(this.validCovA * 0.1);
  }

  get isBRUL247Triggered(): boolean {
    if (this.quoteService.formType !== FormTypeConstants.HO6) { return false; }

    const effectiveDate = this.policySummaryData.isPolicy ? this.entityRiskData.getPolicyFirstIssueDate() : new Date(sessionStorage.getItem('selectedEffectiveDate'));
    const isFL = this.isFL ? this.isFL : JSON.parse((sessionStorage.getItem('formTypeAndState')))?.state === 'FL';
    const showMessage = isFL && new Date(effectiveDate) >= new Date(this.authService?.brul247EffectiveDate);
    const covC = this.coveragesAndDeductiblesForm.get('coverageC')?.value;
    const isPolicyOrRenewal = this.policySummaryData.isPolicy ||
      (this.policySummaryData.renewalCode !== null || this.policySummaryData.renewalStatusCode !== null);
    const covCBackUp = this.policySummaryData.isPolicy ?
      this.entityRiskData.ParentBackUpRiskData?.riskCoverages[0].covC :
      this.entityRiskData.renewalBackup?.riskCoverages[0].covC;

    return (!this.isExternal && showMessage && covC === 0) || (isPolicyOrRenewal && showMessage && covCBackUp === 0 && covC === 0);
  }

  removePrivateFloodCoverage(): boolean {
    const effectiveDate = this.policySummaryData.isPolicy ?
      this.entityRiskData.getPolicyFirstIssueDate() : new Date(sessionStorage.getItem('selectedEffectiveDate'));
    return this.isFL && this.isExternal && new Date(effectiveDate) >= new Date(this.authService?.privateFloodCoverageRemovalEffectiveDate);
  }

  isValidForNewMinimumDeductibleStateWide(): boolean {
    let effectiveDate: Date = null;
    if (this.policySummaryData.isPolicy) {
      effectiveDate = this.entityRiskData.getPolicyFirstIssueDate();
    } else {
      const cntrDate = new Date(sessionStorage.getItem('selectedEffectiveDate'));
      effectiveDate = new Date(cntrDate.getFullYear(), cntrDate.getMonth(), cntrDate.getDate());
    }
    return moment(effectiveDate).diff(this.authService.stateWideNewMinimumDeductibleEffectiveDate, 'days') >= 0;
  }

  get isPolicyOrRenewal(): boolean {
    return this.policySummaryData.isPolicy ||
      (this.policySummaryData.renewalCode !== null ||
      this.policySummaryData.renewalStatusCode !== null);
  }
}
