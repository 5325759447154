import { Component, OnInit } from '@angular/core';
import { BaseClass } from '../../../../../../shared/base-class';
import { SuspendedPaymentsLabel } from '../../../../../../shared/constants/suspended-payments.labels.constants';
import { ErrorMessageConstant } from '../../../../../../shared/constants/error-message.constants';
import { SuspendedPaymentsData } from '../../../../../../modules/payments/data/suspended-payments.data';
import { BsModalRef } from 'ngx-bootstrap';
import { FormGroup } from '@angular/forms';
import { SuspendedPaymentDetailsDTO } from '../../../../../../shared/models/data/dto/suspended-payment/suspended-payment-detail.dto';

@Component({
  selector: 'app-ipx-details-suspended-payment-modal',
  templateUrl: './ipx-details-suspended-payment-modal.component.html',
  styleUrls: ['./ipx-details-suspended-payment-modal.component.scss']
})
export class IpxDetailsSuspendedPaymentModalComponent extends BaseClass implements OnInit {
  public SuspendedPaymentsLabel = SuspendedPaymentsLabel;
  public ErrorMessageConstant = ErrorMessageConstant;

  paymentDetail: SuspendedPaymentDetailsDTO;
  hasReturnDetails: boolean;
  isEdit: boolean = false;

  constructor(
    public suspendedPaymentsData: SuspendedPaymentsData,
    private bsModalRef: BsModalRef
  ) {
    super();
  }

  ngOnInit() {
    this.populateFields();
  }

  get returnDetails(): FormGroup {
    return this.suspendedPaymentsData.detailsSuspendedFormGroup;
  }

  get updateSuspendedForm(): FormGroup {
    return this.suspendedPaymentsData.updateSuspendedFormGroup;
  }

  get isPolicyNumberChanged(): boolean {
    return this.paymentDetail.policyNumber !== this.updateSuspendedForm?.get('policyNumber')?.value && this.isEdit && this.suspendedPaymentsData.isPolicySearchDone && !this.suspendedPaymentsData.updateSuspendedFormGroup.invalid;
  }

  hideModal(): void {
    this.bsModalRef.hide();
    this.suspendedPaymentsData.resetDocumentVariables();
    this.suspendedPaymentsData.resetUpdateSuspendedForm();
  }

  populateFields(): void {
    this.initUpdateSuspendedForm();
  }

  onSave(): void {
    if (this.paymentDetail && this.hasReturnDetails) {
      this.suspendedPaymentsData.updateReturnDetails(this.paymentDetail.id);
      this.suspendedPaymentsData.updateReturnDetailsNotifier.subscribe(result => {
        if (result.isCompleted && !result.isError) {
          this.hideModal();
          this.suspendedPaymentsData.getReturnedSuspendedPaymentList();
        }
      });
    }
  }

  onCancel(): void {
    this.initUpdateSuspendedForm();
    this.isEdit = false;
  }

  initUpdateSuspendedForm(): void {
    this.updateSuspendedForm.get('policyNumber').setValue(this.paymentDetail.policyNumber);
    this.updateSuspendedForm.get('payerFirstName').setValue(this.paymentDetail.payer?.firstName);
    this.updateSuspendedForm.get('payerMiddleInitial').setValue(this.paymentDetail.payer?.middleName);
    this.updateSuspendedForm.get('payerLastName').setValue(this.paymentDetail.payer?.lastName);
    this.updateSuspendedForm.get('address1').setValue(this.paymentDetail.payer?.address1);
    this.updateSuspendedForm.get('address2').setValue(this.paymentDetail.payer?.address2);
    this.updateSuspendedForm.get('city').setValue(this.paymentDetail.payer?.city);
    this.updateSuspendedForm.get('state').setValue(this.paymentDetail.payer?.state);
    this.updateSuspendedForm.get('zipCode').setValue(this.paymentDetail.payer?.zip);
  }

  formatDate(date: string) {
    return !date ? '' :
      {
        isRange: false,
        singleDate: {
          jsDate: new Date(date)
        }
      };
  }

}
