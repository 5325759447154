import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InterestsComponent } from './interests.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../../../shared/shared.module';
import { PriorInsuranceComponent } from './prior-insurance/prior-insurance.component';
import { InterestPageModalComponent } from './interest-page-modal/interest-page-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CanDeactivateInterestsComponentGuard } from './interests-navigation-guard.service';

const routes: Routes = [
  // { path: '', component: InterestsComponent, canDeactivate: [CanDeactivateInterestsComponentGuard] }
  { path: '', component: InterestsComponent }
];

@NgModule({
  declarations: [InterestsComponent, PriorInsuranceComponent, InterestPageModalComponent],
  imports: [
    CommonModule,
    SharedModule,
    ModalModule.forRoot(),
    RouterModule.forChild(routes)
  ],
  exports: [
    InterestPageModalComponent,
  ],
  entryComponents: [
    InterestPageModalComponent
  ],
  providers: [CanDeactivateInterestsComponentGuard]
})
export class InterestsModule { }
