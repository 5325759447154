import { OfacSearchNotesModel } from './ofac-search-notes.model';

export interface IOfacSearchModel {
    isHit?: boolean;
    ofacNote?: OfacSearchNotesModel;
}
export class OfacSearchModel implements IOfacSearchModel {
    isHit?: boolean;
    ofacNote?: OfacSearchNotesModel;
    public constructor(init?: Partial<OfacSearchModel>) {
        Object.assign(this, init);
    }
}


