import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CommonService } from 'app/core/services/common.service';
import { Observable } from 'rxjs';
import { LvUWRenewalReviewModel } from '../models/lv-model/lv.uwrenewalreview.model';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class LvService {
  constructor(
    public http: HttpClient,
    protected commonService: CommonService
  ) {}

  getLvUWRenewalReview(): Observable<LvUWRenewalReviewModel[]> {
    return this.http.get(`${environment.ApiUrl}/Lv/lvuwrenewalreview`)
      .pipe(
        map(data => {
          return data as LvUWRenewalReviewModel[];
        }),
        catchError(this.commonService.handleObservableHttpError)
      );
  }
}