import { Injectable } from '@angular/core';
import { AgencyDTO } from 'app/shared/models/management/agency-management/agencyDto';
import { BaseClass } from 'app/shared/base-class';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NachaService } from 'app/core/services/nacha.service';
import { takeUntil } from 'rxjs/operators';
import Utils from 'app/shared/utilities/utils';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { CommissionStatementDTO } from 'app/shared/models/data/dto/nacha/commission-statement.dto';
import { FileSaverService } from 'ngx-filesaver';
import * as _ from 'lodash';
import { GenerateAgencyCommissionStatementRequestDTO, GenerateAgencyCommissionStatementResponseDTO, IGetFileResultDTO } from 'app/shared/models/data/dto/nacha/nacha.dto';
import { PagerService } from 'app/core/services/pagerService';
import { AuthService } from 'app/core/services/auth.service';
import { PolicyService } from 'app/core/services/submission/policy.service';

@Injectable({
    providedIn: 'root',
  })

export class CommissionStatementData extends BaseClass {

  agencyForm: FormGroup;
  archivedCommForm: FormGroup;

  public agencyList: AgencyDTO[];
  public agencyFirst12CommissionList: CommissionStatementDTO[];
  public agencyCommissionList: CommissionStatementDTO[];

  public priorCommissionStatementArchivedList: CommissionStatementDTO[];
  public priorCommissionStatementPageItem: CommissionStatementDTO[];
  public priorCommissionStatementPager: any = {};
  public priorCommissionStatementCurrentPage: number;

  public agencyNameList: any[];
  public selectedAgencyId: string;

  excelAllRequest: GenerateAgencyCommissionStatementRequestDTO[] = [];

  isCurrentCommissionLoading: boolean = true;
  isArchivedCommissionLoading: boolean = false;

  LvMonth: any = [
    {
      value: '01',
      description: 'January'
    },
    {
      value: '02',
      description: 'February'
    },
    {
      value: '03',
      description: 'March'
    },
    {
      value: '04',
      description: 'April'
    },
    {
      value: '05',
      description: 'May'
    },
    {
      value: '06',
      description: 'June'
    },
    {
      value: '07',
      description: 'July'
    },
    {
      value: '08',
      description: 'August'
    },
    {
      value: '09',
      description: 'September'
    },
    {
      value: '10',
      description: 'October'
    },
    {
      value: '11',
      description: 'November'
    },
    {
      value: '12',
      description: 'December'
    }
  ];

  LvYear: number[]= [];

  constructor(
    private fb: FormBuilder,
    public nachaService: NachaService,
    private fileSaverService: FileSaverService,
    public pagerService: PagerService,
    public authService: AuthService,
    private policyService: PolicyService,){
    super();
    this.setYearLv();
  }

  setYearLv(): void {
    const yearCounterLimit = 10;
    let yearCounter = 1;
    let initYear = 2021;
    do {
      this.LvYear.push(initYear);
      yearCounter += 1;
      initYear += 1;
    } while(yearCounterLimit >= yearCounter);
  }

  initializeReportHeaderForm(): void {
    this.agencyForm =  this.fb.group({
      agency: new FormControl(null, [Validators.required]),
    });
  }

  initializeArchivedCommForm(): void {
    this.archivedCommForm = this.fb.group({
      dateFrom: new FormControl(null, [Validators.required]),
      dateTo: new FormControl(null, [Validators.required])
    });
    if (!this.selectedAgencyId) {
      this.archivedCommForm.disable();
    }
  }

  hasChecked(data: CommissionStatementDTO[]): boolean {
    return data?.filter(a => a.isChecked).length !== 0;
  }

  getAgencyCommissionStatementList(agencyId: string): void {
    const currentDate = this.authService.getCustomDate();
    const prevMonthFirstDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const cprevMonthLastDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    const last12MonthFirstDate = new Date(prevMonthFirstDate.setMonth(prevMonthFirstDate.getMonth() - 12));
    const prevMonthLastDate = new Date(cprevMonthLastDate.setDate(cprevMonthLastDate.getDate() - 1));

    this.isCurrentCommissionLoading = true;
    this.resetArchivedData();
    this.isArchivedCommissionLoading = false;
    this.nachaService.agencyCommissionList(agencyId).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.agencyFirst12CommissionList =
      result
        .filter(a => new Date(a.year,a.month-1,1) >=  last12MonthFirstDate && new Date(a.year,a.month-1,1) <= prevMonthLastDate)
        .sort((a, b) => new Date(b.year,b.month-1,1).getTime() - new Date(a.year,a.month-1,1).getTime());
      this.isCurrentCommissionLoading = false;
      if (this.selectedAgencyId) {
        this.archivedCommForm?.enable();
      }
      Utils.unblockUI();
    }, (error) => {
      NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
      this.isCurrentCommissionLoading = false;
    });
  }

  downloadFileLocally(filePath: string, fileName: string, fileType: string): void {
    const filename = filePath.replace(/^.*[\\\/]/, '');
    Utils.customLoaderMessage(`Downloading ${fileName}${fileType}`);
    this.policyService.generateSASURL(filePath).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
      this.nachaService.downloadFile(resultSASURL).subscribe(res => {
        const byteCharacters = atob(res);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, {type: 'text/plain;charset=utf-16'});
        this.fileSaverService.save(blob, filename);
        Utils.unblockUI();
      }, error => {
        NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
      });
    });
  }

  generateAgencyCSVCommissionStatementFile(agencyId: string, month: number, year: number, fileName: string, csvFilePath: string): void {
    Utils.customLoaderMessage(`Downloading ${fileName}.csv`);
    this.downloadFileLocally(csvFilePath, fileName, '.csv');
    // this.nachaService.agencyGenerateCSVCommStatement(agencyId, month, year).pipe(takeUntil(this.stop$)).subscribe(result => {
    //   this.downloadFileLocally(result, fileName, '.csv');
    //   Utils.unblockUI();
    // }, (error) => {
    //   NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
    //   this.isCurrentCommissionLoading = false;
    // });
  }

  searchPriorCommissionStatementArchived(dateFrom: string, dateTo: string): void {
    const dateFromRequest = dateFrom.split('/');
    const dateToRequest = dateTo.split('/');

    const dateToC = new Date(Number(dateToRequest[1]), Number(dateToRequest[0]), 1);
    const dateFromC = new Date(Number(dateFromRequest[1]), Number(dateFromRequest[0]), 1);

    this.nachaService.agencyCommissionList(this.selectedAgencyId).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.priorCommissionStatementArchivedList = result.filter(a => new Date(a.year,a.month,1) >=  dateFromC && new Date(a.year,a.month,1) <= dateToC);
      this.isArchivedCommissionLoading = false;
      this.setPriorArchivePageItem(1);
    }, (error) => {
      NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
      this.isArchivedCommissionLoading = false;
      this.priorCommissionStatementArchivedList = [];
    });
  }

  getCommissionFileName(commStatement: CommissionStatementDTO): string {
    const month = this.LvMonth.find(a => a.value === ('0'+commStatement.month).slice(-2));
    return `${month.description} ${commStatement.year}`;
  }

  openCommissionStatement(filePath: string): void {
    this.policyService.generateSASURL(filePath).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
      window.open(resultSASURL);
    });
  }

  downloadAllSelectedCSVData(data: CommissionStatementDTO[]): void {
    if (data.filter(a => a.isChecked).length !== 0) {
      Utils.customLoaderMessage(`Downloading selected Excel file/s.`);
      this.excelAllRequest = [];
      _.forEach(data, (item: CommissionStatementDTO) => {
        const x = new GenerateAgencyCommissionStatementRequestDTO();
        x.agencyId = this.selectedAgencyId;
        x.month = item.month;
        x.year = item.year;
        this.excelAllRequest.push(x);
      });

      this.nachaService.agencyGenerateCSVCommStatements(this.excelAllRequest).subscribe(response  => {
        _.forEach(response, (res: GenerateAgencyCommissionStatementResponseDTO) => {
          const byteCharacters = atob(res.base64String);
          const byteArrays = [];
          for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }
          const blob = new Blob(byteArrays, {type: 'text/plain;charset=utf-16'});
          this.fileSaverService.save(blob, res.fileName);
        });
        Utils.unblockUI();
      }, error => {
        NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
        Utils.unblockUI();
      });
    }
  }

  downloadAllSelectedPDFData(data: CommissionStatementDTO[]): void {
    Utils.customLoaderMessage(`Downloading selected PDF file/s.`);
    if (data.filter(a => a.isChecked).length !== 0) {
      const requestBody = [];
      _.forEach(data, (item: CommissionStatementDTO) => {
        if (item.isChecked) {
          requestBody.push(item.statementPDF);
        }
      });
      this.nachaService.getFiles(requestBody).subscribe(res => {
        _.forEach(res, (response: IGetFileResultDTO) => {
          const byteCharacters = atob(response.base64String);
          const byteArrays = [];
          for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }
          const blob = new Blob(byteArrays, {type: 'text/plain;charset=utf-16'});
          const comm = data.find(a => a.statementPDF === response.statementPDF);
          const fileName = `${this.getCommissionFileName(comm)}.pdf`;
          this.fileSaverService.save(blob, fileName);
        });
        Utils.unblockUI();
      }, error => {
        NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
        Utils.unblockUI();
      });
    }
  }

  downloadSelectedCommissionFileData(data: CommissionStatementDTO[], fileType: string) {
    Utils.customLoaderMessage(`Downloading selected PDF file/s.`);if (data.filter(a => a.isChecked).length !== 0) {
      const urls = [];
      _.forEach(data, (item: CommissionStatementDTO) => {
        if (item.isChecked) {
          if (fileType === 'CSV') {
            urls.push(item.commissionACH.csvFilePath);
          } else if (fileType === 'PDF') {
            urls.push(item.statementPDF);
          }
        }
      });

      _.forEach(urls, (url: string) => {
        this.policyService.generateSASURL(url).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
          window.open(resultSASURL);
        },
        err => {
          Utils.unblockUI();
        },
        () =>
          Utils.unblockUI()
        );
      });
    }
  }

  setPriorArchivePageItem(page: number): void {
    if (page < 1) {
      return;
    }
    this.priorCommissionStatementArchivedList = this.priorCommissionStatementArchivedList.sort((a, b) => new Date(b.year,b.month-1,1).getTime() - new Date(a.year,a.month-1,1).getTime());
    this.priorCommissionStatementPager = this.pagerService.getPager(this.priorCommissionStatementArchivedList.length, page);
    this.priorCommissionStatementPageItem = this.priorCommissionStatementArchivedList.slice(this.priorCommissionStatementPager.startIndex, this.priorCommissionStatementPager.endIndex + 1);
    this.priorCommissionStatementCurrentPage = page;
    this.unCheckAllItem(this.priorCommissionStatementPageItem);
  }

  unCheckAllItem(list: CommissionStatementDTO[]): void {
    list.forEach(element => {
      element.isChecked = false;
    });
  }

  resetData(): void {
    this.agencyList = [];
    this.agencyFirst12CommissionList = [];
    this.agencyCommissionList = [];
    this.priorCommissionStatementPageItem = [];
    this.priorCommissionStatementArchivedList = [];
    this.priorCommissionStatementPager = {};
    this.priorCommissionStatementCurrentPage = 1;

    this.agencyNameList = [];
    this.selectedAgencyId = '';
  }

  resetArchivedData(): void {
    this.archivedCommForm?.reset();
    this.archivedCommForm?.markAsUntouched();
    this.priorCommissionStatementPageItem = [];
    this.priorCommissionStatementArchivedList = [];
    this.priorCommissionStatementPager = {};
    this.priorCommissionStatementCurrentPage = 1;
  }
}