import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import Utils from '../../utilities/utils';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileUploadConstants } from '../../constants/file-upload.constants';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements ControlValueAccessor, AfterViewChecked {

  constructor(private cdr: ChangeDetectorRef) { }
  fileUploadConstants = FileUploadConstants;

  @Input() selectedFileName = '';
  @Output() fileChange = new EventEmitter();
  @Input() isUploadEnabled: boolean = true;
  @Input() customFileTypes: string = '';
  @Input() isInValidFileType: boolean = false;

  private file: File | null = null;
  private onChange: any = () => { };
  private onTouched: any = () => { };

  dragOver: boolean = false;

  get acceptedFileTypes(): string {
    return this.customFileTypes.length > 0 ? this.customFileTypes : Utils.getAcceptedFileTypes();
  }

  get isFileUploaded(): boolean {
    return this.selectedFileName !== this.fileUploadConstants.noFileChosen &&
      this.selectedFileName !== undefined;
  }

  get uploadedFileCount(): string {
    return this.isFileUploaded ? this.fileUploadConstants.fileCountChosen : this.fileUploadConstants.noFileChosen; // Refactor when multiple upload is implemented
  }

  get invalidFileTypeMessage(): string {
    return this.fileUploadConstants.invalidFileTypeMessage.replace('{0}', this.selectedFileName);
  }

  writeValue(obj: any): void {
    this.file = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.triggerShowBorderCondition();
  }

  onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();

    this.triggerHideBorderCondition();
    if (this.isUploadEnabled) {
      this.selectedFileName = event.dataTransfer.files[0].name;
      this.fileChange.emit(event.dataTransfer.files);
    }
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.triggerHideBorderCondition();
  }

  triggerHideBorderCondition(): void {
    this.dragOver = false;
  }

  triggerShowBorderCondition(): void {
    this.dragOver = true;
  }
}