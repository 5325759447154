import { LvUWApprovalModel } from '../models/lv-model/lv.uwApproval.model';

export const LvUWApprovalCodeConstants = {
  uwr1: 'BR-COVA',
  uwr2: 'BR-EXCLWIND',
  uwr3: 'BR-MIN2500',
  uwr4: 'BR-LIMWATERCOV40YR',
  uwr5: 'BR-MINHUR5',
  uwr6: 'BR-NONWEALOSS',
  uwr7: 'BR-RATINGTIER',
  uwr8: 'BR-YRBUILTUP2020',
  uwr9: 'BR-REMWATER',
  uwr10: 'INE-COVA',
  uwr11: 'INE-COSTSQFOOT',
  uwr12: 'INE-DOGHISTORY',
  uwr13: 'INE-DWELLING75YR',
  uwr14: 'INE-FLOODNEGA',
  uwr15: 'INE-FLOODLOSSLOC',
  uwr16ho3: 'INE-HO3RENTALOCC',
  uwr16dp3: 'INE-DP3RENTALOCC',
  uwr17: 'INE-HO6FLOODREJEC',
  uwr18: 'INE-HOMEUP40YR',
  uwr19: 'INE-LIALOSS',
  uwr20: 'INE-UPTWOWATER',
  uwr21: 'INE-NOAIRCON',
  uwr22: 'INE-INSD100PERCNT',
  uwr23: 'INE-FLOODREJEC',
  uwr24: 'INE-PERPROREPAIR',
  uwr25: 'INE-STARPACK3YR',
  uwr26: 'INE-UNOCC6UPMONTH',
  uwr27: 'INE-VACANTUNOCCU',
  uwr28: 'INE-WATERLOSS10K',
  uwr29: 'INE-ACREAGE',
  uwr30: 'INE-ALUMWIRING',
  uwr31: 'INE-ARCH20YRUP',
  uwr32: 'INE-ASBESTOS',
  uwr33: 'INE-BARNOREL',
  uwr34: 'INE-BUILDCODE',
  uwr35: 'INE-COMMUSE',
  uwr36: 'INE-CONHOME',
  uwr37: 'INE-CORRROOF',
  uwr38: 'INE-DUPCOV',
  uwr39: 'INE-FEETHYDRNT',
  uwr40: 'INE-FLATROOF',
  uwr41: 'INE-FOUNDAGE',
  uwr42: 'INE-FOUNDTYPE',
  uwr43: 'INE-FUSES',
  uwr44: 'INE-HISTHOME',
  uwr45: 'INE-HOMEWATER',
  uwr46: 'INE-HOMEUSAGE',
  uwr47: 'INE-KNOBTUBEWRING',
  uwr48: 'INE-LACKELECUPDT',
  uwr49: 'INE-LACKHEATINGUPT',
  uwr50: 'INE-LACKHVACUPT',
  uwr51: 'INE-LACKPLUMUPT',
  uwr52: 'INE-LAPSECOV',
  uwr53: 'INE-LOCAIRCOMM',
  uwr54: 'INE-METALUP30YR',
  uwr55: 'INE-MILESSTATION',
  uwr56: 'INE-MISSINGHAND',
  uwr57: 'INE-MOBILEHOME',
  uwr58: 'INE-NOCNTRLHEAT',
  uwr59: 'INE-NOPAVEDACCESS',
  uwr60: 'INE-OPENCLAIM',
  uwr61: 'INE-OVERSHINGLE',
  uwr62: 'INE-PLUMMATERIAL',
  uwr63: 'INE-PREMODHOME',
  uwr64: 'INE-PRIOCONVIC',
  uwr65: 'INE-PROSINKHOLE',
  uwr66: 'INE-PUBLICPROCLASS',
  uwr67: 'INE-SHINGLEUP15YR',
  uwr68: 'INE-SLATEUP40YR',
  uwr69: 'INE-TILEUP40YR',
  uwr70: 'INE-TITLINGHOME',
  uwr71: 'INE-UNCONCONST',
  uwr72: 'INE-UNDERCONSREN',
  uwr73: 'INE-POOLPROTC',
  uwr74: 'INE-WINDEXCPOLICY',
  uwr75: 'INE-WOODSHKEROOF',
  uwr76: 'INE-WOODSHOVER',
  uwr77: 'PBR-CHSFLOLN',
  uwr78: 'PBR-EXTWALLFINISH',
  uwr79: 'PBR-FOUNDTYPECHND',
  uwr80: 'PBR-ROOFMATCHAND',
  uwr81: 'PBR-ROOFSHAPECHAND',
  uwr82: 'PBR-PERPROPERTYUP5K',
  uwr83: 'PBR-SQFOOTCHND',
  uwr84: 'PBR-YRBUILTCHND',
  uwr85: 'PBR-LLCTRUST',
  uwr86: 'RF-CENTAURIPOLREWRI',
  uwr87: 'RF-COVAUP1M',
  uwr88: 'RF-FOUNDELEVATION',
  uwr89: 'RF-HO3DUPLEXRENT',
  uwr90: 'RF-FUNGICOV50K',
  uwr91: 'RF-ELEVCERT',
  uwr92: 'RF-PRIORINSHIST',
  uwr93: 'RF-SCHDUP10KUWAPPVL',
  uwr94: 'RF-HO3PERPROUWAPPVL',
  uwr95: 'RF-SCHEDUWAPPVL',
  uwr96: 'RF-SINKHOLECOV',
  uwr97: 'RF-TWOUPPRIORLOSS',
  uwr98: 'RF-UNKNWNROOFMAT',
  uwr99: 'RF-WATERLOSS3YR',
  uwr100: 'RF-ZIPPRIVFLOODCOV',
  uwr101: 'RF-RENWALRULE',
  uwr102: 'RF-FLOODZONEVERI',
  uwr103: 'INE-SIDINGMAT',
  uwr104: 'RF-DISPUTEDLOSS',
  uwr105: 'RF-ADDVALFAILED',
  uwr106: 'RF-POLCHANGERENUWAPP',
  uwr107: 'RF-COVCGREATERHUNDREDK',
  uwr108: 'TEST-CODE',
  uwr109: 'RF-COVCGREATERTHREEHUNDEREDK',
  uwr110: 'INE-UNUSLIAEX',
  uwr111: 'INE-TRAMPPREM',
  uwr112: 'INE-DIVBOARD',
  uwr113: 'INE-POOLSLIDE',
  uwr114: 'BR-LIMWATDMGCOV30YR',
  uwr115: 'INE-HG30YRWATDMGEX',
  uwr116: 'REF-COVAUP750K',
  uwr117: 'REF-PERMTINOCCADD',
  uwr118: 'PBR-WINDMITFRM',
  uwr119: 'PBR-PROOFRENOV',
  uwr120: 'PBR-PROOFSPRINK',
  uwr121: 'PBR-WINDHAILEX',
  uwr122: 'PBR-CONTNTREJ',
  uwr124: 'REF-COVA-INC',
  uwr125: 'REF-COVA-DEC',
  uwr126: 'REF-CTR-PRES',
  uwr127: 'REF-LOW-DEDUC',
  uwr128: 'REF-REM-WINDEX',
  uwr129: 'REF-NAME-CHNG',
  uwr130: 'BIND-RES-LWD15',
  uwr131: 'REF-FLOOD-PREM',
  uwr132: 'BIND-SHRT-T-REN',
  uwr133: 'PBR-POL',
  uwr136: 'REF-MORETHAN1WEATHERLOSS',
  uwr137: 'INE-NEWNONWEATHERLOSS',
  uwr139: 'BIND-RES-SRIREQPB',
  uwr140: 'REF-REMOVINGACV',
  uwr141: '',
  uwr142: '',
  uwr143: '',
  uwr144: '',
  uwr145: '',
  uwr146: '',
  uwr147: '',
  uwr148: '',
  uwr149: '',
  uwr150: '',
  uwr151: 'BIND-RES-4PIREQPB',
  uwr152: 'BIND-RES-PROOFAGEPB',
  uwr154: 'INE-ACVHOME15ORMORE',
  fireReferralCode: 'FIRE-NONE',
  moratoriumCode: 'BND-SUSP-LOC',
  coverageFloodMoratoriumCode: 'COV-FLD-MOR',
  mor6: 'BIND-SUS-DUEHUR',
  closureCode: 'ZIP-CLOSEDEXPOSURE',
  closureUWCode: 'BND-SUSP-UW',
  uwr187: 'REF-LEVELMATCHPROTECTIONCLASS',
  uwr188: 'REF-LEVELMATCHWINDPOOL',
  uwr189: 'REF-LEVELMATCHDISTANCETOCOAST',
};

export const LvUWApprovals: LvUWApprovalModel[] = [
  {
    code: 'BR-COVA',
    description: 'Binding restriction: Coverage A limit less than minimum.'
  },
  {
    code: 'BR-EXCLWIND',
    description: 'Binding restriction: Risk must exclude wind.'
  },
  {
    code: 'BR-MIN2500',
    description: 'Binding restriction: All Other Peril deductible must be minimum of $2,500.'
  },
  {
    code: 'BR-LIMWATERCOV40YR',
    description: 'Binding restriction: Limited Water Damage Coverage on home more than 40 years old.'
  },
  {
    code: 'BR-MINHUR5',
    description: 'Binding restriction: Minimum hurricane deductible is 5%.'
  },
  {
    code: 'BR-NONWEALOSS',
    description: 'Binding restriction: Prior non-weather loss.'
  },
  {
    code: 'BR-RATINGTIER',
    description: 'Binding restriction: Rating tier.'
  },
  {
    code: 'BR-YRBUILTUP2020',
    description: 'Binding restriction: Year built must be 2002 or newer.',
    isRenewalUWR: true
  },
  {
    code: 'BR-REMWATER',
    description: 'Binding restriction: Water Damage Exclusion due to age of risk.'
  },
  {
    code: 'INE-COVA',
    description: 'Ineligible: Coverage A less than minimum.',
    isRenewalUWR: true
  },
  {
    code: 'INE-COSTSQFOOT',
    description: 'Ineligible: Does not meet minimum cost per square foot.'
  },
  {
    code: 'INE-DOGHISTORY',
    description: 'Ineligible: Dog history.'
  },
  {
    code: 'INE-DWELLING75YR',
    description: 'Ineligible: Dwelling older than 75 years.',
    isRenewalUWR: true
  },
  {
    code: 'INE-FLOODNEGA',
    description: 'Ineligible: Flood elevation is negative.'
  },
  {
    code: 'INE-FLOODLOSSLOC',
    description: 'Ineligible: Flood loss at location.'
  },
  {
    code: 'INE-HO3RENTALOCC',
    description: 'Ineligible: HO3 with rental occupancy.'
  },
  {
    code: 'INE-HO6FLOODREJEC',
    description: 'Ineligible: HO6 risks in flood zones starting with A or V require flood policy or flood rejection.'
  },
  {
    code: 'INE-HOMEUP40YR',
    description: 'Ineligible: Home greater than 40 years requires Water Damage Exclusion required due to age of home.'
  },
  {
    code: 'INE-LIALOSS',
    description: 'Ineligible: Liability loss.',
    isRenewalUWR: true
  },
  {
    code: 'INE-UPTWOWATER',
    description: 'Ineligible: More than two water losses in the past five years.',
    isRenewalUWR: true
  },
  {
    code: 'INE-NOAIRCON',
    description: 'Ineligible: No central air conditioning.',
    isRenewalUWR: true
  },
  {
    code: 'INE-INSD100PERCNT',
    description: 'Ineligible: Risk must be insured to 100% of the replacement cost.'
  },
  {
    code: 'INE-FLOODREJEC',
    description: 'Ineligible: Risks in flood zones starting with A or V require flood policy or flood rejection.'
  },
  {
    code: 'INE-PERPROREPAIR',
    description: 'Ineligible: Scheduled personal property items damaged or in need of repair.'
  },
  {
    code: 'INE-STARPACK3YR',
    description: 'Ineligible: Star Package options require home is loss free for the last three years.'
  },
  {
    code: 'INE-UNOCC6UPMONTH',
    description: 'Ineligible: Unoccupied 6 or more months.'
  },
  {
    code: 'INE-VACANTUNOCCU',
    description: 'Ineligible: Vacant or unoccupied.'
  },
  {
    code: 'INE-WATERLOSS10K',
    description: 'Ineligible: Water loss in excess of $10,000 in the past three years.',
    isRenewalUWR: true
  },
  {
    code: 'INE-ACREAGE',
    description: 'Ineligible: Acreage.'
  },
  {
    code: 'INE-ALUMWIRING',
    description: 'Ineligible: Aluminum wiring.'
  },
  {
    code: 'INE-ARCH20YRUP',
    description: 'Ineligible: Architectural or impact resistant shingles more than 20 years old.'
  },
  {
    code: 'INE-ASBESTOS',
    description: 'Ineligible: Asbestos.'
  },
  {
    code: 'INE-BARNOREL',
    description: 'Ineligible: Bars on windows no release.'
  },
  {
    code: 'INE-BUILDCODE',
    description: 'Ineligible: Building code.'
  },
  {
    code: 'INE-COMMUSE',
    description: 'Ineligible: Commercial use.'
  },
  {
    code: 'INE-CONHOME',
    description: 'Ineligible: Condition of home.'
  },
  {
    code: 'INE-CORRROOF',
    description: 'Ineligible: Corrugated or rolled roofing.'
  },
  {
    code: 'INE-DUPCOV',
    description: 'Ineligible: Duplicate Coverage.'
  },
  {
    code: 'INE-FEETHYDRNT',
    description: 'Ineligible: Feet to hydrant.'
  },
  {
    code: 'INE-FLATROOF',
    description: 'Ineligible: Flat roof.'
  },
  {
    code: 'INE-FOUNDAGE',
    description: 'Ineligible: Foundation age.'
  },
  {
    code: 'INE-FOUNDTYPE',
    description: 'Ineligible: Foundation type.'
  },
  {
    code: 'INE-FUSES',
    description: 'Ineligible: Fuses.'
  },
  {
    code: 'INE-HISTHOME',
    description: 'Ineligible: Historical home.'
  },
  {
    code: 'INE-HOMEWATER',
    description: 'Ineligible: Home located over water.'
  },
  {
    code: 'INE-HOMEUSAGE',
    description: 'Ineligible: Home usage.'
  },
  {
    code: 'INE-KNOBTUBEWRING',
    description: 'Ineligible: Knob and tube wiring.'
  },
  {
    code: 'INE-LACKELECUPDT',
    description: 'Ineligible: Lack of electrical update.'
  },
  {
    code: 'INE-LACKHEATINGUPT',
    description: 'Ineligible: Lack of heating system update.',
    isRenewalUWR: true
  },
  {
    code: 'INE-LACKHVACUPT',
    description: 'Ineligible: Lack of HVAC update.',
    isRenewalUWR: true
  },
  {
    code: 'INE-LACKPLUMUPT',
    description: 'Ineligible: Lack of plumbing update.',
    isRenewalUWR: true
  },
  {
    code: 'INE-LAPSECOV',
    description: 'Ineligible: Lapse in coverage.'
  },
  {
    code: 'INE-LOCAIRCOMM',
    description: 'Ineligible: Located in airpark community.'
  },
  {
    code: 'INE-METALUP30YR',
    description: 'Ineligible: Metal roof more than 30 years old.',
    isRenewalUWR: true
  },
  {
    code: 'INE-MILESSTATION',
    description: 'Ineligible: Miles to station.'
  },
  {
    code: 'INE-MISSINGHAND',
    description: 'Ineligible: Missing handrails.'
  },
  {
    code: 'INE-MOBILEHOME',
    description: 'Ineligible: Mobile, manufactured or modular home.'
  },
  {
    code: 'INE-NOCNTRLHEAT',
    description: 'Ineligible: No central heat.'
  },
  {
    code: 'INE-NOPAVEDACCESS',
    description: 'Ineligible: No paved access.'
  },
  {
    code: 'INE-OPENCLAIM',
    description: 'Ineligible: Open claim.'
  },
  {
    code: 'INE-OVERSHINGLE',
    description: 'Ineligible: Overlay shingles.'
  },
  {
    code: 'INE-PLUMMATERIAL',
    description: 'Ineligible: Plumbing  materials.'
  },
  {
    code: 'INE-PREMODHOME',
    description: 'Ineligible: Prefabricated or modular home.'
  },
  {
    code: 'INE-PRIOCONVIC',
    description: 'Ineligible: Prior conviction.'
  },
  {
    code: 'INE-PROSINKHOLE',
    description: 'Ineligible: Proximity to sinkhole.'
  },
  {
    code: 'INE-PUBLICPROCLASS',
    description: 'Ineligible: Public protection class.',
    isRenewalUWR: true
  },
  {
    code: 'INE-SHINGLEUP15YR',
    description: 'Ineligible: Shingle roof more than 15 years old.'
  },
  {
    code: 'INE-SLATEUP40YR',
    description: 'Ineligible: Slate roof more than 40 years old.'
  },
  {
    code: 'INE-TILEUP40YR',
    description: 'Ineligible: Tile roof more than 40 years old.'
  },
  {
    code: 'INE-TITLINGHOME',
    description: 'Ineligible: Titling of home.'
  },
  {
    code: 'INE-UNCONCONST',
    description: 'Ineligible: Unconventional construction.'
  },
  {
    code: 'INE-UNDERCONSREN',
    description: 'Ineligible: Under construction or renovation.'
  },
  {
    code: 'INE-POOLPROTC',
    description: 'Ineligible: Pool protection.'
  },
  {
    code: 'INE-WINDEXCPOLICY',
    description: 'Ineligible: Wind coverage excluded without separate wind policy.'
  },
  {
    code: 'INE-WOODSHKEROOF',
    description: 'Ineligible: Wood shingle or shake roof.'
  },
  {
    code: 'INE-WOODSHOVER',
    description: 'Ineligible: Wood shingles overlaid.'
  },
  {
    code: 'PBR-CHSFLOLN',
    description: 'Post bind refer: Signed Ordinance & Law Notice - less than 25%.',
    isRenewalUWR: true
  },
  {
    code: 'PBR-EXTWALLFINISH',
    description: 'Post bind refer: Exterior wall finish changed.'
  },
  {
    code: 'PBR-FOUNDTYPECHND',
    description: 'Post bind refer: Foundation type changed.'
  },
  {
    code: 'PBR-ROOFMATCHAND',
    description: 'Post bind refer: Roof material changed.'
  },
  {
    code: 'PBR-ROOFSHAPECHAND',
    description: 'Post bind refer: Roof shape changed.'
  },
  {
    code: 'PBR-PERPROPERTYUP5K',
    description: 'Post bind refer: Scheduled personal property Item limit > $5,000. Attach appraisal to quote/policy.'
  },
  {
    code: 'PBR-SQFOOTCHND',
    description: 'Post bind refer: Square footage changed.'
  },
  {
    code: 'PBR-YRBUILTCHND',
    description: 'Post bind refer: Year built changed.'
  },
  {
    code: 'PBR-LLCTRUST',
    description: 'Post bind refer: LLC or Trust.'
  },
  {
    code: 'RF-CENTAURIPOLREWRI',
    description: 'Refer: Centauri policy rewrite.'
  },
  {
    code: 'RF-COVAUP1M',
    description: 'Refer: Coverage A greater than $1,000,000.'
  },
  {
    code: 'RF-FOUNDELEVATION',
    description: 'Refer: Foundation elevation.'
  },
  {
    code: 'RF-HO3DUPLEXRENT',
    description: 'Refer: HO3 duplex with rental occupancy.'
  },
  {
    code: 'RF-FUNGICOV50K',
    description: 'Refer: Limited Fungi, Wet or Dry Rot or Bacteria Coverage at $50,000.'
  },
  {
    code: 'RF-ELEVCERT',
    description: 'Refer: Please provide elevation certificate.',
    isRenewalUWR: true
  },
  {
    code: 'RF-PRIORINSHIST',
    description: 'Refer: Prior insurance history.'
  },
  {
    code: 'RF-SCHDUP10KUWAPPVL',
    description: 'Refer: Scheduled personal property item limit > $10,000 requires prior UW approval.'
  },
  {
    code: 'RF-HO3PERPROUWAPPVL',
    description: 'Refer: HO3 scheduled personal property total schedule limit requires prior UW approval.'
  },
  {
    code: 'RF-SCHEDUWAPPVL',
    description: 'Refer: Scheduled personal property total schedule limit requires prior UW approval.'
  },
  {
    code: 'RF-SINKHOLECOV',
    description: 'Refer: Sinkhole coverage.'
  },
  {
    code: 'RF-TWOUPPRIORLOSS',
    description: 'Refer: Two or more prior losses.',
    isRenewalUWR: true
  },
  {
    code: 'RF-UNKNWNROOFMAT',
    description: 'Refer: Unknown roof material. Please provide roof material type to Underwriting.'
  },
  {
    code: 'RF-WATERLOSS3YR',
    description: 'Refer: Water loss in the past three years.',
    isRenewalUWR: true
  },
  {
    code: 'RF-ZIPPRIVFLOODCOV',
    description: 'Refer: Zip code for Private flood coverage.'
  },
  {
    code: 'RF-RENWALRULE',
    description: 'Renewal rule.',
    isRenewalUWR: true
  },
  {
    code: 'RF-FLOODZONEVERI',
    description: 'Refer: Flood zone verification needed.'
  },
  {
    code: 'INE-SIDINGMAT',
    description: 'Ineligible: Siding materials.'
  },
  {
    code: 'RF-DISPUTEDLOSS',
    description: 'Refer: Disputed loss.'
  },
  {
    code: 'RF-ADDVALFAILED',
    description: 'Refer: Address validation failed.'
  },
  {
    code: 'RF-POLCHANGERENUWAPP',
    description: 'Refer: Policy change close to renewal requires prior UW approval.'
  },
  {
    code: 'RF-COVCGREATERHUNDREDK',
    description: 'Refer: Coverage C greater than $100,000.'
  },
  // No current item provided for UWR 108 on current document Underwriting Refferals Version6 11/03/2021
  {
    code: 'TEST-CODE',
    description: 'NO DESCRIPTION'
  },
  {
    code: 'RF-COVCGREATERTHREEHUNDEREDK',
    description: 'Refer: Coverage A greater than $300,000.'
  },
  {
    code: 'INE-UNUSLIAEX',
    description: 'Ineligible: Unusual liability exposure.'
  },
  {
    code: 'INE-TRAMPPREM',
    description: 'Ineligible: Trampoline on premises.'
  },
  {
    code: 'INE-DIVBOARD',
    description: 'Ineligible: Diving board.'
  },
  {
    code: 'INE-POOLSLIDE',
    description: 'Ineligible: Pool slide.'
  },
  {
    code: 'BR-LIMWATDMGCOV30YR',
    description: 'Binding restriction: Limited Water Damage Coverage on home more than 30 years old.'
  },
  {
    code: 'INE-HG30YRWATDMGEX',
    description: 'Ineligible: Home greater than 30 years requires Water Damage Exclusion.'
  },
  {
    code: 'REF-COVAUP750K',
    description: 'Refer: Coverage A greater than $750,000.'
  },
  {
    code: 'REF-PERMTINOCCADD',
    description: 'Refer: Permitted Incidental Occupancy added.'
  },
  {
    code: 'PBR-WINDMITFRM',
    description: 'Post bind refer: Wind mitigation form.'
  },
  {
    code: 'PBR-PROOFRENOV',
    description: 'Post bind refer: Proof of renovations.'
  },
  {
    code: 'PBR-PROOFSPRINK',
    description: 'Post bind refer: Proof of sprinklers.'
  },
  {
    code: 'PBR-WINDHAILEX',
    description: 'Post bind refer: Wind/Hail exclusion.'
  },
  {
    code: 'PBR-CONTNTREJ',
    description: 'Post bind refer: Contents rejection.'
  },
  {
    code: 'ZIP-CLOSEDEXPOSURE',
    description: 'Zip code is closed for exposure management.'
  },
  {
    code: 'FIRE-NONE',
    description: 'Refers to "Fire" field in the Coverages > Discount section.'
  },
  {
    code: 'INE-DP3RENTALOCC',
    description: 'Ineligible: DP3 with rental occupancy.'
  },
  {
    code: 'BND-SUSP-LOC',
    description: 'Binding is currently suspended for this location.'
  },
  {
    code: 'REF-COVA-INC',
    description: 'Refer: Coverage A increase requires UW review.'
  },
  {
    code: 'REF-COVA-DEC',
    description: 'Refer: Coverage A decrease requires UW review.'
  },
  {
    code: 'REF-CTR-PRES',
    description: 'Refer: CTR is present on policy.'
  },
  {
    code: 'REF-LOW-DEDUC',
    description: 'Refer: Lowering deductible requires UW approval.'
  },
  {
    code: 'REF-REM-WINDEX',
    description: 'Refer: Removing wind exclusion requires UW approval.'
  },
  {
    code: 'REF-NAME-CHNG',
    description: 'Refer: Named insured changed.'
  },
  {
    code: 'BIND-RES-LWD15',
    description: 'Binding restriction: Limited Water Damage Coverage on home more than 15 years old.'
  },
  {
    code: 'COV-FLD-MOR',
    description: 'Flood Coverage is under a moratorium.'
  },
  {
    code: 'BIND-SHRT-T-REN',
    description: 'Binding restriction: Short-term rental.'
  },
  {
    code: 'REF-MORETHAN1WEATHERLOSS',
    description: 'Refer: More than one weather loss. Review for adding ACV or AOP deductible increase.'
  },
  {
    code: 'INE-NEWNONWEATHERLOSS',
    description: 'Ineligible: New non-weather loss (not water)'
  },
  {
    code: 'INE-ACVHOME15ORMORE',
    description: 'Ineligible: ACV endorsement on home 15 or more years old.'
  },
  {
    code: 'REF-REMOVINGACV',
    description: 'Refer: Removing ACV requires UW approval'
  },
  {
    code: 'BIND-RES-SRIREQPB',
    description: 'Binding restriction: Satisfactory roof inspection required prior to binding.'
  },
  {
    code: 'BIND-RES-4PIREQPB',
    description: 'Binding restriction - 4-point inspection required pre-bind.'
  },
  {
    code: 'BIND-RES-PROOFAGEPB',
    description: 'Binding restriction - Proof of roof age required pre-bind.'
  },
  {
    code: 'BIND-SUS-DUEHUR',
    description: 'Binding is suspended due to Hurricane Ian.'
  },
  {
    code: 'PBR-POL',
    description: 'Post bind refer: Proof of Lease.'
  },
  {
    code: 'REF-FLOOD-PREM',
    description: 'Refer: Flood Premium verification required.'
  },
  {
    code: 'BND-SUSP-UW',
    description: 'Binding is suspended for UW reasons'
  },
  {
    code: 'REF-LEVELMATCHPROTECTIONCLASS',
    description: 'Refer: Zip level match, verify protection class.'
  },
  {
    code: 'REF-LEVELMATCHWINDPOOL',
    description: 'Refer: Zip level match, verify wind pool eligibility.'
  },
  {
    code: 'REF-LEVELMATCHDISTANCETOCOAST',
    description: 'Refer: Zip level match, verify distance to coast.'
  }
];
