import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-returned',
  templateUrl: './returned.component.html',
  styleUrls: ['./returned.component.scss']
})
export class ReturnedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
