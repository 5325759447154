import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-transfer-batch-table',
  templateUrl: './transfer-batch-table.component.html',
  styleUrls: ['./transfer-batch-table.component.scss']
})
export class TransferBatchTableComponent implements OnInit {
  @Input() resultsPerPage: number = 10;
  @Input() tableHeaders: any;
  @Input() tableRows: any;
  @Output() editTableItem = new EventEmitter<any>();
  @Output() deleteTableItem = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onEdit(rowId: string): void {
    this.editTableItem.emit(rowId);
  }

  onDelete(rowId: string): void {
    this.deleteTableItem.emit(rowId);
  }
}
