export const ApplicantLabelsConstants = {
  firstName: 'First Name',
  middleName: 'Middle Name',
  LastName: 'Last Name',
  dateOfBirth: 'Date of Birth',
  retired: 'Retired?',
  maritalStatus: 'Marital Status',
  ssn: 'SSN',
  isApplicantsMailingAddressSameAsInsuredLocation: `Is applicant's mailing address same as Insured Location?`,
  mailingAddress: 'Mailing Address',
  aptUnit: 'Apt/Unit #',
  city: 'City',
  country: 'Country',
  state: 'State',
  zip: 'Zip Code',
  emailAddress: 'Email Address',
  mobilePhoneNumber: 'Mobile Phone Number',
  homePhoneNumber: 'Home Phone Number',
  isThereACoApplicant: 'Is there a co-applicant?',
  creditScoreStatus: 'Credit Score Status',
  creditScoreDateOrdered: 'Credit Score Date Ordered',
  relationshipToApplicant: 'Relationship to Applicant',
  isCoApplicantsAddressSameAsInsuredLocation: `Is co-applicant's address same as insured location?`,
  isCoApplicantsMailingAddressSameAsApplicantsMailingAddress: `Is co-applicant's mailing address same as applicant's mailing address?`,
  displayFCRALabel: 'FCRA Reporting Act',
  displayFCRAWording: `The Federal Fair Credit Reporting Act, Driver's Privacy Protection Act, analogous state laws, and other state and federal laws govern the use of reports delivered via the Centauri system. It is your responsibility to ensure that your requests are lawful. When ordering reports for underwriting purposes, you must have a completed application and/or the verbal request of the subject consumer.
  The Federal Fair Credit Reporting Act imposes criminal penalties - including a fine, up to two years in prison or both - against anyone who knowingly and willfully obtains information on a consumer from a consumer reporting agency under false pretenses, and other penalties for anyone who obtains such consumer information without a permissible purpose. `,
  IAgree: 'I AGREE',
  addressType: {
    mailingAddress: 'MA',
    propertyAddress: 'PR'
  },
  countryCode: {
    US: 'USA'
  },
  suffix: 'Suffix',
  inspectionOrdered: 'Inspection Ordered?',
  inspectionOrderDate: 'Inspection Order Date',
  inspectionType: 'Inspection Type',
  inspectionReceived: 'Inspection Received?',
  inspectionReceiptDate: 'Inspection Receipt Date',
  orderCredit: 'Order Credit'
};
