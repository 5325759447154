import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QuoteService {
  protected _formType: any;
  public formTypeObs$: Subject<string> = new Subject<string>();

  public get formType(): any {
    this.formTypeObs$.next(this._formType ?? JSON.parse((sessionStorage.getItem(environment.QuoteFormTypeAndState)))?.formType);
    return this._formType ?? JSON.parse((sessionStorage.getItem(environment.QuoteFormTypeAndState)))?.formType;
  }
  public set formType(value: any) {
    sessionStorage.setItem(
      environment.QuoteFormTypeAndState,
      JSON.stringify({ formType: value, state: this._state, masterProgramType: this._masterProgramType, companyName: this._companyName })
    );
    this._formType = value;
  }

  protected _state: any;
  public get state(): any {
    return this._state ?? JSON.parse((sessionStorage.getItem(environment.QuoteFormTypeAndState)))?.state;;
  }
  public set state(value: any) {
    sessionStorage.setItem(
      environment.QuoteFormTypeAndState,
      JSON.stringify({ formType: this._formType, state: value, masterProgramType: this._masterProgramType, companyName: this._companyName })
    );
    this._state = value;
  }

  protected _masterProgramType: any;
  public get masterProgramType(): any {
    return this._masterProgramType ?? JSON.parse((sessionStorage.getItem(environment.QuoteFormTypeAndState)))?.masterProgramType;;
  }
  public set masterProgramType(value: any) {
    sessionStorage.setItem(
      environment.QuoteFormTypeAndState,
      JSON.stringify({ formType: this._formType, state: this._state, masterProgramType: value, companyName: this._companyName })
    );
    this._masterProgramType = value;
  }

  protected _companyName: any;
  public get companyName(): any {
    return this._companyName ?? JSON.parse((sessionStorage.getItem(environment.QuoteFormTypeAndState)))?.companyName;
  }
  public set companyName(value: any) {
    sessionStorage.setItem(
      environment.QuoteFormTypeAndState,
      JSON.stringify({ formType: this._formType, state: this._state, masterProgramType: this._masterProgramType, companyName: value })
    );
    this._companyName = value;
  }

  constructor() {}

  savetoService(formType: any, state?: any, masterProgramType?: any, companyName?: any) {
    this.formType = formType;
    this.state = state;
    this.masterProgramType = masterProgramType;
    this.companyName = companyName;
  }
}
