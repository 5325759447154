import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { PaymentSummaryDTO } from '../../../../../../app/shared/models/data/dto/billing/payment-summary.dto';
import { PolicyBillingData } from '../../../../../../app/modules/policy-management/data/policy-billing.data';
import { ErrorMessageConstant } from '../../../../../../app/shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../../app/shared/constants/generic.labels.constants';
import { PolicyBillingLabelsConstants } from '../../../../../../app/shared/constants/policy-billing.labels.constants';
import { BsModalRef } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';
import { PaymentRequestDTO } from '../../../../../../app/shared/models/data/dto/billing/payment-request.dto';
import { BillingService } from '../../../../../../app/core/services/billing/billing.service';
import Utils from '../../../../../../app/shared/utilities/utils';
import NotifUtils from '../../../../../../app/shared/utilities/notif-utils';
import { switchMap, takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../../../../app/shared/base-class';
import { BillingPaymentInstrument } from '../../../../../../app/shared/enum/billing-payment-instrument';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { BillingValidators } from '../../../../../../app/modules/submission-management/validators/billing.validator';
import { BillingErrorConstants } from '../../../../../../app/modules/submission-management/validators/validator-error-constants/billing-error.constants';
import { EntityRiskData } from '../../../../../../app/modules/submission-management/data/entity-risk.data';
import { PolicyService } from '../../../../../../app/core/services/submission/policy.service';
import { PolicyNotesData } from '../../../../../../app/modules/policy-management/data/policy-notes.data';
import { AuthService } from 'app/core/services/auth.service';
import { AutoReinstatementData } from '../../../data/auto-reinstatement.data';

@Component({
  selector: 'app-write-off-modal',
  templateUrl: './write-off-modal.component.html',
  styleUrls: ['./write-off-modal.component.scss']
})
export class WriteOffModalComponent extends BaseClass implements OnInit {

  writeOffForm: FormGroup;
  WriteOffConstants = PolicyBillingLabelsConstants.writeOff;
  ErrorMessageConstant = ErrorMessageConstant;
  GenericLabel = GenericLabel;
  BillingErrorConstants = BillingErrorConstants;

  datePipe: DatePipe;

  currencyInputMode = CurrencyMaskInputMode.NATURAL;

  constructor(protected fb: FormBuilder,
    public bsModalRef: BsModalRef,
    protected billingData: PolicyBillingData,
    protected billingService: BillingService,
    protected entityRiskData: EntityRiskData,
    protected policyService: PolicyService,
    protected policyNotesData: PolicyNotesData,
    protected authService: AuthService,
    protected autoReinstatementData: AutoReinstatementData) {
    super();
  }

  ngOnInit() {
    this.datePipe = new DatePipe('en-US');

    this.writeOffForm = this.fb.group({
      method: new FormControl({ value: null, disabled: true }, [Validators.required]),
      amount: new FormControl(null, [Validators.required, BillingValidators.amountCannotBeZeroValidator()]),
      premium: new FormControl(null, [Validators.required]),
      tax: new FormControl(null, [Validators.required]),
      fee: new FormControl(null, [Validators.required]),
      comment: new FormControl('')
    });

    this.writeOffForm.get('method').setValue('writeOff');

    this.writeOffForm.get('amount').valueChanges.subscribe(totalAmount => {
      const isSurchargePopulated: boolean = (this.writeOffForm.get('tax').value + this.writeOffForm.get('fee').value) > 0;

      if (totalAmount && !isSurchargePopulated) {
        this.writeOffForm.get('premium').setValue(totalAmount);

        this.writeOffForm.get('tax').setValue(0);
        this.writeOffForm.get('fee').setValue(0);
      }
    });
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  get isAmountNotMatched(): boolean {
    const totalAmount = (this.writeOffForm.get('premium').value +
      this.writeOffForm.get('tax').value +
      this.writeOffForm.get('fee').value);

    return (totalAmount.toFixed(2) !== this.writeOffForm.get('amount').value?.toFixed(2));
  }

  onSave(): void {
    const writeOffRequest: PaymentRequestDTO = this.getWriteOffRequest();

    Utils.blockUI();
    this.billingService.postPaymentRequest(writeOffRequest)
      .pipe(
        takeUntil(this.stop$),
        switchMap(_ => this.policyService.getPolicyNotes(this.entityRiskData?.getRiskId()))
      ).subscribe(notes => {
        this.bsModalRef.hide();
        this.billingData.showSummary(writeOffRequest.riskId);
        this.billingData.listInstallmentInvoice(writeOffRequest.riskId);
        this.billingData.listPaymentsByRiskId(writeOffRequest.riskId);
        this.policyNotesData.populateNotes(notes);
        this.autoReinstatementData.populateAutoReinstateData();

        Utils.unblockUI();
        NotifUtils.showSuccess(PolicyBillingLabelsConstants.writeOffSuccess);
      },
        err => {
          Utils.unblockUI();
          NotifUtils.showMultiLineError(err.error?.message);
        }
      );
  }

  getWriteOffRequest(): PaymentRequestDTO {
    const paymentSummary: PaymentSummaryDTO = {
      amount: this.writeOffForm.get('amount').value,
      premiumAmount: this.writeOffForm.get('premium').value,
      taxAmount: this.writeOffForm.get('tax').value,
      feeAmount: this.writeOffForm.get('fee').value,

      comment: this.writeOffForm.get('comment').value,
      effectiveDate: this.datePipe.transform(this.authService.getCustomDate(), 'yyyy-MM-dd'),
      instrumentId: BillingPaymentInstrument.WriteOff,
    };

    const paymentRequest: PaymentRequestDTO = {
      riskId: this.entityRiskData.getRiskId(),
      riskDetailId: this.entityRiskData.getRiskDetailId(),
      paymentSummary: paymentSummary,
      isFromInsuredPaymentPortal: false
    };

    return paymentRequest;
  }
}
