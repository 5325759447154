import { createAction, props } from '@ngrx/store';
import { RiskStatus } from 'app/shared/models/data/dto/quick-quote/risk.dto';

export const updatePolicyIsLoadingFromPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update Policy Is Loading',
  props<{ isLoading: boolean }>()
);

export const updatePolicyStatusFromPolicyCancellation = createAction(
  '[PolicyCancellationData] Update Policy Status',
  props<{ status: RiskStatus }>()
);

export const updatePolicyStatusFromPolicyCancellationComponent = createAction(
  '[PolicyCancellationComponent] Update Policy Status',
  props<{ status: RiskStatus }>()
);

export const updatePolicyStatusFromCoveragesData = createAction(
  '[CoveragesData] Update Policy Status',
  props<{ status: RiskStatus }>()
);

export const updatePolicyStatusFromEndorsementsSavingData = createAction(
  '[EndorsementsSavingData] Update Policy Status',
  props<{ status: RiskStatus }>()
);

export const updatePolicyStatusFromSummaryData = createAction(
  '[SummaryData] Update Policy Status',
  props<{ status: RiskStatus }>()
);

export const updatePolicyStatusFromUwApprovalData = createAction(
  '[UWApprovalData] Update Policy Status',
  props<{ status: RiskStatus }>()
);

export const updatePolicyStatusFromApplicantPage = createAction(
  '[ApplicantPageComponent] Update Policy Status',
  props<{ status: RiskStatus }>()
);

export const updatePolicySubStatusFromCoveragesData = createAction(
  '[CoveragesData] Update Policy Sub-status',
  props<{ subStatus: RiskStatus }>()
);

export const updatePolicySubStatusFromEndorsementsSavingData = createAction(
  '[EndorsementsSavingData] Update Policy Sub-status',
  props<{ subStatus: RiskStatus }>()
);

export const updatePolicySubStatusFromSummaryData = createAction(
  '[SummaryData] Update Policy Sub-status',
  props<{ subStatus: RiskStatus }>()
);

export const updatePolicySubStatusFromUwApprovalData = createAction(
  '[UWApprovalData] Update Policy Sub-status',
  props<{ subStatus: RiskStatus }>()
);

export const updatePolicySubStatusFromApplicantPage = createAction(
  '[ApplicantPageComponent] Update Policy Sub-status',
  props<{ subStatus: RiskStatus }>()
);

export const updatePolicyIsLoadingOosFromPolicyIssueData = createAction(
  '[PolicyIssueData] Update Policy Is Loading OOS',
  props<{ isLoadingOos: boolean }>()
);

export const updatePolicyIsLoadingOosFromPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update Policy Is Loading OOS',
  props<{ isLoadingOos: boolean }>()
);

export const updateEntityIsLoadingFromPolicyBillingData = createAction(
  '[PolicyBillingData] Update Entity Is Loading',
  props<{ isLoading: boolean }>()
);