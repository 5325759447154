import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import Utils from '../../shared/utilities/utils';
import { PolicyService } from '../../core/services/submission/policy.service';
import { BaseClass } from '../../shared/base-class';
import { takeUntil, distinctUntilChanged, take, pairwise, startWith, map, filter } from 'rxjs/operators';
import { LayoutService } from '../../core/services/layout/layout.service';
import { ApplicantData } from '../submission-management/data/applicant.data';
import { BindAndIssueData } from '../submission-management/data/bind-and-issue.data';
import { ClaimsData } from '../submission-management/data/claims.data';
import { CoveragesData } from '../submission-management/data/coverages.data';
import { EndorsementsData } from '../submission-management/data/endorsements.data';
import { InterestData } from '../submission-management/data/interest.data';
import { PropertyData } from '../submission-management/data/property.data';
import { SummaryData } from '../submission-management/data/summary.data';
import { UwQuestionsData } from '../submission-management/data/uw-questions.data';
import { PolicySummaryData } from './data/policy-summary.data';
import { PolicyBillingData } from './data/policy-billing.data';
import { createPolicyDetailsMenuItems } from './policy-management-navitems';
import { EntityRiskData } from '../submission-management/data/entity-risk.data';
import { EntityRiskDTO } from '../../shared/models/data/dto/quick-quote/entity-risk.dto';
import { UwApprovalData } from '../submission-management/data/uw-approval.data';
import { PathConstants } from '../../shared/constants/path.constants';
import * as _ from 'lodash';
import { PolicyNavValidationService } from '../../core/services/navigation/policy-nav-validation.service';
import { PolicyNavSavingService } from '../../core/services/navigation/policy-nav-saving.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { PolicyDocumentsData } from './data/policy-documents.data';
import { UWR } from 'app/core/services/submission/uw-referrals/uwr.service';
import { ThirdPartyData } from '../submission-management/data/third-party.data';
import { ZipCodeData } from '../submission-management/data/zipcode.data';
import { PolicyNotesData } from './data/policy-notes.data';
import { PolicyIssueData } from './data/policy-issue.data';
import { PolicyHistoryData } from './data/policy-history-data';
import { combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { GenericConstants } from '../../shared/constants/generic.constants';
import { QuoteService } from '../../core/services/quote.service';
import { RiskDetailsDTO } from '../../shared/models/data/dto/quick-quote/risk-details.dto';
import { AuthService } from '../../core/services/auth.service';
import { TaskData } from '../dashboard/data/task.data';
import { QuickQuoteData } from '../submission-management/data/quick-quote.data';
import { CalculateRaterData } from '../../core/services/submission/rater-premium/calculate-rater-data.service';
import { EndorsementsSavingData } from '../submission-management/data/endorsements.saving.data';
import { SubmissionCopiedInformation } from 'app/shared/models/submission/applicant/submission-copied-information';
import { FormTypeConstants } from '../../shared/constants/form-types.constants';
import { IPolicyNavDetails, NavData } from '../../_nav';
import { SubmissionPageData } from '../submission-management/data/submission-page.data';
import * as moment from 'moment';
import { PageHeaderSummaryConstants } from 'app/shared/constants/page-header-summary.constants';
import { LvRiskStatus } from 'app/shared/constants/risk-status';
import { RiskStatus, RiskStatusCode } from 'app/shared/models/data/dto/quick-quote/risk.dto';
import { UwApprovalSavingData } from 'app/modules/submission-management/data/uw-approval-saving.data';
import { ReportsService } from 'app/core/services/reports/reports.service';
import { AutoReinstatementData } from './data/auto-reinstatement.data';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { UserService } from 'app/core/services/management/user.service';
import { UserDTO } from 'app/shared/models/management/user';
import { ExcludedZipCodeList } from '../../shared/constants/excluded.zipcode.list';
import { MoratoriumService } from '../../core/services/moratorium.services';
import { MoratoriumType, MoratoriumType2 } from '../../shared/constants/moratoriumType';
import { LvUWApprovals } from 'app/shared/constants/uw.referrals';
import { Rater } from 'app/shared/constants/rater.constant';
import { PropertyBusinessRules } from '../submission-management/business-rules/property.business-rules';
import { HORaterRequestData } from 'app/core/services/submission/rater-premium/ho-rater-request-data.service';
import { select, Store } from '@ngrx/store';
import { selectPolicySubStatus } from '../../store/policy/policy.selectors';
import { AllRiskBindNoteDTO } from 'app/shared/models/data/dto/policy-management/all-risk-bind-note.dto';
import { RequestObjectMoratorium } from 'app/shared/models/submission/quick-quote/moratoriums.model';
import {
  updatePolicyNotesIsLoadingFromPolicyManagementComponent,
  updatePolicyNotesListFromPolicyManagementComponent
} from '../../store/policy-notes/policy-notes.actions';
import { selectPolicyNotesList } from '../../store/policy-notes/policy-notes.selectors';
import { updatePolicyIsLoadingFromPolicyManagementComponent } from 'app/store/policy/policy.actions';
import {
  updateAppIsConfirmationFromPolicyManagementComponent,
  updateAppIsLoadingFromPolicyManagementComponent
} from 'app/store/app/app.actions';
import { selectPolicyUWRsTableRows } from 'app/store/policy-uwrs/policy-uwrs.selectors';
import { ITableTr } from 'app/shared/models/dynamic/table.interface';
import { selectMoratoriumIsLoading } from 'app/store/moratorium/moratorium.selectors';
import { EntitySubmissionListDTO } from 'app/shared/models/data/dto/submission-list/entity-submission-list.dto';
import { selectRelatedRiskstList } from 'app/store/related-risks/related-risks.selectors';
import { FormDocumentService } from '../../core/services/submission/report/form-document.service';
import { selectRaterCalculateDP3IsLoading } from 'app/store/rater-calculate-dp3/rater-calculate-dp3.selectors';
import { selectPolicyIsLoading } from 'app/store/policy/policy.selectors';
import { selectInterestsList } from 'app/store/interests/interests.selectors';
import { selectRaterIsLoading } from 'app/store/rater/rater.selectors';
import {
  updateMoratoriumIsLoadingFromPolicyManagementComponent,
  updateMoratoriumListFromPolicyManagementComponent
} from 'app/store/moratorium/moratorium.actions';
import {
  updateBackupDataFormPolicyManagementComponent,
  updateBackupIsLoadingFormPolicyManagementComponent
} from 'app/store/data-backup/data-backup.actions';
import { selectBackupIsLoading } from 'app/store/data-backup/data-backup.selectors';
import { updateRaterIsLoadingFromPolicyManagementComponent } from 'app/store/rater/rater.actions';
import { updateInterestsListFromPolicyManagementComponent } from 'app/store/interests/interests.actions';
import {
  updateChangesIsLoadingFromPolicyManagementComponent,
  updateChangesListFromPolicyManagementComponent
} from 'app/store/changes/changes.actions';
import { updateClaimsListFromPolicyManagementComponent } from 'app/store/claims/claims.actions';
import { updateCoveragesSchedulesFromPolicyManagementComponent } from 'app/store/coverages/coverages.actions';
import { LvService } from 'app/shared/services/lv-service';
import { LvData } from 'app/shared/lv.data';
import { LvUWRenewalReviewModel } from 'app/shared/models/lv-model/lv.uwrenewalreview.model';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { Location } from '@angular/common';

type RenewalSubStatusCode = null | 'CRW' | 'SFNRW' | 'NRWD';
type RenewalStatusCode = null | 'RNPEN' | 'RWO' | 'RRW' | 'RRNPEN' | 'RRWO';

interface INotesCategory {
  tableName: string;
  tableDescription: string;
  code: string;
  description: string;
  isActive: boolean;
}

@Component({
  selector: 'app-policy-management',
  templateUrl: './policy-management.component.html',
  styleUrls: ['./policy-management.component.scss']
})
export class PolicyManagementComponent extends BaseClass implements OnInit, OnDestroy {
  protected selectPolicyIsLoading$: Observable<boolean>;
  protected selectPolicySubStatus$: Observable<RiskStatus>;
  protected selectPolicyNotesList$: Observable<AllRiskBindNoteDTO[]>;
  protected selectPolicyUWRsTableRows$: Observable<ITableTr[]>;
  protected selectRelatedRiskstList$: Observable<EntitySubmissionListDTO[]>;
  protected selectRaterCalculateDP3IsLoading$: Observable<boolean>;
  protected selectInterestsList$: Observable<any>;
  protected selectRaterIsLoading$: Observable<boolean>;
  protected selectMoratoriumIsLoading$: Observable<boolean>;
  protected selectBackupIsLoading$: Observable<boolean>;

  protected riskId: string;
  protected riskDetailId: string;
  protected isParentEntityCompleted = new Subject<boolean>();
  protected isParentEntityCompleted$ = this.isParentEntityCompleted.asObservable();
  public genericConstants = GenericConstants;
  protected reset$ = new Subject();
  public LvRiskStatus = LvRiskStatus;

  // upon navigation saving
  protected previousCategoryRoute: string;
  protected newCategoryRoute: string;
  protected currentCategoryRoute: string;
  formTypeConstants = FormTypeConstants;
  protected pageHeaderConstants = PageHeaderSummaryConstants;

  protected moratoriumChecked: boolean = false;

  protected showUWQuestionRestricted$: Subject<boolean> = new Subject();

  constructor(
    protected layoutService: LayoutService,
    protected summaryData: SummaryData,
    protected coveragesData: CoveragesData,
    protected applicantData: ApplicantData,
    protected bindAndIssueData: BindAndIssueData,
    protected propertyData: PropertyData,
    protected endorsementsData: EndorsementsData,
    protected endorsementSavingData: EndorsementsSavingData,
    protected uwQuestionsData: UwQuestionsData,
    protected interestData: InterestData,
    protected claimsData: ClaimsData,
    protected policySummaryData: PolicySummaryData,
    protected route: ActivatedRoute,
    public router: Router,
    public policyService: PolicyService,
    public entityRiskData: EntityRiskData,
    public uwApprovalData: UwApprovalData,
    public raterData: CalculateRaterData,
    protected policyBillingData: PolicyBillingData,
    public policyNavSavingService: PolicyNavSavingService,
    public policyNavValidationService: PolicyNavValidationService,
    protected storage: LocalStorageService,
    protected policyDocumentsData: PolicyDocumentsData,
    protected uwr: UWR,
    protected thirdPartyData: ThirdPartyData,
    protected zipCodeData: ZipCodeData,
    protected policyNotesData: PolicyNotesData,
    protected policyIssueData: PolicyIssueData,
    protected policyHistoryData: PolicyHistoryData,
    protected quoteService: QuoteService,
    protected authService: AuthService,
    protected taskData: TaskData,
    protected quickQuoteData: QuickQuoteData,
    public submissionData: SubmissionPageData,
    protected uwApprovalSavingData: UwApprovalSavingData,
    protected reportsService: ReportsService,
    protected autoReinstatementData: AutoReinstatementData,
    protected userService: UserService,
    protected moratoriumService: MoratoriumService,
    protected propertyBusinessRules: PropertyBusinessRules,
    protected hoRaterRequestData: HORaterRequestData,
    protected store: Store,
    protected formDocumentService: FormDocumentService,
    protected lvService: LvService,
    protected lvData: LvData,
    protected location: Location
  ) {
    super();
    this.selectPolicyIsLoading$ = this.store.pipe(select(selectPolicyIsLoading));
    this.selectPolicySubStatus$ = this.store.pipe(select(selectPolicySubStatus));
    this.selectPolicyNotesList$ = this.store.pipe(select(selectPolicyNotesList));
    this.selectPolicyUWRsTableRows$ = this.store.pipe(select(selectPolicyUWRsTableRows));
    this.selectMoratoriumIsLoading$ = this.store.pipe(select(selectMoratoriumIsLoading));
    this.selectRelatedRiskstList$ = this.store.pipe(select(selectRelatedRiskstList));
    this.selectRaterCalculateDP3IsLoading$ = this.store.pipe(select(selectRaterCalculateDP3IsLoading));
    this.selectInterestsList$ = this.store.pipe(select(selectInterestsList));
    this.selectBackupIsLoading$ = this.store.pipe(select(selectBackupIsLoading));
    this.selectRaterIsLoading$ = this.store.pipe(select(selectRaterIsLoading));
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    this.removeRaterStorage(this.entityRiskData.getRiskDetailId());
  }

  ngOnInit() {
    this.initData();
    this.subcribeToDataReset();
    this.subscribeToUwApprovalTableRowsSubject();
    this.subscribeToUwApprovalListComplete();
    this.subscribeToInterestDataPopulate();
    this.policyIssueData.isEditButtonClicked$.pipe(takeUntil(this.stop$)).subscribe(()=>{
      this.uwr.getPolicyUwrReferrals();
    });
    this.subscribeToFloodValueChanges();
    this.subscribeToPolicyPageSaving();
    this.subscribeToPolicyNavOutSavingCounter();
    this.subcribeToIsDonePopulatingForms();
    this.subscribeToRenewalSubStatusCode();
    this.subscribeToUwrSavedData();
    this.getPolicyChanges();
    this.subscribeToPolicySubStatus$();
    this.subscribeToPolicyNotesList$();
    this.subscribeToSelectRelatedRiskstList$();
    this.subscribeToIsCovAAndReplacementCostTheSame$();
    this.subscribeUWRPopulated$();
    this.subscribeToPolicySubStatusCode$();
    this.subscriptToShowUWRestricted$();
  }

  ngOnDestroy() {
    this.policySummaryData.isEditPolicy = false;
    localStorage.setItem('isEditPolicy', 'false');
    this.removeRaterStorage(this.entityRiskData.getRiskDetailId());
    this.coveragesData.resetInfoMessageDefaults();
    this.propertyData.resetStoredValues();
    this.reset$.next();
    this.reset$.complete();
    this.summaryData.showPendingEndorsementStatus = false;

    this.claimsData.isClaimListLoading = false;
    this.claimsData.isClaimsForSaving = false;
    this.policySummaryData.isFromDeclinedEdit = false;
    this.uwApprovalData.uwApprovalListComplete$.next(false);
    this.entityRiskData.isDoneFetchMoratorium = false;
    this.entityRiskData.isDonePopulatingForms = false;
  }

  removeRaterStorage(riskDetailId: string){
    this.storage.remove(`${Rater.raterRequest}_${riskDetailId}`);
    this.storage.remove(`${Rater.raterResult}_${riskDetailId}`);
  }

  initData(): void {
    this.initiateForms();
    this.isParentEntitySubscriber();
    this.coveragesData.covAInfoMessage = false;
    this.coveragesData.covAInfoViewed = false;
    this.coveragesData.covCInfoMessage = false;
    this.coveragesData.covCInfoViewed = false;
    this.summaryData.isFormTypeLoaded.next(false);
    this.endorsementSavingData.limitedWaterDmgLimitInfoMessage = false;
    this.endorsementSavingData.limitedWaterDmgInfoViewed = false;
    this.claimsData.isClaimListLoading = false;
    this.currentCategoryRoute = this.getCurrentCategoryRoute();
    this.savingUponNavigate();
    this.subscribeToRouteParams();
    this.applicantData.copiedInformation = new SubmissionCopiedInformation();
    this.policySummaryData.withinRenewalPeriod = false;
    this.policySummaryData.withinNonRenewalPeriod = false;
    this.policySummaryData.renewalSubStatusCode.next(null);
    this.policySummaryData.renewalStatusCode = null;
    this.policySummaryData.renewalCode = null;
    this.policySummaryData.enablePolicyNavSaving = false;
    this.uwApprovalData.uwrSavedData$.next(null);
    this.policySummaryData.showRiskSubStatus = false;
    this.autoReinstatementData.isApprovalRequired$.next(false);
    this.autoReinstatementData.isRedirectedToPolicy = false;
    this.autoReinstatementData.isPolicy = true;
    this.endorsementsData.resetBRNew110InfoStatus();
    this.entityRiskData.isSwitchedRisk = false;
    this.entityRiskData.isFromRewrite = false;
    this.entityRiskData.isDoneFetchMoratorium = false;
    this.store.dispatch(updatePolicyIsLoadingFromPolicyManagementComponent({ isLoading: true }));
    this.resetStoreStates();
    this.coveragesData.hurricaneDeductibleidChanged = false;
    this.applicantData.nameChanged = false;
    this.endorsementsData.isPermittedIncidentalOccupanciesChanged = false;
    this.endorsementsData.roofSurfacingPaymentScheduleChanged = false;
    this.policySummaryData.isPolicyCheckComplete$.next(false);
    this.policySummaryData.subStatusCode$.next(null);
  }

  resetStoreStates(): void {
    this.store.dispatch(updatePolicyIsLoadingFromPolicyManagementComponent({ isLoading: null }));
    this.store.dispatch(updateMoratoriumIsLoadingFromPolicyManagementComponent({ isLoading: null }));
    this.store.dispatch(updateRaterIsLoadingFromPolicyManagementComponent({ isLoading: null }));
    this.store.dispatch(updateInterestsListFromPolicyManagementComponent({ list: null }));
    this.store.dispatch(updateBackupIsLoadingFormPolicyManagementComponent({ isLoading: null }));
    this.store.dispatch(updateClaimsListFromPolicyManagementComponent({ list: null }));
    this.store.dispatch(updateCoveragesSchedulesFromPolicyManagementComponent({ schedules: null }));
  }

  subscribeToRouteParams(): void {
    this.route.params.pipe(takeUntil(this.reset$)).subscribe(params => {
      this.checkParamsUuid(params);

      if(!Utils.isValidGUID(this.riskId) || !Utils.isValidGUID(this.riskDetailId)) {
        this.router.navigate([PathConstants.NotFound]);
        sessionStorage.setItem('hasValidPreviousURL', 'false');
        return;
      }

      this.policyService.getDraftRiskDetailId(this.riskId).subscribe((res) => {
        if (res) {
          const draftRiskDetailId = res?.id;

          if (draftRiskDetailId === params.uuid2) {
            this.policySummaryData.isPolicy = this.checkIsPolicy();

            const isPolicy = `${this.checkIsPolicy()}`;

            localStorage.setItem(`${this.riskId}_isPolicy`, isPolicy);
            if (!this.policySummaryData.isFromDeclinedEdit) {
              localStorage.setItem('isEditPolicy', 'false');
            }
            localStorage.setItem('pageType', PathConstants.Policy.Index);
            // Create side bar menu items.
            this.layoutService.updateMenu(this.createPolicyDetailsMenuItems(this.getCompletePolicyDetailsMenuItems()));
            const entityId = localStorage.getItem('entityId');
            localStorage.setItem('riskDetailId', this.riskDetailId);

            this.checkRiskDetailId(entityId);
            this.store.dispatch(updatePolicyIsLoadingFromPolicyManagementComponent({ isLoading: false }));
          } else {
            this.router.navigateByUrl(this.router.url.replace(params.uuid2, draftRiskDetailId));
          }
        }
      });
    });
  }

  checkParamsUuid(params: any): void {
    if (params.uuid !== undefined) {
      this.riskId = params.uuid;
      this.riskDetailId = params.uuid2;
      this.policySummaryData.clonePolicyId = params.uuid;
      this.policySummaryData.riskDetailId = params.uuid2;
      return;
    }

    this.riskDetailId = null;
  }

  checkRiskDetailId(entityId: string): void {
    if (this.riskDetailId !== null) {
      entityId = entityId ?? '';
      this.entityRiskData.isApiCallCompleted.next(false);
      this.isParentEntityCompleted.next(false);
      Utils.blockUI();
      this.subscribeToPolicyData();
      this.subscribeToPolicyDocuments();
      this.subscribeToClaimsData();
      this.subscribeToInspectionOrderingData();
      this.checkPolicyFormsValidity();
    }
  }

  subscribeToPolicyData(): void {
    const getEntityRisk$: Observable<EntityRiskDTO> = this.policyService.getData(this.riskId, this.riskDetailId);
    const getLvUWRenewalReviewModel$: Observable<LvUWRenewalReviewModel[]> = this.lvService.getLvUWRenewalReview();

    forkJoin([getEntityRisk$, getLvUWRenewalReviewModel$])
      .pipe(
        takeUntil(this.reset$)).subscribe(result => {
        this.lvData.LvUWRenewalReview = <LvUWRenewalReviewModel[]>result[1];
        this.entityRiskData.EntityRisk = <EntityRiskDTO>result[0];
        this.coveragesData.showPrivateFlood = !ExcludedZipCodeList.find(data => data === Number(this?.entityRiskData?.EntityRisk?.entityAddresses[0]?.address?.zipCode));
        localStorage.setItem('entityId', this.entityRiskData.getEntityId());
        this.removeRaterStorage(this.entityRiskData.getLastRiskDetailId());
        // Get Agency / Sub Agency Information
        const agencyId = this?.entityRiskData?.EntityRisk?.risks[0]?.agencyId;
        const parsedUserInfo = { agency_id: agencyId };
        this.authService.getAgenciesById(parsedUserInfo);
        this.authService.getAgencySubAgencies(this?.entityRiskData?.EntityRisk?.risks[0]?.agencyId, this?.entityRiskData?.EntityRisk?.risks[0]?.subAgencyId, this?.entityRiskData?.EntityRisk?.risks[0]?.riskDetails[0].id);
        this.quoteService.savetoService(this?.entityRiskData?.EntityRisk?.risks[0]?.formType, this?.entityRiskData?.EntityRisk?.entityAddresses[0].address?.state);
        this.getParentBackUpData();
        this.thirdPartyData.mapRiskPropertyToAPIResponse(this.entityRiskData.EntityRisk);
        this.thirdPartyData.checkBRUL135to140();
        this.zipCodeData.stateCode = this.entityRiskData.getEntityAddress().address.state;
        this.tryIsFromDeclinedEdit();
        this.entityRiskData.isApiCallCompleted.next(true);
        this.entityRiskData.finishPopulatingForms();
        this.checkWithinRenewalPeriod(this?.entityRiskData?.EntityRisk?.risks[0].firstIssueDate);
        this.checkWithinNonRenewalPeriod(this?.entityRiskData?.EntityRisk?.risks[0].riskDetails[0].expirationDate);
        this.policySummaryData.renewalSubStatusCode.next(this?.entityRiskData?.EntityRisk?.risks[0].renewalSubStatusCode as RenewalSubStatusCode);
        this.policySummaryData.renewalStatusCode = this?.entityRiskData?.EntityRisk?.risks[0].renewalStatusCode as RenewalStatusCode;
        this.policySummaryData.renewalCode = this?.entityRiskData?.EntityRisk?.risks[0].renewalCode as RenewalStatusCode;
        this.policySummaryData.hasUwApprovalChange.next(this.entityRiskData.EntityRisk.risks[0].riskDetails[0].hasUwApprovalChange);
        this.authService.initAppSettings();
        this.populateUWR();
        this.store.dispatch(updatePolicyIsLoadingFromPolicyManagementComponent({ isLoading: false }));
      }, error => {
        this.entityRiskData.isApiCallCompleted.next(false);
        this.store.dispatch(updatePolicyIsLoadingFromPolicyManagementComponent({ isLoading: false }));
        Utils.unblockUI();
      }, () => {
        this.propertyData.populateCounty(this.entityRiskData.EntityRisk, true);
        this.store.dispatch(updatePolicyIsLoadingFromPolicyManagementComponent({ isLoading: false }));
      });
  }

  checkWithinRenewalPeriod(firstIssueDate: any): void {
    const renewalDays = this.pageHeaderConstants.policyRenewal.renewalPeriod; // Change this value if needed for developement testing
    const expirationDate = this.getPolicyExpirationDate(firstIssueDate);
    const daysBeforeExpiration = this.getDaysBeforeExpiration(expirationDate);

    this.policySummaryData.withinRenewalPeriod = (daysBeforeExpiration <= renewalDays && daysBeforeExpiration >= 0);
  }

  checkWithinNonRenewalPeriod(expirationDate: any): void {
    const stateCode = this.entityRiskData.EntityRisk.risks[0].riskStateCode;
    const nonRenewalDays = this.lvData.LvUWRenewalReview.find(x => x.stateCode === stateCode && x.code === 'NRWD');
    const daysBeforeExpiration = this.getDaysBeforeExpiration(expirationDate);

    this.policySummaryData.withinNonRenewalPeriod = (daysBeforeExpiration <= nonRenewalDays.days && daysBeforeExpiration >= 0);
  }

  getPolicyExpirationDate(firstIssueDate: any): any {
    const firstIssueDateFormatted = moment(firstIssueDate).format('YYYY-MM-DD');
    const expirationYear = moment(firstIssueDateFormatted).format('YYYY');
    const expirationMonth = moment(firstIssueDateFormatted).format('MM');
    const expirationDay = moment(firstIssueDateFormatted).format('DD');

    const expirationDateNew = moment([
      Number(expirationYear) + 1,
      Number(expirationMonth) - 1,
      Number(expirationDay),
    ]);
    return moment(expirationDateNew).format('YYYY-MM-DD');
  }

  getDaysBeforeExpiration(expirationDate: any): number {
    const today = this.authService.getCustomDate();
    const expirationDateFormatted = moment(expirationDate).format('YYYY-MM-DD');
    const expirationYear = moment(expirationDateFormatted).format('YYYY');
    const expirationMonth = moment(expirationDateFormatted).format('MM');
    const expirationDay = moment(expirationDateFormatted).format('DD');

    const expirationDateNew = moment([
      Number(expirationYear),
      Number(expirationMonth) - 1,
      Number(expirationDay),
    ]);

    const currentDate = moment([
      today.getFullYear(),
      today.getMonth() ,
      today.getDate(),
    ]);

    return expirationDateNew.diff(currentDate, 'days');
  }

  tryIsFromDeclinedEdit(): void {
    try {
      if (this.policySummaryData.isFromDeclinedEdit) {
        this.setFormPageStatus(true);
        this.policySummaryData.isEditPolicy = true;
        this.policySummaryData.isFromDeclinedEdit = false;
        this.policySummaryData.enablePolicyNavSaving = true;
      } else {
        this.setFormPageStatus(false);
        this.policySummaryData.isEditPolicy = false;
      }

      this.summaryData.isCalcPremium.next(true);
      this.entityRiskData.startPopulatingForms();
      this.populateForms(this.entityRiskData.EntityRisk);
      this.coveragesData.showFireErrorOnInit = true;
      this.coveragesData.isFromPolicy = true;
      this.coveragesData.populateDropdownValues();
      this.coveragesData.setPrivateFloodChildValidator(this.coveragesData.floodForm.get('privateFloodCoverage').value);
      this.subscribeToChangeEffectiveDate();
    } catch (e) { }
  }

  subscribeToPolicyDocuments(): void {
    this.policyService.getPolicyDocuments(this.riskId).pipe(takeUntil(this.reset$)).subscribe(documents => {
      this.policyDocumentsData.mapPolicyDocuments(documents);
      this.policyDocumentsData.getFTPDocuments(this.riskId);
    });
  }

  subscribeToClaimsData(): void {
    this.policyService.getClaimsData(this.riskId, this.riskDetailId).pipe(takeUntil(this.reset$)).subscribe(claims => {

      //TODO: Update logic for DP3 Chargeable Update
      // if (this.entityRiskData.getFormType() === this.formTypeConstants.DP3) {
      //   claims = claims.map(a => {return this.claimsData.setClaimsChargeable(a, this.coveragesData.isCoverageLorMEnabled);});
      // }
      this.claimsData.populateClaimsData(claims);
      this.claimsData.riskClaims = [];
      this.claimsData.riskClaims = claims;
    });
  }

  subscribeToInspectionOrderingData(): void {
    this.reportsService.getInspectionOrdering(this.riskId).pipe(takeUntil(this.reset$)).subscribe(inspectionOrder => {
      this.applicantData.inspectionOrdering = inspectionOrder;
      this.applicantData.populateInspectionData(inspectionOrder);
    });
  }

  subcribeToDataReset(): void {
    this.policyService.reset.pipe(takeUntil(this.stop$), distinctUntilChanged()).subscribe(result => {
      if (result) {
        this.reset$.next();
        this.reset$.complete();
        this.reset$ = new Subject();
        this.initData();
        this.policyService.reset.next(false);
      }
    });
  }

  protected populateForms(data: EntityRiskDTO) {
    this.summaryData.populateSummaryForms(data);
    this.quickQuoteData.populateQuickQuotePage(data);
    this.policyBillingData.populatePolicyBillingPage(data);
    this.raterData.isCalcPremium.subscribe(() => this.policySummaryData.populatesPolicySummaryPage(data));
    this.coveragesData.populateCoveragesPage(data, true);
    this.endorsementsData.populateEndorsementsPage(data);
    this.propertyData.populatePropertyPage(data, true);
    this.applicantData.populateApplicantData(data, true);
    this.uwQuestionsData.populateUWQuestionPage(data);
    this.interestData.populateInterest(data);

    this.policyBillingData.feesInitialLoadNotifier.take(1).subscribe(() => {
      this.raterData.populateRaterRequestPayload(data,
        this.applicantData.mainApplicant,
        this.propertyData.locationDetails,
        this.propertyData.propertyDetails,
        this.summaryData.SummaryForm,
        this.coveragesData,
        this.endorsementsData,
        this.propertyData,
        this.propertyBusinessRules,
        this.policyBillingData.transactionFees,
        true
      );
      this.policyIssueData.populatePolicyChanges(data);
    });
    this.policyBillingData.listTransactionFeesByRiskId(this.entityRiskData.getRiskId(), true);
    this.taskData.getTaskListByPolicy(true);
    this.uwApprovalData.populateUWApprovalData(data);
    this.policyHistoryData.populateHistoryData(
      data?.risks[0]?.policyNumber,
      this.entityRiskData.getRiskId(),
      this.entityRiskData.getLastRiskDetailId()
      );
    this.policyNotesData.currentRiskBindId = data?.risks[0]?.riskDetails[0]?.riskBinds[0]?.id;
    this.summaryData.populateAgentDetails(data?.risks[0]?.agentId);
    this.taskData.getUnderwriterList();
    this.policyHistoryData.historyItemsData = [];
    this.policySummaryData.navigatedToPolicyIssue = false;

    const effectiveDate = new Date(this.applicantData.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate);
    this.claimsData.populateClaimsData(data?.risks[0]?.riskDetails[0]?.riskClaims, effectiveDate, this.endorsementsData.endorsementsGroup);
    this.claimsData.riskClaims = [];
    this.claimsData.riskClaims = data?.risks[0]?.riskDetails[0]?.riskClaims;
    this.autoReinstatementData.populateAutoReinstateData();
    this.propertyBusinessRules.triggerActiveNew125(false, true);
  }

  protected initiateForms() {
    this.coveragesData.initializeForms();
    this.bindAndIssueData.initBindAndIssueData();
    this.propertyData.initPropertyForms();
    this.applicantData.initForms();
    this.summaryData.initForms();
    this.endorsementsData.initForms();
    this.uwQuestionsData.initForms();
    this.interestData.initForms();
    this.claimsData.initForms();
    this.policySummaryData.initForms();
    this.policyBillingData.initForms();
    this.policyIssueData.initForms();
    this.autoReinstatementData.initForms();
    this.applicantData.mainApplicant.get('effectiveDate').setValue(null);
  }

  setFormPageStatus(status: boolean): void {
    this.coveragesData.setCoveragePageFormStatus(status);
    this.endorsementsData.setEndorsementPageFormStatus(status);
    this.propertyData.setPropertyPageFormStatus(status);
    this.applicantData.setApplicantPageFormStatus(status);
    this.uwQuestionsData.setUWQuestionPageFormStatus(false);
    this.interestData.setInterestPageFormStatus(status);
  }

  checkIsPolicy(): boolean {
    return this?.router?.url?.includes('policies');
  }

  protected getCurrentCategoryRoute(): string {
    const splitUrl = _.split(this.router.url, '/');
    const quickQuoteRoute = _.indexOf(splitUrl, PathConstants.QuickQuote.Index);
    if (quickQuoteRoute !== -1) {
      return splitUrl[quickQuoteRoute];
    }
    const currentCategoryIndex = splitUrl.length - 1;
    return splitUrl[currentCategoryIndex];
  }

  savingUponNavigate(): void {
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .pipe(takeUntil(this.reset$)).subscribe((event: NavigationEnd) => {
        this.newCategoryRoute = this.getCurrentCategoryRoute();

        if (this.policySummaryData.isEditPolicy || this.currentCategoryRoute.toLocaleLowerCase() === 'notes') {
          this.policyNavValidationService.validateCurrentCategory(this.currentCategoryRoute);
          if (this.policySummaryData.enablePolicyNavSaving) {
            this.previousCategoryRoute = this.currentCategoryRoute;
            this.policyNavSavingService.saveCurrentCategory(this.currentCategoryRoute);
          }

          //Flag to trigger saving of UWR if there are policy changes from applicant
          this.uwApprovalData.triggerUWRSavingFromApplicant = this.currentCategoryRoute.toLocaleLowerCase() === 'applicant-page' ? true : false;
          this.uwApprovalData.triggerUWRSavingFromCoverages = this.currentCategoryRoute.toLocaleLowerCase() === 'coverages-and-deductions' ? true : false;
          this.updateUWApprovalData();
          this.validateCategories();
          this.layoutService.updateMenu(this.createPolicyDetailsMenuItems(this.getCompletePolicyDetailsMenuItems()));
        }

        if (this.currentCategoryRoute.toLocaleLowerCase() === 'applicant-page' && this.isApplicantInspectionFormValueChanged()){
          this.policyNavSavingService.saveInspectionOrdering();
        }

        if (this.currentCategoryRoute.toLocaleLowerCase() === 'tasks') {
          this.policyService.getPolicyDocuments(this.riskId).pipe(takeUntil(this.reset$)).subscribe(documents => {
            this.policyDocumentsData.mapPolicyDocuments(documents);
          });
        }

        if (this.authService.isInternalUser && this.currentCategoryRoute.toLocaleLowerCase() === 'documents' && this.policyDocumentsData?.suppressDocuments?.length > 0){
          this.formDocumentService.updateFTPDocumentsTemporary(this.policyDocumentsData?.suppressDocuments).subscribe(res => {
            this.policyDocumentsData.suppressDocuments = res.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
          }, error => {
            console.log(error);
          });
        }

        this.currentCategoryRoute = this.newCategoryRoute;
      });
  }

  isParentEntitySubscriber(): void {
    this.isParentEntityCompleted$.pipe(takeUntil(this.reset$)).subscribe(completed => {
      if (completed) {
        this.uwApprovalData.isInitialFetch = true;
        this.uwr.getPolicyUwrReferrals();
        this.uwApprovalData.isInitialFetch = false;

        const formType = this.entityRiskData?.EntityRisk?.risks[0]?.formType;
        if (formType === this.formTypeConstants.DP3 || formType === this.formTypeConstants.HO6) {
          this.policySummaryData.showRiskSubStatus = true;
        }
      }
    });
  }

  validateCategories(): void {
    this.policyNavValidationService.validateCurrentCategory(PathConstants.Applicant.Applicant.ApplicantPage);
    this.policyNavValidationService.validateCurrentCategory(PathConstants.Policy.Policies.Endorsements);
    this.policyNavValidationService.validateCurrentCategory(PathConstants.Policy.Policies.CoveragesAndDeduction);
    this.policyNavValidationService.validateCurrentCategory(PathConstants.Policy.Policies.UWApproval);
    this.layoutService.updateMenu(this.createPolicyDetailsMenuItems(this.getCompletePolicyDetailsMenuItems()));

    this.applicantData.mainApplicant.markAllAsTouched();
    this.applicantData.contact.markAllAsTouched();
    this.applicantData.coApplicant.markAllAsTouched();
    this.endorsementsData.endorsementsGroup.markAllAsTouched();
  }

  public getParentBackUpData(): void {
    const riskDetailId = this.entityRiskData.getRiskDetailId();

    this.store.dispatch(updateBackupIsLoadingFormPolicyManagementComponent({ isLoading: true }));

    this.policyService.getBackUpData(riskDetailId).pipe(takeUntil(this.reset$)).subscribe(result => {
      this.entityRiskData.ParentBackUpRiskData = <RiskDetailsDTO>result;
      this.isParentEntityCompleted.next(true);
      this.store.dispatch(updateBackupDataFormPolicyManagementComponent({ data: result }));
      this.store.dispatch(updateBackupIsLoadingFormPolicyManagementComponent({ isLoading: false }));
    }, error => {
      this.isParentEntityCompleted.next(false);
      this.store.dispatch(updateBackupIsLoadingFormPolicyManagementComponent({ isLoading: false }));
      Utils.unblockUI();
    });
  }

  checkPolicyFormsValidity(): void {
    const covaragesForms = ['coveragesAndDeductiblesForm', 'discountsForm', 'floodForm'];
    const endorsementsForms = ['endorsementsGroup'];
    const propertyForms = ['locationDetails', 'propertyDetails', 'windMitigation'];
    const applicantForms = ['mainApplicant', 'contact', 'coApplicant', 'fcraReportingActForm'];
    const uwQuestionsForms = ['eligibilityGroup', 'generalQuestionsGroup'];
    const interestForms = ['priorInsuranceForm'];

    this.subscribeToPolicyFormsValidity(covaragesForms, this.coveragesData);
    this.subscribeToPolicyFormsValidity(endorsementsForms, this.endorsementsData);
    this.subscribeToPolicyFormsValidity(propertyForms, this.propertyData);
    this.subscribeToPolicyFormsValidity(applicantForms, this.applicantData);
    this.subscribeToPolicyFormsValidity(uwQuestionsForms, this.uwQuestionsData);
    this.subscribeToPolicyFormsValidity(interestForms, this.interestData);
  }

  subscribeToPolicyFormsValidity(formNames: string[], fromGroup: any): void {
    formNames.forEach(form => {
      fromGroup[form].statusChanges.pipe(takeUntil(this.reset$), distinctUntilChanged()).subscribe(result => {
        this.validateCurrentCategory(fromGroup);
        this.layoutService.updateMenu(this.createPolicyDetailsMenuItems(this.getCompletePolicyDetailsMenuItems()));
      });
    });
  }

  validateCurrentCategory(fromGroup: any): void {
    switch (fromGroup) {
      case this.coveragesData:
        this.policyNavValidationService.validateCurrentCategory(PathConstants.Policy.Policies.CoveragesAndDeduction);
        break;
      case this.endorsementsData:
        this.policyNavValidationService.validateCurrentCategory(PathConstants.Policy.Policies.Endorsements);
        break;
      case this.propertyData:
        this.policyNavValidationService.validateCurrentCategory(PathConstants.Policy.Policies.Property);
        break;
      case this.applicantData:
        this.policyNavValidationService.validateCurrentCategory(PathConstants.Policy.Policies.ApplicantPage);
        break;
      case this.uwQuestionsData:
        this.policyNavValidationService.validateCurrentCategory(PathConstants.Policy.Policies.UWQuestions);
        break;
      case this.interestData:
        this.policyNavValidationService.validateCurrentCategory(PathConstants.Policy.Policies.Interests);
        break;
    }
  }

  subscribeToUwApprovalTableRowsSubject(): void {
    this.uwApprovalData.uwApprovalTableRows$.pipe(takeUntil(this.stop$)).subscribe(result => {
      this.updateUWApprovalData();
      this.validateCategories();
    });
  }

  subscribeToUwApprovalListComplete(): void {
    this.uwApprovalData.uwApprovalListComplete$.pipe(takeUntil(this.stop$), distinctUntilChanged()).subscribe(result => {
      if (result) {
        this.updateUWApprovalData();
        this.validateCategories();
        this.uwApprovalData.showLoader = false;
      }
    });
  }

  updateUWApprovalData(): void {
    this.policySummaryData.uwrListEmpty = this.uwApprovalData.uwrListEmpty;
    this.policySummaryData.uwrListAllPostBind = this.uwApprovalData.uwrListAllPostBind;
    this.policySummaryData.uwrListAllNonPostBind = this.uwApprovalData.uwrListAllNonPostBind;
    this.policySummaryData.nonPostBindUwrItemsChecked = this.uwApprovalData.nonPostBindUwrItemsChecked;
    this.policySummaryData.nonPostBindUwrItemsApproved = this.uwApprovalData.nonPostBindUwrItemsApproved;
  }

  subscribeToInterestDataPopulate(): void {
    this.interestData.fetchInterestsComplete.pipe(takeUntil(this.stop$)).subscribe((result) => {
      if (result) {
        this.uwr.getPolicyUwrReferrals();
      }
    });
  }

  checkSubmissionReferApprovedAIRDecline(): boolean{
    let isResult;
    if(this.entityRiskData.EntityRisk?.risks[0]?.isSiebel === true ){
      isResult = true;
    }else{
      isResult = false;
    }
    this.checkisDateImplemented();
    return isResult;
  }

  checkisDateImplemented(): void {
      if (this.router.url.includes('/uw-questions')) {
        if(this.entityRiskData.EntityRisk?.risks[0]?.isSiebel === true ){
          this.showUWQuestionRestricted$.next(true);
        }
      }  else {
        this.showUWQuestionRestricted$.next(false);
      }
  }

  subscriptToShowUWRestricted$(): void {
    this.showUWQuestionRestricted$.pipe(distinctUntilChanged(),takeUntil(this.stop$)).subscribe(data => {
      if (data) {
          setTimeout(() => {
            NotifUtils.showError('Restricted Access', () => {
              this.location.back();
            });
          }, 3000);

      }
    });
  }

  getCompletePolicyDetailsMenuItems(hasUwrChanges: boolean = false): IPolicyNavDetails {
    return {
      riskId: this.riskId,
      riskDetailId: this.riskDetailId,
      storage: this.storage,
      validation: this.policyNavValidationService.validationList,
      quoteStatus: this.submissionData.policyStatus.value,
      hasAir: this.submissionData.hasAir,
      hasDecline: this.submissionData.hasDecline,
      uwrListEmpty: this.policySummaryData.uwrListEmpty,
      hasUwrChanges: hasUwrChanges,
      uwrListAllPostBind: this.policySummaryData.uwrListAllPostBind,
      postBindUwrItemsApproved: this.policySummaryData.postBindUwrItemsApproved,
      nonPostBindUwrItemsChecked: this.policySummaryData.nonPostBindUwrItemsChecked,
      nonPostBindUwrItemsApproved: this.policySummaryData.nonPostBindUwrItemsApproved,
      isEditPolicy: this.policySummaryData.isEditPolicy,
      isSubmissionReferApprovedAirDeclined: this.checkSubmissionReferApprovedAIRDecline()
    };
  }

  subscribeToFloodValueChanges(): void {
    this.coveragesData.subscribeToFloodValueChanges();
    this.coveragesData.setPrivateFloodChildValidator(this.coveragesData.floodForm.get('privateFloodCoverage').value);
  }

  subscribeToPolicyPageSaving(): void {
    const policySavePagesComplete: string[] = [
      'saveCoveragesComplete$',
      'saveEndorsementsComplete$',
      'saveClaimsComplete$',
      'savePropertyComplete$',
      'saveInterestsComplete$',
      'saveApplicantComplete$',
      'saveUwApprovalComplete$'
    ];

    policySavePagesComplete.forEach(page => {
      this.policySummaryData[page].pipe(takeUntil(this.stop$)).subscribe(result => {
        if (result) {
          this.updateSavingCounter(page);
        }
      });
    });
  }

  subcribeToIsDonePopulatingForms(): void {
    if(!this.entityRiskData.isDonePopulatingForms) {
      this.entityRiskData.isDonePopulatingForms$.pipe(takeUntil(this.stop$)).subscribe(() => {
        if (!this.policySummaryData.isPolicy) {
          this.quickQuoteData.setSeniorRetireeValue();
          }
        this.getActiveMoratorium();
        this.getPolicyChanges();
      });

      return;
    }
  }

  getPolicyChanges(): void {
    this.uwApprovalData.hurricaneDeductibleChangesDescription = this.policySummaryData.policyChanges?.find(x => x.includes('Changed Hurricane Deductible From:'));
    this.store.dispatch(updateChangesIsLoadingFromPolicyManagementComponent({ isLoading: true }));

    this.policyService.getPolicyChanges(this.riskId, this.riskDetailId).pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe((policyChanges: Array<string>) => {
      this.policySummaryData.policyChanges = policyChanges;
      this.policyIssueData.policyChangesLength = policyChanges?.length;

      if (policyChanges?.length > 0) {
        this.uwr.checkUWR106();

        if (this.uwr.hasUWR106) {
          this.uwApprovalData.initData(this.uwApprovalData.uwApprovalList, this.entityRiskData.EntityRisk?.risks[0]?.riskDetails[0]?.riskUWApproval);
        }
      }

      if (this.uwApprovalData.triggerUWRSavingFromApplicant || this.uwApprovalData.triggerUWRSavingFromCoverages) {
        this.uwApprovalData.checkPendingItems();
        if (this.entityRiskData.isDoneFetchMoratorium) {
          this.uwApprovalData.initUWRData();
          this.updateUWApprovalData();
        }
        this.validateCategories();
      }

      if(policyChanges?.find(list => list.includes('Retired?') || list.includes('Birth Date'))?.length > 0) {
        this.quickQuoteData.setSeniorRetireeValue(true);
      }

      this.store.dispatch(updateChangesIsLoadingFromPolicyManagementComponent({ isLoading: false }));
      this.store.dispatch(updateChangesListFromPolicyManagementComponent({ list: policyChanges }));
    });
  }

  checkPolicyChanges(policyChanges: string[]): void {
    if (String(this.policySummaryData.policyChanges) !== String(policyChanges)) {
      this.checkSubStatusPending(policyChanges);
    }
  }

  checkSubStatusPending(policyChanges: string[]): void {
    if (policyChanges.length === 0 && this.submissionData.policyStatus.value === 'Pending') {
      this.revertPolicySubStatusToDraft();
    }
  }

  revertPolicySubStatusToDraft(): void {
    this.policySummaryData.showRiskSubStatus = false;

    this.policyService.policyUpdateSubStatusCode(this.riskDetailId, 'Draft').pipe(takeUntil(this.stop$)).subscribe(result => {
      if (result) {
        const statusDescription = this.LvRiskStatus.find(a => a.code === result)?.description;
        this.submissionData.policyStatus.next(statusDescription as RiskStatus);

        if (result === 'Pending' || (result === 'Draft' && this.policySummaryData.policyChanges.length > 0)) {
          this.summaryData.showPendingEndorsementStatus = true;
          this.summaryData.isDraftRiskSubStatus = false;
        } else {
          this.summaryData.showPendingEndorsementStatus = false;
        }
      }
      this.policySummaryData.showRiskSubStatus = true;
    });
  }

  subscribeToChangeEffectiveDate(): void {
    let newSelectedEffectiveDate;
    this.policyIssueData.issuePolicyForm.get('changeEffectiveDate').valueChanges.pipe(startWith(null as string), pairwise()).subscribe(([prevValue, nextValue]: [any, any]) => {
      this.policyIssueData.isOutOfSequenceTransaction = false;
      if (prevValue !== null && nextValue && !this.entityRiskData.isSwitchedRisk && !this.entityRiskData.isFromRewrite) {
        const effectiveDate = moment(nextValue?.singleDate?.jsDate).format('YYYY-MM-DD');
        newSelectedEffectiveDate = moment(nextValue?.singleDate?.jsDate).format('YYYY-MM-DDT00:00:00.000');
        this.policyService.postEffectiveDate(this.riskId, this.riskDetailId, effectiveDate).pipe(takeUntil(this.stop$)).subscribe();
      }

      this.uwr.checkUWR106();
      this.uwApprovalData.initUWRData();
      this.uwApprovalData.checkPendingItems();

      this.selectMoratoriumIsLoading$
        .pipe(
          filter((value) => {
            return value === false;
          }),
          takeUntil(this.stop$)
        )
        .subscribe(() => {
          this.uwApprovalData.initUWRData();
        });

      this.policyIssueData.callOutOfSequence(newSelectedEffectiveDate, true);
    });
  }

  subscribeToRenewalSubStatusCode(): void {
    this.policySummaryData.renewalSubStatusCode.pipe(takeUntil(this.stop$)).subscribe(renewalSubStatusCode => {
      if (renewalSubStatusCode === 'CRW') {
        const lvRenewalChangesCategory: INotesCategory = {
          tableName: 'LvRenewalChangesCategory ',
          tableDescription: 'Renewal Changes',
          code: 'RC',
          description: 'Renewal Changes',
          isActive: true
        };

        const hasRenewalChangesCateogry = this.bindAndIssueData.notesCategories.find(noteCategory => {
          return noteCategory.code === 'RC';
        });

        if (!hasRenewalChangesCateogry) {
          this.bindAndIssueData.notesCategories.push(lvRenewalChangesCategory);
        }
        this.getPolicyNotes();

        return;
      }

      const notesCategories = this.bindAndIssueData.notesCategories.filter(noteCategory => {
        return noteCategory.code !== 'RC';
      });

      this.bindAndIssueData.notesCategories = notesCategories;
      this.getPolicyNotes();
    });
  }

  getPolicyNotes(): void {
    this.store.dispatch(updatePolicyNotesIsLoadingFromPolicyManagementComponent({ isLoading: true }));
    this.store.dispatch(updatePolicyNotesListFromPolicyManagementComponent({ list: null }));

    this.policyService.getPolicyNotes(this.riskId).pipe(take(1)).subscribe(riskBindNotes => {
      this.store.dispatch(updatePolicyNotesIsLoadingFromPolicyManagementComponent({ isLoading: false }));
      this.store.dispatch(updatePolicyNotesListFromPolicyManagementComponent({ list: riskBindNotes }));
    });
  }

  subscribeToUwrSavedData(): void {
    this.uwApprovalData.uwrSavedData$.pipe(takeUntil(this.stop$)).subscribe(() => {
      this.policyService.getPolicyDocuments(this.riskId).pipe(takeUntil(this.reset$)).subscribe(documents => {
        this.policyDocumentsData.mapPolicyDocuments(documents);
      });
    });
  }

  isApplicantInspectionFormValueChanged(): boolean {
    return this.applicantData.inspection.dirty;
  }

  updateSavingCounter(page: string): void {
    const navOutCoveragesSavingCounter = this.policySummaryData.navOutCoveragesSavingCounter$;
    const navOutEndorsementsSavingCounter = this.policySummaryData.navOutEndorsementsSavingCounter$;
    const navOutClaimsSavingCounter = this.policySummaryData.navOutClaimsSavingCounter$;
    const navOutPropertySavingCounter = this.policySummaryData.navOutPropertySavingCounter$;
    const navOutApplicantSavingCounter = this.policySummaryData.navOutApplicantSavingCounter$;
    const navOutInterestsSavingCounter = this.policySummaryData.navOutInterestsSavingCounter$;
    const navOutUwApprovalSavingCounter = this.policySummaryData.navOutUwApprovalSavingCounter$;

    switch (page) {
      case 'saveCoveragesComplete$':
        navOutCoveragesSavingCounter.next(navOutCoveragesSavingCounter.value + 1);
        navOutEndorsementsSavingCounter.next(navOutEndorsementsSavingCounter.value + 1);
        navOutPropertySavingCounter.next(navOutPropertySavingCounter.value + 1);
        navOutApplicantSavingCounter.next(navOutApplicantSavingCounter.value + 1);
        break;
      case 'saveEndorsementsComplete$':
        navOutEndorsementsSavingCounter.next(navOutEndorsementsSavingCounter.value + 1);
        navOutCoveragesSavingCounter.next(navOutCoveragesSavingCounter.value + 1);
        navOutPropertySavingCounter.next(navOutPropertySavingCounter.value + 1);
        break;
      case 'saveClaimsComplete$':
        navOutClaimsSavingCounter.next(navOutClaimsSavingCounter.value + 1);
        break;
      case 'savePropertyComplete$':
        navOutPropertySavingCounter.next(navOutPropertySavingCounter.value + 1);
        break;
      case 'saveApplicantComplete$':
        navOutApplicantSavingCounter.next(navOutApplicantSavingCounter.value + 1);
        break;
      case 'saveInterestsComplete$':
        navOutInterestsSavingCounter.next(navOutInterestsSavingCounter.value + 1);
        break;
      case 'saveUwApprovalComplete$':
        navOutCoveragesSavingCounter.next(navOutCoveragesSavingCounter.value + 1);
        navOutEndorsementsSavingCounter.next(navOutEndorsementsSavingCounter.value + 1);
        navOutClaimsSavingCounter.next(navOutClaimsSavingCounter.value + 1);
        navOutPropertySavingCounter.next(navOutPropertySavingCounter.value + 1);
        navOutInterestsSavingCounter.next(navOutInterestsSavingCounter.value + 1);
        navOutUwApprovalSavingCounter.next(navOutUwApprovalSavingCounter.value + 1);
        break;
    }
  }

  subscribeToPolicyNavOutSavingCounter(): void {
    const policyNavOutSavingCounters: string[] = [
      'navOutCoveragesSavingCounter$',
      'navOutEndorsementsSavingCounter$',
      'navOutClaimsSavingCounter$',
      'navOutPropertySavingCounter$',
      'navOutApplicantSavingCounter$',
      'navOutInterestsSavingCounter$',
      'navOutUwApprovalSavingCounter$'
    ];

    policyNavOutSavingCounters.forEach(navOutSavingCounter => {
      this.policySummaryData[navOutSavingCounter].pipe(takeUntil(this.stop$)).subscribe(count => {
        let targetCount: number;
        let url: string ;

        switch (navOutSavingCounter) {
          case 'navOutCoveragesSavingCounter$':
            targetCount = 2;
            url = PathConstants.Policy.Policies.CoveragesAndDeduction;
            break;
          case 'navOutEndorsementsSavingCounter$':
            targetCount = 3;
            url = PathConstants.Policy.Policies.Endorsements;
            break;
          case 'navOutClaimsSavingCounter$':
            targetCount = 2;
            url = PathConstants.Policy.Policies.Claims;
            break;
          case 'navOutPropertySavingCounter$':
            targetCount = 4;
            url = PathConstants.Policy.Policies.Property;
            break;
          case 'navOutApplicantSavingCounter$':
            targetCount = 2;
            url = PathConstants.Policy.Policies.ApplicantPage;
            break;
          case 'navOutInterestsSavingCounter$':
            targetCount = 2;
            url = PathConstants.Policy.Policies.Interests;
            break;
          case 'navOutUwApprovalSavingCounter$':
            targetCount = 1;
            url = PathConstants.Policy.Policies.UWApproval;
            break;
        }

        if (count === targetCount && url === this.previousCategoryRoute) {
          this.getPolicyChanges();
        }
      });
    });
  }

  public getActiveMoratorium(): void {
    const countyFromEntity = this.propertyData?.locationDetails?.get('county')?.value?.toString()?.trim() ?? this.entityRiskData.EntityRisk?.entityAddresses[0]?.address?.county?.toString()?.trim() ?? '';
    const effectiveDateFromEntity = moment(this.entityRiskData.getPolicyFirstIssueDate()).format('YYYY-MM-DD');
    const zipCodeFromEntity = this.entityRiskData.EntityRisk?.entityAddresses[0]?.address?.zipCode?.toString()?.trim() ?? '';
    const stateCodeFromEntity = this.entityRiskData.EntityRisk?.entityAddresses[0]?.address?.state?.toString()?.trim() ?? '';
    const $isRaterCalculated = this.hoRaterRequestData.isRaterCalculated;
    const $uwApprovalListComplete = this.uwApprovalData.uwApprovalListComplete$;

    const moratoriumRequest: RequestObjectMoratorium = {
      county: countyFromEntity ,
      effectiveDate: effectiveDateFromEntity ,
      zipCode: zipCodeFromEntity,
      stateCode: stateCodeFromEntity,
      formType: this.quoteService.formType,
      agencyId: this.entityRiskData.agencyId
    };

    if (!countyFromEntity || !effectiveDateFromEntity || !zipCodeFromEntity || !stateCodeFromEntity) {
      return;
    }

    if(!this.entityRiskData.isDoneFetchMoratorium && this.policySummaryData.isPolicy) {
      this.store.dispatch(updateAppIsLoadingFromPolicyManagementComponent({ isLoading: true }));
      this.store.dispatch(updateMoratoriumIsLoadingFromPolicyManagementComponent({ isLoading: true }));

      this.moratoriumService.getAllMoratoriums().pipe(takeUntil(this.stop$)).subscribe(moraResult => {
        this.entityRiskData.allMoratoriumList = moraResult;
      }, (error) => {
        NotifUtils.showError(GenericConstants.moratoriumAPIError);
      });

      this.moratoriumService.getMoratoriums(moratoriumRequest).pipe(takeUntil(this.stop$))
        .subscribe(result => {
          this.entityRiskData.isDoneFetchMoratorium = true;
          this.entityRiskData.moratoriumResponse =
              result.filter(a => a.type === MoratoriumType.closure ||
                                        a.type === MoratoriumType.moratorium ||
                                        a.type === MoratoriumType.coverageMoratorium) ?? [];
                this.uwr.getPolicyUwrReferrals();
                this.uwApprovalData.initUWRData();
                this.removeInActiveFloodMORUWR();
                this.store.dispatch(updateAppIsLoadingFromPolicyManagementComponent({ isLoading: false }));

                this.store.dispatch(updateMoratoriumListFromPolicyManagementComponent({ list: result }));
                this.store.dispatch(updateMoratoriumIsLoadingFromPolicyManagementComponent({ isLoading: false }));
      }, (error) => {
        this.store.dispatch(updateAppIsLoadingFromPolicyManagementComponent({ isLoading: false }));
        this.store.dispatch(updateAppIsConfirmationFromPolicyManagementComponent({ isConfirmation: true }));
        this.store.dispatch(updateMoratoriumIsLoadingFromPolicyManagementComponent({ isLoading: false }));

        NotifUtils.showInformationWithConfirmationFromUser(GenericConstants.moratoriumAPIError, () => {
          this.store.dispatch(updateAppIsConfirmationFromPolicyManagementComponent({ isConfirmation: false }));
        });
      });
    }
  }

  removeInActiveFloodMORUWR(): void {
    const riskData = this.entityRiskData.EntityRisk;
    const floodMORDescription = LvUWApprovals.find(a => a.code === 'COV-FLD-MOR')?.description;
    if (this.entityRiskData.moratoriumResponse.filter(a => a.type === MoratoriumType.coverageMoratorium && a.type2 === MoratoriumType2.floodCoverage).length === 0) {
      const uwApprovalData = riskData.risks[0].riskDetails[0].riskUWApproval.filter(a => a.uwApprovalDescription !== floodMORDescription);
      this.entityRiskData.EntityRisk.risks[0].riskDetails[0].riskUWApproval = uwApprovalData;
      this.uwApprovalData.populateUWApprovalData(this.entityRiskData.EntityRisk);
    }
  }

  subscribeToPolicySubStatus$(): void {
    this.selectPolicySubStatus$.pipe(takeUntil(this.stop$), pairwise()).subscribe(([prevValue, nextValue]: [any, any]) => {
      const validPrevStatusList: RiskStatus[] = [
        this.genericConstants.quoteStatus.ruw,
        this.genericConstants.quoteStatus.air,
        this.genericConstants.quoteStatus.duw,
        this.genericConstants.quoteStatus.auw,
      ];

      if (validPrevStatusList.includes(prevValue)) {
        this.getPolicyNotes();
      }
    });
  }

  subscribeToPolicyNotesList$(): void {
    this.selectPolicyNotesList$.pipe(takeUntil(this.stop$)).subscribe(result => {
      if (result) {
        this.policyNotesData.populateNotes(result as AllRiskBindNoteDTO[]);
      }
    });
  }

  get yearBuilt(): number {
    return Number(this.propertyData?.propertyDetails?.get('yearBuilt')?.value);
  }

  subscribeToSelectRelatedRiskstList$(): void {
    this.selectRelatedRiskstList$.pipe(takeUntil(this.stop$)).subscribe(result => {
      this.coveragesData.relatedRisks = result;
      this.coveragesData.validateHurricaneDeductible(this.yearBuilt);
    });
  }

  subscribeUWRPopulated$(): void {
    this.uwr.uwrPopulated$.pipe(takeUntil(this.stop$)).subscribe(() => {
      this.selectPolicyUWRsTableRows$.pipe(
        filter(value => {
          return value !== null;
        }),
        map(val => {
          return val?.map(item => {
            return {
              description: item.tr[0].value,
              status: item.tr[1].value,
            };
          });
        }),
        takeUntil(this.stop$),
        distinctUntilChanged(_.isEqual)
      ).subscribe(result => {
        this.uwApprovalSavingData.saveUWApproval();
      });
    });
  }

  populateUWR(): void {
    const stop$ = new Subject();

    combineLatest([
      this.selectPolicyIsLoading$
      .pipe(
        filter((value) => {
          return value === false;
        }),
        takeUntil(this.stop$)
      ),
      this.selectMoratoriumIsLoading$
      .pipe(
        filter((value) => {
          return value === false;
        }),
        takeUntil(this.stop$)
      ),
      this.selectInterestsList$
      .pipe(
        filter(data => {
          return data !== null;
        }),
        takeUntil(this.stop$)
      ),
      this.selectRaterIsLoading$
      .pipe(
        takeUntil(this.stop$)
      ),
      this.selectBackupIsLoading$
      .pipe(
        filter((value) => {
          return value === false;
        }),
        takeUntil(this.stop$)
      ),
      this.selectRaterCalculateDP3IsLoading$
      .pipe(
        takeUntil(this.stop$)
      )
    ]).pipe(takeUntil(stop$)).subscribe(value => {
      const isDP3: boolean = this.entityRiskData.EntityRisk.risks[0].formType === this.formTypeConstants.DP3;

      if (isDP3) {
        const selectRaterCalculateDP3IsLoading = value[5];

        if (!selectRaterCalculateDP3IsLoading) {
          this.completePopulateUWR(stop$);
        }
      } else {
        const selectRaterIsLoading = value[3];

        if (!selectRaterIsLoading) {
          this.completePopulateUWR(stop$);
        }
      }
    });
  }

  completePopulateUWR(stop$: Subject<any>): void {
    this.uwApprovalData.populateUWApprovalData(this.entityRiskData.EntityRisk);
    this.uwr.uwrPopulated$.next();
    stop$.next();
    stop$.complete();
  }

  createPolicyDetailsMenuItems(navDetails: IPolicyNavDetails): NavData[] {
    return createPolicyDetailsMenuItems(navDetails);
  }

  subscribeToIsCovAAndReplacementCostTheSame$(): void {
    this.thirdPartyData.isCovAAndReplacementCostTheSame$.pipe(
      takeUntil(this.stop$)
    ).subscribe(() => {
      this.uwr.getUwrReferrals(false);
    });
  }

  subscribeToPolicySubStatusCode$(): void {
    this.policySummaryData.subStatusCode$.pipe(takeUntil(this.stop$), distinctUntilChanged()).subscribe(subStatusCode => {
      this.policyService.getPolicyChanges(this.riskId, this.riskDetailId).pipe(takeUntil(this.stop$), distinctUntilChanged()).subscribe(() => {
        this.policyService.getSubStatusCode(this.riskDetailId).pipe(takeUntil(this.stop$)).subscribe(_subStatusCode => {
          const riskSubStatusCodeDescription = LvRiskStatus.find(a => a.code === _subStatusCode)?.description;
          const pendingSubStatusCode: RiskStatusCode = 'PEN';
          const draftSubStatusCode: RiskStatusCode = 'Draft';

          this.summaryData.SummaryForm?.get('policyStatus').setValue(riskSubStatusCodeDescription);
          this.submissionData.policyStatus.next(riskSubStatusCodeDescription as RiskStatus);

          if (_subStatusCode === pendingSubStatusCode) {
            this.summaryData.showPendingEndorsementStatus = true;
            this.summaryData.isDraftRiskSubStatus = false;
          } else if(_subStatusCode === draftSubStatusCode) {
            this.summaryData.isDraftRiskSubStatus = true;
          } else {
            this.summaryData.isDraftRiskSubStatus = false;
          }
        });
      });
    });
  }
}
