import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from 'app/core/services/common.service';
import { environment } from '../../../environments/environment';
import Utils from '../../shared/utilities/utils';

@Component({
  selector: 'app-nightly-job-test',
  templateUrl: './nightly-job-test.component.html',
  styleUrls: ['./nightly-job-test.component.scss']
})
export class NightlyJobTestComponent implements OnInit {
  datePickerDateOption: IAngularMyDpOptions;
  frmGrp = new FormGroup({
    requestDate: new FormControl(null),
    isPostSuspendedProcessIncluded: new FormControl(true),
    isRefundClearingJobIncluded: new FormControl(true)
  });
  requestDate: string;
  orchestrationResponseModel = new OrchestrationResponseModel();
  status: string = '';

  constructor(private http: HttpClient, private commonService: CommonService) { }

  ngOnInit() {
    this.datePickerDateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy'
    };
  }

  get noDateValue(): boolean {
    return this.frmGrp.get('requestDate').value === null;
  }

  runJobs(): void {
    this.requestDate = this.reformatRequestDate(this.frmGrp.get('requestDate').value?.singleDate?.formatted);
    Utils.blockUI();
    if (this.requestDate) {
      this.postJobRun().subscribe(result => {
        Utils.unblockUI();
        this.orchestrationResponseModel = JSON.parse(result);
        this.refreshStatus();
        NotifUtils.showSuccess(this.orchestrationResponseModel.CustomMessage);
      }, (err) => {
        Utils.unblockUI();
        NotifUtils.showError('Something Went Wrong');
      });
    }
  }

  private postJobRun(): Observable<string> {
    const nightlyJobUrl = environment.AzureNightlyJobUrl;
    const request = new JobRequest({
      date: this.requestDate,
      isSuspendedPaymentIncluded: this.frmGrp.get('isPostSuspendedProcessIncluded').value,
      isRefundClearingIncluded: this.frmGrp.get('isRefundClearingJobIncluded').value,
    });

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }), responseType: 'text' as 'json' };
    return this.http.post<string>(nightlyJobUrl, request, httpOptions)
      .catch(this.commonService.handleObservableHttpError);
  }

  private reformatRequestDate(requestDate?): string {
    const splitRequestDate = requestDate.split('/');
    return `${splitRequestDate[2]}-${splitRequestDate[0]}-${splitRequestDate[1]}`;
  }

  refreshStatus(): void {
    this.getCheckStatus().subscribe(result => {
      this.status = result.runtimeStatus;
    }, (err) => {
      NotifUtils.showError('Something Went Wrong');
    });
  }

  private getCheckStatus(): Observable<any> {
    const statusUrl = this.orchestrationResponseModel.StatusQueryGetUri;
    return this.http.get<any>(statusUrl)
      .catch(this.commonService.handleObservableHttpError);
  }
}

class OrchestrationResponseModel {
  Id: string;
  StatusQueryGetUri: string;
  SendEventPostUri: string;
  TerminatePostUri: string;
  PurgeHistoryDeleteUri: string;
  RestartPostUri: string;
  IsNonBusinessDay: boolean;
  CustomMessage: string;
  public constructor(init?: Partial<OrchestrationResponseModel>) {
    Object.assign(this, init);
  }
}

class JobRequest {
  date: string;
  isSuspendedPaymentIncluded: boolean;
  isRefundClearingIncluded: boolean;

  public constructor(init?: Partial<JobRequest>) {
    Object.assign(this, init);
  }
}