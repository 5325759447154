import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ClaimsData } from 'app/modules/submission-management/data/claims.data';
import { ClaimsModalModel } from 'app/shared/models/submission/claims/claims.model';
import { BaseClass } from 'app/shared/base-class';
import { ClaimsService } from './claims.service';
import { EndorsementsData } from 'app/modules/submission-management/data/endorsements.data';
import { CoveragesData } from 'app/modules/submission-management/data/coverages.data';

@Injectable({
    providedIn: 'root'
})
export class ClaimsChargeableService extends BaseClass {

    constructor(
        protected claimsService: ClaimsService,
        protected claimsData: ClaimsData,
        protected endorsementsData: EndorsementsData,
        protected coveragesData: CoveragesData){
        super();
    }

  updateConditionaClaimsValue(claim: ClaimsModalModel, effectiveDate: Date): boolean {
    const lossDate = claim.lossDate?.singleDate?.formatted ? new Date(claim.lossDate.singleDate?.formatted) : new Date(claim.lossDate);
    const isChargeable = this.claimsService.getChargeableFieldUpdate(claim.claimType);
    return this.claimsData.checkAPlusChargeableExceptionRule(claim, isChargeable, lossDate, effectiveDate);
  }

  checkClaimsChargeabilityRule(effectiveDate): void {
    const conditionsPremisesDP3 = ['BINONAUTO', 'DAMAG', 'LIABILITY', 'MEDICAL', 'PLBI', 'PLPD', 'SLIP'];
    if (this.claimsData.claimList === null ||this.claimsData.claimList?.length === 0) { return; }

    // for DP3 Liab conditional update
    if (this.coveragesData.coveragesAndDeductiblesForm.get('isCoverageLEnabled').dirty
      || this.endorsementsData.endorsementsGroup.get('personalLiability').dirty) {
      this.claimsData.claimList.forEach(claim => {
        if (conditionsPremisesDP3.includes(claim.claimType)) {
          claim.claimChargeableVsNonChargeable = this.updateConditionaClaimsValue(claim, effectiveDate);
        }
      });
    }

    // for Dog Bite Liability
    if (this.endorsementsData.endorsementsGroup.get('animalLiability').dirty
      && this.claimsData.claimList.findIndex(c => c.claimType === 'DOG') !== -1) {
      this.claimsData.claimList.forEach(claim => {
        if (claim.claimType === 'DOG') {
          claim.claimChargeableVsNonChargeable = this.updateConditionaClaimsValue(claim, effectiveDate);
        }
      });
    }

    // for Identification Theft
    if (this.endorsementsData.endorsementsGroup.get('identityFraud').dirty
      && this.claimsData.claimList.findIndex(c => c.claimType === 'ID') !== -1) {
      this.claimsData.claimList.forEach(claim => {
        if (claim.claimType === 'ID') {
          claim.claimChargeableVsNonChargeable = this.updateConditionaClaimsValue(claim, effectiveDate);
        }
      });
    }

    // for Personal Injury - NonAuto
    if (this.endorsementsData.endorsementsGroup.get('personalInjury').dirty
      && this.claimsData.claimList.findIndex(c => c.claimType === 'PINONAUTO') !== -1) {
      this.claimsData.claimList.forEach(claim => {
        if (claim.claimType === 'PINONAUTO') {
          claim.claimChargeableVsNonChargeable = this.updateConditionaClaimsValue(claim, effectiveDate);
        }
      });
    }

    // for Robbery and Theft/Burglary
    if (this.endorsementsData.endorsementsGroup.get('limitedTheftCoverage').dirty
      && (this.claimsData.claimList.findIndex(c => c.claimType === 'ROBBERY') !== -1
        || this.claimsData.claimList.findIndex(c => c.claimType === 'THEFT') !== -1)) {
      this.claimsData.claimList.forEach(claim => {
        if (claim.claimType === 'ROBBERY' || claim.claimType === 'THEFT') {
          claim.claimChargeableVsNonChargeable = this.updateConditionaClaimsValue(claim, effectiveDate);
        }
      });
    }

    // for Sewer Backup
    if (this.endorsementsData.endorsementsGroup.get('waterBackup').dirty
      && (this.claimsData.claimList.findIndex(c => c.claimType === 'SBKP') !== -1
        || this.claimsData.claimList.findIndex(c => c.claimType === 'WBKP') !== -1)) {
      this.claimsData.claimList.forEach(claim => {
        if (claim.claimType === 'SBKP' || claim.claimType === 'WBKP') {
          claim.claimChargeableVsNonChargeable = this.updateConditionaClaimsValue(claim, effectiveDate);
        }
      });
    }
  }

  checkIfClaimTypeConditionalFieldDirty(): boolean {
    return this.coveragesData.coveragesAndDeductiblesForm.get('isCoverageLEnabled').dirty
      || this.endorsementsData.endorsementsGroup.get('personalLiability').dirty
      || this.endorsementsData.endorsementsGroup.get('animalLiability').dirty
      || this.endorsementsData.endorsementsGroup.get('identityFraud').dirty
      || this.endorsementsData.endorsementsGroup.get('personalInjury').dirty
      || this.endorsementsData.endorsementsGroup.get('limitedTheftCoverage').dirty
      || this.endorsementsData.endorsementsGroup.get('waterBackup').dirty;
  }

}