export class UploadCheckImageResponsetDTO implements IUploadCheckImageResponsetDTO {
  checkImageCloudId?: string | undefined;
  filePath?: string | undefined;
  isUploaded?: boolean | undefined;

  constructor(data?: IUploadCheckImageResponsetDTO) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }
}

export interface IUploadCheckImageResponsetDTO {
  checkImageCloudId?: string | undefined;
  filePath?: string | undefined;
  isUploaded?: boolean | undefined;
}
