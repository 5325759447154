import { Component, OnInit } from '@angular/core';
import { DocumentsLabelConstants } from '../../../../../shared/constants/bind-and-issue.labels.constants';
import { BindAndIssueData } from '../../../../../modules/submission-management/data/bind-and-issue.data';
import { SubmissionPageData } from '../../../../../modules/submission-management/data/submission-page.data';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { IHideTableItems, ITableTd} from '../../../../../shared/models/dynamic/table.interface';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { DocumentsModalComponent } from './documents-modal/documents-modal.component';
import { TableNameConstants } from 'app/shared/constants/table.name.constants';
import { SummaryData } from '../../../../../modules/submission-management/data/summary.data';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../../../shared/base-class';
import { QuickQuoteService } from 'app/core/services/submission/quick-quote.service';
import Utils from 'app/shared/utilities/utils';
import * as _ from 'lodash';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { ClaimsSavingData } from 'app/modules/submission-management/data/claims-saving.data';
import NotifUtils from '../../../../../shared/utilities/notif-utils';
import { InfoMessageConstant } from '../../../../../shared/constants/info-message.constants';
import { UwApprovalData } from 'app/modules/submission-management/data/uw-approval.data';
import { PolicyService } from 'app/core/services/submission/policy.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent extends BaseClass implements OnInit {
  public TableNameConstants = TableNameConstants;
  protected DocumentsLabelConstants = DocumentsLabelConstants;
  protected ErrorMessageConstant = ErrorMessageConstant;
  protected GenericLabel = GenericLabel;
  protected isOpen: boolean = true;
  protected modalRef: BsModalRef | null;
  protected selectedTableItems = [];
  protected statusList: ('Active' | 'Cancel')[] = ['Active', 'Cancel'];
  public isDocumentsLoading = false;

  protected hideItems: IHideTableItems = {
    checkboxButton: false,
    viewIcon: true
  };

  constructor(
    public bindAndIssueData: BindAndIssueData,
    protected submissionData: SubmissionPageData,
    protected modalService: BsModalService,
    protected summaryData: SummaryData,
    public quickQuoteService: QuickQuoteService,
    protected entityData: EntityRiskData,
    protected claimsSavingData: ClaimsSavingData,
      protected uwApprovalData: UwApprovalData,
      protected policyService: PolicyService
  ) {
    super();
  }

  ngOnInit() {
    this.mapDocumentList();
    this.bindAndIssueData.documentsTableRows = [];
    this.initData(this.bindAndIssueData.documentsList);
  }

  // CENTAURI-493 will implement this when APlus document development resumes
  retrieveRiskBindDocument(): void {
    this.quickQuoteService.getRiskBindDocuments(this.summaryData.SummaryForm.get('riskId').value, this.summaryData.SummaryForm.get('riskDetailId').value)
      .subscribe(result => {
        this.isDocumentsLoading = false;
        this.bindAndIssueData.populateDocumentSection(result.riskBindDocuments, this.uwApprovalData.uwrSavedData$.value.riskUWApprovals);
        this.mapDocumentList();
        this.bindAndIssueData.documentsTableRows = [];
        this.initData(this.bindAndIssueData.documentsList);
      }, error => {
        this.isDocumentsLoading = false;
      });
  }

  mapDocumentList(): void {
    this.bindAndIssueData.documentsTableRows.forEach((item, index) => {
      this.bindAndIssueData.documentsList[index].id = item.id;
    });
  }

  initData(documentsList: any): void {
    documentsList.map(x => {
      const documents = {
        id: x.id,
        description: x.description,
        category: x.category,
        dateAdded: x.dateAdded ? x.dateAdded : x.createdDate,
        fileName: x.fileName,
        riskBindId: x.riskBindId,
        documentsId: x.documentsId ? x.documentsId : x.id,
        File: x.File,
        isUploaded: x.isUploaded,
        createdBy: x.createdBy
      };
      this.createTableItemsOnInit(documents);
    });
  }

  editTableItem(rowId: any, tableRow: string): void {
    this.bindAndIssueData[tableRow].forEach((item) => {
      if (item.id === rowId) {
        const fileName = this.findFileNameIndDocumentList(item.id);
        this.showEditUploadDocument({
          id: item.id,
          category: item.tr[0].value,
          description: item.tr[1].value,
          fileName: fileName,
          dateAdded: item.tr[2].value
        });
      } else {
        item.edit = false;
      }
    });
  }

  updateTableItem(row: any): void {
    this.bindAndIssueData.documentsTableRows.forEach((item) => {
      if (item.id === row.id) {
        item.tr[0].value = row.category;
        item.tr[1].value = row.description;
        // item.tr[2].value = row.file && row.file?.name ? row.file.name : row.file;
        item.tr[2].value = row.dateAdded;
        item.tr[0].display = this.bindAndIssueData.documentsCategoryList.find(cat => cat.code === row.category).description;
        item.tr[1].display = row.description;
        // item.tr[2].display =  row.file && row.file?.name ? row.file.name : row.file;
        item.tr[2].display = row.dateAdded?.singleDate.formatted ? row.dateAdded?.singleDate.formatted : row.dateAdded;
      }
    });

    this.bindAndIssueData.documentsList.forEach((item) => {
      if (item.id === row.id) {
        item.category = row.category;
        item.description = row.description;
        item.fileName = row.fileName;
        item.filePath = row.filePath;
        item.dateAdded = row.dateAdded;
        item.isUploaded = false;
      }
    });
  }

  findFileNameIndDocumentList(id: string): string {
    if (id) {
      const document = this.bindAndIssueData.documentsList.find(a => a.id === id);
      if (document){
        return document.fileName;
      }
    }
    return '';
  }

  deleteTableItem(rowId: any, tableRow: string): void {
    this.bindAndIssueData[tableRow].forEach((item, index) => {
      if (item.id === rowId) {
        const payload = {
          riskId: this.summaryData.SummaryForm.get('riskId').value,
          riskDetailId: this.summaryData.SummaryForm.get('riskDetailId').value,
          riskBindDocumentId: item.id
        };
        Utils.blockUI();
        this.quickQuoteService.deleteBindDocument(payload).subscribe(res => {
          this.bindAndIssueData[tableRow].splice(index, 1);
          this.bindAndIssueData.documentsList.splice(index, 1);
          Utils.unblockUI();
        },
          err => {
            Utils.unblockUI();
            console.log(err);
          }
        );
      }
    }, this);
  }

  showUploadDocument(): void {
    const initialState = {
      title: this.DocumentsLabelConstants.addTitle,
      idForSaving: this.generateId(),
    };
    this.modalRef = this.modalService.show(DocumentsModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.content.event.pipe(takeUntil(this.stop$)).subscribe((res) => {
      this.createTableItemsOnAdd(res.data);
      this.bindAndIssueData.documentsList.unshift(res.data);
    });
  }

  createRowItem(newItem: any): ITableTd[] {
    const tr: ITableTd[] = [];
    const fields: string[] = [
      'category',
      'description',
      // 'fileName',
      'dateAdded'
    ];

    fields.forEach((item, index) => {
      let display: any;

      switch (item) {
        case 'category':
          display = this.bindAndIssueData.documentsCategoryList.find(category => category.code === newItem[item])?.description;
          break;
        case 'fileName':
          display = newItem[item].name ? newItem[item].name : newItem[item];
          break;
        case 'dateAdded':
          display = newItem[item]?.singleDate?.formatted ? newItem[item]?.singleDate?.formatted : this.bindAndIssueData.mapDate(newItem[item]);
          break;
        default:
          display = Boolean(newItem[item]) ? String(newItem[item]) : '';
          break;
      }

      tr.push({
        id: newItem.id,
        value: Boolean(newItem[item]) ? newItem[item] : '',
        display: display,
      });
    });

    return tr;
  }

  createTableItemsOnInit(documents: any): void {
    this.pushDocumentItemOnInit(documents, this.createRowItem(documents));
    this.checkRenewalOfferDocs();
  }

  pushDocumentItemOnInit(documents: any, tableRows: ITableTd[]): void {
    this.bindAndIssueData.documentsTableRows.push({
      id: documents.id,
      tr: tableRows,
      createdBy: documents.createdBy,
    });
  }

  createTableItemsOnAdd(documents: any): void {
    this.unshiftDocumentItemOnAdd(documents, this.createRowItem(documents));
    this.checkRenewalOfferDocs();
  }

  unshiftDocumentItemOnAdd(documents: any, tableRows: ITableTd[]): void {
    this.bindAndIssueData.documentsTableRows.unshift({
      id: documents.id,
      tr: tableRows,
      createdBy: documents.createdBy,
    });
  }

  checkRenewalOfferDocs(): void {
    const isSystemGenerated = 'System';

    this.bindAndIssueData.documentsTableRows = this.bindAndIssueData.documentsTableRows.map(item => {
      const document = this.bindAndIssueData.documentsList.find(doc => doc.id === item.id);

      if (Boolean(document) && document?.createdByFullName === isSystemGenerated) {
        item.disableUpdateIcon = true;
        item.disableDeleteIcon = true;
      }

      return item;
    });
  }

  showEditUploadDocument(model): void {
    const data = model;
    const currentDocument = _.find(this.bindAndIssueData.documentsList, document => document.id === model.id);
    const documents = {
      id: data.id,
      description: data.description,
      category: data.category,
      fileName: data.fileName,
      dateAdded: data.dateAdded,
      isEdit: true,
      filePath: currentDocument.filePath
    };

    const initialState = {
      title: this.DocumentsLabelConstants.editTitle,
      documentsModel: documents,
      isAdd: false,
    };

    this.modalRef = this.modalService.show(DocumentsModalComponent, {
      initialState,
      backdrop: 'static',
      ignoreBackdropClick: true
    });

    this.modalRef.content.event.pipe(takeUntil(this.stop$)).subscribe((res) => {
      this.updateTableItem(res.data);
    });
  }

  generateId(): any {
    const maxId = this.bindAndIssueData.documentsTableRows.length > 0
      ? Math.max(...this.bindAndIssueData.documentsTableRows.map(document => document.id)) + 1
      : 1;

    return maxId;
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
    this.submissionData.isDocumentsOpen = this.isOpen;
  }

  loadSelectedTableItems(selectedItems: any): void {
    this.selectedTableItems = selectedItems;
  }

  onFileClick(rowId: any): void{
    const document = this.bindAndIssueData.documentsList.find(item => item.id === rowId);
    if(Boolean(document?.filePath)) {
      this.policyService.generateSASURL(Utils.URLEncoder(document?.filePath, document?.fileName)).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
        window.open(resultSASURL);
      });
    } else {
      NotifUtils.showInfo(InfoMessageConstant.generationDocumentMessage);
    }
  }
}
