import { Injectable, Input } from '@angular/core';
import { NavData } from '../../../_nav';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    // navItems: NavData[];
    @Input() navItems: NavData[] = [];
    @Input() leftSidebarVisible: boolean = false;
    submissionSidebarVisible: boolean = true;

    updateMenu(items: NavData[]): void {
        this.navItems = items;
        this.leftSidebarVisible = true;
    }

    clearMenu(): void {
        this.navItems = [];
        this.leftSidebarVisible = false;
    }
}
