import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomValidators } from 'app/shared/validators/custom.validator';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { TaskLabelsConstants } from 'app/shared/constants/task.labels.constants';
import { LvTaskDocStatus, LvTaskDocType } from 'app/shared/constants/task.options.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TaskService } from 'app/core/services/task/task.service';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from 'app/shared/base-class';
import { ITaskDocumentResultDTO, TaskDocumentRequestDTO, TaskDocumentsDTO } from 'app/shared/models/data/dto/task/task.dto';
import Utils from 'app/shared/utilities/utils';
import { TaskData } from 'app/modules/dashboard/data/task.data';
import { GenericLabel } from 'app/shared/constants/generic.labels.constants';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { DatePipe } from '@angular/common';
import { AuthService } from 'app/core/services/auth.service';
import * as _ from 'lodash';
import { PolicyService } from 'app/core/services/submission/policy.service';

@Component({
  selector: 'app-task-documents-modal',
  templateUrl: './task-documents-modal.component.html',
  styleUrls: ['./task-documents-modal.component.scss']
})
export class TaskDocumentsModalComponent extends BaseClass implements OnInit, OnDestroy {

  title: string;
  isAddDocument: boolean = true;
  modalData: TaskDocumentsDTO;
  hideSaveButton: boolean = false;
  isViewDocument: boolean = false;

  public taskDocumentModalLabels = TaskLabelsConstants;
  public ErrorMessageConstant = ErrorMessageConstant;
  public genericConstants = GenericConstants;
  GenericLabel = GenericLabel;
  LvTaskDocType = LvTaskDocType;
  LvTaskDocStatus = LvTaskDocStatus;
  modalRef: BsModalRef | null;
  documentFileUploadForm: FormGroup;

  fileLabel: string;
  currentFileLabel: string;

  fileToUpload: File = null;
  fileSizeExceeds: boolean = false;
  isCancel: boolean = false;

  constructor(
    protected fb: FormBuilder,
    protected modalService: BsModalService,
    public bsModalRef: BsModalRef,
    protected taskService: TaskService,
    public taskData: TaskData,
    protected datePipe: DatePipe,
    public authService: AuthService,
    public policyService: PolicyService) {
      super();
    }

  ngOnInit() {
    this.initializeForm();
    if (!this.isAddDocument) {
      this.populateEditDocumentModal();
    }
  }

  ngOnDestroy() {
    this.isAddDocument = true;
    this.isViewDocument = false;
  }

  initializeForm(): void {
    this.documentFileUploadForm = this.fb.group({
      dateAdded: new FormControl(this.datePipe.transform(this.authService.getCustomDate(), this.taskData.dateFormat), [Validators.required]),
      addedBy: new FormControl(this.taskData.currentUserName, [Validators.required]),
      file: ['', [Validators.required, CustomValidators.requiredFileFormat(this.genericConstants.acceptedFileTypes)]],
      description: ['', [Validators.required, this.taskData.noWhitespaceValidator]],
      type: ['', [Validators.required]],
      status: ['', [Validators.required]],
      fileName: [this.modalData?.fileName],
      filePath: [this.modalData?.filePath]
    });
    this.mapFile(this.modalData?.fileName);
    if (this.isViewDocument) {
      this.documentFileUploadForm.disable();
    }
  }

  hideMyModal(): void {
    this.isCancel = true;
    this.bsModalRef.hide();
  }

  mapFile(file?) {
    this.documentFileUploadForm.get('file').setValue(file);
    if (file?.name) {
      this.fileLabel = file.name;
    } else if (file) {
      this.fileLabel = file;
    } else {
      this.fileLabel = 'No File Chosen';
    }
  }

  okMyModal(): void {
    if (this.documentFileUploadForm.invalid) {
      return;
    }
    const file = this.fileToUpload ?? this.documentFileUploadForm.get('file').value;
    const payload: TaskDocumentRequestDTO = {
      riskId: this.taskData.currentRiskId,
      riskDetailId: this.taskData.currentRiskDetailId,
      taskId: this.taskData.currentTaskId,
      stateCode: this.taskData.currentStateCode,
      file: file,
      fileName: file?.name
    };

    Utils.blockUI();
    this.taskService.addTaskDocument(payload).pipe(takeUntil(this.stop$)).subscribe(result => {
      if (this.isAddDocument) {
        this.pushDocument(result);
      } else {
        this.updateDocument(result);
      }
      this.taskData.hasDocumentChanges = true;
      Utils.unblockUI();
      this.bsModalRef.hide();
      this.taskData.setTaskDocumentsList(1);
    }, error => {
      const err = error;
      this.taskData.setTaskDocumentsList(1);
      Utils.unblockUI();
      this.bsModalRef.hide();
    });
  }

  pushDocument(data: ITaskDocumentResultDTO): void {
    const taskDocument: TaskDocumentsDTO = {
      taskId: this.taskData.currentTaskId,
      taskDocType: this.documentFileUploadForm.get('type')?.value,
      taskDocStatus: this.documentFileUploadForm.get('status')?.value,
      description: this.documentFileUploadForm.get('description')?.value,
      fileName: data.fileName,
      filePath: data.filePath,
      createdDate: this.authService.getCustomDate(),
      initialId: this.taskData.taskDocuments.length + 1,
      isDeletable: true,
      isEditable: true,
      createdBy: this.taskData.currentUserId,
      createdByFullName: this.taskData.currentUserName
    };
    this.taskData.taskDocuments = _.concat(this.taskData.taskDocuments, taskDocument);
  }

  updateDocument(data: ITaskDocumentResultDTO): void {
    const document = this.taskData.taskDocuments.find(a => a.id === this.modalData.id);
    const documentIndex = this.taskData.taskDocuments.indexOf(document);
    const taskDocument: TaskDocumentsDTO = {
      taskId: this.taskData.currentTaskId,
      taskDocType: this.documentFileUploadForm.get('type')?.value,
      taskDocStatus: this.documentFileUploadForm.get('status')?.value,
      description: this.documentFileUploadForm.get('description')?.value,
      fileName: data.fileName || document.fileName,
      filePath: data.filePath || document.filePath,
      createdDate: this.isAddDocument ? data.createdDate : document.createdDate,
      id: this.modalData.id,
      createdBy: document.createdBy,
      isDeletable: true,
      isEditable: true,
      createdByFullName: document.createdByFullName
    };
    this.taskData.taskDocuments[documentIndex] = taskDocument;
  }

  handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
    this.fileLabel = this.fileToUpload.name;
    this.documentFileUploadForm.get('file').setValue(this.fileToUpload);
    this.fileSizeExceeds = false;

    this.fileSizeExceeds = this.fileToUpload?.size > this.genericConstants.fileSizeLimit ? true : false;
  }

  get invalidFileTypeMessage(): string {
    return `The document ${this.documentFileUploadForm.get('file').value?.name} could not be uploaded. The file type is invalid.
    Note: Only the following file types are valid and can be attached – <list of valid file types e.g. ".pdf", ".doc", ".docx", ".msg", ".jpg", ".jpeg", ".bmp", ".png", ".xls", ".xlsx", ".txt", ".zip">`;
  }

  get acceptedFileTypes(): string {
    let acceptedFileTypes: string = '';

    this.genericConstants.acceptedFileTypes.forEach(item => {
      acceptedFileTypes += `.${item}, `;
    });

    return acceptedFileTypes;
  }

  checkForm(): boolean {
    return !(this.documentFileUploadForm.valid &&
      this.documentFileUploadForm.get('file').valid) || this.fileSizeExceeds;
  }

  isViewDisable(): boolean {
    return !(!this.isAddDocument && this.modalData && this.modalData.filePath !== null);
  }

  getDocumentFormValue(name: string): string {
    return this.documentFileUploadForm.get(name)?.value ?? '';
  }

  populateEditDocumentModal(): void {
    this.documentFileUploadForm.get('dateAdded').setValue(this.datePipe.transform(this.modalData.createdDate, this.taskData.dateFormat));
    this.documentFileUploadForm.get('addedBy').setValue(this.modalData.createdByFullName);
    this.documentFileUploadForm.get('description').setValue(this.modalData.description);
    this.documentFileUploadForm.get('type').setValue(this.modalData.taskDocType);
    this.documentFileUploadForm.get('status').setValue(this.modalData.taskDocStatus);
    this.currentFileLabel = this.fileLabel;
  }

  viewDocument(): void {
    this.policyService.generateSASURL(this.modalData.filePath).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
      window.open(resultSASURL);
    });
  }

  hasDocumentChange(): boolean {
    if (this.isAddDocument) {
      return false;
    }

    const hasChanges = this.modalData.description === this.getDocumentFormValue('description') &&
                      this.modalData.taskDocType === this.getDocumentFormValue('type') &&
                      this.modalData.taskDocStatus === this.getDocumentFormValue('status') &&
                      this.currentFileLabel === this.fileLabel;

    return hasChanges;
  }
}
