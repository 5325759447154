
import { IProcessedRaterView } from "app/shared/models/rater-premium/processed-rater-view.model";

export interface RaterCalculateDP3State {
  isLoading: boolean | null;
  list: IProcessedRaterView[] | null;
}
export const initialState = {
  isLoading: null,
  list: null
}