import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AddPaymentAccountModalComponent } from 'app/modules/policy-management/policy-billing/recurring-payment-enrollment/add-payment-account-modal/add-payment-account-modal.component';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { PaymentAccountResponse } from 'app/shared/models/data/dto/billing/payment-account-response.dto';
import { PaymentProfileRequest } from 'app/shared/models/data/dto/billing/payment-profile-request.dto';
import { PolicyBillingData } from 'app/modules/policy-management/data/policy-billing.data';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { BillingLabelConstants } from 'app/shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { PolicyBillingLabelsConstants } from 'app/shared/constants/policy-billing.labels.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BaseClass } from '../../../../../shared/base-class';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { UpdateAllowRecurringRequestDto } from 'app/shared/models/data/dto/billing/update-allow-recurring-request.dto';


@Component({
  selector: 'app-rn-recurring-payment',
  templateUrl: './rn-recurring-payment.component.html',
  styleUrls: ['./rn-recurring-payment.component.scss']
})
export class RnRecurringPaymentComponent extends BaseClass implements OnInit {
  @Input() isRenewal: boolean = false;

  isOpen: boolean = true;
  public recurringPaymentConstants = PolicyBillingLabelsConstants.recurrentPaymentEnrollment;
  public billingLabelConstants = BillingLabelConstants;
  public policyBillingLabelConstants = PolicyBillingLabelsConstants;

  ErrorMessageConstant = ErrorMessageConstant;
  confirmationModalRef: BsModalRef | null;
  modalRef: BsModalRef | null;

  public paymentAccountsHeaders: string[] = [
    this.recurringPaymentConstants.accountDescription,
    this.recurringPaymentConstants.accountType,
    this.recurringPaymentConstants.accountDefault
  ];

  constructor(protected fb: FormBuilder,
    protected modalService: BsModalService,
    public billingData: PolicyBillingData,
    protected entityRiskData: EntityRiskData
  ) { super(); }

  ngOnInit() {
    if (!Boolean(this.billingData.addPaymentAccountForm)) {
        this.billingData.initPaymentAccountForm();
        this.billingData.initAccountHolderForm();
    }

    if (!this.entityRiskData.isDonePopulatingForms) {
      this.entityRiskData.isDonePopulatingForms$.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(() => {
        if (this.entityRiskData.statusesForRenewalCheckbox() && this.entityRiskData.isNotCurrentPolicyNonRenewedWithRenewalNotTaken()) {
          this.billingData.getRecurringPaymentDetails(this.entityRiskData.getRiskId());
        }
      });
    } else {
      if (this.entityRiskData.statusesForRenewalCheckbox() && this.entityRiskData.isNotCurrentPolicyNonRenewedWithRenewalNotTaken()) {
        this.billingData.getRecurringPaymentDetails(this.entityRiskData.getRiskId());
      }
    }
  }

  get isDisabled(): boolean {
    return this.billingData.rnPaymentAccounts.some(val => val.isDefault === true);
  }

  get paymentAccounts(): PaymentAccountResponse[] {
    return this.billingData.rnPaymentAccounts;
  }

  get accountHolderForm(): FormGroup {
    return this.billingData.accountHolderForm;
  }

  get hasAccountHolder(): boolean {
    return this.billingData.hasAccountHolder;
  }

  get isSaveButtonEnabled(): boolean {
    return this.billingData.accountHolderForm.valid;
  }

  onEnrollmentSave() {
  }

  onAddPaymentAccount() {
    const initialState = {
      paymentProfileRequest: this.billingData.setDefaultAccountHolderInfo()
    };

    this.modalRef = this.showRecurringSectionModal(AddPaymentAccountModalComponent, initialState);
  }


  onUpdatePaymentItem(item: PaymentAccountResponse): void {
    if (item?.id) {
      item.isDefault = !item.isDefault;
      this.billingData.updateRenewalPaymentAccount(item);
    };
  }

  onDeletePaymentAccount(item: PaymentAccountResponse): void {
    const initialState = {
      message: 'Are you sure you want to delete this payment account?',
      id: item?.id,
      isRecurring: true
    };
    this.confirmationModalRef = this.showRecurringSectionModal(ConfirmationModalComponent, initialState);
    this.confirmationModalRef.content.event.subscribe((result) => {
      if (result.data) {
        this.billingData.deletePaymentAccount(item);
        this.billingData.paymentAccountNotifier.subscribe(response => {
          if (response.isCompleted) {
            this.confirmationModalRef.hide();
          }
        });
      }
    });
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }

  updateAutoPay(): void {
    const payload: UpdateAllowRecurringRequestDto = {
      riskId: this.entityRiskData.getRiskId(),
      isRecurringPayment: this.accountHolderForm.get('agreeEnrollAutoPay').value,
    };
    this.billingData.updateAllowRecurring(payload);
  }

  showRecurringSectionModal(component?, initialState?): BsModalRef {
    return this.modalService.show(component, {
      initialState,
      backdrop: 'static',
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md modal-dialog-centered',
    });
  }

}
