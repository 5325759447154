import { Injectable } from '@angular/core';
import { QuickQuoteService } from '../../../core/services/submission/quick-quote.service';
import { SaveUWApprovalDTO } from '../../../shared/models/data/dto/quick-quote/save-uw-approval.dto';
import { BaseClass } from '../../../shared/base-class';
import { SummaryData } from './summary.data';
import { UwApprovalData } from './uw-approval.data';
import { RiskUWApprovalDTO } from '../../../shared/models/data/dto/quick-quote/uw-approval.dto';
import Utils from '../../../shared/utilities/utils';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { ToastrService } from 'ngx-toastr';
import { PathConstants } from '../../../shared/constants/path.constants';
import { InfoMessageConstant } from '../../../shared/constants/info-message.constants';
import { ErrorMessageConstant } from '../../../shared/constants/error-message.constants';
import { takeUntil, distinctUntilChanged, delay } from 'rxjs/operators';
import { serialize } from 'object-to-formdata';
import { IFile, ITableTd } from '../../../shared/models/dynamic/table.interface';
import { PolicySummaryData } from '../../../modules/policy-management/data/policy-summary.data';
import { EntityRiskData } from './entity-risk.data';
import { SubmissionPageData } from './submission-page.data';
import { GenericConstants } from '../../../shared/constants/generic.constants';
import { UwApprovalResultDTO } from '../../../shared/models/data/dto/quick-quote/uw-approval-result.dto';
import { AllRiskBindNoteDTO } from '../../../shared/models/data/dto/policy-management/all-risk-bind-note.dto';
import { NotesCategories } from '../../../shared/constants/notes.options.constants';
import { PolicyNotesData } from '../../../modules/policy-management/data/policy-notes.data';
import * as _ from 'lodash';
import { TaskGenerationService } from '../../../core/services/submission/task-generation/task-generation.service';
import { OfacSearchConstants } from '../../../shared/constants/ofac-search.constants';
import { RiskStatusCode } from 'app/shared/models/data/dto/quick-quote/risk.dto';
import { PolicyIssueData } from 'app/modules/policy-management/data/policy-issue.data';
import { Store } from '@ngrx/store';
import { updateSubmissionStatusFromUwApprovalData } from 'app/store/submission/submission.actions';
import { Router } from '@angular/router';
import { ThirdPartyData } from './third-party.data';
import { Subscription } from 'rxjs';
import { updatePolicyStatusFromUwApprovalData, updatePolicySubStatusFromUwApprovalData } from 'app/store/policy/policy.actions';
import { GetRiskStatusPipe } from 'app/shared/pipes/get-risk-status.pipe';
import {
  updateSubmissionUWRsIsLoadingFromUwApprovalSavingData,
  updateSubmissionUWRsListFromUwApprovalSavingData
} from 'app/store/submission-uwrs/submission-uwrs.actions';
import {
  updateAppIsLoadingFromUwApprovalSavingData,
  updateAppRuwIsLoadingFromUwApprovalSavingData
} from 'app/store/app/app.actions';
import {
  updatePolicyUWRsIsLoadingFromUwApprovalSavingData,
  updatePolicyUWRsListFromUwApprovalSavingData
} from 'app/store/policy-uwrs/policy-uwrs.actions';
import { updateUWApprovalIsLoadingFromUWApprovalSavingData } from '../../../store/uw-approval/uw-approval.actions';

@Injectable({
  providedIn: 'root',
})
export class UwApprovalSavingData extends BaseClass {
  protected isUWApprovalForSaving: boolean = false;
  genericConstants = GenericConstants;
  currentActionType: any;
  genericContants = GenericConstants;
  saveUWApprovalSubscription: Subscription;

  constructor(public uwApprovalData: UwApprovalData,
    protected quickQuoteService: QuickQuoteService,
    protected summaryData: SummaryData,
    protected toast: ToastrService,
    protected policySummaryData: PolicySummaryData,
    public entityRiskData: EntityRiskData,
    protected submissionData: SubmissionPageData,
    protected policyNotesData: PolicyNotesData,
    protected taskGeneration: TaskGenerationService,
    protected policyIssueData: PolicyIssueData,
    protected store: Store,
    protected router: Router,
    protected thirdPartyData: ThirdPartyData,
    protected getRiskStatus: GetRiskStatusPipe
    ) {
    super();
  }

  saveUWApproval(isFromAdd: boolean = false, rowId: any = null, isMora?: boolean) {
    try {
      this.entityRiskData.startSavingForms();
      this.store.dispatch(updateUWApprovalIsLoadingFromUWApprovalSavingData({ isLoading: true }));
      const riskId = this.entityRiskData.getRiskId();
      const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;

      const uwApprovalRequest: SaveUWApprovalDTO = {
        riskId: riskId,
        riskDetailId: riskDetailId,
        riskUWApprovals: [],
        actionType: this.submissionData.actionType,
        policyURL: window.location.origin
      };

      this.currentActionType = this.submissionData.actionType;
      this.updateUwApprovalTableRows(isFromAdd, rowId, riskId, uwApprovalRequest, riskDetailId);

      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
        allowEmptyArrays: true,
      };
      const formData = serialize(uwApprovalRequest, options);

      this.updateRiskUWApprovalDocuments(uwApprovalRequest, formData);
      this.postUWApproval(riskId, riskDetailId, formData, isMora);
    } catch (error) {
      this.entityRiskData.finishSavingForms();
      this.store.dispatch(updateUWApprovalIsLoadingFromUWApprovalSavingData({ isLoading: false }));
      console.log(error); // for error logging
    }
  }

  updateUwApprovalTableRows(isFromAdd: boolean, rowId: any, riskId: any, uwApprovalRequest: SaveUWApprovalDTO, riskDetailId: any): void {
    this.uwApprovalData.uwApprovalTableRows.forEach((item) => {
      const riskUWApprovalDocuments = [];
      this.uwApprovalData.uwApprovalTableRows.find(x => x.id === item.id).files?.forEach(a => {
        riskUWApprovalDocuments.push({
          file: isFromAdd ? (item.id === rowId? a.file : null) : a.file,
          isUploaded: a.isUploaded,
          fileId: a.fileId,
          riskUWApprovalId: a.riskUWApprovalId,
        });
      });

      const approval: RiskUWApprovalDTO = {
        id: item.itemId ?? null,
        riskId: riskId,
        riskDetailId: riskDetailId,
        uwApprovalDescription: item.tr[0].value,
        uwApprovalCode: this.getDropdownValueId(item.tr[1].value, this.uwApprovalData.statusOptions, true, true),
        uwComment: !item.tr[2].value ? null : item.tr[2].value,
        agentComment: !item.tr[3].value ? null : item.tr[3].value,
        riskUWApprovalDocuments: riskUWApprovalDocuments,
        remarks: !item.tr[3].value ? null : item.tr[3].value
      };
      uwApprovalRequest.riskUWApprovals.push(approval);
    });
  }

  updateRiskUWApprovalDocuments(uwApprovalRequest: SaveUWApprovalDTO, formData: any): void {
    uwApprovalRequest.riskUWApprovals.forEach((outerDoc, outerindex) => {
      outerDoc.riskUWApprovalDocuments.forEach((innerDoc, innerIndex) => {
        if (innerDoc.fileId) {
          formData.append(`riskUWApprovals[${outerindex}].riskUWApprovalDocuments[${innerIndex}].id`, innerDoc?.fileId);
        }
        if (innerDoc.riskUWApprovalId) {
          formData.append(`riskUWApprovals[${outerindex}].riskUWApprovalDocuments[${innerIndex}].riskUWApprovalId`, innerDoc?.riskUWApprovalId);
        }
        if (innerDoc.isUploaded) {
          return;
        }
        formData.append(`riskUWApprovals[${outerindex}].riskUWApprovalDocuments[${innerIndex}].file`, innerDoc?.file);
        });
    });
  }

  postUWApproval(riskId: any, riskDetailId: any, formData: any, isMora?: boolean): void {
    if (riskId && riskDetailId) {
      if(this.saveUWApprovalSubscription) {
        this.saveUWApprovalSubscription.unsubscribe();
      }
      this.policySummaryData.saveUwApprovalComplete$.next(false);
      this.store.dispatch(updateUWApprovalIsLoadingFromUWApprovalSavingData({ isLoading: true }));

      if (this.policySummaryData.isPolicy) {
        this.policySummaryData.showRiskSubStatus = false;
      }

      if (!isMora) {
        this.store.dispatch(updateAppIsLoadingFromUwApprovalSavingData({ isLoading: true }));
      }

      if (this.policySummaryData.isPolicy) {
        this.store.dispatch(updatePolicyUWRsIsLoadingFromUwApprovalSavingData({ isLoading: true }));
      } else {
        this.store.dispatch(updateSubmissionUWRsIsLoadingFromUwApprovalSavingData({ isLoading: true }));
      }

      this.saveUWApprovalSubscription = this.quickQuoteService.postUWApproval(formData).pipe(takeUntil(this.stop$)).subscribe(res => {
        this.entityRiskData.finishSavingForms();
        const result = <UwApprovalResultDTO>res;
        this.updateCollectionID(result, riskId);
        this.unmarkUWApprovalForSaving();
        const check = this.policySummaryData.isPolicy ? this.uwApprovalData.checkUwrChangesForPolicy(result) :
          this.uwApprovalData.checkUwrChangesForQuote(result);
        this.uwApprovalData.uwrSavedData$.next(result);

        if (this.currentActionType) {
          this.mapRiskUWApproveDeclinedNotes(result.riskBindNotes);
        }

        // Fix for the restricted access due to UW Approval is the last thing was called during on load
        const isExternal = this.summaryData.authService.userType.value === GenericConstants.userType.external;
        if ((this.policySummaryData.renewalStatusCode === 'RRNPEN' && this.entityRiskData.getRisk().riskStatusCode !== 'CAN') && isExternal) {
          NotifUtils.showError('Restricted Access', () => {
            this.router.navigate(['submissions/list']);
          });
        }

        NotifUtils.showConsoleSuccess(`${PathConstants.Submission.Submission.UWApproval} ${InfoMessageConstant.savedSuccessfullyMessage}`);

        if (this.summaryData.raterData.isViewQuoteProposalClicked) {
          this.summaryData.raterData.recalculatePremiumAndSave$.next(true);
          this.summaryData.raterData.isViewQuoteProposalClicked = false;
        }
        if (this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId())) {
          this.policySummaryData.subStatusCode$.next(res.riskSubStatusCode as RiskStatusCode);
          this.policySummaryData.showRiskSubStatus = true;
          this.store.dispatch(updatePolicyStatusFromUwApprovalData({ status: this.getRiskStatus.transform(result.riskStatusCode) }));
          this.store.dispatch(updatePolicySubStatusFromUwApprovalData({ subStatus: this.getRiskStatus.transform(result.riskSubStatusCode) }));
          this.store.dispatch(updatePolicyUWRsIsLoadingFromUwApprovalSavingData({ isLoading: false }));
          this.store.dispatch(updatePolicyUWRsListFromUwApprovalSavingData({ list: result?.riskUWApprovals }));
        } else {
          this.setQuoteStatus(result.riskStatusCode, result.riskSubStatusCode);
          this.store.dispatch(updateSubmissionUWRsIsLoadingFromUwApprovalSavingData({ isLoading: false }));
          this.store.dispatch(updateSubmissionUWRsListFromUwApprovalSavingData({ list: result?.riskUWApprovals }));
        }

        this.submissionData.hasUwApprovalChange.next(result.hasUwApprovalChange);
        this.submissionData.uwApprovalSavingComplete.next();
        this.policySummaryData.saveUwApprovalComplete$.next(true);
        if (!this.thirdPartyData.isCurrentlyOrdering) {
          this.store.dispatch(updateAppIsLoadingFromUwApprovalSavingData({ isLoading: false }));
        }
        this.store.dispatch(updateUWApprovalIsLoadingFromUWApprovalSavingData({ isLoading: false }));
      },
        err => {
          this.entityRiskData.finishSavingForms();
          this.store.dispatch(updateAppIsLoadingFromUwApprovalSavingData({ isLoading: false }));
          this.store.dispatch(updateUWApprovalIsLoadingFromUWApprovalSavingData({ isLoading: false }));
          this.toast.error(ErrorMessageConstant.savingErrorMessage);
          // add code here for error log
        }
      );
    }
  }

  // Prevent Application status reverting back to Quoted
  setQuoteStatus(riskStatusCode: RiskStatusCode, riskSubStatusCode: RiskStatusCode): void {
    if (riskStatusCode !== this.genericContants.quoteStatusCode.quo) {
      this.submissionData.quoteStatus.next(this.submissionData.getQuoteStatus(riskStatusCode));
      this.store.dispatch(updateSubmissionStatusFromUwApprovalData({ status: this.submissionData.getQuoteStatus(riskStatusCode) }));
    }
  }

  getDropdownValueId(name: string, dropdown: any[], isLvRater: boolean = false, isChangedToCode: boolean = false) {
    let value = '';
    if (isLvRater) {
      value = isChangedToCode ? dropdown.find(x => x.code === name)?.code : dropdown.find(x => x.code === name)?.id;
    }​​​​​​​​​​​​ else {
      value = dropdown.find(x => x.name === name)?.value;
    }
    return value;
  }

  updateCollectionID(response: any, riskId: string): void {
    const riskUWApprovals = response?.riskUWApprovals;
    const uwApprovalFile: IFile[] = [];

    if (riskUWApprovals) {
      this.checkRiskUWApprovalDocuments(riskUWApprovals, uwApprovalFile);
      this.checkUwApprovalTableRows(riskUWApprovals);
    }
  }

  checkRiskUWApprovalDocuments(riskUWApprovals: any, uwApprovalFile: IFile[]): void {
    riskUWApprovals.forEach((element,index) => {
      element.riskUWApprovalDocuments.forEach((innerElement,innerIndex) => {

        const dateAdded = new Date(innerElement.createdDate);
        const dateString = `${('0' + (dateAdded.getMonth() + 1)).slice(-2)}/${('0' + (dateAdded.getDate())).slice(-2)}/${dateAdded.getFullYear()}`;
        uwApprovalFile.push({
          id: uwApprovalFile.length + 1,
          fileName: innerElement.fileName,
          filePath: innerElement.filePath,
          dateAdded: dateString,
          fileId: innerElement.id,
          riskUWApprovalId: innerElement.riskUWApprovalId
        });
      });

    });
  }

  checkUwApprovalTableRows(riskUWApprovals: any): void {
    let miscIndex = 0;

    if (riskUWApprovals) {
      riskUWApprovals.forEach((element, index) => {
        if (!this.uwApprovalData.isMiscellaneousApprovalDescription(element.uwApprovalDescription)) {
          this.uwApprovalData.uwApprovalTableRows.forEach(item => {
            if (item.tr[0].value === element.uwApprovalDescription){
              item.itemId = element.id;
            }
          });
        } else {
          const tableMiscData = this.uwApprovalData.uwApprovalTableRows.filter(item => item.tr[0].value === element.uwApprovalDescription);
          const miscUWApprovalTableRowId = tableMiscData[miscIndex].id;
          this.uwApprovalData.uwApprovalTableRows.find(item => item.id === miscUWApprovalTableRowId).itemId = element.id;
          miscIndex += 1;
        }

        if (this.uwApprovalData?.uwApprovalTableRows[index]?.isUploaded !== undefined) {
          this.uwApprovalData.uwApprovalTableRows[index].isUploaded = true;
        }

        element.riskUWApprovalDocuments.forEach((innerElement, innerIndex) => {
           if (this.uwApprovalData?.uwApprovalTableRows[index] !== undefined &&
            this.uwApprovalData.uwApprovalTableRows[index].files !== undefined &&
            this.uwApprovalData.uwApprovalTableRows[index].files[innerIndex] !== undefined) {
             try {
              this.uwApprovalData.uwApprovalTableRows[index].files[innerIndex].fileId = innerElement.id;
              this.uwApprovalData.uwApprovalTableRows[index].files[innerIndex].riskUWApprovalId = innerElement.riskUWApprovalId;
             } catch (e) {
               //Handle errors here => changed this
             }
           }
        });

        this.uwApprovalData?.uwApprovalTableRows[index]?.files?.forEach((innerElement, innerIndex) => {
          innerElement.isUploaded = true;
        });
      });
    }
  }

  markUWApprovalForSaving() {
    this.isUWApprovalForSaving = true;
  }

  unmarkUWApprovalForSaving() {
    this.isUWApprovalForSaving = false;
  }

  checkIfUWApprovalForSaving(): boolean {
    return this.isUWApprovalForSaving;
  }

  referToUw(): void {
    const riskId = this.entityRiskData.getRiskId();
    const riskDetailId =  this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId()) ? this.entityRiskData.getRiskDetailId() :
                    this.summaryData.SummaryForm.get('riskDetailId').value;

    if (riskId) {
      this.store.dispatch(updateAppRuwIsLoadingFromUwApprovalSavingData({ ruwIsLoading: true }));

      this.quickQuoteService.referToUw(riskId, riskDetailId).pipe(takeUntil(this.stop$)).subscribe(res => {
        if (this.submissionData.quoteStatus.value !== this.genericConstants.quoteStatus.quo) {
          if (this.policySummaryData.isPolicyPage(riskId)) {
            this.submissionData.policyStatus.next(this.submissionData.getQuoteStatus(this.genericConstants.quoteStatusCode.ruw));
          } else {
            this.submissionData.quoteStatus.next(this.submissionData.getQuoteStatus(this.genericConstants.quoteStatusCode.ruw));

            if (this.policySummaryData.isPolicy) {
              this.store.dispatch(updatePolicyStatusFromUwApprovalData({ status: this.submissionData.getQuoteStatus(this.genericConstants.quoteStatusCode.ruw) }));
            } else {
              this.store.dispatch(updateSubmissionStatusFromUwApprovalData({ status: this.submissionData.getQuoteStatus(this.genericConstants.quoteStatusCode.ruw) }));
            }
          }
          this.taskGeneration.taskRUW(this.policySummaryData.isPolicyPage(riskId));
        }

        this.saveUWApproval();
        Utils.unblockUI();
        this.store.dispatch(updateAppRuwIsLoadingFromUwApprovalSavingData({ ruwIsLoading: false }));
      },
      err => {
        Utils.unblockUI();
        this.toast.error(ErrorMessageConstant.savingErrorMessage);
        this.store.dispatch(updateAppRuwIsLoadingFromUwApprovalSavingData({ ruwIsLoading: false }));
        // add code here for error log
      });
    }
  }

  mapRiskUWApproveDeclinedNotes(data: AllRiskBindNoteDTO[]): void {
    _.forEach(data, (item, index) => {
      const id = this.policyNotesData.notesTableRows.length + 1;
      const newItem: any = {
        category: item.category,
        description: item.description,
        dateAdded: item.createdDate,
        detail: item.detail,
        addedBy: OfacSearchConstants.OfacSearchBindAndIssueNoteDetails.NotesAddedBy,
        createdBy: item.createdBy,
        createdByFullName: item.createdByFullName,
      };
      this.addTableItem(newItem, id);
    });
  }

  addTableItem(newItem: any, id: any): void {
    const tr: ITableTd[] = [];
    const fields: string[] = [
      'category',
      'description',
      'dateAdded',
      'addedBy'
    ];

    _.forEach(fields, (item, index) => {
      const display: string = this.updateDisplay(item, newItem);
      tr.push({
        id: id,
        value: Boolean(newItem[item]) ? newItem[item] : '',
        display: display,
      });
    });

    this.policyNotesData.notesTableRows.push({
      id: id,
      tr: tr,
      createdBy: newItem.createdBy,
      isFromParent: newItem.isFromParent
    });
    const riskId = this.entityRiskData.getRiskId();
    const newNote: any = {
      id: newItem.id,
      category: newItem.category,
      description: newItem.description,
      createdDate: newItem.dateAdded,
      riskBindId: this.policyNotesData.currentRiskBindId,
      riskId: riskId,
      detail: newItem.detail,
      createdBy: newItem.createdBy,
      createdByFullName: newItem.createdByFullName
    };
    this.policyNotesData.notesList.push(newNote);
  }

  updateDisplay(item: any, newItem: any): string {
    switch (item) {
      case 'dateAdded':
        return newItem[item]?.singleDate?.formatted ? newItem[item]?.singleDate?.formatted : this.mapDate(newItem[item]);
      case 'category':
        return Boolean(newItem[item]) ? _.find(NotesCategories, ['code', newItem[item]])?.description : '';
      default:
        return Boolean(newItem[item]) ? String(newItem[item]) : '';
    }
  }

  mapDate(date?): string {
    const month = new Date(date).getMonth() + 1 > 9 ? new Date(date).getMonth() + 1 : '0' + (new Date(date).getMonth() + 1);
    const day = new Date(date).getDate() > 9 ? new Date(date).getDate() : '0' + new Date(date).getDate();
    const year = new Date(date).getFullYear();

    return `${month}/${day}/${year}`;
  }

  checUwrChanges(result: UwApprovalResultDTO): void {
    if (this.policySummaryData.isPolicy && result.riskSubStatusCode !== 'PEN') {
      this.uwApprovalData.checkUwrChangesForPolicy(result);
      return;
    }

    this.uwApprovalData.checkUwrChangesForQuote(result);
  }
}

