import { Subject } from 'rxjs';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import NotifUtils from '../../../../shared/utilities/notif-utils';
import { EndorsementsComponent } from './endorsements.component';
import { SummaryData } from '../../data/summary.data';
import { PageSectionsValidations } from '../../../../shared/enum/page-sections.enum';
import { PathConstants } from '../../../../shared/constants/path.constants';
import { GenericConstants } from '../../../../shared/constants/generic.constants';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { ErrorMessageConstant } from '../../../../shared/constants/error-message.constants';

@Injectable()
export class CanDeactivateEndorsementsComponentGuard implements CanDeactivate<EndorsementsComponent> {
    genericConstants = GenericConstants;

    constructor(protected qqData: SummaryData, public router: Router,
        protected policySummaryData: PolicySummaryData) {}

    canDeactivate(endorsements: EndorsementsComponent, route: ActivatedRouteSnapshot, state: RouterStateSnapshot, nextState: RouterStateSnapshot) {
        const subject = new Subject<boolean>();
        const displayPopup = sessionStorage.getItem(PageSectionsValidations.CurrentPage) !== PathConstants.PageDestination.Quickquote && this.qqData.SummaryForm.get('riskId').value ? true : false;
        
        if (endorsements.endorsementsData.endorsementsGroup.get('premiumAdjustment').value) {
            const minimumEstimatedPremium = endorsements.endorsementsData.getMinimumEstimatedPremium();
            if (minimumEstimatedPremium !== '' && displayPopup) {
                NotifUtils.showError(ErrorMessageConstant.allowedPremiumAdjustmentAmount.replace('{0}', minimumEstimatedPremium));
                return subject.asObservable();
            }
        }
        return true;
    }
}