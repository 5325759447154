export const ErrorMessageConstant = {
    usernameAlreadyExists: 'Username already exists',
    title: 'Oops...',
    errorOnRetrievingCounty: `Error on retrieving county list`,
    errorCountyMessage: `Please select county`,
    errorNoCountyMessage: `No county retrieved. Please check inputed address`,
    effectiveDateValidDate: 'Please enter a valid date',
    effectiveDateInvalidFutureDate: 'Quoting is available for effective dates within 90 days',
    effectiveDateInvalidPastDate: 'Effective date must be a current or future date',
    fieldRequired: 'This field is required.',
    contactAdminErrorMessage: 'Something went wrong. Please contact your administrator.',
    errorOnRetrievingData: 'Error on retrieving data with an id: ',
    tokenExpiredErrorMessage: 'Sorry your token has expired. Please login again.',
    requiredErrorMessage: '{0} is required.',
    standardNameErrorMessage: '{0} must contain only letters, apostrophes, hyphens and spaces.',
    genericRequiredErrorMessage: 'This is a required field.',
    pleaseAgree: 'Please agree on FCRA Reporting Act',
    validEmailAddressErrorMessage: 'Please enter a valid email address.',
    validPhoneNumberErrorMessage: 'Please enter a valid phone number.',
    validFormatErrorMessage: 'Please enter a valid format',
    validData: 'Please enter a valid value',
    validDate: 'Date of Birth is invalid',
    futureDate: 'Date of Birth cannot be a future date',
    notEarlierDate: 'Date should not be earlier than 1/1/1800',
    validFileType: 'Valid file formats: pdf, doc, docx, msg, jpg, jpeg, bmp, png, xls, xlsx, txt, zip',
    below18age: 'Applicant must be at least 18 years old',
    yearMaximumErrorMessage: '{0} can not be older than year of construction',
    yearBuiltErrorMessage: 'Year Built is required',
    purchaseDateErrorMessage: 'Purchase date cannot be prior to date built or later than policy effective date',
    emailAddressMismatchErrorMessage: 'Email Address does not match',
    starPackageErrorMessage: 'Star Package options require home is loss free for the last three years',
    quickQuoteErrorMessage: {
      yearBuiltMinimumErrorMessage: 'Year built cannot be earlier than 1800.',
      yearBuiltMaximumErrorMessage: 'Year built cannot be more than 1 year in the future.',
      roofAgeMaximumErrorMessage: 'Year Roof Installed cannot be older than Year Built',
      yearMinimumErrorMessage: '{0} cannot be earlier than 1800.'
    },
    coveragesErrorMessage: {
      coverageAMinimumErrorMessage: 'Coverage A cannot be less than $75,000.',
      coverageAMaximumErrorMessage: 'Coverage A cannot be greater than $10,000,000.',
      coverageCMaximumErrorMessage: 'Coverage C cannot be greater than $10,000,000.',
      floodCoverageAMaximumErrorMessage: 'Flood Coverage A cannot be greater than $2,000,000.',
      floodCoverageCLimitMaximumErrorMessage: 'Flood Coverage C cannot be greater than $500,000.',
      fireDiscountErrorMessage: 'Fire protection device cannot be none.',
      hurricaneDeductibShouldNotbeLessthanleErrorMessage: 'Hurricane Deductible cannot be less than AOP Deductible.',
      floodLossLocationNotEligibleErrorMessage: 'This location is not eligible for private flood.',
      errorRecievingFloodData: 'Error receiving flood data, please try again later.',
      coverageCMinimumErrorMessageDP3Def: 'Coverage C cannot be less than $5,000.',
      coverageCMaximumErrorMessageDP3Def: 'Coverage C cannot be greater than $250,000.',
      coverageCMinimumErrorMessageDP3Tenant: 'Coverage C cannot be less than $10,000.',
      coverageCMaximumErrorMessageDP3Tenant: 'Coverage C cannot be greater than $100,000.',
      coverageCMaximumErrorMessageHO4: 'Coverage C cannot be greater than $10,000,000.',
      coverageCMinimumErrorMessageHO4: 'Coverage C cannot be less than $10,000.',
      coverageCLatestValidValueDP3Def: 'You may have entered a value that is either less than $5,000 or greater than $250,000 in Coverage C. Coverage C has been set to the latest valid value.',
      coverageCLatestValidValueDP3Tenant: 'You may have entered a value that is either less than $10,000 or greater than $100,000 in Coverage C. Coverage C has been set to the latest valid value.',
      // brul for dp3 cov a not yet implemented
      coverageAMinimumErrorMessageDP3: 'Coverage A cannot be less than $125,000',
      ho6MinCovACovCErrorMessage: 'Minimum sum total for Coverage A & C is $40,000.',
      coverageCMaximumErrorMessageDP3: 'Coverage C - Personal Property Limit cannot be more than 70% of Coverage A.'
    },
    propertyErrorMessage: {
      numMonthsMaxErrorMessage: 'Number of months cannot be greater than 12',
      numMonthsMinErrorMessage: 'Number of months cannot be less than 1',
      floorNumMaxErrorMessage: 'Number of Floor Unit cannot be greater than 50',
      errorParishMessage: `Please select Parish.`,
      errorNoParishMessage: `No parish retrieved. Please check inputed address.`,
    },
    coveragesInfoMessage: {
      floodCovAMaximumDefault: 'Flood Coverage A cannot be greater than $2,000,000',
      floodCovCMaximum: 'Flood Coverage C defaulted to maximum allowed of $500,000'
    },
    endorsementsInfoMessage: {
      rejectedIncreasedAmount: 'Rejected Increased Amount',
      bothZeroAmountError: 'Increased Amount cannot be both $0'
    },
    claimsInfoMessage: {
      invalidLossAssociatedMessage: 'Select atleast 1 Loss Associated.',
    },
    modalCustomErrorMessage: {
      documentDateAddedIsRequired: 'Date Added is Required',
      notesDateAddedIsRequired: 'Date Added is Required'
    },
    raterCannotBeReachedErrorMessage: 'Rating Service cannot be reached. Please try again.',
    raterTooManyRequestMessage: 'Rater Service Unavailable: Request cannot be processed due to excessive number of requests. Please try again shortly.',
    error429TooManyRequestMessage: 'The request limit has been reached. Please wait for a minute to proceed.',
    savingErrorMessage: 'There\'s an error while saving category.',
    Iso360ValueErrorMessage: {
      Iso360ValueFailedToReceiveDataMessage: 'There was an error while fetching the data from 360 Value',
      Iso360ValueConnectionFailedMessage: 'Connection to ISO 360Value cannot be established at this time.',
      Iso360ReplacementCostErrorMessage: 'Replacement cost cannot be imported at this time. Please try again later.'
    },
    CreditService: {
      LexisNexisNcfFailed: 'Connection to LexisNexis cannot be established at this time.',
      LexisNexisNcfFailed_V1: 'Credit ordering service currently unavailable.',
      CE01ErrorCode: 'CE01',
      CS02ErrorCode: 'CS02',
      NcfApiErrorCode: 'CE05',
      GeneralErrorCode: 'CE06',
      NcfApiAccountCodeErrorCode: 'CE08',
      CS02ErrorCodeMessage: 'Credit ordering service currently unavailable. Please contact technical support.',
      GeneralErrorMessage: 'Credit ordering service currently unavailable. Please contact technical support.',
      AccountSetupBRQQ41ErrorMessage: 'A credit reporting account has not been setup for this agency. Please contact customer service.',
      AccountSetupBrQq41InvaliderrorMessage:
        'A credit reporting account has not been setup for this agency. Please contact customer service.<li>Invalid Lexis Code</li>'
    },
    coApplicantErrorMessage: {
      isCoApplicantSetToNo: `Switching back to NO, will remove any changes made in the Co-Applicant Section. <br><br> Are you sure want to continue?`,
      isCoApplicantMailingAddressSetToYes: `Switching back to YES, will remove any changes made in the Co-Applicant Mailing address. <br><br> Are you sure want to continue?`
    },
    inspectionOrderingErrorMessage: {
      isInspectionOrderedSetToNo: `Switching back to NO, will remove the data or any changes made in the Inspection Section. <br><br> Are you sure want to continue?`,
      isInspectionReceivedSetToNo: `Switching back to NO, will remove the data or any changes made in the Inspection Receipt Date. <br><br> Are you sure want to continue?`
    },
    documentsErrorMessage: {
      invalidFileTypeErrorMessage: `The document {0} could not be uploaded. The file type is invalid.
      Note: Only the following file types are valid and can be attached – <list of valid file types e.g. ".pdf", ".doc", ".docx", ".msg", ".jpg", ".jpeg", ".bmp", ".png", ".xls", ".xlsx", ".txt", ".zip">`
    },
    validPolicyPattern: 'Invalid policy number. Only use letters, numbers and dashes.',
    suspendedPayments: {
      invalidPayerErrorMessage: 'Invalid {0}. Only use letters, spaces and apostrophes.',
      street1MaximumCharacter: 'Cannot exceed 30 characters.',
      street2MaximumCharacter: 'Cannot exceed 30 characters.',
      paymentNotAllowedForCancel: 'Posting suspended payment to a cancelled policy is not allowed'
    },
    allowedPremiumAdjustmentAmount: 'Premium Adjustment Amount should not be less than {0}.',
    billingErrorMessage: {
      makePaymentAmountCannotBeZeroErrorMessage: 'Amount cannot be zero',
      makePaymentAmountMinErrorMessage: 'Minimum payment amount allowed is {0}',
      makePaymentAmountMaxErrorMessage: 'Maximum payment amount allowed is {0}',

      refundAmountMaximumErrorMessage: 'Amount cannot be greater than the Total Paid',
      policyNotFoundDashboard: 'Policy Number and Last Name combination not found.',
      policyNotFoundPortal: 'Policy Number and Last Name combination not found. <br/> Please contact your agent for assistance.',
      paymentNotAllowedDashboard: 'Payment can\'t be made on this policy due to {0}.',
      paymentNotAllowedPortal: 'Payment can\'t be made on this policy due to {0}. Please contact your agent.',
      changeRefundToInfoMsg: 'Please change "Refund To" to "Other" to make changes.',
      updateRefundDetailsInfoMsg: 'Please update the field(s) to satisfy its required length.',

      nameMaxLength: '{0} cannot exceed {1} characters.'
    },
    TorrentFlood: {
      torrentFailedMessage: 'Connection to NFIP cannot be established at this time.',
      torrentPassthroughFailedMessage: 'Incorrect Torrent Credentials. Please contact customer service.',
      noUsernameTorrentErrorMessage: 'Torrent account has not been setup for your account. Please contact customer service.',
      noUsernameTorrentResponse: 'NUR',
      unauthorizedLoginResponse: 'IUN',
    },
    FloodAssist: {
      floodAssistFailedMessage: 'Connection to Flood Assist cannot be established at this time. Please try again later.',
      mustOrderFloodErrorMessage: 'Must order flood data on the coverages screen.',
      participationStatusInfoMessage: 'Flood Coverage is not available due to participation status.',
      participationStatusErrorMessage: 'Ineligible for Flood Coverage Endorsement.'
    },
    userInternalErrorMessage: 'Unable to add, Not an {0} user',
    usernameExistErrorMessage: 'Username does not exist',
    usernameExistListErrorMessage: 'Username already exist',
    cannotRetrieveFeesErrorMessage: 'Cannot retrieve fees from billing service.',
    thirdPartyGenericErrorMessage: 'Connection to the Third Party API cannot be established at this time.',
    IsoLocationErrorMessage: 'Connection to ISO Location cannot be established at this time.',
    IsoAirHazardErrorMessage: 'Connection to ISO AIR Hazard cannot be established at this time.',
    IsoAplusErrorMessage: 'Connection to ISO Aplus cannot be established at this time. Please try again later.',
    EC_IsoLocation: 'EC-01',
    EC_Iso360Value: 'EC-02',
    EC_AirHazard: 'EC-03',
    EC_Torrent: 'EC-04',
    EC_LexisNexis: 'EC-05',
    EC_PrefillUnknown: 'EC-06',
    EC_Aplus: 'EC-07',
    EC_IsoLocation_BCEGS: 'EC-01-01',
    EC_IsoLocation_DistanceToCoast: 'EC-01-02',
    EC_IsoLocation_PropertyTerritory: 'EC-01-03',
    EC_IsoLocation_PPC: 'EC-01-04',
    EC_IsoLocation_WindPool: 'EC-01-05',
    EC_IsoLocation_GroupIITerritoryPialZone: 'EC-01-06',
    passwordErrorMessage: {
      minlength: 'Passwords should be at least 8 characters',
      hasNumber: 'Must contain at least 1 number',
      hasCapitalCase: 'Must contain at least 1 in Capital Case',
      hasSmallCase: 'Must contain at least 1 Letter in Small Case',
      hasSpecialChar: 'Must contain at least 1 Special Character',
      doNotMatch: 'Password does not match',
      hasNotAllowedCharacters: 'These special characters are not allowed (#, ?, &, +, %)'
    },
    zipCodeNotFoundContactUWErrorMessage: 'Zip code not found, contact Underwriting',
    zipCodeNotFoundErrorMessage: 'Zip code not found',
    validCompanyNameErrorMessage: 'Please enter a valid company name.',
    invalidExpirationDate: 'invalid expiration date.',
    agencySubAgencyInactive: 'Agency and/or subagency is inactive',
    unAuthorizedError: {
      error: 'error',
      message: 'Your token is invalid or has expired. Please login again.',
      sessionExpiredMessage: 'Your session has expired or invalid.'
    },
    invalidNewBusiness: 'New Business is invalid',
    invalidRenewalBusiness: 'Renewal Business is invalid',
    quoteProposalErrorMessage: 'Quote Proposal failed to generate. Please try again later.',
    validLicenseNumberErrorMessage: 'Please enter a valid license number.',
    invalidRiskId: 'Invalid risk Id.',
    maximumLengthErrorMessage: 'Please enter a maximum of {0} characters.',
    passwordWasIncorrectErrorMessage: 'Password was incorrect.',
    suspendedSplitPayment: {
      suspendedSplitPaymentAmountCannotBeZeroErrorMessage: 'Amount cannot be zero',
      suspendedSplitPaymentAmountMinErrorMessage: 'Minimum payment amount allowed is {0}',
      suspendedSplitPaymentAmountMaxErrorMessage: 'Maximum payment amount allowed is {0}',
    },
    exceededMaxLengthErrorMessage: '{0} field total characters of {1} exceeded the maximum required length of {2} characters.',
    manualRenewalTransferErrorMessage: {
      manualRenewalTransferCannotBeZeroErrorMessage: 'Amount cannot be zero',
      manualRenewalTransferAmountMinErrorMessage: 'Minimum renewal transfer amount allowed is {0}',
      manualRenewalTransferAmountMaxErrorMessage: 'Maximum renewal transfer amount allowed is {0}',
    },
    transferToSuspense: {
      minimumTransferAmountErrorMsg: 'Minimum payment transfer amount allowed is {0}',
      maximumTransferAmountErrorMsg: 'Maximum payment transfer amount allowed is {0}',
    },
    batchPayments: {
      invalidInsuredNameErrorMessage: 'Invalid {0}. Only use letters, spaces and apostrophes.',
    },
    covCJewelryIncreaseSpeLimitMaxAmount: 'Jewelry Increased Amount cannot be greater than $4,000.',
    windMitigationInfoMessage: {
      buildingCodeDiscountInfoMessage: 'Documentation such as the HO A 001 form must be received prior to binding for Building Code discount to apply!',
      openingProtectionInfoMessage: 'Documentation such as the HO A 001 form must be received prior to binding for Opening Protection discount to apply!'
    },
    Commission: {
      genericError: 'An error occured while checking the Commission Rate.<br />Please contact agency relations.',
      noCommissionRate: 'No Commission Rate setup found for this Quote.<br />Please contact agency relations.'
    }
};
