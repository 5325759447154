import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavigationService } from '../../core/services/navigation/navigation.service';
import { AuthService } from '../../core/services/auth.service';
import NotifUtils from '../../shared/utilities/notif-utils';
import Utils from '../../shared/utilities/utils';
import { ErrorMessageConstant } from '../../shared/constants/error-message.constants';
import { LoginLabelsConstants, ReferenceCodeConstants } from '../../shared/constants/login.labels.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ReferenceCodeModalComponent } from './reference-code-modal/reference-code-modal.component';
import { ValidationService } from '../../core/services/validation.service';
import { forkJoin } from 'rxjs';
import { AgentService } from '../../core/services/management/agent-service';
import { environment } from '../../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../shared/base-class';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { updateAppBrulDatesFromLogin, updateAppIsLoadingFromLoginComponent } from 'app/store/app/app.actions';
import { Store } from '@ngrx/store';

// const usernameListToBypass2FactorAuth = ['qaauto001', 'qaauto002', 'rivtechadmin', 'rivtechqa'];
// IMPORTANT: Remove line 24 and uncomment line 22 IF PR to Production
const usernameListToBypass2FactorAuth = ['qaauto001', 'qaauto002', 'rivtechadmin', 'rivtechqa', 'mark.sibayan'];

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseClass implements OnInit {
  public ErrorMessageConstant = ErrorMessageConstant;
  public LoginLabelsConstants = LoginLabelsConstants;
  public ReferenceCodeConstants = ReferenceCodeConstants;
  loginForm: FormGroup;
  submitted = false;

  skipTwoAuthFactor: boolean = false;

  constructor(private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private navService: NavigationService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private validationService: ValidationService,
    private agentService: AgentService,
    private storage: LocalStorageService,
    private store: Store
  ) {
    super();
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get today(): Date { return new Date(); }

  get showChangeServerDate(): boolean { return environment.showChangeServerDate; }
  get showSkipTwoFactor(): boolean { return environment.showSkipTwoFactor; }

  get form() { return this.loginForm.controls; }

  onSubmit(): void {
    this.submitted = true;

    this.form.username.markAsDirty();
    this.form.password.markAsDirty();

    if (this.loginForm.invalid) {
      return;
    }

    Utils.blockUI();

    const isTokenExist = this.checkIfSameUser();
    if(!isTokenExist ){
      this.authService.login(this.form.username.value, this.form.password.value)
        .subscribe(_ => {
          if (this.authService.isPasswordChanged === true && this.authService.isChangePasswordAtLogin === false) {
            if (this.authService.agencyId && this.authService.subagencyId) {
              forkJoin([this.agentService.getAgenciesById(this.authService.agencyId), this.agentService.getSubAgenciesById(this.authService.subagencyId)])
                .subscribe(data => {
                  this.authService.agenciesInfo = data[0];
                  this.authService.subAgenciesInfo = data[1];
                  this.onLoginSuccessProcess(true, data[0].isActive && data[1].isActive);
                }, err => {
                  NotifUtils.showError(JSON.stringify(err?.error));
                });
            } else {
              this.onLoginSuccessProcess();
            }
          }
        },
          err => {
            this.store.dispatch(updateAppIsLoadingFromLoginComponent({ isLoading: false }));
            NotifUtils.showError(err?.error?.error);
          }
        );
    }else{
      this.router.navigate(['/dashboard']);
    }


  }

  checkIfSameUser(){
    const authInfo = localStorage.getItem('auth');
    if(authInfo || authInfo !== null){
      const auth = JSON.parse(authInfo);
      if(auth.token){
        return true;
      }
      return false;
    }
    return false;
  }

  onLoginSuccessProcess(isAgent: boolean = false, isValid: boolean = true): void {
    localStorage.setItem('loginDate', JSON.stringify(this.authService.LoginDateFormGroup.get('loginDate').value));
    this.authService.getServerDate().subscribe(result => {
      // FormGroup SetValue
      const ipxDate = result?.brulDate?.ipxDate;
      const new110 = result?.brulDate?.new110;
      const uwr26Update = result?.brulDate?.uwR26Update;
      const newRaterVersionDate = result?.brulDate?.newRaterVersionDate;
      const limitedTheftCovChargingStartDate = result?.brulDate?.newLimitedTheftCoverageCharging;
      const rspsDate_nb = result?.brulDate?.rspsDate_NB;
      const rspsDate_rb = result?.brulDate?.rspsDate_RB;
      const triggerInactivityTimer = result?.inactivityTimeout?.triggerInactivityTimer;
      const activateInactivityTimer = result?.inactivityTimeout?.activateInactivityTimer;
      const frreDate_nb = result?.brulDate?.frreDate_NB;
      const frreDate_rb = result?.brulDate?.frreDate_RB;
      this.authService.LoginDateFormGroup.get('UTCServerDate').setValue(result.currentServerDate);
      this.authService.LoginDateFormGroup.get('ESTServerDate').setValue(result.currentServerDateProper);
      this.authService.LoginDateFormGroup.get('IPXDate').setValue(ipxDate);
      this.authService.LoginDateFormGroup.get('New110').setValue(new110);
      this.authService.LoginDateFormGroup.get('UWR26Update').setValue(uwr26Update);
      this.authService.LoginDateFormGroup.get('NewRaterVersionDate').setValue(newRaterVersionDate);
      this.authService.LoginDateFormGroup.get('NewLimitedTheftCoverageCharging').setValue(limitedTheftCovChargingStartDate);
      this.authService.LoginDateFormGroup.get('RSPSDate_NB').setValue(rspsDate_nb);
      this.authService.LoginDateFormGroup.get('RSPSDate_RB').setValue(rspsDate_rb);
      this.authService.LoginDateFormGroup.get('TriggerInactivityTimer').setValue(triggerInactivityTimer);
      this.authService.LoginDateFormGroup.get('ActivateInactivityTimer').setValue(activateInactivityTimer);
      this.authService.LoginDateFormGroup.get('FRREDate_NB').setValue(frreDate_nb);
      this.authService.LoginDateFormGroup.get('FRREDate_RB').setValue(frreDate_rb);

      // LocalStorage Set Value
      localStorage.setItem('UTCServerDate', result.currentServerDate);
      localStorage.setItem('ESTServerDate', result.currentServerDateProper);
      localStorage.setItem('IPXDate', ipxDate);
      localStorage.setItem('UWR26Update', uwr26Update);
      localStorage.setItem('NewRaterVersionDate', newRaterVersionDate);
      localStorage.setItem('NewLimitedTheftCoverageCharging', limitedTheftCovChargingStartDate);
      localStorage.setItem('RSPSDate_NB', rspsDate_nb);
      localStorage.setItem('RSPSDate_RB', rspsDate_rb);
      localStorage.setItem('FRREDate_NB', frreDate_nb);
      localStorage.setItem('FRREDate_RB', frreDate_rb);

      this.store.dispatch(updateAppBrulDatesFromLogin({ brulDates: result }));

      const defaultActiveTime = +result?.inactivityTimeout.activateInactivityTimer;
      const trigerTime = +result?.inactivityTimeout.triggerInactivityTimer;
      const activeTime = defaultActiveTime - trigerTime;
      const newActiveTime = activeTime * 60;
      const newTrigerTimer = trigerTime * 60;
      localStorage.setItem('TriggerInactivityTimerSecs', ''+newTrigerTimer);
      localStorage.setItem('ActivateInactivityTimerSecs', ''+newActiveTime);

      this.authService.serverDates.next(this.authService.LoginDateFormGroup);
    });
    if ((usernameListToBypass2FactorAuth.includes(this.form.username.value) || this.skipTwoAuthFactor) && isValid) {
      this.authService.setAuthData();
      this.navService.initializeMenus().subscribe(() => {
        Utils.unblockUI();
        this.authService.logUserLogin(this.storage.get('uname')).subscribe(() => {
          this.authService.initAppSettings();
          this.router.navigate(['/dashboard']);
        });
      }, err => {
        Utils.unblockUI();
      });
    } else {
      if ((isAgent && isValid) || !isAgent) {
        this.authService.checkIpAddress().subscribe(result => {
          if (!result) {
            this.callUserTwoFactorAuth();
          } else {
            this.authService.setAuthData();
            this.authService.logUserLogin(this.storage.get('uname')).subscribe(() => {
              this.authService.initAppSettings();
              this.router.navigate(['/dashboard']);
            });
          }
        });
      } else {
        Utils.unblockUI();
        this.authService.loginAuditLog(ErrorMessageConstant.agencySubAgencyInactive, this.form.username.value);
        NotifUtils.showError(LoginLabelsConstants.errorMessage.incorrectUsernamePassword);
      }
    }
  }

  callUserTwoFactorAuth(): void {
    this.validationService.getUserTwoFactorAuth(this.form.username.value).subscribe(result => {
      Utils.unblockUI();
      this.showReferenceCodeModal(result);
    }, err => {
      console.log(err);
      Utils.unblockUI();
    });
  }

  showReferenceCodeModal(referenceNumber: string): void {
    const initialState = {
      title: this.ReferenceCodeConstants.title,
      description1: this.ReferenceCodeConstants.description1,
      description2: this.ReferenceCodeConstants.description2,
      referenceNumber: referenceNumber,
      username: this.form.username.value
    };

    this.modalRef = this.modalService.show(ReferenceCodeModalComponent, {
      initialState,
      class: 'modal-dialog-centered modal-sm',
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }
}
