import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { EndorsementsData } from '../../data/endorsements.data';
import { ErrorMessageConstant } from '../../../../shared/constants/error-message.constants';
import { ClickTypes } from '../../../../shared/enum/click-type.enum';
import { SubmissionPageData } from '../../data/submission-page.data';
import { EndorsementsLabelsConstants } from '../../../../shared/constants/endorsements.labels.constants';
import { CoveragesData } from '../../data/coverages.data';
import { PropertyData } from '../../data/property.data';
import { AuthService } from '../../../../core/services/auth.service';
import { QuickQuoteData } from '../../data/quick-quote.data';
import { ClaimsData } from '../../data/claims.data';
import NotifUtils from '../../../../shared/utilities/notif-utils';
import { ClaimsModalModel } from '../../../../shared/models/submission/claims/claims.model';
import { ApplicantData } from '../../data/applicant.data';
import { SummaryData } from '../../data/summary.data';
import { QuickQuoteService } from '../../../../core/services/submission/quick-quote.service';
import { NavigationService } from '../../../../core/services/navigation/navigation.service';
import { BaseClass } from '../../../../shared/base-class';
import { PathConstants } from '../../../../shared/constants/path.constants';
import { PageSectionsValidations } from '../../../../shared/enum/page-sections.enum';
import { PolicySummaryData } from '../../../../modules/policy-management/data/policy-summary.data';
import { EntityRiskData } from '../../data/entity-risk.data';
import FormUtils from '../../../../shared/utilities/form.utils';
import { GenericLabel } from '../../../../shared/constants/generic.labels.constants';
import { GenericConstants } from '../../../../shared/constants/generic.constants';
import { FormTypeConstants } from '../../../../shared/constants/form-types.constants';
import { QuoteService } from '../../../../core/services/quote.service';
import { EndorsementValidatorErrorConstants } from '../../validators/validator-error-constants/endorsement-validator-error.constants';
import { EndorsementsValidationService } from '../../../../core/services/submission/validations/endorsements-validation.service';
import { SubmissionNavValidationService } from '../../../../core/services/navigation/submission-nav-validation.service';
import { GeneralValidationService } from 'app/core/services/submission/validations/general-validation.service';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import Utils from '../../../../shared/utilities/utils';
import { EndorsementsSavingData } from '../../data/endorsements.saving.data';
import { UwQuestionsData } from '../../data/uw-questions.data';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { CalculateRaterData } from 'app/core/services/submission/rater-premium/calculate-rater-data.service';
import { LocationLabelConstants } from '../../../../shared/constants/property.labels.constants';
import { PageHeaderSummaryConstants } from 'app/shared/constants/page-header-summary.constants';
import { PropertyBusinessRules } from '../../business-rules/property.business-rules';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectAppBrulDates } from 'app/store/app/app.selectors';
import { updateAppBrulDatesFromEndorsementComponent } from 'app/store/app/app.actions';
import { IProcessedRaterView } from 'app/shared/models/rater-premium/processed-rater-view.model';
import { selectRaterList } from 'app/store/rater/rater.selectors';

@Component({
  selector: 'app-endorsements',
  templateUrl: './endorsements.component.html',
  styleUrls: ['./endorsements.component.scss'],
})

export class EndorsementsComponent extends BaseClass implements OnInit {
  public ErrorMessageConstant = ErrorMessageConstant;
  public EndorsementsLabelsConstants = EndorsementsLabelsConstants;
  public GenericLabel = GenericLabel;
  public GenericConstants = GenericConstants;
  public FormUtils = FormUtils;
  submitted: boolean = false;
  isOpen: boolean = true;
  homeAge: number = 41;
  public formTypes = FormTypeConstants;
  public EndorsementValidatorErrorConstants = EndorsementValidatorErrorConstants;
  readonly keyCondition = ['delete', 'backspace', 'tab', 'escape', 'enter', 'decimal point'];
  userType: string;
  genericConstant = GenericConstants;
  agentDashboardContants = AgentDashboardConstants;
  isHurrDeductibleExcluded: boolean;

  protected selectAppBrulDates$: Observable<any>;
  protected selectAppBrulDates: any | null;
  selectRaterList$: Observable<IProcessedRaterView[]>;
  hasRSPSRaterResult: boolean = true;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public endorsementsData: EndorsementsData,
    public submissionData: SubmissionPageData,
    public coveragesData: CoveragesData,
    public propertyData: PropertyData,
    public authService: AuthService,
    public quickQuoteData: QuickQuoteData,
    protected claimsData: ClaimsData,
    protected applicantData: ApplicantData,
    protected quickQuoteService: QuickQuoteService,
    protected summaryData: SummaryData,
    public navigationService: NavigationService,
    public raterData: CalculateRaterData,
    public policySummaryData: PolicySummaryData,
    public entityRiskData: EntityRiskData,
    public quoteService: QuoteService,
    public endorsementsValidationService: EndorsementsValidationService,
    public formValidation: SubmissionNavValidationService,
    protected generalValidationService: GeneralValidationService,
    public endorsementSavingData: EndorsementsSavingData,
    public uwQuestionsData: UwQuestionsData,
    protected propertyBusinessRules: PropertyBusinessRules,
    protected store: Store
  ) {
    super();
    this.selectAppBrulDates$ = this.store.pipe(select(selectAppBrulDates));
    this.selectRaterList$ = this.store.pipe(select(selectRaterList));
    this.subscribeToSelectAppBrulDates$();
    this.subscribeToSelectRaterList$();
  }

  get CoveragesAndDeductiblesForm() {
    return this.coveragesData.coveragesAndDeductiblesForm;
  }

  get PropertyLocationForm() {
    return this.propertyData.locationDetails;
  }

  get isUnitOwnersRentalDisabled(): boolean {
    return this.propertyBusinessRules.isUnitOwnersRentalDisabled;
  }

  get starAdvantagePackageTooltip(): string {
    return this.endorsementsData.effectiveDateStarPackage ?
      EndorsementsLabelsConstants.starAdvantagePackageTooltipNew :
      EndorsementsLabelsConstants.starAdvantagePackageTooltip;
  }

  get rspsDate(): any {
    return this.entityRiskData.getRisk()?.isFromRenewal ? this.selectAppBrulDates?.brulDate?.rspsDate_RB : this.selectAppBrulDates?.brulDate?.rspsDate_NB;
  }

  ngOnInit() {
    this.initData();
    sessionStorage.setItem(PageSectionsValidations.CurrentPage, PathConstants.PageDestination.Endorsements);
    this.subscribeToUserType();
    this.subscribeToIsHurrDeductibleExcluded();
  }

  initData(): void {
    if(!this.entityRiskData.isDonePopulatingForms){
      this.entityRiskData.isDonePopulatingForms$.pipe(takeUntil(this.stop$)).subscribe(() => {
        if (!this.policySummaryData.isPolicy || this.policySummaryData.isEditPolicy) {
          this.endorsementsData.endorsementBusinessRules(false);
          this.propertyBusinessRules.triggerActiveNew125(true);
        }
        this.propertyBusinessRules.triggerActiveNew112();
        this.propertyBusinessRules.triggerActiveNew125(true);
      });
      return;
    }

    this.propertyBusinessRules.triggerActiveNew112();
    if (!this.policySummaryData.isPolicy || this.policySummaryData.isEditPolicy) {
      this.endorsementsData.endorsementBusinessRules(false);
    }
  }

  subscribeToUserType(): void {
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.userType = userType;
    });
  }

  get isRenewalOffered(): boolean {
    return (this.policySummaryData.renewalStatusCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RWO ||
      this.policySummaryData.renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RWO) ||
      (this.policySummaryData.renewalStatusCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO ||
      this.policySummaryData.renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO);
  }

  calculateDiff(sentDate) {
    const date1: any = sentDate;
    const date2: any = this.authService.getCustomDate();
    const diffDays: any = Math.floor((date1 - date2) / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  get disableFormFields(): boolean {

    if (this.isRefferedtoUWStatus) {
      return true;
    }

    if (this.policySummaryData.isPolicy) {
      return !(this.policySummaryData.isEditPolicy || this.isRefferedtoUWStatus);
    }

    const renewalEffectiveDate = new Date(this.entityRiskData.EntityRisk?.risks[0]?.renewalEffectiveDate);
    const diffDays = this.calculateDiff(renewalEffectiveDate);

    if (this.isRenewalOffered &&
        this.authService.userType.value===GenericConstants.userType.external &&
        diffDays < 2) {
      return true;
    }

    return this.submissionData.disableQuoteForms;
  }

  get isRefferedtoUWStatus(): boolean {
    return this.submissionData.policyStatus?.value === this.agentDashboardContants.referredToUW && this.coveragesData.isExternal;
  }

  get isDeclinedUWStatus(): boolean {
    return this.submissionData.policyStatus?.value === this.agentDashboardContants.declineByUW;
  }

  disableFormControl(formControlName: string, value?: any): void {
    this.endorsementsData.endorsementsGroup.controls[formControlName].disable();

    if (value !== undefined) {
      this.endorsementsData.endorsementsGroup.get(formControlName).patchValue(value);
    }
  }

  enableFormControl(formControlName: string, value?: any): void {
    this.endorsementsData.endorsementsGroup.controls[formControlName].enable();
    const patchValue = this.endorsementsData.endorsementsGroup.get(formControlName).value;
    this.endorsementsData.endorsementsGroup.get(formControlName).patchValue(patchValue);
  }

  getJewelryTotal(): void {
    const jewelryValue = this.endorsementsData.endorsementsGroup.get('jewelryIncreased').value;
    const jewelryTotal: number = jewelryValue === '0' ?
      1000 : jewelryValue === '' ? null : (Number(jewelryValue) + 1000);
    this.endorsementsData.endorsementsGroup.get('silverwareIncreased').updateValueAndValidity();

    this.endorsementsData.endorsementsGroup.patchValue({
      jewelryTotal: jewelryTotal,
    });
  }

  getSilverwareTotal(): void {
    const silverwareValue = this.endorsementsData.endorsementsGroup.get('silverwareIncreased').value;
    const silverwareTotal: number = silverwareValue === '0' ?
      2500 : silverwareValue === '' ? null : (Number(silverwareValue) + 2500);
    this.endorsementsData.endorsementsGroup.get('jewelryIncreased').updateValueAndValidity();

    this.endorsementsData.endorsementsGroup.patchValue({
      silverwareTotal: silverwareTotal,
    });
  }

  onSubmit(clickType?): void {
    if (clickType === ClickTypes.Calculate) {
      if (this.endorsementsData.endorsementsGroup.get('premiumAdjustment').value) {
        const minimumEstimatedPremium = this.endorsementsData.getMinimumEstimatedPremium();
        if (minimumEstimatedPremium !== '') {
            NotifUtils.showError(ErrorMessageConstant.allowedPremiumAdjustmentAmount.replace('{0}', minimumEstimatedPremium));
        } else {
          this.raterData.endorsementRaterRequest(this.endorsementsData, this.entityRiskData.getPolicyNumber());
        }
      } else {
        this.raterData.endorsementRaterRequest(this.endorsementsData, this.entityRiskData.getPolicyNumber());
        this.endorsementSavingData.saveEndorsements();
      }
      return;
    }

    if (!this.policySummaryData.isPolicy) {
      switch (clickType) {
        case ClickTypes.Back:
            this.navigationService.navigateRoute(PathConstants.PageDestination.Coverages);
          break;
        case ClickTypes.Next:
          this.navigationService.navigateRoute(PathConstants.PageDestination.Claims);
          break;
      }
    } else {
      this.onNextBackPolicy(clickType);
    }
  }

  onNextBackPolicy(clickType): void {
    const page = clickType === ClickTypes.Back ? PathConstants.Policy.Policies.CoveragesAndDeduction :
      PathConstants.Policy.Policies.Claims;
    this.navigationService.navigatePolicyRoute(page);
  }

  getscreenedEncCarPortCovId(code, isSelected): number {
    if (isSelected) {
      return this.endorsementsData.screenedEnclosureLimitOptions.find(x => x.code === Number(code))?.code;
    } else {
      return null;
    }
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
    this.submissionData.isEndorsementOpen = this.isOpen;
  }

  applyStarPackageCommonInclusion(): void {
    this.disableFormControl('personalProperty', false);
    this.disableFormControl('ordinanceLaw', false);
    this.disableFormControl('ordinanceLawOrCov', false);
    this.disableFormControl('waterBackup', false);
    this.disableFormControl('personalInjury', false);
    this.disableFormControl('lossAssessment', false);
    this.disableFormControl('coverageC', false);
    this.enableFormControl('specialPersonal');
    this.coveragesData.coveragesAndDeductiblesForm.get('coverageCLimitId').setValue('50');
    this.coveragesData.coveragesAndDeductiblesForm.get('coverageCLimitId').disable();
    // TODO: Make sure to add this in payload when saving in database.
  }

  applyStarPackageAdvantageInclusion(): void {
    this.disableFormControl('increasedReplacement', false);
    this.disableFormControl('specialPersonal', false);
    this.endorsementsData.setCoverageCLimit();
    // TODO: Make sure to add this in payload when saving in database.
  }

  setOrdinanceLawValue(): void {
    const ordinanceLawValue = this.endorsementsData.endorsementsGroup.get('ordinanceLaw').value;
    this.endorsementsData.endorsementsGroup.get('ordinanceLaw').setValue(ordinanceLawValue ? ordinanceLawValue : '25' );
  }

  switchKeyPress(key: string, fieldName: string, form: FormGroup): void {
    if (!form.get(fieldName).disabled) {
      switch (key.toLowerCase()) {
        case 'y':
          form.get(fieldName).setValue(true);
          break;
        case 'n':
          form.get(fieldName).setValue(false);
          break;
        default: break;
      }
    }
  }

  checkStarPackage(): void {
    const starPackage = this.endorsementsData.endorsementsGroup.get('starPackage').value;
    const starAdvantagePackage = this.endorsementsData.endorsementsGroup.get('starAdvantagePackage').value;
    if ((starPackage || starAdvantagePackage) && this.claimsData.claimList.length > 0) {
      const invalidLosses = this.claimsData.claimList.filter((x: ClaimsModalModel) => this.claimsData.hasClaimsForPastThreeYears(x.lossDate) && !this.claimsData.checkIsActOfGod(x.claimType)).length;
      if (invalidLosses > 0) {
        NotifUtils.showError(ErrorMessageConstant.starPackageErrorMessage, () => {
          this.endorsementsData.endorsementsGroup.get('starPackage').setValue(false);
          this.endorsementsData.endorsementsGroup.get('starAdvantagePackage').setValue(false);
        });
      }
    }

    this.endorsementsData.isStarPackage = starPackage || starAdvantagePackage ? true : false;
    this.endorsementsData.isStarAdvantagePackage = starAdvantagePackage ? true : false;
  }

  calculateAge(): number {
    const today = this.authService.getCustomDate();
    const effectiveDate = this.applicantData.mainApplicant.get('effectiveDate').value;
    const dateRef = new Date(effectiveDate?.singleDate?.jsDate);
    let age: number = today.getFullYear() - dateRef.getFullYear();
    const m: number = today.getMonth() - dateRef.getMonth();
    const d: number = today.getDate() - dateRef.getDate();

    if (age === 18 && (m < 0 || (m === 0 && d < 0))) {
      age = age - 1;
    }
    return age;
  }

  toggleSwitch(controlName: string, fieldName?: string, event?: any): void {
    const formType = this.entityRiskData.EntityRisk?.risks[0]?.formType;
    const value = this.endorsementsData.endorsementsGroup.get(fieldName)?.value;

    switch (controlName) {
      case EndorsementsLabelsConstants.waterBackupLabel:
        this.endorsementsData.endorsementsGroup.get('waterBackupLimit').setValue(EndorsementsLabelsConstants.waterBackupLimitAmount);
        this.endorsementsData.endorsementsGroup.get('waterBackupDeductible').setValue(EndorsementsLabelsConstants.waterBackupDeductibleAmount);
        break;
      case EndorsementsLabelsConstants.limitWaterLabel:
        this.endorsementsData.endorsementsGroup.get('limitedWaterLimit').setValue(EndorsementsLabelsConstants.limitedWaterLimitAmount);
        break;
      case EndorsementsLabelsConstants.animalLiabilityLabel:
        this.endorsementsData.endorsementsGroup.get('animalLiabilityLimit').setValue(EndorsementsLabelsConstants.animalLiabilityLimitAmount);
        break;
      case EndorsementsLabelsConstants.serviceLineLabel:
        this.endorsementsData.endorsementsGroup.get('serviceLineLimit').setValue(EndorsementsLabelsConstants.serviceLineLimitAmount);
        this.endorsementsData.endorsementsGroup.get('serviceLineDeductible').setValue(EndorsementsLabelsConstants.serviceLineDeductibleAmount);
        break;
      case EndorsementsLabelsConstants.permittedIncidentalOccupanciesLiability:
        if (formType === this.formTypes.DP3) {
          this.endorsementsData.endorsementsGroup.get('permittedIncidentalOccupancies').setValue(value);
        }

        if (event.target.checked !== this.endorsementsData.endorsementsRetrievalData.isPermittedIncidentalOccupanciesLiability) {
          this.endorsementsData.isPermittedIncidentalOccupanciesChanged = true;
        }

        if (event.target.checked !== this.endorsementsData.endorsementsRetrievalData.isPermittedIncidentalOccupanciesLiability) {
          this.endorsementsData.isPermittedIncidentalOccupanciesChanged = true;
        }

        break;
      case EndorsementsLabelsConstants.permittedIncidentalOccupancies:
        if (formType === this.formTypes.DP3) {
          this.endorsementsData.endorsementsGroup.get('permittedIncidentalOccupanciesLiability').setValue(value);
        }

        if (event.target.checked !== this.endorsementsData.endorsementsRetrievalData.isPermittedIncidentalOccupancies) {
          this.endorsementsData.isPermittedIncidentalOccupanciesChanged = true;
        }
        break;
      case EndorsementsLabelsConstants.roofSurfacingPaymentScheduleEndorsement:
        if (event.target.checked !== this.endorsementsData.endorsementsRetrievalData.isRoofSurfacingPaymentSchedule) {
          this.endorsementsData.roofSurfacingPaymentScheduleChanged = true;
        }

        if (event.target.checked !== this.endorsementsData.endorsementsRetrievalData.isPermittedIncidentalOccupancies) {
          this.endorsementsData.isPermittedIncidentalOccupanciesChanged = true;
        }

        break;
      case EndorsementsLabelsConstants.roofSurfacingPaymentScheduleEndorsement:
        if (event.target.checked !== this.endorsementsData.endorsementsRetrievalData.isRoofSurfacingPaymentSchedule) {
          this.endorsementsData.roofSurfacingPaymentScheduleChanged = true;
        }
        break;
    };
  }

  onKeyDownPremiumAdjustmentAmount(event): void {
    if (isNaN(event.key) && !this.keyCondition.includes(event.key.toLowerCase())) {
      event.preventDefault();
    } else {
      this.endorsementsData.currencyChecking.onKeyDown(this.endorsementsData.endorsementsGroup, 'premiumAdjustmentAmount', event);
    }
  }

  onKeyUpPremiumAdjustmentAmount(event): void {
    if (isNaN(event.key) && !this.keyCondition.includes(event.key.toLowerCase())) {
      event.preventDefault();
    } else {
      this.endorsementsData.currencyChecking.onKeyUp(this.endorsementsData.endorsementsGroup, 'premiumAdjustmentAmount', event);
    }
  }

  onPastePremiumAdjustmentAmount(event: ClipboardEvent): void {
    const pastedText = +event.clipboardData.getData('text');
    if (isNaN(pastedText)) {
      event.preventDefault();
    } else {
      this.endorsementsData.currencyChecking.onPaste(this.endorsementsData.endorsementsGroup, 'premiumAdjustmentAmount', event);
    }
  }

  onPersonalLiabilityChange(): void {
    this?.endorsementsData?.manualToggleValueForPersonalLiability?.next(this?.endorsementsData?.endorsementsGroup?.get('personalLiability')?.value);
    if (this?.endorsementsData?.endorsementsGroup?.get('personalLiability')?.value) {
      this.coveragesData.coveragesAndDeductiblesForm.get('isCoverageLEnabled').setValue(true);
      this.coveragesData.coveragesAndDeductiblesForm.get('isCoverageMEnabled').setValue(true);
      if(this.isPremisesLiabilityMandatory()) {
        this.endorsementsData.endorsementsGroup.get('premisesLiability').setValue(true);
        if (this.coveragesData.isExternal) {
          this.endorsementsData.endorsementsGroup.get('premisesLiability').disable();
          this.endorsementsData.isPremisesLiabilityDisabled.next(true);
        }
      } else {
          this.endorsementsData.endorsementsGroup.get('premisesLiability').enable();
          this.endorsementsData.isPremisesLiabilityDisabled.next(false);
      }
    } else {
      this?.endorsementsData?.manualToggleValueForPremisesLiability$.pipe(takeUntil(this.stop$)).subscribe(value => {
        this.endorsementsData.endorsementsGroup.get('premisesLiability').setValue(value ?? this?.endorsementsData?.endorsementsGroup?.get('premisesLiability')?.value);
      });

      this.coveragesData.coveragesAndDeductiblesForm.get('isCoverageLEnabled').setValue(false);
      this.coveragesData.coveragesAndDeductiblesForm.get('isCoverageMEnabled').setValue(false);
    }
  }

  onPremiseslLiabilityChange(): void {
    this?.endorsementsData?.manualToggleValueForPremiseslLiability?.next(this?.endorsementsData?.endorsementsGroup?.get('premisesLiability')?.value);
  }

  isPremisesLiabilityMandatory(): boolean {
    const MINIMUMNUMBEROFMONTHS: number = 1;
    const MAXIMUMNUMBEROFMONTHS: number = 8;
    const propertyUsage = this?.endorsementsData?.propertyData?.locationDetails?.get('propertyUsage')?.value?.toString()?.toUpperCase()?.trim();

    return (Number(this?.endorsementsData?.propertyData?.locationDetails.get('numMonthsOccupied').value) >= MINIMUMNUMBEROFMONTHS &&
            Number(this?.endorsementsData?.propertyData?.locationDetails.get('numMonthsOccupied').value) <= MAXIMUMNUMBEROFMONTHS &&
            this?.coveragesData?.coveragesAndDeductiblesForm?.get('isCoverageLEnabled')?.value &&
            (propertyUsage !== LocationLabelConstants.ownerOccupiedPrimary &&
            propertyUsage !== LocationLabelConstants.ownerOccupiedSecondary));
  }

  subscribeToIsHurrDeductibleExcluded(): void {
    this.endorsementsData.isHurrDeductibleExcluded$.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(value => {
      this.isHurrDeductibleExcluded = value;
    });
  }

  onUnitOwnersRentalToOthersChanged(): void {
    this.endorsementsData.setMaximumLiability();
    this.propertyBusinessRules.triggerActiveNew112(this.propertyData.locationDetails.get('propertyUsage').value, true);
  }

  subscribeToSelectAppBrulDates$(): void {
    this.selectAppBrulDates$.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(brulDates => {
      if (!brulDates) {
        this.authService.getServerDate().subscribe(result => {
          this.selectAppBrulDates = result;
          this.store.dispatch(updateAppBrulDatesFromEndorsementComponent({ brulDates: result }));
        });
      } else {
        this.selectAppBrulDates = brulDates;
      }
    });
  }

  subscribeToSelectRaterList$(): void {
    const RSPSRaterStepAlias = 'RSPSEDFactor';
    const RSPSStepCode = '20';
    if (this.policySummaryData.isPolicy) {
      this.selectRaterList$
        .pipe(filter((data) => { return data !== null; }))
        .subscribe(result => {
          this.hasRSPSRaterResult = result?.filter(res => res.stepCode === RSPSStepCode && res.stepAlias === RSPSRaterStepAlias)?.length > 0;
      });
    }
  }

  get isAnimalLiabilityEndorsementDisabled() {
    return this.authService.userType.value === GenericConstants.userType.external &&
      this.quoteService.formType !== FormTypeConstants.DP3 &&
      (this.uwQuestionsData.eligibilityGroup.get('AreDogsClassified').value ||
        this.uwQuestionsData.eligibilityGroup.get('DoDogsKept').value);
  }
}
