import { Injectable } from '@angular/core';
import { EntityRiskDTO } from '../../../shared/models/data/dto/quick-quote/entity-risk.dto';
import { BaseClass } from '../../../shared/base-class';
import { ActivatedRoute, Router } from '@angular/router';
import { QuickQuoteService } from '../../../core/services/submission/quick-quote.service';
import { ErrorMessageConstant } from '../../../shared/constants/error-message.constants';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { AddressDTO3, EntityAddressDTO3 } from '../../../shared/models/data/dto/quick-quote/entity-address.dto';
import { RiskDTO2 } from '../../../shared/models/data/dto/quick-quote/risk-dto2';
import { CoApplicantEntityDTO } from '../../../shared/models/data/dto/quick-quote/co-applicant-entity.dto';
import { PolicySummaryData } from '../../../modules/policy-management/data/policy-summary.data';
import { AddressType } from '../../../shared/enum/address-type.enum';
import { RiskCoverageDTO2, RiskCoverageDTO3 } from '../../../shared/models/data/dto/quick-quote/risk-coverage.dto';
import { SavePropertyDTO } from '../../../shared/models/data/dto/quick-quote/save-property.dto';
import { RiskDetailsDTO } from '../../../shared/models/data/dto/quick-quote/risk-details.dto';
import { BillingService } from '../../../core/services/billing/billing.service';
import { UpdateRiskRequestDTO } from '../../../shared/models/data/dto/billing/update-risk-request.dto';
import { InfoMessageConstant } from '../../../shared/constants/info-message.constants';
import * as moment from 'moment';
import { AuthService } from '../../../core/services/auth.service';
import { ResponseObjectMoratorium } from '../../../shared/models/submission/quick-quote/moratoriums.model';
import { RiskBindBillingDTO } from '../../../shared/models/data/dto/quick-quote/risk-bind-billing.dto';
import { AutoReinstatementConstants } from '../../../shared/constants/auto-reinstatement.constants';
import { PageHeaderSummaryConstants } from '../../../shared/constants/page-header-summary.constants';
import { RiskEndorsementDTO2 } from 'app/shared/models/data/dto/quick-quote/risk-endorsement.dto';
import { RiskScheduleDTO, RiskScheduleDTO2 } from 'app/shared/models/data/dto/quick-quote/risk-schedule.dto';
import { RiskFloodDTO } from 'app/shared/models/data/dto/quick-quote/risk-flood.dto';

@Injectable({
  providedIn: 'root',
})

export class EntityRiskData extends BaseClass {
  public EntityRisk: EntityRiskDTO;
  public CoEntityRisk: EntityRiskDTO;
  public ParentBackUpRiskData: RiskDetailsDTO;
  ErrorMessageConstant = ErrorMessageConstant;
  apiCallCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isApiCallCompleted = new Subject<boolean>();
  isApiCallCompleted$ = this.isApiCallCompleted.asObservable();

  isDonePopulatingForms: boolean = false;
  isDonePopulatingForms$: Subject<void> = new Subject<void>();
  isDoneSavingForms: boolean = true;
  isDoneSavingForms$: Subject<void> = new Subject<void>();
  floodAssistStatusCode: string;
  moratoriumResponse: ResponseObjectMoratorium[];
  allMoratoriumList: ResponseObjectMoratorium[];
  isDoneFetchMoratorium: boolean = false;
  isDoneFetchingMoratorium$: Subject<boolean> = new Subject<boolean>();
  isNewQuote: boolean = false;
  public renewalBackup: RiskDetailsDTO;
  public policyBackupRenewalPending: RiskDetailsDTO;
  public expiringPolicyEntityRisk: EntityRiskDTO;
  public isSwitchedRisk: boolean = false;
  public isFromRewrite: boolean = false;
  public isFromQQSaveMoratorium: boolean = false;
  public isFromQQSaveCommissionRate: boolean = false;
  public pageHeaderConstants = PageHeaderSummaryConstants;
  public isAgencyNotes: boolean = false;

  constructor(public router: Router,
    public route: ActivatedRoute,
    public quickQuoteService: QuickQuoteService,
    public policySummaryData: PolicySummaryData,
    protected billingService: BillingService,
    protected auth: AuthService
  ) {
    super();
  }

  public getRiskId(): string {
    return this.EntityRisk?.risks[0]?.id;
  }

  public getEntityId(): string {
    return this.EntityRisk?.id;
  }

  public getRiskDetailId(): string {
    return this.EntityRisk?.risks[0].riskDetails[0].id;
  }

  public getLastRiskDetailId(): string {
    return this.EntityRisk?.risks[0].riskDetails[0].lastRiskDetailId;
  }

  public getEntityAddress(): EntityAddressDTO3 {
    return this.EntityRisk?.entityAddresses[0];
  }

  public getEntityAddresses(): EntityAddressDTO3[] {
    return this.EntityRisk?.entityAddresses;
  }

  public getRisk(): RiskDTO2 {
    return this.EntityRisk?.risks[0];
  }

  public getRiskProcessDate(): Date {
    return this.EntityRisk?.risks[0]?.processDate;
  }

  public getActiveRiskDetailId(): string {
    return this.EntityRisk?.risks[0]?.riskDetails?.filter(a => a.riskSubStatusCode === 'ACT')[0].id;
  }

  public getParentBackUpRiskData(): RiskDetailsDTO {
    return this.ParentBackUpRiskData;
  }

  public getPolicyNumber(): string {
    return this.EntityRisk?.risks[0]?.policyNumber;
  }

  public getQuoteNumber(): string {
    return this.EntityRisk?.risks[0]?.quoteNumber;
  }

  public getPolicyStatus(): string {
    return this.EntityRisk?.risks[0]?.riskStatusCode;
  }

  public getFormType(): string {
    return JSON.parse((sessionStorage.getItem('formTypeAndState')))?.formType ?? '';
  }

  public getDraftRiskDetails(): RiskDetailsDTO {
    return this.EntityRisk?.risks[0]?.riskDetails.find(a => a.isCurrentEndorsement === false);
  }

  public getCurrentRiskDetails(): RiskDetailsDTO {
    return this.EntityRisk?.risks[0]?.riskDetails.find(a => a.isActive);
  }

  public getRiskUnderWriterId(): number {
    return this.EntityRisk?.risks[0]?.uwUserId;
  }

  public getRiskSubAgencyId(): string {
    return this.EntityRisk?.risks[0]?.subAgencyId;
  }

  public getRiskIsSiebel(): boolean {
    return this.EntityRisk?.risks[0]?.isSiebel;
  }

  public getCurrentRiskBindbilling(): RiskBindBillingDTO {
    return this.EntityRisk?.risks[0]?.riskDetails.find(a => a.isCurrentEndorsement)?.riskBinds[0]?.riskBindBilling;
  }

  public getExpiringRiskIdFromSubmission(): string {
    return this.expiringPolicyEntityRisk?.risks[0].id;
  }

  public getExpiringRiskDetailIdFromSubmission(): string {
    return this.expiringPolicyEntityRisk?.risks[0].riskDetails[0].id;
  }

  public getExpiringLastRiskDetailIdFromSubmission(): string {
    return this.expiringPolicyEntityRisk?.risks[0].riskDetails[0].lastRiskDetailId;
  }

  public getExpiringPolicyCurrentRiskBindbilling(): RiskBindBillingDTO {
    return this.expiringPolicyEntityRisk?.risks[0]?.riskDetails.find(a => a.isCurrentEndorsement)?.riskBinds[0]?.riskBindBilling;
  }

  public getExpiringActiveRiskDetailId(): string {
    return this.expiringPolicyEntityRisk?.risks[0]?.riskDetails?.filter(a => a.riskSubStatusCode === 'ACT')[0].id;
  }

  public getExpiringPolicyNumber(): string {
    return this.expiringPolicyEntityRisk?.risks[0]?.policyNumber;
  }

  public getExpiringEntityId(): string {
    return this.expiringPolicyEntityRisk?.id;
  }

  public getPolicyFirstIssueDate(): Date {
    return this.EntityRisk.risks[0]?.firstIssueDate;
  }

  public getIsNewBusiness(): boolean {
    return this.getRisk()?.isFromRenewal === false && this.getRisk().isFromRewrite === false;
  }

  public getAgencyHasCommissionRate(): boolean {
    return this.auth.commissionRateResponse && this.auth.commissionRateResponse.hasAgencyCommissionSetup;
  }

  public getIsRenewalQuote(): boolean {
    return (this.getRisk()?.renewalCode !== null &&
      this.getRisk()?.renewalCode !== undefined &&
      this.getRisk()?.renewalStatusCode !== null &&
      this.getRisk()?.renewalStatusCode !== undefined);
  }

  public updateEntityRiskDataApplicant(data: CoApplicantEntityDTO, response: any): void {
    if (this.EntityRisk) {
      this.EntityRisk.mobilePhone = data?.mobilePhone;
      this.EntityRisk.personalEmailAddress = data?.personalEmailAddress;
      this.EntityRisk.isMailingAddressSameInsured = data?.isMailingAddressSameInsured;
      if (!data?.isMailingAddressSameInsured && data?.entityAddresses !== null) {
        const mailingAddress = data.entityAddresses.find(a => a.addressTypeCode === AddressType.Mailing).address;
        const indexOfMailingAddress = this.EntityRisk.entityAddresses.findIndex(a => a.addressTypeCode === AddressType.Mailing);
        const newMailingAddress: AddressDTO3 = {
          streetAddress1: mailingAddress.streetAddress1,
          streetAddress2: mailingAddress.streetAddress2,
          city: mailingAddress.city,
          countryCode: mailingAddress.countryCode,
          county: mailingAddress.county,
          state: mailingAddress.state,
          zipCode: `${mailingAddress.zipCode}`,
        };

        if (indexOfMailingAddress >= 0) {
          this.EntityRisk.entityAddresses[indexOfMailingAddress].address = newMailingAddress;
        } else {
          const newEntityAddress: EntityAddressDTO3 = {
            entityId: response.entityId,
            address: newMailingAddress,
            addressTypeCode: AddressType.Mailing
          };
          this.EntityRisk.entityAddresses.push(newEntityAddress);
        }
      }
      if (this.policySummaryData.isPolicyPage(this.getRiskId())) {
        this.policySummaryData.populatesPolicySummaryPage(this.EntityRisk);
      }
    }
  }

  public updateEntityRiskDataCoverage(data: RiskCoverageDTO2): void {
    this.EntityRisk.risks[0].riskDetails[0].riskCoverages[0] = <RiskCoverageDTO3>data;

    this.policySummaryData.populatesPolicySummaryPage(this.EntityRisk);
  }

  public updateEntityRiskDataProperty(data: SavePropertyDTO): void {
    this.EntityRisk.risks[0].riskDetails[0].riskProperties[0].yearBuilt = data.riskProperty.yearBuilt;
    this.EntityRisk.risks[0].riskDetails[0].riskProperties[0].protectionClassCode = data.riskProperty.protectionClassCode;
    this.EntityRisk.risks[0].riskDetails[0].riskProperties[0].squareFootage = data.riskProperty.squareFootage;
    this.EntityRisk.risks[0].riskDetails[0].riskProperties[0].roofMaterialCode = data.riskProperty.roofMaterialCode;
    this.EntityRisk.risks[0].riskDetails[0].riskProperties[0].yearRoofInstalled = data.riskProperty.yearRoofInstalled;
    this.EntityRisk.risks[0].riskDetails[0].riskProperties[0].county = data.riskProperty.county;

    this.policySummaryData.populatesPolicySummaryPage(this.EntityRisk);
  }

  public updateEntityRiskDataInterest(data: any): void {
    this.EntityRisk.risks[0].riskDetails[0].riskInterests[0].riskInterestDetails = data;

    this.policySummaryData.populatesPolicySummaryPage(this.EntityRisk);
  }

  public startPopulatingForms(): void {
    this.isDonePopulatingForms = false;
  }

  public finishPopulatingForms(): void {
    this.isDonePopulatingForms = true;
    this.isDonePopulatingForms$.next();
  }

  public startSavingForms(): void {
    this.isDoneSavingForms = false;
  }

  public finishSavingForms(): void {
    this.isDoneSavingForms = true;
    this.isDoneSavingForms$.next();
  }

  public getRiskBindId(): string {
    return this.EntityRisk?.risks[0]?.riskDetails[0]?.riskBinds[0]?.id;
  }

  public getRiskInterestId(): string {
    return this.EntityRisk?.risks[0]?.riskDetails[0]?.riskInterests[0]?.id;
  }

  setPolicyNumberOnBind(policy: UpdateRiskRequestDTO): void {
    this.billingService.putPolicyOnBind(policy).subscribe(res => {
      NotifUtils.showConsoleSuccess(InfoMessageConstant.policyNumberSaved);
    }, err => {
      NotifUtils.showError(JSON.stringify(err.error?.message));
    });
  }

  /**
   *
   * @param date - created date from DB in UTC
   * @returns converted local time
   */
  convertUTCDateToLocalDate(date): string {
    // moment.utc(date).local().format('YYYY-MM-DDTHH:mm:ss'); - Uncomment for dynamic timezone

    return moment.utc(date).subtract(5, 'hours').format('YYYY-MM-DDTHH:mm:ss'); //Fix EST
  }

  /**
   *
   * @returns local time zone and store in Entity table
   */
  getUserTimeZone(): number {
    return -(this.auth.getCustomDate().getTimezoneOffset() / 60);
  }

  public get riskId(): string {
    return this.EntityRisk?.risks[0]?.id;
  }

  public get riskDetailId(): string {
    return this.EntityRisk?.risks[0].riskDetails[0].id;
  }

  public get policyRiskDetailId(): string {
    return this.EntityRisk?.risks[0].riskDetails[1].id;
  }

  public getRiskBindBillingId(): string {
    return this.EntityRisk?.risks[0]?.riskDetails[0]?.riskBinds[0]?.riskBindBillingId;
  }

  public getRiskBindBillingPayPlan(): string {
    return this.EntityRisk?.risks[0]?.riskDetails[0]?.riskBinds[0]?.riskBindBilling?.payPlanOption;
  }

  public getRiskBindBillingPaymentMethod(): number {
    return this.EntityRisk?.risks[0]?.riskDetails[0]?.riskBinds[0]?.riskBindBilling?.paymentMethod;
  }

  public statusesForUpdateRenewalPayplan(): boolean {
    return this.EntityRisk?.risks[0]?.renewalCode === 'RWO' || this.EntityRisk?.risks[0]?.renewalCode === 'RWNT' || this.EntityRisk?.risks[0]?.renewalCode === 'RRWO';
  }

  public statusesForRenewalCheckbox(): boolean {
    return this.EntityRisk?.risks[0]?.renewalCode === 'RWO' || this.EntityRisk?.risks[0]?.renewalCode === 'RWNT' || this.EntityRisk?.risks[0]?.renewalCode === 'RRWO';
  }

  public updateAllRiskDetailsRiskBindBillingPaymentPayPlan(payPlanOption: string): void {
    this.EntityRisk.risks[0].riskDetails?.forEach(riskDetail => riskDetail.riskBinds[0].riskBindBilling.payPlanOption = payPlanOption);
  }

  public updateCurrentRiskBindBillingPaymentPayPlan(payPlanOption: string): void {
    this.EntityRisk.risks[0].riskDetails.find(a => a.isCurrentEndorsement).riskBinds[0].riskBindBilling.payPlanOption = payPlanOption;
  }

  public updateCurrentRiskBindBillingNextTermPaymentPayPlan(payPlanOption: string): void {
    this.EntityRisk.risks[0].riskDetails.find(a => a.isCurrentEndorsement).riskBinds[0].riskBindBilling.nextTermpaymentPlan = payPlanOption;
  }

  public getPropertyAddress(): AddressDTO3 {
    return this.EntityRisk?.entityAddresses?.find(a => a.addressTypeCode === AddressType.Property)?.address;
  }

  get isRenewalNotTaken(): boolean {
    return this.EntityRisk?.risks[0]?.renewalCode === AutoReinstatementConstants.reinstateStatus.RWNT && Boolean(this.EntityRisk?.risks[0]?.renewalFromRiskId);
  }

  removeDateTime(date?): any {
    return new Date(moment(date).format('MM/DD/YYYY'));
  }

  isActiveNewRaterVersion(effectiveDate: any): boolean {
    const newRaterVersionDate = this.auth.LoginDateFormGroup.get('NewRaterVersionDate').value !== '' || this.auth.LoginDateFormGroup.get('NewRaterVersionDate').value !== undefined || this.auth.LoginDateFormGroup.get('NewRaterVersionDate').value !== null
                      ? localStorage.getItem('NewRaterVersionDate')
                      : this.auth.LoginDateFormGroup.get('NewRaterVersionDate').value;
    const isRenewal = (this.policySummaryData.renewalCode !== null && this.policySummaryData.renewalCode !== undefined)
    || (this.policySummaryData.renewalStatusCode !== null && this.policySummaryData.renewalStatusCode !== undefined);
    const isPolicy = this.policySummaryData.isPolicy;
    const firstIssueDate = { isRange: false, singleDate: { jsDate: new Date(this.getRisk()?.firstIssueDate) } };
    const isRewrite = this.getRisk()?.isFromRewrite;
    const processDate = { isRange: false, singleDate: { jsDate: new Date(this.getRisk()?.processDate) } };
    let final_effectiveDate: any;
    // Uncomment if Renewal has issue on active dates
    // const relatedSubmissionList = this.submissionListData.relatedSubmissionList;

    // if (isRenewal) {
    //   final_effectiveDate = (relatedSubmissionList?.length > 2)
    //                       ? this.removeDateTime(relatedSubmissionList[relatedSubmissionList.length - 1]?.processDate)
    //                       : isPolicy ? this.removeDateTime(processDate?.singleDate?.jsDate) : this.removeDateTime(this.expiringPolicyEntityRisk?.risks[0]?.processDate);
    // } else
    if (isPolicy && !isRewrite) {
      final_effectiveDate = this.removeDateTime(processDate?.singleDate?.jsDate);
    } else if (isPolicy && isRewrite) {
      final_effectiveDate = firstIssueDate?.singleDate?.jsDate;
    } else {
      final_effectiveDate = effectiveDate?.jsDate ? effectiveDate?.jsDate : effectiveDate?.singleDate?.jsDate;
    }

    return moment(newRaterVersionDate).diff(final_effectiveDate, 'days') <= 0;
  }

  get agencyId(): string {
    return this.EntityRisk?.risks[0]?.agencyId;
  }

  public getExpiringRisk(): RiskDTO2 {
    return this.expiringPolicyEntityRisk?.risks[0];
  }

  public isNotCurrentPolicyNonRenewedWithRenewalNotTaken(): boolean {
    // scenario for issiebel only. if expiring risk got non renewed and renewal pending risk got renewal not taken
    return !(this.getRisk()?.isSiebel === true && this.getRisk()?.renewalStatusCode === AutoReinstatementConstants.reinstateStatus.RWNT &&
      this.getExpiringRisk()?.isSiebel === true && this.getExpiringRisk()?.renewalCode === this.pageHeaderConstants.policyRenewal.subStatusCode.NRWD);
  }

  public updateCurrentRisksRiskEndorsementData(riskEndorsements: RiskEndorsementDTO2[]): void {
    this.EntityRisk.risks[0].riskDetails[0].riskEndorsements = riskEndorsements;
  }

  public updateCurrentRisksRiskSchedulesData(riskSchedules: RiskScheduleDTO[]): void {
    this.EntityRisk.risks[0].riskDetails[0].riskSchedules = riskSchedules;
  }

  public updateCurrentRisksRiskFloodData(riskFlood: RiskFloodDTO[]): void {
    this.EntityRisk.risks[0].riskDetails[0].riskFloods = riskFlood;
  }

  get isRenewalQuote(): boolean {
    const riskStatusCode = this.EntityRisk?.risks[0]?.riskStatusCode;
    const renewalCode = this.EntityRisk?.risks[0]?.renewalCode;
    const hasExpiryPolicy = Boolean(this.EntityRisk?.risks[0]?.renewalFromRiskId);
    const isQuote = (riskStatusCode !== 'ACT' && riskStatusCode !== 'CAN' && riskStatusCode !== 'EXP' && riskStatusCode !== 'PEXP');
    const isRenewal = (renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RNPEN || renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RWO ||
      renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO || renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRW ||
      renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RWNT) && hasExpiryPolicy;

    return isQuote && isRenewal;
  }
}
