import { Injectable } from '@angular/core';
import { QuickQuoteService } from '../../../core/services/submission/quick-quote.service';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { RiskEndorsementDTO } from '../../../shared/models/data/dto/quick-quote/risk-endorsement.dto';
import { SaveEndorsementDTO } from '../../../shared/models/data/dto/quick-quote/save-endorsement.dto';
import { EndorsementsData } from './endorsements.data';
import { SummaryData } from './summary.data';
import { BaseClass } from '../../../shared/base-class';
import { PathConstants } from '../../../shared/constants/path.constants';
import { InfoMessageConstant } from '../../../shared/constants/info-message.constants';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessageConstant } from '../../../shared/constants/error-message.constants';
import { takeUntil } from 'rxjs/operators';
import { CoveragesData } from './coverages.data';
import { SubmissionPageData } from './submission-page.data';
import { EndorsementsResultDTO } from '../../../shared/models/data/dto/quick-quote/endorsements-result.dto';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { EntityRiskData } from './entity-risk.data';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { EndorsementValidatorErrorConstants } from '../validators/validator-error-constants/endorsement-validator-error.constants';
import { CalculateRaterData } from 'app/core/services/submission/rater-premium/calculate-rater-data.service';
import { QuoteService } from '../../../core/services/quote.service';
import { FormTypeConstants } from '../../../shared/constants/form-types.constants';
import { LocationLabelConstants } from '../../../shared/constants/property.labels.constants';
import { EndorsementsLabelsConstants } from '../../../shared/constants/endorsements.labels.constants';
import { PolicyIssueData } from '../../policy-management/data/policy-issue.data';
import { RiskStatusCode } from 'app/shared/models/data/dto/quick-quote/risk.dto';
import { Store } from '@ngrx/store';
import { updateSubmissionStatusFromEndorsementsSavingData } from 'app/store/submission/submission.actions';
import { SavingFlagConstants } from 'app/shared/constants/saving-flag.constants';
import FormUtils from 'app/shared/utilities/form.utils';
import { updatePolicyStatusFromEndorsementsSavingData, updatePolicySubStatusFromEndorsementsSavingData } from 'app/store/policy/policy.actions';
import { GetRiskStatusPipe } from 'app/shared/pipes/get-risk-status.pipe';
import { updateEndorsementsIsLoadingFromEndorsementsSavingData } from '../../../store/endorsements/endorsements.actions';

@Injectable({
  providedIn: 'root'
})

export class EndorsementsSavingData extends BaseClass {

  isEndorsementSkippedSaving: boolean = true;
  endorsementsLabelsConstants = EndorsementsLabelsConstants;
  limitedWaterDmgLimitInfoMessage: boolean = false;
  limitedWaterDmgInfoViewed: boolean = false;
  validLimitedWaterDmgLimit: number = null;
  invalidLimitedWaterDmgLimit: number = null;
  genericContants = GenericConstants;

  constructor(protected summaryData: SummaryData,
    public endorsementData: EndorsementsData,
    protected quickQuoteService: QuickQuoteService,
    protected raterData: CalculateRaterData,
    protected toast: ToastrService,
    public coveragesData: CoveragesData,
    public submissionData: SubmissionPageData,
    public policySummaryData: PolicySummaryData,
    public entityRiskData: EntityRiskData,
    protected quoteService: QuoteService,
    protected policyIssueData: PolicyIssueData,
    protected store: Store,
    protected getRiskStatus: GetRiskStatusPipe
  ) {
    super();
  }

  checkIsFromEndorsement(endorsementRequest: SaveEndorsementDTO): void {
    const limitedWaterLimitAmount = this.endorsementData.endorsementsGroup.get('limitedWaterLimit').value;

    if (limitedWaterLimitAmount >= this.endorsementsLabelsConstants.limitedWaterLimitAmount) {
      endorsementRequest.riskEndorsement.limitedWaterDamageLimit = +limitedWaterLimitAmount;
      this.validLimitedWaterDmgLimit = +limitedWaterLimitAmount;
      if (this.invalidLimitedWaterDmgLimit !== null) {
        this.invalidLimitedWaterDmgLimit = null;
      }
    } else {
      this.endorsementData.endorsementsGroup.get('limitedWaterLimit').setValue(endorsementRequest.riskEndorsement.limitedWaterDamageLimit);
      if (this.endorsementData.endorsementsGroup.get('limitedWaterLimit').errors?.required) {
        endorsementRequest.riskEndorsement.limitedWaterDamageLimit = this.validLimitedWaterDmgLimit;
      }
      endorsementRequest.riskEndorsement.limitedWaterDamageLimit = this.validLimitedWaterDmgLimit;
      this.limitedWaterDmgLimitInfoMessage = true;
      this.invalidLimitedWaterDmgLimit = limitedWaterLimitAmount === null ? -1 : limitedWaterLimitAmount;
    }
  }

  saveEndorsements(saveCoverages: boolean = true, propertyUsage?: string) {
    try {
      // save coverages on value change from endorsements
      this.quickQuoteService.setCategoryToSavingState(SavingFlagConstants.endorsements);
      if (saveCoverages) {
        this.saveCoverages(propertyUsage);
      }
      this.endorsementData.setPreviousCovE();

      this.store.dispatch(updateEndorsementsIsLoadingFromEndorsementsSavingData({ isLoading: true }));
      this.entityRiskData.startSavingForms();
      const riskId = this.entityRiskData.getRiskId();
      const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;
      const cntr = this.endorsementData.endorsementsGroup.controls['seasonalDwelling']?.value;

      if (saveCoverages) {
        this.checkIsFromEndorsement(this.getEndorsementRequest(riskId, riskDetailId));

        if (this.limitedWaterDmgLimitInfoMessage && !this.limitedWaterDmgInfoViewed) {
          this.limitedWaterDmgInfoViewed = true;
        } else if (this.limitedWaterDmgLimitInfoMessage && this.limitedWaterDmgInfoViewed) {
          if (this.invalidLimitedWaterDmgLimit === null) {
            this.limitedWaterDmgLimitInfoMessage = false;
            this.limitedWaterDmgInfoViewed = false;
          }
        }
      }

      this.postEndorsements(riskId, riskDetailId);
    } catch (error) {
      this.entityRiskData.finishSavingForms();
      this.quickQuoteService.setCategoryToNotSavingState(SavingFlagConstants.endorsements);
      NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
      this.store.dispatch(updateEndorsementsIsLoadingFromEndorsementsSavingData({ isLoading: false }));
    }
  }

  saveCoverages(propertyUsage?: string): void {
    if (this.quoteService.formType === FormTypeConstants.DP3) {
      this.coveragesData.saveCoverages(false, true, false, propertyUsage);
      return;
    }

    this.coveragesData.saveCoverages(false, true, false);
  }

  postEndorsements(riskId: any, riskDetailId: any): void {
    if (riskId && riskDetailId) {
      this.policySummaryData.saveEndorsementsComplete$.next(false);
      this.store.dispatch(updateEndorsementsIsLoadingFromEndorsementsSavingData({ isLoading: true }));

      this.endorsementData.waterDamageValue = this.endorsementData.endorsementsGroup.get('waterDamage').value;

      this.quickQuoteService.postEndorsements(this.getEndorsementRequest(riskId, riskDetailId)).pipe(takeUntil(this.stop$)).subscribe(res => {
        this.quickQuoteService.setCategoryToNotSavingState(SavingFlagConstants.endorsements);

        const result = <EndorsementsResultDTO>res;
        this.entityRiskData.finishSavingForms();
        this.entityRiskData.updateCurrentRisksRiskEndorsementData(result.riskEndorsements);

        if(this.entityRiskData.EntityRisk) {
          this.entityRiskData.updateCurrentRisksRiskEndorsementData(result.riskEndorsements);
        }

        if (this.isStarPackageSelected()) {
          this.coveragesData.saveCoverages(false, true, false);
          this.endorsementData.endorsementsGroup.get('starPackage').markAsPristine(); // will need this for saving of coverages when starPackage was set from true to false
          this.endorsementData.endorsementsGroup.get('starAdvantagePackage').markAsPristine(); // same as starPackage above
        }

        this.setQuoteStatus(result.riskStatusCode, result.riskSubStatusCode as RiskStatusCode);

        if (this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId())) {
          this.policySummaryData.subStatusCode$.next(res.riskSubStatusCode as RiskStatusCode);
        }


        FormUtils.markFormGroupAsPristine(this.endorsementData.endorsementsGroup); // need for claims DP3 chargeability checking
        this.summaryData.isCalcPremium.next(true);
        const policyNumber = this.entityRiskData?.EntityRisk?.risks[0]?.policyNumber ?? '';
        this.raterData.endorsementRaterRequest(this.endorsementData, policyNumber);
        NotifUtils.showConsoleSuccess(`${PathConstants.Submission.Submission.Endorsements} ${InfoMessageConstant.savedSuccessfullyMessage}`);

        this.setQuoteStatus(result.riskStatusCode, result.riskSubStatusCode as RiskStatusCode);

        if (this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId())) {
          this.policySummaryData.subStatusCode$.next(result.riskSubStatusCode as RiskStatusCode);
        }

        this.policySummaryData.saveEndorsementsComplete$.next(true);
        this.store.dispatch(updateEndorsementsIsLoadingFromEndorsementsSavingData({ isLoading: false }));
      },
        err => {
          this.quickQuoteService.setCategoryToNotSavingState(SavingFlagConstants.endorsements);
          this.entityRiskData.finishSavingForms();
          this.summaryData.isCalcPremium.next(false);
          this.store.dispatch(updateEndorsementsIsLoadingFromEndorsementsSavingData({ isLoading: false }));
          this.toast.error(ErrorMessageConstant.savingErrorMessage);
          NotifUtils.showError(JSON.parse(err.error).errors.toString());
          // add code here for error log
        }
      );
    }
  }

  // Prevent Application status reverting back to Quoted
  setQuoteStatus(riskStatusCode: RiskStatusCode, riskSubStatusCode: RiskStatusCode): void {
    if (riskStatusCode !== this.genericContants.quoteStatusCode.quo) {
      this.submissionData.quoteStatus.next(this.submissionData.getQuoteStatus(riskStatusCode));

      if (this.policySummaryData.isPolicy) {
        this.store.dispatch(updatePolicyStatusFromEndorsementsSavingData({ status: this.getRiskStatus.transform(riskStatusCode) }));
        this.store.dispatch(updatePolicySubStatusFromEndorsementsSavingData({ subStatus: this.getRiskStatus.transform(riskSubStatusCode) }));
      } else {
        this.store.dispatch(updateSubmissionStatusFromEndorsementsSavingData({ status: this.submissionData.getQuoteStatus(riskStatusCode) }));
      }
    }
  }

  getEndorsementRequest(riskId: any, riskDetailId: any): SaveEndorsementDTO {
    const endorsementRequest: SaveEndorsementDTO = {
      riskId: riskId,
      riskDetailId: riskDetailId,
      riskEndorsement: this.getRiskEndorsement(riskDetailId),
      isValidForApplication: this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId()) ? false : this.submissionData.isValidForApplication ?? false
    };

    return endorsementRequest;
  }

  getRiskEndorsement(riskDetailId: any): RiskEndorsementDTO {
    this.endorsementData.checkCoverageCLimitId();
    const formType = this.endorsementData.quoteService.formType;
    const formTypeConstants = this.endorsementData.formTypeConstants;
    if (!this.endorsementData.endorsementsGroup.controls['ordinanceLawOrCov'].value) {
      this.endorsementData.endorsementsGroup.get('ordinanceLaw').setValue('');
    }

    if (!this.endorsementData.endorsementsGroup.controls['fungiWetOrDryRot'].value) {
      this.endorsementData.endorsementsGroup.get('fungiWetOrDryRotLimit').setValue('');
    }
    const propertyUsage = this?.endorsementData?.propertyData?.locationDetails?.get('propertyUsage').value?.toString()?.toUpperCase()?.trim();
    const riskEndorsement: RiskEndorsementDTO = {
      riskDetailId: riskDetailId,
      isLimitedFungiWet: formType === formTypeConstants.DP3 ? false : this.endorsementData.endorsementsGroup.controls['limitedFungi'].value,
      limitedFungiWetLimitCode: formType === formTypeConstants.DP3 ? null :
        this.endorsementData.endorsementsGroup.controls['limitedFungi'].value ?
          this.endorsementData.limitedFungiLimitsOptions.find(x => x.code === Number(this.endorsementData.endorsementsGroup.controls['limitedFungiLimit'].value))?.code : null,

      isWaterBackUpSump: this.endorsementData.endorsementsGroup.controls['waterBackup'].value,
      waterBackUpSumpLimit: this.endorsementData.endorsementsGroup.controls['waterBackup'].value ? this.endorsementData.endorsementsGroup.controls['waterBackupLimit'].value.replace('$', '').replace(',', '') : null,
      waterBackUpSumpDeductible: this.endorsementData.endorsementsGroup.controls['waterBackup'].value ? this.endorsementData.endorsementsGroup.controls['waterBackupDeductible'].value.replace('$', '').replace(',', '') : null,

      isLimitedWaterDamage: this.endorsementData.endorsementsGroup.controls['limitedWater'].value,
      limitedWaterDamageLimit: this.endorsementData.endorsementsGroup.controls['limitedWater'].value ?
        this.validLimitedWaterDmgLimit ? this.validLimitedWaterDmgLimit : this.endorsementData.endorsementsGroup.controls['limitedWaterLimit'].value : null,

      isOrdinanceLaw: formType !== formTypeConstants.HO4 ? this.endorsementData.endorsementsGroup.controls['ordinanceLawOrCov'].value : false,
      ordinanceLaw: formType !== formTypeConstants.HO4 ? (this.endorsementData.endorsementsGroup.controls['ordinanceLawOrCov'].value ? this.endorsementData.endorsementsGroup.controls['ordinanceLaw'].value : null) : null,
      isWaterDamageExclusion: this.endorsementData.endorsementsGroup.controls['waterDamage'].value,

      isPersonalProRepCost: this.endorsementData.endorsementsGroup.controls['personalProperty'].value,

      isPersonalInjury: this.endorsementData.endorsementsGroup.controls['personalInjury'].value,

      isEquipBreakdownEnEndr: this.endorsementData.endorsementsGroup.controls['equipmentBreakdown'].value,

      isIdentityFraudExCov: this.endorsementData.endorsementsGroup.controls['identityFraud'].value,

      isStarPackage: this.endorsementData.endorsementsGroup.controls['starPackage'].value,
      isStarAdvantagePackage: this.endorsementData.endorsementsGroup.controls['starAdvantagePackage'].value,

      isLossAssessCov: this.endorsementData.endorsementsGroup.controls['lossAssessment'].value,
      lossAssessCovCode: this.endorsementData.endorsementsGroup.controls['lossAssessment'].value ? this.endorsementData.lossAssessmentLimitOptions.find(x => x.code === Number(this.endorsementData.endorsementsGroup.controls['lossAssessmentLimit'].value))?.code : null,

      screenedEnclosure: this.endorsementData.endorsementsGroup.controls['screenedEnclosure'].value,
      screenedEnclosureLimit: this.endorsementData.endorsementsGroup.controls['screenedEnclosure'].value!=='N' ? this.endorsementData.endorsementsGroup.controls['screenedEnclosureLimit'].value : null,
      isAnimalLiabilityEndr: this.endorsementData.endorsementsGroup.controls['animalLiability'].value,
      animalLiabilityEndrLimit: this.endorsementData.endorsementsGroup.controls['animalLiability'].value ? this.endorsementData.endorsementsGroup.controls['animalLiabilityLimit'].value.replace('$', '').replace(',', '') : null,

      isHomeComputerCov: this.endorsementData.endorsementsGroup.controls['homeComputer'].value,
      homeComputerCovLimitCode: this.endorsementData.endorsementsGroup.controls['homeComputer'].value ? this.endorsementData.homeComputerLimitOptions.find(x => x.code === Number(this.endorsementData.endorsementsGroup.controls['homeComputerLimit'].value))?.code : null,

      isServiceLineEnEndr: this.endorsementData.endorsementsGroup.controls['serviceLine'].value,
      serviceLineEnEndrLimit: this.endorsementData.endorsementsGroup.controls['serviceLine'].value ? this.endorsementData.endorsementsGroup.controls['serviceLineLimit'].value.replace('$', '').replace(',', '') : null,
      serviceLineEnEndrDeductible: this.endorsementData.endorsementsGroup.controls['serviceLine'].value ? this.endorsementData.endorsementsGroup.controls['serviceLineDeductible'].value.replace('$', '').replace(',', '') : null,

      isGolfCartPhyDamLiaEndr: this.endorsementData.endorsementsGroup.controls['golfCart'].value,
      numberOfGolfCarts: this.endorsementData.endorsementsGroup.controls['golfCart'].value ? this.endorsementData.endorsementsGroup.controls['numberOfGolfCarts'].value : null,
      golfCartLiabilityTypeCode: this.endorsementData.endorsementsGroup.controls['golfCart'].value ? this.endorsementData.liabilityTypeOptions.find(x => x.code === this.endorsementData.endorsementsGroup.controls['liabilityType'].value)?.code : null,

      isCovCIncSpecialLimitOfLiability: this.endorsementData.endorsementsGroup.controls['coverageC'].value,
      jewWatFursIncreasedAmtCode: this.endorsementData.endorsementsGroup.controls['coverageC'].value ? this.endorsementData.jewelryIncreasedOptions.find(x =>
        x.code === (this.endorsementData.endorsementsGroup.controls['jewelryIncreased'].value !== '' ? Number(this.endorsementData.endorsementsGroup.controls['jewelryIncreased'].value) : ''))?.code : null,
      jewWatFursIncreasedTotalAmt: this.endorsementData.endorsementsGroup.controls['jewelryIncreased'].value ? this.endorsementData.endorsementsGroup.controls['jewelryTotal'].value : null,
      silGoldPewIncreasedAmtCode: this.endorsementData.endorsementsGroup.controls['coverageC'].value ? this.endorsementData.silverwareIncreasedOptions.find(x =>
        x.code === (this.endorsementData.endorsementsGroup.controls['silverwareIncreased'].value !== '' ? Number(this.endorsementData.endorsementsGroup.controls['silverwareIncreased'].value) : ''))?.code : null,
      silGoldPewTotalAmt: this.endorsementData.endorsementsGroup.controls['silverwareIncreased'].value ? this.endorsementData.endorsementsGroup.controls['silverwareTotal'].value : null,

      isIncreaseRepCostDwelling: this.endorsementData.endorsementsGroup.controls['increasedReplacement'].value,

      isSpecialPersonalProCov: this.endorsementData.endorsementsGroup.controls['specialPersonal'].value,

      isPremisesAlarmFireProcSys: this.endorsementData.endorsementsGroup.controls['premisesAlarm'].value,

      isSinkHoleLossCov: this.endorsementData.endorsementsGroup.controls['sinkholeLoss'].value,
      sinkholeDeductible: this.endorsementData.endorsementsGroup.controls['sinkholeDeductible'].value,

      IsSeasonalDwellingEndr: formType !== formTypeConstants.HO4 ? this.endorsementData.endorsementsGroup.controls['seasonalDwelling'].value ?? false : false,
      isRoofSurfacingPaymentSchedule: this.endorsementData.endorsementsGroup.controls['roofSurfacingPaymentSchedule'].value,
      isUnitOwnersCoverageA: formType === FormTypeConstants.HO6 ? this.endorsementData.endorsementsGroup.controls['unitOwnersCoverageASpecialCoverage'].value : false,
      isUnitOwnersRentalToOthers: this.endorsementData.endorsementsGroup.controls['unitOwnersRentalToOthers'].value,
      leaseTerm: this.endorsementData.endorsementsGroup.controls['unitOwnersRentalToOthers'].value ? this.endorsementData.endorsementsGroup.controls['leaseTerm'].value : null,

      isLimitedFungiWetIncreasedAmount: this?.endorsementData?.endorsementsGroup?.controls['fungiWetOrDryRot']?.value,
      limitedFungiWetIncreasedAmountLimitCode: this?.endorsementData.endorsementsGroup?.controls['fungiWetOrDryRot']?.value ? this?.endorsementData?.endorsementsGroup?.controls['fungiWetOrDryRotLimit']?.value : null,
      isPersonalLiability: this?.endorsementData?.endorsementsGroup?.controls['personalLiability']?.value,
      isPremisesLiability: this?.endorsementData?.endorsementsGroup?.controls['premisesLiability']?.value,
      isPermittedIncidentalOccupancies: this?.endorsementData?.endorsementsGroup?.controls['permittedIncidentalOccupancies']?.value,
      occupancyDescription: this?.endorsementData.endorsementsGroup?.controls['permittedIncidentalOccupancies']?.value ? this?.endorsementData?.endorsementsGroup?.controls['descriptionOfOccupancy']?.value : null,
      isPermittedIncidentalOccupanciesLiability: this?.endorsementData?.endorsementsGroup?.controls['permittedIncidentalOccupanciesLiability']?.value,
      businessDescription: this?.endorsementData.endorsementsGroup?.controls['permittedIncidentalOccupanciesLiability']?.value ? this?.endorsementData?.endorsementsGroup?.controls['descriptionOfBusiness']?.value : null,
      businessLocation: this?.endorsementData.endorsementsGroup?.controls['permittedIncidentalOccupanciesLiability']?.value ? this?.endorsementData?.endorsementsGroup?.controls['businessLocation']?.value : null,
      isLimitedTheftCoverage: this?.endorsementData?.isLimitedTheftCoverageAvailable ? this?.endorsementData?.endorsementsGroup?.controls['limitedTheftCoverage']?.value : false,
      isVacancyEndorsement: this?.endorsementData?.endorsementsGroup?.controls['vacancyEndorsement']?.value,
      isWindstormExteriorPaint: this?.endorsementData?.endorsementsGroup?.controls['windstormExteriorPaintoOrWaterproofingExclusionSeacoastFlorida']?.value ?? false,
    };

    this.endorsementData.currentLimitedWaterDmg = this.endorsementData.endorsementsGroup.controls['limitedWater'].value;

    // IsPremiumAdjustment
    const isPremAdj = this.endorsementData.endorsementsGroup.controls['premiumAdjustment'].value;
    riskEndorsement.isPremiumAdjustment = isPremAdj;

    // PremiumAdjustment
    const premAdj = this.endorsementData.endorsementsGroup.controls['premiumAdjustmentAmount'].value;
    // const premAdj = this.endorsementData.currencyChecking.checkAmountForSaving(this.endorsementData.endorsementsGroup.controls['premiumAdjustmentAmount'].value ? this.endorsementData.endorsementsGroup.controls['premiumAdjustmentAmount'].value : 0);
    const savePremAdj = isPremAdj ? premAdj : null;

    this.endorsementData.currentPremiumAdjustmentAmount = savePremAdj;
    riskEndorsement.premiumAdjustmentAmount = savePremAdj;

    // IsConsentToRate
    const isConRate = this.endorsementData.endorsementsGroup.controls['consentToRate'].value;
    riskEndorsement.isConsentToRate = isConRate;

    // ConsentToRate
    const conRate = this.endorsementData.endorsementsGroup.controls['consentToRateAmount'].value;
    const saveConRate = isConRate ? conRate : null;

    this.endorsementData.currentConsentToRateAmount = saveConRate;
    riskEndorsement.consentToRateAmount = saveConRate;

    // IsPersonalPropertyAtOtherResidences
    const isPerPropAOR = this.endorsementData.endorsementsGroup.controls['personalPropertyAtOtherResidences'].value;
    riskEndorsement.isPersonalPropertyAtOtherResidences = isPerPropAOR;

    // PersonalPropertyAtOtherResidences
    const perPropAOR = this.endorsementData.endorsementsGroup.controls['personalPropertyAtOtherResidencesLimit'].value;

    const savePerPropAOR =
      isPerPropAOR ? // Check if IsPersonalPropertyAtOtherResidences is checked
        perPropAOR ? // Check if PersonalPropertyAtOtherResidences has value
          !this.endorsementData.endorsementsGroup.get('personalPropertyAtOtherResidencesLimit').hasError(EndorsementValidatorErrorConstants.personalPropertyAtOtherResidencesLimitDivisibleErrorMessage.key) ? // Check if divisible by 1000
            perPropAOR
            : this.endorsementData.currentPersonalPropertyAtOtherResidencesLimit ?
              this.endorsementData.currentPersonalPropertyAtOtherResidencesLimit : null
        : this.endorsementData.currentPersonalPropertyAtOtherResidencesLimit ?
          this.endorsementData.currentPersonalPropertyAtOtherResidencesLimit : null
      : null;
    this.endorsementData.currentPersonalPropertyAtOtherResidencesLimit = savePerPropAOR;
    riskEndorsement.personalPropertyAtOtherResidencesLimit = savePerPropAOR;

    const premAdjAmt = this.endorsementData.endorsementsGroup.get('premiumAdjustmentAmount').value;
    this.endorsementData.endorsementsGroup.get('premiumAdjustmentAmount').setValue(premAdjAmt !== GenericConstants.CustomAmountChecking.withNegativeDollarSign ? premAdjAmt : '');

    return riskEndorsement;
  }

  getscreenedEncCarPortCovId(code, isSelected): number {
    if (isSelected) {
      return this.endorsementData.screenedEnclosureLimitOptions.find(x => x.code === Number(code))?.code;
    } else {
      return null;
    }
  }

  isStarPackageSelected(): boolean {
    return this.endorsementData.endorsementsGroup.get('starPackage').value === true ||
    this.endorsementData.endorsementsGroup.get('starAdvantagePackage').value === true ||
    this.endorsementData.endorsementsGroup.get('starPackage').dirty ||
    this.endorsementData.endorsementsGroup.get('starAdvantagePackage').dirty;
  }
}
