import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PolicyDocumentsComponent } from './policy-documents.component';
import { SharedModule } from '../../../shared/shared.module';
import { PolicyDocumentsModalComponent } from './policy-documents-modal/policy-documents-modal.component';
import { SuppressDocumentsComponent } from './suppress-documents/suppress-documents.component';
import { TooltipModule } from 'ngx-bootstrap';

const routes: Routes = [
  { path: '', component: PolicyDocumentsComponent }
];

@NgModule({
  declarations: [
    PolicyDocumentsComponent,
    PolicyDocumentsModalComponent,
    SuppressDocumentsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    TooltipModule
  ],
  exports: [PolicyDocumentsComponent],
  entryComponents: [PolicyDocumentsModalComponent]
})
export class PolicyDocumentsModule { }
