export const LvRiskStatus = [
  {
    id: 1,
    code: 'PEN',
    description: 'Pending'
  },
  {
    id: 2,
    code: 'IF',
    description: 'In-Force'
  },
  {
    id: 3,
    code: 'RUW',
    description: 'Referred to UW'
  },
  {
    id: 4,
    code: 'AUW',
    description: 'Approved by UW'
  },
  {
    id: 5,
    code: 'DUW',
    description: 'Declined by UW'
  },
  {
    id: 6,
    code: 'QEXP',
    description: 'Quote Expired'
  },
  {
    id: 7,
    code: 'ACT',
    description: 'Active'
  },
  {
    id: 8,
    code: 'PEC',
    description: 'Pending Cancellation'
  },
  {
    id: 9,
    code: 'CAN',
    description: 'Cancelled'
  },
  {
    id: 10,
    code: 'PER',
    description: 'Pending Renewal'
  },
  {
    id: 11,
    code: 'PRP',
    description: 'Pending Renewal Payment'
  },
  {
    id: 12,
    code: 'QUO',
    description: 'Quoted'
  },
  {
    id: 13,
    code: 'APP',
    description: 'Application'
  },
  {
    id: 14,
    code: 'PEXP',
    description: 'Policy Expired'
  },
  {
    id: 15,
    code: 'AIR',
    description: 'Additional Information Required'
  },
  {
    id: 16,
    code: 'ISS',
    description: 'Issued'
  },
  {
    id: 17,
    code: 'PNR',
    description: 'Pending Non-Renewal'
  },
  {
    id: 18,
    code: 'ARINUW',
    description: 'Approved for Reinstatement'
  },
  {
    id: 19,
    code: 'RNPEN',
    description: 'Renewal Pending'
  },
  {
    id: 20,
    code: 'RWO',
    description: 'Renewal Offered'
  },
  {
    id: 21,
    code: 'RWNT',
    description: 'Renewal Not Taken'
  },
  {
    id: 22,
    code: 'RRNPEN',
    description: 'Revised Renewal Pending'
  },
  {
    id: 23,
    code: 'RRWO',
    description: 'Revised Renewal Offered'
  },
  {
    id: 24,
    code: 'RRW',
    description: 'Rescinded Renewal Offer'
  },
  {
    id: 34,
    code: 'PRRW',
    description: 'Rescinded Renewal Pending'
  }
];

export const StatusConstants = {
  approvedByUW: 'AUW',
  pending: 'PEN',
  cancelled: 'CAN',
  approvedForReinstatement: 'ARINUW',
  quote: 'QUO',
  additionalInformationRequired: 'AIR',
  declinedByUW: 'DUW',
  referredToUW: 'RUW',
  application: 'APP'
};

export const RiskStatusConstants = {
  status: 'Status',
  quoted: 'Quoted',
  application: 'Application',
  referredToUW: 'Referred to UW',
  approvedByUW: 'Approved by UW',
  declinedByUW: 'Declined by UW',
  additionalInformationrequired: 'Additional Information Required',
  quoteExpired: 'Quote Expired',
  active: 'Active',
  pending: 'Pending',
  pendingCancellation: 'Pending Cancellation',
  policyExpired: 'Policy Expired',
  draft: 'Draft',
  cancelled: 'Cancelled'
};