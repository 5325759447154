import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReferenceListDetailsDTO } from 'app/shared/models/data/dto/dashboard/reference-list';
import { IBanner } from 'app/shared/models/dashboard.banner.model';
import { IHelpBoxProgram } from 'app/shared/models/dashboard.helpbox.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IProcessEndorsement } from '../../shared/models/dashboard.process.endorsement.model';
import { IAgentDashboardDTO } from 'app/shared/models/data/dto/dashboard/agentDashboardDTO';
import { CommonService } from './common.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashBoardService {
  baseUrl: string;

  constructor(private http: HttpClient,
    private commonService: CommonService) {
    this.baseUrl = environment.ApiUrl;
  }

  getBanners(): Observable<IBanner[]> {
    const url = `${this.baseUrl}/Banner`;
    return this.http.get<IBanner[]>(url);
  }

  getHelpBoxSection(): Observable<IHelpBoxProgram[]> {
    const url = `${this.baseUrl}/AgentDashboard/helpbox-program`;
    return this.http.get<IHelpBoxProgram[]>(url);
  }

  getReferenceBoxList(): Observable<ReferenceListDetailsDTO[]> {
    const url = `${this.baseUrl}/AgentDashboard/reference-box`;
    return this.http.get<ReferenceListDetailsDTO[]>(url);
  }

  postLatestRiskId(number_code_payload: any): Observable<IProcessEndorsement> {
    const url = `${this.baseUrl}/Submission/number-code`;
    return this.http.post<IProcessEndorsement>(url, number_code_payload)
    .pipe(
        catchError(this.commonService.handleObservableHttpError)
    );
  }

  getAgentDashBoardPolicySection(agentId: string): Observable<IAgentDashboardDTO> {
    return this.http.get(`${environment.ApiUrl}/AgentDashboard/agent-policy-section?agentId=${agentId}`)
      .pipe(
        map(result => {
          return result as IAgentDashboardDTO;
        }),
        catchError(this.commonService.handleObservableHttpError)
      );
  }
}
