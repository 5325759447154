import { createSelector } from '@ngrx/store';
import { BaseState } from '../base.state';

export const selectDashboardState = (state: BaseState) => state.dashboard;

export const selectDashboardProgramStateAgentDetailIsLoading = createSelector(
  selectDashboardState,
  (state) => state.isLoading
);

export const selectDashboardProgramStateAgentDetailStates = createSelector(
  selectDashboardState,
  (state) => state.stateList
);