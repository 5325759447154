import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubmissionPageData } from '../../data/submission-page.data';
import { ErrorMessageConstant } from '../../../../shared/constants/error-message.constants';
import { ApplicantLabelsConstants } from 'app/shared/constants/applicant.labels.constants';
import { ApplicantData } from '../../data/applicant.data';
import FormUtils from 'app/shared/utilities/form.utils';
import { AuthService } from 'app/core/services/auth.service';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';

@Component({
  selector: 'app-applicant-contact',
  templateUrl: './applicant-contact.component.html',
  styleUrls: ['./applicant-contact.component.scss'],
})
export class ApplicantContactComponent implements OnInit, OnDestroy {
  public isContactHidden: boolean = false;
  public ErrorMessageConstant = ErrorMessageConstant;
  public LabelMessageConstant = ApplicantLabelsConstants;
  public FormUtils = FormUtils;

  constructor(public applicantData: ApplicantData,
              protected submissionData: SubmissionPageData,
              protected authService: AuthService,
              protected policySummaryData: PolicySummaryData) {}

  ngOnInit(): void {
    this.validateValue('emailAddress', this.applicantData.validAddress);
    this.validateValue('mobilePhoneNumber', this.applicantData.validMobilePhone);
    this.validateValue('homePhoneNumber', this.applicantData.validHomePhone);
  }

  get UATCNTR910_Logic(): boolean {
    return this.authService.userType.value === 'external' && (this.policySummaryData.isPolicy || this.policySummaryData.isRenewal());
  }

  get isSubmission(): boolean {
    return !this.policySummaryData.isPolicy && !this.policySummaryData.isRenewal();
  }

  private validateValue(fieldName: string, validValue: string){
    this.applicantData.contact.get(fieldName).setValue((validValue) ? validValue : null);
  }

  public ToggleHidingOfContact(): void {
    this.isContactHidden = !this.isContactHidden;
    this.submissionData.isContactOpen = !this.isContactHidden;
  }

  ngOnDestroy(){
    this.revalidateContactFields();
    this.validateValue('emailAddress', this.applicantData.validAddress);
    this.validateValue('mobilePhoneNumber', this.applicantData.validMobilePhone);
    this.validateValue('homePhoneNumber', this.applicantData.validHomePhone);
  }

  revalidateContactFields(): void{
    if (!this.applicantData.contact.get('emailAddress').errors?.pattern){
      this.applicantData.validAddress = this.applicantData.contact.get('emailAddress').value;
    }

    if (!this.applicantData.contact.get('mobilePhoneNumber').errors?.pattern){
      this.applicantData.validMobilePhone = this.applicantData.contact.get('mobilePhoneNumber').value;
    }

    if (!this.applicantData.contact.get('homePhoneNumber').invalid){
      this.applicantData.validHomePhone = this.applicantData.contact.get('homePhoneNumber').value;
    }
  }
}
