import { Component, OnInit, Inject, OnDestroy, HostListener } from '@angular/core';
import { LayoutService } from '../../core/services/layout/layout.service';
import { createSubmissionDetailsMenuItems } from './submission-management-navitems';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SubmissionService } from '../../core/services/submission/submission.service';
import { DOCUMENT } from '@angular/common';
import { ApplicantService } from '../../core/services/submission/applicant.service';
import { PathConstants } from '../../shared/constants/path.constants';
import { CoveragesData } from './data/coverages.data';
import { SubmissionPageData } from './data/submission-page.data';
import { BindAndIssueData } from './data/bind-and-issue.data';
import { PropertyData } from './data/property.data';
import { QuickQuoteData } from './data/quick-quote.data';
import { ApplicantData } from './data/applicant.data';
import { SummaryData } from './data/summary.data';
import { EndorsementsData } from './data/endorsements.data';
import { UwQuestionsData } from './data/uw-questions.data';
import { InterestData } from './data/interest.data';
import { ClaimsData } from './data/claims.data';
import { EntityRiskData } from './data/entity-risk.data';
import { EntityRiskDTO } from '../../shared/models/data/dto/quick-quote/entity-risk.dto';
import NotifUtils from '../../shared/utilities/notif-utils';
import { ErrorMessageConstant } from '../../shared/constants/error-message.constants';
import { ZipData } from './data/zip.data';
import Utils from '../../shared/utilities/utils';
import { UwApprovalData } from './data/uw-approval.data';
import { ThirdPartyDataService } from '../../core/services/submission/third-party/third-party-data.service';
import { ThirdPartyData } from './data/third-party.data';
import { BaseClass } from '../../shared/base-class';
import {
  takeUntil,
  distinctUntilChanged,
  switchMap,
  tap,
  pairwise,
  map,
  filter
} from 'rxjs/operators';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { ZipCodeData } from './data/zipcode.data';
import { environment } from '../../../environments/environment';

import { SubmissionNavSavingService } from '../../core/services/navigation/submission-nav-saving.service';
import * as _ from 'lodash';
import { SubmissionNavValidationService } from '../../core/services/navigation/submission-nav-validation.service';
import { PolicySummaryData } from '../policy-management/data/policy-summary.data';
import { QuoteService } from '../../core/services/quote.service';
import { EndorsementsSavingData } from './data/endorsements.saving.data';
import { InterestSavingData } from './data/interest-saving.data';
import { ValidationListModel } from '../../shared/models/validation-list.model';
import { Rater } from '../../shared/constants/rater.constant';
import { AuthService } from '../../core/services/auth.service';
import { UWR } from 'app/core/services/submission/uw-referrals/uwr.service';
import { CalculateRaterData } from '../../core/services/submission/rater-premium/calculate-rater-data.service';
import { MoratoriumService } from '../../core/services/moratorium.services';
import { INavDetails, NavData } from 'app/_nav';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { FormTypeConstants } from '../../shared/constants/form-types.constants';
import { ComparativeRaterData } from '../comparative-rater/comparative-rater.data';
import { ApplicantValidationService } from 'app/core/services/submission/validations/applicant-validation.service';
import { PolicyService } from 'app/core/services/submission/policy.service';
import { SubmissionConstants } from 'app/shared/constants/submission.constants';
import { ClaimsSavingData } from 'app/modules/submission-management/data/claims-saving.data';
import { Observable, Subject, Subscription, combineLatest, concat, forkJoin } from 'rxjs';
import { SubmissionListData } from './data/submission-list.data';
import { IRiskClaimDTO } from 'app/shared/models/data/dto/quick-quote/risk-claim.dto';
import { AutoReinstatementData } from '../policy-management/data/auto-reinstatement.data';
import { UserService } from 'app/core/services/management/user.service';
import * as moment from 'moment';
import { UserDTO } from 'app/shared/models/management/user';
import { UwApprovalSavingData } from './data/uw-approval-saving.data';
import { RiskStatusConstants, StatusConstants } from 'app/shared/constants/risk-status';
import { QuickQuoteService } from 'app/core/services/submission/quick-quote.service';
import { PropertyBusinessRules } from './business-rules/property.business-rules';
import { HORaterRequestData } from 'app/core/services/submission/rater-premium/ho-rater-request-data.service';
import { RiskStatus } from 'app/shared/models/data/dto/quick-quote/risk.dto';
import { select, Store } from '@ngrx/store';
import { selectSubmissionIsLoading, selectSubmissionIsPopulatingForms, selectSubmissionStatus } from 'app/store/submission/submission.selectors';
import { RiskBindNoteDTO } from 'app/shared/models/data/dto/quick-quote/risk-bind-note.dto';
import { updateSubmissionIsLoadingFromSubmissionManagementComponent, updateSubmissionIsPopulatingFormsFromSubmissionManagementComponent } from 'app/store/submission/submission.actions';
import { updateAppIsLoadingFromSubmissionManagementComponent } from 'app/store/app/app.actions';
import { selectSubmissionUWRsTableRows } from 'app/store/submission-uwrs/submission-uwrs.selectors';
import { ITableTr } from 'app/shared/models/dynamic/table.interface';
import { selectMoratoriumIsLoading } from 'app/store/moratorium/moratorium.selectors';
import { selectRaterIsLoading } from 'app/store/rater/rater.selectors';
import { selectRaterCalculateDP3IsLoading } from 'app/store/rater-calculate-dp3/rater-calculate-dp3.selectors';
import { selectInterestsList } from 'app/store/interests/interests.selectors';
import {
  updateBackupDataFormSubmissionManagementComponent,
  updateBackupIsLoadingFormSubmissionManagementComponent
} from 'app/store/data-backup/data-backup.actions';
import { selectBackupIsLoading } from 'app/store/data-backup/data-backup.selectors';
import { updateMoratoriumIsLoadingFromSubmissionManagementComponent } from 'app/store/moratorium/moratorium.actions';
import { updateRaterIsLoadingFromSubmissionManagementComponent } from 'app/store/rater/rater.actions';
import { updateInterestsListFromSubmissionManagementComponent } from 'app/store/interests/interests.actions';
import {
  updateChangesIsLoadingFromSubmissionManagementComponent,
  updateChangesListFromSubmissionManagementComponent
} from 'app/store/changes/changes.actions';
import { MoratoriumType } from 'app/shared/constants/moratoriumType';
import { EntitySubmissionListDTO } from 'app/shared/models/data/dto/submission-list/entity-submission-list.dto';
import { selectRelatedRiskstList } from 'app/store/related-risks/related-risks.selectors';
import { updateClaimsListFromSubmissionManagementComponent } from 'app/store/claims/claims.actions';
import { selectCalculateValuationIsLoading } from 'app/store/calculate-valuation/calculate-valuation.selectors';
import { updateCalculateValuationDataFromThirdPartyData } from 'app/store/calculate-valuation/calculate-valuation.actions';
import { updateCoveragesSchedulesFromSubmissionManagementComponent } from 'app/store/coverages/coverages.actions';
import { Location } from '@angular/common';

type PolicyBackupRemark = 'Renewal Pending' | 'Renewal Offer';
type RenewalStatusCode = null | 'RNPEN' | 'RWO' | 'RRW' | 'RWNT' | 'RRNPEN' | 'RRWO';

@Component({
  selector: 'app-submission-management',
  templateUrl: './submission-management.component.html',
  styleUrls: ['./submission-management.component.scss']
})
export class SubmissionManagementComponent extends BaseClass implements OnInit, OnDestroy {
  protected selectSubmissionIsLoading$: Observable<boolean>;
  protected selectSubmissionStatus$: Observable<RiskStatus>;
  protected selectSubmissionUWRsTableRows$: Observable<ITableTr[]>;
  protected selectMoratoriumIsLoading$: Observable<boolean>;
  protected selectRaterIsLoading$: Observable<boolean>;
  protected selectRaterCalculateDP3IsLoading$: Observable<boolean>;
  protected selectRelatedRiskstList$: Observable<EntitySubmissionListDTO[]>;
  protected selectInterestsList$: Observable<any>;
  protected selectBackupIsLoading$: Observable<boolean>;
  protected uwrPopulated$ = new Subject();
  protected selectCalculateValuationIsLoading$: Observable<boolean>;
  protected selectSubmissionIsPopulatingForms$: Observable<boolean>;

  public sidebarMinimized = true;
  public ErrorMessageConstant = ErrorMessageConstant;
  protected changes: MutationObserver;
  public element: HTMLElement;
  genericConstants = GenericConstants;
  agentDashboardConstants = AgentDashboardConstants;

  // currentSubmissionId: string;
  currentRiskDetailId: string;
  currentRiskId: string;

  // upon navigation saving
  protected newCategoryRoute: string;
  protected currentCategoryRoute: string;
  formTypeConstants = FormTypeConstants;
  protected submissionConstants = SubmissionConstants;
  protected policyBackupRemark: PolicyBackupRemark;
  protected getAllSubmissionDocumentsSubscription: Subscription;
  protected moratoriumChecked: boolean = false;
  protected showUWQuestionRestricted$: Subject<boolean> = new Subject();
  protected riskStatus: RiskStatus = null;

  constructor(
    protected route: ActivatedRoute,
    protected submissionService: SubmissionService,
    protected applicantService: ApplicantService,
    protected layoutService: LayoutService,
    protected coveragesData: CoveragesData,
    protected submissionData: SubmissionPageData,
    protected bindAndIssueData: BindAndIssueData,
    protected propertyData: PropertyData,
    protected quickQuoteData: QuickQuoteData,
    protected applicantData: ApplicantData,
    protected summaryData: SummaryData,
    protected endorsementsData: EndorsementsData,
    protected uwQuestionsData: UwQuestionsData,
    protected interestData: InterestData,
    protected claimsData: ClaimsData,
    public entityRiskData: EntityRiskData,
    public zipData: ZipData,
    public uwApprovalData: UwApprovalData,
    protected thirdPartyDataService: ThirdPartyDataService,
    protected thirdPartyData: ThirdPartyData,
    protected storage: LocalStorageService,
    protected raterData: CalculateRaterData,
    protected zipCodeData: ZipCodeData,
    protected submissionNavSavingService: SubmissionNavSavingService,
    protected submissionNavValidationService: SubmissionNavValidationService,
    protected router: Router,
    protected policySummaryData: PolicySummaryData,
    protected quoteService: QuoteService,
    protected endorsementsSavingData: EndorsementsSavingData,
    protected interestSavingData: InterestSavingData,
    protected authService: AuthService,
    protected uwr: UWR,
    protected moratoriumService: MoratoriumService,
    protected comparativeRaterData: ComparativeRaterData,
    protected applicantValidationService: ApplicantValidationService,
    public policyService: PolicyService,
    protected claimsSavingData: ClaimsSavingData,
    protected submissionListData: SubmissionListData,
    protected autoReinstateData: AutoReinstatementData,
    protected userService: UserService,
    protected uwApprovalSavingData: UwApprovalSavingData,
    protected quickQuoteService: QuickQuoteService,
    protected propertyBusinessRules: PropertyBusinessRules,
    protected hoRaterRequestData: HORaterRequestData,
    protected store: Store,
    protected location: Location,
    @Inject(DOCUMENT) _document?: any,
  ) {
    super();
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
    this.thirdPartyData.creditOrderDetail$.pipe(takeUntil(this.stop$)).subscribe(detail => {
      this.summaryData.SummaryForm.get('creditOrderStatus').setValue(detail.statusDescription);
    });

    this.selectSubmissionIsLoading$ = this.store.pipe(select(selectSubmissionIsLoading));
    this.selectSubmissionStatus$ = this.store.pipe(select(selectSubmissionStatus));
    this.selectSubmissionUWRsTableRows$ = this.store.pipe(select(selectSubmissionUWRsTableRows));
    this.selectMoratoriumIsLoading$ = this.store.pipe(select(selectMoratoriumIsLoading));
    this.selectRaterIsLoading$ = this.store.pipe(select(selectRaterIsLoading));
    this.selectRaterCalculateDP3IsLoading$ = this.store.pipe(select(selectRaterCalculateDP3IsLoading));
    this.selectBackupIsLoading$ = this.store.pipe(select(selectBackupIsLoading));
    this.selectInterestsList$ = this.store.pipe(select(selectInterestsList));
    this.selectRelatedRiskstList$ = this.store.pipe(select(selectRelatedRiskstList));
    this.selectCalculateValuationIsLoading$ = this.store.pipe(select(selectCalculateValuationIsLoading));
    this.selectSubmissionIsPopulatingForms$ = this.store.pipe(select(selectSubmissionIsPopulatingForms));
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    this.removeRaterStorage(this.entityRiskData.getRiskDetailId());
  }

  ngOnInit(): void {
    this.initData();
    this.currentCategoryRoute = this.getCurrentCategoryRoute();
    this.subscribeToRouteChange();
    this.initZipCodeData();
    this.getSubmissionIdFromRoute();
    this.subscribeToQuoteStatus();
    this.subscribeToUwApprovalTableRowsSubject();
    this.subscribeToUwApprovalListComplete();
    this.subcribeToIsDonePopulatingForms();
    this.subscribeToQuoteSaving();
    this.subscibeToHasRenewalOfferChanges();
    this.subscribeToCommissionRateChecking();
    this.checkIfDoneSaving();
    this.subscribeToSelectSubmissionStatus$();
    this.subscribeToSelectRelatedRiskstList$();
    this.subscribeToIsCovAAndReplacementCostTheSame$();
    this.subscribeUWRPopulated$();
    this.populateUWR();
    this.subscriptToShowUWRestricted$();
    this.subscribeToMainStatus$();
  }

  initData(): void {
    this.policySummaryData.isPolicy = false;
    this.coveragesData.isFromPolicy = false;
    this.coveragesData.isFromCoverages = false;
    this.coveragesData.showFireErrorOnInit = true;
    this.summaryData.isFormTypeLoaded.next(false);
    this.initiateForms();
    this.submissionData.initSubmissionData();
    this.policySummaryData.renewalStatusCode = null;
    this.policySummaryData.renewalCode = null;
    this.uwApprovalData.uwrSavedData$.next(null);
    this.submissionData.hasRenewalOfferChanges.next(false);
    this.submissionData.initFormsComplete$.next(false);
    this.thirdPartyData.iso360YearBuilt = null;
    this.autoReinstateData.isApprovalRequired$.next(false);
    this.autoReinstateData.isRedirectedToPolicy = false;
    this.autoReinstateData.isPolicy = false;
    this.endorsementsData.resetBRNew110InfoStatus();
    this.zipCodeData.fetchMoratoriumComplete.next(false);
    this.entityRiskData.isDoneFetchMoratorium = false;
    this.uwApprovalData.uwApprovalListComplete$.next(false);
    this.resetStoreStates();
    this.savingUponNavigate();
    this.coveragesData.hurricaneDeductibleidChanged = false;
    this.applicantData.nameChanged = false;
    this.endorsementsData.isPermittedIncidentalOccupanciesChanged = false;
    this.endorsementsData.roofSurfacingPaymentScheduleChanged = false;
    this.propertyData.validYearBuilt = null;
  }

  resetStoreStates(): void {
    this.store.dispatch(updateSubmissionIsLoadingFromSubmissionManagementComponent({ isLoading: null }));
    this.store.dispatch(updateMoratoriumIsLoadingFromSubmissionManagementComponent({ isLoading: null }));
    this.store.dispatch(updateRaterIsLoadingFromSubmissionManagementComponent({ isLoading: null }));
    this.store.dispatch(updateInterestsListFromSubmissionManagementComponent({ list: null }));
    this.store.dispatch(updateBackupIsLoadingFormSubmissionManagementComponent({ isLoading: null }));
    this.store.dispatch(updateClaimsListFromSubmissionManagementComponent({ list: null }));
    this.store.dispatch(updateCalculateValuationDataFromThirdPartyData({ data: null }));
    this.store.dispatch(updateSubmissionIsPopulatingFormsFromSubmissionManagementComponent({ isPopulatingForms: null }));
    this.store.dispatch(updateCoveragesSchedulesFromSubmissionManagementComponent({ schedules: null }));
  }

  initZipCodeData(): void {
    this.zipCodeData.stateCode = JSON.parse(sessionStorage.getItem(environment.QuoteFormTypeAndState))?.state;

    if (this.zipCodeData.zipCodeList?.length === 0) {
      this.zipCodeData.getAllZipCodeByState(JSON.parse(sessionStorage.getItem(environment.QuoteFormTypeAndState))?.state);
    }
  }

  removeRaterStorage(riskDetailId: string) {
    this.storage.removeAll(riskDetailId);
  }

  checkisDateImplemented(): void {
    if (this.authService.userType.value === GenericConstants.userType.external) {
      if (
        this.submissionData.quoteStatus.value === GenericConstants.quoteStatus.ruw ||
        this.submissionData.quoteStatus.value === GenericConstants.quoteStatus.auw ||
        this.submissionData.quoteStatus.value === GenericConstants.quoteStatus.air ||
        this.submissionData.quoteStatus.value === GenericConstants.quoteStatus.duw
      ){
        this.summaryData.isDateImplemented.subscribe(res=> {
          if(res){
            if (this.router.url.includes('/uw-questions')) {
            this.showUWQuestionRestricted$.next(true);
          }  else {
            this.showUWQuestionRestricted$.next(false);
          }
          }
        });
      }else{
        this.showUWQuestionRestricted$.next(false);
        this.summaryData.isDateImplemented.next(false);
      }

    }
  }

  subscriptToShowUWRestricted$(): void {
    this.showUWQuestionRestricted$.pipe(distinctUntilChanged(),takeUntil(this.stop$)).subscribe(data => {
      if (data) {
        if(this.riskStatus !== 'Application'){
          setTimeout(() => {
            NotifUtils.showError('Restricted Access', () => {
              this.submissionData.disableQuoteFormsByUWQuestions.next(false);
              this.location.back();
            });
          }, 3000);
        }

      }
    });
  }

  checkSubmissionReferApprovedAIRDecline(): boolean {
    this.submissionData.disableQuoteFormsByUWQuestions.next(true);
    const dateImplement = moment(AgentDashboardConstants.defaultEffectiveDate);
    const effecttiveDateInfo = this.summaryData.SummaryForm? this.summaryData.SummaryForm.get('effectiveDate').value?.singleDate?.formatted :'';
    let dateCreated;
    if (effecttiveDateInfo) {
      dateCreated = this.summaryData.SummaryForm.get('effectiveDate').value?.singleDate?.formatted;
    } else {
      dateCreated = this.entityRiskData.EntityRisk?.risks[0]?.riskDetails[0]?.effectiveDate;
    }

    let isResult;
    const dDiff = dateImplement.diff(moment(dateCreated));
    if (dDiff <= 0) {
      this.summaryData.isDateImplemented.next(true);
      this.submissionData.disableQuoteFormsByUWQuestions.next(true);
      isResult = true;
    } else {
      this.summaryData.isDateImplemented.next(false);
      this.submissionData.disableQuoteFormsByUWQuestions.next(false);
      isResult = false;
    }
    this.checkisDateImplemented();
    return isResult;
  }

  subscribeToMainStatus$(): void {
    this.selectSubmissionStatus$.pipe().subscribe(status => {
      this.riskStatus = status;
    });
  }

  getSubmissionIdFromRoute(): void {
    this.route.params.pipe(takeUntil(this.stop$)).subscribe(params => {
      if (params.uuid !== undefined && params.uuid2 !== undefined) {
        this.currentRiskId = params.uuid;
        this.currentRiskDetailId = params.uuid2;
      } else {
        this.currentRiskDetailId = null;
        this.currentRiskId = null;
      }

      // Create side bar menu items.
      this.layoutService.updateMenu(this.createSubmissionDetailsMenuItems({
          riskId: this.currentRiskId,
          riskDetailId: this.currentRiskDetailId,
          storage: this.storage
        },
        this.checkIfHasClosure()
      ));
      localStorage.setItem('riskId', this.currentRiskId);
      localStorage.setItem('riskDetailId', this.currentRiskDetailId);
      localStorage.setItem('pageType', PathConstants.Submission.Index);
      if (this.currentRiskDetailId) {
        // Load submission by id.
        this.submissionData.hasRiskEntity = true;
        this.coveragesData.covAInfoMessage = false;
        this.coveragesData.covAInfoViewed = false;
        this.coveragesData.covCInfoMessage = false;
        this.coveragesData.covCInfoViewed = false;
        this.endorsementsSavingData.limitedWaterDmgLimitInfoMessage = false;
        this.endorsementsSavingData.limitedWaterDmgInfoViewed = false;
        this.zipCodeData.isDisableControlsDueToMoratorium = false;

        if (this.currentRiskId && this.currentRiskDetailId !== null) {
          Utils.blockUI();
          this.entityRiskData.isApiCallCompleted.next(false);
          this.store.dispatch(updateSubmissionIsLoadingFromSubmissionManagementComponent({ isLoading: true }));

          this.submissionService.getData(this.currentRiskId, this.currentRiskDetailId).pipe(takeUntil(this.stop$),
          tap(result => {
            Utils.blockUI();
            this.entityRiskData.EntityRisk = <EntityRiskDTO>result;
            this.entityRiskData.floodAssistStatusCode = this.entityRiskData.EntityRisk?.risks[0]?.riskDetails[0]?.riskFloods[0]?.floodAssistStatusCode;
            this.coveragesData.showPrivateFlood = !this.coveragesData.floodCoverageExcludedZipCode?.find(data => data === Number(this?.entityRiskData?.EntityRisk?.entityAddresses[0]?.address?.zipCode));
            this.policySummaryData.renewalStatusCode = this?.entityRiskData?.EntityRisk?.risks[0].renewalStatusCode as RenewalStatusCode;
            this.policySummaryData.renewalCode = this?.entityRiskData?.EntityRisk?.risks[0].renewalCode as RenewalStatusCode;
            this.claimsData.claimList = this.entityRiskData.EntityRisk.risks[0].riskDetails[0].riskClaims;
            this.submissionData.renewalStatus = this.entityRiskData?.EntityRisk?.risks[0]?.renewalCode as RenewalStatusCode;
          }),
          switchMap(() =>
            this.submissionService.getExpiringData(this.entityRiskData.EntityRisk?.risks[0]?.renewalFromRiskId)
          )).subscribe(result => {
            this.entityRiskData.expiringPolicyEntityRisk = <EntityRiskDTO>result;
            this.propertyData.distanceToActualCoastCodeISO = this.entityRiskData?.EntityRisk?.risks[0]?.riskDetails[0]?.riskProperties[0]?.distanceToActualCoastCode;

            try {
              this.authService.initAppSettings();
              this.initFormsByType();
              // Commented transferred logic to UW Approval
              // Fix for the restricted access due to UW Approval is the last thing was called during on load
              // this.renewalPendingRestriction();
            } catch (e) {
              console.error(e);
            }

            this.entityRiskData.isApiCallCompleted.next(true);
            this.entityRiskData.finishPopulatingForms();
            this.store.dispatch(updateSubmissionIsLoadingFromSubmissionManagementComponent({ isLoading: false }));
          }, error => {
            this.summaryData.isCalcPremium.next(false);
            this.entityRiskData.isApiCallCompleted.next(false);
            Utils.unblockUI();
            NotifUtils.showError(`${ErrorMessageConstant.errorOnRetrievingData}${this.currentRiskDetailId}`);
          }, () => {
            if (this.comparativeRaterData.isFromComparativeRater) {
              this.comparativeRaterData.event.pipe(takeUntil(this.stop$)).subscribe(onSuccessRetrieve => {
                if (onSuccessRetrieve) {
                  this.comparativeRaterData.populateCompRaterCounty(this.entityRiskData.EntityRisk);
                }
              });
            } else {
              this.propertyData.populateCounty(this.entityRiskData.EntityRisk, false);
            }
          this.validateCategories();
          this.entityRiskData.finishPopulatingForms();
          });
        }
      } else {
        this.initNewSubmission();
      }
    });
  }

  renewalPendingRestriction(): void {
    const isExternal = this.authService.userType.value === GenericConstants.userType.external;
    if (this.policySummaryData.renewalCode === 'RRNPEN' && isExternal) {
      NotifUtils.showError('Restricted Access', () => {
        this.router.navigate(['submissions/list']);
      });
    }
  }

  initFormsByType(): void {
    // Saving of FormType and State
    this.quoteService.savetoService(this?.entityRiskData?.EntityRisk?.risks[0]?.formType, this?.entityRiskData?.EntityRisk?.entityAddresses[0].address?.state);
    // Get Agency / Sub Agency Information
    let agencyId = this.applicantData.copiedInformation ? this.applicantData.copiedInformation.agencyId : this?.entityRiskData?.EntityRisk?.risks[0]?.agencyId;
    let subAgencyId = this.applicantData.copiedInformation ? this.applicantData.copiedInformation.subAgencyId : this?.entityRiskData?.EntityRisk?.risks[0]?.subAgencyId;

    if (!agencyId && !subAgencyId ) {
      agencyId = this.entityRiskData?.EntityRisk?.risks[0]?.agencyId;
      subAgencyId = this.entityRiskData?.EntityRisk?.risks[0]?.subAgencyId;
    }

    const parsedUserInfo = { agency_id: agencyId };
    this.authService.getAgenciesById(parsedUserInfo);
    this.authService.getAgencySubAgencies(agencyId, subAgencyId, this?.entityRiskData?.EntityRisk?.risks[0]?.riskDetails[0].id, this.submissionNavSavingService.effectiveDateQQ);

    if (Boolean(this.entityRiskData?.EntityRisk?.risks[0]?.comparativeId)) {
      this.comparativeRaterData.transactionId = this.entityRiskData?.EntityRisk?.risks[0]?.comparativeId;
      this.comparativeRaterData.isFromComparativeRater = Boolean(this.entityRiskData?.EntityRisk?.risks[0]?.comparativeId);
      this.comparativeRaterData.getComparativeRateFromSubmission(this.entityRiskData?.EntityRisk?.risks[0]?.comparativeId);
    }

    // Initiate Forms again due to update the FormType

    this.initiateForms();
    this.entityRiskData.startPopulatingForms();
    this.summaryData.isCalcPremium.next(true);
    this.populateForms(this.entityRiskData.EntityRisk);
    this.thirdPartyData.mapRiskPropertyToAPIResponse(this.entityRiskData.EntityRisk);
    this.coveragesData.validateHurricaneDeductible();
    this.coveragesData.setPrivateFloodChildValidator(this.coveragesData.floodForm.get('privateFloodCoverage').value);
    this.thirdPartyData.checkBRUL135to140();
    this.getYearBuiltFromISO360();
    this.checkQuoteFormsValidity();
    this.subscribeToFormChanges();
    this.checkIfFromRenewal();
    this.checkRenewalOfferChanges();
    this.subscribeToUwrSavedData();
    this.submissionData.hasUwApprovalChange.next(this.entityRiskData.EntityRisk.risks[0].riskDetails[0].hasUwApprovalChange);
  }

  checkQuoteFormsValidity(): void {
    const covaragesForms = ['coveragesAndDeductiblesForm', 'discountsForm', 'floodForm'];
    const endorsementsForms = ['endorsementsGroup'];
    const propertyForms = ['locationDetails', 'propertyDetails', 'windMitigation'];
    const applicantForms = ['mainApplicant', 'contact', 'coApplicant', 'fcraReportingActForm'];
    const uwQuestionsForms = ['eligibilityGroup', 'generalQuestionsGroup'];
    const interestForms = ['priorInsuranceForm'];
    const bindAndIssueForms = ['billingForm', 'paperlessDocumentForm', 'signatureForm'];

    this.subscribeToQuoteFormsValidity(covaragesForms, this.coveragesData);
    this.subscribeToQuoteFormsValidity(endorsementsForms, this.endorsementsData);
    this.subscribeToQuoteFormsValidity(propertyForms, this.propertyData);
    this.subscribeToQuoteFormsValidity(applicantForms, this.applicantData);
    this.subscribeToQuoteFormsValidity(uwQuestionsForms, this.uwQuestionsData);
    this.subscribeToQuoteFormsValidity(interestForms, this.interestData);
    this.subscribeToQuoteFormsValidity(bindAndIssueForms, this.bindAndIssueData, true);
  }

  subscribeToQuoteFormsValidity(formNames: string[], fromGroup: any, bindAndIssue: boolean = false): void {
    formNames.forEach(form => {
      fromGroup[form].statusChanges.pipe(takeUntil(this.stop$), distinctUntilChanged()).subscribe(result => {
        this.submissionNavValidationService.validationList.uwApproval = this.submissionData.allItemsChecked;
        this.validateCategories(false);
        if (!bindAndIssue) {
          this.updateUwApprovalAccessibility();
        }
        this.updateBindAndIssueAccessibility();
      });
    });
  }

  updateMenuItems(hasUwrChanges: boolean = false): any {
    const hasInvalid = localStorage.getItem('entityId') ? this.submissionNavValidationService.hasInvalidQuote() : false;
    const menuItems = this.createSubmissionDetailsMenuItems(this.getCompleteSubmissionDetailsMenuItems(hasUwrChanges), this.checkIfHasClosure());

    return {
      hasInvalid: hasInvalid,
      menuItems: menuItems
    };
  }

  updateUwApprovalAccessibility(): void {
    const { hasInvalid, menuItems } = this.updateMenuItems();

    menuItems.forEach(item => {
      if (item.name === 'UW APPROVAL') {
        item.attributes.disabled = hasInvalid;
      }
    });
  }

  subscribeToFormChanges(): void {
    this.uwApprovalData.uwApprovalList$.pipe(takeUntil(this.stop$), distinctUntilChanged()).subscribe((result) => {
      if (result) {
        this.updateBindAndIssueAccessibility(true);
      } else {
        this.updateBindAndIssueAccessibility();
      }
    });
  }

  updateBindAndIssueAccessibility(hasUwrChanges: boolean = false): void {
    const { menuItems } = this.updateMenuItems(hasUwrChanges);

    this.layoutService.updateMenu(menuItems);
  }

  initNewSubmission(): void {
    this.quickQuoteData.isRetired = false;
    this.entityRiskData.EntityRisk = null;
    this.submissionData.isOrder = false;
    this.submissionData.isSuccessfulOrder = false;
    this.bindAndIssueData.documentsList = [];
    this.bindAndIssueData.documentsTableRows = [];
    this.bindAndIssueData.notesList = [];
    this.bindAndIssueData.notesTableRows = [];
    this.claimsData.claimList = [];
    this.zipCodeData.qqCityList = [];
    this.zipCodeData.interestCityList = [];
    this.zipCodeData.applicantInsuredCityList = [];
    this.zipCodeData.billingCityList = [];
    this.zipCodeData.coApplicantCityList = [];
    this.uwQuestionsData.resetUWQuestionResponseId();
    this.zipCodeData.zipCodeList = [];
    this.coveragesData.covAInfoMessage = false;
    this.coveragesData.covAInfoViewed = false;
    this.coveragesData.covCInfoMessage = false;
    this.coveragesData.covCInfoViewed = false;
    this.coveragesData.showPrivateFlood = true;
    this.endorsementsSavingData.limitedWaterDmgLimitInfoMessage = false;
    this.endorsementsSavingData.limitedWaterDmgInfoViewed = false;
    this.propertyData.validYearRoof = null;
    this.quickQuoteData.orderPrefill = false;
    this.coveragesData.userSelectedCovCId = 50;
    this.endorsementsSavingData.isEndorsementSkippedSaving = true;
    this.interestSavingData.isInterestSkippedSaving = true;
    this.submissionNavValidationService.validationList = new ValidationListModel();
    this.zipCodeData.isDisableControlsDueToMoratorium = false;
    this.entityRiskData.isNewQuote = true;
    this.claimsData.isClaimListLoading = false;
    this.submissionListData.relatedSubmissionList = [];
    this.zipCodeData.isPrefillClicked = false;
    this.authService.commissionRateResponse = null;
  }

  subscribeToQuoteStatus(): void {
    this.submissionData.quoteStatus.pipe(takeUntil(this.stop$), distinctUntilChanged()).subscribe(result => {
      this.layoutService.updateMenu(
        this.createSubmissionDetailsMenuItems(this.getCompleteSubmissionDetailsMenuItems(), this.checkIfHasClosure())
      );
    });
  }

  ngOnDestroy(): void {
    this.entityRiskData.isDonePopulatingForms = false;
    this.coveragesData.resetInfoMessageDefaults();
    this.layoutService.clearMenu();
    this.removeRaterStorage(this.entityRiskData.getRiskDetailId());
    this.propertyData.resetStoredValues();
    this.claimsData.isClaimsForSaving = false;
    this.claimsData.isClaimListLoading = false;
    this.zipCodeData.fetchMoratoriumComplete.next(false);
    this.entityRiskData.isDoneFetchMoratorium = false;
    this.uwApprovalData.uwApprovalListComplete$.next(false);
    this.entityRiskData.isNewQuote = false;
  }

  isNew(): boolean {
    return this.currentRiskDetailId != null;
  }

  protected initiateForms(): void {
    this.coveragesData.initializeForms();
    this.bindAndIssueData.initBindAndIssueData();
    this.propertyData.initPropertyForms();
    this.quickQuoteData.initForms();
    this.applicantData.initForms();
    this.summaryData.initForms();
    this.endorsementsData.initForms();
    this.uwQuestionsData.initForms();
    this.interestData.initForms();
    this.claimsData.initForms();
    this.autoReinstateData.initForms();
    this.submissionData.initFormsComplete$.next(true);
  }

  protected populateForms(data: EntityRiskDTO): void {
    this.store.dispatch(updateSubmissionIsPopulatingFormsFromSubmissionManagementComponent({ isPopulatingForms: true }));
    this.summaryData.populateSummaryForms(data);
    this.bindAndIssueData.populateBindAndIssue(data);
    this.quickQuoteData.populateQuickQuotePage(data);
    this.coveragesData.populateCoveragesPage(data);
    this.endorsementsData.populateEndorsementsPage(data);
    this.endorsementsData.endorsementBusinessRules(false);
    this.propertyData.populatePropertyPage(data);
    this.applicantData.populateApplicantData(data);
    this.uwQuestionsData.populateUWQuestionPage(data);
    this.interestData.populateInterest(data);
    this.uwApprovalData.populateUWApprovalData(data);
    const effectiveDate = new Date(this.applicantData.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate);
    this.claimsData.populateClaimsData(data?.risks[0]?.riskDetails[0]?.riskClaims, effectiveDate, this.endorsementsData.endorsementsGroup);
    this.claimsData.riskClaims = [];
    this.claimsData.riskClaims = data?.risks[0]?.riskDetails[0]?.riskClaims;
    this.raterData.populateRaterRequestPayload(data,
      this.applicantData.mainApplicant,
      this.propertyData.locationDetails,
      this.propertyData.propertyDetails,
      this.summaryData.SummaryForm,
      this.coveragesData,
      this.endorsementsData,
      this.propertyData,
      this.propertyBusinessRules
    );
    this.summaryData.populateAgentDetails(data?.risks[0]?.agentId);
    this.autoReinstateData.populateAutoReinstateData();
    this.store.dispatch(updateSubmissionIsPopulatingFormsFromSubmissionManagementComponent({ isPopulatingForms: false }));
    this.propertyBusinessRules.triggerActiveNew125(false, true);
  }

  subscribeToRouteChange(): void {
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .pipe(takeUntil(this.stop$)).subscribe((event: NavigationEnd) => {
        this.saveUponNavigate();
        this.checkBindAndIssueAccess();
        this.convertQuoteToApplication(event.url);
      });
  }

  saveUponNavigate(): void {
    this.newCategoryRoute = this.getCurrentCategoryRoute();

        if (this.currentCategoryRoute
          && (this.newCategoryRoute !== this.currentCategoryRoute)
          && this.summaryData.SummaryForm.get('riskId')
        ) {
          this.submissionNavValidationService.validateCurrentCategory(this.currentCategoryRoute);
          this.submissionNavSavingService.saveCurrentCategory(this.currentCategoryRoute);
          this.validateCategories();
        }

        this.currentCategoryRoute = this.newCategoryRoute;
  }

  checkBindAndIssueAccess(): void {
    this.validateCategories();

    if (this.currentCategoryRoute === PathConstants.Submission.Submission.BindAndIssue && this.submissionNavValidationService.isBindAndIssueDisabled) {
      const currentURL: string = this.router.url;
      const currentURLParts: string[] = currentURL.split('/');

      currentURLParts[currentURLParts.length - 1] = PathConstants.Submission.Submission.UWApproval;
      const newURL = currentURLParts.join('/');

      this.router.navigate([newURL]);
    }
  }

  protected getCurrentCategoryRoute(): string {
    const splitUrl = _.split(this.router.url, '/');
    const quickQuoteRoute = _.indexOf(splitUrl, PathConstants.QuickQuote.Index);
    if (quickQuoteRoute !== -1) {
      return splitUrl[quickQuoteRoute];
    }
    const currentCategoryIndex = splitUrl.length - 1;
    return splitUrl[currentCategoryIndex];
  }

  validateCategories(updateMenu: boolean = true): void {
    this.validateCurrentCategories();
    this.markFormsAsTouoched();

    if (updateMenu) {
      this.layoutService.updateMenu(
        this.createSubmissionDetailsMenuItems(this.getCompleteSubmissionDetailsMenuItems(), this.checkIfHasClosure())
      );
    }
  }

  validateCurrentCategories(): void {
    this.submissionNavValidationService.validateCurrentCategory(PathConstants.Submission.Submission.CoveragesAndDeduction);
    this.submissionNavValidationService.validateCurrentCategory(PathConstants.Submission.Submission.Property);
    this.submissionNavValidationService.validateCurrentCategory(PathConstants.Applicant.Applicant.ApplicantPage);
    this.submissionNavValidationService.validateCurrentCategory(PathConstants.Submission.Submission.UWQuestions);
    this.submissionNavValidationService.validateCurrentCategory(PathConstants.Submission.Submission.Interests);
    this.submissionNavValidationService.validateCurrentCategory(PathConstants.Submission.Submission.UWApproval);
    this.submissionNavValidationService.validateCurrentCategory(PathConstants.Submission.Submission.BindAndIssue);
    this.submissionNavValidationService.validateCurrentCategory(PathConstants.Submission.Submission.Endorsements);
    this.submissionNavValidationService.validateCurrentCategory(PathConstants.QuickQuote.QuickQuote.Index);
  }

  markFormsAsTouoched(): void {
    this.propertyData.propertyDetails.markAllAsTouched();
    this.propertyData.locationDetails.markAllAsTouched();
    this.propertyData.windMitigation.markAllAsTouched();
    this.applicantData.mainApplicant.markAllAsTouched();
    this.applicantData.contact.markAllAsTouched();
    this.applicantData.coApplicant.markAllAsTouched();
    this.uwQuestionsData.generalQuestionsGroup.markAllAsTouched();
    this.interestData.priorInsuranceForm.markAllAsTouched();
    this.bindAndIssueData.billingForm.markAllAsTouched();
    this.bindAndIssueData.signatureForm.markAllAsTouched();
    this.endorsementsData.endorsementsGroup.markAllAsTouched();
  }

  subscribeToUwApprovalTableRowsSubject(): void {
    this.uwApprovalData.uwApprovalTableRows$.pipe(takeUntil(this.stop$)).subscribe(result => {
      if (!this.policySummaryData.isPolicy) {
        this.submissionData.hasAir = this.uwApprovalData.air;
        this.submissionData.hasDecline = this.uwApprovalData.decline;
        this.submissionData.nonPostBindUwrItemsChecked = this.uwApprovalData.nonPostBindUwrItemsChecked;
        this.submissionData.postBindUwrItemsApproved = this.uwApprovalData.postBindUwrItemsApproved;
        this.submissionData.nonPostBindUwrItemsApproved = this.uwApprovalData.nonPostBindUwrItemsApproved;
        this.submissionData.uwApprovalListAddedAllPostBind = this.uwApprovalData.uwApprovalListAddedAllPostBind;
        this.updateBindAndIssueAccessibility();
      }
    });
  }

  subscribeToUwApprovalListComplete(): void {
    this.uwApprovalData.uwApprovalListComplete$.pipe(takeUntil(this.stop$), distinctUntilChanged()).subscribe(result => {
      if (result && localStorage.getItem('entityId')) {
        this.submissionData.hasAir = this.uwApprovalData.air;
        this.submissionData.hasDecline = this.uwApprovalData.decline;
        this.submissionData.uwrListEmpty = this.uwApprovalData.uwrListEmpty;
        this.submissionData.hasPostBindUwrItem = this.uwApprovalData.hasPostBindUwrItem;
        this.submissionData.uwrListAllPostBind = this.uwApprovalData.uwrListAllPostBind;
        this.submissionData.uwrListAllNonPostBind = this.uwApprovalData.uwrListAllNonPostBind;
        this.submissionData.postBindUwrItemsApproved = this.uwApprovalData.postBindUwrItemsApproved;
        this.submissionData.nonPostBindUwrItemsChecked = this.uwApprovalData.nonPostBindUwrItemsChecked;
        this.submissionData.nonPostBindUwrItemsApproved = this.uwApprovalData.nonPostBindUwrItemsApproved;
        this.submissionData.uwApprovalListAddedAllPostBind = this.uwApprovalData.uwApprovalListAddedAllPostBind;
        this.validateCategories();
        this.uwApprovalData.showLoader = false;
      }
    });
  }

  getYearBuiltFromISO360(): void {
    this.thirdPartyData.getISO360YearBuilt();
  }

  subcribeToIsDonePopulatingForms(): void {
    if(!this.entityRiskData.isDonePopulatingForms) {
      this.entityRiskData.isDonePopulatingForms$.pipe(takeUntil(this.stop$)).subscribe(() => {
        this.coveragesData.subscribeToFloodValueChanges();
        this.coveragesData.setPrivateFloodChildValidator(this.coveragesData.floodForm.get('privateFloodCoverage').value);
      });

      return;
    }

    this.coveragesData.subscribeToFloodValueChanges();
    this.coveragesData.setPrivateFloodChildValidator(this.coveragesData.floodForm.get('privateFloodCoverage').value);
  }

  getCompleteSubmissionDetailsMenuItems(hasUwrChanges: boolean = false): INavDetails {
    return {
      riskId: this.currentRiskId,
      riskDetailId: this.currentRiskDetailId,
      storage: this.storage,
      validation: this.submissionNavValidationService.validationList,
      quoteStatus: this.submissionData.quoteStatus.value,
      hasAir: this.submissionData.hasAir,
      hasDecline: this.submissionData.hasDecline,
      uwrListEmpty: this.uwApprovalData.uwrListEmpty,
      allUwrApproved: this.uwApprovalData.isAllUWApprovalApproved,
      hasUwrChanges: hasUwrChanges,
      hasPostBindUwrItem: this.uwApprovalData.hasPostBindUwrItem,
      uwrListAllPostBind: this.uwApprovalData.uwrListAllPostBind,
      postBindUwrItemsApproved: this.uwApprovalData.postBindUwrItemsApproved,
      nonPostBindUwrItemsChecked: this.uwApprovalData.nonPostBindUwrItemsChecked,
      nonPostBindUwrItemsApproved: this.uwApprovalData.nonPostBindUwrItemsApproved,
      uwApprovalListAddedAllPostBind: this.uwApprovalData.uwApprovalListAddedAllPostBind,
      fcraAcknowledgement: this.applicantValidationService.fcraAcknowledgementSection,
      hasRenewalOfferChanges: this.submissionData.hasRenewalOfferChanges.value,
      hasCommissionRate: this.entityRiskData?.getAgencyHasCommissionRate(),
      isSubmissionReferApprovedAirDeclined: this.checkSubmissionReferApprovedAIRDecline()
    };
  }

  convertQuoteToApplication(url: string): void {
    const currentURL: string = url;
    const currentURLParts: string[] = currentURL.split('/');
    const currentURLLastSegment: string = currentURLParts[currentURLParts.length - 1];

    this.validateCategories();

    if (this.submissionData.quoteStatus.value !== this.genericConstants.quoteStatus.quo) {return;}
    if (
      currentURLLastSegment === PathConstants.QuickQuote.QuickQuote.ApplicantPage ||
      currentURLLastSegment === PathConstants.Submission.Submission.CoveragesAndDeduction ||
      currentURLLastSegment === PathConstants.Submission.Submission.Endorsements
    ) {return;}

    if (
      this.submissionNavValidationService.validationList.quickQuote &&
      this.submissionNavValidationService.validationList.coverages &&
      this.submissionNavValidationService.validationList.endorsements
    ) {
      this.submissionData.isValidForApplication = true;
      this.coveragesData.saveCoverages(false, false, false);
    }
  }

  checkIfFromRenewal(): void {
    const isRenewalNoTaken: boolean = this.entityRiskData.EntityRisk.risks[0].renewalCode === 'RWNT';
    const isFromRenewal: boolean = this.entityRiskData.EntityRisk.risks[0].isFromRenewal;
    const riskDetailId: string = this.entityRiskData.EntityRisk.risks[0].riskDetails[0].id;
    const renewalBackupRemarks: string = isRenewalNoTaken ? this.submissionConstants.renewalOffer :
      this.submissionConstants.renewalPending;

    if (isFromRenewal) {
      Utils.blockUI();
      this.store.dispatch(updateBackupIsLoadingFormSubmissionManagementComponent({ isLoading: true }));

      this.policyService.getBackUpData(riskDetailId, renewalBackupRemarks as PolicyBackupRemark).pipe(takeUntil(this.stop$)).subscribe(result => {
        this.entityRiskData.policyBackupRenewalPending = result;
        this.entityRiskData.renewalBackup = result;
        this.subscribeToUwApprovalSavingComplete();
        this.uwr.getUwrReferrals();
        this.submissionNavSavingService.updateUwApprovalTableRows();
        this.store.dispatch(updateBackupDataFormSubmissionManagementComponent({ data: result }));
        this.store.dispatch(updateBackupIsLoadingFormSubmissionManagementComponent({ isLoading: false }));
      });

      return;
    }

    this.uwr.getUwrReferrals(false);
  }

  subscribeToUwApprovalSavingComplete(): void {
    this.submissionData.uwApprovalSavingComplete.pipe(takeUntil(this.stop$)).subscribe(() => {
      this.uwApprovalData.updateUWApprovalData(this.uwApprovalData.uwrSavedData$.value?.riskUWApprovals);
      Utils.unblockUI();
    });
  }

  subscribeToQuoteSaving(): void {
    const savingPagesCompleteList: string[] = [
      'qqSavingComplete',
      'coveragesSavingComplete',
      'endorsementsSavingComplete',
      'claimsSavingComplete',
      'propertiesSavingComplete',
      'applicantSavingComplete',
      'uwQuestionsSavingComplete',
      'interestsSavingComplete',
      'uwApprovalSavingComplete',
    ];

    savingPagesCompleteList.forEach(savingPageComplete => {
      this.submissionData[savingPageComplete].subscribe(() => {
        this.checkRenewalOfferChanges();
      });
    });
  }

  checkRenewalOfferChanges(): void {
    if (!(this.policySummaryData.renewalStatusCode === 'RWO' || this.policySummaryData.renewalStatusCode === 'RRWO')) {
      return;
    }

    this.policyBackupRemark = 'Renewal Offer';
    if (this.uwApprovalData.renewalPolicyChangeSubscription) {
      this.uwApprovalData.renewalPolicyChangeSubscription.unsubscribe();
    }

    this.uwApprovalData.isPolicyChangesDone = false;
    this.store.dispatch(updateChangesIsLoadingFromSubmissionManagementComponent({ isLoading: true }));

    this.uwApprovalData.renewalPolicyChangeSubscription = this.policyService.getPolicyChanges(this.entityRiskData.getRiskId(), this.entityRiskData.getRiskDetailId(), this.policyBackupRemark)
      .pipe(takeUntil(this.stop$))
      .subscribe((policyChanges: Array<string>) => {
        this.submissionData.hasRenewalOfferChanges.next(policyChanges?.length !== 0);
        this.uwApprovalData.isPolicyChangesDone = true;
        this.store.dispatch(updateChangesIsLoadingFromSubmissionManagementComponent({ isLoading: false }));
        this.store.dispatch(updateChangesListFromSubmissionManagementComponent({ list: policyChanges }));
    });
  }

  subscibeToHasRenewalOfferChanges(): void {
    this.submissionData.hasRenewalOfferChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(result => {
      const submissionDetailsMenuItems = this.getCompleteSubmissionDetailsMenuItems();

      this.layoutService.updateMenu(
        this.createSubmissionDetailsMenuItems(submissionDetailsMenuItems, this.checkIfHasClosure())
      );
    });
  }

  subscribeToUwrSavedData(): void {
    this.uwApprovalData.uwrSavedData$.pipe(takeUntil(this.stop$)).subscribe(uwApprovals => {
      if (this.currentRiskId && this.currentRiskDetailId) {
        if (this.getAllSubmissionDocumentsSubscription) {
          this.getAllSubmissionDocumentsSubscription.unsubscribe();
        }

        this.getAllSubmissionDocumentsSubscription = this.submissionService.getAllSubmissionDocuments(this.currentRiskId).pipe(takeUntil(this.stop$)).subscribe(documents => {
          this.bindAndIssueData.populateDocumentSection(documents, uwApprovals?.riskUWApprovals);
        });
      }
    });
  }

  checkIfDoneSaving() {
    if (!this.quickQuoteService.checkIfAllCategoriesDoneSaving() || !this.entityRiskData.isDoneSavingForms) {
      setTimeout(() => { this.checkIfDoneSaving(); }, 100); /* this checks the flag every 100 milliseconds*/
    } else {
      if (!this.entityRiskData.isDonePopulatingForms) {
        this.entityRiskData.isDonePopulatingForms$.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(() => {
          const isNewQuote = this.entityRiskData.getRiskDetailId() ? false : true;
          this.quickQuoteData.callRecheckMoratorium(isNewQuote);
          this.entityRiskData.isNewQuote = false;
          this.quickQuoteData.getCommissionRate(false, true);
        });
      } else if (!this.entityRiskData.isDoneSavingForms) {
        this.entityRiskData.isDoneSavingForms$.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(() => {
          const isNewQuote = this.entityRiskData.getRiskDetailId() ? false : true;
          this.quickQuoteData.callRecheckMoratorium(isNewQuote);
          this.entityRiskData.isNewQuote = false;
        });
      }
    }
  }

  subscribeToSelectSubmissionStatus$(): void {
    this.selectSubmissionStatus$.pipe(takeUntil(this.stop$), pairwise()).subscribe(([prevValue, nextValue]: [any, any]) => {
      const validPrevStatusList: RiskStatus[] = [
        this.genericConstants.quoteStatus.ruw,
        this.genericConstants.quoteStatus.air,
        this.genericConstants.quoteStatus.duw,
        this.genericConstants.quoteStatus.auw,
      ];

      if (validPrevStatusList.includes(prevValue)) {
        this.submissionService.getRiskBindNotes(this.currentRiskId, this.currentRiskDetailId).pipe(takeUntil(this.stop$)).subscribe(result => {
          this.bindAndIssueData.populateBindnotes(result.riskBindNotes as RiskBindNoteDTO[]);
        });
      }
    });
  }

  checkIfHasClosure(): boolean {
    let hasClosure: boolean = false;
    const currentMoratorium = this.entityRiskData?.moratoriumResponse;
    if (!this.authService.isInternalUser && currentMoratorium) {
      currentMoratorium.forEach(moratorium => {
        if (moratorium.type === MoratoriumType.closure) {
          hasClosure = true;
        }
      });
    } else {
      hasClosure = false;
    }

    return hasClosure;
  }

  get yearBuilt(): number {
    return Number(this.propertyData?.propertyDetails?.get('yearBuilt')?.value);
  }

  subscribeToSelectRelatedRiskstList$(): void {
    this.selectRelatedRiskstList$.pipe(takeUntil(this.stop$)).subscribe(result => {
      this.coveragesData.relatedRisks = result;
      this.coveragesData.validateHurricaneDeductible(this.yearBuilt);
    });
  }

  subscribeUWRPopulated$(): void {
    this.uwr.uwrPopulated$.pipe(takeUntil(this.stop$)).subscribe(() => {
      this.selectSubmissionUWRsTableRows$.pipe(
        filter(value => {
          return value !== null;
        }),
        map(val => {
          return val?.map(item => {
            return {
              description: item.tr[0].value,
              status: item.tr[1].value,
            };
          });
        }),
        takeUntil(this.stop$),
        distinctUntilChanged(_.isEqual)
      ).subscribe(result => {
        if (!this.isRenewalNotTaken) {
          this.uwApprovalSavingData.saveUWApproval();
        }
      });
    });
  }

  populateUWR(): void {
    const stop$ = new Subject();

    const selectSubmissionIsLoading$ = this.selectSubmissionIsLoading$
      .pipe(
        filter((value) => {
          return value === false;
        }),
        takeUntil(this.stop$)
      );

    const selectMoratoriumIsLoading$ = this.selectMoratoriumIsLoading$
      .pipe(takeUntil(this.stop$));

    const selectInterestsList$ = this.selectInterestsList$
      .pipe(
        filter(data => {
          return data !== null;
        }),
        takeUntil(this.stop$)
      );

    const selectRaterIsLoading$ = this.selectRaterIsLoading$
      .pipe(takeUntil(this.stop$));

    const selectBackupIsLoading$ = this.selectBackupIsLoading$
      .pipe(takeUntil(this.stop$));

    const selectRaterCalculateDP3IsLoading$ = this.selectRaterCalculateDP3IsLoading$
      .pipe(takeUntil(this.stop$));

    const selectCalculateValuationIsLoading$ = this.selectCalculateValuationIsLoading$
      .pipe(takeUntil(this.stop$));

    const selectSubmissionIsPopulatingForms$ = this.selectSubmissionIsPopulatingForms$
      .pipe(
        filter((value) => {
          return value === false;
        }),
        takeUntil(this.stop$)
      );

    combineLatest([
      selectSubmissionIsLoading$,
      selectMoratoriumIsLoading$,
      selectInterestsList$,
      selectRaterIsLoading$,
      selectBackupIsLoading$,
      selectRaterCalculateDP3IsLoading$,
      selectCalculateValuationIsLoading$,
      selectSubmissionIsPopulatingForms$
    ]).pipe(takeUntil(stop$)).subscribe(result => {
      const [
        _selectSubmissionIsLoading,
        _selectMoratoriumIsLoading,
        _selectInterestsList,
        _selectRaterIsLoading,
        _selectBackupIsLoading,
        _selectRaterCalculateDP3IsLoading,
        _selectCalculateValuationIsLoading,
        _selectSubmissionIsPopulatingForms
      ] = result;

      const isHO3orDP3: boolean = this.entityRiskData.EntityRisk.risks[0].formType === this.formTypeConstants.HO3 ||
        this.entityRiskData.EntityRisk.risks[0].formType === this.formTypeConstants.DP3;

      if (isHO3orDP3) {
        if (_selectCalculateValuationIsLoading === false) {
          this.checkIfRenewalSubmission(result, stop$);
        }

        return;
      }

      this.checkIfRenewalSubmission(result, stop$);
    });
  }

  checkIfRenewalSubmission(result: any, stop$: Subject<any>): void {
    const [
      _selectSubmissionIsLoading,
      _selectMoratoriumIsLoading,
      _selectInterestsList,
      _selectRaterIsLoading,
      _selectBackupIsLoading,
      _selectRaterCalculateDP3IsLoading,
      _selectCalculateValuationIsLoading,
      _selectSubmissionIsPopulatingForms
    ] = result;

    const isFromRenewal: boolean = this.entityRiskData.EntityRisk.risks[0].isFromRenewal;
    const isDP3: boolean = this.entityRiskData.EntityRisk.risks[0].formType === this.formTypeConstants.DP3;

    if (!this.uwr.uwrHelpers.isValidForRenewalUWR && _selectMoratoriumIsLoading === null) {
      return;
    }

    if (isFromRenewal) {
      if (!_selectBackupIsLoading && !_selectCalculateValuationIsLoading && !_selectSubmissionIsPopulatingForms) {
        if (isDP3) {
          if (!_selectRaterCalculateDP3IsLoading) {
            this.completePopulateUWR(stop$);
          }
        } else {
          if (!_selectRaterIsLoading) {
            this.completePopulateUWR(stop$);
          }
        }
      }
    } else {
      if (isDP3 && !_selectCalculateValuationIsLoading && !_selectSubmissionIsPopulatingForms) {
        if (!_selectRaterCalculateDP3IsLoading) {
          this.completePopulateUWR(stop$);
        }
      } else {
        if (!_selectRaterIsLoading) {
          this.completePopulateUWR(stop$);
        }
      }
    }
  }

  completePopulateUWR(stop$: Subject<any>): void {
    this.uwApprovalData.populateUWApprovalData(this.entityRiskData.EntityRisk);

    if (!this.isRenewalNotTaken) {
      this.uwr.uwrPopulated$.next();
    }

    stop$.next();
    stop$.complete();
  }

  savingUponNavigate(): void {
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .pipe(takeUntil(this.stop$)).subscribe((event: NavigationEnd) => {
        this.uwApprovalData.triggerUWRSavingFromApplicant = this.currentCategoryRoute.toLocaleLowerCase() === 'applicant-page' ? true : false;
        this.uwApprovalData.triggerUWRSavingFromCoverages = this.currentCategoryRoute.toLocaleLowerCase() === 'coverages-and-deductions' ? true : false;
      });
  }

  get isRenewalNotTaken(): boolean {
    const renewalNotTaken: string = 'RWNT';

    return this.entityRiskData.EntityRisk.risks[0].renewalCode === renewalNotTaken;
  }

  createSubmissionDetailsMenuItems(navDetails: INavDetails , hasMoratoriumClosure: boolean = false): NavData[] {
    return createSubmissionDetailsMenuItems(navDetails, hasMoratoriumClosure);
  }

  subscribeToIsCovAAndReplacementCostTheSame$(): void {
    this.thirdPartyData.isCovAAndReplacementCostTheSame$.pipe(
      takeUntil(this.stop$)
    ).subscribe(() => {
      this.uwr.getUwrReferrals(false);
    });
  }

  subscribeToCommissionRateChecking(): void {
    this.authService.isDoneCommissionRateChecking$.pipe(takeUntil(this.stop$), distinctUntilChanged()).subscribe(result => {
      this.layoutService.updateMenu(
        this.createSubmissionDetailsMenuItems(this.getCompleteSubmissionDetailsMenuItems(), this.checkIfHasClosure())
      );
    });
  }
}
