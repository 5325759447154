export class CreditScoreRiskRequestDTO implements ICreditScoreRiskRequestDTO {
    firstName?: string | undefined;
    middleName?: string | undefined;
    lastName?: string | undefined;
    birthDate?: any;
    streetAddress1?: string | undefined;
    streetAddress2?: string | undefined;
    unitNumber?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    zipCode?: number | undefined;
    county?: string | undefined;
    countryCode?: string | undefined;
    isInternal?: boolean | undefined;
    subAgencyId?: string | undefined;
    riskId?: string | undefined;

    public constructor(init?: Partial<CreditScoreRiskRequestDTO>) {
      Object.assign(this, init);
    }
  }

  export interface ICreditScoreRiskRequestDTO {
    firstName?: string | undefined;
    middleName?: string | undefined;
    lastName?: string | undefined;
    birthDate?: any;
    streetAddress1?: string | undefined;
    streetAddress2?: string | undefined;
    unitNumber?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    zipCode?: number | undefined;
    county?: string | undefined;
    countryCode?: string | undefined;
    isInternal?: boolean | undefined;
    subAgencyId?: string | undefined;
    riskId?: string | undefined;
  }