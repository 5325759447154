export class ValidationListModel {
  constructor(
    public coverages?: boolean,
    public endorsements?: boolean,
    public claims?: boolean,
    public property?: boolean,
    public applicant?: boolean,
    public uwQuestions?: boolean,
    public interest?: boolean,
    public uwApproval?: boolean,
    public bindAndIssue?: boolean,
    public quickQuote?: boolean
  ) {
    this.coverages = coverages;
    this.endorsements = endorsements;
    this.claims = claims;
    this.property = property;
    this.applicant = applicant;
    this.uwQuestions = uwQuestions;
    this.interest = interest;
    this.uwApproval = uwApproval;
    this.bindAndIssue = bindAndIssue;
    this.quickQuote = quickQuote;
  }
}