import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { PolicyBillingData } from '../../../../../modules/policy-management/data/policy-billing.data';
import { PolicyBillingLabelsConstants } from '../../../../../shared/constants/policy-billing.labels.constants';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { PaymentMethod } from '../../../../../shared/enum/payment-method.enum';
import { PaymentProfileRequest } from '../../../../../../app/shared/models/data/dto/billing/payment-profile-request.dto';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../../../../app/shared/base-component';
import { FormGroup } from '@angular/forms';
import { AddressDTO3 } from '../../../../../../app/shared/models/data/dto/quick-quote/entity-address.dto';
import { ZipCodeData } from '../../../../../../app/modules/submission-management/data/zipcode.data';
import { PostPaymentCreditCardComponent } from '../../payments/post-payments/post-payment-credit-card/post-payment-credit-card.component';
import { PostPaymentEftComponent } from '../../payments/post-payments/post-payment-eft/post-payment-eft.component';
import { AddressType } from '../../../../../../app/shared/enum/address-type.enum';
import { EntityRiskData } from '../../../../../../app/modules/submission-management/data/entity-risk.data';
import { ApplicantData } from '../../../../../../app/modules/submission-management/data/applicant.data';

@Component({
  selector: 'app-add-payment-account-modal',
  templateUrl: './add-payment-account-modal.component.html',
  styleUrls: ['./add-payment-account-modal.component.scss']
})
export class AddPaymentAccountModalComponent extends BaseComponent implements OnInit {
  public RecurringPaymentConstants = PolicyBillingLabelsConstants.recurrentPaymentEnrollment;
  public ErrorMessageConstant = ErrorMessageConstant;
  public GenericLabel = GenericLabel;
  PaymentMethod = PaymentMethod;

  paymentProfileRequest: PaymentProfileRequest;

  @ViewChild('ccForm') ccFormRef: PostPaymentCreditCardComponent;
  @ViewChild('eftForm') eftFormRef: PostPaymentEftComponent;

  constructor(protected bsModalRef: BsModalRef,
    public billingData: PolicyBillingData,
    public zipCodeData: ZipCodeData,
    protected entityRiskData: EntityRiskData,
    protected applicantData: ApplicantData) { super(); }

  ngOnInit() {
    this.billingData.paymentMethodChangedEvent.pipe(takeUntil(this.stop$)).subscribe(() => {
      this.prefillPayeeInfo();
    });
  }

  get isSaveButtonEnabled(): boolean {
    return this.billingData.addPaymentAccountForm.valid && this.billingData.hasOneAgreeCheckbox;
  }

  get isAgreeInsuredChecked(): boolean {
    if (this.billingData.addPaymentAccountForm.get('paymentMethod').value !== PaymentMethod.CashCheck) {
      return this.billingData.addPaymentAccountForm.get('agreeInsured').value ?? false;
    } else {
      return true;
    }
  }

  onAddPaymentAccount(): void {
    const isNewPaymentProfile: boolean = this.billingData.paymentAccounts.length === 0;
    const subj: Subject<any> = isNewPaymentProfile ? this.billingData.paymentProfileNotifier : this.billingData.paymentAccountNotifier;
    subj.pipe(takeUntil(this.stop$)).subscribe(result => {
      if (result.isCompleted && !result.isError) {
        this.hideModal();
      }
    });

    if (isNewPaymentProfile) {
      this.paymentProfileRequest.paymentAccount = this.billingData.paymentAccountRequestMapper(true, false);
      this.paymentProfileRequest.firstName = this.paymentProfileRequest.paymentAccount.billingDetail.billingAddress.firstName;
      this.paymentProfileRequest.lastName = this.paymentProfileRequest.paymentAccount.billingDetail.billingAddress.lastName;
      this.billingData.addPaymentProfile(this.paymentProfileRequest);
    } else {
      this.billingData.addPaymentAccount();
    }
  }

  hideModal(): void {
    this.bsModalRef.hide();
    this.billingData.initPaymentAccountForm();
  }

  prefillPayeeInfo(): void {
    let address: AddressDTO3;
    if (this.entityRiskData.EntityRisk.isMailingAddressSameInsured) {
      address = this.entityRiskData?.EntityRisk?.entityAddresses.find(a => a.addressTypeCode === AddressType.Property)?.address;
    } else {
      address = this.entityRiskData?.EntityRisk?.entityAddresses.find(a => a.addressTypeCode === AddressType.Mailing)?.address;
    }

    const firstName: string = this.applicantData.mainApplicant.get('firstName').value;
    const lastName: string = this.applicantData.mainApplicant.get('lastName').value;
    const address1: string = address.streetAddress1;
    const zip: string = address.zipCode;
    const city: string = address.city;

    const paymentForm: FormGroup = this.billingData.getCurrentPaymentForm(this.billingData.addPaymentAccountForm);

    if (paymentForm && !paymentForm.touched) {
      paymentForm.get('firstName').setValue(firstName);
      paymentForm.get('lastName').setValue(lastName);
      paymentForm.get('address').setValue(address1);
      paymentForm.get('zip').setValue(zip);

      const emailField = paymentForm.get('email');
      if (emailField) {
        emailField.setValue(this.billingData.data.personalEmailAddress);
      }

      paymentForm.get('firstName').markAsTouched();

      this.zipCodeData.getCityListCompleted$.pipe(take(1)).subscribe(() => {
        paymentForm.get('city').setValue(city);
      });

      const isUSCountry = address?.countryCode === 'USA' || address?.countryCode === 'US';
      const paymentMethod = this.billingData.addPaymentAccountForm.get('paymentMethod').value;
      if (paymentMethod === PaymentMethod.RecurringCreditCard) {
        this.ccFormRef.setZipCode(isUSCountry);
      } else if (paymentMethod === PaymentMethod.RecurringECheck) {
        this.eftFormRef.setZipCode(isUSCountry);
      }
    }

    this.billingData.hasOneAgreeCheckbox = false;

    const paymentMethod = this.billingData.postPaymentForm.get('paymentMethod').value;
    if (paymentMethod === PaymentMethod.RecurringCreditCard || paymentMethod === PaymentMethod.RecurringECheck){
      this.billingData.postPaymentForm.controls.agreeEnrollAutoPay.setValue(false);
    }
  }
}
