import { Pipe, PipeTransform } from '@angular/core';
import { LvRiskStatus } from 'app/shared/constants/risk-status';

@Pipe({
  name: 'status'
})
export class SubmissionTablePipe implements PipeTransform{
  public LvRiskStatus = LvRiskStatus;

  transform(code: string) {
    const description = this.LvRiskStatus.find(item => item.code === code)?.description;

    return description;
  }
}