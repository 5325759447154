import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { getCurrentMonth } from 'app/shared/helpers/date-helper';
import { ReportsService } from 'app/core/services/reports/reports.service';
import { YearConstant } from 'app/shared/constants/year.constants';
import { getYearRange } from 'app/shared/helpers/year-range-helper';
import Utils from 'app/shared/utilities/utils';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { PolicyService } from 'app/core/services/submission/policy.service';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from 'app/shared/base-class';

@Component({
  selector: 'app-earned-premium',
  templateUrl: './earned-premium.component.html',
  styleUrls: ['./earned-premium.component.scss']
})
export class EarnedPremiumComponent extends BaseClass implements OnInit {

  earnedPremiumFormGroup: FormGroup = new FormGroup({
    yearForm: new FormControl('', [Validators.required]),
    monthForm: new FormControl('', [Validators.required]),
  });

  year: number = new Date().getFullYear();
  LvYear: number[] = getYearRange();
  month: any = YearConstant[0].key;
  LvMonth: any = YearConstant;
  
  constructor(private reportService: ReportsService,
    private policyService: PolicyService) { super(); }

  ngOnInit() {
  }

  //#region Component Function  Helpers
  initializations(){
    this.setFormValues();
    this.setYear();
   }
//#endregion


//#region Form Manipulations
setFormValues(){
  this.earnedPremiumFormGroup.controls['yearForm'].setValue(this.year);
}

setMonth() {
  this.month = this.earnedPremiumFormGroup.controls['monthForm'].value;
}

setYear() {
  this.year = this.earnedPremiumFormGroup.controls['yearForm'].value;
}

viewReport(){
  this.getMonthlyEarnedPremiumReport(this.month,this.year);
}

//#endregion

  //#region API Integrations
  getMonthlyEarnedPremiumReport(month,year){
    Utils.blockUI();
    this.reportService.getMonthlyEarnedPremiumReport(month,year).subscribe(result => {
      if(result == null || result == ""){
        NotifUtils.showError("Report is not available.");
       }else{
        this.policyService.generateSASURL(result.filePath).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
          window.open(resultSASURL, `A2_MonthlyEP_${month}_${year}`);
        });
       }
      Utils.unblockUI();
    },error => {
      Utils.unblockUI();
      NotifUtils.showError("Report is not available.");
    })
  }
  //#endregion
}
