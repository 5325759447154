import { ReportMap } from './quote-proposal-report.labels.constants';
import * as endorsementsOptions from './endorsements.options.constants';
import { FormGroupType } from '../enum/form-group-type.enum';

export const QPEndorsementsMap = [
  {
    alias: ReportMap.AnimalLiability.alias,
    formTypesVisible: ['HO3', 'HO4', 'HO6'],
    label: ReportMap.AnimalLiability.reportLabel,
    limitSwitchControlName: 'animalLiability',
    limitValueControlName: 'animalLiabilityLimit',
    riskEndorsementKey: 'isAnimalLiabilityEndr',
    riskEndorsementValueKey: 'animalLiabilityEndrLimit'
  },
  {
    alias: ReportMap.ConsentToRate.alias,
    formTypesVisible: ['HO3', 'HO4', 'HO6', 'DP3'],
    label: ReportMap.ConsentToRate.reportLabel,
    limitSwitchControlName: 'consentToRate',
    riskEndorsementKey: 'isConsentToRate'
  },
  {
    alias: ReportMap.IncSpecialLimit.alias,
    formTypesVisible: ['HO3', 'HO4', 'HO6'],
    label: ReportMap.IncSpecialLimit.reportLabel,
    limitSwitchControlName: 'coverageC',
    riskEndorsementKey: 'isCovCIncSpecialLimitOfLiability'
  },
  {
    alias: ReportMap.EquipBreakdown.alias,
    formTypesVisible: ['HO3', 'HO6'],
    label: ReportMap.EquipBreakdown.reportLabel,
    limitSwitchControlName: 'equipmentBreakdown',
    riskEndorsementKey: 'isEquipBreakdownEnEndr'
  },
  {
    alias: ReportMap.FloodCoverage.alias,
    formGroupType: FormGroupType.Flood,
    formTypesVisible: ['HO3', 'HO4', 'HO6'],
    label: ReportMap.FloodCoverage.reportLabel,
    limitSwitchControlName: 'privateFloodCoverage',
    riskEndorsementKey: 'isPrivateFloodCov'
  },
  {
    alias: ReportMap.GolfCart.alias,
    formTypesVisible: ['HO3', 'HO4', 'HO6'],
    label: ReportMap.GolfCart.reportLabel,
    limitSwitchControlName: 'golfCart',
    riskEndorsementKey: 'isGolfCartPhyDamLiaEndr'
  },
  {
    alias: ReportMap.HomeComputerCoverage.alias,
    formTypesVisible: ['HO3', 'HO4', 'HO6'],
    label: ReportMap.HomeComputerCoverage.reportLabel,
    limitSwitchControlName: 'homeComputer',
    limitValueControlName: 'homeComputerLimit',
    limitOptionsList: endorsementsOptions.LvRaterHomeComputerLimit,
    riskEndorsementKey: 'isHomeComputerCov',
    riskEndorsementValueKey: 'homeComputerCovLimitCode'
  },
  {
    alias: ReportMap.IdentityFraud.alias,
    formTypesVisible: ['HO3', 'HO4', 'HO6'],
    label: ReportMap.IdentityFraud.reportLabel,
    limitSwitchControlName: 'identityFraud',
    riskEndorsementKey: 'isIdentityFraudExCov'
  },
  {
    alias: ReportMap.PersPropOthResidences.alias,
    formTypesVisible: ['HO4'],
    label: ReportMap.PersPropOthResidences.reportLabel,
    limitSwitchControlName: 'personalPropertyAtOtherResidences',
    limitValueControlName: 'personalPropertyAtOtherResidencesLimit',
    riskEndorsementKey: 'isPersonalPropertyAtOtherResidences',
    riskEndorsementValueKey: 'personalPropertyAtOtherResidencesLimit'
  },
  {
    alias: ReportMap.RepCostDwelling.alias,
    formTypesVisible: ['HO3'],
    label: ReportMap.RepCostDwelling.reportLabel,
    limitSwitchControlName: 'increasedReplacement',
    riskEndorsementKey: 'isIncreaseRepCostDwelling'
  },
  {
    alias: ReportMap.LimitedFungi.alias,
    formTypesVisible: ['HO3', 'HO4', 'HO6'],
    label: ReportMap.LimitedFungi.reportLabel,
    limitSwitchControlName: 'limitedFungi',
    limitValueControlName: 'limitedFungiLimit',
    limitOptionsList: endorsementsOptions.LvRaterLimitedFungiLimit,
    riskEndorsementKey: 'isLimitedFungiWet',
    riskEndorsementValueKey: 'limitedFungiWetLimitCode'
  },
  {
    alias: ReportMap.LimitedWaterDamage.alias,
    formTypesVisible: ['HO3', 'HO4', 'HO6', 'DP3'],
    label: ReportMap.LimitedWaterDamage.reportLabel,
    limitSwitchControlName: 'limitedWater',
    limitValueControlName: 'limitedWaterLimit',
    riskEndorsementKey: 'isLimitedWaterDamage',
    riskEndorsementValueKey: 'limitedWaterDamageLimit'
  },
  {
    alias: ReportMap.LossAssessment.alias,
    formTypesVisible: ['HO3', 'HO6'],
    label: ReportMap.LossAssessment.reportLabel,
    limitSwitchControlName: 'lossAssessment',
    limitValueControlName: 'lossAssessmentLimit',
    limitOptionsList: endorsementsOptions.LvRaterLossAssessmentLimit,
    riskEndorsementKey: 'isLossAssessCov',
    riskEndorsementValueKey: 'lossAssessCovCode'
  },
  {
    alias: ReportMap.OrdinanceLawCoverage25.alias,
    formTypesVisible: ['HO3', 'HO6'],
    label: ReportMap.OrdinanceLawCoverage25.reportLabel,
    limitSwitchControlName: 'ordinanceLawOrCov',
    flagMatchSource: 'ordinanceLaw',
    flagMatchValue: '25',
    riskEndorsementKey: 'isOrdinanceLaw'
  },
  {
    alias: ReportMap.OrdinanceLawCoverage50.alias,
    formTypesVisible: ['HO3', 'HO6'],
    label: ReportMap.OrdinanceLawCoverage50.reportLabel,
    limitSwitchControlName: 'ordinanceLawOrCov',
    flagMatchSource: 'ordinanceLaw',
    flagMatchValue: '50',
    riskEndorsementKey: 'isOrdinanceLaw'
  },
  {
    alias: ReportMap.PersonalInjury.alias,
    formTypesVisible: ['HO3', 'HO4', 'HO6'],
    label: ReportMap.PersonalInjury.reportLabel,
    limitSwitchControlName: 'personalInjury',
    riskEndorsementKey: 'isPersonalInjury'
  },
  {
    alias: ReportMap.PersPropReplacement.alias,
    formTypesVisible: ['HO3', 'HO4', 'HO6'],
    label: ReportMap.PersPropReplacement.reportLabel,
    limitSwitchControlName: 'personalProperty',
    riskEndorsementKey: 'isPersonalProRepCost'
  },
  {
    alias: ReportMap.PersPropScheduled.alias,
    formGroupType: FormGroupType.Schedules,
    formTypesVisible: ['HO3', 'HO4', 'HO6'],
    label: ReportMap.PersPropScheduled.reportLabel,
    limitSwitchControlName: 'addSchedules'
  },
  {
    alias: ReportMap.PremisesAlarm.alias,
    formTypesVisible: ['HO3', 'HO4', 'HO6', 'DP3'],
    label: ReportMap.PremisesAlarm.reportLabel,
    limitSwitchControlName: 'premisesAlarm',
    riskEndorsementKey: 'isPremisesAlarmFireProcSys'
  },
  {
    alias: ReportMap.PremiumAdjustment.alias,
    formTypesVisible: ['HO3', 'HO4', 'HO6', 'DP3'],
    label: ReportMap.PremiumAdjustment.reportLabel,
    limitSwitchControlName: 'premiumAdjustment',
    riskEndorsementKey: 'isPremiumAdjustment'
  },
  {
    alias: ReportMap.SeasonalDwelling.alias,
    formTypesVisible: ['HO3', 'HO4', 'HO6'],
    label: ReportMap.SeasonalDwelling.reportLabel,
    limitSwitchControlName: 'seasonalDwelling',
    riskEndorsementKey: 'isSeasonalDwellingEndr'
  },
  {
    alias: ReportMap.SECarportsCoverageACV.alias,
    formTypesVisible: ['HO3'],
    label: ReportMap.SECarportsCoverageACV.reportLabel,
    limitValueControlName: 'screenedEnclosureLimit',
    flagMatchSource: 'screenedEnclosure',
    flagMatchValue: 'ACV',
    riskEndorsementKey: 'screenedEnclosure',
    riskEndorsementValueKey: 'screenedEnclosureLimit'
  },
  {
    alias: ReportMap.SECarportsCoverageRC.alias,
    formTypesVisible: ['HO3'],
    label: ReportMap.SECarportsCoverageRC.reportLabel,
    limitValueControlName: 'screenedEnclosureLimit',
    flagMatchSource: 'screenedEnclosure',
    flagMatchValue: 'RC',
    riskEndorsementKey: 'screenedEnclosure',
    riskEndorsementValueKey: 'screenedEnclosureLimit'
  },
  {
    alias: ReportMap.ServiceLineEndorsement.alias,
    formTypesVisible: ['HO3'],
    label: ReportMap.ServiceLineEndorsement.reportLabel,
    limitSwitchControlName: 'serviceLine',
    limitValueControlName: 'serviceLineLimit',
    riskEndorsementKey: 'isServiceLineEnEndr',
    riskEndorsementValueKey: 'serviceLineEnEndrLimit'
  },
  {
    alias: ReportMap.SinkholeLoss.alias,
    formTypesVisible: ['HO3'],
    label: ReportMap.SinkholeLoss.reportLabel,
    limitSwitchControlName: 'sinkholeLoss',
    riskEndorsementKey: 'isSinkHoleLossCov'
  },
  {
    alias: ReportMap.SpecialPersProp.alias,
    formTypesVisible: ['HO3', 'HO4', 'HO6'],
    label: ReportMap.SpecialPersProp.reportLabel,
    limitSwitchControlName: 'specialPersonal',
    riskEndorsementKey: 'isSpecialPersonalProCov'
  },
  {
    alias: ReportMap.StarAdvPackage.alias,
    formTypesVisible: ['HO3'],
    label: ReportMap.StarAdvPackage.reportLabel,
    limitSwitchControlName: 'starAdvantagePackage',
    riskEndorsementKey: 'isStarAdvantagePackage'
  },
  {
    alias: ReportMap.StarPackage.alias,
    formTypesVisible: ['HO3'],
    label: ReportMap.StarPackage.reportLabel,
    limitSwitchControlName: 'starPackage',
    riskEndorsementKey: 'isStarPackage'
  },
  {
    alias: ReportMap.UnitOwnersRental.alias,
    formTypesVisible: ['HO6'],
    label: ReportMap.UnitOwnersRental.reportLabel,
    limitSwitchControlName: 'unitOwnersRentalToOthers',
    riskEndorsementKey: 'isUnitOwnersRentalToOthers'
  },
  {
    alias: ReportMap.WaterBackUp.alias,
    formTypesVisible: ['HO3', 'HO4', 'HO6'],
    label: ReportMap.WaterBackUp.reportLabel,
    limitSwitchControlName: 'waterBackup',
    limitValueControlName: 'waterBackupLimit',
    riskEndorsementKey: 'isWaterBackUpSump',
    riskEndorsementValueKey: 'waterBackUpSumpLimit'
  },
  {
    alias: ReportMap.WaterDamageExc.alias,
    formTypesVisible: ['HO3', 'HO4', 'HO6', 'DP3'],
    label: ReportMap.WaterDamageExc.reportLabel,
    limitSwitchControlName: 'waterDamage',
    riskEndorsementKey: 'isWaterDamageExclusion'
  },
  {
    alias: ReportMap.LimitedFungiDP3.alias,
    formTypesVisible: ['DP3'],
    label: ReportMap.LimitedFungiDP3.reportLabel,
    limitSwitchControlName: 'fungiWetOrDryRot',
    limitValueControlName: 'fungiWetOrDryRotLimit',
    limitOptionsList: endorsementsOptions.LvRaterLimitedFungiLimit,
    riskEndorsementKey: 'isLimitedFungiWetIncreasedAmount',
    riskEndorsementValueKey: 'limitedFungiWetIncreasedAmountLimitCode'
  },
  {
    alias: ReportMap.EquipBreakdownDP3.alias,
    formTypesVisible: ['DP3'],
    label: ReportMap.EquipBreakdownDP3.reportLabel,
    limitSwitchControlName: 'equipmentBreakdown',
    riskEndorsementKey: 'isEquipBreakdownEnEndr'
  },
  {
    alias: ReportMap.SECarportsCoverageACVDP3.alias,
    formTypesVisible: ['DP3'],
    label: ReportMap.SECarportsCoverageACVDP3.reportLabel,
    limitValueControlName: 'screenedEnclosureLimit',
    flagMatchSource: 'screenedEnclosure',
    flagMatchValue: 'ACV',
    riskEndorsementKey: 'screenedEnclosure',
    riskEndorsementValueKey: 'screenedEnclosureLimit'
  },
  {
    alias: ReportMap.SECarportsCoverageRCDP3.alias,
    formTypesVisible: ['DP3'],
    label: ReportMap.SECarportsCoverageRCDP3.reportLabel,
    limitValueControlName: 'screenedEnclosureLimit',
    flagMatchSource: 'screenedEnclosure',
    flagMatchValue: 'RC',
    riskEndorsementKey: 'screenedEnclosure',
    riskEndorsementValueKey: 'screenedEnclosureLimit'
  },
  {
    alias: ReportMap.LossAssessmentDP3.alias,
    formTypesVisible: ['DP3'],
    label: ReportMap.LossAssessmentDP3.reportLabel,
    limitSwitchControlName: 'lossAssessment',
    limitValueControlName: 'lossAssessmentLimit',
    limitOptionsList: endorsementsOptions.LvRaterLossAssessmentLimit,
    riskEndorsementKey: 'isLossAssessCov',
    riskEndorsementValueKey: 'lossAssessCovCode'
  },
  {
    alias: ReportMap.SinkholeLossDP3.alias,
    formTypesVisible: ['DP3'],
    label: ReportMap.SinkholeLossDP3.reportLabel,
    limitSwitchControlName: 'sinkholeLoss',
    riskEndorsementKey: 'isSinkHoleLossCov'
  },
  {
    alias: ReportMap.OrdinanceLawCoverage25DP3.alias,
    formTypesVisible: ['DP3'],
    label: ReportMap.OrdinanceLawCoverage25DP3.reportLabel,
    limitSwitchControlName: 'ordinanceLawOrCov',
    flagMatchSource: 'ordinanceLaw',
    flagMatchValue: '25',
    riskEndorsementKey: 'isOrdinanceLaw'
  },
  {
    alias: ReportMap.PersPropReplacementDP3.alias,
    formTypesVisible: ['DP3'],
    label: ReportMap.PersPropReplacementDP3.reportLabel,
    limitSwitchControlName: 'personalProperty',
    riskEndorsementKey: 'isPersonalProRepCost'
  },
  {
    alias: ReportMap.PersonalLiabilityDP3.alias,
    formTypesVisible: ['DP3'],
    label: ReportMap.PersonalLiabilityDP3.reportLabel,
    limitSwitchControlName: 'personalLiability',
    riskEndorsementKey: 'isPersonalLiability'
  },
  {
    alias: ReportMap.PremisesLiabilityDP3.alias,
    formTypesVisible: ['DP3'],
    label: ReportMap.PremisesLiabilityDP3.reportLabel,
    limitSwitchControlName: 'premisesLiability',
    riskEndorsementKey: 'isPremisesLiability'
  },
  {
    alias: ReportMap.PermittedIncidentalLiabDP3.alias,
    formTypesVisible: ['DP3'],
    label: ReportMap.PermittedIncidentalLiabDP3.reportLabel,
    limitSwitchControlName: 'permittedIncidentalOccupanciesLiability',
    riskEndorsementKey: 'isPermittedIncidentalOccupanciesLiability'
  },
  {
    alias: ReportMap.PermittedIncidentalDP3.alias,
    formTypesVisible: ['DP3'],
    label: ReportMap.PermittedIncidentalDP3.reportLabel,
    limitSwitchControlName: 'permittedIncidentalOccupancies',
    riskEndorsementKey: 'isPermittedIncidentalOccupancies'
  },
  {
    alias: ReportMap.ServiceLineEndorsementDP3.alias,
    formTypesVisible: ['DP3'],
    label: ReportMap.ServiceLineEndorsementDP3.reportLabel,
    limitSwitchControlName: 'serviceLine',
    limitValueControlName: 'serviceLineLimit',
    riskEndorsementKey: 'isServiceLineEnEndr',
    riskEndorsementValueKey: 'serviceLineEnEndrLimit'
  },
  {
    alias: ReportMap.LimitedTheftDP3.alias,
    formTypesVisible: ['DP3'],
    label: ReportMap.LimitedTheftDP3.reportLabel,
    limitSwitchControlName: 'limitedTheftCoverage',
    riskEndorsementKey: 'isLimitedTheftCoverage'
  },
  {
    alias: ReportMap.WaterBackupDP3.alias,
    formTypesVisible: ['DP3'],
    label: ReportMap.WaterBackupDP3.reportLabel,
    limitSwitchControlName: 'waterBackup',
    limitValueControlName: 'waterBackupLimit',
    riskEndorsementKey: 'isWaterBackUpSump',
    riskEndorsementValueKey: 'waterBackUpSumpLimit'
  },
  {
    alias: ReportMap.VacancyDP3.alias,
    formTypesVisible: ['DP3'],
    label: ReportMap.VacancyDP3.reportLabel,
    limitSwitchControlName: 'vacancyEndorsement',
    riskEndorsementKey: 'isVacancyEndorsement'
  },
  {
    alias: ReportMap.WindstormExtPaintDP3.alias,
    formTypesVisible: ['DP3'],
    label: ReportMap.WindstormExtPaintDP3.reportLabel,
    limitSwitchControlName: 'windstormExteriorPaintoOrWaterproofingExclusionSeacoastFlorida',
    riskEndorsementKey: 'isWindstormExteriorPaint'
  }
];

export interface IEndorsementMap {
  alias: string;
  /**
   * The FormGroup where to get the control values from
   */
  formGroupType?: FormGroupType;
  formTypesVisible: string[];
  label: string;
  /**
   * Control's name to extract toggle value (boolean), supply only when applicable
   */
  limitOptionsList?: any;
  limitSwitchControlName?: string;
  /**
   * Control's name to extract the limit value, supply only when applicable
   */
  limitValueControlName?: string;
  /**
   * The Control's name where to extract the flag value from
   */
  flagMatchSource?: string;
  /**
   * The flag value to match
   */
  flagMatchValue?: string;
  /**
   * RiskEndorsementDTO2 key
   */
  riskEndorsementKey?: string;
  riskEndorsementValueKey?: string;
}