import { Injectable } from '@angular/core';
import { FileUploadConstants } from '../constants/file-upload.constants';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  fileUploadConstants = FileUploadConstants;

  get acceptedCSVExcelFileTypes(): string {
    let acceptedFileTypes: string = '';

    this.fileUploadConstants.acceptedCSVExcelFileType.forEach(item => {
      acceptedFileTypes += `.${item}, `;
    });

    return acceptedFileTypes;
  }

  isUploadedCSVExcelFileTypeInvalid(file): boolean {
    if (file === this.fileUploadConstants.noFileChosen) {
      return false;
    }

    const extension = file?.split('.')?.pop();
    return !this.fileUploadConstants.acceptedCSVExcelFileType.includes(extension.toLowerCase());
  }
}