export const SubmissionConstants = {
  foundationTypeAllForms: 'CS',
  numberOfStoriesAllForms: '1',
  propertyUsageHO3DP3: 'OOP',
  structureTypeHO3DP3: '1',
  propertyUsageHO4: 'TO',
  structureTypeHO4: 'RE',
  propertyUsageHO6: 'TO',
  structureTypeHO6: '1',
  renewalPending: 'Renewal Pending',
  renewalOffer: 'Renewal Offer',
  renewalOfferChangesTooltip: 'Changes have been made to Renewal Offer. Click Revise Renewal Offer button to confirm.',
  terrainB: 'B',
  windSpeed100: 'WS0',
  windDesignSpeed100:'100',
  noFormConverted: 'NFC',
  oirMitigationType: 'OR',
  noForm: 'NF',
  noCommissionRateTooltip: 'No Commission Rate setup found for this Quote. Please contact agency relations.',
  noCommissionRateAlertMessage: 'No Commission Rate setup found for this Quote. Please contact agency relations.',
};