export const TableNameConstants = {
  uwApprovalTable: 'uw-approval-table',
  scheduleTable: 'schedule-table',
  interestTable: 'interest-table',
  bindAndIssueDocumentTable: 'bind-issue-document-table',
  bindAndIssueNotesTable: 'bind-issue-notes-table',
  claimsTable: 'claims-table',
  policyInterestTable: 'policy-interest-table',
  policyDocumentsTable: 'policy-documents-table',
  policyNotesTable: 'policy-notes-table',
  agencyNotesTable: 'agency-notes-table',
  userTypeTable: 'user-type-table',
  groupTable: 'group-table',
  uwRenewalReferral: 'uw-renewal-referral-table',
  uwApprovalFileModalTable: 'uw-approval-file-modal-table',
  suppressDocumentTable: 'suppress-document-table'
};
