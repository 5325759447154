import { createSelector } from '@ngrx/store';
import { BaseState } from '../base.state';

export const selectRaterState = (state: BaseState) => state.rater;

export const selectRaterIsLoading = createSelector(
  selectRaterState,
  (state) => state.isLoading
);

export const selectRaterList = createSelector(
  selectRaterState,
  (state) => state.list
);

export const selectProratedList = createSelector(
  selectRaterState,
  (state) => state.proratedList
);