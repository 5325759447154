export const quoteStatus = {
  quoted: 'Quoted',
  application: 'Application',
  ruw: 'Referred to UW',
  auw: 'Approved by UW',
  duw: 'Declined by UW',
  air: 'Additional Information Required',
  expired: 'Quote Expired',
  can: 'Cancelled'
};
