import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ApplicantValidatorConstants } from './validator-error-constants/applicant-validators.constants';
import * as _ from 'lodash';

export class ApplicantValidators {
  static nameMustBeIndividualsAndNotCorporationsValidator(control: AbstractControl): ValidatorFn {
    if (control && control.value) {
      const key: any = {};
      const splitName = _.split(control.value, ' ');
      const splitNameUpperCase = splitName?.map(name => name.toUpperCase());
      const hasCoAbbreviation = _.indexOf(splitNameUpperCase, ApplicantValidatorConstants.corporationNames.coAbbreviation);
      const hasCorpAbbreviation = _.indexOf(splitNameUpperCase, ApplicantValidatorConstants.corporationNames.corpAbbreviation);
      const hasLlcAbbreviation = _.indexOf(splitNameUpperCase, ApplicantValidatorConstants.corporationNames.llcAbbreviation);
      const hasCompAbbreviation = _.indexOf(splitNameUpperCase, ApplicantValidatorConstants.corporationNames.compAbbreviation);
      const hasIncAbbreviation = _.indexOf(splitNameUpperCase, ApplicantValidatorConstants.corporationNames.incAbbreviation);
      const hasCorporationInName = _.indexOf(splitNameUpperCase, ApplicantValidatorConstants.corporationNames.corporation);
      const hasCompanyInName = _.indexOf(splitNameUpperCase, ApplicantValidatorConstants.corporationNames.company);
      const hasTrustInName = _.indexOf(splitNameUpperCase, ApplicantValidatorConstants.corporationNames.trust);

      if (hasCoAbbreviation !== -1
        || hasCorpAbbreviation !== -1
        || hasLlcAbbreviation !== -1
        || hasCompAbbreviation !== -1
        || hasIncAbbreviation !== -1
        || hasCorporationInName !== -1
        || hasCompanyInName !== -1
        || hasTrustInName !== -1) {
        key[ApplicantValidatorConstants.nameMustBeIndividualsAndNotCorporationsErrorMessage.key] = true;
        return key;
      }
    }
    return null;
  }
}