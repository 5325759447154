import { Action, createReducer, on } from '@ngrx/store';
import * as fromCoveragesActions from '../../store/coverages/coverages.actions';
import { initialState, CoveragesState } from './coverages.state';

const _coveragesReducer = createReducer(
  initialState,
  on(
    fromCoveragesActions.updateCoveragesIsLoadingFromCoveragesData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromCoveragesActions.updateCoveragesSchedulesFromCoveragesData,
    (state, { schedules }) => ({ ...state, schedules: schedules })
  ),
  on(
    fromCoveragesActions.updateCoveragesSchedulesFromSubmissionManagementComponent,
    (state, { schedules }) => ({ ...state, schedules: schedules })
  ),
  on(
    fromCoveragesActions.updateCoveragesSchedulesFromPolicyManagementComponent,
    (state, { schedules }) => ({ ...state, schedules: schedules })
  )
);

export const coveragesReducer = (
  state: CoveragesState | null,
  action: Action
) => {
  return _coveragesReducer(state, action);
};