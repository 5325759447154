import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PendingComponent } from './pending.component';
import { RouterModule, Routes } from '@angular/router';
import { PendingSuspendedComponent } from './pending-suspended/pending-suspended.component';
import { SharedModule } from '../../../../shared/shared.module';
import { AddSuspendedPaymentModalComponent } from './pending-suspended-modal/add-suspended-payment-modal/add-suspended-payment-modal.component';
import { DetailsSuspendedPaymentModalComponent } from './pending-suspended-modal/details-suspended-payment-modal/details-suspended-payment-modal.component';
import { PostSuspendedComponent } from './pending-suspended-modal/post-suspended-payment-modal/post-suspended.component';
import { SearchResultModalComponent } from './pending-suspended-modal/post-suspended-payment-modal/search-result-modal/search-result-modal.component';
import { ReturnModalComponent } from './pending-suspended-modal/return-suspended-payment-modal/return-suspended-payment-modal.component';
import { VoidModalComponent } from './pending-suspended-modal/void-suspended-payment-modal/void-suspended-payment-modal.component';
import { CustomPipesModule } from '../../../../shared/custom pipes/custom-pipe.module';
import { SuspendedPaymentRelatedDocComponent } from '../shared/suspended-payment-related-doc/suspended-payment-related-doc.component';
import { UploadIpxModalComponent } from './pending-suspended-modal/upload-ipx-modal/upload-ipx-modal.component';
import { GenerateIpxSuspenseReportModalComponent } from './pending-suspended-modal/generate-ipx-suspense-report-modal/generate-ipx-suspense-report-modal.component';
import { TooltipModule } from 'ngx-bootstrap';
import { AddSplitPaymentModalComponent } from './pending-suspended-modal/add-split-payment-modal/add-split-payment-modal.component';
import { DetailsSuspendedSplitPaymentTableModalComponent } from './details-suspended-split-payment-table-modal.component.html/details-suspended-split-payment-table-modal.component';



const routes: Routes = [
  { path: '', component: PendingComponent }
];

@NgModule({
  declarations: [
    PendingComponent,
    PendingSuspendedComponent,
    AddSuspendedPaymentModalComponent,
    PostSuspendedComponent,
    SearchResultModalComponent,
    ReturnModalComponent,
    VoidModalComponent,
    SuspendedPaymentRelatedDocComponent,
    DetailsSuspendedPaymentModalComponent,
    UploadIpxModalComponent,
    GenerateIpxSuspenseReportModalComponent,
    AddSplitPaymentModalComponent,
    DetailsSuspendedSplitPaymentTableModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    CustomPipesModule,
    TooltipModule.forRoot()
  ],
  entryComponents: [
    AddSuspendedPaymentModalComponent,
    PostSuspendedComponent,
    SearchResultModalComponent,
    ReturnModalComponent,
    VoidModalComponent,
    DetailsSuspendedPaymentModalComponent,
    UploadIpxModalComponent,
    GenerateIpxSuspenseReportModalComponent,
    AddSplitPaymentModalComponent,
    DetailsSuspendedSplitPaymentTableModalComponent
  ]
})
export class PendingModule { }
