/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import * as _ from 'lodash';

@Directive({
    selector: '[inputPattern]'
})
export class InputRestrictionDirective {
    inputElement: ElementRef;
    @Input('inputPattern') appInputRestriction: string;

    constructor(el: ElementRef) {
        this.inputElement = el;
    }

    /**
     * Check input restriction on fields
     * Other input restriction condition can be added.
     * Example, input field that accept integer only, see "Else if" condition.
     *
     * @param event
     */
    @HostListener('keypress', ['$event']) onKeyPress(event) {
        if (this.appInputRestriction === 'namePattern') {
            this.namePatternRestriction(event);
        } else if (this.appInputRestriction === 'integer') {
            this.integerOnlyRestriction(event);
        } else if (this.appInputRestriction === 'policyPattern') {
            this.policyPatternRestriction(event);
        } else if (this.appInputRestriction === 'alphaNumericPattern') {
            this.alphaNumericPattern(event);
        } else if (this.appInputRestriction === 'namePatternWithÑ') {
            this.namePatternRestrictionWithÑ(event);
        }
    }

    /**
     * Prevent pasting inputs that is not allowed on a certain field.
     *
     * @param event
     */
    @HostListener('paste', ['$event']) onPaste(event) {
        const e = <ClipboardEvent>event;
        const pasteData = e.clipboardData.getData('text/plain');
        const firstLetterPattern = /^[a-z ñ]+$/i;
        const includedFields: Array<string> = ['namePattern', 'integer'];
        let regex;
        switch (this.appInputRestriction) {
            case includedFields[0]:
                regex = /^[a-z ñ '-]+$/i;
                break;
            case includedFields[1]:
                regex = /[0-9]/g;
                break;
            default:
                break;
        }

        if (this.appInputRestriction === 'alphaNumeric') {
        regex = /^[a-z0-9A-Z Ñ]+$/i;
          if (!regex.test(pasteData)) {
            alert('Field can contain A-Z, a-z and numbers only.');
            e.preventDefault();
          }
        }

        if (_.includes(includedFields, this.appInputRestriction)) {
            if (regex.test(pasteData)) {
                const arr: string[] = pasteData.split('');
                if (arr.length) {
                    if (firstLetterPattern.test(arr[0])) {
                        return;
                    } else {
                        if (this.inputElement.nativeElement.selectionStart > 0) {
                            return;
                        } else {
                            // TODO: alert message are temporary.
                            alert('First letter should only contain A-Z!');
                            e.preventDefault();
                        }
                    }
                } else {
                    if (firstLetterPattern.test(pasteData)) {
                        return;
                    } else {
                        // TODO: alert message are temporary.
                        alert('First letter should only contain A-Z!');
                        e.preventDefault();
                    }
                }
                return;
            } else {
                // TODO: alert message are temporary.
                alert('Field can contain A-Z, a-z, hyphen, apostrophe, space only!');
                e.preventDefault();
            }
        }
    }

    /**
     * Pattern restriction for firstname and lastname based on BRUL 12 and 13.
     *
     * @param event
     */
    namePatternRestriction(event) {
        const e = <KeyboardEvent>event;
        const firstLetterPattern = /^[a-z ñ]+$/i;
        const namePattern = /^[a-z ñ'-]+$/i;
        const arr: string[] = this.inputElement.nativeElement.value.split('');
        if (e.key.toUpperCase() === 'TAB') {
            return;
        }
        if (namePattern.test(e.key)) {
            if (arr.length && this.inputElement.nativeElement.selectionStart > 0) {
                return;
            } else {
                if (firstLetterPattern.test(e.key)) {
                    return;
                } else {
                    e.preventDefault();
                }
            }
        }
        e.preventDefault();
    }

    /**
     * Pattern restriction for firstname and lastname based on BRUL 12 and 13.
     *
     * @param event
     */
    namePatternRestrictionWithÑ(event) {
        const e = <KeyboardEvent>event;
        const firstLetterPatternWithÑ  = /^[a-z]+$/i;
        const namePatternWithÑ = /^[a-z Ñ'-]+$/i;

        const arr: string[] = this.inputElement.nativeElement.value.split('');
        if (e.key.toUpperCase() === 'TAB') {
            return;
        }
        if (namePatternWithÑ.test(e.key)) {
            if (arr.length && this.inputElement.nativeElement.selectionStart > 0) {
                return;
            }else {
                if (firstLetterPatternWithÑ.test(e.key)) {
                    return;
                } else {
                    e.preventDefault();
                }
            }
        }
        e.preventDefault();
    }

    /**
     * Can be used for fields that only accept numbers.
     *
     * @param event
     */
    integerOnlyRestriction(event) {
        const e = <KeyboardEvent>event;
        if (e.key.toUpperCase() === 'TAB') {
            if (_.includes(['delete', 'backspace', 'tab', 'escape', 'enter', 'decimal point'], e.key.toLowerCase()) ||
                // Allow: Ctrl+A
                (e.key === 'a' && e.ctrlKey === true) ||
                // Allow: Ctrl+C
                (e.key === 'c' && e.ctrlKey === true) ||
                // Allow: Ctrl+V
                (e.key === 'v' && e.ctrlKey === true) ||
                // Allow: Ctrl+X
                (e.key === 'x' && e.ctrlKey === true)) {
                // let it happen, don't do anything
                return;
            }
            if (!_.includes(['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'], e.key)) {
                e.preventDefault();
            }
        }
    }

    /**
     * Pattern restriction for policy number.
     *
     * @param event
     */
    policyPatternRestriction(event) {
        const e = <KeyboardEvent>event;
        if (e.code.toLowerCase() === 'space') {
            e.preventDefault();
        } else {
            return;
        }
    }

    alphaNumericPattern(event) {
        const e = <KeyboardEvent>event;
        const firstLetterPattern = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g;
        const alphaNumericSpecialChars = /^[a-z A-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g;
        const arr: string[] = this.inputElement.nativeElement.value.split('');
        if (e.key.toUpperCase() === 'TAB') {
            return;
        }
        if (alphaNumericSpecialChars.test(e.key)) {
            if (arr.length && this.inputElement.nativeElement.selectionStart > 0) {
                return;
            } else {
                if (firstLetterPattern.test(e.key)) {
                    return;
                } else {
                    e.preventDefault();
                }
            }
        }
        e.preventDefault();
    }
}
