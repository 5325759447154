import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { UwQuestionsLabelsConstants } from '../../../../../shared/constants/uw-questions.labels.constants';
import { SubmissionPageData } from '../../../../../modules/submission-management/data/submission-page.data';
import { UwQuestionsData } from '../../../data/uw-questions.data';
import { LvUWQuestionsModel } from 'app/shared/models/lv-model/lv.uwquestion.model';
import { CustomValidators } from 'app/shared/validators/custom.validator';
import { PolicySummaryData } from '../../../../policy-management/data/policy-summary.data';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import FormUtils from 'app/shared/utilities/form.utils';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';
import { QuoteService } from 'app/core/services/quote.service';
import { LvUWQuestions } from '../../../../../shared/constants/uw-questions.labels.constants';
import { CoveragesData } from 'app/modules/submission-management/data/coverages.data';

@Component({
  selector: 'app-eligibility',
  templateUrl: './eligibility.component.html',
  styleUrls: ['./eligibility.component.scss'],
})
export class EligibilityComponent implements OnInit {
  public ErrorMessageConstant = ErrorMessageConstant;
  public UWQUestionInfoMessage: any = UwQuestionsLabelsConstants.infoMessage;
  public UwQuestionsLabelsConstants: any = UwQuestionsLabelsConstants.eligibility;
  public LvUwQuestionEligibility: LvUWQuestionsModel[] = [];
  public everRentLease: any[] = [];
  GenericLabel = GenericLabel;
  public FormUtils = FormUtils;
  public formTypes = FormTypeConstants;
  public lvQuestions = LvUWQuestions;

  constructor(
    public uwQuestionsData: UwQuestionsData,
    protected submissionData: SubmissionPageData,
    public policySummaryData: PolicySummaryData,
    public quoteService: QuoteService,
    public coveragesData: CoveragesData
  ) { }

  ngOnInit() {
    this.LvUwQuestionEligibility = this.uwQuestionsData.LvUWQuestions.filter(a => a.section === 'Eligibility');
    this.everRentLease = this.uwQuestionsData.uwQuestionHeldAndRent.filter(val => val.isActive === true);
    this.uwQuestionsData.isAreDogsClassifiedChange = false;
  }


  switchKeyPress(key: string, fieldName: string, form: FormGroup): void {
    if (!form.get(fieldName).disabled) {
      switch (key.toLowerCase()) {
        case 'y':
          form.get(fieldName).setValue(true);
          break;
        case 'n':
          form.get(fieldName).setValue(false);
          break;
        default: break;
      }
    }
  }

  getLvDescription(code: string): string {
    const description = this.quoteService.formType === this.formTypes.DP3 && code === this.lvQuestions[53].code ?
      this.LvUwQuestionEligibility.find(x => x.code === code && x.isDP3)?.description :
      this.LvUwQuestionEligibility.find(x => x.code === code)?.description;
    return description || '';
  }
}
