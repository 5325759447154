import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TaskData } from '../../../data/task.data';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { TaskLabelsConstants } from '../../../../../shared/constants/task.labels.constants';
import { LvOwner,
  LvTaskCategory,
  LvTaskContactType,
  LvTaskDescription,
  LvTaskPriority,
  LvTaskStatus,
  LvTaskSubType,
  LvTaskType } from '../../../../../shared/constants/task.options.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TaskSearchModalComponent } from './task-search-modal/task-search-modal.component';
import { ITaskPostRequestDTO,
  ITaskPolicySearchRequestDTO,
  ITaskDTO,
  ITaskPolicySearchResultDTO,
  ITaskNotesDTO,
  ITaskDocumentsDTO,
  TaskDocumentsDTO,
  TaskNotesDTO} from '../../../../../shared/models/data/dto/task/task.dto';
import { TaskService } from '../../../../../core/services/task/task.service';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../../../shared/base-class';
import Utils from '../../../../../shared/utilities/utils';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import * as _ from 'lodash';
import { PageSections } from '../../../../../shared/enum/page-sections.enum';

@Component({
  selector: 'app-task-modal',
  templateUrl: './task-modal.component.html',
  styleUrls: ['./task-modal.component.scss']
})
export class TaskModalComponent extends BaseClass implements OnInit, OnDestroy {

  isAddTaskModal: boolean = true;
  isEndorsement: boolean;
  title: string;
  isFromPolicyRequest: boolean = false;
  isExternal: boolean = false;
  isView: boolean = false;
  isDashboardPage: boolean = true;
  noResult: boolean = false;

  initialTaskNotes: ITaskNotesDTO[] = [];
  initialTaskDocuments: ITaskDocumentsDTO[] = [];

  public taskDetailsForm: FormGroup;
  public TaskLabelsConstants = TaskLabelsConstants;
  public ErrorMessageConstant = ErrorMessageConstant;
  public PageSections = PageSections;
  GenericLabel = GenericLabel;
  LvTaskType = LvTaskType;
  LvTaskSubType = LvTaskSubType;
  LVTaskStatus = LvTaskStatus;
  LVTaskContactType = LvTaskContactType;
  LVTaskCategory = LvTaskCategory;
  LVTaskDescription = LvTaskDescription;
  LVTaskPriority = LvTaskPriority;
  LVOwner = LvOwner;
  modalRef: BsModalRef | null;
  taskDescription: any;
  taskCategory: any;

  modalData: ITaskDTO | null;

  isFs13: boolean = false;
  isFs95: boolean = false;
  isFs11: boolean = false;
  isFs12: boolean = false;

  dueDateOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'mm/dd/yyyy',
    disableSince: {year: 0, month: 0, day: 0},
    disableUntil: {year: 0, month: 0, day: 0}
  };

  constructor( protected fb: FormBuilder,
      protected modalService: BsModalService,
      public taskData: TaskData,
      public  bsModalRef: BsModalRef,
      public taskService: TaskService) {
    super();
  }

  ngOnInit() {
    this.taskData.getUserInformation();
    this.taskData.initializeForm();
    this.taskData.isAddTask = this.isAddTaskModal;
    this.subscribetoFontSizeSubscription();
    if (this.isAddTaskModal) {
      this.taskData.generateTaskId();
      this.taskData.enabledDisabledControls();
    } else {
      // Populate data
      this.onSelectCategory(this.modalData.taskCategoryCode);
      this.taskData.populateTaskModal(this.modalData);
    }
  }

  ngOnDestroy() {
    this.taskData.currentRiskId = null;
    this.taskData.currentRiskDetailId = null;
    this.taskData.currentTaskId = null;
    this.taskData.currentEntityId = null;
    this.taskData.currentStateCode = null;
    this.taskData.currentPolicyNumber = null;
    this.taskData.currentQuoteNumber = null;
    this.taskData.currentInsuredName = null;
    this.taskData.currentPropertyAddress = null;
    this.taskData.isEndorsement = true;
    this.taskData.currentProcessDate = null;

    this.taskData.currentCategory = null;
    this.taskData.currentTaskDescription = null;
    this.taskData.currentOtherDescription = null;
    this.taskData.currentDueDate = null;
    this.taskData.currentOwner = null;
    this.taskData.currentOwnerName = null;

    this.taskData.currentUserName = null;
    this.taskData.currentUserId = null;

    this.taskData.taskDocuments = [];
    this.taskData.taskNotes = [];
    this.taskData.taskHistoryList = [];
    this.taskData.isSearchModalOpen = false;

    this.modalData = null;
    this.isAddTaskModal = true;
    this.taskData.isViewTask = false;
    this.isFromPolicyRequest = false;
    this.taskData.isDisabledForm = true;

    this.taskData.searchItemPerPage = 10;
    this.taskData.searchCurrentPage = 1;
    this.taskData.searchTotalRowCount = 500;

    this.taskData.hasNoteChanges = false;
    this.taskData.hasDocumentChanges = false;
  }

  onSelectCategory(code) {
    this.taskDescription = this.LVTaskDescription.filter(data => data.taskCategoryCode === code);
    this.taskData.taskDetailsForm.get('taskDescription').setValue('');
    this.taskData.taskDetailsForm.get('taskDescription').updateValueAndValidity();
   }

  hideMyModal(): void {
    this.bsModalRef.hide();
  }

  okMyModal(): void {
    const payload = this.taskData.getTaskPayload(this.isAddTaskModal, this.modalData);

    _.forEach(payload.task.taskNotes, (data, index) => {
      if (this.isAddTaskModal) {
        // Empty Task Notes CreatedDate
        data.createdDate = null;
      }
      if (typeof data.createdBy === 'string' || data.createdBy instanceof String) {
        data.createdBy = this.taskData.currentUserId;
      }
    });

    _.forEach(payload.task.taskDocuments, (data, index) => {
      // Empty Task Documents CreatedDate
      if (this.isAddTaskModal) {
        data.createdDate = null;
      }
      if (typeof data.createdBy === 'string' || data.createdBy instanceof String) {
        data.createdBy = this.taskData.currentUserId;
      }
    });
    this.postTask(payload);
  }

  searchData(): void {
    const payload: ITaskPolicySearchRequestDTO = {
      searchValue: this.taskData.policyForm.get('searchInput').value,
      pageNumber: 1,
      pageSize: 10,
    };
    if (!this.taskData.isSearchModalOpen) {
      Utils.blockUI();
      this.taskService.taskSearch(payload).pipe(takeUntil(this.stop$)).subscribe(result => {
        this.taskData.policyData = result.resultList;
        this.taskData.searchTotalRowCount = result.paginationDetails.totalCount;

        if (this.taskData.policyData.length === 1) {
          this.populateSearchResult(this.taskData.policyData[0]);
        } else {
            this.showModalSearchResult();
        }
        Utils.unblockUI();
      }, (error) => {
        Utils.unblockUI();
      });
    }
  }

  populateSearchResult(data: ITaskPolicySearchResultDTO): void {
    this.taskData.taskSelectPolicy(data);
  }

  showModalSearchResult(): void {
    const initialState = {
      title: this.TaskLabelsConstants.Task.Search.SearchPolicyTitle
    };

    this.modalRef = this.modalService.show(TaskSearchModalComponent, {
      initialState,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  postTask(payload: ITaskPostRequestDTO): void {
    Utils.blockUI();
    this.taskService.postTask(payload).pipe(takeUntil(this.stop$)).subscribe(result => {
      // Get Task List to refresh table in dashboard
      if (!this.isFromPolicyRequest) {
        let taskPayload;
        if (!this.taskData.isExternal) {
          taskPayload = this.taskData.getUWTaskFilterFormPayload(null,this.taskData.currentPageInfo);
        } else {
          taskPayload = this.taskData.getInitialTaskListPayload(this.taskData.currentPageInfo);
        }
        this.taskData.getTaskList(taskPayload);
      } else {
        this.taskData.policyCurrentPage = 1;
        this.taskData.getTaskListByPolicy(this.isFromPolicyRequest, this.taskData.policyCurrentPage);
      }

      this.hideMyModal();
    }, (error) => {
      Utils.unblockUI();
    });
  }

  subscribetoFontSizeSubscription(): void {
    this.taskData.taskDetailsForm.get('taskDescription').valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
      this.isFs13 = value.toUpperCase() === 'POR';
    });

    const preDefinedOwner: string[] = [TaskLabelsConstants.UWG, TaskLabelsConstants.PUW, TaskLabelsConstants.ACG, TaskLabelsConstants.UAG];
    this.taskData.taskDetailsForm.get('owner').valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
      if (value) {
        this.isFs11 = value === TaskLabelsConstants.CSG;
        this.isFs12 = preDefinedOwner.includes(value);

        if (value !== TaskLabelsConstants.CSG ||  value !== TaskLabelsConstants.UWG || value !== TaskLabelsConstants.PUW || value !== TaskLabelsConstants.ACG || value !== TaskLabelsConstants.UAG) {
          this.isFs95 = (value.length > 15);
        }
      }
    });
  }

  onSelect(event?): void {
    this.taskData.taskOwnerData = event.item;
  }

  typeaheadNoResults(event: boolean): void {
    this.noResult = event;
  }

  getTaskDetailFormValue(name: string): any {
    return this.taskData.taskDetailsForm.get(name)?.value ?? '';
  }

  hasChanges(): boolean {
    if (this.isAddTaskModal) {
      return false;
    }
    const date = new Date(this.modalData.dueDate).toLocaleDateString();
    const cDate = new Date(this.getTaskDetailFormValue('dueDate')?.singleDate?.jsDate).toLocaleDateString();

    const hasTaskFormChanges = this.modalData.typeCode !== this.getTaskDetailFormValue('type') ||
                    this.modalData.taskSubTypeCode !== this.getTaskDetailFormValue('subType') ||
                    this.modalData.taskContactTypeCode !== this.getTaskDetailFormValue('contactType') ||
                    (this.modalData?.contactFullName ?? '') !== this.getTaskDetailFormValue('contactName') ||
                    this.modalData.taskCategoryCode !== this.getTaskDetailFormValue('category') ||
                    this.modalData.taskDescriptionCode !== this.getTaskDetailFormValue('taskDescription') ||
                    (this.modalData?.otherDescription ?? '') !== this.getTaskDetailFormValue('otherDescription') ||
                    this.taskData.currentOwnerName !== this.getTaskDetailFormValue('owner') ||
                    this.modalData.taskStatusCode !== this.getTaskDetailFormValue('status') ||
                    date !== cDate;

    const hasTaskNoteChanges = this.initialTaskNotes.length !== this.taskData.taskNotes.length || this.taskData.hasNoteChanges;

    const hasTaskDocumentChanges = this.initialTaskDocuments.length !== this.taskData.taskDocuments.length ||  this.taskData.hasDocumentChanges;

    return !(hasTaskFormChanges || hasTaskNoteChanges || hasTaskDocumentChanges);
  }
}
