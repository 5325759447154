import { Component, EventEmitter, OnInit } from '@angular/core';
import { PagerService } from '../../../../../../../core/services/pagerService';
import { SuspendedPaymentsLabel } from '../../../../../../../shared/constants/suspended-payments.labels.constants';
import { BsModalRef } from 'ngx-bootstrap';
import { PolicySearchResultDTO } from '../../../../../../../../app/shared/models/data/dto/suspended-payment/policy-list-dto';

@Component({
  selector: 'app-search-result-modal',
  templateUrl: './search-result-modal.component.html',
  styleUrls: ['./search-result-modal.component.scss']
})
export class SearchResultModalComponent implements OnInit {

  public event: EventEmitter<any> = new EventEmitter<any>();
  public LabelConstants = SuspendedPaymentsLabel.searchResult;

  resultListPager: any = {};
  resultListLoading: boolean;
  resultListPagedItems: any[];

  public resultList: PolicySearchResultDTO[];

  isSiebelWithDuplicatePolicyNumber: boolean = false;

  constructor(private pagerService: PagerService,
    public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.resultListSetPage(1);
  }

  resultListSetPage(page: number): void {
    if (page < 1) {
      return;
    }

    this.resultListPager = this.pagerService.getPager(this.resultList.length, page, 5);
    this.resultListPagedItems = this.resultList.slice(this.resultListPager.startIndex, this.resultListPager.endIndex + 1);
  }

  onSelect(risk: PolicySearchResultDTO): void {
    this.event.next(risk);
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }
}
