import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuspendedPaymentsComponent } from './suspended-payments.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AppBreadcrumbModule, AppSidebarModule } from '@coreui/angular';
import { TabsModule } from 'ngx-bootstrap';
import { SharedModule } from 'app/shared/shared.module';
import { SuspendedPaymentsRoutingModule } from './suspended-payments-routing.module';
import { ReturnedModule } from './returned/returned.module';
import { PendingModule } from './pending/pending.module';
import { SuspendedPaymentsData } from '../data/suspended-payments.data';
import { SentToIpxModule } from './sent-to-ipx/sent-to-ipx.module';
import { SuspendedPaymentsGuard } from 'app/core/guards/suspended-payments.guard';



@NgModule({
  declarations: [SuspendedPaymentsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    AppSidebarModule,
    AppBreadcrumbModule.forRoot(),
    TabsModule.forRoot(),
    SharedModule,
    SuspendedPaymentsRoutingModule,
    ReturnedModule,
    PendingModule,
    SentToIpxModule
  ],
  providers: [
    SuspendedPaymentsData,
    SuspendedPaymentsGuard
  ],
})
export class SuspendedPaymentsModule { }
