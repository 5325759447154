export interface IOfacSearchNotesModel {
    description?: string | undefined;
    category?: string | undefined;
    riskBindId?: string | undefined;
    notesId?: string | undefined;
}
export class OfacSearchNotesModel implements IOfacSearchNotesModel {
    description?: string;
    category?: string;
    riskBindId?: string;
    notesId?: string;
    public constructor(init?: Partial<OfacSearchNotesModel>) {
        Object.assign(this, init);
    }
}
