import { createSelector } from '@ngrx/store';
import { BaseState } from '../base.state';

export const selectRelatedRisksState = (state: BaseState) => state.relatedRisks;

export const selectRelatedRisksIsLoading = createSelector(
  selectRelatedRisksState,
  (state) => state.isLoading
);

export const selectRelatedRiskstList = createSelector(
  selectRelatedRisksState,
  (state) => state.list
);