import { Component, OnDestroy, OnInit } from '@angular/core';
import { AgencyService } from 'app/core/services/management/agency-service';
import { BaseClass } from 'app/shared/base-class';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import NotifUtils from 'app/shared/utilities/notif-utils';
import Utils from 'app/shared/utilities/utils';
import { takeUntil } from 'rxjs/operators';
import { CommissionStatementData } from '../data/commission-statement.data';
import { CommissionStatementConstant } from 'app/shared/constants/commission-statement.constants';

@Component({
  selector: 'app-commission-statement',
  templateUrl: './commission-statement.component.html',
  styleUrls: ['./commission-statement.component.scss']
})
export class CommissionStatementComponent extends BaseClass implements OnInit, OnDestroy {

  hideCommission: boolean = false;
  hideArchivedCommission: boolean = true;
  CommissionStatementConstant = CommissionStatementConstant;

  constructor(
    public commissionStatementData: CommissionStatementData,
    public agencyService: AgencyService
    ) {
    super();
   }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.commissionStatementData.resetData();
  }

  toggleCurrentCommissionStatement(): void {
    this.hideCommission = !this.hideCommission;
  }

  toggleArchivedCommission(): void {
    this.hideArchivedCommission = !this.hideArchivedCommission;
  }
}
