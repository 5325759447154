import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ClaimsModalModel } from '../../../../shared/models/submission/claims/claims.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ClaimsModalComponent } from './claims-modal/claims-modal/claims-modal.component';
import { ActivatedRoute } from '@angular/router';
import { ClickTypes } from '../../../../shared/enum/click-type.enum';
import { ClaimsLabelsConstants } from '../../../../shared/constants/claims.labels.constants';
import { CurrencyPipe } from '@angular/common';
import { ITableTd } from '../../../../shared/models/dynamic/table.interface';
import NotifUtils from '../../../../shared/utilities/notif-utils';
import { ClaimsData } from '../../data/claims.data';
import { ErrorMessageConstant } from '../../../../shared/constants/error-message.constants';
import { EndorsementsData } from '../../data/endorsements.data';
import { ApplicantData } from '../../data/applicant.data';
import { TableNameConstants } from '../../../../shared/constants/table.name.constants';
import { SummaryData } from '../../data/summary.data';
import { SaveClaimsDTO } from '../../../../shared/models/data/dto/quick-quote/save-claims.dto';
import { RiskClaimDTO } from '../../../../shared/models/data/dto/third-party/risk-claims.dto';
import { AuthService } from '../../../../core/services/auth.service';
import { BaseClass } from '../../../../shared/base-class';
import { NavigationService } from '../../../../core/services/navigation/navigation.service';
import { ThirdPartyData } from '../../../../modules/submission-management/data/third-party.data';
import Utils from '../../../../shared/utilities/utils';
import { ThirdPartyDataService } from '../../../../core/services/submission/third-party/third-party-data.service';
import { take, takeUntil } from 'rxjs/operators';
import * as vkbeautify from 'vkbeautify';
import { ClaimsSource, ClaimStatus, LossTypeList } from '../../../../shared/constants/claims.options.constants';
import * as _ from 'lodash';
import { ClaimsSavingData } from '../../data/claims-saving.data';
import { PathConstants } from '../../../../shared/constants/path.constants';
import { PageSectionsValidations } from '../../../../shared/enum/page-sections.enum';
import { PolicySummaryData } from '../../../../modules/policy-management/data/policy-summary.data';
import { PageHeaderSummaryConstants } from '../../../../shared/constants/page-header-summary.constants';
import { IDisableTableItems } from '../../../../shared/models/dynamic/table.interface';
import { SubmissionPageData } from '../../data/submission-page.data';
import { GenericConstants } from '../../../../shared/constants/generic.constants';
import { PolicyService } from '../../../../core/services/submission/policy.service';
import { EntityRiskData } from '../../data/entity-risk.data';
import { CalculateRaterData } from 'app/core/services/submission/rater-premium/calculate-rater-data.service';
import { FormTypeConstants } from '../../../../shared/constants/form-types.constants';
import { CoveragesData } from '../../data/coverages.data';
import { IPolicyCancellationDTO } from '../../../../shared/models/data/dto/policy-management/policy-cancellation.dto';
import {
  updateClaimsIsLoadingFromClaimsComponent,
  updateClaimsListFromClaimsComponent
} from 'app/store/claims/claims.actions';
import { select, Store } from '@ngrx/store';
import { selectClaimsIsLoading } from 'app/store/claims/claims.selectors';
import { Observable } from 'rxjs';
import { QuickQuoteService } from 'app/core/services/submission/quick-quote.service';
import { AplusLastCallStatusDTO } from '../../../../shared/models/data/dto/third-party/aplus-last-call-status.dto';
import { LocalStorageService } from '../../../../core/services/local-storage.service';

type mode = 'edit' | 'delete';

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.scss'],
})
export class ClaimsComponent extends BaseClass implements OnInit {
  public TableNameConstants = TableNameConstants;
  public ClaimsLabel = ClaimsLabelsConstants;
  protected riskIdField: string;
  protected riskDetailIdField: string;
  protected saveClaimsDto: SaveClaimsDTO = { riskId: '', riskDetailId: '', riskClaims: [] };
  protected rowIndexEdited: any;
  protected codeTypeDefinition: any = {
    ClaimType: this.claimsData.claimsConstants.fields.claimType,
    ClaimSource: this.claimsData.claimsConstants.fields.claimSource,
    ClaimStatus: this.claimsData.claimsConstants.fields.claimStatus
  };
  genericConstants = GenericConstants;
  claimsFrm: FormGroup;
  modalRef: BsModalRef | null;
  isClaimsHidden: boolean = false;
  idForSaving: any;
  disableItems: IDisableTableItems = {
    deleteIcon: false
  };
  formTypeConstants = FormTypeConstants;

  protected selectClaimsIsLoading$: Observable<boolean>;
  protected showClaimsPreloader: boolean = false;

  constructor(
    protected fb: FormBuilder,
    protected modalService: BsModalService,
    public route: ActivatedRoute,
    protected currency: CurrencyPipe,
    public claimsData: ClaimsData,
    protected endorsementData: EndorsementsData,
    protected applicantData: ApplicantData,
    protected summaryData: SummaryData,
    protected authService: AuthService,
    public navigationService: NavigationService,
    protected thirdPartyDataService: ThirdPartyDataService,
    protected thirdPartyData: ThirdPartyData,
    protected claimsSavingData: ClaimsSavingData,
    public policySummaryData: PolicySummaryData,
    public submissionData: SubmissionPageData,
    public policyService: PolicyService,
    public entityRiskData: EntityRiskData,
    public raterData: CalculateRaterData,
    protected coveragesData: CoveragesData,
    protected store: Store,
    protected quickQuoteService: QuickQuoteService,
    protected storage: LocalStorageService
  ) {
    super();
    this.selectClaimsIsLoading$ = this.store.pipe(select(selectClaimsIsLoading));
  }

  ngOnInit() {
    this.checkShowClaimsPreloader();
    this.riskIdField = this.summaryData.SummaryForm.get('riskId').value ? this.summaryData.SummaryForm.get('riskId').value : this.claimsData.riskId;
    this.riskDetailIdField = this.summaryData.SummaryForm.get('riskDetailId').value;
    this.subscribeToUserType();
    sessionStorage.setItem(PageSectionsValidations.CurrentPage, PathConstants.PageDestination.Claims);
    this.subscribeToQuoteStatus();

    if (this.policySummaryData.isPolicy) {
      this.generateStateTiming();
    }
    else {
      this.policyService.getRiskById(this.storage.get('riskId')).pipe(takeUntil(this.stop$)).subscribe(result => {
        if (result.renewalCode !== null || result.renewalStatusCode !== null){
          this.claimsData.disableOrderLossReport = true;
        }
        else {
          this.claimsData.disableOrderLossReport = result.isLastAPlusCallSuccess;
        }
      }, error => {
        this.store.dispatch(updateClaimsIsLoadingFromClaimsComponent({ isLoading: false }));
      });
    }
  }

  subscribeToUserType(): void {
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.claimsData.isExternal = this.claimsData.isExternalUser(userType);
      this.setTable();
      this.claimsData.mapClaimListAndSetIndexID();
      this.checkStarPackage();
    });
  }

  subscribeToQuoteStatus(): void {
    this.submissionData.quoteStatus.pipe(takeUntil(this.stop$)).subscribe(result => {
      this.disableItems.deleteIcon = this.isExternal && (result === this.genericConstants.quoteStatus.ruw);
    });
  }

  setTable(): void {
    const claimSourceIndex = this.claimsData.tableHeaders.findIndex(x => x.value === this.ClaimsLabel.claimSource);
    const claimNumberIndex = this.claimsData.tableHeaders.findIndex(x => x.value === this.ClaimsLabel.claimNumber);
    const individualMatchTypeIndex = this.claimsData.tableHeaders.findIndex(x => x.value === this.ClaimsLabel.individualMatchType);
    const totalOutstandingReserveIndex = this.claimsData.tableHeaders.findIndex(x => x.value === this.ClaimsLabel.totalOutstandingReserve);
    const totalRecoveriesAndReimbursementIndex = this.claimsData.tableHeaders.findIndex(x => x.value === this.ClaimsLabel.totalRecoveriesAndReimbursement);
    const pcsCodeIndex = this.claimsData.tableHeaders.findIndex(x => x.value === this.ClaimsLabel.pcsCode);

    this.setTableByUserType(claimSourceIndex, claimNumberIndex, individualMatchTypeIndex,totalOutstandingReserveIndex,
      totalRecoveriesAndReimbursementIndex, pcsCodeIndex);
    this.claimsData.claimsTableRows = [];
    this.checkClaimList();
    this.initData(this.claimsData.claimList);
  }

  setTableByUserType(claimSourceIndex: number, claimNumberIndex: number, individualMatchTypeIndex: number,
    totalOutstandingReserveIndex: number, totalRecoveriesAndReimbursementIndex: number, pcsCodeIndex: number): void {
    if (this.isExternal && claimSourceIndex !== -1 && claimNumberIndex !== -1 && individualMatchTypeIndex !== -1
      && totalOutstandingReserveIndex !== -1 && totalRecoveriesAndReimbursementIndex !== -1 && pcsCodeIndex !== -1) {
        this.claimsData.tableHeaders.splice(pcsCodeIndex, 1);
        this.claimsData.tableHeaders.splice(totalRecoveriesAndReimbursementIndex, 1);
        this.claimsData.tableHeaders.splice(totalOutstandingReserveIndex, 1);
      this.claimsData.tableHeaders.splice(individualMatchTypeIndex, 1);
      this.claimsData.tableHeaders.splice(claimNumberIndex, 1);
      this.claimsData.tableHeaders.splice(claimSourceIndex, 1);
      this.claimsData.claimsTableRows.forEach(row => {
        row.tr.splice(pcsCodeIndex, 1);
        row.tr.splice(totalRecoveriesAndReimbursementIndex, 1);
        row.tr.splice(totalOutstandingReserveIndex, 1);
        row.tr.splice(individualMatchTypeIndex, 1);
        row.tr.splice(claimNumberIndex, 1);
        row.tr.splice(claimSourceIndex, 1);
      });
      return;
    }

    if (!this.isExternal && claimSourceIndex === -1 && claimNumberIndex === -1 && individualMatchTypeIndex === -1
      && totalOutstandingReserveIndex === -1 && totalRecoveriesAndReimbursementIndex === -1 && pcsCodeIndex === -1) {
      this.claimsData.tableHeaders.push({ value: this.ClaimsLabel.claimSource });
      this.claimsData.tableHeaders.push({ value: this.ClaimsLabel.claimNumber });
      this.claimsData.tableHeaders.push({ value: this.ClaimsLabel.individualMatchType });
      this.claimsData.tableHeaders.push({ value: this.ClaimsLabel.totalOutstandingReserve });
      this.claimsData.tableHeaders.push({ value: this.ClaimsLabel.totalRecoveriesAndReimbursement });
      this.claimsData.tableHeaders.push({ value: this.ClaimsLabel.pcsCode });
    }
  }

  checkClaimList(): void {
    if(this.claimsSavingData.checkIfClaimsForSaving()) {
      setTimeout(() => { this.checkClaimList(); }, 100);
    } else { // if claims done saving, retrieve claims
      this.claimsData.clearPreviousClaimsData();
      if (this.claimsData.claimList === undefined || this.claimsData.claimList.length === 0) {
        if (!this.riskIdField) {
          if (!this.policySummaryData.isPolicy) {
            // this.showReportMessage();
            this.claimsData.disableOrderLossReport = false;
          }
        } else {

          if (!this.policySummaryData.isPolicy && !this.policySummaryData.isRenewal()) {
            this.callAPlusApi();
          } else {
            this.claimsData.isClaimListLoading = true; // claims loading
            this.policyService.getClaimsData(this.entityRiskData.getRiskId(), this.entityRiskData.getRiskDetailId()).pipe(takeUntil(this.stop$)).subscribe(claims => {
              if(this.policySummaryData?.isPolicy && this.policySummaryData?.isEditPolicy) { this.claimsSavingData.markClaimsForSaving(); }
              const effectiveDate = new Date(this.applicantData.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate);
              this.claimsData.populateClaimsData(claims, effectiveDate, this.endorsementData.endorsementsGroup);
              this.claimsData.riskClaims = [];
              this.claimsData.riskClaims = claims;
              this.claimsData.isClaimListLoading = false; // claims loading
            });
          }
        }
      } else {
        if (!this.policySummaryData.isPolicy || (this.policySummaryData?.isPolicy && this.policySummaryData?.isEditPolicy)) {
          this.claimsSavingData.markClaimsForSaving();
        }
      }
    }
  }

  initData(claimsList: any): void {
    claimsList.map((item: ClaimsModalModel) => {
      const claims: ClaimsModalModel = {
        claimChargeableVsNonChargeable: item.claimChargeableVsNonChargeable,
        lossDate: item.lossDate,
        additionalDescription: item.additionalDescription,
        claimSource: item.claimSource,
        claimType: item.claimType,
        notes: item.notes,
        claimAmount: item.claimAmount,
        otherQuestions: item.otherQuestions,
        id: item.id,
        claimStatus: item.claimStatus,
        catCode: item.catCode,
        policyType: item.policyType,
        dispute: item.dispute,
        isActOfGod: item.isActOfGod,
        claimNumber: item.claimNumber,
        individualMatchType: item.individualMatchType,
        riskClaimId: item.riskClaimId,
        isFromParent: item?.isFromParent,
        createdDate: item?.createdDate,
        isComparative: item.isComparative,
        isLossAssociatedToHome: item.isLossAssociatedToHome,
        isLossAssociatedToInsured: item.isLossAssociatedToInsured,
        totalOutstandingReserve: item.totalOutstandingReserve,
        totalRecoveriesAndReimbursement: item.totalRecoveriesAndReimbursement,
        pcsCode: item.pcsCode
      };

      this.claimsData.addTableItem(claims);
    });
  }

  editTableItem(rowId: any, tableRow: string): void {
    this.rowIndexEdited = rowId;

    this.claimsData[tableRow].forEach((item) => {
      if (item.id === rowId) {
        if (this.isExternal) {
          this.showEditClaims(this.claimsData.claimList.find(a => a.id === rowId));
        } else {
          const claims = {
            lossDate: item.tr[0].value,
            claimType: item.tr[1].value,
            additionalDescription: item.tr[2].value,
            catCode: item.tr[3].value,
            claimAmount: item.tr[4].value,
            claimChargeableVsNonChargeable: item.tr[5].value,
            claimStatus: item.tr[6].value,
            policyType: item.tr[7].value,
            dispute: item.tr[8].value,
            claimSource: item.tr[9].value,
            claimNumber: item.tr[10].value,
            individualMatchType: item.tr[11].value,
            totalOutstandingReserve: item.tr[12].value,
            totalRecoveriesAndReimbursement: item.tr[13].value,
            pcsCode: item.tr[14].value,
            id: item.id,
            createdDate: item.createdDate,
            isLossAssociatedToHome: this.claimsData.claimList.find(a => a.id === item.id).isLossAssociatedToHome,
            isLossAssociatedToInsured: this.claimsData.claimList.find(a => a.id === item.id).isLossAssociatedToInsured
          };

          this.showEditClaims(claims);
        }
      } else {
        item.edit = false;
        Utils.unblockUI();
      }
    });
  }

  updateTableItem(row: ClaimsModalModel): void {
    this.claimsData.claimsTableRows.forEach((item) => {
      if (item.id === row.id) {
        item.tr[0].value = row.lossDate;
        item.tr[1].value = row.claimType;
        item.tr[2].value = row.additionalDescription;
        item.tr[3].value = row.catCode;
        item.tr[4].value = row.claimAmount;
        item.tr[5].value = row.claimChargeableVsNonChargeable;
        item.tr[6].value = row.claimStatus;
        item.tr[7].value = row.policyType;
        item.tr[8].value = row.dispute;

        item.tr[0].display = row.lossDate?.singleDate?.formatted;
        item.tr[1].display = this.findCodeToDisplayDescription(this.codeTypeDefinition.ClaimType, (claim) => claim.code === row.claimType);
        item.tr[2].display = row.additionalDescription;
        item.tr[3].display = row.catCode;
        item.tr[4].display = this.currency.transform(row.claimAmount, 'USD', 'symbol', '1.0');
        item.tr[5].display = row.claimChargeableVsNonChargeable ? this.claimsData.claimsConstants.isChargeable : this.claimsData.claimsConstants.notChargeable;
        item.tr[6].display = this.findCodeToDisplayDescription(this.codeTypeDefinition.ClaimStatus, (status) => status.code === row.claimStatus);
        item.tr[7].display = row.policyType;
        item.tr[8].display = row.dispute ? this.claimsData.claimsConstants.isDispute : this.claimsData.claimsConstants.notDispute;

        if (!this.isExternal) {
          item.tr[9].value = row.claimSource;
          item.tr[9].display = this.findCodeToDisplayDescription(this.codeTypeDefinition.ClaimSource, (source) => source.code === row.claimSource);

          item.tr[10].value = row.claimNumber;
          item.tr[10].display = row.claimNumber;

          item.tr[11].value = row.individualMatchType;
          item.tr[11].display = row.individualMatchType;

          item.tr[12].value = row.totalOutstandingReserve;
          item.tr[12].display = row.totalOutstandingReserve;
          item.tr[13].value = row.totalRecoveriesAndReimbursement;
          item.tr[13].display = row.totalRecoveriesAndReimbursement;
          item.tr[14].value = row.pcsCode;
          item.tr[14].display = row.pcsCode;
        }

      }
    });

    this.claimsData.claimList.forEach((item) => {
      if (item.id === row.id) {
        item.additionalDescription = row.additionalDescription;
        item.catCode = row.catCode;
        item.claimAmount = row.claimAmount;
        item.claimChargeableVsNonChargeable = row.claimChargeableVsNonChargeable;
        item.claimSource = row.claimSource;
        item.claimStatus = row.claimStatus;
        item.claimType = row.claimType;
        item.dispute = row.dispute;
        // item.id = row.id;
        item.isActOfGod = row.isActOfGod;
        item.lossDate = row.lossDate;
        item.notes = row.lossDate;
        item.otherQuestions = row.otherQuestions;
        item.policyType = row.policyType;
        item.individualMatchType = row.individualMatchType;
        item.claimNumber = row.claimNumber;
        item.isLossAssociatedToHome = row.isLossAssociatedToHome;
        item.isLossAssociatedToInsured = row.isLossAssociatedToInsured;
        item.totalOutstandingReserve = row.totalOutstandingReserve;
        item.totalRecoveriesAndReimbursement = row.totalRecoveriesAndReimbursement;
        item.pcsCode = row.pcsCode;
        item.isExternal = row.isExternal;
      }
    });
  }

  deleteTableItem(rowId: any, tableRow: string): void {
    this.claimsSavingData.markClaimsForSaving();

    this.claimsData[tableRow].forEach((item, index) => {
      if (item.id === rowId) {
        this.claimsData[tableRow].splice(index, 1);
        this.claimsData.claimList.splice(index, 1);
      }
    });

    this.claimsSavingData.saveClaims();
  }

  generateId(): number {
    const maxId = this.claimsData.claimsTableRows.length > 0
      ? Math.max(...this.claimsData.claimsTableRows.map(claim => claim.id)) + 1
      : 1;

    return maxId;
  }

  showAddClaims(): void {
    const claim: ClaimsModalModel = {
      claimType: '',
      additionalDescription: '',
      claimStatus: '',
      lossDate: null,
      claimChargeableVsNonChargeable: false,
      catCode: this.claimsData.claimsConstants.newCATCode,
      policyType: this.getPolicyTypeBasedOnFormType(),
      dispute: false,
      claimNumber: this.claimsData.claimsConstants.newClaimNumber,
      individualMatchType: this.claimsData.claimsConstants.newIndividualMatchType,
      claimSource: this.isExternal ? ClaimsSource[1].code : ClaimsSource[2].code,
      id: null,
      createdDate: null,
      isComparative: false,
      isLossAssociatedToHome: false,
      isLossAssociatedToInsured: false,
      totalOutstandingReserve: 0,
      totalRecoveriesAndReimbursement: 0,
      pcsCode: ''
    };

    const initialState = {
      title: this.claimsData.claimsConstants.addClaims,
      idForSaving: this.generateId(),
      claimsModel: claim,
      isAdd: true,
      riskId: this.entityRiskData.getRiskId()
    };

    this.claimsData.isAddingClaim = true;
    this.modalRef = this.modalService.show(ClaimsModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.content.event.pipe(takeUntil(this.stop$)).subscribe((res) => {
      this.claimsData.isAddingClaim = false;
      this.claimsData.addTableItem(res.data);
      this.claimsData.claimList.push(res.data);
      this.claimsData.mapClaimListAndSetIndexID();
      this.checkStarPackage();

      if (this.claimsData.claimList.length > 0 && this.claimsData.claimList.find(f => f.isAPlus)) {
        this.claimsData.disableOrderLossReport = true;
      }

      if (this.policySummaryData.isPolicy) {
        this.claimsData.sortPolicyClaimsList(this.claimsData.claimList);
      }

      this.claimsSavingData.saveClaims();
    });
  }

  checkStarPackage(): void {
    const starPackage = this.endorsementData.endorsementsGroup.get('starPackage').value;
    const starAdvantagePackage = this.endorsementData.endorsementsGroup.get('starAdvantagePackage').value;

    if ((starPackage || starAdvantagePackage) && this.claimsData.claimList.length > 0) {
      const invalidLosses = this.claimsData.claimList.filter((x: ClaimsModalModel) => this.claimsData.hasClaimsForPastThreeYears(x.lossDate) && !this.claimsData.checkIsActOfGod(x.claimType)).length;

      if (invalidLosses > 0) {
        NotifUtils.showError(ErrorMessageConstant.starPackageErrorMessage, () => {
          this.endorsementData.isStarPackageChanged = true;
          this.endorsementData.endorsementsGroup.get('starPackage').setValue(false);
          this.endorsementData.endorsementsGroup.get('starAdvantagePackage').setValue(false);
        });
      }
    }
  }

  showEditClaims(data: ClaimsModalModel): void {
    const claims: ClaimsModalModel = {
      claimAmount: data?.claimAmount,
      claimChargeableVsNonChargeable: data?.claimChargeableVsNonChargeable,
      lossDate: data?.lossDate,
      additionalDescription: data?.additionalDescription,
      claimSource: data?.claimSource,
      claimType: data?.claimType,
      notes: data?.notes,
      otherQuestions: data?.otherQuestions,
      id: data?.id,
      claimStatus: data?.claimStatus,
      catCode: data?.catCode,
      policyType: data?.policyType,
      dispute: data?.dispute,
      claimNumber: data?.claimNumber,
      individualMatchType: data?.individualMatchType,
      isAPlus: data?.isAPlus,
      createdDate: data?.createdDate,
      isComparative: data?.isComparative,
      isLossAssociatedToHome: data?.isLossAssociatedToHome,
      isLossAssociatedToInsured: data?.isLossAssociatedToInsured,
      totalOutstandingReserve: data?.totalOutstandingReserve,
      totalRecoveriesAndReimbursement: data?.totalRecoveriesAndReimbursement,
      pcsCode: data?.pcsCode
    };

    const initialState = {
      title: this.claimsData.claimsConstants.editClaims,
      claimsModel: claims,
      isAdd: false,
      riskId: this.entityRiskData.getRiskId()
    };

    this.modalRef = this.modalService.show(ClaimsModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.content.event.pipe(takeUntil(this.stop$)).subscribe((res) => {
      this.updateTableItem(res.data);
      const modalModel: ClaimsModalModel = res.data as ClaimsModalModel;
      // eslint-disable-next-line radix
      const claim: ClaimsModalModel = this.claimsData.claimList.find(a => a.id === this.rowIndexEdited);
      this.updateClaim(claim, modalModel);

      if (this.policySummaryData.isPolicy) {
        this.claimsData.sortPolicyClaimsList(this.claimsData.claimList);
      }

      this.claimsSavingData.saveClaims();
    });
  }

  ToggleHidingOfClaims(): void {
    this.isClaimsHidden = !this.isClaimsHidden;
  }

  public onSubmit(clickType?: any): void {
    if (clickType === ClickTypes.Calculate) {
      this.raterData.claimsRequest(this.claimsData, this.entityRiskData.getPolicyNumber());
      this.claimsSavingData.saveClaims();
      return;
    }

    if (!this.policySummaryData.isPolicy) {
      switch (clickType) {
        case ClickTypes.Back:
          this.navigationService.navigateRoute(PathConstants.PageDestination.Endorsements);
          break;
        case ClickTypes.Next:
          this.navigationService.navigateRoute(PathConstants.PageDestination.Property);
          break;
      }

      return;
    }

    this.onNextBackPolicy(clickType);
  }

  onNextBackPolicy(clickType: any): void {
    const page = clickType === ClickTypes.Back ? PathConstants.Policy.Policies.Endorsements :
      PathConstants.Policy.Policies.Property;
    this.navigationService.navigatePolicyRoute(page);
  }

  public callAPlusApi(isManualOrderLossReport: boolean = false): void {
    if (!this.riskIdField && !isManualOrderLossReport) {
      this.claimsData.disableOrderLossReport = false;
      return;
    }

    const effectiveDate = new Date(this.applicantData.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate);
    this.store.dispatch(updateClaimsIsLoadingFromClaimsComponent({ isLoading: true }));

    this.store.dispatch(updateClaimsIsLoadingFromClaimsComponent({ isLoading: true }));
    try {
      const thirdPartyRequest = this.thirdPartyData.getApplicantInformationModel();
      const aPlusRequest = this.thirdPartyData.getAPlusRequestModel(thirdPartyRequest);
      aPlusRequest.riskId = aPlusRequest.riskId ? aPlusRequest.riskId : this.riskIdField;
      this.claimsData.isClaimListLoading = true;
      this.claimsData.disableOrderLossReport = true;

      if (!this.thirdPartyData.dataFromAPIResponse.isLastAPlusCallSuccess) {
        this.thirdPartyDataService.getAPlusThirdPartyData(aPlusRequest).pipe(takeUntil(this.stop$)).subscribe(result => {
          this.claimsSavingData.markClaimsForSaving();
            this.claimsData.disableOrderLossReport = true;
            if (!result) {
              this.claimsData.disableOrderLossReport = false;
              this.showReportMessage();
            } else {
              this.saveClaimsDto.riskId = this.riskIdField;
              this.claimsData.populateClaimsData(result, effectiveDate, this.endorsementData.endorsementsGroup);
              this.claimsData.riskClaims = [];
              this.claimsData.riskClaims = result;

              if (result && result?.some(claim => claim.isNewlyRetrievedFromAPlus)) {
                this.claimsData.claimList.forEach(claim => {
                  if(claim.isAPlus) {
                    const lossDate = new Date(claim.lossDate.singleDate?.jsDate);
                    claim.claimChargeableVsNonChargeable = this.claimsData.checkAPlusChargeableExceptionRule(claim, claim.claimChargeableVsNonChargeable, lossDate, effectiveDate);
                    claim.isComparative = false;
                  }
                });
              }

              this.claimsData.mapClaimListAndSetIndexID();
              this.claimsData.disableOrderLossReport = true;
              this.claimsSavingData.saveClaims();
              this.thirdPartyData.dataFromAPIResponse.isLastAPlusCallSuccess = true;
              this.thirdPartyData.saveAplusLastCallStatus();
          }

          this.claimsData.isClaimListLoading = false;

          this.store.dispatch(updateClaimsListFromClaimsComponent({ list: result }));
          this.store.dispatch(updateClaimsIsLoadingFromClaimsComponent({ isLoading: false }));

        }, error => {
          this.getClaimsPopulateData();
          this.showReportMessage();
          this.claimsSavingData.unmarkClaimsForSaving();
          this.claimsData.disableOrderLossReport = false;
          this.thirdPartyData.dataFromAPIResponse.isLastAPlusCallSuccess = false;
          this.claimsData.isClaimListLoading = false;
          this.thirdPartyData.saveAplusLastCallStatus();
          this.store.dispatch(updateClaimsIsLoadingFromClaimsComponent({ isLoading: false }));
        });
      }
      else {
        this.getClaimsPopulateData();
        this.claimsSavingData.unmarkClaimsForSaving();
        this.claimsData.disableOrderLossReport = true;
        this.claimsData.isClaimListLoading = false;
        this.store.dispatch(updateClaimsIsLoadingFromClaimsComponent({ isLoading: false }));
      }

    } catch (error) {
        this.getClaimsPopulateData();
        this.claimsSavingData.unmarkClaimsForSaving();
        this.claimsData.disableOrderLossReport = false;
        this.thirdPartyData.dataFromAPIResponse.isLastAPlusCallSuccess = false;
        this.showReportMessage();
        this.claimsData.isClaimListLoading = false;
        this.store.dispatch(updateClaimsIsLoadingFromClaimsComponent({ isLoading: false }));
    }

  }

  orderLossReportRequest(event: any): void {
    this.callAPlusApi(true);
  }

  getClaimsPopulateData(data: RiskClaimDTO[] = []) {
    this.policyService.getClaims(this.entityRiskData.getRiskId(), this.entityRiskData.getRiskDetailId()).pipe(takeUntil(this.stop$)).subscribe(claims => {
      const mergeData = data.concat(claims);
      const effectiveDate = new Date(this.applicantData.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate);

      //TODO: Update logic for DP3 Chargeable Update
      // if (this.entityRiskData.getFormType() === this.formTypeConstants.DP3) {
      //   mergeData = mergeData.map(a =>  {return this.claimsData.setClaimsChargeable(a, this.coveragesData.isCoverageLorMEnabled);});
      // }
      this.claimsData.populateClaimsData(mergeData, effectiveDate, this.endorsementData.endorsementsGroup);
      this.claimsData.riskClaims = [];
      this.claimsData.riskClaims = mergeData;
      this.claimsData.mapClaimListAndSetIndexID();
    });
  }

  calculateAge(): number {
    const today = this.authService.getCustomDate();
    const effectiveDate = this.applicantData.mainApplicant.get('effectiveDate').value;
    const dateRef = new Date(effectiveDate?.singleDate?.jsDate);
    let age: number = today.getFullYear() - dateRef.getFullYear();
    const m: number = today.getMonth() - dateRef.getMonth();
    const d: number = today.getDate() - dateRef.getDate();

    if (age === 18 && (m < 0 || (m === 0 && d < 0))) {
      age = age - 1;
    }

    return age;
  }

  updateCollectionID(response: any, riskId: string): void {
    const riskClaims = response?.riskClaims;

    if (riskClaims) {
      riskClaims.forEach((element, index) => {
        this.claimsData.claimList[index].riskClaimId = element.id;
      });
    }
  }

  protected updateClaim(sourceClaim: ClaimsModalModel, updatedClaim: ClaimsModalModel): void {
    sourceClaim.claimChargeableVsNonChargeable = updatedClaim?.claimChargeableVsNonChargeable;
    sourceClaim.lossDate = updatedClaim.lossDate;
    sourceClaim.additionalDescription = updatedClaim.additionalDescription;
    sourceClaim.claimSource = updatedClaim.claimSource;
    sourceClaim.claimType = updatedClaim.claimType;
    sourceClaim.notes = updatedClaim.notes;
    sourceClaim.claimAmount = updatedClaim.claimAmount;
    sourceClaim.otherQuestions = updatedClaim.otherQuestions;
    sourceClaim.claimStatus = updatedClaim.claimStatus;
    sourceClaim.catCode = updatedClaim.catCode;
    sourceClaim.policyType = updatedClaim.policyType;
    sourceClaim.dispute = updatedClaim.dispute;
    sourceClaim.isActOfGod = updatedClaim.claimType?.actOfGod;
    sourceClaim.individualMatchType = updatedClaim.individualMatchType;
    sourceClaim.claimNumber = updatedClaim.claimNumber;
    sourceClaim.isLossAssociatedToHome = updatedClaim.isLossAssociatedToHome;
    sourceClaim.isLossAssociatedToInsured = updatedClaim.isLossAssociatedToInsured;
    sourceClaim.totalOutstandingReserve = updatedClaim.totalOutstandingReserve;
    sourceClaim.totalRecoveriesAndReimbursement = updatedClaim.totalRecoveriesAndReimbursement;
    sourceClaim.pcsCode = updatedClaim.pcsCode;
    sourceClaim.isExternal = updatedClaim.isExternal;
  }

  protected findCodeToDisplayDescription(codeType: string, callbackPredicate: any): string {
    switch (codeType) {
      case this.codeTypeDefinition.ClaimType:
        return LossTypeList.find(callbackPredicate).description;
      case this.codeTypeDefinition.ClaimSource:
        return ClaimsSource.find(callbackPredicate).description;
      case this.codeTypeDefinition.ClaimStatus:
        return ClaimStatus.find(callbackPredicate).description;
      default:
        break;
    }
  }

  protected showReportMessage(): void {
    if (this.summaryData.SummaryForm.get('policyStatus').value !== PageHeaderSummaryConstants.policyStatusActive){
      NotifUtils.showError(ErrorMessageConstant.IsoAplusErrorMessage);
    }
  }

  get isExternal(): boolean {
    return this.authService.userType.value === this.genericConstants.userType.external;
  }

  generateStateTiming(): void {
    const currentRiskId = this.entityRiskData.getRiskId();
    const currentRiskDetailId = this.entityRiskData.getLastRiskDetailId();

    Utils.blockUI();
    this.policyService.getStateTiming(currentRiskId, currentRiskDetailId).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.claimsData.stateTimingData = result as IPolicyCancellationDTO;
      Utils.unblockUI();
    }, (error) => {
      this.claimsData.stateTimingData = null;
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify('Error encountered while generating State Timing: ' + error));
    });
  }

  getPolicyTypeBasedOnFormType() {
    let result = '';
    const formType = this.summaryData.SummaryForm.get('formType')?.value;
    switch (formType) {
      case FormTypeConstants.HO3:
        result = ClaimsLabelsConstants.policyTypes.ho3;
        break;
      case FormTypeConstants.HO4:
        result = ClaimsLabelsConstants.policyTypes.ho4;
        break;
      case FormTypeConstants.HO6:
        result = ClaimsLabelsConstants.policyTypes.ho6;
        break;
      case FormTypeConstants.DP3:
        result = ClaimsLabelsConstants.policyTypes.dp3;
        break;
      default:
        result = ClaimsLabelsConstants.policyTypes.default;
        break;
    }

    return result;
  }

  checkShowClaimsPreloader(): void {
    this.selectClaimsIsLoading$.pipe(takeUntil(this.stop$)).subscribe(result => {
      const showClaimsPreloader = result;

      if (showClaimsPreloader) {
        this.showClaimsPreloader = showClaimsPreloader;
      } else {
        setTimeout(() => {
          this.showClaimsPreloader = showClaimsPreloader;
        }, 3000);
      }
    });
  }

  getClaimsData(rowId: any, tableRow: string, mode: mode): void {
    const editMode = 'edit';
    const deleteMode = 'delete';
    if (mode === editMode) {
      Utils.blockUI();
    }

    const effectiveDate = new Date(this.applicantData.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate);

    this.riskIdField = this.riskIdField && this.riskIdField !== '' ? this.riskIdField : this.entityRiskData?.EntityRisk?.risks[0]?.id;
    this.riskDetailIdField = this.riskDetailIdField && this.riskDetailIdField !== '' ? this.riskDetailIdField : this.entityRiskData?.EntityRisk?.risks[0]?.riskDetails[0]?.id;

    this.quickQuoteService.getClaimsData(this.riskIdField, this.riskDetailIdField).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.claimsData.populateClaimsData(result, effectiveDate, this.endorsementData.endorsementsGroup);

      switch (mode) {
        case editMode:
          this.editTableItem(rowId, tableRow);
          break;
        case deleteMode:
          this.deleteTableItem(rowId, tableRow);
          break;
      }
    });
  }
}
