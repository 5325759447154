import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { PolicyBillingData } from 'app/modules/policy-management/data/policy-billing.data';
import { MonthConstants } from 'app/shared/constants/month.constants';
import { GenericLabel } from 'app/shared/constants/generic.labels.constants';
import { ZipCodeData } from 'app/modules/submission-management/data/zipcode.data';
import Utils from 'app/shared/utilities/utils';
import { PageSections } from 'app/shared/enum/page-sections.enum';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { PolicyBillingLabelsConstants } from 'app/shared/constants/policy-billing.labels.constants';
import FormUtils from '../../../../../../shared/utilities/form.utils';
import { AuthService } from 'app/core/services/auth.service';

@Component({
  selector: 'app-post-payment-credit-card',
  templateUrl: './post-payment-credit-card.component.html',
  styleUrls: ['./post-payment-credit-card.component.scss']
})
export class PostPaymentCreditCardComponent implements OnInit {

  @Input() ccPaymentForm: FormGroup;
  @Input() hasEmail: boolean = false;
  @Input() isRefundRequest: boolean = false;
  public FormUtils = FormUtils;

  months = [];
  years = [];
  currentYear = this.authService.getCustomDate().getFullYear();

  ErrorMessageConstant = ErrorMessageConstant;
  CCPaymentConstants = PolicyBillingLabelsConstants.creditCard;
  GenericLabel = GenericLabel;

  constructor(public billingData: PolicyBillingData,
    public zipCodeData: ZipCodeData, protected authService: AuthService) { }

  ngOnInit() {
    for (const month in MonthConstants) {
      this.months.push(MonthConstants[month]);
    }

    for (let i = 1; i < 20; i++) {
      this.years.push(this.currentYear++);
    }
  }

  setZipCode(isUSCountry?: boolean) {
    this.ccPaymentForm.get('city').enable();
    const zipCode = this.ccPaymentForm.get('zip').value;
    let formControlNames = ['state', 'city', 'zip'];
    this.zipCodeData.ccCityList = [];
    Utils.blockUI();
    if (zipCode !== '' && isUSCountry) {
      this.zipCodeData.getGenericZipCode(zipCode, this.ccPaymentForm, formControlNames, PageSections.CreditCardPayment);

    } else {
      if (isUSCountry) { // IF US COUNTRY AS IS
        this.resetZipFields(formControlNames);
        Utils.unblockUI();
        NotifUtils.showError('Zip code not found, contact Underwriting');
      } else { // IF NOT US COUNTRY
        formControlNames = ['state', 'city', 'zip', 'address'];
        this.resetZipFields(formControlNames);
        Utils.unblockUI();
      }
    }
    this.zipCodeData.billingCityListComplete();
  }

  resetZipFields(formControlNames): void {
    this.FormUtils.resetFields(this.ccPaymentForm as FormGroup, formControlNames);
    this.zipCodeData.ccCityList = [];
  }

  get activeCreditCardTypes(): any {
    return this.billingData.LvCreditCardType.filter(x => x.isActive);
  }
}
