export enum ClickTypes {
  Next = 'next',
  Back = 'back',
  Save = 'save',
  Issue = 'issue',
  PolicyIssue = 'policyissue',
  Reset = 'reset',
  Calculate = 'calculate',
  EmailQuote = 'emailQuote',
  LaunchProperty = 'launchProperty',
  Cancel = 'cancel',
  Update = 'update',
  Revise = 'reviserenewaloffer'
}