import { ITableTr } from 'app/shared/models/dynamic/table.interface';
import { createAction, props } from '@ngrx/store';
import { RiskUWApprovalDTO } from 'app/shared/models/data/dto/quick-quote/uw-approval.dto';

export const updatePolicyUWRsIsLoadingFromUwApprovalSavingData = createAction(
  '[UwApprovalSavingData] Update Policy UWRs Is Loading',
  props<{ isLoading: boolean }>()
);

export const updatePolicyUWRsListFromUwApprovalSavingData = createAction(
  '[UwApprovalSavingData] Update Policy UWRs List',
  props<{ list: RiskUWApprovalDTO[] }>()
);

export const updatePolicyUWRsTableRowsFromUwApprovalData = createAction(
  '[UwApprovalData] Update Policy UWRs Table Rows',
  props<{ tableRows: ITableTr[] }>()
);