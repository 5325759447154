export const AgentDashboardConstants = {
  quotes: 'Quotes',
  pendingQuotes: 'Pending Quotes',
  referredToUW: 'Referred to UW',
  approvedbyUW: 'Approved by UW',
  declineByUW: 'Declined by UW',
  additionalInfoRequired: 'Additional Info Required',
  billing: 'Billing',
  paymentDue: 'Payment Due',
  paymentPastDue: 'Payment Past Due',
  policies: `Policies`,
  activePolicies: 'Active Policies',
  referredToUWPolicies: 'Referred to UW',
  approvedbyUWPolicies: 'Approved by UW',
  declineByUWPolicies: 'Declined by UW',
  additionalInfoRequiredPolicies: 'Additional Info Required',
  pendingCancellation: 'Pending Cancellation',
  pendingNonRenewal: 'Pending Non-Renewal',
  pendingRenewal: 'Pending Renewal',
  renewalOffered: 'Renewal Offered',
  nonRenewed: 'Non-Renewed',
  reference: 'Reference',
  louisianaHomeownersQuickReferenceGuide: 'Louisiana Homeowners Quick Reference Guide',
  louisianaDwellingFireQuickReferenceGuide: 'Louisiana Dwelling Fire Quick Reference Guide',
  homeOwnerUnderwritingGuidelines: 'Homeowner Underwriting Guidelines',
  dwellingFireUnderwritingGuidelines: 'Dwelling Fire Underwriting Guidelines',
  quickReferenceGuide: 'Quick Reference Guide',
  homeownersRatingManual: 'Homeowners Rating Manual - Effective 01/01/2021',
  homeownersRatingManual2: `Homeowners Rating Manual - Effective 05/15/2020`,
  dwellingFireRatingManual: `Dwelling Fire Rating Manual - 01/01/2021`,
  dwellingFireRatingManual2: 'Dwelling Fire Rating Manual - 05/15/2020',
  accreditedBuilderList: `Accredited Builder List`,
  formsLibrary: 'Forms Library',
  help: `WE'RE HERE TO HELP`,
  customerServiceUnderwriting: 'Customer Service/Underwriting',
  customerServiceUnderwritingTelephone: '866-318-4113',
  customerServiceUnderwritingEmail: 'customerservice@centauri-ins.com',
  claims: 'Claims',
  claimsTelephone: '866-215-7574',
  claimsTelephone2: '888-481-1141 (NFIP Flood Claims)',
  agencyServices: 'Agency Services',
  agencyServicesTelephone: '866-318-4113',
  agencyServicesEmail: 'customerservice@centauri-ins.com',
  agencyServicesRSM: 'RSM',
  paymentMailingAddresses: `Payment Mailing Addresses`,
  startNewQuote: 'Start New Quote',
  startNewQuoteModalTitle: 'Choose a Program State and Type',
  processEndorsement: 'Process Endorsement',
  makeAPayment: 'Make A Payment',
  makeAPaymentModalTitle: 'Make A Payment',
  reportAClaim: 'Report A Claim',
  reportsNavBar: 'Reports',
  dashboardNavBar: 'Dashboard',
  logoutNavBar: 'Logout',
  changePasswordNavBar: 'Change Password',
  tasks: 'Tasks',
  taskFilters: 'Task Filters',
  taskFiltersmyTasks: 'My Tasks',
  tasksFiltersOwner: 'Owner',
  tasksFiltersDueDate: 'Due Today',
  tasksFiltersDueWithin30Days: 'Due Within 30 Days',
  tasksPolicyNumber: 'Policy #',
  tasksQuoteNumber: 'Quote #',
  tasksnamedInsured: 'Named Insured',
  tasksDescription: 'Description',
  tasksDueDate: 'Due Date',
  tasksOwner: 'Owner',
  floridaFN: 'Florida',
  alabamaFN: 'Alabama',
  louisianaFN: 'Louisiana',
  mississippiFN: 'Mississippi',
  southCarolinaFN: 'South Carolina',
  texasFN: 'Texas',
  floridaSC: 'FL',
  alabamaSC: 'AL',
  louisianaSC: 'LA',
  mississippiSC: 'MS',
  southCarolinaSC: 'SC',
  texasSC: 'TX',
  actParam: 'ACT',
  ruwParam: 'RUW',
  auwParam: 'AUW',
  duwParam: 'DUW',
  airParam: 'AIR',
  pecParam: 'PEC',
  rwoParam: 'RWO',
  rrwoParam: 'RRWO',
  rrwParam: 'RRW',
  rwntParam: 'RWNT',
  prrwParam:  'PRRW',
  rnpenParam: 'RNPEN',
  rrnpenParam : 'RRNPEN',
  qexpParam: 'QEXP',
  defaultEffectiveDate: '2023-12-06',
  rnpen_allParam: 'RNPEN_ALL',
  rwo_allParam: 'RWO_ALL',
  nrwdParam: 'NRWD',
  sfnrwParam: 'SFNRW',
  policyNumberCannotBeFound: 'Policy Number cannot be found.',
  ok: 'Ok',
  cancel: 'Cancel',
  policyNumber: 'Policy Number',
  noOfItems: 'items',
  dashboardRouterUrl: '/dashboard',
  management: 'Management',
  taskEditButton: 'Edit',
  billingType: {
    paymentDue: 'paymentDue',
    paymentPastDue: 'paymentPastDue'
  },
  startNewQuoteModal: {
    programState: 'Program State',
    programType: 'Program Type'
  },
  claimsCustomURL: 'To report a Homeowners claim, click',
  flood: 'Flood',
  bindingAuthorityNotAvailable: 'Binding authority is not available for this agency. You have servicing-only capabilities for existing business.'
};
