import { Action, createReducer, on } from '@ngrx/store';
import * as fromPropertiesActions from '../../store/properties/properties.actions';
import { initialState, PropertiesState } from './properties.state';

const _propertiesReducer = createReducer(
  initialState,
  on(
    fromPropertiesActions.updatePropertiesIsLoadingFromPropertySavingData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  )
);

export function propertiesReducer(
  state: PropertiesState | null,
  action: Action
) {
  return _propertiesReducer(state, action);
}