import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IInspectionOrderingDTO, InspectionOrderingDTO } from 'app/shared/models/data/dto/report/inspection.ordering.dto';
import { CommonService } from '../common.service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    public http: HttpClient,
    protected commonService: CommonService
  ) { }

  getInspectionOrdering(riskId: string): Observable<IInspectionOrderingDTO> {
    return this.http.get(`${environment.ApiUrl}/InspectionOrdering/risk?riskId=${riskId}`)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getCS1MonthlyTransactionReport(year): Observable<Blob>{     
    return this.http.get(`${environment.ApiUrl}/Report/CS1MonthlyTransactionReport?year=${year}`, { responseType: "blob"})
}

getMonthlyEarnedPremiumReport(month: number, year: number): Observable<any> {
    return this.http.get<any>(`${environment.ApiUrl}/MonthlyEarnedDoc/EarnedPremiumReport?month=${month}&year=${year}`)
    .catch(this.commonService.handleObservableHttpError);
}

  postInspectionOrdering(data?: InspectionOrderingDTO): Observable<string> {
    const url = `${environment.ApiUrl}/InspectionOrdering`;

    return this.http.post(url, data)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  putInspectionOrdering(data?: InspectionOrderingDTO): Observable<string> {
    const url = `${environment.ApiUrl}/InspectionOrdering/${data.id}`;

    return this.http.put(url, data)
      .pipe(catchError(this.commonService.handleObservableHttpError)
      );
  }

  getAgencySnapshotDocByFilter(agencyId: string, month: number, year: number, reportType: string): Observable<any> {
    if(agencyId == null || agencyId == undefined){
      return this.http.get<any>(`${environment.ApiUrl}/AgencySnapshotDoc/AgencySnapshotDocByFilter?month=${month}&year=${year}&reportType=${reportType}`)
      .catch(this.commonService.handleObservableHttpError);
    }else{
      return this.http.get<any>(`${environment.ApiUrl}/AgencySnapshotDoc/AgencySnapshotDocByFilter?agencyId=${agencyId}&month=${month}&year=${year}&reportType=${reportType}`)
      .catch(this.commonService.handleObservableHttpError);
    }

  }

}
