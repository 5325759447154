import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BillingLabelConstants } from 'app/shared/constants/bind-and-issue.labels.constants';
import { PaymentMethod } from 'app/shared/enum/payment-method.enum';
import { BaseClass } from 'app/shared/base-class';
import { takeUntil } from 'rxjs/operators';
import { PolicyBillingData } from 'app/modules/policy-management/data/policy-billing.data';

@Component({
  selector: 'app-change-payment-plan-modal-agreement',
  templateUrl: './change-payment-plan-modal-agreement.component.html',
  styleUrls: ['./change-payment-plan-modal-agreement.component.scss']
})
export class ChangePaymentPlanModalAgreementComponent implements OnInit {

  @Input() paymentForm: FormGroup;

  public BillingLabelConstants = BillingLabelConstants;

  constructor(public billingData: PolicyBillingData) { }

  ngOnInit() {}

  get isPaymentMethodRecurring(): boolean {
    return this.paymentForm.get('paymentMethod').value === PaymentMethod.RecurringCreditCard ||
      this.paymentForm.get('paymentMethod').value === PaymentMethod.RecurringECheck;
  }
  get isPaymentMethodOneTimeAuth(): boolean {
    return this.paymentForm.get('paymentMethod').value === PaymentMethod.CreditCard ||
      this.paymentForm.get('paymentMethod').value === PaymentMethod.EFT ||
      this.paymentForm.get('paymentMethod').value === PaymentMethod.ECheck;
  }
  get isPaymentMethodCreditCard(): boolean {
    return this.paymentForm.get('paymentMethod').value === PaymentMethod.CreditCard ||
      this.paymentForm.get('paymentMethod').value === PaymentMethod.RecurringCreditCard;
  }

  toggleIAgreeInsuredCheckbox(isChecked: boolean): void {
    this.billingData.checkIfOneAgreeCheckbox(isChecked, this.isPaymentMethodRecurring ? this.paymentForm.get('agreeEnrollAutoPay').value : this.paymentForm.get('agreeOneTimePayment').value);
  }
  
  toggleIAgreeOneTimeAuthCheckbox(isChecked: boolean): void {
    this.billingData.checkIfOneAgreeCheckbox(this.paymentForm.controls.agreeInsured.value,isChecked);
  }

}
