import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UwQuestionsComponent } from './uw-questions.component';
import { SharedModule } from '../../../../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { EligibilityComponent } from './eligibility/eligibility.component';
import { GeneralQuestionsComponent } from './general-questions/general-questions.component';
import { CanDeactivateUWQuestionsComponentGuard } from './uw-questions-navigation-guard.service';

const routes: Routes = [
  // { path: '', component: UwQuestionsComponent, canDeactivate:[CanDeactivateUWQuestionsComponentGuard] }
  { path: '', component: UwQuestionsComponent }
];

@NgModule({
  declarations: [
    UwQuestionsComponent,
    EligibilityComponent,
    GeneralQuestionsComponent,
  ],
  imports: [CommonModule, SharedModule, RouterModule.forChild(routes)],
  providers: [CanDeactivateUWQuestionsComponentGuard]
})
export class UwQuestionsModule {}
