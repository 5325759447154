export const LocationLabelConstants = {
  locationDetailsTitle: 'Location Details',
  locationAddress: 'Location Address',
  county: 'County',
  distanceToWater: 'Distance To Water',
  distanceToActualCoast: 'Distance To Actual Coast',
  protectionClass: 'Protection Class',
  milesToFireStation: 'Miles To Fire Station',
  feetToHydrant: 'Feet To Hydrant',
  propertyUsage: 'Property Usage',
  structureType: 'Structure Type',
  numberOfUnitsFirewalls: 'Number of Units Between Firewalls',
  monthsOccupied: '# Months Occupied',
  residenceRented: 'Is the Residence Rented?',
  propertyManager: 'Property Manager',
  bceg: 'BCEG',
  bcegCommunity: 'BCEG Community',
  territoryCode: 'Territory Code',
  matchLevel: 'Match Level',
  latitude: 'Latitude',
  longitude: 'Longitude',
  windPoolEligible: 'Wind Pool Eligible',
  oceanName: 'Ocean Name',
  nearestWaterName: 'Nearest Water Name',
  respondingFireDepartment: 'Responding Fire Department',
  fireDistrictName: 'Fire District Name',
  valuationId: 'Valuation ID',
  pleaseSelect: 'Please Select',
	FireHydrantLessThan1000Feet: 'FH0',
  FireHydrantMoreThan1000Feet: 'FH1',
  MilesToFireStationFiveRoadMilesOrLess: 'MS0',
  MilesToFireStationFiveRoadMilesOrMore: 'MS1',
  SeasonalDwelling: 'SS',
  ownerOccupiedPrimary: 'OOP',
  ownerOccupiedSecondary: 'OOS',
  tenantOccupied: 'TO',
  shortTermRental: 'STR',
  numberOfUnitsInsured: 'How many units insured under this policy?',
  coveredPorchOrScreenedEnclosure: 'Covered Porch or Screened Enclosure',
  pialZone: 'PIAL Zone',
  residenceRentedBRUL294: 'If property is rented, property usage cannot be owner occupied or seasonal unless structure type = duplex, triplex, or quadplex.',
  MilesToFireStationSevenRoadMilesOrLess: 'MS2',
  MilesToFireStationSevenRoadMilesOrMore: 'MS3',
  parish: 'Parish',
  genzip: 'GENZIP'
};

export const PropertyLabelConstants = {
  propertyDetailsTitle: 'Property Details',
  yearBuilt: 'Year Built',
  purchaseDate: 'Purchase Date',
  squareFootage: 'Square Footage',
  exteriorWallFinish: 'Exterior Wall Finish',
  construction: 'Construction',
  numberOfStories: 'Number of Stories',
  roofShape: 'Roof Shape',
  roofMaterial: 'Roof Material',
  flatTile: 'Is Roof Material a flat tile?',
  yearRoof: 'Year Roof Installed',
  foundationType: 'Foundation Type',
  foundationElevation: 'Foundation Elevation',
  electrical: 'Electrical Type',
  yearElectrical: 'Year electrical system installed',
  yearPlumbing: 'Year plumbing system installed',
  primaryHeatType: 'Primary Heat Type',
  yearHeat: 'Year heating system installed',
  centralAirConditioning: 'Central air conditioning?',
  yearCentralAirConditioning: 'Year central air conditioning system installed',
  replacementCost: 'Replacement Cost',
  replacementCostDate: 'Replacement Cost Date',
  launch360: 'Launch 360 Value',
  pleaseSelect: 'Please Select',
  IsRoofMaterialAFlatTileMaterials: ['TICL','TICO','TICE'],
  IsFoundationTypeHasFoundationElevation: ['EP','SW','DP'],
  floorUnitNumber: 'Floor Unit is on',
  yearWaterHeater: 'Year water heater installed',
  locationOfWaterHeater: 'Location of water heater',
  numberOfFloorsAboveUnit: 'Number of Floors Above Unit'
};

export const WindMitigationLabelConstants = {
  windMitigationTitle: 'Wind Mitigation',
  mitigationForm: 'Mitigation Form',
  dateOfInspection: 'Date Of Inspection',
  internationalResidential: 'International Residential Building Code',
  predominantRoofCovering: 'Predominant Roof Covering',
  permitAppDate: 'Permit App Date/Date of Installation',
  roofDeckAttachment: 'Roof Deck Attachment',
  roofToWallAttachment: 'Roof to Wall Attachment',
  roofGeometry: 'Roof Geometry',
  gableEndBracing: 'Gable End Bracing',
  wallConstructionType: 'Wall Construction Type',
  secondaryWaterResistance: 'Secondary Water Resistance',
  openingProtection: 'Opening Protection',
  qualifiedInspectorType: 'Qualified Inspector Type',
  inspectorLicenseNumber: 'Inspector License Number',
  terrain: 'Terrain',
  windSpeed: 'Wind Speed',
  windDesignSpeed: 'Wind Design Speed',
  internalPressureDesign: 'Internal Pressure Design',
  windBorneDebrisRegion: 'Wind Borne Debris Region',
  mitigation: 'Mitigation',
  pleaseSelect: 'Please Select',
  isFortifiedForSaferHome: 'Fortified for Safer Home',
  level: 'Level',
  isBuildingCodeDiscount: 'Building Code Discount',
  fortifiedForSaferHome: 'Fortified Safer Homes',
  buildingCodeDiscount: 'Building Code Construction',
  floorToFoundationConstruction: 'Floor to Foundation Construction'
};

export const PropertyDropdownName = {
  PropertyUsage : 'Property Usage Dropdown',
  StructureType: 'Structure Type Dropdown',
  NumberOfUnitsInsured: 'Number Of Units Insured Dropdown',
  NumberOfMonthsOccupied: '# Months Occupied'
};

export const PropertyYearFields = {
  Built : 'yearBuilt',
  Roof : 'yearRoof',
  Electrical : 'yearElectrical',
  Plumbing : 'yearPlumbing',
  Heating : 'yearHeating',
  Aircon : 'yearCentralAirConditioning',
  WaterHeater: 'yearWaterHeater'
};

export const PropertyDetailFields = {
  FloorUnit : 'floorUnitNumber'
};