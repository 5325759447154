import { Component, Input, OnInit } from '@angular/core';
import { InfoMessageConstant } from 'app/shared/constants/info-message.constants';
import NotifUtils from 'app/shared/utilities/notif-utils';
import Utils from 'app/shared/utilities/utils';
import { BillingService } from 'app/core/services/billing/billing.service';
import { PolicyBillingData } from 'app/modules/policy-management/data/policy-billing.data';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { BaseClass } from 'app/shared/base-class';
import { PolicyBillingLabelsConstants } from 'app/shared/constants/policy-billing.labels.constants';
import { PolicyService } from 'app/core/services/submission/policy.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-rn-installment-schedule',
  templateUrl: './rn-installment-schedule.component.html',
  styleUrls: ['./rn-installment-schedule.component.scss']
})
export class RnInstallmentScheduleComponent extends BaseClass implements OnInit {
  public installmentScheduleConstants = PolicyBillingLabelsConstants.installmentSchedule;

  isOpen: boolean = true;

  @Input() isRenewal: boolean = false;

  constructor(public policyBillingData: PolicyBillingData,
    public summaryData: SummaryData,
    public billingService: BillingService,
    private policyService: PolicyService) {
    super();
  }

  ngOnInit() {
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }

  onViewClick(invoiceId: string): void {
    Utils.blockUI();
    this.billingService.getInvoiceDocUrl(invoiceId).subscribe(res => {
      Utils.unblockUI();
      if (res) {
        this.policyService.generateSASURL(res).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
          window.open(resultSASURL, '_blank');
        });
      } else {
        NotifUtils.showInfo(InfoMessageConstant.generationDocumentMessage);
      }
    }, err => {
      NotifUtils.showError(err.error?.message);
    });
  }

}
