import { Injectable } from '@angular/core';
import { BaseClass } from '../../../shared/base-class';
import * as _ from 'lodash';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';
import { PropertyData } from '../data/property.data';
import { QuoteService } from 'app/core/services/quote.service';
import { EndorsementsData } from '../data/endorsements.data';
import { ApplicantData } from '../data/applicant.data';
import { EntityRiskData } from '../data/entity-risk.data';
import { LocationLabelConstants } from 'app/shared/constants/property.labels.constants';
import { Validators } from '@angular/forms';
import { PropertyValidators } from '../validators/property.validators';
import { GeneralValidationService } from 'app/core/services/submission/validations/general-validation.service';
import * as moment from 'moment';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { updateAppBrulDatesFromEndorsementComponent } from 'app/store/app/app.actions';
import { AuthService } from 'app/core/services/auth.service';
import { selectAppBrulDates } from 'app/store/app/app.selectors';
import { RoofSurfacingPaymentScheduleBRULDatePipe } from 'app/shared/pipes/rsps-brul-date.pipe';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { EndorsementsLabelsConstants } from 'app/shared/constants/endorsements.labels.constants';
import { UwQuestionsData } from '../data/uw-questions.data';
import { UwQuestionsFieldName } from '../../../shared/constants/uw-questions.labels.constants';
@Injectable()
export class PropertyBusinessRules extends BaseClass {
  formTypeConstants = FormTypeConstants;
  isUnitOwnersRentalDisabled: boolean = false;

  protected selectAppBrulDates$: Observable<any>;
  protected selectAppBrulDates: any | null;

  constructor(protected propertyData: PropertyData
            , protected quoteService: QuoteService
            , protected endorsementData: EndorsementsData
            , protected applicantData: ApplicantData
            , protected entityRiskData: EntityRiskData
            , protected generalValidationService: GeneralValidationService
            , protected store: Store
            , protected authService: AuthService
            , protected roofSurfacingPaymentScheduleBRULDate: RoofSurfacingPaymentScheduleBRULDatePipe
            , protected uwQuestionsData: UwQuestionsData ) {
    super();
    this.selectAppBrulDates$ = this.store.pipe(select(selectAppBrulDates));
    this.subscribeToSelectAppBrulDates$();
  }

  triggerActiveNew111(): boolean {
    const isHO6 = this.quoteService.formType === this.formTypeConstants.HO6;
    const propertyUsage = this.propertyData.locationDetails.get('propertyUsage').value;
    const isResidenceRented = this.propertyData.locationDetails.get('isResidenceRented').value;
    const unitOwnersRentalToOthers = this.endorsementData.endorsementsGroup.get('unitOwnersRentalToOthers').value;
    const isActiveNewRaterVersion = this.entityRiskData.isActiveNewRaterVersion(this.applicantData.mainApplicant.get('effectiveDate').value);

    if ((isHO6 && isActiveNewRaterVersion) && (propertyUsage === LocationLabelConstants.SeasonalDwelling && isResidenceRented) && unitOwnersRentalToOthers) {
        return true;
    } else {
        return false;
    }
  }

  triggerActiveNew112(propertyUsageValue?, isChanged?): void {
    const isHO6 = this.quoteService.formType === this.formTypeConstants.HO6;
    const propertyUsage = propertyUsageValue ?? this.propertyData.locationDetails.get('propertyUsage').value;
    const isResidenceRented = this.propertyData.locationDetails.get('isResidenceRented').value;
    const unitOwnersRentalToOthers = this.endorsementData.endorsementsGroup.get('unitOwnersRentalToOthers');
    const isActiveNewRaterVersion = this.entityRiskData.isActiveNewRaterVersion(this.applicantData.mainApplicant.get('effectiveDate').value);
    const isPolicy = this.entityRiskData.policySummaryData.isPolicy;
    if ((isHO6 && isActiveNewRaterVersion) && (propertyUsage === LocationLabelConstants.SeasonalDwelling)) {
      switch (isPolicy) {
        case true:
          if (propertyUsage === LocationLabelConstants.SeasonalDwelling && isResidenceRented === unitOwnersRentalToOthers.value) {
            unitOwnersRentalToOthers.disable();
            unitOwnersRentalToOthers.setValue(isResidenceRented);
            this.isUnitOwnersRentalDisabled = true;
          } else {
            if (isChanged || !isPolicy) {
              unitOwnersRentalToOthers.disable();
              unitOwnersRentalToOthers.setValue(isResidenceRented);
              this.isUnitOwnersRentalDisabled = true;
            } else {
              unitOwnersRentalToOthers.enable();
              this.isUnitOwnersRentalDisabled = false;
            }
          }
          break;
        case false:
          unitOwnersRentalToOthers.disable();
          unitOwnersRentalToOthers.setValue(isResidenceRented);
          this.isUnitOwnersRentalDisabled = true;
          break;
      }
    } else {
        unitOwnersRentalToOthers.enable();
        this.isUnitOwnersRentalDisabled = false;
    }
  }

  triggerActiveNew115(propertyUsageValue?, isChanged?): void {
    const isHO6 = this.quoteService.formType === this.formTypeConstants.HO6;
    const isHO4 = this.quoteService.formType === this.formTypeConstants.HO4;
    const propertyUsage = propertyUsageValue ?? this.propertyData.locationDetails.get('propertyUsage').value;
    const isResidenceRented = this.propertyData.locationDetails.get('isResidenceRented');
    const isActiveNewRaterVersion = this.entityRiskData.isActiveNewRaterVersion(this.applicantData.mainApplicant.get('effectiveDate').value);
    const isPolicy = this.entityRiskData.policySummaryData.isPolicy;
    if (isHO6 && isActiveNewRaterVersion) {
      this.propertyData.locationDetails.get('isResidenceRented').setValidators([Validators.required, PropertyValidators.riskOwnerOccupiedPrimaryCannotBeRented(propertyUsage, isHO6, isActiveNewRaterVersion)]);
      this.propertyData.locationDetails.updateValueAndValidity();
    } else if (isHO4) {
      this.generalValidationService.clearValidatorFormControl(this.propertyData.locationDetails, 'isResidenceRented');
    } else {
      this.propertyData.locationDetails.get('isResidenceRented').setValidators([Validators.required]);
      this.propertyData.locationDetails.updateValueAndValidity();
    }

    if ((isHO6 && isActiveNewRaterVersion)
        && (propertyUsage === LocationLabelConstants.tenantOccupied || propertyUsage === LocationLabelConstants.shortTermRental)
    ) {
      switch (isPolicy) {
        case true:
          if (isResidenceRented.value) {
            isResidenceRented.setValue(true);
            isResidenceRented.disable();
          } else {
            if (isChanged) {
              isResidenceRented.setValue(true);
              isResidenceRented.disable();
            } else {
              isResidenceRented.enable();
            }
          }
          break;
        case false:
          isResidenceRented.setValue(true);
          isResidenceRented.disable();
          break;
      }
    } else {
      isResidenceRented.enable();
    }
  }

  triggerActiveNew125(isFromEndorsementPage: boolean = false, isFromPopulateForms: boolean = false, effectiveDateChangedFromQuote: boolean = false, isFromOrderPrefill: boolean = false, isYearChanged: boolean = false, isRoofMaterialChanged: boolean = false): void {
    const metalRoof = ['METC', 'META'];
    const yearRoof = this.propertyData.propertyDetails.get('yearRoof').value;
    const yearBuilt = this.propertyData.propertyDetails.get('yearBuilt').value;
    const roofMaterial = this.propertyData.propertyDetails.get('roofMaterial').value;
    const effectiveDateYear = moment(this.applicantData.mainApplicant.get('effectiveDate')?.value?.singleDate?.jsDate).format('YYYY');
    const isFromRenewal = this.entityRiskData.getRisk()?.isFromRenewal;
    const is15YearsOrGreater = isFromRenewal ?
                isRoofMaterialChanged
                  ? (Number(effectiveDateYear) - Number(yearRoof)) >= 15 && (Number(effectiveDateYear) - Number(yearBuilt)) >= 15
                  : (Number(effectiveDateYear) - Number(yearRoof)) >= 15 && (Number(effectiveDateYear) - Number(yearBuilt)) >= 15
              : (Number(effectiveDateYear) - Number(yearBuilt)) >= 15 && (Number(effectiveDateYear) - Number(yearRoof)) >= 15;
    const isYearRoofYearBuilt15YearsOrGreater = !metalRoof.includes(roofMaterial) && is15YearsOrGreater;
    const isMetalYearRoof25YearsOrGreater = metalRoof.includes(roofMaterial) && ((Number(effectiveDateYear) - Number(yearRoof)) >= 25 && (Number(effectiveDateYear) - Number(yearBuilt)) >= 25);
    const rspsDate = isFromRenewal ? this.selectAppBrulDates?.brulDate?.rspsDate_RB : this.selectAppBrulDates?.brulDate?.rspsDate_NB;
    const isValidRoofSurfacingScheduleDate = this.roofSurfacingPaymentScheduleBRULDate.transform(this.quoteService.formType, rspsDate, this.applicantData.mainApplicant.get('effectiveDate')?.value, true, effectiveDateChangedFromQuote);

    if (this.quoteService.formType === this.formTypeConstants.DP3) {
      if (isYearRoofYearBuilt15YearsOrGreater || isMetalYearRoof25YearsOrGreater) {
        if (isValidRoofSurfacingScheduleDate) {
          this.setRoofSurfacingPaymentSchedule(((isYearRoofYearBuilt15YearsOrGreater || isMetalYearRoof25YearsOrGreater) && isValidRoofSurfacingScheduleDate), isFromEndorsementPage, isFromPopulateForms, isFromOrderPrefill, isYearChanged, effectiveDateChangedFromQuote, isRoofMaterialChanged);
        } else {
          this.setRoofSurfacingPaymentSchedule(((isYearRoofYearBuilt15YearsOrGreater || isMetalYearRoof25YearsOrGreater) && isValidRoofSurfacingScheduleDate), isFromEndorsementPage, isFromPopulateForms, isFromOrderPrefill, isYearChanged, effectiveDateChangedFromQuote, isRoofMaterialChanged);
        }
      } else {
        this.setRoofSurfacingPaymentSchedule(((isYearRoofYearBuilt15YearsOrGreater || isMetalYearRoof25YearsOrGreater) && isValidRoofSurfacingScheduleDate), isFromEndorsementPage, isFromPopulateForms, isFromOrderPrefill, isYearChanged, effectiveDateChangedFromQuote, isRoofMaterialChanged);
      }
    } else {
      this.endorsementData.showRSPSInfoMessage = false;
      if (!isValidRoofSurfacingScheduleDate && effectiveDateChangedFromQuote) {
        this.endorsementData.endorsementsGroup.get('roofSurfacingPaymentSchedule').setValue(isValidRoofSurfacingScheduleDate);
      }
    }
  }

  setRoofSurfacingPaymentSchedule(value: boolean, isFromEndorsementPage: boolean, isFromPopulateForms: boolean, isFromOrderPrefill?: boolean, isYearChanged?: boolean, effectiveDateChangedFromQuote?: boolean, isRoofMaterialChanged?: boolean): void {
    const isFromRenewal = this.entityRiskData.getRisk()?.isFromRenewal;
    const previous_rsps = this.endorsementData.endorsementsGroup.get('roofSurfacingPaymentSchedule').value;
    const current_rsps = value;
    const yearRoof = this.propertyData.propertyDetails.get('yearRoof').value;
    const yearBuilt = this.propertyData.propertyDetails.get('yearBuilt').value;
    const isYearRoofValid = Number(yearBuilt) <= Number(yearRoof);

    if (isFromPopulateForms) {
      this.endorsementData.showRSPSInfoMessage = value;
    } else if (isFromEndorsementPage) {
      this.endorsementData.showRSPSInfoMessage = value;
      if (this.endorsementData.policySummaryData.isPolicy || isFromRenewal) {
        this.endorsementData.showRSPSInfoMessage = value;
      } else {
        this.endorsementData.endorsementsGroup.get('roofSurfacingPaymentSchedule').setValue(value);
        this.endorsementData.showRSPSInfoMessage = value;
      }
    } else {
      if (isFromOrderPrefill) {
        this.endorsementData.showRSPSInfoMessage = value;
        this.endorsementData.endorsementsGroup.get('roofSurfacingPaymentSchedule').setValue(value);
      } else if ((isYearChanged || isRoofMaterialChanged) && isYearRoofValid) {
        this.endorsementData.showRSPSInfoMessage = value;
        this.endorsementData.endorsementsGroup.get('roofSurfacingPaymentSchedule').setValue(value);
        if (previous_rsps !== current_rsps) {
          NotifUtils.showInfo(EndorsementsLabelsConstants.rspsNotification.replace('{0}', current_rsps ? 'YES' : 'NO'));
        }
      } else if (effectiveDateChangedFromQuote) {
        this.endorsementData.showRSPSInfoMessage = value;
        this.endorsementData.endorsementsGroup.get('roofSurfacingPaymentSchedule').setValue(value);
      }
    }
  }

  subscribeToSelectAppBrulDates$(): void {
    this.selectAppBrulDates$.pipe().subscribe(brulDates => {
      if (!brulDates) {
        if (localStorage.getItem('auth')) {
          this.authService.getServerDate().subscribe(result => {
            this.selectAppBrulDates = result;
            this.authService.brul247EffectiveDate = result?.brulDate?.bruL247;
            this.authService.uwr121UpdateEffectiveDate = result?.brulDate?.uwR121Update;
            this.authService.uwr116UpdateEffectiveDate = result?.brulDate?.uwR116Update;
            this.authService.uwr87UpdateEffectiveDate = result?.brulDate?.uwR87Update;
            this.authService.privateFloodCoverageRemovalEffectiveDate = result?.brulDate?.privateFloodCoverageRemoval;
            this.authService.uwr116DeactivationEffectiveDate = result?.brulDate?.uwR116Deactivation;
            this.authService.uwr87ActivationEffectiveDate = result?.brulDate?.uwR87Activation;
            this.store.dispatch(updateAppBrulDatesFromEndorsementComponent({ brulDates: result }));
          });
        }
      } else {
        this.selectAppBrulDates = brulDates;
      }
    });
  }

  triggerBRUL351(): void {
    const isResidenceRented = this.propertyData.locationDetails.get('isResidenceRented').value;
    if (this.quoteService.formType !== this.formTypeConstants.HO4) {
      this.uwQuestionsData.eligibilityGroup.get('IsEverRented').setValue(isResidenceRented);
      this.uwQuestionsData.clearChildData(UwQuestionsFieldName.eligibility.IsEverRented);
    }
  }
}