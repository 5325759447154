import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppAsideModule } from '@coreui/angular';
import { ModalModule } from 'ngx-bootstrap';
import { PathConstants } from '../../shared/constants/path.constants';
import { AgencySnapshotComponent } from './agency-snapshot/agency-snapshot.component';
import { CommissionStatementComponent } from './commission-statement/commission-statement.component';
import { CustomerServiceComponent } from './customer-service/customer-service.component';
import { EarnedPremiumComponent } from './earned-premium/earned-premium.component';
import { LeadingIndicatorComponent } from './leading-indicator/leading-indicator.component';
import { ReportsComponent } from './reports.component';

const routes: Routes = [
  {
    path: '',
    component: ReportsComponent,
    children: [
      {
        path: `${PathConstants.Reports.Agency.Index}/${PathConstants.Reports.Agency.Miscellaneous}/${PathConstants.Reports.Agency.AgencySnapshot}`,
        component: AgencySnapshotComponent,
        loadChildren: () => import('./agency-snapshot/agency-snapshot.module').then(m => m.AgencySnapshotModule)
      },
      {
        path: `${PathConstants.Reports.Agency.Index}/${PathConstants.Reports.Agency.Miscellaneous}/${PathConstants.Reports.Agency.CommissionStatement}`,
        component: CommissionStatementComponent,
        loadChildren: () => import('./commission-statement/commission-statement.module').then(m => m.CommissionStatementModule)
      },
      {
        path: `${PathConstants.Reports.Agency.Index}/${PathConstants.Reports.Agency.Miscellaneous}/${PathConstants.Reports.Agency.CustomerService}`,
        component: CustomerServiceComponent,
        loadChildren: () => import('./customer-service/customer-service.module').then(m => m.CustomerServiceModule)
      },
      {
        path: `${PathConstants.Reports.Agency.Index}/${PathConstants.Reports.Agency.Miscellaneous}/${PathConstants.Reports.Agency.EarnedPremium}`,
        component: EarnedPremiumComponent,
        loadChildren: () => import('./earned-premium/earned-premium.module').then(m => m.EarnedPremiumModule)
      },
      {
        path: `${PathConstants.Reports.Underwriter.Index}/${PathConstants.Reports.Underwriter.Generic}/${PathConstants.Reports.Underwriter.LeadingIndicator}`,
        component: LeadingIndicatorComponent,
        loadChildren: () => import('./leading-indicator/leading-indicator.module').then(m => m.LeadingIndicatorModule)
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes), AppAsideModule, ModalModule.forRoot()],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
