import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SummaryData } from '../../../../modules/submission-management/data/summary.data';
import { DocumentsLabelConstants } from '../../../../shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from '../../../../shared/constants/error-message.constants';
import { GenericConstants } from '../../../../shared/constants/generic.constants';
import { GenericLabel } from '../../../../shared/constants/generic.labels.constants';
import { RiskBindDocumentDTO } from '../../../../shared/models/data/dto/quick-quote/risk-bind-document.dto';
import { RiskBindDTO, RiskBindDTO2 } from '../../../../shared/models/data/dto/quick-quote/risk-bind-dto';
import Utils from '../../../../shared/utilities/utils';
import { CustomValidators } from '../../../../shared/validators/custom.validator';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { serialize } from 'object-to-formdata';
import { PolicyDocumentsData } from '../../data/policy-documents.data';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { PolicySummaryData } from '../../data/policy-summary.data';
import { PolicyService } from '../../../../core/services/submission/policy.service';
import { AuthService } from 'app/core/services/auth.service';
import { EntityRiskData } from '../../../../modules/submission-management/data/entity-risk.data';

@Component({
  selector: 'app-policy-documents-modal',
  templateUrl: './policy-documents-modal.component.html',
  styleUrls: ['./policy-documents-modal.component.scss']
})
export class PolicyDocumentsModalComponent implements OnInit {

  public event: EventEmitter<any> = new EventEmitter<any>();
  public ErrorMessageConstant = ErrorMessageConstant;
  public DocumentsLabelConstants = DocumentsLabelConstants;
  public genericConstants = GenericConstants;
  public Utils = Utils;

  GenericLabel = GenericLabel;
  documentsForm: FormGroup;
  modalRef: BsModalRef | null;
  title: string;
  documentsModel: any;
  isAdd: boolean;
  isCancel: boolean = false;
  fileToUpload: File = null;
  fileLabel: string;
  fileSizeExceeds: boolean = false;
  fileNameHasSpecialCharacter: boolean = false;
  dateAddedOption: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'mm/dd/yyyy'
  };

  constructor(protected fb: FormBuilder,
    public bsModalRef: BsModalRef,
    public policyDocumentsData: PolicyDocumentsData,
    public policyService: PolicyService,
    public summaryData: SummaryData,
    protected policySummaryData: PolicySummaryData,
    protected authService: AuthService,
    protected entityRiskData: EntityRiskData) { }

  ngOnInit(): void {
    const currentDate = this.authService.getCustomDate();
    this.documentsForm = this.fb.group({
      id: [this.documentsModel?.id],
      category: [this.documentsModel?.category, [Validators.required]],
      description: [this.documentsModel?.description, [Validators.required, CustomValidators.spaceValidator]],
      dateAdded: [this.documentsModel?.dateAdded ? this.documentsModel?.dateAdded.singleDate ? this.documentsModel?.dateAdded
        : this.getFromApiDate(this.documentsModel?.dateAdded)
        : {
          singleDate: {
            date: {
              year: currentDate.getFullYear(),
              month: currentDate.getMonth() + 1,
              day: currentDate.getDate()
            },
            formatted: `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`,
            jsDate: currentDate
          }
        }, [Validators.required]],
      file: ['', [Validators.required, CustomValidators.requiredFileFormat(this.genericConstants.acceptedFileTypes)]],
      fileName: [this.documentsModel?.fileName],
      filePath: [this.documentsModel?.filePath]
    });

    this.mapFile(this.documentsModel?.fileName);
  }

  mapFile(file?): void {
    this.documentsForm.get('file').setValue(file);
    if (file?.name) {
      this.fileLabel = file.name;
    } else if (file) {
      this.fileLabel = file;
    } else {
      this.fileLabel = 'No File Chosen';
    }
  }

  getFromApiDate(date?): any {
    const new_date = new Date(date);
    return {
      singleDate: {
        date: {
          year: new_date.getFullYear(),
          month: new_date.getMonth() + 1,
          day: new_date.getDate()
        },
        formatted: `${new_date.getMonth() + 1}/${new_date.getDate()}/${new_date.getFullYear()}`,
        jsDate: date
      }
    };
  }

  setDisable(): void {
    if (this.documentsModel?.isEdit) {
      this.documentsForm.get('category').disable();
    }
  }

  hideMyModal(): void {
    this.isCancel = true;
    this.bsModalRef.hide();
  }

  onSubmit(): void {
    if (!this.isCancel) {
      const riskId = this.policySummaryData.clonePolicyId;
      const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;

      const policyDocumentrequest = {
        riskId: riskId,
        riskDetailId: riskDetailId,
        riskBind: new RiskBindDTO()
      };
      const riskBindId = this.entityRiskData.getRiskBindId();
      const document: RiskBindDocumentDTO = {
        id: this.documentsForm.get('id')?.value,
        riskBindId: riskBindId,
        category: this.documentsForm.get('category')?.value,
        description: this.documentsForm.get('description')?.value,
        fileName: this.fileToUpload ? this.fileToUpload : this.documentsForm.get('file').value,
        File: this.fileToUpload ? this.fileToUpload : this.documentsForm.get('file').value,
        filePath: this.documentsForm.get('filePath')?.value,
        isUploaded: false
      };
      policyDocumentrequest.riskBind.id = riskBindId;
      policyDocumentrequest.riskBind.riskDetailId = riskDetailId;
      policyDocumentrequest.riskBind.riskBindDocuments = [];
      policyDocumentrequest.riskBind.riskBindDocuments.push(document);

      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
        allowEmptyArrays: true,
      };

      const serializedData = serialize(policyDocumentrequest, options);
      serializedData.append(`riskBind.riskBindDocuments[0].file`, this.fileToUpload ? this.fileToUpload : this.documentsForm.get('file').value);
      Utils.blockUI();
      this.policyService.postPolicyDocument(serializedData).subscribe(res => {
        const response = <RiskBindDTO2>res;
        const data = {
          id: response.riskBindDocument.id,
          category: this.documentsForm.get('category')?.value,
          description: this.documentsForm.get('description')?.value,
          dateAdded: this.documentsForm.get('dateAdded')?.value,
          file: this.fileToUpload ? this.fileToUpload : this.documentsForm.get('file').value,
          fileName: response.riskBindDocument.fileName,
          riskBindId: response.riskBindDocument.riskBindId,
          documentsId: null,
          isUploaded: response.riskBindDocument.isUploaded,
          filePath: response.riskBindDocument.filePath,
          riskId: response.riskId
        };
        Utils.unblockUI();
        this.event.emit({ data: data, res: 200 });
      },
        err => {
          Utils.unblockUI();
          console.log(err);
        }
      );
      this.bsModalRef.hide();
    }
  }

  handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
    this.fileLabel = this.fileToUpload.name;
    this.documentsForm.get('file').setValue(this.fileToUpload);
    this.fileSizeExceeds = false;

    if (this.documentsForm.get('file').valid) {
      this.fileSizeExceeds = this.fileToUpload?.size > this.genericConstants.fileSizeLimit ? true : false;
    }

    this.fileNameHasSpecialCharacter = false;
    if (this.documentsForm.get('file').valid) {
      for (var counter in this.genericConstants.unacceptedFileNameSpecialCharacter) {
        if (this.fileToUpload?.name.indexOf(this.genericConstants.unacceptedFileNameSpecialCharacter[counter].sign) != -1) {
          this.fileNameHasSpecialCharacter = true;
          break;
        }  
      }
    }
  }

  get invalidFileTypeMessage(): string {
    return ErrorMessageConstant.documentsErrorMessage.invalidFileTypeErrorMessage.replace('{0}', this.documentsForm.get('file').value?.name);
  }

  get acceptedFileTypes(): string {
    return Utils.getAcceptedFileTypes();
  }
}
