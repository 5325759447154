import { Action, createReducer, on } from '@ngrx/store';
import * as fromEndorsementsActions from '../../store/endorsements/endorsements.actions';
import { initialState, EndorsementsState } from './endorsements.state';

const _endorsementsReducer = createReducer(
  initialState,
  on(
    fromEndorsementsActions.updateEndorsementsIsLoadingFromEndorsementsSavingData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  )
);

export function endorsementsReducer(
  state: EndorsementsState | null,
  action: Action
) {
  return _endorsementsReducer(state, action);
}