import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'disableRemoveSelectedButton'
})
export class DisableRemoveSelectedButtonPipe implements PipeTransform {
  transform(forTransferPolicies: any): boolean {
    if (forTransferPolicies?.length === 0) {
      return true;
    }

    const hasSelectedPolicy = forTransferPolicies?.some((policy: any) => {
      return policy.isSelected === true;
    });

    return !hasSelectedPolicy;
  }
}
