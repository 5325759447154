import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'app/shared/base-class';
import Utils from 'app/shared/utilities/utils';
import { BookTransferData } from 'app/modules/book-transfer/data/book-transfer.data';
import { BookTransferService } from 'app/core/services/book-transfer.service';
import { takeUntil } from 'rxjs/operators';
import { BatchDashboardDTO } from 'app/shared/models/data/dto/book-transfer/batch-dashboard.dto';
import { NavigationService } from 'app/core/services/navigation/navigation.service';
import { BatchTransferSavingDTO } from 'app/shared/models/data/dto/book-transfer/book-transfer-saving.dto';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { NotificationMessages } from 'app/shared/constants/book-transfer.constants';
import { BookTransferConstants } from 'app/shared/constants/book-transfer.constants';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-batch-transfer-dashboard',
  templateUrl: './batch-transfer-dashboard.component.html',
  styleUrls: ['./batch-transfer-dashboard.component.scss']
})
export class BatchTransferDashboardComponent extends BaseClass implements OnInit {
  // resultsPerPage: number = 10; // JPMoscosa - 07/13/2022 - comment out; for Pagination use
  notificationMessages = NotificationMessages;
  bookTransferConstants = BookTransferConstants;
  isSearch: boolean = false;
  searchKeywordValue: string = '';

  constructor(
    public navigationService: NavigationService,
    public bookTransferData: BookTransferData,
    public bookTransferService: BookTransferService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.search) {
        this.searchKeywordValue = params.search;

        this.isSearch = true;
        this.bookTransferData.batchQueueTableRows = [];
        this.searchBatchQueue(this.searchKeywordValue);

        return;
      }

      this.searchKeywordValue = '';
      this.isSearch = false;
      this.getBatchQueue();
    });
  }

  getBatchQueue(): void {
    Utils.blockUI();

    this.bookTransferService.getBatchDashboardData().pipe(takeUntil(this.stop$)).subscribe(result => {
      if (result) {
        this.mapResults(result);
      }
      Utils.unblockUI();
    });
  }

  searchBatchQueue(keyword: string): void {
    Utils.blockUI();

    this.bookTransferService.getBatchDashboardDataByKeyword(keyword).pipe(takeUntil(this.stop$)).subscribe(result => {
      if (result) {
        this.mapResults(result);
      }

      Utils.unblockUI();
    });
  }

  mapResults(result: BatchDashboardDTO[]): void {
    this.bookTransferData.batchQueueTableRows = [];
    this.bookTransferData.addTableItem(result);
  }

  viewBatchDetails(batchId: string): void {
    this.navigationService.navigateTransferPage(batchId);
  }

  editBatchDetails(batchId: string): void {
    this.navigationService.navigateTransferPage(batchId);
  }

  deleteTableItem(batchId: any): void {
    NotifUtils.showConfirmMessage(this.notificationMessages.deleteTransferBatchConfirmation,
      () => {
        Utils.blockUI();

        const deleteModel = new BatchTransferSavingDTO();
        deleteModel.id = batchId;

        this.bookTransferService.deleteBatchById(deleteModel).pipe(takeUntil(this.stop$)).subscribe(result => {
          if (result) {
            this.mapResults(result);
            NotifUtils.showSuccessWithConfirmationFromUser(this.notificationMessages.deleteTransferBatchSuccess);
          }
        });
      }
    );
  }

  searchBatch(keyword: string): void {
    keyword = keyword.trim();

    this.router.navigate(['book-transfer', `batch-dashboard`], { queryParams: { search: keyword } });
  }

  onChangeSearchKeyword(keyword: string): void {
    keyword = keyword.trim();

    if (keyword === '') {
      this.router.navigate(['book-transfer', `batch-dashboard`]);
    }
  }
}


