import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MaintenanceModeConstants } from '../../shared/constants/maintenance-mode.constants';
import { PathConstants } from '../../shared/constants/path.constants';
import { AuthService } from './auth.service';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceModeService {
  isValidRedirect: boolean;
  maintenanceModeMsg: string;
  // Add routes here to include in maintenance mode
  routesUnderMaintenanceMode: string[] = [];
  isMaintenanceMode: boolean = false;
  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected store: Store
  ) { }

  isUnderMaintenance(routePattern: string) {
    return this.routesUnderMaintenanceMode.find(x => x === routePattern) !== undefined;
  }

  setMaintenanceModeMsg(message?: string): void {
    this.maintenanceModeMsg = message;
  }

  getMaintenanceModeMsg(): string {
    return this.maintenanceModeMsg ?? MaintenanceModeConstants.defaultMsg;
  }

  redirectToMaintenanceModePage(maintenanceMsg?: string): void {
    this.isValidRedirect = true;
    this.setMaintenanceModeMsg(maintenanceMsg);
    this.router.navigate([PathConstants.MaintenanceMode.Index]);
  }
}