import { AfterViewInit, Component, HostListener, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { distinctUntilChanged, filter, first, take } from 'rxjs/operators';
import { FormDocumentData } from '../../../../core/services/submission/report/form-document-data.service';
import { SummaryData } from '../../../../modules/submission-management/data/summary.data';
import { PathConstants } from '../../../../shared/constants/path.constants';
import * as _ from 'lodash';
import { SubmissionNavSavingService } from '../../../../core/services/navigation/submission-nav-saving.service';
import { PolicyNavSavingService } from '../../../../core/services/navigation/policy-nav-saving.service';
import NotifUtils from '../../../../shared/utilities/notif-utils';
import { ErrorMessageConstant } from '../../../../shared/constants/error-message.constants';
import { BaseClass } from '../../../../shared/base-class';
import { takeUntil } from 'rxjs/operators';
import Utils from '../../../../shared/utilities/utils';
import { combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { PolicySummaryData } from '../../../../modules/policy-management/data/policy-summary.data';
import { CalculateRaterData } from 'app/core/services/submission/rater-premium/calculate-rater-data.service';
import { ThirdPartyData } from 'app/modules/submission-management/data/third-party.data';
import { ThirdPartyDataService } from '../../../../core/services/submission/third-party/third-party-data.service';
import { BehaviorSubject } from 'rxjs';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { ZipCodeData } from '../../../../modules/submission-management/data/zipcode.data';
import { select, Store } from '@ngrx/store';
import { selectCoveragesIsLoading } from 'app/store/coverages/coverages.selectors';
import { selectEndorsementsIsLoading } from 'app/store/endorsements/endorsements.selectors';
import { selectClaimsIsLoading } from 'app/store/claims/claims.selectors';
import { selectPropertiesIsLoading } from 'app/store/properties/properties.selectors';
import { selectRecalculateValuationIsLoading } from 'app/store/recalculate-valuation/racalculate-valuation.selectors';
import { selectApplicantsIsLoading } from 'app/store/applicants/applicants.selectors';
import { selectUWQuestionsIsLoading } from 'app/store/uw-questions/uw-questions.selectors';
import { selectInterestsIsLoading } from 'app/store/interests/interests.selectors';
import { selectSubmissionUWRsIsLoading } from 'app/store/submission-uwrs/submission-uwrs.selectors';
import { selectRaterIsLoading } from 'app/store/rater/rater.selectors';
import { selectRaterCalculateDP3IsLoading } from 'app/store/rater-calculate-dp3/rater-calculate-dp3.selectors';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';

@Component({
  selector: 'app-next-back-button',
  templateUrl: './next-back-button.component.html',
  styleUrls: ['./next-back-button.component.scss']
})
export class NextBackButtonComponent extends BaseClass implements OnInit, OnChanges, AfterViewInit {
  cardBody: any;
  card: any;
  hasScrollBar: boolean;
  currentPage: string = '';

  @Output() clickType = new EventEmitter();
  @Input() isScrollPage: boolean = true;

  @Input() showNext: boolean = true;
  @Input() showBack: boolean = true;
  @Input() showViewQuote: boolean = false;
  @Input() showEmailQuote: boolean = false;
  @Input() showCalculate: boolean = false;
  @Input() showPropertyServices: boolean = false;
  @Input() showIssue: boolean = false;
  @Input() showReset: boolean = false;
  @Input() showPolicyIssue: boolean = false;
  @Input() showSave: boolean = false;
  @Input() showCancel: boolean = false;
  @Input() showUpdate: boolean = false;
  @Input() showReviseRenewalOffer: boolean = false;

  @Input() disableBack: boolean = false;
  @Input() disableNext: boolean = false;
  @Input() disableViewQuote: boolean = false;
  @Input() disableEmailQuote: boolean = false;
  @Input() disableIssue: boolean = false;
  @Input() disableCalculate: boolean = false;
  @Input() disablePropertyServices: boolean = false;
  @Input() disableReset: boolean = false;
  @Input() disablePolicyIssue: boolean = false;
  @Input() disableSave: boolean = false;
  @Input() disableCancel: boolean = false;
  @Input() disableUpdate: boolean = false;
  @Input() disableReviseRenewalOffer: boolean = false;

  @Input() debounceTime: number = 1000;

  @Input() showReviseRenewalOfferSpinner: boolean = false;

  riskSavingComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input() policyIssueValidationMessage: string;

  selectCoveragesIsLoading$: Observable<boolean>;
  selectEndorsementsIsLoading$: Observable<boolean>;
  selectClaimsIsLoading$: Observable<boolean>;
  selectPropertiesIsLoading$: Observable<boolean>;
  selectRecalculateValuationIsLoading$: Observable<boolean>;
  selectApplicantsIsLoading$: Observable<boolean>;
  selectUWQuestionsIsLoading$: Observable<boolean>;
  selectInterestsIsLoading$: Observable<boolean>;
  selectSubmissionUWRsIsLoading$: Observable<boolean>;
  selectRaterIsLoading$: Observable<boolean>;
  selectRaterCalculateDP3IsLoading$: Observable<boolean>;

  constructor(
    public formDocumentData: FormDocumentData,
    protected summaryData: SummaryData,
    protected router: Router,
    protected submissionNavSavingService: SubmissionNavSavingService,
    protected thirdPartyDataService: ThirdPartyDataService,
    protected raterData: CalculateRaterData,
    protected policySummaryData: PolicySummaryData,
    protected policyNavSavingService: PolicyNavSavingService,
    protected thirdPartyData: ThirdPartyData,
    protected entityRiskData: EntityRiskData,
    public zipCodeData: ZipCodeData,
    public store: Store
  ) {
    super();
    this.selectCoveragesIsLoading$ = this.store.pipe(select(selectCoveragesIsLoading));
    this.selectEndorsementsIsLoading$ = this.store.pipe(select(selectEndorsementsIsLoading));
    this.selectClaimsIsLoading$ = this.store.pipe(select(selectClaimsIsLoading));
    this.selectPropertiesIsLoading$ = this.store.pipe(select(selectPropertiesIsLoading));
    this.selectRecalculateValuationIsLoading$ = this.store.pipe(select(selectRecalculateValuationIsLoading));
    this.selectApplicantsIsLoading$ = this.store.pipe(select(selectApplicantsIsLoading));
    this.selectUWQuestionsIsLoading$ = this.store.pipe(select(selectUWQuestionsIsLoading));
    this.selectInterestsIsLoading$ = this.store.pipe(select(selectInterestsIsLoading));
    this.selectSubmissionUWRsIsLoading$ = this.store.pipe(select(selectSubmissionUWRsIsLoading));
    this.selectRaterIsLoading$ = this.store.pipe(select(selectRaterIsLoading));
    this.selectRaterCalculateDP3IsLoading$ = this.store.pipe(select(selectRaterCalculateDP3IsLoading));
  }

  get isPremiumInvalid(): boolean {
    return this.validatePremium();
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.cardBody = document.getElementById('card-body');

    if (this.cardBody) {
      if (!window.scrollY) {
        this.cardBody.style.background = 'white';
        this.cardBody.style.bottom = '65px';
      }
      this.onScroll();
    }

  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.isScrollPage) {
      this.isScrollPage = simpleChanges.isScrollPage.currentValue;
    }
  }

  @HostListener('window:scroll')
  @HostListener('window:resize', ['$event'])
  @HostListener('window:click')
  onScroll(): void {
    this.cardBody = document.getElementById('card-body');
    this.card = document.getElementById('card');
    if (this.cardBody) {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 8) {
        this.cardBody.style.background = 'white';
        this.cardBody.style.bottom = '65px';
      } else {
        this.cardBody.style.bottom = '0';
      }
    }
  }

  @HostListener('window:click')
  clickEvent(): void {
    setTimeout(() => { this.onScroll(); }, 70);
  }

  onClick(event?) {
    this.clickType.emit(event);
  }

  async viewQuote(): Promise<void> {
    const stop$: Subject<any> = new Subject<any>();

    await this.thirdPartyData.recalculateValuationThenMap();
    Utils.generatingQP();
    Utils.isGeneratingQuoteProposal = true;
    this.raterData.isViewQuoteProposalClicked = true;

    this.currentPage = this.getCurrentCategoryRoute();
    this.submissionCalculatePremiumAndSave();
    this.submissionNavSavingService.propertySavingData.saveProperty();

    this.selectPropertiesIsLoading$
      .pipe(
        filter((value) => {
          return value === false;
        }),
        takeUntil(stop$))
      .subscribe(() => {
        this.checkViewQuoteProposalRequest();

        stop$.next();
        stop$.complete();
      });
  }

  validatePremium(): boolean {
    const estimatedPremium = +this.summaryData?.SummaryForm?.get('estimatedPremium')?.value;
    return estimatedPremium <= 0 ? true : false;
  }

submissionCalculatePremiumAndSave(): void {
    
    switch(this.currentPage) {
      case PathConstants.Submission.Submission.Claims:
        this.raterData.claimsRequest(this.submissionNavSavingService.claimsSavingData.claimsData, this.submissionNavSavingService.entityRiskData.getPolicyNumber());
        this.submissionNavSavingService.claimsSavingData.saveClaims();
        this.thirdPartyData.saveAplusLastCallStatus();
        
        break;
      case PathConstants.Submission.Submission.CoveragesAndDeduction:
        this.raterData.coverageRaterRequest(this.submissionNavSavingService.coveragesData, this.submissionNavSavingService.entityRiskData.getPolicyNumber());
        this.submissionNavSavingService.coveragesData.setHeaderDetails(this.submissionNavSavingService.coveragesData.coveragesAndDeductiblesForm.get('coverageA').valid);
        this.submissionNavSavingService.saveCoverages();
        break;
      case PathConstants.Submission.Submission.Endorsements:
        if (this.submissionNavSavingService.endorsementData.endorsementsGroup.get('premiumAdjustment').value) {
          const minimumEstimatedPremium = this.submissionNavSavingService.endorsementData.getMinimumEstimatedPremium();
          if (minimumEstimatedPremium !== '') {
              Utils.isGeneratingQuoteProposal = false;
              Utils.unblockUI();
              NotifUtils.showError(ErrorMessageConstant.allowedPremiumAdjustmentAmount.replace('{0}', minimumEstimatedPremium));
              this.raterData.recalculatePremiumAndSave$.next(false);
              this.raterData.isViewQuoteProposalClicked = false;
          } else {
            this.raterData.endorsementRaterRequest(this.submissionNavSavingService.endorsementData, this.submissionNavSavingService.entityRiskData.getPolicyNumber());
            this.submissionNavSavingService.endorsementsSavingData.saveEndorsements();
          }
        } else {
          this.raterData.endorsementRaterRequest(this.submissionNavSavingService.endorsementData, this.submissionNavSavingService.entityRiskData.getPolicyNumber());
          this.submissionNavSavingService.endorsementsSavingData.saveEndorsements();
        }
        break;
      case PathConstants.Submission.Submission.Property:
        const riskId = this.summaryData.SummaryForm.get('riskId').value;
        const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;
        this.raterData.propertyRaterRequest(this.submissionNavSavingService.propertySavingData.propertyData, this.submissionNavSavingService.coveragesData, this.submissionNavSavingService.entityRiskData.getPolicyNumber(), this.submissionNavSavingService.propertySavingData.propertyBusinessRules);
        this.submissionNavSavingService.propertySavingData.saveProperty();
        break;
      default:
        this.submissionNavSavingService.saveCurrentCategory(this.currentPage);
        break;
    }
  }

  protected getCurrentCategoryRoute(): string {
    const splitUrl = _.split(this.router.url, '/');
    const quickQuoteRoute = _.indexOf(splitUrl, PathConstants.QuickQuote.Index);
    if (quickQuoteRoute !== -1) {
      return splitUrl[quickQuoteRoute];
    }
    const currentCategoryIndex = splitUrl.length - 1;
    return splitUrl[currentCategoryIndex];
  }

  protected checkViewQuoteProposalRequest(): void {
    const stop$ = new Subject();

    combineLatest([
      this.selectCoveragesIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectEndorsementsIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectClaimsIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectPropertiesIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectRecalculateValuationIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectApplicantsIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectUWQuestionsIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectInterestsIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectSubmissionUWRsIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectRaterIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectRaterCalculateDP3IsLoading$
        .pipe(takeUntil(this.stop$))
    ]).pipe(takeUntil(stop$)).subscribe(result => {
      const selectCoveragesIsLoading$ = result[0];
      const selectEndorsementsIsLoading$ = result[1];
      const selectClaimsIsLoading$ = result[2];
      const selectPropertiesIsLoading$ = result[3];
      const selectRecalculateValuationIsLoading$ = result[4];
      const selectApplicantsIsLoading$ = result[5];
      const selectUWQuestionsIsLoading$ = result[6];
      const selectInterestsIsLoading$ = result[7];
      const selectRaterIsLoading$ = result[9];
      const selectRaterCalculateDP3IsLoading$ = result[10];

      const formType = this.summaryData.SummaryForm.get('formType')?.value;
      const raterIsLoading = formType === FormTypeConstants.DP3 ?
        selectRaterCalculateDP3IsLoading$ :
        selectRaterIsLoading$;

      switch(this.currentPage) {
        case PathConstants.Submission.Submission.CoveragesAndDeduction:
          if (selectCoveragesIsLoading$ === false && raterIsLoading === false) {
            this.formDocumentData.viewQuoteProposalRequest();
            this.completeViewQuoteProposalRequest(stop$);
          }
          break;
        case PathConstants.Submission.Submission.Endorsements:
          if (selectCoveragesIsLoading$ === false && selectEndorsementsIsLoading$ === false && raterIsLoading === false) {
            this.formDocumentData.viewQuoteProposalRequest();
            this.completeViewQuoteProposalRequest(stop$);
          }
          break;
        case PathConstants.Submission.Submission.Claims:
          if (selectClaimsIsLoading$ === false && raterIsLoading === false) {
            this.formDocumentData.viewQuoteProposalRequest();
            this.completeViewQuoteProposalRequest(stop$);
          }
          break;
        case PathConstants.Submission.Submission.Property:
          const status: boolean = formType === FormTypeConstants.HO4 ?
            (selectPropertiesIsLoading$ === false && raterIsLoading === false) :
            (selectPropertiesIsLoading$ === false && selectRecalculateValuationIsLoading$ === false && raterIsLoading === false);

          if (status) {
            this.formDocumentData.viewQuoteProposalRequest();
            this.completeViewQuoteProposalRequest(stop$);
          }
          break;
        case PathConstants.Submission.Submission.ApplicantPage:
          if (selectCoveragesIsLoading$ === false && selectApplicantsIsLoading$ === false && raterIsLoading === false) {
            this.formDocumentData.viewQuoteProposalRequest();
            this.completeViewQuoteProposalRequest(stop$);
          }
          break;
        case PathConstants.Submission.Submission.UWQuestions:
          if (selectUWQuestionsIsLoading$ === false) {
            this.formDocumentData.viewQuoteProposalRequest();
            this.completeViewQuoteProposalRequest(stop$);
          }
          break;
        case PathConstants.Submission.Submission.Interests:
          if (selectInterestsIsLoading$ === false) {
            this.formDocumentData.viewQuoteProposalRequest();
            this.completeViewQuoteProposalRequest(stop$);
          }
          break;
        case PathConstants.Submission.Submission.UWApproval:
          this.formDocumentData.viewQuoteProposalRequest();
          this.completeViewQuoteProposalRequest(stop$);
          break;
      }
    });
  }

  protected completeViewQuoteProposalRequest(stop$: Subject<any>): void {
    stop$.next();
    stop$.complete();
  }
}

