import { Action, createReducer, on } from '@ngrx/store';
import * as fromClaimsActions from '../../store/claims/claims.actions';
import { initialState, ClaimsState } from './claims.state';

const _claimsReducer = createReducer(
  initialState,
  on(
    fromClaimsActions.updateClaimsIsLoadingFromClaimsSavingData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromClaimsActions.updateClaimsListFromClaimsSavingData,
    (state, { list }) => ({ ...state, list: list })
  ),
  on(
    fromClaimsActions.updateClaimsIsLoadingFromClaimsComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromClaimsActions.updateClaimsListFromClaimsComponent,
    (state, { list }) => ({ ...state, list: list })
  ),
  on(
    fromClaimsActions.updateClaimsListFromSubmissionManagementComponent,
    (state, { list }) => ({ ...state, list: list })
  ),
  on(
    fromClaimsActions.updateClaimsListFromPolicyManagementComponent,
    (state, { list }) => ({ ...state, list: list })
  ),
  on(
    fromClaimsActions.updateClaimsListFromClaimsData,
    (state, { list }) => ({ ...state, list: list })
  )
);

export function claimsReducer(
  state: ClaimsState | null,
  action: Action
) {
  return _claimsReducer(state, action);
}