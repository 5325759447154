import { Component, OnInit } from '@angular/core';
import { GenericLabel } from 'app/shared/constants/generic.labels.constants';
import { ApplicantLabelsConstants } from 'app/shared/constants/applicant.labels.constants';
import { ApplicantData } from '../../data/applicant.data';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { AuthService } from 'app/core/services/auth.service';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { LA_PolicyManagementComponent } from 'app/states/la/policy-management/policy-management.component';

@Component({
  selector: 'app-applicant-inspection',
  templateUrl: './applicant-inspection.component.html',
  styleUrls: ['./applicant-inspection.component.scss']
})
export class ApplicantInspectionComponent implements OnInit {
  public isInspectionHidden: boolean = false;
  public LabelMessageConstant = ApplicantLabelsConstants;
  GenericLabel = GenericLabel;
  
  constructor(public applicantData: ApplicantData
            , public authService: AuthService
            , public policySummaryData: PolicySummaryData
            ,){ }

  ngOnInit() {
  }

  public ToggleHidingOfInspection(): void {
    this.isInspectionHidden = !this.isInspectionHidden;
  }

  public onValueChangeInspectionOrdered(): void {

    if (this.applicantData.inspection.get('inspectionOrdered').value == false) {
      NotifUtils.showConfirmMessage(ErrorMessageConstant.inspectionOrderingErrorMessage.isInspectionOrderedSetToNo, () => {
        // this.applicantData.inspection = this.applicantData.setInspectionSectionFormGroupValues();
        this.applicantData.inspection.get('inspectionOrderDate').setValue('');
        this.applicantData.inspection.get('inspectionType').setValue('');
        if (this.applicantData.inspectionOrdering?.isOrdered === true)
          this.applicantData.inspection.markAsDirty();
      }, () => {
        this.applicantData.inspection.get('inspectionOrdered').setValue(true);
      });

    }
  }


  public onValueChangeInspectionReceived(): void {
    if (this.applicantData.inspection.get('inspectionReceived').value == false) {
      NotifUtils.showConfirmMessage(ErrorMessageConstant.inspectionOrderingErrorMessage.isInspectionReceivedSetToNo, () => {
        this.applicantData.inspection.get('inspectionReceiptDate').setValue('');
      }, () => {
        this.applicantData.inspection.get('inspectionReceived').setValue(true);
      });
    }
  }

}
