import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UWApprovalPageLabel } from 'app/shared/constants/uw-approval.labels.constants';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { UWApprovalModalModel } from 'app/shared/models/submission/uw-approval/uw-approval.model';
import { BsModalRef } from 'ngx-bootstrap';
import { UwApprovalData } from '../../../data/uw-approval.data';
import { AuthService } from '../../../../../core/services/auth.service';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { SubmissionPageData } from 'app/modules/submission-management/data/submission-page.data';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';

@Component({
  selector: 'app-uw-approval-page-modal',
  templateUrl: './uw-approval-page-modal.component.html',
  styleUrls: ['./uw-approval-page-modal.component.scss'],
})
export class UwApprovalPageModalComponent implements OnInit {
  public genericConstants = GenericConstants;
  public event: EventEmitter<any> = new EventEmitter<any>();
  public ErrorMessageConstant = ErrorMessageConstant;
  public UWApprovalLabel = UWApprovalPageLabel;
  uwApprovalForm: FormGroup;
  modalRef: BsModalRef | null;
  title: any;
  uwApprovalModel: UWApprovalModalModel;
  uwApprovalModalData: UWApprovalModalModel[];
  isAdd: boolean;
  indexToUpdate: number;
  isCancel: boolean = false;
  statusOptions: any;
  riskId: string;

  constructor(
    protected fb: FormBuilder,
    public bsModalRef: BsModalRef,
    public uwApprovalData: UwApprovalData,
    protected authService: AuthService,
    public submissionData: SubmissionPageData,
    public policySummaryData: PolicySummaryData
  ) { }

  ngOnInit() {
    this.initForm();
    this.disableFieldByUserType();
  }

  initForm(): void {
    this.uwApprovalForm = this.fb.group({
      description: [{ value: this.isAdd ? this.UWApprovalLabel.miscellaneous : this.uwApprovalModel?.description, disabled: true}],
      status: [this.uwApprovalModel?.status, [Validators.required]],
      uwComment: [this.uwApprovalModel?.uwComment],
      agentComment: [this.uwApprovalModel?.agentComment],
    });

    this.statusOptions = this.uwApprovalData.statusOptions.filter(status => status.code !== 'Pending');
  }

  disableFieldByUserType(): void {
    if (this.authService.userType.value === this.genericConstants.userType.internal) {
      this.uwApprovalForm.get('status').enable();
      this.uwApprovalForm.get('uwComment').enable();
      this.uwApprovalForm.get('agentComment').disable();
    } else {
      this.uwApprovalForm.get('status').disable();
      this.uwApprovalForm.get('agentComment').enable();
      this.uwApprovalForm.get('uwComment').disable();
    }
  }

  hideModal(): void {
    this.isCancel = true;
    this.bsModalRef.hide();
  }

  onSubmit(uwApproval: UWApprovalModalModel) {
    if (!this.isCancel) {
      uwApproval.id = this.isAdd
        ? this.uwApprovalData.uwApprovalTableRows.length
        : (uwApproval.id = this.indexToUpdate);
      uwApproval.status = this.uwApprovalForm.get('status').value;
      uwApproval.description = this.uwApprovalForm.get('description').value;
      uwApproval.uwComment = this.uwApprovalForm.get('uwComment').value;
      uwApproval.agentComment = this.uwApprovalForm.get('agentComment').value;

      this.event.emit(uwApproval);
    }

    this.bsModalRef.hide();
  }
}
