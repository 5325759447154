import { createAction, props } from '@ngrx/store';

export const updateChangesIsLoadingFromPolicyHistoryData = createAction(
  '[PolicyHistoryData] Update Changes Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateChangesListFromPolicyHistoryData = createAction(
  '[PolicyHistoryData] Update Changes List',
  props<{ list: string[] }>()
);

export const updateChangesIsLoadingFromPolicyIssueComponent = createAction(
  '[PolicyIssueComponent] Update Changes Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateChangesListFromPolicyIssueComponent = createAction(
  '[PolicyIssueComponent] Update Changes List',
  props<{ list: string[] }>()
);

export const updateChangesIsLoadingFromPolicyHistoryComponent = createAction(
  '[PolicyHistoryComponent] Update Changes Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateChangesListFromPolicyHistoryComponent = createAction(
  '[PolicyHistoryComponent] Update Changes List',
  props<{ list: string[] }>()
);

export const updateChangesIsLoadingFromPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update Changes Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateChangesListFromPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update Changes List',
  props<{ list: string[] }>()
);

export const updateChangesIsLoadingFromQuickQuoteData = createAction(
  '[QuickQuoteData] Update Changes Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateChangesListFromQuickQuoteData = createAction(
  '[QuickQuoteData] Update Changes List',
  props<{ list: string[] }>()
);

export const updateChangesIsLoadingFromSubmissionManagementComponent = createAction(
  '[SubmissionManagementComponent] Update Changes Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateChangesListFromSubmissionManagementComponent = createAction(
  '[SubmissionManagementComponent] Update Changes List',
  props<{ list: string[] }>()
);

export const updateChangesIsLoadingFromUwApprovalComponent = createAction(
  '[UwApprovalComponent] Update Changes Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateChangesListFromUwApprovalComponent = createAction(
  '[UwApprovalComponent] Update Changes List',
  props<{ list: string[] }>()
);

export const updateChangesIsLoadingFromPolicyEditCancelButtonComponent = createAction(
  '[PolicyEditCancelButtonComponent] Update Changes Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateChangesListFromPolicyEditCancelButtonComponent = createAction(
  '[PolicyEditCancelButtonComponent] Update Changes List',
  props<{ list: string[] }>()
);

export const updateChangesPolicyChangeUserFromPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update Changes Policy Change User',
  props<{ policyChangeUser: any }>()
);

export const updateChangesEffectiveDateUserFromPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update Changes Effective Date User',
  props<{ effectiveDateUser: any }>()
);

export const updateChangesListFromIssuePolicyComponent = createAction(
  '[IssuePolicyComponent] Update Changes List',
  props<{ list: string[] }>()
);