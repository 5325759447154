import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentsLabelConstants } from '../../../../../../shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from '../../../../../../shared/constants/error-message.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BindAndIssueData } from '../../../../../../modules/submission-management/data/bind-and-issue.data';
import { GenericLabel } from '../../../../../../shared/constants/generic.labels.constants';
import { CustomValidators } from '../../../../../../shared/validators/custom.validator';
import { QuickQuoteService } from 'app/core/services/submission/quick-quote.service';
import { RiskBindDTO, RiskBindDTO2 } from 'app/shared/models/data/dto/quick-quote/risk-bind-dto';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { RiskBindDocumentDTO } from 'app/shared/models/data/dto/quick-quote/risk-bind-document.dto';
import { serialize } from 'object-to-formdata';
import Utils from 'app/shared/utilities/utils';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { EntityRiskData } from '../../../../../../modules/submission-management/data/entity-risk.data';

@Component({
  selector: 'app-documents-modal',
  templateUrl: './documents-modal.component.html',
  styleUrls: ['./documents-modal.component.scss']
})
export class DocumentsModalComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter<any>();
  public ErrorMessageConstant = ErrorMessageConstant;
  public DocumentsLabelConstants = DocumentsLabelConstants;
  public genericConstants = GenericConstants;
  public Utils = Utils;

  GenericLabel = GenericLabel;
  documentsForm: FormGroup;
  modalRef: BsModalRef | null;
  title: any;
  documentsModel: any;
  isAdd: boolean;
  isCancel: boolean = false;
  idForSaving: any;
  fileToUpload: File = null;
  fileLabel: string;
  fileSizeExceeds: boolean = false;

  constructor(
    protected fb: FormBuilder,
    protected modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public bindAndIssueData: BindAndIssueData,
    public quickQuoteService: QuickQuoteService,
    public summaryData: SummaryData,
    protected entityRiskData: EntityRiskData) { }

  ngOnInit() {
    const currentDate = this.summaryData.authService.getCustomDate();
    this.documentsForm = this.fb.group({
      id: [this.documentsModel?.id],
      category: [this.documentsModel?.category, [Validators.required]],
      description: [this.documentsModel?.description, [Validators.required, CustomValidators.spaceValidator]],
      dateAdded: [this.documentsModel?.dateAdded ? this.documentsModel?.dateAdded.singleDate ? this.documentsModel?.dateAdded
        : this.getFromApiDate(this.documentsModel?.dateAdded)
        : {
            singleDate: {
              date: {
                year: currentDate.getFullYear(),
                month: currentDate.getMonth() + 1,
                day: currentDate.getDate()
              },
              formatted: `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`,
              jsDate: currentDate
            }
          }, [Validators.required]],
      file: ['', [Validators.required, CustomValidators.requiredFileFormat(this.genericConstants.acceptedFileTypes)]],
      fileName: [this.documentsModel?.fileName],
      filePath: [this.documentsModel?.filePath]
    });

    this.mapFile(this.documentsModel?.fileName);
    // this.setDisable();
  }

  mapFile(file?) {
    this.documentsForm.get('file').setValue(file);
    if (file?.name) {
      this.fileLabel = file.name;
    } else if (file) {
      this.fileLabel = file;
    } else {
      this.fileLabel = 'No File Chosen';
    }
  }

  getFromApiDate(date?) {
    const new_date = new Date(date);
    return {
      singleDate: {
        date: {
          year: new_date.getFullYear(),
          month: new_date.getMonth() + 1,
          day: new_date.getDate()
        },
        formatted: `${new_date.getMonth() + 1}/${new_date.getDate()}/${new_date.getFullYear()}`,
        jsDate: date
      }
    };
  }

  setDisable() {
    if (this.documentsModel?.isEdit) {
      this.documentsForm.get('category').disable();
    }
  }

  hideMyModal(): void {
    this.isCancel = true;
    this.bsModalRef.hide();
  }

  onSubmit() {
    if (!this.isCancel) {
      const riskId = this.summaryData.SummaryForm.get('riskId').value;
      const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;

      const bindDocumentrequest = {
        riskId: riskId,
        riskDetailId: riskDetailId,
        riskBind: new RiskBindDTO()
      };
      const riskBindId = this.entityRiskData.getRiskBindId();
      const bindDocument: RiskBindDocumentDTO = {
        id: this.documentsForm.get('id')?.value,
        riskBindId: riskBindId,
        category: this.documentsForm.get('category')?.value,
        description: this.documentsForm.get('description')?.value,
        fileName: this.fileToUpload ? this.fileToUpload : this.documentsForm.get('file').value,
        File: this.fileToUpload ? this.fileToUpload : this.documentsForm.get('file').value,
        filePath: this.documentsForm.get('filePath').value,
        isUploaded: false
      };
      bindDocumentrequest.riskBind.id = riskBindId;
      bindDocumentrequest.riskBind.riskDetailId = riskDetailId;
      bindDocumentrequest.riskBind.riskBindDocuments = [];
      bindDocumentrequest.riskBind.riskBindDocuments.push(bindDocument);

      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
        allowEmptyArrays: true,
      };

      const serializedData = serialize(bindDocumentrequest, options);
      serializedData.append(`riskBind.riskBindDocuments[0].file`,this.fileToUpload ? this.fileToUpload : this.documentsForm.get('file').value);
      Utils.blockUI();
      this.quickQuoteService.postBindDocument(serializedData).subscribe(res => {
        const response = <RiskBindDTO2>res;
        // register response id to table
        const data = {
          id: response.riskBindDocument.id,
          category: this.documentsForm.get('category')?.value,
          description: this.documentsForm.get('description')?.value,
          dateAdded: this.documentsForm.get('dateAdded')?.value,
          file: this.fileToUpload ? this.fileToUpload : this.documentsForm.get('file').value,
          fileName: this.fileToUpload ? this.fileToUpload : this.documentsForm.get('file').value,
          riskBindId: response.riskBindDocument.riskBindId,
          documentsId: null,
          isUploaded: response.riskBindDocument.isUploaded,
          filePath: response.riskBindDocument.filePath,
          createdBy: response.riskBindDocument.createdBy
        };
        Utils.unblockUI();
        this.event.emit({ data: data, res: 200 });
      },
        err => {
          Utils.unblockUI();
          console.log(err);
        }
      );
      this.bsModalRef.hide();
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.fileLabel = this.fileToUpload.name;
    this.documentsForm.get('file').setValue(this.fileToUpload);
    this.fileSizeExceeds = false;

    if (this.documentsForm.get('file').valid) {
      this.fileSizeExceeds = this.fileToUpload?.size > this.genericConstants.fileSizeLimit ? true : false;
    }
  }

  get invalidFileTypeMessage(): string {
    return ErrorMessageConstant.documentsErrorMessage.invalidFileTypeErrorMessage.replace('{0}', this.documentsForm.get('file').value?.name);
  }

  get acceptedFileTypes(): string {
    return Utils.getAcceptedFileTypes();
  }
}
