import { createSelector } from '@ngrx/store';
import { BaseState } from '../base.state';

export const selectPolicyState = (state: BaseState) => state.policy;

export const selectPolicyIsLoading = createSelector(
  selectPolicyState,
  (state) => state.isLoading
);

export const selectPolicyStatus = createSelector(
  selectPolicyState,
  (state) => state.status
);

export const selectPolicySubStatus = createSelector(
  selectPolicyState,
  (state) => state.subStatus
);

export const selectIsLoadingOos = createSelector(
  selectPolicyState,
  (state) => state.isLoadingOos
);

export const selectEntityFromPolicyBillingDataIsLoading = createSelector(
  selectPolicyState,
  (state) => state.isLoading
);
