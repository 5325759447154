import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { getCurrentMonth } from 'app/shared/helpers/date-helper';
import { ReportsService } from 'app/core/services/reports/reports.service';
import { YearConstant } from 'app/shared/constants/year.constants';
import { getYearRange } from 'app/shared/helpers/year-range-helper';
import Utils from 'app/shared/utilities/utils';
import NotifUtils from 'app/shared/utilities/notif-utils';

@Component({
  selector: 'app-customer-service',
  templateUrl: './customer-service.component.html',
  styleUrls: ['./customer-service.component.scss']
})
export class CustomerServiceComponent implements OnInit {

  customerServiceFormGroup: FormGroup = new FormGroup({
    yearForm: new FormControl('', [Validators.required]),
  });
  year: number = new Date().getFullYear();
  LvYear: number[] = getYearRange();

  constructor(private reportService: ReportsService) { }

  ngOnInit() {
    this.initializations();
  }

  //#region Component Function  Helpers
    initializations(){
      this.setFormValues();
      this.setYear();
     }
  //#endregion


  //#region Form Manipulations
  setFormValues(){
    this.customerServiceFormGroup.controls['yearForm'].setValue(this.year);
  }

  setYear() {
    this.year = this.customerServiceFormGroup.controls['yearForm'].value;
  }

  viewReport(){
    this.getCS1MonthlyTransaction(this.year)
  }
  //#endregion

  //#region API Integrations
  getCS1MonthlyTransaction(year){
    let currentMonth = getCurrentMonth() + 1;
    Utils.blockUI();
    this.reportService.getCS1MonthlyTransactionReport(year).subscribe(result => {
      var link = document.createElement('a');
      link.setAttribute("type", "hidden");
      link.download = `CS1_MonthlyTransaction_${currentMonth}_${year}.xlsx`;
      link.href = window.URL.createObjectURL(result);
      document.body.appendChild(link);
      link.click();
      Utils.unblockUI();
    },error => {
      Utils.unblockUI();
      NotifUtils.showError("Report is not available.");
    })
  }
  //#endregion

}
