export const CommissionConstant = {
  Commissions: 'Commissions',
  Month: 'Month',
  Year: 'Year',
  PreviewACHFile: 'Preview ACH File',
  GenerateACHFile: 'Generate ACH File',
  PreviewCommissionStatement: 'Preview Commission Statements',
  EmailCommissionStatement: 'Email Commission Statements',
  Exit: 'Exit',
  AchFile: 'ACH File',
  CommStatements: 'Commission Statements',
  Preview: 'Preview',
  Generate: 'Generate',
  Open: 'Open',
  Email: 'Email',
  achConfirmationMessage: 'Are you sure, you want to generate ACH for',
  achFileSuccessfullyGenerated: 'ACH File successfully generated.',
  achInfoMessage: 'Agency commission statement is not yet ready. Please try again later.',
  previewCommissionFileSuccessfullyGenerated: 'Preview Commission files successfully generated',
  commissionFileSuccessfullyGenerated: 'Commission files successfully generated',
  downloadCommissionFileSuccess: 'Commission files download is complete',
  commissionPayment: 'Commission Payment',
  agency: 'Agency',
  state: 'State',
  bank: 'Bank',
  routingType: 'Routing Type',
  LvCommissionState: [
    'FL',
    'LA'
  ],
  LvCommissionBank: [
    'Hancock',
    'BMO'
  ],
  LvCommissionRoutingType: [
    'CSIC',
    'CNIC'
  ]
};

export const CommissionErrorConstant = {
  invalidDate: 'Invalid Date. Selected date overlap in other setup.',
  invalidDateRange: 'Invalid date range.'
};