export class TaskPolicySearchResultDTO implements ITaskPolicySearchResultDTO {
  riskId?: string;
  riskDetailId?: string;
  entityId?: string;
  policyNumber?: string;
  quoteNumber?: string;
  quoteNumberSiebel?: string;
  insuredName?: string;
  property?: string;
  stateCode?: string;
  isEndorsement?: boolean;
  riskProcessDate?: Date;
}

export interface ITaskPolicySearchResultDTO {
  riskId?: string;
  riskDetailId?: string;
  entityId?: string;
  policyNumber?: string;
  quoteNumber?: string;
  quoteNumberSiebel?: string;
  insuredName?: string;
  property?: string;
  stateCode?: string;
  isEndorsement?: boolean;
  riskProcessDate?: Date;
}

export class TaskPolicySearchRequestDTO implements ITaskPolicySearchRequestDTO {
  searchValue?: string;
  searchBy?: string;
  pageNumber?: number;
  pageSize?: number;
  entityId?: string;
}
export interface ITaskPolicySearchRequestDTO {
  searchValue?: string;
  searchBy?: string;
  pageNumber?: number;
  pageSize?: number;
  entityId?: string;
}

export class TaskPostRequestDTO implements ITaskPostRequestDTO {
  taskId?: string | undefined;
  task?: TaskDTO | undefined;
}

export interface ITaskPostRequestDTO {
  taskId?: string | undefined;
  task?: TaskDTO | undefined;
}

export class TaskPostResultDTO implements ITaskPostResultDTO {
  taskId?: string | undefined;
}
export interface ITaskPostResultDTO {
  taskId?: string | undefined;
}
export class TaskPaginationResultDTO implements ITaskPaginationResultDTO {
  currentPage?: number | undefined;
  firstPage?: number | undefined;
  lastPage?: number | undefined;
  previousPage?: number | undefined;
  totalCount?:  number | undefined;
  totalPages?: number | undefined;
}

export interface ITaskPaginationResultDTO  {
  currentPage?: number | undefined;
  firstPage?: number | undefined;
  lastPage?: number | undefined;
  previousPage?: number | undefined;
  totalCount?:  number | undefined;
  totalPages?: number | undefined;
}

export class TaskDashboardResultDTO implements ITaskDashboardResultDTO {
  paginationDetails: TaskPaginationResultDTO | undefined;
  resultList: TaskListResultDTO[] | undefined;
}

export interface ITaskDashboardResultDTO {
  paginationDetails: TaskPaginationResultDTO | undefined;
  resultList: TaskListResultDTO[] | undefined;
}

export class TaskSearchResultDTO implements ITaskSearchResultDTO {
  paginationDetails: TaskPaginationResultDTO | undefined;
  resultList: TaskPolicySearchResultDTO[] | undefined;
}

export interface ITaskSearchResultDTO {
  paginationDetails: TaskPaginationResultDTO | undefined;
  resultList: TaskPolicySearchResultDTO[] | undefined;
}

export class TaskDTO implements ITaskDTO {
  id?: string | undefined;
  policyNumber?: string | undefined;
  quoteNumber?: string | undefined;
  entityId?: string | undefined;
  typeCode?: string | undefined;
  taskSubTypeCode?: string | undefined;
  taskContactTypeCode?: string | undefined;
  taskCategoryCode?: string | undefined;
  taskDescriptionCode?: string | undefined;
  taskOwnerCode?: string | undefined;
  taskStatusCode?: string | undefined;
  contactFullName?: string | undefined;
  priority?: string | undefined;
  dueDate?: string | undefined;
  otherDescription?: string | undefined;
  taskNotes: TaskNotesDTO[] | undefined;
  taskDocuments: TaskDocumentsDTO[] | undefined;
  stateCode?: string | undefined;
  taskOwnerUserId?: number | undefined;
  taskOwnerAgentId? : number | undefined;
  createdBy?: number | undefined;
  fullName?: string | undefined;
  isSystem?: boolean | undefined;
}

export interface ITaskDTO {
  id?: string | undefined;
  policyNumber?: string | undefined;
  quoteNumber?: string | undefined;
  entityId?: string | undefined;
  typeCode?: string | undefined;
  taskSubTypeCode?: string | undefined;
  taskContactTypeCode?: string | undefined;
  taskCategoryCode?: string | undefined;
  taskDescriptionCode?: string | undefined;
  taskOwnerCode?: string | undefined;
  taskStatusCode?: string | undefined;
  contactFullName?: string | undefined;
  priority?: string | undefined;
  dueDate?: string | undefined;
  otherDescription?: string | undefined;
  taskNotes: TaskNotesDTO[] | undefined;
  taskDocuments: TaskDocumentsDTO[] | undefined;
  stateCode?: string | undefined;
  taskOwnerUserId?: number | undefined;
  taskOwnerAgentId? : number | undefined;
  createdBy?: number | undefined;
  fullName?: string | undefined;
  isSystem?: boolean | undefined;
}

export class TaskNotesDTO implements ITaskNotesDTO {
  id?: string | undefined;
  initialId?: number | undefined;
  taskId?: string | undefined;
  taskContactTypeCode?: string | undefined;
  contactFullName?: string | undefined;
  description?: string | undefined;
  createdBy?: number| undefined;
  createdDate?: Date| undefined;
  isActive?: boolean;
  isEditable?: boolean;
  isDeletable?: boolean;
  createdByFullName?: string | undefined;
  createdDateProper?: Date| undefined;
}

export interface ITaskNotesDTO {
  id?: string | undefined;
  initialId?: number | undefined;
  taskId?: string | undefined;
  taskContactTypeCode?: string | undefined;
  contactFullName?: string | undefined;
  description?: string | undefined;
  createdBy?: number| undefined;
  createdDate?: Date| undefined;
  isActive?: boolean;
  isEditable?: boolean;
  isDeletable?: boolean;
  createdByFullName?: string | undefined;
  createdDateProper?: Date| undefined;
}

export class TaskDocumentsDTO implements ITaskDocumentsDTO {
  id?: string | undefined;
  initialId?: number | undefined;
  taskId?: string | undefined;
  taskDocType?: string | undefined;
  taskDocStatus?: string | undefined;
  description?: string | undefined;
  fileName?: string | undefined;
  filePath?: string | undefined;
  createdBy?: number;
  createdDate?: Date | undefined;
  isActive?: boolean;
  isEditable?: boolean;
  isDeletable?: boolean;
  createdByFullName?: string | undefined;
  createdDateProper?: Date | undefined;
}

export interface ITaskDocumentsDTO {
  id?: string | undefined;
  initialId?: number | undefined;
  taskId?: string | undefined;
  taskDocType?: string | undefined;
  taskDocStatus?: string | undefined;
  description?: string | undefined;
  fileName?: string | undefined;
  filePath?: string | undefined;
  createdBy?: number;
  createdDate?: Date | undefined;
  isActive?: boolean;
  isEditable?: boolean;
  isDeletable?: boolean;
  createdByFullName?: string | undefined;
  createdDateProper?: Date | undefined;
}

export class TaskDocumentRequestDTO implements ITaskDocumentRequestDTO {
  riskId: string | undefined;
  riskDetailId: string | undefined;
  taskId: string | undefined;
  stateCode: string | undefined;
  file: any;
  fileName: string | undefined;
}

export interface ITaskDocumentRequestDTO {
  riskId: string | undefined;
  riskDetailId: string | undefined;
  taskId: string | undefined;
  stateCode: string | undefined;
  file: any;
  fileName: string | undefined;
}

export class TaskDocumentResultDTO implements ITaskDocumentResultDTO {
  filePath: string | undefined;
  fileName: string | undefined;
  createdDate: Date | undefined;
  createdBy: number | undefined;
}

export interface ITaskDocumentResultDTO {
  filePath: string | undefined;
  fileName: string | undefined;
  createdDate: Date | undefined;
  createdBy: number | undefined;
}

export class TaskListRequestDTO implements ITaskListRequestDTO {
  isMyTask?: boolean = true;
  status?: string | undefined;
  priority?: string | undefined;
  policyNumber?: string | undefined;
  owner?: string | undefined;
  dueDate?: Date | undefined;
  category?: string | undefined;
  search?: string | undefined;
  userType?: string | undefined;
  dueDateFrom?: string | undefined;
  dueDateTo?: string | undefined;
  isFromPolicyRequest?: boolean;
  pageNumber?: number;
  pageSize?: number;
  sortField?: string;
  order: string;
  agentId?: string;
}
export interface ITaskListRequestDTO {
  isMyTask?: boolean;
  status?: string | undefined;
  priority?: string | undefined;
  policyNumber?: string | undefined;
  owner?: string | undefined;
  dueDate?: Date | undefined;
  category?: string | undefined;
  search?: string | undefined;
  userType?: string | undefined;
  dueDateFrom?: string | undefined;
  dueDateTo?: string | undefined;
  isFromPolicyRequest?: boolean;
  pageNumber?: number;
  pageSize?: number;
  sortField?: string;
  order?: string;
  agentId?: string;
  description?: string;
  stateCode?: string;
}

export class TaskListResultDTO implements ITaskListResultDTO {
  taskId?: string | undefined;
  dateAdded?: Date | undefined;
  owner?: string | undefined;
  fullName?: string | undefined;
  category?: string | undefined;
  dueDate?: Date | undefined;
  priority?: string | undefined;
  taskStatus?: string | undefined;
  insuredName?: string | undefined;
  policyNumber?: string | undefined;
  riskStatus?: string | undefined;
  effectiveDate?: Date | undefined;
  description?: string | undefined;
  fullDescription?: string | undefined;
  fullOwner?: string | undefined;
  fullCategory?: string | undefined;
  fullTaskStatus?: string | undefined;
  fullRiskStatus?: string | undefined;
  riskId?: string | undefined;
  riskDetailId?: string | undefined;
  isEndorsement?: boolean | undefined;
  dateAddedProper?: Date | undefined;
  taskOwnerUserId?: string | undefined;
  ownerProper?: string | undefined;
}
export interface ITaskListResultDTO {
  taskId?: string | undefined;
  dateAdded?: Date | undefined;
  owner?: string | undefined;
  fullName?: string | undefined;
  category?: string | undefined;
  dueDate?: Date | undefined;
  priority?: string | undefined;
  taskStatus?: string | undefined;
  insuredName?: string | undefined;
  policyNumber?: string | undefined;
  riskStatus?: string | undefined;
  effectiveDate?: Date | undefined;
  description?: string | undefined;
  fullDescription?: string | undefined;
  fullOwner?: string | undefined;
  fullCategory?: string | undefined;
  fullTaskStatus?: string | undefined;
  fullRiskStatus?: string | undefined;
  riskId?: string | undefined;
  riskDetailId?: string | undefined;
  isEndorsement?: boolean | undefined;
  isSystem?: boolean | undefined;
  dateAddedProper?: Date | undefined;
  taskOwnerUserId?: string | undefined;
  ownerProper?: string | undefined;
}

export class TaskHistoryDTO implements ITaskHistoryDTO {
  id?: string | undefined;
  taskId?: string | undefined;
  actionCode?: string | undefined;
  property?: string | undefined;
  previous?: string | undefined;
  new?: string | undefined;
  createdBy?: number | undefined;
  createdDate?: Date | undefined;
  isActive?: boolean | undefined;
  createdByFullName?: string | undefined;
  createdDateProper?: Date | undefined;
  previousFullname?: string | undefined;
  newFullname?: string | undefined;
}

export interface ITaskHistoryDTO {
  id?: string | undefined;
  taskId?: string | undefined;
  actionCode?: string | undefined;
  property?: string | undefined;
  previous?: string | undefined;
  new?: string | undefined;
  createdBy?: number | undefined;
  createdDate?: Date | undefined;
  isActive?: boolean | undefined;
  createdByFullName?: string | undefined;
  createdDateProper?: Date | undefined;
  previousFullname?: string | undefined;
  newFullname?: string | undefined;
}

export interface ITasksRequestDTO {
  entityId?: string;
  taskDescriptionCodes?:  Array<string> | undefined;
  taskStatusCode?: string;
}

export class TasksRequestDTO implements ITasksRequestDTO {
  entityId?: string;
  taskDescriptionCodes?:  Array<string> | undefined;
  taskStatusCode?: string;
}
export interface ITasksRequestDTO {
  entityId?: string;
  taskDescriptionCodes?:  Array<string> | undefined;
  taskStatusCode?: string;
}
