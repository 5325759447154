
export class RiskUWQuestionDTO implements IRiskUWQuestionDTO {
    id?: string | undefined;
    riskDetailId?: string | undefined;
    uwQuestionCode?: string | undefined;
    uwQuestionValue?: string | undefined;
    createdBy?: number | undefined;
    createdByFullName?: string | undefined;
    createdDate?: Date | undefined;
    readonly isActive?: boolean;
}

export interface IRiskUWQuestionDTO {
    id?: string | undefined;
    riskDetailId?: string | undefined;
    uwQuestionCode?: string | undefined;
    uwQuestionValue?: string | undefined;
    createdBy?: number | undefined;
    createdByFullName?: string | undefined;
    createdDate?: Date | undefined;
    isActive?: boolean;
}
export class RiskUWQuestionDTO2 implements IRiskUWQuestionDTO2 {
    id?: string | undefined;
    riskId?: string | undefined;
    uwQuestionCode?: string | undefined;

    constructor(data?: IRiskUWQuestionDTO2) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }
}

export interface IRiskUWQuestionDTO2 {
    id?: string | undefined;
    riskId?: string | undefined;
    uwQuestionCode?: string | undefined;
}
