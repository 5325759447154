import { Component, OnInit } from '@angular/core';
import { SubmissionPageData } from '../../data/submission-page.data';
import { ErrorMessageConstant } from '../../../../shared/constants/error-message.constants';
import { ApplicantLabelsConstants } from 'app/shared/constants/applicant.labels.constants';
import { ApplicantData } from '../../data/applicant.data';
import { GenericLabel } from '../../../../shared/constants/generic.labels.constants';
import Utils from 'app/shared/utilities/utils';
import { ZipData } from '../../data/zip.data';
import { ZipCodeData } from '../../data/zipcode.data';
import { PageSections } from '../../../../shared/enum/page-sections.enum';
import NotifUtils from '../../../../shared/utilities/notif-utils';
import { ApplicantValidationService } from '../../../../core/services/submission/validations/applicant-validation.service';
import { PolicySummaryData } from '../../../../modules/policy-management/data/policy-summary.data';
import FormUtils from 'app/shared/utilities/form.utils';
import { ApplicantBusinessRules } from '../../business-rules/applicant.business-rules';
import { BaseClass } from 'app/shared/base-class';
import { QuickQuoteData } from '../../data/quick-quote.data';
import { Validators } from '@angular/forms';
import { CustomValidators } from '../../../../shared/validators/custom.validator';
import { Pages } from 'app/shared/constants/pages-and-sections.constants';
import { AuthService } from 'app/core/services/auth.service';

@Component({
  selector: 'app-applicant-coapplicant',
  templateUrl: './applicant-coapplicant.component.html',
  styleUrls: ['./applicant-coapplicant.component.scss'],
})
export class ApplicantCoApplicantComponent extends BaseClass implements OnInit {
  public hasMailAndAddressSame: boolean = false;
  public isThereACoApplicant: boolean = false;
  public isCoApplicantAddressSameAsInsuredLocation: boolean = false;
  public isCoApplicantMailingAddressSameAsApplicant: boolean = false;
  public isCoApplicantHidden: boolean = false;
  public ErrorMessageConstant = ErrorMessageConstant;
  public LabelMessageConstant = ApplicantLabelsConstants;
  public FormUtils = FormUtils;
  GenericLabel = GenericLabel;
  public showBelow18Age: boolean = false;
  showBelow1800: boolean = false;
  showFutureDate: boolean = false;


  constructor(public applicantData: ApplicantData,
    protected submissionData: SubmissionPageData,
    public zipData: ZipData,
    protected zipCodeData: ZipCodeData,
    protected applicantValidationService: ApplicantValidationService,
    public policySummaryData: PolicySummaryData,
    protected applicantBusinessRules: ApplicantBusinessRules,
    protected quickQuoteData: QuickQuoteData,
    protected authService: AuthService) { super(); }

  get CoApplicantForm() {
    return this.applicantData.coApplicant;
  }

  get UATCNTR910_Logic(): boolean {
    return this.authService.userType.value === 'external' && (this.policySummaryData.isPolicy || this.policySummaryData.isRenewal());
  }

  get isSubmission(): boolean {
    return !this.policySummaryData.isPolicy && !this.policySummaryData.isRenewal();
  }

  ngOnInit(): void {
    this.zipCodeData.zipCodeOnInitChange = true;
    this.onCityChange();
    this.calculateAgeFromDateOfBirthField();
    this.setIsUSCountryValue(this.applicantData.coApplicant.get('coApplicantSameAsApplicantCountry').value);
    this.applicantData.setCoApplicantPageDisabledDOBandRetired();
    this.coApplicantValidation();
  }

  public ToggleMailingAndBusinessAddress(): void {
    this.hasMailAndAddressSame = !this.hasMailAndAddressSame;
  }

  public ToggleIsThereACoApplicant(): void {
    this.isThereACoApplicant = !this.isThereACoApplicant;
  }

  public ToggleIsCoApplicantAddressSameAsInsuredLocation(): void {
    this.isCoApplicantAddressSameAsInsuredLocation = !this
      .isCoApplicantAddressSameAsInsuredLocation;
  }

  public ToggleCoApplicantMailingAddressSameAsApplicant(): void {
    this.isCoApplicantMailingAddressSameAsApplicant = !this
      .isCoApplicantMailingAddressSameAsApplicant;

  }

  public ToggleHidingOfCoApplicant(): void {
    this.isCoApplicantHidden = !this.isCoApplicantHidden;
    this.submissionData.isCoApplicantOpen = !this.isCoApplicantHidden;
  }

  switchKeyPress(key: string, fieldName: string) {
    key = key.toLowerCase();
    switch (key) {
      case 'y':
        this.applicantData.coApplicant.get(fieldName).setValue(true);
        break;
      case 'n':
        this.applicantData.coApplicant.get(fieldName).setValue(false);
        break;
      default:
        break;
    }
    if (fieldName === 'isThereACoApplicant') {
      this.ToggleIsThereACoApplicant();
    } else if (fieldName === 'isCoApplicantsAddressSameAsInsuredLocation') {
      this.ToggleIsCoApplicantAddressSameAsInsuredLocation();
    } else if (fieldName === 'isCoApplicantsMailingAddressSameAsApplicantsMailingAddress') {
      this.ToggleCoApplicantMailingAddressSameAsApplicant();
    }
  }

  validateKeyPress(event) {
    Utils.validateKeyPressAndAcceptAlphanumericOnly(event);
  }

  setZipCode() {
    if (!this.applicantData.isCoAppUSCountry) { return; }
    const zipCode = this.applicantData.coApplicant.get('coApplicantSameAsApplicantZipCode').value;
    this.zipCodeData.selectedCity = this.CoApplicantForm.get('coApplicantSameAsApplicantCity').value;
    this.CoApplicantForm.get('coApplicantSameAsApplicantCity').reset();
    this.zipCodeData.zipCodeOnInitChange = false;
    const formControlNames = ['coApplicantSameAsApplicantState', 'coApplicantCounty', 'coApplicantSameAsApplicantCity', 'coApplicantSameAsApplicantZipCode'];
    if(zipCode === '' || zipCode === null) {
      this.applicantValidationService.setCoApplicantAddressValidation();
    }
    Utils.blockUI();
    this.CoApplicantForm.get('coApplicantSameAsApplicantCity').enable();
    if(zipCode !== '') {
      const state = this.zipCodeData.stateCode;
      this.zipCodeData.getGenericZipCode(zipCode, this.applicantData.coApplicant, formControlNames, PageSections.CoApplicant, false, ErrorMessageConstant.zipCodeNotFoundErrorMessage);
    } else {
      this.FormUtils.resetFields(this.applicantData.coApplicant, formControlNames);
      this.zipCodeData.coApplicantCityList = [];
      this.CoApplicantForm.markAllAsTouched();
      Utils.unblockUI();
    }
  }

  onValueChange() {
    const isCoApplicantsMailingAddressSameAsApplicantsMailingAddress = this.CoApplicantForm.get('isCoApplicantsMailingAddressSameAsApplicantsMailingAddress').value;
    if (!isCoApplicantsMailingAddressSameAsApplicantsMailingAddress) {
      if (this.applicantData.isCoAppUSCountry) {
        this.applicantValidationService.setCoApplicantAddressValidation();
      } else {
        this.applicantValidationService.clearCoApplicantAddressValidation();
      }
      this.applicantData.coApplicant.markAllAsTouched();
    } else {
      NotifUtils.showConfirmMessage(ErrorMessageConstant.coApplicantErrorMessage.isCoApplicantMailingAddressSetToYes, () => {
        this.applicantData.setToDefaultMailingAddress();
        this.applicantValidationService.clearCoApplicantAddressValidation();
        this.setIsUSCountryValue('USA');
      }, () => {
        this.CoApplicantForm.get('isCoApplicantsMailingAddressSameAsApplicantsMailingAddress').setValue(false);
      });
    }
  }

  onValueChangeCoApplicant() {
    const isThereACoApplicant = this.CoApplicantForm.get('isThereACoApplicant').value;
    if (isThereACoApplicant) {
      this.coApplicantValidation();
      this.applicantData.coApplicantIsRetired = false;
      this.CoApplicantForm.get('isCoApplicantsMailingAddressSameAsApplicantsMailingAddress').setValue(true);
    } else {
      NotifUtils.showConfirmMessage(ErrorMessageConstant.coApplicantErrorMessage.isCoApplicantSetToNo, () => {
        this.applicantData.setToDefaultCoApplicant();
        this.applicantData.setToDefaultMailingAddress();
        this.setIsUSCountryValue('USA');
        this.applicantData.setCoApplicantPageDisabledDOBandRetired();

        this.applicantValidationService.clearCoApplicantValidation();
        this.applicantValidationService.clearCoApplicantAddressValidation();
      }, () => {
        this.CoApplicantForm.get('isThereACoApplicant').setValue(true);
      });
    }
  }

  coApplicantValidation(): void {
    this.applicantValidationService.setCoApplicantValidation();
    if (this.policySummaryData.isPolicy || this.policySummaryData.isRenewal()) {
      this.applicantValidationService.userTypeValidation(Pages.Applicant.sections.CoApplicant);
    }
  }

  setCity(): void {
      this.zipCodeData.selectedCity = this.CoApplicantForm.get('coApplicantSameAsApplicantCity').value;
      this.zipCodeData.coApplicantCitySetter(this.zipCodeData.coApplicantCityList.length > 0, this.zipCodeData.selectedCity, true);
  }

  onCityChange(): void {
    this.zipCodeData.cityObservable.subscribe(city => {
      this.CoApplicantForm.get('coApplicantSameAsApplicantCity').setValue(city.value);
    });
  }

  calculateAgeFromDateOfBirthField(): void {
    const dateOfBirth = new Date(this.applicantData.coApplicant.get('coApplicantDateOfBirth').value?.singleDate?.jsDate?.toLocaleDateString('en-US'));
    this.checkApplicantAgeForSeniorRetiree(dateOfBirth);
  }

  public calculateAge(event): void {
    const dateOfBirth = event.jsDate;
    this.checkApplicantAgeForSeniorRetiree(dateOfBirth);
  }

  checkApplicantAgeForSeniorRetiree(dateOfBirth: Date): void {
    if (dateOfBirth) {
      const age = this.applicantBusinessRules.calculateInsuredAge(dateOfBirth);
      this.applicantData.age = age;
      if (age < 18) {
        this.showBelow18Age = true;
        NotifUtils.showError(ErrorMessageConstant.below18age, () => {
          this.applicantData.coApplicant.get('coApplicantDateOfBirth').setValue(null);
        });
      } else {
        this.showBelow18Age = false;
      }

      if (age < 55) {
        this.applicantData.coApplicant.get('coApplicantisRetiredYesNo').setValue(false);
      }

      if (this.policySummaryData.isPolicy) {
        this.quickQuoteData.getPolicyChangesForSeniorRetiree();
      } else {
        this.quickQuoteData.setSeniorRetireeValue();
      }

    }
  }

  onCoAppCountrySelect(event?): void {
    const country = event?.target ? event?.target?.value : event;
    this.setIsUSCountryValue(country);
    if (this.applicantData.isCoAppUSCountry) {
      const regex = /[^0-9]*/g;
      this.CoApplicantForm.get('coApplicantSameAsApplicantCity').setValidators([Validators.required]);
      this.CoApplicantForm.get('coApplicantSameAsApplicantState').setValidators([Validators.required]);
      this.CoApplicantForm.get('coApplicantSameAsApplicantZipCode').setValidators([Validators.required, Validators.pattern(regex), CustomValidators.hasNoValue]);
      this.CoApplicantForm.get('coApplicantSameAsApplicantCity').reset();
      this.CoApplicantForm.get('coApplicantSameAsApplicantState').reset();
      this.CoApplicantForm.get('coApplicantSameAsApplicantZipCode').reset();
    } else {
      this.zipCodeData.coApplicantCityList = [];
      this.CoApplicantForm.get('coApplicantSameAsApplicantCity').clearValidators();
      this.CoApplicantForm.get('coApplicantSameAsApplicantState').clearValidators();
      this.CoApplicantForm.get('coApplicantSameAsApplicantZipCode').clearValidators();
      this.CoApplicantForm.get('coApplicantSameAsApplicantCity').reset();
      this.CoApplicantForm.get('coApplicantSameAsApplicantCity').enable();
      this.CoApplicantForm.get('coApplicantSameAsApplicantState').reset();
      this.CoApplicantForm.get('coApplicantSameAsApplicantZipCode').reset();
    }
    this.CoApplicantForm.updateValueAndValidity();
    this.CoApplicantForm.markAllAsTouched();
  }

  setIsUSCountryValue(country: string): void {
    this.applicantData.isCoAppUSCountry = country === 'USA';
  }
}
