import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessedBatchPaymentsComponent } from './processed-batch-payments.component';
import { RouterModule, Routes } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap';
import { ProcessedBatchPaymentsListComponent } from './processed-batch-payments-list/processed-batch-payments-list.component';
import { SharedModule } from '../../../../shared/shared.module';
import { CustomPipesModule } from '../../../../shared/custom pipes/custom-pipe.module';

const routes: Routes = [
  { path: '', component: ProcessedBatchPaymentsComponent }
];

@NgModule({
  declarations: [
    ProcessedBatchPaymentsComponent,
    ProcessedBatchPaymentsListComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    CustomPipesModule,
    TooltipModule.forRoot()
  ],
  entryComponents: [

  ]
})
export class ProcessedBatchPaymentsModule { }
