export const YearConstant =  [
    {
      key: 1,
      value: 'January'
    },
    {
      key: 2,
      value: 'February'
    },
    {
      key: 3,
      value: 'March'
    },
    {
      key: 4,
      value: 'April'
    },
    {
      key: 5,
      value: 'May'
    },
    {
      key: 6,
      value: 'June'
    },
    {
      key: 7,
      value: 'July'
    },
    {
      key: 8,
      value: 'August'
    },
    {
      key: 9,
      value: 'September'
    },
    {
      key: 10,
      value: 'October'
    },
    {
      key: 11,
      value: 'November'
    },
    {
      key: 12,
      value: 'December'
    }
  ];