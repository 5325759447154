export class ResidentialRequestDTO implements IResidentialRequestDTO {
    valuationId?: string;
    name: string;
    streetAddress: string;
    city: string;
    state: string;
    zipcode: string;
    country?: string;
    unit?: string;
    yearBuilt?: string;
    squareFootage?: string;
    numberOfStories?: string;
    structureType?: string;
    foundationType?: string;
    roofType?: string;
    roofMaterialType?: string;
    exteriorWallFinishType?: string;
    exteriorWallConstructionType?: string;
    formType: string;
    riskId?: string;
    riskDetailId?: string;
    quoteNumber?: string;
    policyNumber?: string;
    public constructor(init?: Partial<ResidentialRequestDTO>) {
        Object.assign(this, init);
    }
}

export interface IResidentialRequestDTO {
    valuationId?: string;
    name: string;
    streetAddress: string;
    city: string;
    state: string;
    zipcode: string;
    country?: string;
    unit?: string;
    yearBuilt?: string;
    squareFootage?: string;
    numberOfStories?: string;
    structureType?: string;
    foundationType?: string;
    roofType?: string;
    roofMaterialType?: string;
    exteriorWallFinishType?: string;
    exteriorWallConstructionType?: string;
    formType: string;
    riskId?: string;
    riskDetailId?: string;
    quoteNumber?: string;
    policyNumber?: string;
}
