import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, combineLatest, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { map, catchError, takeUntil } from 'rxjs/operators';
import { NavData } from '../../../_nav';
import { AuthService } from '../auth.service';
import { StorageService } from '../storage.service';
import { Menu } from '../../../shared/models/menu';
import { UserAccessRight } from '../../../shared/models/userAccessRight';
import NotifUtils from '../../../shared/utilities/notif-utils';
import JsUtils from '../../../shared/utilities/js.utils';
import { BaseComponent } from '../../../shared/base-component';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService extends BaseComponent {

  selectedChildMenuList: NavData[];
  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    protected router: Router,
    public route: ActivatedRoute,
    protected storageService: StorageService
  ) {
    super();
  }

  initializeMenus(): Observable<any> {
    const values = combineLatest(
      [
        this.getMenuList(),
        this.getAuthorizedMenuList()
      ]
    ).pipe(
      takeUntil(this.stop$),
      map(([menus, authMenus]) => {

        this.menuList = [];
        this.mainMenuList = [];
        this.allMenuList = [];
        menus = menus.map(m => {
          m.name = m.menuLabel;
          m.url = m.controllerName;
          return m;
        });

        this.allMenuList = menus;

        menus = menus.filter(p => authMenus.map(a => a.menuId).indexOf(p.menuId) !== -1);
        if (authMenus.length > 0) {
          const authorizedMenus: Menu[] = JsUtils.mergeArrayObjects(
            menus.map(m => ({ ...m, id: m.menuId })),
            authMenus.map(m => ({ ...m, id: m.menuId })), this.menuComparator);

          this.menuList = authorizedMenus;
          this.mainMenuList = authorizedMenus.filter(p => p.parentId === p.menuId);
        }
      })
      , catchError(() =>
        of(
          NotifUtils.showError('Something went wrong in initializing menus.')
        )
      )
    );

    return values;
  }


  protected getMenuList(): Observable<Menu[]> {
    const applicationId = environment.ApplicationId;
    const url = `/api/Programs/${applicationId}/menus`;

    return this.http.get<any>(`${environment.IdentityServiceUrl}${url}`).pipe(
      takeUntil(this.stop$),
      map((res) => {
        return res;
      })
      , catchError(() =>
        of(
          NotifUtils.showError('Something went wrong in initializing menus.')
        )
      )
    );
  }


  protected getAuthorizedMenuList(): Observable<UserAccessRight[]> {
    return this.authService.getUserAccessRights().pipe(
      takeUntil(this.stop$),
      map(res => (res.filter(p => p.isView))));
  }

  public getChildMenusByRoute(route: string) {

    // sheh temporary assign the menus

    return;

    const firstPath = '/' + route.split('/')[1];
    const parentMenu: Menu = this.menuList.filter(p => p.controllerName === firstPath)[0];
    // let regx = new RegExp('^' + firstPath, 'i');
    // let parentMenu: Menu = this.menuList.filter(p => regx.test(p.controllerName))[0];

    // this.getChildMenusByParentId(parentMenu.menuId);
    this.selectedChildMenuList = JsUtils.getNestedChildren(this.menuList.map(m => ({ ...m, id: m.menuId })), parentMenu.menuId);
  }

  public getChildMenusByParentId(menuId: number) {

    if (this.menuList) {
      return this.selectedChildMenuList = this.menuList.filter(q => q.parentId === menuId)
        .map((res) => ({ name: res.menuLabel, url: res.controllerName.replace(':id', '0') }));
    }
  }

  public getMenuByControllerName(controllerName: string): Menu {

    const menu = this.menuList.filter(m => m.controllerName === controllerName)[0];

    return menu;
  }

  public get menuList(): Menu[] {
    return JSON.parse(this.storageService.getItem('menu'));
  }

  public set menuList(lstMenu: Menu[]) {
    this.storageService.setItem('menu', JSON.stringify(lstMenu));
  }

  public get mainMenuList(): Menu[] {
    return JSON.parse(this.storageService.getItem('mainMenu'));
  }

  public set mainMenuList(lstMenu: Menu[]) {
    this.storageService.setItem('mainMenu', JSON.stringify(lstMenu));
  }

  public get allMenuList(): Menu[] {
    return JSON.parse(this.storageService.getItem('allMenu'));
  }

  public set allMenuList(lstMenu: Menu[]) {
    this.storageService.setItem('allMenu', JSON.stringify(lstMenu));
  }

  protected menuComparator(a, b) {
    return a.menuId - b.menuId;
  }

  navigateRoute(page: string, isApplicantPage: boolean = false, isQuickQuotePage: boolean = false, riskId?: string, riskDetailId?: string) {
    const cntr = '/submissions/new/submission/' + page;
    const nextPageData = (cntr).split('/');
    const url = this.route.snapshot['_routerState'].url;
    const urlData = url.split('/');
    if (urlData && urlData[2].toLowerCase() !== 'new') {
      nextPageData[2] = `${urlData[2]}/${urlData[3]}`;
    }
    if ((riskId && riskDetailId) && urlData[2].toLowerCase() === 'new') {
      nextPageData[2] = `${riskId}/${riskDetailId}`;
    }
    nextPageData[3] = isApplicantPage ? 'applicant' : isQuickQuotePage ? 'quickquote' : 'submission';
    const nextUrl = nextPageData.join('/');
    this.router.navigate([nextUrl]);
  }

  navigatePolicyRoute(page: string, isApplicantPage: boolean = false): void {
    console.log(this.route.snapshot['_routerState'].url);
    const url = this.route.snapshot['_routerState'].url;
    const urlData = url.split('/');
    urlData[4] = page;
    isApplicantPage = urlData[5] ? true : isApplicantPage;
    if (isApplicantPage) {
      urlData.splice(5,1);
    };
    const nextUrl = urlData.join('/');
    this.router.navigate([nextUrl]);
  }

  navigateTransferPage(batchId: string): void {
    const url = this.route.snapshot['_routerState'].url;
    const urlData = url.split('/');
    urlData[2] = `edit/${batchId}`;
    const nextUrl = urlData.join('/');
    this.router.navigate([nextUrl]);
  }
}




