import { createSelector } from '@ngrx/store';
import { BaseState } from '../base.state';

export const selectRaterCalculateDP3State = (state: BaseState) => state.raterCalculateDP3;

export const selectRaterCalculateDP3IsLoading = createSelector(
  selectRaterCalculateDP3State,
  (state) => state.isLoading
)

export const selectRaterCalculateDP3List = createSelector(
  selectRaterCalculateDP3State,
  (state) => state.list
)