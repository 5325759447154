import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quick-search-section',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss']
})
export class QuickSearchSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
