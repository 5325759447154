import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import Utils from 'app/shared/utilities/utils';
import { IAngularMyDpOptions, IMyDate } from 'angular-mydatepicker';
import { BaseClass } from 'app/shared/base-class';
import { PolicyService } from 'app/core/services/submission/policy.service';
import { takeUntil } from 'rxjs/operators';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { CancellationDTO, IPolicyCancellationDTO } from 'app/shared/models/data/dto/policy-management/policy-cancellation.dto';
import { ReinstatementResponseDTO } from '../../../../app/shared/models/data/dto/policy-management/policy-reinstatement.dto';
import { SubmissionPageData } from '../../../../app/modules/submission-management/data/submission-page.data';
import { SummaryData } from '../../../../app/modules/submission-management/data/summary.data';
import { PolicyBillingData } from './policy-billing.data';
import { PolicyDocumentsData } from './policy-documents.data';
import { LocalStorageService } from '../../../../app/core/services/local-storage.service';
import { CalculateRaterData } from '../../../../app/core/services/submission/rater-premium/calculate-rater-data.service';
import { LvRiskStatus } from '../../../../app/shared/constants/risk-status';
import { GenericConstants } from '../../../../app/shared/constants/generic.constants';
import { RiskStatus } from '../../../../app/shared/models/data/dto/quick-quote/risk.dto';
import { Rater } from '../../../../app/shared/constants/rater.constant';
import { AuthService } from 'app/core/services/auth.service';
import { PolicyHistoryData } from './policy-history-data';
import * as moment from 'moment';
import { PolicyIssueData } from './policy-issue.data';
import { Store } from '@ngrx/store';
import { AutoReinstatementData } from './auto-reinstatement.data';
import { updatePolicyStatusFromPolicyCancellation } from '../../../store/policy/policy.actions';
import { ClaimsData } from 'app/modules/submission-management/data/claims.data';

@Injectable({
  providedIn: 'root'
})
export class PolicyCancellationData extends BaseClass {


  effectiveDateOption: IAngularMyDpOptions;
  disableEffectiveDate: boolean = true;
  cancellationReasonIsRequired: boolean = false;

  policyCancellationForm: FormGroup;
  stateTimingData: IPolicyCancellationDTO;

  currentRiskId: string;
  currentRiskDetailId: string;
  cancellationReasonTypeHasValue: string = null;
  isInternal: boolean;

  genericConstants = GenericConstants;

  isCancellationEffectiveDateValid: boolean = true;

  claimsLossDate: Date;
  iseffectiveDatePriorToClaimsLossDate: boolean = false;

  constructor(
    protected fb: FormBuilder,
    public policyService: PolicyService,
    protected entityRiskData: EntityRiskData,
    protected submissionPageData: SubmissionPageData,
    protected summaryData: SummaryData,
    protected billingData: PolicyBillingData,
    protected policyDocumentsData: PolicyDocumentsData,
    protected storage: LocalStorageService,
    protected raterData: CalculateRaterData,
    protected authService: AuthService,
    protected policyHistoryData: PolicyHistoryData,
    protected policyIssueData: PolicyIssueData,
    protected store: Store,
    public autoReinstatementData: AutoReinstatementData,
    public claimsData: ClaimsData
  ) {
    super();
    this.effectiveDateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy',
    };
  }

  get isExternal(): boolean {
    return !this.isInternal;
  }

  initializeForm(): void {
    this.policyCancellationForm = this.fb.group({
      effectiveDate: new FormControl('', [Validators.required]),
      cancellationType: new FormControl('', [Validators.required]),
      cancellationReasonType: new FormControl('', [Validators.required]),
      cancellationReason: new FormControl('')
    });
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.isInternal = userType.toLocaleLowerCase() === 'internal';
    });
  }

  initForms(): void {
    this.dateOptions();
  }

  get isInvalidForm(): boolean {

    if (!this.policyCancellationForm.valid || this.cancellationReasonTypeHasValue == null
      || !this.isCancellationEffectiveDateValid) {
      return true;
    } else {
      return false;
    }

  }




  dateOptions(isNotIR: boolean = true): void {
    const policyEffectiveDate = new Date(new Date(this.stateTimingData.effectiveDate) > this.claimsLossDate ? this.stateTimingData.effectiveDate : (this.claimsLossDate == null ? this.stateTimingData.effectiveDate : this.claimsLossDate));
    const getDayOfEffectiveDate = policyEffectiveDate.getDate();
    const firstIssueDate = new Date(this.entityRiskData.getRisk().firstIssueDate);

    if (getDayOfEffectiveDate === 1) {
      const dayBeforeEffectiveDate = new Date(policyEffectiveDate);
      dayBeforeEffectiveDate.setDate(dayBeforeEffectiveDate.getDate() - 1);

      this.effectiveDateOption = {
        dateRange: false,
        dateFormat: 'mm/dd/yyyy',
        disableUntil: this.setBackdateLimit(dayBeforeEffectiveDate, getDayOfEffectiveDate),
        disableSince: {
          year: Number(firstIssueDate.getFullYear() + 1),
          month: Number(firstIssueDate.getMonth() + 1),
          day: Number(firstIssueDate.getDate() + 1),
        }
      };
    } else {
      this.effectiveDateOption = {
        dateRange: false,
        dateFormat: 'mm/dd/yyyy',
        disableUntil: this.setBackdateLimit(policyEffectiveDate, getDayOfEffectiveDate),
        disableSince: {
          year: Number(firstIssueDate.getFullYear() + 1),
          month: Number(firstIssueDate.getMonth() + 1),
          day: Number(firstIssueDate.getDate() + 1)
        }
      };
    }
  }

  generateStateTiming(): void {

    this.currentRiskId = this.entityRiskData.getRiskId();
    this.currentRiskDetailId = this.entityRiskData.getRiskDetailId();

    Utils.blockUI();
    this.policyService.getStateTiming(this.currentRiskId, this.currentRiskDetailId).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.stateTimingData = result as IPolicyCancellationDTO;
      this.initForms();
      this.setCancellationEffectiveDate();
      Utils.unblockUI();
    }, (error) => {
      Utils.unblockUI();
    });
  }

  setCancellationEffectiveDate(): void {
    if (this.stateTimingData) {
      const effectiveDate = new Date(this.stateTimingData?.effectiveDate);
      const currentDate = this.authService.getCustomDate();

      if (effectiveDate > currentDate) {
        this.policyCancellationForm.get('effectiveDate').setValue({ isRange: false, singleDate: { jsDate: (effectiveDate) } });
      } else {
        this.policyCancellationForm.get('effectiveDate').setValue({ isRange: false, singleDate: { jsDate: (currentDate) } });
      }
    }
  }

  getPolicyCancellationFormValue(): CancellationDTO {
    let policyCancellation = new CancellationDTO();

    const effectiveDate = this.policyCancellationForm.get('effectiveDate')?.value?.singleDate?.formatted ||
      new Date(this.policyCancellationForm.get('effectiveDate')?.value.singleDate?.jsDate).toLocaleDateString();

    policyCancellation = {
      riskId: this.currentRiskId,
      riskDetailId: this.currentRiskDetailId,
      cancellationEffectiveDate: effectiveDate,
      cancellationTypeCode: this.policyCancellationForm.get('cancellationType')?.value,
      cancellationReasonTypeCode: this.policyCancellationForm.get('cancellationReasonType')?.value,
      cancellationReason: this.policyCancellationForm.get('cancellationReason')?.value
    };

    return policyCancellation;
  }

  reinstatePolicyCallback(result: ReinstatementResponseDTO) {
    this.submissionPageData.isApprovedForReinstatement.next(result.riskStatusCode === 'CAN' && Boolean(result.isApprovedForReinstatement));
    this.submissionPageData.isPendingCancellation.next(false);
    this.autoReinstatementData.isApprovalRequired$.next(result.riskStatusCode === 'CAN' && Boolean(result.isApprovedForReinstatement));
    const riskStatusDescription = LvRiskStatus.find(a => a.code === result.riskStatusCode)?.description;
    this.summaryData.SummaryForm.get('quoteStatus').setValue(riskStatusDescription);
    this.submissionPageData.quoteStatus.next(riskStatusDescription as RiskStatus);
    this.store.dispatch(updatePolicyStatusFromPolicyCancellation({ status: riskStatusDescription as RiskStatus }));
    this.submissionPageData.isRewritten.next(false);

    this.entityRiskData.getRisk().riskStatusCode = result.riskStatusCode;

    this.policyService.getPolicyDocuments(this.entityRiskData?.getRiskId()).pipe(takeUntil(this.stop$)).subscribe(documents => {
      this.policyDocumentsData.mapPolicyDocuments(documents);
      this.policyDocumentsData.getFTPDocuments(this.entityRiskData?.getRiskId());
    });

    this.billingData.showSummary(result.riskId);
    this.billingData.listInstallmentInvoice(result.riskId);
    this.billingData.populateTransactionSummary(result.riskId);
    this.billingData.listPaymentsByRiskId(result.riskId);

    const riskDetail = this.entityRiskData.getRisk().riskDetails[0];
    const isFromRenewal = this.entityRiskData.getRisk()?.isFromRenewal;
    this.summaryData.setShowRewriteOption(riskDetail.effectiveDate, riskDetail.endorsementNumber, riskStatusDescription, isFromRenewal);

    if (result.raterResults) {
      const raterResultKey = `${Rater.raterResult}_${result.riskDetailId}`;
      this.storage.store(raterResultKey, result.raterResults);

      this.raterData.calculateRaterPremium();
      this.updatePolicyHistoryData();

      this.billingData.listTransactionFeesByRiskId(this.entityRiskData.getRiskId());
    }

    this.policyIssueData.triggerPolicyChanges();
  }

  public updatePolicyHistoryData() {
    const policyNumber = this.summaryData.SummaryForm.get('policyNumber').value;
    this.policyHistoryData.populateHistoryData(
      policyNumber,
      this.entityRiskData.getRiskId(),
      this.entityRiskData.getLastRiskDetailId()
    );
  }

  private setBackdateLimit(date: Date, day: number) {
    let disableUntil: any;

    if (this.authService.isInternalUser) {
      disableUntil = {
        year: Number(date.getFullYear()),
        month: Number(date.getMonth() + 1),
        day: Number(day === 1 ? date.getDate() : date.getDate() - 1)
      };
    } else {
      const loginDate = this.authService.getCustomDate();
      loginDate.setDate(loginDate.getDate() - 30);

      if (loginDate > date) {
        disableUntil =  {
          year: Number(loginDate.getFullYear()),
          month: Number(loginDate.getMonth() + 1),
          day: Number(loginDate.getDate())
        };
      } else {
        disableUntil = {
          year: Number(date.getFullYear()),
          month: Number(date.getMonth() + 1),
          day: Number(day === 1 ? date.getDate() : date.getDate() - 1)
        };
      }
    }

    return disableUntil;
  }

  checkSelectedCancellationEffectiveDateValidity(selectedCancellationEffectiveDate: Date) {
    const firstIssueDate = new Date(this.entityRiskData.getRisk().firstIssueDate);
    this.isCancellationEffectiveDateValid = selectedCancellationEffectiveDate >= firstIssueDate && !this.iseffectiveDatePriorToClaimsLossDate;
  }

  getClaimsLossDate() {
    const a = this.claimsData.riskClaims;
    this.claimsLossDate = Object.keys(a).length === 0 ? null : new Date(Math.max.apply(null, a.map((e) => new Date(e.lossDate))));
  }

  checkEffectiveDatePriorToClaims(selectedEffectiveDate: Date) {
    this.iseffectiveDatePriorToClaimsLossDate = this.claimsLossDate == null ? false : selectedEffectiveDate < this.claimsLossDate ? true : false;
  }
}
