export const LvAccountType = [
  {
    tableName: 'LvAccountType',
    tableDescription: 'Account Type',
    code: 'AT0',
    description: 'Checking',
    isActive: true
  },
  {
    tableName: 'LvAccountType',
    tableDescription: 'Account Type',
    code: 'AT1',
    description: 'Savings',
    isActive: true
  }
];

export const LvAgencyNetwork = [
  {
    tableName: 'LvAgencyNetwork',
    tableDescription: 'Agency Network',
    code: 'ARROW',
    description: 'Arrowhead',
    isActive: true
  },
  {
    tableName: 'LvAgencyNetwork',
    tableDescription: 'Agency Network',
    code: 'CENTA',
    description: 'Centauri Agents',
    isActive: true
  },
  {
    tableName: 'LvAgencyNetwork',
    tableDescription: 'Agency Network',
    code: 'KRAFT',
    description: 'Kraft Lake Insurance Agency',
    isActive: true
  },
  {
    tableName: 'LvAgencyNetwork',
    tableDescription: 'Agency Network',
    code: 'NLAKE',
    description: 'Northlake Insurance Group Ltd',
    isActive: true
  },
  {
    tableName: 'LvAgencyNetwork',
    tableDescription: 'Agency Network',
    code: 'PANLA',
    description: 'Premiere Agency Network LLC LA',
    isActive: true
  },
  {
    tableName: 'LvAgencyNetwork',
    tableDescription: 'Agency Network',
    code: 'PANAL',
    description: 'Premiere Agency Network LLC AL',
    isActive: true
  },
  {
    tableName: 'LvAgencyNetwork',
    tableDescription: 'Agency Network',
    code: 'SIM',
    description: 'Select Insurance Markets',
    isActive: true
  },
  {
    tableName: 'LvAgencyNetwork',
    tableDescription: 'Agency Network',
    code: 'SCIG',
    description: 'Smart Choice Insurance Group',
    isActive: true
  }
];

export const LvAgencyManagementSystem = [{
  tableName: 'LvAgencyManagementSystem',
  tableDescription: 'Agency Management System',
  code: 'IVANS',
  description: 'IVANS',
  isActive: true
},
{
  tableName: 'LvAgencyManagementSystem',
  tableDescription: 'Agency Management System',
  code: 'NA',
  description: 'N/A',
  isActive: true
}
];

export const LvCommissionType = [{
    tableName: 'LvCommissionType',
    tableDescription: 'Commission Type',
    code: 'F',
    description: 'Flat',
    isActive: true
  },
  {
    tableName: 'LvCommissionType',
    tableDescription: 'Commission Type',
    code: 'S',
    description: 'Schedule',
    isActive: true
  }
];
