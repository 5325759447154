export const RegexConstants = {
  phoneValidation: /^\d{10}$/ ,
  zipValidation: /[^0-9]*/g,
  hasANumber: /\d/,
  hasALetterAndASpaceAndAHyphen: /^[a-zA-Z\s\-']*$/,
  hasALetterAndASpaceAndAHyphenWithÑ:/^[a-zA-Z Ñ \s\-']*$/i,
  policyNumber: /^[a-zA-Z0-9-_]*$/,
  payer: /^[a-zA-Z' -]*$/,
  hasBothLettersAndNumbersAndUnderScore: /[A-Za-z0-9\-\_]+/,

  CreditCardFormat: {
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    masterCard: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/
  },
  guidFormat: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
};
