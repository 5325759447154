import { Component, OnDestroy, OnInit } from '@angular/core';
import { TaskData } from 'app/modules/dashboard/data/task.data';
import { TaskService } from 'app/core/services/task/task.service';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { BaseClass } from 'app/shared/base-class';
import Utils from 'app/shared/utilities/utils';
import { TaskModalComponent } from '../task-modal/task-modal.component';
import { TaskLabelsConstants } from 'app/shared/constants/task.labels.constants';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { ITaskDTO } from 'app/shared/models/data/dto/task/task.dto';
import { AuthService } from 'app/core/services/auth.service';

@Component({
  selector: 'app-task-section',
  templateUrl: './task-section.component.html',
  styleUrls: ['./task-section.component.scss']
})
export class TaskSectionComponent extends BaseClass implements OnInit, OnDestroy {


  public agentDashboardConstants = AgentDashboardConstants;
  public taskLabelsConstants = TaskLabelsConstants;
  modalRef: BsModalRef | null;

  public showRowsNumber: number = 8;

  constructor(
    public taskService: TaskService,
    public taskData: TaskData,
    private modalService: BsModalService,
    private authService: AuthService) {
      super();
    }

  ngOnInit() {
    this.taskData.initializeAgentTaskFilterForm();
    this.authService.isAgentUserInfoCompleted.subscribe(() => {
      this.taskData.getTaskList(this.taskData.getInitialTaskListPayload());
    });
    this.taskData.agentOnFirstLoad = true;
  }

  ngOnDestroy() {
    this.taskData.currentPage = 1;
    this.taskData.itemPerPage = 8;
    this.taskData.totalRowCount = 500;
    this.taskData.taskListLoading = false;
    this.taskData.uwOnFirstLoad = true;
    this.taskData.agentOnFirstLoad = true;
  }

  setPage(page: number) {
    this.taskData.setTaskList(page);
  }

  getTask(taskId: string): void {
    Utils.blockUI();
    this.taskService.getTaskById(taskId).pipe(takeUntil(this.stop$)).subscribe(result => {
      // Show Edit modal UI here and populate data by result;
      this.showEditTaskModal(result);
      Utils.unblockUI();
    }, (error) => {
      Utils.unblockUI();
    });
  }

  showEditTaskModal(data: ITaskDTO): void {
    const initialState = {
      title: this.taskLabelsConstants.Task.Edit.EditTaskTitle,
      isAddTaskModal: false,
      modalData: data,
      isExternal: true,
      initialTaskNotes: data.taskNotes,
      initialTaskDocuments: data.taskDocuments
    };

    this.modalRef = this.modalService.show(TaskModalComponent, {
      initialState,
      class: 'modal-lg modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  changeShowRowsNumber(value): void{
    if (this.taskData.itemPerPage === value) { return; }

    this.showRowsNumber = value;
    this.taskData.itemPerPage = value;
    this.taskData.currentPage = 1;

    const payload = this.taskData.getInitialTaskListPayload(this.taskData.currentPage, this.taskData.itemPerPage);
    localStorage.setItem('currentPage',''+this.taskData.currentPage);
    localStorage.setItem('itemPerPage',''+this.taskData.itemPerPage);
    this.taskData.getTaskList(payload);
  }

  searchTask(): void {
    const payload = this.taskData.getAgentTaskFilterFormPayload();
    this.taskData.getTaskList(payload);
    this.taskData.getUnderwriterList();
  }


  pageChanged(event: PageChangedEvent): void {
    if(this.taskData.taskListLoading) { return; }
    this.taskData.currentPage = event.page;
    const payload = this.taskData.getInitialTaskListPayload(this.taskData.currentPage, this.taskData.itemPerPage);
    this.taskData.getTaskList(payload);
  }

}
