import { ValidationListModel } from 'app/shared/models/validation-list.model';
import { PathConstants } from '../../shared/constants/path.constants';
import { NavData, INavDetails, IEnableBindAndIssueDetails } from '../../_nav';
import { RiskStatus } from 'app/shared/models/data/dto/quick-quote/risk.dto';
import { GenericConstants, UserType } from '../../shared/constants/generic.constants';
import { SubmissionConstants } from 'app/shared/constants/submission.constants';
import { RiskStatusConstants } from 'app/shared/constants/risk-status';

interface IBadge {
  text?: string;
  variant?: string;
}

type badgeVariant = 'danger' | 'warning';
type badgeText = '!' | 'i';

const setBadge = (validation?: boolean, text: badgeText = '!', variant: badgeVariant = 'danger'): IBadge => {
  let badge = {};
  const entityId = localStorage.getItem('entityId');

  if (validation !== undefined && entityId !== 'null' && !validation) {
    badge = {
      text: text,
      variant: variant
    };
  }

  return badge;
};

export const createSubmissionDetailsMenuItems = (navDetails: INavDetails , hasMoratoriumClosure: boolean = false): NavData[] => {
  const {
    riskId,
    riskDetailId,
    storage,
    validation,
    quoteStatus,
    hasAir,
    hasDecline,
    allUwrApproved,
    uwrListEmpty,
    hasUwrChanges,
    hasPostBindUwrItem,
    uwrListAllPostBind,
    postBindUwrItemsApproved,
    nonPostBindUwrItemsChecked,
    nonPostBindUwrItemsApproved,
    uwApprovalListAddedAllPostBind,
    fcraAcknowledgement,
    hasRenewalOfferChanges,
    hasCommissionRate,
    isSubmissionReferApprovedAirDeclined
  } = navDetails;
  const enableRaterAudit = storage?.retrieve('enableRaterAudit');
  const userType: UserType = localStorage?.getItem('userType') as UserType;
  let paramId = '/new';
  let raterMenuItem = [];
  const getEnableBindAndIssue = enableBindAndIssue({
    enablePage: !pageDisabler(validation),
    quoteStatus: quoteStatus,
    hasAir,
    hasDecline,
    allUwrApproved: allUwrApproved,
    uwrListEmpty: uwrListEmpty,
    hasUwrChanges: hasUwrChanges,
    hasPostBindUwrItem: hasPostBindUwrItem,
    uwrListAllPostBind: uwrListAllPostBind,
    postBindUwrItemsApproved: postBindUwrItemsApproved,
    nonPostBindUwrItemsChecked: nonPostBindUwrItemsChecked,
    nonPostBindUwrItemsApproved: nonPostBindUwrItemsApproved,
    uwApprovalListAddedAllPostBind: uwApprovalListAddedAllPostBind
  });
  const getEnableUwApproval = !pageDisabler(validation);

  // If id has value, concat id to submission details url path.
  if (riskId != null && riskDetailId != null) {
    paramId = `/${riskId}/${riskDetailId}`;
  }

  // Url path of submission details. '/submission/details'
  const path = '/' + PathConstants.SubmissionManagement.Index + paramId;

  const showErrorBadgeForExternalUser: boolean = (
    userType === GenericConstants.userType.external &&
    quoteStatus === RiskStatusConstants.application &&
    !uwrListEmpty &&
    !uwrListAllPostBind &&
    !nonPostBindUwrItemsChecked
  );

  let submissionMenu = [
    {
      name: 'QUICK QUOTE',
      icon: 'icon-docs',
      badge: setBadge(validation?.quickQuote),
      url: `${path}/${PathConstants.QuickQuote.QuickQuote.Index}/${PathConstants.QuickQuote.QuickQuote.ApplicantPage}`,
      attributes: {
        disabled : hasMoratoriumClosure
      }
    },
    {
      name: 'COVERAGES',
      icon: 'icon-calculator',
      badge: setBadge(validation?.coverages),
      url: `${path}/${PathConstants.Submission.Submission.Index}/${PathConstants.Submission.Submission.CoveragesAndDeduction}`,
      attributes: {
        disabled : hasMoratoriumClosure
      }
    },
    {
      name: 'ENDORSEMENTS',
      icon: 'icon-target',
      badge: setBadge(validation?.endorsements),
      url: `${path}/${PathConstants.Submission.Submission.Index}/${PathConstants.Submission.Submission.Endorsements}`,
      attributes: {
        disabled : hasMoratoriumClosure
      }
    },
    {
      name: 'CLAIMS',
      icon: 'icon-notebook',
      url: `${path}/${PathConstants.Submission.Submission.Index}/${PathConstants.Submission.Submission.Claims}`,
      attributes: {
        disabled : hasMoratoriumClosure
      }
    },
    {
      name: 'PROPERTY',
      icon: 'icon-info',
      badge: setBadge(validation?.property),
      url: `${path}/${PathConstants.Submission.Submission.Index}/${PathConstants.Submission.Submission.Property}`,
      attributes: {
        disabled : hasMoratoriumClosure
      }
    },
    {
      name: 'APPLICANT',
      icon: 'icon-user',
      badge: setBadge(validation?.applicant && fcraAcknowledgement),
      url: `${path}/${PathConstants.Applicant.Applicant.Index}/${PathConstants.Applicant.Applicant.ApplicantPage}`,
      attributes: {
        disabled : hasMoratoriumClosure
      }
    },
    {
      name: 'UW QUESTIONS',
      icon: 'icon-question',
      badge: setBadge(validation?.uwQuestions),
      url: `${path}/${PathConstants.Submission.Submission.Index}/${PathConstants.Submission.Submission.UWQuestions}`,
      attributes: {
        disabled : hasMoratoriumClosure
      }
    },
    {
      name: 'INTERESTS',
      icon: 'icon-support',
      badge: setBadge(validation?.interest),
      url: `${path}/${PathConstants.Submission.Submission.Index}/${PathConstants.Submission.Submission.Interests}`,
      attributes: {
        disabled : hasMoratoriumClosure
      }
    },
    {
      name: 'UW APPROVAL',
      icon: 'icon-docs',
      badge: setUwApprovalBadge(getEnableUwApproval, userType, showErrorBadgeForExternalUser, hasUwrChanges, validation, hasRenewalOfferChanges),
      url: `${path}/${PathConstants.Submission.Submission.Index}/${PathConstants.Submission.Submission.UWApproval}`,
      attributes: setUwApprovalAttibutes(getEnableUwApproval, hasMoratoriumClosure, hasRenewalOfferChanges)
    },
    {
      name: 'BIND AND ISSUE',
      icon: 'icon-list',
      badge: setBindAndIssueBadge(getEnableBindAndIssue, validation, hasCommissionRate),
      url: `${path}/${PathConstants.Submission.Submission.Index}/${PathConstants.Submission.Submission.BindAndIssue}`,
      attributes: setBindAndIssueAttibutes(getEnableBindAndIssue, hasMoratoriumClosure, hasCommissionRate)
    }
  ];

  switch (userType) {
    case GenericConstants.userType.internal:
      raterMenuItem = [{
        name: 'RATER MATRIX',
        icon: 'icon-plus',
        url: `${path}/${PathConstants.Submission.Submission.Index}/${PathConstants.Submission.Submission.RaterPremiumTable}`
      }];
      break;
  }

  let newsubmissionMenu =[];
  if(isSubmissionReferApprovedAirDeclined){
    if(userType ==  GenericConstants.userType.external){
      if(
        quoteStatus === GenericConstants.quoteStatus.ruw ||
        quoteStatus === GenericConstants.quoteStatus.auw ||
        quoteStatus === GenericConstants.quoteStatus.air ||
        quoteStatus === GenericConstants.quoteStatus.duw
        ){
        submissionMenu.find(res=> {
          if(res.name !='UW QUESTIONS'){
            newsubmissionMenu.push(res)
          }
        })
      }else{
        submissionMenu.find(res=> {
          newsubmissionMenu.push(res)
        })
      }
      submissionMenu = newsubmissionMenu
    }
  }

  return [...submissionMenu, ...raterMenuItem];
};

const pageDisabler = (pageValidity: ValidationListModel): boolean => {
  return !(
    pageValidity?.coverages &&
    pageValidity?.endorsements &&
    pageValidity?.property &&
    pageValidity?.applicant &&
    pageValidity?.uwQuestions &&
    pageValidity?.interest
  );
};

export const enableBindAndIssue = (enableBindAndIssueDetails: IEnableBindAndIssueDetails): boolean => {
  const { enablePage, hasUwrChanges } = enableBindAndIssueDetails;

  if (enablePage) {
    if (hasUwrChanges) {
      return checkHasUwrChanges(enableBindAndIssueDetails);
    }
    return checkNoUwrChanges(enableBindAndIssueDetails);
  }
  return false;
};

const checkHasUwrChanges = (enableBindAndIssueDetails: IEnableBindAndIssueDetails) => {
  const {
    quoteStatus,
    hasUwrChanges = false,
    uwrListEmpty = false,
    uwrListAllPostBind = false,
    uwApprovalListAddedAllPostBind = false,
    hasAir = false,
    hasDecline = false
  } = enableBindAndIssueDetails;
  let enableBindAndIssue: boolean = false;

  if (hasUwrChanges && !uwApprovalListAddedAllPostBind) {
    enableBindAndIssue = false;
  } else {
    if (uwrListEmpty) {
      return true;
    } else {
      if (uwrListAllPostBind) {
        if (quoteStatus === GenericConstants.quoteStatus.air || quoteStatus === GenericConstants.quoteStatus.duw) {
          return false;
        } else {
          return true;
        }
      } else {
        if (quoteStatus === GenericConstants.quoteStatus.auw) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  return enableBindAndIssue;
};

const checkNoUwrChanges = (enableBindAndIssueDetails: IEnableBindAndIssueDetails) => {
  const {
    quoteStatus,
    uwrListEmpty = false,
    uwrListAllPostBind = false,
    hasAir = false,
    hasDecline = false
  } = enableBindAndIssueDetails;

  if (uwrListEmpty) {
    return true;
  } else {
    if (uwrListAllPostBind) {
      if (quoteStatus === GenericConstants.quoteStatus.air || quoteStatus === GenericConstants.quoteStatus.duw) {
        return false;
      } else {
        return true;
      }
    } else {
      if (quoteStatus === GenericConstants.quoteStatus.auw) {
        return true;
      } else {
        return false;
      }
    }
  }
};

const setUwApprovalBadge = (
  getEnableUwApproval: boolean,
  userType: UserType,
  showErrorBadgeForExternalUser: boolean,
  hasUwrChanges: boolean,
  validation: ValidationListModel,
  hasRenewalOfferChanges: boolean
): IBadge => {
  if (getEnableUwApproval && (userType === GenericConstants.userType.internal || showErrorBadgeForExternalUser)) {
    if (hasUwrChanges) {
      return setBadge(false);
    } else {
      if (validation?.uwApproval && !hasRenewalOfferChanges) {
        return setBadge(validation?.uwApproval);
      } else if (validation?.uwApproval && hasRenewalOfferChanges) {
        return setBadge(false, 'i', 'warning');
      } else {
        return setBadge(false);
      }
    }
  } else {
    if (hasRenewalOfferChanges) {
      return setBadge(false, 'i', 'warning');
    } else {
      return {};
    }
  }
};

const setUwApprovalAttibutes = (
  getEnableUwApproval: boolean,
  hasMoratoriumClosure: boolean,
  hasRenewalOfferChanges: boolean
): any => {
  if (!hasRenewalOfferChanges) {
    return {
      disabled : !getEnableUwApproval || hasMoratoriumClosure
    };
  }

  return {
    disabled : !getEnableUwApproval || hasMoratoriumClosure,
    class: 'custom-tooltip',
    'data-title': SubmissionConstants.renewalOfferChangesTooltip
  };
};

const setBindAndIssueBadge = (
  getEnableBindAndIssue: boolean,
  validation: ValidationListModel,
  hasCommissionRate: boolean = false
): IBadge => {
  if (getEnableBindAndIssue && hasCommissionRate) {
    if (!validation?.bindAndIssue) {
      return setBadge(false);
    } else {
      return {};
    }
  } else if (getEnableBindAndIssue && !hasCommissionRate) {
    return setBadge(false, 'i', 'warning');
  } else {
    return {};
  }
};

const setBindAndIssueAttibutes = (
  getEnableBindAndIssue: boolean,
  hasMoratoriumClosure,
  hasCommissionRate: boolean = true
): any => {
  if (!hasCommissionRate) {
    return {
      disabled : !getEnableBindAndIssue || hasMoratoriumClosure,
      class: 'custom-tooltip',
      'data-title': SubmissionConstants.noCommissionRateTooltip
    };
  } else {
    return {
      disabled : !getEnableBindAndIssue || hasMoratoriumClosure
    };
  }
};
