import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { TokenResponse } from '../../shared/models/tokenResponse';
import { FormGroup } from '@angular/forms';
import { AuthService } from './auth.service';
import NotifUtils from '../../shared/utilities/notif-utils';
import Utils from '../../shared/utilities/utils';


@Injectable()
export class AccountService {

    isLogout: boolean;
    isUserLocked: boolean = false;
    unlockingDone$: Subject<boolean> = new Subject<boolean>();
    isAccountLockedUpdated: boolean = false;

    public currentUserRole: string;

    constructor(private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private cookieService: CookieService,
        private authService: AuthService) {
        this.isLogout = true;
    }


    sendForgotPasswordEmail(username: string) {

        const data = {
            username: username,
            appCode: environment.ApplicationId,
            loginUrl: environment.LoginUrl
        };

        return this.http.post(`${environment.GenericServiceUrl}/api/user/forgot-password`, data, { responseType: 'text' }).pipe(
            map((res) => {
                return res;
            }),
            catchError(error => {
                return observableThrowError(error);
            }));
    }


    resetPassword(referenceNum: string, password: string) {

        const data = {
            ref: referenceNum,
            password: password
        };

        return this.http.put(`${environment.GenericServiceUrl}/api/user/forgot-password`, data, { responseType: 'text' }).pipe(
            map((res) => {
                return res;
            }),
            catchError(error => {
                return observableThrowError(error);
            }));

    }


    changePassword(data: any) {
        return this.http.put(`${environment.GenericServiceUrl}/api/user/change-password`, data, { responseType: 'text' }).pipe(
            map((res) => {
                return res;
            }),
            catchError(error => {
                return observableThrowError(error);
            }));
    }

    userResetPassword(data: any) {
        return this.http.put(`${environment.GenericServiceUrl}/api/user/system-change-password`, data, { responseType: 'text' }).pipe(
            map((res) => {
                return res;
            }),
            catchError(error => {
                return observableThrowError(error);
            }));
    }

    changePasswordAtLogin(data: any) {
        return this.http.put(`${environment.GenericServiceUrl}/api/user/change-password-at-login`, data, { responseType: 'text' }).pipe(
            map((res) => {
                return res;
            }),
            catchError(error => {
                return observableThrowError(error);
            }));
    }

    checkAccountLockedToggle(formGroup: FormGroup): void {
      formGroup.get('isAccountLocked').setValue(this.isUserLocked);
      if (this.authService.isAdminUser && this.isUserLocked) {
        formGroup.get('isAccountLocked').enable();
      } else {
        formGroup.get('isAccountLocked').disable();
      }
    }

    unlockAccount(formGroup: FormGroup, username: string, entityId: string): void {
        NotifUtils.showConfirmMessage('Are you sure you want to unlock this account?', () => {
            Utils.blockUI();
            this.authService.unlockUser(username).subscribe(res => {
                if (res > 0) {
                    this.isUserLocked = false;
                    this.authService.insertUnlockAuditLog(username, entityId, true);
                    Utils.unblockUI();
                    NotifUtils.showSuccess('Account has been unlocked');
                    formGroup.get('isAccountLocked').setValue(this.isUserLocked);
                    formGroup.get('isAccountLocked').disable();
                    this.unlockingDone$.next(true);
                } else {
                    this.authService.insertUnlockAuditLog(username, entityId);
                    Utils.unblockUI();
                    NotifUtils.showError('Failed to unlock the user.<br />Please contact your Administrator');
                    formGroup.get('isAccountLocked').setValue(true);
                    this.unlockingDone$.next(false);
                }
            }, err => {
                this.authService.insertUnlockAuditLog(username, entityId);
                Utils.unblockUI();
                formGroup.get('isAccountLocked').setValue(true);
                NotifUtils.showError(err.message);
                this.unlockingDone$.next(false);
            });
        }, () => {
            formGroup.get('isAccountLocked').setValue(true);
            Utils.unblockUI();
            this.unlockingDone$.next(false);
        });
    }
}
