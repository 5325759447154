import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'app/core/services/auth.service';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { RefundSetEscheatDateDTO } from '../../../../../../app/shared/models/data/dto/billing/refund-set-escheat-date.dto';
import * as moment from 'moment';

@Component({
  selector: 'app-escheat-confirmation-modal',
  templateUrl: './escheat-confirmation-modal.component.html',
  styleUrls: ['./escheat-confirmation-modal.component.scss']
})
export class EscheatConfirmationModalComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  message: string;
  actionType: string;
  dateOption: IAngularMyDpOptions;

  ErrorMessageConstant = ErrorMessageConstant;

  escheatForm: FormGroup;

  constructor(protected fb: FormBuilder, protected authService: AuthService) { }

  ngOnInit(): void {

    const currentDate = this.authService.getCustomDate();

    this.escheatForm = this.fb.group({
      escheatDate: new FormControl(currentDate, [Validators.required]),
      comment: new FormControl('', [Validators.required])
    });

    this.dateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy'
    };
  }

  confirm(): void {
    const request: RefundSetEscheatDateDTO = {
      // escheatDate: new Date(new Date(this.escheatForm.get('escheatDate').value.singleDate.jsDate).toLocaleDateString('en-US') + 'UTC'),
      escheatDate: moment(this.escheatForm.get('escheatDate').value.singleDate.jsDate).format('YYYY-MM-DDT00:00:00.000'),
      comments: this.escheatForm.get('comment').value
    };

    this.triggerEvent(request);
  }

  decline(): void {
    this.triggerEvent(false);
  }

  triggerEvent(res: any) {
    this.event.emit({ data: res, res: 200 });
  }
}
