import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CheckboxComponent } from './components/dynamic/checkbox/checkbox.component';
import { DateComponent } from './components/dynamic/date/date.component';
import { InputComponent } from './components/dynamic/input/input.component';
import { SelectComponent } from './components/dynamic/select/select.component';
import { DynamicFormComponent } from './components/dynamic/dynamic-form/dynamic-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DynamicFieldDirective } from './components/dynamic/dynamic-field.directive';
import { RadiobuttonComponent } from './components/dynamic/radiobutton/radiobutton.component';
import { TextAreaComponent } from './components/dynamic/text-area/text-area.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { CcardComponent } from './components/ccard/ccard.component';
import { RadioButtonCardComponent } from './components/radio-button-card/radio-button-card.component';
import { PageHeaderDetailsComponent } from './components/page-header-details/page-header-details.component';
import { NextBackButtonComponent } from './components/dynamic/next-back-button/next-back-button.component';
import { RadioComponent } from './components/radio/radio.component';
import { TableComponent } from './components/dynamic/table/table.component';
import { YesNoRadioComponent } from './components/dynamic/yes-no-radio/yes-no-radio.component';
import { DatePickerComponent } from './components/dynamic/date-picker/date-picker.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { AlertFixedComponent } from './components/dynamic/alert-fixed/alert-fixed.component';
import { PageHeaderSummaryComponent } from './components/page-header-summary/page-header-summary.component';
import { RouterModule } from '@angular/router';
import { TablePaginationComponent } from './components/table-pagination/table-pagination.component';
import { MaskDateDirective } from './directives/mask-date.directive';
import { MaskDateMMYYYYDirective } from './directives/mask-date-mm-yyyy.directive';
import { SortDirective } from './directives/sort.directive';
import { InputRestrictionDirective } from './directives/input-restriction-directive';
import { NameCasePipe } from './custom pipes/name-pipe';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { PolicyEditCancelButtonComponent } from './components/dynamic/policy-edit-cancel-button/policy-edit-cancel-button.component';
import { SuspendedPaymentDetailComponent } from './components/suspended-payment-detail/suspended-payment-detail.component';
import { NotesTableComponent } from './components/notes-table/notes-table.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalEventDirective } from './directives/modal-event.directive';
import { PostPaymentCheckComponent } from '../../app/modules/policy-management/policy-billing/payments/post-payments/post-payment-check/post-payment-check.component';
import { PostPaymentCreditCardComponent } from '../../app/modules/policy-management/policy-billing/payments/post-payments/post-payment-credit-card/post-payment-credit-card.component';
import { PostPaymentEftComponent } from '../../app/modules/policy-management/policy-billing/payments/post-payments/post-payment-eft/post-payment-eft.component';
import { DashboardPaginationComponent } from './components/dashboard-pagination/dashboard-pagination.component';
import { UwrTableComponent } from './components/uwr-table/uwr-table/uwr-table.component';
import { NotesComponent } from '../modules/submission-management/submission/bind-and-issue/notes/notes.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { EntityNameComponent } from '../../app/modules/policy-management/policy-billing/payments/post-payments/entity-name/entity-name.component';
import { ReinstatementRequirementsMetModalComponent } from '../../app/modules/dashboard/components/dashboard/make-payment/reinstatement-requirements-met-modal/reinstatement-requirements-met-modal.component';
import { OutstandingReinstatementRequirementsModalComponent } from '../../app/modules/dashboard/components/dashboard/make-payment/outstanding-reinstatement-requirements-modal/outstanding-reinstatement-requirements-modal.component';
import { PaymentAgreementComponent } from '../modules/policy-management/policy-billing/payments/post-payments/payment-agreement/payment-agreement.component';
import { PolicyCancellationComponent } from '../modules/policy-management/policy-cancellation/policy-cancellation.component';
import { BsModalRef, TypeaheadModule } from 'ngx-bootstrap';
import { PolicyCancellationData } from '../modules/policy-management/data/policy-cancellation.data';
import { GenericAddressComponent } from './components/generic-address/generic-address.component';
import { SearchFieldComponent } from './components/dynamic/search-field/search-field.component';
import { PolicyRewriteData } from '../modules/policy-management/data/policy-rewrite.data';
import { PolicyRewriteComponent } from '../modules/policy-management/policy-actions/policy-rewrite/policy-rewrite.component';
import { PreventDoubleClickDirective } from './directives/prevent-double-click.directive';
import { PolicyNonRenewalComponent } from '../modules/policy-management/policy-non-renewal/policy-non-renewal.component';
import { MakeAPaymentAgreementComponent } from 'app/modules/make-a-payment/make-a-payment-agreement/make-a-payment-agreement.component';
import { ReportsHeaderComponent } from './components/reports-header/reports-header.component';
import { CommissionStatementHeaderComponent } from './components/commission-statement-header/commission-statement-header.component';
import { AutoReinstatementApprovalComponent } from './components/auto-reinstatement-approval/auto-reinstatement-approval.component';
import { CanDeactivateAutoReinstatementApprovalComponentGuard } from './components/auto-reinstatement-approval/auto-reinstatement-approval-navigation-guard';
import { NgSelectModule } from '@ng-select/ng-select';
import { AlertModule } from 'ngx-bootstrap/alert';
import { GetRiskStatusPipe } from './pipes/get-risk-status.pipe';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { PreloaderThreeBounceComponent } from './components/preloader-three-bounce/preloader-three-bounce.component';
import { AopDeductibleMinimumOfRequiredInfoPipe } from './pipes/aop-deductible-minimum-required-info.pipe';
import { HurricaneDeductibleMinimumOf5PercentRequiredPipe } from './pipes/hurricane-deductible-minimum-of-5-percent-required.pipe';
import { UnderMaintenanceComponent } from './components/under-maintenance/under-maintenance.component';
import { CapitalizeFirstLetterPipe } from './pipes/capitalize-first-letter.pipe';
import { MaintenanceModeComponent } from './components/maintenance-mode/maintenance-mode.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { HurricaneDeductibleMinimumPercentRequiredPipe } from './pipes/hurricane-deductible-minimum-percent.pipe';

const maskConfig: Partial<IConfig> = {
    validation: true,
};

export const customCurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: true,
    decimal: '.',
    precision: 0,
    prefix: '$ ',
    suffix: '',
    thousands: ',',
    nullable: true
};

@NgModule({
  declarations: [
    CheckboxComponent,
    DateComponent,
    InputComponent,
    SelectComponent,
    DynamicFormComponent,
    CheckboxComponent,
    DynamicFieldDirective,
    RadiobuttonComponent,
    TextAreaComponent,
    NumberOnlyDirective,
    ConfirmationModalComponent,
    RadioButtonCardComponent,
    CcardComponent,
    PageHeaderDetailsComponent,
    RadioComponent,
    PageHeaderDetailsComponent,
    NextBackButtonComponent,
    PolicyEditCancelButtonComponent,
    TableComponent,
    YesNoRadioComponent,
    DatePickerComponent,
    AlertFixedComponent,
    PageHeaderSummaryComponent,
    TablePaginationComponent,
    MaskDateDirective,
    MaskDateMMYYYYDirective,
    SortDirective,
    InputRestrictionDirective,
    CustomTableComponent,
    PolicyEditCancelButtonComponent,
    SuspendedPaymentDetailComponent,
    NotesTableComponent,
    ModalEventDirective,
    PostPaymentCheckComponent,
    PostPaymentCreditCardComponent,
    PostPaymentEftComponent,
    EntityNameComponent,
    UwrTableComponent,
    NotesComponent,
    ReinstatementRequirementsMetModalComponent,
    OutstandingReinstatementRequirementsModalComponent,
    PaymentAgreementComponent,
    MakeAPaymentAgreementComponent,
    PolicyCancellationComponent,
    GenericAddressComponent,
    SearchFieldComponent,
    PolicyRewriteComponent,
    PreventDoubleClickDirective,
    SearchFieldComponent,
    PolicyNonRenewalComponent,
    ReportsHeaderComponent,
    CommissionStatementHeaderComponent,
    AutoReinstatementApprovalComponent,
    GetRiskStatusPipe,
    PreloaderComponent,
    PreloaderThreeBounceComponent,
    AopDeductibleMinimumOfRequiredInfoPipe,
    HurricaneDeductibleMinimumOf5PercentRequiredPipe,
    HurricaneDeductibleMinimumPercentRequiredPipe,
    CapitalizeFirstLetterPipe,
    UnderMaintenanceComponent,
    MaintenanceModeComponent,
    FileUploadComponent
  ],
  imports: [
    SweetAlert2Module,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgxMaskModule.forRoot(maskConfig),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    AngularMyDatePickerModule,
    RouterModule,
    TooltipModule,
    BsDropdownModule.forRoot(),
    TypeaheadModule.forRoot(),
    NgSelectModule,
    AlertModule.forRoot()
  ],
  entryComponents: [
    DynamicFormComponent,
    CheckboxComponent,
    DateComponent,
    InputComponent,
    SelectComponent,
    RadiobuttonComponent,
    TextAreaComponent,
    ConfirmationModalComponent,
    ReinstatementRequirementsMetModalComponent,
    OutstandingReinstatementRequirementsModalComponent,
    PolicyCancellationComponent,
    GenericAddressComponent,
    PolicyRewriteComponent,
    PolicyNonRenewalComponent
  ],
  exports: [
    SweetAlert2Module,
    DynamicFieldDirective,
    DynamicFormComponent,
    NgxCurrencyModule,
    ReactiveFormsModule,
    FormsModule,
    NumberOnlyDirective,
    RadioButtonCardComponent,
    CcardComponent,
    PageHeaderDetailsComponent,
    RadioComponent,
    NextBackButtonComponent,
    PolicyEditCancelButtonComponent,
    TableComponent,
    YesNoRadioComponent,
    DatePickerComponent,
    AlertFixedComponent,
    PageHeaderSummaryComponent,
    TablePaginationComponent,
    MaskDateDirective,
    MaskDateMMYYYYDirective,
    SortDirective,
    InputRestrictionDirective,
    CustomTableComponent,
    SuspendedPaymentDetailComponent,
    NotesTableComponent,
    ModalEventDirective,
    PostPaymentCheckComponent,
    PostPaymentCreditCardComponent,
    PostPaymentEftComponent,
    EntityNameComponent,
    UwrTableComponent,
    NotesComponent,
    ReinstatementRequirementsMetModalComponent,
    OutstandingReinstatementRequirementsModalComponent,
    PaymentAgreementComponent,
    MakeAPaymentAgreementComponent,
    PolicyCancellationComponent,
    SearchFieldComponent,
    PolicyRewriteComponent,
    PreventDoubleClickDirective,
    PolicyNonRenewalComponent,
    ReportsHeaderComponent,
    CommissionStatementHeaderComponent,
    AutoReinstatementApprovalComponent,
    NgSelectModule,
    AlertModule,
    GetRiskStatusPipe,
    PreloaderComponent,
    PreloaderThreeBounceComponent,
    AopDeductibleMinimumOfRequiredInfoPipe,
    HurricaneDeductibleMinimumOf5PercentRequiredPipe,
    HurricaneDeductibleMinimumPercentRequiredPipe,
    CapitalizeFirstLetterPipe,
    FileUploadComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    NameCasePipe,
    CapitalizeFirstLetterPipe,
    BsModalRef,
    PolicyCancellationData,
    PolicyRewriteData,
    CanDeactivateAutoReinstatementApprovalComponentGuard,
    GetRiskStatusPipe
  ]
})


export class SharedModule { }
