export class UploadCheckImageRequestDTO implements IUploadCheckImageRequestDTO {
  riskId?: string | undefined;
  file?: any;
  fileName?: string | undefined;
  filePath: string | undefined;

  constructor(data?: IUploadCheckImageRequestDTO) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }
}

export interface IUploadCheckImageRequestDTO {
  riskId?: string | undefined;
  file?: any;
  fileName?: string | undefined;
  filePath: string | undefined;
}
