export const LvMaritalStatuses = [
    {
        tableName: 'LvMaritalStatus',
        tableDescription: 'Marital Status',
        code: 'D',
        description: 'Divorced',
        isActive: true
    },
    {
        tableName: 'LvMaritalStatus',
        tableDescription: 'Marital Status',
        code: 'M',
        description: 'Married',
        isActive: true
    },
    {
        tableName: 'LvMaritalStatus',
        tableDescription: 'Marital Status',
        code: 'SE',
        description: 'Separated',
        isActive: true
    },
    {
        tableName: 'LvMaritalStatus',
        tableDescription: 'Marital Status',
        code: 'SI',
        description: 'Single',
        isActive: true
    },
    {
        tableName: 'LvMaritalStatus',
        tableDescription: 'Marital Status',
        code: 'W',
        description: 'Widowed',
        isActive: true
    }
];

export const LvRelationshipToApplicant = [
    {
        tableName: 'LvRelationshipToApplicant',
        tableDescription: 'Relationship to Applicant',
        code: 'S',
        description: 'Spouse',
        isActive: true
    },
    {
        tableName: 'LvRelationshipToApplicant',
        tableDescription: 'Relationship to Applicant',
        code: 'P',
        description: 'Parent',
        isActive: true
    },
    {
        tableName: 'LvRelationshipToApplicant',
        tableDescription: 'Relationship to Applicant',
        code: 'C',
        description: 'Child',
        isActive: true
    },
    {
        tableName: 'LvRelationshipToApplicant',
        tableDescription: 'Relationship to Applicant',
        code: 'R',
        description: 'Relative',
        isActive: true
    },
    {
        tableName: 'LvRelationshipToApplicant',
        tableDescription: 'Relationship to Applicant',
        code: 'RM',
        description: 'Roommate',
        isActive: true
    },
    {
        tableName: 'LvRelationshipToApplicant',
        tableDescription: 'Relationship to Applicant',
        code: 'O',
        description: 'Other ',
        isActive: true
    }
];

export const LvGender = [
    {
        tableName: 'LvGender',
        tableDescription: 'Gender',
        code: 'M',
        description: 'Male',
        isActive: true
    },
    {
        tableName: 'LvGender',
        tableDescription: 'Gender',
        code: 'F',
        description: 'Female',
        isActive: true
    }
];

export const LvCities = [
    { name: 'Miami', value: '01' },
    { name: 'Orlando', value: '02' },
    { name: 'Tampa', value: '03' },
    { name: 'Jacksonville', value: '04' },
];

export const LvCountries = [
    /**
     * NOTE: Modify LvCountries table in Centauri DB if
     * there is any modification done in this array
     */
    { name: 'Afghanistan', value: 'AF' },
    { name: 'Albania', value: 'AL' },
    { name: 'Algeria', value: 'DZ' },
    // { name: 'American Samoa', value: 'AS' },
    { name: 'Andorra', value: 'AD' },
    { name: 'Angola', value: 'AO' },
    { name: 'Antigua and Barbuda', value: 'AG' },
    { name: 'Argentina', value: 'AR' },
    { name: 'Armenia', value: 'AM' },
    { name: 'Australia', value: 'AU' },
    { name: 'Austria', value: 'AT' },
    { name: 'Azerbaijan', value: 'AZ' },
    { name: 'Bahamas', value: 'BS' },
    { name: 'Bahrain', value: 'BH' },
    { name: 'Bangladesh', value: 'BD' },
    { name: 'Barbados', value: 'BB' },
    { name: 'Belarus', value: 'BY' },
    { name: 'Belgium', value: 'BE' },
    { name: 'Belize', value: 'BZ' },
    { name: 'Benin', value: 'BJ' },
    { name: 'Bhutan', value: 'BT' },
    { name: 'Bolivia', value: 'BO' },
    { name: 'Bosnia and Herzegovina', value: 'BA' },
    { name: 'Botswana', value: 'BW' },
    { name: 'Brazil', value: 'BR' },
    { name: 'Brunei', value: 'BN' },
    { name: 'Bulgaria', value: 'BG' },
    { name: 'Burkina Faso', value: 'BF' },
    { name: 'Burundi', value: 'BI' },
    { name: 'Cabo Verde', value: 'CV' },
    { name: 'Cambodia', value: 'KH' },
    { name: 'Cameroon', value: 'CM' },
    { name: 'Canada', value: 'CA' },
    { name: 'Central African Republic', value: 'CF' },
    { name: 'Chad', value: 'TD' },
    { name: 'Chile', value: 'CL' },
    { name: 'China', value: 'CN' },
    { name: 'Colombia', value: 'CO' },
    { name: 'Comoros', value: 'KM' },
    { name: 'Congo, Democratic Republic of the', value: 'CD' },
    { name: 'Congo, Republic of the', value: 'CG' },
    { name: 'Costa Rica', value: 'CR' },
    { name: `Cote D'Ivoire`, value: 'CI' },
    { name: 'Croatia', value: 'HR' },
    { name: 'Cuba', value: 'CU' },
    { name: 'Cyprus', value: 'CY' },
    { name: 'Czechia', value: 'CZ' },
    { name: 'Denmark', value: 'DK' },
    { name: 'Djibouti', value: 'DJ' },
    { name: 'Dominica', value: 'DM' },
    { name: 'Dominican Republic', value: 'DO' },
    { name: 'Ecuador', value: 'EC' },
    { name: 'Egypt', value: 'EG' },
    { name: 'El Salvador', value: 'SV' },
    { name: 'Equatorial Guinea', value: 'GQ' },
    { name: 'Eritrea', value: 'ER' },
    { name: 'Estonia', value: 'EE' },
    { name: 'Eswatini (formerly Swaziland)', value: 'SZ' },
    { name: 'Ethiopia', value: 'ET' },
    { name: 'Fiji', value: 'FJ' },
    { name: 'Finland', value: 'FI' },
    { name: 'France', value: 'FR' },
    { name: 'Gabon', value: 'GA' },
    { name: 'Gambia', value: 'GM' },
    { name: 'Georgia', value: 'GE' },
    { name: 'Germany', value: 'DE' },
    { name: 'Ghana', value: 'GH' },
    { name: 'Greece', value: 'GR' },
    { name: 'Grenada', value: 'GD' },
    { name: 'Guatemala', value: 'GT' },
    { name: 'Guinea', value: 'GN' },
    { name: 'Guinea-Bissau', value: 'GW' },
    { name: 'Guyana', value: 'GY' },
    { name: 'Haiti', value: 'HT' },
    { name: 'Honduras', value: 'HN' },
    { name: 'Hungary', value: 'HU' },
    { name: 'Iceland', value: 'IS' },
    { name: 'India', value: 'IN' },
    { name: 'Indonesia', value: 'ID' },
    { name: 'Iran', value: 'IR' },
    { name: 'Iraq', value: 'IQ' },
    { name: 'Ireland', value: 'IE' },
    { name: 'Israel', value: 'IL' },
    { name: 'Italy', value: 'IT' },
    { name: 'Jamaica', value: 'JM' },
    { name: 'Japan', value: 'JP' },
    { name: 'Jordan', value: 'JO' },
    { name: 'Kazakhstan', value: 'KZ' },
    { name: 'Kenya', value: 'KE' },
    { name: 'Kiribati', value: 'KI' },
    { name: 'Kosovo', value: 'XK' },
    { name: 'Kuwait', value: 'KW' },
    { name: 'Kyrgyzstan', value: 'KG' },
    { name: `Laos`, value: 'LA' },
    { name: 'Latvia', value: 'LV' },
    { name: 'Lebanon', value: 'LB' },
    { name: 'Lesotho', value: 'LS' },
    { name: 'Liberia', value: 'LR' },
    { name: 'Libya', value: 'LY' },
    { name: 'Liechtenstein', value: 'LI' },
    { name: 'Lithuania', value: 'LT' },
    { name: 'Luxembourg', value: 'LU' },
    { name: 'Madagascar', value: 'MG' },
    { name: 'Malawi', value: 'MW' },
    { name: 'Malaysia', value: 'MY' },
    { name: 'Maldives', value: 'MV' },
    { name: 'Mali', value: 'ML' },
    { name: 'Malta', value: 'MT' },
    { name: 'Marshall Islands', value: 'MH' },
    { name: 'Mauritania', value: 'MR' },
    { name: 'Mauritius', value: 'MU' },
    { name: 'Mexico', value: 'MX' },
    { name: 'Micronesia', value: 'FM' },
    // { name: 'Moldova, Republic of', value: 'MD' },
    { name: 'Moldova', value: 'MD' },
    { name: 'Monaco', value: 'MC' },
    { name: 'Mongolia', value: 'MN' },
    { name: 'Montenegro', value: 'ME' },
    { name: 'Morocco', value: 'MA' },
    { name: 'Mozambique', value: 'MZ' },
    { name: 'Myanmar (formerly Burma)', value: 'MM' },
    { name: 'Namibia', value: 'NA' },
    { name: 'Nauru', value: 'NR' },
    { name: 'Nepal', value: 'NP' },
    { name: 'Netherlands', value: 'NL' },
    { name: 'New Zealand', value: 'NZ' },
    { name: 'Nicaragua', value: 'NI' },
    { name: 'Niger', value: 'NE' },
    { name: 'Nigeria', value: 'NG' },
    { name: 'North Korea', value: 'KP' },
    { name: 'North Macedonia', value: 'MK' },
    { name: 'Norway', value: 'NO' },
    { name: 'Oman', value: 'OM' },
    { name: 'Pakistan', value: 'PK' },
    { name: 'Palau', value: 'PW' },
    { name: 'Palestine', value: 'PS' },
    { name: 'Panama', value: 'PA' },
    { name: 'Papua New Guinea', value: 'PG' },
    { name: 'Paraguay', value: 'PY' },
    { name: 'Peru', value: 'PE' },
    { name: 'Philippines', value: 'PH' },
    { name: 'Poland', value: 'PL' },
    { name: 'Portugal', value: 'PT' },
    { name: 'Qatar', value: 'QA' },
    { name: 'Romania', value: 'RO' },
    { name: 'Russia', value: 'RU' },
    { name: 'Rwanda', value: 'RW' },
    { name: 'Saint Kitts and Nevis', value: 'KN' },
    { name: 'Saint Lucia', value: 'LC' },
    { name: 'Saint Vincent and the Grenadines', value: 'VC' },
    { name: 'Samoa', value: 'WS' },
    { name: 'San Marino', value: 'SM' },
    { name: 'Sao Tome and Principe', value: 'ST' },
    { name: 'Saudi Arabia', value: 'SA' },
    { name: 'Senegal', value: 'SN' },
    { name: 'Serbia', value: 'CS' },
    { name: 'Seychelles', value: 'SC' },
    { name: 'Sierra Leone', value: 'SL' },
    { name: 'Singapore', value: 'SG' },
    { name: 'Slovakia', value: 'SK' },
    { name: 'Slovenia', value: 'SI' },
    { name: 'Solomon Islands', value: 'SB' },
    { name: 'Somalia', value: 'SO' },
    { name: 'South Africa', value: 'ZA' },
    { name: 'South Korea', value: 'KR' },
    { name: 'South Sudan', value: 'SS' },
    { name: 'Spain', value: 'ES' },
    { name: 'Sri Lanka', value: 'LK' },
    { name: 'Sudan', value: 'SD' },
    { name: 'Suriname', value: 'SR' },
    { name: 'Sweden', value: 'SE' },
    { name: 'Switzerland', value: 'CH' },
    { name: 'Syria', value: 'SY' },
    { name: 'Taiwan', value: 'TW' },
    { name: 'Tajikistan', value: 'TJ' },
    { name: 'Tanzania', value: 'TZ' },
    { name: 'Thailand', value: 'TH' },
    { name: 'Timor-Leste', value: 'TL' },
    { name: 'Togo', value: 'TG' },
    { name: 'Tonga', value: 'TO' },
    { name: 'Trinidad and Tobago', value: 'TT' },
    { name: 'Tunisia', value: 'TN' },
    { name: 'Turkey', value: 'TR' },
    { name: 'Turkmenistan', value: 'TM' },
    { name: 'Tuvalu', value: 'TV' },
    { name: 'Uganda', value: 'UG' },
    { name: 'Ukraine', value: 'UA' },
    { name: 'United Arab Emirates (UAE)', value: 'AE' },
    { name: 'United Kingdom (UK)', value: 'GB' },
    { name: 'United States of America (USA)', value: 'USA' },
    { name: 'Uruguay', value: 'UY' },
    { name: 'Uzbekistan', value: 'UZ' },
    { name: 'Vanuatu', value: 'VU' },
    { name: 'Vatican City (Holy See)', value: 'VA' },
    { name: 'Venezuela', value: 'VE' },
    { name: 'Vietnam', value: 'VN' },
    { name: 'Yemen', value: 'YE' },
    { name: 'Zambia', value: 'ZM' },
    { name: 'Zimbabwe', value: 'ZW' }
    
    /**
     * NOTE: Modify LvCountries table in Centauri DB if
     * there is any modification done in this array
     */
];

export const LvCreditScoreStatus = [
    { description: 'Not Ordered', code: 'COS0' },
    { description: 'Ordered - No Hit', code: 'COS1' },
    { description: 'Ordered - No Score', code: 'COS2' },
    { description: 'Ordered - Score Received', code: 'COS3' }
];

export const LvNameSuffix = [
    {
        tableName: 'LvNameSuffix',
        tableDescription: 'Suffix',
        code: 'Jr.',
        description: 'Jr.',
        isActive: true
    },
    {
        tableName: 'LvNameSuffix',
        tableDescription: 'Suffix',
        code: 'Sr.',
        description: 'Sr.',
        isActive: true
    },
    {
        tableName: 'LvNameSuffix',
        tableDescription: 'Suffix',
        code: 'I',
        description: 'I',
        isActive: true
    },
    {
        tableName: 'LvNameSuffix',
        tableDescription: 'Suffix',
        code: 'II',
        description: 'II',
        isActive: true
    },

    {
        tableName: 'LvNameSuffix',
        tableDescription: 'Suffix',
        code: 'III',
        description: 'III',
        isActive: true
    },
    {
        tableName: 'LvNameSuffix',
        tableDescription: 'Suffix',
        code: 'IV',
        description: 'IV',
        isActive: true
    }
];

export const LvInspectionOrderingType = [
    {
        tableName: 'LvInspectionOrderingType',
        tableDescription: 'InspectionOrderingType',
        code: '4',
        description: '4 Point - New Business',
        isActive: true
    },
    {
        tableName: 'LvInspectionOrderingType',
        tableDescription: 'InspectionOrderingType',
        code: '4_RN',
        description: '4 Point - Renewal',
        isActive: true
    },
    {
        tableName: 'LvInspectionOrderingType',
        tableDescription: 'InspectionOrderingType',
        code: 'EOM',
        description: 'Exterior Observation & Measurement - New Business',
        isActive: true
    },
    {
        tableName: 'LvInspectionOrderingType',
        tableDescription: 'InspectionOrderingType',
        code: 'BEH',
        description: 'Exterior Observation & Measurement + 4 Point - New Business',
        isActive: true
    },
    {
        tableName: 'LvInspectionOrderingType',
        tableDescription: 'InspectionOrderingType',
        code: 'BEH_RN',
        description: 'Exterior Observation & Measurement + 4 Point - Renewal',
        isActive: true
    },
    {
        tableName: 'LvInspectionOrderingType',
        tableDescription: 'InspectionOrderingType',
        code: 'EOM_Project',
        description: 'Exterior Observation & Measurement Project',
        isActive: true
    },
    {
        tableName: 'LvInspectionOrderingType',
        tableDescription: 'InspectionOrderingType',
        code: 'B',
        description: 'Exterior Photo - New Business',
        isActive: true
    },
    {
        tableName: 'LvInspectionOrderingType',
        tableDescription: 'InspectionOrderingType',
        code: 'B_RN',
        description: 'Exterior Photo - Renewal',
        isActive: true
    },
    {
        tableName: 'LvInspectionOrderingType',
        tableDescription: 'InspectionOrderingType',
        code: 'HVH',
        description: 'High Value + 4 Point - New Business',
        isActive: true
    },
    {
        tableName: 'LvInspectionOrderingType',
        tableDescription: 'InspectionOrderingType',
        code: 'HVH_RN',
        description: 'High Value + 4 Point - Renewal',
        isActive: true
    },
    {
        tableName: 'LvInspectionOrderingType',
        tableDescription: 'InspectionOrderingType',
        code: 'HVH_Condo',
        description: 'High Value + 4 Point Condo',
        isActive: true
    },
    {
        tableName: 'LvInspectionOrderingType',
        tableDescription: 'InspectionOrderingType',
        code: 'EO_RN',
        description: 'Exterior without RCE - Renewal',
        isActive: true
    },
    {
        tableName: 'LvInspectionOrderingType',
        tableDescription: 'InspectionOrderingType',
        code: 'SI',
        description: 'Self Inspection - New Business',
        isActive: true
    },
    {
        tableName: 'LvInspectionOrderingType',
        tableDescription: 'InspectionOrderingType',
        code: 'SI_RN',
        description: 'Self Inspection - Renewal',
        isActive: true
    },
]