import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApplicationInformationDTO } from '../../../shared/models/data/dto/third-party/application-information.dto';
import { APlusClaimsRequestDTO } from '../../../shared/models/data/dto/third-party/aplus-claims-request.dto';
import { AplusLastCallStatusDTO } from '../../../shared/models/data/dto/third-party/aplus-last-call-status.dto';
import { BaseClass } from '../../../shared/base-class';
import { ApplicantData } from './applicant.data';
import { AllResponseDTO } from '../../../shared/models/data/dto/third-party/all-response.dto';
import { PropertyData } from './property.data';
import { CoveragesData } from './coverages.data';
import { BasePropertyDataModel, ResidentialResponseDTO } from '../../../shared/models/data/dto/third-party/residential-response.dto';
import { ResidentialRequestDTO } from '../../../shared/models/data/dto/third-party/residential-request.dto';
import { SaveIso360PropertyDTO } from '../../../shared/models/data/dto/quick-quote/save-iso360-property.dto';
import { SummaryData } from './summary.data';
import { Iso360PropertyDTO } from '../../../shared/models/data/dto/quick-quote/risk-iso360-property.dto';
import { RiskClaimDTO } from '../../../shared/models/data/dto/third-party/risk-claims.dto';
import { Iso360ValueMappingData } from '../data/iso360value-mapping.data';
import { EntityRiskDTO } from '../../../shared/models/data/dto/quick-quote/entity-risk.dto';
import { EntityRiskData } from './entity-risk.data';
import { PolicySummaryData } from '../../../modules/policy-management/data/policy-summary.data';
import { CreditScoreRiskResponseDTO } from '../../../shared/models/data/dto/third-party/credit-score-risk-response.dto';
import { LvCreditOrderStatus, LvCreditOrderStatusIndex, LvEstimatedCredit, LvEstimatedCreditIndex } from '../../../shared/constants/quick-quote.options.constants';
import { Subject } from 'rxjs';
import { CreditOrderDetail } from '../../../shared/models/third-party/credit-order-detail';
import { CreditScoreRiskRequestDTO } from '../../../shared/models/data/dto/third-party/credit-score-risk-request.dto';
import { ThirdPartyDataService } from '../../../core/services/submission/third-party/third-party-data.service';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessageConstant } from '../../../shared/constants/error-message.constants';
import { MajorBodyOfWater } from '../../../shared/constants/major-body-of-water.list';
import { AirHazardResponseDTO } from '../../../shared/models/data/dto/third-party/air-hazard-response.dto';
import { AirHazardRequestDTO } from '../../../shared/models/data/dto/third-party/air-hazard-request.dto';
import { TorrentResponseDTO } from '../../../shared/models/data/dto/third-party/torrent-flood-quote.dto';
import { TorrentRequestDTO } from '../../../shared/models/data/dto/third-party/torrent-request.dto';
import { CoveragesLabelsConstants } from '../../../shared/constants/coverages.labels.constants';
import { LocationLabelConstants, PropertyLabelConstants } from '../../../shared/constants/property.labels.constants';
import { QuickQuoteFormConstant, QuickQuotesLabelConstans } from '../../../shared/constants/quick-quotes.labels.constants';
import Utils from '../../../shared/utilities/utils';
import { QuoteService } from 'app/core/services/quote.service';
import { FormTypeConstants } from '../../../shared/constants/form-types.constants';
import { FloodAssistRequestDTO } from '../../../shared/models/data/dto/third-party/flood-assist-request.dto';
import { FloodAssistResponseDTO } from '../../../shared/models/data/dto/third-party/flood-assist-response.dto';
import { AddressType } from '../../../shared/enum/address-type.enum';
import { Validators } from '@angular/forms';
import { QuickQuoteData } from './quick-quote.data';
import { FloodAssistOrderPrefillDTO } from 'app/shared/models/data/dto/third-party/flood-assist-order-prefill.dto';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { AuthService } from 'app/core/services/auth.service';
import { UWR } from 'app/core/services/submission/uw-referrals/uwr.service';
import { SubmissionDefaultData } from './submission-default.data';
import { SubmissionConstants } from 'app/shared/constants/submission.constants';
import { ClaimsData } from './claims.data';
import { ClaimsModalModel } from '../../../shared/models/submission/claims/claims.model';
import { ThirdPartyErrorDTO } from 'app/shared/models/data/dto/third-party/third-party-error.dto';
import * as moment from 'moment';
import { EndorsementsData } from './endorsements.data';
import { ClaimsChargeableService } from 'app/core/services/submission/claims/claims-chargeable.service';
import { GenericConstants } from '../../../shared/constants/generic.constants';
import { ComparativeRequestPayload } from '../../../shared/models/rater-premium/comparativeRate.model';
import { ComparativeRaterData } from '../../comparative-rater/comparative-rater.data';
import { IIso360ValueResultDTO } from 'app/shared/models/data/dto/third-party/iso360value-result.dto';
import { updateRecalculateValuationIsLoadingFromThirdPartyData } from 'app/store/recalculate-valuation/racalculate-valuation.actions';
import { Store } from '@ngrx/store';
import { updateCalculateValuationDataFromThirdPartyData, updateCalculateValuationIsLoadingFromThirdPartyData } from 'app/store/calculate-valuation/calculate-valuation.actions';

export const AIRHAZARD2010YRSTART = 2010;
export const PROPERTYUSAGEDEFAULT = 'OOP';

@Injectable({
  providedIn: 'root'
})

export class ThirdPartyData extends BaseClass {
  protected _dataFromAPIResponse: AllResponseDTO;
  protected _dataFromAPlusResponse: RiskClaimDTO;
  public creditOrderDetail$: Subject<CreditOrderDetail> = new Subject<CreditOrderDetail>();
  public applicantCreditOrder$: Subject<boolean> = new Subject<boolean>();
  public applicantCreditOrderDialog$: Subject<boolean> = new Subject<boolean>();
  public coveragesSectionOrderCredit$: Subject<boolean> = new Subject<boolean>();
  public quickQuoteCreditOrderPrefill$: Subject<boolean> = new Subject<boolean>();
  protected excellentCreditScore: number = 900;
  protected isInternalUser: boolean = false;

  protected _isNoHitIso360ValueFirstCall: boolean;
  protected formTypeConstants = FormTypeConstants;

  public iso360YearBuilt: number | null;
  public iso360YearBuiltValue$: Subject<boolean> = new Subject<boolean>();

  public QuickQuoteFormConstant = QuickQuoteFormConstant;
  public genericConstants = GenericConstants;

  public isDataFromOrderPrefill: boolean = false;
  public isCurrentlyOrdering: boolean = false;

  public isCovAAndReplacementCostTheSame$: Subject<any> = new Subject<any>();

  public get dataFromAPIResponse(): AllResponseDTO {
    return this._dataFromAPIResponse;
  }
  public set dataFromAPIResponse(value: AllResponseDTO) {
    this._dataFromAPIResponse = value;
  }
  public get dataFromAPlusResponse(): RiskClaimDTO {
    return this._dataFromAPlusResponse;
  }
  public set dataFromAPlusResponse(value: RiskClaimDTO) {
    this._dataFromAPlusResponse = value;
  }
  public get costPerSqft(): string {
    return this.dataFromAPIResponse?.iso360Value?.costPerSqft;
  }
  public set costPerSqft(value: string) {
    this.dataFromAPIResponse.iso360Value.costPerSqft = value;
  }
  public getEntityRisk(): EntityRiskDTO {
    return this.entityRiskData.EntityRisk;
  }
  public get isNoHitIso360ValueFirstCall(): boolean {
    return this._isNoHitIso360ValueFirstCall;
  }
  public set isNoHitIso360ValueFirstCall(value: boolean) {
    this._isNoHitIso360ValueFirstCall = value;
  }

  valuationFields: string[] = [
    'yearBuilt',
    'squareFootage',
    'exteriorWallFinish',
    'constructionType',
    'numOfStories',
    'roofShape',
    'roofMaterial',
    'foundationType'
  ];

  constructor(
    protected applicantData: ApplicantData,
    protected propertyData: PropertyData,
    protected summaryData: SummaryData,
    protected coveragesData: CoveragesData,
    protected iso360ValueMapping: Iso360ValueMappingData,
    protected entityRiskData: EntityRiskData,
    protected policySummaryData: PolicySummaryData,
    protected thirdPartyDataService: ThirdPartyDataService,
    protected toastr: ToastrService,
    protected quoteService: QuoteService,
    protected quickQuoteData: QuickQuoteData,
    protected authService: AuthService,
    protected submissionDefaultData: SubmissionDefaultData,
    protected claimsData: ClaimsData,
    protected claimsChargeableService: ClaimsChargeableService,
    protected comparativeRaterData: ComparativeRaterData,
    protected store: Store
  ) {
    super();
    this.summaryData.authService.userType.subscribe(userType => {
      this.isInternalUser = this.validateInternalUser(userType);
    });
  }

  getPrefillRequestModel(): ApplicationInformationDTO {
    const applicantForm = this.applicantData.mainApplicant;
    return new ApplicationInformationDTO({
      firstName: applicantForm.get('firstName').value,
      middleInitial: applicantForm.get('middleInitial').value,
      lastName: applicantForm.get('lastName').value,
      // effectiveDate: new Date(applicantForm.get('effectiveDate').value?.singleDate?.jsDate + 'UTC'),
      // dateOfBirth: new Date(applicantForm.get('dateOfBirth').value?.singleDate?.jsDate + 'UTC'),
      effectiveDate: moment(applicantForm.get('effectiveDate').value?.singleDate?.jsDate).format('YYYY-MM-DDT00:00:00.000'),
      dateOfBirth: moment(applicantForm.get('dateOfBirth').value?.singleDate?.jsDate).format('YYYY-MM-DDT00:00:00.000'),
      streetAddress: applicantForm.get('addressLine1').value,
      aptUnit: applicantForm.get('addressLine2').value,
      city: applicantForm.get('qqCity').value,
      state: applicantForm.get('qqState').value,
      zipCode: applicantForm.get('qqZipCode').value,
      retired: applicantForm.get('isRetiredYesNo').value,
      isOrderCredit: applicantForm.get('isOrderYesNo').value,
      formType: this.quoteService.formType ?? this.summaryData.SummaryForm.get('formType').value,
      riskId: applicantForm.get('isOrderYesNo').value ? this.entityRiskData.getRiskId() : '',
      isInternal: this.isInternalUser,
      subAgencyId: this.applicantData.copiedInformation ? this.applicantData.copiedInformation.subAgencyId : this.summaryData.authService.subAgenciesInfo?.id ?? '',
      riskDetailId: this.summaryData.SummaryForm.get('riskDetailId').value,
      quoteNumber: this.summaryData.SummaryForm.get('quoteNumber').value
    });
  }

  getApplicantInformationModel(): ApplicationInformationDTO {
    const applicantForm = this.applicantData.mainApplicant;
    return new ApplicationInformationDTO({
      firstName: applicantForm.get('firstName').value,
      middleInitial: applicantForm.get('middleInitial').value,
      lastName: applicantForm.get('lastName').value,
      effectiveDate: moment(applicantForm.get('effectiveDate').value?.singleDate?.jsDate).format('YYYY-MM-DDT00:00:00.000'),
      dateOfBirth: moment(applicantForm.get('dateOfBirth').value?.singleDate?.jsDate).format('YYYY-MM-DDT00:00:00.000'),
      // effectiveDate: new Date(applicantForm.get('effectiveDate').value?.singleDate?.jsDate),
      // dateOfBirth: new Date(applicantForm.get('dateOfBirth').value?.singleDate?.jsDate + 'UTC'),
      streetAddress: applicantForm.get('addressLine1').value,
      aptUnit: applicantForm.get('aptUnit').value,
      city: applicantForm.get('qqCity').value,
      state: applicantForm.get('qqState').value,
      zipCode: applicantForm.get('qqZipCode').value,
      retired: applicantForm.get('isRetiredYesNo').value,
      isOrderCredit: applicantForm.get('isOrderYesNo').value,
      formType: this.quoteService.formType ?? this.summaryData.SummaryForm.get('formType').value,
      riskId: applicantForm.get('isOrderYesNo').value ? this.entityRiskData.getRiskId() : '',
      riskDetailId: this.summaryData.SummaryForm.get('riskDetailId').value,
      quoteNumber: this.summaryData.SummaryForm.get('quoteNumber').value
    });
  }

  getOrderCreditRequestModel(): CreditScoreRiskRequestDTO {
    const applicantForm = this.applicantData.mainApplicant;
    return new CreditScoreRiskRequestDTO({
      firstName: applicantForm.get('firstName').value,
      middleName: applicantForm.get('middleInitial').value,
      lastName: applicantForm.get('lastName').value,
      // birthDate: new Date(applicantForm.get('dateOfBirth').value?.singleDate?.jsDate + 'UTC'),
      birthDate: moment(applicantForm.get('dateOfBirth').value?.singleDate?.jsDate).format('YYYY-MM-DDT00:00:00.000'),
      streetAddress1: applicantForm.get('addressLine1').value,
      unitNumber: applicantForm.get('aptUnit').value,
      city: applicantForm.get('qqCity').value,
      state: applicantForm.get('qqState').value,
      zipCode: applicantForm.get('qqZipCode').value,
      isInternal: this.isInternalUser,
      subAgencyId: this.authService.subAgenciesInfo?.id,
      riskId: this.entityRiskData.getRiskId()
    });
  }

  getIso360ValueRequestModel(): ResidentialRequestDTO {
    const applicantForm = this.applicantData.mainApplicant;
    const propertyDetails = this.propertyData.propertyDetails;
    const locationDetails = this.propertyData.locationDetails;

    const data = new ResidentialRequestDTO({
      valuationId: locationDetails.get('valuationId').value,
      name: `${applicantForm.get('firstName').value} ${applicantForm.get('lastName').value}`,
      streetAddress: applicantForm.get('addressLine1').value || this.getEntityRisk()?.entityAddresses[0]?.address?.streetAddress1,
      city: applicantForm.get('qqCity').value || this.getEntityRisk()?.entityAddresses[0]?.address?.city,
      state: applicantForm.get('qqState').value || this.getEntityRisk()?.entityAddresses[0]?.address?.state,
      unit: applicantForm.get('aptUnit').value || this.getEntityRisk()?.entityAddresses[0]?.address?.streetAddress2,
      zipcode: applicantForm.get('qqZipCode')?.value || this.getEntityRisk()?.entityAddresses[0]?.address?.zipCode,
      yearBuilt: !propertyDetails.get('yearBuilt').valid ? this.propertyData.validYearBuilt : propertyDetails.get('yearBuilt').value,
      country: `USA`,
      numberOfStories: propertyDetails.get('numOfStories').value,
      roofType: propertyDetails.get('roofShape').value,
      squareFootage: propertyDetails.get('squareFootage').value === '' ? 0 : propertyDetails.get('squareFootage').value,
      structureType: locationDetails.get('structureType').value,
      exteriorWallConstructionType: this.dataFromAPIResponse.iso360Value?.construction?.iso360ValueCode,
      exteriorWallFinishType: this.dataFromAPIResponse.iso360Value?.exteriorWallFinish?.iso360ValueCode,
      foundationType: this.dataFromAPIResponse.iso360Value?.foundationType?.iso360ValueCode,
      roofMaterialType: this.dataFromAPIResponse.iso360Value?.roofMaterial?.iso360ValueCode,
      formType: this.summaryData.SummaryForm.get('formType').value,
      riskId: this.entityRiskData?.getRiskId(),
      riskDetailId: this.entityRiskData?.getRiskDetailId(),
      quoteNumber: this.entityRiskData?.getQuoteNumber(),
      policyNumber: this.entityRiskData?.getPolicyNumber()
    });
    return data;
  }

  getIso360ValueGetValuationRequestModel(): ResidentialRequestDTO {

    const locationDetails = this.propertyData.locationDetails;

    return new ResidentialRequestDTO({
      valuationId: locationDetails.get('valuationId').value,
      formType: this.summaryData.SummaryForm.get('formType').value
    });
  }

  iso360PropertyModel(): SaveIso360PropertyDTO {
    const riskId = this.summaryData.SummaryForm.get('riskId').value;
    const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;
    const applicantForm = this.applicantData.mainApplicant;
    const propertyDetails = this.propertyData.propertyDetails;
    const locationDetails = this.propertyData.locationDetails;

    return new SaveIso360PropertyDTO({
      riskId: riskId,
      riskDetailId: riskDetailId,
      iso360PropertyDTO: new Iso360PropertyDTO({
        yearBuilt: propertyDetails.get('yearBuilt').value,
        latitude: locationDetails.get('latitude')?.value,
        longitude: locationDetails.get('longitude')?.value,
        constructionCode: propertyDetails.get('constructionType').value,
        exteriorWallCode: propertyDetails.get('exteriorWallFinish').value,
        foundationType: propertyDetails.get('foundationType').value,
        numberOfStory: propertyDetails.get('numOfStories').value,
        replacementCost: propertyDetails.get('replacementCost').value,
        replacementCostDate: propertyDetails.get('replacementCostDate').value?.singleDate?.jsDate.toLocaleDateString('en-US'),
        riskId: riskId,
        roofMaterialCode: propertyDetails.get('roofMaterial')?.value,
        roofShape: propertyDetails.get('roofShape').value,
        squareFootage: propertyDetails.get('squareFootage').value,
        structureType: locationDetails.get('structureType').value,
        valuationId: locationDetails.get('valuationId').value,
        costPerSqft: this.costPerSqft,
        coverageA: +this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').value,
        construction360Code: this.iso360ValueMapping.defaultConstructionTypeMapping.find(m => m.uiCode === this.propertyData.propertyDetails.get('constructionType').value)?.iso360Code ?? '',
        exteriorWallFinish360Code: this.iso360ValueMapping.defaultExteriorWallFinishMapping.find(m => m.uiCode === this.propertyData.propertyDetails.get('exteriorWallFinish').value)?.iso360Code ?? '',
        foundationType360Code: this.iso360ValueMapping.defaultFoundationTypeMapping.find(m => m.uiCode === this.propertyData.propertyDetails.get('foundationType').value)?.iso360Code ?? '',
        roofMaterial360Code: this.iso360ValueMapping.defaultRoofMaterialMapping.find(m => m.uiCode === this.propertyData.propertyDetails.get('roofMaterial').value)?.iso360Code ?? '',
        coverageB: this.coveragesData.coveragesAndDeductiblesForm.get('coverageB').value,
        coverageC: this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').value,
        coverageD: this.coveragesData.coveragesAndDeductiblesForm.get('coverageD').value,
      })
    });
  }

  getAPlusRequestModel(requestData: ApplicationInformationDTO): APlusClaimsRequestDTO {
    return new APlusClaimsRequestDTO({
      appCode: environment.ApplicationId,
      riskId: this.summaryData.SummaryForm.get('riskId').value,
      riskDetailId: this.summaryData.SummaryForm.get('riskDetailId').value,
      riskBindId: this.entityRiskData.getRiskBindId(),
      insuredFirstName: requestData.firstName,
      insuredMiddleName: requestData.middleInitial,
      insuredLastName: requestData.lastName,
      // insuredBirthday: new Date(requestData.dateOfBirth + 'UTC'),
      insuredBirthday: moment(requestData.dateOfBirth).format('YYYY-MM-DDT00:00:00.000'),
      streetAddress1: requestData.streetAddress,
      streetAddress2: null,
      city: requestData.city,
      state: requestData.state,
      zipCode: requestData.zipCode,
      mailingstreetAddress1: requestData.streetAddress,
      mailingstreetAddress2: null,
      mailingCity: requestData.city,
      mailingState: requestData.state,
      mailingZipCode: requestData.zipCode,
      priorPolicyNumber: null,
      formType: this.entityRiskData.getFormType(),
      quoteNumber: requestData.quoteNumber
    });
  }

  getAplusLastCallStatusModel(): AplusLastCallStatusDTO {
    return new AplusLastCallStatusDTO({
      riskId: this.summaryData.SummaryForm.get('riskId').value,
      isLastAPlusCallSuccess: this.dataFromAPIResponse.isLastAPlusCallSuccess,
    });
  }

  saveAplusLastCallStatus() {
    this.thirdPartyDataService.postLastAPlusCallStatus(this.getAplusLastCallStatusModel()).pipe(takeUntil(this.stop$)).subscribe(result => {
    }, error => {
    });
  }

  mapThirdPartyResultToSubmission(thirdPartyResult: AllResponseDTO) {
    this.mapLocationResultToSubmission(thirdPartyResult);
    this.mapIso360ResultToSubmission(thirdPartyResult.iso360Value);
    this.mapAirHazardToSubmission(thirdPartyResult.airHazard);
    this.mapTorrentFloodToSubmissionOrderPrefill(thirdPartyResult.torrentFloodQuote, thirdPartyResult.floodAssist);

    if (this.comparativeRaterData.isFromComparativeRater && thirdPartyResult.iso360Value?.isNoHitAddress) {
      this.comparativeRaterData.initial360ValueFromComparative(this.comparativeRaterData.comparativeDetails);
    } else {
      this.propertyData.squareFootage360Value = thirdPartyResult?.iso360Value?.squareFootage; // initial sqft from iso360
      const initial360ValueForm = this.propertyData.iso360ValueInitialValueForm;
      const iso360Value = thirdPartyResult.iso360Value;
      initial360ValueForm.get('exteriorWallFinish360DefaultCode').setValue(iso360Value?.exteriorWallFinish?.code);
      initial360ValueForm.get('foundationType360DefaultCode').setValue(iso360Value?.foundationType?.code);
      initial360ValueForm.get('roofMaterial360DefaultCode').setValue(iso360Value?.roofMaterial?.code);
      initial360ValueForm.get('roofShape360DefaultCode').setValue(iso360Value?.roofShape?.code?.trim());
    }

    if (thirdPartyResult.creditScoreInfo) {
      this.mapCreditScoreInfoResultToCoveragesData(thirdPartyResult.creditScoreInfo);
      if (thirdPartyResult.creditScoreInfo.creditOrderStatusCode !== LvCreditOrderStatus[LvCreditOrderStatusIndex.NotOrdered].code) {
        const isCreditOrder = this.applicantData.mainApplicant.get('isOrderYesNo').value;
        if (isCreditOrder) {
          this.setFcraValidator();
        }
      } else {
        this.setCreditOrderingToNotOrdered();
      }
    } else {
      this.setCreditOrderingToNotOrdered();
    }
  }

  getClaimList(thirdPartyResult: AllResponseDTO): Array<ClaimsModalModel> {
    const claimList: Array<ClaimsModalModel> = [];
    const effectiveDate = this.applicantData.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate ?
      new Date(this.applicantData.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate)
      : new Date(this.applicantData.mainApplicant.get('effectiveDate').value);

    if (thirdPartyResult.claims == null) {
      if (!thirdPartyResult.errors) {
        thirdPartyResult.errors = [];
      }
      const errorModel: ThirdPartyErrorDTO = {
        errorCode: 'EC-07',
        errorMessage: 'APlus API service encountered an error. There was an error in processing the API.'
      };

      this.dataFromAPIResponse.isLastAPlusCallSuccess = false;
      this.saveAplusLastCallStatus();
      thirdPartyResult.errors.push(errorModel);
    }

    thirdPartyResult.claims?.forEach(claimItem => {
      const claim: ClaimsModalModel = {
        claimAmount: claimItem.amount,
        catCode: claimItem.catCode,
        claimChargeableVsNonChargeable: claimItem.chargeableNonChargable,
        claimNumber: claimItem.claimNumber,
        claimSource: claimItem.claimSource,
        claimStatus: claimItem.claimStatus,
        claimType: claimItem.claimType,
        createdDate: claimItem.createdDate,
        additionalDescription: claimItem.description,
        id: claimItem.id,
        individualMatchType: claimItem.individualMatchType,
        isAPlus: claimItem.isAPlus,
        isComparative: false,
        isAdd: claimItem.isAdd,
        dispute: claimItem.isDispute,
        lossDate: {
          singleDate: {
            date: {
              year: new Date(claimItem.lossDate).getFullYear(),
              month: (new Date(claimItem.lossDate).getMonth() + 1),
              day: new Date(claimItem.lossDate).getDate()
            },
            formatted: String(moment(new Date(claimItem.lossDate)).format('MM/DD/YYYY')),
            jsDate: new Date(claimItem.lossDate)
          }
        },
        notes: claimItem.notes,
        otherQuestions: claimItem.otherQuestion,
        policyType: claimItem.policyType,
        riskDetailId: claimItem.riskDetailId,
        isLossAssociatedToHome: claimItem.isLossAssociatedToHome,
        isLossAssociatedToInsured: claimItem.isLossAssociatedToInsured,
        totalOutstandingReserve: claimItem.totalOutstandingReserve,
        totalRecoveriesAndReimbursement: claimItem.totalRecoveriesAndReimbursement,
        pcsCode: claimItem.pcsCode,
      };

      claim.claimChargeableVsNonChargeable = this.claimsChargeableService.updateConditionaClaimsValue(claim, effectiveDate);

      claimList.push(claim);
    });

    return claimList;
  }

  mapLocationResultToSubmission(thirdPartyResult: AllResponseDTO) {
    // empty string '' default value is for displaying of 'Please select' for dropdown values
    const propertyLocationDetialsForm = this.propertyData.locationDetails;

    propertyLocationDetialsForm.get('oceanName').setValue(thirdPartyResult?.oceanName ?? '');
    propertyLocationDetialsForm.get('respondingFireDept').setValue(thirdPartyResult?.respondingFireDepartment ?? '');
    propertyLocationDetialsForm.get('windPoolEligible')
      .setValue(thirdPartyResult?.windPoolEligible ? this.stringYesOrNoToBoolean(thirdPartyResult?.windPoolEligible) : false);
    propertyLocationDetialsForm.get('milesToFireStation')
      .setValue(thirdPartyResult?.milesToFireStation ? this.milesToFireStationMapping(thirdPartyResult?.milesToFireStation) : '');
    propertyLocationDetialsForm.get('nearestWaterName').setValue(thirdPartyResult?.nearestWaterName ?? '');
    propertyLocationDetialsForm.get('distanceToWater').setValue(thirdPartyResult?.nearestWaterName ?? '');
    propertyLocationDetialsForm.get('distanceToActualCoast').setValue(thirdPartyResult?.oceanName ?? '');
    propertyLocationDetialsForm.get('fireDistrictName').setValue(thirdPartyResult?.fireDistrictName ?? '');
    propertyLocationDetialsForm.get('bceg').setValue(thirdPartyResult?.bceg ?? '');
    propertyLocationDetialsForm.get('matchLevel').setValue(thirdPartyResult?.matchLevel ?? '');
    this.propertyData.distanceToActualCoastCodeISO = thirdPartyResult.distanceToActualCoastCode;

    // for navigate out saving
    propertyLocationDetialsForm.markAsDirty();
  }

  mapIso360ResultToSubmission(iso360Value: ResidentialResponseDTO) {
    this.mapIfIsNoHitAddress(iso360Value);
    this.mapiso360AddressData(iso360Value);

    const propertyLocationDetailsForm = this.propertyData.locationDetails;
    const propertyPropertyDetailsForm = this.propertyData.propertyDetails;
    const replacementCost = Math.round(Number(Boolean(this.comparativeRaterData.isFromComparativeRater && iso360Value?.isNoHitAddress) ?
      (this.quoteService.formType === this.formTypeConstants.HO4) ?
      '0' : this.comparativeRaterData.comparativeDetails?.coverages?.find(cov => cov.coverageID === 'DWELL').limit : iso360Value?.replacementCost));

    if (this.comparativeRaterData.isFromComparativeRater) {
      this.comparativeRaterData.mapComparativeRaterResult(iso360Value, propertyLocationDetailsForm, propertyPropertyDetailsForm, replacementCost);
      this.comparativeRaterData.mapPropertyFromComparative(this.dataFromAPIResponse, this.isDataFromOrderPrefill);
    } else {
      this.mapIso360YearBuiltToYOC(iso360Value?.yearBuilt);
      propertyPropertyDetailsForm.get('squareFootage').setValue(iso360Value?.squareFootage ?? '');
      propertyPropertyDetailsForm.get('numOfStories').setValue(iso360Value?.numberOfStories?.code ?? SubmissionConstants.numberOfStoriesAllForms);

      if (this.quoteService.formType !== this.formTypeConstants.HO6) {
        propertyPropertyDetailsForm.get('exteriorWallFinish').setValue(iso360Value?.exteriorWallFinish?.code ?? '');
        propertyPropertyDetailsForm.get('constructionType').setValue(iso360Value?.construction?.code ?? '');
        propertyPropertyDetailsForm.get('roofMaterial').setValue(iso360Value?.roofMaterial?.code ?? '');
        propertyPropertyDetailsForm.get('roofShape').setValue(iso360Value?.roofShape?.code?.trim() ?? '');
        propertyPropertyDetailsForm.get('foundationType').setValue(iso360Value?.foundationType?.code ?? SubmissionConstants.foundationTypeAllForms);
        this.propertyData.showFoundationElevation = this.propertyData.setFoundationType(propertyPropertyDetailsForm.get('foundationType').value);
      }

      if (this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3) { // if HO3 OR DPR set replacementCost Value
        propertyPropertyDetailsForm.get('replacementCost').setValue(replacementCost ?? '');
        if (iso360Value?.replacementCostDate &&
          !(propertyPropertyDetailsForm.get('replacementCostDate').value)) {
          const replacementCostDate = new Date(iso360Value.replacementCostDate);
          propertyPropertyDetailsForm.get('replacementCostDate').setValue({
            singleDate: {
              date: {
                year: replacementCostDate.getFullYear(),
                month: replacementCostDate.getMonth() + 1,
                day: replacementCostDate.getDate()
              },
              jsDate: replacementCostDate
            }
          });
        }
      }

      if (this.quoteService.formType === FormTypeConstants.HO3 ||
        this.quoteService.formType === FormTypeConstants.DP3) { // if HO3 set value on structure Type
        propertyLocationDetailsForm.get('structureType').setValue(iso360Value?.structureType?.code ?? this.submissionDefaultData.getStructureTypeDefault());
      }

      propertyLocationDetailsForm.get('valuationId').setValue(iso360Value?.valuationId ?? '');
      propertyLocationDetailsForm.get('latitude').setValue(iso360Value?.latitude ?? '');
      propertyLocationDetailsForm.get('longitude').setValue(iso360Value?.longitude ?? '');
      this.propertyData.mapRoofGeometry(iso360Value?.roofShape?.code?.trim() ?? '');
      this.propertyData.setRoofMaterialList();

      this.comparativeRaterData.isComparativeResultMapped = false;
    }

    if (this.comparativeRaterData.isFromComparativeRater && this.isDataFromOrderPrefill) {
      this.comparativeRaterData.mapDeductiblesFromComparative();
      this.comparativeRaterData.mapYearFieldsFromComparative(this.dataFromAPIResponse);
    }

    switch (this.quoteService.formType) {
      case this.formTypeConstants.HO4:
        this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').setValue(0);
        this.summaryData.SummaryForm.get('coverageA').setValue(0);
        this.coveragesData.validCovA = 0;
        if (this.comparativeRaterData.isFromComparativeRater) {
          const covC = this.comparativeRaterData.comparativeDetails?.coverages.find(cov => cov.coverageID === 'PP').limit;
          this.summaryData.SummaryForm.get('coverageC').setValue(covC);
          this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').setValue(covC);
          this.coveragesData.isComparativeCoverageMapped = this.comparativeRaterData.mapCoverageFromComparative();
        } else {
          this.summaryData.SummaryForm.get('coverageC').setValue(25000);
        }
        break;
      case this.formTypeConstants.HO6:
        if (this.comparativeRaterData.isFromComparativeRater) {
          const covA = this.comparativeRaterData.comparativeDetails?.coverages?.find(cov => cov.coverageID === 'DWELL').limit;
          const covC = this.comparativeRaterData.comparativeDetails?.coverages.find(cov => cov.coverageID === 'PP').limit;
          this.summaryData.SummaryForm.get('coverageA').setValue(covA);
          this.summaryData.SummaryForm.get('coverageC').setValue(covC);
          this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').setValue(covA);
          this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').setValue(covC);
          this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').markAsTouched();
          this.coveragesData.isComparativeCoverageMapped = this.comparativeRaterData.mapCoverageFromComparative();
        } else {
          const coverageAValue = this?.coveragesData?.coveragesAndDeductiblesForm?.get('coverageA')?.value !== null &&
          this?.coveragesData?.coveragesAndDeductiblesForm?.get('coverageA')?.value?.toString()?.trim() !== '' ? this?.coveragesData?.coveragesAndDeductiblesForm?.get('coverageA')?.value : 35000;
          this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').setValue(coverageAValue);
          this.summaryData.SummaryForm.get('coverageA').setValue(coverageAValue);
          this.coveragesData.validCovA = coverageAValue;
          this.summaryData.SummaryForm.get('coverageC').setValue(25000);
        }
        break;
      default:
        this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').setValue(replacementCost ?? '');
        this.summaryData.SummaryForm.get('coverageA').setValue(replacementCost ?? '');
        this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').markAsTouched();
        this.coveragesData.validCovA = replacementCost;
        if (this.comparativeRaterData.isFromComparativeRater) {
          this.comparativeRaterData.mapCoverageFromComparative();
          this.coveragesData.calculateCoverages(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').value);
        }
    }
    this.costPerSqft = iso360Value?.costPerSqft;

    this.valuationFields.forEach(field => {
      this.propertyData.getPropertyForm().propertyDetails.get(field).markAsPristine();
    });
  }

  mapIso360ResultToProperty(iso360Value: ResidentialResponseDTO, isFrom360ValueModal: Boolean = false) {

    const errorResult = iso360Value as IIso360ValueResultDTO;

    if(errorResult?.errorType != null ||
      errorResult?.errorDescription != null){
      return; // Prevent mapping if an error occurs when getting valuation from 360
    }

    const propertyLocationDetailsForm = this.propertyData.locationDetails;
    const propertyPropertyDetailsForm = this.propertyData.propertyDetails;
    const replacementCost = Math.round(Number(iso360Value?.replacementCost));

    this.mapIso360YearBuiltToYOC(iso360Value?.yearBuilt);

    if (iso360Value?.squareFootage && iso360Value?.squareFootage !== '') {
      propertyPropertyDetailsForm.get('squareFootage').setValue(iso360Value?.squareFootage);
    }

    if (iso360Value?.numberOfStories && iso360Value?.numberOfStories?.code !== '') {
      propertyPropertyDetailsForm.get('numOfStories').setValue(iso360Value?.numberOfStories?.code);
    }

    if (iso360Value?.exteriorWallFinish && iso360Value?.exteriorWallFinish?.code !== '') {
      propertyPropertyDetailsForm.get('exteriorWallFinish').setValue(iso360Value?.exteriorWallFinish?.code);
    }

    if (iso360Value?.construction && iso360Value?.construction?.code !== '') {
      propertyPropertyDetailsForm.get('constructionType').setValue(iso360Value?.construction?.code);
    }

    const isPouredConcrete = propertyPropertyDetailsForm.get('roofMaterial').value === 'PC';
    if (iso360Value?.roofMaterial && iso360Value?.roofMaterial?.code !== '' && !isPouredConcrete) {
      propertyPropertyDetailsForm.get('roofMaterial').setValue(iso360Value?.roofMaterial?.code);
    }

    if (iso360Value?.roofShape && iso360Value?.roofShape?.code !== '' && !isPouredConcrete) {
      propertyPropertyDetailsForm.get('roofShape').setValue(iso360Value?.roofShape?.code);
    }

    if (iso360Value?.foundationType && iso360Value?.foundationType?.code !== '') {
      propertyPropertyDetailsForm.get('foundationType').setValue(iso360Value?.foundationType?.code);
      this.propertyData.showFoundationElevation = this.propertyData.setFoundationType(propertyPropertyDetailsForm.get('foundationType').value);
    }

    if (this.quoteService.formType === FormTypeConstants.HO3 || this.quoteService.formType === FormTypeConstants.DP3) { // if HO3 set value on structure Type
      if (iso360Value?.structureType && iso360Value?.structureType?.code !== '') {
        propertyLocationDetailsForm.get('structureType').setValue(iso360Value?.structureType?.code);
      }
    }

    if (iso360Value?.valuationId && iso360Value?.valuationId !== '') {
      propertyLocationDetailsForm.get('valuationId').setValue(iso360Value?.valuationId);
    }

    if (iso360Value?.latitude && iso360Value?.latitude !== '') {
      propertyLocationDetailsForm.get('latitude').setValue(iso360Value?.latitude);
    }

    if (iso360Value?.longitude && iso360Value?.longitude !== '') {
      propertyLocationDetailsForm.get('longitude').setValue(iso360Value?.longitude);
    }

    if (iso360Value?.roofShape && iso360Value?.roofShape?.code !== '') {
      this.propertyData.mapRoofGeometry(iso360Value?.roofShape?.code?.trim());
    }

    // CENTAURI-5485
    // Added a logic when during midterm policy, disable the update of Coverage A (Replacement Cost)
    // CNTSUP-2030
    // Logic updated to also remove cov A updates during renewal
    // Also, updates from the 360Value Property Viewer should be allowed to update Cov A

    const isPolicy = this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId());
    const isNewBusiness = this.entityRiskData.getRisk()?.isFromRenewal === false && this.entityRiskData.getRisk().isFromRewrite === false;

    if ((isNewBusiness && !isPolicy) || isFrom360ValueModal) {
      if (this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3) { // if HO3 OR DPR set replacementCost Value
        propertyPropertyDetailsForm.get('replacementCost').setValue(replacementCost);
      }

      switch (this.quoteService.formType) {
        case this.formTypeConstants.HO4:
          this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').setValue(0);
          this.summaryData.SummaryForm.get('coverageA').setValue(0);
          this.coveragesData.validCovA = 0;
          this.summaryData.SummaryForm.get('coverageC').setValue(25000);
          break;
        case this.formTypeConstants.HO6:
          const coverageAValue = this?.coveragesData?.coveragesAndDeductiblesForm?.get('coverageA')?.value !== null &&
            this?.coveragesData?.coveragesAndDeductiblesForm?.get('coverageA')?.value?.toString()?.trim() !== '' ? this?.coveragesData?.coveragesAndDeductiblesForm?.get('coverageA')?.value : 35000;
          this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').setValue(coverageAValue);
          this.summaryData.SummaryForm.get('coverageA').setValue(coverageAValue);
          this.coveragesData.validCovA = coverageAValue;
          this.summaryData.SummaryForm.get('coverageC').setValue(25000);
          break;
        default:
          this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').setValue(replacementCost ?? '');
          this.summaryData.SummaryForm.get('coverageA').setValue(replacementCost ?? '');
          this.coveragesData.validCovA = replacementCost;
          this.coveragesData.calculateCoverages(replacementCost);
          this.isCovAAndReplacementCostTheSame$.next();
      }
    }

    if (this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3) { // if HO3 OR DPR set replacementCost Value
      if (iso360Value?.replacementCostDate) {
        const replacementCostDate = new Date(iso360Value?.replacementCostDate);
        if (moment.isDate(replacementCostDate)) {
          propertyPropertyDetailsForm.get('replacementCostDate').setValue({
            singleDate: {
              date: {
                year: replacementCostDate.getFullYear(),
                month: replacementCostDate.getMonth() + 1,
                day: replacementCostDate.getDate()
              },
              jsDate: replacementCostDate
            }
          });
        }
      }
    }

    if (this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3) { // if HO3 OR DPR set replacementCost Value
      if (iso360Value?.replacementCostDate) {
        const replacementCostDate = new Date(iso360Value?.replacementCostDate);
        if (moment.isDate(replacementCostDate)) {
          propertyPropertyDetailsForm.get('replacementCostDate').setValue({
            singleDate: {
              date: {
                year: replacementCostDate.getFullYear(),
                month: replacementCostDate.getMonth() + 1,
                day: replacementCostDate.getDate()
              },
              jsDate: replacementCostDate
            }
          });
        }
      }
    }

    if (iso360Value?.costPerSqft && iso360Value?.costPerSqft !== '') {
      this.costPerSqft = iso360Value?.costPerSqft;
    }

    this.valuationFields.forEach(field => {
      this.propertyData.getPropertyForm().propertyDetails.get(field).markAsPristine();
    });
  }

  protected mapiso360AddressData(iso360Value: ResidentialResponseDTO) {
    if (!iso360Value?.isNoHitAddress &&
      iso360Value) {

      if (iso360Value?.street ||
        iso360Value?.street !== '') {
        this.applicantData.mainApplicant.get('addressLine1').setValue(iso360Value?.street);
      }

      if (iso360Value?.unit ||
        iso360Value?.unit !== '') {
        this.applicantData.mainApplicant.get('addressLine2').setValue(iso360Value?.unit);
      }

      if (iso360Value?.city ||
        iso360Value?.city !== '') {
        this.applicantData.mainApplicant.get('qqCity').setValue(iso360Value?.city?.replace('St ', 'Saint '));
      }

      if (iso360Value?.state ||
        iso360Value?.state !== '') {
        this.applicantData.mainApplicant.get('qqState').setValue(iso360Value?.state);
      }

      if (iso360Value?.zipCode ||
        iso360Value?.zipCode !== '') {
        this.applicantData.mainApplicant.get('qqZipCode').setValue(Number(iso360Value?.zipCode));
      }

    }
  }

  protected mapNoOrderCreditToCoveragesData() {
    const notOrderedIndex = 0;
    const excellentIndex = 0;
    this.coveragesData.creditOrderStatusCode = LvCreditOrderStatus[notOrderedIndex].code;
    this.coveragesData.estimatedCode = LvEstimatedCredit[excellentIndex].code;
    this.coveragesData.creditScore = 900;
    this.coveragesData.creditScoreDateOrdered = new Date('');
    this.coveragesData.creditOrderStatusCode = LvCreditOrderStatus[notOrderedIndex].code;
    this.applicantData.fcraReportingActForm.get('fcraReportingAct').setValue(false);
    this.applicantData.fcraReportingActForm.get('fcraReportingAct').enable();
    this.applicantData.mainApplicant.get('isOrderYesNo').setValue(false);
  }

  mapCreditScoreInfoResultToCoveragesData(creditScoreInfo: CreditScoreRiskResponseDTO) {
    const creditOrderStatus = creditScoreInfo.creditOrderStatus;
    const excellentDefaultCreditScore = 900;
    const isNotOrdered = creditScoreInfo.creditOrderStatusId === LvCreditOrderStatus[LvCreditOrderStatusIndex.NotOrdered].id;
    const notOrderedId = LvCreditOrderStatus[LvCreditOrderStatusIndex.NotOrdered].id;
    const creditOrderDetail: CreditOrderDetail = {
      statusDescription: creditOrderStatus
    };

    if (creditOrderStatus === LvCreditOrderStatus[LvCreditOrderStatusIndex.NotOrdered].description ||
      creditScoreInfo.creditOrderStatusId === notOrderedId) {
      this.setDefaultNotOrderedCreditStatus(excellentDefaultCreditScore, creditScoreInfo);
      this.coveragesData.hasSuccessOrderCredit = false;
    } else if (creditOrderStatus !== '') {
      this.coveragesData.creditScore = isNotOrdered && creditScoreInfo.creditScore === 0 ? excellentDefaultCreditScore : creditScoreInfo.creditScore;
      this.coveragesData.creditOrderStatusId = creditScoreInfo.creditOrderStatusId;
      this.coveragesData.creditOrderStatusCode = creditScoreInfo.creditOrderStatusCode;
      this.coveragesData.creditScoreDateOrdered = this.authService.getCustomDate();
      this.coveragesData.lexisReferenceNumber = creditScoreInfo.lexisReferenceNumber;
      this.coveragesData.estimatedCode = isNotOrdered ? LvEstimatedCredit[LvEstimatedCreditIndex.Excellent].code : '';
      this.coveragesData.statusCodeObservable.next(this.coveragesData.creditOrderStatusCode);
      this.coveragesData.hasSuccessOrderCredit = true;
      this.summaryData.isUpdatedInsuredProfileOrPrefill = true;
    } else {
      this.setDefaultNotOrderedCreditStatus(excellentDefaultCreditScore, creditScoreInfo);
      this.coveragesData.hasSuccessOrderCredit = false;
    }

    this.creditOrderDetail$.next(creditOrderDetail);

    if (this.entityRiskData.getRiskId()) {
      this.entityRiskData.EntityRisk.creditScoreDateOrdered = this.coveragesData.creditScoreDateOrdered;
      this.coveragesData.updateRaterRequest();
    }
  }

  setFcraValidator(): void {
    if (this.coveragesData.creditOrderStatusCode !== LvCreditOrderStatus[LvCreditOrderStatusIndex.NotOrdered].code) {
      this.applicantData.fcraReportingActForm.get('fcraReportingAct').clearValidators();
    }
    this.applicantData.fcraReportingActForm.get('fcraReportingAct').updateValueAndValidity();
  }

  setFcraValidatorOnError(): void {
    this.applicantData.mainApplicant.get('isOrderYesNo').setValue(false);
    this.quickQuoteCreditOrderPrefill$.next(false);
    this.applicantData.fcraReportingActForm.get('fcraReportingAct').setValidators(Validators.requiredTrue);
    this.applicantData.fcraReportingActForm.get('fcraReportingAct').updateValueAndValidity();
  }

  protected setDefaultNotOrderedCreditStatus(excellentDefaultCreditScore: number, creditScoreInfo: CreditScoreRiskResponseDTO) {
    this.coveragesData.creditScore = excellentDefaultCreditScore;
    this.coveragesData.creditOrderStatusId = creditScoreInfo.creditOrderStatusId;
    this.coveragesData.creditOrderStatusCode = creditScoreInfo.creditOrderStatusCode;
    // Default this to empty date for FCRA section under Applicant page
    this.coveragesData.creditScoreDateOrdered = new Date('');
    this.coveragesData.estimatedCode = LvEstimatedCredit[LvEstimatedCreditIndex.Excellent].code;
    this.applicantData.fcraReportingActForm.get('fcraReportingAct').enable();
    this.applicantData.fcraReportingActForm.get('fcraReportingAct').setValue(false);
  }

  public mapCreditScoreNotOrderedToCoveragesData() {
    const notOrderedIndex = 0;
    const excellentIndex = 0;
    const creditOrderStatus = LvCreditOrderStatus[notOrderedIndex];
    const creditScoreDate = new Date('');
    const creditOrderDetail: CreditOrderDetail = {
      statusDescription: creditOrderStatus.description
    };

    this.coveragesData.creditScore = this.excellentCreditScore;
    this.coveragesData.creditOrderStatusId = creditOrderStatus.id;
    this.coveragesData.creditOrderStatusCode = creditOrderStatus.code;
    this.coveragesData.creditScoreDateOrdered = new Date('');
    this.applicantData.fcraReportingActForm.get('fcraReportingAct').enable();
    this.applicantData.fcraReportingActForm.get('creditScoreDateOrdered').setValue({
      singleDate:
      {
        date:
        {
          year: creditScoreDate.getFullYear(),
          month: creditScoreDate.getMonth() + 1,
          day: creditScoreDate.getDate()
        },
        jsDate: creditScoreDate
      }
    });
    this.coveragesData.estimatedCode = LvEstimatedCredit[excellentIndex].code;
    this.creditOrderDetail$.next(creditOrderDetail);
    this.resetOrderCreditValue();
  }

  protected resetOrderCreditValue() {
    this.quickQuoteData.isOrderCreditValue = false;
  }

  mapIso360YearBuiltToYOC(yearbuilt: string) {
    const propertyPropertyDetailsForm = this.propertyData.propertyDetails;
    this.propertyData.validYearBuilt = Number(yearbuilt);
    // if current year built value is the same as ISO360 year built
    // do not prefill YOC values
    if (Number(propertyPropertyDetailsForm.get('yearBuilt').value) !== Number(yearbuilt)) {
      propertyPropertyDetailsForm.get('yearRoof').setValue(this.quoteService.formType !== this.formTypeConstants.HO4 ? yearbuilt : null);
      propertyPropertyDetailsForm.get('yearElectrical').setValue(this.quoteService.formType !== this.formTypeConstants.HO4 ? yearbuilt : null);
      propertyPropertyDetailsForm.get('yearPlumbing').setValue(this.quoteService.formType !== this.formTypeConstants.HO4 ? yearbuilt : null);
      propertyPropertyDetailsForm.get('yearHeating').setValue(this.quoteService.formType !== this.formTypeConstants.HO4 ? yearbuilt : null);
      propertyPropertyDetailsForm.get('yearCentralAirConditioning').setValue(this.quoteService.formType !== this.formTypeConstants.HO4 ? yearbuilt : null);
    }
    propertyPropertyDetailsForm.get('yearBuilt').setValue(yearbuilt);
  }

  mapRiskPropertyToAPIResponse(risk: EntityRiskDTO) {
    const riskProperties = risk?.risks[0]?.riskDetails[0]?.riskProperties;
    const riskFloods = risk?.risks[0]?.riskDetails[0]?.riskFloods;
    this.dataFromAPIResponse = {
      county: [riskProperties[0]?.county],
      distanceToActualCoast: riskProperties[0]?.distanceToActualCoast,
      distanceToWater: riskProperties[0]?.distanceToWater,
      bceg: riskProperties[0]?.bceg,
      fireDistrictName: riskProperties[0]?.fireDistrictName,
      matchLevel: riskProperties[0]?.matchLevel,
      isMilesToFireStationFromISO: riskProperties[0].isMilesToFireStationFromISO,
      milesToFireStation: riskProperties[0]?.milesToFireStationCode,
      nearestWaterName: riskProperties[0]?.nearestWaterName,
      oceanName: riskProperties[0]?.oceanName,
      respondingFireDepartment: riskProperties[0]?.respondingFireDepartment,
      windPoolEligible: riskProperties[0]?.windPoolEligible,
      iso360Value: {
        construction: {
          code: riskProperties[0]?.raterConstructionTypeCode,
          iso360ValueCode: riskProperties[0]?.construction360Code,
          description: '',
        },
        exteriorWallFinish: {
          code: riskProperties[0]?.raterExteriorWallFinishCode,
          iso360ValueCode: riskProperties[0]?.exteriorWallFinish360Code,
          description: '',
        },
        foundationType: {
          code: riskProperties[0]?.raterFoundationTypeCode,
          iso360ValueCode: riskProperties[0]?.foundationType360Code,
          description: '',
        },
        numberOfStories: {
          code: riskProperties[0]?.numberOfStory,
          iso360ValueCode: riskProperties[0]?.numberOfStory,
          description: ''
        },
        roofMaterial: {
          code: riskProperties[0]?.roofMaterialCode,
          iso360ValueCode: riskProperties[0]?.roofMaterial360Code,
          description: ''
        },
        roofShape: {
          code: riskProperties[0]?.raterRoofShapeCode,
          iso360ValueCode: riskProperties[0]?.raterRoofShapeCode,
          description: ''
        },
        structureType: {
          code: riskProperties[0]?.structureTypeCode,
          iso360ValueCode: riskProperties[0]?.structureTypeCode,
          description: ''
        },
        isNoHitAddress: riskProperties[0]?.isNoHitAddress,
        pricingChanged: null,
        latitude: riskProperties[0]?.latitude,
        longitude: riskProperties[0]?.longitude,
        squareFootage: riskProperties[0]?.squareFootage,
        replacementCostDate: riskProperties[0]?.replacementCostDate,
        replacementCost: riskProperties[0]?.replacementCost,
        valuationId: riskProperties[0]?.valuationId,
        yearBuilt: riskProperties[0]?.yearBuilt?.toString(),
        costPerSqft: riskProperties[0]?.costPerSqft?.toString(),
      },
      distanceToActualCoastCode: riskProperties[0]?.distanceToActualCoastCode,
      distanceToWaterCode: riskProperties[0]?.distanceToWaterCode,
      isoLocationPPC: riskProperties[0]?.isoLocationPPC,
      isLastAPlusCallSuccess: risk?.risks[0].isLastAPlusCallSuccess,
      isoLocationPPCReturnSource: riskProperties[0].isoLocationPPCReturnSource,
      distanceToCoastReturnSource: riskProperties[0].distanceToActualCoastReturnSource,
      windPoolEligibleReturnSource: riskProperties[0].windPoolEligibleReturnSource
    };
    this.isNoHitIso360ValueFirstCall = riskProperties[0]?.isNoHitIso360ValueFirstCall;
    this.propertyData.isNoHitIso360ValueFirstCall = riskProperties[0]?.isNoHitIso360ValueFirstCall;
    this.coveragesData.torrentResponse = {
      floodZone: riskFloods[0]?.floodZone,
      nfipPremium: riskFloods[0]?.nfipPremium,
      torrentRequestTransactionId: riskFloods[0].torrentRequestTransactionId,
      torrentTrackingNum: riskFloods[0].torrentTrackingNum,
      isFloodZoneAvailableFromTorrent: riskFloods[0]?.isFloodZoneAvailableFromTorrent,
      isTorrentPurchased: riskFloods[0].isTorrentPurchased,
      isTorrentAddressValidationFailed: riskFloods[0].isTorrentAddressValidationFailed
    };
    if (riskFloods[0].torrentTrackingNum) { this.coveragesData.floodForm.get('floodZone').disable(); }
    this.propertyData.squareFootage360Value = riskProperties[0]?.squareFootage360Value;
  }

  stringYesOrNoToBoolean(stringToConvert: string): boolean {
    if (!!!stringToConvert) {
      return false;
    }
    return stringToConvert.toLocaleLowerCase() === 'y' ? true : false;
  }

  milesToFireStationMapping(miles: string): number | '' {
    if (!!!miles) {
      return ''; // based on PropertyData's default
    }
    const fiveMiles = [
      '1 MILE OR LESS',
      'GREATER THAN 1 TO 2 MILES',
      'GREATER THAN 2 TO 3 MILES',
      'GREATER THAN 3 TO 4 MILES',
      'GREATER THAN 4 TO 5 MILES'
    ];
    // return numbers are based on dropdown values from PropertyData's milesToStationList
    return fiveMiles.findIndex(m => m === miles) === -1 ? 2 : 1;
  }

  public checkBRUL135to140(): void {
    const lessThan100 = ['01', '02'];
    const lessThan2500 = ['03', '04', '05'];
    const nearestWaterName = this.dataFromAPIResponse?.nearestWaterName;
    const distanceToWaterCode = this.dataFromAPIResponse?.distanceToWaterCode;
    const distanceToActualCoastCode = this.dataFromAPIResponse?.distanceToActualCoastCode;
    const majorBodyOfWater = MajorBodyOfWater;
    const isValidForNewMinimumDeductibleStateWide = this.coveragesData.isValidForNewMinimumDeductibleStateWide();

    this.coveragesData.intCoveragesHurricaneDeductibleInfoMessage();
    if (this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3) {
      if (distanceToWaterCode === distanceToActualCoastCode ||
        Number(distanceToActualCoastCode) < Number(distanceToWaterCode)) {
        if (lessThan100.includes(distanceToActualCoastCode)) {
          this.coveragesData.hurricaneDeductibleExcludedInfoMessage = true;
        } else if (lessThan2500.includes(distanceToActualCoastCode)) {
          //CNTMAIN-275 change minimum info message to 2%
          this.coveragesData.hurricaneDeductible2InfoMessage = isValidForNewMinimumDeductibleStateWide;
          this.coveragesData.hurricaneDeductible5InfoMessage = !isValidForNewMinimumDeductibleStateWide;
        }
      } else if (Number(distanceToWaterCode) < Number(distanceToActualCoastCode)) {
        if (lessThan100.includes(distanceToWaterCode) && majorBodyOfWater.includes(nearestWaterName)) {
          this.coveragesData.hurrDedExcludedDueToWaterInfoMessage = true;
        } else if (lessThan2500.includes(distanceToWaterCode) && majorBodyOfWater.includes(nearestWaterName)) {
          //CNTMAIN-275 Change minumum hurr ded to 2%
          this.coveragesData.hurrDed2DueToWaterInfoMessage = isValidForNewMinimumDeductibleStateWide;
          this.coveragesData.hurrDed5DueToWaterInfoMessage = !isValidForNewMinimumDeductibleStateWide;
        }
        if (lessThan100.includes(distanceToWaterCode) &&
          lessThan2500.includes(distanceToActualCoastCode) &&
          (Number(distanceToActualCoastCode) > Number(distanceToWaterCode))) {
            //CNTMAIN-275 Change minumum hurr ded to 2%
          this.coveragesData.hurrDed2DueToWaterInfoMessage = isValidForNewMinimumDeductibleStateWide;
          this.coveragesData.hurrDed5DueToWaterInfoMessage = !isValidForNewMinimumDeductibleStateWide;
          this.coveragesData.hurrDedExcludedDueToWaterInfoMessage = false;
        }
      } else if (lessThan100.includes(distanceToActualCoastCode) ||
        (lessThan100.includes(distanceToWaterCode) && majorBodyOfWater.includes(nearestWaterName))) {
          this.coveragesData.hurricaneDeductibleExcludedInfoMessage = true;
      }

      if (lessThan100.includes(distanceToWaterCode) &&
          lessThan100.includes(distanceToActualCoastCode) &&
          majorBodyOfWater.includes(nearestWaterName)) {
        this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValue('E');
        //CNTMAIN-275 Change minumum hurr ded to 2%
        this.coveragesData.hurrDed2DueToWaterInfoMessage = isValidForNewMinimumDeductibleStateWide;
        this.coveragesData.hurrDed5DueToWaterInfoMessage = !isValidForNewMinimumDeductibleStateWide;
        this.coveragesData.hurrDedExcludedDueToWaterInfoMessage = true;
        if (distanceToWaterCode === distanceToActualCoastCode) {
          this.coveragesData.hurrDedExcludedDueToWaterInfoMessage = false;
          this.coveragesData.hurricaneDeductibleExcludedInfoMessage = true;
        }
      }
    }

    if((this.entityRiskData.getRisk()?.riskDetails[0]?.riskProperties[0]?.county)?.toLowerCase() === this.QuickQuoteFormConstant.Coverages.Monroe) {
      this.coveragesData.intCoveragesHurricaneDeductibleInfoMessage();
    }
  }

  mapAirHazardToSubmission(airHazardResponse: AirHazardResponseDTO): void {
    if (!!!airHazardResponse) { return; }
    this.dataFromAPIResponse.airHazard = airHazardResponse;
    const windMitigationForm = this.propertyData.windMitigation;
    windMitigationForm.get('terrain').setValue(airHazardResponse.terrain);
    windMitigationForm.get('windSpeed').setValue(airHazardResponse.windSpeedCode);
    windMitigationForm.get('windDesignSpeed').setValue(airHazardResponse.raterWindDesignSpeedCode);
    windMitigationForm.get('windBorneDebrisRegion').setValue(airHazardResponse.isWindBorneDebrisRegion);
  }

  createAirHazardRequest(): AirHazardRequestDTO {
    const applicantForm = this.applicantData.mainApplicant;
    return new AirHazardRequestDTO({
      streetAddress: applicantForm.get('addressLine1').value,
      city: applicantForm.get('qqCity').value,
      state: applicantForm.get('qqState').value,
      zipCode: applicantForm.get('qqZipCode').value,
      yearBuilt: this.propertyData.propertyDetails.get('yearBuilt').value
    });
  }

  checkIfYearBuiltRangeChanged(): boolean {
    const currentYearBuilt = Number(this.propertyData.propertyDetails.get('yearBuilt').value);
    const previousYearBuilt = Number(this.dataFromAPIResponse?.iso360Value?.yearBuilt);
    return currentYearBuilt !== previousYearBuilt &&
      !((currentYearBuilt < AIRHAZARD2010YRSTART && previousYearBuilt < AIRHAZARD2010YRSTART) ||
        (currentYearBuilt >= AIRHAZARD2010YRSTART && previousYearBuilt >= AIRHAZARD2010YRSTART));
  }

  mapTorrentFloodToSubmissionOrderPrefill(torrentResponse: TorrentResponseDTO, floodAssist: FloodAssistOrderPrefillDTO): void {
    if (torrentResponse) {
      this.dataFromAPIResponse.torrentFloodQuote = torrentResponse;
      this.coveragesData.torrentResponse = torrentResponse;
    }

    if (torrentResponse?.floodZone && torrentResponse?.isFloodZoneAvailableFromTorrent) {
      this.coveragesData.floodForm.get('floodZone').setValue(torrentResponse.floodZone);
      this.coveragesData.floodForm.get('floodZone').disable();
      this.coveragesData.floodAssistRetrievalForm.get('isFloodZoneFromFloodAssist').setValue(false);
    } else if (floodAssist?.floodZone && floodAssist?.isFloodZoneFromFloodAssist) {
      this.coveragesData.floodForm.get('floodZone').setValue(floodAssist.floodZone);
      this.coveragesData.floodForm.get('floodZone').disable();
      this.coveragesData.floodAssistRetrievalForm.get('isFloodZoneFromFloodAssist').setValue(floodAssist?.isFloodZoneFromFloodAssist);
    }

    if (torrentResponse?.torrentTrackingNum) {
      this.coveragesData.floodForm.get('nFIPPremium').setValue(torrentResponse?.nfipPremium);
    }
  }

  mapTorrentFloodToSubmission(torrentResponse: TorrentResponseDTO): void {
    if (torrentResponse.passthroughAuthenticationAccess === ErrorMessageConstant.TorrentFlood.unauthorizedLoginResponse
      || torrentResponse.passthroughAuthenticationAccess === ErrorMessageConstant.TorrentFlood.noUsernameTorrentResponse) { return; }
    this.dataFromAPIResponse.torrentFloodQuote = torrentResponse;
    this.coveragesData.torrentResponse = torrentResponse;
    if (torrentResponse?.floodZone && torrentResponse?.isFloodZoneAvailableFromTorrent) {
      this.coveragesData.floodForm.get('floodZone').setValue(torrentResponse.floodZone);
      this.coveragesData.floodForm.get('floodZone').disable();
      this.coveragesData.floodAssistRetrievalForm.get('isFloodZoneFromFloodAssist').setValue(false);
    }
    if (torrentResponse?.torrentTrackingNum) {
      this.coveragesData.floodForm.get('nFIPPremium').setValue(torrentResponse?.nfipPremium);
    }
    this.coveragesData.isTorrentPurchased = torrentResponse.isTorrentPurchased;
    this.coveragesData.discountsForm.get('companion').setValue(this.isCompanionDiscountAvailable(torrentResponse));
  }

  isCompanionDiscountAvailable(torrentResponse: TorrentResponseDTO): boolean {
    return this.coveragesData.floodForm.get('privateFloodCoverage').value
      || this.coveragesData?.torrentResponse?.isTorrentPurchased;
  }

  getTorrentRequestModel(): TorrentRequestDTO {
    const coveragesForm = this.coveragesData.coveragesAndDeductiblesForm;
    const propertyDetailsForm = this.propertyData.propertyDetails;
    const locationDetailsForm = this.propertyData.locationDetails;
    const applicantForm = this.applicantData.mainApplicant;
    const contactForm = this.applicantData.contact;

    const request = new TorrentRequestDTO({
      riskId: this.summaryData.SummaryForm.get('riskId').value,
      riskDetailId: this.summaryData.SummaryForm.get('riskDetailId').value,
      coverageA: coveragesForm.get('coverageA').value ? Math.round(+coveragesForm.get('coverageA').value) : 0,
      coverageC: Math.round(+coveragesForm.get('coverageC').value),
      yearBuilt: propertyDetailsForm.get('yearBuilt').value,
      propertyUsage: !!!locationDetailsForm.get('propertyUsage').value ? PROPERTYUSAGEDEFAULT : locationDetailsForm.get('propertyUsage').value,
      foundationType: propertyDetailsForm.get('foundationType').value,
      structureType: locationDetailsForm.get('structureType').value,
      numberOfStories: propertyDetailsForm.get('numOfStories').value,
      firstName: applicantForm.get('firstName').value,
      lastName: applicantForm.get('lastName').value,
      streetAddress: applicantForm.get('addressLine1').value,
      city: applicantForm.get('qqCity').value,
      state: applicantForm.get('qqState').value,
      zipCode: applicantForm.get('qqZipCode').value,
      purchaseDate: propertyDetailsForm.get('purchaseDate').value?.singleDate?.jsDate ? Utils.mapDateToString(new Date(propertyDetailsForm.get('purchaseDate').value?.singleDate?.jsDate)) : null,
      mailingAddress: applicantForm.get('addressLine1').value,
      mailingAddressCity: applicantForm.get('qqCity').value,
      mailingAddressState: applicantForm.get('qqState').value,
      mailingAddressZipCode: applicantForm.get('qqZipCode').value,
      replacementCost: Math.round(+propertyDetailsForm.get('replacementCost').value),
      contactNumber: contactForm.get('mobilePhoneNumber').value,
      contactEmailAddress: contactForm.get('emailAddress').value,
      formType: this.quoteService.formType ?? this.summaryData.SummaryForm.get('formType').value
    });

    if (!applicantForm.get('isApplicantsMailingAddressSameAsInsuredLocation').value) {
      request.mailingAddress = applicantForm.get('mailingAddress').value;
      request.mailingAddressCity = applicantForm.get('city').value;
      request.mailingAddressState = applicantForm.get('state').value;
      request.mailingAddressZipCode = applicantForm.get('zipCode').value;
    }

    return request;
  }

  getMissingRequiredFields(): string[] {
    const requiredFields = [];
    const coveragesForm = this.coveragesData.coveragesAndDeductiblesForm;
    const propertyDetailsForm = this.propertyData.propertyDetails;
    const locationDetailsForm = this.propertyData.locationDetails;
    const applicantForm = this.applicantData.mainApplicant;

    if (!!!coveragesForm.get('coverageA').value && this.quoteService.formType !== FormTypeConstants.HO4) {
      requiredFields.push(CoveragesLabelsConstants.coveragesSection.coverageA);
    }
    if (!!!coveragesForm.get('coverageC').value && coveragesForm.get('coverageC').value !== 0) {
      requiredFields.push(CoveragesLabelsConstants.coveragesSection.coverageC);
    }
    if (!!!propertyDetailsForm.get('yearBuilt').value) {
      requiredFields.push(PropertyLabelConstants.yearBuilt);
    }
    if (!!!locationDetailsForm.get('propertyUsage').value && this.quoteService.formType !== FormTypeConstants.HO4) {
      requiredFields.push(LocationLabelConstants.propertyUsage);
    }
    if (!!!propertyDetailsForm.get('foundationType').value && this.quoteService.formType !== FormTypeConstants.HO6) {
      requiredFields.push(PropertyLabelConstants.foundationType);
    }
    if (!!!locationDetailsForm.get('structureType').value) {
      requiredFields.push(LocationLabelConstants.structureType);
    }
    if (!!!propertyDetailsForm.get('numOfStories').value) {
      requiredFields.push(PropertyLabelConstants.numberOfStories);
    }
    if (!!!applicantForm.get('firstName').value) {
      requiredFields.push(QuickQuotesLabelConstans.applicantPageLabel.firstName);
    }
    if (!!!applicantForm.get('lastName').value) {
      requiredFields.push(QuickQuotesLabelConstans.applicantPageLabel.lastName);
    }

    return requiredFields;
  }

  getFloodAssistModel(): FloodAssistRequestDTO {
    if (this.policySummaryData.isPolicy) {
      const propertyAddress = this.entityRiskData.EntityRisk?.entityAddresses?.find(a => a.addressTypeCode === AddressType.Property)?.address;
      return new FloodAssistRequestDTO({
        riskId: this.entityRiskData.getRiskId(),
        riskDetailId: this.entityRiskData.getRiskDetailId(),
        streetAddress: propertyAddress.streetAddress1,
        city: propertyAddress.city,
        state: propertyAddress.state,
        zipCode: propertyAddress.zipCode,
      });
    } else {
      const applicantForm = this.applicantData.mainApplicant;
      return new FloodAssistRequestDTO({
        riskId: this.entityRiskData.getRiskId(),
        riskDetailId: this.entityRiskData.getRiskDetailId(),
        streetAddress: applicantForm.get('addressLine1').value,
        city: applicantForm.get('qqCity').value,
        state: applicantForm.get('qqState').value,
        zipCode: applicantForm.get('qqZipCode').value,
      });
    }
  }

  mapFloodAssistResponseToSubmission(floodAssistResponse: FloodAssistResponseDTO): void {
    const floodAssistRetrieveForm = this.coveragesData.floodAssistRetrievalForm;
    const floodForm = this.coveragesData.floodForm;

    floodAssistRetrieveForm.get('isFloodAssistRetrieved').setValue(floodAssistResponse.isFloodAssistRetrieved);
    floodAssistRetrieveForm.get('isFloodAssistRetrievedSuccessful').setValue(floodAssistResponse.isFloodAssistRetrievedSuccessful);
    floodAssistRetrieveForm.get('floodAssistStatusCode').setValue(floodAssistResponse.floodAssistStatusCode);
    floodAssistRetrieveForm.get('isFIRMDateFromFloodAssist').setValue(floodAssistResponse.isFIRMDateFromFloodAssist);
    floodForm.get('firmDate').setValue(floodAssistResponse.firmDate ? new Date(floodAssistResponse.firmDate).getFullYear() : null);
    floodForm.get('participationStatusId').setValue(floodAssistResponse.participationStatusCode);
    floodForm.get('participationStatusId').markAsTouched();

    if (!this.coveragesData.torrentResponse?.isFloodZoneAvailableFromTorrent) {
      if (floodAssistRetrieveForm.get('isFloodZoneFromFloodAssist').value) { floodForm.get('floodZone').setValue(floodAssistResponse.floodZone || 'NA'); }
      floodAssistRetrieveForm.get('isFloodZoneFromFloodAssist').setValue(floodAssistResponse.isFloodZoneFromFloodAssist);
    }
  }

  checkIfFloodIsRequiredBeforeBinding(): boolean {
    const floodAssistRetrieveForm = this.coveragesData.floodAssistRetrievalForm;
    const floodForm = this.coveragesData.floodForm;

    return floodForm.get('privateFloodCoverage').value &&
      floodAssistRetrieveForm.get('isFloodAssistRetrieved').value &&
      !floodAssistRetrieveForm.get('isFloodAssistRetrievedSuccessful').value;
  }

  protected mapIfIsNoHitAddress(iso360Value: ResidentialResponseDTO) {
    const comparativeDetails: ComparativeRequestPayload = this.comparativeRaterData.comparativeDetails;
    if (!iso360Value || iso360Value?.isNoHitAddress) {
      if (this.comparativeRaterData.isFromComparativeRater) {
        this.dataFromAPIResponse.iso360Value = this.comparativeRaterData.mapIso360ValueFromComparative(comparativeDetails);
      } else {
        this.dataFromAPIResponse.iso360Value = new ResidentialResponseDTO({
          yearBuilt: '',
          squareFootage: '',
          valuationId: '',
          replacementCost: '0',
          replacementCostDate: '',
          pricingChanged: '',
          latitude: '',
          longitude: '',
          numberOfStories: new BasePropertyDataModel({
            iso360ValueCode: '',
            code: '',
            description: '',
          }),
          exteriorWallFinish: new BasePropertyDataModel({
            iso360ValueCode: '',
            code: '',
            description: '',
          }),
          construction: new BasePropertyDataModel({
            iso360ValueCode: '',
            code: '',
            description: '',
          }),
          roofShape: new BasePropertyDataModel({
            iso360ValueCode: '',
            code: '',
            description: '',
          }),
          foundationType: new BasePropertyDataModel({
            iso360ValueCode: '',
            code: '',
            description: '',
          }),
          structureType: new BasePropertyDataModel({
            iso360ValueCode: '',
            code: '',
            description: '',
          }),
          roofMaterial: new BasePropertyDataModel({
            iso360ValueCode: '',
            code: '',
            description: '',
          }),
          isNoHitAddress: true,
          costPerSqft: ''
        });
      }
    }

    if (this.quoteService.formType === this.formTypeConstants.HO6 && iso360Value) {
      iso360Value.exteriorWallFinish = new BasePropertyDataModel({
        iso360ValueCode: '',
        code: '',
        description: '',
      });

      iso360Value.construction = new BasePropertyDataModel({
        iso360ValueCode: '',
        code: '',
        description: '',
      });

      iso360Value.roofShape = new BasePropertyDataModel({
        iso360ValueCode: '',
        code: '',
        description: '',
      });

      iso360Value.foundationType = new BasePropertyDataModel({
        iso360ValueCode: '',
        code: '',
        description: '',
      });

      iso360Value.structureType = new BasePropertyDataModel({
        iso360ValueCode: '',
        code: '',
        description: '',
      });

      iso360Value.roofMaterial = new BasePropertyDataModel({
        iso360ValueCode: '',
        code: '',
        description: '',
      });
    }
  }

  locationDetails(): boolean {
    const results: boolean[] = [];

    this.valuationFields.forEach(field => {
      results.push(this.propertyData.getPropertyForm().propertyDetails.get(field).dirty ? true : false);
    });

    return results.includes(true) ? true : false;
  }

  public async recalculateValuationThenMap() {
    this.store.dispatch(updateRecalculateValuationIsLoadingFromThirdPartyData({ isLoading: true }));

    try {
      if (this.locationDetails() && this.quoteService.formType !== FormTypeConstants.HO4) {
        await this.thirdPartyDataService.iso360ValueRecalculateValuationAsync(this.getIso360ValueRequestModel()).then(data => {
          this.mapIso360ResultToProperty(data);
          this.store.dispatch(updateRecalculateValuationIsLoadingFromThirdPartyData({ isLoading: false }));
        });
      }
    } catch (e) {
      NotifUtils.showError(ErrorMessageConstant.Iso360ValueErrorMessage.Iso360ValueFailedToReceiveDataMessage);
      this.store.dispatch(updateRecalculateValuationIsLoadingFromThirdPartyData({ isLoading: false }));
    }
  }

  protected validateInternalUser(userType: string): boolean {
        return userType?.toLocaleLowerCase() === 'internal';
  }

  getISO360YearBuilt(): void {
    const isHO3orDP3 = (this.quoteService.formType === FormTypeConstants.HO3 || this.quoteService.formType === FormTypeConstants.DP3);
    const noIso360YearBuilt = (this.iso360YearBuilt === null || this.iso360YearBuilt === undefined);

    if (isHO3orDP3 && noIso360YearBuilt && !this.policySummaryData.isPolicy) {
      const request = this.getIso360ValueRequestModel();
      request.yearBuilt = '';
      this.store.dispatch(updateCalculateValuationIsLoadingFromThirdPartyData({ isLoading: true }));

      this.thirdPartyDataService.calculateValuation(request).pipe(takeUntil(this.stop$)).subscribe(result => {
        if (result?.yearBuilt) {
          this.iso360YearBuilt = Number(result?.yearBuilt);
          this.iso360YearBuiltValue$.next(true);
        }

        this.store.dispatch(updateCalculateValuationDataFromThirdPartyData({ data: result }));
        this.store.dispatch(updateCalculateValuationIsLoadingFromThirdPartyData({ isLoading: false }));
      }, error => {
        this.store.dispatch(updateCalculateValuationIsLoadingFromThirdPartyData({ isLoading: false }));
      });
    } else {
      this.iso360YearBuiltValue$.next(true);
    }
  }

  getHurricaneDeductibleStatus(): boolean {
    return  (!this.isInternalUser &&
      this.coveragesData?.county?.toString().toLocaleLowerCase() === this.QuickQuoteFormConstant.Coverages.Monroe) ||
      (!this.isInternalUser && (this.coveragesData.hurricaneDeductibleExcludedInfoMessage && !this.policySummaryData.isRenewal()));
  }

  checkThirdPartyBRUL(): void {
    //BR-PROP-02
    if (this.dataFromAPIResponse?.isMilesToFireStationFromISO) {
      this.propertyData.locationDetails.get('milesToFireStation').disable();
    }
  }

  resetAPlusReportRecord(): void {
    this.dataFromAPIResponse.aPlusReportRecord = null;
  }

  setCreditOrderingToNotOrdered(): void {
    this.quickQuoteData.isOrderCreditValue = false;
    this.coveragesData.hasSuccessOrderCredit = false;
    const creditDetail: CreditOrderDetail = {
      statusDescription: LvCreditOrderStatus[0].description
    };
    this.mapNoOrderCreditToCoveragesData();
    this.creditOrderDetail$.next(creditDetail);
  }
}
