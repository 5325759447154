import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentsLabelConstants } from '../../../../../../shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from '../../../../../../shared/constants/error-message.constants';
import { BsModalRef } from 'ngx-bootstrap';
import { BindAndIssueData } from '../../../../../submission-management/data/bind-and-issue.data';
import { GenericLabel } from '../../../../../../shared/constants/generic.labels.constants';
import { CustomValidators } from '../../../../../../shared/validators/custom.validator';
import { QuickQuoteService } from 'app/core/services/submission/quick-quote.service';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { DocumentUpload } from 'app/shared/models/policy/document-upload.model';
import { v4 as uuidv4 } from 'uuid';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { AuthService } from 'app/core/services/auth.service';

@Component({
  selector: 'app-document-upload-modal',
  templateUrl: './document-upload-modal.component.html',
  styleUrls: ['./document-upload-modal.component.scss']
})
export class DocumentUploadModalComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter<any>();
  public ErrorMessageConstant = ErrorMessageConstant;
  public DocumentsLabelConstants = DocumentsLabelConstants;
  public genericConstants = GenericConstants;

  GenericLabel = GenericLabel;
  documentsForm: FormGroup;
  modalRef: BsModalRef | null;
  title: any;
  documentsModel: any;
  isAdd: boolean;
  isCancel: boolean = false;
  idForSaving: any;
  fileToUpload: File = null;
  fileLabel: string;
  fileSizeExceeds: boolean = false;

  hideCategoryField: boolean = false;
  isUploadCheckImage: boolean = false;

  public reader: FileReader;

  dateAddedOption: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'mm/dd/yyyy'
  };

  constructor(protected fb: FormBuilder, public bsModalRef: BsModalRef, public bindAndIssueData: BindAndIssueData,
    public quickQuoteService: QuickQuoteService, public summaryData: SummaryData, protected authService: AuthService) { }

  ngOnInit() {
    const currentDate = this.authService.getCustomDate();
    this.documentsForm = this.fb.group({
      id: [this.documentsModel?.id],
      category: [this.documentsModel?.category, [Validators.required]],
      description: [this.documentsModel?.description, [Validators.required, CustomValidators.spaceValidator]],
      dateAdded: [this.documentsModel?.dateAdded ? this.documentsModel?.dateAdded.singleDate ? this.documentsModel?.dateAdded
        : this.getFromApiDate(this.documentsModel?.dateAdded)
        : {
          singleDate: {
            date: {
              year: currentDate.getFullYear(),
              month: currentDate.getMonth() + 1,
              day: currentDate.getDate()
            },
            formatted: `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`,
            jsDate: currentDate
          }
        }, [Validators.required]],
      file: ['', [Validators.required, CustomValidators.requiredFileFormat(this.genericConstants.acceptedFileTypes)]],
      fileName: [this.documentsModel?.fileName]
    });
    this.mapFile(this.documentsModel?.fileName);
    this.removeFieldValidations();
  }

  mapFile(file?): void {
    if (this.hideCategoryField) {
      this.documentsForm.get('category').setValue('MISC');
    }

    if (file?.name) {
      this.fileLabel = file.name;
    } else if (file) {
      this.fileLabel = file;
    } else {
      this.fileLabel = 'No File Chosen';
    }
  }

  getFromApiDate(date?): any {
    const new_date = new Date(date);
    return {
      singleDate: {
        date: {
          year: new_date.getFullYear(),
          month: new_date.getMonth() + 1,
          day: new_date.getDate()
        },
        formatted: `${new_date.getMonth() + 1}/${new_date.getDate()}/${new_date.getFullYear()}`,
        jsDate: date
      }
    };
  }

  setDisable() {
    if (this.documentsModel?.isEdit) {
      this.documentsForm.get('category').disable();
    }
  }

  hideModal(): void {
    this.isCancel = true;
    this.bsModalRef.hide();
  }

  onSubmit(): void {
    const document = new DocumentUpload({
      id: uuidv4(),
      documentFile: this.documentsForm.value.file,
      category: this.documentsForm.value.category,
      description: this.documentsForm.value.description,
      fileName: 'filename',
      uploadedBy: 'User',
      createdAt: this.documentsForm.value.dateAdded
    });

    this.event.emit({ data: document, res: 200 });
    this.bsModalRef.hide();
  }

  handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
    this.fileLabel = this.fileToUpload.name;
    this.documentsForm.get('file').setValue(this.fileToUpload);
    this.fileSizeExceeds = false;

    if (this.documentsForm.get('file').valid) {
      this.fileSizeExceeds = this.fileToUpload?.size > this.genericConstants.fileSizeLimit ? true : false;
    }
  }

  get invalidFileTypeMessage(): string {
    return 'The file type is invalid';
  }

  get acceptedFileTypes(): string {
    let acceptedFileTypes: string = '';

    this.genericConstants.acceptedFileTypes.forEach(item => {
      acceptedFileTypes += `.${item}, `;
    });

    return acceptedFileTypes;
  }

  protected removeFieldValidations(): void {
    if(this.isUploadCheckImage){
      this.documentsForm.get('description').clearValidators();
      this.documentsForm.get('description').updateValueAndValidity();
    }
  }
}
