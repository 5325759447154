import { Component, OnInit } from '@angular/core';
import { BaseClass } from '../../../../../shared/base-class';
import { TableNameConstants } from '../../../../../shared/constants/table.name.constants';
import { UWApprovalPageLabel } from '../../../../../shared/constants/uw-approval.labels.constants';
import { IHideTableItems, ITableFormControl, ITableTh } from '../../../../../shared/models/dynamic/table.interface';
import { UwApprovalData } from '../../../data/uw-approval.data';

@Component({
  selector: 'app-uw-renewal-referral',
  templateUrl: './uw-renewal-referral.component.html',
  styleUrls: ['./uw-renewal-referral.component.scss']
})
export class UwRenewalReferralComponent extends BaseClass implements OnInit {

  public TableNameConstants = TableNameConstants;
  RenewalReferralLabel = UWApprovalPageLabel.renewalReferral;
  isOpen: boolean = true;
  hideItems: IHideTableItems = {
    checkboxButton: true,
    viewIcon: true,
    deleteIcon: true,
    updateIcon: true,
    fileIcon: true,
    addButton: true
  };
  renewalReferralTableFormControls: ITableFormControl[] = [{ name: 'field_1', type: 'string' }];
  renewalReferralHeaders: ITableTh[] = [{ value: 'Description' }];

  constructor(
    public uwApprovalData: UwApprovalData
  ) {
    super();
  }

  ngOnInit() {
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }
}
