import { Pipe, PipeTransform } from '@angular/core';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';

@Pipe({
  name: 'laPropertyUsageLogic'
})
export class LA_PropertyUsageLogicPipe implements PipeTransform {

  transform(propertyUsage: string, formType: string, isResidenceRented: boolean): boolean {
    const seasonal = 'SS';
    const ownerOccupiedSecondary = 'OOS';
    const shortTermRental = 'STR';
    const tenantOccupied = 'TO';

    switch(formType) {
      case FormTypeConstants.HO3:
        if (propertyUsage === seasonal || propertyUsage === ownerOccupiedSecondary) {
          return true;
        }
        break;
      case FormTypeConstants.HO6:
        if ((propertyUsage === shortTermRental || propertyUsage === tenantOccupied) || isResidenceRented) {
          return true;
        }
        break;
      case FormTypeConstants.DP3:
        if (propertyUsage === seasonal) {
          return true;
        }
        break;
    }

    return false;
  }
}
