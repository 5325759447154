import { Injectable } from '@angular/core';
import { SuspendedPaymentDetailsDTO } from '../../../shared/models/data/dto/suspended-payment/suspended-payment-detail.dto';
import { BaseClass } from '../../../shared/base-class';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { LvSuspensionReason } from '../../../shared/constants/suspended-payments.options.constants';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { BillingService } from '../../../core/services/billing/billing.service';
import { SuspendedPaymentRequestDTO } from '../../../shared/models/data/dto/billing/suspended-payment-request.dto';
import { PayerDTO } from '../../../shared/models/data/dto/billing/payer.dto';
import { SuspendedPaymentResponseDTO } from '../../../shared/models/data/dto/billing/suspended-payment-response.dto';
import Utils from '../../../shared/utilities/utils';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { PagerService } from '../../../core/services/pagerService';
import { ReverseReturnedViewDTO } from '../../../shared/models/data/dto/suspended-payment/returned-suspended-payment-detail.dto';
import { ReturnedSuspendedPaymentListDTO } from '../../../shared/models/data/dto/suspended-payment/returned-suspended-payment-list.dto';
import { PaymentPage } from '../../../shared/enum/payment-page.enum';
import { Subject } from 'rxjs';
import { UpdateReturnDetailsDTO } from '../../../shared/models/data/dto/billing/update-return-suspended-payment.dto';
import { PaymentSuspendedDocDTO } from '../../../shared/models/data/dto/billing/payment-suspended-doc.dto';
import { RegexConstants } from '../../../shared/constants/regex.constants';
import { CustomValidators } from '../../../shared/validators/custom.validator';
import { SuspendedDetailDTO } from '../../../../app/shared/models/data/dto/billing/suspended-detail.dto';
import { SuspendedCheckDTO } from '../../../../app/shared/models/data/dto/billing/suspended-check.dto';
import { AuthService } from 'app/core/services/auth.service';
import { PolicyService } from '../../../core/services/submission/policy.service';
import { takeUntil } from 'rxjs/operators';
import FormUtils from '../../../shared/utilities/form.utils';
import { ZipCodeData } from '../../submission-management/data/zipcode.data';
import { PageSections } from '../../../shared/enum/page-sections.enum';
import { ImportIpxToTableRequestDTO } from '../../../shared/models/data/dto/billing/import-ipx-to-table-request.dto';
import { GenerateIpxReportRequestDTO } from '../../../shared/models/data/dto/billing/generate-ipx-report-request.dto';
import { IPXSuspendedPaymentDTO } from '../../../shared/models/data/dto/suspended-payment/ipx-suspended-payment.dto';
import { PutForIPXRequestDTO } from '../../../shared/models/data/dto/suspended-payment/put-for-ipx-request.dto';
import { IpxSuspenseReportRequestDTO } from '../../../shared/models/data/dto/billing/ipx-suspense-report-request.dto';
import { SuspendedPaymentsLabel } from '../../../shared/constants/suspended-payments.labels.constants';
import * as moment from 'moment';
import { CopyFileDocsSuspendedPaymentDTO } from '../../../shared/models/data/dto/suspended-payment/copy-file-docs-suspended-payment.dto';
import { CarryOverSourceDocsSuspendedPaymentDTO } from '../../../shared/models/data/dto/suspended-payment/carry-over-source-docs-suspended-payment.dto';
import { Router } from '@angular/router';
import { PolicyBillingConstants } from 'app/shared/constants/policy-billing.constants';
import { RefundRelatedConstants } from '../../../shared/constants/refund.options.constants';
@Injectable()
export class SuspendedPaymentsData extends BaseClass {

  public LvSuspensionReason = LvSuspensionReason;

  postSuspendedForm: FormGroup;
  addSuspendedForm: FormGroup;
  updateSuspendedFormGroup: FormGroup;
  reversePaymentFormGroup: FormGroup;
  returnSuspendedFormGroup: FormGroup;
  voidSuspendedFormGroup: FormGroup;
  detailsSuspendedFormGroup: FormGroup;
  ipxFormGroup: FormGroup;
  addSuspendedSplitPaymentFromGroup: FormGroup;
  dateOption: IAngularMyDpOptions;

  postSuspendedPaymentResponse: SuspendedPaymentResponseDTO;
  isPostSuspendedPaymentCompleted = new Subject<boolean>();
  isPostSuspendedPaymentCompleted$ = this.isPostSuspendedPaymentCompleted.asObservable();

  reverseReturnedPayment: ReverseReturnedViewDTO;
  returnedListPagedItems: ReturnedSuspendedPaymentListDTO[];
  public returnedList: ReturnedSuspendedPaymentListDTO[] = [];

  returnedListPager: any = {};
  returnedListLoading: boolean;

  suspendedPaymentListNotifier = new Subject<{ pageType: PaymentPage; suspendedPaymentList: any; isCompleted: boolean; isError: boolean; stayAtCurrentPage?: boolean; currentPage?: number }>();
  updateReturnDetailsNotifier = new Subject<{ updatedDetails: any; isCompleted: boolean; isError: boolean }>();
  detailsViewNotifier = new Subject<{ details: any; isCompleted: boolean; isError: boolean }>();

  suspendedPaymentList: SuspendedPaymentDetailsDTO[] = [];

  suspendedPaymentListPager: any = {};
  suspendedPaymentListLoading: boolean;
  suspendedPaymentListPagedItems: SuspendedPaymentDetailsDTO[];

  sentToIPXListPager: any = {};
  sentToIPXListPagedItems: IPXSuspendedPaymentDTO[];
  sentToIPXList: IPXSuspendedPaymentDTO[] = [];

  documentRequest: PaymentSuspendedDocDTO[] = [];
  isDocumentUploadSuccess = new Subject<boolean>();
  isDocumentUploadSuccess$ = this.isDocumentUploadSuccess.asObservable();
  paymentDocumentTableRows = [];

  returnedListIsCheckNotIssued: boolean;

  LvNameSuffix: any[];

  isPolicySearchDone: boolean;

  ipxFile: File;
  importedPaymentsReport: IpxSuspenseReportRequestDTO[] = [];

  public SuspendedPaymentsLabel = SuspendedPaymentsLabel;

  isSplitPayment: boolean = false;
  suspendedSplitPayment = [];
  sumOfSplitPaymentsPolicyPaymentAmounts: number;
  availableToAllocate: number;
  suspendedPaymentBatch: SuspendedPaymentRequestDTO[] = [];
  splitPaymentSourceDocs = [];
  copyFileDocs = [];
  paymentSuspendedDocsReqForSplit = [];

  public directBillCarriers = [
    { code: 'CSIC', description: 'Centauri Specialty Insurance Company' },
    { code: 'CNIC', description: 'Centauri National Insurance Company' }
  ];
  public FormUtils = FormUtils;
  RefundCommentsConstants = RefundRelatedConstants.refundComments;

  constructor(
    private fb: FormBuilder,
    private billingService: BillingService,
    private pagerService: PagerService,
    private authService: AuthService,
    private policyService: PolicyService,
    public zipCodeData: ZipCodeData,
    public router: Router
  ) {
    super();
    this.updateList();
    this.initForms();
    this.returnedListSetPage(1);
  }

  initForms(): void {
    this.postSuspendedForm = this.fb.group({
      policyNumber: new FormControl(''),
      insuredName: new FormControl(''),
      propertyAddress: new FormControl(''),
    });
    this.reversePaymentFormGroup = this.fb.group({
      comments: new FormControl('')
    });
    this.addSuspendedForm = this.addSuspendedSection();
    this.returnSuspendedFormGroup = this.initReturnSuspendedFormgroup();
    this.voidSuspendedFormGroup = this.initVoidSuspendedFormgroup();
    this.detailsSuspendedFormGroup = this.initDetailsSuspendedFormgroup();
    this.updateSuspendedFormGroup = this.initUpdateSuspendedFormgroup();
    this.ipxFormGroup = this.initIpxFormgroup();
    this.addSuspendedSplitPaymentFromGroup = this.addSuspendedSplitPayment();

    this.dateFormatOptions();
  }

  addSuspendedSection(): FormGroup {
    return new FormGroup({
      receiptDate: new FormControl(null, [Validators.required]),
      amount: new FormControl(null, [Validators.required, Validators.min(1)]),
      policyNumber: new FormControl(null, [Validators.pattern(RegexConstants.policyNumber), CustomValidators.spaceValidator]),
      suspendedReason: new FormControl('', [CustomValidators.spaceValidator]),
      comment: new FormControl(null, CustomValidators.spaceValidator),

      transactionNum: new FormControl(null, [CustomValidators.spaceValidator]),
      lockboxNum: new FormControl(null, [CustomValidators.spaceValidator]),
      batch: new FormControl(null, [CustomValidators.spaceValidator]),
      batchItem: new FormControl(null, [CustomValidators.spaceValidator]),

      checkAmount: new FormControl(null, [Validators.min(1)]),
      checkRoutingNum: new FormControl(null, [CustomValidators.spaceValidator]),
      checkAccountNum: new FormControl(null, [CustomValidators.spaceValidator]),
      checkNum: new FormControl(null, [CustomValidators.spaceValidator]),

      directBillCarrierId: new FormControl('', [Validators.required]),

      payerFirstName: new FormControl(null, [Validators.pattern(RegexConstants.payer)]),
      payerMiddleInitial: new FormControl(null, [Validators.pattern(RegexConstants.payer)]),
      payerLastName: new FormControl(null, [Validators.pattern(RegexConstants.payer)]),

      address1: new FormControl(null, [CustomValidators.spaceValidator]),
      address2: new FormControl(null, [CustomValidators.spaceValidator]),

      city: new FormControl(''),
      state: new FormControl(null),
      zipCode: new FormControl(null, [CustomValidators.spaceValidator]),
    });
  }

  addSuspendedSplitPayment(): FormGroup {
    return new FormGroup({
      amount: new FormControl(null, [Validators.required]),
    });
  }

  initReturnSuspendedFormgroup(): FormGroup {
    return new FormGroup({
      name: new FormControl('', [Validators.required, CustomValidators.spaceValidator, Validators.maxLength(64)]),
      mailingAddress: new FormControl('', [Validators.required, CustomValidators.spaceValidator, Validators.maxLength(32)]),
      aptOrUnit: new FormControl('', [CustomValidators.spaceValidator, Validators.maxLength(32)]),
      city: new FormControl('', [Validators.required, Validators.maxLength(32)] ),
      state: new FormControl({ value: '', disabled: true }, Validators.required),
      zipCode: new FormControl('', [Validators.required, CustomValidators.spaceValidator]),
      comments: new FormControl(this.RefundCommentsConstants.defaultComment, CustomValidators.spaceValidator),
      firstName: new FormControl('', [CustomValidators.spaceValidator]),
      lastName: new FormControl('', [CustomValidators.spaceValidator]),
      suffix: new FormControl('', [CustomValidators.spaceValidator]),
      isIndividualYesNo: new FormControl(false),
      country: new FormControl('', Validators.required),
    });
  }

  initVoidSuspendedFormgroup(): FormGroup {
    return new FormGroup({
      comments: new FormControl('', Validators.required)
    });
  }

  initDetailsSuspendedFormgroup(): FormGroup {
    return new FormGroup({
      returnDate: new FormControl({ value: '', disabled: true }),
      payee: new FormControl({ value: '', disabled: true }),
      checkNumber: new FormControl({ value: '', disabled: true }),
      checkDate: new FormControl({ value: '', disabled: true }),
      clearDate: new FormControl(''),
      escheatDate: new FormControl(''),
      address: new FormControl({ value: '', disabled: true })
    });
  }

  initUpdateSuspendedFormgroup(): FormGroup {
    return new FormGroup({
      policyNumber: new FormControl(null, [Validators.pattern(RegexConstants.policyNumber), CustomValidators.spaceValidator]),
      payerFirstName: new FormControl(null, [Validators.pattern(RegexConstants.payer)]),
      payerMiddleInitial: new FormControl(null, [Validators.pattern(RegexConstants.payer)]),
      payerLastName: new FormControl(null, [Validators.pattern(RegexConstants.payer)]),
      address1: new FormControl(null, [CustomValidators.spaceValidator]),
      address2: new FormControl(null, [CustomValidators.spaceValidator]),
      city: new FormControl(''),
      state: new FormControl(null),
      zipCode: new FormControl(null, [CustomValidators.spaceValidator]),
    });
  }

  initIpxFormgroup(): FormGroup {
    return new FormGroup({
      forIPX: new FormControl(false),
    });
  }

  dateFormatOptions() {
    this.dateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy'
    };
  }

  returnedListSetPage(page: number): void {
    if (page < 1) {
      return;
    }

    let returnedListFiltered = this.returnedList;
    returnedListFiltered.sort((a, b) => {
      return <any>new Date(b.returnDate) - <any>new Date(a.returnDate);
    });
    if (this.returnedListIsCheckNotIssued) {
      returnedListFiltered = this.returnedList.filter(x => x.checkIssueDate === null && x.checkNumber === null);
    }

    this.returnedListPager = this.pagerService.getPager(returnedListFiltered.length, page);
    this.returnedListPagedItems = returnedListFiltered.slice(this.returnedListPager.startIndex, this.returnedListPager.endIndex + 1);
  }

  suspendedPaymentListSetPage(page: number): void {
    if (page < 1) {
      return;
    }
    this.suspendedPaymentListPager = this.pagerService.getPager(this.suspendedPaymentList.length, page);
    this.suspendedPaymentListPagedItems = this.suspendedPaymentList.slice(this.suspendedPaymentListPager.startIndex, this.suspendedPaymentListPager.endIndex + 1);
  }

  sentToIPXListSetPage(page: number): void {
    if (page < 1) {
      return;
    }

    const sentToIPXListSorted = this.sentToIPXList;
    sentToIPXListSorted.sort((a, b) => {
      return <any>new Date(b.sentToIPXdate) - <any>new Date(a.sentToIPXdate);
    });

    this.sentToIPXListPager = this.pagerService.getPager(sentToIPXListSorted.length, page);
    this.sentToIPXListPagedItems = this.sentToIPXList.slice(this.sentToIPXListPager.startIndex, this.sentToIPXListPager.endIndex + 1);
  }

  getSuspendedPaymentList(callback?, stayAtCurrentPage: boolean = false): void {
    const temp_fixed_status_id = 'PEND';
    this.suspendedPaymentListNotifier.next({ pageType: PaymentPage.Pending, suspendedPaymentList: [], isCompleted: false, isError: false });
    Utils.blockUI();
    this.billingService.getAllSuspendedPayments(temp_fixed_status_id).subscribe((response: SuspendedPaymentDetailsDTO) => {
      this.suspendedPaymentListNotifier.next({ pageType: PaymentPage.Pending, suspendedPaymentList: response, isCompleted: true, isError: false, stayAtCurrentPage, currentPage: this.suspendedPaymentListPager.currentPage });
      Utils.unblockUI();
      if (callback) {
        callback();
      }
    }, err => {
      Utils.unblockUI();
      this.suspendedPaymentListNotifier.next({ pageType: PaymentPage.Pending, suspendedPaymentList: err.error, isCompleted: true, isError: true });
      NotifUtils.showError(JSON.stringify(err.error));
    });
  }

  getReturnedSuspendedPaymentList(callback?): void {
    this.suspendedPaymentListNotifier.next({ pageType: PaymentPage.Returned, suspendedPaymentList: [], isCompleted: false, isError: false });
    Utils.blockUI();
    this.billingService.getAllReturnedSuspendedPayments().subscribe((response: any) => {
      this.suspendedPaymentListNotifier.next({ pageType: PaymentPage.Returned, suspendedPaymentList: response, isCompleted: true, isError: false });
      Utils.unblockUI();
      if (callback) {
        callback();
      }
    }, err => {
      Utils.unblockUI();
      this.suspendedPaymentListNotifier.next({ pageType: PaymentPage.Returned, suspendedPaymentList: err.error, isCompleted: true, isError: true });
      NotifUtils.showError(JSON.stringify(err.error));
    });
  }

  getSentToIpxSuspendedPaymentList(callback?): void {
    this.suspendedPaymentListNotifier.next({ pageType: PaymentPage.SentToIpx, suspendedPaymentList: [], isCompleted: false, isError: false });
    Utils.blockUI();
    this.billingService.getSentToIpxSuspendedPayments().subscribe((response: any) => {
      this.suspendedPaymentListNotifier.next({ pageType: PaymentPage.SentToIpx, suspendedPaymentList: response, isCompleted: true, isError: false });
      Utils.unblockUI();
      if (callback) {
        callback();
      }
    }, err => {
      Utils.unblockUI();
      this.suspendedPaymentListNotifier.next({ pageType: PaymentPage.SentToIpx, suspendedPaymentList: err.error, isCompleted: true, isError: true });
      NotifUtils.showError(JSON.stringify(err.error));
    });
  }

  reloadReturnedSuspendedPaymentList(): void {
    this.suspendedPaymentListNotifier.next({ pageType: PaymentPage.Returned, suspendedPaymentList: [], isCompleted: false, isError: false });
    this.billingService.getAllReturnedSuspendedPayments().subscribe((response: any) => {
      this.suspendedPaymentListNotifier.next({ pageType: PaymentPage.Returned, suspendedPaymentList: response, isCompleted: true, isError: false });
    }, err => {
      this.suspendedPaymentListNotifier.next({ pageType: PaymentPage.Returned, suspendedPaymentList: err.error, isCompleted: true, isError: true });
      NotifUtils.showError(JSON.stringify(err.error));
    });
  }

  saveSuspendedPayments(): void {
    this.isPostSuspendedPaymentCompleted.next(false);
    const suspendedPaymentRequest = this.mapSuspendedPaymentRequest();
    Utils.blockUI();
    this.billingService.postCreateSuspendedPayment(suspendedPaymentRequest).subscribe((response: SuspendedPaymentResponseDTO) => {
      this.postSuspendedPaymentResponse = response;
      this.isPostSuspendedPaymentCompleted.next(true);
      if (this.documentRequest.length) {
        this.addSuspendedPaymentDoc(response.id);
      } else {
        Utils.unblockUI();
      }
    }, err => {
      this.isPostSuspendedPaymentCompleted.next(false);
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify(err.error));
    });
  }

  createSuspendedPaymentForSplitPayments(): void {
    this.billingService.postCreateBatchSuspendedPayment(this.suspendedPaymentBatch).subscribe(res => {
      if (res) {
        if (this.splitPaymentSourceDocs.length !== 0) {
          res.forEach(x => {
            this.splitPaymentSourceDocs.forEach(y => {
              const copyFileDocs: CopyFileDocsSuspendedPaymentDTO = {
                sourceFilePath: y.filePath,
                newFilePath: y.filePath.replace(/^.+\/(.+)\/.+\/?$/, `${x.id}/${y.fileName}`)
              };

              const filePathForPaymentSusDoc = this.router.url.includes('uistagingclone') ? SuspendedPaymentsLabel.splitPayment.stDocStgClone : SuspendedPaymentsLabel.splitPayment.stDocStg;
              const paymentSuspendedDoc: PaymentSuspendedDocDTO = {
                suspendedPaymentId: x.id,
                fileName: y.fileName,
                filePath: y.filePath.replace(/^.+\/(.+)\/.+\/?$/, `${filePathForPaymentSusDoc}billing/suspended-payment/${x.id}/${y.fileName}`),
                description: y.description,
                isActive: true,
                createdBy: y.createdBy
              };

              this.paymentSuspendedDocsReqForSplit.push(paymentSuspendedDoc);
              this.copyFileDocs.push(copyFileDocs);
            });
          });
          const carryOverDocsReq: CarryOverSourceDocsSuspendedPaymentDTO = {
            suspendedPaymentDocs: this.paymentSuspendedDocsReqForSplit,
            copyFileRelatedDocs: this.copyFileDocs
          };

          this.billingService.carryOverSourceDocForSplitPayment(carryOverDocsReq).subscribe(() => { });
        }

        const callback = () => NotifUtils.showSuccess(`${res.length} Suspended Payments Successfully Created`);
        this.getSuspendedPaymentList(callback);
      }
    });
  }

  loadSuspPaymentDocsSourceForSplitPayment(suspendedPaymentId: string): void {
    this.billingService.listSuspendedPaymentDoc(suspendedPaymentId).pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res) {
        this.splitPaymentSourceDocs = res;
      }
    });
  }

  mapSuspendedPaymentRequest(): SuspendedPaymentRequestDTO {
    const suspendedPaymentRequest: SuspendedPaymentRequestDTO = {
      // receiptDate: new Date(this.addSuspendedForm.get('receiptDate').value?.singleDate?.jsDate + 'UTC'),
      receiptDate: moment(this.addSuspendedForm.get('receiptDate').value?.singleDate?.jsDate).format('YYYY-MM-DDT00:00:00.000'),
      amount: this.getFormControlValue(this.addSuspendedForm, 'amount'),
      policyNumber: this.getFormControlValue(this.addSuspendedForm, 'policyNumber'),
      sourceId: 'IN',
      comment: this.getFormControlValue(this.addSuspendedForm, 'comment'),
      originalPolicyPaymentAmount: this.getFormControlValue(this.addSuspendedForm, 'amount'),

      directBillCarrierId: this.getFormControlValue(this.addSuspendedForm, 'directBillCarrierId'),

      suspendedDetail: this.mapSuspendedDetail(),
      suspendedCheck: this.mapSuspendedCheck(),

      suspendedPayer: this.mapPayer(),
    };

    if (this.getFormControlValue(this.addSuspendedForm, 'suspendedReason')) {
      suspendedPaymentRequest.reasonId = this.getFormControlValue(this.addSuspendedForm, 'suspendedReason');
    }

    return suspendedPaymentRequest;
  }

  mapSuspendedDetail(): SuspendedDetailDTO {
    const suspendedDetail: SuspendedDetailDTO = {
      transactionNum: this.getFormControlValue(this.addSuspendedForm, 'transactionNum'),
      lockboxNum: this.getFormControlValue(this.addSuspendedForm, 'lockboxNum'),
      batch: this.getFormControlValue(this.addSuspendedForm, 'batch'),
      batchItem: this.getFormControlValue(this.addSuspendedForm, 'batchItem'),
    };

    return suspendedDetail;
  }

  mapSuspendedCheck(): SuspendedCheckDTO {
    const suspendedDetail: SuspendedCheckDTO = {
      checkAmount: this.getFormControlValue(this.addSuspendedForm, 'checkAmount') || 0,
      checkRoutingNum: this.getFormControlValue(this.addSuspendedForm, 'checkRoutingNum'),
      checkAccountNum: this.getFormControlValue(this.addSuspendedForm, 'checkAccountNum'),
      checkNum: this.getFormControlValue(this.addSuspendedForm, 'checkNum')
    };

    return suspendedDetail;
  }

  mapPayer(): PayerDTO {
    const payer: PayerDTO = {
      firstName: this.getFormControlValue(this.addSuspendedForm, 'payerFirstName'),
      middleName: this.getFormControlValue(this.addSuspendedForm, 'payerMiddleInitial'),
      lastName: this.getFormControlValue(this.addSuspendedForm, 'payerLastName'),

      address1: this.getFormControlValue(this.addSuspendedForm, 'address1'),
      address2: this.getFormControlValue(this.addSuspendedForm, 'address2'),

      city: this.getFormControlValue(this.addSuspendedForm, 'city'),
      state: this.getFormControlValue(this.addSuspendedForm, 'state'),
      zip: this.getFormControlValue(this.addSuspendedForm, 'zipCode'),
    };

    return payer;
  }

  getFormControlValue(formGroup: FormGroup, formControlName: string): any {
    return formGroup.get(formControlName).value;
  }

  resetFormGroup(): void {
    this.addSuspendedForm = this.addSuspendedSection();
    this.returnSuspendedFormGroup = this.initReturnSuspendedFormgroup();
  }

  getDetailedSuspendedView(id: string): void {
    this.detailsViewNotifier.next({ details: {}, isCompleted: false, isError: false });
    Utils.blockUI();
    this.billingService.getSuspendedPaymentDetail(id).subscribe((response: SuspendedPaymentDetailsDTO) => {
      this.detailsViewNotifier.next({ details: response, isCompleted: true, isError: false });
      Utils.unblockUI();
    }, err => {
      this.detailsViewNotifier.next({ details: err.error, isCompleted: true, isError: true });
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify(err.error));
    });
  }

  updateList(): void {
    this.suspendedPaymentListNotifier.subscribe(result => {
      switch (result.pageType) {
        case PaymentPage.Pending:
          if (result.isCompleted && !result.isError) {
            this.suspendedPaymentList = result.suspendedPaymentList ? result.suspendedPaymentList : [];

            this.suspendedPaymentList = this.suspendedPaymentList.sort((val1, val2) => +new Date(val2.receiptDate) - +new Date(val1.receiptDate));
            this.suspendedPaymentListSetPage(!result?.stayAtCurrentPage ? 1 : result?.currentPage);
          }
          break;
        case PaymentPage.Returned:
          if (result.isCompleted && !result.isError) {
            this.returnedList = result.suspendedPaymentList ? result.suspendedPaymentList : [];
            this.returnedListSetPage(1);
          }
          break;
        case PaymentPage.SentToIpx:
          if (result.isCompleted && !result.isError) {
            this.sentToIPXList = result.suspendedPaymentList ? result.suspendedPaymentList : [];

            this.sentToIPXList = this.sentToIPXList.sort((val1, val2) => +new Date(val2.receiptDate) - +new Date(val1.receiptDate));
            this.sentToIPXListSetPage(1);
          }
          break;
      }
    });
  }

  updateReturnDetails(id: string): void {
    this.updateReturnDetailsNotifier.next({ updatedDetails: {}, isCompleted: false, isError: false });
    Utils.blockUI();
    const updateReturnDetailRequest = this.mapUpdateReturnDetailRequest(id);
    this.billingService.putReturnDetail(updateReturnDetailRequest).subscribe((response: SuspendedPaymentResponseDTO) => {
      this.updateReturnDetailsNotifier.next({ updatedDetails: response, isCompleted: true, isError: false });
      Utils.unblockUI();
    }, err => {
      this.updateReturnDetailsNotifier.next({ updatedDetails: err.error, isCompleted: true, isError: true });
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify(err.error));
    });
  }

  mapUpdateReturnDetailRequest(id: string): UpdateReturnDetailsDTO {
    const returnDetail: UpdateReturnDetailsDTO = {
      suspendedPaymentId: id,
      checkIssueDate: this.detailsSuspendedFormGroup.get('checkDate').value.singleDate?.jsDate,
      checkNumber: this.detailsSuspendedFormGroup.get('checkNumber').value,
      checkClearDate: this.detailsSuspendedFormGroup.get('clearDate').value?.singleDate?.jsDate ?? null,
      checkEscheatDate: this.detailsSuspendedFormGroup.get('escheatDate').value?.singleDate?.jsDate ?? null
    };
    return returnDetail;
  }

  addSuspendedPaymentDoc(suspendedId: string) {
    Utils.blockUI();
    this.isDocumentUploadSuccess.next(false);
    const request = this.documentRequest;
    request.forEach(val => val.suspendedPaymentId = suspendedId);

    this.billingService.addSuspendedPaymentDoc(request).subscribe(response => {
      Utils.unblockUI();
      this.isDocumentUploadSuccess.next(true);
    },
      err => {
        this.isDocumentUploadSuccess.next(false);
        Utils.unblockUI();
        NotifUtils.showMultiLineError(err.error?.message);
      }
    );
  }

  resetDocumentVariables(): void {
    this.documentRequest = [];
    this.paymentDocumentTableRows = [];
  }

  updateFormNameValidators(value: boolean): void {
    if (value) {
      this.returnSuspendedFormGroup.get('name').clearValidators();
      this.returnSuspendedFormGroup.get('firstName').setValidators([Validators.required, CustomValidators.spaceValidator]);
      this.returnSuspendedFormGroup.get('lastName').setValidators([Validators.required, CustomValidators.spaceValidator]);
      this.returnSuspendedFormGroup.get('suffix').setValidators([CustomValidators.spaceValidator]);
      this.returnSuspendedFormGroup.get('firstName').updateValueAndValidity();
      this.returnSuspendedFormGroup.get('lastName').updateValueAndValidity();
      this.returnSuspendedFormGroup.get('suffix').updateValueAndValidity();
      this.returnSuspendedFormGroup.get('name').updateValueAndValidity();
    } else {
      this.returnSuspendedFormGroup.get('name').setValidators([Validators.required, CustomValidators.spaceValidator, Validators.maxLength(64)]);
      this.returnSuspendedFormGroup.get('firstName').clearValidators();
      this.returnSuspendedFormGroup.get('lastName').clearValidators();
      this.returnSuspendedFormGroup.get('suffix').clearValidators();
      this.returnSuspendedFormGroup.get('firstName').updateValueAndValidity();
      this.returnSuspendedFormGroup.get('lastName').updateValueAndValidity();
      this.returnSuspendedFormGroup.get('suffix').updateValueAndValidity();
      this.returnSuspendedFormGroup.get('name').updateValueAndValidity();
    }
  }

  onPolicyNumberSearch(isEdit: boolean = false): void {
    Utils.blockUI();
    this.isPolicySearchDone = false;
    const form: FormGroup = !isEdit ? this.addSuspendedForm : this.updateSuspendedFormGroup;
    const searchText = isEdit ? this.updateSuspendedFormGroup.get('policyNumber').value : this.addSuspendedForm.get('policyNumber').value;

    this.policyService.searchPolicies(searchText)
      .pipe(takeUntil(this.stop$)).subscribe(result => {

        const isSiebelDataWithDuplicatePolicyNumber = result.length > 1 && result[0].isSiebel;
        const actOrPexpSiebelDataWithDuplicatePolicyNumber = result.filter(x => x.riskStatusCode === 'ACT' || x.riskStatusCode === 'PEXP');
        const latestActOrPexpSiebelDataWithDuplicatePolicyNumber = actOrPexpSiebelDataWithDuplicatePolicyNumber.sort((a, b) => new Date(b.firstIssueDate).getTime() - new Date(a.firstIssueDate).getTime())[0];

        Utils.unblockUI();
        if (result.length === 1) {
          form.get('payerFirstName').setValue(result[0].firstName);
          form.get('payerLastName').setValue(result[0].lastName);
          form.get('payerMiddleInitial').setValue(result[0].middleInitial ?? '');
          form.get('address1').setValue(result[0].propertyStreetAddress);
          form.get('address2').setValue(result[0].propertyStreetAddress2 ?? '');
          form.get('city').setValue(result[0].propertyCity);
          form.get('state').setValue(result[0].propertyState);
          form.get('zipCode').setValue(result[0].propertyZipCode);
          this.setZipCode(isEdit ? true : false);
        }

        if (isSiebelDataWithDuplicatePolicyNumber) {
          form.get('payerFirstName').setValue(latestActOrPexpSiebelDataWithDuplicatePolicyNumber.firstName);
          form.get('payerLastName').setValue(latestActOrPexpSiebelDataWithDuplicatePolicyNumber.lastName);
          form.get('payerMiddleInitial').setValue(latestActOrPexpSiebelDataWithDuplicatePolicyNumber.middleInitial ?? '');
          form.get('address1').setValue(latestActOrPexpSiebelDataWithDuplicatePolicyNumber.propertyStreetAddress);
          form.get('address2').setValue(latestActOrPexpSiebelDataWithDuplicatePolicyNumber.propertyStreetAddress2 ?? '');
          form.get('city').setValue(latestActOrPexpSiebelDataWithDuplicatePolicyNumber.propertyCity);
          form.get('state').setValue(latestActOrPexpSiebelDataWithDuplicatePolicyNumber.propertyState);
          form.get('zipCode').setValue(latestActOrPexpSiebelDataWithDuplicatePolicyNumber.propertyZipCode);
          this.setZipCode(isEdit ? true : false);
        }


        if (result !== undefined && result.length !== 1 && isEdit && !isSiebelDataWithDuplicatePolicyNumber) {
          this.clearPayerDetails(this.updateSuspendedFormGroup);
          this.isPolicySearchDone = true;
        }

        // Clear Payer Details when no results found
        if (result?.length === 0) {
          this.clearPayerDetails(isEdit ? this.updateSuspendedFormGroup : this.addSuspendedForm);
          this.isPolicySearchDone = true;
        }
      },
        err => {
          Utils.unblockUI();
          NotifUtils.showError(err.error?.message);
          this.isPolicySearchDone = true;
        });
  }

  setZipCode(isEdit: boolean = false): void {
    if (!isEdit) {
      this.addSuspendedForm.get('zipCode').enable();
    }
    const form = !isEdit ? this.addSuspendedForm : this.updateSuspendedFormGroup;
    const zipCode = form.get('zipCode').value;
    const formControlNames = ['state', 'city', 'zipCode', 'address1', 'address2'];
    Utils.blockUI();
    if (zipCode !== '') {
      this.zipCodeData.getGenericZipCode(zipCode, form, formControlNames, PageSections.AddSuspendedPayment, false);
      this.isPolicySearchDone = true;
    } else {
      this.FormUtils.resetFields(form, formControlNames);
      this.zipCodeData.addSupendedPaymentCityList = [];
      Utils.unblockUI();
      this.isPolicySearchDone = true;
    }
  }

  resetUpdateSuspendedForm(): void {
    this.updateSuspendedFormGroup = this.initUpdateSuspendedFormgroup();
  }

  clearPayerDetails(formGroup: FormGroup): void {
    const controlsToClear = [
      'payerFirstName',
      'payerMiddleInitial',
      'payerLastName',
      'address1',
      'address2',
      'city',
      'state',
      'zipCode'
    ];

    controlsToClear.forEach(key => {
      formGroup.get(key).setValue('');
    });
  }

  uploadIpx(): void {
    Utils.blockUI();
    //Upload to Azure Storage
    this.billingService.uploadIpx(this.ipxFile).subscribe(res1 => {

      if (res1) {
        const importIpxPayload: ImportIpxToTableRequestDTO = {
          applicationId: 'CENT',
          fileName: res1.fileName
        };
        //Posting of Imported Payments in Supended Payment Pending Table
        this.billingService.importIpxtoTable(importIpxPayload).subscribe(res2 => {
          if (res2.suspendedPayment.length === 0) {
            NotifUtils.showError('No Payments Imported', () => {
              Utils.unblockUI();
            });
          } else {
            NotifUtils.showSuccess(`${res2.suspendedPayment.length} Payments Imported`, () => {
              Utils.unblockUI();
              this.getSuspendedPaymentList();

              this.importedPaymentsReport = this.getCorrectMappingforIPXRequest(res2.suspendedPayment, true);

              const payloadForGenerateIpxReport: GenerateIpxReportRequestDTO = {
                isForImportedPayments: true,
                isFinal: false,
                ipxSuspenseList: this.importedPaymentsReport
              };
              //Generating of Reports
              this.billingService.generateIpxReport(payloadForGenerateIpxReport).subscribe(() => { });
            });
          }
        });
      }
    });
  }

  getListOfIpxSuspense(isFinal: boolean): void {
    this.billingService.getListOfIpxSuspense(isFinal).subscribe(list => {
      const ipxList: IpxSuspenseReportRequestDTO[] = this.getCorrectMappingforIPXRequest(list);

      const payloadForGenerateIpxReport: GenerateIpxReportRequestDTO = {
        isForImportedPayments: false,
        isFinal: isFinal,
        ipxSuspenseList: ipxList
      };

      if (list?.length > 0) {
        if (isFinal) {
          this.billingService.checkFinalReportExists().subscribe(fileExists => {
            if (!fileExists) {
              this.callGenerateIPXReportAPI(payloadForGenerateIpxReport, true);
            } else {
              NotifUtils.showWarning(this.SuspendedPaymentsLabel.Pending.Report.finalReportExistMsg, () => {
                this.callGenerateIPXReportAPI(payloadForGenerateIpxReport, true);
              });
            }
          });
        } else {
          this.callGenerateIPXReportAPI(payloadForGenerateIpxReport);
        }
      } else {
        NotifUtils.showError(this.SuspendedPaymentsLabel.Pending.Report.noResultsFound, () => {
          Utils.unblockUI();
        });
      }
    });
  }

  updateForIPX(payload: PutForIPXRequestDTO): void {
    Utils.blockUI();
    this.billingService.putForIPX(payload).subscribe(res => {
      if (res) {
        const flag = payload.forIPX ? 'Flagged' : 'Unflagged';

        const callback = () => NotifUtils.showSuccess(`${flag} for IPX successfully.`);
        this.getSuspendedPaymentList(callback, true);
      }
    });
  };

  getCorrectMappingforIPXRequest(list: any[], isForImport: boolean = false): IpxSuspenseReportRequestDTO[] {
    const correctList = [];

    list.forEach(item => {
      item.batchDate = this.getFormmatedDate(new Date(item?.receiptDate));
      item.checkNumber = isForImport ? item?.suspendedCheck?.checkNum : item?.checkNum;
      item.batchNumber = isForImport ? item?.suspendedDetail?.batch : item.batchNumber;

      this.cleanUpIPXSuspenseDetail(item, isForImport);

      correctList.push(item);
    });

    return correctList;
  };

  getFormmatedDate(date: Date): string {
    const month = date?.getUTCMonth() + 1;
    const day = date?.getUTCDate();
    const year = date?.getUTCFullYear();

    return `${month}/${day}/${year}`;
  };

  cleanUpIPXSuspenseDetail(item: any, isForImport: boolean): void {
    if (isForImport) {
      delete item.checkNum;
      delete item.suspendedCheck;
      delete item.checkRequested;
      delete item.comment;
      delete item.createdBy;
      delete item.createdById;
      delete item.createdDate;
      delete item.payer;
      delete item.payerId;
      delete item.reason;
      delete item.reasonId;
      delete item.source;
      delete item.sourceId;
      delete item.status;
      delete item.statusId;
      delete item.receiptDate;
      delete item.suspendedDetail;
    } else {
      delete item.checkNum;
      delete item.receiptDate;
      delete item.suspendedDetail;
      delete item.suspendedPayer;
    }
  }

  callGenerateIPXReportAPI(payload: GenerateIpxReportRequestDTO, isFinal: boolean = false) {
    Utils.blockUI();
    this.billingService.generateIpxReport(payload).subscribe(res => {
      if (res) {
        // Download Report File if Draft report
        if (!isFinal) {
          this.policyService.generateSASURL(res?.filePath).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
            window.open(resultSASURL);
          });
        }

        const msg = !isFinal ? `${payload.ipxSuspenseList?.length} Payment(s) Processed` : `${payload.ipxSuspenseList?.length} Payment(s) Processed.\n${res?.message}`;

        NotifUtils.showSuccess(msg, () => {
          Utils.unblockUI();
          this.getSuspendedPaymentList();
        });
      }
    });
  }

  resetSplitpaymentVariables() {
    this.isSplitPayment = false;
    this.suspendedSplitPayment = [];
    this.suspendedPaymentBatch = [];
    this.splitPaymentSourceDocs = [];
    this.copyFileDocs = [];
    this.paymentSuspendedDocsReqForSplit = [];
  }

  getNameLength(isIndividual: boolean ): number {
    const form = this.returnSuspendedFormGroup;
    const nameLength = form.get('name')?.value?.length;
    const fullNameLength = form.get('firstName')?.value?.length +
                          form.get('lastName')?.value?.length +
                          form.get('suffix')?.value?.length;

    return isIndividual ? fullNameLength : nameLength;
  }

  getDirectBillCarrierIdByRiskStateCode(riskState: string): string {
    switch(riskState.toUpperCase()) {
      case 'LA':
        return PolicyBillingConstants.CNIC;
      default:
        return PolicyBillingConstants.CSIC;
    }
  }
}
