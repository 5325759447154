import { createSelector } from '@ngrx/store';
import { BaseState } from '../base.state';

export const selectSubmissionState = (state: BaseState) => state.submission;

export const selectSubmissionIsLoading = createSelector(
  selectSubmissionState,
  (state) => state.isLoading
);

export const selectSubmissionStatus = createSelector(
  selectSubmissionState,
  (state) => state.status
);

export const selectSubmissionIsPopulatingForms = createSelector(
  selectSubmissionState,
  (state) => state.isPopulatingForms
);