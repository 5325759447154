import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { PolicySummaryComponent } from './policy-summary.component';
import { DeductiblesComponent } from './deductibles/deductibles.component';
import { InsuredComponent } from './insured/insured.component';
import { InterestsComponent } from './interests/interests.component';
import { MainCoveragesLimitsComponent } from './main-coverages-limits/main-coverages-limits.component';
import { OptionalCoveragesLimitsComponent } from './optional-coverages-limits/optional-coverages-limits.component';
import { PropertyCharacteristicsComponent } from './property-characteristics/property-characteristics.component';
import { NgxMaskModule } from 'ngx-mask';

const routes: Routes = [
  { path: '', component: PolicySummaryComponent }
];

@NgModule({
  declarations: [PolicySummaryComponent,
    DeductiblesComponent,
    InsuredComponent,
    InterestsComponent,
    MainCoveragesLimitsComponent,
    OptionalCoveragesLimitsComponent,
    PropertyCharacteristicsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot()
  ],
  exports: [PolicySummaryComponent]
})
export class PolicySummaryModule { }
