import { LvUWQuestionsModel } from '../models/lv-model/lv.uwquestion.model';


export const UwQuestionsLabelsConstants = {
  eligibility: {
    everRented: 'Is the home ever rented?',
    everRentTerms: 'What are the lease terms?',
    forRental: 'Are there any rooms, units, or other structures rented or held for rental at this residence?',
    forRentTerms: 'What are the lease terms?',
    tramponline: 'Is there a trampoline on the premises?',
    bars: 'Are there bars on the windows?',
    quickRelease: 'Are there quick release mechanisms on all barred windows?',
    centralHeating: 'Does the risk have central heat and air conditioning?',
    swimmingPool: 'Is there a swimming pool or hot tub on the premises?',
    divingBoard: 'Is there a diving board?',
    slide: 'Is there a slide?',
    // lockingFence: 'Is there a permanent, locking fence at least 4 ft high surrounding the pool or hot tub; or locking cover for hot tub?',
    lockingFence: 'Is there a permanent, locking fence at least 4 ft high, or alternate approved enclosure, surrounding the pool or hot tub; or locking cover for hot tub?',
    dogsClassified: 'Are there any dogs classified as, or a mix of one of the following breeds? Akitas, American Bulldogs, Beaucerons, Caucasian Mountain Dogs, Chow Chows, Doberman Pinschers, German Shepherds, Great Danes, Pit Bulls, Rottweilers, Staffordshire Terriers, Wolf Hybrids?',
    dogsKept: 'Do any dogs kept on the premises have a history of biting or have they been trained as attack or guard dogs?',
    exoticAnimals: 'Are there any exotic animals, farm or saddle animals kept on premises? This includes, but is not limited to, hoofed animals, livestock, reptiles, primates and fowl.',
    porches: 'Are there any porches/decks or stairs more than 2 feet high without handrails?',
    applicantHadThree: 'Has the applicant had three (3) or more total claims reported or one (1) or more personal liability claims in the last 3 years, whether or not reported to insurance?',
    prefabricated: 'Is the home mobile, manufactured, modular or prefabricated?',
    mobileHome: 'Is property classified as a manufactured or mobile home, motor home, house boat, house trailer, trailer home?',
    coverageDeclined: 'Has the applicant had any coverage declined, cancelled or non-renewed during the last 3 years for any reason besides nonpayment of premium?',
    // explain: 'Please explain:',
    explain: 'Explain',
    propertyNameBusiness: 'Is property in the name of a business, Limited Partnership, Corporation, land trust or estate?',
    farmingOrBusiness: 'Is there any farming or business conducted on the premises?',
    twoMortgagees: 'Are there more than 2 mortgagees?',
    propertyLocated: 'Is property located on more than five acres?',
    describe: 'Describe land use',
    collegeHousing: 'Is the home used for the purpose of college housing, assisted living, nursing home or group home facilities including child or adult daycare?',
    airparkCommunity: 'Is the home located in a fly-in fly-out or airpark community?',
    duplicateInsurance: 'Is there any duplicate insurance coverage provided on this home?',
    aluminum: 'Does the home contain any aluminum wiring, fuses or knob and tube wiring?',
    polybutylene: 'Does the home contain any polybutylene or cast iron plumbing?',
    unconvDwelling: 'Is the home an unconventional dwelling such as a pole, log or dome home?',
    historical: 'Is the home considered to be historical, located in a historical district or on a historical registry?',
    shingles: 'Does the roof have more than one overlay of shingles or have any wood shingles been overlayed?',
    // woodShingles: 'Have any wood woodShingles been overlayed?',
    woodShingles: 'Have any wood shingles been overlayed?',
    unconventional: 'Was the home constructed by any unconventional method such as log, pole barn home, do-it-yourself, dome, shell, or utilize any unconventional parts?',
    uncorrected: 'Does the home have any uncorrected fire or building code violations?',
    paved: 'Does the home have paved access from the street?',
    overWater: 'Is the home located entirely or partially over water?',
    dwelling: 'Does the dwelling have a separate wind policy?',
    goodCondition: 'Is the dwelling free of maintenance concerns and existing damage?',
    sinkholes: 'Does the property have, or is it adjacent to a property with prior sinkhole activity or loss? ',
    unusualLiability: 'Are there any excessive or unusual liability exposures on the property such as skateboard or bicycle ramps, empty swimming pools, etc.?',
    asbestos: 'Was the home built using asbestos in any part including but not limited to roofing, siding, and walls?',
  },
  generalQuestions: {
    residenceOwned: 'Any other residence owned, occupied, or rented by the applicant?',
    otherInsurance: 'Does the applicant have any other insurance with this company?',
    additionalPolicyNumbers: 'Additional Policy Numbers',
    moreThanOneUnit: 'Is there more than one unit, apartment, room, or other structure rented, or held for rent at this residence?',
    buildingUnderContruction: 'Is building undergoing construction or renovation?',
    estimatedCompletion: 'Estimated Completion Date',
    pleaseDescribe: 'Please describe',
    convicted: 'Has any applicant ever been convicted of any degree of the crime of arson?',
    uwAcknowledgement: 'I hereby certify that I have reviewed and accurately responded to all underwriting questions.',
  },
  infoMessage: {
    trampolinePremise: 'Liability for trampolines is excluded from the policy.',
    divingBoard: 'Liability for diving boards is excluded from the policy.',
    slide: 'Liability for swimming pool slides is excluded from the policy.',
    dogsClassified: 'Liability for these dog breeds is excluded from the policy.',
    exoticAnimals: 'Liability for exotic, farm and/or saddle animals is excluded from the policy.'
  }
};


export const LvUWQuestions: LvUWQuestionsModel[] = [
  {
    id: 1,
    code: 'IsTrampPremis',
    description: 'Is there a trampoline on the premises?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 2,
    code: 'AreBarsWindow',
    description: 'Are there bars on the windows?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 3,
    code: 'AreQReleaseBarredWin',
    description: 'Are there quick release mechanisms on all barred windows?',
    parentCode: 'AreBarsWindow',
    section: 'Eligibility'
  },
  {
    id: 4,
    code: 'IsSwimHotTubPremis',
    description: 'Is there a swimming pool or hot tub on the premises?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 5,
    code: 'IsDivBoard',
    description: 'Is there a diving board?',
    parentCode: 'IsSwimHotTubPremis',
    section: 'Eligibility'
  },
  {
    id: 6,
    code: 'IsSlide',
    description: 'Is there a slide?',
    parentCode: 'IsSwimHotTubPremis',
    section: 'Eligibility'
  },
  {
    id: 7,
    code: 'IsPermanentLockFence',
    description: 'Is there a permanent, locking fence at least 4 ft high, or alternate approved enclosure, surrounding the pool or hot tub; or locking cover for hot tub?',
    parentCode: 'IsSwimHotTubPremis',
    section: 'Eligibility'
  },
  {
    id: 8,
    code: 'AreDogsClassified',
    description: 'Are there any dogs classified as, or a mix of one of the following breeds? Akitas, American Bulldogs, Beaucerons, Caucasian Mountain Dogs, Chow Chows, Doberman Pinschers, German Shepherds, Great Danes, Pit Bulls, Rottweilers, Staffordshire Terriers, Wolf Hybrids?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 9,
    code: 'DoDogsKept',
    description: 'Do any dogs kept on the premises have a history of biting or have they been trained as attack or guard dogs?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 10,
    code: 'AreExoticAnimals',
    description: 'Are there any exotic animals, farm or saddle animals kept on premises? This includes, but is not limited to, hoofed animals, livestock, reptiles, primates and fowl.',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 11,
    code: 'ArePorchesDecks',
    description: 'Are there any porches/decks or stairs more than 2 feet high without handrails?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 12,
    code: 'HasApplicantClaim',
    description: 'Has the applicant had three (3) or more total claims reported or one (1) or more personal liability claims in the last 3 years, whether or not reported to insurance?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 13,
    code: 'IsPrefabricatedModHome',
    description: 'Is the home mobile, manufactured, modular or prefabricated?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 14,
    code: 'IsPropertyClassified',
    description: 'Is property classified as a manufactured or mobile home, motor home, house boat, house trailer, trailer home?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 15,
    code: 'HasAppCovDeclined',
    description: 'Has the applicant had any coverage declined, cancelled or non-renewed during the last 3 years for any reason besides nonpayment of premium?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 16,
    code: 'Explain',
    description: 'Explain',
    parentCode: 'HasAppCovDeclined',
    section: 'Eligibility'
  },
  {
    id: 17,
    code: 'IsPropertyBusiness',
    description: 'Is property in the name of a business, Limited Partnership, Corporation, land trust or estate?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 18,
    code: 'IsFarmBusPremis',
    description: 'Is there any farming or business conducted on the premises?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 19,
    code: 'IsProp5Acres',
    description: 'Is property located on more than five acres?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 20,
    code: 'Describe',
    description: 'Describe land use',
    parentCode: 'IsProp5Acres',
    section: 'Eligibility'
  },
  {
    id: 21,
    code: 'IsHomeUsed',
    description: 'Is the home used for the purpose of college housing, assisted living, nursing home or group home facilities including child or adult daycare?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 22,
    code: 'IsHomeLocated',
    description: 'Is the home located in a fly-in fly-out or airpark community?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 23,
    code: 'IsAnyDupilcate',
    description: 'Is there any duplicate insurance coverage provided on this home?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 24,
    code: 'DoesHomeAlumW',
    description: 'Does the home contain any aluminum wiring, fuses or knob and tube wiring?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 25,
    code: 'DoesHomePolyIron',
    description: 'Does the home contain any polybutylene or cast iron plumbing?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 26,
    code: 'IsHomeHistorical',
    description: 'Is the home considered to be historical, located in a historical district or on a historical registry?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 27,
    code: 'DoesRoofShingles',
    description: 'Does the roof have more than one overlay of shingles or have any wood shingles been overlayed?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 28,
    code: 'HaveWoodShingles',
    description: 'Have any wood shingles been overlayed?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 29,
    code: 'WasHomeConsUncovMethod',
    description: 'Was the home constructed by any unconventional method such as log, pole barn home, do-it-yourself, dome, shell, or utilize any unconventional parts?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 30,
    code: 'DoesHomeHaveVio',
    description: 'Does the home have any uncorrected fire or building code violations?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 31,
    code: 'DoesHomePaved',
    description: 'Does the home have paved access from the street?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 32,
    code: 'IsHomeLocOverWater',
    description: 'Is the home located entirely or partially over water?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 33,
    code: 'HasAppliCrimeOfArson',
    description: 'Has any applicant ever been convicted of any degree of the crime of arson?',
    parentCode: null,
    section: 'General Question'
  },
  {
    id: 34,
    code: 'DoesDwelSepWindPol',
    description: 'Does the dwelling have a separate wind policy?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 35,
    code: 'IsDwellGCondition',
    description: 'Is the dwelling free of maintenance concerns and existing damage?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 36,
    code: 'IsPropLocKnownSink',
    description: 'Does the property have, or is it adjacent to a property with prior sinkhole activity or loss?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 37,
    code: 'WasHomeBuiltAsbestos',
    description: 'Was the home built using asbestos in any part including but not limited to roofing, siding, and walls?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 38,
    code: 'AnyOthResApplicant',
    description: 'Any other residence owned, occupied, or rented by the applicant?',
    parentCode: null,
    section: 'General Question'
  },
  {
    id: 39,
    code: 'DoesAppHaveInsurance',
    description: 'Does the applicant have any other insurance with this company?',
    parentCode: null,
    section: 'General Question'
  },
  {
    id: 40,
    code: 'AddPolNumber',
    description: 'Additional Policy Numbers',
    parentCode: 'DoesAppHaveInsurance',
    section: 'General Question'
  },
  {
    id: 41,
    code: 'IsMoreThanOneUnit',
    description: 'Is there more than one unit, apartment, room, or other structure rented, or held for rent at this residence?',
    parentCode: null,
    section: 'General Question'
  },
  {
    id: 42,
    code: 'IsBldgUnConsRenov',
    description: 'Is building undergoing construction or renovation?',
    parentCode: null,
    section: 'General Question'
  },
  {
    id: 43,
    code: 'EstCompleDate',
    description: 'Estimated Completion Date',
    parentCode: 'IsBldgUnConsRenov',
    section: 'General Question'
  },
  {
    id: 44,
    code: 'PleaseDescibe',
    description: 'Please describe',
    parentCode: 'IsBldgUnConsRenov',
    section: 'General Question'
  },
  {
    id: 45,
    code: 'AreTherPrimHeatSource',
    description: 'Are space heaters, woodburning stoves or fireplaces the primary heat source?',
    parentCode: null,
    section: 'General Question'
  },
  {
    id: 46,
    code: 'ICertify',
    description: 'I hereby certify that I have reviewed and accurately responded to all underwriting questions.',
    parentCode: null,
    section: 'General Question'
  },
  {
    id: 47,
    code: 'IsEverRented',
    description: 'Is the home ever rented?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 48,
    code: 'EverRentLease',
    description: 'What are the lease terms?',
    parentCode: 'IsEverRented',
    section: 'Eligibility'
  },
  {
    id: 49,
    code: 'IsForRent',
    description: 'Are there any rooms, units, or other structures rented or held for rental at this residence?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 50,
    code: 'ForRentLease',
    description: 'What are the lease terms?',
    parentCode: 'IsForRent',
    section: 'Eligibility'
  },
  {
    id: 51,
    code: 'HaveCentralHeat',
    description: 'Does the risk have central heat and air conditioning?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 52,
    code: 'IsUnconvDwelling',
    description: 'Is the home an unconventional dwelling such as a pole, log or dome home?',
    parentCode: null,
    section: 'Eligibility'
  },
  {
    id: 53,
    code: 'AreUnusualLiability',
    description: 'Are there any excessive or unusual liability exposures on the property such as skateboard or bicycle ramps, empty swimming pools, etc.?',
    parentCode: null,
    section: 'Eligibility',
    isDP3: false
  },
  {
    id: 54,
    code: 'AreUnusualLiability',
    description: 'Are there any excessive or unusual liability exposures on the property such as skateboard or bicycle ramps, empty swimming pools, or trampolines without netting, etc.?',
    parentCode: null,
    section: 'Eligibility',
    isDP3: true
  },
];

export const UwQuestionsFieldName = {
  eligibility: {
    IsEverRented : 'IsEverRented',
    EverRentLease : 'EverRentLease',
    IsForRent : 'IsForRent',
    ForRentLease : 'ForRentLease',
    IsTrampPremis : 'IsTrampPremis',
    AreBarsWindow : 'AreBarsWindow',
    HaveCentralHeat : 'HaveCentralHeat',
    AreQReleaseBarredWin : 'AreQReleaseBarredWin',
    IsSwimHotTubPremis : 'IsSwimHotTubPremis',
    IsDivBoard : 'IsDivBoard',
    IsSlide : 'IsSlide',
    IsPermanentLockFence : 'IsPermanentLockFence',
    AreDogsClassified : 'AreDogsClassified',
    DoDogsKept : 'DoDogsKept',
    AreExoticAnimals : 'AreExoticAnimals',
    ArePorchesDecks : 'ArePorchesDecks',
    HasApplicantClaim : 'HasApplicantClaim',
    IsPrefabricatedModHome : 'IsPrefabricatedModHome',
    IsPropertyClassified : 'IsPropertyClassified',
    HasAppCovDeclined : 'HasAppCovDeclined',
    Explain : 'Explain',
    IsPropertyBusiness : 'IsPropertyBusiness',
    IsFarmBusPremis : 'IsFarmBusPremis',
    IsProp5Acres : 'IsProp5Acres',
    Describe : 'Describe',
    IsHomeUsed : 'IsHomeUsed',
    IsHomeLocated : 'IsHomeLocated',
    IsAnyDupilcate : 'IsAnyDupilcate',
    DoesHomeAlumW : 'DoesHomeAlumW',
    DoesHomePolyIron : 'DoesHomePolyIron',
    IsUnconvDwelling : 'IsUnconvDwelling',
    IsHomeHistorical : 'IsHomeHistorical',
    DoesRoofShingles : 'DoesRoofShingles',
    HaveWoodShingles : 'HaveWoodShingles',
    WasHomeConsUncovMethod : 'WasHomeConsUncovMethod',
    DoesHomeHaveVio : 'DoesHomeHaveVio',
    DoesHomePaved : 'DoesHomePaved',
    IsHomeLocOverWater : 'IsHomeLocOverWater',
    DoesDwelSepWindPol : 'DoesDwelSepWindPol',
    IsDwellGCondition : 'IsDwellGCondition',
    IsPropLocKnownSink : 'IsPropLocKnownSink',
    AreUnusualLiability : 'AreUnusualLiability',
    WasHomeBuiltAsbestos : 'WasHomeBuiltAsbestos'
  },
  generalQuestions: {
    AnyOthResApplicant : 'AnyOthResApplicant',
    DoesAppHaveInsurance : 'DoesAppHaveInsurance',
    AddPolNumber : 'AddPolNumber',
    IsMoreThanOneUnit : 'IsMoreThanOneUnit',
    IsBldgUnConsRenov : 'IsBldgUnConsRenov',
    EstCompleDate : 'EstCompleDate',
    PleaseDescibe : 'PleaseDescibe',
    HasAppliCrimeOfArson : 'HasAppliCrimeOfArson',
    ICertify : 'ICertify'
  }
};