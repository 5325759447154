import { Subject } from 'rxjs';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import NotifUtils from '../../../../shared/utilities/notif-utils';
import { ApplicantPageComponent } from './applicant-page.component';
import { SummaryData } from '../../data/summary.data';
import { PageSectionsValidations } from '../../../../shared/enum/page-sections.enum';
import { PathConstants } from '../../../../shared/constants/path.constants';
import { GenericConstants } from '../../../../shared/constants/generic.constants';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { ErrorMessageConstant } from '../../../../shared/constants/error-message.constants';
import { ThirdPartyData } from '../../data/third-party.data';
import Utils from 'app/shared/utilities/utils';
import { RaterPremiumService } from 'app/core/services/submission/rater-premium/rater-premium.service';
import { CoveragesData } from '../../data/coverages.data';
import { ClaimsData } from '../../data/claims.data';
import { ApplicantData } from '../../data/applicant.data';

@Injectable()
export class CanDeactivateApplicantPageComponentGuard implements CanDeactivate<ApplicantPageComponent> {
    genericConstants = GenericConstants;

    constructor(protected qqData: SummaryData, public router: Router,
        protected policySummaryData: PolicySummaryData, protected thirdPartyData: ThirdPartyData,
        protected raterPremiumService: RaterPremiumService,
        protected coveragesData: CoveragesData,
        protected claimsData: ClaimsData,
        protected applicantData: ApplicantData) {}

    canDeactivate(quickQuote: ApplicantPageComponent, route: ActivatedRouteSnapshot, state: RouterStateSnapshot, nextState: RouterStateSnapshot) {
        const subject = new Subject<boolean>();
        //const displayPopup = sessionStorage.getItem(PageSectionsValidations.CurrentPage) !== PathConstants.PageDestination.Quickquote && this.qqData.SummaryForm.get('riskId').value ? true : false;
        const riskId = this.qqData.SummaryForm.get('riskId').value;
        const displayPopup = riskId ? true : false;
        const showPopUp = !this.policySummaryData.isPolicyPage(riskId) || this.policySummaryData.isEditPolicy;
        const isRevisedRenewalPending = (this.policySummaryData.renewalCode === 'RRNPEN');
        if (nextState.url === this.genericConstants.submissionsListUrl && displayPopup && showPopUp && !isRevisedRenewalPending) {
            NotifUtils.showConfirmMessage(PageSectionsValidations.ConfirmMessageForSubmission,
                () => {
                    subject.next(true);
                }, () => {
                    subject.next(false);
                });
            return subject.asObservable();
        } else {
            const isOrderYesNo = quickQuote.applicantData.mainApplicant.get('isOrderYesNo').value;
            const isFCRAChecked = quickQuote.applicantData.fcraReportingActForm.get('fcraReportingAct').value;
            const hasRiskId = quickQuote.entityRiskData.getRiskId();
            const destinationAllowed = nextState.url.includes(this.genericConstants.quoteURL.submissions);
            const isNotRenewal = !this.policySummaryData.isRenewal();
            if (isNotRenewal && hasRiskId && quickQuote.hasUpdateInApplicantNameOrBirthDate() && (isOrderYesNo || isFCRAChecked) && destinationAllowed) {
                Utils.blockUI();
                quickQuote.orderCredit(result => {
                    Utils.unblockUI();
                    if (result) {
                        this.qqData.isUpdatedInsuredProfileOrPrefill = true;
                        this.thirdPartyData.mapCreditScoreInfoResultToCoveragesData(result);
                        quickQuote.coveragesData.populateFinancialResponsibility(result.creditScore);
                        this.thirdPartyData.coveragesSectionOrderCredit$.next(true);

                        switch (result.errorCode) {
                            case ErrorMessageConstant.CreditService.CS02ErrorCode:
                            case ErrorMessageConstant.CreditService.CE01ErrorCode:
                            case ErrorMessageConstant.CreditService.NcfApiErrorCode:
                            case ErrorMessageConstant.CreditService.GeneralErrorCode:
                              quickQuote.quickQuoteData.isOrderCreditValue = false;
                              this.thirdPartyData.setFcraValidatorOnError();
                            break;
                            default:
                              break;
                        }
                        if (result.errorCode === ErrorMessageConstant.CreditService.CS02ErrorCode && displayPopup) {
                            NotifUtils.showError(ErrorMessageConstant.CreditService.CS02ErrorCodeMessage, () => {
                                subject.next(false);
                            });
                        } else if ((result.errorCode === ErrorMessageConstant.CreditService.CE01ErrorCode ||
                            result.errorCode === ErrorMessageConstant.CreditService.NcfApiErrorCode) && displayPopup) {
                            NotifUtils.showError(ErrorMessageConstant.CreditService.LexisNexisNcfFailed_V1, () => {
                                subject.next(false);
                            });
                        } else if (result.errorCode === ErrorMessageConstant.CreditService.GeneralErrorCode && displayPopup) {
                            NotifUtils.showError(ErrorMessageConstant.CreditService.GeneralErrorMessage, () => {
                                subject.next(false);
                            });
                        } else if (result.errorCode === ErrorMessageConstant.CreditService.NcfApiAccountCodeErrorCode && displayPopup) {
                            NotifUtils.showError(ErrorMessageConstant.CreditService.AccountSetupBrQq41InvaliderrorMessage, () => {
                                subject.next(false);
                            });
                        } else {
                            subject.next(true);
                        }
                    } else {
                        this.thirdPartyData.mapCreditScoreNotOrderedToCoveragesData();
                        this.thirdPartyData.setFcraValidatorOnError();
                        NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage, () => {
                            subject.next(false);
                        });
                    }
                }, error => {
                    Utils.unblockUI();
                    this.thirdPartyData.mapCreditScoreNotOrderedToCoveragesData();
                    this.thirdPartyData.setFcraValidatorOnError();
                    NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage, () => {
                        subject.next(false);
                    });
                }, () => {
                    Utils.unblockUI();
                });
                return subject.asObservable();
            }
        }

        return true;
    }

    protected async getClaimTier() {
        const applicantForm = this.applicantData.mainApplicant;
        const effectiveDateRaw = applicantForm.get('effectiveDate').value?.singleDate?.date ??
          new Date(applicantForm.get('effectiveDate').value?.singleDate?.jsDate).toLocaleDateString('en-US');
        const effectiveDateRefined = effectiveDateRaw.month ? `${effectiveDateRaw.month}/${effectiveDateRaw.day}/${effectiveDateRaw.year}` : effectiveDateRaw;
        const claimsCount = this.claimsData.claimList ? this.claimsData.claimList.length : this.coveragesData?.entityRiskData?.getRisk()?.riskDetails[0]?.riskClaims?.length;
        await this.raterPremiumService.getPriorClaimTierAsync(this.coveragesData?.creditScore,
            claimsCount, effectiveDateRefined).then(claimTier => {
                this.coveragesData.creditPriorClaimTier = claimTier;
            });
    }
}