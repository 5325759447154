import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { PolicyBillingLabelsConstants } from '../../../../shared/constants/policy-billing.labels.constants';
import { BaseClass } from '../../../../shared/base-class';
import { PolicyBillingData } from '../../data/policy-billing.data';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { PaymentDetailsModalComponent } from './payment-details-modal/payment-details-modal.component';
import { PostPaymentModalComponent } from './post-payments/post-payment-modal/post-payment-modal.component';
import { WriteOffModalComponent } from './write-off-modal/write-off-modal.component';
import { AdjustmentModalComponent } from './adjustment-modal/adjustment-modal.component';
import { BillingService } from '../../../../../app/core/services/billing/billing.service';
import { PaymentDetailsViewDTO } from '../../../../../app/shared/models/data/dto/billing/payment-details-view.dto';
import { takeUntil } from 'rxjs/operators';
import NotifUtils from '../../../../../app/shared/utilities/notif-utils';
import { PolicyBillingConstants } from '../../../../shared/constants/policy-billing.constants';
import { PaymentType } from '../../../../../app/shared/enum/payment-type.enum';
import Utils from '../../../../../app/shared/utilities/utils';
import { EntityRiskData } from '../../../../../app/modules/submission-management/data/entity-risk.data';
import { AuthService } from '../../../../../app/core/services/auth.service';
import { PagerService } from '../../../../../app/core/services/pagerService';
import { AutoReinstatementData } from '../../data/auto-reinstatement.data';
import { ThirdPartyDataService } from 'app/core/services/submission/third-party/third-party-data.service';
import { PolicyService } from 'app/core/services/submission/policy.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent extends BaseClass implements OnInit {
  isOpen: boolean = true;

  public paymentLabelConstants = PolicyBillingLabelsConstants.payments;
  public policyBillingLabelConstants = PolicyBillingLabelsConstants;

  paymentDetailsModalRef: BsModalRef | null;

  isInternal: boolean;
  public paymentsHeaders: string[] = [
    this.paymentLabelConstants.postDate,
    this.paymentLabelConstants.amount,
    this.paymentLabelConstants.type,
    this.paymentLabelConstants.method,
    this.paymentLabelConstants.referenceNumber,
    this.paymentLabelConstants.comments,
    this.paymentLabelConstants.reversalDate,
    this.paymentLabelConstants.clearDate,
    ''
  ];

  constructor(public billingData: PolicyBillingData,
    protected modalService: BsModalService,
    protected billingService: BillingService,
    protected entityRiskData: EntityRiskData,
    protected authService: AuthService,
    protected pagerService: PagerService,
    protected policyBillingData: PolicyBillingData,
    protected autoReinstatementData: AutoReinstatementData,
    protected thirdPartyDataService: ThirdPartyDataService,
    protected policyService: PolicyService
  ) {
    super();
  }

  ngOnInit() {
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.isInternal = this.isInternalUser(userType);
    });
  }

  viewPaymentDetails(paymentId: string, paymentType: string, paymentMethod: string): void {
    const riskId = this.entityRiskData.getRiskId();

    Utils.blockUI();
    this.billingService.getPaymentDetails(riskId, paymentId).pipe(takeUntil(this.stop$)).subscribe(res => {
      Utils.unblockUI();
      const paymentDetails: PaymentDetailsViewDTO = this.getUpdatedPayerDetails(res);

      let modalTitle: string = PolicyBillingLabelsConstants.paymentDetails.paymentModalTitle;
      if (paymentDetails.paymentMethod === PolicyBillingConstants.paymentMethod.adjustment) {
        modalTitle = PolicyBillingLabelsConstants.paymentDetails.adjustmentModalTitle;
      } else if (paymentDetails.paymentMethod === PolicyBillingConstants.paymentMethod.writeOff) {
        modalTitle = PolicyBillingLabelsConstants.paymentDetails.writeOffModalTitle;
      }

      let isRefund: boolean = false;
      if (paymentType.toLowerCase() === PaymentType.Refund.toLowerCase()) {
        modalTitle = 'Refund Details';
        isRefund = true;
      }

      const initialState = {
        title: modalTitle,
        paymentData: paymentDetails,
        isPostPayment: false,
        isRefund: isRefund,
        isFromSuspended: res.suspendedPaymentDetail
      };

      this.paymentDetailsModalRef = this.modalService.show(PaymentDetailsModalComponent, {
        initialState,
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'modal-lg',
      });
    },
      err => {
        NotifUtils.showError(err.error);
      }
    );
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }

  onAdjustment(): void {
    this.policyBillingData.ctr = 0;
    this.paymentDetailsModalRef = this.modalService.show(AdjustmentModalComponent, {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-md',
    });
  }

  onWriteOff(): void {
    this.policyBillingData.ctr = 0;
    this.paymentDetailsModalRef = this.modalService.show(WriteOffModalComponent, {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-md',
    });
  }

  onRefund(): void {
    this.policyBillingData.ctr = 0;
    const initialState = {
      isPostPayment: true,
      isRefund: true
    };

    this.paymentDetailsModalRef = this.modalService.show(PostPaymentModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-md',
    });
  }

  onRefundRequest(): void {
    Utils.blockUI();

    this.billingService.getAllowedPaymentRange(this.entityRiskData.getRiskId()).pipe(takeUntil(this.stop$)).subscribe(res => {
      Utils.unblockUI();
      this.autoReinstatementData.allowedPaymentRangeFromPostPayment = res;

      this.billingData.ctr = 0;
      const initialState = {
        isPostPayment: true,
        isRefund: true,
        isRefundRequest: true
      };
  
      this.paymentDetailsModalRef = this.modalService.show(PostPaymentModalComponent, {
        initialState,
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'modal-md',
      });
    },
    err => {
      Utils.unblockUI();
      NotifUtils.showMultiLineError(err.error?.message);
    });


  }

  public isInternalUser(userType: string): boolean {
    return userType.toLocaleLowerCase() === 'internal';
  }

  filterPaymentList(event): void {
    if(event.target.checked){
    this.billingData.isPaymentViewFilter = true;
      this.billingData.paymentListSetPage(1);

    } else {
      this.billingData.isPaymentViewFilter = false;
      this.billingData.paymentListSetPage(1);
    }
  }

  getUpdatedPayerDetails(details: PaymentDetailsViewDTO): PaymentDetailsViewDTO {
    const entityDetails = this.entityRiskData.EntityRisk;
    let payerDetails = details.suspendedPaymentDetail?.payer;

    if (details.suspendedPaymentDetail === null) {
      details.suspendedPaymentDetail = {};
      return details;
    }

    if (payerDetails === null) {
      payerDetails = {};
    } else {
      details.suspendedPaymentDetail.policyNumber = entityDetails?.risks[0]?.policyNumber;
      payerDetails.firstName = entityDetails?.firstName;
      payerDetails.lastName = entityDetails?.lastName;
      payerDetails.address1 = entityDetails?.entityAddresses[0]?.address?.streetAddress1;
      payerDetails.address2 = entityDetails?.entityAddresses[0]?.address?.streetAddress2;
      payerDetails.city = entityDetails?.entityAddresses[0]?.address?.city;
      payerDetails.state = entityDetails?.entityAddresses[0]?.address?.state;
      payerDetails.zip = entityDetails?.entityAddresses[0]?.address?.zipCode;
    }

    return details;
  }
}
