import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'disableAuwButton'
})
export class DisableAuwButtonPipe implements PipeTransform {

  transform(data: any): boolean {
    const {
      disableAuw,
      hasInvalidQuote,
      selectSubmissionUWRsIsLoading,
      selectPolicyUWRsIsLoading,
      selectUWApprovalIsLoading
    } = data;

    return disableAuw ||
      hasInvalidQuote ||
      selectSubmissionUWRsIsLoading ||
      selectPolicyUWRsIsLoading ||
      selectUWApprovalIsLoading;
  }
}
