import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFloodExcludedZipCodeDTO } from '../../shared/models/data/dto/flood-excluded-zipcode/flood.excluded.zipcode.dto';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExcludeZipcodeService {

  constructor(
    public http: HttpClient,
    private commonService: CommonService
    ) { }


  getFloodExcludedZipCodeList(): Observable<IFloodExcludedZipCodeDTO[]> {
    return this.http.get(`${environment.ApiUrl}/Lv/flood-coverage-ex-zipcode`)
      .pipe(
        map(result => {
          return result as IFloodExcludedZipCodeDTO[];
        }),
        catchError(this.commonService.handleObservableHttpError)
      );
  }
}
