import { Pipe, PipeTransform } from '@angular/core';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';

@Pipe({
  name: 'laPersonalPropertyReplacementCost'
})
export class LA_PersonalPropertyReplacementCostPipe implements PipeTransform {

  transform(isCoverageCEnabled: boolean, propertyUsage: string, currentCondition: boolean): boolean {
    const ownerOccupiedValues = ['OOS', 'OOP'];
    const isOwnerOccupied = ownerOccupiedValues.includes(propertyUsage);

    return isCoverageCEnabled && isOwnerOccupied && currentCondition;
  }
}
