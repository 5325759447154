import { Component, OnInit } from '@angular/core';
import { NotesLabelConstants } from 'app/shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { GenericLabel } from 'app/shared/constants/generic.labels.constants';
import { TableNameConstants } from 'app/shared/constants/table.name.constants';
import { NavigationService } from 'app/core/services/navigation/navigation.service';
import { PathConstants } from 'app/shared/constants/path.constants';
import { ClickTypes } from 'app/shared/enum/click-type.enum';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { IHideTableItems, ITableTd } from 'app/shared/models/dynamic/table.interface';
import { PolicyNotesData } from '../data/policy-notes.data';
import { PolicyNotesModalComponent } from './policy-notes-modal/policy-notes-modal.component';
import { BaseComponent } from 'app/shared/base-component';
import { takeUntil } from 'rxjs/operators';
import { PolicySummaryData } from '../data/policy-summary.data';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'environments/environment';
import { NotesCategories } from 'app/shared/constants/notes.options.constants';
import { SubmissionPageData } from 'app/modules/submission-management/data/submission-page.data';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { CoveragesData } from 'app/modules/submission-management/data/coverages.data';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { PolicyService } from 'app/core/services/submission/policy.service';
import Utils from 'app/shared/utilities/utils';
import NotifUtils from 'app/shared/utilities/notif-utils';

@Component({
  selector: 'app-policy-notes',
  templateUrl: './policy-notes.component.html',
  styleUrls: ['./policy-notes.component.scss']
})
export class PolicyNotesComponent extends BaseComponent implements OnInit {
  public TableNameConstants = TableNameConstants;
  NotesLabelConstants = NotesLabelConstants;
  ErrorMessageConstant = ErrorMessageConstant;
  GenericLabel = GenericLabel;
  isOpen: boolean = true;
  modalRef: BsModalRef | null;
  agentDashboardContants = AgentDashboardConstants;

  hideItems: IHideTableItems = {
    checkboxButton: false,
    fileIcon: true
  };

  isloading: boolean = false;

  constructor(
    public navigationService: NavigationService,
    protected modalService: BsModalService,
    public policyNotesData: PolicyNotesData,
    public policySummaryData: PolicySummaryData,
    public submissionData: SubmissionPageData,
    public coveragesData: CoveragesData,
    protected entityRiskData: EntityRiskData,
    public policyService: PolicyService
  ) {
    super();
  }

  ngOnInit(): void {
    this.policyNotesData.notesTableRows = [];
    if (this.policyNotesData.notesList.length !== 0){
      this.policyNotesData.initData(this.policyNotesData.notesList);
    } else {
      // Retrieve policy notes in API.
      Utils.blockUI();
      const riskId = this.entityRiskData?.getRiskId();
      if (riskId) {
        this.policyService.getPolicyNotes(riskId)
          .pipe(takeUntil(this.stop$))
          .subscribe(riskBindNotes => {
            this.policyNotesData.populateNotes(riskBindNotes);
            Utils.unblockUI();
          }, error => {
            Utils.unblockUI();
            NotifUtils.showError('Error fetching Notes in database.');
          }
        );
      }
    }
  }

  editTableItem(rowId: any, tableRow: string): void {
    this.policyNotesData[tableRow].forEach((item) => {
      if (item.id === rowId) {
        this.showEditNotes({
          id: item.id,
          category: item.tr[0].value,
          description: item.tr[1].value,
          dateAdded: item.tr[2].value
        });
      } else {
        item.edit = false;
      }
    });
  }

  updateTableItem(row: any): void {
    this.policyNotesData.notesTableRows.forEach((item) => {
      if (item.id === row.id) {
        item.tr[0].value = row.category;
        item.tr[1].value = row.description;
        item.tr[2].value = row.dateAdded;
        item.tr[3].value = row.createdByFullName;

        item.tr[0].display = _.find(NotesCategories, ['code', row.category])?.description || '';
        item.tr[1].display = row.description;
        item.tr[2].display = row.dateAdded?.singleDate.formatted ? row.dateAdded?.singleDate.formatted : row.dateAdded;
        item.tr[3].display = row.createdByFullName;
      }
    });

    this.policyNotesData.notesList.forEach((item) => {
      if (item.id === row.id) {
        item.category = row.category;
        item.description = row.description;
        item.dateAdded = row.dateAdded;
        item.isAdd = row.isAdd;
      }
    });
    this.policyNotesData.saveNotes();
  }

  deleteTableItem(rowId: any, tableRow: string): void {
    this.policyNotesData[tableRow].forEach((item, index) => {
      if (item.id === rowId) {
        this.policyNotesData[tableRow].splice(index, 1);
        const noteListIndex = this.policyNotesData.notesList.findIndex(a => a.id === rowId);
        this.policyNotesData.notesList.splice(noteListIndex, 1);
      }
    });
    this.policyNotesData.saveNotes();
    if (this.policyNotesData.policyPagedItems.length > 1) {
      this.setPage(this.policyNotesData.currentPageNumber);
    } else {
      this.setPage(1);
    }
  }

  showAddNotes(): void {
    const initialState = {
      title: 'Add Notes',
      idForSaving: this.generateId(),
    };
    this.modalRef = this.modalService.show(PolicyNotesModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.content.event.pipe(takeUntil(this.stop$)).subscribe((res) => {
      this.addTableItem(res.data);
    });
  }

  addTableItem(newItem: any): void {
    newItem.createdBy = localStorage.getItem(environment.UserIdentifierKey);
    this.policyNotesData.addTableItem(newItem, newItem.id);
    this.policyNotesData.notesList.push({
      id: newItem.id,
      category: newItem.category,
      description: newItem.description,
      createdDate: newItem.dateAdded,
      riskBindId: this.policyNotesData.currentRiskBindId,
      notesId: newItem.id,
      createdBy: newItem.createdBy,
      riskId: this.policySummaryData.clonePolicyId,
      riskDetailId: this.policySummaryData.riskDetailId,
      createdByFullName: newItem.createdByFullName,
      isAdd: true
    });
    this.policyNotesData.saveNotes();
    this.setPage(1);
  }

  generateId(): string {
    return uuidv4();
  }

  showEditNotes(model): void {
    const data = model;
    const notes = {
      id: data.id,
      description: data.description,
      category: data.category,
      dateAdded: data.dateAdded,
      isEdit: true,
      createdBy: data.createdBy
    };

    const initialState = {
      title: 'Edit Notes',
      notesModel: notes,
      isAdd: false,
    };

    this.modalRef = this.modalService.show(PolicyNotesModalComponent, {
      initialState,
    });

    this.modalRef.content.event.subscribe((res) => {
      this.updateTableItem(res.data);
    });
  }

  onDelete(model): void {
    this.policyNotesData.notesList = this.policyNotesData.notesList.filter((x) => x.id !== model.id);
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }

  public onClick(clickType?): void {
    switch (clickType) {
      case ClickTypes.Back:
        // to do back
        this.navigationService.navigatePolicyRoute(PathConstants.Policy.Policies.Issue);
        break;
      case ClickTypes.Next:
        // to do next
        this.navigationService.navigatePolicyRoute(PathConstants.Policy.Policies.Tasks);
        break;
    }
  }

  get disableFormFields(): boolean {
    if (this.policySummaryData.isPolicy) {
      return !(this.policySummaryData.isEditPolicy || this.isRefferedtoUWStatus);
    }

    return this.submissionData.disableQuoteForms;
  }

  get isRefferedtoUWStatus(): boolean {
    return this.submissionData.policyStatus?.value === this.agentDashboardContants.referredToUW && this.coveragesData.isExternal;
  }

  get isDeclinedUWStatus(): boolean {
    return this.submissionData.policyStatus?.value === this.agentDashboardContants.declineByUW && this.coveragesData.isExternal;
  }

  setPage(page: number): void {
    this.policyNotesData.setPolicyNotesPage(page);
  }
}
