export const AgentLabelConstants = {
  name: 'Name',
  primarySubAgency: 'Primary Sub Agency',
  active: 'Active',

  username: 'Username',
  firstName: 'First Name',
  lastName: 'Last Name',
  systemUser: 'Is System User',
  emailAddress: 'Email Address',
  workphone: 'Work Phone',
  workfax: 'Work Fax',
  resetPassword: 'Reset Password',
  torrentUsername: 'Torrent Username',

  subAgencies: 'Sub Agencies',
  linked: 'Linked',
  nameCity: 'Name (City)',
  officeAddress: 'Office Address',
  primary: 'Primary',

  licenses: 'Licenses',
  state: 'State',
  effectiveDate: 'Effective Date',
  expirationDate: 'Expiration Date',
  viewCommission: 'View Commission',
  viewReport: 'View Report',

  agentModalAddedSuccessfullyMessage: 'Agent Added Successfully.',
  agentModalUpdatedSuccessfullyMessage: 'Agent Updated Successfully.',
  agentDeleteMessage: 'Agent Deleted Successfully.',

  stateCode: 'State Code',
  licenseNumber: 'License Number',
  surplusLines: 'Surplus Lines',
  licenseEffectiveDate: 'License Effective Date',
  licenseExpirationDate: 'License Expiration Date',

  userExistError: 'User already exists',
  subAgencyError: 'Sub Agency is required',
  workFaxFormatError: 'Please enter a valid fax number.',
  subAgencyCode: 'Sub Agency Code',
  agencyCode: 'Agency Code',

  methodName: {
    add: 'saveUserAndAgent',
    edit: 'updateUserAndAgent',
    delete: 'deleteAgent'
  }
};