import { Component, OnInit } from '@angular/core';
import { ErrorMessageConstant } from '../../../../../../shared/constants/error-message.constants';
import { SuspendedPaymentsData } from '../../../../../../modules/payments/data/suspended-payments.data';
import { GenericLabel } from '../../../../../../shared/constants/generic.labels.constants';
import { SuspendedPaymentsLabel } from '../../../../../../shared/constants/suspended-payments.labels.constants';
import { BsModalRef } from 'ngx-bootstrap';
import FormUtils from '../../../../../../shared/utilities/form.utils';
import { Subject } from 'rxjs';
import Utils from '../../../../../../shared/utilities/utils';
import { ZipCodeData } from '../../../../../../modules/submission-management/data/zipcode.data';
import { PageSections } from '../../../../../../shared/enum/page-sections.enum';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { take } from 'rxjs/operators';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { AuthService } from 'app/core/services/auth.service';
@Component({
  selector: 'app-add-suspended-payment-modal',
  templateUrl: './add-suspended-payment-modal.component.html',
  styleUrls: ['./add-suspended-payment-modal.component.scss']
})
export class AddSuspendedPaymentModalComponent implements OnInit {
  public SuspendedPaymentsLabel = SuspendedPaymentsLabel;
  public GenericLabel = GenericLabel;
  public ErrorMessageConstant = ErrorMessageConstant;
  public CurrencyMaskInputMode = CurrencyMaskInputMode;
  public FormUtils = FormUtils;
  public onClose: Subject<boolean>;
  datePickerDateOption: IAngularMyDpOptions;

  constructor(public bsModalRef: BsModalRef, public suspendedPaymentsData: SuspendedPaymentsData, public zipCodeData: ZipCodeData, private authService: AuthService) { }

  public directBillCarriers: any[];

  ngOnInit() {
    this.onClose = new Subject();
    this.datePickerDateOption = this.setDatePickerOption();

    this.zipCodeData.addSupendedPaymentCityList = [];

    this.directBillCarriers = this.suspendedPaymentsData.directBillCarriers;
  }

  hideModal(): void {
    this.suspendedPaymentsData.resetFormGroup();
    this.onClose.next(false);
    this.bsModalRef.hide();
    this.suspendedPaymentsData.resetDocumentVariables();
  }

  onSave(): void {
    this.suspendedPaymentsData.saveSuspendedPayments();
    this.suspendedPaymentsData.isPostSuspendedPaymentCompleted.subscribe((isSuccess: boolean) => {
      if (isSuccess) {
        if (this.suspendedPaymentsData.documentRequest.length) {
          this.suspendedPaymentsData.isDocumentUploadSuccess.pipe(take(2)).subscribe(value => {
            if (value) {
              this.onSavingSuccess();
            }
          });
        } else {
          this.onSavingSuccess();
        }
      }
    });
  }

  onDelete(): void {
  }

  onView(): void {
  }


  setDatePickerOption(): IAngularMyDpOptions {
    const date = this.authService.getCustomDate();
    const options = this.suspendedPaymentsData.dateOption;
    options.disableSince = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() + 1 };

    return options;
  }

  onSavingSuccess(): void {
    this.suspendedPaymentsData.resetFormGroup();
    this.suspendedPaymentsData.resetDocumentVariables();
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  validateKeyPress(event) {
    Utils.validateKeyPressAndAcceptAlphanumericOnly(event);
  }
}
