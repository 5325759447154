import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PendingBatchPaymentsComponent } from './pending-batch-payments.component';
import { RouterModule, Routes } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap';
import { PendingBatchPaymentsListComponent } from './pending-batch-payments-list/pending-batch-payments-list.component';
import { SharedModule } from '../../../../shared/shared.module';
import { CustomPipesModule } from '../../../../shared/custom pipes/custom-pipe.module';

const routes: Routes = [
  { path: '', component: PendingBatchPaymentsComponent }
];

@NgModule({
  declarations: [
    PendingBatchPaymentsComponent,
    PendingBatchPaymentsListComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    CustomPipesModule,
    TooltipModule.forRoot()
  ],
  entryComponents: [

  ]
})
export class PendingBatchPaymentsModule { }
