import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Submission } from '../../../shared/models/submission/submission';
import { Observable, of } from 'rxjs';
import { ISubmissionCategoryData } from '../../../shared/models/submission/ISubmissionCategoryData';
import { ISubmissionCategoryService } from '../../../shared/models/submission/ISubmissionCategoryService';
import { environment } from '../../../../environments/environment';
import { PathConstants } from '../../../shared/constants/path.constants';
import { map, catchError, shareReplay } from 'rxjs/operators';
import { CommonService } from '../common.service';
import { FilterSubmissionListDTO } from 'app/shared/models/data/dto/submission-list/filter-submission-list.dto';
import { EntitySubmissionListDTO, SubmissionPaginationResultDTO } from 'app/shared/models/data/dto/submission-list/entity-submission-list.dto';
import { EntityRiskDTO } from 'app/shared/models/data/dto/quick-quote/entity-risk.dto';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { RiskBindDocumentDTO } from 'app/shared/models/data/dto/quick-quote/risk-bind-document.dto';
import { RiskBindDTO } from 'app/shared/models/data/dto/quick-quote/risk-bind-dto';


@Injectable({
  providedIn: 'root'
})
export class SubmissionService {
  submission: Submission;

  constructor(public http: HttpClient,
    protected commonService: CommonService,
    protected entityRiskData: EntityRiskData) { }

  getSubmissionId(): number {
    return this.submission.id;
  }

  get(id: number): Observable<Submission> {
    return this.http.get(`${environment.ApiUrl}/submissions/${id}`)
      .pipe(
        map(data => {
          this.submission = data as Submission;
          return data as Submission;
        }),
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getData(riskId: string, riskDetailId: string): Observable<EntityRiskDTO> {
    const url = `${environment.ApiUrl}/entity`;
    const paramData = new HttpParams()
      .append('riskDetailId', riskDetailId)
      .append('riskId', riskId);

    return this.http.get(url, { params: paramData})
      .pipe(
        map(data => {
          return data as EntityRiskDTO;
        }),
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  put(submission: Submission): Observable<any> {
    return this.http.put(`${environment.ApiUrl}/submissions`, submission);
  }

  post(submission: Submission) {
    return this.http.post(`${environment.ApiUrl}/submissions`, submission).pipe(
      map(data => {
        return data;
      })
    );
  }

  public saveCurrentCategory(
    categoryService: ISubmissionCategoryService,
    categoryData: ISubmissionCategoryData,
    currentCategory: string) {

    let categoryProperty = currentCategory;
    switch (categoryProperty) {
      case PathConstants.Submission.Submission.Index:
        categoryProperty = 'quickquote';
        break;
      default:
        break;
    }

    this.submission[categoryProperty] = categoryData.getFormValue();
    this.submission[categoryProperty]['id'] = this.getSubmissionId();

    categoryService.put(this.submission).subscribe(data => {
      // show sweetalert message after save
    });

  }

  getSubmissionListAsync(filterSubmissionList: FilterSubmissionListDTO): Observable<SubmissionPaginationResultDTO> {
    return this.http.post(`${environment.ApiUrl}/Submission/submissions`, filterSubmissionList).pipe(
      map(data => data as SubmissionPaginationResultDTO)
    ).catch(this.commonService.handleObservableHttpError);
  }

  getRelatedSubmissionListAsync(riskId: string): Observable<EntitySubmissionListDTO[]> {
    return this.http.get(`${environment.ApiUrl}/Risk/related/${riskId}`).pipe(
      map(data => data as EntitySubmissionListDTO[])
    ).catch(this.commonService.handleObservableHttpError);
  }

  getSubmissionDocuments(riskId: string, riskDetailId: string): Observable<RiskBindDocumentDTO[]> {
    return this.http.get(`${environment.ApiUrl}/Risk/document?riskId=${riskId}&riskDetailId=${riskDetailId}`)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getAllSubmissionDocuments(riskId: string): Observable<RiskBindDocumentDTO[]> {
    return this.http.get(`${environment.ApiUrl}/Risk/document/all?riskId=${riskId}`)
      .pipe(
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getRiskBindNotes(riskId: string, riskDetailId: string): Observable<RiskBindDTO> {
    return this.http.get(`${environment.ApiUrl}/Risk/bindnotes?riskId=${riskId}&riskDetailId=${riskDetailId}`)
    .pipe(
      map(data => {
        return data as RiskBindDTO;
      }),
      catchError(this.commonService.handleObservableHttpError)
    );
  }

  getExpiringData(riskId: string): Observable<EntityRiskDTO> {
    if (!Boolean(riskId)) {
      return of(null);
    }

    const url = `${environment.ApiUrl}/entity/expiringEntity`;
    const paramData = new HttpParams()
      .append('riskId', riskId);

    return this.http.get(url, { params: paramData})
      .pipe(
        map(data => {
          return data as EntityRiskDTO;
        }),
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getSubmissionListBySubagencyAsync(riskIds: string[]): Observable<string[]> {
    return this.http.post(`${environment.ApiUrl}/Submission/verify-sub-agency-submissions`, riskIds).pipe(
      map(data => data)
    ).catch(this.commonService.handleObservableHttpError);
  }
}
