export const MajorBodyOfWater = [
    'INLET GULF OF MEXICO', 'ANCLOTE RIVER', 'BAY ATLANTIC OCEAN', 'EAST COVE', 'APALACHEE BAY',
    'APALACHICOLA BAY (ICW)', 'BLIND PASS', 'STANAHAN RIVER', 'BARFIELD BAY', 'BARNES SOUND',
    'BIG BAYOU', 'PITHLACHASCOTEE RIVER', 'BIG CARLOS PASS', 'BIG LAGOON', 'BIG MARCO PASS',
    'BIMINI BAY', 'BISHOP BAYOU', 'ANNE MARIA SOUND', 'BISHOP HARBOR', 'BLACKBURN BAY',
    'FORT GEORGE INLET', 'BLUE HILL BAY', 'NEW RIVER', 'INLET BISCAYNE BAY', 'BOCA CIEGA BAY',
    'LAKE WORTH (ICW)', 'BOCA GRANDE BAYOU', 'BOCA GRANDE PASS', 'BOCILLA INLET', 'BONEFISH BAY',
    'BOOT KEY HARBOR', 'CHANNEL ATLANTIC OCEAN', 'BUTTONWOOD BAY', 'CAPTIVA PASS', 'CAXAMBAS BAY',
    'CEDAR COVE', 'CHARLOTTE HARBOR', 'CHASSAHOWITZKA BAY', 'CHATWICK BAYOU', 'TARPON BAY',
    'CHOCTAWHATCHEE BAY', 'CHOKOLOSKEE BAY', 'CLAM BAY', 'CLAM BAYOU', 'CLAMBAR BAY',
    'CLEARWATER HARBOR', 'COLLIER BAY', 'CORAL CREEK', 'BANANA RIVER', 'COW KEY CHANNEL',
    'CRITICAL BAYOU', 'CRYSTAL BAY', 'CUSTER BAYOU', 'EAST BAY', 'ESCAMBIA BAY',
    'ESTERO BAY', 'INLET ATLANTIC OCEAN', 'ESTERO PASS', 'BAY GULF OF MEXICO', 'FAKA UNION BAY',
    'FAKAHATCHEE BAY', 'FLORIDA BAY', 'FORTY ACRE BAY', 'GASPARILLA SOUND', 'GOODLAND BAY',
    'GRAND CANAL', 'GRAND LAGOON', 'GRASSY KEY HARBOR', 'GREENS BAYOU', 'GULLIVAN BAY',
    'HARRIS BAYOU', 'HILLSBOROUGH BAY', 'HOMOSASSA BAY', 'HORSESHOE COVE', 'HURRICANE BAY',
    'INLET ESTERO BAY', 'INTRACOASTAL WATERWAY', 'JOE BAY', 'JOES BAYOU', 'JOHNSON BAY',
    'KETTLE HARBOR', 'KEY WEST NAVAL AIR STATION LAK', 'KINZIE COVE', 'KLOSTERMAN BAYOU', 'LAMAR LAKE',
    'LARGO HARBOR', 'LEMON BAY (ICW)', 'LITTLE COCKROACH BAY', 'LITTLE SARASOTA BAY', 'LOWER SUGARLOAF SOUND',
    'LOWER THIRTYSIX BAY', 'MALTLACHA PASS', 'NASSAU RIVER (ICW)', 'MCPHERSON BAYOU', 'MIGUEL BAY',
    'MILLAR BAY', 'MOSQUITO LAGOON', 'NAPLES BAY', 'NASSAU SOUND', 'NEW PASS',
    'OCHLOCKONEE BAY', 'INDIAN RIVER', 'OLD TAMPA BAY', 'OSTEGO BAY', 'PARK CHANNEL',
    'BISCAYNE BAY', 'PELICAN BAY', 'PENSACOLA BAY', 'PERDIDO BAY', 'PERICO BAYOU',
    'PINE ISLAND SOUND', 'PONCE DE LEON CUT', 'PONCE DE LEON INLET', 'RATTLESNAKE COVE', 'REDFISH COVE',
    'REDFISH PASS', 'ROBERTS BAY', 'ROCK HARBOR', 'ROCKLAND CHANNEL', 'ROOKERY BAY',
    'ROOSEVELT CHANNEL', 'SADDLEBUNCH NO 2 CHANNEL', 'SADDLEBUNCH NO 3 CHANNEL', 'SAFETY HARBOR', 'SAN CARLOS BAY',
    'SANTA ROSA SOUND', 'SANTA ROSA SOUND (ICW)', 'SANTINA BAY', 'SARASOTA BAY', 'SHELTER BAY', 'SHIRK BAYOU',
    'SIMILAR SOUND', 'SMITH BAYOU', 'SMOKEHOUSE BAY', 'SNUG HARBOR', 'ST GEORGE SOUND',
    'HALIFAX RIVER', 'ST JOSEPH BAY', 'FORT GEORGE RIVER', 'ST JOSEPH SOUND', 'SURPRISE LAKE',
    'SURPRISE WATERWAY', 'TAMPA BAY', 'TAVERNIER CREEK', 'TAVERNIER HARBOR', 'TERRA CEIA BAY',
    'ST MARKS RIVER', 'TILETTE BAYOU', 'TRIDENT BASIN', 'TURTLE BAY', 'UPPER SUGARLOAF SOUND',
    'UPPER THIRTYSIX BAY', 'WACCASASSA BAY', 'WEST BAY (ICW)', 'WIDGEON BAY', 'WULFERT CHANNEL'
];
