import { Component, OnInit } from '@angular/core';
import { AgencyService } from 'app/core/services/management/agency-service';
import { BaseClass } from 'app/shared/base-class';
import { LayoutService } from 'app/core/services/layout/layout.service';
import { CommissionStatementData } from './data/commission-statement.data';
import { createReportsMenuItems, createReportsMenuItemsForAgents, createReportsMenuItemsForCommissionAuthorithy } from './reports-navitems';
import { TypeaheadSettings } from 'ngx-type-ahead';
import { AuthService } from 'app/core/services/auth.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent extends BaseClass implements OnInit {


  customSettings: Partial<TypeaheadSettings> = {
    noMatchesText: 'No results found',
    dropdownToggleClass: 'button-down',
    suggestionsLimit: 0
  };

  constructor(
      private layoutService: LayoutService,
      private authService: AuthService) {
    super();
  }

  ngOnInit() {
    this.initializations();
  }

  initializations(){
    if (this.authService?.getUserViewCommissionAuthority) {
      this.layoutService.updateMenu(createReportsMenuItemsForCommissionAuthorithy());
    } else if (this.authService.isExternalUser) {
      this.layoutService.updateMenu(createReportsMenuItemsForAgents());
    } else if (this.authService.isInternalUser) {
      this.layoutService.updateMenu(createReportsMenuItems());
    }
  }
}
