import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'disableAirButton'
})
export class DisableAirButtonPipe implements PipeTransform {

  transform(data: any): boolean {
    const {
      disableAir,
      hasInvalidQuote,
      selectSubmissionUWRsIsLoading,
      selectPolicyUWRsIsLoading,
      selectUWApprovalIsLoading
    } = data;

    return disableAir ||
      hasInvalidQuote ||
      selectSubmissionUWRsIsLoading ||
      selectPolicyUWRsIsLoading ||
      selectUWApprovalIsLoading;
  }
}
