import { Action, createReducer, on } from '@ngrx/store';
import * as fromApplicantsActions from '../../store/applicants/applicants.actions';
import { initialState, ApplicantsState } from './applicants.state';

const _applicantsReducer = createReducer(
  initialState,
  on(
    fromApplicantsActions.updateApplicantsIsLoadingFromApplicantsSavingData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  )
);

export function applicantsReducer(
  state: ApplicantsState | null,
  action: Action
) {
  return _applicantsReducer(state, action);
}