export const PolicyBillingConstants = {
  paymentMethod: {
    adjustment: 'Adjustment',
    writeOff: 'WriteOff',
  },
  suspendedPayment: {
    statusId: {
      post: 'POST'
    }
  },
  creditCardFee: 0.03,
  creditCardFeeState: (riskStateCode: string) => {
    switch(riskStateCode) {
      case 'FL':
        return 'Centauri Specialty Insurance Company';
      case 'LA':
        return 'Centauri National Insurance Company';
      default:
        return 'Centauri Specialty Insurance Company';
    }
  },
  CSIC: 'CSIC',
  CNIC: 'CNIC'
};