import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { FormGroup, Validators } from '@angular/forms';
import { AutoReinstatementData } from '../../../modules/policy-management/data/auto-reinstatement.data';
import { PolicySummaryData } from '../../../modules/policy-management/data/policy-summary.data';
import { AutoReinstatementConstants } from '../../constants/auto-reinstatement.constants';
import { NavigationStart, Router } from '@angular/router';import { BaseClass } from 'app/shared/base-class';

@Component({
  selector: 'app-auto-reinstatement-approval',
  templateUrl: './auto-reinstatement-approval.component.html',
  styleUrls: ['./auto-reinstatement-approval.component.scss']
})
export class AutoReinstatementApprovalComponent extends BaseClass implements OnInit, OnDestroy {
  public AutoReinstate = AutoReinstatementConstants.reinstatementApprovalSection;
  public Error = AutoReinstatementConstants.errorMessage;
  customApprovalExpirationDateErrorMessage: string = AutoReinstatementConstants.errorMessage.genericRequiredErrorMessage;
  isOpen: boolean = false;
  routerEventSubscription: any;

  @Input() isInternal: boolean;

  constructor(
    public autoReinstatementData: AutoReinstatementData,
    public policySummaryData: PolicySummaryData,
    protected router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToFormChanges();
    this.triggerSaving();
    this.autoReinstatementData.controlsWithChanges = [];
  }

  ngOnDestroy(): void {
    this.routerEventSubscription.unsubscribe();
    this.autoReinstatementData.isSubmissionPolicies = false;
  }

  get isPaymentGreaterThanRequired(): boolean {
    return this.form.get('paymentRequired').value > this.autoReinstatementData.reinstatementView.payoffAmount && this.autoReinstatementData.reinstatementView.payoffAmount >= 0;
  }

  get form(): FormGroup {
    return this.autoReinstatementData?.autoReinstatementForm;
  }

  /**
   * Add formcontrol(s) in this method that you desired to monitor its value changes
   */
  protected subscribeToFormChanges(): void {
    this.form.get('isApprovedReinstatement').valueChanges
      .debounceTime(300)
      .distinctUntilChanged()
      .subscribe((value: boolean) => {

        this.setFormValidation(value);
        this.resetValuesOnReinstatementRevert(value);
        this.otherLogicsOnApprove(value);
        this.setFlaggingForChanges('isApprovedReinstatement');
      });

    if (this.autoReinstatementData.isCancelledPolicyWithoutRenewal() || this.autoReinstatementData.isRWNT()) {
      this.form.get('approvalExpirationDate').valueChanges
        .debounceTime(300)
        .distinctUntilChanged()
        .takeUntil(this.stop$)
        .subscribe((value) => {
          this.recalculateAmountToReinstate(value);
        });
    }
  }

  protected setFormValidation(isApproved: boolean): void {
    if (isApproved) {
      for (const key in this.form.controls) {
        if (key !== 'isApprovedReinstatement') {
          this.form.get(key).setValidators([Validators.required]);
          this.form.get(key).updateValueAndValidity();
        }

        if (key === 'paymentRequired') {
          this.form.get(key).setValidators([Validators.required, Validators.max(this.autoReinstatementData.reinstatementView.maximumPaymentAmount)]);
          this.form.get(key).updateValueAndValidity();
        }
      }
    } else {
      for (const key in this.form.controls) {
        if (key !== 'isApprovedReinstatement') {
          this.form.get(key).clearValidators();
          this.form.get(key).updateValueAndValidity();
        }
      }
    }
  }

  protected resetValuesOnReinstatementRevert(isApproved: boolean): void {
    if (isApproved) {
      this.form.get('paymentRequired').setValue(this.autoReinstatementData.amountBilled);
      return;
    }
    this.form.get('approvalExpirationDate').setValue(null);
    this.form.get('paymentRequired').setValue(0);

    this.autoReinstatementData.isValidExpirationDate = true;
  }

  protected otherLogicsOnApprove(isApproved: boolean): void {
    const reinstatementDetails = this.autoReinstatementData.reinstatementView?.approvalDetails;
    if (isApproved && Boolean(reinstatementDetails)) {
      if (this.policySummaryData.isPolicy) {
        if (reinstatementDetails.approvedById === null && reinstatementDetails.rescindedById === null) {
          this.autoReinstatementData.approverName = this.autoReinstatementData.fromNotesUserDetails.fullName;
        }

        if (Boolean(reinstatementDetails.reinstatementApprovalStatusId) && reinstatementDetails.reinstatementApprovalStatusId === AutoReinstatementConstants.approvalStatus.Expired) {
          this.autoReinstatementData.approverName = this.autoReinstatementData.fromNotesUserDetails.fullName;
          this.autoReinstatementData.showOtherApprovalDetails$.next(true);
        }
      } else {
        if (reinstatementDetails.approvedById === null && reinstatementDetails.rescindedById === null) {
          this.autoReinstatementData.approverName = this.autoReinstatementData.fromNotesUserDetails.fullName;
        }
        this.autoReinstatementData.showOtherApprovalDetails$.next(true);
      }
    }
  }

  setFlaggingForChanges(formControl: string): void {
    if (this.autoReinstatementData.controlsWithChanges.includes(formControl)) {
      return;
    }
    this.autoReinstatementData.controlsWithChanges.push(formControl);
  }

  checkExpirationDateValidityByInputField(data: Object): void {
    setTimeout(() => {
      const currentDate = moment(this.autoReinstatementData.authService.getCustomDate()).format('L');
      if (data.hasOwnProperty('value') && Boolean(data['value'])) {
        const isValidDate = moment(data['value'], 'mm/dd/yyyy');
        if (isValidDate.isValid()) {
          this.autoReinstatementData.isValidExpirationDate = moment(moment(data['value']).format('L')).isSameOrAfter(currentDate);
          this.customApprovalExpirationDateErrorMessage = AutoReinstatementConstants.errorMessage.invalidApprovalExpirationDate;
        } else {
          this.customApprovalExpirationDateErrorMessage = AutoReinstatementConstants.errorMessage.genericRequiredErrorMessage;
          this.autoReinstatementData.isValidExpirationDate = false;
        }
      } else {
        this.customApprovalExpirationDateErrorMessage = AutoReinstatementConstants.errorMessage.genericRequiredErrorMessage;
        this.autoReinstatementData.isValidExpirationDate = false;
      }
    }, 50);
  }

  protected triggerSaving() {
    this.routerEventSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.autoReinstatementData.isSubmissionPolicies = [AutoReinstatementConstants.submissionSubstringURL, AutoReinstatementConstants.policySubstringURL].some(substringURL => event.url.includes(substringURL));

        if (this.autoReinstatementData.isSubmissionPolicies && this.autoReinstatementData.controlsWithChanges.length > 0) {
          if (this.policySummaryData.isPolicy) {
            this.autoReinstatementData.postAutomatedReinstatementApproval();
          } else {
            this.autoReinstatementData.postRenewalAutomatedReinstatementApproval();
          }
        }
      }
    });
  }

  recalculateAmountToReinstate(data: any): void {
    let approvalExpirationDate = data?.singleDate?.formatted;
    const currentDate = moment(this.autoReinstatementData.authService.getCustomDate()).format('L');
    const isValidDate = moment(approvalExpirationDate, 'mm/dd/yyyy');
    const isDateValid = moment(moment(approvalExpirationDate)).isSameOrAfter(currentDate);
    if (isValidDate.isValid() && isDateValid) {
      approvalExpirationDate = moment.utc(this.autoReinstatementData.autoReinstatementForm.get('approvalExpirationDate').value?.singleDate?.jsDate.toISOString()).tz('America/New_York');
      this.autoReinstatementData.recalculateAmountToReinstate(approvalExpirationDate, this.autoReinstatementData.isRWNT());
    }
  }
}