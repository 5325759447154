import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppAsideModule } from '@coreui/angular';
import { PathConstants } from '../../shared/constants/path.constants';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PolicyListComponent } from './policy-list/policy-list.component';
import { PolicyManagementComponent } from './policy-management.component';
import { RaterPremiumResolverService } from '../../core/services/submission/rater-premium/rater-premium-resolver.service';

// const policyDetailsChildRoutes = [
//   {
//     path: PathConstants.Policy.Policies.Index,
//     loadChildren: () => import('./policy-management.module').then(m => m.PolicyManagementModule)
//   },
//   { path: '', redirectTo: PathConstants.Policy.List, pathMatch: 'full' },
// ];

const routes: Routes = [
  {
    path: PathConstants.Policy.List,
    component: PolicyListComponent,
  },
  {
    path: ':uuid/:uuid2',
    component: PolicyManagementComponent,
    children: [
      {
        path: PathConstants.Policy.Policies.Summary,
        loadChildren: () => import('./policy-summary/policy-summary.module').then(m => m.PolicySummaryModule)
      },
      {
        path: PathConstants.Policy.Policies.History,
        loadChildren: () => import('./policy-history/policy-history.module').then(m => m.PolicyHistoryModule)
      },
      {
        path: PathConstants.Policy.Policies.Billing,
        loadChildren: () => import('./policy-billing/policy-billing.module').then(m => m.PolicyBillingModule)
      },
      {
        path: PathConstants.Policy.Policies.Documents,
        loadChildren: () => import('./policy-documents/policy-documents.module').then(m => m.PolicyDocumentsModule)
      },
      {
        path: PathConstants.Policy.Policies.CoveragesAndDeduction,
        loadChildren: () => import('../submission-management/submission/coverages/coverages.module').then(m => m.CoveragesModule)
      },
      {
        path: PathConstants.Policy.Policies.Endorsements,
        loadChildren: () => import('../submission-management/submission/endorsements/endorsements.module').then(m => m.EndorsementsModule)
      },
      {
        path: PathConstants.Policy.Policies.Claims,
        loadChildren: () => import('../submission-management/submission/claims/claims.module').then(m => m.ClaimsModule)
      },
      {
        path: PathConstants.Policy.Policies.Property,
        loadChildren: () => import('../submission-management/submission/property/property.module').then(m => m.PropertyModule)
      },
      {
        path: PathConstants.Policy.Policies.Applicant,
        loadChildren: () => import('../submission-management/applicant/applicant.module').then(m => m.ApplicantModule)
      },
      {
        path: PathConstants.Policy.Policies.UWQuestions,
        loadChildren: () => import('../submission-management/submission/uw-questions/uw-questions.module').then(m => m.UwQuestionsModule)
      },
      {
        path: PathConstants.Policy.Policies.Interests,
        loadChildren: () => import('../submission-management/submission/interests/interests.module').then(m => m.InterestsModule)
      },
      {
        path: PathConstants.Policy.Policies.UWApproval,
        loadChildren: () => import('../submission-management/submission/uw-approval/uw-approval.module').then(m => m.UwApprovalModule)
      },
      {
        path: PathConstants.Policy.Policies.Issue,
        loadChildren: () => import('./policy-issue/policy-issue.module').then(m => m.PolicyIssueModule)
      },
      {
        path: PathConstants.Policy.Policies.Notes,
        loadChildren: () => import('./policy-notes/policy-notes.module').then(m => m.PolicyNotesModule)
      },
      {
        path: PathConstants.Policy.Policies.Tasks,
        loadChildren: () => import('./policy-tasks/policy-tasks.module').then(m => m.PolicyTasksModule)
      },
      {
        path: PathConstants.Policy.Policies.RaterPremiumTable,
        loadChildren: () => import('../submission-management/submission/rater-premium-table/rater-premium-table.module').then(m => m.RaterPremiumTableModule)
      },
      {
        path: '**',
        redirectTo: '/404',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: '',
    redirectTo: PathConstants.Policy.List,
    pathMatch: 'full'
  },
  // { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes), AppAsideModule, ModalModule.forRoot()],
  exports: [RouterModule],
  providers: []
})
export class PolicyManagementRoutingModule { }
