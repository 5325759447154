import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentsComponent } from './payments.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AppBreadcrumbModule, AppSidebarModule } from '@coreui/angular';
import { TabsModule } from 'ngx-bootstrap';
import { SharedModule } from 'app/shared/shared.module';
import { PaymentsRoutingModule } from './payments-routing.module';
import { SuspendedPaymentsModule } from './suspended-payments/suspended-payments.module';
import { DetailsSuspendedPaymentModalComponent } from './suspended-payments/pending/pending-suspended-modal/details-suspended-payment-modal/details-suspended-payment-modal.component';
import { RefundModule } from './refund/refund.module';
import { RefundRoutingModule } from './refund/refund-routing.module';
import { BatchPaymentsModule } from './batch-payments/batch-payments.module';



@NgModule({
  declarations: [PaymentsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    AppSidebarModule,
    AppBreadcrumbModule.forRoot(),
    TabsModule.forRoot(),
    SharedModule,
    PaymentsRoutingModule,
    SuspendedPaymentsModule,
    RefundModule,
    RefundRoutingModule,
    BatchPaymentsModule
  ],
  entryComponents: [
    DetailsSuspendedPaymentModalComponent
  ]
})
export class PaymentsModule { }
