import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CoveragesData } from 'app/modules/submission-management/data/coverages.data';
import { DP3RaterRequestData } from './dp3rater-request-data.service';
import { HORaterRequestData } from './ho-rater-request-data.service';
import { BaseClass } from 'app/shared/base-class';
import { ClaimsData } from 'app/modules/submission-management/data/claims.data';
import { EndorsementsData } from 'app/modules/submission-management/data/endorsements.data';
import { PropertyData } from 'app/modules/submission-management/data/property.data';
import { PolicyBillingData } from 'app/modules/policy-management/data/policy-billing.data';
import { TransactionFeeDTO } from 'app/shared/models/data/dto/billing/transaction-fee.dto';
import { EntityRiskDTO } from 'app/shared/models/data/dto/quick-quote/entity-risk.dto';
import { RaterFormTypeConstants } from 'app/shared/constants/rater-form-types.constants';
import { Subject } from 'rxjs-compat';
import { takeUntil } from 'rxjs/operators';
import { RaterPremiumDetails } from 'app/shared/models/rater-premium/rater-premium-details';
import { RaterCreditClaimTierDetails } from 'app/shared/models/rater-premium/rater-credit-claim-tier-details';
import { IProcessedRaterView } from 'app/shared/models/rater-premium/processed-rater-view.model';
import { DPRater } from 'app/shared/constants/dp3-rater.constant';
import { PropertyBusinessRules } from 'app/modules/submission-management/business-rules/property.business-rules';

@Injectable({
  providedIn: 'root'
})
export class CalculateRaterData extends BaseClass {
  formType: string = 'HO';
  raterFormType = RaterFormTypeConstants;
  isCalcPremium: Subject<boolean> = new Subject<boolean>();
  recalculatePremiumAndSave$: Subject<boolean> = new Subject<boolean>();

  recalculatePremiumSubmissionRewrite$: Subject<boolean> = new Subject<boolean>();

  populateRequestPayload$: Subject<boolean> = new Subject<boolean>();

  proratedRaterResults: Subject<any> = new Subject<any>();
  raterPremiumDetails: Subject<RaterPremiumDetails> = new Subject<RaterPremiumDetails>();
  creditClaimTiers: Subject<RaterCreditClaimTierDetails> = new Subject<RaterCreditClaimTierDetails>();
  fireProtection: number;
  isViewQuoteProposalClicked: boolean = false;
  isRaterCalculated: boolean = false;
  endorsementEffectiveDate: Date;

  endorsementRequest$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public hoRaterData: HORaterRequestData,
    protected dp3RaterData: DP3RaterRequestData) {
    super();
    this.initialize();
  }

  initialize() {
    this.hoRaterData.isCalcPremium.pipe(takeUntil(this.stop$)).subscribe(res => this.isCalcPremium.next(res));
    this.dp3RaterData.isCalcPremium.pipe(takeUntil(this.stop$)).subscribe(res => this.isCalcPremium.next(res));
    this.hoRaterData.isRaterCalculated.pipe(takeUntil(this.stop$)).subscribe(res => { this.isRaterCalculated = res; });
    this.dp3RaterData.isRaterCalculated.pipe(takeUntil(this.stop$)).subscribe(res => { this.isRaterCalculated = res; });
    this.hoRaterData.raterPremiumDetails.pipe(takeUntil(this.stop$)).subscribe(res => { this.raterPremiumDetails.next(res); });
    this.dp3RaterData.raterPremiumDetails.pipe(takeUntil(this.stop$)).subscribe(res => { this.raterPremiumDetails.next(res); });
    this.hoRaterData.proratedRaterResults.pipe(takeUntil(this.stop$)).subscribe(res => { this.proratedRaterResults.next(res); });
    this.dp3RaterData.proratedRaterResults.pipe(takeUntil(this.stop$)).subscribe(res => { this.proratedRaterResults.next(res); });
    this.hoRaterData.endorsementEffectiveDate.pipe(takeUntil(this.stop$)).subscribe(res => { this.endorsementEffectiveDate = res; });
    this.dp3RaterData.endorsementEffectiveDate.pipe(takeUntil(this.stop$)).subscribe(res => { this.endorsementEffectiveDate = res; });
    this.hoRaterData.creditClaimTiers.pipe(takeUntil(this.stop$)).subscribe(res => { this.creditClaimTiers.next(res); });
    this.hoRaterData.fireProtection.pipe(takeUntil(this.stop$)).subscribe(res => { this.fireProtection = res; });
    this.dp3RaterData.fireProtection.pipe(takeUntil(this.stop$)).subscribe(res => { this.fireProtection = res; });

    this.hoRaterData.recalculatePremiumAndSave$.pipe(takeUntil(this.stop$))
      .subscribe(res => {
        if (this.isViewQuoteProposalClicked) {
          this.recalculatePremiumAndSave$.next(true);
          this.isViewQuoteProposalClicked = false;
        }
      });

    this.dp3RaterData.recalculatePremiumAndSave$.pipe(takeUntil(this.stop$))
      .subscribe(res => {
        if (this.isViewQuoteProposalClicked) {
          this.recalculatePremiumAndSave$.next(true);
          this.isViewQuoteProposalClicked = false;
        }
      });

    this.hoRaterData.recalculatePremiumSubmissionRewrite$.pipe(takeUntil(this.stop$))
      .subscribe(res => {
        this.recalculatePremiumSubmissionRewrite$.next(true);
      });

    this.dp3RaterData.recalculatePremiumSubmissionRewrite$.pipe(takeUntil(this.stop$))
      .subscribe(res => {
        this.recalculatePremiumSubmissionRewrite$.next(true);
      });

    this.hoRaterData.endorsementRequest$.pipe(takeUntil(this.stop$)).subscribe(() => { this.endorsementRequest$.next(true); });
    this.dp3RaterData.endorsementRequest$.pipe(takeUntil(this.stop$)).subscribe(() => { this.endorsementRequest$.next(true); });
  }

  quickQuoteRaterRequest(
    qqApplicant: FormGroup,
    propLocation: FormGroup,
    propDetails: FormGroup,
    summary: FormGroup,
    coveragesData: CoveragesData,
    isNew?: boolean) {
    this.formType = summary.get('formType')?.value.substring(0, 2);
    switch (this.formType) {
      case this.raterFormType.DP3:
        this.dp3RaterData.quickQuoteRaterRequest(
          qqApplicant, propLocation, propDetails, summary, coveragesData, isNew);
        break;
      case this.raterFormType.HO:
        this.hoRaterData.quickQuoteRaterRequest(
          qqApplicant, propLocation, propDetails, summary, coveragesData, isNew);
        break;
    }
  }

  coverageRaterRequest(coverageData: CoveragesData, policyNumber: string) {
    switch (this.formType) {
      case this.raterFormType.DP3:
        this.dp3RaterData.coverageRaterRequest(coverageData, policyNumber);
        break;
      case this.raterFormType.HO:
        this.hoRaterData.coverageRaterRequest(coverageData, policyNumber);
        break;
    }
  }

  applicantRaterRequest(coveragesData: CoveragesData) {
    switch (this.formType) {
      case this.raterFormType.DP3:
        this.dp3RaterData.applicantRaterRequest(coveragesData);
        break;
      case this.raterFormType.HO:
        this.hoRaterData.applicantRaterRequest(coveragesData);
        break;
    }
  }

  claimsRequest(claims: ClaimsData, policyNumber: string) {
    switch (this.formType) {
      case this.raterFormType.DP3:
        this.dp3RaterData.claimsRequest(claims, policyNumber);
        break;
      case this.raterFormType.HO:
        this.hoRaterData.claimsRequest(claims, policyNumber);
        break;
    }
  }

  endorsementRaterRequest(endorsementData: EndorsementsData, policyNumber: string) {
    switch (this.formType) {
      case this.raterFormType.DP3:
        this.dp3RaterData.endorsementRaterRequest(endorsementData, policyNumber);
        break;
      case this.raterFormType.HO:
        this.hoRaterData.endorsementRaterRequest(endorsementData, policyNumber);
        break;
    }
  }

  propertyRaterRequest(propertyData: PropertyData, coverageData: CoveragesData, policyNumber: string, propertyBusinessRules: PropertyBusinessRules) {
    switch (this.formType) {
      case this.raterFormType.DP3:
        this.dp3RaterData.propertyRaterRequest(propertyData, coverageData, policyNumber, propertyBusinessRules);
        break;
      case this.raterFormType.HO:
        this.hoRaterData.propertyRaterRequest(propertyData, coverageData, policyNumber, propertyBusinessRules);
        break;
    }
  }

  bindAndIssueRaterRequest(bindAndIssue: any, response: any) {
    switch (this.formType) {
      case this.raterFormType.DP3:
        this.dp3RaterData.bindAndIssueRaterRequest(bindAndIssue, response);
        break;
      case this.raterFormType.HO:
        this.hoRaterData.bindAndIssueRaterRequest(bindAndIssue, response);
        break;
    }
  }

  issueEndorsementPolicy(response: any) {
    switch (this.formType) {
      case this.raterFormType.DP3:
        this.dp3RaterData.issueEndorsementPolicy(response);
        break;
      case this.raterFormType.HO:
        this.hoRaterData.issueEndorsementPolicy(response);
        break;
    }
  }

  billingTransactionFeesRequest(policyBillingData: PolicyBillingData) {
    switch (this.formType) {
      case this.raterFormType.DP3:
        this.dp3RaterData.billingTransactionFeesRequest(policyBillingData);
        break;
      case this.raterFormType.HO:
        this.hoRaterData.billingTransactionFeesRequest(policyBillingData);
        break;
    }
  }

  populateRewritePayload(effectiveDate?, isProceedSaving?) {
    switch (this.formType) {
      case this.raterFormType.DP3:
        this.dp3RaterData.populateRewritePayload(effectiveDate, isProceedSaving);
        break;
      case this.raterFormType.HO:
        this.hoRaterData.populateRewritePayload(effectiveDate, isProceedSaving);
        break;
    }
  }

  calculateRaterPremium() {
    switch (this.formType) {
      case this.raterFormType.DP3:
        this.dp3RaterData.calculateRaterPremium();
        break;
      case this.raterFormType.HO:
        this.hoRaterData.calculateRaterPremium();
        break;
    }
  }

  populatedEndorsementIssuePayload(effectiveDate) {
    switch (this.formType) {
      case this.raterFormType.DP3:
        this.dp3RaterData.populatedEndorsementIssuePayload(effectiveDate);
        break;
      case this.raterFormType.HO:
        this.hoRaterData.populatedEndorsementIssuePayload(effectiveDate);
        break;
    }
  }

  getProratedPremiumDetails(response: IProcessedRaterView[]) {
    if (response != null){
      const proratedPremiumDetails = response?.filter(s => {
        return s.isMonetary && s.isMatrixDisplay && s.stepAlias !== DPRater.baseRate &&
          (s.totalProratedPremium !== 0 || s.proratedPremiumDiff !== 0);
      });
      const proratedResult = {
        proratedPremiumDetails: proratedPremiumDetails
      };
      this.proratedRaterResults.next(proratedResult);
    }
  }

  setEffectiveDateIssuePolicy(effectiveDate) {
    switch (this.formType) {
      case this.raterFormType.DP3:
        this.dp3RaterData.effectiveDate = new Date(effectiveDate + 'UTC');
        break;
      case this.raterFormType.HO:
        this.hoRaterData.effectiveDate = new Date(effectiveDate + 'UTC');
        break;
    }
  }

  populateRaterRequestPayload(
    data: EntityRiskDTO,
    qq: FormGroup,
    propLoc: FormGroup,
    propDtls: FormGroup,
    summary: FormGroup,
    covData: CoveragesData,
    endrData: EndorsementsData,
    propData: PropertyData,
    propertyBusinessRules: PropertyBusinessRules,
    tranFees?: TransactionFeeDTO[],
    isEndorsement: boolean = false
  ) {
    this.formType = summary.get('formType')?.value.substring(0, 2);
    switch (this.formType) {
      case this.raterFormType.DP3:
        this.dp3RaterData.populateRaterRequestPayload(
          data, qq, propLoc, propDtls, summary, covData, endrData, propData, propertyBusinessRules, tranFees, isEndorsement);
        break;
      case this.raterFormType.HO:
        this.hoRaterData.populateRaterRequestPayload(
          data, qq, propLoc, propDtls, summary, covData, endrData, propData, propertyBusinessRules, tranFees, isEndorsement);
        break;
    }

    this.populateRequestPayload$.next(true);
  }
}
