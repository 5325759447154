import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pending-batch-payments',
  templateUrl: './pending-batch-payments.component.html',
  styleUrls: ['./pending-batch-payments.component.scss']
})
export class PendingBatchPaymentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
