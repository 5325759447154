export const TableConstants = {
  relatedDocuments: 'Related Documents',
  uploadDocuments: 'Upload Documents',
  viewDocument: 'View Document',
  add: 'Add',
  edit: 'Edit',
  delete: 'Delete',
  SystemGenerated: -1,
  suppressed: 'Suppressed',
  viewDetails: 'View Details'
};
