import { Subject } from 'rxjs';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import NotifUtils from '../../../../shared/utilities/notif-utils';
import { UwApprovalComponent } from './uw-approval.component';
import { SummaryData } from '../../data/summary.data';
import { PageSectionsValidations } from '../../../../shared/enum/page-sections.enum';
import { PathConstants } from '../../../../shared/constants/path.constants';
import { GenericConstants } from '../../../../shared/constants/generic.constants';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';

@Injectable()
export class CanDeactivateUwApprovalComponentGuard implements CanDeactivate<UwApprovalComponent> {
    genericConstants = GenericConstants;

    constructor(private qqData: SummaryData, public router: Router,
        private policySummaryData: PolicySummaryData) {}

    canDeactivate(uwApproval: UwApprovalComponent, route: ActivatedRouteSnapshot, state: RouterStateSnapshot, nextState: RouterStateSnapshot) {
        const subject = new Subject<boolean>();
        const displayPopup = sessionStorage.getItem(PageSectionsValidations.CurrentPage) !== PathConstants.PageDestination.Quickquote && this.qqData.SummaryForm.get('riskId').value ? true : false;
        const showPopUp = !this.policySummaryData.isPolicyPage(this.qqData.SummaryForm.get('riskId').value) || this.policySummaryData.isEditPolicy;
        if (nextState.url === this.genericConstants.submissionsListUrl && displayPopup && showPopUp) {
            NotifUtils.showConfirmMessage(PageSectionsValidations.ConfirmMessageForSubmission,
                () => {
                    subject.next(true);
                }, () => {
                    subject.next(false);
                });
            return subject.asObservable();
        }
        return true;
    }
}