import { Injectable } from '@angular/core';
import { IApplicantPreviousValues } from 'app/shared/models/submission/applicant/applicant-previous-values.model';

@Injectable({
  providedIn: 'root'
})
export class PreviousApplicantValuesService {

  protected _previousApplicantValues: IApplicantPreviousValues = null;

  constructor() { }

  get Applicant(): IApplicantPreviousValues {
    return this._previousApplicantValues;
  }

  set Applicant(value: IApplicantPreviousValues) {
    this._previousApplicantValues = value;
  }

}
