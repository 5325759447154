import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'app/shared/base-class';
import { DashBoardService } from 'app/core/services/dashboard.service';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { takeUntil } from 'rxjs/operators';
import { IHelpBoxProgram } from 'app/shared/models/dashboard.helpbox.model';

@Component({
  selector: 'app-helpsection',
  templateUrl: './help-section.component.html',
  styleUrls: ['./help-section.component.scss']
})
export class HelpSectionComponent extends BaseClass implements OnInit {

  public agentDashboardConstants = AgentDashboardConstants;
  public iHelpBoxProgram: IHelpBoxProgram[];

  constructor(private dashBoardService: DashBoardService) {
    super();
   }

  ngOnInit() {
    this.dashBoardService.getHelpBoxSection().pipe(takeUntil(this.stop$)).subscribe(response => {
      const convertedResponse = response as IHelpBoxProgram[];
      let newArr = []
      let findRegionalSalesMgnr  = convertedResponse.find(r => r.description == 'Regional Sales Managers');
      let removeItem = convertedResponse.filter(( obj )=> {
        return obj.description !== 'Regional Sales Managers'
      });

      let getSecondIndex = removeItem.find((r,index) => index == 2);

      newArr.push(findRegionalSalesMgnr );

      let thirdIndex = removeItem.filter((r,index) => index != 2);

     thirdIndex.forEach((r,index)=>{
      if(index == 1){
        getSecondIndex.helpBoxProgramDetails.push(...r.helpBoxProgramDetails)
      }
     })
     thirdIndex.forEach((r,index)=>{
      if(index == 0){
        getSecondIndex.helpBoxProgramDetails.push(...r.helpBoxProgramDetails)
      }
     })

      newArr.push(getSecondIndex)
      this.iHelpBoxProgram = newArr;
    });
  }

}
