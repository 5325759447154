import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'app/core/services/layout/layout.service';
import { SuspendedPaymentsData } from '../data/suspended-payments.data';
import { createSuspendedPaymentsMenuItems } from './suspended-payments-navitems';

@Component({
  selector: 'app-suspended-payments',
  templateUrl: './suspended-payments.component.html',
  styleUrls: ['./suspended-payments.component.scss']
})
export class SuspendedPaymentsComponent implements OnInit {

  constructor(private layoutService: LayoutService, private suspendedPaymentsData: SuspendedPaymentsData) { }

  ngOnInit() {
    this.layoutService.updateMenu(createSuspendedPaymentsMenuItems());
    this.suspendedPaymentsData.initForms();
    this.suspendedPaymentsData.getSuspendedPaymentList();
    this.suspendedPaymentsData.getReturnedSuspendedPaymentList();
    this.suspendedPaymentsData.getSentToIpxSuspendedPaymentList();
  }

}
