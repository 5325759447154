import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-transfer-search-box',
  templateUrl: './transfer-search-box.component.html',
  styleUrls: ['./transfer-search-box.component.scss']
})
export class TransferSearchBoxComponent implements OnInit, OnChanges {
  searchForm = new FormGroup({
    keyword: new FormControl('')
  });

  @Input() searchKeywordValue: string = '';
  @Input() clearSearchBox: boolean = false;
  @Input() enableDebounceTime: boolean = false;
  @Input() debounceTime: number = 500;
  @Output() searchKeyword = new EventEmitter<any>();
  @Output() onChangeSearchKeyword = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.setSearchKeywordValue();
    this.checkChangeSearchKeyword();
  }

  setSearchKeywordValue(): void {
    this.searchForm.get('keyword').setValue(this.searchKeywordValue);
  }

  checkChangeSearchKeyword(): void {
    this.searchForm.get('keyword').valueChanges.subscribe(keyword => {
      this.onChangeSearchKeyword.emit(keyword);
    });
  }

  submitSearch(): void {
    this.searchKeyword.emit(this.searchForm.get('keyword').value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkClearSearchBox(changes.clearSearchBox?.currentValue);
    this.checkEnableDebounceTime(changes.enableDebounceTime?.currentValue);
  }

  checkClearSearchBox(clearSearchBox: boolean): void {
    if (clearSearchBox) {
      this.searchForm.get('keyword').setValue('');
    }
  }

  checkEnableDebounceTime(enableDebounceTime: boolean): void {
    if (enableDebounceTime) {
      this.searchForm.get('keyword').valueChanges.pipe(debounceTime(this.debounceTime)).subscribe(value => {
        this.submitSearch();
      });
    }
  }
}
