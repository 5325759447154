export const LvPayPlanOptions = [
  {
    tableName: 'LvPayPlanOptions',
    tableDescription: 'Payment Plan Options',
    code: 'PPO4',
    description: 'Mortgagee Bill – Full Pay',
    isActive: true,
    billingCode: 'Mortgagee'
  },
  {
    tableName: 'LvPayPlanOptions',
    tableDescription: 'Payment Plan Options',
    code: 'PPO0',
    description: 'Insured Billed – Full Pay',
    isActive: true,
    billingCode: 'Full'
  },
  {
    tableName: 'LvPayPlanOptions',
    tableDescription: 'Payment Plan Options',
    code: 'PPO1',
    description: 'Insured Billed – 2 Pay',
    isActive: true,
    billingCode: 'Two'
  },
  {
    tableName: 'LvPayPlanOptions',
    tableDescription: 'Payment Plan Options',
    code: 'PPO2',
    description: 'Insured Billed – 4 Pay',
    isActive: true,
    billingCode: 'Four'
  },
  {
    tableName: 'LvPayPlanOptions',
    tableDescription: 'Payment Plan Options',
    code: 'PPO3',
    description: 'Insured Billed – 8 Pay',
    isActive: true,
    billingCode: 'Eight'
  }
];

export const LvCreditCardType = [
  {
    tableName: 'LvCreditCardType',
    tableDescription: 'Credit Card Type',
    code: 'CCT0',
    description: 'AMEX',
    isActive: false
  },
  {
    tableName: 'LvCreditCardType',
    tableDescription: 'Credit Card Type',
    code: 'CCT1',
    description: 'DISCOVER',
    isActive: false
  },
  {
    tableName: 'LvCreditCardType',
    tableDescription: 'Credit Card Type',
    code: 'CCT2',
    description: 'VISA',
    isActive: true
  },
  {
    tableName: 'LvCreditCardType',
    tableDescription: 'Credit Card Type',
    code: 'CCT3',
    description: 'MASTERCARD',
    isActive: true
  }
];

export const LvAccountType = [
  {
    tableName: 'LvAccountType',
    tableDescription: 'Account Type',
    code: 'AT0',
    description: 'Checking',
    isActive: true
  },
  {
    tableName: 'LvAccountType',
    tableDescription: 'Account Type',
    code: 'AT1',
    description: 'Savings',
    isActive: true
  }
];

export const LvPaymentMethod = [
  {
    tableName: 'LvPaymentMethod',
    tableDescription: 'Payment Method',
    code: 'PM0',
    description: 'Cash/Check',
    isActive: true
  },
  {
    tableName: 'LvPaymentMethod',
    tableDescription: 'Payment Method',
    code: 'PM1',
    description: 'Credit Card',
    isActive: true
  },
  {
    tableName: 'LvPaymentMethod',
    tableDescription: 'Payment Method',
    code: 'PM2',
    description: 'E-check',
    isActive: true
  },
  {
    tableName: 'LvPaymentMethod',
    tableDescription: 'Payment Method',
    code: 'PM3',
    description: 'Recurring Credit Card',
    isActive: true
  },
  {
    tableName: 'LvPaymentMethod',
    tableDescription: 'Payment Method',
    code: 'PM4',
    description: 'Recurring E-check',
    isActive: true
  }
];