export const commonEnvironment = {
    ApiUrl: `/api`,
    AuthKey: 'auth',
    UsernameKey: 'uname',
    ClientId: 'Environmental',
    GrantType: {
        Password: 'password',
        RefreshToken: 'refresh_token'
    },
    QuoteFormTypeAndState: 'formTypeAndState',
    UserIdentifierKey: 'userIdentifier',
    showChangeServerDate: false,
    UserTypeRoleDescription: 'userTypeRoleDescription',
    activateNightlyJob: true,
    viewCommission: 'view_commission'
};
