import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuickQuoteData } from '../../../../../../modules/submission-management/data/quick-quote.data';
import { QuoteModalComponent } from '../../../../../../modules/dashboard/components/dashboard/quote-modal/quote-modal.component';
import { SubmissionListData } from '../../../../../../modules/submission-management/data/submission-list.data';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LvRiskStatus } from 'app/shared/constants/risk-status';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from 'app/shared/base-class';
import { FormBuilder } from '@angular/forms';
import { GenericLabel } from 'app/shared/constants/generic.labels.constants';
import { QuoteService } from '../../../../../../core/services/quote.service';
import { AuthService } from '../../../../../../core/services/auth.service';
import { AgentDashboardConstants } from '../../../../../../shared/constants/agent-dashboard.constants';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { PageHeaderSummaryConstants } from '../../../../../../shared/constants/page-header-summary.constants';
import NotifUtils from '../../../../../../shared/utilities/notif-utils';

@Component({
  selector: 'app-submission-table',
  templateUrl: './submission-table.component.html',
  styleUrls: ['./submission-table.component.scss']
})
export class SubmissionTableComponent extends BaseClass implements OnInit, OnDestroy {
  public LvRiskStatus = LvRiskStatus;
  modalRef: BsModalRef | null;
  public searchQuery: string = '';
  isExternal: boolean = true;
  public genericLabel = GenericLabel;
  public agentDashboardConstants = AgentDashboardConstants;
  public resultsPerPage: number = 10;
  public renewalStatus = PageHeaderSummaryConstants.policyRenewal.statusCode;
  @Input() submissionLoader: boolean = true;

  constructor(
    protected router: Router,
    public submissionListData: SubmissionListData,
    protected modalService: BsModalService,
    protected quickQuoteData: QuickQuoteData,
    public route: ActivatedRoute,
    protected fb: FormBuilder,
    protected quoteService: QuoteService,
    protected authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.queryParamMap.pipe(takeUntil(this.stop$)).subscribe(queryParams => {
      this.searchQuery = queryParams.get('q')?.toString()?.trim();
      this.submissionListData.searchForm.get('searchText').setValue(this.searchQuery);
    });

    this.authService.userType.subscribe(x => {
      this.isExternal = (x === 'external');
    });

  }

  ngOnDestroy(): void {
    this.submissionListData.totalRowCount = 500;
    this.submissionListData.itemPerPage = 10;
    this.submissionListData.currentPage = 1;
  }

  policyNumber(data?: any): string {
    const renewalStatus = this.renewalStatus;
    const includedRenewalStatus: string[] = [renewalStatus.RNPEN, renewalStatus.RRNPEN, renewalStatus.RWO, renewalStatus.RRWO, renewalStatus.RWNT, renewalStatus.RRW];
    return data?.isEndorsement || includedRenewalStatus.includes(data?.renewalStatus) ? data?.policyNumber : '';
  }

  onCreateSubmissionBtnClicked(event: Event) {
    this.router.navigateByUrl('/submission/details');
  }

  setPage(page: number) {
    this.submissionListData.setPage(page);
  }

  showModal(): void {
    if (this.isExternal) {
      this.authService.checkUserBindingAuthority(this.authService.isExternalUser, this.authService.getCustomDate());
      if (this.authService.isUserAllowedToBind) {
        this.quickQuoteData.orderPrefill = false;
        const initialState = {
          title: this.agentDashboardConstants.startNewQuoteModalTitle,
        };
        this.modalRef = this.modalService.show(QuoteModalComponent, {
          initialState,
          backdrop: true,
          ignoreBackdropClick: true,
        });
      } else {
        NotifUtils.showInformationWithConfirmationFromUser(this.agentDashboardConstants.bindingAuthorityNotAvailable, () => { });
      }
    }
  }

  onClick(data?) {
    localStorage.setItem('entityId', data?.entityId);
    localStorage.setItem('riskId', data?.riskId);

    if (data?.endorsementRiskDetailId) {
      localStorage.setItem('riskDetailId', data?.endorsementRiskDetailId);
    } else {
      localStorage.setItem('riskDetailId', data?.submissionRiskDetailId);
    }

    this.quoteService.savetoService(data?.formType);
  }

  onSubmit(value: string): void {
    this.submissionListData.showLoader = true;
    this.submissionListData.currentPage = 1;
    this.submissionListData.searchFilterForm.get('searchText').setValue(this.submissionListData.searchForm.get('searchText').value);
    this.submissionListData.buildSubmissionListRequest(this.submissionListData.searchFilterForm);
    this.submissionListData.callSubmissionList();
  }

  get showTableFooter(): boolean {
    return this.submissionListData.totalRowCount > this.resultsPerPage;
  }

  pageChanged(event: PageChangedEvent): void {
    if (this.submissionListData.currentPage === event.page) {return;}
    this.submissionListData.showLoader = true;
    this.submissionListData.currentPage = event.page;
    this.submissionListData.buildSubmissionListRequest(this.submissionListData.searchFilterForm);
    this.submissionListData.callSubmissionList();
  }
}
