import { createSelector } from '@ngrx/store';
import { BaseState } from '../base.state';

export const selectChangesState = (state: BaseState) => state.changes;

export const selectChangesIsLoading = createSelector(
  selectChangesState,
  (state) => state.isLoading
);

export const selectChangesList = createSelector(
  selectChangesState,
  (state) => state.list
);

export const selectPolicyChangeUser = createSelector(
  selectChangesState,
  (state) => state.policyChangeUser
);

export const selectEffectiveDateUser = createSelector(
  selectChangesState,
  (state) => state.effectiveDateUser
);