import { Component, OnInit } from '@angular/core';
import { BaseClass } from '../../../../../shared/base-class';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BatchPaymentsConstants } from '../../../../../shared/constants/batch-payments.constants';
import { BatchPaymentsData } from '../../../../../modules/payments/data/batch-payments.data';
import { BatchPaymentsDetailsModalComponent } from '../../batch-payments-modals/batch-payments-details-modal/batch-payments-details-modal.component';
import { BatchPaymentDTO } from '../../../../../shared/models/data/dto/batch-payments/batch-payment.dto';

@Component({
  selector: 'app-processed-batch-payments-list',
  templateUrl: './processed-batch-payments-list.component.html',
  styleUrls: ['./processed-batch-payments-list.component.scss']
})
export class ProcessedBatchPaymentsListComponent extends BaseClass implements OnInit {
  public BatchPaymentsLabel = BatchPaymentsConstants.Labels;
  modalRef: BsModalRef;
  processedBatchPaymentsItems = [];

  constructor(
    private modalService: BsModalService,
    private batchPaymentsData: BatchPaymentsData
  ) {
    super();
  }

  ngOnInit() {
    this.batchPaymentsData.getProcessedBatchPaymentsList();
  }

  onDetailsClick(item?: BatchPaymentDTO): void {
    this.batchPaymentsData.getDetailedBatchPaymentView(item.batchNumber);
    this.batchPaymentsData.detailsViewNotifier.take(1).subscribe(res => {
      if (res.details) {

        const initialState = {
          batchPaymentDetails: res.details,
          isAdd: false,
          isForPendingPayments: false
        };

        this.showBatchPaymentDetailsModal(BatchPaymentsDetailsModalComponent, initialState);
      }
    });
  }


  showBatchPaymentDetailsModal(component?, initialState?): BsModalRef {
    return this.modalService.show(component, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
    });
  }

  showUploadBatchPaymentFileModal(component?, initialState?): BsModalRef {
    return this.modalService.show(component, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
  }

  setPage(page: number) {
    this.batchPaymentsData.batchPaymentsProcessedListSetPage(page);
  }
}
