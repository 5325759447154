export const LvSuspensionReason = [
  {
    tableName: 'LvSuspensionReason',
    tableDescription: 'Suspension Reason',
    code: 'NMP',
    description: 'No Matching Policy',
    isActive: true
  },
  {
    tableName: 'LvSuspensionReason',
    tableDescription: 'Suspension Reason',
    code: 'RCNM',
    description: 'Reinstatement Criteria Not Met',
    isActive: true
  }
];