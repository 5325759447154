import { Component, OnInit } from '@angular/core';
import { SuspendedPaymentsLabel } from '../../../../../shared/constants/suspended-payments.labels.constants';
import { BaseClass } from '../../../../../shared/base-class';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ReverseModalComponent } from '../reverse-modal/reverse-modal.component';
import { DetailsSuspendedPaymentModalComponent } from '../../pending/pending-suspended-modal/details-suspended-payment-modal/details-suspended-payment-modal.component';
import { SuspendedPaymentDetailsDTO } from '../../../../../../app/shared/models/data/dto/suspended-payment/suspended-payment-detail.dto';
import { SuspendedPaymentsData } from '../../../../../modules/payments/data/suspended-payments.data';
import Utils from '../../../../../../app/shared/utilities/utils';

@Component({
  selector: 'app-returned-list',
  templateUrl: './returned-list.component.html',
  styleUrls: ['./returned-list.component.scss']
})
export class ReturnedListComponent extends BaseClass implements OnInit {

  isOpen: boolean = true;

  public returnedLabelConstants = SuspendedPaymentsLabel.returned;

  constructor(public suspendedPaymentsData: SuspendedPaymentsData,
    private modalService: BsModalService,
  ) {
    super();
  }

  ngOnInit() {
  }

  onDetails(item?: SuspendedPaymentDetailsDTO): void {
    Utils.blockUI();
    this.suspendedPaymentsData.getDetailedSuspendedView(item.suspendedPaymentId);
    this.suspendedPaymentsData.detailsViewNotifier.take(1).subscribe(res => {
      Utils.unblockUI();

      const initialState = {
        paymentDetail: res.details,
        hasReturnDetails: true
      };
      this.showReturnedSuspendedModal(DetailsSuspendedPaymentModalComponent, initialState);
    });
  }

  onReverse(id?: string): void {
    Utils.blockUI();
    this.suspendedPaymentsData.getDetailedSuspendedView(id);
    this.suspendedPaymentsData.detailsViewNotifier.take(1).subscribe(res => {
      Utils.unblockUI();

      const initialState = {
        suspendedPaymentId: id,
        returnPaymentDetail: res.details,
        isReversal: true
      };
      this.showReturnedSuspendedModal(ReverseModalComponent, initialState);
    });
  }

  showReturnedSuspendedModal(component, initialState: any): BsModalRef {
    return this.modalService.show(component, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }

  onCheckNotIssuedChange(event: any): void {
    this.suspendedPaymentsData.returnedListIsCheckNotIssued = event.target.checked;
    this.suspendedPaymentsData.returnedListSetPage(1);
  }
}
