import { Pipe, PipeTransform } from '@angular/core';
import { RiskStatus, RiskStatusCode } from '../models/data/dto/quick-quote/risk.dto';

@Pipe({
  name: 'getRiskStatus'
})
export class GetRiskStatusPipe implements PipeTransform {
  transform(riskStatusCode: RiskStatusCode): RiskStatus {
    let riskStatus: RiskStatus = 'Status';

    switch (riskStatusCode) {
      case 'QUO':
        riskStatus = 'Quoted';
        break;
      case 'APP':
        riskStatus = 'Application';
        break;
      case 'RUW':
        riskStatus = 'Referred to UW';
        break;
      case 'AUW':
        riskStatus = 'Approved by UW';
        break;
      case 'DUW':
        riskStatus = 'Declined by UW';
        break;
      case 'AIR':
        riskStatus = 'Additional Information Required';
        break;
      case 'QEXP':
        riskStatus = 'Quote Expired';
        break;
      case 'ACT':
        riskStatus = 'Active';
        break;
      case 'PEN':
        riskStatus = 'Pending';
        break;
      case 'PEC':
        riskStatus = 'Pending Cancellation';
        break;
      case 'PEXP':
        riskStatus = 'Policy Expired';
        break;
      case 'Draft':
        riskStatus = 'Draft';
        break;
      case 'CAN':
        riskStatus = 'Cancelled';
        break;
      default:
        riskStatus = 'Status';
    }

    return riskStatus;
  }
}
