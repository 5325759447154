import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notes-section',
  templateUrl: './notes-section.component.html',
  styleUrls: ['./notes-section.component.scss']
})
export class NotesSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
