import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PolicyBillingLabelsConstants } from '../../../../shared/constants/policy-billing.labels.constants';
import { BaseClass } from '../../../../shared/base-class';
import { PolicyBillingData } from '../../data/policy-billing.data';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { BillingService } from 'app/core/services/billing/billing.service';
import { InstallmentInvoiceDTO } from '../../../../../app/shared/models/data/dto/billing/installment-invoice.dto';
import NotifUtils from '../../../../../app/shared/utilities/notif-utils';
import Utils from '../../../../../app/shared/utilities/utils';
import { InfoMessageConstant } from '../../../../../app/shared/constants/info-message.constants';
import { EntityRiskData } from '../../../../modules/submission-management/data/entity-risk.data';
import { PolicyService } from 'app/core/services/submission/policy.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-installment-schedule',
  templateUrl: './installment-schedule.component.html',
  styleUrls: ['./installment-schedule.component.scss']
})
export class InstallmentScheduleComponent extends BaseClass implements OnInit {
  isOpen: boolean = true;

  public installmentScheduleConstants = PolicyBillingLabelsConstants.installmentSchedule;

  installmentSchedules: InstallmentInvoiceDTO[];

  temporaryDateForHidingView: Date
  isSiebel: boolean;

  constructor(public policyBillingData: PolicyBillingData,
    public summaryData: SummaryData,
    public billingService: BillingService,
    public entityRiskData: EntityRiskData,
    protected policyService: PolicyService) {
    super();
  }


  ngOnInit() {
    this.temporaryDateForHidingView = new Date('06/01/2022')
    this.entityRiskData.isApiCallCompleted$.subscribe(() => {
      this.isSiebel = this.entityRiskData.getRiskIsSiebel() ?? false;
    });

    if (this.entityRiskData.getRisk()) {
      this.isSiebel = this.entityRiskData.getRiskIsSiebel() ?? false;
    }
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }

  onViewClick(url: string): void {
    Utils.blockUI();
    this.policyService.generateSASURL(url).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
      window.open(resultSASURL, '_blank');
    });
    Utils.unblockUI();
  }
}
