import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { SubmissionPageData } from '../../../../../modules/submission-management/data/submission-page.data';
import { InterestLabelsConstants } from 'app/shared/constants/interest.labels.constants';
import { InterestData } from '../../../../../modules/submission-management/data/interest.data';
import { GeneralValidationService } from '../../../../../core/services/submission/validations/general-validation.service';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { SubmissionNavValidationService } from '../../../../../core/services/navigation/submission-nav-validation.service';
import { PathConstants } from '../../../../../shared/constants/path.constants';
import { BaseClass } from '../../../../../shared/base-class';
import { PolicySummaryData } from '../../../../policy-management/data/policy-summary.data';
import FormUtils from 'app/shared/utilities/form.utils';
import { AuthService } from 'app/core/services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';
import { QuoteService } from 'app/core/services/quote.service';
import { PageHeaderSummaryConstants } from 'app/shared/constants/page-header-summary.constants';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';

@Component({
  selector: 'app-prior-insurance',
  templateUrl: './prior-insurance.component.html',
  styleUrls: ['./prior-insurance.component.scss'],
})
export class PriorInsuranceComponent extends BaseClass implements OnInit {
  priorInsurancefrm: FormGroup;
  public isPriorInsuranceHidden: boolean = false;
  public LabelMessageConstants = InterestLabelsConstants;
  public ErrorMessageConstant = ErrorMessageConstant;
  public GenericLabel = GenericLabel;
  public FormUtils = FormUtils;
  isExternal: boolean;
  public formTypeConstants = FormTypeConstants;

  constructor(
    public interestData: InterestData,
    protected submissionData: SubmissionPageData,
    protected generalValidationService: GeneralValidationService,
    protected submissionNavValidationsService: SubmissionNavValidationService,
    public policySummaryData: PolicySummaryData,
    public authService: AuthService,
    public quoteService: QuoteService,
    protected entityRiskData: EntityRiskData) {
    super();
  }

  ngOnInit() {
    this.ToggleHasPriorInsurance();
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.isExternal = userType.toLocaleLowerCase() === 'external';
    });
  }

  public ToggleHasPriorInsurance(): void {
    const c = this.interestData.priorInsuranceForm.get('priorInsurance').value;
    if (c === InterestLabelsConstants.priorCarrierList.PI0) {
      this.interestData.hasPriorInsurance = true;
      if (this.quoteService.formType !== this.formTypeConstants.HO4) {
        this.generalValidationService.resetValidatorFormControl(this.interestData.priorInsuranceForm, 'priorCarrier', Validators.required);
      }
      this.generalValidationService.resetValidatorFormControl(this.interestData.priorInsuranceForm, 'priorExpirationDate', Validators.required);
      this.interestData.priorInsuranceForm.markAllAsTouched();
      this.setValidation();
    } else {
      this.interestData.hasPriorInsurance = false;
      this.interestData.priorInsuranceForm.get('priorCarrier').setValue('');
      this.interestData.priorInsuranceForm.get('priorExpirationDate').setValue('');
      this.generalValidationService.clearValidatorFormControl(this.interestData.priorInsuranceForm, 'priorCarrier');
      this.generalValidationService.clearValidatorFormControl(this.interestData.priorInsuranceForm, 'priorExpirationDate');
      this.setValidation();
    }
  }

  public TogglePriorInsuranceHidden(): void {
    this.isPriorInsuranceHidden = !this.isPriorInsuranceHidden;
    this.submissionData.isPriorInsuranceOpen = !this.isPriorInsuranceHidden;
  }

  onPriorCareerChange(): void {
    this.setValidation();
  }

  onPriorExpirationDateChanged(event?): void {
    if (event.formatted !== '') {
      this.interestData.priorInsuranceForm.get('priorExpirationDate').setValue(event);
      this.setValidation();
    }
  }

  setValidation(): void {
    this.submissionNavValidationsService.validateCurrentCategory(PathConstants.Submission.Submission.Interests);
    this.interestData.hasInvalid = (this.submissionNavValidationsService?.validationList?.coverages &&
      this.submissionNavValidationsService?.validationList?.property &&
      this.submissionNavValidationsService?.validationList?.applicant &&
      this.submissionNavValidationsService?.validationList?.uwQuestions &&
      this.submissionNavValidationsService?.validationList?.interest) ? false : true;
  }

  isInternalPolicyControls(): boolean {
    const status = !this.policySummaryData.isPolicy ? false :
      this.policySummaryData.isEditPolicy && !this.isExternal ? false : true;
    return status;
  }

  get isRenewalOffered(): boolean {
    return (this.policySummaryData.renewalStatusCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RWO ||
      this.policySummaryData.renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RWO) ||
      (this.policySummaryData.renewalStatusCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO ||
      this.policySummaryData.renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO);
  }

  calculateDiff(sentDate) {
    const date1: any = sentDate;
    const date2: any = this.authService.getCustomDate();
    const diffDays: any = Math.floor((date1 - date2) / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  get disableFormFields(): boolean {

    const renewalEffectiveDate = new Date(this.entityRiskData.EntityRisk?.risks[0]?.renewalEffectiveDate);
    const diffDays = this.calculateDiff(renewalEffectiveDate);

    if (this.isRenewalOffered &&
        this.authService.userType.value === GenericConstants.userType.external &&
        diffDays < 2) {
      return true;
    }

    return this.submissionData.disableQuoteForms;
  }
}
