import { AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SubmissionPageData } from '../../../../../modules/submission-management/data/submission-page.data';
import { BindAndIssueData } from '../../../../../modules/submission-management/data/bind-and-issue.data';
import { SignatureLabelConstants } from '../../../../../shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { TypeaheadSettings } from 'ngx-type-ahead';


@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit, AfterViewInit {
  @ViewChild('signature') signature: ElementRef;
  @ViewChild('arrow') arrow: ElementRef;

  SignatureLabelConstants = SignatureLabelConstants;
  ErrorMessageConstant = ErrorMessageConstant;
  GenericLabel = GenericLabel;

  isOpen: boolean = true;
  noResult: boolean = false;

  count: number = 0;

  customSettings: Partial<TypeaheadSettings> = {
    noMatchesText: 'No results found',
    dropdownToggleClass: 'button-down',
    suggestionsLimit: 0
  };

  constructor(
    public bindAndIssueData: BindAndIssueData,
    protected submissionData: SubmissionPageData,
    protected renderer: Renderer2
  ) { }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.renderer.listen('window', 'click',(e: Event)=> {
      if(e.target !== this.signature.nativeElement && e.target !== this.arrow.nativeElement){
        this.bindAndIssueData.signatureForm.get('signature').parent.controls['signature'].touched = false;
      } else {
        this.bindAndIssueData.signatureForm.get('signature').parent.controls['signature'].touched = true;
      }
    });
  }

  collapse() {
    this.isOpen = !this.isOpen;
    this.submissionData.isSignatureOpen = this.isOpen;
  }

  typeaheadNoResults(event: boolean): void {
    this.noResult = event;
  }

  toggleOwnerDropdwon() {
    this.count++;
    if (this.bindAndIssueData.signatureForm.get('signature').parent.controls['signature'].touched && this.count !== 1) {
      this.signature.nativeElement.blur();
    } else {
      this.signature.nativeElement.focus();
    }
  }
}