import { Component, OnInit } from '@angular/core';
import { PolicyIssueConstants } from '../../../../shared/constants/policy-issue.labels.constants';
import { PolicyIssueData } from '../../data/policy-issue.data';
import { PremiumChangeModel } from '../../../../shared/models/policy/premium-changes.model';
import { RaterFormTypeConstants } from '../../../../shared/constants/rater-form-types.constants';
import { DPRater } from '../../../../shared/constants/dp3-rater.constant';
import { Rater } from '../../../../shared/constants/rater.constant';
import { BaseComponent } from 'app/shared/base-component';

@Component({
  selector: 'app-premium-changes',
  templateUrl: './premium-changes.component.html',
  styleUrls: ['./premium-changes.component.scss']
})
export class PremiumChangesComponent extends BaseComponent implements OnInit {
  public PolicyIssueConstants = PolicyIssueConstants;
  isOpen: boolean = true;
  premiumChangeList: PremiumChangeModel[];
  totals: PremiumChangeModel;
  formType: string = 'HO';
  raterFormType = RaterFormTypeConstants;

  public premiumChangesHeaders: string[] = [
    this.PolicyIssueConstants.premiumChanges.coveragesLabel,
    this.PolicyIssueConstants.premiumChanges.newPremiumLabel,
    this.PolicyIssueConstants.premiumChanges.currentPremiumLabel,
    this.PolicyIssueConstants.premiumChanges.proRatedPremiumLabel,
    this.PolicyIssueConstants.premiumChanges.premiumDifferenceChangeLabel
  ];

  constructor(protected policyIssueData: PolicyIssueData) {
    super();
    this.premiumChangeList = this.policyIssueData.premiumChangeList;
  }

  ngOnInit() { }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }

  highlightTotalRows(rater) {
    switch(this.formType) {
      case this.raterFormType.DP3:
        return rater.stepAlias === DPRater.totalPremium ||
          rater.stepAlias === DPRater.totPremByPeril ||
          rater.stepAlias === DPRater.fprePlusFee;
      case this.raterFormType.HO:
        return rater.stepAlias === Rater.totalPremium ||
          rater.stepAlias === Rater.fprePlusFee ||
          rater.stepAlias === Rater.totalPremiumWithAdj;
    }
  }
}