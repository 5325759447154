import { Component, OnInit } from '@angular/core';
import { PathConstants } from 'app/shared/constants/path.constants';
import { NavigationService } from 'app/core/services/navigation/navigation.service';
import { ClickTypes } from 'app/shared/enum/click-type.enum';
import { HistoryLabelsConstants } from '../../../shared/constants/history.labels.constants';
import { SummaryData } from '../../../modules/submission-management/data/summary.data';
import { RaterPremiumService } from '../../../core/services/submission/rater-premium/rater-premium.service';
import { PolicyHistoryData } from '../data/policy-history-data';
import { EndorsementHistory } from '../../../shared/models/rater-premium/endorsement-history.model';
import { GenericLabel } from '../../../shared/constants/generic.labels.constants';
import { EntityRiskData } from '../../../modules/submission-management/data/entity-risk.data';
import { PolicyStatus } from '../../../shared/constants/rater-policy-status.constant';
import { TransactionType } from '../../../shared/constants/transaction-type.constant';
import NotifUtils from '../../../../app/shared/utilities/notif-utils';
import { PolicyService } from '../../../../app/core/services/submission/policy.service';
import Utils from '../../../../app/shared/utilities/utils';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../../app/shared/base-component';
import { ErrorMessageConstant } from '../../../../app/shared/constants/error-message.constants';
import { Router } from '@angular/router';
import { CalculateRaterData } from '../../../../app/core/services/submission/rater-premium/calculate-rater-data.service';
import { BackoutEndorsementDTO } from '../../../../app/shared/models/data/dto/policy-management/backout-endorsement.dto';
import { StatusConstants } from '../../../../app/shared/constants/risk-status';
import { PolicyEditConstants } from '../../../shared/constants/policy-edit.constants';
import { PolicySummaryData } from '../data/policy-summary.data';
import { AgentDashboardConstants } from '../../../shared/constants/agent-dashboard.constants';
import { SubmissionPageData } from '../../submission-management/data/submission-page.data';

@Component({
  selector: 'app-policy-history',
  templateUrl: './policy-history.component.html',
  styleUrls: ['./policy-history.component.scss']
})
export class PolicyHistoryComponent extends BaseComponent implements OnInit {

  public HistoryLabelsConstants = HistoryLabelsConstants;
  public genericLabel = GenericLabel;
  public TransactionType = TransactionType;
  PolicyStatus = PolicyStatus;

  historyTableHeaders: string[] = [
    '',
    'Date',
    'Effective Date',
    'Transaction Type',
    'Annual Premium for Current Endorsement',
    'Premium Before Endorsement',
    'Premium After Endorsement',
    'Premium Change'
  ];
  historyItems: any[] = [];
  isToggleHistory: boolean = true;

  constructor(
    public navigationService: NavigationService,
    public policyHistoryData: PolicyHistoryData,
    public raterPremiumService: RaterPremiumService,
    public summaryData: SummaryData,
    public entity: EntityRiskData,
    public policyService: PolicyService,
    public router: Router,
    protected entityRiskData: EntityRiskData,
    protected policySummaryData: PolicySummaryData,
    protected submissionPageData: SubmissionPageData) {
    super();
    this.updateEndorsementHistory();
  }

  ngOnInit() {
    const policyNumber = this.summaryData.SummaryForm.get('policyNumber').value;
    if (policyNumber !== null && this.policyHistoryData.historyItemsData?.length === 0) {
      if (this.entity.getRiskId() !== undefined && this.entity.getLastRiskDetailId() !== undefined) {
        this.policyHistoryData.populateHistoryData(
          policyNumber,
          this.entity.getRiskId(),
          this.entity.getLastRiskDetailId()
        );
      }
    }
  }

  getTransactionType(item) {
    return item.policyStatus === PolicyStatus.issued ?
      (item.endorsementNumber === 0 ?
        (item.isRewrite ? TransactionType.newbusiness : TransactionType.new) :
        TransactionType.endorsement + ' ' + item.endorsementNumber) :
      item.policyStatus === PolicyStatus.cancelled ?
        (item.isRewrite ? TransactionType.cancelledRewrite : item.policyStatus) : item.policyStatus;
  }

  updateEndorsementHistory() {
    this.policyHistoryData.historyItems.subscribe(endorsementResult => {

      if (this.entity.getRisk()?.riskStatusCode !== StatusConstants.cancelled) {
        const currentEndorsement = endorsementResult?.filter(trans => {
          return trans.policyStatus === PolicyStatus.issued && parseFloat(trans.endorsementNumber) > 0 && !trans.isBackOut;
        }).reduce((prev, current) => {
          return (prev?.endorsementNumber > current.endorsementNumber) ? prev : current;
        }, null);

        if (currentEndorsement) {
          currentEndorsement.isBackoutEnabled = true;
        }
      }

      if (this.summaryData.hiddenRewriteDueToEndorsement && endorsementResult?.filter(x => x.isBackoutEnabled).length === 0) {
        this.summaryData.showRewriteOption = true;
      }

      this.historyItems = endorsementResult;
      this.policyHistoryData.historyItemsData = endorsementResult;
    });
  }

  toggleHistory() {
    this.isToggleHistory = !this.isToggleHistory;
  }

  showCollapsibleRow(item): boolean {
    return item.details && item.details.length > 0;
  }

  public onClick(clickType?) {
    switch (clickType) {
      case ClickTypes.Back:
        // to do back
        this.navigationService.navigatePolicyRoute(PathConstants.Policy.Policies.Summary);
        break;
      case ClickTypes.Next:
        // to do next
        this.navigationService.navigatePolicyRoute(PathConstants.Policy.Policies.Billing);
        break;
    }
  }

  callPolicyChangesHistory(historyItems: EndorsementHistory, index: number): void {
    if (index !== 0) {
      if (historyItems.cancellationTypeCode !== null) {
        this.policyHistoryData.mapCancellationReasonHistory(historyItems, true);
      } else if (historyItems.cancellationTypeCode === null && historyItems.policyStatus === TransactionType.cancelled) {
        this.policyHistoryData.mapCancellationReasonHistory(historyItems, false);
      } else {
        this.policyHistoryData.populatePolicyChangesHistory(historyItems.riskDetailId);
      }
    }
  }

  confirmBackout(riskDetailId: string): void {
    const riskId = this.entityRiskData.getRisk()?.id;
    const currentRiskDetailId = this.entityRiskData.getRisk()?.riskDetails[0].id;
    const validPolicyStatus = this.isValidPolicyStatus();
    Utils.blockUI();
    this.policyService.getPolicyChanges(riskId, currentRiskDetailId).pipe(takeUntil(this.stop$)).subscribe(policyChanges => {
      if (policyChanges?.length > 0 && validPolicyStatus) {
        NotifUtils.showInfo(PolicyEditConstants.pendingEndorsementChangesConfirmationMessage, () => {
            this.policySummaryData.navigatedToPolicyIssue = true;
            this.navigationService.navigatePolicyRoute(PathConstants.Policy.Policies.Issue);
            Utils.unblockUI();
        }, false);
      } else {
        NotifUtils.showConfirmMessage(this.HistoryLabelsConstants.backoutConfirmation, () => {
            const backoutRequest: BackoutEndorsementDTO = {
              riskId: this.entity.getRiskId(),
              riskDetailId: riskDetailId
            };
            this.processBackout(backoutRequest);
        }, () => { });
      }
    }, error => {
      Utils.unblockUI();
      NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
    });
  }

  processBackout(backoutRequest: BackoutEndorsementDTO): void {
    Utils.blockUI();
    this.policyService.postBackoutEndorsement(backoutRequest).pipe(takeUntil(this.stop$)).subscribe(result => {
      Utils.unblockUI();

      NotifUtils.showSuccessWithConfirmationFromUser(HistoryLabelsConstants.backoutComplete, () => {
        localStorage.setItem('isEditPolicy', 'false');

        const entityRisk = this.entityRiskData?.EntityRisk?.risks[0];
        localStorage.setItem('lastRiskDetailId', entityRisk?.riskDetails[0]?.lastRiskDetailId);

        // Force reload to re-initialize  fetched data after back-out
        this.router.navigate(['/']).then(() => {
          // Then navigates to desired url
          this.router.navigate([`/policies/${result.riskId}/${result.newRiskDetailId}/history`]);
        });
      });
    }, err => {
      Utils.unblockUI();
      NotifUtils.showError(err.error);
    });
  }

  get isPolicyCancelled(): boolean {
    return this.entity.getRisk().riskStatusCode === StatusConstants.cancelled;
  }

  isValidPolicyStatus(): boolean {
    return this.submissionPageData.policyStatus?.value !== AgentDashboardConstants.declineByUW &&
      this.entityRiskData.getPolicyStatus() !== 'CAN';
  }
}
