import { Injectable } from '@angular/core';
import { QuickQuoteService } from '../../../core/services/submission/quick-quote.service';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { SummaryData } from './summary.data';
import { NavigationService } from '../../../core/services/navigation/navigation.service';
import { BaseClass } from '../../../shared/base-class';
import Utils from '../../../shared/utilities/utils';
import { PropertyData } from './property.data';
import { SaveApplicantDTO } from '../../../shared/models/data/dto/quick-quote/save-applicant-dto';
import { CoApplicantEntityAddressDTO } from '../../../shared/models/data/dto/quick-quote/co-applicant-entity-address.dto';
import { CoApplicantAddressDTO } from '../../../shared/models/data/dto/quick-quote/co-applicant-address.dto';
import { CoApplicantEntityDTO, CoApplicantEntityDTO2 } from '../../../shared/models/data/dto/quick-quote/co-applicant-entity.dto';
import { ApplicantAddressDTO } from '../../../shared/models/data/dto/quick-quote/applicant-address.dto';
import { ApplicantEntityAddressDTO } from '../../../shared/models/data/dto/quick-quote/applicant-entity-address.dto';
import { RegexConstants } from '../../../shared/constants/regex.constants';
import { ApplicantData } from './applicant.data';
import { PathConstants } from '../../../shared/constants/path.constants';
import { InfoMessageConstant } from '../../../shared/constants/info-message.constants';
import { takeUntil } from 'rxjs/operators';
import { NameCasePipe } from '../../../shared/custom pipes/name-pipe';
import { ErrorMessageConstant } from '../../../shared/constants/error-message.constants';
import { PreviousApplicantValuesService } from 'app/core/services/submission/others/previous-applicant-values.service';
import { ApplicantValidationService } from '../../../core/services/submission/validations/applicant-validation.service';
import { EntityRiskData } from './entity-risk.data';
import { EntityAddressDTO3, AddressDTO3 } from '../../../shared/models/data/dto/quick-quote/entity-address.dto';
import { EntityRiskDTO } from '../../../shared/models/data/dto/quick-quote/entity-risk.dto';
import { ApplicantLabelsConstants } from '../../../shared/constants/applicant.labels.constants';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { ApplicantBusinessRules } from '../business-rules/applicant.business-rules';
import { ZipCodeData } from './zipcode.data';
import { CoveragesData } from './coverages.data';
import { ThirdPartyDataService } from 'app/core/services/submission/third-party/third-party-data.service';
import { ThirdPartyData } from './third-party.data';
import { ToastrService } from 'ngx-toastr';
import { LvCreditOrderStatus, LvCreditOrderStatusIndex } from 'app/shared/constants/quick-quote.options.constants';
import { PolicyIssueData } from '../../policy-management/data/policy-issue.data';
import { RaterPremiumService } from 'app/core/services/submission/rater-premium/rater-premium.service';
import { ClaimsData } from './claims.data';
import { SavingFlagConstants } from 'app/shared/constants/saving-flag.constants';
import { SubmissionPageData } from 'app/modules/submission-management/data/submission-page.data';
import { LvMaritalStatuses } from '../../../shared/constants/applicant.options.constants';
import { ReportsService } from 'app/core/services/reports/reports.service';
import { InspectionOrderingDTO } from 'app/shared/models/data/dto/report/inspection.ordering.dto';
import { AuthService } from '../../../core/services/auth.service';
import { GenericConstants } from '../../../shared/constants/generic.constants';
import { UwApprovalData } from './uw-approval.data';
import { updateApplicantsIsLoadingFromApplicantsSavingData } from '../../../store/applicants/applicants.actions';
import { Store } from '@ngrx/store';
import { RiskStatusCode } from 'app/shared/models/data/dto/quick-quote/risk.dto';

@Injectable({
  providedIn: 'root'
})

export class ApplicantSavingData extends BaseClass {
  public ErrorMessageConstant = ErrorMessageConstant;
  public ApplicantLabelConstants = ApplicantLabelsConstants;

  constructor(protected summaryData: SummaryData,
    protected quickQuoteService: QuickQuoteService,
    protected reportService: ReportsService,
    protected propertyData: PropertyData,
    public applicantData: ApplicantData,
    protected namePipe: NameCasePipe,
    public previousApplicantValues: PreviousApplicantValuesService,
    protected applicantValidationServices: ApplicantValidationService,
    protected entityRiskData: EntityRiskData,
    public policySummaryData: PolicySummaryData,
    public applicantBusinessRules: ApplicantBusinessRules,
    protected zipCodeData: ZipCodeData,
    protected coveragesData: CoveragesData,
    protected thirdPartyData: ThirdPartyData,
    protected thirdPartyService: ThirdPartyDataService,
    protected toastr: ToastrService,
    public policyIssueData: PolicyIssueData,
    protected raterPremiumService: RaterPremiumService,
    protected claimsData: ClaimsData,
    protected submissionData: SubmissionPageData,
    protected authService: AuthService,
    protected uwApprovalData: UwApprovalData,
    protected store: Store
  ) {
      super();
  }

  async saveApplicant() {
    try {
      this.store.dispatch(updateApplicantsIsLoadingFromApplicantsSavingData({ isLoading: true }));
      this.entityRiskData.startSavingForms();
      this.quickQuoteService.setCategoryToSavingState(SavingFlagConstants.applicant);
      this.entityRiskData.isApiCallCompleted.next(false);
      const regex = RegexConstants.phoneValidation;
      const isSubmission = !this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId());
      const hasAgreed = this.applicantData.fcraReportingActForm.get('fcraReportingAct').value;

      const homePhoneTest = regex.test(this.applicantData.contact.get('homePhoneNumber').value);
      const mobilePhoneTest = regex.test(this.applicantData.contact.get('mobilePhoneNumber').value);
      const homePhone = this.applicantData.contact.get('homePhoneNumber')?.value;
      const mobilePhone = this.applicantData.contact.get('mobilePhoneNumber')?.value;

      let entityId: string, riskId: string, riskDetailId: string;
      if (this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId())) {
        entityId = this.entityRiskData.getEntityId();
        riskId = this.entityRiskData.getRiskId();
        riskDetailId = this.entityRiskData.getRiskDetailId();
      } else {
        entityId = this.summaryData.SummaryForm.get('entityId').value;
        riskId = this.summaryData.SummaryForm.get('riskId').value;
        riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;
      }
        this.processSaving(entityId, riskId, riskDetailId);
    } catch (error) {
      this.entityRiskData.finishSavingForms();
      this.quickQuoteService.setCategoryToNotSavingState(SavingFlagConstants.applicant);
      this.store.dispatch(updateApplicantsIsLoadingFromApplicantsSavingData({ isLoading: false }));
      console.log(error); // for error logging
    }
  }

  async saveInspection() {
    try {
      const inspectionOrderDate = this.applicantData.inspection.get('inspectionOrderDate').value?.singleDate?.jsDate;
      const inspectionReceiptDate = this.applicantData.inspection.get('inspectionReceiptDate').value?.singleDate?.jsDate;
      const orderedDate: any = inspectionOrderDate == null || inspectionOrderDate === '' ? null : new Date(inspectionOrderDate).toLocaleDateString('en-US');
      const receivedDate: any = inspectionReceiptDate == null || inspectionReceiptDate === '' ? null : new Date(inspectionReceiptDate).toLocaleDateString('en-US');

      const inspectionOrderingDTO: InspectionOrderingDTO = {
        id: this.applicantData.inspectionOrdering?.id,
        riskId: this.entityRiskData.getRiskId(),
        inspectionOrderingTypeCode: this.applicantData.inspection.get('inspectionType').value,
        isOrdered: this.applicantData.inspection.get('inspectionOrdered').value,
        orderedDate: orderedDate,
        isReceived: this.applicantData.inspection.get('inspectionReceived').value,
        receivedDate: receivedDate,
        policyNumber: this.entityRiskData.getPolicyNumber()
      };

      Utils.blockUI();

      if (this.applicantData.inspectionOrdering) {
        this.reportService.putInspectionOrdering(inspectionOrderingDTO).pipe(takeUntil(this.stop$)).subscribe(_ => {
          Utils.unblockUI();
        });
      } else {
        this.reportService.postInspectionOrdering(inspectionOrderingDTO).pipe(takeUntil(this.stop$)).subscribe(res => {
          inspectionOrderingDTO.id = res;
          Utils.unblockUI();
        });
      }

      this.applicantData.inspection.markAsPristine();
      this.applicantData.inspectionOrdering = inspectionOrderingDTO;
    } catch (error) {
      Utils.unblockUI();
    }
  }

  processSaving(entityId: string, riskId: string, riskDetailId: string) {
    const isSameAsQQAddress = this.applicantData.mainApplicant.get('isApplicantsMailingAddressSameAsInsuredLocation').value;
    const zipCode = isSameAsQQAddress ? this.applicantData.mainApplicant.get('qqZipCode')?.value : this.applicantData.mainApplicant.get('zipCode').value ?? 0;
    const county = this.propertyData.locationDetails.get('county').value;
    const countyAddress = Array.isArray(county) ? county[0] : county;

    const quickQuoteAddress: ApplicantEntityAddressDTO = {
      addressTypeCode: this.ApplicantLabelConstants.addressType.mailingAddress
    };
    if (this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId())) {
      const policyAddress = this.entityRiskData.getEntityAddress();
      quickQuoteAddress.address = new ApplicantAddressDTO({
        streetAddress1: policyAddress.address.streetAddress1,
        streetAddress2: policyAddress.address.streetAddress2,
        zipCode: Number(policyAddress.address.zipCode),
        city: policyAddress.address.city,
        state: policyAddress.address.state,
        county: policyAddress.address.county,
        countryCode: this.applicantData.mainApplicant.get('country')?.value
      });
    } else {
      quickQuoteAddress.address = new ApplicantAddressDTO({
        streetAddress1: this.applicantData.mainApplicant.get('addressLine1').value,
        streetAddress2: this.applicantData.mainApplicant.get('addressLine2').value,
        zipCode: this.applicantData.mainApplicant.get('qqZipCode')?.value,
        city: this.applicantData.mainApplicant.get('qqCity')?.value?.toLowerCase() === this.entityRiskData.getPropertyAddress()?.city?.toLowerCase() ?
          this.entityRiskData.getPropertyAddress()?.city : this.applicantData.mainApplicant.get('qqCity').value, // to avoid policy changes for case sensitivity in IPX data,
        state: this.applicantData.mainApplicant.get('qqState')?.value,
        county: this.applicantData.mainApplicant.get('mainApplicantCounty')?.value,
        countryCode: this.applicantData.mainApplicant.get('country')?.value
      });

      this.setToPreviousValuesIfEmpty();
    }

    const city = this.applicantData.mainApplicant.get('city')?.value;
    const state = this.applicantData.mainApplicant.get('state')?.value;

    const applicantAddress: ApplicantEntityAddressDTO = {
      addressTypeCode: this.ApplicantLabelConstants.addressType.mailingAddress,
      address: new ApplicantAddressDTO({
        streetAddress1: this.applicantData.mainApplicant.get('mailingAddress').value,
        streetAddress2: this.applicantData.mainApplicant.get('aptUnit').value,
        zipCode: zipCode ? zipCode : 0,
        city: this.isNullOrWhiteSpace(city) ? city : 'NA',
        state: this.isNullOrWhiteSpace(state) ? state : 'NA',
        county: this.applicantData.mainApplicant.get('mainApplicantCounty')?.value,
        countryCode: this.applicantData.mainApplicant.get('country')?.value
      })
    };

    const birthDate = new Date(this.applicantData.mainApplicant.get('dateOfBirth').value?.singleDate?.jsDate);
    const age = this.applicantBusinessRules.calculateInsuredAge(birthDate);
    const isRetiredGreaterOrEqualTo60 = this.applicantBusinessRules.isInAgeOfRetirementBRULCOV20(age);
    const isRetired = this.applicantBusinessRules.isInAgeOfRetirement(age) ? this.applicantData.mainApplicant.get('isRetiredYesNo').value : false;
    let applicantDateOfBirth;
    if ((!this.applicantData.mainApplicant.get('dateOfBirth')?.valid && age > 18) || birthDate.toString() === 'Invalid Date') {
      applicantDateOfBirth = new Date(this.applicantData.validDateOfBirth).toLocaleDateString('en-US');
    } else {
      applicantDateOfBirth = new Date(this.applicantData.mainApplicant.get('dateOfBirth').value?.singleDate?.jsDate).toLocaleDateString('en-US');
    }
    const applicantPayload: CoApplicantEntityDTO = {
      id: entityId,
      firstName: this.namePipe.transform(this.applicantData.mainApplicant.get('firstName').value),
      middleName: !this.applicantData.mainApplicant.get('middleInitial').value ? null : this.namePipe.transform(this.applicantData.mainApplicant.get('middleInitial').value),
      nameSuffix: this.namePipe.transform(this.applicantData.mainApplicant.get('nameSuffix').value),
      lastName: this.namePipe.transform(this.applicantData.mainApplicant.get('lastName').value),
      birthDate: applicantDateOfBirth,
      isRetired: isRetiredGreaterOrEqualTo60 ? true : isRetired,
      maritalStatusCode: this.applicantData.mainApplicant.get('maritalStatusApplicant').value ? this.applicantData.mainApplicant.get('maritalStatusApplicant').value : '',
      isMailingAddressSameInsured: this.applicantData.mainApplicant.get('isApplicantsMailingAddressSameAsInsuredLocation').value,
      homePhone: this.applicantData.contact.get('homePhoneNumber').valid ? this.applicantData.contact.get('homePhoneNumber').value : '',
      mobilePhone: this.applicantData.contact.get('mobilePhoneNumber').valid ? this.applicantData.contact.get('mobilePhoneNumber').value : '',
      entityAddresses: this.applicantData.mainApplicant.get('isApplicantsMailingAddressSameAsInsuredLocation').value
                      ? null
                      : this.isInsuredMailingAddressValid() ? [applicantAddress] : this.setInsuredMailingAddress(),
      fCRAAcknowledgement: this.policySummaryData.isPolicy ? true : this.validateAcknowledgement(),
      creditScore: this.coveragesData?.creditScore,
      creditScoreStatusCode: this.coveragesData?.creditOrderStatusCode,
      creditScoreDateOrdered: this.coveragesData?.creditScoreDateOrdered,
      estimatedCredit: this.coveragesData?.estimatedCode ?? '',
      lexisReferenceNumber: this.coveragesData?.lexisReferenceNumber,
      claimsTier: this.coveragesData?.creditPriorClaimTier
    };

    if (!this.applicantData.contact.get('emailAddress').errors?.pattern) {
      applicantPayload.personalEmailAddress = this.applicantData.contact.get('emailAddress').value;
      this.applicantData.validAddress = this.applicantData.contact.get('emailAddress').value;
    } else {
      applicantPayload.personalEmailAddress = this.applicantData.validAddress;
      this.applicantData.validAddress = this.applicantData.validAddress;
    }

    if (!this.applicantData.contact.get('homePhoneNumber').invalid) {
      applicantPayload.homePhone = this.applicantData.contact.get('homePhoneNumber').value;
      this.applicantData.validHomePhone = this.applicantData.contact.get('homePhoneNumber').value;
    } else {
      applicantPayload.homePhone = this.applicantData.validHomePhone;
      this.applicantData.validHomePhone = this.applicantData.validHomePhone;
    }

    if (!this.applicantData.contact.get('mobilePhoneNumber').errors?.pattern) {
      applicantPayload.mobilePhone = this.applicantData.contact.get('mobilePhoneNumber').value;
      this.applicantData.validMobilePhone = this.applicantData.contact.get('mobilePhoneNumber').value;
    } else {
      applicantPayload.mobilePhone = this.applicantData.validMobilePhone;
      this.applicantData.validMobilePhone = this.applicantData.validMobilePhone;
    }

    const coAppCity = this.applicantData.coApplicant.get('coApplicantSameAsApplicantCity').value;
    const coBaseState = this.applicantData.coApplicant.get('coApplicantSameAsApplicantState').value;
    const coAppZipCode = this.applicantData.coApplicant.get('coApplicantSameAsApplicantZipCode').value;
    const isThereACoApplicant = this.applicantData.coApplicant.get('isThereACoApplicant').value;
    const isCoApplicantDataValid = this.applicantValidationServices.checkCoApplicantSection();
    let coApplicantPayload: CoApplicantEntityDTO2 = null;
    if (isThereACoApplicant && isCoApplicantDataValid) {
      this.zipCodeData.selectedCity = this.applicantData.coApplicant.get('coApplicantSameAsApplicantCity').value;
      const isSameAddress = this.applicantData.coApplicant.get('isCoApplicantsMailingAddressSameAsApplicantsMailingAddress').value;
      const coApplicantEntityAddress: CoApplicantEntityAddressDTO = {
        addressTypeCode: this.ApplicantLabelConstants.addressType.mailingAddress,
        address: new CoApplicantAddressDTO({
          streetAddress1: this.applicantData.coApplicant.get('coApplicantSameAsApplicantMailingAddress').value,
          streetAddress2: this.applicantData.coApplicant.get('coApplicantSameAsApplicantAptUnit').value,
          countryCode: this.applicantData.coApplicant.get('coApplicantSameAsApplicantCountry').value,
          county: this.applicantData.coApplicant.get('coApplicantCounty').value,
          zipCode: this.isNullOrWhiteSpace(coAppZipCode) ? coAppZipCode : 0,
          city: this.isNullOrWhiteSpace(coAppCity) ? coAppCity : 'NA',
          state: this.isNullOrWhiteSpace(coBaseState) ? coBaseState : 'NA'
        })
      };

      const birthDate_CoApplicant = new Date(this.applicantData.coApplicant.get('coApplicantDateOfBirth').value?.singleDate?.jsDate?.toLocaleDateString('en-US'));
      const age_CoApplicant = this.applicantBusinessRules.calculateInsuredAge(birthDate_CoApplicant);
      const isRetiredGreaterOrEqualTo60_CoApplicant = this.applicantBusinessRules.isInAgeOfRetirementBRULCOV20(age_CoApplicant);
      const isRetired_CoApplicant = this.applicantBusinessRules.isInAgeOfRetirement(age_CoApplicant) ? this.applicantData.coApplicant.get('coApplicantisRetiredYesNo').value : false;
      const coApplicantDateOfBirth: any = new Date(this.applicantData.coApplicant.get('coApplicantDateOfBirth').value?.singleDate?.jsDate).toLocaleDateString('en-US');
      coApplicantPayload = {
        firstName: this.namePipe.transform(this.applicantData.coApplicant.get('coApplicantFirstName').value),
        middleName: !this.applicantData.coApplicant.get('coApplicantMiddleInitial').value ? null : this.namePipe.transform(this.applicantData.coApplicant.get('coApplicantMiddleInitial').value),
        lastName: this.namePipe.transform(this.applicantData.coApplicant.get('coApplicantLastName').value),
        birthDate: coApplicantDateOfBirth,
        isRetired: isRetiredGreaterOrEqualTo60_CoApplicant ? true : isRetired_CoApplicant,
        maritalStatusCode: !this.applicantData.coApplicant.get('coApplicantMaritalStatus').value ? null : this.applicantData.coApplicant.get('coApplicantMaritalStatus').value,
        isCoAppMailAddrAsAppMailAddr: this.applicantData.coApplicant.get('isCoApplicantsMailingAddressSameAsApplicantsMailingAddress').value,
        applicantRelationshipCode: this.applicantData.coApplicant.get('coApplicantRelationship').value,
        creditScoreStatusId: this.applicantData.coApplicant.get('creditScoreStatus')?.value ? this.applicantData.coApplicant.controls['creditScoreStatus'].value : 0,
        creditScoreDateOrdered: this.applicantData.coApplicant.get('creditScoreOrdered')?.value,
        entityAddresses: isSameAddress ? null : [coApplicantEntityAddress],
        isActive: this.applicantData.coApplicant.get('isThereACoApplicant').value ? true : false
      };
      this.saveNewCoEntityForEntityRisk(coApplicantPayload);
    } else if (isThereACoApplicant && !isCoApplicantDataValid && this.entityRiskData.EntityRisk && this.entityRiskData.EntityRisk.coEntity) {
      this.zipCodeData.selectedCity = this.entityRiskData.EntityRisk.coEntity.entityAddresses[0]?.address.city;
      const coApplicantAddresses: CoApplicantEntityAddressDTO = {
        addressTypeCode: this.ApplicantLabelConstants.addressType.mailingAddress,
        address: new CoApplicantAddressDTO({
          streetAddress1: this.entityRiskData.EntityRisk.coEntity.entityAddresses[0]?.address.streetAddress1,
          streetAddress2: this.entityRiskData.EntityRisk.coEntity.entityAddresses[0]?.address.streetAddress2,
          countryCode: this.entityRiskData.EntityRisk.coEntity.entityAddresses[0]?.address.countryCode,
          county: this.entityRiskData.EntityRisk.coEntity.entityAddresses[0]?.address.county,
          zipCode: Number(this.entityRiskData.EntityRisk.coEntity.entityAddresses[0]?.address.zipCode),
          city: this.entityRiskData.EntityRisk.coEntity.entityAddresses[0]?.address.city,
          state: this.entityRiskData.EntityRisk.coEntity.entityAddresses[0]?.address.state
        }),
      };

      coApplicantPayload = {
        firstName: this.entityRiskData.EntityRisk.coEntity.firstName,
        middleName: this.entityRiskData.EntityRisk.coEntity.middleName,
        lastName: this.entityRiskData.EntityRisk.coEntity.lastName,
        birthDate: this.entityRiskData.EntityRisk.coEntity.birthDate,
        maritalStatusCode: this.entityRiskData.EntityRisk.coEntity.maritalStatusCode,
        applicantRelationshipCode: this.entityRiskData.EntityRisk.coEntity.applicantRelationshipCode,
        isCoAppMailAddrAsAppMailAddr: this.isCoApplicantMailingAddressValid() ? this.entityRiskData.EntityRisk.coEntity.isCoAppMailAddrAsAppMailAddr : true,
        creditScoreStatusCode: this.entityRiskData.EntityRisk.coEntity.creditScoreStatusCode,
        creditScoreDateOrdered: this.entityRiskData.EntityRisk.coEntity.creditScoreDateOrdered,
        entityAddresses: this.entityRiskData.EntityRisk.coEntity.isCoAppMailAddrAsAppMailAddr ? null : this.isCoApplicantMailingAddressValid() ? [coApplicantAddresses] : null,
        isActive: this.entityRiskData.EntityRisk.coEntity.isActive,
        isRetired: false,
      };
      this.repopulateCurrentCoApplicant(coApplicantPayload);
    } else {
      coApplicantPayload = null;
      this.applicantData.coApplicant.get('isThereACoApplicant').setValue(false);
      this.applicantData.setToDefaultCoApplicant();
      this.applicantData.setToDefaultMailingAddress();
      if (!this.applicantData.copiedInformation && !this.entityRiskData.isNewQuote) {
        this.entityRiskData.EntityRisk.coEntity = null;
      }
    }

    const saveApplicantRequest: SaveApplicantDTO = {
      riskId: this.summaryData.SummaryForm.get('riskId').value,
      riskDetailId: this.summaryData.SummaryForm.get('riskDetailId').value,
      applicant: applicantPayload,
      coApplicant: coApplicantPayload
    };

    if (riskId && riskDetailId) {
      if(!this.applicantData.isLexisCalled) {
        Utils.blockUI();
      }

      this.policySummaryData.saveApplicantComplete$.next(false);

      this.quickQuoteService.postApplicant(saveApplicantRequest).pipe(takeUntil(this.stop$)).subscribe(res => {
        this.quickQuoteService.setCategoryToNotSavingState(SavingFlagConstants.applicant);
        this.entityRiskData.finishSavingForms();
        this.setHeaderDetails();
        this.entityRiskData.updateEntityRiskDataApplicant(saveApplicantRequest.applicant, res);

        if (this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId())) {
          this.policySummaryData.subStatusCode$.next(res.riskSubStatusCode as RiskStatusCode);
        }

        if (this.summaryData.creditOrderErrorCode === ErrorMessageConstant.CreditService.CS02ErrorCode ||
          this.summaryData.creditOrderErrorCode === ErrorMessageConstant.CreditService.CE01ErrorCode) {
          this.summaryData.creditOrderErrorCode = '';
        }

        if (this.summaryData.raterData.isViewQuoteProposalClicked) {
          this.summaryData.raterData.recalculatePremiumAndSave$.next(true);
          this.summaryData.raterData.isViewQuoteProposalClicked = false;
        }

        this.summaryData.raterData.applicantRaterRequest(this.coveragesData);
        this.checkNameInsured();
        this.applicantData.isfcraAcknowledgement = saveApplicantRequest?.applicant?.fCRAAcknowledgement;

        if (!this.thirdPartyData.isCurrentlyOrdering) {
          Utils.unblockUI();
        }

        this.entityRiskData.isApiCallCompleted.next(true);
        this.policySummaryData.saveApplicantComplete$.next(true);
        this.submissionData.applicantSavingComplete.next();
        this.store.dispatch(updateApplicantsIsLoadingFromApplicantsSavingData({ isLoading: false }));
      },
        err => {
          Utils.unblockUI();
          this.quickQuoteService.setCategoryToNotSavingState(SavingFlagConstants.applicant);
          this.entityRiskData.isApiCallCompleted.next(false);
          this.entityRiskData.finishSavingForms();
          this.store.dispatch(updateApplicantsIsLoadingFromApplicantsSavingData({ isLoading: false }));

          NotifUtils.showError(this.setErrorMessage(JSON.parse(err.error).errors));
        },() => {
          if(!this.applicantData.isLexisCalled) {
            if (!this.thirdPartyData.isCurrentlyOrdering) {
              Utils.unblockUI();
            }
            this.applicantData.isLexisCalled = false;
          }
        }
      );
    }
  }

  public setErrorMessage(errors: any[]): string {
    let errorMessage: string = '';

    if (errors.length > 1) {
      errors.map(error => {
        errorMessage = errorMessage === '' ? `${error}` : `${errorMessage}<br />${error}`;
      });

      return `<p>${errorMessage}</p>`;
    }

    errorMessage = errors[0];

    return `<p>${errorMessage}</p>`;
  }

  public validateAcknowledgement(): boolean {
    const agreed = this.applicantData.mainApplicant.get('isOrderYesNo').value && this.applicantData.isPrefillClicked ? false : this.applicantData.fcraReportingActForm.get('fcraReportingAct').value;
    return this.coveragesData?.creditOrderStatusCode > LvCreditOrderStatus[0].code ? agreed : false;
  }

  setToPreviousValuesIfEmpty(): void  {

    const values = this.previousApplicantValues.Applicant;

    if (this?.applicantData?.mainApplicant?.get('firstName')?.value !== '' && this?.applicantData?.mainApplicant?.get('lastName')?.value !== '' &&
        this?.applicantData?.mainApplicant?.get('dateOfBirth')?.value != null && this?.applicantData?.mainApplicant?.get('dateOfBirth')?.value !== undefined) {
          return;
    }

    if (this?.applicantData?.mainApplicant?.get('firstName')?.value !== '') {
      this.previousApplicantValues.Applicant.firstName = this?.applicantData?.mainApplicant?.get('firstName')?.value;
    }

    if (this?.applicantData?.mainApplicant?.get('lastName')?.value !== '') {
      this.previousApplicantValues.Applicant.lastName = this?.applicantData?.mainApplicant?.get('lastName')?.value;
    }

    if (this?.applicantData?.mainApplicant?.get('dateOfBirth')?.value != null && this?.applicantData?.mainApplicant?.get('dateOfBirth')?.value !== undefined) {
      this.previousApplicantValues.Applicant.dateOfBirth = this?.applicantData?.mainApplicant?.get('dateOfBirth')?.value?.singleDate?.jsDate;
    }

    const firstName = this?.applicantData?.mainApplicant?.get('firstName')?.value !== '' ? this?.applicantData?.mainApplicant?.get('firstName').value : this.previousApplicantValues.Applicant.firstName;
    const lastName = this?.applicantData?.mainApplicant?.get('lastName')?.value !== '' ? this?.applicantData?.mainApplicant?.get('lastName').value : this.previousApplicantValues.Applicant.lastName;
    const dateOfBirth = this?.applicantData?.mainApplicant?.get('dateOfBirth')?.value !== null && this?.applicantData?.mainApplicant?.get('dateOfBirth')?.value?.singleDate?.jsDate !== undefined ?
                        this.applicantData?.mainApplicant?.get('dateOfBirth')?.value?.singleDate?.jsDate : this.previousApplicantValues.Applicant.dateOfBirth;

    this.applicantData.mainApplicant.get('firstName').setValue(firstName);
    this.applicantData.mainApplicant.get('lastName').setValue(lastName);
    this.applicantData.mainApplicant.get('dateOfBirth').setValue({ isRange: false, singleDate: { jsDate: new Date(dateOfBirth) } });

  }

  isInsuredMailingAddressValid(): boolean {
    return  (this.applicantData.mainApplicant.get('mailingAddress').valid && this.applicantData.mainApplicant.get('mailingAddress').value !== '')
      && (this.applicantData.mainApplicant.get('zipCode').valid)
      && (this.applicantData.mainApplicant.get('city').status === 'VALID' || this.applicantData.mainApplicant.get('city').status === 'DISABLED')
      && (this.applicantData.mainApplicant.get('state').status === 'VALID' || this.applicantData.mainApplicant.get('state').status === 'DISABLED')
      && ((this.applicantData.mainApplicant.get('country').valid && this.applicantData.mainApplicant.get('country').value !== ''));
  }

  isCoApplicantSectionValid() {
    const isCoAppMailAddrAsAppMailAddr = this.applicantData.coApplicant.get('isCoApplicantsMailingAddressSameAsApplicantsMailingAddress').value;
    if (isCoAppMailAddrAsAppMailAddr) {
      return (this.applicantData.coApplicant.get('coApplicantFirstName').valid && this.applicantData.coApplicant.get('coApplicantLastName').valid &&
        this.applicantData.coApplicant.get('coApplicantDateOfBirth').valid && this.applicantData.coApplicant.get('coApplicantMaritalStatus').valid &&
        this.applicantData.coApplicant.get('coApplicantRelationship').valid);
    } else {
      return this.applicantData.coApplicant.valid;
    }
  }

  setInsuredMailingAddress(): any {
    this.applicantData.mainApplicant.get('isApplicantsMailingAddressSameAsInsuredLocation').setValue(true);
    this.applicantData.setToDefaultInsuredMailingAddress();

    return null;
  }

  isMailingAddressValid() {
    const isApplicantsMailingAddressSameAsInsuredLocation = this.applicantData.mainApplicant.get('isApplicantsMailingAddressSameAsInsuredLocation').value;
    if (isApplicantsMailingAddressSameAsInsuredLocation) {
      return (this.applicantData.mainApplicant.get('mailingAddress').valid && this.applicantData.mainApplicant.get('zipCode').valid &&
        this.applicantData.mainApplicant.get('city').valid && this.applicantData.mainApplicant.get('state').valid);
    } else {
      return this.applicantData.mainApplicant.valid;
    }
  }

  isCoApplicantDetailsValid() {
    return (this.applicantData.coApplicant.get('coApplicantFirstName').valid && this.applicantData.coApplicant.get('coApplicantLastName').valid &&
      this.applicantData.coApplicant.get('coApplicantDateOfBirth').valid && this.applicantData.coApplicant.get('coApplicantMaritalStatus').valid &&
      this.applicantData.coApplicant.get('coApplicantRelationship').valid);
  }

  isCoApplicantMailingAddressValid() {
   return (this.applicantData.coApplicant.get('coApplicantSameAsApplicantMailingAddress').valid)
    && (this.applicantData.coApplicant.get('coApplicantSameAsApplicantCity').valid)
    && (this.applicantData.coApplicant.get('coApplicantSameAsApplicantState').valid)
    && (this.applicantData.coApplicant.get('coApplicantSameAsApplicantZipCode').valid)
    && (this.applicantData.coApplicant.get('coApplicantSameAsApplicantCountry').valid);
  }

  setHeaderDetails() {
    this.summaryData.SummaryForm.get('name').setValue(this.namePipe.transform(this.applicantData.name));
    if (!this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId())) {
      this.summaryData.SummaryForm.get('address').setValue(this.applicantData.address);
    }
  }

  repopulateCurrentCoApplicant(coEntity: CoApplicantEntityDTO2) {
    this.applicantData.coApplicant.get('isThereACoApplicant').setValue(true);
    this.applicantData.coApplicant.get('coApplicantFirstName').setValue(this.namePipe.transform(coEntity.firstName));
    this.applicantData.coApplicant.get('coApplicantMiddleInitial').setValue(this.namePipe.transform(coEntity.middleName));
    this.applicantData.coApplicant.get('coApplicantDateOfBirth').setValue({ isRange: false, singleDate: { jsDate: new Date(coEntity?.birthDate) } });
    this.applicantData.coApplicant.get('coApplicantLastName').setValue(this.namePipe.transform(coEntity.lastName));
    this.applicantData.coApplicant.get('coApplicantMaritalStatus').setValue(LvMaritalStatuses.find(m => m.code === coEntity?.maritalStatusCode)?.code ?? '');
    this.applicantData.coApplicant.get('coApplicantRelationship').setValue(coEntity.applicantRelationshipCode);
    this.applicantData.coApplicant.get('isCoApplicantsMailingAddressSameAsApplicantsMailingAddress').setValue(coEntity?.isCoAppMailAddrAsAppMailAddr);
    this.applicantData.coApplicant.get('coApplicantSameAsApplicantMailingAddress').setValue(coEntity?.entityAddresses ? coEntity?.entityAddresses[0]?.address.streetAddress1 : '');
    this.applicantData.coApplicant.get('coApplicantSameAsApplicantAptUnit').setValue(coEntity?.entityAddresses ? coEntity?.entityAddresses[0]?.address.streetAddress2 : '');
    const coApplicantAddress = coEntity?.entityAddresses ? coEntity?.entityAddresses[0]?.address : '';
    this.applicantData.coApplicant.get('coApplicantSameAsApplicantZipCode').setValue(coApplicantAddress ? coApplicantAddress?.zipCode : '');
    this.applicantData.coApplicant.get('coApplicantSameAsApplicantCity').setValue(coApplicantAddress ? coApplicantAddress?.city : '');
    this.applicantData.coApplicant.get('coApplicantSameAsApplicantState').setValue(coApplicantAddress ? coApplicantAddress?.state : '');
    this.applicantData.coApplicant.get('creditScoreStatus')?.setValue(1);
    this.applicantData.coApplicant.get('creditScoreOrdered')?.setValue({ isRange: false, singleDate: { jsDate: new Date(coEntity?.creditScoreDateOrdered) } }); // TODO map data?.CreditScoreDateOrdered; datepicker
    this.applicantData.coApplicant.get('coApplicantSameAsApplicantCountry').setValue(coApplicantAddress ? coApplicantAddress?.countryCode : 'USA');
  }

  saveNewCoEntityForEntityRisk(newCoApplicant: CoApplicantEntityDTO2) {
    let coApplicantAddresses: EntityAddressDTO3 = null;
    if (newCoApplicant.entityAddresses) {
      coApplicantAddresses = {
        addressTypeCode: this.ApplicantLabelConstants.addressType.mailingAddress,
        address: new AddressDTO3({
          streetAddress1: newCoApplicant.entityAddresses[0]?.address.streetAddress1,
          streetAddress2: newCoApplicant.entityAddresses[0]?.address.streetAddress2,
          countryCode: newCoApplicant.entityAddresses[0]?.address.countryCode,
          county: newCoApplicant.entityAddresses[0]?.address.county,
          zipCode: newCoApplicant.entityAddresses[0]?.address.zipCode.toString(),
          city: newCoApplicant.entityAddresses[0]?.address.city,
          state: newCoApplicant.entityAddresses[0]?.address.state
        }),
      };
    }

    const newCoEntity: EntityRiskDTO = {
      firstName: newCoApplicant.firstName,
      middleName: newCoApplicant.middleName,
      lastName: newCoApplicant.lastName,
      birthDate: newCoApplicant.birthDate,
      maritalStatusCode: newCoApplicant.maritalStatusCode,
      applicantRelationshipCode: newCoApplicant.applicantRelationshipCode,
      isCoAppMailAddrAsAppMailAddr: newCoApplicant.isCoAppMailAddrAsAppMailAddr,
      creditScoreStatusCode: newCoApplicant.creditScoreStatusCode,
      creditScoreDateOrdered: newCoApplicant.creditScoreDateOrdered,
      entityAddresses: [coApplicantAddresses],
      coEntity: null
    };

    if (!this.entityRiskData.isNewQuote) {
      this.entityRiskData.EntityRisk.coEntity = newCoEntity;
    }
  }

  isNullOrWhiteSpace(data: string): boolean {
    if (data) {
      return /\S/.test(data);
    }
    return false;
  }

  checkNameInsured(): void {
    if (this.policySummaryData.isPolicy) {
      const previousValues = this.previousApplicantValues?.Applicant;
      const nameInsured = this.applicantData.mainApplicant;
      const isNameInsuredChanged: boolean =
        (previousValues?.firstName ? previousValues?.firstName : '') !== (nameInsured.get('firstName').value ? nameInsured.get('firstName').value?.trim() : '')
        || (previousValues?.middleInitial ? previousValues?.middleInitial : '') !== (nameInsured.get('middleInitial').value ? nameInsured.get('middleInitial').value?.trim() : '')
        || (previousValues?.lastName ? previousValues?.lastName : '') !== (nameInsured.get('lastName').value ? nameInsured.get('lastName').value?.trim() : '')
        || (previousValues?.nameSuffix ? previousValues?.nameSuffix : '') !== (nameInsured.get('nameSuffix').value ? nameInsured.get('nameSuffix').value : '');

      if (this.authService.userType.value === GenericConstants.userType.internal && isNameInsuredChanged) {
        this.uwApprovalData.isUWR129Triggered = false;
        this.uwApprovalData.isUWR129TriggeredByExternal = false;
      } else if (this.authService.userType.value === GenericConstants.userType.external && isNameInsuredChanged) {
        this.uwApprovalData.isUWR129TriggeredByExternal = true;
      } else {
        this.uwApprovalData.isUWR129TriggeredByExternal = false;
      }
    }
  }
}
