export const InfoMessageConstant = {
  excludedZipCodeList: 'Flood Coverage Endorsement is not available due to location.',
  iso360NoHitAddressMessage: 'Address could not be validated. Prior Underwriting approval will be required to issue. If address is incorrect, please start new quote.',
  savedSuccessfullyMessage: 'saved successfully.',
  noDataMessage: 'No Data To Display',
  unsavedChangesMessage: 'You have unsaved changes that will be lost',
  generationDocumentMessage: 'The document you are viewing is not yet available. Generation is still in progress. Please try again later.',
  downloadGenerationDocumentMessage: 'The document you are downloading is not yet available. Generation is still in progress. Please try again later.',
  policyNumberSaved: 'Policy number saved successfully.',
  NEW23InfoMessage: 'Identical claim detected. Duplication of claim is not permitted.',
  QuickQuote: {
    resetOrderCredit: 'Changing the applicant information requires ordering of credit again.'
  },
  endorsementNotAllowedMessage: 'Endorsement is not allowed for cancelled policies.'
};
