import { createSelector } from '@ngrx/store';
import { BaseState } from '../base.state';

export const selectClaimsState = (state: BaseState) => state.claims;

export const selectClaimsIsLoading = createSelector(
  selectClaimsState,
  (state) => state.isLoading
);

export const selectClaimsList = createSelector(
  selectClaimsState,
  (state) => state.list
);