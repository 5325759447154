import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BatchPaymentsComponent } from './batch-payments.component';
import { AppBreadcrumbModule, AppSidebarModule } from '@coreui/angular';
import { BatchPaymentsRoutingModule } from './batch-payments-routing.module';
import { PendingBatchPaymentsModule } from './pending-batch-payments/pending-batch-payments.module';
import { ProcessedBatchPaymentsModule } from './processed-batch-payments/processed-batch-payments.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap';
import { SharedModule } from '../../../shared/shared.module';
import { BatchPaymentsData } from '../data/batch-payments.data';
import { BatchPaymentsDetailsModalComponent } from './batch-payments-modals/batch-payments-details-modal/batch-payments-details-modal.component';
import { BatchSuspensePaymentDetailsModalComponent } from './batch-payments-modals/batch-suspense-payment-details-modal/batch-suspense-payment-details-modal.component';
import { UploadBatchPaymentModalComponent } from './batch-payments-modals/upload-batch-payment-modal/upload-batch-payment-modal.component';
import { BatchPaymentRelatedDocComponent } from '../suspended-payments/shared/batch-payment-related-doc/batch-payment-related-doc.component';



@NgModule({
  declarations: [
    BatchPaymentsComponent,
    BatchPaymentsDetailsModalComponent,
    BatchSuspensePaymentDetailsModalComponent,
    UploadBatchPaymentModalComponent,
    BatchPaymentRelatedDocComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppSidebarModule,
    AppBreadcrumbModule.forRoot(),
    TabsModule.forRoot(),
    SharedModule,
    BatchPaymentsRoutingModule,
    PendingBatchPaymentsModule,
    ProcessedBatchPaymentsModule
  ],
  providers: [
    BatchPaymentsData
  ],
  entryComponents: [
    BatchPaymentsDetailsModalComponent,
    BatchSuspensePaymentDetailsModalComponent,
    UploadBatchPaymentModalComponent,
    BatchPaymentRelatedDocComponent
  ]
})
export class BatchPaymentsModule { }
