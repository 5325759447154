export const InterestLabelsConstants = {
  saveAnInterest: 'Save',
  interestType: 'Interest Type',
  interestName: 'Interest Name',
  interestName2: 'Interest Name 2',
  refLoanNumber: 'Loan Number',
  rank: 'Rank',
  descriptionOfInterest: 'Description of Interest',
  street: 'Street',
  mailingAddress: 'Mailing Address',
  mailingAddress2: 'Mailing Address 2',
  zipCode: 'Zip Code',
  city: 'City',
  state: 'State',
  priorInsurance: 'Prior Insurance?',
  priorCarrier: 'Prior Carrier',
  priorExpirationDate: 'Prior Expiration Date',
  addInterest: 'Add Interest',
  editInterest: 'Save',
  interestTypeColumn : 'interestType',
  interestNameColumn : 'interestName',
  interestName2Column :'interestName2',
  mailingAddressColumn : 'mailingAddress',
  zipCodeColumn : 'zipCode',
  cityColumn : 'city',
  stateColumn : 'state',
  refLoanNumberColumn : 'refLoanNumber',
  rankColumn : 'rank',
  descriptionOfInterestColumn : 'descriptionOfInterest',
  priorCarrierList: {
    PI0: 'PI0'
  },
  isIndividualYesNo: 'Is this an individual?',
  firstName: 'First Name',
  lastName: 'Last Name',
  suffix: 'Suffix',
  multipleRank1MortNotAllowedMessage: 'Only one First Mortgagee is allowed on a policy at a time. Please remove one or change the rank.',
  country: 'Country'
};
