export const PageHeaderSummaryConstants = {
    formType: 'HO3',
    policyStatusActive: 'Active',
    policyStatusPending: 'Pending',
    policyStatusCancelled: 'Cancelled',
    policyStatusPolicyExpired: 'Policy Expired',
    policyStatusPendingEndorsement: 'Pending Endorsement',
    policyStatus: {
      PendingCancellation: 'Pending Cancellation',
      ApprovedReinstatement: 'Approved for Reinstatement',
      RenewalOffered: 'Renewal Offered'
    },
    Header: {
      FormType: 'Form Type',
      EffectiveDate: 'Effective Date',
      PolicyNumber: 'Policy Number',
      QuoteNumber: 'Quote #',
      Agency: 'Agency',
      Code: 'Code',
      AgencyStatus: 'Agency Status',
      CoverageA: 'Coverage A',
      CoverageC: 'Coverage C',
      CreditOrderStatus: 'Credit Order Status',
      EstimatedPremium: 'Estimated Premium',
      Premium: 'Premium',
      Taxes: 'Assessments',
      Fees: 'Fees',
      InsuredName: 'Insured Name',
      QuoteNumber2: 'Quote Number',
      RiskStatus: 'Quote Status',
      Actions: 'Actions',
      Cancel: 'Cancel',
      Rewrite: 'Rewrite',
      Copy: 'Copy',
      Reinstate: 'Reinstate',
      ReinstateForCorrection: 'Reinstate For Correction',
      Reinstatement: {
        Approve: 'Approved for Reinstatement',
        Reverse: 'Reverse Reinstatement Approval'
      },
      NonRenew: 'Non-Renew',
      RescindNonRenewal: 'Rescind Non-Renewal',
      ReverseNonRenewal: 'Reverse Non-Renewal',
      FlagRenewal: 'Flag for Changes at Renewal',
      UnflagRenewal: 'Unflag for Changes at Renewal',
      RenewalReinstatement: {
        Approve: 'Approve for Renewal Reinstatement'
      },
      RescindPendingCancellation: 'Rescind Pending Cancellation',
      RescindRenewalPending: 'Rescind Renewal Pending',
      RescindRenewalOffer: 'Rescind Renewal Offer',
      ReverseRescindRenewalPending: 'Reverse Rescind Renewal Pending',
      ReverseRescindRenewalOffer: 'Reverse Rescind Renewal Offer'
    },
    RewriteConfirmation: 'Rewriting this policy, Property Address and Primary Insured Name are not Editable. Are you sure you want to Rewrite?',
    ReinstateConfirmation: 'Are you sure you want to reinstate this policy?',
    CopyConfirmation: 'Are you sure you want to copy this policy?',
    policyRenewal: {
      subStatus: {
        CRW: 'Changes at Renewal',
        SFNRW: 'Scheduled for Non-Renewal',
        NRWD: 'Non-Renewed'
      },
      subStatusCode: {
        CRW: 'CRW',
        SFNRW: 'SFNRW',
        NRWD: 'NRWD',
        RNRW: 'RNRW',
        RVNRW: 'RVNRW'
      },
      status: {
        RNPEN: 'Renewal Pending',
        RWO: 'Renewal Offered',
        RRW: 'Rescinded Renewal Offer',
        RWNT: 'Renewal Not Taken',
        RWD: 'Renewed',
        RRNPEN: 'Revised Renewal Pending',
        RRWO: 'Revised Renewal Offered',
        PRRW: 'Rescinded Renewal Pending'
      },
      statusCode: {
        RNPEN: 'RNPEN',
        RWO: 'RWO',
        RRW: 'RRW',
        RWNT: 'RWNT',
        RWD: 'RWD',
        RRNPEN: 'RRNPEN',
        RRWO: 'RRWO',
        PRRW: 'PRRW'
      },
      rescindNonRenewalConfirmation: 'Confirm Rescission?',
      reverseNonRenewalConfirmation: 'Confirm Reversal?',
      nonRenewalPeriodPassedAlertTitle: 'Oops..',
      nonRenewalPeriodPassedAlertIcon: 'info',
      nonRenewalPeriodPassedAlertMessasge: 'Non-renewal compliance period has passed',
      renewalPeriod: 160,
      nonRenewalPeriod: 125,
      approveRenewalReinstatement: 'Approve Renewal Reinstatement?',
      approveRescindRenewalOffer: 'Approve Rescind Renewal Offer?',
      flagChangesInformationMessage: 'Please provide details for renewal change. Go to ‘Notes’ tab and add ‘renewal changes’ type note with detailed information on requested change(s).'
    },
    rescindPendingCancellationConfirmation: 'Confirm Rescission?'
};
