import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'laFortifiedForSaferHome'
})
export class LA_FortifiedForSaferHomePipe implements PipeTransform {

  transform(currentCondition: boolean = true, fortifiedForSaferHome, formGroup: FormGroup, fieldName: string): boolean {
    const SLVRGOLDNEWC = ['SLVR','GOLD','NEWC'];
    const openingProtection = 'openingProtection';
    const isBuildingCodeDiscount = 'isBuildingCodeDiscount';
    let isBRUL349 = false;

    switch(fieldName) {
      case openingProtection:
        isBRUL349 = !SLVRGOLDNEWC.includes(fortifiedForSaferHome);
        return isBRUL349;
        break;
      case isBuildingCodeDiscount:
        const isBRUL350 = fortifiedForSaferHome === undefined || fortifiedForSaferHome === '' || fortifiedForSaferHome === null;
        return isBRUL350;
    }

    return currentCondition;
  }
}
