import { Injectable } from '@angular/core';
import { RiskInterestDTO } from '../../../shared/models/data/dto/quick-quote/risk-interest.dto';
import { BaseClass } from '../../../shared/base-class';
import { SummaryData } from './summary.data';
import { InterestData } from './interest.data';
import { RiskInterestDetailDTO, RiskInterestDetailDTO2 } from '../../../shared/models/data/dto/quick-quote/risk-interest-detail.dto';
import { QuickQuoteService } from '../../../core/services/submission/quick-quote.service';
import { SaveInterestDTO } from '../../../shared/models/data/dto/quick-quote/save-interest.dto';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { PathConstants } from '../../../shared/constants/path.constants';
import { InfoMessageConstant } from '../../../shared/constants/info-message.constants';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessageConstant } from '../../../shared/constants/error-message.constants';
import { takeUntil } from 'rxjs/operators';
import { InterestLabelsConstants } from '../../../shared/constants/interest.labels.constants';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { EntityRiskData } from './entity-risk.data';
import { QuoteService } from '../../../../app/core/services/quote.service';
import { FormTypeConstants } from '../../../../app/shared/constants/form-types.constants';
import { PolicyIssueData } from '../../policy-management/data/policy-issue.data';
import { SubmissionPageData } from 'app/modules/submission-management/data/submission-page.data';
import {
  updateInterestsIsLoadingFromInterestSavingData,
  updateInterestsListFromInterestSavingData
} from 'app/store/interests/interests.actions';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { PolicyBillingData } from 'app/modules/policy-management/data/policy-billing.data';
import { BillingService } from 'app/core/services/billing/billing.service';
import { RiskStatusCode } from 'app/shared/models/data/dto/quick-quote/risk.dto';

@Injectable({
  providedIn: 'root',
})
export class InterestSavingData extends BaseClass {

  protected isInterestForSaving: boolean = false;
  isInterestSkippedSaving: boolean = true;

  constructor(protected summaryData: SummaryData,
    public interestsData: InterestData,
    protected quickQuoteService: QuickQuoteService,
    protected toast: ToastrService,
    public policySummaryData: PolicySummaryData,
    public entityRiskData: EntityRiskData,
    public quoteService: QuoteService,
    protected policyIssueData: PolicyIssueData,
    protected submissionData: SubmissionPageData,
    protected store: Store,
    protected billingData: PolicyBillingData,
    protected billingService: BillingService
  ) {
    super();
  }

  saveInterest(selectedPayorRiskInterestDetailId: string = null, selectedNextPayorRiskInterestDetailId: string = null, isSaveFromChangePaymentPlan: boolean = false) {
    try {
      this.entityRiskData.startSavingForms();
      const riskId = this.entityRiskData.getRiskId();
      const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;

      const riskInterestId = this.entityRiskData.getRiskInterestId();
      const riskInterest: RiskInterestDTO = {
        riskDetailId: riskDetailId,
        priorInsuranceCode: this.interestsData.priorInsuranceForm.get('priorInsurance').value ? this.interestsData.priorInsuranceForm.get('priorInsurance').value : 'PI1',
        priorCarrierCode: this.interestsData.priorInsuranceForm.get('priorInsurance').value === InterestLabelsConstants.priorCarrierList.PI0 && this.quoteService.formType !== FormTypeConstants.HO4 ? this.interestsData.priorInsuranceForm.get('priorCarrier').value : null,
        priorExpirationDate: this.interestsData.priorInsuranceForm.get('priorExpirationDate').value ? this.interestsData.priorInsuranceForm.get('priorInsurance').value === InterestLabelsConstants.priorCarrierList.PI0 ? this.interestsData.priorInsuranceForm.get('priorExpirationDate').value?.singleDate?.jsDate.toLocaleDateString('en-US') : null : null,
        riskInterestDetails: []
      };

      this.interestsData.interestsTableRows.forEach((data) => {
        const addInsurance = this.interestsData.descriptionOfInterestsForInsured.find(x => x.code === data.tr[10].value)?.code;
        const interest: RiskInterestDetailDTO = {
          id: data.tr[0].id,
          riskInterestId: riskInterestId,
          interestTypeCode: data.tr[0].value,
          interestName: data.tr[1].value,
          interestName2: data.tr[2].value,
          mailingAddress: data.tr[3].value,
          mailingAddress2: data.tr[4].value,
          street: null,
          zipCode: data.tr[5].value,
          city: data.tr[6].value,
          state: data.tr[7].value,
          loanNumber: data.tr[8].value,
          rankCode: data.tr[9].value === '' ? null : data.tr[9].value,
          descOfInterestCode: data.tr[10].value === '' ? null : addInsurance,
          interestLastName: data.item.interestLastName,
          interestLastName2: data.item.interestLastName2,
          interestNameSuffix: data.item.interestNameSuffix,
          interestNameSuffix2: data.item.interestNameSuffix2,
          isIndividual: data.item.isIndividual,
          isFromParent: data.item.isFromParent,
          createdDate: data.item.createdDate === undefined ? null : data.item.createdDate,
          isAdd: data.item.isAdd,
          countryCode: data.tr[11].value,
        };

        interest.isSelectedPayor = selectedPayorRiskInterestDetailId ? (data.tr[0].id === selectedPayorRiskInterestDetailId ? true : false) : false;
        if (!this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId())) {
          interest.isSelectedNextTermPayor = selectedNextPayorRiskInterestDetailId ? (data.tr[0].id === selectedNextPayorRiskInterestDetailId ? true : false) : false;
        } else {
          if (!isSaveFromChangePaymentPlan) {
            interest.isSelectedPayor = data.item.isSelectedPayor;
          }
          interest.isSelectedNextTermPayor = data.item.isSelectedNextTermPayor;
        }

        riskInterest.riskInterestDetails.push(interest);
      });

      if (riskInterest.riskInterestDetails.filter(x => x.isSelectedPayor).length > 0) {
        this.interestsData.selectedPayorId = riskInterest.riskInterestDetails.find(x => x.isSelectedPayor).id;
      }

      const saveInterestRequest: SaveInterestDTO = {
        riskId: riskId,
        riskDetailId: riskDetailId,
        riskInterest: riskInterest
      };

      if (riskId) {
        this.policySummaryData.saveInterestsComplete$.next(false);
        this.store.dispatch(updateInterestsIsLoadingFromInterestSavingData({ isLoading: true }));

        this.quickQuoteService.postInterests(saveInterestRequest).pipe(takeUntil(this.stop$)).subscribe(res => {
          this.entityRiskData.finishSavingForms();
          this.unmarkInterestForSaving();
          this.fetchInterstDataAfterSaving(res);

          if (this.summaryData.raterData.isViewQuoteProposalClicked) {
            this.summaryData.raterData.recalculatePremiumAndSave$.next(true);
            this.summaryData.raterData.isViewQuoteProposalClicked = false;
          }

          if (this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId())) {
            this.policySummaryData.subStatusCode$.next(res.riskSubStatusCode as RiskStatusCode);
            this.entityRiskData.updateEntityRiskDataInterest(saveInterestRequest.riskInterest.riskInterestDetails);
          }

          this.policySummaryData.saveInterestsComplete$.next(true);
          this.submissionData.interestsSavingComplete.next();
          NotifUtils.showConsoleSuccess(`${PathConstants.Submission.Submission.Interests} ${InfoMessageConstant.savedSuccessfullyMessage}`);

          const interests = _.orderBy(res.riskInterestDetails, ['id'], ['asc']);

          this.store.dispatch(updateInterestsListFromInterestSavingData({ list: interests }));
          this.store.dispatch(updateInterestsIsLoadingFromInterestSavingData({ isLoading: false }));
        },
          err => {
            console.log(err);
            this.entityRiskData.finishSavingForms();
            this.toast.error(ErrorMessageConstant.savingErrorMessage);
            this.store.dispatch(updateInterestsIsLoadingFromInterestSavingData({ isLoading: false }));
            // add code here for error log
          }
        );
      }
    } catch (error) {
      this.entityRiskData.finishSavingForms();
      console.log(error); // for error logging
    }
  }

  markInterestForSaving() {
    this.isInterestForSaving = true;
  }

  unmarkInterestForSaving() {
    this.isInterestForSaving = false;
  }

  checkIfInterestForSaving(): boolean {
    return this.isInterestForSaving;
  }

  fetchInterstDataAfterSaving(response: any): void {
    this.interestsData.riskInterestId = response.riskInterestId;
    const riskInterestDetails = response?.riskInterestDetails;
    if (riskInterestDetails) {
      if (localStorage.getItem('pageType') !== 'policies') {
        this.interestsData.interestList = [];
        this.interestsData.interestsTableRows = [];
        this.interestsData.getInterestsFromApi();
      }
    }
  }
}
