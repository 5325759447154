import { Injectable } from '@angular/core';
import { BaseClass } from 'app/shared/base-class';
import * as _ from 'lodash';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { UWRHelpers } from './uwr-helpers.service';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectInterestsList } from 'app/store/interests/interests.selectors';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { RiskDetailsDTO } from 'app/shared/models/data/dto/quick-quote/risk-details.dto';
import { LvUWApprovalCodeConstants } from 'app/shared/constants/uw.referrals';
import { selectChangesList } from 'app/store/changes/changes.selectors';
import { CoveragesData } from 'app/modules/submission-management/data/coverages.data';
import { AuthService } from '../../auth.service';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { UwApprovalData } from 'app/modules/submission-management/data/uw-approval.data';
import { selectRaterList } from 'app/store/rater/rater.selectors';
import { IProcessedRaterView } from 'app/shared/models/rater-premium/processed-rater-view.model';
import { ApplicantData } from 'app/modules/submission-management/data/applicant.data';
import { RiskScheduleDTO } from 'app/shared/models/data/dto/quick-quote/risk-schedule.dto';
import { selectCoveragesSchedules } from 'app/store/coverages/coverages.selectors';

@Injectable({
  providedIn: 'root',
})
export class AsyncPolicyUWR extends BaseClass {
  protected selectInterestsList$: Observable<any>;
  protected selectChangesList$: Observable<any>;
  protected selectRaterList$: Observable<IProcessedRaterView[]>;
  protected selectCoveragesSchedules$: Observable<RiskScheduleDTO[]>;

  lvUWApprovalCodeConstants = LvUWApprovalCodeConstants;
  genericConstants = GenericConstants;

  constructor(
    public policySummaryData: PolicySummaryData,
    protected uwrHelpers: UWRHelpers,
    protected store: Store,
    protected entityRiskData: EntityRiskData,
    protected coveragesData: CoveragesData,
    protected authService: AuthService,
    protected uwApprovalData: UwApprovalData,
    protected applicantData: ApplicantData
  ) {
    super();
    this.selectInterestsList$ = this.store.pipe(select(selectInterestsList));
    this.selectChangesList$ = this.store.pipe(select(selectChangesList));
    this.selectRaterList$ = this.store.pipe(select(selectRaterList));
    this.selectCoveragesSchedules$ = this.store.pipe(select(selectCoveragesSchedules));
  }

  get parentRiskDetail(): RiskDetailsDTO {
    return this.entityRiskData?.ParentBackUpRiskData;
  }

  initAsyncPolicyUWRs(): void {
    this.asyncPolicyUWR82();
    this.asyncPolicyUWR85();
    this.asyncPolicyUWR128();
    this.asyncPolicyUWR129();
    this.asyncPolicyUWR131();
  }

  asyncPolicyUWR82(): void {
    this.selectCoveragesSchedules$
      .pipe(
        filter((data) => {
          return data !== null;
        }),
        takeUntil(this.stop$)
      )
      .pipe(takeUntil(this.stop$))
      .subscribe((schedules) => {
        const addSchedules = this.coveragesData.schedulesForm.get('addSchedules')?.value;
        const parentRiskSchedules: RiskScheduleDTO[] = this.parentRiskDetail?.riskSchedules;

        const newSchedules: RiskScheduleDTO[] = schedules.filter(item => {
          const oldSchedule: RiskScheduleDTO = parentRiskSchedules.find(_item => {
            return item.createdDate === _item.createdDate;
          });

          if (!oldSchedule) {
            return item;
          };
        });

        let status = false;

        if (this.uwrHelpers.isHO3 && addSchedules && newSchedules?.length > 0) {
          this.coveragesData.scheduleTableNames.map((scheduleName) => {
            const _schedules = newSchedules.filter(item => {
              return item?.tableId === scheduleName;
            });

            _schedules.map(item => {
              const limit: number = Number(item?.limit);

              if (limit > 5000 && this.coveragesData.schedulesForm.get(scheduleName).value) {
                status = true;
              }
            });
          });
        }

        this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
          return uwr.id === 82 ? { ...uwr, status: status } : uwr;
        });

        this.uwrHelpers.getAsyncUWRs$.next();
      });
  }

  asyncPolicyUWR85(): void {
    this.selectInterestsList$
      .pipe(
        filter((data) => {
          return data !== null;
        }),
        takeUntil(this.stop$)
      )
      .pipe(takeUntil(this.stop$))
      .subscribe((interests) => {
        let status = false;

        const parentRiskInterests =
          this.parentRiskDetail?.riskInterests[0].riskInterestDetails.map(
            (interest) => {
              return this.uwrHelpers.getInterestProps(interest);
            }
          );

        interests = interests.map((interest) => {
          return this.uwrHelpers.getInterestProps(interest);
        });

        let result: any = [];

        if (parentRiskInterests.length === 0) {
          result = _.filter(interests, (interest) => {
            return (
              interest?.interestTypeCode.toUpperCase() === 'ADDINS' &&
              (interest?.descOfInterestCode.toUpperCase().includes('DI2') ||
                interest?.descOfInterestCode.toUpperCase().includes('DI3'))
            );
          });
        } else {
          _.forEach(interests, (interest, index) => {
            const parentInterest = parentRiskInterests.find(_parentInterest => {
              return _parentInterest.id === interest.id;
            });

            const isEqual = _.isEqual(parentInterest, interest);

            if (!isEqual) {
              const _status = interest?.interestTypeCode.toUpperCase() === 'ADDINS' &&
                (interest?.descOfInterestCode.toUpperCase().includes('DI2') ||
                interest?.descOfInterestCode.toUpperCase().includes('DI3'));

              if (_status) {
                if (parentInterest) {
                  result = [...result, interest];
                } else {
                  if (_status) {
                    result = [...result, interest];
                  }
                }
              }
            }
          });
        }

        status = this.uwrHelpers.isHO3orDP3orHO6 && result.length > 0;

        this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
          return uwr.id === 85 ? { ...uwr, status: status } : uwr;
        });

        this.uwrHelpers.getAsyncUWRs$.next();
      });
  }

  asyncPolicyUWR128(): void {
    this.selectChangesList$
      .pipe(
        filter((data) => {
          return data !== null;
        }),
        takeUntil(this.stop$)
      )
      .pipe(takeUntil(this.stop$))
      .subscribe((changes) => {
        let status = false;

        const isExternalUser = this.authService.userType.value === this.genericConstants.userType.external;

        if (changes.length > 0) {
          const nameChangesKey = ['Changed Hurricane Deductible From:'];
          let hasHurricaneDeductibleChanges = false;

          const parentHurricaneDeductible =
            this.parentRiskDetail?.riskCoverages[0]?.hurricaneDeductible;
          const currentHurricaneDeductibleId =
            this.coveragesData.coveragesAndDeductiblesForm.get(
              'hurricaneDeductibleid'
            )?.value;

          _.forEach(changes, (change, index) => {
            _.forEach(nameChangesKey, (key) => {
              hasHurricaneDeductibleChanges = change.includes(key);

              if (hasHurricaneDeductibleChanges) {
                status = parentHurricaneDeductible === 'E' && currentHurricaneDeductibleId !== 'E';
              }
            });
          });
        }

        if (status) {
          if (this.coveragesData.hurricaneDeductibleidChanged) {
            if (!isExternalUser) {
              status = false;
            }
          } else {
            const uwr128Description = this.uwrHelpers.getUwrDescription(this.lvUWApprovalCodeConstants['uwr128']);
            const hasUWR128 = this.uwApprovalData.uwApprovalList.includes(uwr128Description);

            status = hasUWR128;
          }
        }

        status = status && this.uwrHelpers.isHO3orDP3orHO4orHO6;

        this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
          return uwr.id === 128 ? { ...uwr, status: status } : uwr;
        });

        this.uwrHelpers.getAsyncUWRs$.next();
      });
  }

  asyncPolicyUWR129(): void {
    this.selectChangesList$
      .pipe(
        filter((value) => {
          return value !== null;
        }),
        takeUntil(this.stop$)
      )
      .pipe(takeUntil(this.stop$))
      .subscribe((changes) => {
        let status = false;

        const isExternalUser = this.authService.userType.value === this.genericConstants.userType.external;

        if (changes.length > 0) {
          const nameChangesKey = [
            'Removed Middle Name:',
            'Removed Name Suffix:',
            'Added Middle Name:',
            'Added Name Suffix:',
            'Changed First Name From:',
            'Changed Last Name From:',
            'Changed Middle Name From:',
            'Changed Name Suffix From:',
          ];

          _.forEach(changes, (change, index) => {
            _.forEach(nameChangesKey, (key) => {
              status = change.includes(key);

              if (status) {
                return false;
              }
            });

            if (status) {
              return false;
            }
          });
        }

        if (status) {
          if (this.applicantData.nameChanged) {
            if (!isExternalUser) {
              status = false;
            }
          } else {
            const uwr129Description = this.uwrHelpers.getUwrDescription(this.lvUWApprovalCodeConstants['uwr129']);
            const hasUWR129 = this.uwApprovalData.uwApprovalList.includes(uwr129Description);

            status = hasUWR129;
          }
        }

        status = status && this.uwrHelpers.isHO3orDP3orHO4orHO6;

        this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
          return uwr.id === 129 ? { ...uwr, status: status } : uwr;
        });

        this.uwrHelpers.getAsyncUWRs$.next();
      });
  }

  asyncPolicyUWR131(): void {
    this.selectRaterList$
      .pipe(
        filter((value) => {
          return value !== null;
        }),
        takeUntil(this.stop$)
      )
      .pipe(takeUntil(this.stop$))
      .subscribe((raterList) => {
        let status = false;

        const withPrivateFloodCoverage = this.coveragesData.floodForm.get('privateFloodCoverage')?.value;
        const isElevationCertificatePresent = this.coveragesData.floodForm.get('elevationCertificateId').value;
        const floodCovPremium = raterList?.find(x => x.stepAlias === 'FloodCovPremium')?.perilValue;

        const parentBaseElevation = Math.trunc(Number(this.parentRiskDetail?.riskFloods[0].baseFloodElevation));
        const parentElevationDifference = Math.trunc(Number(this.parentRiskDetail?.riskFloods[0].elevationDifference));
        const parentLowestFloorElevation = Math.trunc(Number(this.parentRiskDetail?.riskFloods[0].lowestFloorElevation));

        const currentBaseFloodElevation = withPrivateFloodCoverage ? Math.trunc(Number(this.coveragesData.floodForm.get('baseFloodElevation')?.value)) : 0;
        const currentLowestFloodElevation = withPrivateFloodCoverage ? Math.trunc(Number(this.coveragesData.floodForm.get('lowestFloodElevation')?.value)) : 0;
        const currentElevationDifference = withPrivateFloodCoverage ? Math.trunc(Number(this.coveragesData.floodForm.get('elevationDifference')?.value)) : 0;

        const hasChanges = (parentElevationDifference !== currentElevationDifference ||
          parentBaseElevation !== currentBaseFloodElevation ||
          parentLowestFloorElevation !== currentLowestFloodElevation);

        if (hasChanges) {
          status = withPrivateFloodCoverage && isElevationCertificatePresent.toLocaleLowerCase() === 'y' && Math.trunc(Number(floodCovPremium)) === 0 && !this.uwrHelpers.isDP3;
        }

        this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
          return uwr.id === 131 ? { ...uwr, status: status } : uwr;
        });

        this.uwrHelpers.getAsyncUWRs$.next();
      });
  }
}
