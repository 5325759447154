export const PolicySummaryLabelsConstants = {
    insured: {
      mailingAddress: 'Mailing Address',
      mobilePhone: 'Mobile Phone Number',
      email: 'Email Address',
    },
    mainCoveragesLimits: {
      coverageA: 'Coverage A - Dwelling',
      coverageB: 'Coverage B - Other Structures Limit',
      coverageC: 'Coverage C - Personal Property Limit',
      coverageD: 'Coverage D - Loss of Use',
      coverageE: 'Coverage E - Personal Liability',
      coverageF: 'Coverage F - Medical Payments',
      coverageDorE: 'Coverage D/E - Fair Rental/Addl Living',
      coverageL: 'Coverage L - Personal Liability',
      coverageM: 'Coverage M - Medical Payments',
    },
    deductibles: {
      allOtherPerils: 'All Other Perils Deductible',
      hurricane: 'Hurricane Deductible',
    },
    optionalCoveragesLimits: {
      coverages: 'Coverages',
      limit: 'Limit',
      premium: 'Premium'
    },
    propertyCharacteristics: {
      yearBuilt: 'Year Built',
      protectionClass: 'Protection Class',
      county: 'County',
      squareFootage: 'Square Footage',
      roofMaterial: 'Roof Material',
      yearRoofInstalled: 'Year Roof Installed',
    },
    interests: {
      rank: 'Rank',
      interestType: 'Interest Type',
      interest: 'Interest Name',
      address: 'Mailing Address',
      loanNumber: 'Loan Number'
    },
    hurricaneDeductible500: 500,
    hurricaneDeductibleExcluded: 'E',
    hurricaneExcluded: 'Excluded'
  };
