import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { throwError as _observableThrow, of as _observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IProcessedRaterView } from '../../../../shared/models/rater-premium/processed-rater-view.model';
import { IProcessRaterCommand } from '../../../../shared/models/rater-premium/process-rater-command.model';
import { environment } from '../../../../../environments/environment';
import { EndorsementHistory } from '../../../../shared/models/rater-premium/endorsement-history.model';
import { IProcessDP3Command } from 'app/shared/models/rater-premium/process-dp3-command.model';
import { CommonService } from '../../common.service';
import { SiebelGenerateRequestDTO } from '../../../../shared/models/data/dto/rater/siebel-generate-request.dto';
import { SiebelGenerateResponseDTO } from '../../../../shared/models/data/dto/rater/siebel-generate-response.dto';

@Injectable({
  providedIn: 'root',
})
export class RaterPremiumService {
  protected readonly URL = `${environment.CentauriRaterServiceUrl}/api/rater/`;
  protected readonly comparativeURL = `${environment.CentauriRaterServiceUrl}/api/Comparative/`;

  constructor(protected httpClient: HttpClient,
    protected commonService: CommonService) { }

  // will be removed once the auth is already implemented in rater api
  authJsonHttpHeaders(): HttpHeaders {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
      'Access-Control-Allow-Headers':
        'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
    });

    return httpHeaders;
  }

  calculateRaterPremium(
    raterRequest: IProcessRaterCommand
  ): Observable<IProcessedRaterView[]> {
    return this.httpClient.post<IProcessedRaterView[]>(
      `${this.URL}`, raterRequest, { headers: this.authJsonHttpHeaders() }
    );
  }

  calculateDP3RaterPremium(
    raterRequest: IProcessDP3Command
  ): Observable<IProcessedRaterView[]> {
    return this.httpClient.post<IProcessedRaterView[]>(
      `${this.URL}calculateDP3`, raterRequest, { headers: this.authJsonHttpHeaders() }
    );
  }

  getRaterTransaction(
    riskId: string,
    riskDetailId: string
  ): Observable<IProcessedRaterView> {
    const url = `${this.URL}transaction?riskId=${riskId}&riskDetailId=${riskDetailId}`;
    return this.httpClient.get<IProcessedRaterView>(url, {
      headers: this.authJsonHttpHeaders(),
    });
  }

  getTerritories(zipCode: string): Observable<string> {
    const url = `${this.URL}Territories?zipCode=${zipCode}`;
    return this.httpClient.get<string>(url, {
      headers: this.authJsonHttpHeaders(),
    });
  }

  getEndorsementHistory(
    policyNumber: string,
    riskId: string,
    riskDetailId: string): Observable<EndorsementHistory[]> {
    const url = `${this.URL}EndorsementHistory?policyNumber=${policyNumber}&riskId=${riskId}&riskDetailId=${riskDetailId}`;
    return this.httpClient.get<EndorsementHistory[]>(url, {
      headers: this.authJsonHttpHeaders(),
    });
  }

  getPriorClaimTier(
    creditScore: number,
    priorLosses: number,
    effectiveDate: Date): Observable<number> {
    const url = `${this.URL}CreditClaimsTier?creditScore=${creditScore}&priorLosses=${priorLosses}&effectiveDate=${effectiveDate}`;
    return this.httpClient.get<number>(url, {
      headers: this.authJsonHttpHeaders(),
    });
  }

  getPriorClaimTierAsync(
    creditScore: number,
    priorLosses: number,
    effectiveDate: string,
    stateCode?: string,
    priorInsuranceType?: string,
    priorLiabilityLimit?: string,
    formType?: string): Promise<any> {
    const url = `${this.URL}CreditClaimsTier?creditScore=${creditScore}&priorLosses=${priorLosses}&effectiveDate=${effectiveDate}`;
    return this.httpClient.get(url, {
      headers: this.authJsonHttpHeaders(),
    }).toPromise()
      .then(data => data)
      .catch(this.commonService.handleObservableHttpError);
  }

  getComparativeRate(transactionId: string): Observable<any> {
    const url = `${this.comparativeURL}ComparativeRate?transactionId=${transactionId}`;
    return this.httpClient.get<string>(url, {
      headers: this.authJsonHttpHeaders(),
    });
  }

  postGenerateSiebelRater(request: SiebelGenerateRequestDTO): Observable<SiebelGenerateResponseDTO[]> {
    return this.httpClient.post<SiebelGenerateResponseDTO[]>(`${environment.ApiUrl}/Rater/GenerateSiebelRater`, request)
      .catch(this.commonService.handleObservableHttpError);
  }
}
