import { PathConstants } from '../../shared/constants/path.constants';
import { NavData } from '../../_nav';

export const createReportsMenuItems = (subCategory?: string, reportName?: string): NavData[] => {
  const path = '/' + PathConstants.Reports.Index;

  const reportsMenu = [
    {
      name: 'Agency',
      icon: 'fa fa-users fa-3x',
      url: `${path}/${PathConstants.Reports.Agency.Index}`,
      children: [
        {
          name: 'Miscellaneous',
          icon: 'icon-list',
          url: `${path}/${PathConstants.Reports.Agency.Index}/${PathConstants.Reports.Agency.Miscellaneous}`,
          children: [
            {
              name: 'Agency Snapshot',
              icon: 'cil-camera',
              url: `${path}/${PathConstants.Reports.Agency.Index}/${PathConstants.Reports.Agency.Miscellaneous}/${PathConstants.Reports.Agency.AgencySnapshot}`,
            },
            {
              name: 'Commission Statement',
              icon: 'cil-notes',
              url: `${path}/${PathConstants.Reports.Agency.Index}/${PathConstants.Reports.Agency.Miscellaneous}/${PathConstants.Reports.Agency.CommissionStatement}`,
            },
            {
              name: 'Customer Service',
              icon: 'fa fa-users',
              url: `${path}/${PathConstants.Reports.Agency.Index}/${PathConstants.Reports.Agency.Miscellaneous}/${PathConstants.Reports.Agency.CustomerService}`,
            },
            {
              name: 'Earned Premium',
              icon: 'fa fa-dollar',
              url: `${path}/${PathConstants.Reports.Agency.Index}/${PathConstants.Reports.Agency.Miscellaneous}/${PathConstants.Reports.Agency.EarnedPremium}`,
            },
          ]
        },
      ]
    },
    // {
    //   name: 'Underwriter',
    //   icon: 'icon-user',
    //   url: `${path}/${PathConstants.Reports.Underwriter.Index}`,
    //   children: [
    //     {
    //       name: 'Miscellaneous',
    //       icon: 'icon-list',
    //       url: `${path}/${PathConstants.Reports.Underwriter.Index}/${PathConstants.Reports.Underwriter.Generic}`,
    //       children: [{
    //         name: 'Leading Indicator',
    //         icon: 'fa fa-arrow-up',
    //         url: `${path}/${PathConstants.Reports.Underwriter.Index}/${PathConstants.Reports.Agency.Generic}/${PathConstants.Reports.Underwriter.LeadingIndicator}`,
    //       }]
    //     },
    //   ]
    // }
  ];

  return reportsMenu;
};

export const createReportsMenuItemsForCommissionAuthorithy = (subCategory?: string, reportName?: string): NavData[] => {
  const path = '/' + PathConstants.Reports.Index;

  const reportsMenu = [
    {
      name: 'Agency',
      icon: 'fa fa-users fa-3x',
      url: `${path}/${PathConstants.Reports.Agency.Index}`,
      children: [
        {
          name: 'Miscellaneous',
          icon: 'icon-list',
          url: `${path}/${PathConstants.Reports.Agency.Index}/${PathConstants.Reports.Agency.Miscellaneous}`,
          children: [
            {
              name: 'Agency Snapshot',
              icon: 'cil-camera',
              url: `${path}/${PathConstants.Reports.Agency.Index}/${PathConstants.Reports.Agency.Miscellaneous}/${PathConstants.Reports.Agency.AgencySnapshot}`,
            },
            {
              name: 'Commission Statement',
              icon: 'cil-notes',
              url: `${path}/${PathConstants.Reports.Agency.Index}/${PathConstants.Reports.Agency.Miscellaneous}/${PathConstants.Reports.Agency.CommissionStatement}`,
            }
          ]
        },
      ]
    }
  ];

  return reportsMenu;
};

export const createReportsMenuItemsForAgents = (subCategory?: string, reportName?: string): NavData[] => {
  const path = '/' + PathConstants.Reports.Index;

  const reportsMenu = [
    {
      name: 'Agency',
      icon: 'fa fa-users fa-3x',
      url: `${path}/${PathConstants.Reports.Agency.Index}`,
      children: [
        {
          name: 'Miscellaneous',
          icon: 'icon-list',
          url: `${path}/${PathConstants.Reports.Agency.Index}/${PathConstants.Reports.Agency.Miscellaneous}`,
          children: [
            {
              name: 'Agency Snapshot',
              icon: 'cil-camera',
              url: `${path}/${PathConstants.Reports.Agency.Index}/${PathConstants.Reports.Agency.Miscellaneous}/${PathConstants.Reports.Agency.AgencySnapshot}`,
            }
          ]
        },
      ]
    }
  ];

  return reportsMenu;
};

