import { Component, OnInit } from '@angular/core';
import { SubmissionPageData } from '../../../../../modules/submission-management/data/submission-page.data';
import { BindAndIssueData } from '../../../../../modules/submission-management/data/bind-and-issue.data';
import { BindAndIssueLabelConstants } from '../../../../../shared/constants/bind-and-issue.labels.constants';

@Component({
  selector: 'app-bind-and-issue-section',
  templateUrl: './bind-and-issue-section.component.html',
  styleUrls: ['./bind-and-issue-section.component.scss']
})
export class BindAndIssueSectionComponent implements OnInit {
  public BindAndIssueLabelConstants = BindAndIssueLabelConstants;
  isOpen: boolean = true;
  constructor(public bindAndIssueData: BindAndIssueData
            , protected submissionData: SubmissionPageData) { }

  ngOnInit() {
  }
  collapse() {
    this.isOpen = !this.isOpen;
    this.submissionData.isBindAndIssueOpen = this.isOpen;
    if (!this.bindAndIssueData.paperlessDocumentForm.get('agreement').value) {
      const error = {
        required: true
      };
      this.bindAndIssueData.paperlessDocumentForm.get('agreement').setErrors(error);
    }
  }
}