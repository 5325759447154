import { RiskBindBillingDTO } from './risk-bind-billing.dto';
import { RiskBindPaperlessDTO } from './risk-bind-paper-less.dto';
import { RiskBindSignatureDTO } from './risk-bind-signature.dto';
import { RiskBindDocumentDTO } from './risk-bind-document.dto';
import { RiskBindNoteDTO } from './risk-bind-note.dto';

export class RiskBindDTO implements IRiskBindDTO {
    id?: string | undefined;
    riskDetailId?: string | undefined;
    riskBindBillingId?: string | undefined;
    riskBindBilling?: RiskBindBillingDTO;
    riskBindPaperlessId?: string | undefined;
    riskBindPaperless?: RiskBindPaperlessDTO;
    riskBindSignatureId?: string | undefined;
    riskBindSignature?: RiskBindSignatureDTO;
    riskBindDocuments?: RiskBindDocumentDTO[] | undefined;
    riskBindNotes?: RiskBindNoteDTO[] | undefined;
    createdBy?: number | undefined;
    createdByFullName?: string | undefined;
    createdDate?: Date | undefined;
    readonly isActive?: boolean;

    constructor(data?: IRiskBindDTO) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }
}

export interface IRiskBindDTO {
    id?: string | undefined;
    riskDetailId?: string | undefined;
    riskBindBillingId?: string | undefined;
    riskBindBilling?: RiskBindBillingDTO;
    riskBindPaperlessId?: string;
    riskBindPaperless?: RiskBindPaperlessDTO;
    riskBindSignatureId?: string;
    riskBindSignature?: RiskBindSignatureDTO;
    riskBindDocuments?: RiskBindDocumentDTO[] | undefined;
    riskBindNotes?: RiskBindNoteDTO[] | undefined;
    createdBy?: number | undefined;
    createdByFullName?: string | undefined;
    createdDate?: Date | undefined;
    isActive?: boolean;
}

export class RiskBindDTO2 implements IRiskBindDTO2 {
    riskId?: string | undefined;
    riskBindId?: string | undefined;
    riskBindDocument?: RiskBindDocumentDTO | undefined;
    riskBindNotes?: RiskBindNoteDTO[] | undefined;

    constructor(data?: IRiskBindDTO2) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }
}
export interface IRiskBindDTO2 {
    id?: string | undefined;
    riskDetailId?: string | undefined;
    riskBindBillingId?: string | undefined;
    riskBindBilling?: RiskBindBillingDTO;
    riskBindPaperlessId?: string;
    riskBindPaperless?: RiskBindPaperlessDTO;
    riskBindSignatureId?: string;
    riskBindSignature?: RiskBindSignatureDTO;
    riskBindDocuments?: RiskBindDocumentDTO[] | undefined;
    riskBindNotes?: RiskBindNoteDTO[] | undefined;
    isActive?: boolean;
}
