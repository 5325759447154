import { Injectable } from '@angular/core';
import { BaseClass } from '../../../shared/base-class';

interface IIso360ValueMapping {
  iso360Code: string;
  uiCode: string;
}

@Injectable({
  providedIn: 'root'
})
export class Iso360ValueMappingData extends BaseClass {
  defaultExteriorWallFinishMapping: IIso360ValueMapping[] = [
    {
      iso360Code: 'AL',
      uiCode: 'AOM'
    },
    {
      iso360Code: 'VL',
      uiCode: 'VVS'
    },
    {
      iso360Code: 'ASB',
      uiCode: 'HHP'
    },
    {
      iso360Code: 'FCEMS',
      uiCode: 'SFI'
    },
    {
      iso360Code: 'SHN',
      uiCode: 'WSS'
    },
    {
      iso360Code: 'SSTU',
      uiCode: 'SST'
    },
    {
      iso360Code: 'STU',
      uiCode: 'STR'
    },
    {
      iso360Code: 'BR',
      uiCode: 'BSV'
    },
    {
      iso360Code: 'SBR',
      uiCode: 'SBS'
    },
    {
      iso360Code: 'N',
      uiCode: 'OTH'
    }
  ];

  defaultConstructionTypeMapping: IIso360ValueMapping[] = [
    {
      iso360Code: 'FRW',
      uiCode: 'FMV'
    },
    {
      iso360Code: 'BL',
      uiCode: 'MAS'
    },
    {
      iso360Code: 'FRS',
      uiCode: 'SUP'
    }
  ];

  defaultFoundationTypeMapping: IIso360ValueMapping[] = [
    {
      iso360Code: 'B',
      uiCode: 'BM'
    },
    {
      iso360Code: 'CS',
      uiCode: 'CS'
    },
    {
      iso360Code: 'CR',
      uiCode: 'CR'
    },
    {
      iso360Code: 'P',
      uiCode: 'PB'
    },
    {
      iso360Code: 'E',
      uiCode: 'EP'
    },
    {
      iso360Code: 'ES',
      uiCode: 'SW'
    },
    {
      iso360Code: 'PIL',
      uiCode: 'DP'
    }
  ];

  defaultRoofMaterialMapping: IIso360ValueMapping[] = [
    {
      iso360Code: 'COMP',
      uiCode: 'COMP3'
    },
    {
      iso360Code: 'A_COMP',
      uiCode: 'COMPA'
    },
    {
      iso360Code: 'ICOMP',
      uiCode: 'COMPI'
    },
    {
      iso360Code: 'RR',
      uiCode: 'COMPR'
    },
    {
      iso360Code: 'BUP',
      uiCode: 'BU'
    },
    {
      iso360Code: 'MTLC',
      uiCode: 'METC'
    },
    {
      iso360Code: 'MTL',
      uiCode: 'META'
    },
    {
      iso360Code: 'TIL',
      uiCode: 'TICL'
    },
    {
      iso360Code: 'TILC',
      uiCode: 'TICO'
    },
    {
      iso360Code: 'TILF',
      uiCode: 'TICE'
    },
    {
      iso360Code: 'MEME',
      uiCode: 'MEMEPDM'
    },
    {
      iso360Code: 'W',
      uiCode: 'WSS'
    },
    {
      iso360Code: 'SL',
      uiCode: 'SL'
    },
    {
      iso360Code: 'SPF',
      uiCode: 'SPF'
    },
    {
      iso360Code: 'N/A',
      uiCode: 'U'
    },
    {
      iso360Code: 'PC', // not in iso360
      uiCode: 'PC'
    }
  ];
}
