import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FieldConfig } from '../../../models/dynamic/field.interface';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {
  @Input() fields: FieldConfig[] = [];
  @Input() data: {};
  @Output() parentOnInit: EventEmitter<any> = new EventEmitter<any>();
  constructor(protected fb: FormBuilder) { }
  form: FormGroup;

  get value() {
    return this.form.value;
  }

  getControlByName(value: string): FormControl {
    return this.form.controls[value] as FormControl;
  }

  createControl(): FormGroup {
    const group = this.fb.group({});

    this.fields.forEach(field => {
      if (field.type === 'button') {
        return;
      }
      const control = this.fb.control(
        field.value,
        this.bindValidations(field.validations || [])
      );
      group.addControl(field.name, control);
      if (field.validations && field.validations.some(x => x.name === 'required')) {
        field.required = true;
      }
    });
    return group;
  }

  populateData() {
    this.form.patchValue(this.data);
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  ngOnInit() {
    this.form = this.createControl();
    // this.populateData();
    this.parentOnInit.emit(this.form);
  }
}
