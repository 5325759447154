import { TransferQueueDTO } from './transfer-queue.dto';

export class BatchTransferSavingDTO implements IBatchTransferSavingDTO {
  id?: string | undefined;
  fromAgencyId?: string | undefined;
  fromSubAgencyId?: string | undefined;
  toAgencyId?: string | undefined;
  toSubAgencyId?: string | undefined;
  toAgentId?: string | undefined;
  transferReason?: string | undefined;
  statusCode?: string | undefined;
  transferDate?: string | undefined;
  isMassTransfer?: boolean | undefined;
  isActive?: boolean | undefined;
  transferQueue?: TransferQueueDTO[] | undefined;
}

export interface IBatchTransferSavingDTO {
  id?: string | undefined;
  fromAgencyId?: string | undefined;
  fromSubAgencyId?: string | undefined;
  toAgencyId?: string | undefined;
  toSubAgencyId?: string | undefined;
  toAgentId?: string | undefined;
  transferReason?: string | undefined;
  statusCode?: string | undefined;
  transferDate?: string | undefined;
  isMassTransfer?: boolean | undefined;
  isActive?: boolean | undefined;
  transferQueue?: TransferQueueDTO[] | undefined;
}