import { Component, OnInit } from '@angular/core';
import { QuickQuoteFormConstant, QuickQuotesLabelConstans } from 'app/shared/constants/quick-quotes.labels.constants';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { SubmissionPageData } from '../../../../../modules/submission-management/data/submission-page.data';
import { QuickQuoteData } from 'app/modules/submission-management/data/quick-quote.data';
import { LvRaterOtherPerilDeductibleNHR, LvRaterDeductibleHUR } from '../../../../../shared/constants/quick-quote.options.constants';
import { CoveragesData, MAXCOVA, MINCOVA } from '../../../../../modules/submission-management/data/coverages.data';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { CoveragesValidatorErrorConstants } from 'app/modules/submission-management/validators/validator-error-constants/coverages-validator-error.constants';
import { CoveragesLabelsConstants } from 'app/shared/constants/coverages.labels.constants';
import { AuthService } from '../../../../../core/services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../../../shared/base-class';
import { CoverageBusinessRules } from '../../../../../modules/submission-management/business-rules/coverage.business-rules';
import { Pages } from '../../../../../shared/constants/pages-and-sections.constants';
import { QuoteService } from 'app/core/services/quote.service';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';
import { Validators } from '@angular/forms';
import { SubmissionService } from 'app/core/services/submission/submission.service';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { EntityRiskDTO } from 'app/shared/models/data/dto/quick-quote/entity-risk.dto';
import { Router } from '@angular/router';
import { GenericConstants } from '../../../../../shared/constants/generic.constants';
import { PropertyData } from '../../../../../modules/submission-management/data/property.data';
import FormUtils from 'app/shared/utilities/form.utils';
import { ThirdPartyData } from 'app/modules/submission-management/data/third-party.data';
import { ComparativeRaterData } from '../../../../comparative-rater/comparative-rater.data';
import { EndorsementsData } from 'app/modules/submission-management/data/endorsements.data';
import { ApplicantData } from 'app/modules/submission-management/data/applicant.data';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';

@Component({
  selector: 'app-quick-quote-coverage',
  templateUrl: './quick-quote-coverage.component.html',
  styleUrls: ['./quick-quote-coverage.component.scss']
})
export class QuickQuoteCoverageComponent extends BaseClass implements OnInit {
  public ErrorMessageConstant = ErrorMessageConstant;
  public CoverageLabel = QuickQuotesLabelConstans.coveragesPageLabel;
  public CoveragesValidatorErrorConstants = CoveragesValidatorErrorConstants;
  public CoveragesLabelsConstants = CoveragesLabelsConstants;
  public QuickQuoteFormConstant = QuickQuoteFormConstant;
  public Pages = Pages;
  GenericLabel = GenericLabel;
  isCoverageOpen: boolean = true;
  isInvalidCovA: boolean = false;
  LvRaterOtherPerilDeductibleNHR = LvRaterOtherPerilDeductibleNHR;
  LvRaterDeductibleHUR = LvRaterDeductibleHUR;
  isExternal: boolean = true; // default in authservice is external
  isOnInitNavigate: boolean = true;
  public formTypeConstants = FormTypeConstants;
  genericConstants = GenericConstants;
  formType: string;
  entityId: string;
  formTypeInitialized: boolean = false;
  public FormUtils = FormUtils;

  constructor(
    public quickQuoteData: QuickQuoteData,
    protected submissionData: SubmissionPageData,
    public coveragesData: CoveragesData,
    public endorsementData: EndorsementsData,
    protected authService: AuthService,
    public coveragesBusinessRules: CoverageBusinessRules,
    public quoteService: QuoteService,
    protected entityRiskData: EntityRiskData,
    protected propertyData: PropertyData,
    public thirdPartyData: ThirdPartyData,
    protected comparativeRaterData: ComparativeRaterData,
    protected applicantData: ApplicantData,
    protected policySummaryData: PolicySummaryData
  ) {
    super();
  }

  ngOnInit() {
    this.quoteService.formTypeObs$.pipe(takeUntil(this.stop$)).subscribe((formType) => {
      this.formType = formType;
      if (!this.formTypeInitialized && !this.entityRiskData?.isNewQuote) {
        this.validateValue('coverageA', this.coveragesData.validCovA);
        this.formTypeInitialized = true;
      }
    });
    this.newBRUL55();
    this.coveragesData.validateHurricaneDeductible(this.yearBuilt, this.effectiveDate);

    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.isExternal = this.isExternalUser(userType);
      this.coveragesBusinessRules.checkIfOnInitNavigateAndFilter(this.isExternal, this.isOnInitNavigate);
      this.coveragesData.validateHurricaneDeductible(this.yearBuilt, this.effectiveDate);
      this.isOnInitNavigate = false;
    });

    this.coveragesBusinessRules.setupCoverageACValidatorByFormType();
  }

  newBRUL55(): void {
    const hasEntityData = this.entityRiskData.getRisk()?.riskDetails[0]?.riskCoverages[0] === null;
    if (hasEntityData && this.propertyData.locationDetails.get('county')?.value.toLocaleLowerCase() === this.QuickQuoteFormConstant.Coverages.Monroe) {
      this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValue(this.QuickQuoteFormConstant.Coverages.Excluded);
    }
  }

  protected validateValue(fieldName: string, validValue: number) {
    const covA = this.coveragesData.coveragesAndDeductiblesForm.get(fieldName)?.value;
    if(this.formType !== this.formTypeConstants.HO6) {
      if (!(covA >= MINCOVA && covA <= MAXCOVA) && validValue > 0) {
        this.coveragesData.covAInfoMessage = true;
      }
    } else {
      this.coveragesData.coveragesAndDeductiblesForm.get(fieldName).setValidators([Validators.required, Validators.min(0), Validators.max(MAXCOVA)]);
    }
    this.coveragesData.coveragesAndDeductiblesForm.get(fieldName).setValue((validValue) ? validValue.toString() : covA);
  }

  collapseCoverage() {
    this.isCoverageOpen = !this.isCoverageOpen;
    this.submissionData.isQQCoverageOpen = this.isCoverageOpen;
  }

  isExternalUser(userType: string): boolean {
    return userType.toLocaleLowerCase() === 'external';
  }

  get yearBuilt(): number {
    return Number(this.propertyData.propertyDetails.get('yearBuilt').value);
  }

  get effectiveDate(): Date {
    return this.applicantData.mainApplicant.get('effectiveDate').value;
  }

  get isHO3DP3(): boolean {
    return this.formType === this.formTypeConstants.HO3 || this.formType === this.formTypeConstants.DP3;
  }

  get county(): string {
    return this.propertyData.locationDetails.get('county')?.value.toLocaleLowerCase();
  }

  onBlurCoverageAorC() {
    if (this.comparativeRaterData.isFromComparativeRater) {
      this.coveragesData.isComparativeCoverageMapped = false;
      this.comparativeRaterData.isComparativeResultMapped = false;
    }
    const yearBuilt = Number(this.propertyData.propertyDetails.get('yearBuilt').value);
    const effectiveDate = this.applicantData.mainApplicant.get('effectiveDate').value;
    this.coveragesData.calculateCoverages(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').value), yearBuilt, effectiveDate);
    if (this.quoteService.formType !== this.formTypeConstants.HO4) {
      if (this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').valid) {
        this.coveragesData.validCovA = Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').value);
      }
    }
    if (this.quoteService.formType === this.formTypeConstants.HO4 || this.quoteService.formType === this.formTypeConstants.HO6) {
      if (this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').valid) {
        this.coveragesData.validCovC = Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').value);
      }
    }
    if (this.quoteService.formType === this.formTypeConstants.HO3) {
      this.endorsementData.checkCoverageA();
    }
  }

  onChangeHurricaneDeductible(event: any): void {
    if (event !== this.coveragesData?.coveragesRetrievalData?.hurricaneDeductible) {
      this.coveragesData.hurricaneDeductibleidChanged = true;
    }

    this.endorsementData.checkHurricaneDeductibleid();
    const yearBuilt = Number(this.propertyData.propertyDetails.get('yearBuilt').value);
    const effectiveDate = this.applicantData.mainApplicant.get('effectiveDate').value;
    this.coveragesData.validateHurricaneDeductible(yearBuilt, effectiveDate);
    this.propertyData.showWindMitigation();
  }
}
