import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class SuspendedPaymentsGuard implements CanActivate {
    constructor(private _router: Router) { }

    canActivate() {
        return Boolean(localStorage.getItem('userType')) && localStorage.getItem('userType') === 'internal' ? true : this._router.navigate(['404']);
    }
}