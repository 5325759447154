import { Component, EventEmitter, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  message: string;
  id: string = null;
  isRecurring: boolean = false;

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit(): void { }

  confirm(): void {
    this.triggerEvent(true);
  }

  decline(): void {
    this.triggerEvent(false);
    this.hideModal();
  }

  triggerEvent(res: any) {
    this.event.emit({ data: res, res: 200 });
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }
}
