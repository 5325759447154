import { createAction, props } from '@ngrx/store';

export const updateInterestsIsLoadingFromInterestSavingData = createAction(
  '[InterestSavingData] Update Interests Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateInterestsListFromInterestSavingData = createAction(
  '[InterestSavingData] Update Interests List',
  props<{ list: any[] }>()
);

export const updateInterestsIsLoadingFromInterestData = createAction(
  '[InterestData] Update Interests Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateInterestsListFromInterestData = createAction(
  '[InterestData] Update Interests List',
  props<{ list: any[] }>()
);

export const updateInterestsListFromSubmissionManagementComponent = createAction(
  '[SubmissionManagementComponent] Update Interests List',
  props<{ list: any[] }>()
);

export const updateInterestsListFromPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update Interests List',
  props<{ list: any[] }>()
);