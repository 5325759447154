import { Component, EventEmitter, OnInit } from '@angular/core';
import { SuspendedPaymentsLabel } from '../../../../../../shared/constants/suspended-payments.labels.constants';
import { BsModalRef } from 'ngx-bootstrap';
import { SuspendedPaymentsData } from '../../../../data/suspended-payments.data';
import { GenericLabel } from '../../../../../../shared/constants/generic.labels.constants';
import NotifUtils from '../../../../../../shared/utilities/notif-utils';
import Utils from '../../../../../../../app/shared/utilities/utils';
import { BillingService } from '../../../../../../../app/core/services/billing/billing.service';
import { SuspendedPaymentDetailsDTO } from '../../../../../../../app/shared/models/data/dto/suspended-payment/suspended-payment-detail.dto';
import { VoidSuspendedPaymentDTO } from '../../../../../../../app/shared/models/data/dto/suspended-payment/void-suspended-payment.dto';
import { ErrorMessageConstant } from '../../../../../../../app/shared/constants/error-message.constants';

@Component({
  selector: 'app-void-modal',
  templateUrl: './void-suspended-payment-modal.component.html',
  styleUrls: ['./void-suspended-payment-modal.component.scss']
})
export class VoidModalComponent implements OnInit {
  public SuspendedPaymentsLabel = SuspendedPaymentsLabel;
  public GenericLabel = GenericLabel;
  public event: EventEmitter<any> = new EventEmitter<any>();

  public ErrorMessageConstant = ErrorMessageConstant;

  paymentDetail: SuspendedPaymentDetailsDTO;

  constructor(public modalRef: BsModalRef, public suspendedPaymentsData: SuspendedPaymentsData,
    private billingService: BillingService) { }

  ngOnInit() {
    this.suspendedPaymentsData.voidSuspendedFormGroup.reset();
  }

  onVoid(): void {
    NotifUtils.showConfirmMessage(SuspendedPaymentsLabel.voidPendingModal.voidConfirmationMessage, () => {
      const postRequest: VoidSuspendedPaymentDTO = {
        suspendedPaymentId: this.paymentDetail.id,
        comment: this.suspendedPaymentsData.voidSuspendedFormGroup.get('comments').value
      };

      Utils.blockUI();
      this.billingService.voidSuspendedPayment(postRequest).subscribe(res => {
        this.modalRef.hide();

        const callback = () => NotifUtils.showSuccess(this.SuspendedPaymentsLabel.voidPendingModal.voidSuccessMessage);
        this.suspendedPaymentsData.getSuspendedPaymentList(callback);
      });
    });
  }

  cancelVoid(): void {
    this.modalRef.hide();
  }

}
