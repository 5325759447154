export const LvRefundTo = [
  {
    tableName: 'LvRefundTo',
    tableDescription: 'Refund To',
    code: 'Mortgagee',
    description: 'Mortgagee',
    isActive: true
  },
  {
    tableName: 'LvRefundTo',
    tableDescription: 'Refund To',
    code: 'Insured',
    description: 'Insured',
    isActive: true
  },
  {
    tableName: 'LvRefundTo',
    tableDescription: 'Refund To',
    code: 'Other',
    description: 'Other',
    isActive: true
  },
  {
    tableName: 'LvRefundTo',
    tableDescription: 'Refund To',
    code: 'Premium Finance Co.',
    description: 'Premium Finance Co.',
    isActive: true
  }
];

export const LvRequestStatus = [
  {
    tableName: 'LvRequestStatus',
    tableDescription: 'Request Status',
    code: 'A',
    description: 'All',
    isActive: true
  },
  {
    tableName: 'LvRequestStatus',
    tableDescription: 'Request Status',
    code: 'S',
    description: 'Selected',
    isActive: true
  },
  {
    tableName: 'LvRequestStatus',
    tableDescription: 'Request Status',
    code: 'NS',
    description: 'Not Selected',
    isActive: true
  }
];

export const LvDeliveryType = [
  {
    tableName: 'LvDeliveryType',
    tableDescription: 'Delivery Type',
    code: 'N',
    description: 'Normal',
    isActive: true,
    isDisabledToIntlAddress: false
  },
  {
    tableName: 'LvDeliveryType',
    tableDescription: 'Delivery Type',
    code: 'OV',
    description: 'Overnight',
    isActive: true,
    isDisabledToIntlAddress: true
  },
  {
    tableName: 'LvDeliveryType',
    tableDescription: 'Delivery Type',
    code: 'R',
    description: 'Restricted',
    isActive: false,
    isDisabledToIntlAddress: true
  },
  {
    tableName: 'LvDeliveryType',
    tableDescription: 'Delivery Type',
    code: 'C',
    description: 'Certified',
    isActive: false,
    isDisabledToIntlAddress: true
  },
  {
    tableName: 'LvDeliveryType',
    tableDescription: 'Delivery Type',
    code: 'OM',
    description: 'Omaha',
    isActive: false,
    isDisabledToIntlAddress: true
  },
];

export const LvRefundRequestPolicyOrigin = [
  {
    tableName: 'LvRefundRequestPolicyOrigin',
    tableDescription: 'Policy Origin',
    code: 'A',
    description: 'All',
    isActive: true,
    ordering: 1
  },
  {
    tableName: 'LvRefundRequestPolicyOrigin',
    tableDescription: 'Policy Origin',
    code: 'S',
    description: 'Star',
    isActive: true,
    ordering: 2
  },
  {
    tableName: 'LvRefundRequestPolicyOrigin',
    tableDescription: 'Policy Origin',
    code: 'I',
    description: 'IPX',
    isActive: true,
    ordering: 3
  },
  {
    tableName: 'LvRefundRequestPolicyOrigin',
    tableDescription: 'Policy Origin',
    code: 'U',
    description: 'Unspecified',
    isActive: true,
    ordering: 4
  }
];

export const RefundRelatedConstants = {
  refundTo: {
    Insured: 'Insured',
    Mortgagee: 'Mortgagee',
    Other: 'Other',
    PremCo: 'Premium Finance Co.'
  },
  refundFieldsList: {
    FullName: ['firstName', 'lastName', 'suffix'],
    Name: ['name'],
    Address: ['address', 'aptOrUnit', 'city', 'state', 'zip']
  },
  refundComments: {
    defaultComment: 'Returned Premium'
  }
};