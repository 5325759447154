import { Action, createReducer, on } from '@ngrx/store';
import * as fromPolicyActions from 'app/store/policy/policy.actions';
import { initialState, PolicyState } from './policy.state';

const _policyReducer = createReducer(
  initialState,
  on(
    fromPolicyActions.updatePolicyIsLoadingFromPolicyManagementComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromPolicyActions.updatePolicyStatusFromPolicyCancellation,
    (state, { status }) => ({ ...state, status: status })
  ),
  on(
    fromPolicyActions.updatePolicyStatusFromPolicyCancellationComponent,
    (state, { status }) => ({ ...state, status: status })
  ),
  on(
    fromPolicyActions.updatePolicyStatusFromCoveragesData,
    (state, { status }) => ({ ...state, status: status })
  ),
  on(
    fromPolicyActions.updatePolicyStatusFromEndorsementsSavingData,
    (state, { status }) => ({ ...state, status: status })
  ),
  on(
    fromPolicyActions.updatePolicyStatusFromSummaryData,
    (state, { status }) => ({ ...state, status: status })
  ),
  on(
    fromPolicyActions.updatePolicyStatusFromUwApprovalData,
    (state, { status }) => ({ ...state, status: status })
  ),
  on(
    fromPolicyActions.updatePolicyStatusFromApplicantPage,
    (state, { status }) => ({ ...state, status: status })
  ),
  on(
    fromPolicyActions.updatePolicySubStatusFromCoveragesData,
    (state, { subStatus }) => ({ ...state, subStatus: subStatus })
  ),
  on(
    fromPolicyActions.updatePolicySubStatusFromEndorsementsSavingData,
    (state, { subStatus }) => ({ ...state, subStatus: subStatus })
  ),
  on(
    fromPolicyActions.updatePolicySubStatusFromSummaryData,
    (state, { subStatus }) => ({ ...state, subStatus: subStatus })
  ),
  on(
    fromPolicyActions.updatePolicySubStatusFromUwApprovalData,
    (state, { subStatus }) => ({ ...state, subStatus: subStatus })
  ),
  on(
    fromPolicyActions.updatePolicySubStatusFromApplicantPage,
    (state, { subStatus }) => ({ ...state, subStatus: subStatus })
  ),
  on (
    fromPolicyActions.updatePolicyIsLoadingOosFromPolicyIssueData,
    (state, { isLoadingOos }) => ({ ...state, isLoadingOos: isLoadingOos })
  ),
  on (
    fromPolicyActions.updateEntityIsLoadingFromPolicyBillingData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  )
);

export const policyReducer = (
  state: PolicyState | null,
  action: Action
) => {
  return _policyReducer(state, action);
};