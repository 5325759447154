import { Injectable } from '@angular/core';
import { BaseClass } from '../../../shared/base-class';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { BatchPaymentsPage } from '../../../shared/enum/batch-payments-page.enum';
import Utils from '../../../shared/utilities/utils';
import { BillingService } from '../../../core/services/billing/billing.service';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { PagerService } from '../../../core/services/pagerService';
import { Store } from '@ngrx/store';
import { BatchPaymentDTO } from '../../../shared/models/data/dto/batch-payments/batch-payment.dto';
import { BatchPaymentDetailsDTO } from '../../../shared/models/data/dto/batch-payments/batch-payment-details.dto';
import { RegexConstants } from '../../../shared/constants/regex.constants';
import { CustomValidators } from '../../../shared/validators/custom.validator';
import { takeUntil } from 'rxjs/operators';
import { BatchPaymentsConstants } from '../../../shared/constants/batch-payments.constants';
import { PaymentBatchDocDTO } from '../../../shared/models/data/dto/billing/payment-batch-doc.dto';
import { PolicyService } from '../../../core/services/submission/policy.service';
import { PolicySearchResultDTO } from '../../../shared/models/data/dto/suspended-payment/policy-list-dto';
import { SaveControlTotalRequestDTO } from '../../../shared/models/data/dto/batch-payments/save-control-total-request.dto';

@Injectable()
export class BatchPaymentsData extends BaseClass {
  batchPaymentForm: FormGroup;
  batchSuspensePaymentForm: FormGroup;

  batchPaymentsListNotifier = new Subject<{ pageType: BatchPaymentsPage; batchPaymentsList: BatchPaymentDTO[]; isCompleted: boolean; isError: boolean }>();
  detailsViewNotifier = new Subject<{ details: BatchPaymentDetailsDTO[]; isCompleted: boolean; isError: boolean }>();

  pendingBatchPaymentsList = [];
  processedBatchPaymentsList = [];
  pendingBatchPaymentDetails: BatchPaymentDetailsDTO[] = [];
  batchPayment = [];
  filteredPendingBatchPaymentsList: BatchPaymentDTO[] = [];
  filteredProcessedBatchPaymentsList: BatchPaymentDTO[] = [];

  pendingBatchPaymentsListPager: any = {};
  pendingBatchPaymentsListPagedItems: BatchPaymentDTO[];
  processedBatchPaymentsListPager: any = {};
  processedBatchPaymentsListPagedItems: BatchPaymentDTO[];

  batchPaymentFile: File;

  itemPerPage: number = 10;
  currentPage: number = 1;
  totalRowCount: number = 500;

  isAddOrEditPaymentModalOpen: boolean;
  AlertMessage = BatchPaymentsConstants.AlertMessage;

  documentRequest: PaymentBatchDocDTO[] = [];
  isDocumentUploadSuccess = new Subject<boolean>();
  isDocumentUploadSuccess$ = this.isDocumentUploadSuccess.asObservable();
  paymentDocumentTableRows = [];
  editPaymentDocumentTableRows = [];
  isPolicySearchDone: boolean;

  isSaveDisabled: boolean;

  constructor(
    private billingService: BillingService,
    private pagerService: PagerService,
    private store: Store,
    private policyService: PolicyService
  ) {
    super();
    this.initForms();
  }

  initForms(): void {
    this.batchPaymentForm = this.initBatchPaymentFormgroup();
    this.batchSuspensePaymentForm = this.initBatchSuspensePaymentFormgroup();
    this.batchPaymentSaveButtonValidation();
  }

  initBatchPaymentFormgroup(): FormGroup {
    return new FormGroup({
      controlTotal: new FormControl(null, [Validators.required, Validators.min(1)]),
    });
  }

  initBatchSuspensePaymentFormgroup(): FormGroup {
    return new FormGroup({
      batchNumber: new FormControl({ value: null, disabled: true }),
      batchDate: new FormControl({ value: null, disabled: true }, [Validators.required]),
      policyNumber: new FormControl(null, [Validators.pattern(RegexConstants.policyNumber), CustomValidators.spaceValidator]),
      amount: new FormControl(null, [Validators.required, Validators.min(1)]),
      insuredName: new FormControl(null, [Validators.required, Validators.pattern(RegexConstants.payer)]),
      paymentOrigination: new FormControl(null),
      paidBy: new FormControl('', [Validators.required]),
      company: new FormControl('', [Validators.required]),
      userId: new FormControl({ value: null, disabled: true }, [Validators.required]),
      checkNumber: new FormControl(null),
    });
  }

  getPendingBatchPaymentsList(): void {
    Utils.blockUI();
    this.billingService.getBatchPaymentByIsBatchInProcessed(false).subscribe(res => {
      this.pendingBatchPaymentsList = res.sort((a, b) => new Date(b.batchDate).getTime() - new Date(a.batchDate).getTime());
      this.batchPaymentsPendingListSetPage(1);
      Utils.unblockUI();
    });
  }

  getProcessedBatchPaymentsList(): void {
    Utils.blockUI();
    this.billingService.getBatchPaymentByIsBatchInProcessed(true).subscribe(res => {
      this.processedBatchPaymentsList = res.sort((a, b) => new Date(b.batchDate).getTime() - new Date(a.batchDate).getTime());
      this.batchPaymentsProcessedListSetPage(1);
      Utils.unblockUI();
    });
  }

  getDetailedBatchPaymentView(batchNumber: string): void {
    Utils.blockUI();
    this.detailsViewNotifier.next({ details: [], isCompleted: false, isError: false });
    this.billingService.getBatchPaymentDetailsByBatchNumber(batchNumber).pipe(takeUntil(this.stop$)).subscribe((response: BatchPaymentDetailsDTO[]) => {
      this.detailsViewNotifier.next({ details: response, isCompleted: true, isError: false });
      this.pendingBatchPaymentDetails = response;
      Utils.unblockUI();
    }, err => {
      this.detailsViewNotifier.next({ details: err.error, isCompleted: true, isError: true });
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify(err.error));
    });
  }

  postBatchPayment(data: BatchPaymentDetailsDTO[], controlTotal?: number): void {
    Utils.blockUI();
    this.billingService.postBatchPayment(data).subscribe(res => {
      if (res) {
        const batchpaymentDetailIds = res.map(a => a.batchPaymentDetailId);

          for (let i = 0; i < res.length; i++) {
              if (data[i].batchPaymentRelatedDoc.length > 0) {
                data[i].batchPaymentRelatedDoc.forEach(y => {
                  y.batchPaymentDetailId = batchpaymentDetailIds[i];
                });
                this.billingService.addBatchPaymentDoc(data[i].batchPaymentRelatedDoc).subscribe(() => { });
              }
            }

        if (data[0].batchNumber === '') {
          const payload: SaveControlTotalRequestDTO = {
            batchNumber: res[0].batchNumber,
            controlTotal: controlTotal
          };
          this.postControlTotal(payload, false);
        }
        NotifUtils.showSuccess(this.AlertMessage.saveBatchPaymentSuccessMsg, () => {
          this.getPendingBatchPaymentsList();
          if (data[0].batchNumber) {
            this.getDetailedBatchPaymentView(data[0].batchNumber);
           }
        });
      }
    });
  }

  putUpdateBatchPaymentDetail(data: BatchPaymentDetailsDTO): void {
    Utils.blockUI();
    this.billingService.putUpdatePatchPaymentDetail(data).subscribe(res => {
      if (res) {
        if (this.documentRequest.length) {
          this.addBatchPaymentDoc(res.id);
        }
        this.getDetailedBatchPaymentView(data.batchNumber);
        Utils.unblockUI();
        NotifUtils.showSuccess(this.AlertMessage.updateBatchPaymentSuccessMsg, () => {
        });
      }
    });
  }

  batchPaymentsPendingListSetPage(page: number): void {
    if (page < 1) {
      return;
    }
    this.pendingBatchPaymentsListPager = this.pagerService.getPager(this.pendingBatchPaymentsList.length, page);
    this.pendingBatchPaymentsListPagedItems = this.pendingBatchPaymentsList.slice(this.pendingBatchPaymentsListPager.startIndex, this.pendingBatchPaymentsListPager.endIndex + 1);
  }

  batchPaymentsProcessedListSetPage(page: number): void {
    if (page < 1) {
      return;
    }
    this.processedBatchPaymentsListPager = this.pagerService.getPager(this.processedBatchPaymentsList.length, page);
    this.processedBatchPaymentsListPagedItems = this.pendingBatchPaymentsList.slice(this.processedBatchPaymentsListPager.startIndex, this.pendingBatchPaymentsListPager.endIndex + 1);
  }

  uploadBatchPayment(): void {
    Utils.blockUI();
    this.billingService.uploadBatchPayment(this.batchPaymentFile).subscribe(res => {
      if (res) {
        NotifUtils.showSuccess(this.AlertMessage.uploadBatchPaymentSuccessMsg, () => {
          this.getPendingBatchPaymentsList();
        });
      }
    });
  }

  manualPostBatchPayment(data: BatchPaymentDetailsDTO[]): void {
    NotifUtils.showConfirmMessage(this.AlertMessage.processBatchPaymentConfirmMsg, () => {
      Utils.blockUI();
      this.billingService.manualPostBatchPayment(data).subscribe(res => {
        if (res) {
          NotifUtils.showSuccess(this.AlertMessage.manualPostMsg, () => {
            this.getPendingBatchPaymentsList();
          });
        }
      });
    });
  }

  addBatchPaymentDoc(batchPaymentDetailId: string) {
    Utils.blockUI();
    this.isDocumentUploadSuccess.next(false);
    const request = this.documentRequest;
    request.forEach(val => val.batchPaymentDetailId = batchPaymentDetailId);

    this.billingService.addBatchPaymentDoc(request).subscribe(response => {

      this.isDocumentUploadSuccess.next(true);
    },
      err => {
        this.isDocumentUploadSuccess.next(false);
        NotifUtils.showMultiLineError(err.error?.message);
      }
    );
  }

  resetDocumentVariables(): void {
    this.documentRequest = [];
    this.paymentDocumentTableRows = [];
  }

  getBatchPaymentDetailById(id: string): void {
    this.billingService.getBatchPaymentDetailById(id).subscribe(() => { });
  }

  onPolicyNumberSearch(isEdit: boolean = false): void {
    this.isPolicySearchDone = false;
    const form: FormGroup = this.batchSuspensePaymentForm;
    const searchText = this.batchSuspensePaymentForm.get('policyNumber').value;

    if (searchText) {
      Utils.blockUI();
      const searchRisk$: Observable<PolicySearchResultDTO[]> = this.policyService.searchPolicies(searchText);

      searchRisk$.pipe(takeUntil(this.stop$))
        .subscribe(result => {
          if (result) {
            if (result?.length === 0) {
              this.clearSuspenseDetails(this.batchSuspensePaymentForm);
              this.isPolicySearchDone = true;
              Utils.unblockUI();
            } else {
              const isSiebelDataWithDuplicatePolicyNumber = result.length > 1 && result[0].isSiebel;
              const actOrPexpSiebelDataWithDuplicatePolicyNumber = result.filter(x => x.riskStatusCode === 'ACT' || x.riskStatusCode === 'PEXP');
              const latestActOrPexpSiebelDataWithDuplicatePolicyNumber = actOrPexpSiebelDataWithDuplicatePolicyNumber.sort((a, b) => new Date(b.firstIssueDate).getTime() - new Date(a.firstIssueDate).getTime())[0];

              const isRenewalPolicyNumberUsed = Boolean(result[0]?.riskDetails?.risk?.renewalFromRiskId) &&
                (
                  result[0].riskStatusCode !== 'ACT' &&
                  result[0].riskStatusCode !== 'CAN' &&
                  result[0].riskStatusCode !== 'EXP'
                );

              const riskId = isSiebelDataWithDuplicatePolicyNumber ? // check if data is not from siebel
                (
                  isRenewalPolicyNumberUsed ? result[0]?.riskDetails?.risk?.renewalFromRiskId : result[0].riskId
                ) : latestActOrPexpSiebelDataWithDuplicatePolicyNumber.riskId;

              this.billingService.getSummary(riskId).subscribe(summary => {
                const paidBy = summary?.paymentPlan === 'PPO4' ? 'M' : 'I';
                const riskStateCode = isSiebelDataWithDuplicatePolicyNumber ?
                  latestActOrPexpSiebelDataWithDuplicatePolicyNumber.riskDetails[0]?.risk?.riskStateCode
                  :
                  result[0]?.riskDetails[0]?.risk?.riskStateCode;
                const company = riskStateCode !== 'LA' ? 'CSIC' : 'CNIC';

                if (result.length === 1) {
                  form.get('amount').setValue(summary.balance < 0 ? 0 : summary.balance ?? null);
                  form.get('insuredName').setValue(`${result[0].firstName} ${result[0].lastName}`);
                  form.get('paymentOrigination').setValue('');
                  form.get('paidBy').setValue(paidBy ?? 'I');
                  form.get('company').setValue(company ?? 'CSIC');
                }

                if (isSiebelDataWithDuplicatePolicyNumber) {
                  form.get('amount').setValue(summary.balance < 0 ? 0 : summary.balance ?? null);
                  form.get('insuredName').setValue(`${latestActOrPexpSiebelDataWithDuplicatePolicyNumber[0].firstName} ${latestActOrPexpSiebelDataWithDuplicatePolicyNumber[0].lastName}`);
                  form.get('paymentOrigination').setValue('');
                  form.get('paidBy').setValue(paidBy ?? 'I');
                  form.get('company').setValue(company ?? 'CSIC');
                }

                Utils.unblockUI();
              });
            }
          }
        },
          err => {
            Utils.unblockUI();
            NotifUtils.showError(err.error?.message);
            this.isPolicySearchDone = true;
          });

    }
  }

  clearSuspenseDetails(formGroup: FormGroup): void {
    const controlsToClear = [
      'amount',
      'insuredName',
      'paymentOrigination',
      'paidBy',
      'company'
    ];

    controlsToClear.forEach(key => {
      formGroup.get(key).setValue(key === 'amount' ? null : '');
    });
  }

  postControlTotal(data: SaveControlTotalRequestDTO, isEdit): void {
    Utils.blockUI();
    this.billingService.postControlTotal(data).subscribe(res => {
      if (res) {
        if (isEdit) {
          NotifUtils.showSuccess(this.AlertMessage.updateBatchPaymentSuccessMsg, () => {
            this.getDetailedBatchPaymentView(data.batchNumber);
          });
        }
      }
    });
  }


  batchPaymentSaveButtonValidation(): void {
    const controlTotal = this.batchPaymentForm.get('controlTotal').value;

    if (controlTotal === null || this.pendingBatchPaymentDetails?.length === 0) {
      this.isSaveDisabled = true;
    } else {
      this.isSaveDisabled = false;
    }

    this.batchPaymentForm.get('controlTotal').valueChanges.subscribe(amount => {
      if (amount > 0 && this.pendingBatchPaymentDetails?.length > 0) {
        this.isSaveDisabled = false;
      } else {
        this.isSaveDisabled = true;
      }
    });
  }

  updateBatchPaymentsList(isForPending: boolean = false): void {
    if (isForPending) {
      this.getPendingBatchPaymentsList();
    } else {
      this.getProcessedBatchPaymentsList();
    }
  }
}
