import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BindAndIssueData } from '../../../../../../modules/submission-management/data/bind-and-issue.data';
import { NotesLabelConstants } from '../../../../../..//shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from '../../../../../../shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../../shared/constants/generic.labels.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CustomValidators } from '../../../../../../shared/validators/custom.validator';
import { AuthService } from '../../../../../../core/services/auth.service';

@Component({
  selector: 'app-notes-modal',
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.scss']
})
export class NotesModalComponent implements OnInit {

  public event: EventEmitter<any> = new EventEmitter<any>();
  public ErrorMessageConstant = ErrorMessageConstant;
  public NotesLabelConstants = NotesLabelConstants;
  GenericLabel = GenericLabel;
  notesForm: FormGroup;
  modalRef: BsModalRef | null;
  title: any;
  notesModel: any;
  isAdd: boolean;
  isCancel: boolean = false;
  idForSaving: any;

  constructor(
    protected fb: FormBuilder,
    protected modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public bindAndIssueData: BindAndIssueData,
    protected auth: AuthService
    ) { }

  ngOnInit() {
    const currentDate = this.auth.getCustomDate();
    this.notesForm = this.fb.group({
      id: [this.notesModel?.id],
      category: [this.notesModel?.category, [Validators.required]],
      description: [this.notesModel?.description, [Validators.required, CustomValidators.spaceValidator]],
      dateAdded: [this.notesModel?.dateAdded ? this.notesModel?.dateAdded.singleDate ? this.notesModel?.dateAdded
        : this.getFromApiDate(this.notesModel?.dateAdded)
        : {
            singleDate: {
              date: {
                year: currentDate.getFullYear(),
                month: currentDate.getMonth() + 1,
                day: currentDate.getDate()
              },
              formatted: `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`,
              jsDate: currentDate
            }
          }, [Validators.required]]
    });
    // this.setDisable();
  }

  getFromApiDate(date?) {
    const new_date = new Date(date);
    return {
      singleDate: {
        date: {
          year: new_date.getFullYear(),
          month: new_date.getMonth() + 1,
          day: new_date.getDate()
        },
        formatted: `${new_date.getMonth() + 1}/${new_date.getDate()}/${new_date.getFullYear()}`,
        jsDate: date
      }
    };
  }

  setDisable() {
    if (this.notesModel?.isEdit) {
      this.notesForm.get('category').disable();
    }
  }

  hideMyModal(): void {
    this.isCancel = true;
    this.bsModalRef.hide();
  }

  onSubmit() {
    if (!this.isCancel) {
      const currentId = typeof this.idForSaving === 'undefined'
      ? this.notesForm.get('id')?.value : this.idForSaving;
      const data = {
        id: currentId,
        category: this.notesForm.get('category')?.value,
        description: this.notesForm.get('description')?.value,
        dateAdded: this.notesForm.get('dateAdded')?.value,
        riskBindId: null,
        notesId: null
      };

      this.bsModalRef.hide();

      this.event.emit({ data: data, res: 200 });
    }
  }

}
