import { Subject } from 'rxjs';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { ApplicantComponent } from './applicant.component';
import { SummaryData } from '../data/summary.data';
import { GenericConstants } from '../../../shared/constants/generic.constants';
import { PolicySummaryData } from '../../../modules/policy-management/data/policy-summary.data';
import { ThirdPartyDataService } from '../../../core/services/submission/third-party/third-party-data.service';
import { CoveragesData } from '../data/coverages.data';
import { LvCreditOrderStatus, LvCreditOrderStatusIndex } from '../../../shared/constants/quick-quote.options.constants';
import { ErrorMessageConstant } from '../../../shared/constants/error-message.constants';
import { CreditScoreRiskResponseDTO } from '../../../shared/models/data/dto/third-party/credit-score-risk-response.dto';
import Utils from '../../../shared/utilities/utils';
import { ApplicantData } from '../data/applicant.data';
import { ApplicantValidatorConstants } from '../validators/validator-error-constants/applicant-validators.constants';
import { GenericLabel } from '../../../shared/constants/generic.labels.constants';
import { FormGroup } from '@angular/forms';
import { AuthService } from '../../../core/services/auth.service';
import { PathConstants } from 'app/shared/constants/path.constants';

@Injectable()
export class CanDeactivateApplicantComponentGuard implements CanDeactivate<ApplicantComponent> {
    genericConstants = GenericConstants;

    constructor(protected qqData: SummaryData, public router: Router,
        protected policySummaryData: PolicySummaryData,
        protected thirdPartyService: ThirdPartyDataService,
        protected coveragesData: CoveragesData,
        protected applicantData: ApplicantData,
        protected authService: AuthService
    ) { }

    canDeactivate(applicant: ApplicantComponent, route: ActivatedRouteSnapshot, state: RouterStateSnapshot, nextState: RouterStateSnapshot) {
        const subject = new Subject<boolean>();
        const isSubmission = !this.policySummaryData.isPolicyPage(this.coveragesData.entityRiskData.getRiskId());
        const hasAgreed = applicant.applicantData.fcraReportingActForm.get('fcraReportingAct').value;
        const isNotOrdered = this.coveragesData.creditOrderStatusCode === LvCreditOrderStatus[LvCreditOrderStatusIndex.NotOrdered].code;
        const isInternalUser = this.getUserTypeInStorage() === 'internal';
        const destinationAllowed = nextState.url.includes(this.genericConstants.quoteURL.submissions);
        const doesCallCreditApi = isSubmission && hasAgreed && isNotOrdered && (isInternalUser || this.qqData.isOrderCreditAllowed) && destinationAllowed;
        if (doesCallCreditApi) {
            Utils.blockUI();
            this.callOrderCredit(applicant, subject);
            return subject.asObservable();
        } else if(!isSubmission) {
            return this.isNameAnIndividual(nextState.url);
        } else {
            applicant.applicantData.isLexisCalled = false;
            const isNotAllowedToOrderCredit = !isInternalUser && !this.qqData.isOrderCreditAllowed;

            if (hasAgreed && isNotAllowedToOrderCredit && !(nextState.url === PathConstants.Login.IndexWithSlash)) {
                applicant.applicantData.fcraReportingActForm.get('fcraReportingAct').setValue(false);
                NotifUtils.showError(ErrorMessageConstant.CreditService.AccountSetupBRQQ41ErrorMessage);
                return false;
            }
        }
        return true;
    }

    get applicant(): FormGroup {
        return this.applicantData.mainApplicant;
    }

    protected callOrderCredit(component: ApplicantComponent, subject: Subject<boolean>) {
        component.applicantData.isLexisCalled = true;
        const creditRequest = component.thirdPartyData.getOrderCreditRequestModel();
        this.thirdPartyService.postOrderCredit(creditRequest).subscribe(result => {
            Utils.unblockUI();
            if (result) {
                const creditInfoResult: CreditScoreRiskResponseDTO = result;
                component.thirdPartyData.mapCreditScoreInfoResultToCoveragesData(result);
                this.resetIsUpdatedInsuredProfleOrPrefillFlag();
                this.coveragesData.populateFinancialResponsibility(result.creditScore);
                this.coveragesData.saveCoverages(false, false, false);
                component.thirdPartyData.coveragesSectionOrderCredit$.next(true);
                this.qqData.creditOrderErrorCode = creditInfoResult.errorCode;
                if (creditInfoResult.errorCode === ErrorMessageConstant.CreditService.CS02ErrorCode) {
                    NotifUtils.showError(ErrorMessageConstant.CreditService.CS02ErrorCodeMessage);
                } else if (creditInfoResult.errorCode === ErrorMessageConstant.CreditService.CE01ErrorCode ||
                    creditInfoResult.errorCode === ErrorMessageConstant.CreditService.NcfApiErrorCode) {
                    NotifUtils.showError(ErrorMessageConstant.CreditService.LexisNexisNcfFailed_V1);
                } else if (creditInfoResult.errorCode === ErrorMessageConstant.CreditService.GeneralErrorCode) {
                    NotifUtils.showError(ErrorMessageConstant.CreditService.GeneralErrorMessage);
                } else if (creditInfoResult.errorCode === ErrorMessageConstant.CreditService.NcfApiAccountCodeErrorCode) {
                    NotifUtils.showError(ErrorMessageConstant.CreditService.AccountSetupBrQq41InvaliderrorMessage);
                } else {
                    subject.next(true);
                }
            } else {
                component.applicantData.fcraReportingActForm.get('fcraReportingAct').setValue(false);
                component.thirdPartyData.mapCreditScoreNotOrderedToCoveragesData();
                NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
            }

        }, error => {
            Utils.unblockUI();
            component.applicantData.fcraReportingActForm.get('fcraReportingAct').setValue(false);
            component.thirdPartyData.mapCreditScoreNotOrderedToCoveragesData();
            NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
        }, () => {
            this.validateApplicantPage(component);
        });
    }

    protected resetIsUpdatedInsuredProfleOrPrefillFlag() {
        if (this.qqData.isUpdatedInsuredProfileOrPrefill) {
            this.qqData.isUpdatedInsuredProfileOrPrefill = false;
        }
    }

    protected validateApplicantPage(component: ApplicantComponent) {
        if (this.coveragesData.creditOrderStatusCode === LvCreditOrderStatus[LvCreditOrderStatusIndex.NotOrdered].code) {
          component.applicantValidationService.FcraAcknowledgementForm.get('fcraReportingAct').setValue(false);
        }
    }

    protected isNameAnIndividual(destinationPage: string): boolean {
        if (this.authService.userType.value === 'external' && destinationPage.includes('/policies/') && !this.policySummaryData.navigatedToPolicyIssue &&
            (this.applicant.get('firstName').hasError(ApplicantValidatorConstants.nameMustBeIndividualsAndNotCorporationsErrorMessage.key) ||
                this.applicant.get('lastName').hasError(ApplicantValidatorConstants.nameMustBeIndividualsAndNotCorporationsErrorMessage.key) ||
                this.applicant.get('middleInitial').hasError(ApplicantValidatorConstants.nameMustBeIndividualsAndNotCorporationsErrorMessage.key))
        ) {
            NotifUtils.showError(GenericLabel.qqInvalidFieldsMessage);
            return false;
        }
        return true;
    }

    protected getUserTypeInStorage(): string {
        return localStorage.getItem('userType');
      }
}