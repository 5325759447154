import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { PolicyBillingLabelsConstants } from '../../../../../shared/constants/policy-billing.labels.constants';
import { BsModalRef } from 'ngx-bootstrap';
import { RiskDTO2 } from '../../../../../shared/models/data/dto/quick-quote/risk-dto2';
import { PolicyBillingData } from 'app/modules/policy-management/data/policy-billing.data';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';

@Component({
  selector: 'app-transfer-renewal-payment-modal',
  templateUrl: './transfer-renewal-payment-modal.component.html',
  styleUrls: ['./transfer-renewal-payment-modal.component.scss']
})
export class TransferRenewalPaymentModalComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();

  transferRenewalPaymentForm: FormGroup;
  RenewalTransferPaymentConstants = PolicyBillingLabelsConstants.transferRenewalPayment;
  ErrorMessageConstant = ErrorMessageConstant;
  GenericLabel = GenericLabel;
  payOffAmount: number;
  approvalAmountToBind: number;
  renewalRisk: RiskDTO2;

  constructor(protected fb: FormBuilder,
    public bsModalRef: BsModalRef,
    public billingData: PolicyBillingData,
    public entityData: EntityRiskData) { }

  ngOnInit() {
    this.transferRenewalPaymentForm = this.fb.group({
      transferToPolicy: new FormControl(''),
      amount: new FormControl(null, [Validators.required]),
      comment: new FormControl('')
    });

    this.billingData.checkRenewalDetails(this.entityData.getRiskId(), this.entityData.getRiskDetailId(), false);
    this.approvalAmountToBind = this.approvalAmountToBind === 0 ? 1 : this.approvalAmountToBind;
    this.transferRenewalPaymentForm.get('amount').setValue(this.payOffAmount);
    this.transferRenewalPaymentForm.get('amount').setValidators([Validators.required, Validators.min(this.renewalRisk.statusProper === 'Renewal Not Taken' ? this.approvalAmountToBind : this.billingData.renewalAmount), Validators.max(this.payOffAmount)]);
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  onTransfer(): void {
    this.triggerEvent({
      continue: true,
      comment: this.transferRenewalPaymentForm.get('comment').value,
      amount: this.transferRenewalPaymentForm.get('amount').value,
    });
  }

  onCancel(): void {
    this.triggerEvent({ continue: false });
  }

  triggerEvent(res: any) {
    this.event.emit({ data: res, res: 200 });
  }

}

