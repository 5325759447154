import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UwApprovalComponent } from './uw-approval.component';
import { SharedModule } from '../../../../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { UwApprovalPageModalComponent } from './uw-approval-page-modal/uw-approval-page-modal.component';
import { UwApprovalFileModalComponent } from './uw-approval-file-modal/uw-approval-file-modal.component';
import { UwApprovalFileUploadModalComponent } from './uw-approval-file-upload-modal/uw-approval-file-upload-modal.component';
import { CanDeactivateUwApprovalComponentGuard } from './uw-approval-navigation-guard';
import { UwApprovalNotesModalComponent } from './uw-approval-notes-modal/uw-approval-notes-modal.component';
import { DocumentsComponent } from '../bind-and-issue/documents/documents.component';
import { DocumentsModalComponent } from '../bind-and-issue/documents/documents-modal/documents-modal.component';
import { UwRenewalReferralComponent } from './uw-renewal-referral/uw-renewal-referral.component';
import { CanDeactivateAutoReinstatementApprovalComponentGuard } from '../../../../shared/components/auto-reinstatement-approval/auto-reinstatement-approval-navigation-guard';
import { UWApprovalHasRenewalTaskPipe } from 'app/modules/submission-management/submission/uw-approval/pipes/uw-approval-has-renewal-task.pipe';
import { DisableRuwButtonPipe } from './pipes/disable-ruw-button.pipe';
import { DisableAuwButtonPipe } from './pipes/disable-auw-button.pipe';
import { DisableAirButtonPipe } from './pipes/disable-air-button.pipe';
import { DisableDuwButtonPipe } from './pipes/disable-duw-button.pipe';

const routes: Routes = [
  {
    path: '',
    component: UwApprovalComponent,
    canDeactivate: [CanDeactivateAutoReinstatementApprovalComponentGuard]
  }];

@NgModule({
  declarations: [
    UwApprovalComponent,
    UwApprovalPageModalComponent,
    UwApprovalFileModalComponent,
    UwApprovalFileUploadModalComponent,
    UwApprovalNotesModalComponent,
    DocumentsComponent,
    DocumentsModalComponent,
    UwRenewalReferralComponent,
    UWApprovalHasRenewalTaskPipe,
    DisableRuwButtonPipe,
    DisableAuwButtonPipe,
    DisableAirButtonPipe,
    DisableDuwButtonPipe
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    UwApprovalPageModalComponent,
    UwApprovalFileModalComponent
  ],
  entryComponents: [
    UwApprovalPageModalComponent,
    UwApprovalFileModalComponent,
    UwApprovalFileUploadModalComponent,
    UwApprovalNotesModalComponent,
    DocumentsModalComponent,
  ],
  providers: [CanDeactivateUwApprovalComponentGuard]
})
export class UwApprovalModule { }
