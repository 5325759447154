import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClaimsComponent } from './claims.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../../../shared/shared.module';
import { ClaimsModalComponent } from './claims-modal/claims-modal/claims-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CanDeactivateClaimsComponentGuard } from './claims-navigation-guard.service';

const routes: Routes = [
  { path: '', component: ClaimsComponent }
  // { path: '', component: ClaimsComponent, canDeactivate: [CanDeactivateClaimsComponentGuard] }
];

@NgModule({
  declarations: [ClaimsComponent, ClaimsModalComponent],
  imports: [
    CommonModule,
    SharedModule,
    ModalModule.forRoot(),
    RouterModule.forChild(routes)
  ],
  exports: [
    ClaimsModalComponent
  ],
  entryComponents: [
    ClaimsModalComponent
  ],
  providers: [CanDeactivateClaimsComponentGuard]
})
export class ClaimsModule { }
