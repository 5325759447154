import { PayerDTO } from '../billing/payer.dto';
import { SuspendedMiscImageDTO } from '../billing/suspended-misc-img.dto';
import { SuspendedSplitPayment } from './suspended-split-payment.dto';

export class SuspendedPaymentDetailsDTO implements ISuspendedPaymentDetailsDTO {
  id?: string;
  receiptDate?: string;
  policyNumber?: string;
  amount?: number;
  reason?: string;
  status?: string;
  comment?: string;
  statusId?: string;
  suspendedPaymentId?: string;
  returnDetail?: any;

  transactionNum?: string;
  lockboxNum?: string;
  batch?: string;
  batchItem?: string;
  checkAmount?: number;
  routingNum?: string;
  accountNum?: string;
  checkNum?: string;
  checkImg?: string;
  envelopeImg?: string;
  imgUploadDate?: string;

  checkImgLink?: string;
  envelopeImgLink?: string;
  invoiceImgLink?: string;

  directBillCarrierId?: string;

  payer?: PayerDTO;

  inIPXFinalReport?: boolean;
  
  isAllocated?: boolean;
  suspendedSplitPayment?: SuspendedSplitPayment[];
  originalPolicyPaymentAmount?: number;
  suspendedMiscImage?: SuspendedMiscImageDTO[];



  constructor(data?: SuspendedPaymentDetailsDTO) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }
}

export interface ISuspendedPaymentDetailsDTO {
  id?: string;
  receiptDate?: string;
  policyNumber?: string;
  amount?: number;
  reason?: string;
  status?: string;
  comment?: string;
  statusId?: string;
  suspendedPaymentId?: string;
  returnDetail?: any;

  transactionNum?: string;
  lockboxNum?: string;
  batch?: string;
  batchItem?: string;
  checkAmount?: number;
  routingNum?: string;
  accountNum?: string;
  checkNum?: string;
  checkImg?: string;
  envelopeImg?: string;
  imgUploadDate?: string;

  checkImgLink?: string;
  envelopeImgLink?: string;
  invoiceImgLink?: string;

  directBillCarrierId?: string;

  payer?: PayerDTO;

  inIPXFinalReport?: boolean;

  isAllocated?: boolean;
  suspendedSplitPayment?: SuspendedSplitPayment[];
  originalPolicyPaymentAmount?: number;
  suspendedMiscImage?: SuspendedMiscImageDTO[];
}
