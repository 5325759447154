import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { PolicyBillingData } from '../../../../../modules/policy-management/data/policy-billing.data';
import { PolicyBillingLabelsConstants } from '../../../../../shared/constants/policy-billing.labels.constants';
import { LvCountries } from '../../../../../shared/constants/applicant.options.constants';
import { GeneralValidationService } from '../../../../../core/services/submission/validations/general-validation.service';
import Utils from '../../../../../shared/utilities/utils';
import FormUtils from '../../../../../shared/utilities/form.utils';
import NotifUtils from '../../../../../shared/utilities/notif-utils';
import { ZipCodeData } from '../../../../../modules/submission-management/data/zipcode.data';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { PageSections } from '../../../../../shared/enum/page-sections.enum';
import { RefundRequestListDTO } from '../../../../../shared/models/data/dto/refund/refund-request-list.dto';
import { BillingService } from '../../../../../core/services/billing/billing.service';
import { UpdateRefundDetailsRequestDto } from '../../../../../shared/models/data/dto/refund/edit-refund-request.dto';
import { RefundData } from '../../../../../modules/payments/data/refund.data';
import { CustomValidators } from '../../../../../shared/validators/custom.validator';

@Component({
  selector: 'app-edit-refund-modal',
  templateUrl: './edit-refund-modal.component.html',
  styleUrls: ['./edit-refund-modal.component.scss']
})
export class EditRefundModalComponent implements OnInit {
  public FormUtils = FormUtils;
  public refundRequestListDTO: RefundRequestListDTO;
  public title: any;
  public CheckPaymentConstants = PolicyBillingLabelsConstants.checkPayment;
  public PostPaymentLabelConstants = PolicyBillingLabelsConstants.postPayment;
  public ErrorMessageConstant = ErrorMessageConstant;
  public checkPaymentForm: FormGroup;
  public Countries = LvCountries;
  public GenericLabel = GenericLabel;
  public isRefundRequest: boolean = true;
  public isRefundRequestUSCountry: boolean = false;

  constructor(
    public billingData: PolicyBillingData,
    public generalValidationService: GeneralValidationService,
    public zipCodeData: ZipCodeData,
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private billingService: BillingService,
    public refundRequestData: RefundData
  ) { }

  ngOnInit() {
    this.checkPaymentForm = this.fb.group({
      name: [this.refundRequestListDTO.refundPayeeName, [Validators.required, Validators.maxLength(64)]],
      address: [this.refundRequestListDTO.streetAddress1, [Validators.required, Validators.maxLength(32)]],
      city: [this.refundRequestListDTO.city, [Validators.required, Validators.maxLength(32)]],
      state: [this.refundRequestListDTO.state, [Validators.required]],
      zip: [this.refundRequestListDTO.zip, [Validators.required]],
      country: [this.refundRequestListDTO.countryCode, [Validators.required]],
      aptOrUnit: [this.refundRequestListDTO.streetAddress2, [Validators.maxLength(32)]],
      comments: [this.refundRequestListDTO.comments, [Validators.maxLength(250), CustomValidators.spaceValidator]],
    });

    // For Country
    this.isRefundRequestUSCountry = this.refundRequestListDTO.countryCode === 'USA' ? true : false;
    this.setAddressFieldValidator(this.isRefundRequestUSCountry);

    // For Zip Code
    this.setZipCode(this.isRefundRequestUSCountry, true);

    // TODO: Delete this if CENTAURI-6493 passed
    // if (this.refundRequestListDTO.streetAddress1.length > 32) {
    //   this.billingData.validateRefundAddressCount(this.refundRequestListDTO.streetAddress1,this.refundRequestListDTO.streetAddress2, this.checkPaymentForm);
    // }

    this.checkPaymentForm.markAllAsTouched();
    this.checkPaymentForm.updateValueAndValidity();
  }

  onSaveRefund(): void {
    Utils.blockUI();
    this.billingService.editRefundRequest(this.getRefundPayload()).subscribe((response: any) => {
      this.refundRequestData.getRefundRequestList();
      this.bsModalRef.hide();
    }, err => {
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify(err.error));
    });
  }

  getRefundPayload(): UpdateRefundDetailsRequestDto {
    return {
      refundRequestId: this.refundRequestListDTO.id,
      name: this.checkPaymentForm.get('name').value?.toString().trim(),
      street1: this.checkPaymentForm.get('address').value?.toString().trim(),
      street2: this.checkPaymentForm.get('aptOrUnit').value?.toString().trim(),
      city: this.checkPaymentForm.get('city').value?.toString().trim(),
      state: this.checkPaymentForm.get('state').value?.toString().trim(),
      zip: this.checkPaymentForm.get('zip').value?.toString().trim(),
      countryCode: this.checkPaymentForm.get('country').value?.toString().trim(),
      countryName: LvCountries.find(a => a.value === this.checkPaymentForm.get('country').value)?.name.trim() ?? '',
      comments: this.checkPaymentForm.get('comments').value?.toString().trim(),
    };
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  setZipCode(isUSCountry?: boolean, isFromZipField?: boolean) {
    if (isFromZipField && !isUSCountry) { return; }

    this.checkPaymentForm.get('city').enable();
    const zipCode = this.checkPaymentForm.get('zip').value;
    let formControlNames = ['state', 'city', 'zip'];
    this.zipCodeData.checkCityList = [];
    this.isRefundRequestUSCountry = isUSCountry;
    Utils.blockUI();
    if (zipCode !== '' && isUSCountry) {
      this.zipCodeData.getGenericZipCode(zipCode, this.checkPaymentForm, formControlNames, PageSections.CheckPayment, false, ErrorMessageConstant.zipCodeNotFoundErrorMessage);
    } else {
      if (isUSCountry) { // IF US COUNTRY AS IS
        this.resetZipFields(formControlNames);
        Utils.unblockUI();
        NotifUtils.showError(ErrorMessageConstant.zipCodeNotFoundErrorMessage);
      } else { // IF NOT US COUNTRY
        formControlNames = ['state', 'city', 'zip', 'address'];
        this.resetZipFields(formControlNames);
        Utils.unblockUI();
      }
    }
    this.zipCodeData.billingCityListComplete();
  }

  onChangeCountry(countryCode: string): void {
    this.isRefundRequestUSCountry = countryCode === 'USA' ? true : false;

    const formControlNames = ['state', 'city', 'zip', 'address'];
    this.resetZipFields(formControlNames);
    this.checkPaymentForm.get('city').enable();
    this.setAddressFieldValidator(this.isRefundRequestUSCountry);
  }

  resetZipFields(formControlNames): void {
    this.FormUtils.resetFields(this.checkPaymentForm as FormGroup, formControlNames);
    this.zipCodeData.checkCityList = [];
  }

  setAddressFieldValidator(isUSCountry: boolean): void {
    if (isUSCountry) {
      this.generalValidationService.resetValidatorFormControl(this.checkPaymentForm, 'city', [Validators.required]);
      this.generalValidationService.resetValidatorFormControl(this.checkPaymentForm, 'state', [Validators.required]);
      this.generalValidationService.resetValidatorFormControl(this.checkPaymentForm, 'zip', [Validators.required]);
    } else {
      this.generalValidationService.clearValidatorFormControl(this.checkPaymentForm, 'state');
      this.generalValidationService.clearValidatorFormControl(this.checkPaymentForm, 'zip');
    }
  }
}
