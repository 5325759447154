import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RefundData } from 'app/modules/payments/data/refund.data';
import { LA_BillingService } from '../services/billing/billing.service';
import { PagerService } from 'app/core/services/pagerService';

@Injectable()
export class LA_RefundData extends RefundData {
  constructor(
    protected billingService: LA_BillingService,
    protected pagerService: PagerService,
    protected store: Store
  ) {
    super(
      billingService
      , pagerService
      , store
    );
  }
}