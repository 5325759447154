import * as endorsementsOptions from './endorsements.options.constants';

export const ReportMap = {
  AccreditedBuilder: {
    alias: 'AccBuilderFactor',
    reportLabel: 'Accredited Builder'
  },

  AnimalLiability: {
    alias: 'ALCovPremium',
    reportLabel: 'Animal Liability Endorsement',
    limitsControlName: 'animalLiabilityLimit'
  },

  BizTier: {
    alias: 'NewBussRenewalFactor',
    reportLabel: 'Tier Factor'
  },

  BuildingCodeFactor: {
    alias: 'BuildingCodeFactor',
    reportLabel: 'Building Code Effectiveness Grade'
  },

  CompanionPolicy: {
    alias: 'CompPolicyFactor',
    reportLabel: 'Companion Policy Discount'
  },

  ConsentToRate: {
    alias: 'ConsentToRate',
    reportLabel: 'Consent To Rate'
  },

  Construction: {
    alias: 'ProClassFactor',
    reportLabel: 'Protection Class/Construction'
  },

  CovAPremium: {
    alias: 'AdjBasePremium',
    reportLabel: ''
  },

  CovADP3Premium: {
    alias: 'TotalPerilPremiums',
    reportLabel: ''
  },

  CovEPremium: {
    alias: 'IS2CovEPremium',
    reportLabel: ''
  },

  CovFPremium: {
    alias: 'IS2CovFPremium',
    reportLabel: ''
  },

  CovLMPremium: {
    alias: 'CovLMPrem',
    reportLabel: ''
  },

  EMPATFSPrem: {
    alias: 'EMPATFSur',
    reportLabel: ''
  },

  EquipBreakdown: {
    alias: 'EBDPremium',
    reportLabel: 'Equipment Breakdown Enhancement Endorsement'
  },

  FinalPremium: {
    alias: 'FPrePlusFee',
    reportLabel: ''
  },

  FinancialResponsibility: {
    alias: 'FinancialRespFactor',
    reportLabel: 'Financial Responsibility Discount'
  },

  FlatTileRoof: {
    alias: 'FTRoofFactor',
    reportLabel: 'Flat Tile Roof Discount'
  },

  FloodCoverage: {
    alias: 'FloodCovPremium',
    reportLabel: 'Flood Coverage'
  },

  GolfCart: {
    alias: 'GCCovPremium',
    reportLabel: 'Golf Cart Physical Damage & Liability Endorsement'
  },

  HardiPlankFactor: {
    alias: 'HardiPlankFactor',
    reportLabel: 'Hardiplank Siding Discount'
  },

  HomeComputerCoverage: {
    alias: 'HCCovPremium',
    reportLabel: 'Home Computer Coverage',
    limitsControlName: 'homeComputerLimit',
    limitsOptionsList: endorsementsOptions.LvRaterHomeComputerLimit
  },

  IdentityFraud: {
    alias: 'IFECovPremium',
    reportLabel: 'Identity Fraud Expense Coverage'
  },

  IncSpecialLimit: {
    alias: 'ISLLiaPremium',
    reportLabel: 'Coverage C Increased Special Limits of Liability'
  },

  LimitedFungi: {
    alias: 'LFWDBCovPremium',
    reportLabel: 'Limited Fungi, Wet or Dry Rot, or Bacteria Coverage',
    limitsControlName: 'limitedFungiLimit',
    limitsOptionsList: endorsementsOptions.LvRaterLimitedFungiLimit
  },

  LimitedWaterDamage: {
    alias: 'LWDCovFactor',
    reportLabel: 'Limited Water Damage Coverage',
    limitsControlName: 'limitedWaterLimit'
  },

  LossAssessment: {
    alias: 'LACovPremium',
    reportLabel: 'Loss Assessment Coverage',
    limitsControlName: 'lossAssessmentLimit',
    limitsOptionsList: endorsementsOptions.LvRaterLossAssessmentLimit
  },

  MGAPolicyFeePrem: {
    alias: 'MGAF',
    reportLabel: ''
  },

  OrdinanceLawCoverage25: {
    alias: 'OrdLawPremium',
    reportLabel: 'Ordinance or Law Coverage - 25%'
  },

  OrdinanceLawCoverage50: {
    alias: 'OrdLawPremium',
    reportLabel: 'Ordinance or Law Coverage - 50%'
  },

  PersonalInjury: {
    alias: 'PICovPremium',
    reportLabel: 'Personal Injury'
  },

  PersPropOthResidences: {
    alias: 'ILPPORPremium',
    reportLabel: 'Increased Limit On Personal Property In Other Residences'
  },

  PersPropReplacement: {
    alias: 'PersonalProRep',
    reportLabel: 'Personal Property Replacement Cost Loss Settlement'
  },

  PersPropScheduled: {
    alias: 'PPSchedPremium',
    reportLabel: 'Personal Property - Scheduled'
  },

  PremisesAlarm: {
    alias: 'ProDevFireSprinkler',
    reportLabel: 'Premises Alarm or Fire Protection System'
  },

  PremiumAdjustment: {
    alias: 'PremiumAdj',
    reportLabel: 'Premium Adjustment'
  },

  ProtectiveDeviceBurglar: {
    alias: 'ProDevBurglar',
    reportLabel: 'Protective Device Discount - Burglar'
  },

  ProtectiveDeviceSprinkler: {
    alias: 'ProDevFireSprinkler',
    reportLabel: 'Protective Device Fire/Sprinkler'
  },

  RatingTier: {
    alias: 'CrePrioClaimTier',
    reportLabel: ''
  },

  RepCostDwelling: {
    alias: 'IncRCDPremium',
    reportLabel: 'Increased Replacement Cost on Dwelling'
  },

  SeasonalDwelling: {
    alias: 'AgeDwellingYear',
    reportLabel: 'Seasonal Dwelling Endorsement'
  },

  SECarportsCoverageACV: {
    alias: 'SECCovPremium',
    reportLabel: 'Screened Enclosure & Carport Cov - Actual Cash Value',
    limitsControlName: 'screenedEnclosureLimit'
  },

  SECarportsCoverageRC: {
    alias: 'SECCovPremium',
    reportLabel: 'Screened Enclosure & Carport Cov - Replacement Cost',
    limitsControlName: 'screenedEnclosureLimit'
  },

  SecondarySeasonal: {
    alias: 'SSSFactor',
    reportLabel: 'Secondary/Seasonal Surcharge'
  },

  SecuredCommunity: {
    alias: 'SecuredCommFactor',
    reportLabel: 'Secured Community/Building Discount'
  },

  SeniorRetiree: {
    alias: 'SrRetFactor',
    reportLabel: 'Senior Retiree Discount'
  },

  ServiceLineEndorsement: {
    alias: 'SLEndrPremium',
    reportLabel: 'Service Line Enhancement Endorsement',
    limitsControlName: 'serviceLineLimit'
  },

  SinkholeLoss: {
    alias: 'SHCovPremium',
    reportLabel: 'Sinkhole Loss Coverage'
  },

  SpecialPersProp: {
    alias: 'SPPCovPremium',
    reportLabel: 'Special Personal Property Coverage'
  },

  StarAdvPackage: {
    alias: 'SPPremium',
    reportLabel: 'Star Advantage Package'
  },

  StarPackage: {
    alias: 'SPPremium',
    reportLabel: 'Star Package'
  },

  TotalPremiumWithAdjustments: {
    alias: 'TotalPremiumWithAdj',
    reportLabel: ''
  },

  TotalPremium: {
    alias: 'TotalPremium',
    reportLabel: ''
  },

  UnitOwnersRental: {
    alias: 'UWROPremium',
    reportLabel: 'Unit-Owners Rental to Others'
  },

  WaterBackUp: {
    alias: 'WBPremium',
    reportLabel: 'Water Back Up and Sump Overflow',
    limitsControlName: 'waterBackupLimit'
  },

  WaterDamageExc: {
    alias: 'WDFactor',
    reportLabel: 'Water Damage Exclusion Endorsement'
  },

  WindMitigation: {
    alias: 'WStormLossFactor',
    reportLabel: 'Wind Mitigation Discount'
  },

  WoodBurningStove: {
    alias: 'WBSFactor',
    reportLabel: 'Wood Burning Stove Surcharge'
  },

  YearRoofInstalled: {
    alias: 'AgeRoofFactor',
    reportLabel: 'Age of Roof'
  },

  LimitedFungiDP3: {
    alias: 'LimFungPrem',
    reportLabel: 'Fungi, Wet or Dry Rot, or Bacteria Increased Amount of Coverage'
  },

  EquipBreakdownDP3: {
    alias: 'EquipBdownPrem',
    reportLabel: 'Equipment Breakdown Coverage Endorsement'
  },

  SECarportsCoverageACVDP3: {
    alias: 'ScreenEnclPrem',
    reportLabel: 'Screened Enclosure & Carport Cov - Actual Cash Value',
    limitsControlName: 'screenedEnclosureLimit'
  },

  SECarportsCoverageRCDP3: {
    alias: 'ScreenEnclPrem',
    reportLabel: 'Screened Enclosure & Carport Cov - Replacement Cost',
    limitsControlName: 'screenedEnclosureLimit'
  },

  LossAssessmentDP3: {
    alias: 'LossAssmtCovPrem',
    reportLabel: 'Loss Assessment Coverage',
    limitsControlName: 'lossAssessmentLimit',
    limitsOptionsList: endorsementsOptions.LvRaterLossAssessmentLimit
  },

  SinkholeLossDP3: {
    alias: 'SHCovPrem',
    reportLabel: 'Sinkhole Loss Coverage'
  },

  OrdinanceLawCoverage25DP3: {
    alias: 'OrdLawPremium',
    reportLabel: 'Ordinance or Law Coverage - 25%'
  },

  PersPropReplacementDP3: {
    alias: 'PersPropRCPrem',
    reportLabel: 'Personal Property Replacement Cost Loss Settlement'
  },

  PersonalLiabilityDP3: {
    alias: '',
    reportLabel: 'Personal Liability'
  },

  PremisesLiabilityDP3: {
    alias: '',
    reportLabel: 'Premises Liability'
  },

  PermittedIncidentalLiabDP3: {
    alias: '',
    reportLabel: 'Permitted Incidental Occupancies - Liability'
  },

  PermittedIncidentalDP3: {
    alias: '',
    reportLabel: 'Permitted Incidental Occupancies'
  },

  PersPropScheduledDP3: {
    alias: 'PersPropRCPrem',
    reportLabel: 'Personal Property - Scheduled'
  },

  ServiceLineEndorsementDP3: {
    alias: 'ServLineCovPrem',
    reportLabel: 'Service Line Enhancement Endorsement',
    limitsControlName: 'serviceLineLimit'
  },

  LimitedTheftDP3: {
    alias: 'LTheftCovPremium',
    reportLabel: 'Limited Theft Coverage'
  },

  WaterBackupDP3: {
    alias: 'WaterBackupPrem',
    reportLabel: 'Water Back Up and Sump Overflow',
    limitsControlName: 'waterBackupLimit'
  },

  VacancyDP3: {
    alias: '',
    reportLabel: 'Vacancy Endorsement'
  },

  WindstormExtPaintDP3: {
    alias: '',
    reportLabel: 'Windstorm Exterior Paint or Waterproofing Exclusion - Seacoast - Florida'
  }
};

export interface IReportMap {
  alias: string;
  limitsControlName?: string;
  limitsOptionsList?: any;
  reportLabel: string;
}
