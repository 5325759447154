import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { UWApprovalPageLabel } from 'app/shared/constants/uw-approval.labels.constants';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { UWApprovalModalModel } from 'app/shared/models/submission/uw-approval/uw-approval.model';
import { BsModalRef } from 'ngx-bootstrap';
import { UwApprovalData } from '../../../data/uw-approval.data';
import { IHideTableItems } from 'app/shared/models/dynamic/table.interface';
import { UwApprovalSavingData } from 'app/modules/submission-management/data/uw-approval-saving.data';
import { BaseClass } from 'app/shared/base-class';
import { takeUntil } from 'rxjs/operators';
import { TableNameConstants } from '../../../../../shared/constants/table.name.constants';
import { PolicyService } from 'app/core/services/submission/policy.service';

@Component({
  selector: 'app-uw-approval-file-modal',
  templateUrl: './uw-approval-file-modal.component.html',
  styleUrls: ['./uw-approval-file-modal.component.scss']
})
export class UwApprovalFileModalComponent extends BaseClass implements OnInit, OnDestroy {
  public event: EventEmitter<any> = new EventEmitter<any>();
  public openUploadFileModal: EventEmitter<any> = new EventEmitter<any>();
  public ErrorMessageConstant = ErrorMessageConstant;
  public UWApprovalLabel = UWApprovalPageLabel;
  public TableNameConstants = TableNameConstants;
  modalRef: BsModalRef | null;
  title: any;
  uwApprovalModel: UWApprovalModalModel;
  uwApprovalModalData: UWApprovalModalModel[];
  rowId: any;
  uwrDescription: string;
  isCancel: boolean = false;
  hideItems: IHideTableItems = {
    addButton: true,
    updateIcon: true,
    checkboxButton: true,
    viewIcon: true
  };

  constructor(
    public bsModalRef: BsModalRef,
    public uwApprovalData: UwApprovalData,
    public uwApprovalSavingData: UwApprovalSavingData,
    protected policyService: PolicyService
  ) {
    super();
  }

  ngOnInit() {
    this.uwApprovalData.selectedRowId = this.rowId;
    this.initData();
    this.subscribeToUwrSavingData();
  }

  ngOnDestroy(): void {
    this.rowId = '';
    this.uwrDescription = '';
  }

  initData(): void {
    let row = this.uwApprovalData.uwApprovalTableRows.filter(item => {
      return item.id === this.rowId;
    });

    if (row?.length === 0) {
      row = this.uwApprovalData.uwApprovalTableRows.filter(item => {
        return item.tr[0].display === this.uwrDescription;
      });
      this.uwApprovalData.selectedRowId = row[0]?.id;
    }

    this.uwApprovalData.uwApprovalFileTableRows = [];

    if (row[0].files) {
      row[0].files.forEach(item => {
        this.uwApprovalData.uwApprovalFileTableRows.push({
          id: item.fileId ? item.fileId : item.id,
          tr: [
            {
              id: item.fileId ? item.fileId : item.id,
              value: item.fileName,
              display: item.fileName
            },
            {
              id: item.fileId ? item.fileId : item.id,
              value: item.dateAdded,
              display: item.dateAdded
            }
          ]
        });
      });
    }
  }

  openUploadFile() {
    this.openUploadFileModal.emit(this.rowId);
  }

  hideModal(): void {
    this.isCancel = true;
    this.bsModalRef.hide();
  }

  deleteTableItem(rowId: any, tableRow: string): void {
    this.uwApprovalData[tableRow].forEach((item, index) => {
      if (item.id === rowId) {
        this.uwApprovalData[tableRow].splice(index, 1);
      }
    });

    this.uwApprovalData.uwApprovalTableRows.map(item => {
      item.files = item.files?.filter((files) => {
        if (files?.fileId) {
          return files.fileId !== rowId;
        } else {
          return files.id !== rowId;
        }
      });

      return item;
    });

    this.uwApprovalSavingData.saveUWApproval();

  }

  onFileClick(rowId: any): void {
    this.uwApprovalData.uwApprovalTableRows.find(item => {
      const uploadedFile = item?.files.find(a => a.id === rowId);

      if (Boolean(uploadedFile)) { 
        this.policyService.generateSASURL(uploadedFile.filePath).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
          window.open(resultSASURL);
        });
      }
    });
  }

  subscribeToUwrSavingData(): void {
    this.uwApprovalData.uwApprovalListComplete$.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res) {
        // this.uwApprovalData.initUWRData();
        this.initData();
      }
    });
  }
}
