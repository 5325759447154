import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PolicyIssueComponent } from './policy-issue.component';
import { SharedModule } from 'app/shared/shared.module';
import { IssuePolicyComponent } from './issue-policy/issue-policy.component';
import { PolicyChangesComponent } from './policy-changes/policy-changes.component';
import { TextForEndorsementComponent } from './text-for-endorsement/text-for-endorsement.component';
import { PremiumChangesComponent } from './premium-changes/premium-changes.component';
import { ShowIssueButtonPipe } from './pipes/show-issue-button.pipe';
import { GetPolicyIssueValidationMessagePipe } from './pipes/get-policy-issue-validation-message.pipe';
import { IsIssueDisabledPipe } from './pipes/is-issue-disabled.pipe';

const routes: Routes = [
  { path: '', component: PolicyIssueComponent}
];

@NgModule({
  declarations: [
    PolicyIssueComponent,
    IssuePolicyComponent,
    PolicyChangesComponent,
    TextForEndorsementComponent,
    PremiumChangesComponent,
    ShowIssueButtonPipe,
    GetPolicyIssueValidationMessagePipe,
    IsIssueDisabledPipe
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule
  ],
  exports: [PolicyIssueComponent]
})
export class PolicyIssueModule { }
