import { Component, OnInit } from '@angular/core';
import { SuspendedPaymentDetailsDTO } from 'app/shared/models/data/dto/suspended-payment/suspended-payment-detail.dto';
import { DetailsSuspendedPaymentModalComponent } from '../pending-suspended-modal/details-suspended-payment-modal/details-suspended-payment-modal.component';
import { PostSuspendedComponent } from '../pending-suspended-modal/post-suspended-payment-modal/post-suspended.component';
import { SuspendedPaymentsData } from '../../../../../modules/payments/data/suspended-payments.data';
import { SuspendedPaymentsLabel } from '../../../../../shared/constants/suspended-payments.labels.constants';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { AddSuspendedPaymentModalComponent } from '../pending-suspended-modal/add-suspended-payment-modal/add-suspended-payment-modal.component';
import { BaseClass } from '../../../../../shared/base-class';
import { takeUntil } from 'rxjs/operators';
import { ReturnModalComponent } from '../pending-suspended-modal/return-suspended-payment-modal/return-suspended-payment-modal.component';
import { VoidModalComponent } from '../pending-suspended-modal/void-suspended-payment-modal/void-suspended-payment-modal.component';
import { PolicyService } from '../../../../../../app/core/services/submission/policy.service';
import Utils from '../../../../../../app/shared/utilities/utils';
import { PolicySearchResultDTO } from '../../../../../../app/shared/models/data/dto/suspended-payment/policy-list-dto';
import { SuspendedPaymentType } from '../../../../../../app/shared/enum/suspended-payment-type.enum';
import NotifUtils from '../../../../../../app/shared/utilities/notif-utils';
import { forkJoin } from 'rxjs';
import { UploadIpxModalComponent } from '../pending-suspended-modal/upload-ipx-modal/upload-ipx-modal.component';
import { GenerateIpxSuspenseReportModalComponent } from '../pending-suspended-modal/generate-ipx-suspense-report-modal/generate-ipx-suspense-report-modal.component';
import { PutForIPXRequestDTO } from '../../../../../shared/models/data/dto/suspended-payment/put-for-ipx-request.dto';

@Component({
  selector: 'app-pending-suspended',
  templateUrl: './pending-suspended.component.html',
  styleUrls: ['./pending-suspended.component.scss']
})
export class PendingSuspendedComponent extends BaseClass implements OnInit {
  public SuspendedPaymentsLabel = SuspendedPaymentsLabel;
  isOpen: boolean = true;
  modalRef: BsModalRef;

  isSiebelDataWithDuplicatePolicyNumber: boolean;

  constructor(public suspendedPaymentsData: SuspendedPaymentsData, private modalService: BsModalService,
    private policyService: PolicyService) {
    super();
  }

  ngOnInit() {
  }

  setPage(page: number) {
    this.suspendedPaymentsData.suspendedPaymentListSetPage(page);
  }

  onReturnClick(item: SuspendedPaymentDetailsDTO): void {
    // # Keeping this code in case of rollback
    // Utils.blockUI();
    // this.suspendedPaymentsData.getDetailedSuspendedView(item.id);
    // this.suspendedPaymentsData.detailsViewNotifier.take(1).subscribe(res => {
    //   Utils.unblockUI();

    //   const initialState = {
    //     paymentDetail: res.details
    //   };
    //   this.showPendingSuspendedModal(ReturnModalComponent, initialState);
    // });

    Utils.blockUI();

    let searchedPolicy: PolicySearchResultDTO = null;

    this.suspendedPaymentsData.getDetailedSuspendedView(item.id);

    const $detailsViewNotifier = this.suspendedPaymentsData.detailsViewNotifier;
    const $searchPolicies = this.policyService.searchPolicies(item.policyNumber);

    forkJoin([$detailsViewNotifier.take(1), $searchPolicies])
      .pipe(
        takeUntil(this.stop$),
      ).subscribe(([supendedPaymentRes, searchPolicyRes]) => {
        this.isSiebelDataWithDuplicatePolicyNumber = searchPolicyRes.length > 1 && searchPolicyRes[0].isSiebel;

        if (searchPolicyRes.length === 1 && searchPolicyRes[0].isExactSearch) {
          searchedPolicy = searchPolicyRes[0];
        } else {
          if (this.isSiebelDataWithDuplicatePolicyNumber) {
            const actOrPexpSiebelDataWithDuplicatePolicyNumber = searchPolicyRes.filter(x => x.riskStatusCode === 'ACT' || x.riskStatusCode === 'PEXP');
            const latestActOrPexpSiebelDataWithDuplicatePolicyNumber = actOrPexpSiebelDataWithDuplicatePolicyNumber.sort((a,b) => new Date(b.firstIssueDate).getTime() - new Date(a.firstIssueDate).getTime())[0];
            searchedPolicy = latestActOrPexpSiebelDataWithDuplicatePolicyNumber;
          }
        }

        const initialState = {
          paymentDetail: supendedPaymentRes.details,
          policySearchResult: searchedPolicy
        };

        this.showPendingSuspendedModal(ReturnModalComponent, initialState);
      },
        err => {
          Utils.unblockUI();
          NotifUtils.showMultiLineError(err.error?.message);
        }
      );
  }

  onVoidClick(item: SuspendedPaymentDetailsDTO): void {
    Utils.blockUI();
    this.suspendedPaymentsData.getDetailedSuspendedView(item.id);
    this.suspendedPaymentsData.detailsViewNotifier.take(1).subscribe(res => {
      Utils.unblockUI();

      const initialState = {
        paymentDetail: res.details
      };
      this.showPendingSuspendedModal(VoidModalComponent, initialState);
    });
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }

  onDetailsClick(item?: any): void {
    Utils.blockUI();
    this.suspendedPaymentsData.getDetailedSuspendedView(item.id);
    this.suspendedPaymentsData.detailsViewNotifier.take(1).subscribe(res => {
      Utils.unblockUI();
      const initialState = {
        paymentDetail: res.details,
        batchPaymentDetailId: item.batchPaymentDetailsId,
        hasReturnDetails: false
      };
      this.showPendingSuspendedModal(DetailsSuspendedPaymentModalComponent, initialState);
    });
  }


  onAdd(): void {
    this.modalRef = this.showPendingSuspendedModal(AddSuspendedPaymentModalComponent);

    this.modalRef.content.onClose.subscribe(result => {
      switch (result) {
        case true:
          this.suspendedPaymentsData.getSuspendedPaymentList();
          break;
        case false:
          break;
      }
    });
  }

  showPendingSuspendedModal(component?, initialState?): BsModalRef {
    return this.modalService.show(component, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
  }


  onPostClick(item: SuspendedPaymentDetailsDTO): void {

    let searchedPolicy: PolicySearchResultDTO = null;

    this.suspendedPaymentsData.getDetailedSuspendedView(item.id);

    const $detailsViewNotifier = this.suspendedPaymentsData.detailsViewNotifier;
    const $searchPolicies = this.policyService.searchPolicies(item.policyNumber);

    Utils.blockUI();
    forkJoin([$detailsViewNotifier.take(1), $searchPolicies])
      .pipe(
        takeUntil(this.stop$),
      ).subscribe(([supendedPaymentRes, searchPolicyRes]) => {
        this.isSiebelDataWithDuplicatePolicyNumber = searchPolicyRes.length > 1 && searchPolicyRes[0].isSiebel;
        Utils.unblockUI();

        if (searchPolicyRes.length === 1 && searchPolicyRes[0].isExactSearch) {
          searchedPolicy = searchPolicyRes[0];
        }

        if (this.isSiebelDataWithDuplicatePolicyNumber) {
          const actOrPexpSiebelDataWithDuplicatePolicyNumber = searchPolicyRes.filter(x => x.riskStatusCode === 'ACT' || x.riskStatusCode === 'PEXP');
          const latestActOrPexpSiebelDataWithDuplicatePolicyNumber = actOrPexpSiebelDataWithDuplicatePolicyNumber.sort((a,b) => new Date(b.firstIssueDate).getTime() - new Date(a.firstIssueDate).getTime())[0];
          searchedPolicy = latestActOrPexpSiebelDataWithDuplicatePolicyNumber;
        }

        this.showPostSuspendedModal(supendedPaymentRes.details, searchedPolicy);
      },
        err => {
          Utils.unblockUI();
          NotifUtils.showMultiLineError(err.error?.message);
        }
      );
  }

  showPostSuspendedModal(item: SuspendedPaymentDetailsDTO, searchedPolicy: PolicySearchResultDTO) {
    item.status = SuspendedPaymentType.Pending;

    const initialState = {
      isReversal: true,
      paymentDetail: item,
      policySearchResult: searchedPolicy
    };

    this.modalService.show(PostSuspendedComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
  }

  onToggleForIpx(val: boolean, suspendedPaymentId: string): void {
    const payload: PutForIPXRequestDTO = {
      suspendedPaymentId,
      forIPX: val
    };

    this.suspendedPaymentsData.updateForIPX(payload);
  }

  showUploadIpxModal(): void {
    const initialState = {
      title: ''
    };
    this.modalRef = this.modalService.show(UploadIpxModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  onIpxSuspenseReport(): void {
    const initialState = {
      title: SuspendedPaymentsLabel.Pending.Report.header,
    };
    this.modalRef = this.modalService.show(GenerateIpxSuspenseReportModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog modal-sm'
    });
  }

  onSplitPayment(item?: SuspendedPaymentDetailsDTO): void {
    Utils.blockUI();
    this.suspendedPaymentsData.isSplitPayment = true;
    this.suspendedPaymentsData.getDetailedSuspendedView(item.id);
    this.suspendedPaymentsData.detailsViewNotifier.take(1).subscribe(res => {
      Utils.unblockUI();

      const initialState = {
        paymentDetail: res.details,
        hasReturnDetails: false
      };
      this.showPendingSuspendedModal(DetailsSuspendedPaymentModalComponent, initialState);
    });
  }
}
