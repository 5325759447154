import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { BaseClass } from '../../../shared/base-class';
import { SummaryData } from './summary.data';
import { ZipData } from './zip.data';
import { EntityRiskData } from './entity-risk.data';
import { EntityRiskDTO } from '../../../shared/models/data/dto/quick-quote/entity-risk.dto';
import { ApplicantData } from './applicant.data';
import { CoveragesData, MAXCOVA, MINCOVA } from './coverages.data';
import { PropertyData } from './property.data';
import { SubmissionPageData } from './submission-page.data';
import { LvConstructionType, LvProtectionClass, LvRaterExteriorWallFinish, LvRoofMaterial } from '../../../shared/constants/property.options.constants';
import { RiskPropertyDTO3 } from '../../../shared/models/data/dto/quick-quote/risk-property.dto';
import { ApplicantBusinessRules } from '../business-rules/applicant.business-rules';
import { ZipcodesService } from '../../../core/services/submission/zipcodes/zipcodes.service';
import { LvZipCodeDTO } from '../../../shared/models/data/dto/quick-quote/lv-zipcode.dto';
import { ZipCodeData } from './zipcode.data';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { NameCasePipe } from '../../../shared/custom pipes/name-pipe';
import { PreviousApplicantValuesService } from '../../../core/services/submission/others/previous-applicant-values.service';
import { AddressType } from '../../../shared/enum/address-type.enum';
import { FormTypeConstants } from '../../../shared/constants/form-types.constants';
import { QuoteService } from '../../../core/services/quote.service';
import { AuthService } from '../../../core/services/auth.service';
import { AddressDTO3 } from 'app/shared/models/data/dto/quick-quote/entity-address.dto';
import { PolicyService } from 'app/core/services/submission/policy.service';
import { PolicyIssueData } from 'app/modules/policy-management/data/policy-issue.data';
import { Subject } from 'rxjs';
import { IGetAgencyPolicyCommissionRateRequestDTO } from 'app/shared/models/management/agency-management/agency-details.response.dto';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class QuickQuoteData extends BaseClass {
  Applicant: FormGroup;
  Coverage: FormGroup;
  cities: any[];
  states: any[];
  protectionClasses: any[];
  constructionTypes: any[];
  exteriorWallFinish: any[];
  roofMaterials: any[];
  protectionClass: any[];
  zipList: any[];
  effectiveDate: IAngularMyDpOptions;
  dateOfBirth: IAngularMyDpOptions;
  isRetired: boolean;
  orderPrefill: boolean = false;
  cityList: any[] = [];
  isOrderCreditValue: boolean = false;
  formTypeConstants = FormTypeConstants;

  isFromRewrite: boolean = false;
  isQQHasErrorMessage: boolean = false;

  isPolicyChangesSeniorRetireeCompleted = new Subject<boolean>();
  isPolicyChangesSeniorRetireeCompleted$ = this.isPolicyChangesSeniorRetireeCompleted.asObservable();

  constructor(protected summaryData: SummaryData,
    protected zipData: ZipData,
    public entityRiskData: EntityRiskData,
    public applicantData: ApplicantData,
    public coveragesData: CoveragesData,
    public propertyData: PropertyData,
    public submissionData: SubmissionPageData,
    protected applicantBusinessRules: ApplicantBusinessRules,
    protected zipCodeService: ZipcodesService,
    protected zipCodeData: ZipCodeData,
    protected namePipe: NameCasePipe,
    protected previousValueService: PreviousApplicantValuesService,
    protected quoteService: QuoteService,
    protected authService: AuthService,
    protected policyService: PolicyService,
    protected policyIssueData: PolicyIssueData
    ) {
    super();
  }

  get summaryDataForm() {
    return this.summaryData.SummaryForm;
  }

  async initForms() {
    this.resetCoveragesVariables();
    this.Applicant = this.applicantSection();
    this.Coverage = this.coverageSection();
    this.applicantDropdownValues();
    this.propertyDropdownValues();
    this.dateFormatOptions();
    this.isRetired = false;
  }

  applicantSection() {
    const currentDate = this.authService.getCustomDate();
    return new FormGroup({
      firstName: new FormControl('', Validators.required),
      middleInitial: new FormControl(''),
      lastName: new FormControl('', Validators.required),
      dateOfBirth: new FormControl('', Validators.required),
      isRetired: new FormControl(false, []),
      effectiveDate: new FormControl({
        singleDate: {
          date: {
            year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
            day: currentDate.getDate()
          },
          jsDate: currentDate
        }
      }, Validators.required),
      addressLine1: new FormControl('', Validators.required),
      addressLine2: new FormControl(''),
      city: new FormControl(null, Validators.required),
      state: new FormControl('', Validators.required),
      zipCode: new FormControl('', Validators.required),
      isOrderYesNo: new FormControl(false),
      isRetiredYesNo: new FormControl(false)
    });
  }

  coverageSection() {
    return new FormGroup({
      covA: new FormControl(0, [Validators.required, Validators.min(
        this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3 ? MINCOVA : 0
      ), Validators.max(MAXCOVA)]),
      allOtherPerils: new FormControl(null, Validators.required),
      hurricaneDeductibleid: new FormControl(1, Validators.required)
    });
  }

  applicantDropdownValues() {
    this.states = [
      { name: 'AL', value: 1 },
      { name: 'LA', value: 2 },
      { name: 'FL', value: 3 },
      { name: 'MS', value: 4 },
      { name: 'SC', value: 5 },
      { name: 'TX', value: 6 }
    ];
    this.zipList = this.zipData.zipList;
  }

  propertyDropdownValues() {
    this.protectionClasses = LvProtectionClass;

    this.constructionTypes = LvConstructionType;

    this.exteriorWallFinish = LvRaterExteriorWallFinish;

    this.roofMaterials = LvRoofMaterial.filter(val => val.isActive === true);
    this.protectionClass = LvProtectionClass;
  }

  dateFormatOptions() {
    this.effectiveDate = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy'
    };

    this.dateOfBirth = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy',
      disableUntil: {
        year: 1799,
        month: 12,
        day: 31,
      },
      disableSince: {
        year: (this.authService.getCustomDate()).getFullYear(),
        month: (this.authService.getCustomDate()).getMonth() + 1,
        day: (this.authService.getCustomDate()).getDate() + 1
      }
    };
  }

  setHeaderDetail(formControlName: string, value: any) {
    this.summaryDataForm.get(formControlName).setValue(value);
  }

  public populateQuickQuotePage(data: EntityRiskDTO) {
    this.submissionData.isSuccessfulOrder = data?.risks[0]?.isOrderPrefillSuccess;
    const isFromRewrite = data?.risks[0]?.isFromRewrite;

    const address = data?.entityAddresses.find(a => a.addressTypeCode === AddressType.Property).address;
    const riskProperty = data?.risks[0].riskDetails[0]?.riskProperties[0];
    const riskCoverage = data?.risks[0].riskDetails[0]?.riskCoverages[0];
    const riskDetails = data?.risks[0].riskDetails[0];
    this.isOrderCreditValue = data?.risks[0]?.id && data?.isOrderCredit;

    this.previousValueService.Applicant = {
      firstName: this.namePipe.transform(data?.firstName),
      middleInitial: this.namePipe.transform(data?.middleName),
      lastName: this.namePipe.transform(data?.lastName),
      dateOfBirth: new Date(data?.birthDate),
      nameSuffix: data?.nameSuffix
    };

    //Rewrite
    this.isFromRewrite = isFromRewrite;

    // Applicant
    this.applicantData.mainApplicant.get('firstName').setValue(this.namePipe.transform(data?.firstName));
    this.applicantData.mainApplicant.get('middleInitial').setValue(this.namePipe.transform(data?.middleName));
    this.applicantData.mainApplicant.get('nameSuffix').setValue(data?.nameSuffix);
    this.applicantData.mainApplicant.get('lastName').setValue(this.namePipe.transform(data?.lastName));

    this.applicantData.mainApplicant.get('effectiveDate').setValue({ isRange: false, singleDate: { jsDate: new Date(data?.risks[0].riskDetails[0]?.effectiveDate) } });
        sessionStorage.setItem('selectedEffectiveDate', new Date(data?.risks[0].riskDetails[0]?.effectiveDate).toDateString());
    this.applicantData.mainApplicant.get('dateOfBirth').setValue({ isRange: false, singleDate: { jsDate: new Date(data?.birthDate) } });
    const age = this.applicantBusinessRules.calculateInsuredAge(data?.birthDate);
    this.isRetired = this.applicantBusinessRules.isInAgeOfRetirement(age);
    this.applicantData.age = age;
    this.applicantData.isRetired = this.isRetired;
    this.applicantData.mainApplicant.get('isRetiredYesNo').setValue(data?.isRetired);

    this.coveragesData.discountsForm.get('seniorRetiree').setValue(data?.isRetired || data?.coEntity?.isRetired);

    this.applicantData.mainApplicant.get('addressLine1').setValue(address?.streetAddress1);
    this.applicantData.mainApplicant.get('addressLine2').setValue(address?.streetAddress2);
    this.populateCityList(address?.zipCode);
    this.populateMainApplicantAddress(address);
    this.applicantData.mainApplicant.get('isOrderYesNo').setValue(data?.isOrderCredit);
    this.applicantData.mainApplicant.get('screenScrapeSource').setValue(riskDetails?.screenScrapeSource);

    // Property
    this.propertyData.propertyDetails.get('yearBuilt').setValue(riskProperty?.yearBuilt);
    this.propertyData.locationDetails.get('protectionClass').setValue(riskProperty?.protectionClassCode);
    this.propertyData.propertyDetails.get('exteriorWallFinish').setValue(riskProperty?.raterExteriorWallFinishCode);
    this.propertyData.propertyDetails.get('constructionType').setValue(riskProperty?.raterConstructionTypeCode);
    this.propertyData.propertyDetails.get('squareFootage').setValue(riskProperty?.squareFootage);
    this.propertyData.propertyDetails.get('roofMaterial').setValue(riskProperty?.roofMaterialCode);
    this.propertyData.propertyDetails.get('isFlatTile').setValue(riskProperty?.isFlatTile ?? false);
    this.propertyData.propertyDetails.get('yearRoof').setValue(this.quoteService.formType !== this.formTypeConstants.HO4 ? riskProperty?.yearRoofInstalled : null);

    // Coverages
    this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').setValue(riskCoverage?.covA);
    this.coveragesData.validCovA = +riskCoverage?.covA;
    this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').setValue(riskCoverage?.covC);
    this.coveragesData.validCovC = +riskCoverage?.covC;
    this.coveragesData.coveragesAndDeductiblesForm.get('allOtherPerilsId').setValue(riskCoverage?.allOtherPerilsDeductible);
    this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValue(riskCoverage?.hurricaneDeductible);
    this.orderPrefill = this.summaryData.SummaryForm.get('entityId').value ? true : false;
    this.submissionData.isOrder = true;
    this.applicantBusinessRules.updateApplicantNameValidators(this.authService.userType?.value);

  }

  public callRecheckMoratorium(isNewQuote: boolean = false, effectiveDate?): void {
    if ((!this.entityRiskData.isNewQuote && !this.entityRiskData.isFromQQSaveMoratorium) || (isNewQuote)) {
      const zipCode = this.applicantData.mainApplicant.get('qqZipCode').value;
      const state = this.applicantData.mainApplicant.get('qqState').value;
      if (isNewQuote) {
        this.zipCodeData.effectiveDateChanged = effectiveDate.toLocaleString().toLowerCase() !== 'invalid date' ? effectiveDate : this.zipCodeData.effectiveDateChanged;
      } else {
        this.zipCodeData.effectiveDateChanged = '';
      }

      const countyFromProperty = this.propertyData.locationDetails.get('county').value;

      this.zipCodeData.reCheckMoratorium(effectiveDate, zipCode, state, this.applicantData?.mainApplicant, isNewQuote, this.applicantData?.copiedInformation, countyFromProperty);
    }

    this.entityRiskData.isFromQQSaveMoratorium = false;
  }

  populateMainApplicantAddress(address: AddressDTO3): void { // to avoid case sensitivity in populating city and state
    const zipCode = address?.zipCode;
    this.applicantData.mainApplicant.get('qqZipCode').setValue(zipCode);
    if (zipCode || zipCode !== '') {
      this.zipCodeService.getZipCode(zipCode).pipe(takeUntil(this.stop$)).subscribe(res => {
        const zipCodeRes: any = res;
        const cityState = zipCodeRes.find(value => value.city.toLowerCase().includes(address?.city?.toLocaleLowerCase()));
        this.applicantData.mainApplicant.get('qqCity').setValue(cityState?.city);
        this.applicantData.mainApplicant.get('qqState').setValue(cityState?.stateCode);
      });
    }
  }

  populateCityList(zipCode: string) {
    // this.cityList = this.zipList.filter(data => data.address.zip === zipCode);
    if (zipCode || zipCode !== '') {
      this.zipCodeService.getZipCode(zipCode).pipe(takeUntil(this.stop$)).subscribe(res => {
        const zipCodeRes: any = res;
        this.zipCodeData.qqCityList = [];

        zipCodeRes.map(val => {
          const city = val.city;
          this.zipCodeData.qqCityList.push(city);
        });
      });
    }
  }

  getQuickQuoteForms() {
    return {
      Applicant: this.Applicant,
      Coverage: this.Coverage,
      propertyDetails: this.propertyData.propertyDetails,
      propertyLocation: this.propertyData.locationDetails
    };
  }

  public resetCoveragesVariables(): void {
    this.coveragesData.validCovA = null;
    this.coveragesData.validCovC = null;
    this.coveragesData.creditScoreDateOrdered = null;
  }

  orderCreditSetter(): void {
    const isOrder = this.applicantData.mainApplicant.get('isOrderYesNo');

    if (!isOrder.value || (isOrder.value && !this.isOrderCreditValue)) {
      this.isOrderCreditValue = false;
    } else {
      this.isOrderCreditValue = true;
    }
  }


  setSeniorRetireeValue(mainApplicant?: boolean): boolean {
    let isRetiredGreaterOrEqualTo60BRULCOV20_CoApplicant: boolean = false;
    let isInAgeOfRetirement_CoApplicant: boolean = false;
    // Main Applicant DOB
    const birthDate = new Date(this.applicantData.mainApplicant.get('dateOfBirth').value?.singleDate?.jsDate?.toLocaleDateString('en-US'));
    const age = this.applicantBusinessRules.calculateInsuredAge(birthDate);
    const isRetiredGreaterOrEqualTo60BRULCOV20_Main = this.applicantBusinessRules.isInAgeOfRetirementBRULCOV20(age);
    const isInAgeOfRetirement_Main = this.applicantBusinessRules.isInAgeOfRetirement(age);
    // Co Applicant DOB
    const birthDate_CoApplicant = new Date(this.applicantData.coApplicant.get('coApplicantDateOfBirth').value?.singleDate?.jsDate?.toLocaleDateString('en-US'));
    if (birthDate_CoApplicant) {
      const age_CoApplicant = this.applicantBusinessRules.calculateInsuredAge(birthDate_CoApplicant);
      isRetiredGreaterOrEqualTo60BRULCOV20_CoApplicant = this.applicantBusinessRules.isInAgeOfRetirementBRULCOV20(age_CoApplicant);
      isInAgeOfRetirement_CoApplicant = this.applicantBusinessRules.isInAgeOfRetirement(age_CoApplicant);
      this.applicantData.coApplicantIsRetired = this.applicantBusinessRules.isInAgeOfRetirement(age_CoApplicant);

    }

    const isRetired = isRetiredGreaterOrEqualTo60BRULCOV20_Main ? true : this.applicantData.mainApplicant.get('isRetiredYesNo').value;
    const isRetired_CoApplicant = isRetiredGreaterOrEqualTo60BRULCOV20_CoApplicant ? true : this.applicantData?.coApplicant?.get('coApplicantisRetiredYesNo')?.value;
    //DP Logic
    const propertyUsage = this.propertyData?.locationDetails?.get('propertyUsage')?.value;
    const isValidPropertUsage_SeniorRetiree = propertyUsage === 'OOP' || propertyUsage === 'OOS' || propertyUsage === 'SS'; //Owner Occupied Primary/Secondary and Seasonal
    const isValidResidenceRented = !this.propertyData?.locationDetails?.get('isResidenceRented')?.value;
    // Combination of CoApplicant and Main Applicant Logic
    const isRetiredGreaterOrEqualTo60BRULCOV20 = isRetiredGreaterOrEqualTo60BRULCOV20_Main || isRetiredGreaterOrEqualTo60BRULCOV20_CoApplicant;
    const isInAgeOfRetirement = isInAgeOfRetirement_Main || isInAgeOfRetirement_CoApplicant;

    //Hide/Show of Senior Retiree Field
    this.coveragesData.isRetiredGreaterOrEqualTo60BRULCOV20 = isRetiredGreaterOrEqualTo60BRULCOV20 || isInAgeOfRetirement;
    this.coveragesData.isSeniorOrRetired = isRetiredGreaterOrEqualTo60BRULCOV20 || (isInAgeOfRetirement && (isRetired || isRetired_CoApplicant));

    if (this.quoteService.formType !== FormTypeConstants.DP3) {
      this.coveragesData.discountsForm.get('seniorRetiree').setValue(isRetiredGreaterOrEqualTo60BRULCOV20 || (isInAgeOfRetirement && (isRetired || isRetired_CoApplicant)));
    } else {
      this.coveragesData.discountsForm.get('seniorRetiree').setValue((isRetiredGreaterOrEqualTo60BRULCOV20 || (isInAgeOfRetirement && (isRetired || isRetired_CoApplicant))) && isValidPropertUsage_SeniorRetiree && isValidResidenceRented);
    }

    return mainApplicant ? isRetired : isRetired_CoApplicant;
  }

  getPolicyChangesForSeniorRetiree(isMainApplicant?: boolean): void {
    if (this.entityRiskData.getPolicyStatus() !== 'CAN') {

      if (this.policyIssueData.policyChangesSeniorRetireeSubscription) {
        this.policyIssueData.policyChangesSeniorRetireeSubscription.unsubscribe();
      }

      if (this.entityRiskData.getRiskId() && this.entityRiskData.getRiskDetailId()) {
        this.policyIssueData.policyChangesSeniorRetireeSubscription = this.policyService.getPolicyChanges(this.entityRiskData.getRiskId(), this.entityRiskData.getRiskDetailId()).pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe((policyChanges: Array<string>) => {
          if(policyChanges?.find(list => list.includes('Retired?') || list.includes('Birth Date'))?.length > 0) {
            this.setSeniorRetireeValue(isMainApplicant);
            this.coveragesData.saveCoverages();
          } else {
              this.coveragesData.discountsForm?.get('seniorRetiree')?.setValue(this.entityRiskData?.ParentBackUpRiskData?.riskDiscounts[0]?.isSrRetiree);
              this.coveragesData.saveCoverages();
          }
        }, error => {

        }, () => {
          this.coveragesData.saveCoverages();
        });
      }

    }
  }

  public getCommissionRate(isUpdatingEffectiveDate: boolean = false, hasSubscription: boolean = false): void {
    if (!this.entityRiskData.isFromQQSaveCommissionRate) {
      const isNewQuote = this.entityRiskData.getRiskId() ? false : true;
      const territoryCode = this.propertyData.locationDetails.get('territoryCode').value;
      const effectiveDate = isUpdatingEffectiveDate ?
        this.applicantData.mainApplicant.get('effectiveDate')?.value?.jsDate :
        this.applicantData.mainApplicant.get('effectiveDate')?.value?.singleDate?.jsDate;

      if (!effectiveDate) {
        return;
      }

      let riskId: string = null;
      let stateCode: string = null;
      let agencyId: string = null;
      let formType: string = null;
      let transactionType: string = null;

      if (isNewQuote && territoryCode) {
        riskId = null;
        stateCode = this.applicantData.mainApplicant.get('qqState').value;
        const agencies = JSON.parse(localStorage.getItem('agencies'));
        agencyId = agencies?.id;
        formType = this.summaryData.SummaryForm.get('formType').value;
        transactionType = 'NB';
      } else if (!isNewQuote) {
        riskId = this.entityRiskData?.EntityRisk?.risks[0]?.id ?? null;
        stateCode = this.entityRiskData?.EntityRisk?.risks[0]?.riskStateCode ?? null;
        agencyId = this.entityRiskData?.EntityRisk?.risks[0]?.agencyId;
        formType = this.entityRiskData?.EntityRisk?.risks[0]?.formType;
        transactionType = this.entityRiskData?.EntityRisk?.risks[0]?.isFromRenewal ? 'RN' : 'NB';
      } else {
        return;
      }

      const formattedEffectiveDate = new DatePipe('en-US').transform(effectiveDate, 'MM-dd-yyyy');
      const systemDate = new DatePipe('en-US').transform(this.summaryData.authService.getCustomDate(), 'MM-dd-yyyy');

      const request: IGetAgencyPolicyCommissionRateRequestDTO = {
        riskId: riskId,
        stateCode: stateCode,
        agencyId: agencyId,
        effectiveDate: formattedEffectiveDate,
        systemDate: systemDate,
        transactionType: transactionType,
        territory: territoryCode,
        masterProgramId: this.summaryData.authService.getProgramTypeCodeViaStateAndFormType(stateCode, formType)
      };

      this.summaryData.authService.checkAgencyCommissionRate(request, hasSubscription);

    }

    this.entityRiskData.isFromQQSaveCommissionRate = false;
  }
}
