import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { QuoteService } from '../../../../../core/services/quote.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SummaryData } from '../../../../../modules/submission-management/data/summary.data';
import { SubmissionPageData } from '../../../../../modules/submission-management/data/submission-page.data';
import { EntityRiskData } from '../../../../../modules/submission-management/data/entity-risk.data';
import { QuickQuoteData } from '../../../../../modules/submission-management/data/quick-quote.data';
import { FormTypeConstants } from '../../../../../shared/constants/form-types.constants';
import { ApplicantData } from '../../../../../modules/submission-management/data/applicant.data';
import { AuthService } from '../../../../../core/services/auth.service';
import { AgentDashboardConstants } from '../../../../../shared/constants/agent-dashboard.constants';
import { ProgramStateDTO } from '../../../../../shared/models/management/program-state.dto';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { ZipCodeData } from 'app/modules/submission-management/data/zipcode.data';

@Component({
  selector: 'app-quote-modal',
  templateUrl: './quote-modal.component.html',
  styleUrls: ['./quote-modal.component.scss']
})
export class QuoteModalComponent implements OnInit {


  title: any;
  public quoteFrm: FormGroup;
  public formTypeConstants = FormTypeConstants;
  public agentDashboardConstants = AgentDashboardConstants;
  public genericLabel = GenericLabel;

  programType: ProgramStateDTO[] = [];

  constructor( private fb: FormBuilder,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public quoteService: QuoteService,
    private summaryData: SummaryData,
    public submissionData: SubmissionPageData,
    public entityRiskData: EntityRiskData,
    public quickQuoteData: QuickQuoteData,
    private router: Router,
    private applicantData: ApplicantData,
    private authService: AuthService,
    private zipcodeData: ZipCodeData
    ) { }

  ngOnInit() {

    this.quoteFrm = this.fb.group({
      formType: ['', [Validators.required]],
      state: ['', [Validators.required]],
    });

  }

  okMyModal(): void {
    this.entityRiskData.EntityRisk = null;
    this.applicantData.copiedInformation = null;
    this.applicantData.isCopiedInformation = false;
    this.applicantData.isQQSavedForCopy = false;
    this.applicantData.copiedAgencyInformation = null;
    this.bsModalRef.hide();
    this.quoteService.formType = this.quoteFrm.get('formType').value;
    this.quoteService.state = this.quoteFrm.get('state').value;
    this.zipcodeData.stateCode = this.quoteFrm.get('state').value;

    // Set summary as new
    this.summaryData.summaryForm().get('riskId').setValue(null);
    this.summaryData.summaryForm().get('entityId').setValue(null);
    this.summaryData.removePreviousStorageItems();

    if (this.quoteFrm.get('state').value === 'FL') {
      this.router.navigate(['/submissions/new/quickquote/applicant-page']);
    } else {
      this.router.navigate(['la/submissions/new/quick-quote']);
    }
  }

  hideMyModal(): void {
    this.bsModalRef.hide();
  }

  isAllowedFormTypesAndStates(): boolean {
    let userType = '';
    this.authService.userType.subscribe(x => {
      userType = x;
    });

    return (
    (this.quoteFrm.get('formType').value !== this.formTypeConstants.HO3
    && this.quoteFrm.get('formType').value !== this.formTypeConstants.HO4
    && this.quoteFrm.get('formType').value !== this.formTypeConstants.HO6
    && this.quoteFrm.get('formType').value !== this.formTypeConstants.DP3)
    || this.quoteFrm.get('state').value !== 'FL') || userType !== 'external';
  }

  setProgramTypeDropdown(state: any): void {
    this.programType = this.authService.agentProgramStateDetails.filter(x => x.stateCode === state);
    const formType = this.quoteFrm.get('formType').value;
    this.updateCompanyNameAndProgramType(formType);
  }

  onSelectProgramType(value?): void {
    this.updateCompanyNameAndProgramType(value);
  }

  updateCompanyNameAndProgramType(formType: string): void {
    if (formType && formType !== '') {
      const programStateDetails = this.programType.find(x => x.program.formTypeCode === formType);

      this.quoteService.companyName = programStateDetails?.entity?.companyName;
      this.quoteService.masterProgramType = programStateDetails?.program?.masterProgramCode;
    }
  }
}
