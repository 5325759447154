const _noNegativeSetMax = (maxInput: number) => {
    return {
        align: 'left',
        allowNegative: false,
        max: maxInput,
        prefix: '',
        precision: 0
    };
};

// const _monetarySetMax = (maxInput: number) => {
//     return {
//         align: "right",
//         allowNegative: false,
//         decimal: ",",
//         max: maxInput,
//         prefix: '',
//         precision: 0
//     };
// }

export const CurrencyMaskConstants = {
    HourPerDay: _noNegativeSetMax(24),
    HourPerWeek: _noNegativeSetMax(168),
    Percentage: _noNegativeSetMax(100),
    Year: _noNegativeSetMax(99),

    setMaxInput(maxInput: number): any {
        return _noNegativeSetMax(maxInput);
    }
};
