import { Component, Input, OnInit } from '@angular/core';
import { Question } from '../../../shared/models/question';

@Component({
  selector: 'app-ccard',
  templateUrl: './ccard.component.html',
  styleUrls: ['./ccard.component.scss']
})
export class CcardComponent implements OnInit {
  @Input() question: Question;
  isChecked: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
