import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import Utils from '../../../../../../shared/utilities/utils';
import FormUtils from '../../../../../../shared/utilities/form.utils';
import { BsModalRef } from 'ngx-bootstrap';
import { SuspendedPaymentsLabel } from '../../../../../../shared/constants/suspended-payments.labels.constants';
import { GenericLabel } from '../../../../../../shared/constants/generic.labels.constants';
import { ErrorMessageConstant } from '../../../../../../shared/constants/error-message.constants';
import { SuspendedPaymentsData } from '../../../../../../modules/payments/data/suspended-payments.data';

@Component({
  selector: 'app-generate-ipx-suspense-report-modal',
  templateUrl: './generate-ipx-suspense-report-modal.component.html',
  styleUrls: ['./generate-ipx-suspense-report-modal.component.scss']
})
export class GenerateIpxSuspenseReportModalComponent implements OnInit {
  public SuspendedPaymentsLabel = SuspendedPaymentsLabel;
  public GenericLabel = GenericLabel;
  public ErrorMessageConstant = ErrorMessageConstant;
  public FormUtils = FormUtils;
  public onClose: Subject<boolean>;
  title: string;

  constructor(
    public suspendedPaymentsData: SuspendedPaymentsData,
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  onGenerateReport(isFinal: boolean) {
    Utils.blockUI();
    this.suspendedPaymentsData.getListOfIpxSuspense(isFinal);
    this.bsModalRef.hide();
  }

  hideModal(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
}
