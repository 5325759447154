import { Component, OnInit } from '@angular/core';
import { InterestModalModel } from '../../../../shared/models/submission/interest/interest.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InterestPageModalComponent } from './interest-page-modal/interest-page-modal.component';
import { ClickTypes } from '../../../../shared/enum/click-type.enum';
import { SubmissionPageData } from '../../data/submission-page.data';
import { ITableTd } from '../../../../shared/models/dynamic/table.interface';
import { InterestData } from '../../data/interest.data';
import { TableNameConstants } from '../../../../shared/constants/table.name.constants';
import { SummaryData } from '../../data/summary.data';
import { ZipData } from '../../../../modules/submission-management/data/zip.data';
import NotifUtils from '../../../../shared/utilities/notif-utils';
import { NavigationService } from '../../../../core/services/navigation/navigation.service';
import { ThirdPartyDataService } from '../../../../core/services/submission/third-party/third-party-data.service';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../../shared/base-class';
import { InterestLabelsConstants } from '../../../../shared/constants/interest.labels.constants';
import { InterestSavingData } from '../../data/interest-saving.data';
import { SubmissionNavValidationService } from '../../../../core/services/navigation/submission-nav-validation.service';
import { PathConstants } from '../../../../shared/constants/path.constants';
import { PageSectionsValidations } from '../../../../shared/enum/page-sections.enum';
import { PolicySummaryData } from '../../../../modules/policy-management/data/policy-summary.data';
import { InterestValidationService } from '../../../../core/services/submission/validations/interest-validation.service';
import { UWR } from 'app/core/services/submission/uw-referrals/uwr.service';
import { PolicyBillingData } from '../../../../../app/modules/policy-management/data/policy-billing.data';
import { EntityRiskData } from '../../data/entity-risk.data';
import { LvPayPlanOptions } from 'app/shared/constants/billing.options.constants';
import { PaymentPlanListConstants } from 'app/shared/constants/bind-and-issue.labels.constants';

@Component({
  selector: 'app-interests',
  templateUrl: './interests.component.html',
  styleUrls: ['./interests.component.scss'],
})
export class InterestsComponent extends BaseClass implements OnInit {
  modalRef: BsModalRef | null;
  public isInterestHidden: boolean = false;
  public TableNameConstants = TableNameConstants;
  public LabelMessageConstants = InterestLabelsConstants;

  mortgageeCode: string = 'Mort';

  constructor(
    protected modalService: BsModalService,
    public submissionData: SubmissionPageData,
    public interestData: InterestData,
    protected summaryData: SummaryData,
    public zipData: ZipData,
    public navigationService: NavigationService,
    protected thirdPartyDataService: ThirdPartyDataService,
    protected interestSavingData: InterestSavingData,
    public submissionNavValidationsService: SubmissionNavValidationService,
    public policySummaryData: PolicySummaryData,
    public interestsValidationService: InterestValidationService,
    protected uwr: UWR,
    protected billingData: PolicyBillingData,
    protected entityRiskData: EntityRiskData
  ) {
    super();
  }

  get riskId() {
    return this.summaryData.SummaryForm.get('riskId').value;
  }

  ngOnInit() {
    sessionStorage.setItem(PageSectionsValidations.CurrentPage, PathConstants.PageDestination.Interests);
    this.subscribeToFormChanges();
  }

  subscribeToFormChanges(): void {
    const interestsForms = ['priorInsuranceForm'];

    this.uwr.uwrPopulated$.pipe(takeUntil(this.stop$)).subscribe((result) => {
      if (result) {
        interestsForms.forEach(form => {
          this.interestData[form].valueChanges.pipe(takeUntil(this.stop$)).subscribe(res => {
            if (this.policySummaryData.isPolicy) {
              this.uwr.getPolicyUwrReferrals();
            } else {
              this.uwr.getUwrReferrals(false);
            }
          });
        });
      }
    });
  }

  get hasInvalid() {
    if (!this.policySummaryData.isPolicy) {
      return !(
        this.submissionNavValidationsService?.validationList?.coverages &&
        this.submissionNavValidationsService?.validationList?.endorsements &&
        this.submissionNavValidationsService?.validationList?.property &&
        this.submissionNavValidationsService?.validationList?.applicant &&
        this.submissionNavValidationsService?.validationList?.uwQuestions &&
        this.submissionNavValidationsService?.validationList?.interest
      );
    }

    return !this.submissionNavValidationsService;
  }

  editTableItem(rowId: any, tableRow: string): void {
    this.interestSavingData.markInterestForSaving();
    this.interestData[tableRow].forEach((item) => {
      if (item.id === rowId) {
        this.showEditInterest({
          id: item.id,
          interestType: item.tr[0].value,
          interestName: item.tr[1].value,
          interestName2: item.tr[2].value,
          mailingAddress: item.tr[3].value,
          mailingAddress2: item.tr[4].value,
          zipCode: item.tr[5].value,
          city: item.tr[6].value,
          state: item.tr[7].value,
          refLoanNumber: item.tr[8].value,
          rank: item.tr[9].value,
          descriptionOfInterest: item.tr[10].value,
          interestLastName: item.item.interestLastName,
          interestLastName2: item.item.interestLastName2,
          interestNameSuffix: item.item.interestNameSuffix,
          interestNameSuffix2: item.item.interestNameSuffix2,
          isIndividual: item.item.isIndividual,
          createdDate: item.item.createdDate,
          isAdd: item.item.isAdd,
          countryCode: item.tr[11].value
        });
      } else {
        item.edit = false;
      }
    });
  }

  deleteTableItem(rowId: any, tableRow: string): void {
    this.interestSavingData.markInterestForSaving();
    this.interestData[tableRow].forEach((item, index) => {
      if (item.id === rowId) {
        this.interestData[tableRow].splice(index, 1);
      }
    });
  }

  showAddInterest(): void {
    this.interestSavingData.markInterestForSaving();
    const initialState = {
      title: 'Add Interest',
      idForSaving: this.generateId(100),
      allowedMortgageeRanks: this.getAllowedMortgageeRanks(),
      hasRank1Mortgagee: this.hasRank1Mortgagee()
    };
    this.modalRef = this.modalService.show(InterestPageModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.content.event.pipe(takeUntil(this.stop$)).subscribe((res) => {
      if (this.interestData.modalSaveCount === 1) {
        this.interestData.modalSaveCount = 0;
        const row = this.interestData.addItem(res.data);
        this.interestData.addItemToTable(row, res.data);
        this.interestData.interestList.push(res.data);
      }
    });
  }

  showEditInterest(data: InterestModalModel): void {
    const interest: InterestModalModel = {
      id: data?.id,
      interestType: data?.interestType,
      interestName: data?.interestName,
      interestName2: data?.interestName2,
      mailingAddress: data?.mailingAddress,
      mailingAddress2: data?.mailingAddress2,
      zipCode: data?.zipCode,
      city: data?.city,
      state: data?.state,
      isEntityACompany: data?.isEntityACompany,
      rank: data?.rank,
      descriptionOfInterest: data?.descriptionOfInterest,
      isIndividual: data?.isIndividual,
      interestFirstName: data?.interestName,
      interestFirstName2: data?.interestName2,
      interestLastName: data?.interestLastName,
      interestLastName2: data?.interestLastName2,
      interestNameSuffix: data?.interestNameSuffix,
      interestNameSuffix2: data?.interestNameSuffix2,
      createdDate: data?.createdDate,
      countryCode: data?.countryCode
    };

    if (interest.interestType === 'Mort') {
      interest.rank = data?.rank;
      interest.refLoanNumber = data?.refLoanNumber;
    } else {
      interest.descriptionOfInterest = data?.descriptionOfInterest;
    }

    const initialState = {
      title: 'Edit Interest',
      interestModel: interest,
      isAdd: false,
      isPrimaryMortgagee: this.isPrimaryMortgagee(interest),
      hasRank1Mortgagee: this.hasRank1Mortgagee()
    };

    this.modalRef = this.modalService.show(InterestPageModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.content.event.pipe(takeUntil(this.stop$)).subscribe((res) => {
      this.updateTableItem(res.data);
    });
  }

  updateTableItem(row: InterestModalModel): void {
    this.interestSavingData.markInterestForSaving();
    this.interestData.interestsTableRows.forEach((item) => {
      if (item.id === row.id) {
        item.tr[0].value = row.interestType;
        item.tr[1].value = row.interestName;
        item.tr[2].value = row.interestName2;
        item.tr[3].value = row.mailingAddress;
        item.tr[4].value = row.mailingAddress2;
        item.tr[5].value = row.zipCode;
        item.tr[6].value = row.city;
        item.tr[7].value = row.state;
        item.tr[8].value = row.refLoanNumber;
        item.tr[9].value = row.rank;
        item.tr[10].value = row.descriptionOfInterest;
        item.tr[11].value = row.countryCode;
        item.item = row;

        item.tr[0].display = this.interestData.interestTypes.filter(res => res.code === row.interestType)[0]?.description;
        item.tr[1].display = row.isIndividual ? `${row.interestName} ${row.interestLastName} ${row.interestNameSuffix}` : row.interestName;
        item.tr[2].display = row.isIndividual ? `${row.interestName2} ${row.interestLastName2} ${row.interestNameSuffix2}` : row.interestName2;
        item.tr[3].display = row.mailingAddress;
        item.tr[4].display = row.mailingAddress2;
        item.tr[5].display = row.zipCode;
        item.tr[6].display = row.city;
        item.tr[7].display = row.state;
        item.tr[8].display = row.refLoanNumber;
        item.tr[9].display = this.interestData.ranks.filter(res => res.code === row.rank)[0]?.description;
        item.tr[10].display = this.interestData.descriptionOfInterestsForInsured.filter(res => res.code === row.descriptionOfInterest)[0]?.description;
        item.tr[11].display = row.countryCode;
      }
    });

    this.interestData.interestList.forEach((item) => {
      if (item.id === row.id) {
        item.interestType = row.interestType;
        item.interestName = row.interestName;
        item.interestName2 = row.interestName2;
        item.mailingAddress = row.mailingAddress;
        item.mailingAddress2 = row.mailingAddress2;
        item.zipCode = row.zipCode;
        item.city = row.city;
        item.state = row.state;
        item.refLoanNumber = row.refLoanNumber;
        item.rank = row.rank;
        item.descriptionOfInterest = row.descriptionOfInterest;
        item.interestLastName = row.interestLastName;
        item.interestLastName2 = row.interestLastName2;
        item.interestNameSuffix = row.interestNameSuffix;
        item.interestNameSuffix2 = row.interestNameSuffix2;
        item.isIndividual = row.isIndividual;
        item.createdDate = row.createdDate;
        item.countryCode = row.countryCode;
      }
    });
  }

  generateId(length) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  onClick(clickType?) {
    if (!this.policySummaryData.isPolicy) {
      switch (clickType) {
        case ClickTypes.Back:
          if(this.submissionData.isUWQuestionHide()) {
            this.navigationService.navigateRoute(PathConstants.PageDestination.Applicant, true);
          } else {
            this.navigationService.navigateRoute(PathConstants.PageDestination.UWQuestions);
          }
          break;
        case ClickTypes.Next:
          this.navigationService.navigateRoute(PathConstants.PageDestination.UWApproval);
          break;
      }
    } else {
      this.onNextBackPolicy(clickType);
    }
  }

  onNextBackPolicy(clickType): void {
    const page = clickType === ClickTypes.Back ? PathConstants.Policy.Policies.UWQuestions :
      PathConstants.Policy.Policies.UWApproval;
    this.navigationService.navigatePolicyRoute(page);
  }

  public ToggleInterestHidden() {
    this.isInterestHidden = !this.isInterestHidden;
    this.submissionData.isInterestOpen = !this.isInterestHidden;
  }

  onDelete(rowId: number): void {

    this.interestData.interestsTableRows.forEach((item) => {
      if (item.id === rowId) {

        const mortgageeRows = this.interestData.interestsTableRows.map(x => x.item).filter(x => x.interestTypeCode === this.mortgageeCode || x.interestType === this.mortgageeCode);

        let allowDelete: Boolean = true;
        if (!this.policySummaryData.isPolicy //submission
          && this.isPrimaryMortgagee(item.item)
          && mortgageeRows.length > 1 && mortgageeRows.filter(x => x.rankCode === '1stMort' || x.rank === '1stMort').length === 1) {

          allowDelete = false;
          NotifUtils.showInfo('Unable to delete primary mortgagee. Please remove first the lower rank mortgagees.');
        } else if (this.policySummaryData.isPolicy && this.billingData.summary.paymentPlan.toLowerCase() === 'mortgagee' && this.isMortgagee(item.item)) {

          if ((this.isPrimaryMortgagee(item.item) && item.item.isFromParent) || mortgageeRows.length === 1) {
            allowDelete = false;
            NotifUtils.showInfo('Unable to delete primary mortgagee. Please change your payment plan first.');
          }
        } else if (this.entityRiskData.isRenewalQuote && this.entityRiskData.getRiskBindBillingPayPlan() === PaymentPlanListConstants.mortgageePayCode &&
          this.isMortgagee(item.item)) {

          if ((this.isPrimaryMortgagee(item.item) && item.item.isFromParent) || mortgageeRows.length === 1) {
            allowDelete = false;
            NotifUtils.showInfo('Unable to delete primary mortgagee. Please change your payment plan first.');
          }
        }

        if (allowDelete) {
          NotifUtils.showConfirmMessage(`Are you sure you want to delete '${item.item.interestName}'?`,
            () => {
              this.deleteTableItem(rowId, 'interestsTableRows');
            },
            () => { }
          );
        }
      } else {
        item.edit = false;
      }
    });
  }

  isPrimaryMortgagee(interestData: any): boolean {
    const interestTypeCode: string = interestData?.interestTypeCode ?? interestData?.interestType;
    const rankCode: string = interestData?.rankCode ?? interestData?.rank;

    return (interestTypeCode === this.mortgageeCode && rankCode === '1stMort');
  }

  isMortgagee(interestData: any): boolean {
    const interestTypeCode: string = interestData?.interestTypeCode ?? interestData?.interestType;
    return interestTypeCode === this.mortgageeCode;
  }

  getAllowedMortgageeRanks(): string[] {
    const mortgageeList = this.interestData.interestsTableRows.map(x => x.item).filter(x => x.interestTypeCode === this.mortgageeCode || x.interestType === this.mortgageeCode)
      .sort((prev, curr) => ((prev.rankCode || prev.rank) > (curr.rankCode || curr.rank)) ? -1 : 1);

    const lowestRankCode = mortgageeList[0]?.rankCode || mortgageeList[0]?.rank;

    if (mortgageeList.length === 0) {
      return ['1stMort'];
    } else if (lowestRankCode === '1stMort') {
      return ['1stMort', '2ndMort'];
    } else {
      return ['1stMort', '2ndMort', '3rdMort'];
    }
  }

  getMortgageeList(): any[] {
    return this.interestData.interestsTableRows.map(x => x.item).filter(x => x.interestTypeCode === this.mortgageeCode || x.interestType === this.mortgageeCode);
  }

  hasRank1Mortgagee() {
    const result = this.getMortgageeList()?.filter(x => x.rankCode === '1stMort' || x.rank === '1stMort').length > 0;
    return result;
  }
}
