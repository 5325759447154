import { Component, OnInit } from '@angular/core';
import { BillingService } from '../../../../../../../app/core/services/billing/billing.service';
import { PolicyBillingData } from '../../../../../../../app/modules/policy-management/data/policy-billing.data';
import { BaseClass } from '../../../../../../../app/shared/base-class';
import { PolicyBillingLabelsConstants } from '../../../../../../../app/shared/constants/policy-billing.labels.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MakePaymentModalComponent } from '../make-payment-modal/make-payment-modal.component';
import { AllowedPaymentRangeDTO } from '../../../../../../shared/models/data/dto/billing/allowed-payment-range.dto';
import { PostPaymentModalComponent } from '../../../../../../../app/modules/policy-management/policy-billing/payments/post-payments/post-payment-modal/post-payment-modal.component';
import { PaymentProcedure } from '../../../../../../../app/shared/enum/payment-procedure.enum';

@Component({
  selector: 'app-reinstatement-requirements-met-modal',
  templateUrl: './reinstatement-requirements-met-modal.component.html',
  styleUrls: ['./reinstatement-requirements-met-modal.component.scss']
})
export class ReinstatementRequirementsMetModalComponent extends BaseClass implements OnInit {

  public ReinstatementRequirementsMetLabelConstants = PolicyBillingLabelsConstants.reinstatementRequirementsMet;

  modalRef: BsModalRef | null;
  hasOutstandingBalance: boolean;
  outstandingRequirementsList: string[];
  accessedFromPortal: boolean = true;

  balance: number;
  payoffAmount: number;
  minimumAmountToReinstate: number;
  maximumAmountToReinstate: number;

  constructor(
    public billingService: BillingService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public billingData: PolicyBillingData) {

    super();
  }

  ngOnInit() {
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  onPayBalance(): void {
    const paymentRange: AllowedPaymentRangeDTO = {
      minimumPaymentAmount: 0,
      maximumPaymentAmount: this.balance
    };

    const initialState = {
      allowedPaymentRange: paymentRange,
      paymentProcedure: PaymentProcedure.PayBalance
    };

    this.showPaymentModal(initialState);
  }

  onPayToReinstate(): void {
    const paymentRange: AllowedPaymentRangeDTO = {
      minimumPaymentAmount: this.minimumAmountToReinstate,
      maximumPaymentAmount: this.maximumAmountToReinstate
    };

    const initialState = {
      allowedPaymentRange: paymentRange,
      paymentProcedure: PaymentProcedure.PayToReinstate,
    };

    this.showPaymentModal(initialState);
  }

  showPaymentModal(initialState: any) {

    if (this.accessedFromPortal) {
      this.billingData.isAccessedFromPortal = true;
      this.modalRef = this.modalService.show(MakePaymentModalComponent, {
        initialState,
        backdrop: true,
        ignoreBackdropClick: true,
      });
    } else {
      this.billingData.isAccessedFromPortal = false;
      this.modalRef = this.modalService.show(PostPaymentModalComponent, {
        initialState,
        backdrop: true,
        ignoreBackdropClick: true,
      });
    }

    this.modalRef.hide();
    this.bsModalRef.hide();
  }

  onExit(): void {
    this.bsModalRef.hide();
  }
}
