import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/core/services/auth.service';
import { IMySingleDateModel } from 'angular-mydatepicker';
import { PolicyIssueConstants } from '../../../../shared/constants/policy-issue.labels.constants';
import { PolicyIssueData } from '../../data/policy-issue.data';
import { BaseClass } from 'app/shared/base-class';
import { takeUntil, startWith, pairwise } from 'rxjs/operators';
import { ErrorMessageConstant } from '../../../../shared/constants/error-message.constants';
import { CalculateRaterData } from 'app/core/services/submission/rater-premium/calculate-rater-data.service';
import { PolicySummaryData } from '../../data/policy-summary.data';
import { ZipCodeData } from 'app/modules/submission-management/data/zipcode.data';
import { PropertyData } from 'app/modules/submission-management/data/property.data';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { updateAppIsLoadingFromIssuePolicyComponent } from 'app/store/app/app.actions';

@Component({
  selector: 'app-issue-policy',
  templateUrl: './issue-policy.component.html',
  styleUrls: ['./issue-policy.component.scss']
})
export class IssuePolicyComponent extends BaseClass implements OnInit {
  public PolicyIssueConstants = PolicyIssueConstants;
  public ErrorMessageConstant = ErrorMessageConstant;
  isOpen: boolean = true;

  constructor(
      public policyIssueData: PolicyIssueData,
      public policySummaryData: PolicySummaryData,
      public raterData: CalculateRaterData,
      public authService: AuthService,
      protected zipCodeData: ZipCodeData,
      protected propertyData: PropertyData,
      protected store: Store
    ) {
    super();
  }

  ngOnInit() {
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.policyIssueData.isInternal = this.isInternalUser(userType);
      this.policyIssueData.setChangeEffectiveDateOption();
    });

    this.subscribeToChangeEffectiveDate();
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }

  public isInternalUser(userType: string): boolean {
    return userType.toLocaleLowerCase() === 'internal';
  }

  get disableChangeEffectiveDate(): boolean {
    return this.policyIssueData.disableChangeEffectiveDate || this.policyIssueData.isPolicyChangesLoading;
  }

  subscribeToChangeEffectiveDate(): void {
    this.policyIssueData.issuePolicyForm.get('changeEffectiveDate').valueChanges.pipe(takeUntil(this.stop$)).subscribe(date => {
      const latestIssuedChangeEffectiveDate = this.policyIssueData.latestIssuedChangeEffectiveDate?.singleDate?.jsDate ?
        moment(this.policyIssueData.latestIssuedChangeEffectiveDate?.singleDate?.jsDate).format('YYYY-MM-DDT00:00:00.000') :
        null;

      if (date) {
        const newSelectedEffectiveDate = moment(date?.singleDate?.jsDate).format('YYYY-MM-DDT00:00:00.000');
        const isForOOSChecking = moment(newSelectedEffectiveDate).diff(latestIssuedChangeEffectiveDate) < 0;

        if (isForOOSChecking) {
          this.policyIssueData.callOutOfSequence(newSelectedEffectiveDate, true);
          return;
        }

        this.showPreloader();
        return;
      }

      this.showPreloader();
    });
  }

  showPreloader(): void {
    this.store.dispatch(updateAppIsLoadingFromIssuePolicyComponent({ isLoading: true }));
    this.store.dispatch(updateAppIsLoadingFromIssuePolicyComponent({ isLoading: false }));
  }
}
