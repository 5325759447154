import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AuthService } from '../../../core/services/auth.service';
import { LocationLabelConstants } from 'app/shared/constants/property.labels.constants';
import { PropertyValidatorErrorConstants } from './validator-error-constants/property-validator-error.constants';
import { LA_PropertyValidatorErrorConstants } from 'app/states/la/shared/constants/validator-error-constants/property-validator-error.constants';

export class PropertyValidators {
  static auth: AuthService;
  static yearRoofShouldNotBeOlderThanYearBuiltValidator(yearBuilt: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value && yearBuilt && control.value < yearBuilt && control.value >= 1800) {
        key[PropertyValidatorErrorConstants.yearRoofShouldNotBeOlderThanYearBuiltErrorMessage.key] = true;
        return key;
      }
      return null;
    };
  }

  static yearRoofInstalledCannotBeAFutureYearValidator(dateNow: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control && control.value > dateNow.getFullYear() + 1) {
        key[PropertyValidatorErrorConstants.yearRoofInstalledCannotBeAFutureYearErrorMessage.key] = true;
        return key;
      }
      return null;
    };
  }

  static yearShouldNotBeOlderThanYearBuiltValidator(yearBuilt: any, ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value && yearBuilt && control.value < yearBuilt && control.value >= 1800) {
        key[PropertyValidatorErrorConstants.yearShouldNotBeOlderThanYearBuiltErrorMessage.key] = true;
        return key;
      }
      return null;
    };
  }

  static yearInstalledCannotBeAFutureYearValidator(dateNow: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control && control.value > dateNow.getFullYear() + 1) {
        key[PropertyValidatorErrorConstants.yearInstalledCannotBeAFutureYearErrorMessage.key] = true;
        return key;
      }
      return null;
    };
  }

  static purchaseDateCannotBePriorToYearBuiltValidator(yearBuilt: number, purchaseDateYear: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value && yearBuilt && purchaseDateYear && purchaseDateYear < yearBuilt) {
        key[PropertyValidatorErrorConstants.yearRoofShouldNotBeOlderThanYearBuiltErrorMessage.key] = true;
        return key;
      }
      return null;
    };
  }

  static purchaseDateCannotBeGreaterThanEffectiveDateValidator(effectiveDate: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      const selectTedEffectiveDate = sessionStorage.getItem('selectedEffectiveDate') ? new Date (sessionStorage.getItem('selectedEffectiveDate'))
      : effectiveDate ?? null;
      let purchaseDateValue = control.value;
      purchaseDateValue = typeof purchaseDateValue === 'object' ? new Date(control.value?.singleDate?.jsDate) : new Date(control.value);

      if (control.value && selectTedEffectiveDate && purchaseDateValue > selectTedEffectiveDate) {
        key[PropertyValidatorErrorConstants.purchaseDateCannotBeGreaterThanEffectiveDateErrorMessage.key] = true;
        return key;
      }
      return null;
    };
  }

  static numberOfUnitsInsuredValidator(structureType: string, unitsInsured: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const UIConstants = PropertyValidatorErrorConstants.numberOfUnitsInsuredInvalidSelectionErrorMessage.constants;
      const key: any = {};
      if (control.value &&
        (structureType === UIConstants.STDuplex && (unitsInsured === UIConstants.UIThreeFamily || unitsInsured === UIConstants.UIFourFamily || unitsInsured === UIConstants.UIFiveFamily)) ||
        (structureType === UIConstants.STTriplex && (unitsInsured === UIConstants.UIFourFamily || unitsInsured === UIConstants.UIFiveFamily)) ||
        (structureType === UIConstants.STFourplex && unitsInsured === UIConstants.UIFiveFamily) ) {

          key[PropertyValidatorErrorConstants.numberOfUnitsInsuredInvalidSelectionErrorMessage.key] = true;
        return key;
      }

      return null;
    };
  }

  static riskOwnerOccupiedPrimaryCannotBeRented(propertyUsage: string, isHO6: boolean, isActiveNewRaterVersion: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value) {
        if (control.value && propertyUsage === LocationLabelConstants.ownerOccupiedPrimary && (isHO6 && isActiveNewRaterVersion)) {
          key[PropertyValidatorErrorConstants.riskOwnerOccupiedPrimaryCannotBeRentedErrorMessage.key] = true;
          return key;
        }
      }
    };
  }

  static dwellingInTheCourseOfConstructionBRUL333ErrorMessage(propertyUsage: string, dwellingCourseConstruction: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value) {
        if (control.value && propertyUsage !== LocationLabelConstants.ownerOccupiedPrimary && dwellingCourseConstruction) {
          key[LA_PropertyValidatorErrorConstants.dwellingInTheCourseOfConstructionBRUL333ErrorMessage.key] = true;
          return key;
        }
      }
      return null;
    };
  }

  static dwellingInTheCourseOfConstructionBRUL329ErrorMessage(yearBuilt: number, dcocEstimatedCompletionDateYear: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value) {
        if (control.value && yearBuilt !== dcocEstimatedCompletionDateYear) {
          key[LA_PropertyValidatorErrorConstants.dwellingInTheCourseOfConstructionBRUL329ErrorMessage.key] = true;
          return key;
        }
      }
      return null;
    };
  }

}
