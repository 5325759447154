export const ClaimsLabelsConstants = {
  saveAClaim: 'Save',
  lossDate: 'Loss Date',
  claimType: 'Claim Type',
  additionalDescription: 'Additional Description',
  claimAmount: 'Claim Amount',
  claimSource: 'Claim Source',
  claimChargeableVsNonChargeable: 'Chargeable',
  notes: 'Notes',
  otherQuestions: 'Other Questions',
  claimStatus: 'Claim Status',
  addAClaim: 'Add a Claim',
  editAClaim: 'Save',
  cancel: 'Cancel',
  dispute: 'Dispute',
  policyType: 'Policy Type',
  claimNumber: 'Claim Number',
  individualMatchType: 'Individual Match Type',
  totalOutstandingReserve: 'Total Outstanding Reserves',
  totalRecoveriesAndReimbursement: 'Total Recoveries and Reimbursements',
  pcsCode: 'PCS Code',
  catCode: 'CAT Code',
  orderLossReport: 'Order Loss Report',
  tableMessageIfNoData: 'Report Ordered - No claims returned',
  isLossAssociatedToHome: 'Loss Associated To Home',
  isLossAssociatedToInsured: 'Loss Associated To Insured',
  claimsChangesExludedProperties: [
    'createdBy',
    'createdByFullName',
    'createdDate',
    'createdDateProper'
  ],
  claimSourceCodes: {
    aPlus: 'CS0',
    agent: 'CS1',
    centauri: 'CS2',
    fileHandler: 'CS3',
  },
  policyTypes: {
    ho3: 'Homeowners',
    ho4: 'Homeowners',
    ho6: 'Condominium',
    dp3: 'Dwelling Fire',
    default: 'Policy Type'
  }
};
