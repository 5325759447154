import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SuspendedPaymentDetailsDTO } from '../../../shared/models/data/dto/suspended-payment/suspended-payment-detail.dto';
import { SuspendedPaymentsLabel } from '../../../shared/constants/suspended-payments.labels.constants';
import { SuspendedPaymentsData } from '../../../../app/modules/payments/data/suspended-payments.data';
import FormUtils from '../../../shared/utilities/form.utils';
import { ErrorMessageConstant } from '../../../../app/shared/constants/error-message.constants';
import { FormGroup } from '@angular/forms';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { BaseClass } from '../../../shared/base-class';
import { AddSplitPaymentModalComponent } from '../../../modules/payments/suspended-payments/pending/pending-suspended-modal/add-split-payment-modal/add-split-payment-modal.component';
import { BsModalService } from 'ngx-bootstrap';
import { PolicyService } from '../../../core/services/submission/policy.service';
import { DetailsSuspendedSplitPaymentTableModalComponent } from '../../../modules/payments/suspended-payments/pending/details-suspended-split-payment-table-modal.component.html/details-suspended-split-payment-table-modal.component';
import NotifUtils from '../../../shared/utilities/notif-utils';

@Component({
  selector: 'app-suspended-payment-detail',
  templateUrl: './suspended-payment-detail.component.html',
  styleUrls: ['./suspended-payment-detail.component.scss']
})
export class SuspendedPaymentDetailComponent extends BaseClass implements OnInit, OnDestroy {
  @Input() showAllFields?: boolean;
  @Input() paymentDetail?: SuspendedPaymentDetailsDTO;
  @Input() isEditPolicyNumber?: boolean;
  @Input() isFromPaymentSection?: boolean;
  @ViewChild('policyNumber') elemRef: ElementRef;

  public LabelConstants = SuspendedPaymentsLabel;
  public errorMessageConstant = ErrorMessageConstant;

  public directBillCarriers: any;
  formUtils = FormUtils;

  constructor(
    public suspendedPaymentsData: SuspendedPaymentsData,
    protected modalService: BsModalService,
    protected policyService: PolicyService
  ) {
    super();
  }

  ngOnInit() {
    this.directBillCarriers = this.suspendedPaymentsData.directBillCarriers;
    this.subscribeToPolicyNumberChanges();

    if (this.suspendedPaymentsData.isSplitPayment) {
      this.suspendedPaymentsData.availableToAllocate = this.paymentDetail.amount;
      this.suspendedPaymentsData.loadSuspPaymentDocsSourceForSplitPayment(this.paymentDetail.id);
    }
  }


  get updateForm(): FormGroup {
    return this.suspendedPaymentsData?.updateSuspendedFormGroup;
  }

  subscribeToPolicyNumberChanges(): void {
    this.updateForm?.get('policyNumber').valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(() =>{
      this.suspendedPaymentsData.isPolicySearchDone = false;
    });
    this.updateForm?.get('policyNumber').valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$)).debounceTime(1500).subscribe(val => {
      if (val !== undefined) {
        this.suspendedPaymentsData.onPolicyNumberSearch(true);
      }
    });
  }

  showAddSplitPaymentModal() {
    const initialState = {
      paymentDetail: this.paymentDetail,

    };

    this.modalService.show(AddSplitPaymentModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
  }

  showDetailSplitPayment(data: any, index: number): void{
    this.policyService.searchPolicies(data.policyOrQuoteNumber).pipe(takeUntil(this.stop$)).subscribe(result => {
      if (result) {
        const initialState = {
          policySearchResult: result[0],
          paymentDetail: this.paymentDetail,
          policyPaymentAmount: this.suspendedPaymentsData.suspendedSplitPayment[index].policyPaymentAmount
        };

        this.modalService.show(DetailsSuspendedSplitPaymentTableModalComponent, {
          initialState,
          backdrop: true,
          ignoreBackdropClick: true,
          class: 'modal-lg',
        });
      }
    });
  }


  onDelete(index: number): void {
    NotifUtils.showConfirmMessage(this.LabelConstants.splitPayment.deleteConfirmMess, () => {
      this.suspendedPaymentsData.availableToAllocate = this.suspendedPaymentsData.availableToAllocate + this.suspendedPaymentsData.suspendedSplitPayment[index].policyPaymentAmount;
      this.suspendedPaymentsData.suspendedSplitPayment.splice(index, 1);
      this.suspendedPaymentsData.suspendedPaymentBatch.splice(index, 1);
    }, () => {
    });
  }

  ngOnDestroy(): void {
    this.suspendedPaymentsData.resetSplitpaymentVariables();
  }


}
