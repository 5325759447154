import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ITaskDashboardResultDTO,
  ITaskDocumentRequestDTO,
  ITaskDocumentResultDTO,
  ITaskDTO,
  ITaskHistoryDTO,
  ITaskListRequestDTO,
  ITaskListResultDTO,
  ITaskPolicySearchRequestDTO,
  ITaskPolicySearchResultDTO,
  ITaskPostRequestDTO,
  ITaskPostResultDTO,
  ITaskSearchResultDTO,
  ITasksRequestDTO,
  TaskDocumentResultDTO,
  TaskDTO,
} from 'app/shared/models/data/dto/task/task.dto';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs-compat';
import { map } from 'rxjs/operators';
import { serialize } from 'object-to-formdata';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(
    public http: HttpClient,
    protected auth: AuthService) {
  }

  taskSearch(payload: ITaskPolicySearchRequestDTO): Observable<ITaskSearchResultDTO> {
    return this.http.post(`${environment.ApiUrl}/Task/policy/list`, payload).pipe(
      map(data => {
        return data as ITaskSearchResultDTO;
      })
    );
  }

  generateTaskId(): Observable<string> {
    return this.http.post(`${environment.ApiUrl}/Task/id`, null).pipe(
      map(result => {
        return result as string;
      })
    );
  }

  postTask(payload: ITaskPostRequestDTO): Observable<ITaskPostResultDTO> {
    return this.http.post(`${environment.ApiUrl}/Task/task`, payload).pipe(
      map(result => {
        return result as ITaskPostResultDTO;
      })
    );
  }

  postTaskSystem(payload: ITaskPostRequestDTO[]): Observable<ITaskPostResultDTO[]> {
    return this.http.post(`${environment.ApiUrl}/Task/task/system`, payload).pipe(
      map(result => {
        return result as ITaskPostResultDTO[];
      })
    );
  }

  getTaskById(taskId: string): Observable<ITaskDTO> {
    return this.http.get(`${environment.ApiUrl}/Task/task?taskId=${taskId}`).pipe(
      map(result => {
        return result as TaskDTO;
      })
    );
  }

  addTaskDocument(payload: ITaskDocumentRequestDTO): Observable<ITaskDocumentResultDTO> {
    const options = {
      indices: true,
      nullsAsUndefineds: false,
      booleansAsIntegers: false,
      allowEmptyArrays: true,
    };
    return this.http.post(`${environment.ApiUrl}/Task/document`, serialize(payload, options)).pipe(
      map(data => {
        return data as TaskDocumentResultDTO;
      })
    );
  }

  getTaskList(payload: ITaskListRequestDTO): Observable<ITaskDashboardResultDTO> {
    if (this.auth.userType.value === 'external') {
      payload.userType = this.auth.userType.value;
    }
    return this.http.post(`${environment.ApiUrl}/Task/list`, payload).pipe(
      map(result => {
        return result as ITaskDashboardResultDTO;
      })
    );
  }

  getTasks(payload: ITasksRequestDTO): Observable<ITaskDTO[]> {
    return this.http.post(`${environment.ApiUrl}/Task/tasks`, payload).pipe(
      map(result => {
        return result as ITaskDTO[];
      })
    );
  }

  getTaskHistory(taskId: string): Observable<ITaskHistoryDTO[]> {
    return this.http.get(`${environment.ApiUrl}/Task/task/history?taskId=${taskId}`).pipe(
      map(result => {
        return result as ITaskHistoryDTO[];
      })
    );
  }
}
