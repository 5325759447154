import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { UwQuestionsLabelsConstants } from '../../../../../shared/constants/uw-questions.labels.constants';
import { SubmissionPageData } from '../../../../../modules/submission-management/data/submission-page.data';
import { UwQuestionsData } from '../../../data/uw-questions.data';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { LvUWQuestionsModel } from 'app/shared/models/lv-model/lv.uwquestion.model';
import { CustomValidators } from 'app/shared/validators/custom.validator';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import Utils from 'app/shared/utilities/utils';
import { PolicySummaryData } from '../../../../policy-management/data/policy-summary.data';
import FormUtils from 'app/shared/utilities/form.utils';

@Component({
  selector: 'app-general-questions',
  templateUrl: './general-questions.component.html',
  styleUrls: ['./general-questions.component.scss'],
})
export class GeneralQuestionsComponent implements OnInit {
  public ErrorMessageConstant = ErrorMessageConstant;
  public UwQuestionsLabelsConstants: any = UwQuestionsLabelsConstants.generalQuestions;
  public FormUtils = FormUtils;
  GenericLabel = GenericLabel;
  public LvUwQuestionGeneralQuestion: LvUWQuestionsModel[] = [];

  constructor(
    public uwQuestionsData: UwQuestionsData,
    protected submissionData: SubmissionPageData,
    public policySummaryData: PolicySummaryData
  ) {}

  ngOnInit() {
    this.LvUwQuestionGeneralQuestion = this.uwQuestionsData.LvUWQuestions.filter(a => a.section === 'General Question');
  }


  switchKeyPress(key: string, fieldName: string, form: FormGroup): void {
    if (!form.get(fieldName).disabled) {
      switch (key.toLowerCase()) {
        case 'y':
          form.get(fieldName).setValue(true);
          break;
        case 'n':
          form.get(fieldName).setValue(false);
          break;
        default: break;
      }
    }
  }

  getLvDescription(code: string): string {
    const desciption = this.LvUwQuestionGeneralQuestion.find(x => x.code === code)?.description;
    return desciption || '';
  }

  clearChildData(fieldName: string): void {
    const parent = this.uwQuestionsData.generalQuestionsGroup.get(fieldName).value;
    switch (fieldName) {
      case 'DoesAppHaveInsurance':
        this.uwQuestionsData.generalQuestionsGroup.get('AddPolNumber').clearValidators();
        if (!parent) {
          this.uwQuestionsData.generalQuestionsGroup.get('AddPolNumber').setValue('');
          this.uwQuestionsData.generalQuestionsGroup.get('AddPolNumber').markAsUntouched();
        } else {
            this.uwQuestionsData.generalQuestionsGroup.get('AddPolNumber').setValidators([Validators.required, CustomValidators.spaceValidator]);
        }
        break;
      case 'IsBldgUnConsRenov':
        this.uwQuestionsData.generalQuestionsGroup.get('EstCompleDate').clearValidators();
        this.uwQuestionsData.generalQuestionsGroup.get('PleaseDescibe').clearValidators();
        if (!parent) {
          this.uwQuestionsData.generalQuestionsGroup.get('EstCompleDate').setValue('');
          this.uwQuestionsData.generalQuestionsGroup.get('EstCompleDate').markAsUntouched();
          this.uwQuestionsData.generalQuestionsGroup.get('PleaseDescibe').setValue('');
          this.uwQuestionsData.generalQuestionsGroup.get('PleaseDescibe').markAsUntouched();
          this.uwQuestionsData.setEstimatedDateOptions(false);
        } else {
          this.uwQuestionsData.generalQuestionsGroup.get('EstCompleDate').setValidators([Validators.required, CustomValidators.spaceValidator]);
          this.uwQuestionsData.generalQuestionsGroup.get('PleaseDescibe').setValidators([Validators.required, CustomValidators.spaceValidator]);
          this.uwQuestionsData.setEstimatedDateOptions(true);
        }
        break;
    }
  }

  onChangeICertify() {
    const parentform = this.uwQuestionsData.generalQuestionsGroup;
    if (!parentform.get('ICertify').value) {
      parentform.get('ICertify').setValue(null);
    }
  }
}
