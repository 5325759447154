import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BookTransferRoutingModule } from 'app/modules/book-transfer/book-transfer-routing.module';
import { BookTransferComponent } from 'app/modules/book-transfer/book-transfer.component';
import { FormsModule } from '../../views/forms/forms.module';
import { AppSidebarModule } from '@coreui/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { AgencyData } from '../management/data/agency.data';
import { SubAgencyData } from '../management/data/sub-agency.data';
import { AgentData } from '../management/data/agent.data';
import { UserData } from '../management/data/user.data';
import { GenericAddressData } from '../../shared/components/generic-address/data/generic-address.data';
import { ProgramStateData } from '../management/data/program-state.data';
import { CodeInputModule } from 'angular-code-input';
import { SharedModule } from 'app/shared/shared.module';
import { DisableSubmitButtonPipe } from 'app/modules/book-transfer/pipes/disable-submit-button.pipe';
import { NewTransferComponent } from 'app/modules/book-transfer/pages/new-transfer/new-transfer.component';
import { EditTransferComponent } from 'app/modules/book-transfer/pages/edit-transfer/edit-transfer.component';
import { TransferFormComponent } from 'app/modules/book-transfer/components/transfer-form/transfer-form.component';
import { TransferControlButtonsComponent } from 'app/modules/book-transfer/components/transfer-control-buttons/transfer-control-buttons.component';
import { TransferPolicyListComponent } from 'app/modules/book-transfer/components/transfer-policy-list/transfer-policy-list.component';
import { DataTablesModule } from 'angular-datatables';
import { ModalModule, TabsModule, TooltipModule } from 'ngx-bootstrap';
import { DataTableModule } from 'angular2-datatable';
import { NgxMaskModule } from 'ngx-mask';
import { CustomPipesModule } from 'app/shared/custom pipes/custom-pipe.module';
import { BatchTransferDashboardComponent } from 'app/modules/book-transfer/pages/batch-transfer-dashboard/batch-transfer-dashboard.component';
import { TransferBatchTableComponent } from 'app/modules/book-transfer/components/transfer-batch-table/transfer-batch-table.component';
import { TransferStatusPipe } from 'app/modules/book-transfer/pipes/transfer-status.pipe';
import { DisableDeleteButtonPipe } from 'app/modules/book-transfer/pipes/disable-delete-button.pipe';
import { DisableTransferSelectedButtonPipe } from 'app/modules/book-transfer/pipes/disable-transfer-selected-button.pipe';
import { DisableRemoveAllCheckboxPipe } from 'app/modules/book-transfer/pipes/disable-remove-all-checkbox.pipe';
import { DisableRemoveSelectedButtonPipe } from 'app/modules/book-transfer/pipes/disable-remove-selected-button.pipe';
import { TransferSearchBoxComponent } from 'app/modules/book-transfer/components/transfer-search-box/transfer-search-box.component';
import { DisableForTransferPolicyCheckboxPipe } from 'app/modules/book-transfer/pipes/disable-for-tranfer-policy-checkbox.pipe';
import { DisableTransferFormFieldsPipe } from 'app/modules/book-transfer/pipes/disable-transfer-form-fields.pipe';
import { SubmitButtonLabelPipe } from 'app/modules/book-transfer/pipes/submit-button-label.pipe';
import { DisableTransferAllCheckboxPipe } from 'app/modules/book-transfer/pipes/disable-transfer-all-checkbox.pipe';

@NgModule({
  declarations: [
    BookTransferComponent,
    NewTransferComponent,
    EditTransferComponent,
    TransferFormComponent,
    TransferControlButtonsComponent,
    TransferPolicyListComponent,
    DisableSubmitButtonPipe,
    BatchTransferDashboardComponent,
    TransferBatchTableComponent,
    TransferStatusPipe,
    DisableDeleteButtonPipe,
    DisableTransferSelectedButtonPipe,
    DisableRemoveAllCheckboxPipe,
    DisableRemoveSelectedButtonPipe,
    TransferSearchBoxComponent,
    DisableForTransferPolicyCheckboxPipe,
    DisableTransferFormFieldsPipe,
    SubmitButtonLabelPipe,
    DisableTransferAllCheckboxPipe
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AppSidebarModule,
    BookTransferRoutingModule,
    DataTablesModule,
    TooltipModule,
    DataTableModule,
    CustomPipesModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    NgxMaskModule.forRoot(),
    CodeInputModule.forRoot({
      codeLength: 6,
      isCharsCode: true,
      code: ''
    }),
  ]
})
export class BookTransferModule { }
