import { Pipe, PipeTransform } from '@angular/core';

// Pipe used in QQ Module and Applicant Module
@Pipe({ name: 'capitalizeFirstLetter' })
export class CapitalizeFirstLetterPipe implements PipeTransform {
    public transform(input: any): any {
        if (!input){
            return '';
        }else{
            input = input.toLowerCase().split(' ');
            for (var i = 0; i < input.length; i++) {
            input[i] = input[i].charAt(0).toUpperCase() + input[i].substring(1);

            }
            return input.join(' ');
        }

    }


}

