
import { RiskStatus } from 'app/shared/models/data/dto/quick-quote/risk.dto';

export interface PolicyState {
  isLoading: boolean | null;
  status: RiskStatus | null;
  subStatus: RiskStatus | null;
  isLoadingOos: boolean | null;
}

export const initialState: PolicyState = {
  isLoading: null,
  status: null,
  subStatus: null,
  isLoadingOos: null
};
