import { RiskScheduleDTO } from 'app/shared/models/data/dto/quick-quote/risk-schedule.dto';

export interface CoveragesState {
  isLoading: boolean | null;
  schedules: RiskScheduleDTO[] | null;
};

export const initialState = {
  isLoading: null,
  schedules: null
};
