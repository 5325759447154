import { Component, OnDestroy, OnInit } from '@angular/core';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import {
  IAngularMyDpOptions,
  IMyDateModel,
  IMyDateRangeModel,
} from 'angular-mydatepicker';
import { AuthService } from 'app/core/services/auth.service';
import { UwDashboardConstants } from 'app/shared/constants/uw-dashboard.constants';
import { TaskData } from 'app/modules/dashboard/data/task.data';
import { LvOwner,
  LvSortBy,
  LvTaskCategory,
  LvTaskPriority,
  LvTaskStatus,
  LvTaskDescription,
  LvTaskStateCode
 } from 'app/shared/constants/task.options.constants';
import { BaseClass } from 'app/shared/base-class';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';

@Component({
  selector: 'app-uw-task-filter-section',
  templateUrl: './uw-task-filter-section.component.html',
  styleUrls: ['./uw-task-filter-section.component.scss'],
})
export class UwTaskFilterSectionComponent extends BaseClass implements OnInit, OnDestroy {
  public uwDashboardConstants = UwDashboardConstants;
  public genericConstants = GenericConstants;
  LVTaskStatus = LvTaskStatus;
  LVTaskPriority = LvTaskPriority;
  LVTaskCategory = LvTaskCategory;
  LVOwner = LvOwner;
  LVSortBy = LvSortBy;
  LvTaskDescription = LvTaskDescription;
  LvTaskStateCode = LvTaskStateCode;

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'mm/dd/yyyy',
  };
  model: IMyDateModel = null;
  dueDate: IMyDateRangeModel;
  userType: string;

  public mytask:boolean = false;
  public obj: any = {
    code:'OP',
    description:'Open',
 }

 public objDescription: any = {
  code:'',
  description:'All',
}

taskDescription: any;

public agentDashboardConstants = AgentDashboardConstants;
dropDownStates = [this.agentDashboardConstants.alabamaFN,
  this.agentDashboardConstants.floridaFN,
  this.agentDashboardConstants.louisianaFN,
  this.agentDashboardConstants.mississippiFN,
  this.agentDashboardConstants.southCarolinaFN,
  this.agentDashboardConstants.texasFN];

  constructor(
    private authService: AuthService,
    public taskData: TaskData) {
    super();
  }

  ngOnInit() {
    this.authService.userType.subscribe((value) => {
      this.userType = value;
    });
    this.taskData.initializeUWTaskFilterForm();
    this.taskData.uwTaskFilterForm.get('status').setValue('open');
    this.taskData.uwTaskFilterForm.get('description').setValue('');
    this.taskData.uwTaskFilterForm.get('stateCode').setValue('');
    this.taskDescription =  this.LvTaskDescription;
  }

  public onmyTaskChanged(value:boolean){
    if(value){
      this.taskData.uwTaskFilterForm.get('myTask').setValue(value);
      this.taskData.getTaskList();
    }
    this.searchTask();
  }

  ngOnDestroy() {
    this.taskData.filterDateFrom = '';
    this.taskData.filterDateTo = '';
  }

  public onDateChanged(event, isFrom: boolean): void {
    const date = event?.singleDate?.formatted;
    if (isFrom) {
      this.taskData.uwTaskFilterForm.get('dueDateFrom').setValue(date);
      this.taskData.filterDateFrom = date;
    } else {
      this.taskData.uwTaskFilterForm.get('dueDateTo').setValue(date);
      this.taskData.filterDateTo = date;
    }
    const status = this.isValidDueDateField();
    if (status) {
      this.searchTask();
    }
  }

  onSelectCategory(code) {
    this.taskData.uwTaskFilterForm.get('description').setValue('');
    this.searchTask();
    this.taskDescription = this.LvTaskDescription.filter(data => data.taskCategoryCode === code);
    if(this.taskDescription.length == 0) {
      this.taskDescription =  this.LvTaskDescription;
    }
   }

  searchTask(): void {
    this.taskData.currentPage = 1;
    const payload = this.taskData.getUWTaskFilterFormPayload(null,this.taskData.currentPage,this.taskData.itemPerPage);
    this.taskData.getTaskList(payload);
    this.taskData.getUnderwriterList();
    this.taskData.uwOnFirstLoad = false;
  }

  searchQuotePolicyNumber(data: string): void {
    this.searchTask();
  }

  isValidDueDateField(): boolean {
    const from = this.taskData.uwTaskFilterForm.get('dueDateFrom')?.value ||
                  this.taskData.uwTaskFilterForm.get('dueDateFrom')?.value?.singleDate?.formatted;
    const to = this.taskData.uwTaskFilterForm.get('dueDateTo')?.value ||
                  this.taskData.uwTaskFilterForm.get('dueDateTo')?.value?.singleDate?.formatted;

    let fromDate: Date;
    let toDate: Date;

    let status: boolean = false;
    if (from) {
      fromDate = new Date(from);
      if (isNaN(fromDate.getTime())) {
        fromDate = new Date(from.singleDate.formatted);
      }
    }
    if (to) {
      toDate = new Date(to);
      if (isNaN(toDate.getTime())) {
        toDate = new Date(to.singleDate.formatted);
      }
    }

    if (!(this.taskData.filterDateFrom) && !(this.taskData.filterDateTo)) {
      if (fromDate?.getTime() > toDate?.getTime()) {
          return false;
        }

      status = true;
    } else {
       if (fromDate || toDate) {
          status = true;
        }
    }

    return status;
  }
}
