export const LoginLabelsConstants = {
    username: 'Username',
    password: 'Password',

    loginAuditType: 'LOGIN',

    method: {
      getAuth: 'getAuthFromServer',
      onLogin: 'onLoginSuccessProcess'
    },

    errorMessage: {
      exist: 'exist',
      incorrect: 'incorrect',
      userInactive: 'inactive',
      locked: 'locked',
      aleardyLockedlocked: 'aleardyLocked',
      twoFactor: 'twoFactor',
      agencyInactive: 'Agency and/or subagency is inactive',
      incorrectDescription: 'Username {0} failed to login because the username/password is not valid',
      existDescription: 'Username {0} tried to use but the user is not existing',
      userInactiveDescription: 'The Account of username {0} is In-active',
      agencyInactiveDescription: 'The Agency/SubAgency of username {0} is In-active',
      incorrectPassword: 'Incorrect password.',
      incorrectUsernamePassword: 'Incorrect username or password.',
      requiredCredentials: 'Username and password is required.',
      lockedLoginDescription: 'Username {0} is locked out after reaching the maximum failed login attempts.',
      lockedTwoFactorDescription: 'Username {0} is locked out after reaching the maximum failed authentication attempts.',
      accountLockedDescription: 'Username {0} failed to login because the account is locked.',
      incorrectTwoFactorDescription: 'Username {0} failed to login because the two-factor auth code is not valid',
    },

    isTwoFactorDone: {
      key: 'isTwoFactorDone',
      true: 'true',
      false: 'false',
      }
};

export const PasswordFormConstants = {
    password: 'Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm New Password',
    changePassword: 'Change Password',
    enterPassword: 'Please enter your new password',
    resetPassword: 'Reset Password',
    retypePassword: 'Retype Password',
    changeSuccessMessage: 'Your password has been changed.',
    cancel: 'Cancel',
    newUserChangePassword: 'Password successfully changed, please login using your new password.',
    resetPasswordRequestSuccess: 'Password Reset Email has been successfully received by {0}.',
    resetPasswordConfirmation: 'Are you sure you want to reset the password?',
    resetPasswordError: 'Error in sending Password Reset Email. Please check if {0} is correct. If issue persists, please contact the system administrator.',
    forgotPasswordConfirmationMessage: 'Further instructions have been sent to your e-mail address.',

    auditLog: {
      forgotPwRequestLog: {
        auditType: 'FORGOTPWD',
        description: 'Username {0} request a forgot password',
      },
      forgotPwSuccessLog: {
        auditType: 'FORGOTPWD',
        description: 'Username {0} successfully changed his/her forgot password',
      },
      changePwSuccessLog: {
        auditType: 'CHANGEDPWD',
        description: 'Username {0} successfully changed his/her password',
      },
      resetPwSuccessLog: {
        auditType: 'RESETPWD',
        description: 'Username {0} successfully reset his/her password',
      },
      methodName: {
        requestForgot: 'sendForgotPasswordEmail',
        successForgot: 'resetPassword',
        change: 'changePassword',
        reset: 'userResetPassword'
      },
      action: {
        requestForgot: 'request-forgot',
        successForgot: 'success-forgot',
        successChange: 'success-change',
        successReset: 'success-reset',
      }
    },
};

export const ReferenceCodeConstants = {
  title: 'Verification Code',
  description1: 'A security code was sent to you via email. If you don`t see the email, check your spam folder.',
  description2: 'Please enter the code  below then click Continue to complete your login.',
  continue: 'Continue',
  resendCode: 'Resend Code',
  referenceNumber: 'Reference Number:',
  countdown: 60,
  tick: 1000,
  CodeSuccessSent: 'Verification Code Sent',
  CodeisEmpty: 'Vertification Code is empty',
  invalidCode: 'Security Code is invalid'
};