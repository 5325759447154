import { Component } from '@angular/core';
import { BaseClass } from '../../../../shared/base-class';
import { PolicyDocumentsData } from '../../data/policy-documents.data';
import { FormDocumentService } from '../../../../core/services/submission/report/form-document.service';
import { DocumentsLabelConstants } from '../../../../shared/constants/bind-and-issue.labels.constants';
import { suppressDocumentDTO } from '../../../../shared/models/data/dto/form-document/suppressDocumentDTO';
import { EntityRiskData } from '../../../submission-management/data/entity-risk.data';
import Utils from '../../../../shared/utilities/utils';
import { takeUntil } from 'rxjs/operators';
import { PolicyService } from '../../../../core/services/submission/policy.service';

@Component({
  selector: 'app-suppress-documents',
  templateUrl: './suppress-documents.component.html',
  styleUrls: ['./suppress-documents.component.scss']
})
export class SuppressDocumentsComponent extends BaseClass {

  DocumentsLabelConstants = DocumentsLabelConstants;
  isSuppressOpen: boolean = true;

  constructor(
    public policyDocumentsData: PolicyDocumentsData,
    public formDocumentService: FormDocumentService,
    public entityRiskData: EntityRiskData,
    public policyService: PolicyService) {
    super();
  }

  collapseSuppress(): void {
    this.isSuppressOpen = !this.isSuppressOpen;
  }

  getCategory(categorycode: string): void {
    return this.policyDocumentsData.documentsCategoryList.find(a => a.code === categorycode).description;
  }

  checkIfAllDocumentsSelected(): boolean {
    if (this.policyDocumentsData?.suppressDocuments?.length !== 0) {
      return this.policyDocumentsData.suppressDocuments?.filter(a => !a.isSuppressed).length === 0;
    }
    return false;
  }

  onDocumentChecked(item?: suppressDocumentDTO, event?: any): void {
    const isDocumentChecked = event.target.checked;

    this.policyDocumentsData.suppressDocuments.map(value => {
      if (value.id === item.id) {
        value.isSuppressed = isDocumentChecked;
      }
      return value;
    });
  }

  getFTPDocuments(): void {
    this.policyDocumentsData.getFTPDocuments(this.entityRiskData?.getRiskId());
  }

  onViewDocument(document: suppressDocumentDTO): void {
    this.policyService.generateSASURL(Utils.URLEncoder(document.filePath, document.fileName)).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
      window.open(resultSASURL);
    });
  }

}