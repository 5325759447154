import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { EntityRiskDTO } from '../../../shared/models/data/dto/quick-quote/entity-risk.dto';
import { BaseClass } from '../../../shared/base-class';
import { PremiumChangeModel } from '../../../shared/models/policy/premium-changes.model';
import { SummaryData } from '../../../modules/submission-management/data/summary.data';
import { PolicyIssueDTO } from '../../../shared/models/data/dto/policy-management/policy-issue.dto';
import { CalculateRaterData } from '../../../core/services/submission/rater-premium/calculate-rater-data.service';
import { RaterFormTypeConstants } from '../../../shared/constants/rater-form-types.constants';
import { DP3PerilType } from '../../../shared/enum/peril-type.enum';
import { DPRater } from '../../../shared/constants/dp3-rater.constant';
import { Rater } from '../../../shared/constants/rater.constant';
import { AuthService } from 'app/core/services/auth.service';
import { Subject, Subscription } from 'rxjs';
import { PolicySummaryData } from './policy-summary.data';
import { RiskStatus, RiskStatusCode } from 'app/shared/models/data/dto/quick-quote/risk.dto';
import { takeUntil } from 'rxjs/operators';
import { EntityRiskData } from '../../../modules/submission-management/data/entity-risk.data';
import { RiskDTO2 } from 'app/shared/models/data/dto/quick-quote/risk-dto2';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { RiskOutOfSequenceRequestDTO } from 'app/shared/models/data/dto/policy-management/risk-outofsequence.request.dto';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { PolicyService } from 'app/core/services/submission/policy.service';
import Utils from 'app/shared/utilities/utils';
import * as moment from 'moment';
import { updateAppIsConfirmationFromPolicyIssueData, updateAppIsLoadingFromPolicyIssueData } from 'app/store/app/app.actions';
import { Store } from '@ngrx/store';
import { updatePolicyIsLoadingOosFromPolicyIssueData } from 'app/store/policy/policy.actions';
import { updateRaterIsLoadingFromPolicyIssueData } from 'app/store/rater/rater.actions';

@Injectable({
  providedIn: 'root'
})
export class PolicyIssueData extends BaseClass {
  issuePolicyForm: FormGroup;
  policyChangesList: Array<string>;
  premiumChangesForm: FormGroup;
  textForEndorsementForm: FormGroup;
  policyChangesLength: number;
  premiumChangeList: PremiumChangeModel[] = [];
  changeEffectiveDateOption: IAngularMyDpOptions;
  proratedPremiums: any = {};
  disableChangeEffectiveDate: boolean = true;

  latestIssuedChangeEffectiveDate: any;
  latestIssuedeExpirationDate: any;
  policyFirstIssueEffectiveDate: any;
  formType: string;

  policyIssueResult: PolicyIssueDTO;

  isInternal: boolean;
  isEditButtonClicked$: Subject<boolean> = new Subject<boolean>();

  isIssuePolicyFormValid: boolean = true;

  isPolicyChangesLoading: boolean = false;

  policyChangesSubscription: Subscription;
  policyChangesSeniorRetireeSubscription: Subscription;
  premiumChangesSubscription: Subscription;
  outOfSequenceSubscription: Subscription;

  isOutOfSequenceTransaction: boolean = false;

  constructor(
    protected raterData: CalculateRaterData,
    protected summaryData: SummaryData,
    protected entityRiskData: EntityRiskData,
    protected policySummaryData: PolicySummaryData,
    protected storage: LocalStorageService,
    protected policyService: PolicyService,
    protected calculateRaterData: CalculateRaterData,
    protected store: Store
  ) {
    super();
    this.updatePremiumChangesDetails();
  }

  initForms(): void {
    this.formType = this.summaryData.SummaryForm.get('formType')?.value;
    this.policyChangesList = [];
    this.issuePolicyForm = this.issuePolicySection();
    this.textForEndorsementForm = this.textForEndorsementSection();
    this.premiumChangesForm = this.premiumChangesSection();
    this.dateOptions();
  }

  issuePolicySection(): FormGroup {
    return new FormGroup({
      changeEffectiveDate: new FormControl('', Validators.required)
    });
  }

  textForEndorsementSection(): FormGroup {
    return new FormGroup({
      textForEndorsementPolicyChanges: new FormControl('')
    });
  }

  premiumChangesSection(): FormGroup {
    return new FormGroup({
    });
  }

  dateOptions(): void {
    this.changeEffectiveDateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy'
    };
  }

  setChangeEffectiveDateDefaultValue(activeEndorsementEffectiveDate: Date): void {
    const currentDate = this.checkCurrentDate();
    const currentEffectiveDate = activeEndorsementEffectiveDate;
    const defaultDate = currentEffectiveDate > currentDate ? currentEffectiveDate : currentDate;
    this.issuePolicyForm.get('changeEffectiveDate').setValue({
      singleDate: {
        date: {
          year: defaultDate.getFullYear(),
          month: defaultDate.getMonth() + 1,
          day: defaultDate.getDate()
        },
        jsDate: defaultDate
      }
    });
  }

  setChangeEffectiveDateOption(): void {
    const currentDate = this.checkCurrentDate();
    const policyEffectiveDate = new Date(this.policyFirstIssueEffectiveDate?.singleDate.jsDate);

    const disableUntilDate: Date = this.isInternal ? policyEffectiveDate : currentDate;

    disableUntilDate.setDate(disableUntilDate.getDate() - 1);
    this.changeEffectiveDateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy',
      disableUntil: {
        year: disableUntilDate.getFullYear(),
        month: disableUntilDate.getMonth() + 1,
        day: disableUntilDate.getDate()
      },
      disableSince: {
        year: this.policyFirstIssueEffectiveDate?.singleDate.jsDate.getFullYear() + 1,
        month: this.policyFirstIssueEffectiveDate?.singleDate.jsDate.getMonth() + 1,
        day: this.policyFirstIssueEffectiveDate?.singleDate.jsDate.getDate() + 1
      }
    };
  }

  checkCurrentDate(): Date {
    const currentDate = new Date();
    const localStorageDate = localStorage.getItem('loginDate');
    if (localStorageDate !== 'undefined' && localStorageDate !== null && localStorageDate !== 'null') {
      const date = JSON.parse(localStorageDate)?.singleDate?.date;
      return new Date(`${date.month}/${date.day}/${date.year} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}.${currentDate.getMilliseconds()}`);
    } else {
      return currentDate;
    }
  }

  populatePolicyChanges(data: EntityRiskDTO): void {
    const risks = data?.risks[0];
    const activeEndorsementEffectiveDate = new Date(risks?.riskDetails[1]?.effectiveDate);
    this.setChangeEffectiveDateDefaultValue(activeEndorsementEffectiveDate);

    this.raterData.populatedEndorsementIssuePayload(this.issuePolicyForm.get('changeEffectiveDate')?.value?.singleDate?.jsDate);

    this.latestIssuedChangeEffectiveDate = { isRange: false, singleDate: { jsDate: new Date(moment(activeEndorsementEffectiveDate).format('YYYY-MM-DDT00:00:00.000')) } };
    this.policyFirstIssueEffectiveDate = { isRange: false, singleDate: { jsDate: new Date(risks?.firstIssueDate) } };
    this.latestIssuedeExpirationDate = { isRange: false, singleDate: { jsDate: new Date(risks?.riskDetails[0].expirationDate) } };

    this.setChangeEffectiveDateOption();
  }

  updatePremiumChangesDetails(): void {
    this.raterData.proratedRaterResults.subscribe(resultMain => {
      this.proratedPremiums = resultMain.proratedPremiumDetails;

      this.premiumChangeList = [];
      this.proratedPremiums.reduce((acc, cur) => {
        acc[String(cur.id)] = cur.stepCode;
        return acc;
      }, {});

      const premiums = this.groupBy(this.proratedPremiums);
      premiums.forEach(result => {
        this.formType = this.summaryData.SummaryForm.get('formType')?.value;
        const premium = this.formType.substring(0, 2) !== RaterFormTypeConstants.DP3 ? result :
          (result[0].stepAlias === DPRater.totPerilPrem || result[0].stepAlias === DPRater.totPremByPeril ?
            result : result.filter(p => p.peril === DP3PerilType.ADDITIVE));
        const currentPremium = premium.reduce((prev, next) => prev + next.currentPremium, 0);
        const newPremium = premium.reduce((prev, next) => prev + next.newPremium, 0);
        const premiumDiffChange = premium.reduce((prev, next) => prev + next.proratedPremiumDiff, 0);
        const proratedPremium = premium.reduce((prev, next) => prev + next.totalProratedPremium, 0);

        const premiumChanges: PremiumChangeModel = {
          stepCode: premium[0].stepCode,
          description: premium[0].description,
          coverages: premium[0].description,
          currentPremium: Math.round(currentPremium),
          newPremium: Math.round(newPremium),
          premiumDiffChange: Math.round(premiumDiffChange),
          proratedPremium: Math.round(proratedPremium),
          stepAlias: premium[0].stepAlias
        };

        this.premiumChangeList = [...this.premiumChangeList, premiumChanges];
      });
    });
  }

  groupBy(list) {
    const map = new Map();
    list.forEach((item) => {
      const key = item.stepCode;
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  resetPolicyChanges(): void {
    this.policyChangesList = [];
    this.policyChangesLength = 0;
    this.textForEndorsementForm.get('textForEndorsementPolicyChanges')?.setValue('');
    this.summaryData.isDraftRiskSubStatus = true;
  }

  triggerPolicyChanges(): void {
    this.summaryData.setSubStatusCode();
    this.summaryData.showPendingEndorsementStatus = (this.policySummaryData.policyChanges?.length > 0);
  }

  checkIssuePage(): boolean {
    this.isIssuePolicyFormValid = true;
    const latestIssuedChangeEffectiveDate = this.latestIssuedChangeEffectiveDate?.singleDate?.jsDate;
    const changeEffectiveDate = this.issuePolicyForm.get('changeEffectiveDate')?.value?.singleDate?.jsDate;
    const policyFirstIssueEffectiveDate = new Date(this.policyFirstIssueEffectiveDate?.singleDate?.jsDate?.getFullYear() + 1, this.policyFirstIssueEffectiveDate?.singleDate?.jsDate?.getMonth(), this.policyFirstIssueEffectiveDate?.singleDate?.jsDate?.getDate());
    if (changeEffectiveDate - latestIssuedChangeEffectiveDate < 0 || changeEffectiveDate > policyFirstIssueEffectiveDate) {
      this.isIssuePolicyFormValid = false;
    }

    return this.isIssuePolicyFormValid;
  }

  revertPolicySubStatusToDraft(): void {
    const riskDetailId = this.entityRiskData.getRisk()?.riskDetails[0].id;
    this.policySummaryData.showRiskSubStatus = false;

    this.summaryData.policyService.policyUpdateSubStatusCode(riskDetailId, 'Draft').pipe(takeUntil(this.stop$)).subscribe(result => {
      if (result) {
        this.summaryData.submissionPageData.policyStatus.next('Draft' as RiskStatus);
        if (result === 'Pending' || (result === 'Draft' && this.policyChangesLength > 0)) {
          this.summaryData.showPendingEndorsementStatus = true;
          this.summaryData.isDraftRiskSubStatus = false;
        } else {
          this.summaryData.showPendingEndorsementStatus = false;
          this.summaryData.isDraftRiskSubStatus = result === 'Draft';
        }
      }

      this.policySummaryData.showRiskSubStatus = true;
    });
  }

  callOutOfSequence(newSelectedEffectiveDate: any, withChanges?: boolean): void {
    const latestEffectiveDate = new Date(moment(this.latestIssuedChangeEffectiveDate?.singleDate?.jsDate).format('YYYY-MM-DDT00:00:00.000'));
      const oosNewSelectedEffectiveDate = new Date(newSelectedEffectiveDate); // used for condition only
      if (oosNewSelectedEffectiveDate) {
        if (latestEffectiveDate > oosNewSelectedEffectiveDate) {
          switch (withChanges) {
            case true:
              this.store.dispatch(updateAppIsConfirmationFromPolicyIssueData({ isConfirmation: true }));
              NotifUtils.showConfirmMessage(GenericConstants.outOfSequencePromptMessage, () => {
                this.store.dispatch(updateAppIsConfirmationFromPolicyIssueData({ isConfirmation: false }));
                this.outOfSequenceTransaction(newSelectedEffectiveDate);
              }, () => {
                this.store.dispatch(updateAppIsConfirmationFromPolicyIssueData({ isConfirmation: false }));
                this.issuePolicyForm.get('changeEffectiveDate').setValue({ isRange: false, singleDate: { jsDate: new Date(moment(this.latestIssuedChangeEffectiveDate?.singleDate?.jsDate).format('YYYY-MM-DDT00:00:00.000'))} });
                this.store.dispatch(updateAppIsLoadingFromPolicyIssueData({ isLoading: true }));
                this.store.dispatch(updateAppIsLoadingFromPolicyIssueData({ isLoading: false }));
              });
              break;
            case false:
              this.outOfSequenceTransaction(newSelectedEffectiveDate);
              break;
          };
        } else {
          this.raterData.populatedEndorsementIssuePayload(newSelectedEffectiveDate);
        }
      }
  }

  outOfSequenceTransaction(newSelectedEffectiveDate): void {
    const outOfSequencePayload: RiskOutOfSequenceRequestDTO = {
      riskId: this.entityRiskData?.getRiskId(),
      effectiveDate: newSelectedEffectiveDate,
      raterRequest: this.storage.get(`raterRequest_${this.entityRiskData?.getRiskDetailId()}`),
      isProceedSaving: false
    };

    this.store.dispatch(updateAppIsLoadingFromPolicyIssueData({ isLoading: true }));
    this.store.dispatch(updatePolicyIsLoadingOosFromPolicyIssueData({ isLoadingOos: true }));
    this.store.dispatch(updateRaterIsLoadingFromPolicyIssueData({ isLoading : true }));

    if (this.outOfSequenceSubscription) {
      this.outOfSequenceSubscription.unsubscribe();
    }

    this.outOfSequenceSubscription = this.policyService.postOutOfSequence(outOfSequencePayload).subscribe((result) => {
      this.policyChangesList = result.policyChanges;
      this.policyChangesLength = result.policyChanges.length;
      this.textForEndorsementForm.get('textForEndorsementPolicyChanges')?.setValue(this.policyChangesList.join('\n'));
      this.calculateRaterData.getProratedPremiumDetails(result.raterResult);
      this.isOutOfSequenceTransaction = true;
      this.store.dispatch(updateAppIsLoadingFromPolicyIssueData({ isLoading: false }));
      this.store.dispatch(updatePolicyIsLoadingOosFromPolicyIssueData({ isLoadingOos: false }));
      this.store.dispatch(updateRaterIsLoadingFromPolicyIssueData({ isLoading : false }));
    });
  }

}
