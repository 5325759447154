import { CanDeactivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AutoReinstatementApprovalComponent } from './auto-reinstatement-approval.component';
import { AutoReinstatementData } from '../../../modules/policy-management/data/auto-reinstatement.data';
import NotifUtils from '../../utilities/notif-utils';
import { PolicySummaryData } from '../../../modules/policy-management/data/policy-summary.data';
import { GenericConstants } from '../../constants/generic.constants';
import { AutoReinstatementConstants } from '../../constants/auto-reinstatement.constants';

@Injectable()
export class CanDeactivateAutoReinstatementApprovalComponentGuard implements CanDeactivate<AutoReinstatementApprovalComponent> {
    constructor(
        public router: Router,
        public autoReinstatementData: AutoReinstatementData,
        public policySummaryData: PolicySummaryData) { }

    get isExternalUser(): boolean {
        return this.autoReinstatementData.authService.userType.value === GenericConstants.userType.external;
    }

    canDeactivate(): boolean {
        if (this.autoReinstatementData.isApprovalRequired$.value &&
            this.hasIncompleteValues() && !this.isExternalUser) {
            NotifUtils.showError(AutoReinstatementConstants.errorMessage.errorInReinstatementSection);
            return false;
        }
        return true;
    }

    private hasIncompleteValues(): boolean {
        const isApproved: boolean = this.autoReinstatementData.autoReinstatementForm.get('isApprovedReinstatement').value;
        return isApproved ? this.autoReinstatementData.hasIncompleteValues() && this.autoReinstatementData.isSubmissionPolicies : false;
    }
}