import { StateCodeValuePair } from 'app/shared/models/management/program-state-response.dto';

export interface DashboardState {
  isLoading: boolean | null;
  stateList: StateCodeValuePair[] | null;
}

export const initialState: DashboardState = {
  isLoading: null,
  stateList: null
};