import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'disableTransferSelectedButton'
})
export class DisableTransferSelectedButtonPipe implements PipeTransform {
  transform(toTransferPolicies: any, isBatchStatusCompleted: boolean): boolean {
    if (toTransferPolicies?.length === 0) {
      return true;
    }

    const hasSelectedPolicy = toTransferPolicies?.some((policy: any) => {
      return policy.isSelected === true;
    });

    return !hasSelectedPolicy || isBatchStatusCompleted;
  }
}
