export const OfacSearchConstants = {
  OfacSearchBindAndIssueNoteDetails: {
    NotesCategory: 'MISC',
    NotesAddedBy: 'System',
  },
  OfacSearchTriggerConstants: {
    SearchTriggerPolicyBound: 'Policy Bound',
    SearchTriggerNotBound: 'Not Bound',
    SearchTriggerInterestAdded: 'Interest Added',
    SearchTriggerRefundPayee: 'Refund Payee',
  },
  OfacSearchResultMatchConstants: {
    SearchResultMatchFound: 'Match Found',
    SearchResultNoMatch: 'No Match'
  }
};