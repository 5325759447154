import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentsLabelConstants } from '../../../../../shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { take, takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../../../shared/base-class';
import { QuickQuoteService } from '../../../../../../app/core/services/submission/quick-quote.service';
import { PolicyBillingData } from '../../../../../../app/modules/policy-management/data/policy-billing.data';
import { PolicyBillingLabelsConstants } from '../../../../../../app/shared/constants/policy-billing.labels.constants';
import { BillingService } from '../../../../../../app/core/services/billing/billing.service';
import { SuspendedPaymentsLabel } from '../../../../../../app/shared/constants/suspended-payments.labels.constants';
import { DocumentUploadModalComponent } from '../../../../../../app/modules/policy-management/policy-billing/payments/related-documents/document-upload-modal/document-upload-modal.component';
import { PaymentSuspendedDocDTO } from '../../../../../../app/shared/models/data/dto/billing/payment-suspended-doc.dto';
import Utils from '../../../../../../app/shared/utilities/utils';
import NotifUtils from '../../../../../../app/shared/utilities/notif-utils';
import { BatchPaymentsData } from '../../../../../modules/payments/data/batch-payments.data';
import { PaymentBatchDocDTO } from '../../../../../shared/models/data/dto/billing/payment-batch-doc.dto';
import { UserService } from 'app/core/services/management/user.service';
import { AuthService } from 'app/core/services/auth.service';

@Component({
    selector: 'app-batch-payment-related-doc',
    templateUrl: './batch-payment-related-doc.component.html',
    styleUrls: ['./batch-payment-related-doc.component.scss']
})
export class BatchPaymentRelatedDocComponent extends BaseClass implements OnInit, OnDestroy {

    public SuspendedPaymentsLabel = SuspendedPaymentsLabel;
    public ErrorMessageConstant = ErrorMessageConstant;

    @Input() batchPaymentDetailId: string;
    @Input() isAdd: boolean = false;
    @Input() batchPaymentDetailIndex: number;
    @Input() isEditRecord: boolean;

    PolicyBillingLabelsConstants = PolicyBillingLabelsConstants;
    DocumentLabelConstants = DocumentsLabelConstants;

    GenericLabel = GenericLabel;
    isOpen: boolean = true;
    modalRef: BsModalRef | null;

    userFullName: string;

    constructor(public policyBillingData: PolicyBillingData,
                private modalService: BsModalService,
                public quickQuoteService: QuickQuoteService,
                private billingService: BillingService,
                private batchPaymentData: BatchPaymentsData,
                private userService: UserService,
                private authService: AuthService) {
        super();
    }

    ngOnInit() {
        if (this.batchPaymentDetailId) {
            this.batchPaymentData.getBatchPaymentDetailById(this.batchPaymentDetailId);
            this.batchPaymentData.resetDocumentVariables();
            this.loadTableItems();
            this.isAdd = false;
        }

        this.getUserfullNameForUploadingDocs(this.authService.getUserId());
    }

    get paymentDocumentTableRows() {
        return this.batchPaymentData.paymentDocumentTableRows;
    }

    loadTableItems(): void {
        Utils.blockUI();
        if (this.batchPaymentDetailId) {
            this.batchPaymentData.paymentDocumentTableRows = [];
            this.billingService.listBatchPaymentDoc(this.batchPaymentDetailId).pipe(takeUntil(this.stop$)).subscribe(res => {
                if (res.length) {
                    this.batchPaymentData.paymentDocumentTableRows = res;
                }
                Utils.unblockUI();
            });
        }

    }

    onUploadClick(): void {
        const initialState = {
            title: this.DocumentLabelConstants.addTitle,
            hideCategoryField: true,
        };
        this.modalRef = this.modalService.show(DocumentUploadModalComponent, {
            initialState,
            backdrop: true,
            ignoreBackdropClick: true,
        });

        this.modalRef.content.event.pipe(takeUntil(this.stop$)).subscribe((res) => {
            const request: PaymentBatchDocDTO = {
                batchPaymentDetailId: this.batchPaymentDetailId ?? '',
                fileName: res.data.documentFile.name,
                filePath: res.data.documentFile.name,
                description: res.data.description,
                isActive: true,
                file: res.data.documentFile,
                isUploaded: true
            };

            if (this.batchPaymentDetailId) {
                this.batchPaymentData.documentRequest = [];
            }

            this.batchPaymentData.documentRequest.push(request);

            if (this.batchPaymentDetailId) {
                this.batchPaymentData.addBatchPaymentDoc(this.batchPaymentDetailId);

                this.batchPaymentData.isDocumentUploadSuccess.pipe(take(1)).subscribe(value => {
                    if (value) {
                        this.loadTableItems();
                    }
                });
            } else {
                this.setTemporaryTableView(request);
            }
        });
    }

    onViewClick(filePath: string, fileName: string): void {
        window.open(Utils.URLEncoder(filePath, fileName), '_blank');
    }

    onDeleteClick(document: any, index: number): void {
        if (document.id) {
            NotifUtils.showConfirmMessage(this.SuspendedPaymentsLabel.relatedDocuments.deleteConfirmation, () => {
                Utils.blockUI();
                this.billingService.deleteBatchPaymentDoc(document.id).subscribe(_ => {
                    Utils.unblockUI();
                    this.loadTableItems();
                },
                    err => {
                        Utils.unblockUI();
                        NotifUtils.showMultiLineError(err.error?.message);
                    }
                );
            });
        } else {
            this.batchPaymentData.paymentDocumentTableRows.splice(index, 1);
            this.batchPaymentData.documentRequest.splice(index, 1);
        }
    }

    setTemporaryTableView(data: PaymentSuspendedDocDTO): void {
        const newEntry = {
            createdBy: data.createdBy ?? 0,
            createdDate: data.createdDate ?? new Date(Date.now()),
            description: data.description,
            fileName: data.fileName ?? '',
            filePath: data.filePath ?? '',
            id: data.id ?? '',
            isActive: true,
            isUploaded: true,
            riskId: null,
            suspendedPaymentId: data.suspendedPaymentId ?? '',
            createdByFullName: this.userFullName
        };
        this.batchPaymentData.paymentDocumentTableRows.push(newEntry);
        this.batchPaymentData.editPaymentDocumentTableRows[this.batchPaymentDetailIndex].push(newEntry);
    }

    getUserfullNameForUploadingDocs(id: number) {
        Utils.blockUI();
        this.userService.getUserInfoById(id).subscribe(x => {
          this.userFullName = x.fullName;
          Utils.unblockUI();
        });
      }

    ngOnDestroy() {
        if (this.batchPaymentDetailId) {
            this.batchPaymentData.resetDocumentVariables();
        }
    }
}
