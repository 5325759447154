export const PathConstants = {
    NotFound: '/404',
    Home: {
        Index: 'home'
    },
    Login: {
        Index: 'login',
        IndexWithSlash: '/login'
    },
    Auth: {
        Index: 'auth'
    },
    Test: {
        Index: 'test',
        TestComponents: 'test-components',
        TestRoutes: {
            TestRoute: 'test/test-components'
        }
    },
    Account: {
        Index: 'account',
        ForgotPassword: 'forgot-password',
        ResetPassword: 'reset-password',
        ChangePassword: 'change-password',
        NewUserChangePassword: 'change-password-new-user',
        ChangePasswordAtLogin: 'change-password-at-login',
        AccountRoutes: {
        }
    },
    Dashboard: {
        Index: 'dashboard',
    },
    SubmissionManagement: {
        Index: 'submissions',
        FLES: 'fl-es/submissions'
    },
    QuickQuote: {
        Index: 'quickquote',
        List: 'list',
        QuickQuote: {
            Index: 'quickquote',
            ApplicantPage: 'applicant-page'
        },
        SubmissionRoutes: {
            SubmissionRoute: 'quickquote/index'
        }
    },
    Applicant: {
        Index: 'applicant',
        List: 'list',
        Applicant: {
            Index: 'applicant',
            ApplicantPage: 'applicant-page',
        },
        SubmissionRoutes: {
            SubmissionRoute: 'applicant/index'
        }
    },
    Submission: {
        Index: 'submissions',
        List: 'list',
        Submission: {
            Index: 'submission',
            CoveragesAndDeduction: 'coverages-and-deductions',
            Endorsements: 'endorsements',
            Claims: 'claims',
            Property: 'property',
            UWQuestions: 'uw-questions',
            Interests: 'interests',
            UWApproval: 'uw-approval',
            BindAndIssue: 'bind-and-issue',
            RaterPremiumTable: 'rater-premium-table',
            Applicant: 'applicant',
            ApplicantPage: 'applicant-page'
        },
        SubmissionRoutes: {
            SubmissionRoute: 'submission/index'
        }
    },
    PageDestination: {
        Coverages: 'coverages-and-deductions',
        Endorsements: 'endorsements',
        Claims: 'claims',
        Property: 'property',
        Applicant: 'applicant-page',
        UWQuestions: 'uw-questions',
        Interests: 'interests',
        UWApproval: 'uw-approval',
        BindAndIssue: 'bind-and-issue',
        Quickquote: 'quick-quote'
    },
    Policy: {
      Index: 'policies',
      List: 'list',
      Policies: {
        Index: 'policy',
        Summary: 'summary',
        History: 'history',
        Billing: 'billing',
        Documents: 'documents',
        CoveragesAndDeduction: 'coverages-and-deductions',
        Endorsements: 'endorsements',
        Claims: 'claims',
        Property: 'property',
        Applicant: 'applicant',
        ApplicantPage: 'applicant-page',
        UWQuestions: 'uw-questions',
        Interests: 'interests',
        UWApproval: 'uw-approval',
        Issue: 'issue',
        Notes: 'notes',
        Tasks: 'tasks',
        RaterPremiumTable: 'rater-premium-table'
      },
      PolicyRoutes: {
        PolicyRoute: 'policy/index'
      }
    },
    Payments: {
      Index: 'payments',
      SuspendedPayments: {
        Index: 'suspended-payments',
        Pending: 'pending',
        Returned: 'returned',
        SentToIpx: 'sent-to-ipx'
      },
      Refund: {
        Index: 'refund',
        Request: 'request'
      },
      BatchPayments: {
        Index: 'batch-payments',
        Pending: 'pending',
        PendingLabel: 'PENDING',
        Processed: 'processed',
        ProcessedLabel: 'PROCESSED',
      }
    },
    Management: {
        Index: 'management',
        Users: {
            Index: 'users',
            Agent: 'agent-list' ,
            User: 'system-user',
            UserType: 'user-type',
            UserGroup: 'user-group'
        },
        Agency: {
            Index: 'agency',
            New: 'new',
            Edit: 'edit',
        },
        Roles: {
            Index: 'roles',
        },
    },
    Reports: {
        Index: 'reports',
        Agency: {
            Index: 'agency',
            Generic: 'generic',
            Miscellaneous: 'miscellaneous',
            CustomerService: 'customerservice',
            EarnedPremium: 'earned-premium',
            AgencySnapshot: 'agency-snapshot',
            CommissionStatement: 'commission-statement'
        },
        Underwriter: {
            Index: 'underwriter',
            Generic: 'generic',
            LeadingIndicator: 'leading-indicator'
        }
    },
    BookTransfer: {
        Index: 'book-transfer',
        BatchDashboard: 'batch-dashboard',
        New: 'new',
        Edit: 'edit',
    },
    AccessDenied001: {
        Index: 'access-denied-001'
    },
    MaintenanceMode: {
        Index: 'maintenance-mode'
    }
};
