import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { FormTypeConstants } from '../constants/form-types.constants';

@Pipe({
  name: 'roofSurfacingPaymentScheduleBRULDate'
})
export class RoofSurfacingPaymentScheduleBRULDatePipe implements PipeTransform {
  transform(formType: any, rspsBrulDate: any, effectiveDate: any, hasRSPSRaterResult: boolean, effectiveDateChangedFromQuote: boolean = false, forUILogicOnly?: boolean): boolean {
    const isActive: boolean = false;
    if (formType === FormTypeConstants.HO3 || formType === FormTypeConstants.DP3) {
      const final_effectiveDate = effectiveDateChangedFromQuote ? (effectiveDate?.jsDate ? effectiveDate?.jsDate : effectiveDate?.singleDate?.jsDate)    : effectiveDate?.singleDate?.jsDate;

      if (forUILogicOnly) {
        return moment(rspsBrulDate).diff(final_effectiveDate, 'days') <= 0;
      } else {
        return moment(rspsBrulDate).diff(final_effectiveDate, 'days') <= 0
              && hasRSPSRaterResult;
      }
    }

    return isActive;
  }
}
