import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppAsideModule } from '@coreui/angular';
import { ModalModule } from 'ngx-bootstrap';
import { PathConstants } from 'app/shared/constants/path.constants';
import { BookTransferComponent } from './book-transfer.component';
import { NewTransferComponent } from 'app/modules/book-transfer/pages/new-transfer/new-transfer.component';
import { EditTransferComponent } from 'app/modules/book-transfer/pages/edit-transfer/edit-transfer.component';
import { BatchTransferDashboardComponent } from 'app/modules/book-transfer/pages/batch-transfer-dashboard/batch-transfer-dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: BookTransferComponent,
    children: [
      {
        path: PathConstants.BookTransfer.BatchDashboard,
        component: BatchTransferDashboardComponent
      },
      {
        path: PathConstants.BookTransfer.New,
        component: NewTransferComponent,
      },
      {
        path: `${PathConstants.BookTransfer.Edit}/:uuid`,
        component: EditTransferComponent,
      },
      { path: '', redirectTo: PathConstants.BookTransfer.BatchDashboard, pathMatch: 'full' },
      { path: '', redirectTo: '/404', pathMatch: 'full' },   // temporarily redirecting to suspended payments
    ]
  },
  {
    path: '',
    redirectTo: PathConstants.BookTransfer.Index,
    pathMatch: 'full'
  },
  // { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes), AppAsideModule, ModalModule.forRoot()],
  exports: [RouterModule]
})
export class BookTransferRoutingModule { }
