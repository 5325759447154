import { CommissionPaymentDTO } from 'app/shared/models/data/dto/nacha/commission-payment.dto';
import { createAction, props } from '@ngrx/store';

export const updateCommissionPaymentIsLoading = createAction(
  '[CommissionPaymentData] Update CommissionPaymentRequest Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateCommissionPaymentListFromCommissionPaymentData = createAction(
  '[CommissionPaymentData] Update CommissionPayment List.',
  props<{commissionPaymentList: CommissionPaymentDTO[]}>()
);

export const updateFilteredCommissionPaymentListFromCommissionPaymentData = createAction(
  '[CommissionPaymentData] Update FilteredCommissionPayment List.',
  props<{filteredCommissionPaymentList: CommissionPaymentDTO[]}>()
);