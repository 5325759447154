import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ErrorMessageConstant } from '../../../../../../app/shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../../app/shared/constants/generic.labels.constants';
import { PolicyBillingLabelsConstants } from '../../../../../../app/shared/constants/policy-billing.labels.constants';
import { BsModalRef } from 'ngx-bootstrap';
import FormUtils from '../../../../../shared/utilities/form.utils';
import { LvSuspenseReason } from '../../../../../shared/constants/transfer-to-suspense.options.constants';
import { SuspendedCheckDTO } from '../../../../../shared/models/data/dto/billing/suspended-check.dto';
import { SuspendedDetailDTO } from '../../../../../shared/models/data/dto/billing/suspended-detail.dto';
import { RegexConstants } from '../../../../../shared/constants/regex.constants';
import { CustomValidators } from '../../../../../shared/validators/custom.validator';
import { SuspendedPaymentDetailsDTO } from '../../../../../shared/models/data/dto/suspended-payment/suspended-payment-detail.dto';

@Component({
  selector: 'app-transfer-payment-to-suspense-modal',
  templateUrl: './transfer-payment-to-suspense-modal.component.html',
  styleUrls: ['./transfer-payment-to-suspense-modal.component.scss']
})
export class TransferPaymentToSuspenseModalComponent implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();

  transferPaymentForm: FormGroup;
  FormUtils: FormUtils;
  TransferToSuspensePaymentConstants = PolicyBillingLabelsConstants.transferPaymentToSuspense;
  LvSuspenseReason = LvSuspenseReason;
  ErrorMessageConstant = ErrorMessageConstant;
  GenericLabel = GenericLabel;
  postDate: string;
  postedAmount: number;
  transferableAmount: number;
  paymentMethod: string;
  referenceNum: string;
  suspendedPaymentDetail: SuspendedPaymentDetailsDTO;

  constructor(
    protected fb: FormBuilder,
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.initTransferPaymentForm();
    this.populateForm();
  }

  initTransferPaymentForm(): void {
    this.transferPaymentForm = this.fb.group({
      postDate:  new FormControl(null, [Validators.required]),
      postedAmount: new FormControl(null, [Validators.required]),
      paymentMethod:  new FormControl(null, [Validators.required]),
      policyNumber: new FormControl(null, [Validators.pattern(RegexConstants.policyNumber), CustomValidators.spaceValidator]),
      amountToTransfer: new FormControl(null, [Validators.required, Validators.min(1)]),
      suspenseReason: new FormControl('', [Validators.required]),
      comment: new FormControl('')
    });
  }

  populateForm(): void {
    this.transferPaymentForm.get('postDate').setValue(this.postDate);
    this.transferPaymentForm.get('postedAmount').setValue(this.postedAmount);
    this.transferPaymentForm.get('paymentMethod').setValue(this.paymentMethod);

    this.setFormValidators();
  }

  setFormValidators(): void {
    this.transferPaymentForm.get('amountToTransfer').setValidators([Validators.required, Validators.min(1), Validators.max(this.transferableAmount)]);
    this.transferPaymentForm.updateValueAndValidity();
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  onTransfer(): void {
    this.triggerEvent({
      continue: true,
      receiptDate: this.postDate,
      suspendedDetail: this.getSuspendedPaymentDetail(),
      amount: this.transferPaymentForm.get('amountToTransfer').value,
      policyNumber: this.transferPaymentForm.get('policyNumber').value,
      reasonId: this.transferPaymentForm.get('suspenseReason').value,
      originalPolicyPaymentAmount: this.postedAmount,
      suspendedCheck: this.getCheckDetails(),
      comment: this.transferPaymentForm.get('comment').value,
      sourceId: this.getSourceId(this.paymentMethod),
    });
  }

  isCheck(): boolean {
    return this.paymentMethod === 'Check' || this.paymentMethod === 'EFT' || this.paymentMethod === 'Recurring E-check';
  }

  getSourceId(paymentMethod: string): string {
    switch(paymentMethod) {
      case 'Direct Bill':
        return 'DB';
      case 'IPX':
        return 'IPX';
      case 'Lock Box':
        return 'LB';
      default:
        return 'IN';
    }
  }

  onCancel(): void {
    this.triggerEvent({ continue: false });
  }

  triggerEvent(res: any) {
    this.event.emit({ data: res, res: 200 });
  }

  getSuspendedPaymentDetail(): SuspendedDetailDTO {
    const suspendedDetail: SuspendedDetailDTO = {
      transactionNum: this.referenceNum,
    };

    return Object.keys(this.suspendedPaymentDetail).length > 0 ? this.suspendedPaymentDetail : suspendedDetail;
  }

  getCheckDetails(): SuspendedCheckDTO {
    const isLockbox = this.getSourceId(this.paymentMethod) === 'LB';
    const amount = this.transferPaymentForm.get('amountToTransfer').value;

    let checkDetails: SuspendedCheckDTO;

    if (isLockbox) {
      checkDetails = {
        checkAmount: amount,
        checkNum: this.suspendedPaymentDetail?.checkNum,
        checkAccountNum: this.suspendedPaymentDetail?.accountNum,
        checkRoutingNum: this.suspendedPaymentDetail?.routingNum,
      };
    } else {
      checkDetails = {
        checkAmount: amount,
        checkNum: this.referenceNum
      };
    }

    return checkDetails;
  }

}