import { Injectable } from '@angular/core';
import { LvUWRenewalReviewModel } from './models/lv-model/lv.uwrenewalreview.model';

@Injectable({
  providedIn: 'root',
})

export class LvData {
  public LvUWRenewalReview: LvUWRenewalReviewModel[];

  constructor() { }
}
