export const SuspendedPaymentsLabel = {

  Pending: {
    header: 'Pending Suspended Payments',
    Table: {
      ReceiptDate: 'Bank Process Date',
      PolicyNumber: 'Policy/Quote Number',
      Payer: 'Payer',
      Amount: 'Policy Payment Amount',
      SuspenseReason: 'Suspense Reason',
      Comments: 'Comments',
      Details: 'Details',
      Post: 'Post',
      Return: 'Return',
      Void: 'Void',
      Add: 'Add',
      Source: 'Source',
      ForIPX: 'For IPX',
      Report: 'IPX Suspense Report',
      Upload: 'Upload IPX Suspense',
    },
    Add: {
      header: 'Add Suspended Payment',
      SectionHeader: 'Payment Details',
      ReceiptDate: 'Bank Process Date',
      PolicyNumber: 'Policy/Quote Number',
      Amount: 'Policy Payment Amount',
      Comments: 'Comments',
      SuspenseReason: 'Suspense Reason',

      TransactionNum: 'Transaction Number',
      LockboxNum: 'Lockbox number',
      Batch: 'Payment Batch',
      BatchItem: 'Batch Item',

      CheckAmount: 'Check Amount',
      CheckRoutingNum: 'Check Routing Number',
      CheckAccountNum: 'Check Account Number',
      CheckNum: 'Check Number',

      DirectBillCarrier: 'Direct Bill Carrier',

      PayerFirstName: 'Customer First Name',
      PayerMiddleName: 'Middle Name',
      PayerLastName: 'Last Name',
      Address1: 'Address 1',
      Address2: 'Address 2',
      City: 'City',
      State: 'State',
      ZipCode: 'Zip Code',

      Description: 'Description',
      UploadDate: 'Upload Date',
      UploadedBy: 'Uploaded By',
      View: 'View',
      Delete: 'Delete',
      Upload: 'Upload',
      Save: 'Save',
      Close: 'Close',
      RelatedDocuments: 'Related Documents'
    },
    Detail: {
      header: 'Suspended Payment Details',
      PaymentDetails: 'Original Payment Details',
      ReceiptDate: 'Bank Process Date',
      PolicyNumber: 'Policy Number',
      Amount: 'Policy Payment Amount',
      originalPolicyPaymentAmount: 'Original Policy Payment Amount',
      Payer: 'Payer',
      SuspenseReason: 'Suspense Reason',
      Status: 'Status',
      Comments: 'Comments',

      RelatedDocuments: 'Related Documents',
      Description: 'Description',
      UploadDate: 'Upload Date',
      UploadedBy: 'Uploaded By',
      View: 'View',
      Delete: 'Delete',
      Upload: 'Upload',

      ReturnDetails: 'Return Details',
      ReturnDate: 'Return Date',
      Payee: 'Payee',
      CheckNumber: 'Check Number',
      CheckDate: 'Check Date',
      ClearDate: 'Clear Date',
      EscheatDate: 'Escheat Date',
      Save: 'Save',
      Close: 'Close',
      updateSuccessMessage: 'Policy number changed successfully!',
      Address: 'Address'
    },
    Report: {
      header: 'IPX Suspense Report',
      draft: 'Draft Report',
      final: 'Final Report',
      generate: 'Generate',
      close: 'Close',
      isInIPXReport: 'Payment is already included in Final Report that will be sent at 2PM EST',
      finalReportExistMsg: 'Final report for today is already generated. The newly added payment(s) will be added to the report.',
      noResultsFound: 'No Results Found'
    }
  },

  returned: {
    header: 'Returned Suspended Payments',
    checkNotIssued: 'Check Not Issued',
    returnDate: 'Return Date',
    payee: 'Payee',
    amount: 'Policy Payment Amount',
    checkDate: 'Check Date',
    checkNumber: 'Check Number',
    clearDate: 'Clear Date',
    escheatDate: 'Escheat Date',
    details: 'Details',
    reverse: 'Reverse'
  },

  reverseReturnedModal: {
    header: 'Reverse Returned Suspended Payment',
    details: {
      header: 'Details',
      returnDate: 'Return Date',
      payee: 'Payee',
      amount: 'Policy Payment Amount',
      payer: 'Payer',
      suspenseReason: 'Suspense Reason',
      status: 'Status',
      comments: 'Comments',
      checkNum: 'Check Number',
    },
    reversal: {
      header: 'Reversal',
      comments: 'Reversal Comments'
    },
    reverse: 'Reverse',
    cancel: 'Cancel',
    reverseConfirmationMessage: 'Are you sure you want to reverse this payment?',
    reverseSuccessMessage: 'Suspended payment has been reversed'
  },

  detail: {
    receiptDate: 'Bank Process Date',
    policyNumber: 'Policy/Quote Number',
    policyPaymentAmount: 'Policy Payment Amount',
    originalPolicyPaymentAmount: 'Original Policy Payment Amount',
    suspenseReason: 'Suspense Reason',
    status: 'Status',
    comments: 'Comments',

    transactionNum: 'Transaction Number',
    lockboxNum: 'Lockbox number',
    batch: 'Payment Batch',
    batchItem: 'Batch Item',

    checkAmount: 'Check Amount',
    checkRoutingNum: 'Check Routing Number',
    checkAccountNum: 'Check Account Number',
    checkNum: 'Check Number',

    directBillCarrier: 'Direct Bill Carrier',

    payerFirstName: 'Customer First Name',
    payerMiddleName: 'Middle Name',
    payerLastName: 'Last Name',
    address1: 'Address 1',
    address2: 'Address 2',
    city: 'City',
    state: 'State',
    zipCode: 'Zip Code'
  },

  postSuspended: {
    header: 'Post Suspended Payment',
    details: {
      header: 'Details'
    },
    postTo: {
      header: 'Post To',
      search: 'Search',
      policyNumber: 'Policy Number',
      quoteNumber: 'Quote Number',
      insuredName: 'Insured Name',
      propertyAddress: 'Property Address',
      post: 'Post',
      cancel: 'Cancel'
    },
    postConfirmationMessage: 'Are you sure you want to post this payment?',
    postSuccessMessage: 'Payment has been posted',
    noPoliciesFoundMessage: 'No Policies Found',
    postAPISuccessPaymentNotPosted: 'Payment not posted. Policy Cancelled with no outstanding balance.'
  },

  searchResult: {
    header: 'Search Result',
    policyNumber: 'Policy Number',
    quoteNumber: 'Quote Number',
    insuredName: 'Insured Name',
    property: 'Property',
    select: 'Select',
    comments: 'Comments',
    effectiveDate: 'Effective Date'
  },

  returnPendingModal: {
    header: 'Return Suspended Payment',
    headerDetails: 'Details',
    returnToDetails: {
      header: 'Return To',
      name: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      suffix: 'Suffix',
      city: 'City',
      state: 'State',
      zipcode: 'Zip Code',
      comments: 'Return Comments',
      aptOrUnit: 'Apt/Unit #',
      mailingAddress: 'Mailing Address',
      fullName: '"First Name" + "Last Name" + "Suffix"'
    },
    return: 'Return',
    cancel: 'Cancel',
    returnConfirmationMessage: 'Are you sure you want to return this payment?',
    returnSuccessMessage: 'Suspended payment has been returned',
    zipNotFound: 'Zip code not found, contact Underwriting',
    isIndividualYesNo: 'Is this an individual?'
  },

  voidPendingModal: {
    header: 'Void Suspended Payment',
    headerDetails: 'Details',
    voidDetails: {
      header: 'Void',
      comments: 'Void Comments'
    },
    void: 'Void',
    cancel: 'Cancel',
    voidConfirmationMessage: 'Are you sure you want to void this payment?',
    voidSuccessMessage: 'Suspended payment has been voided'
  },

  relatedDocuments: {
    deleteConfirmation: 'Are you sure you want to delete this document?',
    user: 'User',
    system: 'System'
  },

  sentToIpx: {
    header: 'Suspended Payments Sent To IPX ',
    table: {
      sentToIPXDate: 'Sent To IPX Date',
      receiptDate: 'Bank Process Date',
      policyNumber: 'Policy/Quote Number',
      amount: 'Policy Payment Amount',
      suspenseReason: 'Suspense Reason',
      comments: 'Comments',
      details: 'Details',
      source: 'Source'
    },
  },

  splitPayment: {
    splitInto: 'Split Into',
    availableToAllocate: 'Available To Allocate',
    addSuspendedSplitPayment: 'Add Suspended Split Payment',
    splitSuspendedPayment: 'Split Suspended Payment',
    splitSuspendedPaymentDetails: 'Split Suspended Payment Details',
    save: 'Save',
    deleteConfirmMess: 'Are you sure you want to delete this split payment?',
    stDocDev: 'https://stdocdev.blob.core.windows.net/',
    stDocTest: 'https://stdoctest.blob.core.windows.net/',
    stDocStg: 'https://stdoctest.blob.core.windows.net/',
    stDocStgClone: 'https://stdocstagingclone.blob.core.windows.net/'
  }
};
