import { Component, EventEmitter, OnInit } from '@angular/core';
import { SuspendedPaymentsData } from '../../../../../../modules/payments/data/suspended-payments.data';
import { ErrorMessageConstant } from '../../../../../../shared/constants/error-message.constants';
import { SuspendedPaymentsLabel } from '../../../../../../shared/constants/suspended-payments.labels.constants';
import { SuspendedPaymentDetailsDTO } from '../../../../../../shared/models/data/dto/suspended-payment/suspended-payment-detail.dto';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { SearchResultModalComponent } from './search-result-modal/search-result-modal.component';
import { PolicyService } from '../../../../../../../app/core/services/submission/policy.service';
import { PolicySearchResultDTO } from '../../../../../../../app/shared/models/data/dto/suspended-payment/policy-list-dto';
import Utils from '../../../../../../../app/shared/utilities/utils';
import { BillingService } from '../../../../../../../app/core/services/billing/billing.service';
import { PostSuspendedPaymentDTO } from '../../../../../../../app/shared/models/data/dto/suspended-payment/post-suspended-payment.dto';
import NotifUtils from '../../../../../../../app/shared/utilities/notif-utils';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../../../../../app/shared/base-class';
import { PolicyBillingConstants } from '../../../../../../shared/constants/policy-billing.constants';
import { DatePipe } from '@angular/common';
import { IGetAgencyPolicyCommissionRateRequestDTO } from 'app/shared/models/management/agency-management/agency-details.response.dto';
import { Summary } from '@angular/compiler';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { AuthService } from 'app/core/services/auth.service';

@Component({
  selector: 'app-post-suspended',
  templateUrl: './post-suspended.component.html',
  styleUrls: ['./post-suspended.component.scss']
})
export class PostSuspendedComponent extends BaseClass implements OnInit {

  public event: EventEmitter<any> = new EventEmitter<any>();
  public ErrorMessageConstant = ErrorMessageConstant;
  public LabelConstants = SuspendedPaymentsLabel.postSuspended;

  paymentDetail: SuspendedPaymentDetailsDTO;
  policySearchResult: PolicySearchResultDTO;

  searchString: string;

  noMatchFound: boolean = false;

  searchModalRef: BsModalRef | null;

  constructor(public bsModalRef: BsModalRef,
    public suspendedPaymentsData: SuspendedPaymentsData,
    private modalService: BsModalService,
    private policyService: PolicyService,
    private billingService: BillingService,
    public summaryData: SummaryData,
    private authService: AuthService
  ) {

    super();
  }

  ngOnInit() {
    console.log(this.policySearchResult);
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  onPost(): void {
    NotifUtils.showConfirmMessage(SuspendedPaymentsLabel.postSuspended.postConfirmationMessage, () => {
        const postRequest: PostSuspendedPaymentDTO = {
          suspendedPaymentId: this.paymentDetail.id,
          riskId: this.policySearchResult.riskId,
          isManual: true
        };

        Utils.blockUI();
        this.billingService.postSuspendedPayment(postRequest).pipe(takeUntil(this.stop$)).subscribe(res => {
          if (res.isCommissionRateError) {
            NotifUtils.showError(ErrorMessageConstant.Commission.noCommissionRate, () => {});
            this.hideModal();
          } else {
            const successMessage: string = res?.statusId === PolicyBillingConstants.suspendedPayment.statusId.post ? this.LabelConstants.postSuccessMessage : this.LabelConstants.postAPISuccessPaymentNotPosted;
            const callback = () => NotifUtils.showSuccess(successMessage);
            this.hideModal();
            this.suspendedPaymentsData.getSuspendedPaymentList(callback);
          }

        },
          err => {
            Utils.unblockUI();
            this.refreshSuspendedList(err?.error?.message.includes(ErrorMessageConstant.suspendedPayments.paymentNotAllowedForCancel));
            NotifUtils.showMultiLineError(err.error?.message);
          });
    });
  }

  onSearch(): void {
    Utils.blockUI();
    this.policyService.searchPolicies(this.searchString).pipe(takeUntil(this.stop$)).subscribe(result => {
      const isSiebelDataWithDuplicatePolicyNumber = result.length > 1 && result[0].isSiebel;
      const actOrPexpSiebelDataWithDuplicatePolicyNumber = result.filter(x => x.riskStatusCode === 'ACT' || x.riskStatusCode === 'PEXP');
      const latestActOrPexpSiebelDataWithDuplicatePolicyNumber = actOrPexpSiebelDataWithDuplicatePolicyNumber.sort((a, b) => new Date(b.firstIssueDate).getTime() - new Date(a.firstIssueDate).getTime())[0];



      Utils.unblockUI();

      if (result.length === 0) {
        this.noMatchFound = true;
        this.policySearchResult = null;
      } else if (result.length === 1 && result[0].isExactSearch) {
        this.noMatchFound = false;
        this.policySearchResult = result[0];
      } else if (isSiebelDataWithDuplicatePolicyNumber) {
        this.noMatchFound = false;
        this.policySearchResult = latestActOrPexpSiebelDataWithDuplicatePolicyNumber;
      } else {
        this.noMatchFound = false;
        this.showSearchResultModal(result);
      }
    },
      err => {
        Utils.unblockUI();
        NotifUtils.showError(err.error?.message);
      });
  }

  private showSearchResultModal(result: PolicySearchResultDTO[]): void {
    const initialState = {
      isReversal: true,
      resultList: result
    };

    this.searchModalRef = this.modalService.show(SearchResultModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-xl modal-dialog-centered',
    });

    this.searchModalRef.content.event.subscribe((res) => {
      if (res) {
        this.noMatchFound = false;
        this.policySearchResult = res;
      }
      this.searchModalRef.hide();
    });
  }

  private refreshSuspendedList(isCancelled: boolean): void {
    if(isCancelled) {
      this.suspendedPaymentsData.getSuspendedPaymentList();
    }
  }
}
