import { CommissionPaymentDTO } from 'app/shared/models/data/dto/nacha/commission-payment.dto';
import { TablePaginationState } from 'app/shared/models/table.pagination.state';

export interface CommissionPaymentState {
  isLoading: boolean | null;
  commissionPaymentList: CommissionPaymentDTO[] | null;
  filteredCommissionPaymentList: CommissionPaymentDTO[] | null;
};

export const initialState = {
  isLoading: null,
  commissionPaymentList: [],
  filteredCommissionPaymentList: [],
};