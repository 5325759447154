import { Pipe, PipeTransform } from '@angular/core';
import { TransferStatusConstants } from 'app/shared/constants/book-transfer.constants';

@Pipe({
  name: 'disableRemoveAllCheckbox'
})
export class DisableRemoveAllCheckboxPipe implements PipeTransform {
  transform(forTransferPolicies: any, isForTransferSearch: boolean): boolean {
    const hasNotQueuedStatusCode = forTransferPolicies.some(policy => {
      return policy.statusCode !== this.isQueued && policy.statusCode !== null;
    });

    return forTransferPolicies.length === 0 || hasNotQueuedStatusCode || isForTransferSearch;
  }

  get isQueued(): any {
    return TransferStatusConstants.queued;
  }
}
