import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { CommissionStatementComponent } from './commission-statement.component';
import { CurrentCommissionStatementComponent } from './current-commission-statement/current-commission-statement.component';
import { ArchievedCommissionStatementComponent } from './archieved-commission-statement/archieved-commission-statement.component';
import { BsDatepickerModule, TimepickerModule } from 'ngx-bootstrap';



@NgModule({
  declarations: [
    CommissionStatementComponent,
    CurrentCommissionStatementComponent,
    ArchievedCommissionStatementComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
  ]
})
export class CommissionStatementModule { }
