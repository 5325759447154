import { createAction, props } from '@ngrx/store';

export const updateAppIsLoadingFromAuthService = createAction(
  '[AuthService] Update App Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateAppIsLoadingFromPolicyNavSavingService = createAction(
  '[PolicyNavSavingService] Update App Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateAppIsLoadingFromSubmissionNavSavingService = createAction(
  '[SubmissionNavSavingService] Update App Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateAppIsLoadingFromComparativeRaterData = createAction(
  '[ComparativeRaterData] Update App Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateAppIsLoadingFromLoginComponent = createAction(
  '[LoginComponent] Update App Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateAppIsLoadingFromGroupDialogComponent = createAction(
  '[GroupDialogComponent] Update App Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateAppIsLoadingFromPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update App Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateAppIsLoadingFromPolicyIssueComponent = createAction(
  '[PolicyIssueComponent] Update App Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateAppIsLoadingFromSubmissionManagementComponent = createAction(
  '[SubmissionManagementComponent] Update App Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateAppIsLoadingFromSubmissionListData = createAction(
  '[SubmissionListData] Update App Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateAppIsLoadingFromUwApprovalSavingData = createAction(
  '[UwApprovalSavingData] Update App Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateAppIsLoadingFromZipCodeData = createAction(
  '[ZipCodeData] Update App Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateAppIsLoadingFromApplicantPageComponent = createAction(
  '[ApplicantPageComponent] Update App Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateAppIsLoadingFromBindAndIssueComponent = createAction(
  '[BindAndIssueComponent] Update App Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateAppIsLoadingFromIssuePolicyComponent = createAction(
  '[IssuePolicyComponent] Update App Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateAppIsLoadingFromPolicyIssueData = createAction(
  '[PolicyIssueData] Update App Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateAppIsConfirmationFromPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update App Is Confirmation',
  props<{ isConfirmation: boolean }>()
);

export const updateAppIsConfirmationFromPolicyRewriteComponent = createAction(
  '[PolicyRewriteComponent] Update App Is Confirmation',
  props<{ isConfirmation: boolean }>()
);

export const updateAppIsConfirmationFromZipCodeData = createAction(
  '[ZipCodeData] Update App Is Confirmation',
  props<{ isConfirmation: boolean }>()
);

export const updateAppIsConfirmationFromLocationDetailsComponent = createAction(
  '[LocationDetailsComponent] Update App Is Confirmation',
  props<{ isConfirmation: boolean }>()
);

export const updateAppIsConfirmationFromPolicyIssueData = createAction(
  '[PolicyIssueData] Update App Is Confirmation',
  props<{ isConfirmation: boolean }>()
);

export const updateAppRuwIsLoadingFromUwApprovalSavingData = createAction(
  '[UwApprovalSavingData] Update App RUW Is Loading',
  props<{ ruwIsLoading: boolean }>()
);

export const updateAppBrulDatesFromLogin = createAction(
  '[Login] Update App Brul Dates',
  props<{ brulDates: any }>()
);

export const updateAppBrulDatesFromDashboard = createAction(
  '[Dashboard] Update App Brul Dates',
  props<{ brulDates: any }>()
);

export const updateAppBrulDatesFromComparativeRaterData = createAction(
  '[ComparativeRaterData] Update App Brul Dates',
  props<{ brulDates: any }>()
);

export const updateAppBrulDatesFromEndorsementComponent = createAction(
  '[EndorsementComponent] Update App Brul Dates',
  props<{ brulDates: any }>()
);