import { Component, OnInit } from '@angular/core';
import { CommissionStatementDTO } from 'app/shared/models/data/dto/nacha/commission-statement.dto';
import { CommissionStatementData } from '../../data/commission-statement.data';
import { CommissionStatementConstant } from 'app/shared/constants/commission-statement.constants';
import { AuthService } from 'app/core/services/auth.service';

@Component({
  selector: 'app-current-commission-statement',
  templateUrl: './current-commission-statement.component.html',
  styleUrls: ['./current-commission-statement.component.scss']
})
export class CurrentCommissionStatementComponent implements OnInit {

  CommissionStatementConstant = CommissionStatementConstant;

  get isExternal(): boolean {
    return this.authService.isExternalUser;
  }

  constructor(
    public commissionStatementData: CommissionStatementData,
    public authService: AuthService
  ) { }


  ngOnInit() {
    this.emptyAgency();
  }

  emptyAgency(): boolean {
    if (!this.isExternal) {
      const agency =  this.commissionStatementData.agencyForm.get('agency')?.value ?? '';
      const status =  this.commissionStatementData.selectedAgencyId === null || agency === '';

      if (status) {
        this.commissionStatementData.agencyFirst12CommissionList = [];
      }
      return status;
    }
    return false;
  }

  checkAgencyListCount(): boolean {
    return this.commissionStatementData.agencyFirst12CommissionList.length === 0;
  }

  selectItem(data: CommissionStatementDTO): void {
    const index = this.commissionStatementData.agencyFirst12CommissionList.indexOf(data);
    data.isChecked = !data.isChecked;
    this.commissionStatementData.agencyFirst12CommissionList[index] = data;
  }

  selectAllItem(stat: boolean): void {
    this.commissionStatementData.agencyFirst12CommissionList.forEach(element => {
      element.isChecked = stat;
    });
  }

  checkIfAllSelected(): boolean {
    if (this.commissionStatementData?.agencyFirst12CommissionList?.length !== 0) {
      return this.commissionStatementData?.agencyFirst12CommissionList?.filter(a => !a.isChecked).length === 0;
    }
    return false;
  }
}
