export class UploadCheckDTO implements IUploadCheckDTO {
    checkNumber?: string;
    imageURL?: string;

    public constructor(init?: Partial<UploadCheckDTO>) {
        Object.assign(this, init);
    }
}

export interface IUploadCheckDTO {
    checkNumber?: string;
    imageURL?: string;
}
