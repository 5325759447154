import { Action, createReducer, on } from '@ngrx/store';
import * as fromDashboardActions from 'app/store/dashboard/dashboard.actions';
import { initialState, DashboardState } from './dashboard.state';

const _dashboardReducer = createReducer(
  initialState,
  on(
    fromDashboardActions.updateProgramStateAgentDetailIsLoadingFromAuthService,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromDashboardActions.updateProgramStateAgentDetailStateAssignmentFromAuthService,
    (state, { stateList }) => ({ ...state, stateList: stateList })
  )
);

export function dashboardReducer(
  state: DashboardState | null,
  action: Action
) {
  return _dashboardReducer(state, action);
}
