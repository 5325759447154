import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CommonService } from './common.service';
import { IRequestObjectMoratorium, ResponseObjectMoratorium } from '../../shared/models/submission/quick-quote/moratoriums.model';

@Injectable({
  providedIn: 'root'
})
export class MoratoriumService {
  private baseUrl: string;
  public _responseObject: Observable<ResponseObjectMoratorium[]>;

  constructor(private http: HttpClient,
    private commonService: CommonService) {
    this.baseUrl = environment.ApiUrl;
  }

  public getMoratoriums(requestObject: IRequestObjectMoratorium): Observable<ResponseObjectMoratorium[]> {
    const url = `${this.baseUrl}/Moratorium/moratorium-checking`;
    this._responseObject = this.http.post<ResponseObjectMoratorium[]>(url,requestObject).pipe().catch(this.commonService.handleObservableHttpError);
    return this._responseObject;
  }

  public getAllMoratoriums(): Observable<ResponseObjectMoratorium[]> {
    return this.http.get(`${this.baseUrl}/Moratorium/get-all-moratorium`)
      .catch(this.commonService.handleObservableHttpError);
  }
}
