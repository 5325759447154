export class Iso360PropertyDTO implements IIso360PropertyDTO {
    riskId?: string | undefined;
    yearBuilt?: number | undefined;
    squareFootage: string | undefined;
    latitude: string | undefined;
    longitude: string | undefined;
    constructionCode: string | undefined;
    exteriorWallCode: string | undefined;
    structureType: string | undefined;
    roofShape: string | undefined;
    foundationType: string | undefined;
    numberOfStory: string | undefined;
    roofMaterialCode: string | undefined;
    replacementCost: string | undefined;
    replacementCostDate?: Date | undefined;
    valuationId: string | undefined;
    costPerSqft: string | undefined;
    coverageA: number | undefined;
    construction360Code: string | undefined;
    exteriorWallFinish360Code: string | undefined;
    foundationType360Code: string | undefined;
    roofMaterial360Code: string | undefined;
    coverageB: string | undefined;
    coverageC: string | undefined;
    coverageD: string | undefined;
    coverageE: string | undefined;

    public constructor(init?: Partial<Iso360PropertyDTO>) {
        Object.assign(this, init);
    }
}

export interface IIso360PropertyDTO {
    riskId?: string | undefined;
    yearBuilt?: number | undefined;
    squareFootage: string | undefined;
    latitude: string | undefined;
    longitude: string | undefined;
    constructionCode: string | undefined;
    exteriorWallCode: string | undefined;
    structureType: string | undefined;
    roofShape: string | undefined;
    foundationType: string | undefined;
    numberOfStory: string | undefined;
    roofMaterialCode: string | undefined;
    replacementCost: string | undefined;
    replacementCostDate?: Date | undefined;
    valuationId: string | undefined;
    costPerSqft: string | undefined;
    coverageA: number | undefined;
    construction360Code: string | undefined;
    exteriorWallFinish360Code: string | undefined;
    foundationType360Code: string | undefined;
    roofMaterial360Code: string | undefined;
    coverageB: string | undefined;
    coverageC: string | undefined;
    coverageD: string | undefined;
    coverageE: string | undefined;
}
