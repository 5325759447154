import { createSelector } from '@ngrx/store';
import { BaseState } from '../base.state';

export const selectBackupState = (state: BaseState) => state.backup;

export const selectBackupIsLoading = createSelector(
  selectBackupState,
  (state) => state.isLoading
)

export const selectBackupData = createSelector(
  selectBackupState,
  (state) => state.data
)