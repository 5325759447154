import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { RiskScheduleDTO } from '../../../../shared/models/data/dto/quick-quote/risk-schedule.dto';
import { UwApprovalData } from '../../../../modules/submission-management/data/uw-approval.data';
import { LvUWApprovals } from '../../../../shared/constants/uw.referrals';
import { ITasksRequestDTO, TaskDTO, TaskNotesDTO, TaskPostRequestDTO } from '../../../../shared/models/data/dto/task/task.dto';
import { EntityRiskData } from '../../../../modules/submission-management/data/entity-risk.data';
import { LvOwner, LvTaskCategory, LvTaskContactType, LvTaskDescription, LvTaskStatus, LvTaskSubType, LvTaskType } from '../../../../shared/constants/task.options.constants';
import { TaskService } from '../../task/task.service';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../../shared/base-class';
import * as moment from 'moment';
import { LvUWApprovalModel } from '../../../../shared/models/lv-model/lv.uwApproval.model';
import { PolicyIssueData } from '../../../../modules/policy-management/data/policy-issue.data';
import { PolicySummaryData } from '../../../../modules/policy-management/data/policy-summary.data';
import NotifUtils from '../../../../shared/utilities/notif-utils';
import { PropertyData } from '../../../../modules/submission-management/data/property.data';
import { AuthService } from '../../auth.service';
import { FormTypeConstants } from '../../../../shared/constants/form-types.constants';
import { AgentService } from '../../management/agent-service';
import { SubAgenciesResponseDTO } from 'app/shared/models/data/dto/agent/subagencies-response.dto';
import { TaskData } from '../../../../modules/dashboard/data/task.data';
import { CoveragesData } from '../../../../modules/submission-management/data/coverages.data';

@Injectable({
  providedIn: 'root'
})
export class TaskGenerationService extends BaseClass {
  protected approvalsLV = LvUWApprovals;
  protected uwa: Array<string> = [];
  protected stateCodeFL = 'FL';
  protected currentDate: Date = this.auth.getCustomDate();
  protected dateFormat: string = 'MM/DD/yyyy';
  protected policyNumber: string = '';
  protected taskListRequest: TaskPostRequestDTO[] = [];
  formTypeConstants = FormTypeConstants;
  protected excludeTask: number[] = [2];

  protected isAddNote: boolean = false;
  protected typeId: number = 5;
  protected subTypeId: number = 6;
  protected statusId: number = 1;
  protected contactId: number = 6;
  protected categoryId: number = 5;
  protected entityId: string;

  constructor(
    protected uwApprovalData: UwApprovalData,
    protected entityData: EntityRiskData,
    protected taskService: TaskService,
    protected policyIssueData: PolicyIssueData,
    protected policySummaryData: PolicySummaryData,
    public entityRiskData: EntityRiskData,
    protected propertyData: PropertyData,
    protected auth: AuthService,
    protected taskData: TaskData,
    protected agentService: AgentService,
    protected coveragesData: CoveragesData
  ) {
    super();
  }

  triggerTaskMethods(issued: any, renewalSubmissionUWR?: string[], entityId?: string): void {
    this.taskListRequest = [];
    this.uwa = renewalSubmissionUWR ? renewalSubmissionUWR : this.uwApprovalData.uwApprovalList;
    this.policyNumber = issued?.policyNumber;
    this.entityId = renewalSubmissionUWR ? entityId : this.entityData.getEntityId();

    const numberOfTasks = 15;
    let counter = 1;

    if (issued) {
      for (counter; counter <= numberOfTasks; counter++) {
        if (!this.excludeTask.includes(counter)) {
          try {
            if (typeof this[`task${counter}`] === 'function') {
              this['task' + counter]();
            }
          } catch (e) {
            NotifUtils.showError(e);
          }
        }
      }
      this.postTask(this.taskListRequest);
    }
  }

  protected get effectiveDate(): string {
    return new Date(this.entityData.EntityRisk?.risks[0]?.riskDetails[0].effectiveDate).toLocaleDateString('en-US');
  }

  protected get policyChangeEffectiveDate(): string {
    return this.policyIssueData.issuePolicyForm.get('changeEffectiveDate').value?.singleDate?.jsDate?.toLocaleDateString('en-US');
  }

  protected isInRange(list: Array<RiskScheduleDTO>, min: number, max: number): boolean {
    return list.filter(sched => _.inRange(sched.total, min, max)).length > 0;
  }

  protected isUWRTriggered(code: string): boolean {
    return this.uwa.includes(_.find(this.approvalsLV, ['code', code]).description);
  }

  protected generateDueDate(days: number, isEffectiveDate: boolean = true): string {
    const dateNow = moment(this.auth.getCustomDate()).format(this.dateFormat);
    const date = isEffectiveDate ? this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId()) ? this.policyChangeEffectiveDate : this.effectiveDate : dateNow;
    const dueDate = moment(new Date(date)).add(days, 'days');
    return String(dueDate.format(this.dateFormat));
  }

  protected addNotes(taskDescriptionId: number): TaskNotesDTO {
    let notes: TaskNotesDTO;
    const contact: any = _.find(LvTaskContactType, ['id', 6]);
    const noteDescription: string[] = [];

    switch (taskDescriptionId) {
      case 42:
        const includedUWR = ['PBR-EXTWALLFINISH', 'PBR-FOUNDTYPECHND', 'PBR-ROOFMATCHAND', 'PBR-ROOFSHAPECHAND',
          'PBR-SQFOOTCHND', 'PBR-YRBUILTCHND', 'PBR-LLCTRUST'];

        includedUWR.forEach(uwr => {
          const uwApproval = _.find(this.approvalsLV, ['code', uwr]);
          if (this.uwa.includes(uwApproval.description)) {
            noteDescription.push(uwApproval.description);
          }
        });
        notes = this.mappedTaskNotes(contact, noteDescription.join(', '));
        break;
      default:
        break;
    }

    return notes;
  }

  /**
   * @param ownerId - Code based on LvOwner
   * @param taskDescriptionId - Code based on LvTaskDescription
   * @param dueDate - Task due date, 7 or 14 days from effective date.
   * @param typeId - Code based on LvTaskType. Default: System
   * @param subTypeId - Code based on LvTaskSubType. Default: System
   * @param statusId - Code based on LvTaskStatus. Default: Open
   * @param contactId -Code based on LvTaskContactType. Default: System
   * @param categoryId - Code based on LvTaskCategory. Default: System
   * @param stateCode - FL for the current implementation
   * @returns payload in posting task
   */
  protected mappedTaskRequest(
    ownerId: number,
    taskDescriptionId: number,
    dueDate: string,
    isAddNote: boolean = false,
    typeId: number = 5,
    subTypeId: number = 6,
    statusId: number = 1,
    contactId: number = 6,
    categoryId: number = 5,
    stateCode: string = this.stateCodeFL,
    taskOwnerUserId: number = null): void {

    const contact: any = _.find(LvTaskContactType, ['id', contactId]);
    const description: any = _.find(LvTaskDescription, ['id', taskDescriptionId]);

    const mappedTask: TaskDTO = {
      policyNumber: this.policyNumber,
      entityId: this.entityId,
      quoteNumber: this.entityData.getQuoteNumber(),
      typeCode: _.find(LvTaskType, ['id', typeId]).code,
      taskSubTypeCode: _.find(LvTaskSubType, ['id', subTypeId]).code,
      taskContactTypeCode: contact.code,
      taskCategoryCode: _.find(LvTaskCategory, ['id', categoryId]).code,
      taskDescriptionCode: description.code,
      taskOwnerCode: _.find(LvOwner, ['id', ownerId]).code,
      taskStatusCode: _.find(LvTaskStatus, ['id', statusId]).code,
      contactFullName: contact.description,
      dueDate: dueDate,
      taskNotes: [this.mappedTaskNotes(contact, description.description)],
      taskDocuments: [],
      stateCode: stateCode,
      taskOwnerUserId: taskOwnerUserId
    };

    const request: TaskPostRequestDTO = {
      task: mappedTask
    };

    if (isAddNote) {
      const note = this.addNotes(taskDescriptionId);
      request.task.taskNotes.push(note);
    }

    this.taskListRequest.push(request);
  }

  protected mappedTaskNotes(contact: LvUWApprovalModel, description: string): TaskNotesDTO {
    const note: TaskNotesDTO = {
      description: description,
      taskContactTypeCode: contact.code,
      contactFullName: contact.description,
      createdBy: 0,
      createdDate: this.currentDate
    };

    return note;
  }

  protected postTask(payload: TaskPostRequestDTO[]): void {
    if (payload.length > 0) {
      this.taskService.postTaskSystem(payload).pipe(takeUntil(this.stop$)).subscribe(result => {
        this.taskListRequest = [];
      }, (err) => {
        NotifUtils.showError(JSON.stringify(err));
      });
    }
  }

  protected task1(): void {
    // Task1 & Task1a trigger & Condition
    // Item scheduled for an amount $5,000-$9999 and UWR-82 has triggered
    if (this.isUWRTriggered('PBR-PERPROPERTYUP5K')) {
      // ownerId = 4 ; Policy Agent
      // taskDescriptionId = 23 ; Scheduled Pers Property Appraisal
      this.mappedTaskRequest(4, 23, this.generateDueDate(7));
      this.subTask1();
    }
  }

  protected subTask1(): void {
    // ownerId = 6 ; UW Assistant Group
    // taskDescriptionId = 24 ; Scheduled Pers Property Appraisal Rev
    this.mappedTaskRequest(6, 24, this.generateDueDate(14));
  }

  public task2(): void {
    // Task2 & Task2a trigger & Condition
    // 'Mitigation Form' field not equal to 'no form' and UWR-118 has triggered

    const isPolicy = this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId());
    const hasWindMitForm = this.propertyData.windMitigation.get('mitigationForm').value !== 'NF';
    const hasWindMitChanges = isPolicy ? this.checkWindMitChanges() : false;
    let isWMF: boolean = false, isWMFR: boolean = false;
    this.taskListRequest = [];

    if (isPolicy && hasWindMitForm) {
      if (hasWindMitChanges) {
        const payload: ITasksRequestDTO = {
          entityId: this.entityRiskData.getEntityId(),
          taskDescriptionCodes: ['WMF', 'WMFR'],
          taskStatusCode: 'OP'
        };

        this.taskService.getTasks(payload).pipe(takeUntil(this.stop$)).subscribe(result => {
          if (result) {
            result.forEach(task => {
              if (task.taskDescriptionCode === 'WMF') {
                isWMF = true;
              }
              if (task.taskDescriptionCode === 'WMFR') {
                isWMFR = true;
              }
            });

            // ownerId = 4 ; Policy Agent
            // taskDescriptionId = 25 ; Wind Mitigation Form
            if (!isWMF) {
              this.mappedTaskRequest(4, 25, this.generateDueDate(7));
            }
            if (!isWMFR) {
              this.subTask2();
            }
            this.postTask(this.taskListRequest);
          }
        }, (err) => {
          NotifUtils.showError(JSON.stringify(err));
        });
      }
    } else if (hasWindMitForm) {
      this.mappedTaskRequest(4, 25, this.generateDueDate(7));
      this.subTask2();
      this.postTask(this.taskListRequest);
    }
  }

  protected subTask2(): void {
    // ownerId = 6 ; UW Assistant Group
    // taskDescriptionId = 26 ; Wind Mitigation Form Rev
    this.mappedTaskRequest(6, 26, this.generateDueDate(14));
  }

  protected task3(): void {
    // Task3 & Task3a trigger & Condition
    // > Year of construction is > 40 years old and UWR-119 has triggered
    if (this.isUWRTriggered('PBR-PROOFRENOV')) {
      // ownerId = 4 ; Policy Agent
      // taskDescriptionId = 27 ; Proof of Renovations (Roof, elec, plumb, heat)
      this.mappedTaskRequest(4, 27, this.generateDueDate(7));
      this.subTask3();
    }
  }

  protected subTask3(): void {
    // ownerId = 6 ; UW Assistant Group
    // taskDescriptionId = 28 ; Proof of Renovations Rev
    this.mappedTaskRequest(6, 28, this.generateDueDate(14));
  }

  protected task4(): void {
    // Task4 & Task4a trigger & Condition
    // > Sprinkler discount is either 'full' or 'partial' and UWR-120 has triggered
    if (this.isUWRTriggered('PBR-PROOFSPRINK')) {
      // ownerId = 4 ; Policy Agent
      // taskDescriptionId = 29 ; Proof of Sprinklers
      this.mappedTaskRequest(4, 29, this.generateDueDate(7));
      this.subTask4();
    }
  }

  protected subTask4(): void {
    // ownerId = 6 ; UW Assistant Group
    // taskDescriptionId = 30 ; Proof of Sprinklers Rev
    this.mappedTaskRequest(6, 30, this.generateDueDate(14));
  }

  protected task5(): void {
    // Task5 & Task5a trigger & Condition
    // > Hurricane deductible = excluded and UWR-121 has triggered
    if (this.isUWRTriggered('PBR-WINDHAILEX')) {
      // ownerId = 4 ; Policy Agent
      // taskDescriptionId = 31 ; Wind Pol or Wind/Hail Exclusion Form
      this.mappedTaskRequest(4, 31, this.generateDueDate(7));
      this.subTask5();
    }
  }

  protected subTask5(): void {
    // ownerId = 6 ; UW Assistant Group
    // taskDescriptionId = 32 ; Wind Pol or Wind/Hail Exclusion Form Rev
    this.mappedTaskRequest(6, 32, this.generateDueDate(14));
  }

  protected task6(): void {
    // Task6 & Task6a trigger & Condition
    // > Coverage C - Personal Property Limit = excluded and UWR-122 has triggered
    if (this.isUWRTriggered('PBR-CONTNTREJ') && this.entityRiskData.getFormType() === this.formTypeConstants.HO3) {
      // ownerId = 4 ; Policy Agent
      // taskDescriptionId = 33 ; Contents Rejection Form
      this.mappedTaskRequest(4, 33, this.generateDueDate(7));
      this.subTask6();
    }
  }

  protected subTask6(): void {
    // ownerId = 6 ; UW Assistant Group
    // taskDescriptionId = 34 ; Contents Rejection Form Rev
    this.mappedTaskRequest(6, 34, this.generateDueDate(14));
  }

  protected task7(): void {
    // Task7 & Task7a trigger & Condition
    // > Ordinance or Law Coverage = reject increased amount and UWR-77 has triggered
    if (this.isUWRTriggered('PBR-CHSFLOLN')) {
      // ownerId = 4 ; Policy Agent
      // taskDescriptionId = 35 ; Ordinance or Law Notice
      this.mappedTaskRequest(4, 35, this.generateDueDate(7));
      this.subTask7();
    }
  }

  protected subTask7(): void {
    // ownerId = 6 ; UW Assistant Group
    // taskDescriptionId = 36 ; Ordinance or Law Notice Rev
    this.mappedTaskRequest(6, 36, this.generateDueDate(14));
  }

  taskRUW(isPolicy: boolean): void {
    // Task 8 & Task 9
    // ownerId = 5 ; Underwriter
    // taskDescriptionId = 37 ; New Business UW Referral
    // taskDescriptionId = 38 ; Midterm UW Referral

    const subAgencyId = this.entityRiskData.getRiskSubAgencyId();
    this.entityId = this.entityData.getEntityId();
    if (subAgencyId) {

      this.agentService.getSubAgenciesById(subAgencyId).subscribe((res: SubAgenciesResponseDTO) => {
        if (res) {
          if (isPolicy) {
            this.mappedTaskRequest(5, 38, this.generateDueDate(1, false), this.isAddNote, this.typeId, this.subTypeId, this.statusId, this.contactId, this.categoryId, this.stateCodeFL, res?.uwUserId);
          } else {
            this.mappedTaskRequest(5, 37, this.generateDueDate(1, false), this.isAddNote, this.typeId, this.subTypeId, this.statusId, this.contactId, this.categoryId, this.stateCodeFL, res?.uwUserId);
          }
          this.postTask(this.taskListRequest);
        } else {
            const uwUserId = this.entityRiskData.getRiskUnderWriterId();

            if (isPolicy) {
              this.mappedTaskRequest(5, 38, this.generateDueDate(1, false), this.isAddNote, this.typeId, this.subTypeId, this.statusId, this.contactId, this.categoryId, this.stateCodeFL, uwUserId);
            } else {
              this.mappedTaskRequest(5, 37, this.generateDueDate(1, false), this.isAddNote, this.typeId, this.subTypeId, this.statusId, this.contactId, this.categoryId, this.stateCodeFL, uwUserId);
            }
            this.postTask(this.taskListRequest);
        }
      }, err => {
        NotifUtils.showError(JSON.stringify(err.error));
      });

    }
  }

  protected task14(): void {
    // > UWR-78, and/or UWR-79, and/or UWR-80, and/or UWR-81, and/or UWR-83, and/or UWR-84, and/or UWR-85 has triggered
    if (this.isUWRTriggered('PBR-EXTWALLFINISH') || this.isUWRTriggered('PBR-FOUNDTYPECHND') || this.isUWRTriggered('PBR-ROOFMATCHAND')
      || this.isUWRTriggered('PBR-ROOFSHAPECHAND') || this.isUWRTriggered('PBR-SQFOOTCHND') || this.isUWRTriggered('PBR-YRBUILTCHND')
      || this.isUWRTriggered('PBR-LLCTRUST')) {
      // ownerId = 6 ; UW Assistant Group
      // taskDescriptionId = 42 ; Post Bind Referral
      this.mappedTaskRequest(6, 42, this.generateDueDate(7), true);
    }
  }

  protected checkWindMitChanges(): boolean {
    const windMitFields: string[] = [
      'Mitigation Form',
      'Date Of Inspection',
      'International Residential Building Code',
      'Rater Roof Cover',
      'Roof Deck Attachement',
      'Roof Wall Attachement',
      'Secondary Water Resistance',
      'Rater Opening Protection',
      'Qualified Inspector Type',
      'Inspector License Number',
      'Terrain',
      'Wind Speed',
      'Rater Wind Design Speed',
      'Internal Pressure Design',
      'Wind Borne Debris Region'
    ];

    let hasWindMitChanges: boolean = false;
    if (this.policyIssueData.policyChangesList.length !== null ||this.policyIssueData.policyChangesList.length > 0) {
      this.policyIssueData.policyChangesList.forEach(changes => {
        if (!hasWindMitChanges) {
          windMitFields.forEach(field => {
            hasWindMitChanges = !hasWindMitChanges ? changes.includes(field) : hasWindMitChanges;
          });
        }
      });
    }
    return hasWindMitChanges;
  }

  protected task15(): void {
    // Task15 & Task15a trigger & Condition
    // New business policy issued or renewal term generated if companion flood discount is present
    const isPolicy = this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId());
    const isCompanion = this.coveragesData.discountsForm?.get('companion').value === true || this.coveragesData.discountsForm?.get('companion').value === 'true' ?
      true : false;
    const isNewBusiness = this.entityRiskData.getRisk()?.isFromRenewal === false && this.entityRiskData.getRisk()?.isFromRewrite === false;
    if (isNewBusiness && !isPolicy && isCompanion) {
      // ownerId = 3 ; Underwriting Group
      // taskDescriptionId = 52 ; Flood Discount Validation
      this.mappedTaskRequest(3, 52, this.generateDueDate(14));
    }
  }
}
