import { Injectable } from '@angular/core';
import {
  LvUWApprovalCodeConstants,
  LvUWApprovals,
} from 'app/shared/constants/uw.referrals';
import { BaseClass } from 'app/shared/base-class';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { UwApprovalData } from 'app/modules/submission-management/data/uw-approval.data';
import { ApplicantData } from 'app/modules/submission-management/data/applicant.data';
import * as moment from 'moment';
import { ITableTr } from 'app/shared/models/dynamic/table.interface';
import * as _ from 'lodash';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { RiskInterestDetailDTO2 } from 'app/shared/models/data/dto/quick-quote/risk-interest-detail.dto';
import { EndorsementsData } from 'app/modules/submission-management/data/endorsements.data';
import { LvUWApprovalCodeConstantsRevised, LvUWApprovalDescriptionsRevised } from 'app/shared/constants/uw-referrals-revised';

interface IAsyncUWR {
  id: number;
  status: boolean;
}

interface formType {
  ho3: string;
  ho4: string;
  ho6: string;
  dp3: string;
}

@Injectable({
  providedIn: 'root',
})
export class UWRHelpers extends BaseClass {
  lvUWApprovals = LvUWApprovals;
  lvUWApprovalCodeConstants = LvUWApprovalCodeConstants;
  lvUWApprovalDescriptionsRevised = LvUWApprovalDescriptionsRevised;
  lvUWApprovalCodeConstantsRevised = LvUWApprovalCodeConstantsRevised;
  saveCoverages: boolean = true;

  formType: formType = {
    ho3: 'HO3',
    ho4: 'HO4',
    ho6: 'HO6',
    dp3: 'DP3'
  };

  getAsyncUWRs$ = new Subject();

  asyncUWRs: IAsyncUWR[] = [
    { id: 6, status: false },
    { id: 15, status: false },
    { id: 19, status: false },
    { id: 20, status: false },
    { id: 28, status: false },
    { id: 60, status: false },
    { id: 82, status: false },
    { id: 84, status: false },
    { id: 85, status: false },
    { id: 97, status: false },
    { id: 99, status: false },
    { id: 104, status: false },
    { id: 128, status: false },
    { id: 129, status: false },
    { id: 131, status: false }
  ];

  uwrLastCount: number = 189;

  revisedUwrs: number[] = [118,20];

  constructor(
    protected summaryData: SummaryData,
    protected uwApprovalData: UwApprovalData,
    protected applicantData: ApplicantData,
    protected entityRiskData: EntityRiskData,
    public policySummaryData: PolicySummaryData,
    protected endorsementsData: EndorsementsData
  ) {
    super();
  }

  getUwrCode(uwrNo: number) {
    return `uwr${uwrNo.toString()}`;
  }

  getMorCode(morNo: number): string {
    return `mor${morNo.toString()}`;
  }

  getUwrDescription(code: string): string {
    const data =
      this.lvUWApprovals.find((a) => a.code === code)?.description || 'Error';
    return data;
  }

  updateUwrList(condition: boolean, uwr: string, customMessage?: string, isMoratoriumUWR: boolean = false): void {
    const form = this.summaryData.SummaryForm.get('formType')?.value;
    const uwrNumber: number = Number(uwr.replace('uwr', ''));
    const isRevisedUwr: boolean = this.revisedUwrs.includes(uwrNumber);

    if (uwr === 'uwr16') {
      uwr = `uwr16${form.toLocaleLowerCase()}`;
    }

    if (isRevisedUwr && this.isValidRevisedDate(uwr)) {
      this.updateUwrListRevised(condition, uwr, isMoratoriumUWR, customMessage);

      return;
    }

    this.updateUwrListOriginal(condition, uwr, isMoratoriumUWR, customMessage);
  }

  initUpdateUwrList(condition: boolean, desc: string): void {
    if (!condition) {
      this.checkRemovedUwr(desc);

      if (this.uwApprovalData.uwApprovalList.includes(desc)) {
        const indexToRemove = this.uwApprovalData.uwApprovalList.indexOf(desc);

        this.uwApprovalData.uwApprovalList.splice(indexToRemove, 1);
        this.removeFromTableRows(desc);
      }

      return;
    }

    this.checkAddedUwr(desc);

    if (!this.uwApprovalData.uwApprovalList.includes(desc)) {
      this.uwApprovalData.uwApprovalList.push(desc);
    }
  }

  removeFromTableRows(value: string): void {
    this.uwApprovalData.uwApprovalTableRows.forEach((item: ITableTr, index) => {
      if (item.tr[0].value === value) {
        this.uwApprovalData.uwApprovalTableRows.splice(index, 1);
      }
    });
  }

  getSppName(scheduleName: string): string {
    let newScheduleName: string;

    switch (scheduleName) {
      case 'personalJewelry':
        newScheduleName = 'Personal Jewelry';
        break;
      case 'bicycles':
        newScheduleName = 'Bicycles';
        break;
      case 'cameraAndProjectionEquipment':
        newScheduleName = 'Camera and Projection Equipment';
        break;
      case 'coinCollections':
        newScheduleName = 'Coin Collections';
        break;
      case 'antiques':
        newScheduleName = 'Antiques';
        break;
      case 'fineArtsAndAntiquesNoBreakage':
        newScheduleName = 'Fine Arts and Antiques No Breakage';
        break;
      case 'fineArtsAndAntiquesWithBreakage':
        newScheduleName = 'Fine Arts and Antiques With Breakage';
        break;
      case 'furs':
        newScheduleName = 'Furs';
        break;
      case 'golfEquipment':
        newScheduleName = 'Golf Equipment';
        break;
      case 'gunsCollectible':
        newScheduleName = 'Guns Collectible';
        break;
      case 'gunsFired':
        newScheduleName = 'Guns Fired';
        break;
      case 'musicalInstrumentsPersonal':
        newScheduleName = 'Musical Instruments Personal';
        break;
      case 'miscellaneous':
        newScheduleName = 'Miscellaneous';
        break;
      case 'otherSportsEquipment':
        newScheduleName = 'Other Sports Equipment';
        break;
      case 'silverware':
        newScheduleName = 'Silverware';
        break;
      case 'stampCollections':
        newScheduleName = 'Stamp Collections';
        break;
      default:
        newScheduleName = '';
        break;
    }

    return newScheduleName;
  }

  checkUwrChanges(condition: boolean, desc: string): void {
    if (condition) {
      if (this.hasExistingLLCTrust(desc)) {
        return;
      }

      this.checkAddedUwr(desc, false);

      return;
    }

    this.checkRemovedUwr(desc, false);
  }

  checkAddedUwr(desc: string, onNav: boolean = true): void {
    const uwApprovalListAdded = onNav ? 'uwApprovalListAddedOnNav' : 'uwApprovalListAdded';
    const uwApprovalListRemoved = onNav ? 'uwApprovalListRemovedOnNav' : 'uwApprovalListRemoved';

    if (this.uwApprovalData.uwApprovalList.includes(desc) && this.uwApprovalData[uwApprovalListRemoved].includes(desc)) {
      this.uwApprovalData[uwApprovalListRemoved].forEach((item, index) => {
        if (item === desc) {this.uwApprovalData[uwApprovalListRemoved].splice(index, 1);}
      });
    }

    if (!this.uwApprovalData.uwApprovalList.includes(desc) && !this.uwApprovalData[uwApprovalListAdded].includes(desc)) {
      this.uwApprovalData[uwApprovalListAdded].push(desc);

      this.uwApprovalData[uwApprovalListRemoved].forEach((item, index) => {
        if (item === desc) {this.uwApprovalData[uwApprovalListRemoved].splice(index, 1);}
      });
    }
  }

  checkRemovedUwr(desc: string, onNav: boolean = false): void {
    const uwApprovalListAdded = onNav ? 'uwApprovalListAddedOnNav' : 'uwApprovalListAdded';
    const uwApprovalListRemoved = onNav ? 'uwApprovalListRemovedOnNav' : 'uwApprovalListRemoved';

    if (this.uwApprovalData.uwApprovalList.includes(desc)) {
      this.uwApprovalData[uwApprovalListAdded].forEach((item, index) => {
        if (item === desc) {this.uwApprovalData[uwApprovalListAdded].splice(index, 1);}
      });

      if (!this.uwApprovalData[uwApprovalListRemoved].includes(desc)) {
        this.uwApprovalData[uwApprovalListRemoved].push(desc);
      }

      return;
    }

    if (!this.uwApprovalData.uwApprovalList.includes(desc)) {
      if (this.uwApprovalData[uwApprovalListRemoved].includes(desc)) {
        this.uwApprovalData[uwApprovalListRemoved].forEach((item, index) => {
          if (item === desc) {this.uwApprovalData[uwApprovalListRemoved].splice(index, 1);}
        });
      }

      if (this.uwApprovalData[uwApprovalListAdded].includes(desc)) {
        this.uwApprovalData[uwApprovalListAdded].forEach((item, index) => {
          if (item === desc) {this.uwApprovalData[uwApprovalListAdded].splice(index, 1);}
        });
      }
    }
  }

  hasExistingLLCTrust(desc: string): boolean {
    const hasLLCTrust: boolean = this.uwApprovalData.uwrSavedData$.value?.riskUWApprovals?.filter(item => item.uwApprovalDescription === 'Post bind refer: LLC or Trust.')?.length > 0;
    const isUWR85: boolean = desc === 'Post bind refer: LLC or Trust.';

    return isUWR85 && hasLLCTrust;
  }

  stringToLower(data: string): string {
    return data ? `${data}`.toLocaleLowerCase() : '';
  }

  getYearDiffFromEffectiveDate(day: number, month: number, year: number): number {
    const effectiveDate = this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId()) ? new Date(this.entityRiskData.EntityRisk?.risks[0]?.riskDetails[0].effectiveDate) :
      this.applicantData.mainApplicant.get('effectiveDate')?.value?.singleDate?.jsDate;

    const claimsDate = new Date(`${year}/${month}/${day}`);
    const claimLossDate = moment(new DatePipe('en-US')?.transform(claimsDate, 'MM/dd/yyyy'));
    const formattedEffectiveDate = moment(new DatePipe('en-US')?.transform(effectiveDate, 'MM/dd/yyyy'));
    const cntrEffectiveDate = new Date(effectiveDate);

    if((cntrEffectiveDate.getMonth() + 1) === month && cntrEffectiveDate.getDate() === day) {
      return moment.duration(formattedEffectiveDate?.diff(claimLossDate)).years();
    }
    return moment.duration(formattedEffectiveDate?.diff(claimLossDate)).asYears();
  }

  renewalRemoveFromTableRow(uwr: string): void {
    const form = this.summaryData.SummaryForm.get('formType')?.value;
    const uwrNumber: number = Number(uwr.replace('uwr', ''));
    const isRevisedUwr: boolean = this.revisedUwrs.includes(uwrNumber);
    let desc: string;

    if (uwr === 'uwr16') {
      uwr = `uwr16${form.toLocaleLowerCase()}`;
    };

    if (isRevisedUwr && this.isValidRevisedDate(uwr)) {
      desc = this.getUwrDescriptionRevised(this.lvUWApprovalCodeConstantsRevised[uwr]?.code);
    } else {
      desc = this.getUwrDescription(this.lvUWApprovalCodeConstants[uwr]);
    }

    const indexToRemove = this.uwApprovalData.uwApprovalList.indexOf(desc);

    if (indexToRemove >= 0) {
      this.uwApprovalData.uwApprovalList.splice(indexToRemove, 1);
      this.removeFromTableRows(desc);
    }
  }

  checkUwrChangesList(): void {
    const uwApprovalListAdded = this.saveCoverages ? 'uwApprovalListAddedOnNav' : 'uwApprovalListAdded';
    const uwApprovalListRemoved = this.saveCoverages ? 'uwApprovalListRemovedOnNav' : 'uwApprovalListRemoved';
    const uwApprovalList$ = this.saveCoverages ? 'uwApprovalListOnNav$' : 'uwApprovalList$';

    if (this.uwApprovalData[uwApprovalListAdded].length !== 0 || this.uwApprovalData[uwApprovalListRemoved].length !== 0) {
      this.uwApprovalData[uwApprovalList$].next(true);

      return;
    }

    this.uwApprovalData[uwApprovalList$].next(false);
  }

  policyRemoveFromTableRow(uwr: string): void {
    const form = this.summaryData.SummaryForm.get('formType')?.value;
    const uwrNumber: number = Number(uwr.replace('uwr', ''));
    const isRevisedUwr: boolean = this.revisedUwrs.includes(uwrNumber);
    let desc: string;

    if (uwr === 'uwr16') {
      uwr = `uwr16${form.toLocaleLowerCase()}`;
    };

    if (isRevisedUwr && this.isValidRevisedDate(uwr)) {
      desc = this.getUwrDescriptionRevised(this.lvUWApprovalCodeConstantsRevised[uwr]?.code);
    } else {
      desc = this.getUwrDescription(this.lvUWApprovalCodeConstants[uwr]);
    }

    const indexToRemove = this.uwApprovalData.uwApprovalList.indexOf(desc);

    if (indexToRemove >= 0) {
      this.uwApprovalData.uwApprovalList.splice(indexToRemove, 1);
      this.removeFromTableRows(desc);
    }
  }

  getInterestProps(interest: RiskInterestDetailDTO2): RiskInterestDetailDTO2 {
    const newInterest = _.cloneDeep(interest);

    return {
      id: newInterest?.id,
      interestTypeCode: newInterest?.interestTypeCode,
      descOfInterestCode: newInterest?.descOfInterestCode,
      city: newInterest?.city,
      countryCode: newInterest?.countryCode,
      interestLastName: newInterest?.interestLastName,
      interestLastName2: newInterest?.interestLastName2,
      interestName: newInterest?.interestName,
      interestName2: newInterest?.interestName2,
      interestNameSuffix: newInterest?.interestNameSuffix,
      interestNameSuffix2: newInterest?.interestNameSuffix2,
      isIndividual: newInterest?.isIndividual,
      loanNumber: newInterest?.loanNumber,
      mailingAddress: newInterest?.mailingAddress,
      mailingAddress2: newInterest?.mailingAddress2,
      rankCode: newInterest?.rankCode,
      state: newInterest?.state,
      street: newInterest?.street,
      zipCode: newInterest?.zipCode
    };
  }

  get isHO3(): boolean {
    return this.summaryData.SummaryForm.get('formType')?.value === this.formType.ho3;
  }

  get isHO4(): boolean {
    return this.summaryData.SummaryForm.get('formType')?.value === this.formType.ho4;
  }

  get isHO6(): boolean {
    return this.summaryData.SummaryForm.get('formType')?.value === this.formType.ho6;
  }

  get isDP3(): boolean {
    return this.summaryData.SummaryForm.get('formType')?.value === this.formType.dp3;
  }

  get isHO3orHO4(): boolean {
    return this.isHO3 || this.isHO4;
  }

  get isHO6orDP3(): boolean {
    return this.isHO6 || this.isDP3;
  }

  get isHO4orHO6(): boolean {
    return this.isHO4 || this.isHO6;
  }

  get isHO3orHO4orHO6(): boolean {
    return this.isHO3 || this.isHO4 || this.isHO6;
  }

  get isHO3orHO6(): boolean {
    return this.isHO3 || this.isHO6;
  }

  get isHO3orDP3(): boolean {
    return this.isHO3 || this.isDP3;
  }

  get isHO3orDP3orHO6(): boolean {
    return this.isHO3 || this.isDP3 || this.isHO6;
  }

  get isHO3orDP3orHO4orHO6(): boolean {
    return this.isHO3 || this.isDP3 || this.isHO4 || this.isHO6;
  }

  get isPolicy(): boolean {
    return this.policySummaryData.isPolicy;
  }

  get isEditPolicy(): boolean {
    return this.policySummaryData.isEditPolicy;
  }

  get isRenewalPending(): boolean {
    return this.policySummaryData.renewalStatusCode === 'RNPEN';
  }

  get isRevisedRenewalPending(): boolean {
    return this.policySummaryData.renewalStatusCode === 'RRNPEN';
  }

  get isRenewalPendingOrRevisedRenewalPending(): boolean {
    return this.isRenewalPending || this.isRevisedRenewalPending;
  }

  get isRenewalOffered(): boolean {
    return this.policySummaryData.renewalStatusCode === 'RWO';
  }

  get isRenewalPendingOrOffered(): boolean {
    return this.isRenewalPending || this.isRenewalOffered;
  }

  get isRevisedRenewalOffered(): boolean {
    return this.policySummaryData.renewalStatusCode === 'RRWO';
  }

  get isValidForRenewalUWR(): boolean {
    return this.isRenewalOffered || this.isRevisedRenewalOffered;
  }

  get isPolicyOrValidForRenewal(): boolean {
    return this.isPolicy || this.isValidForRenewalUWR;
  }

  get riskEffectiveDateYear(): number {
    const mainApplicantEffectiveDate = this.applicantData.mainApplicant.get('effectiveDate')?.value?.singleDate?.jsDate;
    const effectiveDate = moment(mainApplicantEffectiveDate).format('MM/DD/YYYY');
    const effectiveDateYear = Number(moment(moment(effectiveDate).format('MM/DD/YYYY')).format('YYYY'));

    return effectiveDateYear;
  }

  get riskEffectiveDate(): string {
    return moment(this.applicantData.mainApplicant.get('effectiveDate')?.value?.singleDate?.jsDate).format('MM/DD/YYYY');
  }

  get rspsStartDate(): string {
    return this.endorsementsData.policySummaryData.isRenewal() ? moment(localStorage.getItem('RSPSDate_RB')).format('MM/DD/YYYY') :
    moment(localStorage.getItem('RSPSDate_NB')).format('MM/DD/YYYY');
  }

  getUwrDescriptionRevised(code: string): string {
    const data: string =
      this.lvUWApprovalDescriptionsRevised[code]?.description || 'Error';

    return data;
  }

  isValidRevisedDate(uwr: string): boolean {
    const uwrNumber: number = Number(uwr.replace('uwr', ''));
    const isRevised: boolean = this.revisedUwrs.includes(uwrNumber);

    if (isRevised) {
      const revisedEffectiveDate: string = moment(this.lvUWApprovalCodeConstantsRevised[uwr].effectiveDate).format('MM/DD/YYYY');
      const policyEffectiveDate: string = this.summaryData.SummaryForm.get('effectiveDate').value?.singleDate?.jsDate;
      const policyEffectiveDateFormatted: string = policyEffectiveDate ? moment(this.summaryData.SummaryForm.get('effectiveDate').value?.singleDate?.jsDate).format('MM/DD/YYYY') :
        moment(this.summaryData.SummaryForm.get('effectiveDate').value).format('MM/DD/YYYY');
      const isValidDate: boolean = moment(policyEffectiveDateFormatted).diff(revisedEffectiveDate, 'days') >= 0;

      if (isValidDate) {
        return true;
      }

      return false;
    }

    return false;
  }

  updateUwrListOriginal(condition: boolean, uwr: string, isMoratoriumUWR: boolean, customMessage?: string): void {
    const originalDescription: string = this.getUwrDescription(this.lvUWApprovalCodeConstants[uwr]);
    const revisedDescription: string = this.getUwrDescriptionRevised(this.lvUWApprovalCodeConstantsRevised[uwr]?.code);

    let desc = customMessage
      ? customMessage
      : originalDescription;

    desc = desc.trim();

    // For real-time checking
    if (!this.saveCoverages && !this.policySummaryData.isPolicy && !isMoratoriumUWR) {
      this.checkUwrChanges(condition, desc);
      this.checkUwrChanges(false, revisedDescription);
      return;
    }

    // For non-real-time checking
    this.initUpdateUwrList(condition, desc);
    this.initUpdateUwrList(false, revisedDescription);
  }

  updateUwrListRevised(condition: boolean, uwr: string, isMoratoriumUWR: boolean, customMessage?: string): void {
    const originalDescription: string = this.getUwrDescription(this.lvUWApprovalCodeConstants[uwr]);
    const revisedDescription: string = this.getUwrDescriptionRevised(this.lvUWApprovalCodeConstantsRevised[uwr]?.code);

    let desc = customMessage
      ? customMessage
      : revisedDescription;

    desc = desc.trim();

    // For real-time checking
    if (!this.saveCoverages && !this.policySummaryData.isPolicy && !isMoratoriumUWR) {
      this.checkUwrChanges(condition, desc);
      this.checkUwrChanges(false, originalDescription);
      return;
    }

    // For non-real-time checking
    this.initUpdateUwrList(condition, desc);
    this.initUpdateUwrList(false, originalDescription);
  }

  checkIsUWRTurnOffWithEffectiveDate(turnOffUWREffectiveDate: Date, policyEffectiveDate: string): boolean {
    let status = false;
    if (moment(policyEffectiveDate).diff(turnOffUWREffectiveDate, 'days') >= 0) {
      status = true;
    }
    return status;
  }
}
