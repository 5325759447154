import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClickTypes } from '../../../../shared/enum/click-type.enum';
import { SubmissionPageData } from '../../data/submission-page.data';
import { NavigationService } from '../../../../core/services/navigation/navigation.service';
import { AuthService } from '../../../../core/services/auth.service';
import { PropertyData } from '../../data/property.data';
import { BaseClass } from '../../../../shared/base-class';
import { pairwise, startWith, takeUntil } from 'rxjs/operators';
import { SubmissionNavValidationService } from '../../../../core/services/navigation/submission-nav-validation.service';
import { PropertyValidationService } from '../../../../core/services/submission/validations/property-validation.service';
import { PathConstants } from '../../../../shared/constants/path.constants';
import { PageSectionsValidations } from '../../../../shared/enum/page-sections.enum';
import { SubmissionService } from '../../../../core/services/submission/submission.service';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { CoveragesData } from '../../data/coverages.data';
import { EntityRiskData } from '../../data/entity-risk.data';
import { QuoteService } from '../../../../core/services/quote.service';
import { FormTypeConstants } from '../../../../shared/constants/form-types.constants';
import { PropertySavingData } from '../../data/property.saving.data';
import { SummaryData } from '../../data/summary.data';
import { UWR } from 'app/core/services/submission/uw-referrals/uwr.service';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { FormGroup } from '@angular/forms';
import { CalculateRaterData } from 'app/core/services/submission/rater-premium/calculate-rater-data.service';
import { ThirdPartyData } from '../../data/third-party.data';
import { ThirdPartyDataService } from 'app/core/services/submission/third-party/third-party-data.service';
import { PageHeaderSummaryConstants } from 'app/shared/constants/page-header-summary.constants';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { PropertyBusinessRules } from '../../business-rules/property.business-rules';


@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss']
})

export class PropertyComponent extends BaseClass implements OnInit, OnDestroy {
  userType: string;
  formTypeConstants = FormTypeConstants;
  isInternal: boolean;
  agentDashboardContants = AgentDashboardConstants;

  constructor(
    public route: ActivatedRoute,
    public submissionData: SubmissionPageData,
    public navigationService: NavigationService,
    protected authService: AuthService,
    public propertyData: PropertyData,
    public formValidation: SubmissionNavValidationService,
    public propertyValidationService: PropertyValidationService,
    public submissionService: SubmissionService,
    public policySummaryData: PolicySummaryData,
    public raterData: CalculateRaterData,
    public coveragesData: CoveragesData,
    public entityRiskData: EntityRiskData,
    public quoteService: QuoteService,
    public propertySavingData: PropertySavingData,
    public summaryData: SummaryData,
    protected uwr: UWR,
    protected thirdPartyData: ThirdPartyData,
    protected propertyBusinessRules: PropertyBusinessRules
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeToUserType();
    sessionStorage.setItem(PageSectionsValidations.CurrentPage, PathConstants.PageDestination.Property);
    this.subscribeToFormChanges();
  }

  subscribeToUserType(): void {
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.userType = userType;
      this.isInternal = this.userType === 'internal';
    });
  }

  subscribeToFormChanges(): void {
    const propertyForms = ['locationDetails', 'windMitigation'];

    this.uwr.uwrPopulated$.pipe(takeUntil(this.stop$)).subscribe((result) => {
      if (result) {
        propertyForms.forEach(form => {
          this.propertyData[form].valueChanges.pipe(takeUntil(this.stop$)).subscribe(() => {
            this.uwr.getUwrReferrals(false);
          });
        });
        this.subscribeToPropertyDetailsValueChanges(this.propertyData['propertyDetails']);
      }
    });

    this.propertyData.showWindMitigation();
  }

  async onSubmit(clickType?: string): Promise<void> {
    if (await this.calculate(clickType)) { return; }

    if (!this.policySummaryData.isPolicy) {
      switch (clickType) {
        case ClickTypes.Back:
          this.navigationService.navigateRoute(PathConstants.PageDestination.Claims);
          break;
        case ClickTypes.Next:
          this.navigationService.navigateRoute(PathConstants.PageDestination.Applicant, true);
          break;
      }
      return;
    }

    this.onNextBackPolicy(clickType);
  }

  async calculate(clickType?: string): Promise<boolean> {
    if (clickType === ClickTypes.Calculate) {

     if(this.propertySavingData.locationDetails()){
         await this.thirdPartyData.recalculateValuationThenMap();

         const riskId = this.policySummaryData.isPolicy ? this.entityRiskData.getRiskId() : this.summaryData.SummaryForm.get('riskId').value;
         const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;

         this.raterData.propertyRaterRequest(this.propertyData, this.coveragesData, this.entityRiskData.getPolicyNumber(), this.propertyBusinessRules);
         this.propertySavingData.saveData(riskId, riskDetailId);

         return true;
     } else {
       const riskId = this.policySummaryData.isPolicy ? this.entityRiskData.getRiskId() : this.summaryData.SummaryForm.get('riskId').value;
       const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;

       this.raterData.propertyRaterRequest(this.propertyData, this.coveragesData, this.entityRiskData.getPolicyNumber(), this.propertyBusinessRules);
       this.propertySavingData.saveData(riskId, riskDetailId);

       return true;
     }

    }

    return false;
  }

  get isRenewalOffered(): boolean {
    return (this.policySummaryData.renewalStatusCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RWO ||
      this.policySummaryData.renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RWO) ||
      (this.policySummaryData.renewalStatusCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO ||
      this.policySummaryData.renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO);
  }

  calculateDiff(sentDate) {
    const date1: any = sentDate;
    const date2: any = this.authService.getCustomDate();
    const diffDays: any = Math.floor((date1 - date2) / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  get disableFormFields(): boolean {

    if (this.isRefferedtoUWStatus) {
      return true;
    }

    if (this.policySummaryData.isPolicy) {
      return !(this.policySummaryData.isEditPolicy || this.isRefferedtoUWStatus);
    }

    const renewalEffectiveDate = new Date(this.entityRiskData.EntityRisk?.risks[0]?.renewalEffectiveDate);
    const diffDays = this.calculateDiff(renewalEffectiveDate);

    if (this.isRenewalOffered &&
        this.authService.userType.value === GenericConstants.userType.external &&
        diffDays < 2) {
      return true;
    }

    return this.submissionData.disableQuoteForms;
  }

  get isRefferedtoUWStatus(): boolean {
    return this.submissionData.policyStatus?.value === this.agentDashboardContants.referredToUW && this.coveragesData.isExternal;
  }

  get isDeclinedUWStatus(): boolean {
    return this.submissionData.policyStatus?.value === this.agentDashboardContants.declineByUW && this.coveragesData.isExternal;
  }

  onNextBackPolicy(clickType): void {
    const page = clickType === ClickTypes.Back ? PathConstants.Policy.Policies.Claims :
      PathConstants.Policy.Policies.Applicant;
    this.navigationService.navigatePolicyRoute(page);
  }

  getDropdownValueId(name: string, dropdown: any[], isLvRater: boolean = false) {
    let value;

    if (isLvRater) {
      value = dropdown.find(x => x.code === name)?.id;
    } else {
      value = dropdown.find(x => x.name === name)?.value;
    }

    return value;
  }

  checkYearLength(value: string): boolean {
    const validYearLength: number = 4;
    return value?.toString()?.trim().length === validYearLength;
  }

  subscribeToPropertyDetailsValueChanges(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      if (key !== 'yearBuilt') {
        control.valueChanges.pipe(takeUntil(this.stop$), startWith(control.value), pairwise()).subscribe(([prev, next]) => {
          if (prev !== next && key.includes('year')) {
            if (this.checkYearLength(next)) {
              this.uwr.getUwrReferrals(false);
            }
          }
          if (prev !== next && !key.includes('year')) {
            this.uwr.getUwrReferrals(false);
          }
        });
      } else {
        control.valueChanges.pipe(takeUntil(this.stop$)).subscribe((val) => {
          if (this.checkYearLength(val)) {
            this.uwr.getUwrReferrals(false);
            if (!this.policySummaryData.isPolicy) {
              this.thirdPartyData.getISO360YearBuilt();
            }
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.propertyData.locationDetails.markAllAsTouched();
    this.propertyData.propertyDetails.markAllAsTouched();
    this.propertyData.windMitigation.markAllAsTouched();
  }
}
