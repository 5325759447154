import { Component, OnInit } from '@angular/core';
import { PolicyBillingLabelsConstants } from '../../../../../../../app/shared/constants/policy-billing.labels.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BillingService } from '../../../../../../../app/core/services/billing/billing.service';
import { PolicyBillingData } from '../../../../../../../app/modules/policy-management/data/policy-billing.data';
import { BaseClass } from '../../../../../../../app/shared/base-class';
import { MakePaymentModalComponent } from '../make-payment-modal/make-payment-modal.component';
import { PostPaymentModalComponent } from '../../../../../../../app/modules/policy-management/policy-billing/payments/post-payments/post-payment-modal/post-payment-modal.component';
import { AllowedPaymentRangeDTO } from '../../../../../../../app/shared/models/data/dto/billing/allowed-payment-range.dto';
import { PaymentProcedure } from '../../../../../../../app/shared/enum/payment-procedure.enum';

@Component({
  selector: 'app-outstanding-reinstatement-requirements-modal',
  templateUrl: './outstanding-reinstatement-requirements-modal.component.html',
  styleUrls: ['./outstanding-reinstatement-requirements-modal.component.scss']
})
export class OutstandingReinstatementRequirementsModalComponent extends BaseClass implements OnInit {

  public OutstandingRequirementsLabelConstants = PolicyBillingLabelsConstants.outstandingReinstatementRequirements;

  modalRef: BsModalRef | null;
  hasOutstandingBalance: boolean;
  outstandingRequirementsList: string[];
  riskId: string;
  accessedFromPortal: boolean = true;

  allowedPaymentRange: AllowedPaymentRangeDTO;

  constructor(
    public billingService: BillingService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public billingData: PolicyBillingData) {

    super();
  }

  ngOnInit() {
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  onPayBalance(): void {
    const initialState = {
      allowedPaymentRange: this.allowedPaymentRange,
      paymentProcedure: PaymentProcedure.PayBalance
    };

    if (this.accessedFromPortal) {
      this.billingData.isAccessedFromPortal = true;
      this.modalRef = this.modalService.show(MakePaymentModalComponent, {
        initialState,
        backdrop: true,
        ignoreBackdropClick: true,
      });
    } else {
      this.billingData.isAccessedFromPortal = false;
      this.modalRef = this.modalService.show(PostPaymentModalComponent, {
        initialState,
        backdrop: true,
        ignoreBackdropClick: true,
      });
    }

    this.modalRef.hide();
    this.bsModalRef.hide();
  }

  onExit(): void {
    this.bsModalRef.hide();
  }
}
