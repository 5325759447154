export const UwDashboardConstants = {
  contactsNavBar: 'Contacts',
  linksNavBar: 'Links',
  startNewQuote: 'Start New Quote',
  startNewQuoteModalTitle: 'Choose a Program State and Type',
  processEndorsement: 'Process Endorsement',
  makeAPayment: 'Make A Payment',
  makeAPaymentModalTitle: 'Make A Payment',
  reportAClaim: 'Report A Claim',
  addATask: 'Add A Task',
  reference: 'Reference',
  taskFiltersmyTasks: 'My Tasks',
  taskFiltersOwner: 'Owner',
  taskFiltersStatus: 'Status',
  taskFiltersDescription: 'Description',
  taskFiltersState: 'State',
  taskFiltersDueDate: 'Due Date',
  taskFiltersPriority: 'Priority',
  taskFiltersPolicyNumber: 'Quote/Policy Number',
  taskFiltersCategory: 'Category',
  taskFiltersSortBy: 'Sort By',
  tasks: 'Tasks',
  tasksDateAdded: 'Date Added',
  tasksOwner: 'Owner',
  tasksCategory: 'Category',
  tasksDueDate: 'Due Date',
  tasksPriority: 'Priority',
  tasksTaskStatus: 'Task Status',
  tasksNamedInsured: 'Named Insured',
  tasksQuoteNumber: 'Quote #',
  tasksPolicyNumber: 'Policy #',
  tasksStatus: 'Status',
  tasksEffectiveDate: 'Effective Date',
  tasksDescription: 'Description',
  tasksEditBtnLabel: 'Edit',
  search: 'Search',
  generateACHFile: 'Generate ACH File',
  commissionPayment: 'Commission Payment',
  confirmACHFileGeneration: 'Are you sure you want to generate the ACH File?',
  achFileSuccessfullyGenerated: 'ACH File successfully generated.',
  flood: 'Flood'
};
