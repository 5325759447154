import { Component, OnInit } from '@angular/core';
import { BillingService } from '../../../../../core/services/billing/billing.service';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { BaseClass } from '../../../../../shared/base-class';
import { takeUntil } from 'rxjs/operators';
import { FilterSubmissionListDTO } from '../../../../../shared/models/data/dto/submission-list/filter-submission-list.dto';
import { SubmissionService } from '../../../../../core/services/submission/submission.service';
import { AgentService } from '../../../../../core/services/management/agent-service';
import { AuthService } from 'app/core/services/auth.service';

@Component({
  selector: 'app-billing-section',
  templateUrl: './billing-section.component.html',
  styleUrls: ['./billing-section.component.scss']
})
export class BillingSectionComponent extends BaseClass implements OnInit {

  public agentDashboardConstants = AgentDashboardConstants;
  paymentDueCount: number;
  paymentPastDueCount: number;
  isFromPaymentDue: boolean;
  paymentDueData: string[] = [];
  paymentPastDueData: string[] = [];
  paymentDueFilter: FilterSubmissionListDTO;
  paymentPastDueFilter: FilterSubmissionListDTO;
  agentId: string;
  subAgencyId: string;
  isPaymentDueCountUpdated: boolean;
  isPaymentPastDueCountUpdated: boolean;
  defaultData: string[] = ['00000000-0000-0000-0000-000000000000'];

  constructor(private billingService: BillingService, private submissionService: SubmissionService, private agentService: AgentService
    , private authService: AuthService) {
    super();
  }

  ngOnInit() {
    this.agentService.getAgentUserInformation(localStorage.getItem('uname')).pipe(takeUntil(this.stop$)).subscribe((agentInfo) => {
      if (agentInfo) {
        this.agentId = agentInfo?.id;
        this.agentService.getAgentInformation(this.agentId).pipe(takeUntil(this.stop$)).subscribe((result) => {
          if (result) {
            this.subAgencyId = result?.subAgencyId;
            this.fetchDataFromBillingAPI();
          }
        });
      }
    });
  }

  getCurrentDateOnISOFormat(): string {
    const currentDate = this.authService.getCustomDate();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth()+1;
    const day = currentDate.getDate();
    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
  }

  fetchDataFromBillingAPI(): void {
    this.billingService.getPaymentDueList(this.getCurrentDateOnISOFormat()).pipe(takeUntil(this.stop$)).subscribe((data) => {
      this.isPaymentDueCountUpdated = false;
      if (!this.isBillingDataEmpty(data?.count)) {
        this.paymentDueData = data?.paymentDueRisks.map(x => x.riskId);
        this.getVerifiedData(true);
      } else {
        this.setToDefaultData(true);
      }
    });
    this.billingService.getPaymentPastDueList(this.getCurrentDateOnISOFormat()).pipe(takeUntil(this.stop$)).subscribe((data) => {
      this.isPaymentPastDueCountUpdated = false;
      if (!this.isBillingDataEmpty(data?.count)) {
        this.paymentPastDueData = data?.paymentDueRisks.map(x => x.riskId);
        this.getVerifiedData(false);
      } else {
        this.setToDefaultData(false);
      }
    });
  }

  getVerifiedData(isForPaymentDue: boolean): void {
    const riskIds = isForPaymentDue ? this.paymentDueData : this.paymentPastDueData;
    this.submissionService.getSubmissionListBySubagencyAsync(riskIds).pipe(takeUntil(this.stop$)).subscribe(verified => {
      if (isForPaymentDue) {
        this.paymentDueCount = verified?.length ?? 0;
        this.isPaymentDueCountUpdated = true;
        this.paymentDueData = verified?.length !== 0 ? verified : this.defaultData;
      } else {
        this.paymentPastDueCount  = verified?.length ?? 0;
        this.isPaymentPastDueCountUpdated = true;
        this.paymentPastDueData = verified?.length !== 0 ? verified : this.defaultData;
      }
    });
  }

  isBillingDataEmpty(count: number): boolean {
    return count > 0 ? false : true;
  }

  setToDefaultData(isForPaymentDue: boolean): void {
    if (isForPaymentDue) {
      this.paymentDueCount = 0;
      this.paymentDueData = this.defaultData;
      this.isPaymentDueCountUpdated = true;
    } else {
      this.paymentPastDueCount = 0;
      this.paymentPastDueData = this.defaultData;
      this.isPaymentPastDueCountUpdated = true;
    }
  }
}
