import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from 'app/core/services/navigation/navigation.service';
import { PathConstants } from 'app/shared/constants/path.constants';
import { AuthService } from 'app/core/services/auth.service';
import { BaseClass } from 'app/shared/base-class';
import { takeUntil } from 'rxjs/operators';
import { ClickTypes } from '../../../shared/enum/click-type.enum';
import { PolicyBillingData } from '../data/policy-billing.data';
import { EntityRiskData } from '../../../modules/submission-management/data/entity-risk.data';
import { PolicySummaryData } from '../data/policy-summary.data';

@Component({
  selector: 'app-policy-billing',
  templateUrl: './policy-billing.component.html',
  styleUrls: ['./policy-billing.component.scss']
})
export class PolicyBillingComponent extends BaseClass implements OnInit, OnDestroy {

  isInternal: boolean;

  constructor(protected authService: AuthService,
    public navigationService: NavigationService,
    public policyBillingData: PolicyBillingData,
    public entityRiskData: EntityRiskData,
    public policySummaryData: PolicySummaryData
    ) {
    super();
  }

  ngOnInit() {
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.isInternal = this.isInternalUser(userType);
    });

    this.entityRiskData.isApiCallCompleted$.subscribe(res => {
      if (this.entityRiskData.policySummaryData.isPolicy) {
        if (res) {
          localStorage.setItem('riskId', this.entityRiskData.getRiskId());
          this.policyBillingData.isPaymentViewFilter = false;
          this.policyBillingData.listPaymentsByRiskId(localStorage.getItem('riskId'));
        }
      }
    })


 }
 
  public onClick(clickType?: ClickTypes): void {
    switch (clickType) {
      case ClickTypes.Back:
        // to do back
        this.navigationService.navigatePolicyRoute(PathConstants.Policy.Policies.History);
        break;
      case ClickTypes.Next:
        // to do next
        this.navigationService.navigatePolicyRoute(PathConstants.Policy.Policies.Documents);
        break;
    }
  }

  public isInternalUser(userType: string): boolean {
    return userType.toLocaleLowerCase() === 'internal';
  }

  ngOnDestroy() {
    this.policyBillingData.resetPaymentList();
  }
}
