import { Component, OnInit } from '@angular/core';
import swal, { SweetAlertOptions } from 'sweetalert2';
import Utils from 'app/shared/utilities/utils';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PolicyService } from 'app/core/services/submission/policy.service';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { DashboardData } from '../dashboard/data/dashboard.data';
import { DashBoardService } from 'app/core/services/dashboard.service';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.css']
})
export class AccessComponent implements OnInit {
  stop$: Observable<any>;
  boxList;

  constructor(
    private router: Router,
    private policyService: PolicyService,
    public dashboardData: DashboardData,
    private dashboardService: DashBoardService,
    private route: ActivatedRoute
    ) {

  }

  ngOnInit(): void {
      Utils.blockUI();
      this.dashboardService.getReferenceBoxList().subscribe(response => {
        this.boxList = response;
        let routeId = `${this.route.snapshot.queryParams.id.toLowerCase()}`;
        let getRouteIdDetails = this.boxList.find(x => x['id'] == routeId);

        if(this.boxList.length > 0) {
          this.policyService.generateSASURL(getRouteIdDetails['url']).subscribe(resultSASURL =>{
            Utils.unblockUI();
            window.location.replace(resultSASURL);
          });
        }else{
          Utils.unblockUI();
          this.router.navigate(['/404']);
        }
      });
  }

}
