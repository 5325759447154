import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { CommissionPaymentData } from 'app/modules/reports/data/commission-payment.data';
import { BaseClass } from 'app/shared/base-class';
import { CommissionLabels } from 'app/shared/constants/commission-labels';
import { BsModalRef, PageChangedEvent, TypeaheadMatch } from 'ngx-bootstrap';
import { AuthService } from 'app/core/services/auth.service';
import { AgencyService } from 'app/core/services/management/agency-service';
import { takeUntil } from 'rxjs/operators';
import Utils from 'app/shared/utilities/utils';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { Store } from '@ngrx/store';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { CommissionPaymentDTO, ICommissionPaymentDTO } from '../../../../shared/models/data/dto/nacha/commission-payment.dto';
import { CommissionPaymentService } from 'app/core/services/commission-payment/commission-payment.service';
import { serialize } from 'object-to-formdata';
import { Validators } from '@angular/forms';
import { CustomValidators } from '../../../../../app/shared/validators/custom.validator';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { CommissionPaymentValidatorErrorConstant } from 'app/shared/validators/custom-validator-error-constant';
import { PolicyService } from 'app/core/services/submission/policy.service';

@Component({
  selector: 'app-commission-paymen-info-modal',
  templateUrl: './commission-paymen-info-modal.component.html',
  styleUrls: ['./commission-paymen-info-modal.component.scss']
})
export class CommissionPaymenInfoModalComponent extends BaseClass implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('agencyName') agencyName: ElementRef;
  @ViewChild('arrow') arrow: ElementRef;
  public event: EventEmitter<any> = new EventEmitter<any>();

  public isAdd: boolean = false;
  public isEdit: boolean = false;
  public isView: boolean = false;
  public commissionLabels = CommissionLabels;
  public ErrorMessageConstant = ErrorMessageConstant;
  public genericConstants = GenericConstants;
  public CommissionPaymentValidatorErrorConstant = CommissionPaymentValidatorErrorConstant;
  isViewDocument: boolean = false;
  public headerTitle: string;
  public commissionPayment: CommissionPaymentDTO;
  isTransactionDateTouched: boolean = false;

  fileToUpload: File = null;
  fileLabel: string;
  fileSizeExceeds: boolean = false;
  invalidFileName: boolean = false;
  selectedAgencyId: string = null;

  currencyInputMode = CurrencyMaskInputMode.NATURAL;

  private monthList: any[] = [
    {
      id: 1,
      description: 'January'
    },
    {
      id: 2,
      description: 'February'
    },
    {
      id: 3,
      description: 'March'
    },
    {
      id: 4,
      description: 'April'
    },
    {
      id: 5,
      description: 'May'
    },
    {
      id: 6,
      description: 'June'
    },
    {
      id: 7,
      description: 'July'
    },
    {
      id: 8,
      description: 'August'
    },
    {
      id: 9,
      description: 'September'
    },
    {
      id: 10,
      description: 'October'
    },
    {
      id: 11,
      description: 'November'
    },
    {
      id: 12,
      description: 'December'
    },
  ];

  conditionalRequiredClass: boolean = false;


  noResult: boolean = false;
  count = 0;

  private typeList: any[] = [
    {
      code: 1,
      description: 'Payment'
    },
    {
      code: 2,
      description: 'Adjustment'
    }
  ];

  postTransDateOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'mm/dd/yyyy'
  };

  constructor(
    public bsModalRef: BsModalRef,
    public commissionPaymentData: CommissionPaymentData,
    private authService: AuthService,
    private agencyService: AgencyService,
    private store: Store,
    private commissionPaymentService: CommissionPaymentService,
    private cdr: ChangeDetectorRef,
    private policyService: PolicyService
    ) {
      super();
    }

  ngOnInit() {
    this.commissionPaymentData.initializeForm(this.isAdd, this.isEdit, this.isView, this.commissionPayment);
    this.initAgencyList();

    if(this.isEdit) {
      this.fileLabel = this.commissionPaymentForm.get('file').value;
      this.updateCommissionPaymentForm(this.commissionPaymentForm.get('type').value);
    }
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  isDisabledMonth(monthId: number): boolean {
    let status = true;
    const inputYear = this.commissionPaymentForm.get('year')?.value;
    if (inputYear) {
      const currentDate = this.authService.getCustomDate();
      if(inputYear < currentDate.getFullYear()) {
        status = true;
      } else {
        if (inputYear === currentDate.getFullYear()) {
          status = monthId < (currentDate.getMonth() + 1);
        } else {
          status = false;
        }
      }
    }
    return status;
  }

  ngOnDestroy(): void {
    this.isViewDocument = false;
    this.commissionPayment = null;
    this.fileToUpload = null;
    this.selectedAgencyId = null;
  }

  get commissionPaymentForm() {
    return this.commissionPaymentData.commissionPaymentForm;
  }

  onBlurYear(): void {
    const currentDate = this.authService.getCustomDate();
    const inputYear = this.commissionPaymentForm.get('year')?.value;
    if (inputYear <= currentDate.getFullYear()) {
      this.commissionPaymentForm.get('month').setValue('');
    }
  }

  hideCommissionPaymentInfoModal(): void {
    this.bsModalRef.hide();
    this.commissionPaymentData.isCommissionPaymentModalOpen = false;
  }

  toggleAgencyNameDropdown(): void {
    this.count++;
    if (this.commissionPaymentForm.get('agencyName').parent.controls['agencyName'].touched && this.count !== 1) {
      this.agencyName.nativeElement.blur();
    } else {
      this.agencyName.nativeElement.focus();
    }
  }

  onSelectedAgency(event: TypeaheadMatch): void {
    const agencyId = event.item?.id;
    this.commissionPaymentForm.get('agencyId').setValue(agencyId);
    console.log(agencyId);
  }

  initAgencyList(): void {
    const agencyList = [];
    this.commissionPaymentData.agencyListLoading = true;
    Utils.blockUI();
    this.agencyService.getAllAgencies().pipe(takeUntil(this.stop$)).subscribe(result => {
      this.commissionPaymentData.agencyList = result;
      result.forEach(a => agencyList.push({name: `${a.agencyCode} - ${a.entity.fullName}`, id: a.id}));
      this.commissionPaymentData.agencyNameList = agencyList;
      this.commissionPaymentData.agencyListLoading = false;
      Utils.unblockUI();
    }, (err) => {
      Utils.unblockUI();
      NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
    });
  }

  typeaheadNoResults(event: boolean): void {
    this.noResult = event;
  }

  get invalidFileTypeMessage(): string {
    return `The document ${this.commissionPaymentForm.get('file').value?.name} could not be uploaded. The file type is invalid.
    Note: Only the following file types are valid and can be attached – <list of valid file types e.g. ".pdf", ".doc", ".docx", ".msg", ".jpg", ".jpeg", ".bmp", ".png", ".xls", ".xlsx", ".txt", ".zip">`;
  }

  viewDocument(): void {
    this.policyService.generateSASURL(this.commissionPayment.documentURL).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
      window.open(resultSASURL, '_blank');
    });
  }

  handleFileInput(files: FileList) {
    const specialChars  = /[#%]+/;
    this.fileToUpload = files.item(0);
    this.fileLabel = this.fileToUpload.name;
    this.commissionPaymentForm.get('file').setValue(this.fileToUpload);
    this.fileSizeExceeds = false;
    this.invalidFileName = false;

    if (this.commissionPaymentForm.get('file').valid) {
      this.fileSizeExceeds = this.fileToUpload?.size > (this.genericConstants.fileSizeLimit - 1) ? true : false;
      this.invalidFileName = specialChars.test(this.fileToUpload.name);
    }
  }

  mapFile(file?) {
    this.commissionPaymentForm.get('file').setValue(file);
    if (file?.name) {
      this.fileLabel = file.name;
    } else if (file) {
      this.fileLabel = file;
    } else {
      this.fileLabel = 'No File Chosen';
    }
  }

  saveCommissionPayment(): void {
    Utils.blockUI();
    const payload = this.initializeAddCommissionPaymentPayload();
    const options = {
      indices: true,
      nullsAsUndefineds: false,
      booleansAsIntegers: false,
      allowEmptyArrays: true,
    };
    const serializedData = serialize(payload, options);

    this.commissionPaymentService.postCommissionPayment(serializedData).subscribe(res => {
      this.commissionPaymentData.commissionPaymentList = [];
      this.commissionPaymentData.initCommissionPaymentList();
      this.hideCommissionPaymentInfoModal();
      Utils.unblockUI();
    },
      err => {
        Utils.unblockUI();
        console.log(err);
      }
    );
  }

  initializeAddCommissionPaymentPayload(): ICommissionPaymentDTO {
    const newCommissionPayment: ICommissionPaymentDTO = {
      id: this.isAdd ? null : this.commissionPayment.id,
      agencyId: this.commissionPaymentForm.get('agencyId').value,
      amount: this.commissionPaymentForm.get('amount').value,
      year: this.commissionPaymentForm.get('year').value,
      month: this.commissionPaymentForm.get('month').value,
      postDate: this.commissionPaymentForm.get('postDate').value?.singleDate?.jsDate?.toLocaleDateString('en-US'),
      transactionDate: this.commissionPaymentForm.get('transactionDate').value?.singleDate?.jsDate?.toLocaleDateString('en-US'),
      checkNumber: this.commissionPaymentForm.get('checkNumber').value,
      remarks: this.commissionPaymentForm.get('remarks').value,
      type: this.commissionPaymentForm.get('type').value,
      file: this.fileToUpload
    };

    return newCommissionPayment;
  }

  isValidCommissionPaymentForm(): boolean {
    if (this.isEdit) {
      return this.commissionPaymentForm.valid &&
       !this.fileSizeExceeds &&
       !this.invalidFileName &&
       this.commissionPaymentForm.dirty;
    }
    return this.commissionPaymentForm.valid && !this.fileSizeExceeds && !this.invalidFileName;
  }

  isInValidFormControl(formControlname: string): boolean {
    if (formControlname === 'file') {
      return this.commissionPaymentForm.get(formControlname)?.errors?.required &&
            this.commissionPaymentForm.get(formControlname).touched &&
            Number(this.commissionPaymentForm.get('type').value) === 1;
    }
    return this.commissionPaymentForm.get(formControlname)?.errors?.required && this.commissionPaymentForm.get(formControlname).touched;
  }

  get isPostDateEmpty(): boolean {
    const currentPostDate = this.commissionPaymentForm.get('postDate').value?.singleDate?.jsDate.toLocaleDateString('en-US');
    return currentPostDate === undefined;
  }

  updateCommissionPaymentForm(typeId: any): void {
    this.commissionPaymentForm.get('amount').clearValidators();
    switch(Number(typeId)) {
      case 1:
        this.conditionalRequiredClass = true;
        this.commissionPaymentForm.get('checkNumber').setValidators([Validators.required]);
        this.commissionPaymentForm.get('postDate').setValidators([Validators.required]);
        this.commissionPaymentForm.get('transactionDate').setValidators([Validators.required]);
        this.commissionPaymentForm.get('file').setValidators([Validators.required, CustomValidators.requiredFileFormat(this.genericConstants.acceptedFileTypes)]);
        this.commissionPaymentForm.get('amount').setValidators([Validators.required, CustomValidators.cannotBeZeroOrNull(), CustomValidators.notAllowNegativeValueValidator(1)]);
        break;
      case 2:
        this.conditionalRequiredClass = false;
        this.commissionPaymentForm.get('checkNumber').clearValidators();
        this.commissionPaymentForm.get('postDate').clearValidators();
        this.commissionPaymentForm.get('transactionDate').clearValidators();
        this.commissionPaymentForm.get('file').clearValidators();
        this.commissionPaymentForm.get('amount').setValidators([Validators.required, CustomValidators.cannotBeZeroOrNull()]);
        break;
    }
    this.commissionPaymentForm.get('checkNumber').updateValueAndValidity();
    this.commissionPaymentForm.get('postDate').updateValueAndValidity();
    this.commissionPaymentForm.get('transactionDate').updateValueAndValidity();
    this.commissionPaymentForm.get('file').updateValueAndValidity();
    this.commissionPaymentForm.get('amount').updateValueAndValidity();
    this.commissionPaymentForm.markAllAsTouched();
  }
}
