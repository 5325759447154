export const FileUploadConstants = {
  dragAndDropLabel: 'Drag & Drop files or Click here',
  uploadFileLabel: 'Upload the following:',
  noFileChosen: 'No File Chosen',
  fileCountChosen: '1 File(s) Chosen',
  acceptedCSVExcelFileType: [
    'csv',
    'xls',
    'xlsx'
  ],
  invalidFileTypeMessage: `The document {0} could not be uploaded. The file type is invalid.`
};