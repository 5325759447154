import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EndorsementsComponent } from './endorsements.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../../../shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CanDeactivateEndorsementsComponentGuard } from './endorsements-navigation-guard.service';
import { RoofSurfacingPaymentScheduleBRULDatePipe } from 'app/shared/pipes/rsps-brul-date.pipe';

const routes: Routes = [
  //{ path: '', component: EndorsementsComponent }
  { path: '', component: EndorsementsComponent, canDeactivate: [CanDeactivateEndorsementsComponentGuard] }
];

@NgModule({
  declarations: [EndorsementsComponent, RoofSurfacingPaymentScheduleBRULDatePipe],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    TooltipModule.forRoot(),
  ],
  providers: [CanDeactivateEndorsementsComponentGuard]
})
export class EndorsementsModule {}
