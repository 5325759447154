export const LvUWApprovalCodeConstantsRevised = {
  uwr118: {
    code: 'UWR18A',
    effectiveDate: '12/01/2023'
  },
  uwr20: {
    code: 'UWR20A',
    effectiveDate: '07/25/2024'
  }
};

export const LvUWApprovalDescriptionsRevised = {
  UWR18A: {
    description: 'Refer: Wind mitigation form.'
  },
  UWR20A: {
    description: 'Ineligible: More than two water losses in the past three years.'
  }
};
