import { Component, OnInit } from '@angular/core';
import { DashboardData } from '../../../../../modules/dashboard/data/dashboard.data';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { BaseClass } from '../../../../../../app/shared/base-class';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { AuthService } from 'app/core/services/auth.service';
import { UwDashboardConstants } from 'app/shared/constants/uw-dashboard.constants';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { StateCodeValuePair } from 'app/shared/models/management/program-state-response.dto';
import { selectDashboardProgramStateAgentDetailStates } from 'app/store/dashboard/dashboard.selectors';
import { PolicyService } from 'app/core/services/submission/policy.service';
import { takeUntil } from 'rxjs/operators';
import Utils from 'app/shared/utilities/utils';
import { NachaService } from 'app/core/services/nacha.service';
import { FileSaverService } from 'ngx-filesaver';
import NotifUtils from 'app/shared/utilities/notif-utils';
import *  as fileSaver from 'file-saver';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';

@Component({
  selector: 'app-quick-reference-section',
  templateUrl: './quick-reference.component.html',
  styleUrls: ['./quick-reference.component.scss']
})
export class QuickReferenceSectionComponent extends BaseClass implements OnInit {

  public agentDashboardConstants = AgentDashboardConstants;
  public defaultState: string;
  public genericConstants = GenericConstants;
  public uwDashboardConstants = UwDashboardConstants;
  userType: string;

  dropDownStates = [this.agentDashboardConstants.alabamaFN,
    this.agentDashboardConstants.floridaFN,
    this.agentDashboardConstants.louisianaFN,
    this.agentDashboardConstants.mississippiFN,
    this.agentDashboardConstants.southCarolinaFN,
    this.agentDashboardConstants.texasFN];

  selectDashboardProgramStateAgentDetailStates$: Observable<StateCodeValuePair[]>;
  constructor(public dashboardData: DashboardData
            , private authService: AuthService
            , private store: Store
            , private policyService: PolicyService,
            public nachaService: NachaService,
            private fileSaverService: FileSaverService,
            ) {
    super();
    this.selectDashboardProgramStateAgentDetailStates$ = this.store.pipe(select(selectDashboardProgramStateAgentDetailStates));
  }

  ngOnInit() {
    this.authService.userType.subscribe((value) => {
      this.userType = value;
    });
    this.subscribeToDashboardProgramStateAgentDetailsState();
    this.dashboardData.backDataDefault();
    this.setPage(1);
  }

  setPage(page: number) {
    this.dashboardData.referenceListSetPage(page);
  }

  sendState(state: any) {
    switch (state) {
      case this.agentDashboardConstants.floridaFN:
        state = this.agentDashboardConstants.floridaSC;
        break;
      case this.agentDashboardConstants.alabamaFN:
        state = this.agentDashboardConstants.alabamaSC;
        break;
      case this.agentDashboardConstants.louisianaFN:
        state = this.agentDashboardConstants.louisianaSC;
        break;
      case this.agentDashboardConstants.mississippiFN:
        state = this.agentDashboardConstants.mississippiSC;
        break;
      case this.agentDashboardConstants.texasFN:
        state = this.agentDashboardConstants.texasSC;
        break;
      case this.agentDashboardConstants.southCarolinaFN:
        state = this.agentDashboardConstants.southCarolinaSC;
        break;
      default:
    }
    this.dashboardData.sortingByState(state);
  }

  subscribeToDashboardProgramStateAgentDetailsState(): void {
    if (this.userType === this.genericConstants.userTypeLabel.external.toLowerCase()) {
      this.selectDashboardProgramStateAgentDetailStates$.subscribe(data => {
        if (data) {
          this.defaultState = data[0].stateName;
        }
      });
    } else {
      this.defaultState = this.agentDashboardConstants.floridaFN;
    }
  }

  onViewClick(data: any): void {
    this.policyService.generateSASURL(data.url).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
      window.open(resultSASURL, '_blank');
    });
  }
}
