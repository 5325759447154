import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BindAndIssueComponent } from './bind-and-issue.component';
import { SharedModule } from '../../../../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { BindAndIssueSectionComponent } from './bind-and-issue-section/bind-and-issue-section.component';
import { BillingComponent } from './billing/billing.component';
import { NgxMaskModule } from 'ngx-mask';
import { PaperlessDocumentsComponent } from './paperless-documents/paperless-documents.component';
import { SignatureComponent } from './signature/signature.component';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentsModalComponent } from './documents/documents-modal/documents-modal.component';
import { NotesModalComponent } from './notes/notes-modal/notes-modal.component';
import { BindAndIssueService } from './bind-and-issue.service';
import { FormDocumentService } from '../../../../core/services/submission/report/form-document.service';
import { CanDeactivateBindAndIssueComponentGuard } from './bind-and-issue-navigation-guard.service';
import { BillingFormCreditCardComponent } from './billing/billing-forms/billing-form-credit-card/billing-form-credit-card.component';
import { BillingFormAccountDetailsComponent } from './billing/billing-forms/billing-form-account-details/billing-form-account-details.component';
import { BillingFormCheckComponent } from './billing/billing-forms/billing-form-check/billing-form-check.component';
import { BillingFormECheckComponent } from './billing/billing-forms/billing-form-echeck/billing-form-echeck.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { RnBillingSummaryComponent } from './rn-billing-summary/rn-billing-summary.component';
import { RnInstallmentScheduleComponent } from './rn-installment-schedule/rn-installment-schedule.component';
import { RnRecurringPaymentComponent } from './rn-recurring-payment/rn-recurring-payment.component';
import { TooltipModule } from 'ngx-bootstrap';

const routes: Routes = [
  // { path: '', component: BindAndIssueComponent, canDeactivate: [CanDeactivateBindAndIssueComponentGuard] }
  { path: '', component: BindAndIssueComponent }
];

@NgModule({
  declarations: [
    BindAndIssueComponent,
    BindAndIssueSectionComponent,
    BillingComponent,
    PaperlessDocumentsComponent,
    SignatureComponent,
    NotesModalComponent,
    BillingFormCheckComponent,
    BillingFormCreditCardComponent,
    BillingFormAccountDetailsComponent,
    BillingFormECheckComponent,
    RnBillingSummaryComponent,
    RnInstallmentScheduleComponent,
    RnRecurringPaymentComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot(),
    TypeaheadModule.forRoot(),
    TooltipModule
  ],
  exports: [
    BindAndIssueComponent,
    BindAndIssueSectionComponent,
    BillingComponent,
    PaperlessDocumentsComponent,
    SignatureComponent,
    NotesModalComponent
  ],
  entryComponents: [
    NotesModalComponent
  ],
  providers: [BindAndIssueService, FormDocumentService, CanDeactivateBindAndIssueComponentGuard]
})
export class BindAndIssueModule { }
