import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {
  // Note: Number values are measured in Pixels

  @Input() spinnerColor: string = '#20a8d8';
  @Input() spinnerSize: number = 16;

  spinnerStyle: any;

  constructor() { }

  ngOnInit(): void {
    this.spinnerStyle = {
      'color': `${ this.spinnerColor }`,
      'width': `${ this.spinnerSize }px`,
      'height': `${ this.spinnerSize }px`
    }
  }
}
