import { EventEmitter, Injectable } from '@angular/core';
import { QuickQuoteService } from '../../../core/services/submission/quick-quote.service';
import { ErrorMessageConstant } from '../../../shared/constants/error-message.constants';
import { InfoMessageConstant } from '../../../shared/constants/info-message.constants';
import { PathConstants } from '../../../shared/constants/path.constants';
import { RiskClaimDTO } from '../../../shared/models/data/dto/quick-quote/risk-claim.dto';
import { SaveClaimsDTO } from '../../../shared/models/data/dto/quick-quote/save-claims.dto';
import NotifUtils from '../../../shared/utilities/notif-utils';
import Utils from '../../../shared/utilities/utils';
import { ToastrService } from 'ngx-toastr';
import { BaseClass } from '../../../shared/base-class';
import { ClaimsData } from './claims.data';
import { SummaryData } from './summary.data';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { EntityRiskData } from './entity-risk.data';
import { Observable } from 'rxjs';
import { CalculateRaterData } from 'app/core/services/submission/rater-premium/calculate-rater-data.service';
import { AuthService } from '../../../core/services/auth.service';
import { PolicyIssueData } from '../../policy-management/data/policy-issue.data';
import { v4 as uuidv4 } from 'uuid';
import { SavingFlagConstants } from 'app/shared/constants/saving-flag.constants';
import { ApplicantData } from 'app/modules/submission-management/data/applicant.data';
import { EndorsementsData } from 'app/modules/submission-management/data/endorsements.data';
import { SubmissionPageData } from 'app/modules/submission-management/data/submission-page.data';
import { updateClaimsIsLoadingFromClaimsSavingData, updateClaimsListFromClaimsSavingData } from '../../../store/claims/claims.actions';
import { Store } from '@ngrx/store';
import { RiskStatusCode } from 'app/shared/models/data/dto/quick-quote/risk.dto';

@Injectable({
  providedIn: 'root',
})
export class ClaimsSavingData extends BaseClass {
  public claimsDoneSavingEmitter$: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public claimsData: ClaimsData,
    protected summaryData: SummaryData,
    protected quickQuoteService: QuickQuoteService,
    protected raterData: CalculateRaterData,
    protected toast: ToastrService,
    public policySummaryData: PolicySummaryData,
    public entityRiskData: EntityRiskData,
    protected authService: AuthService,
    protected policyIssueData: PolicyIssueData,
    protected applicantData: ApplicantData,
    protected endorsementsData: EndorsementsData,
    protected submissionData: SubmissionPageData,
    protected store: Store
  ) {
    super();
  }

  saveSubmissionClaims(isFirstSaveFromCompRater: boolean = false) {
    try {
      Utils.blockUI();
      if (!this.checkIfClaimsForSaving()) {
        this.entityRiskData.apiCallCompleted.next(true);
        this.store.dispatch(updateClaimsIsLoadingFromClaimsSavingData({ isLoading: false }));
        Utils.unblockUI();
        return;
      };

      this.store.dispatch(updateClaimsIsLoadingFromClaimsSavingData({ isLoading: true }));
      this.entityRiskData.startSavingForms();
      const riskId = this.entityRiskData.getRiskId() ?? this.summaryData.SummaryForm.get('riskId').value;

      const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;

      const saveClaimsRequest: SaveClaimsDTO = {
        riskId: riskId,
        riskDetailId: riskDetailId,
        riskClaims: []
      };

      const currentDate = this.authService.getCustomDate(true);
      this.claimsData.claimList = Utils.generateUniquerCreatedDate(this.claimsData.claimList, currentDate);
      this.claimsData.claimList = _.orderBy(this.claimsData.claimList, ['createdDate'], ['asc']);

      saveClaimsRequest.riskClaims = _.map(this.claimsData.claimList.filter(a => !a.isFromParent), (item) => {
        const id = uuidv4();

        return new RiskClaimDTO({
          riskDetailId: !item.riskDetailId ? riskDetailId : item.riskDetailId,
          id: item.id !== null ? item.id : id,
          description: this.sanitizeRequiredField(item.additionalDescription, this.claimsData.claimsConstants.noDescription),
          claimType: item.claimType,
          amount: item.claimAmount,
          notes: null,
          claimSource: item.claimSource,
          chargeableNonChargable: item.claimChargeableVsNonChargeable,
          otherQuestion: null,
          lossDate: isFirstSaveFromCompRater ? item.lossDate?.singleDate.jsDate.toUTCString()
            : item.lossDate?.singleDate.jsDate.toLocaleDateString('en-US'),
          catCode: this.sanitizeRequiredField(item.catCode, this.claimsData.claimsConstants.noCATCode),
          claimStatus: item.claimStatus,
          policyType: item.policyType,
          isDispute: item.dispute,
          claimNumber: item.claimNumber,
          individualMatchType: item.individualMatchType,
          isAPlus: item.isAPlus ? item.isAPlus : false,
          isActive: true,
          createdDate: item.createdDate,
          isAdd: item.id !== null ? item.isAdd : true,
          isComparative: item.isComparative,
          isLossAssociatedToHome: item.isLossAssociatedToHome,
          isLossAssociatedToInsured: item.isLossAssociatedToInsured,
          totalOutstandingReserve: item.totalOutstandingReserve,
          totalRecoveriesAndReimbursement: item.totalRecoveriesAndReimbursement,
          pcsCode: item.pcsCode,
          isExternal: item.isExternal
        });
      });

      if (riskId && riskDetailId) {
        this.postClaims(saveClaimsRequest);
      }
    } catch (error) {
      Utils.unblockUI();
      this.quickQuoteService.setCategoryToNotSavingState(SavingFlagConstants.claims);
      this.entityRiskData.apiCallCompleted.next(false);
      this.entityRiskData.finishSavingForms();
      this.store.dispatch(updateClaimsIsLoadingFromClaimsSavingData({ isLoading: false }));
      this.unmarkClaimsForSaving();
      NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
    }
  }

  postClaims(saveClaimsRequest: SaveClaimsDTO): void {
    this.quickQuoteService.setCategoryToSavingState(SavingFlagConstants.claims);

    this.quickQuoteService.postClaims(saveClaimsRequest).pipe(takeUntil(this.stop$)).subscribe(res => {
      const effectiveDate = new Date(this.applicantData.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate);
      this.quickQuoteService.setCategoryToNotSavingState(SavingFlagConstants.claims);
      this.claimsData.populateClaimsData(res.riskClaims, effectiveDate, this.endorsementsData.endorsementsGroup);
      this.claimsData.riskClaims = [];
      this.claimsData.riskClaims = res.riskClaims;
      this.entityRiskData.finishSavingForms();
      this.updateCollectionID(res);
      this.summaryData.isCalcPremium.next(true);
      const policyNumber = this.entityRiskData?.EntityRisk?.risks[0]?.policyNumber ?? '';
      this.raterData.claimsRequest(this.claimsData, policyNumber);
      this.unmarkClaimsForSaving();
      this.store.dispatch(updateClaimsIsLoadingFromClaimsSavingData({ isLoading: false }));
      this.store.dispatch(updateClaimsListFromClaimsSavingData({ list: res.riskClaims }));
      NotifUtils.showConsoleSuccess(`${PathConstants.Submission.Submission.Claims} ${InfoMessageConstant.savedSuccessfullyMessage}`);
      Utils.unblockUI();
      this.entityRiskData.apiCallCompleted.next(true);
      this.submissionData.claimsSavingComplete.next(true);
    },
      err => {
        this.entityRiskData.finishSavingForms();
        this.quickQuoteService.setCategoryToNotSavingState(SavingFlagConstants.claims);
        this.summaryData.isCalcPremium.next(false);
        this.store.dispatch(updateClaimsIsLoadingFromClaimsSavingData({ isLoading: false }));
        this.toast.error(ErrorMessageConstant.savingErrorMessage);
        Utils.unblockUI();
      }
    );
  }

  savePolicyClaims() {
    try {

      if (!this.checkIfClaimsForSaving()) {
        this.entityRiskData.apiCallCompleted.next(true);
        return;
      };

      this.entityRiskData.startSavingForms();
      this.quickQuoteService.setCategoryToSavingState(SavingFlagConstants.claims);
      const apiClaimsSave$ = [];
      const riskId = this.entityRiskData.getRiskId();
      const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;

      this.claimsData.claimList.forEach(item => {
        if (item.riskDetailId === undefined) {
          item.riskDetailId = riskDetailId;
        }
      });

      const policyClaimsRiskDetailIds = this.claimsData.claimList?.map(x => x.riskDetailId)
          .filter((value, index, self) => self.indexOf(value) === index);

      const currentRiskIdIndex = _.findIndex(policyClaimsRiskDetailIds, id => id === riskDetailId);
      if (currentRiskIdIndex === -1) {
        policyClaimsRiskDetailIds.push(riskDetailId);
      }

      policyClaimsRiskDetailIds.forEach(claimRiskDetailId => {
        const saveClaimsRequest: SaveClaimsDTO = {
          riskId: riskId,
          riskDetailId: claimRiskDetailId === undefined ? riskDetailId : claimRiskDetailId,
          riskClaims: []
        };

        const currentDate = this.authService.getCustomDate(true);
        this.claimsData.claimList = Utils.generateUniquerCreatedDate(this.claimsData.claimList, currentDate);
        this.claimsData.claimList = _.orderBy(this.claimsData.claimList, ['createdDate'], ['asc']);

        saveClaimsRequest.riskClaims = _.map(this.claimsData.claimList.filter(a =>a.riskDetailId === claimRiskDetailId), (item) => {
          return new RiskClaimDTO({
            riskDetailId: !item.riskDetailId ? riskDetailId : item.riskDetailId,
            id: item.id,
            description: this.sanitizeRequiredField(item.additionalDescription, this.claimsData.claimsConstants.noDescription),
            claimType: item.claimType,
            amount: item.claimAmount,
            notes: null,
            claimSource: item.claimSource,
            chargeableNonChargable: item.claimChargeableVsNonChargeable,
            otherQuestion: null,
            lossDate: item.lossDate?.singleDate.jsDate.toLocaleDateString('en-US'),
            catCode: this.sanitizeRequiredField(item.catCode, this.claimsData.claimsConstants.noCATCode),
            claimStatus: item.claimStatus,
            policyType: item.policyType,
            isDispute: item.dispute,
            claimNumber: item.claimNumber,
            individualMatchType: item.individualMatchType,
            isAPlus: item.isAPlus ? item.isAPlus : false,
            isActive: item.isActive,
            createdDate: item.createdDate,
            isAdd: item.isAdd,
            isComparative: item.isComparative,
            isLossAssociatedToHome: item.isLossAssociatedToHome,
            isLossAssociatedToInsured: item.isLossAssociatedToInsured,
            totalOutstandingReserve: item.totalOutstandingReserve,
            totalRecoveriesAndReimbursement: item.totalRecoveriesAndReimbursement,
            pcsCode: item.pcsCode
          });
        });

        if (riskId && riskDetailId) {
          Utils.blockUI();
          apiClaimsSave$.push(this.callApiClaimsPolicy(saveClaimsRequest));
        }
      });

      if (apiClaimsSave$.length !== 0) {
        Observable.forkJoin(apiClaimsSave$).subscribe(() => {
            Utils.unblockUI();
            this.entityRiskData.finishSavingForms();
            this.quickQuoteService.setCategoryToNotSavingState(SavingFlagConstants.claims);
            this.unmarkClaimsForSaving();
            this.summaryData.isCalcPremium.next(true);
            const policyNumber = this.entityRiskData?.EntityRisk?.risks[0]?.policyNumber ?? '';
            this.raterData.claimsRequest(this.claimsData, policyNumber);
            this.entityRiskData.apiCallCompleted.next(true);
            NotifUtils.showConsoleSuccess(`${PathConstants.Submission.Submission.Claims} ${InfoMessageConstant.savedSuccessfullyMessage}`);
          },error => {
            this.unmarkClaimsForSaving();
            Utils.unblockUI();
          });
      } else {
        Utils.unblockUI();
        this.unmarkClaimsForSaving();
        this.quickQuoteService.setCategoryToNotSavingState(SavingFlagConstants.claims);
      }
    } catch (error) {
      this.entityRiskData.apiCallCompleted.next(false);
      this.quickQuoteService.setCategoryToNotSavingState(SavingFlagConstants.claims);
      this.entityRiskData.finishSavingForms();
      NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
    }
  }

  callApiClaimsPolicy(payload: SaveClaimsDTO): Observable<any> {
    this.policySummaryData.saveClaimsComplete$.next(false);

    return this.quickQuoteService.postClaims(payload).pipe(takeUntil(this.stop$)).map(res => {
      const effectiveDate = new Date(this.applicantData.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate);

      this.claimsData.populateClaimsData(res.riskClaims, effectiveDate, this.endorsementsData.endorsementsGroup);
      this.claimsData.riskClaims = [];
      this.claimsData.riskClaims = res.riskClaims;
      this.updateCollectionID(res);
      this.policySummaryData.saveClaimsComplete$.next(true);
      this.submissionData.claimsSavingComplete.next();
    },
      err => {
        Utils.unblockUI();
        this.entityRiskData.finishSavingForms();
        this.summaryData.isCalcPremium.next(false);
      }
    );
  }

  public sanitizeRequiredField(field: any, defaultValue: string): string {
    const sanitized = field ? field : `${defaultValue}`;
    return sanitized;
  }

  updateCollectionID(response: any): void {
    try {

      if (this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId())) {
        this.policySummaryData.subStatusCode$.next(response.riskSubStatusCode as RiskStatusCode);
      }
    } catch (e) {
      this.unmarkClaimsForSaving();
    }
  }

  markClaimsForSaving() {
    this.claimsData.isClaimsForSaving = true;
  }

  unmarkClaimsForSaving() {
    this.claimsData.isClaimsForSaving = false;
    this.claimsDoneSavingEmitter$.next(true);
  }

  checkIfClaimsForSaving(): boolean {
    return this.claimsData.isClaimsForSaving;
  }

  saveClaims(isFirstSaveFromCompRater: boolean = false): void {
    if (this.policySummaryData.isPolicy) {
      this.savePolicyClaims();
    } else {
      this.saveSubmissionClaims(isFirstSaveFromCompRater);
    }
  }
}
