export class TorrentRequestDTO {
  riskId?: string;
  riskDetailId?: string;
  coverageA?: number;
  coverageC?: number;
  yearBuilt?: string;
  structureType?: string;
  foundationType?: string;
  propertyUsage?: string;
  numberOfStories?: string;
  firstName?: string;
  lastName?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  purchaseDate?: string;
  mailingAddress?: string;
  mailingAddressCity?: string;
  mailingAddressState?: string;
  mailingAddressZipCode?: string;
  contactNumber?: string;
  contactEmailAddress?: string;
  replacementCost?: number;
  formType?: string;
  public constructor(init?: Partial<TorrentRequestDTO>) {
    Object.assign(this, init);
  }
}