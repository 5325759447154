import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EventEmitter, Injectable } from '@angular/core';
import { BaseClass } from '../../shared/base-class';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { ValidationService } from '../../core/services/validation.service';
import { AgentService } from '../../core/services/management/agent-service';
import { forkJoin, Subject } from 'rxjs';
import NotifUtils from '../../shared/utilities/notif-utils';
import Utils from '../../shared/utilities/utils';
import { ErrorMessageConstant } from '../../shared/constants/error-message.constants';
import { RaterPremiumService } from '../../core/services/submission/rater-premium/rater-premium.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { ComparativeMappedPayload, ComparativeRate, ComparativeRequestPayload } from '../../shared/models/rater-premium/comparativeRate.model';
import { LoginLabelsConstants } from '../../shared/constants/login.labels.constants';
import { QuoteService } from '../../core/services/quote.service';
import { EndorsementsData } from '../submission-management/data/endorsements.data';
import { BasePropertyDataModel, ResidentialResponseDTO } from '../../shared/models/data/dto/third-party/residential-response.dto';
import { LvBceg, LvConstructionType, LvInternalPressureDesign, LvMitigationForm, LvRaterRoofToWallAttachment, LvRoofMaterial,
    LvMonthsOccupied, LvNumberOfStories, LvProtectionClass, LvRaterExteriorWallFinish, LvRaterFoundationType, LvRaterOpeningProtection,
    LvRaterPrimaryHeatType, LvRaterPropertyUsage, LvRaterRoofCover, LvRaterRoofDeckAttachment, LvRaterRoofShape, LvRaterWindDesignSpeed,
    LvRaterTerrain, LvStructureType, StructureTypeTownhouseInteriorUnit, StructureTypeTownhouseEndUnit, LvNumberOfUnitsBetweenFireWalls } from '../../shared/constants/property.options.constants';
import { Iso360ValueMappingData } from '../submission-management/data/iso360value-mapping.data';
import { FormTypeConstants } from '../../shared/constants/form-types.constants';
import { CoveragesData } from '../submission-management/data/coverages.data';
import { PropertyData } from '../submission-management/data/property.data';
import { SubmissionDefaultData } from '../submission-management/data/submission-default.data';
import { SubmissionConstants } from 'app/shared/constants/submission.constants';
import { EstimatedCreditConstants, LvRaterCovBPercentage, LvRaterCovCPercentage, LvRaterCovELiabilityLimit, LvRaterCovFMedicalLimit, LvRaterFloodZone } from '../../shared/constants/coverages.options.constants';
import { LvFloodPolicyDuringPolicyPeriod, LvRaterBurglarProtection, LvRaterDeductibleHUR, LvRaterFireProtection, LvRaterOtherPerilDeductibleNHR, LvRaterSecuredCommunity, LvRaterSprinkler } from '../../shared/constants/quick-quote.options.constants';
import { LvCountries, LvMaritalStatuses, LvRelationshipToApplicant } from '../../shared/constants/applicant.options.constants';
import { RiskDiscountQuickQuoteDTO } from '../../shared/models/data/dto/quick-quote/risk-discount-quick-quote.dto';
import { ComparativeRaterConstants, raterDistanceToFireStation, raterElectricalType, raterFeetToHydrant, SchedulesTableName } from '../../shared/constants/rater.constant';
import { RiskScheduleDTO } from '../../shared/models/data/dto/quick-quote/risk-schedule.dto';
import { ClaimsData } from '../submission-management/data/claims.data';
import { ClaimsChargeableService } from 'app/core/services/submission/claims/claims-chargeable.service';
import { ClaimsModalModel } from 'app/shared/models/submission/claims/claims.model';
import { ClaimStatus, LossTypeList } from 'app/shared/constants/claims.options.constants';
import * as moment from 'moment';
import { RiskPropertyQuickQuoteDTO } from '../../shared/models/data/dto/quick-quote/risk-property-quick-quote.dto';
import { DistanceConstants } from '../../shared/constants/distance.constants';
import { AllResponseDTO } from '../../shared/models/data/dto/third-party/all-response.dto';
import { LvJewelryWatchesAndFursIncreasedAmount, LvOrdinanceLawOptions, LvRaterHomeComputerLimit, LvRaterLimitedFungiLimit, LvRaterLossAssessmentLimit,
    LvRaterScreenedEnclosureLimit, LvSilverwareGoldwarePewterwareIncreasedAmount, LvScreenedEnclosureOptions, LVRaterFungiIncreasedAmountCovLimit,
    LvNumberOfGolfCartOptions, LvRaterGolfCartOption} from '../../shared/constants/endorsements.options.constants';
import { EndorsementsLabelsConstants } from '../../shared/constants/endorsements.labels.constants';
import { LocationLabelConstants } from '../../shared/constants/property.labels.constants';
import { RiskCoverageQuickQuoteDTO } from '../../shared/models/data/dto/quick-quote/risk-coverage-quick-quote.dto';
import { ThirdPartyDataService } from 'app/core/services/submission/third-party/third-party-data.service';
import { EntityRiskDTO } from 'app/shared/models/data/dto/quick-quote/entity-risk.dto';
import { IAddressForCounty } from 'app/shared/models/data/dto/third-party/address-for-county.dto';
import { takeUntil } from 'rxjs/operators';
import { EntityRiskData } from '../submission-management/data/entity-risk.data';
import { GenericConstants } from '../../shared/constants/generic.constants';
import { ApplicantBusinessRules } from '../submission-management/business-rules/applicant.business-rules';
import { updateAppBrulDatesFromComparativeRaterData, updateAppIsLoadingFromComparativeRaterData } from 'app/store/app/app.actions';
import { Store } from '@ngrx/store';

const AGENTCLAIMSOURCE = 'CS1';
@Injectable({
    providedIn: 'root',
})
export class ComparativeRaterData extends BaseClass {
    loginForm: FormGroup;
    showLoginField: boolean = false;
    skipTwoAuthFactor: boolean = true;

    isComparativeResultMapped: boolean = false;
    isFromComparativeRater: boolean = false;
    comparativeDetails: ComparativeRequestPayload = null;
    comparativeMappedPayload: ComparativeMappedPayload = null;

    transactionId: string = '';
    username: string = '';
    password: string = '';

    referrenceCodeNotifier = new Subject<{ data: any; isComplete: boolean; hasError: boolean }>();
    formTypeConstants = FormTypeConstants;
    genericConstants = GenericConstants;

    isCountryUSA: boolean = false;
    isCoAppCountryUSA: boolean = false;
    isApplicantMailingAddressValid: boolean = false;
    isCoApplicantMailingAddressValid: boolean = false;
    public event: EventEmitter<any> = new EventEmitter<any>();

    private structureTypeList: Array<any> = [];

    constructor(private fb: FormBuilder,
        private router: Router,
        private authService: AuthService,
        private validationService: ValidationService,
        private agentService: AgentService,
        private raterService: RaterPremiumService,
        private storage: LocalStorageService,
        private quoteService: QuoteService,
        private endorsementsData: EndorsementsData,
        private iso360ValueMapping: Iso360ValueMappingData,
        private coveragesData: CoveragesData,
        private propertyData: PropertyData,
        private submissionDefaultData: SubmissionDefaultData,
        private claimsData: ClaimsData,
        private claimsChargeableService: ClaimsChargeableService,
        private thirdPartyDataService: ThirdPartyDataService,
        public entityRiskData: EntityRiskData,
        public applicantBusinessRules: ApplicantBusinessRules,
        private store: Store
    ) {
        super();
    }

    get form() { return this.loginForm.controls; }

    initiateFormFields(): FormGroup {
        this.loginForm = this.fb.group({
            username: [{ value: '', disabled: true }, Validators.required],
            password: ['', Validators.required]
        });

        return this.loginForm;
    }

    userLogin(username: string, password: string): void {
        this.authService.login(username, password)
            .subscribe(_ => {
                if (this.authService.isPasswordChanged === true && this.authService.isChangePasswordAtLogin === false) {
                    if (this.authService.agencyId && this.authService.subagencyId) {
                        forkJoin([this.agentService.getAgenciesById(this.authService.agencyId), this.agentService.getSubAgenciesById(this.authService.subagencyId)])
                            .subscribe(data => {
                                this.authService.agenciesInfo = data[0];
                                this.authService.subAgenciesInfo = data[1];
                                this.onLoginSuccessProcess(true, data[0].isActive && data[1].isActive);
                            }, err => {
                                NotifUtils.showError(JSON.stringify(err?.error));
                            });
                    } else {
                        this.onLoginSuccessProcess();
                    }
                }
            },
                err => {
                    this.store.dispatch(updateAppIsLoadingFromComparativeRaterData({ isLoading: false }));
                    const message = err?.error?.error.toLowerCase().includes(LoginLabelsConstants.errorMessage.incorrect) ? LoginLabelsConstants.errorMessage.incorrectPassword : err?.error?.error;
                    if (message === LoginLabelsConstants.errorMessage.incorrectPassword) {
                        NotifUtils.showError(message, () => {
                            this.showLoginField = true;
                            this.loginForm.get('username').setValue(this.username);
                        });
                    } else {
                        const isLocked = err?.error?.error.toLowerCase().includes(LoginLabelsConstants.errorMessage.locked);
                        if (isLocked) {
                            NotifUtils.showError(message);
                            this.resetLoginCredentials(false);
                            this.showLoginField = true;
                            this.loginForm.get('username').setValue(this.username);
                        } else {
                            NotifUtils.showErrorWithoutConfirmation(message);
                            this.resetLoginCredentials(true);
                            sessionStorage.removeItem('comparativeCredential');
                        }
                    }
                    this.resetLoginCredentials(false);
                }
            );
    }

    onLoginSuccessProcess(isAgent: boolean = false, isValid: boolean = true): void {
        localStorage.setItem('loginDate', JSON.stringify(this.authService.LoginDateFormGroup.get('loginDate').value));
        this.authService.getServerDate().subscribe(result => {
            // FormGroup SetValue
            const ipxDate = result?.brulDate?.ipxDate;
            const new110 = result?.brulDate?.new110;
            const uwr26Update = result?.brulDate?.uwR26Update;
            const newRaterVersionDate = result?.brulDate?.newRaterVersionDate;
            const limitedTheftCovChargingStartDate = result?.brulDate?.newLimitedTheftCoverageCharging;
            const rspsDate_nb = result?.brulDate?.rspsDate_NB;
            const rspsDate_rb = result?.brulDate?.rspsDate_RB;
            const triggerInactivityTimer = result?.inactivityTimeout?.triggerInactivityTimer;
            const activateInactivityTimer = result?.inactivityTimeout?.activateInactivityTimer;
            const frreDate_nb = result?.brulDate?.frreDate_NB;
            const frreDate_rb = result?.brulDate?.frreDate_RB;
            this.authService.LoginDateFormGroup.get('UTCServerDate').setValue(result.currentServerDate);
            this.authService.LoginDateFormGroup.get('ESTServerDate').setValue(result.currentServerDateProper);
            this.authService.LoginDateFormGroup.get('IPXDate').setValue(ipxDate);
            this.authService.LoginDateFormGroup.get('New110').setValue(new110);
            this.authService.LoginDateFormGroup.get('UWR26Update').setValue(uwr26Update);
            this.authService.LoginDateFormGroup.get('NewRaterVersionDate').setValue(newRaterVersionDate);
            this.authService.LoginDateFormGroup.get('NewLimitedTheftCoverageCharging').setValue(limitedTheftCovChargingStartDate);
            this.authService.LoginDateFormGroup.get('RSPSDate_NB').setValue(rspsDate_nb);
            this.authService.LoginDateFormGroup.get('RSPSDate_RB').setValue(rspsDate_rb);
            this.authService.LoginDateFormGroup.get('TriggerInactivityTimer').setValue(triggerInactivityTimer);
            this.authService.LoginDateFormGroup.get('ActivateInactivityTimer').setValue(activateInactivityTimer);
            this.authService.LoginDateFormGroup.get('FRREDate_NB').setValue(frreDate_nb);
            this.authService.LoginDateFormGroup.get('FRREDate_RB').setValue(frreDate_rb);

            // LocalStorage Set Value
            localStorage.setItem('UTCServerDate', result.currentServerDate);
            localStorage.setItem('ESTServerDate', result.currentServerDateProper);
            localStorage.setItem('IPXDate', ipxDate);
            localStorage.setItem('New110', new110);
            localStorage.setItem('UWR26Update', uwr26Update);
            localStorage.setItem('NewRaterVersionDate', newRaterVersionDate);
            localStorage.setItem('NewLimitedTheftCoverageCharging', limitedTheftCovChargingStartDate);
            localStorage.setItem('RSPSDate_NB', rspsDate_nb);
            localStorage.setItem('RSPSDate_RB', rspsDate_rb);
            localStorage.setItem('TriggerInactivityTimer', triggerInactivityTimer);
            localStorage.setItem('ActivateInactivityTimer', activateInactivityTimer);
            localStorage.setItem('FRREDate_NB', frreDate_nb);
            localStorage.setItem('FRREDate_RB', frreDate_rb);

            this.store.dispatch(updateAppBrulDatesFromComparativeRaterData({ brulDates: result }));
            this.authService.serverDates.next(this.authService.LoginDateFormGroup);
          });

        if ((isAgent && isValid) || !isAgent) {
            this.callUserTwoFactorAuth();
        } else {
            Utils.unblockUI();
            this.authService.loginAuditLog(ErrorMessageConstant.agencySubAgencyInactive, this.form.username.value);
            NotifUtils.showError(LoginLabelsConstants.errorMessage.incorrectUsernamePassword);
        }
    }

    callUserTwoFactorAuth(): void {
        const uname = this.username ? this.username : this.form?.username?.value;
        this.referrenceCodeNotifier.next({ data: null, isComplete: false, hasError: false });
        this.validationService.getUserTwoFactorAuth(uname).subscribe(result => {
            Utils.unblockUI();
            const modalData = { referenceNumber: result, username: uname };
            this.referrenceCodeNotifier.next({ data: modalData, isComplete: true, hasError: false });
        }, err => {
            this.referrenceCodeNotifier.next({ data: null, isComplete: true, hasError: true });
            console.log(err);
            Utils.unblockUI();
        });
    }

    resetLoginCredentials(resetAll: boolean = false): void {
        if (resetAll) {
            this.username = '';
            this.transactionId = '';
        }
        this.password = '';
    }

    resetComparativeVariables(): void {
        this.transactionId = '';
        this.comparativeDetails = null;
        this.isFromComparativeRater = false;
        this.isComparativeResultMapped = false;
        this.coveragesData.isComparativeCoverageMapped = false;
        this.comparativeMappedPayload = null;
        this.coveragesData.isActiveCentauriHOPolicyFromComparativeRater = false;
    }

    getComparativeRate(transactionId: string, username: string): void {
        let applicantDetails: Array<any> = [];
        Utils.blockUI();
        this.raterService.getComparativeRate(transactionId).subscribe((result: ComparativeRate) => {
            if (result.requestPayload && result.mappedPayload) {
                const comparativeResult = JSON.parse(result.requestPayload);
                const comparativeMapped = JSON.parse(result.mappedPayload);
                applicantDetails = JSON.parse(result.requestPayload).applicant;
                applicantDetails.forEach(applicant => {
                    if (applicant.applicantType === 1) {
                        const compRaterDetails = {
                            form: comparativeResult.policy.form,
                            transactionId: this.transactionId,
                            isFromCompRater: true,
                            request: comparativeResult,
                            mapped: comparativeMapped
                        };
                        this.storage.store('comparativeRater', compRaterDetails);
                        this.quoteService.formType = comparativeResult.policy.form;
                        this.quoteService.masterProgramType = ComparativeRaterConstants.defaultProgramType;
                        this.router.navigate(['/submissions/new/quickquote/applicant-page']);
                        sessionStorage.removeItem('comparativeCredential');
                        this.authService.logUserLogin(username).subscribe(() => {
                            return;
                        });
                    }
                });
            }
        }, err => {
            Utils.unblockUI();
            NotifUtils.showError(err?.error?.errors[0].message);
        });
    }

    initial360ValueFromComparative(comparativeDetails: ComparativeRequestPayload): void {
        this.propertyData.squareFootage360Value = comparativeDetails?.property?.sqFootage?.toString(); // initial sqft from comparative
        const initial360ValueForm = this.propertyData.iso360ValueInitialValueForm;
        initial360ValueForm.get('exteriorWallFinish360DefaultCode').setValue(comparativeDetails?.property?.exteriorWall);
        initial360ValueForm.get('foundationType360DefaultCode').setValue(comparativeDetails?.property?.foundationType);
        initial360ValueForm.get('roofMaterial360DefaultCode').setValue(comparativeDetails?.property?.roofMaterial);
        initial360ValueForm.get('roofShape360DefaultCode').setValue(comparativeDetails?.property?.roofShape);
    }

    mapIso360ValueFromComparative(comparativeDetails: ComparativeRequestPayload): ResidentialResponseDTO {
        return new ResidentialResponseDTO({
            yearBuilt: comparativeDetails?.property?.yearBuilt?.toString(),
            squareFootage: comparativeDetails?.property?.sqFootage?.toString(),
            valuationId: '',
            replacementCost: this.quoteService.formType === this.formTypeConstants.HO4 ?
                '0' : comparativeDetails?.coverages?.find(cov => cov.coverageID === 'DWELL').limit?.toString(),
            replacementCostDate: '',
            pricingChanged: '',
            latitude: '',
            longitude: '',
            numberOfStories: new BasePropertyDataModel({
                iso360ValueCode: comparativeDetails?.property?.noofStories  ?? '',
                code: comparativeDetails?.property?.noofStories  ?? '',
                description: LvNumberOfStories.find(d => d.code === comparativeDetails?.property?.noofStories)?.description  ?? '',
            }),
            exteriorWallFinish: new BasePropertyDataModel({
                iso360ValueCode: this.iso360ValueMapping.defaultExteriorWallFinishMapping.find(i => i.uiCode === comparativeDetails?.property?.exteriorWall)?.iso360Code ?? '',
                code: comparativeDetails?.property?.exteriorWall ?? '',
                description: LvRaterExteriorWallFinish.find(d => d.code === comparativeDetails?.property?.exteriorWall)?.description ?? '',
            }),
            construction: new BasePropertyDataModel({
                iso360ValueCode: this.iso360ValueMapping.defaultConstructionTypeMapping.find(i => i.uiCode === comparativeDetails?.property?.constructionType)?.iso360Code ?? '',
                code: comparativeDetails?.property?.constructionType ?? '',
                description: LvConstructionType.find(d => d.code === comparativeDetails?.property?.constructionType)?.description ?? '',
            }),
            roofShape: new BasePropertyDataModel({
                iso360ValueCode: comparativeDetails?.property?.roofShape ?? '',
                code: comparativeDetails?.property?.roofShape ?? '',
                description: LvRaterRoofShape.find(d => d.code === comparativeDetails?.property?.roofShape)?.description ?? '',
            }),
            foundationType: new BasePropertyDataModel({
                iso360ValueCode: this.iso360ValueMapping.defaultFoundationTypeMapping.find(i => i.uiCode === comparativeDetails?.property?.foundationType)?.iso360Code  ?? '',
                code: comparativeDetails?.property?.foundationType ?? '',
                description: LvRaterFoundationType.find(d => d.code === comparativeDetails?.property?.foundationType)?.description ?? '',
            }),
            structureType: new BasePropertyDataModel({
                iso360ValueCode: comparativeDetails?.property?.structureType  ?? '',
                code: comparativeDetails?.property?.structureType ?? '',
                description: LvStructureType.find(d => d.code === comparativeDetails?.property?.structureType)?.description ?? '',
            }),
            roofMaterial: new BasePropertyDataModel({
                iso360ValueCode: this.iso360ValueMapping.defaultRoofMaterialMapping.find(i => i.uiCode === comparativeDetails?.property?.roofMaterial)?.iso360Code ?? '',
                code: comparativeDetails?.property?.roofMaterial ?? '',
                description: LvRoofMaterial.find(d => d.code === comparativeDetails?.property?.roofMaterial)?.description ?? '',
            }),
            isNoHitAddress: true,
            costPerSqft: ''
        });
    }

    mapComparativeRaterResult(iso360Value: ResidentialResponseDTO,
        propertyLocationDetailsForm: FormGroup, propertyPropertyDetailsForm: FormGroup,
        replacementCost: number): void {

        const comparativePropertyDetails = this.comparativeDetails?.property;
        const isNoHitAddress = iso360Value?.isNoHitAddress;

        const yearBuiltMappingValue = this.mapThirdPartyOrComparativeValue(isNoHitAddress, iso360Value?.yearBuilt, comparativePropertyDetails?.yearBuilt?.toString());
        this.mapComparativeYearBuiltToYOC(yearBuiltMappingValue);

        propertyPropertyDetailsForm.get('squareFootage').setValue(
            this.mapThirdPartyOrComparativeValue(isNoHitAddress, iso360Value?.squareFootage, comparativePropertyDetails?.sqFootage?.toString()));

        propertyPropertyDetailsForm.get('numOfStories').setValue(
            this.mapThirdPartyOrComparativeValue(isNoHitAddress, iso360Value?.numberOfStories?.code, comparativePropertyDetails?.noofStories, SubmissionConstants.numberOfStoriesAllForms));

        propertyPropertyDetailsForm.get('exteriorWallFinish').setValue(
            this.mapThirdPartyOrComparativeValue(isNoHitAddress, iso360Value?.exteriorWallFinish?.code, comparativePropertyDetails?.exteriorWall));
        propertyPropertyDetailsForm.get('exteriorWallFinish').markAsTouched();

        propertyPropertyDetailsForm.get('constructionType').setValue(
            this.mapThirdPartyOrComparativeValue(isNoHitAddress, iso360Value?.construction?.code, comparativePropertyDetails?.constructionType));
        propertyPropertyDetailsForm.get('constructionType').markAsTouched();

        propertyPropertyDetailsForm.get('roofMaterial').setValue(
            this.mapThirdPartyOrComparativeValue(isNoHitAddress, iso360Value?.roofMaterial?.code, comparativePropertyDetails?.roofMaterial));
        propertyPropertyDetailsForm.get('roofMaterial').markAsTouched();

        if (propertyPropertyDetailsForm.get('roofMaterial').value.indexOf('TI') !== -1) {
            propertyPropertyDetailsForm.get('isFlatTile').setValue(this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3 ?
                comparativePropertyDetails?.flatTile ?? false : false);
        }

        propertyPropertyDetailsForm.get('roofShape').setValue(
            this.mapThirdPartyOrComparativeValue(isNoHitAddress, iso360Value?.roofShape?.code?.trim(), comparativePropertyDetails?.roofShape));
        propertyPropertyDetailsForm.get('roofShape').markAsTouched();

        if (this.quoteService.formType !== this.formTypeConstants.HO6) {
            propertyPropertyDetailsForm.get('foundationType').setValue(
                this.mapThirdPartyOrComparativeValue(isNoHitAddress, iso360Value?.foundationType?.code, comparativePropertyDetails?.foundationType, SubmissionConstants.foundationTypeAllForms));
            this.propertyData.showFoundationElevation = this.propertyData.setFoundationType(propertyPropertyDetailsForm.get('foundationType').value);
            propertyPropertyDetailsForm.get('foundationType').markAsTouched();
        }

        if (this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3) { // if HO3 OR DPR set replacementCost Value
            propertyPropertyDetailsForm.get('replacementCost').setValue(replacementCost ?? '');
            if (iso360Value?.replacementCostDate &&
                !(propertyPropertyDetailsForm.get('replacementCostDate').value)) {
                const replacementCostDate = new Date(iso360Value.replacementCostDate);
                propertyPropertyDetailsForm.get('replacementCostDate').setValue({
                    singleDate: {
                        date: {
                            year: replacementCostDate.getFullYear(),
                            month: replacementCostDate.getMonth() + 1,
                            day: replacementCostDate.getDate()
                        },
                        jsDate: replacementCostDate
                    }
                });
            }
        }

        const validIso360StructureType = this.structureTypeList.find(d => d.code === iso360Value?.structureType?.code)?.code ?? '';
        propertyLocationDetailsForm.get('structureType').setValue(
            this.mapThirdPartyOrComparativeValue(isNoHitAddress, validIso360StructureType, comparativePropertyDetails?.structureType, this.submissionDefaultData.getStructureTypeDefault()));
        if ((propertyLocationDetailsForm.get('structureType').value === StructureTypeTownhouseEndUnit || propertyLocationDetailsForm.get('structureType').value === StructureTypeTownhouseInteriorUnit)
            && (this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3)) {
                propertyLocationDetailsForm.get('numberOfUnitsFirewalls').setValue(LvNumberOfUnitsBetweenFireWalls.find(x => x.raterCode === comparativePropertyDetails?.numUnitsBetweenFirewall)?.code ?? null);
        }
        propertyLocationDetailsForm.get('valuationId').setValue(iso360Value?.valuationId ?? '');
        propertyLocationDetailsForm.get('latitude').setValue(iso360Value?.latitude ?? '');
        propertyLocationDetailsForm.get('longitude').setValue(iso360Value?.longitude ?? '');
        this.propertyData.mapRoofGeometry(propertyPropertyDetailsForm.get('roofShape').value);

        this.isComparativeResultMapped = isNoHitAddress;
        this.mapPropertyFieldsHitOrNonhit(iso360Value);
    }

    mapThirdPartyOrComparativeValue(isNoHitAddress, thirdPartyValue, comparativeValue, defaultValue = ''): string | any {
        if (isNoHitAddress) {
            return comparativeValue || '';
        }
        return thirdPartyValue || defaultValue || comparativeValue;
    }

    mapComparativeYearBuiltToYOC(yearbuilt: string) {
        const propertyPropertyDetailsForm = this.propertyData.propertyDetails;
        this.propertyData.validYearBuilt = Number(yearbuilt);
        // if current year built value is the same as ISO360 year built
        // do not prefill YOC values
        if (Number(propertyPropertyDetailsForm.get('yearBuilt').value) !== Number(yearbuilt)) {
            propertyPropertyDetailsForm.get('yearRoof').setValue(this.quoteService.formType !== this.formTypeConstants.HO4 ? yearbuilt : null);
            propertyPropertyDetailsForm.get('yearRoof').markAsTouched();
            propertyPropertyDetailsForm.get('yearElectrical').setValue(yearbuilt);
            propertyPropertyDetailsForm.get('yearPlumbing').setValue(yearbuilt);
            propertyPropertyDetailsForm.get('yearHeating').setValue(yearbuilt);
            propertyPropertyDetailsForm.get('yearCentralAirConditioning').setValue(yearbuilt);
        }
        propertyPropertyDetailsForm.get('yearBuilt').setValue(yearbuilt);
        propertyPropertyDetailsForm.get('yearBuilt').markAsTouched();
    }

    mapEndorsementsFromComparative(applicantForm: FormGroup): void {
        const endorsementsDetails = this.comparativeDetails?.policy;
        const endorFromMappedPayload = this.comparativeMappedPayload;
        this.endorsementsData.initParentFormControls();
        this.endorsementsData.initChildFormControls();

        this.endorsementsData.endorsementsGroup.get('waterBackup').setValue(endorsementsDetails?.waterBackup ?? false);
        this.endorsementsData.endorsementsGroup.get('waterDamage').setValue(this.isBREND01(applicantForm) ?
            true :  endorsementsDetails?.waterDamageExcl ?? false);
        if (this.endorsementsData.endorsementsGroup.get('waterDamage').value) {
            this.endorsementsData.endorsementsGroup.get('limitedWater').setValue(endorsementsDetails?.limitedWaterDamage ?? false);
            if (this.endorsementsData.endorsementsGroup.get('limitedWater').value) {
                this.endorsementsData.currentLimitedWaterDmg = this.endorsementsData.endorsementsGroup.get('limitedWater').value;
                this.endorsementsData.endorsementsGroup.get('limitedWaterLimit').setValue(EndorsementsLabelsConstants.limitedWaterLimitAmount);
            }
        }
        this.endorsementsData.endorsementsGroup.get('personalProperty').setValue(endorsementsDetails?.persPropRepl ?? false);
        this.endorsementsData.endorsementsGroup.get('premisesAlarm').setValue(this.quoteService.formType === this.formTypeConstants.DP3 ?
            this.isBRCOV22() : endorsementsDetails?.premisesAlarm ?? false); // DP3 should follow BRUL
        this.endorsementsData.endorsementsGroup.get('sinkholeLoss').setValue(endorsementsDetails?.sinkholeLoss ?? false);

        if (this.quoteService.formType === this.formTypeConstants.HO3) {
            this.endorsementsData.endorsementsGroup.get('increasedReplacement').setValue(endorsementsDetails?.increasedReplacementCost);

            if (endorsementsDetails?.starPackage === 'S') {
                this.endorsementsData.endorsementsGroup.get('starPackage').setValue(true);
            } else if (endorsementsDetails?.starPackage === 'A') {
                this.endorsementsData.endorsementsGroup.get('starAdvantagePackage').setValue(true);
            } else {
                this.endorsementsData.endorsementsGroup.get('starPackage').setValue(false);
                this.endorsementsData.endorsementsGroup.get('starAdvantagePackage').setValue(false);
            }
        }

        if (this.quoteService.formType === this.formTypeConstants.HO4) {
            this.endorsementsData.endorsementsGroup.get('personalPropertyAtOtherResidences').setValue(endorsementsDetails?.persPropAtOtherResidences);
            this.endorsementsData.endorsementsGroup.get('personalPropertyAtOtherResidencesLimit').setValue(endorsementsDetails?.persPropAtOtherResidencesLimit);
        }

        if (this.quoteService.formType === this.formTypeConstants.HO6) {
            // Floating field, where to map 'unitOwnersCoverage'
            // this.endorsementsData.endorsementsGroup.get('unitOwnersCoverageASpecialCoverage').setValue(endorsementsDetails?.unitOwnersCoverage);

            const leaseTerms: any = [{ text: 'ST', value: 'Short Term' }, { text: 'LT', value: 'Long Term' }];
            const leaseTermValue = leaseTerms.find(l => l.text === endorsementsDetails?.unitOwnersRentalToOthers?.trim())?.value ?? '';
            if (Boolean(leaseTermValue)) {
                this.endorsementsData.endorsementsGroup.get('unitOwnersRentalToOthers').setValue(true);
                this.endorsementsData.endorsementsGroup.get('leaseTerm').setValue(leaseTermValue);
            }
        }

        if (this.quoteService.formType !== this.formTypeConstants.DP3) {
            this.endorsementsData.endorsementsGroup.get('personalInjury').setValue(endorsementsDetails?.personalInjuryCoverage);
            this.endorsementsData.endorsementsGroup.get('identityFraud').setValue(endorsementsDetails?.identityFraudCoverage);
            this.endorsementsData.endorsementsGroup.get('animalLiability').setValue(endorsementsDetails?.animalLiabilityCoverage);
            this.endorsementsData.endorsementsGroup.get('specialPersonal').setValue(endorsementsDetails?.specialPersProp);
            this.endorsementsData.endorsementsGroup.get('limitedFungiLimit').setValue(endorFromMappedPayload?.limitedFungiLimit ?
                LvRaterLimitedFungiLimit.find(x => x.code === Number(endorFromMappedPayload?.limitedFungiLimit))?.code ?? 10 : '');
            this.endorsementsData.endorsementsGroup.get('homeComputer').setValue(endorFromMappedPayload?.isHomeComputer);
            this.endorsementsData.endorsementsGroup.get('homeComputerLimit').setValue(endorFromMappedPayload?.homeComputerLimit ?
                LvRaterHomeComputerLimit.find(x => x.code === endorFromMappedPayload?.homeComputerLimit)?.code : '');

            if (endorsementsDetails?.golfCartCoverage && endorsementsDetails?.golfCartCoverage?.toUpperCase() !== 'NO') {
                const numberOfGolfCarts = endorsementsDetails?.numberOfGolfCarts > 3 ?
                   '3' : LvNumberOfGolfCartOptions.find(x => x.code === endorsementsDetails?.numberOfGolfCarts?.toString())?.code ?? '';
                this.endorsementsData.endorsementsGroup.get('golfCart').setValue(true);
                this.endorsementsData.endorsementsGroup.get('numberOfGolfCarts').setValue(numberOfGolfCarts);
                this.endorsementsData.endorsementsGroup.get('liabilityType').setValue(LvRaterGolfCartOption.find(x => x.code === endorsementsDetails?.golfCartCoverage)?.code ?? 'Option 2');
            }
        }

        if (this.quoteService.formType !== this.formTypeConstants.HO4) {
            this.endorsementsData.endorsementsGroup.get('equipmentBreakdown').setValue(endorsementsDetails?.equipmentBreakdownCoverage ?? false);
            this.endorsementsData.endorsementsGroup.get('ordinanceLawOrCov').setValue(endorFromMappedPayload?.isOrdinanceLaw ?? false);
            this.endorsementsData.endorsementsGroup.get('ordinanceLaw').setValue(endorFromMappedPayload?.ordinanceLaw ?
                LvOrdinanceLawOptions.find(x => x.code === endorFromMappedPayload?.ordinanceLaw)?.code : '');
            this.endorsementsData.endorsementsGroup.get('lossAssessment').setValue(endorFromMappedPayload?.isLossAssessment);
            this.endorsementsData.endorsementsGroup.get('lossAssessmentLimit').setValue(endorFromMappedPayload?.lossAssessmentLimit ?
                LvRaterLossAssessmentLimit.find(x => x.code === Number(endorFromMappedPayload?.lossAssessmentLimit))?.code : '');
        }

        if (this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3) {
            const screenedEnclosure = this.quoteService.formType === this.formTypeConstants.DP3 ? endorFromMappedPayload?.screenedEncCarportCoverage ?? '' : endorFromMappedPayload?.screenedEnclosure ?? '';
            const screenedEnclosureLimit = this.quoteService.formType === this.formTypeConstants.DP3 ? endorFromMappedPayload?.screenedEncLimit ?? '' : endorFromMappedPayload?.seLimit ?? '';

            this.endorsementsData.endorsementsGroup.get('screenedEnclosure').setValue(
                LvScreenedEnclosureOptions.find(x => x.code === screenedEnclosure?.toUpperCase())?.code ?? 'N');
            if (this.endorsementsData.endorsementsGroup.get('screenedEnclosure').value !== 'N'){
                this.endorsementsData.endorsementsGroup.get('screenedEnclosureLimit').setValue(screenedEnclosureLimit ?
                    LvRaterScreenedEnclosureLimit.find(x => x.code === screenedEnclosureLimit)?.code : '');
            }
            this.endorsementsData.endorsementsGroup.get('serviceLine').setValue(endorsementsDetails?.serviceLineEnhancement ?? false);
        }

        if (this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.HO6) {
            this.endorsementsData.endorsementsGroup.get('seasonalDwelling').setValue(endorsementsDetails?.seasonalDwelling ?? false);

            if (endorFromMappedPayload?.islLiability) {
                let isJewelryValid = false;
                let isSilverwareValid = false;
                this.endorsementsData.endorsementsGroup.get('coverageC').setValue(endorFromMappedPayload?.islLiability);

                if (LvJewelryWatchesAndFursIncreasedAmount.find(x => x.code === endorFromMappedPayload?.islJewelryLimit)) {
                    isJewelryValid = endorFromMappedPayload?.islJewelryLimit === 0 ?
                        LvJewelryWatchesAndFursIncreasedAmount.find(x => x.code.toString() === endorsementsDetails?.islJewelryWatchesFurs) ? true : false : true;
                }

                if (LvSilverwareGoldwarePewterwareIncreasedAmount.find(x => x.code === endorFromMappedPayload?.islSilverwareLimit)) {
                    isSilverwareValid = endorFromMappedPayload?.islSilverwareLimit === 0 ?
                        LvSilverwareGoldwarePewterwareIncreasedAmount.find(x => x.code.toString() === endorsementsDetails?.islSilverware) ? true : false : true;
                }

                if (isJewelryValid) {
                    this.endorsementsData.endorsementsGroup.get('jewelryIncreased').setValue(endorFromMappedPayload?.islJewelryLimit);
                }

                if (isSilverwareValid) {
                    this.endorsementsData.endorsementsGroup.get('silverwareIncreased').setValue(endorFromMappedPayload?.islSilverwareLimit);
                }
                this.getCoverageCTotal();
            }
        }

        if (this.quoteService.formType === this.formTypeConstants.DP3) {
            if (endorFromMappedPayload?.isLimitedFungi) {
                this.endorsementsData.endorsementsGroup.get('fungiWetOrDryRot').setValue(endorFromMappedPayload?.isLimitedFungi ?? false);
                this.endorsementsData.endorsementsGroup.get('fungiWetOrDryRotLimit').setValue(
                    LVRaterFungiIncreasedAmountCovLimit.find(x => x.code === Number(endorFromMappedPayload?.limitedFungiLimit))?.code ?? '');
            }

            this.endorsementsData.endorsementsGroup.get('personalLiability').setValue(this.coveragesData.coveragesAndDeductiblesForm.get('isCoverageLEnabled')?.value ? true : false);
            this.endorsementsData.endorsementsGroup.get('premisesLiability').setValue(this.isPremisesLiabilityMandatory()
                ? true : endorsementsDetails?.isPremisesLiability ?? false);
            if (endorsementsDetails?.isPremisesLiability) {
                this?.endorsementsData?.manualToggleValueForPremiseslLiability?.next(this?.endorsementsData?.endorsementsGroup?.get('premisesLiability')?.value);
            }

            if (endorsementsDetails?.isPermittedIncidentalOccupancies || endorsementsDetails?.permittedIncidentalOccupanciesLiability ) {
                this.endorsementsData.endorsementsGroup.get('permittedIncidentalOccupancies').setValue(true);
                this.endorsementsData.endorsementsGroup.get('descriptionOfOccupancy').setValue(endorsementsDetails?.occupancyDescription ?? '');
                this.endorsementsData.endorsementsGroup.get('permittedIncidentalOccupanciesLiability').setValue(true);
                this.endorsementsData.endorsementsGroup.get('descriptionOfBusiness').setValue(endorsementsDetails?.businessDescription ?? '');
                this.endorsementsData.endorsementsGroup.get('businessLocation').setValue(endorsementsDetails?.businessLocation ?? '');
            }

            if (this.isLimitedTheftCoverageAvailable()) {
                this.endorsementsData.endorsementsGroup.get('limitedTheftCoverage').setValue(endorsementsDetails?.isLimitedTheftDamage ?? false);
            }

            this.endorsementsData.endorsementsGroup.get('vacancyEndorsement').setValue(endorsementsDetails?.isVacancyEndorsement ?? false);

            if (this.endorsementsData.isWindStormExteriorVisibleIncluded(this.propertyData.locationDetails.get('territoryCode').value)) {
                this.endorsementsData.endorsementsGroup.get('windstormExteriorPaintoOrWaterproofingExclusionSeacoastFlorida').setValue(endorsementsDetails?.isWStormExtPaintOrWaterProofExclSeacoastFL ?? false);
            }
        }
    }

    getCoverageCTotal(): void {
        const jewelryValue = this.endorsementsData.endorsementsGroup.get('jewelryIncreased').value;
        const silverwareValue = this.endorsementsData.endorsementsGroup.get('silverwareIncreased').value;

        const jewelryTotal: number = jewelryValue === '0' ? 0 : jewelryValue === '' ? null : (Number(jewelryValue) + 1000);
        this.endorsementsData.endorsementsGroup.get('jewelryTotal').setValue(jewelryTotal);

        const silverwareTotal: number = silverwareValue === '0' ? 0 : silverwareValue === '' ? null : (Number(silverwareValue) + 2500);
        this.endorsementsData.endorsementsGroup.get('silverwareTotal').setValue(silverwareTotal);
    }

    getComparativeRateFromSubmission(transactionId: string): void {
        Utils.blockUI();
        this.raterService.getComparativeRate(transactionId).subscribe((result: ComparativeRate) => {
            if (result.requestPayload && result.mappedPayload) {
                this.comparativeDetails = JSON.parse(result.requestPayload);
                this.comparativeMappedPayload = JSON.parse(result.mappedPayload);
                this.getValidComparativeDropdownValues(this.comparativeDetails);
                this.event.emit(true);
            }
        }, err => {
            Utils.unblockUI();
            NotifUtils.showError(err?.error?.errors[0].message);
            this.event.emit(false);
        });
    }

    mapCoverageFromComparative(): boolean {
        const comparativeCoverageDetails = this.comparativeDetails?.coverages;
        const comparativePolicyDetails = this.comparativeDetails?.policy;

        if (this.quoteService.formType === FormTypeConstants.HO3) {
            this.coveragesData.coveragesAndDeductiblesForm.get('coverageB').setValue(comparativeCoverageDetails?.find(cov => cov.coverageID === 'OS').limit);
            this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').setValue(comparativeCoverageDetails?.find(cov => cov.coverageID === 'PP').limit);
            this.coveragesData.coveragesAndDeductiblesForm.get('coverageD').setValue(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').value * 0.1);
            this.setValidCovEandFValues();
        } else if (this.quoteService.formType === FormTypeConstants.HO4) {
            this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').setValue(comparativeCoverageDetails?.find(cov => cov.coverageID === 'PP').limit);
            this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').markAsTouched();
            this.setValidCovEandFValues();
        } else if (this.quoteService.formType === FormTypeConstants.HO6) {
            this.setValidCovEandFValues();
        } else if (this.quoteService.formType === FormTypeConstants.DP3) {
            this.coveragesData.coveragesAndDeductiblesForm.get('coverageB').setValue(comparativeCoverageDetails?.find(cov => cov.coverageID === 'OS')?.limit);
            this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').setValue(comparativeCoverageDetails?.find(cov => cov.coverageID === 'PP')?.limit);
            this.coveragesData.coveragesAndDeductiblesForm.get('coverageDorE').setValue(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').value * 0.1);

            if (this.comparativeMappedPayload?.covLLimit !== 0 && this.entityRiskData.isNewQuote) {
                this.coveragesData.coveragesAndDeductiblesForm.get('isCoverageLEnabled').setValue(true);
                this.coveragesData.coveragesAndDeductiblesForm.get('isCoverageMEnabled').setValue(true);
                this.coveragesData.coveragesAndDeductiblesForm.get('coverageL').setValue(this.comparativeMappedPayload?.covLLimit);
                this.coveragesData.coveragesAndDeductiblesForm.get('coverageM').setValue(this.comparativeMappedPayload?.covLLimit === 100000 ? 1000 : 5000);
            }
        }

        return this.calculateCoverageFromLimit();
    }

    calculateCoverageFromLimit(): boolean {
        const covA = this.quoteService.formType === this.formTypeConstants.HO4 ?
            0 : Number(this.comparativeDetails?.coverages?.find(cov => cov.coverageID === 'DWELL').limit);
        const covB = this.coveragesData.coveragesAndDeductiblesForm.get('coverageB').value;
        const covC = this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').value;
        let isCompMapped: boolean = false;

        if (this.quoteService.formType === FormTypeConstants.HO3 || this.quoteService.formType === FormTypeConstants.DP3) {
            const covBLimitValue = this.comparativeMappedPayload?.coverageBPercent ? this.comparativeMappedPayload?.coverageBPercent
                : Number(covB / covA) * 100;
            const isValidCovB = LvRaterCovBPercentage.find(b => b.code === covBLimitValue.toString())?.code;

            if (Boolean(isValidCovB)) {
                this.coveragesData.coveragesAndDeductiblesForm.get('coverageBLimitId').setValue(covBLimitValue);
            } else {
                let percentage = '' ;
                if (covBLimitValue === 0 ) {
                    percentage = '1';
                } else if (covBLimitValue > 2 && covBLimitValue < 5 ) {
                    percentage = '5';
                } else if (covBLimitValue > 5 && covBLimitValue < 10 ) {
                    percentage = '10';
                } else if (covBLimitValue > 10 && covBLimitValue < 15 ) {
                    percentage = '15';
                } else if (covBLimitValue > 15 ) {
                    percentage = '20';
                }

                this.setDefaultCoveragesValueByPercentage(Number(covA), 'coverageB', 'coverageBLimitId', percentage);
            }

            isCompMapped = true;
        } else {
            this.coveragesData.coveragesAndDeductiblesForm.get('coverageBLimitId').setValue('0');
        }

        if (this.quoteService.formType === FormTypeConstants.HO3) {
            const covCLimitValue = this.comparativeMappedPayload?.coverageCPercent ? this.comparativeMappedPayload?.coverageCPercent
                : Number(covC / covA) * 100;
            const isValidCovC = LvRaterCovCPercentage.find(c => c.code === covCLimitValue.toString())?.code;

            if (Boolean(isValidCovC)) {
                this.coveragesData.coveragesAndDeductiblesForm.get('coverageCLimitId').setValue(covCLimitValue);
            } else {
                let percentage = '' ;
                if (covCLimitValue < 25 ) {
                    percentage = '25';
                } else if (covCLimitValue > 25 && covCLimitValue < 30 ) {
                    percentage = '30';
                } else if (covCLimitValue > 30 && covCLimitValue < 35 ) {
                    percentage = '35';
                } else if (covCLimitValue > 35 && covCLimitValue < 40 ) {
                    percentage = '40';
                } else if (covCLimitValue > 40 && covCLimitValue < 45 ) {
                    percentage = '45';
                } else if (covCLimitValue > 45 && covCLimitValue < 50 ) {
                    percentage = '50';
                } else if (covCLimitValue > 50 && covCLimitValue < 55 ) {
                    percentage = '55';
                } else if (covCLimitValue > 55 && covCLimitValue < 60 ) {
                    percentage = '60';
                } else if (covCLimitValue > 60 && covCLimitValue < 65 ) {
                    percentage = '65';
                } else if (covCLimitValue > 65 && covCLimitValue < 70 ) {
                    percentage = '70';
                } else if (covCLimitValue > 70 ) {
                    percentage = '75';
                }

                this.setDefaultCoveragesValueByPercentage(Number(covA), 'coverageC', 'coverageCLimitId', percentage);
            }

            isCompMapped = true;
        } else {
            this.coveragesData.coveragesAndDeductiblesForm.get('coverageCLimitId').setValue('0');
        }

        return isCompMapped;
    }

    setValidCovEandFValues(): void {
        const covEValue = LvRaterCovELiabilityLimit.find(e => e.code === this.comparativeMappedPayload?.coverageELiability.toString())?.code;
        const covFValue = LvRaterCovFMedicalLimit.find(f => f.code === this.comparativeMappedPayload?.coverageFMedical.toString())?.code;

        this.coveragesData.coveragesAndDeductiblesForm.get('coverageEId').setValue(covEValue);
        this.coveragesData.coveragesAndDeductiblesForm.get('coverageFId').setValue(covFValue);
    }

    private setDefaultCoveragesValueByPercentage(baseValue: number, derivedField: string, percentageField: string, defaultPercentage: string): void {

        this.coveragesData.coveragesAndDeductiblesForm.get(percentageField).setValue(defaultPercentage);
        const percentage = Number(this.coveragesData.coveragesAndDeductiblesForm.get(percentageField).value);

        this.coveragesData.coveragesAndDeductiblesForm.get(derivedField).setValue(Boolean(baseValue) ? (baseValue * (percentage/100)) : 0);
    }

    mapDeductiblesFromComparative(): void {
        const hurricaneLimitList = LvRaterDeductibleHUR.filter(item => {
            if (this.quoteService.formType === FormTypeConstants.HO3 || this.quoteService.formType === FormTypeConstants.DP3) {
              return item.isHO3 || item.isDP3;
            }
            if (this.quoteService.formType === FormTypeConstants.HO4 || this.quoteService.formType === FormTypeConstants.HO6) {
              return item.isHO4 || item.isHO6;
            }
        });
        const aop = LvRaterOtherPerilDeductibleNHR.find(a => a.code === this.comparativeMappedPayload?.nhrDeductible.toString())?.code;
        const hur = hurricaneLimitList.find(h => h.code === this.comparativeMappedPayload?.hurDeductible.toString())?.code;

        this.coveragesData.coveragesAndDeductiblesForm.get('allOtherPerilsId').setValue(aop);
        this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValue(hur);
    }

    mapCoverageDiscountFromComparative(): RiskDiscountQuickQuoteDTO {
        const comparativeDiscount = this.comparativeMappedPayload;
        const riskDiscount:  RiskDiscountQuickQuoteDTO= {
          isCompanion: this.coveragesData.discountsForm.get('companion').value,
          sprinklerCode: LvRaterSprinkler.find(x => x.code === comparativeDiscount?.sprinkler)?.code ?? 'NO',
          buglarCode: LvRaterBurglarProtection.find(x => x.code === comparativeDiscount?.burglarProtection.toString())?.code ?? '1',
          fireCode: LvRaterFireProtection.find(x => x.code === comparativeDiscount?.fireProtection.toString())?.code ?? '4',
          isAccreditedBuilder: this.quoteService.formType === this.formTypeConstants.DP3 ? false : comparativeDiscount?.isAccreditedBuilder === true ? true : false,
          isSrRetiree: this.coveragesData.discountsForm.get('seniorRetiree').value,
          securedCommBldgCode: LvRaterSecuredCommunity.find (x => x.code === comparativeDiscount?.securedCommunity)?.code ?? 'N',
          isCompanionHOPolicy: this.quoteService.formType === this.formTypeConstants.DP3 ? comparativeDiscount?.isActiveCentauriHOPolicy ?? false : false
        };

        return riskDiscount;
    }

    mapApplicantFromComparative(applicantForm: FormGroup, contactForm: FormGroup, coApplicantForm: FormGroup, applicantCityList: any, coApplicantCityList: any): void {
        const data = this.comparativeDetails?.applicant;
        const stateMaxCharacterCount = 10;

        data.forEach(applicant => {
            if (applicant.applicantType === 1) {
                applicantForm.get('maritalStatusApplicant').setValue(LvMaritalStatuses.find(m => m.description.toLowerCase() === applicant?.maritalStatus?.toLowerCase())?.code);
                contactForm.get('emailAddress').setValue(applicant?.email);
                const mobilePhone = applicant?.mobilePhone?.replace(/[- )(]/g,'') ?? '';
                contactForm.get('mobilePhoneNumber').setValue(mobilePhone);
                const homePhone = applicant?.homePhone?.replace(/[- )(]/g,'') ?? '';
                contactForm.get('homePhoneNumber').setValue(homePhone);
                this.isApplicantMailingAddressValid = Boolean(applicantCityList?.find(x => x.toLowerCase() === applicant?.city?.toLowerCase() ?? '') ?? '');

                if ((this.isApplicantMailingAddressValid && this.isCountryUSA) || !this.isCountryUSA) {
                    applicantForm.get('isApplicantsMailingAddressSameAsInsuredLocation').setValue(!applicant.isApplicantAddressDifferent);
                    if (!applicantForm.get('isApplicantsMailingAddressSameAsInsuredLocation').value) {
                        applicantForm.get('mailingAddress').setValue(applicant?.address ?? '');
                        applicantForm.get('aptUnit').setValue(applicant?.address2 ?? '');
                        applicantForm.get('zipCode').setValue(applicant?.zip?.trim() ?? '');
                        applicantForm.get('city').setValue(this.isCountryUSA
                            ? applicantCityList?.find(x => x.toLowerCase() === applicant?.city?.toLowerCase()) ?? '' : applicant?.city ?? '');
                        applicantForm.get('state').setValue(applicantForm.get('state').value
                            ? applicantForm.get('state').value : applicant?.state?.length >= stateMaxCharacterCount
                            ? applicant?.state?.substring(0,stateMaxCharacterCount) : applicant?.state ?? '');

                        const countryCode = applicant?.country ? this.isCountryUSA
                            ? 'USA' : LvCountries.find(c => c.value.toLowerCase() === applicant?.country?.toLowerCase())?.value ?? '' : '';
                        applicantForm.get('country').setValue(countryCode);
                    }
                }
            }

            if (applicant.applicantType === 2) {
                const DOB = Boolean(applicant?.dob) ? new Date(applicant?.dob) : null;
                const isDOBBetweenValidDates = DOB !== null ? moment(DOB).isBetween(new Date('12/31/1799'), new Date()) : false;
                const validDOB = DOB !== null && DOB.toString() !== this.genericConstants.invalidDate && isDOBBetweenValidDates ? DOB : null;
                const validCity = this.isCoAppCountryUSA ? Boolean(coApplicantCityList.length === 1) ?
                    coApplicantCityList[0] ?? '' : coApplicantCityList?.find(x => x.toLowerCase() === applicant?.city?.toLowerCase()) ?? '' : applicant?.city ?? '';

                if (validDOB !== null && this.checkIfCoApplicantHasValidData(applicant, coApplicantCityList)) {
                    coApplicantForm.get('isThereACoApplicant').setValue(true);
                    coApplicantForm.get('coApplicantFirstName').setValue(applicant?.firstName?.trim());
                    coApplicantForm.get('coApplicantLastName').setValue(applicant?.lastName?.trim());
                    coApplicantForm.get('coApplicantDateOfBirth').setValue({ isRange: false, singleDate: { jsDate: validDOB } });
                    coApplicantForm.get('coApplicantMaritalStatus').setValue(LvMaritalStatuses.find(m => m.description.toLowerCase() === applicant?.maritalStatus?.toLowerCase())?.code ?? 'N'); //Set N as initial value, if no data from request
                    coApplicantForm.get('coApplicantRelationship').setValue(LvRelationshipToApplicant.find(r => r.code === applicant?.relationshipToApplicant?.toUpperCase())?.code ?? 'O');
                    coApplicantForm.get('coApplicantisRetiredYesNo').setValue(this.isCoApplicantRetired(validDOB, applicant?.industry?.trim(), applicant?.occupation?.trim()));

                    coApplicantForm.get('isCoApplicantsMailingAddressSameAsApplicantsMailingAddress').setValue(!applicant.isApplicantAddressDifferent);
                    if (!coApplicantForm.get('isCoApplicantsMailingAddressSameAsApplicantsMailingAddress').value) {
                        coApplicantForm.get('coApplicantSameAsApplicantMailingAddress').setValue(applicant?.address?.trim() ?? '');
                        coApplicantForm.get('coApplicantSameAsApplicantAptUnit').setValue(applicant?.address2 ?? '');
                        coApplicantForm.get('coApplicantSameAsApplicantZipCode').setValue(applicant?.zip?.trim() ?? '');
                        coApplicantForm.get('coApplicantSameAsApplicantState').setValue(coApplicantForm.get('coApplicantSameAsApplicantState').value
                            ? coApplicantForm.get('coApplicantSameAsApplicantState').value : applicant?.state ?? '');
                        coApplicantForm.get('coApplicantSameAsApplicantCity').setValue(validCity);

                        const countryCode = applicant?.country ? this.isCoAppCountryUSA
                            ? 'USA' : LvCountries.find(c => c.value.toLowerCase() === applicant?.country?.toLowerCase())?.value ?? '' : '';
                        coApplicantForm.get('coApplicantSameAsApplicantCountry').setValue(countryCode);
                    }
                }
            }
        });
    }

    checkIfCoApplicantHasValidData(coApplicantData: any, coApplicantCityList: any): boolean {
        // Only check Address fields if co-applicant address is different and is US Country
        if (coApplicantData?.isApplicantAddressDifferent && this.isCoAppCountryUSA) {
            const isAddressValid = Boolean (coApplicantData?.address?.trim());
            const isCityValid = Boolean(coApplicantCityList.length === 1 ?
                true : coApplicantCityList?.find(x => x.toLowerCase() === coApplicantData?.city?.toLowerCase() ?? '') ?? '');
            this.isCoApplicantMailingAddressValid = isAddressValid && isCityValid;
        } else {
            this.isCoApplicantMailingAddressValid = true;
        }

        return coApplicantData?.firstName?.trim()
            && coApplicantData?.lastName?.trim()
            && this.isCoApplicantMailingAddressValid;
    }

    isCoApplicantRetired(validDOB: Date, industry: string, occupation: string): boolean {
        const coApplicantAge = validDOB !== null ? this.applicantBusinessRules.calculateInsuredAge(validDOB) : 0;
        const isCoApplicantRetired = this.applicantBusinessRules.isInAgeOfRetirement(coApplicantAge) &&
            ((industry?.toUpperCase() === 'RETIRED') || (occupation?.toUpperCase() === 'RETIRED')) ? true : false;
        const isRetiredGreaterOrEqualTo60 = this.applicantBusinessRules.isInAgeOfRetirementBRULCOV20(coApplicantAge);

        return isRetiredGreaterOrEqualTo60 ? true : isCoApplicantRetired;
    }

    mapSchedulesFromComparative(): RiskScheduleDTO[] {
        const schedules = this.comparativeDetails?.scheduledProperty;

        if (Boolean(schedules.length)) {
            this.coveragesData.schedulesForm.get('addSchedules').setValue(true);

            return schedules.map(schedule => {
                return {
                    scheduleId: 1,
                    tableId: SchedulesTableName.find(n => n.raterCode === schedule?.type?.toLowerCase()).uiCode,
                    description: schedule?.type,
                    limit: schedule?.limit?.toString(),
                    total: schedule?.limit,
                };
            });
        }
        return [];
    }

    mapCoverageFlood(applicantForm: FormGroup): void {
        if (!this.coveragesData?.torrentResponse?.isFloodZoneAvailableFromTorrent && !this.coveragesData.floodAssistRetrievalForm.get('isFloodZoneFromFloodAssist').value) {
            const floodZone = this.quoteService.formType === this.formTypeConstants.DP3 ?
                this.comparativeDetails?.policy?.floodZone?.toUpperCase() : this.comparativeMappedPayload?.floodZone?.toUpperCase();
            this.coveragesData.floodForm.get('floodZone').setValue(LvRaterFloodZone.find(x => x.code === floodZone)?.code ?? 'NA');
        }
        this.coveragesData.floodForm.get('privateFloodCoverage').setValue(this.checkIfZipCodeValidForFloodCov(applicantForm));
        this.coveragesData.discountsForm.get('companion').setValue(this.quoteService.formType === this.formTypeConstants.DP3 ?
            this.comparativeDetails?.policy?.isCompanionFlood : this.coveragesData.floodForm.get('privateFloodCoverage')?.value);
        this.coveragesData.floodForm.get('applicantFloodPolicyId').setValue(
            LvFloodPolicyDuringPolicyPeriod.find(x => x.code.toUpperCase() === this.comparativeDetails?.policy?.companionFloodPolicy?.toUpperCase())?.code ?? '');
    }

    checkIfZipCodeValidForFloodCov(applicantForm: FormGroup): boolean {
        return this.comparativeDetails?.policy?.addCentauriPrivateFloodCoverage
            && !this.coveragesData.floodCoverageExcludedZipCode?.find(data => data === Number(applicantForm.get('qqZipCode').value))
            && !this.propertyData.checkIfNearWaters();
    }

    getDefaultComparativeFloodDeductible(): string {
        const comparativeFloodDeductible = Number(this.comparativeMappedPayload?.floodDeductible);

        const defaultDeductibleIndex = this.coveragesData.floodDeductibleList.findIndex(x => x.description === comparativeFloodDeductible);
        return this.coveragesData.floodDeductibleList[defaultDeductibleIndex]?.code;
    }

    mapClaims(applicantForm: FormGroup): void {
        const effectiveDate = applicantForm.get('effectiveDate').value?.singleDate?.jsDate ?
            new Date(applicantForm.get('effectiveDate').value?.singleDate?.jsDate)
            : new Date(applicantForm.get('effectiveDate').value);
        const maxCharacterCount = 500;

        if (!this.claimsData.claimList || (this.claimsData.claimList && this.claimsData.claimList?.length === 0)) {
            this.comparativeDetails.priorLosses?.forEach(claimItem => {
                const claim: ClaimsModalModel = {
                    claimAmount: claimItem.amtPaid,
                    catCode: 'New CAT Code',
                    claimChargeableVsNonChargeable: false,
                    claimNumber: claimItem.lossID?.toString(),
                    claimSource: AGENTCLAIMSOURCE,
                    claimStatus: ClaimStatus.find(x => x.description.toUpperCase() === claimItem.claimStatus.toUpperCase()).code,
                    claimType: claimItem.causeOfLoss?.trim()?.toUpperCase(),
                    createdDate: new Date(),
                    additionalDescription: claimItem.type?.trim() ?
                        claimItem.type?.length >= maxCharacterCount ? claimItem.type?.substring(0, maxCharacterCount) : claimItem.type
                        : LossTypeList.find(x => x.code === claimItem.causeOfLoss?.trim()?.toUpperCase()).description,
                    individualMatchType: 'Individual Match Type',
                    isAPlus: false,
                    isAdd: true,
                    isComparative: true,
                    dispute: false,
                    lossDate: {
                        singleDate: {
                            date: {
                                year: new Date(claimItem.lossDate).getFullYear(),
                                month: (new Date(claimItem.lossDate).getMonth() + 1),
                                day: new Date(claimItem.lossDate).getUTCDate()
                            },
                            formatted: String(moment(new Date(claimItem.lossDate)).format('MM/DD/YYYY')),
                            jsDate: new Date(claimItem.lossDate)
                        }
                    },
                    policyType: 'Policy Type',
                    isLossAssociatedToHome: true, //CENTAURI-5672 Comparative loss associated to home default to yes
                    isLossAssociatedToInsured: false
                };
                claim.claimChargeableVsNonChargeable = this.claimsChargeableService.updateConditionaClaimsValue(claim, effectiveDate);
                this.claimsData.claimList.push(claim);
            });
        }

        this.claimsData.claimList.forEach(claim => {
            claim.claimChargeableVsNonChargeable = this.claimsChargeableService.updateConditionaClaimsValue(claim, effectiveDate);
        });

        this.claimsChargeableService.checkClaimsChargeabilityRule(effectiveDate);
    }

    mapPropertyFromComparative(dataFromAPIResponse: AllResponseDTO, isDataFromOrderPrefill: boolean): void {
        const comparativePropertyDetails = this.comparativeDetails?.property;
        const locationDetails =  this.propertyData.locationDetails;
        const propertyDetails =  this.propertyData.propertyDetails;
        const windMitigation =  this.propertyData.windMitigation;

        if (!Boolean(dataFromAPIResponse.distanceToWaterCode) && !Boolean(dataFromAPIResponse.distanceToWater)) {
            const distanceToWaterValue = DistanceConstants.find(x => x.code === comparativePropertyDetails?.distanceToWater)?.description ?? '';
            dataFromAPIResponse.distanceToWaterCode = comparativePropertyDetails?.distanceToWater;
            locationDetails.get('distanceToWater').setValue(distanceToWaterValue ?? '');
        }

        if (!Boolean(dataFromAPIResponse.distanceToActualCoastCode) && !Boolean(dataFromAPIResponse.distanceToActualCoast)) {
            const distanceToActualCoastValue = DistanceConstants.find(x => x.code === comparativePropertyDetails?.distanceToCoast)?.description ?? '';
            dataFromAPIResponse.distanceToActualCoastCode = comparativePropertyDetails?.distanceToCoast;
            locationDetails.get('distanceToActualCoast').setValue(distanceToActualCoastValue ?? '');
            this.propertyData.distanceToActualCoastCodeISO = comparativePropertyDetails?.distanceToCoast;
        }

        if (!Boolean(dataFromAPIResponse.protectionClassLocationHigherNumber) && !Boolean(dataFromAPIResponse.protectionClassLocationLowerNumber) && isDataFromOrderPrefill) {
            const raterPPC = LvProtectionClass.find(x => x.code === comparativePropertyDetails?.protectionClass)?.code  ?? '';
            locationDetails.get('protectionClass').setValue(raterPPC);
            this.propertyData.protectionClassLowerNumber = raterPPC;
            this.propertyData.protectionClassHigherNumber = raterPPC;
        }

        if (isDataFromOrderPrefill) {
            locationDetails.get('propertyUsage').setValue(LvRaterPropertyUsage.find(x => x.code === comparativePropertyDetails?.propertyUsage)?.code ?? '');
        }

        locationDetails.get('bceg').setValue(locationDetails.get('bceg').value ? locationDetails.get('bceg').value : LvBceg.find(x => x === comparativePropertyDetails?.bceg) ?? '');

        locationDetails.get('milesToFireStation').setValue(locationDetails.get('milesToFireStation').value
            ? locationDetails.get('milesToFireStation').value : raterDistanceToFireStation.find(x => x.raterCode === comparativePropertyDetails?.distanceToFireStation)?.uiCode ?? '');

        locationDetails.get('feetToHydrant').setValue(locationDetails.get('feetToHydrant').value
            ? locationDetails.get('feetToHydrant').value : raterFeetToHydrant.find(x => x.raterCode === comparativePropertyDetails?.distanceToHydrant)?.uiCode ?? '');

        locationDetails.get('numMonthsOccupied').setValue(locationDetails.get('numMonthsOccupied').value
            ? locationDetails.get('numMonthsOccupied').value : this.quoteService.formType !== this.formTypeConstants.HO4
            ? LvMonthsOccupied.find(x => x.code === comparativePropertyDetails?.noMonthsOccupied)?.code ?? '' : '');

        locationDetails.get('isResidenceRented').setValue(this.quoteService.formType !== this.formTypeConstants.HO4
            ? (comparativePropertyDetails?.isPropertyRented ?? false) : null);


        propertyDetails.get('purchaseDate').setValue(this.quoteService.formType !== this.formTypeConstants.HO4 ? Boolean(comparativePropertyDetails?.datePurchased?.trim())
            ? {isRange: false, singleDate: {jsDate: new Date(comparativePropertyDetails?.datePurchased)}} : null : null);
        propertyDetails.get('floorUnitNumber').setValue(this.quoteService.formType === this.formTypeConstants.HO4 || this.quoteService.formType === this.formTypeConstants.HO6
            ? comparativePropertyDetails?.floorUnitLevel ?? '' : '');
        propertyDetails.get('electrical').setValue(propertyDetails.get('electrical').value
            ? propertyDetails.get('electrical').value : raterElectricalType.find(x => x.raterCode === comparativePropertyDetails?.electricalType)?.uiCode ?? '');
        propertyDetails.get('primaryHeatType').setValue(propertyDetails.get('primaryHeatType').value
            ? propertyDetails.get('primaryHeatType').value : LvRaterPrimaryHeatType.find(x => x.code === comparativePropertyDetails?.primaryHeatType)?.code ?? '');
        windMitigation.get('mitigationForm').setValue(LvMitigationForm.find(x => x.code === this.comparativeMappedPayload?.windMitigationForm)?.code ?? 'NF');

        windMitigation.get('dateInspection').setValue(Boolean(comparativePropertyDetails?.wmDateOfInspection?.trim())
            ? { isRange: false, singleDate: { jsDate: new Date(comparativePropertyDetails?.wmDateOfInspection?.trim())}} ?? null : null);

        windMitigation.get('roofDeckAttachment').setValue(this.setRoofDeckAttachment(propertyDetails, comparativePropertyDetails));

        windMitigation.get('roofToWallAttachment').setValue(LvRaterRoofToWallAttachment.find(x => x.code === comparativePropertyDetails?.roofWallConnection)?.code ?? '');
        windMitigation.get('terrain').setValue(windMitigation.get('terrain').value ? windMitigation.get('terrain').value : LvRaterTerrain.find(x => x.code === comparativePropertyDetails?.terrain)?.code ?? '');
        windMitigation.get('windDesignSpeed').setValue(windMitigation.get('windDesignSpeed').value ? windMitigation.get('windDesignSpeed').value : LvRaterWindDesignSpeed.find(x => x.code === comparativePropertyDetails?.windSpeed)?.code ?? '');
        windMitigation.get('windBorneDebrisRegion').setValue(windMitigation.get('windBorneDebrisRegion').value ? windMitigation.get('windBorneDebrisRegion').value : comparativePropertyDetails?.windBorneDebris ?? false);

        const isBRPROP26 = this.propertyData.propertyDetails.get('yearBuilt').value >= 2002; //Check BR-PROP-26, set default values
        windMitigation.get('predominantRoofCovering').setValue(isBRPROP26 ?
            'F' : LvRaterRoofCover.find(x => x.code === comparativePropertyDetails?.roofCover)?.code ?? '');
        windMitigation.get('internalPressureDesign').setValue(isBRPROP26 ?
            'IPD1' : LvInternalPressureDesign.find(x => x.code === comparativePropertyDetails?.internalPressureDesign)?.code ?? '');

        windMitigation.get('secondaryWaterResistance').setValue(isBRPROP26 ?
            false : comparativePropertyDetails?.swr ?? false);

        if(Boolean(locationDetails.get('county')?.value)) {
            windMitigation.get('openingProtection').setValue(this.isBRPROP27or28() ? 'A' : isBRPROP26 ?
                'N' : LvRaterOpeningProtection.find(x => x.code === comparativePropertyDetails?.openingProtection)?.code ?? '');
        } else {
            windMitigation.get('openingProtection').setValue(isBRPROP26 ?
                'N' : LvRaterOpeningProtection.find(x => x.code === comparativePropertyDetails?.openingProtection)?.code ?? '');
        }
    }

    getRiskPropertyPayloadFromComparative(isNewQuote: boolean, riskProperty: RiskPropertyQuickQuoteDTO,
        locationDetails: FormGroup, propertyDetails: FormGroup, windMitigation: FormGroup): RiskPropertyQuickQuoteDTO{

        if(this.isFromComparativeRater) {
            if (isNewQuote) {
                riskProperty['feetToHyrantCode'] = locationDetails.get('feetToHydrant').value;
                riskProperty['numberMonthOccupied'] = this.quoteService.formType !== this.formTypeConstants.HO4 ? Number(locationDetails.get('numMonthsOccupied').value) : null;
                riskProperty['isTheResidenceRented'] = this.quoteService.formType !== this.formTypeConstants.HO4 ? locationDetails.get('isResidenceRented')?.value : null;
                riskProperty['purchaseDate'] = this.quoteService.formType !== this.formTypeConstants.HO4 ? propertyDetails.get('purchaseDate').value?.singleDate?.jsDate.toUTCString() : null;
                riskProperty['floorUnitNumber'] = this.quoteService.formType === this.formTypeConstants.HO4 || this.quoteService.formType === this.formTypeConstants.HO6 ?
                    propertyDetails.get('floorUnitNumber').value : '';
                riskProperty['electricalCode'] = propertyDetails.get('electrical').value;
                riskProperty['primaryHeatTypeCode'] = propertyDetails.get('primaryHeatType').value;
                riskProperty['mitigationFormCode'] = windMitigation.get('mitigationForm').value;
                riskProperty['dateOfInspection'] = windMitigation.get('dateInspection')?.value?.singleDate?.jsDate.toUTCString();
                riskProperty['raterRoofCoverCode'] = windMitigation.get('predominantRoofCovering').value;
                riskProperty['roofDeckAttachementCode'] = windMitigation.get('roofDeckAttachment').value;
                riskProperty['roofWallAttachementCode'] = windMitigation.get('roofToWallAttachment').value;
                riskProperty['isSecondaryWaterResistance'] = windMitigation.get('secondaryWaterResistance')?.value ? true : false;
                riskProperty['raterOpeningProtectionCode'] = windMitigation.get('openingProtection').value;
                riskProperty['internalPressureDesignCode'] = windMitigation.get('internalPressureDesign').value;
            }

            riskProperty['isCentralAirCondition'] = propertyDetails.get('isCentralAirConditioning').value;
            riskProperty['yearCentralAirConditionSystemInstalled'] = this.quoteService.formType !== this.formTypeConstants.HO4 ? propertyDetails.get('yearCentralAirConditioning').value : '';
            riskProperty['yearElectricalInstalledUpdated'] = this.quoteService.formType !== this.formTypeConstants.HO4 ? propertyDetails.get('yearElectrical').value : '';
            riskProperty['yearPlumbingInstalledUpdated'] = this.quoteService.formType !== this.formTypeConstants.HO4 ? propertyDetails.get('yearPlumbing').value : '';
            riskProperty['yearHeatingInstalledUpdated'] = this.quoteService.formType !== this.formTypeConstants.HO4 ? propertyDetails.get('yearHeating').value : '';

            return riskProperty;
        }

        return riskProperty;
    }

    mapPropertyFieldsHitOrNonhit(iso360Value?: ResidentialResponseDTO): void { //To populate property fields specially for HO6
        const comparativePropertyDetails = this.comparativeDetails?.property;

        if (this.quoteService.formType === this.formTypeConstants.HO6 && !iso360Value?.isNoHitAddress) {
            iso360Value.exteriorWallFinish = new BasePropertyDataModel({
                iso360ValueCode: this.iso360ValueMapping.defaultExteriorWallFinishMapping.find(i => i.uiCode === comparativePropertyDetails?.exteriorWall)?.iso360Code ?? '',
                code: comparativePropertyDetails?.exteriorWall ?? '',
                description: LvRaterExteriorWallFinish.find(d => d.code === comparativePropertyDetails?.exteriorWall)?.description ?? '',
              });

            iso360Value.construction = new BasePropertyDataModel({
                iso360ValueCode: this.iso360ValueMapping.defaultConstructionTypeMapping.find(i => i.uiCode === comparativePropertyDetails?.constructionType)?.iso360Code ?? '',
                code: comparativePropertyDetails?.constructionType ?? '',
                description: LvConstructionType.find(d => d.code === comparativePropertyDetails?.constructionType)?.description ?? '',
            });

            iso360Value.roofShape = new BasePropertyDataModel({
                iso360ValueCode: comparativePropertyDetails?.roofShape ?? '',
                code: comparativePropertyDetails?.roofShape ?? '',
                description: LvRaterRoofShape.find(d => d.code === comparativePropertyDetails?.roofShape)?.description ?? '',
            });

            iso360Value.structureType = new BasePropertyDataModel({
                iso360ValueCode: comparativePropertyDetails?.structureType ?? '',
                code: comparativePropertyDetails?.structureType ?? '',
                description: LvStructureType.find(d => d.code === comparativePropertyDetails?.structureType)?.description ?? '',
            });

            iso360Value.roofMaterial = new BasePropertyDataModel({
                iso360ValueCode: this.iso360ValueMapping.defaultRoofMaterialMapping.find(i => i.uiCode === comparativePropertyDetails?.roofMaterial)?.iso360Code ?? '',
                code: comparativePropertyDetails?.roofMaterial ?? '',
                description: LvRoofMaterial.find(d => d.code === comparativePropertyDetails?.roofMaterial)?.description ?? '',
            });
        }
    }

    getValidComparativeDropdownValues(data: ComparativeRequestPayload): any {
        const roofMaterialList = LvRoofMaterial.filter(item => {
            if (this.quoteService.formType === FormTypeConstants.HO3 || this.quoteService.formType === FormTypeConstants.DP3) {
                return (item.isHO3 || item.isDP3) && item.isActive;
            } else {
                return (item.isHO3 || item.isHO4 || item.isHO6 || item.isDP3) && item.isActive;
            }
        });
        this.structureTypeList = LvStructureType.filter(item => {
            if (this.quoteService.formType !== this.formTypeConstants.HO4) {
                return item.isHO3 || item.isHO6;
            }
            return item.isHO4;
          });

        data.property.noofStories = LvNumberOfStories.find(d => d.code === data?.property?.noofStories)?.code ?? '';
        data.property.exteriorWall = LvRaterExteriorWallFinish.find(d => d.code ===  data?.property?.exteriorWall)?.code ?? '';
        data.property.constructionType = LvConstructionType.find(d => d.code === data?.property?.constructionType)?.code ?? '';
        data.property.roofShape = LvRaterRoofShape.find(d => d.code === data?.property?.roofShape)?.code ?? '';
        data.property.foundationType = LvRaterFoundationType.find(d => d.code === data?.property?.foundationType)?.code ?? '';
        data.property.structureType = this.structureTypeList.find(d => d.code === data?.property?.structureType)?.code ?? '';
        data.property.roofMaterial = roofMaterialList.find(d => d.code === data?.property?.roofMaterial)?.code ?? '';
    }

    mapCreditRatingFromComparative(): void {
        const applicantCreditRating = this.comparativeDetails?.applicant.find(x => x.applicantType === 1)?.creditRating;

        switch (applicantCreditRating) {
            case (EstimatedCreditConstants.EC4):
                this.coveragesData.creditScore = 800;
                this.coveragesData.estimatedCode = EstimatedCreditConstants.EC4;
              break;
            case (EstimatedCreditConstants.EC3):
                this.coveragesData.creditScore = 701;
                this.coveragesData.estimatedCode = EstimatedCreditConstants.EC3;
              break;
            case (EstimatedCreditConstants.EC2):
                this.coveragesData.creditScore = 601;
                this.coveragesData.estimatedCode = EstimatedCreditConstants.EC2;
              break;
            case (EstimatedCreditConstants.EC1):
                this.coveragesData.creditScore = 500;
                this.coveragesData.estimatedCode = EstimatedCreditConstants.EC1;
              break;
            case (EstimatedCreditConstants.EC0):
                this.coveragesData.creditScore = -1;
                this.coveragesData.estimatedCode = EstimatedCreditConstants.EC0;
              break;
            default:
                this.coveragesData.creditScore = 900;
                this.coveragesData.estimatedCode = EstimatedCreditConstants.EC5;
              break;
          }
    }

    private isBRPROP27or28(): boolean {
        const yearBuilt = this.propertyData.propertyDetails.get('yearBuilt').value;
        const county = this.propertyData.locationDetails.get('county')?.value.toLocaleLowerCase();

        const br27CountyList: string[] = [
          'monroe', 'lee', 'escambia', 'broward', 'manatee', 'desoto', 'martin', 'brevard', 'collier', 'glades'
          ,'hendry','indian river','pinellas','sarasota','palm beach','charlotte','miami-dade', 'st lucie'];

        const br28CountyList: string[] = [
          'broward', 'escambia', 'franklin', 'gulf', 'martin', 'miami-dade', 'monroe', 'pinellas', 'sarasota', 'st lucie'];

        const isBRPROP27 = yearBuilt >= 2012 && br27CountyList.includes(county);
        const isBRPROP28 = (yearBuilt >= 2002 && yearBuilt <= 2011) && br28CountyList.includes(county);

        return isBRPROP27 || isBRPROP28;
    }

    isBREND01(applicantForm: FormGroup): boolean {
        const effectiveDateYear = applicantForm.get('effectiveDate').value?.singleDate?.jsDate?.getFullYear();
        const yearBuilt =  this.propertyData.propertyDetails.get('yearBuilt').value;

        return Boolean((effectiveDateYear - yearBuilt) > this.endorsementsData.getWDEYearInclusion());
    }

    //DP3 BRULs START
    private isPremisesLiabilityMandatory(): boolean {
        const MINIMUMNUMBEROFMONTHS: number = 1;
        const MAXIMUMNUMBEROFMONTHS: number = 8;
        const propertyUsage = this.propertyData.locationDetails.get('propertyUsage').value;
        const monthsOccupied = this.propertyData.locationDetails.get('numMonthsOccupied').value;

        return (Number(monthsOccupied) >= MINIMUMNUMBEROFMONTHS
            && Number(monthsOccupied) <= MAXIMUMNUMBEROFMONTHS
            && this?.coveragesData?.coveragesAndDeductiblesForm?.get('isCoverageLEnabled')?.value
            && (propertyUsage !== LocationLabelConstants.ownerOccupiedPrimary
                && propertyUsage !== LocationLabelConstants.ownerOccupiedSecondary));
    }

    private isLimitedTheftCoverageAvailable(): boolean {
        const propertyUsage = this.propertyData.locationDetails.get('propertyUsage').value;

        return propertyUsage === LocationLabelConstants.ownerOccupiedPrimary
            || propertyUsage === LocationLabelConstants.ownerOccupiedSecondary
            || propertyUsage === LocationLabelConstants.SeasonalDwelling;
    }

    getDP3RiskCoveragesPayloadFromComparative(isNewQuote: boolean, riskCoverages: RiskCoverageQuickQuoteDTO, coverages: FormGroup): RiskCoverageQuickQuoteDTO{
        if (this.isFromComparativeRater && isNewQuote && this.quoteService.formType === this.formTypeConstants.DP3) {
            riskCoverages['coverageL'] = coverages.get('isCoverageLEnabled').value ? coverages.get('coverageL').value : 0;
            riskCoverages['coverageM'] = coverages.get('isCoverageMEnabled').value ? coverages.get('coverageM').value : 0;
            riskCoverages['fairRentalAddlLiving'] = coverages.get('coverageDorE').value;
            return riskCoverages;
        }

        return riskCoverages;
    }

    populateCompRaterCounty(data: EntityRiskDTO) {
        const addressIndex = data.entityAddresses.findIndex(x => x.addressTypeCode === 'PR');
        const entityAddress = data.entityAddresses[addressIndex !== -1 ? addressIndex: 0]?.address;

        const addressForCounty: IAddressForCounty = {
            street2: entityAddress?.streetAddress2,
            city: entityAddress?.city,
            stateCode: entityAddress?.state,
            street1: entityAddress?.streetAddress1,
            zip: entityAddress?.zipCode,
        };

        this.thirdPartyDataService.getCounties(addressForCounty).pipe(takeUntil(this.stop$)).subscribe(
            (res) => {
                if (res) {
                    this.propertyData.hasCountyRetrieved = Boolean(res);
                    this.propertyData.countyList = res;
                    if (res.length === 1) {
                        this.propertyData.locationDetails.get('county').setValue(res[0]);
                        this.propertyData.locationDetails.get('county').updateValueAndValidity();
                    } else if (res.length > 1) {
                        const countyIndex = res.findIndex(x => x?.toUpperCase() === this.comparativeDetails?.property?.propertyCounty?.toUpperCase());
                        this.propertyData.locationDetails.get('county').setValue(countyIndex !== -1 ? res[countyIndex] : '');
                        this.propertyData.locationDetails.get('county').updateValueAndValidity();
                        this.propertyData.locationDetails.get('county').enable();
                    }

                    //Check BRPROP27 and 28 once county has been set
                    if (this.isBRPROP27or28()) {
                        this.propertyData.windMitigation.get('openingProtection').setValue('A');
                    }
                }
            },
            (error) => {
                this.propertyData.locationDetails.get('county').setValue(this.comparativeDetails?.property?.propertyCounty?.toUpperCase());
            },
            () => {
                this.propertyData.updateCountyFromRiskProperty(data);
            }
        );
    }

    private isBRCOV22(): boolean {
        const discountsFire = LvRaterFireProtection.find(x => x.code === this.comparativeMappedPayload?.fireProtection.toString())?.code ?? '4';
        const discountsSprinkler = LvRaterSprinkler.find(x => x.code === this.comparativeMappedPayload?.sprinkler)?.code ?? 'NO';

        return discountsFire === '2' || discountsFire === '3' || discountsSprinkler === 'FU';
    }
    //DP3 BRULs END

    mapYearFieldsFromComparative(dataFromAPIResponse: AllResponseDTO): void {
        const comparativePropertyDetails = this.comparativeDetails?.property;
        const propertyDetails =  this.propertyData.propertyDetails;

        propertyDetails.get('yearRoof').setValue(this.quoteService.formType !== this.formTypeConstants.HO4 ? this.comparativeMappedPayload.roofYear ?? comparativePropertyDetails?.yearBuilt : null);
        propertyDetails.get('yearElectrical').setValue(comparativePropertyDetails?.yearElectricalSystemInstalled ?? dataFromAPIResponse?.iso360Value?.yearBuilt);
        propertyDetails.get('yearPlumbing').setValue(comparativePropertyDetails?.yearPlumbingSystemInstalled ?? dataFromAPIResponse?.iso360Value?.yearBuilt);
        propertyDetails.get('yearHeating').setValue(comparativePropertyDetails?.yearHeatingSystemInstalled ?? dataFromAPIResponse?.iso360Value?.yearBuilt);

        if (Boolean(comparativePropertyDetails?.yearAirConditioningSystemInstalled)) {
            propertyDetails.get('isCentralAirConditioning').setValue(true);
            propertyDetails.get('yearCentralAirConditioning').setValue(comparativePropertyDetails?.yearAirConditioningSystemInstalled);
        }
    }

    setRoofDeckAttachment(propertyDetails: FormGroup, comparativePropertyDetails): string { //Check BR-PROP-24
        let roofDeckAttachmentCode = '';
        const isRoofShapeFlat = Boolean(propertyDetails.get('roofShape').value === 'F');
        const isroofDeckAttachmentReinforced =  Boolean(comparativePropertyDetails?.roofDeckAttachment?.trim()?.toLocaleUpperCase() === 'R');

        if (isRoofShapeFlat && isroofDeckAttachmentReinforced) {
            roofDeckAttachmentCode = 'R';
        } else if (!isRoofShapeFlat && isroofDeckAttachmentReinforced) {
            roofDeckAttachmentCode = 'O';
        } else {
            roofDeckAttachmentCode = LvRaterRoofDeckAttachment.find(x => x.code === comparativePropertyDetails?.roofDeckAttachment?.toLocaleUpperCase())?.code ?? '';
        }

        return roofDeckAttachmentCode;
    }
}