import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { ErrorMessageConstant } from '../../../../../../app/shared/constants/error-message.constants';
import { PolicyBillingLabelsConstants } from '../../../../../shared/constants/policy-billing.labels.constants';
import { BsModalRef } from 'ngx-bootstrap';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { PaymentRequestDTO } from '../../../../../../app/shared/models/data/dto/billing/payment-request.dto';
import { PolicyBillingData } from '../../../../../../app/modules/policy-management/data/policy-billing.data';
import { PaymentSummaryDTO } from '../../../../../../app/shared/models/data/dto/billing/payment-summary.dto';
import { DatePipe } from '@angular/common';
import Utils from '../../../../../../app/shared/utilities/utils';
import { BillingService } from '../../../../../../app/core/services/billing/billing.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import NotifUtils from '../../../../../../app/shared/utilities/notif-utils';
import { BaseClass } from '../../../../../../app/shared/base-class';
import { BillingPaymentInstrument } from '../../../../../../app/shared/enum/billing-payment-instrument';
import { EntityRiskData } from '../../../../../../app/modules/submission-management/data/entity-risk.data';
import { PolicyService } from '../../../../../../app/core/services/submission/policy.service';
import { PolicyNotesData } from '../../../../../../app/modules/policy-management/data/policy-notes.data';
import { AuthService } from 'app/core/services/auth.service';
import { AutoReinstatementData } from '../../../data/auto-reinstatement.data';

@Component({
  selector: 'app-adjustment-modal',
  templateUrl: './adjustment-modal.component.html',
  styleUrls: ['./adjustment-modal.component.scss']
})
export class AdjustmentModalComponent extends BaseClass implements OnInit {

  adjustmentForm: FormGroup;
  AdjustmentConstants = PolicyBillingLabelsConstants.adjustment;
  ErrorMessageConstant = ErrorMessageConstant;
  GenericLabel = GenericLabel;

  currencyInputMode = CurrencyMaskInputMode.NATURAL;

  datePipe: DatePipe;

  constructor(protected fb: FormBuilder,
    public bsModalRef: BsModalRef,
    public billingData: PolicyBillingData,
    public billingService: BillingService,
    public entityRiskData: EntityRiskData,
    public policyService: PolicyService,
    public policyNotesData: PolicyNotesData,
    protected authService: AuthService,
    protected autoReinstatementData: AutoReinstatementData) {
    super();
  }

  ngOnInit() {
    this.datePipe = new DatePipe('en-US');

    this.adjustmentForm = this.fb.group({
      method: new FormControl({ value: null, disabled: true }, [Validators.required]),
      amount: new FormControl(null, [Validators.required]),
      premium: new FormControl(null, [Validators.required]),
      tax: new FormControl(null, [Validators.required]),
      fee: new FormControl(null, [Validators.required]),
      comment: new FormControl('')
    });

    this.adjustmentForm.get('method').setValue(this.AdjustmentConstants.adjustmentMethod);
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  get isAmountHasError(): boolean {
    const totalAmount = (this.adjustmentForm.get('premium').value +
      this.adjustmentForm.get('tax').value +
      this.adjustmentForm.get('fee').value);

    return (totalAmount.toFixed(2) !== this.adjustmentForm.get('amount').value?.toFixed(2));
  }

  get isAllAmountZero(): boolean {
    return (this.adjustmentForm.get('premium').value === 0 &&
      this.adjustmentForm.get('tax').value === 0 &&
      this.adjustmentForm.get('fee').value === 0);
  }

  protected getPaymentAdjustmentRequest(): PaymentRequestDTO {

    const paymentSummary: PaymentSummaryDTO = {
      amount: this.adjustmentForm.get('amount').value,
      premiumAmount: this.adjustmentForm.get('premium').value,
      taxAmount: this.adjustmentForm.get('tax').value,
      feeAmount: this.adjustmentForm.get('fee').value,
      comment: this.adjustmentForm.get('comment').value,
      effectiveDate: this.datePipe.transform(this.authService.getCustomDate(), 'yyyy-MM-dd'),
      instrumentId: BillingPaymentInstrument.Adjustment,
    };

    const adjustmentRequest: PaymentRequestDTO = {
      riskId: this.entityRiskData.getRiskId(),
      riskDetailId: this.entityRiskData.getRiskDetailId(),
      paymentSummary: paymentSummary,
      isFromInsuredPaymentPortal: false
    };

    return adjustmentRequest;
  }

  onSaveClick(): void {
    const paymentAdjustmentRequest: PaymentRequestDTO = this.getPaymentAdjustmentRequest();

    Utils.blockUI();
    this.billingService.postPaymentRequest(paymentAdjustmentRequest)
      .pipe(
        takeUntil(this.stop$),
        switchMap(_ => this.policyService.getPolicyNotes(this.entityRiskData?.getRiskId()))
      ).subscribe(notes => {
        this.billingData.showSummary(paymentAdjustmentRequest.riskId);
        this.billingData.listInstallmentInvoice(paymentAdjustmentRequest.riskId);
        this.billingData.listPaymentsByRiskId(paymentAdjustmentRequest.riskId);
        this.policyNotesData.populateNotes(notes);
        this.autoReinstatementData.populateAutoReinstateData();
        this.bsModalRef.hide();
        Utils.unblockUI();
        NotifUtils.showSuccess(PolicyBillingLabelsConstants.successPaymentAdjustment);
      },
        err => {
          Utils.unblockUI();
          NotifUtils.showMultiLineError(err.error?.message);
        }
      );
  }
}
