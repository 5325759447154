import { Injectable } from '@angular/core';
import { OfacSearchModel } from '../../../shared/models/ofac/ofac-search.model';
import { BaseClass } from '../../../shared/base-class';
import { OfacSearchResultDTO } from '../../../shared/models/data/dto/ofac/ofac-search-result.dto';

@Injectable({
    providedIn: 'root'
  })
export class OfacSearchData extends BaseClass {

    private _searchResults: OfacSearchResultDTO[];
    private _ofacSearchModel: OfacSearchModel;

    public get searchResults(): OfacSearchResultDTO[] {
        return this._searchResults;
    }
    public set searchResults(value: OfacSearchResultDTO[]) {
        this._searchResults = value;
    }
    public get ofacSearchModel(): OfacSearchModel {
        return this._ofacSearchModel;
    }
    public set ofacSearchModel(value: OfacSearchModel) {
        this._ofacSearchModel = value;
    }

    constructor() {
        super();
    }
}
