export const LvSuspenseReason = [
  {
    tableName: 'LvSuspenseReason',
    code: 'ADJ',
    description: 'Adjustment',
    isActive: true
  },
  {
    tableName: 'LvSuspenseReason',
    code: 'RD',
    description: 'Redeposit',
    isActive: true
  }
];

export const CheckTypePaymentMethods = [
  'Check',
  'EFT',
  'Recurring E-check',
  'Lock Box'
];