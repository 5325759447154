import { createAction, props } from '@ngrx/store';

export const updateRecalculateValuationIsLoadingFromPropertySavingData = createAction(
  '[PropertySavingData] Update Recalculate Valuation Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateRecalculateValuationIsLoadingFromPropertyDetailsComponent = createAction(
  '[PropertyDetailsComponent] Update Recalculate Valuation Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateRecalculateValuationIsLoadingFromThirdPartyData = createAction(
  '[ThirdPartyData] Update Recalculate Valuation Is Loading',
  props<{ isLoading: boolean }>()
);