import { RiskDTO2 } from 'app/shared/models/data/dto/quick-quote/risk-dto2';
import { createAction, props } from '@ngrx/store';

export const updateBackupIsLoadingFormSubmissionManagementComponent = createAction(
  '[SubmissionManagementComponent] Update Backup Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateBackupDataFormSubmissionManagementComponent = createAction(
  '[SubmissionManagementComponent] Update Backup Data',
  props<{ data: RiskDTO2 }>()
);

export const updateBackupIsLoadingFormPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update Backup Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateBackupDataFormPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update Backup Data',
  props<{ data: RiskDTO2 }>()
);