import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { InterestLabelsConstants } from '../../../../shared/constants/interest.labels.constants';
import { InterestData } from '../../../../modules/submission-management/data/interest.data';
import { Pages } from '../../../../shared/constants/pages-and-sections.constants';
import { GeneralValidationService } from './general-validation.service';
import FormUtils from 'app/shared/utilities/form.utils';
import { QuoteService } from '../../quote.service';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';

@Injectable({
  providedIn: 'root'
})
export class InterestValidationService {

 // #region =====> Interest Page
 priorInsuranceSection: boolean;
 priorInsuranceSectionInvalidControls = [];
 private formTypeConstants = FormTypeConstants;

 get PriorInsuranceForm() {
   return this.interestData.priorInsuranceForm;
 }
// #endregion
  constructor(protected interestData: InterestData, protected generalValidationService: GeneralValidationService, protected quoteService: QuoteService) { }

  checkInterestPage(): void {
    this.priorInsuranceSection = this.checkPriorInsuranceSection();

    this.priorInsuranceSectionInvalidControls = this.generalValidationService.findInvalidControls(this.PriorInsuranceForm);

    this.generalValidationService.mapToInvalidFormControlList(this.priorInsuranceSectionInvalidControls, Pages.Interests.pageName, Pages.Interests.sections.PriorInsurance);
  }

checkPriorInsuranceSection() {
    const c = this.PriorInsuranceForm.get('priorInsurance').value;
    if (c === InterestLabelsConstants.priorCarrierList.PI0) {
      if (this.quoteService.formType !== this.formTypeConstants.HO4) {
        this.generalValidationService.resetValidatorFormControl(this.PriorInsuranceForm, 'priorCarrier', Validators.required);
      } else {
        this.generalValidationService.clearValidatorFormControl(this.PriorInsuranceForm, 'priorCarrier');
      }
      this.generalValidationService.resetValidatorFormControl(this.PriorInsuranceForm, 'priorExpirationDate', Validators.required);
    } else {
      this.generalValidationService.clearValidatorFormControl(this.PriorInsuranceForm, 'priorCarrier');
      this.generalValidationService.clearValidatorFormControl(this.PriorInsuranceForm, 'priorExpirationDate');
    }

    return FormUtils.validateForm(this.PriorInsuranceForm);
  }
}
