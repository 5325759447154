import { Action, createReducer, on } from '@ngrx/store';
import * as fromCalculateValuationActions from 'app/store/calculate-valuation/calculate-valuation.actions';
import { initialState, CalculateValuationState } from './calculate-valuation.state';

const _calculateValuationReducer = createReducer(
  initialState,
  on(
    fromCalculateValuationActions.updateCalculateValuationIsLoadingFromThirdPartyData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromCalculateValuationActions.updateCalculateValuationDataFromThirdPartyData,
    (state, { data }) => ({ ...state, data: data })
  )
);

export const calculateValuationReducer = (
  state: CalculateValuationState | null,
  action: Action
) => {
  return _calculateValuationReducer(state, action);
};