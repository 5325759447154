export class AgencyNoteDTO implements IAgencyNoteDTO {
	id?: string;
	agencyId?: string;
	category?: string;
	description?: string;
	details?: string;
	createdBy?: string;
	createdDate?: Date;
	removedBy?: string;
	removedProcessDate?: Date;
    isActive?: boolean;

	constructor(data?: IAgencyNoteDTO) {
		if (data) {
			for (const property in data) {
				if (data.hasOwnProperty(property)) {(<any>this)[property] = (<any>data)[property];}
			}
		}
	}
}

export interface IAgencyNoteDTO {
    id?: string;
	agencyId?: string;
	category?: string;
	description?: string;
	details?: string;
	createdBy?: string;
	createdDate?: Date;
	removedBy?: string;
	removedProcessDate?: Date;
    isActive?: boolean;
}
