export const GenericAddressLabelConstants = {
  addressType: 'Address Type',
  effectiveDate: 'Effective Date',
  expirationDate: 'Expiration Date',
  streetAddress1: 'Street Address',
  streetAddress2: 'Apt/Unit #',
  zipCode: 'Zip Code',
  state: 'State',
  city: 'City',
  garage: 'Garage',
  isIndoor: 'Indoor',
  isOutdoor: 'Outdoor',
  isFenced: 'Fenced',
  isLighted: 'Lighted',
  isWithSecurityGuard: 'With Security Guard',
  cancel: 'Cancel',
  stateCode: 'State Code',
  commissionType: 'Commission Type',
  commissionGroupName: 'Commission Group Name',
  formGroup: 'Form Group',
  program: 'Program',
  scheduleName: 'Schedule Name'
};