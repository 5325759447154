import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class NightlyJobGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const activateNightlyJob = environment.activateNightlyJob;

    if (activateNightlyJob) {
      //this._router.navigate(['nightlyjobtest']);
      return true;
    } else {
      this._router.navigate(['404']);
      return false;
    }
  }
}
