import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ErrorMessageConstant } from '../../../../../../app/shared/constants/error-message.constants';
import { BillingService } from '../../../../../../app/core/services/billing/billing.service';
import { PolicyBillingData } from '../../../../../../app/modules/policy-management/data/policy-billing.data';
import { BaseClass } from '../../../../../../app/shared/base-class';
import { BsModalRef } from 'ngx-bootstrap';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { PaymentMethod } from '../../../../../../app/shared/enum/payment-method.enum';
import { PolicyBillingLabelsConstants } from '../../../../../../app/shared/constants/policy-billing.labels.constants';
import { GetChangePayPlanResponseDTO } from '../../../../../../app/shared/models/data/dto/billing/change-payplan/get-change-payplan-response.dto';
import { BillingDetailDTO } from '../../../../../../app/shared/models/data/dto/billing/billing-detail.dto';
import { CreditCardDTO } from '../../../../../../app/shared/models/data/dto/billing/credit-card.dto';
import { BankAccountDTO } from '../../../../../../app/shared/models/data/dto/billing/bank-account.dto';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { BillingAddressDTO } from '../../../../../../app/shared/models/data/dto/billing/billing-address.dto';
import { ChangePayPlanRequestDTO } from '../../../../../../app/shared/models/data/dto/billing/change-payplan/change-payplan-request.dto';
import Utils from '../../../../../../app/shared/utilities/utils';
import NotifUtils from '../../../../../../app/shared/utilities/notif-utils';
import { take, takeUntil } from 'rxjs/operators';
import { PayplanPaymentDetailsDTO } from '../../../../../../app/shared/models/data/dto/billing/change-payplan/payplan-payment-details.dto';
import { PaymentPlanListConstants } from '../../../../../../app/shared/constants/bind-and-issue.labels.constants';
import { EntityRiskData } from '../../../../../../app/modules/submission-management/data/entity-risk.data';
import Swal from 'sweetalert2';
import { AddressDTO3 } from '../../../../../../app/shared/models/data/dto/quick-quote/entity-address.dto';
import { AddressType } from '../../../../../../app/shared/enum/address-type.enum';
import { ApplicantData } from '../../../../../../app/modules/submission-management/data/applicant.data';
import { PostPaymentCreditCardComponent } from '../../payments/post-payments/post-payment-credit-card/post-payment-credit-card.component';
import { PostPaymentEftComponent } from '../../payments/post-payments/post-payment-eft/post-payment-eft.component';
import { GenericLabel } from '../../../../../../app/shared/constants/generic.labels.constants';
import { ZipCodeData } from '../../../../../../app/modules/submission-management/data/zipcode.data';
import { InterestSavingData } from '../../../../../../app/modules/submission-management/data/interest-saving.data';

@Component({
  selector: 'app-change-payment-plan-modal',
  templateUrl: './change-payment-plan-modal.component.html',
  styleUrls: ['./change-payment-plan-modal.component.scss']
})
export class ChangePaymentPlanModalComponent extends BaseClass implements OnInit {

  public event: EventEmitter<any> = new EventEmitter<any>();
  public ErrorMessageConstant = ErrorMessageConstant;
  currencyInputMode = CurrencyMaskInputMode.NATURAL;
  public ChangePaymentPlanConstants = PolicyBillingLabelsConstants.changePaymentPlan;
  public GenericLabel = GenericLabel;
  PaymentMethod = PaymentMethod;

  paymentMethods: any;
  currentPayPlan: string;
  payPlanOptions: any[];
  getChangePayplanResponse: GetChangePayPlanResponseDTO[];
  isPaymentRequired: boolean;

  public paymentPlanListConstants = PaymentPlanListConstants;

  @ViewChild('ccForm') ccFormRef: PostPaymentCreditCardComponent;
  @ViewChild('eftForm') eftFormRef: PostPaymentEftComponent;

  constructor(public bsModalRef: BsModalRef,
    public billingData: PolicyBillingData,
    protected billingService: BillingService,
    protected entityRiskData: EntityRiskData,
    protected zipCodeData: ZipCodeData,
    protected applicantData: ApplicantData,
    public interestSavingData: InterestSavingData) {

    super();
  }

  get isAgreeInsuredChecked(): boolean {
    if (this.billingData.changePaymentPlanForm.get('paymentMethod').value !== PaymentMethod.CashCheck && this.isPaymentRequired) {
      return this.billingData.changePaymentPlanForm.get('agreeInsured').value ?? false;
    } else {
      return true;
    }
  }

  get isCreditCardPaymentMethod(): boolean {
    return this.billingData.changePaymentPlanForm.get('paymentMethod').value === PaymentMethod.CreditCard ||
      this.billingData.changePaymentPlanForm.get('paymentMethod').value === PaymentMethod.RecurringCreditCard;
  }

  get hasBoundRenewal(): boolean {
    const risk = this.entityRiskData.getRisk();
    return risk.renewalCode === this.entityRiskData.pageHeaderConstants.policyRenewal.statusCode.RWD;
  }

  ngOnInit() {
    this.populateFields();

    this.currentPayPlan = this.billingData.getCurrentPayPlan();
    this.isPaymentRequired = this.getSelectedPayplanData().amountToPay > 0;

    this.setValidators();
  }

  populateFields(): void {
    this.billingData.changePaymentPlanForm.reset();
    this.billingData.changePaymentPlanForm.get('paymentMethod').setValue('');

    this.populatePaymentMethods();
    this.populatePayPlanOptions();

    this.billingData.paymentMethodChangedEvent.pipe(takeUntil(this.stop$)).subscribe(_ => {
      this.prefillPayeeInfo();
    });

    this.billingData.changePaymentPlanForm.get('newPlan').setValue(this.billingData.selectedPaymentPlanId);
    this.billingData.changePaymentPlanForm.get('newPlan').updateValueAndValidity();

    const amountToPay = this.getSelectedPayplanData().amountToPay;
    this.billingData.changePaymentPlanForm.get('paymentRequired').setValue(amountToPay);
    this.billingData.changePaymentPlanForm.get('amount').setValue(amountToPay);

    this.billingData.changePaymentPlanForm.get('payor').setValue('');
    if (this.billingData.changePaymentPlanForm.get('newPlan').value !== this.paymentPlanListConstants.fullPayCode) {
      this.billingData.changePaymentPlanForm.get('payor').disable();
    } else {
      this.billingData.changePaymentPlanForm.get('payor').enable();
    }
  }

  setValidators(): void {
    if (this.isPaymentRequired) {
      this.billingData.changePaymentPlanForm.get('paymentMethod').setValidators([Validators.required]);
      this.billingData.changePaymentPlanForm.get('amount').setValidators([Validators.required]);
    } else {
      this.billingData.changePaymentPlanForm.get('paymentMethod').clearValidators();
      this.billingData.changePaymentPlanForm.get('amount').clearValidators();
    }

    this.billingData.changePaymentPlanForm.get('paymentMethod').updateValueAndValidity();
    this.billingData.changePaymentPlanForm.get('amount').updateValueAndValidity();
  }

  protected getSelectedPayplanData(): GetChangePayPlanResponseDTO {
    return this.getChangePayplanResponse.filter(x =>
      this.convertPayplanFromBillingCode(x.paymentPlanId) === this.billingData.changePaymentPlanForm.get('newPlan').value)[0];
  }

  protected convertPayplanFromBillingCode(billingPayPlan: string): string {
    return this.billingData.paymentPlanChangePlan.filter(x => x.billingCode === billingPayPlan)[0].code;
  }

  protected convertPayplanToBillingCode(code: string): string {
    return this.billingData.paymentPlanChangePlan.filter(x => x.code === code)[0].billingCode;
  }

  onPayplanChange(): void {
    this.billingData.changePaymentPlanForm.get('payor').setValue('');
    if (this.billingData.changePaymentPlanForm.get('newPlan').value !== this.paymentPlanListConstants.fullPayCode) {
      this.billingData.changePaymentPlanForm.get('payor').disable();
    } else {
      this.billingData.changePaymentPlanForm.get('payor').enable();
    }

    const amountToPay = this.getSelectedPayplanData().amountToPay;
    this.billingData.changePaymentPlanForm.get('paymentRequired').setValue(amountToPay);
    this.billingData.changePaymentPlanForm.get('amount').setValue(amountToPay);

    this.isPaymentRequired = amountToPay > 0;

    this.setValidators();
  }

  onPostChangePaymentPlan(): void {

    NotifUtils.showConfirmMessage(PolicyBillingLabelsConstants.changePayplanConfirmation, () => {
      const riskId: string = this.entityRiskData.getRiskId();
      const riskDetailId: string = this.entityRiskData.getRiskDetailId();

      const changePayplanRequest: ChangePayPlanRequestDTO = this.getChangePaymentPlanRequest(riskId, riskDetailId);

      Utils.blockUI();
      this.billingService.postChangePayplan(changePayplanRequest).pipe(takeUntil(this.stop$)).subscribe(result => {
        Utils.unblockUI();
        const changePaymentPlanInvoice = result.invoiceDTO;
        const payPlanCode = this.billingData.paymentPlan.filter(plan => plan.billingCode.toLowerCase() === changePaymentPlanInvoice.paymentPlan.toLowerCase())[0].code;
        this.entityRiskData.updateCurrentRiskBindBillingPaymentPayPlan(payPlanCode);
        this.billingData.populateBillingSections(riskId);
        this.bsModalRef.hide();

        let successMessage = PolicyBillingLabelsConstants.successChangePayplan;
        if (changePaymentPlanInvoice.isNoLongerEligibleForRecurring) {
          successMessage += '. Pay plan is no longer eligible for recurring';
        }

        if (!result.isRenewalChangePayPlanSuccess) {
          successMessage += `. ${PolicyBillingLabelsConstants.failedRenewalChangePayplan}`;
        } else {
          this.entityRiskData.updateCurrentRiskBindBillingNextTermPaymentPayPlan(payPlanCode);
        }

        this.billingData.setSelectedPayorOption();

        NotifUtils.showSuccess(successMessage, () => this.showPaymentReceipt(result.paymentReceiptEmailBody));
      },
        err => {
          Utils.unblockUI();
          NotifUtils.showMultiLineError(err.error?.message);
        }
      );
    });
  }

  protected getChangePaymentPlanRequest(riskId: string, riskDetailId: string): ChangePayPlanRequestDTO {
    let billingMethod: string;
    let instrumentType: string;

    if (this.isPaymentRequired) {
      billingMethod = this.billingData.changePaymentPlanForm.get('paymentMethod').value;
      instrumentType = this.billingData.paymentMethodsPostPayment.filter(x => x.code === billingMethod)[0].billingCode;
    }

    const payplanDetails: PayplanPaymentDetailsDTO = this.getSelectedPayplanData().details;

    let calcTotalAmount: number;
    if (this.isPaymentRequired) {
      calcTotalAmount = this.billingData.changePaymentPlanForm.get('amount').value;
    } else {
      calcTotalAmount = 0;
    }
    const calcPremiumAmount: number = calcTotalAmount - (payplanDetails.feeAmount + payplanDetails.taxAmount);

    const paymentRequest: ChangePayPlanRequestDTO = {
      riskId: riskId,
      riskDetailId: riskDetailId,
      newPaymentPlanId: this.convertPayplanToBillingCode(this.billingData.changePaymentPlanForm.get('newPlan').value),
      paymentDetails: {
        totalAmount: calcTotalAmount,
        premiumAmount: Math.round(calcPremiumAmount * 100) / 100,
        feeAmount: payplanDetails.feeAmount,
        taxAmount: payplanDetails.taxAmount
      },
      selectedPayorRiskInterestDetailId: this.billingData.changePaymentPlanForm.get('payor').value === '' ? null : this.billingData.changePaymentPlanForm.get('payor').value
    };

    if (this.isPaymentRequired) {
      paymentRequest.instrumentId = instrumentType;
      paymentRequest.billingDetail = this.getBillingDetail(billingMethod);

      if (billingMethod === PaymentMethod.CreditCard) {
        paymentRequest.emailAddress = this.billingData.changePaymentPlanForm.get('creditCard').get('email').value;
      } else if (billingMethod === PaymentMethod.EFT) {
        paymentRequest.emailAddress = this.billingData.changePaymentPlanForm.get('eCheck').get('email').value;
      }

      paymentRequest.isAgreeOneTimePayment = this.billingData.changePaymentPlanForm.get('agreeOneTimePayment').value ?? false;
      paymentRequest.isAgreeInsured = this.billingData.changePaymentPlanForm.get('agreeInsured').value ?? false;
    }

    return paymentRequest;
  }

  protected getBillingDetail(billingMethod: string): BillingDetailDTO {
    const billingDetail: BillingDetailDTO = new BillingDetailDTO();

    if (billingMethod === PaymentMethod.CreditCard) {
      const ccForm = this.billingData.changePaymentPlanForm.get('creditCard');
      const ccType = ccForm.get('creditCardTypeId').value;
      const ccTypeBilling = this.billingData.getCreditCardTypeBillingCode(ccType);

      const creditCardDetail: CreditCardDTO = {
        cardCode: ccForm.get('cardCode').value,
        cardNumber: ccForm.get('cardNumber').value,
        expirationDate: ccForm.get('expirationYear').value + '-' + ('00' + ccForm.get('expirationMonth').value).slice(-2),
        cardType: ccTypeBilling
      };

      billingDetail.creditCard = creditCardDetail;
      billingDetail.billingAddress = this.getBillingAddress(ccForm);
    } else if (billingMethod === PaymentMethod.EFT) {
      const eftForm = this.billingData.changePaymentPlanForm.get('eCheck');

      const accountType: string = eftForm.get('accountType').value;
      const accountTypeBilling: string = this.billingData.getAccountTypeBillingCode(accountType);

      const bankAccount: BankAccountDTO = {
        accountNumber: eftForm.get('accountNumber').value,
        accountType: accountTypeBilling,
        nameOnAccount: eftForm.get('nameOnAccount').value,
        routingNumber: eftForm.get('routingNumber').value,
      };

      billingDetail.bankAccount = bankAccount;
      billingDetail.billingAddress = this.getBillingAddress(eftForm);
    }

    return billingDetail;
  }

  protected getBillingAddress(form: AbstractControl): BillingAddressDTO {
    const billingAddress: BillingAddressDTO = {
      firstName: form.get('firstName').value,
      lastName: form.get('lastName').value,
      address: form.get('address').value,
      city: form.get('city').value,
      state: form.get('state').value,
      zip: form.get('zip').value,
    };

    return billingAddress;
  }

  protected populatePaymentMethods(): void {
    this.paymentMethods = this.billingData.paymentMethodsPostPayment.filter(x => x.code !== PaymentMethod.CashCheck &&
      x.code !== PaymentMethod.RecurringCreditCard &&
      x.code !== PaymentMethod.RecurringECheck);
  }

  protected populatePayPlanOptions(): void {
    this.payPlanOptions = this.billingData.LvPayPlanOptions.filter(x => x.code !== this.billingData.initialPaymentPlanId);
  }

  isPaymentRequiredMet(): boolean {
    return this.billingData.changePaymentPlanForm.get('amount').value >= this.getSelectedPayplanData().amountToPay;
  }

  onCancel(): void {
    this.billingData.selectedPaymentPlanId = this.billingData.initialPaymentPlanId;

    this.bsModalRef.hide();
  }

  protected showPaymentReceipt(emailBody: string): void {
    if (emailBody) {
      Swal.fire(
        {
          customClass: {
            content: 'email-receipt-content-style',
          },
          width: 900,
          html: emailBody,
          showCloseButton: true,
          showConfirmButton: false,
          focusConfirm: false,
        });
    }
  }

  prefillPayeeInfo(): void {
    let address: AddressDTO3;
    if (this.entityRiskData.EntityRisk.isMailingAddressSameInsured) {
      address = this.entityRiskData?.EntityRisk?.entityAddresses.find(a => a.addressTypeCode === AddressType.Property)?.address;
    } else {
      address = this.entityRiskData?.EntityRisk?.entityAddresses.find(a => a.addressTypeCode === AddressType.Mailing)?.address;
    }

    const firstName: string = this.applicantData.mainApplicant.get('firstName').value;
    const lastName: string = this.applicantData.mainApplicant.get('lastName').value;
    const address1: string = address.streetAddress1;
    const zip: string = address.zipCode;
    const city: string = address.city;
    const email: string = this.applicantData.contact.get('emailAddress').value;

    const paymentForm: FormGroup = this.billingData.getCurrentPaymentForm(this.billingData.changePaymentPlanForm);

    if (paymentForm && !paymentForm.touched) {
      paymentForm.get('firstName').setValue(firstName);
      paymentForm.get('lastName').setValue(lastName);
      paymentForm.get('address').setValue(address1);
      paymentForm.get('zip').setValue(zip);

      const emailField = paymentForm.get('email');
      if (emailField) {
        emailField.setValue(email);
      }

      paymentForm.get('firstName').markAsTouched();

      this.zipCodeData.getCityListCompleted$.pipe(take(1)).subscribe(() => {
        paymentForm.get('city').setValue(city);
      });

      const isUSCountry = address?.countryCode === 'USA' || address?.countryCode === 'US';
      const paymentMethod = this.billingData.changePaymentPlanForm.get('paymentMethod').value;
      if (paymentMethod === PaymentMethod.CreditCard || paymentMethod === PaymentMethod.RecurringCreditCard) {
        this.ccFormRef.setZipCode(isUSCountry);
      } else if (paymentMethod === PaymentMethod.EFT || paymentMethod === PaymentMethod.RecurringECheck) {
        this.eftFormRef.setZipCode(isUSCountry);
      }
    }

    this.billingData.hasOneAgreeCheckbox = false;
    const paymentMethod = this.billingData.changePaymentPlanForm.get('paymentMethod').value;
    if (paymentMethod === PaymentMethod.CreditCard || paymentMethod === PaymentMethod.EFT){
      this.billingData.changePaymentPlanForm.controls.agreeOneTimePayment.setValue(false);
    }
  }

  get isSaveButtonEnabled(): boolean {
    return this.billingData.changePaymentPlanForm.valid && this.isPaymentRequiredMet() && this.AgreeCheckboxRequired;
  }
  
  get AgreeCheckboxRequired(): boolean {
    if (!this.isPaymentRequired){
      return true
    }
    else{
      return this.billingData.hasOneAgreeCheckbox
    }
  }
}
