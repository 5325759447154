import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentsLabelConstants } from '../../../../../shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { take, takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../../../shared/base-class';
import { QuickQuoteService } from '../../../../../../app/core/services/submission/quick-quote.service';
import { PolicyBillingData } from '../../../../../../app/modules/policy-management/data/policy-billing.data';
import { PolicyBillingLabelsConstants } from '../../../../../../app/shared/constants/policy-billing.labels.constants';
import { BillingService } from '../../../../../../app/core/services/billing/billing.service';
import { SuspendedPaymentsLabel } from '../../../../../../app/shared/constants/suspended-payments.labels.constants';
import { DocumentUploadModalComponent } from '../../../../../../app/modules/policy-management/policy-billing/payments/related-documents/document-upload-modal/document-upload-modal.component';
import { PaymentSuspendedDocDTO } from '../../../../../../app/shared/models/data/dto/billing/payment-suspended-doc.dto';
import Utils from '../../../../../../app/shared/utilities/utils';
import NotifUtils from '../../../../../../app/shared/utilities/notif-utils';
import { SuspendedPaymentsData } from '../../../../../modules/payments/data/suspended-payments.data';
import { PolicyService } from '../../../../../../app/core/services/submission/policy.service';
import { UserService } from 'app/core/services/management/user.service';
import { AuthService } from 'app/core/services/auth.service';

@Component({
  selector: 'app-suspended-payment-related-doc',
  templateUrl: './suspended-payment-related-doc.component.html',
  styleUrls: ['./suspended-payment-related-doc.component.scss']
})
export class SuspendedPaymentRelatedDocComponent extends BaseClass implements OnInit, OnDestroy {

  public SuspendedPaymentsLabel = SuspendedPaymentsLabel;
  public ErrorMessageConstant = ErrorMessageConstant;

  @Input() suspendedPaymentId: string;
  @Input() isAdd: boolean = false;
  @Input() batchPaymentDetailId: string;


  PolicyBillingLabelsConstants = PolicyBillingLabelsConstants;
  DocumentLabelConstants = DocumentsLabelConstants;

  GenericLabel = GenericLabel;
  isOpen: boolean = true;
  modalRef: BsModalRef | null;
  defaultGUID = '00000000-0000-0000-0000-000000000000';

  userFullName: string;

  constructor(
    public policyBillingData: PolicyBillingData,
    private modalService: BsModalService,
    public quickQuoteService: QuickQuoteService,
    private billingService: BillingService,
    private suspendedPaymentsData: SuspendedPaymentsData,
    private policyService: PolicyService,
    private userService: UserService,
    private authService: AuthService) {
    super();
  }

  ngOnInit() {
    if (this.suspendedPaymentId) {
      this.suspendedPaymentsData.getDetailedSuspendedView(this.suspendedPaymentId);
      this.suspendedPaymentsData.resetDocumentVariables();
      this.loadTableItems();
    }

    this.getUserfullNameForUploadingDocs(this.authService.getUserId());
  }

  get paymentDocumentTableRows() {
    return this.suspendedPaymentsData.paymentDocumentTableRows;
  }

  loadTableItems(): void {
    Utils.blockUI();
    this.suspendedPaymentsData.paymentDocumentTableRows = [];
    if (this.suspendedPaymentId && (!this.batchPaymentDetailId || this.batchPaymentDetailId === this.defaultGUID)) {
      this.billingService.listSuspendedPaymentDoc(this.suspendedPaymentId).pipe(takeUntil(this.stop$)).subscribe(res => {
        if (res) {
          this.suspendedPaymentsData.paymentDocumentTableRows = res;
          this.billingService.getSuspendedPaymentDetail(this.suspendedPaymentId).subscribe(response => {
            if (response) {
              if (response.checkImgLink) {
                this.suspendedPaymentsData.paymentDocumentTableRows.push({description: 'Check Image',filePath: response.checkImgLink, imgUploadDate: response.imgUploadDate });
              }
              if (response.envelopeImgLink) {
                this.suspendedPaymentsData.paymentDocumentTableRows.push({description: 'Envelope Image',filePath: response.envelopeImgLink, imgUploadDate: response.imgUploadDate });
              }

              if (response.invoiceImgLink) {
                this.suspendedPaymentsData.paymentDocumentTableRows.push({description: 'Invoice Image',filePath: response.invoiceImgLink, imgUploadDate: response.imgUploadDate});
              }

              if (response.suspendedMiscImage?.length > 0) {
                for (const miscImg of response.suspendedMiscImage) {
                  this.suspendedPaymentsData.paymentDocumentTableRows.push({description: miscImg.imgDesc,filePath: miscImg.imgLink, imgUploadDate: response.imgUploadDate });
                }
              }
            }
          });
        }
        Utils.unblockUI();
      });
    }


    if (this.suspendedPaymentId && (this.batchPaymentDetailId && this.batchPaymentDetailId !== this.defaultGUID)) {
      this.billingService.listSuspendedPaymentDocByBatchPaymentId(this.batchPaymentDetailId).pipe(takeUntil(this.stop$)).subscribe(res => {
        if (res) {
          this.suspendedPaymentsData.paymentDocumentTableRows = res;
        }
        Utils.unblockUI();
      });
    }

  }

  onUploadClick(): void {
    const initialState = {
      title: this.DocumentLabelConstants.addTitle,
      hideCategoryField: true,
    };
    this.modalRef = this.modalService.show(DocumentUploadModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.content.event.pipe(takeUntil(this.stop$)).subscribe((res) => {
      const request: PaymentSuspendedDocDTO = {
        suspendedPaymentId: this.suspendedPaymentId,
        fileName: res.data.documentFile.name,
        filePath: res.data.documentFile.name,
        description: res.data.description,
        isActive: true,
        file: res.data.documentFile
      };

      if (this.suspendedPaymentId) {
        this.suspendedPaymentsData.documentRequest = [];
      }

      this.suspendedPaymentsData.documentRequest.push(request);

      if (this.suspendedPaymentId) {
        this.suspendedPaymentsData.addSuspendedPaymentDoc(this.suspendedPaymentId);

        this.suspendedPaymentsData.isDocumentUploadSuccess.pipe(take(1)).subscribe(value => {
          if (value) {
            this.loadTableItems();
          }
        });
      } else {
        this.setTemporaryTableView(request);
      }
    });
  }

  onViewClick(filePath: string, fileName: string): void {
    this.policyService.generateSASURL(Utils.URLEncoder(filePath, fileName)).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
      window.open(resultSASURL, '_blank');
    });
  }

  onDeleteClick(document: any, index: number): void {
    if(document.id){
      NotifUtils.showConfirmMessage(this.SuspendedPaymentsLabel.relatedDocuments.deleteConfirmation, () => {
        Utils.blockUI();
        this.billingService.deleteSuspendedPaymentDoc(document.id).subscribe(_ => {
          Utils.unblockUI();
          this.loadTableItems();
        },
          err => {
            Utils.unblockUI();
            NotifUtils.showMultiLineError(err.error?.message);
          }
        );
      });
    } else {
      this.suspendedPaymentsData.paymentDocumentTableRows.splice(index, 1);
      this.suspendedPaymentsData.documentRequest.splice(index, 1);
    }
  }

  setTemporaryTableView(data: PaymentSuspendedDocDTO): void {
    const newEntry = {
      createdBy: data.createdBy ?? 0,
      createdDate: data.createdDate ?? new Date(Date.now()),
      description: data.description,
      fileName: data.fileName ?? '',
      filePath: data.filePath ?? '',
      id: data.id ?? '',
      isActive: true,
      isUploaded: true,
      riskId: null,
      suspendedPaymentId: data.suspendedPaymentId ?? '',
      createdByFullName: this.userFullName
    };
    this.suspendedPaymentsData.paymentDocumentTableRows.push(newEntry);
  }

  getUserfullNameForUploadingDocs(id: number) {
    Utils.blockUI();
    this.userService.getUserInfoById(id).subscribe(x => {
      this.userFullName = x.fullName;
      Utils.unblockUI();
    });
  }

  ngOnDestroy() {
    this.suspendedPaymentsData.resetDocumentVariables();
  }
}
