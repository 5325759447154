import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PathConstants } from 'app/shared/constants/path.constants';
import { AppAsideModule } from '@coreui/angular';
import { ModalModule } from 'ngx-bootstrap';
import { RefundComponent } from './refund.component';
import { RefundApprovalGuard } from 'app/core/guards/refund-approval.guard';
import { AuthGuard } from 'app/core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: RefundComponent,
    canActivate: [AuthGuard, RefundApprovalGuard],
    children: [
        {
          path: PathConstants.Payments.Refund.Request,
          loadChildren: () => import('./request/request.module').then(m => m.RequestModule)
        },
        { path: '', redirectTo: PathConstants.Payments.Refund.Request, pathMatch: 'full' },
      ]
  },
  {
    path: '',
    redirectTo: PathConstants.Payments.Refund.Index,
    pathMatch: 'full'
  },
  // { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes), AppAsideModule, ModalModule.forRoot()],
  exports: [RouterModule],
  providers: []
})
export class RefundRoutingModule { }
