import { commonEnvironment } from './environment.common';

export const _settings = {
  production: true,
  ApiUrl: `https://centauriapi.rivtechglobal.com/api`,
  ApiLexisNexisDevUrl: `https://centauriapi.rivtechglobal.com/api`,
  IdentityServiceUrl: `https://identity.rivtechglobal.com`,
  GenericServiceUrl: `https://genericapi.rivtechglobal.com`,
  ClientManagementUrl: `https://agencyapi.rivtechglobal.com`,
  CentauriServiceUrl: `https://centauriapi.rivtechglobal.com`,
  CentauriRaterServiceUrl: `https://centauriraterapi.rivtechglobal.com`,
  LoginUrl: `https://star.centauriinsurance.com`,
  BillingServiceUrl: `https://centauribillingapi.rivtechglobal.com`,
  BillingAppId: 'CENT',
  ApplicationId: 1,
  AzureNightlyJobUrl: 'https://func-nightlyjob-prod.azurewebsites.net/api/NJDurableFunctionHTTP?code=uJCsrxUsQPR7E72a3fnG0Wi/pJ7mVW3pwMHDSJCr7mREweavBnSwAQ==',
  showChangeServerDate: false,
  showSkipTwoFactor: false,
  activateNightlyJob: false,
  centauriHomePageURL: 'https://centauriinsurance.com/'
};
export const environment = Object.assign({}, commonEnvironment, _settings);
