import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Rater } from 'app/shared/constants/rater.constant';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { LayoutService } from '../../../../core/services/layout/layout.service';
import { SubmissionListData } from '../../data/submission-list.data';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BaseClass } from 'app/shared/base-class';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { FilterSubmissionListDTO } from 'app/shared/models/data/dto/submission-list/filter-submission-list.dto';

@Component({
  selector: 'app-la-submission-list',
  templateUrl: './submission-list.component.html',
  styleUrls: ['./submission-list.component.scss']
})
export class SubmissionListComponent extends BaseClass implements OnInit, OnDestroy {

  protected searchQuery: string = '';
  protected searchStatus: string = '';
  billingType: string;
  riskData: string[] = [];

  constructor(
    protected layoutService: LayoutService,
    protected submissionListData: SubmissionListData,
    protected storage: LocalStorageService,
    protected http: HttpClient,
    public route: ActivatedRoute
  ) {
    super();
    this.storage.store(Rater.enableRaterAudit, true);
  }

  ngOnInit(): void {
    this.layoutService.clearMenu();
    this.submissionListData.isSubmissionListPage = true;
    this.submissionListData.showLoader = true;
    localStorage.removeItem('entityId');

    this.route.queryParamMap.pipe(takeUntil(this.stop$)).subscribe(queryParams => {
      this.searchQuery = queryParams.get('q')?.toString()?.trim();
      this.searchStatus = queryParams.get('status')?.toString()?.trim();
      this.billingType =  queryParams.get('billingType')?.toString();
      queryParams.getAll('data').map(x => this.riskData.push(x));
    });

    if ((this.searchQuery === '' || this.searchQuery === undefined || this.searchQuery === null) && !this.searchStatus && (!this.billingType && this.riskData.length <= 0)) {
      this.submissionListData.searchFilterForm = this.submissionListData.constructFormGroup();
    }

    if (this.billingType !== '' && this.riskData.length > 0) {
      this.submissionListData.isForPaymentDue = true;
      this.submissionListData.searchFilterForm =  this.submissionListData.constructFormGroupForPaymentDue(this.riskData);
    }
  }

  setSearchFormValue(frmgrp: FormGroup) {
    this.submissionListData.currentPage = 1;
    this.submissionListData.buildSubmissionListRequest(this.submissionListData.searchFilterForm);
    this.submissionListData.callSubmissionList();
  }

  ngOnDestroy() {
    this.submissionListData.submissionListRequest = new FilterSubmissionListDTO();
    this.submissionListData.isSubmissionListPage = false;
    this.submissionListData.noResult = false;
    this.submissionListData.isForPaymentDue = false;
  }
}
