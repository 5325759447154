import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CommissionPaymentDTO, GetCommissionPaymentRequest, ICommissionPaymentDTO } from '../../../shared/models/data/dto/nacha/commission-payment.dto';
import { Store } from '@ngrx/store';
import { BaseClass } from 'app/shared/base-class';
import {
  updateCommissionPaymentListFromCommissionPaymentData,
  updateFilteredCommissionPaymentListFromCommissionPaymentData
} from '../../../store/commission/commission-payment/commission-payment.actions';
import { FormGroup } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { AgencyDTO } from 'app/shared/models/management/agency-management/agencyDto';
import { CustomValidators } from 'app/shared/validators/custom.validator';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { CommissionPaymentService } from 'app/core/services/commission-payment/commission-payment.service';
import Utils from 'app/shared/utilities/utils';
import NotifUtils from 'app/shared/utilities/notif-utils';

@Injectable({
    providedIn: 'root',
  })

export class CommissionPaymentData extends BaseClass {

  public agencyList: AgencyDTO[];
  public genericConstants = GenericConstants;
  commissionPaymentList: CommissionPaymentDTO[] = [];
  filteredCommissionPaymentList: CommissionPaymentDTO[] = [];
  commissionPaymentForm: FormGroup;

  isCommissionPaymentModalOpen: boolean = false;

  itemPerPage: number = 5;
  currentPage: number = 1;
  totalRowCount: number = 0;
  searchInput: string = '';
  showPreloader: boolean = false;

  agencyListLoading: boolean = false;
  public agencyNameList: any[];

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private commissionPaymentService: CommissionPaymentService
  ) {
    super();
  }

  initializeForm(isAdd?: boolean, isEdit?: boolean, isView?: boolean, commissionPayment?: CommissionPaymentDTO): void {
    this.commissionPaymentForm = this.fb.group({
      type: new FormControl(null, [Validators.required]),
      agencyName:  new FormControl(null, [Validators.required]),
      agencyCode: new FormControl(null),
      agencyId: new FormControl(null),
      year: new FormControl(null, [Validators.required]),
      month: new FormControl(null, [Validators.required]),
      postDate: new FormControl(null),
      transactionDate: new FormControl(null),
      amount: new FormControl(null, [Validators.required]),
      checkNumber: new FormControl(null),
      remarks: new FormControl(null, [Validators.required]),
      file: ['', [Validators.required, CustomValidators.requiredFileFormat(this.genericConstants.acceptedFileTypes)]],
      fileName: new FormControl(null),
      filePath: new FormControl(null),
    });

    if (isAdd) {

    }

    if ((isView || isEdit) && commissionPayment) {
      this.populatesCommissionPaymentForm(commissionPayment);

      if(isView) {
        this.commissionPaymentForm.disable();
        this.commissionPaymentForm.get('postDate').disable();
        this.commissionPaymentForm.get('transactionDate').disable();
      }
    }
  }

  populatesCommissionPaymentForm(commissionPayment: CommissionPaymentDTO): void {
    this.commissionPaymentForm.get('type').setValue(commissionPayment.type);
    this.commissionPaymentForm.get('agencyName').setValue(commissionPayment.agencyName);
    this.commissionPaymentForm.get('agencyCode').setValue(commissionPayment.agencyCode);
    this.commissionPaymentForm.get('agencyId').setValue(commissionPayment.agencyId);
    this.commissionPaymentForm.get('year').setValue(commissionPayment.year);
    this.commissionPaymentForm.get('month').setValue(commissionPayment.month);
    this.commissionPaymentForm.get('postDate').setValue({ isRange: false, singleDate: { jsDate: new Date(commissionPayment.postDate) } });
    this.commissionPaymentForm.get('transactionDate').setValue({ isRange: false, singleDate: { jsDate: new Date(commissionPayment.transactionDate) } });
    this.commissionPaymentForm.get('amount').setValue(commissionPayment.amount);
    this.commissionPaymentForm.get('checkNumber').setValue(commissionPayment.checkNumber);
    this.commissionPaymentForm.get('remarks').setValue(commissionPayment.remarks);
    this.commissionPaymentForm.get('filePath').setValue(commissionPayment.documentURL);
    if (commissionPayment.documentURL) {
      const filename = commissionPayment.documentURL.split('/').pop();
      this.commissionPaymentForm.get('file').setValue(filename);
    }
  }

  initCommissionPaymentList(request?: GetCommissionPaymentRequest): void {
    this.showPreloader = true;
    if (!request) {
      request = {
        search: '',
        pageNumber: 1,
        pageSize: 5
      };
    }
    this.currentPage = 1;
    this.commissionPaymentList = [];
    this.commissionPaymentService.getCommissionPayment(request).subscribe(result => {
      this.store.dispatch(updateCommissionPaymentListFromCommissionPaymentData({ commissionPaymentList: result.resultList }));
      this.currentPage = result.paginationDetails.currentPage;
      this.totalRowCount = result.paginationDetails.totalCount;
      this.commissionPaymentList = result.resultList;
      this.showPreloader = false;
    }, (error) => {
      this.showPreloader = false;
      NotifUtils.showError(error.message);
    });
  }

  initializeCommissionPayentRequest(commissionPayment: ICommissionPaymentDTO): ICommissionPaymentDTO {
    const request: ICommissionPaymentDTO = {
      id: commissionPayment.id,
      agencyId: commissionPayment.agencyId,
      amount: commissionPayment.amount,
      year: commissionPayment.year,
      month: commissionPayment.month,
      postDate: commissionPayment.postDate,
      transactionDate: commissionPayment.transactionDate,
      checkNumber: commissionPayment.checkNumber,
      remarks: commissionPayment.remarks,
      type: commissionPayment.type,
      file: commissionPayment.file
    };

    return request;
  }
}