import { Injectable } from '@angular/core';
import { QuickQuoteService } from '../../../core/services/submission/quick-quote.service';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { SummaryData } from './summary.data';
import { NavigationService } from '../../../core/services/navigation/navigation.service';
import { BaseClass } from '../../../shared/base-class';
import Utils from '../../../shared/utilities/utils';
import { ThirdPartyDataService } from '../../../core/services/submission/third-party/third-party-data.service';
import { RiskPropertyDTO2 } from '../../../shared/models/data/dto/quick-quote/risk-property.dto';
import { SavePropertyDTO } from '../../../shared/models/data/dto/quick-quote/save-property.dto';
import { ThirdPartyData } from './third-party.data';
import { PropertyData } from './property.data';
import { PathConstants } from '../../../shared/constants/path.constants';
import { InfoMessageConstant } from '../../../shared/constants/info-message.constants';
import { CoveragesData } from './coverages.data';
import { IIso360ValueResultDTO } from '../../../shared/models/data/dto/third-party/iso360value-result.dto';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { PolicySummaryData } from '../../../modules/policy-management/data/policy-summary.data';
import { EntityRiskData } from './entity-risk.data';
import { LocationLabelConstants, PropertyLabelConstants } from '../../../shared/constants/property.labels.constants';
import { EndorsementsSavingData } from './endorsements.saving.data';
import { FormTypeConstants } from '../../../shared/constants/form-types.constants';
import { CalculateRaterData } from 'app/core/services/submission/rater-premium/calculate-rater-data.service';
import { UWR } from 'app/core/services/submission/uw-referrals/uwr.service';
import { PolicyIssueData } from '../../policy-management/data/policy-issue.data';
import { SavingFlagConstants } from 'app/shared/constants/saving-flag.constants';
import { SubmissionPageData } from 'app/modules/submission-management/data/submission-page.data';
import { SubmissionConstants } from 'app/shared/constants/submission.constants';
import { PropertyBusinessRules } from '../business-rules/property.business-rules';
import { updatePropertiesIsLoadingFromPropertySavingData } from '../../../store/properties/properties.actions';
import { Store } from '@ngrx/store';
import { updateRecalculateValuationIsLoadingFromPropertySavingData } from 'app/store/recalculate-valuation/racalculate-valuation.actions';
import { QuoteService } from 'app/core/services/quote.service';
import { RiskStatusCode } from 'app/shared/models/data/dto/quick-quote/risk.dto';

@Injectable({
  providedIn: 'root'
})
export class PropertySavingData extends BaseClass {
  valuationFields: string[] = [
    'yearBuilt',
    'squareFootage',
    'exteriorWallFinish',
    'constructionType',
    'numOfStories',
    'roofShape',
    'roofMaterial',
    'foundationType'
  ];
  propertySavingDataComplete = new BehaviorSubject<boolean>(false);

  constructor(
    protected summaryData: SummaryData,
    protected thirdPartyDataService: ThirdPartyDataService,
    protected thirdPartyData: ThirdPartyData,
    protected quickQuoteService: QuickQuoteService,
    protected raterData: CalculateRaterData,
    public propertyData: PropertyData,
    protected coveragesData: CoveragesData,
    public policySummaryData: PolicySummaryData,
    public entityRiskData: EntityRiskData,
    protected endorsementsSavingData: EndorsementsSavingData,
    protected uwr: UWR,
    protected submissionData: SubmissionPageData,
    public propertyBusinessRules: PropertyBusinessRules,
    protected store: Store,
    protected quoteService: QuoteService
  ) {
    super();
  }

  saveProperty() {
    try {
      this.store.dispatch(updatePropertiesIsLoadingFromPropertySavingData({ isLoading: true }));
      this.entityRiskData.startSavingForms();
      this.quickQuoteService.setCategoryToSavingState(SavingFlagConstants.property);
      const riskId = this.entityRiskData.getRiskId();

      const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;

      this.summaryData.SummaryForm.get('coverageA').setValue(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').value);
      this.propertySavingDataComplete.next(false);

      if (riskId && riskDetailId) {
        Utils.blockUI();

        switch (this.summaryData.SummaryForm.get('formType').value) {
          case FormTypeConstants.HO4:
            // Directly save data from form to DB
            if (this.locationDetails()) {
              this.saveData(riskId, this.entityRiskData.getRiskDetailId());
              this.propertySavingDataComplete.next(false);
              this.valuationFields.forEach(field => this.propertyData.getPropertyForm().propertyDetails.get(field).markAsPristine());
            } else {
              this.saveData(riskId, this.entityRiskData.getRiskDetailId());
              this.propertySavingDataComplete.next(false);
            }
            break;
          case FormTypeConstants.HO3:
          case FormTypeConstants.HO6:
          case FormTypeConstants.DP3:
            this.store.dispatch(updateRecalculateValuationIsLoadingFromPropertySavingData({ isLoading: true }));

            this.thirdPartyDataService.iso360ValueRecalculateValuation(this.thirdPartyData.getIso360ValueRequestModel())
              .pipe(takeUntil(this.stop$)).subscribe(result => {
                if (this.locationDetails()) {
                  this.thirdPartyDataService.iso360ValueGetValuation(this.thirdPartyData.getIso360ValueGetValuationRequestModel())
                    .pipe(takeUntil(this.stop$)).subscribe(response => {
                      this.thirdPartyData.isDataFromOrderPrefill = false;
                      this.thirdPartyData.dataFromAPIResponse.iso360Value = response;
                      this.thirdPartyData.mapIso360ResultToProperty(response);

                      if (this.quoteService.formType === FormTypeConstants.HO3 ||
                        this.quoteService.formType === FormTypeConstants.DP3) {
                        this.calculateNewCoverage();
                      } else if (this.quoteService.formType === FormTypeConstants.HO4 ||
                        this.quoteService.formType === FormTypeConstants.HO6) {
                        this.coveragesData.calculateHO4HO6Coverages(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').value));
                      }
                      // Save coverages after recalculation of Cov B C D E
                      this.coveragesData.saveCoverages();
                      this.propertySavingDataComplete.next(false);
                      this.valuationFields.forEach(field => this.propertyData.getPropertyForm().propertyDetails.get(field).markAsPristine());
                      this.saveISO360Fields();
                      this.saveData(riskId, this.entityRiskData.getRiskDetailId());
                    },
                      (err) => {
                        Utils.unblockUI();
                        this.entityRiskData.finishSavingForms();
                      });
                } else {
                  this.saveISO360Fields();
                  this.saveData(riskId, this.entityRiskData.getRiskDetailId());
                  if (this.policySummaryData.formType === FormTypeConstants.HO3 && this.propertyData.isConstructionTypeChanged) {
                    this.coveragesData.calculateCoverages(this.coveragesData.validCovA);
                    this.propertyData.isConstructionTypeChanged = false;
                  }
                  this.propertySavingDataComplete.next(false);
                }

                this.store.dispatch(updateRecalculateValuationIsLoadingFromPropertySavingData({ isLoading: false }));
              },
                (err) => {
                  Utils.unblockUI();
                  this.entityRiskData.finishSavingForms();
                  this.store.dispatch(updateRecalculateValuationIsLoadingFromPropertySavingData({ isLoading: false }));
                }
              );
            break;
        }

      }
    } catch (error) {
      this.entityRiskData.finishSavingForms();
      this.quickQuoteService.setCategoryToNotSavingState(SavingFlagConstants.property);
      this.store.dispatch(updatePropertiesIsLoadingFromPropertySavingData({ isLoading: false }));
    }
  }

  protected calculateNewCoverage() {
    this.coveragesData.calculateCoverages(this.coveragesData.validCovA);
    if (this.policySummaryData.formType === FormTypeConstants.HO3) {
      this.coveragesData.updateCoverageB(this.coveragesData.coveragesAndDeductiblesForm.get('coverageBLimitId').value);
      this.coveragesData.updateCoverageC(this.coveragesData.coveragesAndDeductiblesForm.get('coverageCLimitId').value);
    }
  }

  getRiskPropertyPayload(riskDetailId: string): RiskPropertyDTO2 {
    const locationDetails = this.propertyData.locationDetails;
    const propertyDetails = this.propertyData.propertyDetails;
    const windMitigation = this.propertyData.windMitigation;
    const dataFromAPIResponse = this.thirdPartyData.dataFromAPIResponse;
    const formType = this.propertyData.quoteService.formType;
    const formTypeConstants = this.propertyData.formTypeConstants;

    const payload2: RiskPropertyDTO2 = {
      riskDetailId: riskDetailId,
      matchLevel: locationDetails.controls['matchLevel'].value,
      latitude: locationDetails.controls['latitude'].value,
      longitude: locationDetails.controls['longitude'].value,
      windPoolEligible: locationDetails.controls['windPoolEligible'].value,
      distanceToActualCoast: locationDetails.controls['distanceToActualCoast'].value,
      oceanName: locationDetails.controls['oceanName'].value,
      distanceToWater: locationDetails.controls['distanceToWater'].value,
      nearestWaterName: locationDetails.controls['nearestWaterName'].value,
      protectionClassCode: locationDetails.controls['protectionClass'].value !== '' ? locationDetails.controls['protectionClass'].value : '0',
      respondingFireDepartment: locationDetails.controls['respondingFireDept'].value,
      feetToHyrantCode: locationDetails.controls['feetToHydrant'].value,
      fireDistrictName: locationDetails.controls['fireDistrictName'].value,
      yearBuilt: Number(propertyDetails.controls['yearBuilt'].value),
      squareFootage: propertyDetails.controls['squareFootage'].value,
      raterConstructionTypeCode: propertyDetails.controls['constructionType'].value,
      isFlatTile: propertyDetails.controls['isFlatTile']?.value ? true : false,
      // roofAge: propertyDetails.controls['yearRoof'].value,
      raterExteriorWallFinishCode: propertyDetails.controls['exteriorWallFinish'].value,
      typeOfOccupancyCode: '',
      // structureUsageCode: locationDetails.controls['structureType'].value,
      numberMonthOccupied: formType !== formTypeConstants.HO4 ? Number(locationDetails.controls['numMonthsOccupied'].value) : null,
      isTheResidenceRented: formType !== formTypeConstants.HO4 ?  locationDetails.controls['isResidenceRented'].value : null,
      structureTypeCode: locationDetails.controls['structureType'].value,
      townhouseRowUnitCode: '',
      raterRoofShapeCode: propertyDetails.controls['roofShape'].value,
      raterFoundationTypeCode: formType !== formTypeConstants.HO6 ? propertyDetails.controls['foundationType'].value : (formType !== formTypeConstants.HO6 ? SubmissionConstants.foundationTypeAllForms : null),
      numberOfStory: propertyDetails.controls['numOfStories'].value,
      bceg: locationDetails.controls['bceg'].value,
      roofMaterialCode: propertyDetails.controls['roofMaterial'].value,
      isRoofMaterialFlatTile: formType === formTypeConstants.HO3 || formType === formTypeConstants.DP3 ? this.isRoofMaterialsFlatTileMaterial(propertyDetails.controls['roofMaterial']?.value) ? propertyDetails.controls['isFlatTile']?.value : false : null,
      replacementCost: formType === formTypeConstants.HO3 || formTypeConstants.DP3 ? propertyDetails.controls['replacementCost'].value : '',
      replacementCostDate: formType === formTypeConstants.HO3 || formTypeConstants.DP3 ? propertyDetails.controls['replacementCostDate'].value?.singleDate?.jsDate.toLocaleDateString('en-US') : '',
      purchaseDate: formType !== formTypeConstants.HO4 ? propertyDetails.controls['purchaseDate'].value?.singleDate?.jsDate.toLocaleDateString('en-US') : '',
      electricalCode: propertyDetails.controls['electrical'].value,
      primaryHeatTypeCode: propertyDetails.controls['primaryHeatType'].value,
      yearHeatingInstalledUpdated: formType !== formTypeConstants.HO4 ? propertyDetails.controls['yearHeating'].value : '',
      yearElectricalInstalledUpdated: formType !== formTypeConstants.HO4 ? propertyDetails.controls['yearElectrical'].value : '',
      yearPlumbingInstalledUpdated: formType !== formTypeConstants.HO4 ? propertyDetails.controls['yearPlumbing'].value : '',
      appFloodPolicyInforceCode: '',
      // floodPolicyNumber: "",
      // floodCarier: "",
      mitigationFormCode: windMitigation.controls['mitigationForm'].value,
      dateOfInspection: windMitigation.controls['dateInspection']?.value?.singleDate?.jsDate.toLocaleDateString('en-US'),
      isInternationalResBldgCode: windMitigation.controls['intlResidentialBuildingCd']?.value ? true : false,
      raterRoofCoverCode: windMitigation.controls['predominantRoofCovering'].value,
      permitAppDateInstallation: windMitigation.controls['permitAppDate'].value?.singleDate?.jsDate.toLocaleDateString('en-US'),
      roofDeckAttachementCode: windMitigation.controls['roofDeckAttachment'].value,
      roofWallAttachementCode: windMitigation.controls['roofToWallAttachment'].value,
      // roofGeometryCode: windMitigation.controls['roofGeometry'].value,
      raterRoofGeometryCode: this.roofGeometrySavingValue(windMitigation.controls['roofGeometry'].value, propertyDetails.controls['roofShape'].value),
      gableEndBracingCode: windMitigation.controls['gableEndBracing'].value,
      isSecondaryWaterResistance: windMitigation.controls['secondaryWaterResistance']?.value ? true : false,
      // secondaryWaterResistanceId: this.propertyData.windMitigation.controls['secondaryWaterResistance']?.value ? 1 : 0,
      raterOpeningProtectionCode: this.propertyData.windMitigation.controls['openingProtection'].value,
      qualifiedInspectorTypeCode: this.propertyData.windMitigation.controls['qualifiedInspectorType'].value,
      inspectorLicenseNumber: this.propertyData.windMitigation.controls['inspectorLicenseNumber'].value,
      terrain: this.propertyData.windMitigation.controls['terrain'].value,
      windSpeedCode: this.propertyData.windMitigation.controls['windSpeed'].value,
      raterWindDesignSpeedCode: this.propertyData.windMitigation.controls['windDesignSpeed'].value,
      internalPressureDesignCode: this.propertyData.windMitigation.controls['internalPressureDesign'].value,
      isWindBorneDebrisRegion: this.propertyData.windMitigation.controls['windBorneDebrisRegion'].value,
      mitigation: this.propertyData.windMitigation.controls['mitigation'].value,
      yearRoofInstalled: formType !== formTypeConstants.HO4 ? this.propertyData.propertyDetails.controls['yearRoof'].value : '',
      county: String(this.propertyData.locationDetails.controls['county'].value),
      isMilesToFireStationFromISO: this.thirdPartyData.dataFromAPIResponse.isMilesToFireStationFromISO,
      milesToFireStationCode: this.propertyData.locationDetails.controls['milesToFireStation'].value,
      numberOfUnitsBetweenFirewallsCode: formType === formTypeConstants.HO3 ||  formType === formTypeConstants.DP3 ? this.propertyData.locationDetails.controls['numberOfUnitsFirewalls'].value !== 'None' ? this.propertyData.locationDetails.controls['numberOfUnitsFirewalls'].value : 0 : '',
      isPropertyManager: formType !== formTypeConstants.HO4 ? this.propertyData.locationDetails.controls['propertyUsage'].value === LocationLabelConstants.SeasonalDwelling ? this.propertyData.locationDetails.controls['isPropertyManager']?.value : false : null,
      territoryCode: this.propertyData.locationDetails.controls['territoryCode'].value,
      valuationId: this.propertyData.locationDetails.controls['valuationId'].value,
      isCentralAirCondition: this.propertyData.propertyDetails.controls['isCentralAirConditioning'].value,
      yearCentralAirConditionSystemInstalled: formType !== formTypeConstants.HO4 ? this.propertyData.propertyDetails.controls['yearCentralAirConditioning'].value : '',
      foundationElevationCode: formType === formTypeConstants.HO3 || formType === formTypeConstants.DP3 ? this.isFoundationTypeHasFoundationElevation(propertyDetails.controls['foundationType']?.value) ? this.propertyData.propertyDetails.controls['foundationElevation'].value : null : null,
      propertyUsageCode: this.propertyData.locationDetails.controls['propertyUsage'].value,
      locationAddress: this.propertyData.locationDetails.controls['locationAddress'].value,  // No longer in use
      construction360Code: this.thirdPartyData.dataFromAPIResponse.iso360Value?.construction?.iso360ValueCode,
      exteriorWallFinish360Code: this.thirdPartyData.dataFromAPIResponse.iso360Value?.exteriorWallFinish?.iso360ValueCode,
      foundationType360Code: this.thirdPartyData.dataFromAPIResponse.iso360Value?.foundationType?.iso360ValueCode,
      roofMaterial360Code: this.thirdPartyData.dataFromAPIResponse.iso360Value?.roofMaterial?.iso360ValueCode,
      costPerSqft: this.thirdPartyData.dataFromAPIResponse.iso360Value?.costPerSqft,
      distanceToWaterCode: this.thirdPartyData.dataFromAPIResponse.distanceToWaterCode,
      distanceToActualCoastCode: this.thirdPartyData.dataFromAPIResponse.distanceToActualCoastCode,
      protectionClassName: this.propertyData.protectionClassName,
      isNoHitAddress: this.thirdPartyData.dataFromAPIResponse.iso360Value?.isNoHitAddress,
      isNoHitIso360ValueFirstCall: this.thirdPartyData.isNoHitIso360ValueFirstCall,
      floorUnitNumber: propertyDetails.controls['floorUnitNumber'].value,
      isoLocationPPC: this.thirdPartyData.dataFromAPIResponse.isoLocationPPC,
      squareFootage360Value: this.propertyData.squareFootage360Value,
      exteriorWallFinish360DefaultCode: this.propertyData.iso360ValueInitialValueForm.get('exteriorWallFinish360DefaultCode').value,
      foundationType360DefaultCode: this.propertyData.iso360ValueInitialValueForm.get('foundationType360DefaultCode').value,
      roofMaterial360DefaultCode: this.propertyData.iso360ValueInitialValueForm.get('roofMaterial360DefaultCode').value,
      roofShape360DefaultCode: this.propertyData.iso360ValueInitialValueForm.get('roofShape360DefaultCode').value,
      numberOfUnitsInsured: formType === formTypeConstants.DP3 ? this.propertyData.locationDetails.get('numberOfUnitsInsured').value : '',
      isoLocationPPCReturnSource: this.thirdPartyData.dataFromAPIResponse.isoLocationPPCReturnSource,
      distanceToActualCoastReturnSource: this.thirdPartyData.dataFromAPIResponse.distanceToCoastReturnSource,
      windPoolEligibleReturnSource: this.thirdPartyData.dataFromAPIResponse.windPoolEligibleReturnSource
    };
    this.propertyData.currentPropertyUsage = this.propertyData.locationDetails.controls['propertyUsage'].value;
    return payload2;
  }

  isRoofMaterialsFlatTileMaterial(code: string): boolean {
    return PropertyLabelConstants.IsRoofMaterialAFlatTileMaterials.includes(code);
  }

  isFoundationTypeHasFoundationElevation(code: string): boolean {
    return PropertyLabelConstants.IsFoundationTypeHasFoundationElevation.includes(code);
  }

  locationDetails(): boolean {
    const results: boolean[] = [];

    this.valuationFields.forEach(field => {
      results.push(this.propertyData.getPropertyForm().propertyDetails.get(field).dirty ? true : false);
    });

    return results.includes(true) ? true : false;
  }

  saveData(riskId?: any, riskDetailId?: any) {
    this.entityRiskData.isApiCallCompleted.next(false);
    if (this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId())) {
      this.endorsementsSavingData.saveEndorsements(); // call save endorsement
    }
    const riskProperty: RiskPropertyDTO2 = this.getRiskPropertyPayload(riskDetailId);
    const payLoad: SavePropertyDTO = {
      riskId: riskId,
      riskDetailId: riskDetailId,
      riskProperty: riskProperty
    };

    this.policySummaryData.savePropertyComplete$.next(false);

    this.quickQuoteService.postProperty(payLoad).pipe(takeUntil(this.stop$)).subscribe(res => {
      this.quickQuoteService.setCategoryToNotSavingState(SavingFlagConstants.property);
      const policyNumber = this.entityRiskData?.EntityRisk?.risks[0]?.policyNumber ?? '';
      this.raterData.propertyRaterRequest(this.propertyData, this.coveragesData, policyNumber, this.propertyBusinessRules);
      NotifUtils.showConsoleSuccess(`${PathConstants.Submission.Submission.Property} ${InfoMessageConstant.savedSuccessfullyMessage}`);

      this.thirdPartyData.iso360YearBuiltValue$.pipe(takeUntil(this.stop$)).subscribe(result => {
        if (!this.thirdPartyData.isCurrentlyOrdering) {
          Utils.unblockUI();
        }
      });

      if (!this.policySummaryData.isPolicy) {
        this.thirdPartyData.getISO360YearBuilt();
      }

      if (this.policySummaryData.isPolicy) {
        this.entityRiskData.updateEntityRiskDataProperty(payLoad);
        this.policySummaryData.subStatusCode$.next(res.riskSubStatusCode as RiskStatusCode);
      }

      this.entityRiskData.isApiCallCompleted.next(true);
      this.entityRiskData.finishSavingForms();
      // this.summaryData.isCalcPremium.next(true);

      this.policySummaryData.savePropertyComplete$.next(true);
      this.submissionData.propertiesSavingComplete.next();
      this.store.dispatch(updatePropertiesIsLoadingFromPropertySavingData({ isLoading: false }));
      if (!this.thirdPartyData.isCurrentlyOrdering) {
        Utils.unblockUI();
      }
    },
      err => {
        if (!this.thirdPartyData.isCurrentlyOrdering) {
          Utils.unblockUI();
        }
        this.entityRiskData.finishSavingForms();
        this.quickQuoteService.setCategoryToNotSavingState(SavingFlagConstants.property);
        this.summaryData.isCalcPremium.next(false);
        NotifUtils.showError(JSON.parse(err.error).errors.toString());
        this.entityRiskData.isApiCallCompleted.next(false);
        this.store.dispatch(updatePropertiesIsLoadingFromPropertySavingData({ isLoading: false }));
        // add code here for error log
      }
    );
  }

  saveISO360Fields() {
    this.thirdPartyDataService.saveIso360ValueData(this.thirdPartyData.iso360PropertyModel())
      .pipe(takeUntil(this.stop$)).subscribe((result) => {
        if (this.policySummaryData.isPolicy) {
          this.policySummaryData.subStatusCode$.next(result.riskSubStatusCode as RiskStatusCode);
        }
      });
  }

  protected roofGeometrySavingValue(roofGeometry: string, roofShape: string): string {
    const roofValue = roofGeometry && roofGeometry !== '' ? roofGeometry : roofShape;
    this.propertyData.mapRoofGeometry(roofValue);

    return this.propertyData.windMitigation.controls['roofGeometry'].value;
  }
}