import { Injectable } from '@angular/core';
import { BaseClass } from '../../../shared/base-class';
import { CoveragesData } from './coverages.data';
import { PropertyData } from './property.data';
import { LvUWApprovals } from '../../../shared/constants/uw.referrals';
import {
  ITableTh,
  ITableTr,
  ITableFormControl,
  ITableTd,
  IFile,
} from '../../../shared/models/dynamic/table.interface';
import { UWApprovalPageLabel } from 'app/shared/constants/uw-approval.labels.constants';
import { EntityRiskDTO } from 'app/shared/models/data/dto/quick-quote/entity-risk.dto';
import { UWApprovalModalModel } from 'app/shared/models/submission/uw-approval/uw-approval.model';
import { RiskUWApprovalDTO2 } from 'app/shared/models/data/dto/quick-quote/uw-approval.dto';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { v4 as uuidv4 } from 'uuid';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import * as _ from 'lodash';
import { AuthService } from 'app/core/services/auth.service';
import { GenericConstants, UserType } from 'app/shared/constants/generic.constants';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { SubmissionPageData } from 'app/modules/submission-management/data/submission-page.data';
import { SubmissionNavValidationService } from 'app/core/services/navigation/submission-nav-validation.service';
import { PolicyNavValidationService } from 'app/core/services/navigation/policy-nav-validation.service';
import { CoveragesValidationService } from 'app/core/services/submission/validations/coverages-validation.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { UwApprovalNotesModalComponent } from '../submission/uw-approval/uw-approval-notes-modal/uw-approval-notes-modal.component';
import { policyStatus } from 'app/shared/constants/policy-status.constants';
import { PolicyService } from 'app/core/services/submission/policy.service';
import { RiskStatus, RiskStatusCode } from 'app/shared/models/data/dto/quick-quote/risk.dto';
import { LvRiskStatus } from 'app/shared/constants/risk-status';
import { PolicyIssueData } from 'app/modules/policy-management/data/policy-issue.data';
import { ReinstatementRequestDTO, ReinstatementResponseDTO } from '../../../../app/shared/models/data/dto/policy-management/policy-reinstatement.dto';
import { PathConstants } from 'app/shared/constants/path.constants';
import { FormTypeConstants } from '../../../shared/constants/form-types.constants';
import { UwApprovalResultDTO } from 'app/shared/models/data/dto/quick-quote/uw-approval-result.dto';
import { RenewalConstants } from '../../../shared/constants/renewal-constants';
import { ApplicantData } from './applicant.data';
import { BillingService } from 'app/core/services/billing/billing.service';
import { updateSubmissionUWRsTableRowsFromUwApprovalData } from 'app/store/submission-uwrs/submission-uwrs.actions';
import { updatePolicyUWRsTableRowsFromUwApprovalData } from 'app/store/policy-uwrs/policy-uwrs.actions';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class UwApprovalData extends BaseClass {
  public genericConstants = GenericConstants;
  public policyStatus = policyStatus;
  public uwApprovalListAdded: any[] = [];
  public uwApprovalListRemoved: any[] = [];
  public uwApprovalListAddedOnNav: any[] = [];
  public uwApprovalListRemovedOnNav: any[] = [];
  public uwApprovalList: any[] = [];
  public uwApprovalList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public uwApprovalListOnNav$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public uwApprovalListComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public UWApprovalLabel = UWApprovalPageLabel;
  public uwAppovalListHasInvalid: boolean;
  public LvRiskStatus = LvRiskStatus;
  public isInitialFetch: boolean = true;
  public showLoader: boolean = false;
  uwaNotesModalRef: BsModalRef | null;
  uwaModalRef: BsModalRef | null;
  modalStatus: string[] = ['Approve', 'Reject'];
  statusOptions: any[] = [
    {
      tableName: 'LvUWApprovalStatus',
      tableDescription: 'UW Approval Status',
      code: 'Approve',
      description: 'Approve',
      isActive: true
    },
    {
      tableName: 'LvUWApprovalStatus',
      tableDescription: 'UW Approval Status',
      code: 'Reject',
      description: 'Reject',
      isActive: true
    },
    {
      tableName: 'LvUWApprovalStatus',
      tableDescription: 'UW Approval Status',
      code: 'AIR',
      description: 'Additional Information Required',
      isActive: true
    },
    {
      tableName: 'LvUWApprovalStatus',
      tableDescription: 'UW Approval Status',
      code: 'Pending',
      description: 'Pending',
      isActive: true
    }
  ];
  tableHeaders: ITableTh[] = [
    { value: this.UWApprovalLabel.description },
    { value: this.UWApprovalLabel.status }
  ];
  uwApprovalTableRows: ITableTr[] = [];
  uwApprovalTableRows$: BehaviorSubject<ITableTr[]> = new BehaviorSubject<ITableTr[]>([]);
  tableFormControls: ITableFormControl[] = [
    {
      name: 'field_1',
      type: 'string',
    },
    {
      name: 'field_2',
      type: 'string',
    },
    {
      name: 'field_3',
      type: 'string',
    },
    {
      name: 'field_4',
      type: 'string',
    },
  ];
  fileTableHeaders: ITableTh[] = [
    { value: this.UWApprovalLabel.fileTableHeaders.fileName },
    { value: this.UWApprovalLabel.fileTableHeaders.dateAdded },
  ];
  uwApprovalFileTableRows: ITableTr[] = [];
  public uwrSavedData$: BehaviorSubject<UwApprovalResultDTO | null> = new BehaviorSubject<UwApprovalResultDTO | null>(null);
  formType: string;
  formTypeConstants = FormTypeConstants;
  LvUWApprovals = LvUWApprovals;
  RenewalConst = RenewalConstants;
  renewalReferralTableRows: ITableTr[] = [];

  hasPolicyChanges: boolean = false;
  isPolicyChangesDone: boolean = true;

  selectedRowId: string;
  isUWR129Triggered: boolean = false;
  isUWR129TriggeredByExternal: boolean = false;
  triggerUWRSavingFromApplicant: boolean = false;
  triggerUWRSavingFromCoverages: boolean = false;
  hurricaneDeductibleChangesDescription: string;

  policyChangeSubscription: Subscription;
  renewalPolicyChangeSubscription: Subscription;

  isRaterCalculationDone: boolean = false;

  initUWRData$ = new Subject();

  constructor(
    protected coveragesData: CoveragesData,
    protected propertyData: PropertyData,
    protected entityRiskData: EntityRiskData,
    protected authService: AuthService,
    public policySummaryData: PolicySummaryData,
    public submissionData: SubmissionPageData,
    protected submissionNavValidationService: SubmissionNavValidationService,
    public policyNavValidationService: PolicyNavValidationService,
    protected coveragesValidationService: CoveragesValidationService,
    protected modalService: BsModalService,
    protected policyService: PolicyService,
    protected policyIssueData: PolicyIssueData,
    protected billingService: BillingService,
    protected store: Store
  ) {
    super();
    this.subscribeToUwApprovalSavedData();
  }

  // This method is used for checking if there are new UWR item/s triggered and added to the list.
  // This also converts the Non-Quoted Quote Status to Application.
  checkUwrChangesForQuote(uwrSavedData: UwApprovalResultDTO): void {
    if (
      this.uwrSavedData$.value === null &&
      uwrSavedData.riskUWApprovals.length === 0 &&
      this.submissionData.quoteStatus.value !== this.genericConstants.quoteStatus.quo &&
      this.submissionData.quoteStatus.value !== this.genericConstants.quoteStatus.app
    ) {
      this.convertToApplicationStatus();

      return;
    }

    if (
      this.uwrSavedData$.value !== null &&
      this.submissionData.quoteStatus.value !== this.genericConstants.quoteStatus.quo &&
      this.submissionData.quoteStatus.value !== this.genericConstants.quoteStatus.app
    ) {
      let currentUwrFromDB = _.cloneDeep(this.uwrSavedData$.value?.riskUWApprovals);
      let newUwrFromDB = _.cloneDeep(uwrSavedData?.riskUWApprovals);

      currentUwrFromDB = currentUwrFromDB.map(item => {
        UWApprovalPageLabel.uwrChangesExludedProperties.forEach((property: string) => {
          delete item[property];
        });

        return item;
      });

      newUwrFromDB = newUwrFromDB.map(item => {
        UWApprovalPageLabel.uwrChangesExludedProperties.forEach((property: string) => {
          delete item[property];
        });

        return item;
      });

      if (JSON.stringify(currentUwrFromDB) !== JSON.stringify(newUwrFromDB)) {
        this.convertToApplicationStatus();
      }
    }
  }

  convertToApplicationStatus(): void {
    this.submissionData.isValidForApplication = true;

    if (this.formType === this.formTypeConstants.DP3) {
      const propertyUsage = this.propertyData.locationDetails.get('propertyUsage')?.value;
      this.coveragesData.saveCoverages(false, false, false, propertyUsage);
    } else {
      this.coveragesData.saveCoverages(false, false, false);
    }
  }

  // This method is used for checking if there are new UWR item/s triggered and added to the list.
  // This also converts the Non-Pending Endorsement Policy Sub-status to Pending Endorsement.
  checkUwrChangesForPolicy(uwrSavedData: UwApprovalResultDTO): void {
    const pendingStatus = 'PEN';

    const draftRiskDetails = this.entityRiskData.getDraftRiskDetails();
    let currentUwrFromDB = _.cloneDeep(this.uwrSavedData$.value?.riskUWApprovals ?? draftRiskDetails?.riskUWApproval);
    let newUwrFromDB = _.cloneDeep(uwrSavedData?.riskUWApprovals);

    currentUwrFromDB = currentUwrFromDB.map(item => {
      UWApprovalPageLabel.uwrChangesExludedProperties.forEach((property: string) => {
        delete item[property];
      });

      return item;
    });

    newUwrFromDB = newUwrFromDB.map(item => {
      UWApprovalPageLabel.uwrChangesExludedProperties.forEach((property: string) => {
        delete item[property];
      });

      return item;
    });

    currentUwrFromDB = _.sortBy(currentUwrFromDB, ['id']);
    newUwrFromDB = _.sortBy(newUwrFromDB, ['id']);

    if (JSON.stringify(currentUwrFromDB) !== JSON.stringify(newUwrFromDB)) {
      this.updatePolicyStatus(pendingStatus);
    }
  }

  checkIsValidForApplication(): boolean {
    return (
      !this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId()) &&
      this.submissionNavValidationService.coveragesValidStatus &&
      this.submissionNavValidationService.endorsementsValidStatus
    );
  }

  subscribeToUwApprovalSavedData(): void {
    this.uwrSavedData$.pipe(takeUntil(this.stop$)).subscribe(result => {
      if (result?.riskUWApprovals?.length) {
        this.updateUWApprovalData(result.riskUWApprovals);
      }
    });
  }

  hasUwrUpdate(result: boolean, updateType: boolean): boolean {
    return result &&
      updateType &&
      this.submissionData.quoteStatus.value !== this.genericConstants.quoteStatus.quo &&
      !this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId());
  }

  get isValidForApplication(): boolean {
    return this.submissionData.isValidForApplication;
  }

  get userType(): UserType {
    return this.authService.userType.value;
  }

  get isRuw(): boolean {
    return this.submissionData.quoteStatus.value === this.genericConstants.quoteStatus.ruw;
  }

  get approve(): boolean {
    if (this.userType === this.genericConstants.userType.external) { return false; }

    if (this.uwrListEmpty) {
      return false;
    } else {
      if (this.uwrListAllPostBind) {
        if (this.isAllUWApprovalApproved) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.nonPostBindUwrItemsApproved) {
          if (this.air || this.decline) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }
    }
  }

  approveAnyUser(): boolean {
    this.policyNavValidationService.validateCurrentCategory(PathConstants.Policy.Policies.UWApproval);

    if (this.policySummaryData.uwrListEmpty) {
      return true;
    } else {
      if (this.policySummaryData.uwrListAllPostBind) {
        if (this.submissionData.policyStatus.value === this.policyStatus.air || this.submissionData.policyStatus.value === this.policyStatus.duw) {
          return false;
        } else {
          return true;
        }
      } else {
        if (this.submissionData.policyStatus.value === this.policyStatus.auw) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  get decline(): boolean {
    if (this.userType === this.genericConstants.userType.external) { return false; }

    return this.atLeastOneItemHas(this.UWApprovalLabel.uwStatus.reject) && this.userType === this.genericConstants.userType.internal;
  }

  get air(): boolean {
    if (this.userType === this.genericConstants.userType.external) { return false; }

    return this.atLeastOneItemHas(this.UWApprovalLabel.uwStatus.air) && this.userType === this.genericConstants.userType.internal && !this.decline;
  }

  get getReferToUw(): boolean {
    return this.userType === this.genericConstants.userType.external && !this.uwrListEmpty;
  }

  get disableAuw(): boolean {
    return this.submissionData.quoteStatus.value === this.genericConstants.quoteStatus.auw ||
      this.submissionData.policyStatus.value === this.genericConstants.quoteStatus.auw;
  }

  get disableRuw(): boolean {
    return this.submissionData.quoteStatus.value === this.genericConstants.quoteStatus.ruw ||
      this.submissionData.quoteStatus.value === this.genericConstants.quoteStatus.duw ||
      this.submissionData.quoteStatus.value === this.genericConstants.quoteStatus.auw ||
      this.submissionData.policyStatus.value === this.genericConstants.quoteStatus.ruw ||
      this.submissionData.policyStatus.value === this.genericConstants.quoteStatus.duw ||
      this.submissionData.policyStatus.value === this.genericConstants.quoteStatus.auw;
  }

  get disableAir(): boolean {
    return this.submissionData.quoteStatus.value === this.genericConstants.quoteStatus.air ||
      this.submissionData.policyStatus.value === this.genericConstants.quoteStatus.air;
  }

  get disableDuw(): boolean {
    return this.submissionData.quoteStatus.value === this.genericConstants.quoteStatus.duw ||
      this.submissionData.policyStatus.value === this.genericConstants.quoteStatus.duw;
  }

  get hasInvalidQuote(): boolean {
    const hasInvalidQuote = this.submissionNavValidationService.hasInvalidQuote();

    this.submissionData.hasInvalidQuote.next(hasInvalidQuote);
    return hasInvalidQuote;
  }

  get isAllUWApprovalApproved(): boolean {
    const isAllUWApprovalApproved = this.allItemHas(this.UWApprovalLabel.uwStatus.approve);

    this.submissionData.isAllUWApprovalApproved.next(isAllUWApprovalApproved);
    return isAllUWApprovalApproved;
  }

  get uwrListEmpty(): boolean {
    const uwrListEmpty = this.uwApprovalTableRows.length === 0;

    return uwrListEmpty;
  }

  populateUWApprovalData(data: EntityRiskDTO) {
    const uwrList = data?.risks[0].riskDetails[0]?.riskUWApproval;
    this.updateUWApprovalData(uwrList.filter(a => !RenewalConstants.renewalReferralRemarks.includes(a?.remarks)));
    this.formType = data?.risks[0]?.formType;

    if (this.policySummaryData.isPolicy) {
      this.getRenewalReferralList();
    }
  }

  updateUWApprovalData(uwApprovalData: RiskUWApprovalDTO2[]) {
    this.uwApprovalList = [];
    this.uwApprovalTableRows = [];
    this.uwApprovalFileTableRows = [];
    this.coveragesData.isUWR128Triggered = false;
    uwApprovalData.forEach((item: RiskUWApprovalDTO2) => {
      this.uwApprovalList.push(item.uwApprovalDescription);
      this.uwrTriggersByExternalUsers(item);
    });

    this.initData(this.uwApprovalList, uwApprovalData);
  }

  uwrTriggersByExternalUsers(item: RiskUWApprovalDTO2): void {
    if (this.policySummaryData.isPolicy) {
      switch (item.uwApprovalDescription) {
        case LvUWApprovals.find(i => i.id === 132)?.description:
          this.isUWR129Triggered = UWApprovalPageLabel.endorsementReferral.UWR129Flag.includes(item?.agentComment) &&
              UWApprovalPageLabel.endorsementReferral.UWR129Flag.includes(item?.remarks);
        break;
        case LvUWApprovals.find(i => i.id === 131)?.description:
          this.coveragesData.isUWR128Triggered = UWApprovalPageLabel.endorsementReferral.UWR128Flag.includes(item?.agentComment) &&
          UWApprovalPageLabel.endorsementReferral.UWR128Flag.includes(item?.remarks);
        break;
      }
    } else {
      // todo if not policy
    }
  }


  initData(uwApprovalList: any, data: RiskUWApprovalDTO2[]) {
    this.showLoader = true;
    this.uwApprovalListComplete$.next(false);

    uwApprovalList.map((uwrDescription: string) => {
      const uwApprovalData: RiskUWApprovalDTO2 = this.getUwApprovalData(data, uwrDescription);

      const uwApproval: UWApprovalModalModel = {
        id: uuidv4(),
        description: uwrDescription,
        agentComment: uwApprovalData?.agentComment,
        status: uwApprovalData?.uwApprovalCode || this.UWApprovalLabel.uwStatus.pending,
        uwComment: uwApprovalData?.uwComment,
      };

      if (this.checkDuplicateUwr(uwrDescription)) {
        this.addTableItem(uwApproval, uwApprovalData);
      }
    });

    this.uwApprovalListComplete$.next(true);
    this.showLoader = false;
  }

  initUWRData(): void {
    this.uwApprovalList.map((uwrDescription: string) => {
      if (!this.isMiscellaneousApprovalDescription(uwrDescription)) {
        const uwApproval: UWApprovalModalModel = {
          id: uuidv4(),
          description: uwrDescription,
          agentComment: '',
          status: this.UWApprovalLabel.uwStatus.pending,
          uwComment: ''
        };

        if (this.checkDuplicateUwr(uwrDescription)) {
          this.addTableItem(uwApproval);
        }
      }
    });

    this.checkUWRChanges();
  }

  checkUWRChanges(): void {
    this.initUWRData$.next();

    let uwApprovalTableRows = _.cloneDeep(this.uwApprovalTableRows);
    uwApprovalTableRows = _.orderBy(uwApprovalTableRows, item => item.tr[0].value, ['asc']);

    if (this.policySummaryData.isPolicy) {
      this.store.dispatch(updatePolicyUWRsTableRowsFromUwApprovalData({ tableRows: uwApprovalTableRows }));

      return;
    }

    this.store.dispatch(updateSubmissionUWRsTableRowsFromUwApprovalData({ tableRows: uwApprovalTableRows }));
  }

  getUwApprovalData(uwApprovalListData: RiskUWApprovalDTO2[], uwrDescription: string): RiskUWApprovalDTO2 {
    if (this.isMiscellaneousApprovalDescription(uwrDescription)) {
      const index = this.uwApprovalTableRows.filter(item => item.tr[0].value.toLocaleLowerCase() === this.UWApprovalLabel.miscellaneous.toLocaleLowerCase()).length;
      const uwApprovalMiscData = uwApprovalListData.filter(item => item.uwApprovalDescription === uwrDescription);

      return uwApprovalMiscData[index];
    } else {
      return uwApprovalListData.find(item => item.uwApprovalDescription === uwrDescription);
    }
  }

  checkDuplicateUwr(uwrDescription: string): boolean {
    const description: string[] = [];

    this.uwApprovalTableRows.forEach((item) => {
      if (uwrDescription === item.tr[0].value && !this.isMiscellaneousApprovalDescription(uwrDescription)) {
        description.push(item.tr[0].value);
      }
    });

    return description.length === 0;
  }

  isMiscellaneousApprovalDescription(description: string): boolean {
    return description.toLocaleLowerCase() === this.UWApprovalLabel.miscellaneous.toLocaleLowerCase();
  }

  addTableItem(newItem: any, uwApprovalData?: RiskUWApprovalDTO2): void {
    this.uwApprovalTableRows.push({
      id: uuidv4(),
      tr: this.getTr(newItem),
      itemId: uwApprovalData?.id,
      files: this.getUwApprovalFile(uwApprovalData)
    });

    this.updateUwApprovalDataStatusDisplay();
  }

  getTr(newItem: any): ITableTd[] {
    const fields: string[] = [
      'description',
      'status',
      'uwComment',
      'agentComment',
    ];

    const tr: ITableTd[] = [];

    fields.forEach((item, index) => {
      tr.push({
        id: uuidv4(),
        value: newItem[item],
        display: newItem[item],
      });
    });

    return tr;
  }

  getUwApprovalFile(uwApprovalData: RiskUWApprovalDTO2): IFile[] {
    const uwApprovalFile: IFile[] = [];

    if (uwApprovalData) {
      uwApprovalData?.riskUWApprovalDocuments?.forEach((data, index) => {
        const dateAdded = new Date(data.createdDate);
        const dateString = `${('0' + (dateAdded.getMonth() + 1)).slice(-2)}/${('0' + (dateAdded.getDate())).slice(-2)}/${dateAdded.getFullYear()}`;

        uwApprovalFile.push({
          id: data.id ? data.id : uuidv4(),
          fileName: data.fileName,
          filePath: data.filePath,
          dateAdded: dateString,
          fileId: data.id,
          riskUWApprovalId: data.riskUWApprovalId,
          createdDate: data.createdDate
        });
      });
    }

    return _.orderBy(uwApprovalFile, ['createdDate'], ['desc']);
  }

  updateUwApprovalDataStatusDisplay(): void {
    this.uwApprovalTableRows.forEach(item => {
      switch (item.tr[1].display) {
        case this.UWApprovalLabel.uwStatus.air:
          item.tr[1].display = this.UWApprovalLabel.buttons.additionalInfo;
          break;
      }
    });
  }

  checkUwApprovalStatus() {
    const airDescription = this.statusOptions.find(status => status.code === this.UWApprovalLabel.uwStatus.air)?.description;
    const rejectDescription = this.statusOptions.find(status => status.code === this.UWApprovalLabel.uwStatus.reject)?.description;
    const pendingDescription = this.statusOptions.find(status => status.code === this.UWApprovalLabel.uwStatus.pending)?.description;
    this.uwAppovalListHasInvalid = this.atLeastOneItemHas(rejectDescription) || this.atLeastOneItemHas(airDescription) || this.atLeastOneItemHas(pendingDescription);
  }

  atLeastOneItemHas(value: string) {
    const items = this.uwApprovalTableRows.filter((item) => {
      return item.tr[1].value?.includes(value);
    });

    return Boolean(items.length);
  }

  allItemHas(value: string, tableRows: ITableTr[] = this.uwApprovalTableRows) {
    const items = tableRows?.filter((item) => {
      return item.tr[1].value?.includes(value);
    });

    return tableRows?.length === items?.length;
  }

  showNoteModal(actionType: string): void {
    if (this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId()) && this.modalStatus.includes(actionType)) {
      let title = '';
      let isApproved = false;
      let isDeclined = false;
      if (actionType === this.genericConstants.uwStatus.approve) {
        title = UWApprovalPageLabel.notesModalHeader.approvalHeader;
        isApproved = true;
      } else if (actionType === this.genericConstants.uwStatus.reject) {
        title = UWApprovalPageLabel.notesModalHeader.declinedHeader;
        isDeclined = true;
      }

      const initialState = {
        title: title,
        isApproved: isApproved,
        isDeclined: isDeclined
      };

      this.uwaModalRef = this.modalService.show(UwApprovalNotesModalComponent, {
        initialState,
        backdrop: 'static',
        ignoreBackdropClick: true
      });
    }
  }

  updatePolicyStatus(statusCode: RiskStatusCode): void {
    const riskDetailId = this.entityRiskData.getRiskDetailId();
    const riskId = this.entityRiskData.getRiskId();
    if (this.policySummaryData.isPolicyPage(riskId)) {
      if (!this.isInitialFetch) {
        // this.policyService.policyUpdateSubStatusCode(riskDetailId, statusCode).pipe(takeUntil(this.stop$)).subscribe(result => {
        //   if (result) {
        //     const statusDescription = this.LvRiskStatus.find(a => a.code === result)?.description;
        //     this.submissionData.policyStatus.next(statusDescription as RiskStatus);
        //   }
        // });
      }
    }
  }

  reinstatePolicy(): Observable<ReinstatementResponseDTO> {
    const request: ReinstatementRequestDTO = {
      riskId: this.entityRiskData.getRiskId(),
      riskDetailId: this.entityRiskData.getRiskDetailId(),
      isReinstatementForCorrection: false
    };

    return this.policyService.postReinstatePolicy(request);
  }

  reinstatePolicyForCorrection(): Observable<ReinstatementResponseDTO> {
    const request: ReinstatementRequestDTO = {
      riskId: this.entityRiskData.getRiskId(),
      riskDetailId: this.entityRiskData.getRiskDetailId(),
      isReinstatementForCorrection: true
    };

    return this.policyService.postReinstatePolicy(request);
  }

  checkPendingItems(): void {
    let allItemsChecked: boolean = false;
    const uwApprovalListUpdated = this.uwApprovalList.length === this.uwApprovalTableRows.length;

    if (uwApprovalListUpdated) {
      const newItems = this.uwApprovalTableRows.filter(item => item.tr[1].value === 'Pending');

      allItemsChecked = !(newItems.length > 0);
    } else {
      allItemsChecked = uwApprovalListUpdated;
    }

    this.isAllItemsChecked(allItemsChecked);
  }

  isAllItemsChecked(allItemsChecked: boolean): void {
    if (this.policySummaryData.isPolicy) {
      this.policySummaryData.allItemsChecked = allItemsChecked;
      this.policyNavValidationService.validateCurrentCategory(PathConstants.Policy.Policies.UWApproval);
    }

    this.submissionData.allItemsChecked = allItemsChecked;
    this.submissionNavValidationService.validateCurrentCategory(PathConstants.Submission.Submission.UWApproval);
  }

  get hasPostBindUwrItem(): boolean {
    const postBindUwrList = this.uwApprovalTableRows.filter(item => item.tr[0].value.toLocaleLowerCase().includes(this.UWApprovalLabel.uwrPostBindText));

    return postBindUwrList.length > 0;
  }

  get uwrListAllPostBind(): boolean {
    const postBindUwrList = this.uwApprovalTableRows.filter(item => item.tr[0].value.toLocaleLowerCase().includes(this.UWApprovalLabel.uwrPostBindText));

    return (
      (this.uwApprovalTableRows.length !== 0 && postBindUwrList.length !== 0) &&
      (this.uwApprovalTableRows.length === postBindUwrList.length)
    );
  }

  get uwrListAllNonPostBind(): boolean {
    const nonPostBindUwrList = this.uwApprovalTableRows.filter(item => !item.tr[0].value.toLocaleLowerCase().includes(this.UWApprovalLabel.uwrPostBindText));

    return (
      (this.uwApprovalTableRows.length !== 0 && nonPostBindUwrList.length !== 0) &&
      (this.uwApprovalTableRows.length === nonPostBindUwrList.length)
    );
  }

  get nonPostBindUwrItemsChecked(): boolean {
    const nonPostBindUwrList = this.uwApprovalTableRows.filter(item => !item.tr[0].value.toLocaleLowerCase().includes(this.UWApprovalLabel.uwrPostBindText));
    const nonPostBindUwrListChecked = nonPostBindUwrList.filter(item => item.tr[1].value !== this.UWApprovalLabel.uwStatus.pending);

    return (
      (nonPostBindUwrList.length !== 0 && nonPostBindUwrListChecked.length !== 0) &&
      (nonPostBindUwrList.length === nonPostBindUwrListChecked.length)
    );
  }

  get postBindUwrItemsApproved(): boolean {
    const postBindUwrList = this.uwApprovalTableRows.filter(item => item.tr[0].value.toLocaleLowerCase().includes(this.UWApprovalLabel.uwrPostBindText));

    return this.allItemHas(this.UWApprovalLabel.uwStatus.approve, postBindUwrList);
  }

  get nonPostBindUwrItemsApproved(): boolean {
    const nonPostBindUwrList = this.uwApprovalTableRows.filter(item => !item.tr[0].value.toLocaleLowerCase().includes(this.UWApprovalLabel.uwrPostBindText));

    return this.allItemHas(this.UWApprovalLabel.uwStatus.approve, nonPostBindUwrList);
  }

  get uwApprovalListAddedAllPostBind(): boolean {
    const uwApprovalListAdded = this.uwApprovalListAdded.filter(item => item.toLocaleLowerCase().includes(this.UWApprovalLabel.uwrPostBindText));

    return (
      (this.uwApprovalListAdded.length !== 0 && uwApprovalListAdded.length !== 0) &&
      (this.uwApprovalListAdded.length === uwApprovalListAdded.length)
    );
  }

  private getRenewalReferralList(): void {
    this.policyService.getRenewalReferral(this.entityRiskData.getRiskId()).pipe(takeUntil(this.stop$)).subscribe(res => {
      this.renewalReferralTableRows = [];

      res.map((item, index) => {
        this.renewalReferralTableRows = [...this.renewalReferralTableRows, {
          id: index,
          tr: [{
            id: uuidv4(),
            value: item,
            display: item.uwApprovalDescription
          }]
        }];
      });
    });
  }
}
