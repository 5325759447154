import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'disableRuwButton'
})
export class DisableRuwButtonPipe implements PipeTransform {

  transform(data: any): boolean {
    const {
      disableRuw,
      hasInvalidQuote,
      selectSubmissionUWRsIsLoading,
      selectPolicyUWRsIsLoading,
      selectAppRuwIsLoading
    } = data;

    return disableRuw ||
      hasInvalidQuote ||
      selectSubmissionUWRsIsLoading ||
      selectPolicyUWRsIsLoading ||
      selectAppRuwIsLoading;
  }
}
