import { Component, OnDestroy, OnInit } from '@angular/core';
import { CurrencyMaskConstants } from '../../../../../shared/constants/currency-mask.constants';
import { RefundData } from '../../../../../modules/payments/data/refund.data';
import { RefundLabelsConstants, RefundRequestValueConstants } from '../../../../../shared/constants/refund.labels.constants';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { Router } from '@angular/router';
import { RefundRequestListDTO } from '../../../../../shared/models/data/dto/refund/refund-request-list.dto';
import { FormControl } from '@angular/forms';
import { EntityRiskData } from '../../../../../modules/submission-management/data/entity-risk.data';
import { PolicyBillingData } from '../../../../../modules/policy-management/data/policy-billing.data';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EditRefundModalComponent } from '../edit-refund-modal/edit-refund-modal.component';
import { PageChangedEvent } from 'ngx-bootstrap';
import Utils from '../../../../../shared/utilities/utils';
import { selectRefundRequestIsLoading } from '../../../../../store/refund-request/refund-request.selectors';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import NotifUtils from '../../../../../shared/utilities/notif-utils';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';

@Component({
  selector: 'app-refund-request',
  templateUrl: './refund-request.component.html',
  styleUrls: ['./refund-request.component.scss']
})
export class RefundRequestComponent implements OnInit, OnDestroy {
  isOpen: boolean = true;
  isFilterOpen: boolean = true;

  modalRef: BsModalRef | null;

  public RefundLabels = RefundLabelsConstants;
  public CurrencyMaskConstants = CurrencyMaskConstants;
  public CurrencyMaskInputMode = CurrencyMaskInputMode;
  public RefundRequestValueConstants = RefundRequestValueConstants;
  private selectRefundRequestIsLoading$: Observable<boolean>;
  showPreloader: boolean = false;
  isLoadingSub = new Subscription();

  constructor(
    public router: Router,
    public refundRequestData: RefundData,
    public entityRiskData: EntityRiskData,
    public policyBillingData: PolicyBillingData,
    private modalService: BsModalService,
    private store: Store
  ) {
    this.selectRefundRequestIsLoading$ = this.store.pipe(select(selectRefundRequestIsLoading));
    this.checkIfLoading();
  }

  ngOnInit() {
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }

  collapseFilter(): void {
    this.isFilterOpen = !this.isFilterOpen;
  }

  onChecked(event: any, index: number, refundRequestItem: RefundRequestListDTO, refundAmountText: string, refundToTypeText: string, deliveryTypeText: string, isChecked: boolean): void {
    // check if name and address exceeded max length
    const refundPayeeDetails = this.refundRequestData.getRefundPayeeDetailsForRefundPortal(refundRequestItem);
    if (isChecked && this.refundRequestData.nameOrAddressExceedMaxLength(refundPayeeDetails)) {
      this.uncheckItem(event, index);
      const infoMsg = ErrorMessageConstant.billingErrorMessage.updateRefundDetailsInfoMsg;
      const errorMsg = this.refundRequestData.getCustomErrorMsg(refundPayeeDetails, infoMsg);
      NotifUtils.showError(errorMsg, () => this.onEditClick(refundRequestItem));
    } else {
      this.updateList(isChecked, refundRequestItem);
      this.refundRequestData.saveRefundRequest(refundRequestItem, refundAmountText, refundToTypeText, deliveryTypeText, isChecked);
    }
  }

  updateList(isChecked: boolean, refundRequestItem: RefundRequestListDTO): void {
    this.refundRequestData.filteredRefundRequestList = this.refundRequestData.filteredRefundRequestList.map(item => {
      if (item.id === refundRequestItem.id) {
        item.isChecked = isChecked;
        item.refundAmount = item.totalPaymentAmount - item.totalTransactionAmount;
        if (!item.isChecked) {
          item.refundAmount = 0;
        }
      }
      return item;
    });
  }

  onRemoveClick(item: RefundRequestListDTO): void {
    this.refundRequestData.removeManualRefund(item);
    this.updateList(item.isChecked, item);
  }

  onEditClick(item: RefundRequestListDTO): void {
    const initialState = {
      title: this.RefundLabels.RefundRequest.Action.EditRefundModalTitle,
      refundRequestListDTO: item,
    };
    this.modalRef = this.modalService.show(EditRefundModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  redirectToBilling(riskId: any, riskDetailId: any) {
    localStorage.setItem('riskId', riskId);
    this.router.navigate(['/policies/' + riskId + '/' + riskDetailId + '/billing']);
  }

  pageChanged(event: PageChangedEvent): void {
    if (this.refundRequestData.currentPage === event.page) {return;}
    Utils.blockUI();
    this.refundRequestData.currentPage = event.page;
    this.refundRequestData.getRefundRequestList();
  }

  checkIfLoading(): void {
    this.isLoadingSub = this.selectRefundRequestIsLoading$.subscribe(isLoading => this.showPreloader = isLoading);
  }

  get showTableFooter(): boolean {
    return this.refundRequestData.totalRowCount > 10 && !this.showPreloader;
  }

  ngOnDestroy(): void {
    if (this.isLoadingSub) {
      this.isLoadingSub.unsubscribe();
    }
  }

  onSearchClick(): void {
    this.refundRequestData.filterList();
  }

  uncheckItem(evt: any, itemIndex: number): void {
    this.refundRequestData.filteredRefundRequestList[itemIndex].isChecked = false;
    evt.target.checked = false;
  }

  refundToTypeDisable(item: any): boolean {
    return item.isChecked && (item.paymentPlan.includes(RefundRequestValueConstants.MortgageeBill)
    || (item.isFromSuspendedPayment && item.policyNumber !== null)
    || item.isManualRefund
    || (!((item.isFromSuspendedPayment && item.policyNumber !== null) || item.isManualRefund) && item.isOtherPayeeAvailable))
    && (item.isOtherPayeeAvailable || item.paymentPlan.includes(RefundRequestValueConstants.MortgageeBill));
  }
}
