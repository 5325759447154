import { Injectable } from '@angular/core';
import swal, { SweetAlertOptions } from 'sweetalert2';
import Utils from './utils';

@Injectable()
export default class NotifUtils {
  private static successColor = 'color: #3c763d; font-weight: bold; font-size: 25px;';
  public static messages = [];

  static showNotice(options: string | SweetAlertOptions, defaults: SweetAlertOptions, callback: () => any = null, cancelCallBack: () => any = null): void {

    if (!options || options.toString().length < 5) {
      console.log('invalid message', options);
      return;
    }

    const opts: any = {};

    if (options && typeof options === 'string') {
      opts.html = options as string;
    }

    Object.assign(opts, defaults, options || {});

    swal.fire(opts).then((result) => {
      if (callback && result.value) {
        setTimeout(callback);
      } else if (cancelCallBack && !result.value) {
        setTimeout(cancelCallBack);
      }
    });
  }

  static showConfirmMessage(options: string | SweetAlertOptions, callback: () => any = null, cancelCallback: () => any = null): void {
    const defaults: any = {
      title: 'Confirmation',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      stopKeydownPropagation: true,
      keydownListenerCapture: false,
      allowEnterKey: true
    };
    this.showNotice(options, defaults, callback, cancelCallback);
  }

  static showSuccess(options: string | SweetAlertOptions, callback: () => any = null): void {
    const defaults: any = {
      icon: 'success'
    };
    this.showNotice(options, defaults, callback);
  }

  static showSuccessWithConfirmationFromUser(options: string | SweetAlertOptions, callback: () => any = null): void {
    const defaults: any = {
      icon: 'success',
      allowOutsideClick: false,
      allowEscapeKey: false,
      stopKeydownPropagation: true,
      keydownListenerCapture: false,
      allowEnterKey: true
    };
    this.showNotice(options, defaults, callback);
  }

  static showInformationWithConfirmationFromUser(options: string | SweetAlertOptions, callback: () => any = null): void {
    const defaults: any = {
      icon: 'info',
      allowOutsideClick: false,
      allowEscapeKey: false,
      stopKeydownPropagation: true,
      keydownListenerCapture: false,
      allowEnterKey: true
    };
    this.showNotice(options, defaults, callback);
  }

  static showError(options: string | SweetAlertOptions, callback: () => any = null): void {
    if (!Utils.isExpiredToken) {
      const defaults: any = {
        icon: 'error',
        allowOutsideClick: false,
        customClass: {
          popup: 'swal-content-p'
        }
      };
      this.showNotice(options, defaults, callback);
    }
  }

  static showErrorHugeBox(options: string | SweetAlertOptions, callback: () => any = null): void {
      const defaults: any = {
        icon: 'error',
        allowOutsideClick: false,
        width: '700px'
      };
      this.showNotice(options, defaults, callback);
  }

  static showWarning(options: string | SweetAlertOptions, callback: () => any = null): void {
    const defaults: any = {
      icon: 'warning'
    };
    this.showNotice(options, defaults, callback);
  }

  static showInfo(options: string | SweetAlertOptions, callback: () => any = null, allowOutsideClick: boolean = true): void {
    const defaults: any = {
      icon: 'info',
      allowOutsideClick: allowOutsideClick
    };
    this.showNotice(options, defaults, callback);
  }

  static showMultipleErrors(ineligibleRiskReasons: string[], options: SweetAlertOptions = {},
    callback: () => any = null): void {

    const defaults: any = {
      type: 'error'
    };

    let reasons = '';

    ineligibleRiskReasons.forEach(reason => {
      reasons += `<li>${reason}</li>`;
    });

    options.html = `
    <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;"><span class="swal2-x-mark">
      <span class="swal2-x-mark-line-left"></span>
      <span class="swal2-x-mark-line-right"></span>
      </span>
    </div>
    <div class="swal-generic-http-error-response text-left">
      <div class="content small">
        ${reasons}
      </div>
    </div>
  `;
    return this.showNotice(options, defaults, callback);
  }

  static showMessage(options: string | SweetAlertOptions, callback: () => any = null): void {
    this.showNotice(options, {}, callback);
  }

  static showConsoleSuccess(message: string): void {
    console.log(`%c ${message}`, this.successColor);
  }

  static showMultiLineError(message: string): void {
    this.showError(message?.replace(new RegExp('\r?\n','g'), '<br />'));
  }

  static showMultipleRequiredFieldsError(requiredFields: string[], options: SweetAlertOptions = {},
    callback: () => any = null): void {
      const defaults: any = {
        type: 'error'
      };

      let reasons = '';

      requiredFields.forEach(reason => {
        reasons += `<li>${reason}</li>`;
      });

      options.html = `
      <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;"><span class="swal2-x-mark">
        <span class="swal2-x-mark-line-left"></span>
        <span class="swal2-x-mark-line-right"></span>
        </span>
      </div>
      <div class="swal-generic-http-error-response text-left">
        <div class="content small">
          ${reasons}
        </div>
      </div>
    `;
      return this.showNotice(options, defaults, callback);
  }

  static showThirdPartyAPIError(messages: string[] = [], options: SweetAlertOptions = {},
    callback: () => any = null): void {
      const defaults: any = {
        type: 'error'
      };

      let displayMessages = '';

      messages.forEach(message => {
        displayMessages += `<li>${message}</li>`;
      });

      options.html = `
      <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;"><span class="swal2-x-mark">
        <span class="swal2-x-mark-line-left"></span>
        <span class="swal2-x-mark-line-right"></span>
        </span>
      </div>
      <h2>Oops...</h2>
      <div class="swal-generic-http-error-response text-left">
        <div>
          <center>
            <p>${displayMessages}</p>
          </center>
        </div>
        <center>Please try again later. If issue persists, contact technical support.</center>
      </div>
    `;
      return this.showNotice(options, defaults, callback);
  }

  static showErrorViaChain(errorMessages: any[]): void {
    swal.mixin({
      icon: 'error',
      confirmButtonText: 'Ok',
      showCancelButton: false,
    }).queue(errorMessages);
  }

  static showQueue(callback: () => any = null): void {
    if (!swal.isVisible() || swal.isLoading()) {
      swal.mixin({
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        stopKeydownPropagation: true,
        keydownListenerCapture: false,
        allowEnterKey: true
      }).queue(NotifUtils.messages).then((result) =>{
        NotifUtils.messages = [];
        setTimeout(callback);
      });
    }
  }

  static showErrorWithoutConfirmation(options: string | SweetAlertOptions): void {
    const defaults: any = {
      title: 'Oops...',
      icon: 'error',
      allowOutsideClick: false,
      showConfirmButton: false
    };
    this.showNotice(options, defaults);
  }
}
