import { Router, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { PathConstants } from 'app/shared/constants/path.constants';
import { AuthService } from 'app/core/services/auth.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AgentService } from 'app/core/services/management/agent-service';
import { AgentUserResponseDTO } from 'app/shared/models/data/dto/agent/agentuser-response.dto';
import NotifUtils from 'app/shared/utilities/notif-utils';

@Injectable()
export class ReportsGuard implements CanActivate {

	constructor(
    public router: Router,
    public authService: AuthService,
    public agentService: AgentService
  ) {}

	canActivate(): Observable<boolean> {
    const usernameKey = localStorage.getItem(environment.UsernameKey);
    const userType = localStorage.getItem('userType');
    if (usernameKey && userType) {
      return this.agentService.getAgentUserInformation(usernameKey).map((response: AgentUserResponseDTO) => {
        if(userType === 'internal') {
          return true;
        } else if(userType === 'external') {
          const url = window.location.href;
          const urlItems = url?.split('/');
          const targetPage = urlItems[urlItems?.length - 1];
          const allowAccess = targetPage === 'agency-snapshot' || targetPage === 'dashboard';
          const hasViewCommissionAuthority = response?.entity?.canViewCommission;

          if (hasViewCommissionAuthority || allowAccess) {
            return true;
          } else {
            this.router.navigate([PathConstants.NotFound]);
            return false;
          }
        }
      }, err => {
        this.router.navigate([PathConstants.NotFound]);
        NotifUtils.showError(JSON.stringify(err.error));
        return false;
      }).first();
    } else {
      this.router.navigate([PathConstants.NotFound]);
    }
	}
}