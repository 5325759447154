export class FloodAssistRequestDTO {
  riskId: string;
  riskDetailId: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  public constructor(init?: Partial<FloodAssistRequestDTO>) {
    Object.assign(this, init);
  }
}