import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { BaseClass } from '../../../shared/base-class';
import { PagerService } from '../../../core/services/pagerService';
import { ReferenceListDetailsDTO } from '../../../shared/models/data/dto/dashboard/reference-list';
import { AgentDashboardConstants } from '../../../shared/constants/agent-dashboard.constants';
import {  distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DashBoardService } from 'app/core/services/dashboard.service';
import { IAgentDashboardDTO } from 'app/shared/models/data/dto/dashboard/agentDashboardDTO';
import { Subject } from 'rxjs';
import { AgentService } from '../../../core/services/management/agent-service';
import { GenericLabel } from '../../../shared/constants/generic.labels.constants';
import { AuthService } from 'app/core/services/auth.service';
import { TaskData } from './task.data';
import { GenericConstants } from '../../../shared/constants/generic.constants';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs-compat';
import { selectDashboardProgramStateAgentDetailIsLoading } from 'app/store/dashboard/dashboard.selectors';


@Injectable({
  providedIn: 'root'
})
export class DashboardData extends BaseClass {

  agentDashboardConstants = AgentDashboardConstants;
  genericLabelConstants = GenericLabel;
  genericConstants = GenericConstants;

  dashboardReferenceList: ReferenceListDetailsDTO[] = [];
  dashboardReferenceListPagedItems: ReferenceListDetailsDTO[];
  referenceDataFiltered: any = [];
  referenceDataDefault: any = [];
  isToDefaultList: boolean = false;
  dashboardReferenceListPager: any = {};
  statesWithLicense: any = [];
  agentAssignedStates: any = [];

  isAlActive: Subject<boolean> = new Subject<boolean>();
  isFlActive: Subject<boolean> = new Subject<boolean>();
  isLaActive: Subject<boolean> = new Subject<boolean>();
  isMsActive: Subject<boolean> = new Subject<boolean>();
  isScActive: Subject<boolean> = new Subject<boolean>();
  isTxActive: Subject<boolean> = new Subject<boolean>();
  isAgentLicencseNone: Subject<boolean> = new Subject<boolean>();

  public agentDashBoard: IAgentDashboardDTO;

  agentId: string;
  isAgentDashboardPolicySectionDoneLoading: boolean = false;

  protected selectDashboardProgramStateAgentDetailIsLoading$: Observable<boolean>;

  constructor(protected pagerService: PagerService,
              protected dashBoardService: DashBoardService,
              protected agentService: AgentService,
              protected authService: AuthService,
              protected store: Store) {
      super();
      this.selectDashboardProgramStateAgentDetailIsLoading$ = this.store.pipe(select(selectDashboardProgramStateAgentDetailIsLoading));
      this.referenceListSetPage(1);
      this.isToDefaultList = true;

  }

  sortingByState(selectedState: any) {
    this.referenceDataFiltered =  this.getFilteredListByState(selectedState);
    if (this.statesWithLicense) {
      if (selectedState === this.statesWithLicense[0]) {
        this.isToDefaultList = true;
      } else {
        this.isToDefaultList = false;
      }
    } else {
      this.isToDefaultList = false;
    }
    this.referenceListSetPage(1);
}

  getFilteredListByState(state){
    return this.dashboardReferenceList.filter(x => x.stateCode === state);
  }

  referenceListSetPage(page: number) {
    if (page < 1) {
      return;
    }
    if (this.isToDefaultList) {
      // Default List
      this.dashBoardService.getReferenceBoxList().pipe(takeUntil(this.stop$)).subscribe(response => {
        this.dashboardReferenceList = response;

        if (this.authService.userType.value === this.genericConstants.userTypeLabel.internal.toLowerCase()) {
          this.isAgentLicencseNone.next(true);
          this.isFlActive.next(true);
          this.referenceDataDefault = this.dashboardReferenceList.filter(x => x.stateCode === this.agentDashboardConstants.floridaSC);
          this.setListPager(page);
        } else {
          this.populateReferenceBox(page);
        }
      }, err => {});
    } else {
      this.dashboardReferenceListPager = this.pagerService.getPager8Items(this.referenceDataFiltered.length, page);
      this.dashboardReferenceListPagedItems = this.referenceDataFiltered.slice(this.dashboardReferenceListPager.startIndex, this.dashboardReferenceListPager.endIndex + 1);
    }
 }

 backDataDefault() {
   this.referenceDataFiltered = this.referenceDataDefault;
  }

  getUniqueValue(value, index, self) {
    return self.indexOf(value) === index;
  }

  setListPager(page: number) {
    this.dashboardReferenceListPager = this.pagerService.getPager8Items(this.referenceDataDefault.length, page);
    this.dashboardReferenceListPagedItems = this.referenceDataDefault.slice(this.dashboardReferenceListPager.startIndex, this.dashboardReferenceListPager.endIndex + 1);
  }

  populateReferenceBox(page: number): void {
    this.selectDashboardProgramStateAgentDetailIsLoading$.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(() => {
      this.agentAssignedStates = this.authService.programStates.map(x => x.stateCode);
      this.agentAssignedStates.sort();
      if (this.agentAssignedStates?.length > 0) {
        if (this.agentAssignedStates[0] === this.agentDashboardConstants.alabamaSC) {
          this.isAlActive.next(true);
          this.referenceDataDefault = this.dashboardReferenceList.filter(x => x.stateCode === this.agentDashboardConstants.alabamaSC);
        } else if (this.agentAssignedStates[0] === this.agentDashboardConstants.floridaSC) {
          this.isFlActive.next(true);
          this.referenceDataDefault = this.dashboardReferenceList.filter(x => x.stateCode === this.agentDashboardConstants.floridaSC);
        } else if (this.agentAssignedStates[0] === this.agentDashboardConstants.louisianaSC) {
          this.isLaActive.next(true);
          this.referenceDataDefault = this.dashboardReferenceList.filter(x => x.stateCode === this.agentDashboardConstants.louisianaSC);
        } else if (this.agentAssignedStates[0] === this.agentDashboardConstants.mississippiSC) {
          this.isMsActive.next(true);
          this.referenceDataDefault = this.dashboardReferenceList.filter(x => x.stateCode === this.agentDashboardConstants.mississippiSC);
        } else if (this.agentAssignedStates[0] === this.agentDashboardConstants.southCarolinaSC) {
          this.isScActive.next(true);
          this.referenceDataDefault = this.dashboardReferenceList.filter(x => x.stateCode === this.agentDashboardConstants.southCarolinaSC);
        } else if (this.agentAssignedStates[0] === this.agentDashboardConstants.texasSC) {
          this.isTxActive.next(true);
          this.referenceDataDefault = this.dashboardReferenceList.filter(x => x.stateCode === this.agentDashboardConstants.texasSC);
        }
      } else {
        this.isAgentLicencseNone.next(true);
        this.referenceDataDefault = [];
      }
      this.setListPager(page);
    });
  }
}
