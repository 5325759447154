export const CoveragesLabelsConstants = {
  coveragesSection: {
    coveragesHeader: 'Coverages',
    ineligibilityReasons: 'Ineligibility Reasons',
    ineligibilityReasonsDefault: 'No ineligibility reason.',
    coveragesSectionHeader: 'Coverages',
    coverageA: 'Coverage A - Dwelling',
    coverageB: 'Coverage B - Other Structures Limit',
    coverageC: 'Coverage C - Personal Property Limit',
    coverageD: 'Coverage D - Loss of Use',
    coverageE: 'Coverage E - Personal Liability',
    coverageF: 'Coverage F - Medical Payments',
    coverageL: 'Coverage L - Personal Liability',
    coverageM: 'Coverage M - Medical Payments',
    coverageDorE: 'Coverage D/E - Fair Rental/Addl Living',
    deductiblesHeader: 'Deductibles',
    allOtherPerilsDeductible: 'All Other Perils Deductible',
    hurricaneDeductible: 'Hurricane Deductible',
    creditHeader: 'Credit',
    estimatedCredit: 'Estimated Credit',
    creditOrdered: 'Credit Ordered'
  },
  discounts: {
    discountsHeader: 'Discounts',
    accreditedBuilder: 'Accredited Builder',
    securedCommunityBuilding: 'Secured Community/Building',
    companionFlood: 'Companion Flood',
    companionAuto: 'Companion Auto',
    companionUmbrella: 'Companion Umbrella',
    waterDetection: 'Water Detection',
    companionHOPolicy: 'Companion HO Policy',
    seniorRetiree: 'Senior/Retiree',
    fire: 'Fire',
    burglar: 'Burglar',
    sprinkler: 'Sprinkler',
    companionFloodToolTip: 'Discount will automatically apply if Centauri private flood coverage endorsement is selected or NFIP policy is purchased through Centauri',
    lacompanionFloodToolTip: 'Discount is offered if NFIP policy is purchased through Centauri',
    ladp3companionFloodToolTip: 'Discount will automatically apply if NFIP policy is purchased through Centauri',
    HOSeniorRetireeToolTip: `Discount will automatically apply if applicant is 60 or older, or between ages of 55-59 and selected 'retired' on Quick quote screen`,
    HOPolicySeniorRetireeToolTip: `Discount will automatically apply if applicant is 60 or older, or between ages of 55-59 and selected 'retired' on Applicant screen`,
    DPSeniorRetireeToolTip: `Discount will automatically apply if applicant is 60 or older, or between ages of 55-59 and selected 'retired' on Quick quote screen and Property Usage is Owner Occupied and Residence is not Rented`,
    DPPolicySeniorRetireeToolTip: `Discount will automatically apply if applicant is 60 or older, or between ages of 55-59 and selected 'retired' on Applicant screen and Property Usage is Owner Occupied and Residence is not Rented`,
    financialResponsibility: `Financial Responsibility`,
    accreditedBuildertoolTip: 'See list of Accredited Builders on dashboard '
  },
  flood: {
    floodHeader: 'Flood',
    addCentauriPrivateFloodCoverage: 'Add Centauri Private Flood Coverage:',
    hasThereEverBeenAFloodLossAtThislocation: 'Has there ever been a flood loss at this location?',
    floodCoverageALimit: 'Flood Coverage A Limit',
    floodCoverageCLimit: 'Flood Coverage C Limit',
    floodDeductible: 'Flood Deductible',
    elevationCertificate: 'Elevation Certificate',
    baseFloodElevation: 'Base Flood Elevation',
    lowestFloorElevation: 'Lowest Floor Elevation',
    elevationDifference: 'Elevation Difference',
    floodZone: 'Flood Zone',
    participationStatus: 'Participation Status',
    firmDate: 'FIRM Date',
    orderFloodData: 'Order Flood Data',
    addNFIPFloodPolicy: 'Add NFIP Flood Policy',
    nFIPPremium: 'Estimated NFIP Premium',
    purchaseNFIP: 'Purchase NFIP',
    retrieveNFIP: 'Retrieve NFIP',
    cannotRetrieveEstimate: 'Cannot retrieve NFIP Premium estimate. Please create quote in Torrent.',
    willTheApplicantHaveaFloodPolicyDuringThePolicyPeriod: 'Will the applicant have a flood policy during the policy period?',
    clickButtonToRetrieve: 'Flood Data Retrieval Failed Click button to retrieve',
    floodDataRetrievalSuccessful: 'Flood Data Retrieval Successful',
    mustOrderFloodData: 'Must Order Flood Data',
    floodCovAMaxValue: 2000000,
    floodCovAMinValue: 100000,
    floodCovCMaxValue: 500000,
    floodCovCMinValue: 35000,
  },
  schedules: {
    schedulesHeader: 'Schedules',
    addSchedules: 'Add Schedules:',
    yesLabel: 'Yes',
    noLabel: 'No',
    personalJewelry: 'Personal Jewelry',
    bicycles: 'Bicycles',
    cameraAndProjectionEquipment: 'Camera & Projection Equipment',
    coinCollections: 'Coin Collections',
    antiques: 'Antiques',
    fineArtsAndAntiquesNoBreakage: 'Fine Arts & Antiques (No Breakage)',
    fineArtsAndAntiquesWithBreakage: 'Fine Arts & Antiques (With Breakage)',
    furs: 'Furs',
    golfEquipment: 'Golf Equipment',
    gunsCollectible: 'Guns (Collectible)',
    gunsFired: 'Guns (Fired)',
    musicalInstrumentsPersonal: 'Musical Instruments (Personal)',
    miscellaneous: 'Miscellaneous',
    otherSportsEquipment: 'Other Sports Equipment',
    silverware: 'Silverware',
    stampCollections: 'Stamp Collections',
    areAnyItemsdamgesorInNeedOfRepair: 'Are any items damaged or in need of repair?'
  },
  infoMessage: {
    floodZoneVMessage: 'Flood Coverage Endorsement is not available due to flood zone.',
    floodZoneExcludedZipCodeMessage: 'Flood Coverage Endorsement is not available due to location.',
    coverageAMessage: 'You may have entered a value that is either less than $75,000 or greater than $10,000,000 in Coverage A.<br>Coverage A has been set to the latest valid value.',
    coverageCMessage: 'You may have entered a value that is either less than $0 or greater than $10,000,000 in Coverage C.<br>Coverage C has been set to the latest valid value.',
    floodCoverageALimitMessage: 'You may have entered a value that is either less than $100,000 or greater than Coverage A value.<br>Flood Coverage A limit has been set to the latest valid value.',
    floodCoverageALimitMessage_Preferred: 'You may have entered a value that is either less than $20,000 or greater than Coverage A value.<br>Flood Coverage A limit has been set to the latest valid value.',
    floodCoverageCLimitMessage: 'You may have entered a value that is either less than $35,000 or greater than $500,000.<br>Flood Coverage C limit has been set to the latest valid value.',
    floodCoverageCLimitMessage_Preferred: 'You may have entered a value that is either less than $8,000 or greater than $500,000.<br>Flood Coverage C limit has been set to the latest valid value.',
    floodCoverageCLimitMessageExclude: 'Flood Coverage C limit has been set to the latest valid value.',
    coverageAMessageHO6: 'Coverage A has been set to the latest valid value.',
    coverageCMessageDP3Def: 'You may have entered a value that is either less than $5,000 or greater than $250,000 in Coverage C.<br>Coverage C has been set to the latest valid value.',
    coverageCMessageDP3Tenant: 'You may have entered a value that is either less than $10,000 or greater than $100,000 in Coverage C.<br>Coverage C has been set to the latest valid value.'
  },
  operations: {
    add: 'add',
    minus: 'minus'
  },
  scheduleTableName : {
    personalJewelry:'personalJewelry',
    bicycles: 'bicycles',
    cameraAndProjectionEquipment: 'cameraAndProjectionEquipment',
    coinCollections:'coinCollections',
    antiques: 'antiques',
    fineArtsAndAntiquesNoBreakage: 'fineArtsAndAntiquesNoBreakage',
    fineArtsAndAntiquesWithBreakage: 'fineArtsAndAntiquesWithBreakage',
    furs: 'furs',
    golfEquipment: 'golfEquipment',
    gunsCollectible: 'gunsCollectible',
    gunsFired: 'gunsFired',
    musicalInstrumentsPersonal: 'musicalInstrumentsPersonal',
    miscellaneous: 'miscellaneous',
    otherSportsEquipment: 'otherSportsEquipment',
    silverware: 'silverware',
    stampCollections:'stampCollections'
  }
};
