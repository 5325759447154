import { Component, OnDestroy, OnInit } from '@angular/core';
import { SuspendedPaymentsData } from '../../../../../modules/payments/data/suspended-payments.data';
import { BaseClass } from '../../../../../shared/base-class';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { SuspendedPaymentsLabel } from '../../../../../shared/constants/suspended-payments.labels.constants';
import { BsModalRef } from 'ngx-bootstrap';
import { PolicySearchResultDTO } from '../../../../../shared/models/data/dto/suspended-payment/policy-list-dto';
import { SuspendedPaymentDetailsDTO } from '../../../../../shared/models/data/dto/suspended-payment/suspended-payment-detail.dto';


@Component({
    selector: 'app-details-suspended-split-payment-table-modal',
    templateUrl: './details-suspended-split-payment-table-modal.component.html',
    styleUrls: ['./details-suspended-split-payment-table-modal.component.scss']
})
export class DetailsSuspendedSplitPaymentTableModalComponent extends BaseClass implements OnInit {
    public LabelConstants = SuspendedPaymentsLabel.detail;
    public SuspendedPaymentsLabel = SuspendedPaymentsLabel;
    public errorMessageConstant = ErrorMessageConstant;
    public directBillCarriers: any;

    policySearchResult: PolicySearchResultDTO;
    paymentDetail: SuspendedPaymentDetailsDTO;
    policyPaymentAmount: any;

    constructor(
        public suspendedPaymentsData: SuspendedPaymentsData,
        private bsModalRef: BsModalRef) {
        super();
    }

    ngOnInit() {

    }

    hideModal(): void {
        this.bsModalRef.hide();
        this.suspendedPaymentsData.resetDocumentVariables();
        this.suspendedPaymentsData.resetUpdateSuspendedForm();
    }

}
