export const AgencyTableConstants = {
  agencyName: 'Name',
  agencyEmailAddress: 'Email Address',
  agencyWorkPhone: 'Work Phone',
  agencyContactPerson: 'Contact Person',
  agencyStatus: 'Status',
  newBusiness: 'New Business',
  renewalBusiness: 'Renewal Business',
  effectiveDate: 'Effective Date',
  expirationDate: 'Expiration Date',
  status: 'Status',
  type: 'Type',
  city: 'City',
  state: 'State',
  zipCode: 'Zip Code',
  program: 'Program',
  startDate: 'Start Date',
  endDate: 'End Date',
  schedule: 'Schedule',
  policyEffectiveDateRange: 'Policy Effective Date Range',
  agencyCode: 'Agency Code',
  subAgencyCode: 'Sub Agency Code'
};

export const ProgramStateTableConstants = {
  mainProgram: 'Main Program',
  programName: 'Program Name',
  formType: 'Form Type',
  state: 'State',
  status: 'Status'
};
