import { Injectable } from '@angular/core';
import { BaseClass } from 'app/shared/base-class';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { QuickQuoteService } from 'app/core/services/submission/quick-quote.service';
import { takeUntil } from 'rxjs/operators';
import { PathConstants } from 'app/shared/constants/path.constants';
import { ToastrService } from 'ngx-toastr';
import { InfoMessageConstant } from 'app/shared/constants/info-message.constants';
import { SaveBindNotesDTO } from 'app/shared/models/data/dto/quick-quote/save-bind-and-issue.dto';
import { BindAndIssueData } from 'app/modules/submission-management/data/bind-and-issue.data';
import { RiskBindNoteDTO } from 'app/shared/models/data/dto/quick-quote/risk-bind-note.dto';
import { BindAndIssueNoteResultDTO } from 'app/shared/models/data/dto/quick-quote/bind-and-issue-result.dto';
import Utils from 'app/shared/utilities/utils';

@Injectable({
  providedIn: 'root'
})
export class BindAndIssueSavingData extends BaseClass {

  constructor(
    protected summaryData: SummaryData,
    public entityRiskData: EntityRiskData,
    public policySummaryData: PolicySummaryData,
    protected quickQuoteService: QuickQuoteService,
    protected toast: ToastrService,
    protected bindAndIssueData: BindAndIssueData
  ) {
    super();
  }

  saveBindAndIssue(): void {
    try {
      this.entityRiskData.startSavingForms();
      const riskId = this.entityRiskData.getRiskId();
      const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;

      this.postBindNotes(riskId, riskDetailId);
    } catch (error) {
      this.entityRiskData.finishSavingForms();
      NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
    }
  }

  postBindNotes(riskId: any, riskDetailId: any): void {
    Utils.blockUI();
    this.quickQuoteService.postBindNotes(this.getBindNotesRequest(riskId, riskDetailId)).pipe(takeUntil(this.stop$)).subscribe(res => {
      const result = <BindAndIssueNoteResultDTO>res;
      this.bindAndIssueData.bindNotesSavedData.next(result.riskNotes);
      this.entityRiskData.finishSavingForms();
      NotifUtils.showConsoleSuccess(`${PathConstants.Submission.Submission.Endorsements} ${InfoMessageConstant.savedSuccessfullyMessage}`);
      Utils.unblockUI();
    },
      err => {
        this.entityRiskData.finishSavingForms();
        this.toast.error(ErrorMessageConstant.savingErrorMessage);
        Utils.unblockUI();
        NotifUtils.showError(JSON.parse(err.error).errors.toString());
        // add code here for error log
      }
    );
  }

  getBindNotesRequest(riskId: any, riskDetailId: any): SaveBindNotesDTO {
    const bindNotesRequest = {
      riskId: riskId,
      riskDetailId: riskDetailId,
      riskBind: {
        riskDetailId: riskDetailId,
        riskBindNotes: this.getNotes()
      }
    };

    return bindNotesRequest;
  }

  getNotes(): RiskBindNoteDTO[] {
    const notesList: RiskBindNoteDTO[] = this.bindAndIssueData.notesList.map(item => {
      return {
        id: item.id,
        riskBindId: item.riskBindId,
        category: item.category,
        description: item.description,
        detail: item.description,
        createdBy: item.createdBy,
        createdDate: item.createdDate ?? item.dateAdded.singleDate.formatted,
        isAdd: item.isAdd
      };
    });

    const internalNotesList: RiskBindNoteDTO[] = this.bindAndIssueData.internalNotesList.map(item => {
      return {
        id: item.id,
        riskBindId: item.riskBindId,
        category: item.category,
        description: item.description,
        detail: item.description,
        createdBy: item.createdBy,
        createdDate: item.createdDate ?? item.dateAdded.singleDate.formatted,
        isAdd: item.isAdd
      };
    });

    return [
      ...notesList,
      ...internalNotesList
    ];
  }

}
