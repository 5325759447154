import { ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClaimsLabelsConstants } from '../../../../../../shared/constants/claims.labels.constants';
import { ClaimsModalModel } from '../../../../../../shared/models/submission/claims/claims.model';
import { BsModalRef } from 'ngx-bootstrap';
import { ErrorMessageConstant } from '../../../../../../shared/constants/error-message.constants';
import { ClaimsData } from '../../../../../../modules/submission-management/data/claims.data';
import { AuthService } from '../../../../../../core/services/auth.service';
import { GenericConstants } from '../../../../../../shared/constants/generic.constants';
import { ClaimsSavingData } from '../../../../../../modules/submission-management/data/claims-saving.data';
import { PolicySummaryData } from '../../../../../../modules/policy-management/data/policy-summary.data';
import { SubmissionPageData } from '../../../../data/submission-page.data';
import { ClaimsService } from '../../../../../../core/services/submission/claims/claims.service';
import { InfoMessageConstant } from '../../../../../../shared/constants/info-message.constants';
import { BaseClass } from 'app/shared/base-class';
import { takeUntil } from 'rxjs/operators';
import { TaskService } from '../../../../../../core/services/task/task.service';
import { PageHeaderSummaryConstants } from 'app/shared/constants/page-header-summary.constants';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { EndorsementsData } from 'app/modules/submission-management/data/endorsements.data';
import Utils from 'app/shared/utilities/utils';

@Component({
  selector: 'app-claims-modal',
  templateUrl: './claims-modal.component.html',
  styleUrls: ['./claims-modal.component.scss'],
})
export class ClaimsModalComponent extends BaseClass implements OnInit {
  public event: EventEmitter<any> = new EventEmitter<any>();
  public ErrorMessageConstant = ErrorMessageConstant;
  public LabelMessageConstant = ClaimsLabelsConstants;
  claimsFrm: FormGroup;
  modalRef: BsModalRef | null;
  title: any;
  claimsModel: ClaimsModalModel;
  idForSaving: any;
  isAdd: boolean = true;
  isPolicy: boolean = false;
  riskId: string;
  protected  genericConstants = GenericConstants;
  public infoMessageConstant = InfoMessageConstant;
  hasAplusDuplicate: boolean;
  lossDate: string;
  lossType: string;
  public codeAPLUS: string = 'CS0';
  disableSaveButton: boolean = false;
  stateTimingUWPeriod: string = '';
  origChargeability: boolean = false;
  currentClaimType: string = '';
  currentLossDateValue: string = '';
  currentCATCodeValue: string = '';
  currentClaimSource: any = 'CS0';
  fileHandlerCode: any = 'CS3';
  constructor(
    protected  fb: FormBuilder,
    public bsModalRef: BsModalRef,
    public claimsData: ClaimsData,
    protected  authService: AuthService,
    protected  claimsSavingData: ClaimsSavingData,
    public policySummaryData: PolicySummaryData,
    public submissionData: SubmissionPageData,
    protected  claims: ClaimsService,
    protected  taskService: TaskService,
    protected  entityRiskData: EntityRiskData,
    public endorsementData: EndorsementsData,
    protected  cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.initData();
    //TODO: Update logic for DP3 Chargeable Update
    // if(!this.isAdd){
    //   this.onSelectClaimType();
    // }
  }

  get isRenewalOffered(): boolean {
    return (this.policySummaryData.renewalStatusCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RWO ||
      this.policySummaryData.renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RWO) ||
      (this.policySummaryData.renewalStatusCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO ||
      this.policySummaryData.renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO);
  }

  calculateDiff(sentDate) {
    const date1: any = sentDate;
    const date2: any = this.authService.getCustomDate();
    const diffDays: any = Math.floor((date1 - date2) / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  get disableFormFields(): boolean {

    const renewalEffectiveDate = new Date(this.entityRiskData.EntityRisk?.risks[0]?.renewalEffectiveDate);
    const diffDays = this.calculateDiff(renewalEffectiveDate);

    if (this.isRenewalOffered &&
        this.authService.userType.value === GenericConstants.userType.external &&
        diffDays < 2) {
      return true;
    }

    return this.submissionData.disableQuoteForms;
  }

  initData(): void {
    this.claimsSavingData.markClaimsForSaving();
    const nonWhitespaceRegExp: RegExp = new RegExp('\\S');

    this.initClaimsForm(nonWhitespaceRegExp);
    this.isPolicy = this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId());
    this.disableSubmissionControls();
    this.watchUserType();
    this.enableFieldsOnEdit();
    this.origChargeability = false;
    this.currentClaimType = this.claimsFrm.get('claimType').value;
    this.currentLossDateValue = this.claimsFrm.get('lossDate').value?.singleDate?.formatted;
    this.currentCATCodeValue = this.claimsFrm.get('catCode').value;
    this.currentClaimSource = this.claimsFrm.get('claimSource').value;
    this.claimsData.setClaimSourceDropdown(this.currentClaimSource);
    this.getClaimsFileHandlerFieldValidation();
    Utils.unblockUI();
  }

  initClaimsForm(nonWhitespaceRegExp: RegExp): void {
    this.claimsFrm = this.fb.group({
      id: [this.claimsModel?.id, []],
      lossDate: [this.claimsModel?.lossDate, [Validators.required]],
      claimType: [this.claimsModel?.claimType, [Validators.required]],
      additionalDescription: [
        this.claimsModel?.additionalDescription,
        [Validators.required, Validators.pattern(nonWhitespaceRegExp), Validators.maxLength(500)],
      ],
      claimAmount: [this.claimsModel?.claimAmount, [Validators.required]],
      claimSource: [this.claimsModel?.claimSource, [Validators.required]],
      claimChargeableVsNonChargeable: [
        this.claimsModel?.claimChargeableVsNonChargeable === null ? false : this.claimsModel?.claimChargeableVsNonChargeable ? true : false,
        [Validators.required]],
      claimStatus: [this.claimsModel?.claimStatus, [Validators.required]],
      dispute: [
        this.claimsModel?.dispute === null ? false : this.claimsModel?.dispute ? true : false
        , [Validators.required]],
      policyType: [this.claimsModel?.policyType, [Validators.required]],
      claimNumber: [this.claimsModel?.claimNumber, [Validators.required, Validators.pattern(nonWhitespaceRegExp)]],
      individualMatchType: [this.claimsModel?.individualMatchType, [Validators.required, Validators.pattern(nonWhitespaceRegExp)]],
      totalOutstandingReserve: [this.claimsModel?.totalOutstandingReserve + '' === '' ||
        this.claimsModel?.totalOutstandingReserve === undefined ? 0 : this.claimsModel?.totalOutstandingReserve, [Validators.required]],
      totalRecoveriesAndReimbursement: [this.claimsModel?.totalRecoveriesAndReimbursement + '' === '' ||
        this.claimsModel?.totalRecoveriesAndReimbursement === undefined ? 0 : this.claimsModel?.totalRecoveriesAndReimbursement, [Validators.required]],
      pcsCode: [this.claimsModel?.pcsCode],
      catCode: [this.claimsModel?.catCode, [Validators.required, Validators.pattern(nonWhitespaceRegExp)]],
      isLossAssociatedToHome: [this.claimsModel?.isLossAssociatedToHome, [Validators.required]],
      isLossAssociatedToInsured: [this.claimsModel?.isLossAssociatedToInsured, [Validators.required]],
      isExternal: [this.isExternal, []]
    });

    this.lossType = this.claimsModel?.claimType ? this.claimsModel?.claimType : null;
  }

  protected  disableFormControl(formControlName: string, value?: any): void {
    this.claimsFrm.controls[formControlName].disable();

    if (value) {this.claimsFrm.get(formControlName).patchValue(value);}
  }

  protected  enableFormControl(formControlName: string, value?: any): void {
    this.claimsFrm.controls[formControlName].enable();

    if (value) {this.claimsFrm.get(formControlName).patchValue(value);}
  }

  hideMyModal(): void {
    this.claimsData.isAddingClaim = false;
    this.bsModalRef.hide();
  }

  saveMyModal(): void {
    this.disableSaveButton = true;

    if (this.isAdd) {
      this.taskService.generateTaskId().pipe(takeUntil(this.stop$)).subscribe(generatedId => {
        if (generatedId) {
          this.saveClaim(generatedId);
        } else {
          this.disableSaveButton = false;
        }
      });
    } else {
      this.saveClaim(this.claimsModel.id);
    }
  }

  claimDateValidationMessage(value: string): string {
    return `${value} is required.`;
  }

  onSelectClaimType(): void {
    if (!this.isAdd) {
      const newValue = this.claimsFrm.get('claimType').value;
      if (this.currentClaimType !== newValue) {
        this.currentClaimType = newValue;
        this.checkClaimTypeChange();
      }
    } else {
      this.checkClaimTypeChange();
    }
  }

  checkClaimTypeChange(): void {
    this.lossType = this.claimsFrm.get('claimType').value;
    const isChargeable = this.getChargeableField(this.lossType);
    this.origChargeability = this.getChargeableField(this.lossType);
    this.claimsFrm.get('claimChargeableVsNonChargeable').setValue(isChargeable);
    this.checkClaimsNewBRules();
  }


  protected  watchUserType(): void {
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe((userType) => {
      if (!this.isAdd) {this.disableFieldsOnEdit();}

      if (userType === this.genericConstants.userType.external) {
        this.disableFormControl('claimChargeableVsNonChargeable');
      } else {
        this.enableFormControl('claimChargeableVsNonChargeable');
      }

      if (this.policySummaryData.isPolicyPage(this.riskId) && this.isExternal && !this.isAdd) {
        this.disableFormControl('additionalDescription');
        this.disableFormControl('dispute');
      }
    });
  }

  disableSubmissionControls(): void {
    this.disableFormControl('policyType');

    if (this.isExternal || this.claimsFrm.get('claimSource').value === this.codeAPLUS ||  this.claimsFrm.get('claimSource').value === this.fileHandlerCode) {
      this.disableFormControl('claimSource');
      this.disableFormControl('claimNumber');
      this.disableFormControl('individualMatchType');
    } else {
      this.enableFormControl('claimSource');
      this.enableFormControl('claimNumber');
      this.enableFormControl('individualMatchType');
    }

    if (this.isExternal || this.claimsFrm.get('claimSource').value === this.codeAPLUS ||  this.claimsFrm.get('claimSource').value === this.fileHandlerCode) {
      this.disableFormControl('claimSource');
      this.disableFormControl('claimNumber');
      this.disableFormControl('individualMatchType');
    } else {
      this.enableFormControl('claimSource');
      this.enableFormControl('claimNumber');
      this.enableFormControl('individualMatchType');
    }

    if (this.claimsFrm.get('claimSource').value === this.codeAPLUS ||
    this.claimsFrm.get('claimSource').value === this.fileHandlerCode) {
      this.disableFormControl('catCode');
      this.disableFormControl('isLossAssociatedToHome');
      this.disableFormControl('isLossAssociatedToInsured');
      this.disableFormControl('totalOutstandingReserve');
      this.disableFormControl('totalRecoveriesAndReimbursement');
      this.disableFormControl('pcsCode');
    } else {
      this.enableFormControl('isLossAssociatedToHome');
      this.enableFormControl('isLossAssociatedToInsured');
      this.enableFormControl('totalOutstandingReserve');
      this.enableFormControl('totalRecoveriesAndReimbursement');
      this.enableFormControl('pcsCode');
    }
  }

  protected  getChargeableField(claimType: string): boolean {
    if (this.claims.specialClaimTypeCodes.includes(claimType)) {
      return this.claims.getChargeableField(claimType);
    }

    return this.claimsData.claimTypes.find((type) => claimType === type.code).chargeable;
  }

  onLossDateChange(lossDate: any): void {
    if (lossDate?.valid) {
      this.lossDate = lossDate.value;
      if (!this.isAdd) {
        const newValue = this.lossDate;
        if (this.currentLossDateValue !== newValue) {
          this.currentLossDateValue = newValue;
          this.checkLossDateChange();
        }
      } else {
        this.checkLossDateChange();
      }
    }
  }

  checkLossDateChange(): void {
    this.lossType = this.claimsFrm.get('claimType').value;
    this.origChargeability = this.lossType ? this.getChargeableField(this.lossType) : false;
    this.checkClaimsNewBRules();
  }

  formatDateFromAplus(lossdate: any): string {
    const month = lossdate.month < 10 ? `0${lossdate.month}` : lossdate.month;
    const day = lossdate.day < 10 ? `0${lossdate.day}` : lossdate.day;

    return `${month}/${day}/${lossdate.year}`;
  }

  NEW23BRUL(lossDate: string, claimType: string): void {
    this.hasAplusDuplicate = this.claimsData.claimList.filter((claim) => {
      let lossDateFromAplus = claim.lossDate?.singleDate?.date;
      lossDateFromAplus = this.formatDateFromAplus(lossDateFromAplus);

      return lossDateFromAplus === lossDate && claim.claimType === claimType && claim.id !== this.claimsModel.id && claim.isAPlus;
    })?.length > 0;
  }

  disableFieldsOnEdit(): void {
    if (this.claimsFrm.get('claimSource').value === 'CS0' ||
    this.claimsFrm.get('claimSource').value === 'CS3') {
      this.disableFormControl('claimType');
      this.disableFormControl('claimAmount');
      this.disableFormControl('claimStatus');
      this.disableFormControl('totalOutstandingReserve');
      this.disableFormControl('totalRecoveriesAndReimbursement');
     this.disableFormControl('pcsCode');
    }

    this.disableFormControl('claimChargeableVsNonChargeable');
  }

  enableFieldsOnEdit(): void {
    if (!this.isAdd) {
      const fields: Array<string> = ['lossDate', 'claimType', 'additionalDescription', 'claimAmount', 'claimStatus','totalOutstandingReserve', 'totalRecoveriesAndReimbursement', 'pcsCode'];

      if (this.claimsFrm.get('claimSource').value !== this.codeAPLUS && this.claimsFrm.get('claimSource').value !== this.fileHandlerCode) {
        fields.forEach(name => {
          this.enableFormControl(name);
        });
      }
    }
  }

  get isExternal(): boolean {
    return this.authService.userType.value === this.genericConstants.userType.external;
  }

  saveClaim(id: string): void {
    const claimType = this.claimsData.claimTypes.filter((claimTypes) => claimTypes.code === this.claimsFrm.get('claimType')?.value);
    const currentDate = this.authService.getCustomDate(true);

    const data: ClaimsModalModel = {
      id,
      claimChargeableVsNonChargeable: this.claimsFrm.get('claimChargeableVsNonChargeable')?.value,
      lossDate: this.claimsFrm.get('lossDate')?.value,
      additionalDescription: this.claimsFrm.get('additionalDescription')?.value,
      claimSource: this.claimsFrm.get('claimSource')?.value,
      claimType: this.claimsFrm.get('claimType')?.value,
      notes: this.claimsFrm.get('notes')?.value,
      claimAmount: this.claimsFrm.get('claimAmount')?.value,
      otherQuestions: this.claimsFrm.get('otherQuestions')?.value,
      claimStatus: this.claimsFrm.get('claimStatus').value,
      catCode: this.claimsFrm.get('catCode').value,
      policyType: this.claimsFrm.get('policyType').value,
      dispute: this.claimsFrm.get('dispute').value,
      isActOfGod: claimType[0]?.actOfGod,
      individualMatchType: this.claimsFrm.get('individualMatchType')?.value,
      claimNumber: this.claimsFrm.get('claimNumber')?.value,
      riskClaimId: null,
      isAPlus: this.claimsModel.isAPlus ? true : false,
      createdDate: currentDate,
      isAdd: this.isAdd ? true : false,
      isComparative: this.claimsModel.isComparative,
      isLossAssociatedToHome: this.claimsFrm.get('isLossAssociatedToHome').value,
      isLossAssociatedToInsured: this.claimsFrm.get('isLossAssociatedToInsured').value,
      totalOutstandingReserve: this.claimsFrm.get('totalOutstandingReserve')?.value,
      totalRecoveriesAndReimbursement: this.claimsFrm.get('totalRecoveriesAndReimbursement')?.value,
      pcsCode: this.claimsFrm.get('pcsCode')?.value,
      isExternal: this.isExternal
    };

    this.bsModalRef.hide();
    this.event.emit({ data: data, res: 200 });
    this.disableSaveButton = false;
  }

  get isPolicyPage(): boolean {
    return this.policySummaryData.isPolicyPage(this.riskId);
  }

  onChangeCATCode(): void {
    if (!this.isAdd) {
      const newValue = this.claimsFrm.get('catCode').value;
      if (this.currentCATCodeValue !== newValue) {
        this.currentCATCodeValue = newValue;
        this.checkClaimsNewBRules();
      }
    } else {
      this.checkClaimsNewBRules();
    }
  }

  checkClaimsNewBRules(): void {
    if (this.lossDate && this.lossType) {
      this.NEW23BRUL(this.lossDate, this.lossType);
      const isChargeable = this.getChargeableField(this.lossType);
      if (isChargeable) {
        const isWithin3Yrs = this.New78BrulForManual(this.lossDate);
        if (isWithin3Yrs) {
          const isNotCatClaim = this.New79BrulForManual();
          if (this.isPolicy && isNotCatClaim) {
            this.New103BrulB(this.lossDate);
          }
        }
      } else {
        this.claimsFrm.get('claimChargeableVsNonChargeable').setValue(false);
      }
    }
  }

  New103BrulB(lossDate: string): void {
    const currentDate: Date = this.authService.getCustomDate(true);
    currentDate.setHours(0, 0, 0, 0);
    const uwPeriod: number = this.claimsData.stateTimingData ? Number(this.claimsData.stateTimingData.uwPeriod) : 0;
    const claimLossDate: Date = new Date(lossDate);
    const policyEffectiveDate: Date = new Date(this.entityRiskData.EntityRisk?.risks[0].firstIssueDate);
    let uwPeriodDate: Date = new Date(policyEffectiveDate);
    uwPeriodDate = new Date(uwPeriodDate.setDate(uwPeriodDate.getDate() + uwPeriod));
    uwPeriodDate.setHours(0, 0, 0, 0);
    const isWithinUwPeriod: boolean = currentDate <= uwPeriodDate;
    const isLessThanEffDate: boolean = claimLossDate < policyEffectiveDate;
    this.claimsFrm.get('claimChargeableVsNonChargeable').setValue(isWithinUwPeriod && isLessThanEffDate);
  }

  New78BrulForManual(lossDate: string): boolean {
    const policyEffectiveDate: Date = new Date(this.entityRiskData.EntityRisk?.risks[0].firstIssueDate);
    const hasClaimsWithin3Years = this.claimsData.hasClaimsForPastThreeYears(lossDate, policyEffectiveDate, false);
    this.claimsFrm.get('claimChargeableVsNonChargeable').setValue(hasClaimsWithin3Years);
    return hasClaimsWithin3Years;
  }

  New79BrulForManual(): boolean {
    const catCode: string = this.claimsFrm.get('catCode').value;
    const isNotCatClaim = catCode.trim().toLowerCase() !== 'y';
    this.claimsFrm.get('claimChargeableVsNonChargeable').setValue(isNotCatClaim);
    return isNotCatClaim;
  }

  get invalidLossAssociated(): boolean {
    return !(this.claimsFrm.get('isLossAssociatedToHome').value || this.claimsFrm.get('isLossAssociatedToInsured').value);
  }

  onSelectedClaimSource(event){
    this.currentClaimSource = this.claimsFrm.get('claimSource').value;
    this.getClaimsFileHandlerFieldValidation();
    this.cdr.detectChanges();
   }

   getClaimsFileHandlerFieldValidation() {
     if (this.claimsFrm.get('claimSource').value === ClaimsLabelsConstants.claimSourceCodes.fileHandler) {
       this.claimsFrm.get('totalOutstandingReserve').setValidators([Validators.required]);
       this.claimsFrm.get('totalOutstandingReserve').updateValueAndValidity();
       this.claimsFrm.get('totalRecoveriesAndReimbursement').setValidators([Validators.required]);
       this.claimsFrm.get('totalRecoveriesAndReimbursement').updateValueAndValidity();
     } else {
       this.claimsFrm.get('totalOutstandingReserve').clearValidators();
       this.claimsFrm.get('totalOutstandingReserve').updateValueAndValidity();
       this.claimsFrm.get('totalRecoveriesAndReimbursement').clearValidators();
       this.claimsFrm.get('totalRecoveriesAndReimbursement').updateValueAndValidity();
     }
   }

}
