import { Injectable } from '@angular/core';
import { LvZipCodeDTO } from '../../../shared/models/data/dto/quick-quote/lv-zipcode.dto';
import { ZipcodesService } from '../../../core/services/submission/zipcodes/zipcodes.service';
import { BaseClass } from '../../../shared/base-class';
import Utils from '../../../shared/utilities/utils';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { FormGroup } from '@angular/forms';
import { PageSections } from '../../../shared/enum/page-sections.enum';
import { share, take, takeUntil } from 'rxjs/operators';
import { EntityRiskData } from './entity-risk.data';
import FormUtils from '../../../shared/utilities/form.utils';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { CoveragesData } from 'app/modules/submission-management/data/coverages.data';
import { InfoMessageConstant } from 'app/shared/constants/info-message.constants';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { MoratoriumService } from '../../../core/services/moratorium.services';
import { ResponseObjectMoratorium, RequestObjectMoratorium } from 'app/shared/models/submission/quick-quote/moratoriums.model';
import * as _ from 'lodash';
import { GenericConstants } from '../../../shared/constants/generic.constants';
import { AuthService } from '../../../core/services/auth.service';
import { QuoteService } from '../../../core/services/quote.service';
import { FormTypeConstants } from '../../../shared/constants/form-types.constants';
import { HORaterRequestData } from '../../../core/services/submission/rater-premium/ho-rater-request-data.service';
import { DP3RaterRequestData } from '../../../core/services/submission/rater-premium/dp3rater-request-data.service';
import { PolicyIssueData } from 'app/modules/policy-management/data/policy-issue.data';
import { utils } from 'protractor';
import * as moment from 'moment';
import { createSubmissionDetailsMenuItems } from '../../../modules/submission-management/submission-management-navitems';
import { LayoutService } from '../../../core/services/layout/layout.service';
import { SubmissionPageData } from './submission-page.data';
import { ComparativeRaterConstants } from '../../../shared/constants/rater.constant';
import { ApplicantLabelsConstants } from '../../../shared/constants/applicant.labels.constants';
import { MoratoriumType } from '../../../shared/constants/moratoriumType';
import { SubmissionCopiedInformation } from '../../../shared/models/submission/applicant/submission-copied-information';
import {
  updateAppIsConfirmationFromZipCodeData,
  updateAppIsLoadingFromZipCodeData
} from 'app/store/app/app.actions';
import { Store } from '@ngrx/store';
import {
  updateMoratoriumIsLoadingFromZipCodeData,
  updateMoratoriumListFromZipCodeData }
from 'app/store/moratorium/moratorium.actions';
import { SummaryData } from './summary.data';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { INavDetails } from 'app/_nav';

@Injectable({
  providedIn: 'root'
})
export class ZipCodeData extends BaseClass {
  constructor(
    protected zipCodeService: ZipcodesService,
    public entityRiskData: EntityRiskData,
    protected coveragesData: CoveragesData,
    protected moratoriumService: MoratoriumService,
    protected authService: AuthService,
    protected quoteService: QuoteService,
    protected hoRaterData: HORaterRequestData,
    protected dpRaterData: DP3RaterRequestData,
    protected policyIssueData: PolicyIssueData,
    protected layoutService: LayoutService,
    protected submissionPageData: SubmissionPageData,
    protected store: Store,
    protected summaryData: SummaryData,
    protected policySummaryData: PolicySummaryData
  ) {
    super();
  }
  public FormUtils = FormUtils;

  public isDisableControlsDueToMoratorium: boolean = false;

  public fetchMoratoriumComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  stateCode: string;

  zipCodeData: any;
  zipCodeList: any = [];
  qqCityList: any = [];
  qqCountyList: any = [];
  applicantInsuredCityList: any = [];
  applicantInsuredqqCountyList: any = [];
  coApplicantCityList: any = [];
  coApplicantCountyList: any = [];
  interestCityList: any = [];
  billingCityList: any = [];
  billingCountyList: any = [];
  checkCityList: any = [];
  ccCityList: any = [];
  eftCityList: any = [];
  supendedPaymentCityList: any = [];
  addSupendedPaymentCityList: any = [];
  selectedCity: string = '';
  zipCodeInfo: any;
  cityObservable = new Subject<{ value: string; onFetch: boolean }>();
  multipleCities: boolean = false;
  zipCodeOnInitChange: boolean = true;
  public infoMessageConstant = InfoMessageConstant;

  getCityListCompleted$: Subject<null> = new Subject<null>();

  isZipCodeFromComparativeRater: boolean = false;

  moratoriumSubscription: Subscription;
  effectiveDateChanged: string = '';
  isDoneMoratoriumChecking$: Subject<null> = new Subject<null>();
  isPrefillClicked: boolean = false;
  isZipcodeChangedAfterCopy: boolean = false;

  getAllZipCodeByState(state?) {
    this.zipCodeService.getAllZipCodeByState(state).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.zipCodeList = <LvZipCodeDTO>result;
    });
  }

  getZipCode(zipCode?, formGroup?, formControlNames?, section?, isAdd?) {
    this.zipCodeService.getZipCode(zipCode).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.zipCodeData = <LvZipCodeDTO>result;

      if (this.zipCodeData.length > 0) {
        try {
          this.setList(this.zipCodeData, formGroup, formControlNames, section, isAdd);
        } catch (e) { }

        this.store.dispatch(updateAppIsLoadingFromZipCodeData({ isLoading: false }));
      } else {
        this.FormUtils.resetFields(formGroup, formControlNames);
        NotifUtils.showError('Zip code not found, contact Underwriting');
      }
    }, error => {
      this.store.dispatch(updateAppIsLoadingFromZipCodeData({ isLoading: false }));
      this.FormUtils.resetFields(formGroup, formControlNames);
      NotifUtils.showError('Zip code not found, contact Underwriting');
    });
  }

  setZipCodeInfo(zipCodeData){
    this.zipCodeInfo = zipCodeData;
    localStorage.setItem('zipCode',JSON.stringify(this.zipCodeInfo[0]));
  }

  getZipCodeWithState(zipCode?, state?, formGroup?, formControlNames?, section?) {
    this.zipCodeService.getZipCodeWithState(zipCode, state).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.zipCodeData = <LvZipCodeDTO>result;

      this.isDisableControlsDueToMoratorium = true; // Initialize to be disabled until moratorium has been checked
      this.effectiveDateChanged = '';
      this.displayMoratoriumNewQuote(zipCode, state, formGroup,result as LvZipCodeDTO, false, null);

      if (this.zipCodeData.length > 0) {
        try {
          this.setList(this.zipCodeData, formGroup, formControlNames, section);
          this.checkZipCodeToExcludedZipCodeList(zipCode);
          this.setZipCodeInfo(this.zipCodeData);
        } catch (e) { }
      } else {
        this.FormUtils.resetFields(formGroup, formControlNames);
        NotifUtils.showError('Zip code not found, contact Underwriting');
      }
    }, error => {
      this.store.dispatch(updateAppIsLoadingFromZipCodeData({ isLoading: false }));
      this.FormUtils.resetFields(formGroup, formControlNames);
      NotifUtils.showError('Zip code not found, contact Underwriting');
    });
  }

  checkZipCodeToExcludedZipCodeList(zipCode): void {
    if (zipCode) {
      this.coveragesData.showPrivateFlood = !this.coveragesData.floodCoverageExcludedZipCode.find(data => data === Number(zipCode));
      if (!this.coveragesData.showPrivateFlood) {
        this.coveragesData.floodForm.get('floodCoverageALimit').setValue(null);
        this.coveragesData.floodForm.get('floodCoverageCLimit').setValue(null);
        NotifUtils.messages.push({ html: this.infoMessageConstant.excludedZipCodeList});
        NotifUtils.showQueue();
      } else {
        Utils.unblockUI();
      }
    } else {
      this.coveragesData.showPrivateFlood = true;
    }
  }

  getGenericZipCode(zipCode?, formGroup?, formControlNames?, section?, isAdd?, errorMessage: string = ErrorMessageConstant.zipCodeNotFoundContactUWErrorMessage) {
    this.zipCodeService.getGenericZipCode(zipCode).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.zipCodeData = <LvZipCodeDTO>result;

      if (this.zipCodeData.length > 0) {
        try {
          this.setList(this.zipCodeData, formGroup, formControlNames, section, isAdd);
        } catch (e) { }
        Utils.unblockUI();
      } else {
        this.FormUtils.resetFields(formGroup, formControlNames);
        if (section === PageSections.CoApplicant) {
          if (this.entityRiskData.getRiskId()) {
            NotifUtils.showError(errorMessage);
            formGroup.markAllAsTouched();
            this.coApplicantCityList = [];
          }
        } else if (section === PageSections.ApplicantInformation) {
          NotifUtils.showError(errorMessage);
          formGroup.markAllAsTouched();
          this.applicantInsuredCityList = [];
        } else {
          NotifUtils.showError(errorMessage);
        }
      }
    }, error => {
      console.log(error);
      Utils.unblockUI();
      this.FormUtils.resetFields(formGroup, formControlNames);
      NotifUtils.showError(errorMessage);
    });
  }

  setPropertyCounty(zipCode?, state?, formGroup?) {
    this.zipCodeService.getZipCodeWithState(zipCode, state).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.zipCodeData = <LvZipCodeDTO>result;
      this.qqCountyList = [];
      this.qqCountyList = this.getCountyList(this.zipCodeData);
      formGroup.get('county').setValue(this.qqCountyList);
    }, error => {
      console.log(error);
      NotifUtils.showError('Zip code not found');
    });
  }

  setList(zipCodeData?, formGroup?, formControlNames?, section?, isAdd?) {
    switch (section) {
      case PageSections.QuickQuoteApplicant:
        this.qqCityList = [];
        this.qqCityList = this.getCityList(zipCodeData);
        if (this.qqCityList.length > 0) {
          this.autoPopulateValues(zipCodeData, formGroup, formControlNames, this.qqCityList);
        }
        break;
      case PageSections.ApplicantInformation:
        this.applicantInsuredCityList = [];
        this.applicantInsuredCityList = this.getCityList(zipCodeData);
        if (this.applicantInsuredCityList.length > 0) {
          this.autoPopulateValues(zipCodeData, formGroup, formControlNames, this.applicantInsuredCityList);
        }
        break;
      case PageSections.CoApplicant:
        this.coApplicantCityList = [];
        this.coApplicantCityList = this.getCityList(zipCodeData);
        if (this.coApplicantCityList.length > 0) {
          this.autoPopulateValues(zipCodeData, formGroup, formControlNames, this.coApplicantCityList, false, true);
        }
        break;
      case PageSections.Interest:
        this.interestCityList = [];
        this.interestCityList = this.getCityList(zipCodeData);
        if (this.interestCityList.length > 0) {
          this.autoPopulateValues(zipCodeData, formGroup, formControlNames, this.interestCityList, isAdd);
        }
        break;
      case PageSections.Billing:
        this.billingCityList = [];
        this.billingCityList = this.getCityList(zipCodeData);
        if (this.billingCityList.length > 0) {
          this.autoPopulateValues(zipCodeData, formGroup, formControlNames, this.billingCityList);
          this.billingCityListComplete();
        }
        break;
      case PageSections.CreditCardPayment:
        this.ccCityList = [];
        this.ccCityList = this.getCityList(zipCodeData);
        if (this.ccCityList.length > 0) {
          this.autoPopulateValues(zipCodeData, formGroup, formControlNames, this.ccCityList);
          this.billingCityListComplete();
        }
        break;
      case PageSections.EFTPayment:
        this.eftCityList = [];
        this.eftCityList = this.getCityList(zipCodeData);
        if (this.eftCityList.length > 0) {
          this.autoPopulateValues(zipCodeData, formGroup, formControlNames, this.eftCityList);
          this.billingCityListComplete();
        }
        break;
      case PageSections.CheckPayment:
        this.checkCityList = [];
        this.checkCityList = this.getCityList(zipCodeData);
        if (this.checkCityList.length > 0) {
          this.autoPopulateValues(zipCodeData, formGroup, formControlNames, this.checkCityList);
          this.billingCityListComplete();
        }
        break;
      case PageSections.SuspendedPayment:
        this.supendedPaymentCityList = [];
        this.supendedPaymentCityList = this.getCityList(zipCodeData);
        if (this.supendedPaymentCityList.length > 0) {
          this.autoPopulateValues(zipCodeData, formGroup, formControlNames, this.supendedPaymentCityList);
        }
        break;
      case PageSections.AddSuspendedPayment:
        this.addSupendedPaymentCityList = [];
        this.addSupendedPaymentCityList = this.getCityList(zipCodeData);
        if (this.addSupendedPaymentCityList.length > 0) {
          this.autoPopulateValues(zipCodeData, formGroup, formControlNames, this.addSupendedPaymentCityList, isAdd);
        }
        break;
    }
  }

  getCityList(zipCode) {
    const cityList = [];
    zipCode.map(val => {
      const city = val.city;
      cityList.push(city);
    });

    return cityList;
  }

  getCountyList(zipCode) {
    const countyList = [];
    zipCode.map(val => {
      const county = val.county;
      countyList.push(county);
    });

    return countyList;
  }

  autoPopulateValues(zipData?, formGroup?: FormGroup, formControls?, cityList?, isAdd = true, isFromCoApplicant = false) {
    formControls.map(formControl => {
      if (formControl.toLowerCase().includes('city')) {
        if (isFromCoApplicant) {
          this.updateFormControlValidity(formGroup, formControl);
        }
        if (cityList.length === 1) {
          formGroup.get(formControl).setValue(zipData[0].city);
          formGroup.get(formControl).disable();

          if (isFromCoApplicant) {
            this.coApplicantCitySetter(false, zipData[0].city);
          }
        } else {
          const currentCity = formGroup.get(formControl)?.value;
          const cityFound = currentCity ? cityList.find(city => city.toLowerCase().includes(currentCity.toLowerCase())) : '';
          if (!cityList.includes(cityFound)) {
            formGroup.get(formControl).setValue('');
          } else {
            formGroup.get(formControl).setValue(cityFound ?? '');
          }

          if (isFromCoApplicant) {
            this.coApplicantCitySetter(true, this.selectedCity);
          }
          formGroup.get(formControl).markAsTouched();
        }
      } else if (formControl.toLowerCase().includes('state')) {
        formGroup.get(formControl).setValue(zipData[0].stateCode);
      } else if (formControl.toLowerCase().includes('county')) {
        formGroup.get(formControl).setValue(zipData[0].county);
      }
    });
  }

  updateFormControlValidity(group: FormGroup, control: string): void {
    /**
     * This method is for formcontrols that
     * do not update its validity in realtime
     */
    if (!this.entityRiskData.policySummaryData.isPolicy ||
      (this.entityRiskData.policySummaryData.isPolicy && this.entityRiskData.policySummaryData.isEditPolicy)) {
      group.get(control).markAsDirty();
      group.get(control).enable();
      group.get(control).markAsTouched();
    }
  }

  coApplicantCitySetter(isMultipleCities: boolean, city: string, onSelect: boolean = false): void {
    this.multipleCities = isMultipleCities;

    if ((!onSelect && ((this.multipleCities && this.zipCodeOnInitChange) || !this.multipleCities)) || onSelect) {
      this.cityObservable.next({ value: city, onFetch: false });
    } else if (!onSelect && this.multipleCities && !this.zipCodeOnInitChange) {
      this.cityObservable.next({ value: '', onFetch: false });
    }
  }

  billingCityListComplete() {
    this.getCityListCompleted$.next();
  }

  public disableControlsDueToMoratorium(request: ResponseObjectMoratorium, isNewQuote: boolean = false) {
    if(request.type === GenericConstants.closure) {
      this.isDisableControlsDueToMoratorium = true;
      this.layoutService.updateMenu(
        createSubmissionDetailsMenuItems({riskId:''},true)
      );
      this.submissionPageData.isOrder = false;
    } else {
      if (isNewQuote) {
        this.submissionPageData.isOrder = this.isPrefillClicked;
        this.layoutService.updateMenu(
          createSubmissionDetailsMenuItems({riskId:''}, false)
        );
      } else {
        this.submissionPageData.isOrder = true;
      }
      this.isDisableControlsDueToMoratorium = false;
    }
  }

  public enableControlsAfterClosureChecking() {
    this.layoutService.updateMenu(
      createSubmissionDetailsMenuItems({riskId:''},false)
    );
  }

  public displayMoratoriumNewQuote(zipCode: string, state: string, formGroup: FormGroup, result?: LvZipCodeDTO, isFromCopy: boolean = false, copiedInformation?: SubmissionCopiedInformation): void {
    const counties: string [] = _.uniq(_.map((result as LvZipCodeDTO), 'county'));
    const moratoriumRequestObject: RequestObjectMoratorium = {
      county: isFromCopy && !this.isZipcodeChangedAfterCopy ? copiedInformation.county : counties?.length >= 1 ? counties[0]?.toString()?.trim() : '',
      effectiveDate: this.effectiveDateChanged !== '' ? this.effectiveDateChanged : moment(new Date(formGroup?.get('effectiveDate')?.value?.singleDate?.jsDate)).format('YYYY-MM-DD'),
      zipCode: zipCode?.toString()?.trim(),
      stateCode: state,
      formType: this.quoteService.formType,
      agencyId: this.entityRiskData.agencyId
    };

    if (!moratoriumRequestObject.county || !moratoriumRequestObject.effectiveDate || !moratoriumRequestObject.zipCode || !moratoriumRequestObject.stateCode || moratoriumRequestObject.effectiveDate.toLocaleString().toLowerCase() === 'invalid date') {
      Utils.unblockUI();
      return;
    }

    if (this.moratoriumSubscription) {
      this.moratoriumSubscription.unsubscribe();
    }

    this.store.dispatch(updateMoratoriumIsLoadingFromZipCodeData({ isLoading: true }));

    this.moratoriumService.getAllMoratoriums().pipe(takeUntil(this.stop$)).subscribe(moraResult => {
      this.entityRiskData.allMoratoriumList = moraResult;
    }, (error) => {
      NotifUtils.showError(GenericConstants.moratoriumAPIError);
    });

    this.moratoriumSubscription = this.moratoriumService.getMoratoriums(moratoriumRequestObject).pipe(takeUntil(this.stop$),share()).subscribe(moratoriumResult => {
      const response = moratoriumResult.filter(a => a.type === MoratoriumType.closure || a.type === MoratoriumType.moratorium || a.type === MoratoriumType.coverageMoratorium);
      if (response?.length > 0) {
        this.entityRiskData.moratoriumResponse = response;

        if (this.authService.isExternalUser) {
          let messageBuilder = '';
          response.forEach(x => {
            messageBuilder += ` <li> ${x.popUpMessage} </li> `;
            this.disableControlsDueToMoratorium(x, true);
          });

          if (NotifUtils.messages?.length > 0) {
            const sameMessage = NotifUtils.messages.filter(x => x.html === messageBuilder);
            if (sameMessage?.length === 0) {
              NotifUtils.messages.push({ html: messageBuilder });
            }
          } else {
            NotifUtils.messages.push({ html: messageBuilder });
          }

          this.store.dispatch(updateAppIsConfirmationFromZipCodeData({ isConfirmation: true }));

          Utils.isCheckingMoratorium = true;
          NotifUtils.showQueue(() => {
            this.store.dispatch(updateAppIsConfirmationFromZipCodeData({ isConfirmation: false }));
          });
          Utils.isCheckingMoratorium = false;
        } else {
          this.isDisableControlsDueToMoratorium = false;
        }
      } else {
        this.entityRiskData.moratoriumResponse = null;
        this.isDisableControlsDueToMoratorium = false;
        this.submissionPageData.isOrder = this.isPrefillClicked;
        this.enableControlsAfterClosureChecking();
      }
      this.entityRiskData.isNewQuote = true;
      this.checkZipCodeIfFromComparativeRater(response);

      this.store.dispatch(updateMoratoriumListFromZipCodeData({ list: moratoriumResult }));
      this.store.dispatch(updateMoratoriumIsLoadingFromZipCodeData({ isLoading: false }));
    },(error) => {
      this.entityRiskData.moratoriumResponse = null;
      this.isDisableControlsDueToMoratorium = false;
      this.submissionPageData.isOrder = this.isPrefillClicked;
      this.enableControlsAfterClosureChecking();
      this.store.dispatch(updateAppIsConfirmationFromZipCodeData({ isConfirmation: true }));

      NotifUtils.showInformationWithConfirmationFromUser(GenericConstants.moratoriumAPIError, () => {
        this.store.dispatch(updateAppIsConfirmationFromZipCodeData({ isConfirmation: false }));
      });
    });
  }

  public displayMoratoriumExistingQuote(formGroup: FormGroup, countyFromProperty?: string): void {
    if (!this.entityRiskData.policySummaryData.isPolicy) {
      const entityAdress = this?.entityRiskData?.EntityRisk?.entityAddresses.find(a => a.addressTypeCode === ApplicantLabelsConstants.addressType.propertyAddress);
      const countyFromEntity = entityAdress.address?.county?.toString()?.trim() ?? '';
      const countyForRequest = countyFromEntity && countyFromEntity !== '' ? countyFromEntity : countyFromProperty;
      const effectiveDateFromEntity = this.getMoratoriumEffectiveDate(formGroup);
      const zipCodeFromEntity = entityAdress.address?.zipCode?.toString()?.trim() ?? '';
      const stateCodeFromEntity = entityAdress.address?.state?.toString()?.trim() ?? '';
      const moratoriumRequest: RequestObjectMoratorium = {
        county: countyForRequest,
        effectiveDate: effectiveDateFromEntity,
        zipCode: zipCodeFromEntity,
        stateCode: stateCodeFromEntity,
        formType: this.quoteService.formType,
        agencyId: this.entityRiskData.agencyId
      };

      if (!countyForRequest || !effectiveDateFromEntity || !zipCodeFromEntity || !stateCodeFromEntity) {
        Utils.unblockUI();
        return;
      }

      if (this.moratoriumSubscription) {
        this.moratoriumSubscription.unsubscribe();
      }

      this.store.dispatch(updateAppIsLoadingFromZipCodeData({ isLoading: true }));
      this.store.dispatch(updateMoratoriumIsLoadingFromZipCodeData({ isLoading: true }));

      this.moratoriumService.getAllMoratoriums().pipe(takeUntil(this.stop$)).subscribe(moraResult => {
        this.entityRiskData.allMoratoriumList = moraResult;
      }, (error) => {
        NotifUtils.showError(GenericConstants.moratoriumAPIError);
      });

      this.moratoriumSubscription = this.moratoriumService.getMoratoriums(moratoriumRequest).pipe(takeUntil(this.stop$)).subscribe(moratoriumResult => {
        this.entityRiskData.moratoriumResponse =
              moratoriumResult.filter(a => a.type === MoratoriumType.closure ||
                            a.type === MoratoriumType.moratorium ||
                            a.type === MoratoriumType.coverageMoratorium) ?? [];
        this.entityRiskData.isDoneFetchMoratorium = true;
        this.fetchMoratoriumComplete.next(true);
        this.entityRiskData.isDoneFetchingMoratorium$.next(true);

        if (this.entityRiskData.moratoriumResponse?.length > 0) {
          if (this?.authService?.userType?.value?.toString()?.toLowerCase() === GenericConstants?.userType?.external?.toString()?.toLowerCase()) {
            let messageBuilder = '';
            this.entityRiskData.moratoriumResponse.forEach(x => {
              messageBuilder += ` <li> ${x.popUpMessage} </li> `;
              this.disableControlsDueToMoratorium(x);
            });

            if (this.authService.isExternalUser) {
              this.store.dispatch(updateAppIsConfirmationFromZipCodeData({ isConfirmation: true }));

              if (!this.restrictedAccess) {
                NotifUtils.showInformationWithConfirmationFromUser(messageBuilder, () => {
                  this.store.dispatch(updateAppIsConfirmationFromZipCodeData({ isConfirmation: false }));
                });
              } else {
                this.store.dispatch(updateAppIsConfirmationFromZipCodeData({ isConfirmation: false }));
              }
            }
          }
        } else {
          this.submissionPageData.isOrder = true;
          this.isDisableControlsDueToMoratorium = false;
          this.entityRiskData.moratoriumResponse = null;
          this.store.dispatch(updateAppIsLoadingFromZipCodeData({ isLoading: false }));
        }

        this.store.dispatch(updateMoratoriumListFromZipCodeData({ list: moratoriumResult }));
        this.store.dispatch(updateMoratoriumIsLoadingFromZipCodeData({ isLoading: false }));
        this.store.dispatch(updateAppIsLoadingFromZipCodeData({ isLoading: false }));
        this.isDoneMoratoriumChecking$.next();
      },(error) => {
        this.store.dispatch(updateAppIsLoadingFromZipCodeData({ isLoading: false }));
        this.store.dispatch(updateAppIsConfirmationFromZipCodeData({ isConfirmation: true }));

        NotifUtils.showInformationWithConfirmationFromUser(GenericConstants.moratoriumAPIError, () => {
          this.store.dispatch(updateAppIsConfirmationFromZipCodeData({ isConfirmation: false }));
        });
      });
    }
  }

  public displayMoratoriumEndorsementOnExistingPolicyIssue(countyFromEntity: string, effectiveDateFromEntity: any, zipCodeFromEntity: string, stateCodeFromEntity: string): void {
    const moratoriumRequest: RequestObjectMoratorium = {
      county: countyFromEntity,
      effectiveDate: effectiveDateFromEntity,
      zipCode: zipCodeFromEntity,
      stateCode: stateCodeFromEntity,
      formType: this.quoteService.formType,
      agencyId: this.entityRiskData.agencyId
    };

      if (!countyFromEntity || !effectiveDateFromEntity || !zipCodeFromEntity || !stateCodeFromEntity) {
        Utils.unblockUI();
        return;
      }

      if (this.moratoriumSubscription) {
        this.moratoriumSubscription.unsubscribe();
      }

      this.store.dispatch(updateMoratoriumIsLoadingFromZipCodeData({ isLoading: true }));

      this.moratoriumService.getAllMoratoriums().pipe(takeUntil(this.stop$)).subscribe(moraResult => {
        this.entityRiskData.allMoratoriumList = moraResult;
      }, (error) => {
        NotifUtils.showError(GenericConstants.moratoriumAPIError);
      });

      this.moratoriumSubscription = this.moratoriumService.getMoratoriums(moratoriumRequest).pipe(takeUntil(this.stop$)).subscribe(moratoriumResult => {
        const response = moratoriumResult.filter(a => a.type === MoratoriumType.closure || a.type === MoratoriumType.moratorium || a.type === MoratoriumType.coverageMoratorium);
        if (response?.length > 0) {
          let messageBuilder = '';
          response?.filter(x => x.type !== GenericConstants.closure)?.forEach(x => {
            messageBuilder += ` <li> ${x.popUpMessage} </li> `;
          });
          if (messageBuilder !== '') {
            this.entityRiskData.moratoriumResponse = response;
            if (this.authService.isExternalUser) {
              this.store.dispatch(updateAppIsConfirmationFromZipCodeData({ isConfirmation: true }));

              NotifUtils.showInformationWithConfirmationFromUser(messageBuilder, () => {
                this.store.dispatch(updateAppIsConfirmationFromZipCodeData({ isConfirmation: false }));
              });
            }
          } else {
            this.entityRiskData.moratoriumResponse = null;
          }
          this.policyIssueData.isEditButtonClicked$.next(true);
        } else {
          this.entityRiskData.moratoriumResponse = null;
        }

        this.store.dispatch(updateMoratoriumListFromZipCodeData({ list: moratoriumResult }));
        this.store.dispatch(updateMoratoriumIsLoadingFromZipCodeData({ isLoading: false }));
      }, (error) => {
        this.store.dispatch(updateAppIsConfirmationFromZipCodeData({ isConfirmation: true }));

        NotifUtils.showInformationWithConfirmationFromUser(GenericConstants.moratoriumAPIError, () => {
          this.store.dispatch(updateAppIsConfirmationFromZipCodeData({ isConfirmation: false }));
        });
      });
  }

  public reCheckMoratorium(effectiveDate: any, zipCode: string, state: string, formGroup: any, isNewQuote?: boolean, copiedInformation?: SubmissionCopiedInformation, countyFromProperty?: string): void {
    switch(isNewQuote) {
      case true:
        const counties: string [] = _.uniq(_.map((this.zipCodeData as LvZipCodeDTO), 'county'));
        const copiedCounty = copiedInformation?.county;
        const isFromCopy = copiedCounty !== undefined && copiedCounty !== '';
        const countyName = counties?.length === 1 ? counties[0]?.toString()?.trim() : '';
        if (effectiveDate && zipCode && (countyName || copiedCounty)) {
          this.displayMoratoriumNewQuote(zipCode, state, formGroup, this.zipCodeData, isFromCopy, copiedInformation);
        }
       break;
      case false:
        if (!this.checkIfQuoteInRenewal()) {
          this.displayMoratoriumExistingQuote(formGroup, countyFromProperty);
        }
        break;
    }
  }

  checkZipCodeIfFromComparativeRater(moratoriumDetails): void {
    const isForClosure = [];
    if (this.isZipCodeFromComparativeRater) {
      moratoriumDetails?.forEach(x => {
        if (Boolean(x.type === 'Closure')) {
          isForClosure.push(x);
        }
      });

      if (isForClosure.length === 0) {
        NotifUtils.messages.push({ html: ComparativeRaterConstants.prefillMessage});
        NotifUtils.showQueue();
      }
    }
  }

  getMoratoriumEffectiveDate(formGroup: FormGroup): string {  // HOTFIX CENTAURI-6012
    return Boolean(formGroup.get('effectiveDate')?.value?.singleDate) ?
      moment(new Date(formGroup.get('effectiveDate')?.value?.singleDate?.jsDate)).format('YYYY-MM-DD') : moment(new Date(formGroup.get('effectiveDate')?.value?.jsDate)).format('YYYY-MM-DD');
  }

  get restrictedAccess(): boolean {
    const isExternal = this.summaryData.authService.userType.value === GenericConstants.userType.external;
    const restrictedAccess = (this.policySummaryData.renewalStatusCode === 'RRNPEN' && this.entityRiskData.getRisk().riskStatusCode !== 'CAN') && isExternal;

    return restrictedAccess;
  }

  checkIfQuoteInRenewal(): boolean {
    return (this.policySummaryData.renewalCode === 'RWNT' ||
      this.policySummaryData.renewalCode === 'RWO' ||
      this.policySummaryData.renewalCode === 'RRWO' ||
      this.policySummaryData.renewalCode === 'RRNPEN' ||
      this.policySummaryData.renewalCode === 'RNPEN');
  }

  recreateMenuAfterMoratoriumChecking(isMora: boolean, navDetails: INavDetails): void {
    if (isMora) {
      this.layoutService.updateMenu(
        createSubmissionDetailsMenuItems({riskId:''},true)
      );
    } else {
      this.layoutService.updateMenu(
        createSubmissionDetailsMenuItems(navDetails)
      );
    }
  }

  recreateMenuAfterCommissionRateChecking(navDetails: INavDetails): void {
    this.layoutService.updateMenu(createSubmissionDetailsMenuItems(navDetails));
  }
}
