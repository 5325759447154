import { Injectable, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/base-component';
import { PathConstants } from '../../../shared/constants/path.constants';
import { ValidationListModel } from '../../../shared/models/validation-list.model';
import { CoveragesValidationService } from '../submission/validations/coverages-validation.service';
import { PropertyValidationService } from '../submission/validations/property-validation.service';
import { ApplicantValidationService } from '../submission/validations/applicant-validation.service';
import { UwQuestionsValidationService } from '../submission/validations/uw-questions-validation.service';
import { InterestValidationService } from '../submission/validations/interest-validation.service';
import { BindAndIssueValidationService } from 'app/core/services/submission/validations/bind-and-issue-validation.service';
import { EndorsementsValidationService } from '../submission/validations/endorsements-validation.service';
import { SubmissionPageData } from '../../../modules/submission-management/data/submission-page.data';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { ApplicantData } from '../../../modules/submission-management/data/applicant.data';
import { QuickQuoteValidationService } from '../submission/validations/quick-quote-validation.service';
import { enableBindAndIssue } from 'app/modules/submission-management/submission-management-navitems';
import { AuthService } from 'app/core/services/auth.service';

@Injectable()
export class SubmissionNavValidationService extends BaseComponent {
  genericConstants = GenericConstants;
  effectiveDate: Date;
  userType: string;
  uwApprovalListChangesOnNav: boolean = false;


  validationList = new ValidationListModel();
  constructor(
    protected coveragesValidationService: CoveragesValidationService,
    protected propertyValidationService: PropertyValidationService,
    protected applicantValidationService: ApplicantValidationService,
    protected uwQuestionsValidationService: UwQuestionsValidationService,
    protected interestValidationService: InterestValidationService,
    protected bindAndIssueValidationService: BindAndIssueValidationService,
    protected endorsementsValidationService: EndorsementsValidationService,
    protected quickQuoteValidationService: QuickQuoteValidationService,
    public submissionPageData: SubmissionPageData,
    public applicantData: ApplicantData,
    protected authService: AuthService
  ) {
    super();
  }

  get coveragesValidStatus() {
    return this.coveragesValidationService.coveragesSection &&
      this.coveragesValidationService.discountsSection &&
      this.coveragesValidationService.floodSection;
  }

  get propertyValidStatus() {
    return this.propertyValidationService.locationDetailsSection &&
      this.propertyValidationService.propertyDetailsSection &&
      this.propertyValidationService.windMitigationSection;
  }

  get applicantValidStatus() {
    return this.applicantValidationService.applicantSection &&
      this.applicantValidationService.contactSection;
  }

  get coApplicantValidStatus() {
    return this.applicantValidationService.coApplicantSection;
  }

  get uwQuestionsValidStatus() {
    return this.uwQuestionsValidationService.eligibilitySection &&
      this.uwQuestionsValidationService.generalQuestionSection;
  }

  get interestValidStatus() {
    return this.interestValidationService.priorInsuranceSection;
  }

  get bindAndIssueValidStatus() {
    return this.bindAndIssueValidationService.billingSection &&
      this.bindAndIssueValidationService.signatureSection &&
      this.bindAndIssueValidationService.paperlessDocumentSection;
  }

  get endorsementsValidStatus() {
    return this.endorsementsValidationService.endorsementsSection;
  }

  get quickQuoteEffectiveDAteValidStatus() {
    return this.quickQuoteValidationService.isEffectiveDateValid;
  }

  get isValidForApplication(): boolean {
    if (this.submissionPageData.quoteStatus.value === this.genericConstants.quoteStatus.quo) {
      return this.coveragesValidStatus &&
        this.endorsementsValidStatus &&
        this.submissionPageData.outsideQuotedTabs;
    }

    if (this.submissionPageData.quoteStatus.value !== this.genericConstants.quoteStatus.app) {
      return this.coveragesValidStatus &&
        this.endorsementsValidStatus &&
        this.submissionPageData.outsideQuotedTabs &&
        this.uwApprovalListChangesOnNav;
    }
  }

  public validateCurrentCategory(currentCategoryRoute: string): void {
    try {
      switch (currentCategoryRoute.toLowerCase()) {
        case PathConstants.QuickQuote.QuickQuote.Index:
          if (this.applicantValidationService.checkResetFcraAcknowledgement()) {
            this.applicantValidationService.checkApplicantPage();
          }
          this.quickQuoteValidationService.isQuickQuoteEffectivaDateValid();
          this.validationList.quickQuote =  this.quickQuoteEffectiveDAteValidStatus;
          break;
        case PathConstants.Submission.Submission.CoveragesAndDeduction:
          this.coveragesValidationService.checkCoveragesPage();
          this.validationList.coverages = this.coveragesValidStatus;
          this.submissionPageData.covaragesValid = this.coveragesValidStatus;
          this.submissionPageData.isValidForApplication = this.isValidForApplication;
          break;
        case PathConstants.Submission.Submission.Endorsements:
          this.endorsementsValidationService.checkEndorsementsPage();
          this.validationList.endorsements = this.endorsementsValidStatus;
          break;
        case PathConstants.Submission.Submission.Claims:
          // to do
          break;
        case PathConstants.Submission.Submission.Property:
          this.propertyValidationService.checkPropertyPage();
          this.validationList.property = this.propertyValidStatus;
          this.submissionPageData.propertyValid = this.propertyValidStatus;
          break;
        case PathConstants.Applicant.Applicant.ApplicantPage:
          this.applicantValidationService.checkApplicantPage();
          this.applicantValidationService.checkCoApplicantSection();
          this.validationList.applicant = this.applicantValidStatus && this.coApplicantValidStatus;
          this.submissionPageData.applicantValid = this.applicantValidStatus && this.coApplicantValidStatus;
          break;
        case PathConstants.Submission.Submission.UWQuestions:
          this.uwQuestionsValidationService.checkUWQuestionsPage();
          this.validationList.uwQuestions = this.uwQuestionsValidStatus;
          this.submissionPageData.uwQuestionsValid = this.uwQuestionsValidStatus;
          break;
        case PathConstants.Submission.Submission.Interests:
          this.interestValidationService.checkInterestPage();
          this.validationList.interest = this.interestValidStatus;
          break;
        case PathConstants.Submission.Submission.UWApproval:
          this.validationList.uwApproval = this.checkUwApprovalValidity();
          break;
        case PathConstants.Submission.Submission.BindAndIssue:
          if (!this.isBindAndIssueDisabled) {
            this.bindAndIssueValidationService.checkBindAndIssuePage();
            this.validationList.bindAndIssue = this.bindAndIssueValidStatus;
          } else {
            this.validationList.bindAndIssue = true;
          }
          break;
        default:
          return;
      }
    } catch (e) { }
  }

  hasInvalid(): boolean {
    this.validateCategories();

    return !(
      (this.validationList.coverages || this.validationList.coverages === undefined) &&
      (this.validationList.property  || this.validationList.property === undefined) &&
      (this.validationList.applicant  || this.validationList.applicant === undefined) &&
      (this.validationList.uwQuestions || this.validationList.uwQuestions === undefined) &&
      (this.validationList.interest || this.validationList.interest === undefined) &&
      (this.validationList.bindAndIssue || this.validationList.bindAndIssue === undefined) &&
      (this.validationList.endorsements || this.validationList.endorsements === undefined) &&
      (this.validationList.quickQuote || this.validationList.quickQuote === undefined)
    );
  }

  hasInvalidQuote(): boolean {
    this.validateCategories();

    return !(
      (this.validationList.coverages || this.validationList.coverages === undefined) &&
      (this.validationList.property  || this.validationList.property === undefined) &&
      (this.validationList.applicant  || this.validationList.applicant === undefined) &&
      (this.validationList.uwQuestions || this.validationList.uwQuestions === undefined) &&
      (this.validationList.interest || this.validationList.interest === undefined) &&
      (this.validationList.endorsements || this.validationList.endorsements === undefined)
    );
  }

  validateCategories(): void {
    this.validateCurrentCategory(PathConstants.Submission.Submission.CoveragesAndDeduction);
    this.validateCurrentCategory(PathConstants.Submission.Submission.Property);
    this.validateCurrentCategory(PathConstants.Submission.Submission.ApplicantPage);
    this.validateCurrentCategory(PathConstants.Submission.Submission.UWQuestions);
    this.validateCurrentCategory(PathConstants.Submission.Submission.Interests);
    this.validateCurrentCategory(PathConstants.Submission.Submission.UWApproval);
    this.validateCurrentCategory(PathConstants.Submission.Submission.BindAndIssue);
    this.validateCurrentCategory(PathConstants.Submission.Submission.Endorsements);
    this.validateCurrentCategory(PathConstants.QuickQuote.QuickQuote.Index);
  }

  get isBindAndIssueDisabled(): boolean {
    return !enableBindAndIssue({
      enablePage: !this.submissionPageData.hasInvalidQuote.value,
      quoteStatus: this.submissionPageData.quoteStatus.value,
      allUwrApproved: this.submissionPageData.isAllUWApprovalApproved.value,
      hasAir: this.submissionPageData.hasAir,
      hasDecline: this.submissionPageData.hasDecline,
      uwrListEmpty: this.submissionPageData.uwrListEmpty,
      uwrListAllPostBind: this.submissionPageData.uwrListAllPostBind,
      uwrListAllNonPostBind: this.submissionPageData.uwrListAllNonPostBind,
      postBindUwrItemsChecked: this.submissionPageData.postBindUwrItemsChecked,
      postBindUwrItemsApproved: this.submissionPageData.postBindUwrItemsApproved,
      nonPostBindUwrItemsChecked: this.submissionPageData.nonPostBindUwrItemsChecked,
      nonPostBindUwrItemsApproved: this.submissionPageData.nonPostBindUwrItemsApproved,
      uwApprovalListAddedAllPostBind: this.submissionPageData.uwApprovalListAddedAllPostBind,
      hasPostBindUwrItem: this.submissionPageData.hasPostBindUwrItem
    });
  }

  checkUwApprovalValidity(): boolean {
    if (this.authService.userType.value === this.genericConstants.userType.external) {
      return false;
    }

    if (this.submissionPageData.uwrListEmpty) {
      return true;
    }

    if (this.submissionPageData.uwrListAllPostBind) {
      return true;
    }

    return this.submissionPageData.nonPostBindUwrItemsChecked;
  }
}
