
import { AllRiskBindNoteDTO } from "app/shared/models/data/dto/policy-management/all-risk-bind-note.dto";

export interface PolicyNotesState {
  isLoading: boolean | null;
  list: AllRiskBindNoteDTO[] | null;
}
export const initialState = {
  isLoading: null,
  list: null
}