export class ApplicantAddressDTO implements IApplicantAddressDTO {
    streetAddress1?: string | undefined;
    streetAddress2?: string | undefined;
    county?: string | undefined;
    zipCode?: number | undefined;
    city?: string | undefined;
    state?: string | undefined;
    countryCode?: string | undefined;
    constructor(data?: IApplicantAddressDTO) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }
}

export interface IApplicantAddressDTO {
    streetAddress1?: string | undefined;
    streetAddress2?: string | undefined;
    county?: string | undefined;
    zipCode?: number | undefined;
    city?: string | undefined;
    state?: string | undefined;
    countryCode?: string | undefined;
}
