import { Component, OnInit } from '@angular/core';
import { TaskService } from 'app/core/services/task/task.service';
import { BaseClass } from 'app/shared/base-class';
import { TaskData } from 'app/modules/dashboard/data/task.data';
import { TaskLabelsConstants } from 'app/shared/constants/task.labels.constants';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs/operators';
import Utils from 'app/shared/utilities/utils';
import { ITaskPolicySearchRequestDTO, ITaskPolicySearchResultDTO } from 'app/shared/models/data/dto/task/task.dto';
import { PageChangedEvent } from 'ngx-bootstrap';

@Component({
  selector: 'app-task-search-modal',
  templateUrl: './task-search-modal.component.html',
  styleUrls: ['./task-search-modal.component.scss']
})
export class TaskSearchModalComponent extends BaseClass implements OnInit {

  title: string;
  public searchPolicyLabels = TaskLabelsConstants;


  constructor(
      public  bsModalRef: BsModalRef,
      public taskData: TaskData,
      public taskService: TaskService
     ) {
    super();
    }

  ngOnInit() {
    this.taskData.isSearchModalOpen = true;
  }

  hideMyModal(): void {
    this.taskData.isSearchModalOpen = false;
    this.bsModalRef.hide();
  }

  selectPolicy(data: ITaskPolicySearchResultDTO): void {
    this.taskData.taskSelectPolicy(data);
    this.taskData.isSearchModalOpen = false;
    this.bsModalRef.hide();
  }

  setPage(page: number) {
    this.taskData.setTaskSearchList(page);
  }

  pageChanged(event: PageChangedEvent): void {
    this.taskData.searchCurrentPage = event.page;

    this.taskData.searchPagination(this.taskData.searchCurrentPage, this.taskData.searchItemPerPage);
  }

}
