import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PolicyNotesComponent } from './policy-notes.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { PolicyNotesModalComponent } from './policy-notes-modal/policy-notes-modal.component';

const routes: Routes = [
  { path: '', component: PolicyNotesComponent}
];

@NgModule({
  declarations: [
    PolicyNotesComponent,
    PolicyNotesModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
  ],
  entryComponents:[PolicyNotesModalComponent],
  exports: [PolicyNotesComponent]
})
export class PolicyNotesModule { }
