import { Component, OnInit } from '@angular/core';
import { StatusCounter } from '../../status-counter/status-counter';

@Component({
  selector: 'app-announcements-section',
  templateUrl: './announcements-section.component.html',
  styleUrls: ['./announcements-section.component.scss']
})
export class AnnouncementsSectionComponent implements OnInit {

  submissionCounterItems: StatusCounter[];

  constructor() { }

  ngOnInit(): void {
    this.submissionCounterItems = [
      { description: 'Moratorium Info', icon: 'cil-double-quote-sans-left', url: '/submissions/list' },
      { description: 'Others', icon: 'cil-double-quote-sans-left', url: '/submissions/list?status=underwriting' },
      //   { description: 'Update Mortgagee', icon: 'cil-double-quote-sans-left', url: '/submissions/list?status=quoted' },
      // { description: 'Voided", count: 27, icon: 'cil-ban", url: '/submissions/list?status=quoted' },
      // { description: 'Info Needed', count: 27, icon: "cil-info", url: '/submissions/list?status=quoted' },
    ];
  }


}
