import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QuickQuoteModel } from '../../../shared/models/submission/quick-quote/quick-quote.model';
import { environment } from '../../../../environments/environment';
import { EntityQuickQuoteDTO } from 'app/shared/models/data/dto/quick-quote/entity-quick-quote.dto';
import { param } from 'jquery';
import { SaveCoveragesDTO } from 'app/shared/models/data/dto/quick-quote/save-coverages.dto';
import { SaveEndorsementDTO } from 'app/shared/models/data/dto/quick-quote/save-endorsement.dto';
import { SaveClaimsDTO } from 'app/shared/models/data/dto/quick-quote/save-claims.dto';
import { SavePropertyDTO } from 'app/shared/models/data/dto/quick-quote/save-property.dto';
import { SaveApplicantDTO } from 'app/shared/models/data/dto/quick-quote/save-applicant-dto';
import { SaveUwQuestionDTO } from 'app/shared/models/data/dto/quick-quote/save-uw-question.dto';
import { SaveInterestDTO } from 'app/shared/models/data/dto/quick-quote/save-interest.dto';
import { SaveBindNotesDTO } from 'app/shared/models/data/dto/quick-quote/save-bind-and-issue.dto';
import { CommonService } from '../common.service';
import { UpdateRenewalPayPlanRequestDTO } from '../../../shared/models/data/dto/billing/update-renewal-payplan-request.dto';
import { UpdateRenewalPayPlanResponseDTO } from '../../../shared/models/data/dto/billing/update-renewal-payplan-response.dto';
import { RiskBindDTO } from 'app/shared/models/data/dto/quick-quote/risk-bind-dto';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RiskClaimDTO } from '../../../shared/models/data/dto/third-party/risk-claims.dto';

@Injectable({
  providedIn: 'root'
})
export class QuickQuoteService {
  baseUrl: string;

  isQuickQuoteSaving: boolean = false;
  isCoveragesSaving: boolean = false;
  isEndorsementsSaving: boolean = false;
  isClaimsSaving: boolean = false;
  isPropertySaving: boolean = false;
  isApplicantSaving: boolean = false;
  isNotesSaving: boolean = false;

  constructor(protected http: HttpClient, protected commonService: CommonService) {
    // this.baseUrl = environment.ApiUrl; TODO: Uncomment
    this.baseUrl = environment.ApiUrl;
  }

  setCategoryToSavingState(category): void {
    this[category] = true;
  }

  setCategoryToNotSavingState(category): void {
    this[category] = false;
  }

  checkIfAllCategoriesDoneSaving(): boolean {
    return !this.isQuickQuoteSaving
      && !this.isCoveragesSaving
      && !this.isEndorsementsSaving
      && !this.isClaimsSaving
      && !this.isPropertySaving
      && !this.isApplicantSaving
      && !this.isNotesSaving;
  }

  postQuickQuote(quickQuoteModel: EntityQuickQuoteDTO) {
    const url = `${this.baseUrl}/Submission/quick-quote`;
    return this.post(url, quickQuoteModel);
  }

  updateQuickQuote(quickQuoteModel: EntityQuickQuoteDTO) {
    const url = `${this.baseUrl}/Submission/quick-quote`;
    return this.put(url, quickQuoteModel);
  }

  postCoverages(model: SaveCoveragesDTO) {
    const url = `${this.baseUrl}/Submission/coverages`;
    return this.post(url, model);
  }

  postEndorsements(model: SaveEndorsementDTO) {
    const url = `${this.baseUrl}/Submission/endorsements`;
    return this.post(url, model);
  }

  postClaims(model: SaveClaimsDTO) {
    const url = `${this.baseUrl}/Submission/claims`;
    return this.post(url, model);
  }

  postProperty(model: SavePropertyDTO) {
    const url = `${this.baseUrl}/Submission/property`;
    return this.post(url, model);
  }

  postApplicant(model: SaveApplicantDTO) {
    const url = `${this.baseUrl}/Submission/applicant`;
    return this.post(url, model);
  }

  postUWQuestions(model: SaveUwQuestionDTO) {
    const url = `${this.baseUrl}/Submission/uwquestions`;
    return this.post(url, model);
  }

  postInterests(model: SaveInterestDTO) {
    const url = `${this.baseUrl}/Submission/interests`;
    return this.post(url, model);
  }

  postUWApproval(model: any) {
    const url = `${this.baseUrl}/Submission/uwapproval`;
    return this.post(url, model);
  }

  referToUw(riskId: any, riskDetailId: string) {
    const url = `${this.baseUrl}/Risk/status/refertouw?riskId=${riskId}&riskDetailId=${riskDetailId}`;
    return this.put(url);
  }

  postBindAndIssue(model: any) {
    const url = `${this.baseUrl}/Submission/issue`;
    return this.post(url, model);
  }

  postBindDocument(model: any) {
    const url = `${this.baseUrl}/Submission/binddocument`;
    return this.post(url, model);
  }

  postBindNotes(model: SaveBindNotesDTO) {
    const url = `${this.baseUrl}/Submission/bindnotes`;
    return this.post(url, model);
  }

  deleteBindDocument(model: any) {
    const url = `${this.baseUrl}/Submission/binddocument?riskId=${model.riskId}&riskDetailId=${model.riskDetailId}&riskBindDocumentId=${model.riskBindDocumentId}`;
    return this.delete(url);
  }

  post(url, params?, options?) {
    return this.http.post(url, params, options)
      .catch(this.commonService.handleObservableHttpError);
  }

  delete(url, params?) {
    return this.http.delete(url, params);
  }

  put(url, params?, options?) {
    return this.http.put(url, params, options);
  }

  updateRenewalPayPlan(updateRenewalPayPlanPayload?: UpdateRenewalPayPlanRequestDTO): Observable<UpdateRenewalPayPlanResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/Submission/update-renewal-payplan`, updateRenewalPayPlanPayload)
      .catch(this.commonService.handleObservableHttpError);
  }

  postComparative(quickQuoteModel: EntityQuickQuoteDTO) {
    const url = `${this.baseUrl}/Submission/comparative`;
    return this.post(url, quickQuoteModel);
  }

  updateComparative(quickQuoteModel: EntityQuickQuoteDTO) {
    const url = `${this.baseUrl}/Submission/comparative`;
    return this.put(url, quickQuoteModel);
  }

  getRiskBindDocuments(riskId: string, riskDetailId: string): Observable<RiskBindDTO> {
    return this.http.get(`${environment.ApiUrl}/Risk/binddocuments?riskId=${riskId}&riskDetailId=${riskDetailId}`).pipe(
      map(data => data as RiskBindDTO)
    ).catch(this.commonService.handleObservableHttpError);
  }
  
  getClaimsData(riskId: string, riskDetailId: string): Observable<RiskClaimDTO[]> {
    const url = `${environment.ApiUrl}/Risk/claims?riskId=${riskId}&riskDetailId=${riskDetailId}`;

    return this.http.get(url)
      .pipe(catchError(this.commonService.handleObservableHttpError)
      );
  }
}
