import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import {
  ITaskDashboardResultDTO,
  ITaskDocumentsDTO,
  ITaskDTO,
  ITaskHistoryDTO,
  ITaskListRequestDTO,
  ITaskListResultDTO,
  ITaskNotesDTO,
  ITaskPolicySearchRequestDTO,
  ITaskPolicySearchResultDTO,
  TaskDocumentsDTO,
  TaskDTO,
  TaskListResultDTO,
  TaskNotesDTO,
  TaskPostRequestDTO
} from '../../../shared/models/data/dto/task/task.dto';
import { BaseClass } from '../../../shared/base-class';
import { TaskService } from '../../../core/services/task/task.service';
import { takeUntil } from 'rxjs/operators';
import Utils from '../../../shared/utilities/utils';
import {
  LvOwner,
  LvTaskAction,
  LvTaskCategory,
  LvTaskContactType,
  LvTaskDescription,
  LvTaskDocStatus,
  LvTaskDocType,
  LvTaskNoteContactType,
  LVTaskPolicyStatus,
  LvTaskPriority,
  LvTaskStatus,
  LvTaskSubType,
  LvTaskType
} from '../../../shared/constants/task.options.constants';
import { PagerService } from '../../../core/services/pagerService';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { AuthService } from '../../../core/services/auth.service';
import { UserService } from '../../../core/services/management/user.service';
import { TaskLabelsConstants } from '../../../shared/constants/task.labels.constants';
import { ITableFormControl, ITableTd, ITableTh, ITableTr } from '../../../shared/models/dynamic/table.interface';
import { UwDashboardConstants } from '../../../shared/constants/uw-dashboard.constants';
import { Subject, Subscription } from 'rxjs';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { EntityRiskData } from '../../submission-management/data/entity-risk.data';
import { AddressType } from '../../../shared/enum/address-type.enum';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class TaskData extends BaseClass {

  isAddTask: boolean = true;
  isEndorsement: boolean = true;
  isDisabledForm: boolean = true;

  taskDetailsForm: FormGroup;
  policyForm: FormGroup;
  notesForm: FormGroup;
  uwTaskFilterForm: FormGroup;
  agentTaskFilterForm: FormGroup;
  // Use this varialbe to get what usertype is logged in.
  isExternal: boolean;
  isSearchModalOpen: boolean;

  itemPerPage: number = 8;
  currentPage: number = 1;
  totalRowCount: number = 500;
  sortBy: string = 'DueDate';
  orderBy: string = 'ASC';

  policyItemPerPage: number = 10;
  policyCurrentPage: number = 1;
  policyTotalRowCount: number = 0;
  policyTotalPage: number = 0;

  searchItemPerPage: number = 10;
  searchCurrentPage: number = 1;
  searchTotalRowCount: number = 500;

  uwOnFirstLoad: boolean = true;
  agentOnFirstLoad: boolean = true;

  taskListLoading: boolean = false;
  policyTaskLoading: boolean = false;

  currentRiskId: string;
  currentRiskDetailId: string;
  currentTaskId: string;
  currentEntityId: string;
  currentStateCode: string = null;
  currentPolicyNumber: string = null;
  currentPropertyAddress: string = null;
  currentQuoteNumber: string = null;
  currentInsuredName: string = null;
  currentProcessDate: Date = null;

  currentCategory: string = null;
  currentTaskDescription: string = null;
  currentOtherDescription: string = null;
  currentDueDate: Date = null;
  currentOwner: string = null;
  currentOwnerName: string = null;

  currentUserName: string = null;
  currentUserId: number;

  taskOwnerData: any;

  hasNoteChanges: boolean = false;
  hasDocumentChanges: boolean = false;

  policyData: ITaskPolicySearchResultDTO[] = [];
  taskDocuments: TaskDocumentsDTO[] = [];
  taskList: ITaskListResultDTO[] = [];
  taskHistoryList: ITaskHistoryDTO[] = [];
  taskNotes: TaskNotesDTO[] = [];
  taskListPagedItems: ITaskListResultDTO[] = [];
  taskSearchListPagedItems: ITaskPolicySearchResultDTO[] = [];
  taskNotesListPagedItems: TaskNotesDTO[] = [];
  taskDocumentsListPagedItems: TaskDocumentsDTO[] = [];
  taskHistoryListPagedItems: ITaskHistoryDTO[] = [];
  searchBy: string = 'PolicyNumber';
  taskListPager: any = {};
  taskSearchListPager: any = {};
  taskNotesListPager: any = {};
  taskDocumentsListPager: any = {};
  taskHistoryListPager: any = {};
  ownerNameList = [];

  LVTaskType = LvTaskType;
  LVTaskSubType = LvTaskSubType;
  LVTaskStatus = LvTaskStatus;
  LVTaskContactType = LvTaskContactType;
  LVTaskCategory = LvTaskCategory;
  LVTaskDescription = LvTaskDescription;
  LVTaskPriority = LvTaskPriority;
  LVOwner = LvOwner;
  LvTaskDocType = LvTaskDocType;
  LvTaskDocStatus = LvTaskDocStatus;
  LvTaskNoteContactType = LvTaskNoteContactType;
  LVTaskAction = LvTaskAction;
  LVTaskPolicyStatus = LVTaskPolicyStatus;
  dateFormat: string = 'MMMM d, y';
  tableDateFormat: string = 'MM/dd/yyyy';

  TaskChange: any = {
    task: 'TA',
    typeCode: 'TC',
    taskSubTypeCode: 'STC',
    taskContactTypeCode: 'CTC',
    contactFullName: 'CNC',
    taskCategoryCode: 'CC',
    dueDate: 'DDC',
    priority: 'PRC',
    taskOwnerCode: 'OC',
    taskStatusCode: 'SC',
    policy: 'PC'
  };

  policyFilter: any[] = [
    {
      code: 'PolicyNumber',
      description: 'Policy Number'
    },
    {
      code: 'InsuredName',
      description: 'Insured Name'
    },
    {
      code: 'Propery',
      description: 'Property'
    }
  ];
  genericConstants: any;

  filterDateFrom: string;
  filterDateTo: string;

  //#region Task Page - Policy UI
  tableHeaders: ITableTh[] = [
    { value: UwDashboardConstants.tasksDateAdded },
    { value: UwDashboardConstants.tasksOwner },
    { value: UwDashboardConstants.tasksCategory },
    { value: UwDashboardConstants.tasksDueDate },
    { value: UwDashboardConstants.tasksTaskStatus },
    { value: UwDashboardConstants.tasksDescription }
  ];

  tableFormControls: ITableFormControl[] = [
    {
      name: 'field_1',
      type: 'string',
    }
  ];

  tasksTableRows: ITableTr[] = [];
  taskDetailNotifier = new Subject<{ data: ITaskDTO; isComplete: boolean; hasError: boolean }>();
  isViewTask: boolean = false;
  taskDescriptionCodeList: string[] = [];
  taskDescriptionCodeAndStatusList: TaskListResultDTO[] = [];

  taskSubscription: Subscription;

  currentPageInfo: any = 1;

  //#endregion

  constructor(
    protected fb: FormBuilder,
    public taskService: TaskService,
    protected pagerService: PagerService,
    protected datePipe: DatePipe,
    public authService: AuthService,
    public userService: UserService,
    protected entityRiskData: EntityRiskData
  ) {
    super();
    this.setTaskNotesList(1);
    this.setTaskDocumentsList(1);
    this.setTaskHistoryList(1);
  }

  get CurrentTaskId(): string {
    return this.currentTaskId;
  }

  initializeForm(): void {
    if (this.isExternal) {
      this.taskDetailsForm = this.fb.group({
        category: new FormControl({ value: '', disabled: true }, [Validators.required]),
        taskDescription: new FormControl({ value: '', disabled: true }, [Validators.required]),
        otherDescription: new FormControl({ value: '', disabled: true }),
        dueDate: new FormControl({ value: '', disabled: true }, Validators.required),
        owner: new FormControl({ value: '', disabled: true }, [Validators.required]),
        status: new FormControl('', [Validators.required]),
      });
    } else {
      this.taskDetailsForm = this.fb.group({
        type: new FormControl('', [Validators.required]),
        subType: new FormControl('', [Validators.required]),
        contactType: new FormControl('', [Validators.required]),
        contactName: new FormControl(''),
        category: new FormControl('', [Validators.required]),
        taskDescription: new FormControl('', [Validators.required]),
        otherDescription: new FormControl(''),
        dueDate: new FormControl(null, Validators.required),
        owner: new FormControl('', [Validators.required]),
        status: new FormControl('OP', [Validators.required]),
      });
      this.policyForm = this.fb.group({
        searchInput: new FormControl(''),
      });
    }
  }

  initializeUWTaskFilterForm(): void {
    this.uwTaskFilterForm = this.fb.group({
      myTask: new FormControl(false),
      owner: new FormControl(''),
      status: new FormControl(''),
      dueDate: new FormControl(''),
      dueDateFrom: new FormControl(''),
      dueDateTo: new FormControl(''),
      priority: new FormControl(''),
      category: new FormControl(''),
      search: new FormControl(''),
      sortField: new FormControl('DueDate'),
      description: new FormControl(''),
      stateCode: new FormControl(''),
    });
  }

  initializeAgentTaskFilterForm(): void {
    this.agentTaskFilterForm = this.fb.group({
      myTask: new FormControl(''),
      owner: new FormControl(''),
      due: new FormControl(''),
      search: new FormControl(''),
    });
  }

  initializeNoteForm(): void {
    this.notesForm = this.fb.group({
      createdDate: new FormControl(''),
      // TODO: Get this user in user management
      createdBy: new FormControl(this.currentUserName),
      taskContactTypeCode: new FormControl('', [Validators.required]),
      contactFullName: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
      description: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
    });
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  taskSelectPolicy(data: ITaskPolicySearchResultDTO): void {
    const result = this.policyData.find(a => a.riskId === data.riskId);
    if (data) {
      this.currentRiskId = data.riskId;
      this.currentRiskDetailId = data.riskDetailId;
      this.isEndorsement = data.isEndorsement;
      this.currentEntityId = data.entityId;
      this.currentStateCode = data.stateCode;
      this.currentPolicyNumber = data.policyNumber;
      this.currentPropertyAddress = data.property;
      this.currentQuoteNumber = data.quoteNumber;
      this.currentInsuredName = data.insuredName;
      this.currentProcessDate = data.riskProcessDate;
      this.enabledDisabledControls();
    }
  }

  getTaskPayload(isAddTask: boolean = false, data: ITaskDTO): TaskPostRequestDTO {
    const payload: TaskPostRequestDTO = {
      taskId: this.currentTaskId,
      task: this.getTaskDetailFormValue(isAddTask, data)
    };
    return payload;
  }

  collectTaskNotes(): TaskNotesDTO[] {
    return this.taskNotes;
  }

  collectTaskDocuments(): TaskDocumentsDTO[] {
    return this.taskDocuments;
  }

  getTaskDetailFormValue(isAddTask: boolean = false, data: ITaskDTO): TaskDTO {

    const dueDate = this.taskDetailsForm.get('dueDate')?.value?.singleDate?.formatted ||
      new Date(this.taskDetailsForm.get('dueDate')?.value.singleDate?.jsDate).toLocaleDateString('en-US');
    let task = new TaskDTO();
    if (this.isExternal) {
      task = {
        id: this.currentTaskId,
        policyNumber: this.currentPolicyNumber,
        quoteNumber: this.currentQuoteNumber,
        entityId: this.currentEntityId,
        taskStatusCode: this.taskDetailsForm.get('status')?.value,
        taskNotes: this.collectTaskNotes(),
        taskDocuments: this.collectTaskDocuments(),

        typeCode: data.typeCode,
        taskSubTypeCode: data.taskSubTypeCode,
        taskContactTypeCode: data.taskContactTypeCode,
        taskCategoryCode: data.taskCategoryCode,
        taskDescriptionCode: data.taskDescriptionCode,
        taskOwnerCode: data.taskOwnerCode,
        contactFullName: data.contactFullName,
        dueDate: data.dueDate,
        otherDescription: data.otherDescription,
      };
    } else {
      let taskOwnerCode: string;
      let taskOwnerUserId: number;

      if (this.taskOwnerData.userType === 'Internal') {
        taskOwnerCode = 'PUW';
        taskOwnerUserId = this.taskOwnerData.code;
      } else {
        taskOwnerCode = this.taskOwnerData.code;
        taskOwnerUserId = null;
      }

      task = {
        id: this.currentTaskId,
        policyNumber: this.currentPolicyNumber,
        quoteNumber: this.currentQuoteNumber,
        entityId: this.currentEntityId,
        typeCode: this.taskDetailsForm.get('type')?.value,
        taskSubTypeCode: this.taskDetailsForm.get('subType')?.value,
        taskContactTypeCode: this.taskDetailsForm.get('contactType')?.value,
        taskCategoryCode: this.taskDetailsForm.get('category')?.value,
        taskDescriptionCode: this.taskDetailsForm.get('taskDescription')?.value,
        taskOwnerCode: taskOwnerCode,
        taskStatusCode: this.taskDetailsForm.get('status')?.value,
        contactFullName: this.taskDetailsForm.get('contactName')?.value,
        dueDate: dueDate,
        otherDescription: this.taskDetailsForm.get('otherDescription')?.value,
        taskNotes: this.collectTaskNotes(),
        taskDocuments: this.collectTaskDocuments(),
        taskOwnerUserId: taskOwnerUserId
      };
    }
    return task;
  }

  generateTaskId(): void {
    Utils.blockUI();
    this.taskService.generateTaskId().pipe(takeUntil(this.stop$)).subscribe(result => {
      this.currentTaskId = result;
      Utils.unblockUI();
    }, (error) => {
      Utils.unblockUI();
    });
  }

  getUserInformation(): void {
    this.currentUserId = this.authService.getUserId();
    Utils.blockUI();
    this.userService.getUserInfoById(this.currentUserId).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.currentUserName = result?.fullName;
      Utils.unblockUI();
    }, (error) => {
      Utils.unblockUI();
    });
  }

  get isValidForm(): boolean {
    return !(this.currentQuoteNumber && this.currentTaskId && this.taskDetailsForm.valid);
  }

  getUWTaskFilterFormPayload(dueDate: string = null, pageNumber: number = 1, pageSize: number = this.itemPerPage, sortBy: string = this.sortBy, orderBy: string = this.orderBy): ITaskListRequestDTO {
    const agentId = this.authService.isExternalUser ? this.authService.agentUserInfo?.id : undefined;
    const payload: ITaskListRequestDTO = {
      isMyTask: this.uwTaskFilterForm.get('myTask')?.value,
      status: this.uwTaskFilterForm.get('status')?.value,
      description: this.uwTaskFilterForm.get('description')?.value,
      stateCode: this.uwTaskFilterForm.get('stateCode')?.value,
      search: this.uwTaskFilterForm.get('search')?.value,
      owner: this.uwTaskFilterForm.get('owner')?.value,
      dueDate: dueDate || this.uwTaskFilterForm.get('dueDate')?.value?.dateRange?.formatted || '',
      category: this.uwTaskFilterForm.get('category')?.value,
      dueDateFrom: this.filterDateFrom,
      dueDateTo: this.filterDateTo,
      pageNumber: pageNumber,
      pageSize: pageSize,
      sortField: sortBy,
      order: orderBy,
      agentId: agentId
    };
    return payload;
  }

  getAgentTaskFilterFormPayload(pageNumber: number = 1, pageSize: number = this.itemPerPage): ITaskListRequestDTO {
    const agentId = this.authService.isExternalUser ? this.authService.agentUserInfo?.id : undefined;
    const payload: ITaskListRequestDTO = {
      isMyTask: false,
      status: '',
      owner: '',
      dueDate: null,
      search: this.agentTaskFilterForm.get('search')?.value,
      pageNumber: pageNumber,
      pageSize: pageSize,
      agentId: agentId
    };
    return payload;
  }

  getTaskList(payload: ITaskListRequestDTO = {}): void {
    this.clearTaskListPageItems();
    this.taskList = [];

    if (this.taskSubscription) {
      this.taskSubscription.unsubscribe();
    }

    Utils.blockUI();
    this.taskListLoading = true;
    this.taskSubscription = this.taskService.getTaskList(payload).pipe(takeUntil(this.stop$)).subscribe(result => {

      const data = result.resultList;
      this.totalRowCount = result.paginationDetails.totalCount;
      this.currentPageInfo = `${result.paginationDetails.currentPage}`;

      this.taskList = _.uniqBy(data, 'taskId');
      this.taskList.forEach(x => {
        x.fullDescription = this.getTaskDescription(x.description);
        x.fullOwner = x.ownerProper;
        x.fullCategory = this.getTaskCategoryDescription(x.category);
        x.fullTaskStatus = this.getTaskStatusDescription(x.taskStatus);
        x.fullRiskStatus = this.getTaskPolicyStatusDescription(x.riskStatus);
      });

      this.taskListLoading = false;
      Utils.unblockUI();
      this.authService.isTaskLoadedAfterCopy$.next();
    }, (error) => {
      this.taskListLoading = false;
      Utils.unblockUI();
    });
  }

  getColor(value): string{
    let color: string = '';
    const inputDate = moment.utc(value.dueDate.toLocaleString()).format('MM/DD/YYYY');
    const getServerDatelocalStorage =  this.authService.getCustomDate();
    const serverDate = moment.utc(getServerDatelocalStorage).format('MM/DD/YYYY');
    if (Date.parse(inputDate) < Date.parse(serverDate) ) {
      color = 'text-highlight-ff0000';
    }else {
      color = 'text-light';
    }
    return color;
 }

  getUnderwriterAgentFullName(data: TaskListResultDTO): string {
    if (data.fullName !== '' && data.fullName !== null && (data.owner === 'PUW' || data.owner === 'PAG')) {
      return data.fullName;
    } else {
      return this.getTaskOwnerDescription(data.owner);
    }
  }

  getOwnerFullName(owner: string, fullName: string): string {
    if (fullName !== null) {
      return fullName;
    } else {
      return this.getTaskOwnerDescription(owner);
    }
  }

  setOwnerFullName(data: ITaskDTO): void {
    if (data.taskOwnerCode === 'PUW' || data.taskOwnerCode === 'PAG') {
      if (data.taskOwnerUserId !== null && data.taskOwnerCode === 'PUW') {
        this.taskOwnerData = this.ownerNameList.find(x => x.code === data.taskOwnerUserId);
        if (this.taskOwnerData) {
          this.taskDetailsForm.get('owner').setValue(this.taskOwnerData?.name);
        } else {
          this.taskOwnerData = { code: data.taskOwnerUserId, name: data?.fullName, userType: TaskLabelsConstants.Internal };
          this.taskDetailsForm.get('owner').setValue(data?.fullName);
        }
      } else {
        this.taskOwnerData = this.ownerNameList.find(x => x.code === data.taskOwnerCode);
        this.taskDetailsForm.get('owner').setValue(this.taskOwnerData?.name);
      }
    } else {
      this.taskOwnerData = this.ownerNameList.find(x => x.code === data.taskOwnerCode);
      this.taskDetailsForm.get('owner').setValue(this.taskOwnerData?.name);
    }
    this.currentOwnerName = this.taskDetailsForm.get('owner')?.value;
  }

  getTaskTypeDescription(code: string): string {
    return this.LVTaskType.find(a => a.code === code)?.description;
  }

  getTaskSubTypeDescription(code: string): string {
    return this.LVTaskSubType.find(a => a.code === code)?.description;
  }

  getTaskStatusDescription(code: string): string {
    return this.LVTaskStatus.find(a => a.code === code)?.description;
  }

  getTaskContactTypeDescription(code: string): string {
    return this.LVTaskContactType.find(a => a.code === code)?.description;
  }

  getTaskCategoryDescription(code: string): string {
    return this.LVTaskCategory.find(a => a.code === code)?.description;
  }

  getTaskDescription(code: string): string {
    return this.LVTaskDescription.find(a => a.code === code)?.description;
  }

  getTaskPriorityDescription(code: string): string {
    return this.LVTaskPriority.find(a => a.code === code)?.description;
  }

  getTaskOwnerDescription(code: string): string {
    return this.LVOwner.find(a => a.code === code && a.isActive)?.description;
  }

  getTaskDocTypeDescription(code: string): string {
    return this.LvTaskDocType.find(a => a.code === code)?.description;
  }

  getTaskDocStatusDescription(code: string): string {
    return this.LvTaskDocStatus.find(a => a.code === code)?.description;
  }

  getTaskNoteContactType(code: string): string {
    return this.LvTaskNoteContactType.find(a => a.code === code)?.description;
  }

  getTaskActionDescription(code: string): string {
    return this.LVTaskAction.find(a => a.code === code)?.description;
  }

  getTaskPolicyStatusDescription(code: string): string {
    return this.LVTaskPolicyStatus.find(a => a.code === code)?.description;
  }

  getTaskHistoryDescription(changeTypeCode: string, changeCode: string, fullName: string): string {
    if (changeTypeCode === this.TaskChange.task) {
      return '';
    } else if (changeTypeCode === this.TaskChange.typeCode) {
      return this.getTaskTypeDescription(changeCode);
    } else if (changeTypeCode === this.TaskChange.taskSubTypeCode) {
      return this.getTaskSubTypeDescription(changeCode);
    } else if (changeTypeCode === this.TaskChange.taskContactTypeCode) {
      return this.getTaskContactTypeDescription(changeCode);
    } else if (changeTypeCode === this.TaskChange.contactFullName) {
      return changeCode;
    } else if (changeTypeCode === this.TaskChange.taskCategoryCode) {
      return this.getTaskCategoryDescription(changeCode);
    } else if (changeTypeCode === this.TaskChange.dueDate) {
      return changeCode;
    } else if (changeTypeCode === this.TaskChange.priority) {
      return this.getTaskPriorityDescription(changeCode);
    } else if (changeTypeCode === this.TaskChange.taskOwnerCode) {
      return this.getOwnerFullName(changeCode, fullName);
    } else if (changeTypeCode === this.TaskChange.taskStatusCode) {
      return this.getTaskStatusDescription(changeCode);
    } else if (changeTypeCode === this.TaskChange.policy) {
      return 'Policy Change';
    }
  }

  populateTaskModal(data: ITaskDTO): void {
    if (data) {
      Utils.blockUI();
      this.getTaskStateCodeOnEdit(data);
      this.populateTaskHistoryList(data.id);
      this.currentTaskId = data.id;
      this.isDisabledForm = false;
      // Populate Task Notes Section
      this.taskNotes = data.taskNotes;
      this.setTaskNotesList(1);

      // Populate Task Documents Section
      this.taskDocuments = data.taskDocuments;
      this.setTaskDocumentsList(1);

      if (this.isViewTask) {
        this.disableFormControls(this.taskDetailsForm);
      }
    }
  }

  mapTask(data: ITaskDTO): void {
    if (this.isExternal) {
      this.currentCategory = this.getTaskCategoryDescription(data.taskCategoryCode);
      this.currentTaskDescription = this.getTaskDescription(data.taskDescriptionCode);
      this.currentOtherDescription = data.otherDescription;
      this.currentDueDate = new Date(data.dueDate);
      this.currentOwner = this.getTaskOwnerDescription(data.taskOwnerCode);
      this.taskDetailsForm.get('category').setValue(this.currentCategory);
      this.taskDetailsForm.get('taskDescription').setValue(this.currentTaskDescription);
      this.taskDetailsForm.get('otherDescription').setValue(this.currentOtherDescription);
      this.taskDetailsForm.get('dueDate').setValue(this.datePipe.transform(this.currentDueDate, 'MM/dd/yyyy'));
      this.taskDetailsForm.get('owner').setValue(this.currentOwner);
      this.taskDetailsForm.get('status').setValue(data.taskStatusCode);
    } else {
      // Populate Task Detail Section
      this.taskDetailsForm.get('type').setValue(data.typeCode);
      this.taskDetailsForm.get('subType').setValue(data.taskSubTypeCode);
      this.taskDetailsForm.get('contactType').setValue(data.taskContactTypeCode);
      this.taskDetailsForm.get('contactName').setValue(data.contactFullName);
      this.taskDetailsForm.get('category').setValue(data.taskCategoryCode);
      this.taskDetailsForm.get('taskDescription').setValue(data.taskDescriptionCode);
      this.taskDetailsForm.get('otherDescription').setValue(data.otherDescription);
      this.taskDetailsForm.get('dueDate').setValue({ isRange: false, singleDate: { jsDate: new Date(data?.dueDate) } });
      this.setOwnerFullName(data);
      this.taskDetailsForm.get('status').setValue(data.taskStatusCode);
    }
  }

  getTaskStateCodeOnEdit(data: ITaskDTO): void {
    const request: ITaskPolicySearchRequestDTO = {
      searchValue: data.policyNumber || data.quoteNumber,
      searchBy: 'PolicyNumber',
      pageNumber: 1,
      pageSize: 10,
      entityId: data.entityId
    };

    Utils.blockUI();
    this.taskService.taskSearch(request).pipe(takeUntil(this.stop$)).subscribe(result => {
      const riskResult = _.find(result.resultList, x => {
        return (x.policyNumber === data.policyNumber || x.quoteNumber === data.quoteNumber || x.quoteNumberSiebel === data.quoteNumber);
      });
      this.currentRiskId = riskResult?.riskId;
      this.currentRiskDetailId = riskResult?.riskDetailId;
      this.isEndorsement = riskResult?.isEndorsement;
      this.currentEntityId = riskResult?.entityId;
      this.currentStateCode = riskResult?.stateCode;
      this.currentPolicyNumber = riskResult?.policyNumber;
      this.currentPropertyAddress = riskResult?.property;
      this.currentQuoteNumber = riskResult?.quoteNumber;
      this.currentInsuredName = riskResult?.insuredName;
      this.currentProcessDate = riskResult?.riskProcessDate;
      this.mapTask(data);
      Utils.unblockUI();
    }, (error) => {
      Utils.unblockUI();
    });
  }

  populateTaskHistoryList(taskId: string): void {
    this.taskService.getTaskHistory(taskId).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.taskHistoryList = result;
      this.setTaskHistoryList(1);
    }, (error) => {
      Utils.unblockUI();
    });
  }

  setTaskSearchList(page: number) {
    if (page < 1) {
      return;
    }
    this.taskSearchListPager = this.pagerService.getPager(this.policyData.length, page);
    this.taskSearchListPagedItems = this.policyData.slice(this.taskSearchListPager.startIndex, this.taskSearchListPager.endIndex + 1);
  }

  setTaskNotesList(page: number) {
    if (page < 1) {
      return;
    }

    this.taskNotesListPager = this.pagerService.getPager5Items(this.taskNotes.length, page);
    this.taskNotesListPagedItems = this.taskNotes.slice(this.taskNotesListPager.startIndex, this.taskNotesListPager.endIndex + 1);

  }

  setTaskDocumentsList(page: number) {
    if (page < 1) {
      return;
    }
    this.taskDocumentsListPager = this.pagerService.getPager5Items(this.taskDocuments.length, page);
    this.taskDocumentsListPagedItems = this.taskDocuments.slice(this.taskDocumentsListPager.startIndex, this.taskDocumentsListPager.endIndex + 1);
  }

  setTaskHistoryList(page: number) {
    if (page < 1) {
      return;
    }
    this.taskHistoryListPager = this.pagerService.getPager5Items(this.taskHistoryList.length, page);
    this.taskHistoryListPagedItems = this.taskHistoryList.slice(this.taskHistoryListPager.startIndex, this.taskHistoryListPager.endIndex + 1);
  }

  setTaskList(page: number) {
    if (page < 1) {
      return;
    }
    this.taskListPager = this.pagerService.getPager8Items(this.taskList.length, page);
    this.taskListPagedItems = this.taskList.slice(this.taskListPager.startIndex, this.taskListPager.endIndex + 1);
  }

  clearTaskListPageItems(): void {
    this.taskListPagedItems = [];
    this.taskListPager = {};
  }

  getInitialTaskListPayload(pageNumber: number = 1, pageSize: number = this.itemPerPage, sortBy: string = this.sortBy, orderBy: string = this.orderBy): ITaskListRequestDTO {
    const agentId = this.authService.isExternalUser ? this.authService.agentUserInfo?.id : undefined;
    const payload: ITaskListRequestDTO = {
      status: LvTaskStatus.find(a => a.description === 'Open').code,
      pageNumber: pageNumber,
      pageSize: pageSize,
      sortField: sortBy,
      order: orderBy,
      agentId: agentId
    };
    return payload;
  }

  //#region Task Page - Policy UI

  getTaskListByPolicy(isFromPolicyRequest: boolean = false, pageNumber: number = 1): void {
    this.taskDescriptionCodeList = [];
    this.taskDescriptionCodeAndStatusList = [];
    if (this.entityRiskData.policySummaryData.isPolicy) {
      Utils.blockUI();
      const policyNumber = this.entityRiskData.getPolicyNumber();
      const agentId = this.authService.isExternalUser ? this.authService.agentUserInfo?.id : undefined;
      const payload: ITaskListRequestDTO = {
        search: policyNumber,
        isFromPolicyRequest: isFromPolicyRequest,
        pageNumber: pageNumber,
        pageSize: this.policyItemPerPage,
        agentId: agentId
      };
      this.policyTaskLoading = true;
      this.taskService.getTaskList(payload).pipe(takeUntil(this.stop$)).subscribe((result: ITaskDashboardResultDTO) => {

        const resultList = result.resultList;
        this.policyTotalRowCount = result.paginationDetails.totalCount;
        this.policyTotalPage = result.paginationDetails.totalPages;

        let filteredList = resultList.filter(t => Boolean(t.riskId) && t.riskId === this.entityRiskData.riskId);
        filteredList = _.uniqBy(filteredList, 'taskId');
        this.populateTaskPageData(filteredList);

        if (isFromPolicyRequest) {
          filteredList.forEach(a => this.taskDescriptionCodeList.push(a.description));
          filteredList.forEach(a => this.taskDescriptionCodeAndStatusList.push({
              description: a.description,
              taskStatus: a.taskStatus
            })
          );
        }
        this.policyCurrentPage = payload.pageNumber;
        this.policyTaskLoading = false;
        Utils.unblockUI();
      }, (error) => {
        this.policyTaskLoading = false;
        Utils.unblockUI();
        NotifUtils.showError(error.message);
      });
    }
  }

  getTaskDetail(taskId: string): void {
    Utils.blockUI();
    this.taskService.getTaskById(taskId).pipe(takeUntil(this.stop$)).subscribe((result: ITaskDTO) => {
      this.taskDetailNotifier.next({ data: result, isComplete: true, hasError: false });
      Utils.unblockUI();
    }, (error) => {
      this.taskDetailNotifier.next({ data: null, isComplete: true, hasError: true });
      Utils.unblockUI();
      NotifUtils.showError(error.message);
    });
  }

  populateTaskPageData(result: ITaskListResultDTO[]): void {
    this.tasksTableRows = [];
    if (result.length > 0) {
      _.map(result, (item) => {
        this.addTableItem(item);
      });
    }
  }

  addTableItem(newItem: ITaskListResultDTO): void {
    const tr: ITableTd[] = [];
    const fields: string[] = [
      TaskLabelsConstants.TableFields.createdDate,
      TaskLabelsConstants.TableFields.tasksOwner,
      TaskLabelsConstants.TableFields.tasksCategory,
      TaskLabelsConstants.TableFields.tasksDueDate,
      TaskLabelsConstants.TableFields.tasksTaskStatus,
      TaskLabelsConstants.TableFields.tasksDescription
    ];

    fields.forEach((item) => {
      let display: any;

      switch (item) {
        case TaskLabelsConstants.TableFields.createdDate:
          display = Boolean(newItem[item]) ? String(this.datePipe.transform(newItem[item], this.tableDateFormat)) : '';
          break;
        case TaskLabelsConstants.TableFields.tasksOwner:
          display = this.getUnderwriterAgentFullName(newItem);
          break;
        case TaskLabelsConstants.TableFields.tasksCategory:
          display = this.getTaskCategoryDescription(newItem[item]);
          break;
        case TaskLabelsConstants.TableFields.tasksDueDate:
          display = Boolean(newItem[item]) ? String(this.datePipe.transform(newItem[item], this.tableDateFormat)) : '';
          break;
        case TaskLabelsConstants.TableFields.tasksTaskStatus:
          display = this.getTaskStatusDescription(newItem[item]);
          break;
        case TaskLabelsConstants.TableFields.tasksDescription:
          display = this.getTaskDescription(newItem[item]);
          break;
        default:
          display = Boolean(newItem[item]) ? String(newItem[item]) : '';
          break;
      }

      tr.push({
        id: newItem.taskId,
        value: Boolean(newItem[item]) ? newItem[item] : '',
        display: display
      });
    });
    this.tasksTableRows.push({
      id: newItem.taskId,
      tr: tr,
      riskId: newItem.riskId ?? '',
      riskDetailId: newItem.riskDetailId ?? ''
    });
  }

  enabledDisabledControls(): void {
    if (this.isValidQuotePolicyNumber()) {
      this.enableFormControls(this.taskDetailsForm);
      this.isDisabledForm = false;
    } else {
      this.disableFormControls(this.taskDetailsForm);
      this.isDisabledForm = true;
    }
  }

  isValidQuotePolicyNumber(): boolean {
    if (this.currentRiskId || this.currentTaskId) {
      return true;
    } else {
      return false;
    }
  }


  getUnderwriterList(): void {
    this.userService.getInternalUserList().takeUntil(this.stop$).subscribe((res) => {
      if (res) {
        this.ownerNameList = [];

        LvOwner.forEach(x => {
          if (x.isActive) {
            this.ownerNameList.push({ code: x.code, name: x.description, userType: TaskLabelsConstants.Group });
          }
        });

        res.forEach(x => {
          this.ownerNameList.push({ code: x?.userId, name: `${x?.firstName} ${x?.lastName}`, userType: TaskLabelsConstants.Internal });
        });
      }
    }, err => {
      NotifUtils.showError(err);
    });
  }

  disableFormControls(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).disable();
    });
  }

  enableFormControls(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).enable();
    });
  }

  initializeDetailsOnAdd(): void {
    const entityAddresses = this.entityRiskData.EntityRisk.entityAddresses;
    this.currentQuoteNumber = this.entityRiskData.getQuoteNumber();
    this.currentPolicyNumber = this.entityRiskData.getPolicyNumber();
    this.currentInsuredName = this.entityRiskData.EntityRisk.fullName;
    this.currentPropertyAddress = entityAddresses.length > 0 ? Utils.getFullAddress(entityAddresses.find(a => a.addressTypeCode === AddressType.Property)?.address, true) : '';
    this.currentEntityId = this.entityRiskData.getEntityId();
    this.currentRiskId = this.entityRiskData.getRisk().id;
    this.currentStateCode = this.entityRiskData.getEntityAddress().address.state;
    this.currentRiskDetailId = this.entityRiskData.getRiskDetailId();
    this.currentProcessDate = this.entityRiskData.getRiskProcessDate();
  }

  getTableCreatedDate(data): Date {
    return data.createdDateProper ?? data.createdDate;
  }

  searchPagination(pageNumber: number = 1, pageSize: number = 10): void {
    const payload: ITaskPolicySearchRequestDTO = {
      searchValue: this.policyForm.get('searchInput').value,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };

    if (this.isSearchModalOpen) {
      Utils.blockUI();
      this.taskService.taskSearch(payload).pipe(takeUntil(this.stop$)).subscribe(result => {
        this.policyData = result.resultList;

        Utils.unblockUI();
      }, (error) => {
        Utils.unblockUI();
      });
    }
  }

  policyNumber(data?: any): string {
    return data?.isEndorsement ? data?.policyNumber : '';
  }

  //#endregion
}

