import { Component, OnDestroy, OnInit } from '@angular/core';
import { SuspendedPaymentDetailsDTO } from '../../../../../../shared/models/data/dto/suspended-payment/suspended-payment-detail.dto';
import { SuspendedPaymentsData } from '../../../../../../modules/payments/data/suspended-payments.data';
import { SuspendedPaymentsLabel } from '../../../../../../shared/constants/suspended-payments.labels.constants';
import { BsModalRef } from 'ngx-bootstrap';
import { ErrorMessageConstant } from '../../../../../../shared/constants/error-message.constants';
import { FormGroup } from '@angular/forms';
import { PutSuspendedPaymentRequestDTO } from '../../../../../../../app/shared/models/data/dto/suspended-payment/put-suspended-payment-request.dto';
import { PayerDTO } from '../../../../../../../app/shared/models/data/dto/billing/payer.dto';
import { BillingService } from '../../../../../../../app/core/services/billing/billing.service';
import Utils from '../../../../../../../app/shared/utilities/utils';
import NotifUtils from '../../../../../../../app/shared/utilities/notif-utils';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../../../../../app/shared/base-class';
import { SuspendedPaymentRequestDTO } from '../../../../../../shared/models/data/dto/billing/suspended-payment-request.dto';
import { SuspendedCheckDTO } from '../../../../../../shared/models/data/dto/billing/suspended-check.dto';

@Component({
  selector: 'app-details-suspended-payment-modal',
  templateUrl: './details-suspended-payment-modal.component.html',
  styleUrls: ['./details-suspended-payment-modal.component.scss']
})
export class DetailsSuspendedPaymentModalComponent extends BaseClass implements OnInit, OnDestroy {
  public SuspendedPaymentsLabel = SuspendedPaymentsLabel;
  public ErrorMessageConstant = ErrorMessageConstant;

  paymentDetail: SuspendedPaymentDetailsDTO;
  hasReturnDetails: boolean;
  isEdit: boolean = true;
  batchPaymentDetailId: string;

  constructor(
    public suspendedPaymentsData: SuspendedPaymentsData,
    private bsModalRef: BsModalRef,
    private billingService: BillingService) {
    super();
  }

  ngOnInit() {
    this.populateFields();
  }

  get returnDetails(): FormGroup {
    return this.suspendedPaymentsData.detailsSuspendedFormGroup;
  }

  get updateSuspendedForm(): FormGroup {
    return this.suspendedPaymentsData.updateSuspendedFormGroup;
  }

  get isPolicyNumberChanged(): boolean {
    return this.paymentDetail.policyNumber !== this.updateSuspendedForm?.get('policyNumber')?.value && this.isEdit && this.suspendedPaymentsData.isPolicySearchDone && !this.suspendedPaymentsData.updateSuspendedFormGroup.invalid;
  }

  hideModal(): void {
    this.bsModalRef.hide();
    this.suspendedPaymentsData.resetDocumentVariables();
    this.suspendedPaymentsData.resetUpdateSuspendedForm();
  }

  populateFields(): void {
    if (this.hasReturnDetails) {
      this.populateReturnDetails();
    }
    this.initUpdateSuspendedForm();
  }

  populateReturnDetails(): void {
    if (this.paymentDetail.returnDetail && Object.keys(this.paymentDetail).length !== 0) {
      const detail = this.paymentDetail.returnDetail;

      this.returnDetails.get('returnDate').setValue(detail.returnDate);
      this.returnDetails.get('payee').setValue(detail.payee);
      this.returnDetails.get('checkNumber').setValue(detail.checkNumber);
      this.returnDetails.get('checkDate').setValue(this.formatDate(detail.checkIssueDate));
      this.returnDetails.get('clearDate').setValue(this.formatDate(detail.checkClearDate));
      this.returnDetails.get('escheatDate').setValue(this.formatDate(detail.checkEscheatDate));
      this.returnDetails.get('address').setValue(detail?.address);
    }
  }

  onSave(): void {
    if (this.paymentDetail && this.hasReturnDetails) {
      this.suspendedPaymentsData.updateReturnDetails(this.paymentDetail.id);
      this.suspendedPaymentsData.updateReturnDetailsNotifier.subscribe(result => {
        if (result.isCompleted && !result.isError) {
          this.hideModal();
          this.suspendedPaymentsData.getReturnedSuspendedPaymentList();
        }
      });
    }
  }

  onUpdate(): void {
    if (this.paymentDetail && !this.hasReturnDetails) {
      const putRequest: PutSuspendedPaymentRequestDTO = {
        suspendedPaymentId: this.paymentDetail.id,
        policyNumber: this.updateSuspendedForm?.get('policyNumber').value?.trim(),
        suspendedPayer: this.getSuspendedPayerDetails()
      };

      const splitPaymentRequest: PutSuspendedPaymentRequestDTO = {
        suspendedPaymentId: this.paymentDetail.id,
        suspendedSplitPayment: this.suspendedPaymentsData.suspendedSplitPayment,
      };

      Utils.blockUI();
      this.billingService.putSuspendedPayment(!this.suspendedPaymentsData.isSplitPayment ? putRequest : splitPaymentRequest).pipe(takeUntil(this.stop$)).subscribe(res => {
        if (res) {
          this.bsModalRef.hide();
          if (!this.suspendedPaymentsData.isSplitPayment) {
            const callback = () => NotifUtils.showSuccess(this.SuspendedPaymentsLabel.Pending.Detail.updateSuccessMessage);
            this.isEdit = false;
            this.suspendedPaymentsData.getSuspendedPaymentList(callback);
          }
        }
      },
        err => {
          this.isEdit = false;
          Utils.unblockUI();
          NotifUtils.showMultiLineError(err.error?.message);
        });


      if (this.suspendedPaymentsData.isSplitPayment) {

        if (this.suspendedPaymentsData.availableToAllocate !== 0) {
          this.pushSusPaymentWIthEmptyPolicyNum();
        }
        this.suspendedPaymentsData.createSuspendedPaymentForSplitPayments();
      }
    }
  }

  onCancel(): void {
    this.initUpdateSuspendedForm();
    this.isEdit = false;
  }

  initUpdateSuspendedForm(): void {
    this.updateSuspendedForm.get('policyNumber').setValue(this.paymentDetail.policyNumber);
    this.updateSuspendedForm.get('payerFirstName').setValue(this.paymentDetail.payer?.firstName);
    this.updateSuspendedForm.get('payerLastName').setValue(this.paymentDetail.payer?.lastName);
    this.updateSuspendedForm.get('address1').setValue(this.paymentDetail.payer?.address1);
    this.updateSuspendedForm.get('address2').setValue(this.paymentDetail.payer?.address2);
    this.updateSuspendedForm.get('city').setValue(this.paymentDetail.payer?.city);
    this.updateSuspendedForm.get('state').setValue(this.paymentDetail.payer?.state);
    this.updateSuspendedForm.get('zipCode').setValue(this.paymentDetail.payer?.zip);
  }

  getSuspendedPayerDetails(): PayerDTO {
    return {
      firstName: this.updateSuspendedForm.get('payerFirstName').value,
      lastName: this.updateSuspendedForm.get('payerLastName').value,
      address1: this.updateSuspendedForm.get('address1').value,
      address2: this.updateSuspendedForm.get('address2').value,
      city: this.updateSuspendedForm.get('city').value,
      state: this.updateSuspendedForm.get('state').value,
      zip: this.updateSuspendedForm.get('zipCode').value
    };
  }

  formatDate(date: string) {
    return !date ? '' :
      {
        isRange: false,
        singleDate: {
          jsDate: new Date(date)
        }
      };
  }

  pushSusPaymentWIthEmptyPolicyNum(): void {
    const receiptDate = new Date(this.paymentDetail.receiptDate);
    receiptDate.setDate(receiptDate.getDate() + 1);

    const suspendedCheck: SuspendedCheckDTO = {
      checkAmount: this.paymentDetail.checkAmount
    };

    const splitPayment: SuspendedPaymentRequestDTO = {
      receiptDate: receiptDate,
      policyNumber: '',
      sourceId: 'IN',
      amount: this.suspendedPaymentsData.availableToAllocate,
      comment: '',
      originalPolicyPaymentAmount: this.paymentDetail.amount,
      suspendedCheck: suspendedCheck,
      directBillCarrierId: this.paymentDetail.directBillCarrierId
    };

    this.suspendedPaymentsData.suspendedPaymentBatch.push(splitPayment);
  }

  ngOnDestroy(): void {
    this.suspendedPaymentsData.resetSplitpaymentVariables();
  }
}
