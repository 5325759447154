import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { PropertyComponent } from './property.component';
import { RouterModule, Routes } from '@angular/router';
import { LocationDetailsComponent } from './location-details/location-details.component';
import { PropertyDetailsComponent } from './property-details/property-details.component';
import { WindMitigationComponent } from './wind-mitigation/wind-mitigation.component';
import { ISO360ValueModalComponent } from './property-details/iso-360value-modal/iso-360value-modal.component';
import { ModalModule } from 'ngx-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { CanDeactivatePropertyComponentGuard } from './property-navigation-guard.service';

const routes: Routes = [
  { path: '', component: PropertyComponent }
  // { path: '', component: PropertyComponent, canDeactivate: [CanDeactivatePropertyComponentGuard]}
];

@NgModule({
  declarations: [PropertyComponent, LocationDetailsComponent, PropertyDetailsComponent, WindMitigationComponent, ISO360ValueModalComponent],
  entryComponents: [ISO360ValueModalComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    ModalModule.forRoot(),
    NgxMaskModule.forRoot()
  ],
  providers: [CanDeactivatePropertyComponentGuard]
})
export class PropertyModule { }
