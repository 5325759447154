export const PolicyCancellationLabelsConstants = {
  PolicyCancellation: {
    PolicyCancellationTitleHeader: 'Policy Cancellation',
    EffectiveDate: 'Effective Date',
    CancellationType: 'Cancellation Type',
    CancellationReasonType: 'Cancellation Reason Type',
    CancellationReason: 'Cancellation Reason',
    CancelPolicyButton: 'Cancel Policy',
    CloseButton: 'Close',
    CancellationTypePlaceHolder: 'Select cancellation type',
    CancellationReasonTypePlaceHolder: 'Select cancellation reason type',
    EffectiveDatePriorDateOfLoss: 'Cancellation effective date cannot be prior to date of loss',
    InsuredRequest: 'IR',
    BillingReasons: 'BR',
    UnderwriterReasons: 'UR'
  },

  PolicyRewrite: {
    Title: 'Rewrite Policy',
    NewPolicyEffectiveDate: 'New Policy Effective Date',
    Ok: 'OK',
    Cancel: 'Cancel',
    RewriteNotes: 'Cancel rewrite allows for an automated rewrite of the policy due to an effective date change only. Please choose the adjusted effective date for the rewritten policy below, and the policy will be flat cancelled.  If you need to update more than the effective date, please use the Copy option.',
    RewriteSuccess: 'Policy rewrite has been completed.'
  }
};
