/* eslint-disable curly */
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseClass } from 'app/shared/base-class';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { AuthService } from '../../../core/services/auth.service';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { EntityRiskData } from '../../submission-management/data/entity-risk.data';
import { BillingService } from '../../../core/services/billing/billing.service';
import { PostAutoReinstatementDTO } from '../../../shared/models/data/dto/billing/post-auto-reinstatement.dto';
import { RiskDTO2 } from '../../../shared/models/data/dto/quick-quote/risk-dto2';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { ApproverDetailsDTO } from '../../../shared/models/data/dto/billing/approver-details.dto';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import * as moment from 'moment';
import 'moment-timezone';
import { ReinstatementApprovalViewDTO } from '../../../shared/models/data/dto/billing/reinstatement-approval-view.dto';
import { ReinstatementApprovalDTO } from '../../../shared/models/data/dto/billing/reinstatement-approval.dto';
import { SubmissionPageData } from '../../submission-management/data/submission-page.data';
import { ApproveReinstatementDTO } from '../../../shared/models/data/dto/billing/approve-reinstatement.dto';
import { PolicyService } from '../../../core/services/submission/policy.service';
import { AutoReinstatementConstants } from '../../../shared/constants/auto-reinstatement.constants';
import { StatusConstants } from '../../../shared/constants/risk-status';
import { RenewalOfferRequestDTO } from '../../../shared/models/data/dto/billing/renewal-offer-request.dto';
import { RenewalReinstatementApprovalDetailsRequestDTO } from '../../../shared/models/data/dto/billing/renewal-reinstatement-approval-details-request.dto';
import Utils from '../../../shared/utilities/utils';
import { OtherApprovalRequirementsDTO } from '../../../shared/models/data/dto/billing/other-approval-requirements.dto';
import { AllowedPaymentRangeDTO } from '../../../shared/models/data/dto/billing/allowed-payment-range.dto';
import { PaymentProcedure } from '../../../shared/enum/payment-procedure.enum';
import { PolicyBillingData } from './policy-billing.data';
import { PaymentRequestDTO } from '../../../shared/models/data/dto/billing/payment-request.dto';
import { MakePaymentDTO } from '../../../shared/models/data/dto/billing/make-payment.dto';
import { PageHeaderSummaryConstants } from '../../../shared/constants/page-header-summary.constants';
import { RecalculateReinstatementAmountRequestDTO } from 'app/shared/models/data/dto/billing/recalculate-reinstatement-amount-request.dto';
import { LvPayPlanOptions } from '../../../shared/constants/billing.options.constants';
import { RenewalBillingSummaryRequestDTO } from '../../../shared/models/data/dto/billing/renewal-billing-summary.request.dto';

@Injectable({
  providedIn: 'root'
})
export class AutoReinstatementData extends BaseClass {
  autoReinstatementForm: FormGroup;
  isApprovalRequired$ = new BehaviorSubject<boolean>(false);
  showOtherApprovalDetails$ = new BehaviorSubject<boolean>(false);
  controlsWithChanges: string[] = [];
  amountBilled: number = 0;
  approverName: string = '';
  fromNotesUserDetails: ApproverDetailsDTO = null;
  approverDetails: ApproverDetailsDTO = null;
  serverDate: Date = null;
  expirationDateOptions: IAngularMyDpOptions;
  reinstatementView: ReinstatementApprovalViewDTO = null;
  isValidExpirationDate: boolean = true;
  isSubmissionPolicies: boolean = false;
  // otherRequirementsMetDetails: OtherApprovalRequirementsDTO = null;
  renewalRiskId: string = '';
  isRedirectedToPolicy: boolean = false;
  isPolicy: boolean = false;
  public pageHeaderConstants = PageHeaderSummaryConstants;
  LvPayPlanOptions = LvPayPlanOptions;

  constructor(
    protected entityRiskData: EntityRiskData,
    public authService: AuthService,
    protected billingService: BillingService,
    public submissionData: SubmissionPageData,
    protected policyService: PolicyService,
    public billingData: PolicyBillingData,

  ) {
    super();
  }

  populateAutoReinstateData() {
    this.initOtherVariables();
    if (this.risk?.riskStatusCode === StatusConstants.cancelled && this.risk?.cancellationTypeCode === AutoReinstatementConstants.BillingReasonCode) {
      this.getDetailsForReinstatement();
    } else if (this.isRenewalSubmission() && this.entityRiskData.isNotCurrentPolicyNonRenewedWithRenewalNotTaken()) {
      this.getDetailsForRenewalReinstatement();
    } else if (this.billingData.hasRenewalNotTaken) {
      this.billingService.getRenewalRiskDetails(this.risk?.id).pipe(take(1), filter(result => Boolean(result))).subscribe(res => {
        this.renewalRiskId = res?.id;
        this.getDetailsForRenewalReinstatement();
      });
    }
  }

  initForms(): void {
    this.autoReinstatementForm = new FormGroup({
      isApprovedReinstatement: new FormControl(false),
      approvalExpirationDate: new FormControl(null),
      hasStatementOfNoLoss: new FormControl(false),
      paymentRequired: new FormControl(0)
    });
  }

  get risk(): RiskDTO2 {
    return this.entityRiskData.getRisk();
  }

  protected initOtherVariables(): void {
    this.serverDate = this.authService.getCustomDate();
    this.expirationDateOptions = this.setApprovalExpirationOptions();
  }

  protected setApprovalExpirationOptions(): IAngularMyDpOptions {
    const currentDate = this.authService.getCustomDate();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const policyEffectiveDate = new Date(this.risk?.riskDetails[0]?.effectiveDate);
    const isCurrentAfterExpiration = moment(currentDate).isAfter(moment(policyEffectiveDate).add(1, 'y'));

    return {
      dateFormat: 'mm/dd/yyyy',
      disableUntil: { year: currentYear, month: currentMonth + 1, day: currentDay },
      enableDates: isCurrentAfterExpiration ? [] : [{ year: currentYear, month: currentMonth + 1, day: currentDay }],
    };
  }

  protected getDetailsForReinstatement(): void {
    this.isApprovalRequired$.next(false);
    const billingDetails$ = this.billingService.getBillingAutoReinstatementDetails(this.risk.id)
      .pipe(filter(result => Boolean(result)));

    billingDetails$.pipe(
      tap(result => {
        this.reinstatementView = result;
      }),
      switchMap(result => this.authService.getApproverDetails(result)))
      .subscribe(approverDetails => {
        this.isApprovalRequired$.next(this.reinstatementView.isApprovalRequired);
        this.showOtherApprovalDetails$.next(Boolean(this.risk.approvedForReinstatementDate) ||
          (Boolean(this.reinstatementView.approvalDetails) &&
            this.reinstatementView.approvalDetails?.reinstatementApprovalStatusId !== AutoReinstatementConstants.approvalStatus.Expired));
        this.populateAutomatedReinstatementSection(this.risk, this.reinstatementView.approvalDetails, true);

        if (Boolean(approverDetails)) {
          this.approverDetails = approverDetails;
          this.approverName = approverDetails?.fullName ?? '';
        }
      }, err => {
        this.isApprovalRequired$.next(false);
        NotifUtils.showError(JSON.stringify(err.error?.message) ?? 'Error in fetching approver details');
      });
  }

  protected populateAutomatedReinstatementSection(risk: RiskDTO2, reinstatement: ReinstatementApprovalDTO, onInit: boolean = true, isForRenewal: boolean = false): void {
    this.autoReinstatementForm.get('isApprovedReinstatement').setValue(onInit ? Boolean(isForRenewal ? reinstatement.isApproved : risk.approvedForReinstatementDate) : reinstatement.isApproved);

    if (Boolean(reinstatement)) {
      //formattedDate is used to prevent the subtracting of 1 day in approval expiration date
      const formattedDate = Boolean(reinstatement.approvalExpirationDate) ? reinstatement.approvalExpirationDate.toString().includes('Z') ? reinstatement.approvalExpirationDate : `${reinstatement.approvalExpirationDate}Z` : null;
      this.autoReinstatementForm.get('approvalExpirationDate').setValue(Boolean(reinstatement.approvalExpirationDate) ? { isRange: false, singleDate: { jsDate: new Date(formattedDate) } } : null);
      this.autoReinstatementForm.get('hasStatementOfNoLoss').setValue(reinstatement.isStatementOfNoLossReceived);
      this.autoReinstatementForm.get('paymentRequired').setValue(reinstatement.paymentRequiredToReinstate < 0 ? 0 : reinstatement.paymentRequiredToReinstate);

      this.amountBilled = this.amountBilledSetter(reinstatement.isApproved, reinstatement.paymentRequiredToReinstate);
      this.risk.approvedForReinstatementDate = reinstatement.isApproved ? this.serverDate : null;
    }
  }

  protected amountBilledSetter(isApproved: boolean, adjustedPaymentToReinstate: number): number {
    const computedAmount = this.reinstatementView.computedAmountToReinstate < 0 ? 0 : this.reinstatementView.computedAmountToReinstate;
    return isApproved ? adjustedPaymentToReinstate : computedAmount;
  }

  postAutomatedReinstatementApproval(): void {
    if (this.hasIncompleteValues()) {
      return;
    }

    Utils.blockUI();
    forkJoin([
      this.setApproveForReinstatement(Boolean(this.autoReinstatementForm.get('isApprovedReinstatement').value)),
      this.billingService.postBillingAutoReinstatementDetails(this.postApprovalDetailsPayload())
    ])
      .pipe(
        tap(result => {
          if (Boolean(result[1])) {
            this.reinstatementView = result[1];
          }
        }),
        switchMap(_ => {
          const details = this.reinstatementView.approvalDetails;
          const updatedById = Boolean(details) ? details.isApproved ? details.approvedById : details.rescindedById : '';
          if (Number(updatedById) === this.approverDetails?.userId) {
            return of(this.approverDetails);
          } else {
            return this.authService.getApproverDetails(this.reinstatementView);
          }
        })
      )
      .subscribe(approverDetails => {
        Utils.unblockUI();
        this.isApprovalRequired$.next(this.reinstatementView.isApprovalRequired);
        this.showOtherApprovalDetails$.next(Boolean(this.risk.approvedForReinstatementDate) || Boolean(this.reinstatementView.approvalDetails));
        this.submissionData.isApprovedForReinstatement.next(this.reinstatementView.approvalDetails.isApproved);
        this.populateAutomatedReinstatementSection(this.risk, this.reinstatementView.approvalDetails, false);

        if (Boolean(approverDetails)) {
          this.approverDetails = approverDetails;
          this.approverName = approverDetails?.fullName ?? '';
        }
        this.controlsWithChanges = [];
      }, err => {
        Utils.unblockUI();
        NotifUtils.showError(JSON.stringify(err.error?.message));
      });
  }

  protected setApproveForReinstatement(isApprovedForReinstatement: boolean): Observable<any> {
    const riskId = this.risk.id;
    const riskDetailId = this.risk.riskDetails[0].id;

    let approveForReinstatementObs: Observable<any> = null;

    if (isApprovedForReinstatement) {
      approveForReinstatementObs = this.policyService.setApproveForReinstatement(riskId, riskDetailId);
    } else {
      approveForReinstatementObs = this.policyService.revertApproveForReinstatement(riskId, riskDetailId);
    }

    return approveForReinstatementObs;
  }

  protected postApprovalDetailsPayload(): PostAutoReinstatementDTO {
    const isApproved: boolean = this.autoReinstatementForm.get('isApprovedReinstatement').value;
    return new PostAutoReinstatementDTO({
      riskId: this.risk?.id,
      isApproved: isApproved,
      approvalExpirationDate: isApproved ? moment.utc(this.autoReinstatementForm.get('approvalExpirationDate').value?.singleDate?.jsDate.toISOString()).tz('America/New_York') : null,
      isStatementOfNoLossReceived: this.autoReinstatementForm.get('hasStatementOfNoLoss').value,
      paymentRequiredToReinstate: this.autoReinstatementForm.get('paymentRequired').value
    });
  }

  hasIncompleteValues(): boolean {
    const form = this.autoReinstatementForm;
    const includedField = ['approvalExpirationDate', 'paymentRequired'];
    if (form.get('isApprovedReinstatement').value) {
      for (const key in form.controls) {
        if (includedField.includes(key) && (form.get(key).value === null || form.get(key).value === '')) {
          return true;
        }

        if (key === 'paymentRequired') {
          return form.get(key).value > this.reinstatementView.maximumPaymentAmount;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  public getDetailsForRenewalReinstatement(): void {
    this.isApprovalRequired$.next(false);
    const billingDetails$ = this.billingService.getRenewalAutoReinstatementDetails(this.getRenewalReinstatementPayload())
      .pipe(filter(result => Boolean(result)));

    billingDetails$.pipe(
      tap(result => {
        this.reinstatementView = result;
      }),
      switchMap(result => this.authService.getApproverDetails(result))
    ).subscribe(approverDetails => {
      this.isApprovalRequired$.next(this.isPolicy ? false : this.reinstatementView.isApprovalRequired);
      this.showOtherApprovalDetails$.next(Boolean(this.risk.approvedForReinstatementDate) ||
        Boolean(this.reinstatementView?.approvalDetails));
      this.populateAutomatedReinstatementSection(this.risk, this.reinstatementView?.approvalDetails, true, true);

      if (Boolean(approverDetails)) {
        this.approverDetails = approverDetails;
        this.approverName = approverDetails?.fullName ?? '';
      }
    }, err => {
      this.isApprovalRequired$.next(false);
      NotifUtils.showError(JSON.stringify(err.error?.message) ?? 'Error in fetching approver details');
    });
  }

  postRenewalAutomatedReinstatementApproval(): void {
    if (this.hasIncompleteValues()) {
      return;
    }
    Utils.blockUI();
    const billingDetails$ = this.billingService.postRenewalBillingAutoReinstatementDetails(this.getPostRenewalApprovalDetailsPayload())
      .pipe(filter(result => Boolean(result)));

    billingDetails$.pipe(
      tap(result => {
        this.reinstatementView = result;
      }),
      switchMap(_ => this.authService.getApproverDetails(this.reinstatementView))
    ).subscribe(approverDetails => {
      Utils.unblockUI();
      this.controlsWithChanges = [];
      this.isApprovalRequired$.next(this.reinstatementView.isApprovalRequired);
      this.showOtherApprovalDetails$.next(Boolean(this.risk.approvedForReinstatementDate) || Boolean(this.reinstatementView.approvalDetails));
      this.submissionData.isApprovedForReinstatement.next(this.reinstatementView?.approvalDetails?.isApproved);
      this.populateAutomatedReinstatementSection(this.risk, this.reinstatementView?.approvalDetails, false, true);

      if (Boolean(approverDetails)) {
        this.approverDetails = approverDetails;
        this.approverName = approverDetails?.fullName ?? '';
      }
    }, err => {
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify(err.error?.message));
    });
  }

  protected getRenewalReinstatementPayload(): RenewalReinstatementApprovalDetailsRequestDTO {
    return new RenewalReinstatementApprovalDetailsRequestDTO({
      renewalRiskId: this.isPolicy ? this.renewalRiskId : this.risk?.id,
      expiringRiskId: this.isPolicy ? this.risk?.id : this.risk?.renewalFromRiskId,
      paymentPlan: this.risk?.riskDetails[0]?.riskBinds[0]?.riskBindBilling?.payPlanOption
    });
  }

  protected getPostRenewalApprovalDetailsPayload(): PostAutoReinstatementDTO {
    const isApproved: boolean = this.autoReinstatementForm.get('isApprovedReinstatement').value;
    return new PostAutoReinstatementDTO({
      riskId: this.risk?.id,
      isApproved,
      approvalExpirationDate: isApproved ? moment.utc(this.autoReinstatementForm.get('approvalExpirationDate').value?.singleDate?.jsDate.toISOString()).tz('America/New_York') : null,
      isStatementOfNoLossReceived: this.autoReinstatementForm.get('hasStatementOfNoLoss').value,
      paymentRequiredToReinstate: this.autoReinstatementForm.get('paymentRequired').value,
      renewalOfferRequest: this.getRenewalOfferPayload(),
    });
  }

  protected getRenewalOfferPayload(): RenewalOfferRequestDTO {
    return new RenewalOfferRequestDTO({
      expiringPolicyRiskId: this.risk?.renewalFromRiskId,
      renewalPolicyRiskId: this.risk?.id,
      renewalPolicyEffectiveDate: this.risk?.riskDetails[0]?.effectiveDate,
      paymentPlan: this.risk?.riskDetails[0]?.riskBinds[0]?.riskBindBilling?.payPlanOption
    });
  }

  requirementsMetDetails: OtherApprovalRequirementsDTO = null;
  allowedPaymentRangeFromPostPayment: AllowedPaymentRangeDTO = null;
  protected isReinstatementRequirementsNotMet: boolean = false;

  checkIsReinstatementRequirementsNotMet(isRefundFromPostPayment: boolean, postPaymentForm: FormGroup): boolean {
    const details = this.requirementsMetDetails;
    const allowedRange = this.allowedPaymentRangeFromPostPayment;
    allowedRange.minimumPaymentAmount = this.getMinimumPaymentAmount(allowedRange?.minimumPaymentAmount);
    allowedRange.maximumPaymentAmount = allowedRange.maximumPaymentAmount >= 0 ? allowedRange.maximumPaymentAmount : 0;
    const includedRenewalStatus = [AutoReinstatementConstants.reinstateStatus.RRW, AutoReinstatementConstants.reinstateStatus.RWNT];
    const isRenewal = includedRenewalStatus.includes(this.risk?.renewalCode);
    const cancelledAllowedRange = postPaymentForm.get('amount').value < allowedRange.minimumPaymentAmount || postPaymentForm.get('amount').value > allowedRange.maximumPaymentAmount;
    const cancelledRescindedAllowedRange = postPaymentForm.get('amount').value < details?.requiredAmount ||
      (
        details?.areRequirementsMetExceptPayment ?
          postPaymentForm.get('amount').value > allowedRange.maximumPaymentAmount + this.billingData.renewalAmount :
          postPaymentForm.get('amount').value > details?.requiredAmount
      );
    const isMinAndMaxAllowedRangeMet = this.hasRescindedOffer ?
      cancelledRescindedAllowedRange :
      cancelledAllowedRange;

    this.isReinstatementRequirementsNotMet = (
      (this.risk?.riskStatusCode === StatusConstants.cancelled && this.risk?.cancellationTypeCode === AutoReinstatementConstants.BillingReasonCode && !isRefundFromPostPayment) &&
      (!details?.areRequirementsMetExceptPayment || (details?.areRequirementsMetExceptPayment && isMinAndMaxAllowedRangeMet && !isRenewal && this.reinstatementView?.isApprovalRequired))
    );
    return this.isReinstatementRequirementsNotMet;
  }

  portalCheckIsReinstatementRequirementsNotMet(isRefundFromPostPayment: boolean, postPaymentForm: FormGroup, makePayment: MakePaymentDTO): boolean {
    const details = this.requirementsMetDetails;
    const allowedRange = this.allowedPaymentRangeFromPostPayment;
    allowedRange.minimumPaymentAmount = this.getMinimumPaymentAmount(allowedRange?.minimumPaymentAmount);
    allowedRange.maximumPaymentAmount = allowedRange.maximumPaymentAmount >= 0 ? allowedRange.maximumPaymentAmount : 0;
    const includedRenewalStatus = [AutoReinstatementConstants.reinstateStatus.RRW, AutoReinstatementConstants.reinstateStatus.RWNT];
    const isRenewal = includedRenewalStatus.includes(this.risk?.renewalCode);
    const cancelledAllowedRange = postPaymentForm.get('amount').value < allowedRange.minimumPaymentAmount || postPaymentForm.get('amount').value > allowedRange.maximumPaymentAmount;
    const cancelledRescindedAllowedRange = postPaymentForm.get('amount').value < details?.requiredAmount ||
      (
        details?.areRequirementsMetExceptPayment ?
          postPaymentForm.get('amount').value > allowedRange.maximumPaymentAmount + this.billingData.renewalAmount :
          postPaymentForm.get('amount').value > details?.requiredAmount
      );
    const isMinAndMaxAllowedRangeMet = this.hasRescindedOffer ?
      cancelledRescindedAllowedRange :
      cancelledAllowedRange;

    this.isReinstatementRequirementsNotMet = (
      (makePayment.riskStatusCode === StatusConstants.cancelled && makePayment.cancellationTypeCode === AutoReinstatementConstants.BillingReasonCode && !isRefundFromPostPayment) &&
      (!details?.areRequirementsMetExceptPayment || (details?.areRequirementsMetExceptPayment && isMinAndMaxAllowedRangeMet && !isRenewal && this.reinstatementView?.isApprovalRequired))
    );
    return this.isReinstatementRequirementsNotMet;
  }

  checkIsRenewalReinstatementRequirementsNotMet(isRefundFromPostPayment: boolean, postPaymentForm: FormGroup): boolean {
    const details = this.requirementsMetDetails;
    const isCurrentAndRenewalTermChecked = postPaymentForm.get('currentAndRenewalTermCheckbox').value;

    return (((this.billingData.hasRenewalNotTaken || this.entityRiskData.isRenewalNotTaken) && !isRefundFromPostPayment) &&
      isCurrentAndRenewalTermChecked &&
      (
        !details?.areRequirementsMetExceptPayment ||
        (details?.areRequirementsMetExceptPayment && this.reinstatementView?.isApprovalRequired &&
          (
            postPaymentForm.get('amount').value < this.requirementsMetDetails?.requiredAmount ||
            postPaymentForm.get('amount').value > this.billingData.currenntAndRenewalAmount
          )
        )
      )
    );
  }

  checkIsReinstatementCheckboxNeeded(postPaymentForm: FormGroup): boolean {
    return this.reinstatementView != null && !postPaymentForm.get('reinstateAmountCheckbox').value && !postPaymentForm.get('balanceAmountCheckbox').value && !this.entityRiskData.isRenewalNotTaken && this.isPolicyCancelled;
  }

  checkIsReinstatementExpired(): boolean {
    const approvalDetails = this.reinstatementView?.approvalDetails;
    return Boolean(approvalDetails) && approvalDetails?.reinstatementApprovalStatusId === AutoReinstatementConstants.approvalStatus.Expired;
  }

  setReinstatementAndRequirementsMetProperty(paymentProcedure: PaymentProcedure, postPaymentForm: FormGroup): boolean {
    if (this.risk?.riskStatusCode !== StatusConstants.cancelled || (this.risk?.riskStatusCode === StatusConstants.cancelled &&
      this.risk?.cancellationTypeCode !== AutoReinstatementConstants.BillingReasonCode) || paymentProcedure !== PaymentProcedure.PayToReinstate) {
      return true;
    } else {
      if (this.risk?.cancellationTypeCode === AutoReinstatementConstants.BillingReasonCode) {
        return postPaymentForm.get('balanceAmountCheckbox').value ? false : Boolean(this.requirementsMetDetails) && !this.isReinstatementRequirementsNotMet;
      }
      return true;
    }
  }

  portalSetReinstatementAndRequirementsMetProperty(paymentProcedure: PaymentProcedure, postPaymentForm: FormGroup, makePayment: MakePaymentDTO): boolean {
    if (makePayment.riskStatusCode !== StatusConstants.cancelled || (makePayment.riskStatusCode === StatusConstants.cancelled &&
      makePayment.cancellationTypeCode !== AutoReinstatementConstants.BillingReasonCode) || paymentProcedure !== PaymentProcedure.PayToReinstate) {
      return true;
    } else {
      if (makePayment.cancellationTypeCode === AutoReinstatementConstants.BillingReasonCode) {
        return postPaymentForm.get('balanceAmountCheckbox').value ? false : Boolean(this.requirementsMetDetails) && !this.isReinstatementRequirementsNotMet;
      }
      return true;
    }
  }

  subscribePostPaymentReinstatementCheckboxes(postPaymentForm: FormGroup, balance: number): void {
    if (this.billingData.isPolicyCancelled && this.isCancelledDueToBillingReason && !postPaymentForm.get('amount').disabled) {
      postPaymentForm.get('amount').disable();
      postPaymentForm.get('amount').markAsUntouched();
    }

    postPaymentForm.get('reinstateAmountCheckbox').valueChanges
      .subscribe(isChecked => {
        if (isChecked) {
          postPaymentForm.get('balanceAmountCheckbox').setValue(false);
          postPaymentForm.get('amount').updateValueAndValidity();
          postPaymentForm.get('amount').enable();
          if (!Boolean(this.requirementsMetDetails?.areRequirementsMetExceptPayment)) {
            this.notifyForRequirementsNotMet(
              AutoReinstatementConstants.warningMessage.reinstate,
              postPaymentForm, this.requirementsMetDetails.requiredAmount, true, true);
          } else {
            this.setDefaultAmountReinstatementProcess(postPaymentForm, this.requirementsMetDetails.requiredAmount);
          }
        } else if (!isChecked && !postPaymentForm.get('balanceAmountCheckbox').value) {
          postPaymentForm.get('amount').disable();
          postPaymentForm.get('amount').setValue(0);
          postPaymentForm.get('amount').markAsUntouched();
        }
      });

    postPaymentForm.get('balanceAmountCheckbox').valueChanges
      .subscribe(isChecked => {
        if (isChecked) {
          postPaymentForm.get('reinstateAmountCheckbox').setValue(false);
          postPaymentForm.get('amount').updateValueAndValidity();
          postPaymentForm.get('amount').enable();
          this.setDefaultAmountReinstatementProcess(postPaymentForm, balance);
        } else if (!isChecked && !postPaymentForm.get('reinstateAmountCheckbox').value) {
          postPaymentForm.get('amount').disable();
          postPaymentForm.get('amount').setValue(0);
          postPaymentForm.get('amount').markAsUntouched();
        }
      });
  }

  protected setDefaultAmountReinstatementProcess(postPaymentForm: FormGroup, minAmount: number): void {
    const includedRenewalStatus = [AutoReinstatementConstants.reinstateStatus.RRW, AutoReinstatementConstants.reinstateStatus.RWNT];
    const isRenewal = includedRenewalStatus.includes(this.risk?.renewalCode);
    const isPaybalance = postPaymentForm?.get('balanceAmountCheckbox').value;
    const policyValidators = [Validators.required, Validators.min(isPaybalance ? minAmount : this.getMinimumPaymentAmount(this.allowedPaymentRangeFromPostPayment?.minimumPaymentAmount)), Validators.max(this.allowedPaymentRangeFromPostPayment?.maximumPaymentAmount)];
    const RWNTValidators = [Validators.required, Validators.min(minAmount)];
    const RRWValidators = [Validators.required, Validators.min(minAmount), Validators.max(this.requirementsMetDetails?.areRequirementsMetExceptPayment ? this.allowedPaymentRangeFromPostPayment?.maximumPaymentAmount + this.billingData.renewalAmount : minAmount)];

    postPaymentForm.get('amount').setValidators(
      isRenewal ?
        (
          this.hasRescindedOffer ?
            RRWValidators : RWNTValidators
        )
        :
        policyValidators
    );
    postPaymentForm.get('amount').setValue(minAmount);
    postPaymentForm.get('amount').updateValueAndValidity();
  }

  setMinimumReinstateAmount(): number {
    const isPolicyReinstatement = this.risk?.riskStatusCode === StatusConstants.cancelled && this.risk?.cancellationTypeCode === AutoReinstatementConstants.BillingReasonCode;
    const isRenewalReinstatement = this.risk?.renewalCode === AutoReinstatementConstants.reinstateStatus.RWNT;
    if (isPolicyReinstatement) {
      return this.requirementsMetDetails.requiredAmount;
    } else if (isRenewalReinstatement) {
      return this.requirementsMetDetails.requiredAmount;
    } else {
      return 0;
    }
  }

  setIsReinstatingByMakeAPayment(isPayToReinstate: boolean, postPaymentForm: FormGroup): boolean {
    return isPayToReinstate ? (postPaymentForm.get('reinstateAmountCheckbox').value == null ? true : postPaymentForm.get('reinstateAmountCheckbox').value) : false;
  }

  notifyForRequirementsNotMet(message: string, form: FormGroup, amount: number, isPolicyReinstatement: boolean, isUpdateValueAndValidity: boolean): void {
    NotifUtils.showWarning(message, () => {
      if (isPolicyReinstatement) {
        if (isUpdateValueAndValidity) {
          this.setDefaultAmountReinstatementProcess(form, amount);
        } else {
          form.get('reinstateAmountCheckbox').setValue(false);
        }
      }
    });
  }

  get isPolicyCancelled(): boolean {
    return this.entityRiskData.getRisk().riskStatusCode === StatusConstants.cancelled;
  }

  setPaymentProcedureForRenewalReinstatement(isPaymentFromSubmission: boolean, paymentRequest: PaymentRequestDTO): Observable<any> {
    return this.getOtherReinstatementRequirements(true, this.getRenewalReinstatementPayload()).pipe(
      switchMap(otherDetails => {
        paymentRequest.isForReinstatementAndRequirementsMet = otherDetails.areRequirementsMetExceptPayment;
        return this.billingService.postPaymentRequest(paymentRequest);
      }));
  }

  protected isRenewalSubmission(): boolean {
    return Boolean(this.risk?.renewalFromRiskId) && !Boolean(this.risk?.riskDetails[0]?.isEndorsement) && this.risk?.renewalCode === AutoReinstatementConstants.reinstateStatus.RWNT;
  }

  get hasRescindedOffer(): boolean {
    return this.risk?.renewalCode === AutoReinstatementConstants.reinstateStatus.RRW;
  }

  getOtherReinstatementRequirements(isRenewalNotTaken: boolean, request: RenewalReinstatementApprovalDetailsRequestDTO | string, isFromDashBoardOrPortal: boolean = false): Observable<OtherApprovalRequirementsDTO> {
    if (!Boolean(request) && isRenewalNotTaken) {
      request = isFromDashBoardOrPortal ? this.getRenewalReinstatementPayloadForDashboardAndPortal() : this.getRenewalReinstatementPayload();
    }

    if (isRenewalNotTaken) {
      return this.billingService.getIsOtherRenewalRequirementsMet(<RenewalReinstatementApprovalDetailsRequestDTO>request);
    } else {
      return this.billingService.getIsOtherRequirementsMet(<string>request);
    }
  }

  checkIsReinstatementCheckboxNeededForDashboardAndPortal(postPaymentForm: FormGroup, makePaymentData: MakePaymentDTO): boolean {
    return this.reinstatementView != null && !postPaymentForm.get('reinstateAmountCheckbox').value && !postPaymentForm.get('balanceAmountCheckbox').value && !this.entityRiskData.isRenewalNotTaken && this.isPolicyCancelledForDashboardAndPortal(makePaymentData);
  }

  isPolicyCancelledForDashboardAndPortal(makePaymentData: MakePaymentDTO): boolean {
    return makePaymentData?.riskStatusCode === StatusConstants.cancelled;
  }

  getRenewalReinstatementPayloadForDashboardAndPortal(): RenewalReinstatementApprovalDetailsRequestDTO {
    return new RenewalReinstatementApprovalDetailsRequestDTO({
      renewalRiskId: this.billingData.makePaymentData.renewalRiskIdData,
        expiringRiskId: this.billingData.makePaymentData.riskIdData,
        paymentPlan: this.billingData.makePaymentData.renewalPayPlan
    });
  }

  setPaymentProcedureForRenewalReinstatementForDashboardAndPortal(paymentRequest: PaymentRequestDTO): Observable<any> {
    return this.getOtherReinstatementRequirements(true, this.getRenewalReinstatementPayloadForDashboardAndPortal(), true).pipe(
      switchMap(otherDetails => {
        paymentRequest.isForReinstatementAndRequirementsMet = otherDetails.areRequirementsMetExceptPayment;
        return this.billingService.postPaymentRequest(paymentRequest);
      }));
  }

  get isCancelledDueToBillingReason(): boolean {
    return Boolean(this.billingData.makePaymentData?.riskStatusCode) ?
      this.billingData.makePaymentData.cancellationTypeCode === AutoReinstatementConstants.BillingReasonCode :
      this.entityRiskData.getRisk().cancellationTypeCode === AutoReinstatementConstants.BillingReasonCode;
  }

  isPaymentForMinimumDueOnly(paymentProcedure: PaymentProcedure, minimumAmountDue: number, paymentAmount: number): boolean {
    const requiredAmountToReinstate = this.requirementsMetDetails?.requiredAmount;
    const isPaymentEnoughForMinimumDueOnly = paymentAmount >= minimumAmountDue && paymentAmount < requiredAmountToReinstate;
    return paymentProcedure === PaymentProcedure.PayToReinstate && isPaymentEnoughForMinimumDueOnly;
  }

  getMinimumPaymentAmount(minimumDueAmount: number): number {
    const isApproved = this.reinstatementView?.approvalDetails?.isApproved;
    const requiredAmountToReinstate = this.requirementsMetDetails?.requiredAmount;
    return isApproved ? requiredAmountToReinstate : minimumDueAmount;
  }

  recalculateAmountToReinstate(approvalExpirationDate: any, isForRenewal: boolean): void {
    if (isForRenewal) {
      // for RWNT submission UI
      const expiringPolicyRiskId = this.entityRiskData?.EntityRisk?.risks[0]?.renewalFromRiskId;
      const renewalTransactionDetails$ = this.billingService.getRenewalTransactionDetails(expiringPolicyRiskId);
      let renewalOfferRequestPayload: RenewalBillingSummaryRequestDTO;

      Utils.blockUI();
      renewalTransactionDetails$.pipe(
        tap(transactionDetailsPayload => {
          const payPlanPayload = this.entityRiskData.getRiskBindBillingPayPlan();
          const paymentPlan = this.LvPayPlanOptions.find(x => x.code === payPlanPayload)?.billingCode;
          renewalOfferRequestPayload = {
            ExpiringPolicyRiskId: expiringPolicyRiskId,
            RenewalPolicyRiskId: this.risk?.id,
            RenewalPolicyEffectiveDate: this.entityRiskData?.EntityRisk?.risks[0]?.renewalEffectiveDate,
            PaymentPlan: paymentPlan,
            TransactionDetails: transactionDetailsPayload,
            AppId: 'CENT'
          };
        }),
        switchMap(_ => this.billingService.recalculateAmountToReinstate(this.createRecalculateReinstatementAmountPayload(approvalExpirationDate, isForRenewal, renewalOfferRequestPayload))))
        .subscribe(recalculatedAmountToReinstate => {
          this.showOtherApprovalDetails$.next(true);
          this.setPaymentRequiredToReinstate(recalculatedAmountToReinstate);
          Utils.unblockUI();
        }, err => {
          Utils.unblockUI();
          NotifUtils.showError(JSON.stringify(err.error?.message) ?? 'Error in fetching recalculated amount to reinstate');
        });
    } else {
      // for Cancelled Policies w/o renewal
      const recalculateAmount$ = this.billingService.recalculateAmountToReinstate(this.createRecalculateReinstatementAmountPayload(approvalExpirationDate, isForRenewal));
      Utils.blockUI();

      recalculateAmount$.subscribe(recalculatedAmountToReinstate => {
          this.showOtherApprovalDetails$.next(true);
          this.setPaymentRequiredToReinstate(recalculatedAmountToReinstate);
          Utils.unblockUI();
        }, err => {
          Utils.unblockUI();
          NotifUtils.showError(JSON.stringify(err.error?.message) ?? 'Error in fetching recalculated amount to reinstate');
        });
    }
  }

  protected setPaymentRequiredToReinstate(recalculatedAmountToReinstate: number): void {
    if (recalculatedAmountToReinstate) {
      this.autoReinstatementForm.get('paymentRequired').setValue(recalculatedAmountToReinstate);
    }
  }

  protected createRecalculateReinstatementAmountPayload(approvalExpirationDate: any, isForRenewal: boolean, renewalOfferRequest: RenewalBillingSummaryRequestDTO = null): RecalculateReinstatementAmountRequestDTO {
    return new RecalculateReinstatementAmountRequestDTO({
      riskId: this.risk?.id,
      approvalExpirationDate: approvalExpirationDate,
      renewalOfferRequest: renewalOfferRequest,
      isForRenewal: isForRenewal
    });
  }

  isCancelledPolicyWithoutRenewal(): boolean {
    return this.risk?.riskStatusCode === StatusConstants.cancelled && this.risk?.cancellationTypeCode === AutoReinstatementConstants.BillingReasonCode;
  }

  isRWNT(): boolean {
    return this.entityRiskData?.isRenewalNotTaken;
  }

  computeRenewalReinstatementAmountWithoutBalance(currentTermAmount: number, defaultRenewalAmount: number): number {
    return this.reinstatementView?.isApprovalRequired && this.reinstatementView?.approvalDetails?.isApproved ? (this.requirementsMetDetails.requiredAmount - currentTermAmount) : defaultRenewalAmount;
  }
}
