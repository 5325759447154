/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { SessionTimeoutLabelsConstants } from '../../../shared/constants/session-timeout.constant';
import { Observable, Subscription, timer } from 'rxjs';
import { AuthService } from 'app/core/services/auth.service';
import Utils from '../../../shared/utilities/utils';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { PathConstants } from 'app/shared/constants/path.constants';
import { Subject } from 'rxjs/internal/Subject';
import { DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { DOCUMENT, LocationStrategy } from '@angular/common';
import { take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, fromEvent} from 'rxjs';
import { map, repeatWhen, switchMap,tap } from 'rxjs/operators';
import { TimeoutModalComponent } from '../timeout-modal/timeout-modal.component';
import swal, { SweetAlertOptions } from 'sweetalert2';
import { AuditLogService } from 'app/core/services/management/auditLog.service';
import { LoginLabelsConstants } from 'app/shared/constants/login.labels.constants';
import { ClientDetailService } from 'app/core/services/client-details/client-detail.service';


const counter = timer(0, 2000);
@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit {
  public clock;
  public SessionTimeoutLabelsConstants  = SessionTimeoutLabelsConstants;
  timer: number = 0;
  private authService: AuthService;
  public countDown: Observable<void>;
  private onDestroy$ = new Subject<void>();
  display = '';
  public intervalSub: Subscription;
  mySubscription: any;
  timerInfo;
  timeOut: Boolean = false;
  isSession: Boolean = false;
  bsModalRef: BsModalRef;
  subscription: Subscription;
private readonly _restartTimer = new Subject<void>();
private readonly _stopTimer = new Subject<void>();
timeoutModal;
  constructor(
    private modalRef: BsModalRef,
    private router: Router,
    private modalService: BsModalService,
    private location: LocationStrategy,
    private auditLogService: AuditLogService,
    private clientDetailService: ClientDetailService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(){
      this.startTimer();
  }



  startTimer() {
    this.authService.idle.clearInterrupts();
    history.pushState(null, null, window.location.href);//disabled back and forward button of browser
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
    this.timer = +localStorage.getItem('TriggerInactivityTimerSecs');
    let minutes;
    let seconds;
    let isIdle;

    this.intervalSub = Observable.interval(500)
    .takeUntil(this.onDestroy$)
    .subscribe(x => {
        const endTimeInfo = +localStorage.getItem('endTime');
        this.timer = (endTimeInfo - Date.now()) / 1000;
        minutes = Math.floor(this.timer / 60);
        seconds = Math.floor(this.timer % 60);

        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        this.display = `${ minutes }${ SessionTimeoutLabelsConstants.mins } ${ seconds } ${ SessionTimeoutLabelsConstants.secs}`;

        isIdle = localStorage.getItem('ng2Idle.main.idling');

        if(this.timer <= 0 ) {
          const clientDetails = this.clientDetailService.getClientDetails();
          const payload = {
            userId: +this.authService.getUserId(),
            keyId: localStorage.getItem('uname'),
            auditType: `${SessionTimeoutLabelsConstants.sessionAuditType}`,
            description: `User ${localStorage.getItem('uname')} logged out by the system due to inactivity.`,
            method: '',
            os: `${clientDetails.os} ${clientDetails.osVersion}`,
            deviceType: clientDetails.mobile ? 'Mobile' : 'PC',
            browser: clientDetails.browser,
            browserVersion: clientDetails.browserVersion
          };
          localStorage.clear();
          this.auditLogService.insertToAuditLog(payload).subscribe();
          this.authService.reset();
          this.authService.idle.watch();
          this.authService.setAuth(null);
          this.authService.setUserLoggedIn(false);
          localStorage.setItem('isUserLoggedIn','false');
          localStorage.removeItem('isUserLoggedIn');
          this.display  = `00 ${ SessionTimeoutLabelsConstants.mins } 00 ${ SessionTimeoutLabelsConstants.secs }`;
          this.intervalSub.unsubscribe();
          this.authService.idle.onTimeout.unsubscribe();
          window.localStorage.setItem('isSessionOpen', 'true');
        } else if (isIdle === 'false' && this.timer > 0) {
          this.closeDialog();
        }
    });

  }


  showUnauthorizedError(): void {
    const defaults: any = {
        title: 'Oops...',
        icon: ErrorMessageConstant.unAuthorizedError.error,
        allowOutsideClick: false
    };
    NotifUtils.showNotice(ErrorMessageConstant.unAuthorizedError.message, defaults, () => {
      this.modalRef.hide();
      this.authService.logout();
        this.router.navigate([`/${PathConstants.Login.Index}`]);
    });
  }

  ngOnDestroy(): void {
    this.intervalSub.unsubscribe();
    this.authService.idle.onTimeout.unsubscribe();
    this.onDestroy$.next();
    this.authService.reset();
  }

  removeMinSec(str){
    const searchMins00 = str.search('00 mins');
    let newString;
    if(searchMins00 === 0 ){
      newString = str.slice(7);
    }else{
      newString = str;
    }
    return newString;
  }

  checkIfTokenExists(){
    if(localStorage.getItem('auth')){
      return true;
    }
    return false;
  }

  redirectToLogin(){
    this.modalRef.hide();
    setTimeout(() => {
      swal.fire({
        title: 'Please wait',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        customClass: {
          container: 'session-swal'
        }
      }).then(()=>{
        Utils.unblockUI();
      });
      swal.showLoading();
      this.router.navigate(['/dashboard']);
      this.document.location.href = '/dashboard';
    },500);
  }

  closeDialog(){
    this.authService.idle.setInterrupts(this.authService.createCustomInterruptSources(null));
    this.authService.idle.watch();
    this.display = `00 ${ SessionTimeoutLabelsConstants.mins }  00 ${ SessionTimeoutLabelsConstants.secs}`;
    this.modalRef.hide();
    this.intervalSub.unsubscribe();
  }

  closeTimoutDialog(){
    window.localStorage.removeItem('isSessionOpen');
    this.modalRef.hide();
    setTimeout(() => {
      swal.fire({
        title: 'Please wait',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        customClass: {
          container: 'session-swal'
        }
      }).then(()=>{
        Utils.unblockUI();
      });
      swal.showLoading();
      this.document.location.href = '/dashboard';
    },500);

  }


};

