export class ApplicationInformationDTO implements IApplicationInformationDTO {
  firstName?: string | undefined;
  middleInitial?: string | undefined;
  lastName?: string | undefined;
  effectiveDate?: any;
  dateOfBirth?: any;
  streetAddress?: string | undefined;
  aptUnit?: string | undefined;
  city?: string | undefined;
  state?: string | undefined;
  zipCode?: string | undefined;
  retired?: boolean;
  isOrderCredit?: boolean;
  formType?: string;
  riskId?: string | undefined;
  isInternal?: boolean | undefined;
  subAgencyId?: string | undefined;
  riskDetailId?: string | undefined;
  quoteNumber?: string | undefined;

  public constructor(init?: Partial<ApplicationInformationDTO>) {
    Object.assign(this, init);
  }
}

export interface IApplicationInformationDTO {
  firstName?: string | undefined;
  middleInitial?: string | undefined;
  lastName?: string | undefined;
  effectiveDate?: any;
  dateOfBirth?: any;
  streetAddress?: string | undefined;
  aptUnit?: string | undefined;
  city?: string | undefined;
  state?: string | undefined;
  zipCode?: string | undefined;
  retired?: boolean;
  formType?: string;
  riskId?: string | undefined;
  isInternal?: boolean | undefined;
  subAgencyId?: string | undefined;
  quoteNumber?: string | undefined;
}
