import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { AuthService } from 'app/core/services/auth.service';

@Component({
  selector: 'app-footer-admin',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentAppVersion = formatDate(this.authService.getCustomDate(), 'MMddyyyy', 'en') + '-1';

  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

}
