import { IProcessedRaterView } from 'app/shared/models/rater-premium/processed-rater-view.model';
import { createAction, props } from '@ngrx/store';

export const updateRaterCalculateDP3IsLoadingFromDP3RaterRequestData = createAction(
  '[DP3RaterRequestData] Update Rater Calculate DP3 Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateRaterCalculateDP3ListFromDP3RaterRequestData = createAction(
  '[DP3RaterRequestData] Update Rater Calculate DP3 List',
  props<{ list: IProcessedRaterView[] }>()
);