import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { EntityRiskDTO } from '../../../shared/models/data/dto/quick-quote/entity-risk.dto';
import { BaseClass } from '../../../shared/base-class';
import * as endorsementsOptions from '../../../shared/constants/endorsements.options.constants';
import { CoveragesData, MAXCOVA, MINCOVA } from './coverages.data';
import { PropertyData } from './property.data';
import { PolicySummaryData } from '../../policy-management/data/policy-summary.data';
import { AuthService } from '../../../core/services/auth.service';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { EntityRiskData } from './entity-risk.data';
import FormUtils from '../../../shared/utilities/form.utils';
import { ApplicantData } from './applicant.data';
import { CustomCurrencyCheckingService } from '../../../shared/services/custom-currency-checking.service';
import { PremiumConstants } from '../../../shared/constants/bind-and-issue.labels.constants';
import { GenericConstants } from '../../../shared/constants/generic.constants';
import { LocationLabelConstants } from '../../../shared/constants/property.labels.constants';
import { EndorsementValidators } from '../validators/endorsements.validators';
import { GeneralValidationService } from '../../../core/services/submission/validations/general-validation.service';
import { QuoteService } from '../../../core/services/quote.service';
import { FormTypeConstants } from '../../../shared/constants/form-types.constants';
import { Rater } from '../../../shared/constants/rater.constant';
import { AddressType } from '../../../shared/enum/address-type.enum';
import { SummaryData } from './summary.data';
import { BehaviorSubject } from 'rxjs';
import { UwQuestionsData } from './uw-questions.data';
import { EndorsementsLabelsConstants } from '../../../shared/constants/endorsements.labels.constants';
import * as moment from 'moment';
import { RiskEndorsementDTO2 } from 'app/shared/models/data/dto/quick-quote/risk-endorsement.dto';

export const SINKHOLEDEDUCTIBLEVALUE = 0.1;
export const FLORIDA = 'FL';
export const WATERDAMAGEINCLUSIONYEARS = 40;
export const HO4WATERDAMAGEINCLUSIONYEARS = 50;
export const DP3WATERDAMAGEINCLUSIONYEARS = 30;
export const HO3ANDHO6LIMITEDWATERDAMAGE = 40;
export const DP3LIMITEDWATERDAMAGE = 30;

export const NBHO3DP3HO6WATERDAMAGEINCLUSIONYEAR = 15;

export const MINIMUMNUMBEROFMONTHS: number = 1;
export const MAXIMUMNUMBEROFMONTHS: number = 8;


@Injectable({
  providedIn: 'root',
})
export class EndorsementsData extends BaseClass {
  limitedfungiWetOrDryRotLimitsOptions: any[] = endorsementsOptions.LVRaterFungiIncreasedAmountCovLimit;
  limitedFungiLimitsOptions: any[] = endorsementsOptions.LvRaterLimitedFungiLimit;
  lossAssessmentLimitOptions: any[] = endorsementsOptions.LvRaterLossAssessmentLimit;
  screenedEnclosureLimitOptions: any[] = endorsementsOptions.LvRaterScreenedEnclosureLimit;
  screenedEnclosureCarportLimitOptions: any[] = endorsementsOptions.LvRaterScreenedEnclosureLimit;
  homeComputerLimitOptions: any[] = endorsementsOptions.LvRaterHomeComputerLimit;
  liabilityTypeOptions: any[] = endorsementsOptions.LvRaterGolfCartOption;
  jewelryIncreasedOptions: any[] = endorsementsOptions.LvJewelryWatchesAndFursIncreasedAmount;
  silverwareIncreasedOptions: any[] = endorsementsOptions.LvSilverwareGoldwarePewterwareIncreasedAmount;
  numberOfGolfCartOptions: any[] = endorsementsOptions.LvNumberOfGolfCartOptions;
  ordinanceLawOptions: any[] = endorsementsOptions.LvOrdinanceLawOptions;
  screenedEnclosureOptions: any[] = endorsementsOptions.LvScreenedEnclosureOptions;
  public endorsementsGroup: FormGroup;
  // Temporary , Will replace as HO4 and HO6 functionalities continues. for UI purposes only.
  public leaseTermsHO6: any = [ { text: 'Short Term' , value:'Short Term' }, { text: 'Long Term' , value: 'Long Term' } ];
  public locationLabelConstants = LocationLabelConstants;
  public currentPremiumAdjustmentAmount: number;
  public currentConsentToRateAmount: number;
  public currentPersonalPropertyAtOtherResidencesLimit: number;
  formTypeConstants = FormTypeConstants;
  isStarPackage: boolean = false;
  isStarPackageChanged: boolean = false;
  public isLimitedTheftCoverageAvailable: boolean = false;
  public windStormExteriorShow: boolean = false;
  public windStormExteriorTerritories: string[] = ['50', '70', '100', '180', '181', '181A', '181B', '181C', '181D', '182', '182A', '182B', '310', '320', '340', '350', '360', '361', '370', '380',  '381'];
  public isPremisesLiabilityDisabled = new BehaviorSubject<boolean>(false);
  public isPremisesLiabilityDisabled$ = this.isPremisesLiabilityDisabled.asObservable();
  isStarAdvantagePackage: boolean = false;

  public isInternal: boolean = false;

  public currentLimitedWaterDmg: boolean = null;

  protected isPersonalPropertyDisabled = new BehaviorSubject<boolean>(true);
  public isPersonalPropertyDisabled$ = this.isPersonalPropertyDisabled.asObservable();
  protected isScreenEnclosureDisabled = new BehaviorSubject<boolean>(true);
  public isScreenEnclosureDisabled$ = this.isScreenEnclosureDisabled.asObservable();

  public isWindStormExteriorDisabled = new BehaviorSubject<boolean>(false);
  public isWindStormExteriorDisabled$ = this.isWindStormExteriorDisabled.asObservable();
  public isWindStormExteriorVisible = new BehaviorSubject<boolean>(false);
  public isWindStormExteriorVisible$ = this.isWindStormExteriorVisible.asObservable();

  public manualToggleValueForPersonalLiability = new BehaviorSubject<boolean>(false);
  public manualToggleValueForPersonalLiability$ = this.manualToggleValueForPersonalLiability.asObservable();

  public manualToggleValueForPremiseslLiability = new BehaviorSubject<boolean>(false);
  public manualToggleValueForPremisesLiability$ = this.manualToggleValueForPremiseslLiability.asObservable();

  public isHurrDeductibleExcluded = new BehaviorSubject<boolean>(false);
  public isHurrDeductibleExcluded$ = this.isHurrDeductibleExcluded.asObservable();

  protected previousCovE: string;
  endorsementsLabelsConstants = EndorsementsLabelsConstants;
  public waterDamageDefaultState: boolean;
  public waterDamageValue: boolean;
  public limitedWaterDefaultState: boolean;
  public uwr130Status: boolean = false;

  public brNew110WDEInfoMessageStatus: boolean = false;
  public brNew110LWDInfoMessageStatus: boolean = false;

  public isJewelryAndSilverwareBothZero: boolean = false;

  endorsementsRetrievalData: RiskEndorsementDTO2;
  isPermittedIncidentalOccupanciesChanged: boolean = false;
  roofSurfacingPaymentScheduleChanged: boolean = false;
  showRSPSInfoMessage: boolean = true;

  countyCovered: any = {
    broward: 'Broward',
    palmBeach: 'Palm Beach',
    stLucie: 'St Lucie',
    miamiDade: 'Miami-Dade',
  };

  triCountyAndStLucie = ['broward', 'palm beach', 'miami-dade', 'st lucie'];

  effectiveDateStarPackage: boolean = false;
  realtimeStarPackage: boolean;
  realtimeStarAdvPackage: boolean;

  public isWindStormExteriorVisibleIncluded(territoryCode: string): boolean {
    return this.quoteService.formType === this.formTypeConstants.DP3 && this.windStormExteriorTerritories.filter(x => x === territoryCode?.toString().trim()).length > 0;
  }

  get IsPolicyOrRenewal(): boolean {
    return this.policySummaryData.isPolicy || this.policySummaryData.renewalStatusCode !== null;
  }

  getBRULNew110Date(): Date {
    return new Date(this.authService.LoginDateFormGroup.get('New110')?.value ?? localStorage.getItem('New110'));
  }

  getPolicyEffectiveDate(): Date {
    let effectiveDate = new Date(this.entityRiskData.EntityRisk?.risks[0]?.firstIssueDate);
    if (this.policySummaryData.renewalStatusCode !== null) {
      effectiveDate =  new Date(this.entityRiskData.expiringPolicyEntityRisk?.risks[0]?.firstIssueDate);
    }
    return effectiveDate;
  }

  getWDEYearInclusion(): number {
    return this.isBRNew110Apply() ?  this.GetNewBusinessWaterDamageExclusion() : this.GetWaterDamageInclusion();
  }

  isBRNew110Apply(): boolean {
    const brNew110Apply = this.IsPolicyOrRenewal ? (this.getPolicyEffectiveDate() >= this.getBRULNew110Date()) : true;
    return brNew110Apply &&
            (this.quoteService.formType === this.formTypeConstants.HO3 ||
            this.quoteService.formType === this.formTypeConstants.HO6 ||
            this.quoteService.formType === this.formTypeConstants.DP3);
  }

  constructor(
    public coveragesData: CoveragesData,
    protected formBuilder: FormBuilder,
    public propertyData: PropertyData,
    public policySummaryData: PolicySummaryData,
    public authService: AuthService,
    public entityRiskData: EntityRiskData,
    public applicantData: ApplicantData,
    public currencyChecking: CustomCurrencyCheckingService,
    protected generalValidationService: GeneralValidationService,
    public quoteService: QuoteService,
    protected summaryData: SummaryData) {
    super();
    this.subscribeUserType();
  }

  initForms(): void {
    this.initParentFormControls();
    this.initChildFormControls();
    this.subscribeToRoofSurfacingPaymentSchedule();
  }

  initParentFormControls(): void {
    this.endorsementsGroup = this.formBuilder.group({
      limitedFungi: new FormControl({ value: true, disabled: this.isInternal ? false : true }, []),
      personalProperty: new FormControl({ value: this.quoteService.formType === this.formTypeConstants.HO3 ||this.quoteService.formType === this.formTypeConstants.HO6 ?
        true : false, disabled: false }, []),
      waterBackup: new FormControl(false, []),
      limitedWater: new FormControl({ value: false, disabled:  this.limitedWaterDefaultState ? true : false }, []),
      ordinanceLawOrCov: new FormControl(this.quoteService.formType === this.formTypeConstants.HO3 ? true : false, []),
      lossAssessment: new FormControl(false, []),
      screenedEnclosure: new FormControl('N', []),
      personalInjury: new FormControl(false, []),
      equipmentBreakdown: new FormControl(this.quoteService.formType !== this.formTypeConstants.HO4 ? true : false, [], []),
      identityFraud: new FormControl(this.quoteService.formType !== this.formTypeConstants.DP3 ? true : false, []),
      starPackage: new FormControl(false, []),
      starAdvantagePackage: new FormControl(false, []),
      waterDamage: new FormControl({ value: false, disabled: this.waterDamageDefaultState ? true : false }, []),
      animalLiability: new FormControl(false, []),
      homeComputer: new FormControl(false, []),
      serviceLine: new FormControl(false, []),
      golfCart: new FormControl(false, []),
      increasedReplacement: new FormControl(false, []),
      specialPersonal: new FormControl(false, []),
      coverageC: new FormControl({ value: false, disabled: true }, []),
      premisesAlarm: new FormControl({ value: false, disabled: this.isInternal ? false : true }, []),
      sinkholeLoss: new FormControl(false, []),
      seasonalDwelling: new FormControl({ value: false, disabled: this.isInternal ? false : true }, []),
      premiumAdjustment: new FormControl(false, []),
      consentToRate: new FormControl(false, []),
      unitOwnersCoverageASpecialCoverage: new FormControl({value: this.quoteService.formType === this.formTypeConstants.HO6 ? true : false, disabled: this.isInternal ? false : true }, []),
      unitOwnersRentalToOthers: new FormControl({value: false, disabled: this.isInternal ? false : true }, []),
      personalPropertyAtOtherResidences: new FormControl(false, []),
      fungiWetOrDryRot: new FormControl({ value: false, disabled: false }, []),
      personalLiability: new FormControl(false,[]),
      premisesLiability: new FormControl({ value: false, disabled: false },[]),
      permittedIncidentalOccupancies: new FormControl(false,[]),
      permittedIncidentalOccupanciesLiability: new FormControl(false,[]),
      limitedTheftCoverage: new FormControl(false,[]),
      vacancyEndorsement: new FormControl(false,[]),
      windstormExteriorPaintoOrWaterproofingExclusionSeacoastFlorida: new FormControl({value: false, disabled: this.isInternal ? false : true },[]),
      roofSurfacingPaymentSchedule: new FormControl(false, [])
    });


  }

  initChildFormControls(): void {

    this.endorsementsGroup.addControl(
      'fungiWetOrDryRotLimit',
      new FormControl('', [])
    );

    this.endorsementsGroup.addControl(
      'descriptionOfOccupancy',
      new FormControl('', [])
    );

    this.endorsementsGroup.addControl(
      'descriptionOfBusiness',
      new FormControl('', [])
    );

    this.endorsementsGroup.addControl(
      'businessLocation',
      new FormControl('', [])
    );

    this.endorsementsGroup.addControl(
        'leaseTerm',
        new FormControl('', []),
    );
    this.endorsementsGroup.addControl(
      'limitedFungiLimit',
      new FormControl(10, [])
    );
    this.endorsementsGroup.addControl(
      'waterBackupLimit',
      new FormControl('$5,000', [])
    );
    this.endorsementsGroup.addControl(
      'waterBackupDeductible',
      new FormControl('$1,000', [])
    );
    this.endorsementsGroup.addControl(
      'limitedWaterLimit',
      new FormControl({value: 10000, disabled: (this.policySummaryData.isPolicy || this.isInternal) ? false : true }, [])
    );
    this.endorsementsGroup.addControl(
      'ordinanceLaw',
      new FormControl(this.quoteService.formType !== this.formTypeConstants.HO4 ? '25' : '', [])
    );
    this.endorsementsGroup.addControl(
      'lossAssessmentLimit',
      new FormControl('', [])
    );
    this.endorsementsGroup.addControl(
      'screenedEnclosureLimit',
      new FormControl('', [])
    );
    this.endorsementsGroup.addControl(
      'animalLiabilityLimit',
      new FormControl('$50,000', [])
    );
    this.endorsementsGroup.addControl(
      'homeComputerLimit',
      new FormControl('', [])
    );
    this.endorsementsGroup.addControl(
      'serviceLineLimit',
      new FormControl('$10,000', [])
    );
    this.endorsementsGroup.addControl(
      'serviceLineDeductible',
      new FormControl('$500', [])
    );
    this.endorsementsGroup.addControl(
      'numberOfGolfCarts',
      new FormControl('', [])
    );
    this.endorsementsGroup.addControl(
      'liabilityType',
      new FormControl('', [])
    );
    this.endorsementsGroup.addControl(
      'jewelryIncreased',
      new FormControl('', [])
    );
    this.endorsementsGroup.addControl(
      'jewelryTotal',
      new FormControl(null, [])
    );
    this.endorsementsGroup.addControl(
      'silverwareIncreased',
      new FormControl('', [])
    );
    this.endorsementsGroup.addControl(
      'silverwareTotal',
      new FormControl(null, [])
    );
    this.endorsementsGroup.addControl(
      'premiumAdjustmentAmount',
      new FormControl(null, [])
    );
    this.currentPremiumAdjustmentAmount = null;
    this.endorsementsGroup.addControl(
      'consentToRateAmount',
      new FormControl(null, [])
    );
    this.currentConsentToRateAmount = null;
    this.endorsementsGroup.addControl(
      'sinkholeDeductible',
      new FormControl(0, [])
    );
    this.endorsementsGroup.addControl(
      'personalPropertyAtOtherResidencesLimit',
      new FormControl(null, [])
    );
    this.currentPersonalPropertyAtOtherResidencesLimit = null;
  }

  subscribeToRoofSurfacingPaymentSchedule(): void {
    this.endorsementsGroup.controls['roofSurfacingPaymentSchedule'].valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.stop$))
      .subscribe(() => {
        this.roofSurfacingPaymentScheduleChanged = this.policySummaryData.isEditPolicy;
    });
  }

  public populateEndorsementsPage(data: EntityRiskDTO) {
    this.endorsementsRetrievalData = data.risks[0].riskDetails[0].riskEndorsements[0];
    this.endorsementBusinessRules();
    const riskEndorsements = data?.risks[0]?.riskDetails[0]?.riskEndorsements;
    const riskProperties = data?.risks[0]?.riskDetails[0]?.riskProperties;
    this.applicantData.defaultState = data?.entityAddresses.find(a => a.addressTypeCode === AddressType.Property).address.state;

    if (riskEndorsements.length !== 0 && riskEndorsements[0]) {

      //If Cov C is Excluded , Meaning 0
      if (data?.risks[0]?.riskDetails[0]?.riskCoverages[0]?.covC?.toString()?.trim() === '0' && (this.quoteService.formType === FormTypeConstants.HO3)) {
       this.isPersonalPropertyDisabled.next(true);
      } else {
       this.isPersonalPropertyDisabled.next(false);
      }

      this.endorsementsGroup.get('limitedFungi').setValue(riskEndorsements[0]?.isLimitedFungiWet);

      this.checkIsLimitedFungiWet(riskEndorsements[0]);

      this.endorsementsGroup.get('waterBackup').setValue(riskEndorsements[0]?.isWaterBackUpSump);
      // Might use for future code adjustment.
      // if (riskEndorsements[0]?.isWaterBackUpSump) {
      //   this.endorsementsGroup.get('waterBackupLimit').setValue(riskEndorsements[0]?.waterBackUpSumpLimit);
      //   this.endorsementsGroup.get('waterBackupDeductible').setValue(riskEndorsements[0]?.waterBackUpSumpDeductible);
      // }

      this.endorsementsGroup.get('limitedWater').setValue(riskEndorsements[0]?.isLimitedWaterDamage);
      this.currentLimitedWaterDmg = riskEndorsements[0]?.isLimitedWaterDamage;

      if (riskEndorsements[0]?.isLimitedWaterDamage) {
        if (riskEndorsements[0]?.limitedWaterDamageLimit) {
          this.endorsementsGroup.get('limitedWaterLimit').setValue(riskEndorsements[0]?.limitedWaterDamageLimit);
        } else {
          this.endorsementsGroup.get('limitedWaterLimit').setValue(this.endorsementsLabelsConstants.limitedWaterLimitAmount);
        }
      }

      this.endorsementsGroup.get('ordinanceLawOrCov').setValue(riskEndorsements[0]?.isOrdinanceLaw);
      this.endorsementsGroup.get('ordinanceLaw').setValue(riskEndorsements[0]?.ordinanceLaw ?? '');

      this.endorsementsGroup.get('waterDamage').setValue(riskEndorsements[0]?.isWaterDamageExclusion);

      this.endorsementsGroup.get('personalProperty').setValue(riskEndorsements[0]?.isPersonalProRepCost);

      this.endorsementsGroup.get('personalInjury').setValue(riskEndorsements[0]?.isPersonalInjury);

      this.endorsementsGroup.get('equipmentBreakdown').setValue(riskEndorsements[0]?.isEquipBreakdownEnEndr);

      this.endorsementsGroup.get('identityFraud').setValue(riskEndorsements[0]?.isIdentityFraudExCov);

      // if (riskEndorsements[0]?.isStarPackage){
      //   this.endorsementsGroup.get('starPackage').enable();
      // }
      this.endorsementsGroup.get('starPackage').setValue(riskEndorsements[0]?.isStarPackage);

      // if (riskEndorsements[0]?.isStarAdvantagePackage){
      //   this.endorsementsGroup.get('starAdvantagePackage').enable();
      // }
      this.endorsementsGroup.get('starAdvantagePackage').setValue(riskEndorsements[0]?.isStarAdvantagePackage);

      this.endorsementsGroup.get('lossAssessment').setValue(riskEndorsements[0]?.isLossAssessCov);
      if (riskEndorsements[0]?.isLossAssessCov) {
        this.endorsementsGroup.get('lossAssessmentLimit').setValue(this.lossAssessmentLimitOptions.find(x => x.code === Number(riskEndorsements[0]?.lossAssessCovCode))?.code ?? '');
      }

      this.endorsementsGroup.get('screenedEnclosure').setValue(riskEndorsements[0]?.screenedEnclosure ?? 'N');
      this.endorsementsGroup.get('screenedEnclosureLimit').setValue(riskEndorsements[0]?.screenedEnclosureLimit ?? '');

      this.endorsementsGroup.get('animalLiability').setValue(riskEndorsements[0]?.isAnimalLiabilityEndr);
      // Might use for future code adjustment.
      // if (riskEndorsements[0]?.isAnimalLiabilityEndr) {
      //   this.endorsementsGroup.get('animalLiabilityLimit').setValue(riskEndorsements[0]?.animalLiabilityEndrLimit);
      // }

      this.endorsementsGroup.get('homeComputer').setValue(riskEndorsements[0]?.isHomeComputerCov);
      if (riskEndorsements[0]?.isHomeComputerCov) {
        this.endorsementsGroup.get('homeComputerLimit').setValue(this.homeComputerLimitOptions.find(x => x.code === Number(riskEndorsements[0]?.homeComputerCovLimitCode))?.code ?? '');
      }

      this.endorsementsGroup.get('serviceLine').setValue(riskEndorsements[0]?.isServiceLineEnEndr);
      // Might use for future code adjustment.
      // if (riskEndorsements[0]?.isServiceLineEnEndr) {
      //   this.endorsementsGroup.get('serviceLineLimit').setValue(riskEndorsements[0]?.serviceLineEnEndrLimit);
      //   this.endorsementsGroup.get('serviceLineDeductible').setValue(riskEndorsements[0]?.serviceLineEnEndrDeductible);
      // }

      this.endorsementsGroup.get('golfCart').setValue(riskEndorsements[0]?.isGolfCartPhyDamLiaEndr);
      if (riskEndorsements[0]?.isGolfCartPhyDamLiaEndr) {
        this.endorsementsGroup.get('numberOfGolfCarts').setValue(riskEndorsements[0]?.numberOfGolfCarts ?? '');
        this.endorsementsGroup.get('liabilityType').setValue(this.liabilityTypeOptions.find(x => x.code === riskEndorsements[0]?.golfCartLiabilityTypeCode)?.code ?? '');
      }

      this.endorsementsGroup.get('coverageC').setValue(riskEndorsements[0]?.isCovCIncSpecialLimitOfLiability);
      if (riskEndorsements[0]?.isCovCIncSpecialLimitOfLiability) {
        this.endorsementsGroup.get('jewelryIncreased').setValue(this.jewelryIncreasedOptions.find(x =>
          x.code === (riskEndorsements[0]?.jewWatFursIncreasedAmtCode !== null ? Number(riskEndorsements[0]?.jewWatFursIncreasedAmtCode) : null))?.code ?? '');
        this.endorsementsGroup.get('jewelryTotal').setValue(riskEndorsements[0]?.jewWatFursIncreasedTotalAmt ?? '');
        this.endorsementsGroup.get('silverwareIncreased').setValue(this.silverwareIncreasedOptions.find(x =>
          x.code === (riskEndorsements[0]?.silGoldPewIncreasedAmtCode !== null ? Number(riskEndorsements[0]?.silGoldPewIncreasedAmtCode) : null))?.code ?? '');
        this.endorsementsGroup.get('silverwareTotal').setValue(riskEndorsements[0]?.silGoldPewTotalAmt ?? '');
      }

      this.endorsementsGroup.get('increasedReplacement').setValue(riskEndorsements[0]?.isIncreaseRepCostDwelling);

      this.endorsementsGroup.get('specialPersonal').setValue(riskEndorsements[0]?.isSpecialPersonalProCov);

      this.endorsementsGroup.get('premisesAlarm').setValue(riskEndorsements[0]?.isPremisesAlarmFireProcSys);

      this.endorsementsGroup.get('sinkholeLoss').setValue(riskEndorsements[0]?.isSinkHoleLossCov);
      this.populateSinkholeDeductible(riskEndorsements[0]?.isSinkHoleLossCov);

      const isPremiumAdjustment = riskEndorsements[0]?.isPremiumAdjustment;
      this.endorsementsGroup.get('premiumAdjustment').setValue(isPremiumAdjustment);
      if (isPremiumAdjustment) {
        const premiumAdjustmentAmount = riskEndorsements[0]?.premiumAdjustmentAmount;
        this.endorsementsGroup.get('premiumAdjustmentAmount').setValue(premiumAdjustmentAmount);
      }
      this.currentPremiumAdjustmentAmount = riskEndorsements[0]?.premiumAdjustmentAmount;

      this.endorsementsGroup.get('consentToRate').setValue(riskEndorsements[0]?.isConsentToRate);
      if (riskEndorsements[0]?.isConsentToRate) {
        this.endorsementsGroup.get('consentToRateAmount').setValue(riskEndorsements[0]?.consentToRateAmount);
      }
      this.currentConsentToRateAmount = riskEndorsements[0]?.consentToRateAmount;

      this.endorsementsGroup.get('seasonalDwelling').setValue(riskEndorsements[0]?.isSeasonalDwellingEndr);
      this.endorsementsGroup.get('roofSurfacingPaymentSchedule').setValue(riskEndorsements[0]?.isRoofSurfacingPaymentSchedule);

      this.endorsementsGroup.get('unitOwnersCoverageASpecialCoverage').setValue(riskEndorsements[0]?.isUnitOwnersCoverageA);
      this.endorsementsGroup.get('unitOwnersRentalToOthers').setValue(riskEndorsements[0]?.isUnitOwnersRentalToOthers);

      const isUnitOwnersRentalToOthers = riskEndorsements[0]?.isUnitOwnersRentalToOthers;
      if (this.quoteService.formType === this.formTypeConstants.HO6) {
        if (isUnitOwnersRentalToOthers) {
          this.endorsementsGroup.get('leaseTerm').setValue(riskEndorsements[0]?.leaseTerm ?? '');
        }

      }

      this.endorsementsGroup.get('personalPropertyAtOtherResidences').setValue(riskEndorsements[0]?.isPersonalPropertyAtOtherResidences);
      this.endorsementsGroup.get('personalPropertyAtOtherResidencesLimit').setValue(riskEndorsements[0]?.personalPropertyAtOtherResidencesLimit);
      this.currentPersonalPropertyAtOtherResidencesLimit = riskEndorsements[0]?.personalPropertyAtOtherResidencesLimit;

      //DP3
      this.endorsementsGroup.get('fungiWetOrDryRot').setValue(riskEndorsements[0]?.isLimitedFungiWetIncreasedAmount ?? false);
      this.endorsementsGroup.get('fungiWetOrDryRotLimit')?.setValue(riskEndorsements[0]?.limitedFungiWetIncreasedAmountLimitCode ?? '');
      this.endorsementsGroup.get('personalLiability').setValue(riskEndorsements[0]?.isPersonalLiability);
      this.endorsementsGroup.get('premisesLiability').setValue(riskEndorsements[0]?.isPremisesLiability);
      this.endorsementsGroup.get('permittedIncidentalOccupancies').setValue(riskEndorsements[0]?.isPermittedIncidentalOccupancies);
      this.endorsementsGroup.get('descriptionOfOccupancy').setValue(riskEndorsements[0]?.occupancyDescription);
      this.endorsementsGroup.get('permittedIncidentalOccupanciesLiability').setValue(riskEndorsements[0]?.isPermittedIncidentalOccupanciesLiability);
      this.endorsementsGroup.get('descriptionOfBusiness').setValue(riskEndorsements[0]?.businessDescription);
      this.endorsementsGroup.get('businessLocation').setValue(riskEndorsements[0]?.businessLocation);
      this.endorsementsGroup.get('limitedTheftCoverage').setValue(riskEndorsements[0]?.isLimitedTheftCoverage);
      this.isLimitedTheftCoverageAvailable = riskEndorsements[0]?.isLimitedTheftCoverage ?? false;
      this.endorsementsGroup.get('vacancyEndorsement').setValue(riskEndorsements[0]?.isVacancyEndorsement);
      this.endorsementsGroup.get('windstormExteriorPaintoOrWaterproofingExclusionSeacoastFlorida').setValue(riskEndorsements[0]?.isWindstormExteriorPaint);

      if (this.isPremisesLiabilityMandatory(data) && !this.isInternal) {
         this.isPremisesLiabilityDisabled.next(true);
      } else {
         this.isPremisesLiabilityDisabled.next(false);
      }

      if (this.quoteService.formType === this.formTypeConstants.DP3) {
        const isResidenceRented = riskProperties[0]?.isTheResidenceRented ?? false;
        const propertyUsage = riskProperties[0]?.propertyUsageCode ?? '';
        this.isLimitedTheftCoverageAvailable = this.showLimitedTheftCoverageToggle(true, isResidenceRented, propertyUsage);
      }

    //HO6 BRUL
    this.previousCovE = '';
    this.previousCovE = data?.risks[0]?.riskDetails[0]?.riskCoverages[0]?.covE ? data?.risks[0]?.riskDetails[0]?.riskCoverages[0]?.covE: this.coveragesData.coveragesAndDeductiblesForm.get('coverageEId').value;
    if (!this.previousCovE) {
      this.setMaximumLiability();
    }

      if (this.isWindStormExteriorVisibleIncluded(data?.risks[0]?.riskDetails[0]?.riskProperties[0]?.territoryCode?.toString()?.toUpperCase()?.trim())) {
        this.isWindStormExteriorVisible.next(true);
      } else {
        this.isWindStormExteriorVisible.next(false);
      }

      if (data?.risks[0]?.riskDetails[0]?.riskCoverages[0]?.coverageL?.toString()?.toUpperCase()?.trim() !== '0') {
        this.endorsementsGroup.get('personalLiability').setValue(true);
      }

      if (this.isExcludedHurricaneDeductible(data) && !this.isInternal) {
        this.isScreenEnclosureDisabled.next(true);
      } else {
        this.isScreenEnclosureDisabled.next(false);
      }

    }

    FormUtils.markFormControlAsTouched(this.endorsementsGroup);
    if (this.isWindStormExteriorVisible.value && this.isInternal) {
      this.endorsementsGroup.get('windstormExteriorPaintoOrWaterproofingExclusionSeacoastFlorida').markAsDirty();
    }
  }

  protected isExcludedHurricaneDeductible(data: EntityRiskDTO): boolean {
    return data?.risks[0]?.riskDetails[0]?.riskCoverages[0]?.hurricaneDeductible.toString().toUpperCase().trim() === 'E';
  }

  protected isPremisesLiabilityMandatory(data: EntityRiskDTO): boolean {
    return  Number(data?.risks[0]?.riskDetails[0]?.riskProperties[0]?.numberMonthOccupied) >= MINIMUMNUMBEROFMONTHS &&
    Number(data?.risks[0]?.riskDetails[0]?.riskProperties[0]?.numberMonthOccupied) <= MAXIMUMNUMBEROFMONTHS && data?.risks[0]?.riskDetails[0]?.riskCoverages[0]?.coverageL?.toString()?.trim() !== '0' &&
    (data?.risks[0]?.riskDetails[0]?.riskProperties[0]?.propertyUsageCode !== LocationLabelConstants.ownerOccupiedPrimary && data?.risks[0]?.riskDetails[0]?.riskProperties[0]?.propertyUsageCode !== LocationLabelConstants.ownerOccupiedSecondary);
  }

  getEndorsementsForm() {
    return {
      endorsementGroup: this.endorsementsGroup
    };
  }

  get CoveragesAndDeductiblesForm(): FormGroup {
    return this.coveragesData.coveragesAndDeductiblesForm;
  }

  get PropertyLocationForm(): FormGroup {
    return this.propertyData.locationDetails;
  }

  get DiscountsForm(): FormGroup {
    return this.coveragesData.discountsForm;
  }

  setEndorsementPageFormStatus(status: boolean): void {
    FormUtils.setFormGroupStatus(this.endorsementsGroup, status);
    if (status) {
      if (this.isWindStormExteriorVisible.value && this.isInternal) {
        this.endorsementsGroup.get('windstormExteriorPaintoOrWaterproofingExclusionSeacoastFlorida').markAsDirty();
      }
      this.endorsementBusinessRules();
    }
    if (this.isInternal){
      this.enableFormControl('limitedFungi');
      this.enableFormControl('premisesAlarm');
      this.enableControl('limitedWater');
      this.enableControl('limitedWaterLimit');
      this.enableControl('descriptionOfBusiness');
      this.enableControl('businessLocation');
      this.enableControl('descriptionOfOccupancy');
      this.enableControl('windstormExteriorPaintoOrWaterproofingExclusionSeacoastFlorida');
    } else {
      this.disableFormControl('limitedFungi');
      this.disableFormControl('premisesAlarm');
      this.disableFormControl('limitedWater');
      if (this.policySummaryData.isPolicy) {
        this.enableControl('limitedWaterLimit');
      } else {
        this.disableFormControl('limitedWaterLimit');
      }
      this.disableFormControl('descriptionOfBusiness');
      this.disableFormControl('businessLocation');
      this.disableFormControl('descriptionOfOccupancy');
      this.disableFormControl('windstormExteriorPaintoOrWaterproofingExclusionSeacoastFlorida');
    }

    if (!status) {
      this.disableFormControl('waterBackup');
      this.disableFormControl('ordinanceLaw');
      this.disableFormControl('ordinanceLawOrCov');
      this.disableFormControl('personalInjury');
      this.disableFormControl('personalProperty');
      this.disableFormControl('lossAssessment');
    }
  }

  disableFormControl(formControlName: string, value?: any): void {
    this.endorsementsGroup.controls[formControlName].disable();

    if (value !== undefined) {
      this.endorsementsGroup.get(formControlName).patchValue(value);
    }
  }

  enableControl(formControlName: string, value?: any): void {
    this.endorsementsGroup.controls[formControlName].enable();

    if (value !== undefined) {
      this.endorsementsGroup.get(formControlName).patchValue(value);
    }
  }

  enableFormControl(formControlName: string, value?: any): void {
    this.endorsementsGroup.controls[formControlName].enable();
    const patchValue = this.endorsementsGroup.get(formControlName).value;
    this.endorsementsGroup.get(formControlName).patchValue(patchValue);
  }

  endorsementBusinessRules(checkHurricaneDeductibleid: boolean = true): void {
    const parentStarAdvantagePackage = this.entityRiskData?.ParentBackUpRiskData?.riskEndorsements[0]?.isStarAdvantagePackage;
    const parentStarPackage = this.entityRiskData?.ParentBackUpRiskData?.riskEndorsements[0]?.isStarPackage;

    const currentStarAdvantagePackage = this.entityRiskData?.getRisk().riskDetails[0]?.riskEndorsements[0]?.isStarAdvantagePackage;
    const currentStarPackage = this.entityRiskData?.getRisk().riskDetails[0].riskEndorsements[0]?.isStarPackage;

    this.checkCoverageA();
    this.disableSPP();
    this.initWatchers();
    this.setWDEOnLoad();
    if (this.starPackageAndAdvantageChecker()) {
      this.packageSelected();
    }
    if (!this.policySummaryData.isPolicy) {
      this.packageSelected();
    }
    this.checkCoverageCLimitId();

    if (checkHurricaneDeductibleid) {
      this.checkHurricaneDeductibleid();
    }

    this.setUnitOwners();
  }

  starPackageAndAdvantageChecker(): boolean {
    const parentStarAdvantagePackage = this.entityRiskData?.ParentBackUpRiskData?.riskEndorsements[0]?.isStarAdvantagePackage;
    const parentStarPackage = this.entityRiskData?.ParentBackUpRiskData?.riskEndorsements[0]?.isStarPackage;

    const currentStarAdvantagePackage = this.entityRiskData?.getRisk().riskDetails[0]?.riskEndorsements[0]?.isStarAdvantagePackage;
    const currentStarPackage = this.entityRiskData?.getRisk().riskDetails[0].riskEndorsements[0]?.isStarPackage;

    return (parentStarAdvantagePackage !== this.realtimeStarAdvPackage || parentStarPackage !== this.realtimeStarPackage) ||
    (currentStarAdvantagePackage !== this.realtimeStarAdvPackage || currentStarPackage !== this.realtimeStarPackage);
  }

  setUnitOwners(): void {
    if (this.summaryData.SummaryForm.get('formType')?.value === 'HO6') {
      if(this.isInternal) {
        this.enableControl('unitOwnersCoverageASpecialCoverage');
        // this.enableControl('unitOwnersRentalToOthers');
      } else {
        this.disableFormControl('unitOwnersCoverageASpecialCoverage');
        this.disableFormControl('unitOwnersRentalToOthers');
      }
    }
  }

  setUnitOwnersValue(): void {
    if (this.summaryData.SummaryForm.get('formType')?.value === 'HO6') {
      const propertyUsage = this?.propertyData?.locationDetails?.get('propertyUsage')?.value?.toString()?.toUpperCase()?.trim();

      if (propertyUsage === LocationLabelConstants.tenantOccupied) {
        this.endorsementsGroup.get('unitOwnersRentalToOthers').setValue(true);
      }
    }
  }

  checkCoverageA(): void {
    const coverageA = Number(this.CoveragesAndDeductiblesForm.value['coverageA']);

    if (!this.policySummaryData.isPolicy || this.policySummaryData.isEditPolicy) {
      if (coverageA >= MINCOVA && coverageA <= MAXCOVA) {
        if (coverageA < 150000) {
          this.disableFormControl('starPackage', false);
          this.disableFormControl('starAdvantagePackage', false);
        } else if (coverageA < 200000) {
          this.enableFormControl('starPackage');
          this.disableFormControl('starAdvantagePackage', false);
        } else {
          this.enableFormControl('starPackage');
          this.enableFormControl('starAdvantagePackage');
        }
      } else {
        this.disableFormControl('starPackage', false);
        this.disableFormControl('starAdvantagePackage', false);
        this.reset(this.propertyData.propertyDetails.get('yearBuilt').value, this.applicantData.mainApplicant.get('effectiveDate').value);
      }
    }
  }

  checkCoverageCLimitId(): void {
    if (!this.policySummaryData.isPolicy || this.policySummaryData.isEditPolicy) {
      const test = this.CoveragesAndDeductiblesForm.value['coverageCLimitId'];
      if (this.CoveragesAndDeductiblesForm.value['coverageCLimitId'] === '0' || this.CoveragesAndDeductiblesForm.value['coverageCLimitId'] === 0) {
        if (this.quoteService.formType === this.formTypeConstants.HO3) {
          this.disableFormControl('personalProperty', false);
          this.disableFormControl('specialPersonal', false);
          this.disableFormControl('coverageC', false);
        } else if (this.quoteService.formType === this.formTypeConstants.HO4) {

        } else if (this.quoteService.formType === this.formTypeConstants.HO6) {

        }
      } else {
        const starPack = this.endorsementsGroup.get('starPackage').value;
        const starPackAd = this.endorsementsGroup.get('starAdvantagePackage').value;
        if (!starPackAd) {
          this.enableFormControl('specialPersonal');
          this.enableFormControl('increasedReplacement');
        } else {
          this.disableFormControl('specialPersonal', false);
          this.disableFormControl('increasedReplacement', false);
        }

        if (!(starPack || starPackAd)) {
          this.enableFormControl('coverageC');
          this.enableFormControl('personalProperty');
          this.enableFormControl('ordinanceLawOrCov');
        }
      }
    }
  }

  checkHurricaneDeductibleid(): void {
    if (!this.policySummaryData.isPolicy || this.policySummaryData.isEditPolicy) {
      if (this.CoveragesAndDeductiblesForm.value['hurricaneDeductibleid'] === 'E') {
        this.disableFormControl('screenedEnclosure', 'N');
        this.isScreenEnclosureDisabled.next(true);
      } else {
        this.enableFormControl('screenedEnclosure', 'N');
        this.isScreenEnclosureDisabled.next(false);
      }

      if (this.isWindStormExteriorVisible.value) {
        if (this.endorsementsGroup.get('windstormExteriorPaintoOrWaterproofingExclusionSeacoastFlorida').pristine) {
          if (this.CoveragesAndDeductiblesForm.value['hurricaneDeductibleid'] === 'E') {
            if (this.isInternal) {
              this.enableControl('windstormExteriorPaintoOrWaterproofingExclusionSeacoastFlorida', false);
            } else {
              this.disableFormControl('windstormExteriorPaintoOrWaterproofingExclusionSeacoastFlorida', false);
            }
          } else {
            if (this.isInternal) {
              this.enableControl('windstormExteriorPaintoOrWaterproofingExclusionSeacoastFlorida', true);
            } else {
              this.disableFormControl('windstormExteriorPaintoOrWaterproofingExclusionSeacoastFlorida', true);
            }
          }
        }
      }
    }

    if (this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3) {
      this.checkHurrDeductibleExcluded(this.CoveragesAndDeductiblesForm.value['hurricaneDeductibleid']);
    }
  }

  disableSPP(): void {

    if (this.isInternal){
      this.enableFormControl('seasonalDwelling');
    } else {
      this.disableFormControl('seasonalDwelling');
    }
  }

  initWatchers(): void {
    this.watchUserType();
    this.watchCoverageC();
    this.watchPackageType();
    this.watchWaterDamage();
    this.watchSinkHoleLossCoverage();
    this.watchValidatedFields();
    this.watchLimitedWaterDamage();
  }

  subscribeUserType(): void {
    this.authService.userType.subscribe((userType) => {
      this.isInternal = this.isInternalUser(userType);
    });
  }

  protected isInternalUser(userType: string): boolean {
    return userType?.toLocaleLowerCase() === 'internal';
  }

  watchUserType(): void {
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe((userType) => {
      if (userType === 'internal') {
        this.enableFormControl('premiumAdjustment');
        this.enableFormControl('premiumAdjustmentAmount');
        this.enableFormControl('consentToRate');
        this.enableFormControl('consentToRateAmount');
        if (this.policySummaryData.isPolicy && !this.policySummaryData.isEditPolicy) {
          this.disableFormControl('premiumAdjustment');
          this.disableFormControl('premiumAdjustmentAmount');
          this.disableFormControl('consentToRate');
          this.disableFormControl('consentToRateAmount');
        }
      } else {
        this.disableFormControl('premiumAdjustment');
        this.disableFormControl('premiumAdjustmentAmount');
        this.disableFormControl('consentToRate');
        this.disableFormControl('consentToRateAmount');
      }
    });
  }

  watchCoverageC(): void {
    this.endorsementsGroup
      .get('coverageC')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((coverageC) => {
        if (coverageC === false) {
          this.endorsementsGroup.get('jewelryIncreased').patchValue('');
          this.endorsementsGroup.get('jewelryTotal').patchValue(null);
          this.endorsementsGroup.get('silverwareIncreased').patchValue('');
          this.endorsementsGroup.get('silverwareTotal').patchValue(null);
        }
      });
  }

  watchPackageType(): void {
    this.endorsementsGroup
      .get('starPackage')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((starPackage) => {
        if (starPackage === true) {
          this.endorsementsGroup.patchValue({
            starAdvantagePackage: false,
          });
        }
        this.realtimeStarPackage = starPackage;
        if (this.starPackageAndAdvantageChecker()) {
          this.packageSelected();
        }

        if (!this.policySummaryData.isPolicy) {
          this.packageSelected();
        }
      });

    this.endorsementsGroup
      .get('starAdvantagePackage')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((starAdvantagePackage) => {
        if (starAdvantagePackage === true) {
          this.endorsementsGroup.patchValue({
            starPackage: false,
          });
        }
        this.realtimeStarAdvPackage = starAdvantagePackage;
        if (this.starPackageAndAdvantageChecker()) {
          this.packageSelected();
        }

        if (!this.policySummaryData.isPolicy) {
          this.packageSelected();
        }
      });
  }

  onChangeWaterDamage(): void {
    if (this.endorsementsGroup.get('waterDamage')?.value  === true) {
      if (this.endorsementsGroup.get('waterDamage')?.status === 'DISABLED' && this.policySummaryData.isPolicy && this.endorsementsGroup.get('waterDamage')?.value !== true) {
        if (this.isInternal) {
          this.enableControl('limitedWater');
          this.limitedWaterDefaultState = false;
        } else {
          this.setLWDOnLoad();
        }
      } else {
        this.setLWDOnLoad();
      }
    } else {
      if (this.isInternal) {
        this.enableControl('limitedWater', false);
        this.limitedWaterDefaultState = false;
      } else {
        this.endorsementsGroup.get('limitedWater').setValue(false);
        this.setLWDOnLoad();
      }
    }
  }

  watchWaterDamage(): void {
    this.endorsementsGroup
      .get('waterDamage')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((waterDamage) => {
        if (waterDamage === true) {
          if (this.endorsementsGroup.get('waterDamage')?.status === 'DISABLED' && this.policySummaryData.isPolicy && this.endorsementsGroup.get('waterDamage')?.value !== true) {
            if (this.isInternal) {
              this.disableLimitedWaterDamage(false);
            } else {
              this.setLWDOnLoad();
            }
          }
        } else {
          if (this.isInternal) {
            this.disableLimitedWaterDamage(false);
          } else {
            this.setLWDOnLoad();
          }
        }
        this.setBRNew110InfoMessageStatus();
      });
  }

  watchLimitedWaterDamage(): void {
    this.endorsementsGroup
      .get('limitedWater')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((waterDamage) => {
        this.setBRNew110InfoMessageStatus();
    });
  }

  limitedWaterBRUL(): void {
    const effDateYear = this.applicantData.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate?.getFullYear();
    const yearB =  this.propertyData.propertyDetails.get('yearBuilt').value;
    if (this.quoteService.formType === this.formTypeConstants.HO4) {
      this.enableFormControl('limitedWater');
      this.endorsementsGroup.get('limitedWater').setValue((effDateYear - yearB > this.GetWaterDamageInclusion()));
    } else {
      if (this.quoteService.formType === this.formTypeConstants.DP3 && this.currentLimitedWaterDmg == null) {
        this.endorsementsGroup.get('limitedWater').setValue((effDateYear - yearB > this.GetWaterDamageInclusion()));
      }
    }

    if (!this.endorsementsGroup.get('waterDamage').value) {
      const limitedWater = this.checkLimitedWater();
      this.endorsementsGroup.get('limitedWater').setValue(limitedWater);
    }
  }

  watchSinkHoleLossCoverage(): void {
    this.endorsementsGroup
      .get('sinkholeLoss')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((sinkholeLoss) => {
        this.populateSinkholeDeductible(sinkholeLoss);
      });
  }

  watchValidatedFields(): void {
    this.endorsementsGroup
      .get('ordinanceLawOrCov')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((ordinanceLawOrCov) => {
        if (ordinanceLawOrCov) {
          this.generalValidationService.resetValidatorFormControl(this.endorsementsGroup, 'ordinanceLaw', [Validators.required]);
        } else {
          this.generalValidationService.clearValidatorFormControl(this.endorsementsGroup, 'ordinanceLaw');
        }
      });

    this.endorsementsGroup
      .get('screenedEnclosure')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((screenedEnclosure) => {
        if (screenedEnclosure !== 'N') {
          this.generalValidationService.resetValidatorFormControl(this.endorsementsGroup, 'screenedEnclosureLimit', [Validators.required]);
        } else {
          this.generalValidationService.clearValidatorFormControl(this.endorsementsGroup, 'screenedEnclosureLimit');
        }
      });

    this.endorsementsGroup
      .get('lossAssessment')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((lossAssessment) => {
        if (lossAssessment) {
          this.generalValidationService.resetValidatorFormControl(this.endorsementsGroup, 'lossAssessmentLimit', [Validators.required]);
        } else {
          this.generalValidationService.clearValidatorFormControl(this.endorsementsGroup, 'lossAssessmentLimit');
        }
      });

    this.endorsementsGroup
      .get('homeComputer')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((homeComputer) => {
        if (homeComputer) {
          this.generalValidationService.resetValidatorFormControl(this.endorsementsGroup, 'homeComputerLimit', [Validators.required]);
        } else {
          this.generalValidationService.clearValidatorFormControl(this.endorsementsGroup, 'homeComputerLimit');
        }
      });

    this.endorsementsGroup
      .get('premiumAdjustment')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((premiumAdjustment) => {
        if (premiumAdjustment) {
          this.generalValidationService.resetValidatorFormControl(this.endorsementsGroup, 'premiumAdjustmentAmount', [Validators.required, EndorsementValidators.personalPropertyAtOtherResidencesLimitNotZeroValidator()]);
        } else {
          this.generalValidationService.clearValidatorFormControl(this.endorsementsGroup, 'premiumAdjustmentAmount');
        }
      });

    this.endorsementsGroup
      .get('consentToRate')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((consentToRate) => {
        if (consentToRate) {
          this.generalValidationService.resetValidatorFormControl(this.endorsementsGroup, 'consentToRateAmount', [Validators.required, EndorsementValidators.personalPropertyAtOtherResidencesLimitNotZeroValidator()]);
        } else {
          this.generalValidationService.clearValidatorFormControl(this.endorsementsGroup, 'consentToRateAmount');
        }
      });

    this.endorsementsGroup
      .get('personalPropertyAtOtherResidences')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((personalPropertyAtOtherResidences) => {
        if (personalPropertyAtOtherResidences) {
          this.generalValidationService.resetValidatorFormControl(this.endorsementsGroup, 'personalPropertyAtOtherResidencesLimit', [Validators.required, EndorsementValidators.personalPropertyAtOtherResidencesLimitDivisibleValidator(), EndorsementValidators.personalPropertyAtOtherResidencesLimitNotZeroValidator()]);
        } else {
          this.generalValidationService.clearValidatorFormControl(this.endorsementsGroup, 'personalPropertyAtOtherResidencesLimit');
        }
      });

    this.endorsementsGroup
      .get('unitOwnersRentalToOthers')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((unitOwnersRentalToOthers) => {
        if (unitOwnersRentalToOthers) {
          this.generalValidationService.resetValidatorFormControl(this.endorsementsGroup, 'leaseTerm', [Validators.required]);
        } else {
          this.generalValidationService.clearValidatorFormControl(this.endorsementsGroup, 'leaseTerm');
        }
      });

    this.endorsementsGroup
      .get('permittedIncidentalOccupancies')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((permittedIncidentalOccupancies) => {
        if (permittedIncidentalOccupancies) {
          this.generalValidationService.resetValidatorFormControl(this.endorsementsGroup, 'descriptionOfOccupancy', [Validators.required]);
        } else {
          this.generalValidationService.clearValidatorFormControl(this.endorsementsGroup, 'descriptionOfOccupancy');
        }
      });

    this.endorsementsGroup
      .get('permittedIncidentalOccupanciesLiability')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((permittedIncidentalOccupanciesLiability) => {
        if (permittedIncidentalOccupanciesLiability) {
          this.generalValidationService.resetValidatorFormControl(this.endorsementsGroup, 'descriptionOfBusiness', [Validators.required]);
          this.generalValidationService.resetValidatorFormControl(this.endorsementsGroup, 'businessLocation', [Validators.required]);
        } else {
          this.generalValidationService.clearValidatorFormControl(this.endorsementsGroup, 'descriptionOfBusiness');
          this.generalValidationService.clearValidatorFormControl(this.endorsementsGroup, 'businessLocation');
        }
      });


    this.endorsementsGroup
      .get('fungiWetOrDryRot')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((fungiWetOrDryRot) => {
        if (fungiWetOrDryRot) {
          this.generalValidationService.resetValidatorFormControl(this.endorsementsGroup, 'fungiWetOrDryRotLimit', [Validators.required]);
        } else {
          this.generalValidationService.clearValidatorFormControl(this.endorsementsGroup, 'fungiWetOrDryRotLimit');
        }
      });

    this.subscribeToLimitedWater();
    this.subscribeToGolfCart();
    this.subscribeToCoverageC();
  }

  populateSinkholeDeductible(sinkholeLoss: boolean): void {
    const state = this.applicantData.state ? this.applicantData.state : this.applicantData.defaultState;
    this.endorsementsGroup.get('sinkholeDeductible').setValue(sinkholeLoss && state === FLORIDA ? SINKHOLEDEDUCTIBLEVALUE : 0);
  }

  setWDEOnLoad(): void {
    if (this.entityRiskData.EntityRisk) {
      const effectiveDate = this.getPolicyEffectiveDate()?.getFullYear();
      const checkYearBuilt = this.propertyData.propertyDetails.get('yearBuilt').value === '' || this.propertyData.propertyDetails.get('yearBuilt').value === null  || Number(this.propertyData.propertyDetails.get('yearBuilt').value) === 0;
      const yearBuilt = checkYearBuilt ? this.entityRiskData.EntityRisk?.risks[0]?.riskDetails[0]?.riskProperties[0]?.yearBuilt : this.propertyData.propertyDetails.get('yearBuilt').value;

      const WDEYearInclusion = this.getWDEYearInclusion();
      const isWaterDamageSelected = this.endorsementsGroup.get('waterDamage').value === true;

      if (this.IsPolicyOrRenewal) {
        // New110 apply in policy where effective date is > 5/15/2022
        if (this.isBRNew110Apply()) {
          this.disableWaterDamage(false);
          if ((effectiveDate - Number(yearBuilt) > WDEYearInclusion) && isWaterDamageSelected) {
            this.disableWaterDamage(true);
            if (this.isInternal) {
              this.disableWaterDamage(false);
            }
          }
        } else {
          // BR-END-01 and BR-END-17 applies.
          this.disableWaterDamage((effectiveDate - Number(yearBuilt)) > WDEYearInclusion);
          if (this.isInternal) {
            this.disableWaterDamage(false);
          }
        }
      } else {
        if ((effectiveDate - Number(yearBuilt)) > WDEYearInclusion) {
          this.disableWaterDamage(false);
        }
      }
      this.setLWDOnLoad();
    }
  }

  setLWDOnLoad(): void {
    const effectiveDateYear = this.applicantData.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate?.getFullYear();
    const yearBuilt =  Number(this.propertyData.propertyDetails.get('yearBuilt').value);
    const isWaterDamageSelected = this.endorsementsGroup.get('waterDamage').value === true;
    let HO3DP3HO6WdeYear = 0;

    if (this.quoteService.formType === this.formTypeConstants.HO4) {
      this.disableLimitedWaterDamage(!this.isInternal);
      if (isWaterDamageSelected) {
        this.disableLimitedWaterDamage(false);
      }
    } else if (this.isBRNew110Apply() && this.quoteService.formType !== this.formTypeConstants.HO4) {
      this.disableLimitedWaterDamage(!this.isInternal);
      if (isWaterDamageSelected) {
        this.disableLimitedWaterDamage(false);
      }
    } else {
      // BR-END-01 and BR-END-17 applies.
      HO3DP3HO6WdeYear = this.getWDEYearInclusion();
      if (this.isTriCountyAndStLucie() && (effectiveDateYear - yearBuilt) > HO3DP3HO6WdeYear) {
        this.disableLimitedWaterDamage(!this.isInternal);
      } else {
        this.disableLimitedWaterDamage(!this.isInternal);
        if (isWaterDamageSelected) {
          this.disableLimitedWaterDamage(false);
        }
      }
    }
  }

  disableWaterDamage(status: boolean): void {
    this.waterDamageDefaultState = status;
    if(!status) {
      this.enableControl('waterDamage');
    } else {
      this.disableFormControl('waterDamage');
    }
  }

  disableLimitedWaterDamage(status: boolean): void {
    this.limitedWaterDefaultState = status;
    if(!status) {
      this.enableControl('limitedWater');
    } else {
      this.disableFormControl('limitedWater');
    }
  }

  setWaterDamageExclusion(): void {
    const effectiveDateYear = this.applicantData.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate?.getFullYear();
    const yearBuilt =  Number(this.propertyData.propertyDetails.get('yearBuilt').value);
    const WDEYearInclusion = this.getWDEYearInclusion();
    this.enableControl('waterDamage');
    this.endorsementsGroup.get('waterDamage').setValue((effectiveDateYear - yearBuilt) > WDEYearInclusion);

    this.setLimitedWaterDamage();
  }

  setLimitedWaterDamage(): void {
    const effectiveDateYear = this.applicantData.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate?.getFullYear();
    const yearBuilt =  Number(this.propertyData.propertyDetails.get('yearBuilt').value);
    const WDEYearInclusion = this.getWDEYearInclusion();

    if (this.IsPolicyOrRenewal) {
      /* BRUL RT-24 OR NEW-110 */
      if (this.quoteService.formType === this.formTypeConstants.HO4 || this.isBRNew110Apply()){
        this.endorsementsGroup.get('limitedWater').setValue((effectiveDateYear - yearBuilt) > WDEYearInclusion);
      } else {
        // BR-END-01 AND NEW-17 for Tri County and the rest of the state.
        if (this.isTriCountyAndStLucie() && (effectiveDateYear - yearBuilt) > WDEYearInclusion) {
          this.endorsementsGroup.get('limitedWater').setValue(false);
        } else {
          this.endorsementsGroup.get('limitedWater').setValue(true);
        }
      }
    } else {
      this.endorsementsGroup.get('limitedWater').setValue((effectiveDateYear - yearBuilt > this.GetNewBusinessWaterDamageExclusion()));
    }
  }

  isTriCountyAndStLucie(): boolean {
    const county = this.propertyData.locationDetails.get('county')?.value;
    return  this.triCountyAndStLucie.includes(county?.toString().toLocaleLowerCase());
  }

  GetWaterDamageInclusion(): number {
    if (this.quoteService.formType === this.formTypeConstants.DP3) {
      return DP3WATERDAMAGEINCLUSIONYEARS;
    } else if (this.quoteService.formType === this.formTypeConstants.HO4) {
      return HO4WATERDAMAGEINCLUSIONYEARS;
    } else {
      return WATERDAMAGEINCLUSIONYEARS;
    }
  }

  GetNewBusinessWaterDamageExclusion(): number {
    if (this.quoteService.formType === this.formTypeConstants.HO4) {
        return HO4WATERDAMAGEINCLUSIONYEARS;
    }
    return NBHO3DP3HO6WATERDAMAGEINCLUSIONYEAR;
  }

  packageSelected(): void {
    if (this.endorsementsGroup.get('starPackage').value === true ||
      this.endorsementsGroup.get('starAdvantagePackage').value === true) {
      this.isStarPackage = true;
      this.applyStarPackageCommonInclusion();  // Star Package
      if (this.endorsementsGroup.get('starAdvantagePackage').value === true) {
        this.isStarAdvantagePackage = true;
        this.applyStarPackageAdvantageInclusion(); // Star Advantage package
      } else {
        this.isStarAdvantagePackage = false;
      }
      const coverageA = this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').value;
      this.coveragesData.calculateCoverages(Number(coverageA));
    } else {
      this.isStarPackage = false;
      this.isStarAdvantagePackage = false;
      this.reset(this.propertyData.propertyDetails.get('yearBuilt').value, this.applicantData.mainApplicant.get('effectiveDate').value);
    }
  }

  applyStarPackageCommonInclusion(): void {
    this.disableFormControl('personalProperty', false);
    this.disableFormControl('ordinanceLaw', '25');
    this.disableFormControl('ordinanceLawOrCov', false);
    this.disableFormControl('waterBackup', false);
    this.disableFormControl('personalInjury', false);
    this.disableFormControl('lossAssessment', false);
    this.disableFormControl('coverageC', false);
    this.enableFormControl('specialPersonal');
    this.enableFormControl('increasedReplacement');
    this.coveragesData.coveragesAndDeductiblesForm.get('coverageCLimitId').setValue('50');
    if (!this.isInternal) { this.coveragesData.coveragesAndDeductiblesForm.get('coverageCLimitId').disable(); }
    this.isPersonalPropertyDisabled.next(this.endorsementsGroup.get('starPackage').value);
    // TODO: Make sure to add this in payload when saving in database.
  }

  applyStarPackageAdvantageInclusion(): void {
    this.disableFormControl('specialPersonal', false);
    this.disableFormControl('ordinanceLaw', '25');
    this.disableFormControl('ordinanceLawOrCov', false);
    this.disableFormControl('increasedReplacement', false);
    this.setCoverageCLimit();
    this.isPersonalPropertyDisabled.next(this.endorsementsGroup.get('starAdvantagePackage').value);
    // TODO: Make sure to add this in payload when saving in database.
  }

  reset(yearBuilt?: any, effectiveDate?: any): void {
    const isPolicy = this.checkBooleanStorage(localStorage.getItem('isPolicy'));
    const isEditPolicy = this.checkBooleanStorage(localStorage.getItem('isEditPolicy'));
    if (!isPolicy || isEditPolicy) {
      if (this?.coveragesData?.coveragesAndDeductiblesForm?.get('coverageCLimitId')?.value.toString().trim() === '0' && (this?.quoteService?.formType === FormTypeConstants?.HO3)) {
       this.disableFormControl('personalProperty',false);
       this.isPersonalPropertyDisabled.next(true);
      } else {
       this.enableFormControl('personalProperty');
       this.isPersonalPropertyDisabled.next(false);
      }
      this.enableFormControl('ordinanceLaw', null);
      this.enableFormControl('ordinanceLawOrCov', false);
      this.enableFormControl('waterBackup', false);
      this.enableFormControl('personalInjury', false);
      this.enableFormControl('lossAssessment', false);
      this.enableFormControl('coverageC', false);
      this.enableFormControl('increasedReplacement', false);
      this.enableFormControl('specialPersonal', false);
      if (this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId())) {
        if (this.policySummaryData.isEditPolicy) {
          this.coveragesData.coveragesAndDeductiblesForm.get('coverageCLimitId').enable();
        } else {
          this.coveragesData.coveragesAndDeductiblesForm.get('coverageCLimitId').disable();
        }
      } else {
        this.coveragesData.coveragesAndDeductiblesForm.get('coverageCLimitId').enable();
      }
      this.coveragesData.coveragesAndDeductiblesForm.get('coverageCLimitId').setValue(this.coveragesData.userSelectedCovCId !== null && this.coveragesData.userSelectedCovCId !== undefined ? this.coveragesData.userSelectedCovCId : this?.coveragesData?.coveragesAndDeductiblesForm?.get('coverageCLimitId')?.value ??  '50');
      this.coveragesData.calculateCoverages(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').value), yearBuilt, effectiveDate); // to reset coverageC value after Limit changes
    }
  }

  checkBooleanStorage(data: string): boolean {
    return data === 'true' ? true : false;
  }

  getMinimumEstimatedPremium(): string {
    let minimumLimit = '';
    const isPremiumAdj = this.endorsementsGroup.get('premiumAdjustment').value;
    const premiumAdjAmt = this.endorsementsGroup.get('premiumAdjustmentAmount').value;
    if (isPremiumAdj && premiumAdjAmt) {
      const riskDetailId = this.entityRiskData.getRisk()?.riskDetails[0].id;
      const raterResultKey = `${Rater.raterResult}_${riskDetailId}`;
      const storage = JSON.parse(localStorage.getItem(raterResultKey));
      const premium = !storage ? null : storage.filter(r =>
        (
          r.stepAlias.toUpperCase() === PremiumConstants.FinalFeesStepAlias.toUpperCase() ||
          r.stepAlias.toUpperCase() === PremiumConstants.MGAFeesStepAlias.toUpperCase() ||
          r.stepAlias.toUpperCase() === PremiumConstants.EMPATFSurStepAlias.toUpperCase() ||
          r.stepAlias.toUpperCase() === PremiumConstants.PremiumAdj.toUpperCase() ||
          r.groupName.toUpperCase() === PremiumConstants.FigaAssessmentStepAlias.toUpperCase() ||
          r.groupName.toUpperCase() === PremiumConstants.PremTaxDisc.toUpperCase()
        )
      );
      let totalPremium: number = 0;
      if (premium) {
        premium.map(value => {
          totalPremium = value.stepAlias.toUpperCase() === PremiumConstants.FinalFeesStepAlias.toUpperCase() &&
          value.stepAlias.toUpperCase() !== PremiumConstants.PremiumAdj.toUpperCase() ?
            totalPremium + Number(value.perilValue) : totalPremium - Number(value.perilValue);
        });
      } else {
        totalPremium = 0;
      }
      const premiumAdjustmentAmount = this.currencyChecking.checkAmountForSaving(premiumAdjAmt);
      const newTotalPremium = totalPremium + (!premiumAdjustmentAmount ? 0 : premiumAdjustmentAmount);
      if (newTotalPremium < 0) {
        minimumLimit = totalPremium !== 0 ? GenericConstants.CustomAmountChecking.withNegativeDollarSign + totalPremium.toString() : '';
        this.currencyChecking.getCommaIndex(minimumLimit, true);
        minimumLimit = this.currencyChecking.insertComma(minimumLimit);
      }
    }
    return minimumLimit;
  }

  markEndorsementPageAsTouch(): void {
    FormUtils.markFormgroupAsTouch(this.endorsementsGroup);
  }

  setDisabledEndorsementFields(uwQuestionsData: UwQuestionsData): void {
    if (uwQuestionsData.eligibilityGroup.get('AreDogsClassified').value) {
      this.endorsementsGroup.get('animalLiability').disable();
    }
  }

  setMaximumLiability(): void {
    if (this.quoteService.formType === this.formTypeConstants.HO6 &&
      this.endorsementsGroup.get('unitOwnersRentalToOthers').value &&
      this.endorsementsGroup.get('leaseTerm').value === 'Short Term') {
        this.coveragesData.coveragesAndDeductiblesForm.get('coverageEId').setValue(100000);
    } else {
      if (this.previousCovE) {
        this.coveragesData.coveragesAndDeductiblesForm.get('coverageEId').setValue(Number(this.previousCovE));
      }
    }
  }

  setPreviousCovE(): void {
    this.previousCovE = this.coveragesData.coveragesAndDeductiblesForm.get('coverageEId').value;
  }

  checkHurrDeductibleExcluded(value: any): void {
    this.isHurrDeductibleExcluded.next(value === 'E' ? true : false);
  }

  checkLimitedWater(): boolean {
    const isHO3orHO6 = this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.HO6;
    const county = this.propertyData.locationDetails.get('county')?.value;
    const isCountyCovered = county?.toString().toLocaleLowerCase() === this.countyCovered.broward.toLocaleLowerCase() ||
      county?.toString().toLocaleLowerCase() === this.countyCovered.palmBeach.toLocaleLowerCase() ||
      county?.toString().toLocaleLowerCase() === this.countyCovered.stLucie.toLocaleLowerCase() ||
      county?.toString().toLocaleLowerCase() === this.countyCovered.miamiDade.toLocaleLowerCase();
    const effectiveDate = this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId()) ?
      new Date(this.entityRiskData.EntityRisk?.risks[0]?.riskDetails[0]?.effectiveDate) :
      new Date(this.applicantData?.mainApplicant?.get('effectiveDate')?.value?.singleDate?.jsDate);
    const yearBuilt = this.propertyData.propertyDetails.get('yearBuilt')?.value;
    const yearBuiltAge = yearBuilt ? effectiveDate.getFullYear() - Number(yearBuilt) : null;

    return isHO3orHO6 && isCountyCovered && (yearBuiltAge > 40);
  }

  setBRNew110InfoMessageStatus(): void {
    if (this.isBRNew110Apply()) {
      const waterDamage = this.endorsementsGroup.get('waterDamage').value;
      const limitedWater = this.endorsementsGroup.get('limitedWater').value;
      const effectiveDateYear = this.applicantData.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate?.getFullYear();
      const yearBuilt = Number(this.propertyData.propertyDetails.get('yearBuilt').value);
      const yearBuiltAge = effectiveDateYear - yearBuilt;

      this.brNew110LWDInfoMessageStatus = !limitedWater && yearBuiltAge > 15;
      this.brNew110WDEInfoMessageStatus = !waterDamage && yearBuiltAge > 15;
    } else {
      this.resetBRNew110InfoStatus();
    }
  }

  resetBRNew110InfoStatus(): void {
    this.brNew110WDEInfoMessageStatus = false;
    this.brNew110LWDInfoMessageStatus = false;
  }

  setCoverageCLimit(): void {
    let effectiveDate = this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId()) ? new Date(this.entityRiskData.EntityRisk?.risks[0]?.firstIssueDate) :
    this.applicantData.mainApplicant.get('effectiveDate')?.value?.singleDate?.jsDate;
    const submissionStartingDate = '2022-04-14';
    const renewalSubmissionStartingDate = '2022-06-30';
    const startingDate: string = !this.entityRiskData.getRisk()?.isFromRenewal ? submissionStartingDate : renewalSubmissionStartingDate;
    const dateFormat: string = 'MM/DD/YYYY';

    effectiveDate = moment(effectiveDate).format(dateFormat);
    this.effectiveDateStarPackage = moment(effectiveDate).isAfter(moment(startingDate).format(dateFormat));

    if (this.effectiveDateStarPackage) {
      this.coveragesData.coveragesAndDeductiblesForm.get('coverageCLimitId').disable();
      this.coveragesData.coveragesAndDeductiblesForm.get('coverageCLimitId').setValue('75');
    } else {
      this.coveragesData.coveragesAndDeductiblesForm.get('coverageCLimitId').disable();
      this.coveragesData.coveragesAndDeductiblesForm.get('coverageCLimitId').setValue('70');
    }
  }

  showLimitedTheftCoverageToggle(isFromPopulate?: boolean, riskIsResidenceRented?: boolean, propertyUsage?: string): boolean {
    if (this.quoteService.formType === this.formTypeConstants.DP3) {
      const propertyUsageCode = isFromPopulate ? propertyUsage : this.propertyData.locationDetails.get('propertyUsage')?.value;
      const isPropertyUsageValid = (propertyUsageCode === LocationLabelConstants.ownerOccupiedPrimary
        || propertyUsageCode === LocationLabelConstants.ownerOccupiedSecondary
        || propertyUsageCode === LocationLabelConstants.SeasonalDwelling);

      if (isPropertyUsageValid) {
        let effectiveDate = this.policySummaryData.isPolicyPage(this.entityRiskData.getRiskId()) ?
          new Date(this.entityRiskData.EntityRisk?.risks[0]?.firstIssueDate) :
          this.applicantData.mainApplicant.get('effectiveDate')?.value?.singleDate?.jsDate;
        if (effectiveDate === undefined || effectiveDate.toString() === 'Invalid Date') {
          effectiveDate = this.applicantData.mainApplicant.get('effectiveDate')?.value?.jsDate;
        }
        effectiveDate = new Date(effectiveDate);

        const limitedTheftCovChargingStartDate = new Date(localStorage.getItem('NewLimitedTheftCoverageCharging'));
        const isChargingStarted = effectiveDate >= limitedTheftCovChargingStartDate;
        const isResidenceRented = isFromPopulate ? riskIsResidenceRented : this.propertyData.locationDetails.get('isResidenceRented')?.value;

        if (isChargingStarted && isResidenceRented) {
          return false;
        }
        return true;
      }
      return false;
    }
    return this.isLimitedTheftCoverageAvailable;
  }

  checkIsLimitedFungiWet(riskEndorsements: any): void {
    if (riskEndorsements?.isLimitedFungiWet) {
      this.endorsementsGroup.get('limitedFungiLimit').setValue(this.limitedFungiLimitsOptions.find(x => x.code === Number(riskEndorsements?.limitedFungiWetLimitCode))?.code ?? '');
    }
  }

  subscribeToLimitedWater(): void {
    this.endorsementsGroup
      .get('limitedWater')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((limitedWater) => {
        if (limitedWater) {
          this.generalValidationService.resetValidatorFormControl(this.endorsementsGroup, 'limitedWaterLimit', [Validators.required]);
        } else {
          this.generalValidationService.clearValidatorFormControl(this.endorsementsGroup, 'limitedWaterLimit');
        }
      });
  }

  subscribeToGolfCart(): void {
    this.endorsementsGroup
      .get('golfCart')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((golfCart) => {
        if (golfCart) {
          this.generalValidationService.resetValidatorFormControl(this.endorsementsGroup, 'numberOfGolfCarts', [Validators.required]);
          this.generalValidationService.resetValidatorFormControl(this.endorsementsGroup, 'liabilityType', [Validators.required]);
        } else {
          this.generalValidationService.clearValidatorFormControl(this.endorsementsGroup, 'numberOfGolfCarts');
          this.generalValidationService.clearValidatorFormControl(this.endorsementsGroup, 'liabilityType');
        }
      });
  }

  subscribeToCoverageC(): void {
    this.endorsementsGroup
      .get('coverageC')
      .valueChanges.pipe(takeUntil(this.stop$)).subscribe((coverageC) => {
        if (coverageC) {
          this.generalValidationService.resetValidatorFormControl(this.endorsementsGroup, 'jewelryIncreased',
            [Validators.required, EndorsementValidators.coverageCIncreasedAmountCannotBeBothZero('silverwareIncreased')]);
          this.generalValidationService.resetValidatorFormControl(this.endorsementsGroup, 'silverwareIncreased',
            [Validators.required, EndorsementValidators.coverageCIncreasedAmountCannotBeBothZero('jewelryIncreased')]);
        } else {
          this.generalValidationService.clearValidatorFormControl(this.endorsementsGroup, 'jewelryIncreased');
          this.generalValidationService.clearValidatorFormControl(this.endorsementsGroup, 'silverwareIncreased');
        }
      });
  }
}