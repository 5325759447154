import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { forkJoin, Observable, of } from 'rxjs';
import Utils from '../../shared/utilities/utils';
import { PolicyBillingLabelsConstants } from '../../shared/constants/policy-billing.labels.constants';
import { PolicyBillingData } from '../policy-management/data/policy-billing.data';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BillingService } from 'app/core/services/billing/billing.service';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { AllowedPaymentRangeDTO } from 'app/shared/models/data/dto/billing/allowed-payment-range.dto';
import { PaymentProcedure } from 'app/shared/enum/payment-procedure.enum';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BaseClass } from 'app/shared/base-class';
import { PolicyService } from 'app/core/services/submission/policy.service';
import { RiskSearchFilterDTO } from 'app/shared/models/data/dto/policy-management/risk-search-filter.dto';
import { RiskSearchResultDTO } from 'app/shared/models/data/dto/policy-management/risk-search-result.dto';
import { BillingSummaryDTO } from 'app/shared/models/data/dto/billing/billing-summary.dto';
import { MakePaymentDTO } from 'app/shared/models/data/dto/billing/make-payment.dto';
import { StatusConstants } from 'app/shared/constants/risk-status';
import { MakePaymentModalComponent } from '../dashboard/components/dashboard/make-payment/make-payment-modal/make-payment-modal.component';
import { OutstandingReinstatementRequirementsModalComponent } from '../dashboard/components/dashboard/make-payment/outstanding-reinstatement-requirements-modal/outstanding-reinstatement-requirements-modal.component';
import { ReinstatementRequirementsMetModalComponent } from '../dashboard/components/dashboard/make-payment/reinstatement-requirements-met-modal/reinstatement-requirements-met-modal.component';
import { BillingLabelConstants, PaymentPlanListConstants } from 'app/shared/constants/bind-and-issue.labels.constants';
import { RegexConstants } from 'app/shared/constants/regex.constants';
import { GenericLabel } from 'app/shared/constants/generic.labels.constants';
import { PaymentMethod } from 'app/shared/enum/payment-method.enum';
import { DatePipe } from '@angular/common';
import { PostPaymentCheckComponent } from '../policy-management/policy-billing/payments/post-payments/post-payment-check/post-payment-check.component';
import { PostPaymentCreditCardComponent } from '../policy-management/policy-billing/payments/post-payments/post-payment-credit-card/post-payment-credit-card.component';
import { PostPaymentEftComponent } from '../policy-management/policy-billing/payments/post-payments/post-payment-eft/post-payment-eft.component';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { AuthService } from '../../core/services/auth.service';
import { ZipCodeData } from '../submission-management/data/zipcode.data';
import { EntityRiskDTO } from 'app/shared/models/data/dto/quick-quote/entity-risk.dto';
import { AddressDTO3 } from 'app/shared/models/data/dto/quick-quote/entity-address.dto';
import { AddressType } from 'app/shared/enum/address-type.enum';
import Swal from 'sweetalert2';
import { PaymentRequestDTO } from 'app/shared/models/data/dto/billing/payment-request.dto';
import { PaymentSummaryDTO } from 'app/shared/models/data/dto/billing/payment-summary.dto';
import { BillingDetailDTO } from 'app/shared/models/data/dto/billing/billing-detail.dto';
import { CreditCardDTO } from 'app/shared/models/data/dto/billing/credit-card.dto';
import { BankAccountDTO } from 'app/shared/models/data/dto/billing/bank-account.dto';
import { BillingAddressDTO } from 'app/shared/models/data/dto/billing/billing-address.dto';
import { ActivatedRoute } from '@angular/router';
import { SubmissionListData } from 'app/modules/submission-management/data/submission-list.data';
import { AutoReinstatementData } from '../policy-management/data/auto-reinstatement.data';
import { AutoReinstatementConstants } from '../../shared/constants/auto-reinstatement.constants';
import { OtherApprovalRequirementsDTO } from '../../shared/models/data/dto/billing/other-approval-requirements.dto';
import { PolicySummaryData } from '../policy-management/data/policy-summary.data';
import { EntityRiskData } from '../submission-management/data/entity-risk.data';
import { RiskUWApprovalDTO2 } from '../../shared/models/data/dto/quick-quote/uw-approval.dto';
import { TaskGenerationService } from '../../core/services/submission/task-generation/task-generation.service';
import { SummaryData } from '../submission-management/data/summary.data';
import { DecryptParameterDTO } from 'app/shared/models/data/dto/billing/decrypt-parameter.dto';

@Component({
  selector: 'app-make-a-payment',
  templateUrl: './make-a-payment.component.html',
  styleUrls: ['./make-a-payment.component.scss']
})
export class MakeAPaymentComponent extends BaseClass implements OnInit, OnDestroy {
  public event: EventEmitter<any> = new EventEmitter<any>();
  public ErrorMessageConstant = ErrorMessageConstant;
  public SearchPolicyLabelConstants = PolicyBillingLabelsConstants.searchPolicy;
  public BillingLabelConstants = BillingLabelConstants;
  public AutoReinstatementConstants = AutoReinstatementConstants;

  searchPolicyForm: FormGroup;

  isPolicyNotFound: boolean;
  isPaymentNotAllowed: boolean;
  attemptedSearch: boolean;

  paymentNotAllowedReason: string;

  isShowPaymentDetails: boolean;

  public MakePaymentLabelConstants = PolicyBillingLabelsConstants.makePayment;
  public GenericLabel = GenericLabel;

  allowedPaymentRange: AllowedPaymentRangeDTO;

  PaymentMethod = PaymentMethod;
  paymentProcedure: PaymentProcedure = PaymentProcedure.PostBasic;

  modalRef: BsModalRef | null;
  title: string;

  paymentMethods: any;

  datePipe: DatePipe;
  currencyInputMode = CurrencyMaskInputMode.NATURAL;
  datePickerDateOption: IAngularMyDpOptions;

  balance: number;
  currentTermAmountToPay: number;
  isForRenewalReinstatement: boolean = false;
  renewalRiskId: string;
  renewalPolicyNumber: string;
  renewalRiskDetailId: string;
  renewalRiskEntityId: string;
  renewalPayplan: string;
  forRenewalNotTaken: boolean;
  withRenewalOfferedOrRenewalNotTaken: boolean;
  isSiebelDataWithDuplicatePolicyNumber: boolean;
  isFromReinstate: boolean = false;
  IunderstandCheckbox: boolean = false;

  isRenewalPolicyNumberUsedAndSiebelData: boolean;
  expRiskIdForRenPolNumberUsedAndSiebelData: string;
  isPolicyOnPendingCancellation: boolean;

  isPaymentForRenewal: boolean = false;

  submitClickCount: number = 0;


  @ViewChild('checkForm') checkFormRef: PostPaymentCheckComponent;
  @ViewChild('ccForm') ccFormRef: PostPaymentCreditCardComponent;
  @ViewChild('eftForm') eftFormRef: PostPaymentEftComponent;
  constructor(
    private fb: FormBuilder,
    public billingData: PolicyBillingData,
    private billingService: BillingService,
    private policyService: PolicyService,
    private authService: AuthService,
    private zipCodeData: ZipCodeData,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private activatedRoute: ActivatedRoute,
    private submissionListData: SubmissionListData,
    public autoReinstatementData: AutoReinstatementData,
    private policySummaryData: PolicySummaryData,
    private taskGenerationService: TaskGenerationService,
    private summaryData: SummaryData
  ) { super(); }

  ngOnInit() {
    this.setupAuthAndDetails();
  }

  initSearchForm(): void {
    // Search Form
    this.billingData.makePaymentData = null;
    this.renewalPolicyNumber = null;

    this.searchPolicyForm = this.fb.group({
      policyNumber: new FormControl(null, [Validators.required, Validators.pattern(RegexConstants.policyNumber)]),
      insuredLastName: new FormControl(null, [Validators.required]),
      policyPayOffAmount: new FormControl(null),
      amountDue: new FormControl(null)
    });

    this.populateSearchFields();
  }

  populateSearchFields(): void {
    // Search Form
    this.searchPolicyForm.reset();
  }

  populatePaymentFields(): void {
    // Payment Form
    this.billingData.postPaymentForm.reset();

    this.billingData.postPaymentForm.get('paymentMethod').enable();
    this.billingData.postPaymentForm.get('paymentMethod').setValue('');
    this.setAmountValidation();
    this.billingData.postPaymentForm.get('amount').updateValueAndValidity();

    this.billingData.postPaymentForm.get('postDate').setValue({ isRange: false, singleDate: { jsDate: this.billingData.auth.getCustomDate() } });

    this.balance = this.billingData.makePaymentData.amountDue >= 0 ? this.billingData.makePaymentData.amountDue : 0;

    this.currentTermAmountToPay = Number(this.allowedPaymentRange?.minimumPaymentAmount);

    if (this.isPolicyOnPendingCancellation && this.paymentProcedure === PaymentProcedure.PostBasic) {
      this.currentTermAmountToPay = this.autoReinstatementData.requirementsMetDetails?.requiredAmount;
    }

    this.billingData.postPaymentForm.get('amount').setValue(this.isReinstatementCheckboxNeeded ? this.autoReinstatementData?.requirementsMetDetails?.requiredAmount : this.currentTermAmountToPay > 0 ? this.currentTermAmountToPay : 0);

    this.billingData.postPaymentForm.get('check').get('isIndividual').setValue(true);
  }

  onContinue(policyNumber: string = null): void {
    if (this.searchPolicyForm.get('policyNumber').value === '' || this.searchPolicyForm.get('insuredLastName').value === '' || this.searchPolicyForm.get('policyNumber').value === null || this.searchPolicyForm.get('insuredLastName').value === null) {
      return;
    }
    const policySearchRequest: RiskSearchFilterDTO = {
      policyNumber: policyNumber ? policyNumber : this.searchPolicyForm.get('policyNumber').value,
      isLatestEndorsement: true,
      insuredLastName: this.searchPolicyForm.get('insuredLastName').value
    };

    const searchRisk$: Observable<RiskSearchResultDTO[]> = this.policyService.searchRiskByFilter(policySearchRequest);
    const paymentCheckIfAllowed$: Observable<string[]> = this.policyService.getPaymentNotAllowedReasons(policySearchRequest.policyNumber);

    Utils.blockUI();

    forkJoin([searchRisk$, paymentCheckIfAllowed$])
      .pipe(
        takeUntil(this.stop$),
        tap(([searchResult, paymentErrors]) => {
          Utils.blockUI();
          this.isSiebelDataWithDuplicatePolicyNumber = searchResult.length > 1 && searchResult[0].entity.risks[0].isSiebel;

          if (searchResult.length === 0) {
            this.isPolicyNotFound = true;
            this.billingData.postPaymentForm.reset();
            this.isShowPaymentDetails = false;
          }
          if (paymentErrors.length > 0 && !this.isSiebelDataWithDuplicatePolicyNumber) {
            this.isPaymentNotAllowed = true;
            this.paymentNotAllowedReason = paymentErrors.join('. ');
          }
        }),
        switchMap(([searchResult, paymentErrors]) => {
          if (this.isPolicyNotFound) {
            return of(false);
          }

          const actOrPexpSiebelDataWithDuplicatePolicyNumber = searchResult.filter(x => x.riskStatusCode === 'ACT' || x.riskStatusCode === 'PEXP' || x.riskStatusCode === 'CAN');
          const latestActOrPexpSiebelDataWithDuplicatePolicyNumber = actOrPexpSiebelDataWithDuplicatePolicyNumber.sort((a, b) => new Date(b.entity.risks[0].firstIssueDate).getTime() - new Date(a.entity.risks[0].firstIssueDate).getTime())[0];
          const isRenewalPolicyNumberUsed = !this.isSiebelDataWithDuplicatePolicyNumber ?
            Boolean(searchResult[0]?.renewalFromRiskId) && (
              searchResult[0].riskStatusCode !== 'ACT' &&
              searchResult[0].riskStatusCode !== 'CAN' &&
              searchResult[0].riskStatusCode !== 'EXP' &&
              searchResult[0].riskStatusCode !== 'PEXP'
            ) : Boolean(latestActOrPexpSiebelDataWithDuplicatePolicyNumber.renewalFromRiskId) && (
              latestActOrPexpSiebelDataWithDuplicatePolicyNumber.riskStatusCode !== 'ACT' &&
              latestActOrPexpSiebelDataWithDuplicatePolicyNumber.riskStatusCode !== 'CAN' &&
              latestActOrPexpSiebelDataWithDuplicatePolicyNumber.riskStatusCode !== 'EXP' &&
              latestActOrPexpSiebelDataWithDuplicatePolicyNumber.riskStatusCode !== 'PEXP'
            );

          const riskId = !this.isSiebelDataWithDuplicatePolicyNumber ? // check if data is not from siebel
            (
              isRenewalPolicyNumberUsed ? searchResult[0]?.renewalFromRiskId : searchResult[0].id
            ) : latestActOrPexpSiebelDataWithDuplicatePolicyNumber.id;

          this.withRenewalOfferedOrRenewalNotTaken = !this.isSiebelDataWithDuplicatePolicyNumber ? (
              searchResult[0].renewalCode === 'RWNT' || searchResult[0].renewalCode === 'RWO' || searchResult[0].renewalCode === 'RRWO'
            ) : (
              latestActOrPexpSiebelDataWithDuplicatePolicyNumber.renewalCode === 'RWNT' || latestActOrPexpSiebelDataWithDuplicatePolicyNumber.renewalCode === 'RWO'
              || latestActOrPexpSiebelDataWithDuplicatePolicyNumber.renewalCode === 'RRWO'
            );
          this.forRenewalNotTaken = !this.isSiebelDataWithDuplicatePolicyNumber ? searchResult[0].renewalCode === 'RWNT'
            : latestActOrPexpSiebelDataWithDuplicatePolicyNumber.renewalCode === 'RWNT';

          if (!isRenewalPolicyNumberUsed) { // Expiring Policy # Used (w/ or w/o Renewal code)
            const risk = !this.isSiebelDataWithDuplicatePolicyNumber ? searchResult[0] : latestActOrPexpSiebelDataWithDuplicatePolicyNumber;
            if ((searchResult.length > 0 && paymentErrors.length === 0) || (paymentErrors.length > 0 && this.isSiebelDataWithDuplicatePolicyNumber)) {
              // For Expiring Policy w/ RWNT
              if (this.withRenewalOfferedOrRenewalNotTaken) {
                // Get Renewal Risk info
                return this.billingService.getRenewalRiskDetails(riskId)
                  .pipe(
                    switchMap(renewalRisk => { // renewal risk info
                      this.renewalRiskId = renewalRisk?.id;
                      this.renewalPolicyNumber = renewalRisk?.policyNumber;
                      this.renewalRiskDetailId = renewalRisk?.riskDetails[0]?.id;
                      this.renewalRiskEntityId = renewalRisk?.entityId;

                      return this.policyService.getRiskById(riskId).map(currentRisk => currentRisk); // Get Current Risk info
                    }),
                    switchMap(currentRisk => { // current risk info
                      this.renewalPayplan = currentRisk.riskDetails?.filter(x => x.isActive)[0].riskBinds[0]?.riskBindBilling?.nextTermpaymentPlan;
                      return this.billingService.getSummary(riskId).map(res => ({ summary: res, policy: risk }));
                    })
                  );
              }

              // For Policy w/o RWNT
              return this.billingService.getSummary(riskId).map(res => ({ summary: res, policy: risk }));
            }
            return of(false);
          } else if (isRenewalPolicyNumberUsed && this.withRenewalOfferedOrRenewalNotTaken) { // Renewal Policy # w/ Renewal code RWO | RRWO | RWNT
            const renewalRisk = searchResult[0];
            this.isRenewalPolicyNumberUsedAndSiebelData = renewalRisk.entity.risks[0].isSiebel;
            this.expRiskIdForRenPolNumberUsedAndSiebelData = renewalRisk.renewalFromRiskId;
            this.renewalRiskId =  renewalRisk.id;
            this.renewalPolicyNumber = renewalRisk?.policyNumber;
            this.renewalRiskDetailId = renewalRisk?.riskDetails[0]?.id;
            this.renewalRiskEntityId = renewalRisk?.entityId;

            return this.policyService.getRiskById(renewalRisk.renewalFromRiskId)
              .pipe(
                switchMap(expiringRisk => {
                  this.renewalPayplan = expiringRisk.riskDetails?.filter(x => x.isActive)[0].riskBinds[0]?.riskBindBilling?.nextTermpaymentPlan;

                  const searchRequest: RiskSearchFilterDTO = {
                    policyNumber: expiringRisk.policyNumber,
                    isLatestEndorsement: true,
                    insuredLastName: this.searchPolicyForm.get('insuredLastName').value
                  };

                  return this.policyService.searchRiskByFilter(searchRequest).map(res => res);
                }),
                switchMap(updatedSearchResult => {
                  const resultCount = updatedSearchResult.length;
                  let updatedResult = resultCount === 1 ? updatedSearchResult[0] : updatedSearchResult?.find(activePolicy => activePolicy?.riskStatusCode === 'ACT');
                  if (!updatedResult) { // This Code represents, what if updatedSearchResult has 2 or more records yet there is no 'ACT' riskStatus
                    updatedResult = updatedSearchResult.find(expiry => expiry.id === this.expRiskIdForRenPolNumberUsedAndSiebelData);
                  }
                  return this.billingService.getSummary(updatedResult?.id).map(res => ({ summary: res, policy: updatedResult }));
                })
              );
          } else {
            return of(false);
          }
        }))
      .subscribe(res => {
        this.attemptedSearch = true;

        if (res) {
          const policyData = res as { summary: BillingSummaryDTO; policy: RiskSearchResultDTO };

          //total premium is now zero for cancelled policies
          // if (policyData.summary.totalPremium === 0) {
          //   NotifUtils.showError(this.SearchPolicyLabelConstants.billingDataNotFound);
          //   return;
          // }

          const makePaymentData: MakePaymentDTO = {
            riskIdData: !this.isRenewalPolicyNumberUsedAndSiebelData ? policyData.policy.id : this.expRiskIdForRenPolNumberUsedAndSiebelData,
            policyNumberData: policyData.policy.policyNumber,
            insuredLastName: policySearchRequest.insuredLastName,
            payoffAmount: policyData.summary.payoffAmount,
            amountDue: policyData.summary.balance,
            pastDueAmount: policyData.summary.pastDueAmount,
            isFullPay: this.billingData.paymentPlan.filter(plan => plan.billingCode.toLowerCase() === policyData.summary.paymentPlan.toLowerCase())[0].code === PaymentPlanListConstants.fullPayCode,
            isMortgageePayPlan: this.billingData.paymentPlan.filter(plan => plan.billingCode.toLowerCase() === policyData.summary.paymentPlan.toLowerCase())[0].code === PaymentPlanListConstants.mortgageePayCode,
            entity: policyData.policy.entity,
            renewalCode: policyData.policy.renewalCode,
            riskStatusCode: policyData.policy.riskStatusCode,
            riskDetails: policyData.policy.riskDetails,
            cancellationTypeCode: policyData.policy.cancellationTypeCode,
            renewalPolicyNumberData: this.renewalPolicyNumber,
            renewalRiskIdData: this.renewalRiskId,
            renewalPayPlan: this.renewalPayplan,
            renewalRiskDetailIdData: this.renewalRiskDetailId,
            renewalRiskEntityIdData: this.renewalRiskEntityId,
            isPendingCancellation: policyData.policy.entity.risks[0]?.pendingCancellationDate !== null
          };
          this.billingData.makePaymentData = makePaymentData;
          this.billingData.isAccessedFromPortal = true;

          if (policyData.policy.entity.risks[0].cancellationReason === PolicyBillingLabelsConstants.rewrite) {
            NotifUtils.showInfo(PolicyBillingLabelsConstants.rewritePaymentNotAllowed);
          } else {
            if (policyData.policy.riskStatusCode === StatusConstants.cancelled) {
              this.attemptedSearch = false;
              this.showReinstatementModal(policyData.policy.approvedForReinstatementDate ? true : false);
            } else {
              this.showPaymentDetails();
            }
          }
        } else {
          Utils.unblockUI();
        }
      },
        err => {
          this.attemptedSearch = false;
          Utils.unblockUI();
          NotifUtils.showMultiLineError(err.error?.message);
        });
  }

  showPaymentDetails(): void {
    Utils.blockUI();

    this.isPolicyOnPendingCancellation = this.billingData.makePaymentData.isPendingCancellation;
    const apiCallsForActivePolicy = this.isPolicyOnPendingCancellation ? [
      this.billingService.getAllowedPaymentRange(this.billingData.makePaymentData.riskIdData),
      this.autoReinstatementData.getOtherReinstatementRequirements(false, this.billingData.makePaymentData.riskIdData)
    ] : [
      this.billingService.getAllowedPaymentRange(this.billingData.makePaymentData.riskIdData)
    ];
    const parallelCalls = this.billingData.hasRenewalNotTaken ? [
      this.billingService.getAllowedPaymentRange(this.billingData.makePaymentData.riskIdData),
      this.billingService.getIsOtherRenewalRequirementsMet({
        renewalRiskId: this.renewalRiskId,
        expiringRiskId: this.billingData.makePaymentData.riskIdData,
        paymentPlan: this.billingData.makePaymentData.renewalPayPlan
      })
    ] : apiCallsForActivePolicy;

    forkJoin(parallelCalls)
      .pipe(takeUntil(this.stop$))
      .subscribe(res => {
        this.allowedPaymentRange = <AllowedPaymentRangeDTO>res[0];
        this.paymentProcedure = PaymentProcedure.PostBasic;
        this.balance = this.billingData.makePaymentData?.amountDue;
        this.isForRenewalReinstatement = this.forRenewalNotTaken;

        this.autoReinstatementData.allowedPaymentRangeFromPostPayment = <AllowedPaymentRangeDTO>res[0];
        this.autoReinstatementData.requirementsMetDetails = <OtherApprovalRequirementsDTO>res[1] ?? null;

        this.isShowPaymentDetails = true;
        this.initPaymentForm();
      },
        err => {
          Utils.unblockUI();
          NotifUtils.showMultiLineError(err.error?.message);
        });
  }

  initPaymentForm(): void {
    if (this.isForRenewalReinstatement) {
      this.getDetailsForReinstatement();
    }

    // Search Form
    this.datePipe = new DatePipe('en-US');
    this.billingData.initPostPaymentForm();

    // Payment Form
    this.billingData.paymentMethodChangedEvent.pipe(takeUntil(this.stop$)).subscribe(() => {
      this.prefillPayeeInfo();
    });

    this.searchPolicyForm.get('policyPayOffAmount').setValue(this.billingData.makePaymentData.payoffAmount);
    this.searchPolicyForm.get('amountDue').setValue(this.billingData.makePaymentData.amountDue);

    this.getPaymentMethods();

    this.billingData.postPaymentForm.get('amount').updateValueAndValidity();


    this.initForm();
    this.isCurrentOrRenewalCheckBoxChecked();
    this.subscribeToReinstatementPayments();
  }

  initForm(): void {
    this.datePickerDateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy'
    };

    this.billingData.enableCityFields();

    this.zipCodeData.checkCityList = [];
    this.zipCodeData.ccCityList = [];
    this.zipCodeData.eftCityList = [];

    const riskId = this.billingData.makePaymentData.riskIdData;
    const riskDetailIdAct = this.billingData.makePaymentData.riskDetails.filter(a => a.riskSubStatusCode === 'ACT')[0]?.id;

    this.billingData.checkRenewalDetails(riskId, riskDetailIdAct);
    this.populatePaymentFields();

    this.billingData.postPaymentForm.get('check').get('isIndividual').setValue(true);
  }

  onPostPayment() {
    this.submitClickCount++;

    if (this.submitClickCount === 1) {
      this.processPostPayment();
     }
  }

  private processPostPayment() {
    if (this.billingData.isPolicyCancelled) {
      this.paymentProcedure = this.autoReinstatementData.isPaymentForMinimumDueOnly(this.paymentProcedure, this.allowedPaymentRange?.minimumPaymentAmount, this.billingData.postPaymentForm?.get('amount').value) ?
        PaymentProcedure.PostBasic : PaymentProcedure.PayToReinstate;
    }
    const paymentRequest: PaymentRequestDTO = this.getPaymentRequest(this.paymentProcedure === PaymentProcedure.PostBasic);

    let paymentProcedure$: Observable<any>;
    this.paymentProcedure = this.billingData.postPaymentForm.get('balanceAmountCheckbox').value && this.paymentProcedure === PaymentProcedure.PayToReinstate ? PaymentProcedure.PayBalance : this.paymentProcedure;

    switch (this.paymentProcedure) {
      case PaymentProcedure.PostBasic:

        if (this.isPaymentForRenewal) {
          this.billingData.getCommissionRate();
          this.summaryData.authService.isDoneCommissionRateChecking$.pipe(takeUntil(this.stop$)).subscribe(() => {

            if (!this.authService.isCommissionRateError) {
              paymentRequest.commissionRate = Number(this.authService.commissionRateResponse.commissionRate);

              if (this.checkIfRWNTReinstatement) {
                if (this.isExpiredReinstatement()) {
                  //Prevent payment of reinstatement to force the user to edit reinstatement approval section
                  NotifUtils.showError(AutoReinstatementConstants.errorMessage.expiredApprovalExpirationDate);
                  return;
                } else {

                  paymentProcedure$ = this.autoReinstatementData.setPaymentProcedureForRenewalReinstatementForDashboardAndPortal(paymentRequest);
                }
              } else {
                paymentProcedure$ = this.billingService.postPaymentRequest(paymentRequest);
              }

              Utils.blockUI();
              paymentProcedure$.pipe(takeUntil(this.stop$)).subscribe(result => {
                Utils.unblockUI();
                this.generateTasksForRenewedPolicy();

                const options: any = {
                  icon: 'success',
                  allowOutsideClick: false,
                  text: PolicyBillingLabelsConstants.successPayment
                };

                NotifUtils.showSuccess(options, () => {
                  this.showPaymentReceipt(result.paymentReceiptEmailBody);
                  this.onClear();
                });
              },
                err => {
                  Utils.unblockUI();
                  NotifUtils.showError(err.error?.message, () => {
                    this.onClear();
                  });
                }
              );
            } else {
              this.onClear();
            }
          });

        } else {
          paymentProcedure$ = this.billingService.postPaymentRequest(paymentRequest);
        }
        break;
      case PaymentProcedure.PayBalance:
        paymentProcedure$ = this.billingService.payBalance(paymentRequest);
        break;
      case PaymentProcedure.PayToReinstate:
        if (this.isExpiredReinstatement() && this.billingData.postPaymentForm.get('reinstateAmountCheckbox').value) {
          //Prevent payment of reinstatement to force the user to edit reinstatement approval section
          NotifUtils.showError(AutoReinstatementConstants.errorMessage.expiredApprovalExpirationDate);
          return;
        }
        paymentProcedure$ = this.billingService.payToReinstate(paymentRequest);
        break;
      default:
    }

    if (paymentProcedure$ && !this.isPaymentForRenewal) {
      Utils.blockUI();
      paymentProcedure$.pipe(takeUntil(this.stop$)).subscribe(result => {
        Utils.unblockUI();
        this.onClear();
        this.generateTasksForRenewedPolicy();

        const options: any = {
          icon: 'success',
          allowOutsideClick: false,
          text: PolicyBillingLabelsConstants.successPayment
        };

        NotifUtils.showSuccess(options, () => this.showPaymentReceipt(result.paymentReceiptEmailBody));
      },
        err => {
          Utils.unblockUI();
          NotifUtils.showError(err.error?.message, () => {
            this.onClear();
          });
        }
      );
    }


  }

  prefillPayeeInfo(): void {
    const entity: EntityRiskDTO = this.billingData.makePaymentData.entity;
    let address: AddressDTO3;

    if (this.billingData.makePaymentData.entity.isMailingAddressSameInsured) {
      address = entity.entityAddresses.find(a => a.addressTypeCode === AddressType.Property)?.address;
    } else {
      address = entity.entityAddresses.find(a => a.addressTypeCode === AddressType.Mailing)?.address;
    }

    const paymentForm: FormGroup = this.billingData.getCurrentPaymentForm(this.billingData.postPaymentForm);

    if (paymentForm && !paymentForm.touched) {
      paymentForm.get('firstName').setValue(entity.firstName);
      paymentForm.get('lastName').setValue(entity.lastName);
      paymentForm.get('address').setValue(address.streetAddress1);
      paymentForm.get('zip').setValue(address.zipCode);

      const emailField = paymentForm.get('email');
      if (emailField) {
        emailField.setValue(entity.personalEmailAddress);
      }

      paymentForm.get('firstName').markAsTouched();

      this.zipCodeData.getCityListCompleted$.pipe(take(1)).subscribe(() => {
        paymentForm.get('city').setValue(address.city);
      });

      const isUSCountry = address?.countryCode === 'USA' || address?.countryCode === 'US';
      if (isUSCountry) {
        const paymentMethod = this.billingData.postPaymentForm.get('paymentMethod').value;
        if (this.checkFormRef && paymentMethod === PaymentMethod.CashCheck) {
          this.checkFormRef.setZipCode(isUSCountry);
        } else if (this.ccFormRef && (paymentMethod === PaymentMethod.CreditCard || paymentMethod === PaymentMethod.RecurringCreditCard)) {
          this.ccFormRef.setZipCode(isUSCountry);
        } else if (this.eftFormRef && (paymentMethod === PaymentMethod.EFT || paymentMethod === PaymentMethod.RecurringECheck)) {
          this.eftFormRef.setZipCode(isUSCountry);
        }
      } else {
        paymentForm.get('address').setValue('');
        paymentForm.get('city').setValue('');
        paymentForm.get('state').setValue('');
        paymentForm.get('zip').setValue('');
      }
    }

    this.billingData.hasOneAgreeCheckbox = false;
    
    const paymentMethod = this.billingData.postPaymentForm.get('paymentMethod').value;
    if (paymentMethod === PaymentMethod.CreditCard || paymentMethod === PaymentMethod.EFT){
      this.billingData.postPaymentForm.controls.agreeOneTimePayment.setValue(false);
    }
  }

  isPaymentMethodHidden(paymentMethodCode: string): boolean {
    const recurringPayments: string[] = [PaymentMethod.RecurringCreditCard, PaymentMethod.RecurringECheck];
    const isInternal: boolean = this.authService.isInternalUser;
    return (recurringPayments.includes(paymentMethodCode) && isInternal) || (!isInternal && paymentMethodCode === PaymentMethod.CashCheck);
  }

  ngOnDestroy() {
    this.billingData.makePaymentData = null;
    this.billingData.resetCheckImageModel();
  }

  get checkIfRWNTReinstatement(): boolean {
    return (this.policySummaryData.isPolicy && this.billingData.hasRenewalNotTaken) ||
      (!this.policySummaryData.isPolicy && this.billingData.makePaymentData.renewalCode === AutoReinstatementConstants.reinstateStatus.RWNT);
  }

  private isExpiredReinstatement(): boolean {
    return this.autoReinstatementData.checkIsReinstatementExpired();
  }

  private setLogicForAutoReinstatement(): boolean {
    return this.autoReinstatementData.portalSetReinstatementAndRequirementsMetProperty(this.paymentProcedure, this.billingData.postPaymentForm, this.billingData.makePaymentData);
  }

  private generateTasksForRenewedPolicy(): void {
    if (!this.billingData.withRenewalOffered) {
      Utils.unblockUI();
      this.onClear();
      return;
    }
    // renewal risk info
    const renewalRiskId = this.billingData.makePaymentData?.renewalRiskIdData;
    const renewalRiskDetailId = this.billingData.makePaymentData?.renewalRiskDetailIdData;
    const renewalRiskPolicyNumber = this.billingData.makePaymentData?.renewalPolicyNumberData;
    const renewalEntityId = this.billingData.makePaymentData?.renewalRiskEntityIdData;

    Utils.blockUI();
    this.policyService.getPolicyUwApprovals(renewalRiskId, renewalRiskDetailId).pipe(takeUntil(this.stop$)).subscribe((result: RiskUWApprovalDTO2[]) => {
      if (!result) {
        Utils.unblockUI();
        this.onClear();
        return;
      }

      const renewalSubmissionUWR: string[] = result.map(uwr => {
        return uwr.uwApprovalDescription;
      });
      const issued = {
        policyNumber: renewalRiskPolicyNumber
      };

      this.taskGenerationService.triggerTaskMethods(issued, renewalSubmissionUWR, renewalEntityId);
      Utils.unblockUI();
      this.onClear();
    }, (err) => {
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify(err.error?.message));
      this.bsModalRef.hide();
      this.onClear();
    });
  }

  get isReinstatementRequirementsNotMet(): boolean {
    return this.autoReinstatementData.portalCheckIsReinstatementRequirementsNotMet(false, this.billingData.postPaymentForm, this.billingData.makePaymentData);
  }

  get isReinstatementCheckboxNeeded(): boolean {
    return this.autoReinstatementData.checkIsReinstatementCheckboxNeededForDashboardAndPortal(this.billingData.postPaymentForm, this.billingData?.makePaymentData);
  }

  private subscribeToReinstatementPayments(): void {
    this.autoReinstatementData.subscribePostPaymentReinstatementCheckboxes(this.billingData.postPaymentForm, this.balance);
  }

  setMinimumReinstateAmount(): number {
    return this.autoReinstatementData.requirementsMetDetails?.requiredAmount;
  }

  get isRenewalReinstatementRequirementsNotMet(): boolean {
    return this.autoReinstatementData.checkIsRenewalReinstatementRequirementsNotMet(false, this.billingData.postPaymentForm);
  }

  get hasReinstatementErrorMessage(): boolean {
    return (this.isReinstatementRequirementsNotMet && this.billingData.postPaymentForm.get('reinstateAmountCheckbox').value) || (this.isRenewalReinstatementRequirementsNotMet && this.billingData.postPaymentForm.get('currentAndRenewalTermCheckbox').value);
  }

  toggleIunderstandCheckbox(isChecked: boolean): void {
    this.IunderstandCheckbox = isChecked;
  }

  get shouldBeDisabled(): boolean {
    return this.billingData.postPaymentForm.invalid ||
      this.isAmountZero ||
      this.isCheckImageNotUploaded || this.isReinstatementCheckboxNeeded ||
      (this.billingData.withRenewalOffered ? this.isCheckBoxPaymentBothFalse : '') ||
      (this.hasReinstatementErrorMessage && !this.IunderstandCheckbox) ||
      !(this.billingData.hasOneAgreeCheckbox);
  }

  private getDetailsForReinstatement(): void {
    this.autoReinstatementData.isApprovalRequired$.next(false);
    const billingDetails$ = this.billingService.getRenewalAutoReinstatementDetails(this.autoReinstatementData.getRenewalReinstatementPayloadForDashboardAndPortal()).pipe(filter(result => Boolean(result)));

    billingDetails$.pipe(
      tap(result => {
        this.autoReinstatementData.reinstatementView = result;
      }),
      switchMap(result => this.authService.getApproverDetails(result)))
      .subscribe(() => {
        this.autoReinstatementData.isApprovalRequired$.next(this.autoReinstatementData.reinstatementView.isApprovalRequired);
      }, err => {
        this.autoReinstatementData.isApprovalRequired$.next(false);
        NotifUtils.showError(JSON.stringify(err.error?.message) ?? 'Error in fetching approver details');
      });
  }

  setAmountValidation(): void {
      this.billingData.postPaymentForm.get('amount').setValidators([Validators.required,
      Validators.min(this.allowedPaymentRange.minimumPaymentAmount),
      Validators.max(this.allowedPaymentRange.maximumPaymentAmount)]);
  }

  private getPaymentRequest(isPostBasic: boolean): PaymentRequestDTO {

    const billingMethod = this.billingData.postPaymentForm.get('paymentMethod').value;
    const instrumentType: string = this.billingData.paymentMethodsPostPayment.filter(x => x.code === billingMethod)[0].billingCode;
    const amount: number = this.billingData.postPaymentForm.get('amount').value;
    const roundedAmount = Math.round((amount + Number.EPSILON) * 100) / 100;

    const paymentSummary: PaymentSummaryDTO = {
      amount: roundedAmount,
      effectiveDate: this.datePipe.transform(this.billingData.postPaymentForm.get('postDate').value.singleDate.jsDate, 'yyyy-MM-dd'),
      instrumentId: instrumentType,
    };

    if (billingMethod === PaymentMethod.RecurringCreditCard || billingMethod === PaymentMethod.RecurringECheck) {
      paymentSummary.isRecurringPayment = this.billingData.postPaymentForm.get('agreeEnrollAutoPay').value ?? false;
    } else if (billingMethod === PaymentMethod.CreditCard || billingMethod === PaymentMethod.EFT || billingMethod === PaymentMethod.ECheck) {
      paymentSummary.isAgreeOneTimePayment = this.billingData.postPaymentForm.get('agreeOneTimePayment').value ?? false;
    }

    if (billingMethod === PaymentMethod.RecurringCreditCard || billingMethod === PaymentMethod.RecurringECheck ||
      billingMethod === PaymentMethod.CreditCard || billingMethod === PaymentMethod.EFT || billingMethod === PaymentMethod.ECheck) {
      paymentSummary.isAgreeInsured = true;
    }

    const billingDetail: BillingDetailDTO = new BillingDetailDTO();

    if (billingMethod === PaymentMethod.CreditCard || billingMethod === PaymentMethod.RecurringCreditCard) {
      const ccForm = this.billingData.postPaymentForm.get('creditCard');
      const ccType = ccForm.get('creditCardTypeId').value;
      const ccTypeBilling = this.billingData.getCreditCardTypeBillingCode(ccType);

      const creditCardDetail: CreditCardDTO = {
        cardCode: ccForm.get('cardCode').value,
        cardNumber: ccForm.get('cardNumber').value,
        expirationDate: `${ccForm.get('expirationYear').value}-${('00' + ccForm.get('expirationMonth').value).slice(-2)}`,
        cardType: ccTypeBilling
      };

      billingDetail.creditCard = creditCardDetail;
      billingDetail.billingAddress = this.getBillingAddress(ccForm);

      paymentSummary.email = ccForm.get('email').value;
    } else if (billingMethod === PaymentMethod.EFT || billingMethod === PaymentMethod.RecurringECheck) {
      const eftForm = this.billingData.postPaymentForm.get('eCheck');

      const accountType: string = eftForm.get('accountType').value;
      const accountTypeBilling: string = this.billingData.getAccountTypeBillingCode(accountType);

      const bankAccount: BankAccountDTO = {
        accountNumber: eftForm.get('accountNumber').value,
        accountType: accountTypeBilling,
        nameOnAccount: eftForm.get('nameOnAccount').value,
        routingNumber: eftForm.get('routingNumber').value,
      };

      billingDetail.bankAccount = bankAccount;
      billingDetail.billingAddress = this.getBillingAddress(eftForm);

      paymentSummary.email = eftForm.get('email').value;
    } else if (billingMethod === PaymentMethod.CashCheck) {
      const cashForm = this.billingData.postPaymentForm.get('check');
      billingDetail.billingAddress = this.getBillingAddress(cashForm);
    }

    paymentSummary.checkNumber = this.billingData.postPaymentForm.get('checkNumber')?.value ?? '';
    paymentSummary.imageUrl = this.billingData.uploadedCheckImage?.filePath ?? '';

    const isCurrentAndRenewalPayment = isPostBasic && (this.billingData.postPaymentForm.get('currentAndRenewalTermCheckbox').value ?? false);

    const paymentRequest: PaymentRequestDTO = {
      riskId: this.billingData.makePaymentData.riskIdData,
      riskDetailId: this.billingData.makePaymentData.riskDetails.filter(a => a.riskSubStatusCode === 'ACT')[0]?.id,
      paymentSummary: paymentSummary,
      billingDetail: billingDetail,
      postSuspendedPayments: this.isFromReinstate,
      isFromInsuredPaymentPortal: true,
      isForCurrentAndRenewal: isCurrentAndRenewalPayment,
      isForReinstatementAndRequirementsMet: this.setLogicForAutoReinstatement(),
      isReinstatingByMakeAPayment: this.autoReinstatementData.setIsReinstatingByMakeAPayment(this.paymentProcedure === PaymentProcedure.PayToReinstate, this.billingData.postPaymentForm),
      commissionRate: 0
    };

    return paymentRequest;
  }

  private getBillingAddress(form: AbstractControl): BillingAddressDTO {
    const billingAddress: BillingAddressDTO = {
      firstName: form.get('firstName').value,
      lastName: form.get('lastName').value,
      address: form.get('address').value,
      city: form.get('city').value,
      zip: form.get('zip').value,
      state: form.get('state').value
    };

    if (form.get('email')) {
      billingAddress.email = form.get('email').value;
    }

    return billingAddress;
  }
  private showPaymentReceipt(emailBody: string): void {
    if (emailBody) {
      Swal.fire(
        {
          customClass: {
            content: 'email-receipt-content-style',
          },
          width: 900,
          html: emailBody,
          showCloseButton: true,
          showConfirmButton: false,
          focusConfirm: false,
        });
    }
  }

  getPaymentMethods(): void {
    const paymentMethods = this.billingData.paymentMethodsPostPayment.filter(x => !x.recurringMethod);
    this.paymentMethods = paymentMethods.filter(x => x.code !== PaymentMethod.CashCheck);
  }

  onClear(): void {
    this.populateSearchFields();
    this.attemptedSearch = false;
    this.isPolicyNotFound = false;
    this.isPaymentNotAllowed = false;
  }

  onFieldChange(id: number): void {
    if (id === 1) {
      const policy = this.searchPolicyForm.get('policyNumber');
      policy.setValue(policy.value.replace(/\s/g, ''));
    }
  }

  get isAmountZero(): boolean {
    return this.billingData.postPaymentForm.get('amount').value === 0;
  }

  showReinstatementModal(isApprovedForReinstatement: boolean): void {
    Utils.blockUI();

    forkJoin([this.billingService.getIsPayToInstantReinstateMet(this.billingData.makePaymentData.riskIdData),
    this.billingService.getIsOtherRequirementsMet(this.billingData.makePaymentData.riskIdData),
    this.billingService?.getBillingAutoReinstatementDetails(this.billingData.makePaymentData.riskIdData)])
      .subscribe(result => {
        Utils.unblockUI();
        this.autoReinstatementData.allowedPaymentRangeFromPostPayment = result[0].allowedPaymentRange;
        this.autoReinstatementData.requirementsMetDetails = result[1];
        this.autoReinstatementData.reinstatementView = result[2];
        this.autoReinstatementData.isApprovalRequired$.next(this.autoReinstatementData.reinstatementView?.isApprovalRequired ?? false);

        const initialState = {
          allowedPaymentRange: result[0].allowedPaymentRange,
          paymentProcedure: PaymentProcedure.PayToReinstate,
          balance: this.billingData.makePaymentData?.amountDue
        };
        this.modalRef = this.modalService.show(MakePaymentModalComponent, {
          initialState,
          backdrop: true,
          ignoreBackdropClick: true,
        });
      }, err => {
        Utils.unblockUI();
        NotifUtils.showError(JSON.stringify(err.error?.message));
      });
  }

  showReinstatementOutstandingRequirementsModal(outstandingRequirementsList: string[], hasOutstandingBalance: boolean): void {
    Utils.unblockUI();

    const allowedPaymentRange: AllowedPaymentRangeDTO = {
      minimumPaymentAmount: 0,
      maximumPaymentAmount: this.billingData.makePaymentData.amountDue
    };

    const initialState = {
      allowedPaymentRange: allowedPaymentRange,
      paymentProcedure: PaymentProcedure.PayBalance,
      balance: this.billingData.makePaymentData?.amountDue
    };

    this.modalRef = this.modalService.show(MakePaymentModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.hide();
    this.bsModalRef.hide();
  }

  showReinstatementRequirementsMetModal(minimumAmountToReinstate: number, maximumAmountToReinstate: number): void {
    Utils.unblockUI();

    const paymentRange: AllowedPaymentRangeDTO = {
      minimumPaymentAmount: minimumAmountToReinstate,
      maximumPaymentAmount: maximumAmountToReinstate
    };

    const initialState = {
      allowedPaymentRange: paymentRange,
      paymentProcedure: PaymentProcedure.PayToReinstate,
      balance: this.billingData.makePaymentData?.amountDue
    };

    this.modalRef = this.modalService.show(MakePaymentModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.hide();
    this.bsModalRef.hide();
  }

  checkAndGetServerDate(): void {
    const UTCServerDate = localStorage.getItem('UTCServerDate');
    const ESTServerDate = localStorage.getItem('ESTServerDate');
    const TriggerInactivityTimer = localStorage.getItem('TriggerInactivityTimer');
    const ActivateInactivityTimer = localStorage.getItem('ActivateInactivityTimer');
    if (UTCServerDate === null || ESTServerDate === null || TriggerInactivityTimer === null || ActivateInactivityTimer === null) {
      this.authService.getServerDate().subscribe(result => {
        // FormGroup SetValue
        const ipxDate = result?.brulDate?.ipxDate;
        const triggerInactivityTimer = result?.inactivityTimeout?.triggerInactivityTimer;
        const activateInactivityTimer = result?.inactivityTimeout?.activateInactivityTimer;
        this.authService.LoginDateFormGroup.get('UTCServerDate').setValue(result.currentServerDate);
        this.authService.LoginDateFormGroup.get('ESTServerDate').setValue(result.currentServerDateProper);
        this.authService.LoginDateFormGroup.get('IPXDate').setValue(ipxDate);
        this.authService.LoginDateFormGroup.get('TriggerInactivityTimer').setValue(triggerInactivityTimer);
        this.authService.LoginDateFormGroup.get('ActivateInactivityTimer').setValue(activateInactivityTimer);

        // LocalStorage Set Value
        localStorage.setItem('UTCServerDate', result.currentServerDate);
        localStorage.setItem('ESTServerDate', result.currentServerDateProper);
        localStorage.setItem('IPXDate', ipxDate);
        localStorage.setItem('TriggerInactivityTimer', triggerInactivityTimer);
        localStorage.setItem('ActivateInactivityTimer', activateInactivityTimer);
      });
    }
  }

  isCurrentOrRenewalCheckBoxChecked() {
    const pastDueAmount = this.allowedPaymentRange.minimumPaymentAmount;
    const payOffAmount = this.allowedPaymentRange.maximumPaymentAmount;

    this.billingData.postPaymentForm.get('currentTermCheckbox').valueChanges.subscribe(res => {
      if (res) {
        this.isPaymentForRenewal = false;
        this.billingData.postPaymentForm.get('amount').setValidators([Validators.required, Validators.min(pastDueAmount), Validators.max(payOffAmount)]);
        this.billingData.postPaymentForm.get('currentAndRenewalTermCheckbox').setValue(false);
        this.billingData.postPaymentForm.get('amount').setValue(this.currentTermAmountToPay);
        this.billingData.postPaymentForm.get('amount').updateValueAndValidity();
        this.billingData.postPaymentForm.get('amount').enable();
      }

      if (this.isCheckBoxPaymentBothFalse) {
        this.billingData.postPaymentForm.get('amount').disable();
        this.billingData.postPaymentForm.get('amount').setValue(0);
        this.billingData.postPaymentForm.get('amount').markAsUntouched();
      }
    });

    this.billingData.postPaymentForm.get('currentAndRenewalTermCheckbox').valueChanges.subscribe(res => {
      if (res) {
        this.isPaymentForRenewal = res;
        if (this.checkIfRWNTReinstatement && !this.autoReinstatementData.requirementsMetDetails?.areRequirementsMetExceptPayment) {
          this.autoReinstatementData.notifyForRequirementsNotMet(AutoReinstatementConstants.warningMessage.renewalReinstate, this.billingData.postPaymentForm, 0, false, false);
        }

        const reinstatementOrRenewalDepositOnly = this.getRenewalOrReinstatementAmountOnly();

        this.billingData.postPaymentForm.get('amount').setValidators([
          Validators.required,
          Validators.min(this.paymentProcedure !== PaymentProcedure.PayToReinstate ? this.currentTermAmountToPay + reinstatementOrRenewalDepositOnly : this.currentTermAmountToPay + 1),
          Validators.max(
            this.paymentProcedure !== PaymentProcedure.PayToReinstate ?
              this.currentTermAmountToPay + this.billingData.renewalTotalPremium : this.billingData.renewalAmount + this.currentTermAmountToPay
          )
        ]);
        this.billingData.postPaymentForm.get('currentTermCheckbox').setValue(false);
        this.billingData.postPaymentForm.get('amount').setValue(this.currentTermAmountToPay + reinstatementOrRenewalDepositOnly);
        this.billingData.postPaymentForm.get('amount').updateValueAndValidity();
        this.billingData.postPaymentForm.get('amount').enable();
      }

      if (this.isCheckBoxPaymentBothFalse) {
        this.billingData.postPaymentForm.get('amount').disable();
        this.billingData.postPaymentForm.get('amount').setValue(0);
        this.billingData.postPaymentForm.get('amount').markAsUntouched();
      }
    });
  }

  get minimumPaymentAmount(): number {
    if (this.billingData.withRenewalOffered) {
      const currentAmountChecked = this.billingData.postPaymentForm.get('currentTermCheckbox').value;
      return currentAmountChecked ? this.currentTermAmountToPay : this.currentTermAmountToPay + this.getRenewalOrReinstatementAmountOnly();
    } else {
      return this.billingData.postPaymentForm.get('reinstateAmountCheckbox').value ? this.autoReinstatementData.getMinimumPaymentAmount(this.allowedPaymentRange?.minimumPaymentAmount) :
        this.billingData.postPaymentForm.get('balanceAmountCheckbox').value ? this.balance : this.allowedPaymentRange.minimumPaymentAmount;
    }
  }

  get maximumPaymentAmount(): number {
    const payOffAmount = this.allowedPaymentRange.maximumPaymentAmount;
    const rescindReinstatementAmount = this.autoReinstatementData.requirementsMetDetails?.areRequirementsMetExceptPayment ?
      this.allowedPaymentRange.maximumPaymentAmount + this.billingData?.renewalAmount :
      this.autoReinstatementData.requirementsMetDetails?.requiredAmount;

    if (this.billingData.withRenewalOffered) {
      const currentAmountChecked = this.billingData.postPaymentForm.get('currentTermCheckbox').value;
      return currentAmountChecked ? payOffAmount : this.paymentProcedure !== PaymentProcedure.PayToReinstate ? this.currentTermAmountToPay + this.billingData.renewalTotalPremium : this.billingData.renewalAmount + this.currentTermAmountToPay;
    } else {
      return this.autoReinstatementData.hasRescindedOffer ? rescindReinstatementAmount : this.allowedPaymentRange.maximumPaymentAmount;
    }
  }

  get isCheckBoxPaymentBothFalse(): boolean {
    const checkboxesFormControlFalseValue = !this.billingData.postPaymentForm.get('currentAndRenewalTermCheckbox').value && !this.billingData.postPaymentForm.get('currentTermCheckbox').value && !this.billingData.isPolicyCancelled;
    return checkboxesFormControlFalseValue && (this.billingData.withRenewalOffered || this.autoReinstatementData.hasRescindedOffer);
  }

  get isCheckImageNotUploaded(): boolean {
    return !Boolean(this.billingData.uploadedCheckImage?.isUploaded) && this.billingData.postPaymentForm.get('paymentMethod')?.value === PaymentMethod.CashCheck;
  }

  setupAuthAndDetails() {
    Utils.blockUI();
    const auth = localStorage.getItem('auth');
    const userType = localStorage.getItem('userType');

    this.authService.getPublicInsuredToken().subscribe(authResult => {
      if (authResult.token) {
        localStorage.setItem('auth', JSON.stringify(authResult));
        Utils.unblockUI();
      }
      this.proceedLoadingOfDetails();
    });
  }

  proceedLoadingOfDetails() {
    this.checkAndGetServerDate();

    this.billingData.isMakeAPaymentRedirect = false;

    this.initSearchForm();

    this.activatedRoute.paramMap.subscribe(params => {
      const payloadForDecrypt: DecryptParameterDTO = {
        policyNumber: params.get('policynumber'),
        insuredLastName: params.get('insuredlastname')
      };

      const policyNumber = this.searchPolicyForm.get('policyNumber');
      const insuredLastName = this.searchPolicyForm.get('insuredLastName');

      this.billingService.decryptParameter(payloadForDecrypt).subscribe(result => {
        policyNumber.setValue(result.policyNumber);
        insuredLastName.setValue(result.insuredLastName);
      
        if (this.searchPolicyForm.get('policyNumber').value !== '' && this.searchPolicyForm.get('insuredLastName').value !== '' && this.searchPolicyForm.get('policyNumber').value !== null && this.searchPolicyForm.get('insuredLastName').value !== null) {
          this.onContinue();
        }
      });
    });
  }

  /**
   * This method is used in the ff: reinstatement amount in HTML, computation for minimum amount validation, and setting the amount for renewal.
   * This should always be synced to other make-a-payment-modal and post-payment-modal component.
   */
  getRenewalOrReinstatementAmountOnly(): number {
    return this.autoReinstatementData.computeRenewalReinstatementAmountWithoutBalance(this.currentTermAmountToPay, this.billingData.renewalAmount);
  }
}
