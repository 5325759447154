import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { OfacSearchResultDTO } from '../../../../../shared/models/data/dto/ofac/ofac-search-result.dto';
import { OfacSearchRequestDTO } from '../../../../../shared/models/data/dto/ofac/ofac-search-request.dto';
import { OfacSearchRefundRequestDTO } from 'app/shared/models/data/dto/ofac/ofac-search-refund-request.dto';
import { OfacSearchService } from './ofac-search.service';
import { OfacSearchData } from '../../../../../modules/submission-management/data/ofac-search.data';
import { OfacSearchNotesModel } from '../../../../../shared/models/ofac/ofac-search-notes.model';
import { OfacSearchConstants } from '../../../../../shared/constants/ofac-search.constants';
import { OfacSearchModel } from '../../../../../shared/models/ofac/ofac-search.model';
import { BindAndIssueData } from 'app/modules/submission-management/data/bind-and-issue.data';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { PolicyDocumentsData } from 'app/modules/policy-management/data/policy-documents.data';
import { PolicyNotesData } from 'app/modules/policy-management/data/policy-notes.data';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from 'app/shared/base-class';
import * as _ from 'lodash';
import { PolicyService } from '../../policy.service';
import { OfacSearchRefundSuspendedPaymentRequestDTO } from 'app/shared/models/data/dto/ofac/ofac-search-refund-suspended-payment-request.dto';
import { AuthService } from '../../../../../core/services/auth.service';

@Injectable()
export class OfacService extends BaseClass {
  ofacObservable: Subject<any> = new Subject<any>();
  constructor(public http: HttpClient,
    protected ofacSearchService: OfacSearchService,
    protected ofacSearchData: OfacSearchData,
    protected bindAndIssueData: BindAndIssueData,
    protected entityRiskData: EntityRiskData,
    protected policyService: PolicyService,
    protected policySummaryData: PolicySummaryData,
    protected policyDocumentsData: PolicyDocumentsData,
    protected policyNotesData: PolicyNotesData,
    protected authService: AuthService) {
    super();
  }

  runOfacOnRefund(firstName: string, lastName: string, suffix: string): void{

    const ofacRefundRequestDto: OfacSearchRefundRequestDTO = {
      firstName: firstName,
      lastName: lastName,
      suffix: suffix,
      riskId: this.entityRiskData?.getRiskId(),
      riskDetailId: this.entityRiskData?.getRiskDetailId()
    };

    this.ofacSearchService.postRefundPayee(ofacRefundRequestDto).pipe(takeUntil(this.stop$)).subscribe(result => {
      if (result){
        this.policyService.getPolicyDocuments(this.entityRiskData?.getRiskId()).pipe(takeUntil(this.stop$)).subscribe(documents => {
          this.policyDocumentsData.mapPolicyDocuments(documents);
        });
      }

      this.policyService.getPolicyNotes(this.entityRiskData?.getRiskId()).pipe(takeUntil(this.stop$)).subscribe(riskBindNotes =>{
        this.policyNotesData.populateNotes(riskBindNotes);
      });
    });
  }

  runOfacOnRefundSuspendedPayment(policyNumber: string, firstName: string, lastName: string, suffix: string): void{

    const ofacRefundRequestDto: OfacSearchRefundSuspendedPaymentRequestDTO = {
      policyNumber: policyNumber,
      firstName: firstName,
      lastName: lastName,
      suffix: suffix
    };

    this.ofacSearchService.postRefundSuspendedPayment(ofacRefundRequestDto).pipe(takeUntil(this.stop$)).subscribe();
  }

  run(ofacSearchRequest: OfacSearchRequestDTO, isBindAndIssueOnLoad: boolean) {
    const result = this.ofacSearchService.postData(ofacSearchRequest).pipe(takeUntil(this.stop$)).subscribe(results => {
      this.ofacSearchData.searchResults = results as OfacSearchResultDTO[];

      // Create a Note base on the OFAC Search Result
      let ofacNote: OfacSearchNotesModel = new OfacSearchNotesModel();

      this.ofacSearchData.ofacSearchModel = new OfacSearchModel();

      this.ofacSearchData.ofacSearchModel.isHit = results.length > 0;

      ofacNote = {
        category: OfacSearchConstants.OfacSearchBindAndIssueNoteDetails.NotesCategory,
        description: this.ofacSearchData.ofacSearchModel.isHit ? this.setValue(ofacSearchRequest.firstName,ofacSearchRequest.lastName, this.ofacSearchData.ofacSearchModel.isHit, isBindAndIssueOnLoad) :
          this.setValue(ofacSearchRequest.firstName,ofacSearchRequest.lastName, this.ofacSearchData.ofacSearchModel.isHit, isBindAndIssueOnLoad),
      };

      const ofacSearchModel: OfacSearchModel = new OfacSearchModel();

      ofacSearchModel.ofacNote = ofacNote;
      this.ofacSearchData.ofacSearchModel = ofacSearchModel;

      this.appendToNotesList();

      this.ofacObservable.next();
    }, error => {
      console.log(error);
    });
  }

  appendToNotesList() {
    let notesListLength = this.bindAndIssueData.notesList.length;

    if (notesListLength > 0) {
      notesListLength++;
    } else {
      notesListLength = 0;
    }

    // Push the note into the bind and issue notes list
    this.bindAndIssueData.notesList.push({
      id: notesListLength,
      description: this.ofacSearchData?.ofacSearchModel?.ofacNote?.description,
      category: this.ofacSearchData?.ofacSearchModel?.ofacNote?.category,
      createdDate: this.authService.getCustomDate(),
      notesId: this.ofacSearchData?.ofacSearchModel?.ofacNote?.notesId,
      riskBindId: this.ofacSearchData?.ofacSearchModel?.ofacNote?.riskBindId
    });
  }

  protected setValue(firstName, lastName, isHit, isBindAndIssueOnLoad) {
    if (isBindAndIssueOnLoad) {
      if (isHit) {
        return `OFAC Search Result - Match ${firstName.toUpperCase()} ${lastName.toUpperCase()} (${OfacSearchConstants.OfacSearchTriggerConstants.SearchTriggerNotBound})`;
      } else {
        return `OFAC Search Result - No Match ${firstName.toUpperCase()} ${lastName.toUpperCase()} (${OfacSearchConstants.OfacSearchTriggerConstants.SearchTriggerNotBound})`;
      }
    } else {
      if (isHit) {
        return `OFAC Search Result - Match ${firstName.toUpperCase()} ${lastName.toUpperCase()} (${OfacSearchConstants.OfacSearchTriggerConstants.SearchTriggerPolicyBound})`;
      } else {
        return `OFAC Search Result - No Match ${firstName.toUpperCase()} ${lastName.toUpperCase()} (${OfacSearchConstants.OfacSearchTriggerConstants.SearchTriggerPolicyBound})`;
      }
    }
  }
}