import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationService } from '../../../core/services/navigation/navigation.service';
import { PathConstants } from '../../../shared/constants/path.constants';
import { ClickTypes } from '../../../shared/enum/click-type.enum';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { take } from 'rxjs/operators';
import { TaskLabelsConstants } from '../../../shared/constants/task.labels.constants';
import { ITaskDTO } from '../../../shared/models/data/dto/task/task.dto';
import { IHideTableItems } from '../../../shared/models/dynamic/table.interface';
import { TaskModalComponent } from '../../dashboard/components/dashboard/task-modal/task-modal.component';
import { TaskData } from '../../dashboard/data/task.data';
import { AuthService } from '../../../core/services/auth.service';
import { SubmissionPageData } from 'app/modules/submission-management/data/submission-page.data';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import * as _ from 'lodash';

@Component({
  selector: 'app-policy-tasks',
  templateUrl: './policy-tasks.component.html',
  styleUrls: ['./policy-tasks.component.scss']
})
export class PolicyTasksComponent implements OnInit {
  @ViewChild('divClick') divClick: ElementRef;
  public genericConstants = GenericConstants;
  isTaskHidden: boolean = false;
  modalRef: BsModalRef | null;
  hideItems: IHideTableItems = {
    fileIcon: true,
    deleteIcon: true,
    checkboxButton: true,
    viewIcon: false
  };
  viewTask: string = 'View Task';
  internal: string = 'internal';

  constructor(
    protected navigationService: NavigationService,
    public taskData: TaskData,
    protected modalService: BsModalService,
    protected authService: AuthService,
    public submissionData: SubmissionPageData
  ) { }

  ngOnInit() {
    this.authService.userType.subscribe(_ => this.hideItems.addButton = this.authService.userType.value?.toLowerCase() !== 'internal');
    if (this.submissionData.policyStatus.value === this.genericConstants.quoteStatus.duw || this.submissionData.policyStatus.value === this.genericConstants.quoteStatus.qexp) {
      this.taskData.getTaskListByPolicy(true);

      setTimeout(() => {
        this.divClick.nativeElement.click();
      }, 200);
    }
  }

  public onClick(clickType?) {
    switch (clickType) {
      case ClickTypes.Back:
        this.navigationService.navigatePolicyRoute(PathConstants.Policy.Policies.Notes);
        break;
      case ClickTypes.Next:
        this.navigationService.navigatePolicyRoute(PathConstants.Policy.Policies.RaterPremiumTable);
        break;
      default:
        break;
    }
  }

  toggleHidingOfTasks(): void {
    this.isTaskHidden = !this.isTaskHidden;
  }

  getTask(taskId: string, isView: boolean): void {
    this.taskData.getTaskDetail(taskId);
    this.taskData.taskDetailNotifier.pipe(take(1)).subscribe(res => {
      if (res.isComplete && !res.hasError) {
        this.editViewTask(res.data, isView);
      }
    });
  }

  editViewTask(data: ITaskDTO, isView: boolean): void {
    const initialState = {
      title: isView ? TaskLabelsConstants.Task.View.ViewTitle : TaskLabelsConstants.Task.Edit.EditTaskTitle,
      isAddTaskModal: false,
      modalData: data,
      action: isView,
      isFromPolicyRequest: true,
      initialTaskNotes: _.concat(data.taskNotes),
      initialTaskDocuments: _.concat(data.taskDocuments)
    };
    this.taskData.isViewTask = isView;
    this.taskData.isExternal = this.authService.userType.value.toLowerCase() !== this.internal;
    this.modalRef = this.showTaskModal(initialState);
  }

  addTask(): void {
    const initialState = {
      title: TaskLabelsConstants.Task.Add.AddTaskTitle,
      isDashboardPage: false,
      isFromPolicyRequest: true
    };
    this.taskData.initializeDetailsOnAdd();
    this.taskData.isExternal = this.authService.userType.value.toLowerCase() !== this.internal;
    this.modalRef = this.showTaskModal(initialState);
  }

  showTaskModal(initialState): BsModalRef {
    return this.modalService.show(TaskModalComponent, {
      initialState,
      class: 'modal-lg modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  pageChanged(event: PageChangedEvent): void {

    if(this.taskData.policyTaskLoading) { return; }

    this.taskData.policyCurrentPage = event.page;

    this.taskData.getTaskListByPolicy(true, this.taskData.policyCurrentPage);

    setTimeout(() => {
      this.divClick.nativeElement.click();
    }, 200);

  }
}
