export class RiskClaimDTO implements IRiskClaimDTO {
  id?: string | undefined;
  riskDetailId?: string | undefined;
  description?: string | undefined;
  claimType?: string | undefined;
  amount?: number | undefined;
  notes?: string | undefined;
  claimSource?: string | undefined;
  chargeableNonChargable?: boolean;
  otherQuestion?: string | undefined;
  lossDate?: Date | string | any | undefined;
  catCode?: string | undefined;
  claimStatus?: string | undefined;
  policyType?: string | undefined;
  isDispute?: boolean | undefined;
  claimNumber?: string | undefined;
  individualMatchType?: string | undefined;
  isAPlus?: boolean;
  createdBy?: number | undefined;
  createdByFullName?: string | undefined;
  createdDate?: Date | undefined;
  isActive?: boolean;
  isAdd?: boolean;
  isComparative?: boolean;
  isLossAssociatedToHome?: boolean | undefined;
  isLossAssociatedToInsured?: boolean | undefined;
  totalOutstandingReserve?: number | undefined;
  totalRecoveriesAndReimbursement?: number | undefined;
  pcsCode?: string | undefined;
  isExternal?: boolean | undefined;

  constructor(data?: IRiskClaimDTO) {
      if (data) {
          for (const property in data) {
              if (data.hasOwnProperty(property)) {
                  (<any>this)[property] = (<any>data)[property];
              }
          }
      }
  }
}

export interface IRiskClaimDTO {
  id?: string | undefined;
  riskDetailId?: string | undefined;
  description?: string | undefined;
  claimType?: string | undefined;
  amount?: number | undefined;
  notes?: string | undefined;
  claimSource?: string | undefined;
  chargeableNonChargable?: boolean;
  otherQuestion?: string | undefined;
  lossDate?: Date | string | undefined;
  catCode?: string | undefined;
  claimStatus?: string | undefined;
  policyType?: string | undefined;
  isDispute?: boolean | undefined;
  claimNumber?: string | undefined;
  individualMatchType?: string | undefined;
  isAPlus?: boolean;
  createdBy?: number | undefined;
  createdByFullName?: string | undefined;
  createdDate?: Date | undefined;
  isActive?: boolean;
  isAdd?: boolean;
  isComparative?: boolean;
  isLossAssociatedToHome?: boolean | undefined;
  isLossAssociatedToInsured?: boolean | undefined;
  totalOutstandingReserve?: number | undefined;
  totalRecoveriesAndReimbursement?: number | undefined;
  pcsCode?: string | undefined;
  isExternal?: boolean | undefined;
}
