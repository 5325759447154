export const LvCancellationType = [
  {
    id: 1,
    code: 'IR',
    description: 'Insured Request',
    isActive: true
  },
  {
    id: 2,
    code: 'BR',
    description: 'Billing Reasons',
    isActive: true
  },
  {
    id: 3,
    code: 'UR',
    description: 'Underwriter Reasons',
    isActive: true
  }
];

export const LvCancellationTypeReason = [
  {
    id: 1,
    code: 'IRRPE',
    description: 'Insured Request - Replaced Policy elsewhere',
    cancellationTypeCode: 'IR',
    isFreeFormRequired: false,
    isActive: true
  },
  {
    id: 2,
    code: 'IRCNN',
    description: 'Insured Request - Coverage not needed',
    cancellationTypeCode: 'IR',
    isFreeFormRequired: false,
    isActive: true
  },
  {
    id: 3,
    code: 'IRO',
    description: 'Insured Request - Other',
    cancellationTypeCode: 'IR',
    isFreeFormRequired: false,
    isActive: true
  },
  {
    id: 4,
    code: 'IRRC',
    description: 'Insured Request - Rewritten with Centauri',
    cancellationTypeCode: 'IR',
    isFreeFormRequired: false,
    isActive: true
  },
  {
    id: 5,
    code: 'NPP',
    description: 'Non Payment of Premium',
    cancellationTypeCode: 'BR',
    isFreeFormRequired: false,
    isActive: true
  },
  {
    id: 6,
    code: 'PRNSF',
    description: 'Payment returned - Non-sufficient funds',
    cancellationTypeCode: 'BR',
    isFreeFormRequired: false,
    isActive: true
  },
  {
    id: 7,
    code: 'NPPFC',
    description: 'Non Payment to Premium Finance Company',
    cancellationTypeCode: 'BR',
    isFreeFormRequired: false,
    isActive: true
  },
  {
    id: 8,
    code: 'SCIR',
    description: 'Substantial Change in  Risk',
    cancellationTypeCode: 'UR',
    isFreeFormRequired: true,
    isActive: true
  },
  {
    id: 9,
    code: 'PRC',
    description: 'Property Condition',
    cancellationTypeCode: 'UR',
    isFreeFormRequired: true,
    isActive: true
  },
  {
    id: 10,
    code: 'LHA',
    description: 'Liability hazard',
    cancellationTypeCode: 'UR',
    isFreeFormRequired: true,
    isActive: true
  },
  {
    id: 11,
    code: 'AOH',
    description: 'Age of Home',
    cancellationTypeCode: 'UR',
    isFreeFormRequired: true,
    isActive: true
  },
  {
    id: 12,
    code: 'LOE',
    description: 'Loss Experience',
    cancellationTypeCode: 'UR',
    isFreeFormRequired: true,
    isActive: true
  },
  {
    id: 13,
    code: 'OOU',
    description: 'Occupancy or Use',
    cancellationTypeCode: 'UR',
    isFreeFormRequired: true,
    isActive: true
  },
  {
    id: 14,
    code: 'EXM',
    description: 'Exposure Management',
    cancellationTypeCode: 'UR',
    isFreeFormRequired: false,
    isActive: true
  },
  {
    id: 15,
    code: 'FCUWR',
    description: 'Fail to Comply with UW Req',
    cancellationTypeCode: 'UR',
    isFreeFormRequired: true,
    isActive: true
  },
  {
    id: 16,
    code: 'OTHER',
    description: 'Other',
    cancellationTypeCode: 'UR',
    isFreeFormRequired: true,
    isActive: true
  },
  {
    id: 17,
    code: 'MMS',
    description: 'Material Misrep',
    cancellationTypeCode: 'UR',
    isFreeFormRequired: true,
    isActive: true
  },
  {
    id: 18,
    code: 'RW',
    description: 'Rewrite',
    cancellationTypeCode: 'RW',
    isFreeformRequired: false,
    isActive: true
  }
];

export const LvCancellationTypeReasonStateMapping = [
  {
    id: 1,
    stateCode: 'AL',
    cancellationTypeReasonCode: 'IRRPE',
    isActive: true
  },
  {
    id: 2,
    stateCode: 'AL',
    cancellationTypeReasonCode: 'IRCNN',
    isActive: true
  },
  {
    id: 3,
    stateCode: 'AL',
    cancellationTypeReasonCode: 'IRO',
    isActive: true
  },
  {
    id: 4,
    stateCode: 'AL',
    cancellationTypeReasonCode: 'IRRC',
    isActive: true
  },
  {
    id: 5,
    stateCode: 'AL',
    cancellationTypeReasonCode: 'NPP',
    isActive: true
  },
  {
    id: 6,
    stateCode: 'AL',
    cancellationTypeReasonCode: 'PRNSF',
    isActive: true
  },
  {
    id: 7,
    stateCode: 'AL',
    cancellationTypeReasonCode: 'NPPFC',
    isActive: true
  },
  {
    id: 8,
    stateCode: 'AL',
    cancellationTypeReasonCode: 'SCIR',
    isActive: true
  },
  {
    id: 9,
    stateCode: 'AL',
    cancellationTypeReasonCode: 'PRC',
    isActive: true
  },
  {
    id: 10,
    stateCode: 'AL',
    cancellationTypeReasonCode: 'LHA',
    isActive: true
  },
  {
    id: 11,
    stateCode: 'AL',
    cancellationTypeReasonCode: 'AOH',
    isActive: true
  },
  {
    id: 12,
    stateCode: 'AL',
    cancellationTypeReasonCode: 'LOE',
    isActive: true
  },
  {
    id: 13,
    stateCode: 'AL',
    cancellationTypeReasonCode: 'OOU',
    isActive: true
  },
  {
    id: 14,
    stateCode: 'AL',
    cancellationTypeReasonCode: 'EXM',
    isActive: true
  },
  {
    id: 15,
    stateCode: 'AL',
    cancellationTypeReasonCode: 'FCUWR',
    isActive: true
  },
  {
    id: 16,
    stateCode: 'AL',
    cancellationTypeReasonCode: 'OTHER',
    isActive: true
  },
  {
    id: 17,
    stateCode: 'AL',
    cancellationTypeReasonCode: 'MMS',
    isActive: true
  },
  {
    id: 18,
    stateCode: 'FL',
    cancellationTypeReasonCode: 'IRRPE',
    isActive: true
  },
  {
    id: 19,
    stateCode: 'FL',
    cancellationTypeReasonCode: 'IRCNN',
    isActive: true
  },
  {
    id: 20,
    stateCode: 'FL',
    cancellationTypeReasonCode: 'IRO',
    isActive: true
  },
  {
    id: 21,
    stateCode: 'FL',
    cancellationTypeReasonCode: 'IRRC',
    isActive: true
  },
  {
    id: 22,
    stateCode: 'FL',
    cancellationTypeReasonCode: 'NPP',
    isActive: true
  },
  {
    id: 23,
    stateCode: 'FL',
    cancellationTypeReasonCode: 'PRNSF',
    isActive: true
  },
  {
    id: 24,
    stateCode: 'FL',
    cancellationTypeReasonCode: 'SCIR',
    isActive: true
  },
  {
    id: 25,
    stateCode: 'FL',
    cancellationTypeReasonCode: 'PRC',
    isActive: true
  },
  {
    id: 26,
    stateCode: 'FL',
    cancellationTypeReasonCode: 'LHA',
    isActive: true
  },
  {
    id: 27,
    stateCode: 'FL',
    cancellationTypeReasonCode: 'AOH',
    isActive: true
  },
  {
    id: 28,
    stateCode: 'FL',
    cancellationTypeReasonCode: 'LOE',
    isActive: true
  },
  {
    id: 29,
    stateCode: 'FL',
    cancellationTypeReasonCode: 'OOU',
    isActive: true
  },
  {
    id: 30,
    stateCode: 'FL',
    cancellationTypeReasonCode: 'EXM',
    isActive: true
  },
  {
    id: 31,
    stateCode: 'FL',
    cancellationTypeReasonCode: 'FCUWR',
    isActive: true
  },
  {
    id: 32,
    stateCode: 'FL',
    cancellationTypeReasonCode: 'OTHER',
    isActive: true
  },
  {
    id: 33,
    stateCode: 'FL',
    cancellationTypeReasonCode: 'MMS',
    isActive: true
  },
  {
    id: 34,
    stateCode: 'LA',
    cancellationTypeReasonCode: 'IRRPE',
    isActive: true
  },
  {
    id: 35,
    stateCode: 'LA',
    cancellationTypeReasonCode: 'IRCNN',
    isActive: true
  },
  {
    id: 36,
    stateCode: 'LA',
    cancellationTypeReasonCode: 'IRO',
    isActive: true
  },
  {
    id: 37,
    stateCode: 'LA',
    cancellationTypeReasonCode: 'IRRC',
    isActive: true
  },
  {
    id: 38,
    stateCode: 'LA',
    cancellationTypeReasonCode: 'NPP',
    isActive: true
  },
  {
    id: 39,
    stateCode: 'LA',
    cancellationTypeReasonCode: 'PRNSF',
    isActive: true
  },
  // {
  //   id: 40,
  //   stateCode: 'LA',
  //   cancellationTypeReasonCode: 'NPPFC',
  //   isActive: true
  // },
  {
    id: 41,
    stateCode: 'LA',
    cancellationTypeReasonCode: 'SCIR',
    isActive: true
  },
  {
    id: 42,
    stateCode: 'LA',
    cancellationTypeReasonCode: 'PRC',
    isActive: true
  },
  {
    id: 43,
    stateCode: 'LA',
    cancellationTypeReasonCode: 'LHA',
    isActive: true
  },
  {
    id: 44,
    stateCode: 'LA',
    cancellationTypeReasonCode: 'AOH',
    isActive: true
  },
  {
    id: 45,
    stateCode: 'LA',
    cancellationTypeReasonCode: 'LOE',
    isActive: true
  },
  {
    id: 46,
    stateCode: 'LA',
    cancellationTypeReasonCode: 'OOU',
    isActive: true
  },
  {
    id: 47,
    stateCode: 'LA',
    cancellationTypeReasonCode: 'EXM',
    isActive: true
  },
  {
    id: 48,
    stateCode: 'LA',
    cancellationTypeReasonCode: 'FCUWR',
    isActive: true
  },
  {
    id: 49,
    stateCode: 'LA',
    cancellationTypeReasonCode: 'OTHER',
    isActive: true
  },
  {
    id: 50,
    stateCode: 'LA',
    cancellationTypeReasonCode: 'MMS',
    isActive: true
  },
  {
    id: 51,
    stateCode: 'MS',
    cancellationTypeReasonCode: 'IRRPE',
    isActive: true
  },
  {
    id: 52,
    stateCode: 'MS',
    cancellationTypeReasonCode: 'IRCNN',
    isActive: true
  },
  {
    id: 53,
    stateCode: 'MS',
    cancellationTypeReasonCode: 'IRO',
    isActive: true
  },
  {
    id: 54,
    stateCode: 'MS',
    cancellationTypeReasonCode: 'IRRC',
    isActive: true
  },
  {
    id: 55,
    stateCode: 'MS',
    cancellationTypeReasonCode: 'NPP',
    isActive: true
  },
  {
    id: 56,
    stateCode: 'MS',
    cancellationTypeReasonCode: 'PRNSF',
    isActive: true
  },
  {
    id: 57,
    stateCode: 'MS',
    cancellationTypeReasonCode: 'NPPFC',
    isActive: true
  },
  {
    id: 58,
    stateCode: 'MS',
    cancellationTypeReasonCode: 'SCIR',
    isActive: true
  },
  {
    id: 59,
    stateCode: 'MS',
    cancellationTypeReasonCode: 'PRC',
    isActive: true
  },
  {
    id: 60,
    stateCode: 'MS',
    cancellationTypeReasonCode: 'LHA',
    isActive: true
  },
  {
    id: 61,
    stateCode: 'MS',
    cancellationTypeReasonCode: 'AOH',
    isActive: true
  },
  {
    id: 62,
    stateCode: 'MS',
    cancellationTypeReasonCode: 'LOE',
    isActive: true
  },
  {
    id: 63,
    stateCode: 'MS',
    cancellationTypeReasonCode: 'OOU',
    isActive: true
  },
  {
    id: 64,
    stateCode: 'MS',
    cancellationTypeReasonCode: 'EXM',
    isActive: true
  },
  {
    id: 65,
    stateCode: 'MS',
    cancellationTypeReasonCode: 'FCUWR',
    isActive: true
  },
  {
    id: 66,
    stateCode: 'MS',
    cancellationTypeReasonCode: 'OTHER',
    isActive: true
  },
  {
    id: 67,
    stateCode: 'MS',
    cancellationTypeReasonCode: 'MMS',
    isActive: true
  },
  {
    id: 68,
    stateCode: 'SC',
    cancellationTypeReasonCode: 'IRRPE',
    isActive: true
  },
  {
    id: 69,
    stateCode: 'SC',
    cancellationTypeReasonCode: 'IRCNN',
    isActive: true
  },
  {
    id: 70,
    stateCode: 'SC',
    cancellationTypeReasonCode: 'IRO',
    isActive: true
  },
  {
    id: 71,
    stateCode: 'SC',
    cancellationTypeReasonCode: 'IRRC',
    isActive: true
  },
  {
    id: 72,
    stateCode: 'SC',
    cancellationTypeReasonCode: 'NPP',
    isActive: true
  },
  {
    id: 73,
    stateCode: 'SC',
    cancellationTypeReasonCode: 'PRNSF',
    isActive: true
  },
  {
    id: 74,
    stateCode: 'SC',
    cancellationTypeReasonCode: 'NPPFC',
    isActive: true
  },
  {
    id: 75,
    stateCode: 'SC',
    cancellationTypeReasonCode: 'SCIR',
    isActive: true
  },
  {
    id: 76,
    stateCode: 'SC',
    cancellationTypeReasonCode: 'PRC',
    isActive: true
  },
  {
    id: 77,
    stateCode: 'SC',
    cancellationTypeReasonCode: 'LHA',
    isActive: true
  },
  {
    id: 78,
    stateCode: 'SC',
    cancellationTypeReasonCode: 'AOH',
    isActive: true
  },
  {
    id: 79,
    stateCode: 'SC',
    cancellationTypeReasonCode: 'LOE',
    isActive: true
  },
  {
    id: 80,
    stateCode: 'SC',
    cancellationTypeReasonCode: 'OOU',
    isActive: true
  },
  {
    id: 81,
    stateCode: 'SC',
    cancellationTypeReasonCode: 'EXM',
    isActive: true
  },
  {
    id: 82,
    stateCode: 'SC',
    cancellationTypeReasonCode: 'FCUWR',
    isActive: true
  },
  {
    id: 83,
    stateCode: 'SC',
    cancellationTypeReasonCode: 'OTHER',
    isActive: true
  },
  {
    id: 84,
    stateCode: 'SC',
    cancellationTypeReasonCode: 'MMS',
    isActive: true
  },
  {
    id: 85,
    stateCode: 'TX',
    cancellationTypeReasonCode: 'IRRPE',
    isActive: true
  },
  {
    id: 86,
    stateCode: 'TX',
    cancellationTypeReasonCode: 'IRCNN',
    isActive: true
  },
  {
    id: 87,
    stateCode: 'TX',
    cancellationTypeReasonCode: 'IRO',
    isActive: true
  },
  {
    id: 88,
    stateCode: 'TX',
    cancellationTypeReasonCode: 'IRRC',
    isActive: true
  },
  {
    id: 89,
    stateCode: 'TX',
    cancellationTypeReasonCode: 'NPP',
    isActive: true
  },
  {
    id: 90,
    stateCode: 'TX',
    cancellationTypeReasonCode: 'PRNSF',
    isActive: true
  },
  {
    id: 91,
    stateCode: 'TX',
    cancellationTypeReasonCode: 'NPPFC',
    isActive: true
  },
  {
    id: 92,
    stateCode: 'TX',
    cancellationTypeReasonCode: 'SCIR',
    isActive: true
  },
  {
    id: 93,
    stateCode: 'TX',
    cancellationTypeReasonCode: 'PRC',
    isActive: true
  },
  {
    id: 94,
    stateCode: 'TX',
    cancellationTypeReasonCode: 'LHA',
    isActive: true
  },
  {
    id: 95,
    stateCode: 'TX',
    cancellationTypeReasonCode: 'AOH',
    isActive: true
  },
  {
    id: 96,
    stateCode: 'TX',
    cancellationTypeReasonCode: 'LOE',
    isActive: true
  },
  {
    id: 97,
    stateCode: 'TX',
    cancellationTypeReasonCode: 'OOU',
    isActive: true
  },
  {
    id: 98,
    stateCode: 'TX',
    cancellationTypeReasonCode: 'FCUWR',
    isActive: true
  },
  {
    id: 99,
    stateCode: 'TX',
    cancellationTypeReasonCode: 'OTHER',
    isActive: true
  },
  {
    id: 100,
    stateCode: 'TX',
    cancellationTypeReasonCode: 'MMS',
    isActive: true
  }
];
