import { Action, createReducer, on } from '@ngrx/store';
import { CommissionPaymentState, initialState } from './commission-payment.state';
import * as fromCommissionPaymentActions from 'app/store/commission/commission-payment/commission-payment.actions';

const _commissionPaymentReducer = createReducer(
  initialState,
  on(
    fromCommissionPaymentActions.updateCommissionPaymentIsLoading,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on (
    fromCommissionPaymentActions.updateCommissionPaymentListFromCommissionPaymentData,
    (state, { commissionPaymentList }) => ({ ...state, commissionPaymentList: commissionPaymentList})
  ),
  on (
    fromCommissionPaymentActions.updateFilteredCommissionPaymentListFromCommissionPaymentData,
    (state, { filteredCommissionPaymentList }) => ({ ...state, filteredCommissionPaymentList: filteredCommissionPaymentList})
  )
);

export function commissionPaymentReducer(
  state: CommissionPaymentState | null,
  action: Action
) {
  return _commissionPaymentReducer(state, action);
}