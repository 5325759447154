const getStarAdvantagePackageTooltip = (CovCLimit: number): string => {
  return `<div>This Package Includes:</div>
  <li>Personal Property Replacement Cost</li>
  <li>20% of Coverage A for Increased Replacement Cost</li>
  <li>$5,000 Water Backup/Sump Pump Overflow Coverage</li>
  <li>25% of Coverage A for Ordinance or Law Coverage</li>
  <li>Special Personal Property Coverage</li>
  <li>Personal Injury Coverage</li>
  <li>${CovCLimit}% Coverage C limit</li>
  <li>$10,000 Loss Assessment Coverage</li>
  <li>$2,000 limit for Credit Card, Fund Transfer Card, Forgery and Counterfeit Money</li>
  <li>$500 for Lock Replacement Coverage</li>
  <li>$1,000 limit for Fire Department Service Charge</li>
  <p></p>
  <div>Increased Special Limits:<div>
  <li>$5,000 limit ($2,500 limit per item) on Jewelry, Watches, Furs, Precious and Semi-Precious Stones</li>
  <li>$300 on money, bank notes, gold, etc.</li>
  <li>$2,500 on securities, deeds, letters of credit, etc.</li>
  <li>$3,500 on firearms</li>
  <li>$4,000 on silverware, pewterware, tea sets, etc.</li>
  <li>$3,000 on business property on the residence premises</li>
  <li>$750 on business property away from the residence premises.</li>`;
};

export const EndorsementsLabelsConstants = {
  limitedFungi: 'Limited Fungi, Wet or Dry Rot, or Bacteria Coverage:',
  limitedFungiLimit: 'Limit:',
  personalProperty: 'Personal Property Replacement Cost Loss Settlement:',
  waterBackup: 'Water Back Up and Sump Overflow:',
  waterBackupLimit: 'Limit:',
  waterBackupDeductible: 'Deductible:',
  limitedWater: 'Limited Water Damage Coverage:',
  limitedWaterLimit: 'Limit:',
  limitedWaterLimitRequired: 'Limit',
  limitedWaterTooltip: `<div>Limited Water Damage can only be selected if the Water Damage Exclusion is attached</div>`,
  ordinanceLawOrCov: 'Ordinance or Law Coverage:',
  ordinanceLaw: 'Percentage',
  ordinanceLawOrCovToolTip: '<div>Florida law requires insurers to offer policyholders the option of purchasing Ordinance or Law coverage at 25% or 50% of Coverage A. If an increased limit is not selected, coverage defaults to the limit included in the base policy which is 10% of the Coverage A limit shown on your declarations page, and a rejection form must be signed.</div>',
  lossAssessment: 'Loss Assessment Coverage:',
  lossAssessmentLimit: 'Limit',
  screenedEnclosure: 'Screened Enclosure & Carport Coverage',
  screenedEnclosureLimit: 'Limit',
  personalInjury: 'Personal Injury:',
  equipmentBreakdown: 'Equipment Breakdown Enhancement Endorsement:',
  identityFraud: 'Identity Fraud Expense Coverage:',
  starPackage: 'Star Package:',
  starPackageTooltip: `<div>This Package Includes:</div>
  <li>Personal Property Replacement Cost</li>
  <li>25% of Coverage A for Ordinance or Law Coverage</li>
  <li>$5,000 Water Backup/Sump Pump Overflow Coverage</li>
  <li>Personal Injury Coverage</li>
  <li>50% Coverage C limit</li>
  <li>$5,000 Loss Assessment Coverage</li>
  <li>$1,000 limit for Credit Card, Fund Transfer Card, Forgery and Counterfeit Money</li>
  <li>$250 for Lock Replacement Coverage</li>
  <li>$750 limit for Fire Department Service Charge</li>
  <li>$3,000 limit ($1,500 limit per item) for loss by theft of Jewerly, Watches, Furs, Precious and Semi-precious Stones.</li>`,
  starAdvantagePackage: 'Star Advantage Package:',
  starAdvantagePackageTooltip: getStarAdvantagePackageTooltip(70),
  starAdvantagePackageTooltipNew: getStarAdvantagePackageTooltip(75),
  waterDamage: 'Water Damage Exclusion Endorsement:',
  animalLiability: 'Animal Liability Endorsement:',
  animalLiabilityLimit: 'Limit:',
  screenedEnclosureCarport:
    'Screened Enclosure & Carport Cov - Replacement Cost:',
  screenedEnclosureCarportLimit: 'Limit',
  homeComputer: 'Home Computer Coverage:',
  homeComputerLimit: 'Limit',
  serviceLine: 'Service Line Enhancement Endorsement:',
  serviceLineLimit: 'Limit:',
  serviceLineDeductible: 'Deductible:',
  golfCart: 'Golf Cart Physical Damage & Liability Endorsement:',
  numberOfGolfCarts: '# of Golf Carts',
  liabilityType: 'Liability Type',
  increasedReplacement: 'Increased Replacement Cost on Dwelling:',
  ordinanceLawCoverage: 'Ordinance or Law Coverage - 50%:',
  specialPersonal: 'Special Personal Property Coverage:',
  coverageC: 'Coverage C Increased Special Limits of Liability:',
  jewelryIncreased: 'Jewelry, Watches and Furs',
  jewelryIncreasedAmount: 'Increased Amount',
  jewelryTotal: 'Total Amount:',
  silverwareIncreased: 'Silverware, Goldware, Pewterware',
  silverwareIncreasedAmount: 'Increased Amount',
  silverwareTotal: 'Total Amount:',
  premisesAlarm: 'Premises Alarm or Fire Protection System:',
  sinkholeLoss: 'Sinkhole Loss Coverage:',
  seasonalDwelling: 'Seasonal Dwelling Endorsement:',
  premiumAdjustment: 'Premium Adjustment:',
  premiumAdjustmentAmount: 'Premium Adjustment Amount',
  consentToRate: 'Consent To Rate:',
  consentToRateAmount: 'Consent To Rate Amount',
  waterBackupLimitAmount: `$5,000`,
  waterBackupDeductibleAmount: `$1,000`,
  limitedWaterLimitAmount: 10000,
  limitedWaterLimitInfoMessage: 'You may have entered a value that is less than $10,000 in Limit field. Limit has been set to the latest valid value.',
  animalLiabilityLimitAmount: `$50,000`,
  serviceLineLimitAmount: `$10,000`,
  serviceLineDeductibleAmount: `$500`,
  limitWaterLabel: 'limitedWater',
  waterBackupLabel: 'waterBackup',
  animalLiabilityLabel: 'animalLiability',
  serviceLineLabel: 'serviceLine',
  personalPropertyAtOtherResidences: 'Personal Property at other Residences:',
  personalPropertyAtOtherResidencesLimit: 'Limit',
  leaseTerm: 'Lease Term:',
  unitOwnersRentalToOthers: 'Unit-Owners Rental To Others:',
  unitOwnersCoverageASpecialCoverage: 'Unit-Owners Coverage A - Special Coverage:',
  replacementCost: 'RC',
  actualCashValue: 'ACV',
  fungiWetOrDryRot: 'Fungi, Wet or Dry Rot, or Bacteria Increased Amount of Coverage:',
  fungiWetOrDryRotLimit: 'Limit:',
  personalLiability: 'Personal Liability:',
  premisesLiability: 'Premises Liability:',
  permittedIncidentalOccupancies: 'Permitted Incidental Occupancies:',
  descriptionOfOccupancy: 'Description of Occupancy:',
  permittedIncidentalOccupanciesLiability: 'Permitted Incidental Occupancies - Liability:',
  descriptionOfBusiness: 'Description of Business:',
  businessLocation: 'Business Location:',
  limitedTheftCoverage: 'Limited Theft Coverage:',
  vacancyEndorsement: 'Vacancy Endorsement:',
  windstormExteriorPaintoOrWaterproofingExclusionSeacoastFlorida: 'Windstorm Exterior Paint or Waterproofing Exclusion - Seacoast - Florida:',
  businessLocationMustBeCompleted: 'Business Location must be completed',
  descriptionOfBusinessMustBeCompleted: 'Description of Business must be completed',
  descriptionOfOccupancyMustBeCompleted : 'Description of Occupancy must be completed',
  waterDamageMustBeAttached: 'Water Damage Exclusion must be attached',
  limitedWaterMustBeAttached: 'Limited Water Damage Endorsement must be attached',
  roofSurfacingPaymentScheduleEndorsement: 'Roof Surfacing Payment Schedule Endorsement',
  rspsMustBeAttached: 'Roof Surfacing Payment Schedule must be attached',
  rspsNotification: 'Roof Surfacing Payment Schedule is returned to {0}'
};
