export class RenewalOfferRequestDTO implements IRenewalOfferRequestDTO {
  expiringPolicyRiskId?: string | undefined;
  renewalPolicyRiskId?: string | undefined;
  renewalPolicyEffectiveDate?: any | undefined;
  paymentPlan?: string | undefined;

  constructor(data?: RenewalOfferRequestDTO) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }
}

export interface IRenewalOfferRequestDTO {
  expiringPolicyRiskId?: string | undefined;
  renewalPolicyRiskId?: string | undefined;
  renewalPolicyEffectiveDate?: any | undefined;
  paymentPlan?: string | undefined;
}