import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubmissionComponent } from './submission.component';
import { PathConstants } from '../../../shared/constants/path.constants';
import { RaterPremiumResolverService } from 'app/core/services/submission/rater-premium/rater-premium-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: SubmissionComponent,
    children: [
      {
        path: PathConstants.Submission.Submission.CoveragesAndDeduction,
        loadChildren: () => import('./coverages/coverages.module').then(m => m.CoveragesModule)
      },
      {
        path: PathConstants.Submission.Submission.Endorsements,
        loadChildren: () => import('./endorsements/endorsements.module').then(m => m.EndorsementsModule)
      },
      {
        path: PathConstants.Submission.Submission.Claims,
        loadChildren: () => import('./claims/claims.module').then(m => m.ClaimsModule)
      },
      {
        path: PathConstants.Submission.Submission.Property,
        loadChildren: () => import('./property/property.module').then(m => m.PropertyModule)
      },
      {
        path: PathConstants.Submission.Submission.UWQuestions,
        loadChildren: () => import('./uw-questions/uw-questions.module').then(m => m.UwQuestionsModule)
      },
      {
        path: PathConstants.Submission.Submission.Interests,
        loadChildren: () => import('./interests/interests.module').then(m => m.InterestsModule)
      },
      {
        path: PathConstants.Submission.Submission.UWApproval,
        loadChildren: () => import('./uw-approval/uw-approval.module').then(m => m.UwApprovalModule)
      },
      {
        path: PathConstants.Submission.Submission.BindAndIssue,
        loadChildren: () => import('./bind-and-issue/bind-and-issue.module').then(m => m.BindAndIssueModule)
      },
      {
        path: PathConstants.Submission.Submission.RaterPremiumTable,
        loadChildren: () => import('./rater-premium-table/rater-premium-table.module').then(m => m.RaterPremiumTableModule)
      },
      { path: '', redirectTo: PathConstants.Submission.Submission.CoveragesAndDeduction, pathMatch: 'full' },
      { path: '**', redirectTo: '/404', pathMatch: 'full' },
    ]
  },
  { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubmissionRoutingModule { }
