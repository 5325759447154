import { Action, createReducer, on } from "@ngrx/store";
import * as fromSubmissionUWRsActions from "app/store/submission-uwrs/submission-uwrs.actions";
import { initialState, SubmissionUWRsState } from "./submission-uwrs.state";

const _submissionUWRsReducer = createReducer(
  initialState,
  on(
    fromSubmissionUWRsActions.updateSubmissionUWRsIsLoadingFromUwApprovalSavingData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromSubmissionUWRsActions.updateSubmissionUWRsListFromUwApprovalSavingData,
    (state, { list }) => ({ ...state, list: list })
  ),
  on(
    fromSubmissionUWRsActions.updateSubmissionUWRsTableRowsFromUwApprovalData,
    (state, { tableRows }) => ({ ...state, tableRows: tableRows })
  )
);

export function submissionUWRsReducer(
  state: SubmissionUWRsState | null,
  action: Action
) {
  return _submissionUWRsReducer(state, action);
}