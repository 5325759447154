import { Injectable } from '@angular/core';
import { BaseClass } from 'app/shared/base-class';
import { CoveragesData } from 'app/modules/submission-management/data/coverages.data';
import { PropertyData } from 'app/modules/submission-management/data/property.data';
import { EndorsementsData } from 'app/modules/submission-management/data/endorsements.data';
import { ClaimsData } from 'app/modules/submission-management/data/claims.data';
import { LvRaterDeductibleHUR } from 'app/shared/constants/quick-quote.options.constants';
import * as _ from 'lodash';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { RiskDetailsDTO } from 'app/shared/models/data/dto/quick-quote/risk-details.dto';
import { UWRHelpers } from './uwr-helpers.service';

@Injectable({
  providedIn: 'root',
})
export class RenewalUWR extends BaseClass {
  constructor(
    protected coveragesData: CoveragesData,
    protected propertyData: PropertyData,
    protected endorsementsData: EndorsementsData,
    protected claimsData: ClaimsData,
    protected entityRiskData: EntityRiskData,
    public policySummaryData: PolicySummaryData,
    protected uwrHelpers: UWRHelpers
  ) {
    super();
  }

  get parentRiskDetail(): RiskDetailsDTO {
    return this.entityRiskData.renewalBackup;
  }

  renewalUWR1(): boolean {
    // CNTSUP-1790 Remove for midterm endorsement
    return false;
  }

  renewalUWR2(): boolean {
    // This will not be trigger since distance to coast is disabled in UI can't be edit
    return false;
  }

  renewalUWR3(): boolean {
    // Involved update in AOP Coverage page
    const parentAOP = Math.trunc(Number(this.parentRiskDetail?.riskCoverages[0].allOtherPerilsDeductible));
    const aop = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('allOtherPerilsId')?.value));

    return parentAOP !== aop;
  }

  renewalUWR4(): boolean {
    const parentYearBuilt = this.parentRiskDetail?.riskProperties[0]?.yearBuilt;
    const parentLimitedWater = this.parentRiskDetail?.riskEndorsements[0]?.isLimitedWaterDamage;
    const yearBuilt = this.propertyData.propertyDetails.get('yearBuilt')?.value;
    const limitedWater = this.endorsementsData.endorsementsGroup.get('limitedWater')?.value;

    return parentYearBuilt !== yearBuilt || parentLimitedWater !== limitedWater;
  }

  renewalUWR5(): boolean {
    let isCoverageChanged: boolean = false;

    if (this.uwrHelpers.isHO3orDP3) {
      const parentCoverageA = Math.trunc(Number(this.parentRiskDetail?.riskCoverages[0]?.covA));
      const coverageA = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA')?.value));
      isCoverageChanged = parentCoverageA !== coverageA;

    } else {
      isCoverageChanged = false;
    }

    const parentHurricaneDeductible = this.parentRiskDetail?.riskCoverages[0]?.hurricaneDeductible;
    const hurricaneDeductible = this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid')?.value;
    const isHurDeductibleChanged: boolean = parentHurricaneDeductible !== hurricaneDeductible;

    return this.uwrHelpers.isHO3orDP3 && (isCoverageChanged || isHurDeductibleChanged);
  }

  renewalUWR9(): boolean {
    // Invovled Update in:
    //  yearBuit > Property page
    //  waterDamage > Endorsement page
    const parentYearBuilt = this.parentRiskDetail?.riskProperties[0]?.yearBuilt;
    const parentWaterDamage = this.parentRiskDetail?.riskEndorsements[0]?.isWaterDamageExclusion ?? false;
    const yearBuilt = Math.trunc(Number(this.propertyData.propertyDetails.get('yearBuilt')?.value));
    const waterDamage = this.endorsementsData.endorsementsGroup.get('waterDamage')?.value;

    return parentYearBuilt !== yearBuilt || parentWaterDamage !== waterDamage;
  }

  renewalUWR14(): boolean {
    // Related on Flood assist API not yet implemented.
    // Involve Update in:
    //    floodCoverage > Coverage Page > Flood Section
    //    lowestFloorElevattion > Coverage Page > Flood Section
    //    baseFlood > Coverage Page > Flood Section
    const originalFloodCoverage = this.parentRiskDetail?.riskFloods[0]?.isPrivateFloodCov;
    const parentlowestFloorElevation = Math.trunc(Number(this.parentRiskDetail?.riskFloods[0]?.lowestFloorElevation));
    const parentbaseFlood = Math.trunc(Number(this.parentRiskDetail?.riskFloods[0]?.baseFloodElevation));
    const floodCoverage = this.coveragesData.floodForm.get('privateFloodCoverage')?.value;
    const lowestFloorElevation = +this.coveragesData.floodForm.get('lowestFloodElevation')?.value;
    const baseFlood = +this.coveragesData.floodForm.get('baseFloodElevation')?.value;
    return (originalFloodCoverage !== floodCoverage) ||
      (parentlowestFloorElevation !== lowestFloorElevation) ||
      (parentbaseFlood !== baseFlood);
  }

  renewalUWR15(): boolean {
    // Input Update in:
    //    floodCoverage > Coverage Page > Flood Section
    //    floodLossAtThisLocation > Coverage Page > Flood Section
    //    claimList where claimType === flood

    const parentFloodCoverage = this.parentRiskDetail?.riskFloods[0]?.isPrivateFloodCov;
    const floodCoverage = this.coveragesData.floodForm.get('privateFloodCoverage')?.value;
    let hasNewClaim = false;

    this.claimsData.claimList.forEach(item => {
      if (!item.isFromParent && item.claimType === 'FLOOD' && item.isAPlus) {
        hasNewClaim = true;
        return false;
      }
    });

    return (parentFloodCoverage !== floodCoverage) || hasNewClaim;
  }

  renewalUWR16(): boolean {
    // Involve Update in
    //    propertyUsage > Property Page > Location Section
    //    structureType > Property Page > Location Section
    const parentPropertyUsage = this.parentRiskDetail?.riskProperties[0]?.propertyUsageCode;
    const parentStructureType = this.parentRiskDetail?.riskProperties[0]?.structureTypeCode;

    const propertyUsage = this.propertyData.locationDetails.get('propertyUsage')?.value;
    const structureType = this.propertyData.locationDetails.get('structureType')?.value;

    return parentPropertyUsage !== propertyUsage || parentStructureType !== structureType;
  }

  renewalUWR17(): boolean {
    // Involve Updates in:
    //    floodZone > Coverage Page > Flood Section
    //    applicantFloodPolicyId > Coverage Page > Flood Section
    const parentFloodZone = this.parentRiskDetail?.riskFloods[0].floodZone;
    const parentApplicantFloodPolicyId = this.parentRiskDetail?.riskFloods[0]?.applicantFloodPolicyCode;
    const parentFloorUnitNumber = this.parentRiskDetail?.riskProperties[0]?.floorUnitNumber;

    const floodZone = this.coveragesData.floodForm.get('floodZone')?.value;
    const applicantFloodPolicyId = this.coveragesData.floodForm.get('applicantFloodPolicyId')?.value;
    const floorUnitNumber = this.propertyData.propertyDetails.get('floorUnitNumber').value;

    return parentFloodZone !== floodZone || parentApplicantFloodPolicyId !== applicantFloodPolicyId || parentFloorUnitNumber !== floorUnitNumber;
  }

  renewalUWR18(): boolean {
    // TODO: Agent Cancel/Rewrite
    // Involve Updates in:
    //    yearBuilt > Property Page > Property Details Section
    //    waterDamage > Endorsement Page
    const parentYearBuilt = this.parentRiskDetail?.riskProperties[0]?.yearBuilt;
    const parentWaterDamage = this.parentRiskDetail?.riskEndorsements[0]?.isWaterDamageExclusion;

    const yearBuilt = this.propertyData.propertyDetails.get('yearBuilt')?.value;
    const waterDamage = this.endorsementsData.endorsementsGroup.get('waterDamage')?.value;

    return parentYearBuilt !== yearBuilt || parentWaterDamage !== waterDamage ;
  }

  renewalUWR21(): boolean {
    let parentCentralAir = false;
    if (!this.parentRiskDetail?.riskProperties[0]?.isCentralAirCondition){
      parentCentralAir = true;
    } else {
      parentCentralAir = false;
    };

    let centralAirFalseInUwOrProperty = false;
    if(!this.propertyData.propertyDetails.get('isCentralAirConditioning')?.value) {
      centralAirFalseInUwOrProperty = true;
    } else {
      centralAirFalseInUwOrProperty = false;
    }

    return centralAirFalseInUwOrProperty !== parentCentralAir;
  }

  renewalUWR22(): boolean {
    const parentCoverageA = Math.trunc(Number(this.parentRiskDetail?.riskCoverages[0]?.covA));
    const parentReplacementCost = Math.trunc(Number(this.parentRiskDetail?.riskProperties[0]?.replacementCost));

    const coverageA = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA')?.value));
    const replacementCost = Math.trunc(Number(this.propertyData.propertyDetails.get('replacementCost')?.value));

    return parentCoverageA !== coverageA || parentReplacementCost !== replacementCost;
  }

  renewalUWR23(): boolean {
    // Involve Update
    //    floodZone > Coverage Page > Flood Section
    //    applicantFloodPolicyId > Coverage Page > Flood Section
    const parentFloodZone = this.parentRiskDetail?.riskFloods[0].floodZone;
    const parentApplicantFloodPolicyId = this.parentRiskDetail?.riskFloods[0]?.applicantFloodPolicyCode;

    const floodZone = this.coveragesData.floodForm.get('floodZone')?.value;
    const applicantFloodPolicyId = this.coveragesData.floodForm.get('applicantFloodPolicyId')?.value;

    return parentFloodZone !== floodZone || parentApplicantFloodPolicyId !== applicantFloodPolicyId;
  }

  renewalUWR25(): boolean {
    // TODO: Pending Clarification CENTAURI-1323 related in Submission UWR CENTAURI-190
    // As per discussion in CENTAURI-1323, UWR-25 should be implemented but should not be triggered.

    // const parentStarPackage = this.parentRiskDetail?.riskEndorsements[0]?.isStarPackage;
    // const parentAdvantagePackage = this.parentRiskDetail?.riskEndorsements[0]?.isStarAdvantagePackage;
    // const parentClaimList = this.parentRiskDetail?.riskClaims.length;
    // const parentClaimItems = [];
    // this.parentRiskDetail?.riskClaims.filter(claim => claim.isActive === true).map(item => {
    //   parentClaimItems.push(item.claimType);
    // });

    // const starPackage = this.endorsementsData.endorsementsGroup.get('starPackage')?.value;
    // const starAdvantagePackage = this.endorsementsData.endorsementsGroup.get('starAdvantagePackage')?.value;
    // const claimList = this.claimsData.claimList.length;
    // const claimItems = [];
    // this.claimsData.claimList.filter(claim => claim.isActive === true).map(item => {
    //   claimItems.push(item.claimType);
    // });

    // const hasChangeOnClaims = JSON.stringify(parentClaimItems) !== JSON.stringify(claimItems);

    // return (parentStarPackage !== starPackage || parentAdvantagePackage !== starAdvantagePackage) || parentClaimList !== claimList || hasChangeOnClaims;
    return false;
  }

  renewalUWR26(): boolean {
    // Involve updates in:
    //  numMonthsOccupied > Property Page > Location Section
    //  propertyUsage > Property Page > Location Section
    const parentNumMonthsOccupied = this.parentRiskDetail?.riskProperties[0]?.numberMonthOccupied;
    const parentPropertyUsage = this.parentRiskDetail?.riskProperties[0]?.propertyUsageCode;

    const numMonthsOccupied = this.propertyData.locationDetails.get('numMonthsOccupied')?.value;
    const propertyUsage = this.propertyData.locationDetails.get('propertyUsage')?.value;

    return parentNumMonthsOccupied !== numMonthsOccupied || parentPropertyUsage !== propertyUsage;
  }

  renewalUWR27(): boolean {
    // Involve updates in:
    //    propertyUsage > Property page > Location Section
    const parentPropertyUsage = this.parentRiskDetail?.riskProperties[0].propertyUsageCode;
    const propertyUsage = this.propertyData.locationDetails.get('propertyUsage')?.value;

    return parentPropertyUsage !== propertyUsage;
  }

  renewalUWR30(): boolean {
    // Involve update in:
    //    electrical > Property Page > Property Details Section
    const parentElectrical = this.parentRiskDetail?.riskProperties[0]?.electricalCode;
    const electrical = this.propertyData.propertyDetails.get('electrical')?.value;

    return parentElectrical !== electrical;
  }

  renewalUWR37(): boolean {
    // Involve update in:
    //    roofMaterial > Property Page > Property Details Section\
    const parentRoofMaterial = this.parentRiskDetail?.riskProperties[0]?.roofMaterialCode;
    const roofMaterial = this.propertyData.propertyDetails.get('roofMaterial')?.value;

    return parentRoofMaterial !== roofMaterial;
  }

  renewalUWR39(): boolean {
    const parentFeetToHydrant = this.parentRiskDetail?.riskProperties[0]?.feetToHyrantCode;
    const feetToHydrant = this.propertyData.locationDetails.get('feetToHydrant')
      ?.value;
      return this.uwrHelpers.isDP3 && feetToHydrant !== parentFeetToHydrant;
  }

  renewalUWR40(): boolean {
    // Involve update in:
    //    roofShape > Property Page > Property Details Section
    const parentRoofShape = this.parentRiskDetail?.riskProperties[0]?.raterRoofShapeCode;
    const roofShape = this.propertyData.propertyDetails.get('roofShape')?.value;

    return parentRoofShape !== roofShape;
  }

  renewalUWR42(): boolean {
    //  foundationType > Property Page > Property Details Section
    //  foundationElevation > Property Page > Property Details Section
    const parentFoundationType = this.parentRiskDetail?.riskProperties[0]?.raterFoundationTypeCode;
    const parentFoundationType360Code = this.parentRiskDetail?.riskProperties[0]?.foundationType360Code;
    const parentFoundationElevation = this.parentRiskDetail?.riskProperties[0].foundationElevationCode;

    const foundationType = this.propertyData.propertyDetails.get('foundationType')?.value;
    const foundationElevation = this.propertyData.propertyDetails.get('foundationElevation')?.value;

    const foundationTypeStatus = !(foundationType === parentFoundationType || foundationType === parentFoundationType360Code);

    return foundationTypeStatus || parentFoundationElevation !== foundationElevation;
  }

  renewalUWR43(): boolean {
    // Involve update in:
    //    electrical > Property Page > Property Details Section
    const parentElectrical = this.parentRiskDetail?.riskProperties[0].electricalCode;
    const electrical = this.propertyData.propertyDetails.get('electrical')?.value;
    return parentElectrical !== electrical;
  }

  renewalUWR47(): boolean {
    // Involve update in:
    //    electrical > Property Page > Property Details Section
    const parentElectrical = this.parentRiskDetail?.riskProperties[0].electricalCode;
    const electrical = this.propertyData.propertyDetails.get('electrical')?.value;
    return parentElectrical !== electrical;
  }

  renewalUWR58(): boolean {
    // Involve update in:
    //    primaryHeatType > Property Page > Property Detail Section
    const parentPrimaryHeatType = this.parentRiskDetail?.riskProperties[0]?.primaryHeatTypeCode;
    const primaryHeatType = this.propertyData.propertyDetails.get('primaryHeatType')?.value;
    return parentPrimaryHeatType !== primaryHeatType;
  }

  renewalUWR68(): boolean {
    // Involve update in:
    //    roofMaterial > Property Page > Property Detail Section
    //    yearRoof > Property Page > Property Detail Section
    const parentRoofMaterial = this.parentRiskDetail?.riskProperties[0]?.roofMaterialCode;
    const parentYearRoof = this.parentRiskDetail?.riskProperties[0]?.yearRoofInstalled;

    const roofMaterial = this.propertyData.propertyDetails.get('roofMaterial').value;
    const yearRoof = this.propertyData.propertyDetails.get('yearRoof')?.value;

    return parentRoofMaterial !== roofMaterial ||
      Math.trunc(Number(parentYearRoof)) !== Math.trunc(Number(yearRoof));
  }

  renewalUWR69(): boolean {
    // Involve update in:
    //    roofMaterial > Property Page > Property Detail Section
    //    yearRoof > Property Page > Property Detail Section
    const parentRoofMaterial = this.parentRiskDetail?.riskProperties[0]?.roofMaterialCode;
    const parentYearRoof = this.parentRiskDetail?.riskProperties[0]?.yearRoofInstalled;

    const roofMaterial = this.propertyData.propertyDetails.get('roofMaterial').value;
    const yearRoof = this.propertyData.propertyDetails.get('yearRoof')?.value;

    return parentRoofMaterial !== roofMaterial ||
      Math.trunc(Number(parentYearRoof)) !== Math.trunc(Number(yearRoof));
  }

  renewalUWR75(): boolean {
    // Involve update in:
    //    roofMaterial > Property Page > Property Detail Section
    const parentRoofMaterial = this.parentRiskDetail?.riskProperties[0]?.roofMaterialCode;

    const roofMaterial = this.propertyData.propertyDetails.get('roofMaterial').value;

    return parentRoofMaterial !== roofMaterial;
  }

  renewalUWR77(): boolean {
    //    HO3 and Optional endorsements "Ordinance or Law Coverage - 25%" OR "Ordinance or Law Coverage - 50%" are not included (default to add 25% to policies if they remove, fire rule).  If they have no O&L options, trigger the referral unless they have either Star Package or Star Advantage Package selected.
    const parentOrdinanceLawOrCov = this.parentRiskDetail.riskEndorsements[0].isOrdinanceLaw ?? false;
    const parentStarPackage = this.parentRiskDetail.riskEndorsements[0].isStarPackage ?? false;
    const parentStarAdvPackage = this.parentRiskDetail.riskEndorsements[0].isStarAdvantagePackage ?? false;

    const ordinanceLawOrCov = this.endorsementsData.endorsementsGroup.get('ordinanceLawOrCov')?.value;
    const starPackage = this.endorsementsData.endorsementsGroup.get('starPackage').value || false;
    const starAdvantagePackage = this.endorsementsData.endorsementsGroup.get('starAdvantagePackage')?.value || false;

    return parentOrdinanceLawOrCov !== ordinanceLawOrCov ||
                    parentStarPackage !== starPackage ||
                    parentStarAdvPackage !== starAdvantagePackage;
  }

  renewalUWR85(): boolean {
    const status = this.uwrHelpers.asyncUWRs.find(uwr => { return uwr.id === 85; }).status;

    return status;
  }

  renewalUWR82(): boolean {
    const parentData = this.parentRiskDetail;
    const originalAddSchedules = this.parentRiskDetail?.isAddSchedule;
    const addSchedules = this.coveragesData.schedulesForm.get('addSchedules')?.value;
    const isTableUpdate = this.coveragesData.policyCompareRiskScheduleTableData(parentData);
    return originalAddSchedules !== addSchedules || isTableUpdate;
  }

  renewalUWR86(): void {
    // TEMPORARILY Comment this code, as per business UWR86: turn this off until the copy feature is turned back on

    // const parentPriorInsuranceCarrier = this?.parentRiskDetail?.riskInterests[0]?.priorCarrierCode;
    // const priorInsuranceCarrier = this?.interestData?.priorInsuranceForm?.get('priorCarrier')?.value;
    // const status = parentPriorInsuranceCarrier !== priorInsuranceCarrier;

    // if (status) {
    //   this.uwr86();
    // } else {
    //   this.policyRemoveFromTableRow('uwr86');
    // }
  }

  renewalUWR87(): boolean {
    //    HO3 and Coverage A limit is greater than $1,000,000.
    const parentCovA = Math.trunc(Number(this.parentRiskDetail.riskCoverages[0].covA));
    const coverageA = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA')?.value));

    return parentCovA !== coverageA;
  }

  renewalUWR88(): boolean {
    // Involve update in:
    //    foundationType > Property Page > Property Detail Section
    //    foundationElevation > Property Page > Property Detail Section
    const parentFoundationType = this.parentRiskDetail?.riskProperties[0]?.raterFoundationTypeCode;
    const parentFoundationElevation = this.parentRiskDetail?.riskProperties[0]?.foundationElevationCode ?? '';

    const foundationType = this.propertyData.propertyDetails.get('foundationType')?.value;
    const foundationElevation = this.propertyData.propertyDetails.get('foundationElevation')?.value ?? '';

    return parentFoundationType !== foundationType ||
      parentFoundationElevation !== foundationElevation;
  }

  renewalUWR89(): boolean {
    // Involve update in:
    //    propertyUsage > Property Page > Location Detail Section
    //    structureType > Property Page > Location Detail Section
    const parentPropertyUsage = this.parentRiskDetail?.riskProperties[0]?.propertyUsageCode;
    const parentStructureType = this.parentRiskDetail?.riskProperties[0]?.structureTypeCode;

    const propertyUsage = this.propertyData.locationDetails.get('propertyUsage')?.value;
    const structureType = this.propertyData.locationDetails.get('structureType')?.value;

    return parentPropertyUsage !== propertyUsage ||
      parentStructureType !== structureType;
  }

  renewalUWR90(): boolean {
    // Involve update in:
    //    limitedFungi > Endorsement Page
    //    limitedFungiLimit > Endorsement Page
    let parentLimitedFungi = this.parentRiskDetail?.riskEndorsements[0]?.isLimitedFungiWet;
    let parentLimitedFungiLimit = Math.trunc(Number(this.parentRiskDetail?.riskEndorsements[0]?.limitedFungiWetLimitCode));

    let limitedFungi = this.endorsementsData.endorsementsGroup.get('limitedFungi')?.value;
    let limitedFungiLimit = this.endorsementsData.endorsementsGroup.get('limitedFungiLimit')?.value;

    if(this.uwrHelpers.isDP3){
      parentLimitedFungi = this.parentRiskDetail?.riskEndorsements[0]?.isLimitedFungiWetIncreasedAmount;
      parentLimitedFungiLimit = Math.trunc(Number(this.parentRiskDetail?.riskEndorsements[0]?.limitedFungiWetIncreasedAmountLimitCode));
      limitedFungi = this.endorsementsData.endorsementsGroup.get('fungiWetOrDryRot')?.value;
      limitedFungiLimit = this.endorsementsData.endorsementsGroup.get('fungiWetOrDryRotLimit')?.value;
    }

    return parentLimitedFungi !== limitedFungi ||
      parentLimitedFungiLimit !== Math.trunc(Number(limitedFungiLimit));
  }

  renewalUWR94(): boolean {
    // TODO: Table value comparison
    // Involves:
    // 1. Coverages > Coverages > Coverages > Coverage A - Dwelling
    // 2. Coverages > Schedules > Add Schedules
    // 3. Coverages > Schedules > Schedule Total (Note a field but a value)
    const parentData = this.parentRiskDetail;
    return this.coveragesData.policyCompareRiskScheduleTableData(parentData);
  }

  renewalUWR95(): boolean {
    // Involves:
    // 1. Coverages > Coverages > Coverages > Coverage C
    // 2. Coverages > Schedules > Add Schedules
    // 3. Coverages > Schedules > Schedule Total (Note a field but a value)
    const parentCovC = Math.trunc(Number(this.parentRiskDetail?.riskCoverages[0].covC));
    const parentAddSched = this.parentRiskDetail?.isAddSchedule;

    const covC = Math.trunc(this.coveragesData.coveragesAndDeductiblesForm.get('coverageC')?.value);
    const addSchedules = this.coveragesData.schedulesForm.get('addSchedules')?.value;

    const parentData = this.parentRiskDetail;
    const isTableUpdate = this.coveragesData.policyCompareRiskScheduleTableData(parentData);

    return isTableUpdate || (parentCovC !== covC) || (parentAddSched !== addSchedules);
  }

  renewalUWR96(): boolean {
    // Involves:
    // 1.  sinkholeLoss > Endorsement Page
    const parentSinkholes = this.parentRiskDetail?.riskEndorsements[0]?.isSinkHoleLossCov;
    const sinkholeLoss = this.endorsementsData.endorsementsGroup.get('sinkholeLoss')?.value;

    return parentSinkholes !== sinkholeLoss;
  }

  renewalUWR100(): boolean {
    const originalFloodCoverage = this.parentRiskDetail?.riskFloods[0]?.isPrivateFloodCov;
    const floodCoverage = this.coveragesData.floodForm.get('privateFloodCoverage')?.value;
    return originalFloodCoverage !== floodCoverage;
  }

  renewalUWR102(): boolean {
    // TODO: Flood assis API related to CENTAURI-267
    const originalFloodCoverage = this.parentRiskDetail?.riskFloods[0]?.isPrivateFloodCov;
    const floodCoverage = this.coveragesData.floodForm.get('privateFloodCoverage')?.value;
    return originalFloodCoverage !== floodCoverage;
  }

  renewalUWR103(): boolean {
    // Involve update in:
    //    exteriorWallFinish > Property Page > Property Detail Section
    const parentExteriorWallFinish = this.parentRiskDetail?.riskProperties[0]?.raterExteriorWallFinishCode;
    const exteriorWallFinish = this.propertyData.propertyDetails.get('exteriorWallFinish')?.value;

    return parentExteriorWallFinish !== exteriorWallFinish;
  }

  renewalUWR106(): boolean {
    // Involves:
    // Policy Issue > Change Effective Date
    // Policy Effective Date
    // this.policyIssueData.issuePolicyForm.get('changeEffectiveDate').valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
    //   this.checkUWR106();
    // });
    return false;
  }

  renewalUWR107(): boolean {
    // Involves:
    // 1. Coverages > Coverages > Coverages > Coverage C
    const parentCovC = this.parentRiskDetail?.riskCoverages[0].covC;
    const covC = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageC')?.value) ?? 0);

    return parentCovC !== covC;
  }

  renewalUWR109(): boolean {
    const parentCovA = Math.trunc(Number(this.parentRiskDetail?.riskCoverages[0].covA));
    const covA = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA')?.value));

    return parentCovA !== covA;
  }

  renewalUWR114() {
    // For DP3 Only
    // Involve
    // Property > County
    // Property > Yearbuilt
    // Endorsement > Limited Water Damage Coverage
    const parentYearBuilt = Math.trunc(Number(this.parentRiskDetail?.riskProperties[0]?.yearBuilt));
    const parentCounty = this.parentRiskDetail?.riskProperties[0]?.county;
    const parentLimitedWater = this.parentRiskDetail?.riskEndorsements[0]?.isLimitedWaterDamage || false;

    const yearBuilt = Math.trunc(Number(this.propertyData.propertyDetails.get('yearBuilt')?.value));
    const county = this.uwrHelpers.stringToLower(this.propertyData.locationDetails.get('county')?.value);
    const limitedWater = this.endorsementsData.endorsementsGroup.get('limitedWater')?.value || false;

    return parentYearBuilt !== yearBuilt ||
        this.uwrHelpers.stringToLower(parentCounty) !== this.uwrHelpers.stringToLower(county) ||
        parentLimitedWater !== limitedWater;
  }

  renewalUWR115(): boolean {
    // Involve Updates in:
    //    yearBuilt > Property Page > Property Details Section
    //    waterDamage > Endorsement Page
    const parentYearBuilt = this.parentRiskDetail?.riskProperties[0]?.yearBuilt;
    const parentWaterDamage = this.parentRiskDetail?.riskEndorsements[0]?.isWaterDamageExclusion;

    const yearBuilt = this.propertyData.propertyDetails.get('yearBuilt')?.value;
    const waterDamage = this.endorsementsData.endorsementsGroup.get('waterDamage')?.value;

    return parentYearBuilt !== yearBuilt || parentWaterDamage !== waterDamage;
  }

  renewalUWR116(): boolean {
    // For DP3 Only
    // Involved update in Coverage A value
    const parentCovA = Math.trunc(Number(this.parentRiskDetail?.riskCoverages[0]?.covA));
    const covA = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA')?.value));

    return parentCovA !== covA;
  }

  renewalUWR117(): boolean {
    // FOR Dp3 Only
    // Involve
    // Endorsement > Permitted Incidental Occupancies
    // Endorsement > Permitted Incidental Occupancies - Liability
    // Condition > If agent adds "Permitted Incidental Occupancies" and/or "Permitted Incidental Occupancies - Liability" optional endorsement(s)

    const parentPermittedIncidenal = this.parentRiskDetail?.riskEndorsements[0].isPermittedIncidentalOccupancies || false;
    const parentPermittedIncidentalOccupanciesLiability = this.parentRiskDetail?.riskEndorsements[0].isPermittedIncidentalOccupanciesLiability || false;
    const parentOccupancyDescription = this.uwrHelpers.stringToLower(this.parentRiskDetail?.riskEndorsements[0]?.occupancyDescription);
    const parentBusinessDescription = this.uwrHelpers.stringToLower(this.parentRiskDetail?.riskEndorsements[0]?.businessDescription);
    const parentBusinessLocation = this.uwrHelpers.stringToLower(this.parentRiskDetail?.riskEndorsements[0]?.businessLocation);

    const permittedIncidenal = this.endorsementsData.endorsementsGroup.get('permittedIncidentalOccupancies')?.value || false;
    const occupancyDescription = this.uwrHelpers.stringToLower(this.endorsementsData.endorsementsGroup.get('descriptionOfOccupancy')?.value);
    const permittedIncidentalOccupanciesLiability = this.endorsementsData.endorsementsGroup.get('permittedIncidentalOccupanciesLiability')?.value || false;
    const businessDescription = this.uwrHelpers.stringToLower(this.endorsementsData.endorsementsGroup.get('descriptionOfBusiness')?.value);
    const businessLocation = this.uwrHelpers.stringToLower(this.endorsementsData.endorsementsGroup.get('businessLocation')?.value);

    return parentPermittedIncidenal !== permittedIncidenal ||
      parentPermittedIncidentalOccupanciesLiability !== permittedIncidentalOccupanciesLiability ||
      parentOccupancyDescription !== occupancyDescription ||
      parentBusinessDescription !== businessDescription ||
      parentBusinessLocation !== businessLocation;
  }

  renewalUWR118(): boolean {
    const parentMitigationForm = this.parentRiskDetail?.riskProperties[0].mitigationFormCode;
    const mitigationForm = this.propertyData.windMitigation.get('mitigationForm')?.value;
    return this.uwrHelpers.isHO3orDP3orHO6 && parentMitigationForm !== mitigationForm;
  }

  renewalUWR120(): boolean {
    const parentSprinkler = this.parentRiskDetail?.riskDiscounts[0]?.sprinklerCode;
    const sprinkler = this.coveragesData.discountsForm.get('sprinklerId')?.value;
    return this.uwrHelpers.isHO3orDP3orHO6 && parentSprinkler !== sprinkler;
  }

  renewalUWR121(): boolean {
    const parentHurricaneDeductible = this.parentRiskDetail?.riskCoverages[0]?.hurricaneDeductible;
    const hurricaneDeductible = this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid')?.value;
    return this.uwrHelpers.isHO3orDP3 && parentHurricaneDeductible !== hurricaneDeductible;
  }

  renewalUWR122(): boolean {
    const parentCovC = Math.trunc(Number(this.parentRiskDetail?.riskCoverages[0]?.covCPercentageId));
    const parentCoverageC = Math.trunc(Number(this.parentRiskDetail?.riskCoverages[0]?.covC));
    const covC = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageCLimitId')?.value));
    const coverageC = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageC')?.value));
    let status: boolean = false;

    if (this.uwrHelpers.isHO6orDP3) {
      status = this.uwrHelpers.isHO6orDP3 && parentCoverageC !== coverageC;
    } else {
      status = this.uwrHelpers.isHO3 && parentCovC !== covC;
    }

    return status;
  }

  renewalUWR123(): boolean {
    // TODO: Identify UWR 123
    return false;
  }

  renewalUWR124(): boolean {
    // If midterm increase to Coverage A is 20% or more of Coverage A.
    const parentCovA = Math.trunc(Number(this.parentRiskDetail.riskCoverages[0].covA));
    const currentCovA = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA')?.value));

    const parentCovA20Percent = parentCovA * 0.2;

    return currentCovA >= Math.trunc(Number((parentCovA + parentCovA20Percent)));
  }

  renewalUWR125(): boolean {
    // If midterm decrease to Coverage A (any decrease).
    const parentCovA = Math.round(Number(this.parentRiskDetail.riskCoverages[0].covA));
    const currentCovA = Math.round(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA')?.value));

    return currentCovA < parentCovA;
  }

  renewalUWR127(): boolean {
    // If any deductible (AOP, wind/hail, hurricane, non-hurricane wind) is lowered.
    const parentCovA = Math.trunc(Number(this.parentRiskDetail.riskCoverages[0].covA));
    const parentAOP = Math.trunc(Number(this.parentRiskDetail.riskCoverages[0].allOtherPerilsDeductible));
    const parentHurricaneDeductibleId = this.parentRiskDetail.riskCoverages[0]?.hurricaneDeductible;
    const parentHurricaneDeductible = LvRaterDeductibleHUR.filter(a => a.code === parentHurricaneDeductibleId)[0];

    const parentHurricaneDeductibleValue = parentHurricaneDeductibleId !== 'E' ?
                                            parentHurricaneDeductible.isPercentage ?
                                              parentCovA * Math.trunc(Number(parentHurricaneDeductibleId)) : Math.trunc(Number(parentHurricaneDeductible.code))
                                            : 0;

    const currentCovA = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA')?.value));
    const currentAOP = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('allOtherPerilsId')?.value));
    const currentHurricaneDeductibleId = this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid')?.value;
    const currentHurricaneDeductible = LvRaterDeductibleHUR.find(a => a.code === currentHurricaneDeductibleId);

    const currentHurricaneDeductibleValue = currentHurricaneDeductibleId !== 'E' ?
                                            currentHurricaneDeductible.isPercentage ?
                                              currentCovA * Math.trunc(Number(currentHurricaneDeductibleId)) : Math.trunc(Number(currentHurricaneDeductible.code))
                                            : 0;

    const isHurricaneDeductibleLowered = currentHurricaneDeductibleValue < parentHurricaneDeductibleValue;
    const isAOPLowered = currentAOP < parentAOP;

    return isHurricaneDeductibleLowered || isAOPLowered;
  }

  renewalUWR128(): boolean {
    // If wind was excluded voluntarily (not per UWR-2) and agent attempts to add wind back to policy.
    const status = this.uwrHelpers.asyncUWRs.find(uwr => { return uwr.id === 128; }).status;

    return status;
  }

  renewalUWR129(): boolean {
    // If agent processes change to named insured.
    const status = this.uwrHelpers.asyncUWRs.find(uwr => { return uwr.id === 129; }).status;

    return status;
  }

  renewalUWR131(): boolean {
    const status = this.uwrHelpers.asyncUWRs.find(uwr => { return uwr.id === 131; }).status;

    return status;
  }

  renewalUWR132(): boolean {
    // Involves changes in:
    // Property:
    //    Property Usage
    //    Is the residence Rented?
    // UW Question: UW Question is not editable in midterm endorsement.
    const parentPropertyUsageCode = this.parentRiskDetail?.riskProperties[0].propertyUsageCode;
    const parentIsResidenceRented = this.parentRiskDetail?.riskProperties[0].isTheResidenceRented;

    const currentPropertyUsageCode = this.propertyData.locationDetails.get('propertyUsage').value;
    const currentIsResidenceRented = this.propertyData.locationDetails.get('isResidenceRented')?.value ?? false;

    return parentPropertyUsageCode !== currentPropertyUsageCode ||
                  parentIsResidenceRented !== currentIsResidenceRented;
  }

  renewalUWR133(): boolean {
    // Involves changes in:
    // Property:
    //    Property Usage
    //    Is the residence Rented?
    // UW Question: UW Question is not editable in midterm endorsement.
    const parentPropertyUsageCode = this.parentRiskDetail?.riskProperties[0].propertyUsageCode;
    const parentIsResidenceRented = this.parentRiskDetail?.riskProperties[0].isTheResidenceRented;

    const currentPropertyUsageCode = this.propertyData.locationDetails.get('propertyUsage').value;
    const currentIsResidenceRented = this.propertyData.locationDetails.get('isResidenceRented')?.value ?? false;

    return parentPropertyUsageCode !== currentPropertyUsageCode ||
                  parentIsResidenceRented !== currentIsResidenceRented;
  }

  renewalUWR140(): boolean {
    // UWR-140 condition
    // 1. Roof Surfacing Payment Schedule is on the policy AND
    // 2. Agent enters policy change to remove
    // Form-Type: HO3, DP3

    const parentRoofSurfacingPaymentSchedule = this.parentRiskDetail?.riskEndorsements[0].isRoofSurfacingPaymentSchedule;
    const currentRoofSurfacingPaymentSchedule = this.endorsementsData.endorsementsGroup.get('roofSurfacingPaymentSchedule')?.value;

    return parentRoofSurfacingPaymentSchedule !== currentRoofSurfacingPaymentSchedule;
  }
}
