import { Component, OnInit } from '@angular/core';
import { SuspendedPaymentsLabel } from '../../../../../shared/constants/suspended-payments.labels.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { SuspendedPaymentsData } from '../../../../../modules/payments/data/suspended-payments.data';
import { BaseClass } from '../../../../../shared/base-class';
import { SuspendedPaymentDetailsDTO } from '../../../../../shared/models/data/dto/suspended-payment/suspended-payment-detail.dto';
import Utils from '../../../../../shared/utilities/utils';
import { IpxDetailsSuspendedPaymentModalComponent } from '../ipx-suspended-modal/ipx-details-suspended-payment-modal/ipx-details-suspended-payment-modal.component';

@Component({
  selector: 'app-sent-to-ipx-list',
  templateUrl: './sent-to-ipx-list.component.html',
  styleUrls: ['./sent-to-ipx-list.component.scss']
})
export class SentToIpxListComponent extends BaseClass implements OnInit {
  public SuspendedPaymentsLabel = SuspendedPaymentsLabel;
  isOpen: boolean = true;
  modalRef: BsModalRef;

  constructor(
    public suspendedPaymentsData: SuspendedPaymentsData,
    private modalService: BsModalService
  ) {
    super();
  }

  ngOnInit() {
  }

  setPage(page: number) {
    this.suspendedPaymentsData.sentToIPXListSetPage(page);
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }

  showIPXSuspendedModal(component?, initialState?): BsModalRef {
    return this.modalService.show(component, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
  }

  onDetailsClick(item?: SuspendedPaymentDetailsDTO): void {
    Utils.blockUI();
    this.suspendedPaymentsData.getDetailedSuspendedView(item.id);
    this.suspendedPaymentsData.detailsViewNotifier.take(1).subscribe(res => {
      Utils.unblockUI();

      const initialState = {
        paymentDetail: res.details,
        hasReturnDetails: false
      };
      this.showIPXSuspendedModal(IpxDetailsSuspendedPaymentModalComponent, initialState);
    });
  }
}
