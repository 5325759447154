import { Action, createReducer, on } from "@ngrx/store";
import * as fromPolicyUWRsActions from "app/store/policy-uwrs/policy-uwrs.actions";
import { initialState, PolicyUWRsState } from "./policy-uwrs.state";

const _policyUWRsReducer = createReducer(
  initialState,
  on(
    fromPolicyUWRsActions.updatePolicyUWRsIsLoadingFromUwApprovalSavingData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromPolicyUWRsActions.updatePolicyUWRsListFromUwApprovalSavingData,
    (state, { list }) => ({ ...state, list: list })
  ),
  on(
    fromPolicyUWRsActions.updatePolicyUWRsTableRowsFromUwApprovalData,
    (state, { tableRows }) => ({ ...state, tableRows: tableRows })
  )
);

export function policyUWRsReducer(
  state: PolicyUWRsState | null,
  action: Action
) {
  return _policyUWRsReducer(state, action);
}