import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BaseClass } from '../../../../shared/base-class';
import { takeUntil } from 'rxjs/operators';
import { ApplicantData } from '../../../../modules/submission-management/data/applicant.data';
import { GenericConstants } from '../../../../shared/constants/generic.constants';
import { AuthService } from '../../auth.service';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class QuickQuoteValidationService extends BaseClass  {
    genericConstants = GenericConstants;
    effectiveDate: Date;
    userType: string;
    isEffectiveDateValid: boolean;

  constructor(
    protected authService: AuthService,
    public applicantData: ApplicantData) {
      super();
    }

    isQuickQuoteEffectivaDateValid(): boolean {
        const currentDate = this.authService.getCustomDate();
        this.effectiveDate = this.applicantData.mainApplicant.get('effectiveDate').value.singleDate?.jsDate;
        const formattedEffectiveDate = new DatePipe('en-US').transform(this.effectiveDate, 'MM/dd/yyyy');
        const formattedcurrentDate = new DatePipe('en-US').transform(currentDate, 'MM/dd/yyyy');
        this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(x => {
          this.userType = x;
        });

        if (moment(formattedEffectiveDate).isBefore(formattedcurrentDate) && this.userType === this.genericConstants.userType.external ) {
            this.isEffectiveDateValid = false;
         return false;
        } else {
            this.isEffectiveDateValid = true;
          return true;
        }
      }
}