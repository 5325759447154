import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../common.service';
import { environment } from '../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserDTO } from 'app/shared/models/management/user';
import { UserViewModel } from 'app/shared/models/management/user-view-model';
import { SearchDTO } from '../../../shared/models/management/search.dto';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public http: HttpClient,
    private commonService: CommonService) { }

  getUsers(): Observable<UserViewModel[]> {
    return this.http.get(`${environment.GenericServiceUrl}/api/User/${environment.ApplicationId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  postUser(user: UserViewModel): Observable<any> {
    return this.http.post(`${environment.GenericServiceUrl}/api/User`, user, { responseType: 'text' })
      .catch(this.commonService.handleObservableHttpError);
  }

  getSpecificUser(username: string): Observable<UserDTO> {
    if (username.includes('+')) {
      const newUserName = username.replace('+', '$2B');

      return this.http.get(`${environment.GenericServiceUrl}/api/User/${environment.ApplicationId}/${newUserName}`)
      .catch(this.commonService.handleObservableHttpError);
    } else {

      return this.http.get(`${environment.GenericServiceUrl}/api/User/${environment.ApplicationId}/${username}`)
      .catch(this.commonService.handleObservableHttpError);
    }
  }

  getUserAccessRightsByUsername(username: any): Observable<any[]> {
    return this.http.get(`${environment.IdentityServiceUrl}/api/Users/${username}/${environment.ApplicationId}/access-rights`)
      .catch(this.commonService.handleObservableHttpError);
  }

  putUser(user: UserViewModel): Observable<any> {
    return this.http.put(`${environment.GenericServiceUrl}/api/User/${environment.ApplicationId}`, user, { responseType: 'text' })
      .catch(this.commonService.handleObservableHttpError);
  }

  putDeletedUser(user: UserViewModel): Observable<any> {
    return this.http.put(`${environment.GenericServiceUrl}/api/User/SaveDeletedUser`, user, { responseType: 'text' })
      .catch(this.commonService.handleObservableHttpError);
  }

  checkIfUserExist(username: any, emailAddress: any): Observable<any> {
    return this.http.get(`${environment.GenericServiceUrl}/api/User/UserExist/${environment.ApplicationId}/${username}/${emailAddress}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  getUserInfo(username: string): Observable<UserDTO> {
    return this.http.get(`${environment.IdentityServiceUrl}/api/Users/${username}/${environment.ApplicationId}/user-details`)
      .catch(this.commonService.handleObservableHttpError);
  }

  getUserInfoById(userId: string | number): Observable<any> {
    return this.http.get(`${environment.ApiUrl}/User/${userId}`).pipe(
      map(result => {
        return result;
      })
    );
  }

  searchSystemUser(search: SearchDTO): Observable<UserViewModel[]> {
    return this.http.post(`${environment.GenericServiceUrl}/api/User/Search`, search)
      .catch(this.commonService.handleObservableHttpError);
  }

  getUnderwriterList(): Observable<any> {
    return this.http.get(`${environment.GenericServiceUrl}/api/User/Underwriters/${environment.ApplicationId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  getInternalUserList(): Observable<any> {
    return this.http.get(`${environment.GenericServiceUrl}/api/User/InternalUsers/${environment.ApplicationId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  doesUserNameAlreadyExists(newUserName: string,oldUserName: string): Observable<boolean> {
    const appCode = environment.ApplicationId;
    const value = this.http.post(`${environment.GenericServiceUrl}/api/User/DoesUserNameAlreadyExists`, {newUserName , oldUserName, appCode})
    .catch(this.commonService.handleObservableHttpError);

    return value;

  }
}
