import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SentToIpxComponent } from './sent-to-ipx.component';
import { SentToIpxListComponent } from './sent-to-ipx-list/sent-to-ipx-list.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../../../../app/shared/shared.module';
import { IpxDetailsSuspendedPaymentModalComponent } from './ipx-suspended-modal/ipx-details-suspended-payment-modal/ipx-details-suspended-payment-modal.component';
import { IpxSuspendedPaymentRelatedDocComponent } from '../shared/ipx-suspended-payment-related-doc/ipx-suspended-payment-related-doc.component';

const routes: Routes = [
  { path: '', component: SentToIpxComponent }
];

@NgModule({
  declarations: [
    SentToIpxComponent,
    SentToIpxListComponent,
    IpxDetailsSuspendedPaymentModalComponent,
    IpxSuspendedPaymentRelatedDocComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
  ],
  entryComponents: [IpxDetailsSuspendedPaymentModalComponent]
})
export class SentToIpxModule { }
