import { Action, createReducer, on } from '@ngrx/store';
import * as fromSubmissionsActions from 'app/store/submission/submission.actions';
import { initialState, SubmissionState } from './submission.state';

const _submissionReducer = createReducer(
  initialState,
  on(
    fromSubmissionsActions.updateSubmissionIsLoadingFromSubmissionManagementComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromSubmissionsActions.updateSubmissionStatusFromCoveragesData,
    (state, { status }) => ({ ...state, status: status })
  ),
  on(
    fromSubmissionsActions.updateSubmissionStatusFromEndorsementsSavingData,
    (state, { status }) => ({ ...state, status: status })
  ),
  on(
    fromSubmissionsActions.updateSubmissionStatusFromSubmissionPageData,
    (state, { status }) => ({ ...state, status: status })
  ),
  on(
    fromSubmissionsActions.updateSubmissionStatusFromSummaryData,
    (state, { status }) => ({ ...state, status: status })
  ),
  on(
    fromSubmissionsActions.updateSubmissionStatusFromUwApprovalData,
    (state, { status }) => ({ ...state, status: status })
  ),
  on(
    fromSubmissionsActions.updateSubmissionStatusFromApplicantPage,
    (state, { status }) => ({ ...state, status: status })
  ),
  on(
    fromSubmissionsActions.updateSubmissionIsPopulatingFormsFromSubmissionManagementComponent,
    (state, { isPopulatingForms }) => ({ ...state, isPopulatingForms: isPopulatingForms })
  )
);

export const submissionReducer = (
  state: SubmissionState | null,
  action: Action
) => {
  return _submissionReducer(state, action);
};
