import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

export interface RadioButtonItem {
  name: string;
  value: boolean;
}

@Component({
  selector: 'app-yes-no-radio',
  templateUrl: './yes-no-radio.component.html',
  styleUrls: ['./yes-no-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YesNoRadioComponent),
      multi: true
    }
  ]
})

export class YesNoRadioComponent implements ControlValueAccessor {
  @Input() name: string;
  @Input() id: string;
  @Input() isDisabled: boolean = false;
  radioValue = new FormControl('');

  items: RadioButtonItem[] = [
    { name: 'Yes', value: true },
    { name: 'No', value: false }
  ];

  onChange: Function;
  onTouched: Function;

  writeValue(value: boolean) {
    this.radioValue.setValue(value);
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  change(value: boolean) {
    this.radioValue.setValue(value);
    this.onChange(value);
    this.onTouched(value);
  }
}
