import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotesCategories } from 'app/shared/constants/notes.options.constants';
import { ITableTd } from 'app/shared/models/dynamic/table.interface';
import { PolicyNotesData } from 'app/modules/policy-management/data/policy-notes.data';
import { BindAndIssueData } from 'app/modules/submission-management/data/bind-and-issue.data';
import { BsModalRef } from 'ngx-bootstrap';
import * as _ from 'lodash';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { UWApprovalPageLabel } from 'app/shared/constants/uw-approval.labels.constants';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { AuthService } from '../../../../../core/services/auth.service';

@Component({
  selector: 'app-uw-approval-notes-modal',
  templateUrl: './uw-approval-notes-modal.component.html',
  styleUrls: ['./uw-approval-notes-modal.component.scss']
})
export class UwApprovalNotesModalComponent implements OnInit {
  public ErrorMessageConstant = ErrorMessageConstant;
  public UWApprovalPageLabel = UWApprovalPageLabel;
  public event: EventEmitter<any> = new EventEmitter<any>();
  public openUploadFileModal: EventEmitter<any> = new EventEmitter<any>();
  title: any;
  isApproved: boolean;
  isDeclined: boolean;
  isCancel: boolean = false;
  modalRef: BsModalRef | null;
  uwApprovalNotesForm: FormGroup;

  constructor(public bsModalRef: BsModalRef,
    protected fb: FormBuilder,
    public bindAndIssueData: BindAndIssueData,
    public policyNotesData: PolicyNotesData,
    public entityRiskData: EntityRiskData,
    protected auth: AuthService) { }

  ngOnInit() {
    this.initForms();
  }

  initForms(): void {
    this.uwApprovalNotesForm = this.fb.group({
      category: new FormControl({value:'UW', disabled: true}, [Validators.required]),
      description: new FormControl(this.getDescription(), [Validators.required, this.noWhitespaceValidator]),
      dateAdded: this.auth.getCustomDate()
    });
  }

  getDescription(): string {
    return `Policy ${this.entityRiskData.getPolicyNumber()} changes has been ${this.isApproved ? 'approved' : 'declined'}.`;
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  saveNotes(): void {
    const id = this.policyNotesData.notesTableRows.length + 1;
    const newItem: any = {
      category: this.uwApprovalNotesForm.get('category').value,
      description: this.uwApprovalNotesForm.get('description').value,
      dateAdded: this.uwApprovalNotesForm.get('dateAdded').value,
    };
    this.addTableItem(newItem, id);
    this.hideModal();
  }

  hideModal(): void {
    this.isCancel = true;
    this.bsModalRef.hide();
  }

  addTableItem(newItem: any, id: any): void {
    const tr: ITableTd[] = [];
    const fields: string[] = [
      'category',
      'description',
      'dateAdded'
    ];

    _.forEach(fields, (item, index) => {
      const display: string = this.updateDisplay(item, newItem);
      tr.push({
        id: id,
        value: Boolean(newItem[item]) ? newItem[item] : '',
        display: display,
      });
    });

    this.policyNotesData.notesTableRows.push({
      id: id,
      tr: tr,
      createdBy: newItem.createdBy,
      isFromParent: newItem.isFromParent
    });
    const riskId = this.entityRiskData.getRiskId();
    const newNote: any = {
      id: newItem.id,
      category: newItem.category,
      description: newItem.description,
      createdDate: newItem.dateAdded,
      riskBindId: this.policyNotesData.currentRiskBindId,
      riskId: riskId
    };
    this.policyNotesData.notesList.push(newNote);
  }

  updateDisplay(item: any, newItem: any): string {
    switch (item) {
      case 'dateAdded':
        return newItem[item]?.singleDate?.formatted ? newItem[item]?.singleDate?.formatted : this.mapDate(newItem[item]);
      case 'category':
        return Boolean(newItem[item]) ? _.find(NotesCategories, ['code', newItem[item]])?.description : '';
      default:
        return Boolean(newItem[item]) ? String(newItem[item]) : '';
    }
  }

  mapDate(date?): string {
    const month = new Date(date).getMonth() + 1 > 9 ? new Date(date).getMonth() + 1 : '0' + (new Date(date).getMonth() + 1);
    const day = new Date(date).getDate() > 9 ? new Date(date).getDate() : '0' + new Date(date).getDate();
    const year = new Date(date).getFullYear();

    return `${month}/${day}/${year}`;
  }
}
