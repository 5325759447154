import {
  Component,
  OnInit,
  AfterViewInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { TableComponent } from '../../../shared/components/dynamic/table/table.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../../shared/validators/custom.validator';
import { NotesLabelConstants } from '../../../shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from '../../../shared/constants/error-message.constants';
import { BindAndIssueData } from '../../../modules/submission-management/data/bind-and-issue.data';
import { GenericLabel } from '../../../shared/constants/generic.labels.constants';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { TableConstants } from '../../../shared/constants/table.constants';
import * as moment from 'moment';
import { PolicySummaryData } from '../../../modules/policy-management/data/policy-summary.data';
import { EntityRiskData } from '../../../modules/submission-management/data/entity-risk.data';
import { UserService } from 'app/core/services/management/user.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AuthService } from '../../../core/services/auth.service';
import { TaskService } from '../../../core/services/task/task.service';
import { AutoReinstatementData } from '../../../modules/policy-management/data/auto-reinstatement.data';
import { ApproverDetailsDTO } from '../../models/data/dto/billing/approver-details.dto';
import { AgencyData } from '../../../modules/management/data/agency.data';

@Component({
  selector: 'app-notes-table',
  templateUrl: './notes-table.component.html',
  styleUrls: ['./notes-table.component.scss']
})
export class NotesTableComponent extends TableComponent implements OnInit, AfterViewInit, OnChanges {
  notesForm: FormGroup;
  notesModel: any;
  public errorMessageConstant = ErrorMessageConstant;
  public notesLabelConstants = NotesLabelConstants;
  public TableConstants = TableConstants;
  genericLabel = GenericLabel;
  selectedRowId: any;
  currentUserFullName: string;

  constructor(
    protected fb: FormBuilder,
    public bindAndIssueData: BindAndIssueData,
    public policySummaryData: PolicySummaryData,
    protected entityRiskData: EntityRiskData,
    protected userService: UserService,
    public auth: AuthService,
    protected taskService: TaskService,
    protected autoReinstatementData: AutoReinstatementData,
    protected agencyData: AgencyData) {
    super(fb,auth);
  }

  get riskId() {
    return this.entityRiskData.getRiskId();
  }

  ngOnInit() {
    super.ngOnInit();
    this.initForm();
    this.userService.getUserInfoById(localStorage.getItem(environment.UserIdentifierKey))
      .pipe(takeUntil(this.stop$)).subscribe(user => {
        this.currentUserFullName = user?.fullName;
        this.autoReinstatementData.fromNotesUserDetails = <ApproverDetailsDTO>user;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
      super.ngOnChanges(changes);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  initForm(): void {
    const currentDate = this.auth.getCustomDate();

    this.notesForm = this.fb.group({
      id: [this.notesModel?.id],
      category: [this.notesModel?.category, [Validators.required]],
      description: [this.notesModel?.description, [Validators.required, CustomValidators.spaceValidator]],
      dateAdded: [this.bindAndIssueData.isFromAgency ?
        {
          singleDate: {
            date: {
              year: currentDate.getFullYear(),
              month: currentDate.getMonth() + 1,
              day: currentDate.getDate()
            },
            formatted: String(moment(currentDate).format('MM/DD/YYYY')),
            jsDate: currentDate
          }
        }
        : this.notesModel?.dateAdded ? this.notesModel?.dateAdded.singleDate ? this.notesModel?.dateAdded
          : this.getFromApiDate(this.notesModel?.dateAdded)
          : {
              singleDate: {
                date: {
                  year: currentDate.getFullYear(),
                  month: currentDate.getMonth() + 1,
                  day: currentDate.getDate()
                },
                formatted: String(moment(currentDate).format('MM/DD/YYYY')),
                jsDate: currentDate
              }
            }, [Validators.required]],
        addedBy: this.currentUserFullName,
        createdBy: localStorage.getItem(environment.UserIdentifierKey),
        createdDate: [this.notesModel?.createdDate, []]
    });
  }

  getFromApiDate(date?) {
    const newDate = new Date(date);

    return {
      singleDate: {
        date: {
          year: newDate.getFullYear(),
          month: newDate.getMonth() + 1,
          day: newDate.getDate()
        },
        formatted: String(moment(newDate).format('MM/DD/YYYY')),
        jsDate: date
      }
    };
  }

  onSubmitItem(): void {
    if (this.addNewItem) {
      this.taskService.generateTaskId().pipe(takeUntil(this.stop$)).subscribe(generatedId => {
        if (generatedId) {
          this.saveNote(generatedId);
        }
      });
    } else {
      this.saveNote(this.selectedRowId);
    }
  }

  onCreateNewItem(): void {
    this.addNewItem = true;
    this.initForm();
  }

  onEdit(rowId: any): void {
    this.selectedRowId = rowId;
    const rowItem = this.tableRows.find(item => item.id === rowId);
    const dateAdded = new Date(moment(rowItem.tr[2].value?.singleDate?.jsDate ?? rowItem.tr[2].value).format('ddd MMM D YYYY h:mm:ss'));

    this.notesForm.get('category').setValue(rowItem.tr[0].value);
    this.notesForm.get('description').setValue(rowItem.tr[1].value);
    this.notesForm.get('dateAdded').setValue(this.getFromApiDate(dateAdded));
    this.notesForm.get('addedBy').setValue(rowItem.tr[3].display);
    this.notesForm.get('createdBy').setValue(rowItem.createdBy);
    this.addNewItem = false;
    this.editItem = true;
  }

  onDelete(rowId: any): void {
    const rowItem = this.tableRows.find(item => item.id === rowId);
    this.resetValues();

    if (rowId) {
      NotifUtils.showConfirmMessage(`Are you sure you want to delete '${rowItem.tr[0].display}' note?`,
        () => this.deleteTableItem.emit(rowId),
        () => { }
      );
    }
  }

  resetValues(): void {
    this.addNewItem = false;
    this.editItem = false;
    this.notesForm.reset();
  }

  saveNote(id: string): void {
    const currentDate = this.auth.getCustomDate(true);
    let data: any;

    if (this.bindAndIssueData.isFromAgency) {
      data = {
        id: id,
        category: this.notesForm.get('category')?.value,
        description: this.notesForm.get('description')?.value,
        dateAdded: this.notesForm.get('dateAdded')?.value,
        notesId: id,
        createdByFullName: this.notesForm.get('addedBy').value,
        createdBy: this.notesForm.get('createdBy').value,
        createdDate: this.addNewItem ? currentDate : this.notesForm.get('createdDate')?.value,
        isAdd: this.addNewItem
      };
    } else {
      const riskBindId = this.entityRiskData.getRiskBindId();
      data = {
        id: id,
        category: this.notesForm.get('category')?.value,
        description: this.notesForm.get('description')?.value,
        dateAdded: this.notesForm.get('dateAdded')?.value,
        riskBindId,
        notesId: id,
        createdByFullName: this.notesForm.get('addedBy').value,
        createdBy: this.notesForm.get('createdBy').value,
        createdDate: this.addNewItem ? currentDate : this.notesForm.get('createdDate')?.value,
        isAdd: this.addNewItem ? true : false
      };

    }
    if (this.addNewItem) {
      this.addTableItem.emit(data);
    } else if (this.editItem) {
      this.updateTableItem.emit(data);
    }

    this.resetValues();
  }

  checkAgencyDisableButton(data: any): boolean {
    if (this.bindAndIssueData.isFromAgency) {
      const userId = data?.createdBy;
      const currentUser = Number(localStorage?.getItem(environment.UserIdentifierKey)); //string.. convert to number
      const dateCreated = moment(new Date(data?.tr[2].value)).format('MM/DD/yyyy');
      const loginDate = moment(this.auth.getCustomDate()).format('MM/DD/yyyy');
      if (userId !== currentUser) {
        return true;
      } else if (loginDate > dateCreated) {
        return true;
      }
      return false;
    }
    return false;
  }
}
