export const LvRaterCovBPercentage = [{
    tableName: 'LvRaterCovBPercentage',
    tableDescription: 'Coverage B %',
    code: '1',
    description: '1%',
    isActive: true
},
{
    tableName: 'LvRaterCovBPercentage',
    tableDescription: 'Coverage B %',
    code: '2',
    description: '2%',
    isActive: true
},
{
    tableName: 'LvRaterCovBPercentage',
    tableDescription: 'Coverage B %',
    code: '5',
    description: '5%',
    isActive: true
},
{
    tableName: 'LvRaterCovBPercentage',
    tableDescription: 'Coverage B %',
    code: '10',
    description: '10%',
    isActive: true
},
{
    tableName: 'LvRaterCovBPercentage',
    tableDescription: 'Coverage B %',
    code: '15',
    description: '15%',
    isActive: true
},
{
    tableName: 'LvRaterCovBPercentage',
    tableDescription: 'Coverage B %',
    code: '20',
    description: '20%',
    isActive: true
}];

export const LvRaterCovCPercentage = [{
    tableName: 'LvRaterCovCPercentage',
    tableDescription: 'Coverage C %',
    code: '25',
    description: '25%',
    isActive: true
},
{
    tableName: 'LvRaterCovCPercentage',
    tableDescription: 'Coverage C %',
    code: '30',
    description: '30%',
    isActive: true
},
{
    tableName: 'LvRaterCovCPercentage',
    tableDescription: 'Coverage C %',
    code: '35',
    description: '35%',
    isActive: true
},
{
    tableName: 'LvRaterCovCPercentage',
    tableDescription: 'Coverage C %',
    code: '40',
    description: '40%',
    isActive: true
},
{
    tableName: 'LvRaterCovCPercentage',
    tableDescription: 'Coverage C %',
    code: '45',
    description: '45%',
    isActive: true
},
{
    tableName: 'LvRaterCovCPercentage',
    tableDescription: 'Coverage C %',
    code: '50',
    description: '50%',
    isActive: true
},
{
    tableName: 'LvRaterCovCPercentage',
    tableDescription: 'Coverage C %',
    code: '55',
    description: '55%',
    isActive: true
},
{
    tableName: 'LvRaterCovCPercentage',
    tableDescription: 'Coverage C %',
    code: '60',
    description: '60%',
    isActive: true
},
{
    tableName: 'LvRaterCovCPercentage',
    tableDescription: 'Coverage C %',
    code: '65',
    description: '65%',
    isActive: true
},
{
    tableName: 'LvRaterCovCPercentage',
    tableDescription: 'Coverage C %',
    code: '70',
    description: '70%',
    isActive: true
},
{
    tableName: 'LvRaterCovCPercentage',
    tableDescription: 'Coverage C %',
    code: '75',
    description: '75%',
    isActive: true
},
{
    tableName: 'LvRaterCovCPercentage',
    tableDescription: 'Coverage C %',
    code: '0',
    description: 'Excluded',
    isActive: true
}];

export const LvRaterFloodZone = [{
    tableName: 'LvRaterFloodZone',
    tableDescription: 'Flood Zone',
    code: 'A',
    description: 'A',
    isActive: true
},
{
    tableName: 'LvRaterFloodZone',
    tableDescription: 'Flood Zone',
    code: 'A1-A30',
    description: 'A1-A30',
    isActive: true
},
{
    tableName: 'LvRaterFloodZone',
    tableDescription: 'Flood Zone',
    code: 'A99',
    description: 'A99',
    isActive: true
},
{
    tableName: 'LvRaterFloodZone',
    tableDescription: 'Flood Zone',
    code: 'AE',
    description: 'AE',
    isActive: true
},
{
    tableName: 'LvRaterFloodZone',
    tableDescription: 'Flood Zone',
    code: 'AH',
    description: 'AH',
    isActive: true
},
{
    tableName: 'LvRaterFloodZone',
    tableDescription: 'Flood Zone',
    code: 'AO',
    description: 'AO',
    isActive: true
},
{
    tableName: 'LvRaterFloodZone',
    tableDescription: 'Flood Zone',
    code: 'AR',
    description: 'AR',
    isActive: true
},
{
    tableName: 'LvRaterFloodZone',
    tableDescription: 'Flood Zone',
    code: 'B',
    description: 'B',
    isActive: true
},
{
    tableName: 'LvRaterFloodZone',
    tableDescription: 'Flood Zone',
    code: 'C',
    description: 'C',
    isActive: true
},
{
    tableName: 'LvRaterFloodZone',
    tableDescription: 'Flood Zone',
    code: 'D',
    description: 'D',
    isActive: true
},
{
    tableName: 'LvRaterFloodZone',
    tableDescription: 'Flood Zone',
    code: 'V',
    description: 'V',
    isActive: true
},
{
    tableName: 'LvRaterFloodZone',
    tableDescription: 'Flood Zone',
    code: 'VE',
    description: 'VE',
    isActive: true
},
{
    tableName: 'LvRaterFloodZone',
    tableDescription: 'Flood Zone',
    code: 'X',
    description: 'X',
    isActive: true
},
{
    tableName: 'LvRaterFloodZone',
    tableDescription: 'Flood Zone',
    code: 'NA',
    description: 'Blank',
    isActive: true
}];


export const LvRaterCovELiabilityLimit = [{
    tableName: 'LvRaterCovELiabilityLimit',
    tableDescription: 'Coverage E - Liability Limit',
    code: '100000',
    description: '$100,000',
    isActive: true
},
{
    tableName: 'LvRaterCovELiabilityLimit',
    tableDescription: 'Coverage E - Liability Limit',
    code: '200000',
    description: '$200,000',
    isActive: true
},
{
    tableName: 'LvRaterCovELiabilityLimit',
    tableDescription: 'Coverage E - Liability Limit',
    code: '300000',
    description: '$300,000',
    isActive: true
},
// {
//     tableName: 'LvRaterCovELiabilityLimit',
//     tableDescription: 'Coverage E - Liability Limit',
//     code: '400000',
//     description: '$400,000',
//     isActive: true
// },
{
    tableName: 'LvRaterCovELiabilityLimit',
    tableDescription: 'Coverage E - Liability Limit',
    code: '500000',
    description: '$500,000',
    isActive: true
}];

export const LvRaterCovFMedicalLimit = [{
    tableName: 'LvRaterCovFMedicalLimit',
    tableDescription: 'Coverage F - Medical Limit',
    code: '1000',
    description: '$1,000',
    isActive: true
},
{
    tableName: 'LvRaterCovFMedicalLimit',
    tableDescription: 'Coverage F - Medical Limit',
    code: '2500',
    description: '$2,500',
    isActive: true
},
{
    tableName: 'LvRaterCovFMedicalLimit',
    tableDescription: 'Coverage F - Medical Limit',
    code: '5000',
    description: '$5,000',
    isActive: true
}];

export const LvRaterCovLPersonalLiabilityLimit = [{
    tableName: 'LvRaterCovLPersonalLiabilityLimit',
    tableDescription: 'Coverage L - Personal Liability',
    code: '100000',
    description: '$100,000',
    isActive: true
},
{
    tableName: 'LvRaterCovLPersonalLiabilityLimit',
    tableDescription: 'Coverage L - Personal Liability',
    code: '300000',
    description: '$300,000',
    isActive: true
}];

export const LvFairRentalLivingLimit = [{
    tableName: 'LvRaterCovLPersonalLiabilityLimit',
    tableDescription: 'Coverage D/E - Fair Rental/Addl Living',
    code: '10',
    description: '10%',
    isActive: true
}];

export const CreditOrderStatusConstants = {
  NotOrdered : 'COS0',
  NoHit : 'COS1',
  NoScore : 'COS2',
  ScoreReceived : 'COS3',
};

export const EstimatedCreditConstants = {
  EC5 : 'EC5',
  EC4 : 'EC4',
  EC3 : 'EC3',
  EC2 : 'EC2',
  EC1 : 'EC1',
  EC0 : 'EC0',
};
