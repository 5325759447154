import { ValidationListModel } from 'app/shared/models/validation-list.model';
import { PathConstants } from 'app/shared/constants/path.constants';
import { NavData } from 'app/_nav';

export const createBatchPaymentsMenuItems = (id?: string, storage?, validation?: ValidationListModel): NavData[] => {

  const path = `/${PathConstants.Payments.Index}/${PathConstants.Payments.BatchPayments.Index}`;

  const suspendedPaymentsMenu = [
    {
      name: PathConstants.Payments.BatchPayments.PendingLabel,
      icon: 'fa fa-clock-o',
      url: `${path}/${PathConstants.Payments.BatchPayments.Pending}`
    },
    {
      name: PathConstants.Payments.BatchPayments.ProcessedLabel,
      icon: 'fa fa-check-square-o',
      url: `${path}/${PathConstants.Payments.BatchPayments.Processed}`
    }
  ];

  return suspendedPaymentsMenu;
};


