import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { PathConstants } from '../../../shared/constants/path.constants';
import { NavigationService } from '../../../core/services/navigation/navigation.service';
import { ClickTypes } from '../../../shared/enum/click-type.enum';
import { PolicyService } from '../../../core/services/submission/policy.service';
import { EntityRiskData } from '../../../modules/submission-management/data/entity-risk.data';
import { takeUntil, distinctUntilChanged, share, tap, filter } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/base-component';
import { PolicyIssueData } from '../data/policy-issue.data';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { ErrorMessageConstant } from '../../../shared/constants/error-message.constants';
import Utils from '../../../shared/utilities/utils';
import { BindAndIssueLabelConstants } from '../../../shared/constants/bind-and-issue.labels.constants';
import { UWR } from '../../../core/services/submission/uw-referrals/uwr.service';
import { UwApprovalData } from '../../../modules/submission-management/data/uw-approval.data';
import { Router } from '@angular/router';
import { GeneralValidationService } from 'app/core/services/submission/validations/general-validation.service';
import { PropertyValidationService } from 'app/core/services/submission/validations/property-validation.service';
import { ApplicantValidationService } from 'app/core/services/submission/validations/applicant-validation.service';
import { CoveragesValidationService } from 'app/core/services/submission/validations/coverages-validation.service';
import { InterestValidationService } from 'app/core/services/submission/validations/interest-validation.service';
import { PolicySummaryData } from '../data/policy-summary.data';
import { SubmissionPageData } from 'app/modules/submission-management/data/submission-page.data';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { ThirdPartyData } from 'app/modules/submission-management/data/third-party.data';
import { TaskGenerationService } from '../../../core/services/submission/task-generation/task-generation.service';
import { EndorsementsValidationService } from '../../../core/services/submission/validations/endorsements-validation.service';
import { Rater } from '../../../shared/constants/rater.constant';
import { CalculateRaterData } from 'app/core/services/submission/rater-premium/calculate-rater-data.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { AuthService } from '../../../core/services/auth.service';
import { MoratoriumService } from '../../../core/services/moratorium.services';
import { GenericConstants } from '../../../shared/constants/generic.constants';
import { PropertyData } from '../../../modules/submission-management/data/property.data';
import { PolicyBillingData } from '../data/policy-billing.data';
import { QuickQuoteService } from 'app/core/services/submission/quick-quote.service';
import { PolicyIssueConstants } from '../../../../app/shared/constants/policy-issue.labels.constants';
import { RiskStatus } from 'app/shared/models/data/dto/quick-quote/risk.dto';
import { RiskStatusConstants } from 'app/shared/constants/risk-status';
import { CoveragesData } from 'app/modules/submission-management/data/coverages.data';
import { QuickQuoteData } from 'app/modules/submission-management/data/quick-quote.data';
import * as moment from 'moment';
import { updateAppIsLoadingFromPolicyIssueComponent } from 'app/store/app/app.actions';
import { select, Store } from '@ngrx/store';
import {
  updateChangesIsLoadingFromPolicyIssueComponent,
  updateChangesListFromPolicyIssueComponent
} from 'app/store/changes/changes.actions';
import { selectChangesIsLoading } from 'app/store/changes/changes.selectors';
import { RiskDTO2 } from 'app/shared/models/data/dto/quick-quote/risk-dto2';
import { selectIsLoadingOos } from 'app/store/policy/policy.selectors';
import { selectPolicyUWRsIsLoading } from 'app/store/policy-uwrs/policy-uwrs.selectors';
import { BillingService } from 'app/core/services/billing/billing.service';
import { UpdateIsPremCoDto } from 'app/shared/models/data/dto/billing/update-ispremco.dto';
import { PolicyHistoryData } from '../data/policy-history-data';

@Component({
  selector: 'app-policy-issue',
  templateUrl: './policy-issue.component.html',
  styleUrls: ['./policy-issue.component.scss']
})
export class PolicyIssueComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  riskId: string;
  riskDetailId: string;
  status: string[] = [
    'Referred to UW',
    'Approved by UW',
    'Pending',
    'Additional Information Required'
  ];

  public PolicyIssueConstants = PolicyIssueConstants;
  endorsementRequestSubscription: Subscription;
  public genericConstants = GenericConstants;
  protected riskStatus = RiskStatusConstants;
  protected selectChangesIsLoading$: Observable<boolean>;
  protected selectIsLoadingOos$: Observable<boolean>;
  approveAnyUser: boolean = false;
  uwrsIsLoading: boolean = false;

  private selectPolicyUWRsIsLoading$: Observable<boolean>;

  constructor(
    public navigationService: NavigationService,
    protected policyService: PolicyService,
    protected entityRiskData: EntityRiskData,
    public policyIssueData: PolicyIssueData,
    protected uwrService: UWR,
    protected uwApprovalData: UwApprovalData,
    protected raterData: CalculateRaterData,
    protected router: Router,
    public generalValidationsService: GeneralValidationService,
    public propertyValidationService: PropertyValidationService,
    public applicantValidationService: ApplicantValidationService,
    public coveragesValidationService: CoveragesValidationService,
    public interestValidationService: InterestValidationService,
    public endorsementValidationService: EndorsementsValidationService,
    public policySummaryData: PolicySummaryData,
    public submissionPageData: SubmissionPageData,
    protected thirdPartyData: ThirdPartyData,
    protected taskGenService: TaskGenerationService,
    protected summaryData: SummaryData,
    public authService: AuthService,
    protected moratoriumService: MoratoriumService,
    protected propertyData: PropertyData,
    protected policyBillingData: PolicyBillingData,
    protected quickQuoteService: QuickQuoteService,
    protected coveragesData: CoveragesData,
    protected quickQuoteData: QuickQuoteData,
    protected store: Store,
    protected policyHistoryData: PolicyHistoryData,
    protected billingService: BillingService
  ) {
    super();
    this.selectChangesIsLoading$ = this.store.pipe(select(selectChangesIsLoading));
    this.selectPolicyUWRsIsLoading$ = this.store.pipe(select(selectPolicyUWRsIsLoading));
    this.selectIsLoadingOos$ = this.store.pipe(select(selectIsLoadingOos));
  }

  ngOnInit() {
    this.initData();
    this.subscribeToPolicyStatus();
    this.subscribeToSelectPolicyChangesIsLoading$();
    this.subscribeToSelectPolicyUWRsIsLoading$();
  }

  ngAfterViewInit() {
    if (!this.policySummaryData.enablePolicyNavSaving && this.policySummaryData.isEditPolicy) {
      this.policySummaryData.enablePolicyNavSaving = true;
    }
  }

  initData(): void {
    this.riskId = this.entityRiskData.getRisk()?.id;
    this.riskDetailId = this.entityRiskData.getRisk()?.riskDetails[0].id;
    this.quickQuoteData.getPolicyChangesForSeniorRetiree();
    this.policyIssueData.resetPolicyChanges();

    this.policySummaryData.saveCoveragesComplete$.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(() => {
      this.policyIssueData.policyChangesList = [];
      this.checkIfDoneSaving();
    });

    this.checkUwApprovalPageValidity();
  }

  getPolicyChanges(): void {
    const latestEffectiveDate = new Date(moment(this.policyIssueData.latestIssuedChangeEffectiveDate?.singleDate?.jsDate).format('YYYY-MM-DD'));
    const newSelectedEffectiveDate = new Date(moment(this.policyIssueData.issuePolicyForm.get('changeEffectiveDate').value?.singleDate?.jsDate).format('YYYY-MM-DD'));

    this.riskId = this.entityRiskData.getRisk()?.id;
    this.riskDetailId = this.entityRiskData.getRisk()?.riskDetails[0].id;

    if (this.policyIssueData.policyChangesSubscription) {
      this.policyIssueData.policyChangesSubscription.unsubscribe();
    }
    if (this.entityRiskData.getPolicyStatus() !== 'CAN') {
      this.uwApprovalData.hurricaneDeductibleChangesDescription = this.policySummaryData.policyChanges?.find(x => x.includes('Changed Hurricane Deductible From:'));
      this.policyIssueData.resetPolicyChanges();
      this.policyIssueData.isPolicyChangesLoading = true;
      this.store.dispatch(updateChangesIsLoadingFromPolicyIssueComponent({ isLoading: true }));

      this.policyIssueData.policyChangesSubscription = this.policyService.getPolicyChanges(this.riskId, this.riskDetailId).pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe((policyChanges: Array<string>) => {
        this.policyIssueData.policyChangesList = policyChanges;
        this.policyIssueData.policyChangesLength = policyChanges.length;
        this.policyIssueData.textForEndorsementForm.get('textForEndorsementPolicyChanges')?.setValue(this.policyIssueData.policyChangesList.join('\n'));
        this.summaryData.setSubStatusCode();
        if (this.policyIssueData.policyChangesLength > 0) {
          this.uwrService.checkUWR106();
        } else {
          this.uwrService.updateUWR106(false);
          this.summaryData.setSubStatusCode();
        }
        if (latestEffectiveDate > newSelectedEffectiveDate) {
          this.policyIssueData.callOutOfSequence(newSelectedEffectiveDate, false);
          this.policyIssueData.isPolicyChangesLoading = false;
        } else {
          this.policyIssueData.isPolicyChangesLoading = false;
        }

        this.store.dispatch(updateChangesIsLoadingFromPolicyIssueComponent({ isLoading: false }));
        this.store.dispatch(updateChangesListFromPolicyIssueComponent({ list: policyChanges }));
      });
    } else {
      this.policyIssueData.resetPolicyChanges();
    }
  }

  public onClick(clickType?) {
    switch (clickType) {
      case ClickTypes.Back:
        // to do back
        this.navigationService.navigatePolicyRoute(PathConstants.Policy.Policies.UWApproval);
        break;
      case ClickTypes.Next:
        // to do next
        this.navigationService.navigatePolicyRoute(PathConstants.Policy.Policies.Notes);
        break;
      case ClickTypes.PolicyIssue:
        this.policyIssue();
        break;
      case ClickTypes.Reset:
        this.policyReset();
        break;
    }
  }

  policyIssue(): void {
    const effectiveDate = new Date(moment(this.policyIssueData.issuePolicyForm.get('changeEffectiveDate').value?.singleDate?.jsDate).format('YYYY-MM-DD'));
    const latestIssueEffectiveDate = new Date(moment(this.policyIssueData.latestIssuedChangeEffectiveDate?.singleDate?.jsDate).format('YYYY-MM-DD'));

    const confirmationMessage: string = latestIssueEffectiveDate > effectiveDate ?
      this.PolicyIssueConstants.outOfSequenceEndoConfirmation : this.PolicyIssueConstants.issuanceConfirmation;

    NotifUtils.showConfirmMessage(confirmationMessage, () => {
      Utils.blockUI();

      if (this.thirdPartyData.checkIfFloodIsRequiredBeforeBinding()) {
        Utils.unblockUI();
        return NotifUtils.showError(ErrorMessageConstant.FloodAssist.mustOrderFloodErrorMessage);
      }

      const raterResultKey = `${Rater.raterResult}_${this.riskDetailId}`;
      const raterRequestKey = `${Rater.raterRequest}_${this.riskDetailId}`;

      const raterResult = localStorage.getItem(raterResultKey);
      const raterRequest = localStorage.getItem(raterRequestKey);
      const policyIssuePayload: any = this.getPolicyPayload(raterResult, raterRequest);

      this.postPolicy(policyIssuePayload);
    }, () => { });
  }

  getPolicyPayload(raterResult: any, raterRequest: string): any {
    const effectiveDate = this.policyIssueData.issuePolicyForm.get('changeEffectiveDate').value?.singleDate?.date;

    return {
      riskId: this.riskId,
      riskDetailId: this.riskDetailId,
      lastRiskDetailId: this.entityRiskData.getRisk()?.riskDetails[0].lastRiskDetailId,
      raterResult: JSON.parse(raterResult),
      raterRequest: raterRequest,
      effectiveDate: `${effectiveDate.month}/${effectiveDate.day}/${effectiveDate.year}`
    };
  }

  postPolicy(policyIssuePayload: any): void {
    this.policyService.postPolicyIssue(policyIssuePayload).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.policyIssueData.policyIssueResult = result;

      const entityRisk = this.entityRiskData?.EntityRisk?.risks[0];
      const raterRes = {
        riskId: result.riskId,
        policyNumber: entityRisk?.policyNumber ?? '',
        riskDetailId: entityRisk?.riskDetails[0]?.id,
        lastRiskDetailId: entityRisk?.riskDetails[0]?.lastRiskDetailId ?? ''
      };

      this.taskGenService.triggerTaskMethods(raterRes);
      this.taskGenService.task2();
      if (this.endorsementRequestSubscription) {
        this.endorsementRequestSubscription.unsubscribe();
      }

      Utils.unblockUI();
      const addIntCode = 'AddInt';
      const descOfInterestCodePremCo = 'DI4';
      const premFinanceExists = entityRisk.riskDetails[0].riskInterests[0].riskInterestDetails.some(y => y.interestTypeCode === addIntCode && y.descOfInterestCode === descOfInterestCodePremCo);
      const payloadForUpdateIsPremCo: UpdateIsPremCoDto = {
        riskId: this.entityRiskData.getRiskId(),
        isPremCo: premFinanceExists

      };

      this.showSuccessPolicyIssue(entityRisk);
      this.billingService.putUpdateIsPremco(payloadForUpdateIsPremCo).subscribe(x => {});
    }, error => {
      Utils.unblockUI();
      NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
    });
  }

  policyReset(): void {
    NotifUtils.showConfirmMessage(BindAndIssueLabelConstants.areYouSureYouWantToReset, () => {
      Utils.blockUI();

      this.policyService.policyUpdateSubStatusCode(this.riskDetailId, 'Draft').pipe(takeUntil(this.stop$)).subscribe();

      this.policyService.postResetPolicyChanges(this.riskId, this.riskDetailId).pipe(takeUntil(this.stop$)).subscribe(result => {
        Utils.unblockUI();
        const callBackReloadPage = () => {
          this.initData();
          this.summaryData.showPendingEndorsementStatus = false;
          this.entityRiskData.moratoriumResponse = null;
          this.policyService.reset.next(true);
        };
        NotifUtils.showSuccessWithConfirmationFromUser('Done resetting Policy record. Page will reload after clicking \"OK\".', callBackReloadPage);
      }, error => {
        Utils.unblockUI();
        NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
      });
    },
      () => {

      });
  }

  get isResetDisabled(): boolean {
    const validSubStatus: RiskStatus[] = [
      this.riskStatus.pending as RiskStatus,
      this.riskStatus.referredToUW as RiskStatus,
      this.riskStatus.approvedByUW as RiskStatus,
      this.riskStatus.additionalInformationrequired as RiskStatus,
      this.riskStatus.declinedByUW as RiskStatus
    ];

    if (validSubStatus.includes(this.submissionPageData.policyStatus.value)) {
      return false;
    }

    return !!!this.policyIssueData.policyChangesLength ||
      this.policyIssueData.policyChangesLength === 0 ||
      this.isDeclinedByUWStatus() ||
      !this.policySummaryData.isEditPolicy;
  }

  validatePages() {
    this.generalValidationsService.invalidFormControlList = [];
    this.generalValidationsService.invalidFormControlErrorMessage = '';
    this.coveragesValidationService.checkCoveragesPage();
    this.propertyValidationService.checkPropertyPage();
    this.applicantValidationService.checkApplicantPage();
    this.applicantValidationService.checkCoApplicantSection();
    this.interestValidationService.checkPriorInsuranceSection();
    this.uwApprovalData.checkUwApprovalStatus();
    this.endorsementValidationService.checkEndorsementsPage();
    this.policyIssueData.checkIssuePage();
  }

  isDeclinedByUWStatus(): boolean {
    const status = this.submissionPageData.policyStatus?.value === AgentDashboardConstants.declineByUW;
    return status;
  }

  subscribeToPolicyStatus(): void {
    this.submissionPageData.policyStatus.subscribe(value => {
      if (this.status.includes(value)) {
        this.policyIssueData.disableChangeEffectiveDate = false;
      } else {
        this.policyIssueData.disableChangeEffectiveDate = true;
      }

      this.checkUwApprovalPageValidity();
    });
  }

  get policyIssueValidationMessage(): string {
    const finalPremiumFees =  this.policyIssueData.premiumChangeList?.find(x => x.stepAlias === 'FPrePlusFee')?.premiumDiffChange;
    let msg: string = '';
    if (this.policyBillingData.summary?.paid <= 0) {
      msg = 'Payment of deposit is required prior to issuance.';
    }

    if (finalPremiumFees === 0) {
      msg = '';
    }

    return msg;
  }

  checkIfDoneSaving() {
    if (!this.quickQuoteService.checkIfAllCategoriesDoneSaving() || !this.entityRiskData.isDoneSavingForms) {
      setTimeout(() => { this.checkIfDoneSaving(); }, 100); /* this checks the flag every 100 milliseconds*/
    } else {
      if (!this.entityRiskData.isDonePopulatingForms) {
        this.entityRiskData.isDonePopulatingForms$.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(() => {
          this.getPolicyChanges();
          this.policyIssueData.setChangeEffectiveDateOption();
        });
      } else if (!this.entityRiskData.isDoneSavingForms) {
        this.entityRiskData.isDoneSavingForms$.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(() => {
          this.getPolicyChanges();
          this.policyIssueData.setChangeEffectiveDateOption();
        });
      } else {
        this.getPolicyChanges();
      }
    }
  }

  get isStatusExpired(): boolean {
    return this.submissionPageData.quoteStatus.value === this.genericConstants.quoteStatus.pexp || this.submissionPageData.quoteStatus.value === this.genericConstants.quoteStatus.qexp;
  }

  subscribeToSelectPolicyChangesIsLoading$(): void {
    this.selectChangesIsLoading$.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(isLoading => {
      if (this.policySummaryData.isEditPolicy) {
        if (isLoading) {
          this.store.dispatch(updateAppIsLoadingFromPolicyIssueComponent({ isLoading: true }));
        } else {
          this.store.dispatch(updateAppIsLoadingFromPolicyIssueComponent({ isLoading: false }));
        }
      }
    });
  }

  showSuccessPolicyIssue(entityRisk: RiskDTO2): void {
    NotifUtils.showSuccessWithConfirmationFromUser(BindAndIssueLabelConstants.issuedSuccessfully, () => {
      // success Ok
      this.policyIssueData.resetPolicyChanges();
      this.policySummaryData.isFromDeclinedEdit = false;
      localStorage.setItem('isEditPolicy', 'false');
      this.uwApprovalData.uwApprovalList = [];
      this.summaryData.showPendingEndorsementStatus = false;
      this.policySummaryData.isEditPolicy = false;
      localStorage.setItem('lastRiskDetailId', entityRisk?.riskDetails[0]?.lastRiskDetailId);
      this.router.navigateByUrl(`/policies/${this.riskId}/${this.policyIssueData.policyIssueResult.newRiskDetailId}/summary`);
      this.entityRiskData.moratoriumResponse = [];
      this.uwApprovalData.uwrSavedData$.next(null);
      this.summaryData.setSubStatusCode();
      this.entityRiskData.isDoneFetchMoratorium = false;
    });
  }

  subscribeToSelectPolicyUWRsIsLoading$(): void {
    this.selectPolicyUWRsIsLoading$.pipe(filter(data => { return data !==  null; }), takeUntil(this.stop$)).subscribe(result => {
      this.uwrsIsLoading = result;

      if (result) {
        this.approveAnyUser = false;
      } else {
        this.checkUwApprovalPageValidity();
      }
    });
  }

  checkUwApprovalPageValidity(): void {
    this.validatePages();
    this.approveAnyUser = this.uwApprovalData.approveAnyUser();
  }
}

