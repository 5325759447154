import { Component, OnDestroy, OnInit } from '@angular/core';
import { TaskData } from 'app/modules/dashboard/data/task.data';
import { LvTaskNoteContactType } from 'app/shared/constants/task.options.constants';
import { TaskLabelsConstants } from 'app/shared/constants/task.labels.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ITaskNotesDTO, TaskNotesDTO } from 'app/shared/models/data/dto/task/task.dto';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { AuthService } from 'app/core/services/auth.service';

@Component({
  selector: 'app-task-notes-modal',
  templateUrl: './task-notes-modal.component.html',
  styleUrls: ['./task-notes-modal.component.scss']
})
export class TaskNotesModalComponent implements OnInit, OnDestroy{

  title: string;
  isAddNotes: boolean = true;
  public taskNotesModalLabels = TaskLabelsConstants;
  LvTaskNoteContactType = LvTaskNoteContactType;
  public ErrorMessageConstant = ErrorMessageConstant;
  modalRef: BsModalRef | null;

  currentDate: Date = this.authService.getCustomDate();
  currentNoteId: string;

  modalData: ITaskNotesDTO;

  constructor(
    public  bsModalRef: BsModalRef,
    public taskData: TaskData,
    protected datePipe: DatePipe,
    public authService: AuthService) { }

  ngOnInit() {
    this.taskData.initializeNoteForm();
    if (this.isAddNotes) {
      this.taskData.notesForm.get('createdDate').setValue(this.datePipe.transform(this.currentDate, this.taskData.dateFormat));
    } else {
      this.populatesNotesForm(this.modalData);
    }
  }

  ngOnDestroy() {
    this.currentNoteId = null;
    this.modalData = null;
    this.isAddNotes = true;
  }

  hideMyModal(): void {
    this.bsModalRef.hide();
  }

  okMyModal(): void {
    if (this.isAddNotes) {
      const note: TaskNotesDTO = {
        createdDate: this.currentDate,
        createdBy: this.taskData.currentUserId,
        taskContactTypeCode: this.taskData.notesForm.get('taskContactTypeCode').value,
        contactFullName: this.taskData.notesForm.get('contactFullName').value,
        description: this.taskData.notesForm.get('description').value,
        initialId: this.taskData.taskNotes.length + 1,
        isEditable: true,
        isDeletable: true,
        createdByFullName: this.taskData.currentUserName
      };
      this.taskData.taskNotes = _.concat(this.taskData.taskNotes, note);
    } else {
      let noteData: TaskNotesDTO;
      if (this.taskData.isAddTask) {
        noteData = this.taskData.taskNotes.find(a => a.initialId === Number(this.currentNoteId));
      } else {
        noteData = this.taskData.taskNotes.find(a => a.id === this.currentNoteId);
      }
      const index = _.findIndex(this.taskData.taskNotes, noteData);
      noteData.taskContactTypeCode = this.taskData.notesForm.get('taskContactTypeCode').value;
      noteData.contactFullName = this.taskData.notesForm.get('contactFullName').value;
      noteData.description = this.taskData.notesForm.get('description').value;
      this.taskData.taskNotes.splice(index, 1, noteData);
    }
    this.taskData.hasNoteChanges = true;
    this.bsModalRef.hide();
    this.taskData.setTaskNotesList(1);
  }

  checkForm(): boolean {
    return !this.taskData.notesForm.valid;
  }

  getNotesFormValue(name: string): string {
    return this.taskData.notesForm.get(name)?.value ?? '';
  }

  populatesNotesForm(data: ITaskNotesDTO): void {
    this.taskData.notesForm.get('createdDate').setValue(this.datePipe.transform(data.createdDate, this.taskData.dateFormat));
    this.taskData.notesForm.get('taskContactTypeCode').setValue(data.taskContactTypeCode);
    this.taskData.notesForm.get('contactFullName').setValue(data.contactFullName);
    this.taskData.notesForm.get('description').setValue(data.description);
  }

  hasNoteChanges(): boolean {
    if (this.isAddNotes) {
      return false;
    }

    const hasChanges = this.modalData.taskContactTypeCode === this.getNotesFormValue('taskContactTypeCode') &&
                      this.modalData.contactFullName === this.getNotesFormValue('contactFullName') &&
                      this.modalData.description === this.getNotesFormValue('description');

    return hasChanges;
  }
}
