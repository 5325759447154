import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { PathConstants } from '../../shared/constants/path.constants';
import { AuthService } from '../services/auth.service';
import { MaintenanceModeService } from '../services/maintenance-mode.service';
import { Observable, of} from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BaseClass } from 'app/shared/base-class';

@Injectable()
export class LoginGuard extends BaseClass implements CanActivate {
  constructor(private _authService: AuthService, private _router: Router, private _maintenanceModeService: MaintenanceModeService) { 
    super();
  }
  maintenanceModeInformation: any;
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.getIPAddress();
  }

  getIPAddress(): Observable<boolean> {
    return this._authService.getIPAddress().pipe(
      map((result: any) => {
        localStorage.setItem('ipAddress', result.ip);

        return result.ip;
      }),
      switchMap((ipAddress: string) => this._authService.getMaintenanceModeInformation(ipAddress)),
    ).pipe(
      map(res => {
      this.maintenanceModeInformation = <any>res;
      if (this.maintenanceModeInformation) {
        if (this.maintenanceModeInformation.isMaintenanceMode) {
          if (this.maintenanceModeInformation.isIpAddressWhiteList) {
            if (this._authService.isLoggedIn()) {
              this._router.navigate([PathConstants.Dashboard.Index]);
              return false;
            }
            return true;
          } else {
            this._maintenanceModeService.redirectToMaintenanceModePage();
            return false;
          }
        } else {
          if (this._authService.isLoggedIn()) {
            this._router.navigate([PathConstants.Dashboard.Index]);
            return false;
          }
          return true;
        }
      }
    }),
    catchError(err => {
      if (this._authService.isLoggedIn()) {
        this._router.navigate([PathConstants.Dashboard.Index]);
        return of(false);
      }
      return of(true);
    }));
  }
}

