import { Action, createReducer, on } from '@ngrx/store';
import * as fromChangesActions from 'app/store/changes/changes.actions';
import { initialState, ChangesState } from './changes.state';

const _changesReducer = createReducer(
  initialState,
  on(
    fromChangesActions.updateChangesIsLoadingFromPolicyHistoryData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromChangesActions.updateChangesListFromPolicyHistoryData,
    (state, { list }) => ({ ...state, list: list })
  ),
  on(
    fromChangesActions.updateChangesIsLoadingFromPolicyIssueComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromChangesActions.updateChangesListFromPolicyIssueComponent,
    (state, { list }) => ({ ...state, list: list })
  ),
  on(
    fromChangesActions.updateChangesIsLoadingFromPolicyHistoryComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromChangesActions.updateChangesListFromPolicyHistoryComponent,
    (state, { list }) => ({ ...state, list: list })
  ),
  on(
    fromChangesActions.updateChangesIsLoadingFromPolicyManagementComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromChangesActions.updateChangesListFromPolicyManagementComponent,
    (state, { list }) => ({ ...state, list: list })
  ),
  on(
    fromChangesActions.updateChangesIsLoadingFromQuickQuoteData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromChangesActions.updateChangesListFromQuickQuoteData,
    (state, { list }) => ({ ...state, list: list })
  ),
  on(
    fromChangesActions.updateChangesIsLoadingFromSubmissionManagementComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromChangesActions.updateChangesListFromSubmissionManagementComponent,
    (state, { list }) => ({ ...state, list: list })
  ),
  on(
    fromChangesActions.updateChangesIsLoadingFromUwApprovalComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromChangesActions.updateChangesListFromUwApprovalComponent,
    (state, { list }) => ({ ...state, list: list })
  ),
  on(
    fromChangesActions.updateChangesIsLoadingFromPolicyEditCancelButtonComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromChangesActions.updateChangesListFromPolicyEditCancelButtonComponent,
    (state, { list }) => ({ ...state, list: list })
  ),
  on(
    fromChangesActions.updateChangesPolicyChangeUserFromPolicyManagementComponent,
    (state, { policyChangeUser }) => ({ ...state, policyChangeUser: policyChangeUser })
  ),
  on(
    fromChangesActions.updateChangesEffectiveDateUserFromPolicyManagementComponent,
    (state, { effectiveDateUser }) => ({ ...state, effectiveDateUser: effectiveDateUser })
  ),
  on(
    fromChangesActions.updateChangesListFromIssuePolicyComponent,
    (state, { list }) => ({ ...state, list: list })
  )
);

export const changesReducer = (
  state: ChangesState | null,
  action: Action
) => {
  return _changesReducer(state, action);
};