import { Component, OnInit } from '@angular/core';
import { ITaskDocumentsDTO } from 'app/shared/models/data/dto/task/task.dto';
import { TaskData } from 'app/modules/dashboard/data/task.data';
import { TaskLabelsConstants } from 'app/shared/constants/task.labels.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TaskDocumentsModalComponent } from '../task-documents-modal/task-documents-modal.component';
import NotifUtils from 'app/shared/utilities/notif-utils';
import * as _ from 'lodash';

@Component({
  selector: 'app-task-document-section',
  templateUrl: './task-document-section.component.html',
  styleUrls: ['./task-document-section.component.scss']
})
export class TaskDocumentSectionComponent implements OnInit {

  public taskDocumentLabel = TaskLabelsConstants;
  isDocumentSectionHidden: boolean = false;
  modalRef: BsModalRef | null;

  constructor(
    protected modalService: BsModalService,
    public  bsModalRef: BsModalRef,
    public taskData: TaskData) {
    }

  ngOnInit() {
    this.setPage(1);
  }

  setPage(page: number) {
    this.taskData.setTaskDocumentsList(page);
  }


  toggleDocument() {
    this.isDocumentSectionHidden = !this.isDocumentSectionHidden;
  }

  showDocumentModal(): void {
    const initialState = {
      title: this.taskDocumentLabel.Task.DocumentsSection.DocumentUploadModalTitle,
      isAddDocument: true,
      modalData: null,
      hideSaveButton: false
    };

    this.modalRef = this.modalService.show(TaskDocumentsModalComponent, {
      initialState,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  showEditDocumentModal(data: ITaskDocumentsDTO): void {
    const initialState = {
      title: this.taskDocumentLabel.Task.DocumentsSection.DocumentUpdateModalTitle,
      isAddDocument: false,
      modalData: data,
      hideSaveButton: false,
      isViewDocument: false
    };

    this.modalRef = this.modalService.show(TaskDocumentsModalComponent, {
      initialState,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  showViewDocumentModal(data: ITaskDocumentsDTO): void {

    const initialState = {
      title: this.taskDocumentLabel.Task.DocumentsSection.DocumentViewModalTitle,
      isAddDocument: false,
      modalData: data,
      hideSaveButton: true,
      isViewDocument: true
    };

    this.modalRef = this.modalService.show(TaskDocumentsModalComponent, {
      initialState,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  showDeleteDocumentConfirmation(data: ITaskDocumentsDTO): void {
    NotifUtils.showConfirmMessage(TaskLabelsConstants.Task.ConfirmationMessage.deleteDocument, () => {
      // Delete Document
      this.deleteDocuments(data);
    }, () => {

    });
  }

  deleteDocuments(data: ITaskDocumentsDTO): void {
    if (data.id) {
      this.taskData.taskDocuments = this.taskData.taskDocuments.filter(a => a.id !== data.id);
    } else if (data.initialId) {
      this.taskData.taskDocuments = this.taskData.taskDocuments.filter(a => a.initialId !== data.initialId);
      if(this.taskData.taskDocuments.length === 0) {
        this.taskData.hasDocumentChanges = false;
      }
    }

    _.forEach(this.taskData.taskDocuments, (document) => {
      if (!document.id){
        this.taskData.hasDocumentChanges = true;
        return false;
      } else {
        this.taskData.hasDocumentChanges = false;
      }
    });

    this.setPage(1);
  }

  checkUploadButton(): boolean {
    return this.taskData.currentRiskId === null || this.taskData.currentTaskId === null ||
      this.taskData.currentStateCode === null;
  }
}
