import { Action, createReducer, on } from "@ngrx/store";
import * as fromMoratoriumActions from "app/store/moratorium/moratorium.actions";
import { initialState, MoratoriumState } from "./moratorium.state";

const _moratoriumReducer = createReducer(
  initialState,
  on(
    fromMoratoriumActions.updateMoratoriumIsLoadingFromZipCodeData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromMoratoriumActions.updateMoratoriumListFromZipCodeData,
    (state, { list }) => ({ ...state, list: list })
  ),
  on(
    fromMoratoriumActions.updateMoratoriumIsLoadingFromPolicyManagementComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromMoratoriumActions.updateMoratoriumListFromPolicyManagementComponent,
    (state, { list }) => ({ ...state, list: list })
  )
);

export function moratoriumReducer(
  state: MoratoriumState | null,
  action: Action
) {
  return _moratoriumReducer(state, action);
}