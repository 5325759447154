import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UWApprovalPageLabel } from 'app/shared/constants/uw-approval.labels.constants';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { UWApprovalModalModel } from 'app/shared/models/submission/uw-approval/uw-approval.model';
import { BsModalRef } from 'ngx-bootstrap';
import { UwApprovalData } from '../../../data/uw-approval.data';
import * as moment from 'moment';
import { CustomValidators } from 'app/shared/validators/custom.validator';
import { v4 as uuidv4 } from 'uuid';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { SaveUWApprovalDTO } from 'app/shared/models/data/dto/quick-quote/save-uw-approval.dto';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { RiskUWApprovalDTO } from 'app/shared/models/data/dto/quick-quote/uw-approval.dto';
import { serialize } from 'object-to-formdata';
import Utils from 'app/shared/utilities/utils';
import { QuickQuoteService } from 'app/core/services/submission/quick-quote.service';
import { ToastrService } from 'ngx-toastr';
import { UwApprovalSavingData } from 'app/modules/submission-management/data/uw-approval-saving.data';
import { FileUploadConstants } from '../../../../../shared/constants/file-upload.constants';

@Component({
  selector: 'app-uw-approval-file-upload-modal',
  templateUrl: './uw-approval-file-upload-modal.component.html',
  styleUrls: ['./uw-approval-file-upload-modal.component.scss'],
})
export class UwApprovalFileUploadModalComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter<any>();
  public ErrorMessageConstant = ErrorMessageConstant;
  public UWApprovalLabel = UWApprovalPageLabel;
  public genericConstants = GenericConstants;

  uwApprovalFileUploadForm: FormGroup;
  modalRef: BsModalRef | null;
  title: any;
  uwApprovalModel: UWApprovalModalModel;
  uwApprovalModalData: UWApprovalModalModel[];
  isAdd: boolean;
  rowId: any;
  isCancel: boolean = false;
  fileToUpload: File = null;
  fileSizeExceeds: boolean = false;
  invalidFileName: boolean = false;
  fileLabel: string = FileUploadConstants.noFileChosen;

  constructor(
    protected fb: FormBuilder,
    public bsModalRef: BsModalRef,
    protected uwApprovalData: UwApprovalData,
    public summaryData: SummaryData,
    public quickQuoteService: QuickQuoteService,
    protected toast: ToastrService,
    public uwApprovalSavingData: UwApprovalSavingData
  ) { }

  ngOnInit() {
    this.initUploadForm();
  }

  initUploadForm(): void {
    this.uwApprovalFileUploadForm = this.fb.group({
      file: [null, [Validators.required, CustomValidators.requiredFileFormat(this.genericConstants.acceptedFileTypes)]],
    });
  }

  hideModal(): void {
    this.isCancel = true;
    this.bsModalRef.hide();
  }

  onSubmit() {
    const file = this.uwApprovalFileUploadForm.get('file').value?.name;

    if (!this.isCancel) {
      this.uwApprovalData.uwApprovalTableRows.forEach((item) => {
        if (item.id === this.uwApprovalData.selectedRowId) {
          const currentdate =this.summaryData.authService.getCustomDate();

          if (!item.files) {
            item.files = [];
            this.addFile(item, currentdate, file);
          } else {
            this.addFile(item, currentdate, file);
          }
          this.uwApprovalSavingData.saveUWApproval(true, this.rowId);
        }
      });

      this.isCancel = false;
      this.bsModalRef.hide();
    }
  }

  addFile(item: any, currentdate: Date, file: any): void {
    item.files.push({
      id: uuidv4(),
      fileName: file,
      filePath: this.uwApprovalFileUploadForm.get('file').value,
      dateAdded: String(moment(currentdate).format('MM/DD/YYYY')),
      file: this.fileToUpload,
      isUploaded: false
    });
  }

  handleFileInput(files: FileList): void {
    const specialChars  = /[#%]+/;
    this.fileToUpload = files.item(0);
    this.fileLabel = this.fileToUpload?.name;
    this.uwApprovalFileUploadForm.get('file').setValue(this.fileToUpload);
    this.fileSizeExceeds = false;
    this.invalidFileName = false;

    if (this.uwApprovalFileUploadForm.valid) {
      this.fileSizeExceeds = this.fileToUpload?.size > this.genericConstants.fileSizeLimit ? true : false;
      this.invalidFileName = specialChars.test(this.fileToUpload.name);
    }
  }

  get invalidFileTypeMessage(): string {
    return `The document ${this.uwApprovalFileUploadForm.get('file').value?.name} could not be uploaded. The file type is invalid.
    Note: Only the following file types are valid and can be attached – <list of valid file types e.g. ".pdf", ".doc", ".docx", ".msg", ".jpg", ".jpeg", ".bmp", ".png", ".xls", ".xlsx", ".txt", ".zip">`;
  }

  get acceptedFileTypes(): string {
    let acceptedFileTypes: string = '';

    this.genericConstants.acceptedFileTypes.forEach(item => {
      acceptedFileTypes += `.${item}, `;
    });

    return acceptedFileTypes;
  }
}
