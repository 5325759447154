import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SubmissionRoutingModule } from './submission-routing.module';
import { SharedModule } from '../../../shared/shared.module';
import { EndorsementsModule } from './endorsements/endorsements.module';
import { PropertyModule } from './property/property.module';
import { ClaimsModule } from './claims/claims.module';
import { UwQuestionsModule } from './uw-questions/uw-questions.module';
import { UwApprovalModule } from './uw-approval/uw-approval.module';
import { BindAndIssueModule } from './bind-and-issue/bind-and-issue.module';
import { InterestsModule } from './interests/interests.module';

import { SubmissionComponent } from './submission.component';
import { SubmissionSearchFilterComponent } from './submission-list/submission-list-details/submission-search-filter/submission-search-filter.component';
import { SubmissionTableComponent } from './submission-list/submission-list-details/submission-table/submission-table.component';
import { RouterModule } from '@angular/router';
import { DashboardModule } from 'app/modules/dashboard/dashboard.module';
import { CustomPipesModule } from '../../../shared/custom pipes/custom-pipe.module';
import { DataTableModule } from 'angular2-datatable';
import { SubmissionTablePipe } from './submission-list/submission-list-details/submission-table/submission-table.pipe';
import { PaginationModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [
    SubmissionComponent,
    SubmissionTableComponent,
    SubmissionSearchFilterComponent,
    SubmissionTablePipe
  ],
  imports: [
    CommonModule,
    SubmissionRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    EndorsementsModule,
    PropertyModule,
    ClaimsModule,
    UwQuestionsModule,
    InterestsModule,
    UwApprovalModule,
    BindAndIssueModule,
    RouterModule,
    DashboardModule,
    CustomPipesModule,
    DataTableModule,
    PaginationModule.forRoot()
  ],
  exports: [
    SubmissionSearchFilterComponent,
    SubmissionTableComponent,
    SubmissionComponent
  ],
  entryComponents: [
  ],
})
export class SubmissionModule { }
