import { Injectable } from '@angular/core';
import { BaseClass } from '../../../shared/base-class';
import { RaterPremiumService } from '../../../core/services/submission/rater-premium/rater-premium.service';
import { EndorsementHistory } from '../../../shared/models/rater-premium/endorsement-history.model';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Utils from 'app/shared/utilities/utils';
import { PolicyService } from '../../../core/services/submission/policy.service';
import { EntityRiskData } from '../../../modules/submission-management/data/entity-risk.data';
import { PolicyIssueData } from './policy-issue.data';
import { SummaryData } from '../../../modules/submission-management/data/summary.data';
import { LvCancellationTypeReason } from 'app/shared/constants/policy-cancellation.options.constants';

@Injectable({
  providedIn: 'root'
})
export class PolicyHistoryData extends BaseClass {

  historyItems: BehaviorSubject<EndorsementHistory[]> = new BehaviorSubject<EndorsementHistory[]>(null);
  historyItemsData: EndorsementHistory[] = [];

  isPolicyChangesLoading: boolean = false;
  isHistoryLoading: boolean = false;

  LvCancellationTypeReason = LvCancellationTypeReason;

  constructor(public raterPremiumService: RaterPremiumService
    , protected policyService: PolicyService
    , protected entityRiskData: EntityRiskData
    , protected policyIssueData: PolicyIssueData
    , protected summaryData: SummaryData) {
    super();
  }

  populateHistoryData(
    policyNumber?: string,
    riskId?: string,
    riskDetailId?: string): void {
    this.isHistoryLoading = true;
    this.raterPremiumService.getEndorsementHistory(policyNumber, riskId, riskDetailId)
      .pipe(takeUntil(this.stop$))
      .subscribe(result => {
        this.historyItems.next(result);
        Utils.updateNextBackPosition();
        this.isHistoryLoading = false;
      });
  }

  populatePolicyChangesHistory(riskDetailId?: string): void {
    const hasDetails = this.historyItemsData.find(x => x.riskDetailId === riskDetailId && x.details !== undefined && x.policyStatus !== 'CANCELLED');
    const riskId = this.entityRiskData.getRiskId();

    if ((hasDetails === undefined || !hasDetails)) {
      this.isPolicyChangesLoading = true;
      this.policyIssueData.isPolicyChangesLoading = true;
      this.policyService.getPolicyChanges(riskId, riskDetailId)
        .pipe(takeUntil(this.stop$))
        .subscribe(result => {
          this.summaryData.setSubStatusCode();
          this.historyItemsData = this.historyItemsData.map((items: EndorsementHistory) => {
            if (items.riskDetailId === riskDetailId && items.policyStatus !== 'CANCELLED') {
              const final_res = [];
              result.map(res => {
                if (!res.includes('Current Endorsement From')) {
                  final_res.push(res);
                };
              });
              items.details = final_res;
            }
            items.effectiveDate = new Date(items.effectiveDate);
            return items;
          });
          this.isPolicyChangesLoading = false;
          this.policyIssueData.isPolicyChangesLoading = true;
        });
    }
  }

  mapCancellationReasonHistory(historyItems: EndorsementHistory, hasCancelledInformation?: boolean): void {
    switch (hasCancelledInformation) {
      case true:
        this.historyItemsData = this.historyItemsData.map((items: EndorsementHistory) => {
          if (items.riskDetailId === historyItems.riskDetailId && items.policyStatus === 'CANCELLED') {
            const details = [];
            if (items.cancellationReasonTypeCode !== null && (items.cancellationReason !== null && items.cancellationReason.trim() !== '' && items.cancellationReason !== undefined)) {
              const isFreeFormRequired = this.LvCancellationTypeReason?.find(data => data.code === items.cancellationReasonTypeCode)?.isFreeFormRequired;
              if (isFreeFormRequired) {
                details.push(`Reason for Cancellation: ${items.cancellationReason}`);
              } else {
                details.push(`Reason for Cancellation: ${this.LvCancellationTypeReason?.find(data => data.code === items.cancellationReasonTypeCode)?.description}`);
              }
            }
            items.details = details;
          }
          return items;
        });
        break;
      case false:
        this.historyItemsData = this.historyItemsData.map((items: EndorsementHistory) => {
          if (items.riskDetailId === historyItems.riskDetailId && items.policyStatus === 'CANCELLED') {
            const details = [];
            if (historyItems.cancellationReasonTypeCode !== null && (items.cancellationReason !== null && items.cancellationReason.trim() !== '' && items.cancellationReason !== undefined)) {
              const isFreeFormRequired = this.LvCancellationTypeReason?.find(data => data.code === items.cancellationReasonTypeCode)?.isFreeFormRequired;
              if (isFreeFormRequired) {
                details.push(`Reason for Cancellation: ${items.cancellationReason}`);
              } else {
                details.push(`Reason for Cancellation: ${this.LvCancellationTypeReason?.find(data => data.code === items.cancellationReasonTypeCode)?.description}`);
              }
            } else {
              details.push('Cancellation Information not found.');
            }
            items.details = details;
          }
          return items;
        });
        break;
    }
  }
}
