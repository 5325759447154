import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router } from '@angular/router';
import { SummaryData } from '../../modules/submission-management/data/summary.data';
import { AuthService } from '../services/auth.service';

@Injectable()
export class QuoteGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private summaryData: SummaryData
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const isInternal = localStorage.getItem('userType') === 'internal';
    const fromCopy = Boolean(localStorage.getItem('fromCopy'));

    if (isInternal && !fromCopy) {
      this._router.navigate(['submissions', 'list']);
      return false;
    } else {
      this.summaryData.removePreviousStorageItems();
      return true;
    }
  }
}
