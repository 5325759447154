import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseClass } from 'app/shared/base-class';
import { TaskService } from 'app/core/services/task/task.service';
import { TaskData } from 'app/modules/dashboard/data/task.data';
import { UwDashboardConstants } from 'app/shared/constants/uw-dashboard.constants';
import Utils from 'app/shared/utilities/utils';
import { takeUntil } from 'rxjs/operators';
import { TaskLabelsConstants } from 'app/shared/constants/task.labels.constants';
import { BsModalRef, BsModalService, PageChangedEvent } from 'ngx-bootstrap';
import { TaskModalComponent } from '../task-modal/task-modal.component';
import { ITaskDTO } from 'app/shared/models/data/dto/task/task.dto';
import * as _ from 'lodash';
import { AuthService } from 'app/core/services/auth.service';
@Component({
  selector: 'app-uw-task-section',
  templateUrl: './uw-task-section.component.html',
  styleUrls: ['./uw-task-section.component.scss']
})
export class UwTaskSectionComponent extends BaseClass implements OnInit, OnDestroy {
  public uwDashboardConstants = UwDashboardConstants;
  public taskLabelsConstants = TaskLabelsConstants;
  modalRef: BsModalRef | null;
  public showRowsNumber: number = 8;

  constructor(
    public taskService: TaskService,
    public taskData: TaskData,
    private authService: AuthService,
    private modalService: BsModalService) {
      super();
    }

  ngOnInit() {
    this.taskData.getTaskList(this.taskData.getInitialTaskListPayload());
    this.taskData.getUnderwriterList();
    this.taskData.uwOnFirstLoad = true;
  }

  getTask(taskId: string): void {
    Utils.blockUI();
    this.taskService.getTaskById(taskId).pipe(takeUntil(this.stop$)).subscribe(result => {
      // Show Edit modal UI here and populate data by result;
      this.showEditTaskModal(result);
      Utils.unblockUI();
    }, (error) => {
      Utils.unblockUI();
    });
  }

  showEditTaskModal(data: ITaskDTO): void {
    const initialState = {
      title: this.taskLabelsConstants.Task.Edit.EditTaskTitle,
      isAddTaskModal: false,
      modalData: data,
      isExternal: false,
      initialTaskNotes: _.concat(data.taskNotes),
      initialTaskDocuments: _.concat(data.taskDocuments)
    };

    this.modalRef = this.modalService.show(TaskModalComponent, {
      initialState,
      class: 'modal-lg modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  changeShowRowsNumber(value): void{

    if (this.taskData.itemPerPage === value) { return; }

    this.showRowsNumber = value;
    this.taskData.itemPerPage = value;
    this.taskData.currentPage = 1;
    localStorage.setItem('currentPage',''+this.taskData.currentPage);
    localStorage.setItem('itemPerPage',''+this.taskData.itemPerPage);

    if (this.taskData.uwOnFirstLoad === true) {
      this.taskData.getTaskList(this.taskData.getInitialTaskListPayload(this.taskData.currentPage, this.taskData.itemPerPage, this.taskData.sortBy, this.taskData.orderBy));
    } else {
      const payload = this.taskData.getUWTaskFilterFormPayload(null, this.taskData.currentPage, this.taskData.itemPerPage, this.taskData.sortBy, this.taskData.orderBy);
      this.taskData.getTaskList(payload);
    }
  }

  pageChanged(event: PageChangedEvent): void {

    if(this.taskData.taskListLoading) { return; }

    this.taskData.currentPage = event.page;
    localStorage.setItem('currentPage',''+this.taskData.currentPage);
    localStorage.setItem('itemPerPage',''+this.taskData.itemPerPage);

    if (this.taskData.uwOnFirstLoad === true) {
      this.taskData.getTaskList(this.taskData.getInitialTaskListPayload(this.taskData.currentPage, this.taskData.itemPerPage, this.taskData.sortBy, this.taskData.orderBy));
    } else {
      const payload = this.taskData.getUWTaskFilterFormPayload(null, this.taskData.currentPage, this.taskData.itemPerPage, this.taskData.sortBy, this.taskData.orderBy);
      this.taskData.getTaskList(payload);
    }
  }

  ngOnDestroy() {
    this.taskData.currentPage = 1;
    this.taskData.itemPerPage = 8;
    this.taskData.totalRowCount = 500;
    this.taskData.taskListLoading = false;
    this.taskData.uwOnFirstLoad = true;
    this.taskData.agentOnFirstLoad = true;
    this.taskData.sortBy  = 'DueDate';
    this.taskData.orderBy = 'ASC';
  }

  sortBy(sortBy: string): void {

    this.taskData.sortBy = sortBy;
    if (this.taskData.orderBy === 'ASC') {
      this.taskData.orderBy = 'DESC';
    } else {
      this.taskData.orderBy = 'ASC';
    }

    if (this.taskData.uwOnFirstLoad === true) {
      this.taskData.getTaskList(this.taskData.getInitialTaskListPayload(this.taskData.currentPage, this.taskData.itemPerPage, sortBy, this.taskData.orderBy));
    } else {
      const payload = this.taskData.getUWTaskFilterFormPayload(null, this.taskData.currentPage, this.taskData.itemPerPage, sortBy, this.taskData.orderBy);
      this.taskData.getTaskList(payload);
    }
  }
}
