import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PathConstants } from 'app/shared/constants/path.constants';
import { AppAsideModule } from '@coreui/angular';
import { ModalModule } from 'ngx-bootstrap';
import { PaymentsComponent } from './payments.component';
import { SuspendedPaymentsGuard } from '../../core/guards/suspended-payments.guard';
import { AuthGuard } from 'app/core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: PaymentsComponent,
    children: [
        {
          path: PathConstants.Payments.SuspendedPayments.Index,
          canActivate: [AuthGuard, SuspendedPaymentsGuard],
          loadChildren: () => import('./suspended-payments/suspended-payments.module').then(m => m.SuspendedPaymentsModule)
        },
        {
          path: PathConstants.Payments.Refund.Index,
          loadChildren: () => import('./refund/refund.module').then(m => m.RefundModule)
        },
        {
          path: PathConstants.Payments.BatchPayments.Index,
          loadChildren: () => import('./batch-payments/batch-payments.module').then(m => m.BatchPaymentsModule)
        },
        { path: '', redirectTo: '/404', pathMatch: 'full' }, // temporarily redirecting to suspended payments
      ]
  },
  {
    path: '',
    redirectTo: PathConstants.Payments.Index,
    pathMatch: 'full'
  },
  // { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes), AppAsideModule, ModalModule.forRoot()],
  exports: [RouterModule],
  providers: []
})
export class PaymentsRoutingModule { }
