export const LA_LvCompanionAuto = [
  {
    tableName: 'LA_LvCompanionAuto',
    tableDescription: 'Companion Auto',
    code: 'NO',
    description: 'No',
    isActive: true
  },
  {
    tableName: 'LA_LvCompanionAuto',
    tableDescription: 'Companion Auto',
    code: 'HL5HK',
    description: 'Yes - $500,000 per occurrence limit or higher',
    isActive: true
  },
  {
    tableName: 'LA_LvCompanionAuto',
    tableDescription: 'Companion Auto',
    code: 'LMID',
    description: 'Yes - $300,000 to $499,000 per occurrence limit',
    isActive: true
  },
  {
    tableName: 'LA_LvCompanionAuto',
    tableDescription: 'Companion Auto',
    code: 'L3HK',
    description: 'Yes - Lower than $300,000 per occurrence limit',
    isActive: true
  }
];

export const LA_LvWaterDetection = [
  {
    tableName: 'LA_LvWaterDetection',
    tableDescription: 'WaterDetection',
    code: 'ATOFF',
    description: 'Smart Phone Water Leak Detection/Automatic Shutoff',
    formType: ['HO3','HO4','HO6'],
    isActive: true
  },
  {
    tableName: 'LA_LvWaterDetection',
    tableDescription: 'WaterDetection',
    code: 'MOF2L',
    description: 'Smart Phone Water Leak Detection/Manual Shutoff-two locations',
    formType: ['HO3','HO4','HO6'],
    isActive: true
  },
  {
    tableName: 'LA_LvWaterDetection',
    tableDescription: 'WaterDetection',
    code: 'MOFEL',
    description: 'Smart Phone Water Leak Detection/Manual Shutoff-each location',
    formType: ['HO3','HO4','HO6'],
    isActive: true
  },
  {
    tableName: 'LA_LvWaterDetection',
    tableDescription: 'WaterDetection',
    code: 'WFSEN',
    description: 'Water Flow Sensor',
    formType: ['HO3','HO4','HO6'],
    isActive: true
  },
  {
    tableName: 'LA_LvWaterDetection',
    tableDescription: 'WaterDetection',
    code: 'CENTR',
    description: 'Central Reporting',
    formType: ['DP3'],
    isActive: true
  },
  {
    tableName: 'LA_LvWaterDetection',
    tableDescription: 'WaterDetection',
    code: 'NONE',
    description: 'None',
    formType: ['HO3','HO4','HO6','DP3'],
    isActive: true
  }
];

export const LA_LvRaterOtherPerilDeductibleNHR = [
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '1',
    description: '1%',
    isActive: true,
    formType: ['HO3','HO6', 'DP3'],
    isPercent: true
  },
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '2',
    description: '2%',
    isActive: true,
    formType: ['HO3','HO6', 'DP3'],
    isPercent: true
  },
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '3',
    description: '3%',
    isActive: true,
    formType: ['HO3','HO6', 'DP3'],
    isPercent: true
  },
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '5',
    description: '5%',
    isActive: true,
    formType: ['HO3','HO6', 'DP3'],
    isPercent: true
  },
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '500',
    description: '$500',
    isActive: true,
    formType: ['HO3','HO4','HO6', 'DP3'],
    isPercent: false
  },
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '1000',
    description: '$1,000',
    isActive: true,
    formType: ['HO3','HO4','HO6','DP3'],
    isPercent: false
  },
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '2500',
    description: '$2,500',
    isActive: true,
    formType: ['HO3','HO4','HO6','DP3'],
    isPercent: false
  },
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '5000',
    description: '$5,000',
    isActive: true,
    formType: ['HO3','HO6','DP3'],
    isPercent: false
  },
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '10000',
    description: '$10,000',
    isActive: true,
    formType: ['HO3','HO6','DP3'],
    isPercent: false
  }
];

export const LA_LvRaterDeductibleHUR = [
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '1',
    description: '1%',
    isActive: true,
    formType: ['HO3','HO6','DP3'],
    isPercent: true
  },
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '2',
    description: '2%',
    isActive: true,
    formType: ['HO3','HO6','DP3'],
    isPercent: true
  },
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '3',
    description: '3%',
    isActive: true,
    formType: ['HO3','HO6','DP3'],
    isPercent: true
  },
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '5',
    description: '5%',
    isActive: true,
    formType: ['HO3','HO6','DP3'],
    isPercent: true
  },
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '500',
    description: '$500',
    isActive: true,
    formType: ['HO3','HO4','HO6','DP3'],
    isPercent: false
  },
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '1000',
    description: '$1,000',
    isActive: true,
    formType: ['HO3','HO4','HO6','DP3'],
    isPercent: false
  },
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '2500',
    description: '$2,500',
    isActive: true,
    formType: ['HO3','HO4','HO6','DP3'],
    isPercent: false
  },
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '5000',
    description: '$5,000',
    isActive: true,
    formType: ['HO3','HO6','DP3'],
    isPercent: false
  },
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '10000',
    description: '$10,000',
    isActive: true,
    formType: ['HO3','HO6','DP3'],
    isPercent: false
  },
  {
    tableName: 'LA_LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: 'E',
    description: 'Excluded',
    isActive: true,
    formType: ['HO3','HO4','HO6','DP3'],
    isPercent: false
  },
];

export const LA_LvRaterSecuredCommunity = [
  {
    tableName: 'LA_LvRaterSecuredCommunity',
    tableDescription: 'Secure Community',
    code: 'A',
    description: 'All entrances manned 24 hrs',
    formType: ['HO3','HO4','HO6','DP3'],
    isActive: true,
  },
  {
    tableName: 'LA_LvRaterSecuredCommunity',
    tableDescription: 'Secure Community',
    code: 'B',
    description: 'All entrances passkey entry',
    formType: ['HO3','HO4','HO6','DP3'],
    isActive: true
  },
  {
    tableName: 'LA_LvRaterSecuredCommunity',
    tableDescription: 'Secure Community',
    code: 'C',
    description: 'Single entry into subdivision',
    formType: ['DP3'],
    isActive: true
  },
  {
    tableName: 'LA_LvRaterSecuredCommunity',
    tableDescription: 'Secure Community',
    code: 'D',
    description: '24-hour security patrol',
    formType: ['DP3'],
    isActive: true
  },
  {
    tableName: 'LA_LvRaterSecuredCommunity',
    tableDescription: 'Secure Community',
    code: 'N',
    description: 'None applicable',
    formType: ['HO3','HO4','HO6','DP3'],
    isActive: true
  }
];

export const LA_LvRaterFireProtection = [
  {
    tableName: 'LA_LvRaterFireProtection',
    tableDescription: 'Fire Protection',
    code: '8',
    description: 'Central Fire Alarm',
    formType: ['HO3','HO4','HO6', 'DP3'],
    isActive: true
  },
  {
    tableName: 'LvRaterFireProtection',
    tableDescription: 'Fire Protection',
    code: '3',
    description: 'Fire Station Reporting',
    formType: ['DP3'],
    isActive: true
  },
  {
    tableName: 'LA_LvRaterFireProtection',
    tableDescription: 'Fire Protection',
    code: '4',
    description: 'Smoke Detectors',
    formType: ['HO3','HO4','HO6','DP3'],
    isActive: true
  },
  {
    tableName: 'LA_LvRaterFireProtection',
    tableDescription: 'Fire Protection',
    code: '5',
    description: 'Smart Battery',
    formType: ['HO3','HO4','HO6'],
    isActive: true
  },
  {
    tableName: 'LA_LvRaterFireProtection',
    tableDescription: 'Fire Protection',
    code: '6',
    description: 'Smart Phone Smoke Detector',
    formType: ['HO3','HO4','HO6'],
    isActive: true
  },
  {
    tableName: 'LA_LvRaterFireProtection',
    tableDescription: 'Fire Protection',
    code: '7',
    description: 'Sprinkler System',
    formType: ['HO3','HO4','HO6', 'DP3'],
    isActive: true
  },
  {
    tableName: 'LA_LvRaterFireProtection',
    tableDescription: 'Fire Protection',
    code: '1',
    description: 'None',
    formType: ['HO3','HO4','HO6','DP3'],
    isActive: true
  }
];

export const LA_LvRaterBurglarProtection = [
  {
    tableName: 'LA_LvRaterBurglarProtection',
    tableDescription: 'Burglar Protection',
    code: '5',
    description: 'Central Burglar Alarm',
    formType: ['HO3','HO4','HO6','DP3'],
    isActive: true
  },
  {
    tableName: 'LvRaterBurglarProtection',
    tableDescription: 'Burglar Protection',
    code: '4',
    description: 'Police Station Reporting',
    formType: ['DP3'],
    isActive: true
  },
  {
    tableName: 'LvRaterBurglarProtection',
    tableDescription: 'Burglar Protection',
    code: '3',
    description: 'Local Alarm',
    formType: ['DP3'],
    isActive: true
  },
  {
    tableName: 'LA_LvRaterBurglarProtection',
    tableDescription: 'Burglar Protection',
    code: '6',
    description: 'Local Burglar Alarm',
    formType: ['HO3','HO4','HO6'],
    isActive: true
  },
  {
    tableName: 'LA_LvRaterBurglarProtection',
    tableDescription: 'Burglar Protection',
    code: '7',
    description: 'Smart Phone Local Burglar Alarm',
    formType: ['HO3','HO4','HO6'],
    isActive: true
  },
  {
    tableName: 'LA_LvRaterBurglarProtection',
    tableDescription: 'Burglar Protection',
    code: '8',
    description: 'Smart Phone Motion Detecting Camera',
    formType: ['HO3','HO4','HO6'],
    isActive: true
  },
  {
    tableName: 'LA_LvRaterBurglarProtection',
    tableDescription: 'Burglar Protection',
    code: '1',
    description: 'None',
    formType: ['HO3','HO4','HO6','DP3'],
    isActive: true
  }
];

export const LA_LvRaterCovDPercentage = [{
  tableName: 'LvRaterCovDPercentage',
  tableDescription: 'Coverage D %',
  code: '10',
  description: '10%',
  isActive: true,
  formType: ['HO3','DP3']
},
{
  tableName: 'LvRaterCovDPercentage',
  tableDescription: 'Coverage D %',
  code: '15',
  description: '15%',
  isActive: true,
  formType: ['HO3']
},
{
  tableName: 'LvRaterCovDPercentage',
  tableDescription: 'Coverage D %',
  code: '20',
  description: '20%',
  isActive: true,
  formType: ['HO3','DP3']
},
{
  tableName: 'LvRaterCovDPercentage',
  tableDescription: 'Coverage D %',
  code: '25',
  description: '25%',
  isActive: true,
  formType: ['HO3','DP3']
},
{
  tableName: 'LvRaterCovDPercentage',
  tableDescription: 'Coverage D %',
  code: '30',
  description: '30%',
  isActive: true,
  formType: ['HO3','DP3']
}];

export const LA_LvRaterCovBPercentage = [{
  tableName: 'LA_LvRaterCovBPercentage',
  tableDescription: 'Coverage B %',
  code: '2',
  description: '2%',
  formType: ['HO3','HO4','HO6','DP3'],
  isActive: true
},
{
  tableName: 'LA_LvRaterCovBPercentage',
  tableDescription: 'Coverage B %',
  code: '10',
  description: '10%',
  formType: ['HO3','HO4','HO6','DP3'],
  isActive: true
},
{
  tableName: 'LA_LvRaterCovBPercentage',
  tableDescription: 'Coverage B %',
  code: '15',
  description: '15%',
  formType: ['HO3','HO4','HO6','DP3'],
  isActive: true
},
{
  tableName: 'LA_LvRaterCovBPercentage',
  tableDescription: 'Coverage B %',
  code: '20',
  description: '20%',
  formType: ['HO3','HO4','HO6','DP3'],
  isActive: true
},
{
  tableName: 'LA_LvRaterCovBPercentage',
  tableDescription: 'Coverage B %',
  code: '25',
  description: '25%',
  formType: ['HO3','HO4','HO6'],
  isActive: true
},
{
  tableName: 'LA_LvRaterCovBPercentage',
  tableDescription: 'Coverage B %',
  code: '30',
  description: '30%',
  formType: ['HO3','HO4','HO6'],
  isActive: true
},
{
  tableName: 'LA_LvRaterCovBPercentage',
  tableDescription: 'Coverage B %',
  code: '35',
  description: '35%',
  formType: ['HO3','HO4','HO6'],
  isActive: true
},
{
  tableName: 'LA_LvRaterCovBPercentage',
  tableDescription: 'Coverage B %',
  code: '40',
  description: '40%',
  formType: ['HO3','HO4','HO6'],
  isActive: true
},
{
  tableName: 'LA_LvRaterCovBPercentage',
  tableDescription: 'Coverage B %',
  code: '45',
  description: '45%',
  formType: ['HO3','HO4','HO6'],
  isActive: true
},
{
  tableName: 'LA_LvRaterCovBPercentage',
  tableDescription: 'Coverage B %',
  code: '50',
  description: '50%',
  formType: ['HO3','HO4','HO6'],
  isActive: true
}];

export const LA_LvRaterCovELiabilityLimit = [{
  tableName: 'LA_LvRaterCovELiabilityLimit',
  tableDescription: 'Coverage E - Liability Limit',
  code: '100000',
  description: '$100,000',
  formType: ['HO3','HO4','HO6'],
  isActive: true
},
{
  tableName: 'LA_LvRaterCovELiabilityLimit',
  tableDescription: 'Coverage E - Liability Limit',
  code: '300000',
  description: '$300,000',
  formType: ['HO3','HO4','HO6'],
  isActive: true
},
{
  tableName: 'LA_LvRaterCovELiabilityLimit',
  tableDescription: 'Coverage E - Liability Limit',
  code: '500000',
  description: '$500,000',
  formType: ['HO3','HO6'],
  isActive: true
}];


export const LA_LvRaterCovLPersonalLiabilityLimit = [
  {
    tableName: 'LA_LvRaterCovLPersonalLiabilityLimit',
    tableDescription: 'Coverage L - Personal Liability',
    code: '100000',
    description: '$100,000',
    isActive: true
  },
  {
    tableName: 'LA_LvRaterCovLPersonalLiabilityLimit',
    tableDescription: 'Coverage L - Personal Liability',
    code: '300000',
    description: '$300,000',
    isActive: true
  },
  {
    tableName: 'LA_LvRaterCovLPersonalLiabilityLimit',
    tableDescription: 'Coverage L - Personal Liability',
    code: '500000',
    description: '$500,000',
    isActive: true
  }
];

export const LA_LvRaterCovMMedicalPayments = [
  {
    tableName: 'LA_LvRaterCovMMedicalPayments',
    tableDescription: 'Coverage L - Medical Payments',
    code: '1000',
    description: '$1,000',
    isActive: true
  },
  {
    tableName: 'LA_LvRaterCovMMedicalPayments',
    tableDescription: 'Coverage L - Medical Payments',
    code: '2500',
    description: '$2,500',
    isActive: true
  },
  {
    tableName: 'LA_LvRaterCovMMedicalPayments',
    tableDescription: 'Coverage M - Medical Payments',
    code: '5000',
    description: '$5,000',
    isActive: true
  }
];

export const LA_LvRaterCovCPercentage = [{
  tableName: 'LvRaterCovCPercentage',
  tableDescription: 'Coverage C %',
  code: '25',
  description: '25%',
  isActive: true
},
{
  tableName: 'LA_LvRaterCovCPercentage',
  tableDescription: 'Coverage C %',
  code: '30',
  description: '30%',
  isActive: true
},
{
  tableName: 'LA_LvRaterCovCPercentage',
  tableDescription: 'Coverage C %',
  code: '35',
  description: '35%',
  isActive: true
},
{
  tableName: 'LA_LvRaterCovCPercentage',
  tableDescription: 'Coverage C %',
  code: '40',
  description: '40%',
  isActive: true
},
{
  tableName: 'LA_LvRaterCovCPercentage',
  tableDescription: 'Coverage C %',
  code: '45',
  description: '45%',
  isActive: true
},
{
  tableName: 'LA_LvRaterCovCPercentage',
  tableDescription: 'Coverage C %',
  code: '50',
  description: '50%',
  isActive: true
},
{
  tableName: 'LA_LvRaterCovCPercentage',
  tableDescription: 'Coverage C %',
  code: '55',
  description: '55%',
  isActive: true
},
{
  tableName: 'LA_LvRaterCovCPercentage',
  tableDescription: 'Coverage C %',
  code: '60',
  description: '60%',
  isActive: true
},
{
  tableName: 'LA_LvRaterCovCPercentage',
  tableDescription: 'Coverage C %',
  code: '65',
  description: '65%',
  isActive: true
},
{
  tableName: 'LA_LvRaterCovCPercentage',
  tableDescription: 'Coverage C %',
  code: '70',
  description: '70%',
  isActive: true
},
{
  tableName: 'LA_LvRaterCovCPercentage',
  tableDescription: 'Coverage C %',
  code: '75',
  description: '75%',
  isActive: true
},
{
  tableName: 'LA_LvRaterCovCPercentage',
  tableDescription: 'Coverage C %',
  code: '0',
  description: 'Excluded',
  isActive: true
}];