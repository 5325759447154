import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { BaseClass } from '../../../shared/base-class';
import {
  ITableTh,
  ITableTr,
  ITableFormControl,
  ITableTd,
} from '../../../shared/models/dynamic/table.interface';
import { InterestLabelsConstants } from '../../../shared/constants/interest.labels.constants';
import { EntityRiskDTO } from '../../../shared/models/data/dto/quick-quote/entity-risk.dto';
import FormUtils from 'app/shared/utilities/form.utils';
import { InterestModalModel } from 'app/shared/models/submission/interest/interest.model';
import { RiskInterestDetailDTO2 } from 'app/shared/models/data/dto/quick-quote/risk-interest-detail.dto';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { ThirdPartyDataService } from 'app/core/services/submission/third-party/third-party-data.service';
import { SummaryData } from './summary.data';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'app/core/services/auth.service';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { Subject, BehaviorSubject } from 'rxjs';
import { QuoteService } from 'app/core/services/quote.service';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';
import { PolicyService } from 'app/core/services/submission/policy.service';
import {
  updateInterestsIsLoadingFromInterestData,
  updateInterestsListFromInterestData
} from 'app/store/interests/interests.actions';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})

export class InterestData extends BaseClass {

  public isExternal = true;
  protected genericConstants = GenericConstants;
  protected _hasPriorInsurance: boolean = false;
  priorInsuranceForm: FormGroup;
  priorCarriers: any[];
  priorInsurances: any[];
  priorExpirationDateOption: IAngularMyDpOptions;
  public LabelMessageConstants = InterestLabelsConstants;
  public interestList: any[] = [];
  public previousEndorsementInterestList: any[] = [];
  public interestTypes: any[] = [];
  public descriptionOfInterestsForInsured: any[] = [];
  public ranks: any[] = [];
  public riskInterestId: string;
  public selectedPayorId: string = '';
  tableHeaders: ITableTh[] = [
    { value: this.LabelMessageConstants.interestType },
    { value: this.LabelMessageConstants.interestName },
    { value: this.LabelMessageConstants.interestName2 },
    { value: this.LabelMessageConstants.mailingAddress },
    { value: this.LabelMessageConstants.mailingAddress2 },
    { value: this.LabelMessageConstants.zipCode },
    { value: this.LabelMessageConstants.city },
    { value: this.LabelMessageConstants.state },
    { value: this.LabelMessageConstants.refLoanNumber },
    { value: this.LabelMessageConstants.rank },
    { value: this.LabelMessageConstants.descriptionOfInterest },
    { value: this.LabelMessageConstants.country },
  ];
  interestsTableRows: ITableTr[] = [];
  tableFormControls: ITableFormControl[] = [
    {
      name: 'field_1',
      type: 'string',
    },
    {
      name: 'field_2',
      type: 'string',
    },
    {
      name: 'field_3',
      type: 'string',
    },
    {
      name: 'field_4',
      type: 'string',
    },
    {
      name: 'field_5',
      type: 'string',
    },
    {
      name: 'field_6',
      type: 'string',
    },
    {
      name: 'field_7',
      type: 'string',
    },
    {
      name: 'field_8',
      type: 'string',
    },
    {
      name: 'field_9',
      type: 'string',
    },
    {
      name: 'field_10',
      type: 'string',
    },
    {
      name: 'field_11',
      type: 'string',
    },
  ];

  hasInvalid: boolean;
  modalSaveCount = 1;

  interestsSaved: Subject<any> = new Subject<any>();
  fetchInterestsComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isInterestUSCountry: boolean = false;

  constructor(protected thirdPartyDataService: ThirdPartyDataService,
    protected summaryData: SummaryData,
    protected authService: AuthService,
    protected quoteService: QuoteService,
    public policyService: PolicyService,
    protected store: Store
  ) {
    super();
  }

  initForms() {
    this.priorInsuranceForm = this.priorInsuranceSection();

    this.dropdownValues();
    this.dateOptions();
    this.watchUserType();
  }

  watchUserType(): void {
    this.authService.userType.subscribe((userType) => {
      this.isExternal = userType === this.genericConstants.userType.external;
      if (localStorage.getItem('isPolicy') === 'true' &&
        localStorage.getItem('isEditPolicy') === 'true') {
        const status = this.isExternal ? false : true;
        this.setInterestPageFormStatus(status);
      }
    });
  }

  priorInsuranceSection() {
    return new FormGroup({
      priorInsurance: new FormControl('PI1'),
      priorCarrier: new FormControl('', Validators.required),
      priorExpirationDate: new FormControl('', Validators.required)
    });
  }

  dropdownValues() {
    this.priorCarriers = [
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC1',
        description: 'Allstate Floridian Indemnity Corp',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC2',
        description: 'Allstate Floridian Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC3',
        description: 'Allstate Indemnity Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC4',
        description: 'Allstate Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC5',
        description: 'American Home Assurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC6',
        description: 'American International South Ins Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC7',
        description: 'American National Property and Casualty',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC8',
        description: 'American Strategic Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC9',
        description: 'Amica Mutual Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC10',
        description: 'Atlantic Mutual Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC11',
        description: 'Auto Owners Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC12',
        description: 'Automobile Insurance Co of Hartford CT',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC13',
        description: 'Catawba Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC14',
        description: 'Centauri Insurance',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC15',
        description: 'Citizens Insurance',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC16',
        description: 'Clarendon National Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC17',
        description: 'Cypress Property and Casualty Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC18',
        description: 'Edison Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC19',
        description: 'Federal Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC20',
        description: 'Federated National Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC21',
        description: 'Firemans Fund Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC22',
        description: 'First Floridian Auto and Home Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC23',
        description: 'Florida Family Insurance',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC24',
        description: 'Florida Farm Bureau Casualty Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC25',
        description: 'Florida Peninsula',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC26',
        description: 'Florida Preferred Property Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC27',
        description: 'Florida Select Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC28',
        description: 'Foremost Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC29',
        description: 'Frontline Insurance',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC30',
        description: 'Gulfstream Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC31',
        description: 'Hartford Insurance Co of the Midwest',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC32',
        description: 'Heritage Property and Casualty Insurance',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC33',
        description: 'Homeowners Choice Insurance',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC34',
        description: 'Lexington Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC35',
        description: 'Liberty Mutual Fire Insurance',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC36',
        description: 'Mobile USA Insurance Co Inc',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC37',
        description: 'Nationwide Insurance Co of Florida',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC38',
        description: 'Olympus Insurance',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC39',
        description: 'Omega Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC40',
        description: 'Other',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC41',
        description: 'Royal Palm Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC42',
        description: 'Safe Point',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC43',
        description: 'St. Johns Insurance Co Inc',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC44',
        description: 'State Farm Florida Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC45',
        description: 'State National Insurance',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC46',
        description: 'Sunshine State Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC47',
        description: 'Tower Hill Preferred Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC48',
        description: 'Tower Hill Prime Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC49',
        description: 'United Property and Casualty Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC50',
        description: 'United Services Auto Association',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC51',
        description: 'Universal Property and Casualty Insurance',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC52',
        description: 'UPC Insurance',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC53',
        description: 'USAA Casualty Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC54',
        description: 'USF&G Specialty Insurance Co',
        isActive: true
      },
      {
        tableName: 'LvPriorInsuranceCarrier',
        tableDescription: 'Prior Insurance Carrier',
        code: 'PIC55',
        description: 'Vanguard Fire and Casualty Co',
        isActive: true
      }
    ];

    this.priorInsurances = [
      {
        tableName: 'LvPriorInsurance',
        tableDescription: 'Prior Insurance',
        code: 'PI0',
        description: 'Yes',
        isActive: true,
        formType: ['HO3', 'HO4', 'HO6', 'DP3']
      },
      {
        tableName: 'LvPriorInsurance',
        tableDescription: 'Prior Insurance',
        code: 'PI1',
        description: 'No',
        isActive: true,
        formType: ['HO3', 'HO4', 'HO6', 'DP3']
      },
      {
        tableName: 'LvPriorInsurance',
        tableDescription: 'Prior Insurance',
        code: 'PI2',
        description: 'First Time Owner',
        isActive: true,
        formType: ['HO3', 'HO6', 'DP3']
      },
      {
        tableName: 'LvPriorInsurance',
        tableDescription: 'Prior Insurance',
        code: 'PI3',
        description: 'First Time Renter',
        isActive: true,
        formType: ['HO4']
      },
      {
        tableName: 'LvPriorInsurance',
        tableDescription: 'Prior Insurance',
        code: 'PI4',
        description: 'New Purchase',
        isActive: true,
        formType: ['HO3', 'HO6', 'DP3']
      }
    ];

    this.interestTypes = [
      {
        tableName: 'LvInterests',
        tableDescription: 'Interests type',
        code: 'Mort',
        description: 'Mortgagee',
        isActive: true
      },
      {
        tableName: 'LvInterests',
        tableDescription: 'Interests type',
        code: 'AddIns',
        description: 'Additional Insured',
        isActive: true
      },
      {
        tableName: 'LvInterests',
        tableDescription: 'Interests type',
        code: 'AddInt',
        description: 'Additional Interest',
        isActive: true
      },
      {
        tableName: 'LvInterests',
        tableDescription: 'Interests type',
        code: 'AltPyr',
        description: 'Alternate Payor',
        isActive: true
      }
    ];

    this.ranks = [
      {
        tableName: 'LvRank',
        tableDescription: 'Ranks',
        code: '1stMort',
        description: '1st Mortgagee',
        isActive: true
      },
      {
        tableName: 'LvRank',
        tableDescription: 'Ranks',
        code: '2ndMort',
        description: '2nd Mortgagee',
        isActive: true
      },
      {
        tableName: 'LvRank',
        tableDescription: 'Ranks',
        code: '3rdMort',
        description: '3rd Mortgagee',
        isActive: true
      }
    ];

    this.descriptionOfInterestsForInsured = [
      {
        tableName: 'LvDescriptionOfInterest',
        tableDescription: 'Description of Interests',
        code: 'DI0',
        description: 'Co-Owner/Occupant',
        isActive: true
      },
      {
        tableName: 'LvDescriptionOfInterest',
        tableDescription: 'Description of Interests',
        code: 'DI1',
        description: 'Co-Owner/Non-Occupant',
        isActive: true
      },
      {
        tableName: 'LvDescriptionOfInterest',
        tableDescription: 'Description of Interests',
        code: 'DI2',
        description: 'Limited Liability Corporation (LLC)',
        isActive: true
      },
      {
        tableName: 'LvDescriptionOfInterest',
        tableDescription: 'Description of Interests',
        code: 'DI3',
        description: 'Family Trust',
        isActive: true
      }
    ];

  }

  dateOptions() {
    this.priorExpirationDateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy'
    };
  }

  public populateInterest(data: EntityRiskDTO) {

    const riskInterests = data?.risks[0]?.riskDetails[0]?.riskInterests;
    this.priorInsuranceForm.get('priorInsurance').setValue(riskInterests[0]?.priorInsuranceCode ?? 'PI1');

    if (riskInterests[0]?.priorInsuranceCode === InterestLabelsConstants.priorCarrierList.PI0) {
      this._hasPriorInsurance = true;
      this.priorInsuranceForm.get('priorCarrier').setValue(riskInterests[0]?.priorCarrierCode);
      this.priorInsuranceForm.get('priorExpirationDate').setValue(riskInterests[0]?.priorExpirationDate ? { isRange: false, singleDate: { jsDate: new Date(riskInterests[0]?.priorExpirationDate) } } : null);
    } else {
      this._hasPriorInsurance = false;
    }
    this.riskInterestId = riskInterests[0]?.id || null;
    this.interestList = [];
    this.interestsTableRows = [];
    if (localStorage.getItem('pageType') !== 'policies') {
      this.getInterestsFromApi();
    } else {
      this.getInterestDetails();
    }
  }

  public getInterestDetails(): void {
    const riskId = this.summaryData.SummaryForm.get('riskId').value;
    const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;
    this.fetchInterestsComplete.next(false);
    this.store.dispatch(updateInterestsIsLoadingFromInterestData({ isLoading: true }));

    if (riskId) {
      this.policyService.getInterestsDetails(riskId, riskDetailId).pipe(takeUntil(this.stop$)).subscribe(result => {
        this.selectedPayorId = '';
        if (result[0] != null) {
          this.mapInterests(result);
        }
        this.fetchInterestsComplete.next(true);

        const interests = _.orderBy(result, ['id'], ['asc']);

        this.store.dispatch(updateInterestsListFromInterestData({ list: interests }));
        this.store.dispatch(updateInterestsIsLoadingFromInterestData({ isLoading: false }));
      }, error => {
        this.fetchInterestsComplete.next(true);
        NotifUtils.showError(JSON.parse(error.error).errors.toString());
        this.store.dispatch(updateInterestsIsLoadingFromInterestData({ isLoading: false }));
      });

      this.policyService.getPreviousEndorsementInterestsDetails(riskId, riskDetailId).pipe(takeUntil(this.stop$)).subscribe(result => {
        this.previousEndorsementInterestList = [];
        if (result[0] != null) {
          this.mapInterests(result, true);
        }
        this.fetchInterestsComplete.next(true);
      }, error => {
        this.fetchInterestsComplete.next(true);
        NotifUtils.showError(JSON.parse(error.error).errors.toString());
      });
    }
  }

  getInterestsFromApi(): void {
    const riskId = this.summaryData.SummaryForm.get('riskId').value;
    const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;
    this.fetchInterestsComplete.next(false);
    this.store.dispatch(updateInterestsIsLoadingFromInterestData({ isLoading: true }));

    if (riskId) {
      this.thirdPartyDataService.getRiskInterests(riskId, riskDetailId).pipe(takeUntil(this.stop$)).subscribe(result => {
        this.selectedPayorId = '';
        if (result[0] != null) {
          this.mapInterests(result[0].riskInterestDetails);
        }

        this.fetchInterestsComplete.next(true);

        const interests = _.orderBy(result[0].riskInterestDetails, ['id'], ['asc']);

        this.store.dispatch(updateInterestsListFromInterestData({ list: interests }));
        this.store.dispatch(updateInterestsIsLoadingFromInterestData({ isLoading: false }));
      }, error => {
        this.fetchInterestsComplete.next(true);
        NotifUtils.showError(JSON.parse(error.error).errors.toString());
        this.store.dispatch(updateInterestsIsLoadingFromInterestData({ isLoading: false }));
      });
    }
  }

  mapInterests(riskInterestDetails: RiskInterestDetailDTO2[], isForChangePaymentPlan: boolean = false): void {
    if (riskInterestDetails) {
      riskInterestDetails.forEach(riskInterestDetail => {
        if (riskInterestDetail.isActive) {
          if (riskInterestDetail.isSelectedPayor) {
            this.selectedPayorId = riskInterestDetail.id;
          }
          const interest: InterestModalModel = {
            id: riskInterestDetail.id,
            interestType: riskInterestDetail.interestTypeCode,
            interestName: riskInterestDetail.interestName,
            interestName2: riskInterestDetail.interestName2,
            interestLastName: riskInterestDetail.interestLastName,
            interestLastName2: riskInterestDetail.interestLastName2,
            interestNameSuffix: riskInterestDetail.interestNameSuffix,
            interestNameSuffix2: riskInterestDetail.interestNameSuffix2,
            mailingAddress: riskInterestDetail.mailingAddress,
            mailingAddress2: riskInterestDetail.mailingAddress2,
            zipCode: riskInterestDetail.zipCode?.toString(),
            city: riskInterestDetail.city,
            state: riskInterestDetail.state,
            refLoanNumber: riskInterestDetail.loanNumber,
            rank: riskInterestDetail.rankCode?.toString(),
            descriptionOfInterest: riskInterestDetail.descOfInterestCode?.toString(),
            isIndividual: riskInterestDetail.isIndividual,
            createdDate: riskInterestDetail.createdDate,
            isFromParent: riskInterestDetail.isFromParent,
            isAdd: false,
            countryCode: riskInterestDetail.countryCode,
            interestFullName:
              !riskInterestDetail.isIndividual ?
              (riskInterestDetail.interestName + ' ' +
              (riskInterestDetail.interestName2 ? riskInterestDetail.interestName2 : '')).trim() +
              ' (' + this.interestTypes.find(x => x.code === riskInterestDetail.interestTypeCode).description + ')' :
              (riskInterestDetail.interestName + ' ' +
              riskInterestDetail.interestLastName + ' ' +
              (riskInterestDetail.interestNameSuffix ? riskInterestDetail.interestNameSuffix : '') + ' ' +
              (riskInterestDetail.interestName2 ? riskInterestDetail.interestName2 : '') + ' ' +
              (riskInterestDetail.interestLastName2 ? riskInterestDetail.interestLastName2 : '') + ' ' +
              (riskInterestDetail.interestNameSuffix2 ? riskInterestDetail.interestNameSuffix2 : '')).trim() +
              ' (' + this.interestTypes.find(x => x.code === riskInterestDetail.interestTypeCode).description + ')'
          };
          if (isForChangePaymentPlan) {
            this.previousEndorsementInterestList.push(interest);
          } else {
            const row = this.addItem(interest);
            this.addItemToTable(row, riskInterestDetail);
            this.interestList.push(interest);
          }
        }
      });
    }
  }

  mapInterestsAfterSave(riskInterestDetails: RiskInterestDetailDTO2[]): void {
    this.interestList = [];
    if (riskInterestDetails) {
      riskInterestDetails.forEach(riskInterestDetail => {
        const interest: InterestModalModel = {
          id: riskInterestDetail.id,
          interestType: riskInterestDetail.interestTypeCode,
          interestName: riskInterestDetail.interestName,
          interestName2: riskInterestDetail.interestName2,
          mailingAddress: riskInterestDetail.mailingAddress,
          mailingAddress2: riskInterestDetail.mailingAddress2,
          zipCode: riskInterestDetail.zipCode?.toString(),
          city: riskInterestDetail.city,
          state: riskInterestDetail.state,
          refLoanNumber: riskInterestDetail.loanNumber,
          rank: riskInterestDetail.rankCode?.toString(),
          descriptionOfInterest: riskInterestDetail.descOfInterestCode?.toString(),
          createdDate: riskInterestDetail.createdDate,
          isAdd: false,
          countryCode: riskInterestDetail.countryCode
        };
        this.interestList.push(interest);
      });
      this.interestsSaved.next(this.interestList);
    }
  }

  addItem(newItem: any): ITableTd[] {
    const tr: ITableTd[] = [];
    const fields: string[] = [
      'interestType',
      'interestName',
      'interestName2',
      'mailingAddress',
      'mailingAddress2',
      'zipCode',
      'city',
      'state',
      'refLoanNumber',
      'rank',
      'descriptionOfInterest',
      'countryCode'
    ];

    //   /** Temporary Commented Out Code.
    //   newItem.refLoanNumber = Math.floor(
    //     Math.random() * 20000000000 + 10000000000
    //   );
    // */

    fields.forEach((item, index) => {
      let display: any;

      switch (item) {
        case InterestLabelsConstants.interestTypeColumn:
          const interestTypeId = Boolean(newItem[item]) ? newItem[item] : '';
          const interestType = this.interestTypes.filter(res => res.code === interestTypeId);
          display = interestType[0].description;
          break;
        case InterestLabelsConstants.rankColumn:
          const rankId = Boolean(newItem[item]) ? newItem[item] : '';
          const rank = this.ranks.filter(res => res.code === rankId);
          display = rank.length > 0 ? rank[0].description : '';
          break;
        case InterestLabelsConstants.descriptionOfInterestColumn:
          const descId = Boolean(newItem[item]) ? newItem[item] : '';
          const desc = this.descriptionOfInterestsForInsured.filter(res => res.code === descId);
          display = desc.length > 0 ? desc[0].description : '';
          break;
        case InterestLabelsConstants.interestNameColumn:
          if (newItem.isIndividual){
            display = (`${newItem.interestName} ${newItem.interestLastName} ${newItem.interestNameSuffix ?? ''}`).trim();
          } else{
            display = newItem.interestName;
          }
          break;
        case InterestLabelsConstants.interestName2Column:
          if (newItem.isIndividual){
            display = (`${newItem.interestName2 ?? ''} ${newItem.interestLastName2 ?? ''} ${newItem.interestNameSuffix2 ?? ''}`).trim();
          } else{
            display = newItem.interestName2 ?? '';
          }
          break;
        default:
          display = Boolean(newItem[item]) ? String(newItem[item]) : '';
          break;
      }

      tr.push({
        id: newItem.id,
        value: Boolean(newItem[item]) ? newItem[item] : '',
        display: display
      });
    });

    return tr;
  }

  addItemToTable(tr: ITableTd[], newItem: any): void {
    this.interestsTableRows.push({
      id: newItem?.id ?? newItem.newItemId,
      tr: tr,
      item: newItem
    });
  }

  public get hasPriorInsurance(): boolean {
    return this._hasPriorInsurance;
  }
  public set hasPriorInsurance(value: boolean) {
    this._hasPriorInsurance = value;
  }

  setInterestPageFormStatus(status: boolean): void {
    status = !this.isExternal && status;
    FormUtils.setFormGroupStatus(this.priorInsuranceForm, status);
  }

  public markInterestPageAsTouch(): void {
    FormUtils.markFormgroupAsTouch(this.priorInsuranceForm);
  }

  getPriorInsuranceByFormType(formType: string): any[] {
    return this.priorInsurances.filter(a => a.formType.includes(formType));
  }
}
