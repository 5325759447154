import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuickQuoteComponent } from './quick-quote.component';
import { PathConstants } from '../../../shared/constants/path.constants';
import { ApplicantPageComponent } from './applicant-page/applicant-page.component';
import { CanDeactivateApplicantPageComponentGuard } from './applicant-page/applicant-page-navigation-guard.service';


const routes: Routes = [
  {
    path: '',
    component: QuickQuoteComponent,
    children: [
      {
        path: PathConstants.QuickQuote.QuickQuote.ApplicantPage,
        canDeactivate: [CanDeactivateApplicantPageComponentGuard],
        component: ApplicantPageComponent
      },
      { path: '', redirectTo: PathConstants.QuickQuote.QuickQuote.ApplicantPage, pathMatch: 'full' },
      // { path: '**', redirectTo: '/404', pathMatch: 'full' },
    ]
  },
  // { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CanDeactivateApplicantPageComponentGuard]
})
export class QuickQuoteRoutingModule { }
