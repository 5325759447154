import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'app/core/services/layout/layout.service';
import { createBatchPaymentsMenuItems } from './batch-payments-navitems';

@Component({
  templateUrl: './batch-payments.component.html',
  styleUrls: ['./batch-payments.component.scss']
})
export class BatchPaymentsComponent implements OnInit {

  constructor(
    private layoutService: LayoutService
  ) { }

  ngOnInit() {
    this.layoutService.updateMenu(createBatchPaymentsMenuItems());
  }

}
