
export interface ChangesState {
  isLoading: boolean | null;
  list: string[] | null;
  policyChangeUser: any | null;
  effectiveDateUser: any | null;
};

export const initialState = {
  isLoading: null,
  list: null,
  policyChangeUser: null,
  effectiveDateUser: null,
};