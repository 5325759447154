import { Pipe, PipeTransform } from '@angular/core';
import { ButtonLabelConstants, mode, TransferModeConstants } from '../../../shared/constants/book-transfer.constants';

@Pipe({
  name: 'submitButtonLabel'
})
export class SubmitButtonLabelPipe implements PipeTransform {
  transferModeConstants = TransferModeConstants;
  buttonLabelConstants = ButtonLabelConstants;

  transform(operationMode: mode): string {
    return operationMode === this.transferModeConstants.new ? this.buttonLabelConstants.new : this.buttonLabelConstants.edit;
  }
}
