import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AgencyService } from 'app/core/services/management/agency-service';
import { ReportsService } from 'app/core/services/reports/reports.service';
import Utils from '../../../shared/utilities/utils';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { YearConstant } from 'app/shared/constants/year.constants';
import { getYearRange } from 'app/shared/helpers/year-range-helper';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { ReportType,ReportOptions,AgencyTypeOptions } from 'app/shared/constants/report.options.constants';
import { LvAgencyNetwork } from 'app/shared/constants/agency.options.constants';
import { AuthService } from 'app/core/services/auth.service';
import { PolicyService } from 'app/core/services/submission/policy.service';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from 'app/shared/base-class';

@Component({
  selector: 'app-reports-header',
  templateUrl: './reports-header.component.html',
  styleUrls: ['./reports-header.component.scss']
})
export class ReportsHeaderComponent extends BaseClass implements OnInit {
  @ViewChild('agencyForm') agencyForm: ElementRef;
  @ViewChild('reportType') reportType: ElementRef;
  @ViewChild('report') report: ElementRef;
  @ViewChild('network') network: ElementRef;

  @ViewChild('arrow') arrow: ElementRef;
  @ViewChild('reportTypearrow') reportTypearrow: ElementRef;
  @ViewChild('reportarrow') reportarrow: ElementRef;
  @ViewChild('networkarrow') networkarrow: ElementRef;
  
  month: any = YearConstant[0].key;
  year: number = new Date().getFullYear();
  LvMonth: any = YearConstant;
  isExternal: boolean;
  noResult: boolean = false;
  reportTypeNoResult: boolean = false;
  reportNoresult: boolean = false;
  networkNoresult:boolean = false;
  reportTypeList: Array<any> = new Array<any>();
  reportOptionList:Array<any> = new Array<any>();
  networkList: Array<any> = new Array<any>();

  agencyListLoading: boolean = true;
  reportTypeLoading: boolean = true;
  networkLevel = ReportType[1].name;
  companyLevel = ReportType[0].name;

  agencyFormGroup: FormGroup = new FormGroup({
    reportType: new FormControl('', [Validators.required]),
    report: new FormControl('', [Validators.required]),
    network: new FormControl('', [Validators.required]),
    agencyForm: new FormControl('', [Validators.required]),
    yearForm: new FormControl('', [Validators.required]),
    monthForm: new FormControl('', [Validators.required]),
  });

  agencyList = []

  agencyReportType: any;
  selectedReportType: any;
  selectedReportOption: any;
  selectedNetwork: any;
  allAgencies = 'All Agencies'
  agency: any = "";
  LvYear: number[] = getYearRange();
  isExternalUser: boolean = false;
  isInternalUser: boolean = false;

  constructor(private reportService: ReportsService,
    private agencyService: AgencyService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private policyService: PolicyService
    ) { super(); }

  ngOnInit() {
    this.initializations();
  }


  //#region Form Validations 
  setNetworkValidator(){
    this.enableReportField();
    this.clearAgencyFormValidations();
    this.setNetworkValidations();
  }
  setNetworkValidations(){
    this.agencyFormGroup.get('network').setValidators([Validators.required]);
    this.agencyFormGroup.get('network').updateValueAndValidity();
  }
  
  clearNetworkValidations(){
    this.agencyFormGroup.get('network').clearValidators();
    this.agencyFormGroup.get('network').updateValueAndValidity();
  }

  setAgencyFormValidations(){
    this.agencyFormGroup.get('agencyForm').setValidators([Validators.required]);
    this.agencyFormGroup.get('agencyForm').updateValueAndValidity();
  }
  
  clearAgencyFormValidations(){
    this.agencyFormGroup.get('agencyForm').clearValidators();
    this.agencyFormGroup.get('agencyForm').updateValueAndValidity();
  }
  clearReportTypeValidations(){
    this.agencyFormGroup.get('reportType').clearValidators();
    this.agencyFormGroup.get('reportType').updateValueAndValidity();
  }
  clearReportValidations(){
    this.agencyFormGroup.get('report').clearValidators();
    this.agencyFormGroup.get('report').updateValueAndValidity();
  }

  setCompanyValidator(){
    this.clearAgencyFormValidations();
    this.clearNetworkValidations();
    this.enableReportField();
  }

  disabledReportField(){
    this.agencyFormGroup.controls['report'].disable();
  }
  
  setEmptyValueNetworkField(){
    this.agencyFormGroup.controls['network'].setValue("");
  }
  
  setEmptyValueReportField(){
    this.agencyFormGroup.controls['report'].setValue("");
  }

  setEmptyValueAgencyFormField(){
    this.agencyFormGroup.controls['agencyForm'].setValue("");
  }
  
  
  enableReportField(){
    this.agencyFormGroup.controls['report'].enable();
  }

  //#endregion
  
  //#region Form Bindings
  toggleDropdown(type): void {
    if(type == "agencyForm"){
      this.agencyFormGroup.get('agencyForm').parent.controls['agencyForm'].touched
      this.agencyForm.nativeElement.focus();
    }

    if(type == "reportType"){
      this.agencyFormGroup.get('reportType').parent.controls['reportType'].touched
      this.reportType.nativeElement.focus();
    }
     if(type == "report"){
      this.agencyFormGroup.get('report').parent.controls['report'].touched
      this.report.nativeElement.focus();
    }
    if(type == "network"){
      this.agencyFormGroup.get('network').parent.controls['network'].touched
      this.network.nativeElement.focus();
    }
  }

  typeaheadNoResults(event: boolean, type: string): void {
    if(type == "agencyForm"){
      this.noResult = event;
    }
    if(type == "reportType"){
      this.reportTypeNoResult = event;
    }
    if(type == "report"){
      this.reportNoresult = event;
    }
    if(type == "network"){
      this.networkNoresult = event;
    }
  }

  
  setFormValues(){
    this.agencyFormGroup.controls['yearForm'].setValue(this.year);
    this.agencyFormGroup.controls['monthForm'].setValue(this.month);
  }

  setMonth() {
    this.month = this.agencyFormGroup.controls['monthForm'].value;
  }

  setReporType(){
    this.selectedReportType = this.agencyFormGroup.controls['reportType'].value;
    if(this.selectedReportType == this.networkLevel){
      this.setNetworkValidator();
      this.getReportOptionList();
      this.clearAgencyFormValidations();
      this.setEmptyValueAgencyFormField();
    }else if(this.selectedReportType == this.companyLevel){
      this.setCompanyValidator();
      this.getReportOptionList();
      this.setEmptyValueNetworkField();
    }else{
        this.disabledReportField();
    }
  }

  setReport(){
    this.selectedReportOption = this.agencyFormGroup.controls['report'].value;
    if(this.selectedReportOption == 'Agency'){
      this.setAgencyFormValidations();
    }else{
      this.clearAgencyFormValidations();
    }
  }

  setNetwork(){
    this.selectedNetwork = this.agencyFormGroup.controls['network'].value;
  }

  setYear() {
    this.year = this.agencyFormGroup.controls['yearForm'].value;
  }

   
  onSelected(event: TypeaheadMatch, type: string){
    const key = event.item?.key;
  
    if(key != null && type == 'agency'){
        this.agency = key;
    }
  }

  
  viewReport(){
    if(this.isExternalUser){
      Utils.blockUI();
      this.agencyReportType = AgencyTypeOptions[0].value;
      this.agency = this.authService.agencyId;
      this.getAgencySnapshotDocByFilter(); 
    }
    if(this.isInternalUser){
      let isValidParameter = this.validateParameters();
      if(isValidParameter){
        this.getAgencyReportType();
        Utils.blockUI();
        this.getAgencySnapshotDocByFilter(); 
      }
    }

}
 
  //#endregion

  //#region Helper Functions

  validateParameters(): boolean {
    let isValidParam = true;
    let errorMessage = "";
    let selectedReportType = this.agencyFormGroup.controls['reportType'].value;
    let report = this.agencyFormGroup.controls['report'].value;
    let network = this.agencyFormGroup.controls['network'].value;
    let agencyForm = this.agencyFormGroup.controls['agencyForm'].value;

    if(selectedReportType == this.networkLevel){
         let reportOption = this.reportOptionList.find(x => x.value == report);
         if(reportOption == undefined){
          errorMessage += `"${report}" doesn't exist.`
          NotifUtils.showError(errorMessage);
          isValidParam = false;
          return isValidParam;
         }
         let networkOption = this.networkList.find(x => x.key == network);
         if(networkOption == undefined){
          errorMessage += `"${network}" doesn't exist.`
          NotifUtils.showError(errorMessage);
          isValidParam = false;
          return isValidParam;
         }

     }else if(selectedReportType == this.companyLevel){
      let reportOption = this.reportOptionList.find(x => x.value == report);
      if(reportOption == undefined){
       errorMessage += `"${report}" doesn't exist.`
       NotifUtils.showError(errorMessage);
       isValidParam = false;
       return isValidParam;
      }
      if(reportOption != undefined && reportOption.value == "Agency"){
        let agency = this.agencyList.find(x => x.value == agencyForm);
        if(agency == undefined){
          errorMessage += `"${agencyForm}" doesn't exist.`
          NotifUtils.showError(errorMessage);
          isValidParam = false;
          return isValidParam;
         }
      }
       
     }else{
      isValidParam = false;
      errorMessage += `"${selectedReportType}" doesn't exist.`
      NotifUtils.showError(errorMessage);
      return isValidParam;
     }
    
    return isValidParam;
  }

  initializations(){
    this.setFormValues();
    this.initReportTypes();
     this.getAgencies();
     this.disabledReportField();
     this.getNetworkList();
     this.isExternalUser = this.authService.isExternalUser;
     this.isInternalUser = this.authService.isInternalUser;
     this.removeOtherFormValidationsForAgent();
    }

    removeOtherFormValidationsForAgent(){
      if(this.isExternalUser){
         this.clearNetworkValidations();
         this.clearAgencyFormValidations();
         this.clearReportTypeValidations();
         this.clearReportValidations();
      }
    }
  
    getAgencyReportType(){
      if(this.selectedReportType == this.networkLevel){
       let agencyTypeOption = AgencyTypeOptions.find(x => x.key == `${this.selectedReportOption} & ${this.selectedNetwork}`);
       if(agencyTypeOption != undefined){
         this.agencyReportType = agencyTypeOption.value;
       }
      }else if(this.selectedReportType == this.companyLevel){
        let agencyTypeOption = AgencyTypeOptions.find(x => x.key == this.selectedReportOption);
        if(agencyTypeOption != undefined){
          this.agencyReportType = agencyTypeOption.value;
        }
      }
    }


  initReportTypes(){
    ReportType.map(result => {
      this.reportTypeList.push({
        key: result.name,
        value: result.name 
      })
    })
    this.reportTypeLoading = false;
  }
  sortAgency(agencyList){
    agencyList.sort((a, b) => a.entity.companyName.localeCompare(b.entity.companyName))
    return agencyList;
  }

  detectChanges(){
    this.cdr.detectChanges();
   }

   getReportOptionList(){
     this.reportOptionList = [];
    if(this.selectedReportType == this.networkLevel){

     let allAgencies = "All Agencies";
     let allActiveAgencies = "All Active Agencies";
     

      ReportOptions.map(result => {
          if(result.name == allAgencies || result.name == allActiveAgencies){
            this.reportOptionList.push({
              key: result.name,
              value: result.name 
            });
          }
      })

        this.selectedReportOption = this.reportOptionList[0].key;
        this.agencyFormGroup.controls['report'].setValue(this.selectedReportOption);
    }else if(this.selectedReportType == this.companyLevel){
      ReportOptions.map(result => {
        this.reportOptionList.push({
          key: result.name,
           value: result.name 
         }); 
      });
      this.selectedReportOption = this.reportOptionList[0].key;
      this.agencyFormGroup.controls['report'].setValue(this.selectedReportOption);
    }
   }

   getNetworkList(){
    LvAgencyNetwork.map(x => {
      if(x.code == "KRAFT" || x.code == "SIM" || x.code == "SCIG"){
        this.networkList.push({
          key: x.code,
          value: x.description
        })
      }
    })
    this.detectChanges();
   }
  //#endregion
  
  //#region API Trigger
  getAgencySnapshotDocByFilter(){
    this.reportService.getAgencySnapshotDocByFilter(this.agency,this.month,this.year,this.agencyReportType).subscribe(result => {
     Utils.unblockUI();
     if(result == null){
      NotifUtils.showError("Report is not available.");
     }else{
      this.policyService.generateSASURL(result.filePath).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
        window.open(resultSASURL, "AgencyReport");
      });
     }
    }, error => {
      NotifUtils.showError(error);
    })
  }

    

  getAgencies(){
    this.agencyService.getAllAgencies().subscribe(result => {
     result = this.sortAgency(result);
     result.map(x => {
      this.agencyList.push({
        key: x.id,
        value: `${x.agencyCode} - ${x.entity.companyName}` 
      })

     });
     this.agencyListLoading = false;
     this.detectChanges();
    }, error => {
      NotifUtils.showError(error);
    })
  }
  //#endregion
 

}
