/* eslint-disable no-shadow */
/* eslint-disable no-restricted-imports */
import { EventEmitter, Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseClass } from '../../../shared/base-class';
import { PolicyBillingLabelsConstants } from '../../../../app/shared/constants/policy-billing.labels.constants';
import { DocumentUpload } from '../../../../app/shared/models/policy/document-upload.model';
import { PaymentMethod } from '../../../../app/shared/enum/payment-method.enum';
import { BillingService } from '../../../../app/core/services/billing/billing.service';
import { BillingSummaryDTO } from '../../../../app/shared/models/data/dto/billing/billing-summary.dto';
import { EntityRiskDTO } from '../../../../app/shared/models/data/dto/quick-quote/entity-risk.dto';
import { InstallmentInvoiceDTO } from '../../../../app/shared/models/data/dto/billing/installment-invoice.dto';
import { PaymentViewDTO } from '../../../../app/shared/models/data/dto/billing/payment-view.dto';
import { PagerService } from '../../../../app/core/services/pagerService';
import { takeUntil } from 'rxjs/operators';
import { LvAccountType, LvCreditCardType, LvPaymentMethod, LvPayPlanOptions } from '../../../../app/shared/constants/billing.options.constants';
import JsUtils from '../../../../app/shared/utilities/js.utils';
import { BillingMapper } from '../../../../app/shared/models/policy/billing-mapper.model';
import FormUtils from '../../../../app/shared/utilities/form.utils';
import { CreditCardType } from '../../../../app/shared/enum/credit-card-type-enum';
import { TransactionFeeDTO } from '../../../../app/shared/models/data/dto/billing/transaction-fee.dto';
import Utils from '../../../shared/utilities/utils';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { PaymentProfileRequest } from '../../../shared/models/data/dto/billing/payment-profile-request.dto';
import { PaymentProfileResponse } from '../../../shared/models/data/dto/billing/payment-profile-response.dto';
import { PaymentAccountRequest } from '../../../shared/models/data/dto/billing/payment-account-request.dto';
import { PaymentAccountResponse } from '../../../shared/models/data/dto/billing/payment-account-response.dto';
import { Subject } from 'rxjs/Rx';
import { RegexConstants } from '../../../shared/constants/regex.constants';
import { MakePaymentDTO } from '../../../../app/shared/models/data/dto/billing/make-payment.dto';
import { SummaryData } from '../../../../app/modules/submission-management/data/summary.data';
import { GeneralValidationService } from '../../../../app/core/services/submission/validations/general-validation.service';
import { ErrorMessageConstant } from '../../../../app/shared/constants/error-message.constants';
import { ToastrService } from 'ngx-toastr';
import { EntityRiskData } from '../../../../app/modules/submission-management/data/entity-risk.data';
import { ThirdPartyDataService } from '../../../../app/core/services/submission/third-party/third-party-data.service';
import { merge, Subscription } from 'rxjs';
import { CalculateRaterData } from '../../../core/services/submission/rater-premium/calculate-rater-data.service';
import { AuthService } from '../../../core/services/auth.service';
import { CustomValidators } from '../../../shared/validators/custom.validator';
import { RenewalDetailsRequestDTO } from '../../../shared/models/data/dto/billing/renewal-details-request.dto';
import * as _ from 'lodash';
import { RenewalBillingSummaryRequestDTO, RnBillingSummaryTransactionDetails } from 'app/shared/models/data/dto/billing/renewal-billing-summary.request.dto';
import { Rater } from 'app/shared/constants/rater.constant';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { RenewalBillingSummaryResponseDTO } from 'app/shared/models/data/dto/billing/renewal-billing-summary.response.dto';
import { SavePaymentProfileBillingAddress, SavePaymentProfileBillingDetail, SavePaymentProfileCreditCard, SavePaymentProfilePaymentAccount, SavePaymentProfileRequestDTO } from 'app/shared/models/data/dto/billing/save-payment-profile.request.dto';
import { BindAndIssueData } from '../../../modules/submission-management/data/bind-and-issue.data';
import { SubmissionService } from '../../../core/services/submission/submission.service';
import { QuoteService } from '../../../core/services/quote.service';
import { FormTypeConstants } from '../../../shared/constants/form-types.constants';
import { UploadCheckImageRequestDTO } from '../../../shared/models/data/dto/billing/upload-check-image-request.dto';
import { UploadCheckImageResponsetDTO } from '../../../shared/models/data/dto/billing/upload-check-image-response.dto';
import { GenericConstants } from '../../../shared/constants/generic.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { DocumentUploadModalComponent } from '../policy-billing/payments/related-documents/document-upload-modal/document-upload-modal.component';
import { StatusConstants } from '../../../shared/constants/risk-status';
import { AutoReinstatementConstants } from '../../../shared/constants/auto-reinstatement.constants';
import { RefundRelatedConstants } from '../../../shared/constants/refund.options.constants';
import { RefundData } from '../../../modules/payments/data/refund.data';
import { UpdateAllowRecurringRequestDto } from '../../../shared/models/data/dto/billing/update-allow-recurring-request.dto';
import { PolicyBillingConstants } from '../../../shared/constants/policy-billing.constants';
import { PolicyService } from '../../../core/services/submission/policy.service';
import { RiskInterestDetailDTO2 } from 'app/shared/models/data/dto/quick-quote/risk-interest-detail.dto';
import { IGetAgencyPolicyCommissionRateRequestDTO } from 'app/shared/models/management/agency-management/agency-details.response.dto';
import { DatePipe } from '@angular/common';
import { ApplicantData } from 'app/modules/submission-management/data/applicant.data';
import { PropertyData } from 'app/modules/submission-management/data/property.data';
import { RiskDTO2 } from 'app/shared/models/data/dto/quick-quote/risk-dto2';
import { Store, select } from '@ngrx/store';
import { updateEntityIsLoadingFromPolicyBillingData } from 'app/store/policy/policy.actions';
import { selectRaterIsLoading } from 'app/store/rater/rater.selectors';

@Injectable({
  providedIn: 'root'
})
export class PolicyBillingData extends BaseClass {

  public postPaymentForm: FormGroup;
  public paymentDetailsForm: FormGroup;
  public changePaymentPlanForm: FormGroup;
  public addPaymentAccountForm: FormGroup;
  public accountHolderForm: FormGroup;
  public billingLabelConstants = PolicyBillingLabelsConstants;
  public policyBillingConstants = PolicyBillingConstants;

  public paymentDocumentsList: DocumentUpload[] = [];

  public summary: BillingSummaryDTO;
  public installmentSchedules: InstallmentInvoiceDTO[] = [];
  public paymentList: PaymentViewDTO[];
  public transactionFees: TransactionFeeDTO[];

  public rnInstallmentSchedules: InstallmentInvoiceDTO[] = [];
  public rnPaymentAccounts: PaymentAccountResponse[] = [];
  public rnBillingSummary: RenewalBillingSummaryResponseDTO = null;

  public initialPaymentPlanId: string;
  public selectedPaymentPlanId: string;

  public data: EntityRiskDTO;

  makePaymentData: MakePaymentDTO;

  LvPayPlanOptions = LvPayPlanOptions;
  LvCreditCardType = LvCreditCardType;
  LvAccountType = LvAccountType;
  LvPaymentMethod = LvPaymentMethod;

  paymentListPager: any = {};
  paymentListLoading: boolean;
  paymentListPagedItems: PaymentViewDTO[];
  paymentListPagedItemsTemp: PaymentViewDTO[] = [];

  feeListPager: any = {};
  feeListLoading: boolean;
  feeListPagedItems: TransactionFeeDTO[];

  isMortgageeExists: boolean;
  isPremCoExists: boolean;
  isAccessedFromPortal: boolean;

  formTypes = FormTypeConstants;
  protected getRiskId(): string {
    return this.data?.risks[0]?.id;
  }

  public paymentPlan: BillingMapper[] = [
    { code: 'PPO0', billingCode: 'Full Pay' },
    { code: 'PPO1', billingCode: 'Two Pay' },
    { code: 'PPO2', billingCode: 'Four Pay' },
    { code: 'PPO3', billingCode: 'Eight Pay' },
    { code: 'PPO4', billingCode: 'Mortgagee' }
  ];

  public paymentPlanChangePlan: BillingMapper[] = [
    { code: 'PPO0', billingCode: 'Full' },
    { code: 'PPO1', billingCode: 'Two' },
    { code: 'PPO2', billingCode: 'Four' },
    { code: 'PPO3', billingCode: 'Eight' },
    { code: 'PPO4', billingCode: 'Mortgagee' }
  ];

  public feeTypes = [
    { code: 'NSFF', description: 'NSF Fee' },
    { code: 'IF', description: 'Installment Fee' },
  ];

  public paymentMethods: BillingMapper[] = [
    { code: 'PM0', billingCode: 'C' },
    { code: 'PM1', billingCode: 'CC' },
    { code: 'PM2', billingCode: 'E' },
    { code: 'PM3', billingCode: 'RCC' },
    { code: 'PM4', billingCode: 'RE' },
  ];

  public paymentMethodsPostPayment = [
    { code: 'PM0', description: 'Check', forPostPayment: true, billingCode: 'CHQ', recurringMethod: false },
    { code: 'PM1', description: 'Credit Card', forPostPayment: true, billingCode: 'CC', recurringMethod: false },
    { code: 'PM5', description: 'eCheck ', forPostPayment: true, billingCode: 'EFT', recurringMethod: false },
    { code: 'PM4', description: 'Recurring eCheck', forPostPayment: true, billingCode: 'RCHQ', recurringMethod: true },
    { code: 'PM3', description: 'Recurring Credit Card', forPostPayment: true, billingCode: 'RCC', recurringMethod: true },
  ];

  public accountTypes: BillingMapper[] = [
    { code: 'AT0', billingCode: 'checking' },
    { code: 'AT1', billingCode: 'savings' },
  ];

  public creditCardTypes: BillingMapper[] = [
    { code: 'CCT0', billingCode: 'Amex' },
    { code: 'CCT1', billingCode: 'Discover' },
    { code: 'CCT2', billingCode: 'Visa' },
    { code: 'CCT3', billingCode: 'Mastercard' },
  ];

  public recurringPaymentMethods = [
    { display: 'Credit Card', code: 'PM3', isDisabled: false, instrumentTypeId: 'RCC' },
    { display: 'E-Check', code: 'PM4', isDisabled: false, instrumentTypeId: 'RCHQ' }
  ];

  public paymentAccounts: PaymentAccountResponse[] = [];
  recurringSectionDetails: PaymentProfileResponse = null;
  paymentProfile: PaymentProfileResponse = null;
  readonly cardCodeCvvLength: number = 3;
  readonly cardCodeCidLength: number = 4;

  cardCodeLabel: string = this.billingLabelConstants.creditCard.cardCodeCVV;
  cardCodeLength: number = this.cardCodeCvvLength;

  paymentProfileNotifier = new Subject<{ value: any; isCompleted: boolean; isError: boolean }>();
  paymentAccountNotifier = new Subject<{ value: any; isCompleted: boolean; isError: boolean }>();
  paymentAccountListNotifier = new Subject<{ value: any; isCompleted: boolean; isError: boolean }>();
  hasAccountHolder: boolean = false;

  isEnrolledToAutopay: boolean;
  isDepositFullyPaid: boolean;

  public paymentMethodChangedEvent: EventEmitter<any> = new EventEmitter<any>();

  public feesInitialLoadNotifier: Subject<void> = new Subject();

  isPaymentViewFilter: boolean = false;
  paymentFromSuspenseList: any[] = [];
  paymentFromSuspenseListClone: any[] = [];
  paymentItems: any[] = [];
  isDoneLooping: boolean = false;
  ctr: number = 0;
  isFromSuspendedAndUserUploadInPolicy: boolean = false;

  isMakeAPaymentRedirect: boolean = false;

  withRenewalOffered: boolean;
  renewalAmount: number;
  currenntAndRenewalAmount: number;
  previousBalance: number;
  futureInstallmentAmount: number;
  renewalTotalPremium: number;
  renewalPaymentPlan: string;

  checkImageRequest: UploadCheckImageRequestDTO = null;
  uploadedCheckImage: UploadCheckImageResponsetDTO = null;
  modalRef: BsModalRef | null;

  isPaymentFromRenewalSubmission: boolean = false;

  billDate = [];
  greatherThanHideViewTemporaryDate = [];

  private totalPaymentWithCreditCardFee: number;
  selectedPayorOption: string;
  riskInterestDetailsWithSelectedPayor: RiskInterestDetailDTO2;

  isInternal: boolean;
  hasOneAgreeCheckbox: boolean;
  selectRaterIsLoading$ = this.store.pipe(select(selectRaterIsLoading));


  constructor(protected fb: FormBuilder,
    public billingService: BillingService,
    protected pagerService: PagerService,
    protected summaryData: SummaryData,
    protected raterData: CalculateRaterData,
    protected validationService: GeneralValidationService,
    protected toastr: ToastrService,
    protected entityRiskData: EntityRiskData,
    protected thirdPartyDataService: ThirdPartyDataService,
    protected localStorage: LocalStorageService,
    public auth: AuthService,
    public bindAndIssueData: BindAndIssueData,
    public submissionService: SubmissionService,
    public quoteService: QuoteService,
    public modalService: BsModalService,
    protected refundRequestData: RefundData,
    protected policyService: PolicyService,
    protected applicantData: ApplicantData,
    protected propertyData: PropertyData,
    protected store: Store
  ) {
    super();
    this.selectRaterIsLoading$ = this.store.pipe(select(selectRaterIsLoading));
  }

  initForms(): void {
    this.initPostPaymentForm();
    this.initChangePaymentPlanForm();
    this.initPaymentAccountForm();
    this.initPaymentDetailsForm();
    this.initAccountHolderForm();

    this.auth.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.isInternal = this.isInternalUser(userType);
    });
  }

  initPostPaymentForm(): void {
    this.postPaymentForm = this.fb.group({
      postDate: new FormControl(null, [Validators.required]),
      amount: new FormControl(null, [Validators.required]),
      paymentMethod: new FormControl('', [Validators.required]),
      refundTo: new FormControl(''),
      comments: new FormControl(''),
      email: new FormControl(''),
      totalPayment: new FormControl(null),

      agreeEnrollAutoPay: new FormControl(false),
      //agreeEnrollAutoPay: new FormControl(false, [Validators.required]),
      agreeOneTimePayment: new FormControl(false),
      agreeInsured: new FormControl(false),
      agreeCreditCardFee: new FormControl(false),

      currentTermCheckbox: new FormControl(false),
      currentAndRenewalTermCheckbox: new FormControl(false),

      check: this.initCheckForm(),
      creditCard: this.initCreditCardForm(),
      eCheck: this.initECheckForm(),
      checkNumber: new FormControl(''),

      reinstateAmountCheckbox: new FormControl(false),
      balanceAmountCheckbox: new FormControl(false)
    });

    this.postPaymentForm.get('paymentMethod').valueChanges.subscribe(_ => {
      this.clearAndSetValidators();
      this.paymentMethodChangedEvent.next(this.postPaymentForm.get('paymentMethod').value);
      this.setValidationForCheck();

      this.totalPaymentWithCreditCardFee = this.calculateTotalPaymentWithCreditCardFee(this.postPaymentForm.get('amount').value);
      this.postPaymentForm.get('totalPayment').setValue(this.totalPaymentWithCreditCardFee);
    });

    this.postPaymentForm.get('amount').valueChanges.subscribe((val) => {
      this.totalPaymentWithCreditCardFee = this.calculateTotalPaymentWithCreditCardFee(val);
      this.postPaymentForm.get('totalPayment').setValue(this.totalPaymentWithCreditCardFee);
    });

    this.onCardCodeValueChange(this.postPaymentForm);

    merge(
      this.postPaymentForm.get('creditCard').valueChanges,
      this.postPaymentForm.get('eCheck').valueChanges
    ).subscribe(_ => this.postPaymentForm.get('agreeInsured').setValue(0));
  }

  clearAndSetValidators() {
    this.clearPaymentMethodValidators();

    this.validationService.clearValidatorFormControl(this.postPaymentForm, 'agreeEnrollAutoPay');
    this.validationService.clearValidatorFormControl(this.postPaymentForm, 'agreeOneTimePayment');
    this.validationService.clearValidatorFormControl(this.postPaymentForm, 'agreeInsured');
    this.validationService.clearValidatorFormControl(this.postPaymentForm, 'agreeCreditCardFee');

    if (this.postPaymentForm.get('paymentMethod').value === PaymentMethod.CashCheck) {
      this.setValidatorCheckPayment(this.postPaymentForm);
    } else if (this.postPaymentForm.get('paymentMethod').value === PaymentMethod.CreditCard || this.postPaymentForm.get('paymentMethod').value === PaymentMethod.RecurringCreditCard) {
      this.setValidatorCreditCardPayment(this.postPaymentForm);
    } else if (this.postPaymentForm.get('paymentMethod').value === PaymentMethod.EFT || this.postPaymentForm.get('paymentMethod').value === PaymentMethod.RecurringECheck) {
      this.setValidatorEftPayment(this.postPaymentForm);
    }
  }

  initChangePaymentPlanForm(): void {
    this.changePaymentPlanForm = this.fb.group({
      newPlan: new FormControl('', [Validators.required]),
      payor: new FormControl(''),
      paymentMethod: new FormControl('', [Validators.required]),
      paymentRequired: new FormControl(null),

      amount: new FormControl(null, [Validators.required]),
      totalPayment: new FormControl(null),

      agreeEnrollAutoPay: new FormControl(false),
      agreeOneTimePayment: new FormControl(false),
      agreeInsured: new FormControl(false),
      agreeCreditCardFee: new FormControl(false),

      creditCard: this.initCreditCardForm(),
      eCheck: this.initECheckForm()
    });

    this.changePaymentPlanForm.get('paymentMethod').valueChanges.subscribe((val) => {
      this.clearAndSetValidators();
      this.paymentMethodChangedEvent.next(this.changePaymentPlanForm.get('paymentMethod').value);

      this.clearChangePaymentMethodValidators();

      if (val === PaymentMethod.CreditCard) {
        this.setValidatorCreditCardPayment(this.changePaymentPlanForm);
      } else if (val === PaymentMethod.EFT) {
        this.setValidatorEftPayment(this.changePaymentPlanForm);
      }

      this.totalPaymentWithCreditCardFee = this.calculateTotalPaymentWithCreditCardFee(this.changePaymentPlanForm.get('amount').value);
      this.changePaymentPlanForm.get('totalPayment').setValue(this.totalPaymentWithCreditCardFee);
    });

    this.changePaymentPlanForm.get('amount').valueChanges.subscribe((val) => {
      this.totalPaymentWithCreditCardFee = this.calculateTotalPaymentWithCreditCardFee(val);
      this.changePaymentPlanForm.get('totalPayment').setValue(this.totalPaymentWithCreditCardFee);
    });

    this.changePaymentPlanForm.get('paymentRequired').valueChanges.subscribe(_ => {
      if (this.changePaymentPlanForm.get('paymentRequired').value === 0) {
        this.changePaymentPlanForm.get('paymentMethod').setValue(null);

        this.validationService.clearValidatorFormControl(this.changePaymentPlanForm, 'amount');
        this.validationService.clearValidatorFormControl(this.changePaymentPlanForm, 'agreeEnrollAutoPay');
        this.validationService.clearValidatorFormControl(this.changePaymentPlanForm, 'agreeOneTimePayment');
        this.validationService.clearValidatorFormControl(this.changePaymentPlanForm, 'agreeInsured');
        this.validationService.clearValidatorFormControl(this.changePaymentPlanForm, 'agreeCreditCardFee');
      } else {
        this.validationService.resetValidatorFormControl(this.changePaymentPlanForm, 'amount', [Validators.required]);
      }
    });

    this.onCardCodeValueChange(this.changePaymentPlanForm);

    merge(
      this.changePaymentPlanForm.get('creditCard').valueChanges,
      this.changePaymentPlanForm.get('eCheck').valueChanges
    ).subscribe(_ => this.changePaymentPlanForm.get('agreeInsured').setValue(0));
  }

  initPaymentDetailsForm(): void {
    this.paymentDetailsForm = this.fb.group({
      postDate: new FormControl(null),
      amount: new FormControl(null),
      postedBy: new FormControl(),
      premium: new FormControl(),
      tax: new FormControl(),
      fee: new FormControl(),
      type: new FormControl(),
      paymentMethod: new FormControl(''),
      reference: new FormControl(),
      referenceType: new FormControl(),
      totalPaid: new FormControl(),

      reversalType: new FormControl(),
      reversalDate: new FormControl(),
      reversalProcessedBy: new FormControl(),

      clearDate: new FormControl(),
      escheatDate: new FormControl(),

      comments: new FormControl(''),
      status: new FormControl(),

      suspendedPaymentDetail: this.initSuspendedPaymentDetailForm(),
      refundCheckNumber: new FormControl(),
      refundCheckDate: new FormControl(),
      refundName: new FormControl(),
      refundAddress: new FormControl(),

      isTransferable: new FormControl(),
      isVoidable: new FormControl(),
      transferableAmount: new FormControl(),
    });
  }

  initSuspendedPaymentDetailForm(): FormGroup {
    return this.fb.group({
      receiptDate: new FormControl(),
      transactionNum: new FormControl(),
      lockboxNum: new FormControl(),
      batch: new FormControl(),
      batchItem: new FormControl(),
      reason: new FormControl(),
      routingNum: new FormControl(),
      accountNum: new FormControl(),
      checkNum: new FormControl(),
      checkAmount: new FormControl(),
      amount: new FormControl(),
      policyNumber: new FormControl(),
      comment: new FormControl(''),
      directBillCarrierId: new FormControl(),
      payer: this.fb.group({
        firstName: new FormControl(),
        lastName: new FormControl(),
        middleInitial: new FormControl(),
        address1: new FormControl(),
        address2: new FormControl(),
        city: new FormControl(),
        state: new FormControl(),
        zip: new FormControl()
      })
    });
  }

  initPaymentAccountForm(): void {
    this.addPaymentAccountForm = this.fb.group({
      paymentMethod: new FormControl('', [Validators.required]),
      creditCard: this.initCreditCardForm(),
      eCheck: this.initECheckForm(),
      agreeEnrollAutoPay: new FormControl(false),
      agreeOneTimePayment: new FormControl(false),
      agreeInsured: new FormControl(false),
      agreeCreditCardFee: new FormControl(false)
    });

    this.addPaymentAccountForm.get('paymentMethod').valueChanges.subscribe(_ => {
      this.clearPaymentMethodValidatorsRecurringEnrollment();
      this.paymentMethodChangedEvent.next(this.addPaymentAccountForm.get('paymentMethod').value);

      if (this.addPaymentAccountForm.get('paymentMethod').value === PaymentMethod.RecurringCreditCard) {
        this.setValidatorCreditCardPayment(this.addPaymentAccountForm, true);
        this.addPaymentAccountForm.get('creditCard').updateValueAndValidity();
      } else if (this.addPaymentAccountForm.get('paymentMethod').value === PaymentMethod.RecurringECheck) {
        this.setValidatorEftPayment(this.addPaymentAccountForm, true);
        this.addPaymentAccountForm.get('eCheck').updateValueAndValidity();
      }
    });
    this.onCardCodeValueChange(this.addPaymentAccountForm);
  }

  initAccountHolderForm(): void {
    this.accountHolderForm = this.fb.group({
      agreeEnrollAutoPay: new FormControl(false, [Validators.requiredTrue])
    });

    merge(
      this.addPaymentAccountForm.get('creditCard').valueChanges,
      this.addPaymentAccountForm.get('eCheck').valueChanges
      ).subscribe(_ => this.addPaymentAccountForm.get('agreeInsured').setValue(0));
  }

  protected onCardCodeValueChange(currForm: FormGroup): void {
    currForm.get('creditCard').get('creditCardTypeId').valueChanges.subscribe(typeId => {
      if (typeId === CreditCardType.Amex) {
        this.cardCodeLabel = this.billingLabelConstants.creditCard.cardCodeCID;
        this.cardCodeLength = this.cardCodeCidLength;
      } else {
        if (currForm.get('creditCard').get('cardCode').value?.length > this.cardCodeCvvLength) {
          currForm.get('creditCard').get('cardCode').setValue('');
        }

        this.cardCodeLabel = this.billingLabelConstants.creditCard.cardCodeCVV;
        this.cardCodeLength = this.cardCodeCvvLength;
      }

      this.resetValidatorCreditCardType(currForm.get('creditCard') as FormGroup);

      currForm.updateValueAndValidity();
    });
  }

  initCheckForm(): FormGroup {
    return this.fb.group({
      isIndividual: new FormControl(true),
      name: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
      suffix: new FormControl(),
      address: new FormControl(),
      aptOrUnit: new FormControl(),
      city: new FormControl(),
      state: new FormControl(),
      zip: new FormControl(),
      country: new FormControl()
    });
  }

  initCreditCardForm(): FormGroup {
    return this.fb.group({
      firstName: new FormControl(),
      lastName: new FormControl(),
      address: new FormControl(),
      aptOrUnit: new FormControl(),
      zip: new FormControl(),
      city: new FormControl(),
      state: new FormControl(),
      creditCardTypeId: new FormControl(),
      cardNumber: new FormControl(),
      expirationMonth: new FormControl(),
      expirationYear: new FormControl(),
      cardCode: new FormControl(),
      email: new FormControl()
    });
  }

  initECheckForm(): FormGroup {
    return this.fb.group({
      accountType: new FormControl(),
      routingNumber: new FormControl(),
      accountNumber: new FormControl(),
      bankName: new FormControl(),
      nameOnAccount: new FormControl(),

      firstName: new FormControl(),
      lastName: new FormControl(),
      address: new FormControl(),
      aptOrUnit: new FormControl(),
      zip: new FormControl(),
      city: new FormControl(),
      state: new FormControl(),

      email: new FormControl(),
    });
  }

  setValidatorCheckPayment(form: AbstractControl): void {
    const checkPaymentForm = form.get('check');

    if (checkPaymentForm.get('isIndividual').value) {
      this.resetValidator(checkPaymentForm, 'firstName', [Validators.required, Validators.maxLength(50)]);
      this.resetValidator(checkPaymentForm, 'lastName', [Validators.required, Validators.maxLength(50)]);
    } else {
      this.resetValidator(checkPaymentForm, 'name', [Validators.required, Validators.maxLength(64)]);
    }

    this.resetValidator(checkPaymentForm, 'address', [Validators.required, Validators.maxLength(32)]);
    this.resetValidator(checkPaymentForm, 'aptOrUnit', [Validators.maxLength(32)]);
    this.resetValidator(checkPaymentForm, 'city', [Validators.required, Validators.maxLength(32)]);
    this.resetValidator(checkPaymentForm, 'zip', [Validators.required, Validators.maxLength(10)]);
  }

  setValidatorCreditCardPayment(form: AbstractControl, isForAddPaymentAccount: boolean = false): void {
    const ccPaymentForm = form.get('creditCard');
    this.resetValidator(ccPaymentForm, 'firstName', [Validators.required, Validators.maxLength(32)]);
    this.resetValidator(ccPaymentForm, 'lastName', [Validators.required, Validators.maxLength(31)]);
    this.resetValidator(ccPaymentForm, 'address', [Validators.required]);
    this.resetValidator(ccPaymentForm, 'zip', [Validators.required]);
    this.resetValidator(ccPaymentForm, 'city', [Validators.required]);
    this.resetValidator(ccPaymentForm, 'creditCardTypeId', [Validators.required]);
    this.resetValidator(ccPaymentForm, 'cardNumber', [Validators.required]);
    this.resetValidator(ccPaymentForm, 'expirationMonth', [Validators.required]);
    this.resetValidator(ccPaymentForm, 'expirationYear', [Validators.required]);
    this.resetValidator(ccPaymentForm, 'cardCode', [Validators.required, Validators.minLength(3)]);
    this.resetValidator(ccPaymentForm, 'email', [Validators.pattern(
      '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,12}$'
    ), CustomValidators.spaceValidator,]);

    if (ccPaymentForm.get('email')) {
      this.resetValidator(ccPaymentForm, 'email', [Validators.pattern(
        '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,12}$'
      ), CustomValidators.spaceValidator, Validators.required]);
    }

    this.resetValidatorCreditCardType(ccPaymentForm as FormGroup);
  }

  setValidatorEftPayment(form: AbstractControl, isForAddPaymentAccount: boolean = false): void {
    const eftPaymentForm = form.get('eCheck');
    this.resetValidator(eftPaymentForm, 'accountType', [Validators.required]);
    this.resetValidator(eftPaymentForm, 'routingNumber', [Validators.required]);
    this.resetValidator(eftPaymentForm, 'accountNumber', [Validators.required]);
    this.resetValidator(eftPaymentForm, 'firstName', [Validators.required, Validators.maxLength(32)]);
    this.resetValidator(eftPaymentForm, 'lastName', [Validators.required, Validators.maxLength(31)]);
    this.resetValidator(eftPaymentForm, 'address', [Validators.required]);
    this.resetValidator(eftPaymentForm, 'zip', [Validators.required]);
    this.resetValidator(eftPaymentForm, 'city', [Validators.required]);
    this.resetValidator(eftPaymentForm, 'email', [Validators.pattern(
      '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,12}$'
    ), CustomValidators.spaceValidator]);
    this.resetValidator(eftPaymentForm, 'nameOnAccount', [Validators.required, Validators.maxLength(64)]);

    if (eftPaymentForm.get('email')) {
      this.resetValidator(eftPaymentForm, 'email', [Validators.pattern(
        '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,12}$'
      ), CustomValidators.spaceValidator, Validators.required]);
    }
  }

  clearPaymentMethodValidators(): void {
    const checkPaymentForm = this.postPaymentForm.get('check');
    const ccPaymentForm = this.postPaymentForm.get('creditCard');
    const eftPaymentForm = this.postPaymentForm.get('eCheck');

    FormUtils.clearValidatorsByFormGroup(checkPaymentForm);
    FormUtils.clearValidatorsByFormGroup(ccPaymentForm);
    FormUtils.clearValidatorsByFormGroup(eftPaymentForm);
  }

  clearChangePaymentMethodValidators(): void {
    const ccPaymentForm = this.changePaymentPlanForm.get('creditCard');
    const eftPaymentForm = this.changePaymentPlanForm.get('eCheck');

    FormUtils.clearValidatorsByFormGroup(ccPaymentForm);
    FormUtils.clearValidatorsByFormGroup(eftPaymentForm);
  }

  clearPaymentMethodValidatorsRecurringEnrollment(): void {
    const ccPaymentForm = this.addPaymentAccountForm.get('creditCard');
    const eftPaymentForm = this.addPaymentAccountForm.get('eCheck');

    FormUtils.clearValidatorsByFormGroup(ccPaymentForm);
    FormUtils.clearValidatorsByFormGroup(eftPaymentForm);
  }

  mapDate(date?): string {
    const month = new Date(date).getMonth() + 1 > 9 ? new Date(date).getMonth() + 1 : '0' + (new Date(date).getMonth() + 1);
    const day = new Date(date).getDate() > 9 ? new Date(date).getDate() : '0' + new Date(date).getDate();
    const year = new Date(date).getFullYear();

    return `${month}/${day}/${year}`;
  }

  showSummary(riskId: string): void {
    this.summary = null;
    this.billingService.getSummary(riskId).subscribe(result => {
      if (result.paymentPlan) {

        const payPlan: string = JsUtils.mergeByKey(this.paymentPlan, LvPayPlanOptions, 'code').filter(item => item.billingCode === result.paymentPlan)[0].code;
        this.selectedPaymentPlanId = payPlan;
        this.initialPaymentPlanId = payPlan;

        this.summary = result;
      }

      this.isDepositFullyPaid = result.isDepositFullyPaid;
    });
  }

  populateTransactionSummary(riskId: string): void {
    this.billingService.getTransactionSummary(riskId).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.summaryData.billingPremiumRetrieve.next(result);
    });
  }

  listInstallmentInvoice(riskId: string): void {
    this.greatherThanHideViewTemporaryDate = [];
    this.installmentSchedules = [];
    this.billingService.getInstallmentInvoice(riskId).subscribe(items => {
      if(items) {
        this.billingService.getInvoicesDocs(items.map(x => x.invoiceId)).subscribe(res => {
          if(res) {
            items.forEach(x => {
              x.riskBillingInvoiceDoc = res.find(a => a.invoiceId === x.invoiceId);
            });
            this.installmentSchedules = items;
            this.installmentSchedules.forEach(x => {
              const temporaryDateForHidingView = '2022-06-01T00:00:00';
             this.greatherThanHideViewTemporaryDate.push(new Date(x.billDate) >= new Date(temporaryDateForHidingView));
            });
          }
        });
      }
    });
  }

  listPaymentsByRiskId(riskId: string): void {
    this.resetPaymentList();
    this.billingService.getPaymentByRiskId(riskId).pipe(takeUntil(this.stop$)).subscribe((items) => {
      if (items && items.length > 0) {
        this.paymentItems = items;
        this.paymentItems.forEach((data) => {
          this.billingService.getPaymentDetails(riskId, data.id).pipe(takeUntil(this.stop$)).subscribe(res => {
            if (res) {
              if (res?.suspendedPaymentDetail) {
                this.paymentFromSuspenseList = this.paymentItems.filter(x => x.id === res.paymentId);
                this.paymentFromSuspenseListClone.push(this.paymentFromSuspenseList[0]);
              }
              this.ctr++;
              if (this.ctr === this.paymentItems.length) {
                this.isDoneLooping = true;
                if (this.isDoneLooping) {
                  this.paymentList = this.paymentItems.sort((a, b) => +new Date(b.createdDate) - +new Date(a.createdDate));
                  this.paymentFromSuspenseListClone =  this.paymentFromSuspenseListClone.sort((a, b) => +new Date(b.createdDate) - +new Date(a.createdDate));
                  this.paymentListSetPage(1);
                  this.isDoneLooping = false;
                }
              }
            }
          });
        });
      } else {
        this.paymentListSetPage(1);
      }
    });
  }

  listTransactionFeesByRiskId(riskId: string, isInitialLoad: boolean = false): void {
    this.transactionFees = [];
    this.billingService.getTransactionFees(riskId).pipe(takeUntil(this.stop$)).subscribe(items => {
      this.transactionFees = items.sort((a, b) => +new Date(b.addDate) - +new Date(a.addDate));

      if (isInitialLoad) {
        this.feesInitialLoadNotifier.next();
      } else {
        this.raterData.billingTransactionFeesRequest(this);
      }

      this.feeListSetPage(1);
    }, err => {
      this.toastr.error(ErrorMessageConstant.cannotRetrieveFeesErrorMessage);
    });
  }

  public populatePolicyBillingPage(data: EntityRiskDTO): void {
    this.data = data;
    this.populateBillingSections(this.getRiskId());
    this.checkIfMortgageeExist();
  }

  populateBillingSections(riskId: string): void {
    this.populateTransactionSummary(riskId);
    this.showSummary(riskId);
    this.listInstallmentInvoice(riskId);
    this.listPaymentsByRiskId(riskId);
    this.getRecurringPaymentDetails(riskId);
    this.setSelectedPayorOption();
  }

  paymentListSetPage(page: number): void {
    if (page < 1) {
      return;
    }

    if (this.isPaymentViewFilter) {
      this.paymentFromSuspenseListClone = this.paymentFromSuspenseListClone.filter((value, index, self) => self.map(x => x.id).indexOf(value.id) === index);
      this.paymentListPager = this.pagerService.getPager(this.paymentFromSuspenseListClone?.length, page);
      this.paymentListPagedItems = this.paymentFromSuspenseListClone.slice(this.paymentListPager.startIndex, this.paymentListPager.endIndex + 1);

    } else {
      this.paymentListPager = this.pagerService.getPager(this.paymentList?.length, page);
      this.paymentListPagedItems = this.paymentList?.slice(this.paymentListPager.startIndex, this.paymentListPager.endIndex + 1);
    }
  }

  feeListSetPage(page: number): void {
    this.feeListPager = this.pagerService.getPager(this.transactionFees.length, page);
    this.feeListPagedItems = this.transactionFees.slice(this.feeListPager.startIndex, this.feeListPager.endIndex + 1);
  }

  getAccountTypeBillingCode(code: string): string {
    return JsUtils.mergeByKey(this.accountTypes, LvAccountType, 'code').filter(item => item.code === code)[0].billingCode;
  }

  getCreditCardTypeBillingCode(code: string): string {
    return JsUtils.mergeByKey(this.creditCardTypes, LvCreditCardType, 'code').filter(item => item.code === code)[0].billingCode;
  }

  getCurrentPayPlan(): string {
    return LvPayPlanOptions.filter(item => item.code === this.initialPaymentPlanId)[0]?.description;
  }

  getCurrentNextTermPayPlan(): string {
    const currentRiskDetailBillingBind = this.entityRiskData.getCurrentRiskBindbilling();
    const currentNextTermPayPlan = currentRiskDetailBillingBind.nextTermpaymentPlan;
    return LvPayPlanOptions.filter(item => item.code === currentNextTermPayPlan)[0]?.description;
  }

  enableCityFields(): void {
    this.postPaymentForm.get('check').get('city').enable();
    this.postPaymentForm.get('creditCard').get('city').enable();
    this.postPaymentForm.get('eCheck').get('city').enable();
  }

  protected resetValidator(formGroup?, controlName?, validator?): void {
    this.validationService.resetValidatorFormControl(formGroup, controlName, validator);
  }

  //#region Recurring Payment Enrollment Section
  addPaymentProfile(request: PaymentProfileRequest): void {
    Utils.blockUI();
    this.paymentProfileNotifier.next({ value: null, isCompleted: false, isError: false });
    this.billingService.postPaymentProfile(request).subscribe((result: PaymentProfileResponse) => {
      this.paymentProfileNotifier.next({ value: result, isCompleted: true, isError: false });
      this.accountHolderForm.get('agreeEnrollAutoPay').setValue(result.isRecurringPayment);
      this.getRecurringPaymentDetails(request.riskId);
      Utils.unblockUI();
    }, err => {
      this.paymentProfileNotifier.next({ value: err.error, isCompleted: true, isError: true });
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify(err.error?.message));
    });
  }

  updatePaymentProfile(request: PaymentProfileRequest): void {
    Utils.blockUI();
    this.paymentProfileNotifier.next({ value: null, isCompleted: false, isError: false });
    this.billingService.putPaymentProfile(request).subscribe((result: PaymentProfileResponse) => {
      this.paymentProfileNotifier.next({ value: result, isCompleted: true, isError: false });
      this.accountHolderForm.get('agreeEnrollAutoPay').setValue(result.isRecurringPayment);
      this.getRecurringPaymentDetails(request.riskId);
      Utils.unblockUI();
    }, err => {
      this.paymentProfileNotifier.next({ value: err.error, isCompleted: true, isError: true });
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify(err.error?.message), () => {
        this.accountHolderForm.get('agreeEnrollAutoPay').setValue(!request.isRecurringPayment);
      });
    });
  }

  paymentAccountRequestMapper(isAdd: boolean, isEdit: boolean, item: PaymentAccountResponse = null): PaymentAccountRequest {
    const methodType = this.addPaymentAccountForm.get('paymentMethod').value;
    const creditCard = (this.addPaymentAccountForm.get('creditCard') as FormGroup).controls;
    const eftForm = (this.addPaymentAccountForm.get('eCheck') as FormGroup).controls;
    const zeroLeading = '0';
    let payload: PaymentAccountRequest = null;

    if (isAdd) {
      const selectedPaymentMethod = this.paymentMethodsPostPayment.find(method => method.code === methodType).code;

      let billingAddressForm: { [key: string]: AbstractControl };
      if (selectedPaymentMethod === PaymentMethod.RecurringCreditCard) {
        billingAddressForm = creditCard;
      } else if (selectedPaymentMethod === PaymentMethod.RecurringECheck) {
        billingAddressForm = eftForm;
      }

      payload = {
        riskId: this.getRiskId(),
        instrumentTypeId: this.paymentMethodsPostPayment.find(method => method.code === methodType).billingCode,
        isDefault: true,
        billingDetail: {
          creditCard: (selectedPaymentMethod === PaymentMethod.RecurringCreditCard) ? {
            cardType: this.getCreditCardTypeBillingCode(creditCard.creditCardTypeId.value),
            cardCode: creditCard.cardCode.value,
            cardNumber: creditCard.cardNumber.value,
            expirationDate: `${creditCard.expirationYear.value}-${Number(creditCard.expirationMonth.value) < 10 ? zeroLeading.concat(creditCard.expirationMonth.value) : creditCard.expirationMonth.value}`
          } : null,
          bankAccount: (selectedPaymentMethod === PaymentMethod.RecurringECheck) ? {
            accountNumber: eftForm.accountNumber.value,
            accountType: this.getAccountTypeBillingCode(eftForm.accountType.value),
            nameOnAccount: eftForm.nameOnAccount.value,
            routingNumber: eftForm.routingNumber.value,
          } : null,
          billingAddress: {
            firstName: billingAddressForm.firstName.value,
            lastName: billingAddressForm.lastName.value,
            address: billingAddressForm.address.value,
            city: billingAddressForm.city.value,
            zip: billingAddressForm.zip.value,
            email: billingAddressForm.email.value,
            state: billingAddressForm.state.value
          },
        }
      };
    } else {
      payload = {
        id: item.id,
        riskId: this.getRiskId() ?? this.entityRiskData?.riskId
      };

      if (isEdit) {
        payload.isDefault = item.isDefault;
      }
    }
    return payload;
  }

  addPaymentAccount(): void {
    Utils.blockUI();
    this.paymentAccountNotifier.next({ value: null, isCompleted: false, isError: false });
    const request = this.paymentAccountRequestMapper(true, false);

    this.billingService.postPaymentAccount(request).subscribe((result: PaymentAccountResponse) => {
      this.paymentAccountNotifier.next({ value: result, isCompleted: true, isError: false });
      this.getRecurringPaymentDetails(request.riskId);
      this.paymentAccountListNotifier.take(1).subscribe(acctList => {
        if (acctList.isCompleted) {
          Utils.unblockUI();
        }
      });
    }, err => {
      this.paymentAccountNotifier.next({ value: err.error, isCompleted: true, isError: true });
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify(err.error?.message));
    });
  }

  updatePaymentAccount(account): void {
    Utils.blockUI();
    this.paymentAccountNotifier.next({ value: null, isCompleted: false, isError: false });
    const request = this.paymentAccountRequestMapper(false, true, account);

    this.billingService.putPaymentAccount(request).subscribe((result: PaymentAccountResponse) => {
      this.paymentAccountNotifier.next({ value: result, isCompleted: true, isError: false });
      this.getRecurringPaymentDetails(request.riskId);
      this.paymentAccountListNotifier.take(1).subscribe(acctList => {
        if (acctList.isCompleted) {
          Utils.unblockUI();
        }
      });
    }, err => {
      this.paymentAccountNotifier.next({ value: err.error, isCompleted: true, isError: true });
      this.getRecurringPaymentDetails(request.riskId);
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify(err.error?.message));
    });
  }

  updateRenewalPaymentAccount(account): void {
    Utils.blockUI();
    this.paymentAccountNotifier.next({ value: null, isCompleted: false, isError: false });
    const request = this.paymentAccountRequestMapper(false, true, account);

    this.billingService.putPaymentAccount(request).subscribe((result: PaymentAccountResponse) => {
      this.paymentAccountNotifier.next({ value: result, isCompleted: true, isError: false });
      this.getRenewalRecurringPaymentDetails(request.riskId);
      this.paymentAccountListNotifier.take(1).subscribe(acctList => {
        if (acctList.isCompleted) {
          Utils.unblockUI();
        }
      });
    }, err => {
      this.paymentAccountNotifier.next({ value: err.error, isCompleted: true, isError: true });
      this.getRenewalRecurringPaymentDetails(request.riskId);
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify(err.error?.message));
    });
  }

  deletePaymentAccount(account): void {
    Utils.blockUI();
    this.paymentAccountNotifier.next({ value: null, isCompleted: false, isError: false });
    const request = this.paymentAccountRequestMapper(false, false, account);

    this.billingService.deletePaymentAccount(request).subscribe((result: PaymentAccountResponse) => {
      this.paymentAccountNotifier.next({ value: result, isCompleted: true, isError: false });

      this.getRecurringPaymentDetails(request.riskId);
      this.paymentAccountListNotifier.take(1).subscribe(acctList => {
        if (acctList.isCompleted) {
          Utils.unblockUI();
        }
      });
    }, err => {
      this.paymentAccountNotifier.next({ value: err.error, isCompleted: true, isError: true });
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify(err.error?.message));
    });
  }

  unEnrollPaymentAccount(): void {
    Utils.blockUI();
    this.paymentAccountNotifier.next({ value: null, isCompleted: false, isError: false });

    this.billingService.deleteProfile(this.entityRiskData.getRiskId()).subscribe((result: PaymentAccountResponse) => {
      this.paymentAccountNotifier.next({ value: result, isCompleted: true, isError: false });
      this.getRecurringPaymentDetails(this.getRiskId());
      this.paymentAccountListNotifier.take(1).subscribe(acctList => {
        if (acctList.isCompleted) {
          Utils.unblockUI();
        }
      });
    }, err => {
      this.paymentAccountNotifier.next({ value: err.error, isCompleted: true, isError: true });
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify(err.error?.message));
    });
  }

  getRecurringPaymentDetails(riskId: string): void {
    this.resetRecurringPaymentDetails();

    this.paymentAccountListNotifier.next({ value: null, isCompleted: false, isError: false });
    this.billingService.getRecurringPaymentDetails(riskId).subscribe((result: PaymentProfileResponse) => {
      this.paymentAccountListNotifier.next({ value: result, isCompleted: true, isError: false });
      if (result) {
        this.isEnrolledToAutopay = result.isRecurringPayment;
        this.recurringSectionDetails = result;
        this.accountHolderForm.get('agreeEnrollAutoPay').setValue(result.isRecurringPayment);
        this.paymentAccounts = result?.paymentAccounts.length > 0 ? result.paymentAccounts.map(val => {
          val.accountTypeDisplay = this.recurringPaymentMethods.find(method => method.instrumentTypeId === val.instrumentTypeId).display;
          return val;
        }) : [];
      }
    }, err => {
      this.paymentAccountListNotifier.next({ value: err.error, isCompleted: true, isError: true });
      NotifUtils.showError(JSON.stringify(err.error?.message));
    });
  }

  getRenewalRecurringPaymentDetails(riskId: string): void {
    this.paymentAccountListNotifier.next({ value: null, isCompleted: false, isError: false });
    this.billingService.getRecurringPaymentDetails(riskId).subscribe((result: PaymentProfileResponse) => {
      this.paymentAccountListNotifier.next({ value: result, isCompleted: true, isError: false });
      if (result) {
        this.rnPaymentAccounts = result?.paymentAccounts.length > 0 ? result.paymentAccounts.map(val => {
          val.accountTypeDisplay = this.recurringPaymentMethods.find(method => method.instrumentTypeId === val.instrumentTypeId).display;
          return val;
        }) : [];
      }
    }, err => {
      this.paymentAccountListNotifier.next({ value: err.error, isCompleted: true, isError: true });
      NotifUtils.showError(JSON.stringify(err.error?.message));
    });
  }

  resetRecurringPaymentDetails(): void {
    this.hasAccountHolder = false;
    this.isEnrolledToAutopay = false;
    this.paymentAccounts = [];
    this.recurringSectionDetails = null;
    this.paymentProfile = null;
  }

  setDefaultAccountHolderInfo(): PaymentProfileRequest {
    return {
      riskId: this.entityRiskData.getRiskId(),
      firstName: this.data.firstName,
      lastName: this.data.lastName,
      email: this.data.personalEmailAddress,
      isRecurringPayment: this.paymentAccounts.length === 0? true : this.accountHolderForm.get('agreeEnrollAutoPay').value
    };
  }
  //#endregion

  getCurrentPaymentForm(paymentForm: FormGroup): FormGroup {
    if (paymentForm.get('paymentMethod').value === PaymentMethod.CashCheck) {
      return paymentForm.get('check') as FormGroup;
    } else if (paymentForm.get('paymentMethod').value === PaymentMethod.CreditCard || paymentForm.get('paymentMethod').value === PaymentMethod.RecurringCreditCard) {
      return paymentForm.get('creditCard') as FormGroup;
    } else if (paymentForm.get('paymentMethod').value === PaymentMethod.EFT || paymentForm.get('paymentMethod').value === PaymentMethod.RecurringECheck) {
      return paymentForm.get('eCheck') as FormGroup;
    }
  }

  resetValidatorCreditCardType(creditCardForm: FormGroup): void {
    const ccTypeId = creditCardForm.get('creditCardTypeId').value;

    if (ccTypeId === CreditCardType.Visa) {
      this.resetValidator(creditCardForm, 'cardNumber', [Validators.required, Validators.pattern(RegexConstants.CreditCardFormat.visa)]);
    } else if (ccTypeId === CreditCardType.Mastercard) {
      this.resetValidator(creditCardForm, 'cardNumber', [Validators.required, Validators.pattern(RegexConstants.CreditCardFormat.masterCard)]);
    } else {
      this.resetValidator(creditCardForm, 'cardNumber', [Validators.required]);
    }
  }

  checkIfMortgageeExist() {
    const riskId: string = this.entityRiskData.getRiskId();
    const lastRiskId: string = this.entityRiskData.getRiskDetailId();
    this.thirdPartyDataService.getRiskInterests(riskId, lastRiskId).pipe(takeUntil(this.stop$)).subscribe(interests => {
      const mortgageeCode = 'mort';
      const mortgageeExists = interests.some(x => x.riskInterestDetails.some(y => y.interestTypeCode.toLowerCase() === mortgageeCode && y.isActive && y.removedDate == null));

      this.isMortgageeExists = mortgageeExists;
    });
  }

  resetPaymentList() {
    this.paymentFromSuspenseListClone = [];
    this.paymentList = [];
    this.ctr = 0;
    this.isDoneLooping = false;
  }

  checkRenewalDetails(riskId: string, riskDetailId: string, isRefundRequest: boolean = false) {
    Utils.blockUI();
    const payload: RenewalDetailsRequestDTO = {
      expiringRiskId: riskId,
      expiringRiskDetailsId: riskDetailId

    };
    this.billingService.postRenewalDetails(payload).subscribe(res => {
      if (res) {
        this.renewalAmount = res.currentAmountInvoiced;
        this.currenntAndRenewalAmount = res.totalAmountDue;
        this.futureInstallmentAmount = res.futureInstallmentAmount;
        this.renewalTotalPremium = res.renewalTotalPremium;
        this.withRenewalOffered = this.isPolicyCancelled ? false : true;
        this.renewalPaymentPlan = this.paymentPlanChangePlan.find(x => x.billingCode === res.paymentPlan).code;

        if (this.withRenewalOffered) {
          const isNotInRenewalPage: boolean =  (this.isPolicy || Boolean(this.makePaymentData));
          this.getRenewalRiskForCommRate(isNotInRenewalPage);
        }

        if (!this.postPaymentForm.get('currentAndRenewalTermCheckbox').value && !this.postPaymentForm.get('currentTermCheckbox').value && !this.isPolicyCancelled) {
          if (!isRefundRequest) {
            this.postPaymentForm.get('currentAndRenewalTermCheckbox').setValue(0);
            this.postPaymentForm.get('amount').disable();
          }
        }

      } else {
        this.withRenewalOffered = false;
      }
      Utils.unblockUI();
    });

  }

  renewalBillingSummarySubscription: Subscription;
  populateRnBillingSummary(riskDetailId?: string, updatedPayPlan?: string): void {
    const raterResultKey = `${Rater.raterResult}_${riskDetailId}`;
    const raterResult = this.localStorage.retrieve(raterResultKey);

    const totalPremiumWithAdj = this.quoteService.formType !== this.formTypes.DP3 ? raterResult?.find(x => x.stepAlias === 'TotalPremiumWithAdj')?.perilValue : raterResult?.find(x => x.stepAlias === 'TotalPremium')?.perilValue;
    const mgaFee = raterResult?.find(x => x.stepAlias === 'MGAF')?.perilValue;
    const empatfs = raterResult?.find(x => x.stepAlias === 'EMPATFSur')?.perilValue;
    const figaAssessment = raterResult?.find(x => x.stepAlias === 'FigaAssessment')?.perilValue;
    const figa2ndAssessment = raterResult?.find(x => x.stepAlias === 'Figa2ndAssessment')?.perilValue;
    const figa3rdAssessment = raterResult?.find(x => x.stepAlias === 'Figa3rdAssessment')?.perilValue;
    const premiumTaxDiscount = raterResult?.find(x => x.stepAlias === Rater.premTaxDisc)?.perilValue;

    this.bindAndIssueData.updatedPayPlan = updatedPayPlan;

    const transactionDetails = [{
      Amount: Number(totalPremiumWithAdj == null ? 0 : totalPremiumWithAdj),
      AmountSubType: {
        Id: 'TotalPremiumWithAdj'
      }
    },
    {
      Amount: Number(mgaFee == null ? 0 : mgaFee),
      AmountSubType: {
        Id: 'MGAFee'
      }
    },
    {
      Amount: Number(empatfs == null ? 0 : empatfs),
      AmountSubType: {
        Id: 'EMPATFS'
      }
    },
    {
      Amount: Number(figaAssessment == null ? 0 : figaAssessment),
      AmountSubType: {
        Id: 'FigaAssessment'
      }
    },
    {
      Amount: Number(figa2ndAssessment == null ? 0 : figa2ndAssessment),
      AmountSubType: {
        Id: 'Figa2ndAssessment'
      }
    },
    {
      Amount: Number(figa3rdAssessment == null ? 0 : figa3rdAssessment),
      AmountSubType: {
        Id: 'Figa3rdAssessment'
      }
    },
    {
      Amount: Number(premiumTaxDiscount == null ? 0 : premiumTaxDiscount),
      AmountSubType: {
        Id: Rater.premTaxDisc
      }
    }];

    const transactionDetailsPayload: RnBillingSummaryTransactionDetails[] = transactionDetails;
    const riskId = this.entityRiskData.getRiskId();

    if (riskId) {
      this.store.dispatch(updateEntityIsLoadingFromPolicyBillingData({ isLoading: true }));
      this.submissionService.getData(riskId, riskDetailId).pipe(takeUntil(this.stop$)).subscribe(res1 => {
        if (res1) {
          const payPlanPayload = updatedPayPlan ? updatedPayPlan : res1.risks[0]?.riskDetails[0]?.riskBinds[0]?.riskBindBilling.payPlanOption;
          const payload: RenewalBillingSummaryRequestDTO = {
            ExpiringPolicyRiskId: this.entityRiskData?.EntityRisk?.risks[0]?.renewalFromRiskId,
            RenewalPolicyRiskId: riskId,
            RenewalPolicyEffectiveDate: this.entityRiskData?.EntityRisk?.risks[0]?.renewalEffectiveDate,
            PaymentPlan: this.LvPayPlanOptions.find(x => x.code === payPlanPayload)?.billingCode,
            TransactionDetails: transactionDetailsPayload,
            AppId: 'CENT'
          };
          this.bindAndIssueData.populateBindAndIssue(res1);
          this.store.dispatch(updateEntityIsLoadingFromPolicyBillingData({ isLoading: false }));
          Utils.unblockUI();
          this.subscribeToEntityFromPolicyBillingData(payload);
        }
      });
    }
  }

  subscribeToEntityFromPolicyBillingData(payload): void {
    this.selectRaterIsLoading$.pipe(takeUntil(this.stop$)).subscribe(result => {
    if (this.renewalBillingSummarySubscription) {
      this.renewalBillingSummarySubscription.unsubscribe();
    }

    this.renewalBillingSummarySubscription = this.billingService.postRenewalBillingSummary(payload).pipe(takeUntil(this.stop$)).subscribe(res2 => {
      if (res2) {
        this.rnBillingSummary = res2;
        this.rnInstallmentSchedules = res2.installmentSchedule.map(schedule => {
          if (schedule.billDate) {
            schedule.billDate = this.entityRiskData.convertUTCDateToLocalDate(schedule.billDate);
          }
          return schedule;
        });
        this.rnPaymentAccounts = res2.paymentAccount ? res2.paymentAccount.map(val => {
          val.accountTypeDisplay = this.recurringPaymentMethods.find(method => method.instrumentTypeId === val.instrumentTypeId).display;
          return val;
        }) : [];
        this.previousBalance = res2.installmentSchedule[0]?.balance;
        this.bindAndIssueData.totalChargeAmountComputation(res2);
      }
    });
    });
  }

  savePaymentProfile(riskId: string, billingForm: FormGroup): void {
    Utils.blockUI();

    const creditCardPayload: SavePaymentProfileCreditCard = {
      cardCode: billingForm.get('ccCVV').value,
      cardNumber: billingForm.get('ccNumber').value,
      expirationDate: `${billingForm.get('ccExpirationYear').value}-${FormUtils.padLeadingZeros(billingForm.get('ccExpirationMonth').value, 2)}`,
      cardType: this.getCreditCardTypeBillingCode(billingForm.get('ccType').value)
    };

    const billingAddressPayload: SavePaymentProfileBillingAddress = {
      firstName: billingForm.get('firstName').value,
      lastName: billingForm.get('lastName').value,
      address: billingForm.get('address').value,
      city: billingForm.get('city').value,
      zip: billingForm.get('zipCode').value,
      email: billingForm.get('emailAddress').value,
      state: billingForm.get('state').value
    };

    const billingDetailPayload: SavePaymentProfileBillingDetail = {
      creditCard: creditCardPayload,
      bankAccount: billingForm.get('accountNumber').value,
      billingAddress: billingAddressPayload
    };

    const paymentAccountPayload: SavePaymentProfilePaymentAccount = {
      instrumentTypeId: this.recurringPaymentMethods.find(x => x.display === 'Credit Card').instrumentTypeId,
      isDefault: true,
      billingDetail: billingDetailPayload,
    };

    const paymentProfilePayload: SavePaymentProfileRequestDTO = {
      riskId: riskId,
      firstName: billingForm.get('firstName').value,
      lastName: billingForm.get('lastName').value,
      email: billingForm.get('emailAddress').value,
      isRecurringPayment: billingForm.get('updateRecurringPayment').value,
      paymentAccount: paymentAccountPayload,
      appId: 'CENT'
    };

    this.billingService.postSavePaymentProfile(paymentProfilePayload).subscribe(result => {
      this.rnPaymentAccounts = result.paymentAccounts;
      this.resetPaymentDetails(billingForm);
      Utils.unblockUI();
    }, err => {
      Utils.unblockUI();
      NotifUtils.showError(err.error?.message);
    });
  }

  resetPaymentDetails(billingForm: FormGroup): void {
    const paymentDetailsFormControls = ['updateRecurringPayment', 'ccCVV', 'ccExpirationMonth', 'ccNumber', 'ccExpirationYear',
      'ccType', 'routingNumber', 'accountNumber', 'accountType', 'nameOnAccount'];

    FormUtils.resetFormControls(billingForm, paymentDetailsFormControls);
    FormUtils.markAsTouchedFormControls(billingForm, paymentDetailsFormControls);
  }

  onUploadClick(): void {
    this.modalRef = this.openModal(DocumentUploadModalComponent, {
      title: PolicyBillingLabelsConstants.uploadCheckImageHeader,
      hideCategoryField: true,
      isUploadCheckImage: true
    });

    this.modalRef.content.event.pipe(takeUntil(this.stop$)).subscribe((res) => {
      if (Boolean(/[#%]+/.test(res.data.documentFile.name))) {
        NotifUtils.showError(GenericConstants.fileValidationMessage.invalidFileName);
        return;
      }

      this.uploadCheckImage(new UploadCheckImageRequestDTO({
        riskId: this.isPaymentFromRenewalSubmission ? this.entityRiskData.getExpiringRiskIdFromSubmission() : this.makePaymentData?.riskIdData ?? this.entityRiskData.getRiskId(),
        file: res.data.documentFile,
        fileName: res.data.documentFile.name,
        filePath: res.data.documentFile.name
      }));
    });
  }

  uploadCheckImage(request: UploadCheckImageRequestDTO): void {
    Utils.blockUI();
    this.billingService.addCheckImage(request).pipe(takeUntil(this.stop$)).subscribe(res => {
      this.uploadedCheckImage = new UploadCheckImageResponsetDTO({
        checkImageCloudId: res.checkImageCloudId,
        filePath: res.filePath,
        isUploaded: res.isUploaded
      });
      Utils.unblockUI();
    }, err => {
      Utils.unblockUI();
      NotifUtils.showMultiLineError(err.error);
    });
  }

  onViewClick(): void {
    this.policyService.generateSASURL(this.uploadedCheckImage.filePath).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
      window.open(resultSASURL, '_blank');
    });
  }

  protected openModal(component: any, initialState: any): BsModalRef {
    return this.modalService.show(component, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  resetCheckImageModel(): void {
    this.uploadedCheckImage = new UploadCheckImageResponsetDTO({
      checkImageCloudId: '',
      filePath: '',
      isUploaded: false
    });
  }

  setValidationForCheck(isRefund: boolean = false): void {
    const checkPaymentForm = this.postPaymentForm.get('check');

    if (this.postPaymentForm.get('paymentMethod').value !== PaymentMethod.CashCheck || isRefund) {
      this.resetCheckNumberAndImageSetup();
      return;
    }

    this.postPaymentForm.get('checkNumber').setValidators([Validators.required]);
    this.postPaymentForm.get('checkNumber').updateValueAndValidity();

    if (checkPaymentForm.get('isIndividual').value) {
      this.resetValidator(checkPaymentForm, 'firstName', [Validators.required, isRefund ? Validators.maxLength(50) : Validators.maxLength(32)]);
      this.resetValidator(checkPaymentForm, 'lastName', [Validators.required, isRefund ? Validators.maxLength(50) : Validators.maxLength(31)]);
    } else {
      this.resetValidator(checkPaymentForm, 'name', [Validators.required, Validators.maxLength(64)]);
    }
  }

  get isPolicyCancelled(): boolean {
    return Boolean(this.makePaymentData?.riskStatusCode) ?
      this.makePaymentData.riskStatusCode === StatusConstants.cancelled && this.makePaymentData?.renewalCode !== AutoReinstatementConstants.reinstateStatus.RWNT :
      this.entityRiskData.getRisk().riskStatusCode === StatusConstants.cancelled && this.entityRiskData.getRisk()?.renewalCode !== AutoReinstatementConstants.reinstateStatus.RWNT;
  }

  get hasRenewalNotTaken(): boolean {
    return this.entityRiskData.getRisk() ? this.entityRiskData.getRisk()?.renewalCode === AutoReinstatementConstants.reinstateStatus.RWNT
      : this.makePaymentData.renewalCode === AutoReinstatementConstants.reinstateStatus.RWNT;
  }

  validateRefundAddressCount(address: string, address2: string, editRefundfModalForm?: FormGroup) {
    let newAddressValWithSpaces: string;
    let newAddressValWithOutSpaces: string;
    let aptOrUnitValWithSpaces: string;
    let aptOrUnitValWithOutSpaces: string;

    if (address.includes(' ')) {
      let position = 0;
      const wordsList = address.split(' ');

      const wordDetails = wordsList.map((word, index) => {
        position = index && wordsList[index - 1].length + position + 1;
        return { word, position };
      });

      const filteredWordDetailsForAddress = wordDetails.filter(x => x.position <= 32);
      //remove last element/wordDetails that includes position 32 in filteredWordDetailsForAddress
      const wordDetailsWithPosition32 = filteredWordDetailsForAddress.pop();
      newAddressValWithSpaces = filteredWordDetailsForAddress.map(x => x.word).toString().replace(/,/g, ' ');

      const filteredWordDetailsForAptOrUnit = wordDetails.filter(x => x.position > 32);
      //add wordDetailsWithPosition32 into beginning of filteredWordDetailsForAptOrUnit
      filteredWordDetailsForAptOrUnit.unshift(wordDetailsWithPosition32);
      aptOrUnitValWithSpaces = `${filteredWordDetailsForAptOrUnit.map(x => x.word).toString().replace(/,/g, ' ')} ${address2}`;
    } else {
      newAddressValWithOutSpaces = address.slice(0, 32);
      aptOrUnitValWithOutSpaces = `${address.slice(32, 64)} ${address2}`;
    }

    if (this.postPaymentForm) {
      const paymentForm: FormGroup = this.getCurrentPaymentForm(this.postPaymentForm);
      paymentForm.get('address').setValue(newAddressValWithSpaces ?? newAddressValWithOutSpaces);
      paymentForm.get('aptOrUnit').setValue(aptOrUnitValWithSpaces?.slice(0, 32) ?? aptOrUnitValWithOutSpaces?.slice(0, 32));
    } else {
      editRefundfModalForm.get('address').setValue(newAddressValWithSpaces ?? newAddressValWithOutSpaces);
      editRefundfModalForm.get('aptOrUnit').setValue(aptOrUnitValWithSpaces?.slice(0, 32) ?? aptOrUnitValWithOutSpaces?.slice(0, 32));
    }
  }

  getNameFieldsForRefund(): string[] {
    const refundTo = this.postPaymentForm.get('refundTo').value;

    return refundTo === RefundRelatedConstants.refundTo.Insured || refundTo === RefundRelatedConstants.refundTo.Other ?
            RefundRelatedConstants.refundFieldsList.FullName :
            RefundRelatedConstants.refundFieldsList.Name;
  }

  getNameFieldsForReturnSuspense(isIndividual: boolean): string[] {
    return isIndividual ? RefundRelatedConstants.refundFieldsList.FullName : RefundRelatedConstants.refundFieldsList.Name;
  }

  checkIfNameExceededMaxLength(form: AbstractControl, nameFields: string[]): void {
    let nameLength = 0;

    nameFields.forEach(field => {
      const length = form.get(field)?.value?.length;
      nameLength+=length;
    });

    if (nameLength > 64) {
      this.setMaxLengthErrorForFields(form, nameFields);
      this.refundRequestData.nameOrAddressExceededMaxLength = true;
    } else {
      this.removeMaxLengthErrorFromFields(form, nameFields);
      this.refundRequestData.nameOrAddressExceededMaxLength = false;
    };
  }

  setMaxLengthErrorForFields(form: AbstractControl, fieldNames: string[]): void {
    fieldNames.forEach(field => {
      const control =  form.get(field);

      if (control.value) {
        control.setErrors({
          maxlength: true
        });
        control.markAsTouched();
      }
    });
    form.updateValueAndValidity();
  }

  removeMaxLengthErrorFromFields(form: AbstractControl, fieldNames: string[]): void {
    fieldNames.forEach(field => {
      const control =  form.get(field);

      if(control.hasError('maxlength')) {
        control.setErrors(null);
        control.markAsTouched();;
      }
    });
    form.updateValueAndValidity();
  }

  enableNameFields(form: AbstractControl, fieldsNames: string[]): void {
    fieldsNames.forEach(fieldName => {
      form.get(fieldName).enable();
    });
  }

  getNameLength(isIndividual: boolean ): number {
    const checkPaymentForm = this.postPaymentForm.get('check');
    const nameLength = checkPaymentForm.get('name')?.value?.length;
    const fullNameLength = checkPaymentForm.get('firstName')?.value?.length +
                          checkPaymentForm.get('lastName')?.value?.length +
                          checkPaymentForm.get('suffix')?.value?.length;

    return isIndividual ? fullNameLength : nameLength;
  }

  updateAllowRecurring(request: UpdateAllowRecurringRequestDto): void {
      Utils.blockUI();
      this.paymentProfileNotifier.next({ value: null, isCompleted: false, isError: false });
      this.billingService.putAllowRecurring(request).subscribe((result: any) => {
          this.paymentProfileNotifier.next({ value: result, isCompleted: true, isError: false });
          this.accountHolderForm.get('agreeEnrollAutoPay').setValue(request.isRecurringPayment);
          this.getRecurringPaymentDetails(request.riskId);
          Utils.unblockUI();
      }, err => {
          this.paymentProfileNotifier.next({ value: err.error, isCompleted: true, isError: true });
          Utils.unblockUI();
          NotifUtils.showError(JSON.stringify(err.error?.message), () => {
              this.accountHolderForm.get('agreeEnrollAutoPay').setValue(!request.isRecurringPayment);
          });
      });
  }

  private resetCheckNumberAndImageSetup() {
    this.resetCheckImageModel();
    this.postPaymentForm.get('checkNumber').setValue('');
    this.postPaymentForm.get('checkNumber').clearValidators();
    this.postPaymentForm.get('checkNumber').updateValueAndValidity();
  }

  calculateTotalPaymentWithCreditCardFee(amount: number): number {
    return amount == null || amount === 0 ? 0 :
      (amount + Number((amount * this.policyBillingConstants.creditCardFee).toFixed(2)));
  }

  setSelectedPayorOption(): void {
    this.riskInterestDetailsWithSelectedPayor = this.entityRiskData.EntityRisk.risks[0]?.riskDetails[0]?.riskInterests[0]?.riskInterestDetails?.find(x => x.isSelectedPayor === true);

      if (Boolean(this.riskInterestDetailsWithSelectedPayor)) {
      this.selectedPayorOption = this.riskInterestDetailsWithSelectedPayor.id;
      } else {
      this.selectedPayorOption = undefined;
      }
  }

   get isPolicy(): boolean {
    return this.summaryData.isPolicyPage(this.entityRiskData?.EntityRisk?.risks[0]?.id);
  }

  renewalRisk: RiskDTO2;
    getRenewalRiskForCommRate(isNotInRenewalPage: boolean): void {
        if (isNotInRenewalPage) {
            const expiringRiskId = this.entityRiskData?.EntityRisk?.risks[0]?.id ?? this.makePaymentData.riskIdData;
            this.billingService.getRenewalRiskDetails(expiringRiskId).subscribe(res => {
                this.renewalRisk = res;
            });
        }
    }

  




















  public checkIfOneAgreeCheckbox(agreeInsuredCheckbox?:boolean, agreeOneTimeAuthCheckbox?:boolean): void {
    this.hasOneAgreeCheckbox = (agreeInsuredCheckbox || agreeOneTimeAuthCheckbox);
  }

  public isInternalUser(userType: string): boolean {
    return userType.toLocaleLowerCase() === 'internal';
  }

  getCommissionRate(): void {
    const territoryCode = this.renewalRisk?.riskDetails[0]?.riskProperties[0]?.territoryCode ?? this.entityRiskData?.EntityRisk?.risks[0]?.riskDetails[0]?.riskProperties[0]?.territoryCode;
    const effectiveDate = this.renewalRisk?.riskDetails[0]?.effectiveDate ?? this.entityRiskData?.EntityRisk?.risks[0]?.riskDetails[0]?.effectiveDate;


    let riskId: string = null;
    let stateCode: string = null;
    let agencyId: string = null;
    let formType: string = null;

    riskId = this.renewalRisk?.id ?? this.entityRiskData?.EntityRisk?.risks[0]?.id;
    stateCode = this.renewalRisk?.riskStateCode ?? this.entityRiskData?.EntityRisk?.risks[0]?.riskStateCode;
    agencyId = this.renewalRisk?.agencyId ?? this.entityRiskData?.EntityRisk?.risks[0]?.agencyId;
    formType = this.renewalRisk?.formType ?? this.entityRiskData?.EntityRisk?.risks[0]?.formType;

    const formattedEffectiveDate = new DatePipe('en-US').transform(effectiveDate, 'MM-dd-yyyy');
    const systemDate = new DatePipe('en-US').transform(this.summaryData.authService.getCustomDate(), 'MM-dd-yyyy');

    const request: IGetAgencyPolicyCommissionRateRequestDTO = {
      riskId: riskId,
      stateCode: stateCode,
      agencyId: agencyId,
      effectiveDate: formattedEffectiveDate,
      systemDate: systemDate,
      transactionType: 'RN',
      territory: territoryCode,
      masterProgramId: this.summaryData.authService.getProgramTypeCodeViaStateAndFormType(stateCode, formType)
    };

    this.summaryData.authService.checkAgencyCommissionRate(request, true);
  }
}
