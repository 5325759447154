import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormTypeConstants } from '../../../../shared/constants/form-types.constants';
import { QuoteService } from '../../../../core/services/quote.service';
import { BaseClass } from '../../../../shared/base-class';
import { PolicySummaryData } from '../../data/policy-summary.data';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-property-characteristics',
  templateUrl: './property-characteristics.component.html',
  styleUrls: ['./property-characteristics.component.scss']
})
export class PropertyCharacteristicsComponent extends BaseClass implements OnInit {
  public isOpen: boolean = true;
  formType: string;
  formTypeConstants = FormTypeConstants;

  constructor(public policySummaryData: PolicySummaryData, protected quoteSevice: QuoteService) {
    super();
  }

  ngOnInit(): void {
    this.quoteSevice.formTypeObs$.pipe(takeUntil(this.stop$)).subscribe((formType) => this.formType = formType);
  }

  get PropertyCharacteristicsForm(): FormGroup {
    return this.policySummaryData.propertyCharacteristicsForm;
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }
}
