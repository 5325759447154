export const AutoReinstatementConstants = {
  reinstatementApprovalSection: {
    accordionLabel: 'Automated Reinstatement Approval',
    approveReinstatement: 'Approve Reinstatement',
    approvalExpirationDate: 'Approval Expiration Date',
    statementOfNoLossReceived: 'Statement of No Loss Received',
    paymentToReinstate: 'Payment Required to Reinstate',
    confirmationMessageHasReceived: 'Confirm that Statement Of No Loss is Received?',
    approverLabel: 'Approved By:',
    approveRenewalReinstatement: 'Approve Renewal Bind',
    paymentToRenewalReinstate: 'Payment Required to Bind',
  },
  errorMessage: {
    amountCannotExceedPayoffAmount: 'Amount cannot exceed payoff amount',
    genericRequiredErrorMessage: 'This is a required field.',
    invalidApprovalExpirationDate: 'Date must be between today and any future date',
    errorInReinstatementSection: 'Error in Reinstatement Approval Section',
    requirementsNotMet: 'Reinstatement requirements are not met',
    expiredApprovalExpirationDate: 'Approval Expiration Date is expired'
  },
  approvalStatus: {
    Expired: 'EX',
    Active: 'ACT'
  },
  submissionSubstringURL: 'submissions',
  policySubstringURL: 'policies',
  BillingReasonCode: 'BR',
  MP: {
    reinstate: 'Pay to Reinstate',
    balance: 'Pay Balance'
  },
  warningMessage: {
    reinstate: 'Not all requirements are met to reinstate the policy. Please contact your underwriter.',
    balance: 'Paying the account balance will not reinstate the policy',
    reminder: 'I understand that the policy will not reinstate until all requirements are met.',
    renewalReinstate: 'Not all requirements are met to reinstate the renewal. Please contact your underwriter.'
  },
  reinstateStatus: {
    RWNT: 'RWNT',
    RRW: 'RRW'
  },
  IAgree: 'I Agree'
};