import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../../core/services/auth.service';
import { TaskData } from 'app/modules/dashboard/data/task.data';
import { TaskLabelsConstants } from 'app/shared/constants/task.labels.constants';
import { UwDashboardConstants } from 'app/shared/constants/uw-dashboard.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { SearchPolicyComponent } from '../make-payment/search-policy-component/search-policy.component';
import { QuoteModalComponent } from '../quote-modal/quote-modal.component';
import { TaskModalComponent } from '../task-modal/task-modal.component';
import { ProcessEndorsementModalComponent } from '../process-endorsement-modal/process-endorsement-modal.component';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import Utils from 'app/shared/utilities/utils';
import { ThirdPartyDataService } from 'app/core/services/submission/third-party/third-party-data.service';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from 'app/shared/base-class';

@Component({
  selector: 'app-uw-buttons',
  templateUrl: './uw-buttons.component.html',
  styleUrls: ['./uw-buttons.component.scss'],
})
export class UwButtonsComponent extends BaseClass implements OnInit {
  public uwDashboardConstants = UwDashboardConstants;
  public taskLabelsConstants = TaskLabelsConstants;
  modalRef: BsModalRef | null;
  searchInput: string;

  constructor(
    private modalService: BsModalService,
    private router: Router,
    public taskData: TaskData,
    private thirdPartyDataService: ThirdPartyDataService) {
      super();
    }

  ngOnInit() {
  }

  showModal(): void {
    const initialState = {
      title: this.uwDashboardConstants.startNewQuoteModalTitle,
      modalData: null,
      isAddTaskModal: true
    };
    this.modalRef = this.modalService.show(QuoteModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  showAddTaskModal(): void {
    const initialState = {
      title: this.taskLabelsConstants.Task.Add.AddTaskTitle,
      isExternal: false
    };

    this.modalRef = this.modalService.show(TaskModalComponent, {
      initialState,
      class: 'modal-lg modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  showProcessEndorsementModal(): void {
    const initialState = {
      title: this.uwDashboardConstants.processEndorsement,
      isInternal: true
    };

    this.modalRef = this.modalService.show(ProcessEndorsementModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });

  }

  onSubmit(value: string): void {
    this.router.navigate(['/submissions/list'], { queryParams: { q: value?.toString()?.trim() } });
  }

  onMakePaymentClick(): void {
    const initialState = {
      title: this.uwDashboardConstants.makeAPaymentModalTitle,
    };
    this.modalRef = this.modalService.show(SearchPolicyComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  onFloodClick(): void {
    // todo
    Utils.blockUI();
    this.thirdPartyDataService.torrentPassthroughAccessRequest().pipe(takeUntil(this.stop$)).subscribe(result => {
      this.checkIfBridgeToTorrent(result.passthroughUrl);
      Utils.unblockUI();
    },
    error => {
      Utils.unblockUI();
      NotifUtils.showError(ErrorMessageConstant.TorrentFlood.torrentFailedMessage);
    });
  }

  checkIfBridgeToTorrent(token): void {
    switch (token) {
      case ErrorMessageConstant.TorrentFlood.unauthorizedLoginResponse:
      case ErrorMessageConstant.TorrentFlood.noUsernameTorrentResponse:
        window.open(token, '_blank');
        break;
      default:
        window.open(token, '_blank');
        break;
    }
  }
}
