import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { CommonService } from '../../common.service';
import { ApplicationInformationDTO } from '../../../../shared/models/data/dto/third-party/application-information.dto';
import { AllResponseDTO } from '../../../../shared/models/data/dto/third-party/all-response.dto';
import { ResidentialRequestDTO } from '../../../../shared/models/data/dto/third-party/residential-request.dto';
import { ResidentialValuationResponseDTO } from '../../../../shared/models/data/dto/third-party/residential-valuation-response.dto';
import { ResidentialResponseDTO } from '../../../../shared/models/data/dto/third-party/residential-response.dto';
import { Iso360PropertyDTO } from '../../../../shared/models/data/dto/quick-quote/risk-iso360-property.dto';
import { SaveIso360PropertyDTO } from '../../../../shared/models/data/dto/quick-quote/save-iso360-property.dto';
import { RiskClaimDTO } from '../../../../shared/models/data/dto/third-party/risk-claims.dto';
import { APlusClaimsRequestDTO } from '../../../../shared/models/data/dto/third-party/aplus-claims-request.dto';
import { AplusLastCallStatusDTO } from '../../../../shared/models/data/dto/third-party/aplus-last-call-status.dto';
import { RiskInterestDTO2 } from 'app/shared/models/data/dto/quick-quote/risk-interest.dto';
import { IAddressForCounty } from 'app/shared/models/data/dto/third-party/address-for-county.dto';
import { CreditScoreRiskRequestDTO } from 'app/shared/models/data/dto/third-party/credit-score-risk-request.dto';
import { CreditScoreRiskResponseDTO } from 'app/shared/models/data/dto/third-party/credit-score-risk-response.dto';
import { AirHazardRequestDTO } from 'app/shared/models/data/dto/third-party/air-hazard-request.dto';
import { AirHazardResponseDTO } from 'app/shared/models/data/dto/third-party/air-hazard-response.dto';
import { TorrentResponseDTO } from 'app/shared/models/data/dto/third-party/torrent-flood-quote.dto';
import { TorrentRequestDTO } from 'app/shared/models/data/dto/third-party/torrent-request.dto';
import { FloodAssistResponseDTO } from 'app/shared/models/data/dto/third-party/flood-assist-response.dto';
import { FloodAssistRequestDTO } from 'app/shared/models/data/dto/third-party/flood-assist-request.dto';
import { TorrentPassthroughRequestDTO } from 'app/shared/models/data/dto/third-party/torrent-passthrough-request.dto';
import { TorrentPurchasedDTO } from 'app/shared/models/data/dto/third-party/torrent-purchased.dto';
import { IBCEGSReport } from './ISO-Location/BCEGSReport.dto';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyDataService {
  constructor(public http: HttpClient,
    protected commonService: CommonService) { }

  getThirdPartyData(applicantInformation: ApplicationInformationDTO): Observable<AllResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/v1/third-party/prefill`, applicantInformation)
      .catch(this.commonService.handleObservableHttpError);
  }

  getCounties(addressForCounty: IAddressForCounty): Observable<string[]> {
    return this.http.post(`${environment.ApiUrl}/v1/third-party/getcounties`, addressForCounty)
      .catch(this.commonService.handleObservableHttpError);
  }

  saveIso360ValueData(iso360ValueData: SaveIso360PropertyDTO): Observable<SaveIso360PropertyDTO> {
    return this.http.post(`${environment.ApiUrl}/Submission/iso360property`, iso360ValueData)
      .catch(this.commonService.handleObservableHttpError);
  }

  iso360ValueRecalculateValuation(residentialIso360ValueRequest: ResidentialRequestDTO): Observable<any> {
    return this.http.post(`${environment.ApiUrl}/v1/property/recalculateValuation`, residentialIso360ValueRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  iso360ValueRecalculateValuationAsync(residentialIso360ValueRequest: ResidentialRequestDTO): Promise<any> {
    return this.http.post(`${environment.ApiUrl}/v1/property/recalculateValuation`, residentialIso360ValueRequest)
      .toPromise()
      .then(data => data)
      .catch(this.commonService.handleObservableHttpError);
  }

  iso360ValueEditExistingValuation(residentialIso360ValueRequest: ResidentialRequestDTO): Observable<ResidentialValuationResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/property/editExistingValuation`, residentialIso360ValueRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  iso360ValueGetValuation(residentialIso360ValueRequest: ResidentialRequestDTO): Observable<ResidentialResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/v1/property/getValuation`, residentialIso360ValueRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  getAPlusThirdPartyData(aPlusRequest: APlusClaimsRequestDTO): Observable<RiskClaimDTO[]> {
    return this.http.post(`${environment.ApiUrl}/aplus/v2/claims`, aPlusRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  postLastAPlusCallStatus(aPlusLastCallStatus: AplusLastCallStatusDTO): Observable<any> {
    return this.http.post(`${environment.ApiUrl}/aplus/update/lastcall/status`, aPlusLastCallStatus)
      .catch(this.commonService.handleObservableHttpError);
  }

  getLastAPlusCallStatus(aPlusLastCallStatus: AplusLastCallStatusDTO): Observable<any> {
    return this.http.post(`${environment.ApiUrl}/aplus/get/lastcall/status`, aPlusLastCallStatus)
      .catch(this.commonService.handleObservableHttpError);
  }

  postOrderCredit(request: CreditScoreRiskRequestDTO): Observable<CreditScoreRiskResponseDTO> {
    return this.http.post(`${environment.ApiLexisNexisDevUrl}/ncf/credit`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  recalculateValuationThenSave(riskId: string): Observable<ResidentialResponseDTO>{
    return this.http.post(`${environment.ApiUrl}/v1/residential/recalculateValuationThenSave/${riskId}`, {})
      .catch(this.commonService.handleObservableHttpError);
  }

  getRiskInterests(id: string, riskDetailId: string): Observable<RiskInterestDTO2[]> {
    const url = `${environment.ApiUrl}/Risk/interests/`;
    const paramData = new HttpParams().append('riskId', id).append('riskDetailId', riskDetailId);
    return this.http.get(url, { params: paramData })
      .catch(this.commonService.handleObservableHttpError);
  }

  hazardRequest(request: AirHazardRequestDTO): Observable<AirHazardResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/AirHazard/hazard`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  torrentQuoteRequest(request: TorrentRequestDTO): Observable<TorrentResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/TorrentFlood/quote`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  torrentPassthroughRequest(request: TorrentPassthroughRequestDTO): Observable<TorrentPurchasedDTO> {
    return this.http.post(`${environment.ApiUrl}/TorrentFlood/passthrough`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  torrentPassthroughAccessRequest(): Observable<TorrentPurchasedDTO> {
    return this.http.post(`${environment.ApiUrl}/TorrentFlood/passthroughaccess`, {})
      .catch(this.commonService.handleObservableHttpError);
  }

  floodAssistRequest(request: FloodAssistRequestDTO): Observable<FloodAssistResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/FloodAssist/property`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  calculateValuation(residentialIso360ValueRequest: ResidentialRequestDTO): Observable<any> {
    return this.http.post(`${environment.ApiUrl}/v1/property/calculateValuation`, residentialIso360ValueRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  getBCEGSRecords(addressForCounty: IAddressForCounty): Observable<IBCEGSReport> {
    return this.http.post(`${environment.ApiUrl}/v1/third-party/getbcegs`, addressForCounty)
      .catch(this.commonService.handleObservableHttpError);
  }

}
