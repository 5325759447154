export class PolicyCancellationDTO implements IPolicyCancellationDTO {
  cancelNonPay?: string | undefined;
  uwPeriod?: string | undefined;
  cancelWithinUWPeriod?: string | undefined;
  cancelOutsideUWPeriod?: string | undefined;
  nonRenewal?: string | undefined;
  effectiveDate?: string | undefined;
  uwPeriodDate?: string | undefined;
  cedBillingReasons?: string | undefined;
  cedUnderWritterPolicyWithinUWPeriod?: string | undefined;
  cedUnderWritterPolicyNotWithinUWPeriod?: string | undefined;
}

export interface IPolicyCancellationDTO {
  cancelNonPay?: string | undefined;
  uwPeriod?: string | undefined;
  cancelWithinUWPeriod?: string | undefined;
  cancelOutsideUWPeriod?: string | undefined;
  nonRenewal?: string | undefined;
  effectiveDate?: string | undefined;
  uwPeriodDate?: string | undefined;
  cedBillingReasons?: string | undefined;
  cedUnderWritterPolicyWithinUWPeriod?: string | undefined;
  cedUnderWritterPolicyNotWithinUWPeriod?: string | undefined;
}

export class CancellationDTO implements ICancellationDTO {
  riskId?: string | undefined;
  riskDetailId?: string | undefined;
  cancellationEffectiveDate?: string | undefined;
  cancellationTypeCode?: string | undefined;
  cancellationReasonTypeCode?: string | undefined;
  cancellationReason?: string | undefined;
}

export interface ICancellationDTO {
  riskId?: string | undefined;
  riskDetailId?: string | undefined;
  cancellationEffectiveDate?: string | undefined;
  cancellationTypeCode?: string | undefined;
  cancellationReasonTypeCode?: string | undefined;
  cancellationReason?: string | undefined;
}


export class CancellationResultDTO implements ICancellationResultDTO {
  riskId?: string | undefined;
  riskDetailId?: string | undefined;
  riskStatusCode?: string | undefined;
  riskSubStatusCode?: string | undefined;
  raterResults?: any;
  isPendingCancellation?: boolean;
}

export interface ICancellationResultDTO {
  riskId?: string | undefined;
  riskDetailId?: string | undefined;
  riskStatusCode?: string | undefined;
  riskSubStatusCode?: string | undefined;
  raterResults?: any;
  isPendingCancellation?: boolean;
}
