import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AccordionModule, AccordionConfig } from 'ngx-bootstrap/accordion';
import { SubmissionManagementRoutingModule } from './submission-management-routing.module';
import { AppSidebarModule, AppBreadcrumbModule } from '@coreui/angular';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTableModule } from 'angular2-datatable';
import { QuickQuoteModule } from './quick-quote/quick-quote.module';
import { SubmissionListComponent } from './submission/submission-list/submission-list.component';
import { SubmissionManagementComponent } from './submission-management.component';
import { SubmissionModule } from './submission/submission.module';
import { SharedModule } from '../../shared/shared.module';
import { ApplicantModule } from './applicant/applicant.module';
import { CoveragesData } from './data/coverages.data';
import { BindAndIssueData } from './data/bind-and-issue.data';
import { PropertyData } from './data/property.data';
import { QuickQuoteData } from './data/quick-quote.data';
import { ApplicantData } from './data/applicant.data';
import { SummaryData } from './data/summary.data';
import { SubmissionListData } from './data/submission-list.data';
import { ApplicantBusinessRules } from './business-rules/applicant.business-rules';
import { EndorsementsData } from './data/endorsements.data';
import { UwQuestionsData } from './data/uw-questions.data';
import { UwApprovalData } from './data/uw-approval.data';
import { InterestData } from './data/interest.data';
import { UWR } from '../../core/services/submission/uw-referrals/uwr.service';
import { ThirdPartyData } from './data/third-party.data';
import { ClaimsData } from './data/claims.data';
import { ZipData } from './data/zip.data';
import { EntityRiskData } from './data/entity-risk.data';
import { ZipcodesService } from '../../core/services/submission/zipcodes/zipcodes.service';
import { Iso360ValueMappingData } from './data/iso360value-mapping.data';
import { SubmissionNavSavingService } from '../../core/services/navigation/submission-nav-saving.service';
import { ClaimsSavingData } from './data/claims-saving.data';
import { InterestSavingData } from './data/interest-saving.data';
import { UwApprovalSavingData } from './data/uw-approval-saving.data';
import { QuickQuoteSavingData } from './data/quick-quote-saving.data';
import { EndorsementsSavingData } from './data/endorsements.saving.data';
import { PropertySavingData } from './data/property.saving.data';
import { UwQuestionsSavingData } from './data/uwquestions.saving.data';
import { ApplicantSavingData } from './data/applicant.saving.data';
import { SubmissionNavValidationService } from '../../core/services/navigation/submission-nav-validation.service';
import { GeneralValidationService } from '../../core/services/submission/validations/general-validation.service';
import { CoveragesValidationService } from '../../core/services/submission/validations/coverages-validation.service';
import { PropertyValidationService } from '../../core/services/submission/validations/property-validation.service';
import { ApplicantValidationService } from '../../core/services/submission/validations/applicant-validation.service';
import { UwQuestionsValidationService } from '../../core/services/submission/validations/uw-questions-validation.service';
import { InterestValidationService } from '../../core/services/submission/validations/interest-validation.service';
import { SubmissionService } from '../../core/services/submission/submission.service';
import { CoverageBusinessRules } from './business-rules/coverage.business-rules';
import { OfacSearchService } from '../../core/services/submission/third-party/ofac/ofac-search.service';
import { OfacService } from '../../core/services/submission/third-party/ofac/ofac.service';
import { OfacSearchData } from './data/ofac-search.data';
import { PolicySummaryData } from '../policy-management/data/policy-summary.data';
import { FormDocumentData } from 'app/core/services/submission/report/form-document-data.service';
import { FormDocumentService } from 'app/core/services/submission/report/form-document.service';
import { BindAndIssueSavingData } from 'app/modules/submission-management/data/bind-and-issue-saving.data';
import { BindAndIssueValidationService } from '../../core/services/submission/validations/bind-and-issue-validation.service';
import { QuickQuoteValidationService } from '../../core/services/submission/validations/quick-quote-validation.service';
import { PropertyBusinessRules } from './business-rules/property.business-rules';
import { AccessDenied001Guard } from 'app/core/guards/access-denied-001.guard';
import { RoofSurfacingPaymentScheduleBRULDatePipe } from 'app/shared/pipes/rsps-brul-date.pipe';
import { RenewalTermData } from './data/renewal-term.data';

@NgModule({
  declarations: [SubmissionListComponent, SubmissionManagementComponent],
  imports: [
    CommonModule,
    AccordionModule,
    DataTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    SubmissionManagementRoutingModule,
    AppSidebarModule,
    AppBreadcrumbModule.forRoot(),
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    ApplicantModule,
    QuickQuoteModule,
    SubmissionModule,
    SharedModule
  ],
  entryComponents: [],
  exports: [
    CommonModule,
    AccordionModule,
    DataTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    AppSidebarModule,
    AppBreadcrumbModule,
    PerfectScrollbarModule,
    BsDropdownModule,
    TabsModule,
    ChartsModule,
    ApplicantModule,
    QuickQuoteModule,
    SubmissionModule,
    SharedModule
  ],
  providers: [
    { provide: AccordionConfig, useValue: { closeOthers: true } },
    // BindAndIssueData,
    QuickQuoteData,
    SubmissionListData,
    ApplicantBusinessRules,
    ZipData,
    CurrencyPipe,
    ZipcodesService,
    Iso360ValueMappingData,
    SubmissionNavSavingService,
    SubmissionNavValidationService,
    ClaimsSavingData,
    InterestSavingData,
    UwApprovalSavingData,
    QuickQuoteSavingData,
    EndorsementsSavingData,
    PropertySavingData,
    UwQuestionsSavingData,
    ApplicantSavingData,
    GeneralValidationService,
    CoveragesValidationService,
    PropertyValidationService,
    ApplicantValidationService,
    UwQuestionsValidationService,
    InterestValidationService,
    BindAndIssueValidationService,
    QuickQuoteValidationService,
    SubmissionService,
    CoverageBusinessRules,
    OfacSearchService,
    OfacService,
    OfacSearchData,
    FormDocumentData,
    FormDocumentService,
    DatePipe,
    BindAndIssueSavingData,
    PropertyBusinessRules,
    AccessDenied001Guard,
    RoofSurfacingPaymentScheduleBRULDatePipe,
    RenewalTermData
  ],
})
export class SubmissionManagementModule { }
