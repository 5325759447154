import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../../../../core/services/auth.service';
import { BillingService } from '../../../../../../../app/core/services/billing/billing.service';
import { PolicyBillingData } from '../../../../../../../app/modules/policy-management/data/policy-billing.data';
import { BaseClass } from '../../../../../../../app/shared/base-class';
import { ErrorMessageConstant } from '../../../../../../../app/shared/constants/error-message.constants';
import { PolicyBillingLabelsConstants } from '../../../../../../../app/shared/constants/policy-billing.labels.constants';
import { PaymentMethod } from '../../../../../../../app/shared/enum/payment-method.enum';
import { BankAccountDTO } from '../../../../../../../app/shared/models/data/dto/billing/bank-account.dto';
import { BillingAddressDTO } from '../../../../../../../app/shared/models/data/dto/billing/billing-address.dto';
import { BillingDetailDTO } from '../../../../../../../app/shared/models/data/dto/billing/billing-detail.dto';
import { CreditCardDTO } from '../../../../../../../app/shared/models/data/dto/billing/credit-card.dto';
import { PaymentRequestDTO } from '../../../../../../../app/shared/models/data/dto/billing/payment-request.dto';
import { PaymentSummaryDTO } from '../../../../../../../app/shared/models/data/dto/billing/payment-summary.dto';
import NotifUtils from '../../../../../../../app/shared/utilities/notif-utils';
import Utils from '../../../../../../../app/shared/utilities/utils';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { BsModalRef } from 'ngx-bootstrap';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AllowedPaymentRangeDTO } from '../../../../../../../app/shared/models/data/dto/billing/allowed-payment-range.dto';
import { ZipCodeData } from '../../../../../../../app/modules/submission-management/data/zipcode.data';
import { PaymentProcedure } from '../../../../../../../app/shared/enum/payment-procedure.enum';
import { forkJoin, Observable } from 'rxjs';
import { GenericLabel } from '../../../../../../../app/shared/constants/generic.labels.constants';
import { PostPaymentCheckComponent } from '../../../../../../../app/modules/policy-management/policy-billing/payments/post-payments/post-payment-check/post-payment-check.component';
import { PostPaymentCreditCardComponent } from '../../../../../../../app/modules/policy-management/policy-billing/payments/post-payments/post-payment-credit-card/post-payment-credit-card.component';
import { PostPaymentEftComponent } from '../../../../../../../app/modules/policy-management/policy-billing/payments/post-payments/post-payment-eft/post-payment-eft.component';
import { AddressDTO3 } from '../../../../../../../app/shared/models/data/dto/quick-quote/entity-address.dto';
import { EntityRiskDTO } from '../../../../../../../app/shared/models/data/dto/quick-quote/entity-risk.dto';
import { AddressType } from '../../../../../../../app/shared/enum/address-type.enum';
import Swal from 'sweetalert2';
import { BillingLabelConstants } from '../../../../../../shared/constants/bind-and-issue.labels.constants';
import { AutoReinstatementConstants } from '../../../../../../../app/shared/constants/auto-reinstatement.constants';
import { Router } from '@angular/router';
import { PolicyService } from '../../../../../../../app/core/services/submission/policy.service';
import { TaskGenerationService } from '../../../../../../../app/core/services/submission/task-generation/task-generation.service';
import { AutoReinstatementData } from '../../../../../../../app/modules/policy-management/data/auto-reinstatement.data';
import { PolicySummaryData } from '../../../../../../../app/modules/policy-management/data/policy-summary.data';
import { EntityRiskData } from '../../../../../../../app/modules/submission-management/data/entity-risk.data';
import { SubmissionListData } from '../../../../../../../app/modules/submission-management/data/submission-list.data';
import { RiskUWApprovalDTO2 } from '../../../../../../../app/shared/models/data/dto/quick-quote/uw-approval.dto';
import { SuspendedPaymentDetailsDTO } from '../../../../../../../app/shared/models/data/dto/suspended-payment/suspended-payment-detail.dto';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';

@Component({
  selector: 'app-make-payment-modal',
  templateUrl: './make-payment-modal.component.html',
  styleUrls: ['./make-payment-modal.component.scss']
})
export class MakePaymentModalComponent extends BaseClass implements OnInit, OnDestroy {

  public event: EventEmitter<any> = new EventEmitter<any>();
  public ErrorMessageConstant = ErrorMessageConstant;
  public PostPaymentLabelConstants = PolicyBillingLabelsConstants.postPayment;
  public GenericLabel = GenericLabel;
  public BillingLabelConstants = BillingLabelConstants;
  public AutoReinstatementConstants = AutoReinstatementConstants;
  PaymentMethod = PaymentMethod;
  paymentProcedure: PaymentProcedure = PaymentProcedure.PostBasic;
  isFromReinstate: boolean = false;
  isForRenewalReinstatement: boolean = false;

  modalRef: BsModalRef | null;

  isRefund: boolean = false;
  isRefundRequest: boolean = false;
  isInternal: boolean;
  paymentMethods: any;
  refundToList: any[];
  datePipe: DatePipe;
  currencyInputMode = CurrencyMaskInputMode.NATURAL;
  datePickerDateOption: IAngularMyDpOptions;
  allowedPaymentRange: AllowedPaymentRangeDTO;
  suspendedPaymentsList: SuspendedPaymentDetailsDTO[];

  currentTermAmountToPay: number;
  balance: number;
  expiringRiskIdForRenewalPaymentInSubmission: string;
  expiringRiskIdForRenewalPaymentInExpiringPolicy: string;
  expiringRiskDetailIdForRenewalPaymentInSubmission: string;
  expiringRiskDetailIdForRenewalPaymentInExpiringPolicy: string;
  isMakeAPayment: boolean = false;
  IunderstandCheckbox: boolean = false;

  public MakePaymentLabelConstants = PolicyBillingLabelsConstants.makePayment;
  title: string;
  pastDueAmount: number;
  payOffAmount: number;
  isPolicyOnPendingCancellation: boolean;

  isPaymentForRenewal: boolean = false;

  submitClickCount: number = 0;


  @ViewChild('checkForm') checkFormRef: PostPaymentCheckComponent;
  @ViewChild('ccForm') ccFormRef: PostPaymentCreditCardComponent;
  @ViewChild('eftForm') eftFormRef: PostPaymentEftComponent;

  constructor(
    public bsModalRef: BsModalRef,
    public billingData: PolicyBillingData,
    private authService: AuthService,
    private billingService: BillingService,
    private zipCodeData: ZipCodeData,
    private entityRiskData: EntityRiskData,
    private policyService: PolicyService,
    private policySummaryData: PolicySummaryData,
    private submissionListData: SubmissionListData,
    private taskGenerationService: TaskGenerationService,
    private router: Router,
    public autoReinstatementData: AutoReinstatementData,
    private summaryData: SummaryData
  ) {
    super();
  }

  ngOnInit() {
    if (this.isForRenewalReinstatement) {
      this.getDetailsForReinstatement();
    }

    this.datePipe = new DatePipe('en-US');

    if (!this.billingData.postPaymentForm) {
      this.billingData.initPostPaymentForm();
    }

    this.billingData.paymentMethodChangedEvent.pipe(takeUntil(this.stop$)).subscribe(() => {
      this.prefillPayeeInfo();
    });

    if (this.billingData.isAccessedFromPortal) {
      this.getPaymentMethods();
    } else {
      this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
        this.isInternal = this.isInternalUser(userType);

        this.getPaymentMethods();
      });
    }

    this.billingData.postPaymentForm.get('amount').updateValueAndValidity();

    this.initForm();
    this.isCurrentOrRenewalCheckBoxChecked();
    this.subscribeToReinstatementPayments();
  }

  initForm(): void {
    this.datePickerDateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy'
    };

    this.billingData.enableCityFields();

    this.zipCodeData.checkCityList = [];
    this.zipCodeData.ccCityList = [];
    this.zipCodeData.eftCityList = [];

    const riskId = this.billingData.makePaymentData.riskIdData;
    const riskDetailIdAct = this.billingData.makePaymentData.riskDetails.filter(a => a.riskSubStatusCode === 'ACT')[0]?.id;

    this.billingData.checkRenewalDetails(riskId, riskDetailIdAct, false);
    this.populateFields();

    this.billingData.postPaymentForm.get('check').get('isIndividual').setValue(true);
  }

  populateFields(): void {
    this.billingData.postPaymentForm.reset();

    this.billingData.postPaymentForm.get('paymentMethod').enable();
    this.billingData.postPaymentForm.get('paymentMethod').setValue('');
    this.setAmountValidation();
    this.billingData.postPaymentForm.get('amount').updateValueAndValidity();

    this.billingData.postPaymentForm.get('postDate').setValue({ isRange: false, singleDate: { jsDate: this.billingData.auth.getCustomDate() } });

    this.balance = this.billingData.makePaymentData.amountDue >= 0 ? this.billingData.makePaymentData.amountDue : 0;

    if (!this.isRefund) {
      this.currentTermAmountToPay = Number(this.allowedPaymentRange?.minimumPaymentAmount);

      if (this.isFromReinstate && this.paymentProcedure !== PaymentProcedure.PayToReinstate) {
        this.currentTermAmountToPay = this.currentTermAmountToPay - this.allowedPaymentRange.paymentInSuspense;
      } else if (this.isPolicyOnPendingCancellation && this.paymentProcedure === PaymentProcedure.PostBasic) {
        this.currentTermAmountToPay = this.autoReinstatementData.requirementsMetDetails?.requiredAmount;
      }

      this.billingData.postPaymentForm.get('amount').setValue(this.isReinstatementCheckboxNeeded ? this.autoReinstatementData?.requirementsMetDetails?.requiredAmount : this.currentTermAmountToPay > 0 ? this.currentTermAmountToPay : 0);
    }

    this.billingData.postPaymentForm.get('check').get('isIndividual').setValue(true);
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  onPostPayment() {
    this.submitClickCount++;

    if (this.submitClickCount === 1) {
      this.processPostPayment();
     }
  }

  private processPostPayment(): void {
    if (this.billingData.isPolicyCancelled) {
      this.paymentProcedure = this.autoReinstatementData.isPaymentForMinimumDueOnly(this.paymentProcedure, this.allowedPaymentRange?.minimumPaymentAmount, this.billingData.postPaymentForm?.get('amount').value) ?
        PaymentProcedure.PostBasic : PaymentProcedure.PayToReinstate;
    }
    const paymentRequest: PaymentRequestDTO = this.getPaymentRequest(this.paymentProcedure === PaymentProcedure.PostBasic);

    let paymentProcedure$: Observable<any>;
    this.paymentProcedure = this.billingData.postPaymentForm.get('balanceAmountCheckbox').value && this.paymentProcedure === PaymentProcedure.PayToReinstate ? PaymentProcedure.PayBalance : this.paymentProcedure;

    switch (this.paymentProcedure) {
      case PaymentProcedure.PostBasic:

        if (this.isPaymentForRenewal) {
          this.billingData.getCommissionRate();
          this.summaryData.authService.isDoneCommissionRateChecking$.pipe(takeUntil(this.stop$)).subscribe(() => {

            if (!this.authService.isCommissionRateError) {
              paymentRequest.commissionRate = Number(this.authService.commissionRateResponse.commissionRate);

              if (this.checkIfRWNTReinstatement) {
                if (this.isExpiredReinstatement()) {
                  //Prevent payment of reinstatement to force the user to edit reinstatement approval section
                  NotifUtils.showError(AutoReinstatementConstants.errorMessage.expiredApprovalExpirationDate);
                  return;
                } else {
                  paymentProcedure$ = this.autoReinstatementData.setPaymentProcedureForRenewalReinstatementForDashboardAndPortal(paymentRequest);
                }
              } else {
                paymentProcedure$ = this.billingService.postPaymentRequest(paymentRequest);
              }

              Utils.blockUI();
              paymentProcedure$.pipe(takeUntil(this.stop$)).subscribe(result => {
                Utils.unblockUI();
                this.generateTasksForRenewedPolicy();

                const options: any = {
                  icon: 'success',
                  allowOutsideClick: false,
                  text: PolicyBillingLabelsConstants.successPayment
                };

                NotifUtils.showSuccess(options, () => this.showPaymentReceipt(result.paymentReceiptEmailBody));
              },
                err => {
                  Utils.unblockUI();
                  NotifUtils.showError(err.error?.message, () => {
                    this.hideModal();
                  });
                }
              );
            } else {
              this.bsModalRef.hide();
            }
          });
        } else {
          paymentProcedure$ = this.billingService.postPaymentRequest(paymentRequest);
        }
        break;
      case PaymentProcedure.PayBalance:
        paymentProcedure$ = this.billingService.payBalance(paymentRequest);
        break;
      case PaymentProcedure.PayToReinstate:
        if (this.isExpiredReinstatement() && this.billingData.postPaymentForm.get('reinstateAmountCheckbox').value) {
          //Prevent payment of reinstatement to force the user to edit reinstatement approval section
          NotifUtils.showError(AutoReinstatementConstants.errorMessage.expiredApprovalExpirationDate);
          return;
        }
        paymentProcedure$ = this.billingService.payToReinstate(paymentRequest);
        break;
      default:
    }

    if (paymentProcedure$ && !this.isPaymentForRenewal) {
      Utils.blockUI();
      paymentProcedure$.pipe(takeUntil(this.stop$)).subscribe(result => {
        Utils.unblockUI();
        this.generateTasksForRenewedPolicy();

        const options: any = {
          icon: 'success',
          allowOutsideClick: false,
          text: PolicyBillingLabelsConstants.successPayment
        };

        NotifUtils.showSuccess(options, () => this.showPaymentReceipt(result.paymentReceiptEmailBody));
      },
        err => {
          Utils.unblockUI();
          NotifUtils.showError(err.error?.message, () => {
            this.hideModal();
          });
        }
      );
    }

  }

  private showPaymentReceipt(emailBody: string): void {
    if (emailBody) {
      Swal.fire(
        {
          customClass: {
            content: 'email-receipt-content-style',
          },
          width: 900,
          html: emailBody,
          showCloseButton: true,
          showConfirmButton: false,
          focusConfirm: false,
        });
    }
  }

  getPaymentMethods(): void {
    const paymentMethods = this.billingData.paymentMethodsPostPayment.
      filter(x => !x.recurringMethod);

    if (!this.isInternal) {
      this.paymentMethods = paymentMethods.filter(x => x.code !== PaymentMethod.CashCheck);
    } else {
      this.paymentMethods = paymentMethods;
    }
  }

  public isInternalUser(userType: string): boolean {
    return userType.toLocaleLowerCase() === 'internal';
  }

  private getPaymentRequest(isPostBasic: boolean): PaymentRequestDTO {

    const billingMethod = this.billingData.postPaymentForm.get('paymentMethod').value;
    const instrumentType: string = this.billingData.paymentMethodsPostPayment.filter(x => x.code === billingMethod)[0].billingCode;
    const amount: number = this.billingData.postPaymentForm.get('amount').value;
    const roundedAmount = Math.round((amount + Number.EPSILON) * 100) / 100;

    const paymentSummary: PaymentSummaryDTO = {
      amount: roundedAmount,
      effectiveDate: this.datePipe.transform(this.billingData.postPaymentForm.get('postDate').value.singleDate.jsDate, 'yyyy-MM-dd'),
      instrumentId: instrumentType,
    };

    if (billingMethod === PaymentMethod.RecurringCreditCard || billingMethod === PaymentMethod.RecurringECheck) {
      paymentSummary.isRecurringPayment = this.billingData.postPaymentForm.get('agreeEnrollAutoPay').value ?? false;
    } else if (billingMethod === PaymentMethod.CreditCard || billingMethod === PaymentMethod.EFT || billingMethod === PaymentMethod.ECheck) {
      paymentSummary.isAgreeOneTimePayment = this.billingData.postPaymentForm.get('agreeOneTimePayment').value ?? false;
    }

    if (billingMethod === PaymentMethod.RecurringCreditCard || billingMethod === PaymentMethod.RecurringECheck ||
      billingMethod === PaymentMethod.CreditCard || billingMethod === PaymentMethod.EFT || billingMethod === PaymentMethod.ECheck) {
      paymentSummary.isAgreeInsured = this.billingData.postPaymentForm.get('agreeInsured').value ?? false;
    }

    const billingDetail: BillingDetailDTO = new BillingDetailDTO();

    if (billingMethod === PaymentMethod.CreditCard || billingMethod === PaymentMethod.RecurringCreditCard) {
      const ccForm = this.billingData.postPaymentForm.get('creditCard');
      const ccType = ccForm.get('creditCardTypeId').value;
      const ccTypeBilling = this.billingData.getCreditCardTypeBillingCode(ccType);

      const creditCardDetail: CreditCardDTO = {
        cardCode: ccForm.get('cardCode').value,
        cardNumber: ccForm.get('cardNumber').value,
        expirationDate: `${ccForm.get('expirationYear').value}-${('00' + ccForm.get('expirationMonth').value).slice(-2)}`,
        cardType: ccTypeBilling
      };

      billingDetail.creditCard = creditCardDetail;
      billingDetail.billingAddress = this.getBillingAddress(ccForm);

      paymentSummary.email = ccForm.get('email').value;
    } else if (billingMethod === PaymentMethod.EFT || billingMethod === PaymentMethod.RecurringECheck) {
      const eftForm = this.billingData.postPaymentForm.get('eCheck');

      const accountType: string = eftForm.get('accountType').value;
      const accountTypeBilling: string = this.billingData.getAccountTypeBillingCode(accountType);

      const bankAccount: BankAccountDTO = {
        accountNumber: eftForm.get('accountNumber').value,
        accountType: accountTypeBilling,
        nameOnAccount: eftForm.get('nameOnAccount').value,
        routingNumber: eftForm.get('routingNumber').value,
      };

      billingDetail.bankAccount = bankAccount;
      billingDetail.billingAddress = this.getBillingAddress(eftForm);

      paymentSummary.email = eftForm.get('email').value;
    } else if (billingMethod === PaymentMethod.CashCheck) {
      const cashForm = this.billingData.postPaymentForm.get('check');
      billingDetail.billingAddress = this.getBillingAddress(cashForm);
    }

    paymentSummary.checkNumber = this.billingData.postPaymentForm.get('checkNumber')?.value ?? '';
    paymentSummary.imageUrl = this.billingData.uploadedCheckImage?.filePath ?? '';

    const isCurrentAndRenewalPayment = isPostBasic && (this.billingData.postPaymentForm.get('currentAndRenewalTermCheckbox').value ?? false);

    const paymentRequest: PaymentRequestDTO = {
      riskId: this.billingData.makePaymentData.riskIdData,
      riskDetailId: this.billingData.makePaymentData.riskDetails.filter(a => a.riskSubStatusCode === 'ACT')[0]?.id,
      paymentSummary: paymentSummary,
      billingDetail: billingDetail,
      postSuspendedPayments: this.isFromReinstate,
      isFromInsuredPaymentPortal: false,
      isForCurrentAndRenewal: isCurrentAndRenewalPayment,
      isForReinstatementAndRequirementsMet: this.setLogicForAutoReinstatement(),
      isReinstatingByMakeAPayment: this.autoReinstatementData.setIsReinstatingByMakeAPayment(this.paymentProcedure === PaymentProcedure.PayToReinstate, this.billingData.postPaymentForm),
      commissionRate: 0
    };

    return paymentRequest;
  }

  private getBillingAddress(form: AbstractControl): BillingAddressDTO {
    const billingAddress: BillingAddressDTO = {
      firstName: form.get('firstName').value,
      lastName: form.get('lastName').value,
      address: form.get('address').value,
      city: form.get('city').value,
      zip: form.get('zip').value,
      state: form.get('state').value
    };

    if (form.get('email')) {
      billingAddress.email = form.get('email').value;
    }

    return billingAddress;
  }

  get isAmountZero(): boolean {
    return this.billingData.postPaymentForm.get('amount').value === 0;
  }

  get isAgreeInsuredChecked(): boolean {
    if (this.billingData.postPaymentForm.get('paymentMethod').value !== PaymentMethod.CashCheck) {
      return this.billingData.postPaymentForm.get('agreeInsured').value ?? false;
    } else {
      return true;
    }
  }

  prefillPayeeInfo(): void {
    const entity: EntityRiskDTO = this.billingData.makePaymentData.entity;
    let address: AddressDTO3;

    if (this.billingData.makePaymentData.entity.isMailingAddressSameInsured) {
      address = entity.entityAddresses.find(a => a.addressTypeCode === AddressType.Property)?.address;
    } else {
      address = entity.entityAddresses.find(a => a.addressTypeCode === AddressType.Mailing)?.address;
    }

    const paymentForm: FormGroup = this.billingData.getCurrentPaymentForm(this.billingData.postPaymentForm);

    if (paymentForm && !paymentForm.touched) {
      paymentForm.get('firstName').setValue(entity.firstName);
      paymentForm.get('lastName').setValue(entity.lastName);
      paymentForm.get('address').setValue(address.streetAddress1);
      paymentForm.get('zip').setValue(address.zipCode);

      const emailField = paymentForm.get('email');
      if (emailField) {
        emailField.setValue(entity.personalEmailAddress);
      }

      paymentForm.get('firstName').markAsTouched();

      if (this.billingData.isAccessedFromPortal) {
        paymentForm.get('city').setValue(address.city);
      } else {
        this.zipCodeData.getCityListCompleted$.pipe(take(1)).subscribe(() => {
          paymentForm.get('city').setValue(address.city);
        });
      }

      const isUSCountry = address?.countryCode === 'USA' || address?.countryCode === 'US';
      if (isUSCountry) {
        const paymentMethod = this.billingData.postPaymentForm.get('paymentMethod').value;
        if (this.checkFormRef && paymentMethod === PaymentMethod.CashCheck) {
          this.checkFormRef.setZipCode(isUSCountry);
        } else if (this.ccFormRef && (paymentMethod === PaymentMethod.CreditCard || paymentMethod === PaymentMethod.RecurringCreditCard)) {
          this.ccFormRef.setZipCode(isUSCountry);
        } else if (this.eftFormRef && (paymentMethod === PaymentMethod.EFT || paymentMethod === PaymentMethod.RecurringECheck)) {
          this.eftFormRef.setZipCode(isUSCountry);
        }
      } else {
        paymentForm.get('address').setValue('');
        paymentForm.get('city').setValue('');
        paymentForm.get('state').setValue('');
        paymentForm.get('zip').setValue('');
      }
    }

    this.billingData.hasOneAgreeCheckbox = false;
    
    const paymentMethod = this.billingData.postPaymentForm.get('paymentMethod').value;
    if (paymentMethod === PaymentMethod.CreditCard || paymentMethod === PaymentMethod.EFT){
      this.billingData.postPaymentForm.controls.agreeOneTimePayment.setValue(false);
    }
  }

  isPaymentMethodHidden(paymentMethodCode: string): boolean {
    const recurringPayments: string[] = [PaymentMethod.RecurringCreditCard, PaymentMethod.RecurringECheck];
    return recurringPayments.includes(paymentMethodCode) || (!this.authService.isInternalUser && paymentMethodCode === PaymentMethod.CashCheck);
  }

  isCurrentOrRenewalCheckBoxChecked() {
    const pastDueAmount = this.allowedPaymentRange.minimumPaymentAmount;
    const payOffAmount = this.allowedPaymentRange.maximumPaymentAmount;

    this.billingData.postPaymentForm.get('currentTermCheckbox').valueChanges.subscribe(res => {
      if (res) {
        this.isPaymentForRenewal = false;
        this.billingData.postPaymentForm.get('amount').setValidators([Validators.required, Validators.min(pastDueAmount), Validators.max(payOffAmount)]);
        this.billingData.postPaymentForm.get('currentAndRenewalTermCheckbox').setValue(false);
        this.billingData.postPaymentForm.get('amount').setValue(this.currentTermAmountToPay);
        this.billingData.postPaymentForm.get('amount').updateValueAndValidity();
        this.billingData.postPaymentForm.get('amount').enable();
      }

      if (this.isCheckBoxPaymentBothFalse) {
        this.billingData.postPaymentForm.get('amount').disable();
        this.billingData.postPaymentForm.get('amount').setValue(0);
        this.billingData.postPaymentForm.get('amount').markAsUntouched();
      }
    });

    this.billingData.postPaymentForm.get('currentAndRenewalTermCheckbox').valueChanges.subscribe(res => {
      if (res) {
        this.isPaymentForRenewal = res;
        if (this.checkIfRWNTReinstatement && !this.autoReinstatementData.requirementsMetDetails?.areRequirementsMetExceptPayment) {
          this.autoReinstatementData.notifyForRequirementsNotMet(AutoReinstatementConstants.warningMessage.renewalReinstate, this.billingData.postPaymentForm, 0, false, false);
        }

        const reinstatementOrRenewalDepositOnly = this.getRenewalOrReinstatementAmountOnly();

        this.billingData.postPaymentForm.get('amount').setValidators([
          Validators.required,
          Validators.min(this.paymentProcedure !== PaymentProcedure.PayToReinstate ? this.currentTermAmountToPay + reinstatementOrRenewalDepositOnly : this.currentTermAmountToPay + 1),
          Validators.max(
            this.paymentProcedure !== PaymentProcedure.PayToReinstate ?
              this.currentTermAmountToPay + this.billingData.renewalTotalPremium : this.billingData.renewalAmount + this.currentTermAmountToPay
          )
        ]);
        this.billingData.postPaymentForm.get('currentTermCheckbox').setValue(false);
        this.billingData.postPaymentForm.get('amount').setValue(this.currentTermAmountToPay + reinstatementOrRenewalDepositOnly);
        this.billingData.postPaymentForm.get('amount').updateValueAndValidity();
        this.billingData.postPaymentForm.get('amount').enable();
      }

      if (this.isCheckBoxPaymentBothFalse) {
        this.billingData.postPaymentForm.get('amount').disable();
        this.billingData.postPaymentForm.get('amount').setValue(0);
        this.billingData.postPaymentForm.get('amount').markAsUntouched();
      }
    });
  }

  get minimumPaymentAmount(): number {
    if (this.billingData.withRenewalOffered) {
      const currentAmountChecked = this.billingData.postPaymentForm.get('currentTermCheckbox').value;
      return currentAmountChecked ? this.currentTermAmountToPay : this.currentTermAmountToPay + this.getRenewalOrReinstatementAmountOnly();
    } else {
      return this.billingData.postPaymentForm.get('reinstateAmountCheckbox').value ? this.autoReinstatementData.getMinimumPaymentAmount(this.allowedPaymentRange?.minimumPaymentAmount) :
        this.billingData.postPaymentForm.get('balanceAmountCheckbox').value ? this.balance : this.allowedPaymentRange.minimumPaymentAmount;
    }
  }

  get maximumPaymentAmount(): number {
    const payOffAmount = this.allowedPaymentRange.maximumPaymentAmount;
    const rescindReinstatementAmount = this.autoReinstatementData.requirementsMetDetails?.areRequirementsMetExceptPayment ?
      this.allowedPaymentRange.maximumPaymentAmount + this.billingData?.renewalAmount :
      this.autoReinstatementData.requirementsMetDetails?.requiredAmount;

    if (this.billingData.withRenewalOffered) {
      const currentAmountChecked = this.billingData.postPaymentForm.get('currentTermCheckbox').value;
      return currentAmountChecked ? payOffAmount : this.paymentProcedure !== PaymentProcedure.PayToReinstate ? this.currentTermAmountToPay + this.billingData.renewalTotalPremium : this.billingData.renewalAmount + this.currentTermAmountToPay;
    } else {
      return this.autoReinstatementData.hasRescindedOffer ? rescindReinstatementAmount : this.allowedPaymentRange.maximumPaymentAmount;
    }
  }

  get isCheckBoxPaymentBothFalse(): boolean {
    const checkboxesFormControlFalseValue = !this.billingData.postPaymentForm.get('currentAndRenewalTermCheckbox').value && !this.billingData.postPaymentForm.get('currentTermCheckbox').value && !this.billingData.isPolicyCancelled;
    return checkboxesFormControlFalseValue && (this.billingData.withRenewalOffered || this.autoReinstatementData.hasRescindedOffer);
  }

  get isCheckImageNotUploaded(): boolean {
    return !Boolean(this.billingData.uploadedCheckImage?.isUploaded) && this.billingData.postPaymentForm.get('paymentMethod')?.value === PaymentMethod.CashCheck;
  }

  ngOnDestroy() {
    this.billingData.resetCheckImageModel();
  }

  get checkIfRWNTReinstatement(): boolean {
    return (this.billingData.hasRenewalNotTaken) ||
      (this.billingData.makePaymentData.renewalCode === AutoReinstatementConstants.reinstateStatus.RWNT);
  }

  private isExpiredReinstatement(): boolean {
    return this.autoReinstatementData.checkIsReinstatementExpired();
  }

  private setLogicForAutoReinstatement(): boolean {
    return this.autoReinstatementData.portalSetReinstatementAndRequirementsMetProperty(this.paymentProcedure, this.billingData.postPaymentForm, this.billingData.makePaymentData);
  }

  private generateTasksForRenewedPolicy(): void {
    if (!this.billingData.withRenewalOffered) {
      this.bsModalRef.hide();
      Utils.unblockUI();
      return;
    }
    // renewal risk info
    const renewalRiskId = this.billingData.makePaymentData?.renewalRiskIdData;
    const renewalRiskDetailId = this.billingData.makePaymentData?.renewalRiskDetailIdData;
    const renewalRiskPolicyNumber = this.billingData.makePaymentData?.renewalPolicyNumberData;
    const renewalEntityId = this.billingData.makePaymentData?.renewalRiskEntityIdData;

    Utils.blockUI();
    this.policyService.getPolicyUwApprovals(renewalRiskId, renewalRiskDetailId).pipe(takeUntil(this.stop$)).subscribe((result: RiskUWApprovalDTO2[]) => {
      if (!result) {
        Utils.unblockUI();
        this.bsModalRef.hide();
        return;
      };
      const renewalSubmissionUWR: string[] = result.map(uwr => {
        return uwr.uwApprovalDescription;
      });
      const issued = {
        policyNumber: renewalRiskPolicyNumber
      };

      this.taskGenerationService.triggerTaskMethods(issued, renewalSubmissionUWR, renewalEntityId);
      Utils.unblockUI();
      this.bsModalRef.hide();
    }, (err) => {
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify(err.error?.message));
      this.bsModalRef.hide();
    });
  }

  get isReinstatementRequirementsNotMet(): boolean {
    return this.autoReinstatementData.portalCheckIsReinstatementRequirementsNotMet(false, this.billingData.postPaymentForm, this.billingData.makePaymentData);
  }

  get isReinstatementCheckboxNeeded(): boolean {
    return this.autoReinstatementData.checkIsReinstatementCheckboxNeededForDashboardAndPortal(this.billingData.postPaymentForm, this.billingData?.makePaymentData);
  }

  private subscribeToReinstatementPayments(): void {
    this.autoReinstatementData.subscribePostPaymentReinstatementCheckboxes(this.billingData.postPaymentForm, this.balance);
  }

  setMinimumReinstateAmount(): number {
    return this.autoReinstatementData.requirementsMetDetails?.requiredAmount;
  }

  get isRenewalReinstatementRequirementsNotMet(): boolean {
    return this.autoReinstatementData.checkIsRenewalReinstatementRequirementsNotMet(false, this.billingData.postPaymentForm);
  }

  get hasReinstatementErrorMessage(): boolean {
    return (this.isReinstatementRequirementsNotMet && this.billingData.postPaymentForm.get('reinstateAmountCheckbox').value) || (this.isRenewalReinstatementRequirementsNotMet && this.billingData.postPaymentForm.get('currentAndRenewalTermCheckbox').value);
  }

  toggleIunderstandCheckbox(isChecked: boolean): void {
    this.IunderstandCheckbox = isChecked;
  }

  get shouldBeDisabled(): boolean {
    return this.billingData.postPaymentForm.invalid ||
      this.isAmountZero ||
      this.isCheckImageNotUploaded || this.isReinstatementCheckboxNeeded ||
      (this.billingData.withRenewalOffered ? this.isCheckBoxPaymentBothFalse : '') ||
      (this.hasReinstatementErrorMessage && !this.IunderstandCheckbox) ||
      !(this.billingData.hasOneAgreeCheckbox || this.isCashChecked);
  }

  private getDetailsForReinstatement(): void {
    this.autoReinstatementData.isApprovalRequired$.next(false);
    const billingDetails$ = this.billingService.getRenewalAutoReinstatementDetails(this.autoReinstatementData.getRenewalReinstatementPayloadForDashboardAndPortal()).pipe(filter(result => Boolean(result)));

    billingDetails$.pipe(
      tap(result => {
        this.autoReinstatementData.reinstatementView = result;
      }),
      switchMap(result => this.authService.getApproverDetails(result)))
      .subscribe(() => {
        this.autoReinstatementData.isApprovalRequired$.next(this.autoReinstatementData.reinstatementView.isApprovalRequired);
      }, err => {
        this.autoReinstatementData.isApprovalRequired$.next(false);
        NotifUtils.showError(JSON.stringify(err.error?.message) ?? 'Error in fetching approver details');
      });
  }

  setAmountValidation(): void {
      this.billingData.postPaymentForm.get('amount').setValidators([Validators.required,
      Validators.min(this.allowedPaymentRange.minimumPaymentAmount),
      Validators.max(this.allowedPaymentRange.maximumPaymentAmount)]);
  }

  /**
   * This method is used in the ff: reinstatement amount in HTML, computation for minimum amount validation, and setting the amount for renewal.
   * This should always be synced to other make-a-payment-modal and post-payment-modal component.
   */
  getRenewalOrReinstatementAmountOnly(): number {
    return this.autoReinstatementData.computeRenewalReinstatementAmountWithoutBalance(this.currentTermAmountToPay, this.billingData.renewalAmount);
  }

  get isCashChecked(): boolean {
    return this.billingData.postPaymentForm.get('paymentMethod').value === PaymentMethod.CashCheck;
  }
}
