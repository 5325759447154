export const RenewalConstants = {
    UWRCodesRenewal: {
        UWR8: 'BR-YRBUILTUP2020',
        UWR10: 'INE-COVA',
        UWR13: 'INE-DWELLING75YR',
        UWR19: 'INE-LIALOSS',
        UWR20: 'INE-UPTWOWATER',
        UWR21: 'INE-NOAIRCON',
        UWR28: 'INE-WATERLOSS10K',
        UWR49: 'INE-LACKHEATINGUPT',
        UWR50: 'INE-LACKHVACUPT',
        UWR51: 'INE-LACKPLUMUPT',
        UWR54: 'INE-METALUP30YR',
        UWR66: 'INE-PUBLICPROCLASS',
        UWR77: 'PBR-CHSFLOLN',
        UWR91: 'RF-ELEVCERT',
        UWR97: 'RF-TWOUPPRIORLOSS',
        UWR99: 'RF-WATERLOSS3YR',
        UWR101: 'RF-RENWALRULE'
    },
    county: {
        broward: 'broward',
        palmBeach: 'palm beach',
        miamiDade: 'miami-dade',
    },
    UWR19Condition: ['BINONAUTO', 'DAMAG', 'LIABILITY', 'MEDICAL', 'PLBI', 'PLPD', 'SLIP'],
    UWR19Dog: 'DOG',
    UWR54Condition: ['METC', 'META'],
    UWR66Condition: ['9', '10'],
    ClaimTypeWater: 'WATER',
    renewalReferralRemarks: 'Renewal Referral'
};