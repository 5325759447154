export class DocumentUpload {

    public id: string;

    public categoryId: string;

    public category: string;

    public description: string;

    public uploadedById: string;
    public uploadedBy: string;

    public confidential: boolean;
    public isDeleted: boolean;

    public riskId: string;

    public documentId: string;
    public document: Document;

    public fileName: string;
    public documentFile: string;

    public createdAt: Date;

    public constructor(init?: Partial<DocumentUpload>) {
        Object.assign(this, init);
    }
}