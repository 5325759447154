export class FloodDeductible {

  public tableName: string;
  public tableDescription: string;
  public code: string;
  public description: number;
  public isActive: boolean;

  static get FiveHundred(): FloodDeductible {
    return {
      tableName: 'LvFloodDeductible',
      tableDescription: 'Flood Deductible',
      code: 'FD1',
      description: 500,
      isActive: true
    };
  }

  static get OneThousand(): FloodDeductible {
    return {
      tableName: 'LvFloodDeductible',
      tableDescription: 'Flood Deductible',
      code: 'FD2',
      description: 1000,
      isActive: true
    };
  }

  static get TwoThousandFiveHundred(): FloodDeductible {
    return {
      tableName: 'LvFloodDeductible',
      tableDescription: 'Flood Deductible',
      code: 'FD3',
      description: 2500,
      isActive: true
    };
  }

  static get FiveThousand(): FloodDeductible {
    return {
      tableName: 'LvFloodDeductible',
      tableDescription: 'Flood Deductible',
      code: 'FD4',
      description: 5000,
      isActive: true
    };
  }

  static get SevenThousandFiveHundred(): FloodDeductible {
    return {
      tableName: 'LvFloodDeductible',
      tableDescription: 'Flood Deductible',
      code: 'FD5',
      description: 7500,
      isActive: true
    };
  }

  static get TenThousand(): FloodDeductible {
    return {
      tableName: 'LvFloodDeductible',
      tableDescription: 'Flood Deductible',
      code: 'FD6',
      description: 10000,
      isActive: true
    };
  }

  public constructor(init?: Partial<FloodDeductible>) {
    Object.assign(this, init);
  }
}