export const GenericLabel = {
    pleaseSelect: 'Please Select',
    blank: 'Blank',
    quickQuoteConfirmation: 'Please click Next Button on Quick Quote.',
    qqRequiredFieldsMessage: 'Please enter all required fields on Quick Quote.',
    qqInvalidFieldsMessage: 'There was an error with the information you provided. Please check.',
    orderPrefillButtonMessage: 'Please click Order Prefill Button on Quick Quote.',
    noDataTable: 'No data available to display.',
    addedSuccess: 'added successfully.',
    success: 'Success!',
    user: 'User',
    confirmMessage: 'Are you sure you want to save?',
    userTypeNameFound: 'User type is already existing',
    all: 'All',
    policyNumber: 'Policy #',
    quoteNumber: 'Quote #',
    insuredName: 'Insured Name',
    agencyBroker: 'Agency',
    inceptionDate: 'Effective Date',
    expirationDate: 'Expiration Date',
    status: 'Status',
    noResultsFound: 'No Results Found',
    formType: 'Form Type',
    sortBy: 'Sort By',
    subStatus: 'Sub-Status',
    effectiveDateFrom: 'Effective Date From:',
    effectiveDateTo: 'Effective Date To:',
    address: 'Address',
    stateCode: 'State Code',
    state: 'State',
    none: 'None'
};
