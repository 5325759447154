import { createSelector } from '@ngrx/store';
import { BaseState } from '../base.state';

export const selectSubmissionUWRsState = (state: BaseState) => state.submissionUWRs;

export const selectSubmissionUWRsIsLoading = createSelector(
  selectSubmissionUWRsState,
  (state) => state.isLoading
)

export const selectSubmissionUWRsList = createSelector(
  selectSubmissionUWRsState,
  (state) => state.list
)

export const selectSubmissionUWRsTableRows = createSelector(
  selectSubmissionUWRsState,
  (state) => state.tableRows
)