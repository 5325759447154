import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestComponent } from './request.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../../../shared/shared.module';
import { RefundRequestComponent } from './refund-request/refund-request.component';
import { EditRefundModalComponent } from './edit-refund-modal/edit-refund-modal.component';
import { DataTableModule } from 'angular2-datatable';
import { DataTablesModule } from 'angular-datatables';
import { PaginationModule } from 'ngx-bootstrap';
import { SearchFilterComponent } from './search-filter/search-filter.component';

const routes: Routes = [
  { path: '', component: RequestComponent }
];

@NgModule({
  declarations: [RequestComponent, RefundRequestComponent, EditRefundModalComponent, SearchFilterComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    DataTableModule,
    DataTablesModule,
    PaginationModule.forRoot()
  ],
  entryComponents: [ EditRefundModalComponent ]
})
export class RequestModule { }
