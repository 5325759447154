export enum HOPerilType {
  NHR = 'NHR',
  HUR = 'HUR',
  TOTAL = 'Total'
}

export enum DP3PerilType {
  FD = 'F-D',
  ECD = 'EC-D',
  HURD = 'HUR-D',
  FC = 'F-C',
  ECC = 'EC-C',
  HURC = 'HUR-C',
  ADDITIVE = 'ADDITIVE',
  SUBVALUE = 'SUBVALUE'
}

export enum LA_HOPerilType {
  OP = 'OP',
  NHRWH = 'NHRWH',
  HUR = 'HUR',
  ADDITIVE = 'ADDITIVE'
}

export enum LA_DP3PerilType {
  FD = 'F-D',
  OPD = 'OP-D',
  NHRWHD = 'NHRWH-D',
  HURD = 'HUR-D',
  FC = 'F-C',
  OPC = 'OP-C',
  NHRWHC = 'NHRWH-C',
  HURC = 'HUR-C',
  ADDITIVE = 'ADDITIVE',
  SUBVALUE = 'SUBVALUE'
}