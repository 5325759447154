import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent {
  @Input()
  label: string;
  @Input()
  readonly = false;
  @Input()
  name: string;
  @Input()
  id: string;
  @Input()
  checked = false;
  @Input()
  disabled = false;

  @Input()
  value: any;

  currentValue: Boolean;

  @Output()
  valueChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  selected(value): void {
    this.valueChange.emit(value);
  }
}
