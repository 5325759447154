export const ApplicantValidatorConstants = {
  nameMustBeIndividualsAndNotCorporationsErrorMessage: {
    key: 'nameMustBeIndividuals',
    value: 'Name must be a valid name and cannot contain company, corporation or trust.',
  },
  corporationNames: {
    coAbbreviation: 'CO',
    corpAbbreviation: 'CORP',
    llcAbbreviation: 'LLC',
    corporation: 'CORPORATION',
    company: 'COMPANY',
    trust: 'TRUST',
    compAbbreviation: 'COMP',
    incAbbreviation: 'INC'
  }
};