import { createSelector } from '@ngrx/store';
import { BaseState } from '../base.state';

export const selectMoratoriumState = (state: BaseState) => state.moratorium;

export const selectMoratoriumIsLoading = createSelector(
  selectMoratoriumState,
  (state) => state.isLoading
)

export const selectMoratoriumList = createSelector(
  selectMoratoriumState,
  (state) => state.list
)