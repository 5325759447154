export const EndorsementValidatorErrorConstants = {
  personalPropertyAtOtherResidencesLimitDivisibleErrorMessage: {
    key: 'personalPropertyAtOtherResidencesLimitDivisible',
    value: 'Limit must be in increments of S1,000.'
  },
  personalPropertyAtOtherResidencesLimitNotZeroErrorMessage: {
    key: 'personalPropertyAtOtherResidencesLimitZero',
    value: 'This cannot be zero.'
  },
  coverageCIncreasedAmountCannotBeBothZeroErrorMessage: {
    key: 'personalPropertyAtOtherResidencesLimitZero',
    value: 'Increased Amount cannot be both $0.'
  },
  limitedWaterDamageYear15: {
    key: 'limitedWaterDamageYear15',
    value: 'Limited Water Damage Endorsement must be attached'
  },
  nonStructuralHailLossLimitationIsMetal: {
    key: 'nonStructuralHailLossLimitationIsMetal',
    value: 'Non-Structural Hail Loss Limitation is required for metal roofs'
  },
  nonStructuralHailLossLimitationIsAluminum: {
    key: 'nonStructuralHailLossLimitationIsAluminum',
    value: 'Non-Structural Hail Loss Limitation is required for aluminum siding'
  },
  acvLossSettlementWindHailRoofBRUL322and323: {
    key: 'acvLossSettlementWindHailRoofBRUL322and323',
    value: 'ACV is required due to age of roof'
  },
  limitedWaterDamageYear50: {
    key: 'limitedWaterDamageYear50',
    value: 'Limited Water Damage Coverage Limit is required'
  },
  limitedWaterDamageYear40: {
    key: 'limitedWaterDamageYear40',
    value: 'Limited Water Damage Coverage Limit is required'
  },
  jewelryIncreaseAmountMustBeIncreamentOf100: {
    key: 'jewelryIncreaseAmountMustBeIncreamentOf100',
    value: 'Jewelry Increased Amount must be Increment of 100.'
  },
  limitCannotBeZero: {
    key: 'limitCannotBeZero',
    value: 'Limit cannot be zero.'
  }
};
