
export class SubmissionCopiedInformation {
    public firstName: string;
    public lastName: string;
    public middleName: string;
    public suffix: string;

    public propertyAddressLine1: string;
    public propertyAddressLine2: string;
    public propertyCity: string;
    public propertyState: string;
    public propertyZipCode: string;
    public county: string;

    public isMailingAddressSameInsured: boolean;

    public mailingAddressLine1: string;
    public mailingAddressLine2: string;
    public mailingCity: string;
    public mailingState: string;
    public mailingZipCode: string;
    public mailingCountry: string = 'USA';

    public emailAddress: string;
    public mobilePhoneNumber: string;
    public homePhoneNumber: string;

    public agencyId: string;
    public agentId: string;
    public subAgencyId: string;
    public insuranceName: string;
}

export class SubmissionCopiedAgencyInformation {
    public agencyId: string;
    public agentId: string;
    public subAgencyId: string;
    public insuranceName: string;
}
