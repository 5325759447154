import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  AfterContentInit,
  Input,
} from '@angular/core';
import { NavigationService } from '../../../core/services/navigation/navigation.service';
import { LayoutService } from '../../../core/services/layout/layout.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/services/auth.service';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { BaseClass } from 'app/shared/base-class';
import { takeUntil } from 'rxjs/operators';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { PathConstants } from 'app/shared/constants/path.constants';
import { createSubmissionDetailsMenuItems } from '../../../modules/submission-management/submission-management-navitems';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { SubmissionNavValidationService } from '../../../core/services/navigation/submission-nav-validation.service';
import { SubmissionPageData } from '../../../modules/submission-management/data/submission-page.data';
import { PropertyData } from '../../../modules/submission-management/data/property.data';
import { createPolicyDetailsMenuItems } from 'app/modules/policy-management/policy-management-navitems';
import { PolicyNavValidationService } from 'app/core/services/navigation/policy-nav-validation.service';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { UwDashboardConstants } from 'app/shared/constants/uw-dashboard.constants';
import { EntityRiskData } from '../../../modules/submission-management/data/entity-risk.data';
import { AgentService } from 'app/core/services/management/agent-service';
import Utils from 'app/shared/utilities/utils';
import { GenerateNachaRequestDTO } from 'app/shared/models/data/dto/nacha/nacha.dto';
import { NachaService } from 'app/core/services/nacha.service';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CommissionModalComponent } from '../commission-modal/commission-modal.component';
import { PolicyService } from 'app/core/services/submission/policy.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { CommissionPaymentModalComponent } from '../commission-payment-modal/commission-payment-modal.component';

type userTypes = 'external' | 'internal';

@Component({
  selector: 'app-header-admin',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseClass implements OnInit {
  @Input() leftSidebarAvailable: boolean = true;

  genericConstants = GenericConstants;
  public agentDashboardConstants = AgentDashboardConstants;
  public uwDashboardConstants = UwDashboardConstants;

  commissionModalRef: BsModalRef | null;

  uname: string;
  currentUserType: string;
  payments: string[] = [
    'Suspended Payments',
    'Refund Approval',
    'Batch Payment',
  ];
  bookTransfer: string = 'Book of Business Transfer';
  selectedLink: string;

  constructor(
    public layoutService: LayoutService,
    public navService: NavigationService,
    private authService: AuthService,
    public propertyData: PropertyData,
    public policyNavValidationService: PolicyNavValidationService,
    public policySummaryData: PolicySummaryData,
    private router: Router,
    private nachaService: NachaService,
    private modalService: BsModalService
  ) {
    super();
  }

  get isAccountantUser()  {
    return this.authService.IsAccountantUser();
  }

  get isUnderWriterUser() {
    return this.authService.IsUnderWriterUser();
  }

  ngOnInit(): void {
    const auth = localStorage.getItem(environment.AuthKey);
    if (JSON.parse(auth).token) { // to do expiration token if available
      const userInfo: string = atob(JSON.stringify(auth).split('.')[1]);
      this.authService.userType.next(JSON.parse(userInfo).internal ? 'internal' : 'external');
      localStorage.setItem('userType', JSON.parse(userInfo).internal ? 'internal' : 'external');
      this.callAgencyAPIs(userInfo);
    }

    this.uname = localStorage.getItem('uname');
    this.watchUserType();
  }

  callAgencyAPIs(userInfo): void {
    const parsedUserInfo = JSON.parse(userInfo);
    this.authService.getAgenciesById(parsedUserInfo);
    this.authService.getSubAgenciesById(parsedUserInfo);
    this.authService.getAgentUserInformation(parsedUserInfo);
  }

  get userType(): string {
    return this.authService.userType.value;
  }

  watchUserType(): void {
    this.authService.userType.subscribe(value => {
      this.currentUserType = value;
      // code here
    });
  }

  logout(): void {
    this.authService.logUserLogout(this.uname).subscribe(() => {
      return;
    });
    const isUserLogout = this.authService.logout();
    if(isUserLogout) {
      this.router.navigate([`/${PathConstants.Login.Index}`]);
    }
  }

  paymentLinkValueChange(linkSelected: string): void {
    this.selectedLink = '';
    this.selectedLink = linkSelected;

    switch(this.selectedLink) {
      case this.payments[0]:
        this.router.navigate(['payments','suspended-payments', 'pending']);
        break;
      case this.payments[1]:
        this.router.navigate(['payments','refund', 'request']);
        break;
      case this.payments[2]:
        this.router.navigate(['payments','batch-payments', 'pending']);
        break;
    }
  }

  showCommissionModal(): void {
    this.commissionModalRef = this.modalService.show(CommissionModalComponent, {
      backdrop: 'static',
      keyboard: false,
      ignoreBackdropClick: true,
      class: 'modal-sm',
    });
  }

  showCommissionPaymentModal(): void {
    this.commissionModalRef = this.modalService.show(CommissionPaymentModalComponent, {
      backdrop: 'static',
      keyboard: false,
      ignoreBackdropClick: true,
      class: 'modal-xl',
    });
  }

  checkUserViewCommissionAuthority(): boolean {
    return this.authService?.getUserViewCommissionAuthority ||
          this.authService?.isInternalUser;
  }
}
