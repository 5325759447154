import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RefundComponent } from './refund.component';
import { SharedModule } from '../../../shared/shared.module';
import { RefundRoutingModule } from './refund-routing.module';
import { AppSidebarModule } from '@coreui/angular';
import { RequestModule } from './request/request.module';
import { RefundData } from '../data/refund.data';
import { LA_RefundData } from 'app/states/la/shared/data/refund.data';

@NgModule({
  declarations: [RefundComponent],
  imports: [
    CommonModule,
    RefundRoutingModule,
    SharedModule,
    RequestModule,
    AppSidebarModule
  ],
  providers: [
    RefundData,
    LA_RefundData
  ],
})
export class RefundModule { }
