import { RiskClaimDTO } from "app/shared/models/data/dto/third-party/risk-claims.dto";

export interface ClaimsState {
  isLoading: boolean | null;
  list: RiskClaimDTO[] | null;
};

export const initialState = {
  isLoading: null,
  list: null
};