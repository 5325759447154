import { ChangeDetectorRef, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { PolicyBillingLabelsConstants } from '../../../../../../../app/shared/constants/policy-billing.labels.constants';
import { PaymentMethod } from '../../../../../../../app/shared/enum/payment-method.enum';
import { ErrorMessageConstant } from '../../../../../../../app/shared/constants/error-message.constants';
import { BsModalRef } from 'ngx-bootstrap';
import { PolicyBillingData } from '../../../../../../../app/modules/policy-management/data/policy-billing.data';
import { AuthService } from '../../../../../../core/services/auth.service';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../../../../../app/shared/base-class';
import { BillingService } from '../../../../../../../app/core/services/billing/billing.service';
import { PaymentRequestDTO } from '../../../../../../../app/shared/models/data/dto/billing/payment-request.dto';
import { PaymentSummaryDTO } from '../../../../../../../app/shared/models/data/dto/billing/payment-summary.dto';
import { BillingDetailDTO } from '../../../../../../../app/shared/models/data/dto/billing/billing-detail.dto';
import { CreditCardDTO } from '../../../../../../../app/shared/models/data/dto/billing/credit-card.dto';
import { BankAccountDTO } from '../../../../../../../app/shared/models/data/dto/billing/bank-account.dto';
import { BillingAddressDTO } from '../../../../../../../app/shared/models/data/dto/billing/billing-address.dto';
import { DatePipe } from '@angular/common';
import Utils from '../../../../../../../app/shared/utilities/utils';
import NotifUtils from '../../../../../../../app/shared/utilities/notif-utils';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { RefundRequestDTO } from '../../../../../../../app/shared/models/data/dto/suspended-payment/refund.dto';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { OfacService } from 'app/core/services/submission/third-party/ofac/ofac.service';
import { AllowedPaymentRangeDTO } from '../../../../../../shared/models/data/dto/billing/allowed-payment-range.dto';
import { PaymentProcedure } from '../../../../../../../app/shared/enum/payment-procedure.enum';
import { Observable } from 'rxjs';
import { PolicyService } from '../../../../../../../app/core/services/submission/policy.service';
import { AddressType } from '../../../../../../../app/shared/enum/address-type.enum';
import { AddressDTO3 } from '../../../../../../../app/shared/models/data/dto/quick-quote/entity-address.dto';
import { PostPaymentCheckComponent } from '../post-payment-check/post-payment-check.component';
import { PostPaymentCreditCardComponent } from '../post-payment-credit-card/post-payment-credit-card.component';
import { PostPaymentEftComponent } from '../post-payment-eft/post-payment-eft.component';
import { GenericLabel } from '../../../../../../../app/shared/constants/generic.labels.constants';
import { BillingLabelConstants, PaymentPlanListConstants } from '../../../../../../../app/shared/constants/bind-and-issue.labels.constants';
import Swal from 'sweetalert2';
import { PolicyCancellationData } from '../../../../../../../app/modules/policy-management/data/policy-cancellation.data';
import { AfterViewInit, AfterViewChecked } from '@angular/core';
import { ZipCodeData } from 'app/modules/submission-management/data/zipcode.data';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { PolicyNotesData } from 'app/modules/policy-management/data/policy-notes.data';
import { ApplicantData } from 'app/modules/submission-management/data/applicant.data';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { SuspendedPaymentDetailsDTO } from '../../../../../../shared/models/data/dto/suspended-payment/suspended-payment-detail.dto';
import { SubmissionListData } from 'app/modules/submission-management/data/submission-list.data';
import { TaskGenerationService } from 'app/core/services/submission/task-generation/task-generation.service';
import { RiskUWApprovalDTO2 } from 'app/shared/models/data/dto/quick-quote/uw-approval.dto';
import { Router } from '@angular/router';
import { LvRefundTo, RefundRelatedConstants } from '../../../../../../../app/shared/constants/refund.options.constants';
import { ThirdPartyDataService } from '../../../../../../../app/core/services/submission/third-party/third-party-data.service';
import { AutoReinstatementConstants } from '../../../../../../shared/constants/auto-reinstatement.constants';
import { AutoReinstatementData } from '../../../../data/auto-reinstatement.data';
import { LvCountries } from '../../../../../../shared/constants/applicant.options.constants';
import { RefundData } from '../../../../../../modules/payments/data/refund.data';
import { RiskInterestDetailDTO2 } from '../../../../../../shared/models/data/dto/quick-quote/risk-interest-detail.dto';
import { add } from 'ngx-bootstrap/chronos';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { EntitySubmissionListDTO } from 'app/shared/models/data/dto/submission-list/entity-submission-list.dto';
import { select, Store } from '@ngrx/store';
import { selectRelatedRiskstList } from 'app/store/related-risks/related-risks.selectors';

@Component({
  selector: 'app-post-payment-modal',
  templateUrl: './post-payment-modal.component.html',
  styleUrls: ['./post-payment-modal.component.scss']
})
export class PostPaymentModalComponent extends BaseClass implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  public event: EventEmitter<any> = new EventEmitter<any>();
  public ErrorMessageConstant = ErrorMessageConstant;
  public PostPaymentLabelConstants = PolicyBillingLabelsConstants.postPayment;
  public GenericLabel = GenericLabel;
  public BillingLabelConstants = BillingLabelConstants;
  public AutoReinstatementConstants = AutoReinstatementConstants;
  public RefundToConstants = RefundRelatedConstants.refundTo;
  public RefundCommentsConstants = RefundRelatedConstants.refundComments;
  PaymentMethod = PaymentMethod;
  paymentProcedure: PaymentProcedure = PaymentProcedure.PostBasic;
  isFromReinstate: boolean = false;
  isForRenewalReinstatement: boolean = false;

  modalRef: BsModalRef | null;

  isRefund: boolean = false;
  isRefundRequest: boolean = false;
  isInternal: boolean;
  paymentMethods: any;
  refundToList: any[];
  datePipe: DatePipe;
  currencyInputMode = CurrencyMaskInputMode.NATURAL;
  datePickerDateOption: IAngularMyDpOptions;
  allowedPaymentRange: AllowedPaymentRangeDTO;
  suspendedPaymentsList: SuspendedPaymentDetailsDTO[];

  currentTermAmountToPay: number;
  balance: number;
  expiringRiskIdForRenewalPaymentInSubmission: string;
  expiringRiskIdForRenewalPaymentInExpiringPolicy: string;
  expiringRiskDetailIdForRenewalPaymentInSubmission: string;
  expiringRiskDetailIdForRenewalPaymentInExpiringPolicy: string;
  isMakeAPayment: boolean = false;
  IunderstandCheckbox: boolean = false;
  isPolicyOnPendingCancellation: boolean;

  isPaymentForRenewal: boolean = false;

  selectRelatedRiskstList$: Observable<EntitySubmissionListDTO[]>;
  
  submitClickCount: number = 0;

  @ViewChild('checkForm') checkFormRef: PostPaymentCheckComponent;
  @ViewChild('ccForm') ccFormRef: PostPaymentCreditCardComponent;
  @ViewChild('eftForm') eftFormRef: PostPaymentEftComponent;

  constructor(
    public bsModalRef: BsModalRef,
    public billingData: PolicyBillingData,
    protected authService: AuthService,
    protected billingService: BillingService,
    protected ofacService: OfacService,
    protected zipCodeData: ZipCodeData,
    protected entityRiskData: EntityRiskData,
    protected policyService: PolicyService,
    protected policyNotesData: PolicyNotesData,
    protected applicantData: ApplicantData,
    protected policyCancellationData: PolicyCancellationData,
    protected policySummaryData: PolicySummaryData,
    protected submissionListData: SubmissionListData,
    protected taskGenerationService: TaskGenerationService,
    protected router: Router,
    protected thirdPartyDataService: ThirdPartyDataService,
    public autoReinstatementData: AutoReinstatementData,
    public refundRequestData: RefundData,
    protected cdr: ChangeDetectorRef,
    protected summaryData: SummaryData,
    protected store: Store
  ) {
    super();
    this.selectRelatedRiskstList$ = this.store.pipe(select(selectRelatedRiskstList));
  }

  ngOnInit() {
    this.datePipe = new DatePipe('en-US');

    this.billingData.paymentMethodChangedEvent.pipe(takeUntil(this.stop$)).subscribe(x => {
      if (!this.isRefund) {
        this.prefillPayeeInfo();
      }
    });

    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.isInternal = this.isInternalUser(userType);

      this.getPaymentMethods();
    });

    this.initForm();

    if (!this.isRefund) {
      this.isCurrentOrRenewalCheckBoxChecked();
      this.subscribeToReinstatementPayments();
      this.enablePayeeInfo();
    }

    if (this.isRefundRequest) {
      this.refundToList = LvRefundTo.filter(x => x.code !== 'Premium Finance Co.');
      this.billingData.postPaymentForm.get('refundTo').setValue(this.RefundToConstants.Insured);
      this.disablePayeeInfo();
    }
  }

  initForm(): void {
    this.datePickerDateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy'
    };

    this.billingData.enableCityFields();

    this.zipCodeData.checkCityList = [];
    this.zipCodeData.ccCityList = [];
    this.zipCodeData.eftCityList = [];

    if (this.billingData.isPaymentFromRenewalSubmission) {
      if (this.entityRiskData.getExpiringRiskIdFromSubmission() && this.entityRiskData.getExpiringRiskDetailIdFromSubmission()) {
        this.expiringRiskIdForRenewalPaymentInSubmission = this.entityRiskData.getExpiringRiskIdFromSubmission();
        this.expiringRiskDetailIdForRenewalPaymentInSubmission = this.entityRiskData.getExpiringRiskDetailIdFromSubmission();
      }
    } else {
      if (this.entityRiskData.getRiskId() && this.entityRiskData.getRiskDetailId()) {
        this.expiringRiskIdForRenewalPaymentInExpiringPolicy = this.entityRiskData.getRiskId();
        this.expiringRiskDetailIdForRenewalPaymentInExpiringPolicy = this.entityRiskData.getRiskDetailId();
      }
    }
    const riskId = this.expiringRiskIdForRenewalPaymentInSubmission ? this.expiringRiskIdForRenewalPaymentInSubmission : this.expiringRiskIdForRenewalPaymentInExpiringPolicy;
    const riskDetailIdAct = this.getPolicyRiskDetailIdAct();

    this.billingData.checkRenewalDetails(riskId, riskDetailIdAct, this.isRefundRequest);
    this.populateFields();

    if (this.isRefundRequest) {
      this.billingData.postPaymentForm.get('refundTo').setValidators([Validators.required]);
    } else {
      this.billingData.postPaymentForm.get('refundTo').clearValidators();
    }
  }

  ngAfterViewInit() {
    if (this.isRefund) {
      this.billingData.clearAndSetValidators();
      this.prefillPayeeInfo(true);
      this.billingData.setValidationForCheck(this.isRefund);
      // TODO: Delete this if CENTAURI-6493 passed
      // if (this.billingData.selectedPaymentPlanId !== 'PPO4') {
      //   this.billingData.clearAndSetValidators();
      //   this.prefillPayeeInfo(true);
      // }
    }
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  setAmountValidation(): void {
      this.billingData.postPaymentForm.get('amount').setValidators([Validators.required,
      Validators.min(this.allowedPaymentRange.minimumPaymentAmount),
      Validators.max(this.allowedPaymentRange.maximumPaymentAmount)]);
  }

  populateFields(): void {
    this.billingData.postPaymentForm.reset();

    if (this.billingData.postPaymentForm.get('amount').disabled) {
      this.billingData.postPaymentForm.get('amount').enable();
      this.billingData.postPaymentForm.get('amount').markAsUntouched();
    }

    if (this.isRefund) {
      this.billingData.postPaymentForm.get('paymentMethod').setValue('PM0');
      this.billingData.postPaymentForm.get('paymentMethod').disable();
      this.billingData.postPaymentForm.get('amount').setValidators([Validators.required, Validators.min(0.01), Validators.max(this.billingData.summary.paid)]);
      this.billingData.postPaymentForm.get('amount').updateValueAndValidity();
    } else {
      this.billingData.postPaymentForm.get('paymentMethod').enable();
      this.billingData.postPaymentForm.get('paymentMethod').setValue('');
      this.setAmountValidation();
      this.billingData.postPaymentForm.get('amount').updateValueAndValidity();
    }

    this.billingData.postPaymentForm.get('postDate').setValue({ isRange: false, singleDate: { jsDate: this.billingData.auth.getCustomDate() } });

    if (this.billingData.isPaymentFromRenewalSubmission) {
      this.balance = this.billingData.previousBalance >= 0 ? this.billingData.previousBalance : 0;
    } else {
      this.balance = this.billingData?.summary?.balance >= 0 ? this.billingData.summary?.balance : 0;
    }

    if (!this.isRefund) {
      this.currentTermAmountToPay = Number(this.allowedPaymentRange?.minimumPaymentAmount);

      if (this.isFromReinstate && this.paymentProcedure !== PaymentProcedure.PayToReinstate) {
        this.currentTermAmountToPay = this.currentTermAmountToPay - this.allowedPaymentRange.paymentInSuspense;
      } else if (this.isPolicyOnPendingCancellation && this.paymentProcedure === PaymentProcedure.PostBasic) {
        this.currentTermAmountToPay = this.autoReinstatementData.requirementsMetDetails?.requiredAmount;
      }

      this.billingData.postPaymentForm.get('amount').setValue(this.isReinstatementCheckboxNeeded ? this.autoReinstatementData?.requirementsMetDetails?.requiredAmount : this.currentTermAmountToPay > 0 ? this.currentTermAmountToPay : 0);
    }

    this.checkPaymentForm.get('isIndividual').setValue(true);
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  refreshForm(): void {
    if (this.billingData.postPaymentForm.get('refundTo').value === this.RefundToConstants.Insured) {
      this.disablePayeeInfo();
      this.prefillPayeeInfo(true);
    } else if (this.billingData.postPaymentForm.get('refundTo').value === this.RefundToConstants.Mortgagee) {
      this.disablePayeeInfo();
      this.setMortgageeDetails(true);
    } else {
      this.enablePayeeInfo();
      this.prefillPayeeInfo(true, true);
    }
  }

  onPostPayment(): void {
    this.submitClickCount++;

    if (this.submitClickCount === 1) {

      if (this.isRefund) {
        this.processOfacSearch();
        this.processRefund();
      } else {
        this.processPostPayment();
      }
    }
  }

  protected processPostPayment(): void {
    if (this.billingData.isPolicyCancelled) {
      this.paymentProcedure = this.autoReinstatementData.isPaymentForMinimumDueOnly(this.paymentProcedure, this.allowedPaymentRange?.minimumPaymentAmount, this.billingData.postPaymentForm?.get('amount').value) ?
        PaymentProcedure.PostBasic : PaymentProcedure.PayToReinstate;
    }
    const paymentRequest: PaymentRequestDTO = this.getPaymentRequest(this.paymentProcedure === PaymentProcedure.PostBasic);
    const riskId = this.billingData.isPaymentFromRenewalSubmission ? this.expiringRiskIdForRenewalPaymentInSubmission : this.expiringRiskIdForRenewalPaymentInExpiringPolicy;
    const riskDetailId = this.billingData.isPaymentFromRenewalSubmission ? this.expiringRiskDetailIdForRenewalPaymentInSubmission : this.expiringRiskDetailIdForRenewalPaymentInExpiringPolicy;

    let paymentProcedure$: Observable<any>;
    this.paymentProcedure = this.billingData.postPaymentForm.get('balanceAmountCheckbox').value && this.paymentProcedure === PaymentProcedure.PayToReinstate ? PaymentProcedure.PayBalance : this.paymentProcedure;

    switch (this.paymentProcedure) {
      case PaymentProcedure.PostBasic:

        if (this.isPaymentForRenewal) {
          this.billingData.getCommissionRate();
          this.summaryData.authService.isDoneCommissionRateChecking$.pipe(takeUntil(this.stop$)).subscribe(() => {

            if (!this.authService.isCommissionRateError) {
              paymentRequest.commissionRate = Number(this.authService.commissionRateResponse.commissionRate);

              if (this.checkIfRWNTReinstatement) {
                if (this.isExpiredReinstatement()) {
                  //Prevent payment of reinstatement to force the user to edit reinstatement approval section
                  NotifUtils.showError(AutoReinstatementConstants.errorMessage.expiredApprovalExpirationDate);
                  return;
                } else {
                  paymentProcedure$ = this.autoReinstatementData.setPaymentProcedureForRenewalReinstatement(this.billingData.isPaymentFromRenewalSubmission, paymentRequest);
                }
              } else {
                paymentProcedure$ = this.billingService.postPaymentRequest(paymentRequest);
              }

              Utils.blockUI();
              paymentProcedure$.pipe(takeUntil(this.stop$)).subscribe(result => {
                this.billingData.showSummary(paymentRequest.riskId);
                this.billingData.listInstallmentInvoice(paymentRequest.riskId);
                this.billingData.listPaymentsByRiskId(paymentRequest.riskId);
                this.billingData.getRecurringPaymentDetails(paymentRequest.riskId);
                this.submissionListData.callRelatedSubmissionList(riskId, riskDetailId, this.isInternal);
                Utils.unblockUI();
                this.subsribeToRelatedRisk(result);
              },
                err => {
                  Utils.unblockUI();
                  NotifUtils.showError(err.error?.message, () => {
                    this.hideModal();
                  });
                }
              );
            } else {
              this.bsModalRef.hide();
            }
          });
        } else {
          paymentProcedure$ = this.billingService.postPaymentRequest(paymentRequest);
        }
        break;
      case PaymentProcedure.PayBalance:
        paymentProcedure$ = this.billingService.payBalance(paymentRequest);
        break;
      case PaymentProcedure.PayToReinstate:
        if (this.isExpiredReinstatement() && this.billingData.postPaymentForm.get('reinstateAmountCheckbox').value) {
          //Prevent payment of reinstatement to force the user to edit reinstatement approval section
          NotifUtils.showError(AutoReinstatementConstants.errorMessage.expiredApprovalExpirationDate);
          return;
        }
        paymentProcedure$ = this.billingService.payToReinstate(paymentRequest);
        break;
      default:
    }

    if (paymentProcedure$ && !this.isPaymentForRenewal) {
      Utils.blockUI();
      paymentProcedure$.pipe(takeUntil(this.stop$)).subscribe(result => {
        if (this.paymentProcedure === PaymentProcedure.PostBasic || this.paymentProcedure === PaymentProcedure.PayBalance) {
          this.billingData.showSummary(paymentRequest.riskId);
          this.billingData.listInstallmentInvoice(paymentRequest.riskId);
          this.billingData.listPaymentsByRiskId(paymentRequest.riskId);
          this.billingData.getRecurringPaymentDetails(paymentRequest.riskId);
        } else if (this.paymentProcedure === PaymentProcedure.PayToReinstate) {
          this.policyCancellationData.reinstatePolicyCallback(result);
        }

        this.submissionListData.callRelatedSubmissionList(riskId, riskDetailId, this.isInternal);
        Utils.unblockUI();
        this.subsribeToRelatedRisk(result);
      },
        err => {
          Utils.unblockUI();
          NotifUtils.showError(err.error?.message, () => {
            this.hideModal();
          });
        }
      );
    }
  }

  subsribeToRelatedRisk(paymentProcedure?): void {
    this.selectRelatedRiskstList$.pipe(takeUntil(this.stop$)).subscribe(relatedRiskListResult => {
      this.bsModalRef.hide();

          const options: any = {
            icon: 'success',
            allowOutsideClick: false,
            text: PolicyBillingLabelsConstants.successPayment
          };

          NotifUtils.showSuccess(options, () => {
            this.generateTasksForRenewedPolicy(relatedRiskListResult);
            this.showPaymentReceipt(paymentProcedure.paymentReceiptEmailBody);

        if (this.billingData.isPaymentFromRenewalSubmission) {
          this.billingData.populateRnBillingSummary(this.entityRiskData.getRiskDetailId());
        }

        if (this.billingData.isPaymentFromRenewalSubmission && this.billingData.postPaymentForm.get('currentAndRenewalTermCheckbox').value 
        && !Boolean(paymentProcedure.paymentReceiptEmailBody)
        && !this.isRenewalReinstatementRequirementsNotMet) {
          this.router.navigate([`/policies/${this.entityRiskData.getRiskId()}/${this.entityRiskData.getRiskDetailId()}/summary`]);
          this.autoReinstatementData.isRedirectedToPolicy = true;
        }

        if (((this.billingData.isPaymentFromRenewalSubmission && this.isRenewalReinstatementRequirementsNotMet) ||
          (this.policySummaryData.isPolicy && this.billingData.isPolicyCancelled)) && !this.autoReinstatementData.isRedirectedToPolicy) {
          this.autoReinstatementData.populateAutoReinstateData();
        }
      });
    });
  }



  protected showPaymentReceipt(emailBody: string): void {
    if (emailBody) {
      Swal.fire(
        {
          customClass: {
            content: 'email-receipt-content-style',
          },
          width: 900,
          html: emailBody,
          showCloseButton: true,
          showConfirmButton: false,
          focusConfirm: false,
        }).then((action) => {
          if (action.isDismissed) {
            this.navigateToRenewedPolicy();
          }
        });
    }
  }

  protected navigateToRenewedPolicy(): void {
    if (this.billingData.isPaymentFromRenewalSubmission && this.billingData.postPaymentForm.get('currentAndRenewalTermCheckbox').value && !this.isRenewalReinstatementRequirementsNotMet) {
      this.router.navigate([`/policies/${this.entityRiskData.getRiskId()}/${this.entityRiskData.getRiskDetailId()}/summary`]);
    }
  }

  protected processRefund(): void {
    const billingMethod = this.billingData.postPaymentForm.get('paymentMethod').value;
    const instrumentType: string = this.billingData.paymentMethodsPostPayment.filter(x => x.code === billingMethod)[0].billingCode;

    //NOTE: Check method is the only available method for refund as of the moment
    const cashForm = this.checkPaymentForm;
    const billingAddress = this.getBillingAddress(cashForm);
    const suff = cashForm.get('suffix').value != null ? cashForm.get('suffix').value : '';

    const refundRequest: RefundRequestDTO = {
      riskId: this.entityRiskData.getRiskId(),
      riskDetailId: this.entityRiskData.getRiskDetailId(),
      instrumentId: instrumentType,
      amount: this.billingData.postPaymentForm.get('amount').value,
      comments: this.billingData.postPaymentForm.get('comments').value,
      payeeInfo: {
        name: cashForm.get('isIndividual').value ? `${billingAddress.firstName?.trim()} ${billingAddress.lastName?.trim()} ${suff}` : cashForm.get('name').value?.toString().trim(),
        address: billingAddress.address?.trim(),
        city: billingAddress.city?.trim(),
        state: cashForm.get('state').value?.toString().trim(),
        zipCode: billingAddress.zip?.trim(),
        countryCode: cashForm.get('country').value?.toString().trim(),
        countryName: LvCountries.find(a => a.value === cashForm.get('country').value)?.name.trim() ?? ''
      },
      refundToTypeId: this.isRefundRequest ? this.billingData.postPaymentForm.get('refundTo').value.substring(0, 1) : ''
    };

    if (Boolean(refundRequest.refundToTypeId)) {
      refundRequest.payeeInfo['address2'] = cashForm.get('aptOrUnit').value;
    }

    Utils.blockUI();
    this.billingService.refundPayment(refundRequest)
      .pipe(
        takeUntil(this.stop$),
        switchMap(_ => this.policyService.getPolicyNotes(this.entityRiskData?.getRiskId()))
      ).subscribe(notes => {
        this.billingData.populateBillingSections(refundRequest.riskId);
        this.policyNotesData.populateNotes(notes);

        this.bsModalRef.hide();
        Utils.unblockUI();
        NotifUtils.showSuccess(PolicyBillingLabelsConstants.successRefund);
      },
        err => {
          Utils.unblockUI();
          NotifUtils.showMultiLineError(err.error?.message);
        }
      );
  }

  getPaymentMethods(isWithPayRenewalTerm: boolean = false): void {
    const paymentMethods = this.billingData.paymentMethodsPostPayment;
    const currentBindBilling = this.entityRiskData.getCurrentRiskBindbilling();

    if (!this.isInternal && !this.isRefund) {
      this.paymentMethods = paymentMethods.filter(x => x.code !== PaymentMethod.CashCheck);
    } else {
      this.paymentMethods = paymentMethods;
    }

    if (isWithPayRenewalTerm) {
      if (this.paymentProcedure === PaymentProcedure.PayToReinstate || this.billingData.isEnrolledToAutopay ||
        this.billingData.renewalPaymentPlan === PaymentPlanListConstants.mortgageePayCode) {
        this.paymentMethods = paymentMethods.filter(x => !x.recurringMethod);
      }
    } else {
      const riskBindBilling = !this.billingData.isPaymentFromRenewalSubmission ? currentBindBilling : this.entityRiskData.getExpiringPolicyCurrentRiskBindbilling();

      if (this.paymentProcedure === PaymentProcedure.PayToReinstate || this.billingData.isEnrolledToAutopay ||
        riskBindBilling.payPlanOption === PaymentPlanListConstants.mortgageePayCode) {
        this.paymentMethods = paymentMethods.filter(x => !x.recurringMethod);
      }
    }
  }

  public isInternalUser(userType: string): boolean {
    return userType.toLocaleLowerCase() === 'internal';
  }

  protected getPaymentRequest(isPostBasic: boolean): PaymentRequestDTO {

    const billingMethod = this.billingData.postPaymentForm.get('paymentMethod').value;
    const instrumentType: string = this.billingData.paymentMethodsPostPayment.filter(x => x.code === billingMethod)[0].billingCode;
    const amount: number = this.billingData.postPaymentForm.get('amount').value;
    const roundedAmount = Math.round((amount + Number.EPSILON) * 100) / 100;

    const paymentSummary: PaymentSummaryDTO = {
      amount: roundedAmount,
      effectiveDate: this.datePipe.transform(this.billingData.postPaymentForm.get('postDate').value.singleDate.jsDate, 'yyyy-MM-dd'),
      instrumentId: instrumentType,
    };

    if (billingMethod === PaymentMethod.RecurringCreditCard || billingMethod === PaymentMethod.RecurringECheck) {
      paymentSummary.isRecurringPayment = this.billingData.postPaymentForm.get('agreeEnrollAutoPay').value ?? false;
    } else if (billingMethod === PaymentMethod.CreditCard || billingMethod === PaymentMethod.EFT || billingMethod === PaymentMethod.ECheck) {
      paymentSummary.isAgreeOneTimePayment = this.billingData.postPaymentForm.get('agreeOneTimePayment').value ?? false;
    }

    if (billingMethod === PaymentMethod.RecurringCreditCard || billingMethod === PaymentMethod.RecurringECheck ||
      billingMethod === PaymentMethod.CreditCard || billingMethod === PaymentMethod.EFT || billingMethod === PaymentMethod.ECheck) {
      paymentSummary.isAgreeInsured = this.billingData.postPaymentForm.get('agreeInsured').value ?? false;
    }

    const billingDetail: BillingDetailDTO = new BillingDetailDTO();

    if (billingMethod === PaymentMethod.CreditCard || billingMethod === PaymentMethod.RecurringCreditCard) {
      const ccForm = this.billingData.postPaymentForm.get('creditCard');
      const ccType = ccForm.get('creditCardTypeId').value;
      const ccTypeBilling = this.billingData.getCreditCardTypeBillingCode(ccType);

      const creditCardDetail: CreditCardDTO = {
        cardCode: ccForm.get('cardCode').value,
        cardNumber: ccForm.get('cardNumber').value,
        expirationDate: `${ccForm.get('expirationYear').value}-${('00' + ccForm.get('expirationMonth').value).slice(-2)}`,
        cardType: ccTypeBilling,
      };

      billingDetail.creditCard = creditCardDetail;
      billingDetail.billingAddress = this.getBillingAddress(ccForm);

      paymentSummary.email = ccForm.get('email').value;
    } else if (billingMethod === PaymentMethod.EFT || billingMethod === PaymentMethod.RecurringECheck) {
      const eftForm = this.billingData.postPaymentForm.get('eCheck');

      const accountType: string = eftForm.get('accountType').value;
      const accountTypeBilling: string = this.billingData.getAccountTypeBillingCode(accountType);

      const bankAccount: BankAccountDTO = {
        accountNumber: eftForm.get('accountNumber').value,
        accountType: accountTypeBilling,
        nameOnAccount: eftForm.get('nameOnAccount').value,
        routingNumber: eftForm.get('routingNumber').value,
      };

      billingDetail.bankAccount = bankAccount;
      billingDetail.billingAddress = this.getBillingAddress(eftForm);

      paymentSummary.email = eftForm.get('email').value;
    } else if (billingMethod === PaymentMethod.CashCheck) {
      const cashForm = this.checkPaymentForm;
      billingDetail.billingAddress = this.getBillingAddress(cashForm);
    }

    paymentSummary.checkNumber = this.billingData.postPaymentForm.get('checkNumber')?.value ?? '';
    paymentSummary.imageUrl = this.billingData.uploadedCheckImage?.filePath ?? '';

    const isCurrentAndRenewalPayment = isPostBasic && (this.billingData.postPaymentForm.get('currentAndRenewalTermCheckbox').value ?? false);

    const paymentRequest: PaymentRequestDTO = {
      riskId: this.billingData.isPaymentFromRenewalSubmission ? this.expiringRiskIdForRenewalPaymentInSubmission : this.expiringRiskIdForRenewalPaymentInExpiringPolicy,
      riskDetailId: this.billingData.isPaymentFromRenewalSubmission ? this.expiringRiskDetailIdForRenewalPaymentInSubmission : this.expiringRiskDetailIdForRenewalPaymentInExpiringPolicy,
      paymentSummary: paymentSummary,
      billingDetail: billingDetail,
      postSuspendedPayments: this.isFromReinstate,
      isFromInsuredPaymentPortal: false,
      isForCurrentAndRenewal: isCurrentAndRenewalPayment,
      isForReinstatementAndRequirementsMet: this.setLogicForAutoReinstatement(),
      isReinstatingByMakeAPayment: this.autoReinstatementData.setIsReinstatingByMakeAPayment(this.paymentProcedure === PaymentProcedure.PayToReinstate, this.billingData.postPaymentForm),
      commissionRate: 0
    };

    return paymentRequest;
  }

  protected getBillingAddress(form: AbstractControl): BillingAddressDTO {
    const billingAddress: BillingAddressDTO = {
      firstName: form.get('firstName').value,
      lastName: form.get('lastName').value,
      address: form.get('address').value,
      city: form.get('city').value,
      zip: form.get('zip').value,
      state: form.get('state').value
    };

    if (form.get('email')) {
      billingAddress.email = form.get('email').value;
    }

    return billingAddress;
  }
  get isAmountZero(): boolean {
    return this.billingData.postPaymentForm.get('amount').value === 0;
  }

  get isAgreeInsuredChecked(): boolean {
    if (this.billingData.postPaymentForm.get('paymentMethod').value !== PaymentMethod.CashCheck) {
      return this.billingData.postPaymentForm.get('agreeInsured').value ?? false;
    } else {
      return true;
    }
  }

  processOfacSearch(): void {
    const checkForm = this.checkPaymentForm;

    this.ofacService.runOfacOnRefund(
      checkForm.get('isIndividual').value ? checkForm.get('firstName').value : checkForm.get('name').value,
      checkForm.get('isIndividual').value ? checkForm.get('lastName').value : '',
      checkForm.get('isIndividual').value ? checkForm.get('suffix').value : ''
    );
  }

  prefillPayeeInfo(isRefundToRequested: boolean = false, isRefundToOther: boolean = false): void {
    let address: AddressDTO3;
    if (this.entityRiskData.EntityRisk.isMailingAddressSameInsured) {
      address = this.entityRiskData?.EntityRisk?.entityAddresses.find(a => a.addressTypeCode === AddressType.Property)?.address;
    } else {
      address = this.entityRiskData?.EntityRisk?.entityAddresses.find(a => a.addressTypeCode === AddressType.Mailing)?.address;
    }

    let firstName: string = this.applicantData.mainApplicant.get('firstName').value;
    let lastName: string = this.applicantData.mainApplicant.get('lastName').value;
    let nameSuffix: string = this.applicantData.mainApplicant.get('nameSuffix').value;
    let address1: string = address.streetAddress1;
    let address2: string = address.streetAddress2;
    let zip: string = address.zipCode;
    let city: string = address.city;
    let countryCode: string = address.countryCode;
    let email: string = this.applicantData.contact.get('emailAddress').value;
    const altPyr: RiskInterestDetailDTO2 = this.entityRiskData?.EntityRisk?.risks.find(a => a.id === this.entityRiskData.riskId)
      .riskDetails.find(a => a.id === this.entityRiskData.riskDetailId)
      .riskInterests[0].riskInterestDetails.find(a => a.interestTypeCode === 'AltPyr' && a.isSelectedPayor);
    const paymentForm: FormGroup = this.billingData.getCurrentPaymentForm(this.billingData.postPaymentForm);

    if (isRefundToRequested && isRefundToOther) {

      if (altPyr != null) {
        firstName = altPyr.interestName;
        lastName = altPyr.isIndividual ? altPyr.interestLastName : altPyr.interestName2;
        address1 = altPyr.mailingAddress;
        address2 = altPyr.street;
        zip = altPyr.zipCode.toString();
        city = altPyr.city;
        countryCode = altPyr.countryCode;
        paymentForm.get('isIndividual').setValue(altPyr.isIndividual);
      }
    }

    if ((paymentForm && !paymentForm.touched) || isRefundToRequested) {
      if (isRefundToRequested) {
        if (!altPyr) {
          paymentForm.get('isIndividual').setValue(true);
        }
        this.billingData.clearAndSetValidators();
        paymentForm.get('name').setValue('');
        paymentForm.get('country').setValue(countryCode);
      }

      if (isRefundToRequested) {
        if (paymentForm.get('isIndividual').value === true) {
          paymentForm.get('firstName').setValue(firstName);
          paymentForm.get('lastName').setValue(lastName);
        } else {
          paymentForm.get('name').setValue(firstName);
        }
        this.billingData.postPaymentForm.get('comments').setValue(this.RefundCommentsConstants.defaultComment);
      } else {
        paymentForm.get('firstName').setValue(firstName);
        paymentForm.get('lastName').setValue(lastName);
      }

      paymentForm.get('address').setValue(address1);
      paymentForm.get('aptOrUnit').setValue(address2);
      paymentForm.get('zip').setValue(zip);

      // TODO: Delete this if CENTAURI-6493 passed
      // if (this.isRefund) {
      //   if (address1.length > 32) {
      //     this.billingData.validateRefundAddressCount(address1, address2);
      //   } else {
      //     paymentForm.get('aptOrUnit').setValue(address2);
      //   }
      // }

      const emailField = paymentForm.get('email');
      if (emailField) {
        emailField.setValue(email);
      }

      paymentForm.get('firstName').markAsTouched();

      this.zipCodeData.getCityListCompleted$.pipe(take(1)).subscribe(() => {
        paymentForm.get('city').setValue(city);
      });
      const isUSCountry = address?.countryCode === 'USA' || address?.countryCode === 'US';
      const paymentMethod = this.billingData.postPaymentForm.get('paymentMethod').value;
      if (paymentMethod === PaymentMethod.CashCheck) {
        //paymentForm.get('suffix').setValue(nameSuffix);
        //paymentForm.get('country').setValue(address?.countryCode);
        paymentForm.get('state').setValue(address?.state);
        this.checkFormRef.setZipCode(isUSCountry, false, this.isRefund);

      } else if (paymentMethod === PaymentMethod.CreditCard || paymentMethod === PaymentMethod.RecurringCreditCard) {
        this.ccFormRef.setZipCode(isUSCountry);
      } else if (paymentMethod === PaymentMethod.EFT || paymentMethod === PaymentMethod.RecurringECheck) {
        this.eftFormRef.setZipCode(isUSCountry);
      }
      this.checkIfNameOrAddressExceededMaxLength(isRefundToOther);
    }
    this.billingData.hasOneAgreeCheckbox = false;
    
    const paymentMethod = this.billingData.postPaymentForm.get('paymentMethod').value;
    if (paymentMethod === PaymentMethod.CreditCard || paymentMethod === PaymentMethod.EFT){
      this.billingData.postPaymentForm.controls.agreeOneTimePayment.setValue(false);
    }
  }

  enablePayeeInfo(): void {
    this.checkPaymentForm.get('isIndividual').enable();
    this.checkPaymentForm.get('name').enable();
    this.checkPaymentForm?.get('firstName').enable();
    this.checkPaymentForm?.get('lastName').enable();
    this.checkPaymentForm?.get('suffix').enable();
    this.checkPaymentForm?.get('address').enable();
    this.checkPaymentForm?.get('city').enable();
    this.checkPaymentForm?.get('zip').enable();
    this.checkPaymentForm?.get('country').enable();
    this.checkPaymentForm?.get('aptOrUnit').enable();
  }

  disablePayeeInfo(): void {
    this.checkPaymentForm.get('isIndividual').disable();
    this.checkPaymentForm.get('name').disable();
    this.checkPaymentForm?.get('firstName').disable();
    this.checkPaymentForm?.get('lastName').disable();
    this.checkPaymentForm?.get('suffix').disable();
    this.checkPaymentForm?.get('address').disable();
    this.checkPaymentForm?.get('city').disable();
    this.checkPaymentForm?.get('zip').disable();
    this.checkPaymentForm?.get('country').disable();
    this.checkPaymentForm?.get('aptOrUnit').disable();
  }

  setMortgageeDetails(isRefundToRequested: boolean = false): void {
    const riskId: string = this.entityRiskData.getRiskId();
    const lastRiskId: string = this.entityRiskData.getRiskDetailId();
    Utils.blockUI();
    this.thirdPartyDataService.getRiskInterests(riskId, lastRiskId).pipe(takeUntil(this.stop$)).subscribe(interests => {
      const mortgageeCode = 'mort';
      const mortgageeExists = interests.some(x => x.riskInterestDetails.some(y => y.interestTypeCode.toLowerCase() === mortgageeCode && y.isActive && y.removedDate == null && y.rankCode === '1stMort'));
      const mortgageeDetail = interests.filter(x => x.riskInterestDetails.filter(y => y.interestTypeCode.toLowerCase() === mortgageeCode && y.isActive && y.removedDate == null && y.rankCode === '1stMort'))[0];

      if (mortgageeExists) {
        const isIndividual = mortgageeDetail.riskInterestDetails[0].isIndividual;
        const firstName: string = mortgageeDetail.riskInterestDetails[0].interestName;
        const lastName: string = mortgageeDetail.riskInterestDetails[0].interestLastName;
        const nameSuffix: string = mortgageeDetail.riskInterestDetails[0].interestNameSuffix;
        const mortgageeAddress: string = mortgageeDetail.riskInterestDetails[0].mailingAddress;
        const mortgageeAddress2: string = mortgageeDetail.riskInterestDetails[0].mailingAddress2;
        const zip: string = mortgageeDetail.riskInterestDetails[0].zipCode?.toString();
        const city: string = mortgageeDetail.riskInterestDetails[0].city;
        const country: string = mortgageeDetail.riskInterestDetails[0].countryCode;
        const state: string = mortgageeDetail.riskInterestDetails[0].state;

        const paymentForm: FormGroup = this.billingData.getCurrentPaymentForm(this.billingData.postPaymentForm);

        if ((paymentForm && !paymentForm.touched) || isRefundToRequested) {
          // Toggle
          paymentForm.get('isIndividual').setValue(isIndividual);

          this.billingData.clearAndSetValidators();

          // Individual
          paymentForm.get('name').setValue(firstName);

          // Not Individual
          paymentForm.get('firstName').setValue(firstName);
          paymentForm.get('lastName').setValue(lastName);
          paymentForm.get('address').setValue(mortgageeAddress);
          paymentForm.get('aptOrUnit').setValue(mortgageeAddress2);
          paymentForm.get('zip').setValue(zip);

          this.zipCodeData.getCityListCompleted$.pipe(take(1)).subscribe(() => {
            paymentForm.get('city').setValue(city);
          });
          const isUSCountry = mortgageeDetail.riskInterestDetails[0].countryCode === 'USA' || mortgageeDetail.riskInterestDetails[0].countryCode === 'US';
          const paymentMethod = this.billingData.postPaymentForm.get('paymentMethod').value;
          if (paymentMethod === PaymentMethod.CashCheck) {
            paymentForm.get('suffix').setValue(nameSuffix);
            paymentForm.get('country').setValue(country);
            paymentForm.get('state').setValue(state);
            this.checkFormRef.setZipCode(isUSCountry, false, this.isRefund);

          } else if (paymentMethod === PaymentMethod.CreditCard || paymentMethod === PaymentMethod.RecurringCreditCard) {
            this.ccFormRef.setZipCode(isUSCountry);
          } else if (paymentMethod === PaymentMethod.EFT || paymentMethod === PaymentMethod.RecurringECheck) {
            this.eftFormRef.setZipCode(isUSCountry);
          }
          this.checkIfNameOrAddressExceededMaxLength(false);
        }
        Utils.unblockUI();
      }
    });
  }


  isPaymentMethodHidden(paymentMethodCode: string): boolean {
    return !this.authService.isInternalUser && paymentMethodCode === PaymentMethod.CashCheck;
  }

  isCurrentOrRenewalCheckBoxChecked() {
    const pastDueAmount = this.allowedPaymentRange.minimumPaymentAmount;
    const payOffAmount = this.allowedPaymentRange.maximumPaymentAmount;

    this.billingData.postPaymentForm.get('currentTermCheckbox').valueChanges.subscribe(res => {
      if (res) {
        this.isPaymentForRenewal = false;
        this.getPaymentMethods();
        this.billingData.postPaymentForm.get('paymentMethod').setValue('');

        this.billingData.postPaymentForm.get('amount').setValidators([Validators.required, Validators.min(pastDueAmount), Validators.max(payOffAmount)]);
        this.billingData.postPaymentForm.get('currentAndRenewalTermCheckbox').setValue(false);
        this.billingData.postPaymentForm.get('amount').setValue(this.currentTermAmountToPay);
        this.billingData.postPaymentForm.get('amount').updateValueAndValidity();
        this.billingData.postPaymentForm.get('amount').enable();
      }

      if (this.isCheckBoxPaymentBothFalse) {
        this.setAmountAndPaymentMethodsToDefault();
      }
    });

    this.billingData.postPaymentForm.get('currentAndRenewalTermCheckbox').valueChanges.subscribe(res => {
      if (res) {
        this.isPaymentForRenewal = res;
        this.getPaymentMethods(true);
        this.billingData.postPaymentForm.get('paymentMethod').setValue('');

        if (this.checkIfRWNTReinstatement && !this.autoReinstatementData.requirementsMetDetails?.areRequirementsMetExceptPayment) {
          this.autoReinstatementData.notifyForRequirementsNotMet(AutoReinstatementConstants.warningMessage.renewalReinstate, this.billingData.postPaymentForm, 0, false, false);
        }

        const reinstatementOrRenewalDepositOnly = this.getRenewalOrReinstatementAmountOnly();

        this.billingData.postPaymentForm.get('amount').setValidators([
          Validators.required,
          Validators.min(this.paymentProcedure !== PaymentProcedure.PayToReinstate ? this.currentTermAmountToPay + reinstatementOrRenewalDepositOnly : this.currentTermAmountToPay + 1),
          Validators.max(
            this.paymentProcedure !== PaymentProcedure.PayToReinstate ?
              this.currentTermAmountToPay + this.billingData.renewalTotalPremium : this.billingData.renewalAmount + this.currentTermAmountToPay
          )
        ]);
        this.billingData.postPaymentForm.get('currentTermCheckbox').setValue(false);
        this.billingData.postPaymentForm.get('amount').setValue(this.currentTermAmountToPay + reinstatementOrRenewalDepositOnly);
        this.billingData.postPaymentForm.get('amount').updateValueAndValidity();
        this.billingData.postPaymentForm.get('amount').enable();
      }

      if (this.isCheckBoxPaymentBothFalse) {
        this.setAmountAndPaymentMethodsToDefault();
      }
    });
  }

  get hasSuspendedPayments(): boolean {
    if (this.isRefund) {
      return false;
    }
    return this.isFromReinstate;
  }

  get minimumPaymentAmount(): number {
    if (this.billingData.withRenewalOffered) {
      const currentAmountChecked = this.billingData.postPaymentForm.get('currentTermCheckbox').value;
      return currentAmountChecked ? this.currentTermAmountToPay : this.currentTermAmountToPay + this.getRenewalOrReinstatementAmountOnly();
    } else {
      return this.billingData.postPaymentForm.get('reinstateAmountCheckbox').value ? this.autoReinstatementData.getMinimumPaymentAmount(this.allowedPaymentRange?.minimumPaymentAmount) :
        this.billingData.postPaymentForm.get('balanceAmountCheckbox').value ? this.balance : this.allowedPaymentRange.minimumPaymentAmount;
    }
  }

  get maximumPaymentAmount(): number {
    const payOffAmount = this.allowedPaymentRange.maximumPaymentAmount;
    const rescindReinstatementAmount = this.autoReinstatementData.requirementsMetDetails?.areRequirementsMetExceptPayment ?
      this.allowedPaymentRange.maximumPaymentAmount + this.billingData?.renewalAmount :
      this.autoReinstatementData.requirementsMetDetails?.requiredAmount;

    if (this.billingData.withRenewalOffered) {
      const currentAmountChecked = this.billingData.postPaymentForm.get('currentTermCheckbox').value;
      return currentAmountChecked ? payOffAmount : this.paymentProcedure !== PaymentProcedure.PayToReinstate ? this.currentTermAmountToPay + this.billingData.renewalTotalPremium : this.billingData.renewalAmount + this.currentTermAmountToPay;
    } else {
      return this.autoReinstatementData.hasRescindedOffer ? rescindReinstatementAmount : this.allowedPaymentRange.maximumPaymentAmount;
    }
  }

  get isCheckBoxPaymentBothFalse(): boolean {
    const checkboxesFormControlFalseValue = !this.billingData.postPaymentForm.get('currentAndRenewalTermCheckbox').value && !this.billingData.postPaymentForm.get('currentTermCheckbox').value && !this.billingData.isPolicyCancelled;
    return checkboxesFormControlFalseValue && (this.billingData.withRenewalOffered || this.autoReinstatementData.hasRescindedOffer);
  }

  get isCheckImageNotUploaded(): boolean {
    return !Boolean(this.billingData.uploadedCheckImage?.isUploaded) && (this.billingData.postPaymentForm.get('paymentMethod')?.value === PaymentMethod.CashCheck) && !this.isRefund;
  }

  private generateTasksForRenewedPolicy(relatedRisk?: EntitySubmissionListDTO[]): void {
    if (!this.billingData.withRenewalOffered) {
      return;
    }

    //renewal risk when paying renewal in expiring policy
    const renewalRiskForRenewalPaymentInExpiringPolicy = relatedRisk.filter(relatedRiskItem => {
      return relatedRiskItem.renewalFromRiskId !== null && relatedRiskItem.renewalFromRiskId === this.entityRiskData.riskId;
    })[0];


    //renewal risk when paying renewal in submission
    const renewalRiskIdForRenewalPaymentInSubmission = this.entityRiskData.getRiskId();
    const renewalRiskDetailIdForRenewalPaymentInSubmission = this.entityRiskData.getRiskDetailId();
    const renewalRiskPolicyNumberForRenewalPaymentInSubmission = this.entityRiskData.getPolicyNumber();
    const renewalEntityIdForRenewalPaymentInSubmission = this.entityRiskData.getEntityId();

    const renewalRiskId = Boolean(renewalRiskIdForRenewalPaymentInSubmission) ? renewalRiskIdForRenewalPaymentInSubmission : renewalRiskForRenewalPaymentInExpiringPolicy.riskId;
    const renewalRiskDetailId = Boolean(renewalRiskDetailIdForRenewalPaymentInSubmission) ? renewalRiskDetailIdForRenewalPaymentInSubmission : renewalRiskForRenewalPaymentInExpiringPolicy.submissionRiskDetailId;
    const renewalRiskPolicyNumber = Boolean(renewalRiskPolicyNumberForRenewalPaymentInSubmission) ? renewalRiskPolicyNumberForRenewalPaymentInSubmission : renewalRiskForRenewalPaymentInExpiringPolicy.policyNumber;
    const renewalEntityId = Boolean(renewalEntityIdForRenewalPaymentInSubmission) ? renewalEntityIdForRenewalPaymentInSubmission : renewalRiskForRenewalPaymentInExpiringPolicy.entityId;

    this.policyService.getPolicyUwApprovals(renewalRiskId, renewalRiskDetailId).pipe(takeUntil(this.stop$)).subscribe((result: RiskUWApprovalDTO2[]) => {
      if (!result) {
        return;
      }

      const renewalSubmissionUWR: string[] = result.map(uwr => {
        return uwr.uwApprovalDescription;
      });
      const issued = {
        policyNumber: renewalRiskPolicyNumber
      };

      this.taskGenerationService.triggerTaskMethods(issued, renewalSubmissionUWR, renewalEntityId);
    });
  }

  protected getPolicyRiskDetailIdAct(): string {
    return this.entityRiskData.EntityRisk?.risks[0]?.riskDetails.length > 1 ? this.entityRiskData.getActiveRiskDetailId() : this.entityRiskData.getExpiringActiveRiskDetailId();
  }

  ngOnDestroy() {
    this.billingData.resetCheckImageModel();
    this.authService.isCommissionRateError = false;
  }

  get isReinstatementRequirementsNotMet(): boolean {
    return this.autoReinstatementData.checkIsReinstatementRequirementsNotMet(this.isRefund, this.billingData.postPaymentForm);
  }

  get isReinstatementCheckboxNeeded(): boolean {
    return this.autoReinstatementData.checkIsReinstatementCheckboxNeeded(this.billingData.postPaymentForm);
  }

  protected isExpiredReinstatement(): boolean {
    return this.autoReinstatementData.checkIsReinstatementExpired();
  }

  protected setLogicForAutoReinstatement(): boolean {
    return this.autoReinstatementData.setReinstatementAndRequirementsMetProperty(this.paymentProcedure, this.billingData.postPaymentForm);
  }

  protected subscribeToReinstatementPayments(): void {
    this.autoReinstatementData.subscribePostPaymentReinstatementCheckboxes(this.billingData.postPaymentForm, this.balance);
  }

  setMinimumReinstateAmount(): number {
    return this.autoReinstatementData.setMinimumReinstateAmount();
  }

  get isRenewalReinstatementRequirementsNotMet(): boolean {
    return this.autoReinstatementData.checkIsRenewalReinstatementRequirementsNotMet(this.isRefund, this.billingData.postPaymentForm);
  }

  get hasReinstatementErrorMessage(): boolean {
    return (this.isReinstatementRequirementsNotMet && this.billingData.postPaymentForm.get('reinstateAmountCheckbox').value) || (this.isRenewalReinstatementRequirementsNotMet && this.billingData.postPaymentForm.get('currentAndRenewalTermCheckbox').value);
  }

  get checkIfRWNTReinstatement(): boolean {
    return (this.policySummaryData.isPolicy && this.billingData.hasRenewalNotTaken) ||
      (!this.policySummaryData.isPolicy && this.entityRiskData.getRisk().renewalCode === AutoReinstatementConstants.reinstateStatus.RWNT);
  }

  toggleIunderstandCheckbox(isChecked: boolean): void {
    this.IunderstandCheckbox = isChecked;
  }

  get shouldBeDisabled(): boolean {
 return this.billingData.postPaymentForm.invalid ||
      //this.isAmountZero || (!this.isRefund && !this.isAgreeInsuredChecked) ||
      this.isAmountZero ||
      this.isCheckImageNotUploaded || this.isReinstatementCheckboxNeeded ||
      (!this.isRefund && this.billingData.withRenewalOffered ? this.isCheckBoxPaymentBothFalse : '') ||
      (this.hasReinstatementErrorMessage && !this.IunderstandCheckbox) ||
      (this.isRefund && this.nameOrAddressExceededMaxLength) ||
      !(this.billingData.hasOneAgreeCheckbox || this.isCashChecked);
  }

  get checkPaymentForm() {
    return this.billingData.postPaymentForm?.get('check');
  }

  get nameOrAddressExceededMaxLength() {
    const form = this.checkPaymentForm;
    const refundPayeeDetails = this.refundRequestData.getRefundPayeeDetails(form);

    if (form.get('isIndividual').value) {
      this.billingData.checkIfNameExceededMaxLength(form, this.billingData.getNameFieldsForRefund());
    } else {
      this.refundRequestData.checkIfFieldsExceededMaxLength(form, refundPayeeDetails);
    }

    return this.refundRequestData.nameOrAddressExceededMaxLength;
  }

  checkIfNameOrAddressExceededMaxLength(isRefundToOther: boolean): void {
    const form = this.checkPaymentForm;
    const refundPayeeDetails = this.refundRequestData.getRefundPayeeDetails(form);

    if (!isRefundToOther && this.refundRequestData.nameOrAddressExceedMaxLength(this.refundRequestData.getRefundPayeeDetails(form))) {
      if (form.get('isIndividual').value) {
        this.billingData.checkIfNameExceededMaxLength(form, this.billingData.getNameFieldsForRefund());
      }
      this.refundRequestData.checkIfFieldsExceededMaxLength(form, refundPayeeDetails);
      const infoMsg = ErrorMessageConstant.billingErrorMessage.changeRefundToInfoMsg;
      const errorMsg = this.refundRequestData.getCustomErrorMsg(refundPayeeDetails, infoMsg);
      NotifUtils.showError(errorMsg);
    }
  }

  private setAmountAndPaymentMethodsToDefault() {
    this.getPaymentMethods();
    this.billingData.postPaymentForm.get('paymentMethod').setValue('');

    this.billingData.postPaymentForm.get('amount').disable();
    this.billingData.postPaymentForm.get('amount').setValue(0);
    this.billingData.postPaymentForm.get('amount').markAsUntouched();
  }

  /**
   * This method is used in the ff: reinstatement amount in HTML, computation for minimum amount validation, and setting the amount for renewal.
   * This should always be synced to make-a-payment-modal components.
   */
  getRenewalOrReinstatementAmountOnly(): number {
    return this.autoReinstatementData.computeRenewalReinstatementAmountWithoutBalance(this.currentTermAmountToPay, this.billingData.renewalAmount);
  }

  get isCashChecked(): boolean {
    return this.billingData.postPaymentForm.get('paymentMethod').value === PaymentMethod.CashCheck;
  }
}
