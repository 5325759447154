import { RenewalBillingSummaryRequestDTO } from './renewal-billing-summary.request.dto';

export class RecalculateReinstatementAmountRequestDTO implements IRecalculateReinstatementAmountRequestDTO {
  riskId?: string | undefined;
  approvalExpirationDate?: any | undefined;
  renewalOfferRequest?: RenewalBillingSummaryRequestDTO | undefined;
  isForRenewal?: boolean;

  constructor(data?: RecalculateReinstatementAmountRequestDTO) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }
}

export interface IRecalculateReinstatementAmountRequestDTO {
  riskId?: string | undefined;
  approvalExpirationDate?: any | undefined;
  renewalOfferRequest?: RenewalBillingSummaryRequestDTO | undefined;
  isForRenewal?: boolean;
}