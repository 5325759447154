/* eslint-disable no-shadow */
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../../shared/validators/custom.validator';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { BaseClass } from '../../../shared/base-class';
import { IHideTableItems, ITableFormControl, ITableTd, ITableTh, ITableTr } from '../../../shared/models/dynamic/table.interface';
import { ZipData } from './zip.data';
import { DocumentsLabelConstants, NotesLabelConstants, PaymentPlanListConstants, PremiumConstants, PrintLabelConstants } from '../../../shared/constants/bind-and-issue.labels.constants';
import { EntityRiskDTO } from '../../../shared/models/data/dto/quick-quote/entity-risk.dto';
import { ZipcodesService } from '../../../core/services/submission/zipcodes/zipcodes.service';
import { ZipCodeData } from './zipcode.data';
import { BindAndIssueValidators } from '../validators/bind-and-issue-validator';
import { takeUntil } from 'rxjs/operators';
import { FormDocumentService } from '../../../core/services/submission/report/form-document.service';
import { LvDocumentsCategory } from '../../../shared/constants/documents.options.constants';
import { SummaryData } from './summary.data';
import { OfacSearchData } from './ofac-search.data';
import { LvAgencyNotesCategories, LvNotesCategories, NotesCategories } from 'app/shared/constants/notes.options.constants';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { RiskBindNoteDTO } from 'app/shared/models/data/dto/quick-quote/risk-bind-note.dto';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { InterestData } from './interest.data';
import { Rater } from '../../../shared/constants/rater.constant';
import { AgentService } from '../../../core/services/management/agent-service';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { ApplicantData } from './applicant.data';
import { QuoteService } from '../../../../app/core/services/quote.service';
import { FormTypeConstants } from '../../../../app/shared/constants/form-types.constants';
import { AuthService } from '../../../core/services/auth.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { RiskBindDocumentDTO } from 'app/shared/models/data/dto/quick-quote/risk-bind-document.dto';
import { RiskUWApprovalDTO2 } from 'app/shared/models/data/dto/quick-quote/uw-approval.dto';
import { EntityRiskData } from './entity-risk.data';
import { PolicyBillingData } from '../../../modules/policy-management/data/policy-billing.data';
import { ThirdPartyData } from './third-party.data';
import Utils from '../../../shared/utilities/utils';
import { RenewalBillingSummaryResponseDTO } from '../../../shared/models/data/dto/billing/renewal-billing-summary.response.dto';
import { DocumentTypeConstant } from '../../../shared/constants/document-type';
import { SubAgenciesResponseDTO } from '../../../shared/models/data/dto/agent/subagencies-response.dto';
import { PaymentMethod } from '../../../shared/enum/payment-method.enum';
import { PolicyBillingConstants } from '../../../shared/constants/policy-billing.constants';

@Injectable({
  providedIn: 'root'
})

export class BindAndIssueData extends BaseClass {
  // Form Group
  billingForm: FormGroup;
  paperlessDocumentForm: FormGroup;
  signatureForm: FormGroup;
  printForm: FormGroup;

  public documentsList: any[] = [];
  public notesList: any[] = [];
  public internalNotesList: any[] = [];

  // Dropdown Values
  paymentPlanList: any[];
  creditTypeList: any[];
  zipList: any[];
  signatureList: any[];
  selectedSignature: any[];
  documentsCategoryList: any[];
  notesCategories: any[];
  paymentMethodList: any[];
  ccMonthList: any[] = [];
  ccYearList: any[] = [];
  accountTypeList: any[];
  riskStatusList: any[];
  formDocuments: any[];
  // Date Format Options
  ccExpiration: IAngularMyDpOptions;
  requestedEffectiveDateOption: IAngularMyDpOptions;
  dateAddedOption: IAngularMyDpOptions;

  showTotalChargeAmountField: boolean = false;

  isFromRenewal: boolean = false;
  isFromAgency: boolean = false;
  agencyId: string;
  nextPaymentPlanFromRenewal: string;

  documentsTableRows: ITableTr[] = [];
  notesTableRows: ITableTr[] = [];
  formDocumentTableRows: ITableTr[] = [
    {
      id: '1',
      tr: [
        {
          id: '1',
          value: 'CS FL DEC 01 19 Declaration',
          display: 'Declaration (VAR - HO)',
        },
      ],
    },
    {
      id: '2',
      tr: [
        {
          id: '2',
          value: 'CSH AL 07 19 Animal Liability Endorsement',
          display: 'Animal Liability Endorsement',
        },
      ],
    },
    {
      id: '3',
      tr: [
        {
          id: '3',
          value: 'CSH HOJ 10 16 HO Policy Jkt',
          display: 'HO Policy Jacket',
        },
      ],
    },
    {
      id: '4',
      tr: [
        {
          id: '4',
          value: 'HO 00 03 10 00 HO3 Special Form',
          display: 'Homeowners 3 - Special Form',
        },
      ],
    },
    {
      id: '5',
      tr: [
        {
          id: '5',
          value: 'CSH FL HD 08 14 Hurr Ded',
          display: 'Hurricane Deductible Endorsement (HO)',
        },
      ],
    },
  ];

  notesTableHeaders: ITableTh[] = [
    { value: NotesLabelConstants.category },
    { value: NotesLabelConstants.description },
    { value: NotesLabelConstants.dateAdded },
    { value: NotesLabelConstants.addedBy }
  ];

  notesTableFormControls: ITableFormControl[] = [
    {
      name: 'field_1',
      type: 'string',
    },
    {
      name: 'field_2',
      type: 'string',
    },
    {
      name: 'field_3',
      type: 'string',
    },
    {
      name: 'field_4',
      type: 'string',
    },
  ];

  documentsTableHeaders: ITableTh[] = [
    { value: DocumentsLabelConstants.category },
    { value: DocumentsLabelConstants.description },
    // { value: DocumentsLabelConstants.file },
    { value: DocumentsLabelConstants.dateAdded }
  ];

  documentsTableFormControls: ITableFormControl[] = [
    {
      name: 'field_1',
      type: 'string',
    },
    {
      name: 'field_2',
      type: 'string',
    },
    // {
    //   name: 'field_3',
    //   type: 'string',
    // },
    {
      name: 'field_4',
      type: 'string',
    }
  ];

  formDocumentTableHeaders: ITableTh[] = [
    {
      value: PrintLabelConstants.formName,
    },
  ];

  formDocumentFormControls: ITableFormControl[] = [
    {
      name: 'field_1',
      type: 'string',
    },
  ];

  formDocumentHideItems: IHideTableItems = {
    addButton: true,
    updateIcon: true,
    deleteIcon: true,
    fileIcon: true,
  };

  bindNotesSavedData: BehaviorSubject<RiskBindNoteDTO[]> = new BehaviorSubject<RiskBindNoteDTO[]>([]);

  signatureNameList = [];

  formTypes = FormTypeConstants;

  updatedPayPlan: string;

  constructor(protected zipData: ZipData,
    protected zipCodeService: ZipcodesService,
    protected zipCodeData: ZipCodeData,
    public fileReportService: FormDocumentService,
    protected summaryData: SummaryData,
    protected ofacSearchData: OfacSearchData,
    protected interestData: InterestData,
    protected agentService: AgentService,
    protected applicantData: ApplicantData,
    protected quoteService: QuoteService,
    protected auth: AuthService,
    protected entityRiskData: EntityRiskData,
  ) {
    super();
    this.subscribeToBindNotesSavedData();
  }

  initBindAndIssueData() {
    this.billingForm = this.billingSection();
    this.paperlessDocumentForm = this.paperlessDocumentSection();
    this.signatureForm = this.signatureSection();
    this.printForm = this.printSection();
    this.dropdownValues();
    this.dateFormatOptions();

    this.interestData.interestsSaved.pipe(takeUntil(this.stop$)).subscribe(() => {
      if (!this.isMortgageeExists() && this.billingForm.get('paymentPlan').value === PaymentPlanListConstants.mortgageePayCode) {
        this.billingForm.get('paymentPlan').setValue('');
        this.billingForm.get('paymentPlan').updateValueAndValidity();

        this.billingForm.get('nextTermPaymentPlan').setValue('');
        this.billingForm.get('nextTermPaymentPlan').updateValueAndValidity();
      }
    });
  }

  paperlessDocumentSection() {
    return new FormGroup({
      enrollInPaperless: new FormControl(false, Validators.required),
      paperlessEmailAddress: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,12}$')]),
      paperlessConfirmEmailAddress: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,12}$')]),
      agreement: new FormControl(null, Validators.required)
    });
  }

  signatureSection() {
    return new FormGroup({
      signature: new FormControl('', Validators.required)
    });
  }

  billingSection() {
    return new FormGroup({
      paymentPlan: new FormControl('', Validators.required),
      payor: new FormControl(''),
      downpayment: new FormControl(null, Validators.required),
      paymentMethod: new FormControl('', Validators.required),
      firstName: new FormControl('', [Validators.required, CustomValidators.spaceValidator, Validators.maxLength(32)]),
      lastName: new FormControl('', [Validators.required, CustomValidators.spaceValidator, Validators.maxLength(31)]),
      address: new FormControl('', [Validators.required, CustomValidators.spaceValidator]),
      zipCode: new FormControl('', [Validators.required, CustomValidators.spaceValidator]),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      emailAddress: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,12}$')]),
      ccType: new FormControl('', Validators.required),
      ccNumber: new FormControl('', [Validators.required, Validators.pattern('^[0-9]')]),
      ccExpirationMonth: new FormControl('', [Validators.required, BindAndIssueValidators.checkCreditCardIsExpired(this.auth.getCustomDate())]),
      ccExpirationYear: new FormControl('', [Validators.required, BindAndIssueValidators.checkCreditCardIsExpired(this.auth.getCustomDate())]),
      ccCVV: new FormControl('', [Validators.required, CustomValidators.spaceValidator, Validators.minLength(3)]),
      ccCID: new FormControl('', [Validators.required, CustomValidators.spaceValidator]),
      totalChargeAmt: new FormControl(0), // totalChargeAmt: new FormControl(0, [Validators.required]), --temporary remove validator, rater related
      accountType: new FormControl('', Validators.required),
      routingNumber: new FormControl('', [Validators.required, CustomValidators.spaceValidator]),
      accountNumber: new FormControl('', [Validators.required, CustomValidators.spaceValidator]),
      nameOnAccount: new FormControl('', [Validators.required, CustomValidators.spaceValidator, Validators.maxLength(64)]),
      bankAccountTotalChargeAmt: new FormControl(0, [Validators.required]),
      nextTermPaymentPlan: new FormControl('', Validators.required),
      nextTermPayor: new FormControl(''),
      agreeEnrollAutoPay: new FormControl(false),
      agreeOneTimePayment: new FormControl(false),
      updateRecurringPayment: new FormControl(false),
      checkNumber: new FormControl(''),
      totalChargeAmtWithCCFee: new FormControl(''),
      agreeCreditCardFee: new FormControl(false)
    });
  }

  printSection(): FormGroup {
    return new FormGroup({
      formsDocument: new FormControl('', Validators.required)
    });
  }

  dropdownValues(): void {
    this.paymentPlanList = [
      {
        tableName: 'LvPayPlanOptions',
        tableDescription: 'Payment Plan Options',
        code: 'PPO4',
        description: 'Mortgagee Bill – Full Pay',
        isActive: true
      },
      {
        tableName: 'LvPayPlanOptions',
        tableDescription: 'Payment Plan Options',
        code: 'PPO0',
        description: 'Insured Billed – Full Pay',
        isActive: true
      },
      {
        tableName: 'LvPayPlanOptions',
        tableDescription: 'Payment Plan Options',
        code: 'PPO1',
        description: 'Insured Billed – 2 Pay',
        isActive: true
      },
      {
        tableName: 'LvPayPlanOptions',
        tableDescription: 'Payment Plan Options',
        code: 'PPO2',
        description: 'Insured Billed – 4 Pay',
        isActive: true
      },
      {
        tableName: 'LvPayPlanOptions',
        tableDescription: 'Payment Plan Options',
        code: 'PPO3',
        description: 'Insured Billed – 8 Pay',
        isActive: true
      }
    ];

    this.creditTypeList = [
      {
        tableName: 'LvCreditCardType',
        tableDescription: 'Credit Card Type',
        code: 'CCT0',
        description: 'AMEX',
        isActive: false
      },
      {
        tableName: 'LvCreditCardType',
        tableDescription: 'Credit Card Type',
        code: 'CCT1',
        description: 'DISCOVER',
        isActive: false
      },
      {
        tableName: 'LvCreditCardType',
        tableDescription: 'Credit Card Type',
        code: 'CCT2',
        description: 'VISA',
        isActive: true
      },
      {
        tableName: 'LvCreditCardType',
        tableDescription: 'Credit Card Type',
        code: 'CCT3',
        description: 'MASTERCARD',
        isActive: true
      }
    ];

    this.documentsCategoryList = _.filter(LvDocumentsCategory, ['isActive', true]);

    this.setNotesCategories(this.auth.isExternalUser);

    this.paymentMethodList = [
      {
        tableName: 'LvPaymentMethod',
        tableDescription: 'Payment Method',
        code: 'PM0',
        description: 'Check',
        isActive: true,
        isDisabled: false
      },
      {
        tableName: 'LvPaymentMethod',
        tableDescription: 'Payment Method',
        code: 'PM1',
        description: 'Credit Card',
        isActive: true,
        isDisabled: false
      },
      {
        tableName: 'LvPaymentMethod',
        tableDescription: 'Payment Method',
        code: 'PM2',
        description: 'eCheck',
        isActive: true,
        isDisabled: false
      },
      {
        tableName: 'LvPaymentMethod',
        tableDescription: 'Payment Method',
        code: 'PM3',
        description: 'Recurring Credit Card',
        isActive: true,
        isDisabled: false
      },
      {
        tableName: 'LvPaymentMethod',
        tableDescription: 'Payment Method',
        code: 'PM4',
        description: 'Recurring E-check',
        isActive: true,
        isDisabled: false
      }
    ];

    if (this.ccMonthList.length < 1 && this.ccYearList.length < 1) {
      Array(12).fill(0).map((x, i) => {
        this.ccMonthList.push({ name: `${i + 1}`, value: i + 1 });
      });

      Array(21).fill(2020).map((x, i) => {
        this.ccYearList.push({ name: `${x + i}`, value: x + i });
      });
    }

    this.accountTypeList = [
      {
        tableName: 'LvAccountType',
        tableDescription: 'Account Type',
        code: 'AT0',
        description: 'Checking',
        isActive: true
      },
      {
        tableName: 'LvAccountType',
        tableDescription: 'Account Type',
        code: 'AT1',
        description: 'Savings',
        isActive: true
      }
    ];

    this.zipList = this.zipData.zipList;
    this.riskStatusList = [
      {
        tableName: 'LvRiskStatus',
        tableDescription: 'Risk Status',
        code: 'IF',
        description: 'In-Force',
        isActive: true
      },
      {
        tableName: 'LvRiskStatus',
        tableDescription: 'Risk Status',
        code: 'PE',
        description: 'Pending',
        isActive: true
      }
    ];

    this.formDocuments = [
      {
        id: 1,
        code: 'HO 04 41 10 00 Additional Insured',
        description: 'Additional Insured - Residence Premises',
        isActive: true
      },
      {
        id: 2,
        code: 'HO 04 10 10 00 Additional Interests',
        description: 'Additional Interests - Residence Premises',
        isActive: true
      },
      {
        id: 3,
        code: 'CSH AL 07 19 Animal Liability Endorsement',
        description: 'Animal Liability Endorsement',
        isActive: true
      },
      {
        id: 4,
        code: 'CS PRV 01 20 Privacy Notice',
        description: 'Centauri Specialty Insurance Company Privacy Policy',
        isActive: true
      },
      {
        id: 5,
        code: '',
        description: 'Checklist of Coverage (VAR)',
        isActive: false
      },
      {
        id: 6,
        code: 'CSH FL CTR 07 20 - Consent to Rate',
        description: 'Consent to Rate (HO)',
        isActive: true
      },
      {
        id: 7,
        code: 'HO 04 65 05 11 Cov C Incr Spec Limits of Liab',
        description: 'Coverage C Increased Special Limits of Liability',
        isActive: true
      },
      {
        id: 8,
        code: 'CS FL DEC 01 19 Declaration',
        description: 'Declaration (VAR - HO)',
        isActive: true
      },
      {
        id: 9,
        code: 'Deductible Notification Form (DP)',
        description: 'CSH FL DNF 08 14 Ded Notification',
        isActive: true
      },
      {
        id: 10,
        code: 'CSH FL EB 10 16 Equipment Breakdown',
        description: 'Equipment Breakdown Enhancement Endorsement (HO)',
        isActive: true
      },
      {
        id: 11,
        code: 'CSH FC 05 16  Flood Endorsement HO36',
        description: 'Flood Coverage Endorsement (HO 3 HO 6)',
        isActive: true
      },
      {
        id: 12,
        code: 'CS FR 12 13 Flood Rejection Forme',
        description: 'Flood Rejection Form',
        isActive: true
      },
      {
        id: 13,
        code: 'CSH FL DNF 08 14 Ded Notification',
        description: 'Deductible Notification Form (DP)',
        isActive: true
      },
      {
        id: 14,
        code: 'CSH FL GC 08 14 GC Phys Dam  Liab',
        description: 'Golf Cart Physical Damage and Liability Endorsement',
        isActive: true
      },
      {
        id: 15,
        code: 'CSH HOJ 10 16 HO Policy Jkt',
        description: 'HO Policy Jacket',
        isActive: true
      },
      {
        id: 16,
        code: 'CSH FL HC 08 14 Home Computer Cov',
        description: 'Home Computer Coverage',
        isActive: true
      },
      {
        id: 17,
        code: 'HO 00 03 10 00 HO3 Special Form',
        description: 'Homeowners 3 - Special Form',
        isActive: true
      },
      {
        id: 18,
        code: 'CSH FL IDX03 08 14 Policy Index',
        description: 'Homeowners 3 - Special Form - Policy Index',
        isActive: true
      },
      {
        id: 19,
        code: 'CSH FL HOAP 07 19 Application',
        description: 'Homeowners Application New Business ',
        isActive: true
      },
      {
        id: 20,
        code: 'CSH FL HD 08 14 Hurr Ded',
        description: 'Hurricane Deductible Endorsement (HO)',
        isActive: true
      },
      {
        id: 21,
        code: 'CSH FL IDF 10 16 Identity Fraud Expense',
        description: 'Identity Fraud Expense Coverage',
        isActive: true
      },
      {
        id: 22,
        code: 'CSH FL OLN 08 14 O or L Notice',
        description: 'Important Information Regarding Ordinance or Law Coverage',
        isActive: true
      },
      {
        id: 23,
        code: 'CSH FL IRC 08 14 Incr Repl Cost Cov',
        description: 'Increased Replacement Cost Coverage',
        isActive: true
      },
      {
        id: 24,
        code: 'CSH FL LF 08 14 Limited Fungi Cov End',
        description: 'Limited Fungi, Mold, Wet or Dry Rot, or Bacteria Coverage',
        isActive: true
      },
      {
        id: 25,
        code: 'CSH LSE 08 14 Limited Screened Encl  Carpot Cov',
        description: 'Limited Screened Enclosure and Carport Coverage (ACV)',
        isActive: true
      },
      {
        id: 26,
        code: 'CSH LSE2 07 19 Limited Screened Enclosure  Carpot Cov',
        description: 'Limited Screened Enclosure and Carpot Coverage (RC)',
        isActive: true
      },
      {
        id: 27,
        code: 'Limited Water Damage Coverage (HO)',
        description: 'CSH FL LWD 08 19 Limited Water Damage Coverage',
        isActive: true
      },
      {
        id: 28,
        code: 'HO 04 35 10 00 Loss Assess Cov',
        description: 'Loss Assessment Coverage (HO)',
        isActive: true
      },
      {
        id: 29,
        code: 'CS FL CX 10 16 Notice of Canc HO',
        description: 'Notice of Cancellation (VAR - HO)',
        isActive: true
      },
      {
        id: 30,
        code: 'CSH FL NCPT3 07 19 Notice of Change of Policy Terms - Homes over 40',
        description: 'Notice of Change of Policy Terms - Homes over 40 (HO)',
        isActive: true
      },
      {
        id: 31,
        code: 'CS NR 01 12 Notice of Nonrenewal HO',
        description: 'Notice of Nonrenewal - Homeowners',
        isActive: true
      },
      {
        id: 32,
        code: '',
        description: 'Notice of Premium Discounts for Hurricane Loss Mitigation',
        isActive: false
      },
      {
        id: 33,
        code: 'CSH OEC 08 14 Opt to Excl Cont Cov',
        description: 'Option to Exclude Contents Coverage ',
        isActive: true
      },
      {
        id: 34,
        code: 'CS FL WHR 08 14 Wind Hail Rej',
        description: 'Option to Exclude Windstorm or Hail Coverage (HO)',
        isActive: true
      },
      {
        id: 35,
        code: 'CSH FL SLC 08 14 Sinkhole Cov. HO3',
        description: 'Optional Sinkhole Loss - Section I Property Coverage (HO3) ',
        isActive: true
      },
      {
        id: 36,
        code: 'CSH FL OL 08 14 Ord or Law Cov 25',
        description: 'Ordinance or Law Coverage 25% (HO)',
        isActive: true
      },
      {
        id: 37,
        code: 'CSH FL OL2 08 14 Ord or Law Cov 50',
        description: 'Ordinance or Law Coverage 50% (HO)',
        isActive: true
      },
      {
        id: 38,
        code: 'CSH FL OC03 07 19 HO3 Outline of Coverage',
        description: 'Outline of Coverage - Homeowners Policy ',
        isActive: true
      },
      {
        id: 39,
        code: 'CSH FL PNJ 10 16 Personal Injury Coverage',
        description: 'Personal Injury Coverage - Florida',
        isActive: true
      },
      {
        id: 40,
        code: 'CS 0028 08 11 Personal Policy Change Req',
        description: 'Personal Policy Change Request',
        isActive: true
      },
      {
        id: 41,
        code: 'CSH FL PPR 08 14 Pers Prop Replc Cost',
        description: 'Personal Property Replacement Cost Loss Settlement (HO)',
        isActive: true
      },
      {
        id: 42,
        code: 'CSH FL ALM 08 14 Prem Alm Fire Syst',
        description: 'Premises Alarm or Fire Protection System (HO)',
        isActive: true
      },
      {
        id: 43,
        code: '',
        description: 'Renewal Letter (HO-3 DP-3)',
        isActive: false
      },
      {
        id: 44,
        code: 'CSH FL SPP 07 19 Scheduled Personal Property',
        description: 'Scheduled Personal Property Endorsement ',
        isActive: true
      },
      {
        id: 45,
        code: 'CSH SDW 04 18 Seasonal Dwelling',
        description: 'Seasonal Dwelling Endorsement ',
        isActive: true
      },
      {
        id: 46,
        code: 'CSH FL SL 07 20 Service Line Enhancement Endorsement',
        description: 'Service Line Enhancement Endorsement (HO)',
        isActive: true
      },
      {
        id: 47,
        code: 'CSH SLSR 10 16 FL Sinkhole Loss Cov SelRej',
        description: 'Sinkhole Loss Coverage Selection/Rejection Form (HO)',
        isActive: true
      },
      {
        id: 48,
        code: 'CSH FL SPC 07 20 Special Personal Property Cov',
        description: 'Special Personal Property Coverage',
        isActive: true
      },
      {
        id: 49,
        code: 'CSH FL SPV03 07 19 Special Provisions HO3',
        description: 'Special Provisions - Florida (HO3)',
        isActive: true
      },
      {
        id: 50,
        code: 'CSH FL SAP 08 19 Star Advantage Package',
        description: 'Star Advantage Package Endorsement',
        isActive: true
      },
      {
        id: 51,
        code: 'CSH FL SP 07 19 Star Package',
        description: 'Star Package Endorsement',
        isActive: true
      },
      {
        id: 52,
        code: '',
        description: 'Uniform Mitigation Verification Inspection Form',
        isActive: false
      },
      {
        id: 53,
        code: 'IL P 001 01 04 OFAC',
        description: 'US Treasure Departments OFAC Advisory Notice to Policyholders',
        isActive: true
      },
      {
        id: 54,
        code: 'CSH FL WBU03 07 19 Water Back Up',
        description: 'Water Back Up and Sump Overflow or Discharge (HO3)',
        isActive: true
      },
      {
        id: 55,
        code: 'CSH FL WDE03 07 20 Water Damage Excl (HO3)',
        description: 'Water Damage Exclusion Endorsement (HO3)',
        isActive: true
      },
      {
        id: 56,
        code: '',
        description: 'Welcome Letter',
        isActive: false
      },
      {
        id: 57,
        code: 'HO 04 89 07 01 Wind Hail Excl.',
        description: 'Windstorm or Hail Exclusion - Florida (HO)',
        isActive: false
      },
      {
        id: 58,
        code: 'HO 04 21 07 01 Windstorm Protective Devices - Florida',
        description: 'Windstorm Protective Devices - Florida',
        isActive: false
      }
    ];
  }

  dateFormatOptions() {
    this.ccExpiration = {
      dateRange: false,
      dateFormat: 'mm/yyyy'
    };

    this.requestedEffectiveDateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy'
    };

    this.dateAddedOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy'
    };
  }

  populateBindAndIssue(data: EntityRiskDTO) {
    this.clearData();

    let subAgencyId = this.applicantData.copiedInformation ? this.applicantData.copiedInformation.subAgencyId : data?.risks[0]?.subAgencyId;
    const riskBindDocuments: RiskBindDocumentDTO[] = data?.risks[0]?.riskDetails[0]?.riskBinds[0].riskBindDocuments;
    const riskUWApprovals: RiskUWApprovalDTO2[] = data?.risks[0]?.riskDetails[0]?.riskUWApproval;

    if (!subAgencyId) {
      subAgencyId = data?.risks[0]?.subAgencyId;
    }

    this.getAllSignature(subAgencyId);

    const riskBinds = data?.risks[0]?.riskDetails[0]?.riskBinds;
    const payPlanCode = this.paymentPlanList.find(x => x.code === riskBinds[0]?.riskBindBilling?.payPlanOption)?.code;
    const nextTermPaymentPlan =  data?.risks[0]?.riskDetails[0]?.riskBinds[0]?.riskBindBilling?.nextTermpaymentPlan;
    const isPayplanNotEqualToNextTerm = payPlanCode !== nextTermPaymentPlan;
    this.isFromRenewal = data.risks[0]?.isFromRenewal;

    if (riskBinds.length !== 0 && riskBinds[0]) {

      if (isPayplanNotEqualToNextTerm) {
        if (payPlanCode === PaymentPlanListConstants.fullPayCode || this.updatedPayPlan === PaymentPlanListConstants.fullPayCode) {
          this.billingForm.get('paymentPlan').setValue(PaymentPlanListConstants.fullPayCode);
        } else if (payPlanCode === PaymentPlanListConstants.twoPayCode || this.updatedPayPlan === PaymentPlanListConstants.twoPayCode) {
          this.billingForm.get('paymentPlan').setValue(PaymentPlanListConstants.twoPayCode);
        } else if (payPlanCode === PaymentPlanListConstants.fourPayCode || this.updatedPayPlan === PaymentPlanListConstants.fourPayCode) {
          this.billingForm.get('paymentPlan').setValue(PaymentPlanListConstants.fourPayCode);
        } else if (payPlanCode === PaymentPlanListConstants.eightPayCode || this.updatedPayPlan === PaymentPlanListConstants.eightPayCode) {
          this.billingForm.get('paymentPlan').setValue(PaymentPlanListConstants.eightPayCode);
        } else if (payPlanCode === PaymentPlanListConstants.mortgageePayCode || this.updatedPayPlan === PaymentPlanListConstants.mortgageePayCode) {
          this.billingForm.get('paymentPlan').setValue(PaymentPlanListConstants.mortgageePayCode);
        }
      } else {
        this.setRenewalLogic(this.isFromRenewal, riskBinds[0]);
      }


      this.billingForm.get('firstName').setValue(riskBinds[0]?.riskBindBilling?.firstName);
      this.billingForm.get('lastName').setValue(riskBinds[0]?.riskBindBilling?.lastName);
      this.billingForm.get('address').setValue(riskBinds[0]?.riskBindBilling?.address);
      this.billingForm.get('zipCode').setValue(riskBinds[0]?.riskBindBilling?.zipCode?.toString());
      this.populateCityList(riskBinds[0]?.riskBindBilling?.zipCode?.toString());
      this.billingForm.get('city').setValue(riskBinds[0]?.riskBindBilling?.city);
      this.billingForm.get('state').setValue(riskBinds[0]?.riskBindBilling?.stateCode);
      this.billingForm.get('emailAddress').setValue(riskBinds[0]?.riskBindBilling?.emailAddress);
      this.billingForm.get('ccType').setValue(this.creditTypeList.find(x => x.code === riskBinds[0]?.riskBindBilling?.creditCardType)?.code);
      this.billingForm.get('ccExpirationMonth').setValue(riskBinds[0]?.riskBindBilling?.expirationMonth);
      this.billingForm.get('ccExpirationYear').setValue(riskBinds[0]?.riskBindBilling?.expirationYear);
      this.billingForm.get('ccCVV').setValue(riskBinds[0]?.riskBindBilling?.cvv);
      this.billingForm.get('ccNumber').setValue(riskBinds[0]?.riskBindBilling?.creditCardNumber);
      this.billingForm.get('ccCID').setValue(riskBinds[0]?.riskBindBilling?.cid);
      this.billingForm.get('accountType').setValue(this.accountTypeList.find(x => x.code === riskBinds[0]?.riskBindBilling?.accountType)?.code);
      this.billingForm.get('routingNumber').setValue(riskBinds[0]?.riskBindBilling?.routingNumber);
      this.billingForm.get('accountNumber').setValue(riskBinds[0]?.riskBindBilling?.accountNumber);
      this.billingForm.get('nameOnAccount').setValue(riskBinds[0]?.riskBindBilling?.nameOnAccount);
      this.billingForm.get('nextTermPaymentPlan').setValue(this.paymentPlanList.find(x => x.code === riskBinds[0]?.riskBindBilling?.nextTermpaymentPlan)?.code);
      this.billingForm.get('agreeOneTimePayment').setValue(riskBinds[0]?.riskBindBilling?.isAgreeOneTimePayment);
      this.billingForm.get('payor').setValue(this.interestData.selectedPayorId);

      // Unused property
      // this.billingForm.get('cardHolderDetails').setValue(riskBinds[0]?.riskBindBilling?.lastName);
      // this.billingForm.get('downpayment').setValue(riskBinds[0]?.riskBindBilling?.downpaymentAmount ?? null);
      // this.billingForm.get('bankAccountTotalChargeAmt').setValue(riskBinds[0]?.riskBindBilling?.totalChargeAmountBank);
      // this.billingForm.get('agreeEnrollAutoPay').setValue(riskBinds[0]?.riskBindBilling?.isAgreeEnrollAutoPay);
      //this.billingForm.get('totalChargeAmt').setValue(riskBinds[0]?.riskBindBilling?.totalChargeAmount);

      const paymentMethod = riskBinds[0]?.riskBindBilling?.paymentMethod;
      this.billingForm.get('paymentMethod').setValue(this.paymentMethodList.find(x => x.code === paymentMethod)?.code ?? '');

      if (this.billingForm.get('paymentMethod').value === '') {
        this.showTotalChargeAmountField = false;
      }
      this.paperlessDocumentForm.get('enrollInPaperless').setValue(riskBinds[0]?.riskBindPaperless?.isEnrollInPaperLess);
      this.paperlessDocumentForm.get('paperlessEmailAddress').setValue(riskBinds[0]?.riskBindPaperless?.emailAddressPaperless);
      this.paperlessDocumentForm.get('paperlessConfirmEmailAddress').setValue(riskBinds[0]?.riskBindPaperless?.emailAddressPaperless);
      this.paperlessDocumentForm.get('agreement').setValue(riskBinds[0]?.riskBindPaperless?.isAgreeToPaperless);

      this.signatureForm.get('signature').setValue(this.signatureList?.find(x => x.name === +riskBinds[0]?.riskBindSignature?.signature)?.value ?? '');

      // Populate Document Section
      this.populateDocumentSection(riskBindDocuments, riskUWApprovals);
      this.populateBindnotes(riskBinds[0].riskBindNotes);
    }

  }

  populateDocumentSection(riskBindDocuments: RiskBindDocumentDTO[], riskUWApprovals: RiskUWApprovalDTO2[]): void {
    this.documentsList = [];
    this.documentsTableRows = [];

    this.populateBindDocuments(riskBindDocuments);
    this.populateUwrDocuments(riskUWApprovals);
    this.initDocumentData(this.documentsList);
    this.mapDocumentsTableToDocumentList();
  }

  populateBindDocuments(riskBindDocuments: RiskBindDocumentDTO[]): void {
    this.documentsList = riskBindDocuments.map(document => ({
      id: document.id,
      description: document?.description,
      category: document?.category,
      createdDate: document?.createdDate,
      fileName: document?.fileName,
      riskBindId: document?.riskBindId,
      documentsId: document?.id,
      filePath: document?.filePath,
      createdByFullName: document?.createdByFullName,
      createdBy: document?.createdBy,
      documentType: document?.documentType
    }));
  }

  populateUwrDocuments(riskUWApprovals: RiskUWApprovalDTO2[]): void {
    let riskUWApprovalDocuments: any[] = [];

    riskUWApprovals?.map(documents => {
      documents.riskUWApprovalDocuments.map(document => {
        riskUWApprovalDocuments = [...riskUWApprovalDocuments, document];
      });
    });

    this.documentsList?.forEach(document => {
      const uwrDocumentCategory = 'BD';
      const createdBySystem = -1;

      if (document.category === uwrDocumentCategory &&
          document.description === 'Referral Item Description' &&
          document?.documentType === DocumentTypeConstant.riskUWApprovalDocument) {
        document.createdBy = createdBySystem;
      }
    });
  }

  setRenewalLogic(isFromRenewal?: boolean, riskBinds?: any): void {

    if (isFromRenewal) {
      this.nextPaymentPlanFromRenewal = riskBinds?.riskBindBilling?.nextTermpaymentPlan;
      this.billingForm.get('paymentPlan').setValue(this.nextPaymentPlanFromRenewal);
      this.billingForm.get('nextTermPaymentPlan').setValue(this.nextPaymentPlanFromRenewal);
    }
  }

  clearData(): void {
    this.notesList = [];
    this.notesTableRows = [];
  }

  populateBindnotes(riskBindNotes: RiskBindNoteDTO[]): void {
    this.notesTableRows = [];

    this.notesList = riskBindNotes.map(note => ({
      id: note?.id,
      riskBindId: note?.riskBindId,
      notesId: note?.id,
      description: note?.description,
      category: note?.category,
      createdDate: note?.createdDate,
      createdBy: note?.createdBy,
      createdByFullName: note?.createdByFullName,
      isAdd: false
    }));

    this.setInternalNotesList();
    this.initData(this.notesList);
  }

  subscribeToBindNotesSavedData(): void {
    this.bindNotesSavedData.pipe(takeUntil(this.stop$)).subscribe(result => {
      if (result.length) {
        this.clearData();
        this.populateBindnotes(result);
      }
    });
  }

  populateCityList(zipCode: string) {
    if (zipCode && zipCode !== '') {
      this.zipCodeService.getGenericZipCode(zipCode).pipe(takeUntil(this.stop$)).subscribe(res => {
        const zipCodeRes: any = res;
        this.zipCodeData.billingCityList = [];

        zipCodeRes.map(val => {
          const city = val.city;
          this.zipCodeData.billingCityList.push(city);
        });
      });
    }
  }

  protected mapDocumentsTableToDocumentList() {
    this.documentsTableRows.forEach((item, index) => {
      this.documentsList[index].id = item.id;
    });
  }

  initData(notesList: any) {
    this.isFromAgency = false;
    notesList.map(note => {
      const notes = {
        id: note?.id,
        description: note?.description,
        category: note?.category,
        dateAdded: note?.createdDate,
        riskBindId: note?.riskBindId,
        notesId: note?.notesId,
        createdBy: note?.createdBy,
        createdByFullName: note?.createdByFullName,
        isAdd: note?.isAdd
      };
      if (!notes.description.includes('OFAC')) {
        this.addTableItem(notes);
      }

    });
  }

  initAgencyNotesData(notesList: any) {
    this.notesList = notesList;
    this.isFromAgency = true;
    notesList.map(note => {
      const notes = {
        id: note?.id,
        description: note?.description,
        category: note?.category,
        dateAdded: note?.createdDate,
        agencyId: note?.agencyId,
        notesId: note?.id,
        createdBy: note?.createdBy,
        createdByFullName: note?.createdByFullName,
        isAdd: note?.isAdd
      };

      this.addTableItem(notes);
    });
  }

  addTableItem(newItem: any): void {
    const id: any = uuidv4();

    this.notesTableRows.push({
      id: newItem.notesId ?? id,
      createdBy: newItem.createdBy,
      tr: this.getTr(newItem, id)
    });
  }

  getTr(newItem: any, id: any): ITableTd[] {
    const fields: string[] = [
      'category',
      'description',
      'dateAdded',
      'addedBy'
    ];
    const tr: ITableTd[] = [];

    fields.forEach((item, index) => {
      const display: string = this.updateDisplay(item, newItem);

      tr.push({
        id: newItem.notesId ?? id,
        value: Boolean(newItem[item]) ? newItem[item] : '',
        display: display
      });
    });

    return tr;
  }

  updateDisplay(item: any, newItem: any): string {
    switch (item) {
      case 'dateAdded':
        const cntrDate = newItem[item]?.singleDate?.formatted ? newItem[item]?.singleDate?.formatted : this.mapDate(newItem[item]);
        return String(moment(cntrDate).format('MM/DD/YYYY'));
      case 'category':
        if (this.isFromAgency) {
          return Boolean(newItem[item]) ? _.find(LvAgencyNotesCategories, ['code', newItem[item]])?.description : '';
        } else {
          return Boolean(newItem[item]) ? _.find(NotesCategories, ['code', newItem[item]])?.description : '';
        }
      case 'addedBy':
        return Boolean(newItem?.createdByFullName) ? newItem.createdByFullName : '';
      default:
        return Boolean(newItem[item]) ? String(newItem[item]) : '';
    }
  }

  initDocumentData(documentsList: any) {
    documentsList.map(x => {
      const documents = {
        id: x.id,
        description: x.description,
        category: x.category,
        dateAdded: x.createdDate,
        fileName: x.fileName,
        riskBindId: x.riskBindId,
        documentsId: x.id,
        isUploaded: x.isUploaded,
        createdBy: x.createdBy,
        documentType: x.documentType
      };
      this.addDocumentTableItem(documents);
    });
  }

  addDocumentTableItem(newItem: any): void {
    const tr: ITableTd[] = [];
    const fields: string[] = [
      'category',
      'description',
      // 'fileName',
      'dateAdded'
    ];

    fields.forEach((item, index) => {
      let display: any;

      switch (item) {
        case 'category':
          display = this.documentsCategoryList.find(category => category.code === newItem[item])?.description;
          break;
        case 'dateAdded':
          const cntrDate = new Date(newItem[item]);
          display = `${('0' + (cntrDate.getMonth() + 1)).slice(-2)}/${('0' + (cntrDate.getDate())).slice(-2)}/${cntrDate.getFullYear()}`;
          break;
        default:
          display = Boolean(newItem[item]) ? String(newItem[item]) : '';
          break;
      }

      tr.push({
        id: newItem.id,
        value: Boolean(newItem[item]) ? newItem[item] : '',
        display: display
      });
    });

    this.documentsTableRows.push({
      id: newItem.id,
      tr: tr,
      createdBy: newItem.createdBy
    });

    this.checkRenewalOfferDocs();
  }

  checkRenewalOfferDocs(): void {
    const isSystemGenerated = 'System';

    this.documentsTableRows = this.documentsTableRows.map(item => {
      const document = this.documentsList.find(document => document.id === item.id);

      if (document.createdByFullName === isSystemGenerated) {
        item.disableUpdateIcon = true;
        item.disableDeleteIcon = true;
      }

      return item;
    });
  }

  mapDate(date?) {
    const month = new Date(date).getMonth() + 1;
    const day = new Date(date).getDate();
    const year = new Date(date).getFullYear();

    return `${month}/${day}/${year}`;
  }

  downPaymentComputation(paymentPlan?: string): void {
    const estimatedPremium = this.getTotalPremium();
    const taxes = +this.summaryData.SummaryForm.get('taxes').value;
    const fees = +this.summaryData.SummaryForm.get('fees').value;
    let downPaymentAmount = 0;
    switch (paymentPlan) {
      case PaymentPlanListConstants.fullPayCode:
        this.billingForm.get('downpayment').setValue(null);
        break;
      case PaymentPlanListConstants.twoPayCode:
        downPaymentAmount = (this.percentage(estimatedPremium, PaymentPlanListConstants.sixtyPercent));
        this.billingForm.get('downpayment').setValue(downPaymentAmount + taxes + fees);
        break;
      case PaymentPlanListConstants.fourPayCode:
        downPaymentAmount = (this.percentage(estimatedPremium, PaymentPlanListConstants.fourtyPercent));
        this.billingForm.get('downpayment').setValue(downPaymentAmount + taxes + fees);
        break;
      case PaymentPlanListConstants.eightPayCode:
        downPaymentAmount = (this.percentage(estimatedPremium, PaymentPlanListConstants.thirtyPercent));
        this.billingForm.get('downpayment').setValue(downPaymentAmount + taxes + fees);
        break;
      case PaymentPlanListConstants.mortgageePayCode:
        this.billingForm.get('downpayment').setValue(0);
        break;
    }
  }

  percentage(amount, percent): number {
    return (amount / 100) * percent;
  }

  totalChargeAmountComputation(data?: RenewalBillingSummaryResponseDTO): void {
    const estimatedPremium = this.getTotalPremium();
    const downpayment = +this.billingForm.get('downpayment').value;
    const taxes = Math.round(+this.summaryData.SummaryForm.get('taxes').value);
    const fees = +this.summaryData.SummaryForm.get('fees').value;
    const isFullPay = this.billingForm.get('paymentPlan').value === PaymentPlanListConstants.fullPayCode || this.billingForm.get('paymentPlan').value === PaymentPlanListConstants.mortgageePayCode;
    const balanceFromExpPolicy = data ? data?.installmentSchedule[0]?.balance : 0;
    const totalChargeAmount = isFullPay ? (estimatedPremium + taxes + fees + balanceFromExpPolicy) : (downpayment  + balanceFromExpPolicy);

    this.billingForm.get('totalChargeAmt').setValue(totalChargeAmount);
    this.billingForm.get('bankAccountTotalChargeAmt').setValue(totalChargeAmount);

    const creditFeeAmount = (totalChargeAmount * PolicyBillingConstants.creditCardFee).toFixed(2);
    this.billingForm.get('totalChargeAmtWithCCFee').setValue((totalChargeAmount + Number(creditFeeAmount)).toFixed(2));
  }

  getTotalPremium(): number {
    const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;
    let totalPremium: number = 0;
    if (this.isNullOrWhiteSpace(riskDetailId)) {
      const raterResultKey = `${Rater.raterResult}_${riskDetailId}`;
      const storage = JSON.parse(localStorage.getItem(raterResultKey));
      if (storage == null) return totalPremium;

    let premium: any;

    if (this.quoteService.formType === this.formTypes.HO3 ||
      this.quoteService.formType === this.formTypes.HO4 ||
      this.quoteService.formType === this.formTypes.HO6) {

      premium = storage.filter(r =>
        (r.groupName === PremiumConstants.TotalPremiumWithAdjGroupName && r.stepAlias === PremiumConstants.TotalPremiumWithAdjStepAlias)
      );
    } else if (this.quoteService.formType === this.formTypes.DP3) {

      premium = storage.filter(r =>
        (r.peril.toUpperCase() === PremiumConstants.Additive && r.groupName === PremiumConstants.TotalPremiumGroupName && r.stepAlias === PremiumConstants.TotalPremiumStepAlias)
      );
    }

    premium.map(value => {
      totalPremium = totalPremium + Number(value.perilValue);
    });
    }
      return totalPremium;
  }

  get allowedPaymentPlans(): any {
    let paymentPlans = this.paymentPlanList;

    if (!this.isMortgageeExists()) {
      paymentPlans = paymentPlans.filter(x => x.code !== PaymentPlanListConstants.mortgageePayCode);
    }

    return paymentPlans;
  }

  get payorList(): any {
    const mortgageeCode = 'mort';
    const descOfInterestCodePremCo = 'DI4';
    return this.interestData.interestList.filter(x => x.interestType.toLowerCase() !== mortgageeCode && x.descriptionOfInterest !== descOfInterestCodePremCo );
  }

  get previousEndorsementPayorList(): any {
    const mortgageeCode = 'mort';
    const descOfInterestCodePremCo = 'DI4';
    return this.interestData.previousEndorsementInterestList.filter(x => x.interestType.toLowerCase() !== mortgageeCode && x.descriptionOfInterest !== descOfInterestCodePremCo);
  }

  protected isMortgageeExists(): boolean {
    const mortgageeCode = 'mort';
    return this.interestData.interestList.filter(x => x.interestType.toLowerCase() === mortgageeCode).length > 0;
  }

  get hasCommissionRate(): boolean {
    return this.entityRiskData?.getAgencyHasCommissionRate();
  }

  getAllSignature(subAgencyID: string): void {
    this.signatureList = [];
    this.signatureNameList = [];
    const loginDate = this.auth.LoginDateFormGroup.get('loginDate').value; 
    const dateForChecking = loginDate !== null && loginDate !== undefined ? new Date(loginDate) : new Date();
    let subAgencylicenseExpirationDate: Date;

    if (subAgencyID) {
      this.agentService.getSubAgenciesById(subAgencyID).subscribe((response: SubAgenciesResponseDTO) => {
        if (response) {
          subAgencylicenseExpirationDate = new Date(response?.licenseExpirationDate);
        } else {
          subAgencylicenseExpirationDate = new Date();
        }

        if (subAgencylicenseExpirationDate > dateForChecking) {
          this.agentService.getAllActiveLicenseAgent(subAgencyID).subscribe(res => {
            if (res) {
              res.forEach(x => {
                this.signatureList.push({ name: x?.entity?.fullName, email: x?.entity?.workEmailAddress, id: x?.id });
              });
              this.signatureNameList = this.signatureList;
            }
          }, err => {
            NotifUtils.showError(JSON.stringify(err.error));
          });
        }
      }, err => {
        NotifUtils.showError(JSON.stringify(err.error));
      });
    }
  }

  onSelectSignature(event: TypeaheadMatch): void {
    this.selectedSignature = [];
    this.selectedSignature.push(event.item);
  }

  setNotesCategories(isExternalUser: boolean): void {
    if (isExternalUser) {
      const internal: string = 'INTR';

      this.notesCategories = LvNotesCategories.filter(item => {
        return item.code !== internal;
      });

      return;
    }

    this.notesCategories = LvNotesCategories;
  }

  setInternalNotesList(): void {
    if (this.auth.isExternalUser) {
      const internal: string = 'INTR';
      const notesList: RiskBindNoteDTO[] = [...this.notesList];

      this.notesList = notesList.filter((item: RiskBindNoteDTO) => {
        return item.category !== internal;
      });

      this.internalNotesList = notesList.filter((item: RiskBindNoteDTO) => {
        return item.category === internal;
      });
    }
  }

  get isEcheckOrRecurringEcheck(): boolean {
    return this.billingForm.get('paymentMethod')?.value === PaymentMethod.RecurringECheck ||
    this.billingForm.get('paymentMethod')?.value === PaymentMethod.ECheck;
  }

  get isCreditCard(): boolean {
    return this.billingForm.get('paymentMethod')?.value === PaymentMethod.CreditCard ||
    this.billingForm.get('paymentMethod')?.value === PaymentMethod.RecurringCreditCard;
  }

  isNullOrWhiteSpace(data: string): boolean {
    if (data) {
      return /\S/.test(data);
    }
    return false;
  }
}
