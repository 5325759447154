import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommissionConstant } from '../../../shared/constants/commission-constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AuthService } from '../../../core/services/auth.service';
import { ErrorMessageConstant } from '../../../shared/constants/error-message.constants';
import { NachaService } from '../../../core/services/nacha.service';
import Utils from '../../../shared/utilities/utils';
import { CheckCommissionFileRequestDTO, CheckCommissionFileResponseDTO, DownloadCommissionFileRequestDTO, EmailCommissionFileRequestDTO, GenerateCommissionFileRequestDTO, GenerateNachaRequestDTO, IGenerateNachaRequestDTO, PreviewCommissionFileRequestDTO } from '../../../shared/models/data/dto/nacha/nacha.dto';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../shared/base-class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { FileSaverService } from 'ngx-filesaver';
import { PolicyService } from '../../../core/services/submission/policy.service';
import *  as fileSaver from 'file-saver';
import { CommissionData } from 'app/modules/reports/data/commission.data';

export const COMMISSION_FILE_ADJUSTMENT_RECORD = 2106;
export const COMMISSION_FILE_GENERATION_IS_NOT_COMPLETE = 4127;
export const COMMISSION_FILE_NO_DETAIL_RECORD = 4104;
export const COMMISSION_FILE_NO_DETAIL_RECORD_1 = 4106;
export const COMMISSION_EMAIL_IN_PROGRESS = 4135;
export const COMMISSION_FILE_ALREADY_EMAILED = 4128;

@Component({
  selector: 'app-commission-modal',
  templateUrl: './commission-modal.component.html',
  styleUrls: ['./commission-modal.component.scss']
})

export class CommissionModalComponent extends BaseClass implements OnInit {

  emailReportModalRef: BsModalRef = null;

  public ErrorMessageConstant = ErrorMessageConstant;
  commissionForm: FormGroup;
  CommissionConstant = CommissionConstant;
  currentMonth: number;
  currentYear: number;
  filePath: string;
  achButtonStatus: any = {
    download: false,
    preview: false,
    generate: false,
    email: false,
    emailReport: false,
  };
  disableBankSelect = false;

  LvMonth: any = [
    {
      value: '01',
      description: 'January'
    },
    {
      value: '02',
      description: 'February'
    },
    {
      value: '03',
      description: 'March'
    },
    {
      value: '04',
      description: 'April'
    },
    {
      value: '05',
      description: 'May'
    },
    {
      value: '06',
      description: 'June'
    },
    {
      value: '07',
      description: 'July'
    },
    {
      value: '08',
      description: 'August'
    },
    {
      value: '09',
      description: 'September'
    },
    {
      value: '10',
      description: 'October'
    },
    {
      value: '11',
      description: 'November'
    },
    {
      value: '12',
      description: 'December'
    }
  ];

  LvYear: number[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private authService: AuthService,
    private nachaService: NachaService,
    public httpClient: HttpClient,
    private fileSaverService: FileSaverService,
    private policyService: PolicyService,
    private modalService: BsModalService,
    private commissionData: CommissionData
  ) {
    super();
  }

  get customDate(): Date {
    return this.authService.getCustomDate();
  }

  ngOnInit() {
    this.commissionForm = this.fb.group({
      month: new FormControl(null, [Validators.required]),
      year: new FormControl(null, [Validators.required]),
      bank: new FormControl('', [Validators.required]),
    });
    this.setYearLv();
    this.setCurrentMonthAndYear();
    this.commissionForm.markAllAsTouched();
    this.checkACHFile();
    this.getNachaBank();
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  setYearLv(): void {
    const yearCounterLimit = 13;
    let yearCounter = 1;
    let initYear = 2018;
    do {
      this.LvYear.push(initYear);
      yearCounter += 1;
      initYear += 1;
    } while (yearCounterLimit >= yearCounter);
  }

  setCurrentMonthAndYear(): void {
    const systemDate = this.customDate;
    let currentMonth = (systemDate.getMonth() + 1).toString();
    const currentYear = systemDate.getFullYear();
    if (currentMonth.length === 1) {
      currentMonth = `0${currentMonth}`;
    }

    this.commissionForm.get('month').setValue(currentMonth);
    this.commissionForm.get('year').setValue(currentYear);
  }

  getSelectedMonth(): string {
    return this.LvMonth.find(a => a.value === this.commissionForm.get('month')?.value)?.description;
  }

  getSelectedYear(): string {
    return this.commissionForm.get('year')?.value;
  }

  get commissionACHData(): CheckCommissionFileResponseDTO {
    return this.commissionData.commissionACHData;
  }

  checkACHFile(): void {
    Utils.blockUI();
    this.filePath = '';
    const checkCommissionFileRequest: CheckCommissionFileRequestDTO = {
      month: Number(this.commissionForm.get('month')?.value),
      year: this.commissionForm.get('year')?.value
    };
    this.nachaService.checkCommissionFile(checkCommissionFileRequest).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.commissionData.commissionACHData = result;
      Utils.unblockUI();
    },
      err => {
        Utils.unblockUI();
      });
  }

  getACHRequestPayload(): GenerateNachaRequestDTO {
    const bankId = this.commissionForm.get('bank')?.value;
    const request = new GenerateNachaRequestDTO();
    request.year = this.commissionForm.get('year')?.value;
    request.month = Number(this.commissionForm.get('month')?.value);
    request.bankId = this.commissionForm.get('bank')?.value;
    const nachaBank = this.commissionData.nachaBank.find(a => a.id === request.bankId);
    request.stateCode = nachaBank.nacha.state;
    request.routingType = nachaBank.nacha.nachaRoutingType;

    return request;
  }

  downloadFileLocally(filePath: string, isPreview: boolean, isCommissionStatement: boolean): void {
    Utils.customLoaderMessage('Downloading file');
    const filename = filePath.replace(/^.*[\\\/]/, '');
    this.policyService.generateSASURL(filePath).pipe(takeUntil(this.stop$)).subscribe(resultSASURL => {
      resultSASURL = resultSASURL.includes('#') ? resultSASURL.replace(/#/g, '%23') : resultSASURL;
      resultSASURL = resultSASURL.includes(' ') ? resultSASURL.replace(/ /g, '%20') : resultSASURL;
      fetch(resultSASURL).then(res =>
        res.blob()).then((blob) => {
          fileSaver.saveAs(blob, filename);
        });
      Utils.unblockUI();
    }, error => {
      NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
      Utils.unblockUI();
    });
  }

  checkMonth(month: number): boolean {
    const selectedYear = Number(this.commissionForm.get('year')?.value) ?? 0;
    const currentDate = this.customDate;
    if (Number(currentDate.getFullYear() > selectedYear)) {
      return true;
    } else {
      return (this.customDate.getMonth() + 1) >= Number(month);
    }
  }

  checkYear(year: number): boolean {
    const selectedMonth = Number(this.commissionForm.get('month')?.value) ?? 0;
    const selectedYear = Number(this.commissionForm.get('year')?.value) ?? 0;
    const currentDate = this.customDate;
    if (Number(currentDate.getMonth() + 1) < selectedMonth && selectedYear >= currentDate.getFullYear()) {
      const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
      this.commissionForm.get('month').setValue(month);
      return true;
    } else {
      return this.customDate.getFullYear() >= Number(year);
    }
  }

  isCurrentMonth(): boolean {
    const currentDate = this.customDate;
    const currentMonth = this.customDate.getMonth() + 1;
    const selectedMonth = Number(this.commissionForm.get('month')?.value) ?? 0;
    const selectedYear = Number(this.commissionForm.get('year')?.value) ?? 0;
    return currentMonth === selectedMonth && selectedYear === currentDate.getFullYear();
  }

  createCommissionFile(action: string): void {
    Utils.blockUI();
    if (action.toLocaleLowerCase() === 'preview') {
      const requestPayload = this.getACHRequestPayload();
      this.nachaService.previewCommissionFile(requestPayload).pipe(takeUntil(this.stop$)).subscribe(result => {
        window.open(result.zipFileCloudDirectory);
        NotifUtils.showSuccessWithConfirmationFromUser(this.CommissionConstant.previewCommissionFileSuccessfullyGenerated);
        Utils.unblockUI();
      },
        err => {
          if (err?.error?.statusCode === COMMISSION_FILE_NO_DETAIL_RECORD ||
            err?.error?.statusCode === COMMISSION_FILE_NO_DETAIL_RECORD_1) {
            NotifUtils.showError('No ACH detail record.');
          } else {
            NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
          }
          Utils.unblockUI();
        });
    }

    if (action.toLocaleLowerCase() === 'download') {
      const downloadFileRequest: DownloadCommissionFileRequestDTO = {
        month: Number(this.commissionForm.get('month')?.value),
        year: Number(this.commissionForm.get('year')?.value)
      };
      this.nachaService.downloadCommissionFile(downloadFileRequest).pipe(takeUntil(this.stop$)).subscribe(result => {
        window.open(result.commissionZipFileUrl);
        NotifUtils.showSuccessWithConfirmationFromUser(this.CommissionConstant.downloadCommissionFileSuccess);
        Utils.unblockUI();
      },
        err => {
          if (err?.error?.statusCode === COMMISSION_FILE_NO_DETAIL_RECORD ||
            err?.error?.statusCode === COMMISSION_FILE_NO_DETAIL_RECORD_1) {
            NotifUtils.showError('No ACH detail record.');
          } else {
            NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
          }
          Utils.unblockUI();
        });
    }
  }

  generateCommissionFile(): void {
    Utils.blockUI();
    const generateCommissionFileRequest = this.getACHRequestPayload();
    this.nachaService.generateCommissionFile(generateCommissionFileRequest).pipe(takeUntil(this.stop$)).subscribe(result => {
      window.open(result.zipFileCloudDirectory);
      NotifUtils.showSuccessWithConfirmationFromUser(this.CommissionConstant.commissionFileSuccessfullyGenerated, () => {
        this.checkACHFile();
      });
      Utils.unblockUI();
    },
      err => {
        if (err?.error?.statusCode === COMMISSION_FILE_NO_DETAIL_RECORD ||
          err?.error?.statusCode === COMMISSION_FILE_NO_DETAIL_RECORD_1) {
          NotifUtils.showError('No ACH detail record.');
        } else {
          NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
        }
        Utils.unblockUI();
      });
  }

  emailCommissionFile(): void {
    const emailCommissionConfirmMessage = `Are you sure you want to email Commission Statements for ${this.getSelectedMonth()} ${this.getSelectedYear()}?`;
    NotifUtils.showConfirmMessage(emailCommissionConfirmMessage, () => {
      Utils.blockUI();
      const emailCommissionFileRequest: EmailCommissionFileRequestDTO = {
        month: Number(this.commissionForm.get('month')?.value),
        year: Number(this.commissionForm.get('year')?.value)
      };
      this.nachaService.emailCommissionFile(emailCommissionFileRequest).pipe(takeUntil(this.stop$)).subscribe(result => {
        const emailCommStatement = `Commission Statements for ${this.getSelectedMonth()} ${this.getSelectedYear()} has been added to the queue.`;
        NotifUtils.showSuccessWithConfirmationFromUser(emailCommStatement, () => {
          this.checkACHFile();
        });
        Utils.unblockUI();
      },
        err => {
          switch (err?.error?.statusCode) {
            case COMMISSION_FILE_GENERATION_IS_NOT_COMPLETE ||
              COMMISSION_FILE_ALREADY_EMAILED ||
              COMMISSION_EMAIL_IN_PROGRESS:
              NotifUtils.showInfo(err?.error?.errors[0]);
              break;
            default:
              NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
              break;
          }
          Utils.unblockUI();
        });
    }, () => {
      // NO
      Utils.unblockUI();
    });
  }

  getNachaBank(): void {
    if (this.commissionData?.nachaBank?.length === 0) {
      Utils.blockUI();
      this.nachaService.getNachaBank().pipe(takeUntil(this.stop$)).subscribe(result => {
        this.commissionData.nachaBank = result;
        this.setDefaultNachaBank();
        Utils.unblockUI();
      },
        err => {
          NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
          Utils.unblockUI();
        }
      );
    } else {
      this.setDefaultNachaBank();
    }
  }

  setDefaultNachaBank(): void {
    const activeNachaBank = this.commissionData.nachaBank.filter(a => a.isActive);
    if (activeNachaBank.length === 1) {
      this.commissionForm.get('bank')?.setValue(activeNachaBank[0].id);
      this.disableBankSelect = true;
    } else {
      this.commissionForm.get('bank')?.setValue('');
      this.disableBankSelect = false;
    }
    this.commissionForm.get('bank')?.markAsTouched();
  }
}
