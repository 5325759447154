export const BookTransferConstants = {
  BatchDashboard: {
    noData: 'No Data To Display',
    TableLables: {
      firstRow: {
        source: 'Source',
        destination: 'Destination',
        transferDetails: 'Transfer Details'
      },
      secondRow: {
        fromAgencyCode: 'Agency Code',
        fromAgencyName: 'Agency Name',
        fromSubAgencyCode: 'Sub-Agency Code',
        fromSubAgencyName: 'Sub-Agency Name',
        fromAgentName: 'Agent Name',
        toAgencyCode: 'Agency Code',
        toAgencyName: 'Agency Name',
        toSubAgencyCode: 'Sub-Agency Code',
        toSubAgencyName: 'Sub-Agency Name',
        toAgentName: 'Agent Name',
        createdBy: 'Created By',
        createdDate: 'Created Date',
        transferReason: 'Transfer Reason',
        transferDate: 'Transfer Date',
        isMassTransfer: 'Transfer Type',
        status: 'Status'
      }
    },
    Status: {
      pending: 'Pending',
      inProgress: 'In Progress',
      done: 'Done'
    },
    Fields: {
      fromAgencyId: 'fromAgencyId',
      fromSubAgencyId: 'fromSubAgencyId',
      fromAgentId: 'fromAgentId',
      toAgencyId: 'toAgencyId',
      toSubAgencyId: 'toSubAgencyId',
      toAgentId: 'toAgentId',
      fromAgencyCode: 'fromAgencyCode',
      fromAgencyName: 'fromAgencyName',
      fromSubAgencyCode: 'fromSubAgencyCode',
      fromSubAgencyName: 'fromSubAgencyName',
      fromAgentName: 'fromAgentName',
      toAgencyCode: 'toAgencyCode',
      toAgencyName: 'toAgencyName',
      toSubAgencyCode: 'toSubAgencyCode',
      toSubAgencyName: 'toSubAgencyName',
      toAgentName: 'toAgentName',
      createdDate: 'createdDate',
      createdBy: 'createdBy',
      createdByFullName: 'createdByFullName',
      transferReason: 'transferReason',
      transferDate: 'transferDate',
      isMassTransfer: 'isMassTransfer',
      statusCode: 'statusCode'
    }
  },
  NewTransfer: {
    fromAgencyFormTitle: 'Source Agency',
    toAgencyFormTitle: 'Destination Agency',
    transferDetailsTitle: 'Transfer Details'
  }
};

export const FromAgencyFormConstants = {
  agencyName: 'Agency Name',
  agencyCode: 'Agency Code',
  agencySubAgencyName: 'Agency Sub-agency Name',
  agencySubAgencyCode: 'Agency Sub-agency Code'
};

export const ToAgencyFormConstants = {
  agencyName: 'Agency Name',
  agencyCode: 'Agency Code',
  agencySubAgencyName: 'Agency Sub-agency Name',
  agencySubAgencyCode: 'Agency Sub-agency Code',
  agentName: 'Agent Name'
};

export const TransferDetailsFormConstants = {
  transferBatchStatus: 'Batch Status',
  transferDate: 'Transfer Date',
  transferReason: 'Transfer Reason'
};

export const PoliciesConstants = {
  toTransfer: 'To Transfer',
  forTransfer: 'For Transfer',
  transferSelected: 'Transfer Selected',
  removeSelected: 'Remove Selected',
  noPoliciesToDisplay: 'No Policies To Display'
};

export const TransferModeConstants = {
  new: 'new',
  edit: 'edit'
};

export const ButtonLabelConstants = {
  new: 'Complete Transfer',
  edit: 'Update Transfer'
};

export const ButtonActionConstants = {
  submit: 'submit',
  cancel: 'cancel'
};

export type mode = 'new' | 'edit';

export type buttonAction = 'submit' | 'cancel';

export const NotificationMessages = {
  newTransferBatchSuccess: 'Transfer batch successfully created.',
  deleteTransferBatchSuccess: 'Transfer batch has been successfully deleted.',
  deleteTransferBatchConfirmation: 'Are you sure you want to delete this transfer batch?',
  editTransferQueueSuccess: 'Transfer queue has been successfully updated.',
  noAgentFound: 'No agents found under this sub-agency.<br />Please select another sub-agency.',
  sameSubAgencyError: 'Source sub-agency must not be the same with destination sub-ageny.<br />Please select another sub-agency.'
};

export const TransferStatusConstants = {
  queued: 'Queued',
  inProgress: 'In Progress',
  completed: 'Completed',
  expired: 'Expired'
};