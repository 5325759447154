import { Injectable, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/base-component';
import { PathConstants } from '../../../shared/constants/path.constants';
import { ValidationListModel } from '../../../shared/models/validation-list.model';
import { CoveragesValidationService } from '../submission/validations/coverages-validation.service';
import { PropertyValidationService } from '../submission/validations/property-validation.service';
import { ApplicantValidationService } from '../submission/validations/applicant-validation.service';
import { UwQuestionsValidationService } from '../submission/validations/uw-questions-validation.service';
import { InterestValidationService } from '../submission/validations/interest-validation.service';
import { EndorsementsValidationService } from '../submission/validations/endorsements-validation.service';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { AuthService } from 'app/core/services/auth.service';
import { GenericConstants } from 'app/shared/constants/generic.constants';

@Injectable({
  providedIn: 'root'
})
export class PolicyNavValidationService extends BaseComponent {
  validationList = new ValidationListModel();
  genericConstants = GenericConstants;

  constructor(
    protected coveragesValidationService: CoveragesValidationService,
    protected propertyValidationService: PropertyValidationService,
    protected applicantValidationService: ApplicantValidationService,
    protected uwQuestionsValidationService: UwQuestionsValidationService,
    protected interestValidationService: InterestValidationService,
    protected endorsementsValidationService: EndorsementsValidationService,
    protected policySummaryData: PolicySummaryData,
    protected authService: AuthService
  ) {
    super();
  }

  get coveragesValidStatus() {
    return this.coveragesValidationService.coveragesSection &&
        this.coveragesValidationService.discountsSection &&
        this.coveragesValidationService.floodSection;
  }

  get propertyValidStatus() {
    return this.propertyValidationService.locationDetailsSection &&
    this.propertyValidationService.propertyDetailsSection &&
    this.propertyValidationService.windMitigationSection;
  }

  get applicantValidStatus() {
    return this.applicantValidationService.applicantSection &&
    this.applicantValidationService.contactSection &&
    this.applicantValidationService.fcraAcknowledgementSection;
  }

  get coApplicantValidStatus() {
    return this.applicantValidationService.coApplicantSection;
  }

  get uwQuestionsValidStatus() {
    return this.uwQuestionsValidationService.eligibilitySection &&
        this.uwQuestionsValidationService.generalQuestionSection;
  }

  get interestValidStatus() {
    return this.interestValidationService.priorInsuranceSection;
  }

  get endorsementsValidStatus() {
    return this.endorsementsValidationService.endorsementsSection;
  }

  public validateCurrentCategory(currentCategoryRoute: string): void {
    try {
      switch (currentCategoryRoute.toLowerCase()) {
        case PathConstants.QuickQuote.QuickQuote.Index:
          // to do
          break;
        case PathConstants.Policy.Policies.CoveragesAndDeduction:
            this.coveragesValidationService.checkCoveragesPage();
            this.validationList.coverages = this.coveragesValidStatus;
          break;
        case PathConstants.Policy.Policies.Endorsements:
            this.endorsementsValidationService.checkEndorsementsPage();
            this.validationList.endorsements = this.endorsementsValidStatus;
          break;
        case PathConstants.Policy.Policies.Claims:
          // to do
          break;
        case PathConstants.Policy.Policies.Property:
          this.propertyValidationService.checkPropertyPage();
          this.validationList.property = this.propertyValidStatus;
          break;
        case PathConstants.Applicant.Applicant.ApplicantPage:
          this.applicantValidationService.checkApplicantPage();
          this.applicantValidationService.checkCoApplicantSection();
          this.validationList.applicant = this.applicantValidStatus && this.coApplicantValidStatus;
          break;
        case PathConstants.Policy.Policies.UWQuestions:
          this.uwQuestionsValidationService.checkUWQuestionsPage();
          this.validationList.uwQuestions = this.uwQuestionsValidStatus;
          break;
        case PathConstants.Policy.Policies.Interests:
          this.interestValidationService.checkInterestPage();
          this.validationList.interest = this.interestValidStatus;
          break;
        case PathConstants.Policy.Policies.UWApproval:
          if (this.policySummaryData.isEditPolicy) {
            this.validationList.uwApproval = this.checkUwApprovalValidity();
          } else {
            this.validationList.uwApproval = this.authService.userType.value === this.genericConstants.userType.internal && !this.policySummaryData.isEditPolicy;
          }
          break;
        default:
          return;
      }
    } catch (e) {}
  }

  hasInvalid(): boolean {
    this.validateCurrentCategory(PathConstants.Policy.Policies.CoveragesAndDeduction);
    this.validateCurrentCategory(PathConstants.Policy.Policies.Property);
    this.validateCurrentCategory(PathConstants.Policy.Policies.ApplicantPage);
    this.validateCurrentCategory(PathConstants.Policy.Policies.Interests);
    this.validateCurrentCategory(PathConstants.Policy.Policies.Endorsements);
    return ((this.validationList.coverages || this.validationList.coverages === undefined) &&
      (this.validationList.property  || this.validationList.property === undefined) &&
      (this.validationList.applicant  || this.validationList.applicant === undefined) &&
      (this.validationList.uwQuestions || this.validationList.uwQuestions === undefined) &&
      (this.validationList.interest || this.validationList.interest === undefined) &&
      (this.validationList.endorsements || this.validationList.endorsements === undefined)) ? false : true;
  }

  checkUwApprovalValidity(): boolean {
    if (this.authService.userType.value === this.genericConstants.userType.external) {
      return false;
    }

    if (this.policySummaryData.uwrListEmpty) {
      return true;
    }

    if (this.policySummaryData.uwrListAllPostBind) {
      return true;
    }

    return this.policySummaryData.nonPostBindUwrItemsChecked;
  }
}
