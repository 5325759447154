import { Pipe, PipeTransform } from '@angular/core';

interface IIsIssueDisabled {
  premiumChangeList: any;
  noInvalidFormControl: boolean;
  hasPolicyChanges: boolean;
  approveAnyUser: boolean;
  issuePolicyFormValid: boolean;
  isPaid: boolean;
  isOutOfSequenceTransaction: boolean;
  isRaterCalculated: boolean;
  isEditPolicy: boolean;
  isCancelled: boolean;
  isExpired: boolean;
  uwrsIsDoneLoading: boolean;
  isExternalUser: boolean;
  isDoneLoadingChanges: boolean;
}

@Pipe({
  name: 'isIssueDisabled'
})
export class IsIssueDisabledPipe implements PipeTransform {
  transform(isIssueDisabled: IIsIssueDisabled): boolean {
    const {
      premiumChangeList,
      noInvalidFormControl,
      hasPolicyChanges,
      approveAnyUser,
      issuePolicyFormValid,
      isPaid,
      isOutOfSequenceTransaction,
      isRaterCalculated,
      isEditPolicy,
      isCancelled,
      isExpired,
      uwrsIsDoneLoading,
      isExternalUser,
      isDoneLoadingChanges
    } = isIssueDisabled;

    let status = true;
    const finalPremiumFees = premiumChangeList?.find(x => x.stepAlias === 'FPrePlusFee')?.premiumDiffChange;

    if (
      isEditPolicy &&
      noInvalidFormControl &&
      hasPolicyChanges &&
      approveAnyUser &&
      issuePolicyFormValid &&
      isRaterCalculated &&
      uwrsIsDoneLoading &&
      isDoneLoadingChanges
    ) {
      if (finalPremiumFees === 0) {
        status = false;
      } else if (isPaid) {
        status = false;
      }

      if (isCancelled) {
        status = true;
      } else if (isExpired) {
        status = isExternalUser;
      }
    }

    return status;
  }
}
