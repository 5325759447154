export class BatchDashboardDTO implements IBatchDashboardDTO {
  id?: string | undefined;
  fromAgencyId?: string | undefined;
  fromSubAgencyId?: string | undefined;
  toAgencyId?: string | undefined;
  toSubAgencyId?: string | undefined;
  toAgentId?: string | undefined;
  fromAgencyCode?: string | undefined;
  fromAgencyName?: string | undefined;
  fromSubAgencyCode?: string | undefined;
  fromSubAgencyName?: string | undefined;
  toAgencyCode?: string | undefined;
  toAgencyName?: string | undefined;
  toSubAgencyCode?: string | undefined;
  toSubAgencyName?: string | undefined;
  toAgentName?: string | undefined;
  transferReason?: string | undefined;
  statusCode?: string | undefined;
  createdBy?: string | undefined;
  createdByFullName?: string | undefined;
  createdDate?: string | undefined;
  createdDateProper?: string | undefined;
  transferDate?: string | undefined;
  isMassTransfer?: boolean | undefined;
  isActive?: boolean | undefined;
}

export interface IBatchDashboardDTO {
  id?: string | undefined;
  fromAgencyId?: string | undefined;
  fromSubAgencyId?: string | undefined;
  toAgencyId?: string | undefined;
  toSubAgencyId?: string | undefined;
  toAgentId?: string | undefined;
  fromAgencyCode?: string | undefined;
  fromAgencyName?: string | undefined;
  fromSubAgencyCode?: string | undefined;
  fromSubAgencyName?: string | undefined;
  toAgencyCode?: string | undefined;
  toAgencyName?: string | undefined;
  toSubAgencyCode?: string | undefined;
  toSubAgencyName?: string | undefined;
  toAgentName?: string | undefined;
  transferReason?: string | undefined;
  statusCode?: string | undefined;
  createdBy?: string | undefined;
  createdByFullName?: string | undefined;
  createdDate?: string | undefined;
  createdDateProper?: string | undefined;
  transferDate?: string | undefined;
  isMassTransfer?: boolean | undefined;
  isActive?: boolean | undefined;
}