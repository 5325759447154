import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors, AbstractControl, FormArray } from '@angular/forms';
import { GenericConstants } from '../constants/generic.constants';

@Injectable()
export default class FormUtils {
  constructor() { }

  static formsAreDirty(jsonForms: any): boolean {
    if (!!!jsonForms) {
      return false;
    }

    for (const key in jsonForms) {
      if (jsonForms.hasOwnProperty(key) && jsonForms[key] instanceof FormGroup) {
        const isDirty: boolean = (jsonForms[key] as FormGroup).dirty;

        if (isDirty) {
          return true;
        }
      }
    }

    return false;
  }

  public static matchValues(
    matchTo: string // name of the control to match to
    // eslint-disable-next-line no-shadow
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }

  static markFormsAsPristine(jsonForms: any, formGroupNames: string[] = null): void {
    if (!!!jsonForms) {
      return;
    }

    if (formGroupNames) {
      formGroupNames.forEach(formControl => {
        const jsonFormControl = (jsonForms as FormGroup).get(formControl);
        if (jsonFormControl) {
          jsonFormControl.markAsPristine();
        }
      });
    } else {
      for (const key in jsonForms) {
        if (jsonForms.hasOwnProperty(key) && jsonForms[key] instanceof FormGroup) {
          (jsonForms[key] as FormGroup).markAsPristine();
        }
      }
    }
  }

  static markFormControlAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key: string) => {
      formGroup.get(key).markAsTouched();
    });
  }

  static validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  static isControlValid(controlId: string | AbstractControl, formGroup: FormGroup): boolean {
    const control = (controlId instanceof AbstractControl) ? controlId :
      formGroup.get(controlId);

    return ((control.touched || control.dirty) && !control.valid && control.status.toUpperCase() !== 'DISABLED');
  }

  static setFormGroupStatus(formGroup: FormGroup, isEnable: boolean = true) {
    if (isEnable) {
      formGroup.enable();
    } else {
      formGroup.disable();
    }
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      abstractControl.markAsUntouched();
    });
  }

  static markFormGroupAsPristine(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const abstractControl = formGroup.get(key);
      abstractControl.markAsPristine();
    });
  }

  static validateForm(formGroup: FormGroup) {
    return formGroup.status === GenericConstants.formStatus.disabled ? true :
      formGroup.valid;
  }

  static markFormgroupAsTouch(formGroup: FormGroup): void {
    formGroup.markAllAsTouched();
  }

  static clearValidatorsByFormGroup(control: AbstractControl): void {
    Object.keys((control as FormArray).controls).forEach(key => {
      control['controls'][key].clearValidators();
      control['controls'][key].updateValueAndValidity();
    });
  }

  static resetFields(formGroup?: FormGroup, formControls?) {
    formControls.map(formControl => {
      formGroup.get(formControl).setValue('');
    });
  }

  static resetFormControls(formGroup?: FormGroup, formControls?) {
    formControls.map(formControl => {
      formGroup.get(formControl).reset();
    });
  }

  static markAsTouchedFormControls(formGroup?: FormGroup, formControls?) {
    formControls.map(formControl => {
      formGroup.get(formControl).markAsTouched();
    });
  }

  static isRequiredField(control: AbstractControl): boolean {
    if (!control.validator) {
      return false;
    }

    const validator = control.validator({} as AbstractControl);
    return (validator && validator.required);
  }

  static updateFormControlValidity(formGroup: FormGroup, formControlList: string[]): void {
    if (formControlList) {
      formControlList.forEach(control => {
        formGroup.get(control).updateValueAndValidity();
      });
    }
  }

  static isEqualToZeroOrEmpty(controlId: string | AbstractControl, formGroup: FormGroup): boolean {
    const control = (controlId instanceof AbstractControl) ? controlId :
      formGroup.get(controlId);

    return (control.value === 0 || control.value === '0' || control.value === '');
  }

  static padLeadingZeros(num, size) {
    let s = num + '';
    while (s.length < size) {
      s = `0${s}`;
    }
    return s;
  }
}
