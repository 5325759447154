import { Action, createReducer, on } from "@ngrx/store";
import * as fromInterestsActions from "app/store/interests/interests.actions";
import { initialState, InterestsState } from "./interests.state";

const _interestsReducer = createReducer(
  initialState,
  on(
    fromInterestsActions.updateInterestsIsLoadingFromInterestSavingData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromInterestsActions.updateInterestsListFromInterestSavingData,
    (state, { list }) => ({ ...state, list: list })
  ),
  on(
    fromInterestsActions.updateInterestsIsLoadingFromInterestData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromInterestsActions.updateInterestsListFromInterestData,
    (state, { list }) => ({ ...state, list: list })
  )
);

export function interestsReducer(
  state: InterestsState | null,
  action: Action
) {
  return _interestsReducer(state, action);
}