import {
  Component,
  OnInit,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  Input
} from '@angular/core';
import { TableComponent } from 'app/shared/components/dynamic/table/table.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BindAndIssueData } from 'app/modules/submission-management/data/bind-and-issue.data';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { AuthService } from 'app/core/services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { IDisableUWApprovalTableItems } from 'app/shared/models/dynamic/table.interface';

@Component({
  selector: 'app-uwr-table',
  templateUrl: './uwr-table.component.html',
  styleUrls: ['./uwr-table.component.scss']
})
export class UwrTableComponent extends TableComponent implements OnInit, AfterViewInit, OnChanges  {
  public genericConstants = GenericConstants;
  userType: string = this.genericConstants.userType.external;
  public openUploadFileModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadFile = new EventEmitter<any>();
  @Output() tableChanges = new EventEmitter<any>();
  @Input() uwrLoader: boolean = false;

  @Input() disableItems: IDisableUWApprovalTableItems = {
    addButton: false,
    updateButton: false,
    saveButton: false,
    cancelButton: false,
    controlButtons: false,
    updateIcon: false,
    deleteIcon: false,
    fileIcon: false,
    checkboxButton: false,
    uwApprovalOptions: false
  };

  constructor(
    protected fb: FormBuilder,
    public bindAndIssueData: BindAndIssueData,
    private authService: AuthService) {
      super(fb, authService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscribeToUserType();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  subscribeToUserType(): void {
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(result => {
      const hide = !(result === this.genericConstants.userType.internal);
      this.hideItems.addButton = hide;
      this.hideItems.deleteIcon = hide;
      this.userType = result;
    });
  }

  selectStatus(tdId: string, status: string): void {
    this.tableRows.forEach(row => {
      row.tr.forEach(td => {
        if (td.id === tdId) {
          this.updateStatusValue(row.id, td.id, status);
        }
      });
    });

    this.tableChanges.emit(this.tableRows);
  }

  updateStatusValue(rowId: string, tdId: string, status: string): void {
    this.tableRows.forEach(row => {
      if (row.id === rowId) {
        row.tr.forEach(td => {
          if (td.id === tdId) {
            td.display = this.getStatus(status) === 'AIR' ? 'Additional Information Required': this.getStatus(status);
            td.value = this.getStatus(status);
          }
        });
      }
    });
  }

  getStatus(status: string): string {
    switch (status) {
      case 'approve':
        return 'Approve';
      case 'reject':
        return 'Reject';
      case 'air':
        return 'AIR';
    }
  }

  setCheckboxId(rowId: string, status: string): string {
    return `${rowId}-${status}`;
  }

  onUploadFile(rowId: any) {
    this.uploadFile.emit(rowId);
  }
}
