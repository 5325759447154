import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '../../../common.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { OfacSearchResultDTO } from '../../../../../shared/models/data/dto/ofac/ofac-search-result.dto';
import { OfacSearchRequestDTO } from '../../../../../shared/models/data/dto/ofac/ofac-search-request.dto';
import { OfacSearchNotesDTO } from '../../../../../shared/models/data/dto/ofac/ofac-search-notes.dto';
import { ApplicantData } from 'app/modules/submission-management/data/applicant.data';
import { OfacSearchRefundSuspendedPaymentRequestDTO } from 'app/shared/models/data/dto/ofac/ofac-search-refund-suspended-payment-request.dto';

@Injectable()
export class OfacSearchService {
  constructor(public http: HttpClient,
    private commonService: CommonService) { }

  postData(ofacSearchRequest: OfacSearchRequestDTO): Observable<OfacSearchResultDTO[]> {
    const url = `${environment.ApiUrl}/OFAC/ofac`;
    return this.http.post(url, ofacSearchRequest)
        .catch(this.commonService.handleObservableHttpError);
  }

  saveNoteData(ofacSearchNotes: OfacSearchNotesDTO): Observable<OfacSearchNotesDTO>{
    const url = `${environment.ApiUrl}/OFAC/ofacnotes`;
    return this.http.post(url, ofacSearchNotes)
        .catch(this.commonService.handleObservableHttpError);
  }

  saveNoHitNoteData(ofacSearchNotes: OfacSearchNotesDTO): Observable<OfacSearchNotesDTO>{
    const url = `${environment.ApiUrl}/OFAC/bindnotes`;
    return this.http.post(url, ofacSearchNotes)
        .catch(this.commonService.handleObservableHttpError);
  }

  postRefundPayee(ofacSearchRequest: OfacSearchRequestDTO): Observable<boolean> {
    const url = `${environment.ApiUrl}/OFAC/refund`;
    return this.http.post(url, ofacSearchRequest)
        .catch(this.commonService.handleObservableHttpError);
  }

  postRefundSuspendedPayment(ofacSearchRequest: OfacSearchRefundSuspendedPaymentRequestDTO): Observable<boolean> {
    const url = `${environment.ApiUrl}/OFAC/refundsuspendedpayment`;
    return this.http.post(url, ofacSearchRequest)
        .catch(this.commonService.handleObservableHttpError);
  }
}