import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PolicyBillingComponent } from './policy-billing.component';
import { RouterModule, Routes } from '@angular/router';
import { BillingSummaryComponent } from './billing-summary/billing-summary.component';
import { SharedModule } from '../../../shared/shared.module';
import { InstallmentScheduleComponent } from './installment-schedule/installment-schedule.component';
import { PaymentsComponent } from './payments/payments.component';
import { TaxesFeesComponent } from './taxes-fees/taxes-fees.component';
import { RelatedDocumentsComponent } from './payments/related-documents/related-documents.component';
import { PaymentDetailsModalComponent } from './payments/payment-details-modal/payment-details-modal.component';
import { DocumentUploadModalComponent } from './payments/related-documents/document-upload-modal/document-upload-modal.component';
import { FeeModalComponent } from './taxes-fees/fee-modal/fee-modal.component';
import { RecurringPaymentEnrollmentComponent } from './recurring-payment-enrollment/recurring-payment-enrollment.component';
import { NgxMaskModule } from 'ngx-mask';
import { PostPaymentModalComponent } from './payments/post-payments/post-payment-modal/post-payment-modal.component';
import { AdjustmentModalComponent } from './payments/adjustment-modal/adjustment-modal.component';
import { PaymentConfirmationModalComponent } from './payments/payment-confirmation-modal/payment-confirmation-modal.component';
import { WriteOffModalComponent } from './payments/write-off-modal/write-off-modal.component';
import { TransferPaymentModalComponent } from './payments/transfer-payment-modal/transfer-payment-modal.component';
import { EscheatConfirmationModalComponent } from './payments/escheat-confirmation-modal/escheat-confirmation-modal.component';
import { ChangePaymentPlanModalComponent } from './billing-summary/change-payment-plan-modal/change-payment-plan-modal.component';
import { AddPaymentAccountModalComponent } from './recurring-payment-enrollment/add-payment-account-modal/add-payment-account-modal.component';
import { TooltipModule } from 'ngx-bootstrap';
import { TransferRenewalPaymentModalComponent } from './payments/transfer-renewal-payment-modal/transfer-renewal-payment-modal.component';
import { TransferPaymentToSuspenseModalComponent } from './payments/transfer-payment-to-suspense-modal/transfer-payment-to-suspense-modal.component';
import { AddRecurringPaymentAgreementComponent } from './payments/post-payments/add-recurring-payment-agreement/add-recurring-payment-agreement.component';
import { PostPaymentAgreementComponent } from './payments/post-payments/post-payment-agreement/post-payment-agreement.component';
import { ChangePaymentPlanModalAgreementComponent } from './billing-summary/change-payment-plan-modal-agreement/change-payment-plan-modal-agreement.component';


const routes: Routes = [
  { path: '', component: PolicyBillingComponent }
];

@NgModule({
  declarations: [PolicyBillingComponent,
  BillingSummaryComponent,
    InstallmentScheduleComponent,
    PaymentsComponent,
    TaxesFeesComponent,
    PaymentDetailsModalComponent,
    RelatedDocumentsComponent,
    DocumentUploadModalComponent,
    FeeModalComponent,
    RecurringPaymentEnrollmentComponent,
    PostPaymentModalComponent,
    AdjustmentModalComponent,
    PaymentConfirmationModalComponent,
    WriteOffModalComponent,
    TransferPaymentModalComponent,
    EscheatConfirmationModalComponent,
    ChangePaymentPlanModalComponent,
    AddPaymentAccountModalComponent,
    TransferRenewalPaymentModalComponent,
    TransferPaymentToSuspenseModalComponent,
    AddRecurringPaymentAgreementComponent,
    PostPaymentAgreementComponent,
    ChangePaymentPlanModalAgreementComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot(),
    TooltipModule
  ],
  exports: [PolicyBillingComponent],
  entryComponents: [
    PaymentDetailsModalComponent,
    DocumentUploadModalComponent,
    FeeModalComponent,
    PostPaymentModalComponent,
    AdjustmentModalComponent,
    PaymentConfirmationModalComponent,
    WriteOffModalComponent,
    TransferPaymentModalComponent,
    EscheatConfirmationModalComponent,
    ChangePaymentPlanModalComponent,
    AddPaymentAccountModalComponent,
    TransferRenewalPaymentModalComponent,
    TransferPaymentToSuspenseModalComponent
  ]
})
export class PolicyBillingModule { }
