import { createAction, props } from '@ngrx/store';
import { RiskScheduleDTO } from 'app/shared/models/data/dto/quick-quote/risk-schedule.dto';

export const updateCoveragesIsLoadingFromCoveragesData = createAction(
  '[CoveragesData] Update Coverages Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateCoveragesSchedulesFromCoveragesData = createAction(
  '[CoveragesData] Update Coverages Schedules',
  props<{ schedules: RiskScheduleDTO[] }>()
);

export const updateCoveragesSchedulesFromSubmissionManagementComponent = createAction(
  '[SubmissionManagementComponent] Update Coverages Schedules',
  props<{ schedules: RiskScheduleDTO[] }>()
);

export const updateCoveragesSchedulesFromPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update Coverages Schedules',
  props<{ schedules: RiskScheduleDTO[] }>()
);