import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AllResponseDTO } from "../../../../../shared/models/data/dto/third-party/all-response.dto";
import { IBcegsRecord, IBCEGSReport } from "./BCEGSReport.dto";
import * as _ from 'lodash';

@Injectable({
  providedIn:'root'
})
export class BCEGSService {

  private BCEGSRECORDS: string = 'BCEGSRECORDS';

  public SaveAResponseToStorage(result: AllResponseDTO): void {
    sessionStorage.setItem(this?.BCEGSRECORDS, JSON.stringify(result?.bcegRecords));
  }

  public GetBCEGSRecordsFromStorage(): string {
    return sessionStorage.getItem(this?.BCEGSRECORDS);
  }

  public BCEGSMapperUsingAllResponse(result: AllResponseDTO , PropertyLocationDetailsForm?: FormGroup): void {

    const yearBuilt = Number(result?.iso360Value?.yearBuilt);
    const noHitBCEG = '99';
    const foundExactBCEG : IBcegsRecord[] = _.take(result?.bcegRecords?.filter(x => Number(x?.year) === Number(yearBuilt)),1);
    const foundEarlierBCEG : IBcegsRecord[] =_.sortBy(result?.bcegRecords?.filter(x =>  Number(x?.year) <=  Number(yearBuilt) &&  Number(x?.year) !==  Number(yearBuilt)), ['year']);

    if (result?.protectionClassLocationLowerNumber === '' || result?.protectionClassLocationLowerNumber === null) {
      PropertyLocationDetailsForm?.get('bceg')?.enable();
    }

    if (result?.bceg === noHitBCEG) {
      PropertyLocationDetailsForm?.get('bceg')?.setValue(noHitBCEG);
    } else if (foundExactBCEG.length >= 1) {
      PropertyLocationDetailsForm?.get('bceg')?.setValue(foundExactBCEG[0]?.bcegsCode);
    } else if (foundEarlierBCEG) {
      const closestBCEG = _.uniq(_.map(foundEarlierBCEG, 'year'));
      let smallestGap: number | null = null;
      let closestYear: string | null = null;
      closestBCEG.forEach(year => {
        if (Math.abs(Number(year) - Number(yearBuilt)) < smallestGap || smallestGap === null) {
          smallestGap = Math.abs(Number(year) - Number(yearBuilt));
          closestYear = year;
        }
      });
      const foundClosestYear = foundEarlierBCEG.find(x => x?.year === closestYear);
      if (foundClosestYear) {
        PropertyLocationDetailsForm?.get('bceg')?.setValue(foundClosestYear?.bcegsCode);
      } else {
        PropertyLocationDetailsForm?.get('bceg')?.setValue(noHitBCEG);
      }
    }
  }

  public BCEGSMapperUsingYearBuilt(yearBuiltParam: Number | string, result: IBCEGSReport, PropertyLocationDetailsForm?: FormGroup) {

    const yearBuilt =  Number(yearBuiltParam);
    const noHitBCEG = '99';
    const foundExactBCEG: IBcegsRecord[] = _.take(result?.bcegsRecords?.filter(x =>  Number(x?.year) ===  Number(yearBuilt)),1);
    const foundEarlierBCEG : IBcegsRecord[] =_.sortBy(result?.bcegsRecords?.filter(x => Number(x?.year) <= Number(yearBuilt) && Number(x?.year) !==  Number(yearBuilt)), ['year']);
    const genzipReturnSource = 'GENZIP';

    if (result?.returnSource?.toString()?.trim()?.toUpperCase() === genzipReturnSource) {
      PropertyLocationDetailsForm?.get('bceg')?.setValue(noHitBCEG);
    } else if (foundExactBCEG.length >= 1) {
      PropertyLocationDetailsForm?.get('bceg')?.setValue(foundExactBCEG[0].bcegsCode);
    } else if (foundEarlierBCEG) {
      const closestBCEG = _.uniq(_.map(foundEarlierBCEG, 'year'));
      let smallestGap: number | null = null;
      let closestYear: string | null = null;
      closestBCEG.forEach(year => {
        if (Math.abs(Number(year) - Number(yearBuilt)) <  Number(smallestGap) || smallestGap === null) {
          smallestGap = Math.abs(Number(year) - Number(yearBuilt));
          closestYear = year;
        }
      });
      const foundClosestYear = foundEarlierBCEG.find(x => x?.year === closestYear);
      if (foundClosestYear) {
        PropertyLocationDetailsForm?.get('bceg')?.setValue(foundClosestYear?.bcegsCode);
      } else {
        PropertyLocationDetailsForm?.get('bceg')?.setValue(noHitBCEG);
      }
    }

  }

}

