import { ResponseDTO } from '../response';

export class GenerateNachaRequestDTO implements IGenerateNachaRequestDTO {
  year: number;
  month: number;
  isPreview: boolean;
  bankId: string;
  stateCode: string;
  routingType: string;

  constructor(year?: number, month?: number, isPreview?: boolean, bankId?: string, stateCode?: string, routingType?: string) {
    year = year;
    month = month;
    isPreview = isPreview;
    bankId = bankId;
    stateCode = stateCode;
    routingType = routingType;
  }
}

export interface IGenerateNachaRequestDTO {
  year: number;
  month: number;
  isPreview: boolean;
  bankId: string;
  stateCode: string;
  routingType: string;
}

export class GenerateNachaResponsetDTO implements IGenerateNachaResponsetDTO {
  success: boolean;
  fileHeader: string;
  batchHeaer: string;
  detailRecord: string;
  batchTrailer: string;
  fileTrailer: string;
  filePath: string;
  message: string;
}

export interface IGenerateNachaResponsetDTO {
  success: boolean;
  fileHeader: string;
  batchHeaer: string;
  detailRecord: string;
  batchTrailer: string;
  fileTrailer: string;
  filePath: string;
  message: string;
}

export class GetFileResultDTO implements IGetFileResultDTO{
  statementPDF?: string;
  base64String?: string;
}

export interface IGetFileResultDTO {
  statementPDF?: string;
  base64String?: string;
}

export class GenerateAgencyCommissionStatementRequestDTO implements IGenerateAgencyCommissionStatementRequestDTO{
  agencyId?: string;
  month?: number;
  year?: number;
}

export interface IGenerateAgencyCommissionStatementRequestDTO {
  agencyId?: string;
  month?: number;
  year?: number;
}

export class GenerateAgencyCommissionStatementResponseDTO implements IGenerateAgencyCommissionStatementResponseDTO{
  fileName?: string;
  filePath?: string;
  base64String?: string;
}

export interface IGenerateAgencyCommissionStatementResponseDTO {
  fileName?: string;
  filePath?: string;
  base64String?: string;
}

export class GenerateCommissionStatementResponseDTO implements IGenerateCommissionStatementResponseDTO {
  url?: string;
  isCommissionStatementComplete?: boolean;
  infoMessage?: string;
  isAlreadyEmailed?: boolean;
}

export interface IGenerateCommissionStatementResponseDTO {
  url?: string;
  isCommissionStatementComplete?: boolean;
  infoMessage?: string;
  isAlreadyEmailed?: boolean;
}

export class PreviewCommissionFileRequestDTO extends GenerateNachaRequestDTO {
  /**
   *
   */
  constructor() {
    super();
    this.isPreview = true;
  }
}

export class PreviewCommissionFileResponseDTO extends ResponseDTO {
  zipFileCloudDirectory: string;
}

export class GenerateCommissionFileRequestDTO extends GenerateNachaRequestDTO {
  /**
   *
   */
  constructor() {
    super();
    this.isPreview = false;
  }
}

export class GenerateCommissionFileResponseDTO extends ResponseDTO {
  zipFileCloudDirectory: string;
}

export class EmailCommissionFileRequestDTO {
  year: number;
  month: number;
}

export class EmailCommissionFileResponseDTO extends ResponseDTO {
}

export class DownloadCommissionFileRequestDTO {
  year: number;
  month: number;
}

export class DownloadCommissionFileResponseDTO extends ResponseDTO {
  commissionZipFileUrl: string;
}

export class CheckCommissionFileRequestDTO {
  year: number;
  month: number;
}

export class CheckCommissionFileResponseDTO extends ResponseDTO {
  download: boolean;
  preview: boolean;
  generate: boolean;
  email: boolean;
}

export class NachaBankDTO implements INachaBankDTO {
  id: string;
  description: string;
  isActive: boolean;
  nacha: INachaDTO;
}

export interface INachaBankDTO {
  id: string;
  description: string;
  isActive: boolean;
  nacha: INachaDTO;
}

export class NachaDTO implements INachaDTO {
  id: string;
  nachaBankId: string;
  state: string;
  accountNumber: string;
  clientRoutingNumber: string;
  nachaRoutingType: string;
  destinationName: string;
  originName: string;
  companyIdentification: string;
  companyEntryDescription: string;
  createdBy: number;
  createdDate: Date;
  isActive: boolean;
}

export interface INachaDTO {
  id: string;
  nachaBankId: string;
  state: string;
  accountNumber: string;
  clientRoutingNumber: string;
  nachaRoutingType: string;
  destinationName: string;
  originName: string;
  companyIdentification: string;
  companyEntryDescription: string;
  createdBy: number;
  createdDate: Date;
  isActive: boolean;
}