import { Component, OnInit } from '@angular/core';
import { ErrorMessageConstant } from '../../../../shared/constants/error-message.constants';
import { BsModalRef } from 'ngx-bootstrap';
import { PolicyCancellationLabelsConstants } from '../../../../shared/constants/policy-cancellation.labels.constants';
import { PolicyRewriteData } from '../../data/policy-rewrite.data';
import { EntityRiskData } from '../../../../modules/submission-management/data/entity-risk.data';
import { Rater } from '../../../../shared/constants/rater.constant';
import { distinctUntilChanged, share, switchMap, take, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../../shared/base-component';
import { CalculateRaterData } from 'app/core/services/submission/rater-premium/calculate-rater-data.service';
import { PolicyService } from '../../../../../app/core/services/submission/policy.service';
import Utils from '../../../../../app/shared/utilities/utils';
import NotifUtils from '../../../../shared/utilities/notif-utils';
import { PolicyIssueData } from '../../data/policy-issue.data';
import { SummaryData } from '../../../../../app/modules/submission-management/data/summary.data';
import { MoratoriumService } from '../../../../core/services/moratorium.services';
import { AuthService } from '../../../../core/services/auth.service';
import { GenericConstants } from '../../../../shared/constants/generic.constants';
import { PropertyData } from '../../../../modules/submission-management/data/property.data';
import * as moment from 'moment';
import { MoratoriumType } from '../../../../shared/constants/moratoriumType';
import { QuoteService } from '../../../../core/services/quote.service';
import { RequestObjectMoratorium } from 'app/shared/models/submission/quick-quote/moratoriums.model';
import { updateAppIsConfirmationFromPolicyRewriteComponent } from 'app/store/app/app.actions';
import { Store } from '@ngrx/store';
import { DatePipe } from '@angular/common';
import { IGetAgencyPolicyCommissionRateRequestDTO } from 'app/shared/models/management/agency-management/agency-details.response.dto';


@Component({
  selector: 'app-policy-rewrite',
  templateUrl: './policy-rewrite.component.html',
  styleUrls: ['./policy-rewrite.component.scss']
})
export class PolicyRewriteComponent extends BaseComponent implements OnInit {

  title: string;

  public PolicyRewriteLabelConstants = PolicyCancellationLabelsConstants.PolicyRewrite;
  public ErrorMessageConstant = ErrorMessageConstant;
  isCalcPremiumCompleted: boolean = false;
  isRewriteInProgress: boolean = false;

  constructor(
    public policyRewriteData: PolicyRewriteData,
    public bsModalRef: BsModalRef,
    protected entityRiskData: EntityRiskData,
    protected raterData: CalculateRaterData,
    protected policyService: PolicyService,
    protected policyIssueData: PolicyIssueData,
    protected summaryData: SummaryData,
    protected moratoriumService: MoratoriumService,
    protected authService: AuthService,
    protected propertyData: PropertyData,
    protected quoteService: QuoteService,
    protected store: Store
  ) {
    super();
  }

  ngOnInit() {
    this.policyRewriteData.initForms();
  }

  initPostRewrite(riskId: string, riskDetailId: string, effectiveDate: any) {
    const raterRequestKey = `${Rater.raterRequest}_${riskDetailId}`;
    const raterResultKey = `${Rater.raterResult}_${riskDetailId}`;

    const raterRequest = localStorage.getItem(raterRequestKey);
    const raterResult = localStorage.getItem(raterResultKey);

    const payload = {
      riskId: riskId,
      effectiveDate: effectiveDate,
      raterRequest: raterRequest,
      raterResult: JSON.parse(raterResult),
      commissionRate: this.authService?.commissionRateResponse ? this.authService?.commissionRateResponse?.commissionRate : '0'
    };
    this.policyRewriteData.postRewritePolicy(payload);
  }

  cancel() {
    this.bsModalRef.hide();
  }

  submit() {
    this.getCommissionRateRewrite();

    this.summaryData.authService.isDoneCommissionRateChecking$.pipe(takeUntil(this.stop$)).subscribe(() => {
      if (this.entityRiskData.getAgencyHasCommissionRate()) {
        this.displayMoratoriumRewrite();
      } else {
        return;
      }
    });

  }

  protected displayMoratoriumRewrite(): void {
    const effectiveDateRewrite = moment(new Date(this?.policyRewriteData?.rewriteForm?.get('newPolicyEffectiveDate').value?.singleDate?.jsDate)).format('YYYY-MM-DD');
    const countyFromEntity = this?.entityRiskData?.EntityRisk?.entityAddresses[0]?.address?.county?.toString()?.trim() ?? this?.propertyData?.locationDetails?.get('county')?.value?.toString()?.trim() ?? '';

    const zipCodeFromEntity = this?.entityRiskData?.EntityRisk?.entityAddresses[0]?.address?.zipCode?.toString()?.trim() ?? '';
    const stateCodeFromEntity = this?.entityRiskData?.EntityRisk?.entityAddresses[0]?.address?.state?.toString()?.trim() ?? '';
    const moratoriumRequest: RequestObjectMoratorium = {
      county: countyFromEntity,
      effectiveDate: effectiveDateRewrite,
      zipCode: zipCodeFromEntity,
      stateCode: stateCodeFromEntity,
      formType: this.quoteService.formType,
      agencyId: this.entityRiskData.agencyId
    };

    if (!countyFromEntity || !effectiveDateRewrite || !zipCodeFromEntity || !stateCodeFromEntity) {
      return;
    }

    this.moratoriumService.getAllMoratoriums().pipe(takeUntil(this.stop$)).subscribe(moraResult => {
      this.entityRiskData.allMoratoriumList = moraResult;
    }, (error) => {
      NotifUtils.showError(GenericConstants.moratoriumAPIError);
    });

    this.moratoriumService.getMoratoriums(moratoriumRequest).pipe(takeUntil(this.stop$)).subscribe(moratoriumResult => {
      const response = moratoriumResult.filter(a => a.type === MoratoriumType.closure || a.type === MoratoriumType.moratorium || a.type === MoratoriumType.coverageMoratorium);

      if (response?.filter(x => x.type === GenericConstants?.closure)?.length > 0) {
        let messageBuilder = '';

        response?.filter(x => x.type === GenericConstants?.closure)?.forEach(x => {
          messageBuilder += ` <li> ${x.popUpMessage} </li> `;
        });

        if (this.authService.isExternalUser) {
          this.store.dispatch(updateAppIsConfirmationFromPolicyRewriteComponent({ isConfirmation: true }));

          NotifUtils.showInformationWithConfirmationFromUser(messageBuilder, () => {
            this.store.dispatch(updateAppIsConfirmationFromPolicyRewriteComponent({ isConfirmation: false }));
            return;
          });
        } else {
          this.continueSubmission();
        }
      } else {
        this.continueSubmission();
      }
    }, (error) => {
      this.store.dispatch(updateAppIsConfirmationFromPolicyRewriteComponent({ isConfirmation: true }));

      NotifUtils.showInformationWithConfirmationFromUser(GenericConstants.moratoriumAPIError, () => {
        this.store.dispatch(updateAppIsConfirmationFromPolicyRewriteComponent({ isConfirmation: false }));
      });
      return;
    });
  }

  protected continueSubmission(): void {
    Utils.blockUI();
    const riskDetailId = this.entityRiskData.getRiskDetailId();
    const riskId = this.entityRiskData.getRiskId();
    const effectiveDate = this.policyRewriteData.rewriteForm.get('newPolicyEffectiveDate').value?.singleDate?.jsDate.toLocaleDateString('en-US');
    const isProceedSaving = false;
    this.isRewriteInProgress = true;

    if (this.summaryData.showPendingEndorsementStatus === false) {
      this.raterData.populateRewritePayload(effectiveDate, isProceedSaving);

      this.raterData.isCalcPremium.pipe(take(1)).subscribe(result => {
        this.isCalcPremiumCompleted = !result;
        this.initPostRewrite(riskId, riskDetailId, effectiveDate);
        this.isRewriteInProgress = false;
        this.bsModalRef.hide();
      });
    } else {
      this.policyService.postResetPolicyChanges(riskId, riskDetailId).pipe(takeUntil(this.stop$)).subscribe(() => {
        this.summaryData.showPendingEndorsementStatus = false;
        this.policyService.reset.next(true);
        Utils.blockUI();

        this.raterData.isCalcPremium.pipe(take(1)).subscribe(() => {
          Utils.blockUI();
          this.raterData.populateRewritePayload(effectiveDate, isProceedSaving);
        });

        this.raterData.recalculatePremiumSubmissionRewrite$.pipe(takeUntil(this.stop$)).subscribe(result => {
          this.isCalcPremiumCompleted = !result;
          this.initPostRewrite(riskId, riskDetailId, effectiveDate);
          this.isRewriteInProgress = false;
          this.bsModalRef.hide();
        });
      }, error => {
        Utils.unblockUI();
        this.isRewriteInProgress = false;
        NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
      });
    }
  }

  public getCommissionRateRewrite(): void {
    const risk = this?.entityRiskData?.EntityRisk?.risks[0] ?? null;
    if (!risk) {
      return;
    }

    const effectiveDate = this.policyRewriteData.rewriteForm.get('newPolicyEffectiveDate').value?.singleDate?.jsDate;
    const formattedEffectiveDate = new DatePipe('en-US').transform(effectiveDate, 'MM-dd-yyyy');
    const systemDate = new DatePipe('en-US').transform(this.summaryData.authService.getCustomDate(), 'MM-dd-yyyy');

    const request: IGetAgencyPolicyCommissionRateRequestDTO = {
      riskId: risk.id,
      stateCode: risk.riskStateCode,
      agencyId: risk.agencyId,
      effectiveDate: formattedEffectiveDate,
      systemDate: systemDate,
      transactionType: 'NB',
      territory: this.propertyData?.locationDetails?.get('territoryCode').value,
      masterProgramId: this.summaryData?.authService.getProgramTypeCodeViaStateAndFormType(risk.riskStateCode, risk.formType)
    };

    this.summaryData.authService.checkAgencyCommissionRate(request, true);
  }
}
