import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';
import { BatchDashboardDTO } from '../../shared/models/data/dto/book-transfer/batch-dashboard.dto';
import { TransferQueueDTO } from '../../shared/models/data/dto/book-transfer/transfer-queue.dto';
import { BatchTransferSavingDTO } from '../../shared/models/data/dto/book-transfer/book-transfer-saving.dto';
@Injectable({
    providedIn: 'root'
  })
export class BookTransferService {
  constructor(public http: HttpClient,
    private commonService: CommonService) { }

  getBatchDashboardData(): Observable<BatchDashboardDTO[]> {
    return this.http.get(`${environment.ApiUrl}/BookTransfer/batch-dashboard`)
      .catch(this.commonService.handleObservableHttpError);
  }

  getBatchDashboardDataByKeyword(keyword: string): Observable<BatchDashboardDTO[]> {
    return this.http.get(`${environment.ApiUrl}/BookTransfer/search-batch-dashboard?keyword=${keyword}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  getBatchDetailsByBatchId(batchId: string): Observable<BatchDashboardDTO> {
    return this.http.get(`${environment.ApiUrl}/BookTransfer/batch-details?batchId=${batchId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  getQueueDetailsByBatchId(batchId: string): Observable<TransferQueueDTO[]> {
    return this.http.get(`${environment.ApiUrl}/BookTransfer/queue-details?batchId=${batchId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  getPoliciesByAgency(agencyId: string): Promise<any> {
    return this.http.get(`${environment.ApiUrl}/BookTransfer/agency-policies?agencyId=${agencyId}`)
      .toPromise()
      .then(data => data)
      .catch(this.commonService.handleObservableHttpError);
  }

  getPoliciesByAgencySubAgency(agencyId: string, subAgencyId: string): Promise<any> {
    return this.http.get(`${environment.ApiUrl}/BookTransfer/agency-subagency-policies?agencyId=${agencyId}&subAgencyId=${subAgencyId}`)
      .toPromise()
      .then(data => data)
      .catch(this.commonService.handleObservableHttpError);
  }

  deleteBatchById(model: BatchTransferSavingDTO): Observable<BatchDashboardDTO[]> {
    return this.http.post(`${environment.ApiUrl}/BookTransfer/delete-batch`, model)
      .catch(this.commonService.handleObservableHttpError);
  }

  postNewBookTransfer(model: BatchTransferSavingDTO): Observable<BatchDashboardDTO[]> {
    return this.http.post(`${environment.ApiUrl}/BookTransfer/new-transfer`, model)
      .catch(this.commonService.handleObservableHttpError);
  }

  postEditBookTransfer(model: BatchTransferSavingDTO): Observable<BatchDashboardDTO[]> {
    return this.http.post(`${environment.ApiUrl}/BookTransfer/edit-transfer`, model)
      .catch(this.commonService.handleObservableHttpError);
  }
}