import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EntityRiskDTO } from '../../../shared/models/data/dto/quick-quote/entity-risk.dto';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { BaseClass } from '../../../shared/base-class';
import { CustomValidators } from '../../../shared/validators/custom.validator';
import { ZipData } from './zip.data';
import { ZipcodesService } from '../../../core/services/submission/zipcodes/zipcodes.service';
import { ZipCodeData } from './zipcode.data';
import { PageSections } from '../../../shared/enum/page-sections.enum';
import { LvCities, LvCountries, LvCreditScoreStatus, LvGender, LvInspectionOrderingType, LvMaritalStatuses, LvNameSuffix, LvRelationshipToApplicant } from '../../../shared/constants/applicant.options.constants';
import { AddressType } from '../../../shared/enum/address-type.enum';
import { takeUntil } from 'rxjs/operators';
import { NameCasePipe } from '../../../shared/custom pipes/name-pipe';
import FormUtils from '../../../shared/utilities/form.utils';
import { LvCreditOrderStatus, LvCreditOrderStatusIndex } from '../../../shared/constants/quick-quote.options.constants';
import { SubmissionCopiedAgencyInformation, SubmissionCopiedInformation } from '../../../shared/models/submission/applicant/submission-copied-information';
import { PreviousApplicantInfoModel } from 'app/shared/models/data/submission/previous-applicant-info-model';
import { AuthService } from 'app/core/services/auth.service';
import { RegexConstants } from 'app/shared/constants/regex.constants';
import { InspectionOrderingDTO } from 'app/shared/models/data/dto/report/inspection.ordering.dto';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { PolicyIssueData } from 'app/modules/policy-management/data/policy-issue.data';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})

export class ApplicantData extends BaseClass {
  mainApplicant: FormGroup;
  contact: FormGroup;
  coApplicant: FormGroup;
  inspection: FormGroup;
  fcraReportingActForm: FormGroup;
  addressType: AddressType;
  isOrderCredit: boolean;
  hideFcraAndIAgree: boolean;
  isfcraAcknowledgement: boolean;

  cities: any[];
  countries: any[];
  maritalStatuses: any[];
  relationshipToApplicant: any[];
  creditScoreStatus: any[];
  creditScoreDateOrdered?: Date;
  gender: any[];
  isRetired: boolean;
  cityList: any[] = [];
  nameSuffixList: any[];
  isLexisCalled: boolean = false;
  isPrefillClicked: boolean = false;
  previousApplicantInfo: PreviousApplicantInfoModel = new PreviousApplicantInfoModel();
  inspectionOrderingTypes: any[];
  inspectionOrdering: InspectionOrderingDTO;

  protected _age: number;
  public isInAgeOfRetirement: boolean = false;
  public isInternal: boolean = false;
  public coApplicantDisabledDOB: boolean = false;

  copiedInformation: SubmissionCopiedInformation;
  isCopiedInformation: boolean = false;
  isQQSavedForCopy: boolean = false;
  copiedAgencyInformation: SubmissionCopiedAgencyInformation = null;

  coApplicantIsRetired: boolean;
  isCoAppUSCountry?: boolean = true;

  applicantRetrievalData: EntityRiskDTO;
  nameChanged: boolean = false;
  isNewlyAddedCoApplicantDetails: boolean = false;

  constructor(
      public zipData: ZipData,
      protected zipCodeService: ZipcodesService,
      protected zipCodeData: ZipCodeData,
      protected namePipe: NameCasePipe,
      protected authService: AuthService,
      public policySummaryData: PolicySummaryData) {
    super();
    this.subscribeUserType();
  }

  get age(): number {
    return this._age;
  }

  set age(value: number){
    this._age = value;
  }

  get isPristinePreviousApplicantInfo(): boolean {
    const isPristine: boolean = this.previousApplicantInfo.firstName &&
      this.previousApplicantInfo.middleName &&
      this.previousApplicantInfo.lastName &&
      this.previousApplicantInfo.nameSuffix &&
      this.previousApplicantInfo.birthDate ? true : false;
    return isPristine;
  }

  get hasCoApplicantDateOfBirth() {
    return this.coApplicant.get('coApplicantDateOfBirth').value ? true : false;
  }

  dateOfBirthOption: IAngularMyDpOptions;
  disablePastDatesFromPresentDateOption: IAngularMyDpOptions;
  creditScoreDateOrderedOption: IAngularMyDpOptions;

  validDateOfBirth: Date= null;
  validAddress: string = null;
  validMobilePhone: string = null;
  validHomePhone: string = null;
  defaultState: string = 'FL';

  initForms() {
    this.resetApplicantContact();
    this.mainApplicant = this.mainApplicantSection();
    this.contact = this.contactSection();
    this.coApplicant = this.coapplicantSection();
    this.inspection = this.setInspectionSectionFormGroupValues();
    this.fcraReportingActForm = this.fcraReportingActSection();
    this.mainApplicantDropdownValues();
    this.applicantInspectionDropdownValues();
    this.dateFormatOptions();
    sessionStorage.setItem('selectedEffectiveDate', this.mainApplicant.get('effectiveDate')?.value?.singleDate?.jsDate);
  }

  mainApplicantSection() {
    const currentDate = this.authService.getCorrectEffectiveDate(this.authService.getCustomDate());
    const regex = /[^0-9]*/g;
    const effDate: any = {
      singleDate: {
        date: {
          year: currentDate.getFullYear(),
          month: currentDate.getMonth() + 1,
          day: currentDate.getDate()
        },
        jsDate: currentDate
      }
    };
    sessionStorage.setItem('selectedEffectiveDate', effDate);
    return new FormGroup({
      firstName: new FormControl('', [Validators.required, CustomValidators.spaceValidator, Validators.pattern(RegexConstants.hasALetterAndASpaceAndAHyphen)]),
      middleInitial: new FormControl('', [CustomValidators.spaceValidator, Validators.pattern(RegexConstants.hasALetterAndASpaceAndAHyphen)]),
      lastName: new FormControl('', [Validators.required, CustomValidators.spaceValidator, Validators.pattern(RegexConstants.hasALetterAndASpaceAndAHyphen)]),
      nameSuffix: new FormControl(''),
      dateOfBirth: new FormControl('', Validators.required),
      mailingAddress: new FormControl('', [Validators.required, CustomValidators.spaceValidator]),
      zipCode: new FormControl('', [Validators.required, Validators.pattern(regex), CustomValidators.hasNoValue]),
      state: new FormControl('' ,Validators.required),
      city: new FormControl('', Validators.required),
      country: new FormControl('USA', Validators.required),
      mainApplicantCounty: new FormControl(''),
      aptUnit: new FormControl('', [CustomValidators.spaceValidator]),
      maritalStatusApplicant: new FormControl('', Validators.required),
      ssn: new FormControl('', []),
      retired: new FormControl(false, []),
      isApplicantsMailingAddressSameAsInsuredLocation: new FormControl(
        true,
        []
      ),
      effectiveDate: new FormControl(effDate, Validators.required),
      addressLine1: new FormControl('', [Validators.required, CustomValidators.spaceValidator]),
      addressLine2: new FormControl(''),
      qqCity: new FormControl('', Validators.required),
      qqState: new FormControl('', Validators.required),
      qqZipCode: new FormControl('', Validators.required),
      isOrderYesNo: new FormControl(false),
      isRetiredYesNo: new FormControl(false),
      screenScrapeSource: new FormControl('')
    });
  }

  contactSection() {
    return new FormGroup({
      emailAddress: new FormControl('', [
        Validators.pattern(
          '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,12}$'
        ),
        Validators.required,
        CustomValidators.spaceValidator
      ]),
      mobilePhoneNumber: new FormControl('', [Validators.required, CustomValidators.spaceValidator, CustomValidators.phoneNumberValidator]),
      homePhoneNumber: new FormControl('', [CustomValidators.phoneNumberValidator]),
    });
  }

  coapplicantSection() {
    const regex = /[^0-9]*/g;
    return new FormGroup({
      coApplicantFirstName: new FormControl('', Validators.required),
      coApplicantMiddleInitial: new FormControl('', []),
      coApplicantLastName: new FormControl('', Validators.required),
      coApplicantDateOfBirth: new FormControl('', Validators.required),
      coApplicantMaritalStatus: new FormControl('', Validators.required),
      coApplicantRelationship: new FormControl('', Validators.required),
      isThereCoApplicant: new FormControl('No', []),
      coApplicantSameAsApplicantMailingAddress: new FormControl('', Validators.required),
      coApplicantSameAsApplicantAptUnit: new FormControl('', []),
      coApplicantSameAsApplicantCity: new FormControl('', Validators.required),
      coApplicantSameAsApplicantState: new FormControl('', [Validators.required]),
      coApplicantSameAsApplicantZipCode: new FormControl('', [Validators.required, Validators.pattern(regex), CustomValidators.hasNoValue]),
      isCoApplicantsMailingAddressSameAsApplicantsMailingAddress: new FormControl({ value: true, disabled: false }, []),
      isThereACoApplicant: new FormControl(
        { value: false, disabled: false },
        []
      ),
      coApplicantCounty: new FormControl(''),
      coApplicantisRetiredYesNo: new FormControl(false),
      coApplicantSameAsApplicantCountry: new FormControl('USA', Validators.required)
    });
  }

  public setInspectionSectionFormGroupValues() {
    return new FormGroup({
      inspectionOrdered: new FormControl(false, []),
      inspectionOrderDate: new FormControl('', Validators.required),
      inspectionType: new FormControl('', Validators.required),
      inspectionReceived: new FormControl(false, []),
      inspectionReceiptDate: new FormControl('', Validators.required)
    });
  }

  fcraReportingActSection() {
    return new FormGroup({
      fcraReportingAct: new FormControl(false, Validators.requiredTrue),
      creditScoreStatus: new FormControl({ value: '', disabled: true}, [Validators.required]),
      creditScoreDateOrdered: new FormControl({ value: '', disabled: true }, [Validators.required])
    });
  }

  mainApplicantDropdownValues() {
    this.cities = LvCities;
    this.maritalStatuses = LvMaritalStatuses;
    this.relationshipToApplicant = LvRelationshipToApplicant;
    this.countries = LvCountries;
    this.creditScoreStatus = LvCreditScoreStatus;
    this.gender = LvGender;
    this.nameSuffixList = LvNameSuffix;
  }

  applicantInspectionDropdownValues() {
    this.inspectionOrderingTypes = LvInspectionOrderingType;
  }

  dateFormatOptions() {
    this.dateOfBirthOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy',
      disableUntil: {
        year: 1799,
        month: 12,
        day: 31,
      },
      disableSince: {
        year: this.authService.getCustomDate().getFullYear(),
        month: this.authService.getCustomDate().getMonth() + 1,
        day: this.authService.getCustomDate().getDate() + 1,
      },
    };
    this.creditScoreDateOrderedOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy'
    };
    this.disablePastDatesFromPresentDateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy',
      disableUntil: {
        year: this.authService.getCustomDate().getFullYear(),
        month: this.authService.getCustomDate().getMonth() + 1,
        day: this.authService.getCustomDate().getDate() - 1,
      }
    };
  }

  get address() {
    let fullAddress = '';
    if (this.combinedAddress) {
      fullAddress = `${fullAddress}${this.combinedAddress}`;
    }
    if (this.city) {
      fullAddress = `${fullAddress}${this.city}, `;
    }
    if (this.zipCode) {
      fullAddress = `${fullAddress}${this.state} ${this.zipCode}`;
    }
    return fullAddress;
  }

  get name() {
    const firstName = `${this.mainApplicant.get('firstName').value.trim()}`;
    const middleInitial = this.mainApplicant.get('middleInitial').value ? ` ${this.mainApplicant.get('middleInitial').value.trim()}` : '';
    const lastName = ` ${this.mainApplicant.get('lastName').value.trim()}`;
    const nameSuffix = this.mainApplicant.get('nameSuffix').value ? ` ${this.mainApplicant.get('nameSuffix').value.trim()}` : '';
    const aggregatedNames = `${firstName}${middleInitial}${lastName}${nameSuffix}`;

    return aggregatedNames;
  }

  get coApplicantName() {
    const coappFirstName = `${this.coApplicant.get('coApplicantFirstName').value.trim()}`;
    const coappMiddleInitial = this.coApplicant.get('coApplicantMiddleInitial').value ? ` ${this.coApplicant.get('coApplicantMiddleInitial').value.trim()}` : '';
    const coappLastName = this.coApplicant.get('coApplicantLastName').value ? ` ${this.coApplicant.get('coApplicantLastName').value.trim()}` : '';
    const coappAggregatedNames = `${coappFirstName}${coappMiddleInitial.toUpperCase()}${coappLastName}`;

    return coappAggregatedNames;
  }

  get state() {
    const value = this.mainApplicant.get('qqZipCode').value ? this.mainApplicant.get('qqState').value : '';
    return value;
  }

  get combinedAddress() {
    const addressLine2 = this.mainApplicant.get('addressLine2').value ? `${this.mainApplicant.get('addressLine2').value}, ` : '';
    return `${this.mainApplicant.get('addressLine1').value ?? ''}, ${addressLine2}`;
  }

  get city() {
    return this.mainApplicant.get('qqCity').value ?? '';
  }

  get zipCode() {
    return this.mainApplicant.get('qqZipCode').value;
  }

  public populateApplicantData(data: EntityRiskDTO, isPolicy: boolean = false) {
    this.applicantRetrievalData = data;

    // Applicant Section
    this.mainApplicant.get('maritalStatusApplicant').setValue(data?.maritalStatusCode ?? '');
    const isApplicantSameAsInsuredAddress = data?.isMailingAddressSameInsured;
    const mailingAddress = data?.entityAddresses.length === 1 ?
      data?.entityAddresses[0]?.address : data?.entityAddresses.find(x => x.addressTypeCode === AddressType.Mailing)?.address;
    const propertyAddress = data?.entityAddresses.find(a => a.addressTypeCode === AddressType.Property).address;
    this.mainApplicant.get('isApplicantsMailingAddressSameAsInsuredLocation').setValue(data?.entityAddresses.length > 1 ? data?.isMailingAddressSameInsured : true);
    this.isRetired = data?.isRetired;
    this._age = data?.age;
    this.mainApplicant.get('isRetiredYesNo').setValue(this.isRetired);
    if (isPolicy) {
      this.mainApplicant.get('firstName').setValue(this.namePipe.transform(data?.firstName));
      this.mainApplicant.get('middleInitial').setValue(this.namePipe.transform(data?.middleName));
      this.mainApplicant.get('nameSuffix').setValue(this.namePipe.transform(data?.nameSuffix));
      this.mainApplicant.get('lastName').setValue(this.namePipe.transform(data?.lastName));
      this.mainApplicant.get('dateOfBirth').setValue({ isRange: false, singleDate: { jsDate: new Date(data?.birthDate) } });
      this.mainApplicant.get('isOrderYesNo').setValue(data?.isOrderCredit);
      this.mainApplicant.get('qqZipCode').setValue(propertyAddress?.zipCode ?? 0);
    }
    this.isOrderCredit = data?.isOrderCredit;
    this.hideFcraAndIAgree = this.isOrderCredit;
    this.validDateOfBirth = data?.birthDate;

    if (!data?.isMailingAddressSameInsured && data?.entityAddresses.length > 1) {
      // Add address fields
      this.mainApplicant.get('zipCode').setValue(mailingAddress?.zipCode === '0' ? '' : mailingAddress?.zipCode);
      this.populateCityList(mailingAddress?.zipCode, PageSections.ApplicantInformation);
      this.mainApplicant.get('city').setValue(mailingAddress?.city !== 'NA' ? mailingAddress?.city : '');
      this.mainApplicant.get('state').setValue(mailingAddress?.state !== 'NA' ? mailingAddress?.state : '');
      this.mainApplicant.get('country').setValue(mailingAddress?.countryCode ?? 'USA');
      this.mainApplicant.get('mailingAddress').setValue(mailingAddress?.streetAddress1);
      this.mainApplicant.get('aptUnit').setValue(mailingAddress?.streetAddress2);
      this.mainApplicant.get('mainApplicantCounty').setValue(mailingAddress?.county);
    }

    // Contact Section
    this.contact.get('emailAddress').setValue(data?.personalEmailAddress);
    this.contact.get('mobilePhoneNumber').setValue(data?.mobilePhone);
    this.contact.get('homePhoneNumber').setValue(data?.homePhone);
    this.validAddress = data?.personalEmailAddress;
    this.validMobilePhone = data?.mobilePhone;
    this.validHomePhone = data?.homePhone;

    // Co-Applicant Section
    if (data?.coEntity) {
      const coEntity = data.coEntity;
      this.coApplicant.get('isThereACoApplicant').setValue(true);
      this.coApplicant.get('coApplicantFirstName').setValue(this.namePipe.transform(coEntity.firstName));
      this.coApplicant.get('coApplicantMiddleInitial').setValue(this.namePipe.transform(coEntity.middleName));
      this.coApplicant.get('coApplicantDateOfBirth').setValue({ isRange: false, singleDate: { jsDate: new Date(coEntity?.birthDate) } });
      this.coApplicant.get('coApplicantLastName').setValue(this.namePipe.transform(coEntity.lastName));
      this.coApplicant.get('coApplicantMaritalStatus').setValue(LvMaritalStatuses.find(m => m.code === coEntity?.maritalStatusCode)?.code ?? '');
      this.coApplicant.get('coApplicantRelationship').setValue(coEntity.applicantRelationshipCode);
      this.coApplicant.get('isCoApplicantsMailingAddressSameAsApplicantsMailingAddress').setValue(coEntity?.isCoAppMailAddrAsAppMailAddr);
      this.coApplicant.get('coApplicantSameAsApplicantMailingAddress').setValue(coEntity?.entityAddresses[0]?.address.streetAddress1);
      this.coApplicant.get('coApplicantSameAsApplicantAptUnit').setValue(coEntity?.entityAddresses[0]?.address.streetAddress2);
      const coApplicantAddress = coEntity?.entityAddresses[0]?.address;
      this.coApplicant.get('coApplicantCounty').setValue(coApplicantAddress?.county ?? '');
      this.zipCodeData.selectedCity = coApplicantAddress?.city;
      this.zipCodeData.cityObservable.next({value: coApplicantAddress?.city, onFetch: true});
      this.populateCityList(coApplicantAddress?.zipCode, PageSections.CoApplicant);
      this.coApplicant.get('coApplicantSameAsApplicantZipCode').setValue(coApplicantAddress?.zipCode === '0' ? '' : coApplicantAddress?.zipCode);
      this.coApplicant.get('coApplicantSameAsApplicantCity').setValue(coApplicantAddress?.city === 'NA' ? '' : coApplicantAddress?.city);
      this.coApplicant.get('coApplicantSameAsApplicantState').setValue(coApplicantAddress?.state === 'NA' ? '' : coApplicantAddress?.state);
      this.coApplicant.get('coApplicantSameAsApplicantCountry').setValue(coApplicantAddress?.countryCode ?? 'USA');
      this.isCoAppUSCountry = coApplicantAddress?.countryCode ? coApplicantAddress?.countryCode === 'USA' ? true : false : true;

      const age = this.calculateInsuredAge(new Date(coEntity?.birthDate));
      this.coApplicantIsRetired = (age >= 55 && age <= 59);
      this.coApplicant.get('coApplicantisRetiredYesNo').setValue(coEntity.isRetired);
      this.setCoApplicantPageDisabledDOBandRetired();
    }

    // FCRA Reporting Act Section
    const coverages = data?.risks[0].riskDetails[0]?.riskCoverages[0];
    const notOrderedCode = LvCreditOrderStatus[LvCreditOrderStatusIndex.NotOrdered].code;
    this.fcraReportingActForm.get('fcraReportingAct').setValue(data?.creditScoreStatusCode !== notOrderedCode ? data?.fcraAcknowledgement : false);
    this.isfcraAcknowledgement = this.fcraReportingActForm.get('fcraReportingAct').value;

    if (data?.creditScoreStatusCode && data?.creditScoreStatusCode !== '') {
      this.fcraReportingActForm.get('creditScoreStatus').setValue(LvCreditOrderStatus.find(s => s.code === data?.creditScoreStatusCode).code);
      if (data?.creditScoreStatusCode !== notOrderedCode) {
        this.fcraReportingActForm.get('fcraReportingAct').disable();
      } else {
        this.fcraReportingActForm.get('fcraReportingAct').enable();
      }
    }

    const creditScoreDate = data?.creditScoreDateOrdered;
    if (creditScoreDate) {
      let dateStr = '';
      if (typeof creditScoreDate == 'string') {
        dateStr = creditScoreDate + 'Z';
      } else {
        dateStr = creditScoreDate.toISOString();
      }

      if (dateStr) {
        const creditScoreDateOrdered = new Date(dateStr);
        this.creditScoreDateOrdered = creditScoreDateOrdered;
        this.fcraReportingActForm.get('creditScoreDateOrdered').setValue({
          isRange: false, singleDate:
          {
            date:
            {
              year: creditScoreDateOrdered.getFullYear(),
              month: creditScoreDateOrdered.getMonth() + 1,
              day: creditScoreDateOrdered.getDate()
            },
            jsDate: creditScoreDateOrdered
          }
        } );
      }
    }
  }

  public populateInspectionData(data: InspectionOrderingDTO = {}) {
    this.inspection.get('inspectionOrdered').setValue(data?.isOrdered ?? false);
    this.inspection.get('inspectionOrderDate').setValue({ isRange: false, singleDate: { jsDate: data?.orderedDate == null ? '' : new Date(data?.orderedDate) } });
    this.inspection.get('inspectionType').setValue(data?.inspectionOrderingTypeCode ?? '');
    this.inspection.get('inspectionReceived').setValue(data?.isReceived ?? false);
    this.inspection.get('inspectionReceiptDate').setValue({ isRange: false, singleDate: { jsDate: data?.receivedDate == null ? '' : new Date(data?.receivedDate) } });
  }

  populateCityList(zipCode: string, section?) {
    // this.cityList = this.zipList.filter(data => data.address.zip === zipCode);
    switch (section) {
      case PageSections.ApplicantInformation:
        if (zipCode && zipCode !== '') {
          this.zipCodeService.getGenericZipCode(zipCode).pipe(takeUntil(this.stop$)).subscribe(res => {
            const zipCodeRes: any = res;
            this.zipCodeData.applicantInsuredCityList = [];
            zipCodeRes.map(val => {
              const city = val.city;
              this.zipCodeData.applicantInsuredCityList.push(city);
            });
          });
        }
        break;
      case PageSections.CoApplicant:
        if (zipCode && zipCode !== '') {
          this.zipCodeService.getGenericZipCode(zipCode).pipe(takeUntil(this.stop$)).subscribe(res => {
            const zipCodeRes: any = res;
            this.zipCodeData.coApplicantCityList = [];
            zipCodeRes.map(val => {
              const city = val.city;
              this.zipCodeData.coApplicantCityList.push(city);
            });
          });
        }
        break;
    }
  }

  protected resetApplicantContact(): void {
    this.validAddress = null;
    this.validHomePhone = null;
    this.validMobilePhone = null;
  }

  getApplicantForm() {
    return {
      mainApplicant: this.mainApplicant,
      contact: this.contact,
      coApplicant: this.coApplicant,
      fcraReportingActForm: this.fcraReportingActForm
    };
  }

  setApplicantPageFormStatus(status: boolean): void {
    FormUtils.setFormGroupStatus(this.mainApplicant, status);
    FormUtils.setFormGroupStatus(this.coApplicant, status);
    FormUtils.setFormGroupStatus(this.contact, status);
    FormUtils.setFormGroupStatus(this.fcraReportingActForm, status);
    FormUtils.setFormGroupStatus(this.inspection, status);

    if (status) {
      this.fcraReportingActForm.get('creditScoreStatus').disable();
      this.fcraReportingActForm.get('fcraReportingAct').disable();
    }
  }

  setToDefaultMailingAddress() {
    this.coApplicant.get('coApplicantSameAsApplicantMailingAddress').setValue('');
    this.coApplicant.get('coApplicantSameAsApplicantAptUnit').setValue('');
    this.coApplicant.get('coApplicantSameAsApplicantZipCode').setValue('');
    this.coApplicant.get('coApplicantSameAsApplicantState').setValue('');
    this.coApplicant.get('coApplicantSameAsApplicantCity').setValue('');
    this.zipCodeData.coApplicantCityList = [];
    this.coApplicant.get('coApplicantSameAsApplicantCountry').setValue('USA');
  }

  setToDefaultCoApplicant() {
    this.coApplicant.get('coApplicantFirstName').setValue('');
    this.coApplicant.get('coApplicantLastName').setValue('');
    this.coApplicant.get('coApplicantDateOfBirth').setValue('');
    this.coApplicant.get('coApplicantMaritalStatus').setValue('');
    this.coApplicant.get('coApplicantRelationship').setValue('');
    this.coApplicant.get('coApplicantMiddleInitial').setValue('');
    this.coApplicant.get('isCoApplicantsMailingAddressSameAsApplicantsMailingAddress').setValue(true);
    this.coApplicant.get('coApplicantisRetiredYesNo').setValue(false);
    this.coApplicantIsRetired = false;
  }

  setToDefaultInsuredMailingAddress() {
    this.mainApplicant.get('mailingAddress').setValue('');
    this.mainApplicant.get('aptUnit').setValue('');
    this.mainApplicant.get('zipCode').setValue('');
    this.mainApplicant.get('city').setValue('');
    this.mainApplicant.get('state').setValue('');
    this.mainApplicant.get('country').setValue('USA');
    this.zipCodeData.applicantInsuredCityList = [];
  }

  public markApplicantPageFormAsTouch(): void {
    FormUtils.markFormgroupAsTouch(this.mainApplicant);
    FormUtils.markFormgroupAsTouch(this.contact);
    FormUtils.markFormgroupAsTouch(this.coApplicant);
    FormUtils.markFormgroupAsTouch(this.fcraReportingActForm);
  }

  savePreviousApplicantInfo(): void {
    const applicantForm = this.mainApplicant;
    this.previousApplicantInfo.firstName = applicantForm.get('firstName').value;
    this.previousApplicantInfo.middleName = applicantForm.get('middleInitial').value;
    this.previousApplicantInfo.lastName = applicantForm.get('lastName').value;
    this.previousApplicantInfo.nameSuffix = applicantForm.get('nameSuffix').value;
    this.previousApplicantInfo.birthDate = applicantForm.get('dateOfBirth').value?.singleDate?.jsDate;
    //this.previousApplicantInfo.birthDate = new Date(applicantForm.get('dateOfBirth').value?.singleDate?.jsDate + 'UTC');
  }

  calculateInsuredAge(dateOfBirth: Date): number {
    if (!!!dateOfBirth) {
      return 0;
    }

    const today = this.authService.getCustomDate();
    const birthDate = new Date(dateOfBirth);
    const effectiveDate = this.mainApplicant.get('effectiveDate').value;
    const dateRef = !!!effectiveDate ? today : new Date(effectiveDate?.singleDate?.jsDate);

    let age: number = dateRef.getFullYear() - birthDate.getFullYear();
    const m: number = dateRef.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && dateRef.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  hasRetireeDiscount(isRetired: boolean, isRetiredYesNo: boolean, isSenior?: boolean): boolean {
    return (isRetired && isRetiredYesNo) || isSenior;
  }

  setApplicantPageDisabledDOBandRetired(): void {
    if (!this.isInternal && (this.policySummaryData.isPolicy || this.policySummaryData.renewalStatusCode === 'RWO' || this.policySummaryData.renewalStatusCode === 'RRWO')) {
      this.mainApplicant.get('dateOfBirth').disable();
      this.mainApplicant.get('isRetiredYesNo').disable();
    }
  }

  setCoApplicantPageDisabledDOBandRetired(): void {
    if (this.coApplicant.get('isThereACoApplicant').value) {
      if (this.policySummaryData.isPolicy &&
          !this.isInternal &&
          this.coApplicant.get('coApplicantFirstName').value &&
          this.hasCoApplicantDateOfBirth) {
        this.coApplicant.get('coApplicantisRetiredYesNo').disable();
        this.coApplicantDisabledDOB = this.isNewlyAddedCoApplicantDetails ? false : true;
      } else {
        this.coApplicant.get('coApplicantisRetiredYesNo').enable();
        this.coApplicantDisabledDOB = false;
      }
    } else {
      this.coApplicant.get('coApplicantisRetiredYesNo').enable();
      this.coApplicantDisabledDOB = false;
    }
  }

  getApplicantPageDisabledDOB(): boolean {
    if (!this.policySummaryData.isPolicy) {
      return true;
    }

    if (!this.isInternal || !this.isInternal &&  (this.policySummaryData.renewalStatusCode === 'RWO' || this.policySummaryData.renewalStatusCode === 'RRWO')) {
      return true;
    }

    return false;
  }

  getApplicantPageDisabledRetired(): boolean {
    if (!this.isInternal && this.policySummaryData.isPolicy || !this.isInternal &&  (this.policySummaryData.renewalStatusCode === 'RWO' || this.policySummaryData.renewalStatusCode === 'RRWO')) {
      return true;
    }

    return false;
  }


  subscribeUserType(): void {
    this.authService.userType.subscribe((userType) => {
      this.isInternal = this.isInternalUser(userType);
    });
  }

  protected isInternalUser(userType: string): boolean {
    return userType?.toLocaleLowerCase() === 'internal';
  }

  public isNewlyAddedCoApplicant(policyChangesList: any[]): void {
    const addedCoApplicantKeyWord = 'Added  Co Applicant';
    let hasNewlyAddedCoApplicantChanges: boolean = false;

    _.forEach(policyChangesList, data => {
      if (data.indexOf(addedCoApplicantKeyWord) > -1) {
        hasNewlyAddedCoApplicantChanges =  true;
        return false;
      }
    });

    this.isNewlyAddedCoApplicantDetails = hasNewlyAddedCoApplicantChanges;
  }
}
