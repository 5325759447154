/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UwApprovalPageModalComponent } from './uw-approval-page-modal/uw-approval-page-modal.component';
import { UWApprovalModalModel } from '../../../../shared/models/submission/uw-approval/uw-approval.model';
import { ClickTypes } from '../../../../shared/enum/click-type.enum';
import { Router } from '@angular/router';
import { SubmissionPageData } from '../../data/submission-page.data';
import { UWApprovalPageLabel } from '../../../../shared/constants/uw-approval.labels.constants';
import { ITableTd, IHideTableItems } from '../../../../shared/models/dynamic/table.interface';
import { UwApprovalData } from '../../data/uw-approval.data';
import { UWR } from '../../../../core/services/submission/uw-referrals/uwr.service';
import { UwApprovalFileModalComponent } from './uw-approval-file-modal/uw-approval-file-modal.component';
import { UwApprovalFileUploadModalComponent } from './uw-approval-file-upload-modal/uw-approval-file-upload-modal.component';
import { TableNameConstants } from '../../../../shared/constants/table.name.constants';
import { AuthService } from '../../../../core/services/auth.service';
import { NavigationService } from '../../../../core/services/navigation/navigation.service';
import { UwApprovalSavingData } from '../../data/uw-approval-saving.data';
import { BaseClass } from '../../../../shared/base-class';
import { takeUntil, distinctUntilChanged, filter } from 'rxjs/operators';
import { SubmissionNavValidationService } from '../../../../core/services/navigation/submission-nav-validation.service';
import { PolicyNavValidationService } from '../../../../core/services/navigation/policy-nav-validation.service';
import { PathConstants } from '../../../../shared/constants/path.constants';
import { PageSectionsValidations } from '../../../../shared/enum/page-sections.enum';
import { PolicySummaryData } from '../../../../modules/policy-management/data/policy-summary.data';
import { GenericConstants } from '../../../../shared/constants/generic.constants';
import { CoveragesData } from '../../../../modules/submission-management/data/coverages.data';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { PolicyNotesData } from '../../../../modules/policy-management/data/policy-notes.data';
import { NotesLabelConstants } from '../../../../shared/constants/bind-and-issue.labels.constants';
import { NotesCategories } from '../../../../shared/constants/notes.options.constants';
import { EndorsementsData } from '../../data/endorsements.data';
import { PropertyData } from '../../data/property.data';
import { ApplicantData } from '../../data/applicant.data';
import { InterestData } from '../../data/interest.data';
import { IDisableUWApprovalTableItems } from '../../../../shared/models/dynamic/table.interface';
import { EntityRiskData } from '../../../../modules/submission-management/data/entity-risk.data';
import { PolicyService } from '../../../../core/services/submission/policy.service';
import { PolicyIssueData } from '../../../../modules/policy-management/data/policy-issue.data';
import { AgentDashboardConstants } from '../../../../shared/constants/agent-dashboard.constants';
import { CalculateRaterData } from '../../../../core/services/submission/rater-premium/calculate-rater-data.service';
import { SummaryData } from '../../data/summary.data';
import { PageHeaderSummaryConstants } from 'app/shared/constants/page-header-summary.constants';
import { TaskData } from '../../../dashboard/data/task.data';
import Utils from 'app/shared/utilities/utils';
import { RenewalService } from 'app/core/services/submission/renewal/renewal.service';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { enableBindAndIssue } from 'app/modules/submission-management/submission-management-navitems';
import { AutoReinstatementData } from '../../../policy-management/data/auto-reinstatement.data';
import { combineLatest, Observable } from 'rxjs';
import { selectSubmissionUWRsIsLoading } from 'app/store/submission-uwrs/submission-uwrs.selectors';
import { select, Store } from '@ngrx/store';
import { selectPolicyUWRsIsLoading } from 'app/store/policy-uwrs/policy-uwrs.selectors';
import { selectRaterIsLoading } from 'app/store/rater/rater.selectors';
import {
  updateChangesIsLoadingFromUwApprovalComponent,
  updateChangesListFromUwApprovalComponent
} from 'app/store/changes/changes.actions';
import { selectClaimsIsLoading } from 'app/store/claims/claims.selectors';
import { selectAppRuwIsLoading } from 'app/store/app/app.selectors';
import { selectUWApprovalIsLoading } from 'app/store/uw-approval/uw-approval.selectors';
import { selectSubmissionIsLoading, selectSubmissionIsPopulatingForms } from 'app/store/submission/submission.selectors';
import { selectMoratoriumIsLoading } from 'app/store/moratorium/moratorium.selectors';
import { selectRaterCalculateDP3IsLoading } from 'app/store/rater-calculate-dp3/rater-calculate-dp3.selectors';
import { selectBackupIsLoading } from 'app/store/data-backup/data-backup.selectors';
import { selectCalculateValuationIsLoading } from 'app/store/calculate-valuation/calculate-valuation.selectors';

@Component({
  selector: 'app-uw-approval',
  templateUrl: './uw-approval.component.html',
  styleUrls: ['./uw-approval.component.scss'],
})
export class UwApprovalComponent extends BaseClass implements OnInit, OnDestroy {
  protected selectSubmissionIsLoading$: Observable<boolean>;
  protected selectMoratoriumIsLoading$: Observable<boolean>;
  protected selectRaterIsLoading$: Observable<boolean>;
  protected selectRaterCalculateDP3IsLoading$: Observable<boolean>;
  protected selectBackupIsLoading$: Observable<boolean>;
  protected selectSubmissionUWRsIsLoading$: Observable<boolean>;
  protected selectPolicyUWRsIsLoading$: Observable<boolean>;
  protected selectAppRuwIsLoading$: Observable<boolean>;
  protected selectClaimsIsLoading$: Observable<boolean>;
  protected selectUWApprovalIsLoading$: Observable<boolean>;
  protected selectCalculateValuationIsLoading$: Observable<boolean>;
  protected selectSubmissionIsPopulatingForms$: Observable<boolean>;

  public genericConstants = GenericConstants;
  public UWApprovalLabel = UWApprovalPageLabel;
  public TableNameConstants = TableNameConstants;
  NotesLabelConstants = NotesLabelConstants;
  private codeTypeDefinition: any = { status: 'statusOptions' };
  isOpen: boolean = false;
  isNotesOpen: boolean = false;
  @Input() data: UWApprovalModalModel;

  showLoader: boolean = false;

  uwaModalRef: BsModalRef | null;
  uwaFileTableModalRef: BsModalRef | null;
  uwaFileUploadModalRef: BsModalRef | null;
  hasScrollBar: boolean = false;
  uwApprovalModalData: Array<UWApprovalModalModel> = [];
  hideItems: IHideTableItems = {
    controlButtons: true,
    checkboxButton: true,
    addButton: false,
    deleteIcon: false
  };

  notesHideItems: IHideTableItems = {
    checkboxButton: false,
    fileIcon: true
  };
  agentDashboardContants = AgentDashboardConstants;
  showPreloader: boolean = true;

  constructor(
    protected modalService: BsModalService,
    protected router: Router,
    public submissionData: SubmissionPageData,
    public uwApprovalData: UwApprovalData,
    protected uwr: UWR,
    protected authService: AuthService,
    public navigationService: NavigationService,
    protected uwApprovalSavingData: UwApprovalSavingData,
    protected submissionNavValidationService: SubmissionNavValidationService,
    public policySummaryData: PolicySummaryData,
    public coveragesData: CoveragesData,
    public policyNotesData: PolicyNotesData,
    public endorsementsData: EndorsementsData,
    public propertyData: PropertyData,
    public applicantData: ApplicantData,
    public interestData: InterestData,
    protected raterData: CalculateRaterData,
    protected entityRiskData: EntityRiskData,
    protected policyService: PolicyService,
    public policyIssueData: PolicyIssueData,
    public policyNavValidationService: PolicyNavValidationService,
    protected summaryData: SummaryData,
    protected taskData: TaskData,
    protected renewalService: RenewalService,
    protected submissionPageData: SubmissionPageData,
    public autoReinstatementData: AutoReinstatementData,
    protected store: Store
  ) {
    super();
    this.selectSubmissionIsLoading$ = this.store.pipe(select(selectSubmissionIsLoading));
    this.selectMoratoriumIsLoading$ = this.store.pipe(select(selectMoratoriumIsLoading));
    this.selectRaterIsLoading$ = this.store.pipe(select(selectRaterIsLoading));
    this.selectRaterCalculateDP3IsLoading$ = this.store.pipe(select(selectRaterCalculateDP3IsLoading));
    this.selectBackupIsLoading$ = this.store.pipe(select(selectBackupIsLoading));
    this.selectCalculateValuationIsLoading$ = this.store.pipe(select(selectCalculateValuationIsLoading));
    this.selectSubmissionUWRsIsLoading$ = this.store.pipe(select(selectSubmissionUWRsIsLoading));
    this.selectPolicyUWRsIsLoading$ = this.store.pipe(select(selectPolicyUWRsIsLoading));
    this.selectRaterIsLoading$ = this.store.pipe(select(selectRaterIsLoading));
    this.selectAppRuwIsLoading$ = this.store.pipe(select(selectAppRuwIsLoading));
    this.selectUWApprovalIsLoading$ = this.store.pipe(select(selectUWApprovalIsLoading));
    this.selectClaimsIsLoading$ = this.store.pipe(select(selectClaimsIsLoading));
    this.selectSubmissionIsPopulatingForms$ = this.store.pipe(select(selectSubmissionIsPopulatingForms));
  }

  get riskId(): string {
    return this.coveragesData.entityRiskData.getRiskId();
  }

  get riskDetailId(): string {
    return this.entityRiskData.getRiskDetailId();
  }

  get IsRenewalReviseOffer(): boolean {
    return (this.policySummaryData.renewalStatusCode === 'RWO' || this.policySummaryData.renewalStatusCode === 'RRWO');
  }

  get isBindAndIssueDisabled(): boolean {
    return !enableBindAndIssue({
      enablePage: !this.submissionPageData.hasInvalidQuote.value,
      quoteStatus: this.submissionPageData.quoteStatus.value,
      allUwrApproved: this.submissionPageData.isAllUWApprovalApproved.value,
      hasAir: this.submissionPageData.hasAir,
      hasDecline: this.submissionPageData.hasDecline,
      uwrListEmpty: this.submissionPageData.uwrListEmpty,
      uwrListAllPostBind: this.submissionPageData.uwrListAllPostBind,
      uwrListAllNonPostBind: this.submissionPageData.uwrListAllNonPostBind,
      postBindUwrItemsChecked: this.submissionPageData.postBindUwrItemsChecked,
      postBindUwrItemsApproved: this.submissionPageData.postBindUwrItemsApproved,
      nonPostBindUwrItemsChecked: this.submissionPageData.nonPostBindUwrItemsChecked,
      nonPostBindUwrItemsApproved: this.submissionPageData.nonPostBindUwrItemsApproved,
      uwApprovalListAddedAllPostBind: this.submissionPageData.uwApprovalListAddedAllPostBind,
      hasPostBindUwrItem: this.submissionPageData.hasPostBindUwrItem
    });
  }

  ngOnInit() {
    this.initData(this.uwApprovalData.uwApprovalList);
    sessionStorage.setItem(PageSectionsValidations.CurrentPage, PathConstants.PageDestination.UWApproval);
    this.subscribeToUserType();
    if (this.policySummaryData.isPolicy) {
      this.showLoader = true;
      if (!this.entityRiskData.isDonePopulatingForms) {
        this.entityRiskData.isDonePopulatingForms$.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(() => {
          this.getPolicyChanges();
        });
      } else if (!this.entityRiskData.isDoneSavingForms) {
        this.entityRiskData.isDoneSavingForms$.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(() => {
          this.getPolicyChanges();
        });
      } else {
        this.getPolicyChanges();
      }
    }

  }

  subscribeToUserType(): void {
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(result => {
      const hide = !(result === this.genericConstants.userType.internal);
      this.hideItems.addButton = hide;
      this.hideItems.deleteIcon = hide;
    });
  }

  initData(uwApprovalList: any): void {
    this.uwApprovalData.showLoader = true;
    this.uwApprovalData.uwApprovalListComplete$.next(false);
    this.showPreloader = true;

    uwApprovalList.map((uwrDescription: string) => {
      const uwApproval: UWApprovalModalModel = {
        id: uuidv4(),
        description: uwrDescription,
        agentComment: '',
        status: this.UWApprovalLabel.uwStatus.pending,
        uwComment: ''
      };

      if (this.checkDuplicateUwr(uwrDescription)) {
        this.addTableItem(uwApproval);
      }
    });

    this.uwApprovalData.uwApprovalListComplete$.next(true);
    this.uwApprovalData.showLoader = false;
    this.checkShowPreloader();
  }

  checkDuplicateUwr(uwrDescription: string): boolean {
    const description: string[] = [];

    this.uwApprovalData.uwApprovalTableRows.forEach((item) => {
      if (uwrDescription === item.tr[0].value) {
        description.push(item.tr[0].value);
      }
    });

    return description.length === 0 ? true : false;
  }

  get hasInvalid(): boolean {
    if (!this.policySummaryData.isPolicy) {
      return this.submissionNavValidationService.isBindAndIssueDisabled;
    }

    return false;
  }

  deleteTableItem(rowId: any, tableRow: string): void {
    const pendingStatus = 'PEN';

    this.uwApprovalSavingData.markUWApprovalForSaving();
    this.uwApprovalData[tableRow].forEach((item, index) => {
      if (item.id === rowId) {
        this.uwApprovalData[tableRow].splice(index, 1);
        this.uwApprovalData.uwApprovalList.splice(index, 1);
      }
    });
    this.uwApprovalSavingData.saveUWApproval();
  }

  onSubmit(clickType?): void {
    if (!this.policySummaryData.isPolicy) {
      switch (clickType) {
        case ClickTypes.Back:
          this.navigationService.navigateRoute(PathConstants.PageDestination.Interests);
          // this.router.navigate(['/submissions/new/submission/interests']);
          break;
        case ClickTypes.Next:
          this.navigationService.navigateRoute(PathConstants.PageDestination.BindAndIssue);
          break;
        case ClickTypes.Save:
          // to do logic for save
          break;
        case ClickTypes.Revise:
          this.callReviseRenewal();
          break;
      }
    } else {
      this.onNextBackPolicy(clickType);
    }
  }

  callReviseRenewal(): void {
    Utils.blockUI();
    this.renewalService.putReviseRenewal(this.entityRiskData.getRiskId(), this.entityRiskData.getRiskDetailId()).pipe(takeUntil(this.stop$)).subscribe(() => {
      this.policySummaryData.renewalStatusCode = 'RRNPEN';
      this.router.navigate(['submissions/list']);
      Utils.unblockUI();
    }, (error) => {
      this.policySummaryData.renewalStatusCode = this.policySummaryData.renewalStatusCode === 'RWO' ? 'RWO' : 'RRWO';
      Utils.unblockUI();
      NotifUtils.showError(error);
    });
  }

  onNextBackPolicy(clickType): void {
    const page = clickType === ClickTypes.Back ? PathConstants.Policy.Policies.Interests :
      PathConstants.Policy.Policies.Issue;
    this.navigationService.navigatePolicyRoute(page);
  }

  showAddUwApproval(): void {
    this.uwApprovalSavingData.markUWApprovalForSaving();

    const initialState = {
      title: this.UWApprovalLabel.addTitle,
      uwApprovalModalData: this.uwApprovalModalData,
      isAdd: true,
      riskId: this.riskId
    };

    this.uwaModalRef = this.modalService.show(UwApprovalPageModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.uwaModalRef.content.event.pipe(takeUntil(this.stop$)).subscribe((res) => {
      this.addTableItem(res);
    });
  }

  addTableItem(newItem: any): void {
    const pendingStatus = 'PEN';

    this.uwApprovalSavingData.markUWApprovalForSaving();
    this.uwApprovalData.uwApprovalTableRows = [...this.uwApprovalData.uwApprovalTableRows, {
      id: uuidv4(),
      tr: this.getTr(newItem),
      itemId: null
    }];

    this.uwApprovalData.updateUwApprovalDataStatusDisplay();
  }

  getTr(newItem: any): ITableTd[] {
    const tr: ITableTd[] = [];
    const fields: string[] = [
      'description',
      'status',
      'uwComment',
      'agentComment',
    ];

    fields.forEach((item, index) => {
      tr.push({
        id: uuidv4(),
        value: newItem[item],
        display: newItem[item],
      });
    });

    return tr;
  }

  editTableItem(rowId: any, tableRow: string): void {
    this.uwApprovalSavingData.markUWApprovalForSaving();
    this.uwApprovalData[tableRow].forEach((item) => {
      if (item.id === rowId) {
        this.showEditUwApproval({
          id: item.id,
          description: item.tr[0].value,
          status: item.tr[1].value,
          uwComment: item.tr[2].value,
          agentComment: item.tr[3].value,
        });
      } else {
        item.edit = false;
      }
    });
  }

  updateTableItem(row: UWApprovalModalModel): void {
    this.uwApprovalData.uwApprovalTableRows.forEach((item) => {
      if (item.id === row.id) {
        item.tr[0].value = row.description;
        item.tr[1].value = row.status;
        item.tr[2].value = row.uwComment;
        item.tr[3].value = row.agentComment;
        item.tr[0].display = row.description;
        item.tr[1].display = this.findCodeToDisplayDescription(this.codeTypeDefinition.status, (status) => status.code === row.status);
        item.tr[2].display = row.uwComment;
        item.tr[3].display = row.agentComment;
      }
    });

    this.uwApprovalData.updateUwApprovalDataStatusDisplay();
  }

  onDelete(model): void {
    const data: UWApprovalModalModel = model;
    this.uwApprovalModalData = this.uwApprovalModalData.filter(
      (item) => item.id !== data.id
    );
    this.uwApprovalData.updateUwApprovalDataStatusDisplay();
  }

  checkFile(rowId: any): void {
    this.showUwApprovalFileTable(rowId);
  }

  uploadFile(rowId: any) {
    this.uwApprovalData.selectedRowId = rowId;
    this.showUwApprovalFileUpload(this.uwApprovalData.selectedRowId);
  }

  showEditUwApproval(data: UWApprovalModalModel): void {
    const uwApproval: UWApprovalModalModel = {
      id: data.id,
      description: data.description,
      agentComment: data.agentComment,
      status: data.status,
      uwComment: data.uwComment,
    };

    const initialState = {
      title: this.UWApprovalLabel.editTitle,
      uwApprovalModel: uwApproval,
      uwApprovalModalData: this.uwApprovalModalData,
      isAdd: false,
      indexToUpdate: data.id,
      riskId: this.riskId
    };

    this.uwaModalRef = this.modalService.show(UwApprovalPageModalComponent, {
      initialState,
      backdrop: 'static',
      ignoreBackdropClick: true
    });

    this.uwaModalRef.content.event.pipe(takeUntil(this.stop$)).subscribe((res: UWApprovalModalModel) => {
      this.updateTableItem(res);
    });
  }

  showUwApprovalFileTable(rowId: any): void {
    const uwrdesc = this.uwApprovalData.uwApprovalTableRows.find(a => a.id === rowId);
    const initialState = {
      title: this.UWApprovalLabel.fileModal.title,
      uwApprovalModalData: this.uwApprovalModalData,
      rowId: rowId,
      uwrDescription: uwrdesc.tr[0].display
    };

    this.uwaFileTableModalRef = this.modalService.show(
      UwApprovalFileModalComponent,
      {
        initialState,
        backdrop: true,
        ignoreBackdropClick: true,
      }
    );

    this.uwaFileTableModalRef.content.openUploadFileModal.pipe(takeUntil(this.stop$)).subscribe((res) => {
      this.uwaFileTableModalRef.hide();
      this.showUwApprovalFileUpload(res);
    });
  }

  showUwApprovalFileUpload(rowId: any): void {
    const initialState = {
      title: this.UWApprovalLabel.fileUploadModal.title,
      uwApprovalModalData: this.uwApprovalModalData,
      rowId: this.uwApprovalData.selectedRowId,
    };

    this.uwaFileUploadModalRef = this.modalService.show(
      UwApprovalFileUploadModalComponent,
      {
        initialState,
        backdrop: true,
        ignoreBackdropClick: true,
      }
    );
  }

  collapse(collapseID: number): void {
    if (collapseID === 1) {
      this.isOpen = !this.isOpen;
      this.submissionData.isReferralOpen = !this.isOpen;
    } else if (collapseID === 2) {
      this.isNotesOpen = !this.isNotesOpen;
    }
  }

  findCodeToDisplayDescription(codeType: string, callbackPredicate: any): string {
    switch (codeType) {
      case this.codeTypeDefinition.status:
        return this.uwApprovalData.statusOptions.find(callbackPredicate).description;
      default:
        break;
    }
  }

  referToUw(): void {
    this.uwApprovalSavingData.referToUw();
    this.disableAllFormPage();
  }

  approvedByUw(): void {
    this.submissionData.actionType = this.genericConstants.uwStatus.approve;
    this.uwApprovalSavingData.saveUWApproval();
    this.submissionData.actionType = null;
  }

  additionalInformationRequired(): void {
    this.submissionData.actionType = this.genericConstants.uwStatus.air;
    this.uwApprovalSavingData.saveUWApproval();
    this.submissionData.actionType = null;
  }

  declinedByUw(): void {
    this.submissionData.actionType = this.genericConstants.uwStatus.reject;
    this.uwApprovalSavingData.saveUWApproval();
    this.submissionData.actionType = null;
    this.disableAllFormPage();
  }

  addNotesTableItem(newItem: any): void {
    this.policyNotesData.addTableItem(newItem, newItem.id);
    this.policyNotesData.notesList.push({
      id: newItem.id,
      category: newItem.category,
      description: newItem.description,
      createdDate: newItem.dateAdded,
      riskBindId: this.policyNotesData.currentRiskBindId,
      notesId: newItem.id,
      createdBy: newItem.createdBy,
      riskId: this.policySummaryData.clonePolicyId,
      riskDetailId: this.policySummaryData.riskDetailId,
      createdByFullName: newItem.createdByFullName,
      isAdd: true
    });
    this.policyNotesData.saveNotes();
  }

  updateNotesTableItem(row: any): void {
    this.policyNotesData.notesTableRows.forEach((item) => {
      if (item.id === row.id) {
        item.tr[0].value = row.category;
        item.tr[1].value = row.description;
        item.tr[2].value = row.dateAdded;
        item.tr[3].value = row.createdByFullName;

        item.tr[0].display = _.find(NotesCategories, ['code', row.category])?.description || '';
        item.tr[1].display = row.description;
        item.tr[2].display = row.dateAdded?.singleDate.formatted ? row.dateAdded?.singleDate.formatted : row.dateAdded;
        item.tr[3].display = row.createdByFullName;
      }
    });

    this.policyNotesData.notesList.forEach((item) => {
      if (item.id === row.id) {
        item.category = row.category;
        item.description = row.description;
        item.dateAdded = row.dateAdded;
      }
    });
    this.policyNotesData.saveNotes();
  }

  deleteNotesTableItem(rowId: any, tableRow: string): void {
    this.policyNotesData[tableRow].forEach((item, index) => {
      if (item.id === rowId) {
        this.policyNotesData[tableRow].splice(index, 1);
        const noteListIndex = this.policyNotesData.notesList.findIndex(a => a.id === rowId);
        this.policyNotesData.notesList.splice(noteListIndex, 1);
      }
    });
    this.policyNotesData.saveNotes();
  }

  get isExternalUser(): boolean {
    return this.authService.userType.value === this.genericConstants.userType.external;
  }

  get disableItems(): IDisableUWApprovalTableItems {
    return {
      uwApprovalOptions: this.isExternalUser
    };
  }

  disableAllFormPage(): void {
    if (this.policySummaryData.isPolicy) {
      this.coveragesData.setCoveragePageFormStatus(false);
      this.endorsementsData.setEndorsementPageFormStatus(false);
      this.propertyData.setPropertyPageFormStatus(false);
      this.applicantData.setApplicantPageFormStatus(false);
      this.interestData.setInterestPageFormStatus(false);
      this.policySummaryData.isEditPolicy = false;
      localStorage.setItem('isEditPolicy', 'false');
    }
  }

  get isRenewalOffered(): boolean {
    return (this.policySummaryData.renewalStatusCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RWO ||
      this.policySummaryData.renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RWO) ||
      (this.policySummaryData.renewalStatusCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO ||
      this.policySummaryData.renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO);
  }

  calculateDiff(sentDate) {
    const date1: any = sentDate;
    const date2: any = this.authService.getCustomDate();
    const diffDays: any = Math.floor((date1 - date2) / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  get disableFormFields(): boolean {

    const renewalEffectiveDate = new Date(this.entityRiskData.EntityRisk?.risks[0]?.renewalEffectiveDate);
    const diffDays = this.calculateDiff(renewalEffectiveDate);

    if (this.isRenewalOffered &&
        this.authService.userType.value === GenericConstants.userType.external &&
        diffDays < 2) {
      return true;
    }

    return this.submissionData.disableQuoteForms;
  }

  get isRRWOQuoteStatus(): boolean {
    return (this.uwApprovalData.uwApprovalList.length > 0 && this.submissionData.quoteStatus.value !== 'Approved by UW');
  }

  get isRefferedtoUWStatus(): boolean {
    return this.submissionData.policyStatus?.value === this.agentDashboardContants.referredToUW && this.coveragesData.isExternal;
  }

  get isDeclinedUWStatus(): boolean {
    return this.submissionData.policyStatus?.value === this.agentDashboardContants.declineByUW && this.coveragesData.isExternal;
  }

  tableChanges(changes: any): void {
    this.uwApprovalData.checkPendingItems();
    this.uwApprovalData.uwApprovalTableRows$.next(changes);
  }

  ngOnDestroy() {
    this.showLoader = false;
  }

  getPolicyChanges(): void {
    if (this.entityRiskData.getPolicyStatus() !== 'CAN') {
      this.policyIssueData.isPolicyChangesLoading = true;
      this.uwApprovalData.hurricaneDeductibleChangesDescription = this.policySummaryData.policyChanges?.find(x => x.includes('Changed Hurricane Deductible From:'));
      this.policyIssueData.resetPolicyChanges();
      this.store.dispatch(updateChangesIsLoadingFromUwApprovalComponent({ isLoading: true }));

      this.policyService.getPolicyChanges(this.riskId, this.riskDetailId).pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe((policyChanges: Array<string>) => {
        this.policyIssueData.policyChangesList = policyChanges;
        this.policyIssueData.policyChangesLength = policyChanges.length;
        this.summaryData.setSubStatusCode();
        if (this.policyIssueData.policyChangesLength > 0) {
          this.uwr.checkUWR106();
        } else {
          this.uwr.updateUWR106(false);
        }
        if (this.uwr.hasUWR106) {
          this.initData(this.uwApprovalData.uwApprovalList);
        }
        this.showLoader = false;
        this.policyIssueData.isPolicyChangesLoading = false;

        this.store.dispatch(updateChangesIsLoadingFromUwApprovalComponent({ isLoading: false }));
        this.store.dispatch(updateChangesListFromUwApprovalComponent({ list: policyChanges }));
      }, error => {
        this.showLoader = false;
        this.policyIssueData.isPolicyChangesLoading = false;
        this.store.dispatch(updateChangesIsLoadingFromUwApprovalComponent({ isLoading: false }));
      });
    } else {
      this.showLoader = false;
    }
  }

  get disableReviseRenewalOffer(): boolean {
    const disableReviseRenewalOffer = this.IsRenewalReviseOffer ||
      !this.submissionData.hasRenewalOfferChanges.value ||
      !this.uwApprovalData.isPolicyChangesDone ||
      this.isRRWOQuoteStatus;

    if (this.isBindAndIssueDisabled) {
      return disableReviseRenewalOffer;
    }

    return disableReviseRenewalOffer &&
      this.uwApprovalData.uwrListAllPostBind &&
      !(this.submissionData.quoteStatus.value === 'Approved by UW');
  }

  checkShowPreloader(): void {
    const selectSubmissionIsLoading$ = this.selectSubmissionIsLoading$
      .pipe(takeUntil(this.stop$));

    const selectSubmissionUWRsIsLoading$ = this.selectSubmissionUWRsIsLoading$
      .pipe(takeUntil(this.stop$));

    const selectPolicyUWRsIsLoading$ = this.selectPolicyUWRsIsLoading$
      .pipe(takeUntil(this.stop$));

    const selectClaimsIsLoading$ = this.selectClaimsIsLoading$
      .pipe(takeUntil(this.stop$));

    const selectUWApprovalIsLoading$ = this.selectUWApprovalIsLoading$
      .pipe(takeUntil(this.stop$));

    const selectMoratoriumIsLoading$ = this.selectMoratoriumIsLoading$
      .pipe(takeUntil(this.stop$));

    const selectRaterIsLoading$ = this.selectRaterIsLoading$
      .pipe(takeUntil(this.stop$));

    const selectBackupIsLoading$ = this.selectBackupIsLoading$
      .pipe(takeUntil(this.stop$));

    const selectRaterCalculateDP3IsLoading$ = this.selectRaterCalculateDP3IsLoading$
      .pipe(takeUntil(this.stop$));

    const selectCalculateValuationIsLoading$ = this.selectCalculateValuationIsLoading$
      .pipe(takeUntil(this.stop$));

    const selectSubmissionIsPopulatingForms$ = this.selectSubmissionIsPopulatingForms$
      .pipe(takeUntil(this.stop$));

    const selectAppRuwIsLoading$ = this.selectAppRuwIsLoading$
      .pipe(takeUntil(this.stop$));

    combineLatest([
      selectSubmissionIsLoading$,
      selectSubmissionUWRsIsLoading$,
      selectPolicyUWRsIsLoading$,
      selectClaimsIsLoading$,
      selectUWApprovalIsLoading$,
      selectMoratoriumIsLoading$,
      selectRaterIsLoading$,
      selectBackupIsLoading$,
      selectRaterCalculateDP3IsLoading$,
      selectCalculateValuationIsLoading$,
      selectSubmissionIsPopulatingForms$,
      selectAppRuwIsLoading$
    ]).pipe(takeUntil(this.stop$), distinctUntilChanged()).subscribe(result => {
      const allNull: boolean = result.every(item => {
        return item === null;
      });

      const preloaderTimeOut: number = 3000;

      if (!allNull) {
        if (result.includes(true)) {
          this.showPreloader = true;

          return;
        }

        setTimeout(() => {
          this.showPreloader = false;
        }, preloaderTimeOut);
      }
    });
  }
}
