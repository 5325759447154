import { Pipe, PipeTransform } from '@angular/core';
import { LvTaskDescription } from 'app/shared/constants/task.options.constants';

@Pipe({
  name: 'hasRenewalTask'
})
export class UWApprovalHasRenewalTaskPipe implements PipeTransform {

  transform(taskDescriptionCodeListLength: boolean, taskDescriptionCodeList: any): boolean {
    const taskDescriptionCode = LvTaskDescription?.find(task => {
      return task.id === 39
    });

    const hasRUWR = taskDescriptionCodeList?.includes(taskDescriptionCode?.code);

    return taskDescriptionCodeListLength && hasRUWR;
  }
}
