import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { PolicyBillingData } from 'app/modules/policy-management/data/policy-billing.data';
import { GenericLabel } from 'app/shared/constants/generic.labels.constants';
import { ZipCodeData } from 'app/modules/submission-management/data/zipcode.data';
import Utils from 'app/shared/utilities/utils';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { PageSections } from 'app/shared/enum/page-sections.enum';
import { PolicyBillingLabelsConstants } from 'app/shared/constants/policy-billing.labels.constants';
import FormUtils from '../../../../../../shared/utilities/form.utils';

@Component({
  selector: 'app-post-payment-eft',
  templateUrl: './post-payment-eft.component.html',
  styleUrls: ['./post-payment-eft.component.scss']
})
export class PostPaymentEftComponent implements OnInit {

  @Input() eftPaymentForm: FormGroup;
  @Input() hasEmail: boolean = false;
  @Input() isRefundRequest: boolean = false;

  public FormUtils = FormUtils;

  ErrorMessageConstant = ErrorMessageConstant;
  EftPaymentConstants = PolicyBillingLabelsConstants.eft;
  GenericLabel = GenericLabel;

  constructor(public billingData: PolicyBillingData,
    public zipCodeData: ZipCodeData) { }

  ngOnInit() {
  }

  setZipCode(isUSCountry?: boolean) {
    this.eftPaymentForm.get('city').enable();
    const zipCode = this.eftPaymentForm.get('zip').value;
    let formControlNames = ['state', 'city', 'zip'];
    this.zipCodeData.eftCityList = [];
    Utils.blockUI();
    if (zipCode !== '' && isUSCountry) {
      this.zipCodeData.getGenericZipCode(zipCode, this.eftPaymentForm, formControlNames, PageSections.EFTPayment);
    } else {
      if (isUSCountry) { // IF US COUNTRY AS IS
        this.resetZipFields(formControlNames);
        Utils.unblockUI();
        NotifUtils.showError('Zip code not found, contact Underwriting');
      } else { // IF NOT US COUNTRY
        formControlNames = ['state', 'city', 'zip', 'address'];
        this.resetZipFields(formControlNames);
        Utils.unblockUI();
      }
    }
    this.zipCodeData.billingCityListComplete();
  }

  resetZipFields(formControlNames): void {
    this.FormUtils.resetFields(this.eftPaymentForm as FormGroup, formControlNames);
    this.zipCodeData.eftCityList = [];
  }
}
