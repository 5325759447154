import { IProcessedRaterView } from 'app/shared/models/rater-premium/processed-rater-view.model';
import { createAction, props } from '@ngrx/store';

export const updateRaterIsLoadingFromHORaterRequestData = createAction(
  '[HORaterRequestData] Update Rater Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateRaterListFromHORaterRequestData = createAction(
  '[HORaterRequestData] Update Rater List',
  props<{ list: IProcessedRaterView[] }>()
);

export const updateRaterIsLoadingFromSubmissionManagementComponent = createAction(
  '[SubmissionManagementComponent] Update Rater Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateRaterIsLoadingFromPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update Rater Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateRaterIsLoadingFromIssuePolicyComponent= createAction(
  '[IssuePolicyComponent] Update Rater Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateRaterIsLoadingFromPolicyIssueData = createAction(
  '[PolicyIssueData] Update Rater Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateRaterListFromCalculateRaterData = createAction(
  '[CalculateRaterData] Update Rater List',
  props<{ list: IProcessedRaterView[] }>()
);

export const updateRaterProratedListFromCalculateRaterData = createAction(
  '[CalculateRaterData] Update Prorated List',
  props<{ proratedList: IProcessedRaterView[] }>()
);

export const updateRaterProratedListFromPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update Prorated List',
  props<{ proratedList: IProcessedRaterView[] }>()
);