import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ClickTypes } from '../../../shared/enum/click-type.enum';
import { ApplicantData } from '../data/applicant.data';
import { SubmissionPageData } from '../data/submission-page.data';
import { SummaryData } from '../data/summary.data';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { NavigationService } from '../../../core/services/navigation/navigation.service';
import { ApplicantSavingData } from '../data/applicant.saving.data';
import { GeneralValidationService } from '../../../core/services/submission/validations/general-validation.service';
import { CustomValidators } from '../../../shared/validators/custom.validator';
import { RegexConstants } from '../../../shared/constants/regex.constants';
import { NameCasePipe } from '../../../shared/custom pipes/name-pipe';
import { ApplicantValidationService } from '../../../core/services/submission/validations/applicant-validation.service';
import { SubmissionNavValidationService } from '../../../core/services/navigation/submission-nav-validation.service';
import { PathConstants } from '../../../shared/constants/path.constants';
import { PageSections, PageSectionsValidations } from '../../../shared/enum/page-sections.enum';
import { ErrorMessageConstant } from '../../../shared/constants/error-message.constants';
import { PolicySummaryData } from '../../../modules/policy-management/data/policy-summary.data';
import { ZipCodeData } from '../data/zipcode.data';
import FormUtils from '../../../shared/utilities/form.utils';
import { ThirdPartyData } from '../data/third-party.data';
import Utils from '../../../shared/utilities/utils';
import { ThirdPartyDataService } from '../../../core/services/submission/third-party/third-party-data.service';
import { BaseClass } from '../../../shared/base-class';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { LvCreditOrderStatus, LvCreditOrderStatusIndex } from '../../../shared/constants/quick-quote.options.constants';
import { CoveragesData } from '../data/coverages.data';
import { EntityRiskData } from '../data/entity-risk.data';
import { UWR } from '../../../core/services/submission/uw-referrals/uwr.service';
import { Subject } from 'rxjs';
import { AgentDashboardConstants } from '../../../shared/constants/agent-dashboard.constants';
import { PageHeaderSummaryConstants } from 'app/shared/constants/page-header-summary.constants';
import { AuthService } from 'app/core/services/auth.service';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { PolicyService } from 'app/core/services/submission/policy.service';

@Component({
  selector: 'app-applicant',
  templateUrl: './applicant.component.html',
  styleUrls: ['./applicant.component.scss'],
})
export class ApplicantComponent extends BaseClass implements OnInit, OnDestroy {
  public ErrorMessageConstant = ErrorMessageConstant;
  public FormUtils = FormUtils;
  agentDashboardContants = AgentDashboardConstants;

  constructor(
    public submissionData: SubmissionPageData,
    public applicantData: ApplicantData,
    public navigationService: NavigationService,
    protected applicantSavingData: ApplicantSavingData,
    protected generalValidationService: GeneralValidationService,
    public formValidation: SubmissionNavValidationService,
    public applicantValidationService: ApplicantValidationService,
    public policySummaryData: PolicySummaryData,
    protected zipCodeData: ZipCodeData,
    public thirdPartyData: ThirdPartyData,
    public thirdPartyService: ThirdPartyDataService,
    public coveragesData: CoveragesData,
    protected toastr: ToastrService,
    protected entityRiskData: EntityRiskData,
    protected uwr: UWR,
    protected authService: AuthService,
    protected policyService: PolicyService
  ) {
    super();
  }

  get riskId() {
    return this.entityRiskData.getRiskId();
  }

  get riskDetailId() {
    return this.entityRiskData.getRiskDetailId();
  }

  get isRenewalOffered(): boolean {
    return (this.policySummaryData.renewalStatusCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RWO ||
      this.policySummaryData.renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RWO) ||
      (this.policySummaryData.renewalStatusCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO ||
      this.policySummaryData.renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO);
  }

  ngOnInit(): void {
    this.resetValidatorFormControl();
    sessionStorage.setItem(PageSectionsValidations.CurrentPage, PathConstants.PageDestination.Applicant);
    this.subscribeToFormChanges();
    this.policySummaryData.navigatedToPolicyIssue = false;
    this.initGetPolicyChanges();
  }

  subscribeToFormChanges(): void {
    const applicantForms = ['mainApplicant', 'contact', 'coApplicant', 'fcraReportingActForm'];

    this.uwr.uwrPopulated$.pipe(takeUntil(this.stop$)).subscribe((result) => {
      if (result) {
        applicantForms.forEach(form => {
          this.applicantData[form].valueChanges.pipe(takeUntil(this.stop$)).subscribe(() => {
            this.uwr.getUwrReferrals(false);
          });
        });
      }
    });
  }

  calculateDiff(sentDate) {
    const date1: any = sentDate;
    const date2: any = this.authService.getCustomDate();
    const diffDays: any = Math.floor((date1 - date2) / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  get disableFormFields(): boolean {

    if (this.isRefferedtoUWStatus) {
      return true;
    }

    if (this.policySummaryData.isPolicy) {
      return !(this.policySummaryData.isEditPolicy || this.isRefferedtoUWStatus);
    }

    const renewalEffectiveDate = new Date(this.entityRiskData.EntityRisk?.risks[0]?.renewalEffectiveDate);
    const diffDays = this.calculateDiff(renewalEffectiveDate);

    if (this.isRenewalOffered &&
        this.authService.userType.value === GenericConstants.userType.external &&
        diffDays < 2) {
      return true;
    }

    return this.submissionData.disableQuoteForms;
  }

  get isRefferedtoUWStatus(): boolean {
    return this.submissionData.policyStatus?.value === this.agentDashboardContants.referredToUW && this.coveragesData.isExternal;
  }

  get isDeclinedUWStatus(): boolean {
    return this.submissionData.policyStatus?.value === this.agentDashboardContants.declineByUW && this.coveragesData.isExternal;
  }

  resetValidatorFormControl() {
    const isMailingAddressSameInsured = this.entityRiskData.EntityRisk?.isMailingAddressSameInsured ?? true;
    const isThereCoApplicant = this.entityRiskData.EntityRisk?.coEntity !== null;
    const isCoAppMailAddrAsAppMailAddr = this.entityRiskData.EntityRisk?.coEntity?.isCoAppMailAddrAsAppMailAddr ?? true;
    if(!isMailingAddressSameInsured) {
      this.generalValidationService.resetValidatorFormControl(this.applicantData.mainApplicant, 'mailingAddress', [Validators.required, CustomValidators.spaceValidator]);
      this.generalValidationService.resetValidatorFormControl(this.applicantData.mainApplicant, 'zipCode',  [Validators.required, Validators.pattern(RegexConstants.zipValidation), CustomValidators.hasNoValue]);
      this.generalValidationService.resetValidatorFormControl(this.applicantData.mainApplicant, 'state', [Validators.required]);
      this.generalValidationService.resetValidatorFormControl(this.applicantData.mainApplicant, 'city', [Validators.required]);
      this.generalValidationService.resetValidatorFormControl(this.applicantData.mainApplicant, 'country', [Validators.required]);
    } else {
      this.generalValidationService.clearValidatorFormControl(this.applicantData.mainApplicant, 'mailingAddress');
      this.generalValidationService.clearValidatorFormControl(this.applicantData.mainApplicant, 'zipCode');
      this.generalValidationService.clearValidatorFormControl(this.applicantData.mainApplicant, 'state');
      this.generalValidationService.clearValidatorFormControl(this.applicantData.mainApplicant, 'city');
      this.generalValidationService.clearValidatorFormControl(this.applicantData.mainApplicant, 'country');
      this.applicantData.mainApplicant.get('isApplicantsMailingAddressSameAsInsuredLocation').setValue(true);
    }
    if(isThereCoApplicant) {
      this.applicantValidationService.setCoApplicantValidation();
      if (isCoAppMailAddrAsAppMailAddr && (this.applicantData.coApplicant.get('coApplicantSameAsApplicantCountry').value === 'USA')) {
        this.applicantValidationService.setCoApplicantAddressValidation();
        this.setZipCode();
      } else {
        this.applicantValidationService.clearCoApplicantAddressValidation();
      }
    } else {
      this.applicantData.coApplicant.get('isCoApplicantsMailingAddressSameAsApplicantsMailingAddress').setValue(isCoAppMailAddrAsAppMailAddr);
      this.applicantValidationService.clearCoApplicantAddressValidation();
      this.applicantValidationService.clearCoApplicantValidation();
    }
  }

  public onClick(clickType?) {
    if (clickType === ClickTypes.Calculate) {
      this.applicantSavingData.saveApplicant();
      return;
    }

    if (!this.policySummaryData.isPolicy) {
      switch (clickType) {
      case ClickTypes.Back:
        this.navigationService.navigateRoute(PathConstants.PageDestination.Property);
        break;
      case ClickTypes.Next:
        this.applicantValidationService.checkApplicantPage();
        this.applicantValidationService.checkCoApplicantSection();
        const hasCoApplicant = this.applicantData.coApplicant.get('isThereACoApplicant').value;
        const hasMissingFields = !this.formValidation.applicantValidStatus || (hasCoApplicant && !this.formValidation.coApplicantValidStatus);
        const isSubmission = !this.policySummaryData.isPolicyPage(this.riskId);
        const hasAgreed = this.applicantData.fcraReportingActForm.get('fcraReportingAct').value;
        const doesCallCreditApi = isSubmission && hasAgreed && this.applicantData.fcraReportingActForm.get('fcraReportingAct').enabled;
        this.navigationService.navigateRoute(
          this.submissionData.isUWQuestionHide() ?
          PathConstants.PageDestination.Interests :
          PathConstants.PageDestination.UWQuestions
        );
        break;
      }
    } else {
      if (clickType !== ClickTypes.Calculate) {
        this.onNextBackPolicy(clickType);
      }
    }
  }

  onNextBackPolicy(clickType): void {
    const page = clickType === ClickTypes.Back ? PathConstants.Policy.Policies.Property :
      PathConstants.Policy.Policies.UWQuestions;
    this.navigationService.navigatePolicyRoute(page, true);
  }

  ngOnDestroy() {
    this.applicantData.mainApplicant.markAllAsTouched();
    this.applicantData.coApplicant.markAllAsTouched();
    this.applicantData.contact.markAllAsTouched();
    this.applicantData.fcraReportingActForm.markAllAsTouched();
  }

  setZipCode() {
    const zipCode = this.applicantData.coApplicant.get('coApplicantSameAsApplicantZipCode').value;
    const formControlNames = ['coApplicantSameAsApplicantState', 'coApplicantCounty', 'coApplicantSameAsApplicantCity', 'coApplicantSameAsApplicantZipCode'];
    if(zipCode) {
      this.zipCodeData.getGenericZipCode(zipCode, this.applicantData.coApplicant, formControlNames, PageSections.CoApplicant);
    } else {
      this.FormUtils.resetFields(this.applicantData.coApplicant, formControlNames);
      this.zipCodeData.coApplicantCityList = [];
    }
  }

  initGetPolicyChanges(): void {
    const activeRiskDetail = this.entityRiskData.getCurrentRiskDetails();
    if (activeRiskDetail?.isEndorsement) {
      this.policyService.getPolicyChanges(this.riskId, this.riskDetailId).pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe((policyChanges: Array<string>) => {
        this.applicantData.isNewlyAddedCoApplicant(policyChanges);
        this.applicantData.setCoApplicantPageDisabledDOBandRetired();
      });
    }
  }
}
