import { IBcegsRecord } from '../quick-quote/risk-property-quick-quote.dto';
import { AirHazardResponseDTO } from './air-hazard-response.dto';
import { CreditScoreRiskResponseDTO } from './credit-score-risk-response.dto';
import { FloodAssistOrderPrefillDTO } from './flood-assist-order-prefill.dto';
import { ResidentialResponseDTO } from './residential-response.dto';
import { RiskClaimDTO } from './risk-claims.dto';
import { ThirdPartyErrorDTO } from './third-party-error.dto';
import { TorrentResponseDTO } from './torrent-flood-quote.dto';

export class AllResponseDTO {
  county?: string[];
  distanceToActualCoast?: string;
  distanceToWater?: string;
  distanceToActualCoastCode?: string;
  distanceToWaterCode?: string;
  distanceToCoastReturnSource?: string;
  matchLevel?: string;
  oceanName?: string;
  respondingFireDepartment?: string;
  windPoolEligible?: string;
  windPoolEligibleReturnSource?: string;
  isMilesToFireStationFromISO?: boolean;
  milesToFireStation?: string;
  nearestWaterName?: string;
  fireDistrictName?: string;
  protectionClassLocationLowerNumber?: string;
  protectionClassLocationHigherNumber?: string;
  bcegCommunity?: string;
  bceg?: string;
  isoTerritoryCode?: string;
  flatTile?: string;
  yearRoofInstalled?: string;
  isoLocationPPC?: string;
  isoLocationPPCReturnSource?: string;
  fireStationDistanceBand?: number;
  claims?: RiskClaimDTO[];
  iso360Value?: ResidentialResponseDTO;
  creditScoreInfo?: CreditScoreRiskResponseDTO;
  airHazard?: AirHazardResponseDTO;
  torrentFloodQuote?: TorrentResponseDTO;
  floodAssist?: FloodAssistOrderPrefillDTO;
  errors?: ThirdPartyErrorDTO[];
  bcegRecords?: IBcegsRecord[] | null;
  aPlusReportRecord?: number;
  isLastAPlusCallSuccess?: boolean;
  groupiiCodePial?: string;
  groupiiNamePial?: string;
  groupiiPialZoneReturnSource?: string;
  returnedISOLocationPIAL?: string;
  public constructor(init?: Partial<AllResponseDTO>) {
    Object.assign(this, init);
  }
}
