import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-question-notif-section',
  templateUrl: './question-notif-section.component.html',
  styleUrls: ['./question-notif-section.component.scss']
})
export class QuestionNotifSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
