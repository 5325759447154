import { Injectable } from '@angular/core';
import { BaseClass } from 'app/shared/base-class';
import * as _ from 'lodash';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { UWRHelpers } from './uwr-helpers.service';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectInterestsList } from 'app/store/interests/interests.selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { CoveragesData } from 'app/modules/submission-management/data/coverages.data';
import { selectRaterList } from 'app/store/rater/rater.selectors';
import { IProcessedRaterView } from 'app/shared/models/rater-premium/processed-rater-view.model';
import { RiskClaimDTO } from 'app/shared/models/data/dto/third-party/risk-claims.dto';
import { selectClaimsList } from 'app/store/claims/claims.selectors';
import { ClaimsData } from 'app/modules/submission-management/data/claims.data';
import * as moment from 'moment';
import { EndorsementsData } from 'app/modules/submission-management/data/endorsements.data';
import { RiskScheduleDTO } from 'app/shared/models/data/dto/quick-quote/risk-schedule.dto';
import { selectCoveragesSchedules } from 'app/store/coverages/coverages.selectors';
import { AuthService } from '../../auth.service';

@Injectable({
  providedIn: 'root',
})
export class AsyncBaseUWR extends BaseClass {
  protected selectInterestsList$: Observable<any>;
  protected selectRaterList$: Observable<IProcessedRaterView[]>;
  protected selectClaimsList$: Observable<RiskClaimDTO[]>;
  protected selectCoveragesSchedules$: Observable<RiskScheduleDTO[]>;

  constructor(
    public policySummaryData: PolicySummaryData,
    protected uwrHelpers: UWRHelpers,
    protected store: Store,
    protected coveragesData: CoveragesData,
    protected claimsData: ClaimsData,
    protected endorsementsData: EndorsementsData,
    protected authService: AuthService
  ) {
    super();
    this.selectInterestsList$ = this.store.pipe(select(selectInterestsList));
    this.selectRaterList$ = this.store.pipe(select(selectRaterList));
    this.selectClaimsList$ = this.store.pipe(select(selectClaimsList));
    this.selectCoveragesSchedules$ = this.store.pipe(select(selectCoveragesSchedules));
  }

  initAsyncBaseUWRs(): void {
    this.asyncBaseUWR6();
    this.asyncBaseUWR15();
    this.asyncBaseUWR20();
    this.asyncBaseUWR28();
    this.asyncBaseUWR60();
    this.asyncBaseUWR82();
    this.asyncBaseUWR85();
    this.asyncBaseUWR97();
    this.asyncBaseUWR99();
    this.asyncBaseUWR104();
    this.asyncBaseUWR128();
    this.asyncBaseUWR129();
    this.asyncBaseUWR131();
  }

  asyncBaseUWR6(): void {
    // Involves:
    // Claims > Add Claims > Claim Type

    // Note: See the Lost Type Chargeable and Referral List document to see the claims with Act of God indicator

    this.selectClaimsList$
      .pipe(
        filter((data) => {
          return data !== null;
        }),
        takeUntil(this.stop$)
      )
      .pipe(takeUntil(this.stop$))
      .subscribe((claims) => {

        let status = false;

        _.forEach(claims, (item: RiskClaimDTO) => {
          _.filter(this.claimsData.claimTypes, (claimType) => {
            if (item?.claimType === claimType.code) {
              const day: string = 'D';
              const month: string = 'M';
              const year: string = 'Y';
              const years: number = this.uwrHelpers.getYearDiffFromEffectiveDate(
                Number(moment(item?.lossDate).format(day)),
                Number(moment(item?.lossDate).format(month)),
                Number(moment(item?.lossDate).format(year))
              );
              const claimsLookbackPeriod =
                new Date(this.uwrHelpers.riskEffectiveDate) >= new Date(this.authService.uwr6UpdateEffectiveDate) ? 3 : 5;

              if (this.uwrHelpers.isHO3orDP3orHO4orHO6 && !claimType.actOfGod &&
                item.amount > 0 && years <= claimsLookbackPeriod) {
                status = true;
              }
            }
          });
        });

        this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
          return uwr.id === 6 ? { ...uwr, status: status } : uwr;
        });

        this.uwrHelpers.getAsyncUWRs$.next();
      });
  }

  asyncBaseUWR15(): void {
    //  UWR-15 Condition
    //    Optional Endorsement "Flood Coverage Endorsement" is selected \
    //      and there is a loss with a description/category/type = "flood" from Aplus
    //      and/or they have answered yes to 'Has there even been a flood loss at this location?' question
    //  Form-Type
    //    HO3, HO4, HO6

    this.selectClaimsList$
      .pipe(
        filter((data) => {
          return data !== null;
        }),
        takeUntil(this.stop$)
      )
      .pipe(takeUntil(this.stop$))
      .subscribe((claims) => {
        const floodCoverage = this.coveragesData.floodForm.get('privateFloodCoverage')?.value;
        const floodLossAtThisLocation = this.coveragesData.floodForm.get('floodLossAtThisLocation')?.value;
        const hasFlood = claims.filter((claim) => claim.claimType === 'FLOOD' && claim.isAPlus)?.length > 0;
        const status = this.uwrHelpers.isHO3orHO4orHO6 && ((floodCoverage && hasFlood) || floodLossAtThisLocation);

        this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
          return uwr.id === 15 ? { ...uwr, status: status } : uwr;
        });

        this.uwrHelpers.getAsyncUWRs$.next();
      });
  }

  asyncBaseUWR19(): void { // CNTMAIN-211 Remove UWR-19
    // Involves:
    // Claims > Add Claims > Claim Type

    // Note: See the Lost Type Chargeable and Referral List document

    this.selectClaimsList$
      .pipe(
        filter((data) => {
          return data !== null;
        }),
        takeUntil(this.stop$)
      )
      .pipe(takeUntil(this.stop$))
      .subscribe((claims) => {
        const excludedClaimTypes = ['THIRDLIAB', 'DAMAG', 'DOG', 'LIABILITY', 'SLIP', 'MOLDL'];
        let status = false;

        const filteredClaims = claims.filter((claim) => {
          const day: string = 'D';
          const month: string = 'M';
          const year: string = 'Y';
          const years: number = this.uwrHelpers.getYearDiffFromEffectiveDate(
            Number(moment(claim?.lossDate).format(day)),
            Number(moment(claim?.lossDate).format(month)),
            Number(moment(claim?.lossDate).format(year))
          );

          const isLiability = !excludedClaimTypes.includes(claim.claimType);
          const isWc = claim.claimType === 'WC';

          return (isLiability || isWc) && years <= 5;
        });

        status = this.uwrHelpers.isHO3orDP3orHO4orHO6 && filteredClaims.length !== 0;

        this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
          return uwr.id === 19 ? { ...uwr, status: status } : uwr;
        });

        this.uwrHelpers.getAsyncUWRs$.next();
      });
  }

  asyncBaseUWR20(): void {
    this.selectClaimsList$
      .pipe(
        filter((data) => {
          return data !== null;
        }),
        takeUntil(this.stop$)
      )
      .pipe(takeUntil(this.stop$))
      .subscribe((claims) => {
        let waterLosses = 0;

        claims.filter((claim) => {
          const day: string = 'D';
          const month: string = 'M';
          const year: string = 'Y';
          const years: number = this.uwrHelpers.getYearDiffFromEffectiveDate(
            Number(moment(claim?.lossDate).format(day)),
            Number(moment(claim?.lossDate).format(month)),
            Number(moment(claim?.lossDate).format(year))
          );
          const claimsLookbackPeriod =
            new Date(this.uwrHelpers.riskEffectiveDate) >= new Date(this.authService.uwr20UpdateEffectiveDate) ? 3 : 5;

          if (claim.claimType === 'WATER' && years <= claimsLookbackPeriod) {
            waterLosses++;
          }
        });

        const status = this.uwrHelpers.isHO3orDP3orHO4orHO6 && waterLosses > 2;

        this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
          return uwr.id === 20 ? { ...uwr, status: status } : uwr;
        });

        this.uwrHelpers.getAsyncUWRs$.next();
      });
  }

  asyncBaseUWR28(): void {
    // CS0 = APlus, CS2 = Centauri

    this.selectClaimsList$
      .pipe(
        filter((data) => {
          return data !== null;
        }),
        takeUntil(this.stop$)
      )
      .pipe(takeUntil(this.stop$))
      .subscribe((claims) => {
        const validClaimSource = ['CS0', 'CS2'];
        let waterLossesSum = 0;

        claims.filter((claim) => {
          const day: string = 'D';
          const month: string = 'M';
          const year: string = 'Y';
          const years: number = this.uwrHelpers.getYearDiffFromEffectiveDate(
            Number(moment(claim?.lossDate).format(day)),
            Number(moment(claim?.lossDate).format(month)),
            Number(moment(claim?.lossDate).format(year))
          );

          if (claim.claimType === 'WATER' &&
            validClaimSource.includes(claim.claimSource) && years <= 3) {
            waterLossesSum += claim.amount;
          }
        });

        const status = this.uwrHelpers.isHO3orDP3orHO4orHO6 && waterLossesSum > 10000;

        this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
          return uwr.id === 28 ? { ...uwr, status: status } : uwr;
        });

        this.uwrHelpers.getAsyncUWRs$.next();
      });
  }

  asyncBaseUWR60(): void {
    this.selectClaimsList$
      .pipe(
        filter((data) => {
          return data !== null;
        }),
        takeUntil(this.stop$)
      )
      .pipe(takeUntil(this.stop$))
      .subscribe((claims) => {
        const openClaim: string = 'O';
        const status = this.uwrHelpers.isHO3orDP3orHO6 &&
          claims.filter((claim) => claim.claimStatus === openClaim).length > 0;

        this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
          return uwr.id === 60 ? { ...uwr, status: status } : uwr;
        });

        this.uwrHelpers.getAsyncUWRs$.next();
      });
  }

  asyncBaseUWR82(): void {
    this.selectCoveragesSchedules$
      .pipe(
        filter((data) => {
          return data !== null;
        }),
        takeUntil(this.stop$)
      )
      .pipe(takeUntil(this.stop$))
      .subscribe((schedules) => {
        const addSchedules = this.coveragesData.schedulesForm.get('addSchedules')?.value;
        let status = false;

        if (this.uwrHelpers.isHO3 && addSchedules && schedules?.length > 0) {
          this.coveragesData.scheduleTableNames.map((scheduleName) => {
            const _schedules = schedules.filter(item => {
              return item?.tableId === scheduleName;
            });

            _schedules.map(item => {
              const limit: number = Number(item?.limit);

              if (limit > 5000 && this.coveragesData.schedulesForm.get(scheduleName).value) {
                status = true;
              }
            });
          });
        }

        this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
          return uwr.id === 82 ? { ...uwr, status: status } : uwr;
        });

        this.uwrHelpers.getAsyncUWRs$.next();
      });
  }

  asyncBaseUWR85(): void {
    this.selectInterestsList$
      .pipe(
        filter((data) => {
          return data !== null;
        }),
        takeUntil(this.stop$)
      )
      .pipe(takeUntil(this.stop$))
      .subscribe((interests) => {
        interests = interests.map((interest) => {
          return {
            id: interest.id,
            interestTypeCode: interest.interestTypeCode,
            descOfInterestCode: interest.descOfInterestCode,
          };
        });

        let status = false;

        const result = _.filter(interests, (interest) => {
          return (
            interest?.interestTypeCode.toUpperCase() === 'ADDINS' &&
            (interest?.descOfInterestCode.toUpperCase().includes('DI2') ||
              interest?.descOfInterestCode.toUpperCase().includes('DI3'))
          );
        });

        status = this.uwrHelpers.isHO3orDP3orHO6 && result.length > 0;

        this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
          return uwr.id === 85 ? { ...uwr, status: status } : uwr;
        });

        this.uwrHelpers.getAsyncUWRs$.next();
      });
  }

  asyncBaseUWR97(): void {
    // Involves:
    // Claims > Add Claims > Claim Type

    this.selectClaimsList$
      .pipe(
        filter((data) => {
          return data !== null;
        }),
        takeUntil(this.stop$)
      )
      .pipe(takeUntil(this.stop$))
      .subscribe((claims) => {
        let claimsWithPayout: number = 0;

        claims.filter(claim => {
          const day: string = 'D';
          const month: string = 'M';
          const year: string = 'Y';
          const years: number = this.uwrHelpers.getYearDiffFromEffectiveDate(
            Number(moment(claim?.lossDate).format(day)),
            Number(moment(claim?.lossDate).format(month)),
            Number(moment(claim?.lossDate).format(year))
          );

          const claimsLookbackPeriod =
            new Date(this.uwrHelpers.riskEffectiveDate) >= new Date(this.authService.uwr97UpdateEffectiveDate) ? 3 : 5;

          if (claim.amount > 0 && years <= claimsLookbackPeriod) {
            claimsWithPayout++;
          }
        });

        const status: boolean = this.uwrHelpers.isHO3orDP3orHO4orHO6 && claimsWithPayout >= 2;

        this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
          return uwr.id === 97 ? { ...uwr, status: status } : uwr;
        });

        this.uwrHelpers.getAsyncUWRs$.next();
      });
  }

  asyncBaseUWR99(): void {
    // Involves:
    // Claims > Add Claims > Claim Type
    // Note: See the Lost Type Chargeable and Referral List document to see the claims with Act of God indicator

    // CS0 = APlus, CS2 = Centauri

    this.selectClaimsList$
      .pipe(
        filter((data) => {
          return data !== null;
        }),
        takeUntil(this.stop$)
      )
      .pipe(takeUntil(this.stop$))
      .subscribe((claims) => {
        const validClaimSource = ['CS0', 'CS2'];
        let waterLosses = 0;

        claims.filter((claim) => {
          const day: string = 'D';
          const month: string = 'M';
          const year: string = 'Y';
          const years: number = this.uwrHelpers.getYearDiffFromEffectiveDate(
            Number(moment(claim?.lossDate).format(day)),
            Number(moment(claim?.lossDate).format(month)),
            Number(moment(claim?.lossDate).format(year))
          );

          if (claim.claimType === 'WATER' &&
            years <= 3 &&
            validClaimSource.includes(claim.claimSource) &&
            claim.amount > 0) {
            waterLosses++;
          }
        });

        const status = this.uwrHelpers.isHO3orDP3orHO4orHO6 && waterLosses > 0;

        this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
          return uwr.id === 99 ? { ...uwr, status: status } : uwr;
        });

        this.uwrHelpers.getAsyncUWRs$.next();
      });
  }

  asyncBaseUWR104(): void {
    this.selectClaimsList$
      .pipe(
        filter((data) => {
          return data !== null;
        }),
        takeUntil(this.stop$)
      )
      .pipe(takeUntil(this.stop$))
      .subscribe((claims) => {
        const hasDisputeClaims: RiskClaimDTO[] = claims.filter((claim) => claim.isDispute === true && claim.isExternal === true);
        const status = this.uwrHelpers.isHO3orDP3orHO4orHO6 && hasDisputeClaims.length > 0;

        this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
          return uwr.id === 104 ? { ...uwr, status: status } : uwr;
        });

        this.uwrHelpers.getAsyncUWRs$.next();
      });
  }

  asyncBaseUWR128(): void {
    // Not for Submission
    this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
      return uwr.id === 128 ? { ...uwr, status: false } : uwr;
    });
  }

  asyncBaseUWR129(): void {
    // Not for Submission
    this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
      return uwr.id === 129 ? { ...uwr, status: false } : uwr;
    });
  }

  asyncBaseUWR131(): void {
    this.selectRaterList$
      .pipe(
        filter((value) => {
          return value !== null;
        }),
        takeUntil(this.stop$)
      )
      .pipe(takeUntil(this.stop$))
      .subscribe((raterList) => {
        let status = false;

        const withPrivateFloodCoverage = this.coveragesData.floodForm.get('privateFloodCoverage')?.value;
        const isElevationCertificatePresent = this.coveragesData.floodForm.get('elevationCertificateId').value;
        const floodCovPremium = raterList?.find(x => x.stepAlias === 'FloodCovPremium')?.perilValue;

        status = withPrivateFloodCoverage && isElevationCertificatePresent.toLocaleLowerCase() === 'y' && Math.trunc(Number(floodCovPremium)) === 0 && !this.uwrHelpers.isDP3;

        this.uwrHelpers.asyncUWRs = this.uwrHelpers.asyncUWRs.map((uwr) => {
          return uwr.id === 131 ? { ...uwr, status: status } : uwr;
        });

        this.uwrHelpers.getAsyncUWRs$.next();
      });
  }
}
