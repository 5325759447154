import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmationModalComponent } from '../../../../../app/shared/components/confirmation-modal/confirmation-modal.component';
import { ErrorMessageConstant } from '../../../../../app/shared/constants/error-message.constants';
import { PolicyBillingLabelsConstants } from '../../../../../app/shared/constants/policy-billing.labels.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AddPaymentAccountModalComponent } from './add-payment-account-modal/add-payment-account-modal.component';
import { PolicyBillingData } from '../../data/policy-billing.data';
import { PaymentProfileRequest } from '../../../../shared/models/data/dto/billing/payment-profile-request.dto';
import { PaymentAccountResponse } from '../../../../shared/models/data/dto/billing/payment-account-response.dto';
import { EntityRiskData } from '../../../../../app/modules/submission-management/data/entity-risk.data';
import { BillingLabelConstants } from '../../../../../app/shared/constants/bind-and-issue.labels.constants';
import { UpdateAllowRecurringRequestDto } from '../../../../shared/models/data/dto/billing/update-allow-recurring-request.dto';
import { ThirdPartyDataService } from 'app/core/services/submission/third-party/third-party-data.service';
import { PolicyService } from 'app/core/services/submission/policy.service';
@Component({
  selector: 'app-recurring-payment-enrollment',
  templateUrl: './recurring-payment-enrollment.component.html',
  styleUrls: ['./recurring-payment-enrollment.component.scss']
})
export class RecurringPaymentEnrollmentComponent implements OnInit {
  isOpen: boolean = true;
  public recurringPaymentConstants = PolicyBillingLabelsConstants.recurrentPaymentEnrollment;
  public billingLabelConstants = BillingLabelConstants;
  public policyBillingLabelConstants = PolicyBillingLabelsConstants;

  ErrorMessageConstant = ErrorMessageConstant;
  confirmationModalRef: BsModalRef | null;
  modalRef: BsModalRef | null;

  public paymentAccountsHeaders: string[] = [
    this.recurringPaymentConstants.accountDescription,
    this.recurringPaymentConstants.accountType,
    this.recurringPaymentConstants.accountDefault,
  ];

  constructor(protected fb: FormBuilder,
    protected modalService: BsModalService,
    public billingData: PolicyBillingData,
    protected entityRiskData: EntityRiskData,
    protected thirdPartyDataService: ThirdPartyDataService,
    protected policyService: PolicyService
  ) { }

  ngOnInit() { }

  get isDisabled(): boolean {
    return this.billingData.paymentAccounts.some(val => val.isDefault === true);
  }

  get paymentAccounts(): PaymentAccountResponse[] {
    return this.billingData.paymentAccounts;
  }

  get accountHolderForm(): FormGroup {
    return this.billingData.accountHolderForm;
  }

  get hasAccountHolder(): boolean {
    return this.billingData.hasAccountHolder;
  }

  get isSaveButtonEnabled(): boolean {
    return this.billingData.accountHolderForm.valid;
  }

  onEnrollmentSave() {
  }

  onAddPaymentAccount() {
    const initialState = {
      paymentProfileRequest: this.billingData.setDefaultAccountHolderInfo()
    };

    this.modalRef = this.showRecurringSectionModal(AddPaymentAccountModalComponent, initialState);
  }


  onUpdatePaymentItem(item: PaymentAccountResponse): void {
    if (item?.id) {
      item.isDefault = !item.isDefault;
      this.billingData.updatePaymentAccount(item);
    };
  }

  onDeletePaymentAccount(item: PaymentAccountResponse): void {
    const initialState = {
      message: 'Are you sure you want to delete this payment account?',
      id: item?.id,
      isRecurring: true
    };
    this.confirmationModalRef = this.showRecurringSectionModal(ConfirmationModalComponent, initialState);
    this.confirmationModalRef.content.event.subscribe((result) => {
      if (result.data) {
        this.billingData.deletePaymentAccount(item);
        this.billingData.paymentAccountNotifier.subscribe(response => {
          if (response.isCompleted) {
            this.confirmationModalRef.hide();
          }
        });
      }
    });
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }

  updateAutoPay(): void {
    const payload: UpdateAllowRecurringRequestDto = {
      riskId: this.entityRiskData.getRiskId(),
      isRecurringPayment: this.accountHolderForm.get('agreeEnrollAutoPay').value,
    };
    this.billingData.updateAllowRecurring(payload);
  }

  showRecurringSectionModal(component?, initialState?): BsModalRef {
    return this.modalService.show(component, {
      initialState,
      backdrop: 'static',
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md modal-dialog-centered',
    });
  }
}
