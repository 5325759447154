export const SubAgencyTableConstants = {
    subAgencyName: 'Name',
    subAgencyEmailAddress: 'Email Address',
    subAgencyWorkPhone: 'Work Phone',
    subAgencyContactPerson: 'Contact Person',
    subAgencyStatus: 'Status',
    newBusiness: 'New Business',
    renewalBusiness: 'Renewal Business',
    effectiveDate: 'Effective Date',
    expirationDate: 'Expiration Date',
    status: 'Status',
    type: 'Type',
    city: 'City',
    state: 'State',
    zipCode: 'Zip Code'
  };