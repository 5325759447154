import { Component, forwardRef, Input, Output, ViewChild, EventEmitter, AfterViewChecked, ChangeDetectorRef, ElementRef, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ErrorMessageConstant } from '../../../../shared/constants/error-message.constants';
import { AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel, IMyInputFieldChanged } from 'angular-mydatepicker';
import { PageSections } from '../../../../shared/enum/page-sections.enum';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
  /**
   * Reference: angular-mydatepicker
   * Date Options should be an object
   * Example:
   *  option = {
        dateRange: false,
        dateFormat: 'mm/yyyy' -> your custom date format
      };
   *
   */
export class DatePickerComponent implements ControlValueAccessor, AfterViewChecked, AfterViewInit {
  public ErrorMessageConstant = ErrorMessageConstant;
  @ViewChild('dp') myDp: AngularMyDatePickerDirective;
  @ViewChild('dpElement') element: ElementRef;
  @Input() options: IAngularMyDpOptions;
  @Input() isInvalid: boolean;
  @Output() dateChange = new EventEmitter();
  @Output() inputFieldChanged = new EventEmitter();
  @Input() customErrorMessage: string;
  @Input() customRequiredErrorMessage: string;
  @Input() isDisabled: boolean = false;
  @Input() hasMarginRight: boolean = false;
  @Input() isMMYYYFormat: boolean = false;
  @Input() pageSection: number;
  @Input() hideErrorSection: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() hasApprovalExpirationDateError: boolean = false;
  @Input() isReinstatementDatePicker: boolean = false;
  @Input() hasCustomValidation: boolean = false;
  @Input() customValidationMessage: string;
  @Input() isValidCustomValidation: boolean = true;
  innerValue: any = '';
  private onTouchedCallback: () => void = () => { };
  private onChangeCallback: (_: any) => void = () => { };
  @Input() isDateRangePicker: boolean;
  isInViewPort: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {}
  ngAfterViewChecked() {
    if(this.pageSection !== PageSections.QuickQuoteApplicant &&
      this.pageSection !== PageSections.AddTaskModal) {
      this.myDp.onTouchedCb();
    }

    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    this.checkIfIsInViewPort(this.element.nativeElement);
  }

  get value(): any {
    return this.innerValue;
  }
  set value(newValue: any) {
    if (newValue === this.innerValue) {
      return;
    }
    this.innerValue = newValue;
    this.onChangeCallback(newValue);
  }
  onBlur() {
    this.onTouchedCallback();
  }
  writeValue(val: any): void {
    if (val !== this.innerValue) {
      this.innerValue = val;
    }
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  toggleCalendar(): void {
    this.myDp.toggleCalendar();
  }

  onDateChanged(event: IMyDateModel) {
    if (this.isDateRangePicker) {
      this.dateChange.emit(event);
    } else {
      this.dateChange.emit(event.singleDate);
    }
  }

  onInputFieldChanged(event: IMyInputFieldChanged) {
    this.inputFieldChanged.emit(event);
  }

  checkIfIsInViewPort(element): void {
    const isInViewport = () => {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    this.isInViewPort = isInViewport() ? true : false;
  }
}
