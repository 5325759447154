import { Injectable } from '@angular/core';
import { takeUntil, first } from 'rxjs/operators';
import { CoveragesData } from '../../../modules/submission-management/data/coverages.data';
import { BaseComponent } from '../../../shared/base-component';
import { PathConstants } from '../../../shared/constants/path.constants';
import { ClaimsSavingData } from '../../../modules/submission-management/data/claims-saving.data';
import { InterestSavingData } from '../../../modules/submission-management/data/interest-saving.data';
import { UwApprovalSavingData } from '../../../modules/submission-management/data/uw-approval-saving.data';
import { QuickQuoteSavingData } from '../../../modules/submission-management/data/quick-quote-saving.data';
import { EndorsementsSavingData } from '../../../modules/submission-management/data/endorsements.saving.data';
import { PropertySavingData } from '../../../modules/submission-management/data/property.saving.data';
import { ApplicantSavingData } from '../../../modules/submission-management/data/applicant.saving.data';
import { UwQuestionsSavingData } from '../../../modules/submission-management/data/uwquestions.saving.data';
import { UwApprovalData } from '../../../modules/submission-management/data/uw-approval.data';
import { UWR } from '../../../core/services/submission/uw-referrals/uwr.service';
import { GenericConstants } from '../../../shared/constants/generic.constants';
import { SubmissionPageData } from '../../../modules/submission-management/data/submission-page.data';
import { BindAndIssueSavingData } from '../../../modules/submission-management/data/bind-and-issue-saving.data';
import { SubmissionNavValidationService } from '../../../core/services/navigation/submission-nav-validation.service';
import { HazardYearRangeChangedService } from '../../../core/services/submission/third-party/air-hazard/hazard-year-range-changed.service';
import { PolicySummaryData } from '../../../modules/policy-management/data/policy-summary.data';
import { SummaryData } from '../../../modules/submission-management/data/summary.data';
import { EntityRiskData } from '../../../modules/submission-management/data/entity-risk.data';
import { ThirdPartyData } from '../../../modules/submission-management/data/third-party.data';
import { QuickQuoteData } from '../../../modules/submission-management/data/quick-quote.data';
import Utils from 'app/shared/utilities/utils';
import { EndorsementsData } from '../../../modules/submission-management/data/endorsements.data';
import { ApplicantData } from '../../../modules/submission-management/data/applicant.data';
import { UwQuestionsData } from '../../../modules/submission-management/data/uw-questions.data';
import { FormTypeConstants } from '../../../shared/constants/form-types.constants';
import { PropertyData } from '../../../modules/submission-management/data/property.data';
import { ClaimsData } from 'app/modules/submission-management/data/claims.data';
import { ClaimsChargeableService } from '../submission/claims/claims-chargeable.service';
import { updateAppIsLoadingFromSubmissionNavSavingService } from 'app/store/app/app.actions';
import { Store } from '@ngrx/store';
import { AsyncBaseUWR } from '../submission/uw-referrals/async-base-uwr.service';

@Injectable({
  providedIn: 'root'
})
export class SubmissionNavSavingService extends BaseComponent {
  public genericConstants = GenericConstants;
  isRetiredGreaterOrEqualTo60: boolean;
  formType: string;
  propertyUsage: string;
  formTypeConstants = FormTypeConstants;

  constructor(
    public coveragesData: CoveragesData,
    public claimsSavingData: ClaimsSavingData,
    protected interestSavingData: InterestSavingData,
    protected uwApprovalSavingData: UwApprovalSavingData,
    protected quickQuoteSavingData: QuickQuoteSavingData,
    public endorsementsSavingData: EndorsementsSavingData,
    public propertySavingData: PropertySavingData,
    protected applicantSavingData: ApplicantSavingData,
    protected uwQuestionSavingData: UwQuestionsSavingData,
    protected uwApprovalData: UwApprovalData,
    protected uwr: UWR,
    public submissionData: SubmissionPageData,
    protected bindAndIssueSavingData: BindAndIssueSavingData,
    protected submissionNavValidationService: SubmissionNavValidationService,
    protected hazardYearRangeChangedService: HazardYearRangeChangedService,
    public policySummaryData: PolicySummaryData,
    protected summaryData: SummaryData,
    public entityRiskData: EntityRiskData,
    protected thirdPartyData: ThirdPartyData,
    protected quickQuoteData: QuickQuoteData,
    protected applicantData: ApplicantData,
    public endorsementData: EndorsementsData,
    protected uwQuestionsData: UwQuestionsData,
    protected propertyData: PropertyData,
    protected claimsData: ClaimsData,
    protected claimsChargeableService: ClaimsChargeableService,
    protected store: Store,
    protected asyncBaseUWR: AsyncBaseUWR
  ) {
    super();
  }

  public saveCurrentCategory(currentCategoryRoute: string): void {
    try {
      this.uwr.getUwrReferrals();

      switch (currentCategoryRoute.toLowerCase()) {
        case PathConstants.QuickQuote.QuickQuote.Index:
          this.checkPropertyUpdate();
          this.quickQuoteData.orderCreditSetter();
          if (this.isInAgeOfRetirement() && !this.coveragesData?.isFcraAcknowledgementBeReset) {
            this.updateIsSeniorRetiredValue();
          }
          if (!this.isInAgeOfRetirement() && !this.coveragesData?.isFcraAcknowledgementBeReset) {
            this.resetIsRetiredForNonSeniorApplicant();
          }
          this.submissionData.authService.checkUserBindingAuthority(this.submissionData.authService.userType.value === 'external', this.effectiveDateQQ);
          this.asyncBaseUWR.initAsyncBaseUWRs();
          this.updateUwApprovalTableRows();
          break;
        case PathConstants.Submission.Submission.CoveragesAndDeduction:
          this.checkClaimsChargeability();
          this.saveCoverages();
          this.saveEndorsements(false);
          this.updateUwApprovalTableRows();
          break;
        case PathConstants.Submission.Submission.Endorsements:
          this.coveragesData.isFromCoverages = false;
          this.checkClaimsChargeability();
          this.endorsementsSavingData.saveEndorsements(true);
          this.endorsementsSavingData.isEndorsementSkippedSaving = false;
          this.updateUwApprovalTableRows();
          break;
        case PathConstants.Submission.Submission.Claims:
          if (this.endorsementData.isStarPackageChanged) {
            this.coveragesData.isFromCoverages = false;
            this.endorsementsSavingData.saveEndorsements();
            this.endorsementData.isStarPackageChanged = false;
          }
          this.updateUwApprovalTableRows();
          break;
        case PathConstants.Submission.Submission.Property:
          this.quickQuoteData.setSeniorRetireeValue();

          this.applicantSavingData.saveApplicant();
          this.propertySavingData.saveProperty();
          this.coveragesData.isFromCoverages = false;
          this.saveEndorsementData();
          this.endorsementsSavingData.isEndorsementSkippedSaving = false;
          this.saveUWQuestionsData();
          this.updateUwApprovalTableRows();
          break;
        case PathConstants.Applicant.Applicant.ApplicantPage:
          this.applicantSavingData.saveApplicant();
          this.updateUwApprovalTableRows();
          this.coveragesData.saveCoverages(false, false, false);
          break;
        case PathConstants.Submission.Submission.UWQuestions:
          this.uwQuestionSavingData.saveUWQuestions();
          if (this.uwQuestionsData.isAreDogsClassifiedChange) {
            this.coveragesData.isFromCoverages = false;
            this.endorsementsSavingData.saveEndorsements();
          }
          this.updateUwApprovalTableRows();
          break;
        case PathConstants.Submission.Submission.Interests:
          this.interestSavingData.saveInterest();
          this.interestSavingData.isInterestSkippedSaving = false;
          this.updateUwApprovalTableRows();
          break;
        case PathConstants.Submission.Submission.UWApproval:
          const riskId = this.bindAndIssueSavingData.entityRiskData.getRiskId();
          const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;

          this.updateUwApprovalTableRows();
          // this.bindAndIssueSavingData.postBindNotes(riskId, riskDetailId);
          break;
        case PathConstants.Submission.Submission.BindAndIssue:
          this.bindAndIssueSavingData.saveBindAndIssue();
          break;
        default:
          return;
      }
    } catch (e) { }
  }

  updateUwApprovalTableRows(): void {
    if (this.uwApprovalData.uwApprovalListComplete$.value) {
      this.uwApprovalData.initUWRData();
      this.uwApprovalData.checkPendingItems();
    }
  }

  checkPropertyUpdate(): void {
    this.claimsSavingData.markClaimsForSaving();
    if (this.quickQuoteSavingData.propertyData.propertyDetails.dirty) {
      this.propertySavingData.saveProperty();
      this.quickQuoteSavingData.saveQuickQuote();
    } else {
      this.quickQuoteSavingData.saveQuickQuote();
    }
  }

  saveCoverages(): void {
    this.formType = this.summaryData.SummaryForm.get('formType')?.value;
    this.propertyUsage = this.propertyData.locationDetails.get('propertyUsage')?.value;
    this.coveragesData.isFromCoverages = true;

    if (this.formType === FormTypeConstants.DP3) {
      this.coveragesData.saveCoverages(false, false, false, this.propertyUsage);
      return;
    }
    this.coveragesData.saveCoverages();
  }

  saveEndorsements(saveCoverages: boolean = true): void {
    this.formType = this.summaryData.SummaryForm.get('formType')?.value;
    this.propertyUsage = this.propertyData.locationDetails.get('propertyUsage')?.value;
    this.coveragesData.isFromCoverages = false;
    if (this.formType !== this.formTypeConstants.DP3) {
      this.endorsementsSavingData.saveEndorsements(saveCoverages);
      return;
    }

    this.endorsementsSavingData.saveEndorsements(saveCoverages, this.propertyUsage);
  }

  get validForApplication(): boolean {
    if (this.submissionData.quoteStatus.value !== this.genericConstants.quoteStatus.qexp) {
      if (this.submissionData.quoteStatus.value === this.genericConstants.quoteStatus.quo) {
        return this.submissionData.outsideQuotedTabs &&
          this.submissionNavValidationService.coveragesValidStatus &&
          this.submissionNavValidationService.endorsementsValidStatus;
      }

      if (this.submissionData.quoteStatus.value !== this.genericConstants.quoteStatus.app) {
        return this.submissionData.outsideQuotedTabs &&
          this.submissionNavValidationService.coveragesValidStatus &&
          this.submissionNavValidationService.endorsementsValidStatus &&
          this.submissionNavValidationService.uwApprovalListChangesOnNav;
      }
    }

    return false;
  }

  saveEndorsementData(): void {
    this.endorsementsSavingData.saveEndorsements();
  }

  isInAgeOfRetirement(): boolean {
    const birthDate = new Date(this.applicantData.mainApplicant.get('dateOfBirth').value?.singleDate?.jsDate?.toLocaleDateString('en-US'));
    const age = this.applicantSavingData.applicantBusinessRules.calculateInsuredAge(birthDate);
    const ageOfRetirement = age >= 55;
    this.isRetiredGreaterOrEqualTo60 = this.applicantSavingData.applicantBusinessRules.isInAgeOfRetirementBRULCOV20(age);
    return ageOfRetirement ? true : false;
  }

  resetIsRetiredForNonSeniorApplicant(): void {
    if (this.quickQuoteSavingData.QuickQuoteApplicantForm.get('isRetiredYesNo').value) {
      this.quickQuoteSavingData.QuickQuoteApplicantForm.get('isRetiredYesNo').setValue(false);
      this.applicantSavingData.saveApplicant();
    }
  }

  updateIsSeniorRetiredValue(): void {
    if (this.isRetiredGreaterOrEqualTo60) {
      this.quickQuoteSavingData.QuickQuoteApplicantForm.get('isRetiredYesNo').setValue(true);
    }
    this.applicantSavingData.saveApplicant();
  }

  get effectiveDateQQ(): Date {
    let effectiveDate = new Date(this.entityRiskData.EntityRisk.risks[0].riskDetails.find(a => a.endorsementNumber === 0).effectiveDate);
    let effectiveDateUI = this.summaryData.SummaryForm.get('effectiveDate').value;
    effectiveDateUI = typeof effectiveDateUI === 'object' ? new Date(this.summaryData.SummaryForm.get('effectiveDate').value?.singleDate?.formatted) : new Date(this.summaryData.SummaryForm.get('effectiveDate').value);

    if(effectiveDateUI.toLocaleDateString('en-US') !== effectiveDate.toLocaleDateString('en-US') && !isNaN(effectiveDateUI)){
      effectiveDate = effectiveDateUI;
    }
    return effectiveDate;
  }

  checkClaimsChargeability(): void {
    if (this.claimsChargeableService.checkIfClaimTypeConditionalFieldDirty()
      && this.claimsData.claimList?.length > 0) {
      this.claimsSavingData.markClaimsForSaving();
      this.claimsChargeableService.checkClaimsChargeabilityRule(this.effectiveDateQQ);
      this.claimsSavingData.saveClaims();
    }
  }

  saveUWQuestionsData(): void {
    if (this.formType !== this.formTypeConstants.HO4) {  // BRUL 351
      this.uwQuestionSavingData.saveUWQuestions();
    }
  }
}
