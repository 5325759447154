export interface AppState {
  isLoading: boolean | null;
  isConfirmation: boolean | null;
  ruwIsLoading: boolean | null;
  brulDates: any;
}

export const initialState: AppState = {
  isLoading: null,
  isConfirmation: null,
  ruwIsLoading: null,
  brulDates: null
};