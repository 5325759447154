export const Rater = {
  raterRequest: 'raterRequest',
  raterResult: 'raterResult',
  enableRaterAudit: 'enableRaterAudit',
  totalFilter: 'Total',
  baseRate: 'SBR',
  mgaf: 'MGAF',
  empat : 'EMPATFSur',
  totalPremiumWithAdj: 'TotalPremiumWithAdj',
  fprePlusFee: 'FPrePlusFee',
  totalPremium: 'TotalPremium',
  adjBasePremium: 'AdjBasePremium',
  customFees: 'CustomFees',
    figaAssessment: 'FIGA',
  figa2ndAssessment: 'Figa2ndAssessment',
  additiveFilter: 'ADDITIVE',
  citizenAssessment: 'FairPlanAssess',
  consentToRate: 'ConsentToRate',
  inspectionFee: 'InspectionFee',
  premiumAdj: 'PremiumAdj',
  totByPerilPrem: 'TotByPerilPrem',
  premTaxDisc: 'PremTaxDisc',
  defaultDate: '1/1/2020'
};

export const ComparativeRaterConstants = {
  prefillMessage: 'Please click the Order Prefill button to proceed, also note some values, including Coverage A, may update due to third party returned data.',
  defaultProgramType: 'Preferred'
};

export const VersionControl = {
  version1: 1,
  version2: 2,
  version3: 3
};

export const SchedulesTableName = [
  {
    uiCode: 'personalJewelry',
    raterCode: 'jewelry',
    raterCodeLA: 'jewelry'
  },
  {
    uiCode: 'bicycles',
    raterCode: 'bicycles',
    raterCodeLA: 'bicycles'
  },
  {
    uiCode: 'cameraAndProjectionEquipment',
    raterCode: 'camera',
    raterCodeLA: 'camera'
  },
  {
    uiCode: 'coinCollections',
    raterCode: 'coins',
    raterCodeLA: 'coins'
  },
  {
    uiCode: 'antiques',
    raterCode: 'antiques',
    raterCodeLA: 'antiques'
  },
  {
    uiCode: 'fineArtsAndAntiquesNoBreakage',
    raterCode: 'fine art',
    raterCodeLA: 'fineArt'
  },
  {
    uiCode: 'fineArtsAndAntiquesWithBreakage',
    raterCode: 'fine art w/breakage',
    raterCodeLA: 'fineArtBreakage'
  },
  {
    uiCode: 'furs',
    raterCode: 'furs',
    raterCodeLA: 'furs'
  },
  {
    uiCode: 'golfEquipment',
    raterCode: 'golf equipment',
    raterCodeLA: 'golfersEquipment'
  },
  {
    uiCode: 'gunsCollectible',
    raterCode: 'guns - collecible',
    raterCodeLA: 'gunsCollectible'
  },
  {
    uiCode: 'gunsFired',
    raterCode: 'guns - fired',
    raterCodeLA: 'gunsFired'
  },
  {
    uiCode: 'musicalInstrumentsPersonal',
    raterCode: 'musical instruments',
    raterCodeLA: 'musicalInstruments'
  },
  {
    uiCode: 'miscellaneous',
    raterCode: 'miscellaneous',
    raterCodeLA: 'miscellaneous'
  },
  {
    uiCode: 'otherSportsEquipment',
    raterCode: 'other sports equipment',
    raterCodeLA: 'otherSports'
  },
  {
    uiCode: 'silverware',
    raterCode: 'silverware',
    raterCodeLA: 'silverware'
  },
  {
    uiCode:'stampCollections',
    raterCode: 'stamps',
    raterCodeLA: 'stamps'
  }
];

export const raterDistanceToFireStation = [
  {
    uiCode: 'MS1',
    raterCode: 'G'
  },
  {
    uiCode: 'MS0',
    raterCode: '5'
  }
];

export const raterFeetToHydrant = [
  {
    uiCode: 'FH0',
    raterCode: '1'
  },
  {
    uiCode: 'FH1',
    raterCode: 'G'
  }
];

export const raterElectricalType = [
  {
    uiCode: 'EO0',
    raterCode: 'CB'
  },
  {
    uiCode: 'EO1',
    raterCode: 'AL'
  },
  {
    uiCode: 'EO2',
    raterCode: 'KT'
  },
  {
    uiCode: 'EO3',
    raterCode: 'FS'
  }
];

export const PolicyStatus = {
  NB: 'NB',
  RN: 'RN'
};

export const LA_RaterDefaultValues = {
  HURRICANE_ZONE: 'A',
  INSPECTION_TYPE: 0,
  INSPECTION: false
};

export const RaterSteps = [
  {
    parentStepCode: '8',
    childrenStep: '7',
    form: 'HO'
  }
];

export const RaterSubstepMonetary = {
  stepCodesHO: [ '60A', '60B', '60C', '59A', '59B' ],
  stepCodesDP: [ '42A', '42B', '43A', '43B', '43C' ]
};

export const RaterExemptedLinkConstants = {
  redirectmakeapayment: '/redirectmakeapayment',
  makeapayment: '/makeapayment',
  compRater: '/compRater'
};