import { Component, EventEmitter, OnInit } from '@angular/core';
import { GenericLabel } from 'app/shared/constants/generic.labels.constants';
import { NotesLabelConstants } from 'app/shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { FormBuilder } from '@angular/forms';
import { PolicyNotesData } from '../../data/policy-notes.data';
import { Validators } from '@angular/forms';
import { CustomValidators } from 'app/shared/validators/custom.validator';
import { environment } from 'environments/environment';
import { AuthService } from 'app/core/services/auth.service';

@Component({
  selector: 'app-policy-notes-modal',
  templateUrl: './policy-notes-modal.component.html',
  styleUrls: ['./policy-notes-modal.component.scss']
})
export class PolicyNotesModalComponent implements OnInit {

  public event: EventEmitter<any> = new EventEmitter<any>();
  public ErrorMessageConstant = ErrorMessageConstant;
  public NotesLabelConstants = NotesLabelConstants;
  GenericLabel = GenericLabel;
  notesForm: FormGroup;
  modalRef: BsModalRef | null;
  title: any;
  notesModel: any;
  isAdd: boolean;
  isCancel: boolean = false;
  idForSaving: any;
  maxDescriptionCount: string = '500';

  constructor(protected fb: FormBuilder,
    public bsModalRef: BsModalRef,
    public policyNotesData: PolicyNotesData,
    protected authService: AuthService) { }

  ngOnInit() {
    const currentDate = this.authService.getCustomDate();
    this.notesForm = this.fb.group({
      id: [this.notesModel?.id],
      category: [this.notesModel?.category, [Validators.required]],
      description: [this.notesModel?.description, [Validators.required, CustomValidators.spaceValidator]],
      dateAdded: [this.notesModel?.dateAdded ? this.notesModel?.dateAdded.singleDate ? this.notesModel?.dateAdded
        : this.getFromApiDate(this.notesModel?.dateAdded)
        : {
          singleDate: {
            date: {
              year: currentDate.getFullYear(),
              month: currentDate.getMonth() + 1,
              day: currentDate.getDate()
            },
            formatted: `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`,
            jsDate: currentDate
          }
        }, [Validators.required]]
    });
  }

  getFromApiDate(date?) {
    const new_date = new Date(date);
    return {
      singleDate: {
        date: {
          year: new_date.getFullYear(),
          month: new_date.getMonth() + 1,
          day: new_date.getDate()
        },
        formatted: `${new_date.getMonth() + 1}/${new_date.getDate()}/${new_date.getFullYear()}`,
        jsDate: date
      }
    };
  }

  setDisable() {
    if (this.notesModel?.isEdit) {
      this.notesForm.get('category').disable();
    }
  }

  hideMyModal(): void {
    this.isCancel = true;
    this.bsModalRef.hide();
  }

  onSubmit() {
    if (!this.isCancel) {
      const currentId = typeof this.idForSaving === 'undefined'
        ? this.notesForm.get('id')?.value : this.idForSaving;
      const data = {
        id: currentId,
        category: this.notesForm.get('category')?.value,
        description: this.notesForm.get('description')?.value,
        dateAdded: this.notesForm.get('dateAdded')?.value,
        riskBindId: null,
        notesId: null,
        createdBy: this.notesModel?.isEdit? this.notesModel?.createdBy : localStorage.getItem(environment.UserIdentifierKey)
      };

      this.bsModalRef.hide();

      this.event.emit({ data: data, res: 200 });
    }
  }

}
