import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { LayoutService } from '../../core/services/layout/layout.service';
import { createBookTransferMenuItems } from './book-transfer-navitems';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from 'app/shared/base-class';

@Component({
  selector: 'app-book-transfer',
  templateUrl: './book-transfer.component.html',
  styleUrls: ['./book-transfer.component.scss']
})
export class BookTransferComponent extends BaseClass implements OnInit {

  constructor(
    private layoutService: LayoutService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.updateMenuItems();
    this.subscribeToRouteChange();
  }

  updateMenuItems(): void {
    const isEditMode: boolean = _.split(this.router.url, '/')[2] === 'edit';

    this.layoutService.updateMenu(createBookTransferMenuItems(this.auth.userType.value, isEditMode));
  }

  subscribeToRouteChange(): void {
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .pipe(takeUntil(this.stop$)).subscribe((event: NavigationEnd) => {
        this.updateMenuItems();
      });
  }
}
