import { Injectable } from '@angular/core';
import { UwQuestionsData } from '../../../../modules/submission-management/data/uw-questions.data';
import { Pages } from '../../../../shared/constants/pages-and-sections.constants';
import { GeneralValidationService } from './general-validation.service';

@Injectable({
  providedIn: 'root'
})
export class UwQuestionsValidationService {

  // #region =====> Applicant Page
  eligibilitySection: boolean;
  eligibilitySectionInvalidControls = [];
  generalQuestionSection: boolean;
  generalQuestionSectionInvalidControls = [];

  get EligibilityForm() {
    return this.uwQuestionsData.eligibilityGroup;
  }

  get GeneralQuestionForm() {
    return this.uwQuestionsData.generalQuestionsGroup;
  }

// #endregion
  constructor(protected uwQuestionsData: UwQuestionsData, protected generalValidationService: GeneralValidationService) { }

  checkUWQuestionsPage(): void {
    this.eligibilitySection = this.checkEligibilitySection();
    this.generalQuestionSection = this.checkGeneralQuestionSection();

    this.eligibilitySectionInvalidControls = this.generalValidationService.findInvalidControls(this.EligibilityForm);
    this.generalQuestionSectionInvalidControls = this.generalValidationService.findInvalidControls(this.GeneralQuestionForm);

    this.generalValidationService.mapToInvalidFormControlList(this.eligibilitySectionInvalidControls, Pages.UWQuestions.pageName, Pages.UWQuestions.sections.Eligibility);
    this.generalValidationService.mapToInvalidFormControlList(this.generalQuestionSectionInvalidControls, Pages.UWQuestions.pageName, Pages.UWQuestions.sections.GeneralQuestions);
  }

  checkEligibilitySection(): boolean {
    if (this.EligibilityForm.status === 'DISABLED') {
      return true;
    } else {
      return this.EligibilityForm.valid;
    }
  }

  checkGeneralQuestionSection(): boolean {
    if (this.GeneralQuestionForm.status === 'DISABLED') {
      return true;
    } else {
      return this.GeneralQuestionForm.valid;
    }
  }
}
