export const ProgramStateConstants = {
  programStateAddModalTitle: 'Add Program State',
  programStateEditModalTitle: 'Edit Program State',
  programStateModalButtonLabels: {
    save: 'Save',
    cancel: 'Cancel'
  },
  programStateStatus: {
    active: 'Active',
    inactive: 'Inactive'
  },
  programStateAddedSuccessfulyMessage: 'Program States Saved Successfully.',
  programStateUpdatedSuccessfulyMessage: 'Program State Updated Successfully.',
  programStateDeletedSuccessfulyMessage: 'Program State Deleted Successfully.',
  updateProgramStateConfirmationMessage: 'Are you sure you want to update the Program State status from ',
  deleteProgramStateConfirmationMessage: 'Are you sure you want to delete this Program State?',

  methodName: {
    add: 'saveAddedProgramStates',
    edit: 'updateProgramState',
    delete: 'deleteProgramState'
  }
};