import { Component, OnDestroy, OnInit } from '@angular/core';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { LayoutService } from '../../../../core/services/layout/layout.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { QuoteModalComponent } from '../../components/dashboard/quote-modal/quote-modal.component';
import { AgentDashboardConstants } from '../../../../shared/constants/agent-dashboard.constants';
import { DashBoardService } from '../../../../core/services/dashboard.service';
import { IBanner } from '../../../../shared/models/dashboard.banner.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseClass } from '../../../../shared/base-class';
import { takeUntil, distinctUntilChanged} from 'rxjs/operators';
import { SearchPolicyComponent } from '../../components/dashboard/make-payment/search-policy-component/search-policy.component';
import { AuthService } from '../../../../core/services/auth.service';
import { GenericConstants } from '../../../../shared/constants/generic.constants';
import { TaskData } from '../../data/task.data';
import { Rater } from '../../../../shared/constants/rater.constant';
import { PolicySummaryData } from '../../../policy-management/data/policy-summary.data';
import { ComparativeRaterData } from '../../../comparative-rater/comparative-rater.data';
import { updateAppBrulDatesFromDashboard } from 'app/store/app/app.actions';
import { select, Store } from '@ngrx/store';
import { selectAppBrulDates } from 'app/store/app/app.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseClass implements OnInit, OnDestroy {

  public agentDashboardConstants = AgentDashboardConstants;
  public banners: IBanner[];
  genericConstants = GenericConstants;
  modalRef: BsModalRef | null;
  userType: string;

  private selectAppBrulDates$: Observable<any>;
  private selectAppBrulDates: any | null;

  constructor(
    private layoutService: LayoutService,
    private storage: LocalStorageService,
    private modalService: BsModalService,
    private dashBoardService: DashBoardService,
    private authService: AuthService,
    private router: Router,
    public taskData: TaskData,
    private policySummaryData: PolicySummaryData,
    private comparativeRaterData: ComparativeRaterData,
    private store: Store
  ) {
    super();
    const enableRaterAudit = this.storage.retrieve('enableRaterAudit') ?? false;
    if (!enableRaterAudit) {
      this.storage.store('enableRaterAudit', false);
    }
    this.selectAppBrulDates$ = this.store.pipe(select(selectAppBrulDates));
  }

  ngOnInit() {
    this.subscribeToSelectAppBrulDates$();
    this.checkAndGetServerDate();
    console.log('Test Date Used', this.authService.getCustomDate());
    this.dashBoardService.getBanners().pipe(takeUntil(this.stop$)).subscribe(response => {
        const convertedResponse = response as IBanner[];
        this.banners = convertedResponse;
      });
    this.authService.userType.subscribe((value) => {
      this.userType = value;
      this.taskData.isExternal = this.userType === this.genericConstants.userType.external;
    });
    this.policySummaryData.isPolicy = false;
    this.comparativeRaterData.resetComparativeVariables();
  }

  showModal(): void {
    const initialState = {
      title: this.agentDashboardConstants.startNewQuoteModalTitle,
    };
    this.modalRef = this.modalService.show(QuoteModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  checkAndGetServerDate(): void {
    const UTCServerDate = localStorage.getItem('UTCServerDate');
    const ESTServerDate = localStorage.getItem('ESTServerDate');
    const IPXDate = localStorage.getItem('IPXDate');
    const TriggerInactivityTimer = localStorage.getItem('TriggerInactivityTimer');
    const ActivateInactivityTimer = localStorage.getItem('ActivateInactivityTimer');
    if (UTCServerDate === null || ESTServerDate === null || IPXDate === null
        || TriggerInactivityTimer === null || ActivateInactivityTimer === null) {
      this.authService.getServerDate().subscribe(result => {
        // FormGroup SetValue
        const ipxDate = result?.brulDate?.ipxDate;
        const new110 = result?.brulDate?.new110;
        const uwr26Update = result?.brulDate?.uwR26Update;
        const newRaterVersionDate = result?.brulDate?.newRaterVersionDate;
        const rspsDate_nb = result?.brulDate?.rspsDate_NB;
        const rspsDate_rb = result?.brulDate?.rspsDate_RB;
        const limitedTheftCovChargingStartDate = result?.brulDate?.newLimitedTheftCoverageCharging;
        const triggerInactivityTimer = result?.inactivityTimeout?.triggerInactivityTimer;
        const activateInactivityTimer = result?.inactivityTimeout?.activateInactivityTimer;
        const frreDate_nb = result?.brulDate?.frreDate_NB;
        const frreDate_rb = result?.brulDate?.frreDate_RB;
        this.authService.brul358EffectiveDate = result?.brulDate?.bruL358;
        this.authService.brul247EffectiveDate = result?.brulDate?.bruL247;
        this.authService.uwr121UpdateEffectiveDate = result?.brulDate?.uwR121Update;
        this.authService.uwr116UpdateEffectiveDate = result?.brulDate?.uwR116Update;
        this.authService.uwr87UpdateEffectiveDate = result?.brulDate?.uwR87Update;
        this.authService.privateFloodCoverageRemovalEffectiveDate = result?.brulDate?.privateFloodCoverageRemoval;
        this.authService.uwr6UpdateEffectiveDate = result?.brulDate?.uwR6Update;
        this.authService.uwr20UpdateEffectiveDate = result?.brulDate?.uwR20Update;
        this.authService.uwr97UpdateEffectiveDate = result?.brulDate?.uwR97Update;
        this.authService.uwr116DeactivationEffectiveDate = result?.brulDate?.uwR116Deactivation;
        this.authService.uwr87ActivationEffectiveDate = result?.brulDate?.uwR87Activation;
        this.authService.LoginDateFormGroup.get('UTCServerDate').setValue(result.currentServerDate);
        this.authService.LoginDateFormGroup.get('ESTServerDate').setValue(result.currentServerDateProper);
        this.authService.LoginDateFormGroup.get('IPXDate').setValue(ipxDate);
        this.authService.LoginDateFormGroup.get('New110').setValue(new110);
        this.authService.LoginDateFormGroup.get('UWR26Update').setValue(uwr26Update);
        this.authService.LoginDateFormGroup.get('NewRaterVersionDate').setValue(newRaterVersionDate);
        this.authService.LoginDateFormGroup.get('RSPSDate_NB').setValue(rspsDate_nb);
        this.authService.LoginDateFormGroup.get('RSPSDate_RB').setValue(rspsDate_rb);
        this.authService.LoginDateFormGroup.get('NewLimitedTheftCoverageCharging').setValue(limitedTheftCovChargingStartDate);
        this.authService.LoginDateFormGroup.get('TriggerInactivityTimer').setValue(triggerInactivityTimer);
        this.authService.LoginDateFormGroup.get('ActivateInactivityTimer').setValue(activateInactivityTimer);
        this.authService.LoginDateFormGroup.get('FRREDate_NB').setValue(frreDate_nb);
        this.authService.LoginDateFormGroup.get('FRREDate_RB').setValue(frreDate_rb);

        // LocalStorage Set Value
        localStorage.setItem('UTCServerDate', result.currentServerDate);
        localStorage.setItem('ESTServerDate', result.currentServerDateProper);
        localStorage.setItem('IPXDate', ipxDate);
        localStorage.setItem('UWR26Update', uwr26Update);
        localStorage.setItem('NewRaterVersionDate', newRaterVersionDate);
        localStorage.setItem('RSPSDate_NB', rspsDate_nb);
        localStorage.setItem('RSPSDate_RB', rspsDate_rb);
        localStorage.setItem('NewLimitedTheftCoverageCharging', limitedTheftCovChargingStartDate);
        localStorage.setItem('TriggerInactivityTimer', triggerInactivityTimer);
        localStorage.setItem('ActivateInactivityTimer', activateInactivityTimer);
        localStorage.setItem('FRREDate_NB', frreDate_nb);
        localStorage.setItem('FRREDate_RB', frreDate_rb);

        this.store.dispatch(updateAppBrulDatesFromDashboard({ brulDates: result }));

        this.authService.serverDates.next(this.authService.LoginDateFormGroup);
      });

    }

  }

  onSubmit(value: string): void {
    this.router.navigateByUrl(`submissions/list?q=${value?.toString()?.trim()}`);
  }

  onMakePaymentClick(): void {
    const initialState = {
      title: 'Make A Payment',
    };
    this.modalRef = this.modalService.show(SearchPolicyComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  subscribeToSelectAppBrulDates$(): void {
    this.selectAppBrulDates$.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(brulDates => {
      this.selectAppBrulDates = brulDates;
    });
  }
}
