import { Component, OnInit } from '@angular/core';
import { StatusCounter } from '../../status-counter/status-counter';

@Component({
  selector: 'app-submission-section',
  templateUrl: './submission-section.component.html',
  styleUrls: ['./submission-section.component.scss']
})
export class SubmissionSectionComponent implements OnInit {

  submissionCounterItems: StatusCounter[];

  constructor() { }

  ngOnInit(): void {
    this.submissionCounterItems = [
      { description: 'Quote Pending', icon: 'cil-library', url: '/submissions/list' },
      { description: 'Quote Not Taken', icon: 'cil-pen-alt', url: '/submissions/list?status=underwriting' },
      { description: 'Quote Referral', icon: 'cil-ban', url: '/submissions/list?status=quoted' },
      // { description: "Voided", count: 27, icon: "cil-ban", url: '/submissions/list?status=quoted' },
      // { description: "Info Needed", count: 27, icon: "cil-info", url: '/submissions/list?status=quoted' },
    ];
  }


}
