import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { EntityRiskData } from '../../../../modules/submission-management/data/entity-risk.data';
import { Observable } from 'rxjs';
import { RaterPremiumService } from './rater-premium.service';
import { LocalStorageService } from '../../local-storage.service';

@Injectable()
export class RaterPremiumResolverService implements Resolve<any> {

  constructor(
    protected raterPremiumService: RaterPremiumService,
    public entityRiskData: EntityRiskData,
    protected storage: LocalStorageService
    ) {}

  resolve(): Observable<any> {
    const riskId = this.entityRiskData.getRiskId() ?? this.storage.get('riskId');
    const riskDetailId = this.entityRiskData.getRiskDetailId() ?? this.storage.get('riskDetailId');
    if(riskId !== '' || riskDetailId !== '') {
      return this.raterPremiumService.getRaterTransaction(riskId, riskDetailId);
    }
  }
}
