import { Directive, HostListener } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[modalEventHandler]'
})
export class ModalEventDirective {


    /**
     * Restrict child modal to be displayed when Enter is pressed in parent modal
     *
     * @param event
     */
    @HostListener('keypress', ['$event']) onKeyPress(event) {
        if (event.key.toLowerCase() === 'enter') {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }
    }
}