import { Component, OnInit } from '@angular/core';
import { TaskData } from 'app/modules/dashboard/data/task.data';
import { TaskLabelsConstants } from 'app/shared/constants/task.labels.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TaskNotesModalComponent } from '../task-notes-modal/task-notes-modal.component';
import * as _ from 'lodash';
import { ITaskNotesDTO } from '../../../../../../shared/models/data/dto/task/task.dto';
import NotifUtils from 'app/shared/utilities/notif-utils';

@Component({
  selector: 'app-task-notes-section',
  templateUrl: './task-notes-section.component.html',
  styleUrls: ['./task-notes-section.component.scss']
})
export class TaskNotesSectionComponent implements OnInit {

  public TaskLabelsConstants = TaskLabelsConstants;
  isNotesSectionHidden: boolean = false;
  modalRef: BsModalRef | null;

  constructor(
    protected modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public taskData: TaskData) { }

  ngOnInit() {
    this.setPage(1);
  }

  setPage(page: number) {
    this.taskData.setTaskNotesList(page);
  }

  toggleNotes() {
    this.isNotesSectionHidden = !this.isNotesSectionHidden;
  }

  showNotesModal(): void {
    const initialState = {
      title: this.TaskLabelsConstants.Task.NotesSection.NotesAddModalTitle,
      isAddNotes: true,
      modalData: null,
      currentNoteId: null
    };

    this.modalRef = this.modalService.show(TaskNotesModalComponent, {
      initialState,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  showEditNotesModal(data: ITaskNotesDTO): void {
    const dataId = this.taskData.isAddTask ? data.initialId : data.id;
    const initialState = {
      title: this.TaskLabelsConstants.Task.NotesSection.NotesEditModalTitle,
      isAddNotes: false,
      modalData: data,
      currentNoteId: dataId
    };

    this.modalRef = this.modalService.show(TaskNotesModalComponent, {
      initialState,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  showDeleteNotesConfirmation(data: ITaskNotesDTO): void {
    NotifUtils.showConfirmMessage(TaskLabelsConstants.Task.ConfirmationMessage.deleteNotes, () => {
      // Delete Notes
      this.deleteNotes(data);
    }, () => {

    });
  }

  deleteNotes(data: ITaskNotesDTO): void {
    if (data.id) {
      this.taskData.taskNotes = this.taskData.taskNotes.filter(a => a.id !== data.id);
    } else if (data.initialId) {
      this.taskData.taskNotes = this.taskData.taskNotes.filter(a => a.initialId !== data.initialId);
    }
    this.setPage(1);

    _.forEach(this.taskData.taskNotes, (note) => {
      if (!note.id){
        this.taskData.hasNoteChanges = true;
        return false;
      } else {
        this.taskData.hasNoteChanges = false;
      }
    });
  }

  disableAddButton(): boolean {
    return this.taskData.currentRiskId === null || this.taskData.currentTaskId === null ||
      this.taskData.currentStateCode === null;
  }

}
