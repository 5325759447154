export const LvDocumentsCategory = [
  {
    tableName: 'LvDocumentsCategory ',
    tableDescription: 'Documents Category',
    code: 'BD',
    description: 'Bind Documentation',
    isActive: true
  },
  {
    tableName: 'LvDocumentsCategory',
    tableDescription: 'Documents Category',
    code: 'Email',
    description: 'Email',
    isActive: true
  },
  {
    tableName: 'LvDocumentsCategory ',
    tableDescription: 'Documents Category',
    code: 'MISC',
    description: 'Miscellaneous',
    isActive: true
  },
  {
    tableName: 'LvDocumentsCategory ',
    tableDescription: 'Documents Category',
    code: 'INV',
    description: 'Invoice',
    isActive: true
  },
  {
    tableName: 'LvDocumentsCategory ',
    tableDescription: 'Documents Category',
    code: 'INS',
    description: 'Inspection',
    isActive: true
  },
  {
    tableName: 'LvDocumentsCategory ',
    tableDescription: 'Documents Category',
    code: 'NBDP',
    description: 'New Business Declaration Page',
    isActive: true
  },
  {
    tableName: 'LvDocumentsCategory ',
    tableDescription: 'Documents Category',
    code: 'ADP',
    description: 'Amended Declaration Page',
    isActive: true
  },
  {
    tableName: 'LvDocumentsCategory ',
    tableDescription: 'Documents Category',
    code: 'RW',
    description: 'Renewal',
    isActive: true
  },
  {
    tableName: 'LvDocumentsCategory ',
    tableDescription: 'Documents Category',
    code: 'NTC',
    description: 'Notices',
    isActive: true
  },
  {
    tableName: 'LvDocumentsCategory ',
    tableDescription: 'Documents Category',
    code: 'CRF',
    description: 'Claim Referral',
    isActive: true
  }
];