import { EntitySubmissionListDTO } from "app/shared/models/data/dto/submission-list/entity-submission-list.dto";

export interface RelatedRisks {
  isLoading: boolean | null;
  list: EntitySubmissionListDTO[] | null;
}

export const initialState = {
  isLoading: null,
  list: null
};