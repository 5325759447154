import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-header-details',
  templateUrl: './page-header-details.component.html',
  styleUrls: ['./page-header-details.component.scss']
})
export class PageHeaderDetailsComponent implements OnInit {
  title: string = 'Raul Panelo';
  address: string = '13575 Joshua Way, Sonora, California 95370';
  formType: string = 'HO3';
  agency: string = 'JE Brown Associates';
  estPremium: string = '$8,000';
  quoteNumber: string = 'Q-20004880';
  agencyStatus: string = 'Active';
  coverageA: string = '$3,000';
  creditOrderStatus: string = 'Ordered-Score Received';
  policyStatus: string = 'Pending';


  constructor() { }

  ngOnInit(): void {
  }
}
