import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClickTypes } from '../../../../shared/enum/click-type.enum';
import { SubmissionPageData } from '../../data/submission-page.data';
import { RiskUWQuestionDTO } from '../../../../shared/models/data/dto/quick-quote/risk-uw-question.dto';
import { UwQuestionsData } from '../../data/uw-questions.data';
import NotifUtils from '../../../../shared/utilities/notif-utils';
import { NavigationService } from '../../../../core/services/navigation/navigation.service';
import { UwQuestionsSavingData } from '../../data/uwquestions.saving.data';
import { SubmissionNavValidationService } from '../../../../core/services/navigation/submission-nav-validation.service';
import { UwQuestionsValidationService } from '../../../../core/services/submission/validations/uw-questions-validation.service';
import { PathConstants } from '../../../../shared/constants/path.constants';
import { PageSectionsValidations } from '../../../../shared/enum/page-sections.enum';
import { PolicySummaryData } from '../../../../modules/policy-management/data/policy-summary.data';
import { EntityRiskData } from '../../data/entity-risk.data';
import { UWR } from 'app/core/services/submission/uw-referrals/uwr.service';
import { InterestData } from 'app/modules/submission-management/data/interest.data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { CoveragesData } from 'app/modules/submission-management/data/coverages.data';
import { BaseClass } from 'app/shared/base-class';
import { PageHeaderSummaryConstants } from 'app/shared/constants/page-header-summary.constants';
import { AuthService } from 'app/core/services/auth.service';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import * as moment from 'moment';
import { SummaryData } from '../../data/summary.data';
import { Location } from '@angular/common';

@Component({
  selector: 'app-uw-questions',
  templateUrl: './uw-questions.component.html',
  styleUrls: ['./uw-questions.component.scss'],
})
export class UwQuestionsComponent extends BaseClass implements OnInit, OnDestroy {
  constructor(
    public submissionData: SubmissionPageData,
    public router: Router,
    public route: ActivatedRoute,
    protected uwQuestionData: UwQuestionsData,
    public navigationService: NavigationService,
    protected uwquestionSavingData: UwQuestionsSavingData,
    public formValidation: SubmissionNavValidationService,
    public uwQuestionsValidationService: UwQuestionsValidationService,
    public policySummaryData: PolicySummaryData,
    protected entityRiskData: EntityRiskData,
    protected uwrService: UWR,
    protected interestData: InterestData,
    public coveragesData: CoveragesData,
    protected authService: AuthService,
    protected summaryData: SummaryData,
    protected location: Location
  ) {
    super();
  }

  public riskUWQUestions: RiskUWQuestionDTO[] = [];
  textControl = ['Explain', 'Describe', 'AddPolNumber', 'EstCompleDate', 'PleaseDescibe', 'IsMoreThanOneUnit', 'EverRentLease', 'ForRentLease'];
  valid: string = 'VALID';
  isOpen: boolean = true;
  agentDashboardContants = AgentDashboardConstants;

  ngOnInit() {
    const url = this.route.snapshot['_routerState'].url;
    const urlData = url.split('/');
    sessionStorage.setItem(PageSectionsValidations.CurrentPage, PathConstants.PageDestination.UWQuestions);
    this.subscribeToFormChanges();
  }

  get isRenewalOffered(): boolean {
    return (this.policySummaryData.renewalStatusCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RWO ||
      this.policySummaryData.renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RWO) ||
      (this.policySummaryData.renewalStatusCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO ||
      this.policySummaryData.renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO);
  }

  calculateDiff(sentDate) {
    const date1: any = sentDate;
    const date2: any = this.authService.getCustomDate();
    const diffDays: any = Math.floor((date1 - date2) / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  get disableFormFields(): boolean {
    if (this.policySummaryData.isPolicy) {
      return !(this.policySummaryData.isEditPolicy || this.isRefferedtoUWStatus);
    }
    const riskInfo = this.entityRiskData.EntityRisk?.risks[0];
    const renewalEffectiveDate = new Date(riskInfo?.renewalEffectiveDate);
    const diffDays = this.calculateDiff(renewalEffectiveDate);

    if (this.isRenewalOffered &&
        this.authService.userType.value === GenericConstants.userType.external &&
        diffDays < 2) {
      return true;
    }

    if (this.checkDateImplement() <= 0) {
        if (this.isRenewalSubmission) {
          return true;
        }
        if (this.isQuoteFormDisabled) {
          return true;
        };
    }

    return this.submissionData.disableQuoteForms;
  }

  checkDateImplement(): number {
    const dateImplement = moment(AgentDashboardConstants.defaultEffectiveDate);
    const effecttiveDateInfo = this.summaryData.SummaryForm ? this.summaryData.SummaryForm.get('effectiveDate').value?.singleDate?.formatted :'';
    let dateCreated;
    if (effecttiveDateInfo) {
      dateCreated = this.summaryData.SummaryForm.get('effectiveDate').value?.singleDate?.formatted;
    } else {
      dateCreated = this.entityRiskData.EntityRisk?.risks[0]?.riskDetails[0]?.effectiveDate;
    }

    const dDiff = dateImplement.diff(moment(dateCreated));
    return dDiff;
  }

  get isQuoteFormDisabled(): boolean {
    let result;
    if (this.authService.userType.value === GenericConstants.userType.external) {
      this.submissionData.disableQuoteFormsByUWQuestions.pipe(takeUntil(this.stop$)).subscribe((res) => {
        if (res && this.submissionData.quoteStatus.value === GenericConstants.quoteStatus.ruw ||
          this.submissionData.quoteStatus.value === GenericConstants.quoteStatus.auw ||
          this.submissionData.quoteStatus.value === GenericConstants.quoteStatus.air ||
          this.submissionData.quoteStatus.value === GenericConstants.quoteStatus.duw 
          ) {
          result = res;
        }
      });
    }

    return result;
  }

  get isRenewalSubmission(): boolean {
    const riskInfo = this.entityRiskData.EntityRisk?.risks[0];
    return riskInfo?.renewalCode === AgentDashboardConstants.rwoParam   ||
    riskInfo?.renewalCode === AgentDashboardConstants.rrwoParam  ||
    riskInfo?.renewalCode === AgentDashboardConstants.rnpenParam ||
    riskInfo?.renewalCode === AgentDashboardConstants.rrwParam   ||
    riskInfo?.renewalCode === AgentDashboardConstants.rwntParam  ||
    riskInfo?.renewalCode === AgentDashboardConstants.prrwParam  ||
    riskInfo?.renewalCode === AgentDashboardConstants.rrnpenParam;
  }

  get isRefferedtoUWStatus(): boolean {
    return this.submissionData.policyStatus?.value === this.agentDashboardContants.referredToUW && this.coveragesData.isExternal;
  }

  get isDeclinedUWStatus(): boolean {
    return this.submissionData.policyStatus?.value === this.agentDashboardContants.declineByUW && this.coveragesData.isExternal;
  }

  subscribeToFormChanges(): void {
    const uwQuestionsForms = ['eligibilityGroup', 'generalQuestionsGroup'];

    this.interestData.fetchInterestsComplete.pipe(takeUntil(this.stop$)).subscribe((result) => {
      if (result) {
        uwQuestionsForms.forEach(form => {
          this.uwQuestionData[form].valueChanges.pipe(takeUntil(this.stop$)).subscribe(result => {
            this.uwrService.getUwrReferrals(false);
          });
        });
      }
    });
  }

  collapse() {
    this.isOpen = !this.isOpen;
    this.submissionData.isEligibilityOpen = this.isOpen;
    this.submissionData.isGeneralQuestionOpen = this.isOpen;
  }

  onSubmit(clickType?) {
    if (!this.policySummaryData.isPolicy) {
      switch (clickType) {
        case ClickTypes.Back:
          this.navigationService.navigateRoute(PathConstants.PageDestination.Applicant, true);
          break;
        case ClickTypes.Next:
          this.navigationService.navigateRoute(PathConstants.PageDestination.Interests);
          break;
      }
    } else {
      if (clickType !== ClickTypes.EmailQuote) {
        this.onNextBackPolicy(clickType);
      }
    }
  }

  onNextBackPolicy(clickType): void {
    const page = clickType === ClickTypes.Back ? PathConstants.Policy.Policies.Applicant :
      PathConstants.Policy.Policies.Interests;
    this.navigationService.navigatePolicyRoute(page);
  }

  ngOnDestroy() {
    if (!this.uwquestionSavingData.validateGeneralQuestions() && !this.policySummaryData.isPolicy) {
      this.uwquestionSavingData.checkRequiredField();
      NotifUtils.showError('Check required fields.');
      this.navigationService.navigateRoute('uw-questions');
    } else {
      // TODO : Policy UI edit logic
    }
    this.uwQuestionData.eligibilityGroup.markAllAsTouched();
    this.uwQuestionData.generalQuestionsGroup.markAllAsTouched();
  }
}
