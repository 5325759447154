import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leading-indicator',
  templateUrl: './leading-indicator.component.html',
  styleUrls: ['./leading-indicator.component.scss']
})
export class LeadingIndicatorComponent implements OnInit {
  isHidden: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  toggleArrow(): void {
    this.isHidden = !this.isHidden;
  }

}
