import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'app/core/services/layout/layout.service';

@Component({
  selector: 'app-uw-dashboard',
  templateUrl: './uw-dashboard.component.html',
  styleUrls: ['./uw-dashboard.component.scss']
})
export class UwDashboardComponent implements OnInit {

  constructor(private layoutService: LayoutService) { }

  ngOnInit() {
    this.layoutService.clearMenu();
  }

}
