import { Component, OnInit } from '@angular/core';
import { TaskData } from 'app/modules/dashboard/data/task.data';
import { TaskLabelsConstants } from 'app/shared/constants/task.labels.constants';

@Component({
  selector: 'app-task-history-section',
  templateUrl: './task-history-section.component.html',
  styleUrls: ['./task-history-section.component.scss']
})
export class TaskHistorySectionComponent implements OnInit {

  public taskHistoryLabels = TaskLabelsConstants;
  isHistorySectionHidden: boolean = false;

  constructor(public taskData: TaskData) { }

  ngOnInit() {
    this.setPage(1);
  }

  toggleHistory() {
    this.isHistorySectionHidden = !this.isHistorySectionHidden;
  }

  setPage(page: number) {
    this.taskData.setTaskHistoryList(page);
  }

}
