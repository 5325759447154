import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubmissionPageData } from '../../../../../modules/submission-management/data/submission-page.data';
import { BindAndIssueData } from '../../../../../modules/submission-management/data/bind-and-issue.data';
import { NotesLabelConstants } from '../../../../../shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { IHideTableItems, ITableFormControl, ITableTd, ITableTh, ITableTr } from '../../../../../shared/models/dynamic/table.interface';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NotesModalComponent } from './notes-modal/notes-modal.component';
import { TableNameConstants } from 'app/shared/constants/table.name.constants';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../../../shared/base-class';
import { LvAgencyNotesCategories, LvNotesCategories } from '../../../../../shared/constants/notes.options.constants';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { BindAndIssueSavingData } from 'app/modules/submission-management/data/bind-and-issue-saving.data';
import { SubmissionService } from 'app/core/services/submission/submission.service';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import Utils from 'app/shared/utilities/utils';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { AgencyData } from 'app/modules/management/data/agency.data';
import { PathConstants } from '../../../../../shared/constants/path.constants';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent extends BaseClass implements OnInit{
  public TableNameConstants = TableNameConstants;
  NotesLabelConstants = NotesLabelConstants;
  ErrorMessageConstant = ErrorMessageConstant;
  GenericLabel = GenericLabel;
  isOpen: boolean = true;
  isAgencyNotes: boolean = true;
  agencyId: string;
  modalRef: BsModalRef | null;

  statusList: ('Active' | 'Cancel')[] = ['Active', 'Cancel'];

  hideItems: IHideTableItems = {
    checkboxButton: this.isAgencyNotes,
    fileIcon: true
  };

  constructor(
    public bindAndIssueData: BindAndIssueData,
    protected submissionData: SubmissionPageData,
    protected bindAndIssueSavingData: BindAndIssueSavingData,
    protected modalService: BsModalService,
    public submissionService: SubmissionService,
    public entityRiskData: EntityRiskData,
    public agencyData: AgencyData,
    public router: Router
  ) {
    super();
  }

  ngOnInit() {
    if (this.checkIfAgency()) {
      this.agencyData.getAgencyNotes(this.agencyId);
      this.agencyData.isAgencyNotesCompleted$.pipe(takeUntil(this.stop$)).subscribe(res => {
        this.bindAndIssueData.notesTableRows = [];
        this.bindAndIssueData.initAgencyNotesData(this.agencyData.agencyNotes);
        this.mapNotesList();
        this.setPage(this.agencyData.currentPageNumber);
      });
    } else {
      this.mapNotesList();
      this.bindAndIssueData.notesTableRows = [];
      this.bindAndIssueData.initData(this.bindAndIssueData.notesList);
    }
  }

  checkIfAgency(): boolean {
    const splitUrl = _.split(this.router.url, '/');
    this.isAgencyNotes = splitUrl[2] === PathConstants.Management.Agency.Index;
    this.agencyId = splitUrl[splitUrl.length-1];
    this.bindAndIssueData.agencyId = this.agencyId;
    return this.isAgencyNotes;
  }

  mapNotesList() {
    this.bindAndIssueData.notesTableRows.forEach((item, index) => {
      this.bindAndIssueData.notesList[index].id = item.id;
    });
  }

  editTableItem(rowId: any, tableRow: string): void {
    this.bindAndIssueData[tableRow].forEach((item) => {
      if (item.id === rowId) {
        this.showEditNotes({
          id: item.id,
          category: item.tr[0].value,
          description: item.tr[1].value,
          dateAdded: item.tr[2].value
        });
      } else {
        item.edit = false;
      }
    });
  }

  updateTableItem(row: any): void {
    const notesCategories = this.bindAndIssueData.isFromAgency ? LvAgencyNotesCategories : LvNotesCategories;
    let createdDate: any;

    this.bindAndIssueData.notesTableRows.forEach((item) => {
      if (item.id === row.id) {
        item.tr[0].value = row.category;
        item.tr[1].value = row.description;
        item.tr[2].value = row.dateAdded;
        item.tr[3].value = row.createdByFullName;

        item.tr[0].display = _.find(notesCategories, ['code', row.category]).description || '';
        item.tr[1].display = row.description;
        item.tr[2].display = String(moment(row.dateAdded).format('MM/DD/YYYY'));
        item.tr[3].display = row.createdByFullName;
      }
    });

    this.bindAndIssueData.notesList.forEach((item) => {
      if (item.id === row.id) {
        item.category = row.category;
        item.description = row.description;
        item.dateAdded = row.dateAdded;

        createdDate = item.createdDate;
      }
    });

    if (this.bindAndIssueData.isFromAgency) {
      row.createdDate = createdDate;
      this.agencyData.updateAgencyNote(row);
    } else {
      this.saveNotes();
    }
  }

  deleteTableItem(rowId: any, tableRow: string): void {
    let deleteRow: any;

    this.bindAndIssueData[tableRow].forEach((item, index) => {
      if (item.id === rowId) {
        if (this.bindAndIssueData.isFromAgency) {
          deleteRow = item;
        }
        this.bindAndIssueData[tableRow].splice(index, 1);
        this.bindAndIssueData.notesList.splice(index, 1);
      }
    });

    if (this.bindAndIssueData.isFromAgency) {
      this.agencyData.deleteAgencyNote(deleteRow);
    } else {
      this.saveNotes();
    }
  }

  showAddNotes() {
    const initialState = {
      title: 'Add Notes',
      idForSaving: this.generateId(),
    };
    this.modalRef = this.modalService.show(NotesModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.content.event.pipe(takeUntil(this.stop$)).subscribe((res) => {
      this.bindAndIssueData.addTableItem(res.data);
      this.bindAndIssueData.notesList.push(res.data);
    });
  }

  generateId() {
    const maxId = this.bindAndIssueData.notesTableRows.length > 0
      ? Math.max(...this.bindAndIssueData.notesTableRows.map(note => note.id)) + 1
      : 1;
    return maxId;
  }

  addTableItem(newItem: any): void {
    this.bindAndIssueData.addTableItem(newItem);
    this.bindAndIssueData.notesList.push(newItem);

    if (this.bindAndIssueData.isFromAgency) {
      this.agencyData.saveAgencyNotes(newItem);
    } else {
      this.saveNotes();
    }
  }

  showEditNotes(model): void {
    const data = model;
    const notes = {
      id: data.id,
      description: data.description,
      category: data.category,
      dateAdded: data.dateAdded,
      isEdit: true
    };

    const initialState = {
      title: 'Edit Notes',
      notesModel: notes,
      isAdd: false,
    };

    this.modalRef = this.modalService.show(NotesModalComponent, {
      initialState,
    });

    this.modalRef.content.event.subscribe((res) => {
      this.updateTableItem(res.data);
      this.saveNotes();
    });
  }

  onDelete(model) {
    this.bindAndIssueData.notesList = this.bindAndIssueData.notesList.filter((x) => x.id !== model.id);
    this.saveNotes();
  }

  collapse() {
    this.isOpen = !this.isOpen;
    this.submissionData.isNotesOpen = this.isOpen;
  }

  saveNotes(): void {
    const riskId = this.bindAndIssueSavingData.entityRiskData.getRiskId();
    const riskDetailId = this.bindAndIssueSavingData.entityRiskData.getRiskDetailId();
    this.bindAndIssueSavingData.postBindNotes(riskId, riskDetailId);
  }

  saveAgencyNote(item: any) {
    this.agencyData.saveAgencyNotes(item);
  }

  setPage(page: number): void {
    this.agencyData.setAgencyNotesPage(page);
  }
}
