import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuickQuoteRoutingModule } from './quick-quote-routing.module';
import { QuickQuoteComponent } from './quick-quote.component';
import { ApplicantPageComponent } from './applicant-page/applicant-page.component';
import { SharedModule } from '../../../shared/shared.module';
import { ApplicantInformationComponent } from './applicant-page/applicant-information/applicant-information.component';
import { QuickQuotePropertyComponent } from './applicant-page/quick-quote-property/quick-quote-property.component';
import { QuickQuoteCoverageComponent } from './applicant-page/quick-quote-coverage/quick-quote-coverage.component';
import { NgxMaskModule } from 'ngx-mask';
import { CustomPipesModule } from '../../../shared/custom pipes/custom-pipe.module';

@NgModule({
  declarations: [
    QuickQuoteComponent,
    ApplicantPageComponent,
    ApplicantInformationComponent,
    QuickQuotePropertyComponent,
    QuickQuoteCoverageComponent
  ],
  imports: [
    CommonModule,
    QuickQuoteRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxMaskModule.forRoot(),
    CustomPipesModule
  ],
  entryComponents: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
  ]
})
export class QuickQuoteModule { }
