import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { InterestLabelsConstants } from 'app/shared/constants/interest.labels.constants';
import { InterestModalModel } from 'app/shared/models/submission/interest/interest.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import NotifUtils from '../../../../../shared/utilities/notif-utils';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { ZipData } from 'app/modules/submission-management/data/zip.data';
import { InterestData } from '../../../data/interest.data';
import Utils from '../../../../../shared/utilities/utils';
import { ZipCodeData } from '../../../../../modules/submission-management/data/zipcode.data';
import { PageSections } from '../../../../../shared/enum/page-sections.enum';
import { SubmissionPageData } from '../../../data/submission-page.data';
import FormUtils from '../../../../../shared/utilities/form.utils';
import { LvCountries, LvNameSuffix } from '../../../../../shared/constants/applicant.options.constants';
import { AuthService } from '../../../../../core/services/auth.service';
import { BaseClass } from '../../../../../shared/base-class';
import { TaskService } from '../../../../../core/services/task/task.service';
import { takeUntil } from 'rxjs/operators';
import { GeneralValidationService } from '../../../../../core/services/submission/validations/general-validation.service';

@Component({
  selector: 'app-interest-page-modal',
  templateUrl: './interest-page-modal.component.html',
  styleUrls: ['./interest-page-modal.component.scss'],
})
export class InterestPageModalComponent extends BaseClass implements OnInit, OnDestroy {
  public FormUtils = FormUtils;
  public event: EventEmitter<any> = new EventEmitter<any>();
  public ErrorMessageConstant = ErrorMessageConstant;
  public interestFrm: FormGroup;
  public LabelMessageConstants = InterestLabelsConstants;
  GenericLabel = GenericLabel;
  Countries = LvCountries;

  isCompany: boolean = false;
  interestModel: InterestModalModel;
  idForSaving: any;
  interestType: string;
  isAdd: boolean = true;

  hasRank1Mortgagee: boolean = false;
  allowedMortgageeRanks: string[];
  isPrimaryMortgagee: boolean = false;

  modalRef: BsModalRef | null;
  title: any;
  cities = [
    { name: 'Miami', value: '01' },
    { name: 'Orlando', value: '02' },
    { name: 'Tampa', value: '03' },
    { name: 'Jacksonville', value: '04' },
  ];

  states = [
    { name: 'Florida', value: '01' },
    { name: 'Texas', value: '02' },
    { name: 'Alabama', value: '03' },
    { name: 'Alaska', value: '04' },
  ];

  cityList: any[] = [];
  city: string = '';
  state: string = '';

  interestTypes = [
    { name: 'Mortgagee', value: 'Mortgagee' },
    { name: 'Additional Insured', value: 'Additional Insured' },
    { name: 'Additional Interest', value: 'Additional Interest' },
    { name: 'Alternate Payor', value: 'Alternate Payor' },
  ];

  ranks = [
    { name: '1st Mortgagee', value: '1st Mortgagee' },
    { name: '2nd Mortgagee', value: '1st Mortgagee' },
    { name: '3rd Mortgagee', value: '3rd Mortgagee' },
  ];

  descriptionOfInterestsForInsured = [
    { name: 'Co-Owner/Occupant', value: 'Co-Owner/Occupant' },
    { name: 'Co-Owner/Non-Occupant', value: 'Co-Owner/Non-Occupant' },
    { name: 'Limited Liability Corporation (LLC)', value: 'Limited Liability Corporation (LLC)' },
    { name: 'Family Trust', value: 'Family Trust' },
  ];

  isIndividualYesNo: boolean = false;

  nameSuffixList: any[];

  constructor(
    protected fb: FormBuilder,
    protected modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public zipData: ZipData,
    public interestsData: InterestData,
    public zipCodeData: ZipCodeData,
    public submissionData: SubmissionPageData,
    protected authService: AuthService,
    protected taskService: TaskService,
    public generalValidationService: GeneralValidationService
  ) {
    super();
  }

  ngOnInit() {
    this.initData();
  }

  initData(): void {
    this.initInterestForm();
    this.nameSuffixList = LvNameSuffix;
    this.isCompany = this.interestModel?.isEntityACompany;
    this.watchInterestType();
    this.setInterestCityList();
  }

  get allowedRanks(): any {
    if (this.isAdd) {
      return this.interestsData.ranks.filter(x => this.allowedMortgageeRanks.some(y => y === x.code));
    }
    return this.interestsData.ranks;
  }

  initInterestForm(): void {
    const countryCode = this.interestModel?.countryCode ? this.interestModel?.countryCode : 'USA';

    this.interestFrm = this.fb.group({
      id: [this.interestModel?.id, []],
      interestType: [this.interestModel?.interestType, [Validators.required]],
      interestName: [!this.interestModel?.isIndividual ? this.interestModel?.interestName : '', !this.interestModel?.isIndividual ? [Validators.required] : []],
      interestName2: [this.interestModel?.interestName2],
      interestFirstName: [this.interestModel?.interestFirstName, this.interestModel?.isIndividual ? [Validators.required] : []],
      interestLastName: [this.interestModel?.interestLastName, this.interestModel?.isIndividual ? [Validators.required] : []],
      interestNameSuffix: [this.interestModel?.interestNameSuffix ? this.interestModel?.interestNameSuffix : ''],
      interestFirstName2: [this.interestModel?.interestFirstName2],
      interestLastName2: [this.interestModel?.interestLastName2],
      interestNameSuffix2: [this.interestModel?.interestNameSuffix2 ? this.interestModel?.interestNameSuffix2 : ''],
      mailingAddress: [this.interestModel?.mailingAddress, [Validators.required]],
      mailingAddress2: [this.interestModel?.mailingAddress2],
      zipCode: [this.interestModel?.zipCode, [Validators.required]],
      city: [this.interestModel?.city, [Validators.required]],
      state: [this.interestModel?.countryCode ? this.interestModel?.state : 'FL', [Validators.required]],
      refLoanNumber: [this.interestModel?.refLoanNumber, []],
      rank: [this.interestModel?.rank, [Validators.required]],
      descriptionOfInterest: [this.interestModel?.descriptionOfInterest, [Validators.required]],
      isEntityACompany: [this.interestModel?.isEntityACompany, []],
      isIndividualYesNo: this.interestModel?.isIndividual ?? false,
      createdDate: [this.interestModel?.createdDate, []],
      country: [countryCode]
    });

    this.interestsData.isInterestUSCountry = countryCode === 'USA' ? true : false;
    this.setAddressFieldValidator(this.interestsData.isInterestUSCountry);
  }

  watchInterestType(): void {
    this.interestFrm.get('interestType').valueChanges.subscribe((interestType) => {
      this.setInterestTypeChild(interestType);
    });
  }

  setInterestCityList(): void {
    if (!this.isAdd) {
      this.setZipCode();
      this.setInterestTypeChild(this.interestFrm.get('interestType')?.value);
      return;
    }

    this.zipCodeData.interestCityList = [];
  }

  setInterestTypeChild(interestType: string): void {
    if (interestType == 'AltPyr') {
      this.interestFrm.removeControl('refLoanNumber');
      this.interestFrm.removeControl('rank');
      this.interestFrm.removeControl('descriptionOfInterest');
      return;
    }

    if (interestType == 'Mort') {
      this.interestFrm.removeControl('descriptionOfInterest');
      this.interestFrm.addControl('refLoanNumber', new FormControl(null));
      this.interestFrm.addControl('rank', new FormControl(null, [Validators.required]));
      return;
    }

    this.interestFrm.removeControl('refLoanNumber');
    this.interestFrm.removeControl('rank');
    this.interestFrm.addControl('descriptionOfInterest', new FormControl(null, [Validators.required]));
  }

  saveMyModal(): void {
    if (this.isAdd) {
      if (this.isInterestTypeMortgagee && this.isInvalidRank1Mortgagee()) {
        NotifUtils.showError(this.LabelMessageConstants.multipleRank1MortNotAllowedMessage);
        return;
      }

      this.taskService.generateTaskId().pipe(takeUntil(this.stop$)).subscribe(generatedId => {
        if (generatedId) {
          this.saveInterest(generatedId);
        }
      });
    } else {
      if (this.isInterestTypeMortgagee && !this.isPrimaryMortgagee && this.isInvalidRank1Mortgagee()) {
        NotifUtils.showError(this.LabelMessageConstants.multipleRank1MortNotAllowedMessage);
        return;
      }

      this.saveInterest(this.interestModel.id);
    }
  }

  get isInterestTypeMortgagee(): boolean {
    return this.interestFrm.get('interestType')?.value === 'Mort';
  }

  isInvalidRank1Mortgagee(): boolean {
    return this.hasRank1Mortgagee && this.interestFrm.get('rank')?.value === '1stMort';
  }

  hideMyModal(): void {
    this.bsModalRef.hide();
  }

  ToggleIsCompany(): void {
    this.isCompany = !this.isCompany;
    this.ToggleCompanyValidation();
  }

  ToggleCompanyValidation(): void {
    this.updateValidators();
    this.interestFrm.get('company').updateValueAndValidity();
    this.interestFrm.get('firstName').updateValueAndValidity();
    this.interestFrm.get('middleName').updateValueAndValidity();
    this.interestFrm.get('lastName').updateValueAndValidity();
    this.interestFrm.get('birthDate').updateValueAndValidity();
  }

  updateValidators(): void {
    if (this.isCompany !== false) {
      this.interestFrm.get('company').setValidators(Validators.required);
      this.interestFrm.get('firstName').clearValidators();
      this.interestFrm.get('middleName').clearValidators();
      this.interestFrm.get('lastName').clearValidators();
      this.interestFrm.get('birthDate').clearValidators();
      return;
    }

    this.interestFrm.get('company').clearValidators();
    this.interestFrm.get('firstName').setValidators(Validators.required);
    this.interestFrm.get('middleName').setValidators(Validators.required);
    this.interestFrm.get('lastName').setValidators(Validators.required);
    this.interestFrm.get('birthDate').setValidators(Validators.required);
  }

  setZipCode(): void {
    if (!this.interestsData.isInterestUSCountry) { return; }
    this.interestFrm.get('city').enable();
    const zipCode = this.interestFrm.get('zipCode').value;
    const formControlNames = ['zipCode', 'city', 'state'];

    Utils.blockUI();
    this.checkZipCode(zipCode, formControlNames);
  }

  checkZipCode(zipCode: string, formControlNames: string[]): void {
    if (!zipCode && zipCode === '') {
      this.FormUtils.resetFields(this.interestFrm, formControlNames);
      this.zipCodeData.interestCityList = [];
      Utils.unblockUI();
      return;
    }

    this.zipCodeData.getGenericZipCode(zipCode, this.interestFrm, formControlNames, PageSections.Interest, this.isAdd, ErrorMessageConstant.zipCodeNotFoundErrorMessage);
  }

  onChangeIndividual(value: boolean): void {
    if (!value) {
      this.interestFrm.get('interestName').setValidators([Validators.required]);
      this.interestFrm.get('interestFirstName').clearValidators();
      this.interestFrm.get('interestLastName').clearValidators();
      this.interestFrm.get('interestFirstName').reset();
      this.interestFrm.get('interestLastName').reset();
      this.interestFrm.get('interestFirstName').updateValueAndValidity();
      this.interestFrm.get('interestLastName').updateValueAndValidity();
      this.interestFrm.get('interestNameSuffix').updateValueAndValidity();
      this.interestFrm.get('interestName').updateValueAndValidity();
      return;
    }

    this.interestFrm.get('interestName').clearValidators();
    this.interestFrm.get('interestName').reset();
    this.interestFrm.get('interestFirstName').setValidators([Validators.required]);
    this.interestFrm.get('interestLastName').setValidators([Validators.required]);
    this.interestFrm.get('interestFirstName').updateValueAndValidity();
    this.interestFrm.get('interestLastName').updateValueAndValidity();
    this.interestFrm.get('interestNameSuffix').updateValueAndValidity();
    this.interestFrm.get('interestName').updateValueAndValidity();
  }

  saveInterest(id: string): void {
    const isIndividual = this.interestFrm.get('isIndividualYesNo').value;
    const currentDate = this.authService.getCustomDate(true);
    const existingInterestDetail = this.interestsData.interestList.find(a => a.id === id);
    const data: InterestModalModel = {
      id,
      city: this.interestFrm.get('city')?.value,
      interestName: this?.interestFrm?.get('interestName')?.value && this?.interestFrm?.get('interestName')?.value !== '' && !isIndividual ? this?.interestFrm?.get('interestName')?.value : this?.interestFrm?.get('interestFirstName')?.value,
      interestName2: !isIndividual ? this.interestFrm.get('interestName2')?.value : this.interestFrm.get('interestFirstName2')?.value,
      interestFirstName: this.interestFrm.get('interestFirstName')?.value,
      interestLastName: this.interestFrm.get('interestLastName')?.value,
      interestNameSuffix: !isIndividual ? '' : this.interestFrm.get('interestNameSuffix')?.value === this.LabelMessageConstants.suffix ? '' : this.interestFrm.get('interestNameSuffix')?.value,
      interestFirstName2: this.interestFrm.get('interestFirstName2')?.value,
      interestLastName2: !isIndividual ? '' : this.interestFrm.get('interestLastName2')?.value && this.interestFrm.get('interestLastName2')?.value !== '' ? this.interestFrm.get('interestLastName2')?.value : '',
      interestNameSuffix2: !isIndividual ? '' : this.interestFrm.get('interestNameSuffix2')?.value === this.LabelMessageConstants.suffix ? '' : this.interestFrm.get('interestNameSuffix2')?.value,
      interestType: this.interestFrm.get('interestType')?.value,
      refLoanNumber: this.interestFrm.get('refLoanNumber')?.value,
      state: this.interestFrm.get('state')?.value,
      mailingAddress: this.interestFrm.get('mailingAddress')?.value,
      mailingAddress2: this.interestFrm.get('mailingAddress2')?.value,
      zipCode: this.interestFrm.get('zipCode')?.value,
      descriptionOfInterest: this.interestFrm.get('descriptionOfInterest')
        ?.value,
      rank: this.interestFrm.get('rank')?.value,
      isIndividual: this.interestFrm.get('isIndividualYesNo')?.value,
      createdDate: this.interestFrm.get('createdDate')?.value ? this.interestFrm.get('createdDate')?.value : currentDate,
      isAdd: this.isAdd ? true : existingInterestDetail?.isAdd ?? false,
      isFromParent: existingInterestDetail?.isFromParent ?? false,
      countryCode: this.interestFrm.get('country')?.value ?? 'USA'
    };

    this.bsModalRef.hide();
    this.event.emit({ data, res: 200 });
  }

  onChangeCountry(countryCode: string): void {
    this.zipCodeData.interestCityList = [];
    this.interestsData.isInterestUSCountry = countryCode === 'USA' ? true : false;

    this.interestFrm.get('mailingAddress').reset();
    this.interestFrm.get('mailingAddress2').reset();
    this.interestFrm.get('state').reset();
    this.interestFrm.get('city').reset();
    this.interestFrm.get('zipCode').reset();
    this.interestFrm.get('city').enable();
    this.setAddressFieldValidator(this.interestsData.isInterestUSCountry);
  }

  setAddressFieldValidator(isUSCountry: boolean): void {
    if (isUSCountry) {
      this.generalValidationService.resetValidatorFormControl(this.interestFrm, 'city', Validators.required);
      this.generalValidationService.resetValidatorFormControl(this.interestFrm, 'state', Validators.required);
      this.generalValidationService.resetValidatorFormControl(this.interestFrm, 'zipCode', Validators.required);
    } else {
      this.generalValidationService.clearValidatorFormControl(this.interestFrm, 'city');
      this.generalValidationService.clearValidatorFormControl(this.interestFrm, 'state');
      this.generalValidationService.clearValidatorFormControl(this.interestFrm, 'zipCode');
    }
  }

  ngOnDestroy() {
    this.interestsData.modalSaveCount = 1;
  }
}
