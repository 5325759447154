import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InstallmentInvoiceDTO } from '../../../shared/models/data/dto/billing/installment-invoice.dto';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { BillingSummaryDTO } from '../../../shared/models/data/dto/billing/billing-summary.dto';
import { PaymentRequestDTO } from '../../../shared/models/data/dto/billing/payment-request.dto';
import { PaymentDTO } from '../../../shared/models/data/dto/billing/payment.dto';
import { CommonService } from '../common.service';
import { PaymentViewDTO } from '../../../shared/models/data/dto/billing/payment-view.dto';
import { PaymentDetailsViewDTO } from '../../../shared/models/data/dto/billing/payment-details-view.dto';
import { SuspendedPaymentRequestDTO } from '../../../shared/models/data/dto/billing/suspended-payment-request.dto';
import { SuspendedPaymentResponseDTO } from '../../../shared/models/data/dto/billing/suspended-payment-response.dto';
import { SuspendedPaymentDetailsDTO } from '../../../shared/models/data/dto/suspended-payment/suspended-payment-detail.dto';
import { ChangePayPlanRequestDTO } from '../../../../app/shared/models/data/dto/billing/change-payplan/change-payplan-request.dto';
import { GetChangePayPlanResponseDTO } from '../../../shared/models/data/dto/billing/change-payplan/get-change-payplan-response.dto';
import { PostSuspendedPaymentDTO } from '../../../shared/models/data/dto/suspended-payment/post-suspended-payment.dto';
import { VoidSuspendedPaymentDTO } from '../../../../app/shared/models/data/dto/suspended-payment/void-suspended-payment.dto';
import { UpdateReturnDetailsDTO } from '../../../shared/models/data/dto/billing/update-return-suspended-payment.dto';
import { ReturnSuspendedPaymentDTO } from '../../../shared/models/data/dto/suspended-payment/return-suspended-payment.dto';
import { ReversePaymentDTO } from '../../../../app/shared/models/data/dto/billing/reverse-payment.dto';
import { RefundRequestDTO } from '../../../../app/shared/models/data/dto/suspended-payment/refund.dto';
import { PaymentSuspendedDocDTO } from '../../../../app/shared/models/data/dto/billing/payment-suspended-doc.dto';
import { PaymentRelatedDocDTO } from '../../../../app/shared/models/data/dto/billing/payment-related-doc.dto';
import { ReverseSuspendedPaymentDTO } from '../../../../app/shared/models/data/dto/billing/reverse-suspended-payment.dto';
import { TransactionFeeRequestDTO } from '../../../../app/shared/models/data/dto/billing/transaction-fee-request.dto';
import { VoidTransactionFeeRequestDTO } from '../../../../app/shared/models/data/dto/billing/void-transaction-fee-request.dto';
import { TransactionFeeDTO } from '../../../../app/shared/models/data/dto/billing/transaction-fee.dto';
import { TransferPaymentDTO } from '../../../../app/shared/models/data/dto/billing/transfer-payment.dto';
import { PaymentProfileRequest } from '../../../shared/models/data/dto/billing/payment-profile-request.dto';
import { PaymentProfileResponse } from '../../../shared/models/data/dto/billing/payment-profile-response.dto';
import { PaymentAccountRequest } from '../../../shared/models/data/dto/billing/payment-account-request.dto';
import { PaymentAccountResponse } from '../../../shared/models/data/dto/billing/payment-account-response.dto';
import { CopyPaymentRelatedDocDTO } from '../../../../app/shared/models/data/dto/billing/copy-payment-related-doc.dto';
import { RefundRequestListDTO } from '../../../shared/models/data/dto/refund/refund-request-list.dto';
import { AllowedPaymentRangeDTO } from '../../../../app/shared/models/data/dto/billing/allowed-payment-range.dto';
import { UpdateRefundRequestDTO } from '../../../shared/models/data/dto/refund/save-refund-request.dto';
import { UpdateRiskRequestDTO } from '../../../shared/models/data/dto/billing/update-risk-request.dto';
import { TransactionSummaryDTO } from '../../../../app/shared/models/data/dto/billing/transaction-summary.dto';
import { RefundSetClearDateDTO } from '../../../../app/shared/models/data/dto/billing/refund-set-clear-date.dto';
import { PayToReinstateMetDTO } from '../../../../app/shared/models/data/dto/billing/pay-to-reinstate-met.dto';
import { ChangePayPlanResponseDTO } from '../../../../app/shared/models/data/dto/billing/change-payplan/change-payplan-response.dto';
import { RefundSetEscheatDateDTO } from '../../../../app/shared/models/data/dto/billing/refund-set-escheat-date.dto';
import { PutSuspendedPaymentRequestDTO } from '../../../shared/models/data/dto/suspended-payment/put-suspended-payment-request.dto';
import { RenewalDetailsRequestDTO } from '../../../shared/models/data/dto/billing/renewal-details-request.dto';
import { RenewalDetailsResponseDTO } from '../../../shared/models/data/dto/billing/renewal-details-response.dto';
import { RenewalBillingSummaryRequestDTO, RnBillingSummaryTransactionDetails } from 'app/shared/models/data/dto/billing/renewal-billing-summary.request.dto';
import { SavePaymentProfileRequestDTO } from 'app/shared/models/data/dto/billing/save-payment-profile.request.dto';
import { RenewalBillingSummaryResponseDTO } from 'app/shared/models/data/dto/billing/renewal-billing-summary.response.dto';
import { SavePaymentProfileResponseDTO } from 'app/shared/models/data/dto/billing/save-payment-profile.response.dto';
import { UploadCheckImageResponsetDTO } from '../../../shared/models/data/dto/billing/upload-check-image-response.dto';
import { ApproveReinstatementDTO } from '../../../shared/models/data/dto/billing/approve-reinstatement.dto';
import { UploadIpxResponseDTO } from '../../../shared/models/data/dto/billing/upload-ipx-response.dto';
import { ImportIpxToTableRequestDTO } from '../../../shared/models/data/dto/billing/import-ipx-to-table-request.dto';
import { ImportIpxToTableResponseDTO } from '../../../shared/models/data/dto/billing/import-ipx-to-table-response.dto';
import { IpxSuspenseReportDTO } from '../../../shared/models/data/dto/billing/ipx-suspense-report.dto';
import { IpxSuspenseReportResponseDTO } from '../../../shared/models/data/dto/billing/ipx-suspense-report-response.dto';
import { GenerateIpxReportRequestDTO } from '../../../shared/models/data/dto/billing/generate-ipx-report-request.dto';
import { PutForIPXRequestDTO } from '../../../shared/models/data/dto/suspended-payment/put-for-ipx-request.dto';
import { RemoveManualReturnRequestDTO } from '../../../../app/shared/models/data/dto/billing/remove-manual-return.dto';
import { ReinstatementApprovalViewDTO } from '../../../shared/models/data/dto/billing/reinstatement-approval-view.dto';
import { PostAutoReinstatementDTO } from '../../../shared/models/data/dto/billing/post-auto-reinstatement.dto';
import { OtherApprovalRequirementsDTO } from '../../../shared/models/data/dto/billing/other-approval-requirements.dto';
import { RenewalReinstatementApprovalDetailsRequestDTO } from '../../../shared/models/data/dto/billing/renewal-reinstatement-approval-details-request.dto';
import { RiskDTO2 } from '../../../shared/models/data/dto/quick-quote/risk-dto2';
import { UpdateRefundDetailsRequestDto } from '../../../shared/models/data/dto/refund/edit-refund-request.dto';
import { RefundRequestListRequestDTO } from '../../../shared/models/data/dto/refund/refund-request-list-request.dto';
import { RefundRequestListResponseDTO } from '../../../shared/models/data/dto/refund/refund-request-list-response.dto';
import { CarryOverSourceDocsSuspendedPaymentDTO } from '../../../shared/models/data/dto/suspended-payment/carry-over-source-docs-suspended-payment.dto';
import { NoticeHistoryDTO } from '../../../shared/models/data/dto/billing/notice-history.dto';
import { TransferPaymentToSuspenseRequestDTO } from '../../../shared/models/data/dto/billing/transfer-payment-to-suspense-request.dto';
import { BatchPaymentDetailsDTO } from '../../../shared/models/data/dto/batch-payments/batch-payment-details.dto';
import { UpdateBatchPaymentDetailIsActiveDTO } from '../../../shared/models/data/dto/batch-payments/update-batch-payment-detail-isactive.dto';
import { PaymentBatchDocDTO } from '../../../shared/models/data/dto/billing/payment-batch-doc.dto';
import { SaveControlTotalRequestDTO } from '../../../shared/models/data/dto/batch-payments/save-control-total-request.dto';
import { UpdateAllowRecurringRequestDto } from '../../../shared/models/data/dto/billing/update-allow-recurring-request.dto';
import { RiskBillingInvoiceDocDTO } from '../../../shared/models/data/dto/billing/risk-billing-invoice-doc.dto';
import { UpdateIsPremCoDto } from 'app/shared/models/data/dto/billing/update-ispremco.dto';
import { DecryptParameterDTO } from 'app/shared/models/data/dto/billing/decrypt-parameter.dto';
import { EncryptParameterDTO } from 'app/shared/models/data/dto/billing/encrypt-parameter.dto';
import { UndoPaymentNsfRequestDTO } from 'app/shared/models/data/dto/billing/undo-payment-nsf-request.dto';
import { UndoPaymentNsfProcessResponseDTO } from 'app/shared/models/data/dto/billing/undo-payment-nsf-response.dto';

import { RecalculateReinstatementAmountRequestDTO } from 'app/shared/models/data/dto/billing/recalculate-reinstatement-amount-request.dto';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(protected http: HttpClient, protected commonService: CommonService) { }

  getSummary(riskId: string): Observable<BillingSummaryDTO> {
    return this.http.get<BillingSummaryDTO>(`${environment.BillingServiceUrl}/api/Billing/summary/${riskId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  getInstallmentInvoice(riskId: string): Observable<InstallmentInvoiceDTO[]> {
    return this.http.get<InstallmentInvoiceDTO[]>(`${environment.BillingServiceUrl}/api/Billing/installment-and-invoice/${riskId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  postPaymentRequest(paymentRequest: PaymentRequestDTO): Observable<PaymentDTO> {
    paymentRequest.appId = environment.BillingAppId;

    return this.http.post<PaymentDTO>(`${environment.ApiUrl}/Billing/PostBasicPayment`, paymentRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  payToReinstate(paymentRequest: PaymentRequestDTO): Observable<any> {
    paymentRequest.appId = environment.BillingAppId;

    return this.http.post(`${environment.ApiUrl}/Billing/PayToReinstate`, paymentRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  payBalance(paymentRequest: PaymentRequestDTO): Observable<PaymentDTO> {
    paymentRequest.appId = environment.BillingAppId;

    return this.http.post(`${environment.ApiUrl}/Billing/PayBalance`, paymentRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  getPaymentByRiskId(riskId: string): Observable<PaymentViewDTO[]> {
    return this.http.get<PaymentViewDTO[]>(`${environment.BillingServiceUrl}/api/Payment/GetAllView/${riskId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  getPaymentDetails(riskId: string, paymentId: string): Observable<PaymentDetailsViewDTO> {
    return this.http.get<PaymentDetailsViewDTO>(`${environment.BillingServiceUrl}/api/Payment/details/${riskId}/${paymentId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  postCreateSuspendedPayment(suspendedPaymentRequest: SuspendedPaymentRequestDTO): Observable<SuspendedPaymentResponseDTO> {
    return this.http.post<PaymentDTO>(`${environment.BillingServiceUrl}/api/SuspendedPayment/CreateSuspendedPayment`, suspendedPaymentRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  postCreateBatchSuspendedPayment(suspendedPaymentRequest: SuspendedPaymentRequestDTO[]): Observable<SuspendedPaymentResponseDTO[]> {
    return this.http.post<PaymentDTO>(`${environment.BillingServiceUrl}/api/SuspendedPayment/CreateBatchSuspendedPayment`, suspendedPaymentRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  getSuspendedPaymentDetail(suspendedPaymentId: string): Observable<SuspendedPaymentDetailsDTO> {
    return this.http.get<PaymentDetailsViewDTO>(`${environment.BillingServiceUrl}/api/SuspendedPayment/GetDetailView/${suspendedPaymentId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  getAllSuspendedPayments(statusId: string): Observable<SuspendedPaymentDetailsDTO> {
    return this.http.get<SuspendedPaymentDetailsDTO>(`${environment.BillingServiceUrl}/api/SuspendedPayment/GetAllView/${statusId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  getChangePayplan(riskId: string): Observable<GetChangePayPlanResponseDTO> {
    return this.http.get<GetChangePayPlanResponseDTO>(`${environment.BillingServiceUrl}/api/billing/change-payment-plan/${riskId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  postChangePayplan(payplanRequest: ChangePayPlanRequestDTO): Observable<ChangePayPlanResponseDTO> {
    payplanRequest.appId = environment.BillingAppId;

    return this.http.post<any>(`${environment.ApiUrl}/Billing/change-payment-plan/`, payplanRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  postSuspendedPayment(postRequest: PostSuspendedPaymentDTO): Observable<any> {
    postRequest.appId = environment.BillingAppId;

    return this.http.post<any>(`${environment.ApiUrl}/Billing/SuspendedPayment/PostSuspendedPayment`, postRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  putSuspendedPayment(putRequest?: PutSuspendedPaymentRequestDTO): Observable<any> {
    return this.http.put(`${environment.BillingServiceUrl}/api/SuspendedPayment`, putRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  voidSuspendedPayment(postRequest: VoidSuspendedPaymentDTO): Observable<any> {
    return this.http.post<any>(`${environment.BillingServiceUrl}/api/SuspendedPayment/VoidSuspendedPayment`, postRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  getAllReturnedSuspendedPayments(): Observable<SuspendedPaymentDetailsDTO> {
    return this.http.get<SuspendedPaymentDetailsDTO>(`${environment.BillingServiceUrl}/api/SuspendedPayment/GetReturnedView`)
      .catch(this.commonService.handleObservableHttpError);
  }

  putReturnDetail(putRequest: UpdateReturnDetailsDTO): Observable<SuspendedPaymentResponseDTO> {
    return this.http.put<any>(`${environment.BillingServiceUrl}/api/SuspendedPayment/UpdateReturnDetail`, putRequest);
  }

  returnSuspendedPayment(req: ReturnSuspendedPaymentDTO): Observable<any> {
    return this.http.post<any>(`${environment.BillingServiceUrl}/api/SuspendedPayment/ReturnSuspendedPayment`, req)
      .catch(this.commonService.handleObservableHttpError);
  }

  reversePayment(req: ReversePaymentDTO): Observable<any> {
    return this.http.post<any>(`${environment.ApiUrl}/Billing/ReversePayment`, req)
      .catch(this.commonService.handleObservableHttpError);
  }

  removeManualRefundRequest(req: RemoveManualReturnRequestDTO): Observable<any> {
    return this.http.put<any>(`${environment.BillingServiceUrl}/api/RefundRequest/remove-manual-refund-request`, req)
      .catch(this.commonService.handleObservableHttpError);
  }

  refundPayment(req: RefundRequestDTO): Observable<any> {
    return this.http.post<any>(`${environment.ApiUrl}/Billing/refund`, req)
      .catch(this.commonService.handleObservableHttpError);
  }

  listSuspendedPaymentDoc(suspendedPaymentId: string): Observable<PaymentSuspendedDocDTO[]> {
    return this.http.get(`${environment.ApiUrl}/Billing/paymentSuspendedDoc?suspendedPaymentId=${suspendedPaymentId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  addSuspendedPaymentDoc(docuRequests: PaymentSuspendedDocDTO[]): Observable<string[]> {
    const formData = new FormData();
    docuRequests.forEach((model, index) => {
      Object.keys(model).forEach(key => {
        formData.append(`PaymentSuspendedDocs[${index}].` + key, model[key]);
      });
    });

    return this.http.post(`${environment.ApiUrl}/Billing/paymentsuspendedDoc/list`, formData)
      .catch(this.commonService.handleObservableHttpError);
  }

  deleteSuspendedPaymentDoc(documentId: string): Observable<any> {
    return this.http.delete(`${environment.ApiUrl}/Billing/paymentSuspendedDoc/${documentId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  listPaymentRelatedDoc(paymentId: string): Observable<PaymentRelatedDocDTO[]> {
    return this.http.get(`${environment.ApiUrl}/Billing/paymentRelatedDoc?paymentId=${paymentId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  addPaymentRelatedDoc(model: PaymentRelatedDocDTO): Observable<any> {
    const formData = new FormData();
    Object.keys(model).forEach(key => formData.append(key, model[key]));

    return this.http.post(`${environment.ApiUrl}/Billing/paymentRelatedDoc`, formData)
      .catch(this.commonService.handleObservableHttpError);
  }

  deletePaymentRelatedDoc(documentId: string): Observable<any> {
    return this.http.delete(`${environment.ApiUrl}/Billing/paymentRelatedDoc/${documentId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  reverseSuspendedPayment(req: ReverseSuspendedPaymentDTO): Observable<any> {
    return this.http.post(`${environment.BillingServiceUrl}/api/SuspendedPayment/ReverseSuspendedPayment`, req)
      .catch(this.commonService.handleObservableHttpError);
  }

  getTransactionFees(riskId: string): Observable<TransactionFeeDTO[]> {
    return this.http.get<TransactionFeeDTO[]>(`${environment.BillingServiceUrl}/api/Transaction/fee/${riskId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  addTransactionFee(transactionFeeRequest: TransactionFeeRequestDTO): Observable<any> {
    return this.http.post(`${environment.ApiUrl}/Billing/fee`, transactionFeeRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  voidTransactionFee(voidFeeRequest: VoidTransactionFeeRequestDTO): Observable<any> {
    return this.http.post(`${environment.ApiUrl}/Billing/fee/void`, voidFeeRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  transferPayment(req: TransferPaymentDTO): Observable<any> {
    return this.http.post(`${environment.ApiUrl}/Billing/Payment/transfer`, req)
      .catch(this.commonService.handleObservableHttpError);
  }

  postPaymentProfile(paymentProfileRequest: PaymentProfileRequest): Observable<PaymentProfileResponse> {
    paymentProfileRequest.appId = environment.BillingAppId;
    if (paymentProfileRequest.paymentAccount) {
      paymentProfileRequest.paymentAccount.appId = environment.BillingAppId;
    }

    return this.http.post(`${environment.ApiUrl}/Billing/PaymentProfile/CreateProfile`, paymentProfileRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  putPaymentProfile(paymentProfileRequest: PaymentProfileRequest): Observable<PaymentProfileResponse> {
    paymentProfileRequest.appId = environment.BillingAppId;
    return this.http.put(`${environment.BillingServiceUrl}/api/PaymentProfile/UpdateProfile/`, paymentProfileRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  postPaymentAccount(paymentProfileRequest: PaymentAccountRequest): Observable<PaymentAccountResponse> {
    paymentProfileRequest.appId = environment.BillingAppId;
    return this.http.post(`${environment.BillingServiceUrl}/api/PaymentAccount/CreateAccount`, paymentProfileRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  putPaymentAccount(paymentProfileRequest: PaymentAccountRequest): Observable<PaymentAccountResponse> {
    paymentProfileRequest.appId = environment.BillingAppId;
    return this.http.put(`${environment.BillingServiceUrl}/api/PaymentAccount/DefaultAccount`, paymentProfileRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  deletePaymentAccount(paymentProfileRequest: any): Observable<PaymentAccountResponse> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: paymentProfileRequest
    };
    return this.http.delete(`${environment.BillingServiceUrl}/api/PaymentAccount/DeleteAccount`, options)
      .catch(this.commonService.handleObservableHttpError);
  }

  getPaymentAccounts(riskId: string): Observable<PaymentAccountResponse[]> {
    return this.http.get(`${environment.BillingServiceUrl}/api/PaymentAccount/GetAccount/${riskId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  getRecurringPaymentDetails(riskId: string): Observable<PaymentProfileResponse> {
    return this.http.get(`${environment.BillingServiceUrl}/api/PaymentProfile/GetProfile/${riskId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  copyPaymentRelatedDoc(req: CopyPaymentRelatedDocDTO): Observable<any> {
    return this.http.post(`${environment.ApiUrl}/Billing/CopyRiskBillingPaymentDocument`, req)
      .catch(this.commonService.handleObservableHttpError);
  }

  getInvoiceDocUrl(invoiceId: string): Observable<string> {
    return this.http.get(`${environment.ApiUrl}/Billing/InvoiceDoc?invoiceId=${invoiceId}`, { responseType: 'text' })
      .catch(this.commonService.handleObservableHttpError);
  }

  getInvoicesDocs(invoiceIds: Array<string>): Observable<RiskBillingInvoiceDocDTO[]> {
    return this.http.post(`${environment.ApiUrl}/Billing/InvoicesDocs`, invoiceIds)
      .catch(this.commonService.handleObservableHttpError);
  }

  getAllowedPaymentRange(riskId: string, willSubtractSuspense: boolean = false): Observable<AllowedPaymentRangeDTO> {
    return this.http.get(`${environment.BillingServiceUrl}/api/Payment/allowed-range?riskId=${riskId}&appId=CENT&willSubtractSuspense=${willSubtractSuspense}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  getTransactionSummary(riskId: string): Observable<TransactionSummaryDTO> {
    return this.http.get(`${environment.BillingServiceUrl}/api/Transaction/summary?riskId=${riskId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  refundSetClearDate(req: RefundSetClearDateDTO): Observable<any> {
    return this.http.post<any>(`${environment.BillingServiceUrl}/api/Refund/set-clear-date`, req)
      .catch(this.commonService.handleObservableHttpError);
  }

  // #region ====> REFUND REQUEST
  postRefundRequestList(postRefundRequestListPayload: RefundRequestListRequestDTO ): Observable<RefundRequestListResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/Billing/RefundRequest`, postRefundRequestListPayload)
      .catch(this.commonService.handleObservableHttpError);
  }

  postRefundRequest(refundRequestPayload?: UpdateRefundRequestDTO): Observable<RefundRequestListDTO> {
    return this.http.post(`${environment.BillingServiceUrl}/api/RefundRequest/refundrequest`, refundRequestPayload)
      .catch(this.commonService.handleObservableHttpError);
  }

  putRefundRequest(refundRequestPayload?: UpdateRefundRequestDTO): Observable<any> {
    return this.http.put(`${environment.BillingServiceUrl}/api/RefundRequest`, refundRequestPayload)
      .catch(this.commonService.handleObservableHttpError);
  }

  deleteRefundRequest(refundRequestId?: string): Observable<any> {
    return this.http.delete(`${environment.BillingServiceUrl}/api/RefundRequest/${refundRequestId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  editRefundRequest(refundRequestPayload?: UpdateRefundDetailsRequestDto): Observable<any> {
    return this.http.put(`${environment.BillingServiceUrl}/api/RefundRequest/update-refund-details`, refundRequestPayload)
      .catch(this.commonService.handleObservableHttpError);
  }

  putPolicyOnBind(putRequest: UpdateRiskRequestDTO): Observable<any> {
    return this.http.put(`${environment.BillingServiceUrl}/api/Risk`, putRequest)
      .catch(this.commonService.handleObservableHttpError);
  }
  // #endregion

  getPaymentDueList(currentDate: string): Observable<any> {
    return this.http.get(`${environment.BillingServiceUrl}/api/paymentdue?date=${currentDate}&appId=CENT`)
      .catch(this.commonService.handleObservableHttpError);
  }

  getPaymentPastDueList(currentDate: string): Observable<any> {
    return this.http.get(`${environment.BillingServiceUrl}/api/paymentdue/past-due?date=${currentDate}&appId=CENT`)
      .catch(this.commonService.handleObservableHttpError);
  }

  syncFeesToRater(riskId: string): Observable<any> {
    return this.http.post(`${environment.ApiUrl}/Billing/syncFeesToRater`, [riskId])
      .catch(this.commonService.handleObservableHttpError);
  }

  getIsPayToInstantReinstateMet(riskId: string): Observable<PayToReinstateMetDTO> {
    return this.http.get(`${environment.ApiUrl}/Billing/isPayToInstantReinstateMet/${riskId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  refundSetEscheatDate(req: RefundSetEscheatDateDTO): Observable<any> {
    return this.http.post<any>(`${environment.BillingServiceUrl}/api/Refund/set-escheat-date`, req)
      .catch(this.commonService.handleObservableHttpError);
  }

  deleteProfile(riskId: string): Observable<any> {
    return this.http.delete(`${environment.BillingServiceUrl}/api/PaymentProfile/DeleteProfile/${riskId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  postRenewalDetails(renewalDetailsPayload?: RenewalDetailsRequestDTO): Observable<RenewalDetailsResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/Billing/Payment/renewal-details`, renewalDetailsPayload)
      .catch(this.commonService.handleObservableHttpError);
  }

  postRenewalBillingSummary(renewalBillingSummaryPayload?: RenewalBillingSummaryRequestDTO): Observable<RenewalBillingSummaryResponseDTO> {
    return this.http.post(`${environment.BillingServiceUrl}/api/Renewal/summary`, renewalBillingSummaryPayload)
      .catch(this.commonService.handleObservableHttpError);
  }

  postSavePaymentProfile(savePaymentProfilePayload?: SavePaymentProfileRequestDTO): Observable<SavePaymentProfileResponseDTO> {
    return this.http.post(`${environment.BillingServiceUrl}/api/PaymentProfile/CreateProfile`, savePaymentProfilePayload)
      .catch(this.commonService.handleObservableHttpError);
  }

  addCheckImage(model: PaymentRelatedDocDTO): Observable<UploadCheckImageResponsetDTO> {
    const formData = new FormData();
    Object.keys(model).forEach(key => formData.append(key, model[key]));

    return this.http.post(`${environment.ApiUrl}/Billing/CheckImage`, formData)
      .catch(this.commonService.handleObservableHttpError);
  }

  uploadIpx(file: File): Observable<UploadIpxResponseDTO> {
    const formParams: FormData = new FormData();
    formParams.append('file', file ,file.name);

    return this.http.post(`${environment.ApiUrl}/Billing/SuspendedPayment/UploadIpxFile`,formParams)
    .catch(this.commonService.handleObservableHttpError);
  }

  importIpxtoTable(importIpxPayload: ImportIpxToTableRequestDTO ): Observable<ImportIpxToTableResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/Billing/SuspendedPayment/ImportIpx`, importIpxPayload)
    .catch(this.commonService.handleObservableHttpError);
  }

  getListOfIpxSuspense(isFinal: boolean): Observable<IpxSuspenseReportDTO[]> {
    return this.http.get(`${environment.BillingServiceUrl}/api/SuspendedPayment/ipx/report/${isFinal}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  generateIpxReport(generateIpxReportRequestDTO: GenerateIpxReportRequestDTO): Observable<IpxSuspenseReportResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/Billing/SuspendedPayment/GenerateIpxReport`, generateIpxReportRequestDTO)
      .catch(this.commonService.handleObservableHttpError);;
  }

  getSentToIpxSuspendedPayments(): Observable<SuspendedPaymentDetailsDTO> {
    return this.http.get<SuspendedPaymentDetailsDTO>(`${environment.BillingServiceUrl}/api/suspendedpayment/ipx`)
      .catch(this.commonService.handleObservableHttpError);
  }

  putForIPX(putRequest?: PutForIPXRequestDTO): Observable<any> {
    return this.http.put(`${environment.BillingServiceUrl}/api/suspendedPayment`, putRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  checkFinalReportExists(): Observable<boolean> {
    return this.http.get<SuspendedPaymentDetailsDTO>(`${environment.ApiUrl}/Billing/SuspendedPayment/CheckFinalReportExists`)
      .catch(this.commonService.handleObservableHttpError);
  }

  getBillingAutoReinstatementDetails(riskId: string): Observable<ReinstatementApprovalViewDTO> {
    return this.http.get(`${environment.BillingServiceUrl}/api/Reinstatement/approval/details/${riskId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  postBillingAutoReinstatementDetails(request: PostAutoReinstatementDTO): Observable<ReinstatementApprovalViewDTO> {
    return this.http.post(`${environment.BillingServiceUrl}/api/Reinstatement/approval/details`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  recalculateAmountToReinstate(request: RecalculateReinstatementAmountRequestDTO): Observable<number> {
    return this.http.post(`${environment.BillingServiceUrl}/api/Reinstatement/recalculate-amount-to-reinstate`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  getIsOtherRequirementsMet(riskId: string): Observable<OtherApprovalRequirementsDTO> {
    return this.http.get(`${environment.BillingServiceUrl}/api/Reinstatement/other-requirements-met/${riskId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  getRenewalAutoReinstatementDetails(request: RenewalReinstatementApprovalDetailsRequestDTO): Observable<ReinstatementApprovalViewDTO> {
    return this.http.post(`${environment.ApiUrl}/Billing/AutoReinstatement/GetRenewalReinstatementApprovalDetails`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  postRenewalBillingAutoReinstatementDetails(request: PostAutoReinstatementDTO): Observable<ReinstatementApprovalViewDTO> {
    return this.http.post(`${environment.ApiUrl}/Billing/AutoReinstatement/PostRenewalReinstatementApprovalDetails`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  getRenewalRiskDetails(expiringRiskId: string): Observable<RiskDTO2> {
    return this.http.get(`${environment.ApiUrl}/Risk/renewalRisk/${expiringRiskId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  getNoticeHistoryDetails(riskId: string): Observable<NoticeHistoryDTO> {
    return this.http.get<SuspendedPaymentDetailsDTO>(`${environment.BillingServiceUrl}/api/PolicyCancellation/notice-history/${riskId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  // postRiskEmergencyMoratoriumDetails(request: RiskEmergencyMoratoriumDetailsRequestDTO): Observable<RiskEmergencyMoratoriumDetailsResponseDTO> {
  //   return this.http.post(`${environment.ApiUrl}/Moratorium/risk-emergency-moratorium-details`, request)
  //     .catch(this.commonService.handleObservableHttpError);
  // }

  getIsOtherRenewalRequirementsMet(request: RenewalReinstatementApprovalDetailsRequestDTO): Observable<OtherApprovalRequirementsDTO> {
    return this.http.post(`${environment.ApiUrl}/Billing/renewal-other-requirements-met`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  carryOverSourceDocForSplitPayment(request: CarryOverSourceDocsSuspendedPaymentDTO): Observable<any> {
    return this.http.post(`${environment.ApiUrl}/Billing/SuspendedPayment/carry-over-source-docs-split`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  manualRenwalTransfer(req: TransferPaymentDTO): Observable<any> {
    return this.http.post(`${environment.ApiUrl}/Billing/Payment/manual-renewal-transfer`, req)
      .catch(this.commonService.handleObservableHttpError);
  }

  transferPaymentToSuspense(transferToSuspenseRequest: TransferPaymentToSuspenseRequestDTO): Observable<TransferPaymentDTO> {
    return this.http.post<PaymentDTO>(`${environment.BillingServiceUrl}/api/Payment/transfer-to-suspense`, transferToSuspenseRequest)
      .catch(this.commonService.handleObservableHttpError);
  }

  copySuspendedPaymentDoc(docuRequests: PaymentSuspendedDocDTO[]): Observable<string[]> {
    const formData = new FormData();
    docuRequests.forEach((model, index) => {
      Object.keys(model).forEach(key => {
        formData.append(`PaymentSuspendedDocs[${index}].` + key, model[key]);
      });
    });

    return this.http.post(`${environment.ApiUrl}/Billing/copy-paymentsuspendedDoc/list`, formData)
      .catch(this.commonService.handleObservableHttpError);
  }

//#region  Batch Payments
  getBatchPaymentByIsBatchInProcessed(isBatchInProcessed: boolean): Observable<any> {
    return this.http.get(`${environment.BillingServiceUrl}/api/BatchPayment/GetBatchPaymentByIsBatchInProcessed/${isBatchInProcessed}`)
    .catch(this.commonService.handleObservableHttpError);
  }

  getBatchPaymentDetailsByBatchNumber(batchNumber: string): Observable<BatchPaymentDetailsDTO[]> {
    return this.http.get(`${environment.BillingServiceUrl}/api/BatchPayment/GetBatchPaymentDetailsByBatchNumber/${batchNumber}`)
    .catch(this.commonService.handleObservableHttpError);
  }

  getBatchPaymentDetailById(id: string): Observable<any> {
    return this.http.get(`${environment.BillingServiceUrl}/api/BatchPayment/GetBatchPaymentDetailById/${id}`)
    .catch(this.commonService.handleObservableHttpError);
  }


  postBatchPayment(request: BatchPaymentDetailsDTO[]): Observable<BatchPaymentDetailsDTO[]> {
    return this.http.post(`${environment.BillingServiceUrl}/api/BatchPayment/SaveBatchPayment`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  putUpdatePatchPaymentDetail(request: BatchPaymentDetailsDTO): Observable<BatchPaymentDetailsDTO> {
    return this.http.put(`${environment.BillingServiceUrl}/api/BatchPayment/UpdateBatchPaymentDetail`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  putUpdateIsActive(request: UpdateBatchPaymentDetailIsActiveDTO): Observable<BatchPaymentDetailsDTO> {
    return this.http.put(`${environment.BillingServiceUrl}/api/BatchPayment/UpdateIsActive`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  uploadBatchPayment(file: File): Observable<UploadIpxResponseDTO> {
    const formParams: FormData = new FormData();
    formParams.append('file', file ,file.name);

    return this.http.post(`${environment.ApiUrl}/Billing/BatchPayment/UploadBatchPaymentFile`,formParams)
    .catch(this.commonService.handleObservableHttpError);
  }

  manualPostBatchPayment(request: BatchPaymentDetailsDTO[]): Observable<BatchPaymentDetailsDTO> {
    return this.http.post(`${environment.ApiUrl}/Billing/BatchPayment/ManualPostBatchPayments`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  listBatchPaymentDoc(batchPaymentDetailId: string): Observable<PaymentBatchDocDTO[]> {
    return this.http.get(`${environment.ApiUrl}/Billing/GetPaymentBatchDocsByBatchPaymentDetailIdAsync?batchPaymentDetailId=${batchPaymentDetailId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  addBatchPaymentDoc(docuRequests: PaymentBatchDocDTO[]): Observable<string[]> {
    const formData = new FormData();
    docuRequests.forEach((model, index) => {
      Object.keys(model).forEach(key => {
        formData.append(`PaymentBatchDocs[${index}].` + key, model[key]);
      });
    });

    return this.http.post(`${environment.ApiUrl}/Billing/AddPaymentBatchDocListAsync/list`, formData)
      .catch(this.commonService.handleObservableHttpError);
  }

  deleteBatchPaymentDoc(documentId: string): Observable<any> {
    return this.http.delete(`${environment.ApiUrl}/Billing/DeleteBatchPaymentDoc/${documentId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  postControlTotal(request: SaveControlTotalRequestDTO): Observable<SavePaymentProfileResponseDTO> {
    return this.http.post(`${environment.BillingServiceUrl}/api/BatchPayment/SaveControlTotal`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  listSuspendedPaymentDocByBatchPaymentId(batchPaymentDetailId: string): Observable<PaymentBatchDocDTO[]> {
    return this.http.get(`${environment.ApiUrl}/Billing/GetSuspendedDocsByBatchPaymentDetailIdAsync?batchPaymentDetailId=${batchPaymentDetailId}`)
      .catch(this.commonService.handleObservableHttpError);
  }
  //#endregion

  putAllowRecurring(request: UpdateAllowRecurringRequestDto): Observable<any> {
    request.appId = environment.BillingAppId;
    return this.http.put(`${environment.BillingServiceUrl}/api/PaymentProfile/UpdateAllowRecurring`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  putUpdateIsPremco(request: UpdateIsPremCoDto): Observable<UpdateIsPremCoDto> {
    return this.http.put(`${environment.BillingServiceUrl}/api/Risk/UpdateIsPremCo`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  encryptParameter(request: EncryptParameterDTO): Observable<EncryptParameterDTO> {
    return this.http.post(`${environment.ApiUrl}/Billing/payment-portal-encrypt`, request)
      .catch(this.commonService.handleObservableHttpError);
  }
  
  decryptParameter(request: DecryptParameterDTO): Observable<DecryptParameterDTO> {
    return this.http.post(`${environment.ApiUrl}/Billing/payment-portal-decrypt`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  putUndoPaymentNsf(request: UndoPaymentNsfRequestDTO): Observable<UndoPaymentNsfProcessResponseDTO> {
    return this.http.put(`${environment.ApiUrl}/Billing/NSF/undo`, request)
      .catch(this.commonService.handleObservableHttpError);
  }

  getRenewalTransactionDetails(expiringRiskId: string): Observable<RnBillingSummaryTransactionDetails[]> {
    return this.http.get(`${environment.ApiUrl}/Billing/GetRenewalTransactionDetails/${expiringRiskId}`)
      .catch(this.commonService.handleObservableHttpError);
  }
}
