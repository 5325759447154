
export const TaskLabelsConstants = {

  Task: {
    ConfirmationMessage: {
      deleteDocument: 'Are you sure you want to delete this document?',
      deleteNotes: 'Are you sure you want to delete this note?'
    },
    Edit: {
      EditTaskTitle: 'Edit Task',
    },
    Add: {
      AddTaskTitle: 'Add Task',
      PolicyQuoteHeaderSection: 'QUOTE / POLICY',
      PolicyQuoteNumber: 'Quote / Policy Number',
      PolicyNumber: 'Policy Number',
      QuoteNumber: 'Quote Number',
      InsuredName: 'Insured Name',
      PropertyAddress: 'Property Address',
      Type: 'Type',
      SubType: 'Sub-Type',
      ContactType: 'Contact Type',
      ContactName: 'Contact Name',
      Category: 'Category',
      TaskDescription: 'Task Description',
      Priority: 'Priority',
      OtherDescription: 'Other Description',
      DueDate: 'Due Date',
      Owner: 'Owner',
      Status: 'Status',
      Policy: 'POLICY',
      Quote: 'QUOTE',
      DetailsHeaderSection: 'DETAILS',
      Print: 'Print Summary',
      Save: 'Save',
      Cancel: 'Cancel',
      Search: 'Search By',
    },
    NotesSection: {
      NotesHeaderSection: 'NOTES',
      Actions: 'Actions',
      NotesAddButton: 'Add',
      DateAdded: 'Date Added',
      AddedBy: 'Added By',
      Type: 'Type',
      ContactName: 'Contact Name',
      NoteDescription: 'Note Description',
      Edit: 'Edit',
      Delete: 'Delete',
      NotesModalTitle: 'Add / Edit Note',
      NotesAddModalTitle: 'Add Note',
      NotesEditModalTitle: 'Edit Note',
      NotesModalSaveButton: 'Save',
      NotesModalCancelButton: 'Cancel'
    },
    DocumentsSection: {
      DocumentHeaderSection: 'DOCUMENTS',
      DocumentsUploadButton: 'Upload',
      Actions: 'Actions',
      DateAdded: 'Date Added',
      AddedBy: 'Added By',
      Type: 'Type',
      Status: 'Status',
      DocumentDescription: 'Document Description',
      Edit: 'Edit',
      Delete: 'Delete',
      View: 'View',
      DocumentModalTitle: 'Upload / Update Document',
      DocumentUploadModalTitle: 'Upload Document',
      DocumentUpdateModalTitle: 'Update Document',
      DocumentViewModalTitle: 'View Document',
      DocumentModalBrowseButton: 'Choose File',
      DocumentModalSaveButton: 'Save',
      DocumentModalCancelButton: 'Cancel',
      DocumentModalViewButton: 'View'
    },
    HistorySection: {
      HistoryHeaderSection: 'HISTORY',
      Date: 'Date',
      Action: 'Action',
      By: 'By',
      Previous: 'Previous',
      New: 'New'
    },
    Search: {
      SearchPolicyTitle: 'Search Results',
      PolicyNumber: 'Policy Number',
      QuoteNumber: 'Quote Number',
      InsuredName: 'Insured Name',
      PropertyAddress: 'Property Address',
      SelectButton: 'Select',
      showRowsNumber8: 8,
      showRowsNumber25: 25,
      showRowsNumber50: 50,
    },
    View: {
      ViewTitle: 'View Task'
    }
  },
  TableFields: {
    createdDate: 'dateAddedProper',
    tasksOwner: 'owner',
    tasksCategory: 'category',
    tasksDueDate: 'dueDate',
    tasksTaskStatus: 'taskStatus',
    tasksDescription: 'description',
  },
  Internal: 'Internal',
  External: 'External',
  Group: 'Group',
  ACG: 'Accounting Group',
  CSG: 'Customer Service Group',
  UWG: 'UnderWriting Group',
  PUW: 'Policy Underwriter',
  UAG: 'UW Assistant Group'
};
