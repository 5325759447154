import { Component, Input, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import FormUtils from '../../../../../../../app/shared/utilities/form.utils';
import { ErrorMessageConstant } from '../../../../../../../app/shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../../../app/shared/constants/generic.labels.constants';
import { PolicyBillingLabelsConstants } from '../../../../../../../app/shared/constants/policy-billing.labels.constants';
import { PolicyBillingData } from '../../../../../../../app/modules/policy-management/data/policy-billing.data';
import { LvNameSuffix } from '../../../../../../../app/shared/constants/applicant.options.constants';

@Component({
  selector: 'app-entity-name',
  templateUrl: './entity-name.component.html',
  styleUrls: ['./entity-name.component.scss']
})
export class EntityNameComponent implements OnInit, AfterViewChecked {

  @Input() checkPaymentForm: FormGroup;
  @Input() isRefund: boolean;

  public FormUtils = FormUtils;

  ErrorMessageConstant = ErrorMessageConstant;
  CheckPaymentConstants = PolicyBillingLabelsConstants.checkPayment;
  GenericLabel = GenericLabel;
  FieldNames = this.CheckPaymentConstants.checkPaymentFieldNames;

  public nameSuffixList = LvNameSuffix;

  constructor(public billingData: PolicyBillingData, protected cdr: ChangeDetectorRef) { }


  ngOnInit() {
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  onChangeIndividual(value: boolean): void {
    this.billingData.clearAndSetValidators();
  }

  get nameMaxLengthExceeded(): boolean {
    const form = this.checkPaymentForm;

    this.billingData.checkIfNameExceededMaxLength(form, this.billingData.getNameFieldsForRefund());

    return (form.get(this.FieldNames.firstName).errors?.maxlength ||
            form.get(this.FieldNames.lastName).errors?.maxlength ||
            form.get(this.FieldNames.suffix).errors?.maxlength
          ) && (
            form.get(this.FieldNames.firstName).touched ||
            form.get(this.FieldNames.lastName).touched ||
            form.get(this.FieldNames.suffix).touched
          );
  }

  get nameLength(): number {
    return this.billingData.getNameLength(this.checkPaymentForm.get(this.FieldNames.isIndividual)?.value);
  }
}
