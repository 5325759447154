import { Component, OnInit } from '@angular/core';
import { PolicyBillingLabelsConstants } from '../../../../shared/constants/policy-billing.labels.constants';
import { BaseClass } from '../../../../shared/base-class';
import { PolicyBillingData } from '../../data/policy-billing.data';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FeeModalComponent } from './fee-modal/fee-modal.component';
import { ConfirmationModalComponent } from '../../../../../app/shared/components/confirmation-modal/confirmation-modal.component';
import { TransactionFeeRequestDTO } from '../../../../../app/shared/models/data/dto/billing/transaction-fee-request.dto';
import Utils from '../../../../../app/shared/utilities/utils';
import { BillingService } from '../../../../../app/core/services/billing/billing.service';
import NotifUtils from '../../../../../app/shared/utilities/notif-utils';
import { VoidTransactionFeeRequestDTO } from '../../../../../app/shared/models/data/dto/billing/void-transaction-fee-request.dto';
import { switchMap, takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { EntityRiskData } from '../../../../../app/modules/submission-management/data/entity-risk.data';
import { PolicyDocumentsData } from '../../data/policy-documents.data';
import { PolicyService } from '../../../../../app/core/services/submission/policy.service';

@Component({
  selector: 'app-taxes-fees',
  templateUrl: './taxes-fees.component.html',
  styleUrls: ['./taxes-fees.component.scss']
})
export class TaxesFeesComponent extends BaseClass implements OnInit {
  isOpen: boolean = true;

  public taxesFeesConstants = PolicyBillingLabelsConstants.taxesAndFees;

  feeModalRef: BsModalRef | null;
  confirmationModalRef: BsModalRef | null;

  datePipe: DatePipe;

  constructor(public billingData: PolicyBillingData,
    protected modalService: BsModalService,
    protected billingService: BillingService,
    protected entityRiskData: EntityRiskData,
    protected policyService: PolicyService,
    protected policyDocumentsData: PolicyDocumentsData) {
    super();
  }

  ngOnInit() {
    this.datePipe = new DatePipe('en-US');
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }

  onAddFee(): void {
    const initialState = {
      title: this.taxesFeesConstants.title,
      isAdd: false,
    };

    this.feeModalRef = this.modalService.show(FeeModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-md',
    });
    this.feeModalRef.content.event.subscribe((result) => {
      if (result.data.continue) {
        const request: TransactionFeeRequestDTO = {
          riskId: this.entityRiskData.getRiskId(),
          riskDetailId: this.entityRiskData.getRiskDetailId(),
          lastRiskDetailId: this.entityRiskData.getLastRiskDetailId(),

          amountSubTypeId: result.data.feeType,
          amount: result.data.amount,
          addDate: result.data.addDate
        };

        Utils.blockUI();
        this.billingService.addTransactionFee(request).pipe(
            takeUntil(this.stop$),
            switchMap(() => this.policyService.getPolicyDocuments(this.entityRiskData.getRiskId()))
            ).subscribe(response => {
          Utils.unblockUI();

          this.billingData.populateBillingSections(this.entityRiskData.getRiskId());
          this.billingData.listTransactionFeesByRiskId(this.entityRiskData.getRiskId());
          this.policyDocumentsData.mapPolicyDocuments(response);

          NotifUtils.showSuccess(this.taxesFeesConstants.addFeeSuccess);

          this.feeModalRef.hide();
        },
          err => {
            Utils.unblockUI();
            NotifUtils.showMultiLineError(err.error?.message);
          });
      } else {
        this.feeModalRef.hide();
      }
    });
  }

  onVoidFee(feeId: string): void {
    this.confirmationModalRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: { message: this.taxesFeesConstants.voidFeeConfirmation },
      class: 'modal-sm modal-dialog-centered',
      backdrop: 'static',
      keyboard: false
    });
    this.confirmationModalRef.content.event.subscribe((result) => {
      if (result.data) {
        const voidRequest: VoidTransactionFeeRequestDTO = {
          id: feeId,
          riskId: this.entityRiskData.getRiskId(),
          riskDetailId: this.entityRiskData.getRiskDetailId(),
          lastRiskDetailId: this.entityRiskData.getLastRiskDetailId()
        };

        Utils.blockUI();
        this.billingService.voidTransactionFee(voidRequest).pipe(
          takeUntil(this.stop$),
          switchMap(() => this.policyService.getPolicyDocuments(this.entityRiskData.getRiskId()))
          ).subscribe(response => {
          Utils.unblockUI();

          this.billingData.populateBillingSections(this.entityRiskData.getRiskId());
          this.billingData.listTransactionFeesByRiskId(this.entityRiskData.getRiskId());
          this.policyDocumentsData.mapPolicyDocuments(response);

          NotifUtils.showSuccess(this.taxesFeesConstants.voidFeeSuccess);
        },
          err => {
            Utils.unblockUI();
            NotifUtils.showMultiLineError(err.error?.message);
          });
      }
      this.confirmationModalRef.hide();
    });
  }
}
