import { Iso360PropertyDTO } from './risk-iso360-property.dto';
import { RiskStatusCode } from './risk.dto';

export class SaveIso360PropertyDTO implements ISaveIso360PropertyDTO {
    riskId!: string;
    riskDetailId!: string;
    iso360PropertyDTO: Iso360PropertyDTO;
    riskSubStatusCode?: RiskStatusCode;

    public constructor(init?: Partial<SaveIso360PropertyDTO>) {
        Object.assign(this, init);
    }
}

export interface ISaveIso360PropertyDTO {
    riskId: string;
    riskDetailId: string;
    iso360PropertyDTO: Iso360PropertyDTO;
    riskSubStatusCode?: RiskStatusCode;
}
