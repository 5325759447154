
import { StateCodeValuePair } from 'app/shared/models/management/program-state-response.dto';
import { createAction, props } from '@ngrx/store';

export const updateProgramStateAgentDetailIsLoadingFromAuthService = createAction(
  '[AuthService] ProgramStateAgentDetail API call is loading',
  props<{ isLoading: boolean }>()
);

export const updateProgramStateAgentDetailStateAssignmentFromAuthService = createAction(
  '[AuthService] ProgramStateAgentDetail State assignment',
  props<{ stateList: StateCodeValuePair[] }>()
);