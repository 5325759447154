import { BaseState } from 'app/store/base.state';
import { createSelector } from '@ngrx/store';

export const selectCommissionPaymentState = (state: BaseState) => state.commissionPayment;

export const selectRefundRequestIsLoading = createSelector(
  selectCommissionPaymentState,
  (state) => state.isLoading
);

export const selectCommissionPaymentList = createSelector(
  selectCommissionPaymentState,
  (state) => state.commissionPaymentList
);

export const selectFilteredCommissionPaymentList = createSelector(
  selectCommissionPaymentState,
  (state) => state.filteredCommissionPaymentList
);