import { Component, OnInit } from '@angular/core';
import { BaseClass } from '../../../../../shared/base-class';
import Utils from '../../../../../shared/utilities/utils';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BatchPaymentsConstants } from '../../../../../shared/constants/batch-payments.constants';
import { BatchPaymentsData } from '../../../../../modules/payments/data/batch-payments.data';
import { BatchPaymentsDetailsModalComponent } from '../../batch-payments-modals/batch-payments-details-modal/batch-payments-details-modal.component';
import { UploadBatchPaymentModalComponent } from '../../batch-payments-modals/upload-batch-payment-modal/upload-batch-payment-modal.component';
import { BatchPaymentDTO } from '../../../../../shared/models/data/dto/batch-payments/batch-payment.dto';

@Component({
  selector: 'app-pending-batch-payments-list',
  templateUrl: './pending-batch-payments-list.component.html',
  styleUrls: ['./pending-batch-payments-list.component.scss']
})
export class PendingBatchPaymentsListComponent extends BaseClass implements OnInit {
  public BatchPaymentsLabel = BatchPaymentsConstants.Labels;
  public AlertMessage = BatchPaymentsConstants.AlertMessage;
  modalRef: BsModalRef;
  pendingBatchPaymentsItems = [];

  constructor(
    private modalService: BsModalService,
    private batchPaymentsData: BatchPaymentsData
  ) {
    super();
  }

  ngOnInit() {
    this.batchPaymentsData.getPendingBatchPaymentsList();
  }

  onAdd(): void {
    Utils.blockUI();
    const initialState = {
      isAdd: true,
      isForPendingPayments: true
    };
    Utils.unblockUI();
    this.showBatchPaymentDetailsModal(BatchPaymentsDetailsModalComponent, initialState);
  }

  onDetailsClick(item?: BatchPaymentDTO) {
    this.batchPaymentsData.getDetailedBatchPaymentView(item.batchNumber);
    this.batchPaymentsData.detailsViewNotifier.take(1).subscribe(res => {
      if (res.details) {

        const initialState = {
          batchPaymentDetails: res.details,
          isForNewCreationOfBatchPayment: false,
          isForPendingPayments: true,
          isAdd: false
        };
        this.showBatchPaymentDetailsModal(BatchPaymentsDetailsModalComponent, initialState);
      }
    });
  }

  onPostClick(item: any): void {
    this.batchPaymentsData.getDetailedBatchPaymentView(item.batchNumber);
    this.batchPaymentsData.detailsViewNotifier.take(1).subscribe(res1 => {
      if (res1) {
        this.batchPaymentsData.manualPostBatchPayment(res1.details);
      }
    });
  }

  showBatchPaymentDetailsModal(component?, initialState?): BsModalRef {
    return this.modalService.show(component, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
    });
  }

  showUploadBatchPaymentModal(): void {
    const initialState = {
      title: BatchPaymentsConstants.Labels.Upload.header
    };
    this.modalRef = this.modalService.show(UploadBatchPaymentModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  isControlTotalNotEqualsAmountPosted(batchPayment: BatchPaymentDTO): boolean {
    return batchPayment?.controlTotal !== batchPayment?.amountPosted;
  }

  setPage(page: number) {
    this.batchPaymentsData.batchPaymentsPendingListSetPage(page);
  }
}
