export class EntityAddressDTO3 implements IEntityAddressDTO3 {
  id?: string;
  entityId?: string;
  addressId?: string;
  address?: AddressDTO3;
  // addressTypeId?: number;
  addressTypeCode?: string;
  effectiveDate?: Date;
  expirationDate?: Date;
  createdBy?: number;
  createdByFullName?: string;
  createdDate?: Date;
  // removedBy?: number;
  // removedDate?: Date | undefined;
  isActive?: boolean;

  constructor(data?: IEntityAddressDTO3) {
      if (data) {
          for (const property in data) {
              if (data.hasOwnProperty(property)) {
                  (<any>this)[property] = (<any>data)[property];
              }
          }
      }
  }
}

export interface IEntityAddressDTO3 {
  id?: string;
  entityId?: string;
  addressId?: string;
  address?: AddressDTO3;
  addressTypeId?: number;
  effectiveDate?: Date;
  expirationDate?: Date;
  createdBy?: number;
  createdDate?: Date;
  removedBy?: number;
  removedDate?: Date | undefined;
  addressTypeCode?: string;
  isActive?: boolean;
}

export class AddressDTO3 implements IAddressDTO3 {
  id?: string | undefined;
  streetAddress1?: string | undefined;
  streetAddress2?: string | undefined;
  // cityZipCodeId?: number;
  zipCode?: string | undefined;
  zipCodeExt?: string | undefined;
  city?: string | undefined;
  state?: string | undefined;
  county?: string | undefined;
  countryCode?: string | undefined;
  longitude?: number | undefined;
  latitude?: number | undefined;
  createdBy?: number;
  createdByFullName?: string;
  createdDate?: Date;
  isActive?: boolean;

  constructor(data?: IAddressDTO3) {
      if (data) {
          for (const property in data) {
              if (data.hasOwnProperty(property)) {
                  (<any>this)[property] = (<any>data)[property];
              }
          }
      }
  }
}

export interface IAddressDTO3 {
  id?: string | undefined;
  streetAddress1?: string | undefined;
  streetAddress2?: string | undefined;
  cityZipCodeId?: number;
  zipCode?: string | undefined;
  zipCodeExt?: string | undefined;
  city?: string | undefined;
  state?: string | undefined;
  county?: string | undefined;
  countryCode?: string | undefined;
  longitude?: number | undefined;
  latitude?: number | undefined;
  createdBy?: number;
  createdDate?: Date;
  isActive?: boolean;
}
