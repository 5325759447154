import { Component, OnInit } from '@angular/core';
import { PolicyBillingLabelsConstants } from '../../../../shared/constants/policy-billing.labels.constants';
import { BaseClass } from '../../../../shared/base-class';
import { PolicyBillingData } from '../../data/policy-billing.data';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { SummaryData } from '../../../../modules/submission-management/data/summary.data';
import { BillingService } from '../../../../core/services/billing/billing.service';
import { BillingSummaryDTO } from '../../../../shared/models/data/dto/billing/billing-summary.dto';
import { PostPaymentModalComponent } from '../payments/post-payments/post-payment-modal/post-payment-modal.component';
import Utils from '../../../../shared/utilities/utils';
import { ChangePaymentPlanModalComponent } from './change-payment-plan-modal/change-payment-plan-modal.component';
import { LvPayPlanOptions } from '../../../../shared/constants/billing.options.constants';
import { PaymentPlanListConstants } from '../../../../shared/constants/bind-and-issue.labels.constants';
import NotifUtils from '../../../../shared/utilities/notif-utils';
import { takeUntil } from 'rxjs/operators';
import { EntityRiskData } from '../../../../../app/modules/submission-management/data/entity-risk.data';
import { StatusConstants } from '../../../../../app/shared/constants/risk-status';
import { PolicyService } from '../../../../../app/core/services/submission/policy.service';
import { PaymentProcedure } from '../../../../../app/shared/enum/payment-procedure.enum';
import { SubmissionPageData } from '../../../../../app/modules/submission-management/data/submission-page.data';
import { quoteStatus } from '../../../../../app/shared/constants/quote-status.constants';
import { forkJoin } from 'rxjs';
import { AutoReinstatementData } from '../../data/auto-reinstatement.data';
import { OtherApprovalRequirementsDTO } from '../../../../shared/models/data/dto/billing/other-approval-requirements.dto';
import { AllowedPaymentRangeDTO } from '../../../../shared/models/data/dto/billing/allowed-payment-range.dto';
import { InterestData } from '../../../../../app/modules/submission-management/data/interest.data';
import { GenericLabel } from '../../../../shared/constants/generic.labels.constants';

@Component({
  selector: 'app-billing-summary',
  templateUrl: './billing-summary.component.html',
  styleUrls: ['./billing-summary.component.scss']
})
export class BillingSummaryComponent extends BaseClass implements OnInit {
  isOpen: boolean = true;

  summary: BillingSummaryDTO;

  hasActiveRenewalOffer: boolean;

  modalRef: BsModalRef | null;
  public billingSummaryConstants = PolicyBillingLabelsConstants.billingSummary;
  public paymentLabelConstants = PolicyBillingLabelsConstants.payments;
  public paymentPlanListConstants = PaymentPlanListConstants;

  GenericLabel = GenericLabel;

  constructor(public billingData: PolicyBillingData,
    protected modalService: BsModalService,
    public summaryData: SummaryData,
    public billingService: BillingService,
    protected entityRiskData: EntityRiskData,
    public policyService: PolicyService,
    protected submissionPageData: SubmissionPageData,
    protected autoReinstatementData: AutoReinstatementData,
    public interestData: InterestData) {
    super();
  }

  ngOnInit() {
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }

  onPaymentPlanValueChange(): void {

    Utils.blockUI();
    const riskId: string = this.entityRiskData.getRiskId();
    this.billingService.getChangePayplan(riskId).subscribe(data => {
      Utils.unblockUI();

      const initialState = {
        getChangePayplanResponse: data,
      };

      this.modalRef = this.modalService.show(ChangePaymentPlanModalComponent, {
        initialState,
        class: 'modal-md modal-dialog-centered',
        backdrop: 'static',
        keyboard: false
      });

    });
  }

  changeValue(value: string): void {
    this.billingData.selectedPaymentPlanId = value;
  }

  getPaymentPlanName(code: string): string {
    return LvPayPlanOptions.find(result => result.code === code)?.description;
  }

  onOpenPaymentModal(): void {
    this.billingData.makePaymentData = null;

    if (this.entityRiskData.getRisk().riskStatusCode === StatusConstants.cancelled) {
      this.showReinstatementModal();
    } else {
      this.showMakePaymentModal();
    }
  }

  showMakePaymentModal(): void {
    Utils.blockUI();

    const isPolicyOnPendingCancellation = this.entityRiskData.getRisk().pendingCancellationDate !== null;
    const apiCallsForActivePolicy = isPolicyOnPendingCancellation? [
      this.billingService.getAllowedPaymentRange(this.entityRiskData.getRiskId()),
      this.autoReinstatementData.getOtherReinstatementRequirements(false, this.entityRiskData.getRiskId())
    ] : [
      this.billingService.getAllowedPaymentRange(this.entityRiskData.getRiskId())
    ];
    const parallelCalls = this.billingData.hasRenewalNotTaken ? [
      this.billingService.getAllowedPaymentRange(this.entityRiskData.getRiskId()),
      this.autoReinstatementData.getOtherReinstatementRequirements(true, null)
    ] : apiCallsForActivePolicy;

    forkJoin(parallelCalls)
      .pipe(takeUntil(this.stop$)).subscribe(res => {
        Utils.unblockUI();
        const initialState = {
          allowedPaymentRange: res[0],
          paymentProcedure: PaymentProcedure.PostBasic,
          isMakeAPayment: true,
          isForRenewalReinstatement: this.billingData.hasRenewalNotTaken,
          isPolicyOnPendingCancellation: isPolicyOnPendingCancellation
        };

        this.autoReinstatementData.allowedPaymentRangeFromPostPayment = <AllowedPaymentRangeDTO>res[0];
        this.autoReinstatementData.requirementsMetDetails = <OtherApprovalRequirementsDTO>res[1] ?? null;

        this.modalService.show(PostPaymentModalComponent, {
          initialState,
          backdrop: true,
          ignoreBackdropClick: true,
          class: 'modal-md',
        });
      },
        err => {
          Utils.unblockUI();
          NotifUtils.showMultiLineError(err.error?.message);
        });
  }

  showReinstatementModal(): void {
    Utils.blockUI();
    forkJoin([this.billingService.getIsPayToInstantReinstateMet(this.entityRiskData.getRiskId()),
    this.autoReinstatementData.getOtherReinstatementRequirements(false, this.entityRiskData.getRiskId())])
      .subscribe(result => {
        Utils.unblockUI();
        this.autoReinstatementData.allowedPaymentRangeFromPostPayment = result[0].allowedPaymentRange;
        this.autoReinstatementData.requirementsMetDetails = result[1];

        const initialState = {
          allowedPaymentRange: result[0].allowedPaymentRange,
          paymentProcedure: PaymentProcedure.PayToReinstate,
          balance: this.billingData.summary.balance
        };
        this.modalRef = this.modalService.show(PostPaymentModalComponent, {
          initialState,
          backdrop: true,
          ignoreBackdropClick: true,
        });
      }, err => {
        Utils.unblockUI();
        NotifUtils.showError(JSON.stringify(err.error?.message));
      });
  }

  get isCancelledStatus(): boolean {
    return this.submissionPageData.quoteStatus?.value === quoteStatus.can;
  }
}
