import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BookTransferService } from 'app/core/services/book-transfer.service';
import { BaseClass } from 'app/shared/base-class';
import Utils from 'app/shared/utilities/utils';
import { takeUntil } from 'rxjs/operators';
import { BookTransferData } from 'app/modules/book-transfer/data/book-transfer.data';
import * as _ from 'lodash';
import { AgencyData } from 'app/modules/management/data/agency.data';
import {
  mode,
  PoliciesConstants,
  NotificationMessages,
  buttonAction,
  ButtonActionConstants
} from 'app/shared/constants/book-transfer.constants';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { BatchTransferSavingDTO } from 'app/shared/models/data/dto/book-transfer/book-transfer-saving.dto';
import * as moment from 'moment';
import { TransferQueueDTO } from 'app/shared/models/data/dto/book-transfer/transfer-queue.dto';

@Component({
  selector: 'app-edit-transfer',
  templateUrl: './edit-transfer.component.html',
  styleUrls: ['./edit-transfer.component.scss']
})
export class EditTransferComponent extends BaseClass implements OnInit {
  mode: mode = 'edit';
  notificationMessages = NotificationMessages;
  buttonActionConstants = ButtonActionConstants;

  constructor(
    public agencyData: AgencyData,
    public bookTransferData: BookTransferData,
    private bookTransferService: BookTransferService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    Utils.blockUI();
    this.bookTransferData.isEditMode = true;
    this.bookTransferData.resetData();
    this.bookTransferData.initBookTransferForms();
    this.clearFormValidators();
    this.initTranferDataFromRoute();
  }

  clearFormValidators(): void {
    this.bookTransferData.clearFormValidators(this.bookTransferData.fromAgencyForm);
    this.bookTransferData.clearFormValidators(this.bookTransferData.toAgencyForm);
    this.bookTransferData.clearFormValidators(this.bookTransferData.transferDetailsForm);
    delete this.bookTransferData.transferDateOptions.disableUntil;
  }

  initTranferDataFromRoute(): void {
    const batchId: string = _.split(this.router.url, '/')[3];

    this.bookTransferService.getBatchDetailsByBatchId(batchId).pipe(takeUntil(this.stop$)).subscribe(batch => {
      if (batch) {
        Utils.blockUI();
        this.bookTransferData.currentBatchData = batch;

        this.bookTransferService.getQueueDetailsByBatchId(batchId).pipe(takeUntil(this.stop$)).subscribe(queue => {
          if (queue) {
            if (this.agencyData.isSearched) {
              this.agencyData.callAgencyAPIonLoad();
              this.agencyData.isSearched = false;
            }

            this.bookTransferData.getPoliciesToTransfer();
            this.bookTransferData.currentQueueData = queue;
            this.bookTransferData.loadTransferDetailsFromDashboard();
          }
        });
      }
    });
  }

  onSubmit(data: buttonAction) {
    switch(data) {
      case this.buttonActionConstants.submit:
        this.postEditBookTransfer();
        break;
      case this.buttonActionConstants.cancel:
        this.bookTransferData.onCancel();
        break;
    }
  }

  postEditBookTransfer(): void {
    Utils.blockUI();

    this.bookTransferService.postEditBookTransfer(this.getPostEditBookTransferPayload()).pipe(takeUntil(this.stop$)).subscribe(() => {
      NotifUtils.showSuccessWithConfirmationFromUser(this.notificationMessages.editTransferQueueSuccess, () => {
        this.router.navigate(['book-transfer', 'batch-dashboard']);
      });
    });
  }

  getPostEditBookTransferPayload(): BatchTransferSavingDTO {
    const currentBatchData = this.bookTransferData.currentBatchData;

    const payload = {
      id: currentBatchData.id,
      fromAgencyId: currentBatchData.fromAgencyId,
      fromSubAgencyId: currentBatchData.fromSubAgencyId,
      toAgencyId: currentBatchData.toAgencyId,
      toSubAgencyId: currentBatchData.toSubAgencyId,
      toAgentId: currentBatchData.toSubAgencyId,
      transferReason: currentBatchData.transferReason,
      transferDate: currentBatchData.transferDate,
      isMassTransfer: this.bookTransferData.isMassTransfer,
      isActive: true,
      transferQueue: this.getEditTransferQueuePayload()
    };

    return payload;
  }

  getEditTransferQueuePayload(): TransferQueueDTO[] {
    const payload = this.bookTransferData.forTransferPolicies.map(policy => {
      const policyBySubAgency = this.bookTransferData.policies.find(subAgencyPolicy => {
        return policy.policyNumber === subAgencyPolicy.policyNumber;
      });

      const policyByCurrentQueueData = this.bookTransferData.currentQueueData.find(subAgencyPolicy => {
        return policy.policyNumber === subAgencyPolicy.policyNumber;
      });

      return {
        riskId: policyByCurrentQueueData?.riskId ? policyByCurrentQueueData.riskId : policyBySubAgency.riskId,
        policyNumber: policyBySubAgency.policyNumber,
        fromAgentId: policyByCurrentQueueData?.fromAgentId ? policyByCurrentQueueData?.fromAgentId : policyBySubAgency.fromAgentId,
        transferEffectiveDate: policyByCurrentQueueData?.transferEffectiveDate ? policyByCurrentQueueData.transferEffectiveDate :
          String(moment(policyBySubAgency.firstIssueDate).add(1, 'years').format(this.bookTransferData.dateFormat)),
        isActive: true
      };
    });

    return payload;
  }
}