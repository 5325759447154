import { Action, createReducer, on } from '@ngrx/store';
import * as fromRecalculateValuationActions from './racalculate-valuation.actions';
import { initialState, RecalculateValuationState } from './racalculate-valuation.state';

const _recalculateValuationReducer = createReducer(
  initialState,
  on(
    fromRecalculateValuationActions.updateRecalculateValuationIsLoadingFromPropertySavingData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromRecalculateValuationActions.updateRecalculateValuationIsLoadingFromPropertyDetailsComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  )
);

export function recalculateValuationReducer(
  state: RecalculateValuationState | null,
  action: Action
) {
  return _recalculateValuationReducer(state, action);
}