import { Injectable } from '@angular/core';
import { DocumentsLabelConstants } from '../../../shared/constants/bind-and-issue.labels.constants';
import { BaseClass } from '../../../shared/base-class';
import { ITableFormControl, ITableTd, ITableTh, ITableTr } from '../../../shared/models/dynamic/table.interface';
import { LvDocumentsCategory } from '../../../shared/constants/documents.options.constants';
import { DocumentsConstants } from '../../../shared/constants/documents.constants';
import * as _ from 'lodash';
import { PolicyDocumentsDTO } from '../../../shared/models/data/dto/policy-management/policy-documents.dto';
import { PagerService } from '../../../core/services/pagerService';
import { AuthService } from '../../../core/services/auth.service';
import { suppressDocumentDTO } from '../../../shared/models/data/dto/form-document/suppressDocumentDTO';
import { FormDocumentService } from '../../../core/services/submission/report/form-document.service';
import { PolicyService } from '../../../core/services/submission/policy.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PolicyDocumentsData extends BaseClass {

  constructor(protected pagerService: PagerService,
    protected authService: AuthService,
    protected formDocumentService: FormDocumentService,
    protected policyService: PolicyService) {
    super();
  }
  documentsTableHeaders: ITableTh[] = [
    { value: DocumentsLabelConstants.category },
    { value: DocumentsLabelConstants.description },
    // { value: DocumentsLabelConstants.file },
    { value: DocumentsLabelConstants.dateAdded },
  ];
  documentsTableRows: ITableTr[] = [];
  documentsTableFormControls: ITableFormControl[] = [
    {
      name: 'field_1',
      type: 'string',
    },
    {
      name: 'field_2',
      type: 'string',
    },
    {
      name: 'field_3',
      type: 'string',
    },
    {
      name: 'field_4',
      type: 'string',
    },
  ];
  documentsCategoryList = _.filter(LvDocumentsCategory, ['isActive', true]);
  public documentsList: any[] = [];
  fields: string[] = [
    DocumentsConstants.category,
    DocumentsConstants.description,
    // DocumentsConstants.fileName,
    DocumentsConstants.dateAdded,
  ];
  dateFormat: string = 'MM/dd/yyyy';
  policyDocumentPager: any = {};
  policyPagedItems: ITableTr[] = [];
  currentPageNumber: number = 1;
  suppressDocuments: suppressDocumentDTO[] = [];
  showSuppressDocumentsPreloader: boolean = false;

  mapDate(date?): string {
    const month = new Date(date).getMonth() + 1 > 9 ?  new Date(date).getMonth() + 1 : '0' + (new Date(date).getMonth() + 1);
    const day = new Date(date).getDate() > 9 ? new Date(date).getDate() : '0' + new Date(date).getDate() ;
    const year = new Date(date).getFullYear();

    return `${month}/${day}/${year}`;
  }

  mapPolicyDocuments(policyDocuments: PolicyDocumentsDTO[]): void {
    this.documentsList = [];
    this.documentsTableRows = [];
    this.documentsList = policyDocuments.sort((doc1, doc2) => {
      if (doc1.createdDateProper === doc2.createdDateProper) {
        return doc2.fileName.localeCompare(doc1.fileName);
      }
      return doc2.createdDateProper > doc1.createdDateProper ? 1 : -1;
    }).map(document => ({
      id: document?.id,
      description: document?.description,
      category: document?.category,
      createdDate: document?.createdDateProper ?? document?.createdDate,
      fileName: document?.fileName,
      documentsId: document?.id,
      filePath: document?.filePath,
      isUploaded: document?.isUploaded,
      riskId: document?.riskId,
      isFromParent: document?.isFromParent,
      riskBindId: document?.riskBindId,
      createdBy: document?.createdBy,
      source: document?.source,
      documentType: document?.documentType,
      taskDocumentStatus: document?.taskDocumentStatus,
      isSuppressed: document?.isSuppressed
    }));
    this.initDocumentData(this.documentsList);
    this.mapDocumentsTableToDocumentList();
  }

  initDocumentData(documentsList: any): void {
    documentsList.map(x => {
      const documents = {
        id: x.id,
        description: x.description,
        category: x.category,
        dateAdded: x.createdDate,
        fileName: x.fileName,
        documentsId: x.id,
        isUploaded: x.isUploaded,
        riskId: x.riskId,
        isFromParent: x.isFromParent,
        createdBy: x.createdBy,
        source: x?.source,
        documentType: x?.documentType,
        taskDocumentStatus: x?.taskDocumentStatus,
        isSuppressed: x?.isSuppressed
      };
      this.addDocumentTableItem(documents);
    });
    this.setPolicyDocumentsPage(this.currentPageNumber);
  }

  addDocumentTableItem(newItem: any): void {
    const tr: ITableTd[] = [];
    const strZero = '0';
    let cntrDate = new Date();

    this.fields.forEach((item, index) => {
      let display: any;

      switch (item) {
        case DocumentsConstants.category:
          display = this.documentsCategoryList.find(category => category.code === newItem[item]).description;
          break;
        case DocumentsConstants.dateAdded:
          cntrDate = new Date(newItem[item]);
          display = `${(strZero + (cntrDate.getMonth() + 1)).slice(-2)}/${(strZero + (cntrDate.getDate())).slice(-2)}/${cntrDate.getFullYear()}`;
          break;
        default:
          display = Boolean(newItem[item]) ? String(newItem[item]) : '';
          break;
      }

      tr.push({
        id: newItem.id,
        value: Boolean(newItem[item]) ? newItem[item] : '',
        display: display,
      });
    });

    this.documentsTableRows.push({
      id: newItem.id,
      isFromParent: newItem.isFromParent,
      tr: tr,
      createdBy: newItem.createdBy,
      createdDate: cntrDate,
      isSuppressed: newItem.isSuppressed
    });
  }

  private mapDocumentsTableToDocumentList(): void {
    this.documentsTableRows.forEach((item, index) => {
      this.documentsList[index].id = item.id;
    });
  }

  setPolicyDocumentsPage(page: number): void {
    if (page < 1) {
      return;
    }
    this.documentsTableRows = _.orderBy(this.documentsTableRows, ['createdDate'], ['desc']);
    this.policyDocumentPager = this.pagerService.getPager(this.documentsTableRows.length, page);
    this.policyPagedItems = this.documentsTableRows.slice(this.policyDocumentPager.startIndex, this.policyDocumentPager.endIndex + 1);
    this.currentPageNumber = page;
  }

  getFTPDocuments(riskId: string): void {
    if (this.authService.isInternalUser) {
      this.showSuppressDocumentsPreloader = true;
      this.suppressDocuments = [];

      setTimeout(() => {
        this.policyService.getPolicyDocuments(riskId).pipe(takeUntil(this.stop$)).subscribe(documents => {
          this.mapPolicyDocuments(documents);

          this.formDocumentService.getFTPDocumentsTemporary(riskId).subscribe(ftpdocuments => {
            this.suppressDocuments = ftpdocuments.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
            this.showSuppressDocumentsPreloader = false;
          });
        });
      }, 1000);
    }
  }

}