import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  LvAddressType,
  LvConstructionType, LvElectrical, LvFeetToHydrant,
  LvFoundationElevation, LvGableEndBracing, LvInternalPressureDesign,
  LvMilesToStation,
  LvMitigationForm, LvNumberOfStories, LvNumberOfUnitsBetweenFireWalls,
  LvProtectionClass, LvQualifiedInspectorType, LvRaterExteriorWallFinish,
  LvRaterFoundationType, LvRaterOpeningProtection, LvRaterPrimaryHeatType,
  LvRaterPropertyUsage, LvRaterRoofCover, LvRaterRoofDeckAttachment,
  LvRaterRoofGeometry, LvRaterRoofShape, LvRaterRoofToWallAttachment, LvRaterTerrain,
  LvRaterWindDesignSpeed, LvRoofMaterial, LvStructureType, LvWindSpeed, LvMonthsOccupied, LvBceg, LvNumberOfUnitsInsured
} from '../../../shared/constants/property.options.constants';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { BaseClass } from '../../../shared/base-class';
import { CustomValidators } from '../../../shared/validators/custom.validator';
import { ZipData } from './zip.data';
import { EntityRiskDTO } from '../../../shared/models/data/dto/quick-quote/entity-risk.dto';
import { ApplicantData } from './applicant.data';
import FormUtils from '../../../shared/utilities/form.utils';
import { IAddressForCounty } from '../../../shared/models/data/dto/third-party/address-for-county.dto';
import { ThirdPartyDataService } from '../../../core/services/submission/third-party/third-party-data.service';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { ErrorMessageConstant } from '../../../shared/constants/error-message.constants';
import { takeUntil } from 'rxjs/operators';
import { AddressType } from '../../../shared/enum/address-type.enum';
import Utils from '../../../shared/utilities/utils';
import { QuoteService } from '../../../core/services/quote.service';
import { FormTypeConstants } from '../../../shared/constants/form-types.constants';
import { CoveragesData } from './coverages.data';
import { CoveragesValidators } from '../validators/coverages.validators';
import { UwQuestionsData } from './uw-questions.data';
import { LocationLabelConstants } from '../../../shared/constants/property.labels.constants';
import { AuthService } from '../../../core/services/auth.service';
import { GeneralValidationService } from 'app/core/services/submission/validations/general-validation.service';
import { SubmissionConstants } from 'app/shared/constants/submission.constants';
import { SubmissionDefaultData } from './submission-default.data';
import { GenericConstants } from '../../../shared/constants/generic.constants';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';

@Injectable({
  providedIn: 'root',
})

export class PropertyData extends BaseClass {
  // Form Groups
  locationDetails: FormGroup;
  propertyDetails: FormGroup;
  windMitigation: FormGroup;
  iso360ValueInitialValueForm: FormGroup;
  // Dropdown List
  mitigationFormList: any[];
  booleanList: any[];
  floodPolicyInforce: any[];
  dummyList: any[];
  propertyUsageList: any[];
  feetToHydrantList: any[];
  constructionTypeList: any[];
  exteriorWallFinishList: any[];
  structureTypeList: any[];
  numberOfUnitsFirewallsList: any[];
  roofShapeList: any[];
  foundationTypeList: any[];
  numberOfStoriesList: any[];
  roofMaterialList: any[];
  electricalList: any[];
  primaryHeatTypeList: any[];
  floodCarrierList: any[];
  floodZoneList: any[];
  predominantRoofCoveringList: any[];
  roofDeckAttachmentList: any[];
  roofToWallAttachmentList: any[];
  roofGeometryList: any[];
  gableEndingBracingList: any[];
  wallConstructionTypeList: any[];
  openingProtectionList: any[];
  qualifiedInspectorTypeList: any[];
  terrainList: any[];
  windSpeedList: any[];
  windDesignSpeedList: any[];
  internalPressureDesignList: any[];
  typeOfOccupancyList: any[];
  zipList: any[];
  protectionClassList: any[];
  foundationElevationlist: any[];
  milesToStationList: any[];
  countyList: any[] = [];
  addressType: any[];
  monthsOccupied: any[];
  bcegList: string[];
  numberOfUnitsInsuredList: any[];

  // Date Options
  purchaseDateOption: IAngularMyDpOptions;
  replacementCostDateOption: IAngularMyDpOptions;
  dateOfInspectionOption: IAngularMyDpOptions;
  permitAppDateOption: IAngularMyDpOptions;

  showFoundationElevation: boolean = false;
  propertyResponse: EntityRiskDTO;

  protectionClassName: string;
  protectionClassLowerNumber: string;
  protectionClassHigherNumber: string;
  squareFootage360Value: string;
  distanceToActualCoastCodeISO: string;

  validYearBuilt: number = null;
  validYearRoof: number;
  validYearElectrical: number = null;
  validYearPlumbing: number = null;
  validYearHeating: number = null;
  validYearAircon: number = null;
  validFloorNumber: string = null;
  showInternal: boolean = true;
  hasCountyRetrieved: boolean = false;

  isYearBuiltQQChange: boolean = false;
  isYearAirconChange: boolean = false;
  isDonePopulating: boolean = false;
  formTypeConstants = FormTypeConstants;

  isNoHitAddress: boolean = false;
  isNoHitIso360ValueFirstCall: boolean = false;
  public isExternal: boolean = false;

  public isWindMitigationRequired: boolean = false;
  isConstructionTypeChanged: boolean = false;
  isDisableInspectionDate: boolean = false;

  public currentPropertyUsage: string;
  public showWindMitigationFormSection: boolean = true;

  constructor(
    protected zipData: ZipData,
    protected applicantData: ApplicantData,
    protected thirdPartyDataService: ThirdPartyDataService,
    public quoteService: QuoteService,
    public coveragesData: CoveragesData,
    protected auth: AuthService,
    protected generalValidationService: GeneralValidationService,
    protected submissionDefaultData: SubmissionDefaultData,
    protected policySummaryData: PolicySummaryData
  ) {
    super();
  }

  initPropertyForms(): void {
    this.locationDetails = this.locationDetailsSection();
    this.propertyDetails = this.propertyDetailsSection();
    this.windMitigation = this.windMitigationSection();
    this.iso360ValueInitialValueForm = this.iso360ValueInitialBuildForm();

    this.dropdownValues();
    this.dateFormatOptions();
  }

  locationDetailsSection(): FormGroup {
    return new FormGroup({
      locationAddress: new FormControl('', [Validators.required]),
      county: new FormControl({ value: '', disabled: true }, [Validators.required]),
      distanceToWater: new FormControl('', [Validators.required]),
      distanceToActualCoast: new FormControl('', [Validators.required]),
      protectionClass: new FormControl({ value: '' }, [Validators.required]),
      milesToFireStation: new FormControl('', [Validators.required]),
      feetToHydrant: new FormControl('', [Validators.required]),
      propertyUsage: this.quoteService.formType !== this.formTypeConstants.HO4 ?
        new FormControl(this.submissionDefaultData.getPropertyUsageDefault(), [Validators.required])
        : new FormControl(this.submissionDefaultData.getPropertyUsageDefault(), []),
      structureType: new FormControl(this.submissionDefaultData.getStructureTypeDefault(), [Validators.required]),
      numberOfUnitsFirewalls: this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3 ? new FormControl('', [Validators.required]) :  new FormControl('', []),
      numMonthsOccupied: this.quoteService.formType !== this.formTypeConstants.HO4 ? new FormControl('', [Validators.required, Validators.min(1), Validators.max(12)]) : new FormControl('', []),
      isResidenceRented: this.quoteService.formType !== this.formTypeConstants.HO4 ? new FormControl(false, [Validators.required]) : new FormControl('', []),
      isPropertyManager: this.quoteService.formType !== this.formTypeConstants.HO4 ? new FormControl(false, [Validators.required]) : new FormControl('', []),
      bceg: new FormControl('', [Validators.required]),
      territoryCode: new FormControl('', [Validators.required]),
      matchLevel: new FormControl('', [Validators.required]),
      latitude: new FormControl('', []),
      longitude: new FormControl('', []),
      windPoolEligible: new FormControl({ value: false }, []),
      oceanName: new FormControl('', []),
      nearestWaterName: new FormControl('', []),
      respondingFireDept: new FormControl('', []),
      fireDistrictName: new FormControl('', []),
      valuationId: new FormControl('', []),
      numberOfUnitsInsured: this.quoteService.formType === this.formTypeConstants.DP3 ? new FormControl('UI1', [Validators.required]) :  new FormControl('', []),
    });
  }

  propertyDetailsSection(): FormGroup {
    return new FormGroup({
      yearBuilt: new FormControl('', [Validators.required, Validators.min(1800), Validators.max((this.auth.getCustomDate()).getFullYear() + 1)]),
      purchaseDate:  this.quoteService.formType !== this.formTypeConstants.HO4 ? new FormControl('', [Validators.required]): new FormControl('', []),
      squareFootage: new FormControl('', [Validators.required, CustomValidators.spaceValidator, CustomValidators.zeroValueValidator]),
      exteriorWallFinish: new FormControl('', [Validators.required]),
      constructionType: new FormControl('', [Validators.required]),
      numOfStories: new FormControl(SubmissionConstants.numberOfStoriesAllForms, [Validators.required]),
      roofShape: new FormControl('', [Validators.required]),
      roofMaterial: new FormControl('', [Validators.required]),
      isFlatTile: this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3 ? new FormControl(false, [Validators.required]) : new FormControl('', []),
      yearRoof: this.quoteService.formType !== this.formTypeConstants.HO4 ? new FormControl('', [Validators.required, Validators.min(1800), Validators.max((this.auth.getCustomDate()).getFullYear() + 1)]) : new FormControl('', []),
      foundationType: this.quoteService.formType !== this.formTypeConstants.HO6 ?
        new FormControl(SubmissionConstants.foundationTypeAllForms, [Validators.required])
        : new FormControl('', []),
      foundationElevation: this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3 ? new FormControl('', [Validators.required]): new FormControl('', []),
      electrical: new FormControl('', [Validators.required]),
      yearElectrical: this.quoteService.formType !== this.formTypeConstants.HO4 ? new FormControl('', [Validators.required, Validators.min(1800), Validators.max((this.auth.getCustomDate()).getFullYear() + 1)]) : new FormControl('', []),
      yearPlumbing: this.quoteService.formType !== this.formTypeConstants.HO4 ? new FormControl('', [Validators.required, Validators.min(1800), Validators.max((this.auth.getCustomDate()).getFullYear() + 1)]) : new FormControl('', []),
      primaryHeatType: new FormControl('', [Validators.required]),
      yearHeating: this.quoteService.formType !== this.formTypeConstants.HO4 ? new FormControl('', [Validators.required, Validators.min(1800), Validators.max((this.auth.getCustomDate()).getFullYear() + 1)]) : new FormControl('', []),
      isCentralAirConditioning: new FormControl(false, [Validators.required]),
      yearCentralAirConditioning: this.quoteService.formType !== this.formTypeConstants.HO4 ? new FormControl('', [Validators.required, Validators.min(1800), Validators.max((this.auth.getCustomDate()).getFullYear() + 1)]) : new FormControl('', []),
      replacementCost: this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3 ? new FormControl(null, [Validators.required]) : new FormControl(null, []),
      replacementCostDate: this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3 ? new FormControl('', [Validators.required]): new FormControl('', []),
      floorUnitNumber: this.quoteService.formType === this.formTypeConstants.HO4 || this.quoteService.formType === this.formTypeConstants.HO6 ? new FormControl('', [Validators.required, Validators.max(50)]) : new FormControl('', [])
    });
  }

  windMitigationSection(): FormGroup {
    return new FormGroup({
      mitigationForm: new FormControl('', [Validators.required]),
      dateInspection: new FormControl('', [Validators.required]),
      intlResidentialBuildingCd: new FormControl(false, [Validators.required]),
      predominantRoofCovering: new FormControl('', [Validators.required]),
      permitAppDate: new FormControl(''), // removed from docu
      roofDeckAttachment: new FormControl('', [Validators.required]),
      roofToWallAttachment: new FormControl('', [Validators.required]),
      roofGeometry: new FormControl({ value: '', disabled: true }, [Validators.required]),
      gableEndBracing: new FormControl(''), // removed from docu
      secondaryWaterResistance: new FormControl(false, [Validators.required]),
      openingProtection: new FormControl('', [Validators.required]),
      qualifiedInspectorType: new FormControl(''),
      inspectorLicenseNumber: new FormControl('', CustomValidators.spaceValidator),
      terrain: new FormControl(SubmissionConstants.terrainB, [Validators.required]),
      windSpeed: new FormControl(SubmissionConstants.windSpeed100, [Validators.required]),
      windDesignSpeed: new FormControl(SubmissionConstants.windDesignSpeed100, [Validators.required]),
      internalPressureDesign: new FormControl('', [Validators.required]),
      windBorneDebrisRegion: new FormControl(false, [Validators.required]),
      mitigation: new FormControl('') // removed from docu
    });
  }

  iso360ValueInitialBuildForm(): FormGroup {
    return new FormGroup({
      exteriorWallFinish360DefaultCode: new FormControl(),
	    foundationType360DefaultCode: new FormControl(),
	    roofMaterial360DefaultCode: new FormControl(),
	    roofShape360DefaultCode: new FormControl()
    });
  }

  dropdownValues(): void {
    this.roofShapeList = LvRaterRoofShape;
    this.constructionTypeList = LvConstructionType;
    this.exteriorWallFinishList = LvRaterExteriorWallFinish;
    this.foundationTypeList = LvRaterFoundationType;
    this.predominantRoofCoveringList = LvRaterRoofCover;
    this.roofDeckAttachmentList = LvRaterRoofDeckAttachment.filter(x => x.isActive === true);
    this.roofToWallAttachmentList = LvRaterRoofToWallAttachment;
    this.roofGeometryList = LvRaterRoofGeometry;
    this.openingProtectionList = LvRaterOpeningProtection;
    this.terrainList = LvRaterTerrain;
    this.windDesignSpeedList = LvRaterWindDesignSpeed;
    this.mitigationFormList = LvMitigationForm.filter(item => item.code === SubmissionConstants.noForm || item.code === SubmissionConstants.oirMitigationType);
    this.milesToStationList = LvMilesToStation;
    this.foundationElevationlist = LvFoundationElevation;
    this.protectionClassList = LvProtectionClass;
    this.propertyUsageList = LvRaterPropertyUsage;
    this.feetToHydrantList = LvFeetToHydrant;

    this.quoteService.formTypeObs$.pipe(takeUntil(this.stop$)).subscribe(formType => {
      this.structureTypeList = LvStructureType.filter(item => {
        if (formType !== this.formTypeConstants.HO4) {
          return item.isHO3 || item.isHO6;
        }

        return item.isHO4;
      });
    });

    this.roofMaterialList = LvRoofMaterial.filter(item => {
      if (this.quoteService.formType === FormTypeConstants.HO3 || this.quoteService.formType === FormTypeConstants.DP3) {
        return (item.isHO3 || item.isDP3) && item.isActive;
      } else {
        return (item.isHO3 || item.isHO4 || item.isHO6 || item.isDP3) && item.isActive;
      }
    });

    this.numberOfUnitsFirewallsList = LvNumberOfUnitsBetweenFireWalls;
    this.numberOfStoriesList = LvNumberOfStories;
    this.electricalList = LvElectrical;
    this.primaryHeatTypeList = LvRaterPrimaryHeatType;
    this.gableEndingBracingList = LvGableEndBracing;
    this.qualifiedInspectorTypeList = LvQualifiedInspectorType;
    this.windSpeedList = LvWindSpeed;
    this.internalPressureDesignList = LvInternalPressureDesign;
    this.addressType =LvAddressType;
    this.monthsOccupied = LvMonthsOccupied;
    this.booleanList = [
      { name: 'Yes', value: '1' },
      { name: 'No', value: '2' }
    ];
    this.floodPolicyInforce = [
      { name: 'Yes', value: '1' },
      { name: 'No', value: '2' },
      { name: 'Flood Rejection Form', value: '3' }
    ];
    this.dummyList = [
      { name: 'dummy1', value: 1 },
      { name: 'dummy2', value: 2 }
    ];
    this.typeOfOccupancyList = [
      { name: 'Owner' },
      { name: 'Tenant' }
    ];
    this.floodCarrierList = [
      { name: '' }
    ];
    this.floodZoneList = [
      { name: 'A' },
      { name: 'A1-A30' },
      { name: 'A99' },
      { name: 'AE' },
      { name: 'AH' },
      { name: 'AO' },
      { name: 'AR' },
      { name: 'B' },
      { name: 'C' },
      { name: 'D' },
      { name: 'V' },
      { name: 'VE' },
      { name: 'X' },
      { name: '' }
    ];
    this.wallConstructionTypeList = [
      { name: '' }
    ];
    this.zipList = this.zipData.zipList;
    this.bcegList = LvBceg;
    this.numberOfUnitsInsuredList = LvNumberOfUnitsInsured;
  }

  dateFormatOptions(): void {
    this.purchaseDateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy',
      disableSince: { year: 0, month: 0, day: 0 },
      disableUntil: { year: 0, month: 0, day: 0 }
    };

    this.replacementCostDateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy'
    };

    this.dateOfInspectionOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy'
    };

    this.permitAppDateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy'
    };
  }

  public populateCounty(data: EntityRiskDTO, isFromPolicy: boolean = false): void {
    const entityAddress = data.entityAddresses.find(a => a.addressTypeCode === AddressType.Property)?.address;

    const addressForCounty: IAddressForCounty = {
      street2: entityAddress?.streetAddress2,
      city: entityAddress?.city,
      stateCode: entityAddress?.state,
      street1: entityAddress?.streetAddress1,
      zip: entityAddress?.zipCode,
    };

    this.thirdPartyDataService.getCounties(addressForCounty).pipe(takeUntil(this.stop$)).subscribe(
      (res) => {
        this.updateCounty(res, isFromPolicy);
      },
      (error) => {
        NotifUtils.showError(`${ErrorMessageConstant.errorOnRetrievingCounty}`);
      },
      () => {
        this.updateCountyFromRiskProperty(data);
      }
    );
  }

  updateCounty(res: any, isFromPolicy: boolean): void {
    this.hasCountyRetrieved = Boolean(res);

    if (res) {
      this.countyList = res;
      this.locationDetails.get('county').enable();

      if (res.length === 1) {
        this.locationDetails.get('county').setValue(res[0]);
      } else if (res.length > 1) {
        this.locationDetails.get('county').setValue('');
      }

      if (res.length === 1) {
        this.locationDetails.get('county').disable();
      } else if (!isFromPolicy) {
        this.locationDetails.get('county').enable();
      }
    }
  }

  updateCountyFromRiskProperty(data: any): void {
    if (
      data.risks.length >= 1 &&
      data.risks[0]?.riskDetails[0]?.riskProperties.length >= 1 &&
      data.risks[0]?.riskDetails[0]?.riskProperties[0]?.county
    ) {
      const county = data.risks[0]?.riskDetails[0]?.riskProperties[0]?.county;

      if (!this.countyList.includes(county)) {
        this.countyList.push(county);
      }

      this.locationDetails.get('county').setValue(data.risks[0]?.riskDetails[0]?.riskProperties[0]?.county ?? '');
      this.hasCountyRetrieved = this.countyList.length !== 0;
    }
  }

  public populatePropertyPage(data: EntityRiskDTO, isFromPolicy: boolean = false): void {
    this.propertyResponse = data;
    const riskProperties = data?.risks[0]?.riskDetails[0]?.riskProperties;

    if (riskProperties.length !== 0) {
      this.protectionClassName = riskProperties[0]?.protectionClassName;
      this.isNoHitAddress = riskProperties[0]?.isNoHitAddress;
      this.checkProtectionClassName();
      if (Array.isArray(this?.countyList) && this?.countyList?.length === 0) {
        this.populateCounty(data, isFromPolicy);
      }
      this.updateFormValues(riskProperties, data, isFromPolicy);

      const formValues = {
        predominantRoofCovering: riskProperties[0]?.raterRoofCoverCode ?? '',
        internalPressureDesign: riskProperties[0]?.internalPressureDesignCode ?? '',
        secondaryWaterResistance: riskProperties[0]?.isSecondaryWaterResistance ?? false,
        openingProtection: riskProperties[0]?.raterOpeningProtectionCode ?? ''
      };

      this.setWindMitigationDefault(formValues, true);
      this.showWindMitigation();
    }
  }

  checkProtectionClassName(): void {
    if (this?.protectionClassName?.includes('/')){
      this.protectionClassLowerNumber = this?.protectionClassName?.split('/')[0];
      this.protectionClassHigherNumber = this?.protectionClassName?.split('/')[1];
      return;
    }

    this.protectionClassLowerNumber = this?.protectionClassName;
    this.protectionClassHigherNumber = this?.protectionClassName;
  }

  updateFormValues(riskProperties: any, data: EntityRiskDTO, isFromPolicy?: boolean): void {
    this.updateLocationDetailsFormValues(riskProperties, data);
    this.updatePropertyDetailsFormValues(riskProperties);
    this.updateWindMitigationFormValues(riskProperties, isFromPolicy);
    this.updateIso360ValueInitialValueForm(riskProperties);
    this.updateMitigationFormList(data.risks[0]?.isSiebel, riskProperties[0]?.mitigationFormCode);
  }

  updateLocationDetailsFormValues(riskProperties: any, data: EntityRiskDTO): void {
    this.coveragesData.county = riskProperties[0]?.county;
    this.locationDetails.get('county').setValue(riskProperties[0]?.county);
    this.locationDetails.get('locationAddress').setValue(riskProperties[0]?.locationAddress ?? this.setLocationAddressFromApplicant(data));
    this.locationDetails.get('distanceToWater').setValue(riskProperties[0]?.distanceToWater);
    this.locationDetails.get('distanceToActualCoast').setValue(riskProperties[0]?.distanceToActualCoast);
    this.locationDetails.get('protectionClass').setValue(riskProperties[0]?.protectionClassCode !== '0' ? riskProperties[0]?.protectionClassCode : '');
    this.locationDetails.get('milesToFireStation').setValue(riskProperties[0]?.milesToFireStationCode ?? '');
    this.locationDetails.get('feetToHydrant').setValue(riskProperties[0]?.feetToHyrantCode ?? '');
    this.locationDetails.get('propertyUsage').setValue(riskProperties[0]?.propertyUsageCode ?? '');
    this.currentPropertyUsage = riskProperties[0]?.propertyUsageCode ?? '';
    this.locationDetails.get('structureType').setValue(riskProperties[0]?.structureTypeCode ?? '');
    this.locationDetails.get('numberOfUnitsFirewalls').setValue(riskProperties[0]?.numberOfUnitsBetweenFirewallsCode ?? '');
    this.locationDetails.get('numMonthsOccupied').setValue(riskProperties[0]?.numberMonthOccupied ?? '');
    this.locationDetails.get('isResidenceRented').setValue(this.quoteService.formType !== this.formTypeConstants.HO4 ? (riskProperties[0]?.isTheResidenceRented ?? false) : null);
    this.locationDetails.get('isPropertyManager').setValue(this.quoteService.formType !== this.formTypeConstants.HO4 ? (riskProperties[0]?.isPropertyManager ?? false) : null);
    this.locationDetails.get('bceg').setValue(riskProperties[0]?.bceg);
    this.locationDetails.get('territoryCode').setValue(riskProperties[0]?.territoryCode);
    this.locationDetails.get('matchLevel').setValue(riskProperties[0]?.matchLevel);
    this.locationDetails.get('latitude').setValue(riskProperties[0]?.latitude);
    this.locationDetails.get('longitude').setValue(riskProperties[0]?.longitude);
    this.locationDetails.get('windPoolEligible').setValue(riskProperties[0]?.windPoolEligible === 'true');
    this.locationDetails.get('oceanName').setValue(riskProperties[0]?.oceanName);
    this.locationDetails.get('nearestWaterName').setValue(riskProperties[0]?.nearestWaterName);
    this.locationDetails.get('respondingFireDept').setValue(riskProperties[0]?.respondingFireDepartment);
    this.locationDetails.get('fireDistrictName').setValue(riskProperties[0]?.fireDistrictName);
    this.locationDetails.get('valuationId').setValue(riskProperties[0]?.valuationId);
    this.locationDetails.get('numberOfUnitsInsured').setValue(this.quoteService.formType === this.formTypeConstants.DP3 ? riskProperties[0]?.numberOfUnitsInsured ?? '' : '');
  }

  updatePropertyDetailsFormValues(riskProperties): void {
    this.propertyDetails.get('yearBuilt').setValue(riskProperties[0]?.yearBuilt);
    this.validYearBuilt = +riskProperties[0]?.yearBuilt;
    this.validYearRoof = Number(riskProperties[0]?.yearRoofInstalled);

    if (riskProperties[0]?.purchaseDate){
        this.propertyDetails.get('purchaseDate').setValue({isRange: false, singleDate: {jsDate: new Date(riskProperties[0]?.purchaseDate)}});
    }

    this.propertyDetails.get('squareFootage').setValue(riskProperties[0]?.squareFootage);
    this.propertyDetails.get('exteriorWallFinish').setValue(riskProperties[0]?.raterExteriorWallFinishCode ?? '');
    this.propertyDetails.get('constructionType').setValue(riskProperties[0]?.raterConstructionTypeCode ?? '');
    this.propertyDetails.get('numOfStories').setValue(riskProperties[0]?.numberOfStory ?? '');
    this.propertyDetails.get('roofShape').setValue(riskProperties[0]?.raterRoofShapeCode ?? '');
    this.propertyDetails.get('roofMaterial').setValue(riskProperties[0]?.roofMaterialCode ?? '');
    this.propertyDetails.get('isFlatTile').setValue(this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3 ? (riskProperties[0]?.isFlatTile ?? false) : false);
    this.propertyDetails.get('yearRoof').setValue(this.quoteService.formType !== this.formTypeConstants.HO4 ? riskProperties[0]?.yearRoofInstalled : null);
    this.propertyDetails.get('foundationType').setValue(riskProperties[0]?.raterFoundationTypeCode ?? '');
    this.propertyDetails.get('foundationElevation').setValue(riskProperties[0]?.foundationElevationCode ?? '');
    this.propertyDetails.get('floorUnitNumber').setValue(riskProperties[0]?.floorUnitNumber ?? '');
    this.validFloorNumber = riskProperties[0]?.floorUnitNumber;

    const foundationType = riskProperties[0]?.raterFoundationTypeCode;

    if (foundationType !== null && foundationType !== undefined && foundationType !== '') {
      this.showFoundationElevation = (foundationType.indexOf('EP') !== -1 || foundationType.indexOf('SW') !== -1 || foundationType.indexOf('DP') !== -1) &&
        (this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3);
    }

    this.propertyDetails.get('electrical').setValue(riskProperties[0]?.electricalCode ?? '');
    this.propertyDetails.get('yearElectrical').setValue(riskProperties[0]?.yearElectricalInstalledUpdated ?? riskProperties[0]?.yearBuilt);
    this.propertyDetails.get('yearPlumbing').setValue(riskProperties[0]?.yearPlumbingInstalledUpdated ?? riskProperties[0]?.yearBuilt);
    this.propertyDetails.get('primaryHeatType').setValue(riskProperties[0]?.primaryHeatTypeCode ?? '');
    this.propertyDetails.get('yearHeating').setValue(riskProperties[0]?.yearHeatingInstalledUpdated ?? riskProperties[0]?.yearBuilt);
    this.propertyDetails.get('isCentralAirConditioning').setValue(riskProperties[0]?.isCentralAirCondition ?? true);
    this.propertyDetails.get('yearCentralAirConditioning').setValue(riskProperties[0]?.yearCentralAirConditionSystemInstalled ?? riskProperties[0]?.yearBuilt);
    this.propertyDetails.get('replacementCost').setValue(riskProperties[0]?.replacementCost);
    this.validYearElectrical = Number(riskProperties[0]?.yearElectricalInstalledUpdated);
    this.validYearPlumbing = Number(riskProperties[0]?.yearPlumbingInstalledUpdated);
    this.validYearHeating = Number(riskProperties[0]?.yearHeatingInstalledUpdated);
    this.validYearAircon = Number(riskProperties[0]?.yearCentralAirConditionSystemInstalled);

    if (riskProperties[0]?.replacementCostDate) {
      this.propertyDetails.get('replacementCostDate').setValue({isRange: false, singleDate: {jsDate: new Date(riskProperties[0]?.replacementCostDate)}});
    }
    this.isDonePopulating = true;
    this.setRoofMaterialList();
  }

  updateWindMitigationFormValues(riskProperties: any, isFromPolicy?: boolean): void {
    // this.windMitigation.get('mitigationForm').setValue(this.mitigationFormList.find(a => a.value === riskProperties[0]?.mitigationFormCode?.toString())?.name);
    this.windMitigation.get('mitigationForm').setValue(riskProperties[0]?.mitigationFormCode ?? 'NF');

    if (riskProperties[0]?.dateOfInspection) {
      this.windMitigation.get('dateInspection').setValue({isRange: false, singleDate: {jsDate: new Date(riskProperties[0]?.dateOfInspection)}});
    }

    this.windMitigation.get('intlResidentialBuildingCd').setValue(riskProperties[0]?.isInternationalResBldgCode ?? false);
    this.windMitigation.get('predominantRoofCovering').setValue(riskProperties[0]?.raterRoofCoverCode ?? '');

    if (riskProperties[0]?.permitAppDateInstallation) {
      this.windMitigation.get('permitAppDate').setValue({isRange: false, singleDate: {jsDate: new Date(riskProperties[0]?.permitAppDateInstallation)}});
    }

    if (this.propertyDetails.get('roofShape').value === 'F') {
      this.roofDeckAttachmentList = LvRaterRoofDeckAttachment;
    } else {
      this.roofDeckAttachmentList = LvRaterRoofDeckAttachment.filter(x => x.isActive === true);
    }

    this.windMitigation.get('roofDeckAttachment').setValue(riskProperties[0]?.roofDeckAttachementCode ?? '');
    this.windMitigation.get('roofToWallAttachment').setValue(riskProperties[0]?.roofWallAttachementCode ?? '');

    this.mapRoofGeometry(riskProperties[0]?.raterRoofGeometryCode ?? riskProperties[0]?.raterRoofShapeCode ?? '');

    this.windMitigation.get('gableEndBracing').setValue(riskProperties[0]?.gableEndBracingCode ?? '');

    // Unused Property
    // this.windMitigation.get('wallConstructionType').setValue(riskProperties[0]?.type);

    this.windMitigation.get('secondaryWaterResistance').setValue(riskProperties[0]?.isSecondaryWaterResistance ?? false);
    this.windMitigation.get('openingProtection').setValue(!(this.quoteService.formType === FormTypeConstants.DP3) && this.auth.userType?.value !== GenericConstants.userType.external ? riskProperties[0]?.isRaterOpeningProtectionCode ?? false : riskProperties[0]?.raterOpeningProtectionCode ?? '');
    this.windMitigation.get('qualifiedInspectorType').setValue(riskProperties[0]?.qualifiedInspectorTypeCode ?? '');
    this.windMitigation.get('inspectorLicenseNumber').setValue(riskProperties[0]?.inspectorLicenseNumber);
    this.windMitigation.get('terrain').setValue(riskProperties[0]?.terrain ?? '');
    this.windMitigation.get('windSpeed').setValue(riskProperties[0]?.windSpeedCode ?? '');
    this.windMitigation.get('windDesignSpeed').setValue(riskProperties[0]?.raterWindDesignSpeedCode ?? '');
    this.windMitigation.get('internalPressureDesign').setValue(riskProperties[0]?.internalPressureDesignCode ?? '');
    this.windMitigation.get('windBorneDebrisRegion').setValue(riskProperties[0]?.isWindBorneDebrisRegion ?? false);
    this.windMitigation.get('mitigation').setValue(riskProperties[0]?.mitigation);

    this.updateWindMitigationFormValidity();

    if (!isFromPolicy) {
      this.windMitigation.get('mitigationForm').valueChanges.pipe(takeUntil(this.stop$)).subscribe(result => {
        this.updateWindMitigationFormValidity();
      });
    }
  }

  updateIso360ValueInitialValueForm(riskProperties: any): void {
    this.iso360ValueInitialValueForm.get('exteriorWallFinish360DefaultCode').setValue(riskProperties[0]?.exteriorWallFinish360DefaultCode);
    this.iso360ValueInitialValueForm.get('foundationType360DefaultCode').setValue(riskProperties[0]?.foundationType360DefaultCode);
    this.iso360ValueInitialValueForm.get('roofMaterial360DefaultCode').setValue(riskProperties[0]?.roofMaterial360DefaultCode);
    this.iso360ValueInitialValueForm.get('roofShape360DefaultCode').setValue(riskProperties[0]?.roofShape360DefaultCode);
  }

  updateWindMitigationFormValidity(): void {
    const formControls = [
      'dateInspection',
      'intlResidentialBuildingCd',
      'predominantRoofCovering',
      'roofDeckAttachment',
      'secondaryWaterResistance',
      'roofToWallAttachment',
      'openingProtection',
      'terrain',
      'windSpeed',
      'windDesignSpeed',
      'internalPressureDesign',
      'windBorneDebrisRegion',
      'roofGeometry'
    ];

    const isHurricaneDeductibleExcluded = this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid')?.value === 'E';
    const hasMitigationForm = this.windMitigation.get('mitigationForm').value !== 'NF';
    if (hasMitigationForm && !isHurricaneDeductibleExcluded) {
      this.isWindMitigationRequired = true;

      formControls.forEach(item => {
        if (item === 'dateInspection') {
          if (this.isExternal && (this.policySummaryData.isPolicy || this.policySummaryData.isRenewal())) {
            this.generalValidationService.resetValidatorFormControl(this.windMitigation, item, [Validators.required]);
          } else if(!this.policySummaryData.isPolicy && !this.policySummaryData.isRenewal()) {
            this.generalValidationService.resetValidatorFormControl(this.windMitigation, item, [Validators.required]);
          } else {
            this.generalValidationService.clearValidatorFormControl(this.windMitigation, item);
          }
        } else {
          this.generalValidationService.resetValidatorFormControl(this.windMitigation, item, [Validators.required]);
        }
      });

      if (this.propertyDetails.get('yearBuilt').value >= 2002) {
        this.generalValidationService.clearValidatorFormControl(this.windMitigation, 'roofDeckAttachment');
        this.generalValidationService.clearValidatorFormControl(this.windMitigation, 'roofToWallAttachment');
      }

      if (this.windMitigation.get('mitigationForm').value === SubmissionConstants.noFormConverted) {
        this.generalValidationService.clearValidatorFormControl(this.windMitigation, 'dateInspection');
      }

      return;
    }

    this.isWindMitigationRequired = false;

    formControls.forEach(item => {
      this.generalValidationService.clearValidatorFormControl(this.windMitigation, item);
    });
  }

  mapRoofGeometry(data?): void {
    const other_values = ['GM', 'S', 'M', 'C'];
    const roofShape = data ?? this.propertyDetails.get('roofShape').value;

    if (other_values.find(x => x === roofShape.toUpperCase())) {
      this.windMitigation.get('roofGeometry').setValue('O');
      return;
    }

    this.windMitigation.get('roofGeometry').setValue(roofShape);
  }

  protected setLocationAddressFromApplicant(data: EntityRiskDTO): string {
      const propertyAddress = data.entityAddresses.find(a => a.addressTypeCode === AddressType.Property)?.address;
      return Utils.getFullAddress(propertyAddress);
  }

  getPropertyForm(): any {
    return  {
      locationDetails: this.locationDetails,
      propertyDetails: this.propertyDetails,
      windMitigation: this.windMitigation
    };
  }

  setPropertyPageFormStatus(status: boolean): void {
    FormUtils.setFormGroupStatus(this.locationDetails, status);
    FormUtils.setFormGroupStatus(this.propertyDetails, status);
    FormUtils.setFormGroupStatus(this.windMitigation, status);

    this.disAbleForExternalUser(status);
    this.enableDisableCountyInPolicy(status);
    //Subscribe to Wind Mitigation
    this.windMitigation.get('mitigationForm').valueChanges.pipe(takeUntil(this.stop$)).subscribe(result => {
      this.updateWindMitigationFormValidity();
    });

    this.showWindMitigation();
  }

  protected enableDisableCountyInPolicy(status: boolean): void {
    if (this?.countyList?.length > 1 && status) {
      this.locationDetails.get('county').enable();
      return;
    }

    this.locationDetails.get('county').disable();
  }

  public markApplicantFormPageAsTouch(): void {
    FormUtils.markFormgroupAsTouch(this.locationDetails);
    FormUtils.markFormgroupAsTouch(this.propertyDetails);
    FormUtils.markFormgroupAsTouch(this.windMitigation);
  }

  setWindMitigationDefault(formValues?: any, isOnLoad?: boolean): void {
    const yearBuilt = Number(this.propertyDetails.get('yearBuilt').value);

    if (isOnLoad) {
      this.initOnLoad(isOnLoad, formValues, yearBuilt);
    } else {
      this.initNotOnLoad(yearBuilt);
    }
  }

  initOnLoad(isOnLoad: boolean, formValues: any, yearBuilt: any): void {
    if (isOnLoad) {
      if (yearBuilt >= 2002) {
        this.windMitigation.get('predominantRoofCovering').setValue(formValues.predominantRoofCovering !== '' ? formValues.predominantRoofCovering : 'F');
        this.windMitigation.get('internalPressureDesign').setValue(formValues.internalPressureDesign !== '' ? formValues.internalPressureDesign : 'IPD1');
        this.windMitigation.get('secondaryWaterResistance').setValue(formValues.secondaryWaterResistance ?? false);

        if (formValues.openingProtection) {
          this.windMitigation.get('openingProtection').setValue(formValues.openingProtection ?? '');
        } else {
          this.openingProtectionBRules();
        }

        this.roofDeckRoofWallWindMitigationDefault(yearBuilt);

        return;
      }

      this.roofDeckRoofWallWindMitigationDefault(yearBuilt);
      this.windMitigation.get('predominantRoofCovering').setValue(formValues.predominantRoofCovering ??'');
      this.windMitigation.get('internalPressureDesign').setValue(formValues.internalPressureDesign ??'');
      this.windMitigation.get('secondaryWaterResistance').setValue(formValues.secondaryWaterResistance ?? false);
      this.windMitigation.get('openingProtection').setValue(formValues.openingProtection ?? '');
    }
  }

  initNotOnLoad(yearBuilt: any): void {
    if (yearBuilt >= 2002) {
      this.windMitigation.get('predominantRoofCovering').setValue('F');
      this.windMitigation.get('internalPressureDesign').setValue('IPD1');
      this.windMitigation.get('secondaryWaterResistance').setValue(false);
      this.openingProtectionBRules();

      this.roofDeckRoofWallWindMitigationDefault(yearBuilt);

      return;
    }

    this.roofDeckRoofWallWindMitigationDefault(yearBuilt);
    this.windMitigation.get('predominantRoofCovering').setValue('');
    this.windMitigation.get('internalPressureDesign').setValue('');
    this.windMitigation.get('secondaryWaterResistance').setValue(false);
    this.windMitigation.get('openingProtection').setValue('');
  }

  openingProtectionBRules(): void {
    const yearBuilt = this.propertyDetails.get('yearBuilt').value;
    const county = this.locationDetails.get('county')?.value?.toLocaleLowerCase();

    const br27CountyList: string[] = [
      'monroe', 'lee', 'escambia', 'broward', 'manatee', 'desoto', 'martin', 'brevard', 'collier', 'glades'
      ,'hendry','indian river','pinellas','sarasota','palm beach','charlotte','miami-dade', 'st lucie'];

    const br28CountyList: string[] = [
      'broward', 'escambia', 'franklin', 'gulf', 'martin', 'miami-dade', 'monroe', 'pinellas', 'sarasota', 'st lucie'];

    const validCountyBR27 = br27CountyList.includes(county); // 2012 or newer
    const validCountyBR28 = br28CountyList.includes(county); // 2002 - 2011

    if (yearBuilt >= 2012 && validCountyBR27) {
      this.windMitigation.get('openingProtection').setValue('A');
    } else if (yearBuilt >= 2002 && yearBuilt <= 2011 && validCountyBR28) {
      this.windMitigation.get('openingProtection').setValue('A');
    } else {
      this.windMitigation.get('openingProtection').setValue('N');
    }
  }

  protected disAbleForExternalUser(isEdit: boolean): void {
    if (isEdit && this.isExternal) {
      this.locationDetails.get('protectionClass').disable();
      this.locationDetails.get('windPoolEligible').disable();
    }
    this.disableMitigationForm();
  }

  setCoverageLValidator(): void {
    if (this.quoteService.formType === FormTypeConstants.DP3) {
      const isExternal = localStorage.getItem('userType')?.toLocaleLowerCase() === 'external';
      const isCoverageLEnabled = this.coveragesData.coveragesAndDeductiblesForm.get('isCoverageLEnabled')?.value || false;
      const isShortTermRental = this.locationDetails.get('propertyUsage')?.value === 'STR';
      this.coveragesData.isBrul53 = isExternal && isShortTermRental;

      if (isCoverageLEnabled && this.coveragesData.isBrul53) {
        this.coveragesData.coveragesAndDeductiblesForm.get('coverageL')
            .setValidators(CoveragesValidators.coverageL100kMaxValue());
        this.coveragesData.coveragesAndDeductiblesForm.get('coverageL').updateValueAndValidity();
        return;
      }
    } else {
      this.coveragesData.isBrul53 = false;
    }
    this.coveragesData.clearCoverageLValidator();
  }

  setFoundationType(foundationType?): any {
    return (foundationType.indexOf('EP') !== -1 || foundationType.indexOf('SW') !== -1 || foundationType.indexOf('DP') !== -1);
  }

  resetStoredValues(): void {
    this.squareFootage360Value = null;
    this.isDisableInspectionDate = false;
  }

  checkIfNearWaters(): boolean {
    const lessthan1000 = ['01', '02'];
    const distance1001to2500 = ['03', '04', '05'];
    const gulfOfMexico = 'GULF OF MEXICO';
    const atlanticOcean = 'ATLANTIC OCEAN';
    const locationForm = this.locationDetails;

    return (locationForm?.get('oceanName')?.value?.toUpperCase().indexOf(gulfOfMexico) !== -1
      && distance1001to2500.includes(this.distanceToActualCoastCodeISO))
      || (locationForm?.get('nearestWaterName')?.value?.toUpperCase().indexOf(gulfOfMexico) !== -1
        && distance1001to2500.includes(this.distanceToActualCoastCodeISO))
      || (locationForm?.get('oceanName')?.value?.toUpperCase().indexOf(atlanticOcean) !== -1
        && lessthan1000.includes(this.distanceToActualCoastCodeISO))
      || (locationForm?.get('nearestWaterName')?.value?.toUpperCase().indexOf(atlanticOcean) !== -1
        && lessthan1000.includes(this.distanceToActualCoastCodeISO));
  }

  roofDeckRoofWallWindMitigationDefault(yearBuilt: number): void {
    if (this.windMitigation.get('mitigationForm').value !== 'NF') {
      if (yearBuilt < 2002) {
        this.generalValidationService.resetValidatorFormControl(this.windMitigation, 'roofDeckAttachment', [Validators.required]);
        this.generalValidationService.resetValidatorFormControl(this.windMitigation, 'roofToWallAttachment', [Validators.required]);
      } else {
        this.generalValidationService.clearValidatorFormControl(this.windMitigation, 'roofDeckAttachment');
        this.generalValidationService.clearValidatorFormControl(this.windMitigation, 'roofToWallAttachment');
      }
    }
  }

  updateMitigationFormList(isSiebel: boolean, currentValue: string) {
    if (isSiebel &&
      (this.auth.userType?.value !== GenericConstants.userType.external || currentValue === SubmissionConstants.noFormConverted)) {
      this.mitigationFormList = LvMitigationForm;
    }
    this.disableMitigationForm();
  }

  disableMitigationForm(): void {
    if (this.windMitigation.get('mitigationForm').value === SubmissionConstants.noFormConverted
      && this.auth.userType?.value === GenericConstants.userType.external) {
      FormUtils.setFormGroupStatus(this.windMitigation, false);
      this.isDisableInspectionDate = true;
    }
  }

  showWindMitigation(): void {
    const isPolicy = this.policySummaryData.isPolicy;
    const isExternal = this.auth.userType?.value === GenericConstants.userType.external;
    const isHurricaneDeductibleExcluded = this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid')?.value === 'E';

    if (isPolicy && isExternal) {
      const currentHurricaneDecuctible = this.coveragesData.currentHurricaneDecuctible;
      this.showWindMitigationFormSection = currentHurricaneDecuctible !== 'E';
      this.windMitigation.get('mitigationForm').disable();
    } else {
      this.updateWindMitigationFormValidity();
      this.showWindMitigationFormSection = !isHurricaneDeductibleExcluded;
      this.windMitigation.get('mitigationForm').enable();
    }
  }

  setRoofMaterialList(): void { // BRUL 391
    const roofShape = this.propertyDetails.get('roofShape').value;
    if (roofShape !== 'F') {
      this.roofMaterialList = LvRoofMaterial.filter(item => {
        if (this.quoteService.formType === FormTypeConstants.HO3 || this.quoteService.formType === FormTypeConstants.DP3) {
          return (item.isHO3 || item.isDP3) && item.isActive && item.code !== 'PC';
        } else {
          return (item.isHO3 || item.isHO4 || item.isHO6 || item.isDP3) && item.isActive && item.code !== 'PC';
        }
      });
      if (this.propertyDetails.get('roofMaterial').value === 'PC') {
        this.propertyDetails.get('roofMaterial').setValue('');
      }
    } else {
      this.roofMaterialList = LvRoofMaterial.filter(item => {
        if (this.quoteService.formType === FormTypeConstants.HO3 || this.quoteService.formType === FormTypeConstants.DP3) {
          return (item.isHO3 || item.isDP3) && item.isActive;
        } else {
          return (item.isHO3 || item.isHO4 || item.isHO6 || item.isDP3) && item.isActive;
        }
      });
    }
  }

}
