import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agency-snapshot',
  templateUrl: './agency-snapshot.component.html',
  styleUrls: ['./agency-snapshot.component.scss']
})
export class AgencySnapshotComponent implements OnInit {

  isHidden: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  toggleArrow(): void {
    this.isHidden = !this.isHidden;
  }

}
