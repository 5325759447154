import { Pipe, PipeTransform } from '@angular/core';
import { GenericConstants } from 'app/shared/constants/generic.constants';

@Pipe({
  name: 'showIssueButton'
})
export class ShowIssueButtonPipe implements PipeTransform {
  transform(isInternal: boolean, status: string): boolean {
    if (isInternal) {
      return true;
    } else {
      return status !== GenericConstants.quoteStatus.pexp;
    }
  }
}
