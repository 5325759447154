import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../core/services/auth.service';
import { ClickTypes } from '../../../../shared/enum/click-type.enum';
import { ApplicantData } from '../../data/applicant.data';
import { CoveragesData, MAXCOVA, MINCOVA } from '../../data/coverages.data';
import { PropertyData } from '../../data/property.data';
import { QuickQuoteData } from '../../data/quick-quote.data';
import { SubmissionPageData } from '../../data/submission-page.data';
import { SummaryData } from '../../data/summary.data';
import { QuickQuoteService } from '../../../../core/services/submission/quick-quote.service';
import { CreateQuickQuoteResultDTO } from '../../../../shared/models/data/dto/quick-quote/create-quick-quote-result.dto';
import Utils from '../../../../shared/utilities/utils';
import NotifUtils from '../../../../shared/utilities/notif-utils';
import { ErrorMessageConstant } from '../../../../shared/constants/error-message.constants';
import { EntityRiskData } from '../../data/entity-risk.data';
import { NavigationService } from '../../../../core/services/navigation/navigation.service';
import { QuickQuoteSavingData } from '../../data/quick-quote-saving.data';
import { GenericLabel } from '../../../../shared/constants/generic.labels.constants';
import { BaseClass } from '../../../../shared/base-class';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { PathConstants } from '../../../../shared/constants/path.constants';
import { PageSectionsValidations } from '../../../../shared/enum/page-sections.enum';
import { GeneralValidationService } from '../../../../core/services/submission/validations/general-validation.service';
import { QuickQuoteFormConstant } from '../../../../shared/constants/quick-quotes.labels.constants';
import { SubmissionService } from '../../../../core/services/submission/submission.service';
import { ThirdPartyData } from '../../data/third-party.data';
import { ThirdPartyDataService } from 'app/core/services/submission/third-party/third-party-data.service';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';
import { QuoteService } from 'app/core/services/quote.service';
import { ApplicantBusinessRules } from '../../business-rules/applicant.business-rules';

import { ApplicantSavingData } from '../../../../modules/submission-management/data/applicant.saving.data';
import { SubmissionNavSavingService } from '../../../../core/services/navigation/submission-nav-saving.service';
import { CreditScoreRiskResponseDTO } from 'app/shared/models/data/dto/third-party/credit-score-risk-response.dto';
import { EndorsementsSavingData } from '../../data/endorsements.saving.data';
import { EndorsementsData } from '../../data/endorsements.data';
import { PageSections } from '../../../../shared/enum/page-sections.enum';
import { RaterPremiumService } from 'app/core/services/submission/rater-premium/rater-premium.service';
import { ZipCodeData } from '../../data/zipcode.data';
import { ClaimsData } from '../../data/claims.data';
import { Store } from '@ngrx/store';
import { updateSubmissionStatusFromApplicantPage } from 'app/store/submission/submission.actions';
import { ComparativeRaterData } from 'app/modules/comparative-rater/comparative-rater.data';
import { ClaimsSavingData } from '../../data/claims-saving.data';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { PageHeaderSummaryConstants } from 'app/shared/constants/page-header-summary.constants';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { PropertyValidators } from '../../validators/property.validators';
import { ExcludeZipcodeService } from '../../../../core/services/exclude-zipcode.service';
import { updatePolicyStatusFromApplicantPage, updatePolicySubStatusFromApplicantPage } from 'app/store/policy/policy.actions';
import { GetRiskStatusPipe } from 'app/shared/pipes/get-risk-status.pipe';
import { updateAppIsLoadingFromApplicantPageComponent } from 'app/store/app/app.actions';
import { UwQuestionsSavingData } from '../../data/uwquestions.saving.data';
import { PropertyBusinessRules } from '../../business-rules/property.business-rules';

@Component({
  selector: 'app-applicant-page',
  templateUrl: './applicant-page.component.html',
  styleUrls: ['./applicant-page.component.scss']
})
export class ApplicantPageComponent extends BaseClass implements OnInit, OnDestroy {
  frmApplicantPage: FormGroup;
  submitted = false;
  isRetired: boolean = false;
  userType: string;
  ErrorMessageConstant = ErrorMessageConstant;
  formTypeConstants = FormTypeConstants;
  formType: string;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public entityRiskData: EntityRiskData,
    public submissionData: SubmissionPageData,
    protected authService: AuthService,
    public propertyData: PropertyData,
    public quickQuoteData: QuickQuoteData,
    public coveragesData: CoveragesData,
    public applicantData: ApplicantData,
    protected summaryData: SummaryData,
    protected quickQuoteService: QuickQuoteService,
    public navigationService: NavigationService,
    protected quickQuoteSavingData: QuickQuoteSavingData,
    public generalValidationService: GeneralValidationService,
    protected thirdPartyData: ThirdPartyData,
    protected thirdPartyDataService: ThirdPartyDataService,
    protected quoteService: QuoteService,
    public applicantSavingData: ApplicantSavingData,
    protected applicantBusinessRules: ApplicantBusinessRules,
    protected endorsementsSavingData: EndorsementsSavingData,
    protected endorsementsData: EndorsementsData,
    protected raterPremiumService: RaterPremiumService,
    public zipCodeData: ZipCodeData,
    protected claimsData: ClaimsData,
    protected store: Store,
    protected comparativeRaterData: ComparativeRaterData,
    protected claimsSavingData: ClaimsSavingData,
    protected policySummaryData: PolicySummaryData,
    protected excludedzipCodeService: ExcludeZipcodeService,
    protected getRiskStatus: GetRiskStatusPipe,
    protected uwQuestionSavingData: UwQuestionsSavingData,
    protected propertyBusinessRules: PropertyBusinessRules
  ) {
    super();
  }

  get PropertyLocationDetailsForm() {
    return this.propertyData.locationDetails;
  }

  get PropertyDetailsForm() {
    return this.propertyData.propertyDetails;
  }

  get QuickQuoteApplicantForm() {
    return this.applicantData.mainApplicant;
  }

  get QuickQuoteCoveragesForm() {
    return this.coveragesData.coveragesAndDeductiblesForm;
  }

  get CoveragesDeductiblesForm() {
    return this.coveragesData.coveragesAndDeductiblesForm;
  }

  get ApplicantInformationForm() {
    return this.applicantData.mainApplicant;
  }

  get SummaryDataForm() {
    return this.summaryData.SummaryForm;
  }

  ngOnInit() {
    this.quoteService.formTypeObs$.pipe(takeUntil(this.stop$)).subscribe((formType) => {
      this.formType = formType;
    });
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(x => {
      this.userType = x;
      this.applicantBusinessRules.updateApplicantNameValidators(x);
    });
    sessionStorage.setItem(PageSectionsValidations.CurrentPage, PathConstants.PageDestination.Quickquote);
    if (this.quoteService.formType !== this.formTypeConstants.HO4 && this.quoteService.formType !== this.formTypeConstants.HO6) {
      this.generalValidationService.resetValidatorFormControl(this.coveragesData.coveragesAndDeductiblesForm, 'coverageA', [Validators.required, Validators.min(MINCOVA), Validators.max(MAXCOVA)]);
    }

    if (this.applicantData.copiedInformation && !this.applicantData.isQQSavedForCopy) {
      this.zipCodeData.isZipcodeChangedAfterCopy = false;
      this.zipCodeData.effectiveDateChanged = '';

      this.applicantData.mainApplicant.get('firstName').setValue(this.applicantData.copiedInformation.firstName);
      this.applicantData.mainApplicant.get('lastName').setValue(this.applicantData.copiedInformation.lastName);
      this.applicantData.mainApplicant.get('middleInitial').setValue(this.applicantData.copiedInformation.middleName);
      this.applicantData.mainApplicant.get('nameSuffix').setValue(this.applicantData.copiedInformation.suffix);

      this.applicantData.mainApplicant.get('addressLine1').setValue(this.applicantData.copiedInformation.propertyAddressLine1);
      this.applicantData.mainApplicant.get('addressLine2').setValue(this.applicantData.copiedInformation.propertyAddressLine2);
      this.quickQuoteData.populateCityList(this.applicantData.copiedInformation.propertyZipCode);
      this.applicantData.mainApplicant.get('qqCity').setValue(this.applicantData.copiedInformation.propertyCity);
      this.applicantData.mainApplicant.get('qqState').setValue(this.applicantData.copiedInformation.propertyState);
      this.applicantData.mainApplicant.get('qqZipCode').setValue(this.applicantData.copiedInformation.propertyZipCode);

      this.applicantData.contact.get('emailAddress').setValue(this.applicantData.copiedInformation.emailAddress);
      this.applicantData.contact.get('mobilePhoneNumber').setValue(this.applicantData.copiedInformation.mobilePhoneNumber);
      this.applicantData.contact.get('homePhoneNumber').setValue(this.applicantData.copiedInformation.homePhoneNumber);
      this.applicantData.validAddress = this.applicantData.copiedInformation.emailAddress;
      this.applicantData.validMobilePhone = this.applicantData.copiedInformation.mobilePhoneNumber;
      this.applicantData.validHomePhone = this.applicantData.copiedInformation.homePhoneNumber;
      this.validateValue('emailAddress', this.applicantData.validAddress);
      this.validateValue('mobilePhoneNumber', this.applicantData.validMobilePhone);
      this.validateValue('homePhoneNumber', this.applicantData.validHomePhone);

      if(!this.applicantData.copiedInformation.isMailingAddressSameInsured) {
        this.QuickQuoteApplicantForm.get('isApplicantsMailingAddressSameAsInsuredLocation').setValue(false);
        this.QuickQuoteApplicantForm.get('mailingAddress').setValue(this.applicantData.copiedInformation.mailingAddressLine1);
        this.QuickQuoteApplicantForm.get('aptUnit').setValue(this.applicantData.copiedInformation.mailingAddressLine2);
        this.applicantData.populateCityList(this.applicantData.copiedInformation.mailingZipCode, PageSections.ApplicantInformation);
        this.QuickQuoteApplicantForm.get('city').setValue(this.applicantData.copiedInformation.mailingCity);
        this.QuickQuoteApplicantForm.get('state').setValue(this.applicantData.copiedInformation.mailingState);
        this.QuickQuoteApplicantForm.get('zipCode').setValue(this.applicantData.copiedInformation.mailingZipCode);
        this.QuickQuoteApplicantForm.get('country').setValue(this.applicantData.copiedInformation.mailingCountry);
      }

      let isMoratoriumAPICalled: boolean = false;
      if (this.authService.userType.value === GenericConstants.userType.external) {
        this.authService.isTaskLoadedAfterCopy$.pipe(takeUntil(this.stop$), distinctUntilChanged()).subscribe(result => {
          if (!isMoratoriumAPICalled) {
            this.callMoratoriumNewQuote();
            isMoratoriumAPICalled = true;
          }
        });
      } else {
        this.callMoratoriumNewQuote();
        isMoratoriumAPICalled = true;
      }
    }

    if (!this.quickQuoteData.isQQHasErrorMessage) {
      this.fetchFloodExcludedZipCode();
    }
    this.subscribeToInitFormsComplete();
    this.coveragesData.relatedRisks = [];
  }

  callMoratoriumNewQuote(): void {
    this.zipCodeData.displayMoratoriumNewQuote(
      this.applicantData.copiedInformation.propertyZipCode,
      this.applicantData.copiedInformation.propertyState,
      this.QuickQuoteApplicantForm,
      null, true,
      this.applicantData.copiedInformation);
  }

  get isRenewalOffered(): boolean {
    return (this.policySummaryData.renewalStatusCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RWO ||
      this.policySummaryData.renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RWO) ||
      (this.policySummaryData.renewalStatusCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO ||
      this.policySummaryData.renewalCode === PageHeaderSummaryConstants.policyRenewal.statusCode.RRWO);
  }

  calculateDiff(sentDate) {
    const date1: any = sentDate;
    const date2: any = this.authService.getCustomDate();
    const diffDays: any = Math.floor((date1 - date2) / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  get disableFormFields(): boolean {

    const renewalEffectiveDate = new Date(this.entityRiskData.EntityRisk?.risks[0]?.renewalEffectiveDate);
    const diffDays = this.calculateDiff(renewalEffectiveDate);

    if (this.isRenewalOffered &&
        this.authService.userType.value === GenericConstants.userType.external &&
        diffDays < 2) {
      return true;
    }

    return this.submissionData.disableQuoteForms;
  }

  protected validateValue(fieldName: string, validValue: string){
    this.applicantData.contact.get(fieldName).setValue((validValue) ? validValue : null);
  }

  async onSubmit(clickType?) {
    switch (clickType) {
      case ClickTypes.Back:
        // to do logic for back
        break;
      case ClickTypes.Next:
        if (!!!this.summaryData.SummaryForm.get('entityId').value) {
          Utils.blockUI();
          this.createSubmission();
        } else {
          this.navigationService.navigateRoute(PathConstants.PageDestination.Coverages);
        }

        break;
    }
  }

  isQuickQuoteValid(): boolean {
    return (this.QuickQuoteApplicantForm.get('firstName').valid && this.QuickQuoteApplicantForm.get('lastName').valid &&
      this.QuickQuoteApplicantForm.get('qqZipCode').valid && this.QuickQuoteApplicantForm.get('addressLine1').valid &&
      this.QuickQuoteApplicantForm.get('dateOfBirth').valid && this.QuickQuoteApplicantForm.get('effectiveDate').valid &&
      this.QuickQuoteApplicantForm.get('middleInitial').valid);
  }

  isPropertyValid(): boolean {
    const protectionClass = this.PropertyLocationDetailsForm.get('protectionClass');
    const isFlatTile = this.PropertyDetailsForm.get('isFlatTile');
    const propertyValid = (this.PropertyDetailsForm.get('yearBuilt').valid && this.PropertyDetailsForm.get('exteriorWallFinish').valid &&
      this.PropertyDetailsForm.get('constructionType').valid && this.PropertyDetailsForm.get('squareFootage').valid &&
      this.PropertyDetailsForm.get('roofMaterial').valid && this.PropertyDetailsForm.get('yearRoof').valid &&
      !protectionClass.invalid);

    if (this.PropertyDetailsForm.get('roofMaterial').value.indexOf('TI') !== -1) {
      return propertyValid && (isFlatTile.valid || !isFlatTile.value);
    }
    return propertyValid;
  }

  isCoveragesValid(): boolean {
    if (this.formType === this.formTypeConstants.HO4) {
      this.generalValidationService.clearValidatorFormControl(this.CoveragesDeductiblesForm, 'coverageA');
      return (this.CoveragesDeductiblesForm.get('coverageC').valid &&
        this.CoveragesDeductiblesForm.get('allOtherPerilsId').valid &&
        this.validateHurricaneDeductible());
    }
    if (this.formType === this.formTypeConstants.HO6 ) {
      return (this.CoveragesDeductiblesForm.get('coverageA').valid && this.CoveragesDeductiblesForm.get('coverageC').valid &&
        this.CoveragesDeductiblesForm.get('allOtherPerilsId').valid &&
        this.validateHurricaneDeductible());
    }

    return (this.CoveragesDeductiblesForm.get('coverageA').valid &&
      this.CoveragesDeductiblesForm.get('allOtherPerilsId').valid &&
      this.validateHurricaneDeductible());
  }

  validateHurricaneDeductible(): boolean {
    // NOTE: Hurricane deductible is valid when it is set to excluded,
    // where distance to water < 1000 or distance to coast is < 1000 from ISO Location API Response.
    return (this.CoveragesDeductiblesForm.get('hurricaneDeductibleid').valid ||
      (this.CoveragesDeductiblesForm.get('hurricaneDeductibleid').value === 'E' &&
      this.CoveragesDeductiblesForm.get('hurricaneDeductibleid').status === 'DISABLED')) &&
      this.coveragesData.IsValidHurricaneDeductible;
  }

  isQuickQuotePageValid(): boolean {
    return (this.isQuickQuoteValid() && this.isPropertyValid() && this.isCoveragesValid());
  }

  isOrderPrefillClicked(): boolean {
    let noPrefillError: boolean = true;
    if (!this.quickQuoteData.orderPrefill) {
      noPrefillError = !this.submissionData.isOrder && this.isQuickQuoteValid() ? false : noPrefillError;
    }
    return noPrefillError;
  }

  setValidPropertyValues(): void {
    // Can add more fields to set a default valid value if property section becomes invalid. See Centauri 831.
    if (!this.isPropertyValid()) {
      Object.keys(this.PropertyDetailsForm.controls).forEach(key => {
        switch (key) {
          case QuickQuoteFormConstant.Property.YearBuilt:
            if (!this.PropertyDetailsForm.get(QuickQuoteFormConstant.Property.YearBuilt).valid && this.propertyData.validYearBuilt) {
              this.PropertyDetailsForm.get(QuickQuoteFormConstant.Property.YearBuilt).setValue(this.propertyData.validYearBuilt);
            }
            break;
          case QuickQuoteFormConstant.Property.YearRoof:
            if (!this.PropertyDetailsForm.get(QuickQuoteFormConstant.Property.YearRoof).valid && this.propertyData.validYearRoof) {
              this.PropertyDetailsForm.get(QuickQuoteFormConstant.Property.YearRoof).setValue(this.propertyData.validYearRoof);
            }
            break;
          default:
            break;
        }
      });
    }
  }

  isQuickQuoteApplicantInformationHasBlank(): boolean {
    return (this.checkIfBlankValue(this.QuickQuoteApplicantForm, 'firstName') ||
      this.checkIfBlankValue(this.QuickQuoteApplicantForm, 'lastName') ||
      this.checkIfBlankValue(this.QuickQuoteApplicantForm, 'qqZipCode') ||
      this.checkIfBlankValue(this.QuickQuoteApplicantForm, 'addressLine1') ||
      this.checkIfBlankValue(this.QuickQuoteApplicantForm, 'dateOfBirth') ||
      this.checkIfBlankValue(this.QuickQuoteApplicantForm, 'effectiveDate'));
  }

  isQuickQuotePropertyInformationHasBlank(): boolean {
    return (this.checkIfBlankValue(this.PropertyLocationDetailsForm, 'protectionClass') ||
      this.checkIfBlankValue(this.PropertyDetailsForm, 'yearBuilt') ||
      this.checkIfBlankValue(this.PropertyDetailsForm, 'exteriorWallFinish') ||
      this.checkIfBlankValue(this.PropertyDetailsForm, 'constructionType') ||
      this.checkIfBlankValue(this.PropertyDetailsForm, 'squareFootage') ||
      this.checkIfBlankValue(this.PropertyDetailsForm, 'roofMaterial') ||
      this.checkIfBlankValue(this.PropertyDetailsForm, 'yearRoof'));
  }

  isQuickQuoteCoveragesInformationHasBlank(): boolean {
    return (this.checkIfBlankValue(this.CoveragesDeductiblesForm, 'coverageA') ||
      this.checkIfBlankValue(this.CoveragesDeductiblesForm, 'allOtherPerilsId') ||
      this.checkIfBlankValue(this.CoveragesDeductiblesForm, 'hurricaneDeductibleid'));
  }

  checkIfBlankValue(formGroup?, formControlName?): boolean {
    return formGroup.get(formControlName).value === '';
  }

  markAsTouched(formGroup?: FormGroup, formControlName?): void {
    formGroup.get(formControlName).markAsTouched();
  }

  markAllQuickQuoteSectionAsTouched(): void {
    this.markAsTouched(this.QuickQuoteApplicantForm, 'firstName');
    this.markAsTouched(this.QuickQuoteApplicantForm, 'lastName');
    this.markAsTouched(this.QuickQuoteApplicantForm, 'qqZipCode');
    this.markAsTouched(this.QuickQuoteApplicantForm, 'addressLine1');
    this.markAsTouched(this.QuickQuoteApplicantForm, 'dateOfBirth');
    this.markAsTouched(this.QuickQuoteApplicantForm, 'effectiveDate');
    this.markAsTouched(this.PropertyLocationDetailsForm, 'protectionClass');
    this.markAsTouched(this.PropertyDetailsForm, 'yearBuilt');
    this.markAsTouched(this.PropertyDetailsForm, 'exteriorWallFinish');
    this.markAsTouched(this.PropertyDetailsForm, 'constructionType');
    this.markAsTouched(this.PropertyDetailsForm, 'squareFootage');
    this.markAsTouched(this.PropertyDetailsForm, 'roofMaterial');
    this.markAsTouched(this.PropertyDetailsForm, 'yearRoof');
    this.markAsTouched(this.CoveragesDeductiblesForm, 'coverageA');
    this.markAsTouched(this.CoveragesDeductiblesForm, 'allOtherPerilsId');
    this.markAsTouched(this.CoveragesDeductiblesForm, 'hurricaneDeductibleid');
  }

  createSubmission(): void {
    this.quickQuoteSavingData.markQuickQuoteAsCreating();
    this.quickQuoteSavingData.setApplicantPage();
    this.quickQuoteSavingData.setHeaderDetails();
    if (this.comparativeRaterData.isFromComparativeRater) {
      this.comparativeRaterData.mapCoverageFlood(this.applicantData.mainApplicant);
      if (!this.applicantData.mainApplicant.get('isOrderYesNo').value && !this.coveragesData?.isOrderCreditSuccessful) {
        this.comparativeRaterData.mapCreditRatingFromComparative();
      }
    }
    const quickQuoteRequest = this.quickQuoteSavingData.getQuickQuotePayload(this.comparativeRaterData.isFromComparativeRater);
    const creationSubscription$: any = this.comparativeRaterData.isFromComparativeRater
      ? this.quickQuoteService.postComparative(quickQuoteRequest) : this.quickQuoteService.postQuickQuote(quickQuoteRequest);

    creationSubscription$.pipe(takeUntil(this.stop$)).subscribe(res => { // to follow subscription clean-up. needs to be move to a service
      this.quickQuoteSavingData.finishQuickQuoteCreating();
      const result = <CreateQuickQuoteResultDTO>res;
      this.SummaryDataForm.get('riskId').setValue(result.riskId);
      this.SummaryDataForm.get('riskDetailId').setValue(result.riskDetailId);
      this.SummaryDataForm.get('entityId').setValue(result.entityId);
      this.SummaryDataForm.get('quoteNumber').setValue(result.quoteNumber);
      this.quickQuoteSavingData.requestRaterCalculation(true);
      this.thirdPartyData.resetAPlusReportRecord();
      this.submissionData.quoteStatus.next(this.submissionData.getQuoteStatus(result.riskStatusCode));

      if (this.policySummaryData.isPolicy) {
        this.store.dispatch(updatePolicyStatusFromApplicantPage({ status: this.getRiskStatus.transform(result.riskStatusCode) }));
        this.store.dispatch(updatePolicySubStatusFromApplicantPage({ subStatus: this.getRiskStatus.transform(result.riskSubStatusCode) }));
      } else {
        this.store.dispatch(updateSubmissionStatusFromApplicantPage({ status: this.submissionData.getQuoteStatus(result.riskStatusCode) }));
      }

      this.endorsementsData.currentLimitedWaterDmg = null;
      if (this.comparativeRaterData.isFromComparativeRater){
        this.comparativeRaterData.mapEndorsementsFromComparative(this.applicantData.mainApplicant);
        this.comparativeRaterData.mapApplicantFromComparative(this.applicantData.mainApplicant,
            this.applicantData.contact,
            this.applicantData.coApplicant,
            this.zipCodeData.applicantInsuredCityList,
            this.zipCodeData.coApplicantCityList);
        this.comparativeRaterData.mapClaims(this.applicantData.mainApplicant);
      } else { // Check WaterDamageExclusion BRUL only if not from comparative rater
        this.endorsementsData.setWaterDamageExclusion();
      }
      this.triggerPropertyBRULs();
      this.endorsementsData.setUnitOwnersValue();
      this.endorsementsSavingData.postEndorsements(result.riskId, result.riskDetailId);
      localStorage.setItem('entityId', result.entityId);
      if (this.applicantData.copiedInformation || this.comparativeRaterData.isFromComparativeRater) {
        this.applicantSavingData.saveApplicant();
      }
      if (this.comparativeRaterData.isFromComparativeRater && (this.claimsData.claimList && this.claimsData.claimList?.length > 0)) {
        this.claimsSavingData.markClaimsForSaving();
        this.claimsSavingData.saveClaims(this.comparativeRaterData.isFromComparativeRater);
      }
      this.uwQuestionSavingData.saveUWQuestions();
      Utils.unblockUI();
      this.navigateAfterCreate(result);
      this.entityRiskData.isFromQQSaveMoratorium = true;
      this.entityRiskData.isFromQQSaveCommissionRate = true;
      this.applicantData.isQQSavedForCopy = true;
      this.applicantData.copiedAgencyInformation = null;
      localStorage.removeItem('comparativeRater');
    },
    err => {
      this.quickQuoteSavingData.finishQuickQuoteCreating();
      Utils.unblockUI();
      console.log(err);
      NotifUtils.showError(JSON.parse(err.error).errors.toString());
      // add code here for error log
    }, () => {
      // Reset this to false, either a success or failing HTTP response
      this.summaryData.isUpdatedInsuredProfileOrPrefill = false;
    });
    this.quickQuoteSavingData.coverageAAndHurricaneDeductibleBRUL();
  }

  ngOnDestroy() {
    if ((this.router.url !== '/dashboard') && (this.router.url !== '/login')) {
      if (!this.isQuickQuotePageValid()) {
        if (this.isOrderPrefillClicked()) {
          if (this.isQuickQuoteApplicantInformationHasBlank() || this.isQuickQuotePropertyInformationHasBlank() || this.isQuickQuoteCoveragesInformationHasBlank()) {
            this.showQuickQuoteError(GenericLabel.qqRequiredFieldsMessage);
            this.markAllQuickQuoteSectionAsTouched();
          } else {
            this.showQuickQuoteError(GenericLabel.qqInvalidFieldsMessage);
            this.markAllQuickQuoteSectionAsTouched();
          }
        } else {
          this.showQuickQuoteError(GenericLabel.orderPrefillButtonMessage);
        }
        this.navigationService.navigateRoute(PathConstants.PageDestination.Applicant, false, true);
        this.setValidPropertyValues();
      } else {
        if (!!!this.summaryData.SummaryForm.get('riskId').value && !this.quickQuoteSavingData.checkIfQuickQuoteIsCreating()) {
          this.showQuickQuoteError(GenericLabel.quickQuoteConfirmation);
          this.navigationService.navigateRoute(PathConstants.PageDestination.Applicant, false, true);
        }
      }
    } else {
      localStorage.removeItem('comparativeRater');
      this.quickQuoteData.isQQHasErrorMessage = false;
    }
    this.applicantData.copiedInformation = null;
  }

  orderCredit(successCallback: (value: CreditScoreRiskResponseDTO) => void,
  errorCallback: (error: any) => void,
  completeCallback: () => void): void {
    const previousValues = this.applicantSavingData.previousApplicantValues?.Applicant;
    const currentFirstName = this.applicantSavingData.applicantData.mainApplicant.get('firstName').value;
    const currentMiddleInitial = this.applicantSavingData.applicantData.mainApplicant?.get('middleInitial').value === null ? null : this.applicantSavingData.applicantData.mainApplicant?.get('middleInitial').value?.trim();
    const currentLastName = this.applicantSavingData.applicantData.mainApplicant.get('lastName').value;
    const currentDateOfBirth = this.applicantSavingData.applicantData.mainApplicant.get('dateOfBirth').value?.singleDate?.jsDate;
    const isOrderYesNo = this.applicantSavingData.applicantData.mainApplicant.get('isOrderYesNo').value;
    const isFCRAChecked = this.applicantData.fcraReportingActForm.get('fcraReportingAct').value;
    if ((previousValues?.firstName !== currentFirstName ||
      previousValues?.middleInitial !== currentMiddleInitial ||
      previousValues?.lastName !== currentLastName ||
      previousValues?.dateOfBirth.toLocaleDateString('en-US') !== currentDateOfBirth.toLocaleDateString('en-US'))
      && (isOrderYesNo || isFCRAChecked)
      ) {
        const creditRequest = this.thirdPartyData.getOrderCreditRequestModel();
        this.thirdPartyDataService.postOrderCredit(creditRequest).subscribe(successCallback,
          errorCallback,
          completeCallback);
    }
  }

  hasUpdateInApplicantNameOrBirthDate() {
    const previousValues = this.applicantSavingData.previousApplicantValues?.Applicant;
    const currentFirstName = this.applicantSavingData.applicantData.mainApplicant?.get('firstName').value?.trim();
    const currentMiddleInitial = this.applicantSavingData.applicantData.mainApplicant?.get('middleInitial').value === null ? null : this.applicantSavingData.applicantData.mainApplicant?.get('middleInitial').value?.trim();
    const currentLastName = this.applicantSavingData.applicantData.mainApplicant?.get('lastName').value?.trim();
    const currentDateOfBirth = this.applicantSavingData.applicantData.mainApplicant?.get('dateOfBirth').value?.singleDate?.jsDate;
    if (previousValues?.firstName !== currentFirstName ||
      previousValues?.middleInitial !== currentMiddleInitial ||
      previousValues?.lastName !== currentLastName ||
      previousValues?.dateOfBirth.toLocaleDateString('en-US') !== currentDateOfBirth.toLocaleDateString('en-US')) {
        return true;
      } else {
        return false;
      }
  }

  navigateAfterCreate(result): void {
    if (this.comparativeRaterData.isFromComparativeRater) {
      if (this.checkIfComparativeRaterDoneSaving()) {
        this.navigationService.navigateRoute(PathConstants.PageDestination.Coverages, false, false, result.riskId, result.riskDetailId);
      } else {
        setTimeout(() => { this.navigateAfterCreate(result); }, 100);
      }
    } else {
      this.navigationService.navigateRoute(PathConstants.PageDestination.Coverages, false, false, result.riskId, result.riskDetailId);
    }
  }

  checkIfComparativeRaterDoneSaving(): boolean {
    return !this.quickQuoteService.isEndorsementsSaving
      && !this.quickQuoteService.isClaimsSaving
      && !this.quickQuoteService.isApplicantSaving;
  }

  protected subscribeToInitFormsComplete(): void {
    this.submissionData.initFormsComplete$.pipe(takeUntil(this.stop$)).subscribe(result => {
      if (result) {
        this.subscribeToYearBuilt();
      }
    });
  }

  protected subscribeToYearBuilt(): void {
    this.propertyData.propertyDetails.get('yearBuilt').valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
      if (this.quoteService.formType !== this.formTypeConstants.HO4) {
        this.PropertyDetailsForm.get('yearRoof').setValidators([Validators.required, Validators.min(1800),
        PropertyValidators.yearRoofShouldNotBeOlderThanYearBuiltValidator(value),
        PropertyValidators.yearRoofInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);

        this.propertyData.propertyDetails.get('yearElectrical').setValue(this.propertyYearSetter(value, 'yearElectrical'));
        this.PropertyDetailsForm.get('yearElectrical').setValidators([Validators.required, Validators.min(1800),
        PropertyValidators.yearShouldNotBeOlderThanYearBuiltValidator(value),
        PropertyValidators.yearInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);

        this.propertyData.propertyDetails.get('yearPlumbing').setValue(this.propertyYearSetter(value, 'yearPlumbing'));
        this.PropertyDetailsForm.get('yearPlumbing').setValidators([Validators.required, Validators.min(1800),
        PropertyValidators.yearShouldNotBeOlderThanYearBuiltValidator(value),
        PropertyValidators.yearInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);

        this.propertyData.propertyDetails.get('yearHeating').setValue(this.propertyYearSetter(value, 'yearHeating'));
        this.PropertyDetailsForm.get('yearHeating').setValidators([Validators.required, Validators.min(1800),
        PropertyValidators.yearShouldNotBeOlderThanYearBuiltValidator(value),
        PropertyValidators.yearInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);

        if (this.propertyData.propertyDetails.get('isCentralAirConditioning').value) {
          this.propertyData.propertyDetails.get('yearCentralAirConditioning').setValue(this.propertyYearSetter(value, 'yearCentralAirConditioning'));
          this.PropertyDetailsForm.get('yearCentralAirConditioning').setValidators([Validators.required, Validators.min(1800),
          PropertyValidators.yearShouldNotBeOlderThanYearBuiltValidator(value),
          PropertyValidators.yearInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);
          this.propertyData.validYearAircon = this.propertyData.propertyDetails.get('yearCentralAirConditioning').value;
        }
      } else {
        this.PropertyDetailsForm.get('yearRoof').setValidators([]);
        this.PropertyDetailsForm.get('yearElectrical').setValidators([]);
        this.PropertyDetailsForm.get('yearPlumbing').setValidators([]);
        this.PropertyDetailsForm.get('yearHeating').setValidators([]);
        this.PropertyDetailsForm.get('yearCentralAirConditioning').setValidators([]);
      }

      if (this.quoteService.formType !== this.formTypeConstants.HO4 && (value !== '')) {
        if (!this.checkYearLength(value?.toString())) {
          return;
        }
      }
    });

    this.propertyData.propertyDetails.get('yearRoof').updateValueAndValidity();
    this.propertyData.propertyDetails.get('yearBuilt').updateValueAndValidity();
    this.propertyData.propertyDetails.get('yearElectrical').updateValueAndValidity();
    this.propertyData.propertyDetails.get('yearPlumbing').updateValueAndValidity();
    this.propertyData.propertyDetails.get('yearHeating').updateValueAndValidity();
    this.propertyData.propertyDetails.get('yearCentralAirConditioning').updateValueAndValidity();
  }

  protected checkYearLength(value: string): boolean {
    const validYearLength: number = 4;
    return value?.toString()?.trim().length === validYearLength;
  }

  protected propertyYearSetter(yearBuilt: number, formName: string): any {
    const field = this.propertyData?.propertyDetails?.get(formName)?.value;
    if (field) {
      if (this.propertyData.isYearBuiltQQChange) {
        return yearBuilt;
      } else {
        return field;
      }
    } else {
      return yearBuilt;
    }
  }

  fetchFloodExcludedZipCode(): void {
    this.store.dispatch(updateAppIsLoadingFromApplicantPageComponent({ isLoading: true }));

    this.excludedzipCodeService.getFloodExcludedZipCodeList().pipe(takeUntil(this.stop$)).subscribe(result => {
      this.coveragesData.floodCoverageExcludedZipCode = result.map(a => Number(a.zipCode));
      this.store.dispatch(updateAppIsLoadingFromApplicantPageComponent({ isLoading: false }));
    },
    err => {
      this.store.dispatch(updateAppIsLoadingFromApplicantPageComponent({ isLoading: false }));
      NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
    });
  }

  showQuickQuoteError(errorMessage: string): void {
    this.quickQuoteData.isQQHasErrorMessage = true;
    NotifUtils.showError(errorMessage, () => {
      this.fetchFloodExcludedZipCode();
    });
  }

  triggerPropertyBRULs(): void {
    if (this.entityRiskData.isNewQuote) {
      this.propertyBusinessRules.triggerActiveNew115();
      this.propertyBusinessRules.triggerBRUL351();
    }
  }
}
