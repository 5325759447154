import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'disableTransferAllCheckbox'
})
export class DisableTransferAllCheckboxPipe implements PipeTransform {
  transform(
    toTransferPolicies: any,
    isBatchStatusCompleted: boolean,
    isToTransferSearch: boolean
  ): boolean {
    return toTransferPolicies.length === 0 || isBatchStatusCompleted || isToTransferSearch;
  }
}
