import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommissionStatementConstant } from 'app/shared/constants/commission-statement.constants';
import { DefaultView, IAngularMyDpOptions } from 'angular-mydatepicker';
import { CommissionStatementData } from '../../data/commission-statement.data';
import { ErrorMessageConstant } from '../../../../shared/constants/error-message.constants';
import { CommissionStatementDTO } from 'app/shared/models/data/dto/nacha/commission-statement.dto';

@Component({
  selector: 'app-archieved-commission-statement',
  templateUrl: './archieved-commission-statement.component.html',
  styleUrls: ['./archieved-commission-statement.component.scss']
})
export class ArchievedCommissionStatementComponent implements OnInit {

  CommissionStatementConstant = CommissionStatementConstant;
  ErrorMessageConstant = ErrorMessageConstant;

  purchaseDate: Date;
  purchaseDateYear: number;
  purchaseDateOption: IAngularMyDpOptions;
  modelDate = '';

  minDate = new Date(2017, 5, 10);
  maxDate = new Date(2018, 9, 15);
  bsValue: Date = new Date();

  get archiveForm(): FormGroup {
    return this.commissionStatementData.archivedCommForm;
  }

  constructor(
    public commissionStatementData: CommissionStatementData
  ) {

  }

  ngOnInit() {
    this.commissionStatementData.initializeArchivedCommForm();
    this.commissionStatementData.priorCommissionStatementArchivedList = [];
    this.commissionStatementData.setPriorArchivePageItem(1);
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  onDateChanged(date: any): void {
    if (date?.jsDate !== null) {
      this.purchaseDate = date?.jsDate;
      this.purchaseDateYear = this.purchaseDate.getFullYear();
      return;
    }
  }

  onInputFieldChanged(date: any): void {
    if (date?.valid) {
      this.purchaseDate = new Date(date?.value);
      this.purchaseDateYear = this.purchaseDate.getFullYear();
      return;
    }
  }

  fetchPriorCommissionArchived(): void {
    this.commissionStatementData.isArchivedCommissionLoading = true;
    const dateFrom = this.archiveForm.get('dateFrom')?.value;
    const dateTo = this.archiveForm.get('dateTo')?.value;

    if (dateFrom === null) {
      this.archiveForm.get('dateFrom').markAsTouched();
    }
    if (dateTo === null) {
      this.archiveForm.get('dateTo').markAsTouched();
    }

    if (dateFrom && dateTo && this.archiveForm.valid && !this.isValidDateRange()) {
      const dateFromRequest = `${('0' + (dateFrom.getMonth() + 1)).slice(-2)}/${dateFrom.getFullYear()}`;
      const dateToRequest = `${('0' + (dateTo.getMonth() + 1)).slice(-2)}/${dateTo.getFullYear()}`;
      this.commissionStatementData.searchPriorCommissionStatementArchived(dateFromRequest, dateToRequest);
    }
  }

  isValidDateRange(): boolean {
    const dateFrom = this.archiveForm.get('dateFrom')?.value as Date;
    const dateTo = this.archiveForm.get('dateTo')?.value as Date;

    if(dateFrom !== null && dateTo !== null) {
      if(dateFrom.getTime() > dateTo.getTime()) {
        return true;
      }
    }
    return false;
  }

  isEmptyPriorArchivePageItems(): boolean {
    return this.commissionStatementData?.priorCommissionStatementPageItem?.length === 0;
  }

  selectItem(data: CommissionStatementDTO): void {
    const index = this.commissionStatementData.priorCommissionStatementPageItem.indexOf(data);
    this.commissionStatementData.priorCommissionStatementPageItem[index].isChecked = !data.isChecked;
  }

  selectAllItem(status: boolean): void {
    this.commissionStatementData.priorCommissionStatementPageItem.forEach(element => {
      element.isChecked = status;
    });
  }

  checkIfAllSelected(): boolean {
    if (this.commissionStatementData?.priorCommissionStatementPageItem?.length !== 0) {
      return this.commissionStatementData?.priorCommissionStatementPageItem?.filter(a => !a.isChecked).length === 0;
    }
    return false;
  }
}
