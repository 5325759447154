import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BillingService } from 'app/core/services/billing/billing.service';
import { CommonService } from 'app/core/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class LA_BillingService extends BillingService {

  constructor(
    protected http: HttpClient,
    protected commonService: CommonService
  ) {
    super(
      http,
      commonService
    );
  }
}
