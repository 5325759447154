import { Injectable } from '@angular/core';
import { CoveragesData } from '../../../modules/submission-management/data/coverages.data';
import { BaseComponent } from '../../../shared/base-component';
import { PathConstants } from '../../../shared/constants/path.constants';
import { ClaimsSavingData } from '../../../modules/submission-management/data/claims-saving.data';
import { InterestSavingData } from '../../../modules/submission-management/data/interest-saving.data';
import { UwApprovalSavingData } from '../../../modules/submission-management/data/uw-approval-saving.data';
import { EndorsementsSavingData } from '../../../modules/submission-management/data/endorsements.saving.data';
import { PropertySavingData } from '../../../modules/submission-management/data/property.saving.data';
import { ApplicantSavingData } from '../../../modules/submission-management/data/applicant.saving.data';
import { PolicyNotesData } from '../../../modules/policy-management/data/policy-notes.data';
import { EntityRiskData } from '../../../modules/submission-management/data/entity-risk.data';
import { UWR } from '../submission/uw-referrals/uwr.service';
import { EndorsementsData } from '../../../modules/submission-management/data/endorsements.data';
import { FormTypeConstants } from '../../../shared/constants/form-types.constants';
import { SummaryData } from '../../../modules/submission-management/data/summary.data';
import { PropertyData } from '../../../modules/submission-management/data/property.data';
import { UwApprovalData } from '../../../modules/submission-management/data/uw-approval.data';
import { ClaimsData } from 'app/modules/submission-management/data/claims.data';
import { ClaimsChargeableService } from '../submission/claims/claims-chargeable.service';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { QuickQuoteData } from 'app/modules/submission-management/data/quick-quote.data';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PolicyNavSavingService extends BaseComponent {
  formType: string;
  propertyUsage: string;
  formTypeConstants = FormTypeConstants;

  constructor(
    public coveragesData: CoveragesData,
    public claimsSavingData: ClaimsSavingData,
    public interestSavingData: InterestSavingData,
    protected uwApprovalSavingData: UwApprovalSavingData,
    public endorsementsSavingData: EndorsementsSavingData,
    public propertySavingData: PropertySavingData,
    protected applicantSavingData: ApplicantSavingData,
    protected policyNotesData: PolicyNotesData,
    public entityData: EntityRiskData,
    protected uwrService: UWR,
    protected endorsementData: EndorsementsData,
    protected summaryData: SummaryData,
    protected propertyData: PropertyData,
    protected uwApprovalData: UwApprovalData,
    protected entityRiskData: EntityRiskData,
    protected claimsData: ClaimsData,
    protected claimsChargeableService: ClaimsChargeableService,
    protected policySummaryData: PolicySummaryData,
    protected quickQuoteData: QuickQuoteData
  ) {
    super();
  }

  public saveCurrentCategory(currentCategoryRoute: string): void {
    try {
      this.uwrService.getPolicyUwrReferrals();
      this.resetNavOutSavingCounters();

      switch (currentCategoryRoute.toLowerCase()) {
        case PathConstants.Policy.Policies.Summary:
          break;
        case PathConstants.Policy.Policies.History:
          break;
        case PathConstants.Policy.Policies.Billing:
          break;
        case PathConstants.Policy.Policies.Documents:
          break;
        case PathConstants.Policy.Policies.Notes:
          // Comment this code CNTSUP-186 Save notes upon click save button
          // this.policyNotesData.saveNotes();
          break;
        case PathConstants.Policy.Policies.Tasks:
          break;
        case PathConstants.Policy.Policies.CoveragesAndDeduction:
          this.saveCoverages();
          this.checkClaimsChargeability();
          this.saveEndorsements(false);
          this.updateUwApprovalTableRows();
          break;
        case PathConstants.Policy.Policies.Endorsements:
          this.coveragesData.isFromCoverages = false;
          this.checkClaimsChargeability();
          this.endorsementsSavingData.saveEndorsements(true);
          this.updateUwApprovalTableRows();
          break;
        case PathConstants.Policy.Policies.Claims:
          this.claimsSavingData.savePolicyClaims();
          if (this.endorsementData.isStarPackageChanged) {
            this.endorsementsSavingData.saveEndorsements();
            this.endorsementData.isStarPackageChanged = false;
          }
          this.updateUwApprovalTableRows();
          break;
        case PathConstants.Policy.Policies.Property:
          this.propertySavingData.saveProperty();
          this.updateUwApprovalTableRows();
          break;
        case PathConstants.Policy.Policies.ApplicantPage:
          if (this.policySummaryData.isPolicy) {
            this.applicantSavingData.saveApplicant();
            this.quickQuoteData.getPolicyChangesForSeniorRetiree();
          }
          break;
        case PathConstants.Policy.Policies.UWQuestions:
          break;
        case PathConstants.Policy.Policies.Interests:
          this.interestSavingData.saveInterest();
          this.updateUwApprovalTableRows();
          break;
        case PathConstants.Policy.Policies.UWApproval:
          this.updateUwApprovalTableRows();
          // Comment this code CNTSUP-186 Save notes upon click save button
          // this.policyNotesData.saveNotes();
          break;
        case PathConstants.Policy.Policies.Issue:
          break;
        default:
          return;
      }
    } catch (e) {
      this.entityData.finishSavingForms();
    }
  }

  updateUwApprovalTableRows(): void {
    this.uwApprovalData.initUWRData();
    this.uwApprovalData.checkPendingItems();
  }

  saveCoverages(): void {
    this.formType = this.summaryData.SummaryForm.get('formType')?.value;
    this.propertyUsage = this.propertyData.locationDetails.get('propertyUsage')?.value;
    this.coveragesData.isFromCoverages = true;

    if (this.formType === FormTypeConstants.DP3) {
      this.coveragesData.saveCoverages(false, false, false, this.propertyUsage);
      return;
    }

    this.coveragesData.saveCoverages();
  }

  saveEndorsements(saveCoverages: boolean = true): void {
    this.formType = this.summaryData.SummaryForm.get('formType')?.value;
    this.propertyUsage = this.propertyData.locationDetails.get('propertyUsage')?.value;
    this.coveragesData.isFromCoverages = false;
    if (this.formType !== this.formTypeConstants.DP3) {
      this.endorsementsSavingData.saveEndorsements(saveCoverages);
      return;
    }

    this.endorsementsSavingData.saveEndorsements(saveCoverages, this.propertyUsage);
  }

  saveInspectionOrdering(): void{
    this.applicantSavingData.saveInspection();
  }

  get effectiveDate(): Date {
    let effectiveDateUI = this.summaryData.SummaryForm.get('effectiveDate').value;
    effectiveDateUI = typeof effectiveDateUI === 'object' ? new Date(this.summaryData.SummaryForm.get('effectiveDate').value?.singleDate?.formatted) : new Date(this.summaryData.SummaryForm.get('effectiveDate').value);

    return effectiveDateUI;
  }

  checkClaimsChargeability(): void {
    if (this.claimsChargeableService.checkIfClaimTypeConditionalFieldDirty()
      && this.claimsData.claimList?.length > 0) {
      this.claimsSavingData.markClaimsForSaving();
      this.claimsChargeableService.checkClaimsChargeabilityRule(this.effectiveDate);
      this.claimsSavingData.savePolicyClaims();
    }
  }

  checkApplicantAgeIfRetiree(): void {
    const dateOfBirth = new Date(this.applicantSavingData.applicantData.mainApplicant.get('dateOfBirth').value?.singleDate?.jsDate?.toLocaleDateString('en-US'));
    const age = this.applicantSavingData.applicantBusinessRules.calculateInsuredAge(dateOfBirth);
    if (age < 55) {
      this.applicantSavingData.applicantData.mainApplicant.get('isRetiredYesNo').setValue(false);
      this.coveragesData.discountsForm.get('seniorRetiree').setValue(false);
    }
  }

  resetNavOutSavingCounters(): void {
    const policyNavOutSavingCounters: string[] = [
      'navOutCoveragesSavingCounter$',
      'navOutEndorsementsSavingCounter$',
      'navOutClaimsSavingCounter$',
      'navOutPropertySavingCounter$',
      'navOutApplicantSavingCounter$',
      'navOutInterestsSavingCounter$',
      'navOutUwApprovalSavingCounter$'
    ];

    policyNavOutSavingCounters.forEach(navOutSavingCounter => {
      this.policySummaryData[navOutSavingCounter].next(0);
    });
  }
}
