import { Injectable } from '@angular/core';
import Utils from '../../../shared/utilities/utils';
import { QuickQuoteService } from '../../../core/services/submission/quick-quote.service';
import { BaseClass } from '../../../shared/base-class';
import { SummaryData } from './summary.data';
import { CreateQuickQuoteResultDTO } from '../../../shared/models/data/dto/quick-quote/create-quick-quote-result.dto';
import { EntityRiskData } from './entity-risk.data';
import { SubmissionPageData } from './submission-page.data';
import { PropertyData } from './property.data';
import { QuickQuoteData } from './quick-quote.data';
import { CoveragesData, MAXCOVA, MINCOVA } from './coverages.data';
import { ApplicantData } from './applicant.data';
import { ThirdPartyData } from './third-party.data';
import { Iso360ValueMappingData } from './iso360value-mapping.data';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessageConstant } from '../../../shared/constants/error-message.constants';
import { EntityQuickQuoteDTO } from '../../../shared/models/data/dto/quick-quote/entity-quick-quote.dto';
import { EntityAddressQuickQuoteDTO } from '../../../shared/models/data/dto/quick-quote/entity-address-quick-quote.dto';
import { AddressQuickQuoteDTO } from '../../../shared/models/data/dto/quick-quote/address-quick-quote.dto';
import { RiskQuickQuoteDTO } from '../../../shared/models/data/dto/quick-quote/risk-quick-quote.dto';
import { AllResponseDTO } from '../../../shared/models/data/dto/third-party/all-response.dto';
import { ResidentialResponseDTO } from '../../../shared/models/data/dto/third-party/residential-response.dto';
import { RiskCoverageQuickQuoteDTO } from '../../../shared/models/data/dto/quick-quote/risk-coverage-quick-quote.dto';
import { RiskPropertyQuickQuoteDTO } from '../../../shared/models/data/dto/quick-quote/risk-property-quick-quote.dto';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { PathConstants } from '../../../shared/constants/path.constants';
import { InfoMessageConstant } from '../../../shared/constants/info-message.constants';
import { takeUntil } from 'rxjs/operators';
import { NameCasePipe } from '../../../shared/custom pipes/name-pipe';
import { PreviousApplicantValuesService } from 'app/core/services/submission/others/previous-applicant-values.service';
import { PropertySavingData } from './property.saving.data';
import { ApplicantBusinessRules } from '../business-rules/applicant.business-rules';
import { LvCreditOrderStatus, LvEstimatedCredit } from 'app/shared/constants/quick-quote.options.constants';
import { FormGroup } from '@angular/forms';
import { RiskFloodQuickQuoteDTO } from '../../../shared/models/data/dto/quick-quote/risk-flood-quick-quote.dto';
import { environment } from '../../../../environments/environment';
import { RiskDetailsQuickQuoteDTO } from '../../../shared/models/data/dto/quick-quote/risk-details-quick-quote.dto';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';
import { QuoteService } from 'app/core/services/quote.service';
import { RiskDiscountQuickQuoteDTO } from 'app/shared/models/data/dto/quick-quote/risk-discount-quick-quote.dto';
import { EndorsementsData } from './endorsements.data';
import { EndorsementsSavingData } from './endorsements.saving.data';
import { AuthService } from '../../../core/services/auth.service';
import { CalculateRaterData } from 'app/core/services/submission/rater-premium/calculate-rater-data.service';
import { RiskClaimDTO } from '../../../shared/models/data/dto/third-party/risk-claims.dto';
import { ClaimsModalModel } from '../../../shared/models/submission/claims/claims.model';
import { ClaimsData } from './claims.data';
import { Store } from '@ngrx/store';
import { ClaimsSavingData } from './claims-saving.data';
import { ComparativeRaterData } from 'app/modules/comparative-rater/comparative-rater.data';
import { RiskScheduleDTO } from '../../../shared/models/data/dto/quick-quote/risk-schedule.dto';
import { updateSubmissionStatusFromEndorsementsSavingData } from 'app/store/submission/submission.actions';
import { RiskInterestQuickQuoteDTO } from 'app/shared/models/data/dto/quick-quote/risk-interest-quick-quote.dto';
import { InterestData } from './interest.data';
import { InterestLabelsConstants } from 'app/shared/constants/interest.labels.constants';

@Injectable({
  providedIn: 'root'
})
export class QuickQuoteSavingData extends BaseClass {

  protected isQuickQuoteCreating: boolean = false;

  constructor(
    public entityRiskData: EntityRiskData,
    public submissionData: SubmissionPageData,
    public propertyData: PropertyData,
    public quickQuoteData: QuickQuoteData,
    public coveragesData: CoveragesData,
    public applicantData: ApplicantData,
    protected summaryData: SummaryData,
    protected quickQuoteService: QuickQuoteService,
    public raterData: CalculateRaterData,
    protected thirdPartyData: ThirdPartyData,
    protected iso360ValueMapping: Iso360ValueMappingData,
    protected toastr: ToastrService,
    protected namePipe: NameCasePipe,
    protected previousApplicantValues: PreviousApplicantValuesService,
    protected propertSavingData: PropertySavingData,
    protected applicantBusinessRules: ApplicantBusinessRules,
    protected quoteService: QuoteService,
    public endorsementsData: EndorsementsData,
    protected endorsementsSavingData: EndorsementsSavingData,
    protected authService: AuthService,
    protected claimsData: ClaimsData,
    protected store: Store,
    protected claimsSavingData: ClaimsSavingData,
    protected comparativeRaterData: ComparativeRaterData,
    protected interestData: InterestData
  ) {
    super();
    this.thirdPartyData.quickQuoteCreditOrderPrefill$.subscribe(isOrderCredit => {
      this.quickQuoteData.isOrderCreditValue = isOrderCredit;
    });
  }

  get PropertyLocationDetailsForm() {
    return this.propertyData.locationDetails;
  }

  get PropertyDetailsForm() {
    return this.propertyData.propertyDetails;
  }

  get PropertyWindMitigationForm(): FormGroup {
    return this.propertyData.windMitigation;
  }

  get QuickQuoteApplicantForm() {
    return this.applicantData.mainApplicant;
  }

  get QuickQuoteCoveragesForm() {
    return this.coveragesData.coveragesAndDeductiblesForm;
  }

  get CoveragesDeductiblesForm() {
    return this.coveragesData.coveragesAndDeductiblesForm;
  }

  get ApplicantInformationForm() {
    return this.applicantData.mainApplicant;
  }

  get SummaryDataForm() {
    return this.summaryData.SummaryForm;
  }

  get Iso360ValueInitialValueForm() {
    return this.propertyData.iso360ValueInitialValueForm;
  }

  saveQuickQuote() {
    if (this.summaryData.SummaryForm.get('entityId').value) {
      Utils.blockUI();
      this.setHeaderDetails();
      const quickQuoteRequest = this.getQuickQuotePayload();
      const updateSubscription$: any = this.comparativeRaterData.isFromComparativeRater
        ? this.quickQuoteService.updateComparative(quickQuoteRequest) : this.quickQuoteService.updateQuickQuote(quickQuoteRequest);

      quickQuoteRequest.id = this.summaryData.SummaryForm.get('entityId').value;
      quickQuoteRequest.riskId = this.summaryData.SummaryForm.get('riskId').value;
      quickQuoteRequest.riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;

      updateSubscription$.pipe(takeUntil(this.stop$)).subscribe(res => {
        const result = <CreateQuickQuoteResultDTO>res;
        this.claimsSavingData.unmarkClaimsForSaving();
        this.SummaryDataForm.get('riskId').setValue(result.riskId);
        this.SummaryDataForm.get('entityId').setValue(result.entityId);
        this.SummaryDataForm.get('riskDetailId').setValue(result.riskDetailId);
        localStorage.setItem('entityId', result.entityId);
        this.summaryData.isCalcPremium.next(true);
        this.requestRaterCalculation(false);
        NotifUtils.showConsoleSuccess(`${PathConstants.QuickQuote.Index} ${InfoMessageConstant.savedSuccessfullyMessage}`);
        this.thirdPartyData.resetAPlusReportRecord();

        this.previousApplicantValues.Applicant = {
          firstName: this.namePipe.transform(this.QuickQuoteApplicantForm.get('firstName').value),
          middleInitial: this.namePipe.transform(this.QuickQuoteApplicantForm.get('middleInitial').value),
          lastName: this.namePipe.transform(this.QuickQuoteApplicantForm.get('lastName').value),
          dateOfBirth: new Date(this.QuickQuoteApplicantForm.get('dateOfBirth')?.value?.singleDate?.jsDate),
          nameSuffix: this.QuickQuoteApplicantForm.get('nameSuffix').value
        };

        this.submissionData.quoteStatus.next(this.submissionData.getQuoteStatus(result.riskStatusCode));
        this.store.dispatch(updateSubmissionStatusFromEndorsementsSavingData({ status: this.submissionData.getQuoteStatus(result.riskStatusCode) }));

        if (this.summaryData.creditOrderErrorCode === ErrorMessageConstant.CreditService.CS02ErrorCode ||
          this.summaryData.creditOrderErrorCode === ErrorMessageConstant.CreditService.CE01ErrorCode) {
          this.summaryData.creditOrderErrorCode = '';
        }
        if (this.quoteService.formType === FormTypeConstants.DP3) {
          this.coveragesData.coveragesAndDeductiblesForm.get('coverageDorE').setValue(this.coveragesData.validCovA * .1);
        }
        if (this.quoteService.formType === FormTypeConstants.HO4 || this.quoteService.formType === FormTypeConstants.HO6) {
          const coverageC = this.SummaryDataForm.get('coverageC').value;
          this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').setValue(coverageC);
          this.coveragesData.calculateHO4HO6Coverages(coverageC);
        }

        if (this.applicantData.mainApplicant.get('isOrderYesNo').value &&
          this.coveragesData?.isOrderCreditSuccessful &&
          this.applicantData.isPrefillClicked) {
          this.applicantData.fcraReportingActForm.get('fcraReportingAct').setValue(false);
        }
        this.applicantData.isPrefillClicked = false;
        this.applicantData.isQQSavedForCopy = true;
        localStorage.removeItem('comparativeRater');
        this.submissionData.qqSavingComplete.next();
      }, err => {
          Utils.unblockUI();
          this.claimsSavingData.unmarkClaimsForSaving();
          console.log(err);
          this.summaryData.isCalcPremium.next(false);
          this.toastr.error(ErrorMessageConstant.savingErrorMessage);
          // add code here for error log
      }, () => {
        // Reset this to false, either a success or failing HTTP response
        this.summaryData.isUpdatedInsuredProfileOrPrefill = false;
        this.claimsSavingData.unmarkClaimsForSaving();
        Utils.unblockUI();
      });
      this.coverageAAndHurricaneDeductibleBRUL();
      this.propertyData.validYearBuilt = +this.propertyData.propertyDetails.get('yearBuilt').value;
      this.propertyData.validYearRoof = +this.propertyData.propertyDetails.get('yearRoof').value;

      this.endorsementsSavingData.saveEndorsements();
    }
  }

  setApplicantPage() {
    this.ApplicantInformationForm.get('firstName').setValue(this.QuickQuoteApplicantForm.get('firstName').value);
    this.ApplicantInformationForm.get('middleInitial').setValue(this.QuickQuoteApplicantForm.get('middleInitial').value);
    this.ApplicantInformationForm.get('lastName').setValue(this.QuickQuoteApplicantForm.get('lastName').value);
    this.ApplicantInformationForm.get('dateOfBirth').setValue(this.QuickQuoteApplicantForm.get('dateOfBirth').value);
  }

  setHeaderDetails() {
    this.SummaryDataForm.get('name').setValue(this.namePipe.transform(this.applicantData.name));
    if (this.summaryData.SummaryForm.get('entityId').value === null) {
      this.SummaryDataForm.get('address').setValue(this.applicantData.address);
    }
    this.SummaryDataForm.get('effectiveDate').setValue(this.QuickQuoteApplicantForm.get('effectiveDate').value);
    if (this.quoteService.formType === FormTypeConstants.HO3 || this.quoteService.formType === FormTypeConstants.DP3 ) {
      const covA = this.QuickQuoteCoveragesForm.get('coverageA').value;
      if (covA >= MINCOVA && covA <= MAXCOVA) {
        this.SummaryDataForm.get('coverageA').setValue(this.QuickQuoteCoveragesForm.get('coverageA').value);
      }
    } else if (this.quoteService.formType === FormTypeConstants.HO6) {
      this.SummaryDataForm.get('coverageA').setValue(this.QuickQuoteCoveragesForm.get('coverageA').value);
      this.SummaryDataForm.get('coverageC').setValue(this.QuickQuoteCoveragesForm.get('coverageC').value);
    } else {
      this.SummaryDataForm.get('coverageC').setValue(this.QuickQuoteCoveragesForm.get('coverageC').value);
    }

    if (this.entityRiskData.EntityRisk === null) {
      this.SummaryDataForm.get('policyStatus').setValue('Pending');
      this.SummaryDataForm.get('formType').setValue(JSON.parse(sessionStorage.getItem(environment.QuoteFormTypeAndState))?.formType);
      this.SummaryDataForm.get('quoteNumber').setValue('Q-20004880');
      // Set empty value creditOrderStatus to prevent UI confusion of actual credit order status.
      this.SummaryDataForm.get('creditOrderStatus').setValue('');
      this.SummaryDataForm.get('quoteStatus').setValue('Outstanding Binding Requirements');
      this.summaryData.isFormTypeLoaded.next(true);
    }
  }

  getQuickQuotePayload(isFirstSaveFromCompRater: boolean = false): EntityQuickQuoteDTO {
    const isOrdered = this.QuickQuoteApplicantForm.get('isOrderYesNo').value;
    const isOrderCredit = !isOrdered ? isOrdered : this.quickQuoteData.isOrderCreditValue;
    const notOrderedIndex = 0;
    // isRetired
    const isRetired = this.quickQuoteData.setSeniorRetireeValue(true);

    const entityCreditScoreStatusCode = this.entityRiskData?.EntityRisk?.creditScoreStatusCode ?? this.coveragesData?.creditOrderStatusCode;
    const creditOrderStatusCode = entityCreditScoreStatusCode !== '' ? this.coveragesData?.creditOrderStatusCode : LvCreditOrderStatus[notOrderedIndex].code;
    const entityEstimatedCredit = this.entityRiskData?.EntityRisk?.estimatedCredit;
    const isExcellent = creditOrderStatusCode === LvCreditOrderStatus[notOrderedIndex].code;
    let estimatedCredit = entityEstimatedCredit && entityEstimatedCredit !== '' ?
      this.coveragesData?.estimatedCode : isExcellent ? LvEstimatedCredit[0].code : this.coveragesData?.estimatedCode;

    if (this.comparativeRaterData.isFromComparativeRater && this.entityRiskData.isNewQuote &&
        !this.coveragesData?.isOrderCreditSuccessful && isExcellent) {
      estimatedCredit = this.coveragesData?.estimatedCode ? this.coveragesData?.estimatedCode : LvEstimatedCredit[0].code;
    }

    const entityQuickQuote: EntityQuickQuoteDTO = {
      riskId: this.summaryData.SummaryForm.get('riskId').value,
      firstName: this.namePipe.transform(this.QuickQuoteApplicantForm.get('firstName').value),
      middleName: !this.QuickQuoteApplicantForm.get('middleInitial').value ? null : this.namePipe.transform(this.QuickQuoteApplicantForm.get('middleInitial').value),
      nameSuffix: this.namePipe.transform(this.QuickQuoteApplicantForm.get('nameSuffix').value),
      lastName: this.namePipe.transform(this.QuickQuoteApplicantForm.get('lastName').value),
      birthDate: this.QuickQuoteApplicantForm.get('dateOfBirth').value?.singleDate?.jsDate?.toLocaleDateString('en-US'),
      isRetired: isRetired,
      entityAddresses: [this.getAddressPayload()],
      risks: [this.getRiskPayload(isFirstSaveFromCompRater)],
      creditScoreStatusCode: LvCreditOrderStatus.find(s => s.code === creditOrderStatusCode).code,
      creditScore: this.coveragesData?.creditScore,
      creditScoreDateOrdered: this.coveragesData?.creditScoreDateOrdered,
      lexisReferenceNumber: this.coveragesData?.lexisReferenceNumber,
      estimatedCredit: estimatedCredit,
      fcraAcknowledgement: this.validateAcknowledgement(),
      isOrderCredit: isOrderCredit,
      isUpdateAAN: this.summaryData.isUpdatedInsuredProfileOrPrefill,
      claimsTier: this.coveragesData?.creditPriorClaimTier,
      uwUserId: this.authService.subAgenciesInfo?.uwUserId ?? null,
      isPrefillClicked: this.applicantData.isPrefillClicked,
      aPlusReportRecord:this.thirdPartyData.dataFromAPIResponse.aPlusReportRecord,
      // timeZoneInfo: this.entityRiskData.getUserTimeZone() - Uncomment for dynamic set of local time zone
      maritalStatusCode: this.QuickQuoteApplicantForm.get('maritalStatusApplicant')?.value ?? null
    };

    return entityQuickQuote;
  }

  getAddressPayload(): EntityAddressQuickQuoteDTO {

    const county = this.propertyData.locationDetails.get('county').value;

    const address: AddressQuickQuoteDTO = {
      streetAddress1: !this.QuickQuoteApplicantForm.get('addressLine1').value ? null : this.QuickQuoteApplicantForm.get('addressLine1').value,
      streetAddress2: !this.QuickQuoteApplicantForm.get('addressLine2').value ? null : this.QuickQuoteApplicantForm.get('addressLine2').value,
      city: this.QuickQuoteApplicantForm.get('qqCity').value?.toLowerCase() === this.entityRiskData.getPropertyAddress()?.city?.toLowerCase() ?
        this.entityRiskData.getPropertyAddress()?.city : this.QuickQuoteApplicantForm.get('qqCity').value, // to avoid policy changes for case sensitivity in IPX data
      state: this.QuickQuoteApplicantForm.get('qqState').value,
      zipCode: this.QuickQuoteApplicantForm.get('qqZipCode').value,
      county: Array.isArray(county) ? county[0] : county
      // countryCode: this.quickQuoteData.zipList.find(x => x.address.zip === this.QuickQuoteApplicantForm.get('qqZipCode').value)?.address.territoryCode
    };

    const entityAddress: EntityAddressQuickQuoteDTO = {
      addressTypeCode: 'PR',
      address: address
    };

    return entityAddress;
  }

  getRiskPayload(isFirstSaveFromCompRater: boolean = false): RiskQuickQuoteDTO {
    const county = this.propertyData.locationDetails.get('county').value;
    const priorInsuranceCodeDefault = 'PI1';
    const isOrderPrefillSuccess = this.submissionData.isSuccessfulOrder;

    if (!!!this.thirdPartyData.dataFromAPIResponse) {
      this.thirdPartyData.dataFromAPIResponse = new AllResponseDTO();
      this.thirdPartyData.dataFromAPIResponse.iso360Value = new ResidentialResponseDTO();
    }

    let exteriorWallFinish360Code = this.thirdPartyData.dataFromAPIResponse.iso360Value?.exteriorWallFinish?.iso360ValueCode ?? '';
    let construction360Code = this.thirdPartyData.dataFromAPIResponse.iso360Value?.construction?.iso360ValueCode ?? '';
    let roofMaterial360Code = this.thirdPartyData.dataFromAPIResponse.iso360Value?.roofMaterial?.iso360ValueCode ?? '';
    const foundationType360Code = this.thirdPartyData.dataFromAPIResponse.iso360Value?.foundationType?.iso360ValueCode ?? '';

    // Align the new values manually changed by the user and update the
    // corresponding object property value
    if (this.thirdPartyData.dataFromAPIResponse.iso360Value?.exteriorWallFinish?.code !==
      this.PropertyDetailsForm.get('exteriorWallFinish').value ||
      exteriorWallFinish360Code === '') {
      exteriorWallFinish360Code = this.iso360ValueMapping.defaultExteriorWallFinishMapping.find(f => f.uiCode === this.PropertyDetailsForm.get('exteriorWallFinish').value).iso360Code;
      this.thirdPartyData.dataFromAPIResponse.iso360Value.exteriorWallFinish = {
        iso360ValueCode: exteriorWallFinish360Code,
        code: this.PropertyDetailsForm.get('exteriorWallFinish').value,
        description: ''
      };
    }

    if (this.thirdPartyData.dataFromAPIResponse.iso360Value?.construction?.code !==
      this.PropertyDetailsForm.get('constructionType').value ||
      construction360Code === '') {
      construction360Code = this.iso360ValueMapping.defaultConstructionTypeMapping.find(f => f.uiCode === this.PropertyDetailsForm.get('constructionType').value).iso360Code;
      this.thirdPartyData.dataFromAPIResponse.iso360Value.construction = {
        iso360ValueCode: construction360Code,
        code: this.PropertyDetailsForm.get('constructionType').value,
        description: ''
      };
    }

    if (this.thirdPartyData.dataFromAPIResponse.iso360Value?.roofMaterial?.code !==
      this.PropertyDetailsForm.get('roofMaterial').value ||
      roofMaterial360Code === '') {
      roofMaterial360Code = this.iso360ValueMapping.defaultRoofMaterialMapping.find(f => f.uiCode === this.PropertyDetailsForm.get('roofMaterial').value).iso360Code;
      this.thirdPartyData.dataFromAPIResponse.iso360Value.roofMaterial = {
        iso360ValueCode: roofMaterial360Code,
        code: this.PropertyDetailsForm.get('roofMaterial').value,
        description: ''
      };
    }

    let riskCoverages: RiskCoverageQuickQuoteDTO = {
      covA: Utils.round(Number(this.QuickQuoteCoveragesForm.get('coverageA').value)),
      covB: Utils.round(Number(this.QuickQuoteCoveragesForm.get('coverageB').value)),
      covBPercentageId: +this.QuickQuoteCoveragesForm.get('coverageBLimitId').value,
      covC: Utils.round(Number(this.QuickQuoteCoveragesForm.get('coverageC').value)),
      covCPercentageId: +this.QuickQuoteCoveragesForm.get('coverageCLimitId').value,
      covE: +this.coveragesData.coveragesAndDeductiblesForm.get('coverageEId').value,
      covF: +this.coveragesData.coveragesAndDeductiblesForm.get('coverageFId').value,
      allOtherPerilsDeductible: this.QuickQuoteCoveragesForm.get('allOtherPerilsId').value,
      hurricaneDeductible: this.QuickQuoteCoveragesForm.get('hurricaneDeductibleid').value,
      creditOrdered: this.coveragesData.creditOrderStatusCode,
      fairRentalAddlLiving: this.quoteService.formType === FormTypeConstants.DP3 ? Utils.round(this.QuickQuoteCoveragesForm.get('coverageA').value * 0.1) : 0,
      coverageL: this.quoteService.formType === FormTypeConstants.DP3 ? 100000 : 0,
      coverageM: this.quoteService.formType === FormTypeConstants.DP3 ? 1000 : 0
    };

    let riskProperties: RiskPropertyQuickQuoteDTO = {
      protectionClassName: this.propertyData.protectionClassName,
      protectionClassCode: this.PropertyLocationDetailsForm.get('protectionClass').value,
      yearRoofInstalled: this.quoteService.formType !== FormTypeConstants.HO4 ? this.PropertyDetailsForm.get('yearRoof').value : null,
      yearCentralAirConditionSystemInstalled: this.quoteService.formType !== FormTypeConstants.HO4 ? this.PropertyDetailsForm.get('yearCentralAirConditioning').value : null,
      yearElectricalInstalledUpdated: this.quoteService.formType !== FormTypeConstants.HO4 ? this.PropertyDetailsForm.get('yearElectrical').value : null,
      yearPlumbingInstalledUpdated: this.quoteService.formType !== FormTypeConstants.HO4 ? this.PropertyDetailsForm.get('yearPlumbing').value : null,
      yearHeatingInstalledUpdated: this.quoteService.formType !== FormTypeConstants.HO4 ? this.PropertyDetailsForm.get('yearHeating').value : null,
      yearBuilt: this.PropertyDetailsForm.get('yearBuilt').value ? +this.PropertyDetailsForm.get('yearBuilt').value : null,
      squareFootage: this.PropertyDetailsForm.get('squareFootage').value,
      RaterConstructionTypeCode: this.PropertyDetailsForm.get('constructionType').value,
      roofAge: 0,
      // roofMaterialCode: this.PropertyDetailsForm.get('roofMaterial').value ? this.quickQuoteData.roofMaterials.find(a => a.code === this.PropertyDetailsForm.get('roofMaterial').value).id : null,
      roofMaterialCode: this.PropertyDetailsForm.get('roofMaterial').value,
      raterExteriorWallFinishCode: this.PropertyDetailsForm.get('exteriorWallFinish').value,
      isFlatTile: this.propertSavingData.isRoofMaterialsFlatTileMaterial(this.PropertyDetailsForm.get('roofMaterial').value) ? this.PropertyDetailsForm.get('isFlatTile')?.value : false,
      valuationId: this.PropertyLocationDetailsForm.get('valuationId').value,
      numberOfStory: this.PropertyDetailsForm.get('numOfStories').value,
      raterRoofShapeCode: this.PropertyDetailsForm.get('roofShape').value,
      raterFoundationTypeCode: this.PropertyDetailsForm.get('foundationType').value,
      replacementCost: this.PropertyDetailsForm.get('replacementCost').value,
      replacementCostDate: this.PropertyDetailsForm.get('replacementCostDate').value?.singleDate?.jsDate.toLocaleDateString('en-US'),
      structureTypeCode: this.PropertyLocationDetailsForm.get('structureType').value,
      latitude: this.PropertyLocationDetailsForm.get('latitude').value,
      longitude: this.PropertyLocationDetailsForm.get('longitude').value,
      oceanName: this.PropertyLocationDetailsForm.get('oceanName').value,
      respondingFireDepartment: this.PropertyLocationDetailsForm.get('respondingFireDept').value,
      windPoolEligible: this.PropertyLocationDetailsForm.get('windPoolEligible').value,
      isMilesToFireStationFromISO: this.thirdPartyData.dataFromAPIResponse.isMilesToFireStationFromISO,
      milesToFireStationCode: this.PropertyLocationDetailsForm.get('milesToFireStation').value,
      nearestWaterName: this.PropertyLocationDetailsForm.get('nearestWaterName').value,
      distanceToWater: this.PropertyLocationDetailsForm.get('distanceToWater').value,
      distanceToActualCoast: this.PropertyLocationDetailsForm.get('distanceToActualCoast').value,
      fireDistrictName: this.PropertyLocationDetailsForm.get('fireDistrictName').value,
      bceg: this.PropertyLocationDetailsForm.get('bceg').value,
      matchLevel: this.PropertyLocationDetailsForm.get('matchLevel').value,
      exteriorWallFinish360Code: exteriorWallFinish360Code,
      construction360Code: construction360Code,
      roofMaterial360Code: roofMaterial360Code,
      county: Array.isArray(county) ? county[0] : county,
      territoryCode: this.PropertyLocationDetailsForm.get('territoryCode').value,
      costPerSqft: this.thirdPartyData.costPerSqft,
      distanceToWaterCode: this.thirdPartyData.dataFromAPIResponse.distanceToWaterCode,
      distanceToActualCoastCode: this.thirdPartyData.dataFromAPIResponse.distanceToActualCoastCode,
      isNoHitAddress: this.thirdPartyData.dataFromAPIResponse?.iso360Value?.isNoHitAddress,
      terrain: this.PropertyWindMitigationForm.get('terrain').value,
      windSpeedCode: this.PropertyWindMitigationForm.get('windSpeed').value,
      raterWindDesignSpeedCode: this.PropertyWindMitigationForm.get('windDesignSpeed').value,
      isWindBorneDebrisRegion: this.PropertyWindMitigationForm.get('windBorneDebrisRegion').value,
      isNoHitIso360ValueFirstCall: this.thirdPartyData.isNoHitIso360ValueFirstCall,
      isoLocationPPC: this.thirdPartyData.dataFromAPIResponse.isoLocationPPC,
      foundationType360Code: foundationType360Code,
      squareFootage360Value: this.propertyData.squareFootage360Value,
      propertyUsageCode: this.PropertyLocationDetailsForm.get('propertyUsage').value,
      exteriorWallFinish360DefaultCode: this.Iso360ValueInitialValueForm.get('exteriorWallFinish360DefaultCode').value,
      foundationType360DefaultCode: this.Iso360ValueInitialValueForm.get('foundationType360DefaultCode').value,
      roofMaterial360DefaultCode: this.Iso360ValueInitialValueForm.get('roofMaterial360DefaultCode').value,
      roofShape360DefaultCode: this.Iso360ValueInitialValueForm.get('roofShape360DefaultCode').value,
      numberOfUnitsBetweenFirewallsCode: this.quoteService.formType === FormTypeConstants.HO3 || this.quoteService.formType === FormTypeConstants.DP3 ? this.propertyData.locationDetails.controls['numberOfUnitsFirewalls'].value !== 'None' ? this.propertyData.locationDetails.controls['numberOfUnitsFirewalls'].value : 0 : '',
      numberOfUnitsInsured: this.quoteService.formType === FormTypeConstants.DP3 ? this.propertyData.locationDetails.controls['numberOfUnitsInsured'].value : '',
      isoLocationPPCReturnSource: this.thirdPartyData.dataFromAPIResponse.isoLocationPPCReturnSource,
      distanceToActualCoastReturnSource: this.thirdPartyData.dataFromAPIResponse.distanceToCoastReturnSource,
      windPoolEligibleReturnSource: this.thirdPartyData.dataFromAPIResponse.windPoolEligibleReturnSource
    };

    const riskFlood: RiskFloodQuickQuoteDTO = {
      floodZone: this.coveragesData.floodForm.get('floodZone').value,
      nFIPPremium: this.coveragesData.floodForm.get('nFIPPremium').value,
      torrentTrackingNum: this.coveragesData.torrentResponse?.torrentTrackingNum ?? this.thirdPartyData.dataFromAPIResponse?.torrentFloodQuote?.torrentTrackingNum,
      torrentRequestTransactionId: this.coveragesData.torrentResponse?.torrentRequestTransactionId ?? this.thirdPartyData.dataFromAPIResponse?.torrentFloodQuote?.torrentRequestTransactionId,
      isFloodZoneAvailableFromTorrent: this?.coveragesData?.torrentResponse?.isFloodZoneAvailableFromTorrent ?? this?.thirdPartyData?.dataFromAPIResponse?.torrentFloodQuote?.isFloodZoneAvailableFromTorrent,
      isFloodZoneFromFloodAssist: this.coveragesData.floodAssistRetrievalForm.get('isFloodZoneFromFloodAssist').value,
      isTorrentAddressValidationFailed: this?.coveragesData?.torrentResponse?.isTorrentAddressValidationFailed,
      isPrivateFloodCov: this.coveragesData.floodForm.get('privateFloodCoverage').value,
      applicantFloodPolicyCode: this.coveragesData.floodForm.get('applicantFloodPolicyId').value
    };

    // Check if request is from Comparative Rater
    let riskDiscount: RiskDiscountQuickQuoteDTO = null;
    if (this.comparativeRaterData?.isFromComparativeRater && this.entityRiskData?.isNewQuote) {
      const agentProgramDetails = this.authService.agentProgramStateDetails.find(x => x.program.formTypeCode === this.quoteService.formType);
      this.quoteService.companyName = agentProgramDetails?.entity?.companyName;
      riskDiscount = this.comparativeRaterData.mapCoverageDiscountFromComparative();
    } else {
      riskDiscount = {
      isCompanion: this.coveragesData.discountsForm.get('companion').value,
      sprinklerCode: this.coveragesData.discountsForm.get('sprinklerId').value,
      buglarCode: this.coveragesData.discountsForm.get('burglarId').value,
      fireCode: this.coveragesData.discountsForm.get('fireId').value,
      isAccreditedBuilder: this.coveragesData.discountsForm.get('accreditedBuilder').value,
      isSrRetiree: this.coveragesData.discountsForm.get('seniorRetiree').value,
      securedCommBldgCode: this.coveragesData.discountsForm.get('securedCommunityBuildingId').value
      };
    }

    let riskSchedule: RiskScheduleDTO[] = [];
    let isAddSchedule = false;
    if (this.comparativeRaterData.isFromComparativeRater && this.entityRiskData?.isNewQuote && this.quoteService.formType !== FormTypeConstants.DP3) {
      riskSchedule = this.comparativeRaterData.mapSchedulesFromComparative();
      isAddSchedule = this.coveragesData.schedulesForm.get('addSchedules')?.value;
    }

    riskProperties = this.comparativeRaterData.getRiskPropertyPayloadFromComparative(this.entityRiskData?.isNewQuote,
        riskProperties, this.PropertyLocationDetailsForm, this.PropertyDetailsForm, this.PropertyWindMitigationForm);

    riskCoverages = this.comparativeRaterData.getDP3RiskCoveragesPayloadFromComparative(this.entityRiskData?.isNewQuote,
        riskCoverages, this.coveragesData.coveragesAndDeductiblesForm);

    const riskInterests: RiskInterestQuickQuoteDTO[] = [{
      priorInsuranceCode: this.interestData.priorInsuranceForm.get('priorInsurance').value ? this.interestData.priorInsuranceForm.get('priorInsurance').value : 'PI1',
      priorCarrierCode: this.interestData.priorInsuranceForm.get('priorInsurance').value === InterestLabelsConstants.priorCarrierList.PI0 && this.quoteService.formType !== FormTypeConstants.HO4 ? this.interestData.priorInsuranceForm.get('priorCarrier').value : null,
      priorExpirationDate: this.interestData.priorInsuranceForm.get('priorExpirationDate')?.value ? this.interestData.priorInsuranceForm.get('priorInsurance').value === InterestLabelsConstants.priorCarrierList.PI0 ? this.interestData.priorInsuranceForm.get('priorExpirationDate').value?.singleDate?.jsDate.toLocaleDateString('en-US') : null : null,
      priorLiabilityLimitCode: this.interestData.priorInsuranceForm.get('priorLiabilityLimit')?.value ?? '',
    }];

    const riskDetails: RiskDetailsQuickQuoteDTO = {
      id: this.summaryData.SummaryForm.get('riskDetailId').value,
      riskId: this.summaryData.SummaryForm.get('riskId').value,
      effectiveDate: isFirstSaveFromCompRater ? this.QuickQuoteApplicantForm.get('effectiveDate').value?.singleDate?.jsDate.toUTCString()
        : this.QuickQuoteApplicantForm.get('effectiveDate').value?.singleDate?.jsDate.toLocaleDateString('en-US'),
      riskCoverages: [riskCoverages],
      riskFloods: [riskFlood],
      riskProperties: [riskProperties],
      riskDiscounts: [riskDiscount],
      riskBinds: [{}],
      riskInterests: riskInterests,
      riskClaims: this.claimsData?.claimList?.length !== 0 ? this.getClaimList(this.claimsData.claimList) : this.entityRiskData?.getRisk()?.riskDetails[0]?.riskClaims,
      isAddSchedule: isAddSchedule,
      riskSchedules: riskSchedule,
      screenScrapeSource: this.QuickQuoteApplicantForm.get('screenScrapeSource')?.value ?? ''
    };

    const risk: RiskQuickQuoteDTO = {
      id: this.summaryData.SummaryForm.get('riskId').value,
      riskStateCode: this.QuickQuoteApplicantForm.get('qqState').value,
      formType: this.summaryData.SummaryForm.get('formType').value,
      programId: environment.ApplicationId,
      agencyId: this.applicantData.copiedAgencyInformation ? this.applicantData.copiedAgencyInformation.agencyId : this.authService.agenciesInfo?.id ?? null,
      subAgencyId: this.applicantData.copiedAgencyInformation ? this.applicantData.copiedAgencyInformation.subAgencyId : this.authService.subAgenciesInfo?.id ?? null,
      agentId: this.applicantData.copiedAgencyInformation ? this.applicantData.copiedAgencyInformation.agentId: this.authService.agentUserInfo?.id ?? null,
      clientCode: 'CENT',
      riskDetails: [riskDetails],
      addressId: '',
      uwUserId: this.authService.subAgenciesInfo?.uwUserId ?? null,
      programType: this.quoteService.masterProgramType ?? null,
      insuranceName: this.applicantData.copiedAgencyInformation ? this.applicantData.copiedAgencyInformation.insuranceName : this.quoteService.companyName ?? null,
      comparativeId: this.comparativeRaterData.transactionId ?? null,
      isOrderPrefillSuccess: isOrderPrefillSuccess,
      isLastAPlusCallSuccess: this.thirdPartyData.dataFromAPIResponse.isLastAPlusCallSuccess
    };

    return risk;
  }

  getClaimList(claims: ClaimsModalModel[]): Array<RiskClaimDTO> {
    const claimList: Array<RiskClaimDTO> = [];

    claims.forEach(claimItem => {
      const claim: RiskClaimDTO = {
        amount: claimItem.claimAmount,
        catCode: claimItem.catCode,
        chargeableNonChargable: claimItem.claimChargeableVsNonChargeable,
        claimNumber: claimItem.claimNumber,
        claimSource: claimItem.claimSource,
        claimStatus: claimItem.claimStatus,
        claimType: claimItem.claimType,
        createdDate: claimItem.createdDate,
        description: claimItem.additionalDescription,
        id: claimItem.id,
        individualMatchType: claimItem.individualMatchType,
        isAPlus: claimItem.isAPlus,
        isAdd: claimItem.isAdd,
        isDispute: claimItem.dispute,
        lossDate: new Date(claimItem.lossDate?.singleDate.jsDate).toLocaleDateString('en-US'),
        notes: claimItem.notes,
        otherQuestion: claimItem.otherQuestions,
        policyType: claimItem.policyType,
        riskDetailId: claimItem.riskDetailId,
        isComparative: claimItem.isComparative,
        isLossAssociatedToHome: claimItem.isLossAssociatedToHome,
        isLossAssociatedToInsured: claimItem.isLossAssociatedToInsured
      };

      claimList.push(claim);
    });
    return claimList;
  }

  requestRaterCalculation(isNew: boolean) {
    try {
      this.raterData.quickQuoteRaterRequest(
        this.ApplicantInformationForm,
        this.PropertyLocationDetailsForm,
        this.PropertyDetailsForm,
        this.summaryData.SummaryForm,
        this.coveragesData,
        isNew
      );
    } catch (e) {
      Utils.unblockUI();
      this.toastr.error(ErrorMessageConstant.raterCannotBeReachedErrorMessage);
    }
  }

  coverageAAndHurricaneDeductibleBRUL() {
    if (this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').errors?.min || this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').errors?.max) {
      this.coveragesData.validCovA = this.coveragesData.validCovA;
    } else {
      this.coveragesData.validCovA = +this.coveragesData.coveragesAndDeductiblesForm.get('coverageA').value;
    }
    if (this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').errors?.min || this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').errors?.max) {
      this.coveragesData.validCovC = this.coveragesData.validCovC;
    } else {
      this.coveragesData.validCovC = +this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').value;
    }
  }

  checkIfQuickQuoteIsCreating() {
    return this.isQuickQuoteCreating;
  }

  markQuickQuoteAsCreating() {
    this.isQuickQuoteCreating = true;
  }

  finishQuickQuoteCreating() {
    this.isQuickQuoteCreating = false;
  }

  //remove private to inherit
  validateAcknowledgement(): boolean {
    const agreed = this.applicantData.mainApplicant.get('isOrderYesNo').value && this.applicantData.isPrefillClicked ? false : this.applicantData.fcraReportingActForm.get('fcraReportingAct').value;
    return this.coveragesData?.creditOrderStatusCode > LvCreditOrderStatus[0].code ? agreed : false;
  }
}
