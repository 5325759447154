import { Component, Input, OnInit } from '@angular/core';
import { BillingLabelConstants } from '../../../../../../../../app/shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from '../../../../../../../../app/shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../../../../app/shared/constants/generic.labels.constants';
import FormUtils from '../../../../../../../../app/shared/utilities/form.utils';
import { BindAndIssueData } from '../../../../../../../../app/modules/submission-management/data/bind-and-issue.data';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-billing-form-echeck',
  templateUrl: './billing-form-echeck.component.html',
  styleUrls: ['./billing-form-echeck.component.scss']
})
export class BillingFormECheckComponent implements OnInit {

  @Input() parentForm: FormGroup;

  public FormUtils = FormUtils;
  public BillingLabelConstants = BillingLabelConstants;
  public ErrorMessageConstant = ErrorMessageConstant;
  GenericLabel = GenericLabel;

  constructor(public bindAndIssueData: BindAndIssueData) { }

  ngOnInit() {
  }
}
