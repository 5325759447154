import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Utils from 'app/shared/utilities/utils';
import { DashBoardService } from 'app/core/services/dashboard.service';
import { QuoteService } from 'app/core/services/quote.service';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { QuickQuoteData } from 'app/modules/submission-management/data/quick-quote.data';
import { SubmissionPageData } from 'app/modules/submission-management/data/submission-page.data';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { BaseClass } from 'app/shared/base-class';
import { takeUntil } from 'rxjs/operators';
import { CustomValidators } from 'app/shared/validators/custom.validator';
import { AgentService } from '../../../../../core/services/management/agent-service';

@Component({
  selector: 'app-process-endorsement-modal',
  templateUrl: './process-endorsement-modal.component.html',
  styleUrls: ['./process-endorsement-modal.component.scss'],
})
export class ProcessEndorsementModalComponent extends BaseClass implements OnInit {
  title: any;
  public processEndorsementFrm: FormGroup;
  private riskId: string = '';
  private riskDetailId: string = '';
  public agentDashboardConstants = AgentDashboardConstants;
  private agentId: string;
  isInternal: boolean;

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public quoteService: QuoteService,
    private summaryData: SummaryData,
    public submissionData: SubmissionPageData,
    public entityRiskData: EntityRiskData,
    public quickQuoteData: QuickQuoteData,
    private router: Router,
    private dashBoardService: DashBoardService,
    private agentService: AgentService
  ) { super(); }

  ngOnInit(): void {
    this.processEndorsementFrm = this.fb.group({
      policyNumber: ['', [Validators.required, CustomValidators.spaceValidator]],
    });
    this.agentService.getAgentUserInformation(localStorage.getItem('uname')).pipe(takeUntil(this.stop$)).subscribe((agentInfo) => {
      this.agentId = agentInfo?.id;
    });
  }

  okMyModal(): void {
    const policyNumber = this.processEndorsementFrm.get('policyNumber').value;

    Utils.blockUI();
    const agentId = !this.isInternal ? this.agentId : '';
    const number_code_payload = {
      number: policyNumber,
      agentId: agentId
    };

    this.dashBoardService.postLatestRiskId(number_code_payload).pipe(takeUntil(this.stop$)).subscribe({
      next: (response: any) => {
        if (response.riskId && response.riskDetailId) {
          Utils.unblockUI();
          this.bsModalRef.hide();
          this.riskId = response.riskId;
          this.riskDetailId = response.riskDetailId;
          this.router.navigate([`/policies/${this.riskId}/${this.riskDetailId}/coverages-and-deductions`,]);
          return;
        } else {
          NotifUtils.showError(AgentDashboardConstants.policyNumberCannotBeFound);
        }
      },
      error: (err: any) => {
        NotifUtils.showError(AgentDashboardConstants.policyNumberCannotBeFound);
      }
    });
  }

  hideMyModal(): void {
    this.bsModalRef.hide();
  }
}
