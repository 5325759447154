import { Component, OnInit, EventEmitter } from '@angular/core';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { BsModalRef } from 'ngx-bootstrap';
import { BaseClass } from '../../../../../shared/base-class';
import { SuspendedPaymentsLabel } from '../../../../../shared/constants/suspended-payments.labels.constants';
import NotifUtils from '../../../../../../app/shared/utilities/notif-utils';
import { ReverseSuspendedPaymentDTO } from '../../../../../../app/shared/models/data/dto/billing/reverse-suspended-payment.dto';
import Utils from '../../../../../../app/shared/utilities/utils';
import { BillingService } from '../../../../../../app/core/services/billing/billing.service';
import { takeUntil } from 'rxjs/operators';
import { ReturnSuspendedViewDTO } from '../../../../../../app/shared/models/data/dto/suspended-payment/return-suspended-view.dto';
import { SuspendedPaymentsData } from '../../../../../modules/payments/data/suspended-payments.data';

@Component({
  selector: 'app-payment-details-modal',
  templateUrl: './reverse-modal.component.html',
  styleUrls: ['./reverse-modal.component.scss']
})
export class ReverseModalComponent extends BaseClass implements OnInit {
  public event: EventEmitter<any> = new EventEmitter<any>();
  public ErrorMessageConstant = ErrorMessageConstant;
  public LabelConstants = SuspendedPaymentsLabel.reverseReturnedModal;

  public suspendedPaymentId: string;
  public returnPaymentDetail: ReturnSuspendedViewDTO;

  modalRef: BsModalRef | null;
  title: string;
  isReversal: boolean;

  paymentConfirmationModalRef: BsModalRef | null;

  constructor(
    public bsModalRef: BsModalRef,
    private billingService: BillingService,
    public suspendedPaymentsData: SuspendedPaymentsData
  ) {
    super();
  }

  ngOnInit() {
    this.initForm();
  }

  initForm(): void {
    this.suspendedPaymentsData.reversePaymentFormGroup.reset();
    this.populateFields();
  }

  populateFields(): void {
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  onReverse(): void {
    NotifUtils.showConfirmMessage(this.LabelConstants.reverseConfirmationMessage, () => {
      const reverseRequest: ReverseSuspendedPaymentDTO = {
        suspendedPaymentId: this.returnPaymentDetail.id,
        comment: this.suspendedPaymentsData.reversePaymentFormGroup.get('comments').value
      };

      Utils.blockUI();
      this.billingService.reverseSuspendedPayment(reverseRequest).pipe(takeUntil(this.stop$)).subscribe(res => {
        Utils.unblockUI();

        const callback = () => NotifUtils.showSuccess(this.LabelConstants.reverseSuccessMessage);
        this.suspendedPaymentsData.getSuspendedPaymentList(callback);
        this.suspendedPaymentsData.reloadReturnedSuspendedPaymentList();

        this.bsModalRef.hide();
      },
        err => {
          Utils.unblockUI();
          NotifUtils.showMultiLineError(err.error?.message);
        });
    });
  }
}
