import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PathConstants } from '../../shared/constants/path.constants';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ManagementGuard implements CanActivate {
  constructor(private _authService: AuthService, private _router: Router) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const isInternal = localStorage.getItem('userType') === 'internal';

      if (!isInternal) {
          this._router.navigate(['404']);
          return false;
      }

    return isInternal;
  }
}
