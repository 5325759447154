import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseClass } from '../../../shared/base-class';
import { ErrorMessageConstant } from '../../../shared/constants/error-message.constants';
import { LoginLabelsConstants, ReferenceCodeConstants } from '../../../shared/constants/login.labels.constants';
import NotifUtils from '../../../shared/utilities/notif-utils';
import Utils from '../../../shared/utilities/utils';
import { ComparativeRaterData } from '../comparative-rater.data';
import { ComparativeRaterReferenceCodeModalComponent } from './comparative-rater-reference-code-modal/comparative-rater-reference-code-modal.component';

@Component({
  selector: 'app-comparative-rater-login',
  templateUrl: './comparative-rater-login.component.html',
  styleUrls: ['./comparative-rater-login.component.scss']
})
export class ComparativeRaterLoginComponent extends BaseClass implements OnInit {

  public ErrorMessageConstant = ErrorMessageConstant;
  public LoginLabelsConstants = LoginLabelsConstants;
  public ReferenceCodeConstants = ReferenceCodeConstants;
  submitted = false;

  constructor(private router: Router,
            private route: ActivatedRoute,
            private comparativeRaterData: ComparativeRaterData,
            private modalRef: BsModalRef,
            private modalService: BsModalService) {
              super();
    }

  ngOnInit() {
    this.comparativeRaterData.initiateFormFields();
    this.comparativeRaterData.showLoginField = false;
    this.onLoadLogin();

    this.subscribeToModalTrigger();
  }

  get today(): Date { return new Date(); };

  get showChangeServerDate(): boolean { return environment.showChangeServerDate; }

  onLoadLogin(): void {
    Utils.blockUI();

    this.route.params.pipe(takeUntil(this.stop$)).subscribe(params => {
      this.comparativeRaterData.transactionId = params.tr;
    });

    this.route.queryParams.pipe(takeUntil(this.stop$)).subscribe(queryparams => {
      const comparativeCredentials = {
          un: queryparams.un,
          pwd: queryparams.pwd};
      const sessionCredentials = Boolean(sessionStorage.getItem('comparativeCredential')) ?
          JSON.parse(atob(sessionStorage.getItem('comparativeCredential'))) : '';

      if (Boolean(comparativeCredentials.un && comparativeCredentials.pwd) || Boolean(sessionCredentials)) {
        this.comparativeRaterData.username = comparativeCredentials.un ? comparativeCredentials.un : sessionCredentials.un;
        this.comparativeRaterData.password = comparativeCredentials.pwd ? comparativeCredentials.pwd : sessionCredentials.pwd;

        if (Boolean(comparativeCredentials.un && comparativeCredentials.pwd)) {
          sessionStorage.setItem('comparativeCredential', btoa(JSON.stringify(comparativeCredentials)));
          this.router.navigate(['.'], { relativeTo: this.route }); //Remove query parameters and trigger the observable
        } else {
          this.comparativeRaterData.userLogin(sessionCredentials.un, sessionCredentials.pwd);
        }
      } else {
        Utils.unblockUI();
        NotifUtils.showErrorWithoutConfirmation(LoginLabelsConstants.errorMessage.requiredCredentials);
        this.comparativeRaterData.resetLoginCredentials(true);
        sessionStorage.removeItem('comparativeCredential');
      }
    });

  }

  onSubmit(): void {
    this.submitted = true;

    this.comparativeRaterData.form.username.markAsDirty();
    this.comparativeRaterData.form.password.markAsDirty();

    if (this.comparativeRaterData.loginForm.invalid) {
      return;
    }

    Utils.blockUI();
    this.comparativeRaterData.userLogin(this.comparativeRaterData.form.username.value, this.comparativeRaterData.form.password.value);
  }

  subscribeToModalTrigger(): void {
    this.comparativeRaterData.referrenceCodeNotifier.subscribe(res => {
      if(res.isComplete && !res.hasError){
        this.showReferenceCodeModal(res.data);
      }
    });
  }

  showReferenceCodeModal(data: any): void {
    const initialState = {
      title: this.ReferenceCodeConstants.title,
      description1: this.ReferenceCodeConstants.description1,
      description2: this.ReferenceCodeConstants.description2,
      referenceNumber: data.referenceNumber,
      username: data.username
    };

    this.modalRef = this.modalService.show(ComparativeRaterReferenceCodeModalComponent, {
      initialState,
      class: 'modal-dialog-centered modal-sm',
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

}
