import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { PolicyBillingData } from '../../../../../../app/modules/policy-management/data/policy-billing.data';
import { ErrorMessageConstant } from '../../../../../../app/shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../../app/shared/constants/generic.labels.constants';
import { PolicyBillingLabelsConstants } from '../../../../../../app/shared/constants/policy-billing.labels.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { PolicyService } from '../../../../../core/services/submission/policy.service';
import { PolicySearchResultDTO } from '../../../../../shared/models/data/dto/suspended-payment/policy-list-dto';
import { SearchResultModalComponent } from '../../../../../modules/payments/suspended-payments/pending/pending-suspended-modal/post-suspended-payment-modal/search-result-modal/search-result-modal.component';
import { EntityRiskData } from '../../../../../modules/submission-management/data/entity-risk.data';
import Utils from '../../../../../shared/utilities/utils';

@Component({
  selector: 'app-transfer-payment-modal',
  templateUrl: './transfer-payment-modal.component.html',
  styleUrls: ['./transfer-payment-modal.component.scss']
})
export class TransferPaymentModalComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();

  transferPaymentForm: FormGroup;
  TransferPaymentConstants = PolicyBillingLabelsConstants.transferPayment;
  ErrorMessageConstant = ErrorMessageConstant;
  GenericLabel = GenericLabel;
  transferAmount: number;

  searchModalRef: BsModalRef | null;
  policySearchResult: PolicySearchResultDTO;
  noMatchFound: boolean = false;
  selectedRiskId: string;

  isInvalidPolicyNum: boolean = false;
  isTempRenewalPolicyNum: boolean = false;

  constructor(protected fb: FormBuilder,
    public bsModalRef: BsModalRef,
    protected billingData: PolicyBillingData,
    protected policyService: PolicyService,
    protected modalService: BsModalService,
    protected entityData: EntityRiskData) { }

  ngOnInit() {
    this.transferPaymentForm = this.fb.group({
      transferToPolicy: new FormControl(null, [Validators.required]),
      amount: new FormControl(null, [Validators.required]),
      comment: new FormControl('')
    });

    this.transferPaymentForm.get('amount').setValue(this.transferAmount);

    this.searchForIsSiebelWithDuplicate();
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  onTransfer(): void {
    this.triggerEvent({
      continue: true,
      policyNumber: this.transferPaymentForm.get('transferToPolicy').value,
      comment: this.transferPaymentForm.get('comment').value,
      amount: this.transferPaymentForm.get('amount').value,
      selectedRiskId: this.selectedRiskId ?? null
    });
  }

  onCancel(): void {
    this.triggerEvent({ continue: false });
  }

  triggerEvent(res: any) {
    this.event.emit({ data: res, res: 200 });
  }

  isTransferToSelf(): boolean {
    return !this.entityData.getRiskIsSiebel() ? this.billingData.data.risks[0].policyNumber === this.transferPaymentForm.get('transferToPolicy').value : false;
  }


  showSearchResultModal(result: PolicySearchResultDTO[]): void {
    const initialState = {
      isReversal: true,
      resultList: result,
      isSiebelWithDuplicatePolicyNumber: true
    };

    this.searchModalRef = this.modalService.show(SearchResultModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-xl modal-dialog-centered',
    });

    this.searchModalRef.content.event.subscribe((res) => {
      if (res) {
        this.noMatchFound = false;
        this.policySearchResult = res;
        this.selectedRiskId = res.riskId;
      }
      this.searchModalRef.hide();
    });
  }


  searchForIsSiebelWithDuplicate() {
    this.transferPaymentForm.get('transferToPolicy').valueChanges.subscribe(policyNum => {

      this.isInvalidPolicyNum = false;
      this.isTempRenewalPolicyNum = false;

      if (policyNum) {
        this.transferPaymentForm.get('transferToPolicy').setErrors({ invalid: true });
        this.policyService.searchPolicies(policyNum).subscribe(searchedPolicies => {
          if (searchedPolicies.length > 1) {
            Utils.blockUI();
            const renewalIndex = searchedPolicies.findIndex(x => x.statusProper.includes('Renewal'));
            if (renewalIndex >= 0) {
              searchedPolicies.splice(renewalIndex, 1);
            }
            this.showSearchResultModal(searchedPolicies);
            this.transferPaymentForm.get('transferToPolicy').setErrors(null);
            Utils.unblockUI();
          } else {
            if (searchedPolicies.length === 1 && !searchedPolicies[0].statusProper.includes('Renewal')) {
              this.transferPaymentForm.get('transferToPolicy').setErrors(null);
            }

            if (searchedPolicies.length === 1 && searchedPolicies[0].statusProper.includes('Renewal')) {
              this.isTempRenewalPolicyNum = true;
            }

            if (searchedPolicies.length === 0) {
              this.isInvalidPolicyNum = true;
            }
          }
        });
      }
    });
  }
}
