import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PathConstants } from 'app/shared/constants/path.constants';
import { AppAsideModule } from '@coreui/angular';
import { ModalModule } from 'ngx-bootstrap';
import { SuspendedPaymentsComponent } from './suspended-payments.component';

const routes: Routes = [
  {
    path: '',
    component: SuspendedPaymentsComponent,
    children: [
        {
          path: PathConstants.Payments.SuspendedPayments.Pending,
          loadChildren: () => import('./pending/pending.module').then(m => m.PendingModule)
        },
        {
          path: PathConstants.Payments.SuspendedPayments.Returned,
          loadChildren: () => import('./returned/returned.module').then(m => m.ReturnedModule)
        },
        {
          path: PathConstants.Payments.SuspendedPayments.SentToIpx,
          loadChildren: () => import('./sent-to-ipx/sent-to-ipx.module').then(m => m.SentToIpxModule)
        },
        { path: '', redirectTo: PathConstants.Payments.SuspendedPayments.Pending, pathMatch: 'full' },
      ]
  },
  {
    path: '',
    redirectTo: PathConstants.Payments.SuspendedPayments.Index,
    pathMatch: 'full'
  },
  // { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes), AppAsideModule, ModalModule.forRoot()],
  exports: [RouterModule],
  providers: []
})
export class SuspendedPaymentsRoutingModule { }
