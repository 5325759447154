import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PathConstants } from '../../../shared/constants/path.constants';
import { CanDeactivateApplicantComponentGuard } from './applicant-navigation-guard.service';
import { ApplicantComponent } from './applicant.component';

const routes: Routes = [
  {
    path: '',
    component: ApplicantComponent,
    canDeactivate: [CanDeactivateApplicantComponentGuard],
    children: [
      {
        path: PathConstants.Applicant.Applicant.ApplicantPage,
        component: ApplicantComponent
      },
      { path: '', redirectTo: PathConstants.Applicant.Applicant.ApplicantPage, pathMatch: 'full' },
      // { path: '**', redirectTo: '/404', pathMatch: 'full' },
    ]
  },
  // { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CanDeactivateApplicantComponentGuard]
})
export class ApplicantRoutingModule { }
