import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { PathConstants } from '../../shared/constants/path.constants';
import { AuthService } from '../services/auth.service';
import { MaintenanceModeService } from '../services/maintenance-mode.service';

@Injectable()
export class MaintenanceModeGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _maintenanceModeService: MaintenanceModeService,
    private _router: Router
  ) {

  }

  canActivate() {
    if (!this._maintenanceModeService.isValidRedirect) {
      this._router.navigate(
        this._authService.isLoggedIn() ? [PathConstants.NotFound] : [PathConstants.Login.Index]
      );
      return false;
    }

    return true;
  }
}
