import { Component, OnInit } from '@angular/core';
import { PathConstants } from 'app/shared/constants/path.constants';
import { NavigationService } from 'app/core/services/navigation/navigation.service';
import { ClickTypes } from '../../../shared/enum/click-type.enum';
import { PolicySummaryData } from '../data/policy-summary.data';
import { EntityRiskData } from '../../../modules/submission-management/data/entity-risk.data';
import { PolicyBillingData } from '../data/policy-billing.data';

@Component({
  selector: 'app-policy-summary',
  templateUrl: './policy-summary.component.html',
  styleUrls: ['./policy-summary.component.scss']
})
export class PolicySummaryComponent implements OnInit {

  constructor(public policySummaryData: PolicySummaryData,
    public navigationService: NavigationService,
    public entityRiskData: EntityRiskData,
    public policyBillingData: PolicyBillingData
    ) { }

  ngOnInit() {
  }

  public onClick(clickType?) {
    switch (clickType) {
    case ClickTypes.Back:
      // to do back
      break;
    case ClickTypes.Next:
      // to do next
      this.navigationService.navigatePolicyRoute(PathConstants.Policy.Policies.History);
      break;
    }
  }

}
