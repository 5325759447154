import { Injectable } from '@angular/core';
import { BaseClass } from 'app/shared/base-class';
import {
  ITableTr,
  ITableFormControl,
  ITableTd,
} from 'app/shared/models/dynamic/table.interface';
import { DatePipe } from '@angular/common';
import { BatchDashboardDTO } from 'app/shared/models/data/dto/book-transfer/batch-dashboard.dto';
import {
  BookTransferConstants,
  TransferStatusConstants,
  FromAgencyFormConstants,
  ToAgencyFormConstants,
  TransferDetailsFormConstants,
  TransferModeConstants,
  PoliciesConstants
} from 'app/shared/constants/book-transfer.constants';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { AuthService } from 'app/core/services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import * as moment from 'moment';
import { TransferQueueDTO } from 'app/shared/models/data/dto/book-transfer/transfer-queue.dto';
import { BookTransferService } from 'app/core/services/book-transfer.service';
import Utils from 'app/shared/utilities/utils';
import { ProgramStateAgencyService } from 'app/core/services/management/programstate-agency-service';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})

export class BookTransferData extends BaseClass {
  public dashboardLabel = BookTransferConstants.BatchDashboard.TableLables;
  public dashboardFields = BookTransferConstants.BatchDashboard.Fields;
  public transferStatusConstants = TransferStatusConstants;
  public transferFormLabels = {
    bookTransferConstants: BookTransferConstants,
    fromAgencyFormConstants: FromAgencyFormConstants,
    transferDetailsFormConstants: TransferDetailsFormConstants,
    toAgencyFormConstants: ToAgencyFormConstants,
    errorMessageConstant: ErrorMessageConstant,
    transferModeConstants: TransferModeConstants
  };

  public tableFormControls: ITableFormControl[] = [
    {
      name: 'field_1',
      type: 'string',
    },
    {
      name: 'field_2',
      type: 'string',
    },
    {
      name: 'field_3',
      type: 'string',
    },
    {
      name: 'field_4',
      type: 'string',
    },
    {
      name: 'field_5',
      type: 'string',
    },
    {
      name: 'field_6',
      type: 'string',
    },
    {
      name: 'field_7',
      type: 'string',
    },
    {
      name: 'field_8',
      type: 'string',
    },
    {
      name: 'field_9',
      type: 'string',
    },
    {
      name: 'field_10',
      type: 'string',
    },
    {
      name: 'field_11',
      type: 'string',
    },
    {
      name: 'field_12',
      type: 'string',
    },
    {
      name: 'field_13',
      type: 'string',
    },
    {
      name: 'field_14',
      type: 'string',
    },
    {
      name: 'field_15',
      type: 'string',
    }
  ];

  public batchQueueTableRows: ITableTr[] = [];
  public currentPage: number = 1;

  public fromAgencyForm: FormGroup;
  public toAgencyForm: FormGroup;
  public transferDetailsForm: FormGroup;

  public agencies: any[];
  public fromAgencySubAgencies: any[];
  public toAgencySubAgencies: any[];

  public fromAgencyNames: any[];
  public fromSubAgencyNames: any[];
  public toAgencyNames: any[];
  public toSubAgencyNames: any[];
  public toSubAgencyAgents: any[];

  public disableFromAgencyName: boolean = true;
  public disableFromAgencySubAgencyName: boolean = true;
  public disableToAgencyName: boolean = true;
  public disableToAgencySubAgencyName: boolean = true;

  public currentBatchData: BatchDashboardDTO;
  public currentQueueData: TransferQueueDTO[];
  public currentFromAgencyId: string = '';
  public currentFromSubAgencyId: string = '';
  public currentToAgencyId: string = '';
  public currentToSubAgencyId: string = '';

  public policies: any[] = [];
  public policiesBySubAgency: any[] = [];
  public toTransferPolicies: any[] = [];
  public forTransferPolicies: any[] = [];
  public transferAllSelected: boolean = false;
  public removeAllSelected: boolean = false;
  public isEditMode: boolean = false;

  public fromAgencyNameModel: any;
  public fromSubAgencyNameModel: any;
  public toAgencyNameModel: any;
  public toSubAgencyNameModel: any;
  public disableUntil: any = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date() - 1
  };

  public transferDateOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'mm/dd/yyyy',
    disableUntil: this.disableUntil
  };

  public toTransferSearchKeyword: string = '';
  public isToTransferSearch: boolean = false;
  public isForTransferSearch: boolean = false;
  public forTransferSearchKeyword: string = '';
  public dateFormat: string = 'YYYY-MM-DDTHH:mm:ss';
  public policiesConstants = PoliciesConstants;
  public clearToTransferSearchBox: boolean = false;
  public clearForTransferSearchBox: boolean = false;
  public enableDebounceTime: boolean = true;

  constructor(
    private authService: AuthService,
    private bookTransferService: BookTransferService,
    private programStateAgencyService: ProgramStateAgencyService,
    private router: Router
  ) {
    super();
  }

  public resetData(): void {
    this.batchQueueTableRows = [];
    this.currentPage = 1;
    this.agencies = [];
    this.fromAgencySubAgencies = [];
    this.toAgencySubAgencies = [];
    this.fromAgencyNames = [];
    this.fromSubAgencyNames = [];
    this.toAgencyNames = [];
    this.toSubAgencyNames = [];
    this.toSubAgencyAgents = [];
    this.disableFromAgencyName = true;
    this.disableFromAgencySubAgencyName = true;
    this.disableToAgencyName = true;
    this.disableToAgencySubAgencyName = true;
    this.currentBatchData = null;
    this.currentQueueData = [];
    this.currentFromAgencyId = '';
    this.currentFromSubAgencyId = '';
    this.currentToAgencyId = '';
    this.currentToSubAgencyId = '';
    this.policies = [];
    this.policiesBySubAgency = [];
    this.toTransferPolicies = [];
    this.forTransferPolicies = [];
    this.transferAllSelected = false;
    this.removeAllSelected = false;
    this.fromAgencyNameModel = '';
    this.fromSubAgencyNameModel = '';
    this.toAgencyNameModel = '';
    this.toSubAgencyNameModel = '';
    this.toTransferSearchKeyword = '';
    this.isToTransferSearch = false;
    this.isForTransferSearch = false;
    this.forTransferSearchKeyword = '';
    this.clearToTransferSearchBox = false;
    this.clearForTransferSearchBox = false;
    this.enableDebounceTime = true;

  }

  public checkIfInternalUser(): boolean {
    return this.authService.userType.value === GenericConstants.userType.internal;
  }

  public addTableItem(newItem: any): void {
    newItem.map(currentItem => {
      let tr: ITableTd[] = [];

      const fields: string[] = [
        this.dashboardFields.fromAgencyCode,
        this.dashboardFields.fromAgencyName,
        this.dashboardFields.fromSubAgencyCode,
        this.dashboardFields.fromSubAgencyName,
        this.dashboardFields.toAgencyCode,
        this.dashboardFields.toAgencyName,
        this.dashboardFields.toSubAgencyCode,
        this.dashboardFields.toSubAgencyName,
        this.dashboardFields.isMassTransfer,
        this.dashboardFields.transferDate,
        this.dashboardFields.transferReason,
        this.dashboardFields.statusCode
      ];

      fields.map((item) => {
        let display: any;
        switch (item) {
          case this.dashboardFields.transferDate:
            if (currentItem[item]) {
              const createdDate = new Date(currentItem[item]);
              display = new DatePipe('en-US').transform(createdDate, 'MM/dd/yyyy');
            } else {
              display = '';
            }
          break;
          case this.dashboardFields.isMassTransfer:
            const isMassTransfer = currentItem[item];
            display = isMassTransfer ? 'Mass' : 'Selective';
            break;
          default:
            display = currentItem[item];
            break;
        }

        tr = [...tr, {
          id: currentItem.id,
          value: currentItem[item],
          display: display
        }];
      });

      this.batchQueueTableRows = [...this.batchQueueTableRows, {
        id: currentItem.id,
        tr: tr
      }];
    });
  }

  public async getAgencies(): Promise<void> {
    Utils.blockUI();

    this.agencies = await this.programStateAgencyService.getAllProgramStateAgencyPromise();

    this.agencies = this.agencies.filter(item => {
      if (item.agency.entity.companyName) {
        return item;
      }
    });

    if (this.agencies) {
      this.disableFromAgencyName = false;
      this.disableToAgencyName = false;

      const agencyNames = this.agencies.map((item: any) => {
        return {
          id: item.agencyId,
          name: item.agency.entity.companyName,
          disabled: false
        };
      });

      this.fromAgencyNames = agencyNames;
      this.toAgencyNames = agencyNames;
    }
  }

  public initBookTransferForms(): void {
    this.fromAgencyForm = this.initFromAgencyForm();
    this.toAgencyForm = this.initToAgencyForm();
    this.transferDetailsForm = this.initTransferDetailsForm();
  }

  public initFromAgencyForm(): FormGroup {
    return new FormGroup({
      fromAgencyName: new FormControl('', [Validators.required]),
      fromAgencyCode: new FormControl({ value: '', disabled: true }, [Validators.required]),
      fromSubAgencyName: new FormControl('', [Validators.required]),
      fromSubAgencyCode: new FormControl({ value: '', disabled: true }, [Validators.required])
    });
  }

  public initToAgencyForm(): FormGroup {
    return new FormGroup({
      toAgencyName: new FormControl('', [Validators.required]),
      toAgencyCode: new FormControl({ value: '', disabled: true }, [Validators.required]),
      toSubAgencyName: new FormControl('', [Validators.required]),
      toSubAgencyCode: new FormControl({ value: '', disabled: true }, [Validators.required])
    });
  }

  public initTransferDetailsForm(): FormGroup {
    return new FormGroup({
      transferDate: new FormControl({
        singleDate: {
          date: {
            year: moment().year(),
            month: moment().month() + 1,
            day: moment().date()
          },
          jsDate: moment
        }
      }, Validators.required),
      transferReason: new FormControl('', [Validators.required])
    });
  }

  public async loadTransferDetailsFromDashboard(): Promise<void> {
    const batch = this.currentBatchData;
    const queue = this.currentQueueData;

    this.populateTranferForms(batch, queue);
    this.getQueuedPolicies(queue);
    await this.getPoliciesToTransferByAgencySubAgency(batch.fromAgencyId, batch.fromSubAgencyId);
    this.getPoliciesToTransfer();
    Utils.unblockUI();
  }

  public getQueuedPolicies(queue: any): void {
    const policyList: any[] = [];

    if (queue.length > 0) {
      queue.forEach(policy => {
        policyList.push({
          id: policy.policyNumber,
          policyNumber: policy.policyNumber,
          isSelected: false,
          isForTransfer: true,
          statusCode: policy.statusCode,
          fromAgentId: policy.fromAgentId,
          riskId: policy.riskId
        });
      });

      this.policies = policyList;
      this.getPoliciesForTransfer();
    }
  }

  public populateTranferForms(batch: any, queue: any): void {
    this.disableFromAgencySubAgencyName = false;
    this.disableToAgencySubAgencyName = false;

    this.fromAgencyForm.get('fromAgencyName').patchValue(batch.fromAgencyName);
    this.fromAgencyForm.get('fromAgencyCode').patchValue(batch.fromAgencyCode);
    this.fromAgencyForm.get('fromSubAgencyName').patchValue(batch.fromSubAgencyName);
    this.fromAgencyForm.get('fromSubAgencyCode').patchValue(batch.fromSubAgencyCode);

    this.toAgencyForm.get('toAgencyName').patchValue(batch.toAgencyName);
    this.toAgencyForm.get('toAgencyCode').patchValue(batch.toAgencyCode);
    this.toAgencyForm.get('toSubAgencyName').patchValue(batch.toSubAgencyName);
    this.toAgencyForm.get('toSubAgencyCode').patchValue(batch.toSubAgencyCode);

    this.transferDetailsForm.get('transferDate').setValue({
      isRange: false,
      singleDate: {
        jsDate: new Date(batch.transferDate)
      }
    });
    this.transferDetailsForm.get('transferReason').setValue(batch.transferReason);
  }

  public async getPoliciesToTransferByAgencySubAgency(agencyId: string, subAgencyId: string = ''): Promise<void> {
    const policyList: any[] = [];

    await this.bookTransferService.getPoliciesByAgencySubAgency(agencyId, subAgencyId).then(policies => {
      this.policiesBySubAgency = policies;

      if (policies.length > 0) {
        policies.forEach(policy => {
          const currentQueuePolicy = this.currentQueueData.find(currentQueue => {
            return policy.policyNumber === currentQueue.policyNumber;
          });

          if (!currentQueuePolicy) {
            policyList.push({
              id: policy.policyNumber,
              policyNumber: policy.policyNumber,
              isSelected: false,
              isForTransfer: false,
              statusCode: null,
              fromAgentId: policy.agentId,
              riskId: policy.id
            });
          }
        });

        this.policies = [...this.policies, ...policyList];

        return policyList;
      }

      this.policies = [...this.policies, ...policyList];

      return policyList;
    });
  }

  public getPoliciesForTransfer(): void {
    this.forTransferPolicies = this.policies.filter(policy => {
      return policy.isForTransfer;
    });
  }

  public getPoliciesToTransfer(): void {
    this.toTransferPolicies = this.policies.filter(policy => {
      return !policy.isForTransfer;
    });

    return;
  }

  public clearFormValidators(form: FormGroup): void {
    for (const key in form.controls) {
      form.get(key).clearValidators();
      form.get(key).updateValueAndValidity();
    }
  }

  public get isBatchStatusCompleted(): boolean {
    return this.currentBatchData?.statusCode === this.transferStatusConstants.completed;
  }

  getTransferEffectiveDate(policy: any): string {
    if (policy.renewalStatusCode === 'RNPEN') {
      return policy.firstIssueDate;
    } else {
      return String(moment(policy.firstIssueDate).add(1, 'years').format(this.dateFormat));
    }
  }

  onCancel(): void {
    NotifUtils.showConfirmMessage(`Are you sure you want to leave this page?`,
      () => {
        this.router.navigate(['book-transfer', 'batch-dashboard']);
        Utils.unblockUI();
      },
      () => { }
    );
  }

  policyToTransferClicked(policyId: string): void {
    this.policies = this.policies.map(policy => {
      if (policyId === policy.id) {
        policy.isSelected = !policy.isSelected;
      }

      return policy;
    });

    if (this.isToTransferSearch) {
      this.searchToTransferPolicies(this.toTransferSearchKeyword);
    } else {
      this.getPoliciesToTransfer() ;
    }

    this.resetTransferAllSelectedPolicies(false);
  }

  resetTransferAllSelectedPolicies(fromTransferSelected: boolean = true): void {
    if (fromTransferSelected) {
      if (this.toTransferPolicies.length === 0) {
        this.transferAllSelected = false;
      }

      const forTransferPoliciesAllSelected = this.forTransferPolicies.every(policy => {
        return policy.isSelected === true;
      });

      if (forTransferPoliciesAllSelected) {
        this.removeAllSelected = true;
      }

      return;
    }

    const toTransferPoliciesAllSelected = this.toTransferPolicies.every(policy => {
      return policy.isSelected === true;
    });

    if (!toTransferPoliciesAllSelected) {
      this.transferAllSelected = false;
    }
  }

  transferSelectedClicked(): void {
    this.policies = this.policies.map(policy => {
      if (policy.isSelected && !policy.isForTransfer) {
        policy.isForTransfer = true;
      }

      return policy;
    });

    this.getPoliciesToTransfer();
    this.getPoliciesForTransfer();
    this.resetTransferAllSelectedPolicies();
    this.resetRemoveAllSelectedPolicies();
  }

  resetRemoveAllSelectedPolicies(fromRemoveSelected: boolean = true): void {
    if (fromRemoveSelected) {
      if (this.forTransferPolicies.length === 0) {
        this.removeAllSelected = false;
      }

      const toTransferPoliciesAllSelected = this.toTransferPolicies.every(policy => {
        return policy.isSelected === true;
      });

      if (toTransferPoliciesAllSelected) {
        this.transferAllSelected = true;
      }

      return;
    }

    const forTransferPoliciesAllSelected = this.forTransferPolicies.every(policy => {
      return policy.isSelected === true;
    });

    if (!forTransferPoliciesAllSelected) {
      this.removeAllSelected = false;
    }
  }

  policyForTransferClicked(policyId: string): void {
    this.policies = this.policies.map(policy => {
      if (policyId === policy.id) {
        policy.isSelected = !policy.isSelected;
      }

      return policy;
    });

    if (this.isForTransferSearch) {
      this.searchForTransferPolicies(this.forTransferSearchKeyword);
    } else {
      this.getPoliciesForTransfer();
    }

    this.resetRemoveAllSelectedPolicies(false);
  }

  removeSelectedClicked(): void {
    this.policies = this.policies.map(policy => {
      if (policy.isSelected && policy.isForTransfer) {
        policy.isForTransfer = false;
      }

      return policy;
    });

    this.getPoliciesToTransfer();
    this.getPoliciesForTransfer();
    this.resetTransferAllSelectedPolicies();
    this.resetRemoveAllSelectedPolicies();
  }

  transferAllClicked(): void {
    this.transferAllSelected = !this.transferAllSelected;

    this.policies = this.policies.map(policy => {
      if (!policy.isForTransfer) {
        policy.isSelected = this.transferAllSelected;
      }

      return policy;
    });

    this.getPoliciesToTransfer();
  }

  removeAllClicked(): void {
    this.removeAllSelected = !this.removeAllSelected;

    this.policies = this.policies.map(policy => {
      if (policy.isForTransfer) {
        policy.isSelected = this.removeAllSelected;
      }

      return policy;
    });

    this.getPoliciesForTransfer();
  }

  searchToTransferPolicies(keyword: string): void {
    this.toTransferSearchKeyword = keyword.trim().toUpperCase();

    this.getPoliciesToTransfer();

    if (this.toTransferSearchKeyword !== '') {
      this.isToTransferSearch = true;

      this.toTransferPolicies = this.toTransferPolicies.filter(policy => {
        return policy.policyNumber?.toUpperCase().includes(this.toTransferSearchKeyword);
      });

      return;
    }

    this.isToTransferSearch = false;
  }

  searchForTransferPolicies(keyword: string): void {
    this.forTransferSearchKeyword = keyword.trim().toUpperCase();

    this.getPoliciesForTransfer();

    if (this.forTransferSearchKeyword !== '') {
      this.isForTransferSearch = true;

      this.forTransferPolicies = this.forTransferPolicies.filter(policy => {
        return policy.policyNumber?.toUpperCase().includes(this.forTransferSearchKeyword) ||
          policy.statusCode?.toUpperCase().includes(this.forTransferSearchKeyword);
      });

      return;
    }

    this.isForTransferSearch = false;
  }

  get isMassTransfer(): boolean {
    return this.toTransferPolicies.length === 0 && this.forTransferPolicies.length !== 0;
  }
}