import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { PolicyBillingData } from 'app/modules/policy-management/data/policy-billing.data';
import { AuthService } from '../services/auth.service';

@Injectable()
export class MakeAPaymentGuard implements CanActivate {
  constructor(
    private _router: Router,
    public billingData: PolicyBillingData
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const authKey = localStorage.getItem(environment.AuthKey);
    const decodedJWT = authKey ? JSON.parse(window?.atob(authKey?.split('.')[1])) : null;
    const isInsured = decodedJWT?.is_insured;
    if (this.billingData.isMakeAPaymentRedirect) {
      return true;
    } else {
      if (isInsured) {
        localStorage.removeItem('auth');
        this._router.navigate(['404'], {queryParams: {r: 't'}});
      } else {
        this._router.navigate(['404']);
      }
      return false;
    }
  }
}
