import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { FormDocumentService } from './form-document.service';
import { BaseClass } from '../../../../shared/base-class';
import { SummaryData } from '../../../../modules/submission-management/data/summary.data';
import { LocalStorageService } from '../../local-storage.service';
import { EntityRiskData } from '../../../../modules/submission-management/data/entity-risk.data';
import { Rater } from '../../../../shared/constants/rater.constant';
import { ViewQuoteProposalDTO } from 'app/shared/models/data/dto/form-document/viewQuoteProposalDTO';
import Utils from 'app/shared/utilities/utils';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { PolicyService } from '../policy.service';

@Injectable({
  providedIn: 'root'
})
export class FormDocumentData extends BaseClass {

  constructor(
    protected formDocumentService: FormDocumentService,
    protected summary: SummaryData,
    protected localStorage: LocalStorageService,
      protected entity: EntityRiskData,
      public policyService: PolicyService
  ) {
    super();
  }

  viewQuoteProposalRequest(): void {
    const riskId = this.summary.SummaryForm.get('riskId').value;
    const riskDetailId = this.entity.getRiskDetailId();
    const raterResultKey = `${Rater.raterResult}_${riskDetailId}`;
    const raterResult = this.localStorage.retrieve(raterResultKey);

    const viewQuoteProposalDTO: ViewQuoteProposalDTO = {
      riskId: riskId,
      raterResult: raterResult
    };

    this.formDocumentService.post(viewQuoteProposalDTO).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.policyService.generateSASURL(result).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
        const win = window.open(resultSASURL, '_blank');
        if (win) {
          win.focus();
          Utils.isGeneratingQuoteProposal = false;
          Utils.unblockUI();
        } else {
          NotifUtils.showError('To View Quote Proposal, please allow pop-ups on your browser.');
        }
      });
    }, error => {
      console.log(error);
      Utils.isGeneratingQuoteProposal = false;
      Utils.unblockUI();
      NotifUtils.showError(ErrorMessageConstant.quoteProposalErrorMessage);
    }, () => {
      Utils.isGeneratingQuoteProposal = false;
      Utils.unblockUI();
    });
  }
}