import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-preloader-three-bounce',
  templateUrl: './preloader-three-bounce.component.html',
  styleUrls: ['./preloader-three-bounce.component.scss']
})
export class PreloaderThreeBounceComponent implements OnInit {
  // Note: Number values are measured in Pixels

  @Input() dotColor: string = '#20a8d8';
  @Input() dotSize: number = 16;

  dotStyle: any;

  constructor() { }

  ngOnInit(): void {
    this.dotStyle = {
      'background-color': `${ this.dotColor }`,
      'width': `${ this.dotSize }px`,
      'height': `${ this.dotSize }px`
    }
  }
}
