import { Injectable } from '@angular/core';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';
import { QuoteService } from 'app/core/services/quote.service';
import { BaseClass } from 'app/shared/base-class';
import { SubmissionConstants } from 'app/shared/constants/submission.constants';

@Injectable({
  providedIn: 'root',
})

export class SubmissionDefaultData extends BaseClass {
  constructor(protected quoteService: QuoteService) {
    super();
  }

  getPropertyUsageDefault(): string {
    if(!!!this.quoteService?.formType) { return ''; }

    switch (this.quoteService?.formType) {
      case FormTypeConstants.HO3:
      case FormTypeConstants.DP3:
        return SubmissionConstants.propertyUsageHO3DP3;
      case FormTypeConstants.HO4:
        return SubmissionConstants.propertyUsageHO4;
      case FormTypeConstants.HO6:
        return SubmissionConstants.propertyUsageHO6;
      default:
        return '';
    }
  }

  getStructureTypeDefault(): string {
    if(!!!this.quoteService?.formType) { return ''; }

    switch (this.quoteService?.formType) {
      case FormTypeConstants.HO3:
      case FormTypeConstants.DP3:
        return SubmissionConstants.structureTypeHO3DP3;
      case FormTypeConstants.HO4:
        return SubmissionConstants.structureTypeHO4;
      case FormTypeConstants.HO6:
        return SubmissionConstants.structureTypeHO6;
      default:
        return '';
    }
  }
}