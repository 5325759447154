export class ResidentialResponseDTO {

  yearBuilt: string;

  squareFootage: string;

  valuationId: string;

  replacementCost: string;

  replacementCostDate: any;

  pricingChanged: string;

  latitude: string;

  longitude: string;

  numberOfStories: NumberOfStories;

  exteriorWallFinish: ExteriorWallFinish;

  construction: Construction;

  roofShape: RoofShape;

  foundationType: FoundationType;

  structureType: StructureType;

  roofMaterial: RoofMaterial;

  isNoHitAddress: boolean;

  costPerSqft: string;

  street?: string;

  streetNum?: string;

  streetName?: string;

  streetType?: string;

  city?: string;

  unit?: string;

  zipCode?: string;

  state?: string;

  public constructor(init?: Partial<ResidentialResponseDTO>) {
    Object.assign(this, init);
  }
}

export class BasePropertyDataModel {

  iso360ValueCode: string;

  code: string;

  description: string;

  public constructor(init?: Partial<BasePropertyDataModel>) {
    Object.assign(this, init);
  }
}

export class ExteriorWallFinish extends BasePropertyDataModel { }

export class Construction extends BasePropertyDataModel { }

export class StructureType extends BasePropertyDataModel { }

export class NumberOfStories extends BasePropertyDataModel { }

export class RoofShape extends BasePropertyDataModel { }

export class FoundationType extends BasePropertyDataModel { }

export class RoofMaterial extends BasePropertyDataModel { }
