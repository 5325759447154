export const LA_PropertyValidatorErrorConstants = {
  yearRoofShouldNotBeOlderThanYearBuiltErrorMessage: {
    key: 'yearRoofShouldNotBeOlderThanYearBuilt',
    value: 'Year of roof cannot be older than year of construction.'
  },
  yearRoofInstalledCannotBeAFutureYearErrorMessage: {
    key: 'yearRoofInstalledCannotBeAFutureYear',
    value: 'Year Roof Installed cannot be more than 1 year in the future.'
  },
  yearShouldNotBeOlderThanYearBuiltErrorMessage: {
    key: 'yearShouldNotBeOlderThanYearBuilt',
    value: '{0} cannot be prior to year built.'
  },
  yearInstalledCannotBeAFutureYearErrorMessage: {
    key: 'yearInstalledCannotBeAFutureYear',
    value: '{0} cannot be more than 1 year in the future.'
  },
  purchaseDateCannotBeGreaterThanEffectiveDateErrorMessage: {
    key: 'purchaseDateCannotBeGreaterThanEffectiveDate',
    value: 'Purchase Date cannot be greater than the Policy Effective Date.'
  },
  riskOwnerOccupiedPrimaryCannotBeRentedErrorMessage: {
    key: 'riskOwnerOccupiedPrimaryCannotBeRented',
    value: 'Risk cannot be rented if owner occupied primary.'
  },
  numberOfUnitsInsuredInvalidSelectionErrorMessage: {
    constants: {
      STDuplex: '2',
      STTriplex: '3',
      STFourplex: '4',
      UIThreeFamily: 'UI3',
      UIFourFamily: 'UI4',
      UIFiveFamily: 'UI5'
    },
    key: 'numberOfUnitsInsuredInvalidSelection',
    value: 'Selection is invalid for Structure Type'
  },
  dwellingInTheCourseOfConstructionBRUL333ErrorMessage: {
    key: 'dwellingInTheCourseOfConstructionBRUL333ErrorMessage',
    value: 'Property Usage should be "Owner Occupied Primary" when Dwelling in the Course of Construction is enabled.'
  },
  dwellingInTheCourseOfConstructionBRUL329ErrorMessage: {
    key: 'dwellingInTheCourseOfConstructionBRUL329ErrorMessage',
    value: 'Year Built should be the same as dwelling year'
  }
};
