import { Component, OnInit } from '@angular/core';
import { SubmissionPageData } from '../../../../../modules/submission-management/data/submission-page.data';
import { BindAndIssueData } from '../../../../../modules/submission-management/data/bind-and-issue.data';
import { PaperlessDocumentsLabelConstants } from '../../../../../shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { BindAndIssueService } from '../bind-and-issue.service';
import { GeneralValidationService } from '../../../../../core/services/submission/validations/general-validation.service';
import { Validators } from '@angular/forms';
import { CustomValidators } from '../../../../../shared/validators/custom.validator';
import FormUtils from '../../../../../shared/utilities/form.utils';
@Component({
  selector: 'app-paperless-documents',
  templateUrl: './paperless-documents.component.html',
  styleUrls: ['./paperless-documents.component.scss']
})
export class PaperlessDocumentsComponent implements OnInit {
  PaperlessDocumentsLabelConstants = PaperlessDocumentsLabelConstants;
  ErrorMessageConstant = ErrorMessageConstant;
  isOpen: boolean = true;
  public FormUtils = FormUtils;

  constructor(public bindAndIssueData: BindAndIssueData,
      protected submissionData: SubmissionPageData,
      protected bindAndIssueService: BindAndIssueService,
      protected generalValidationService: GeneralValidationService
      ) { }

  ngOnInit() {
    this.generalValidationService.clearValidatorFormControl(this.bindAndIssueData.paperlessDocumentForm, 'agreement');
    this.generalValidationService.resetValidatorFormControl(this.bindAndIssueData.paperlessDocumentForm, 'agreement', [Validators.required, CustomValidators.spaceValidator]);
    this.bindAndIssueData.paperlessDocumentForm.get('agreement').markAsDirty();
    this.bindAndIssueData.paperlessDocumentForm.get('agreement').markAsTouched();
    if (!this.bindAndIssueData.paperlessDocumentForm.get('agreement').value) {
      const error = {
        required: true
      };
      this.bindAndIssueData.paperlessDocumentForm.get('agreement').setErrors(error);
    }
  }

  collapse() {
    this.isOpen = !this.isOpen;
    this.submissionData.isPaperlessOpen = this.isOpen;
  }

  onSelect(event?) {
    this.bindAndIssueService.checkPaperlessSection();
  }

  onChangeAgreement() {
    const parentform = this.bindAndIssueData.paperlessDocumentForm;
    if (!parentform.get('agreement').value) {
      parentform.get('agreement').setValue(null);
    }
  }
}