import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ClickTypes } from '../../../../shared/enum/click-type.enum';
import { SubmissionPageData } from '../../data/submission-page.data';
import { SummaryData } from '../../data/summary.data';
import { QuickQuoteService } from '../../../../core/services/submission/quick-quote.service';
import { RiskBindSignatureDTO } from '../../../../shared/models/data/dto/quick-quote/risk-bind-signature.dto';
import { RiskBindPaperlessDTO } from '../../../../shared/models/data/dto/quick-quote/risk-bind-paper-less.dto';
import { RiskBindBillingDTO } from '../../../../shared/models/data/dto/quick-quote/risk-bind-billing.dto';
import { RiskBindDTO } from '../../../../shared/models/data/dto/quick-quote/risk-bind-dto';
import { SaveBindAndIssueDTO } from '../../../../shared/models/data/dto/quick-quote/save-bind-and-issue.dto';
import { BindAndIssueData } from '../../data/bind-and-issue.data';
import { RiskBindNoteDTO } from '../../../../shared/models/data/dto/quick-quote/risk-bind-note.dto';
import NotifUtils from '../../../../shared/utilities/notif-utils';
import { NavigationService } from '../../../../core/services/navigation/navigation.service';
import { BindAndIssueService } from './bind-and-issue.service';
import { GeneralValidationService } from '../../../../core/services/submission/validations/general-validation.service';
import { CoveragesValidationService } from '../../../../core/services/submission/validations/coverages-validation.service';
import { PropertyValidationService } from '../../../../core/services/submission/validations/property-validation.service';
import { CoveragesData } from '../../data/coverages.data';
import { PathConstants } from '../../../../shared/constants/path.constants';
import { PageSectionsValidations } from '../../../../shared/enum/page-sections.enum';
import Utils from '../../../../shared/utilities/utils';
import { UwApprovalData } from '../../data/uw-approval.data';
import { NotesCategoryConstants } from '../../../../shared/constants/notes.options.constants';
import { OfacService } from '../../../../core/services/submission/third-party/ofac/ofac.service';
import { OfacSearchRequestDTO } from '../../../../shared/models/data/dto/ofac/ofac-search-request.dto';
import { ApplicantData } from '../../data/applicant.data';
import { BaseComponent } from '../../../../shared/base-component';
import { SubmissionService } from '../../../../core/services/submission/submission.service';
import { EntityRiskData } from '../../data/entity-risk.data';
import { ApplicantValidationService } from '../../../../core/services/submission/validations/applicant-validation.service';
import { BindAndIssueValidationService } from '../../../../core/services/submission/validations/bind-and-issue-validation.service';
import { SubmissionNavValidationService } from '../../../../core/services/navigation/submission-nav-validation.service';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { BindAndIssueLabelConstants, PaymentPlanListConstants } from '../../../../shared/constants/bind-and-issue.labels.constants';
import { EndorsementsSavingData } from '../../data/endorsements.saving.data';
import { PaymentMethod } from '../../../../../app/shared/enum/payment-method.enum';
import { BindAndIssueSavingData } from '../../data/bind-and-issue-saving.data';
import { ThirdPartyData } from '../../data/third-party.data';
import { ErrorMessageConstant } from '../../../../shared/constants/error-message.constants';
import { InterestSavingData } from '../../data/interest-saving.data';
import { TaskGenerationService } from '../../../../core/services/submission/task-generation/task-generation.service';
import { GenericConstants } from '../../../../shared/constants/generic.constants';
import { AuthService } from '../../../../core/services/auth.service';
import { distinctUntilChanged, share, take, takeUntil } from 'rxjs/operators';
import { QuickQuoteValidationService } from '../../../../core/services/submission/validations/quick-quote-validation.service';
import { Rater } from '../../../../shared/constants/rater.constant';
import { CalculateRaterData } from '../../../../core/services/submission/rater-premium/calculate-rater-data.service';
import { UpdateRiskRequestDTO } from '../../../../shared/models/data/dto/billing/update-risk-request.dto';
import { CreditOrderStatusConstants } from 'app/shared/constants/coverages.options.constants';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { ClaimsData } from '../../data/claims.data';
import { RaterPremiumService } from 'app/core/services/submission/rater-premium/rater-premium.service';
import { PolicyBillingData } from 'app/modules/policy-management/data/policy-billing.data';
import { UploadCheckDTO } from '../../../../shared/models/data/dto/quick-quote/upload-check.dto';
import { updateAppIsLoadingFromBindAndIssueComponent } from 'app/store/app/app.actions';
import { Store } from '@ngrx/store';
import { MoratoriumType } from 'app/shared/constants/moratoriumType';
import { InterestData } from '../../data/interest.data';
import Swal from 'sweetalert2';
import { SubmissionConstants } from 'app/shared/constants/submission.constants';

@Component({
  selector: 'app-bind-and-issue',
  templateUrl: './bind-and-issue.component.html',
  styleUrls: ['./bind-and-issue.component.scss']
})
export class BindAndIssueComponent extends BaseComponent implements OnInit, OnDestroy {
  currentDate: Date = this.authService.getCustomDate();
  effectiveDate: Date;
  userType: string;
  genericConstants = GenericConstants;
  creditOrderStatusConstants = CreditOrderStatusConstants;
  submissionConstants = SubmissionConstants;
  hasClosure: boolean = false;

  constructor(
    public submissionData: SubmissionPageData,
    protected router: Router,
    protected summaryData: SummaryData,
    public bindAndIssueData: BindAndIssueData,
    protected quickQuoteService: QuickQuoteService,
    public navigationService: NavigationService,
    protected bindAndIssueService: BindAndIssueService,
    protected generalValidationsService: GeneralValidationService,
    protected coveragesValidationService: CoveragesValidationService,
    public coveragesData: CoveragesData,
    protected propertyValidationService: PropertyValidationService,
    public uwApprovalData: UwApprovalData,
    public ofacService: OfacService,
    public applicantData: ApplicantData,
    public submissionService: SubmissionService,
    public entityRiskData: EntityRiskData,
    protected applicantValidationService: ApplicantValidationService,
    public bindAndIssueValidationService: BindAndIssueValidationService,
    public quickQuoteValidationService: QuickQuoteValidationService,
    public submissionNavValidationService: SubmissionNavValidationService,
    public formValidation: SubmissionNavValidationService,
    public raterData: CalculateRaterData,
    public storage: LocalStorageService,
    protected endorsementsSavingData: EndorsementsSavingData,
    protected thirdPartyData: ThirdPartyData,
    protected interestData: InterestData,
    protected interestSavingData: InterestSavingData,
    protected taskGenService: TaskGenerationService,
    protected authService: AuthService,
    public policySummaryData: PolicySummaryData,
    protected claimsData: ClaimsData,
    protected raterPremiumService: RaterPremiumService,
    protected policyBillingData: PolicyBillingData,
    protected store: Store
  ) {
    super();
  }

  ngOnInit() {
    sessionStorage.setItem(PageSectionsValidations.CurrentPage, PathConstants.PageDestination.BindAndIssue);
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.userType = userType;
    });
    this.allowBindAndIssueAccess();
    this.subscribeToUwApprovalListComplete();

    if (this.entityRiskData?.EntityRisk?.risks[0]?.renewalCode !== null && localStorage.getItem('pageType') !== 'policies') {
      this.policyBillingData.populateRnBillingSummary(this.entityRiskData.getRiskDetailId());
    }

    this.checkClosureForExternalUser();
    this.quickQuoteValidationService.isQuickQuoteEffectivaDateValid();
  }

  // Defensive Code to check Bind And Issue can be accessed
  allowBindAndIssueAccess(): void {
    this.updateUwApprovalData();

    if (this.submissionNavValidationService.isBindAndIssueDisabled) {
      const currentURL: string = this.router.url;
      const currentURLParts: string[] = currentURL.split('/');
      let newURL: string = '';

      currentURLParts[currentURLParts.length - 1] = PathConstants.Submission.Submission.UWApproval;
      newURL = currentURLParts.join('/');

      this.router.navigate([newURL]);
    }
  }

  updateUwApprovalData(): void {
    this.submissionData.hasAir = this.uwApprovalData.air;
    this.submissionData.hasDecline = this.uwApprovalData.decline;
    this.submissionData.uwrListEmpty = this.uwApprovalData.uwrListEmpty;
    this.submissionData.hasPostBindUwrItem = this.uwApprovalData.hasPostBindUwrItem;
    this.submissionData.uwrListAllPostBind = this.uwApprovalData.uwrListAllPostBind;
    this.submissionData.uwrListAllNonPostBind = this.uwApprovalData.uwrListAllNonPostBind;
    this.submissionData.postBindUwrItemsApproved = this.uwApprovalData.postBindUwrItemsApproved;
    this.submissionData.nonPostBindUwrItemsChecked = this.uwApprovalData.nonPostBindUwrItemsChecked;
    this.submissionData.nonPostBindUwrItemsApproved = this.uwApprovalData.nonPostBindUwrItemsApproved;
  }

  subscribeToUwApprovalListComplete(): void {
    this.uwApprovalData.uwApprovalListComplete$.pipe(takeUntil(this.stop$), distinctUntilChanged()).subscribe(result => {
      if (result && localStorage.getItem('entityId')) {
        this.submissionNavValidationService.validateCategories();
        this.uwApprovalData.showLoader = false;
        this.allowBindAndIssueAccess();
      }
    });
  }

  get isStatusExpired(): boolean {
    return this.submissionData.quoteStatus.value === this.genericConstants.quoteStatus.pexp || this.submissionData.quoteStatus.value === this.genericConstants.quoteStatus.qexp;
  }

  get bindAndIssueValidStatus(): boolean {
    return this.bindAndIssueService.paperlessSection && this.bindAndIssueService.billingSection && this.bindAndIssueService.signatureSection;
  }

  get hasInvalid() {
    this.validatePages();
    return this.generalValidationsService.invalidFormControlList.length > 0 ? true :
      this.submissionNavValidationService.isBindAndIssueDisabled ? true :
        this.validatePremium() ? true :
          false;
  }

  get isQuickQuoteEffectivaDateValid(): boolean {
    return this.quickQuoteValidationService.isEffectiveDateValid ? true : false;
  }

  get isNotAllowedToBind(): boolean {
    return !this.authService.isUserAllowedToBind;
  }

  onSubmit(clickType?) {
    switch (clickType) {
      case ClickTypes.Back:
        this.navigationService.navigateRoute('uw-approval');
        break;
      case ClickTypes.Issue:
        this.initIssue();
        break;
    }
  }

  initIssue(): void {
    this.checkAndCallEndorsementSaving();
    this.checkAndCallInterestSaving();

    if (this.thirdPartyData.checkIfFloodIsRequiredBeforeBinding()) {
      return NotifUtils.showError(ErrorMessageConstant.FloodAssist.mustOrderFloodErrorMessage);
    }

    // Ofac Search
    const ofacDto: OfacSearchRequestDTO = {
      firstName: this.entityRiskData?.EntityRisk?.firstName.trim(),
      lastName: this.entityRiskData?.EntityRisk?.lastName.trim()
    };

    const riskId = this.summaryData.SummaryForm.get('riskId').value;
    const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;
    const creditCardPaymentMethods = [PaymentMethod.CreditCard, PaymentMethod.RecurringCreditCard];
    const echeckPaymentMethods = [PaymentMethod.ECheck, PaymentMethod.EFT, PaymentMethod.RecurringECheck];
    const recurringPaymentMethods = [PaymentMethod.RecurringCreditCard, PaymentMethod.RecurringECheck];
    const isFullPay = this.bindAndIssueData.billingForm.get('paymentPlan').value === 'PPO0';
    const isCreditPayment = creditCardPaymentMethods.includes(this.bindAndIssueData.billingForm.get('paymentMethod')?.value) ? true : false;
    const isECheckPayment = echeckPaymentMethods.includes(this.bindAndIssueData.billingForm.get('paymentMethod')?.value) ? true : false;
    const isCheckPayment = this.bindAndIssueData.billingForm.get('paymentMethod').value === PaymentMethod.CashCheck;
    const isRecurringPaymentMethod = recurringPaymentMethods.includes(this.bindAndIssueData.billingForm.get('paymentMethod')?.value) ? true : false;
    const isEnrolledInPaperless = this.bindAndIssueData.paperlessDocumentForm.get('enrollInPaperless').value;
    const downpayment = this.bindAndIssueData.billingForm.get('downpayment').value;
    const riskBindBillingPayload: RiskBindBillingDTO = this.getRiskBindBillingPayload(isFullPay, downpayment, isCreditPayment, isECheckPayment, isCheckPayment, isRecurringPaymentMethod);
    const riskBindPaperlessPayload: RiskBindPaperlessDTO = this.getRiskBindPaperlessPayload();
    const riskBindSignaturePayload: RiskBindSignatureDTO = ({
      signature: this.bindAndIssueData.signatureForm.get('signature').value,
      signatureworkemailaddress: this.bindAndIssueData.selectedSignature[0].email,
      signatureAgentId: this.bindAndIssueData.selectedSignature[0].id
    });
    const riskBindPayload: RiskBindDTO = this.getRiskBindPayload(riskDetailId, riskBindBillingPayload, isEnrolledInPaperless, riskBindPaperlessPayload, riskBindSignaturePayload);

    this.updateNotesList(riskBindPayload);
    this.updateUwApprovalTableRows(riskBindPayload);

    const raterResultKey = `${Rater.raterResult}_${riskDetailId}`;
    const raterResult = localStorage.getItem(raterResultKey);
    const raterRequestKey = `${Rater.raterRequest}_${riskDetailId}`;
    const raterRequest = localStorage.getItem(raterRequestKey);
    const bindAndIssuePayload: SaveBindAndIssueDTO = this.getBindAndIssuePayload(riskId, riskDetailId, riskBindPayload, raterResult, raterRequest);

    this.validatePages();
    this.postBindAndIssue(riskId, riskDetailId, bindAndIssuePayload);
  }

  getRiskBindBillingPayload(
    isFullPay: boolean,
    downpayment: any,
    isCreditPayment: boolean,
    isECheckPayment: boolean,
    isCheckPayment: boolean,
    isRecurringPaymentMethod: boolean
  ): RiskBindBillingDTO {
    return {
      payPlanOption: this.bindAndIssueData.billingForm.get('paymentPlan').value,
      downpaymentAmount: !isFullPay ? downpayment ? Number(downpayment) : 0 : 0,
      // paymentMethod: this.bindAndIssueData.paymentMethodList.find(x => x.value === this.bindAndIssueData.billingForm.get('paymentMethod').value)?.id, // this.bindAndIssueData.billingForm.get('paymentMethod').value
      paymentMethod: this.bindAndIssueData.billingForm.get('paymentMethod').value,
      firstName: isCreditPayment || isECheckPayment ? this.bindAndIssueData.billingForm.get('firstName').value : '',
      lastName: isCreditPayment || isECheckPayment ? this.bindAndIssueData.billingForm.get('lastName').value : '',
      address: isCreditPayment || isECheckPayment ? this.bindAndIssueData.billingForm.get('address').value : '',
      zipCode: isCreditPayment || isECheckPayment ? this.bindAndIssueData.billingForm.get('zipCode').value : '',
      city: isCreditPayment || isECheckPayment ? this.bindAndIssueData.billingForm.get('city').value : '',
      stateCode: isCreditPayment || isECheckPayment ? this.bindAndIssueData.billingForm.get('state').value : '',
      emailAddress: isCreditPayment || isECheckPayment ? this.bindAndIssueData.billingForm.get('emailAddress').value : '',
      creditCardType: isCreditPayment ? this.bindAndIssueData.billingForm.get('ccType').value : null,
      creditCardNumber: isCreditPayment ? this.bindAndIssueData.billingForm.get('ccNumber').value : '',
      expirationMonth: isCreditPayment ? this.bindAndIssueData.billingForm.get('ccExpirationMonth').value : 0,
      expirationYear: isCreditPayment ? this.bindAndIssueData.billingForm.get('ccExpirationYear').value : 0,
      cvv: isCreditPayment ? this.bindAndIssueData.billingForm.get('ccCVV').value : '',
      cid: isCreditPayment ? this.bindAndIssueData.billingForm.get('ccCID').value : '',
      totalChargeAmount: isCreditPayment ? +this.bindAndIssueData.billingForm.get('totalChargeAmt').value : 0,
      accountType: isCheckPayment || isECheckPayment ? this.bindAndIssueData.billingForm.get('accountType').value : null,
      routingNumber: isCheckPayment || isECheckPayment ? this.bindAndIssueData.billingForm.get('routingNumber').value : '',
      accountNumber: isCheckPayment || isECheckPayment ? this.bindAndIssueData.billingForm.get('accountNumber').value : '',
      nameOnAccount: isECheckPayment ? this.bindAndIssueData.billingForm.get('nameOnAccount').value : '',
      totalChargeAmountBank: !isCreditPayment ? +this.bindAndIssueData.billingForm.get('bankAccountTotalChargeAmt').value : 0,
      nextTermpaymentPlan: this.bindAndIssueData.billingForm.get('nextTermPaymentPlan').value,
      isPayLater: this.bindAndIssueData.billingForm.get('paymentPlan').value === PaymentPlanListConstants.mortgageePayCode,
      isAgreeEnrollAutoPay: isRecurringPaymentMethod ? this.bindAndIssueData.billingForm.get('agreeEnrollAutoPay').value : false,
      isAgreeOneTimePayment: isCreditPayment || isECheckPayment ? this.bindAndIssueData.billingForm.get('agreeOneTimePayment').value : false,
    };
  }

  getRiskBindPaperlessPayload(): RiskBindPaperlessDTO {
    return {
      isEnrollInPaperLess: this.bindAndIssueData.paperlessDocumentForm.get('enrollInPaperless').value,
      emailAddressPaperless: this.bindAndIssueData.paperlessDocumentForm.get('paperlessEmailAddress').value === this.bindAndIssueData.paperlessDocumentForm.get('paperlessConfirmEmailAddress').value
        ? this.bindAndIssueData.paperlessDocumentForm.get('paperlessEmailAddress').value : '',
      isAgreeToPaperless: this.bindAndIssueData.paperlessDocumentForm.get('agreement').value
    };
  }

  getRiskBindPayload(
    riskDetailId: string,
    riskBindBillingPayload: RiskBindBillingDTO,
    isEnrolledInPaperless: boolean,
    riskBindPaperlessPayload: RiskBindPaperlessDTO,
    riskBindSignaturePayload: RiskBindSignatureDTO
  ): RiskBindDTO {
    return {
      riskDetailId: riskDetailId,
      riskBindBilling: riskBindBillingPayload,
      riskBindPaperless: isEnrolledInPaperless ? riskBindPaperlessPayload : null,
      riskBindSignature: riskBindSignaturePayload,
      riskBindNotes: []
    };
  }

  getBindAndIssuePayload(riskId: string, riskDetailId: string, riskBindPayload: RiskBindDTO, raterResult: string, raterRequest: string): SaveBindAndIssueDTO {
    return {
      riskId: riskId,
      riskDetailId: riskDetailId,
      riskBind: riskBindPayload,
      raterResult: JSON.parse(raterResult),
      raterRequest: raterRequest,
      uploadedCheck: this.getUploadedCheckDetails(),
      commissionRate: this.entityRiskData.getAgencyHasCommissionRate() ? this.authService.commissionRateResponse.commissionRate : '0'
    };
  }

  updateNotesList(riskBindPayload: RiskBindDTO): void {
    const notesList = this.bindAndIssueData.notesList.map(item => {
      return {
        id: item.notesId ?? null,
        riskBindId: item.riskBindId ?? null,
        category: item.category,
        description: item.description,
        createdBy: item?.createdBy,
        createdDate: item?.createdDate
      };
    });

    const internalNotesList = this.bindAndIssueData.internalNotesList.map(item => {
      return {
        id: item.notesId ?? null,
        riskBindId: item.riskBindId ?? null,
        category: item.category,
        description: item.description,
        createdBy: item?.createdBy,
        createdDate: item?.createdDate
      };
    });

    riskBindPayload.riskBindNotes = [...notesList, ...internalNotesList];
  }

  updateUwApprovalTableRows(riskBindPayload: RiskBindDTO): void {
    this.uwApprovalData.uwApprovalTableRows.forEach(item => {
      if (item?.tr[2]?.value?.trim()) { // UW Comment
        riskBindPayload.riskBindNotes.push(this.getRiskBindNoteFromUWNotes(item?.tr[2]?.value));
      }

      if (item?.tr[3]?.value?.trim()) { // Agent Comment
        riskBindPayload.riskBindNotes.push(this.getRiskBindNoteFromUWNotes(item?.tr[3]?.value));
      }
    });
  }

  postBindAndIssue(riskId: string, riskDetailId: string, bindAndIssuePayload: SaveBindAndIssueDTO): void {
    if (this.bindAndIssueValidStatus) {
      if (riskId && riskDetailId) {
        NotifUtils.showConfirmMessage(BindAndIssueLabelConstants.areYouSureYouWantToIssue, async () => {
          Utils.blockUI();

          if (this.bindAndIssueData.billingForm.get('paymentPlan').value === PaymentPlanListConstants.fullPayCode) {
            this.interestSavingData.saveInterest(this.bindAndIssueData.billingForm.get('payor').value ? this.bindAndIssueData.billingForm.get('payor').value : null, this.bindAndIssueData.billingForm.get('nextTermPayor').value ? this.bindAndIssueData.billingForm.get('nextTermPayor').value : null);
          }

          await this.getFinalClaimTierForAdverseActionNotice(bindAndIssuePayload);
          this.quickQuoteService.postBindAndIssue(bindAndIssuePayload).pipe(takeUntil(this.stop$)).subscribe(result => {
            this.updateCollectionID(result, riskId);
            this.updateSummaryForm(result);
            this.entityRiskData.setPolicyNumberOnBind(this.billingPayloadOnBind(result));
            this.taskGenService.triggerTaskMethods(result);
            this.taskGenService.task2();
            this.uwApprovalData.uwApprovalTableRows = [];
            this.uwApprovalData.uwApprovalList = [];
            this.entityRiskData.moratoriumResponse = null;
            if(bindAndIssuePayload.riskBind.riskBindBilling.isPayLater || bindAndIssuePayload.riskBind.riskBindBilling.payPlanOption === PaymentPlanListConstants.mortgageePayCode
              || bindAndIssuePayload.riskBind.riskBindBilling.paymentMethod.toString() === 'PM0') {
              this.showSuccessBindAndIssue(riskId);
            } else {
              this.showSuccessBindAndIssuePayment(riskId, result.paymentReceiptEmailBody);
            }
          },
            err => {
              Utils.unblockUI();
              NotifUtils.showError(JSON.stringify(err.error));
            }
          );
        }, () => {

        });

        return;
      }

      NotifUtils.showError('Invalid RiskId');
      this.navigationService.navigateRoute('bind-and-issue');
      return;
    }

    NotifUtils.showError(this.generalValidationsService.invalidFormControlErrorMessage);
  }

  protected async getFinalClaimTierForAdverseActionNotice(bindAndIssuePayload: SaveBindAndIssueDTO) {
    const applicantForm = this.applicantData.mainApplicant;
    const effectiveDateRefined = new Date(applicantForm.get('effectiveDate').value?.singleDate?.jsDate).toLocaleDateString('en-US');
    const claimsCount = this.claimsData.claimList ?
      this.claimsData.claimList.filter(x => x.isAPlus).length :
      this.entityRiskData.getRisk().riskDetails[0].riskClaims.filter(x => x.isAPlus).length;

    if (!this.entityRiskData.isNewQuote) {
      await this.raterPremiumService.getPriorClaimTierAsync(this.coveragesData?.creditScore,
        claimsCount, effectiveDateRefined).then(claimTier => {
          this.coveragesData.creditPriorClaimTier = claimTier;
          bindAndIssuePayload.ClaimsTier = claimTier;
        });
    }
  }

  checkAndCallEndorsementSaving(): void {
    if (this.endorsementsSavingData.isEndorsementSkippedSaving) {
      this.endorsementsSavingData.saveEndorsements();
      this.endorsementsSavingData.isEndorsementSkippedSaving = false;
    }
  }

  checkAndCallInterestSaving(): void {
    if (this.interestSavingData.isInterestSkippedSaving) {
      this.interestSavingData.saveInterest();
      this.interestSavingData.isInterestSkippedSaving = false;
    }
  }

  validatePages() {
    this.generalValidationsService.invalidFormControlList = [];
    this.generalValidationsService.invalidFormControlErrorMessage = '';
    this.bindAndIssueService.checkBindAndIssuePage();
    this.propertyValidationService.checkPropertyPage();
    this.uwApprovalData.checkUwApprovalStatus();
    this.applicantValidationService.checkApplicantPage();
    this.applicantValidationService.checkCoApplicantSection();
    this.coveragesValidationService.checkCoveragesPage();
    this.submissionNavValidationService.validateCurrentCategory(PathConstants.QuickQuote.QuickQuote.Index);
  }

  updateCollectionID(response: any, riskId: string): void {
    const riskDocuments = response?.riskDocuments;
    const riskNotes = response?.riskBindNotes; //riskNotes;
    if (riskDocuments) {
      riskDocuments.forEach((element, index) => {
        this.bindAndIssueData.documentsList[index].documentsId = element.id;
        this.bindAndIssueData.documentsList[index].riskBindId = element.riskBindId;
        this.bindAndIssueData.documentsList[index].isUploaded = element.isUploaded;
        this.bindAndIssueData.documentsList[index].filePath = element.filePath;
      });
    }

    if (riskNotes) {
      this.bindAndIssueData.populateBindnotes(riskNotes);
    }

  }

  updateSummaryForm(response: any): void {
    this.summaryData.SummaryForm.get('policyStatus').setValue(response?.newRiskSubStatusCode);// response?.riskStatus);
    this.summaryData.SummaryForm.get('quoteStatus').setValue(response?.riskStatusCode); // response?.riskStatus);
    this.summaryData.SummaryForm.get('newRiskDetailId').setValue(response?.newRiskDetailId);//response?.endorsementRiskId);
    // this.summaryData.SummaryForm.get('endorsementRiskId').setValue(response?.newRiskId);//response?.endorsementRiskId);
  }

  getRiskBindNoteFromUWNotes(comment: string): RiskBindNoteDTO {
    const riskBindNote: RiskBindNoteDTO = {
      id: null,
      riskBindId: null,
      category: NotesCategoryConstants.underwriting,
      description: comment
    };
    const listData = {
      id: null,
      category: NotesCategoryConstants.underwriting,
      description: comment,
      dateAdded: this.authService.getCustomDate(),
      riskBindId: null,
      notesId: null
    };
    this.bindAndIssueData.notesList.push(listData);
    return riskBindNote;
  }

  validatePremium(): boolean {
    const estimatedPremium = +this.summaryData?.SummaryForm?.get('estimatedPremium')?.value;
    return estimatedPremium <= 0 ? true : false;
  }

  billingPayloadOnBind(risk: any): UpdateRiskRequestDTO {
    const request: UpdateRiskRequestDTO = {
      riskId: risk?.riskId,
      policyNumber: risk?.policyNumber,
      quoteNumber: this.entityRiskData.getQuoteNumber()
    };

    return request;
  }

  get disableIssue(): boolean {
    if (this.entityRiskData?.EntityRisk?.risks[0]?.renewalCode === null) {
      return this.hasInvalid ||
      !this.isQuickQuoteEffectivaDateValid ||
      this.isNotAllowedToBind ||
      this.coveragesData.creditOrderStatusCode === this.creditOrderStatusConstants.NotOrdered ||
      !Boolean(this.bindAndIssueData.signatureList?.find(x => x.name === this.bindAndIssueData.signatureForm.get('signature').value)) ||
      this.isCheckImageRequired ||
      this.hasClosure ||
      !this.bindAndIssueData.hasCommissionRate;
    } else {
      return true;
    }
  }

  get isCheckImageRequired(): boolean {
    return this.bindAndIssueData.billingForm.get('paymentMethod')?.value === PaymentMethod.CashCheck &&
      Boolean(this.bindAndIssueData.billingForm.get('checkNumber')?.value) && !Boolean(this.policyBillingData?.uploadedCheckImage?.filePath);
  }

  private getUploadedCheckDetails(): UploadCheckDTO {
    return new UploadCheckDTO({
      checkNumber: this.bindAndIssueData.billingForm.get('checkNumber')?.value ?? '',
      imageURL: this.policyBillingData.uploadedCheckImage?.filePath ?? ''
    });
  }

  checkClosureForExternalUser(): void {
    if (!this.entityRiskData?.isDoneFetchMoratorium) {
      this.entityRiskData?.isDoneFetchingMoratorium$.pipe(takeUntil(this.stop$)).subscribe(result => {
        this.checkForClosure();
      });
    } else {
      this.checkForClosure();
    }
  }

  checkForClosure(): void {
    if (!this.authService.isInternalUser) {
      const currentMoratorium = this.entityRiskData?.moratoriumResponse;
      if (currentMoratorium) {
        currentMoratorium.forEach(moratorium => {
          if (moratorium.type === MoratoriumType.closure) {
            this.hasClosure = true;
          }
        });
      } else {
        this.hasClosure = false;
      }
    } else {
      this.hasClosure = false;
    }
  }

  showSuccessBindAndIssue(riskId: string): void {
    NotifUtils.showSuccessWithConfirmationFromUser(BindAndIssueLabelConstants.issuedSuccessfully, () => {
      this.router.navigateByUrl(`/policies/${riskId}/${this.summaryData.SummaryForm.get('newRiskDetailId').value}/summary`);
    });
  }

  showSuccessBindAndIssuePayment(riskId: string, paymentReceiptEmailBody: string): void {
    NotifUtils.showSuccessWithConfirmationFromUser(BindAndIssueLabelConstants.issuedSuccessfully, () => 
      {
        this.showPaymentReceipt(paymentReceiptEmailBody, riskId, this.router, this.summaryData);
      }
    );
  }

  showPaymentReceipt(emailBody: string, riskId: string, router: any, summaryData: any): void {
    if (emailBody) {
      Swal.fire(
        {
          customClass: {
            content: 'email-receipt-content-style',
          },
          width: 900,
          html: emailBody,
          showCloseButton: true,
          showConfirmButton: false,
          focusConfirm: false,
        }).then(() => {
          router.navigateByUrl(`/policies/${riskId}/${summaryData.SummaryForm.get('newRiskDetailId').value}/summary`);
        });
    }
  }
}
