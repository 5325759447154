import { createSelector } from '@ngrx/store';
import { BaseState } from '../base.state';

export const selectAppState = (state: BaseState) => state.app;

export const selectAppIsLoading = createSelector(
  selectAppState,
  (state) => state.isLoading
);

export const selectAppIsConfirmation = createSelector(
  selectAppState,
  (state) => state.isConfirmation
);

export const selectAppRuwIsLoading = createSelector(
  selectAppState,
  (state) => state.ruwIsLoading
);

export const selectAppBrulDates = createSelector(
  selectAppState,
  (state) => state.brulDates
);
