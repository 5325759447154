export const AgencyLabelConstants = {
    companyName: 'Company Name',
    workPhone: 'Work Phone',
    mobilePhone: 'Mobile Phone',
    workFax: 'Work Fax',
    emailAddress: 'Email',
    businessType: 'Business Type',
    dba: 'Doing Business As',
    yearEstablished: 'Year Established',
    federalIDNumber: 'Federal ID Number',
    naicsCodeID: 'NAICS Code ID',
    additionalNAICSCodeID: 'Additional NAICS Code ID',
    iccmcDocketNumber: 'ICCMC Docket Number',
    usDotNumber: 'US Dot Number',
    pucNumber: 'PUC Number',
    hasSubsidiaries: 'Has Subsidiaries?',
    isActive: 'Is Active?',
    contactName: 'Contact Name',
    eftAvailable: 'EFT Available',
    accountNumber: 'Account Number',
    accountType: 'Account Type',
    routingNumber: 'Routing Number',
    commissionEmail: 'Commission Email',
    commissionPaidToAgency: 'Commission Paid To Agency',
    hasBindingAuthority: 'Has Binding Authority',
    is1099Required: '1099 Required',
    isInitialDownload: 'Initial Download',
    regionalSalesManager: 'Regional Sales Manager',
    agencyManagementSystem: 'Agency Management System',
    network: 'Network',
    commissionType: 'Commission Type',
    commissionGroup: 'Commission Group',
    agencyCode: 'Agency Code',
    agencyLegacyCode: 'Legacy Agency Code',
    subAgencyCode: 'Sub Agency Code',
    lexisNexisCode: 'Lexis Nexis Code',
    effectiveDate: 'Effective Date',
    expirationDate: 'Expiration Date',

    newBusiness: 'New Business',
    renewalBusiness: 'Renewal Business',
    licenseEffectiveDate: 'License Effective Date',
    licenseExpirationDate: 'License Expiration Date',

    detailsTab: 'Details',
    addressTab: 'Address',
    subAgencyTab: 'Sub Agency',
    agentTab: 'Agent',
    programStateTab: 'Program-State',
    notesTab: 'Notes',
    agencyCommission: 'Commission',

    agencyModalAddedSuccessfulyMessage: 'Agency Added Successfully.',
    subAgencyModalAddedSuccessfullyMessage: 'Sub Agency Added Successfully.',
    agencyModalUpdatedSuccessfulyMessage: 'Agency Updated Successfully.',
    subAgencyModalUpdatedSuccessfullyMessage: 'Sub Agency Updated Successfully.',
    addSubAgencyMessageModal: 'Add Sub Agency',
    editSubAgencyMessageModal: 'Edit Sub Agency',
    deleteConfirmationMessageModal: 'Are you sure you want delete?',
    agencyDeleteMessage: 'Agency Deleted Successfully.',

    auditLog: {
      add: 'CREATE',
      edit: 'UPDATE',
      delete: 'DELETE'
    },

    methodName: {
      add: 'saveAgency',
      edit: 'updateAgency',
      delete: 'deleteAgency'
    },

    state: 'State',
    program: 'Program',
    schedule: 'Schedule',
    startDate: 'Start Date',
    endDate: 'End Date',
    policyEffectiveDateRange: 'Policy Effective Date Range',
    areYouSureYouWantToDeleteThisSetup: 'Are you sure you want to delete this commission setup?'
  };