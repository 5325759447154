export const SessionTimeoutLabelsConstants = {
    sessionTimeout: 'Session Timeout',
    topLabel:'Your online session will expire in',
    bottomLabel:'Please click "Continue" to keep working.',
    logoutMsg:'You have been logged out due to inactivity',
    buttonOkLabel:'OK',
    buttonContinueLabel:'Continue',
    hr:'hr',
    mins: ' mins',
    secs:'secs',
    continue: 'Continue',
    sessionAuditType : 'SESSION',

};