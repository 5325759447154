import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormTypeConstants } from '../../../../shared/constants/form-types.constants';
import { QuoteService } from '../../../../core/services/quote.service';
import { BaseClass } from '../../../../shared/base-class';
import { PolicySummaryData } from '../../data/policy-summary.data';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-deductibles',
  templateUrl: './deductibles.component.html',
  styleUrls: ['./deductibles.component.scss']
})
export class DeductiblesComponent extends BaseClass implements OnInit {
  public isOpen: boolean = true;
  formType: string;
  formTypeConstants = FormTypeConstants;

  constructor(public policySummaryData: PolicySummaryData, protected quoteService: QuoteService) {
    super();
  }

  ngOnInit(): void {
    this.quoteService.formTypeObs$.pipe(takeUntil(this.stop$)).subscribe((formType) => {
      this.formType = formType;
    });
  }

  get DeductiblesForm(): FormGroup {
    return this.policySummaryData.deductiblesForm;
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }
}
