export const UWApprovalPageLabel = {
  addTitle: 'Add UW Approval',
  editTitle: 'Edit UW Approval',
  referral: 'Referral',
  addReferral: 'Add Referral',
  description: 'Description',
  type: 'Type',
  status: 'Status',
  uwComment: 'UW Comment',
  agentComment: 'Agent Comment',
  miscellaneous: 'Miscellaneous',
  uwStatus: {
    pending: 'Pending',
    approve: 'Approve',
    reject: 'Reject',
    air: 'AIR'
  },
  fileTableHeaders: {
    fileName: 'Filename',
    dateAdded: 'Date Added'
  },
  fileModal: {
    title: 'Related Documents'
  },
  fileUploadModal: {
    title: 'Upload Document'
  },
  buttons: {
    decline: 'Decline',
    approve: 'Approve',
    referToUw: 'Refer to UW',
    additionalInfo: 'Additional Information Required',
    outStandingReq: 'Outstanding Requirements',
  },
  notesModalHeader: {
    approvalHeader: 'Endorsement Approval Notes',
    declinedHeader: 'Endorsement Declination Notes'
  },
  uwrPostBindText: 'post bind refer',
  uwrChangesExludedProperties: [
    'uwApprovalCode',
    'uwComment',
    'agentComment',
    'createdBy',
    'createdByFullName',
    'createdDate',
    'createdDateProper',
    'isActive',
    'riskUWApprovalDocuments',
    'riskPolicyUWNotes',
    'id'
  ],
  renewalReferral: {
    title: 'Renewal Referral'
  },
  endorsementReferral: {
    UWR129Flag: 'Trigger UWR-129',
    UWR128Flag: 'Trigger UWR-128'
  }
};
