import { Action, createReducer, on } from '@ngrx/store';
import * as fromRelatedRisksActions from 'app/store/related-risks/related-risks.actions';
import { initialState, RelatedRisks } from './related-risks.state';

const _relatedRisksReducer = createReducer(
  initialState,
  on(
    fromRelatedRisksActions.updateRelatedRisksIsLoadingFromSubmissionListData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromRelatedRisksActions.updateRelatedRisksListFromSubmissionListData,
    (state, { list }) => ({ ...state, list: list })
  ),
);

export function relatedRisksReducer(
  state: RelatedRisks | null,
  action: Action
) {
  return _relatedRisksReducer(state, action);
}