import { Component, OnInit } from '@angular/core';
import { MaintenanceModeService } from '../../../core/services/maintenance-mode.service';
import { MaintenanceModeConstants } from '../../../shared/constants/maintenance-mode.constants';

@Component({
  selector: 'app-maintenance-mode',
  templateUrl: './maintenance-mode.component.html',
  styleUrls: ['./maintenance-mode.component.scss']
})
export class MaintenanceModeComponent implements OnInit {
  message: string;
  MaintenanceModeConstants = MaintenanceModeConstants;

  constructor(
    protected maintenanceModeService: MaintenanceModeService
  ) { }

  ngOnInit() {
    this.message = this.maintenanceModeService.getMaintenanceModeMsg();
  }
}
