export const ReportType = [
    {
    name: "Company Level"
    },
    {
    name: "Network Level"
    },
]

export const ReportOptions = [
    {
        name: "250K Inforce",
    },
    {
        name: "Agency",
    },
    {
        name: "All Active Agencies",
    },
    {
        name: "All Agencies",
    },
]

export const AgencyTypeOptions = [
 {
     key: "Agency",
     value: "Agency"
 },
 {
    key: "All Agencies",
    value: "All"
},
{
    key: `All Active Agencies`,
    value: "ActAgen"
},
{
    key: "All Agencies & KRAFT",
    value: "AllKRAFT"
},
{
    key: "All Active Agencies & KRAFT",
    value: "ActKRAFT"
},
{
    key: "All Agencies & SIM",
    value: "AllSIM"
},
{
    key: "All Active Agencies & SIM",
    value: "ActSIM"
},
{
    key: "All Agencies & SCIG",
    value: "AllSCIG"
},
{
    key: "All Active Agencies & SCIG",
    value: "ActSCIG"
},
{
    key: "All Agencies & ARROW",
    value: "AllARROW"
},
{
    key: "All Active Agencies & ARROW",
    value: "ActARROW"
},
{
    key: "All Agencies & CENTA",
    value: "AllCENTA"
},
{
    key: "All Active Agencies & CENTA",
    value: "ActCENTA"
},
{
    key: "All Agencies & NLAKE",
    value: "AllNLAKE"
},
{
    key: "All Active Agencies & NLAKE",
    value: "ActNLAKE"
},
{
    key: "All Agencies & PANLA",
    value: "AllPANLA"
},
{
    key: "All Active Agencies & PANLA",
    value: "ActPANLA"
},
{
    key: "All Agencies & PANAL",
    value: "AllPANAL"
},
{
    key: "All Active Agencies & PANAL",
    value: "ActPANAL"
},
{
    key: "250K Inforce",
    value: "IF250K"
},
]
