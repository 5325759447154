export const CommissionLabels = {
  agency: 'Agency',
  table: {
    commissionPayment: {
      agencyName: 'Agency Name',
      agencyCode: 'Agency Code',
      commissionEffectiveDate: 'Effective Date',
      amount: 'Amount',
      checkNumber: 'Check Number',
      postDate: 'Post Date',
      transactionDate: 'Transaction Date',
      documentURL: 'Document',
      createdBy: 'Created By',
      createdDate: 'Created Date',
      isActive: 'Active',
      action: 'Actions',
      edit: 'Edit',
      delete: 'Delete',
      open: 'Open',
      viewDocument: 'View Document',
      type: 'Type',
      viewDetails: 'View Details',
      noDocumentAvailable: 'No Document Available'
    }
  },
  commissionPayment: 'Commission Payment',
  addCommissionPayment: 'Add Commission Payment',
  editCommissionPayment: 'Edit Commission Payment',
  viewCommissionPayment: 'View Commission Payment',
  saveChanges: 'Save Changes',
  save: 'Save',
  agencyName: 'Agency Name',
  type: 'Type',
  year: 'Year',
  month: 'Month',
  amount: 'Amount',
  checkNumber: 'Check Number',
  postDate: 'Post Date',
  transactionDate: 'Transaction Date',
  remarks: 'Remarks',
  close: 'Close',
  cancel: 'Cancel',
  file: 'File'
};