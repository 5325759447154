import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComparativeRaterLoginComponent } from './comparative-rater-login/comparative-rater-login.component';
import { ComparativeRaterReferenceCodeModalComponent } from './comparative-rater-login/comparative-rater-reference-code-modal/comparative-rater-reference-code-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppSidebarModule } from '@coreui/angular';
import { CodeInputModule } from 'angular-code-input';
import { ComparativeRaterData } from './comparative-rater.data';
import { CustomPipesModule } from '../../shared/custom pipes/custom-pipe.module';
import { SharedModule } from '../../shared/shared.module';
import { TimerPipe } from '../../shared/custom pipes/timer-pipe';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [ComparativeRaterLoginComponent,
    ComparativeRaterReferenceCodeModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppSidebarModule,
    SharedModule,
    CustomPipesModule,
    CodeInputModule.forRoot({
      codeLength: 6,
      isCharsCode: true,
      code: ''
    }),
    RouterModule
  ],
  providers:[ComparativeRaterData, TimerPipe],
  entryComponents: [ComparativeRaterReferenceCodeModalComponent]
})
export class ComparativeRaterModule { }
