import { Component, OnInit } from '@angular/core';
import { PolicyIssueConstants } from '../../../../shared/constants/policy-issue.labels.constants';
import { PolicyIssueData } from '../../data/policy-issue.data';
import { BaseComponent } from 'app/shared/base-component';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectChangesIsLoading } from 'app/store/changes/changes.selectors';
import { takeUntil } from 'rxjs/operators';
import { selectIsLoadingOos } from 'app/store/policy/policy.selectors';

@Component({
  selector: 'app-policy-changes',
  templateUrl: './policy-changes.component.html',
  styleUrls: ['./policy-changes.component.scss']
})
export class PolicyChangesComponent extends BaseComponent implements OnInit {
  public PolicyIssueConstants = PolicyIssueConstants;
  protected isOpen: boolean = true;
  protected selectChangesIsLoading$: Observable<boolean>;
  protected selectIsLoadingOos$: Observable<boolean>;

  constructor(
    public policyIssueData: PolicyIssueData,
    protected store: Store
  ) {
    super();
    this.selectChangesIsLoading$ = this.store.pipe(select(selectChangesIsLoading));
    this.selectIsLoadingOos$ = this.store.pipe(select(selectIsLoadingOos));
  }

  ngOnInit(): void { }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }
}
