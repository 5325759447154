import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import NotifUtils from 'app/shared/utilities/notif-utils';

@Component({
  templateUrl: 'access-denied-001.html'
})

export class AccessDenied001Component implements OnInit {
  constructor(private router: Router) { }

  ngOnInit() {
    NotifUtils.showError('Restricted Access', () => {
      this.router.navigate(['submissions/list']);
    });
  }
}