import { submissionReducer } from 'app/store/submission/submission.reducer';
import { policyReducer } from 'app/store/policy/policy.reducer';
import { policyNotesReducer } from './policy-notes/policy-notes.reducer';
import { submissionUWRsReducer } from './submission-uwrs/submission-uwrs.reducer';
import { appReducer } from './app/app.reducer';
import { policyUWRsReducer } from './policy-uwrs/policy-uwrs.reducer';
import { raterReducer } from './rater/rater.reducer';
import { moratoriumReducer } from './moratorium/moratorium.reducer';
import { raterCalculateDP3Reducer } from './rater-calculate-dp3/rater-calculate-dp3.reducer';
import { interestsReducer } from './interests/interests.reducer';
import { backupReducer } from './data-backup/data-backup.reducer';
import { changesReducer } from './changes/changes.reducer';
import { claimsReducer } from './claims/claims.reducer';
import { coveragesReducer } from './coverages/coverages.reducer';
import { endorsementsReducer } from './endorsements/endorsements.reducer';
import { propertiesReducer } from './properties/properties.reducer';
import { applicantsReducer } from './applicants/applicants.reducer';
import { UWApprovalReducer } from './uw-approval/uw-approval.reducer';
import { uwQuestionsReducer } from './uw-questions/uw-questions.reducer';
import { recalculateValuationReducer } from './recalculate-valuation/racalculate-valuation.reducer';
import { refundRequestReducer } from './refund-request/refund-request.reducer';
import { relatedRisksReducer } from './related-risks/related-risks.reducer';
import { dashboardReducer } from './dashboard/dashboard.reducer';
import { commissionPaymentReducer } from './commission/commission-payment/commission-payment.reducer';
import { calculateValuationReducer } from './calculate-valuation/calculate-valuation.reducer';

export const baseReducer = {
  app: appReducer,
  applicants: applicantsReducer,
  backup: backupReducer,
  calculateValuation: calculateValuationReducer,
  changes: changesReducer,
  claims: claimsReducer,
  coverages: coveragesReducer,
  endorsements: endorsementsReducer,
  interests: interestsReducer,
  moratorium: moratoriumReducer,
  policy: policyReducer,
  policyUWRs: policyUWRsReducer,
  policyNotes: policyNotesReducer,
  properties: propertiesReducer,
  submission: submissionReducer,
  submissionUWRs: submissionUWRsReducer,
  rater: raterReducer,
  raterCalculateDP3: raterCalculateDP3Reducer,
  recalculateValuation: recalculateValuationReducer,
  refundRequest: refundRequestReducer,
  relatedRisks: relatedRisksReducer,
  uwApproval: UWApprovalReducer,
  uwQuestions: uwQuestionsReducer,
  dashboard: dashboardReducer,
  commissionPayment: commissionPaymentReducer
};