import { RenewalOfferRequestDTO } from './renewal-offer-request.dto';

export class PostAutoReinstatementDTO implements IPostAutoReinstatementDTO {
  riskId?: string | undefined;
  isApproved?: boolean;
  approvalExpirationDate?: any | undefined;
  isStatementOfNoLossReceived?: boolean;
  paymentRequiredToReinstate?: number | undefined;
  renewalOfferRequest?: RenewalOfferRequestDTO | undefined;

  constructor(data?: PostAutoReinstatementDTO) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }
}

export interface IPostAutoReinstatementDTO {
  riskId?: string | undefined;
  isApproved?: boolean;
  approvalExpirationDate?: any | undefined;
  isStatementOfNoLossReceived?: boolean;
  paymentRequiredToReinstate?: number | undefined;
  renewalOfferRequest?: RenewalOfferRequestDTO | undefined;
}