export const AgencySubAgencyLabelConstants = {
    effectiveDate: 'Effective Date',
    expirationDate: 'Expiration Date',

    methodName: {
      add: 'saveSubAgency',
      edit: 'updateSubAgency',
      delete: 'deleteSubAgency'
    },

    subAgencyDeleteMessage: 'Sub Agency Deleted Successfully.'
  };