import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsComponent } from './reports.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { AppSidebarModule } from '@coreui/angular';
import { SharedModule } from 'app/shared/shared.module';
import { LeadingIndicatorModule } from './leading-indicator/leading-indicator.module';
import { AgencySnapshotModule } from './agency-snapshot/agency-snapshot.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommissionStatementModule } from './commission-statement/commission-statement.module';
import { TypeaheadModule } from 'ngx-bootstrap';
import { CustomerServiceModule } from './customer-service/customer-service.module';
import { EarnedPremiumModule } from './earned-premium/earned-premium.module';



@NgModule({
  declarations: [ReportsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ReportsRoutingModule,
    AppSidebarModule,
    SharedModule,
    AgencySnapshotModule,
    CustomerServiceModule,
    LeadingIndicatorModule,
    CommissionStatementModule,
    EarnedPremiumModule,
    TypeaheadModule.forRoot(),
  ]
})
export class ReportsModule { }
