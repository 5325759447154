import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NameCasePipe } from '../.../../../../shared/custom pipes/name-pipe';
import { EntityRiskDTO } from '../.../../../../shared/models/data/dto/quick-quote/entity-risk.dto';
import { BaseClass } from '../../../shared/base-class';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { SubmissionPageData } from '../.../../../../modules/submission-management/data/submission-page.data';
import { quoteStatus } from '../.../../../../shared/constants/quote-status.constants';
import { AddressType } from '../../../shared/enum/address-type.enum';
import Utils from '../.../../../../shared/utilities/utils';
import { LvRiskStatus } from '../.../../../../shared/constants/risk-status';
import { RiskStatus, RiskStatusCode } from '../.../../../../shared/models/data/dto/quick-quote/risk.dto';
import { LvCreditOrderStatus } from '../.../../../../shared/constants/quick-quote.options.constants';
import { GenericConstants } from '../.../../../../shared/constants/generic.constants';
import { QuoteService } from '../../../core/services/quote.service';
import { FormTypeConstants } from '../../../../app/shared/constants/form-types.constants';
import { TransactionSummaryDTO } from '../../../../app/shared/models/data/dto/billing/transaction-summary.dto';
import { AuthService } from '../../../core/services/auth.service';
import { CalculateRaterData } from 'app/core/services/submission/rater-premium/calculate-rater-data.service';
import { PolicyService } from 'app/core/services/submission/policy.service';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { Store, select } from '@ngrx/store';
import { updateSubmissionStatusFromSummaryData } from 'app/store/submission/submission.actions';
import { RiskDTO2 } from 'app/shared/models/data/dto/quick-quote/risk-dto2';
import { EntitySubmissionListDTO } from 'app/shared/models/data/dto/submission-list/entity-submission-list.dto';
import { updatePolicyStatusFromSummaryData, updatePolicySubStatusFromSummaryData } from 'app/store/policy/policy.actions';
import * as moment from 'moment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class
SummaryData extends BaseClass {
  public LvRiskStatus = LvRiskStatus;
  public formTypeConstants = FormTypeConstants;
  // Form Group
  SummaryForm: FormGroup;
  isCalcPremium: Subject<boolean> = new Subject<boolean>();
  pageType: string;
  creditOrderErrorCode: string;
  errorMessages: any[] = [];
  isOrderCredit: boolean = false;
  isUpdatedInsuredProfileOrPrefill = false;
  public errorMessage$: Subject<boolean> = new Subject<boolean>();
  public isOrderCreditAllowed: boolean = false;
  public showPendingEndorsementStatus: boolean = false;
  isFormTypeLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  billingPremiumRetrieve: Subject<TransactionSummaryDTO> = new Subject<TransactionSummaryDTO>();

  showRewriteOption: boolean = true;
  hiddenRewriteDueToEndorsement: boolean = false;
  pageHeaderCovA: number;
  pageHeaderCovC: number;

  policyNumbers: {policyNumber: string; riskDetailId: string; riskId: string; isEndorsement: boolean; policyQuoteAndEffectiveDate: string}[];

  public isDraftRiskSubStatus: boolean = false;
  isSiebel: boolean = false;
  isDateImplemented: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public raterData: CalculateRaterData,
    protected namePipe: NameCasePipe,
    public submissionPageData: SubmissionPageData,
    public quoteService: QuoteService,
    public authService: AuthService,
    protected storage: LocalStorageService,
    public policyService: PolicyService,
    protected store: Store
  ) {
    super();
    this.subscribeToRaterPremiumDetails();
    this.subscribeToIsCalcPremium();
    this.subscribeToBillingPremiumRetrieve();
    this.initializeOrderCredit();
  }

  subscribeToRaterPremiumDetails(): void {
    this.raterData.raterPremiumDetails.pipe(takeUntil(this.stop$)).subscribe(result => {
      this.SummaryForm.get('estimatedPremium').setValue(result.estimatedPremium);
      this.SummaryForm.get('fees').setValue(result.fees);
      this.SummaryForm.get('taxes').setValue(result.taxes);
    });
  }

  subscribeToIsCalcPremium(): void {
    this.raterData.isCalcPremium.pipe(takeUntil(this.stop$)).subscribe(result => {
      this.isCalcPremium.next(result);
    });
  }

  subscribeToBillingPremiumRetrieve(): void {
    this.billingPremiumRetrieve.pipe(takeUntil(this.stop$)).subscribe(result => {
      this.SummaryForm.get('billingPremium').setValue(result.premium !== 0 ? result.premium : '0');
      this.SummaryForm.get('billingTaxes').setValue(result.tax !== 0 ? result.tax : '0');
      this.SummaryForm.get('billingFees').setValue(result.fees !== 0 ? result.fees : '0');
    });
  }

  initForms() {
    this.SummaryForm = this.summaryForm();
  }

  summaryForm() {
    return new FormGroup({
      name: new FormControl('Name'),
      address: new FormControl('Address'),
      policyStatus: new FormControl('Status'),
      formType: new FormControl(''),
      quoteNumber: new FormControl(''),
      effectiveDate: new FormControl(''),
      agency: new FormControl(''),
      code: new FormControl(''),
      agencyStatus: new FormControl(''),
      coverageA: new FormControl(''),
      coverageC: new FormControl(''),
      creditOrderStatus: new FormControl(''),
      estimatedPremium: new FormControl('0'),
      taxes: new FormControl('0'),
      fees: new FormControl('0'),
      quoteStatus: new FormControl(''),
      riskId: new FormControl(''),
      entityId: new FormControl(''),
      endorsementRiskId: new FormControl(''),
      policyNumber: new FormControl(''),
      riskDetailId: new FormControl(''),
      newRiskDetailId: new FormControl(''),
      billingPremium: new FormControl('0'),
      billingTaxes: new FormControl('0'),
      billingFees: new FormControl('0'),
    });
  }

  public populateSummaryForms(data: EntityRiskDTO) {
    const effectiveDate = data?.risks[0]?.firstIssueDate;

    // SIEBEL
    this.isSiebel = data?.risks[0]?.isSiebel;

    this.SummaryForm.get('riskId').setValue(data?.risks[0]?.id);
    const isPolicy = localStorage.getItem(`${data?.risks[0]?.id}_${GenericConstants.isPolicy}`) === 'true';

    const propertyAddress = data?.entityAddresses.find(a => a.addressTypeCode === AddressType.Property)?.address;
    const nAddress = Utils.getFullAddress(propertyAddress);
    const riskStatusCode = data?.risks[0]?.riskStatusCode;
    const riskSubStatusCode = data?.risks[0]?.riskDetails[0]?.riskSubStatusCode;
    const riskStatusDescription = this.LvRiskStatus.find(a => a.code === riskStatusCode)?.description;
    const riskSubStatusCodeDescription = this.LvRiskStatus.find(a => a.code === riskSubStatusCode)?.description;
    const creditOrderStatusCode = isPolicy && data?.entityBackUp ? data?.entityBackUp?.creditScoreStatusCode : data?.creditScoreStatusCode;
    const riskId = data?.risks[0].id;
    const riskDetailId = data?.risks[0]?.riskDetails[0]?.id;
    const riskApprovedForReinstatementDate = data?.risks[0]?.approvedForReinstatementDate;
    const riskPendingCancellationDate = data?.risks[0]?.pendingCancellationDate || data?.risks[0].cancellationDate;
    const riskRenewalOfferedDate = data?.risks[0]?.renewalOfferedDate;
    const endorsementNumber = data?.risks[0]?.riskDetails[0]?.endorsementNumber;
    const cancellationReason = data?.risks[0]?.cancellationReason;
    const isFromRenewal = data?.risks[0]?.isFromRenewal;

    this.setSubStatusCode();

    this.SummaryForm.get('entityId').setValue(data?.id);
    this.SummaryForm.get('name').setValue(this.namePipe.transform(data?.fullName));
    this.SummaryForm.get('address').setValue(nAddress);
    this.SummaryForm.get('policyStatus').setValue(riskSubStatusCodeDescription ? riskSubStatusCodeDescription : quoteStatus.quoted);
    this.SummaryForm.get('formType').setValue(data?.risks[0].formType);
    this.isFormTypeLoaded.next(true);
    this.SummaryForm.get('quoteNumber').setValue(data?.risks[0].quoteNumber);


    this.SummaryForm.get('effectiveDate').setValue(isPolicy ? data?.risks[0]?.firstIssueDate : data?.risks[0]?.riskDetails[0]?.effectiveDate);

    this.SummaryForm.get('coverageA').setValue(data?.risks[0]?.riskDetails[0]?.riskCoverages[0].covA);
    this.SummaryForm.get('coverageC').setValue(data?.risks[0]?.riskDetails[0]?.riskCoverages[0].covC);
    this.SummaryForm.get('creditOrderStatus').setValue(creditOrderStatusCode && creditOrderStatusCode !== '' ? LvCreditOrderStatus.find(s => s.code === creditOrderStatusCode).description : 'Ordered-Score Received');
    this.SummaryForm.get('taxes').setValue('0');
    this.SummaryForm.get('quoteStatus').setValue(riskStatusDescription ? riskStatusDescription : quoteStatus.quoted);
    this.SummaryForm.get('policyNumber').setValue(data?.risks[0]?.policyNumber);
    this.SummaryForm.get('riskId').setValue(riskId);
    this.SummaryForm.get('riskDetailId').setValue(riskDetailId);

    this.pageHeaderCovA = isPolicy ? data?.risks[0]?.riskDetails[1]?.riskCoverages[0].covA : data?.risks[0]?.riskDetails[0]?.riskCoverages[0].covA;
    this.pageHeaderCovC = isPolicy ? data?.risks[0]?.riskDetails[1]?.riskCoverages[0].covC : data?.risks[0]?.riskDetails[0]?.riskCoverages[0].covC;

    this.authService.isAgencySubAgencyCompleted$.pipe(takeUntil(this.stop$)).subscribe(() => {
      this.SummaryForm.get('agency').setValue(this.authService?.agenciesSubAgencies[0]?.agency?.entity?.companyName);
      if (this.authService?.agenciesSubAgencies[0]?.agency?.agencyCode) {
        const agencyCode = this.authService.agenciesSubAgencies[0]?.agency?.agencyCode;
        const subAgencyCode = this.authService.agenciesSubAgencies[0]?.subAgency?.subAgencyCode ?? '';
        this.SummaryForm.get('code').setValue(agencyCode + '-' + subAgencyCode);
      } else {
        this.SummaryForm.get('code').setValue('-');
      }
      this.SummaryForm.get('agencyStatus').setValue(this.authService?.agenciesSubAgencies[0]?.agency?.entity?.isActive ? 'Active' : 'Inactive');
    });

    this.submissionPageData.quoteStatus.next(riskStatusDescription as RiskStatus);

    if (localStorage.getItem('pageType') === 'policies') {
      this.store.dispatch(updatePolicyStatusFromSummaryData({ status: riskStatusDescription as RiskStatus }));
      this.store.dispatch(updatePolicySubStatusFromSummaryData({ subStatus: riskSubStatusCodeDescription as RiskStatus }));
    } else {
      this.store.dispatch(updateSubmissionStatusFromSummaryData({ status: riskStatusDescription as RiskStatus }));
    }

    this.submissionPageData.policyStatus.next(riskSubStatusCodeDescription as RiskStatus);
    this.submissionPageData.isPendingCancellation.next(riskPendingCancellationDate ? true : false);
    this.submissionPageData.isApprovedForReinstatement.next(riskApprovedForReinstatementDate ? true : false);
    this.submissionPageData.isRenewalOfferedDate.next(riskRenewalOfferedDate ? true : false);
    this.submissionPageData.isRewritten.next(cancellationReason === 'Rewrite' ? true : false);

    this.setShowRewriteOption(effectiveDate, endorsementNumber, riskStatusDescription, isFromRenewal);

    const rewriteFrom = (data?.risks[0]?.rewriteFromPolicyNumber && data?.risks[0]?.rewriteFromRiskId && data?.risks[0]?.rewriteFromDraftRiskDetailId);
    const rewriteTo = data?.risks[0]?.rewriteToPolicyNumber && data?.risks[0]?.rewriteToRiskId && data?.risks[0]?.rewriteToDraftRiskDetailId;
    this.policyNumbers = [];
    this.setDropdownPolicyNumber(data?.risks[0]);
  }

  setDropdownPolicyNumber(risks: RiskDTO2, relatedSubmissionListHolder?: EntitySubmissionListDTO[]): void {
    this.policyNumbers = [{
      policyNumber: risks?.rewriteFromPolicyNumber,
      riskId: risks?.rewriteFromRiskId,
      riskDetailId: risks?.rewriteFromDraftRiskDetailId,
      isEndorsement: true,
      policyQuoteAndEffectiveDate: risks?.rewriteFromEffectiveDate ? `${risks?.rewriteFromPolicyNumber} (${new Date(risks?.rewriteFromEffectiveDate).toLocaleDateString('en-US')})` : risks?.rewriteFromPolicyNumber
    }, {
      policyNumber: risks?.rewriteToPolicyNumber,
      riskId: risks?.rewriteToRiskId,
      riskDetailId: risks?.rewriteToDraftRiskDetailId,
      isEndorsement: true,
      policyQuoteAndEffectiveDate: risks?.rewriteToEffectiveDate ? `${risks?.rewriteToPolicyNumber} (${new Date(risks?.rewriteToEffectiveDate).toLocaleDateString('en-US')})` : `${risks?.rewriteToPolicyNumber}`
    }, {
      policyNumber: risks?.policyNumber,
      riskId: risks?.id,
      riskDetailId: risks?.riskDetails[0]?.id,
      isEndorsement: true,
      policyQuoteAndEffectiveDate: risks?.riskDetails[0].effectiveDate ? `${risks?.policyNumber} (${new Date(risks?.firstIssueDate).toLocaleDateString('en-US')})` : `${risks?.policyNumber}`,
    }];

    const renewalPolicy = relatedSubmissionListHolder?.find(risk => risk?.renewalFromRiskId === risks?.id);

    if (renewalPolicy?.renewalFromRiskId === risks?.id) {
      const relatedRenewalPolicy = {
        policyNumber: renewalPolicy?.isEndorsement ? renewalPolicy?.policyNumber : renewalPolicy?.quoteNumber,
        riskId: renewalPolicy?.riskId,
        riskDetailId: renewalPolicy?.submissionRiskDetailId,
        isEndorsement: renewalPolicy?.isEndorsement,
        policyQuoteAndEffectiveDate: `${renewalPolicy?.policyQuoteAndEffectiveDate}`
      };

      this.policyNumbers.push(relatedRenewalPolicy);
    }

    this.policyNumbers = this.policyNumbers.filter(x => x?.policyNumber !== null).sort();
  }

  setShowRewriteOption(effectiveDate?: any, endorsementNumber?, riskStatusDescription?, isFromRenewal?): void {
    let userType = '';
    this.hiddenRewriteDueToEndorsement = false;

    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(x => {
      userType = x;
    });

    const dateToday = this.authService.getCustomDate();
    const prior30DaysDateToday = new Date(new Date(dateToday).setDate(new Date(dateToday).getDate() - 31)); // - 30 days -> changed to 31 to correct the subtraction
    const EffectiveDate = new Date(effectiveDate);
    const FLUWPeriod = new Date(moment(effectiveDate, 'YYYY-MM-DDT00:00:00.000').add(90, 'days').format('YYYY-MM-DDT00:00:00.000')); // 90 days for FL User
    const dateTodayMoment = new Date(moment(dateToday).format('YYYY-MM-DDT00:00:00.000'));

    //#region *****[JOC][11142022] - Commented due to CENTAURI-6506 *****
    // if (prior30DaysDateToday <= EffectiveDate && (dateToday >= EffectiveDate || dateToday <= EffectiveDate)) {
    //   this.showRewriteOption = true;
    // } else {
    //   this.showRewriteOption = false;
    // }

    // if (userType === 'internal') {
    //   if (prior30DaysDateToday >= EffectiveDate) {
    //     this.showRewriteOption = true;
    //   }
    // }
    // #endregion

    if (FLUWPeriod >= dateTodayMoment) {
      this.showRewriteOption = true;
    } else {
      this.showRewriteOption = false;
    }

    if (riskStatusDescription === 'Cancelled') {
      this.showRewriteOption = false;
    }

    if (endorsementNumber > 1) {
      if (this.showRewriteOption) {
        this.hiddenRewriteDueToEndorsement = true;
      }

      this.showRewriteOption = false;
    }

    if (isFromRenewal === true) {
      this.showRewriteOption = false;
    }
  }

  subStatusSubscription: Subscription;

  setSubStatusCode(result?: any) {
    const riskId = this.storage.get('riskDetailId');

    if (this.subStatusSubscription) {
      this.subStatusSubscription.unsubscribe();
    }

    this.subStatusSubscription = this.policyService.getSubStatusCode(riskId).pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res) {
        // const riskSubStatusCode = result?.riskSubStatusCode;
        const riskSubStatusCodeDescription = LvRiskStatus.find(a => a.code === res)?.description;

        this.SummaryForm.get('policyStatus').setValue(riskSubStatusCodeDescription);
        this.submissionPageData.policyStatus.next(riskSubStatusCodeDescription as RiskStatus);
        if (res === 'PEN') {
          this.showPendingEndorsementStatus = true;
          this.isDraftRiskSubStatus = false;
        } else if(res === 'Draft') {
          this.isDraftRiskSubStatus = true;
        } else {
          this.isDraftRiskSubStatus = false;
        }
      }
    });
  }

  public isPolicyPage(riskId): boolean {
    return localStorage.getItem(`${riskId}_${GenericConstants.isPolicy}`) === 'true';
  }

  populateAgentDetails(agentId: string): void {
    if (!!!this.authService.agentUserInfo && !!agentId) {
      this.authService.getAgentInformation(agentId);
    }
  }

  initializeOrderCredit(): void {
    this.authService.allowOrderCredit$.pipe(takeUntil(this.stop$)).subscribe(() => {
      const lexisNexisCode = this.authService.subAgenciesInfo?.lexisNexisCode;
      const isInternal = this.authService.userType.value === GenericConstants.userType.internal;

      if (isInternal) {
        this.isOrderCreditAllowed = true;
      } else {
        this.isOrderCreditAllowed = lexisNexisCode != null &&
        lexisNexisCode !== undefined && lexisNexisCode !== '';
      }
    });
  }

  /**
   * Remove or refresh items in localstorage when starting a new quote / copy mode
   */
  removePreviousStorageItems(): void {
    this.storage.remove('entityId');
    this.storage.remove('fromCopy');
    sessionStorage.removeItem('selectedEffectiveDate');
  }
}
