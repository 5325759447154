import { Component, OnInit, OnDestroy } from '@angular/core';
import { ErrorMessageConstant } from '../../../../shared/constants/error-message.constants';
import { ApplicantLabelsConstants } from 'app/shared/constants/applicant.labels.constants';
import { ApplicantData } from '../../data/applicant.data';
import FormUtils from 'app/shared/utilities/form.utils';
import { GenericLabel } from '../../../../shared/constants/generic.labels.constants';
import { CoveragesData } from '../../data/coverages.data';
import { CreditOrderStatusConstants } from '../../../../shared/constants/coverages.options.constants';
import { FormGroup } from '@angular/forms';
import { QuickQuoteData } from '../../data/quick-quote.data';
import { LvCreditOrderStatus, LvCreditOrderStatusIndex } from 'app/shared/constants/quick-quote.options.constants';
import { EntityRiskData } from '../../data/entity-risk.data';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { AuthService } from '../../../../core/services/auth.service';
import { BaseClass } from '../../../../shared/base-class';
import { takeUntil } from 'rxjs/operators';
import { SummaryData } from '../../data/summary.data';
import { Subject } from 'rxjs';
import { ThirdPartyDataService } from '../../../../core/services/submission/third-party/third-party-data.service';
import { ThirdPartyData } from '../../data/third-party.data';
import { CreditScoreRiskResponseDTO } from '../../../../shared/models/data/dto/third-party/credit-score-risk-response.dto';
import NotifUtils from '../../../../shared/utilities/notif-utils';
import Utils from '../../../../shared/utilities/utils';
import { ApplicantSavingData } from '../../data/applicant.saving.data';

@Component({
  selector: 'app-fair-credit-reporting-act',
  templateUrl: './fair-credit-reporting-act.component.html',
  styleUrls: ['./fair-credit-reporting-act.component.scss']
})
export class FairCreditReportingActComponent extends BaseClass implements OnInit, OnDestroy {
  public isCoApplicantHidden: boolean = false;
  public LabelMessageConstant = ApplicantLabelsConstants;
  ErrorMessageConstant = ErrorMessageConstant;
  public FormUtils = FormUtils;
  public GenericLabel = GenericLabel;
  isExternal: boolean;
  isPolicyPage: boolean;

  constructor(
    public applicantData: ApplicantData,
    protected quickQuoteData: QuickQuoteData,
    protected coveragesData: CoveragesData,
    protected summaryData: SummaryData,
    protected policySummaryData: PolicySummaryData,
    protected authService: AuthService,
    protected thirdPartyService: ThirdPartyDataService,
    protected thirdPartyData: ThirdPartyData,
    protected applicantSavingData: ApplicantSavingData
  ) {
    super();
  }

  ngOnInit() {
    this.initData();
  }

  initData(): void {
    this.getHideFcraAndIAgree();

    if (this.coveragesData?.creditOrderStatusCode) {
      this.setCreditOrderStatusDropdown(this.coveragesData?.creditOrderStatusCode);
    }

    this.getCreditScoreDate();
    this.subscribeToCoveragesData();
    this.subscribeToUserType();
    this.isPolicyPage = this.policySummaryData.isPolicyPage(this.coveragesData.entityRiskData.getRiskId());
  }

  getHideFcraAndIAgree(): void {
    if (this.policySummaryData.isPolicyPage(this.coveragesData.entityRiskData.getRiskId())) {
      this.applicantData.hideFcraAndIAgree = this.applicantData?.isOrderCredit;

      return;
    }

    this.applicantData.hideFcraAndIAgree = this.quickQuoteData.isOrderCreditValue;
  }

  getCreditScoreDate(): void {
    const creditScoreDate = this.coveragesData?.creditScoreDateOrdered;

    if (creditScoreDate) {
      let dateStr = '';

      if (typeof creditScoreDate == 'string') {
        dateStr = creditScoreDate + 'Z';
      } else if (!isNaN(creditScoreDate.getDate())) {
        dateStr = creditScoreDate.toISOString();
      } else {
        dateStr = '';
      }

      this.setCreditScoreDateOrderedDPicker(dateStr);
    }
  }

  subscribeToCoveragesData(): void {
    this.coveragesData.statusCodeObservable.pipe(takeUntil(this.stop$)).subscribe(code => {
      if (code) {
        this.setCreditOrderStatusDropdown(code);
      }
    });
  }

  subscribeToUserType(): void {
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.isExternal = this.isExternalUser(userType);
    });
  }

  public ToggleHidingOfCoApplicant(): void {
    this.isCoApplicantHidden = !this.isCoApplicantHidden;
  }

  setCreditOrderStatusDropdown(creditOrderStatusCode: string) {
    this.applicantData.fcraReportingActForm.get('creditScoreStatus').setValue(creditOrderStatusCode);
  }

  setCreditScoreDateOrderedDPicker(dateStr): void {
    if (!dateStr) {
      this.applicantData.fcraReportingActForm.get('creditScoreDateOrdered').setValue(null);
      return;
    }

    const creditOrderDate = new Date(dateStr);

    this.applicantData.fcraReportingActForm.get('creditScoreDateOrdered').setValue({
      isRange: false, singleDate:
      {
        date:
        {
          year: creditOrderDate.getFullYear(),
          month: creditOrderDate.getMonth() + 1,
          day: creditOrderDate.getDate()
        },
        jsDate: creditOrderDate
      }
    });
  }

  get agreed(): string {
    const creditScoreStatusCode = this.coveragesData?.creditOrderStatusCode;

    return (this.policySummaryData.isPolicy && !this.policySummaryData.isEditPolicy) ? '' :
      creditScoreStatusCode > LvCreditOrderStatus[LvCreditOrderStatusIndex.NotOrdered].code ? '' : null;
  }

  isExternalUser(userType: string): boolean {
    return userType.toLocaleLowerCase() === 'external';
  }

  get checkIfCanViewCreditScore(): boolean {
    return (this.isExternal && !this.isPolicyPage) || (!this.isExternal && !this.isPolicyPage) || (!this.isExternal && this.isPolicyPage);
  }

  get toggleFCRASectionDisplayOnPolicyPage(): boolean {
    return !(this.isExternal && this.applicantData.isOrderCredit && this.policySummaryData.isPolicy);
  }

  get isOrderCreditAllowed(): boolean {
    return this.isExternal ? this.summaryData.isOrderCreditAllowed : true;
  }

  get isReOrderCreditAllowed(): boolean {
    return !this.isExternal && (this.applicantData?.isOrderCredit || this.applicantData?.isfcraAcknowledgement);
  }

  get disableReorderOnPolicy(): boolean {
    if (this.policySummaryData.isPolicy && !this.policySummaryData.isEditPolicy) {
      return true;
    } else {
      return false;
    }
  }
  protected callReOrderCredit() {
    const creditRequest = this.thirdPartyData.getOrderCreditRequestModel();
    Utils.blockUI();
    this.thirdPartyService.postOrderCredit(creditRequest).subscribe(result => {
      Utils.unblockUI();
      if (result) {
        const creditInfoResult: CreditScoreRiskResponseDTO = result;

        if (creditInfoResult.errorCode === null && creditInfoResult.errorMessage === null) {
          this.thirdPartyData.mapCreditScoreInfoResultToCoveragesData(result);
          this.coveragesData.populateFinancialResponsibility(result.creditScore);
          this.getCreditScoreDate();
          this.coveragesData.saveCoverages(false, false, false);
          this.applicantSavingData.saveApplicant();
          this.thirdPartyData.coveragesSectionOrderCredit$.next(true);
        }

        if (creditInfoResult.errorCode === ErrorMessageConstant.CreditService.CS02ErrorCode) {
          NotifUtils.showError(ErrorMessageConstant.CreditService.CS02ErrorCodeMessage);
        } else if (creditInfoResult.errorCode === ErrorMessageConstant.CreditService.CE01ErrorCode ||
          creditInfoResult.errorCode === ErrorMessageConstant.CreditService.NcfApiErrorCode) {
          NotifUtils.showError(ErrorMessageConstant.CreditService.LexisNexisNcfFailed_V1);
        } else if (creditInfoResult.errorCode === ErrorMessageConstant.CreditService.GeneralErrorCode) {
          NotifUtils.showError(ErrorMessageConstant.CreditService.GeneralErrorMessage);
        } else if (creditInfoResult.errorCode === ErrorMessageConstant.CreditService.NcfApiAccountCodeErrorCode) {
          NotifUtils.showError(ErrorMessageConstant.CreditService.AccountSetupBrQq41InvaliderrorMessage);
        }
      } else {
        NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
      }
    }, error => {
      Utils.unblockUI();
      NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
    }, () => {
    });
  }
}