import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICommissionPaymentDTO,
  IGetCommissionPaymentRequest,
  IGetCommissionPaymentResponse
} from 'app/shared/models/data/dto/nacha/commission-payment.dto';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class CommissionPaymentService {

  constructor(protected http: HttpClient,
    protected commonService: CommonService) {
  }

  getCommissionPayment(request: IGetCommissionPaymentRequest): Observable<IGetCommissionPaymentResponse> {
    return this.http.post(`${environment.ApiUrl}/CommissionPayment/get-commission-payment`, request)
      .pipe(
        map(result => {
          return result as IGetCommissionPaymentResponse;
        }),
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  postCommissionPayment(request: any): Observable<ICommissionPaymentDTO> {
    return this.http.post(`${environment.ApiUrl}/CommissionPayment/save-commission-payment`, request)
      .pipe(
        map(result => {
          return result as ICommissionPaymentDTO;
        }),
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  deleteCommissionPayment(id: string): Observable<ICommissionPaymentDTO> {
    return this.http.delete(`${environment.ApiUrl}/CommissionPayment?commissionPaymentId=${id}`)
    .pipe(
      map(result => {
        return result as ICommissionPaymentDTO;
      }),
      catchError(this.commonService.handleObservableHttpError)
    );
  }
}
