export const NotesCategories = [
  {
    tableName: 'LvNotesCategory ',
    tableDescription: 'Notes Category',
    code: 'UW',
    description: 'Underwriting',
    isActive: true
  },
  {
    tableName: 'LvNotesCategory ',
    tableDescription: 'Notes Category',
    code: 'FIN',
    description: 'Finance',
    isActive: true
  },
  {
    tableName: 'LvNotesCategory ',
    tableDescription: 'Notes Category',
    code: 'POL',
    description: 'Policy',
    isActive: true
  },
  {
    tableName: 'LvNotesCategory ',
    tableDescription: 'Notes Category',
    code: 'CLMS',
    description: 'Claims',
    isActive: true
  },
  {
    tableName: 'LvNotesCategory ',
    tableDescription: 'Notes Category',
    code: 'MISC',
    description: 'Miscellaneous',
    isActive: true
  },
  {
    tableName: 'LvNotesCategory ',
    tableDescription: 'Notes Category',
    code: 'BILL',
    description: 'Billing',
    isActive: true
  },
  {
    tableName: 'LvNotesCategory ',
    tableDescription: 'Notes Category',
    code: 'TSKN',
    description: 'Task Notes',
    isActive: true
  },
  {
    tableName: 'LvNotesCategory ',
    tableDescription: 'OFAC',
    code: 'OFAC',
    description: 'OFAC',
    isActive: true
  },
  {
    tableName: 'LvRenewalChangesCategory ',
    tableDescription: 'Renewal Changes',
    code: 'RC',
    description: 'Renewal Changes',
    isActive: true
  },
  {
    tableName: 'LvNotesCategory ',
    tableDescription: 'Notes Category',
    code: 'INTR',
    description: 'Internal',
    isActive: true
  }
];

export const LvNotesCategories = [
  {
    tableName: 'LvNotesCategory ',
    tableDescription: 'Notes Category',
    code: 'UW',
    description: 'Underwriting',
    isActive: true
  },
  {
    tableName: 'LvNotesCategory ',
    tableDescription: 'Notes Category',
    code: 'FIN',
    description: 'Finance',
    isActive: true
  },
  {
    tableName: 'LvNotesCategory ',
    tableDescription: 'Notes Category',
    code: 'POL',
    description: 'Policy',
    isActive: true
  },
  {
    tableName: 'LvNotesCategory ',
    tableDescription: 'Notes Category',
    code: 'CLMS',
    description: 'Claims',
    isActive: true
  },
  {
    tableName: 'LvNotesCategory ',
    tableDescription: 'Notes Category',
    code: 'MISC',
    description: 'Miscellaneous',
    isActive: true
  },
  {
    tableName: 'LvNotesCategory ',
    tableDescription: 'Notes Category',
    code: 'BILL',
    description: 'Billing',
    isActive: true
  },
  {
    tableName: 'LvNotesCategory ',
    tableDescription: 'Notes Category',
    code: 'TSKN',
    description: 'Task Notes',
    isActive: true
  },
  {
    tableName: 'LvNotesCategory ',
    tableDescription: 'OFAC',
    code: 'OFAC',
    description: 'OFAC',
    isActive: true
  },
  {
    tableName: 'LvNotesCategory ',
    tableDescription: 'Notes Category',
    code: 'INTR',
    description: 'Internal',
    isActive: true
  }
];

export const NotesCategoryConstants = {
  underwriting: 'UW',
  finance: 'FIN',
  policy: 'POL',
  claims: 'CLMS',
  miscellaneous: 'MISC',
  task: 'TSKN'
};

export const LvAgencyNotesCategories = [
  {
    tableName: 'LvAgencyNotesCategories ',
    tableDescription: 'Agency Notes Category',
    code: 'GEN',
    description: 'General',
    isActive: true
  },
  {
    tableName: 'LvAgencyNotesCategories ',
    tableDescription: 'Agency Notes Category',
    code: 'COMM',
    description: 'Commission',
    isActive: true
  },
  {
    tableName: 'LvAgencyNotesCategories ',
    tableDescription: 'Agency Notes Category',
    code: 'BIND',
    description: 'Binding Authority',
    isActive: true
  },
  {
    tableName: 'LvAgencyNotesCategories ',
    tableDescription: 'Agency Notes Category',
    code: 'TERM',
    description: 'Termination',
    isActive: true
  },
  {
    tableName: 'LvAgencyNotesCategories ',
    tableDescription: 'Agency Notes Category',
    code: 'TRNS',
    description: 'Transfer',
    isActive: true
  },
];

export const AgencyNotesCategoryConstants = {
  general: 'GEN',
  commission: 'COMM',
  bindingAuthority: 'BIND',
  termination: 'TERM',
  transfer: 'TRNS'
};