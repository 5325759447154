export const PolicyBillingLabelsConstants = {
  billingSummary: {
    header: 'Billing Summary',
    totalPremium: 'Total Premium',
    billed: 'Billed',
    paid: 'Paid',
    balance: 'Balance',
    paymentPlan: 'Payment Plan',
    payor: 'Payor',
    pastDueAmount: 'Past Due Amount',
    pastDueDate: 'Past Due Date',
    payoffAmount: 'Payoff Amount',
    equityDate: 'Equity Date',
    nextActivity: 'Next Activity',
    minimumToBindRenewal: 'Minimum To Bind Renewal',
    payoffRenewal: 'Payoff Renewal',
    changePlan: 'Change Plan',
    equityDateNotShown: 'Equity date cannot be shown'
  },

  installmentSchedule: {
    header: 'Installment Schedule',
    installmentNumber: '#',
    status: 'Status',
    type: 'Type',
    premium: 'Premium',
    tax: 'Assessment',
    fee: 'Fee',
    totalBilled: 'Total Billed',
    balance: 'Balance',
    totalDue: 'Total Due',
    billDate: 'Bill Date',
    dueDate: 'Due Date',
    invoiceNumber: 'Invoice #',
    view: 'View',
    billed: 'Billed',
    billPlanChange: 'Bill Plan Change',
    skipped: 'Skipped',
    EndorsementType: 'Endorsement',
    AdjustmentType: 'Adjustment',
    CorrectionType: 'Correction'
  },

  payments: {
    header: 'Payments',
    postDate: 'Post Date',
    amount: 'Amount',
    type: 'Type',
    method: 'Payment Method',
    referenceNumber: 'Reference #',
    comments: 'Comments',
    reversalDate: 'Reversal Date',
    clearDate: 'Clear Date',
    postedBy: 'Posted By',
    premium: 'Premium',
    tax: 'Surcharge',
    fee: 'Fee',

    adjustment: 'Correction',
    writeOff: 'Write-Off',
    refund: 'Refund',
    refundRequest: 'Refund Request',
    postPayment: 'Make A Payment',
    disabledMakeAPayment: 'Make a payment is only available for Renewal Offered, Revised Renewal Offered, and Renewal Not Taken status. Renewal Not Taken only applies if the last term was not Non-renewed.'
  },

  postPayment: {
    title: 'Make A Payment',
    titleRefund: 'Refund',
    titleRefundRequest: 'Refund Request',
    postDate: 'Post Date',
    amount: 'Amount',
    method: 'Payment Method',
    methodRefund: 'Refund Method',
    comments: 'Comments',
    agreeEnrollAutoPay: 'I Agree',
    paymentDetails: 'Payment Details',
    submitPayment: 'Submit Payment',
    submitRefund: 'Save',
    cancel: 'Cancel',
    suspendedPaymentNotice: 'Note: There is a payment in suspense for this policy.',
    suspendedPaymentReinstateNotice: 'Note: There is a payment in suspense for this policy. Please manually reinstate the policy.',
    postPayment: 'Post Payment',
    choosePaymentOption: 'Choose payment option',
    payCurrentTerm: 'Pay Current Term',
    payCurrentAndRenewalTerm: 'Pay Current & Renewal Term',
    refundTo: 'Refund To',
    totalPayment: 'Total Payment',
    debitCardsNotAcceptedAtThisTime: 'Debit cards are not accepted at this time.'
  },

  makePayment: {
    title: 'Make A Payment',
    policyNumber: 'Policy Number',
    insuredLastName: 'Insured Last Name',
    policyPayoffAmount: 'Policy Payoff Amount',
    amountDue: 'Amount Due',
    postDate: 'Post Date',
    amount: 'Payment Amount',
    method: 'Payment Method',
    email: 'Email',
    comments: 'Comments',
    agreeEnrollAutoPay: 'I agree to pay...',
    paymentDetails: 'Payment Details',
    continue: 'Continue',
    completePayment: 'Submit Payment',
    cancel: 'Cancel',
    choosePaymentOption: 'Choose payment option',
    payCurrentTerm: 'Pay Current Term',
    payCurrentAndRenewalTerm: 'Pay Current & Renewal Term',
    totalPayment: 'Total Payment',
    debitCardsNotAcceptedAtThisTime: 'Debit cards are not accepted at this time.'
  },

  searchPolicy: {
    title: 'Make A Payment',
    policyNumber: 'Policy Number',
    insuredLastName: 'Insured Last Name',
    billingDataNotFound: 'Policy billing data not found.',
    continue: 'Continue',
    close: 'Close',
    clear: 'Clear',
    titlePayment: 'Payment Details'
  },

  paymentDetails: {
    postDate: 'Post Date',
    amount: 'Amount',
    postedBy: 'Posted By',
    premium: 'Premium',
    tax: 'Surcharge',
    fee: 'Fee',
    method: 'Payment Method',
    refundMethod: 'Refund Method',
    referenceNumber: 'Reference',
    comments: 'Comments',
    totalPaid: 'Total Paid',

    reversalType: 'Reversal Type',
    reversalDate: 'Reversal Date',
    reversalProcessedBy: 'Reversal Processed By',

    clearDate: 'Clear Date',
    escheatDate: 'Escheat Date',

    voidConfirmation: 'Please enter your comments and confirm Void',
    nsfConfirmation: 'Please enter your comments and confirm NSF',
    stopPayConfirmation: 'Please enter your comments and confirm Stop Pay',
    escheatConfirmation: 'Please enter the escheat date/comment and confirm Escheatment',

    undoPaymentNsfConfirmation: 'Are you sure you want to undo the NSF on this payment?',

    paymentModalTitle: 'Payment Details',
    adjustmentModalTitle: 'Correction Details',
    writeOffModalTitle: 'Write-Off Details',

    voidType: 'Void',
    stopPayType: 'Stop',
    nsfType: 'NSF',
    undoPaymentNsf: 'Reverse NSF',

    successMessage: 'has been completed',
    save: 'Save',

    transferPayment: {
      success: 'Successfully transferred',
      invalidPolicyNumberError: 'Invalid policy number'
    },

    escheatSuccess: 'Escheatment has been completed.',

    checkDetailsHeader: 'CHECK DETAILS',
    refundName: 'Payee Name',
    refundAddress: 'Address',
    refundCheckNumber: 'Check Number',
    refundCheckDate: 'Check Date',

    voidLbl: 'Void',
    nsfLbl: 'NSF',
    stopPayLbl: 'Stop Pay',
    esCheatLbl: 'Escheat',
    transferLbl: 'Transfer',
    transferToSuspenseLbl: 'Transfer to Suspense',
    closeLbl: 'Close',
  },

  taxesAndFees: {
    title: 'Add Fee',
    header: 'Surcharges and Fees',
    addDate: 'Add Date',
    type: 'Type',
    description: 'Description',
    amount: 'Amount',
    voidDate: 'Void Date',
    void: 'Void',
    addFee: 'Add Fee',

    addFeeSuccess: 'Successfully added transaction fee.',
    voidFeeSuccess: 'Successfully voided transaction fee.',
    voidFeeConfirmation: 'Are you sure you want to void this fee?',
    noResultsFound: 'No results found'
  },

  paymentDocument: {
    header: 'Related Documents',
    upload: 'Upload',
    view: 'View',
    delete: 'Delete',
    description: 'Description',
    uploadDate: 'Upload Date',
    uploadedBy: 'Uploaded By',
    noDocument: 'No Documents'
  },

  fee: {
    addDate: 'Date',
    description: 'Description',
    amount: 'Amount',
    Save: 'Save',
    Cancel: 'Cancel'
  },

  cashPayment: {
    payerName: 'Payer Name',
    referenceNumber: 'Reference Number',
  },

  checkPayment: {
    header: 'Payee Information',
    name: 'Payee Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    suffix: 'Suffix',
    address: 'Mailing Address',
    city: 'City',
    state: 'State',
    zip: 'Zip Code',
    IsIndividual: 'Is this an individual',
    country: 'Country',
    aptOrUnit: 'Apt/Unit #',
    fullName: '"First Name" + "Last Name" + "Suffix"',
    checkPaymentFieldNames: {
      isIndividual: 'isIndividual',
      name: 'name',
      firstName: 'firstName',
      lastName: 'lastName',
      suffix: 'suffix',
      address: 'address',
      aptOrUnit: 'aptOrUnit',
      city: 'city',
      state: 'state',
      zip: 'zip',
      country: 'country'
    },
    comments: 'Comments'
  },

  creditCard: {
    cardHolder: 'Card Holder Details',
    firstName: 'First Name',
    lastName: 'Last Name',
    address: 'Address',
    city: 'City',
    state: 'State',
    zip: 'Zip Code',
    creditCard: 'Credit Card Details',
    type: 'Credit Card Type',
    cardNumber: 'Credit Card Number',
    expirationMonth: 'Expiration Month',
    expirationYear: 'Expiration Year',
    cardCodeCVV: 'CVV',
    cardCodeCID: 'CID',
    email: 'Email',
    aptOrUnit: 'Apt/Unit #'
  },

  eft: {
    billingAddress: 'Billing Address',
    firstName: 'First Name',
    lastName: 'Last Name',
    address: 'Address',
    city: 'City',
    state: 'State',
    zip: 'Zip Code',
    bankAccount: 'Bank Account Information',
    accountType: 'Account Type',
    routingNumber: 'Routing Number',
    accountNumber: 'Account Number',
    nameOnAccount: 'Name on Account',
    bankName: 'Bank Name',
    email: 'Email',
    aptOrUnit: 'Apt/Unit #'
  },

  recurrentPaymentEnrollment: {
    header: 'Recurring Payment Enrollment',
    agreeEnrollAutoPay: 'I agree to pay...',
    accountHolderInfo: 'Account Holder Information',
    firstName: 'First Name',
    lastName: 'Last Name',
    phoneNumber: 'Phone Number',
    email: 'Email',
    paymentAccounts: 'Payment Accounts',
    addPaymentAccount: 'Add',
    editPaymentAccount: 'Edit',
    accountDescription: 'Description',
    accountType: 'Type',
    accountDefault: 'Default',
    addAccount: 'Add Payment Account',
    method: 'Payment Method',
    addProfile: 'Save',
    editProfile: 'Edit'
  },

  adjustment: {
    title: 'Correction',
    adjustmentMethod: 'Correction',
    method: 'Payment Method',
    amount: 'Amount',
    adjustmentDetails: 'Correction Details',
    premium: 'Premium',
    tax: 'Surcharge',
    fee: 'Fee',
    comment: 'Comments',
    save: 'Save',
    cancel: 'Cancel',
    notMatchAmountError: 'The sum of Correction Details should be equal to the Amount.'
  },

  writeOff: {
    title: 'Write-Off',
    writeOffMethod: 'Write-Off',
    method: 'Payment Method',
    amount: 'Amount',
    writeOffDetails: 'Write-Off Details',
    premium: 'Premium',
    tax: 'Surcharge',
    fee: 'Fee',
    comment: 'Comments',
    save: 'Save',
    cancel: 'Cancel',
    notMatchAmountError: 'The sum of Write-Off Details should be equal to the Amount.'
  },

  transferPayment: {
    title: 'Transfer Payment',
    transferToPolicyNumber: 'Transfer to Policy',
    amount: 'Amount',
    comment: 'Comments',
    transferToSelfError: 'Cannot transfer to same policy number',
    transfer: 'Transfer',
    cancel: 'Cancel',
    transferToTempRenewalPolicyNum: 'Transfer cannot be made on this policy due to risk status is not active',
    invalidPolicyNumber: 'Invalid policy number'
  },

  transferRenewalPayment: {
    title: 'Renewal Transfer Payment',
    transferToRenewal: 'Transfer to Renewal',
    amount: 'Amount',
    comment: 'Comments',
    transfer: 'Transfer',
    cancel: 'Cancel',
  },

  successPayment: 'Payment has been posted',
  writeOffSuccess: 'Write-Off has been successful',
  successRefund: 'Refund has been successful',
  successPaymentAdjustment: 'Successfully posted payment correction',

  changePaymentPlan: {
    title: 'Change Payment Plan',
    currentPlan: 'Current Plan',
    newPlan: 'New Plan',
    payor: 'Payor',
    paymentRequired: 'Payment Required',
    makeAPaymentHeader: 'Make a Payment',
    paymentMethod: 'Payment Method',
    amount: 'Amount',
    totalPayment: 'Total Payment'
  },

  changePayplanConfirmation: 'Are you sure you want to change the payment plan?',
  successChangePayplan: 'Payment plan has been successfully changed',
  failedRenewalChangePayplan: 'Payment plan for renewal encountered an error',

  outstandingReinstatementRequirements: {
    title: 'Outstanding Reinstatement Requirements',
    payBalance: 'Pay Balance',
    outstandingBalance: 'Oustanding Balance',
    notApprovedByUW: 'Not approved by underwriter for reinstatement',
    exit: 'Exit',
    outstandingReinstatementReminder: 'Paying the account balance will not reinstate the policy.  For reinstatement please contact your agent.'
  },

  reinstatementRequirementsMet: {
    title: '',
    payToReinstate: 'Pay to Reinstate',
    payBalance: 'Pay Balance',
    exit: 'Exit',
    reminder: 'Paying the account balance will not reinstate the policy.  For reinstatement please contact your agent.'
  },

  pendingStatus: 'PEND',
  relatedDocuments: {
    user: 'User',
    system: 'System'
  },

  noResultsFound: 'No results found',
  rewrite: 'Rewrite',
  rewritePaymentNotAllowed: 'Policy has been Cancelled/Rewritten. Payment is not allowed.',
  uploadCheckImageHeader: 'Upload Check Image',

  transferPaymentToSuspense: {
    title: 'Transfer To Suspense',
    postDate: 'Post Date',
    amount: 'Amount',
    policyNumber: 'Policy Number',
    paymentMethod: 'Payment Method',
    availableToTransfer: 'Available To Transfer',
    amountToTransfer: 'Amount To Transfer',
    suspenseReason: 'Suspense Reason',
    comment: 'Comments',
    transfer: 'Transfer',
    cancel: 'Cancel',
    failed: 'Transfer to Suspense failed'
  },
};
