import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import Utils from 'app/shared/utilities/utils';

@Component({
  templateUrl: '404.component.html'
})
export class P404Component {

  homePage: string = 'Go to Home Page';
  previousPage: string = 'Back to Previous Page';
  isFromMakeAPayment: boolean = false;
  centauriPortalPage: string = 'Go to Centauri Insurance website';
  constructor(private location: Location
              , private route: ActivatedRoute
              , private router: Router) {
                this.isFromMakeAPayment = this.route.snapshot.queryParamMap.get('r') === 't' ? true : false;
              }

  back() {
    this.location.back();
  }

  gotoDashboard() {
    sessionStorage.setItem('hasValidPreviousURL', 'true');
  }

  isPreviousPageValidURL(): boolean {
    const cntr = sessionStorage.getItem('hasValidPreviousURL');
    return cntr === 'true';
  }
}
