import { NonRenewalType, NonRenewalReasonType } from 'app/shared/models/policy/policy-non-renewal.model';

export const LvNonRenewalType: NonRenewalType[] = [
  {
    id: 1,
    code: 'IR',
    description: 'Insured Request',
    isActive: true
  },
  {
    id: 2,
    code: 'BR',
    description: 'Billing Reasons',
    isActive: true
  },
  {
    id: 3,
    code: 'UR',
    description: 'Underwriter Reasons',
    isActive: true
  }
];

export const LvNonRenewalReasonType: NonRenewalReasonType[] = [
  {
    id: 1,
    code: 'IRRPE',
    description: 'Insured Request - Replaced Policy elsewhere',
    nonRenewalTypeCode: 'IR',
    isFreeFormRequired: false,
    isActive: true
  },
  {
    id: 2,
    code: 'IRCNN',
    description: 'Insured Request - Coverage not needed',
    nonRenewalTypeCode: 'IR',
    isFreeFormRequired: false,
    isActive: true
  },
  {
    id: 3,
    code: 'IRO',
    description: 'Insured Request - Other',
    nonRenewalTypeCode: 'IR',
    isFreeFormRequired: false,
    isActive: true
  },
  {
    id: 4,
    code: 'IRRC',
    description: 'Insured Request - Rewritten with Centauri',
    nonRenewalTypeCode: 'IR',
    isFreeFormRequired: false,
    isActive: true
  },
  {
    id: 5,
    code: 'NPP',
    description: 'Non Payment of Premium',
    nonRenewalTypeCode: 'BR',
    isFreeFormRequired: false,
    isActive: true
  },
  {
    id: 6,
    code: 'PRNSF',
    description: 'Payment returned - Non-sufficient funds',
    nonRenewalTypeCode: 'BR',
    isFreeFormRequired: false,
    isActive: true
  },
  {
    id: 7,
    code: 'NPPFC',
    description: 'Non Payment to Premium Finance Company',
    nonRenewalTypeCode: 'BR',
    isFreeFormRequired: false,
    isActive: true
  },
  {
    id: 8,
    code: 'SCIR',
    description: 'Substantial Change in  Risk',
    nonRenewalTypeCode: 'UR',
    isFreeFormRequired: true,
    isActive: true
  },
  {
    id: 9,
    code: 'PRC',
    description: 'Property Condition',
    nonRenewalTypeCode: 'UR',
    isFreeFormRequired: true,
    isActive: true
  },
  {
    id: 10,
    code: 'LHA',
    description: 'Liability hazard',
    nonRenewalTypeCode: 'UR',
    isFreeFormRequired: true,
    isActive: true
  },
  {
    id: 11,
    code: 'AOH',
    description: 'Age of Home',
    nonRenewalTypeCode: 'UR',
    isFreeFormRequired: true,
    isActive: true
  },
  {
    id: 12,
    code: 'LOE',
    description: 'Loss Experience',
    nonRenewalTypeCode: 'UR',
    isFreeFormRequired: true,
    isActive: true
  },
  {
    id: 13,
    code: 'OOU',
    description: 'Occupancy or Use',
    nonRenewalTypeCode: 'UR',
    isFreeFormRequired: true,
    isActive: true
  },
  {
    id: 14,
    code: 'EXM',
    description: 'Exposure Management',
    nonRenewalTypeCode: 'UR',
    isFreeFormRequired: false,
    isActive: true
  },
  {
    id: 15,
    code: 'FCUWR',
    description: 'Fail to Comply with UW Req',
    nonRenewalTypeCode: 'UR',
    isFreeFormRequired: true,
    isActive: true
  },
  {
    id: 16,
    code: 'OTHER',
    description: 'Other',
    nonRenewalTypeCode: 'UR',
    isFreeFormRequired: true,
    isActive: true
  },
  {
    id: 17,
    code: 'MMS',
    description: 'Material Misrep',
    nonRenewalTypeCode: 'UR',
    isFreeFormRequired: true,
    isActive: true
  }
];
