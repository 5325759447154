export class APlusClaimsRequestDTO implements IApplicationInformationDTO {
    appCode?: number | undefined;
    riskId?: string | undefined;
    riskDetailId?: string | undefined;
    riskBindId?: string | undefined;
    insuredFirstName?: string | undefined;
    insuredMiddleName?: string | undefined;
    insuredLastName?: string | undefined;
    insuredBirthday?: any;
    streetAddress1?: string | undefined;
    streetAddress2?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    zipCode?: string | undefined;
    mailingstreetAddress1?: string | undefined;
    mailingstreetAddress2?: string | undefined;
    mailingCity?: string | undefined;
    mailingState?: string | undefined;
    mailingZipCode?: string | undefined;
    priorPolicyNumber?: string | undefined;
    formType?: string | undefined;
    quoteNumber?: string | undefined;
    public constructor(init?: Partial<APlusClaimsRequestDTO>) {
      Object.assign(this, init);
    }
}
export interface IApplicationInformationDTO {
  appCode?: number | undefined;
  riskId?: string | undefined;
  riskDetailId?: string | undefined;
  riskBindId?: string | undefined;
  insuredFirstName?: string | undefined;
  insuredMiddleName?: string | undefined;
  insuredLastName?: string | undefined;
  insuredBirthday?: any;
  streetAddress1?: string | undefined;
  streetAddress2?: string | undefined;
  city?: string | undefined;
  state?: string | undefined;
  zipCode?: string | undefined;
  mailingstreetAddress1?: string | undefined;
  mailingstreetAddress2?: string | undefined;
  mailingCity?: string | undefined;
  mailingState?: string | undefined;
  mailingZipCode?: string | undefined;
  priorPolicyNumber?: string | undefined;
  formType?: string | undefined;
  quoteNumber?: string | undefined;
}
