import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PolicyNavSavingService } from 'app/core/services/navigation/policy-nav-saving.service';
import { SubmissionNavSavingService } from 'app/core/services/navigation/submission-nav-saving.service';
import { CalculateRaterData } from 'app/core/services/submission/rater-premium/calculate-rater-data.service';
import { FormDocumentData } from 'app/core/services/submission/report/form-document-data.service';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { ThirdPartyData } from 'app/modules/submission-management/data/third-party.data';
import { ThirdPartyDataService } from '../../../../core/services/submission/third-party/third-party-data.service';
import { ZipCodeData } from 'app/modules/submission-management/data/zipcode.data';
import { NextBackButtonComponent } from 'app/shared/components/dynamic/next-back-button/next-back-button.component';

@Component({
  selector: 'app-transfer-control-buttons',
  templateUrl: './transfer-control-buttons.component.html',
  styleUrls: ['./transfer-control-buttons.component.scss']
})
export class TransferControlButtonsComponent extends NextBackButtonComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() isScrollPage: boolean = true;
  @Input() submitButtonLabel: string = '';
  @Input() disableSubmitButton: boolean = true;

  constructor(
    public formDocumentData: FormDocumentData,
    public summaryData: SummaryData,
    public router: Router,
    public submissionNavSavingService: SubmissionNavSavingService,
    public thirdPartyDataService: ThirdPartyDataService,
    public raterData: CalculateRaterData,
    public policySummaryData: PolicySummaryData,
    public policyNavSavingService: PolicyNavSavingService,
    public thirdPartyData: ThirdPartyData,
    public entityRiskData: EntityRiskData,
    public zipCodeData: ZipCodeData,
    public store: Store
  ) {
    super(
      formDocumentData,
      summaryData,
      router,
      submissionNavSavingService,
      thirdPartyDataService,
      raterData,
      policySummaryData,
      policyNavSavingService,
      thirdPartyData,
      entityRiskData,
      zipCodeData,
      store
    );
  }

  ngOnInit() { }
}

