import { createSelector } from '@ngrx/store';
import { BaseState } from '../base.state';

export const selectCalculateValuationState = (state: BaseState) => state.calculateValuation;

export const selectCalculateValuationIsLoading = createSelector(
  selectCalculateValuationState,
  (state) => state.isLoading
);

export const selectCalculateValuationData = createSelector(
  selectCalculateValuationState,
  (state) => state.data
);