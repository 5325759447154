export const LvTaskType = [
    {
        id: 1,
        code: 'INC',
        description: 'Incoming',
        isActive: true
    },
    {
        id: 2,
        code: 'OUT',
        description: 'Outgoing',
        isActive: true
    },
    {
        id: 3,
        code: 'INT',
        description: 'Internal',
        isActive: true
    },
    {
        id: 4,
        code: 'OTH',
        description: 'Other',
        isActive: true
    },
    {
        id: 5,
        code: 'SYS',
        description: 'System',
        isActive: true
    }];

export const LvTaskSubType = [
    {
        id: 1,
        code: 'PC',
        description: 'Phone Call',
        isActive: true
    },
    {
        id: 2,
        code: 'EM',
        description: 'Email',
        isActive: true
    },
    {
        id: 3,
        code: 'FX',
        description: 'Fax',
        isActive: true
    },
    {
        id: 4,
        code: 'MA',
        description: 'Mail',
        isActive: true
    },
    {
        id: 5,
        code: 'OTH',
        description: 'Other',
        isActive: true
    },
    {
        id: 6,
        code: 'SYS',
        description: 'System',
        isActive: true
    }];

export const LvTaskStatus = [
    {
        id: 1,
        code: 'OP',
        description: 'Open',
        isActive: true
    },
    // {
    //   id: 2,
    //   code: 'CA',
    //   description: 'Cancelled',
    //   isActive: true
    // },
    {
        id: 3,
        code: 'CL',
        description: 'Closed',
        isActive: true
    },
    // {
    //   id: 4,
    //   code: 'CO',
    //   description: 'Completed',
    //   isActive: true
    // }
];

export const LvTaskContactType = [
    {
        id: 1,
        code: 'INS',
        description: 'Insured',
        isActive: true
    },
    {
        id: 2,
        code: 'AG',
        description: 'Agent',
        isActive: true
    },
    {
        id: 3,
        code: 'INT',
        description: 'Internal',
        isActive: true
    },
    {
        id: 4,
        code: 'OTH',
        description: 'Other',
        isActive: true
    },
    {
        id: 5,
        code: 'MORT',
        description: 'Mortgagee',
        isActive: true
    },
    {
        id: 6,
        code: 'SYS',
        description: 'System',
        isActive: true
    }];

export const LvTaskCategory = [
    {
        id: 1,
        code: 'AG',
        description: 'Agency',
        isActive: true
    },
    {
        id: 2,
        code: 'BI',
        description: 'Billing',
        isActive: true
    },
    {
        id: 3,
        code: 'PO',
        description: 'Policy',
        isActive: true
    },
    {
        id: 4,
        code: 'GE',
        description: 'General',
        isActive: true
    },
    {
        id: 5,
        code: 'SYS',
        description: 'System',
        isActive: true
    }];

export const LvTaskStateCode = [
    {
        code: 'FL',
        description: 'Florida',
    },
    {
        code: 'LA',
        description: 'Louisiana',
    },
    {
        code: 'MS',
        description: 'Mississippi',
    },
    {
        code: 'AL',
        description: 'Alabama',
    },
    {
        code: 'SC',
        description: 'South Carolina',
    },
    {
        code: 'TX',
        description: 'Texas',
    }, 
];

export const LvTaskDescription = [
    {
        id: 1,
        code: 'AGRC',
        description: 'Agency Of Record Change',
        isActive: true,
        taskCategoryCode: 'AG'
    },
    {
        id: 2,
        code: 'BIPI',
        description: 'Billing/Payment Inquiry',
        isActive: true,
        taskCategoryCode: 'BI'
    },
    {
        id: 3,
        code: 'CI',
        description: 'Cancel Inquiry',
        isActive: true,
        taskCategoryCode: 'PO'
    },
    {
        id: 4,
        code: 'CO',
        description: 'Commissions',
        isActive: true,
        taskCategoryCode: 'AG'
    },
    {
        id: 5,
        code: 'CIP',
        description: 'Company Info Provided',
        isActive: true,
        taskCategoryCode: 'GE'
    },
    {
        id: 6,
        code: 'CP',
        description: 'Consumer Portal',
        isActive: true,
        taskCategoryCode: 'GE'
    },
    {
        id: 7,
        code: 'CQ',
        description: 'Coverage Question',
        isActive: true,
        taskCategoryCode: 'PO'
    },
    {
        id: 8,
        code: 'EUWG',
        description: 'Eligibility/UW Guidelines',
        isActive: true,
        taskCategoryCode: 'PO'
    },
    {
        id: 9,
        code: 'ENDRI',
        description: 'Endorsement Inquiry',
        isActive: true,
        taskCategoryCode: 'PO'
    },
    {
        id: 10,
        code: 'EXREQ',
        description: 'Exception Request',
        isActive: true,
        taskCategoryCode: 'PO'
    },
    {
        id: 11,
        code: 'FOUP',
        description: 'Follow up',
        isActive: true,
        taskCategoryCode: 'PO'
    },
    {
        id: 12,
        code: 'GEIN',
        description: 'General Inquiry',
        isActive: true,
        taskCategoryCode: 'GE'
    },
    {
        id: 13,
        code: 'NBQ',
        description: 'New Business Quote',
        isActive: true,
        taskCategoryCode: 'PO'
    },
    {
        id: 14,
        code: 'NRI',
        description: 'NonRenewal Inquiry',
        isActive: true,
        taskCategoryCode: 'PO'
    },
    {
        id: 15,
        code: 'PR',
        description: 'Password Reset',
        isActive: true,
        taskCategoryCode: 'AG'
    },
    {
        id: 16,
        code: 'PVP',
        description: 'Pay Via Phone',
        isActive: true,
        taskCategoryCode: 'BI'
    },
    {
        id: 17,
        code: 'POIN',
        description: 'Policy Information',
        isActive: true,
        taskCategoryCode: 'PO'
    },
    {
        id: 18,
        code: 'REIN',
        description: 'Refund Inquiry',
        isActive: true,
        taskCategoryCode: 'BI'
    },
    {
        id: 19,
        code: 'REREQ',
        description: 'Reinstatement Request',
        isActive: true,
        taskCategoryCode: 'PO'
    },
    {
        id: 20,
        code: 'RENIN',
        description: 'Renewal Inquiry',
        isActive: true,
        taskCategoryCode: 'PO'
    },
    {
        id: 21,
        code: 'SYSIS',
        description: 'System Issue',
        isActive: true,
        taskCategoryCode: 'GE'
    },
    {
        id: 22,
        code: 'UMFU',
        description: 'Undeliverable Mail Follow Up',
        isActive: true,
        taskCategoryCode: 'PO'
    },
    {
        id: 23,
        code: 'SPPA',
        description: 'Scheduled Pers Property Appraisal',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 24,
        code: 'SPPAR',
        description: 'Scheduled Pers Property Appraisal Rev',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 25,
        code: 'WMF',
        description: 'Wind Mitigation Form',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 26,
        code: 'WMFR',
        description: 'Wind Mitigation Form Rev',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 27,
        code: 'POR',
        description: 'Proof of Renovations (Roof, elec, plumb, heat)',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 28,
        code: 'PRR',
        description: 'Proof of Renovations Rev',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 29,
        code: 'PS',
        description: 'Proof of Sprinklers',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 30,
        code: 'PSR',
        description: 'Proof of Sprinklers Rev',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 31,
        code: 'WPWF',
        description: 'Wind Pol or Wind/Hail Exclusion Form',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 32,
        code: 'WPWFR',
        description: 'Wind Pol or Wind/Hail Exclusion Form Rev',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 33,
        code: 'CRF',
        description: 'Contents Rejection Form',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 34,
        code: 'CRFR',
        description: 'Contents Rejection Form Rev',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 35,
        code: 'OLN',
        description: 'Ordinance or Law Notice',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 36,
        code: 'OLNR',
        description: 'Ordinance or Law Notice Rev',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 37,
        code: 'NEUWR',
        description: 'New Business UW Referral',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 38,
        code: 'MUWR',
        description: 'Midterm UW Referral',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 39,
        code: 'RUWR',
        description: 'Renewal UW Referral',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 40,
        code: 'IREF',
        description: 'Inspection Referral',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 41,
        code: 'CLREF',
        description: 'Claim Referral',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 42,
        code: 'PBR',
        description: 'Post Bind Referral',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 43,
        code: 'RRNCTR',
        description: 'Consent To Rate',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 44,
        code: 'ARC',
        description: 'Apply Renewal Changes',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    // {
    //     id: 45,
    //     code: 'IRS',
    //     description: 'Inspection Report',
    //     isActive: true,
    //     taskCategoryCode: 'SYS'
    // },
    {
        id: 46,
        code: 'FSH',
        description: 'Fortified for Safer Home',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 47,
        code: 'FSHFR',
        description: 'Fortified for Safer Home Form Rev',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 48,
        code: 'RHD',
        description: 'Renovated Home Discount',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 49,
        code: 'RHDDR',
        description: 'Renovated Home Discount Doc Rev',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 50,
        code: 'POL',
        description: 'Proof of Lease',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 51,
        code: 'POLDR',
        description: 'Proof of Lease Doc Rev',
        isActive: true,
        taskCategoryCode: 'SYS'
    },
    {
        id: 52,
        code: 'FDV',
        description: 'Flood Discount Validation',
        isActive: true,
        taskCategoryCode: 'SYS'
    }
];

export const LvTaskNoteCategory = [
    {
        id: 1,
        code: 'UN',
        description: 'Underwriting',
        isActive: true
    },
    {
        id: 2,
        code: 'BI',
        description: 'Billing',
        isActive: true
    },
    {
        id: 3,
        code: 'PO',
        description: 'Policy',
        isActive: true
    },
    {
        id: 4,
        code: 'CL',
        description: 'Claims',
        isActive: true
    },
    {
        id: 5,
        code: 'MISC',
        description: 'Miscellaneous',
        isActive: true
    }];

export const LvTaskNoteContactType = [
    {
        id: 1,
        code: 'AG',
        description: 'Agent',
        isActive: true
    },
    {
        id: 2,
        code: 'INS',
        description: 'Insured',
        isActive: true
    },
    {
        id: 3,
        code: 'INT',
        description: 'Internal',
        isActive: true
    },
    {
        id: 4,
        code: 'MORT',
        description: 'Mortgagee',
        isActive: true
    },
    {
        id: 5,
        code: 'OTH',
        description: 'Other',
        isActive: true
    },
    {
        id: 6,
        code: 'SYS',
        description: 'System',
        isActive: true
    }];

export const LvTaskDocType = [
    {
        id: 1,
        code: 'ADP',
        description: 'Amended Declaration Page',
        isActive: true
    },
    {
        id: 2,
        code: 'BD',
        description: 'Bind Documentation',
        isActive: true
    },
    {
        id: 3,
        code: 'CR',
        description: 'Claim Referral',
        isActive: true
    },
    {
        id: 4,
        code: 'E',
        description: 'Email',
        isActive: true
    },
    {
        id: 5,
        code: 'INS',
        description: 'Inspection',
        isActive: true
    },
    {
        id: 6,
        code: 'INV',
        description: 'Invoice',
        isActive: true
    },
    {
        id: 7,
        code: 'MISC',
        description: 'Miscellaneous',
        isActive: true
    },
    {
        id: 8,
        code: 'NBDP',
        description: 'New Business Declaration Page',
        isActive: true
    },
    {
        id: 9,
        code: 'NOTC',
        description: 'Notices',
        isActive: true
    },
    {
        id: 10,
        code: 'RDP',
        description: 'Renewal Declaration Page',
        isActive: true
    }];

export const LvTaskDocStatus = [
    {
        id: 1,
        code: 'REC',
        description: 'Received',
        isActive: true
    },
    {
        id: 2,
        code: 'SENT',
        description: 'Sent',
        isActive: true
    }];

export const LvTaskAction = [
    {
        id: 1,
        code: 'TA',
        description: 'Task Added',
        isActive: true
    },
    {
        id: 2,
        code: 'TC',
        description: 'Type Change',
        isActive: true
    },
    {
        id: 3,
        code: 'STC',
        description: 'Sub-Type Change',
        isActive: true
    },
    {
        id: 4,
        code: 'CTC',
        description: 'Contact Type Change',
        isActive: true
    },
    {
        id: 5,
        code: 'CNC',
        description: 'Contact Name Change',
        isActive: true
    },
    {
        id: 6,
        code: 'CC',
        description: 'Category Change',
        isActive: true
    },
    {
        id: 7,
        code: 'DDC',
        description: 'Due Date Change',
        isActive: true
    },
    {
        id: 8,
        code: 'PRC',
        description: 'Priority Change',
        isActive: true
    },
    {
        id: 9,
        code: 'OC',
        description: 'Owner Change',
        isActive: true
    },
    {
        id: 10,
        code: 'SC',
        description: 'Status Change',
        isActive: true
    },
    {
        id: 11,
        code: 'PLC',
        description: 'Policy Change',
        isActive: true
    }];

export const LvTaskPriority = [
    {
        id: 1,
        code: 'HI',
        description: 'High',
        isActive: true
    },
    {
        id: 2,
        code: 'NO',
        description: 'Normal',
        isActive: true
    },
    {
        id: 3,
        code: 'LO',
        description: 'Low',
        isActive: true
    }];

export const LvOwner = [
    {
        id: 1,
        code: 'ACG',
        description: 'Accounting Group',
        isActive: true
    },
    {
        id: 2,
        code: 'CSG',
        description: 'Customer Service Group',
        isActive: true
    },
    {
        id: 3,
        code: 'UWG',
        description: 'UnderWriting Group',
        isActive: true
    },
    {
        id: 4,
        code: 'PAG',
        description: 'Agent',
        isActive: true
    },
    {
        id: 5,
        code: 'PUW',
        description: 'Policy Underwriter',
        isActive: false
    },
    {
        id: 6,
        code: 'UAG',
        description: 'UW Assistant Group',
        isActive: true
    }];

export const TaskAction = [
    {
        id: 1,
        code: 'TA',
        description: 'Task Added',
        isActive: true
    },
    {
        id: 2,
        code: 'TC',
        description: 'Type Change',
        isActive: true
    },
    {
        id: 3,
        code: 'STC',
        description: 'Sub-Type Change',
        isActive: true
    },
    {
        id: 4,
        code: 'CTC',
        description: 'Contact Type Change',
        isActive: true
    },
    {
        id: 5,
        code: 'CNC',
        description: 'Contact Name Change',
        isActive: true
    },
    {
        id: 6,
        code: 'CC',
        description: 'Category Change',
        isActive: true
    },
    {
        id: 7,
        code: 'DDC',
        description: 'Due Date Change',
        isActive: true
    },
    {
        id: 8,
        code: 'PRC',
        description: 'Priority Change',
        isActive: true
    },
    {
        id: 9,
        code: 'OC',
        description: 'Owner Change',
        isActive: true
    },
    {
        id: 10,
        code: 'SC',
        description: 'Status Change',
        isActive: true
    },
    {
        id: 11,
        code: 'PC',
        description: 'Policy Change',
        isActive: true
    },
];

export const LVTaskPolicyStatus = [
    {
        code: 'QUO',
        description: 'Quoted'
    },
    {
        code: 'APP',
        description: 'Application'
    },
    {
        code: 'RUW',
        description: 'Referred to UW'
    },
    {
        code: 'AUW',
        description: 'Approved by UW'
    },
    {
        code: 'DUW',
        description: 'Declined by UW'
    },
    {
        code: 'AIR',
        description: 'Additional Information Required'
    },
    {
        code: 'QEXP',
        description: 'Quote Expired'
    },
    {
        code: 'ACT',
        description: 'Active'
    },
    {
        code: 'PEN',
        description: 'Pending'
    },
    {
        code: 'PEC',
        description: 'Pending Cancellation'
    },
    {
        code: 'CAN',
        description: 'Cancelled'
    },
    {
        code: 'PEXP',
        description: 'Policy Expired'
    }
];

export const LvSortBy = [
    {
        code: 'DateAdded',
        description: 'Date Added'
    },
    {
        code: 'DueDate',
        description: 'Due Date'
    },
    {
        code: 'InsuredName',
        description: 'Insured Name'
    },
    {
        code: 'QuoteNumber',
        description: 'Quote Number'
    },
    {
        code: 'PolicyNumber',
        description: 'Policy Number'
    },
    {
        code: 'EffectiveDate',
        description: 'Effective Date'
    }
];

