import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginLabelsConstants, ReferenceCodeConstants } from '../../../shared/constants/login.labels.constants';
import { BsModalRef } from 'ngx-bootstrap';
import { ValidationService } from '../../../core/services/validation.service';
import { UserTwoFactorAuthResponse } from '../../../shared/models/user-validation/user-two-factor-auth.response';
import { Router } from '@angular/router';
import Utils from '../../../shared/utilities/utils';
import { NavigationService } from '../../../core/services/navigation/navigation.service';
import { Subscription, timer } from 'rxjs';
import { TimerPipe } from '../../../shared/custom pipes/timer-pipe';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/services/auth.service';
import NotifUtils from '../../../shared/utilities/notif-utils';

@Component({
  selector: 'app-reference-code-modal',
  templateUrl: './reference-code-modal.component.html',
  styleUrls: ['./reference-code-modal.component.scss'],
  providers: [ TimerPipe ]
})
export class ReferenceCodeModalComponent implements OnInit, OnDestroy {
  title: string;
  description1: string;
  description2: string;

  referenceNumber: string;
  username: string;
  emailAddress: string;

  errorMessage: string;
  successMessage: string;
  isValid: boolean = true;
  isUserLoggedIn: boolean = false;

  inputtedCode: string;

  public ReferenceCodeConstants = ReferenceCodeConstants;
  public LoginLabelsConstants = LoginLabelsConstants;

  isResendCodeClicked: boolean = false;

  // Resend Code Timer
  countDown: Subscription;
  counter: number = ReferenceCodeConstants.countdown;
  tick: number = ReferenceCodeConstants.tick;

  constructor(private bsModalRef: BsModalRef
              , private validationService: ValidationService
              , private router: Router
              , private navService: NavigationService
              , private timerPipe: TimerPipe
              , private authService: AuthService) { }

  ngOnInit() {
    const auth = localStorage.getItem(environment.AuthKey);
    const authResponse = this.authService.getTokenResponse();

    if (auth || authResponse.token) { // to do expiration token if available

      if(auth){
        const userInfo: string = atob(JSON.stringify(auth).split('.')[1]);
        this.emailAddress = JSON.parse(userInfo).email;
      }else{
        const userInfo: string = atob(JSON.stringify(authResponse.token).split('.')[1]);
        this.emailAddress = JSON.parse(userInfo).email;
      }

    }
  }

  cancel(): void {
    this.clearAuthAndCloseModal();
  }

   // this called every time when user changed the code
  onCodeChanged(code: string): void {
    this.inputtedCode = code;
  }

  // this called only if user entered full code
  onCodeCompleted(code?: string): void {
    this.inputtedCode = code;
  }

  onContinue(): void {
    this.processTwoAuthFactor();
  }

  onResendCode(): void {
    this.isResendCodeClicked = true;
    this.counter = this.ReferenceCodeConstants.countdown;
    this.tick = this.ReferenceCodeConstants.tick;
    Utils.blockUI();
    this.validationService.getUserTwoFactorAuth(this.username).subscribe(result => {
      this.isResendCodeClicked = true;
      this.referenceNumber = result;
      this.isValid = true;
      this.successMessage = this.ReferenceCodeConstants.CodeSuccessSent;
      this.resendCodeTimer();
      Utils.unblockUI();

    }, err => {
      console.log(err);
      Utils.unblockUI();
    });
  }

  resendCodeTimer(): void {
    if (this.countDown) {
      this.countDown.unsubscribe();
    }
    this.countDown = timer(0, this.tick).subscribe(() => {
      --this.counter;
      this.counter = +this.timerPipe.transform(this.counter);
      if (+this.counter === 0) {
        this.isResendCodeClicked = false;
        this.countDown.unsubscribe();
      }
    });
  }

  processTwoAuthFactor(): void {
    Utils.blockUI();

    this.isUserLoggedIn = this.authService.isLoggedIn();
    if (this.isUserLoggedIn) {
      Utils.unblockUI();
      this.redirectToDashboard();
    }

    if (this.inputtedCode && this.inputtedCode !== '') {
      const payload = {
        userName: this.username,
        referenceCode: this.inputtedCode,
        referenceNumber: this.referenceNumber,
        isActive: true,
        appCode: environment.ApplicationId
      };

      this.validationService.postUserTwoFactorAuth(payload).subscribe((result: UserTwoFactorAuthResponse) => {
        if (result.isValid) {
          this.authService.setAuthData();
          this.isValid = result.isValid;
          this.errorMessage = result.error;

          this.navService.initializeMenus().subscribe(() => {
            Utils.unblockUI();
            this.bsModalRef.hide();
            this.authService.logUserLogin(this.username).subscribe(() => {
              this.authService.initAppSettings();
              this.router.navigate(['/dashboard']);
            });
          }, err => {
            Utils.unblockUI();
          });
        } else {
          this.isValid = result.isValid;
          this.errorMessage = this.ReferenceCodeConstants.invalidCode;
          this.errorLogging(result.error);
        }
      },
      error => {
        this.isValid = error.error.isValid;
        this.errorMessage = this.ReferenceCodeConstants.invalidCode;
        this.errorLogging(error.error.error);
      });
    } else {
      this.isValid = false;
      this.errorMessage = this.ReferenceCodeConstants.CodeisEmpty;
      this.errorLogging(this.errorMessage);
    }
  }

  errorLogging(errorMessage: any): void {
    Utils.unblockUI();
    this.authService.isLocked = errorMessage.toLowerCase().includes(LoginLabelsConstants.errorMessage.locked);
    if (this.authService.isLocked) {
      this.authService.loginAuditLog(LoginLabelsConstants.errorMessage.locked, this.username, true);
      NotifUtils.showError(errorMessage);
      this.clearAuthAndCloseModal();
    } else {
      this.authService.loginAuditLog(LoginLabelsConstants.errorMessage.twoFactor, this.username, true);
    }
  }

  ngOnDestroy() {
    this.isUserLoggedIn = this.authService.isLoggedIn();
    if (this.isUserLoggedIn) {
      Utils.unblockUI();
      this.redirectToDashboard();
    }

    if (this.countDown) {
      this.countDown.unsubscribe();
    }
  }

  clearAuthAndCloseModal(): void {
    this.isUserLoggedIn = this.authService.isLoggedIn();
    if (this.isUserLoggedIn) {
      Utils.unblockUI();
      this.redirectToDashboard();
    } else {
      localStorage.clear();
      this.bsModalRef.hide();
    }
  }

  redirectToDashboard(): void {
    this.bsModalRef.hide();
    this.authService.logUserLogin(this.username).subscribe(() => {
      this.router.navigate(['/dashboard']);
    });
  }
}
