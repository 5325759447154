import { Component, ElementRef, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import FormUtils from 'app/shared/utilities/form.utils';
import {
  BookTransferConstants,
  FromAgencyFormConstants,
  ToAgencyFormConstants,
  TransferDetailsFormConstants,
  TransferModeConstants,
  mode
} from 'app/shared/constants/book-transfer.constants';
import { FormGroup } from '@angular/forms';
import { BatchDashboardDTO } from 'app/shared/models/data/dto/book-transfer/batch-dashboard.dto';
import { IAngularMyDpOptions } from 'angular-mydatepicker';

@Component({
  selector: 'app-transfer-form',
  templateUrl: './transfer-form.component.html',
  styleUrls: ['./transfer-form.component.scss']
})
export class TransferFormComponent {
  @ViewChild('fromAgencyNameElement') fromAgencyNameElement: ElementRef;

  @Input() mode: mode = 'new';
  @Input() dateFormat: string = '';
  @Input() formLabels: string = '';
  @Input() fromAgencyForm: FormGroup;
  @Input() toAgencyForm: FormGroup;
  @Input() transferDetailsForm: FormGroup;
  @Input() fromAgencyNames: any;
  @Input() fromAgencyNameModel: any;
  @Input() fromSubAgencyNames: any;
  @Input() disableFromAgencySubAgencyName: boolean;
  @Input() fromSubAgencyNameModel: any;
  @Input() toAgencyNames: any;
  @Input() disableToAgencyName: boolean;
  @Input() toAgencyNameModel: any;
  @Input() toSubAgencyNames: any;
  @Input() disableToAgencySubAgencyName: boolean;
  @Input() toSubAgencyNameModel: any;
  @Input() currentBatchData: BatchDashboardDTO;
  @Input() transferDateOptions: IAngularMyDpOptions;
  @Input() transferFormLabels: any;

  @Output() fromAgencyNameChanged = new EventEmitter<any>();
  @Output() fromSubAgencyNameChanged = new EventEmitter<any>();
  @Output() toAgencyNameChanged = new EventEmitter<any>();
  @Output() toSubAgencyNameChanged = new EventEmitter<any>();

  bookTransferConstants = BookTransferConstants;
  fromAgencyFormConstants = FromAgencyFormConstants;
  transferDetailsFormConstants = TransferDetailsFormConstants;
  toAgencyFormConstants = ToAgencyFormConstants;
  errorMessageConstant = ErrorMessageConstant;
  transferModeConstants = TransferModeConstants;
  formUtils = FormUtils;

  constructor() { }

  fromAgencyNameChangedEvent(data: any): void {
    this.fromAgencyNameChanged.emit(data);
  }

  fromSubAgencyNameChangedEvent(data: any): void {
    this.fromSubAgencyNameChanged.emit(data);
  }

  toAgencyNameChangedEvent(data: any): void {
    this.toAgencyNameChanged.emit(data);
  }

  toSubAgencyNameChangedEvent(data: any): void {
    this.toSubAgencyNameChanged.emit(data);
  }
}