export class PolicyNotesSaveDTO {
    riskId: string | null;
    riskDetailId: string | null;
    riskBind: RiskBindDTO;
    public constructor(init?: Partial<PolicyNotesSaveDTO>) {
        Object.assign(this, init);
    }
}

export class RiskBindDTO {
    riskDetailId: string | null;
    riskBindNotes: PolicyRiskBindNoteDTO[];
    public constructor(init?: Partial<RiskBindDTO>) {
        Object.assign(this, init);
    }
}

export class PolicyRiskBindNoteDTO {
    id: string | null;
    riskBindId: string | null;
    riskDetailId: string | null;
    category: string;
    description: string;
    createdBy: number;
    createdDate?: Date;
    isAdd?: boolean;

    public constructor(init?: Partial<PolicyRiskBindNoteDTO>) {
        Object.assign(this, init);
    }
}