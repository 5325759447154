import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService, PageChangedEvent, TypeaheadMatch } from 'ngx-bootstrap';
import { CommissionConstant } from '../../../shared/constants/commission-constants';
import { CommissionPaymentData } from 'app/modules/reports/data/commission-payment.data';
import { select, Store } from '@ngrx/store';
import { BaseClass } from 'app/shared/base-class';
import { CommissionLabels } from 'app/shared/constants/commission-labels';
import { CommissionPaymenInfoModalComponent } from './commission-paymen-info-modal/commission-paymen-info-modal.component';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { selectCommissionPaymentList, selectFilteredCommissionPaymentList } from 'app/store/commission/commission-payment/commission-payment.selector';
import { Observable } from 'rxjs-compat';
import { takeUntil } from 'rxjs/operators';
import { CommissionPaymentService } from 'app/core/services/commission-payment/commission-payment.service';
import Utils from 'app/shared/utilities/utils';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { CommissionPaymentDTO, GetCommissionPaymentRequest, ICommissionPaymentDTO } from 'app/shared/models/data/dto/nacha/commission-payment.dto';
import { PolicyService } from 'app/core/services/submission/policy.service';

@Component({
  selector: 'app-commission-payment-modal',
  templateUrl: './commission-payment-modal.component.html',
  styleUrls: ['./commission-payment-modal.component.scss']
})
export class CommissionPaymentModalComponent extends BaseClass implements OnInit, OnDestroy {

  commissionConstant = CommissionConstant;
  public commissionLabels = CommissionLabels;
  public ErrorMessageConstant = ErrorMessageConstant;

  private selectCommissionPaymentList$: Observable<CommissionPaymentDTO[]>;
  private selectFilteredCommissionPaymentList$: Observable<CommissionPaymentDTO[]>;

  commissionPaymentModalRef: BsModalRef = null;

  constructor(
      public bsModalRef: BsModalRef,
      public commissionPaymentData: CommissionPaymentData,
      private modalService: BsModalService,
      private store: Store,
      private commissionPaymentService: CommissionPaymentService,
      private policyService: PolicyService
    ) {
      super();
      this.selectCommissionPaymentList$ = this.store.pipe(select(selectCommissionPaymentList));
      this.selectFilteredCommissionPaymentList$ = this.store.pipe(select(selectFilteredCommissionPaymentList));
      this.subscribeToInitialFetch();
  }

  ngOnInit() {
    this.commissionPaymentData.initCommissionPaymentList();
  }

  ngOnDestroy(): void {
    this.commissionPaymentData.searchInput = '';
    this.commissionPaymentData.commissionPaymentList = [];
    this.commissionPaymentData.showPreloader = false;
    this.commissionPaymentData.totalRowCount = 0;
    this.commissionPaymentData.itemPerPage = 5;
  }

  subscribeToInitialFetch(): void {
    this.selectCommissionPaymentList$.pipe(takeUntil(this.stop$)).subscribe(result => {
      this.commissionPaymentData.commissionPaymentList = result;
    });
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  pageChanged(event: PageChangedEvent): void {
    if (this.commissionPaymentData.currentPage === event.page) {
      return;
    } else {
      const request: GetCommissionPaymentRequest = {
        pageNumber: event.page,
        pageSize: this.commissionPaymentData.itemPerPage,
        search: this.commissionPaymentData.searchInput
      };
      this.commissionPaymentData.initCommissionPaymentList(request);
    }
  }

  searchRecords(input: string): void {
    let request: GetCommissionPaymentRequest = null;
    if(input) {
      this.commissionPaymentData.searchInput = input;
      this.commissionPaymentData.currentPage = 1;
       request = {
        pageNumber: 1,
        pageSize: this.commissionPaymentData.itemPerPage,
        search: input
      };
    }
    this.commissionPaymentData.initCommissionPaymentList(request);
  }

  get showTableFooter(): boolean {
    return this.commissionPaymentData.totalRowCount > this.commissionPaymentData.itemPerPage;
  }

  openLink(url: string): void {
    window.open(url);
  }

  showCommissionPaymentInfoModal(isEditable: boolean, isAdd?: boolean, isEdit?: boolean, isView?: boolean, commissionPayment?: CommissionPaymentDTO): void {
    let headerTitle = '';
    if(isAdd) {
      headerTitle = this.commissionLabels.addCommissionPayment;
      commissionPayment = null;
    }

    if (isEdit || isView) {

      if(isEdit) {
        headerTitle = this.commissionLabels.editCommissionPayment;
      }

      if (isView) {
        headerTitle = this.commissionLabels.viewCommissionPayment;
      }
    }

    if(isEditable || isView) {
      const initialState = {
        isAdd: isAdd,
        isEdit: isEdit,
        isView: isView,
        headerTitle: headerTitle,
        commissionPayment: commissionPayment
      };
      this.commissionPaymentModalRef = this.modalService.show(CommissionPaymenInfoModalComponent, {
        initialState,
        class: 'modal-dialog',
        backdrop: true,
        ignoreBackdropClick: true,
      });
      this.commissionPaymentData.isCommissionPaymentModalOpen = true;
    }
  }

  viewDocument(url: string): void {
    if (url) {
      this.policyService.generateSASURL(url).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
        window.open(resultSASURL, '_blank');
      });
    }
  }

  deleteCommissionPaymentData(commissionPaymentData: ICommissionPaymentDTO): void {
    if (commissionPaymentData.isEditable) {
      const emailCommissionConfirmMessage = `Are you sure you want to delete commission payment for ${commissionPaymentData.agencyCode}-${commissionPaymentData.agencyName} for ${commissionPaymentData.month}/${commissionPaymentData.year}?`;
      NotifUtils.showConfirmMessage(emailCommissionConfirmMessage, () => {
        this.commissionPaymentService.deleteCommissionPayment(commissionPaymentData.id).subscribe(res => {
          this.commissionPaymentData.initCommissionPaymentList();
        },
          err => {
            Utils.unblockUI();
            console.log(err);
          }
        );
      }, () => {
        Utils.unblockUI();
      });
    }
  }

  setViewDocumentToolTip(value: string): string {
    return value ? this.commissionLabels.table.commissionPayment.viewDocument : this.commissionLabels.table.commissionPayment.noDocumentAvailable;
  }
}
