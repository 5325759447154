import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AuthService } from '../../../core/services/auth.service';
import {
  IAopDeductibleMinimumOf5000RequiredArgs,
  IHurricaneDeductibleMinimumOf5PercentRequiredArgs,
  IHurricaneDeductibleMinimumPercentRequiredArgs
} from '../data/coverages.data';
import { CoveragesValidatorErrorConstants } from './validator-error-constants/coverages-validator-error.constants';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';
import { LA_LvRaterDeductibleHUR, LA_LvRaterOtherPerilDeductibleNHR } from 'app/states/la/shared/constants/coverages.options.constants';
import { LA_IAopDeductibleMinimumOf5000RequiredArgs } from 'app/states/la/shared/data/coverages.data';

export class CoveragesValidators {
  static auth: AuthService;
  static floodCovAShouldNotBeGreaterThanBaseValueValidator(baseCovValue: any, centauriPrivateFlood: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value) {
        // if (centauriPrivateFlood.value && control.value > baseCovValue.value && !(control.value > 2000000)) {
        if (control.value > Number(baseCovValue.value) && !(control.value > 2000000)) {
          key[CoveragesValidatorErrorConstants.floodCovAGreaterThanBaseErrorMessage.key] = true;
          return key;
        }
      }
      return null;
    };
  }

  static floodCovCShouldNotBeGreaterThanBaseValueValidator(baseCovCValue: any, centauriPrivateFlood: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value) {
        if (Math.round(control.value) > Math.round(baseCovCValue.value) && !(control.value > 500000)) {
          key[CoveragesValidatorErrorConstants.floodCovCGreaterThanBaseErrorMessage.key] = true;
          return key;
        }
      }
      return null;
    };
  }

  static floodCovAValidatorBaseOnFloodZone(floodZone: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value || control.value === 0) {
        if (control.value < 100000 && floodZone.value && !(floodZone.value === 'B' || floodZone.value === 'C' || floodZone.value === 'X')) {
          key[CoveragesValidatorErrorConstants.floodCovABaseFloodZoneErrorMessage.key] = true;
          return key;
        }
        if (control.value < 20000 && (floodZone.value === 'B' || floodZone.value === 'C' || floodZone.value === 'X')) {
          key[CoveragesValidatorErrorConstants.floodCovABaseFloodZoneBCXErrorMessage.key] = true;
          return key;
        }
        return null;
      }
      return null;
    };
  }

  static floodCovCValidatorBaseOnFloodZone(floodZone: any, coverageCLimitId?: any, formType?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};

      if (control.value || control.value === 0) {
        if (
          coverageCLimitId.value !== '0' &&
          control.value < 35000 &&
          floodZone.value && !(floodZone.value === 'B' || floodZone.value === 'C' || floodZone.value === 'X')
        ) {

          if (formType !== FormTypeConstants.HO3) {
            key[CoveragesValidatorErrorConstants.floodCovCBaseFloodZoneErrorMessage.key] = true;
          }

          return key;
        }

        if (
          coverageCLimitId.value !== '0' &&
          control.value < 8000 &&
          (floodZone.value === 'B' || floodZone.value === 'C' || floodZone.value === 'X')
        ) {
          if (formType !== FormTypeConstants.HO3) {
            key[CoveragesValidatorErrorConstants.floodCovCBaseFloodZoneBCXErrorMessage.key] = true;
          }

          return key;
        }
      }

      return null;
    };
  }

  static participationStatusIdShouldBeRegularProgram(participationStatusId: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value) {
        if (participationStatusId.value !== 'PS6') {
          key[CoveragesValidatorErrorConstants.participationStatusIdShouldBeRegularProgram.key] = true;
          return key;
        }
      }
      return null;
    };
  }

  static floodCovAInfoMessage(): ValidatorFn {
    return(control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value) {
        if (Number(control.value) > 2000000) {
          key[CoveragesValidatorErrorConstants.floodCovAInfoMessage.key] = true;
          return key;
        }
      }
      return null;
    };
  }

  static floodCovCInfoMessage(): ValidatorFn {
    return(control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value) {
        if (Number(control.value) > 500000) {
          key[CoveragesValidatorErrorConstants.floodCovCInfoMessage.key] = true;
          return key;
        }
      }
      return null;
    };
  }

  static firmDateValidator(dateNow: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value) {
        const current_year = dateNow.getFullYear();
        if (control.value < 1970 || control.value > current_year) {
          key[CoveragesValidatorErrorConstants.firmDateErrorMessage.key] = true;
          return key;
        }
      }
      return null;
    };
  }

  static hurricaneDedCannotBeLessThanAOP(hurricaneDed?: number, AOP?: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value) {
        if (control.value !== null && hurricaneDed < AOP) {
          key[CoveragesValidatorErrorConstants.hurricaneDedCannotBeLessThanAOP.key] = true;
          return key;
        }
      }
      return null;
    };
  }

  static hurricaneDeductibleMustBeExcludedWhenCountyMonroe(hurricaneDed?: any, county?: string): ValidatorFn{
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value) {
        if (control.value !== null && hurricaneDed !== 'E' && county?.toString().toLowerCase() === 'monroe') {
          key[CoveragesValidatorErrorConstants.hurricaneDeductibleMustBeExcludedWhenCountyMonroe.key] = true;
          return key;
        }
      }
      return null;
    };
  }

  static coverageL100kMaxValue(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value) {
        if (control.value > 100000) {
          key[CoveragesValidatorErrorConstants.coverageL100kMaxValue.key] = true;
          return key;
        }
      }
    };
  }

  static hurricaneDeductibleMinimumOf5PercentRequired(
    hurricaneDeductibleMinimumOf5PercentRequiredArgs: IHurricaneDeductibleMinimumOf5PercentRequiredArgs
  ): ValidatorFn {
    const {
      homeAge,
      isActiveIPXBrul,
      isExternal
    } = hurricaneDeductibleMinimumOf5PercentRequiredArgs;

    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      const homeAgeMinimum: number = 10;

      if (control.value) {
        const controlValue = control.value;
        if (controlValue !== 'E') {
          if (((Number(controlValue) === 500 && homeAge >= homeAgeMinimum) && isActiveIPXBrul) && isExternal) {
            key[CoveragesValidatorErrorConstants.hurricaneDeductibleMinimumOf5PercentRequired.key] = true;
            return key;
          } else if (((Number(controlValue) < 5 && homeAge >= homeAgeMinimum) && isActiveIPXBrul) && isExternal) {
            key[CoveragesValidatorErrorConstants.hurricaneDeductibleMinimumOf5PercentRequired.key] = true;
            return key;
          }
        }
      }
    };
  }

  static hurricaneDeductibleMinimumOfPercentRequired(
    hurricaneDeductibleMinimumOfPercentRequiredArgs: IHurricaneDeductibleMinimumPercentRequiredArgs
  ): ValidatorFn {
    const {
      homeAge,
      isActiveIPXBrul,
      isExternal,
      isValidForNewMinimumDeductibleStateWide
    } = hurricaneDeductibleMinimumOfPercentRequiredArgs;

    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      let homeAgeMinimum: number = 10;
      if (isValidForNewMinimumDeductibleStateWide) { homeAgeMinimum = 0; }
      if (control.value) {
        const controlValue = control.value;
        const minimumValue = isValidForNewMinimumDeductibleStateWide ? 2 : 5;
        if (controlValue !== 'E') {
          if (((Number(controlValue) === 500 && homeAge >= homeAgeMinimum) && isActiveIPXBrul) && isExternal) {
            switch(minimumValue) {
              case 2:
                key[CoveragesValidatorErrorConstants.hurricaneDeductibleMinimumOf2PercentRequired.key] = true;
                break;
              case 5:
                key[CoveragesValidatorErrorConstants.hurricaneDeductibleMinimumOf5PercentRequired.key] = true;
                break;
            }
            return key;
          } else if (((Number(controlValue) < minimumValue && homeAge >= homeAgeMinimum) && isActiveIPXBrul) && isExternal) {
            switch(minimumValue) {
              case 2:
                key[CoveragesValidatorErrorConstants.hurricaneDeductibleMinimumOf2PercentRequired.key] = true;
                break;
              case 5:
                key[CoveragesValidatorErrorConstants.hurricaneDeductibleMinimumOf5PercentRequired.key] = true;
                break;
            }
            return key;
          }
        }
      }
    };
  }

  static aopDeductibleMinimumOf5000Required(
    aopDeductibleMinimumOf5000RequiredArgs: IAopDeductibleMinimumOf5000RequiredArgs
  ): ValidatorFn {
    const {
      homeAge,
      isActiveIPXBrul,
      isExternal
    } = aopDeductibleMinimumOf5000RequiredArgs;

    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      const homeAgeMinimum: number = 10;

      if (control.value) {
        if (((Number(control.value) < 5000 && homeAge >= homeAgeMinimum) && isActiveIPXBrul) && isExternal) {
          key[CoveragesValidatorErrorConstants.aopDeductibleMinimumOf5000Required.key] = true;
          return key;
        }
      }
    };
  }

  static aopDeductibleMinimumOf2500Required(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value) {
        if ((Number(control.value)) < 2500) {
          key[CoveragesValidatorErrorConstants.aopDeductibleMinimumOf2500Required.key] = true;
          return key;
        }
      }
    };
  }

  static la_aopDeductibleMinimumOf5000Required(
    aopDeductibleMinimumOf5000RequiredArgs: LA_IAopDeductibleMinimumOf5000RequiredArgs
  ): ValidatorFn {
    const {
      homeAge,
      isActiveIPXBrul,
      isExternal,
      coverageA
    } = aopDeductibleMinimumOf5000RequiredArgs;

    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      const homeAgeMinimum: number = 10;
      const aopData = LA_LvRaterOtherPerilDeductibleNHR.find(a => a.code === control.value);
      const aopAmount: number = aopData.isPercent ?
                          coverageA * (Number(aopData.code) / 100) :
                          Number(aopData.code);

      if (control.value) {
        if ((aopAmount < 5000 && homeAge >= homeAgeMinimum) && isActiveIPXBrul && isExternal) {
          key[CoveragesValidatorErrorConstants.aopDeductibleMinimumOf5000Required.key] = true;
          return key;
        }
      }
    };
  }

  static fireProtectionDeviceCannotBeNone(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value) {
        if (Number(control.value) === 1) {
          key[CoveragesValidatorErrorConstants.fireProtectionCannotBeNoneMessage.key] = true;
          return key;
        }
      }
    };
  }

  static covCCannotBeZero(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value !== null) {
        if (Number(control.value) === 0) {
          key[CoveragesValidatorErrorConstants.covCCannotBeZero.key] = true;
          return key;
        }
      }
    };
  }

  static covACannotBeZero(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value !== null) {
        if (Number(control.value) === 0) {
          key[CoveragesValidatorErrorConstants.covACannotBeZero.key] = true;
          return key;
        }
      }
    };
  }

  static starPackageCovCMinumum50Percentage(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value !== null) {
        if (Number(control.value) < 50 || Number(control.value) === 0) {
          key[CoveragesValidatorErrorConstants.covCMinimum50Percent.key] = true;
          return key;
        }
      }
    };
  }

  static starPackageCovCMinumum75Percentage(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value !== null) {
        if (Number(control.value) < 75 || Number(control.value) === 0) {
          key[CoveragesValidatorErrorConstants.covCMinimum75Percent.key] = true;
          return key;
        }
      }
    };
  }

  static hurricaneDeductibleMin5Percent(coverageA: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value !== null) {
        const hurricaneDeductibleId = control.value;
        const hurricaneOption = LA_LvRaterDeductibleHUR.find(a => a.code === control.value);

        if(hurricaneOption.isPercent) {
          if(Number(hurricaneDeductibleId) < 5) {
            key[CoveragesValidatorErrorConstants.minimunHurricaneDeductible5Percent.key] = true;
            return key;
          }
        } else {
          const covA5Percent = hurricaneDeductibleId === 'E' ? 0 : coverageA * 0.05;
          if(Number(hurricaneDeductibleId) < covA5Percent) {
            key[CoveragesValidatorErrorConstants.minimunHurricaneDeductible5Percent.key] = true;
            return key;
          }
        }
      }
    };
  }

  static hurricaneDeductibleMin1Percent(coverageA: number, coverageC: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value !== null) {
        const hurricaneDeductibleId = control.value;
        const hurricaneOption = LA_LvRaterDeductibleHUR.find(a => a.code === control.value);

        if(hurricaneOption.isPercent) {
          if(Number(hurricaneDeductibleId) < 1) {
            key[CoveragesValidatorErrorConstants.minimunHurricaneDeductible1Percent.key] = true;
            return key;
          }
        } else {
          const isExcludedHurricaneDeductible = hurricaneDeductibleId === 'E';
          const covAC1Percent = isExcludedHurricaneDeductible ? 0 : (coverageA + coverageC) * 0.01;
          if(!isExcludedHurricaneDeductible && (Number(hurricaneDeductibleId) < covAC1Percent && covAC1Percent < 1000)) {
            key[CoveragesValidatorErrorConstants.minimunHurricaneDeductible1Percent.key] = true;
            return key;
          }
        }
      }
    };
  }

  static ho3IsDwellCourseAddedValidator(coverageCLimitId: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value !== null && Number(coverageCLimitId) !== 25) {
        key[CoveragesValidatorErrorConstants.ho3IsDwellCourseAdded.key] = true;
        return key;
      }
    };
  }

  static dp3CovCMustIncrementsOf5k(): ValidatorFn{
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      const value = control.value;
      if (value !== null && Number(value) % 5000 !== 0) {
        key[CoveragesValidatorErrorConstants.dp3CovCMustIncrementOf5k.key] = true;
        return key;
      }
    };
  }

  static hurricaneDeductibleMustBeExcludedWhenPIALZoneEqualFive(hurricaneDeductible?: any, pialZone?: string): ValidatorFn{
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control.value) {
        if (control.value !== null && hurricaneDeductible !== 'E' && pialZone === 'PZ5') {
          key[CoveragesValidatorErrorConstants.hurricaneDeductibleMustBeExcludedWhenPIALZoneEqualFive.key] = true;
          return key;
        }
      }
      return null;
    };
  }
}
