import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import FormUtils from 'app/shared/utilities/form.utils';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { ApplicantData } from '../../../../modules/submission-management/data/applicant.data';
import { CoveragesData, MAXCOVA, MINCOVA, MINCOVCDP3DEF, MAXCOVCDP3DEF, MINCOVCDP3TENANT, MAXCOVCDP3TENANT } from '../../../../modules/submission-management/data/coverages.data';
import { Pages } from '../../../../shared/constants/pages-and-sections.constants';
import { GeneralValidationService } from './general-validation.service';
import { AuthService } from '../../auth.service';
import { CoveragesValidators } from '../../../../modules/submission-management/validators/coverages.validators';
import { QuoteService } from '../../quote.service';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';
import { PropertyData } from '../../../../modules/submission-management/data/property.data';
import { LocationLabelConstants } from '../../../../shared/constants/property.labels.constants';
import { CoverageBusinessRules } from '../../../../modules/submission-management/business-rules/coverage.business-rules';

@Injectable({
  providedIn: 'root'
})
export class CoveragesValidationService {
  // #region =====> Coverages Page
  coveragesSection: boolean = false;
  coveragesSectionInvalidControls = [];
  discountsSection: boolean = false;
  discountsSectionInvalidControls = [];
  floodSection: boolean = false;
  floodSectionInvalidControls = [];
  formTypeConstants = FormTypeConstants;
  genericConstants = GenericConstants;
  locationLabelConstants = LocationLabelConstants;

  get CoveragesForm() {
    return this.coveragesData.coveragesAndDeductiblesForm;
  }

  get DiscountsForm() {
    return this.coveragesData.discountsForm;
  }

  get FloodForm() {
    return this.coveragesData.floodForm;
  }
// #endregion

get ApplicantInformationForm() {
  return this.applicantData.mainApplicant;
}

  constructor(
    protected coveragesData: CoveragesData,
    protected generalValidationService: GeneralValidationService,
    protected applicantData: ApplicantData,
    protected authService: AuthService,
    protected quoteService: QuoteService,
    protected propertyData: PropertyData,
    protected coveragesBusinessRules: CoverageBusinessRules
  ) { }

  checkCoveragesPage(): void {
    this.coveragesSection = this.checkCoveragesSection();
    this.discountsSection = this.checkDiscountsSection();
    this.floodSection = this.checkFloodSection();

    this.coveragesSectionInvalidControls = this.generalValidationService.findInvalidControls(this.CoveragesForm);
    this.discountsSectionInvalidControls = this.generalValidationService.findInvalidControls(this.DiscountsForm);
    this.floodSectionInvalidControls  = this.generalValidationService.findInvalidControls(this.FloodForm);

    this.generalValidationService.mapToInvalidFormControlList(this.coveragesSectionInvalidControls, Pages.Coverages.pageName, Pages.Coverages.sections.Coverages);
    this.generalValidationService.mapToInvalidFormControlList(this.discountsSectionInvalidControls, Pages.Coverages.pageName, Pages.Coverages.sections.Discounts);
    this.generalValidationService.mapToInvalidFormControlList(this.floodSectionInvalidControls, Pages.Coverages.pageName, Pages.Coverages.sections.Flood);
  }

  checkUserType(coverageSection: string): void {
    let userType = '';
    this.authService.userType.subscribe(x => {
      userType = x;
    });

    switch(userType.toLowerCase()) {
      case 'external':
        this.externalValidation(coverageSection);
        break;
      default:
        break;
    }
  }

  externalValidation(section: string): void {
    switch(section) {
      case Pages.Coverages.sections.Coverages:
        break;
      case Pages.Coverages.sections.Discounts:
        break;
      case Pages.Coverages.sections.Flood:
        if(!this.FloodForm.get('privateFloodCoverage').value) {
          this.generalValidationService.clearValidatorFormControl(this.FloodForm, 'firmDate');
        }
        break;
      default:
        break;
    }
  }

  // #region ==========> Coverages Page Validations
  checkCoveragesSection() {
    const isCreditOrdered = this.ApplicantInformationForm.get('isOrderYesNo').value;
    switch(isCreditOrdered) {
      case true:
        this.generalValidationService.clearValidatorFormControl(this.CoveragesForm, 'creditOrdered');
        // Note: This is the correct code
        // Commented for now, LexNex is always []
        // this.resetValidatorFormControl(this.CoveragesForm, 'creditOrdered', [Validators.required]);
        break;
      case false:
        this.generalValidationService.clearValidatorFormControl(this.CoveragesForm, 'creditOrdered');
        break;
    }

    const covA = this.CoveragesForm.get('coverageA').value;

    if (this.quoteService.formType === this.formTypeConstants.HO4) {
      this.generalValidationService.resetValidatorFormControl(this.CoveragesForm, 'coverageA', []);
    } else if (this.quoteService.formType === this.formTypeConstants.HO6) {
      this.generalValidationService.resetValidatorFormControl(this.CoveragesForm, 'coverageA', [Validators.required, Validators.min(0), Validators.max(MAXCOVA)]);
    } else {
      this.generalValidationService.resetValidatorFormControl(this.CoveragesForm, 'coverageA', [Validators.required, Validators.min(MINCOVA), Validators.max(MAXCOVA)]);
    }
    this.CoveragesForm.get('coverageA').markAsTouched();
    if (this.quoteService.formType === this.formTypeConstants.DP3) {
      this.checkCovC();
    }

    this.checkHurricaneDeductible();
    if (this.quoteService.formType === this.formTypeConstants.HO6) {
      this.coveragesBusinessRules.setupCoverageACValidatorByFormType();
    }

    return FormUtils.validateForm(this.CoveragesForm);
  }

  checkHurricaneDeductible(): void {
    const yearBuilt = Number(this.propertyData.propertyDetails.get('yearBuilt').value);
    const effectiveDate = this.applicantData.mainApplicant.get('effectiveDate').value;
    this.coveragesData.validateHurricaneDeductible(yearBuilt, effectiveDate);
  }

  checkDiscountsSection() {
    const isRetired = this.ApplicantInformationForm.get('isRetiredYesNo').value;
    switch(isRetired) {
      case true:
        this.generalValidationService.resetValidatorFormControl(this.DiscountsForm, 'seniorRetiree', [Validators.required]);
        break;
      case false:
        this.generalValidationService.clearValidatorFormControl(this.DiscountsForm, 'seniorRetiree');
        break;
    }
    return FormUtils.validateForm(this.DiscountsForm);
  }

  checkFloodSection() {
    this.checkUserType(Pages.Coverages.sections.Flood);

    if (this.quoteService.formType === this.formTypeConstants.HO4) {
      this.generalValidationService.clearValidatorFormControl(this.coveragesData.floodForm, 'applicantFloodPolicyId');
    }

    return FormUtils.validateForm(this.FloodForm);
  }

  checkCovC(): void {
    this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').clearValidators();
    const covC = this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').value;
    const propertyUsage = this.propertyData.locationDetails.get('propertyUsage').value;

    if (Number(covC) === 0 || covC === null) {
      this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').setValidators([Validators.required]);
    } else {
      this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').setValidators([
        Validators.required,
        Validators.min(propertyUsage === this.locationLabelConstants.tenantOccupied ? MINCOVCDP3TENANT : MINCOVCDP3DEF),
        Validators.max(propertyUsage === this.locationLabelConstants.tenantOccupied ? MAXCOVCDP3TENANT : MAXCOVCDP3DEF)
      ]);
    }
    this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').updateValueAndValidity();

    this.CoveragesForm.get('coverageC').markAsTouched();
  }
// #endregion

}
