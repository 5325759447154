import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { GeneralValidationService } from 'app/core/services/submission/validations/general-validation.service';
import { CustomValidators } from 'app/shared/validators/custom.validator';
import { BaseClass } from '../../../shared/base-class';
import { ApplicantData } from '../data/applicant.data';
import { QuickQuoteData } from '../data/quick-quote.data';
import { ApplicantValidators } from '../validators/applicant.validators';
import { ApplicantValidatorConstants } from '../validators/validator-error-constants/applicant-validators.constants';
import * as _ from 'lodash';
import { ApplicantFormControlConstants } from 'app/shared/constants/form-controls/applicant-form-control.constants';
import { CoveragesData } from '../data/coverages.data';
import { LvCreditOrderStatus, LvEstimatedCredit } from 'app/shared/constants/quick-quote.options.constants';
import { SummaryData } from '../data/summary.data';
import { AuthService } from 'app/core/services/auth.service';
import { RegexConstants } from 'app/shared/constants/regex.constants';

@Injectable()
export class ApplicantBusinessRules extends BaseClass {

  constructor(protected applicantData: ApplicantData,
    protected coveragesData: CoveragesData,
    protected summaryData: SummaryData,
    protected generalValidatorService: GeneralValidationService,
    protected authService: AuthService) {
    super();
  }

  calculateInsuredAge(dateOfBirth: Date): number {
    if (!!!dateOfBirth) {
      return 0;
    }

    const today = this.authService.getCustomDate();
    const birthDate = new Date(dateOfBirth);
    const effectiveDate = this.applicantData.mainApplicant.get('effectiveDate').value;
    const dateRef = !!!effectiveDate ? today : new Date(effectiveDate?.singleDate?.jsDate);

    let age: number = dateRef.getFullYear() - birthDate.getFullYear();
    const m: number = dateRef.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && dateRef.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  calculateInsuredAgeBasedOnEffectivityDate(dateOfBirth: Date, EffectiveDate: Date): number {
    if (!!!dateOfBirth) {
      return 0;
    }

    const today = this.authService.getCustomDate();
    const birthDate = new Date(dateOfBirth);
    const effectiveDate = EffectiveDate;
    const dateRef = new Date(effectiveDate);
    let age = dateRef.getFullYear() - birthDate.getFullYear();
    const m = dateRef.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && dateRef.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  isInAgeOfRetirement(age: number): boolean {
    return age >= 55 && age <= 59;
  }

  isInAgeOfRetirementBRULCOV20(age: number): boolean {
    return age >= 60;
  }

  updateApplicantNameValidators(userType): void {
    if (userType === 'internal') {
      this.generalValidatorService.resetValidatorFormControl(this.applicantData.mainApplicant, 'firstName',
        [Validators.required, CustomValidators.spaceValidator, Validators.pattern(RegexConstants.hasALetterAndASpaceAndAHyphenWithÑ)]);
      this.generalValidatorService.resetValidatorFormControl(this.applicantData.mainApplicant, 'lastName',
        [Validators.required, CustomValidators.spaceValidator, Validators.pattern(RegexConstants.hasALetterAndASpaceAndAHyphenWithÑ)]);
      this.generalValidatorService.resetValidatorFormControl(this.applicantData.mainApplicant, 'middleInitial',
        [CustomValidators.spaceValidator, Validators.pattern(RegexConstants.hasALetterAndASpaceAndAHyphenWithÑ)]);

    } else {
      this.generalValidatorService.resetValidatorFormControl(this.applicantData.mainApplicant, 'firstName',
        [Validators.required, CustomValidators.spaceValidator, ApplicantValidators.nameMustBeIndividualsAndNotCorporationsValidator, Validators.pattern(RegexConstants.hasALetterAndASpaceAndAHyphenWithÑ)]);
      this.generalValidatorService.resetValidatorFormControl(this.applicantData.mainApplicant, 'lastName',
        [Validators.required, CustomValidators.spaceValidator, ApplicantValidators.nameMustBeIndividualsAndNotCorporationsValidator, Validators.pattern(RegexConstants.hasALetterAndASpaceAndAHyphenWithÑ)]);
      this.generalValidatorService.resetValidatorFormControl(this.applicantData.mainApplicant, 'middleInitial',
        [CustomValidators.spaceValidator, ApplicantValidators.nameMustBeIndividualsAndNotCorporationsValidator, Validators.pattern(RegexConstants.hasALetterAndASpaceAndAHyphenWithÑ)]);

    }
  }

  showApplicantNameCorporationInvalidErrorMessage(formControl: string): void {
    if (this.isNameAnIndividualAndNotCorporation(this.applicantData.mainApplicant?.get(formControl)?.value)) {
      NotifUtils.showError(ApplicantValidatorConstants.nameMustBeIndividualsAndNotCorporationsErrorMessage.value);
    }
  }

  isNameAnIndividualAndNotCorporation(value: string): boolean {
    if (value) {
      const splitName = _.split(value, ' ');
      const splitNameUpperCase = splitName?.map(name => name.toUpperCase());
      const hasCoAbbreviation = _.indexOf(splitNameUpperCase, ApplicantValidatorConstants.corporationNames.coAbbreviation);
      const hasCorpAbbreviation = _.indexOf(splitNameUpperCase, ApplicantValidatorConstants.corporationNames.corpAbbreviation);
      const hasLlcAbbreviation = _.indexOf(splitNameUpperCase, ApplicantValidatorConstants.corporationNames.llcAbbreviation);
      const hasCompAbbreviation = _.indexOf(splitNameUpperCase, ApplicantValidatorConstants.corporationNames.compAbbreviation);
      const hasIncAbbreviation = _.indexOf(splitNameUpperCase, ApplicantValidatorConstants.corporationNames.incAbbreviation);
      const hasCorporationInName = _.indexOf(splitNameUpperCase, ApplicantValidatorConstants.corporationNames.corporation);
      const hasCompanyInName = _.indexOf(splitNameUpperCase, ApplicantValidatorConstants.corporationNames.company);
      const hasTrustInName = _.indexOf(splitNameUpperCase, ApplicantValidatorConstants.corporationNames.trust);

      return (hasCoAbbreviation !== -1
        || hasCorpAbbreviation !== -1
        || hasLlcAbbreviation !== -1
        || hasCompAbbreviation !== -1
        || hasIncAbbreviation !== -1
        || hasCorporationInName !== -1
        || hasCompanyInName !== -1
        || hasTrustInName !== -1);
    }
    return false;
  }

  validateApplicantInfo(formControlName: string): boolean {
    let hasChangedApplicantInfo: boolean = false;
    const applicantForm = this.applicantData.mainApplicant;
    const previousApplicantInfo = this.applicantData.previousApplicantInfo;
    if (applicantForm.get('isOrderYesNo').value &&
      this.coveragesData.hasSuccessOrderCredit) {

      switch (formControlName) {
        case ApplicantFormControlConstants.firstName:
          if (previousApplicantInfo?.firstName !== applicantForm?.get(formControlName)?.value.trim()) {
            hasChangedApplicantInfo = true;
          }

          break;
        case ApplicantFormControlConstants.middleInitial:
          if (previousApplicantInfo?.middleName !== applicantForm?.get(formControlName)?.value.trim()) {
            hasChangedApplicantInfo = true;
          }
          break;
        case ApplicantFormControlConstants.lastName:
          if (previousApplicantInfo?.lastName !== applicantForm?.get(formControlName)?.value.trim()) {
            hasChangedApplicantInfo = true;
          }
          break;
        case ApplicantFormControlConstants.nameSuffix:
          if (previousApplicantInfo?.nameSuffix !== applicantForm?.get(formControlName)?.value.trim()) {
            hasChangedApplicantInfo = true;
          }
          break;
        default:
          break;
      }

      if (hasChangedApplicantInfo) {
        applicantForm.get('isOrderYesNo').setValue(false);
        this.resetCreditDetails();
      }
    }

    return hasChangedApplicantInfo;
  }

  validateApplicantDateOfBirth(dateOfBirth): boolean {
    let hasChanged: boolean = false;
    const applicantForm = this.applicantData.mainApplicant;
    const previousApplicantInfo = this.applicantData.previousApplicantInfo;
    if (applicantForm.get('isOrderYesNo').value &&
      this.coveragesData.hasSuccessOrderCredit) {
      if (previousApplicantInfo?.birthDate !== dateOfBirth) {
        applicantForm.get('isOrderYesNo').setValue(false);
        this.resetCreditDetails();
        hasChanged = true;
      }
    }

    return hasChanged;
  }

  resetCreditDetails() {
    const notOrderedIndex = 0;
    const excellentIndex = 0;
    this.coveragesData.creditOrderStatusCode = LvCreditOrderStatus[notOrderedIndex].code;
    this.coveragesData.estimatedCode = LvEstimatedCredit[excellentIndex].code;
    this.coveragesData.creditScore = 900;
    this.coveragesData.creditScoreDateOrdered = new Date('');
    this.coveragesData.creditOrderStatusCode = LvCreditOrderStatus[notOrderedIndex].code;
    this.coveragesData.hasSuccessOrderCredit = false;
    this.summaryData.SummaryForm.get('creditOrderStatus').setValue(LvCreditOrderStatus[notOrderedIndex].description);
  }
}
