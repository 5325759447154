export const StateConstants: any[] = [
  { stateCode: 'AL', stateDescription: 'Alabama' },
  { stateCode: 'AK', stateDescription: 'Alasaka' },
  { stateCode: 'AZ', stateDescription: 'Arizona' },
  { stateCode: 'AR', stateDescription: 'Arkansas' },
  { stateCode: 'AS', stateDescription: 'American Samoa' },
  { stateCode: 'CA', stateDescription: 'California' },
  { stateCode: 'CO', stateDescription: 'Colorado' },
  { stateCode: 'CT', stateDescription: 'Connecticut' },
  { stateCode: 'DE', stateDescription: 'Delaware' },
  { stateCode: 'DC', stateDescription: 'District of Columbia' },
  { stateCode: 'FL', stateDescription: 'Florida' },
  { stateCode: 'GA', stateDescription: 'Georgia' },
  { stateCode: 'GU', stateDescription: 'Guam' },
  { stateCode: 'HI', stateDescription: 'Hawaii' },
  { stateCode: 'ID', stateDescription: 'Idaho' },
  { stateCode: 'IL', stateDescription: 'Illinois' },
  { stateCode: 'IN', stateDescription: 'Indiana' },
  { stateCode: 'IA', stateDescription: 'Iowa' },
  { stateCode: 'KS', stateDescription: 'Kansas' },
  { stateCode: 'KY', stateDescription: 'Kentucky' },
  { stateCode: 'LA', stateDescription: 'Louisiana' },
  { stateCode: 'ME', stateDescription: 'Maine' },
  { stateCode: 'MD', stateDescription: 'Maryland' },
  { stateCode: 'MA', stateDescription: 'Massachusetts' },
  { stateCode: 'MI', stateDescription: 'Michigan' },
  { stateCode: 'MN', stateDescription: 'Minnesota' },
  { stateCode: 'MS', stateDescription: 'Mississippi' },
  { stateCode: 'MO', stateDescription: 'Missouri' },
  { stateCode: 'MT', stateDescription: 'Montana' },
  { stateCode: 'NE', stateDescription: 'Nebraska' },
  { stateCode: 'NV', stateDescription: 'Nevada' },
  { stateCode: 'NH', stateDescription: 'New Hampshire' },
  { stateCode: 'NJ', stateDescription: 'New Jersey' },
  { stateCode: 'NM', stateDescription: 'New Mexico' },
  { stateCode: 'NY', stateDescription: 'New York' },
  { stateCode: 'NC', stateDescription: 'North Carolina' },
  { stateCode: 'ND', stateDescription: 'North Dakota' },
  { stateCode: 'MP', stateDescription: 'Northern Mariana Islands' },
  { stateCode: 'OH', stateDescription: 'Ohio' },
  { stateCode: 'OK', stateDescription: 'Oklahoma' },
  { stateCode: 'OR', stateDescription: 'Oregon' },
  { stateCode: 'PA', stateDescription: 'Pennsylvania' },
  { stateCode: 'PR', stateDescription: 'Puerto Rico' },
  { stateCode: 'RI', stateDescription: 'Rhode Island' },
  { stateCode: 'SC', stateDescription: 'South Carolina' },
  { stateCode: 'SD', stateDescription: 'South Dakota' },
  { stateCode: 'TN', stateDescription: 'Tennessee' },
  { stateCode: 'TX', stateDescription: 'Texas' },
  { stateCode: 'TT', stateDescription: 'Trust Territories' },
  { stateCode: 'UT', stateDescription: 'Utah' },
  { stateCode: 'VT', stateDescription: 'Vermont' },
  { stateCode: 'VA', stateDescription: 'Virginia' },
  { stateCode: 'VI', stateDescription: 'Virgin Islands' },
  { stateCode: 'WA', stateDescription: 'Washington' },
  { stateCode: 'WV', stateDescription: 'West Virginia' },
  { stateCode: 'WI', stateDescription: 'Wisconsin' },
  { stateCode: 'WY', stateDescription: 'Wyoming' },
];
