
import { IProcessedRaterView } from 'app/shared/models/rater-premium/processed-rater-view.model';

export interface RaterState {
  isLoading: boolean | null;
  list: IProcessedRaterView[] | null;
  proratedList: IProcessedRaterView[] | null;
};

export const initialState = {
  isLoading: null,
  list: null,
  proratedList: null
};