import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PathConstants } from '../../../shared/constants/path.constants';
import { AppAsideModule } from '@coreui/angular';
import { ModalModule } from 'ngx-bootstrap';
import { BatchPaymentsComponent } from './batch-payments.component';


const routes: Routes = [
  {
    path: '',
    component: BatchPaymentsComponent,
    children: [
        {
          path: PathConstants.Payments.BatchPayments.Pending,
          loadChildren: () => import('./pending-batch-payments/pending-batch-payments.module').then(m => m.PendingBatchPaymentsModule)
        },
        {
          path: PathConstants.Payments.BatchPayments.Processed,
          loadChildren: () => import('./processed-batch-payments/processed-batch-payments.module').then(m => m.ProcessedBatchPaymentsModule)
        },
        { path: '', redirectTo: PathConstants.Payments.BatchPayments.Pending, pathMatch: 'full' },
      ]
  },
  {
    path: '',
    redirectTo: PathConstants.Payments.BatchPayments.Index,
    pathMatch: 'full'
  },
  // { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes), AppAsideModule, ModalModule.forRoot()],
  exports: [RouterModule]
})
export class BatchPaymentsRoutingModule { }
