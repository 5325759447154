import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PolicyTasksComponent } from './policy-tasks.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { PaginationModule } from 'ngx-bootstrap';

const routes: Routes = [
  { path: '', component: PolicyTasksComponent }
];


@NgModule({
  declarations: [PolicyTasksComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    PaginationModule.forRoot()
  ],
  exports: [PolicyTasksComponent]
})
export class PolicyTasksModule { }
