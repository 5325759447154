export const AccountLockoutLabelConstants = {
    accountLocked: 'Account Locked',
    userUnlockSuccessMessage: '{username} was successfully unlocked by {user}.',
    userUnlockFailedMessage: '{user} failed to unlock the account of {username}.',
    auditlog: {
        action: {
            unlock: 'UNLOCK'
        },
        methodName: {
            unlock: 'Auth/reset-login'
        },
    },
    userRoles: {
        rivtechInternal: 0,
        rivtechAdmin: 1,
        centauriAdmin: 2,
        underwriter: 3,
        agent: 4,
        regionalSalesManager: 5,
        other: 6,
        accountant: 11,
    }
};