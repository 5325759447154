import { LossTypeModel } from '../models/submission/claims/claims.model';

export const LossTypeList: LossTypeModel[] = [
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'ACCDL',
    chargeable: true,
    actOfGod: false,
    description: 'Accidental Discharge/Leakage',
    value: 'Accidental Discharge/Leakage',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'APPL',
    chargeable: true,
    actOfGod: false,
    description: 'Appliance Related Water',
    value: 'Appliance Related Water',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'BINONAUTO',
    chargeable: true,
    actOfGod: false,
    description: 'Bodily Injury Liability – NonAuto',
    value: 'Bodily Injury Liability – NonAuto',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'BOILER',
    chargeable: false,
    actOfGod: false,
    description: 'Boiler & Machinery',
    value: 'Boiler & Machinery',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'BOP',
    chargeable: false,
    actOfGod: false,
    description: 'Business Owners Policy',
    value: 'Business Owners Policy',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'CLPS',
    chargeable: true,
    actOfGod: false,
    description: 'Building Collapse',
    value: 'Building Collapse',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'COLL',
    chargeable: true,
    actOfGod: false,
    description: 'Collision, Upset',
    value: 'Collision, Upset',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'COMPOPS',
    chargeable: false,
    actOfGod: false,
    description: 'Completed Operations Liability',
    value: 'Completed Operations Liability',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'CONTA',
    chargeable: true,
    actOfGod: false,
    description: 'Contamination',
    value: 'Contamination',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'CRAFT',
    chargeable: false,
    actOfGod: false,
    description: 'Watercraft',
    value: 'Watercraft',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'CREDIT',
    chargeable: true,
    actOfGod: false,
    description: 'Credit Card',
    value: 'Credit Card',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'DAMAG',
    chargeable: true,
    actOfGod: false,
    description: 'Damage to Property of Others',
    value: 'Damage to Property of Others',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'DISAP',
    chargeable: true,
    actOfGod: false,
    description: 'Mysterious Disappearance',
    value: 'Mysterious Disappearance',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'DISSC',
    chargeable: false,
    actOfGod: false,
    description: 'Mysterious Disappearance Scheduled Property',
    value: 'Mysterious Disappearance Scheduled Property',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'DOG',
    chargeable: true,
    actOfGod: false,
    description: 'Dog Bite (Liability)',
    value: 'Dog Bite (Liability)',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'EMPLOYEE',
    chargeable: false,
    actOfGod: false,
    description: 'Employee Dishonesty',
    value: 'Employee Dishonesty',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'EO',
    chargeable: false,
    actOfGod: false,
    description: 'Error and Omissions',
    value: 'Error and Omissions',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'EPLY',
    chargeable: false,
    actOfGod: false,
    description: 'Employment Practice Liability',
    value: 'Employment Practice Liability',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'EXPL',
    chargeable: true,
    actOfGod: false,
    description: 'Explosion',
    value: 'Explosion',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'EXTEN',
    chargeable: true,
    actOfGod: false,
    description: 'Extended Coverage Perils',
    value: 'Extended Coverage Perils',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'FIRE',
    chargeable: true,
    actOfGod: false,
    description: 'Fire',
    value: 'Fire',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'FIRELL',
    chargeable: true,
    actOfGod: false,
    description: 'Fire Legal Liability',
    value: 'Fire Legal Liability',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'FLOOD',
    chargeable: false,
    actOfGod: true,
    description: 'Flood',
    value: 'Flood',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'FOBJ',
    chargeable: true,
    actOfGod: false,
    description: 'Falling Objects',
    value: 'Falling Objects',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'FOROB',
    chargeable: false,
    actOfGod: false,
    description: 'Foreign Object Intake',
    value: 'Foreign Object Intake',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'FREEZE',
    chargeable: true,
    actOfGod: false,
    description: 'Freezing Water (includes bursting pipes)',
    value: 'Freezing Water (includes bursting pipes)',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'GLASS',
    chargeable: true,
    actOfGod: false,
    description: 'Broken Window',
    value: 'Broken Window',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'GROUNDED',
    chargeable: false,
    actOfGod: false,
    description: 'Grounded',
    value: 'Grounded',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'HAIL',
    chargeable: false,
    actOfGod: true,
    description: 'Hail',
    value: 'Hail',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'HAULAUNCH',
    chargeable: false,
    actOfGod: false,
    description: 'Hauling/Launching',
    value: 'Hauling/Launching',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'HWYACCD',
    chargeable: false,
    actOfGod: false,
    description: 'Highway Accident',
    value: 'Highway Accident',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'ICE',
    chargeable: true,
    actOfGod: false,
    description: 'Ice Damage',
    value: 'Ice Damage',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'ID',
    chargeable: false,
    actOfGod: false,
    description: 'Identification Theft',
    value: 'Identification Theft',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'LIABILITY',
    chargeable: true,
    actOfGod: false,
    description: 'Liability (All Other)',
    value: 'Liability (All Other)',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'LIGHT',
    chargeable: false,
    actOfGod: true,
    description: 'Lightning',
    value: 'Lightning',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'LIVES',
    chargeable: true,
    actOfGod: false,
    description: 'Livestock',
    value: 'Livestock',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'LLL',
    chargeable: false,
    actOfGod: false,
    description: 'Liquor Law Liability',
    value: 'Liquor Law Liability',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'MEDICAL',
    chargeable: true,
    actOfGod: false,
    description: 'Medical Payments',
    value: 'Medical Payments',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'MINE',
    chargeable: false,
    actOfGod: false,
    description: 'Mine Subsidence',
    value: 'Mine Subsidence',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'MOLD',
    chargeable: true,
    actOfGod: false,
    description: 'Mold',
    value: 'Mold',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'MOLDL',
    chargeable: true,
    actOfGod: false,
    description: 'Mold Liability',
    value: 'Mold Liability',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'MOVE',
    chargeable: false,
    actOfGod: true,
    description: 'Earth Movement',
    value: 'Earth Movement',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'OTHER',
    chargeable: true,
    actOfGod: false,
    description: 'Other -  Any value not in this list',
    value: 'Other -  Any value not in this list',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'PCOBI',
    chargeable: false,
    actOfGod: false,
    description: 'Products and Completed Operations Bodily Injury',
    value: 'Products and Completed Operations Bodily Injury',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'PCOPD',
    chargeable: false,
    actOfGod: false,
    description: 'Products and Completed Operations Property Damage',
    value: 'Products and Completed Operations Property Damage',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'PHYDA',
    chargeable: true,
    actOfGod: false,
    description: 'Physical Damage (All Other)',
    value: 'Physical Damage (All Other)',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'PINONAUTO',
    chargeable: false,
    actOfGod: false,
    description: 'Personal Injury – NonAuto',
    value: 'Personal Injury – NonAuto',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'PL',
    chargeable: false,
    actOfGod: false,
    description: 'Product Liability',
    value: 'Product Liability',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'PLBI',
    chargeable: true,
    actOfGod: false,
    description: 'Premises Liability Bodily Injury',
    value: 'Premises Liability Bodily Injury',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'PLIAB',
    chargeable: false,
    actOfGod: false,
    description: 'Pollution Liability',
    value: 'Pollution Liability',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'PLPD',
    chargeable: true,
    actOfGod: false,
    description: 'Premises Liability Property Damage',
    value: 'Premises Liability Property Damage',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'POLLUTION',
    chargeable: true,
    actOfGod: false,
    description: 'Pollution Remediation',
    value: 'Pollution Remediation',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'QUAKE',
    chargeable: false,
    actOfGod: true,
    description: 'Earthquake',
    value: 'Earthquake',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'RIOT',
    chargeable: true,
    actOfGod: false,
    description: 'Riot or Civil Commotion',
    value: 'Riot or Civil Commotion',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'ROBBERY',
    chargeable: true,
    actOfGod: false,
    description: 'Robbery',
    value: 'Robbery',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'SBKP',
    chargeable: false,
    actOfGod: false,
    description: 'Sewer Backup',
    value: 'Sewer Backup',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'SINK',
    chargeable: false,
    actOfGod: true,
    description: 'Sinkhole',
    value: 'Sinkhole',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'SINKING',
    chargeable: false,
    actOfGod: false,
    description: 'Sinking',
    value: 'Sinking',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'SLIP',
    chargeable: true,
    actOfGod: false,
    description: 'Slip/Fall (Liability)',
    value: 'Slip/Fall (Liability)',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'SMOKE',
    chargeable: true,
    actOfGod: false,
    description: 'Smoke',
    value: 'Smoke',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'SPOIL',
    chargeable: false,
    actOfGod: false,
    description: 'Spoilage',
    value: 'Spoilage',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'SPRLE',
    chargeable: true,
    actOfGod: false,
    description: 'Sprinkler Leakage',
    value: 'Sprinkler Leakage',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'SUBOBJ',
    chargeable: false,
    actOfGod: false,
    description: 'Submerged Object',
    value: 'Submerged Object',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'THEFT',
    chargeable: true,
    actOfGod: false,
    description: 'Theft/Burglary',
    value: 'Theft/Burglary',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'THFSC',
    chargeable: false,
    actOfGod: false,
    description: 'Theft Scheduled Property',
    value: 'Theft Scheduled Property',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'THIRDLIAB',
    chargeable: false,
    actOfGod: false,
    description: '3rd Party Liability',
    value: '3rd Party Liability',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'TOWING',
    chargeable: false,
    actOfGod: false,
    description: 'Towing',
    value: 'Towing',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'VMM',
    chargeable: true,
    actOfGod: false,
    description: 'Vandalism and Malicious Mischief',
    value: 'Vandalism and Malicious Mischief',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'VOLCANO',
    chargeable: false,
    actOfGod: true,
    description: 'Volcano',
    value: 'Volcano',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'WATER',
    chargeable: true,
    actOfGod: false,
    description: 'Water Damage',
    value: 'Water Damage',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'WBKP',
    chargeable: false,
    actOfGod: false,
    description: 'Water Backup',
    value: 'Water Backup',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'WC',
    chargeable: false,
    actOfGod: false,
    description: 'Worker’s Compensation',
    value: 'Worker’s Compensation',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'WCIDEM',
    chargeable: false,
    actOfGod: false,
    description: 'Workers Compensation Indemnity',
    value: 'Workers Compensation Indemnity',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'WCMED',
    chargeable: false,
    actOfGod: false,
    description: 'Workers Compensation Medical',
    value: 'Workers Compensation Medica',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'WEATHER',
    chargeable: false,
    actOfGod: true,
    description: 'Weather Related',
    value: 'Weather Related',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'WIND',
    chargeable: false,
    actOfGod: true,
    description: 'Wind',
    value: 'Wind',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'WRECKRMV',
    chargeable: false,
    actOfGod: false,
    description: 'Wreck Removal',
    value: 'Wreck Removal',
    isActive: true
  },
  {
    tableName: 'LvClaimType',
    tableDescription: 'Claim Types',
    code: 'WSNOW',
    chargeable: true,
    actOfGod: false,
    description: 'Weight of Ice or Snow',
    value: 'Weight of Ice or Snow',
    isActive: true
  }
];

export const ClaimsSource = [
  {
    tableName: 'LvClaimSource',
    tableDescription: 'Claim Source',
    code: 'CS0',
    description: 'APlus',
    isActive: true
  },
  {
    tableName: 'LvClaimSource',
    tableDescription: 'Claim Source',
    code: 'CS1',
    description: 'Agent',
    isActive: true
  },
  {
    tableName: 'LvClaimSource',
    tableDescription: 'Claim Source',
    code: 'CS2',
    description: 'Centauri',
    isActive: true
  },
  {
    tableName: 'LvClaimSource',
    tableDescription: 'Claim Source',
    code: 'CS3',
    description: 'FileHandler',
    isActive: true
  }
];

export const ClaimStatus = [
  {
    tableName: 'LvClaimStatus',
    tableDescription: 'Claim Status',
    code: 'C',
    description: 'Closed',
    isActive: true
  },
  {
    tableName: 'LvClaimStatus',
    tableDescription: 'Claim Status',
    code: 'O',
    description: 'Open',
    isActive: true
  }
];
