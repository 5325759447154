import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { SubmissionPageData } from '../../../../../modules/submission-management/data/submission-page.data';
import { PropertyData } from '../../../data/property.data';
import { PropertyLabelConstants, PropertyYearFields, PropertyDetailFields } from '../../../../../shared/constants/property.labels.constants';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { AuthService } from '../../../../../core/services/auth.service';
import { Validators } from '@angular/forms';
import { LvRaterRoofDeckAttachment } from '../../../../../shared/constants/property.options.constants';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { Subject } from 'rxjs';
import { PropertyValidators } from '../../../../../modules/submission-management/validators/property.validators';
import { PropertyValidatorErrorConstants } from '../../../../../modules/submission-management/validators/validator-error-constants/property-validator-error.constants';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { BaseClass } from '../../../../../shared/base-class';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ISO360ValueModalComponent } from './iso-360value-modal/iso-360value-modal.component';
import { ThirdPartyData } from '../../../../../modules/submission-management/data/third-party.data';
import { ThirdPartyDataService } from '../../../../../core/services/submission/third-party/third-party-data.service';
import NotifUtils from '../../../../../shared/utilities/notif-utils';
import { Iso360ValueMappingData } from '../../../../../modules/submission-management/data/iso360value-mapping.data';
import Utils from '../../../../../shared/utilities/utils';
import { GeneralValidationService } from '../../../../../core/services/submission/validations/general-validation.service';
import { PolicySummaryData } from '../../../../policy-management/data/policy-summary.data';
import FormUtils from '../../../../../shared/utilities/form.utils';
import { EndorsementsData, WATERDAMAGEINCLUSIONYEARS } from '../../../../../modules/submission-management/data/endorsements.data';
import { ApplicantData } from '../../../../../modules/submission-management/data/applicant.data';
import { PropertyValidationService } from '../../../../../core/services/submission/validations/property-validation.service';
import { FormTypeConstants } from '../../../../../shared/constants/form-types.constants';
import { QuoteService } from '../../../../../core/services/quote.service';
import { EntityRiskData } from '../../../../../modules/submission-management/data/entity-risk.data';
import { GenericConstants } from '../../../../../shared/constants/generic.constants';
import { BCEGSService } from 'app/core/services/submission/third-party/ISO-Location/BCEGSService';
import { IAddressForCounty } from 'app/shared/models/data/dto/third-party/address-for-county.dto';
import { SummaryData } from '../../../../../modules/submission-management/data/summary.data';
import { updateRecalculateValuationIsLoadingFromPropertyDetailsComponent } from 'app/store/recalculate-valuation/racalculate-valuation.actions';
import { Store } from '@ngrx/store';
import { PropertyBusinessRules } from 'app/modules/submission-management/business-rules/property.business-rules';
import { EndorsementsLabelsConstants } from 'app/shared/constants/endorsements.labels.constants';

@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss']
})
export class PropertyDetailsComponent extends BaseClass implements OnInit, OnDestroy, OnChanges {
  public PropertyLabelConstants = PropertyLabelConstants;
  public ErrorMessageConstant = ErrorMessageConstant;
  public PropertyValidatorErrorConstants = PropertyValidatorErrorConstants;
  public EndorsementsLabelsConstants = EndorsementsLabelsConstants;
  public FormUtils = FormUtils;
  GenericLabel = GenericLabel;
  isOpen: boolean = true;
  updatedPurchaseDate: IAngularMyDpOptions;
  roofDeckAttachmentList: any[];
  modalRef: BsModalRef;
  showInternal: boolean = true;
  formTypeConstants = FormTypeConstants;
  genericConstants = GenericConstants;
  purchaseDate: Date;
  yearBuilt: number;
  purchaseDateYear: number;

  @Input() userType: string;

  constructor(
    public propertyData: PropertyData,
    public submissionData: SubmissionPageData,
    protected authService: AuthService,
    protected modalService: BsModalService,
    protected thirdPartyData: ThirdPartyData,
    protected thirdPartyDataService: ThirdPartyDataService,
    protected iso360ValueMapping: Iso360ValueMappingData,
    protected generalValidationService: GeneralValidationService,
    public policySummaryData: PolicySummaryData,
    protected endorsementsData: EndorsementsData,
    protected applicantData: ApplicantData,
    public quoteService: QuoteService,
    protected entityRiskData: EntityRiskData,
    protected bCEGSService: BCEGSService,
    protected summaryData: SummaryData,
    protected propertyValidationService: PropertyValidationService,
    protected store: Store,
    protected propertyBusinessRules: PropertyBusinessRules
  ) {
    super();
  }

  get riskId() {
    return this.entityRiskData.getRiskId();
  }

  get WindMitigationForm() {
    return this.propertyData.windMitigation;
  }

  get PropertyDetailsForm() {
    return this.propertyData.propertyDetails;
  }


  get isPurchaseDateInvalid(): boolean {
    const currentPurchaseDate = this.propertyData.propertyDetails.get('purchaseDate').value?.singleDate?.jsDate.toLocaleDateString('en-US');
    if (currentPurchaseDate) {
      this.purchaseDate = new Date(currentPurchaseDate);
      if (this.userType.toLowerCase() === 'external') {
        const isDateInvalid = this.checkPurchaseDateValidityToEffectiveDate();
        this.propertyValidationService.checkPropertyPage(true);
        return isDateInvalid;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get isPurchaseDateEmpty(): boolean {
    const currentPurchaseDate = this.propertyData.propertyDetails.get('purchaseDate').value?.singleDate?.jsDate.toLocaleDateString('en-US');
    return currentPurchaseDate === undefined;
  }

  ngOnInit() {
    this.setMaximumYear();
    this.setYearBuilt();
    this.checkPurchaseDateOptions();
    this.subscribeToYearBuilt();
    this.resetValidatorFormControl();
    this.propertyData.validYearRoof = this.propertyData.propertyDetails.get('yearRoof').value;
    // this.showFoundationElevation = this.setFoundationType(this.propertyData.propertyDetails.get('foundationType')?.value);
    this.subcribeToFormChanges();
    this.subcribeToFormChangesOnPageReload();
    this.propertyData.isYearAirconChange = false;
  }

  subcribeToFormChanges(): void {
    if (this.entityRiskData.apiCallCompleted) {
      this.subscribeToYearBuilt();
      this.setYearBuilt();
      this.subscribeToFoundationType();
    }
  }

  subcribeToFormChangesOnPageReload(): void {
    this.entityRiskData.isApiCallCompleted$.pipe(takeUntil(this.stop$), distinctUntilChanged()).subscribe((result) => {
      this.entityRiskData.apiCallCompleted.next(result);

      if (result) {
        this.subscribeToYearBuilt();
        this.setYearBuilt();
        this.subscribeToFoundationType();
      }
    });
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
    this.submissionData.isPropertyOpen = this.isOpen;
  }

  ngOnChanges() {
    this.showInternal = this.userType.toLowerCase() === 'external' ? false : true;
  }

  onChangeRoofShape(): void {
    const other_values = ['GM', 'S', 'M', 'C'];
    const roofShape = this.propertyData.propertyDetails.get('roofShape').value;

    this.propertyData.setRoofMaterialList();

    if (roofShape === 'F') {
      this.propertyData.roofDeckAttachmentList = LvRaterRoofDeckAttachment;
    } else {
      this.propertyData.roofDeckAttachmentList = LvRaterRoofDeckAttachment.filter(x => x.isActive === true);
      this.propertyData.windMitigation.get('roofDeckAttachment').setValue('');
    }

    if (other_values.find(x => x === roofShape)) {
      this.WindMitigationForm.get('roofGeometry').setValue('O');
      return;
    }

    this.WindMitigationForm.get('roofGeometry').setValue(roofShape);
  }

  onInputYearBuilt(yearBuilt: number): void {
    this.setMaximumYear();
    this.setYearBuilt();
    this.propertyData.setWindMitigationDefault();
    this.setWaterDamageExclusion(Number(yearBuilt));
    this.propertyData.propertyDetails.get('yearRoof').setValue(this.quoteService.formType !== this.formTypeConstants.HO4 ? yearBuilt : null);
    this.propertyData.isYearBuiltQQChange = true;
    this.BCEGSBasedOnYearBuiltChanged(Number(yearBuilt));
    this.endorsementsData.setBRNew110InfoMessageStatus();
    if (Number(yearBuilt) > 999) {
      this.propertyBusinessRules.triggerActiveNew125(false, false, false, false, true);
    }
  }

  setWaterDamageExclusion(yearBuilt: number): void {
    const effectiveDateYear = this.applicantData.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate?.getFullYear();
    this.endorsementsData.waterDamageDefaultState = false;
    const validYearBuilt = yearBuilt > 1800;
    this.endorsementsData.disableWaterDamage(false);
    const WDEYearInclusion = this.endorsementsData.getWDEYearInclusion();

    if (validYearBuilt && (effectiveDateYear - yearBuilt) > WDEYearInclusion) {
      this.endorsementsData.endorsementsGroup.get('waterDamage').setValue(true);
      this.endorsementsData.disableWaterDamage(false);
      if (this.endorsementsData.IsPolicyOrRenewal) {
        this.endorsementsData.disableWaterDamage(!this.showInternal);
      }
      this.endorsementsData.setLimitedWaterDamage();
      this.endorsementsData.setBRNew110InfoMessageStatus();
    }
  }

  setYearBuilt(): void {
    const yearBuilt = this.propertyData?.propertyDetails?.get('yearBuilt')?.value ?? this.PropertyDetailsForm.get('yearBuilt').value;
    if (yearBuilt) {
      this.PropertyDetailsForm.get('yearHeating').setValue(this.quoteService.formType !== this.formTypeConstants.HO4 ? this.propertyYearSetter(yearBuilt, 'yearHeating') : null);
      this.PropertyDetailsForm.get('yearElectrical').setValue(this.quoteService.formType !== this.formTypeConstants.HO4 ? this.propertyYearSetter(yearBuilt, 'yearElectrical') : null);
      this.PropertyDetailsForm.get('yearPlumbing').setValue(this.quoteService.formType !== this.formTypeConstants.HO4 ? this.propertyYearSetter(yearBuilt, 'yearPlumbing') : null);
      this.updatedPurchaseDate = this.propertyData.purchaseDateOption;
      this.propertyData.purchaseDateOption = {
        dateFormat: this.updatedPurchaseDate.dateFormat,
        disableSince: this.updatedPurchaseDate.disableSince,
        dateRange: this.updatedPurchaseDate.dateRange
      };
    }
  }

  subscribeToYearBuilt(): void {
    this.propertyData.propertyDetails.get('yearBuilt').valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
      if (this.quoteService.formType !== this.formTypeConstants.HO4) {
        this.PropertyDetailsForm.get('yearRoof').setValidators([Validators.required, Validators.min(1800),
        PropertyValidators.yearRoofShouldNotBeOlderThanYearBuiltValidator(value),
        PropertyValidators.yearRoofInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);

        this.propertyData.propertyDetails.get('yearElectrical').setValue(this.propertyYearSetter(value, 'yearElectrical'));
        this.PropertyDetailsForm.get('yearElectrical').setValidators([Validators.required, Validators.min(1800),
        PropertyValidators.yearShouldNotBeOlderThanYearBuiltValidator(value),
        PropertyValidators.yearInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);

        this.propertyData.propertyDetails.get('yearPlumbing').setValue(this.propertyYearSetter(value, 'yearPlumbing'));
        this.PropertyDetailsForm.get('yearPlumbing').setValidators([Validators.required, Validators.min(1800),
        PropertyValidators.yearShouldNotBeOlderThanYearBuiltValidator(value),
        PropertyValidators.yearInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);

        this.propertyData.propertyDetails.get('yearHeating').setValue(this.propertyYearSetter(value, 'yearHeating'));
        this.PropertyDetailsForm.get('yearHeating').setValidators([Validators.required, Validators.min(1800),
        PropertyValidators.yearShouldNotBeOlderThanYearBuiltValidator(value),
        PropertyValidators.yearInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);

        if (this.propertyData.propertyDetails.get('isCentralAirConditioning').value) {
          this.propertyData.propertyDetails.get('yearCentralAirConditioning').setValue(this.propertyYearSetter(value, 'yearCentralAirConditioning'));
          this.PropertyDetailsForm.get('yearCentralAirConditioning').setValidators([Validators.required, Validators.min(1800),
          PropertyValidators.yearShouldNotBeOlderThanYearBuiltValidator(value),
          PropertyValidators.yearInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);
          this.propertyData.validYearAircon = this.propertyData.propertyDetails.get('yearCentralAirConditioning').value;
        }
      } else {
        this.PropertyDetailsForm.get('yearRoof').setValidators([]);
        this.PropertyDetailsForm.get('yearElectrical').setValidators([]);
        this.PropertyDetailsForm.get('yearPlumbing').setValidators([]);
        this.PropertyDetailsForm.get('yearHeating').setValidators([]);
        this.PropertyDetailsForm.get('yearCentralAirConditioning').setValidators([]);
      }

      if (this.quoteService.formType !== this.formTypeConstants.HO4 && (value !== '')) {
        if (!this.checkYearLength(value?.toString())) {
          return;
        }
      }
    });

    this.propertyData.propertyDetails.get('yearRoof').updateValueAndValidity();
    this.propertyData.propertyDetails.get('yearBuilt').updateValueAndValidity();
    this.propertyData.propertyDetails.get('yearElectrical').updateValueAndValidity();
    this.propertyData.propertyDetails.get('yearPlumbing').updateValueAndValidity();
    this.propertyData.propertyDetails.get('yearHeating').updateValueAndValidity();
    this.propertyData.propertyDetails.get('yearCentralAirConditioning').updateValueAndValidity();
  }

  setMaximumYear(): void {
    const yearBuilt = this.PropertyDetailsForm.get('yearBuilt').value;
    if (this.quoteService.formType !== this.formTypeConstants.HO4) {
      this.PropertyDetailsForm.get('yearRoof').setValidators([Validators.required, Validators.min(1800),
      PropertyValidators.yearRoofShouldNotBeOlderThanYearBuiltValidator(yearBuilt),
      PropertyValidators.yearRoofInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);

      this.PropertyDetailsForm.get('yearHeating').setValidators([Validators.required, Validators.min(1800),
      PropertyValidators.yearShouldNotBeOlderThanYearBuiltValidator(yearBuilt),
      PropertyValidators.yearInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);

      this.PropertyDetailsForm.get('yearElectrical').setValidators([Validators.required, Validators.min(1800),
      PropertyValidators.yearShouldNotBeOlderThanYearBuiltValidator(yearBuilt),
      PropertyValidators.yearInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);

      this.PropertyDetailsForm.get('yearPlumbing').setValidators([Validators.required, Validators.min(1800),
      PropertyValidators.yearShouldNotBeOlderThanYearBuiltValidator(yearBuilt),
      PropertyValidators.yearInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);

      this.PropertyDetailsForm.get('yearCentralAirConditioning').setValidators([Validators.required, Validators.min(1800),
      PropertyValidators.yearShouldNotBeOlderThanYearBuiltValidator(yearBuilt),
      PropertyValidators.yearInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);
    } else {
      this.PropertyDetailsForm.get('yearRoof').setValidators([]);
      this.PropertyDetailsForm.get('yearHeating').setValidators([]);
      this.PropertyDetailsForm.get('yearElectrical').setValidators([]);
      this.PropertyDetailsForm.get('yearPlumbing').setValidators([]);
      this.PropertyDetailsForm.get('yearCentralAirConditioning').setValidators([]);
    }

    this.PropertyDetailsForm.get('yearRoof').updateValueAndValidity();
    this.PropertyDetailsForm.get('yearHeating').updateValueAndValidity();
    this.PropertyDetailsForm.get('yearElectrical').updateValueAndValidity();
    this.PropertyDetailsForm.get('yearPlumbing').updateValueAndValidity();
    this.PropertyDetailsForm.get('yearCentralAirConditioning').updateValueAndValidity();
  }

  subscribeToFoundationType(): void {
    this.PropertyDetailsForm.get('foundationType').valueChanges.pipe(takeUntil(this.stop$), distinctUntilChanged()).subscribe((result) => {
      this.propertyData.showFoundationElevation = this.setFoundationType(result);
      this.onChangeFoundationType();

      if (this.propertyData.showFoundationElevation) {
        this.generalValidationService.resetValidatorFormControl(this.PropertyDetailsForm, 'foundationElevation', [Validators.required]);
      } else {
        this.generalValidationService.clearValidatorFormControl(this.PropertyDetailsForm, 'foundationElevation');
      }

      this.PropertyDetailsForm.get('foundationElevation').updateValueAndValidity();
    });
  }

  setFoundationType(foundationType?): any {
    return (foundationType.indexOf('EP') !== -1 || foundationType.indexOf('SW') !== -1 || foundationType.indexOf('DP') !== -1) &&
      (this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3);;
  }

  showIso360Modal(): void {
    // If Valuation Id is missing, prevent the plugin from launching
    if (!this.propertyData.locationDetails.get('valuationId').value) {
      return NotifUtils.showError('Cannot Launch 360Value Window. Missing Valuation Id');
    }

    Utils.blockUI();

    this.recalculateValuation(this.quoteService.formType);
  }

  recalculateValuation(formType: string): void {
    this.store.dispatch(updateRecalculateValuationIsLoadingFromPropertyDetailsComponent({ isLoading: true }));

    // Recalculate Valuation first before opening modal. To ensure tokenized data is updated
    this.thirdPartyDataService.iso360ValueRecalculateValuation(this.thirdPartyData.getIso360ValueRequestModel())
    .pipe(takeUntil(this.stop$)).subscribe(data => {
      // Save the recalculated data to the database
      this.thirdPartyDataService.saveIso360ValueData(this.thirdPartyData.iso360PropertyModel())
      .pipe(takeUntil(this.stop$)).subscribe(() => {}, error => {
        Utils.unblockUI();
        NotifUtils.showError(ErrorMessageConstant.Iso360ValueErrorMessage.Iso360ReplacementCostErrorMessage);
      });
      // Api call to get token for Iso360Value Plugin Viewer
      this.thirdPartyDataService.iso360ValueEditExistingValuation(this.thirdPartyData.getIso360ValueRequestModel())
        .pipe(takeUntil(this.stop$)).subscribe(result => {
          if (formType !== FormTypeConstants.HO6) {
            const apiResult = result as any;

            if (apiResult?.errorDescription ||
              apiResult?.errorType) {
              Utils.unblockUI();
              return NotifUtils.showError(ErrorMessageConstant.Iso360ValueErrorMessage.Iso360ReplacementCostErrorMessage);
            }
          }

          const initialState = {
            title: 'ISO 360Value',
            token: result.token
          };

          Utils.unblockUI();
          this.modalRef = this.modalService.show(ISO360ValueModalComponent, {
            initialState,
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-lg modal-xl'
          });

          this.modalRef.content.onClose.pipe(takeUntil(this.stop$)).subscribe((res) => {
          });
        }, error => {
          Utils.unblockUI();
          NotifUtils.showError(ErrorMessageConstant.Iso360ValueErrorMessage.Iso360ReplacementCostErrorMessage);
        });

        this.store.dispatch(updateRecalculateValuationIsLoadingFromPropertyDetailsComponent({ isLoading: false }));
    }, error => {
      Utils.unblockUI();
      NotifUtils.showError(ErrorMessageConstant.Iso360ValueErrorMessage.Iso360ReplacementCostErrorMessage);
      this.store.dispatch(updateRecalculateValuationIsLoadingFromPropertyDetailsComponent({ isLoading: false }));
    });
  }

  onChangeExteriorWallFinish(): void {
    if (this.thirdPartyData.dataFromAPIResponse !== undefined ||
      this.thirdPartyData.dataFromAPIResponse !== null) {
      if (this.propertyData.propertyDetails.get('exteriorWallFinish').value !== this.thirdPartyData.dataFromAPIResponse.iso360Value?.exteriorWallFinish.code) {
        this.thirdPartyData.dataFromAPIResponse.iso360Value.exteriorWallFinish.iso360ValueCode = this.iso360ValueMapping.defaultExteriorWallFinishMapping.find(m => m.uiCode === this.propertyData.propertyDetails.get('exteriorWallFinish').value)?.iso360Code;
        this.thirdPartyData.dataFromAPIResponse.iso360Value.exteriorWallFinish.code = this.propertyData.propertyDetails.get('exteriorWallFinish').value;
      }
    }
  }

  onChangeExteriorWallConstructionType(): void {
    if (this.thirdPartyData.dataFromAPIResponse !== undefined ||
      this.thirdPartyData.dataFromAPIResponse !== null) {
      if (this.propertyData.propertyDetails.get('constructionType').value !== this.thirdPartyData.dataFromAPIResponse.iso360Value?.construction.code) {
        this.thirdPartyData.dataFromAPIResponse.iso360Value.construction.iso360ValueCode = this.iso360ValueMapping.defaultConstructionTypeMapping.find(m => m.uiCode === this.propertyData.propertyDetails.get('constructionType').value)?.iso360Code;
        this.thirdPartyData.dataFromAPIResponse.iso360Value.construction.code = this.propertyData.propertyDetails.get('constructionType').value;
        this.propertyData.isConstructionTypeChanged = true;
      }
    }
  }

  onChangeFoundationType(): void {
    if (this.thirdPartyData.dataFromAPIResponse !== undefined ||
      this.thirdPartyData.dataFromAPIResponse !== null) {

      if (!!!this.thirdPartyData.dataFromAPIResponse.iso360Value.foundationType) {
        this.thirdPartyData.dataFromAPIResponse.iso360Value.foundationType = {
          code: this.iso360ValueMapping.defaultFoundationTypeMapping.find(m => m.uiCode === this.propertyData.propertyDetails.get('foundationType').value)?.uiCode,
          iso360ValueCode: this.iso360ValueMapping.defaultFoundationTypeMapping.find(m => m.uiCode === this.propertyData.propertyDetails.get('foundationType').value)?.iso360Code,
          description: ''
        };
      }

      if (this.propertyData.propertyDetails.get('foundationType').value !== this.thirdPartyData.dataFromAPIResponse.iso360Value?.foundationType.code) {
        this.thirdPartyData.dataFromAPIResponse.iso360Value.foundationType.iso360ValueCode = this.iso360ValueMapping.defaultFoundationTypeMapping.find(m => m.uiCode === this.propertyData.propertyDetails.get('foundationType').value)?.iso360Code;
        this.thirdPartyData.dataFromAPIResponse.iso360Value.foundationType.code = this.propertyData.propertyDetails.get('foundationType').value;
      }
    }
  }

  onChangeRoofMaterialType(): void {
    if (this.thirdPartyData.dataFromAPIResponse !== undefined ||
      this.thirdPartyData.dataFromAPIResponse !== null) {
      if (this.propertyData.propertyDetails.get('roofMaterial').value !== this.thirdPartyData.dataFromAPIResponse.iso360Value?.roofMaterial.code) {
        this.thirdPartyData.dataFromAPIResponse.iso360Value.roofMaterial.iso360ValueCode = this.iso360ValueMapping.defaultRoofMaterialMapping.find(m => m.uiCode === this.propertyData.propertyDetails.get('roofMaterial').value)?.iso360Code;
        this.thirdPartyData.dataFromAPIResponse.iso360Value.roofMaterial.code = this.propertyData.propertyDetails.get('roofMaterial').value;
      }
    }

    if(this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3){
      this.propertyData.propertyDetails.get('isFlatTile').setValue(this.propertyData.propertyDetails.get('roofMaterial').value.indexOf('TI') === -1 ? false : this.propertyData.propertyDetails.get('isFlatTile').value);
      this.propertyBusinessRules.triggerActiveNew125(false, false, false, false, false, true);
    }
  }

  onChangeCentralAirConditioning(event?): void {
    const isCentralAirCondition = event.target.checked;
    const yearBuilt = this.PropertyDetailsForm.get('yearBuilt').value;

    if (!isCentralAirCondition) {
      this.propertyData.propertyDetails.get('yearCentralAirConditioning').setValue(null);
      this.PropertyDetailsForm.get('yearCentralAirConditioning').clearValidators();
      this.propertyData.validYearAircon = null;
      return;
    }

    this.propertyData.propertyDetails.get('yearCentralAirConditioning').setValue(yearBuilt);
    this.PropertyDetailsForm.get('yearCentralAirConditioning').setValidators([Validators.required, Validators.min(1800),
    PropertyValidators.yearShouldNotBeOlderThanYearBuiltValidator(yearBuilt),
    PropertyValidators.yearInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);
    this.propertyData.validYearAircon = this.propertyData.propertyDetails.get('yearCentralAirConditioning').value;
  }

  resetValidatorFormControl(): void {
    this.generalValidationService.resetValidatorFormControl(this.PropertyDetailsForm, 'foundationElevation', [Validators.required]);
  }

  checkInternalPolicyDropdownControls(): boolean {
    const status = !this.policySummaryData.isPolicy ? true :
      (this.policySummaryData.isEditPolicy && this.showInternal) ? false : true;
    return status;
  }

  propertyYearSetter(yearBuilt: number, formName: string): any {
    const field = this.propertyData?.propertyDetails?.get(formName)?.value;
    if (field) {
      if (this.propertyData.isYearBuiltQQChange) {
        return yearBuilt;
      } else {
        return field;
      }
    } else {
      return yearBuilt;
    }
  }

  onAirconYearChange(): void {
    const yearBuilt = this.PropertyDetailsForm.get('yearBuilt').value;
    const airconYearForm = this.propertyData.propertyDetails.get('yearCentralAirConditioning');
    this.propertyData.validYearAircon = airconYearForm.valid ? airconYearForm.value : this.propertyData.validYearAircon > yearBuilt ? this.propertyData.validYearAircon ?? yearBuilt : yearBuilt;
    this.propertyData.isYearAirconChange = true;
  }

  setValidPropertyValues(): void {
    Object.keys(this.PropertyDetailsForm.controls).forEach(key => {
      switch (key) {
        case PropertyYearFields.Built:
          if (!this.propertyData.propertyDetails.get(PropertyYearFields.Built).valid) {
            this.propertyData.propertyDetails.get(PropertyYearFields.Built).setValue(this.propertyData.validYearBuilt);
          }
          break;
        case PropertyYearFields.Roof:
          if (!this.propertyData.propertyDetails.get(PropertyYearFields.Roof).valid) {
            this.propertyData.propertyDetails.get(PropertyYearFields.Roof).setValue(this.propertyData.validYearRoof);
          }
          break;
        case PropertyYearFields.Electrical:
          if (!this.propertyData.propertyDetails.get(PropertyYearFields.Electrical).valid) {
            this.propertyData.propertyDetails.get(PropertyYearFields.Electrical).setValue(this.propertyData.validYearElectrical);
          }
          this.propertyData.validYearElectrical = this.propertyData.propertyDetails.get(PropertyYearFields.Electrical).value;
          break;
        case PropertyYearFields.Plumbing:
          if (!this.propertyData.propertyDetails.get(PropertyYearFields.Plumbing).valid) {
            this.propertyData.propertyDetails.get(PropertyYearFields.Plumbing).setValue(this.propertyData.validYearPlumbing);
          }
          this.propertyData.validYearPlumbing = this.propertyData.propertyDetails.get(PropertyYearFields.Plumbing).value;
          break;
        case PropertyYearFields.Heating:
          if (!this.propertyData.propertyDetails.get(PropertyYearFields.Heating).valid) {
            this.propertyData.propertyDetails.get(PropertyYearFields.Heating).setValue(this.propertyData.validYearHeating);
          }
          this.propertyData.validYearHeating = this.propertyData.propertyDetails.get(PropertyYearFields.Heating).value;
          break;
        case PropertyYearFields.Aircon:
          if (!this.propertyData.propertyDetails.get(PropertyYearFields.Aircon).valid) {
            this.propertyData.propertyDetails.get(PropertyYearFields.Aircon).setValue(this.propertyData.validYearAircon);
          }
          this.propertyData.validYearAircon = this.propertyData.propertyDetails.get(PropertyYearFields.Aircon).value;
          break;
        case PropertyDetailFields.FloorUnit:
          if (!this.propertyData.propertyDetails.get(PropertyDetailFields.FloorUnit).valid) {
            this.propertyData.propertyDetails.get(PropertyDetailFields.FloorUnit).setValue(this.propertyData.validFloorNumber);
          }
          this.propertyData.validFloorNumber = this.propertyData.propertyDetails.get(PropertyDetailFields.FloorUnit).value;
          break;
        default:
          break;
      }
    });
  }

  onDateChanged(date: any): void {
    if (date?.valid) {
      this.purchaseDate = date?.jsDate;
      this.purchaseDateYear = this.purchaseDate.getFullYear();
      return;
    }
  }

  onInputFieldChanged(date: any): void {
    if (date?.valid) {
      this.purchaseDate = new Date(date?.value);
      this.purchaseDateYear = this.purchaseDate.getFullYear();
      return;
    }
  }

  checkYearLength(value: string): boolean {
    const validYearLength: number = 4;
    return value?.toString()?.trim().length === validYearLength;
  }

  checkPurchaseDateValidityToEffectiveDate(): boolean {
    const effectiveDate = this.policySummaryData.isPolicy ?
      new Date(this.summaryData.SummaryForm.get('effectiveDate')?.value) :
      new Date(this.applicantData.mainApplicant.get('effectiveDate').value.singleDate?.jsDate?.toLocaleDateString('en-US'));

    return this.purchaseDate > effectiveDate;
  }

  ngOnDestroy(): void {
    this.setValidPropertyValues();
    this.propertyData.isYearBuiltQQChange = false;
    this.purchaseDate = null;
  }

  protected BCEGSBasedOnYearBuiltChanged(yearBuilt: number): void {
    if (yearBuilt?.toString()?.length === 4 && yearBuilt >= 1800){
      const address: IAddressForCounty = {};
      address.stateCode = this?.applicantData?.mainApplicant?.get('qqState')?.value;
      address.city = this?.applicantData?.mainApplicant?.get('qqCity')?.value;
      address.street1 = this?.applicantData?.mainApplicant?.get('addressLine1')?.value;
      address.street2 = this?.applicantData?.mainApplicant?.get('addressLine2')?.value;
      address.zip = this?.applicantData?.mainApplicant?.get('qqZipCode')?.value;
      Utils.blockUI();
      this.thirdPartyDataService.getBCEGSRecords(address).pipe(takeUntil(this.stop$)).subscribe(results => {
        Utils.unblockUI();
        this.bCEGSService.BCEGSMapperUsingYearBuilt(yearBuilt,results,this.propertyData.locationDetails);
      },error => {
        NotifUtils.showError('Something went wrong.');
      });
    }
  }

  checkPurchaseDateOptions(): void {
    const isExternal = this.userType.toLowerCase() === 'external' ? true: false;
    if (isExternal) {
      const effectiveDate = new Date(this.applicantData.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate?.toLocaleDateString('en-US'));
      this.propertyData.purchaseDateOption = {
        dateRange: false,
        dateFormat: 'mm/dd/yyyy',
        disableSince: { year: effectiveDate.getFullYear(), month: effectiveDate.getMonth() + 1, day: effectiveDate.getDate() + 1},
        disableUntil: { year: 0, month: 0, day: 0 }
      };
    } else {
      this.propertyData.purchaseDateOption = {
        dateRange: false,
        dateFormat: 'mm/dd/yyyy',
        disableSince: { year: 0, month: 0, day: 0 },
        disableUntil: { year: 0, month: 0, day: 0 }
      };
    }
  }

  onInputYearRoof(yearRoof: number): void {
    const isFromRenewal = this.entityRiskData.getRisk()?.isFromRenewal;
    if (Number(yearRoof) > 999) {
      this.propertyBusinessRules.triggerActiveNew125(false, false, false, false, true);
    }
  }
}
