import { Output, EventEmitter, HostListener } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { SubmissionListData } from 'app/modules/submission-management/data/submission-list.data';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from 'app/shared/base-class';
import { GenericLabel } from 'app/shared/constants/generic.labels.constants';
import { FormTypeConstants } from '../../../../../shared/constants/form-types.constants';
import { RefundLabelsConstants } from 'app/shared/constants/refund.labels.constants';
import { CurrencyMaskConstants } from 'app/shared/constants/currency-mask.constants';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { RefundData } from '../../../../../modules/payments/data/refund.data';
import { KEYUP } from 'angular-mydatepicker';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent extends BaseClass implements OnInit {

  public RefundLabels = RefundLabelsConstants;
  public CurrencyMaskConstants = CurrencyMaskConstants;
  public CurrencyMaskInputMode = CurrencyMaskInputMode;
  public genericLabel = GenericLabel;
  private searchQuery: string = '';
  private searchStatus: string = '';
  private searchSubStatus: string = '';
  public act: string = 'ACT';
  public formTypeConstants = FormTypeConstants;

  hasMarginRight: boolean = true;

  @Output() submitSearch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  constructor(
    public refundRequestData: RefundData,
    public location: Location,
    public router: Router,
    public route: ActivatedRoute) {
      super();
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.refundRequestData.filterList();
  }

  @HostListener('document:keypress', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
        this.onSubmit();
        event.preventDefault();
    }
}

}
