import { Component, Injectable } from '@angular/core';
import { BaseClass } from '../../../shared/base-class';
import {
  ITableTh,
  ITableTr,
  ITableFormControl,
  ITableTd,
} from '../../../shared/models/dynamic/table.interface';
import { ClaimsLabelsConstants } from '../../../shared/constants/claims.labels.constants';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { ClaimsSource, ClaimStatus, LossTypeList } from 'app/shared/constants/claims.options.constants';
import { EntityRiskDTO } from 'app/shared/models/data/dto/quick-quote/entity-risk.dto';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'app/core/services/auth.service';
import { RiskClaimDTO } from '../../../shared/models/data/dto/third-party/risk-claims.dto';
import { ClaimsModalModel } from '../../../shared/models/submission/claims/claims.model';
import { CurrencyPipe } from '@angular/common';
import * as _ from 'lodash';
import { GenericConstants } from '../../../shared/constants/generic.constants';
import * as moment from 'moment';
import { FormGroup } from '@angular/forms';
import { IPolicyCancellationDTO } from '../../../shared/models/data/dto/policy-management/policy-cancellation.dto';
import { BehaviorSubject } from 'rxjs';
import { IRiskClaimDTO } from 'app/shared/models/data/dto/quick-quote/risk-claim.dto';
import { Store } from '@ngrx/store';
import { updateClaimsListFromClaimsData } from 'app/store/claims/claims.actions';

@Injectable({
  providedIn: 'root',
})
export class ClaimsData extends BaseClass {
  disableOrderLossReport: boolean = true;

  LabelMessageConstant = ClaimsLabelsConstants;

  claimTypes: any = [];
  claimsSource: any[];
  claimStatus: any[];
  isExternal: boolean;

  claimsDateOption: IAngularMyDpOptions;

  public riskId: any;
  public claimList: ClaimsModalModel[] = [];
  public ClaimsLabel = ClaimsLabelsConstants;
  public genericConstants = GenericConstants;
  public claimsConstants = GenericConstants.claimsDefault;

  isClaimListLoading: boolean = false;
  stateTimingData: IPolicyCancellationDTO;
  public claimsSavedData$: BehaviorSubject<IRiskClaimDTO | null> = new BehaviorSubject<IRiskClaimDTO | null>(null);
  isAddingClaim: boolean = false;

  constructor(
    protected authService: AuthService,
    protected currency: CurrencyPipe,
    protected store: Store
  ) {
    super();
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.isExternal = this.isExternalUser(userType);
    });
  }

  public isExternalUser(userType: string): boolean {
    return userType?.toLocaleLowerCase() === this.genericConstants.userType.external;
  }

  tableHeaders: ITableTh[] = [
    { value: this.LabelMessageConstant.lossDate},
    { value: this.LabelMessageConstant.claimType },
    { value: this.LabelMessageConstant.additionalDescription },
    { value: this.LabelMessageConstant.catCode },
    { value: this.LabelMessageConstant.claimAmount },
    { value: this.LabelMessageConstant.claimChargeableVsNonChargeable },
    { value: this.LabelMessageConstant.claimStatus },
    { value: this.LabelMessageConstant.policyType },
    { value: this.LabelMessageConstant.dispute },
    { value: this.LabelMessageConstant.claimSource },
    { value: this.LabelMessageConstant.claimNumber },
    { value: this.LabelMessageConstant.individualMatchType },
    { value: this.LabelMessageConstant.totalOutstandingReserve },
    { value: this.LabelMessageConstant.totalRecoveriesAndReimbursement },
    { value: this.LabelMessageConstant.pcsCode },
  ];

  claimsTableRows: ITableTr[] = [];

  tableFormControls: ITableFormControl[] = [
    {
      name: 'field_1',
      type: 'string',
    },
    {
      name: 'field_2',
      type: 'string',
    },
    {
      name: 'field_3',
      type: 'string',
    },
    {
      name: 'field_4',
      type: 'string',
    },
    {
      name: 'field_5',
      type: 'string',
    },
    {
      name: 'field_6',
      type: 'string',
    },
    {
      name: 'field_7',
      type: 'string',
    },
    {
      name: 'field_8',
      type: 'string',
    },
    {
      name: 'field_9',
      type: 'string',
    },
    {
      name: 'field_10',
      type: 'string',
    },
    {
      name: 'field_11',
      type: 'string',
    },
    {
      name: 'field_12',
      type: 'string',
     },
    {
      name: 'field_13',
      type: 'string',
    },
    {
      name: 'field_14',
      type: 'string',
    },
    {
      name: 'field_15',
      type: 'string',
    },
  ];

  public isClaimsForSaving: boolean = false;
  public riskClaims: RiskClaimDTO[] = [];

  initForms() {
    this.isClaimsForSaving = false;
    this.dropdownValues();
    this.dateOptions();
  }

  dropdownValues() {
    this.claimTypes = LossTypeList;
    this.claimStatus = ClaimStatus;
    this.setClaimSourceDropdown();
  }

  dateOptions() {
    this.claimsDateOption = {
      dateRange: false,
      dateFormat: this.claimsConstants.dateFormat,
      disableUntil: {
        year: 1799,
        month: 12,
        day: 31,
      },
      disableSince: {
        year: (this.authService.getCustomDate()).getFullYear(),
        month: (this.authService.getCustomDate()).getMonth() + 1,
        day: (this.authService.getCustomDate()).getDate() + 1
      }
    };
  }

  public populateClaimsData(result: RiskClaimDTO[], effectiveDate: Date = null, endorsementForm: FormGroup = null) {
    this.clearPreviousClaimsData();
    this.store.dispatch(updateClaimsListFromClaimsData({ list: result }));

    if (result.length > 0) {
      const sortedResult = _.orderBy(result, ['lossDate'], ['desc']);

      _.map(sortedResult, (item) => {
        const lossDate = new Date(item.lossDate);

        const claims: ClaimsModalModel = {
          claimChargeableVsNonChargeable: item.chargeableNonChargable,
          lossDate: {
            singleDate: {
              date: {
                year: lossDate.getFullYear(),
                month: (lossDate.getMonth() + 1),
                day: lossDate.getDate()
              },
              formatted: String(moment(lossDate).format('MM/DD/YYYY')),
              jsDate: lossDate
            }
          },
          additionalDescription: item.description ? item.description : this.claimsConstants.noDescription,
          claimSource: ClaimsSource.find(source => source.description === item.claimSource || source.code === item.claimSource).code,
          claimType: item?.claimType ? LossTypeList.find((lossType) => lossType.description === item.claimType || lossType.code === item.claimType).code : '',
          notes: item.notes ? item.notes : '',
          claimAmount: item.amount,
          otherQuestions: item.otherQuestion ? item.otherQuestion : '',
          id: item.id,
          claimStatus: ClaimStatus.find((status) => status.description === item.claimStatus || status.code === item.claimStatus).code,
          catCode: item.catCode ? item.catCode : this.claimsConstants.noCATCode,
          policyType: item.policyType,
          dispute: item.isDispute ? true : false,
          claimNumber: item.claimNumber,
          individualMatchType: item.individualMatchType,
          isAPlus: ClaimsSource.find(source => source.description === item.claimSource || source.code === item.claimSource).code === ClaimsSource[0].code ? true : false,
          isFileHandler: ClaimsSource.find(source => source.description === item.claimSource || source.code === item.claimSource).code === ClaimsSource[3].code ? true : false,
          isFromParent: item.isFromParent,
          isUnder24Hrs: item.isUnder24Hrs,
          riskDetailId: item.riskDetailId,
          createdDate: item.createdDate,
          isAdd: false,
          isComparative: item.isComparative,
          isLossAssociatedToHome: item.isLossAssociatedToHome  ? true : false,
          isLossAssociatedToInsured: item.isLossAssociatedToInsured  ? true : false,
          totalOutstandingReserve: item.totalOutstandingReserve,
          totalRecoveriesAndReimbursement: item.totalRecoveriesAndReimbursement,
          pcsCode: item.pcsCode,
          isExternal: item.isExternal
        };

        this.claimList.push(claims);
        this.addTableItem(claims);
      });

      this.mapClaimListAndSetIndexID();
    }
  }

  public mapClaimListAndSetIndexID() {
    this.claimsTableRows.forEach((item, index) => {
      item.id = item.id ? item.id : null;
      this.claimList[index].id = item.id ? item.id : null;
    });
  }

  public addTableItem(newItem: any): void {
    const tr: ITableTd[] = [];
    const fields: string[] = [
      this.claimsConstants.fields.lossDate,
      this.claimsConstants.fields.claimType,
      this.claimsConstants.fields.additionalDescription,
      this.claimsConstants.fields.catCode,
      this.claimsConstants.fields.claimAmount,
      this.claimsConstants.fields.claimChargeableVsNonChargeable,
      this.claimsConstants.fields.claimStatus,
      this.claimsConstants.fields.policyType,
      this.claimsConstants.fields.dispute,
      this.claimsConstants.fields.claimSource,
      this.claimsConstants.fields.claimNumber,
      this.claimsConstants.fields.individualMatchType,
      this.claimsConstants.fields.totalOutstandingReserve,
      this.claimsConstants.fields.totalRecoveriesAndReimbursement,
      this.claimsConstants.fields.pcsCode
    ];
    let rowAPlus: string = '';
    let createdDate: Date;

    fields.forEach((item, index) => {
      let display: any;

      switch (item) {
        case this.claimsConstants.fields.lossDate:
          display = newItem[item]?.singleDate?.formatted;
          break;
        case this.claimsConstants.fields.claimAmount:
          display = Boolean(newItem[item]) ? this.transformCurrencyValue(newItem[item]) : this.transformCurrencyValue(0);
          break;
        case this.claimsConstants.fields.dispute:
        case this.claimsConstants.fields.claimChargeableVsNonChargeable:
          display = Boolean(newItem[item]) ? this.claimsConstants.isChargeable : this.claimsConstants.notChargeable;
          break;
        case this.claimsConstants.fields.claimType:
          display = LossTypeList.find((claim) => claim.code === newItem[item])?.description;
          break;
        case this.claimsConstants.fields.claimStatus:
          display = ClaimStatus.find(status => status.code === newItem[item])?.description;
          break;
        case this.claimsConstants.fields.claimSource:
          display = ClaimsSource.find(source => source.code === newItem[item] || source.code === newItem[item])?.description;
          rowAPlus = display;
          createdDate = newItem[this.claimsConstants.fields.createdDate];
          break;
        case this.claimsConstants.fields.claimNumber:
          display = newItem[item];
          break;
        case this.claimsConstants.fields.individualMatchType:
          display = newItem[item];
          break;
        case this.claimsConstants.fields.totalOutstandingReserve:
          display = newItem[item];
          break;
        case this.claimsConstants.fields.totalRecoveriesAndReimbursement:
          display = newItem[item];
          break;
        case this.claimsConstants.fields.pcsCode:
          display = newItem[item];
          break;
        default:
          display = Boolean(newItem[item]) ? String(newItem[item]) : '';
          break;
      }

      tr.push({
        id: newItem.id,
        value: Boolean(newItem[item]) ? newItem[item] : item === this.claimsConstants.fields.claimAmount ? 0 : '',
        display: display
      });
    });

    const claimSourceIndex = this.tableHeaders.findIndex(x => x.value === this.ClaimsLabel.claimSource);
    const claimNumberIndex = this.tableHeaders.findIndex(x => x.value === this.ClaimsLabel.claimNumber);
    const individualMatchTypeIndex = this.tableHeaders.findIndex(x => x.value === this.ClaimsLabel.individualMatchType);
    const totalOutstandingReserveIndex = this.tableHeaders.findIndex(x => x.value === this.ClaimsLabel.totalOutstandingReserve);
    const totalRecoveriesAndReimbursementIndex = this.tableHeaders.findIndex(x => x.value === this.ClaimsLabel.totalRecoveriesAndReimbursement);
    const pcsCodeIndex = this.tableHeaders.findIndex(x => x.value === this.ClaimsLabel.pcsCode);

    if (this.isExternal) {
      tr.splice(pcsCodeIndex, 1);
      tr.splice(totalRecoveriesAndReimbursementIndex, 1);
      tr.splice(totalOutstandingReserveIndex, 1);
      tr.splice(individualMatchTypeIndex, 1);
      tr.splice(claimNumberIndex, 1);
      tr.splice(claimSourceIndex, 1);
    }

    this.claimsTableRows.push({
      id: newItem.id,
      tr: tr,
      isAPlus: rowAPlus === ClaimsSource[0].description ? true : false,
      isFileHandler: rowAPlus === ClaimsSource[3].description ? true : false,
      isFromParent: newItem.isFromParent,
      isUnder24Hrs: newItem.isUnder24Hrs,
      riskDetailId: newItem.riskDetailId ?? '',
      isComparative: newItem.isComparative,
      isLossAssociatedToHome: newItem.isLossAssociatedToHome,
      isLossAssociatedToInsured: newItem.isLossAssociatedToInsured,
      totalOutstandingReserve: newItem.totalOutstandingReserve,
      totalRecoveriesAndReimbursement: newItem.totalRecoveriesAndReimbursement,
      pcsCode: newItem.pcsCode,
      claimSource: rowAPlus,
      createdDate: createdDate
    });
  }

  public clearPreviousClaimsData(): void {
    this.claimList = [];
    this.claimsTableRows = [];
  }

  public hasClaimsForPastThreeYears(lossDate: any, effectiveDate: any = null, isCheckingStarPackage: boolean = true): boolean {
    const dateForComputation = isCheckingStarPackage ? this.authService.getCustomDate() : effectiveDate;
    const dateRef = isCheckingStarPackage ? new Date(lossDate.singleDate?.jsDate) : new Date(lossDate);
    const yearDiff: number = dateForComputation.getFullYear() - dateRef.getFullYear();
    const monthDiff: number = dateForComputation.getMonth() - dateRef.getMonth();
    const dayDiff: number = dateForComputation.getDate() - dateRef.getDate();
    if (yearDiff < 3 ||
      (yearDiff === 3 && monthDiff < 0) ||
      (yearDiff === 3 && monthDiff === 0 && dayDiff <= 0)) {
      return true;
    } else {
      return false;
    }
  }

  public checkIsActOfGod(claimType: string): boolean {
    return LossTypeList.find((lossType) => lossType.code === claimType).actOfGod;
  }

  transformCurrencyValue(amount: number): string {
    return isNaN(amount) ? '' : String(this.currency.transform(amount, 'USD', 'symbol', '1.0'));
  }

  checkAPlusChargeableExceptionRule(claim: any, currentChargeableValue: boolean, lossDate: Date, effectiveDate: Date): boolean {
    if (currentChargeableValue) {
      if (claim?.catCode?.toUpperCase() === 'Y') {
        return false;
      } else if (this.hasClaimsForPastThreeYears(lossDate, effectiveDate, false)) {
        return currentChargeableValue;
      } else {
        return false;
      }
    } else {
      return currentChargeableValue;
    }
  }

  sortPolicyClaimsList(claimsList: any): void {
    const claimsData: RiskClaimDTO[] = [];
    this.clearPreviousClaimsData();

    _.map(claimsList, (item: ClaimsModalModel) => {
      const data: RiskClaimDTO = {
        id: item.id,
        riskDetailId: item.riskDetailId,
        description: item.additionalDescription,
        claimType: item.claimType,
        amount: item.claimAmount,
        notes: item.notes,
        claimSource: item.claimSource,
        chargeableNonChargable: item.claimChargeableVsNonChargeable,
        otherQuestion: item.otherQuestions,
        lossDate: new Date (item.lossDate.singleDate?.formatted),
        catCode: item.catCode,
        claimStatus: item.claimStatus,
        policyType: item.policyType,
        isDispute: item.dispute,
        claimNumber: item.claimNumber,
        individualMatchType: item.individualMatchType,
        isAPlus: item.isAPlus,
        isFileHandler: item.isFileHandler,
        isComparative: item.isComparative,
        isFromParent: item.isFromParent ? true : false,
        isUnder24Hrs: item.isUnder24Hrs ? true : false,
        createdDate: item.createdDate,
        isAdd: item.isAdd,
        isLossAssociatedToHome: item.isLossAssociatedToHome  ? true : false,
        isLossAssociatedToInsured: item.isLossAssociatedToInsured  ? true : false,
        totalOutstandingReserve: item.totalOutstandingReserve,
        totalRecoveriesAndReimbursement: item.totalRecoveriesAndReimbursement,
        pcsCode: item.pcsCode
      };

      claimsData.push(data);
    });

    const sortedResult = _.orderBy(claimsData, ['lossDate'], ['desc']);

    _.map(sortedResult, (item) => {
      const lossDate = new Date(item.lossDate);

      const claims: ClaimsModalModel = {
        claimChargeableVsNonChargeable: item.chargeableNonChargable,
        lossDate: {
          singleDate: {
            date: {
              year: lossDate.getFullYear(),
              month: (lossDate.getMonth() + 1),
              day: lossDate.getDate()
            },
            formatted: String(moment(lossDate).format('MM/DD/YYYY')),
            jsDate: lossDate
          }
        },
        additionalDescription: item.description ? item.description : this.claimsConstants.noDescription,
        claimSource: ClaimsSource.find(source => source.description === item.claimSource || source.code === item.claimSource).code,
        claimType: item?.claimType ? LossTypeList.find((lossType) => lossType.description === item.claimType || lossType.code === item.claimType).code : '',
        notes: item.notes ? item.notes : '',
        claimAmount: item.amount,
        otherQuestions: item.otherQuestion ? item.otherQuestion : '',
        id: item.id,
        claimStatus: ClaimStatus.find((status) => status.description === item.claimStatus || status.code === item.claimStatus).code,
        catCode: item.catCode ? item.catCode : this.claimsConstants.noCATCode,
        policyType: item.policyType,
        dispute: item.isDispute ? true : false,
        claimNumber: item.claimNumber,
        individualMatchType: item.individualMatchType,
        isAPlus: ClaimsSource.find(source => source.description === item.claimSource || source.code === item.claimSource).code === ClaimsSource[0].code ? true : false,
        isFileHandler: ClaimsSource.find(source => source.description === item.claimSource || source.code === item.claimSource).code === ClaimsSource[3].code ? true : false,
        isFromParent: item.isFromParent,
        isUnder24Hrs: item.isUnder24Hrs,
        riskDetailId: item.riskDetailId,
        createdDate: item.createdDate,
        isAdd: item.isAdd,
        isComparative: item.isComparative,
        isLossAssociatedToHome: item.isLossAssociatedToHome  ? true : false,
        isLossAssociatedToInsured: item.isLossAssociatedToInsured  ? true : false,
        totalOutstandingReserve: item.totalOutstandingReserve,
        totalRecoveriesAndReimbursement: item.totalRecoveriesAndReimbursement,
        pcsCode: item.pcsCode
      };

      this.claimList.push(claims);
      this.addTableItem(claims);
    });

    this.mapClaimListAndSetIndexID();
  }

  setClaimSourceDropdown(currentSource: string = '') {
    if (currentSource === ClaimsLabelsConstants.claimSourceCodes.aPlus) {
      this.claimsSource = ClaimsSource.filter(source => source.code === ClaimsLabelsConstants.claimSourceCodes.aPlus || source.code === ClaimsLabelsConstants.claimSourceCodes.agent || source.code === ClaimsLabelsConstants.claimSourceCodes.centauri);
    } else if (currentSource === ClaimsLabelsConstants.claimSourceCodes.fileHandler) {
      this.claimsSource = ClaimsSource.filter(source => source.code === ClaimsLabelsConstants.claimSourceCodes.fileHandler || source.code === ClaimsLabelsConstants.claimSourceCodes.agent || source.code === ClaimsLabelsConstants.claimSourceCodes.centauri);
    } else {
      this.claimsSource = ClaimsSource.filter(source => source.code === ClaimsLabelsConstants.claimSourceCodes.agent || source.code === ClaimsLabelsConstants.claimSourceCodes.centauri);
    }
  }
}
