import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import {
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
  AppAsideModule,
  AppBreadcrumbModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { NgxSpinnerModule } from 'ngx-spinner';

import { CoreModule } from './core/core.module';
import { StorageService } from './core/services/storage.service';
import { ToastrModule } from 'ngx-toastr';
import { AuthComponent } from './auth/auth.component';
import { SubmissionManagementModule } from './modules/submission-management/submission-management.module';
import { FooterComponent } from './layout/components/footer/footer.component';
import { HeaderComponent } from './layout/components/header/header.component';
import { LeftSidebarComponent } from './layout/components/left-sidebar/left-sidebar.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { RightSidebarComponent } from './layout/components/right-sidebar/right-sidebar.component';
import { MainSidebarLayoutComponent } from './layout/main-sidebar-layout/main-sidebar-layout.component';
import { UserLayoutComponent } from './layout/user-layout/user-layout.component';
import { PagerService } from './core/services/pagerService';
import { P404Component } from './shared/components/error/404.component';
import { LocalStorageService } from './core/services/local-storage.service';
import { PolicyManagementModule } from './modules/policy-management/policy-management.module';
import { PaymentsModule } from './modules/payments/payments.module';
import { NightlyJobTestComponent } from './modules/nightly-job-test/nightly-job-test.component';
import { LoginComponent } from './modules/login/login.component';
import { ManagementModule } from './modules/management/management.module';
import { environment } from 'environments/environment';
import { ComparativeRaterModule } from './modules/comparative-rater/comparative-rater.module';
import { ReportsModule } from './modules/reports/reports.module';
import { TooltipModule } from 'ngx-bootstrap';
import { CustomPipesModule } from './shared/custom pipes/custom-pipe.module';
import { MakeAPaymentComponent } from './modules/make-a-payment/make-a-payment.component';
import { TestMakeAPaymentComponent } from './modules/make-a-payment/test-make-a-payment/test-make-a-payment.component';
import { BookTransferModule } from './modules/book-transfer/book-transfer.module';
import { baseReducer } from './store/base.reducer';
import { RaterMigrationComponent } from './modules/rater-migration/rater-migration.component';
import { AccessDenied001Component } from './views/error/access-denied-001.component';
import { LA_PropertyUsageLogicPipe } from './states/la/shared/pipes/la-property-usage.pipe';
import { LA_PersonalPropertyReplacementCostPipe } from './states/la/shared/pipes/la-personal-property-replacement-cost.pipe';
import { LA_FortifiedForSaferHomePipe } from './states/la/shared/pipes/la-fortified-for-safer-home.pipe';
import { SessionTimeoutComponent } from './modules/login/session-timeout/session-timeout.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment';
import { TimeoutModalComponent } from './modules/login/timeout-modal/timeout-modal.component';
import { AccessComponent } from './modules/access/access.component';
import { LvService } from './shared/services/lv-service';


@NgModule({
  imports: [
    CoreModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    SubmissionManagementModule,
    PolicyManagementModule,
    ManagementModule,
    BookTransferModule,
    PaymentsModule,
    StoreModule.forRoot(baseReducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    ComparativeRaterModule,
    CustomPipesModule,
    ReportsModule,
    TooltipModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
  ],
  declarations: [
    AppComponent,
    FooterComponent,
    MainLayoutComponent,
    RightSidebarComponent,
    HeaderComponent,
    LeftSidebarComponent,
    MainSidebarLayoutComponent,
    UserLayoutComponent,
    AuthComponent,
    P404Component,
    NightlyJobTestComponent,
    LoginComponent,
    MakeAPaymentComponent,
    TestMakeAPaymentComponent,
    RaterMigrationComponent,
    AccessDenied001Component,
    SessionTimeoutComponent,
    TimeoutModalComponent,
    AccessComponent
  ],
  providers: [
    StorageService,
    PagerService,
    LocalStorageService,
    LA_PropertyUsageLogicPipe,
    LA_PersonalPropertyReplacementCostPipe,
    LA_FortifiedForSaferHomePipe,
    LvService
  ],
  bootstrap: [AppComponent],
  entryComponents: [SessionTimeoutComponent,TimeoutModalComponent]
})
export class AppModule { }
