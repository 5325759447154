import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  ITableTh,
  ITableTr,
  ITableFormControl,
  IHideTableItems,
  IDisableTableItems
} from '../../../models/dynamic/table.interface';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import NotifUtils from '../../../../shared/utilities/notif-utils';
import { TableNameConstants } from '../../../../shared/constants/table.name.constants';
import * as _ from 'lodash';
import Utils from '../../../../shared/utilities/utils';
import { environment } from '../../../../../environments/environment';
import { UWApprovalPageLabel } from '../../../../shared/constants/uw-approval.labels.constants';
import { TableConstants } from '../../../../shared/constants/table.constants';
import { BaseClass } from '../../../../shared/base-class';
import { AuthService } from '../../../../core/services/auth.service';
import * as moment from 'moment';
import { ClaimsSource } from 'app/shared/constants/claims.options.constants';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent extends BaseClass implements OnInit, AfterViewInit, OnChanges {
  TableNameConstants = TableNameConstants;
  addNewItem: boolean = false;
  editItem: boolean = false;
  tableItemGroup: FormGroup;
  eneableHorizontalScroll: boolean = false;
  rowId: any;
  UWApprovalLabel = UWApprovalPageLabel;
  TableConstants = TableConstants;

  // Table name class specifier.
  isUWApprovalTable = false;
  isScheduleTable = false;
  isInterestTable = false;
  isBindAndIssueDocumentTable = false;
  isBindAndIssueNotesTable = false;
  isClaimsTable = false;
  isPolicyDocumentsTable = false;

  tablePage = true;
  @Input() tableName: string;
  @Input() tableHeaders: ITableTh[] = [];
  @Input() tableRows: ITableTr[] = [];
  @Input() formControls: ITableFormControl[] = [];
  @Input() customAddEvent: boolean = false;
  @Input() customEditEvent: boolean = false;
  @Input() customDeleteEvent: boolean = false;
  @Input() customAdditionalHeader: boolean = false;
  @Input() messageIfNoData: string = 'No Data To Display';
  @Input() hideItems: IHideTableItems = {
    addButton: false,
    updateButton: false,
    saveButton: false,
    cancelButton: false,
    controlButtons: false,
    updateIcon: false,
    deleteIcon: false,
    fileIcon: true,
    checkboxButton: true,
    viewIcon: true
  };
  @Input() disableItems: IDisableTableItems = {
    addButton: false,
    updateButton: false,
    saveButton: false,
    cancelButton: false,
    controlButtons: false,
    updateIcon: false,
    deleteIcon: false,
    fileIcon: false,
    checkboxButton: false
  };
  @Input() showFileIcon: boolean = false;
  @Input() enableEdit: boolean = false;
  @Input() customViewTooltip: string = null;
  @Input() isDataLoading: boolean = false;
  @Output() addTableItem = new EventEmitter<any>();
  @Output() editTableItem = new EventEmitter<any>();
  @Output() updateTableItem = new EventEmitter<any>();
  @Output() deleteTableItem = new EventEmitter<any>();
  @Output() customAddTableItem = new EventEmitter<any>();
  @Output() customEditTableItem = new EventEmitter<any>();
  @Output() customDeleteTableItem = new EventEmitter<any>();
  @Output() checkFile = new EventEmitter<any>();
  @Output() selectedTableItems = new EventEmitter<any>();
  @Output() changes = new EventEmitter<any>();
  @Output() viewItem = new EventEmitter<any>();

  @ViewChild('tableWrapper') tableWrapper: ElementRef;
  @ViewChild('table') table: ElementRef;

  constructor(protected formBuilder: FormBuilder, public auth: AuthService) {
    super();
  }

  ngOnInit() {
    this.tableItemGroup = this.formBuilder.group({});
    this.checkTableClassName();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.changes.emit(changes);
  }

  ngAfterViewInit() {
    this.checkTableOverFlow();
  }

  checkTableOverFlow(): void {
    if (this.tableRows && this.tableRows.length) {
      if (
        this.table.nativeElement.offsetWidth >
        this.tableWrapper.nativeElement.offsetWidth
      ) {
        setTimeout(() => {
          this.eneableHorizontalScroll = true;
        }, 1);
      } else {
        this.eneableHorizontalScroll = false;
      }
    }
  }

  initTableItemGroup(): void {
    if (this.formControls && this.formControls.length) {
      this.formControls.forEach((item, index) => {
        this.tableItemGroup.addControl(item.name, new FormControl(null, []));
      });
    }
  }

  addDataType(item: Object): 'number' | 'string' {
    if (typeof item === 'number') {
      return 'number';
    } else {
      return 'string';
    }
  }

  onCreateNewItem(): void {
    if (this.formControls.length !== 0) {
      if (this.customAddEvent === true) {
        this.customAddTableItem.emit();
      } else {
        this.addNewItem = true;
        this.initTableItemGroup();
      }
    } else {
      throw new Error(
        'formControls property has no values. Please pass data from parent component.'
      );
    }
  }

  onSubmitItem(): void {
    if (
      this.addNewItem &&
      !this.tableItemGroup.pristine &&
      this.checkProperties(this.tableItemGroup.value)
    ) {
      this.addTableItem.emit({ ...this.tableItemGroup });
    } else {
      this.updateTableItem.emit({
        form: { ...this.tableItemGroup },
        rowId: this.rowId,
      });
    }

    this.resetValues();
  }

  checkProperties(obj: any): boolean {
    const propertyValues: any = [];

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        propertyValues.push(obj[key]);
      }
    }

    return propertyValues.every((item: any) => Boolean(item));
  }

  onCancel(value: any): void {
    if (value) {
      NotifUtils.showConfirmMessage(
        'You have unsaved changes that will be lost',
        () => {
          this.resetValues();
        },
        () => { }
      );
    }
  }

  onDelete(rowId: any): void {
    let itemToDelete: any;
    let cancelDelete: boolean;

    this.resetValues();
    this.tableRows.forEach((row) => {
      if (rowId === row.id) {
        if (row.isAPlus || this.checkPolicyNotesActionButtonsDisabled(row) || this.isNotMiscellaneousApprovalDescription(row)) {
          cancelDelete = true;
        } else if (this.customDeleteEvent === true) {
          this.customDeleteTableItem.emit(rowId);
          cancelDelete = true;
        } else {
          cancelDelete = false;
          itemToDelete = this.getItemToDeleteDescription(row);
        }
      }
    });

    if (!cancelDelete) {
      if (rowId) {
        let deleteConfirmationMessage = 'Are you sure you want to delete';
        if (this.customAdditionalHeader) {
          deleteConfirmationMessage = `${deleteConfirmationMessage} this Transfer Batch?`;
        } else {
          deleteConfirmationMessage = itemToDelete === '' ? `${deleteConfirmationMessage}${itemToDelete}?`
            : `${deleteConfirmationMessage} '${itemToDelete}'?`;
        }
        NotifUtils.showConfirmMessage(deleteConfirmationMessage, () => {
            this.deleteTableItem.emit(rowId);
          },
          () => { }
        );
      }
    }
  }

  onEdit(rowId: any): void {
    const itemToEdit = _.find(this.tableRows, row => row.id === rowId);
    if (itemToEdit && this.checkPolicyNotesActionButtonsDisabled(itemToEdit)) {
      return;
    }

    if (!this.customEditEvent === true) {
      this.editTableItem.emit(rowId);
      this.rowId = rowId;
      this.resetValues();
      this.tableItemGroup = this.formBuilder.group({});
      this.tableRows.forEach((row) => {
        if (row.id === rowId) {
          row.tr.forEach((td, tdIndex) => {
            this.tableItemGroup.addControl(
              this.formControls[tdIndex].name,
              new FormControl(td.value, [])
            );
            td.type = this.formControls[tdIndex].type;
            td.formControlName = this.formControls[tdIndex].name;
          });
        }
      });
      this.editItem = true;
    } else {
      this.customEditTableItem.emit(rowId);
    }
  }

  onCheckFile(rowId: any): void {
    this.checkFile.emit(rowId);
  }

  resetValues(): void {
    this.addNewItem = false;
    this.editItem = false;
    this.tableItemGroup.reset();
  }

  checkTableClassName() {
    if (this.tableName) {
      switch (this.tableName) {
        case (this.TableNameConstants.uwApprovalTable):
          this.isUWApprovalTable = true;
          break;
        case (this.TableNameConstants.scheduleTable):
          this.isScheduleTable = true;
          break;
        case (this.TableNameConstants.interestTable):
          this.isInterestTable = true;
          break;
        case (this.TableNameConstants.bindAndIssueDocumentTable):
          this.isBindAndIssueDocumentTable = true;
          break;
        case (this.TableNameConstants.bindAndIssueNotesTable):
          this.isBindAndIssueNotesTable = true;
          break;
        case (this.TableNameConstants.claimsTable):
          this.isClaimsTable = true;
          break;
        case (this.TableNameConstants.policyDocumentsTable):
          this.isPolicyDocumentsTable = true;
          break;
      }
    }
  }

  resetTableClass() {
    this.isBindAndIssueDocumentTable = false;
    this.isBindAndIssueNotesTable = false;
    this.isClaimsTable = false;
    this.isInterestTable = false;
    this.isScheduleTable = false;
    this.isUWApprovalTable = false;
    this.isPolicyDocumentsTable = false;
  }

  checkAllCheckBox(event) {
    this.tableRows.forEach(x => x.checked = event.target.checked);
    this.selectedTableItems.emit(this.tableRows.filter(x => x.checked));
  }

  onChecked(event: any, index: any): void {
    this.tableRows[index].checked = event.target.checked;
    this.selectedTableItems.emit(this.tableRows.filter(x => x.checked));
  }

  isAllCheckBoxChecked(): boolean {
    if (this.tableRows.length < 1) {
      return false;
    }
    return this.tableRows.every(i => i.checked);
  }

  getItemToDeleteDescription(row: any): string {
    return this.tableName === TableNameConstants.claimsTable || TableNameConstants.userTypeTable ? row.tr[1].display :
      this.tableName === TableNameConstants.policyDocumentsTable ? row.tr[2].display : row.tr[0].value;
  }

  checkPolicyNotesActionButtonsDisabled(row: any): boolean {
    const currentDate = this.auth.getCustomDate();
    const createdDate = new Date(row?.tr[2]?.value).toString().toLowerCase() === 'invalid date' ?
      new Date(row?.tr[2]?.value?.singleDate?.jsDate) :
      new Date(row?.tr[2]?.value);

    let is24HrsOld = false;

    if (currentDate.getTime() > createdDate.getTime()) {
      is24HrsOld = ((currentDate.getTime() - createdDate.getTime()) / 3600000) > 24;
    }

    return (TableNameConstants.policyNotesTable === this.tableName ||
              TableNameConstants.bindAndIssueNotesTable === this.tableName ||
              TableNameConstants.bindAndIssueDocumentTable === this.tableName) &&
          (is24HrsOld || (Number(row?.createdBy) !== Number(localStorage.getItem(environment.UserIdentifierKey))) || row?.isFromParent);
  }

  isNotMiscellaneousApprovalDescription(row: any): boolean {
    return TableNameConstants.uwApprovalTable === this.tableName &&
      (row?.tr[0]?.display.toLocaleLowerCase() !== this.UWApprovalLabel.miscellaneous.toLocaleLowerCase());
  }

  checkPolicyDocumentsButtonsDisabled(row: any): boolean {
    return (TableNameConstants.policyDocumentsTable === this.tableName && row?.isFromParent)
      || Number(row?.createdBy) === TableConstants.SystemGenerated;
  }

  checkPolicyClaimsButtonsDisabled(row: any): boolean {
    return TableNameConstants.claimsTable === this.tableName && (row?.isFromParent || (row?.isUnder24Hrs === false));
  }

  checkPolicyInterestsDeleteButtonDisabled(row: any): boolean {
    return TableNameConstants.interestTable === this.tableName && row?.item.isFromParent;
  }

  checkIfSubmissionDocumentsButtonsDisabled(row: any): boolean {
    return TableNameConstants.bindAndIssueDocumentTable === this.tableName
      && Number(row?.createdBy) === TableConstants.SystemGenerated;
  }

  checkIfClaimSourceIsInternal(row: any): boolean {
    let isExternal = false;
    const claimSource = ClaimsSource.find(source => source.description === row?.claimSource)?.code;
    const isAgentClaimSource = claimSource === 'CS1';
    const isUWClaimSource = claimSource === 'CS2';
    const uWClaimSource = ['CS0', 'CS3'];
    const dateToday = moment(this.auth.getCustomDate()).format('YYYY-MM-DDT00:00:00.000');
    const createdDate = moment(new Date(row?.createdDate)).format('YYYY-MM-DDT00:00:00.000'); // UTC foprmat
    const sameDate = moment(dateToday).isSame(createdDate);

    this.auth.userType.subscribe(user => {
      isExternal = user === 'external';
    });

    if (isExternal) {
      return TableNameConstants.claimsTable === this.tableName && ((isAgentClaimSource && !sameDate) || isUWClaimSource);
    } else {
      return TableNameConstants.claimsTable === this.tableName && uWClaimSource.includes(claimSource);
    }
  }

  disabledDeleteButton(tableRow: any): boolean {
    return tableRow.isAPlus ||
    tableRow.isFileHandler
    || this.checkPolicyNotesActionButtonsDisabled(tableRow)
    || this.checkPolicyDocumentsButtonsDisabled(tableRow)
    || this.isNotMiscellaneousApprovalDescription(tableRow)
    || this.checkIfSubmissionDocumentsButtonsDisabled(tableRow)
    || this.checkIfClaimSourceIsInternal(tableRow);
  }

  disabledEditButton(tableRow: any): boolean {
    return this.checkPolicyNotesActionButtonsDisabled(tableRow)
    || this.checkPolicyDocumentsButtonsDisabled(tableRow)
    || this.checkIfSubmissionDocumentsButtonsDisabled(tableRow);
  }

  checkIfIssued(savedRiskDetailId): boolean {
    return (savedRiskDetailId !== '' && savedRiskDetailId !== localStorage.getItem('riskDetailId')) ? true : false;
  }

  onView(rowId: any): void {
    this.viewItem.emit(rowId);
  }
}
