import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PathConstants } from './shared/constants/path.constants';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthComponent } from './auth/auth.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { MainSidebarLayoutComponent } from './layout/main-sidebar-layout/main-sidebar-layout.component';
import { UserLayoutComponent } from './layout/user-layout/user-layout.component';
import { P404Component } from './shared/components/error/404.component';
import { NightlyJobTestComponent } from './modules/nightly-job-test/nightly-job-test.component';
import { LoginComponent } from './modules/login/login.component';
import { ManagementGuard } from './core/guards/management.guard';
import { NightlyJobGuard } from './core/guards/nightlyjob.guard';
import { ComparativeRaterLoginComponent } from './modules/comparative-rater/comparative-rater-login/comparative-rater-login.component';
import { ReportsGuard } from './modules/reports/reports-navigation-guard.service';
import { MakeAPaymentComponent } from './modules/make-a-payment/make-a-payment.component';
import { MakeAPaymentGuard } from './core/guards/makeapayment.guard';
import { TestMakeAPaymentComponent } from './modules/make-a-payment/test-make-a-payment/test-make-a-payment.component';
import { BookTransferGuard } from './core/guards/book-transfer.guard';
import { RaterMigrationGuard } from './core/guards/rater-migration.guard';
import { RaterMigrationComponent } from './modules/rater-migration/rater-migration.component';
import { AccessDenied001Component } from './views/error/access-denied-001.component';
import { LoginGuard } from './core/guards/login.guard';
import { UnderMaintenanceComponent } from './shared/components/under-maintenance/under-maintenance.component';
import { AccessComponent } from './modules/access/access.component';
import { MaintenanceModeGuard } from './core/guards/maintenace-mode.guard';
import { MaintenanceModeComponent } from './shared/components/maintenance-mode/maintenance-mode.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    },
    canActivate: [LoginGuard]
  },
  { path: PathConstants.Dashboard.Index, redirectTo: PathConstants.Dashboard.Index, pathMatch: 'full' },
  {
    path: '',
    component: MainLayoutComponent,

    data: {
      title: 'Home',
      leftSideBarHide: true,
    },
    children: [
      {
        path: PathConstants.Dashboard.Index,
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: '',
    component: MainSidebarLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: PathConstants.SubmissionManagement.Index,
        loadChildren: () => import('./modules/submission-management/submission-management.module').then(m => m.SubmissionManagementModule)
      }
    ]
  },
  {
    path: '',
    component: MainSidebarLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: `la`,
        loadChildren: () => import('app/states/la/la.module').then(m => m.LA_Module)
      }
    ]
  },
  {
    path: '',
    component: UserLayoutComponent,
    children: [
      {
        path: PathConstants.Account.Index,
        loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
      }
    ]
  },
  {
    path: '',
    component: MainSidebarLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: PathConstants.Policy.Index,
        loadChildren: () => import('./modules/policy-management/policy-management.module').then(m => m.PolicyManagementModule)
      }
    ]
  },
  {
    path: '',
    component: MainSidebarLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: PathConstants.Payments.Index,
        loadChildren: () => import('./modules/payments/payments.module').then(m => m.PaymentsModule)
      }
    ]
  },
  {
    path: '',
    component: MainSidebarLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard, ManagementGuard],
    children: [
      {
        path: PathConstants.Management.Index,
        loadChildren: () => import('./modules/management/management.module').then(m => m.ManagementModule)
      },
    ]
  },
  {
    path: 'compRater/:tr',
    component: ComparativeRaterLoginComponent,
    data: {
      title: 'Comparative Rater Login Page'
    }
  },
  {
    path: '',
    component: MainSidebarLayoutComponent,
    data: {
      title: 'Reports'
    },
    canActivate: [AuthGuard, ReportsGuard],
    children: [
      {
        path: PathConstants.Reports.Index,
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
      },
    ]
  },
  {
    path: '',
    component: MainSidebarLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard, BookTransferGuard],
    children: [
      {
        path: PathConstants.BookTransfer.Index,
        loadChildren: () => import('./modules/book-transfer/book-transfer.module').then(m => m.BookTransferModule)
      }
    ]
  },
  {
    path: PathConstants.AccessDenied001.Index,
    component: AccessDenied001Component,
    canActivate: [AuthGuard],
    data: {
      title: 'Access Denied'
    }
  },
  { path: 'nightlyjobtest', canActivate: [NightlyJobGuard], component: NightlyJobTestComponent, pathMatch: 'full' },
  { path: 'makeapayment/:policynumber/:insuredlastname', canActivate: [MakeAPaymentGuard], component: MakeAPaymentComponent, pathMatch: 'full' },
  { path: 'redirectmakeapayment/:policynumber/:insuredlastname', component: TestMakeAPaymentComponent, pathMatch: 'full' },
  // { path: 'makeapayment/:policynumber/:insuredlastname', component: UnderMaintenanceComponent, pathMatch: 'full' },
  // { path: 'redirectmakeapayment/:policynumber/:insuredlastname', component: UnderMaintenanceComponent, pathMatch: 'full' },
  { path: 'rater-migration', canActivate: [RaterMigrationGuard], component: RaterMigrationComponent, pathMatch: 'full' },
  { path: 'access', component: AccessComponent,canActivate: [AuthGuard], pathMatch: 'full' },
  { path: 'maintenance-mode', canActivate: [MaintenanceModeGuard], component: MaintenanceModeComponent, pathMatch: 'full'},
  { path: '', component: AuthComponent, pathMatch: 'full' },
  { path: '404', component: P404Component },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', scrollOffset: [0, 0],anchorScrolling: "enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
