import { Action, createReducer, on } from '@ngrx/store';
import * as fromRaterActions from 'app/store/rater/rater.actions';
import { initialState, RaterState } from './rater.state';

const _raterReducer = createReducer(
  initialState,
  on(
    fromRaterActions.updateRaterIsLoadingFromHORaterRequestData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromRaterActions.updateRaterListFromHORaterRequestData,
    (state, { list }) => ({ ...state, list: list })
  ),
  on(
    fromRaterActions.updateRaterIsLoadingFromPolicyManagementComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromRaterActions.updateRaterIsLoadingFromIssuePolicyComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromRaterActions.updateRaterIsLoadingFromPolicyIssueData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromRaterActions.updateRaterListFromCalculateRaterData,
    (state, { list }) => ({ ...state, list: list })
  ),
  on(
    fromRaterActions.updateRaterProratedListFromCalculateRaterData,
    (state, { proratedList }) => ({ ...state, proratedList: proratedList })
  ),
  on(
    fromRaterActions.updateRaterProratedListFromPolicyManagementComponent,
    (state, { proratedList }) => ({ ...state, proratedList: proratedList })
  )
);

export const raterReducer = (
  state: RaterState | null,
  action: Action
) => {
  return _raterReducer(state, action);
};