import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { CommonService } from '../../common.service';
import { ViewQuoteProposalDTO } from 'app/shared/models/data/dto/form-document/viewQuoteProposalDTO';
import { suppressDocumentDTO } from '../../../../shared/models/data/dto/form-document/suppressDocumentDTO';

@Injectable({
  providedIn: 'root'
})
export class FormDocumentService {

  constructor(protected http: HttpClient,
    protected commonService: CommonService,
    ) { }

  post(viewQuoteProposalDTO: ViewQuoteProposalDTO): Observable<string> {
    return this.http.post(`${environment.ApiUrl}/FormDocument/Report/ViewQuoteProposal`, viewQuoteProposalDTO, { responseType: 'text' }).pipe(
        map(data => {
            const result = data as string;
            return decodeURIComponent(result);
            }),
            catchError(this.commonService.handleObservableHttpError)
    );
  }

  getFTPDocumentsTemporary(riskId: string): Observable<suppressDocumentDTO[]> {
    return this.http.get(`${environment.ApiUrl}/FormDocument/FTPDocuments/${riskId}`)
      .catch(this.commonService.handleObservableHttpError);
  }

  updateFTPDocumentsTemporary(request: suppressDocumentDTO[]): Observable<any> {
    return this.http.put(`${environment.ApiUrl}/FormDocument/FTPDocuments`, request)
      .catch(this.commonService.handleObservableHttpError);
  }
}
