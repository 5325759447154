import { Pipe, PipeTransform } from '@angular/core';
import { mode, TransferModeConstants } from 'app/shared/constants/book-transfer.constants';
import { TransferStatusConstants } from 'app/shared/constants/book-transfer.constants';

@Pipe({
  name: 'transferStatus'
})
export class TransferStatusPipe implements PipeTransform {
  transform(transferStatus: string): string {
    if (!transferStatus) {
      return '';
    }

    let badgeClass: string;

    switch (transferStatus) {
      case this.isQueued:
        badgeClass = 'badge-secondary';
        break;
      case this.isInProgress:
        badgeClass = 'badge-primary';
        break;
      case this.isCompleted:
        badgeClass = 'badge-success';
        break;
      case this.isExpired:
        badgeClass = 'badge-danger';
        break;
    }

    return `<span class="transfer-status badge ${ badgeClass }">${ transferStatus.toLocaleUpperCase() }</span>`;
  }

  get isQueued(): any {
    return TransferStatusConstants.queued;
  }

  get isInProgress(): any {
    return TransferStatusConstants.inProgress;
  }

  get isCompleted(): any {
    return TransferStatusConstants.completed;
  }

  get isExpired(): any {
    return TransferStatusConstants.expired;
  }
}
