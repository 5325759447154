import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ReturnedComponent } from './returned.component';
import { ReturnedListComponent } from './returned-list/returned-list.component';
import { SharedModule } from 'app/shared/shared.module';
import { ReverseModalComponent } from './reverse-modal/reverse-modal.component';

const routes: Routes = [
  { path: '', component: ReturnedComponent }
];

@NgModule({
  declarations: [ReturnedComponent, ReturnedListComponent, ReverseModalComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
  ],
  entryComponents: [
    ReverseModalComponent
  ]
})
export class ReturnedModule { }
