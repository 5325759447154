import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BillingService } from '../../../../../../../app/core/services/billing/billing.service';
import { PolicyBillingData } from '../../../../../../../app/modules/policy-management/data/policy-billing.data';
import { BaseClass } from '../../../../../../../app/shared/base-class';
import { ErrorMessageConstant } from '../../../../../../../app/shared/constants/error-message.constants';
import { PolicyBillingLabelsConstants } from '../../../../../../../app/shared/constants/policy-billing.labels.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { MakePaymentModalComponent } from '../make-payment-modal/make-payment-modal.component';
import { PolicyService } from '../../../../../../../app/core/services/submission/policy.service';
import { RiskSearchFilterDTO } from '../../../../../../../app/shared/models/data/dto/policy-management/risk-search-filter.dto';
import NotifUtils from '../../../../../../../app/shared/utilities/notif-utils';
import Utils from '../../../../../../../app/shared/utilities/utils';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { BillingSummaryDTO } from '../../../../../../../app/shared/models/data/dto/billing/billing-summary.dto';
import { RiskSearchResultDTO } from '../../../../../../../app/shared/models/data/dto/policy-management/risk-search-result.dto';
import { RegexConstants } from '../../../../../../../app/shared/constants/regex.constants';
import { OutstandingReinstatementRequirementsModalComponent } from '../outstanding-reinstatement-requirements-modal/outstanding-reinstatement-requirements-modal.component';
import { MakePaymentDTO } from '../../../../../../../app/shared/models/data/dto/billing/make-payment.dto';
import { ReinstatementRequirementsMetModalComponent } from '../reinstatement-requirements-met-modal/reinstatement-requirements-met-modal.component';
import { StatusConstants } from '../../../../../../../app/shared/constants/risk-status';
import { AllowedPaymentRangeDTO } from '../../../../../../../app/shared/models/data/dto/billing/allowed-payment-range.dto';
import { PaymentPlanListConstants } from '../../../../../../../app/shared/constants/bind-and-issue.labels.constants';
import { PaymentProfileResponse } from '../../../../../../../app/shared/models/data/dto/billing/payment-profile-response.dto';
import { PaymentProcedure } from '../../../../../../../app/shared/enum/payment-procedure.enum';
import { AutoReinstatementData } from '../../../../../../../app/modules/policy-management/data/auto-reinstatement.data';
import { OtherApprovalRequirementsDTO } from '../../../../../../shared/models/data/dto/billing/other-approval-requirements.dto';

@Component({
  selector: 'app-search-policy-component',
  templateUrl: './search-policy.component.html',
  styleUrls: ['./search-policy.component.scss']
})
export class SearchPolicyComponent extends BaseClass implements OnInit, OnDestroy {

  public event: EventEmitter<any> = new EventEmitter<any>();
  public ErrorMessageConstant = ErrorMessageConstant;
  public SearchPolicyLabelConstants = PolicyBillingLabelsConstants.searchPolicy;

  modalRef: BsModalRef | null;

  searchPolicyForm: FormGroup;

  isPolicyNotFound: boolean;
  isPaymentNotAllowed: boolean;
  attemptedSearch: boolean;

  paymentNotAllowedReason: string;

  renewalRiskId: string;
  renewalPolicyNumber: string;
  renewalRiskDetailId: string;
  renewalRiskEntityId: string;
  renewalPayplan: string;
  forRenewalNotTaken: boolean;
  withRenewalOfferedOrRenewalNotTaken: boolean;
  isSiebelDataWithDuplicatePolicyNumber: boolean;

  isRenewalPolicyNumberUsedAndSiebelData: boolean;
  expRiskIdForRenPolNumberUsedAndSiebelData: string;
  enablePostPaymentFormAmountField: boolean = true;

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public billingData: PolicyBillingData,
    private billingService: BillingService,
    private policyService: PolicyService,
    public autoReinstatementData: AutoReinstatementData
  ) {
    super();
  }

  ngOnInit() {
    this.billingData.initPostPaymentForm();

    this.initForm();
  }

  initForm(): void {
    this.billingData.makePaymentData = null;
    this.renewalPolicyNumber = null;

    this.searchPolicyForm = this.fb.group({
      policyNumber: new FormControl(null, [Validators.required, Validators.pattern(RegexConstants.policyNumber)]),
      insuredLastName: new FormControl(null, [Validators.required]),
    });

    this.populateFields();
  }

  populateFields(): void {
    this.searchPolicyForm.reset();
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  onContinue(policyNumber: string = null): void {

    const policySearchRequest: RiskSearchFilterDTO = {
      policyNumber: policyNumber ? policyNumber : this.searchPolicyForm.get('policyNumber').value,
      isLatestEndorsement: true,
      insuredLastName: this.searchPolicyForm.get('insuredLastName').value
    };

    const searchRisk$: Observable<RiskSearchResultDTO[]> = this.policyService.searchRiskByFilter(policySearchRequest);
    const paymentCheckIfAllowed$: Observable<string[]> = this.policyService.getPaymentNotAllowedReasons(policySearchRequest.policyNumber);

    Utils.blockUI();

    forkJoin([searchRisk$, paymentCheckIfAllowed$])
      .pipe(
        takeUntil(this.stop$),
        tap(([searchResult, paymentErrors]) => {

          this.isSiebelDataWithDuplicatePolicyNumber= searchResult.length > 1 && searchResult[0].entity.risks[0].isSiebel;

          if (searchResult.length === 0) {
            this.isPolicyNotFound = true;
          }

          if (paymentErrors.length > 0 && !this.isSiebelDataWithDuplicatePolicyNumber) {
            this.isPaymentNotAllowed = true;
            this.paymentNotAllowedReason = paymentErrors.join('. ');
          }
        }),
        switchMap(([searchResult, paymentErrors]) => {
          if (this.isPolicyNotFound) {
            return of(false);
          }

          const actOrPexpSiebelDataWithDuplicatePolicyNumber = searchResult.filter(x => x.riskStatusCode === 'ACT' || x.riskStatusCode === 'PEXP' || x.riskStatusCode === 'CAN');
          const latestActOrPexpSiebelDataWithDuplicatePolicyNumber = actOrPexpSiebelDataWithDuplicatePolicyNumber.sort((a,b) => new Date(b.entity.risks[0].firstIssueDate).getTime() - new Date(a.entity.risks[0].firstIssueDate).getTime())[0];
          const isRenewalPolicyNumberUsed = !this.isSiebelDataWithDuplicatePolicyNumber ?
            Boolean(searchResult[0]?.renewalFromRiskId) && (
              searchResult[0].riskStatusCode !== 'ACT' &&
              searchResult[0].riskStatusCode !== 'CAN' &&
              searchResult[0].riskStatusCode !== 'EXP' &&
              searchResult[0].riskStatusCode !== 'PEXP'
            ) : Boolean(latestActOrPexpSiebelDataWithDuplicatePolicyNumber?.renewalFromRiskId) && (
              latestActOrPexpSiebelDataWithDuplicatePolicyNumber.riskStatusCode !== 'ACT' &&
              latestActOrPexpSiebelDataWithDuplicatePolicyNumber.riskStatusCode !== 'CAN' &&
              latestActOrPexpSiebelDataWithDuplicatePolicyNumber.riskStatusCode !== 'EXP' &&
              latestActOrPexpSiebelDataWithDuplicatePolicyNumber.riskStatusCode !== 'PEXP'
            );

          const riskId = !this.isSiebelDataWithDuplicatePolicyNumber ? // check if data is not from siebel
            (
              isRenewalPolicyNumberUsed ? searchResult[0]?.renewalFromRiskId : searchResult[0].id
            ) : latestActOrPexpSiebelDataWithDuplicatePolicyNumber.id;

          this.withRenewalOfferedOrRenewalNotTaken = !this.isSiebelDataWithDuplicatePolicyNumber ? (
              searchResult[0].renewalCode === 'RWNT' || searchResult[0].renewalCode === 'RWO' || searchResult[0].renewalCode === 'RRWO'
            ) : (
              latestActOrPexpSiebelDataWithDuplicatePolicyNumber.renewalCode === 'RWNT' || latestActOrPexpSiebelDataWithDuplicatePolicyNumber.renewalCode === 'RWO'
              || latestActOrPexpSiebelDataWithDuplicatePolicyNumber.renewalCode === 'RRWO'
            );
          this.forRenewalNotTaken = !this.isSiebelDataWithDuplicatePolicyNumber ? searchResult[0].renewalCode === 'RWNT'
            : latestActOrPexpSiebelDataWithDuplicatePolicyNumber.renewalCode === 'RWNT';

          if (!isRenewalPolicyNumberUsed) { // Expiring Policy # Used (w/ or w/o Renewal code)
            const risk = !this.isSiebelDataWithDuplicatePolicyNumber ? searchResult[0] : latestActOrPexpSiebelDataWithDuplicatePolicyNumber;
            if ((searchResult.length > 0 && paymentErrors.length === 0 ) || (paymentErrors.length > 0 && this.isSiebelDataWithDuplicatePolicyNumber)) {
              // For Expiring Policy w/ RWNT
              if(this.withRenewalOfferedOrRenewalNotTaken) {
                // Get Renewal Risk info
                return this.billingService.getRenewalRiskDetails(riskId)
                .pipe(
                  switchMap(renewalRisk => { // renewal risk info
                    this.renewalRiskId = renewalRisk?.id;
                    this.renewalPolicyNumber = renewalRisk?.policyNumber;
                    this.renewalRiskDetailId = renewalRisk?.riskDetails[0]?.id;
                    this.renewalRiskEntityId = renewalRisk?.entityId;

                    return this.policyService.getRiskById(riskId).map(currentRisk => currentRisk); // Get Current Risk info
                  }),
                  switchMap(currentRisk => { // current risk info
                    this.renewalPayplan = currentRisk.riskDetails?.filter(x => x.isActive)[0].riskBinds[0]?.riskBindBilling?.nextTermpaymentPlan;
                    return this.billingService.getSummary(riskId).map(res => ({ summary: res, policy: risk }));
                  })
                );
              }

              // For Policy w/o RWNT
              return this.billingService.getSummary(riskId).map(res => ({ summary: res, policy: risk })) ;
            }
            return of(false);
          } else if (isRenewalPolicyNumberUsed && this.withRenewalOfferedOrRenewalNotTaken) { // Renewal Policy # w/ Renewal code RWO | RRWO | RWNT
            const renewalRisk = searchResult[0];
            this.isRenewalPolicyNumberUsedAndSiebelData = renewalRisk.entity.risks[0].isSiebel;
            this.expRiskIdForRenPolNumberUsedAndSiebelData = renewalRisk.renewalFromRiskId;
            this.renewalRiskId =  renewalRisk.id;
            this.renewalPolicyNumber = renewalRisk?.policyNumber;
            this.renewalRiskDetailId = renewalRisk?.riskDetails[0]?.id;
            this.renewalRiskEntityId = renewalRisk?.entityId;

            return this.policyService.getRiskById(renewalRisk.renewalFromRiskId)
            .pipe(
              switchMap(expiringRisk => {
                this.renewalPayplan = expiringRisk.riskDetails?.filter(x => x.isActive)[0].riskBinds[0]?.riskBindBilling?.nextTermpaymentPlan;

                const searchRequest: RiskSearchFilterDTO = {
                  policyNumber: expiringRisk.policyNumber,
                  isLatestEndorsement: true,
                  insuredLastName: this.searchPolicyForm.get('insuredLastName').value
                };

                return this.policyService.searchRiskByFilter(searchRequest).map(res => res);
              }),
              switchMap(updatedSearchResult => {
                const resultCount = updatedSearchResult.length;
                let updatedResult = resultCount === 1 ? updatedSearchResult[0] : updatedSearchResult?.find(activePolicy => activePolicy?.riskStatusCode === 'ACT');
                if (!updatedResult) { // This Code represents, what if updatedSearchResult has 2 or more records yet there is no 'ACT' riskStatus
                  updatedResult = updatedSearchResult.find(expiry => expiry.id === this.expRiskIdForRenPolNumberUsedAndSiebelData);
                }
                return this.billingService.getSummary(updatedResult?.id).map(res => ({ summary: res, policy: updatedResult }));
              })
            );
          } else {
            return of(false);
          }
        }))
      .subscribe(res => {
        this.attemptedSearch = true;

        if (res) {
          const policyData = res as { summary: BillingSummaryDTO; policy: RiskSearchResultDTO };

          //total premium is now zero for cancelled policies
          // if (policyData.summary.totalPremium === 0) {
          //   NotifUtils.showError(this.SearchPolicyLabelConstants.billingDataNotFound);
          //   return;
          // }

          const makePaymentData: MakePaymentDTO = {
            riskIdData: !this.isRenewalPolicyNumberUsedAndSiebelData ? policyData.policy.id : this.expRiskIdForRenPolNumberUsedAndSiebelData,
            policyNumberData: policyData.policy.policyNumber,
            insuredLastName: policySearchRequest.insuredLastName,
            payoffAmount: policyData.summary.payoffAmount,
            amountDue: policyData.summary.balance,
            pastDueAmount: policyData.summary.pastDueAmount,
            isFullPay: this.billingData.paymentPlan.filter(plan => plan.billingCode.toLowerCase() === policyData.summary.paymentPlan.toLowerCase())[0].code === PaymentPlanListConstants.fullPayCode,
            isMortgageePayPlan: this.billingData.paymentPlan.filter(plan => plan.billingCode.toLowerCase() === policyData.summary.paymentPlan.toLowerCase())[0].code === PaymentPlanListConstants.mortgageePayCode,
            entity: policyData.policy.entity,
            renewalCode: policyData.policy.renewalCode,
            riskStatusCode: policyData.policy.riskStatusCode,
            riskDetails: policyData.policy.riskDetails,
            cancellationTypeCode: policyData.policy.cancellationTypeCode,
            renewalPolicyNumberData: this.renewalPolicyNumber,
            renewalRiskIdData: this.renewalRiskId,
            renewalPayPlan: this.renewalPayplan,
            renewalRiskDetailIdData: this.renewalRiskDetailId,
            renewalRiskEntityIdData: this.renewalRiskEntityId,
            isPendingCancellation: policyData.policy.entity.risks[0]?.pendingCancellationDate !== null
          };
          this.billingData.makePaymentData = makePaymentData;
          this.billingData.isAccessedFromPortal = false;

          if (policyData.policy.entity.risks[0].cancellationReason === PolicyBillingLabelsConstants.rewrite) {
            NotifUtils.showInfo(PolicyBillingLabelsConstants.rewritePaymentNotAllowed);
          } else {
            if (policyData.policy.riskStatusCode === StatusConstants.cancelled) {
              this.attemptedSearch = false;
              this.showReinstatementModal(policyData.policy.approvedForReinstatementDate ? true : false);
            } else {
              this.showMakePaymentModal(this.isRenewalPolicyNumberUsedAndSiebelData);
            }
          }
        } else {
          Utils.unblockUI();
        }
      },
        err => {
          this.attemptedSearch = false;
          Utils.unblockUI();
          NotifUtils.showMultiLineError(err.error?.message);
        });
  }

  showMakePaymentModal(isRenewalPolicyNumberUsedAndSiebelData?: boolean): void {
    Utils.blockUI();

    const isPolicyOnPendingCancellation = this.billingData.makePaymentData.isPendingCancellation;
    const apiCallsForActivePolicy = isPolicyOnPendingCancellation? [
      this.billingService.getAllowedPaymentRange(this.billingData.makePaymentData.riskIdData),
      this.autoReinstatementData.getOtherReinstatementRequirements(false, this.billingData.makePaymentData.riskIdData)
    ] : [
      this.billingService.getAllowedPaymentRange(this.billingData.makePaymentData.riskIdData)
    ];
    const parallelCalls = this.billingData.hasRenewalNotTaken ? [
      this.billingService.getAllowedPaymentRange(this.billingData.makePaymentData.riskIdData),
      this.billingService.getIsOtherRenewalRequirementsMet({
        renewalRiskId: this.renewalRiskId,
        expiringRiskId: this.billingData.makePaymentData.riskIdData,
        paymentPlan: this.billingData.makePaymentData.renewalPayPlan
      })
    ] : apiCallsForActivePolicy;

    forkJoin(parallelCalls)
      .pipe(takeUntil(this.stop$))
      .subscribe(res => {
        const initialState = {
          allowedPaymentRange: <AllowedPaymentRangeDTO>res[0],
          paymentProcedure: PaymentProcedure.PostBasic,
          balance: this.billingData.makePaymentData?.amountDue,
          isForRenewalReinstatement: this.forRenewalNotTaken,
          isRenewalPolicyNumberUsedAndSiebelData: isRenewalPolicyNumberUsedAndSiebelData,
          isPolicyOnPendingCancellation: isPolicyOnPendingCancellation
        };

        this.autoReinstatementData.allowedPaymentRangeFromPostPayment = <AllowedPaymentRangeDTO>res[0];
        this.autoReinstatementData.requirementsMetDetails = <OtherApprovalRequirementsDTO>res[1] ?? null;

        this.modalRef = this.modalService.show(MakePaymentModalComponent, {
          initialState,
          backdrop: true,
          ignoreBackdropClick: true,
        });

        this.modalRef.hide();
        this.bsModalRef.hide();
      },
      err => {
        Utils.unblockUI();
        NotifUtils.showMultiLineError(err.error?.message);
      });
  };


  showReinstatementModal(isApprovedForReinstatement: boolean): void {
    Utils.blockUI();
    forkJoin([this.billingService.getIsPayToInstantReinstateMet(this.billingData.makePaymentData.riskIdData),
      this.billingService.getIsOtherRequirementsMet(this.billingData.makePaymentData.riskIdData),
      this.billingService?.getBillingAutoReinstatementDetails(this.billingData.makePaymentData.riskIdData)
    ])
        .subscribe(result => {
          Utils.unblockUI();

          this.autoReinstatementData.allowedPaymentRangeFromPostPayment = result[0].allowedPaymentRange;
          this.autoReinstatementData.requirementsMetDetails = result[1];
          this.autoReinstatementData.reinstatementView = result[2];
          this.autoReinstatementData.isApprovalRequired$.next(this.autoReinstatementData.reinstatementView?.isApprovalRequired ?? false);

          const initialState = {
            allowedPaymentRange: result[0].allowedPaymentRange,
            paymentProcedure: PaymentProcedure.PayToReinstate,
            balance: this.billingData.makePaymentData?.amountDue
          };

          this.modalRef = this.modalService.show(MakePaymentModalComponent, {
            initialState,
            backdrop: true,
            ignoreBackdropClick: true,
          });

          this.enablePostPaymentFormAmountField = false;

          this.modalRef.hide();
          this.bsModalRef.hide();
        }, err => {
          Utils.unblockUI();
          NotifUtils.showError(JSON.stringify(err.error?.message));
        });
  }

  showReinstatementOutstandingRequirementsModal(outstandingRequirementsList: string[], hasOutstandingBalance: boolean): void {
    Utils.unblockUI();

    const allowedPaymentRange: AllowedPaymentRangeDTO = {
      minimumPaymentAmount: 0,
      maximumPaymentAmount: this.billingData.makePaymentData.amountDue
    };

    const initialState = {
      accessedFromPortal: true,
      riskId: this.billingData.makePaymentData.riskIdData,
      outstandingRequirementsList: outstandingRequirementsList,
      hasOutstandingBalance: hasOutstandingBalance,
      allowedPaymentRange: allowedPaymentRange
    };

    this.modalRef = this.modalService.show(OutstandingReinstatementRequirementsModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.hide();
    this.bsModalRef.hide();
  }

  showReinstatementRequirementsMetModal(minimumAmountToReinstate: number, maximumAmountToReinstate: number): void {
    Utils.unblockUI();

    const initialState = {
      accessedFromPortal: true,
      balance: this.billingData.makePaymentData.amountDue,
      payoffAmount: this.billingData.makePaymentData.payoffAmount,
      pastDueAmount: this.billingData.makePaymentData.pastDueAmount,
      minimumAmountToReinstate: minimumAmountToReinstate,
      maximumAmountToReinstate: maximumAmountToReinstate
    };

    this.modalRef = this.modalService.show(ReinstatementRequirementsMetModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.hide();
    this.bsModalRef.hide();
  }

  onClose(): void {
    this.bsModalRef.hide();
  }

  onFieldChange(id: number): void {
    if (id === 1) {
      const policy = this.searchPolicyForm.get('policyNumber');
      policy.setValue(policy.value.replace(/\s/g, ''));
    }
  }

  ngOnDestroy() {
    if (this.enablePostPaymentFormAmountField) {
      this.billingData.postPaymentForm.get('amount').enable();
    }
  }

  onClear() {
    this.attemptedSearch = false;
    this.isPolicyNotFound = false;
    this.isPaymentNotAllowed = false;
    this.populateFields();
  }
}
