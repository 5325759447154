import { RiskClaimDTO } from 'app/shared/models/data/dto/third-party/risk-claims.dto';
import { createAction, props } from '@ngrx/store';

export const updateClaimsIsLoadingFromClaimsSavingData = createAction(
  '[ClaimsSavingData] Update Claims Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateClaimsListFromClaimsSavingData = createAction(
  '[ClaimsSavingData] Update Claims List',
  props<{ list: RiskClaimDTO[] }>()
);

export const updateClaimsIsLoadingFromClaimsComponent = createAction(
  '[ClaimsComponent] Update Claims Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateClaimsListFromClaimsComponent = createAction(
  '[ClaimsComponent] Update Claims List',
  props<{ list: RiskClaimDTO[] }>()
);

export const updateClaimsListFromSubmissionManagementComponent = createAction(
  '[SubmissionManagementComponent] Update Claims List',
  props<{ list: RiskClaimDTO[] }>()
);

export const updateClaimsListFromPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update Claims List',
  props<{ list: RiskClaimDTO[] }>()
);

export const updateClaimsListFromClaimsData = createAction(
  '[ClaimsData] Update Claims List',
  props<{ list: RiskClaimDTO[] }>()
);