import { Component, Input, OnInit } from '@angular/core';
import { BindAndIssueValidatorErrorConstants } from '../../../../../../../../app/modules/submission-management/validators/validator-error-constants/bind-and-issue-error.constants';
import { BillingLabelConstants, CreditCardTypeConstants } from '../../../../../../../../app/shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from '../../../../../../../../app/shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../../../../app/shared/constants/generic.labels.constants';
import FormUtils from '../../../../../../../../app/shared/utilities/form.utils';
import { BindAndIssueData } from '../../../../../../../../app/modules/submission-management/data/bind-and-issue.data';
import { FormGroup, Validators } from '@angular/forms';
import { PaymentMethod } from '../../../../../../../../app/shared/enum/payment-method.enum';
import { MonthConstants } from '../../../../../../../../app/shared/constants/month.constants';
import { CreditCardType } from '../../../../../../../../app/shared/enum/credit-card-type-enum';
import { RegexConstants } from '../../../../../../../../app/shared/constants/regex.constants';
import { GeneralValidationService } from '../../../../../../../../app/core/services/submission/validations/general-validation.service';
import { BindAndIssueService } from '../../../bind-and-issue.service';

@Component({
  selector: 'app-billing-form-credit-card',
  templateUrl: './billing-form-credit-card.component.html',
  styleUrls: ['./billing-form-credit-card.component.scss']
})
export class BillingFormCreditCardComponent implements OnInit {

  @Input() parentForm: FormGroup;

  public FormUtils = FormUtils;
  public BillingLabelConstants = BillingLabelConstants;
  public ErrorMessageConstant = ErrorMessageConstant;
  public CreditCardTypeConstants = CreditCardTypeConstants;
  GenericLabel = GenericLabel;
  isOpen: boolean = true;
  creditCardExpiredErrorMessage = BindAndIssueValidatorErrorConstants.creditCardExpired.value;

  months: any = [];

  PaymentMethod = PaymentMethod;

  cardCodeLength: number = 3;

  constructor(public bindAndIssueData: BindAndIssueData,
    protected bindAndIssueService: BindAndIssueService) { }

  ngOnInit() {
    this.bindAndIssueService.checkCreditCardPaymentForUpdateRecurring();
    for (const month in MonthConstants) {
      this.months.push(MonthConstants[month]);
    }
  }

  get billingForm(): FormGroup {
    return this.bindAndIssueData.billingForm;
  }

  onCreditCardType(event?): void {
    const creditCardType = event.target.value;
    if (creditCardType !== CreditCardTypeConstants.AMEX) {
      this.bindAndIssueData.billingForm.get('ccCID').setValue('');
    } else {
      this.bindAndIssueData.billingForm.get('ccCVV').setValue('');
    }
  }

  get activeCreditCardTypes(): any {
    return this.bindAndIssueData.creditTypeList.filter(x => x.isActive);
  }

  checkIfExpired(field: any): boolean {
    return this.bindAndIssueData.billingForm.get(field).hasError(BindAndIssueValidatorErrorConstants.creditCardExpired.key);
  }
}
