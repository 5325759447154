import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CheckCommissionFileRequestDTO,
  CheckCommissionFileResponseDTO,
  DownloadCommissionFileRequestDTO,
  DownloadCommissionFileResponseDTO,
  EmailCommissionFileRequestDTO,
  EmailCommissionFileResponseDTO,
  GenerateAgencyCommissionStatementRequestDTO,
  GenerateAgencyCommissionStatementResponseDTO,
  GenerateCommissionFileRequestDTO,
  GenerateCommissionFileResponseDTO,
  IGenerateCommissionStatementResponseDTO,
  IGenerateNachaRequestDTO,
  IGenerateNachaResponsetDTO,
  IGetFileResultDTO,
  INachaBankDTO,
  PreviewCommissionFileRequestDTO,
  PreviewCommissionFileResponseDTO
} from '../../shared/models/data/dto/nacha/nacha.dto';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonService } from './common.service';
import { CommissionStatementDTO } from 'app/shared/models/data/dto/nacha/commission-statement.dto';

@Injectable({
  providedIn: 'root'
})
export class NachaService {

  httpRequestHeader= new HttpHeaders({ timeout: `${900000}` });

  constructor(
    public http: HttpClient,
    private commonService: CommonService
    ) {
    }

  downloadFile(uriFilePath: string): Observable<string> {
    return this.http.get(`${environment.ApiUrl}/Nacha/get-file?filepath=${uriFilePath}`, { headers: this.httpRequestHeader })
      .pipe(
        map(result => {
          return result as string;
        }),
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  getFiles(filePaths: string[]): Observable<IGetFileResultDTO[]> {
    return this.http.post(`${environment.ApiUrl}/Nacha/get-files`, filePaths, { headers: this.httpRequestHeader })
      .pipe(
        map(result => {
          return result as Observable<IGetFileResultDTO[]>;
        }),
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  agencyCommissionList(agencyId: string): Observable<CommissionStatementDTO[]> {
    return this.http.get(`${environment.ApiUrl}/Nacha/agency-commission-list?agencyId=${agencyId}`, { headers: this.httpRequestHeader })
      .pipe(
        map(result => {
          return result as CommissionStatementDTO[];
        }),
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  agencyGenerateCSVCommStatement(agencyId: string, month: number, year: number): Observable<string> {
    return this.http.get(`${environment.ApiUrl}/Nacha/agency-generate-csv-commission-statement?month=${month}&year=${year}&agencyId=${agencyId}`, { headers: this.httpRequestHeader })
      .pipe(
        map(result => {
          return result as string;
        }),
        catchError(this.commonService.handleObservableHttpError)
      );
  }

  agencyGenerateCSVCommStatements(request: GenerateAgencyCommissionStatementRequestDTO[]): Observable<GenerateAgencyCommissionStatementResponseDTO[]> {
    return this.http.post(`${environment.ApiUrl}/Nacha/agency-generate-csv-commission-statement`, request, { headers: this.httpRequestHeader })
    .pipe(
      map(result => {
        return result as GenerateAgencyCommissionStatementResponseDTO[];
      }),
      catchError(this.commonService.handleObservableHttpError)
    );
  }

  previewCommissionFile(request: PreviewCommissionFileRequestDTO): Observable<PreviewCommissionFileResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/Nacha/preview-commission-file`, request, { headers: this.httpRequestHeader })
    .pipe(
      map(result => {
        return result as PreviewCommissionFileResponseDTO[];
      }),
      catchError(this.commonService.handleObservableHttpError)
    );
  }

  generateCommissionFile(request: GenerateCommissionFileRequestDTO): Observable<GenerateCommissionFileResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/Nacha/generate-commission-file`, request, { headers: this.httpRequestHeader })
    .pipe(
      map(result => {
        return result as GenerateCommissionFileResponseDTO;
      }),
      catchError(this.commonService.handleObservableHttpError)
    );
  }

  emailCommissionFile(request: EmailCommissionFileRequestDTO): Observable<EmailCommissionFileResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/Nacha/email-commission-file`, request, { headers: this.httpRequestHeader })
    .pipe(
      map(result => {
        return result as EmailCommissionFileResponseDTO;
      }),
      catchError(this.commonService.handleObservableHttpError)
    );
  }

  downloadCommissionFile(request: DownloadCommissionFileRequestDTO): Observable<DownloadCommissionFileResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/Nacha/download-commission-file`, request, { headers: this.httpRequestHeader })
    .pipe(
      map(result => {
        return result as DownloadCommissionFileResponseDTO;
      }),
      catchError(this.commonService.handleObservableHttpError)
    );
  }

  checkCommissionFile(request: CheckCommissionFileRequestDTO): Observable<CheckCommissionFileResponseDTO> {
    return this.http.post(`${environment.ApiUrl}/Nacha/check-commission-file`, request, { headers: this.httpRequestHeader })
    .pipe(
      map(result => {
        return result as CheckCommissionFileResponseDTO;
      }),
      catchError(this.commonService.handleObservableHttpError)
    );
  }

  getNachaBank(): Observable<INachaBankDTO[]> {
    return this.http.get(`${environment.ApiUrl}/Nacha/nacha-bank`)
      .pipe(
        map(result => {
          return result as Observable<INachaBankDTO[]>;
        }),
        catchError(this.commonService.handleObservableHttpError)
      );
  }
}