export const UsersLabelConstants = {
  userInfoTitle: 'User Information',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  fullName: 'Full Name',
  homePhone: 'Home Phone',
  mobilePhone: 'Mobile Phone',
  workPhone: 'Work Phone',
  workPhoneExtension: 'Work Phone Extension',
  personalEmailAddress: 'Personal Email Address',
  workEmailAddress: 'Work Email Address',
  birthdate: 'Birtdate',
  age: 'Age',
  gender: 'Gender',
  maritalStatus: 'Marital Status',
  federalIdNumber: 'Federal ID Number',
  iccmcDocketNumber: 'ICCMC Docket Number',
  usDotNumber: 'US Dot Number',
  pucNumber: 'PUC Number',
  hasSubsidiaries: 'Has Subsidiaries?',
  isActive: 'Is Active?',
  isAgent: 'Is Agent?',
  isSystemUser: 'Is System User?',
  userName: 'Username',
  roleName: 'User Type',
  address: 'Address',
  resetPassword: 'Reset Password',
  torrentUsername: 'Torrent Username',

  userModalAddedSuccessfullyMessage: 'User Added Successfully.',
  userModalUpdatedSuccessfullyMessage: 'User Updated Successfully.',
  userModalDeletedSuccessfullyMessage: 'User Deleted Successfully.',

  auditLog: {
    add: 'CREATE',
    edit: 'UPDATE',
    delete: 'DELETE'
  },

  methodName: {
    add: 'postUserInformation',
    edit: 'putUserInformation',
    delete: ''
  }
};

export const UserAddressLabelConstants = {
  address: 'Address',
  city: 'City',
  zipCode: 'Zip Code',
  state: 'State',
  addressType: 'Address Type',
  streetAddress1: 'Street Address 1',
  streetAddress2: 'Street Address 2',
  zipCodeExtension: 'Zip Code Extension',
};

export const UserProgramAccessLabelConstants = {
  userAccessTitle: 'User Access',
  program: 'Program',
  state: 'State',
  roleGroup: 'Role Group',
  role: 'Role',
  rolePagesTitle: 'Role Pages',
  mainPage: 'Main Page',
  page: 'Page',
  view: 'View',
  add: 'Add',
  edit: 'Edit'
};

export const UserAgencyAccessLabelConstants = {
  agency: 'Agency',
  program: 'Program'
};

export const UserTableLabelConstants = {
  userName: 'Username',
  name: 'Name',
  email: 'Email',
  agency: 'Agency',
  subAgency: 'Sub Agency',
  status: 'Status',
  usersLabel: 'Users',
  searchButtonLabel: 'Search by',
  agentLabel: 'Agent',
  accessCategory: 'Access Category',
  userType: 'User Type',
  program: 'Program',
};

export const UserEditLabelConstants = {
  userNameLabel: 'Username:',
  userName: 'Username',
  firstNameLabel: 'First Name:',
  firstName: 'First Name:',
  lastNameLabel: 'Last Name:',
  lastName: 'Last Name',
  emailAddLabel: 'Email Address:',
  emailAdd: 'Email Address',
  mainPageLabel: 'Main Page:',
  roleGroupLabel: 'Role Group:',
  roleGroup: 'Role Group',
  roleLabel: 'Role:',
  role: 'Role'
};

export const UserTypeLabelConstants = {
  categoryLabel: 'Access Category:',
  category: 'Access Category',
  typeLabel: 'Type Name:',
  type: 'Type Name',
  internalLabel: 'Internal User:',
  isActiveLabel: 'Active:',
  programLabel: 'Program:',
  program: 'Program',
  addUserTypeModal: 'Add User Type',
  editUserTypeModal: 'Edit User Type',
  regionalSalesManager: 'regional sales manager'
};

export const GroupTableLabelConstants = {
  name: 'Name',
  description: 'Description',
  status: 'Status',
  active: 'Active',
  userName: 'Username',
  isInternal: 'Internal',
  addGroupModal: 'Add User Group',
  editGroupModal: 'Edit User Group'
};

export const RoleTableLabelConstants = {
  userName: 'Username',
  name: 'Name',
  email: 'Email',
  status: 'Status',
  accessCategoryLabel: 'Access Category:',
  typeNameLabel: 'Type Name:',
  sectionLabel: 'Role Access:',
  searchButtonLabel: 'Search by',
  roleModal: 'View Access Page',
};

export const GenericUserLabels = {
  add: 'Add User',
  edit: 'Edit User',
  save: 'Save',
  cancel: 'Cancel'
};
