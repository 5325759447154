import { createAction, props } from '@ngrx/store';

export const updateCalculateValuationIsLoadingFromThirdPartyData = createAction(
  '[ThirdPartyData] Update Calculate Valuation Is Loading',
  props<{ isLoading: boolean }>()
);

export const updateCalculateValuationDataFromThirdPartyData = createAction(
  '[ThirdPartyData] Update Calculate Valuation Data',
  props<{ data: any }>()
);