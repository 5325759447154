import { Component, OnInit } from '@angular/core';
import { AgencyData } from 'app/modules/management/data/agency.data';
import { BaseClass } from 'app/shared/base-class';
import { BookTransferData } from 'app/modules/book-transfer/data/book-transfer.data';
import Utils from 'app/shared/utilities/utils';
import {
  mode,
  PoliciesConstants,
  NotificationMessages,
  buttonAction,
  ButtonActionConstants
} from 'app/shared/constants/book-transfer.constants';
import { BookTransferService } from 'app/core/services/book-transfer.service';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { BatchTransferSavingDTO } from 'app/shared/models/data/dto/book-transfer/book-transfer-saving.dto';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { TransferQueueDTO } from 'app/shared/models/data/dto/book-transfer/transfer-queue.dto';
import { takeUntil } from 'rxjs/operators';
import { ProgramStateSubAgencyService } from 'app/core/services/management/programstate-subagency.service';
import { ProgramStateAgentService } from 'app/core/services/management/programstate-agent-service';

@Component({
  selector: 'app-new-transfer',
  templateUrl: './new-transfer.component.html',
  styleUrls: ['./new-transfer.component.scss']
})
export class NewTransferComponent extends BaseClass implements OnInit {
 mode: mode = 'new';
 notificationMessages = NotificationMessages;
 buttonActionConstants = ButtonActionConstants;

  constructor(
    public agencyData: AgencyData,
    private bookTransferData: BookTransferData,
    private bookTransferService: BookTransferService,
    private router: Router,
    private programStateSubAgencyService: ProgramStateSubAgencyService,
    private programStateAgentService: ProgramStateAgentService
  ) {
    super();
  }

  ngOnInit(): void {
    Utils.blockUI();
    this.bookTransferData.isEditMode = false;
    this.bookTransferData.resetData();
    this.bookTransferData.transferDateOptions.disableUntil = this.bookTransferData.disableUntil;
    this.bookTransferData.initBookTransferForms();

    if (this.agencyData.isSearched) {
      this.agencyData.callAgencyAPIonLoad();
      this.agencyData.isSearched = false;
    }

    this.bookTransferData.getAgencies();
    this.bookTransferData.getPoliciesToTransfer();
    Utils.unblockUI();
  }

  clearPolicies(): void {
    this.bookTransferData.policies = [];
    this.bookTransferData.toTransferPolicies = [];
    this.bookTransferData.forTransferPolicies = [];
  }

  async fromAgencyNameChanged(data: any): Promise<void> {
    Utils.blockUI();

    if (data) {
      this.bookTransferData.currentFromAgencyId = data?.id;
      this.bookTransferData.fromAgencyForm.get('fromAgencyName').patchValue(data?.name);
      this.bookTransferData.fromAgencyForm.get('fromAgencyCode').patchValue(this.getAgencyCode(data?.id));

      this.resetFromSubAgencyName();
      this.clearPolicies();
      await this.initFromAgencySubAgencies(data?.id);
      Utils.unblockUI();

      return;
    }

    this.bookTransferData.currentFromAgencyId = '';
    this.resetFromAgencyName();
    this.resetFromSubAgencyName();
    this.clearPolicies();
    Utils.unblockUI();
  }

  resetFromAgencyName(): void {
    this.bookTransferData.fromAgencyForm.get('fromAgencyName').patchValue('');
    this.bookTransferData.fromAgencyForm.get('fromAgencyCode').patchValue('');

    if (this.bookTransferData.toAgencyForm.get('toAgencyName').value === '') {
      this.clearAgencyNamesDisabling();
    }
  }

  resetFromSubAgencyName(): void {
    this.bookTransferData.fromAgencyForm.get('fromSubAgencyName').patchValue('');
    this.bookTransferData.fromAgencyForm.get('fromSubAgencyCode').patchValue('');

    this.bookTransferData.disableFromAgencySubAgencyName = true;
    this.bookTransferData.fromSubAgencyNameModel = null;
  }

  async initFromAgencySubAgencies(id: string): Promise<void> {
    this.bookTransferData.fromAgencySubAgencies = await this.getSubAgencies(id);

    this.bookTransferData.fromSubAgencyNames = this.bookTransferData.fromAgencySubAgencies.map((item: any) => {
      return {
        id: item.subAgency.id,
        name: item.subAgency.entity.companyName,
        disabled: false
      };
    });

    this.bookTransferData.disableFromAgencySubAgencyName = false;
  }

  async fromSubAgencyNameChanged(data: any): Promise<void> {
    Utils.blockUI();

    if (data) {
      if (this.bookTransferData.currentToSubAgencyId === data.id) {
        NotifUtils.showError(this.notificationMessages.sameSubAgencyError, () => {
          this.bookTransferData.fromAgencyForm.get('fromSubAgencyName').patchValue('');
          this.bookTransferData.fromAgencyForm.get('fromSubAgencyCode').patchValue('');
          this.bookTransferData.fromSubAgencyNameModel = null;
        });

        return;
      }

      this.bookTransferData.currentFromSubAgencyId = data?.id;
      this.bookTransferData.fromAgencyForm.get('fromSubAgencyName').patchValue(data?.name);
      this.bookTransferData.fromAgencyForm.get('fromSubAgencyCode').patchValue(this.getFormAgencySubAgencyCode(data?.id));
      this.clearPolicies();

      await this.bookTransferData.getPoliciesToTransferByAgencySubAgency(this.bookTransferData.currentFromAgencyId, this.bookTransferData.currentFromSubAgencyId);
      this.getTransferQueuePayload();
      this.bookTransferData.getPoliciesToTransfer();
      Utils.unblockUI();

      return;
    }

    this.bookTransferData.currentFromSubAgencyId = '';
    this.bookTransferData.fromAgencyForm.get('fromSubAgencyName').patchValue('');
    this.bookTransferData.fromAgencyForm.get('fromSubAgencyCode').patchValue('');
    this.clearPolicies();
    Utils.unblockUI();
  }

  getFormAgencySubAgencyCode(subAngecyId: string): string {
    const fromAgencySubAgencies = this.bookTransferData.fromAgencySubAgencies.find(item => {
      return item.subAgency.id === subAngecyId;
    });

    return fromAgencySubAgencies.subAgency.subAgencyCode;
  }

  resetToSubAgencyName(): void {
    this.bookTransferData.toAgencyForm.get('toSubAgencyName').patchValue('');
    this.bookTransferData.toAgencyForm.get('toSubAgencyCode').patchValue('');

    this.bookTransferData.disableToAgencySubAgencyName = true;
    this.bookTransferData.toSubAgencyNameModel = null;
  }

  async toAgencyNameChanged(data: any): Promise<void> {
    Utils.blockUI();

    if (data) {
      this.bookTransferData.currentToAgencyId = data?.id;
      this.bookTransferData.toAgencyForm.get('toAgencyName').patchValue(data?.name);
      this.bookTransferData.toAgencyForm.get('toAgencyCode').patchValue(this.getAgencyCode(data?.id));
      this.resetToSubAgencyName();
      await this.initToAgencySubAgencies(data?.id);
      Utils.unblockUI();

      return;
    }

    this.resetToAgencyName();
    this.resetToSubAgencyName();
    Utils.unblockUI();
  }

  getAgencyCode(agencyId: string): string {
    const agency = this.bookTransferData.agencies.find(item => {
      return item.agencyId === agencyId;
    });

    return agency.agency.agencyCode;
  }

  toSubAgencyNameChanged(data: any): void {
    if (data) {
      if (this.bookTransferData.currentFromSubAgencyId === data.id) {
        NotifUtils.showError(this.notificationMessages.sameSubAgencyError, () => {
          this.bookTransferData.toAgencyForm.get('toSubAgencyName').patchValue('');
          this.bookTransferData.toAgencyForm.get('toSubAgencyCode').patchValue('');
          this.bookTransferData.toSubAgencyNameModel = null;
        });

        return;
      }

      Utils.blockUI();
      this.bookTransferData.currentToSubAgencyId = data?.id;
      this.bookTransferData.toAgencyForm.get('toSubAgencyName').patchValue(data?.name);
      this.bookTransferData.toAgencyForm.get('toSubAgencyCode').patchValue(this.getToAgencySubAgencyCode(data?.id));
      this.getToAgents();

      return;
    }

    this.bookTransferData.currentToSubAgencyId = '';
    this.bookTransferData.toAgencyForm.get('toSubAgencyName').patchValue('');
    this.bookTransferData.toAgencyForm.get('toSubAgencyCode').patchValue('');
  }

  getToAgencySubAgencyCode(subAngecyId: string): string {
    const toAgencySubAgencies = this.bookTransferData.toAgencySubAgencies.find(item => {
      return item.subAgency.id === subAngecyId;
    });

    return toAgencySubAgencies.subAgency.subAgencyCode;
  }

  getToAgents(): void {
    this.programStateAgentService.getAllProgramStateAgents(this.bookTransferData.currentToAgencyId, this.bookTransferData.currentToSubAgencyId)
      .pipe(takeUntil(this.stop$))
      .subscribe(agents => {
        if (agents.length > 0) {
          this.bookTransferData.toSubAgencyAgents = agents;
          Utils.unblockUI();
        } else {
          NotifUtils.showError(this.notificationMessages.noAgentFound, () => {
            this.bookTransferData.toAgencyForm.get('toSubAgencyName').patchValue('');
            this.bookTransferData.toAgencyForm.get('toSubAgencyCode').patchValue('');
            this.bookTransferData.toSubAgencyNameModel = null;
          });
        }
      });
  }

  async initToAgencySubAgencies(id: string): Promise<void> {
    this.bookTransferData.toAgencySubAgencies = await this.getSubAgencies(id);

    this.bookTransferData.toSubAgencyNames = this.bookTransferData.toAgencySubAgencies.map((item: any) => {
      return {
        id: item.subAgency.id,
        name: item.subAgency.entity.companyName,
        disabled: false
      };
    });

    this.bookTransferData.disableToAgencySubAgencyName = false;
  }

  async getSubAgencies(agencyId: string): Promise<any> {
    let subAgencies: any[];

    await this.programStateSubAgencyService.getProgramSubStateAgencyWithAgencyIdPromise(agencyId).then(data => {
      subAgencies = data.filter(item => {
        if (item.subAgency.entity.companyName) {
          return item;
        }
      });
    });

    return subAgencies;
  }

  resetToAgencyName(): void {
    this.bookTransferData.toAgencyForm.get('toAgencyName').patchValue('');
    this.bookTransferData.toAgencyForm.get('toAgencyCode').patchValue('');

    if (this.bookTransferData.fromAgencyForm.get('fromAgencyName').value === '') {
      this.clearAgencyNamesDisabling();
    }
  }

  clearAgencyNamesDisabling(): void {
    this.bookTransferData.fromAgencyNames = this.bookTransferData.fromAgencyNames.map(item => {
      item.disabled = false;

      return item;
    });

    this.bookTransferData.toAgencyNames = this.bookTransferData.toAgencyNames.map(item => {
      item.disabled = false;

      return item;
    });
  }

  getPostNewBookTransferPayload(): BatchTransferSavingDTO {
    const transferDate = this.bookTransferData.transferDetailsForm.get('transferDate').value?.singleDate?.formatted;
    const transferDateFormatted = String(moment(transferDate).format(this.bookTransferData.dateFormat));

    const payload = {
      fromAgencyId: this.bookTransferData.currentFromAgencyId,
      fromSubAgencyId: this.bookTransferData.currentFromSubAgencyId,
      toAgencyId: this.bookTransferData.currentToAgencyId,
      toSubAgencyId: this.bookTransferData.currentToSubAgencyId,
      toAgentId: this.bookTransferData.toSubAgencyAgents[0].agent.id,
      transferReason: this.bookTransferData.transferDetailsForm.get('transferReason').value,
      transferDate: transferDateFormatted,
      isMassTransfer: this.bookTransferData.isMassTransfer,
      isActive: true,
      transferQueue: this.getTransferQueuePayload()
    };

    return payload;
  }

  getTransferQueuePayload(): TransferQueueDTO[] {
    const payload = this.bookTransferData.forTransferPolicies.map(policy => {
      const policyBySubAgency = this.bookTransferData.policiesBySubAgency.find(subAgencyPolicy => {
        return policy.policyNumber === subAgencyPolicy.policyNumber;
      });

      return {
        riskId: policyBySubAgency.id,
        policyNumber: policyBySubAgency.policyNumber,
        fromAgentId: policyBySubAgency.agentId,
        transferEffectiveDate: this.bookTransferData.getTransferEffectiveDate(policyBySubAgency),
        isActive: true
      };
    });

    return payload;
  }

  onSubmit(data: buttonAction) {
    switch(data) {
      case this.buttonActionConstants.submit:
        this.postNewBookTransfer();
        break;
      case this.buttonActionConstants.cancel:
        this.bookTransferData.onCancel();
        break;
    }
  }

  postNewBookTransfer(): void {
    Utils.blockUI();

    this.bookTransferService.postNewBookTransfer(this.getPostNewBookTransferPayload()).pipe(takeUntil(this.stop$)).subscribe(() => {
      NotifUtils.showSuccessWithConfirmationFromUser(this.notificationMessages.newTransferBatchSuccess, () => {
        this.router.navigate(['book-transfer', 'batch-dashboard']);
      });
    });
  }
}