import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from 'app/core/services/common.service';
import Utils from '../../shared/utilities/utils';
import { RaterPremiumService } from '../../../app/core/services/submission/rater-premium/rater-premium.service';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../app/shared/base-class';
import { SiebelGenerateRequestDTO } from '../../../app/shared/models/data/dto/rater/siebel-generate-request.dto';
import { RegexConstants } from '../../../app/shared/constants/regex.constants';
import { ErrorMessageConstant } from '../../../app/shared/constants/error-message.constants';
import { SiebelGenerateResponseDTO } from '../../../app/shared/models/data/dto/rater/siebel-generate-response.dto';

@Component({
  selector: 'app-rater-migration',
  templateUrl: './rater-migration.component.html',
  styleUrls: ['./rater-migration.component.scss']
})
export class RaterMigrationComponent extends BaseClass implements OnInit {
  datePickerDateOption: IAngularMyDpOptions;
  frmGrp = new FormGroup({
    dateFrom: new FormControl(null),
    dateTo: new FormControl(null),
    policyNumber: new FormControl(null)
  });

  isMigrationComplete: boolean;
  migrationResult: SiebelGenerateResponseDTO[];
  public ErrorMessageConstant = ErrorMessageConstant;

  requestDate: string;
  constructor(private http: HttpClient,
    private commonService: CommonService,
    private raterPremiumService: RaterPremiumService) {

    super();
  }

  ngOnInit() {
    this.datePickerDateOption = {
      dateRange: false,
      dateFormat: 'mm/dd/yyyy'
    };
  }

  processMigration(): void {
    this.isMigrationComplete = false;
    this.migrationResult = [];

    if (this.isFormValid) {
      Utils.blockUI();

      const request = this.getRequest();
      this.raterPremiumService.postGenerateSiebelRater(request).pipe(takeUntil(this.stop$)).subscribe(res => {
        Utils.unblockUI();
        NotifUtils.showSuccess('Migration complete.');

        this.isMigrationComplete = true;
        this.migrationResult = res;
      }, () => {
        Utils.unblockUI();
        NotifUtils.showError('Something went wrong.');
      });
    }
  }

  reformatRequestDate(requestDate?): string {
    const splitRequestDate = requestDate.split('/');
    return `${splitRequestDate[2]}-${splitRequestDate[0]}-${splitRequestDate[1]}`;
  }

  get isFormValid(): boolean {
    const isvalid =
      (this.frmGrp.get('dateFrom').value !== null && this.frmGrp.get('dateTo').value !== null) ||
      !!this.frmGrp.get('policyNumber').value;

    return isvalid;
  }

  getRequest(): SiebelGenerateRequestDTO {
    const request: SiebelGenerateRequestDTO = {
      dateFrom: this.frmGrp.get('dateFrom').value?.singleDate?.jsDate,
      dateTo: this.frmGrp.get('dateTo').value?.singleDate?.jsDate,
      policyNumber: this.frmGrp.get('policyNumber').value,
    };

    return request;
  }
}
