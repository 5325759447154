import { Injectable } from '@angular/core';
import { BindAndIssueData } from '../../../../modules/submission-management/data/bind-and-issue.data';
import { EntityRiskData } from '../../../../modules/submission-management/data/entity-risk.data';
import { Pages } from '../../../../shared/constants/pages-and-sections.constants';
import { GeneralValidationService } from './general-validation.service';

@Injectable({
  providedIn: 'root'
})
export class BindAndIssueValidationService {

  // #region =====> Bind and Issue Page
  billingSection: boolean;
  billingSectionInvalidControls = [];
  signatureSection: boolean;
  signatureSectionInvalidControls = [];
  paperlessDocumentSection: boolean;
  paperlessDocumentSectionInvalidControls = [];

  get BillingForm() {
    return this.bindAndIssueData.billingForm;
  }

  get SignatureForm() {
    return this.bindAndIssueData.signatureForm;
  }

  get paperlessDocumentsForm() {
    return this.bindAndIssueData.paperlessDocumentForm;
  }

  get isRenewalTerm(): boolean {
    return Boolean(this.entityRiskData?.EntityRisk?.risks[0]?.renewalCode);
  }

// #endregion
  constructor(
    protected bindAndIssueData: BindAndIssueData,
    protected generalValidationService: GeneralValidationService,
    public entityRiskData: EntityRiskData
    ) { }

  checkBindAndIssuePage(): void {
    this.billingSection = this.checkBillingSection();
    this.signatureSection = this.checkSignatureSection();
    this.paperlessDocumentSection = this.checkPaperlessDocumentSection();

    this.billingSectionInvalidControls = this.isRenewalTerm ? [] : this.generalValidationService.findInvalidControls(this.BillingForm);
    this.signatureSectionInvalidControls = this.isRenewalTerm ? [] : this.generalValidationService.findInvalidControls(this.SignatureForm);
    this.paperlessDocumentSectionInvalidControls = this.isRenewalTerm ? [] : this.generalValidationService.findInvalidControls(this.paperlessDocumentsForm);

    this.generalValidationService.mapToInvalidFormControlList(this.billingSectionInvalidControls, Pages.BindAndIssue.pageName, Pages.BindAndIssue.sections.Billing);
    this.generalValidationService.mapToInvalidFormControlList(this.signatureSectionInvalidControls, Pages.BindAndIssue.pageName, Pages.BindAndIssue.sections.Signature);
    this.generalValidationService.mapToInvalidFormControlList(this.paperlessDocumentSectionInvalidControls, Pages.BindAndIssue.pageName, Pages.BindAndIssue.sections.PaperlessDocuments);
  }

  checkBillingSection(): boolean {
    if (this.BillingForm.status === 'DISABLED') {
      return true;
    } else {
      return this.isRenewalTerm ? true : this.BillingForm.valid;
    }
  }

  checkSignatureSection(): boolean {
    if (this.SignatureForm.status === 'DISABLED') {
      return true;
    } else {
      return this.isRenewalTerm ? true : this.SignatureForm.valid;
    }
  }

  checkPaperlessDocumentSection(): boolean {
    if (this.paperlessDocumentsForm.status === 'DISABLED') {
      return true;
    } else {
      return this.isRenewalTerm ? true : this.paperlessDocumentsForm.valid;
    }
  }
}