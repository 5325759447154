import { Component, ComponentFactoryResolver, Input, OnChanges, OnInit } from '@angular/core';
import { WindMitigationLabelConstants } from '../../../../../shared/constants/property.labels.constants';
import { SubmissionPageData } from '../../../../../modules/submission-management/data/submission-page.data';
import { PropertyData } from '../../../data/property.data';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { PolicySummaryData } from '../../../../policy-management/data/policy-summary.data';
import FormUtils from 'app/shared/utilities/form.utils';
import { GenericConstants } from '../../../../../shared/constants/generic.constants';
import { EntityRiskData } from '../../../../../modules/submission-management/data/entity-risk.data';
import { BaseClass } from 'app/shared/base-class';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'app/core/services/auth.service';

@Component({
  selector: 'app-wind-mitigation',
  templateUrl: './wind-mitigation.component.html',
  styleUrls: ['./wind-mitigation.component.scss']
})
export class WindMitigationComponent extends BaseClass implements OnInit, OnChanges {
  public WindMitigationLabelConstants = WindMitigationLabelConstants;
  public ErrorMessageConstant = ErrorMessageConstant;
  public FormUtils = FormUtils;
  GenericLabel = GenericLabel;
  GenericConstants = GenericConstants;
  isOpen: boolean = true;
  @Input() userType: string;
  showInternal: boolean;

  constructor(
    public propertyData: PropertyData,
    public submissionData: SubmissionPageData,
    public policySummaryData: PolicySummaryData,
    protected entityRiskData: EntityRiskData,
    protected authService: AuthService
  ) {
    super();
  }

    get riskId() {
      return this.entityRiskData.getRiskId();
    }

  get PropertyDetailsForm() {
    return this.propertyData.propertyDetails;
  }

  get WindMitigationForm() {
    return this.propertyData.windMitigation;
  }

  get isRoofDeckRoofWallWindMitigationRequired(): boolean {
    return Number(this.PropertyDetailsForm.get('yearBuilt').value) < 2002;
  }

  get isDateofInspectionRequired(): boolean {
    return this.WindMitigationForm.get('mitigationForm').value === 'OR';
  }

  get UATCNTR910_Logic(): boolean {
    return this.authService.userType.value === 'external' && (this.policySummaryData.isPolicy || this.policySummaryData.isRenewal());
  }

  get isSubmission(): boolean {
    return !this.policySummaryData.isPolicy && !this.policySummaryData.isRenewal();
  }

  ngOnInit() {
    this.initData();
  }

  initData(): void {
    if (this.entityRiskData.apiCallCompleted.value) {
      this.subcribeToFormChanges();
      return;
    }

    this.entityRiskData.apiCallCompleted.pipe(takeUntil(this.stop$)).subscribe(result => {
      if (result) this.subcribeToFormChanges();
    });
  }

  subcribeToFormChanges(): void {
    this.WindMitigationForm.get('mitigationForm').valueChanges.pipe(takeUntil(this.stop$)).subscribe(result => {
      this.propertyData.updateWindMitigationFormValidity();
    });
  }

  ngOnChanges() {
    this.showInternal = this.userType.toLowerCase() === 'external' ? false : true;
  }

  collapse() {
    this.isOpen = !this.isOpen;
    this.submissionData.isWindMitigationOpen = this.isOpen;
  }


  checkInternalPolicyDropdownControls(): boolean {
    const status = !this.policySummaryData.isPolicyPage(this.riskId) ? true :
      (this.policySummaryData.isEditPolicy && this.showInternal) ? false : true;
    return status;
  }
}
