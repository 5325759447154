export const QuickQuotesLabelConstans = {
    applicantPageLabel : {
        firstName: 'First Name',
        middleName: 'Middle Name',
        lastName: 'Last Name',
        dateOfBirth: 'Date of Birth',
        socialSecurity: 'Social Security',
        effectiveDate: 'Effective Date',
        retired: 'Retired?',
        streetAddress: 'Street Address',
        aptUnitNum: 'Apt/Unit #',
        zipCode: 'Zip Code',
        city: 'City',
        state: 'State',
        doYouWantoOrderCredit: 'Do you want to order Credit?',
        orderPrefill: 'Order Prefill',
        pleaseSelect: 'Please Select',
        suffix: 'Suffix',
        screenScrapeSource: 'Screen Scrape Source',
        maritalStatus: 'Marital Status'
    },
    propertyPageLabel : {
        yearBuilt: 'Year Built',
        protectionClass: 'Protection Class',
        exteriorWallFinish: 'Exterior Wall Finish',
        constructionType: 'Construction',
        squareFootage: 'Square Footage',
        roofMaterial: 'Roof Material',
        flatTile: 'Flat Tile?',
        yearRoofInstalled: 'Year Roof Installed',
        pleaseSelect: 'Please Select',
        numberOfStories: 'Number of Stories',
        numberOfFloorsAboveUnit: 'Number of Floors Above Unit'
    },
    coveragesPageLabel : {
        coverageA: 'Coverage A',
        allOtherPerilsDeductible: 'All other Perils Deductible',
        hurricaneDeductible: 'Hurricane Deductible',
        pleaseSelect: 'Please Select',
        coverageC: 'Coverage C'
    },
    priorInsurancePageLabel : {
        priorInsurance: 'Prior Insurance?',
        priorLiabilityLimit: 'Prior Liability Limit',
        priorCarrier: 'Prior Carrier',
        priorExpirationDate: 'Prior Expiration Date'
    }
};

export const QuickQuoteFormConstant = {
    Property : {
        YearBuilt: 'yearBuilt',
        YearRoof : 'yearRoof'
    },
    Coverages: {
        Monroe: 'monroe',
        Excluded: 'E'
    }
};