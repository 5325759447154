import { FloodDeductible } from './default-list-values/flood-deductible';


export const LvRaterOtherPerilDeductibleNHR = [{
    tableName: 'LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '500',
    description: '$500',
    isActive: true
},
{
    tableName: 'LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '1000',
    description: '$1,000',
    isActive: true
},
{
    tableName: 'LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '2500',
    description: '$2,500',
    isActive: true
},
{
    tableName: 'LvRaterOtherPerilDeductibleNHR',
    tableDescription: 'Other Perils (NHR) Deductible',
    code: '5000',
    description: '$5,000',
    isActive: true
}];

export const LvRaterDeductibleHUR = [{
    tableName: 'LvRaterDeductibleHUR',
    tableDescription: 'HUR Deductible',
    code: '2',
    description: '2%',
    isActive: true,
    isHO3: true,
    isHO4: false,
    isHO6: false,
    isDP3: true,
    isPercentage: true
},
{
    tableName: 'LvRaterDeductibleHUR',
    tableDescription: 'HUR Deductible',
    code: '5',
    description: '5%',
    isActive: true,
    isHO3: true,
    isHO4: false,
    isHO6: false,
    isDP3: true,
    isPercentage: true
},
{
    tableName: 'LvRaterDeductibleHUR',
    tableDescription: 'HUR Deductible',
    code: '10',
    description: '10%',
    isActive: true,
    isHO3: true,
    isHO4: false,
    isHO6: false,
    isDP3: true,
    isPercentage: true
},
{
    tableName: 'LvRaterDeductibleHUR',
    tableDescription: 'HUR Deductible',
    code: '500',
    description: '$500',
    isActive: true,
    isHO3: true,
    isHO4: true,
    isHO6: true,
    isDP3: true,
    isPercentage: false
},
{
  tableName: 'LvRaterDeductibleHUR',
  tableDescription: 'HUR Deductible',
  code: '1000',
  description: '$1,000',
  isActive: true,
  isHO3: false,
  isHO4: true,
  isHO6: true,
  isDP3: false,
  isPercentage: false
},
{
  tableName: 'LvRaterDeductibleHUR',
  tableDescription: 'HUR Deductible',
  code: '2500',
  description: '$2,500',
  isActive: true,
  isHO3: false,
  isHO4: true,
  isHO6: true,
  isDP3: false,
  isPercentage: false
},
{
  tableName: 'LvRaterDeductibleHUR',
  tableDescription: 'HUR Deductible',
  code: '5000',
  description: '$5,000',
  isActive: true,
  isHO3: false,
  isHO4: true,
  isHO6: true,
  isDP3: false,
  isPercentage: false
},
{
  tableName: 'LvRaterDeductibleHUR',
  tableDescription: 'HUR Deductible',
  code: 'E',
  description: 'Excluded',
  isActive: true,
  isHO3: true,
  isHO4: true,
  isHO6: true,
  isDP3: true,
  isPercentage: false
}];

export const LvRaterSecuredCommunity = [
    {
      tableName: 'LvRaterSecuredCommunity',
      tableDescription: 'Secure Community',
      code: 'D',
      description: '24-hour security patrol',
      isActive: true
    },
    {
      tableName: 'LvRaterSecuredCommunity',
      tableDescription: 'Secure Community',
      code: 'C',
      description: 'Single entry into subdivision',
      isActive: true
    },
    {
      tableName: 'LvRaterSecuredCommunity',
      tableDescription: 'Secure Community',
      code: 'A',
      description: 'All entrances manned 24 hrs',
      isActive: true,
    },
    {
      tableName: 'LvRaterSecuredCommunity',
      tableDescription: 'Secure Community',
      code: 'B',
      description: 'All entrances passkey entry',
      isActive: true
    },
    {
      tableName: 'LvRaterSecuredCommunity',
      tableDescription: 'Secure Community',
      code: 'N',
      description: 'None applicable',
      isActive: true
    }
];

export const LvRaterFireProtection = [
    {
      tableName: 'LvRaterFireProtection',
      tableDescription: 'Fire Protection',
      code: '2',
      description: 'Central Station Reporting',
      isActive: true
    },
    {
      tableName: 'LvRaterFireProtection',
      tableDescription: 'Fire Protection',
      code: '3',
      description: 'Fire Station Reporting',
      isActive: true
    },
    {
      tableName: 'LvRaterFireProtection',
      tableDescription: 'Fire Protection',
      code: '4',
      description: 'Smoke Detectors',
      isActive: true
    },
    {
      tableName: 'LvRaterFireProtection',
      tableDescription: 'Fire Protection',
      code: '1',
      description: 'None',
      isActive: true
    }
];

export const LvRaterBurglarProtection = [
    {
      tableName: 'LvRaterBurglarProtection',
      tableDescription: 'Burglar Protection',
      code: '2',
      description: 'Central Station Reporting',
      isActive: true
    },
    {
      tableName: 'LvRaterBurglarProtection',
      tableDescription: 'Burglar Protection',
      code: '4',
      description: 'Police Station Reporting',
      isActive: true
    },
    {
      tableName: 'LvRaterBurglarProtection',
      tableDescription: 'Burglar Protection',
      code: '3',
      description: 'Local Alarm',
      isActive: true
    },
    {
      tableName: 'LvRaterBurglarProtection',
      tableDescription: 'Burglar Protection',
      code: '1',
      description: 'None',
      isActive: true
    }
];

export const LvRaterSprinkler = [
    {
      tableName: 'LvRaterSprinkler',
      tableDescription: 'Sprinkler List',
      code: 'FU',
      description: 'Full',
      isActive: true
    },
    {
      tableName: 'LvRaterSprinkler',
      tableDescription: 'Sprinkler List',
      code: 'PA',
      description: 'Partial',
      isActive: true
    },
    {
      tableName: 'LvRaterSprinkler',
      tableDescription: 'Sprinkler List',
      code: 'NO',
      description: 'None',
      isActive: true
}];

export const LvElevationCertificate = [
    {
      tableName: 'LvElevationCertificate',
      tableDescription: 'Elevation Certificate',
      code: 'D',
      description: 'NFIP Dec',
      isActive: true
    },
    {
      tableName: 'LvElevationCertificate',
      tableDescription: 'Elevation Certificate',
      code: 'N',
      description: 'No',
      isActive: true
    },
    {
      tableName: 'LvElevationCertificate',
      tableDescription: 'Elevation Certificate',
      code: 'Y',
      description: 'Yes',
      isActive: true
    }
];

export const LvFloodPolicyDuringPolicyPeriod = [
  {
    tableName: 'LvFloodPolicyDuringPolicyPeriod',
    tableDescription: 'Flood Policy During the Policy Period',
    code: 'FP1',
    description: 'Yes',
    isActive: true
  },
  {
    tableName: 'LvFloodPolicyDuringPolicyPeriod',
    tableDescription: 'Flood Policy During the Policy Period',
    code: 'FP0',
    description: 'No',
    isActive: true
  },
  {
    tableName: 'LvFloodPolicyDuringPolicyPeriod',
    tableDescription: 'Flood Policy During the Policy Period',
    code: 'FP2',
    description: 'Flood Rejection Form',
    isActive: true
  }
];

export const LvFloodDeductible = [
  FloodDeductible.FiveHundred,
  FloodDeductible.OneThousand,
  FloodDeductible.TwoThousandFiveHundred,
  FloodDeductible.FiveThousand,
  FloodDeductible.SevenThousandFiveHundred,
  FloodDeductible.TenThousand
];

export const LvParticipationStatus = [
  {
    tableName: 'LvParticipationStatus',
    tableDescription: 'Participation Status',
    code: 'PS0',
    description: 'Defunct Community (Ghost Town)',
    isActive: true
  },
  {
    tableName: 'LvParticipationStatus',
    tableDescription: 'Participation Status',
    code: 'PS1',
    description: 'Emergency Program',
    isActive: true
  },
  {
    tableName: 'LvParticipationStatus',
    tableDescription: 'Participation Status',
    code: 'PS2',
    description: 'Not an NFIP Community',
    isActive: true
  },
  {
    tableName: 'LvParticipationStatus',
    tableDescription: 'Participation Status',
    code: 'PS3',
    description: 'Not participating in program - FEMA map',
    isActive: true
  },
  {
    tableName: 'LvParticipationStatus',
    tableDescription: 'Participation Status',
    code: 'PS4',
    description: 'Not participating in program - no FEMA map',
    isActive: true
  },
  {
    tableName: 'LvParticipationStatus',
    tableDescription: 'Participation Status',
    code: 'PS5',
    description: 'Participating - On probation',
    isActive: true
  },
  {
    tableName: 'LvParticipationStatus',
    tableDescription: 'Participation Status',
    code: 'PS6',
    description: 'Regular Program',
    isActive: true
  },
  {
    tableName: 'LvParticipationStatus',
    tableDescription: 'Participation Status',
    code: 'PS7',
    description: 'Suspended from program participation',
    isActive: true
  },
  {
    tableName: 'LvParticipationStatus',
    tableDescription: 'Participation Status',
    code: 'PS8',
    description: 'Withdrawn from program participation',
    isActive: true
  }
];

export const ParticipationStatusConstants = {
  regularProgram: 'PS6'
};

export const LvEstimatedCredit = [
  {
    tableName: 'LvEstimatedCredit',
    tableDescription: 'Estimated Credit',
    code: 'EC5',
    description: 'Excellent',
    isActive: true
  },
  {
    tableName: 'LvEstimatedCredit',
    tableDescription: 'Estimated Credit',
    code: 'EC4',
    description: 'Very Good',
    isActive: true
  },
  {
    tableName: 'LvEstimatedCredit',
    tableDescription: 'Estimated Credit',
    code: 'EC3',
    description: 'Average',
    isActive: true
  },
  {
    tableName: 'LvEstimatedCredit',
    tableDescription: 'Estimated Credit',
    code: 'EC2',
    description: 'Below Average',
    isActive: true
  },
  {
    tableName: 'LvEstimatedCredit',
    tableDescription: 'Estimated Credit',
    code: 'EC1',
    description: 'Poor',
    isActive: true
  },
  {
    tableName: 'LvEstimatedCredit',
    tableDescription: 'Estimated Credit',
    code: 'EC0',
    description: 'No Score',
    isActive: true
  }
];

export const LvEstimatedCreditIndex = {
  Excellent: 0,
  VeryGood: 1,
  Average: 2,
  BelowAverage: 3,
  Poor: 4,
  NoScore: 5
};

export const LvCreditOrderStatus = [
  {
    id: 1,
    tableName: 'LvCreditOrderStatus',
    tableDescription: 'Credit Order Status',
    code: 'COS0',
    description: 'Not Ordered',
    isActive: true
  },
  {
    id: 2,
    tableName: 'LvCreditOrderStatus',
    tableDescription: 'Credit Order Status',
    code: 'COS1',
    description: 'Ordered - No Hit',
    isActive: true
  },
  {
    id: 3,
    tableName: 'LvCreditOrderStatus',
    tableDescription: 'Credit Order Status',
    code: 'COS2',
    description: 'Ordered - No Score',
    isActive: true
  },
  {
    id: 4,
    tableName: 'LvCreditOrderStatus',
    tableDescription: 'Credit Order Status',
    code: 'COS3',
    description: 'Ordered - Score Received',
    isActive: true
  }
];

export const LvCreditOrderStatusIndex = {
  NotOrdered: 0,
  NoHit: 1,
  NoScore: 2,
  ScoreReceived: 3
};

export const LvFinancialResponsibility = [
  {
    code: '0',
    description: '0',
    isActive: true
  },
  {
    code: '10',
    description: '10',
    isActive: true
  },
  {
    code: '20',
    description: '20',
    isActive: true
}];

export const LvCreditClaimTier = () => {
  const tiers: number[] = [];
  for (let index = 0; index < 25; index++) {
    const firstTier: number = 5;
    const tier: number = index + firstTier;
    tiers.push(tier);
  }
  return tiers;
};

export const LvCreditScoreBrakcets = [
  { tier: 5, ranges: [{ min: 876, max: 999}] },
  { tier: 6, ranges:[{ min: 826, max: 875}]},
  { tier: 7, ranges:[{ min: 801, max: 825}]},
  { tier: 8, ranges: [{ min: 776, max: 800}, { min: 876, max: 999}]},
  { tier: 9, ranges: [{ min: 826, max: 875}, { min: 876, max: 999}]},
  { tier: 10, ranges: [{ min: 751, max: 775}, { min: 801, max: 825}, { min: 826, max: 875}]},
  { tier: 11, ranges: [{ min: 726, max: 750}, { min: 776, max: 800}, { min: 801, max: 825}]},
  { tier: 12, ranges:[{ min: 776, max: 800}]},
  { tier: 13, ranges: [{ min: 701, max: 725}, { min: 751, max: 775}]},
  { tier: 14, ranges: [{ min: 726, max: 750}, { min: 751, max: 775}]},
  { tier: 15, ranges: [{ min: 726, max: 750}]},
  { tier: 16, ranges: [{ min: 676, max: 700}]},
  { tier: 17, ranges: [{ min: 651, max: 675}, { min: 701, max: 725}]},
  { tier: 18, ranges: [{ min: 626, max: 650}, { min: 676, max: 700}, { min: 701, max: 725}]},
  { tier: 19, ranges: [{ min: 601, max: 625}]},
  { tier: 20, ranges: [{ min: 651, max: 675}, { min: 676, max: 700}]},
  { tier: 21, ranges: [{ min: 576, max: 600}, { min: 626, max: 650}, , { min: 651, max: 675}]},
  { tier: 22, ranges: [{ min: 551, max: 575}, { min: 601, max: 625}]},
  { tier: 23, ranges: [{ min: 0, max: 550}, { min: 626, max: 650}]},
  { tier: 24, ranges: [{ min: 576, max: 600}, { min: 601, max: 625}]},
  { tier: 25, ranges: [{ min: 551, max: 575}]},
  { tier: 26, ranges: [{ min: 576, max: 600}]},
  { tier: 27, ranges: [{ min: 551, max: 575}]},
  { tier: 28, ranges: [{ min: 0, max: 550}]},
  { tier: 29, ranges: [{ min: 0, max: 550}]},
];