import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-quickquote',
  templateUrl: './quick-quote.component.html',
  styleUrls: ['./quick-quote.component.scss']
})
export class QuickQuoteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
