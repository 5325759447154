import { Component, EventEmitter, OnInit } from '@angular/core';
import { BaseClass } from '../../../../../../shared/base-class';
import { BsModalRef } from 'ngx-bootstrap';
import { SuspendedPaymentsData } from '../../../../../../modules/payments/data/suspended-payments.data';
import { FileUploadConstants } from '../../../../../../shared/constants/file-upload.constants';
import { FileUploadService } from '../../../../../../shared/services/file-upload.service';

@Component({
  selector: 'app-upload-ipx-modal',
  templateUrl: './upload-ipx-modal.component.html',
  styleUrls: ['./upload-ipx-modal.component.scss']
})
export class UploadIpxModalComponent extends BaseClass implements OnInit {

  fileUploadConstants = FileUploadConstants;

  constructor(private suspendedPaymentData: SuspendedPaymentsData,
    public bsModalRef: BsModalRef,
    public fileUploadService: FileUploadService) {
      super();
     }

  fileLabel: string = this.fileUploadConstants.noFileChosen;

  get isInValidFileType(): boolean {
    return this.fileUploadService.isUploadedCSVExcelFileTypeInvalid(this.fileLabel);
  }

  ngOnInit() {
    this.suspendedPaymentData.ipxFile = null;
  }

  onChoosingFile(event) {
    this.suspendedPaymentData.ipxFile = event.item(0);
    this.fileLabel = this.suspendedPaymentData.ipxFile?.name;
  }

  onUploadIpx() {
    this.bsModalRef.hide();
    this.suspendedPaymentData.uploadIpx();
  }

  hideModal() {
    this.bsModalRef.hide();
  }

}