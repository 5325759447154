import { Injectable } from '@angular/core';
import { QuickQuoteService } from '../../../core/services/submission/quick-quote.service';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { SummaryData } from './summary.data';
import { BaseClass } from '../../../shared/base-class';
import Utils from '../../../shared/utilities/utils';
import { UwQuestionsData } from './uw-questions.data';
import { RiskUWQuestionDTO } from '../../../shared/models/data/dto/quick-quote/risk-uw-question.dto';
import { SaveUwQuestionDTO } from '../../../shared/models/data/dto/quick-quote/save-uw-question.dto';
import { FormGroup } from '@angular/forms';
import { PathConstants } from '../../../shared/constants/path.constants';
import { InfoMessageConstant } from '../../../shared/constants/info-message.constants';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { SubmissionPageData } from 'app/modules/submission-management/data/submission-page.data';
import { updateUWQuestionsIsLoadingFromUWQuestionsSavingData } from '../../../store/uw-questions/uw-questions.actions';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class UwQuestionsSavingData extends BaseClass {

    constructor(
      protected summaryData: SummaryData,
      protected quickQuoteService: QuickQuoteService,
      protected uwQuestionData: UwQuestionsData,
      protected submissionData: SubmissionPageData,
      protected store: Store
    ) {
      super();
    }

    public riskUWQUestions: RiskUWQuestionDTO[] = [];
    textControl = ['Explain', 'Describe', 'AddPolNumber', 'EstCompleDate', 'PleaseDescibe', 'IsMoreThanOneUnit', 'EverRentLease', 'ForRentLease'];
    valid: string = 'VALID';

    saveUWQuestions() {
      const riskId = this.summaryData.SummaryForm.get('riskId').value;
      const riskDetailId = this.summaryData.SummaryForm.get('riskDetailId').value;

      if (riskId && riskDetailId) {
        this.riskUWQUestions = [];
        this.setUWQuestionPayload(riskDetailId, this.uwQuestionData.eligibilityGroup);
        this.setUWQuestionPayload(riskDetailId, this.uwQuestionData.generalQuestionsGroup);

        const uwQuestions: SaveUwQuestionDTO = {
          riskId: riskId,
          riskDetailId: riskDetailId,
          riskUWQuestions: this.riskUWQUestions
        };

        this.store.dispatch(updateUWQuestionsIsLoadingFromUWQuestionsSavingData({ isLoading: true }));

        if (this.validateGeneralQuestions()) {
          Utils.blockUI();
          this.quickQuoteService.postUWQuestions(uwQuestions).pipe(takeUntil(this.stop$)).subscribe(res => {
              this.saveUWQuestionResponseID(res);

              if (this.summaryData.raterData.isViewQuoteProposalClicked) {
                this.summaryData.raterData.recalculatePremiumAndSave$.next(true);
                this.summaryData.raterData.isViewQuoteProposalClicked = false;
              }

              this.submissionData.uwQuestionsSavingComplete.next();
              this.store.dispatch(updateUWQuestionsIsLoadingFromUWQuestionsSavingData({ isLoading: false }));
              Utils.unblockUI();
              NotifUtils.showConsoleSuccess(`${PathConstants.Submission.Submission.UWQuestions} ${InfoMessageConstant.savedSuccessfullyMessage}`);
            },
            err => {
              Utils.unblockUI();
              this.store.dispatch(updateUWQuestionsIsLoadingFromUWQuestionsSavingData({ isLoading: false }));
              console.log(err);
              NotifUtils.showError(JSON.parse(err.error).errors.toString());
              // TODO: Error handling item on API
            }
          );
        } else {
          if (this.summaryData.raterData.isViewQuoteProposalClicked) {
            this.summaryData.raterData.recalculatePremiumAndSave$.next(true);
            this.summaryData.raterData.isViewQuoteProposalClicked = false;
            this.store.dispatch(updateUWQuestionsIsLoadingFromUWQuestionsSavingData({ isLoading: false }));
          }
        }
      }
    }

    setUWQuestionPayload(riskDetailId: string, formGroup: FormGroup) {
      Object.keys(formGroup.controls).forEach((key: string) => {
        const abstractControl = formGroup.get(key);
        if (abstractControl instanceof FormGroup) {
          this.setUWQuestionPayload(riskDetailId, abstractControl);
        } else {
          if (abstractControl.value) {
            const uwQuestionData = new RiskUWQuestionDTO();
            // uwQuestionData.id = this.uwQuestionData.responseId[key] || null;
            uwQuestionData.riskDetailId = riskDetailId;
            uwQuestionData.uwQuestionCode = key;
            if (this.textControl.includes(key)) {
              if (key === 'EstCompleDate') {
                const formattedDate = new DatePipe('en-US').transform(new Date(abstractControl.value?.singleDate?.jsDate).toLocaleDateString('en-US'), 'MM/dd/yyyy');
                uwQuestionData.uwQuestionValue = formattedDate;
              } else {
                uwQuestionData.uwQuestionValue = abstractControl.value;
              }
            }
            this.riskUWQUestions.push(uwQuestionData);
          }
        }
      });
    }

    saveUWQuestionResponseID(response: any): void {
      const riskUWQuestions = response?.riskUWQuestions;
      if (riskUWQuestions) {
        riskUWQuestions.forEach(element => {
          this.uwQuestionData.responseId[element.uwQuestionCode] = element.id;
        });
      }
    }

    checkRequiredField() {
      if (this.uwQuestionData.eligibilityGroup.get('Explain')?.status !== this.valid) {
        this.uwQuestionData.eligibilityGroup.get('Explain').markAsTouched();
      }
      if (this.uwQuestionData.eligibilityGroup.get('Describe')?.status !== this.valid) {
        this.uwQuestionData.eligibilityGroup.get('Describe').markAsTouched();
      }
      if (this.uwQuestionData.generalQuestionsGroup.get('AddPolNumber')?.status !== this.valid) {
        this.uwQuestionData.generalQuestionsGroup.get('AddPolNumber').markAsTouched();
      }
      if (this.uwQuestionData.generalQuestionsGroup.get('EstCompleDate')?.status !== this.valid) {
        this.uwQuestionData.generalQuestionsGroup.get('EstCompleDate').markAsTouched();
      }
      if (this.uwQuestionData.generalQuestionsGroup.get('PleaseDescibe')?.status !== this.valid) {
        this.uwQuestionData.generalQuestionsGroup.get('PleaseDescibe').markAsTouched();
      }
      if (this.uwQuestionData.generalQuestionsGroup.get('IsMoreThanOneUnit')?.status !== this.valid) {
        this.uwQuestionData.generalQuestionsGroup.get('IsMoreThanOneUnit').markAsTouched();
      }
      if (this.uwQuestionData.eligibilityGroup.get('EverRentLease')?.status !== this.valid) {
        this.uwQuestionData.eligibilityGroup.get('EverRentLease').markAsTouched();
      }
      if (this.uwQuestionData.eligibilityGroup.get('ForRentLease')?.status !== this.valid) {
        this.uwQuestionData.eligibilityGroup.get('ForRentLease').markAsTouched();
      }
    }

    //#region =====> Added to avoid error prompt in IsMoreThatOneUnit, Please see Line 56. The Validation.
    validateGeneralQuestions(): boolean {
      return this.uwQuestionData.generalQuestionsGroup.get('AnyOthResApplicant').valid
      && this.uwQuestionData.generalQuestionsGroup.get('DoesAppHaveInsurance').valid
      && this.uwQuestionData.generalQuestionsGroup.get('IsBldgUnConsRenov').valid;
    }
    //#endregion
}