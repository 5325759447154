import { Injectable } from '@angular/core';
import { RefundRequestListDTO } from '../../../shared/models/data/dto/refund/refund-request-list.dto';
import { BaseClass } from '../../../shared/base-class';
import { LvRefundTo, LvRequestStatus, LvDeliveryType, LvRefundRequestPolicyOrigin } from '../../../shared/constants/refund.options.constants';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { PaymentPage } from '../../../shared/enum/payment-page.enum';
import Utils from '../../../shared/utilities/utils';
import { BillingService } from '../../../core/services/billing/billing.service';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { PagerService } from '../../../core/services/pagerService';
import { LvPayPlanOptions } from '../../../shared/constants/billing.options.constants';
import { RefundLabelsConstants, RefundRequestNameAndAddressLimits, RefundPayeeFieldNames } from '../../../shared/constants/refund.labels.constants';
import { UpdateRefundRequestDTO } from '../../../shared/models/data/dto/refund/save-refund-request.dto';
import { RefundPayeeDTO } from '../../../shared/models/data/dto/refund/refund-payee.dto';
import { RemoveManualReturnRequestDTO } from '../../../shared/models/data/dto/billing/remove-manual-return.dto';
import { RefundRequestListRequestDTO } from '../../../shared/models/data/dto/refund/refund-request-list-request.dto';
import { RefundRequestListResponseDTO } from '../../../shared/models/data/dto/refund/refund-request-list-response.dto';
import { LvCountries } from '../../../shared/constants/applicant.options.constants';
import { updateRefundRequestIsLoadingFromRefundData } from '../../../store/refund-request/refund-request.actions';
import { Store } from '@ngrx/store';
import { PolicyBillingLabelsConstants } from '../../../shared/constants/policy-billing.labels.constants';

@Injectable()
export class RefundData extends BaseClass {
  refundRequestListNotifier = new Subject<{ pageType: PaymentPage; refundRequestList: RefundRequestListDTO[]; isCompleted: boolean; isError: boolean }>();
  refundRequestList: RefundRequestListDTO[] = [];
  filteredRefundRequestList: RefundRequestListDTO[] = [];

  refundRequestListPager: any = {};
  refundRequestListPagedItems: RefundRequestListDTO[];

  refundToList: any[];
  refundToListMort: any[];
  requestStatusList: any[];
  deliveryTypeList: any[];
  policyOriginList: any[];

  filterFormGroup: FormGroup;

  refundAmount = new FormControl(''); // this is used for currency format only and to avoid error
  refundToType = new FormControl(''); // this is used to avoid error
  deliveryType = new FormControl(''); // this is used to avoid error

  latestValidRefundAmount: number;
  latestValidRefundToType: string;
  latestValidDeliveryType: string;

  itemPerPage: number = 10;
  currentPage: number = 1;
  totalRowCount: number = 500;

  public NameAndAddressLimit = RefundRequestNameAndAddressLimits;
  public CheckPaymentConstants = PolicyBillingLabelsConstants.checkPayment;
  nameOrAddressExceededMaxLength: boolean = false;

  constructor(
    protected billingService: BillingService,
    protected pagerService: PagerService,
    protected store: Store
  ) {
    super();
    this.updateList();
    this.dropdownValues();
    this.filterFormGroupSection();
  }

  initFormGroup(): void {
    this.filterFormGroup = this.filterFormGroupSection();
  }

  filterFormGroupSection(): FormGroup {
    return new FormGroup({
      requestStatus: new FormControl('A'),
      searchQuery: new FormControl(''),
      overpaymentMin: new FormControl(''),
      overpaymentMax: new FormControl(''),
      policyOrigin: new FormControl('A')
    });
  }

  dropdownValues(): void {
    this.refundToList = LvRefundTo.filter(x => x.code !== 'Mortgagee');
    this.refundToListMort = LvRefundTo;
    this.requestStatusList = LvRequestStatus;
    this.deliveryTypeList = LvDeliveryType.filter(x => x.isActive);
    this.policyOriginList = LvRefundRequestPolicyOrigin.filter(x => x.isActive).sort(x => x.ordering);
  }

  getRefundRequestList(): void {
    this.refundRequestListNotifier.next({ pageType: PaymentPage.RefundRequest, refundRequestList: [], isCompleted: false, isError: false });
    Utils.blockUI();
    const
      requestStatusFilter = this.filterFormGroup.get('requestStatus').value,
      searchQueryFilter = this.filterFormGroup.get('searchQuery').value,
      minimumFilter = Number(this.filterFormGroup.get('overpaymentMin').value),
      maximumFilter = Number(this.filterFormGroup.get('overpaymentMax').value);

    const payload: RefundRequestListRequestDTO = {
      pageNumber: this.currentPage,
      pageSize: this.itemPerPage,
      policyOrigin: this.filterFormGroup.get('policyOrigin').value
    };

    if (requestStatusFilter != RefundLabelsConstants.RefundRequest.RequestStatus.All) payload.requestStatus = requestStatusFilter;
    if (searchQueryFilter) payload.searchQuery = searchQueryFilter;
    if (Boolean(minimumFilter)) payload.minOverpayment = minimumFilter;
    if (Boolean(maximumFilter)) payload.maxOverpayment = maximumFilter;

    this.store.dispatch(updateRefundRequestIsLoadingFromRefundData({ isLoading: true}));

    this.billingService.postRefundRequestList(payload).subscribe((response: RefundRequestListResponseDTO) => {
      this.refundRequestListNotifier.next({ pageType: PaymentPage.RefundRequest, refundRequestList: response.resultList, isCompleted: true, isError: false });
      this.totalRowCount = response.paginationDetails.totalCount;
      this.currentPage = response.paginationDetails.currentPage;
      Utils.unblockUI();
      this.store.dispatch(updateRefundRequestIsLoadingFromRefundData({ isLoading: false }));
    }, err => {
      Utils.unblockUI();
      this.refundRequestListNotifier.next({ pageType: PaymentPage.RefundRequest, refundRequestList: err.error, isCompleted: true, isError: true });
      NotifUtils.showError(JSON.stringify(err.error));
      this.store.dispatch(updateRefundRequestIsLoadingFromRefundData({ isLoading: false }));
    });
  }

  updateList(): void {
    this.refundRequestListNotifier.subscribe(result => {
      switch (result.pageType) {
        case PaymentPage.RefundRequest:
          if (result.isCompleted && !result.isError) {
            this.refundRequestList = result.refundRequestList ? result.refundRequestList : [];
            this.refundRequestList = this.refundRequestList.map(refundRequest => {
              refundRequest.paymentPlan = LvPayPlanOptions.find(paymentPlan => paymentPlan.code === refundRequest.paymentPlan)?.description ?? '';
              refundRequest.deliveryType = this.setDeliveryTypeToCode(refundRequest.deliveryType);

              return refundRequest;
            });
            this.filteredRefundRequestList = this.refundRequestList;
            this.refundRequestListSetPage(1);
          }
          break;
      }
    });
  }

  refundRequestListSetPage(page: number): void {
    if (page < 1) {
      return;
    }
    this.refundRequestListPager = this.pagerService.getPager(this.refundRequestList.length, page);
    this.refundRequestListPagedItems = this.refundRequestList.slice(this.refundRequestListPager.startIndex, this.refundRequestListPager.endIndex + 1);
  }

  filterList(): void {
    this.currentPage = 1;
    this.getRefundRequestList();
  }

  filterByStatus(): void {
    switch (this.filterFormGroup.get('requestStatus').value) {
      case RefundLabelsConstants.RefundRequest.RequestStatus.All:
        this.filteredRefundRequestList = this.refundRequestList;
        break;
      case RefundLabelsConstants.RefundRequest.RequestStatus.Selected:
        this.filteredRefundRequestList = this.refundRequestList.filter(refundRequest => refundRequest.isChecked === true);
        break;
      case RefundLabelsConstants.RefundRequest.RequestStatus.NotSelected:
        this.filteredRefundRequestList = this.refundRequestList.filter(refundRequest => refundRequest.isChecked === false);
        break;
      default:
        this.filteredRefundRequestList = this.refundRequestList;
        break;
    };
  }

  getRefundPayeePayload(refundRequestItem: RefundRequestListDTO): RefundPayeeDTO {
    const countryCode = refundRequestItem.countryCode;

    return {
      name: refundRequestItem.refundPayeeName,
      street1: refundRequestItem.streetAddress1,
      street2: refundRequestItem.streetAddress2,
      city: refundRequestItem.city,
      state: refundRequestItem.state,
      zip: refundRequestItem.zip,
      countryCode: countryCode,
      countryName: LvCountries.find(a => a.value === countryCode)?.name ?? ''
    };
  }

  getRefundRequestPayload(refundRequestItem: RefundRequestListDTO): UpdateRefundRequestDTO {
    return {
      refundRequestId: refundRequestItem.id,
      amount: Number(refundRequestItem.refundAmount),
      refundToTypeId: this.setRefundToTypeCode(refundRequestItem.refundToType),
      deliveryTypeId: refundRequestItem?.deliveryType,
      refundPayee: this.getRefundPayeePayload(refundRequestItem),
      isChecked: refundRequestItem.isChecked
    };
  }

  saveRefundRequest(refundRequestItem: RefundRequestListDTO, refundAmountText: string, refundToTypeText: string, deliveryTypeText: string, isChecked: boolean): void {
    const refundAmount = Number(refundAmountText.replace(/[^0-9\.]+/g, ''));
    const refundToType = refundToTypeText;
    const deliveryType = deliveryTypeText;

    this.latestValidRefundAmount = refundRequestItem.refundAmount;
    this.latestValidRefundToType = refundRequestItem.refundToType;
    this.latestValidDeliveryType = refundRequestItem.deliveryType;

    if (refundToType === this.latestValidRefundToType) {
      Utils.blockUI();
    }

    const mappedRefundRequestItem = this.mapUpdateRefundRequestItemPayload(refundRequestItem, refundAmount, refundToType, deliveryType, isChecked);
    if (refundAmount !== 0) {
      this.billingService.putRefundRequest(this.getRefundRequestPayload(mappedRefundRequestItem)).subscribe((response: any) => {
        this.filteredRefundRequestList = this.filteredRefundRequestList.map(item => {
          if (item.id === response.id) {
            item.refundAmount = response.amount ?? 0;
            item.refundToType = this.setRefundByTypeCode(response.refundToTypeId);
            item.deliveryType = response.deliveryTypeId;
          }
          return item;
        });

        if (refundToType === this.latestValidRefundToType) {
          Utils.unblockUI();
        } else {
          this.getRefundRequestList();
        }
      }, err => {
        Utils.unblockUI();
        NotifUtils.showError(JSON.stringify(err.error));
      });
    } else {
      Utils.unblockUI();
      NotifUtils.showError('Refund Amount should not be equal to 0.', () => {
        this.filteredRefundRequestList = this.filteredRefundRequestList.map(item => {
          if (item.id === refundRequestItem.id) {
            item.refundAmount = this.latestValidRefundAmount;
            item.refundToType = this.latestValidRefundToType;
            item.deliveryType = this.latestValidDeliveryType;
          }
          return item;
        });
      });
    }
  }

  removeManualRefund(item: RefundRequestListDTO): void {
    NotifUtils.showConfirmMessage(RefundLabelsConstants.RefundRequest.Action.RemoveQuestion, () => {
      const request: RemoveManualReturnRequestDTO = {
        riskId: item.riskId,
        refundRequestId: item.id
      };

      Utils.blockUI();
      this.billingService.removeManualRefundRequest(request).subscribe((response: any) => {
        this.getRefundRequestList();
      },
        err => {
          Utils.unblockUI();
          NotifUtils.showMultiLineError(err.error?.message);
        });
    });
  }

  mapUpdateRefundRequestItemPayload(refundRequestItem: RefundRequestListDTO, refundAmount: number, refundToType: string, deliveryType: string, isChecked: boolean): RefundRequestListDTO {
    refundRequestItem.refundAmount = refundAmount;
    refundRequestItem.refundToType = refundToType;
    refundRequestItem.deliveryType = deliveryType;
    refundRequestItem.isChecked = isChecked;

    return refundRequestItem;
  }

  setRefundToTypeCode(value: string): string {
    switch (value) {
      case RefundLabelsConstants.RefundRequest.RefundToType.Insured:
        return RefundLabelsConstants.RefundRequest.RefundToType.InsuredCode;
      case RefundLabelsConstants.RefundRequest.RefundToType.Mortgagee:
        return RefundLabelsConstants.RefundRequest.RefundToType.MortgageeCode;
      case RefundLabelsConstants.RefundRequest.RefundToType.Other:
        return RefundLabelsConstants.RefundRequest.RefundToType.OtherCode;
      case RefundLabelsConstants.RefundRequest.RefundToType.PremCo:
        return RefundLabelsConstants.RefundRequest.RefundToType.PremCoCode;
      default:
        return '';
    }
  }

  setRefundByTypeCode(value: string): string {
    if (Boolean(value)) {
      switch (value) {
        case RefundLabelsConstants.RefundRequest.RefundToType.InsuredCode:
          return RefundLabelsConstants.RefundRequest.RefundToType.Insured;
        case RefundLabelsConstants.RefundRequest.RefundToType.MortgageeCode:
          return RefundLabelsConstants.RefundRequest.RefundToType.Mortgagee;
        case RefundLabelsConstants.RefundRequest.RefundToType.OtherCode:
          return RefundLabelsConstants.RefundRequest.RefundToType.Other;
        case RefundLabelsConstants.RefundRequest.RefundToType.PremCoCode:
          return RefundLabelsConstants.RefundRequest.RefundToType.PremCo;
        default:
          return '';
      }
    }
    return '';
  }

  setDeliveryTypeToCode(value: string): string {
    switch (value) {
      case RefundLabelsConstants.RefundRequest.DeliveryType.Overnight:
        return RefundLabelsConstants.RefundRequest.DeliveryType.OvernightCode;
      case RefundLabelsConstants.RefundRequest.DeliveryType.Restricted:
        return RefundLabelsConstants.RefundRequest.DeliveryType.RestrictedCode;
      case RefundLabelsConstants.RefundRequest.DeliveryType.Certified:
        return RefundLabelsConstants.RefundRequest.DeliveryType.CertifiedCode;
      case RefundLabelsConstants.RefundRequest.DeliveryType.Omaha:
        return RefundLabelsConstants.RefundRequest.DeliveryType.OmahaCode;
      default:
        return RefundLabelsConstants.RefundRequest.DeliveryType.NormalCode;
    }
  }

  getRefundPayeeDetailsForRefundPortal(payeeDetails: RefundRequestListDTO ): RefundPayeeDTO {
    return {
      name: payeeDetails?.refundPayeeName,
      street1: payeeDetails?.streetAddress1,
      street2: payeeDetails?.streetAddress2,
      city: payeeDetails?.city,
      state: payeeDetails?.state,
      zip: payeeDetails?.zip
    };
  }

  getRefundPayeeDetails(form: AbstractControl): RefundPayeeDTO {
    const isIndividual = form.get(RefundPayeeFieldNames.isIndividual).value;
    const firstName = form.get(RefundPayeeFieldNames.firstName)?.value;
    const lastName = form.get(RefundPayeeFieldNames.lastName)?.value;
    const suffix = form.get(RefundPayeeFieldNames.suffix)?.value;
    const fullName = `${firstName}${lastName}${suffix}`;
    const name = isIndividual ? fullName : form.get(RefundPayeeFieldNames.name)?.value;

    return {
      [isIndividual ? RefundPayeeFieldNames.fullName : RefundPayeeFieldNames.name]: name,
      address: form.get(RefundPayeeFieldNames.address).value,
      aptOrUnit: form.get(RefundPayeeFieldNames.aptOrUnit).value,
      city: form.get(RefundPayeeFieldNames.city).value,
      state: form.get(RefundPayeeFieldNames.state).value,
      zip: form.get(RefundPayeeFieldNames.zip).value
    };
  }

  nameOrAddressExceedMaxLength(refundPayeeDetails: RefundPayeeDTO): boolean {
    return  refundPayeeDetails.name?.length > this.NameAndAddressLimit.name ||
            refundPayeeDetails.fullName?.length > this.NameAndAddressLimit.name ||
            refundPayeeDetails.street1?.length > this.NameAndAddressLimit.street1 ||
            refundPayeeDetails.street2?.length > this.NameAndAddressLimit.street2 ||
            refundPayeeDetails.address?.length > this.NameAndAddressLimit.street1 ||
            refundPayeeDetails.aptOrUnit?.length > this.NameAndAddressLimit.street2 ||
            refundPayeeDetails.city?.length > this.NameAndAddressLimit.city ||
            refundPayeeDetails.state?.length > this.NameAndAddressLimit.state ||
            refundPayeeDetails.zip?.length > this.NameAndAddressLimit.zip;
  }

  getProperFieldName(fieldName: string): string {
    if (fieldName === RefundPayeeFieldNames.name) {
      return this.CheckPaymentConstants.name;
    } else if (fieldName === RefundPayeeFieldNames.fullName) {
      return this.CheckPaymentConstants.fullName;
    } else if (fieldName === RefundPayeeFieldNames.street1 || fieldName === RefundPayeeFieldNames.address) {
      return this.CheckPaymentConstants.address;
    } else if (fieldName === RefundPayeeFieldNames.street2 || fieldName === RefundPayeeFieldNames.aptOrUnit) {
      return this.CheckPaymentConstants.aptOrUnit;
    } else if (fieldName === RefundPayeeFieldNames.city) {
      return this.CheckPaymentConstants.city;
    } else if (fieldName === RefundPayeeFieldNames.state) {
      return this.CheckPaymentConstants.state;
    } else if (fieldName === RefundPayeeFieldNames.zip) {
      return this.CheckPaymentConstants.zip;
    } else  {
      return '';
    }
  }

  getFieldLimit(fieldName: string): number {
    if (fieldName === RefundPayeeFieldNames.name || fieldName === RefundPayeeFieldNames.fullName) {
      return this.NameAndAddressLimit.name;
    } else if (fieldName === RefundPayeeFieldNames.street1 || fieldName === RefundPayeeFieldNames.address) {
      return this.NameAndAddressLimit.street1;
    } else if (fieldName === RefundPayeeFieldNames.street2 || fieldName === RefundPayeeFieldNames.aptOrUnit) {
      return this.NameAndAddressLimit.street2;
    } else if (fieldName === RefundPayeeFieldNames.city) {
      return this.NameAndAddressLimit.city;
    } else if (fieldName === RefundPayeeFieldNames.state) {
      return this.NameAndAddressLimit.state;
    } else if (fieldName === RefundPayeeFieldNames.zip) {
      return this.NameAndAddressLimit.zip;
    } else  {
      return 0;
    }
  }

  generateErrorMessage(refundPayeeDetails: RefundPayeeDTO): string[] {
    const invalidFields: string[] = [];

    Object.keys(refundPayeeDetails).forEach((fieldName) => {
      const maxLength = this.getFieldLimit(fieldName);
      const currentLength = refundPayeeDetails[fieldName]?.trim()?.length;

      if (refundPayeeDetails[fieldName]?.length > maxLength) {
        invalidFields.push(`${this.getProperFieldName(fieldName)} ${fieldName === RefundPayeeFieldNames.fullName ? 'fields' : 'field'} total characters of ${currentLength} exceeded the maximum required length of ${maxLength} characters.`);
      }
    });

    return invalidFields;
  }

  checkIfFieldsExceededMaxLength(form: AbstractControl, refundPayeeDetails: RefundPayeeDTO): void {
    const invalidFields: string[] = [];

    Object.keys(refundPayeeDetails).forEach((fieldName) => {
      const maxLength = this.getFieldLimit(fieldName);

      if (refundPayeeDetails[fieldName]?.length > maxLength) {
        invalidFields.push(fieldName);
        this.setMaxLengthErrorForField(form, fieldName);
      } else {
        this.removeMaxLengthErrorFromFields(form, fieldName);
      }
    });

    if (invalidFields.length >= 1) {
      this.nameOrAddressExceededMaxLength = true;
    } else {
      this.nameOrAddressExceededMaxLength = false;
    }
  }

  setMaxLengthErrorForField(form: AbstractControl, fieldName: string): void {
    const control =  form.get(fieldName);

    if (control?.value) {
      control.setErrors({
        maxlength: true
      });

      control.markAsTouched();
      form.updateValueAndValidity();
    }
  }

  getCustomErrorMsg(refundPayeeDetails: RefundPayeeDTO, infoMsg: string): string {
    const errorMsg = this.generateErrorMessage(refundPayeeDetails);
    const msgTemplate = this.generateErrorTemplate(errorMsg, infoMsg);
    return msgTemplate;
  };

  generateErrorTemplate(errors: string[], infoMsg: string): string {
    const containerStyles = 'text-align: center !important; font-size: 14px !important;';
    const errorTextStyles = 'color: red;';
    const infoTextStyles = 'color: blue;';

    let html = `<div style="${containerStyles}">`;

    errors.forEach(error => {
      html += `
        <h6 style="${errorTextStyles}">
          ${error}
        </h6>`;
    });

    html += `
        <h6 style="${infoTextStyles}" class="my-4">
          ${infoMsg}
        </h6>
      </div>`;

    return html;
  }

  removeMaxLengthErrorFromFields(form: AbstractControl, fieldName: string): void {
    const control =  form.get(fieldName);
    
    if(control != null) {
      if(control.hasError('maxlength')) {
        control.setErrors(null);
        control.markAsTouched();;
      }
      form.updateValueAndValidity();
    }
  }
}
