import { Component, Input, OnInit } from '@angular/core';
import { BindAndIssueValidatorErrorConstants } from '../../../../../../../../app/modules/submission-management/validators/validator-error-constants/bind-and-issue-error.constants';
import { BillingLabelConstants, CreditCardTypeConstants } from '../../../../../../../../app/shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from '../../../../../../../../app/shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../../../../app/shared/constants/generic.labels.constants';
import FormUtils from '../../../../../../../../app/shared/utilities/form.utils';
import { BindAndIssueData } from '../../../../../../../../app/modules/submission-management/data/bind-and-issue.data';
import { FormGroup } from '@angular/forms';
import { PaymentMethod } from '../../../../../../../../app/shared/enum/payment-method.enum';

@Component({
  selector: 'app-billing-form-check',
  templateUrl: './billing-form-check.component.html',
  styleUrls: ['./billing-form-check.component.scss']
})
export class BillingFormCheckComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() showNameOnAccount: boolean;

  public FormUtils = FormUtils;
  public BillingLabelConstants = BillingLabelConstants;
  public ErrorMessageConstant = ErrorMessageConstant;
  public CreditCardTypeConstants = CreditCardTypeConstants;
  GenericLabel = GenericLabel;
  isOpen: boolean = true;
  creditCardExpiredErrorMessage = BindAndIssueValidatorErrorConstants.creditCardExpired.value;

  PaymentMethod = PaymentMethod;

  constructor(public bindAndIssueData: BindAndIssueData) { }

  ngOnInit() {
  }

}
