import { createSelector } from '@ngrx/store';
import { BaseState } from '../base.state';

export const selectPolicyUWRsState = (state: BaseState) => state.policyUWRs;

export const selectPolicyUWRsIsLoading = createSelector(
  selectPolicyUWRsState,
  (state) => state.isLoading
)

export const selectPolicyUWRsList = createSelector(
  selectPolicyUWRsState,
  (state) => state.list
)

export const selectPolicyUWRsTableRows = createSelector(
  selectPolicyUWRsState,
  (state) => state.tableRows
)