import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../core/services/navigation/navigation.service';
import { PathConstants } from '../../../shared/constants/path.constants';
import { ClickTypes } from '../../../shared/enum/click-type.enum';
import { IHideTableItems, ITableTd } from '../../../shared/models/dynamic/table.interface';
import { DocumentsLabelConstants } from '../../../shared/constants/bind-and-issue.labels.constants';
import { PolicyDocumentsData } from '../data/policy-documents.data';
import { TableNameConstants } from '../../../shared/constants/table.name.constants';
import { SummaryData } from '../../../modules/submission-management/data/summary.data';
import Utils from '../../../shared/utilities/utils';
import { PolicyDocumentsModalComponent } from './policy-documents-modal/policy-documents-modal.component';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../shared/base-class';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DocumentsConstants } from '../../../shared/constants/documents.constants';
import { PolicyService } from '../../../core/services/submission/policy.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import NotifUtils from '../../../shared/utilities/notif-utils';
import { InfoMessageConstant } from '../../../shared/constants/info-message.constants';
import { AuthService } from '../../../core/services/auth.service';
import *  as fileSaver from 'file-saver';
import { Subject } from 'rxjs-compat';

@Component({
  selector: 'app-policy-documents',
  templateUrl: './policy-documents.component.html',
  styleUrls: ['./policy-documents.component.scss']
})
export class PolicyDocumentsComponent extends BaseClass implements OnInit {

  public DocumentsLabelConstants = DocumentsLabelConstants;
  public TableNameConstants = TableNameConstants;
  InfoMessageConstant = InfoMessageConstant;
  isOpen: boolean = true;
  hideItems: IHideTableItems = {
    checkboxButton: false,
    fileIcon: false,
    viewIcon: true
  };
  selectedTableItems = [];
  modalRef: BsModalRef | null;
  datePipe: DatePipe;
  isloading: boolean = false;
  downloadCompleted$: Subject<boolean> = new Subject<boolean>();

  constructor(protected navigationService: NavigationService,
    public policyDocumentsData: PolicyDocumentsData,
    protected summaryData: SummaryData,
    public policyService: PolicyService,
    protected modalService: BsModalService,
    public authService: AuthService) {
    super();
  }

  ngOnInit(): void {
    this.datePipe = new DatePipe('en-US');
    this.mapDocumentList();
    this.policyDocumentsData.documentsTableRows = [];
    this.initData(this.policyDocumentsData.documentsList);
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }

  mapDocumentList(): void {
    this.policyDocumentsData.documentsTableRows.forEach((item, index) => {
      this.policyDocumentsData.documentsList[index].id = item.id;
    });
  }

  initData(documentsList: any): void {
    documentsList.map(x => {
      const documents = {
        id: x.id,
        description: x.description,
        category: x.category,
        dateAdded: x.dateAdded ? x.dateAdded : x.createdDate,
        fileName: x.fileName,
        riskBindId: x.riskBindId,
        documentsId: x.documentsId ? x.documentsId : x.id,
        File: x.File,
        isUploaded: x.isUploaded,
        isFromParent: x.isFromParent,
        createdBy: x.createdBy,
        isSuppressed: x.isSuppressed
      };
      this.addTableItem(documents);
    });
  }

  updateTableItem(row: any): void {
    this.policyDocumentsData.documentsTableRows.forEach((item) => {
      if (item.id === row.id) {
        item.tr[0].value = row.category;
        item.tr[1].value = row.description;
        // item.tr[2].value = row.file && row.file?.name ? row.file.name : row.file;
        item.tr[2].value = row.dateAdded;
        item.tr[0].display = this.policyDocumentsData.documentsCategoryList.find(cat => cat.code === row.category).description;
        item.tr[1].display = row.description;
        // item.tr[2].display = row.file && row.file?.name ? row.file.name : row.file;
        item.tr[2].display = row.dateAdded?.singleDate.formatted ? this.datePipe.transform(row.dateAdded?.singleDate.formatted, this.policyDocumentsData.dateFormat) : row.dateAdded;
      }
    });

    this.policyDocumentsData.documentsList.forEach((item) => {
      if (item.id === row.id) {
        item.category = row.category;
        item.description = row.description;
        item.fileName = row.fileName;
        item.filePath = row.filePath;
        item.dateAdded = row.dateAdded;
        item.isUploaded = false;
      }
    });
  }

  editTableItem(rowId: any, tableRow: string): void {
    this.policyDocumentsData[tableRow].forEach((item) => {
      if (item.id === rowId) {
        const fileName = this.findFileNameIndDocumentList(item.id);
        this.showEditUploadDocument({
          id: item.id,
          category: item.tr[0].value,
          description: item.tr[1].value,
          fileName: fileName,
          dateAdded: item.tr[2].value
        });
      } else {
        item.edit = false;
      }
    });
  }

  findFileNameIndDocumentList(id: string): string {
    if (id) {
      const document = this.policyDocumentsData.documentsList.find(a => a.id === id);
      if (document){
        return document.fileName;
      }
    }
    return '';
  }

  showEditUploadDocument(model): void {
    const data = model;
    const currentDocument = _.find(this.policyDocumentsData.documentsList, document => document.id === model.id);
    const documents = {
      id: data.id,
      description: data.description,
      category: data.category,
      fileName: data.fileName,
      dateAdded: data.dateAdded,
      isEdit: true,
      filePath: currentDocument.filePath
    };

    const initialState = {
      title: this.DocumentsLabelConstants.editTitle,
      documentsModel: documents,
      isAdd: false,
    };

    this.modalRef = this.modalService.show(PolicyDocumentsModalComponent, {
      initialState,
      backdrop: 'static',
      ignoreBackdropClick: true
    });

    this.modalRef.content.event.pipe(takeUntil(this.stop$)).subscribe((res) => {
      this.updateTableItem(res.data);
    });
  }

  deleteTableItem(rowId: any, tableRow: string): void {
    this.policyDocumentsData[tableRow].forEach((item, index) => {
      if (item.id === rowId) {
        const payload = {
          riskId: this.summaryData.SummaryForm.get('riskId').value,
          riskDetailId: this.summaryData.SummaryForm.get('riskDetailId').value,
          riskBindDocumentId: item.id
        };
        Utils.blockUI();
        this.policyService.deletePolicyDocument(payload).subscribe(res => {
          this.policyDocumentsData[tableRow].splice(index, 1);
          this.policyDocumentsData.documentsList.splice(index, 1);
          this.setPage(1);
          Utils.unblockUI();
        },
          err => {
            Utils.unblockUI();
            console.log(err);
          }
        );
      }
    });
  }

  onFileClick(rowId: any): void{
    const document = this.policyDocumentsData.documentsList.find(d => d.id === rowId);
    const riskId = this.summaryData.SummaryForm.get('riskId').value;

    if (!document.isUploaded) {
      this.policyService.getPolicyDocuments(riskId).pipe(takeUntil(this.stop$)).subscribe(result =>{
        const riskDocument = result.find(d => d.id === document.id);

        if (riskDocument.isUploaded) {
          this.policyService.generateSASURL(Utils.URLEncoder(riskDocument?.filePath, riskDocument?.fileName)).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
            window.open(resultSASURL);
          });
        } else {
          NotifUtils.showInfo(InfoMessageConstant.generationDocumentMessage);
        }
      });
    } else {
      this.policyService.generateSASURL(Utils.URLEncoder(document?.filePath, document?.fileName)).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
        window.open(resultSASURL);
      });
    }
  }

  showUploadDocument(): void {
    const initialState = {
      title: this.DocumentsLabelConstants.addTitle
    };
    this.modalRef = this.modalService.show(PolicyDocumentsModalComponent, {
      initialState,
      backdrop: true,
      ignoreBackdropClick: true,
    });

    this.modalRef.content.event.pipe(takeUntil(this.stop$)).subscribe((res) => {
      this.addTableItem(res.data);
      this.policyDocumentsData.documentsList.unshift(res.data);
    });
  }

  addTableItem(newItem: any): void {
    const tr: ITableTd[] = [];

    this.policyDocumentsData.fields.forEach((item, index) => {
      let display: any;

      switch (item) {
        case DocumentsConstants.category:
          display = this.policyDocumentsData.documentsCategoryList.find(category => category.code === newItem[item])?.description;
          break;
        case DocumentsConstants.fileName:
          display = newItem[item].name ? newItem[item].name : newItem[item];
          break;
        case DocumentsConstants.dateAdded:
          display = newItem[item]?.singleDate?.formatted ? this.datePipe.transform(newItem[item]?.singleDate?.formatted, this.policyDocumentsData.dateFormat) : this.policyDocumentsData.mapDate(newItem[item]);
          break;
        default:
          display = Boolean(newItem[item]) ? String(newItem[item]) : '';
          break;
      }

      tr.push({
        id: newItem.id,
        value: Boolean(newItem[item]) ? newItem[item] : '',
        display: display,
      });
    });

    const createdDate = newItem.dateAdded?.singleDate?.jsDate ?? new Date(newItem.dateAdded);

    this.policyDocumentsData.documentsTableRows.push({
      id: newItem.id,
      isFromParent: newItem.isFromParent,
      tr: tr,
      createdBy: newItem.createdBy,
      createdDate: createdDate,
      isSuppressed: newItem.isSuppressed
    });
    this.setPage(1);
  }

  loadSelectedTableItems(selectedItems: any): void {
    this.selectedTableItems = selectedItems;
  }

  public onClick(clickType?) {
    switch (clickType) {
      case ClickTypes.Back:
        // to do back
        this.navigationService.navigatePolicyRoute(PathConstants.Policy.Policies.Billing);
        break;
      case ClickTypes.Next:
        // to do next
        this.navigationService.navigatePolicyRoute(PathConstants.Policy.Policies.CoveragesAndDeduction);
        break;
    }
  }

  setPage(page: number): void {
      this.policyDocumentsData.setPolicyDocumentsPage(page);
  }

  get hasNoCheckedDocuments(): boolean {
    return this.selectedTableItems?.length === 0;
  }

  downloadFile(): void {
    Utils.blockUI();
    let isDone: boolean = false;
    let hasNullFilePath: boolean = false;
    let checkedCount: number = this.policyDocumentsData.documentsTableRows.filter(x => x.checked)?.length;

    this.downloadCompleted$.pipe(takeUntil(this.stop$)).subscribe(isCompleted => {
      if (isCompleted) {
        isDone = true;
        Utils.unblockUI();

        if (hasNullFilePath) {
          NotifUtils.showInfo(InfoMessageConstant.downloadGenerationDocumentMessage );
          hasNullFilePath = false;
        }
      }
    });

    if (!isDone) {
      this.policyDocumentsData.documentsTableRows.forEach((item) => {
        if (item?.checked) {
          let targetUrl: string = '';
          this.policyDocumentsData.documentsList.forEach((document) => {
            if (document.id === item.id) {
              targetUrl = document.filePath;
            }
          });

          if (targetUrl) {
            hasNullFilePath = false;
            const filename = targetUrl.split('/').pop();

            targetUrl = targetUrl.includes('#') ? targetUrl.replace(/#/g,'%23') : targetUrl;
            targetUrl = targetUrl.includes(' ') ? targetUrl.replace(/ /g,'%20') : targetUrl;
            this.policyService.generateSASURL(targetUrl).pipe(takeUntil(this.stop$)).subscribe(resultSASURL =>{
              fetch(resultSASURL).then(res =>
                res.blob()).then((blob) => {
                  fileSaver.saveAs(blob, filename);
              });
            });
          } else {
            hasNullFilePath = true;
          }

          checkedCount--;
        }

        if (checkedCount === 0) {
          this.downloadCompleted$.next(true);
        }
      });
    }
  }
}
