import { Injectable } from '@angular/core';
import { Pages } from '../../../../shared/constants/pages-and-sections.constants';
import { PropertyData } from '../../../../modules/submission-management/data/property.data';
import { GeneralValidationService } from './general-validation.service';
import { AuthService } from '../../auth.service';
import { Validators } from '@angular/forms';
import { CustomValidators } from '../../../../shared/validators/custom.validator';
import FormUtils from '../../../../shared/utilities/form.utils';
import { PropertyValidators } from '../../../../modules/submission-management/validators/property.validators';
import { QuoteService } from '../../quote.service';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { CoveragesData } from 'app/modules/submission-management/data/coverages.data';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { PropertyBusinessRules } from 'app/modules/submission-management/business-rules/property.business-rules';

@Injectable({
  providedIn: 'root'
})
export class PropertyValidationService {
  // #region =====> Property Page
  locationDetailsSection: boolean;
  locationDetailsSectionInvalidControls = [];
  propertyDetailsSection: boolean;
  propertyDetailsSectionInvalidControls = [];
  windMitigationSection: boolean;
  windMitigationSectionInvalidControls = [];
  formTypeConstants = FormTypeConstants;

  get LocationDetailsForm() {
    return this.propertyData.locationDetails;
  }

  get PropertyDetailsForm() {
    return this.propertyData.propertyDetails;
  }

  get WindMitigationForm() {
    return this.propertyData.windMitigation;
  }

  get effectiveDate(): Date {
    let effectiveDateUI = this.summaryData.SummaryForm.get('effectiveDate').value;
    effectiveDateUI = typeof effectiveDateUI === 'object' ? new Date(this.summaryData.SummaryForm.get('effectiveDate').value?.singleDate?.jsDate) : new Date(this.summaryData.SummaryForm.get('effectiveDate').value);

    return effectiveDateUI;
  }
// #endregion
  constructor(protected propertyData: PropertyData,
    protected generalValidationService: GeneralValidationService,
    protected authService: AuthService,
    protected quoteService: QuoteService,
    protected summaryData: SummaryData,
    protected coveragesData: CoveragesData,
    protected policySummaryData: PolicySummaryData,
    protected propertyBusinessRules: PropertyBusinessRules) { }

  checkPropertyPage(isPropertyOnly: boolean = false): void {
    if (isPropertyOnly) {
      this.propertyDetailsSection = this.checkPropertyDetailsSection();
      this.propertyDetailsSectionInvalidControls = this.generalValidationService.findInvalidControls(this.PropertyDetailsForm);
      this.generalValidationService.mapToInvalidFormControlList(this.propertyDetailsSectionInvalidControls, Pages.Property.pageName, Pages.Property.sections.PropertyDetails);
    } else {
      this.locationDetailsSection = this.checkLocationDetailsSection();
      this.propertyDetailsSection = this.checkPropertyDetailsSection();
      this.windMitigationSection = this.checkWindMitigationSection();

      this.locationDetailsSectionInvalidControls = this.generalValidationService.findInvalidControls(this.LocationDetailsForm);
      this.propertyDetailsSectionInvalidControls = this.generalValidationService.findInvalidControls(this.PropertyDetailsForm);
      this.windMitigationSectionInvalidControls = this.propertyData.isWindMitigationRequired ? this.generalValidationService.findInvalidControls(this.WindMitigationForm) : [];

      this.generalValidationService.mapToInvalidFormControlList(this.locationDetailsSectionInvalidControls, Pages.Property.pageName, Pages.Property.sections.LocationDetails);
      this.generalValidationService.mapToInvalidFormControlList(this.propertyDetailsSectionInvalidControls, Pages.Property.pageName, Pages.Property.sections.PropertyDetails);
      this.generalValidationService.mapToInvalidFormControlList(this.windMitigationSectionInvalidControls, Pages.Property.pageName, Pages.Property.sections.WindMitigation);
    }
  }

  checkLocationDetailsSection() {
    this.userTypeValidation(Pages.Property.sections.LocationDetails);

    const structureType = this.LocationDetailsForm.get('structureType').value;
    if ((structureType === 'E' || structureType === 'I') && (this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3)) {
      this.generalValidationService.resetValidatorFormControl(this.LocationDetailsForm, 'numberOfUnitsFirewalls', [Validators.required]);
    } else {
      this.generalValidationService.clearValidatorFormControl(this.LocationDetailsForm, 'numberOfUnitsFirewalls');
    }

    this.numberOfUnitsInsuredValidation();
    this.propertyBusinessRules.triggerActiveNew115();

    return FormUtils.validateForm(this.LocationDetailsForm);
  }

  checkPropertyDetailsSection(): boolean {
    const foundationType = this.PropertyDetailsForm.get('foundationType').value;
    if ((foundationType === 'EP' || foundationType === 'SW' || foundationType === 'DP') && (this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3 )) {
      this.generalValidationService.resetValidatorFormControl(this.PropertyDetailsForm, 'foundationElevation', [Validators.required]);
    } else {
      this.generalValidationService.clearValidatorFormControl(this.PropertyDetailsForm, 'foundationElevation');
    }

    const isCentralAirConditioning = this.PropertyDetailsForm.get('isCentralAirConditioning').value;
    const yearBuilt = this.PropertyDetailsForm.get('yearBuilt').value;
    const isYearBuiltValid = yearBuilt.toString().length === 4;
    if (isCentralAirConditioning && this.quoteService.formType !== this.formTypeConstants.HO4) {

      if (isYearBuiltValid && !this.propertyData.isYearAirconChange && !this.propertyData.isDonePopulating) {
        this.PropertyDetailsForm.get('yearCentralAirConditioning').setValue(yearBuilt);
        this.propertyData.isYearAirconChange = false;
      }

      this.generalValidationService.resetValidatorFormControl(this.PropertyDetailsForm, 'yearCentralAirConditioning', [Validators.required, Validators.min(1800),
        PropertyValidators.yearShouldNotBeOlderThanYearBuiltValidator(yearBuilt),
        PropertyValidators.yearInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);
    } else {
      this.generalValidationService.clearValidatorFormControl(this.PropertyDetailsForm, 'yearCentralAirConditioning');
    }

    const squareFootage = this.PropertyDetailsForm.get('squareFootage').value;
    if (squareFootage <= 0) {
      this.generalValidationService.resetValidatorFormControl(this.PropertyDetailsForm, 'squareFootage', [Validators.required, CustomValidators.zeroValueValidator]);
    } else {
      this.generalValidationService.clearValidatorFormControl(this.PropertyDetailsForm, 'squareFootage');
    }

    const isHO4 = this.quoteService.formType === this.formTypeConstants.HO4;
    const isHO6 = this.quoteService.formType === this.formTypeConstants.HO6;
    const isHO4orHO6 = this.quoteService.formType === this.formTypeConstants.HO4 || this.quoteService.formType === this.formTypeConstants.HO6;
    const purchaseDateYear = this.PropertyDetailsForm.get('purchaseDate').value?.singleDate?.jsDate?.getFullYear();
    if (isHO4) {
      this.generalValidationService.clearValidatorFormControl(this.PropertyDetailsForm, 'purchaseDate');
      this.generalValidationService.clearValidatorFormControl(this.PropertyDetailsForm, 'yearRoof');
      this.generalValidationService.clearValidatorFormControl(this.LocationDetailsForm, 'numMonthsOccupied');
      this.generalValidationService.clearValidatorFormControl(this.LocationDetailsForm, 'isResidenceRented');
      this.generalValidationService.clearValidatorFormControl(this.LocationDetailsForm, 'isPropertyManager');
    } else {
      this.userTypeValidation(Pages.Property.sections.PropertyDetails);

      this.generalValidationService.resetValidatorFormControl(this.LocationDetailsForm, 'numMonthsOccupied', [Validators.required, Validators.min(1), Validators.max(12)]);
      // this.generalValidationService.resetValidatorFormControl(this.LocationDetailsForm, 'isResidenceRented', [Validators.required]);
      this.generalValidationService.resetValidatorFormControl(this.LocationDetailsForm, 'isPropertyManager', [Validators.required]);
      this.generalValidationService.resetValidatorFormControl(this.PropertyDetailsForm, 'yearRoof', [Validators.required, Validators.min(1800),
        PropertyValidators.yearRoofShouldNotBeOlderThanYearBuiltValidator(yearBuilt),
        PropertyValidators.yearRoofInstalledCannotBeAFutureYearValidator(this.authService.getCustomDate())]);
      this.propertyBusinessRules.triggerActiveNew115();
    }

    if (isHO6) {
      this.generalValidationService.clearValidatorFormControl(this.PropertyDetailsForm, 'foundationType');
    } else {
      this.generalValidationService.resetValidatorFormControl(this.PropertyDetailsForm, 'foundationType', [Validators.required]);
    }

    if (isHO4orHO6) {
      this.generalValidationService.resetValidatorFormControl(this.PropertyDetailsForm, 'floorUnitNumber', [Validators.required, Validators.max(50)]);
    } else {
      this.generalValidationService.clearValidatorFormControl(this.PropertyDetailsForm, 'floorUnitNumber');
    }

    return FormUtils.validateForm(this.PropertyDetailsForm);
  }

  checkWindMitigationSection() {
    this.userTypeValidation(Pages.Property.sections.WindMitigation);
    return FormUtils.validateForm(this.WindMitigationForm);
  }

  userTypeValidation(section: string): void {
    let userType = '';
    this.authService.userType.subscribe(x => {
      userType = x;
    });

    switch (userType) {
      case 'external':
        this.externalValidation(section);
        break;
      case 'internal':
        this.internalValidation(section);
        break;
    }
  }

  externalValidation(section: string) {
    switch(section) {
      case Pages.Property.sections.LocationDetails:
        this.externalLocationDetailsValidation();
        break;
      case Pages.Property.sections.PropertyDetails:
        this.externalPropertyDetailsValidation();
        break;
      case Pages.Property.sections.WindMitigation:
        this.externalWindMitigationValidation();
        break;
      default:
        break;
    }
  }

  internalValidation(section: string) {
    switch(section) {
      case Pages.Property.sections.LocationDetails:
        this.internalLocationDetailsValidation();
        break;
      case Pages.Property.sections.PropertyDetails:
        this.internalPropertyDetailsValidation();
        break;
      case Pages.Property.sections.WindMitigation:
        this.internalWindMitigationValidation();
        break;
      default:
        break;
    }
  }

  externalLocationDetailsValidation(): void {
    this.generalValidationService.clearValidatorFormControl(this.LocationDetailsForm, 'bceg');
    this.generalValidationService.clearValidatorFormControl(this.LocationDetailsForm, 'territoryCode');
    this.generalValidationService.clearValidatorFormControl(this.LocationDetailsForm, 'matchLevel');

    this.generalValidationService.clearValidatorFormControl(this.PropertyDetailsForm, 'replacementCost');
    this.generalValidationService.clearValidatorFormControl(this.PropertyDetailsForm, 'replacementCostDate');

    this.generalValidationService.clearValidatorFormControl(this.WindMitigationForm, 'roofGeometry');
    this.generalValidationService.clearValidatorFormControl(this.WindMitigationForm, 'qualifiedInspectorType');
    this.generalValidationService.clearValidatorFormControl(this.WindMitigationForm, 'inspectorLicenseNumber');
  }

  externalPropertyDetailsValidation(): void {
    this.generalValidationService.resetValidatorFormControl(this.PropertyDetailsForm, 'purchaseDate', [Validators.required,
      PropertyValidators.purchaseDateCannotBeGreaterThanEffectiveDateValidator(this.effectiveDate)]);
  }

  externalWindMitigationValidation(): void {
    const isHurricaneDeductibleExcluded = this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid')?.value === 'E';
    const hasMitigationForm = this.WindMitigationForm.get('mitigationForm').value !== 'NF';
    if (hasMitigationForm && !isHurricaneDeductibleExcluded) {
      this.generalValidationService.resetValidatorFormControl(this.WindMitigationForm, 'dateInspection', [Validators.required]);
    } else {
      this.generalValidationService.clearValidatorFormControl(this.WindMitigationForm, 'dateInspection');
    }
  }

  internalLocationDetailsValidation(): void {
    this.generalValidationService.resetValidatorFormControl(this.WindMitigationForm, 'roofGeometry', [Validators.required]);
    this.generalValidationService.resetValidatorFormControl(this.WindMitigationForm, 'qualifiedInspectorType');
    this.generalValidationService.resetValidatorFormControl(this.WindMitigationForm, 'inspectorLicenseNumber', [CustomValidators.spaceValidator]);

    if (this.quoteService.formType === this.formTypeConstants.HO3 || this.quoteService.formType === this.formTypeConstants.DP3) {
      this.generalValidationService.resetValidatorFormControl(this.PropertyDetailsForm, 'replacementCost', [Validators.required]);
      this.generalValidationService.resetValidatorFormControl(this.PropertyDetailsForm, 'replacementCostDate', [Validators.required]);
    } else {
      this.generalValidationService.resetValidatorFormControl(this.PropertyDetailsForm, 'replacementCost');
      this.generalValidationService.resetValidatorFormControl(this.PropertyDetailsForm, 'replacementCostDate');
    }

    this.generalValidationService.resetValidatorFormControl(this.WindMitigationForm, 'roofGeometry', [Validators.required]);
    this.generalValidationService.resetValidatorFormControl(this.WindMitigationForm, 'qualifiedInspectorType');
    this.generalValidationService.resetValidatorFormControl(this.WindMitigationForm, 'inspectorLicenseNumber', [CustomValidators.spaceValidator]);
  }

  internalPropertyDetailsValidation(): void {
    this.generalValidationService.resetValidatorFormControl(this.PropertyDetailsForm, 'purchaseDate', [Validators.required]);
  }

  internalWindMitigationValidation(): void {
    if (this.policySummaryData.isPolicy || this.policySummaryData.isRenewal()) { // this condition is used for Renewal and Policy only
      this.generalValidationService.clearValidatorFormControl(this.WindMitigationForm, 'dateInspection');
    }
  }

  numberOfUnitsInsuredValidation(): void {
    const structureType = this.propertyData.locationDetails.get('structureType').value;
    const unitsInsured = this.propertyData.locationDetails.get('numberOfUnitsInsured').value;

    if (this.quoteService.formType === this.formTypeConstants.DP3) {
      if (structureType === '' || structureType === '1') {
        this.generalValidationService.clearValidatorFormControl(this.propertyData.locationDetails, 'numberOfUnitsInsured');
        this.propertyData.locationDetails.get('numberOfUnitsInsured').setValue(structureType === '1' ? 'UI1' : '');
      } else {
        this.generalValidationService.resetValidatorFormControl(this.propertyData.locationDetails, 'numberOfUnitsInsured', [Validators.required,
          PropertyValidators.numberOfUnitsInsuredValidator(structureType, unitsInsured)]);
      }
    } else {
      this.generalValidationService.clearValidatorFormControl(this.propertyData.locationDetails, 'numberOfUnitsInsured');
      this.propertyData.locationDetails.get('numberOfUnitsInsured').setValue(structureType === '1' ? 'UI1' : '');
    }
  }
}
