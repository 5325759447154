import { createSelector } from '@ngrx/store';
import { BaseState } from '../base.state';

export const selectPolicyNotesState = (state: BaseState) => state.policyNotes;

export const selectPolicyNotesIsLoading = createSelector(
  selectPolicyNotesState,
  (state) => state.isLoading
)

export const selectPolicyNotesList = createSelector(
  selectPolicyNotesState,
  (state) => state.list
)