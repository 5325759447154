import { Pipe, PipeTransform } from '@angular/core';
import { CoveragesData } from 'app/modules/submission-management/data/coverages.data';

interface IAopDeductibleMinimumInfoArgs {
  formType: string;
  aopIsValid: boolean;
  aop: number;
  homeAge: number;
  isActiveIPXBrul: boolean;
  isInternal: boolean;
  isPolicyOrRenewal: boolean;
  aopBackup: number;
  isValidForBRUL358: boolean;
  isValidForNewMinimumDeductibleStateWide: boolean;
}

@Pipe({
  name: 'showAopDeductibleMinimumOfRequiredInfo',
  pure: false
})
export class AopDeductibleMinimumOfRequiredInfoPipe implements PipeTransform {

  constructor(
    private coveragesData: CoveragesData
  ) {

  }

  transform(aopDeductibleMinimumInfoArgs: IAopDeductibleMinimumInfoArgs): boolean {
    const {
      formType,
      aopIsValid,
      aop,
      homeAge,
      isActiveIPXBrul,
      isInternal,
      isPolicyOrRenewal,
      aopBackup,
      isValidForBRUL358,
      isValidForNewMinimumDeductibleStateWide
    } = aopDeductibleMinimumInfoArgs;

    const validFormTypes: string[] = ['HO3', 'DP3'];
    const brul358ValidFormTypes: string[] = ['HO3', 'HO4', 'HO6', 'DP3'];

    const homeAgeMinimum: number = isValidForNewMinimumDeductibleStateWide? 0 : 10;
    const aopMinimum: number = (isValidForBRUL358 || isValidForNewMinimumDeductibleStateWide) ? 2500 : 5000;

    let showMessage: boolean;

    if (isValidForBRUL358) {
      showMessage = aopIsValid &&
        brul358ValidFormTypes.includes(formType) &&
        Number(aop) < aopMinimum &&
        homeAge >= homeAgeMinimum &&
        isActiveIPXBrul;
    } else {
      showMessage = aopIsValid &&
        validFormTypes.includes(formType) &&
        Number(aop) < aopMinimum &&
        homeAge >= homeAgeMinimum &&
        isActiveIPXBrul;
    }
    return showMessage;
  }
}
