import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Utils from '../../../../../../shared/utilities/utils';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { BaseClass } from '../../../../../../shared/base-class';
import { PropertyLabelConstants } from '../../../../../../shared/constants/property.labels.constants';
import { ThirdPartyData } from '../../../../../../modules/submission-management/data/third-party.data';
import { ThirdPartyDataService } from '../../../../../../core/services/submission/third-party/third-party-data.service';
import Swal from 'sweetalert2';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { timer } from 'rxjs';
import { ErrorMessageConstant } from '../../../../../../shared/constants/error-message.constants';
import { takeUntil } from 'rxjs/operators';

declare const _360Value: any;

@Component({
  selector: 'app-iso-360value-modal',
  templateUrl: './iso-360value-modal.component.html',
  styleUrls: ['./iso-360value-modal.component.scss']
})
export class ISO360ValueModalComponent extends BaseClass implements OnInit, AfterViewInit {
  @ViewChild('container360Value') container360ValueRef: ElementRef;
  title: string = 'ISO 360Value';
  token: string;
  tempToken: string = null;
  hideToken: boolean = false;
  public onClose: Subject<boolean>;
  public PropertyLabelConstants = PropertyLabelConstants;
  public ErrorMessageConstants = ErrorMessageConstant;

  constructor(public bsModalRef: BsModalRef,
    protected thirdPartyData: ThirdPartyData,
    protected thirdPartyDataService: ThirdPartyDataService) {
    super();
  }

  public static closeModal(service: ThirdPartyDataService, thirdpartyData: ThirdPartyData, subject: Subject<boolean>, modalRef: BsModalRef, errorMessageConstants) {
    service.iso360ValueGetValuation(thirdpartyData.getIso360ValueGetValuationRequestModel())
      .subscribe(result => {
        thirdpartyData.dataFromAPIResponse.iso360Value = result;
        thirdpartyData.mapIso360ResultToProperty(result, true);
        subject.next(true);
        modalRef.content = null;
        modalRef.hide();
        Utils.unblockUI();
        Utils.hasIso360Loaded = false;
      },
      error => {
        NotifUtils.showError(errorMessageConstants.Iso360ValueErrorMessage.Iso360ValueFailedToReceiveDataMessage);
      });
  }

  public static closeModalOnError(modalRef: BsModalRef){
    modalRef.content = null;
    modalRef.hide();
    Utils.unblockUI();
    Utils.hasIso360Loaded = false;
  }

  ngOnInit() {
    this.onClose = new Subject();
    try {
      this.init360Value(this.thirdPartyDataService, this.thirdPartyData, this.onClose, this.bsModalRef, this.ErrorMessageConstants);
    } catch (error) {
      Utils.unblockUI();
      NotifUtils.showError(this.ErrorMessageConstants.Iso360ValueErrorMessage.Iso360ReplacementCostErrorMessage);
    }
  }

  ngAfterViewInit(): void {
    timer(20000).pipe(takeUntil(this.stop$))
      .subscribe(() => {
        if (!Utils.hasIso360Loaded){
          this.bsModalRef.hide();
          NotifUtils.showError(this.ErrorMessageConstants.Iso360ValueErrorMessage.Iso360ReplacementCostErrorMessage);
        }
      });
  }

  init360Value(service: ThirdPartyDataService, thirdpartyData: ThirdPartyData, subject: Subject<boolean>, modalRef: BsModalRef, errorMsgConstants) {
    Utils.blockUI();
    const initData: any = {
      elementId: 'div360Value',
      onLoad: () => {
        Utils.unblockUI();
        Utils.hasIso360Loaded = true;
      },
      onCalculate: () => {
        Utils.unblockUI();
      },
      onFinish: () => {
        ISO360ValueModalComponent.closeModal(service, thirdpartyData, subject, modalRef, errorMsgConstants);
        Utils.hasIso360Loaded = false;
      },
      onSessionTimeout: (data, errorObject) => {
        ISO360ValueModalComponent.closeModalOnError(modalRef);
        NotifUtils.showError(errorMsgConstants.Iso360ValueErrorMessage.Iso360ReplacementCostErrorMessage);
      },
      onCriticalError: (data, errorObject) => {
        ISO360ValueModalComponent.closeModalOnError(modalRef);
        NotifUtils.showError(errorMsgConstants.Iso360ValueErrorMessage.Iso360ReplacementCostErrorMessage);
      }
    };

    initData.token = this.token;
    initData.locale = 'en_US';
    initData.useIframe = true;
    _360Value.init(initData);
  }

  internalCloseModal() {
    Swal.fire({
      title: 'Confirmation',
      text: 'You are leaving this page. Any unfinished calculation will be discarded. Proceed?',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        Utils.blockUI();
        this.onClose.next(true);
        this.bsModalRef.hide();
        Utils.unblockUI();
        Utils.hasIso360Loaded = false;
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }
}
