import { Component, OnDestroy, OnInit } from '@angular/core';
import { LvCancellationType, LvCancellationTypeReason, LvCancellationTypeReasonStateMapping } from 'app/shared/constants/policy-cancellation.options.constants';
import { PolicyCancellationLabelsConstants } from 'app/shared/constants/policy-cancellation.labels.constants';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PolicyCancellationData } from '../data/policy-cancellation.data';
import { FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { QuoteService } from 'app/core/services/quote.service';
import { ICancellationDTO } from 'app/shared/models/data/dto/policy-management/policy-cancellation.dto';
import Utils from 'app/shared/utilities/utils';
import { PolicyService } from 'app/core/services/submission/policy.service';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from 'app/shared/base-class';
import { SubmissionPageData } from 'app/modules/submission-management/data/submission-page.data';
import { RiskStatus } from 'app/shared/models/data/dto/quick-quote/risk.dto';
import { LvRiskStatus } from 'app/shared/constants/risk-status';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { AuthService } from 'app/core/services/auth.service';
import { PolicyBillingData } from '../data/policy-billing.data';
import { Rater } from '../../../../app/shared/constants/rater.constant';
import { LocalStorageService } from '../../../../app/core/services/local-storage.service';
import { PolicyDocumentsData } from '../data/policy-documents.data';
import NotifUtils from '../../../../app/shared/utilities/notif-utils';
import { CalculateRaterData } from 'app/core/services/submission/rater-premium/calculate-rater-data.service';
import { EntityRiskData } from '../../../../app/modules/submission-management/data/entity-risk.data';
import { PolicyIssueData } from '../data/policy-issue.data';
import { AddressType } from 'app/shared/enum/address-type.enum';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { PolicySummaryData } from '../data/policy-summary.data';
import { CoveragesData } from 'app/modules/submission-management/data/coverages.data';
import { EndorsementsData } from 'app/modules/submission-management/data/endorsements.data';
import { PropertyData } from 'app/modules/submission-management/data/property.data';
import { ApplicantData } from 'app/modules/submission-management/data/applicant.data';
import { InterestData } from 'app/modules/submission-management/data/interest.data';
import { PolicyNavValidationService } from 'app/core/services/navigation/policy-nav-validation.service';
import { LayoutService } from 'app/core/services/layout/layout.service';
import { PathConstants } from 'app/shared/constants/path.constants';
import { createPolicyDetailsMenuItems } from 'app/modules/policy-management/policy-management-navitems';
import { UwQuestionsData } from 'app/modules/submission-management/data/uw-questions.data';
import { Store } from '@ngrx/store';
import { updatePolicyStatusFromPolicyCancellationComponent } from '../../../store/policy/policy.actions';
import { ClaimsData } from 'app/modules/submission-management/data/claims.data';

@Component({
  selector: 'app-policy-cancellation',
  templateUrl: './policy-cancellation.component.html',
  styleUrls: ['./policy-cancellation.component.scss']
})
export class PolicyCancellationComponent extends BaseClass implements OnInit, OnDestroy {

  public policyCancellationLabelsConstants = PolicyCancellationLabelsConstants;
  public ErrorMessageConstant = ErrorMessageConstant;
  LvCancellationType = LvCancellationType;
  LvCancellationTypeReason = LvCancellationTypeReason;
  LvCancellationTypeReasonStateMapping = LvCancellationTypeReasonStateMapping;
  cancellationTypeReason: any;
  cancellationTypeReasonMapping: any;
  reasons: any = [];

  title: string;
  public policyCancellationForm: FormGroup;
  effectivityDate: Date;
  constructor(
    public policyCancellationData: PolicyCancellationData,
    public bsModalRef: BsModalRef,
    protected quoteService: QuoteService,
    public policyService: PolicyService,
    public submissionPageData: SubmissionPageData,
    public summaryData: SummaryData,
    public authService: AuthService,
    public billingData: PolicyBillingData,
    protected storage: LocalStorageService,
    protected policyDocumentsData: PolicyDocumentsData,
    protected raterData: CalculateRaterData,
    protected entityRiskData: EntityRiskData,
    public policyIssueData: PolicyIssueData,
    public policyNavValidationService: PolicyNavValidationService,
    protected layoutService: LayoutService,
    public policySummaryData: PolicySummaryData,
    public coveragesData: CoveragesData,
    public endorsementsData: EndorsementsData,
    public propertyData: PropertyData,
    public applicantData: ApplicantData,
    public interestData: InterestData,
    public uwQuestionsData: UwQuestionsData,
    protected store: Store
  ) {
    super();
  }


  ngOnInit() {
    this.policyCancellationData.generateStateTiming();
    this.policyCancellationData.initializeForm();
    this.filterLvCancellaTypebyUser();
    this.policyCancellationData.getClaimsLossDate();
    this.cancellationForm.get('cancellationType').valueChanges.subscribe(val => {
        this.policyCancellationData.dateOptions(val.toUpperCase() !== 'IR');
    });
  }

  get cancellationForm(): FormGroup {
    return this.policyCancellationData.policyCancellationForm;
  }

  get effectiveDateOption(): IAngularMyDpOptions {
    return this.policyCancellationData.effectiveDateOption;
  }

  ngOnDestroy() {
    this.policyCancellationData.currentRiskId = null;
    this.policyCancellationData.currentRiskDetailId = null;

    this.policyCancellationData.disableEffectiveDate = true;
    this.policyCancellationData.cancellationReasonIsRequired = false;

    this.policyCancellationData.cancellationReasonTypeHasValue = null;
  }

  filterLvCancellaTypebyUser(): void {
    if (!this.policyCancellationData.isInternal) {
      this.LvCancellationType = LvCancellationType.filter(a =>
        a.code === this.policyCancellationLabelsConstants.PolicyCancellation.InsuredRequest);
      this.policyCancellationData.policyCancellationForm.get('cancellationType').setValue(this.policyCancellationLabelsConstants.PolicyCancellation.InsuredRequest);
      this.onSelectCancellationType(this.policyCancellationLabelsConstants.PolicyCancellation.InsuredRequest);
    } else {
      this.LvCancellationType = LvCancellationType;
      this.policyCancellationData.disableEffectiveDate = false;
    }
  }

  hideMyModal(): void {
    this.bsModalRef.hide();
  }

  okMyModal(): void {
    const payload = this.policyCancellationData.getPolicyCancellationFormValue();

    this.postCancellationPolicy(payload);
  }

  onSelectCancellationType(code) {
    this.reasons = [];
    this.policyCancellationData.cancellationReasonTypeHasValue = null;
    this.policyCancellationData.cancellationReasonIsRequired = false;
    this.policyCancellationData.policyCancellationForm.get('cancellationReason').clearValidators();
    this.policyCancellationData.policyCancellationForm.get('cancellationReason').updateValueAndValidity();

    this.cancellationTypeReason = this.LvCancellationTypeReason.filter(data => data.cancellationTypeCode === code);

    const insuredAddress = this.entityRiskData.getEntityAddresses()?.find(a => a.addressTypeCode === AddressType.Property).address?.state;
    this.cancellationTypeReasonMapping = this.LvCancellationTypeReasonStateMapping.filter(data => data.stateCode === insuredAddress);

    this.cancellationTypeReason.forEach(d => {
      if(this.cancellationTypeReasonMapping.find(innerData => innerData.cancellationTypeReasonCode === d.code)){
        this.reasons.push(d);
      }
    });

    if (code === this.policyCancellationLabelsConstants.PolicyCancellation.InsuredRequest) {
      this.policyCancellationData.disableEffectiveDate = false;
      this.policyCancellationData.setCancellationEffectiveDate();
    } else if (code === this.policyCancellationLabelsConstants.PolicyCancellation.BillingReasons) {
      this.policyCancellationData.disableEffectiveDate = this.policyCancellationData.isExternal;
      this.policyCancellationData.policyCancellationForm.get('effectiveDate').setValue({ isRange: false, singleDate: { jsDate: new Date(this.policyCancellationData.stateTimingData.cedBillingReasons) } });
    } else if (code === this.policyCancellationLabelsConstants.PolicyCancellation.UnderwriterReasons) {
      this.policyCancellationData.disableEffectiveDate = this.policyCancellationData.isExternal;

      const withinUWPeriod = new Date(this.policyCancellationData.stateTimingData.cedUnderWritterPolicyWithinUWPeriod);
      const notWithinUWPeriod = new Date(this.policyCancellationData.stateTimingData.cedUnderWritterPolicyNotWithinUWPeriod);
      const currentDate = this.authService.getCustomDate();
      const effectiveDate = new Date(this.policyCancellationData.stateTimingData?.effectiveDate);

      if (this.getBetweenDates(currentDate, new Date(this.policyCancellationData.stateTimingData.effectiveDate), new Date(this.policyCancellationData.stateTimingData.uwPeriodDate))) {
        this.policyCancellationData.policyCancellationForm.get('effectiveDate').setValue({ isRange: false, singleDate: { jsDate: (withinUWPeriod) } });
      } else {
        if (effectiveDate > currentDate) {
          this.policyCancellationData.policyCancellationForm.get('effectiveDate').setValue({ isRange: false, singleDate: { jsDate: (withinUWPeriod) } });
        } else {
          this.policyCancellationData.policyCancellationForm.get('effectiveDate').setValue({ isRange: false, singleDate: { jsDate: (notWithinUWPeriod) } });
        }
      }
    }
    this.policyCancellationData.policyCancellationForm.get('cancellationReasonType')?.setValue('');
    this.policyCancellationData.policyCancellationForm.get('cancellationReasonType').markAsTouched();
  }

  onSelectCancellationReasonType(code) {
    this.policyCancellationData.cancellationReasonTypeHasValue = null;
    const freeForm = this?.LvCancellationTypeReason?.find(data => data.code === code)?.isFreeFormRequired;
      if (code && (freeForm !== undefined && freeForm !== null)) {
        this.policyCancellationData.cancellationReasonIsRequired = freeForm;
        if (freeForm) {
          this.policyCancellationData.policyCancellationForm.get('cancellationReason').setValidators([Validators.required]);
        } else {
          this.policyCancellationData.policyCancellationForm.get('cancellationReason').clearValidators();
        }
        this.policyCancellationData.policyCancellationForm.get('cancellationReason').updateValueAndValidity();
        this.policyCancellationData.policyCancellationForm.get('cancellationReason').markAsTouched();

        this.policyCancellationData.cancellationReasonTypeHasValue = this.policyCancellationData.policyCancellationForm.get('cancellationReasonType').value;
      }
  }

  getBetweenDates(base: Date, start: Date, end: Date): boolean {
    const a = new Date(start.getFullYear(), start.getMonth(), start.getDate());
    const b = new Date(end.getFullYear(), end.getMonth(), end.getDate());
    const c = new Date(b.getTime());   // clone the date to not muck the original value
    c.setDate(c.getDate()+1);        // add a day
    const inRange = base >= a && base < c; // use a half-open interval
    return inRange;
  }

  postCancellationPolicy(payload: ICancellationDTO): void {
    Utils.blockUI();
    this.policyService.postCancellationPolicy(payload).pipe(takeUntil(this.stop$)).subscribe(result => {
      if (result?.isPendingCancellation) {
        this.submissionPageData.isPendingCancellation.next(true);
      }
      const riskStatusDescription = LvRiskStatus.find(a => a.code === result.riskStatusCode)?.description;
      this.summaryData.SummaryForm.get('quoteStatus').setValue(riskStatusDescription);
      this.submissionPageData.quoteStatus.next(riskStatusDescription as RiskStatus);
      this.store.dispatch(updatePolicyStatusFromPolicyCancellationComponent({ status: riskStatusDescription as RiskStatus }));
      this.entityRiskData.getRisk().riskStatusCode = result.riskStatusCode;
      this.entityRiskData.getRisk().cancellationTypeCode = payload.cancellationTypeCode;

      this.billingData.showSummary(result.riskId);
      this.billingData.listInstallmentInvoice(result.riskId);
      this.billingData.populateTransactionSummary(result.riskId);
      this.resetPolicyChanges(result.riskStatusCode === 'CAN');
      this.policyService.getPolicyDocuments(result.riskId).pipe(takeUntil(this.stop$)).subscribe(documents => {
        this.policyDocumentsData.mapPolicyDocuments(documents);
        this.policyDocumentsData.getFTPDocuments(result.riskId);
      });
      this.policyCancellationData.autoReinstatementData.populateAutoReinstateData();

      const riskDetail = this.entityRiskData.getRisk().riskDetails[0];
      const isFromRenewal = this.entityRiskData.getRisk()?.isFromRenewal;
      this.summaryData.setShowRewriteOption(riskDetail.effectiveDate, riskDetail.endorsementNumber, riskStatusDescription, isFromRenewal);

      if (result.raterResults) {
        const raterResultKey = `${Rater.raterResult}_${result.riskDetailId}`;
        this.storage.store(raterResultKey, result.raterResults);

        this.raterData.calculateRaterPremium();
        this.policyCancellationData.updatePolicyHistoryData();

        this.billingData.listTransactionFeesByRiskId(this.entityRiskData.getRiskId());
      }

      this.disableFields();

      Utils.unblockUI();
      this.hideMyModal();
    }, (error) => {
      Utils.unblockUI();
      this.hideMyModal();

      NotifUtils.showError(error?.error?.message ?? error);
    });
  }

  resetPolicyChanges(isCancel: boolean): void {
    if(isCancel){
      this.policyIssueData.resetPolicyChanges();
    }
  }

  disableFields() {
    this.policySummaryData.isEditPolicy = false;
    localStorage.setItem('isEditPolicy', `${this.policySummaryData.isEditPolicy}`);
    this.coveragesData.setCoveragePageFormStatus(this.policySummaryData.isEditPolicy);
    this.endorsementsData.setEndorsementPageFormStatus(this.policySummaryData.isEditPolicy);
    this.propertyData.setPropertyPageFormStatus(this.policySummaryData.isEditPolicy);
    this.applicantData.setApplicantPageFormStatus(this.policySummaryData.isEditPolicy);
    this.interestData.setInterestPageFormStatus(this.policySummaryData.isEditPolicy);
    this.uwQuestionsData.setDisabledEndorsementFields();
    this.policyNavValidationService.validateCurrentCategory(PathConstants.Policy.Policies.UWApproval);

    this.layoutService.updateMenu(createPolicyDetailsMenuItems({
      riskId: this.policySummaryData.clonePolicyId,
      riskDetailId: this.policySummaryData.riskDetailId,
      storage: this.storage,
      validation : this.policyNavValidationService.validationList,
      isEditPolicy: this.policySummaryData.isEditPolicy
    }));

    this.coveragesData.markCoveragesFormPageAsTouch();
    this.propertyData.markApplicantFormPageAsTouch();
    this.applicantData.markApplicantPageFormAsTouch();
    this.interestData.markInterestPageAsTouch();
    this.endorsementsData.markEndorsementPageAsTouch();
  }
  onDateChanged(event?) {
    this.effectivityDate = event.jsDate;
    this.policyCancellationData.checkEffectiveDatePriorToClaims(this.effectivityDate);
    this.policyCancellationData.checkSelectedCancellationEffectiveDateValidity(this.effectivityDate);
  }

  onInputFieldChanged(event?) {
    this.effectivityDate = new Date(event?.value);
    this.policyCancellationData.checkEffectiveDatePriorToClaims(this.effectivityDate);
    this.policyCancellationData.checkSelectedCancellationEffectiveDateValidity(this.effectivityDate);
  }
}
