export const MoratoriumType = {
  closure: 'Closure',
  moratorium: 'Moratorium',
  coverageMoratorium: 'CoverageMoratorium'
};

export const MoratoriumType2 = {
  floodCoverage: 'FloodCoverage'
};

export const MoratoriumTypeCode = {
  moratoriumCode: 'moratoriumCode',
  closureCode: 'closureCode',
  closureUWCode: 'closureUWCode',
  coverageFloodMoratoriumCode: 'coverageFloodMoratoriumCode'
};