import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { FlagRenewalDTO } from 'app/shared/models/data/dto/renewal/flag-renewal.dto';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../common.service';


@Injectable({
  providedIn: 'root'
})
export class RenewalService {

  constructor(public http: HttpClient
            , protected commonService: CommonService) { }

  putFlagRenewal(flagPayload: FlagRenewalDTO): Observable<any> {
    const url = `${environment.ApiUrl}/risk/renewal/renewalchange/flag`;
    return this.http.put(url, flagPayload)
    .pipe(catchError(this.commonService.handleObservableHttpError));
  }

  putUnFlagRenewal(riskId: string, riskDetailId: string): Observable<any> {
    return this.http.put(`${environment.ApiUrl}/risk/renewal/renewalchange/unflag?riskId=${riskId}&riskDetailId=${riskDetailId}`, {})
      .pipe(catchError(this.commonService.handleObservableHttpError));
  }

  putReviseRenewal(riskId: string, riskDetailId: string): Observable<any> {
    return this.http.put(`${environment.ApiUrl}/risk/renewal/renewalchange/revise?riskId=${riskId}&riskDetailId=${riskDetailId}`, {})
      .pipe(catchError(this.commonService.handleObservableHttpError));
  }

  //TODO: Uncomment for LA-rollover, related to https://rivpartners.atlassian.net/browse/CNTLA-2201
  // putRescindRenewal(convertedRenewalRiskId: string): Observable<any> {
  //   return this.http.put(`${environment.ApiUrl}/risk/renewal/rescind/${convertedRenewalRiskId}`, {})
  //     .pipe(catchError(this.commonService.handleObservableHttpError));
  // }

  //TODO: Uncomment for LA-rollover, related to https://rivpartners.atlassian.net/browse/CNTLA-2201
  // putReverseRescindRenewal(convertedRenewalRiskId: string): Observable<any> {
  //   return this.http.put(`${environment.ApiUrl}/risk/renewal/reverse-rescind/${convertedRenewalRiskId}`, {})
  //     .pipe(catchError(this.commonService.handleObservableHttpError));
  // }
}
