import { Component, OnInit } from '@angular/core';
import { BaseClass } from '../../../../shared/base-class';
import { PolicySummaryData } from '../../data/policy-summary.data';

@Component({
  selector: 'app-insured',
  templateUrl: './insured.component.html',
  styleUrls: ['./insured.component.scss']
})
export class InsuredComponent extends BaseClass implements OnInit {
  isOpen: boolean = true;

  constructor(public policySummaryData: PolicySummaryData) {
    super();
  }

  ngOnInit(): void {
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }
}
