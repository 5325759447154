import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PolicyManagementComponent } from './policy-management.component';
import { PolicyListComponent } from './policy-list/policy-list.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppBreadcrumbModule, AppSidebarModule } from '@coreui/angular';
import { TabsModule } from 'ngx-bootstrap';
import { SharedModule } from '../../shared/shared.module';
import { PolicyManagementRoutingModule } from './policy-management-routing.module';
import { PolicyHistoryModule } from './policy-history/policy-history.module';
import { PolicySummaryModule } from './policy-summary/policy-summary.module';
import { PolicyTasksModule } from './policy-tasks/policy-tasks.module';
import { PolicyDocumentsModule } from './policy-documents/policy-documents.module';
import { PolicyNotesModule } from './policy-notes/policy-notes.module';
import { PolicyIssueModule } from './policy-issue/policy-issue.module';
import { PolicyBillingModule } from './policy-billing/policy-billing.module';
import { PolicyBillingData } from './data/policy-billing.data';
import { PolicyNavSavingService } from 'app/core/services/navigation/policy-nav-saving.service';
import { PolicyNavValidationService } from 'app/core/services/navigation/policy-nav-validation.service';
import { PolicyDocumentsData } from './data/policy-documents.data';
import { PolicyHistoryData } from './data/policy-history-data';
import { OfacService } from 'app/core/services/submission/third-party/ofac/ofac.service';
import { AutoReinstatementData } from './data/auto-reinstatement.data';
import { PropertyBusinessRules } from '../submission-management/business-rules/property.business-rules';

@NgModule({
  declarations: [
    PolicyManagementComponent,
    PolicyListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    AppSidebarModule,
    AppBreadcrumbModule.forRoot(),
    TabsModule.forRoot(),
    SharedModule,
    PolicyManagementRoutingModule,
    PolicyHistoryModule,
    PolicySummaryModule,
    PolicyTasksModule,
    PolicyDocumentsModule,
    PolicyNotesModule,
    PolicyIssueModule,
    PolicyBillingModule
  ],
  providers: [
    PolicyBillingData,
    PolicyNavSavingService,
    PolicyNavValidationService,
    PolicyDocumentsData,
    PolicyHistoryData,
    OfacService,
    AutoReinstatementData,
    PropertyBusinessRules
  ],
})
export class PolicyManagementModule { }
