import { Component, OnInit } from '@angular/core';
import { SubmissionPageData } from '../../data/submission-page.data';
import { ErrorMessageConstant } from '../../../../shared/constants/error-message.constants';
import { ApplicantLabelsConstants } from 'app/shared/constants/applicant.labels.constants';
import { QuickQuoteData } from '../../data/quick-quote.data';
import { ApplicantData } from '../../data/applicant.data';
import { ApplicantBusinessRules } from '../../business-rules/applicant.business-rules';
import { GenericLabel } from '../../../../shared/constants/generic.labels.constants';
import Utils from 'app/shared/utilities/utils';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../../shared/base-class';
import NotifUtils from '../../../../shared/utilities/notif-utils';
import { ZipData } from '../../data/zip.data';
import { CoveragesData } from '../../data/coverages.data';
import { ZipCodeData } from '../../data/zipcode.data';
import { PageSections } from '../../../../shared/enum/page-sections.enum';
import { Validators } from '@angular/forms';
import { CustomValidators } from '../../../../shared/validators/custom.validator';
import { GeneralValidationService } from '../../../../core/services/submission/validations/general-validation.service';
import { RegexConstants } from '../../../../shared/constants/regex.constants';
import { PolicySummaryData } from '../../../../modules/policy-management/data/policy-summary.data';
import FormUtils from 'app/shared/utilities/form.utils';
import { EntityRiskData } from '../../data/entity-risk.data';
import { ApplicantValidatorConstants } from '../../validators/validator-error-constants/applicant-validators.constants';
import { AuthService } from 'app/core/services/auth.service';
import { EntityRiskDTO } from 'app/shared/models/data/dto/quick-quote/entity-risk.dto';

@Component({
  selector: 'app-applicant-main-applicant',
  templateUrl: './applicant-main-applicant.component.html',
  styleUrls: ['./applicant-main-applicant.component.scss'],
})
export class ApplicantMainApplicantComponent extends BaseClass implements OnInit {
  public hasMailAndAddressSame: boolean = false;
  public isApplicantHidden: boolean = false;
  public ErrorMessageConstant = ErrorMessageConstant;
  public LabelMessageConstant = ApplicantLabelsConstants;
  public ApplicantValidatorConstants = ApplicantValidatorConstants;
  public FormUtils = FormUtils;
  GenericLabel = GenericLabel;
  showBelow18Age: boolean = false;
  isUSCountry?: boolean = true;
  userType: string = null;

  constructor(
    public quickQuoteData: QuickQuoteData,
    public applicantData: ApplicantData,
    protected submissionData: SubmissionPageData,
    protected applicantBusinessRules: ApplicantBusinessRules,
    public zipData: ZipData,
    protected coveragesData: CoveragesData,
    protected zipCodeData: ZipCodeData,
    protected generalValidationService: GeneralValidationService,
    public policySummaryData: PolicySummaryData,
    public entityRiskData: EntityRiskData,
    protected authService: AuthService) {
    super();
  }

  get QuickQuoteApplicantForm() {
    return this.applicantData.mainApplicant;
  }

  get UATCNTR910_Logic(): boolean {
    return this.authService.userType.value === 'external' && (this.policySummaryData.isPolicy || this.policySummaryData.isRenewal());
  }

  get isSubmission(): boolean {
    return !this.policySummaryData.isPolicy && !this.policySummaryData.isRenewal();
  }

  ngOnInit() {
    if (this.entityRiskData.isDonePopulatingForms) {
      this.initBusinessRules();
    } else {
      this.entityRiskData.isDonePopulatingForms$.pipe(takeUntil(this.stop$)).subscribe(() => {
        if (this.applicantData.mainApplicant.get('zipCode').value) {
          this.applicantData.populateCityList(this.applicantData.mainApplicant.get('zipCode').value, PageSections.ApplicantInformation);
        }
        this.initBusinessRules();
      });
    }

    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(user => {
      this.userType = user;
      this.applicantBusinessRules.updateApplicantNameValidators(this.userType);
    });

    if (!this.applicantData.mainApplicant.get('isApplicantsMailingAddressSameAsInsuredLocation').value){
      this.onValueChange();
    }

    this.calculateAgeFromDateOfBirthField();
  }

  initBusinessRules(): void {
    this.setIsUSCountryValue(this.applicantData.mainApplicant.get('country').value);
  }

  private hasRetireeDiscount(isRetiredYesNo: boolean): boolean {
    return (this.quickQuoteData.isRetired || this.entityRiskData?.EntityRisk?.isRetired) && isRetiredYesNo;
  }

  public calculateAge(event?: any): void {
    const dateOfBirth = event.jsDate;
    this.checkApplicantAgeForSeniorRetiree(dateOfBirth);
  }

  public calculateAgeFromDateOfBirthField(): void {
    const dateOfBirth = new Date(this.QuickQuoteApplicantForm.get('dateOfBirth').value?.singleDate?.jsDate?.toLocaleDateString('en-US'));
    this.checkApplicantAgeForSeniorRetiree(dateOfBirth);
  }

  protected checkApplicantAgeForSeniorRetiree(dateOfBirth: Date): void {
    if (dateOfBirth) {
      const age = this.applicantBusinessRules.calculateInsuredAge(dateOfBirth);
      const isInAgeOfRetirementBRULCOV20 = this.applicantBusinessRules.isInAgeOfRetirementBRULCOV20(age);
      const isInAgeOfRetirement = this.applicantBusinessRules.isInAgeOfRetirement(age);
      if (isInAgeOfRetirementBRULCOV20) {
        this.applicantData.isRetired = true;
        this.applicantData.isInAgeOfRetirement = false;
      } else if (isInAgeOfRetirement) {
        this.applicantData.isRetired = true;
        this.quickQuoteData.isRetired = true;
        this.applicantData.isInAgeOfRetirement = true;
      } else {
        this.applicantData.isRetired = false;
        this.quickQuoteData.isRetired = false;
        this.applicantData.isInAgeOfRetirement = false;
      }

      this.applicantData.age = age;
      if (age < 18) {
        this.showBelow18Age = true;
        NotifUtils.showError(ErrorMessageConstant.below18age, () => {
          this.applicantData.mainApplicant.get('dateOfBirth').setValue(null);
        });
      } else {
        this.showBelow18Age = false;
        if (this.applicantData.mainApplicant.get('dateOfBirth')?.valid) {
          this.applicantData.validDateOfBirth = this.applicantData.mainApplicant.get('dateOfBirth')?.value?.singleDate?.jsDate?.toLocaleDateString('en-US');
        }
      }

      if (age < 55) {
        this.applicantData.mainApplicant.get('isRetiredYesNo').setValue(false);
      }

      if (this.policySummaryData.isPolicy) {
        this.quickQuoteData.getPolicyChangesForSeniorRetiree(true);
      } else {
        this.quickQuoteData.setSeniorRetireeValue(true);
      }

    }
  }

  public ToggleMailingAndInsuredLocation(): void {
    this.hasMailAndAddressSame = !this.hasMailAndAddressSame;
  }

  public ToggleHidingOfApplicant(): void {
    this.isApplicantHidden = !this.isApplicantHidden;
    this.submissionData.isApplicantOpen = !this.isApplicantHidden;
  }

  switchKeyPress(key: string, fieldName: string) {
    switch (key.toLowerCase()) {
      case 'y':
        this.applicantData.mainApplicant.get(fieldName).setValue(true);
        break;
      case 'n':
        this.applicantData.mainApplicant.get(fieldName).setValue(false);
        break;
      default:
        break;
    }

    if (fieldName === 'isApplicantsMailingAddressSameAsInsuredLocation') {
      this.ToggleMailingAndInsuredLocation();
    }
  }

  setZipCode(): void {
    if (!this.isUSCountry) { return; }
    this.QuickQuoteApplicantForm.get('city').enable();
    const zipCode = this.QuickQuoteApplicantForm.get('zipCode').value;
    const formControlNames = ['state', 'mainApplicantCounty', 'city', 'zipCode'];
    Utils.blockUI();
    if (zipCode !== '') {
      this.zipCodeData.getGenericZipCode(zipCode, this.QuickQuoteApplicantForm, formControlNames, PageSections.ApplicantInformation, false, ErrorMessageConstant.zipCodeNotFoundErrorMessage);
    } else {
      this.FormUtils.resetFields(this.QuickQuoteApplicantForm, formControlNames);
      this.zipCodeData.applicantInsuredCityList = [];
      Utils.unblockUI();
    }
  }

  onCountrySelect(event?): void {
    const country = event?.target ? event?.target?.value : event;
    this.setIsUSCountryValue(country);
    if (this.isUSCountry) {
      const regex = /[^0-9]*/g;
      this.QuickQuoteApplicantForm.get('zipCode').setValidators([Validators.required, Validators.pattern(regex), CustomValidators.hasNoValue]);
      this.QuickQuoteApplicantForm.get('state').setValidators([Validators.required]);
      this.QuickQuoteApplicantForm.get('mailingAddress').setValidators([Validators.required]);
      this.QuickQuoteApplicantForm.get('city').setValidators([Validators.required]);
      this.QuickQuoteApplicantForm.get('zipCode').reset();
      this.QuickQuoteApplicantForm.get('city').reset('');
      this.QuickQuoteApplicantForm.get('state').reset('');
    } else {
      this.zipCodeData.applicantInsuredCityList = [];
      this.generalValidationService.clearValidatorFormControl(this.QuickQuoteApplicantForm, 'zipCode');
      this.generalValidationService.clearValidatorFormControl(this.QuickQuoteApplicantForm, 'state');
      this.generalValidationService.clearValidatorFormControl(this.QuickQuoteApplicantForm, 'city');
      this.generalValidationService.clearValidatorFormControl(this.QuickQuoteApplicantForm, 'aptUnit');
      this.QuickQuoteApplicantForm.updateValueAndValidity();
      this.QuickQuoteApplicantForm.get('zipCode').reset();
      this.QuickQuoteApplicantForm.get('state').reset();
      this.QuickQuoteApplicantForm.get('city').reset();
      this.QuickQuoteApplicantForm.get('city').enable();
    }
    this.QuickQuoteApplicantForm.markAllAsTouched();
  }

  setIsUSCountryValue(country: string): void {
    this.isUSCountry = country === 'USA';
  }

  onValueChange() {
    const isApplicantsMailingAddressSameAsInsuredLocation = this.QuickQuoteApplicantForm.get('isApplicantsMailingAddressSameAsInsuredLocation').value;

    if (!isApplicantsMailingAddressSameAsInsuredLocation) {
      if (this.isUSCountry) {
        this.generalValidationService.resetValidatorFormControl(this.QuickQuoteApplicantForm, 'mailingAddress', [Validators.required, CustomValidators.spaceValidator]);
        this.generalValidationService.resetValidatorFormControl(this.QuickQuoteApplicantForm, 'aptUnit', []);
        this.generalValidationService.resetValidatorFormControl(this.QuickQuoteApplicantForm, 'zipCode', [Validators.required, Validators.pattern(RegexConstants.zipValidation), CustomValidators.hasNoValue]);
        this.generalValidationService.resetValidatorFormControl(this.QuickQuoteApplicantForm, 'state', [Validators.required]);
        this.generalValidationService.resetValidatorFormControl(this.QuickQuoteApplicantForm, 'city', [Validators.required]);
        this.generalValidationService.resetValidatorFormControl(this.QuickQuoteApplicantForm, 'country', [Validators.required]);
      } else{
        this.generalValidationService.resetValidatorFormControl(this.QuickQuoteApplicantForm, 'mailingAddress', [Validators.required, CustomValidators.spaceValidator]);
        this.generalValidationService.resetValidatorFormControl(this.QuickQuoteApplicantForm, 'aptUnit', []);
        this.generalValidationService.resetValidatorFormControl(this.QuickQuoteApplicantForm, 'zipCode', []);
        this.generalValidationService.resetValidatorFormControl(this.QuickQuoteApplicantForm, 'state', []);
        this.generalValidationService.resetValidatorFormControl(this.QuickQuoteApplicantForm, 'city', []);
        this.generalValidationService.resetValidatorFormControl(this.QuickQuoteApplicantForm, 'country', [Validators.required]);
      }

      if (this?.applicantData?.copiedInformation && this.IsValidCopiedInformation() && !this.policySummaryData.isPolicy) {
        this.QuickQuoteApplicantForm.get('mailingAddress').setValue(this.applicantData.copiedInformation.mailingAddressLine1);
        this.QuickQuoteApplicantForm.get('aptUnit').setValue(this.applicantData.copiedInformation.mailingAddressLine2);
        this.applicantData.populateCityList(this.applicantData.copiedInformation.mailingZipCode, PageSections.ApplicantInformation);
        this.QuickQuoteApplicantForm.get('city').setValue(this.applicantData.copiedInformation.mailingCity);
        this.QuickQuoteApplicantForm.get('state').setValue(this.applicantData.copiedInformation.mailingState);
        this.QuickQuoteApplicantForm.get('zipCode').setValue(this.applicantData.copiedInformation.mailingZipCode);
        this.QuickQuoteApplicantForm.get('country').setValue(this.applicantData.copiedInformation.mailingCountry);
      }
    } else {
      if (!this.applicantData.copiedInformation) {
        NotifUtils.showConfirmMessage(`Switching back to YES, will remove any changes made in the mailing address. <br><br> Are you sure want to continue?`, () => {
          this.setToDefaultMailingAddress();
          this.generalValidationService.clearValidatorFormControl(this.QuickQuoteApplicantForm, 'mailingAddress');
          this.generalValidationService.clearValidatorFormControl(this.QuickQuoteApplicantForm, 'aptUnit');
          this.generalValidationService.clearValidatorFormControl(this.QuickQuoteApplicantForm, 'zipCode');
          this.generalValidationService.clearValidatorFormControl(this.QuickQuoteApplicantForm, 'state');
          this.generalValidationService.clearValidatorFormControl(this.QuickQuoteApplicantForm, 'city');
          this.generalValidationService.clearValidatorFormControl(this.QuickQuoteApplicantForm, 'country');
        }, () => {
          this.QuickQuoteApplicantForm.get('isApplicantsMailingAddressSameAsInsuredLocation').setValue(false);
        });
      }
    }
  }


  public IsValidCopiedInformation(): string  {

    return this?.applicantData?.copiedInformation?.mailingAddressLine1 && this?.applicantData?.copiedInformation?.mailingZipCode &&
    this?.applicantData?.copiedInformation?.mailingCity && this?.applicantData?.copiedInformation?.mailingState &&
    this?.applicantData?.copiedInformation?.mailingZipCode && this?.applicantData?.copiedInformation?.mailingCountry;

  }

  setToDefaultMailingAddress() {
    this.QuickQuoteApplicantForm.get('mailingAddress').setValue('');
    this.QuickQuoteApplicantForm.get('aptUnit').setValue('');
    this.QuickQuoteApplicantForm.get('zipCode').setValue('');
    this.QuickQuoteApplicantForm.get('state').setValue('');
    this.QuickQuoteApplicantForm.get('city').setValue('');
    this.QuickQuoteApplicantForm.get('city').enable();
    this.QuickQuoteApplicantForm.get('country').setValue('USA');
    this.zipCodeData.applicantInsuredCityList = [];
    this.setIsUSCountryValue(this.QuickQuoteApplicantForm.get('country').value);
  }

  onBlurApplicantName(formControl: string): void  {
    this.applicantBusinessRules.showApplicantNameCorporationInvalidErrorMessage(formControl);
  }

  onChangeFirstName(event: any): void {
    if (this.applicantRetrievalData?.firstName !== event) {
      this.applicantData.nameChanged = true;
    }
  }

  onChangeMiddleName(event: any): void {
    if (this.applicantRetrievalData?.middleName !== event) {
      this.applicantData.nameChanged = true;
    }
  }

  onChangeLastName(event: any): void {
    if (this.applicantRetrievalData?.lastName !== event) {
      this.applicantData.nameChanged = true;
    }
  }

  onChangeNameSuffix(event: any): void {
    if (this.applicantRetrievalData?.nameSuffix !== event) {
      this.applicantData.nameChanged = true;
    }
  }

  get applicantRetrievalData(): EntityRiskDTO {
    return this.applicantData.applicantRetrievalData;
  }
}
