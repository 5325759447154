import { Pipe, PipeTransform } from '@angular/core';
import { TransferStatusConstants } from 'app/shared/constants/book-transfer.constants';

@Pipe({
  name: 'disableForTransferPolicyCheckbox'
})
export class DisableForTransferPolicyCheckboxPipe implements PipeTransform {
  transform(transferStatus: string): boolean {
    if (transferStatus) {
      return transferStatus !== this.isQueued;
    }

    return false;
  }

  get isQueued(): any {
    return TransferStatusConstants.queued;
  }
}
