export const ExcludedZipCodeList = [
  32080, 32118, 32135, 32169, 32176, 32250, 32320, 32322, 32323, 32327,
  32328, 32329, 32346, 32348, 32355, 32358, 32359, 32401, 32403, 32404,
  32405, 32407, 32408, 32409, 32410, 32413, 32439, 32444, 32456, 32457,
  32459, 32461, 32465, 32501, 32502, 32503, 32504, 32505, 32506, 32507,
  32508, 32514, 32520, 32521, 32526, 32530, 32533, 32534, 32536, 32539,
  32540, 32541, 32542, 32544, 32547, 32548, 32550, 32559, 32561, 32563,
  32566, 32569, 32570, 32571, 32578, 32579, 32580, 32583, 32625, 32903,
  32920, 32925, 32931, 32937, 32951, 32952, 32953, 32963, 33001, 33036,
  33037, 33040, 33042, 33043, 33050, 33051, 33052, 33070, 33109, 33139,
  33140, 33141, 33149, 33154, 33480, 33606, 33616, 33621, 33706, 33708,
  33715, 33767, 33785, 33786, 33908, 33921, 33922, 33924, 33931, 33945,
  33946, 33956, 33957, 34101, 34102, 34113, 34114, 34134, 34138, 34139,
  34140, 34141, 34145, 34146, 34209, 34216, 34217, 34228, 34236, 34242,
  34250, 34679, 34949, 36561, 34607, 32648, 34681
];
