import { Injectable } from '@angular/core';
import { Pages } from '../../../../shared/constants/pages-and-sections.constants';
import { ApplicantData } from '../../../../modules/submission-management/data/applicant.data';
import { GeneralValidationService } from './general-validation.service';
import { Validators } from '@angular/forms';
import { CustomValidators } from '../../../../shared/validators/custom.validator';
import { RegexConstants } from '../../../../shared/constants/regex.constants';
import FormUtils from 'app/shared/utilities/form.utils';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { ZipCodeData } from '../../../../modules/submission-management/data/zipcode.data';
import { CoveragesData } from '../../../../modules/submission-management/data/coverages.data';
import { LvCreditOrderStatus, LvCreditOrderStatusIndex } from 'app/shared/constants/quick-quote.options.constants';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { GenericConstants } from '../../../../shared/constants/generic.constants';

@Injectable({
  providedIn: 'root'
})
export class ApplicantValidationService {
  // #region =====> Applicant Page
  applicantSection: boolean;
  applicantSectionInvalidControls = [];
  contactSection: boolean;
  contactSectionInvalidControls = [];
  coApplicantSection: boolean;
  coApplicantSectionInvalidControls = [];
  fcraAcknowledgementSection: boolean;
  fcraAcknowledgementSectionInvalidControls = [];

  get ApplicantForm() {
    return this.applicantData.mainApplicant;
  }

  get ContactForm() {
    return this.applicantData.contact;
  }

  get CoApplicantForm() {
    return this.applicantData.coApplicant;
  }

  get FcraAcknowledgementForm() {
    return this.applicantData.fcraReportingActForm;
  }
// #endregion
  constructor(protected applicantData: ApplicantData,
    protected generalValidationService: GeneralValidationService,
    protected summaryData: SummaryData,
    protected policySummaryData: PolicySummaryData,
    protected zipData: ZipCodeData,
    protected coveragesData: CoveragesData) { }

  checkApplicantPage(): void {
    this.applicantSection = this.checkApplicantSection();
    this.contactSection = this.checkContactSection();
    this.fcraAcknowledgementSection = this.checkFcraAcknowledgementSection();

    this.applicantSectionInvalidControls = this.generalValidationService.findInvalidControls(this.ApplicantForm);
    this.contactSectionInvalidControls = this.generalValidationService.findInvalidControls(this.ContactForm);
    this.fcraAcknowledgementSectionInvalidControls = this.generalValidationService.findInvalidControls(this.FcraAcknowledgementForm);

    this.generalValidationService.mapToInvalidFormControlList(this.applicantSectionInvalidControls, Pages.Applicant.pageName, Pages.Applicant.sections.Applicant);
    this.generalValidationService.mapToInvalidFormControlList(this.contactSectionInvalidControls, Pages.Applicant.pageName, Pages.Applicant.sections.Contact);
    this.generalValidationService.mapToInvalidFormControlList(this.fcraAcknowledgementSectionInvalidControls, Pages.Applicant.pageName, Pages.Applicant.sections.FCRA);
  }

  checkApplicantSection() {
    const isApplicantsMailingAddressSameAsInsuredLocation = this.ApplicantForm.get('isApplicantsMailingAddressSameAsInsuredLocation').value;
    if (!isApplicantsMailingAddressSameAsInsuredLocation) {
      if (this.ApplicantForm.get('country').value === 'USA'){
        this.generalValidationService.resetValidatorFormControl(this.ApplicantForm, 'mailingAddress', [Validators.required, CustomValidators.spaceValidator]);
        this.generalValidationService.resetValidatorFormControl(this.ApplicantForm, 'zipCode', [Validators.required, Validators.pattern(RegexConstants.zipValidation), CustomValidators.hasNoValue]);
        this.generalValidationService.resetValidatorFormControl(this.ApplicantForm, 'state', [Validators.required]);
        this.generalValidationService.resetValidatorFormControl(this.ApplicantForm, 'city', [Validators.required]);
        this.generalValidationService.resetValidatorFormControl(this.ApplicantForm, 'country', [Validators.required]);
      } else {
        this.generalValidationService.resetValidatorFormControl(this.ApplicantForm, 'mailingAddress', [Validators.required, CustomValidators.spaceValidator]);
        this.generalValidationService.resetValidatorFormControl(this.ApplicantForm, 'zipCode', []);
        this.generalValidationService.resetValidatorFormControl(this.ApplicantForm, 'state', []);
        this.generalValidationService.resetValidatorFormControl(this.ApplicantForm, 'city', []);
        this.generalValidationService.resetValidatorFormControl(this.ApplicantForm, 'country', [Validators.required]);
      }
    } else {
      this.generalValidationService.clearValidatorFormControl(this.ApplicantForm, 'addressLine1');
      this.generalValidationService.clearValidatorFormControl(this.ApplicantForm, 'mailingAddress');
      this.generalValidationService.clearValidatorFormControl(this.ApplicantForm, 'zipCode');
      this.generalValidationService.clearValidatorFormControl(this.ApplicantForm, 'state');
      this.generalValidationService.clearValidatorFormControl(this.ApplicantForm, 'city');
      this.generalValidationService.clearValidatorFormControl(this.ApplicantForm, 'country');
    }

    if (this.policySummaryData.isPolicyPage(this.coveragesData.entityRiskData.getRiskId())) {
      this.generalValidationService.clearValidatorFormControl(this.ApplicantForm, 'addressLine1');
      this.generalValidationService.clearValidatorFormControl(this.ApplicantForm, 'qqCity');
      this.generalValidationService.clearValidatorFormControl(this.ApplicantForm, 'qqState');
      this.generalValidationService.clearValidatorFormControl(this.ApplicantForm, 'qqZipCode');
    }

    if (this.policySummaryData.isPolicy || this.policySummaryData.isRenewal()) {
      this.userTypeValidation(Pages.Applicant.sections.Applicant);
    }

    return FormUtils.validateForm(this.ApplicantForm);
  }

  checkContactSection() {
    if (this.policySummaryData.isPolicy || this.policySummaryData.isRenewal()) {
      this.userTypeValidation(Pages.Applicant.sections.Contact);
    }
    return FormUtils.validateForm(this.ContactForm);
  }

  checkCoApplicantSection() {
    const isThereCoApplicant = this.CoApplicantForm.get('isThereACoApplicant').value;
    if (isThereCoApplicant) {
      this.setCoApplicantValidation();
      if (this.policySummaryData.isPolicy || this.policySummaryData.isRenewal()) {
        this.userTypeValidation(Pages.Applicant.sections.CoApplicant);
      }
    } else {
      this.clearCoApplicantValidation();
    }

    const isCoApplicantsMailingAddressSameAsApplicantsMailingAddress = this.CoApplicantForm.get('isCoApplicantsMailingAddressSameAsApplicantsMailingAddress').value;
    if (isThereCoApplicant && !isCoApplicantsMailingAddressSameAsApplicantsMailingAddress) {
      if (this.CoApplicantForm.get('coApplicantSameAsApplicantCountry').value === 'USA') {
        this.setCoApplicantAddressValidation();
      } else {
        this.generalValidationService.resetValidatorFormControl(this.CoApplicantForm, 'coApplicantSameAsApplicantMailingAddress', [Validators.required, CustomValidators.spaceValidator]);
        this.generalValidationService.resetValidatorFormControl(this.CoApplicantForm, 'coApplicantSameAsApplicantCity', []);
        this.generalValidationService.resetValidatorFormControl(this.CoApplicantForm, 'coApplicantSameAsApplicantState', []);
        this.generalValidationService.resetValidatorFormControl(this.CoApplicantForm, 'coApplicantSameAsApplicantZipCode', []);
        this.generalValidationService.resetValidatorFormControl(this.CoApplicantForm, 'coApplicantSameAsApplicantCountry', [Validators.required]);
      }
    } else {
      this.clearCoApplicantAddressValidation();
    }

    this.coApplicantSectionInvalidControls = this.generalValidationService.findInvalidControls(this.CoApplicantForm);
    this.generalValidationService.mapToInvalidFormControlList(this.coApplicantSectionInvalidControls, Pages.Applicant.pageName, Pages.Applicant.sections.CoApplicant);
    this.coApplicantSection = FormUtils.validateForm(this.CoApplicantForm);
    return this.coApplicantSection;
  }

  checkFcraAcknowledgementSection() {
    const isCredited = this.ApplicantForm.get('isOrderYesNo').value;
    const creditOrderStatus = this.coveragesData.creditOrderStatusCode;
    const notOrderedStatus = LvCreditOrderStatus[LvCreditOrderStatusIndex.NotOrdered].code;

    if (isCredited && creditOrderStatus !== notOrderedStatus) {
      this.generalValidationService.clearValidatorFormControl(this.FcraAcknowledgementForm, 'fcraReportingAct');
    } else {
      this.generalValidationService.resetValidatorFormControl(this.FcraAcknowledgementForm, 'fcraReportingAct', [Validators.requiredTrue]);
    }
    return FormUtils.validateForm(this.FcraAcknowledgementForm);
  }

  checkResetFcraAcknowledgement() {
    const isCredited = this.ApplicantForm.get('isOrderYesNo').value;
    if (!isCredited) {
      return this.FcraAcknowledgementForm.get('fcraReportingAct').value;
    }
    return false;
  }

  clearCoApplicantValidation() {
    this.generalValidationService.clearValidatorFormControl(this.CoApplicantForm, 'coApplicantFirstName');
    this.generalValidationService.clearValidatorFormControl(this.CoApplicantForm, 'coApplicantLastName');
    this.generalValidationService.clearValidatorFormControl(this.CoApplicantForm, 'coApplicantDateOfBirth');
    this.generalValidationService.clearValidatorFormControl(this.CoApplicantForm, 'coApplicantMaritalStatus');
    this.generalValidationService.clearValidatorFormControl(this.CoApplicantForm, 'coApplicantRelationship');
  }

  clearCoApplicantAddressValidation() {
    this.generalValidationService.clearValidatorFormControl(this.CoApplicantForm, 'coApplicantSameAsApplicantMailingAddress');
    this.generalValidationService.clearValidatorFormControl(this.CoApplicantForm, 'coApplicantSameAsApplicantCity');
    this.generalValidationService.clearValidatorFormControl(this.CoApplicantForm, 'coApplicantSameAsApplicantState');
    this.generalValidationService.clearValidatorFormControl(this.CoApplicantForm, 'coApplicantSameAsApplicantZipCode');
    this.generalValidationService.clearValidatorFormControl(this.CoApplicantForm, 'coApplicantSameAsApplicantCountry');
  }

  setCoApplicantValidation() {
    this.generalValidationService.resetValidatorFormControl(this.CoApplicantForm, 'coApplicantFirstName', [Validators.required, Validators.pattern(RegexConstants.hasALetterAndASpaceAndAHyphenWithÑ)]);
    this.generalValidationService.resetValidatorFormControl(this.CoApplicantForm, 'coApplicantLastName', [Validators.required, Validators.pattern(RegexConstants.hasALetterAndASpaceAndAHyphenWithÑ)]);
    this.generalValidationService.resetValidatorFormControl(this.CoApplicantForm, 'coApplicantDateOfBirth', [Validators.required]);
    this.generalValidationService.resetValidatorFormControl(this.CoApplicantForm, 'coApplicantRelationship', [Validators.required]);
    if (!this.policySummaryData.isPolicy && !this.policySummaryData.isRenewal()) {
      this.generalValidationService.resetValidatorFormControl(this.CoApplicantForm, 'coApplicantMaritalStatus', [Validators.required]);
    }
  }

  setCoApplicantAddressValidation() {
    this.generalValidationService.resetValidatorFormControl(this.CoApplicantForm, 'coApplicantSameAsApplicantMailingAddress', [Validators.required, CustomValidators.spaceValidator]);
    this.generalValidationService.resetValidatorFormControl(this.CoApplicantForm, 'coApplicantSameAsApplicantCity', [Validators.required]);
    this.generalValidationService.resetValidatorFormControl(this.CoApplicantForm, 'coApplicantSameAsApplicantState', [Validators.required]);
    this.generalValidationService.resetValidatorFormControl(this.CoApplicantForm, 'coApplicantSameAsApplicantZipCode', [Validators.required, Validators.pattern(RegexConstants.zipValidation), CustomValidators.hasNoValue]);
    this.generalValidationService.resetValidatorFormControl(this.CoApplicantForm, 'coApplicantSameAsApplicantCountry', [Validators.required]);

    if (this.zipData.coApplicantCityList.length === 1 &&
      this.policySummaryData.isPolicy && this.policySummaryData.isEditPolicy) {
      this.CoApplicantForm.get('coApplicantSameAsApplicantCity').disable();
    }
  }

  public getUserTypeInStorage(): string {
    return localStorage.getItem('userType');
  }

  userTypeValidation(section: string): void {
    switch (this.getUserTypeInStorage()) {
      case 'external':
        this.externalValidation(section);
        break;
      case 'internal':
        this.internalValidation(section);
        break;
    }
  }

  externalValidation(section: string): void {
    switch(section) {
      case Pages.Applicant.sections.Applicant:
        this.externalApplicantValidation();
        break;
      case Pages.Applicant.sections.CoApplicant:
        this.externalCoApplicantValidation();
        break;
      case Pages.Applicant.sections.Contact:
        this.externalContactValidation();
        break;
      case Pages.Applicant.sections.FCRA:
        break;
      default:
        break;
    }
  }

  internalValidation(section: string): void {
    switch(section) {
      case Pages.Applicant.sections.Applicant:
        this.internalApplicantValidation();
        break;
      case Pages.Applicant.sections.CoApplicant:
        this.internalCoApplicantValidation();
        break;
      case Pages.Applicant.sections.Contact:
        this.internalContactValidation();
        break;
      case Pages.Applicant.sections.FCRA:
        break;
      default:
        break;
    }
  }

  externalApplicantValidation(): void {
    this.generalValidationService.resetValidatorFormControl(this.ApplicantForm, 'maritalStatusApplicant', [Validators.required]);
  }

  externalCoApplicantValidation(): void {
    this.generalValidationService.resetValidatorFormControl(this.CoApplicantForm, 'coApplicantMaritalStatus', [Validators.required]);
  }

  externalContactValidation(): void {
    this.generalValidationService.resetValidatorFormControl(this.ContactForm, 'emailAddress', [
      Validators.pattern(
        '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,12}$'
      ),
      Validators.required,
      CustomValidators.spaceValidator
    ]);
  }

  externalFCRAValidation(): void {
    // to do
  }

  internalApplicantValidation(): void {
    this.generalValidationService.clearValidatorFormControl(this.ApplicantForm, 'maritalStatusApplicant');
  }

  internalCoApplicantValidation(): void {
    this.generalValidationService.clearValidatorFormControl(this.CoApplicantForm, 'coApplicantMaritalStatus');
  }

  internalContactValidation(): void {
    this.generalValidationService.resetValidatorFormControl(this.ContactForm, 'emailAddress', [
      Validators.pattern(
        '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,12}$'
      ),
      CustomValidators.spaceValidator
    ]);
  }

  internalFCRAValidation(): void {
    // to do
  }
}
