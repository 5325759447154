import { Component, OnInit } from '@angular/core';
import { SubmissionPageData } from '../../../../../modules/submission-management/data/submission-page.data';
import { BindAndIssueData } from '../../../../../modules/submission-management/data/bind-and-issue.data';
import { BillingLabelConstants, CreditCardTypeConstants, PaymentPlanListConstants } from '../../../../../shared/constants/bind-and-issue.labels.constants';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { BindAndIssueService } from '../bind-and-issue.service';
import { BindAndIssueValidatorErrorConstants } from '../../../../../modules/submission-management/validators/validator-error-constants/bind-and-issue-error.constants';
import { SummaryData } from '../../../../../modules/submission-management/data/summary.data';
import { take, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../../../shared/base-component';
import FormUtils from '../../../../../shared/utilities/form.utils';
import { PaymentMethod } from '../../../../../../app/shared/enum/payment-method.enum';
import { AuthService } from '../../../../../core/services/auth.service';
import { CalculateRaterData } from 'app/core/services/submission/rater-premium/calculate-rater-data.service';
import { ApplicantData } from 'app/modules/submission-management/data/applicant.data';
import { EntityRiskData } from '../../../../../../app/modules/submission-management/data/entity-risk.data';
import { AddressType } from '../../../../../../app/shared/enum/address-type.enum';
import { AddressDTO3 } from '../../../../../../app/shared/models/data/dto/quick-quote/entity-address.dto';
import Utils from '../../../../../../app/shared/utilities/utils';
import { PageSections } from '../../../../../../app/shared/enum/page-sections.enum';
import { ZipCodeData } from '../../../../../../app/modules/submission-management/data/zipcode.data';
import { PolicyBillingData } from 'app/modules/policy-management/data/policy-billing.data';
import { QuickQuoteService } from '../../../../../core/services/submission/quick-quote.service';
import { UpdateRenewalPayPlanRequestDTO } from '../../../../../shared/models/data/dto/billing/update-renewal-payplan-request.dto';
import NotifUtils from '../../../../../shared/utilities/notif-utils';
import { AutoReinstatementData } from '../../../../../../app/modules/policy-management/data/auto-reinstatement.data';
import { PolicyBillingConstants } from '../../../../../shared/constants/policy-billing.constants';
import { InterestSavingData } from 'app/modules/submission-management/data/interest-saving.data';
import { Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectRaterIsLoading } from 'app/store/rater/rater.selectors';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent extends BaseComponent implements OnInit {
  public FormUtils = FormUtils;
  public BillingLabelConstants = BillingLabelConstants;
  public ErrorMessageConstant = ErrorMessageConstant;
  public CreditCardTypeConstants = CreditCardTypeConstants;
  public PolicyBillingConstants = PolicyBillingConstants;
  GenericLabel = GenericLabel;
  isOpen: boolean = true;
  creditCardExpiredErrorMessage = BindAndIssueValidatorErrorConstants.creditCardExpired.value;

  isDisablePayLater: boolean = false;
  isCalcPremiumCompleted: boolean = false;

  PaymentMethod = PaymentMethod;
  PaymentPlan = PaymentPlanListConstants;

  paymentMethods: any;
  isInternal: boolean;

  paymentPlanDesc: string;

  private selectRaterIsLoading$: Observable<boolean>;

  constructor(public bindAndIssueData: BindAndIssueData,
    protected submissionData: SubmissionPageData,
    protected bindAndIssueService: BindAndIssueService,
    protected summaryData: SummaryData,
    protected raterData: CalculateRaterData,
    protected authService: AuthService,
    protected applicantData: ApplicantData,
    protected entityRiskData: EntityRiskData,
    protected zipCodeData: ZipCodeData,
    protected policyBillingData: PolicyBillingData,
    protected quickQuoteService: QuickQuoteService,
    protected autoReinstatementData: AutoReinstatementData,
    public interestSavingData: InterestSavingData,
    protected store: Store
  ) {

    super();
    this.selectRaterIsLoading$ = this.store.pipe(select(selectRaterIsLoading));
  }

  get billingForm() {
    return this.bindAndIssueData.billingForm;
  }

  get isUpdateRecurringPayment(): boolean {
    return this.bindAndIssueData.billingForm.get('updateRecurringPayment')?.value;
  }

  get isUpdateRecurPaymentFieldsValid(): boolean {
    return this.bindAndIssueData.billingForm.get('ccCVV')?.valid && this.bindAndIssueData.billingForm.get('ccExpirationYear')?.valid && this.bindAndIssueData.billingForm.get('ccExpirationMonth')?.valid
      && this.bindAndIssueData.billingForm.get('ccNumber')?.valid && this.bindAndIssueData.billingForm.get('ccType')?.valid;
  }

  ngOnInit() {
    this.initSubscribeToRaterIsLoading();

    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(userType => {
      this.isInternal = userType.toLocaleLowerCase() === 'internal';

      this.getPaymentMethods();
    });

    if (!this.entityRiskData.isDonePopulatingForms) {
      this.entityRiskData.isDonePopulatingForms$.pipe(takeUntil(this.stop$)).subscribe(() => {
        this.autofillAccountDetails();
      });
    } else {
      this.autofillAccountDetails();
    }
  }

  collapse() {
    this.isOpen = !this.isOpen;
    this.submissionData.isBillingOpen = this.isOpen;
  }

  onSelectPaymentMethod(event?) {
    const value = event.target.value;
    this.setPaymentMethodOption(value);
    this.bindAndIssueService.checkBillingSection();
  }

  setPaymentMethodOption(value?) {
    const cashCheckConditions = ['PM0', 'PM2', 'PM4'];

    if (this.bindAndIssueData.isFromRenewal || (!this.bindAndIssueData.isFromRenewal && cashCheckConditions.includes(value))) {
      this.bindAndIssueData.showTotalChargeAmountField = true;
    } else {
      this.bindAndIssueData.showTotalChargeAmountField = false;
    }

    this.subscribeToRaterIsLoading(this.billingForm.get('paymentPlan').value ?? undefined);
    this.setValidationForCheck();
  }

  onSelectNextTermPaymentPlan() {
    this.billingForm.get('nextTermPayor').setValue('');
    if (this.billingForm.get('nextTermPaymentPlan').value !== this.PaymentPlan.fullPayCode) {
      this.billingForm.get('nextTermPayor').disable();
    } else {
      this.billingForm.get('nextTermPayor').enable();
    }
  }

  onSelectPaymentPlan(event?) {
    this.billingForm.get('payor').setValue('');
    if (this.billingForm.get('paymentPlan').value !== this.PaymentPlan.fullPayCode) {
      this.billingForm.get('payor').disable();
    } else {
      this.billingForm.get('payor').enable();
    }

    const paymentPlan = event.target.value;

    const updatePayPlanPayload: UpdateRenewalPayPlanRequestDTO = {
      riskId: this.entityRiskData.getRiskId(),
      riskDetailId: this.entityRiskData.getRiskDetailId(),
      riskBindBilling: {
        id: this.entityRiskData.getRiskBindBillingId(),
        payPlanOption: paymentPlan,
        isEnrolledToAutopay: this.policyBillingData.isEnrolledToAutopay
      }
    };

    switch (paymentPlan) {
      case PaymentPlanListConstants.fullPayCode:
        this.paymentPlanDesc = PaymentPlanListConstants.fullPayDesc;
        this.bindAndIssueData.showTotalChargeAmountField = false;
        break;
      case PaymentPlanListConstants.twoPayCode:
        this.paymentPlanDesc = PaymentPlanListConstants.twoPayDesc;
        this.bindAndIssueData.showTotalChargeAmountField = false;
        break;
      case PaymentPlanListConstants.fourPayCode:
        this.paymentPlanDesc = PaymentPlanListConstants.fourPayDesc;
        this.bindAndIssueData.showTotalChargeAmountField = false;
        break;
      case PaymentPlanListConstants.eightPayCode:
        this.paymentPlanDesc = PaymentPlanListConstants.eightPayDesc;
        this.bindAndIssueData.showTotalChargeAmountField = false;
        break;
      case PaymentPlanListConstants.mortgageePayCode:
        this.bindAndIssueData.billingForm.get('paymentMethod').disable();
        this.bindAndIssueData.billingForm.get('paymentMethod').setValue('');
        this.paymentPlanDesc = PaymentPlanListConstants.mortgageePayDesc;
        this.bindAndIssueData.showTotalChargeAmountField = false;
        break;
      default:
        break;
    }

    if (this.isPaymentMethodDisabled(this.paymentMethod)) {
      this.billingForm.get('paymentMethod').setValue('');
    }

    if (paymentPlan !== PaymentPlanListConstants.mortgageePayCode) {
      this.bindAndIssueData.billingForm.get('paymentMethod').enable();
    }

    this.bindAndIssueService.checkBillingSection();

    this.subscribeToRaterIsLoading(paymentPlan);

    if (this.entityRiskData.statusesForUpdateRenewalPayplan() && localStorage.getItem('pageType') !== 'policies') {
      NotifUtils.showConfirmMessage(`Are you sure you want to change your pay plan to ${this.paymentPlanDesc}?`, () => {
        Utils.blockUI();
        this.quickQuoteService.updateRenewalPayPlan(updatePayPlanPayload).subscribe(res => {
          Utils.blockUI();
          if (res) {
            NotifUtils.showSuccess('Pay plan succesfully changed', () => {
              Utils.blockUI();
              this.policyBillingData.populateRnBillingSummary(this.entityRiskData.getRiskDetailId(), paymentPlan);
              this.autoReinstatementData.populateAutoReinstateData();
              this.policyBillingData.getRecurringPaymentDetails(this.entityRiskData.getRiskId());
              this.entityRiskData.updateAllRiskDetailsRiskBindBillingPaymentPayPlan(paymentPlan);
            });
          }
        });
      }, () => {
        this.billingForm.get('paymentPlan').setValue(this.entityRiskData.getRiskBindBillingPayPlan());
        this.bindAndIssueData.downPaymentComputation(this.entityRiskData.getRiskBindBillingPayPlan());
        this.bindAndIssueData.totalChargeAmountComputation(this.policyBillingData.rnBillingSummary);
      });
    }
  }

  onSelectPayor() {
    if (this.entityRiskData.statusesForUpdateRenewalPayplan() && localStorage.getItem('pageType') !== 'policies') {
      this.interestSavingData.saveInterest(this.billingForm.get('payor').value === '' ? null : this.billingForm.get('payor').value, null, true);
    } else {
      this.interestSavingData.interestsData.selectedPayorId = this.billingForm.get('payor').value === '' ? null : this.billingForm.get('payor').value;
    }
  }

  get isRecurringPaymentMethod(): boolean {
    const selectedPaymentMethod: string = this.bindAndIssueData.billingForm.get('paymentMethod').value;
    const recurringPayments: string[] = [PaymentMethod.RecurringCreditCard, PaymentMethod.RecurringECheck];

    return recurringPayments.indexOf(selectedPaymentMethod) > -1;
  }

  get isOneTimePaymentAuthRequired(): boolean {
    const selectedPaymentMethod: string = this.bindAndIssueData.billingForm.get('paymentMethod').value;
    const authRequiredPaymentMethods: string[] = [PaymentMethod.CreditCard, PaymentMethod.EFT, PaymentMethod.ECheck];

    return authRequiredPaymentMethods.indexOf(selectedPaymentMethod) > -1;
  }

  get paymentMethod(): string {
    return this.billingForm.get('paymentMethod').value;
  }

  get isRecurringPayment(): boolean {
    return this.policyBillingData?.rnBillingSummary?.isRecurringPayment;
  }

  getPaymentMethods(): void {
    const paymentMethods = this.bindAndIssueData.paymentMethodList;
    if (!this.isInternal) {
      this.paymentMethods = paymentMethods.filter(x => x.code !== PaymentMethod.CashCheck);
    } else {
      this.paymentMethods = paymentMethods;
    }
  }


  isPaymentMethodDisabled(paymentMethodCode: string): boolean {
    const recurringPayments: string[] = [PaymentMethod.RecurringCreditCard, PaymentMethod.RecurringECheck];
    const isFullPay = this.billingForm.get('paymentPlan').value === PaymentPlanListConstants.mortgageePayCode;

    return (isFullPay && recurringPayments.includes(paymentMethodCode));
  }

  autofillAccountDetails(): void {

    const country = this?.applicantData?.mainApplicant?.get('country')?.value;

    this.bindAndIssueData.billingForm.get('emailAddress').setValue(this.applicantData.contact.get('emailAddress').value);

    let address: AddressDTO3 = { streetAddress1: '', city: '', zipCode: '' };
    if (this.entityRiskData?.EntityRisk?.isMailingAddressSameInsured) {
      address = this.entityRiskData?.EntityRisk?.entityAddresses.find(a => a.addressTypeCode === AddressType.Property)?.address;
    } else {
      address.streetAddress1 = this.applicantData.mainApplicant.get('mailingAddress').value ?? '';
      address.city = this.applicantData.mainApplicant.get('city').value ?? '';
      address.zipCode = this.applicantData.mainApplicant.get('zipCode').value ?? '';
    }

    if (this.bindAndIssueData.billingForm.pristine) {
      this.bindAndIssueData.billingForm.get('firstName').setValue(this.applicantData.mainApplicant.get('firstName').value);
      this.bindAndIssueData.billingForm.get('lastName').setValue(this.applicantData.mainApplicant.get('lastName').value);
      this.bindAndIssueData.billingForm.get('address').setValue(address?.streetAddress1);
      this.bindAndIssueData.billingForm.get('zipCode').setValue(address?.zipCode);
      if (country === 'USA') {
        this.setZipCode(address?.city);
      }
    }
  }

  setZipCode(city: string): void {
    this.billingForm.get('city').enable();
    const zipCode = this.billingForm.get('zipCode').value;
    const formControlNames = ['state', 'address', 'city', 'zipCode'];
    Utils.blockUI();
    if (zipCode !== '') {
      this.zipCodeData.getCityListCompleted$.pipe(take(1)).subscribe(() => {
        this.billingForm.get('city').setValue(city);
      });

      this.zipCodeData.getGenericZipCode(zipCode, this.billingForm, formControlNames, PageSections.Billing);
    } else {
      this.FormUtils.resetFields(this.billingForm, formControlNames);
      this.zipCodeData.billingCityList = [];
      Utils.unblockUI();
    }
  }

  onSaveBillingRecurringPayment(): void {
    this.policyBillingData.savePaymentProfile(this.entityRiskData?.getRiskId(), this.billingForm);
  }

  get isCheckImageNotUploaded(): boolean {
    return !Boolean(this.policyBillingData.uploadedCheckImage?.isUploaded) && (this.bindAndIssueData.billingForm.get('paymentMethod')?.value === PaymentMethod.CashCheck);
  }

  private setValidationForCheck(): void {
    if (this.bindAndIssueData.billingForm.get('paymentMethod')?.value === PaymentMethod.CashCheck) {
      this.bindAndIssueData.billingForm.get('checkNumber').setValidators([Validators.required]);
      this.bindAndIssueData.billingForm.get('checkNumber').updateValueAndValidity();
    } else {
      this.policyBillingData.resetCheckImageModel();
      this.bindAndIssueData.billingForm.get('checkNumber').setValue('');
      this.bindAndIssueData.billingForm.get('checkNumber').clearValidators();
      this.bindAndIssueData.billingForm.get('checkNumber').updateValueAndValidity();
    }
  }

  subscribeToRaterIsLoading(paymentPlan?): void {
    this.bindAndIssueData.downPaymentComputation(paymentPlan);
    this.bindAndIssueData.totalChargeAmountComputation();
  }

  initSubscribeToRaterIsLoading(): void {
    this.selectRaterIsLoading$.subscribe(result => {
      if (result) {
        this.setPaymentMethodOption(this.billingForm.get('paymentMethod').value);
      }
    });
  }
}
