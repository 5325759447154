import { Pipe, PipeTransform } from '@angular/core';
import { mode, TransferModeConstants } from '../../../shared/constants/book-transfer.constants';

@Pipe({
  name: 'disableSubmitButton'
})
export class DisableSubmitButtonPipe implements PipeTransform {
  private transferModeConstants = TransferModeConstants;

  transform(
    fromAgencyFormInvalid: boolean,
    operationMode: mode,
    toAgencyFormInvalid: boolean,
    transferDetailsFormInvalid: boolean,
    forTransferPoliciesLength: number,
    toSubAgencyAgentsLength: number,
    isBatchStatusCompleted: boolean

  ): boolean {
    const hasNoToAgent = operationMode === this.transferModeConstants.new ? toSubAgencyAgentsLength === 0 : false;

    const disableSubmitButton = fromAgencyFormInvalid ||
      toAgencyFormInvalid ||
      transferDetailsFormInvalid ||
      forTransferPoliciesLength === 0 ||
      hasNoToAgent ||
      isBatchStatusCompleted;

    return disableSubmitButton;
  }
}
