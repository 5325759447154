import { Injectable } from '@angular/core';
import { BaseClass } from 'app/shared/base-class';
import { ClaimsData } from 'app/modules/submission-management/data/claims.data';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { EndorsementsData } from 'app/modules/submission-management/data/endorsements.data';
import { CoveragesData } from '../../../../modules/submission-management/data/coverages.data';

interface formType {
  ho3: string;
  ho4: string;
  ho6: string;
  dp3: string;
}

@Injectable({
  providedIn: 'root'
})
export class ClaimsService extends BaseClass {
  specialClaimTypeCodes: string[] = [
    'BINONAUTO',
    'CREDIT',
    'DAMAG',
    'DOG',
    'ID',
    'LIABILITY',
    'MEDICAL',
    'MOLDL',
    'PINONAUTO',
    'PLBI',
    'PLPD',
    'ROBBERY',
    'SBKP',
    'SLIP',
    'THEFT',
    'WBKP'
  ];
  claimTypeCode: string = '';
  formType: formType = {
    ho3: 'HO3',
    ho4: 'HO4',
    ho6: 'HO6',
    dp3: 'DP3'
  };

  constructor(
    protected claimsData: ClaimsData,
    protected summaryData: SummaryData,
    protected endorsementsData: EndorsementsData,
    protected coveragesData: CoveragesData
  ) {
    super();
  }

  getChargeableField(claimTypeCode: string): boolean {
    this.claimTypeCode = claimTypeCode;
    return this[`${claimTypeCode.toLowerCase()}`]();
  }

  getChargeableFieldUpdate(claimTypeCode: string): boolean {
    if (this.specialClaimTypeCodes.includes(claimTypeCode)) {
      return this[`${claimTypeCode.toLowerCase()}`]();
    }

    return this.claimsData.claimTypes.find((type) => claimTypeCode?.toUpperCase() === type.code.toUpperCase()).chargeable;
  }

  binonauto(): boolean {
    return (this.isPersonalLiabAttached && this.isDP3) || this.isHO3orHO4orHO6;
  }

  credit(): boolean {
    return this.isHO3orHO4orHO6;
  }

  damag(): boolean {
    return (this.isPersonalLiabAttached && this.isDP3) || this.isHO3orHO4orHO6;
  }

  dog(): boolean {
    return this.endorsementsData.endorsementsGroup.get('animalLiability').value && this.isHO3orHO4orHO6;
  }

  id(): boolean {
    return this.endorsementsData.endorsementsGroup.get('identityFraud').value;
  }

  liability(): boolean {
    return (this.isPersonalLiabAttached && this.isDP3) || this.isHO3orHO4orHO6;
  }

  medical(): boolean {
    return (this.isPersonalLiabAttached && this.isDP3) || this.isHO3orHO4orHO6;
  }

  moldl(): boolean {
    return this.isHO3orHO4orHO6;
  }

  other(): boolean {
    return this.defaultChargeableValue;
  }

  pinonauto(): boolean {
    return this.endorsementsData.endorsementsGroup.get('personalInjury').value;
  }

  plbi(): boolean {
    return (this.isPersonalLiabAttached && this.isDP3) || this.isHO3orHO4orHO6;
  }

  plpd(): boolean {
    return (this.isPersonalLiabAttached && this.isDP3) || this.isHO3orHO4orHO6;
  }

  robbery(): boolean {
    if (this.isHO3orHO4orHO6) {return true;}
    return this.endorsementsData.endorsementsGroup.get('limitedTheftCoverage').value;
  }

  sbkp(): boolean {
    return this.endorsementsData.endorsementsGroup.get('waterBackup').value;
  }

  slip(): boolean {
    return (this.isPersonalLiabAttached && this.isDP3) || this.isHO3orHO4orHO6;
  }

  theft(): boolean {
    if (this.isHO3orHO4orHO6) {return true;}
    return this.endorsementsData.endorsementsGroup.get('limitedTheftCoverage').value;
  }

  wbkp(): boolean {
    return this.endorsementsData.endorsementsGroup.get('waterBackup').value;
  }

  get defaultChargeableValue(): boolean {
    return this.claimsData.claimTypes.find((type) => this.claimTypeCode === type.code).chargeable;
  }

  get isHO3(): boolean {
    return this.summaryData.SummaryForm.get('formType')?.value === this.formType.ho3;
  }

  get isHO4(): boolean {
    return this.summaryData.SummaryForm.get('formType')?.value === this.formType.ho4;
  }

  get isHO6(): boolean {
    return this.summaryData.SummaryForm.get('formType')?.value === this.formType.ho6;
  }

  get isDP3(): boolean {
    return this.summaryData.SummaryForm.get('formType')?.value === this.formType.dp3;
  }

  get isHO3orHO4(): boolean {
    return this.isHO3 || this.isHO4;
  }

  get isHO4orHO6(): boolean {
    return this.isHO4 || this.isHO6;
  }

  get isHO3orHO4orHO6(): boolean {
    return this.isHO3 || this.isHO4 || this.isHO6;
  }

  get isHO3orHO6(): boolean {
    return this.isHO3 || this.isHO6;
  }

  get isHO3orDP3(): boolean {
    return this.isHO3 || this.isDP3;
  }

  get isHO3orDP3orHO6(): boolean {
    return this.isHO3 || this.isDP3 || this.isHO6;
  }

  get isHO3orDP3orHO4orHO6(): boolean {
    return this.isHO3 || this.isDP3 || this.isHO4 || this.isHO6;
  }

  get isPersonalLiabAttached(): boolean {
    return this.coveragesData.isCoverageLorMEnabled || this.endorsementsData.endorsementsGroup.get('personalLiability').value;
  }
}
