import { AbstractControl, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { EndorsementValidatorErrorConstants } from './validator-error-constants/endorsement-validator-error.constants';
import { LA_EndorsementValidatorErrorConstants } from 'app/states/la/shared/constants/validator-error-constants/endorsement-validator-error.constants';

export class EndorsementValidators {
  static personalPropertyAtOtherResidencesLimitDivisibleValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control && control.value !== 0 && control.value % 1000 !== 0) {
        key[EndorsementValidatorErrorConstants.personalPropertyAtOtherResidencesLimitDivisibleErrorMessage.key] = true;
        return key;
      }
      return null;
    };
  }

  static personalPropertyAtOtherResidencesLimitNotZeroValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control && control.value === 0) {
        key[EndorsementValidatorErrorConstants.personalPropertyAtOtherResidencesLimitNotZeroErrorMessage.key] = true;
        return key;
      }
      return null;
    };
  }

  static coverageCIncreasedAmountCannotBeBothZero(otherIncreasedAmount: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if ((control.value !== '' && control.value !== null && control.parent.get(otherIncreasedAmount).value !== '')
        && (Number(control.value) === 0 && Number(control.parent.get(otherIncreasedAmount).value) === 0)) {
        key[EndorsementValidatorErrorConstants.coverageCIncreasedAmountCannotBeBothZeroErrorMessage.key] = true;
        return key;
      }
      return null;
    };
  }

  static limitedWaterDamage15Years(yearBuiltAge: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if ((control.value !== '' && control.value === false && yearBuiltAge > 15)) {
        key[EndorsementValidatorErrorConstants.limitedWaterDamageYear15.key] = true;
        return key;
      }
      return null;
    };
  }

  static nonStructuralHailLossLimitationMetalEndorsement(roofMaterial: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      const metalRoofs = ['METC', 'META'];
      if ((control.value !== '' && control.value === false && metalRoofs.includes(roofMaterial))) {
        key[EndorsementValidatorErrorConstants.nonStructuralHailLossLimitationIsMetal.key] = true;
        return key;
      }
      return null;
    };
  }

  static nonStructuralHailLossLimitationAluminumEndorsement(exteriorWallFinish: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      const exteriorWall = ['AOM'];
      if ((control.value !== '' && control.value === false && exteriorWall.includes(exteriorWallFinish))) {
        key[EndorsementValidatorErrorConstants.nonStructuralHailLossLimitationIsAluminum.key] = true;
        return key;
      }
      return null;
    };
  }

  static acvLossSettlementWindHailRoofBRUL322and323(roofMaterial: string, yearRoofAge: number, effectiveDateYear: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      const roofMaterialBRUL322 = ['COMP3'];
      const roofMaterialBRUL323 = ['COMPI', 'COMPA'];
      const brul322Age = effectiveDateYear - yearRoofAge;
      const brul323Age = effectiveDateYear - yearRoofAge;
      if ((control.value !== '' && control.value === false && ((roofMaterialBRUL322.includes(roofMaterial) && brul322Age >= 20) || (roofMaterialBRUL323.includes(roofMaterial) && brul323Age >= 25)))) {
        key[EndorsementValidatorErrorConstants.acvLossSettlementWindHailRoofBRUL322and323.key] = true;
        return key;
      }
      return null;
    };
  }

  static limitedWaterDamage50Years(yearBuiltAge: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if ((control.value !== '' && control.value === false && yearBuiltAge > 50)) {
        key[EndorsementValidatorErrorConstants.limitedWaterDamageYear50.key] = true;
        return key;
      }
      return null;
    };
  }

  static limitedWaterDamage40Years(yearBuiltAge: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if ((control.value !== '' && control.value === false && (yearBuiltAge > 40))) {
        key[EndorsementValidatorErrorConstants.limitedWaterDamageYear40.key] = true;
        return key;
      }
      return null;
    };
  }

  static jewelryIncreaseAmountMustBeIncreamentOf100(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      const val = Number(control.value);
      if (val % 100 !== 0 && val < 4000) {
        key[EndorsementValidatorErrorConstants.jewelryIncreaseAmountMustBeIncreamentOf100.key] = true;
        return key;
      }
      return null;
    };
  }

  static limitCannotBeZero(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control?.value !== null && Number(control?.value) === 0) {
        key[EndorsementValidatorErrorConstants.limitCannotBeZero.key] = true;
        return key;
      }
      return null;
    };
  }

  static personalLiabilityBRUL173(covL: boolean, covM: boolean, structureType: string, isPersonalLiab: boolean, propertyUsage: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      const multiFam = 'MU';
      const propertyUsageOption = ['UOCC', 'VCN'];
      if ((control.value !== '' && ((covL && covM) && isPersonalLiab && (structureType === multiFam || propertyUsageOption.includes(propertyUsage))))) {
        key[LA_EndorsementValidatorErrorConstants.personalLiabilityBRUL173.key] = true;
        return key;
      }
      return null;
    };
  }

  static fortifiedRoofReplacementEndorsementBRUL401(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key: any = {};
      if (control?.value !== null && control?.value === false) {
        key[LA_EndorsementValidatorErrorConstants.fortifiedRoofReplacementEndorsementBRUL401.key] = true;
        return key;
      }
      return null;
    };
  }
}
