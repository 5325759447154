import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InterestData } from 'app/modules/submission-management/data/interest.data';
import { EntityRiskDTO } from 'app/shared/models/data/dto/quick-quote/entity-risk.dto';
import { RiskInterestDetailDTO2 } from 'app/shared/models/data/dto/quick-quote/risk-interest-detail.dto';
import { LvRoofMaterial } from 'app/shared/constants/property.options.constants';
import { BaseClass } from '../../../shared/base-class';
import { PolicySummaryLabelsConstants } from '../../../shared/constants/policy-summary.labels.constant';
import {
  ITableTh,
  ITableTr,
  ITableFormControl,
  IHideTableItems,
} from '../../../shared/models/dynamic/table.interface';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { AddressType } from 'app/shared/enum/address-type.enum';
import { AddressDTO3 } from 'app/shared/models/data/dto/quick-quote/entity-address.dto';
import Utils from 'app/shared/utilities/utils';
import { QuoteService } from '../../../core/services/quote.service';
import { FormTypeConstants } from '../../../shared/constants/form-types.constants';
import { CurrencyPipe } from '@angular/common';
import { IEndorsementMap, QPEndorsementsMap } from 'app/shared/constants/qp-endorsements.map.constants';
import { Rater } from 'app/shared/constants/rater.constant';
import { FormGroupType } from 'app/shared/enum/form-group-type.enum';
import { RenewalService } from 'app/core/services/submission/renewal/renewal.service';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { takeUntil } from 'rxjs/operators';
import { FlagRenewalDTO } from 'app/shared/models/data/dto/renewal/flag-renewal.dto';
import { BehaviorSubject, Subject } from 'rxjs';
import { RiskStatusCode } from 'app/shared/models/data/dto/quick-quote/risk.dto';

type RenewalSubStatusCode = null | 'CRW' | 'SFNRW' | 'NRWD';
type RenewalStatusCode = null | 'RNPEN' | 'RWO' | 'RRW' | 'RWNT' | 'RRNPEN' | 'RRWO';

@Injectable({
  providedIn: 'root',
})
export class PolicySummaryData extends BaseClass {
  protected readonly included: string = 'Included';
  public PolicySummaryLabelsConstants = PolicySummaryLabelsConstants;
  public LvRoofMaterial = LvRoofMaterial.filter(val => val.isActive === true);
  public entityId = '';
  public isPolicy: boolean = false;
  public isPolicyCheckComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isEditPolicy: boolean = false;
  public clonePolicyId: string = '';
  public riskDetailId: string = '';
  public previousCategoryRoute: string;

  // uwr related
  public allItemsChecked: boolean = false;
  public nonPostBindUwrItemsApproved: boolean = false;
  public uwrListEmpty: boolean = true;
  public uwrListAllPostBind: boolean = false;
  public uwrListAllNonPostBind: boolean = false;
  public postBindUwrItemsApproved: boolean = false;
  public nonPostBindUwrItemsChecked: boolean = false;
  public hasUwApprovalChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isPrivateFloodUpdated: boolean = false;
  public isFromDeclinedEdit: boolean = false;

  // renewal related
  public withinRenewalPeriod: boolean = false;
  public withinNonRenewalPeriod: boolean = false;
  public renewalSubStatusCode: BehaviorSubject<RenewalSubStatusCode> = new BehaviorSubject<RenewalSubStatusCode>(null);
  public renewalStatusCode: RenewalStatusCode = null;
  public renewalCode: RenewalStatusCode = null;

  // saving related
  public saveCoveragesComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public saveEndorsementsComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public saveClaimsComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public savePropertyComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public saveApplicantComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public saveInterestsComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public saveUwApprovalComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public showRiskSubStatus: boolean = false;
  public policyChanges: string[] = [];

  // nav out saving counter
  public navOutCoveragesSavingCounter$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public navOutEndorsementsSavingCounter$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public navOutClaimsSavingCounter$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public navOutPropertySavingCounter$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public navOutApplicantSavingCounter$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public navOutInterestsSavingCounter$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public navOutUwApprovalSavingCounter$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public isReset: boolean = false;
  public subStatusCode$: Subject<RiskStatusCode | null> = new Subject<RiskStatusCode | null>();

  insuredForm: FormGroup;
  mainCoveragesForm: FormGroup;
  deductiblesForm: FormGroup;
  propertyCharacteristicsForm: FormGroup;
  hideItems: IHideTableItems = {
    addButton: true,
    updateIcon: true,
    deleteIcon: true,
    fileIcon: true,
    checkboxButton: true,
    viewIcon: true
  };
  optionalCoveragesTableHeaders: ITableTh[] = [
    { value: this.PolicySummaryLabelsConstants.optionalCoveragesLimits.coverages },
    { value: this.PolicySummaryLabelsConstants.optionalCoveragesLimits.limit },
    { value: this.PolicySummaryLabelsConstants.optionalCoveragesLimits.premium },
  ];
  interestsTableHeaders: ITableTh[] = [
    { value: this.PolicySummaryLabelsConstants.interests.rank },
    { value: this.PolicySummaryLabelsConstants.interests.interestType },
    { value: this.PolicySummaryLabelsConstants.interests.interest },
    { value: this.PolicySummaryLabelsConstants.interests.address },
    { value: this.PolicySummaryLabelsConstants.interests.loanNumber }
  ];
  optionalCoveragesTableRows: ITableTr[] = [];
  interestsTableRows: ITableTr[] = [];
  optionalCoveragesTableFormControls: ITableFormControl[] = [
    {
      name: 'field_1',
      type: 'string',
    },
    {
      name: 'field_2',
      type: 'string',
    },
  ];
  interestsTableFormControls: ITableFormControl[] = [
    {
      name: 'field_1',
      type: 'string',
    },
    {
      name: 'field_2',
      type: 'string',
    },
    {
      name: 'field_3',
      type: 'string',
    },
    {
      name: 'field_4',
      type: 'string',
    },
    {
      name: 'field_5',
      type: 'string',
    },
    {
      name: 'field_6',
      type: 'string',
    },
  ];
  formTypeConstants = FormTypeConstants;
  formType: string;
  navigatedToPolicyIssue: boolean = false;
  enablePolicyNavSaving: boolean = false;

  constructor(public interestData: InterestData,
    protected quoteService: QuoteService,
    protected currencyPipe: CurrencyPipe,
    protected renewalService: RenewalService
  ) {
    super();
  }

  initForms(): void {
    this.insuredForm = this.insuredFormSection();
    this.mainCoveragesForm = this.mainCoveragesFormSection();
    this.deductiblesForm = this.deductiblesFormSection();
    this.propertyCharacteristicsForm = this.propertyCharacteristicsFormSection();
    this.interestsTableRows = [];
  }

  insuredFormSection(): FormGroup {
    return new FormGroup({
      mailingAddress: new FormControl(''),
      mobilePhone: new FormControl(''),
      email: new FormControl(''),
    });
  }

  protected mainCoveragesFormSection(): FormGroup {
    return new FormGroup({
        coverageA: new FormControl(''),
        coverageB: new FormControl(''),
        coverageC: new FormControl(''),
        coverageD: new FormControl(''),
        coverageE: new FormControl(''),
        coverageF: new FormControl(''),
        coverageDorE: new FormControl(''),
        coverageL: new FormControl(''),
        coverageM: new FormControl('')
    });
  }

  protected deductiblesFormSection() {
    return new FormGroup({
        allOtherPerils: new FormControl(''),
        hurricane: new FormControl(''),
    });
  }

  protected propertyCharacteristicsFormSection() {
    return new FormGroup({
        yearBuilt: new FormControl(''),
        protectionClass: new FormControl(''),
        county: new FormControl(''),
        squareFootage: new FormControl(''),
        roofMaterial: new FormControl(''),
        yearRoofInstalled: new FormControl(''),
    });
  }

  populatesPolicySummaryPage(data: EntityRiskDTO): void {
    // Insured Section
    let address: AddressDTO3 = {};
    if (data.isMailingAddressSameInsured) {
      address = data?.entityAddresses.find(a => a.addressTypeCode === AddressType.Property)?.address;
    } else {
      address = data?.entityAddresses.find(a => a.addressTypeCode === AddressType.Mailing)?.address;
    }
    if (address) {
      const mailingAddress = Utils.getFullAddress(address, true);
      this.insuredForm.get('mailingAddress').setValue(mailingAddress);
    }
    this.insuredForm.get('mobilePhone').setValue(data?.mobilePhone);
    this.insuredForm.get('email').setValue(data?.personalEmailAddress);

    // Main Coverages / Limits Section
    const coverages = data.risks[0]?.riskDetails[0]?.riskCoverages[0];
    this.mainCoveragesForm.get('coverageA').setValue(coverages.covA);
    this.mainCoveragesForm.get('coverageB').setValue(coverages.covB);
    this.mainCoveragesForm.get('coverageC').setValue(coverages.covC);
    this.mainCoveragesForm.get('coverageD').setValue(coverages.covD);
    this.mainCoveragesForm.get('coverageE').setValue(coverages.covE);
    this.mainCoveragesForm.get('coverageF').setValue(coverages.covF);
    this.mainCoveragesForm.get('coverageDorE').setValue(coverages.fairRentalAddlLiving);
    this.mainCoveragesForm.get('coverageL').setValue(coverages.coverageL);
    this.mainCoveragesForm.get('coverageM').setValue(coverages.coverageM);

    // Deductibles Section
    this.quoteService.formTypeObs$.subscribe((formType) => {
      this.formType = formType;
      this.deductiblesForm.get('hurricane').setValue(this.getHurricane(coverages.hurricaneDeductible));
    });
    this.deductiblesForm.get('allOtherPerils').setValue(coverages.allOtherPerilsDeductible);
    this.deductiblesForm.get('hurricane').setValue(this.getHurricane(coverages.hurricaneDeductible));

    // Optional Coverages / Limits
    this.populateOptionalCoverages(data);

    // Property Characteristics
    const property = data.risks[0]?.riskDetails[0]?.riskProperties[0];
    this.propertyCharacteristicsForm.get('yearBuilt').setValue(property.yearBuilt);
    this.propertyCharacteristicsForm.get('protectionClass').setValue(property.protectionClassCode);
    this.propertyCharacteristicsForm.get('county').setValue(property.county);
    this.propertyCharacteristicsForm.get('squareFootage').setValue(property.squareFootage);
    const roofMaterial = this.LvRoofMaterial.find(a => a.code === property.roofMaterialCode)?.description;
    this.propertyCharacteristicsForm.get('roofMaterial').setValue(roofMaterial);
    this.propertyCharacteristicsForm.get('yearRoofInstalled').setValue(property.yearRoofInstalled);

    // Interests Section
    const interest = data.risks[0]?.riskDetails[0]?.riskInterests[0]?.riskInterestDetails;
    if (interest?.length) {
      this.populateSummaryInterests(interest);
    } else {
      this.interestsTableRows = [];
    }
  }

  getHurricane(hurricane?) {
    return +hurricane === PolicySummaryLabelsConstants.hurricaneDeductible500 ?
        `$ ${hurricane}` : // hurricane is $500
      hurricane === PolicySummaryLabelsConstants.hurricaneDeductibleExcluded ?
        `${PolicySummaryLabelsConstants.hurricaneExcluded}` : // if hurricane deductible is 'E'
      this.formType === this.formTypeConstants.HO3 || this.formType === this.formTypeConstants.DP3 ?
        `${hurricane}%` : // if hurricane is %
        this.currencyPipe.transform(hurricane, 'USD', 'symbol', '1.0-0'); // if hurricane is Exact Amount
  }

  populateSummaryInterests(interestList: RiskInterestDetailDTO2[]) {
    this.interestsTableRows = [];
    interestList.forEach((item) => {
      const rank = this.interestData.ranks.find(a => a.code === item.rankCode)?.description;
      const interestType = this.interestData.interestTypes.find(a => a.code === item.interestTypeCode)?.description;
      const address = `${item.mailingAddress ? item.mailingAddress : ''}${item.city ? ', ' + item.city : ''}${item.city || item.state || item.zipCode || item.countryCode ? ', ' : ''} ${item.state ?? ''} ${item.zipCode ?? ''} ${item.countryCode ?? ''}`;
      const tableData: ITableTr = {
        id: item.id,
        tr: [
          {
            id: item.id,
            value: rank,
            display: rank
          },
          {
            id: item.id,
            value: interestType,
            display: interestType
          },
          {
            id: item.id,
            value: `${item.interestName} ${item.interestLastName ?? ''}`,
            display: String(`${item.interestName} ${item.interestLastName ?? ''}`)
          },
          {
            id: item.id,
            value: address,
            display: address
          },
          {
            id: item.id,
            value: item.loanNumber,
            display: item.loanNumber
          }
        ]
      };
      this.interestsTableRows.push(tableData);
    });
  }

  populateOptionalCoverages(data: EntityRiskDTO) {
    this.optionalCoveragesTableRows = [];
    this.riskDetailId = data.risks[0]?.riskDetails[0]?.id;
    const formType = data.risks[0]?.formType;
    const activeEndorsements = new Array<IEndorsementMap>();

    const raterResultKey = `${Rater.raterResult}_${this.riskDetailId}`;
    const raterResult = JSON.parse(localStorage.getItem(raterResultKey));

    const endMap: IEndorsementMap[] = QPEndorsementsMap.filter(x => x.formTypesVisible.includes(formType));

    endMap.forEach(x => {
      const DTO = this.getDTO(x.formGroupType, data);

      if (DTO) {
        if (x.flagMatchValue) {
          const flagValue = DTO[x.flagMatchSource];
          if (flagValue === x.flagMatchValue) {
            activeEndorsements.push(x);
          }
        } else if (x.formGroupType === FormGroupType.Schedules) {
          if (DTO.length) {
            activeEndorsements.push(x);
          }
        } else {
          const isOn = DTO[x.riskEndorsementKey];
          if (isOn) {
            activeEndorsements.push(x);
          }
        }
      }
    });

    if (raterResult) {
      let counter = 1;
      activeEndorsements.forEach(x => {
        const DTO = this.getDTO(x.formGroupType, data);
        const limitValue = this.getEndorsementLimits(x, DTO);
        const premiumValue = this.getEndorsementValueByRaterAlias(x.alias, raterResult);

        this.addOptionalCoveragesTableRows(counter, x.label, limitValue, premiumValue);
        counter++;
      });
    }
  }

  protected getDTO(formGroupType: FormGroupType, data: EntityRiskDTO): any {
    let DTO: any;

    switch (formGroupType) {
      case FormGroupType.Schedules:
        DTO = data.risks[0]?.riskDetails[0].riskSchedules;
        break;
      case FormGroupType.Flood:
        DTO = data.risks[0]?.riskDetails[0]?.riskFloods[0];
        break;
      default:
        DTO = data.risks[0]?.riskDetails[0]?.riskEndorsements[0];
        break;
    }

    return DTO;
  }

  protected getEndorsementLimits(endMap: IEndorsementMap, DTO: any): string {
    let limitsValue = DTO[endMap.riskEndorsementValueKey];

    if (endMap.limitOptionsList) {
      const limitsValueString = this.getListViewDescriptionByCode(endMap.limitOptionsList, limitsValue);
      return limitsValueString;
    };

    if (limitsValue && !isNaN(limitsValue)) {
      limitsValue = this.formatToCurrency(limitsValue, true);
    };

    return limitsValue || '';
  }

  protected formatToCurrency(value: string, isReturnNoDecimal: boolean = false): string {
    if (isNaN(+value)) {
      return '';
    }

    const digitsInfo = isReturnNoDecimal ? '1.0-0' : '1.2-2';
    const resultValue = this.currencyPipe.transform(value, 'USD', 'symbol', digitsInfo);

    return resultValue;
  }

  protected getListViewDescriptionByCode(listView: any, code: string): string {
    let value: string = '';

    if (code) {
      const obj = listView.find(x => x.code === code);
      value = obj !== undefined ? obj['description'] : this.getSafeGuardValue(listView, code);
    }

    return value;
  }

  protected getSafeGuardValue(listView: any, code: any): string {
    let value: string = '';

    if (!isNaN(+code)) {
      const obj = listView.find(x => x.code === +code);
      value = obj !== undefined ? obj['description'] : '';
    }

    return value;
  }

  protected getEndorsementValueByRaterAlias(raterAlias: any, raterResult: any): string {
    const results = raterResult.filter(x => x.stepAlias === raterAlias);

    if (!results.length) {
      return '';
    }

    let premiumValue: string;

    if (!results[0].isMonetary) {
      premiumValue = this.included;
    } else {
      let totalValue: number = 0;

      results.map(result => {
        totalValue = totalValue + Number(result.perilValue);
      });

      premiumValue = this.formatToCurrency(totalValue.toString(), false);

      if (totalValue === 0) {
        premiumValue = this.included;
      }
    }

    return premiumValue;
  }

  addOptionalCoveragesTableRows(id, label, limit, premium) {
    const row = {
      id: id,
      tr: [
        {
          id: id,
          value: label,
          display: label,
        },
        {
          id: id,
          value: limit,
          display: limit,
        },
        {
          id: id,
          value: premium,
          display: premium,
        }
      ]
    };

    this.optionalCoveragesTableRows.push(row);
  }

  setFlagRenewalPayload(riskId?: string, riskDetailId?: string): FlagRenewalDTO {
    return {
      riskId: riskId,
      riskDetailId: riskDetailId,
      riskRenewalNotes: []
    };
  }

  callFlagRenewal(riskId?: string, riskDetailId?: string): any {
    const flagRenewalPayload: FlagRenewalDTO = this.setFlagRenewalPayload(riskId, riskDetailId);
    Utils.blockUI();
    return this.renewalService.putFlagRenewal(flagRenewalPayload).pipe(takeUntil(this.stop$));
  }

  callUnFlagRenewal(riskId?: string, riskDetailId?: string): void {
    Utils.blockUI();
    this.renewalService.putUnFlagRenewal(riskId, riskDetailId).pipe(takeUntil(this.stop$)).subscribe(() => {
      this.renewalSubStatusCode.next(null);
      Utils.unblockUI();
    }, (error) => {
      Utils.unblockUI();
      NotifUtils.showError(error);
    });
  }

  public getTableStatus(): boolean {
    return !this.isEditPolicy && this.isPolicy;
  }

  public isPolicyPage(riskId): boolean {
    return localStorage.getItem(`${riskId}_${GenericConstants.isPolicy}`) === 'true';
  }

  public isRenewalTerm(): boolean {
    return this.renewalCode !== null;
  }

  public isRenewal(): boolean {
    return this.renewalCode !== null || this.renewalStatusCode !== null;
  }
}
