import { AllRiskBindNoteDTO } from 'app/shared/models/data/dto/policy-management/all-risk-bind-note.dto';
import { createAction, props } from '@ngrx/store';

export const updatePolicyNotesIsLoadingFromPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update Policy Notes Is Loading',
  props<{ isLoading: boolean }>()
);

export const updatePolicyNotesListFromPolicyManagementComponent = createAction(
  '[PolicyManagementComponent] Update Policy Notes List',
  props<{ list: AllRiskBindNoteDTO[] }>()
);