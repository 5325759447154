import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { GenericConstants } from 'app/shared/constants/generic.constants';

@Component({
  selector: 'app-dashboard-pagination',
  templateUrl: './dashboard-pagination.component.html',
  styleUrls: ['./dashboard-pagination.component.scss']
})
export class DashboardPaginationComponent implements OnInit {
  @Input() pager: any = {};
  @Input() loading: boolean;
  @Input() userType: string;

  @Output() setPage: EventEmitter<number> = new EventEmitter<number>();
  public genericConstants = GenericConstants;
  public agentDashboardConstants = AgentDashboardConstants;

  constructor() { }

  ngOnInit() {
  }

  setPageNumber(page: number) {
    this.setPage.emit(page);
  }
}
