import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BindAndIssueValidatorErrorConstants } from './validator-error-constants/bind-and-issue-error.constants';

export class BindAndIssueValidators {
  static checkCreditCardIsExpired(dateNow: Date) {
    const key: any = {};
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const month = parseInt(control.parent.get('ccExpirationMonth').value, 10);
        const year = parseInt(control.parent.get('ccExpirationYear').value, 10);
        const currentMonth = dateNow.getMonth() + 1;
        const currentYear = dateNow.getFullYear();

        if (year < currentYear) {
          key[BindAndIssueValidatorErrorConstants.creditCardExpired.key] = true;
          return key;
        } else if (year === currentYear && month < currentMonth) {
          key[BindAndIssueValidatorErrorConstants.creditCardExpired.key] = true;
          return key;
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }
}