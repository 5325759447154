import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-under-maintenance',
  templateUrl: './under-maintenance.component.html',
  styleUrls: ['./under-maintenance.component.scss']
})
export class UnderMaintenanceComponent implements OnInit {

  undermaintenanceImage = 'https://stdocumentprod.blob.core.windows.net/other-document/Banner/Images/default_maintenance_banner.png'
  constructor() { }

  ngOnInit() {
  }

}
