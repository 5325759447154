import { Action, createReducer, on } from "@ngrx/store";
import * as fromRaterCalculateDP3Actions from "app/store/rater-calculate-dp3/rater-calculate-dp3.actions";
import { initialState, RaterCalculateDP3State } from "./rater-calculate-dp3.state";

const _raterCalculateDP3Reducer = createReducer(
  initialState,
  on(
    fromRaterCalculateDP3Actions.updateRaterCalculateDP3IsLoadingFromDP3RaterRequestData,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromRaterCalculateDP3Actions.updateRaterCalculateDP3ListFromDP3RaterRequestData,
    (state, { list }) => ({ ...state, list: list })
  )
);

export function raterCalculateDP3Reducer(
  state: RaterCalculateDP3State | null,
  action: Action
) {
  return _raterCalculateDP3Reducer(state, action);
}