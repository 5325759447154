import { Component, Input, OnInit } from '@angular/core';
import { PolicyBillingLabelsConstants } from 'app/shared/constants/policy-billing.labels.constants';
import { PolicyBillingData } from 'app/modules/policy-management/data/policy-billing.data';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { CalculateRaterData } from 'app/core/services/submission/rater-premium/calculate-rater-data.service';
import { BaseClass } from 'app/shared/base-class';
import { takeUntil } from 'rxjs/operators';
import { StatusConstants } from '../../../../../shared/constants/risk-status';
import Utils from '../../../../../shared/utilities/utils';
import { BillingService } from '../../../../../core/services/billing/billing.service';
import { PaymentProcedure } from '../../../../../shared/enum/payment-procedure.enum';
import { SubmissionPageData } from '../../../data/submission-page.data';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { PostPaymentModalComponent } from '../../../../policy-management/policy-billing/payments/post-payments/post-payment-modal/post-payment-modal.component';
import NotifUtils from '../../../../../shared/utilities/notif-utils';
import { AllowedPaymentRangeDTO } from '../../../../../shared/models/data/dto/billing/allowed-payment-range.dto';
import { OutstandingReinstatementRequirementsModalComponent } from '../../../../dashboard/components/dashboard/make-payment/outstanding-reinstatement-requirements-modal/outstanding-reinstatement-requirements-modal.component';
import { ReinstatementRequirementsMetModalComponent } from '../../../../dashboard/components/dashboard/make-payment/reinstatement-requirements-met-modal/reinstatement-requirements-met-modal.component';
import { RenewalBillingSummaryResponseDTO } from '../../../../../shared/models/data/dto/billing/renewal-billing-summary.response.dto';
import { forkJoin } from 'rxjs';
import { AutoReinstatementData } from '../../../../policy-management/data/auto-reinstatement.data';
import { AutoReinstatementConstants } from '../../../../../shared/constants/auto-reinstatement.constants';

@Component({
  selector: 'app-rn-billing-summary',
  templateUrl: './rn-billing-summary.component.html',
  styleUrls: ['./rn-billing-summary.component.scss']
})
export class RnBillingSummaryComponent extends BaseClass implements OnInit {
  isOpen: boolean = true;
  public billingSummaryConstants = PolicyBillingLabelsConstants.billingSummary;
  public paymentLabelConstants = PolicyBillingLabelsConstants.payments;
  @Input() isRenewal: boolean = false;
  modalRef: BsModalRef | null;

  constructor(public billingData: PolicyBillingData,
    protected entityRiskData: EntityRiskData,
    protected calculateRaterData: CalculateRaterData,
    public billingService: BillingService,
    protected submissionPageData: SubmissionPageData,
    protected modalService: BsModalService,
    protected autoReinstatementData: AutoReinstatementData) {
    super();
  }

  ngOnInit() {
    if (!Boolean(this.billingData.postPaymentForm)) this.billingData.initPostPaymentForm();
    this.calculateRaterData.isCalcPremium.pipe(takeUntil(this.stop$)).subscribe(() => {
      if (this.entityRiskData?.EntityRisk?.risks[0]?.renewalCode !== null && localStorage.getItem('pageType') !== 'policies') {
        this.billingData.populateRnBillingSummary(this.entityRiskData.getRiskDetailId());
      }
    });
  }

  collapse(): void {
    this.isOpen = !this.isOpen;
  }

  onOpenPaymentModal(): void {
    this.billingData.makePaymentData = null;
    this.showMakePaymentModal();
  }

  showMakePaymentModal(): void {
    Utils.blockUI();
    const expiringRiskIdForRenewalPaymentInSubmission = this.entityRiskData.getExpiringRiskIdFromSubmission();
    this.billingData.isPaymentFromRenewalSubmission = true;

    forkJoin([
      this.billingService.getAllowedPaymentRange(expiringRiskIdForRenewalPaymentInSubmission),
      this.autoReinstatementData.getOtherReinstatementRequirements(true, null)
    ])

      .pipe(takeUntil(this.stop$)).subscribe(result => {
        Utils.unblockUI();
        this.autoReinstatementData.allowedPaymentRangeFromPostPayment = result[0];
        this.autoReinstatementData.requirementsMetDetails = result[1];

        const initialState = {
          allowedPaymentRange: result[0],
          requirementsMetDetails: result[1],
          paymentProcedure: PaymentProcedure.PostBasic,
          isMakeAPayment: true,
          isForRenewalReinstatement: this.entityRiskData.getRisk()?.renewalCode === AutoReinstatementConstants.reinstateStatus.RWNT ? true : false
        };

        this.modalService.show(PostPaymentModalComponent, {
          initialState,
          backdrop: true,
          ignoreBackdropClick: true,
          class: 'modal-md',
        });
      },
        err => {
          Utils.unblockUI();
          NotifUtils.showMultiLineError(err.error?.message);
        });
  }


  get summary(): RenewalBillingSummaryResponseDTO {
    return this.billingData?.rnBillingSummary;
  }

  get expiringEntity(): any {
    if (Boolean(this.entityRiskData?.expiringPolicyEntityRisk?.risks)) this.billingData.makePaymentData.entity.risks = this.entityRiskData.expiringPolicyEntityRisk.risks;

    return this.entityRiskData?.expiringPolicyEntityRisk?.risks;
  }
}
