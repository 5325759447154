export const PolicyIssueConstants = {
  issuePolicyHeader: 'Issue Policy Change(s)',
  policyChangesHeader: 'Policy Changes',
  textForEndorsementHeader: 'Text for Endorsement Form',
  premiumChangesHeader: 'Premium Changes',
  issuePolicy: {
    changeEffectiveDateLabel: 'Change Effective Date'
  },
  textForEndorsement: {
    textForEndorsementLabel: 'Text For Endorsement Form'
  },
  premiumChanges: {
    coveragesLabel: 'Coverages',
    currentPremiumLabel: 'Premium Before Endorsement',
    newPremiumLabel: 'Annual Premium for Current Endorsement',
    proRatedPremiumLabel: 'Premium After Endorsement',
    premiumDifferenceChangeLabel: 'Premium Difference'
  },
  issuanceConfirmation: 'Are you sure you want to issue?',
  outOfSequenceEndoConfirmation: 'This issuance will result to out of sequence endorsement. Are you sure you want to continue?'
};