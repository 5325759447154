export const DistanceConstants = [
  {
    code: '01',
    description: '0 to 500 feet'
  },
  {
    code: '02',
    description: '501 to 1000 feet'
  },
  {
    code: '03',
    description: '1001 to 1500 feet'
  },
  {
    code: '04',
    description: '1501 to 2000 feet'
  },
  {
    code: '05',
    description: '2001 to 2500 feet'
  },
  {
    code: '06',
    description: '2501 ft to less than 1 mi'
  },
  {
    code: '07',
    description: '1 mi to less than 2 mi'
  },
  {
    code: '08',
    description: '2 mi to less than 3 mi'
  },
  {
    code: '09',
    description: '3 mi to less than 4 mi'
  },
  {
    code: '10',
    description: '4 mi to less than 5 mi'
  },
  {
    code: '11',
    description: '5 mi to less than 10 mi'
  },
  {
    code: '12',
    description: '10 mi to less than 15 mi'
  },
  {
    code: '13',
    description: '15 mi to less than 20 mi'
  },
  {
    code: '14',
    description: '20 mi to less than 25 mil'
  },
  {
    code: '15',
    description: '25 mi to less than 30 mil'
  },
  {
    code: '16',
    description: '30 mi and greater'
  },
];
