import { Component, EventEmitter, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { SuspendedPaymentsLabel } from '../../../../../../shared/constants/suspended-payments.labels.constants';
import { BsModalRef } from 'ngx-bootstrap';
import { SuspendedPaymentsData } from '../../../../data/suspended-payments.data';
import { GenericLabel } from '../../../../../../shared/constants/generic.labels.constants';
import { ErrorMessageConstant } from '../../../../../../shared/constants/error-message.constants';
import { FormGroup, Validators } from '@angular/forms';
import FormUtils from '../../../../../../shared/utilities/form.utils';
import { SuspendedPaymentDetailsDTO } from '../../../../../../../app/shared/models/data/dto/suspended-payment/suspended-payment-detail.dto';
import { BillingService } from '../../../../../../../app/core/services/billing/billing.service';
import NotifUtils from '../../../../../../../app/shared/utilities/notif-utils';
import { ReturnSuspendedPaymentDTO } from '../../../../../../../app/shared/models/data/dto/suspended-payment/return-suspended-payment.dto';
import Utils from '../../../../../../../app/shared/utilities/utils';
import { ZipCodeData } from '../../../../../../../app/modules/submission-management/data/zipcode.data';
import { PageSections } from '../../../../../../../app/shared/enum/page-sections.enum';
import { OfacService } from 'app/core/services/submission/third-party/ofac/ofac.service';
import { LvCountries, LvNameSuffix } from 'app/shared/constants/applicant.options.constants';
import { PolicySearchResultDTO } from 'app/shared/models/data/dto/suspended-payment/policy-list-dto';
import { GeneralValidationService } from '../../../../../../core/services/submission/validations/general-validation.service';
import { PolicyBillingData } from '../../../../../../modules/policy-management/data/policy-billing.data';


@Component({
  selector: 'app-return-modal',
  templateUrl: './return-suspended-payment-modal.component.html',
  styleUrls: ['./return-suspended-payment-modal.component.scss']
})
export class ReturnModalComponent implements OnInit, AfterViewChecked {
  public SuspendedPaymentsLabel = SuspendedPaymentsLabel;
  public GenericLabel = GenericLabel;
  public ErrorMessageConstant = ErrorMessageConstant;
  public event: EventEmitter<any> = new EventEmitter<any>();
  public FormUtils = FormUtils;
  public NameSuffixList = LvNameSuffix;

  paymentDetail: SuspendedPaymentDetailsDTO;
  policySearchResult: PolicySearchResultDTO;

  Countries = LvCountries;
  isUSCountry: boolean;

  constructor(
    public modalRef: BsModalRef,
    public suspendedPaymentsData: SuspendedPaymentsData,
    private billingService: BillingService,
    public zipCodeData: ZipCodeData,
    private ofacService: OfacService,
    private generalValidationService: GeneralValidationService,
    private billingData: PolicyBillingData,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.suspendedPaymentsData.resetFormGroup();
    this.zipCodeData.supendedPaymentCityList = [];
    this.returnSuspendedForm.get('country').setValue('USA');
    this.isUSCountry = true;
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  get returnSuspendedForm(): FormGroup {
    return this.suspendedPaymentsData.returnSuspendedFormGroup;
  }

  onReturn(): void {
    NotifUtils.showConfirmMessage(SuspendedPaymentsLabel.returnPendingModal.returnConfirmationMessage, () => {

      const concatenatedName = `${this.returnSuspendedForm.get('firstName').value} ${this.returnSuspendedForm.get('lastName').value} ${this.returnSuspendedForm.get('suffix').value}`;

      this.ofacService.runOfacOnRefundSuspendedPayment(
        this.paymentDetail.policyNumber,
        this.returnSuspendedForm.get('isIndividualYesNo').value ? this.returnSuspendedForm.get('firstName').value : this.returnSuspendedForm.get('name').value,
        this.returnSuspendedForm.get('isIndividualYesNo').value ? this.returnSuspendedForm.get('lastName').value : '',
        this.returnSuspendedForm.get('isIndividualYesNo').value ? this.returnSuspendedForm.get('suffix').value : ''
      );

      const returnRequest: ReturnSuspendedPaymentDTO = this.policySearchResult ? {
        suspendedPaymentId: this.paymentDetail.id,
        payee: {
          name: this.returnSuspendedForm.get('isIndividualYesNo').value ? concatenatedName : this.returnSuspendedForm.get('name').value,
          address: this.returnSuspendedForm.get('mailingAddress').value,
          address2: this.returnSuspendedForm.get('aptOrUnit').value,
          city: this.returnSuspendedForm.get('city').value,
          state: this.returnSuspendedForm.get('state').value,
          zip: this.returnSuspendedForm.get('zipCode').value,
          countryCode: this.returnSuspendedForm.get('country').value,
          countryName: LvCountries.find(a => a.value === this.returnSuspendedForm.get('country').value)?.name ?? ''
        },
        comment: this.returnSuspendedForm.get('comments').value,
        riskId: this.policySearchResult?.riskId
      } : {
        suspendedPaymentId: this.paymentDetail.id,
        payee: {
          name: this.returnSuspendedForm.get('isIndividualYesNo').value ? concatenatedName : this.returnSuspendedForm.get('name').value,
          address: this.returnSuspendedForm.get('mailingAddress').value,
          address2: this.returnSuspendedForm.get('aptOrUnit').value,
          city: this.returnSuspendedForm.get('city').value,
          state: this.returnSuspendedForm.get('state').value,
          zip: this.returnSuspendedForm.get('zipCode').value,
          countryCode: this.returnSuspendedForm.get('country').value,
          countryName: LvCountries.find(a => a.value === this.returnSuspendedForm.get('country').value)?.name ?? ''
        },
        comment: this.returnSuspendedForm.get('comments').value
      };

      Utils.blockUI();
      this.billingService.returnSuspendedPayment(returnRequest).subscribe(res => {
        this.modalRef.hide();

        const callback = () => NotifUtils.showSuccess(this.SuspendedPaymentsLabel.returnPendingModal.returnSuccessMessage);
        this.suspendedPaymentsData.getSuspendedPaymentList(callback);
        this.suspendedPaymentsData.reloadReturnedSuspendedPaymentList();
      },
        err => {
          Utils.unblockUI();
          NotifUtils.showMultiLineError(err.error?.message);
        });
    });
  }

  cancelReturn(): void {
    this.suspendedPaymentsData.resetFormGroup();
    this.modalRef.hide();
  }

  setZipCode() {
    this.zipCodeData.supendedPaymentCityList = [];
    if (!this.isUSCountry) { return; }
    this.returnSuspendedForm.get('city').enable();
    const zipCode = this.returnSuspendedForm.get('zipCode').value;
    const formControlNames = ['state', 'city', 'zipCode'];
    Utils.blockUI();
    if (zipCode !== '') {
      this.zipCodeData.getGenericZipCode(zipCode, this.returnSuspendedForm as FormGroup, formControlNames, PageSections.SuspendedPayment);
    } else {
      this.FormUtils.resetFields(this.returnSuspendedForm as FormGroup, formControlNames);
      this.zipCodeData.supendedPaymentCityList = [];

      Utils.unblockUI();
      NotifUtils.showError(SuspendedPaymentsLabel.returnPendingModal.zipNotFound);
    }
  }

  onChangeIndividual(value: boolean): void {
    this.suspendedPaymentsData.updateFormNameValidators(value);
  }

  onChangeCountry(countryCode: string): void {
    this.zipCodeData.interestCityList = [];
    this.isUSCountry = countryCode === 'USA' ? true : false;

    this.returnSuspendedForm.get('state').reset();
    this.returnSuspendedForm.get('city').reset();
    this.returnSuspendedForm.get('zipCode').reset();
    this.returnSuspendedForm.get('city').enable();
    this.setAddressFieldValidator(this.isUSCountry);
  }

  setAddressFieldValidator(isUSCountry: boolean): void {
    if (isUSCountry) {
      this.generalValidationService.resetValidatorFormControl(this.returnSuspendedForm, 'city', Validators.required);
      this.generalValidationService.resetValidatorFormControl(this.returnSuspendedForm, 'state', Validators.required);
      this.generalValidationService.resetValidatorFormControl(this.returnSuspendedForm, 'zipCode', Validators.required);
    } else {
      this.generalValidationService.clearValidatorFormControl(this.returnSuspendedForm, 'city');
      this.generalValidationService.clearValidatorFormControl(this.returnSuspendedForm, 'state');
      this.generalValidationService.clearValidatorFormControl(this.returnSuspendedForm, 'zipCode');
    }
  }

  get nameMaxLengthExceeded(): boolean {
    const form = this.returnSuspendedForm;

    this.billingData.checkIfNameExceededMaxLength(form, this.billingData.getNameFieldsForReturnSuspense(form.get('isIndividualYesNo').value));

    return (form.get('firstName').errors?.maxlength ||
            form.get('lastName').errors?.maxlength ||
            form.get('suffix').errors?.maxlength
          ) && (
            form.get('firstName').touched ||
            form.get('lastName').touched ||
            form.get('suffix').touched
          );
  }

  get nameLength(): number {
    return this.suspendedPaymentsData.getNameLength(this.returnSuspendedForm.get('isIndividualYesNo')?.value);
  }
}