export const LvPaidBy = [
  {
    tableName: 'LvPaidBy',
    tableDescription: 'Batch Payments Paid By',
    code: 'I',
    description: 'Insured',
    isActive: true
  },
  {
    tableName: 'LvPaidBy',
    tableDescription: 'Batch Payments Paid By',
    code: 'M',
    description: 'Mortgagee Company',
    isActive: true
  },
  {
    tableName: 'LvPaidBy',
    tableDescription: 'Batch Payments Paid By',
    code: 'T',
    description: 'Title Company',
    isActive: true
  },
  {
    tableName: 'LvPaidBy',
    tableDescription: 'Batch Payments Paid By',
    code: 'O',
    description: 'Other',
    isActive: true
  },
];

export const LvCompany = [
  {
    tableName: 'LvPaidBy',
    tableDescription: 'Batch Payments Companies',
    code: 'CSIC',
    description: 'CSIC',
    isActive: true
  },
  {
    tableName: 'LvPaidBy',
    tableDescription: 'Batch Payments Companies',
    code: 'CNIC',
    description: 'CNIC',
    isActive: true
  }
];