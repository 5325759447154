export const CoveragesValidatorErrorConstants = {
  floodCovCGreaterThanBaseErrorMessage: {
    key: 'floodCovCGreaterThanBase',
    value: 'Flood Coverage C limit cannot be greater than policy Coverage C limit.'
  },
  floodCovAGreaterThanBaseErrorMessage: {
    key: 'floodCovAGreaterThanBase',
    value: 'Flood Coverage A limit cannot be greater than policy Coverage A limit when Flood Coverage is selected.'
  },
  floodCovABaseFloodZoneBCXErrorMessage: {
    key: 'floodCovABaseFloodZoneBCX',
    value: 'Flood Coverage A limit cannot be less than $20,000 when Flood Coverage is selected.'
  },
  floodCovCBaseFloodZoneBCXErrorMessage: {
    key: 'floodCovCBaseFlodZoneBCX',
    value: 'Flood Coverage C limit cannot be less than $8,000 when Flood Coverage is selected.'
  },
  floodCovABaseFloodZoneErrorMessage: {
    key: 'floodCovABaseFloodZone',
    value: 'Flood Coverage A limit cannot be less than $100,000 when Flood Coverage is selected.'
  },
  floodCovCBaseFloodZoneErrorMessage: {
    key: 'floodCovCBaseFloodZone',
    value: 'Flood Coverage C limit cannot be less than $35,000 when Flood Coverage is selected.'
  },
  participationStatusIdShouldBeRegularProgram: {
    key: 'participationStatusId',
    value: 'Flood Coverage is not available due to participation status.'
  },
  floodCovAInfoMessage: {
    key: 'floodCovAInfoMessage',
    value: 'Flood Coverage A defaulted to maximum allowed of $2,000,000.'
  },
  floodCovCInfoMessage: {
    key: 'floodCovCInfoMessage',
    value: 'Flood Coverage C defaulted to maximum allowed of $500,000.'
  },
  fireProtectionCannotBeNoneMessage: {
    key: 'fireProtectionCannotBeNone',
    value: 'Fire protection device cannot be none.'
  },
  floodZoneVErrorMessage: {
    key: 'floodZoneVError',
    value: 'Flood Coverage Endorsement is not available due to flood zone.'
  },
  firmDateErrorMessage: {
    key: 'firmDateError',
    value: 'Year should be in range 1970 to current year.'
  },
  hurricaneDeductibleExcludedInfoMessage: {
    key: 'hurricaneDeductibleExcludedInfoMessage',
    value: 'Policy must be ex-wind due to distance to coast'
  },
  hurricaneDeductible5InfoMessage: {
    key: 'hurricaneDeductible5InfoMessage',
    value: 'Minimum deductible is 5% due to distance to coast'
  },
  hurricaneDeductible2InfoMessage: {
    key: 'hurricaneDeductible2InfoMessage',
    value: 'Minimum deductible is 2% due to distance to coast'
  },
  hurrDedExcludedDueToWaterInfoMessage: {
    key: 'hurrDedExcludedDueToWaterInfoMessage',
    value: 'Policy must be ex-wind due to distance to water'
  },
  hurrDed5DueToWaterInfoMessage: {
    key: 'hurrDed5DueToWaterInfoMessage',
    value: 'Minimum deductible is 5% due to distance to water'
  },
  hurrDed2DueToWaterInfoMessage: {
    key: 'hurrDed2DueToWaterInfoMessage',
    value: 'Minimum deductible is 2% due to distance to water'
  },
  hurricaneDedCannotBeLessThanAOP: {
    key: 'hurricaneDedCannotBeLessThanAOP',
    value: 'Hurricane deductible cannot be less than AOP'
  },
  coverageL100kMaxValue: {
    key: 'coverageL100kMaxValue',
    value: 'Maximum liability limit allowed is $100,000'
  },
  hurricaneDeductibleMustBeExcludedWhenCountyMonroe: {
    key: 'hurricaneDeductibleMustBeExcludedWhenCountyMonroe',
    value: 'Hurricane deductible must be excluded.'
  },
  hurricaneDeductibleMinimumOf5PercentRequired: {
    key: 'hurricaneDeductibleMinimumOf5PercentRequired',
    value: 'Minimum of 5% Hurricane Deductible is required.'
  },
  hurricaneDeductibleMinimumOf2PercentRequired: {
    key: 'hurricaneDeductibleMinimumOf2PercentRequired',
    value: 'Minimum of 2% Hurricane Deductible is required.'
  },
  aopDeductibleMinimumOf5000Required: {
    key: 'aopDeductibleMinimumOf5000Required',
    value: 'Minimum of $5,000 AOP Deductible is required.'
  },
  aopDeductibleMinimumOf2500Required: {
    key: 'aopDeductibleMinimumOf2500Required',
    value: 'Minimum of $2,500 AOP Deductible is required.'
  },
  covCCannotBeZero: {
    key: 'covCCannotBeZero',
    value: 'Coverage C cannot be $0.'
  },
  covCMinimum50Percent: {
    key: 'covCMinimum50Percent',
    value: 'Coverage C must be 50% when selecting the Star Package.'
  },
  covCMinimum75Percent: {
    key: 'covCMinimum75Percent',
    value: 'Coverage C must be 75% when selecting the Star Advantage Package.'
  },
  minimunHurricaneDeductible5Percent: {
    key: 'minimunHurricaneDeductible5Percent',
    value: 'Minimum 5% Hurricane Deductible required.'
  },
  minimunHurricaneDeductible1Percent: {
    key: 'minimunHurricaneDeductible1Percent',
    value: 'Minimum Hurricane deductible is 1% of Coverage A/C or $1,000.'
  },
  ho3IsDwellCourseAdded: {
    key: 'ho3IsDwellCourseAdded',
    value: 'Coverage C must be 25% of Coverage A when Dwelling in the Course of Construction is present.'
  },
  covACannotBeZero: {
    key: 'coverageCannotBeZero',
    value: 'Coverage A cannot be $0.'
  },
  dp3CovCMustIncrementOf5k: {
    key: 'dp3CovCMustIncrementOf5k',
    value: 'Coverage C can be increased or decreased in $5,000 increments.'
  },
  hurricaneDeductibleMustBeExcludedWhenPIALZoneEqualFive: {
    key: 'hurricaneDeductibleMustBeExcludedWhenPIALZoneEqualFive',
    value: 'Windstorm or Hail Exclusion is required for dwellings located in PIAL Zone 5.'
  }
};
