import { Injectable } from '@angular/core';
import { FormTypeConstants } from '../../../../shared/constants/form-types.constants';
import { EndorsementsData } from '../../../../modules/submission-management/data/endorsements.data';
import { Pages } from '../../../../shared/constants/pages-and-sections.constants';
import { QuoteService } from '../../quote.service';
import { GeneralValidationService } from './general-validation.service';
import { Validators } from '@angular/forms';
import { EndorsementValidators } from '../../../../modules/submission-management/validators/endorsements.validators';

@Injectable({
  providedIn: 'root'
})
export class EndorsementsValidationService {

  endorsementsSection: boolean;
  endorsementsSectionInvalidControls = [];
  public formTypes = FormTypeConstants;

  get EndorsementsForm() {
    return this.endorsementsData.endorsementsGroup;
  }

  constructor(protected endorsementsData: EndorsementsData, protected generalValidationService: GeneralValidationService, public quoteService: QuoteService) { }

  checkEndorsementsPage(): void {
    this.endorsementsSection = this.checkEndorsementsSection();

    this.endorsementsSectionInvalidControls = this.generalValidationService.findInvalidControls(this.EndorsementsForm);

    this.generalValidationService.mapToInvalidFormControlList(this.endorsementsSectionInvalidControls, Pages.Endorsements.pageName, Pages.Endorsements.sections.Endorsements);
  }

  checkEndorsementsSection(): boolean {
    if (this.quoteService.formType === this.formTypes.HO4) {
      this.clearEndorsementsValidationHO4();
    } else if (this.quoteService.formType === this.formTypes.HO6) {
      this.clearEndorsementsValidationHO6();
    } else if (this.quoteService.formType === this.formTypes.DP3) {
      this.clearEndorsementsValidationDP3();
    } else {
      this.clearEndorsementsValidationHO3();
    }

    if (this.EndorsementsForm.status === 'DISABLED') {
      return true;
    } else {
      return this.EndorsementsForm.valid;
    }
  }

  clearEndorsementsValidationHO3(): void {
    if (!this.EndorsementsForm.get('ordinanceLawOrCov').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'ordinanceLaw');
    }

    if (this.EndorsementsForm.get('screenedEnclosure').value === 'N') {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'screenedEnclosureLimit');
    }

    if (!this.EndorsementsForm.get('lossAssessment').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'lossAssessmentLimit');
    }

    if (!this.EndorsementsForm.get('homeComputer').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'homeComputerLimit');
    }

    if (!this.EndorsementsForm.get('golfCart').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'numberOfGolfCarts');
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'liabilityType');
    }

    if (!this.EndorsementsForm.get('coverageC').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'jewelryIncreased');
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'silverwareIncreased');
    }

    if (!this.EndorsementsForm.get('premiumAdjustment').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'premiumAdjustmentAmount');
    }

    if (!this.EndorsementsForm.get('consentToRate').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'consentToRateAmount');
    }

    this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'personalPropertyAtOtherResidencesLimit');

    this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'leaseTerm');

    if (!this.EndorsementsForm.get('permittedIncidentalOccupanciesLiability').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'descriptionOfBusiness');
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'businessLocation');
    }

    if (!this.EndorsementsForm.get('permittedIncidentalOccupancies').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'descriptionOfOccupancy');
    }
    this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'fungiWetOrDryRotLimit');
  }

  clearEndorsementsValidationHO4(): void {
    this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'ordinanceLaw');
    this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'screenedEnclosureLimit');
    this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'lossAssessmentLimit');

    if (!this.EndorsementsForm.get('homeComputer').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'homeComputerLimit');
    }

    if (!this.EndorsementsForm.get('golfCart').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'numberOfGolfCarts');
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'liabilityType');
    }

    this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'jewelryIncreased');
    this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'silverwareIncreased');

    if (!this.EndorsementsForm.get('premiumAdjustment').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'premiumAdjustmentAmount');
    }

    this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'consentToRateAmount');

    if (!this.EndorsementsForm.get('personalPropertyAtOtherResidences').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'personalPropertyAtOtherResidencesLimit');
    }

    this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'leaseTerm');

    if (!this.EndorsementsForm.get('permittedIncidentalOccupanciesLiability').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'descriptionOfBusiness');
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'businessLocation');
    }

    if (!this.EndorsementsForm.get('permittedIncidentalOccupancies').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'descriptionOfOccupancy');
    }
    this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'fungiWetOrDryRotLimit');
  }

  clearEndorsementsValidationDP3(): void {
    if (!this.EndorsementsForm.get('ordinanceLawOrCov').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'ordinanceLaw');
    }

    if (this.EndorsementsForm.get('screenedEnclosure').value === 'N') {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'screenedEnclosureLimit');
    }

    if (!this.EndorsementsForm.get('lossAssessment').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'lossAssessmentLimit');
    }

    if (!this.EndorsementsForm.get('homeComputer').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'homeComputerLimit');
    }

    if (!this.EndorsementsForm.get('golfCart').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'numberOfGolfCarts');
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'liabilityType');
    }

    if (!this.EndorsementsForm.get('coverageC').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'jewelryIncreased');
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'silverwareIncreased');
    }

    if (!this.EndorsementsForm.get('premiumAdjustment').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'premiumAdjustmentAmount');
    }

    if (!this.EndorsementsForm.get('consentToRate').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'consentToRateAmount');
    }

    this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'personalPropertyAtOtherResidencesLimit');

    if (!this.EndorsementsForm.get('unitOwnersRentalToOthers').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'leaseTerm');
    }

    if (!this.EndorsementsForm.get('permittedIncidentalOccupanciesLiability').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'descriptionOfBusiness');
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'businessLocation');
    }

    if (!this.EndorsementsForm.get('permittedIncidentalOccupancies').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'descriptionOfOccupancy');
    }

    if (!this.EndorsementsForm.get('fungiWetOrDryRot').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'fungiWetOrDryRotLimit');
    }

  }

  clearEndorsementsValidationHO6(): void {
    if (!this.EndorsementsForm.get('ordinanceLawOrCov').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'ordinanceLaw');
    }

    this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'screenedEnclosureLimit');

    if (!this.EndorsementsForm.get('lossAssessment').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'lossAssessmentLimit');
    }

    if (!this.EndorsementsForm.get('homeComputer').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'homeComputerLimit');
    }

    if (!this.EndorsementsForm.get('golfCart').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'numberOfGolfCarts');
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'liabilityType');
    }

    if (!this.EndorsementsForm.get('coverageC').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'jewelryIncreased');
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'silverwareIncreased');
    }

    if (!this.EndorsementsForm.get('premiumAdjustment').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'premiumAdjustmentAmount');
    }

    this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'consentToRateAmount');

    this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'personalPropertyAtOtherResidencesLimit');

    if (!this.EndorsementsForm.get('unitOwnersRentalToOthers').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'leaseTerm');
    }

    if (!this.EndorsementsForm.get('permittedIncidentalOccupanciesLiability').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'descriptionOfBusiness');
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'businessLocation');
    }

    if (!this.EndorsementsForm.get('permittedIncidentalOccupancies').value) {
      this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'descriptionOfOccupancy');
    }
    this.generalValidationService.clearValidatorFormControl(this.EndorsementsForm, 'fungiWetOrDryRotLimit');
  }

  setEndorsementsValidation(): void {
    this.generalValidationService.resetValidatorFormControl(this.EndorsementsForm, 'ordinanceLaw', [Validators.required]);

    this.generalValidationService.resetValidatorFormControl(this.EndorsementsForm, 'screenedEnclosureLimit', [Validators.required]);

    this.generalValidationService.resetValidatorFormControl(this.EndorsementsForm, 'lossAssessmentLimit', [Validators.required]);

    this.generalValidationService.resetValidatorFormControl(this.EndorsementsForm, 'homeComputerLimit', [Validators.required]);

    this.generalValidationService.resetValidatorFormControl(this.EndorsementsForm, 'numberOfGolfCarts', [Validators.required]);
    this.generalValidationService.resetValidatorFormControl(this.EndorsementsForm, 'liabilityType', [Validators.required]);

    this.generalValidationService.resetValidatorFormControl(this.EndorsementsForm, 'jewelryIncreased',
      [Validators.required, EndorsementValidators.coverageCIncreasedAmountCannotBeBothZero(this.EndorsementsForm.get('silverwareIncreased').value)]);
    this.generalValidationService.resetValidatorFormControl(this.EndorsementsForm, 'silverwareIncreased',
      [Validators.required, EndorsementValidators.coverageCIncreasedAmountCannotBeBothZero(this.EndorsementsForm.get('jewelryIncreased').value)]);

    this.generalValidationService.resetValidatorFormControl(this.EndorsementsForm, 'premiumAdjustmentAmount', [Validators.required, EndorsementValidators.personalPropertyAtOtherResidencesLimitNotZeroValidator()]);

    this.generalValidationService.resetValidatorFormControl(this.EndorsementsForm, 'consentToRateAmount', [Validators.required, EndorsementValidators.personalPropertyAtOtherResidencesLimitNotZeroValidator()]);

    this.generalValidationService.resetValidatorFormControl(this.EndorsementsForm, 'personalPropertyAtOtherResidencesLimit', [Validators.required, EndorsementValidators.personalPropertyAtOtherResidencesLimitDivisibleValidator(), EndorsementValidators.personalPropertyAtOtherResidencesLimitNotZeroValidator()]);

    this.generalValidationService.resetValidatorFormControl(this.EndorsementsForm, 'leaseTerm', [Validators.required]);

    this.generalValidationService.resetValidatorFormControl(this.EndorsementsForm, 'fungiWetOrDryRotLimit', [Validators.required]);
    this.generalValidationService.resetValidatorFormControl(this.EndorsementsForm, 'descriptionOfOccupancy', [Validators.required]);
    this.generalValidationService.resetValidatorFormControl(this.EndorsementsForm, 'descriptionOfBusiness', [Validators.required]);
    this.generalValidationService.resetValidatorFormControl(this.EndorsementsForm, 'businessLocation', [Validators.required]);

  }
}
