import { Action, createReducer, on } from "@ngrx/store";
import * as fromPolicyNotesActions from "app/store/policy-notes/policy-notes.actions";
import { initialState, PolicyNotesState } from "./policy-notes.state";

const _policyNotesReducer = createReducer(
  initialState,
  on(
    fromPolicyNotesActions.updatePolicyNotesIsLoadingFromPolicyManagementComponent,
    (state, { isLoading }) => ({ ...state, isLoading: isLoading })
  ),
  on(
    fromPolicyNotesActions.updatePolicyNotesListFromPolicyManagementComponent,
    (state, { list }) => ({ ...state, list: list })
  )
);

export function policyNotesReducer(
  state: PolicyNotesState | null,
  action: Action
) {
  return _policyNotesReducer(state, action);
}