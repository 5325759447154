export const Pages = {
  QuickQuote: {
    pageName: 'Quick Quote',
    sections: {
      ApplicantInformation: 'Applicant Information',
      Property: 'Property',
      Coverages: 'Coverages'
    }
  },
  Coverages: {
    pageName: 'Coverages',
    sections: {
      Coverages: 'Coverages',
      Discounts: 'Discounts',
      Flood: 'Flood',
      Schedules: 'Schedules'
    }
  },
  Endorsements: {
    pageName: 'Endorsements',
    sections: {
      Endorsements: 'Endorsements'
    }
  },
  Claims: 'claims',
  Property: {
    pageName: 'Property',
    sections: {
      LocationDetails: 'Location Details',
      PropertyDetails: 'PropertyDetails',
      WindMitigation: 'Wind Mitigation'
    }
  },
  Applicant: {
    pageName: 'Applicant',
    sections: {
      Applicant: 'Applicant',
      Contact: 'Contact',
      CoApplicant: 'Co Applicant',
      FCRA: 'FCRA Acknowledgement'
    }
  },
  UWQuestions: {
    pageName: 'UW Questions',
    sections: {
      Eligibility: 'Eligibility',
      GeneralQuestions: 'General Questions'
    }
  },
  Interests: {
    pageName: 'Interest',
    sections: {
      PriorInsurance: 'Prior Insurance'
    }
  },
  UWApproval: 'uwapproval',
  BindAndIssue: {
    pageName: 'Bind and Issue',
    sections: {
        Billing: 'Billing',
        PaperlessDocuments: 'Paperless Documents',
        Signature: 'Signature'
    }
  }
};
