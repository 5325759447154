import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import { PolicyBillingData } from 'app/modules/policy-management/data/policy-billing.data';
import { PolicyBillingLabelsConstants } from 'app/shared/constants/policy-billing.labels.constants';
import { GenericLabel } from 'app/shared/constants/generic.labels.constants';
import { ZipCodeData } from 'app/modules/submission-management/data/zipcode.data';
import Utils from 'app/shared/utilities/utils';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { PageSections } from 'app/shared/enum/page-sections.enum';
import FormUtils from '../../../../../../shared/utilities/form.utils';
import { GeneralValidationService } from '../../../../../../core/services/submission/validations/general-validation.service';
import { LvCountries } from '../../../../../../shared/constants/applicant.options.constants';

@Component({
  selector: 'app-post-payment-check',
  templateUrl: './post-payment-check.component.html',
  styleUrls: ['./post-payment-check.component.scss']
})
export class PostPaymentCheckComponent implements OnInit {

  @Input() checkPaymentForm: FormGroup;
  @Input() showIsIndividualToggle: boolean = true;
  @Input() isRefundRequest: boolean = true;

  public FormUtils = FormUtils;

  ErrorMessageConstant = ErrorMessageConstant;
  CheckPaymentConstants = PolicyBillingLabelsConstants.checkPayment;
  GenericLabel = GenericLabel;
  Countries = LvCountries;
  FieldNames = this.CheckPaymentConstants.checkPaymentFieldNames;

  isRefundRequestUSCountry: boolean = false;

  constructor(
    public billingData: PolicyBillingData,
    public zipCodeData: ZipCodeData,
    public generalValidationService: GeneralValidationService
  ) { }

  ngOnInit() {
  }

  setZipCode(isUSCountry?: boolean, isFromZipField?: boolean, isRefundRequest?: boolean) {
    if (isFromZipField && !isUSCountry) { return; }

    this.checkPaymentForm.get(this.FieldNames.city).enable();
    const zipCode = this.checkPaymentForm.get(this.FieldNames.zip).value;
    let formControlNames = [this.FieldNames.state, this.FieldNames.city, this.FieldNames.zip];
    this.zipCodeData.checkCityList = [];
    this.isRefundRequestUSCountry = isUSCountry;
    Utils.blockUI();
    if (zipCode !== '' && isUSCountry) {
      this.zipCodeData.getGenericZipCode(zipCode, this.checkPaymentForm, formControlNames, PageSections.CheckPayment, false, ErrorMessageConstant.zipCodeNotFoundErrorMessage);
    } else {
      if (isUSCountry) { // IF US COUNTRY AS IS
        this.resetZipFields(formControlNames);
        Utils.unblockUI();
        NotifUtils.showError(ErrorMessageConstant.zipCodeNotFoundErrorMessage);
      } else { // IF NOT US COUNTRY
        if (!isRefundRequest) {
          formControlNames = [this.FieldNames.state, this.FieldNames.city, this.FieldNames.zip, this.FieldNames.address];
          this.resetZipFields(formControlNames);
        }
        Utils.unblockUI();
      }
    }
    this.zipCodeData.billingCityListComplete();
  }

  resetZipFields(formControlNames): void {
    this.FormUtils.resetFields(this.checkPaymentForm as FormGroup, formControlNames);
    this.zipCodeData.checkCityList = [];
  }

  onChangeCountry(countryCode: string): void {
    this.isRefundRequestUSCountry = countryCode === 'USA' ? true : false;

    const formControlNames = [this.FieldNames.state, this.FieldNames.city, this.FieldNames.zip, this.FieldNames.address];
    this.resetZipFields(formControlNames);
    this.checkPaymentForm.get(this.FieldNames.city).enable();
    this.setAddressFieldValidator(this.isRefundRequestUSCountry);
  }

  setAddressFieldValidator(isUSCountry: boolean): void {
    if (isUSCountry) {
      this.generalValidationService.resetValidatorFormControl(this.checkPaymentForm, this.FieldNames.address, [Validators.required, Validators.maxLength(32)]);
      this.generalValidationService.resetValidatorFormControl(this.checkPaymentForm, this.FieldNames.aptOrUnit, [Validators.maxLength(32)]);
      this.generalValidationService.resetValidatorFormControl(this.checkPaymentForm, this.FieldNames.city, [Validators.required, Validators.maxLength(32)]);
      this.generalValidationService.resetValidatorFormControl(this.checkPaymentForm, this.FieldNames.state, [Validators.required]);
      this.generalValidationService.resetValidatorFormControl(this.checkPaymentForm, this.FieldNames.zip, [Validators.required]);
    } else {
      this.generalValidationService.resetValidatorFormControl(this.checkPaymentForm, this.FieldNames.address, [Validators.required, Validators.maxLength(32)]);
      this.generalValidationService.resetValidatorFormControl(this.checkPaymentForm, this.FieldNames.aptOrUnit, [Validators.maxLength(32)]);
      this.generalValidationService.clearValidatorFormControl(this.checkPaymentForm, this.FieldNames.state);
      this.generalValidationService.clearValidatorFormControl(this.checkPaymentForm, this.FieldNames.zip);
    }
  }

}
