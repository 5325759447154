import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseClass } from '../../../shared/base-class';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { LvUWQuestions } from 'app/shared/constants/uw-questions.labels.constants';
import { EntityRiskDTO } from 'app/shared/models/data/dto/quick-quote/entity-risk.dto';
import { RiskUWQuestionDTO } from 'app/shared/models/data/dto/quick-quote/risk-uw-question.dto';
import { CustomValidators } from '../../../shared/validators/custom.validator';
import { UwQuestionsFieldName } from 'app/shared/constants/uw-questions.labels.constants';
import FormUtils from 'app/shared/utilities/form.utils';
import Utils from 'app/shared/utilities/utils';
import { EndorsementsData } from './endorsements.data';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';
import { CoveragesData } from './coverages.data';
import { QuoteService } from 'app/core/services/quote.service';
import { CoveragesValidators } from '../validators/coverages.validators';
import { PropertyData } from './property.data';
import { AuthService } from '../../../core/services/auth.service';
import { GenericConstants } from '../../../shared/constants/generic.constants';

@Injectable({
  providedIn: 'root'
})
export class UwQuestionsData extends BaseClass {
  eligibilityGroup: FormGroup;
  generalQuestionsGroup: FormGroup;
  LvUWQuestions = LvUWQuestions;
  public UwQuestionsFieldName: any = UwQuestionsFieldName;
  public estimatedDateOptions: IAngularMyDpOptions = {
    disableUntil: {
      year: Utils.getYesterdayDate().getFullYear(),
      month: Utils.getYesterdayDate().getMonth() + 1,
      day: Utils.getYesterdayDate().getDate()
    },
    dateFormat: 'mm/dd/yyyy',
    dateRange: false
  };
  public responseId = {
    IsEverRented: '',
    EverRentLease: '',
    IsForRent: '',
    ForRentLease: '',
    IsTrampPremis: '',
    AreBarsWindow: '',
    HaveCentralHeat: '',
    AreQReleaseBarredWin: '',
    IsSwimHotTubPremis: '',
    IsDivBoard: '',
    IsSlide: '',
    IsPermanentLockFence: '',
    AreDogsClassified: '',
    DoDogsKept: '',
    AreExoticAnimals: '',
    ArePorchesDecks: '',
    HasApplicantClaim: '',
    IsPrefabricatedModHome: '',
    IsPropertyClassified: '',
    HasAppCovDeclined: '',
    Explain: '',
    IsPropertyBusiness: '',
    IsFarmBusPremis: '',
    IsProp5Acres: '',
    Describe: '',
    IsHomeUsed: '',
    IsHomeLocated: '',
    IsAnyDupilcate: '',
    DoesHomeAlumW: '',
    DoesHomePolyIron: '',
    IsUnconvDwelling: '',
    IsHomeHistorical: '',
    DoesRoofShingles: '',
    HaveWoodShingles: '',
    WasHomeConsUncovMethod: '',
    DoesHomeHaveVio: '',
    DoesHomePaved: '',
    IsHomeLocOverWater: '',
    HasAppliCrimeOfArson: '',
    DoesDwelSepWindPol: '',
    IsDwellGCondition: '',
    IsPropLocKnownSink: '',
    AreUnusualLiability: '',
    WasHomeBuiltAsbestos: '',
    AnyOthResApplicant: '',
    DoesAppHaveInsurance: '',
    AddPolNumber: '',
    IsMoreThanOneUnit: '',
    IsBldgUnConsRenov: '',
    EstCompleDate: '',
    PleaseDescibe: '',
    AreTherPrimHeatSource: '',
    ICertify: '',
};

  // Dropdown List
  uwQuestionHeldAndRent: any[];

  defaultDateOption: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'mm/dd/yyyy'
  };

  isAreDogsClassifiedChange: boolean = false;

  constructor(
    public endorsementsData: EndorsementsData,
    public coveragesData: CoveragesData,
    protected quoteService: QuoteService,
      protected propertyData: PropertyData,
      protected authService: AuthService) {
    super();
  }

  initForms() {
    this.eligibilityGroup = this.setupEligibilityGroup();
    this.generalQuestionsGroup = this.setupGeneralQuestionsGroup();
    this.dropdownValues();
  }

  setupEligibilityGroup() {
    return new FormGroup({
      IsEverRented: new FormControl(false, [Validators.required]),
      EverRentLease: new FormControl(''),
      IsForRent: new FormControl(false, [Validators.required]),
      ForRentLease: new FormControl(''),
      IsTrampPremis: new FormControl(false, [Validators.required]),
      AreBarsWindow: new FormControl(false, [Validators.required]),
      AreQReleaseBarredWin: new FormControl(false, [Validators.required]),
      HaveCentralHeat: new FormControl(true, [Validators.required]),
      // centralHeating: new FormControl(false, [Validators.required]),
      IsSwimHotTubPremis: new FormControl(false, [Validators.required]),
      IsDivBoard: new FormControl(false, [Validators.required]),
      IsSlide: new FormControl(false, [Validators.required]),
      IsPermanentLockFence: new FormControl(false, [Validators.required]),
      AreDogsClassified: new FormControl(false, [Validators.required]),
      DoDogsKept: new FormControl(false, [Validators.required]),
      AreExoticAnimals: new FormControl(false, [Validators.required]),
      ArePorchesDecks: new FormControl(false, [Validators.required]),
      HasApplicantClaim: new FormControl(false, [Validators.required]),
      IsPrefabricatedModHome: new FormControl(false, [Validators.required]),
      IsPropertyClassified: new FormControl(false, [Validators.required]),
      HasAppCovDeclined: new FormControl(false, [Validators.required]),
      Explain: new FormControl(''),
      IsPropertyBusiness: new FormControl(false, [Validators.required]),
      IsFarmBusPremis: new FormControl(false, [Validators.required]),
      // twoMortgagees: new FormControl(false, [Validators.required]),
      IsProp5Acres: new FormControl(false, [Validators.required]),
      Describe: new FormControl(''),
      IsHomeUsed: new FormControl(false, [Validators.required]),
      IsHomeLocated: new FormControl(false, [Validators.required]),
      IsAnyDupilcate: new FormControl(false, [Validators.required]),
      DoesHomeAlumW: new FormControl(false, [Validators.required]),
      DoesHomePolyIron: new FormControl(false, [Validators.required]),
      IsUnconvDwelling: new FormControl(false, [Validators.required]),
      IsHomeHistorical: new FormControl(false, [Validators.required]),
      DoesRoofShingles: new FormControl(false, [Validators.required]),
      HaveWoodShingles: new FormControl(false, [Validators.required]),
      WasHomeConsUncovMethod: new FormControl(false, [Validators.required]),
      DoesHomeHaveVio: new FormControl(false, [Validators.required]),
      DoesHomePaved: new FormControl(false, [Validators.required]),
      IsHomeLocOverWater: new FormControl(false, [Validators.required]),
      DoesDwelSepWindPol: new FormControl(false, [Validators.required]),
      IsDwellGCondition: new FormControl(true, [Validators.required]),
      IsPropLocKnownSink: new FormControl(false, [Validators.required]),
      AreUnusualLiability: new FormControl(false, [Validators.required]),
      WasHomeBuiltAsbestos: new FormControl(false, [Validators.required]),
    });
  }

  setupGeneralQuestionsGroup() {
    return new FormGroup({
      AnyOthResApplicant: new FormControl(false, [Validators.required]),
      DoesAppHaveInsurance: new FormControl(false, [Validators.required]),
      AddPolNumber: new FormControl(''),
      IsMoreThanOneUnit: new FormControl(''),
      IsBldgUnConsRenov: new FormControl(false, [Validators.required]),
      EstCompleDate: new FormControl(''),
      PleaseDescibe: new FormControl(''),
      HasAppliCrimeOfArson: new FormControl(false, [Validators.required]),
      ICertify: new FormControl('', [Validators.required]),
    });
  }

  dropdownValues() {
      this.uwQuestionHeldAndRent = [
        {
          tableName: 'LvUWQuestionHeldAndRentAtThisResidence ',
          tableDescription: 'UW Questions',
          code: 'UWQ0',
          description: 'No',
          isActive: false
        },
        {
          tableName: 'LvUWQuestionHeldAndRentAtThisResidence ',
          tableDescription: 'UW Questions',
          code: 'UWQ1',
          description: 'Weekly',
          isActive: true
        },
        {
          tableName: 'LvUWQuestionHeldAndRentAtThisResidence ',
          tableDescription: 'UW Questions',
          code: 'UWQ2',
          description: 'Monthly',
          isActive: true
        },
        {
          tableName: 'LvUWQuestionHeldAndRentAtThisResidence ',
          tableDescription: 'UW Questions',
          code: 'UWQ3',
          description: 'Semi-Annual',
          isActive: true
        },
        {
          tableName: 'LvUWQuestionHeldAndRentAtThisResidence ',
          tableDescription: 'UW Questions',
          code: 'UWQ4',
          description: 'Annual',
          isActive: true
        },
        {
          tableName: 'LvUWQuestionHeldAndRentAtThisResidence ',
          tableDescription: 'UW Questions',
          code: 'UWQ5',
          description: 'Other',
          isActive: true
        }
    ];
  }

  public populateUWQuestionPage(data?: EntityRiskDTO) {
    const uwQuestionData = data?.risks[0]?.riskDetails[0]?.riskUWQuestions;
    this.registerKeyForUpdates(uwQuestionData);
    this.populateUWQuestionControl(uwQuestionData);
  }

  registerKeyForUpdates(uwQuestions: RiskUWQuestionDTO[]) {
    uwQuestions.forEach(item => {
      this.responseId[item.uwQuestionCode] = item.id;
    });
  }

  populateUWQuestionControl(uwQuestions: RiskUWQuestionDTO[]) {
    uwQuestions.forEach(item => {
      if (item.isActive) {
        const section = this.LvUWQuestions.find(a => a.code === item.uwQuestionCode)?.section;
        if (section === 'Eligibility') {
          if (item.uwQuestionCode === this.UwQuestionsFieldName.eligibility.EverRentLease || item.uwQuestionCode === this.UwQuestionsFieldName.eligibility.ForRentLease ||
            item.uwQuestionCode === this.UwQuestionsFieldName.eligibility.Explain || item.uwQuestionCode === this.UwQuestionsFieldName.eligibility.Describe) {
            this.eligibilityGroup.get(item.uwQuestionCode).setValue(item.uwQuestionValue);
          } else {
            this.eligibilityGroup.get(item.uwQuestionCode).setValue(true);
            if (item.uwQuestionCode === this.UwQuestionsFieldName.eligibility.HasAppCovDeclined) {
              this.clearChildData(this.UwQuestionsFieldName.eligibility.HasAppCovDeclined);
              this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.Explain).markAsTouched();
            }
            if (item.uwQuestionCode === this.UwQuestionsFieldName.eligibility.IsProp5Acres) {
              this.clearChildData(this.UwQuestionsFieldName.eligibility.IsProp5Acres);
              this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.Describe).markAsTouched();
            }
            if (item.uwQuestionCode === this.UwQuestionsFieldName.eligibility.IsEverRented) {
              this.clearChildData(this.UwQuestionsFieldName.eligibility.IsEverRented);
              this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.EverRentLease).markAsTouched();
            }
            if (item.uwQuestionCode === this.UwQuestionsFieldName.eligibility.IsForRent) {
              this.clearChildData(this.UwQuestionsFieldName.eligibility.IsForRent);
              this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.ForRentLease).markAsTouched();
            }
          }
        } else if (section === 'General Question') {
          if  (item.uwQuestionCode === this.UwQuestionsFieldName.generalQuestions.IsMoreThanOneUnit || item.uwQuestionCode === this.UwQuestionsFieldName.generalQuestions.EstCompleDate ||
               item.uwQuestionCode === this.UwQuestionsFieldName.generalQuestions.PleaseDescibe || item.uwQuestionCode === this.UwQuestionsFieldName.generalQuestions.AddPolNumber) {
            if (item.uwQuestionCode === this.UwQuestionsFieldName.generalQuestions.EstCompleDate) {
              this.generalQuestionsGroup.get(this.UwQuestionsFieldName.generalQuestions.EstCompleDate).setValue({isRange: false, singleDate: {jsDate: new Date(item.uwQuestionValue)}});
            } else {
              this.generalQuestionsGroup.get(item.uwQuestionCode).setValue(item?.uwQuestionValue);
            }
          } else {
            this.generalQuestionsGroup.get(item.uwQuestionCode).setValue(true);
            if (item.uwQuestionCode === this.UwQuestionsFieldName.generalQuestions.IsBldgUnConsRenov) {
              this.clearChildData(this.UwQuestionsFieldName.generalQuestions.IsBldgUnConsRenov);
              this.generalQuestionsGroup.get(this.UwQuestionsFieldName.generalQuestions.EstCompleDate).markAsTouched();
              this.generalQuestionsGroup.get(this.UwQuestionsFieldName.generalQuestions.PleaseDescibe).markAsTouched();
            }
          }
        }
      }
    });

    this.setAreDogsClassifiedChange();
    this.setUWQuestionsWithDefaultValue(uwQuestions);
    this.setCoverageLValidator();
  }

  public resetUWQuestionResponseId() {
    // eslint-disable-next-line guard-for-in
    for (const key in this.responseId) {
      this.responseId[key] = '';
    }
  }

  getUwQuestionsForm() {
    return {
      eligibilityGroup: this.eligibilityGroup,
      generalQuestionsGroup: this.generalQuestionsGroup
    };
  }

  clearChildData(fieldName: string): void {
    const eligibilityParent = this.eligibilityGroup.get(fieldName)?.value;
    const generalParent = this.generalQuestionsGroup.get(fieldName)?.value;

    switch (fieldName) {
      case this.UwQuestionsFieldName.eligibility.AreBarsWindow:
        this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.AreQReleaseBarredWin).setValue(false);
        break;
      case this.UwQuestionsFieldName.eligibility.IsSwimHotTubPremis:
        this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.IsDivBoard).setValue(false);
        this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.IsSlide).setValue(false);
        this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.IsPermanentLockFence).setValue(false);
        break;
      case this.UwQuestionsFieldName.eligibility.HasAppCovDeclined:
        this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.Explain).clearValidators();
        if (!eligibilityParent) {
            this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.Explain).setValue('');
            this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.Explain).markAsUntouched();
        } else {
            this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.Explain).setValidators([Validators.required, Validators.maxLength(100), CustomValidators.spaceValidator]);
            this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.Explain).markAsTouched();
          }
          this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.Explain).updateValueAndValidity();
          break;
      case this.UwQuestionsFieldName.eligibility.IsProp5Acres:
        this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.Describe).clearValidators();
        if (!eligibilityParent) {
            this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.Describe).setValue('');
            this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.Describe).markAsUntouched();
        } else {
          this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.Describe).setValidators([Validators.required, Validators.maxLength(100), CustomValidators.spaceValidator]);
          this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.Describe).markAsTouched();
        }
        this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.Describe).updateValueAndValidity();
        break;
      case this.UwQuestionsFieldName.eligibility.IsEverRented:
        this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.EverRentLease).clearValidators();
        if (!eligibilityParent) {
          this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.EverRentLease).setValue('');
          this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.EverRentLease).markAsUntouched();
          this.coveragesData.isBrul54 = false;
          this.coveragesData.clearCoverageLValidator();
        } else {
          this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.EverRentLease).setValidators([Validators.required]);
          this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.EverRentLease).markAsTouched();
        }
        this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.EverRentLease).updateValueAndValidity();
        break;
      case this.UwQuestionsFieldName.eligibility.IsForRent:
        this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.ForRentLease).clearValidators();
        if (!eligibilityParent) {
            this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.ForRentLease).setValue('');
            this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.ForRentLease).markAsUntouched();
        } else {
          this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.ForRentLease).setValidators([Validators.required]);
          this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.ForRentLease).markAsTouched();
        }
        this.eligibilityGroup.get(this.UwQuestionsFieldName.eligibility.ForRentLease).updateValueAndValidity();
        break;
      case this.UwQuestionsFieldName.generalQuestions.IsBldgUnConsRenov:
        this.generalQuestionsGroup.get(this.UwQuestionsFieldName.generalQuestions.EstCompleDate).clearValidators();
        this.generalQuestionsGroup.get(this.UwQuestionsFieldName.generalQuestions.PleaseDescibe).clearValidators();
        if (!generalParent) {
          this.generalQuestionsGroup.get(this.UwQuestionsFieldName.generalQuestions.EstCompleDate).setValue('');
          this.generalQuestionsGroup.get(this.UwQuestionsFieldName.generalQuestions.EstCompleDate).markAsUntouched();
          this.generalQuestionsGroup.get(this.UwQuestionsFieldName.generalQuestions.PleaseDescibe).setValue('');
          this.generalQuestionsGroup.get(this.UwQuestionsFieldName.generalQuestions.PleaseDescibe).markAsUntouched();
        } else {
          this.generalQuestionsGroup.get(this.UwQuestionsFieldName.generalQuestions.EstCompleDate).setValidators([Validators.required]);
          this.generalQuestionsGroup.get(this.UwQuestionsFieldName.generalQuestions.EstCompleDate).markAsTouched();
          this.generalQuestionsGroup.get(this.UwQuestionsFieldName.generalQuestions.PleaseDescibe).setValidators([Validators.required, CustomValidators.spaceValidator]);
          this.generalQuestionsGroup.get(this.UwQuestionsFieldName.generalQuestions.PleaseDescibe).markAsTouched();
        }
        this.generalQuestionsGroup.get(this.UwQuestionsFieldName.generalQuestions.EstCompleDate).updateValueAndValidity();
        this.generalQuestionsGroup.get(this.UwQuestionsFieldName.generalQuestions.PleaseDescibe).updateValueAndValidity();
        break;
    }
  }

  setUWQuestionPageFormStatus(status: boolean): void {
    FormUtils.setFormGroupStatus(this.eligibilityGroup, status);
    FormUtils.setFormGroupStatus(this.generalQuestionsGroup, status);
    this.setEstimatedDateOptions(status);
  }

  setEstimatedDateOptions(status): void {
    if (status) {
      this.estimatedDateOptions = {
        disableUntil: {
          year: Utils.getYesterdayDate().getFullYear(),
          month: Utils.getYesterdayDate().getMonth() + 1,
          day: Utils.getYesterdayDate().getDate()
        },
        dateFormat: 'mm/dd/yyyy',
        dateRange: false
      };
    } else {
      this.estimatedDateOptions = {
        dateFormat: 'mm/dd/yyyy',
        dateRange: false
      };
    }
  }

  setAreDogsClassifiedChange(): void {
    if (this.authService.userType.value === GenericConstants.userType.external &&
      this.quoteService.formType !== FormTypeConstants.DP3 &&
      (this.eligibilityGroup.get('AreDogsClassified').value || this.eligibilityGroup.get('DoDogsKept').value)
    ) {
      this.endorsementsData.endorsementsGroup.get('animalLiability').setValue(false);
      this.endorsementsData.endorsementsGroup.get('animalLiabilityLimit').setValue('');
      this.endorsementsData.endorsementsGroup.get('animalLiability').disable();
      this.endorsementsData.endorsementsGroup.get('animalLiabilityLimit').disable();
      this.isAreDogsClassifiedChange = true;
    } else {
      this.isAreDogsClassifiedChange = false;
      this.endorsementsData.endorsementsGroup.get('animalLiability').enable();
      this.endorsementsData.endorsementsGroup.get('animalLiabilityLimit').enable();
    }
  }

  setDisabledEndorsementFields(): void {
    if (this.authService.userType.value === GenericConstants.userType.external &&
      this.quoteService.formType !== FormTypeConstants.DP3 &&
      (this.eligibilityGroup.get('AreDogsClassified').value  || this.eligibilityGroup.get('DoDogsKept').value)
    ) {
      this.endorsementsData.endorsementsGroup.get('animalLiability').disable();
      this.endorsementsData.endorsementsGroup.get('animalLiabilityLimit').disable();
    }
  }

  setCoverageLValidator(): void {
    if (this.quoteService.formType === FormTypeConstants.DP3) {
      const isExternal = localStorage.getItem('userType')?.toLocaleLowerCase() === 'external';
      const rentedChild = ['UWQ1', 'UWQ2', 'UWQ5'];
      const isCoverageLEnabled = this.coveragesData.coveragesAndDeductiblesForm.get('isCoverageLEnabled')?.value || false;
      const isRented = this.eligibilityGroup.get('IsEverRented')?.value || false;
      const isRentedChild = this.eligibilityGroup.get('EverRentLease')?.value;
      this.coveragesData.isBrul54 = isExternal && isRented && rentedChild.includes(isRentedChild);
      if (isCoverageLEnabled && this.coveragesData.isBrul54) {
        this.coveragesData.coveragesAndDeductiblesForm.get('coverageL')
            .setValidators(CoveragesValidators.coverageL100kMaxValue());
        this.coveragesData.coveragesAndDeductiblesForm.get('coverageL').updateValueAndValidity();
        return;
      }
    } else {
      this.coveragesData.isBrul54 = false;
    }
    this.coveragesData.clearCoverageLValidator();
  }

  setUWQuestionsWithDefaultValue(uwQuestions: RiskUWQuestionDTO[]): void {
    const uwQuestionCodes = uwQuestions?.map((a) => a.uwQuestionCode);

    this.eligibilityGroup.get('IsDwellGCondition').setValue(this.findControlFromUWCodes('IsDwellGCondition', uwQuestionCodes));
    this.eligibilityGroup.get('HaveCentralHeat').setValue(this.findControlFromUWCodes('HaveCentralHeat', uwQuestionCodes));
  }

  findControlFromUWCodes(control: string, uwQuestionCodes: string[]): boolean {
    return uwQuestionCodes.includes(control);
  }
}
