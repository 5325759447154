import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommissionStatementData } from 'app/modules/reports/data/commission-statement.data';
import { AuthService } from 'app/core/services/auth.service';
import { CommissionConstant } from 'app/shared/constants/commission-constants';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { TypeaheadSettings } from 'ngx-type-ahead';
import Utils from 'app/shared/utilities/utils';
import { takeUntil } from 'rxjs/operators';
import { AgencyService } from 'app/core/services/management/agency-service';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { BaseClass } from 'app/shared/base-class';

@Component({
  selector: 'app-commission-statement-header',
  templateUrl: './commission-statement-header.component.html',
  styleUrls: ['./commission-statement-header.component.scss']
})
export class CommissionStatementHeaderComponent extends BaseClass implements OnInit {
  @ViewChild('agency') agency: ElementRef;
  @ViewChild('arrow') arrow: ElementRef;

  CommissionConstant = CommissionConstant;
  noResult: boolean = false;
  count = 0;
  agencyListLoading: boolean = true;
  isExternal: boolean;

  customSettings: Partial<TypeaheadSettings> = {
    noMatchesText: 'No results found',
    dropdownToggleClass: 'button-down',
    suggestionsLimit: 0
  };

  get agencyForm(): FormGroup {
    return this.commissionStatementData.agencyForm;
  }

  get customDate(): Date {
    return this.authService.getCustomDate();
  }

  get isExternalUser(): boolean {
    return localStorage.getItem('userType').toLocaleLowerCase() === 'external';
  }

  get ExternalAgencyName(): string {
    return this.authService?.agenciesInfo?.entity?.fullName;
  }

  get ExternalAgencyId(): string {
    return this.authService?.agenciesInfo?.id;
  }

  constructor(
    public authService: AuthService,
    public commissionStatementData: CommissionStatementData,
    public agencyService: AgencyService
  ) {
    super();
   }

  ngOnInit() {
    this.commissionStatementData.initializeReportHeaderForm();
    if(this.isExternalUser) {
      if (this.ExternalAgencyId) {
        this.commissionStatementData.selectedAgencyId = this.ExternalAgencyId;
        this.commissionStatementData.getAgencyCommissionStatementList(this.ExternalAgencyId);
      } else {
        this.subscribeToAgencyFetchResponse();
      }
      this.isExternal = true;
    } else {
      this.isExternal = false;
      this.initAgencyList();
    }
  }

  onSelectedAgency(event: TypeaheadMatch): void {
    const agencyId = event.item?.id;
    if (agencyId !== null) {
      this.commissionStatementData.selectedAgencyId = agencyId;
      this.commissionStatementData.getAgencyCommissionStatementList(this.commissionStatementData.selectedAgencyId);
    }
  }

  typeaheadNoResults(event: boolean): void {
    this.noResult = event;
  }

  toggleAgencyDropdown(): void {
    this.count++;
    if (this.agencyForm.get('agency').parent.controls['agency'].touched && this.count !== 1) {
      this.agency.nativeElement.blur();
    } else {
      this.agency.nativeElement.focus();
    }
  }

  initAgencyList(): void {
    const agencyList = [];
    this.agencyListLoading = true;
    Utils.blockUI();
    this.agencyService.getAllAgencies().pipe(takeUntil(this.stop$)).subscribe(result => {
      this.commissionStatementData.agencyList = result;
      result.forEach(a => agencyList.push({name: `${a.agencyCode} - ${a.entity.fullName}`, id: a.id}));
      this.commissionStatementData.agencyNameList = agencyList;
      this.agencyListLoading = false;
      Utils.unblockUI();
    }, (err) => {
      Utils.unblockUI();
      NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
    });
  }

  subscribeToAgencyFetchResponse(): void {
    this.authService.isAgencyCompleted$.pipe(takeUntil(this.stop$)).subscribe(() => {
      this.commissionStatementData.selectedAgencyId = this.ExternalAgencyId;
      this.commissionStatementData.getAgencyCommissionStatementList(this.ExternalAgencyId);
    });
  }
}
