import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GenericConstants } from 'app/shared/constants/generic.constants';

@Injectable({
  providedIn: 'root'
})
export class CustomCurrencyCheckingService {

  commaIndex: number[] = [];
  cursorPosition: number = 0;
  origValue: string = '';
  highlightedText: string = '';
  constructor() { }

  onKeyDown(formGroup: FormGroup, fieldName: string, event: any) {
    this.origValue = formGroup.get(fieldName).value;
    this.highlightedText = typeof window.getSelection !== 'undefined' ? window.getSelection().toString() : '';
    if (event.key !== '-' && this.checkSpecialCharacters(event.key)) {
      event.preventDefault();
    } else if (this.origValue) {
      if ((event.key === '-' && this.origValue === '$ 0') ||
        (this.origValue.includes('$') && event.selectionStart === 0 && Number(event.key))) {
        event.preventDefault();
      } else if (this.origValue.includes('-') &&
        (this.getNumericChars(this.origValue).length === 0 || this.getNumericChars(this.origValue) === '0') &&
        event.key === '0') {
        event.preventDefault();
      } else if (this.getNumericChars(this.origValue).startsWith('0') && (event.key === '0' || Number(event.key))) {
        if (event.key !== '0') {
          event.target.selectionStart = this.origValue.indexOf('0');
        } else {
          event.preventDefault();
        }
      } else if (this.highlightedText === ' ' && !GenericConstants.CustomAmountChecking.exemptedKeys.includes(event.key)) {
        event.preventDefault();
      } else if (this.origValue && this.origValue !== '' && (!isNaN(event.key) || event.key === '-')) {
        if ((this.origValue.includes('-') && event.key === '-')  ||
          (this.getNumericChars(this.origValue).length === 10 && event.key !== '-' && this.highlightedText === '') ||
          (event.key === '-' && event.target.selectionStart !== 0) ||
          (event.key === '0' && event.target.selectionStart <= 2 && !this.origValue.includes('-')) ||
          (event.key === '0' && event.target.selectionStart <= 3 && this.origValue.includes('-')) ||
          (this.highlightedText === GenericConstants.CustomAmountChecking.withDollarSign) ||
          (this.highlightedText === GenericConstants.CustomAmountChecking.withNegativeDollarSign)) {
          event.preventDefault();
        }
      } else if (event.key === GenericConstants.CustomAmountChecking.backspaceKey || event.key === GenericConstants.CustomAmountChecking.deleteKey) {
        const charArray = Array.from(this.origValue);
        this.cursorPosition = event.target.selectionStart;
        if (event.key === GenericConstants.CustomAmountChecking.backspaceKey &&
          (charArray[this.cursorPosition-1] === '$' || charArray[this.cursorPosition-1] === ' ') &&
          this.highlightedText === '') {
          event.preventDefault();
        } else if (this.highlightedText !== '' &&
          (this.highlightedText.includes('$') || this.highlightedText.includes(' ')) &&
          this.highlightedText !== this.getNumericChars(this.highlightedText) &&
          this.highlightedText !== this.origValue) {
          event.preventDefault();
        } else if (event.key === GenericConstants.CustomAmountChecking.backspaceKey && charArray[this.cursorPosition-1] === ',') {
          event.target.selectionStart = this.cursorPosition - 2;
        } else if (event.key === GenericConstants.CustomAmountChecking.deleteKey &&
          (charArray[this.cursorPosition+1] === '$' ||
          charArray[this.cursorPosition+1] === ' ')) {
          event.target.selectionStart = this.cursorPosition + 2;
        } else if (event.key === GenericConstants.CustomAmountChecking.deleteKey &&
          (this.cursorPosition === 0 ||
          charArray[this.cursorPosition] === ',' ||
          charArray[this.cursorPosition] === '-' ||
          charArray[this.cursorPosition] === ' ')) {
          event.target.selectionStart = this.cursorPosition + 1;
        }
      }
      this.getCommaIndex(this.origValue);
    }
  }

  onKeyUp(formGroup: FormGroup, fieldName: string, event: any): void {
    const currentInput = formGroup.get(fieldName).value;

    if (event.key === '-' && currentInput === '-') {
        formGroup.get(fieldName).setValue(GenericConstants.CustomAmountChecking.withNegativeDollarSign);
    } else if (this.checkLetters(event.key) && event.key.length === 1) {
      let newAmount = this.getNumericChars(currentInput);
      newAmount = Number(newAmount).toString();
      let signUsed = (this.origValue &&
        this.highlightedText === this.origValue &&
        this.origValue !== '') ?
        currentInput :
        this.origValue;

      if (newAmount !== '0') {
        this.getCommaIndex(newAmount, true);
        newAmount = this.insertComma(newAmount);
        if (!newAmount.includes(GenericConstants.CustomAmountChecking.withDollarSign)) {
          signUsed = signUsed.toString().replace(/[0-9]/g,'').replace(/,/g,'');
          newAmount = (signUsed.includes(GenericConstants.CustomAmountChecking.withNegativeDollarSign) ? GenericConstants.CustomAmountChecking.withNegativeDollarSign : GenericConstants.CustomAmountChecking.withDollarSign) + newAmount;
        }
        formGroup.get(fieldName).setValue(newAmount);
      } else {
        formGroup.get(fieldName).setValue('');
      }
    } else if (Number(event.key) || event.key === '0') {
      this.getCommaIndex(this.getNumericChars(currentInput), true);
      const newAmount = this.insertComma(this.getNumericChars(currentInput));
      if (!newAmount.includes('$')) {
        if (this.origValue && this.origValue.includes(GenericConstants.CustomAmountChecking.withNegativeDollarSign)) {
          formGroup.get(fieldName).setValue(GenericConstants.CustomAmountChecking.withNegativeDollarSign + newAmount);
        } else {
          formGroup.get(fieldName).setValue(GenericConstants.CustomAmountChecking.withDollarSign + newAmount);
        }
      } else {
        formGroup.get(fieldName).setValue(newAmount);
      }
    } else if (event.key === GenericConstants.CustomAmountChecking.backspaceKey || event.key === GenericConstants.CustomAmountChecking.deleteKey) {
      if (currentInput === GenericConstants.CustomAmountChecking.withDollarSign || currentInput === GenericConstants.CustomAmountChecking.withNegativeDollarSign) {
        formGroup.get(fieldName).setValue('');
      } else if (currentInput === '-$' || currentInput === '$') {
        formGroup.get(fieldName).setValue('');
      } else if (this.getNumericChars(currentInput).length > 0) {
        this.getCommaIndex(currentInput, true);
        let newAmount = this.insertComma(currentInput);
        if (this.getNumericChars(newAmount).startsWith('0')) {
          if (Number(this.getNumericChars(newAmount)) !== 0 ) {
            newAmount = Number(this.getNumericChars(newAmount)).toString();
            const signOnly = currentInput.toString().replace(/[0-9]/g,'').replace(/,/g,'');
            newAmount = signOnly + newAmount;
            this.getCommaIndex(newAmount, true);
            newAmount = this.insertComma(newAmount);
          } else {
            newAmount = '';
          }
        }
        formGroup.get(fieldName).setValue(newAmount);
        event.selectionStart = this.cursorPosition;
        event.selectionEnd = this.cursorPosition;
      } else if (this.getNumericChars(currentInput).length <= 0) {
        formGroup.get(fieldName).setValue('');
      }
    }
  }

  getCommaIndex(userInput?: string, isRemoving: boolean = false): void {
    this.commaIndex = [];
    const numbersOnly = Number(this.getNumericChars(userInput)).toString();
    if (numbersOnly.length >= 3 && numbersOnly.length <= 5) {
      let newIndex = isRemoving ? 3 : 2;
      newIndex = numbersOnly.length - newIndex;
      if (newIndex > 0) {
        this.commaIndex.push(newIndex);
      }
    } else if (numbersOnly.length > 5 && numbersOnly.length <= 8) {
      if (isRemoving && numbersOnly.length !== 3) {
        this.commaIndex.push(numbersOnly.length-3);
        if (numbersOnly.length !== 6) {
          this.commaIndex.push(numbersOnly.length ===  7 ? 1 : 2);
        }
      } else {
        this.commaIndex.push(numbersOnly.length-2);
        this.commaIndex.push(numbersOnly.length === 6 ? 1 : numbersOnly.length === 7 ? 2 : 3);
      }
    } else if (numbersOnly.length === 9 || numbersOnly.length === 10) {
      if (isRemoving && numbersOnly.length === 9) {
        this.commaIndex.push(6, 3);
      } else {
        this.commaIndex.push(7, 4, 1);
      }
    }
  }

  insertComma(userInput?: string): string {
    let newInput = '';
    const signOnly = userInput.toString().replace(/[0-9]/g,'').replace(/,/g,'');
    const numbersOnly = Number(this.getNumericChars(userInput)).toString();
    if (numbersOnly.length > 3 && numbersOnly.length <= 10) {
      const charArray = Array.from(numbersOnly);
      this.commaIndex.forEach(index => {
        charArray.splice(index, 0, ',');
      });
      charArray.forEach(index => {
        newInput += index;
      });
      return signOnly + newInput;
    } else if (numbersOnly.length <= 3) {
      return userInput.replace(/,/g,'');
    } else {
      return userInput;
    }
  }

  getNumericChars(userInput?: string): string {
    return userInput.replace(/\D/g,'');
  }

  checkAmountForSaving(amount?: number): number {
    let newAmount = '';
    if (amount?.toString().includes(GenericConstants.CustomAmountChecking.withDollarSign)) {
      newAmount = amount?.toString().replace(/\D/g,'');
      if (amount?.toString().includes('-') && this.getNumericChars(amount?.toString()) !== '') {
        newAmount = '-' + newAmount;
      }
    } else {
      newAmount = this.getNumericChars(amount?.toString()) !== '' ? amount?.toString() : '';
    }

    if (this.getNumericChars(amount?.toString()) === '0') {
      newAmount = '0';
    }
    return newAmount !== '' ? Number(newAmount) : null;
  }

  formatAmountToDisplay(amount?: number): string {
    let newAmount = amount?.toString();
    if (amount === 0) {
      newAmount = GenericConstants.CustomAmountChecking.withDollarSign + newAmount;
    } else if (amount?.toString().includes('-')) {
      newAmount = newAmount.replace(/-/g,'');
      newAmount = GenericConstants.CustomAmountChecking.withNegativeDollarSign + newAmount;
    } else {
      newAmount = GenericConstants.CustomAmountChecking.withDollarSign + newAmount;
    }
    this.getCommaIndex(newAmount, true);
    newAmount = this.insertComma(newAmount);
    return newAmount;
  }

  checkSpecialCharacters(stringInput?: string): boolean {
    const format = /[ _\-+=`~!@#$%^&*(),.?":{}|<>/\\'"\[\]\;]/g;
    return format.test(stringInput);
  }

  checkLetters(stringInput?: string): boolean {
    const format = /[a-zA-Z]/g;
    return format.test(stringInput);
  }

  checkNegativeSign(stringInput?: string): boolean {
    if (stringInput?.includes('-') && stringInput?.indexOf('-') === 0) {
      return true;
    } else {
      return false;
    }
  }

  onPaste(formGroup: FormGroup, fieldName: string, event: any): void {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    let newAmount = pastedText;
    if (this.checkLetters(pastedText) || this.checkSpecialCharacters(pastedText)) {
      newAmount = this.getNumericChars(pastedText);
    }

    if (newAmount.length > 10) {
      newAmount = newAmount.slice(0, 10);
    }
    const currentInput = (this.checkNegativeSign(pastedText) ? GenericConstants.CustomAmountChecking.withNegativeDollarSign : GenericConstants.CustomAmountChecking.withDollarSign) + newAmount;
    this.getCommaIndex(currentInput, true);
    newAmount = this.insertComma(currentInput);
    formGroup.get(fieldName).setValue(newAmount);
    event.preventDefault();
  }
}