/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { SessionTimeoutLabelsConstants } from '../../../shared/constants/session-timeout.constant';
import { Observable, Subscription, timer } from 'rxjs';
import { AuthService } from 'app/core/services/auth.service';
import Utils from '../../../shared/utilities/utils';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';
import NotifUtils from 'app/shared/utilities/notif-utils';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { PathConstants } from 'app/shared/constants/path.constants';
import { Subject } from 'rxjs/internal/Subject';
import { DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { DOCUMENT, LocationStrategy } from '@angular/common';
import { take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, fromEvent} from 'rxjs';
import { map, repeatWhen, switchMap,tap } from 'rxjs/operators';
const counter = timer(0, 2000);
import swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-timeout-modal',
  templateUrl: './timeout-modal.component.html',
  styleUrls: ['./timeout-modal.component.scss']
})
export class TimeoutModalComponent implements OnInit {
  public clock;
  public SessionTimeoutLabelsConstants  = SessionTimeoutLabelsConstants;
  timer: number = 0;
  private authService: AuthService;
  public countDown: Observable<void>;
  private onDestroy$ = new Subject<void>();
  display = '';
  public intervalSub: Subscription;
  mySubscription: any;
  timerInfo;
  timeOut: Boolean = false;
  isIdle;

  static hasIso360Loaded: boolean = false;
  static isGeneratingQuoteProposal: boolean = false;
  static isExpiredToken: boolean = false;

private readonly _restartTimer = new Subject<void>();
private readonly _stopTimer = new Subject<void>();
subscription: Subscription;
  constructor(
    private modalRef: BsModalRef,
    private router: Router,
    private modalService: BsModalService,
    private location: LocationStrategy,
    public bsModalRef: BsModalRef,
    @Inject(DOCUMENT) private document: Document,

  ) {}

  ngOnInit(){}


  startTimer() {
    localStorage.removeItem('timer');
    localStorage.removeItem('endTime');
    localStorage.removeItem('display');

    localStorage.setItem('isSessionTimeoutOpen','true');
    this.authService.idle.clearInterrupts();
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });

    const isIdle = localStorage.getItem('ng2Idle.main.idling');

    this.timer = +localStorage.getItem('timer');

  }

  hideButton(){
    this.modalRef.hide();
  }


  showUnauthorizedError(): void {
    const defaults: any = {
        title: 'Oops...',
        icon: ErrorMessageConstant.unAuthorizedError.error,
        allowOutsideClick: false
    };
    NotifUtils.showNotice(ErrorMessageConstant.unAuthorizedError.message, defaults, () => {
      this.modalRef.hide();
      this.authService.logout();
        this.router.navigate([`/${PathConstants.Login.Index}`]);
    });
  }

  removeMinSec(str){
    const searchMins00 = str.search('00 mins');
    let newString;
    if(searchMins00 === 0 ){
      newString = str.slice(7);
    }else{
      newString = str;
    }
    return newString;
  }

  checkIfTokenExists(){
    if(localStorage.getItem('auth')){
      return true;
    }
    return false;
  }

  redirectToLogin(){
    this.modalRef.hide();
    this.router.navigate(['/dashboard']);
    this.document.location.href = '/dashboard';
    window.location.href = '/dashboard';
  }


  closeTimoutDialog(){
    this.modalRef.hide();
    swal.fire({
      title: 'Please wait',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      customClass: {
        container: 'session-swal'
      }
    }).then(()=>{
      Utils.unblockUI();
    });
    swal.showLoading();
    localStorage.clear();
    window.location.reload();
    this.document.location.href = '/dashboard';
  }

  static sessionBlockUI(): void {
    if (!this.isGeneratingQuoteProposal) {
      swal.fire({
        title: 'Please wait',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false
      });
      swal.showLoading();
    }
  }

  static sessionUnblockUI(): void {
    if (!this.isGeneratingQuoteProposal && !this.isExpiredToken && swal.isVisible() && swal.isLoading()) {
      swal.close();
    }
  }


}
