import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { PathConstants } from 'app/shared/constants/path.constants';
import { BaseClass } from 'app/shared/base-class';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SubmissionService } from '../services/submission/submission.service';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import Utils from '../../shared/utilities/utils';

@Injectable()
export class AccessDenied001Guard extends BaseClass implements CanActivate {
  protected genericConstants = GenericConstants;

  constructor(
    protected router: Router,
    protected submissionService: SubmissionService
  ) {
    super();
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkAccess(route);
  }

  checkAccess(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (route.url[0].path !== 'submission' && route.url[1].path !== 'list') {
      const riskId = route.url[0].path;
      const riskDetailId = route.url[1].path;

      if (!Utils.isValidGUID(riskId) || !Utils.isValidGUID(riskDetailId)) {
        this.router.navigate([PathConstants.NotFound]);
        sessionStorage.setItem('hasValidPreviousURL', 'false');
        return of(false);
      }

      return this.submissionService.getData(riskId, riskDetailId).pipe(
        take(1),
        map(data => {
          const isRevisedRenewalPending = data?.risks[0].renewalCode === 'RRNPEN';
          const isInternal = localStorage.getItem('userType') === this.genericConstants.userType.internal;;

          if (isInternal) {
            return true;
          } else if (!isInternal && isRevisedRenewalPending) {
            this.router.navigate([PathConstants.AccessDenied001.Index]);
            return false;
          }

          return true;
        })
      );
    }
  }
}
