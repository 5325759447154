import { Injectable } from '@angular/core';
import { BaseClass } from '../../../shared/base-class';
import { BehaviorSubject, combineLatest, Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { RiskStatus, RiskStatusCode } from '../../../shared/models/data/dto/quick-quote/risk.dto';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { takeUntil } from 'rxjs/operators';
import { UWActionType } from 'app/shared/models/data/dto/quick-quote/risk.dto';
import { AuthService } from 'app/core/services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { PathConstants } from 'app/shared/constants/path.constants';
import * as _ from 'lodash';
import { select, Store } from '@ngrx/store';
import { updateSubmissionStatusFromSubmissionPageData } from 'app/store/submission/submission.actions';
import { selectCoveragesIsLoading } from 'app/store/coverages/coverages.selectors';
import { selectEndorsementsIsLoading } from 'app/store/endorsements/endorsements.selectors';
import { selectClaimsIsLoading } from 'app/store/claims/claims.selectors';
import { selectPropertiesIsLoading } from 'app/store/properties/properties.selectors';
import { selectRecalculateValuationIsLoading } from 'app/store/recalculate-valuation/racalculate-valuation.selectors';
import { selectApplicantsIsLoading } from 'app/store/applicants/applicants.selectors';
import { selectUWQuestionsIsLoading } from 'app/store/uw-questions/uw-questions.selectors';
import { selectInterestsIsLoading } from 'app/store/interests/interests.selectors';
import { selectSubmissionUWRsIsLoading } from 'app/store/submission-uwrs/submission-uwrs.selectors';
import { selectRaterIsLoading } from 'app/store/rater/rater.selectors';
import { selectRaterCalculateDP3IsLoading } from 'app/store/rater-calculate-dp3/rater-calculate-dp3.selectors';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import * as moment from 'moment';

type RenewalStatusCode = null | 'RNPEN' | 'RWO' | 'RRW' | 'RWNT' | 'RRNPEN' | 'RRWO';

@Injectable({
  providedIn: 'root',
})

export class SubmissionPageData extends BaseClass {
  public genericConstants = GenericConstants;
  public hasRiskEntity = false;
  public initFormsComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public disableViewQuote: boolean = true;

  // selectors
  public selectCoveragesIsLoading$: Observable<boolean>;
  public selectEndorsementsIsLoading$: Observable<boolean>;
  public selectClaimsIsLoading$: Observable<boolean>;
  public selectPropertiesIsLoading$: Observable<boolean>;
  public selectRecalculateValuationIsLoading$: Observable<boolean>;
  public selectApplicantsIsLoading$: Observable<boolean>;
  public selectUWQuestionsIsLoading$: Observable<boolean>;
  public selectInterestsIsLoading$: Observable<boolean>;
  public selectSubmissionUWRsIsLoading$: Observable<boolean>;
  public selectRaterIsLoading$: Observable<boolean>;
  public selectRaterCalculateDP3IsLoading$: Observable<boolean>;

  // quote status
  quoteStatus: BehaviorSubject<RiskStatus> = new BehaviorSubject<RiskStatus>('Status');
  policyStatus: BehaviorSubject<RiskStatus> = new BehaviorSubject<RiskStatus>('Status');

  // renewal status
  renewalStatus: RenewalStatusCode = null;

  isPendingCancellation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isApprovedForReinstatement: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isRenewalOfferedDate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isRewritten: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isValidForApplication: boolean = false;
  disableQuoteForms: boolean = false;
  actionType: UWActionType = null;
  visitedEndorsements: boolean = false;
  outsideQuotedTabs: boolean = false;

  // navigation validation
  covaragesValid: boolean = false;
  propertyValid: boolean = false;
  applicantValid: boolean = false;
  uwQuestionsValid: boolean = false;

  // quick quote
  isApplicantFormOpen: boolean = true;
  isQQPropertyOpen: boolean = true;
  isQQCoverageOpen: boolean = true;
  isOrder: boolean = false;
  isSuccessfulOrder: boolean = false;
  qqSavingComplete: Subject<boolean> = new Subject<boolean>();

  // coverages
  isCoveragesOpen: boolean = true;
  isDiscountsOpen: boolean = true;
  isFloodOpen: boolean = true;
  isSchedulesOpen: boolean = true;
  isOrderFloodDataClicked: boolean = false;
  coveragesSavingComplete: Subject<boolean> = new Subject<boolean>();

  // endorsements
  isEndorsementOpen: boolean = true;
  endorsementsSavingComplete: Subject<boolean> = new Subject<boolean>();

  // claims
  public isClaimsOpen: boolean = true;
  public claimsSavingComplete: Subject<boolean> = new Subject<boolean>();

  // property details
  isLocationOpen: boolean = true;
  isPropertyOpen: boolean = true;
  isWindMitigationOpen: boolean = true;
  propertiesSavingComplete: Subject<boolean> = new Subject<boolean>();

  // applicant
  isApplicantOpen: boolean = true;
  isContactOpen: boolean = true;
  isCoApplicantOpen: boolean = true;
  applicantSavingComplete: Subject<boolean> = new Subject<boolean>();

  // uwquestion
  isEligibilityOpen: boolean = true;
  isGeneralQuestionOpen: boolean = true;
  uwQuestionsSavingComplete: Subject<boolean> = new Subject<boolean>();

  // interest
  isPriorInsuranceOpen: boolean = true;
  isInterestOpen: boolean = true;
  interestsSavingComplete: Subject<boolean> = new Subject<boolean>();

  // uwapproval
  hasAir: boolean = false;
  hasDecline: boolean = false;
  uwrListEmpty: boolean = false;
  isReferralOpen: boolean = true;
  allItemsChecked: boolean = false;
  hasPostBindUwrItem: boolean = false;
  uwrListAllPostBind: boolean = false;
  uwrListAllNonPostBind: boolean = false;
  postBindUwrItemsChecked: boolean = false;
  postBindUwrItemsApproved: boolean = false;
  nonPostBindUwrItemsChecked: boolean = false;
  nonPostBindUwrItemsApproved: boolean = false;
  uwApprovalListAddedAllPostBind: boolean = false;
  uwApprovalSavingComplete: Subject<boolean> = new Subject<boolean>();
  hasUwApprovalChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // bind and issue
  isBindAndIssueOpen: boolean = true;
  isBillingOpen: boolean = true;
  isPaperlessOpen: boolean = true;
  isSignatureOpen: boolean = true;
  isDocumentsOpen: boolean = true;
  isNotesOpen: boolean = true;
  isPrintOpen: boolean = true;

  // bind and issue validity
  hasInvalidQuote: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isAllUWApprovalApproved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // renewal
  hasRenewalOfferChanges: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  disableQuoteFormsByUWQuestions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public authService: AuthService,
    protected router: Router,
    protected store: Store
  ) {
    super();
    this.selectCoveragesIsLoading$ = this.store.pipe(select(selectCoveragesIsLoading));
    this.selectEndorsementsIsLoading$ = this.store.pipe(select(selectEndorsementsIsLoading));
    this.selectClaimsIsLoading$ = this.store.pipe(select(selectClaimsIsLoading));
    this.selectPropertiesIsLoading$ = this.store.pipe(select(selectPropertiesIsLoading));
    this.selectRecalculateValuationIsLoading$ = this.store.pipe(select(selectRecalculateValuationIsLoading));
    this.selectApplicantsIsLoading$ = this.store.pipe(select(selectApplicantsIsLoading));
    this.selectUWQuestionsIsLoading$ = this.store.pipe(select(selectUWQuestionsIsLoading));
    this.selectInterestsIsLoading$ = this.store.pipe(select(selectInterestsIsLoading));
    this.selectSubmissionUWRsIsLoading$ = this.store.pipe(select(selectSubmissionUWRsIsLoading));
    this.selectRaterIsLoading$ = this.store.pipe(select(selectRaterIsLoading));
    this.selectRaterCalculateDP3IsLoading$ = this.store.pipe(select(selectRaterCalculateDP3IsLoading));
    this.checkDisableViewQuote();
  }

  initSubmissionData(): void {
    this.initValues();
    this.subscribeToQuoteStatus();
    this.subscribeToUserType();
    this.subscribeToRouteChange();
  }

  initValues(): void {
    // quote status
    this.quoteStatus.next('Status');
    this.store.dispatch(updateSubmissionStatusFromSubmissionPageData({ status: 'Status' }));
    this.isValidForApplication = false;
    this.disableQuoteForms = false;
    this.actionType = null;
    this.visitedEndorsements = false;
    this.outsideQuotedTabs = this.setOutsideQuotedTabs(this.getCurrentCategoryRoute);

    // navigation validation
    this.covaragesValid = false;
    this.propertyValid = false;
    this.applicantValid = false;
    this.uwQuestionsValid = false;

    // quick quote
    this.isApplicantFormOpen = true;
    this.isQQPropertyOpen = true;
    this.isQQCoverageOpen = true;

    // coverages
    this.isCoveragesOpen = true;
    this.isDiscountsOpen = true;
    this.isFloodOpen = true;
    this.isSchedulesOpen = true;

    // endorsements
    this.isEndorsementOpen = true;

    // claims
    this.isClaimsOpen = true;

    // property details
    this.isLocationOpen = true;
    this.isPropertyOpen = true;
    this.isWindMitigationOpen = true;

    // applicant
    this.isApplicantOpen = true;
    this.isContactOpen = true;
    this.isCoApplicantOpen = true;

    // uwquestion
    this.isEligibilityOpen = true;
    this.isGeneralQuestionOpen = true;

    // interest
    this.isPriorInsuranceOpen = true;
    this.isInterestOpen = true;

    // uwapproval
    this.hasAir = false;
    this.hasDecline = false;
    this.uwrListEmpty = true;
    this.isReferralOpen = true;
    this.allItemsChecked = false;
    this.hasPostBindUwrItem = false;
    this.uwrListAllPostBind = false;
    this.uwrListAllNonPostBind = false;
    this.postBindUwrItemsChecked = false;
    this.postBindUwrItemsApproved = false;
    this.nonPostBindUwrItemsChecked = false;
    this.nonPostBindUwrItemsApproved = false;
    this.uwApprovalListAddedAllPostBind = false;

    // bindandissue
    this.isBindAndIssueOpen = true;
    this.isBillingOpen = true;
  }

  subscribeToQuoteStatus(): void {
    this.quoteStatus.takeUntil(this.stop$).subscribe(result => {
      this.disableQuoteForms = (result === this.genericConstants.quoteStatus.ruw && this.authService.userType.value === this.genericConstants.userType.external);

      if (this.renewalStatus === 'RWNT'){
        this.disableQuoteForms = true;
      }
    });
  }

  subscribeToUserType(): void {
    this.authService.userType.pipe(takeUntil(this.stop$)).subscribe(result => {
      this.disableQuoteForms = (result === this.genericConstants.userType.external && this.quoteStatus.value === this.genericConstants.quoteStatus.ruw);
    });
  }

  getQuoteStatus(quoteStatusCode: RiskStatusCode): RiskStatus {
    switch (quoteStatusCode) {
      case this.genericConstants.quoteStatusCode.quo:
        return this.genericConstants.quoteStatus.quo;
      case this.genericConstants.quoteStatusCode.app:
        return this.genericConstants.quoteStatus.app;
      case this.genericConstants.quoteStatusCode.ruw:
        return this.genericConstants.quoteStatus.ruw;
      case this.genericConstants.quoteStatusCode.auw:
        return this.genericConstants.quoteStatus.auw;
      case this.genericConstants.quoteStatusCode.duw:
        return this.genericConstants.quoteStatus.duw;
      case this.genericConstants.quoteStatusCode.air:
        return this.genericConstants.quoteStatus.air;
      case this.genericConstants.quoteStatusCode.qexp:
        return this.genericConstants.quoteStatus.qexp;
      case this.genericConstants.quoteStatusCode.act:
        return this.genericConstants.quoteStatus.act;
      case this.genericConstants.quoteStatusCode.pen:
        return this.genericConstants.quoteStatus.pen;
      case this.genericConstants.quoteStatusCode.can:
        return this.genericConstants.quoteStatus.can;
      case this.genericConstants.quoteStatusCode.pexp:
        return this.genericConstants.quoteStatus.pexp;
      default:
        return this.genericConstants.quoteStatus.status;
    }
  }

  subscribeToRouteChange(): void {
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .pipe(takeUntil(this.stop$)).subscribe((event: NavigationEnd) => {
        this.outsideQuotedTabs = this.setOutsideQuotedTabs(this.getCurrentCategoryRoute);
      });
  }

  setOutsideQuotedTabs(route: string): boolean {
    return route !== PathConstants.QuickQuote.QuickQuote.Index &&
      route !== PathConstants.Submission.Submission.CoveragesAndDeduction &&
      route !== PathConstants.Submission.Submission.Endorsements;
  }

  get getCurrentCategoryRoute(): string {
    const splitUrl = _.split(this.router.url, '/');
    const quickQuoteRoute = _.indexOf(splitUrl, PathConstants.QuickQuote.Index);

    if (quickQuoteRoute !== -1) {
      return splitUrl[quickQuoteRoute];
    }

    const currentCategoryIndex = splitUrl.length - 1;

    return splitUrl[currentCategoryIndex];
  }

  private checkDisableViewQuote(): void {
    combineLatest([
      this.selectCoveragesIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectEndorsementsIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectClaimsIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectPropertiesIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectRecalculateValuationIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectApplicantsIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectUWQuestionsIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectInterestsIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectSubmissionUWRsIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectRaterIsLoading$
        .pipe(takeUntil(this.stop$)),
      this.selectRaterCalculateDP3IsLoading$
        .pipe(takeUntil(this.stop$))
    ]).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.disableViewQuote = result.some(isLoading => {
        return isLoading === true;
      });
    });
  }

  public isUWQuestionHide(): boolean {
    const selectedEffectiveDate = new Date(sessionStorage.getItem('selectedEffectiveDate'));
    const dateImplement = moment(AgentDashboardConstants.defaultEffectiveDate);
    const dateDiff = dateImplement.diff(moment(selectedEffectiveDate));

    if(this.authService.userType.value === GenericConstants.userType.external && dateDiff <= 0) {
      const data = [GenericConstants.quoteStatus.ruw, GenericConstants.quoteStatus.auw, GenericConstants.quoteStatus.air, GenericConstants.quoteStatus.duw];
      return (data.includes(this.quoteStatus.value));
    } else {
      return false;
    }
  }
}
