import { Injectable } from '@angular/core';
import { BaseClass } from '../../../shared/base-class';
import { CoveragesData } from '../data/coverages.data';
import { LvRaterOtherPerilDeductibleNHR } from '../../../shared/constants/quick-quote.options.constants';
import * as _ from 'lodash';
import { QuoteService } from 'app/core/services/quote.service';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';
import { Validators } from '@angular/forms';
import { CoveragesValidators } from '../validators/coverages.validators';
import { GeneralValidationService } from '../../../core/services/submission/validations/general-validation.service';
import { PolicySummaryData } from '../../policy-management/data/policy-summary.data';
import { EntityRiskData } from '../data/entity-risk.data';
import { AuthService } from '../../../core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CoverageBusinessRules extends BaseClass {
  formTypeConstants = FormTypeConstants;

  constructor(
    protected coveragesData: CoveragesData,
    protected quoteService: QuoteService,
    protected generalValidationService: GeneralValidationService,
    protected policySummaryData: PolicySummaryData,
    protected entityRiskData: EntityRiskData,
    protected authService: AuthService
  ) {
    super();
  }

  updateAOPDeductibleDefault(isExternal: boolean): void {
    if (isExternal) {
      this.coveragesData.coveragesAndDeductiblesForm.get('allOtherPerilsId').setValue('1000');
    }
  }

  updateHurricaneDeductibleDefault(isExternal: boolean): void {
    if (isExternal) {
      if (this.quoteService.formType === this.formTypeConstants.HO3) {
        this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValue('2');
      } else {
        this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValue('1000');
      }
    }
  }

  checkIfOnInitNavigateAndFilter(isExternal: boolean, isOnInitNavigate: boolean): void {
    if (!isOnInitNavigate) {
      this.updateAOPDeductibleDefault(isExternal);
      this.updateHurricaneDeductibleDefault(isExternal);
    }
  }

  hideAOPDropdownItem(isExternal: boolean, code: string): boolean {
    return code === '500' && isExternal;
  }

  hideHurricaneDropdownItem(isExternal: boolean, code: string): boolean {
    return code === '500' && isExternal;
  }

  setupCoverageACValidatorByFormType(): void {
    const covCValidators = [Validators.required];

    switch (this.quoteService.formType) {
      case this.formTypeConstants.HO3:
        break;
      case this.formTypeConstants.HO4:
        break;
      case this.formTypeConstants.HO6:
        const isFL = this.coveragesData.isFL ?
          this.coveragesData.isFL : JSON.parse((sessionStorage.getItem('formTypeAndState')))?.state === 'FL';
        const effectiveDate = this.policySummaryData.isPolicy ?
          this.entityRiskData.getPolicyFirstIssueDate() :
          new Date(sessionStorage.getItem('selectedEffectiveDate'));
        const isPolicyOrRenewal = this.policySummaryData.isPolicy ||
          (this.policySummaryData.renewalCode !== null || this.policySummaryData.renewalStatusCode !== null);
        const covCBackUp = this.policySummaryData.isPolicy ?
          this.entityRiskData.ParentBackUpRiskData?.riskCoverages[0].covC :
          this.entityRiskData.renewalBackup?.riskCoverages[0].covC;

        if (isFL && this.coveragesData.isExternal && new Date(effectiveDate) >= new Date(this.authService?.brul247EffectiveDate)) {
          if (!isPolicyOrRenewal || (isPolicyOrRenewal && covCBackUp !== 0)){
            covCValidators.push(CoveragesValidators.covCCannotBeZero());
          }
        }
        break;
      case this.formTypeConstants.DP3:
        break;
    }

    this.generalValidationService.resetValidatorFormControl(
      this.coveragesData.coveragesAndDeductiblesForm,
      'coverageC',
      covCValidators
    );
  }
}