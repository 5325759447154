import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppAsideModule } from '@coreui/angular';
import { PathConstants } from '../../shared/constants/path.constants';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SubmissionListComponent } from './submission/submission-list/submission-list.component';
import { SubmissionManagementComponent } from './submission-management.component';
import { QuoteGuard } from '../../core/guards/new-quote.guard';
import { AccessDenied001Guard } from 'app/core/guards/access-denied-001.guard';


const submissionDetailsChildRoutes = [
  {
    path: PathConstants.QuickQuote.QuickQuote.Index,
    loadChildren: () => import('./quick-quote/quick-quote.module').then(m => m.QuickQuoteModule)
  },
  {
    path: PathConstants.Applicant.Applicant.Index,
    loadChildren: () => import('./applicant/applicant.module').then(m => m.ApplicantModule)
  },
  {
    path: PathConstants.Submission.Submission.Index,
    loadChildren: () => import('./submission/submission.module').then(m => m.SubmissionModule)
  },
  { path: '', redirectTo: PathConstants.Applicant.Applicant.Index, pathMatch: 'full' },
];

const routes: Routes = [
  {
    path: PathConstants.Submission.List,
    component: SubmissionListComponent,
  },
  {
    path: 'new',
    component: SubmissionManagementComponent,
    children: submissionDetailsChildRoutes,
    canActivate: [QuoteGuard]
  },
  {
    path: ':uuid/:uuid2',
    canActivate: [AccessDenied001Guard],
    component: SubmissionManagementComponent,
    children: submissionDetailsChildRoutes
  },
  { path: '', redirectTo: PathConstants.Applicant.Applicant.Index, pathMatch: 'full' },
  // { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes), AppAsideModule, ModalModule.forRoot()],
  exports: [RouterModule],
  providers: []
})
export class SubmissionManagementRoutingModule { }
