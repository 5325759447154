import { Component, OnInit } from '@angular/core';
import { AgentDashboardConstants } from 'app/shared/constants/agent-dashboard.constants';
import { NavigationService } from '../../../core/services/navigation/navigation.service';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.css']
})
export class RightSidebarComponent implements OnInit {

  public agentDashboardConstants = AgentDashboardConstants;

  constructor(public navService: NavigationService) { }

  ngOnInit() {
    this.toggleHideMainSidebar();
  }


  toggleHideMainSidebar() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('aside-menu-show');
  }
}
