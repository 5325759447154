import { Pipe, PipeTransform } from '@angular/core';
import { mode, TransferModeConstants } from '../../../shared/constants/book-transfer.constants';

@Pipe({
  name: 'disableTransferFormFields'
})
export class DisableTransferFormFieldsPipe implements PipeTransform {
  transferModeConstants = TransferModeConstants;

  transform(operationMode: mode): boolean {
    return operationMode === this.transferModeConstants.edit;
  }
}
