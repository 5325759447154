import { createSelector } from '@ngrx/store';
import { BaseState } from '../base.state';

export const selectCoveragesState = (state: BaseState) => state.coverages;

export const selectCoveragesIsLoading = createSelector(
  selectCoveragesState,
  (state) => state.isLoading
);

export const selectCoveragesSchedules = createSelector(
  selectCoveragesState,
  (state) => state.schedules
);