export const LvRaterLimitedFungiLimit = [
  {
    id: 1,
    tableName: 'LvRaterLimitedFungiLimit',
    tableDescription: 'Limited Fungi Limit',
    code: 10,
    description: '$10,000',
    isActive: true,
  },
  {
    id: 2,
    tableName: 'LvRaterLimitedFungiLimit',
    tableDescription: 'Limited Fungi Limit',
    code: 25,
    description: '$25,000/$50,000',
    isActive: true,
  },
  {
    id: 3,
    tableName: 'LvRaterLimitedFungiLimit',
    tableDescription: 'Limited Fungi Limit',
    code: 50,
    description: '$50,000/$50,000',
    isActive: true,
  },
];

export const LvRaterLossAssessmentLimit = [
  {
    id: 1,
    tableName: 'LvRaterLossAssessmentLimit',
    tableDescription: 'Loss Assessment Limit',
    code: 5,
    description: '$5,000',
    isActive: true,
  },
  {
    id: 2,
    tableName: 'LvRaterLossAssessmentLimit',
    tableDescription: 'Loss Assessment Limit',
    code: 10,
    description: '$10,000',
    isActive: true,
  }
];

export const LvRaterGolfCartOption = [
  {
    id: 1,
    tableName: 'LvRaterGolfCartOption',
    tableDescription: 'Golf Cart Option',
    code: 'Option 1',
    description: 'Option 1',
    isActive: true,
  },
  {
    id: 2,
    tableName: 'LvRaterGolfCartOption',
    tableDescription: 'Golf Cart Option',
    code: 'Option 2',
    description: 'Option 2',
    isActive: true,
  }
];

export const LvRaterScreenedEnclosureLimit = [
  {
    id: 1,
    tableName: 'LvRaterScreenedEnclosureLimit',
    tableDescription: 'Screened Enclosure Limit',
    code: 10000,
    description: '$10,000',
    isActive: true,
  },
  {
    id: 2,
    tableName: 'LvRaterScreenedEnclosureLimit',
    tableDescription: 'Screened Enclosure Limit',
    code: 15000,
    description: '$15,000',
    isActive: true,
  },
  {
    id: 3,
    tableName: 'LvRaterScreenedEnclosureLimit',
    tableDescription: 'Screened Enclosure Limit',
    code: 20000,
    description: '$20,000',
    isActive: true,
  },
  {
    id: 4,
    tableName: 'LvRaterScreenedEnclosureLimit',
    tableDescription: 'Screened Enclosure Limit',
    code: 25000,
    description: '$25,000',
    isActive: true,
  },
  {
    id: 5,
    tableName: 'LvRaterScreenedEnclosureLimit',
    tableDescription: 'Screened Enclosure Limit',
    code: 30000,
    description: '$30,000',
    isActive: true,
  },
  {
    id: 6,
    tableName: 'LvRaterScreenedEnclosureLimit',
    tableDescription: 'Screened Enclosure Limit',
    code: 35000,
    description: '$35,000',
    isActive: true,
  },
  {
    id: 7,
    tableName: 'LvRaterScreenedEnclosureLimit',
    tableDescription: 'Screened Enclosure Limit',
    code: 40000,
    description: '$40,000',
    isActive: true,
  },
  {
    id: 8,
    tableName: 'LvRaterScreenedEnclosureLimit',
    tableDescription: 'Screened Enclosure Limit',
    code: 45000,
    description: '$45,000',
    isActive: true,
  },
  {
    id: 9,
    tableName: 'LvRaterScreenedEnclosureLimit',
    tableDescription: 'Screened Enclosure Limit',
    code: 50000,
    description: '$50,000',
    isActive: true,
  }
];

export const LvRaterHomeComputerLimit = [
  {
    id: 1,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 1000,
    description: '$1,000',
    isActive: true,
  },
  {
    id: 2,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 2000,
    description: '$2,000',
    isActive: true,
  },
  {
    id: 3,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 3000,
    description: '$3,000',
    isActive: true,
  },
  {
    id: 4,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 4000,
    description: '$4,000',
    isActive: true,
  },
  {
    id: 5,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 5000,
    description: '$5,000',
    isActive: true,
  },
  {
    id: 6,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 6000,
    description: '$6,000',
    isActive: true,
  },
  {
    id: 7,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 7000,
    description: '$7,000',
    isActive: true,
  },
  {
    id: 8,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 8000,
    description: '$8,000',
    isActive: true,
  },
  {
    id: 9,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 9000,
    description: '$9,000',
    isActive: true,
  },
  {
    id: 10,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 10000,
    description: '$10,000',
    isActive: true,
  },
  {
    id: 11,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 11000,
    description: '$11,000',
    isActive: true,
  },
  {
    id: 12,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 12000,
    description: '$12,000',
    isActive: true,
  },
  {
    id: 13,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 13000,
    description: '$13,000',
    isActive: true,
  },
  {
    id: 14,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 14000,
    description: '$14,000',
    isActive: true,
  },
  {
    id: 15,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 15000,
    description: '$15,000',
    isActive: true,
  },
  {
    id: 16,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 16000,
    description: '$16,000',
    isActive: true,
  },
  {
    id: 17,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 17000,
    description: '$17,000',
    isActive: true,
  },
  {
    id: 18,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 18000,
    description: '$18,000',
    isActive: true,
  },
  {
    id: 19,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 19000,
    description: '$19,000',
    isActive: true,
  },
  {
    id: 20,
    tableName: 'LvRaterHomeComputerLimit',
    tableDescription: 'Home Computer Limit',
    code: 20000,
    description: '$20,000',
    isActive: true,
  },
];

export const LvRaterCovELiabilityLimit = [
  {
    tableName: 'LvRaterCovELiabilityLimit',
    tableDescription: 'Coverage E - Liability Limit',
    code: 100000,
    description: '$100,000',
    isActive: true,
  },
  {
    tableName: 'LvRaterCovELiabilityLimit',
    tableDescription: 'Coverage E - Liability Limit',
    code: 200000,
    description: '$200,000',
    isActive: true,
  },
  {
    tableName: 'LvRaterCovELiabilityLimit',
    tableDescription: 'Coverage E - Liability Limit',
    code: 300000,
    description: '$300,000',
    isActive: true,
  },
  {
    tableName: 'LvRaterCovELiabilityLimit',
    tableDescription: 'Coverage E - Liability Limit',
    code: 500000,
    description: '$500,000',
    isActive: true,
  },
];

export const LvRaterCovFMedicalLimit = [
  {
    tableName: 'LvRaterCovFMedicalLimit',
    tableDescription: 'Coverage F - Medical Limit',
    code: 1000,
    description: '$1,000',
    isActive: true,
  },
  {
    tableName: 'LvRaterCovFMedicalLimit',
    tableDescription: 'Coverage F - Medical Limit',
    code: 2500,
    description: '$2,500',
    isActive: true,
  },
  {
    tableName: 'LvRaterCovFMedicalLimit',
    tableDescription: 'Coverage F - Medical Limit',
    code: 5000,
    description: '$5,000',
    isActive: true,
  },
];

export const LvJewelryWatchesAndFursIncreasedAmount = [
  {
    tableName: 'LvJewelryWatchesAndFursIncreasedAmount',
    tableDescription: 'Jewelry, Watches and Furs Increased Amount',
    code: 0,
    description: '$0',
    isActive: true
  },
  {
    tableName: 'LvJewelryWatchesAndFursIncreasedAmount',
    tableDescription: 'Jewelry, Watches and Furs Increased Amount',
    code: 1000,
    description: '$1,000',
    isActive: true
  },
  {
    tableName: 'LvJewelryWatchesAndFursIncreasedAmount',
    tableDescription: 'Jewelry, Watches and Furs Increased Amount',
    code: 2000,
    description: '$2,000',
    isActive: true
  },
  {
    tableName: 'LvJewelryWatchesAndFursIncreasedAmount',
    tableDescription: 'Jewelry, Watches and Furs Increased Amount',
    code: 3000,
    description: '$3,000',
    isActive: true
  },
  {
    tableName: 'LvJewelryWatchesAndFursIncreasedAmount',
    tableDescription: 'Jewelry, Watches and Furs Increased Amount',
    code: 4000,
    description: '$4,000',
    isActive: true
  },
  {
    tableName: 'LvJewelryWatchesAndFursIncreasedAmount',
    tableDescription: 'Jewelry, Watches and Furs Increased Amount',
    code: 5000,
    description: '$5,000',
    isActive: true
  }
];

export const LvSilverwareGoldwarePewterwareIncreasedAmount = [
  {
    tableName: 'LvSilverwareGoldwarePewterwareIncreasedAmount',
    tableDescription: 'Silverware, Goldware, Pewterware Increased Amount',
    code: 0,
    description: '$0',
    isActive: true
  },
  {
    tableName: 'LvSilverwareGoldwarePewterwareIncreasedAmount',
    tableDescription: 'Silverware, Goldware, Pewterware Increased Amount',
    code: 1000,
    description: '$1,000',
    isActive: true
  },
  {
    tableName: 'LvSilverwareGoldwarePewterwareIncreasedAmount',
    tableDescription: 'Silverware, Goldware, Pewterware Increased Amount',
    code: 2000,
    description: '$2,000',
    isActive: true
  },
  {
    tableName: 'LvSilverwareGoldwarePewterwareIncreasedAmount',
    tableDescription: 'Silverware, Goldware, Pewterware Increased Amount',
    code: 3000,
    description: '$3,000',
    isActive: true
  },
  {
    tableName: 'LvSilverwareGoldwarePewterwareIncreasedAmount',
    tableDescription: 'Silverware, Goldware, Pewterware Increased Amount',
    code: 4000,
    description: '$4,000',
    isActive: true
  },
  {
    tableName: 'LvSilverwareGoldwarePewterwareIncreasedAmount',
    tableDescription: 'Silverware, Goldware, Pewterware Increased Amount',
    code: 5000,
    description: '$5,000',
    isActive: true
  },
  {
    tableName: 'LvSilverwareGoldwarePewterwareIncreasedAmount',
    tableDescription: 'Silverware, Goldware, Pewterware Increased Amount',
    code: 6000,
    description: '$6,000',
    isActive: true
  },
  {
    tableName: 'LvSilverwareGoldwarePewterwareIncreasedAmount',
    tableDescription: 'Silverware, Goldware, Pewterware Increased Amount',
    code: 7000,
    description: '$7,000',
    isActive: true
  },
  {
    tableName: 'LvSilverwareGoldwarePewterwareIncreasedAmount',
    tableDescription: 'Silverware, Goldware, Pewterware Increased Amount',
    code: 8000,
    description: '$8,000',
    isActive: true
  },
  {
    tableName: 'LvSilverwareGoldwarePewterwareIncreasedAmount',
    tableDescription: 'Silverware, Goldware, Pewterware Increased Amount',
    code: 9000,
    description: '$9,000',
    isActive: true
  },
  {
    tableName: 'LvSilverwareGoldwarePewterwareIncreasedAmount',
    tableDescription: 'Silverware, Goldware, Pewterware Increased Amount',
    code: 10000,
    description: '$10,000',
    isActive: true
  }
];

export const LvNumberOfGolfCartOptions= [
  {
    tableName: 'LvNumberOfGolfCartOptions',
    tableDescription: 'Number of Golf Cart',
    code: '1',
    description: '1',
    isActive: true
  },
  {
    tableName: 'LvNumberOfGolfCartOptions',
    tableDescription: 'Number of Golf Cart',
    code: '2',
    description: '2',
    isActive: true
  },
  {
    tableName: 'LvNumberOfGolfCartOptions',
    tableDescription: 'Number of Golf Cart',
    code: '3',
    description: '3+',
    isActive: true
  }
];

export const LvOrdinanceLawOptions= [
  {
    tableName: 'LvOrdinanceLawOptions',
    tableDescription: '25%, 50%, Reject',
    code: '25',
    description: '25%',
    isActive: true
  },
  {
    tableName: 'LvOrdinanceLawOptions',
    tableDescription: '25%, 50%, Reject',
    code: '50',
    description: '50%',
    isActive: true
  }
];

export const LvScreenedEnclosureOptions= [
  {
    tableName: 'LvScreenedEnclosureOptions',
    tableDescription: 'None, Replacement Cost, Actual Cash Value',
    code: 'N',
    description: 'None',
    isActive: true
  },
  {
    tableName: 'LvScreenedEnclosureOptions',
    tableDescription: 'None, Replacement Cost, Actual Cash Value',
    code: 'RC',
    description: 'Replacement Cost',
    isActive: true
  },
  {
    tableName: 'LvScreenedEnclosureOptions',
    tableDescription: 'None, Replacement Cost, Actual Cash Value',
    code: 'ACV',
    description: 'Actual Cash Value',
    isActive: true
  }
];

export const LVRaterFungiIncreasedAmountCovLimit = [
  {
    id: 1,
    tableName: 'LvRaterFungiIncreasedAmountCovLimit',
    tableDescription: 'Fungi, Wet or Dry Rot, or Bacteria Increased Amount of Coverage',
    code: 25,
    description: '$25,000/$50,000',
    isActive: true,
  },
  {
    id: 2,
    tableName: 'LvRaterFungiIncreasedAmountCovLimit',
    tableDescription: 'Fungi, Wet or Dry Rot, or Bacteria Increased Amount of Coverage',
    code: 50,
    description: '$50,000/$50,000',
    isActive: true,
  },
];